import { Box, Divider, PageHeader, PageLayout } from '@cmg/design-system';
import React from 'react';

import MyRetailDemandInfoBoxSkeleton from './info-box-skeleton/MyRetailDemandInfoBoxSkeleton';
import InternalRetailDemandSkeleton from './internal-retail-demand-skeleton/InternalRetailDemandSkeleton';

const MyRetailDemandPageSkeleton: React.FC = () => {
  return (
    <div>
      <PageLayout
        header={
          <React.Fragment>
            <PageHeader pageTitle="Total Demand & Retention" />
            <Divider />
          </React.Fragment>
        }
      >
        <Box py={2} data-testid="retail-page-skeleton" maxWidth={theme => theme.spacing(85)}>
          <MyRetailDemandInfoBoxSkeleton />
        </Box>
      </PageLayout>

      <PageLayout
        header={
          <React.Fragment>
            <PageHeader pageTitle="Internal Retail Demand" />
            <Divider />
          </React.Fragment>
        }
      >
        <Box py={2}>
          <InternalRetailDemandSkeleton />
        </Box>
      </PageLayout>
    </div>
  );
};

export default MyRetailDemandPageSkeleton;
