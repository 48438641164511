import { useCheckAccountTraits } from '@cmg/auth';
import { Banner, ServiceErrorBanner, ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { ServiceErrorPartsFragment } from '../../../common/graphql/fragments';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { SingleCurrencyInstrumentTrancheBanner } from '../components/single-currency-instrument-tranche-banner/SingleCurrencyInstrumentTrancheBanner';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import {
  useOfferingSetup_AddCurrencyToTrancheMutation,
  useOfferingSetup_AddInstrumentToTrancheMutation,
  useOfferingSetup_DeleteCurrencyFromTrancheMutation,
  useOfferingSetup_DeleteInstrumentFromTrancheMutation,
  useOfferingSetup_SetDefaultInstrumentForTrancheMutation,
  useOfferingSetup_TranchesQuery,
  useOfferingSetup_UpdateTrancheMutation,
} from './graphql';
import TrancheForm from './TrancheForm';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Tranches Route for Offering Setup
 * Displays list of tranches with editable currencies and instruments for each
 */
export const TranchesRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;
  const [editingId, setEditingId] = React.useState<string | null>(null);

  const hasInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const { data, loading, error, refetch } = useOfferingSetup_TranchesQuery({
    variables: { offeringId },
    fetchPolicy: 'network-only',
  });

  const { revalidate } = useValidateOffering(offeringId);

  const [deleteInstrumentFromTrancheServiceError, setDeleteInstrumentFromTrancheServiceError] =
    React.useState<ServiceErrorPartsFragment | null>();

  const [updateTranche, { loading: updating, error: updateError }] =
    useOfferingSetup_UpdateTrancheMutation({
      onCompleted: () => {
        setEditingId(null);
        refetch();
        revalidate();
        ToastManager.success('Updated Tranche');
      },
    });

  const [addCurrencyToTranche, { loading: addingCurrency, error: addCurrencyError }] =
    useOfferingSetup_AddCurrencyToTrancheMutation({
      onCompleted: () => {
        refetch();
        revalidate();
        ToastManager.success('Added Currency to Tranche');
      },
    });

  const [deleteCurrencyFromTranche, { loading: deletingCurrency, error: deleteCurrencyError }] =
    useOfferingSetup_DeleteCurrencyFromTrancheMutation({
      onCompleted: () => {
        refetch();
        revalidate();
        ToastManager.success('Deleted Currency from Tranche');
      },
    });

  const [addInstrumentToTranche, { loading: addingInstrument, error: addInstrumentError }] =
    useOfferingSetup_AddInstrumentToTrancheMutation({
      onCompleted: () => {
        setDeleteInstrumentFromTrancheServiceError(null);
        refetch();
        revalidate();
        ToastManager.success('Added Instrument to Tranche');
      },
    });

  const [
    deleteInstrumentFromTranche,
    { loading: deletingInstrument, error: deleteInstrumentError },
  ] = useOfferingSetup_DeleteInstrumentFromTrancheMutation({
    onCompleted: ({ deleteInstrumentFromTranche }) => {
      if (deleteInstrumentFromTranche.__typename === 'ServiceError') {
        setDeleteInstrumentFromTrancheServiceError(deleteInstrumentFromTranche);
      } else {
        setDeleteInstrumentFromTrancheServiceError(null);
        refetch();
        revalidate();
        ToastManager.success('Deleted Instrument from Tranche');
      }
    },
  });

  const [
    setDefaultInstrumentForTranche,
    { loading: settingDefaultInstrument, error: setDefaultInstrumentError },
  ] = useOfferingSetup_SetDefaultInstrumentForTrancheMutation({
    onCompleted: () => {
      refetch();
      revalidate();
      ToastManager.success('Set Default Instrument for Tranche');
    },
  });

  const currencies = data?.offering.currencies;
  const instruments = data?.offering.deliveryInstruments;
  const tranches = data?.offering.tranches;
  const errors =
    error ||
    updateError ||
    addCurrencyError ||
    deleteCurrencyError ||
    addInstrumentError ||
    deleteInstrumentError ||
    setDefaultInstrumentError;

  if (loading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        <SetupScreen.Header />
        {hasInternationalTrait ? (
          <Banner variant="info">
            Only single-instrument, single-tranche offerings are currently supported.
          </Banner>
        ) : (
          <SingleCurrencyInstrumentTrancheBanner />
        )}
        {errors && <ServerErrorsBanner error={errors} />}
        <SetupForm title="Tranches">
          {deleteInstrumentFromTrancheServiceError && (
            <ServiceErrorBanner error={deleteInstrumentFromTrancheServiceError} />
          )}
          {tranches &&
            tranches.map((tranche, index) => (
              <TrancheForm
                key={index}
                editing={editingId === tranche.id}
                disabled={
                  addingCurrency ||
                  deletingCurrency ||
                  addingInstrument ||
                  deletingInstrument ||
                  settingDefaultInstrument
                }
                submitting={updating}
                tranche={tranche}
                allCurrencies={currencies}
                allInstruments={instruments}
                onEdit={() => setEditingId(tranche.id)}
                onCancel={() => setEditingId(null)}
                onAddCurrency={currencyCode =>
                  addCurrencyToTranche({
                    variables: { offeringId, trancheId: tranche.id, currencyCode },
                  })
                }
                onDeleteCurrency={currencyCode =>
                  deleteCurrencyFromTranche({
                    variables: { offeringId, trancheId: tranche.id, currencyCode },
                  })
                }
                onAddInstrument={instrumentId =>
                  addInstrumentToTranche({
                    variables: { offeringId, trancheId: tranche.id, instrumentId },
                  })
                }
                onDeleteInstrument={instrumentId =>
                  deleteInstrumentFromTranche({
                    variables: { offeringId, trancheId: tranche.id, instrumentId },
                  })
                }
                onDefaultInstrumentChange={instrumentId => {
                  setDefaultInstrumentForTranche({
                    variables: { offeringId, trancheId: tranche.id, instrumentId },
                  });
                }}
                onSubmit={values =>
                  updateTranche({
                    variables: {
                      offeringId,
                      trancheId: tranche.id,
                      input: values,
                    },
                  })
                }
              />
            ))}
        </SetupForm>
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default TranchesRoute;
