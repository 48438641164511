import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import React from 'react';

type AccessCheckResult = {
  canRead: boolean;
  canUpdate: boolean;
  isSellSideAccount: boolean;
};

/**
 * Checks accessibility of an Institutional Indication.
 */
export function useCheckInstitutionalIndicationAccess({
  entitlements,
}: {
  entitlements: readonly string[];
}): AccessCheckResult {
  const hasRead = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalIndication.READ,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
    ],
    false
  );
  const hasFull = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalIndication.FULL,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.FULL,
    ],
    false
  );

  const isSellSideAccount = useCheckAccountType('SELL_SIDE');

  const accessChecks = React.useMemo(
    () => ({
      canRead:
        hasRead && (!isSellSideAccount || entitlements.includes('READ:INSTITUTIONAL_INDICATION')),
      canUpdate:
        hasFull && (!isSellSideAccount || entitlements.includes('UPDATE:INSTITUTIONAL_INDICATION')),
    }),
    [hasRead, hasFull, entitlements, isSellSideAccount]
  );

  return { ...accessChecks, isSellSideAccount };
}

// TODO remove
export default useCheckInstitutionalIndicationAccess;
