import { timeUtil } from '@cmg/common';

export const getShareStatusChangedLabel = (
  shareInfo: { isActive: boolean; timestamp: string } | null
) => {
  if (!shareInfo) {
    return null;
  }

  const prefix = shareInfo.isActive ? 'Shared as of' : 'Last Shared';
  return `${prefix} ${timeUtil.formatAsDisplayDateTime(shareInfo.timestamp)}`;
};
