import { RadioGroupField } from '@cmg/common';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import FilingCommonFieldsForm from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm';
import FilingDetailsForm from '../../../common/content/filing-details-form/FilingDetailsForm';
import { filingNames } from '../../../common/filing-name-utils';
import { dealStatusOptions } from './DSFilingForm.model';

export type Props = Readonly<{
  readonly stockSymbols: readonly string[];
}>;

const DSFilingForm: React.FC<Props> = ({ stockSymbols }) => {
  return (
    <div>
      <FilingCommonFieldsForm />
      <FilingDetailsForm stockSymbols={stockSymbols}>
        <GridLayout>
          <Column span={3}>
            <RadioGroupField
              withMargin
              fullWidth
              required
              label={filingNames.DS.full}
              name="dealStatus"
              options={dealStatusOptions}
            />
          </Column>
        </GridLayout>
      </FilingDetailsForm>
    </div>
  );
};

export default DSFilingForm;
