import {
  CurrencyInputField,
  DatePickerField,
  Option,
  RadioGroupField,
  TextAreaField,
  TextInputField,
} from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { RpnBasisCodeType, RpnRestrictedPeriodType } from '../../../../../graphql';
import { RPNFilingFormValues } from './RPNForm.model';

export const basisCode1dOptions: Option<RpnBasisCodeType>[] = [
  {
    label: 'Underwriter Activity Report',
    value: RpnBasisCodeType.Uar,
  },
  {
    label: 'ADTV and Public Float',
    value: RpnBasisCodeType.AdtvPfv,
  },
  {
    label: 'Other',
    value: RpnBasisCodeType.Other,
  },
];

export const basisCode5dOptions: Option<RpnBasisCodeType>[] = [
  {
    label: '5D',
    value: RpnBasisCodeType.FiveDays,
  },
  {
    label: 'Other',
    value: RpnBasisCodeType.Other,
  },
];

const BasisCodeSubForm: React.FC = () => {
  const { values } = useFormikContext<RPNFilingFormValues>();
  return (
    <div>
      <GridLayout>
        <Column span={6}>
          <RadioGroupField
            withMargin
            required
            fullWidth
            label="Basis Code"
            name="basisCode"
            options={
              values.restrictedPeriod === RpnRestrictedPeriodType.OneDay
                ? basisCode1dOptions
                : basisCode5dOptions
            }
          />
        </Column>
      </GridLayout>
      {values.basisCode === RpnBasisCodeType.Uar && (
        <GridLayout>
          <Column span={3}>
            <DatePickerField
              withMargin
              fullWidth
              required
              label="Underwriter Activity Report Date"
              testId="Regulatory Filings / Filings / RPN / Underwriter Activity Report Date"
              name="uarDate"
            />
          </Column>
        </GridLayout>
      )}
      {values.basisCode === RpnBasisCodeType.AdtvPfv && (
        <div>
          <GridLayout>
            <Column span={3}>
              <CurrencyInputField
                withMargin
                fullWidth
                required
                label="ADTV Number"
                aria-label="ADTV Number"
                name="adtvNumber"
                precision={6}
              />
            </Column>
            <Column span={3}>
              <TextInputField
                withMargin
                fullWidth
                required
                label="ADTV Source"
                aria-label="ADTV Source"
                name="adtvSource"
              />
            </Column>
          </GridLayout>
          <GridLayout>
            <Column span={3}>
              <CurrencyInputField
                withMargin
                required
                fullWidth
                label="Public Float Value"
                aria-label="Public Float Value"
                name="publicFloatValue"
                precision={6}
              />
            </Column>
            <Column span={3}>
              <TextInputField
                withMargin
                required
                fullWidth
                label="Public Float Value Source"
                aria-label="Public Float Value Source"
                name="publicFloatValueSource"
              />
            </Column>
          </GridLayout>
        </div>
      )}
      {values.basisCode === RpnBasisCodeType.Other && (
        <GridLayout>
          <Column span={6}>
            <TextAreaField
              withMargin
              required
              fullWidth
              label="Other Basis Text"
              aria-label="Other Basis Text"
              name="otherBasisDescription"
            />
          </Column>
        </GridLayout>
      )}
    </div>
  );
};

export default BasisCodeSubForm;
