import { Box, Typography } from '@cmg/design-system';

import { sortCaseInsensitive } from '../../../../../../../common/util/sortCaseInsensitive';
import { getFirmDisplayName } from '../../../../utils';
import { SyndicateManager } from '../../types';

export const getRelationshipTooltipContent = (managers: (SyndicateManager | null)[]) => {
  const managersList = managers.map((manager, index) => (
    <Typography variant="tooltip" key={`manager-${index}`} component="div">
      {getFirmDisplayName(manager ?? null, {
        ignoreAbbreviation: true,
        defaultName: manager?.cmgEntityKey,
      })}
    </Typography>
  ));
  return (
    <Box
      sx={{
        width: 200,
      }}
    >
      {managersList}
    </Box>
  );
};

export const getSortedRelationshipList = (
  managers: (SyndicateManager | null)[],
  oidcUserCmgEntityKey: string
) => {
  return (
    managers?.slice().sort((a, b) => {
      if (a?.cmgEntityKey === oidcUserCmgEntityKey) {
        return -1;
      }
      if (b?.cmgEntityKey === oidcUserCmgEntityKey) {
        return 1;
      }

      return sortCaseInsensitive(
        a?.firmNameAbbreviation || a?.firmName || '',
        b?.firmNameAbbreviation || b?.firmName || ''
      );
    }) || []
  );
};
