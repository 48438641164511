import { ApolloError } from '@apollo/client';
import { UUID } from '@cmg/common';
import { useCallback, useMemo, useState } from 'react';

import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import { ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment } from '../graphql';
import {
  getOnSendValidation,
  getValidInvestorKeys,
  isProspectusUploaded,
  ResendProspectusResult,
} from './useBulkResendProspectus.model';
import { useProspectusDeliveryOnSendValidationQuery } from './useProspectusDeliveryOnSendValidationQuery';
import { useResendProspectusMutation } from './useResendProspectusMutation';

export type BulkResendProspectusProps = Readonly<{
  readonly offeringId: UUID;
}>;

export type BulkResendProspectusResult = Readonly<{
  readonly loading: boolean;
  readonly error?: ApolloError;
  readonly prospectusOnSendValidation?: ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment;
  readonly isProspectusDocumentUploaded: boolean;
  readonly resendProspectus: (investorKeys: string[]) => Promise<ResendProspectusResult>;
}>;

export function useBulkResendProspectus({
  offeringId,
}: BulkResendProspectusProps): BulkResendProspectusResult {
  const [latestValidationResult, setLatestValidationResult] =
    useState<ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment>();
  const { type: personaType } = useIdentifyOrderBookPersona({ offeringId });
  const isSyndicatePersona = personaType === OrderBookPersonaType.SYNDICATE;

  const {
    loading: loadingValidationResult,
    error: errorValidationResult,
    data: dataValidationResult,
    refetch: getValidationResult,
  } = useProspectusDeliveryOnSendValidationQuery({
    investorKeys: [],
    isSyndicatePersona,
    offeringId,
    currentTenantOnly: false,
  });

  const isProspectusDocumentUploaded = useMemo(
    () => isProspectusUploaded(isSyndicatePersona, dataValidationResult),
    [dataValidationResult, isSyndicatePersona]
  );

  const [
    executeResendProspectusMutation,
    { loading: loadingResendProspectus, error: errorResendProspectus },
  ] = useResendProspectusMutation({ offeringId });

  const resendProspectus = useCallback(
    async (investorKeys: string[]): Promise<ResendProspectusResult> => {
      setLatestValidationResult(undefined);
      const { data, error } = await getValidationResult({
        offeringId,
        investorKeys,
        isSyndicatePersona,
      });

      if (error) {
        return null;
      }

      const validationResult = getOnSendValidation(isSyndicatePersona, data);
      const validInvestorKeys = getValidInvestorKeys(isSyndicatePersona, investorKeys, data);

      if (validInvestorKeys.length === 0) {
        setLatestValidationResult(validationResult);
        return { validationError: null, numOfInvestorsRecievingProspectus: 0 };
      }

      const { validationError } = await executeResendProspectusMutation({
        investorKeys: validInvestorKeys,
      });

      setLatestValidationResult(validationResult);
      return {
        validationError,
        numOfInvestorsRecievingProspectus: validationError ? 0 : validInvestorKeys.length,
      };
    },
    [executeResendProspectusMutation, getValidationResult, isSyndicatePersona, offeringId]
  );

  const loading = loadingValidationResult || loadingResendProspectus;

  return {
    loading,
    error: errorValidationResult ?? errorResendProspectus,
    prospectusOnSendValidation: latestValidationResult,
    isProspectusDocumentUploaded,
    resendProspectus,
  };
}
