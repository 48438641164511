import { FlexContainer, numericUtil, ToastManager, UUID } from '@cmg/common';
import type { AgGridEvent } from 'ag-grid-community';
import React from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useGridReady } from '../../../common/hooks/useGridReady';
import { useGridRefresh } from '../../../common/hooks/useGridRefresh';
import { useIsUserSettlementAgent } from '../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import routeFactory from '../../../common/util/routeFactory';
import { TradeReleaseHistoryRoute } from '../history/TradeReleaseHistoryRoute';
import {
  TradeRelease_TradeGridItemFragment,
  useTradeRelease_TradeGridRowsQuery,
} from './graphql/__generated__';
import TradeReleaseGrid from './grid/TradeReleaseGrid';
import GridActions from './grid-actions/GridActions';
import { InvestorsWithoutBDAgentAlert } from './grid-alerts/InvestorsWithoutBDAgentAlert';
import { NoActionNeededAlert } from './grid-alerts/NoActionNeededAlert';
import { StatusNotChangedAlert } from './grid-alerts/StatusNotChangedAlert';
import { GridFilters } from './grid-filters/GridFilters';
import { useExportTradeRelease } from './hooks/useExportTradeRelease';
import { useTradeGrid } from './hooks/useTradeGrid';
import { SGridContainer, SLayout, SSubTitle, STitle } from './TradeReleaseLayout.styles';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  offeringId: UUID;
  offeringIssuerName?: string;
}>;

const TradeReleaseLayout: React.FC<Props> = ({ offeringId, offeringIssuerName }) => {
  const {
    data: tradeReleaseData,
    loading: tradeReleaseLoading,
    error: tradeReleaseError,
  } = useTradeRelease_TradeGridRowsQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });
  const exportTrades = useExportTradeRelease(offeringIssuerName);
  const {
    loading: settlementAgentLoading,
    isSettlementAgent,
    error: settlementAgentError,
  } = useIsUserSettlementAgent({ offeringId });

  const gridRef = React.useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);

  const {
    selectedRows,
    setSelectedRows,
    allRows,
    filteredRows,
    filters,
    investors,
    numOfRowsWithoutBnDAgent,
    numOfRowsNeedingAction,
    numOfUnchangedTrades,
    setNumOfUnchangedTrades,
  } = useTradeGrid(tradeReleaseData?.tradeGridRows ?? []);

  useGridRefresh({
    rows: allRows as TradeRelease_TradeGridItemFragment[],
    gridApi: gridRef.current?.api,
  });

  const handleExportTrades = () => {
    try {
      exportTrades(gridRef.current?.api);
      ToastManager.success('Export successfully completed.');
    } catch {
      ToastManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    }
  };

  if (tradeReleaseError) {
    return <ServerErrorsBanner error={tradeReleaseError} />;
  }
  if (tradeReleaseLoading || settlementAgentLoading) {
    return <Loading />;
  }

  return (
    <SLayout data-testid="trade-release-single-record" direction="column" expand>
      {settlementAgentError && <ServerErrorsBanner error={settlementAgentError} />}

      <FlexContainer direction="row" alignItems="baseline">
        <STitle>Trade Release</STitle>
        <SSubTitle>
          {`Trades needing action: ${getDisplayValueForInteger(numOfRowsNeedingAction)}`}
        </SSubTitle>
      </FlexContainer>

      {isSettlementAgent && numOfRowsWithoutBnDAgent > 0 && (
        <InvestorsWithoutBDAgentAlert
          offeringId={offeringId}
          numOfInvestorsWithoutBDAgent={numOfRowsWithoutBnDAgent}
        />
      )}
      {numOfRowsNeedingAction === 0 && <NoActionNeededAlert />}
      {numOfUnchangedTrades > 0 && (
        <StatusNotChangedAlert
          tradesCount={numOfUnchangedTrades}
          onDismiss={() => setNumOfUnchangedTrades(0)}
        />
      )}

      {selectedRows.length > 0 && (
        <GridActions
          offeringId={offeringId}
          gridApi={gridRef.current?.api}
          setUnchangedTradesCount={setNumOfUnchangedTrades}
          selectedRows={selectedRows}
        />
      )}
      {selectedRows.length === 0 && (
        <GridFilters
          filters={filters}
          offeringId={offeringId}
          onExportTrades={handleExportTrades}
        />
      )}

      <SGridContainer>
        <TradeReleaseGrid
          offeringId={offeringId}
          filteredTradeRows={filteredRows}
          onGridReady={onGridReady}
          onSelectionChange={setSelectedRows}
          numOfAllRows={allRows.length}
          setNumOfUnchangedTrades={setNumOfUnchangedTrades}
        />
      </SGridContainer>

      <Route
        path={routeFactory.tradeReleaseHistory.routePath}
        render={renderProps => <TradeReleaseHistoryRoute {...renderProps} investors={investors} />}
      />
    </SLayout>
  );
};

export default TradeReleaseLayout;
