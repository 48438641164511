import React from 'react';

import { SContainer } from './ListItem.styles';

export type Props = {
  onClick?: () => void;
  active?: boolean;
  isChild?: boolean;
  disabled?: boolean;
};

const ListItem: React.FC<Props> = ({
  children,
  active = false,
  onClick,
  isChild = false,
  disabled = false,
}) => {
  const handleOnClick = () => {
    onClick && onClick();
  };

  return (
    <SContainer active={active} onClick={handleOnClick} isChild={isChild} disabled={disabled}>
      {children}
    </SContainer>
  );
};

export default ListItem;
