import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Stack, Typography } from '@cmg/design-system';
import React from 'react';

import { useIsOfferingAuthor } from '../../../../../../../common/hooks/useIsOfferingAuthor';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { stepIds } from '../../../../../../offering-setup/constants';
import { ProspectusValidationErrors } from '../../ComplianceProspectus.model';
import { ProspectusHistoryTable } from './ProspectusHistoryTable';
import { ProspectusHistoryValidationError } from './ProspectusHistoryValidationError';

export type Props = Readonly<{
  offeringId: UUID;
  validationErrors: ProspectusValidationErrors;
  linkToIndication: string;
}>;

export const ProspectusHistoryValidationErrors: React.FC<Props> = ({
  offeringId,
  validationErrors,
  linkToIndication,
}) => {
  const isOfferingAuthor = useIsOfferingAuthor(offeringId);
  const hasOfferingPermission = useCheckPermissions([permissionsByEntity.Offering.FULL]);

  if (!validationErrors.hasValidationError) {
    return null;
  }

  return (
    <Stack gap={3}>
      <ProspectusHistoryTable revisions={[]} />
      <Stack alignItems="center" width="100%" px={4} gap={2}>
        <Typography variant="subtitle1" color="text.primary">
          Prospectus requires additional action to send
        </Typography>
        <Stack>
          {validationErrors.isProspectusDocumentMissing && (
            <ProspectusHistoryValidationError
              title="Offering Details: Prospectus"
              hasPermissionsToFix={isOfferingAuthor && hasOfferingPermission}
              linkToFix={routeFactory.offeringSetup.getUrlPath({
                stepId: stepIds.PROSPECTUS,
                offeringId,
              })}
              howToFixText="Upload document"
              missingPermissionsTooltipText="You do not have access to edit this area. Please contact the offering author to provide the information"
            />
          )}
          {validationErrors.isContactMissing && (
            <ProspectusHistoryValidationError
              title="Indication: Investor Contacts"
              hasPermissionsToFix
              linkToFix={linkToIndication}
              howToFixText="Add investor contacts"
            />
          )}
          {validationErrors.isIndicationUnacknowledged && (
            <ProspectusHistoryValidationError
              title="Indication Acknowledged"
              hasPermissionsToFix
              linkToFix={linkToIndication}
              howToFixText="Acknowledge Indication"
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
