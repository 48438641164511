/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_RetailDemandRevisionFragment = {
  readonly __typename?: 'RetailDemandRevision';
  readonly id: string;
  readonly modifiedAt: string;
  readonly modifiedByFirmKey?: string | null;
  readonly roundLots?: number | null;
  readonly shareQuantity: number;
};

export type OrderBook_RetailDemandHistoryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type OrderBook_RetailDemandHistoryQuery = {
  readonly __typename?: 'Query';
  readonly retailDemandHistory: ReadonlyArray<{
    readonly __typename?: 'RetailDemandRevision';
    readonly id: string;
    readonly modifiedAt: string;
    readonly modifiedByFirmKey?: string | null;
    readonly roundLots?: number | null;
    readonly shareQuantity: number;
  }>;
};

export const OrderBook_RetailDemandRevisionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailDemandRevision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailDemandRevision' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_RetailDemandHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_RetailDemandHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retailDemandHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailDemandRevision' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailDemandRevision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailDemandRevision' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_RetailDemandHistoryQuery__
 *
 * To run a query within a React component, call `useOrderBook_RetailDemandHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_RetailDemandHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_RetailDemandHistoryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useOrderBook_RetailDemandHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_RetailDemandHistoryQuery,
    OrderBook_RetailDemandHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_RetailDemandHistoryQuery,
    OrderBook_RetailDemandHistoryQueryVariables
  >(OrderBook_RetailDemandHistoryDocument, options);
}
export function useOrderBook_RetailDemandHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_RetailDemandHistoryQuery,
    OrderBook_RetailDemandHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_RetailDemandHistoryQuery,
    OrderBook_RetailDemandHistoryQueryVariables
  >(OrderBook_RetailDemandHistoryDocument, options);
}
export type OrderBook_RetailDemandHistoryQueryHookResult = ReturnType<
  typeof useOrderBook_RetailDemandHistoryQuery
>;
export type OrderBook_RetailDemandHistoryLazyQueryHookResult = ReturnType<
  typeof useOrderBook_RetailDemandHistoryLazyQuery
>;
export type OrderBook_RetailDemandHistoryQueryResult = Apollo.QueryResult<
  OrderBook_RetailDemandHistoryQuery,
  OrderBook_RetailDemandHistoryQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeOrderBook_RetailDemandHistoryMockResponse = makeMockResponse<
  OrderBook_RetailDemandHistoryQueryVariables,
  OrderBook_RetailDemandHistoryQuery
>(OrderBook_RetailDemandHistoryDocument);
