import { textUtil } from '@cmg/common';
import { Tooltip, Typography, TypographyProps } from '@cmg/design-system';
import React from 'react';

type Props = {
  textContent: string;
  maxLength: number;
  color?: TypographyProps['color'];
};

export const TruncatedTextFormatter: React.FC<Props> = ({ color, textContent, maxLength }) => {
  if (textContent.length > maxLength) {
    return (
      <Tooltip variant="info" placement="bottom-start" title={textContent}>
        <Typography color={color}>
          {textUtil.truncateAtMaxLength(textContent, { maxLength })}
        </Typography>
      </Tooltip>
    );
  }
  return <Typography color={color}>{textContent.length ? textContent : '-'}</Typography>;
};
