import { FormField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../common/components/layout/panel/Panel';
import { CommonFields } from '../filing-common-fields-form/FilingCommonFieldsForm.model';

export type Props = {
  detail: CommonFields;
};

const FilingCommonFieldsDetail: React.FC<Props> = ({ detail }) => {
  return (
    <Panel testId={xcSelectors.regulatoryFilingsCommonDetail.testId}>
      <Panel.Header title="Contact Information" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Firm Name">
              <div>{detail.firmName}</div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Firm CRD Number">
              <div>{detail.firmCrdNumber}</div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Firm MPID">
              <div>{detail.firmMpid}</div>
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Contact Name">
              <div>{detail.contactName}</div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Contact Title">
              <div>{detail.contactTitle}</div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Telephone Number">
              <div>{detail.telephoneNumber}</div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Email Address">
              <div>{detail.emailAddress}</div>
            </FormField>
          </Column>
        </GridLayout>
      </Panel.Content>
    </Panel>
  );
};

export default FilingCommonFieldsDetail;
