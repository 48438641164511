import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';
import { wiresFormEmailAddressesSchema } from '../../common/components/form/emails-picker/wiresFormEmailAddressesSchema';

export const validationSchema = yup
  .object()
  .concat(wiresFormEmailAddressesSchema)
  .concat(wiresFormDisclaimerSchema);

export type Values = {
  emailAddresses: readonly string[];
  disclaimer: string | null;
};
