import { LinkButton } from '@cmg/common';
import React from 'react';

import { SSectionHeader, SSectionHeaderWrapper } from './FormSectionHeader.styles';

export type AddEnabledProps = {
  isAddEnabled: true;
  title: string;
  addButtonText: string;
  addButtonTestId?: string;
  handleAddClick: () => void;
};

export type AddDisabledProps = {
  isAddEnabled: false;
  title: string;
};

export type Props = AddDisabledProps | AddEnabledProps;

const FormSectionHeader: React.FC<Props> = props => {
  return (
    <SSectionHeaderWrapper>
      <SSectionHeader>{props.title}</SSectionHeader>
      {props.isAddEnabled && (
        <LinkButton
          iconLeft={{ name: 'plus', size: 'sm' }}
          inline
          onClick={props.handleAddClick}
          testId={props.addButtonTestId}
        >
          {props.addButtonText}
        </LinkButton>
      )}
    </SSectionHeaderWrapper>
  );
};

export default FormSectionHeader;
