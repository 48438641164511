import { ListStatusItemContentProps } from '@cmg/design-system';
import groupBy from 'lodash/groupBy';

import { sortCaseInsensitive } from '../../../../common/util/sortCaseInsensitive';
import { ManagerRole } from '../../../../graphql';

export type RecipientListManagerRole = string;

type RecipientBase<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly cmgEntityKey: string;
  readonly firmName: string;
  readonly role: TManagerRole;
  readonly distributionList: readonly string[];
}>;

export type Recipient<TManagerRole extends RecipientListManagerRole> = RecipientBase<TManagerRole> &
  Readonly<{
    readonly status?: {
      color: ListStatusItemContentProps['status'];
      label: string;
    };
  }>;

export const roleOrder: ReadonlyArray<RecipientListManagerRole> = Object.freeze([
  ManagerRole.ActiveBookrunner,
  ManagerRole.Bookrunner,
  ManagerRole.CoLead,
  ManagerRole.CoManager,
  ManagerRole.Underwriter,
  ManagerRole.SellingGroupMember,
]);

/**
 * Sorts managers by their name and groups them by their role. Groups of managers are than sorted by the role order defined in ECM-12046.
 * Useful when rendering a list of managers grouped by roles.
 */
export function recipientsToRoleGroupedEntries<
  TManagerRole extends RecipientListManagerRole,
  T extends RecipientBase<TManagerRole>
>(managers: T[]): Array<[TManagerRole, T[]]> {
  const sortedManagers = [...managers].sort((a, b) => sortCaseInsensitive(a.firmName, b.firmName));

  return (Object.entries(groupBy(sortedManagers, 'role')) as Array<[TManagerRole, T[]]>).sort(
    ([A], [B]) => roleOrder.indexOf(A) - roleOrder.indexOf(B)
  );
}
