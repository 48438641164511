/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_HouseAccountPartsFragment = {
  readonly __typename?: 'HouseAccount';
  readonly id: string;
  readonly investorFirmName: string;
  readonly bankInvestorKey?: string | null;
  readonly cmgEntityKey: string;
  readonly firmId: string;
  readonly investorAddress?: string | null;
};

export type SalesCredits_HouseAccountsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SalesCredits_HouseAccountsQuery = {
  readonly __typename?: 'Query';
  readonly salesCreditsHouseAccounts: ReadonlyArray<{
    readonly __typename?: 'HouseAccount';
    readonly id: string;
    readonly investorFirmName: string;
    readonly bankInvestorKey?: string | null;
    readonly cmgEntityKey: string;
    readonly firmId: string;
    readonly investorAddress?: string | null;
  }>;
};

export type SalesCredits_UpdateHouseAccountsMutationVariables = Types.Exact<{
  investorsFirmIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;

export type SalesCredits_UpdateHouseAccountsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSalesCreditsHouseAccounts: ReadonlyArray<{
    readonly __typename?: 'HouseAccount';
    readonly id: string;
    readonly investorFirmName: string;
    readonly bankInvestorKey?: string | null;
    readonly cmgEntityKey: string;
    readonly firmId: string;
    readonly investorAddress?: string | null;
  }>;
};

export const SalesCredits_HouseAccountPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SalesCredits_HouseAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesCredits_HouseAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCreditsHouseAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesCredits_HouseAccountsQuery__
 *
 * To run a query within a React component, call `useSalesCredits_HouseAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_HouseAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCredits_HouseAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSalesCredits_HouseAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SalesCredits_HouseAccountsQuery,
    SalesCredits_HouseAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesCredits_HouseAccountsQuery, SalesCredits_HouseAccountsQueryVariables>(
    SalesCredits_HouseAccountsDocument,
    options
  );
}
export function useSalesCredits_HouseAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCredits_HouseAccountsQuery,
    SalesCredits_HouseAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesCredits_HouseAccountsQuery,
    SalesCredits_HouseAccountsQueryVariables
  >(SalesCredits_HouseAccountsDocument, options);
}
export type SalesCredits_HouseAccountsQueryHookResult = ReturnType<
  typeof useSalesCredits_HouseAccountsQuery
>;
export type SalesCredits_HouseAccountsLazyQueryHookResult = ReturnType<
  typeof useSalesCredits_HouseAccountsLazyQuery
>;
export type SalesCredits_HouseAccountsQueryResult = Apollo.QueryResult<
  SalesCredits_HouseAccountsQuery,
  SalesCredits_HouseAccountsQueryVariables
>;
export const SalesCredits_UpdateHouseAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SalesCredits_UpdateHouseAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorsFirmIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalesCreditsHouseAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorsFirmIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorsFirmIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SalesCredits_UpdateHouseAccountsMutationFn = Apollo.MutationFunction<
  SalesCredits_UpdateHouseAccountsMutation,
  SalesCredits_UpdateHouseAccountsMutationVariables
>;

/**
 * __useSalesCredits_UpdateHouseAccountsMutation__
 *
 * To run a mutation, you first call `useSalesCredits_UpdateHouseAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_UpdateHouseAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesCreditsUpdateHouseAccountsMutation, { data, loading, error }] = useSalesCredits_UpdateHouseAccountsMutation({
 *   variables: {
 *      investorsFirmIds: // value for 'investorsFirmIds'
 *   },
 * });
 */
export function useSalesCredits_UpdateHouseAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesCredits_UpdateHouseAccountsMutation,
    SalesCredits_UpdateHouseAccountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesCredits_UpdateHouseAccountsMutation,
    SalesCredits_UpdateHouseAccountsMutationVariables
  >(SalesCredits_UpdateHouseAccountsDocument, options);
}
export type SalesCredits_UpdateHouseAccountsMutationHookResult = ReturnType<
  typeof useSalesCredits_UpdateHouseAccountsMutation
>;
export type SalesCredits_UpdateHouseAccountsMutationResult =
  Apollo.MutationResult<SalesCredits_UpdateHouseAccountsMutation>;
export type SalesCredits_UpdateHouseAccountsMutationOptions = Apollo.BaseMutationOptions<
  SalesCredits_UpdateHouseAccountsMutation,
  SalesCredits_UpdateHouseAccountsMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeSalesCredits_HouseAccountsMockResponse = makeMockResponse<
  SalesCredits_HouseAccountsQueryVariables,
  SalesCredits_HouseAccountsQuery
>(SalesCredits_HouseAccountsDocument);
