import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const dtcSchema = yup
  .string()
  .nullable()
  .test(
    'input-is-dtc',
    ({ label }) => `${label} is a 4-digit number`,
    value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      return /^\d{4}$/.test(value);
    }
  );

export const recipientUserDataValidationSchema = yup
  .object()
  .shape({
    recipient: yup.string().label('Recipient').required(),
    payment: yup.number().nullable().label('Payment').min(0).required(),
    recipientDtc: dtcSchema.label('Recipient DTC Number'),
  })
  .nullable();

export const validationSchema = yup
  .object()
  .shape({
    recipientsUserData: yup
      .array()
      .of(recipientUserDataValidationSchema)
      .label('Recipients')
      .min(1)
      .required()
      .test('non-nullable-data', 'At least one manager must have user data', value => {
        return !!value?.some(data => data !== null);
      }),
    senderDtc: dtcSchema.label('Sender DTC Number').required(),
  })
  .concat(wiresFormDisclaimerSchema);

export type ManagerBdRecipientUserData = {
  recipient: string;
  payment: number | null;
  recipientDtc: string | null | undefined;
};

export type Values = {
  disclaimer: string | null;
  senderDtc: string | null;
  recipientsUserData: (ManagerBdRecipientUserData | null)[];
};
