import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DesignationWiresDocument,
  SyndicateWires_SendDesignationWireMutation,
  useSyndicateWires_SendDesignationWireMutation,
} from '../graphql';

export type SendDesignationWireMutation = SyndicateWires_SendDesignationWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_DesignationWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useSendDesignationWireMutation = () => {
  return useSyndicateWires_SendDesignationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
