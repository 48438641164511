import { UUID } from '@cmg/common';
import {
  Box,
  CancelOutlinedIcon,
  CheckCircleOutline,
  Divider,
  LoadingButton,
  Stack,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { OfferingStatus, OrderBookState } from '../../../../../graphql';
import { DemandGridV2Switch } from '../../../components/demand-grid-v2-switch/DemandGridV2Switch';
import { OrderBookStatusDropdownButton } from '../order-book-status-dropdown-button/OrderBookStatusDropdownButton';

export type Props = Readonly<{
  canManageAllocations: boolean;
  isFinalAllocationSetReleased: boolean;
  isReleaseFinalAllocationSetLoading: boolean;
  offeringId: UUID;
  orderBookState: OrderBookState | undefined;
  offeringStatus: OfferingStatus | undefined;
  isUpdateOrderBookStatusLoading: boolean;
  onReleaseFinalAllocations: () => void;
  onUpdateOrderBookStatus: () => void;
  isVisibleToNonSyndicate: boolean;
  orderBookSalesState: OrderBookState | undefined;
  onUpdateOrderBookSalesState: () => void;
}>;

export const SubNavActionButtons: React.FC<Props> = ({
  offeringId,
  offeringStatus,
  orderBookState,
  isFinalAllocationSetReleased,
  isUpdateOrderBookStatusLoading,
  isReleaseFinalAllocationSetLoading,
  canManageAllocations,
  onReleaseFinalAllocations,
  onUpdateOrderBookStatus,
  isVisibleToNonSyndicate,
  orderBookSalesState,
  onUpdateOrderBookSalesState,
}) => {
  const isReleaseAllocationsDisabled =
    orderBookState !== OrderBookState.Closed ||
    offeringStatus !== OfferingStatus.Priced ||
    isReleaseFinalAllocationSetLoading;

  const canUnreleaseFinalAllocation = isFinalAllocationSetReleased && isVisibleToNonSyndicate;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <DemandGridV2Switch />
      <Divider orientation="vertical" flexItem sx={{ margin: theme => `${theme.spacing(1)} 0` }} />
      <OrderBookStatusDropdownButton
        offeringId={offeringId}
        onUpdateOrderBookStatus={onUpdateOrderBookStatus}
        orderBookState={orderBookState}
        orderBookSalesState={orderBookSalesState}
        onUpdateOrderBookSalesState={onUpdateOrderBookSalesState}
        offeringStatus={offeringStatus}
      />

      {isFinalAllocationSetReleased && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          data-test-id={xcSelectors.orderBookHeaderAllocationsReleasedStatus.testId}
        >
          <Typography variant="body2" color="text.secondary">
            Final Allocations:
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            {canUnreleaseFinalAllocation ? (
              <Tooltip
                placement="top"
                title="Final allocations are released and visible to Buy-side and S&T."
                arrow
              >
                <CheckCircleOutline fontSize="inherit" color="success" />
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title="Final allocations are not visible to Buy-side and S&T. Only Syndicate can see final allocations."
                arrow
              >
                <CancelOutlinedIcon fontSize="inherit" color="error" />
              </Tooltip>
            )}
            <Typography variant="body2">
              {canUnreleaseFinalAllocation ? 'Released' : 'Unreleased'}
            </Typography>
          </Stack>
        </Stack>
      )}
      {canManageAllocations && (
        <Tooltip
          placement="top"
          title="Controls visibility of allocations from Buy-side and S&T. Only Syndicate can see final allocations when unreleased."
          arrow
        >
          <Box display="flex">
            <LoadingButton
              variant="outlined"
              data-testid={xcSelectors.orderBookHeaderReleaseFinalAllocationsButton.testId}
              disabled={isReleaseAllocationsDisabled}
              loading={isReleaseFinalAllocationSetLoading}
              onClick={onReleaseFinalAllocations}
            >
              {canUnreleaseFinalAllocation ? 'Unrelease Allocations' : 'Release Allocations'}
            </LoadingButton>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
