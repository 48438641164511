import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { AllocationAcknowledgement } from '../../../../../../../graphql';
import { IndicationWithDemandLevels } from '../../types';
import { acknowledgementStatusTexts } from './AllocationAcknowledgementCell.model';

export const StyledStatusIndicatorIcon = styled(Icon)<{
  status: AllocationAcknowledgement;
}>`
  margin-right: 5px;
  color: ${({ status, theme }) => {
    if (status === AllocationAcknowledgement.Acknowledged) {
      return theme.brand.color.success;
    }

    if (status === AllocationAcknowledgement.Rejected) {
      return theme.brand.color.danger;
    }

    return theme.brand.color.dark;
  }};
`;

type Props = {
  data: IndicationWithDemandLevels;
};

const AllocationAcknowledgementCell: React.FC<Props> = ({ data }) => {
  return data.finalAllocation ? (
    <React.Fragment>
      <StyledStatusIndicatorIcon
        name="circle"
        variant="solid"
        size="sm"
        fixedWidth
        status={
          data.finalAllocation.investorReply?.status ?? AllocationAcknowledgement.Unacknowledged
        }
      />
      {
        acknowledgementStatusTexts[
          data.finalAllocation.investorReply?.status ?? AllocationAcknowledgement.Unacknowledged
        ]
      }
    </React.Fragment>
  ) : null;
};

export default AllocationAcknowledgementCell;
