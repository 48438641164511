import routeFactory from '../../common/util/routeFactory';

export type GetDefaultFinalSettlementUrlOptions = {
  offeringId;
  canViewDesignation: boolean;
  canViewExpense: boolean;
  canViewStabilization: boolean;
  canViewAccounting: boolean;
  canViewLetter: boolean;
};

export function getDefaultFinalSettlementUrl({
  offeringId,
  canViewDesignation,
  canViewExpense,
  canViewStabilization,
  canViewAccounting,
  canViewLetter,
}: GetDefaultFinalSettlementUrlOptions): string | null {
  if (canViewDesignation) {
    return routeFactory.finalSettlementDesignationMonitor.getUrlPath({
      offeringId,
    });
  }

  if (canViewExpense) {
    return routeFactory.finalSettlementExpenses.getUrlPath({
      offeringId,
    });
  }

  if (canViewStabilization) {
    return routeFactory.finalSettlementStabilization.getUrlPath({
      offeringId,
    });
  }

  if (canViewAccounting) {
    return routeFactory.finalSettlementAccounting.getUrlPath({
      offeringId,
    });
  }

  if (canViewLetter) {
    return routeFactory.finalSettlementSettlementLetters.getUrlPath({
      offeringId,
    });
  }

  return null;
}
