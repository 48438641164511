import { UUID } from '@cmg/common';

import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';
import { SyndicateWires_SyndicateWiresManagerPartsFragment } from '../graphql';
import { useSyndicateManagerListQuery } from './useSyndicateManagerListQuery';

export function createUseDefaultIsWireManagerListOutdated(
  filter: (manager: SyndicateWires_SyndicateWiresManagerPartsFragment) => boolean = () => true
) {
  return ({
    offeringId,
    syndicateWireId,
  }: {
    offeringId: UUID;
    syndicateWireId: UUID;
  }): boolean | undefined => {
    const { useManagers } = useWireTypeConfigContext();

    const managerListQuery = useSyndicateManagerListQuery({ offeringId }, 'cache-first');
    const wireManagersQuery = useManagers({ offeringId, syndicateWireId });

    if (
      managerListQuery.loading ||
      wireManagersQuery.isLoading ||
      managerListQuery.error ||
      wireManagersQuery.error
    ) {
      return undefined;
    }

    const wireManagers = wireManagersQuery.data ?? [];
    const offeringManagers = (managerListQuery.data ?? []).filter(filter);

    return (
      wireManagers.length !== offeringManagers.length ||
      wireManagers.some(wireManager =>
        offeringManagers.every(
          offeringManager => offeringManager.cmgEntityKey !== wireManager.cmgEntityKey
        )
      )
    );
  };
}
