import { numericUtil, Theme } from '@cmg/common';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';

import { expensesManagerRoleMapping } from '../../../../common/ExpensesManagerRoleMapping';
import { FinalSettlement_AccountingManagerPartsFragment } from '../../../graphql';
import TotalCellRenderer from '../total-cell-renderer/TotalCellRenderer';

export const createAccountingColumn = ({
  field,
  headerName,
  valueFormatter,
  valueGetter,
  cellAlign = 'left',
  minWidth = 200,
}: {
  field: string;
  headerName: string;
  valueFormatter?: (params: ValueFormatterParams) => string;
  valueGetter?: (params: ValueGetterParams) => string | number;
  cellAlign?: 'right' | 'left';
  minWidth?: number;
}): ColDef => ({
  colId: field,
  field,
  headerName,
  minWidth,
  suppressAutoSize: true,
  sortable: false,
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.2,
    justifyContent: cellAlign,
  },
  headerClass: cellAlign === 'right' ? 'ag-header-right' : undefined,
  valueGetter,
  valueFormatter,
  pinnedRowCellRendererFramework: TotalCellRenderer,
  lockPinned: true,
});

export const createAccountingFixedColumn = ({
  field,
  headerName,
  pinned,
  theme,
  valueFormatter,
  cellAlign = 'left',
}: {
  field: string;
  headerName: string;
  pinned: 'left' | 'right';
  theme: Theme;
  valueFormatter?: (params: ValueFormatterParams) => string;
  cellAlign?: 'right' | 'left';
}): ColDef => {
  const column = createAccountingColumn({
    field,
    headerName,
    valueFormatter,
    cellAlign,
    minWidth: 270,
  });
  const fixedColumn = { ...column, pinned };

  if (pinned === 'left') {
    return {
      ...fixedColumn,
      cellStyle: {
        ...fixedColumn.cellStyle,
        borderRight: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
      },
    };
  }
  return {
    ...fixedColumn,
    cellStyle: {
      ...fixedColumn.cellStyle,
      borderLeft: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
    },
  };
};

type AvailableTotalFields =
  | 'incentiveFeePercentage'
  | 'managementFeesPercentage'
  | 'baseUnderwritingPercentage'
  | 'potEconomics'
  | 'potEconomicsWithOverwrites'
  | 'designatedShares'
  | 'baseUnderwritingShares'
  | 'overallotmentUnderwritingShares'
  | 'retailRetention'
  | 'managementFees'
  | 'underwritingFees'
  | 'designationFees'
  | 'retailRetentionFees'
  | 'stabilizationGainLoss'
  | 'managerExpenseReimbursement'
  | 'dealExpenses'
  | 'netDealFees'
  | 'manualAdjustment'
  | 'finalSettlement';

export const getTotal = (
  rows: readonly FinalSettlement_AccountingManagerPartsFragment[],
  columnName: keyof Pick<FinalSettlement_AccountingManagerPartsFragment, AvailableTotalFields>
): number | null => {
  return numericUtil.sum(...rows.map(row => row[columnName]));
};

export const accountingGridPercentFormatter = ({ value }: { value: number }) => {
  return numericUtil.getDisplayValueForPercents(value, 4);
};

export const accountingGridIntegerFormatter = ({ value }: { value: number }) => {
  return numericUtil.getDisplayValueForInteger(value);
};

export const accountingGridCurrencyFormatter = ({ value }: { value: number }) => {
  return numericUtil.getDisplayValueForCurrency(value, 2);
};

export const accountingGridExpensesManagerRoleFormatter = ({ value }: { value: string }) => {
  return expensesManagerRoleMapping[value];
};
