import {
  SyndicateWires_UpdateRegMWireMutation,
  useSyndicateWires_UpdateRegMWireMutation,
} from '../graphql';

export type UpdateRegMWireMutation = SyndicateWires_UpdateRegMWireMutation;

export const useUpdateRegMWireMutation = () => {
  return useSyndicateWires_UpdateRegMWireMutation();
};
