/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_UpdateMyRetailMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.UpdateMyRetailInput;
}>;

export type OrderBook_UpdateMyRetailMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMyRetail: {
    readonly __typename?: 'MyRetailSummary';
    readonly id: string;
    readonly sellingConcession?: number | null;
    readonly totalRetailInfo: {
      readonly __typename?: 'TotalRetailInfo';
      readonly id: string;
      readonly submittedRetailDemand?: {
        readonly __typename?: 'TotalSubmittedRetailDemandInfo';
        readonly id: string;
        readonly shareQuantity: number;
        readonly submittedDateTime: string;
      } | null;
      readonly releasedRetailRetention?: {
        readonly __typename?: 'TotalRetentionInfo';
        readonly id: string;
        readonly shareQuantity: number;
        readonly releasedDateTime: string;
      } | null;
    };
    readonly brokerDealers: ReadonlyArray<{
      readonly __typename?: 'RetailBrokerDealer';
      readonly id: string;
      readonly sellingConcessionPercentage?: number | null;
      readonly firmCmgEntityKey?: string | null;
      readonly firmName: string;
      readonly isOnPlatform: boolean;
      readonly retention: {
        readonly __typename?: 'RetailBrokerDealerRetention';
        readonly id: string;
        readonly shareQuantity?: number | null;
        readonly roundLots?: number | null;
      };
      readonly demand: {
        readonly __typename?: 'RetailBrokerDealerDemand';
        readonly id: string;
        readonly shareQuantity: number;
        readonly roundLots?: number | null;
      };
    }>;
  };
};

export const OrderBook_UpdateMyRetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateMyRetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMyRetailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyRetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_MyRetailSummaryParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_MyRetailSummaryParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyRetailSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalRetailInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brokerDealers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedRetailDemand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasedRetailRetention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releasedDateTime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateMyRetailMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateMyRetailMutation,
  OrderBook_UpdateMyRetailMutationVariables
>;

/**
 * __useOrderBook_UpdateMyRetailMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateMyRetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateMyRetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateMyRetailMutation, { data, loading, error }] = useOrderBook_UpdateMyRetailMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_UpdateMyRetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateMyRetailMutation,
    OrderBook_UpdateMyRetailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateMyRetailMutation,
    OrderBook_UpdateMyRetailMutationVariables
  >(OrderBook_UpdateMyRetailDocument, options);
}
export type OrderBook_UpdateMyRetailMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateMyRetailMutation
>;
export type OrderBook_UpdateMyRetailMutationResult =
  Apollo.MutationResult<OrderBook_UpdateMyRetailMutation>;
export type OrderBook_UpdateMyRetailMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_UpdateMyRetailMutation,
  OrderBook_UpdateMyRetailMutationVariables
>;
