import { useCheckAccountTraits } from '@cmg/auth';
import { Popover, Switch } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const STestOfferingsFilter = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
`;

export const SSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 5px;

  & > div {
    margin-right: 10px;
  }
`;

export type Props = Readonly<{
  onChange: (value: boolean) => void;
  checked: boolean;
}>;
/**
 * TestOfferingsFilter is a switch toggle that filters the calendar data by whether
 * the offering is a test offering.
 */
const TestOfferingsFilter: React.FC<Props> = ({ onChange, checked }) => {
  const canViewTestOfferings = useCheckAccountTraits(['XC_TEST_OFFERINGS_IN_CALENDAR']);

  return canViewTestOfferings ? (
    <STestOfferingsFilter>
      <Popover
        variant="TOOLTIP"
        placement="topRight"
        content="Include offerings that are test offerings"
      >
        <SSwitchWrapper>
          <Switch onChange={onChange} checkedContent="On" unCheckedContent="Off" value={checked} />
          <div>Test Offerings</div>
        </SSwitchWrapper>
      </Popover>
    </STestOfferingsFilter>
  ) : null;
};

export default TestOfferingsFilter;
