import React from 'react';

import AttestationFormStatusRenderer from '../../../../../attestation/AttestationFormStatusRenderer';
import { IndicationWithDemandLevels } from '../../types';

export type Props = {
  data: IndicationWithDemandLevels;
};

const AttestationStatusCell: React.FC<Props> = ({ data }) => {
  const status = data.attestationStatus?.status;

  return <AttestationFormStatusRenderer status={status} />;
};

export default AttestationStatusCell;
