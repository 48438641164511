import { isValidCmgEntityKey } from '../../../../../../../../common/util/isValidCmgEntityKey';
import { AttestationFormStatus, OfferingType } from '../../../../../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { OrderBookPersonaType } from '../../../../../hooks/useIdentifyOrderBookPersona';
import { useSyndicateAttestationQuery } from '../../syndicate/useAttestationQuery';
import { CertificateModel, PersonaProps } from '../useCertificatesModel';

export type Props = PersonaProps & {
  offeringId: string;
  cmgEntityKey: string | undefined;
};

export const COMPLIANCE_WARNING_MESSAGE_SYNDICATE =
  'Please review the outstanding requirements in the Compliance section.';

export const COMPLIANCE_TOOLTIP_WARNING_MESSAGE_SYNDICATE = `5130/31 is required to participate in this offering. Click Copy link and provide the link to the investor to complete.`;

export const useSyndicateCertificatesModel = ({
  type,
  offeringId,
  cmgEntityKey,
  isOrderBookAttestationOn,
}: Props): CertificateModel => {
  const isSyndicate = type === OrderBookPersonaType.SYNDICATE;
  // Attestation
  const attestationQuery = useSyndicateAttestationQuery(
    { offeringId, cmgEntityKey: cmgEntityKey! },
    {
      skip:
        !isValidCmgEntityKey(cmgEntityKey) ||
        !isSyndicate ||
        !isOrderBookAttestationOn ||
        !offeringId,
    }
  );
  const { attestation = null, basicPublishedOffering = null } = attestationQuery.data ?? {};
  const { type: offeringType } = basicPublishedOffering || {};

  const hasComplianceWarning =
    (attestation?.formStatus === AttestationFormStatus.Expired ||
      attestation?.formStatus === AttestationFormStatus.NotOnFile) &&
    offeringType === OfferingType.Ipo;

  const redirectUrl = cmgEntityKey
    ? offeringSidePanelSellSideRouteFactory.orderBookCompliance.getUrlPath({
        cmgEntityKey: cmgEntityKey,
      })
    : null;

  return {
    canRead: !!cmgEntityKey && isSyndicate,
    attestation,
    loading: attestationQuery.loading,
    offeringType: offeringType,
    warning: {
      hasComplianceWarning,
      canByPassComplianceWarning: true,
      message: COMPLIANCE_WARNING_MESSAGE_SYNDICATE,
      redirectUrl,
      tooltipMessage: COMPLIANCE_TOOLTIP_WARNING_MESSAGE_SYNDICATE,
    },
  };
};
