import styled, { css } from 'styled-components/macro';

export const SPanel = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;

export const SRoleHeader = styled.div`
  background: ${({ theme }) => theme.border.color.light};
  padding: 8px;
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SManager = styled.div<{ isActive?: boolean }>`
  padding: 8px 0;
  border-radius: ${({ theme }) => theme.border.radius.small};
  display: grid;
  cursor: pointer;
  grid-template-areas:
    'icon name'
    'empty status';
  column-gap: 8px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.designSystem.colors.blue['300'] : 'transparent'};
`;

export const SManagerIcon = styled.div`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 1em;
`;

export const SManagerNameContainer = styled.span`
  grid-area: name;
  display: flex;
  flex-direction: row;
  width: 200px;
`;

export const SManagerName = styled.span<{ isActive?: boolean }>`
  flex-grow: 1;
  ${({ theme, isActive }) => {
    if (isActive) {
      return css`
        color: ${theme.text.color.white};
      `;
    }
  }};
`;

export const SManagerStatus = styled.div<{ isActive?: boolean }>`
  grid-area: status;
  font-size: ${({ theme }) => theme.text.size.xxs};
  color: ${({ theme, isActive }) =>
    isActive ? theme.text.color.white : theme.designSystem.colors.gray['500']};
`;

export const SManagerList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0;
`;
