import { CurrencyInputField, numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { managerRoleLabels } from '../../../../../../types/domain/manager/constants';
import { StyledCell, StyledRow } from '../../../ExpensesRoute.styles';
import { FinalSettlement_ExpensesQuery } from '../../../graphql';

export type Props = {
  index: number;
  manager: FinalSettlement_ExpensesQuery['expenses']['managers'][number];
  total: number | null;
};

const EditableManagerExpensesRow: React.FC<Props> = ({ index, manager, total }) => {
  return (
    <StyledRow suppressHighlightingOnHover isFocused={false}>
      <Table.Cell>{manager.name}</Table.Cell>
      <Table.Cell>{managerRoleLabels[manager.role]}</Table.Cell>
      <Table.Cell>
        <CurrencyInputField name={`managerExpenses.${index}.travelAndMeals`} fullWidth />
      </Table.Cell>
      <Table.Cell>
        <CurrencyInputField name={`managerExpenses.${index}.technologyAndData`} fullWidth />
      </Table.Cell>
      <Table.Cell>
        <CurrencyInputField name={`managerExpenses.${index}.miscellaneous`} fullWidth />
      </Table.Cell>
      <StyledCell bold>{numericUtil.getDisplayValueForCurrency(total)}</StyledCell>
      <Table.Cell>
        <CurrencyInputField name={`managerExpenses.${index}.reimbursement`} fullWidth />
      </Table.Cell>
    </StyledRow>
  );
};

export default EditableManagerExpensesRow;
