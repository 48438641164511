import { useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery } from '../../../../../graphql/__generated__';
import { useCertificateQueryParams } from '../../../../../hooks/useCertificateQueryParams';

const useGetCertificateData = () => {
  const { cmgEntityKey, crmInvestorId, source } = useCertificateQueryParams();

  const { data, loading } = useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery({
    variables: {
      cmgEntityKey,
      crmInvestorId,
      source,
    },
    skip: !cmgEntityKey && !crmInvestorId,
  });

  return {
    firmCertificate: data?.firmCertificatesByCmgEntityKeyAndInvestorId,
    pastCertificates: data?.pastFirmCertificatesByCmgEntityKey,
    loading,
  };
};

export default useGetCertificateData;
