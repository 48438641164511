/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CreateRoadshowManagementTeamMemberMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  payload: Types.ManagementTeamMemberInput;
}>;

export type Roadshows_CreateRoadshowManagementTeamMemberMutation = {
  readonly __typename?: 'Mutation';
  readonly createRoadshowManagementTeamMember: {
    readonly __typename?: 'ManagementTeamMember';
    readonly id: string;
    readonly name: string;
    readonly title?: string | null;
    readonly officePhone?: string | null;
    readonly mobilePhone?: string | null;
    readonly email: string;
    readonly isParticipating: boolean;
  };
};

export type Roadshows_DeleteRoadshowManagementTeamMemberMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  teamMemberId: Types.Scalars['UUID'];
}>;

export type Roadshows_DeleteRoadshowManagementTeamMemberMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteRoadshowManagementTeamMember: string;
};

export type Roadshows_UpdateRoadshowManagementTeamMemberMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  teamMemberId: Types.Scalars['UUID'];
  payload: Types.ManagementTeamMemberInput;
}>;

export type Roadshows_UpdateRoadshowManagementTeamMemberMutation = {
  readonly __typename?: 'Mutation';
  readonly updateRoadshowManagementTeamMember: {
    readonly __typename?: 'ManagementTeamMember';
    readonly id: string;
    readonly name: string;
    readonly title?: string | null;
    readonly officePhone?: string | null;
    readonly mobilePhone?: string | null;
    readonly email: string;
    readonly isParticipating: boolean;
  };
};

export const Roadshows_CreateRoadshowManagementTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CreateRoadshowManagementTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMemberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoadshowManagementTeamMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CreateRoadshowManagementTeamMemberMutationFn = Apollo.MutationFunction<
  Roadshows_CreateRoadshowManagementTeamMemberMutation,
  Roadshows_CreateRoadshowManagementTeamMemberMutationVariables
>;

/**
 * __useRoadshows_CreateRoadshowManagementTeamMemberMutation__
 *
 * To run a mutation, you first call `useRoadshows_CreateRoadshowManagementTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CreateRoadshowManagementTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCreateRoadshowManagementTeamMemberMutation, { data, loading, error }] = useRoadshows_CreateRoadshowManagementTeamMemberMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_CreateRoadshowManagementTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CreateRoadshowManagementTeamMemberMutation,
    Roadshows_CreateRoadshowManagementTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CreateRoadshowManagementTeamMemberMutation,
    Roadshows_CreateRoadshowManagementTeamMemberMutationVariables
  >(Roadshows_CreateRoadshowManagementTeamMemberDocument, options);
}
export type Roadshows_CreateRoadshowManagementTeamMemberMutationHookResult = ReturnType<
  typeof useRoadshows_CreateRoadshowManagementTeamMemberMutation
>;
export type Roadshows_CreateRoadshowManagementTeamMemberMutationResult =
  Apollo.MutationResult<Roadshows_CreateRoadshowManagementTeamMemberMutation>;
export type Roadshows_CreateRoadshowManagementTeamMemberMutationOptions =
  Apollo.BaseMutationOptions<
    Roadshows_CreateRoadshowManagementTeamMemberMutation,
    Roadshows_CreateRoadshowManagementTeamMemberMutationVariables
  >;
export const Roadshows_DeleteRoadshowManagementTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_DeleteRoadshowManagementTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamMemberId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRoadshowManagementTeamMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamMemberId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamMemberId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_DeleteRoadshowManagementTeamMemberMutationFn = Apollo.MutationFunction<
  Roadshows_DeleteRoadshowManagementTeamMemberMutation,
  Roadshows_DeleteRoadshowManagementTeamMemberMutationVariables
>;

/**
 * __useRoadshows_DeleteRoadshowManagementTeamMemberMutation__
 *
 * To run a mutation, you first call `useRoadshows_DeleteRoadshowManagementTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DeleteRoadshowManagementTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsDeleteRoadshowManagementTeamMemberMutation, { data, loading, error }] = useRoadshows_DeleteRoadshowManagementTeamMemberMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useRoadshows_DeleteRoadshowManagementTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_DeleteRoadshowManagementTeamMemberMutation,
    Roadshows_DeleteRoadshowManagementTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_DeleteRoadshowManagementTeamMemberMutation,
    Roadshows_DeleteRoadshowManagementTeamMemberMutationVariables
  >(Roadshows_DeleteRoadshowManagementTeamMemberDocument, options);
}
export type Roadshows_DeleteRoadshowManagementTeamMemberMutationHookResult = ReturnType<
  typeof useRoadshows_DeleteRoadshowManagementTeamMemberMutation
>;
export type Roadshows_DeleteRoadshowManagementTeamMemberMutationResult =
  Apollo.MutationResult<Roadshows_DeleteRoadshowManagementTeamMemberMutation>;
export type Roadshows_DeleteRoadshowManagementTeamMemberMutationOptions =
  Apollo.BaseMutationOptions<
    Roadshows_DeleteRoadshowManagementTeamMemberMutation,
    Roadshows_DeleteRoadshowManagementTeamMemberMutationVariables
  >;
export const Roadshows_UpdateRoadshowManagementTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateRoadshowManagementTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamMemberId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMemberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoadshowManagementTeamMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamMemberId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamMemberId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateRoadshowManagementTeamMemberMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateRoadshowManagementTeamMemberMutation,
  Roadshows_UpdateRoadshowManagementTeamMemberMutationVariables
>;

/**
 * __useRoadshows_UpdateRoadshowManagementTeamMemberMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateRoadshowManagementTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateRoadshowManagementTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateRoadshowManagementTeamMemberMutation, { data, loading, error }] = useRoadshows_UpdateRoadshowManagementTeamMemberMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      teamMemberId: // value for 'teamMemberId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateRoadshowManagementTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateRoadshowManagementTeamMemberMutation,
    Roadshows_UpdateRoadshowManagementTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateRoadshowManagementTeamMemberMutation,
    Roadshows_UpdateRoadshowManagementTeamMemberMutationVariables
  >(Roadshows_UpdateRoadshowManagementTeamMemberDocument, options);
}
export type Roadshows_UpdateRoadshowManagementTeamMemberMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateRoadshowManagementTeamMemberMutation
>;
export type Roadshows_UpdateRoadshowManagementTeamMemberMutationResult =
  Apollo.MutationResult<Roadshows_UpdateRoadshowManagementTeamMemberMutation>;
export type Roadshows_UpdateRoadshowManagementTeamMemberMutationOptions =
  Apollo.BaseMutationOptions<
    Roadshows_UpdateRoadshowManagementTeamMemberMutation,
    Roadshows_UpdateRoadshowManagementTeamMemberMutationVariables
  >;
