import { FormField, numericUtil, timeUtil } from '@cmg/common';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';

import Column from '../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../common/components/layout/grid-layout/GridLayout';
import { getLabelFromOptions } from '../../../../common/form-utils';
import {
  currencyOptions,
  isLastSaleBeforeDistributionRequired,
} from '../../form/TNFilingForm.model';
import { RegulatoryFilings_TnFilingPartsFragment } from '../../graphql';

export type Props = {
  filing: RegulatoryFilings_TnFilingPartsFragment;
};

const NonATMTradeDetail: React.FC<Props> = ({ filing }) => {
  return (
    <div>
      <GridLayout>
        <Column span={3}>
          <FormField withMargin fullWidth label="Number of Shares">
            <div aria-label="Number of Shares">
              {numericUtil.formatInteger(filing.nonAtmTradeInfo?.numberOfShares)}
            </div>
          </FormField>
        </Column>
        <Column span={3}>
          <FormField withMargin fullWidth label="Offering Price">
            <div aria-label="Offering Price">
              {numericUtil.formatCurrency(filing.nonAtmTradeInfo?.offeringPrice, 6)}
            </div>
          </FormField>
        </Column>
        <Column span={3}>
          <FormField withMargin fullWidth label="Currency">
            <div aria-label="Currency">
              {getLabelFromOptions(filing.nonAtmTradeInfo?.currency, currencyOptions)}
            </div>
          </FormField>
        </Column>
        <Column span={3}>
          <FormField withMargin fullWidth label="Pricing Date">
            <div aria-label="Pricing Date">
              {filing.nonAtmTradeInfo?.pricingDate &&
                timeUtil.formatAsDisplayDate(filing.nonAtmTradeInfo?.pricingDate)}
            </div>
          </FormField>
        </Column>
      </GridLayout>
      <GridLayout>
        <Column span={3}>
          <FormField withMargin fullWidth label="SEC Effective Date and Time">
            <div aria-label="SEC Effective Date and Time">
              {filing.nonAtmTradeInfo?.secEffectiveDateTime &&
                filing.nonAtmTradeInfo?.secEffectiveTimezone &&
                timeUtil.formatAsDisplayDateTime(
                  utcToZonedTime(
                    filing.nonAtmTradeInfo?.secEffectiveDateTime,
                    filing.nonAtmTradeInfo?.secEffectiveTimezone
                  )
                )}
            </div>
          </FormField>
        </Column>
        <Column span={3}>
          <FormField withMargin fullWidth label="Trade Date">
            <div aria-label="Trade Date">
              {filing.nonAtmTradeInfo?.tradeDate &&
                timeUtil.formatAsDisplayDate(filing.nonAtmTradeInfo?.tradeDate)}
            </div>
          </FormField>
        </Column>
      </GridLayout>
      {isLastSaleBeforeDistributionRequired(filing.transactionType) && (
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Last Sale Before Distribution Price">
              <div aria-label="Last Sale Before Distribution Price">
                {numericUtil.formatCurrency(
                  filing.nonAtmTradeInfo?.lastSaleBeforeDistributionPrice,
                  6
                )}
              </div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Last Sale Before Distribution Currency">
              <div aria-label="Last Sale Before Distribution Currency">
                {getLabelFromOptions(
                  filing.nonAtmTradeInfo?.lastSaleBeforeDistributionCurrency,
                  currencyOptions
                )}
              </div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Last Sale Before Distribution Date">
              <div aria-label="Last Sale Before Distribution Date">
                {filing.nonAtmTradeInfo?.lastSaleBeforeDistributionDate &&
                  timeUtil.formatAsDisplayDate(
                    filing.nonAtmTradeInfo?.lastSaleBeforeDistributionDate
                  )}
              </div>
            </FormField>
          </Column>
        </GridLayout>
      )}
    </div>
  );
};

export default NonATMTradeDetail;
