import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ShareActivityRow from './ShareActivityRow';
import { Props } from './ShareActivityTooltip';
import {
  STooltipHeader,
  STooltipTable,
  STooltipTableContent,
  STooltipTableHeader,
  STooltipTableHeaderCell,
} from './ShareActivityTooltip.styles';

const ShareTooltipContent = ({ shareHistory }: Props) => (
  <div data-test-id={xcSelectors.myOfferingsDraftOfferingShareHistoryTooltip.testId}>
    <STooltipHeader>Shared with:</STooltipHeader>
    <STooltipTable>
      <STooltipTableHeader>
        <STooltipTableHeaderCell>UNDERWRITER</STooltipTableHeaderCell>
        <STooltipTableHeaderCell>SHARED ON</STooltipTableHeaderCell>
      </STooltipTableHeader>
      <STooltipTableContent>
        {shareHistory.map((shareActivity, index) => {
          return (
            <ShareActivityRow key={`${index}-${shareActivity.shareDatetime}`} {...shareActivity} />
          );
        })}
      </STooltipTableContent>
    </STooltipTable>
  </div>
);

export default ShareTooltipContent;
