import { dateTimeUtil, numericUtil } from '@cmg/common';
import type { ValueFormatterFunc } from '@cmg/data-grid';
import { formatters, getSelectableCol } from '@cmg/data-grid';

import type {
  OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
} from '../../../graphql/__generated__';
import { DemandGridRowData } from '../../types';
import { getCellRendererSelector } from '../columns.model';
import { InvestorCellRenderer } from './InvestorCellRenderer';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['investor']['displayName'];

type GroupValueFormatterFunc = ValueFormatterFunc<
  OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
  string | null | undefined
>;

const groupKeyEnumValueFormatter: GroupValueFormatterFunc = ({ data, ...params }) => {
  return formatters.enumValueFormatter({ ...params, data, value: data?.groupKey });
};

export const GroupColValueFormatters = {
  attestationStatus: params => {
    const { data } = params;
    return data?.groupKey ? groupKeyEnumValueFormatter(params) : 'Not on file';
  },
  bndAgent: ({ data }) => data?.groupKey ?? 'Unassigned',
  'auditInfo.createdAt': ({ data }) => {
    return dateTimeUtil.getDisplayValueForDate(data?.groupKey ?? '');
  },
  'prospectusDeliverySummary.overallStatusDisplayValue': groupKeyEnumValueFormatter,
} satisfies Record<string, GroupValueFormatterFunc>;

/**
 * Exported for testing purposes
 */
export const valueFormatter: ValueFormatterFunc<DemandGridRowData, Value> = params => {
  const { data, api, value } = params;

  if (data?.__typename === 'SyndicateGridTotalsRow') {
    return `Rows: ${numericUtil.getDisplayValueForInteger(data.indicationCount)}`;
  }

  if (data?.__typename === 'Group') {
    const formattedGroupKey = data.groupKey ?? 'Empty value';
    const [rowGroupCol] = api.getRowGroupColumns();
    const valueFormatter = GroupColValueFormatters[rowGroupCol.getColId()];

    return valueFormatter ? valueFormatter(params) : formattedGroupKey;
  }

  return value ?? '-';
};

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: {
    component: 'agGroupCellRenderer',
    params: {
      innerRenderer: InvestorCellRenderer,
    },
  },
  groupRow: {
    component: 'agGroupCellRenderer',
  },
});

/**
 * Investor is used as auto-group column
 */
const investorColDef = {
  ...getSelectableCol<DemandGridRowData, Value>({
    field: 'investor.displayName',
    headerName: 'Investor',
    pinned: 'left',
    minWidth: 220,
    valueFormatter,
    cellRendererSelector,
  }),
  server: {
    sortFieldPath: 'investor.displayName',
    filterFieldPath: 'investor.displayName',
  },
};

export default investorColDef;
