import { ButtonsContainer, Icon, SecondaryButton, SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { useFormikContext } from 'formik';
import React from 'react';

import ButtonTooltip from '../../../../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import SideBarSectionHeader from '../../../../../components/side-bar-header/SideBarHeader';
import { useSupersededOfferingContext } from '../../../../../contexts/SupersededOfferingContext';
import { ViewMode } from '../../../../constants';
import { StyledActionsContainer, StyledTitle } from './OfferingNoteBar.styles';

type OfferingNoteBarProps = {
  viewMode: ViewMode;
  handleChangeViewMode: (newViewMode: ViewMode) => void;
  loading?: boolean;
  handleConfirmDeleteNote: () => void;
  handleSubmitForm: () => void;
};

const OfferingNoteBar: React.FC<OfferingNoteBarProps> = ({
  viewMode,
  handleChangeViewMode,
  loading,
  handleConfirmDeleteNote,
  handleSubmitForm,
}) => {
  const { resetForm } = useFormikContext();

  const { isObsoleteOffering } = useSupersededOfferingContext();

  const handleShowCreateNoteForm = () => {
    handleChangeViewMode(ViewMode.Create);
  };

  const handleDiscardChanges = () => {
    resetForm();
    handleChangeViewMode(ViewMode.View);
  };

  const renderButtons = () => {
    switch (viewMode) {
      case ViewMode.Create:
      case ViewMode.Edit:
        return (
          <React.Fragment>
            <SecondaryButton
              onClick={handleDiscardChanges}
              disabled={loading}
              testId={xcSelectors.offeringNotesDiscardNoteChangesButton.testId}
            >
              Discard Changes
            </SecondaryButton>
            <SuccessButton
              onClick={handleSubmitForm}
              loading={loading}
              testId={xcSelectors.offeringNotesSaveNoteChangesButton.testId}
            >
              Save
            </SuccessButton>
          </React.Fragment>
        );

      case ViewMode.Delete:
        return (
          <React.Fragment>
            <SecondaryButton
              onClick={handleDiscardChanges}
              disabled={loading}
              testId={xcSelectors.offeringNotesCancelDeleteNoteButton.testId}
            >
              No
            </SecondaryButton>
            <SuccessButton
              onClick={handleConfirmDeleteNote}
              loading={loading}
              testId={xcSelectors.offeringNotesConfirmDeleteNoteButton.testId}
            >
              Yes
            </SuccessButton>
          </React.Fragment>
        );

      default:
        return (
          <ButtonTooltip
            variant="TOOLTIP"
            placement="top"
            disabled={!isObsoleteOffering}
            hideArrow
            content="A new note cannot be created for an obsolete offering"
          >
            <SuccessButton
              onClick={handleShowCreateNoteForm}
              testId={xcSelectors.offeringNotesCreateNoteButton.testId}
              disabled={isObsoleteOffering}
            >
              Create Note
            </SuccessButton>
          </ButtonTooltip>
        );
    }
  };

  const getOfferingBarTitle = () => {
    switch (viewMode) {
      case ViewMode.Create:
        return 'Create New Note';

      case ViewMode.Edit:
        return 'Edit Note';

      case ViewMode.Delete:
        return 'Delete Note';

      default:
        return null;
    }
  };

  return (
    <SideBarSectionHeader title="Notes">
      {viewMode !== ViewMode.View && (
        <React.Fragment>
          <Icon name="chevron-right" variant="regular" />
          <StyledTitle>{getOfferingBarTitle()}</StyledTitle>
        </React.Fragment>
      )}
      <StyledActionsContainer>
        <ButtonsContainer>{renderButtons()}</ButtonsContainer>
      </StyledActionsContainer>
    </SideBarSectionHeader>
  );
};

export default OfferingNoteBar;
