import { CurrencyInputField, numericUtil, ODSTable as Table, TextInputField } from '@cmg/common';
import React from 'react';

import { AccountingManagerValues } from '../AccountingManualAdjustmentModal.model';
import { calculateFinalSettlement } from './ManualAdjustmentRow.model';

export type Props = {
  row: AccountingManagerValues;
  firmName: string;
  netDealFees: number | null | undefined;
  retailRetentionFees: number | null | undefined;
  index: number;
};

const ManualAdjustmentRow: React.FC<Props> = ({
  row,
  firmName,
  netDealFees,
  retailRetentionFees,
  index,
}) => {
  const finalSettlement = calculateFinalSettlement({
    netDealFees,
    retailRetentionFees,
    manualAdjustment: row.manualAdjustment,
  });

  return (
    <Table.Row isFocused={false} suppressHighlightingOnHover title={firmName}>
      <Table.Cell paddingSize="S" centered>
        {firmName}
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <CurrencyInputField name={`managers.${index}.manualAdjustment`} placeholder="$" fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField
          name={`managers.${index}.description`}
          placeholder="Enter Description"
          fullWidth
        />
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" centered>
        {numericUtil.formatCurrency(finalSettlement, 2)}
      </Table.Cell>
    </Table.Row>
  );
};

export default ManualAdjustmentRow;
