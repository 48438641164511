import { useField } from 'formik';
import { useCallback } from 'react';

import { useDisclaimerContext } from '../context/DisclaimerContext';
import { replaceTextInDisclaimer } from './useDisclaimerVariables.model';

export type UseDisclaimerVariablesResult = Readonly<{
  readonly pasteVariable: (variable: string) => void;
}>;

export function useDisclaimerVariables(): UseDisclaimerVariablesResult {
  const { disclaimerInputRef } = useDisclaimerContext();
  const [{ value: disclaimer }, , { setValue: setDisclaimer }] = useField<string | null>(
    'disclaimer'
  );

  const pasteVariable = useCallback(
    (variable: string) => {
      if (!disclaimerInputRef.current) {
        return;
      }

      if (document.activeElement !== disclaimerInputRef.current) {
        return; // ignore call if disclaimer field is not in focus
      }

      const updatedDisclaimer = replaceTextInDisclaimer(
        disclaimer,
        variable,
        disclaimerInputRef.current.selectionStart,
        disclaimerInputRef.current.selectionEnd
      );

      setDisclaimer(updatedDisclaimer);
      disclaimerInputRef.current.blur(); // make user to focus on input again to prevent adding multiple variables by mistake
    },
    [disclaimer, disclaimerInputRef, setDisclaimer]
  );

  return {
    pasteVariable,
  };
}
