import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteNonSyndicateTerminationWireMutation,
  SyndicateWires_NonSyndicateTerminationWiresDocument,
  useSyndicateWires_DeleteNonSyndicateTerminationWireMutation,
} from '../graphql';

export type DeleteNonSyndicateTerminationWireMutation =
  SyndicateWires_DeleteNonSyndicateTerminationWireMutation;

export const useDeleteNonSyndicateTerminationWireMutation = () => {
  return useSyndicateWires_DeleteNonSyndicateTerminationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateTerminationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
