import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import selectWireIcon from '../../../../common/assets/select-wire.svg';
import { HeroGraphics } from '../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import SyndicateWireContent from '../common/components/syndicate-wire-content/SyndicateWireContent';

type Props = { issuerName?: string } & RouteComponentProps<{ offeringId: UUID }>;

const OverviewRoute: React.FC<Props> = ({ issuerName }) => {
  useDocumentTitle(
    routeFactory.syndicateWires.getDocumentTitle({
      issuerName,
    })
  );

  const subtitle = React.useMemo(() => {
    return (
      <div>
        1. Choose a wire template that is pre-populated with Offering Details or Order Book data.
        <br />
        2. Enrich the wire, prepare a draft version.
        <br />
        3. Transmit the wire, manage the responses, and send additional versions as needed.
      </div>
    );
  }, []);

  return (
    <SyndicateWireContent>
      <SyndicateWireContent.ContentSection>
        <HeroGraphics
          image={selectWireIcon}
          imageAlt="select wire"
          title="Communicate offering information among the participants with Wires"
          subtitle={subtitle}
        />
      </SyndicateWireContent.ContentSection>
    </SyndicateWireContent>
  );
};

export default OverviewRoute;
