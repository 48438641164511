import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import React from 'react';

import emptyWire from '../../../../../../../common/assets/empty-wire.svg';
import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useIsOfferingAuthor } from '../../../../../../../common/hooks/useIsOfferingAuthor';
import { useCheckSyndicateWiresAccess } from '../../../../../../../common/util/check-access/useCheckSyndicateWiresAccess';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { WireTypeValidation } from '../../../context/WireTypeConfigContext.model';
import { ValidationErrorList } from '../validation-error-list/ValidationErrorList';

export type Props = {
  validation: WireTypeValidation;
  managers: SyndicateWireManager[];
  offeringId: UUID;
};

export const WireValidation: React.FC<Props> = ({ validation, managers, offeringId }) => {
  const { canManage } = useCheckSyndicateWiresAccess(offeringId);
  const isOfferingAuthor = useIsOfferingAuthor(offeringId);
  const hasUserPermissions = useCheckPermissions(
    [
      permissionsByEntity.Offering.FULL,
      permissionsByEntity.OrderBook.FULL,
      permissionsByEntity.Designation.FULL,
    ],
    true
  );
  const hasPermissions = canManage && hasUserPermissions && isOfferingAuthor;

  return (
    <HeroGraphics
      image={emptyWire}
      imageAlt="Validation Errors"
      title={
        hasPermissions ? (
          <React.Fragment>
            The following deal information is not configured.
            <br />
            Please add the following data to access the templates:
          </React.Fragment>
        ) : (
          <React.Fragment>
            The following deal information is not configured.
            <br />
            Please contact the offering's author to provide the following
            <br />
            information in order to access the templates:
          </React.Fragment>
        )
      }
      subtitle={
        <ValidationErrorList
          validation={validation}
          managers={managers}
          hasPermissions={hasPermissions}
          offeringId={offeringId}
        />
      }
    />
  );
};
