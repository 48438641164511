import styled from 'styled-components/macro';

export const SMyOrdersGridWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  // Setting the width of the wrapper around the Investor name cell
  // to 100% so that we can properly handle text overflow and styling.
  .ag-react-container,
  .ag-pinned-left-cols-container .ag-react-container,
  .ag-pinned-right-cols-container .ag-react-container,
  .ag-pinned-right-floating-top .ag-react-container {
    width: 100%;
  }
`;
