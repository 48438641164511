import { useAuth } from '@cmg/auth';
import isNil from 'lodash/isNil';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { ManagerBdRecipientInputData, WireTemplateType } from '../../../../../graphql';
import {
  createWireTypeConfig,
  WireTypeConfigInitialValues,
} from '../../common/context/WireTypeConfigContext.model';
import {
  BillingAndDeliveryPaymentFragment,
  SyndicateWires_ManagerDtcNumbersQuery,
  useBillAndDeliverPaymentsQuery,
  useSyndicateWires_ManagerDtcNumbersQuery,
  useSyndicateWires_WiresDisclaimerQuery,
} from '../../common/graphql';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_ManagerBdWirePartsFragment,
  SyndicateWires_ManagerBdWireValidationPartsFragment,
  useSyndicateWires_ManagerBdWireDetailsQuery,
  useSyndicateWires_ManagerBdWirePreviewQuery,
  useSyndicateWires_ManagerBdWiresQuery,
  useSyndicateWires_ManagerBdWireValidationQuery,
} from './graphql';
import { useCreateManagerBdWireMutation } from './hooks/useCreateManagerBdWireMutation';
import { useDeleteManagerBdWireMutation } from './hooks/useDeleteManagerBdWireMutation';
import { useSendManagerBdWireMutation } from './hooks/useSendManagerBdWireMutation';
import { useUpdateManagerBdWireMutation } from './hooks/useUpdateManagerBdWireMutation';
import { ManagerBdWireForm } from './ManagerBdWireForm';
import { ManagerBdRecipientUserData, validationSchema, Values } from './ManagerBdWireForm.model';

const wireTypeName = 'Manager B&D';

const getManagerBdRecipientInputData = (formValues: Values) => {
  const { disclaimer, senderDtc, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<ManagerBdRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        recipientDtc:
          recipientUserData!.recipientDtc !== '' ? recipientUserData!.recipientDtc : null,
        payment: recipientUserData!.payment!,
        senderDtc: senderDtc!,
        disclaimer,
      };
    });
};

export const getPredefinedRecipientUserData = (
  manager: SyndicateWireManager,
  initialRecipientsUserData: (ManagerBdRecipientUserData | null)[],
  billAndDeliverData: ReadonlyArray<BillingAndDeliveryPaymentFragment>,
  managerDtcNumbers: SyndicateWires_ManagerDtcNumbersQuery['syndicateWires']['managerDtcNumbers']
): ManagerBdRecipientUserData | null => {
  const initialManagerValues = initialRecipientsUserData.find(
    initialUserData => initialUserData?.recipient === manager.cmgEntityKey
  );

  const initialPayment = initialManagerValues?.payment;
  const initialRecipientDtc = initialManagerValues?.recipientDtc;

  const defaultPayment =
    billAndDeliverData.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey)?.payment ??
    null;
  const defaultRecipientDtc =
    managerDtcNumbers.find(item => item.managerKey === manager.cmgEntityKey)?.managerDtcNumber ??
    null;

  if (
    isNil(initialPayment) &&
    isNil(defaultPayment) &&
    isNil(initialRecipientDtc) &&
    isNil(defaultRecipientDtc)
  ) {
    return null;
  } else {
    return {
      recipient: manager.cmgEntityKey,
      payment: initialPayment ?? defaultPayment,
      recipientDtc: initialRecipientDtc ?? defaultRecipientDtc,
    };
  }
};

export const wireTypeConfigManagerBd = createWireTypeConfig<
  Values,
  SyndicateWires_ManagerBdWirePartsFragment,
  SyndicateWires_ManagerBdWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresManagerBd
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresManagerBd,
  wireTemplateType: WireTemplateType.ManagerBillingAndDelivery,
  // @ts-expect-error schema type mismatch on DTC number
  formValidation: validationSchema,
  createOrUpdateModalSize: 'md',
  CreateOrUpdateForm: ManagerBdWireForm,
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_ManagerBdWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.managerBdWireValidation, isLoading: loading, error, refetch };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_ManagerBdWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.managerBdWireList,
      isLoading: loading,
      error,
    };
  },
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_ManagerBdWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.managerBdWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_ManagerBdWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.managerBdWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateManagerBdWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getManagerBdRecipientInputData(values),
            },
          },
        });

        if (result.data?.createManagerBdWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createManagerBdWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateManagerBdWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getManagerBdRecipientInputData(values),
            },
          },
        });

        if (result.data?.updateManagerBdWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateManagerBdWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteManagerBdWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteManagerBdWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendManagerBdWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendManagerBdWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendManagerBdWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    wire,
    managers,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: billAndDeliverData,
      loading: billAndDeliverLoading,
      error: billAndDeliverError,
    } = useBillAndDeliverPaymentsQuery({
      variables: {
        offeringId,
      },
    });

    const {
      data: managerDtcData,
      loading: managerDtcLoading,
      error: managerDtcError,
    } = useSyndicateWires_ManagerDtcNumbersQuery({
      variables: {
        offeringId,
      },
    });

    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const { oidcUserCmgEntityKey } = useAuth();

    const initialUserData = React.useMemo(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0]?.disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const initialRecipientUserData = managers.map<ManagerBdRecipientUserData | null>(manager => {
        const managerValues = wire?.recipientsUserData?.find(
          ({ recipient }) => recipient === manager.cmgEntityKey
        );

        return !managerValues
          ? null
          : {
              recipientDtc: managerValues.recipientDtc,
              recipient: managerValues.recipient,
              payment: managerValues.payment,
            };
      });

      const predefinedRecipientUserData = managers.map<ManagerBdRecipientUserData | null>(
        manager => {
          return getPredefinedRecipientUserData(
            manager,
            initialRecipientUserData,
            billAndDeliverData?.syndicateWires.billAndDeliverPayments ?? [],
            managerDtcData?.syndicateWires.managerDtcNumbers ?? []
          );
        }
      );

      const defaultSenderDtc =
        managerDtcData?.syndicateWires.managerDtcNumbers.find(
          item => item.managerKey === oidcUserCmgEntityKey
        )?.managerDtcNumber ?? null;
      const initialSenderDtc = wire?.recipientsUserData[0]?.senderDtc ?? null;

      const initialValues: WireTypeConfigInitialValues<Values> = {
        data: {
          disclaimer,
          senderDtc: initialSenderDtc,
          recipientsUserData: initialRecipientUserData,
        },
        predefinedData: {
          disclaimer,
          senderDtc: initialSenderDtc ?? defaultSenderDtc,
          recipientsUserData: predefinedRecipientUserData,
        },
      };

      return initialValues;
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
      billAndDeliverData?.syndicateWires.billAndDeliverPayments,
      managerDtcData?.syndicateWires.managerDtcNumbers,
      oidcUserCmgEntityKey,
    ]);

    const isLoading = billAndDeliverLoading || disclaimerLoading || managerDtcLoading;
    const error = billAndDeliverError || disclaimerError || managerDtcError;

    return {
      isLoading,
      error,
      data: initialUserData.data,
      predefinedData: initialUserData.predefinedData,
    };
  },
});
