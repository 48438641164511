import { useEffect } from 'react';

import {
  useCalendar_PublishedOfferingsBuySideQuery,
  useCalendar_PublishedOfferingsQuery,
} from '../graphql/__generated__';

type OfferingsFullQuery =
  | ReturnType<typeof useCalendar_PublishedOfferingsQuery>
  | ReturnType<typeof useCalendar_PublishedOfferingsBuySideQuery>;

export function useFetchAllPaginatedPublishedOfferings(offeringsFullQuery: OfferingsFullQuery) {
  useEffect(() => {
    if (!offeringsFullQuery.data?.publishedOfferings?.pageInfo.hasNextPage) {
      return;
    }

    offeringsFullQuery.fetchMore({
      variables: {
        skip: offeringsFullQuery.data?.publishedOfferings?.items?.length,
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        if (!fetchMoreResult.publishedOfferings?.items) {
          return previousQueryResult;
        }

        return {
          ...previousQueryResult,
          publishedOfferings: {
            ...previousQueryResult.publishedOfferings,
            ...fetchMoreResult.publishedOfferings,
            items: [
              ...(previousQueryResult.publishedOfferings?.items ?? []),
              ...fetchMoreResult.publishedOfferings.items,
            ],
          },
        };
      },
    });
  }, [offeringsFullQuery]);
}
