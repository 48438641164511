import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { EventStatus } from '../../../../../../../graphql';
import { Roadshows_RoadshowPartsFragment } from '../../../../../common/graphql';
import MeetingSidePanel from '../../common/meeting-side-panel/MeetingSidePanel';
import { MeetingNavigationSteps } from '../../common/meeting-side-panel/MeetingSidePanel.model';
import InvestorsForm from '../common/investors-form/InvestorsForm';
import { Values as InvestorTeamsValues } from '../common/investors-form/InvestorsForm.model';
import { useRoadshows_CoveredMeetingQuery } from './graphql';
import { useUpdateCoveredInvestorTeamsMutation } from './hooks/useUpdateCoveredInvestorTeamsMutation';
import MeetingDetails from './meeting-details/MeetingDetails';
import MeetingInvestors from './meeting-investors/MeetingInvestors';
import MeetingParticipants from './meeting-participants/MeetingParticipants';
import { getRoadshowDay } from './SalesAndTradingDetailsPanel.model';

export type Props = {
  meetingId: UUID;
  roadshow: Roadshows_RoadshowPartsFragment;
  onClose: () => void;
  stepId?: MeetingNavigationSteps;
};

const SalesAndTradingDetailsPanel: React.FC<Props> = ({
  meetingId,
  onClose,
  roadshow,
  stepId = MeetingNavigationSteps.DETAILS,
}) => {
  const { push } = useHistory();
  const {
    data: meetingData,
    loading: meetingLoading,
    error: meetingError,
  } = useRoadshows_CoveredMeetingQuery({
    variables: { meetingId, roadshowId: roadshow.id },
    fetchPolicy: 'cache-and-network',
  });

  const [updateInvestors, { loading: isSavingInvestors }] = useUpdateCoveredInvestorTeamsMutation();

  const handleNavigationChange = (nextStepId: MeetingNavigationSteps) => {
    push(
      routeFactory.roadshowMeetingDetails.getUrlPath({
        meetingId,
        roadshowId: roadshow.id,
        stepId: nextStepId,
      })
    );
  };

  const handleSubmitInvestors = async ({ investors }: InvestorTeamsValues) => {
    try {
      const payload = investors.map(({ name, ...investor }) => investor);

      await updateInvestors({ variables: { meetingId, roadshowId: roadshow.id, payload } });

      ToastManager.success('Investors saved successfully');
    } catch {
      ToastManager.error('Failed to save investors');
    }
  };

  return (
    <MeetingSidePanel
      title="Meeting Details"
      onClose={onClose}
      eventStatus={meetingData?.coveredRoadshowMeeting.meetingDetails.status}
      activeNavigationStepId={stepId}
      onNavigationChange={handleNavigationChange}
      loading={meetingLoading}
      testId="roadshows / single-roadshow / calendar / meeting panel"
    >
      <FlexLayout expand direction="column">
        {meetingError && <ServerErrorsBanner error={meetingError} />}
        {stepId === MeetingNavigationSteps.DETAILS && !!meetingData && (
          <MeetingDetails
            meeting={meetingData.coveredRoadshowMeeting.meetingDetails}
            roadshowDay={getRoadshowDay(
              roadshow,
              meetingData.coveredRoadshowMeeting.meetingDetails.date
            )}
          />
        )}
        {stepId === MeetingNavigationSteps.TEAMS && !!meetingData && (
          <MeetingParticipants participants={meetingData.coveredRoadshowMeeting.participantsTeam} />
        )}
        {stepId === MeetingNavigationSteps.INVESTORS &&
          meetingData?.coveredRoadshowMeeting.meetingDetails.status === EventStatus.OpenToSales && (
            <InvestorsForm
              onSubmit={handleSubmitInvestors}
              isSaving={isSavingInvestors}
              meeting={meetingData.coveredRoadshowMeeting}
              roadshowStatus={roadshow.status}
              meetingHeaderInfo={null}
            />
          )}
        {stepId === MeetingNavigationSteps.INVESTORS &&
          meetingData?.coveredRoadshowMeeting.meetingDetails.status === EventStatus.Confirmed && (
            <MeetingInvestors investors={meetingData.coveredRoadshowMeeting.investors} />
          )}
      </FlexLayout>
    </MeetingSidePanel>
  );
};

export default SalesAndTradingDetailsPanel;
