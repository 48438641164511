import { useFormikContext } from 'formik';
import React from 'react';

import { Values } from '../../StabilizationForm.model';
import EditableOverallotmentRefreshField from './editable-overallotment-refresh/EditableOverallotmentRefresh';
import ReadOnlyOverallotmentRefresh from './read-only-overallotment-refresh/ReadOnlyOverallotmentRefresh';

export type Props = {
  isEditable: boolean;
  offeringPrice: number;
};

export const OverallotmentRefresh: React.FC<Props> = ({ isEditable, offeringPrice }) => {
  const { values } = useFormikContext<Values>();

  if (isEditable) {
    return <EditableOverallotmentRefreshField offeringPrice={offeringPrice} />;
  }

  return <ReadOnlyOverallotmentRefresh offeringPrice={offeringPrice} trades={values.sell} />;
};

export default OverallotmentRefresh;
