import { useAuth } from '@cmg/auth';
import { timeUtil, UUID } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import includes from 'lodash/includes';
import { useCallback, useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../common/config';
import { useIsUserSettlementAgent } from '../../../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { useCheckInstitutionalPassiveOrderBookAccess } from '../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { OfferingType } from '../../../../../../../graphql';
import { RowAlertsObject } from '../../components/row-alert/RowAlert.model';
import { RowAlertCellRenderer } from '../../components/row-alert/RowAlertCellRenderer';
import { RowAlertHeaderRenderer } from '../../components/row-alert/RowAlertHeaderRenderer';
import { CMGColumnID } from '../../types';
import IndicationAcknowledgementsCell from '../acknowledgements-cell/IndicationAcknowledgementsCell';
import AlloConfirmedByCell from '../allo-confirmed-by-cell/AlloConfirmedByCell';
import BDAgentCell from '../bd-agent-cell/BDAgentCell';
import CompanyNameCell from '../company-name-cell/CompanyNameCell';
import { DupeGroupCellRenderer } from '../dupe-group-cell/DupeGroupCellRenderer';
import { FirmListDisplayNameCell } from '../firm-list-display-name-cell/FirmListDisplayNameCell';
import { SPinnedRowText } from '../InstitutionalDemandGridColumns.styles';
import IOIAckByCell from '../ioi-ack-by-cell/IOIAckByCell';
import { RelationshipNameCell } from '../relationship-cell/RelationshipNameCell';
import SalesCoverageCell from '../sales-coverage-cell/SalesCoverageCell';
import TypeCellRenderer from '../type-cell/TypeCell';
import { cellStyle, defaultColumnProps, defaultColumnWidth, MenuType } from './Columns.model';
import {
  acknowledgementsValueGetter,
  bdAgentValueGetter,
  cmgColumnsRowAlertValueGetter,
  firmDisplayNamesValueGetter,
  investorValueGetter,
  salesCoverageValueGetter,
  typeValueGetter,
} from './value-getters/CmgColumnsValueGetters';

export const useCmgColumns = (
  offeringId: UUID,
  rowAlertsObject: RowAlertsObject,
  offeringType?: OfferingType,
  displayDupeGroupColumn?: boolean
): ColDef[] => {
  const { oidcUserCmgEntityKey } = useAuth();
  const {
    isOrderBookAttestationOn,
    isOrderBookSellSideIndicationAcknowledgementOn,
    isOrderBookDupesOn,
    isOrderBookPinnedColumnsOn,
  } = getFeatureToggles();
  const lockPinned = !isOrderBookPinnedColumnsOn;
  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });
  const { isSettlementAgent } = useIsUserSettlementAgent({ offeringId });

  const hideFilter = useCallback(
    (c: ColDef) => {
      const colId: string = c.colId!;
      const excludedColumns = [
        ...(!isOrderBookAttestationOn ? [CMGColumnID.ATTESTATION, CMGColumnID.ROW_ALERT] : []),
        ...(!isOrderBookSellSideIndicationAcknowledgementOn ? [CMGColumnID.ACKNOWLEGMENTS] : []),
        ...(!isOrderBookDupesOn
          ? [CMGColumnID.SELECTED, CMGColumnID.CMG_ENTITY_NAME, CMGColumnID.FIRM_KEY]
          : []),
        ...(canManagePassiveOrderBook
          ? [CMGColumnID.RELATIONSHIP, CMGColumnID.ACKNOWLEGMENTS, CMGColumnID.ACKNOWLEDGING_USER]
          : []),
      ];

      return !includes(excludedColumns, colId);
    },
    [
      canManagePassiveOrderBook,
      isOrderBookAttestationOn,
      isOrderBookDupesOn,
      isOrderBookSellSideIndicationAcknowledgementOn,
    ]
  );

  return useMemo(() => {
    const displayRowAlertColumn = rowAlertsObject?.errors > 0 || rowAlertsObject?.warnings > 0;
    const alertsCount = rowAlertsObject?.errors + rowAlertsObject?.warnings;
    const isWarningOnly = rowAlertsObject?.errors === 0 && rowAlertsObject?.warnings > 0;

    return [
      // Status column is strictly for exporting purpose. It is hidden in the grid.
      {
        ...defaultColumnProps,
        colId: CMGColumnID.INDICATION_STATUS,
        headerName: 'Status',
        field: 'status',
        refData: {
          menuDisplayType: MenuType.HIDE,
        },
        lockPinned: true,
      },
      {
        ...defaultColumnProps,
        colId: CMGColumnID.ROW_ALERT,
        headerName: '',
        refData: {
          menuDisplayType: MenuType.HIDE,
        },
        headerComponentFramework: ({ columnApi }) => (
          <RowAlertHeaderRenderer
            alertsCount={alertsCount}
            columnApi={columnApi}
            sortingOrder="desc"
            isWarningOnly={isWarningOnly}
          />
        ),
        cellStyle: () => ({
          ...cellStyle,
          lineHeight: '0px',
        }),
        hide: !displayRowAlertColumn,
        pinned: 'left',
        maxWidth: 42,
        minWidth: 42,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPosition: true,
        lockPinned: true,
        editable: false,
        cellRendererFramework: ({ data, context }) => {
          const attestationStatus = data.attestationStatus?.status;
          const { overallDeliveryStatus: prospectusDeliveryStatus } =
            data.prospectusDeliveryStatus ?? {};

          return (
            <RowAlertCellRenderer
              data={data}
              attestationStatus={attestationStatus}
              context={context}
              prospectusDeliveryStatus={prospectusDeliveryStatus}
              isSettlementAgent={isSettlementAgent}
              offeringType={offeringType}
            />
          );
        },
        sortable: true,
        valueGetter: ({ data }) => {
          return cmgColumnsRowAlertValueGetter({
            indication: data,
            offeringType: offeringType,
            isSettlementAgent,
            prospectusDeliveryStatus: data.prospectusDeliveryStatus?.overallDeliveryStatus,
            oidcUserCmgEntityKey,
          });
        },
      },
      {
        ...defaultColumnProps,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0px',
        },
        colId: CMGColumnID.DUPE_GROUP,
        headerName: '',
        refData: {
          menuDisplayType: MenuType.HIDE,
        },
        hide: !displayDupeGroupColumn,
        maxWidth: 28,
        minWidth: 28,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPosition: true,
        lockPinned: true,
        editable: false,
        sortable: false,
        pinned: 'left',
        cellRendererFramework: DupeGroupCellRenderer,
      },
      {
        ...defaultColumnProps,
        colId: CMGColumnID.SELECTED,
        headerName: '',
        refData: {
          menuDisplayType: MenuType.HIDE,
        },
        field: 'id',
        hide: false,
        pinned: 'left',
        maxWidth: 38,
        minWidth: 38,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPosition: true,
        lockPinned: true,
        editable: false,
        sortable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueFormatter: () => '',
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.INVESTOR,
        field: 'investorInformation.bankInvestorName',
        headerName: 'Investor',
        refData: {
          menuDisplayType: MenuType.READ_ONLY,
        },
        hide: false,
        suppressAutoSize: true,
        suppressMovable: true,
        pinned: 'left',
        minWidth: 275,
        lockPosition: true,
        lockPinned: true,
        pinnedRowCellRendererFramework: ({ data }) => {
          return <SPinnedRowText>Rows: {data.filteredIndicationCount}</SPinnedRowText>;
        },
        valueGetter: investorValueGetter,
        cellRendererFramework: CompanyNameCell,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.TYPE,
        field: 'type',
        headerName: 'Type',
        cellRendererFramework: TypeCellRenderer,
        valueGetter: typeValueGetter,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.BD_AGENT,
        field: 'bdAgent',
        headerName: 'B&D Agent',
        cellStyle: {
          justifyContent: 'flex-start',
          display: 'flex',
          lineHeight: 1.2,
          alignItems: 'center',
        },
        cellClass: 'bd-agent-cell',
        valueGetter: bdAgentValueGetter,
        cellRendererFramework: BDAgentCell,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.CMG_ENTITY_NAME,
        field: 'investorInformation.cmgEntityName',
        headerName: 'CMG Entity Name',
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.ACKNOWLEGMENTS,
        headerName: 'Acknowledgements',
        valueGetter: data => acknowledgementsValueGetter(data, oidcUserCmgEntityKey),
        cellRendererFramework: IndicationAcknowledgementsCell,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.FIRM_KEY,
        field: 'investorInformation.bankInvestorKey',
        headerName: 'Firm Key',
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SUBMITTED_BY,
        field: 'submittedBy',
        headerName: 'Submitted By',
        valueGetter: ({ data }) => firmDisplayNamesValueGetter([data.submittedBy]),
        cellRendererFramework: ({ data }) => (
          <FirmListDisplayNameCell managers={[data.submittedBy]} />
        ),
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SUBMITTED_ON,
        field: 'auditInfo.createdAt',
        headerName: 'Submitted On',
        valueFormatter: ({ value }) => {
          return timeUtil.formatAsDisplayDateTime(value) ?? '';
        },
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.MODIFIED_BY,
        field: 'modifiedBy',
        headerName: 'Modified By',
        valueGetter: ({ data }) => firmDisplayNamesValueGetter([data.modifiedBy]),
        cellRendererFramework: ({ data }) => (
          <FirmListDisplayNameCell managers={[data.modifiedBy]} />
        ),
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.MODIFIED_ON,
        field: 'auditInfo.modifiedAt',
        headerName: 'Modified On',
        valueFormatter: ({ value }) => {
          return timeUtil.formatAsDisplayDateTime(value) ?? '';
        },
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.RELATIONSHIP,
        field: 'coveringManagerNames',
        headerName: 'Relationship',
        valueGetter: ({ data }) => firmDisplayNamesValueGetter(data.coveringManagerNames),
        cellRendererFramework: ({ data }) => (
          <RelationshipNameCell managers={data.coveringManagerNames ?? []} />
        ),

        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.ACKNOWLEDGING_USER,
        field: 'acknowledgingUser',
        headerName: 'IOI Ack By',
        cellRendererFramework: IOIAckByCell,
        valueGetter: ({ data }) => {
          return data.acknowledgingUser ?? '-';
        },
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SALES_COVEREAGE,
        field: 'investorEmployeeRelationships',
        headerName: 'Sales Coverage',
        cellRendererFramework: SalesCoverageCell,
        valueGetter: salesCoverageValueGetter,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.INVESTOR_REPLY_SUBMITTER,
        field: 'finalAllocation.investorReplySubmitterName',
        headerName: 'Allo Confirmed by',
        cellRendererFramework: AlloConfirmedByCell,
        valueGetter: ({ data }) => {
          return data.finalAllocation?.investorReplySubmitterName ?? '-';
        },
        lockPinned,
      },
    ].filter(hideFilter);
  }, [
    rowAlertsObject?.errors,
    rowAlertsObject?.warnings,
    displayDupeGroupColumn,
    lockPinned,
    hideFilter,
    isSettlementAgent,
    offeringType,
    oidcUserCmgEntityKey,
  ]);
};
