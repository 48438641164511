import { OfferingStage, OfferingStatus, OfferingType } from '../../../../graphql';
import {
  extendedOfferingTypesOptions,
  offeringTypesOptions,
} from '../../../../types/domain/offering/constants';
import {
  OfferingSetup_BasicInfoRoute_BasicInfoQuery,
  OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
} from '../../graphql';

export function getOfferingSetupTypesOptions({ excludeIpo }: { excludeIpo: boolean }) {
  return offeringTypesOptions.filter(type => {
    return ![
      OfferingType.Rights,
      OfferingType.RegisteredDirect,
      ...(excludeIpo ? [OfferingType.Ipo, OfferingType.IpoSpac] : []),
    ].includes(type.value);
  });
}

export function getInternationalOfferingSetupTypesOptions({ excludeIpo }: { excludeIpo: boolean }) {
  return extendedOfferingTypesOptions
    .filter(type => {
      return ![
        OfferingType.Rights,
        OfferingType.RegisteredDirect,
        ...(excludeIpo ? [OfferingType.Ipo, OfferingType.IpoSpac] : []),
      ].includes(type.value);
    })
    .map(type => {
      const isUsType = Object.values(OfferingType).includes(type.value);
      return {
        ...type,
        group: isUsType ? 'US' : 'International',
      };
    });
}

type OfferingTypeProps = {
  tooltipTitle: string;
  inputIsDisabled: boolean;
  excludeIpo: boolean;
};

export function getOfferingTypeProps(
  offering?: OfferingSetup_BasicInfoRoute_BasicInfoQuery['offering'],
  publishedOffering?: OfferingSetup_BasicInfoRoute_PublishedOfferingQuery['publishedOffering']
): OfferingTypeProps {
  const pricedOfferingTooltipTitle =
    'Offering type can not be changed for priced published offerings.';
  const ipoOfferingTooltipTitle = 'IPO Offering type can not be changed for published offerings.';

  // No restriction on offering type modification
  if (offering?.id === undefined || offering?.stage === OfferingStage.Draft) {
    return {
      tooltipTitle: '',
      inputIsDisabled: false,
      excludeIpo: false,
    };
  }

  if (offering?.stage === OfferingStage.Published && publishedOffering?.status) {
    const isOfferingIpo = [OfferingType.Ipo, OfferingType.IpoSpac].includes(offering.type);
    const isOfferingLiveOrFiled = [OfferingStatus.Live, OfferingStatus.Filed].includes(
      publishedOffering.status
    );
    const isOfferingPriced = publishedOffering.status === OfferingStatus.Priced;

    // Disable input for IPO
    if (isOfferingIpo) {
      return {
        tooltipTitle: isOfferingPriced ? pricedOfferingTooltipTitle : ipoOfferingTooltipTitle,
        inputIsDisabled: true,
        excludeIpo: false,
      };
    }

    // Allow editing for live/filed FO
    if (isOfferingLiveOrFiled) {
      return {
        tooltipTitle: '',
        inputIsDisabled: false,
        excludeIpo: true,
      };
    }

    // Disable input for priced FO
    if (publishedOffering.status === OfferingStatus.Priced) {
      return {
        tooltipTitle: pricedOfferingTooltipTitle,
        inputIsDisabled: true,
        excludeIpo: false,
      };
    }
  }

  // Default behaviour
  return {
    tooltipTitle: '',
    inputIsDisabled: true,
    excludeIpo: false,
  };
}
