import { SecondaryButton, SuccessButton, TextInputField } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { CreateInvestorFirmBasicInput, RolodexRecordStatus } from '../../../../../../graphql';
import FlexLayout from '../../../../layout/flex-layout/FlexLayout';
import { useAdvancedInvestorSearchFlow } from '../AdvancedInvestorSearchFlowContext';
import { ButtonGroup } from '../AdvancedInvestorSearchFlowModal.styles';
import {
  AddressTextInput,
  StyledDivider,
  StyledFlexLayout,
  StyledFooter,
  StyledTextInput,
} from './CreateNewInvestorForm.styles';

export const CreateNewInvestorSchema = yup.object().shape({
  name: yup.string().nullable().required('Investor name is required'),
  firmKey: yup.string().nullable(),
  cik1: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be numeric')
    .length(10, 'Must be 10 digits long')
    .nullable(),
  cik2: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be numeric')
    .length(10, 'Must be 10 digits long')
    .nullable(),
  lei: yup
    .string()
    .matches(/^[a-zA-Z0-9]/, 'Must be alphanumeric')
    .length(20, 'Must be 20 characters long')
    .nullable(),
  crd1: yup.string().nullable(),
  crd2: yup.string().nullable(),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  website1: yup.string().nullable(),
  website2: yup.string().nullable(),
});

export type Props = {
  onCreate: (values: CreateInvestorFirmBasicInput) => void;
  submitting: boolean;
  onClose: () => void;
  goBack: () => void;
};

/**
 * CreateNewInvestorForm allows the user to create a free text basic investor firm.
 * The only required field is a name, once the user creates the investor a new cmgEntityKey is generated "on the fly"
 * when successful the modal will close and the new firm will be selected in the investor firm search
 */
const CreateNewInvestorForm: React.FC<Props> = ({ onCreate, goBack, onClose, submitting }) => {
  const { searchForm } = useAdvancedInvestorSearchFlow();
  const formik = useFormik<CreateInvestorFirmBasicInput>({
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: CreateNewInvestorSchema,
    initialValues: {
      name: searchForm.searchText || '',
      firmKey: searchForm.firmKey || '',
      cik1: searchForm.cik || '',
      cik2: '',
      lei: searchForm.lei || '',
      crd: searchForm.crd || '',
      address1: '',
      address2: '',
      website1: '',
      website2: '',
      status: RolodexRecordStatus.Effective,
    },
    onSubmit: values => {
      // remove any empty values
      // this is a workaround because the API has an issue accepting an empty firmKey
      const populatedValues = Object.entries(values).reduce(
        (acc, [key, value]) => {
          return {
            ...acc,
            [key]: value === '' ? undefined : value,
          };
        },
        { ...values }
      );

      onCreate(populatedValues);
    },
  });

  return (
    <FlexLayout direction="column" expand>
      <FlexLayout direction="row">
        <FormikProvider value={formik}>
          <StyledFlexLayout direction="column" expand>
            <TextInputField withMargin label="Investor Name" name="name" required />
            <TextInputField withMargin label="Bank Firm Key" name="firmKey" />
            <AddressTextInput withMargin label="Address1" name="address1" />
            <AddressTextInput withMargin label="Address2" name="address2" />
            <TextInputField withMargin label="Website1" name="website1" />
            <TextInputField withMargin label="Website2" name="website2" />
            <StyledDivider />
            <StyledFlexLayout direction="row">
              <StyledTextInput withMargin label="CIK1" name="cik1" />
              <StyledTextInput withMargin label="CIK2" name="cik2" />
              <StyledTextInput withMargin label="LEI" name="lei" />
              <StyledTextInput withMargin label="CRD" name="crd" />
            </StyledFlexLayout>
          </StyledFlexLayout>
        </FormikProvider>
      </FlexLayout>
      <StyledFooter>
        <SecondaryButton onClick={() => goBack()} iconLeft={{ name: 'angle-left' }}>
          Advanced Search
        </SecondaryButton>
        <ButtonGroup>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <SuccessButton
            disabled={!formik.isValid}
            loading={submitting}
            loadingLabel="Submitting..."
            onClick={() => formik.handleSubmit()}
          >
            Create
          </SuccessButton>
        </ButtonGroup>
      </StyledFooter>
    </FlexLayout>
  );
};

export default CreateNewInvestorForm;
