import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';

import { useIsUserSettlementAgent } from '../../../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../../../../graphql';
import { isRowValidAndBnDAgentUnassigned } from '../../components/row-alert/RowAlert.model';
import { defaultUnassignedManager, getMangerOptions, Manager } from './BDAgentForm.model';
import {
  SBDAlertPopoverText,
  SColumn,
  SSelectFieldWrapper,
  StyledBDAlertPopover,
  StyledSelectField,
} from './BDAgentForm.styles';

export type BillingAndDeliveryFormValues = {
  billingAndDeliveryAgentCmgEntityKey: string;
};

export type Props = {
  managers: Record<string, Manager>;
  offeringId: string;
  billingAndDeliveryAgentCmgEntityKey: string;
  loading: boolean;
  onChange: (values: BillingAndDeliveryFormValues) => void;
  finalAllocationShareQuantity?: number | null;
  menuPortalTarget?: HTMLElement;
  status?: IndicationStatus;
  type?: InstitutionalIndicationOrderType;
};

export const BDAgentFormComponent: React.FC<Props> = ({
  managers,
  offeringId,
  billingAndDeliveryAgentCmgEntityKey,
  loading,
  onChange,
  finalAllocationShareQuantity,
  menuPortalTarget,
  status,
  type,
}) => {
  const { isSettlementAgent } = useIsUserSettlementAgent({ offeringId });
  const [isDropdownHovered, setIsDropdownHovered] = React.useState(false);
  const formik = useFormik<BillingAndDeliveryFormValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      billingAndDeliveryAgentCmgEntityKey: !managers[billingAndDeliveryAgentCmgEntityKey]
        ? defaultUnassignedManager.cmgEntityKey
        : billingAndDeliveryAgentCmgEntityKey,
    },
    onSubmit: values => onChange(values),
  });

  const isUnAssignedBDAlert = useMemo(
    () =>
      status &&
      type &&
      isRowValidAndBnDAgentUnassigned({
        billingAndDeliveryAgentCmgEntityKey: billingAndDeliveryAgentCmgEntityKey,
        finalAllocationShareQuantity: finalAllocationShareQuantity!,
        isSettlementAgent: isSettlementAgent!,
        status,
        type,
      }),
    [
      billingAndDeliveryAgentCmgEntityKey,
      finalAllocationShareQuantity,
      isSettlementAgent,
      status,
      type,
    ]
  );

  const { handleSubmit } = formik;

  const handleChange = React.useCallback(() => {
    setIsDropdownHovered(false);
    handleSubmit();
  }, [handleSubmit, setIsDropdownHovered]);

  const menuPortalTargetProp = {
    ...(menuPortalTarget ? { menuPortalTarget } : {}),
  };

  const options = useMemo(() => {
    return getMangerOptions([...Object.values(managers), defaultUnassignedManager]);
  }, [managers]);

  return (
    <FormikProvider value={formik}>
      <SColumn>
        <StyledBDAlertPopover
          content={<SBDAlertPopoverText>B&D unassigned.</SBDAlertPopoverText>}
          variant="TOOLTIP"
          trigger="hover"
          placement="top"
          arrowPointAtCenter
          disabled={!isUnAssignedBDAlert || !isDropdownHovered}
        >
          <SSelectFieldWrapper
            bdAlertEnabled={isUnAssignedBDAlert}
            onMouseOver={() => setIsDropdownHovered(true)}
            onMouseLeave={() => setIsDropdownHovered(false)}
          >
            <StyledSelectField
              name="billingAndDeliveryAgentCmgEntityKey"
              options={options}
              isClearable={false}
              fullWidth={true}
              disabled={loading}
              onChange={handleChange}
              styledConfig={{
                control: base => ({
                  ...base,
                  height: 32,
                  minHeight: 32,
                }),
              }}
              testId={
                xcSelectors.institutionalDemandGridCellBillingAndDeliveryAgentDropdownMenu.testId
              }
              renderSingleSelectedOption={({ label }) =>
                label === 'Unassign' ? 'Unassigned' : label
              }
              {...menuPortalTargetProp}
            />
          </SSelectFieldWrapper>
        </StyledBDAlertPopover>
      </SColumn>
    </FormikProvider>
  );
};

export default BDAgentFormComponent;
