import { useCheckAccountTraits } from '@cmg/auth';
import { Icon, UUID } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import {
  Props as SideNavProps,
  SideNav,
  Step,
} from '../../../../common/components/navigation/side-nav/SideNav';
import { useFeatureToggles } from '../../../../common/config';
import { stepDataTestIds, stepIds, stepTitles } from '../../../offering-setup/constants';

export const StyledSideNav = styled(SideNav)`
  margin: 0 10px 0 0;
  width: 190px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.background.color.light};
  padding-top: 20px;
  padding-left: 12px;
`;

export type Props = Pick<SideNavProps, 'activeStepId' | 'onChangeStep' | 'steps'> & {
  offeringId?: UUID;
};

export const OfferingSetupSideNav: React.FC<Props> = props => {
  const disabled = !props.offeringId ? { disabled: true } : {};

  const hasInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);
  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();

  // ECM-46695 - For international offering
  const steps = [
    { id: stepIds.BASIC_INFO },
    ...(hasInternationalTrait
      ? [{ id: stepIds.INSTRUMENTS, ...disabled } as Step, { id: stepIds.CURRENCIES, ...disabled }]
      : [
          { id: stepIds.CURRENCIES, ...disabled } as Step,
          { id: stepIds.INSTRUMENTS, ...disabled },
        ]),
    { id: stepIds.TRANCHES, ...disabled },
    { id: stepIds.MANAGERS, ...disabled },
    { id: stepIds.TIMING, ...disabled },
    { id: stepIds.MARKETING, ...disabled },

    ...(isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
      ? [
          { id: stepIds.OFFERING_TERMS, ...disabled },
          { id: stepIds.UNDERWRITING_TERMS, ...disabled },
        ]
      : [
          { id: stepIds.TERMS, ...disabled },
          { id: stepIds.UW_DISCOUNTS_FEES, ...disabled },
          { id: stepIds.MANAGER_ECONOMICS, ...disabled },
        ]),
    { id: stepIds.PROSPECTUS, ...disabled },
    { id: stepIds.DISCLAIMERS, ...disabled },
    { id: stepIds.SUMMARY, icon: <Icon name="file-alt" />, ...disabled },
  ];

  return (
    <StyledSideNav
      {...props}
      steps={steps.map<Step>(step => ({
        ...step,
        /** @todo: add logic for updating this step array with statuses */
        label: stepTitles[step.id], // get the label of the step
        testId: stepDataTestIds[step.id], // get the data-test-id of the step
      }))}
    />
  );
};
