import { TextAreaField } from '@cmg/common';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../common/components/layout/panel/Panel';
import FilingCommonFieldsForm from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm';
import FilingDetailsForm from '../../../common/content/filing-details-form/FilingDetailsForm';
import ActivityForm from './activity/ActivityForm';

export type Props = Readonly<{
  readonly stockSymbols: readonly string[];
}>;

const NOIFilingForm: React.FC<Props> = ({ stockSymbols }) => {
  return (
    <div>
      <FilingCommonFieldsForm />
      <FilingDetailsForm stockSymbols={stockSymbols} />
      <ActivityForm />
      <Panel>
        <Panel.Content>
          <GridLayout>
            <Column span={3}>
              <TextAreaField
                withMargin
                fullWidth
                label="Activity Additional Information"
                aria-label="Activity Additional Information"
                name="additionalInformation"
              />
            </Column>
          </GridLayout>
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default NOIFilingForm;
