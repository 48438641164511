import { Option, PrimaryButton, UnsavedChangesGuard } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import FlexLayout from '../../../../../../../../common/components/layout/flex-layout/FlexLayout';
import { RoadshowStatus } from '../../../../../../../../graphql';
import {
  Roadshows_ManagementTeamMemberPartsFragment,
  Roadshows_MeetingPartsFragment,
} from '../../../../../../common/graphql';
import { EventInfo } from '../../../../common/event-header-info/EventHeaderInfo.model';
import EventFormSubHeader from '../../../common/event-form-subheader/EventFormSubHeader';
import MeetingButtonGroup from '../../../common/meeting-button-group/MeetingButtonGroup';
import { InvestorPanel } from './investors-panel/InvestorPanel';
import { getInitialValues, InvestorValues, validationSchema, Values } from './InvestorsForm.model';
import {
  SDeleteHeaderCell,
  SMemberHeaderCell,
  SMemberList,
  StyledDeleteIconButton,
  StyledFlexLayout,
  StyledForm,
  StyledInvestorSearch,
  StyledMemberRow,
  StyledTextInputField,
} from './InvestorsForm.styles';

export type Props = {
  meetingHeaderInfo: EventInfo | null;
  meeting: Roadshows_MeetingPartsFragment;
  roadshowStatus: RoadshowStatus;
  meetingManagementMembers?: readonly Roadshows_ManagementTeamMemberPartsFragment[];
  onSubmit: (values: Values) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onOpenToSales?: () => void;
  onConfirm?: () => void;
  isConfirming?: boolean;
  isDeleting?: boolean;
  isSaving: boolean;
  isOpeningToSales?: boolean;
};

const InvestorsForm: React.FC<Props> = ({
  onDelete,
  onCancel,
  onSubmit,
  isDeleting,
  isSaving,
  meeting,
  isOpeningToSales,
  onOpenToSales,
  meetingHeaderInfo,
  isConfirming,
  onConfirm,
  meetingManagementMembers,
  roadshowStatus,
}) => {
  const theme = useTheme();
  const formik = useFormik<Values>({
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues: getInitialValues(meeting.investors),
    onSubmit,
  });

  const { dirty, setFieldValue, values, resetForm } = formik;

  const [newInvestorOption, setNewInvestorOption] = React.useState<Option<string | null> | null>(
    null
  );

  const handleAddInvestor = () => {
    const isExistingInvestor =
      newInvestorOption &&
      values.investors.some(({ cmgEntityKey }) => cmgEntityKey === newInvestorOption.value);

    if (!newInvestorOption?.value || isExistingInvestor) {
      setNewInvestorOption(null);
      return;
    }

    const newInvestor: InvestorValues = {
      cmgEntityKey: newInvestorOption.value,
      name: newInvestorOption.label,
      members: [],
    };

    setNewInvestorOption(null);
    setFieldValue('investors', [...values.investors, newInvestor]);
  };

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
        <MeetingButtonGroup
          meetingHeaderInfo={meetingHeaderInfo}
          meetingStatus={meeting.meetingDetails.status}
          roadshowStatus={roadshowStatus}
          meetingInvestors={meeting.investors}
          meetingManagementMembers={meetingManagementMembers}
          eventParticipantsTeam={meeting.participantsTeam}
          onDelete={onDelete}
          onCancel={onCancel}
          onOpenToSales={onOpenToSales}
          onConfirm={onConfirm}
          isDeleting={isDeleting}
          isOpeningToSales={isOpeningToSales}
          isConfirming={isConfirming}
          isSaving={isSaving}
        />

        <StyledForm>
          <EventFormSubHeader title="Investors">
            <StyledFlexLayout direction="row">
              <StyledInvestorSearch
                showCreateNewInvestor
                onChange={setNewInvestorOption}
                value={newInvestorOption}
              />
              <PrimaryButton
                testId={xcSelectors.roadshowInvestorsFormAddInvestorButton.testId}
                disabled={!newInvestorOption}
                onClick={handleAddInvestor}
              >
                Add
              </PrimaryButton>
            </StyledFlexLayout>

            <FieldArray name="investors">
              {investorHelpers =>
                values.investors.map((investor, investorIndex) => {
                  return (
                    <FieldArray
                      key={investor.cmgEntityKey}
                      name={`investors.${investorIndex}.members`}
                    >
                      {memberHelpers => (
                        <InvestorPanel
                          name={investor.name}
                          memberCount={investor.members.length}
                          onDelete={() => investorHelpers.remove(investorIndex)}
                          onAddMember={() => memberHelpers.push({ email: '', name: '' })}
                        >
                          <SMemberList>
                            <FlexLayout direction="row" expand>
                              <SMemberHeaderCell>Name</SMemberHeaderCell>
                              <SMemberHeaderCell>Email</SMemberHeaderCell>
                              <SDeleteHeaderCell />
                            </FlexLayout>

                            {investor.members.map((member, memberIndex) => {
                              const memberPathName = `investors.${investorIndex}.members.${memberIndex}`;
                              const key = `${investor.cmgEntityKey}-${memberIndex}`;

                              return (
                                <StyledMemberRow key={key} direction="row" expand>
                                  <StyledTextInputField
                                    name={`${memberPathName}.name`}
                                    placeholder="Enter name"
                                  />
                                  <StyledTextInputField
                                    name={`${memberPathName}.email`}
                                    placeholder="Enter email"
                                  />
                                  <StyledDeleteIconButton
                                    testId={
                                      xcSelectors.roadshowInvestorsFormDeleteParticipantButton
                                        .testId
                                    }
                                    icon={{ name: 'trash-alt', color: theme.text.color.link }}
                                    onClick={() => memberHelpers.remove(memberIndex)}
                                  />
                                </StyledMemberRow>
                              );
                            })}
                          </SMemberList>
                        </InvestorPanel>
                      )}
                    </FieldArray>
                  );
                })
              }
            </FieldArray>
          </EventFormSubHeader>
        </StyledForm>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default InvestorsForm;
