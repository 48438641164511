import { PrimaryButton, Ribbon, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import capitalize from 'lodash/capitalize';
import React from 'react';

import ButtonTooltip from '../../../../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { filterRecipientsWithMissingEmails } from '../../../../../../../common/context/ContactSupportModalContext';
import { useContactSupportModalContext } from '../../../../../../../common/context/hooks/useContactSupportModalContext';
import { useCheckSyndicateWiresAccess } from '../../../../../../../common/util/check-access/useCheckSyndicateWiresAccess';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useManagersMissingEmail } from '../../../hooks/useManagersMissingEmail';
import { useSyndicateWiresRouteQuery } from '../../../hooks/useSyndicateWiresRouteQuery';
import { DisclaimerContextProvider } from '../../dialogs/create-or-update-wire-dialog/context/DisclaimerContext';
import { CreateOrUpdateWireDialog } from '../../dialogs/create-or-update-wire-dialog/CreateOrUpdateWireDialog';
import MenuHeading from '../../menu-heading/MenuHeading';
import { OperationType } from '../../modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import SyndicateWireContent from '../../syndicate-wire-content/SyndicateWireContent';
import { SyndicateWireHeader } from '../../syndicate-wire-header/SyndicateWireHeader';
import { SyndicateWiresErrorBanner } from '../../syndicate-wires-error-banner/SyndicateWiresErrorBanner';
import { MissingDistributionListBanner } from '../../validation/missing-distribution-list-banner/MissingDistributionListBanner';
import { WireRibbon } from '../../wire-ribbon/WireRibbon';

export type Props = {
  offeringId: UUID;
  nextRecipients: SyndicateWireManager[];
};

export const TemplateStage: React.FC<Props> = ({ offeringId, nextRecipients }) => {
  const { canManage } = useCheckSyndicateWiresAccess(offeringId);
  const {
    wireTypeName,
    wireTypeCategory,
    useGenerateWirePreview,
    useCreateIsDisabledReason,
    ManagersNavigation,
  } = useWireTypeConfigContext();

  const { data, isLoading, error } = useGenerateWirePreview({ offeringId });
  const createIsDisabledReason = useCreateIsDisabledReason({ offeringId });
  const managersMissingEmail = filterRecipientsWithMissingEmails(
    nextRecipients,
    useManagersMissingEmail({ offeringId })
  );
  const { open: openSupportModal } = useContactSupportModalContext();

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useSyndicateWiresRouteQuery(offeringId);

  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const templateHTML = React.useMemo(() => {
    return { __html: data ?? '' };
  }, [data]);

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  if (isLoading || offeringLoading) {
    return (
      <SyndicateWireContent>
        <Loading />
      </SyndicateWireContent>
    );
  }

  if (error || offeringError) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner loadedEntity={`the ${wireTypeName} template`} />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  return (
    <SyndicateWireContent data-test-id="wire-template-stage">
      <SyndicateWireHeader title={wireTypeName}>
        {canManage && (
          <ButtonTooltip
            disabled={!createIsDisabledReason}
            content={createIsDisabledReason}
            variant="DARK"
            placement="topRight"
          >
            <PrimaryButton
              onClick={handleOpenCreateModal}
              disabled={!!createIsDisabledReason}
              testId={xcSelectors.wiresTemplateStageCreateWireButton.testId}
            >
              Create {capitalize(wireTypeCategory)}
            </PrimaryButton>
          </ButtonTooltip>
        )}
      </SyndicateWireHeader>

      <SyndicateWireContent.ContentSection>
        {!!managersMissingEmail.length && (
          <MissingDistributionListBanner
            onContactSupport={() => openSupportModal(managersMissingEmail)}
            firmNames={managersMissingEmail.map(manager => manager.firmName)}
          />
        )}

        <SyndicateWireContent.Body>
          <SyndicateWireContent.ManagerList>
            <MenuHeading>Recipients</MenuHeading>
            <ManagersNavigation managers={nextRecipients} offeringId={offeringId} />
          </SyndicateWireContent.ManagerList>

          <SyndicateWireContent.Template>
            <Ribbon.Wrapper>
              <WireRibbon stage="template" />
              <div dangerouslySetInnerHTML={templateHTML} />
            </Ribbon.Wrapper>
          </SyndicateWireContent.Template>
        </SyndicateWireContent.Body>
      </SyndicateWireContent.ContentSection>
      {isCreateModalOpen && (
        <DisclaimerContextProvider>
          <CreateOrUpdateWireDialog
            onClose={handleCloseCreateModal}
            operationType={OperationType.CREATE}
            offeringId={offeringId}
            nextRecipients={nextRecipients}
            offeringType={offeringData.offeringType}
          />
        </DisclaimerContextProvider>
      )}
    </SyndicateWireContent>
  );
};
