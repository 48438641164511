import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../../../common/components/layout/flex-layout/FlexLayout';
import Column from '../../../../../../../../common/components/layout/grid-layout/Column';

export const StyledTitleColumn = styled(Column)`
  color: ${({ theme }) => theme.text.color.light};
`;

export const StyledValueColumn = styled(Column)`
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SColumn = styled.div`
  flex: 1;
  padding: 15px 10px;
`;

export const SHeaderColumn = styled(SColumn)`
  color: ${({ theme }) => theme.text.color.light};
  padding: 5px 10px;
`;

export const StyledFlexRow = styled(FlexLayout).attrs(() => ({
  direction: 'row',
  expand: true,
}))`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

export const SSubheaderContent = styled.div`
  & > * {
    margin: 8px 0;
  }
`;
