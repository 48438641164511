import type { UUID } from '@cmg/common';
import find from 'lodash/find';
import { useCallback, useState } from 'react';

import { useOrderBook_InstitutionalDemand_FinalAllocationsLazyQuery } from '../graphql/__generated__';

const PAGE_SIZE = 100;

export type FinalAllocation = {
  indicationId: UUID;
  investorDisplayName: string | null | undefined;
  finalAllocationShares: number | null;
};
type State = {
  loading: boolean;
  finalAllocations: FinalAllocation[];
};

export type Props = Readonly<{
  offeringId: UUID;
  finalAllocationSetId: UUID | undefined;
}>;

/**
 * Hook to get Demand Grid final allocations based on the selected indication ids.
 *
 * @param offeringId - Related offering id
 * @param finalAllocationSetId
 */
export const useGetFinalAllocationsToUpdate = ({ offeringId, finalAllocationSetId }: Props) => {
  const [{ finalAllocations, loading }, setState] = useState<State>({
    finalAllocations: [],
    /**
     * Loading state is handled by the component itself to avoid unnecessary re-renders.
     * These are caused by running the investor keys lazy query multiple times for larger books with multiple pages of data.
     */
    loading: false,
  });

  const [loadFinalAllocationsPage, queryResult] =
    useOrderBook_InstitutionalDemand_FinalAllocationsLazyQuery();

  const getFinalAllocations = useCallback(
    async (indicationIds: UUID[]) => {
      let finalAllocations: FinalAllocation[] = [];
      let hasNextPage = true;
      let pageCounter = 0;

      if (!finalAllocationSetId) {
        setState({ finalAllocations: [], loading: false });
        return [];
      }

      setState(prevState => ({ ...prevState, loading: true }));

      /**
       * Fetches all investor keys from the server by paginating through the data.
       */
      while (hasNextPage) {
        const { data } = await loadFinalAllocationsPage({
          fetchPolicy: 'no-cache',
          variables: {
            offeringId,
            finalAllocationSetId,
            skip: pageCounter++ * PAGE_SIZE,
            take: PAGE_SIZE,
            where: { id: { in: indicationIds } },
            min: 1,
            max: 1,
            increment: 1,
          },
        });

        const { pageInfo, items } = data?.syndicateInstitutionalGrid ?? {};

        const nextAllocations =
          items?.map<FinalAllocation>(({ id: indicationId, investor, allocations }) => ({
            indicationId,
            investorDisplayName: investor.displayName,
            finalAllocationShares:
              find(allocations, { allocationSetId: finalAllocationSetId })?.shares ?? null,
          })) ?? [];

        finalAllocations = [...finalAllocations, ...nextAllocations];
        hasNextPage = !!pageInfo?.hasNextPage;
      }

      setState({ finalAllocations, loading: false });
      return finalAllocations;
    },
    [finalAllocationSetId, loadFinalAllocationsPage, offeringId]
  );

  return {
    getFinalAllocations,
    loading,
    error: queryResult.error,
    finalAllocations,
  };
};
