import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 11px;
`;

export const SIconWrapper = styled.div`
  margin: 0 5px;
  padding: 3px;
  cursor: pointer;
`;
