import { PushNotificationStatus } from '../types';

/** Resolve a PushNotificationStatus given a browserNotificationPermission and pushNotificationEnabledStatus. */
export const resolveNotificationStatus: (options: {
  browserNotificationPermission: NotificationPermission;
  pushNotificationEnabledStatus: boolean | undefined;
}) => PushNotificationStatus = ({
  browserNotificationPermission,
  pushNotificationEnabledStatus,
}) => {
  if (browserNotificationPermission === 'default') {
    return 'default';
  } else if (browserNotificationPermission === 'denied') {
    return 'disabled-byBrowser';
  } else {
    if (pushNotificationEnabledStatus === false) {
      return 'disabled-byUser';
    } else {
      return 'enabled';
    }
  }
};
