import identityIcon from '../../../../../common/assets/identity-icon.svg';

export type Props = {
  size?: number;
};

const IdentityIcon: React.FC<Props> = ({ size }) => {
  return <img src={identityIcon} alt="identity-logo" width={size} />;
};

export default IdentityIcon;
