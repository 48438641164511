import { getCurrencySymbol, numericUtil } from '@cmg/common';
import { Grid } from '@cmg/design-system';
import capitalize from 'lodash/capitalize';
import React from 'react';

import {
  InstitutionalIndicationOrderType,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
} from '../../../../../../../../graphql';
import { limitTypeLabels } from '../../../../../../../../types/domain/order-book/constants';
import {
  UpdateHistory_CoveredIndicationVersionPartsFragment,
  UpdateHistory_IndicationVersionPartsFragment,
  UpdateHistory_MyIndicationVersionPartsFragment,
} from '../../graphql';

export type Props = Readonly<{
  item:
    | UpdateHistory_IndicationVersionPartsFragment
    | UpdateHistory_CoveredIndicationVersionPartsFragment
    | UpdateHistory_MyIndicationVersionPartsFragment;
  // demand currency
  currency: string;
  pricingCurrencyCode?: string | null;
}>;

export const getLimitTypeCellValue = (
  orderType: InstitutionalIndicationOrderType,
  limitType: InterestLevelLimitType | null | undefined,
  currency: string | null | undefined,
  limitPrice: number | null | undefined
) => {
  if (orderType === InstitutionalIndicationOrderType.Market) {
    return;
  }
  const isLimitTypePrice = limitType === InterestLevelLimitType.Price;

  const formattedCurrency = isLimitTypePrice ? ` (${currency})` : '';
  const formattedLimitPrice = isLimitTypePrice
    ? numericUtil.formatCurrency(limitPrice, undefined, getCurrencySymbol(currency))
    : numericUtil.formatPercents(limitPrice);

  return `${limitTypeLabels[limitType!]}${formattedCurrency}: ${formattedLimitPrice}`;
};

const InterestLevelGridRows: React.FC<Props> = ({ item, currency, pricingCurrencyCode }) => {
  return (
    <React.Fragment>
      {item.interestLevels.reduce<React.ReactElement[]>((gridRows, interestLevel, index) => {
        gridRows.push(
          <Grid
            item
            xs={4}
            key={`${item.id}_interest-quantity_${index}`}
            aria-label="interest-quantity"
          >
            {interestLevel.interestUnit === InterestLevelInterestUnit.Currency &&
              numericUtil.formatCurrency(
                interestLevel.interestQuantity,
                undefined,
                getCurrencySymbol(currency)
              )}
            {interestLevel.interestUnit === InterestLevelInterestUnit.Percent &&
              numericUtil.formatPercents(interestLevel.interestQuantity)}
            {interestLevel.interestUnit === InterestLevelInterestUnit.Shares &&
              numericUtil.formatNumber(interestLevel.interestQuantity, 0)}
          </Grid>
        );
        gridRows.push(
          <Grid item xs={4} key={`${item.id}_interest-level_${index}`} aria-label="interest-unit">
            {capitalize(interestLevel.interestUnit)}
          </Grid>
        );
        gridRows.push(
          <Grid item xs={4} key={`${item.id}_interest-price_${index}`} aria-label="interest-price">
            {capitalize(interestLevel.limitType ? '' : InstitutionalIndicationOrderType.Market)}

            {interestLevel.limitType &&
              getLimitTypeCellValue(
                item.type,
                interestLevel.limitType,
                pricingCurrencyCode,
                interestLevel.limitPrice
              )}
          </Grid>
        );
        return gridRows;
      }, [])}
    </React.Fragment>
  );
};

export default InterestLevelGridRows;
