import React from 'react';

import { OfferingSetup_OfferingEntitlementsQuery } from '../graphql';
import { DataByManager } from './EntitlementsDataByManagerContext';

/**
 * useEntitlementByManager processes entitlement data into an entitlements by manager cmgEntityKey
 * object.
 * The logic merges the syndicate manager data with the entitlement data by cmgEntityKey which allows us
 * to add the missing role and firmName information to our data structure
 */
export function useEntitlementByManager({
  data,
}: {
  data?: OfferingSetup_OfferingEntitlementsQuery;
}) {
  const managersByCmgEntityKey = React.useMemo(() => {
    return data?.offering.syndicate.managers.reduce(
      (total, current) => ({ ...total, [current.cmgEntityKey]: current }),
      {}
    );
  }, [data]);

  const entitlementsByManager: DataByManager = React.useMemo(() => {
    const syndicateEntitlements = data?.offering.entitlements.syndicateEntitlements;
    if (syndicateEntitlements) {
      return syndicateEntitlements?.reduce(
        (total, current) => ({
          ...total,
          [current.cmgEntityKey]: {
            ...current,
            ...(managersByCmgEntityKey ? managersByCmgEntityKey[current.cmgEntityKey] : {}),
          },
        }),
        {}
      );
    }
    return {};
  }, [data, managersByCmgEntityKey]);

  return entitlementsByManager;
}
