import { Autocomplete, AutocompleteTextFieldProps } from '@cmg/design-system';
import { useMemo, VFC } from 'react';

import { createOptionFromEmailAddress, getOptionText, Option } from './EmailAddressesPicker.model';

export type EmailAddressesPickerProps = Readonly<{
  readonly TextFieldProps: AutocompleteTextFieldProps;
  readonly selectedEmailAddresses: string[];
  readonly onChange: (selectedEmails: string[]) => void;
  readonly onBlur?: React.FocusEventHandler<HTMLDivElement>;
}>;

export const EmailAddressesPicker: VFC<EmailAddressesPickerProps> = ({
  TextFieldProps,
  selectedEmailAddresses,
  onChange,
  onBlur,
}) => {
  const autocompleteValue: Option[] = useMemo(
    () =>
      selectedEmailAddresses.map(emailAddress => ({
        isValid: true,
        validationError: null,
        emailAddress,
      })),
    [selectedEmailAddresses]
  );

  return (
    <Autocomplete<Option, true>
      multiple
      autoHighlight
      value={autocompleteValue}
      options={[]}
      TextFieldProps={TextFieldProps}
      onChange={(_event, selectedOptions) => {
        const validEmails = selectedOptions
          .filter(option => option.isValid)
          .map(({ emailAddress }) => emailAddress);

        onChange(validEmails);
      }}
      onBlur={onBlur}
      filterOptions={(_options, { inputValue }) => {
        const option = createOptionFromEmailAddress(inputValue, selectedEmailAddresses);
        return option ? [option] : [];
      }}
      noOptionsText="Start typing to add recipient’s email address"
      getOptionLabel={({ emailAddress }) => emailAddress}
      getOptionDisabled={({ isValid }) => !isValid}
      renderOption={(props, option) => <li {...props}>{getOptionText(option)}</li>}
    />
  );
};
