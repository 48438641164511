import { DemandGridServerColDef } from '../../types';
import { getSalesNoteTooltipContent, getSyndicateNoteTooltipContent } from './NoteTooltip';

const syndicateNotesColDefs: DemandGridServerColDef<string | null | undefined>[] = [
  {
    field: 'notes.syndicate',
    headerName: 'Syndicate Notes',
    tooltipField: 'notes.syndicate',
    tooltipComponentParams: {
      getContent: getSyndicateNoteTooltipContent,
    },
  },
  {
    field: 'notes.salesAndTrading',
    headerName: 'Sales & Trading Notes',
    tooltipField: 'notes.salesAndTrading',
    tooltipComponentParams: {
      getContent: getSalesNoteTooltipContent,
    },
  },
];

const salesNotesColDefs: DemandGridServerColDef<string | null | undefined>[] = [
  {
    field: 'notes.salesContact',
    headerName: 'Sales Contact Notes',
    tooltipField: 'notes.salesContact',
  },
  {
    field: 'notes.salesDepartment',
    headerName: 'Sales Department Notes',
    tooltipField: 'notes.salesDepartment',
  },
];

export default function getNotesColDefs({
  canViewSyndicateNotes,
}: {
  canViewSyndicateNotes: boolean;
}) {
  return [...(canViewSyndicateNotes ? syndicateNotesColDefs : []), ...salesNotesColDefs];
}
