import styled from 'styled-components/macro';

import FlexLayout from '../../../../../common/components/layout/flex-layout/FlexLayout';

export const SObsoleteDraftsFilter = styled.div`
  min-width: 240px;
  max-width: 400px;
  flex-grow: 1;
  flex: 1 1 0px;
`;

export const StyledFlexLayout = styled(FlexLayout).attrs({ direction: 'row' })`
  align-items: center;
  gap: 5px;
`;
