import * as yup from 'yup';

import { MAX_32_BIT_INT } from '../../../../../../../types/graphql/constants';
import { FinalSettlement_DesignationsGridItemFragment } from '../../../../common/graphql/__generated__';
import {
  FinalSettlement_InvestorDesignationPartsFragment,
  FinalSettlement_SettlementInvestorDesignationQuery,
} from '../../graphql/__generated__';

export type FormDesignation = {
  totalShares: number;
  shares: number;
  managerCmgEntityKey: string;
  managerName: string;
};

export type OverwriteForm = {
  formDesignations: FormDesignation[];
};

export const getInvestors = (
  designations: readonly Pick<FinalSettlement_DesignationsGridItemFragment, 'allocation'>[]
) => {
  return designations.map(({ allocation }) => {
    return {
      key: allocation.id,
      name: allocation.investorName,
    };
  });
};

export const getDesignationTotal = (designations: FormDesignation[]) => {
  return designations.reduce((result, current) => result + (current.shares ?? 0), 0) ?? 0;
};

export const getInitialOverwriteDesignations = (
  designation: FinalSettlement_InvestorDesignationPartsFragment | undefined,
  managers:
    | FinalSettlement_SettlementInvestorDesignationQuery['publishedOffering']['syndicate']['managers']
    | undefined
): OverwriteForm => {
  if (!designation || !managers) {
    return { formDesignations: [] };
  }

  const formDesignations = managers.reduce<FormDesignation[]>((result, current) => {
    const managerDesignation = designation.designations.find(
      ({ manager }) => manager.cmgEntityKey === current.cmgEntityKey
    );

    if (managerDesignation) {
      result.push({
        managerCmgEntityKey: current.cmgEntityKey,
        managerName: current.firmName,
        shares: managerDesignation.shares ?? 0,
        totalShares: designation.allocation.shares,
      });
    }

    return result;
  }, []);

  return { formDesignations };
};
export const designationValidationSchema = yup.object({
  totalShares: yup.number(),
  shares: yup
    .number()
    .min(0)
    .nullable()
    .max(MAX_32_BIT_INT, 'Invalid Shares value')
    .required()
    .label('Shares'),
  managerCmgEntityKey: yup.string(),
  managerName: yup.string(),
});

export const overwriteDesignationsValidationSchema = yup.object({
  formDesignations: yup.array().of(designationValidationSchema),
});
