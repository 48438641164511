import { loggerUtil, UUID } from '@cmg/common';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { PrivateRoute } from '../../../common/routing/private-route/PrivateRoute';
import {
  useCheckNonSyndicateCommunicationAccess,
  useCheckSyndicateCommunicationAccess,
} from '../../../common/util/check-access/useCheckSyndicateCommunicationAccess';
import { isIPOType } from '../../../common/util/offering/offering-type.util';
import routeFactory from '../../../common/util/routeFactory';
import { ServiceErrorCode } from '../../../types/domain/error/ServiceErrorCode';
import { NoAccessRedirect } from '../../core/RootRouter';
import { SyndicateWiresSideNav } from './common/components/navigation/syndicate-wires-side-nav/SyndicateWiresSideNav';
import { useSyndicateWiresRouteQuery } from './common/hooks/useSyndicateWiresRouteQuery';
import OverviewRoute from './overview/OverviewRoute';
import SyndicateWiresLayout from './SyndicateWiresRoute.styles';
import { wireTypeConfigDesignation } from './wires/designation/DesignationWire.model';
import { DesignationWireRoute } from './wires/designation/DesignationWireRoute';
import { wireTypeConfigFreeForm } from './wires/free-form/FreeFormWire.model';
import { FreeFormWireRoute } from './wires/free-form/FreeFormWireRoute';
import { wireTypeConfigGreenShoe } from './wires/green-shoe/GreenShoeWire.model';
import { GreenShoeWireRoute } from './wires/green-shoe/GreenShoeWireRoute';
import { wireTypeConfigIssuerAllocation } from './wires/issuer-allocation/IssuerAllocationWire.model';
import { IssuerAllocationWireRoute } from './wires/issuer-allocation/IssuerAllocationWireRoute';
import { wireTypeConfigIssuerIndication } from './wires/issuer-indication/IssuerIndicationWire.model';
import { IssuerIndicationWireRoute } from './wires/issuer-indication/IssuerIndicationWireRoute';
import { wireTypeConfigManagerBd } from './wires/manager-bd/ManagerBdWire.model';
import { ManagerBdWireRoute } from './wires/manager-bd/ManagerBdWireRoute';
import { wireTypeConfigNonSyndicateFreeForm } from './wires/non-syndicate-free-form/NonSyndicateFreeFormWire.model';
import { NonSyndicateFreeFormWireRoute } from './wires/non-syndicate-free-form/NonSyndicateFreeFormWireRoute';
import { wireTypeConfigNonSyndicatePayment } from './wires/non-syndicate-payment/NonSyndicatePaymentWire.model';
import { NonSyndicatePaymentWireRoute } from './wires/non-syndicate-payment/NonSyndicatePaymentWireRoute';
import { createWireTypeConfigNonSyndicateSellingGroupInvitation } from './wires/non-syndicate-selling-group-invitation/NonSyndicateSellingGroupInvitationWire.model';
import { NonSyndicateSellingGroupInvitationWireRoute } from './wires/non-syndicate-selling-group-invitation/NonSyndicateSellingGroupInvitationWireRoute';
import { wireTypeConfigNonSyndicateSellingGroupTermsRetention } from './wires/non-syndicate-selling-group-terms-retention/NonSyndicateSellingGroupTermsRetentionWire.model';
import { NonSyndicateSellingGroupTermsRetentionWireRoute } from './wires/non-syndicate-selling-group-terms-retention/NonSyndicateSellingGroupTermsRetentionWireRoute';
import { wireTypeConfigNonSyndicateTermination } from './wires/non-syndicate-termination/NonSyndicateTerminationWire.model';
import { NonSyndicateTerminationWireRoute } from './wires/non-syndicate-termination/NonSyndicateTerminationWireRoute';
import { wireTypeConfigPayment } from './wires/payment/PaymentWire.model';
import { PaymentWireRoute } from './wires/payment/PaymentWireRoute';
import { wireTypeConfigPotAccountNames } from './wires/pot-account-names/PotAccountNamesWire.model';
import { PotAccountNamesWireRoute } from './wires/pot-account-names/PotAccountNamesWireRoute';
import { wireTypeConfigPotAllocation } from './wires/pot-allocation/PotAllocationWire.model';
import { PotAllocationWireRoute } from './wires/pot-allocation/PotAllocationWireRoute';
import { wireTypeConfigPricingTerms } from './wires/pricing-terms/PricingTermsWire.model';
import { PricingTermsWireRoute } from './wires/pricing-terms/PricingTermsWireRoute';
import { wireTypeConfigPricingTermsRetention } from './wires/pricing-terms-retention/PricingTermsRetentionWire.model';
import { PricingTermsRetentionWireRoute } from './wires/pricing-terms-retention/PricingTermsRetentionWireRoute';
import { wireTypeConfigRegM } from './wires/reg-m/RegMWire.model';
import { RegMWireRoute } from './wires/reg-m/RegMWireRoute';
import { wireTypeConfigRetention } from './wires/retention/RetentionWire.model';
import { RetentionWireRoute } from './wires/retention/RetentionWireRoute';
import { createWireTypeConfigSellingGroupInvitation } from './wires/selling-group-invitation/SellingGroupInvitationWire.model';
import { SellingGroupInvitationWireRoute } from './wires/selling-group-invitation/SellingGroupInvitationWireRoute';
import { wireTypeConfigSellingGroupTermsRetention } from './wires/selling-group-terms-retention/SellingGroupTermsRetentionWire.model';
import { SellingGroupTermsRetentionWireRoute } from './wires/selling-group-terms-retention/SellingGroupTermsRetentionWireRoute';
import { wireTypeConfigTermination } from './wires/termination/TerminationWire.model';
import { TerminationWireRoute } from './wires/termination/TerminationWireRoute';
import { createWireTypeConfigUnderwriterInvitation } from './wires/underwriter-invitation/UnderwriterInvitationWire.model';
import { UnderwriterInvitationWireRoute } from './wires/underwriter-invitation/UnderwriterInvitationWireRoute';

const MixpanelModuleContextProvider = loggerUtil.MixpanelModuleContext.Provider;

export type RouteProps = { offeringId: UUID; syndicateWireId?: UUID; managerId?: string };
type Props = { issuerName?: string } & RouteComponentProps<RouteProps>;

export const SyndicateWiresRoute: React.FC<Props> = ({ match, issuerName }) => {
  const offeringId = match.params.offeringId;
  const { data, error, loading } = useSyndicateWiresRouteQuery(offeringId);
  const { canRead: canReadSyndicateCommunication } =
    useCheckSyndicateCommunicationAccess(offeringId);

  const isOfferingIpo = React.useMemo(() => !!isIPOType(data.offeringType), [data.offeringType]);

  const { canRead: canReadNonSyndicateCommunication } =
    useCheckNonSyndicateCommunicationAccess(offeringId);

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const offeringType = data.offeringType;

  return (
    <MixpanelModuleContextProvider value={{ moduleName: 'syndicate-wires' }}>
      <SyndicateWiresLayout>
        <SyndicateWiresLayout.SideNav>
          <SyndicateWiresSideNav
            offeringId={match.params.offeringId}
            isOfferingIpo={isOfferingIpo}
            offeringType={offeringType}
          />
        </SyndicateWiresLayout.SideNav>
        <SyndicateWiresLayout.Main>
          <Switch>
            <Route
              exact
              path={routeFactory.syndicateWires.routePath}
              render={routeProps => (
                <OverviewRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
            />
            <PrivateRoute
              exact
              path={
                createWireTypeConfigUnderwriterInvitation({ offeringType }).wireTypeRoute.routePath
              }
              render={routeProps => (
                <UnderwriterInvitationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                  offeringType={offeringType}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={
                createWireTypeConfigSellingGroupInvitation({ offeringType }).wireTypeRoute.routePath
              }
              render={routeProps => (
                <SellingGroupInvitationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                  offeringType={offeringType}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigRegM.wireTypeRoute.routePath}
              render={routeProps => (
                <RegMWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigPricingTerms.wireTypeRoute.routePath}
              render={routeProps => (
                <PricingTermsWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigPricingTermsRetention.wireTypeRoute.routePath}
              render={routeProps => (
                <PricingTermsRetentionWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigSellingGroupTermsRetention.wireTypeRoute.routePath}
              render={routeProps => (
                <SellingGroupTermsRetentionWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigRetention.wireTypeRoute.routePath}
              render={routeProps => (
                <RetentionWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigTermination.wireTypeRoute.routePath}
              render={routeProps => (
                <TerminationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigPotAllocation.wireTypeRoute.routePath}
              render={routeProps => (
                <PotAllocationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigPotAccountNames.wireTypeRoute.routePath}
              render={routeProps => (
                <PotAccountNamesWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigPayment.wireTypeRoute.routePath}
              render={routeProps => (
                <PaymentWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigManagerBd.wireTypeRoute.routePath}
              render={routeProps => (
                <ManagerBdWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigDesignation.wireTypeRoute.routePath}
              render={routeProps => (
                <DesignationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigGreenShoe.wireTypeRoute.routePath}
              render={routeProps => (
                <GreenShoeWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigFreeForm.wireTypeRoute.routePath}
              render={routeProps => (
                <FreeFormWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigIssuerIndication.wireTypeRoute.routePath}
              render={routeProps => (
                <IssuerIndicationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication && isOfferingIpo}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigIssuerAllocation.wireTypeRoute.routePath}
              render={routeProps => (
                <IssuerAllocationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadSyndicateCommunication && isOfferingIpo}
            />
            <PrivateRoute
              exact
              path={
                createWireTypeConfigNonSyndicateSellingGroupInvitation({ offeringType })
                  .wireTypeRoute.routePath
              }
              render={routeProps => (
                <NonSyndicateSellingGroupInvitationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                  offeringType={offeringType}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadNonSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigNonSyndicateSellingGroupTermsRetention.wireTypeRoute.routePath}
              render={routeProps => (
                <NonSyndicateSellingGroupTermsRetentionWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadNonSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigNonSyndicateTermination.wireTypeRoute.routePath}
              render={routeProps => (
                <NonSyndicateTerminationWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadNonSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigNonSyndicatePayment.wireTypeRoute.routePath}
              render={routeProps => (
                <NonSyndicatePaymentWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadNonSyndicateCommunication}
            />
            <PrivateRoute
              exact
              path={wireTypeConfigNonSyndicateFreeForm.wireTypeRoute.routePath}
              render={routeProps => (
                <NonSyndicateFreeFormWireRoute
                  {...(routeProps as RouteComponentProps<RouteProps>)}
                  issuerName={issuerName}
                />
              )}
              renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
              accessCheck={() => canReadNonSyndicateCommunication}
            />
            {/* Unknown url */}
            <Route
              render={() => (
                <Redirect
                  to={routeFactory.error.getUrlPath({ errorCode: ServiceErrorCode.NOT_FOUND })}
                />
              )}
            />
          </Switch>
        </SyndicateWiresLayout.Main>
      </SyndicateWiresLayout>
    </MixpanelModuleContextProvider>
  );
};
