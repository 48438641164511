import React from 'react';

import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { ReviewSalesCreditsItemFunction } from '../../grid-actions/SalesCreditsGridActions.model';
import { StyledLinkButton, SWarning } from './BankInvestorKeyChangedPopoverContent.styles';

export type Props = {
  salesCreditsItem: SalesCredits_SalesCreditItemPartsFragment;
  onOpenReviewModal: ReviewSalesCreditsItemFunction;
};

const BankInvestorKeyChangedPopoverContent: React.FC<Props> = ({
  salesCreditsItem,
  onOpenReviewModal,
}) => {
  const handleOpenReviewModal = () => {
    onOpenReviewModal(salesCreditsItem);
  };

  return (
    <div>
      <SWarning>
        The Bank Investor Key has been modified from the indication. Please review and accept or
        reject this change.
      </SWarning>
      <StyledLinkButton onClick={handleOpenReviewModal}>View</StyledLinkButton>
    </div>
  );
};

export default BankInvestorKeyChangedPopoverContent;
