import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { SyndicateWiresManagerRole, WireTemplateType } from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_PricingTermsWirePartsFragment,
  SyndicateWires_PricingTermsWireValidationPartsFragment,
  useSyndicateWires_PricingTermsWireDetailsQuery,
  useSyndicateWires_PricingTermsWirePreviewQuery,
  useSyndicateWires_PricingTermsWiresQuery,
  useSyndicateWires_PricingTermsWireValidationQuery,
} from './graphql';
import { useCreatePricingTermsWireMutation } from './hooks/useCreatePricingTermsWireMutation';
import { useDeletePricingTermsWireMutation } from './hooks/useDeletePricingTermsWireMutation';
import { useSendPricingTermsWireMutation } from './hooks/useSendPricingTermsWireMutation';
import { useUpdatePricingTermsWireMutation } from './hooks/useUpdatePricingTermsWireMutation';
import { PricingTermsWireForm } from './PricingTermsWireForm';
import { validationSchema, Values } from './PricingTermsWireForm.model';

const wireTypeName = 'Pricing Terms';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role !== SyndicateWiresManagerRole.SellingGroupMember;
};

export const wireTypeConfigPricingTerms = createWireTypeConfig<
  Values,
  SyndicateWires_PricingTermsWirePartsFragment,
  SyndicateWires_PricingTermsWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresPricingTerms
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresPricingTerms,
  wireTemplateType: WireTemplateType.PricingTerms,
  CreateOrUpdateForm: PricingTermsWireForm,
  formValidation: validationSchema,

  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_PricingTermsWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.pricingTermsWireList,
      isLoading: loading,
      error,
    };
  },
  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_PricingTermsWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.pricingTermsWirePreview.htmlContent,
      isLoading: loading,
      error,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_PricingTermsWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.pricingTermsWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_PricingTermsWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.pricingTermsWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreatePricingTermsWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: { offeringId, payload: values },
        });

        if (result.data?.createPricingTermsWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createPricingTermsWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdatePricingTermsWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: values },
        });

        if (result.data?.updatePricingTermsWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updatePricingTermsWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeletePricingTermsWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deletePricingTermsWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendPricingTermsWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendPricingTermsWire.__typename === 'ServiceError') {
          throw new Error(
            `Sending the ${wireTypeName} wire failed. 
${result.data.sendPricingTermsWire?.message}`
          );
        }

        return result.data?.sendPricingTermsWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      return {
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [operationType, wire?.disclaimer, disclaimerData?.syndicateWires.wiresDisclaimer]);

    return { isLoading: loading, data, error };
  },
});
