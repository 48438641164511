import { Option } from '@cmg/common';

import { RoadshowDetailsValues } from '../../RoadshowDetails.model';
import { Roadshows_RoadshowMapsAutocompleteQuery } from './graphql';

/**
 * Create Options for Roadshow Cities, with label format: "City, State" eg. Seattle, WA
 */
export function createRoadshowPlaceOptions(
  cityInfo: Roadshows_RoadshowMapsAutocompleteQuery['roadshowCityAutocomplete']
) {
  return cityInfo.map<Option>(info => ({
    label: `${info.cityAddressParts[0]}, ${info.cityAddressParts[1]}`,
    value: info.fullCityAddress,
  }));
}

export function createDefaultOptions(initialFormValues: RoadshowDetailsValues, dayIndex: number) {
  return initialFormValues.roadshowDays[dayIndex]?.cities.map(city => {
    const cityParts = city.split(', ');
    return {
      label: `${cityParts[0]}, ${cityParts[1]}`,
      value: city,
    };
  });
}
