import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ButtonsContainer, PrimaryButton, SecondaryButton, ToastManager, UUID } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Panel from '../../../../common/components/layout/panel/Panel';
import ValidationErrorBanner from '../../common/components/error-banner/ValidationErrorBanner';
import { ExportButton } from '../../common/components/export-button/ExportButton';
import NotesField from '../../common/components/notes-field/NotesField';
import {
  SSettlementHeaderText,
  SSettlementHeaderWrapper,
} from '../../common/components/settlement-header/SettlementHeader';
import {
  FinalSettlement_StabilizationInformationPartsFragment,
  FinalSettlement_StabilizationPartsFragment,
} from '../graphql';
import DeleteNotesConfirmationModal from './delete-notes-confirmation-modal/DeleteNotesConfirmationModal';
import { useFinalSettlement_CreateOrUpdateStabilizationMutation } from './graphql';
import { useExportOfferingStabilization } from './hooks/useExportOfferingStabilization';
import StabilizationFormContent from './stabilization-form-content/StabilizationFormContent';
import {
  getErrors,
  getInitialValues,
  getPayload,
  isStabilizationEmpty,
  validationSchema,
  Values,
} from './StabilizationForm.model';
import { StyledNotesHeader } from './StabilizationForm.styles';

export type Props = {
  stabilization: FinalSettlement_StabilizationPartsFragment;
  offeringInformation: FinalSettlement_StabilizationInformationPartsFragment;
  offeringId: UUID;
  issuerName: string;
};

const StabilizationForm: React.FC<Props> = ({
  stabilization,
  offeringInformation,
  offeringId,
  issuerName,
}) => {
  const canEditStabilization = useCheckPermissions([permissionsByEntity.Stabilization.FULL]);
  const isCreateWorkflow = isStabilizationEmpty(stabilization);
  const [isEditable, setIsEditable] = React.useState(isCreateWorkflow && canEditStabilization);
  const [isDeleteNotesModalVisible, setIsDeleteNotesModalVisible] = React.useState(false);
  const [areNotesVisible, setAreNotesVisible] = React.useState(!!stabilization.notes);

  const [createOrUpdateStabilization, { loading: isUpdating }] =
    useFinalSettlement_CreateOrUpdateStabilizationMutation();

  const { handleExport, loading: isExporting } = useExportOfferingStabilization({
    offeringId,
    offeringIssuerName: issuerName,
  });

  const formik = useFormik<Values>({
    initialValues: getInitialValues(stabilization),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      try {
        await createOrUpdateStabilization({
          variables: { offeringId, payload: getPayload(values) },
        });
        setIsEditable(false);
      } catch {
        ToastManager.error('Failed to save Stabilization');
      }
    },
  });

  const { submitForm, resetForm, errors, values, setFieldValue } = formik;

  const handleEditButton = () => {
    setIsEditable(true);
  };

  const handleCancelButton = () => {
    resetForm();
    setAreNotesVisible(!!stabilization.notes);
    setIsEditable(false);
  };

  const handleSaveButton = () => {
    setAreNotesVisible(!!values.notes);
    submitForm();
  };

  const handleAddNotesButton = () => {
    setAreNotesVisible(true);
  };

  const handleCloseDeleteNotesModal = () => {
    setIsDeleteNotesModalVisible(false);
  };

  const handleDeleteNotes = () => {
    if (!values.notes) {
      setAreNotesVisible(false);
    } else {
      setIsDeleteNotesModalVisible(true);
    }
  };

  const handleDeleteNotesValue = () => {
    setFieldValue('notes', '');
    setAreNotesVisible(false);
    handleCloseDeleteNotesModal();
  };

  const formErrors = getErrors(errors);

  return (
    <div>
      <SSettlementHeaderWrapper>
        <SSettlementHeaderText>Stabilization</SSettlementHeaderText>

        <ButtonsContainer>
          {isEditable && !areNotesVisible && (
            <SecondaryButton testId="add-notes-button" onClick={handleAddNotesButton}>
              Add Notes
            </SecondaryButton>
          )}
          {!isCreateWorkflow && isEditable && (
            <SecondaryButton
              testId="cancel-button"
              onClick={handleCancelButton}
              disabled={isUpdating}
            >
              Cancel
            </SecondaryButton>
          )}
          {isEditable && (
            <PrimaryButton testId="save-button" onClick={handleSaveButton} loading={isUpdating}>
              Save
            </PrimaryButton>
          )}
          {!isEditable && !isCreateWorkflow && (
            <ExportButton
              onExport={handleExport}
              testId="export-file-button"
              loading={isExporting}
              disabled={isStabilizationEmpty(stabilization)}
            />
          )}
          {!isEditable && canEditStabilization && (
            <SecondaryButton testId="edit-button" onClick={handleEditButton}>
              Edit
            </SecondaryButton>
          )}
        </ButtonsContainer>
      </SSettlementHeaderWrapper>

      {formErrors.length > 0 && <ValidationErrorBanner errors={formErrors} />}

      <FormikProvider value={formik}>
        <Form>
          {areNotesVisible && (
            <React.Fragment>
              <Panel>
                <StyledNotesHeader title="Notes">
                  {isEditable && (
                    <SecondaryButton testId="delete-notes-button" onClick={handleDeleteNotes}>
                      Delete Notes
                    </SecondaryButton>
                  )}
                </StyledNotesHeader>
                <Panel.Content paddingSize="M">
                  <NotesField
                    isEditable={isEditable}
                    notesValue={values.notes}
                    emptyFieldMessage="No Stabilization notes have been entered"
                  />
                </Panel.Content>
              </Panel>

              <Panel>
                <Panel.Header title="Stabilization" />
                <Panel.Content paddingSize="M">
                  <StabilizationFormContent
                    isEditable={isEditable}
                    offeringId={offeringId}
                    offeringInformation={offeringInformation}
                  />
                </Panel.Content>
              </Panel>
            </React.Fragment>
          )}

          {!areNotesVisible && (
            <StabilizationFormContent
              isEditable={isEditable}
              offeringId={offeringId}
              offeringInformation={offeringInformation}
            />
          )}
        </Form>
      </FormikProvider>

      <DeleteNotesConfirmationModal
        show={isDeleteNotesModalVisible}
        onHide={handleCloseDeleteNotesModal}
        onDeleteNotes={handleDeleteNotesValue}
      />
    </div>
  );
};

export default StabilizationForm;
