import { numericUtil } from '@cmg/common';
import { Grid } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

import GridWarningTooltip from '../../../../../../../design-system/components/data-display/grid-warning-tooltip/GridWarningTooltip';

const { getDisplayValueForInteger, sum, negate } = numericUtil;

export type Props = Readonly<{
  investorName: string;
  allocatedShares: number;
  designatedShares: number | null | undefined;
}>;

const OutOfBalanceError: React.FC<Props> = ({
  investorName,
  allocatedShares,
  designatedShares,
}) => {
  return (
    <GridWarningTooltip variant="error">
      <div>{investorName} is out of balance:</div>
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          Total Allocated:
        </Grid>
        <Grid item xs={6}>
          {getDisplayValueForInteger(allocatedShares)} shares
        </Grid>

        <Grid item xs={6}>
          Total Designated:
        </Grid>
        <Grid item xs={6}>
          {getDisplayValueForInteger(designatedShares)} {isNil(designatedShares) ? '' : 'shares'}
        </Grid>

        <Grid item xs={6}>
          Difference:
        </Grid>
        <Grid item xs={6}>
          {getDisplayValueForInteger(sum(allocatedShares, negate(designatedShares)))} shares
        </Grid>
      </Grid>
    </GridWarningTooltip>
  );
};

export default OutOfBalanceError;
