import { AddIcon, Button, Stack, Typography } from '@cmg/design-system';
import React from 'react';

import emptyState from '../../../../../../../common/assets/empty-state-image.svg';

export type Props = Readonly<{ onAddRow: () => void }>;

const InternalRetailDemandEmptyState: React.FC<Props> = ({ onAddRow }) => {
  return (
    <Stack gap={3} alignItems="center">
      <img src={emptyState} alt="No Retail Demand Submitted" width={150} />
      <Stack alignItems="center">
        <Typography variant="h4">No Retail Demand Submitted</Typography>
        <Typography>
          Click
          <Button onClick={onAddRow} startIcon={<AddIcon />}>
            Add Demand
          </Button>
          to add a new Retail Demand
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InternalRetailDemandEmptyState;
