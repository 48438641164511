import groupBy from 'lodash/groupBy';

import { ExpensesManagerRole } from '../../../../graphql';
import { FinalSettlement_EligibleManagerPartsFragment } from '../graphql';

export function managersToRoleGroupedEntries(
  managers: FinalSettlement_EligibleManagerPartsFragment[]
): Array<[ExpensesManagerRole, FinalSettlement_EligibleManagerPartsFragment[]]> {
  return Object.entries(groupBy(managers, 'role')) as Array<
    [ExpensesManagerRole, FinalSettlement_EligibleManagerPartsFragment[]]
  >;
}
