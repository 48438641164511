import { AgGridApi } from '@cmg/common';

import { CrmSelectionRow } from '../../CrmSelectModal.model';
import { StyledRadioCheck } from './CrmSelectionRadioCheckboxRenderer.styles';

export type Props = {
  readonly api: AgGridApi;
  data: CrmSelectionRow;
  onChange: (value: string) => void;
};

export const refreshSelectionCellParams = {
  force: true,
  columns: ['selected'],
};

const CrmSelectionRadioCheckboxRenderer = ({ data, api, onChange }: Props) => {
  const currentNode = api.getRenderedNodes().find(n => n.data.id === data.id);
  const handleOnChange = () => {
    currentNode?.setSelected(true);
    api.refreshCells(refreshSelectionCellParams);
    onChange && currentNode && onChange(currentNode.data.id);
  };
  const isSelected = currentNode?.isSelected() ?? false;

  return (
    <StyledRadioCheck
      key="selected"
      checked={isSelected}
      value={data.id}
      onChange={() => handleOnChange()}
    />
  );
};

export default CrmSelectionRadioCheckboxRenderer;
