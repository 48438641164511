import { NumericInputField, numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { StyledFooterRow } from '../../../../../../../expenses/ExpensesRoute.styles';
import { getDesignationTotal, OverwriteForm } from '../../OverwriteDesignation.model';

export type Props = {
  canEdit: boolean;
  totalShares: number;
};

export const DesignationTable: React.FC<Props> = ({ canEdit, totalShares }) => {
  const { values } = useFormikContext<OverwriteForm>();

  const totalDesignations = getDesignationTotal(values.formDesignations);

  return (
    <Table gridTemplateColumns="2fr 1fr 1fr">
      <Table.Row>
        <Table.TableHeaderCell>Recipient</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Shares</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">%</Table.TableHeaderCell>
      </Table.Row>

      {values.formDesignations.map((item, index) => {
        return (
          <Table.Row key={index}>
            <Table.Cell>{item.managerName}</Table.Cell>
            <Table.Cell paddingSize="XS" centered align="right">
              {canEdit ? (
                <NumericInputField
                  testId={`shares-input-${item.managerCmgEntityKey}`}
                  fullWidth
                  name={`formDesignations.${index}.shares`}
                  precision={0}
                />
              ) : (
                item.shares
              )}
            </Table.Cell>
            <Table.Cell align="right">
              {numericUtil.formatPercents(numericUtil.divide(item.shares, item.totalShares))}
            </Table.Cell>
          </Table.Row>
        );
      })}

      <StyledFooterRow>
        <Table.Cell bold>Total</Table.Cell>
        <Table.Cell bold align="right">
          {numericUtil.formatInteger(totalDesignations)}
        </Table.Cell>
        <Table.Cell bold align="right">
          {numericUtil.formatPercents(numericUtil.divide(totalDesignations, totalShares))}
        </Table.Cell>
      </StyledFooterRow>
    </Table>
  );
};

export default DesignationTable;
