import { Icon, Theme } from '@cmg/common';
import { RowNode } from 'ag-grid-community';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { InvestorReplyStatus } from '../../../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { SIconWrapper } from '../../../TradeReleaseLayout.styles';

export type Props = {
  value?: TradeRelease_TradeGridItemFragment['investorReplyStatus'];
  node: RowNode;
};

export const getAcknowledgementColor = (theme: Theme, status: InvestorReplyStatus | null) => {
  switch (status) {
    case null:
      return theme.designSystem.colors.darkBlue[200];
    case InvestorReplyStatus.Confirmed:
      return theme.designSystem.colors.green[300];
    case InvestorReplyStatus.Rejected:
      return theme.designSystem.colors.red[300];
  }
};

export const getAcknowledgementLabel = (status: InvestorReplyStatus | null | undefined) => {
  switch (status) {
    case null:
      return 'Pending';
    case InvestorReplyStatus.Confirmed:
      return 'Confirmed';
    case InvestorReplyStatus.Rejected:
      return 'Rejected';
  }
};

export const AcknowledgementStatusRenderer: React.FC<Props> = ({ value, node }) => {
  const isHeaderRow = node.isRowPinned();
  const theme = useTheme();

  if (isHeaderRow) {
    return null;
  }

  return (
    <div>
      <SIconWrapper>
        <Icon
          name="circle"
          variant="solid"
          size="sm"
          color={getAcknowledgementColor(theme, value!)}
        />
      </SIconWrapper>
      {getAcknowledgementLabel(value!)}
    </div>
  );
};
