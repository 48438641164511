import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
} from '@cmg/design-system';
import React, { useMemo } from 'react';

import EmptyDistributionListWarning from '../../alerts/empty-distribution-list-warning/EmptyDistributionListWarning';
import { RecipientListManagerRole } from '../../lists/recipient-list/RecipientList.model';
import { Recipient } from './hooks/useSelectRecipients';
import SelectRecipientsTable from './SelectRecipientsTable';

export type Props<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly onSelectionChange: (selectedRecipients: string[]) => void;
  readonly onContactSupport: () => void;
  readonly open: boolean;
  readonly recipients: Recipient<TManagerRole>[];
  readonly hideSubmitButton?: boolean;
  readonly onSubmit: (selectedRecipientKeys: string[]) => void;
  readonly isSubmitting?: boolean;
  readonly onCancel: () => void;
  readonly message?: React.ReactNode;
  readonly ccmyfirm?: React.ReactNode;
  readonly title?: string;
  readonly withDistributionList?: boolean;
  readonly submitButtonLabel: string;
  readonly disableSubmitWithoutSelectedRecipient?: boolean;
  readonly getManagerRoleLabel: (role: TManagerRole) => string;
}>;

function SelectRecipientsDialog<TManagerRole extends RecipientListManagerRole>({
  recipients,
  onSelectionChange,
  open,
  onCancel,
  onSubmit,
  isSubmitting,
  hideSubmitButton,
  message,
  submitButtonLabel,
  onContactSupport,
  ccmyfirm,
  title = 'Email Notification',
  withDistributionList = true,
  disableSubmitWithoutSelectedRecipient,
  getManagerRoleLabel,
}: Props<TManagerRole>) {
  const selectedRecipientKeys = useMemo(() => {
    return recipients
      .filter(({ isSelected, isDisabled }) => isSelected && !isDisabled)
      .map(({ cmgEntityKey }) => cmgEntityKey);
  }, [recipients]);

  const handleSubmit = React.useCallback(() => {
    onSubmit(selectedRecipientKeys);
  }, [onSubmit, selectedRecipientKeys]);

  const noSelectedRecipients =
    disableSubmitWithoutSelectedRecipient && selectedRecipientKeys.length === 0;
  const loadingButtonDisabled = noSelectedRecipients || isSubmitting;

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          {withDistributionList && (
            <EmptyDistributionListWarning
              recipients={recipients}
              onContactSupport={onContactSupport}
            />
          )}
          {message}
          <SelectRecipientsTable
            withDistributionList={withDistributionList}
            recipients={recipients}
            onSelectionChange={onSelectionChange}
            onContactSupport={onContactSupport}
            getManagerRoleLabel={getManagerRoleLabel}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          gap={1}
        >
          <Box>{ccmyfirm}</Box>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button
              data-testid="select-recipients-dialog-cancel-button"
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            {!hideSubmitButton && (
              <LoadingButton
                loading={isSubmitting}
                disabled={loadingButtonDisabled}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                {submitButtonLabel}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default SelectRecipientsDialog;
