/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_DealTeamExistsQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type Roadshows_DealTeamExistsQuery = {
  readonly __typename?: 'Query';
  readonly dealTeamExists: boolean;
};

export const Roadshows_DealTeamExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_DealTeamExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealTeamExists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_DealTeamExistsQuery__
 *
 * To run a query within a React component, call `useRoadshows_DealTeamExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DealTeamExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_DealTeamExistsQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRoadshows_DealTeamExistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_DealTeamExistsQuery,
    Roadshows_DealTeamExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_DealTeamExistsQuery, Roadshows_DealTeamExistsQueryVariables>(
    Roadshows_DealTeamExistsDocument,
    options
  );
}
export function useRoadshows_DealTeamExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_DealTeamExistsQuery,
    Roadshows_DealTeamExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_DealTeamExistsQuery, Roadshows_DealTeamExistsQueryVariables>(
    Roadshows_DealTeamExistsDocument,
    options
  );
}
export type Roadshows_DealTeamExistsQueryHookResult = ReturnType<
  typeof useRoadshows_DealTeamExistsQuery
>;
export type Roadshows_DealTeamExistsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_DealTeamExistsLazyQuery
>;
export type Roadshows_DealTeamExistsQueryResult = Apollo.QueryResult<
  Roadshows_DealTeamExistsQuery,
  Roadshows_DealTeamExistsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRoadshows_DealTeamExistsMockResponse = makeMockResponse<
  Roadshows_DealTeamExistsQueryVariables,
  Roadshows_DealTeamExistsQuery
>(Roadshows_DealTeamExistsDocument);
