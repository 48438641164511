import { FlexContainer } from '@cmg/common';
import styled, { CSSObject } from 'styled-components/macro';

export type StyleProps = {
  align?: CSSObject['textAlign'];
};

export const SSplitCellWrapper = styled.div<StyleProps>`
  height: 44px;
  padding-right: 3px;
  text-align: ${({ align = 'right' }) => align};
`;

export const SFlexContainer = styled(FlexContainer)`
  height: 100%;
  line-height: normal;
`;
