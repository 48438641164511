import { FormLabel, TextInput } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

export const SIssuerFilter = styled.div`
  width: 280px;
  margin: 0 20px;
`;

export type Props = {
  issuerName: string;
  onChange: (value: string) => void;
};

const IssuerFilter: React.FC<Props> = ({ onChange, issuerName }) => {
  return (
    <SIssuerFilter>
      <FormLabel>Issuer</FormLabel>
      <TextInput
        placeholder="Filter by Issuer Name..."
        onChange={onChange}
        testId={xcSelectors.myOrdersIssuerFilterSearchTextInput.testId}
        value={issuerName}
      />
    </SIssuerFilter>
  );
};

export default IssuerFilter;
