import styled from 'styled-components/macro';

export const SFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0 16px 20px;
  gap: 16px;
`;

export const SSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    margin: 5px 0;
  }
`;
