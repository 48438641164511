import { Option, ToastManager } from '@cmg/common';
import * as Yup from 'yup';

import { CreateDealTeamInput } from '../../../../../graphql';
import {
  DealTeamFormValues,
  dealTeamSchema,
} from '../../deal-team-details/deal-team-form/DealTeamForm.model';
import { useRoadshows_DealTeamExistsLazyQuery as dealTeamExistsQuery } from './graphql';
import { Roadshows_OrganizationSearchQuery } from './organization-panel/graphql';

export type CreateDealTeamFormValues = {
  organizationField: Option | null;
} & DealTeamFormValues;

export function mapOrganizationsToOptions(
  underwriters: Roadshows_OrganizationSearchQuery['underwriters']['data'] | undefined
): Option[] {
  return (underwriters ?? []).map(item => ({
    value: item.cmgEntityKey,
    label: item.displayName ?? item.cmgEntityKey,
  }));
}

// Returns false if organinzation is already assigned to some deal team
export async function organizationTest(field: Option | null) {
  const [dealTeamExists] = dealTeamExistsQuery();

  if (field?.value) {
    try {
      const { data } = await dealTeamExists({ variables: { cmgEntityKey: field.value } });
      return !data?.dealTeamExists;
    } catch {
      ToastManager.error('Failed to validate input data');
    }
  }

  return true;
}

export const createDealTeamSchema = dealTeamSchema.shape({
  organizationField: Yup.mixed<Option>()
    .nullable()
    .required()
    .label('Organization')
    .test('checkOrganization', 'This organization already has deal team', organizationTest),
});

export function getPayload(form: CreateDealTeamFormValues): CreateDealTeamInput {
  return {
    firmCmgEntityKey: form.organizationField?.value!,
    departments: form.departments,
    members: form.dealTeamMembers.map(item => ({
      email: item.email,
      department: item.department,
      name: item.name,
      officePhone: item.officePhone || null,
      mobilePhone: item.mobilePhone || null,
    })),
  };
}
