import { getOperationName } from '@apollo/client/utilities';

import { OfferingSetup_OfferingEntitlementsDocument } from '../../entitlements/graphql';
import { useOfferingSetup_UpdateManagerResponsibilitiesMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchOfferingSetup_OfferingEntitlementsQuery = getOperationName(
    OfferingSetup_OfferingEntitlementsDocument
  );
  return [
    // Refetch offering entitlements after manager responsibilities have been updated
    refetchOfferingSetup_OfferingEntitlementsQuery!,
  ];
};

export const useUpdateManagerResponsibilitiesMutation = (
  options: Parameters<typeof useOfferingSetup_UpdateManagerResponsibilitiesMutation>[0]
) => useOfferingSetup_UpdateManagerResponsibilitiesMutation({ ...options, refetchQueries });
