import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

import { CLOSE_TIMEOUT_MS, contentAndOverlayCss, ModalSize } from './Modal.styles';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

ReactModal.setAppElement('body');

export type Props = {
  /**
   * support for custom styling via styled components
   */
  className?: string;
  /**
   * modal title
   */
  title: string;
  /**
   * opens a modal
   */
  show: boolean;
  /**
   * modal size, passed directly to styles
   */
  size?: ModalSize;
  /**
   * a callback when a modal is closed, when not provided the close button is not showed
   */
  onHide?: () => void;
  /**
   * should close the modal when the user clicks in the overlay area
   */
  shouldCloseOnOverlayClick?: boolean;
  /**
   * setting testId renders a data-test-id attribute in the DOM
   */
  testId?: string;
};

/**
 * Moving Modal from @cmg/common to XC because its cumbersome to style.
 * This is a temporary solution that will be moved back to common package in the future.
 */
export const ModalComponent: React.FC<Props> = ({
  show,
  title,
  children,
  className,
  onHide,
  testId,
  shouldCloseOnOverlayClick = true,
}) => {
  const overlayClass = `${className}__overlay`;

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      className={className}
      overlayClassName={overlayClass}
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      data={testId ? { 'test-id': testId } : undefined}
    >
      <ModalHeader onHide={onHide}>{title}</ModalHeader>
      {children}
    </ReactModal>
  );
};

/**
 * styling using styled-components needs extra wrapper & two pseudo-classes
 * source: https://github.com/reactjs/react-modal/issues/603#issuecomment-378847885
 *
 * @see https://bitbucket.org/capitalmarketsgateway/ecm_client_web/src/dev/docs/Styling.md#markdown-header-styling-react-portal
 */
const Modal = styled(ModalComponent)`
  ${contentAndOverlayCss}
`;

/**
 * Adding modal part components to Modal component static context.
 * This makes them optional to use inside Modal and easy to style.
 */
export default Object.assign(Modal, {
  Content: ModalContent,
  Footer: ModalFooter,
});
