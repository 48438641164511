import {
  FlexContainer,
  PrimaryButton,
  SecondaryButton,
  SwitchField,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../common/components/overlays/modal/Modal';
import {
  useTradeRelease_TradeReleaseSettingsQuery,
  useTradeRelease_UpdateTradeReleaseSettingsMutation,
} from './graphql';
import { getInitialValues, ReleaseSettingsValues } from './ReleaseSettingsModal.model';
import { SSubText, SText } from './ReleaseSettingsModal.styles';

export type Props = { show: boolean; onCloseModal: () => void; offeringId: UUID };

const ReleaseSettingsModal: React.FC<Props> = ({ show, onCloseModal, offeringId }) => {
  const {
    data,
    loading: isLoadingSettings,
    error: fetchError,
  } = useTradeRelease_TradeReleaseSettingsQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });
  const [updateSettings, { loading: isUpdatingSettings }] =
    useTradeRelease_UpdateTradeReleaseSettingsMutation();

  const handleSaveSettings = async ({ isAutoreleaseEnabled }: ReleaseSettingsValues) => {
    try {
      await updateSettings({ variables: { offeringId, isAutoreleaseEnabled } });

      ToastManager.success('Successfully saved release settings');
      onCloseModal();
    } catch {
      ToastManager.error('Failed to update release settings');
    }
  };

  const formik = useFormik<ReleaseSettingsValues>({
    initialValues: getInitialValues(data),
    onSubmit: handleSaveSettings,
    enableReinitialize: true,
  });

  return (
    <Modal show={show} title="Release Settings">
      <Modal.Content>
        {fetchError && <ServerErrorsBanner error={fetchError} />}

        {!fetchError && (
          <FlexContainer direction="row" gap={8}>
            <FormikProvider value={formik}>
              <Form>
                <SwitchField name="isAutoreleaseEnabled" loading={isLoadingSettings} fullWidth />
              </Form>
            </FormikProvider>

            <div>
              <SText>Auto-release allocations after acknowledgement (for this offering)</SText>
              <SSubText>
                Trades acknowledged by someone in your organization, will be automatically released
                at time of acknowledgement.
              </SSubText>
            </div>
          </FlexContainer>
        )}
      </Modal.Content>

      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton
            onClick={formik.submitForm}
            loading={isUpdatingSettings}
            disabled={!!fetchError || isLoadingSettings || !formik.dirty}
          >
            Save
          </PrimaryButton>
          <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default ReleaseSettingsModal;
