import { useEffect, useState } from 'react';

import { useUpdateEffect } from '../../hooks';
import { notificationApi } from '../util';
import { useInstrumentation } from './useInstrumentation';

/** A hook that provides the current Notification.permission and updates when it changes. */
export const useBrowserNotificationPermission = () => {
  const { record } = useInstrumentation();
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    return notificationApi.onPermissionChange(permission => {
      setPermission(permission);
    });
  }, []);

  useUpdateEffect(() => {
    record('Permission changed', { pushNotification_permission: permission });
  }, [permission]);

  return permission;
};
