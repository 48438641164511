import { useAuth } from '@cmg/auth';
import { SnackbarManager } from '@cmg/design-system';
import { saveAs } from 'file-saver';
import React from 'react';

import { offeringApiClient } from '../../../../../common/api/offeringApiClient';
import { CalendarFilters } from '../../../hooks/useFilters';
import { filtersToExportCalendarRestParams, generateFileName } from '../CalendarExportButton.model';

export function useCalendarExportOfferings(filters: CalendarFilters) {
  const [loading, setLoading] = React.useState(false);
  const { oidcUserCmgEntityKey } = useAuth();

  const handleExport = React.useCallback(async () => {
    setLoading(true);
    try {
      const onPlatformOnly = undefined;
      const startPublicFilingDate = undefined;
      const endPublicFilingDate = undefined;
      const pricingInstrumentRegion = undefined;
      const {
        cmgEntityKey,
        types,
        issuerSectors,
        isSpac,
        startPricingDate,
        endPricingDate,
        startPostponedDate,
        endPostponedDate,
        pricingInstrumentCountryCode,
      } = filtersToExportCalendarRestParams(filters, oidcUserCmgEntityKey);
      const response = await offeringApiClient.publishedOfferings_ExportPublishedOfferings(
        cmgEntityKey,
        types,
        issuerSectors,
        isSpac,
        onPlatformOnly,
        startPublicFilingDate,
        endPublicFilingDate,
        startPricingDate,
        endPricingDate,
        startPostponedDate,
        endPostponedDate,
        pricingInstrumentRegion,
        pricingInstrumentCountryCode
      );

      saveAs(response.data, generateFileName());
      SnackbarManager.success('Download successfully completed.');
    } catch {
      SnackbarManager.error(
        'An error has occurred while trying to export your calendar data. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [filters, oidcUserCmgEntityKey]);

  return { handleExport, loading };
}
