import { getOperationName } from '@apollo/client/utilities';

import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../variables';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../institutional-demand-grid/graphql';
import { useInstitutionalDemandGrid_UpdateDraftAllocationMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchSummaryGridQuery = getOperationName(InstitutionalDemandGrid_SummaryDocument);

  return [refetchSummaryGridQuery!];
};

export const useUpdateDraftAllocationMutation = () => {
  return useInstitutionalDemandGrid_UpdateDraftAllocationMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      pendingOrderBookGridForcedRefreshVar(true);
    },
  });
};
