import { Grid } from '@cmg/design-system';
import { FormikAutocompleteField } from '@cmg/design-system-formik';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import {
  securityShareClassOptions,
  securityTypeOptions,
} from '../../../../types/domain/security/constants';
import Section from '../../components/design-system/section/Section';

export type Props = {
  submitting?: boolean;
  disablePricingCurrencyCode?: boolean;
};

const defaultParseFormikValue = (value, options) => options.find(option => option.value === value);
const defaultParseToFormikValue = option => option?.value;
/**
 * A reusable subsection of Fields for Formik forms
 * Used by BasicInfoForm & CreateOfferingForm
 */
export const SecuritySubForm: React.FC<Props> = ({ submitting, disablePricingCurrencyCode }) => (
  <Section title="Security" spacing={3}>
    <Grid item md={3} sm={6} xs={12}>
      <FormikAutocompleteField
        data-testid={xcSelectors.offeringSetupBasicInfoSecurityType.testId}
        name="security.type"
        label="Security Type"
        placeholder="Choose Type..."
        required
        parseFormikValue={defaultParseFormikValue}
        parseToFormikValue={defaultParseToFormikValue}
        disabled={submitting}
        options={securityTypeOptions}
        fullWidth
      />
    </Grid>
    <Grid item md={3} sm={6} xs={12}>
      <FormikAutocompleteField
        data-testid={xcSelectors.offeringSetupBasicInfoShareClass.testId}
        name="security.shareClass"
        label="Security Description"
        placeholder="Choose Security Description..."
        disabled={submitting}
        options={securityShareClassOptions}
        parseFormikValue={defaultParseFormikValue}
        parseToFormikValue={defaultParseToFormikValue}
        fullWidth
      />
    </Grid>
  </Section>
);

export default SecuritySubForm;
