import { useAuth } from '@cmg/auth';
import { loggerUtil } from '@cmg/common';
import React from 'react';

const Provider = loggerUtil.MixpanelAppContext.Provider;

export const XcMixpanelAppContextProvider: React.FC = ({ children }) => {
  const { oidcUserCmgEntityKey: userId, oidcUserAccountType: accountType = null } = useAuth();

  return <Provider value={{ appName: 'xc', userId, accountType }}>{children}</Provider>;
};
