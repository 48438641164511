import { DataGridGroupingClient, DeepWritable } from '@cmg/common';
import { Box, TableSkeleton } from '@cmg/design-system';
import { AgGridEvent } from 'ag-grid-community';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { SupportModalDismissibleErrorsBanner } from '../../../../common/components/dismissible-error-banners/SupportModalDismissibleErrorsBanner';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useGridReady } from '../../../../common/hooks/useGridReady';
import MyOrdersGridFilter from '../my-orders-grid-filters/MyOrdersFilters';
import { useMyOrdersGridData } from './hooks/useMyOrdersGridData';
import { useMyOrdersGridPreferences } from './hooks/useMyOrdersGridPreferences';
import { myOrdersSecondaryErrorPrefixMap } from './MyOrdersGrid.model';
import { SMyOrdersGridWrapper } from './MyOrdersGrid.styles';
import { getColumns, MyOrderGridOrderField } from './MyOrdersGridColumns';
import { PricingDateHeader } from './PricingDateHeader';

const dismissibleBannerProps = {
  bannerProps: {
    title: 'There was an error loading some of the data on the page',
    body: 'Please contact support to report this issue. You are able to continue working with the present data until it is resolved.',
  },
  dialogProps: {
    title: 'Submit Support Request',
    presetCategory: 'TECHNICAL_ISSUE',
    presetSubject: 'Issue loading data in My Orders',
    setErrorsOnDescription: true,
  },
};

const groupHeaderRenderer = groupValue => <PricingDateHeader pricingDate={groupValue} />;

function MyOrdersGrid<TIndication>(): React.ReactElement {
  const match = useRouteMatch();
  const {
    primaryErrors,
    primaryQueriesLoading,
    secondaryErrors,
    gridData,
    gridContext,
    filters,
    setFilters,
    refetchOfferings,
    filteredData,
    hasCalledPrimaryQueries,
    secondaryQueriesLoading,
    hasCalledSecondaryQueries,
  } = useMyOrdersGridData({ match });

  const gridRef = React.useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);
  const { columnsOrder, setColumnsOrder } = useMyOrdersGridPreferences();
  const theme = useTheme();

  const columns = React.useMemo(
    () =>
      getColumns({
        columnsOrder,
        displayAlert: gridContext.investorsWithInvalidCompliance.length !== 0,
      }),
    [columnsOrder, gridContext.investorsWithInvalidCompliance.length]
  );

  // force refresh cells after secondary queries have finished loading
  React.useEffect(() => {
    if (hasCalledSecondaryQueries && !secondaryQueriesLoading && gridData) {
      gridRef?.current?.api.refreshCells({ force: true });
    }
  }, [secondaryQueriesLoading, hasCalledSecondaryQueries, gridData]);

  const primaryError = primaryErrors && primaryErrors[0];

  return (
    <React.Fragment>
      {primaryError && <ServerErrorsBanner error={primaryError} />}
      {!primaryError && (
        <SupportModalDismissibleErrorsBanner
          {...dismissibleBannerProps}
          errors={secondaryErrors}
          prefixMessageMap={myOrdersSecondaryErrorPrefixMap}
        />
      )}
      <SMyOrdersGridWrapper>
        <MyOrdersGridFilter
          onDateChange={refetchOfferings}
          onChange={setFilters}
          filters={filters}
          indications={gridData}
        />
        {primaryQueriesLoading || !hasCalledPrimaryQueries ? (
          <Box sx={{ backgroundColor: 'secondaryDarkBackground.light' }}>
            <TableSkeleton cellSize="medium" numOfColumns={8} numOfRows={20} />
          </Box>
        ) : (
          <DataGridGroupingClient<TIndication>
            onGridReady={onGridReady}
            groupByField={MyOrderGridOrderField.PRICING_DATE}
            columns={columns}
            rows={filteredData as DeepWritable<typeof filteredData>}
            skipSetTimeOutLoading={true}
            resizeBy="grid"
            domLayout="normal"
            resizeStrategy="fit-content"
            extended={{
              withMargin: false,
              hideColumnResize: true,
              hideColumnSelector: true,
              fillViewport: true,
              hideHeader: true,
            }}
            groupHeaderRenderer={groupHeaderRenderer}
            onFirstDataRendered={({ columnApi }) => {
              columnApi.autoSizeAllColumns(false); // skipHeader set to false in param
            }}
            onModelUpdated={({ columnApi }) => {
              columnApi.autoSizeAllColumns(false); // skipHeader set to false in param
            }}
            gridOptions={{
              suppressCellSelection: true,
              suppressRowClickSelection: true,
              suppressBrowserResizeObserver: true,
              tooltipShowDelay: 0,
              rowHeight: 50,
              rowStyle: {
                background: theme.background.color.white,
                borderBottom: theme.border.smallSolidLight,
              },
              onDragStopped: ({ columnApi, target }) => {
                if (target.attributes && target.attributes[0].nodeValue === 'eResize') {
                  // Column was only resized (individually). We don't want to trigger any actions that will cause a reload or ag grid events to fire.
                  // Else, autoSizeAllColumns will be triggered and resize will be lost
                  return;
                }

                const columns = columnApi.getAllDisplayedColumns() ?? [];
                setColumnsOrder(columns.map(column => column.getColId()));
              },
            }}
            getRowNodeId={data => data.id}
            context={gridContext}
          />
        )}
      </SMyOrdersGridWrapper>
    </React.Fragment>
  );
}

export default MyOrdersGrid;
