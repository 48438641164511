import {
  CurrencyInputField,
  DatePickerField,
  Icon,
  LinkButton,
  NumericInputField,
  SelectField,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';
import Table from '../../../../../../common/components/layout/table/Table';
import { AtmTrade, currencyOptions, TNFilingValues } from '../TNFilingForm.model';

const SWrapper = styled.div`
  margin-bottom: 16px;
`;

const ATMTradeForm: React.FC = () => {
  const { values } = useFormikContext<TNFilingValues>();

  function addAtmTrade(arrayHelpers: FieldArrayRenderProps) {
    const atmTrade: AtmTrade = {
      averagePrice: null,
      currency: null,
      numberOfShares: null,
      tradeDate: null,
    };
    arrayHelpers.push(atmTrade);
  }

  return (
    <FieldArray name="atmTrades">
      {arrayHelpers => (
        <SWrapper>
          <FlexLayout direction="row">
            <h3>ATM Trades</h3>
            <LinkButton
              inline
              testId={xcSelectors.tnAddTradeInfo.testId}
              onClick={() => addAtmTrade(arrayHelpers)}
              iconLeft={{ name: 'plus' }}
            >
              Add Trade
            </LinkButton>
          </FlexLayout>
          {values.atmTrades.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>Trade Date</th>
                  <th>Number of Shares</th>
                  <th>Average Price</th>
                  <th>Currency</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {values.atmTrades.map((_, index) => (
                  <tr key={index}>
                    <td>
                      <DatePickerField name={`atmTrades.${index}.tradeDate`} />
                    </td>
                    <td>
                      <NumericInputField name={`atmTrades.${index}.numberOfShares`} />
                    </td>
                    <td>
                      <CurrencyInputField name={`atmTrades.${index}.averagePrice`} />
                    </td>
                    <td>
                      <SelectField name={`atmTrades.${index}.currency`} options={currencyOptions} />
                    </td>
                    <td>
                      <LinkButton
                        testId={`${xcSelectors.tnRemoveTradeInfo.testId}.${index}`}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <Icon name="trash-alt" />
                      </LinkButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </SWrapper>
      )}
    </FieldArray>
  );
};

export default ATMTradeForm;
