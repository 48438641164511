import { UUID } from '@cmg/common';
import type { ToolPanelDef } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { useMemo } from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import { useCheckDraftAllocationAccess } from '../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import type { AllocationSetsToolPanelProps } from '../side-bar/AllocationSetsToolPanel';
import { AllocationSetsToolPanel } from '../side-bar/AllocationSetsToolPanel';
import type { DemandToolPanelProps } from '../side-bar/DemandToolPanel';
import { DemandToolPanel } from '../side-bar/DemandToolPanel';
import type { TemplatesToolPanelProps } from '../side-bar/TemplatesToolPanel';
import { TemplatesToolPanel } from '../side-bar/TemplatesToolPanel';

const widthConfig = {
  width: 300,
  minWidth: 300,
  maxWidth: 300,
};

const allocationSetsToolPanelDef: ToolPanelDef = {
  id: 'allocationSets',
  labelKey: '',
  labelDefault: 'Allocations',
  iconKey: '',
  toolPanel: AllocationSetsToolPanel,
  ...widthConfig,
};

const demandToolPanelDef: ToolPanelDef = {
  id: 'demand',
  labelKey: '',
  labelDefault: 'Demand',
  iconKey: '',
  toolPanel: DemandToolPanel,
  ...widthConfig,
};

const templatesToolPanelDef: ToolPanelDef = {
  id: 'templates',
  labelKey: '',
  labelDefault: 'Templates',
  iconKey: '',
  toolPanel: TemplatesToolPanel,
  ...widthConfig,
};

const columnsToolPanelDef: ToolPanelDef = {
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {
    suppressValues: true,
    suppressPivotMode: true,
  },
  ...widthConfig,
};

const filtersToolPanelDef: ToolPanelDef = {
  id: 'filters',
  labelDefault: 'Filters',
  labelKey: 'filters',
  iconKey: 'filter',
  toolPanel: 'agFiltersToolPanel',
  ...widthConfig,
};

export type SideBarDefProps = Readonly<{
  allocationSetsProps: AllocationSetsToolPanelProps;
  demandProps: DemandToolPanelProps;
  templatesProps: TemplatesToolPanelProps;
  offeringId: UUID;
}>;

export const useSideBarDef = ({
  allocationSetsProps,
  templatesProps,
  demandProps,
  offeringId,
}: SideBarDefProps) => {
  const { getOpenedToolPanel } = useDataGridContext();
  const { canRead: canReadDraftAllocations } = useCheckDraftAllocationAccess({
    offeringId,
  });
  const { canRead: canReadFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { isDraftAllocationOn } = useFeatureToggles();

  const displayAllocations =
    canReadFinalAllocations || (canReadDraftAllocations && isDraftAllocationOn);

  return useMemo(
    () => ({
      toolPanels: [
        {
          ...demandToolPanelDef,
          toolPanelParams: demandProps,
        },
        ...(displayAllocations
          ? [
              {
                ...allocationSetsToolPanelDef,
                toolPanelParams: allocationSetsProps,
              },
            ]
          : []),
        {
          ...templatesToolPanelDef,
          toolPanelParams: templatesProps,
        },
        columnsToolPanelDef,
        filtersToolPanelDef,
      ],
      defaultToolPanel: getOpenedToolPanel() ?? undefined,
    }),
    [demandProps, displayAllocations, allocationSetsProps, templatesProps, getOpenedToolPanel]
  );
};
