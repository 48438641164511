import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { SyndicateWires_ManagerRevisedUnderwritingCommitmentPartsFragment } from '../../graphql';

/**
 * Maps the manager recipient to corresponding revised underwriting commitment
 * @param managerRevisedUnderwritingCommitment
 * @param manager
 */
export const getManagerWithUnderwritingCommitment = (
  managerRevisedUnderwritingCommitment: readonly SyndicateWires_ManagerRevisedUnderwritingCommitmentPartsFragment[],
  manager: SyndicateWireManager
) => {
  const managerCommitment = managerRevisedUnderwritingCommitment.find(
    ({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey
  );

  return {
    shares: managerCommitment?.revisedUnderwritingCommitment ?? null,
    cmgEntityKey: manager.cmgEntityKey,
    firmName: manager.firmName,
  };
};
