import styled from 'styled-components/macro';

export const SSelectedRowsToolbarWrapper = styled.div`
  height: 79px;
  background-color: ${({ theme }) => theme.background.color.highlighted};
  width: calc(100% + 40px);
  position: relative;
  left: -20px;
`;

export const SSelectedRowsToolbarContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SAmmountSelected = styled.div`
  font-weight: bold;
  padding: 30px 0 0 34px;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  button + button {
    margin-left: 10px;
  }

  button:last-child {
    margin-right: 18px;
  }

  button {
    padding: 0px 10px;
    display: inline-block;
    height: 35px;
    margin-top: 22px;
  }
`;
