import { ODSTable as Table, UUID } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useSalesCredits_BankInvestorsQuery } from './graphql';
import InvestorRow from './investor-row/InvestorRow';
import OtherInvestorRow from './other-investor-row/OtherInvestorRow';
import { StyledTable } from './SalesCreditsBankInvestorKeyTable.styles';

export type Props = {
  firmCmgEntityKey: UUID;
  firmName: string;
  firmBankInvestorKey: string | null;
  firmLocation: string | null;
  firmId: UUID | null;
  selectedFirmId: UUID | null;
  onFirmChange: (nextValue: UUID | null) => void;
};

const SalesCreditsBankInvestorKeyTable: React.FC<Props> = ({
  firmCmgEntityKey,
  firmId,
  firmName,
  firmBankInvestorKey,
  firmLocation,
  selectedFirmId,
  onFirmChange,
}) => {
  const { data, loading, error } = useSalesCredits_BankInvestorsQuery({
    variables: { cmgEntityKey: firmCmgEntityKey },
  });

  const investorIds = React.useMemo(() => {
    const mappedInvestors = data?.allInvestorFirmsMappedToCmgEntityKey.map(({ id }) => id!) ?? [];
    const houseAccountInvestors = data?.salesCreditsHouseAccounts.map(({ firmId }) => firmId) ?? [];
    return [...mappedInvestors, ...houseAccountInvestors];
  }, [data?.allInvestorFirmsMappedToCmgEntityKey, data?.salesCreditsHouseAccounts]);

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const areHouseAccountsSetUp = data && data.salesCreditsHouseAccounts.length > 0;
  const isFromCrm = data && data.allInvestorFirmsMappedToCmgEntityKey.length > 0;

  return (
    <StyledTable gridTemplateColumns="36px 2fr 1fr 3fr">
      <Table.Row>
        <Table.TableHeaderCell />
        <Table.TableHeaderCell>Firm Name</Table.TableHeaderCell>
        <Table.TableHeaderCell>Bank Investor Key</Table.TableHeaderCell>
        <Table.TableHeaderCell>Location</Table.TableHeaderCell>
      </Table.Row>

      {isFromCrm && (
        <React.Fragment>
          <Table.Row>
            <Table.GroupHeaderCell colSpan={4}>
              CMG entity name: {data?.allInvestorFirmsMappedToCmgEntityKey[0].name}
            </Table.GroupHeaderCell>
          </Table.Row>
          {data?.allInvestorFirmsMappedToCmgEntityKey.map(
            ({ id, name, firmKey, address1, address2 }) => (
              <InvestorRow
                key={id}
                investorName={name}
                investorFirmKey={firmKey}
                investorAddress={[address1, address2].filter(address => address).join(', ')}
                onSelectInvestor={() => onFirmChange(id!)}
                isSelected={selectedFirmId === id}
              />
            )
          )}
        </React.Fragment>
      )}

      {areHouseAccountsSetUp && (
        <React.Fragment>
          <Table.Row>
            <Table.GroupHeaderCell colSpan={4}>Internal House Account</Table.GroupHeaderCell>
          </Table.Row>
          {data?.salesCreditsHouseAccounts.map(
            ({ firmId, investorFirmName, bankInvestorKey, investorAddress }) => (
              <InvestorRow
                key={firmId}
                investorName={investorFirmName}
                investorFirmKey={bankInvestorKey}
                investorAddress={investorAddress}
                onSelectInvestor={() => onFirmChange(firmId)}
                isSelected={selectedFirmId === firmId}
              />
            )
          )}
        </React.Fragment>
      )}

      <Table.Row>
        <Table.GroupHeaderCell colSpan={4}>Other Investor</Table.GroupHeaderCell>
      </Table.Row>
      <OtherInvestorRow
        onSelectInvestor={onFirmChange}
        selectedInvestorId={selectedFirmId}
        defaultFirmId={firmId}
        defaultFirmName={firmName}
        defaultBankInvestorKey={firmBankInvestorKey}
        defaultFirmLocation={firmLocation}
        allInvestors={investorIds}
      />
    </StyledTable>
  );
};

export default SalesCreditsBankInvestorKeyTable;
