import merge from 'lodash/merge';

import {
  IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables,
  useIndicationActivity_OrderBookSalesStateForSalesTraderQuery,
  useWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription,
} from './__generated__';

export const useIndicationActivityOrderBookSalesStateForSalesTraderQuery = (
  variables: IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables,
  cmgEntityKey: string | undefined,
  canReadOfferingAndIndication: boolean = false
) => {
  let queryResult = useIndicationActivity_OrderBookSalesStateForSalesTraderQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    skip: !cmgEntityKey || !variables.offeringId || !canReadOfferingAndIndication,
    notifyOnNetworkStatusChange: true,
  });

  queryResult = useWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription(
    queryResult,
    {
      variables: () => variables,
      loadingStateBehavior: 'false-on-refetch',
      updateQuery:
        () =>
        (prev, { subscriptionData }) =>
          merge({}, prev, {
            salesAndTradingOrderBook: {
              salesState: subscriptionData.data.orderBookSalesStateChangedForSalesTrader.salesState,
            },
          }),
    }
  );

  return queryResult;
};
