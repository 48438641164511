import { NumericInputField, numericUtil, TextInputField } from '@cmg/common';
import React from 'react';

export type Props = {
  isEditing?: boolean;
  shares?: number | null;
};

const AllocationSharesField: React.FC<Props> = ({ isEditing, shares }) => {
  if (isEditing) {
    return <NumericInputField name="allocation.shares" fullWidth precision={0} />;
  }

  if (shares != null) {
    return <span>{numericUtil.formatNumber(shares, 0)}</span>;
  }

  return <TextInputField name="emptyShares" disabled placeholder="N/A" fullWidth />;
};

export default AllocationSharesField;
