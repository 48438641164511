import React from 'react';

import { ConfirmationPanel } from '../confirmation-panel/ConfirmationPanel';

type Props = {
  /**
   * Called when user clicks on No button
   */
  onCancel?: () => void;
  /**
   * Called when user clicks on Yes button
   */
  onConfirm?: () => void;
};

/**
 * Ask user to confirm declination of invitation wire
 */
export const DeclineInvitationPanel: React.FC<Props> = ({ onCancel, onConfirm, children }) => {
  return (
    <ConfirmationPanel
      title="Decline Invitation"
      cancelButtonLabel="No"
      onCancel={onCancel}
      confirmButtonLabel="Yes"
      onConfirm={onConfirm}
    >
      {children}
    </ConfirmationPanel>
  );
};
