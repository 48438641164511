import { DateRange, DateRangeValue, FormLabel, Icon, Popover } from '@cmg/common';
import { addDays, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components/macro';

import { DEFAULT_DATE_RANGE } from '../../../../hooks/useFilters';

export const SPricingDateFilter = styled.div`
  width: 280px;
  margin: 0 20px;
`;

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  margin-bottom: 4px;
`;

export type Props = {
  onChange: (value: DateRangeValue) => void;
  values: DateRangeValue;
};

const PricingDateFilter: React.FC<Props> = ({ values, onChange }) => {
  const [minMaxDate, setMinMaxDate] = React.useState<{
    min: Date | undefined;
    max: Date | undefined;
  }>({
    min: undefined,
    max: undefined,
  });
  const [ownValues, setOwnValues] = React.useState<DateRangeValue>({ ...values });

  const handleOnChange = value => {
    const { start, end } = value;
    if (!end) {
      setMinMaxDate({
        min: parseISO(start),
        max: addDays(parseISO(start), DEFAULT_DATE_RANGE),
      });
      setOwnValues(value);
    } else {
      setMinMaxDate({
        min: undefined,
        max: undefined,
      });
      onChange(value);
    }
  };

  return (
    <SPricingDateFilter>
      <FormLabel>
        Priced Deals Date Range
        <Popover
          content="All live offerings are displayed below.  Date picker only impacts Priced offerings. Can only select a 7 day range at a time."
          variant="DARK"
          placement="top"
        >
          <span>
            <StyledIcon name="info-circle" variant="solid" />
          </span>
        </Popover>
      </FormLabel>
      <DateRange
        value={ownValues}
        selectsRange={true}
        presetOptions={{}}
        onChange={handleOnChange}
        monthsShown={1}
        minDate={minMaxDate.min}
        maxDate={minMaxDate.max}
        useDebounceApplyDisplayValue={false}
        dateRangeLimit={DEFAULT_DATE_RANGE}
      />
    </SPricingDateFilter>
  );
};

export default PricingDateFilter;
