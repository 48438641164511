import {
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import { useCallback, useState } from 'react';

import { ServiceErrorPartsFragment } from '../graphql/fragments';

/**
 * This is an util fn to retrieve the first/only attribute which contains the mutation response from the
 * onCompleted callback param. After the response is retrived the mutation is able to check whether the response
 * is a ServiceError o success response.
 */
const getFirstAttribute = <T extends object>(param: T) => {
  const firstAttribute = Object.keys(param)[0];
  return param[firstAttribute];
};

export type ServiceMutationType<TData = unknown, TVariables = Record<string, unknown>> = [
  (options?: MutationFunctionOptions<TData, TVariables> | undefined) => Promise<FetchResult<TData>>,
  MutationResult<TData> & {
    serviceError: ServiceErrorPartsFragment | null;
  }
];
export function useServiceMutation<
  TData extends Record<string, unknown> = Record<string, unknown>,
  TVariables = Record<string, unknown>
>(
  mutation: DocumentNode,
  options: MutationHookOptions<TData, TVariables>
): ServiceMutationType<TData, TVariables> {
  const [serviceError, setServiceError] = useState<ServiceErrorPartsFragment | null>(null);

  const onCompleted = useCallback(
    (data: TData) => {
      const attr = getFirstAttribute(data);
      if (attr.__typename === 'ServiceError') {
        setServiceError(attr);
      } else {
        setServiceError(null);
        options.onCompleted?.(data);
      }
    },
    [options]
  );

  const [mutationFn, mutationMeta] = useMutation<TData, TVariables>(mutation, {
    ...options,
    onCompleted,
  });

  return [mutationFn, { ...mutationMeta, serviceError }];
}
