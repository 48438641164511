import { ISODateTime, LinkButton, Popover, timeUtil } from '@cmg/common';
import React from 'react';

import { IndicationNoteType } from '../../../../../../../graphql';
import { SAuditInfo, SContent, SHeading, SText, STitle } from './NotePopover.styles';

export type Props = {
  title: string;
  type: IndicationNoteType;
  text: string;
  modifiedAt?: ISODateTime | null;
  onClick: () => void;
};

export const NotePopover: React.FC<Props> = ({
  text,
  type,
  title,
  children,
  onClick,
  modifiedAt,
}) => {
  return (
    <Popover
      variant="DARK"
      content={() => {
        return (
          <SContent>
            <SHeading>
              <STitle>{title}</STitle>
              {(type === IndicationNoteType.SalesAndTradingNote ||
                type === IndicationNoteType.SyndicateNote) && (
                <LinkButton inline onClick={() => onClick()}>
                  See All
                </LinkButton>
              )}
            </SHeading>
            <SText>{text}</SText>
            {modifiedAt && <SAuditInfo>{timeUtil.formatAsDisplayDateTime(modifiedAt)}</SAuditInfo>}
          </SContent>
        );
      }}
    >
      {children}
    </Popover>
  );
};
