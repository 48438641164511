/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Offering_OfferingDetails_BasicPublishedOfferingProfilePartsFragment = {
  readonly __typename?: 'BasicPublishedOfferingProfile';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly issuerName: string;
  readonly issuerBusinessDescription?: string | null;
  readonly issuerSector?: Types.Sector | null;
  readonly securityType: Types.SecurityType;
  readonly hasForwardAgreement?: boolean | null;
  readonly pricingCurrencyCode: string;
  readonly pricingDate?: string | null;
  readonly booksCloseAt?: string | null;
  readonly timeZone?: string | null;
  readonly firstTradeDate?: string | null;
  readonly settlementDate?: string | null;
  readonly terminatedDate?: string | null;
  readonly disclaimers: {
    readonly __typename?: 'DisclaimersProfile';
    readonly restrictions?: string | null;
    readonly footnotes?: string | null;
    readonly registrationLinks: ReadonlyArray<string>;
    readonly rule134Legend?: string | null;
  };
  readonly marketing: {
    readonly __typename?: 'Marketing';
    readonly id: string;
    readonly managementTeam?: string | null;
    readonly callDetails?: string | null;
  };
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerProfile';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly role: Types.ManagerRole;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    }>;
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilitiesProfile';
      readonly stabilizationAgent?: string | null;
      readonly settlementAgent?: string | null;
      readonly logisticsAgent?: string | null;
      readonly leftLead?: string | null;
    };
  };
  readonly prospectusDocuments: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocumentProfile';
    readonly id: string;
    readonly documentName: string;
    readonly publicUrl: string;
    readonly firstPublishedAt?: string | null;
  }>;
  readonly terms: {
    readonly __typename?: 'TermsProfile';
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricingProfile';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingProfile';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly totalSharesOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly totalSharesBaseOffering?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
    }>;
  };
  readonly roadshowSchedules: ReadonlyArray<{
    readonly __typename?: 'RoadshowScheduleProfile';
    readonly id: string;
    readonly date: string;
    readonly details: string;
  }>;
};

export type Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type Offering_OfferingDetails_BasicPublishedOfferingProfileQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly issuerName: string;
    readonly issuerBusinessDescription?: string | null;
    readonly issuerSector?: Types.Sector | null;
    readonly securityType: Types.SecurityType;
    readonly hasForwardAgreement?: boolean | null;
    readonly pricingCurrencyCode: string;
    readonly pricingDate?: string | null;
    readonly booksCloseAt?: string | null;
    readonly timeZone?: string | null;
    readonly firstTradeDate?: string | null;
    readonly settlementDate?: string | null;
    readonly terminatedDate?: string | null;
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilitiesProfile';
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
      readonly documentName: string;
      readonly publicUrl: string;
      readonly firstPublishedAt?: string | null;
    }>;
    readonly terms: {
      readonly __typename?: 'TermsProfile';
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricingProfile';
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'FilingProfile';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly totalSharesOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly totalSharesBaseOffering?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
      }>;
    };
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowScheduleProfile';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
  };
};

export type Offering_OfferingDetails_Offering_ManagerResponsibilitiesPartsFragment = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: string;
  readonly stabilizationAgent?: string | null;
  readonly settlementAgent?: string | null;
  readonly logisticsAgent?: string | null;
  readonly leftLead?: string | null;
};

export type Offering_OfferingDetails_Offering_ManagerPartsFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly isJuniorRole: boolean;
  readonly isParticipating: boolean;
  readonly economics?: number | null;
  readonly firmName: string;
};

export type Offering_OfferingDetails_Offering_DisclaimersPartsFragment = {
  readonly __typename?: 'Disclaimers';
  readonly id: string;
  readonly restrictions?: string | null;
  readonly footnotes?: string | null;
  readonly registrationLinks: ReadonlyArray<string>;
  readonly rule134Legend?: string | null;
};

export type Offering_OfferingDetails_Offering_MarketingPartsFragment = {
  readonly __typename?: 'Marketing';
  readonly id: string;
  readonly managementTeam?: string | null;
  readonly callDetails?: string | null;
};

export type Offering_OfferingDetails_Offering_FollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly id: string;
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type Offering_OfferingDetails_Offering_FilingPartsFragment = {
  readonly __typename?: 'Filing';
  readonly id: string;
  readonly filingType: Types.FilingType;
  readonly initialRegistrationValue?: number | null;
  readonly primarySharesBaseOffering?: number | null;
  readonly secondarySharesBaseOffering?: number | null;
  readonly notionalOverAllotmentAuthorized?: number | null;
  readonly primarySharesOverAllotmentAuthorized?: number | null;
  readonly secondarySharesOverAllotmentAuthorized?: number | null;
  readonly notionalOverAllotmentExercised?: number | null;
  readonly primarySharesOverAllotmentExercised?: number | null;
  readonly secondarySharesOverAllotmentExercised?: number | null;
  readonly totalSharesOverAllotmentAuthorized?: number | null;
  readonly totalSharesBaseOffering?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly offerPrice?: number | null;
  readonly postOfferingShares?: number | null;
  readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
  readonly lockUpPeriod?: number | null;
  readonly recordStatus: Types.RecordStatus;
};

export type Offering_OfferingDetails_OfferingPartsFragment = {
  readonly __typename?: 'Offering';
  readonly id: string;
  readonly version: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly pricingInstrumentId?: string | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly sizeInShares?: number | null;
  readonly sizeInCurrency?: number | null;
  readonly hasForwardAgreement?: boolean | null;
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrument';
    readonly id: string;
    readonly countryCode: string;
    readonly stockSymbol?: string | null;
    readonly exchange?: string | null;
  }>;
  readonly syndicate: {
    readonly __typename?: 'Syndicate';
    readonly grossSpread?: number | null;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'Manager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly role: Types.ManagerRole;
      readonly isJuniorRole: boolean;
      readonly isParticipating: boolean;
      readonly economics?: number | null;
      readonly firmName: string;
    }>;
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilities';
      readonly id: string;
      readonly stabilizationAgent?: string | null;
      readonly settlementAgent?: string | null;
      readonly logisticsAgent?: string | null;
      readonly leftLead?: string | null;
    };
  };
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly name: string;
    readonly lei?: string | null;
    readonly cik?: string | null;
    readonly website?: string | null;
    readonly address?: string | null;
    readonly sector?: Types.Sector | null;
    readonly businessDescription?: string | null;
  };
  readonly security: {
    readonly __typename?: 'Security';
    readonly type: Types.SecurityType;
    readonly isDepositaryReceipt: boolean;
    readonly shareClass?: Types.SecurityShareClass | null;
  };
  readonly disclaimers: {
    readonly __typename?: 'Disclaimers';
    readonly id: string;
    readonly restrictions?: string | null;
    readonly footnotes?: string | null;
    readonly registrationLinks: ReadonlyArray<string>;
    readonly rule134Legend?: string | null;
  };
  readonly marketing: {
    readonly __typename?: 'Marketing';
    readonly id: string;
    readonly managementTeam?: string | null;
    readonly callDetails?: string | null;
  };
  readonly timing: {
    readonly __typename?: 'Timing';
    readonly confidentialFilingDate?: string | null;
    readonly publicFilingDate?: string | null;
    readonly filingOccurred?: Types.MarketTiming | null;
    readonly launchOccurred?: Types.MarketTiming | null;
    readonly launchDate?: string | null;
    readonly booksCloseAt?: string | null;
    readonly pricingDate?: string | null;
    readonly firstTradeDate?: string | null;
    readonly tradeDate?: string | null;
    readonly settlementDate?: string | null;
    readonly postponedDate?: string | null;
    readonly withdrawnDate?: string | null;
    readonly terminatedDate?: string | null;
    readonly timeZone?: string | null;
  };
  readonly terms: {
    readonly __typename?: 'Terms';
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricing';
      readonly id: string;
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly notionalOverAllotmentExercised?: number | null;
      readonly primarySharesOverAllotmentExercised?: number | null;
      readonly secondarySharesOverAllotmentExercised?: number | null;
      readonly totalSharesOverAllotmentAuthorized?: number | null;
      readonly totalSharesBaseOffering?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
  };
  readonly currencies: ReadonlyArray<{
    readonly __typename?: 'DemandCurrency';
    readonly currencyCode: string;
    readonly exchangeRate: number;
  }>;
  readonly deliveryInstruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrument';
    readonly id: string;
    readonly currencyCode: string;
    readonly countryCode: string;
    readonly isDepositaryReceipt: boolean;
    readonly stockSymbol?: string | null;
    readonly isin?: string | null;
    readonly cusip?: string | null;
    readonly figi?: string | null;
    readonly exchangeMic?: Types.ExchangeMic | null;
    readonly depositaryReceiptConversionRatio?: {
      readonly __typename?: 'DepositaryReceiptConversionRatio';
      readonly numerator: number;
      readonly denominator: number;
    } | null;
  }>;
  readonly roadshowSchedules: ReadonlyArray<{
    readonly __typename?: 'RoadshowSchedule';
    readonly id: string;
    readonly date: string;
    readonly details: string;
  }>;
  readonly tranches: ReadonlyArray<{
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencyIds: ReadonlyArray<string>;
  }>;
  readonly prospectusDocuments: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocument';
    readonly id: string;
    readonly documentName: string;
    readonly includeOnPublish: boolean;
    readonly publicUrl?: string | null;
    readonly uploadedAt: string;
    readonly firstPublishedAt?: string | null;
  }>;
};

export type Offering_OfferingDetails_OfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type Offering_OfferingDetails_OfferingQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly version: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly pricingInstrumentId?: string | null;
    readonly pricingCurrencyCode: string;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly sizeInShares?: number | null;
    readonly sizeInCurrency?: number | null;
    readonly hasForwardAgreement?: boolean | null;
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
      readonly exchange?: string | null;
    }>;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly grossSpread?: number | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isJuniorRole: boolean;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly id: string;
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly name: string;
      readonly lei?: string | null;
      readonly cik?: string | null;
      readonly website?: string | null;
      readonly address?: string | null;
      readonly sector?: Types.Sector | null;
      readonly businessDescription?: string | null;
    };
    readonly security: {
      readonly __typename?: 'Security';
      readonly type: Types.SecurityType;
      readonly isDepositaryReceipt: boolean;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
    readonly disclaimers: {
      readonly __typename?: 'Disclaimers';
      readonly id: string;
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly timing: {
      readonly __typename?: 'Timing';
      readonly confidentialFilingDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly launchDate?: string | null;
      readonly booksCloseAt?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly tradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly postponedDate?: string | null;
      readonly withdrawnDate?: string | null;
      readonly terminatedDate?: string | null;
      readonly timeZone?: string | null;
    };
    readonly terms: {
      readonly __typename?: 'Terms';
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricing';
        readonly id: string;
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'Filing';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly notionalOverAllotmentExercised?: number | null;
        readonly primarySharesOverAllotmentExercised?: number | null;
        readonly secondarySharesOverAllotmentExercised?: number | null;
        readonly totalSharesOverAllotmentAuthorized?: number | null;
        readonly totalSharesBaseOffering?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
        readonly recordStatus: Types.RecordStatus;
      }>;
    };
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly isin?: string | null;
      readonly cusip?: string | null;
      readonly figi?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowSchedule';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
    readonly tranches: ReadonlyArray<{
      readonly __typename?: 'Tranche';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly defaultInstrumentId?: string | null;
      readonly currencyIds: ReadonlyArray<string>;
    }>;
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocument';
      readonly id: string;
      readonly documentName: string;
      readonly includeOnPublish: boolean;
      readonly publicUrl?: string | null;
      readonly uploadedAt: string;
      readonly firstPublishedAt?: string | null;
    }>;
  };
};

export type Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfilePartsFragment =
  {
    readonly __typename?: 'ManagerResponsibilitiesProfile';
    readonly stabilizationAgent?: string | null;
    readonly settlementAgent?: string | null;
    readonly logisticsAgent?: string | null;
    readonly leftLead?: string | null;
  };

export type Offering_OfferingDetails_PublishedOffering_DisclaimersProfilePartsFragment = {
  readonly __typename?: 'DisclaimersProfile';
  readonly restrictions?: string | null;
  readonly footnotes?: string | null;
  readonly registrationLinks: ReadonlyArray<string>;
  readonly rule134Legend?: string | null;
};

export type Offering_OfferingDetails_PublishedOffering_MarketingPartsFragment = {
  readonly __typename?: 'Marketing';
  readonly id: string;
  readonly managementTeam?: string | null;
  readonly callDetails?: string | null;
};

export type Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfilePartsFragment = {
  readonly __typename?: 'FollowOnPricingProfile';
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type Offering_OfferingDetails_PublishedOffering_FilingProfilePartsFragment = {
  readonly __typename?: 'FilingProfile';
  readonly id: string;
  readonly filingType: Types.FilingType;
  readonly initialRegistrationValue?: number | null;
  readonly primarySharesBaseOffering?: number | null;
  readonly secondarySharesBaseOffering?: number | null;
  readonly notionalOverAllotmentAuthorized?: number | null;
  readonly totalSharesOverAllotmentAuthorized?: number | null;
  readonly primarySharesOverAllotmentAuthorized?: number | null;
  readonly secondarySharesOverAllotmentAuthorized?: number | null;
  readonly totalSharesBaseOffering?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly offerPrice?: number | null;
  readonly postOfferingShares?: number | null;
  readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
  readonly lockUpPeriod?: number | null;
};

export type Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfilePartsFragment = {
  readonly __typename?: 'ProspectusDocumentProfile';
  readonly id: string;
  readonly documentName: string;
  readonly publicUrl: string;
  readonly firstPublishedAt?: string | null;
};

export type Offering_OfferingDetails_PublishedOfferingPartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly version: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly isExecutingOnPlatform: boolean;
  readonly pricingInstrumentId: string;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly pricingCurrencyCode: string;
  readonly supersededByOfferingId?: string | null;
  readonly supersededOfferingId?: string | null;
  readonly sizeInShares?: number | null;
  readonly sizeInCurrency?: number | null;
  readonly hasForwardAgreement?: boolean | null;
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerProfile';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly role: Types.ManagerRole;
      readonly firmName: string;
    }>;
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilitiesProfile';
      readonly stabilizationAgent?: string | null;
      readonly settlementAgent?: string | null;
      readonly logisticsAgent?: string | null;
      readonly leftLead?: string | null;
    };
  };
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly name: string;
    readonly businessDescription?: string | null;
    readonly sector?: Types.Sector | null;
  };
  readonly security: {
    readonly __typename?: 'SecurityProfile';
    readonly type: Types.SecurityType;
    readonly shareClass?: Types.SecurityShareClass | null;
  };
  readonly disclaimers: {
    readonly __typename?: 'DisclaimersProfile';
    readonly restrictions?: string | null;
    readonly footnotes?: string | null;
    readonly registrationLinks: ReadonlyArray<string>;
    readonly rule134Legend?: string | null;
  };
  readonly marketing: {
    readonly __typename?: 'Marketing';
    readonly id: string;
    readonly managementTeam?: string | null;
    readonly callDetails?: string | null;
  };
  readonly timing: {
    readonly __typename?: 'TimingProfile';
    readonly booksCloseAt?: string | null;
    readonly timeZone?: string | null;
    readonly pricingDate?: string | null;
    readonly firstTradeDate?: string | null;
    readonly settlementDate?: string | null;
    readonly terminatedDate?: string | null;
    readonly launchDate?: string | null;
    readonly publicFilingDate?: string | null;
    readonly confidentialFilingDate?: string | null;
    readonly filingOccurred?: Types.MarketTiming | null;
    readonly launchOccurred?: Types.MarketTiming | null;
    readonly withdrawnDate?: string | null;
  };
  readonly demandCurrenciesWithExchangeRates: ReadonlyArray<{
    readonly __typename?: 'DemandCurrencyProfile';
    readonly currencyCode: string;
    readonly exchangeRate?: number | null;
  }>;
  readonly terms: {
    readonly __typename?: 'TermsProfile';
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricingProfile';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingProfile';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly totalSharesOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly totalSharesBaseOffering?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
    }>;
  };
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrumentPrivileged';
    readonly id: string;
    readonly currencyCode: string;
    readonly countryCode: string;
    readonly isDepositaryReceipt: boolean;
    readonly stockSymbol?: string | null;
    readonly exchange?: string | null;
    readonly exchangeMic?: Types.ExchangeMic | null;
    readonly cusip?: string | null;
    readonly isin?: string | null;
    readonly figi?: string | null;
    readonly depositaryReceiptConversionRatio?: {
      readonly __typename?: 'DepositaryReceiptConversionRatio';
      readonly numerator: number;
      readonly denominator: number;
    } | null;
  }>;
  readonly roadshowSchedules: ReadonlyArray<{
    readonly __typename?: 'RoadshowScheduleProfile';
    readonly id: string;
    readonly date: string;
    readonly details: string;
  }>;
  readonly prospectusDocuments: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocumentProfile';
    readonly id: string;
    readonly documentName: string;
    readonly publicUrl: string;
    readonly firstPublishedAt?: string | null;
  }>;
  readonly managerEconomics?: {
    readonly __typename?: 'ManagerEconomicsProfile';
    readonly underwritingBaseSharesDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
  } | null;
  readonly tranches?: ReadonlyArray<{
    readonly __typename?: 'TranchePrivileged';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly deliveryInstrumentIds: ReadonlyArray<string>;
    readonly demandCurrencies: ReadonlyArray<string>;
    readonly defaultInstrumentId?: string | null;
  }> | null;
};

export type Offering_OfferingDetails_PublishedOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type Offering_OfferingDetails_PublishedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly version: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly isExecutingOnPlatform: boolean;
    readonly pricingInstrumentId: string;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly pricingCurrencyCode: string;
    readonly supersededByOfferingId?: string | null;
    readonly supersededOfferingId?: string | null;
    readonly sizeInShares?: number | null;
    readonly sizeInCurrency?: number | null;
    readonly hasForwardAgreement?: boolean | null;
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly firmName: string;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilitiesProfile';
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly name: string;
      readonly businessDescription?: string | null;
      readonly sector?: Types.Sector | null;
    };
    readonly security: {
      readonly __typename?: 'SecurityProfile';
      readonly type: Types.SecurityType;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly timing: {
      readonly __typename?: 'TimingProfile';
      readonly booksCloseAt?: string | null;
      readonly timeZone?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly terminatedDate?: string | null;
      readonly launchDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly confidentialFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly withdrawnDate?: string | null;
    };
    readonly demandCurrenciesWithExchangeRates: ReadonlyArray<{
      readonly __typename?: 'DemandCurrencyProfile';
      readonly currencyCode: string;
      readonly exchangeRate?: number | null;
    }>;
    readonly terms: {
      readonly __typename?: 'TermsProfile';
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricingProfile';
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'FilingProfile';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly totalSharesOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly totalSharesBaseOffering?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
      }>;
    };
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly exchange?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly cusip?: string | null;
      readonly isin?: string | null;
      readonly figi?: string | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowScheduleProfile';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
      readonly documentName: string;
      readonly publicUrl: string;
      readonly firstPublishedAt?: string | null;
    }>;
    readonly managerEconomics?: {
      readonly __typename?: 'ManagerEconomicsProfile';
      readonly underwritingBaseSharesDistribution: ReadonlyArray<{
        readonly __typename?: 'SharesPerCmgEntity';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly sharesValue: number;
      }>;
    } | null;
    readonly tranches?: ReadonlyArray<{
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly deliveryInstrumentIds: ReadonlyArray<string>;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly defaultInstrumentId?: string | null;
    }> | null;
  };
};

export const Offering_OfferingDetails_PublishedOffering_DisclaimersProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOffering_MarketingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfilePartsFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
            { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
            { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
            { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfilePartsFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
            { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
            { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOffering_FilingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_BasicPublishedOfferingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_BasicPublishedOfferingProfileParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerBusinessDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_ManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_ManagerResponsibilitiesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_Offering_ManagerResponsibilitiesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_DisclaimersPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_MarketingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_FollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_Offering_FilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_OfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_ManagerParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_ManagerResponsibilitiesParts',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_Offering_DisclaimersParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_MarketingParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_FollowOnPricingParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_FilingParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIds' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_Offering_ManagerResponsibilitiesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_PublishedOfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededByOfferingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededOfferingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandCurrenciesWithExchangeRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerEconomics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingDetails_BasicPublishedOfferingProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_BasicPublishedOfferingProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_BasicPublishedOfferingProfileParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_BasicPublishedOfferingProfileParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerBusinessDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery__
 *
 * To run a query within a React component, call `useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    Offering_OfferingDetails_BasicPublishedOfferingProfileQuery,
    Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Offering_OfferingDetails_BasicPublishedOfferingProfileQuery,
    Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables
  >(Offering_OfferingDetails_BasicPublishedOfferingProfileDocument, options);
}
export function useOffering_OfferingDetails_BasicPublishedOfferingProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Offering_OfferingDetails_BasicPublishedOfferingProfileQuery,
    Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Offering_OfferingDetails_BasicPublishedOfferingProfileQuery,
    Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables
  >(Offering_OfferingDetails_BasicPublishedOfferingProfileDocument, options);
}
export type Offering_OfferingDetails_BasicPublishedOfferingProfileQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery
>;
export type Offering_OfferingDetails_BasicPublishedOfferingProfileLazyQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_BasicPublishedOfferingProfileLazyQuery
>;
export type Offering_OfferingDetails_BasicPublishedOfferingProfileQueryResult = Apollo.QueryResult<
  Offering_OfferingDetails_BasicPublishedOfferingProfileQuery,
  Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables
>;
export const Offering_OfferingDetails_OfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingDetails_OfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_Offering_ManagerResponsibilitiesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_ManagerParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_ManagerResponsibilitiesParts',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_Offering_DisclaimersParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingDetails_Offering_MarketingParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_FollowOnPricingParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_Offering_FilingParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIds' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOffering_OfferingDetails_OfferingQuery__
 *
 * To run a query within a React component, call `useOffering_OfferingDetails_OfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffering_OfferingDetails_OfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffering_OfferingDetails_OfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOffering_OfferingDetails_OfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Offering_OfferingDetails_OfferingQuery,
    Offering_OfferingDetails_OfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Offering_OfferingDetails_OfferingQuery,
    Offering_OfferingDetails_OfferingQueryVariables
  >(Offering_OfferingDetails_OfferingDocument, options);
}
export function useOffering_OfferingDetails_OfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Offering_OfferingDetails_OfferingQuery,
    Offering_OfferingDetails_OfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Offering_OfferingDetails_OfferingQuery,
    Offering_OfferingDetails_OfferingQueryVariables
  >(Offering_OfferingDetails_OfferingDocument, options);
}
export type Offering_OfferingDetails_OfferingQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_OfferingQuery
>;
export type Offering_OfferingDetails_OfferingLazyQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_OfferingLazyQuery
>;
export type Offering_OfferingDetails_OfferingQueryResult = Apollo.QueryResult<
  Offering_OfferingDetails_OfferingQuery,
  Offering_OfferingDetails_OfferingQueryVariables
>;
export const Offering_OfferingDetails_PublishedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededByOfferingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededOfferingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandCurrenciesWithExchangeRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerEconomics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOffering_OfferingDetails_PublishedOfferingQuery__
 *
 * To run a query within a React component, call `useOffering_OfferingDetails_PublishedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffering_OfferingDetails_PublishedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffering_OfferingDetails_PublishedOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOffering_OfferingDetails_PublishedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Offering_OfferingDetails_PublishedOfferingQuery,
    Offering_OfferingDetails_PublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Offering_OfferingDetails_PublishedOfferingQuery,
    Offering_OfferingDetails_PublishedOfferingQueryVariables
  >(Offering_OfferingDetails_PublishedOfferingDocument, options);
}
export function useOffering_OfferingDetails_PublishedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Offering_OfferingDetails_PublishedOfferingQuery,
    Offering_OfferingDetails_PublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Offering_OfferingDetails_PublishedOfferingQuery,
    Offering_OfferingDetails_PublishedOfferingQueryVariables
  >(Offering_OfferingDetails_PublishedOfferingDocument, options);
}
export type Offering_OfferingDetails_PublishedOfferingQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_PublishedOfferingQuery
>;
export type Offering_OfferingDetails_PublishedOfferingLazyQueryHookResult = ReturnType<
  typeof useOffering_OfferingDetails_PublishedOfferingLazyQuery
>;
export type Offering_OfferingDetails_PublishedOfferingQueryResult = Apollo.QueryResult<
  Offering_OfferingDetails_PublishedOfferingQuery,
  Offering_OfferingDetails_PublishedOfferingQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOffering_OfferingDetails_BasicPublishedOfferingProfileMockResponse =
  makeMockResponse<
    Offering_OfferingDetails_BasicPublishedOfferingProfileQueryVariables,
    Offering_OfferingDetails_BasicPublishedOfferingProfileQuery
  >(Offering_OfferingDetails_BasicPublishedOfferingProfileDocument);

export const makeOffering_OfferingDetails_OfferingMockResponse = makeMockResponse<
  Offering_OfferingDetails_OfferingQueryVariables,
  Offering_OfferingDetails_OfferingQuery
>(Offering_OfferingDetails_OfferingDocument);

export const makeOffering_OfferingDetails_PublishedOfferingMockResponse = makeMockResponse<
  Offering_OfferingDetails_PublishedOfferingQueryVariables,
  Offering_OfferingDetails_PublishedOfferingQuery
>(Offering_OfferingDetails_PublishedOfferingDocument);
