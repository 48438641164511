import { LinkButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SCertificatesContentWrapper = styled.div`
  padding: 10px 16px 10px 16px;
`;

export const StyledComplianceLink = styled(LinkButton)`
  padding: 0px;
`;

export const SComplianceTableHeader = styled.div`
  padding-top: 10px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SComplianceTableWrapper = styled.div`
  margin: 10px 0 10px 0;
`;
