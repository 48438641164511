import { ApolloCache } from '@apollo/client';

import { Roadshows_UpdateRoadshowMutation, useRoadshows_UpdateRoadshowMutation } from '../graphql';

export type UpdateRoadshowMutation = Roadshows_UpdateRoadshowMutation;

export const updateCache = (cache: ApolloCache<Roadshows_UpdateRoadshowMutation>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'validateRoadshowName' });
};

export const useUpdateRoadshowMutation = () => {
  return useRoadshows_UpdateRoadshowMutation({
    // reset the cache of validation endpoint
    update: updateCache,
  });
};
