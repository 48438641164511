import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import { calculateGrossSpreadTableValues } from './GrossSpreadTable.model';
import { SSourceLabel } from './GrossSpreadTable.styles';

const GrossSpreadTable: React.FC = () => {
  const accounting = useAccountingContext();
  const {
    baseManagementFee,
    incentiveManagementFee,
    baseUnderwritingFee,
    incentiveUnderwritingFee,
    baseSellingConcession,
    incentiveSellingConcession,
  } = accounting;

  const {
    baseManagementFeeValue,
    baseUnderwritingFeeValue,
    baseSellingConcessionValue,
    incentiveManagementFeeValue,
    incentiveUnderwritingFeeValue,
    incentiveSellingConcessionValue,
    totalManagementFeeGrossSpread,
    totalUnderwritingFeeGrossSpread,
    totalSellingConcessionGrossSpread,
    totalBaseAllocation,
    totalIncentiveAllocation,
    totalBaseDecomposition,
    totalIncentiveDecomposition,
    totalGrossSpread,
  } = calculateGrossSpreadTableValues(accounting);

  return (
    <Table gridTemplateColumns="1.6fr 1fr 1fr 0.3fr 1.3fr 1.3fr 1.3fr">
      <Table.Cell withBottomBorder={false} />
      <Table.TableHeaderCell colSpan={3} align="center" nonSticky>
        Gross Spread Allocation
        <SSourceLabel>Sourced from Offering Setup</SSourceLabel>
      </Table.TableHeaderCell>
      <Table.TableHeaderCell colSpan={3} align="center" centered nonSticky>
        Gross Spread Decomposition
      </Table.TableHeaderCell>

      <Table.TableHeaderCell withRightBorder={false} nonSticky />
      <Table.TableHeaderCell align="right" nonSticky>
        Base %
      </Table.TableHeaderCell>
      <Table.TableHeaderCell align="right" withRightBorder={false} nonSticky>
        Incentive %
      </Table.TableHeaderCell>
      <Table.TableHeaderCell align="right" nonSticky />
      <Table.TableHeaderCell align="right" nonSticky>
        Base $
      </Table.TableHeaderCell>
      <Table.TableHeaderCell align="right" nonSticky>
        Incentive $
      </Table.TableHeaderCell>
      <Table.TableHeaderCell align="right" nonSticky>
        Total Gross Spread
      </Table.TableHeaderCell>

      <Table.Cell>Management Fee</Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(baseManagementFee, 0)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(incentiveManagementFee, 0)}
      </Table.Cell>
      <Table.Cell withRightBorder />
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(baseManagementFeeValue, 6)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(incentiveManagementFeeValue, 6)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalManagementFeeGrossSpread, 6)}
      </Table.Cell>

      <Table.Cell>Underwriting Fee</Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(baseUnderwritingFee, 0)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(incentiveUnderwritingFee, 0)}
      </Table.Cell>
      <Table.Cell withRightBorder />
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(baseUnderwritingFeeValue, 6)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(incentiveUnderwritingFeeValue, 6)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalUnderwritingFeeGrossSpread, 6)}
      </Table.Cell>

      <Table.Cell>Selling Concession</Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(baseSellingConcession, 0)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForPercents(incentiveSellingConcession, 0)}
      </Table.Cell>
      <Table.Cell withRightBorder />
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(baseSellingConcessionValue, 6)}
      </Table.Cell>
      <Table.Cell align="right">
        {numericUtil.getDisplayValueForCurrency(incentiveSellingConcessionValue, 6)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalSellingConcessionGrossSpread, 6)}
      </Table.Cell>

      <Table.Cell bold>Total</Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForPercents(totalBaseAllocation, 0)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForPercents(totalIncentiveAllocation, 0)}
      </Table.Cell>
      <Table.Cell withRightBorder />
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalBaseDecomposition, 6)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalIncentiveDecomposition, 6)}
      </Table.Cell>
      <Table.Cell align="right" bold>
        {numericUtil.getDisplayValueForCurrency(totalGrossSpread, 6)}
      </Table.Cell>
    </Table>
  );
};

export default GrossSpreadTable;
