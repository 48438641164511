import { numericUtil } from '@cmg/common';
import React from 'react';

import TableTotalRow from '../../../../../../design-system/components/data-display/table/TableTotalRow';
import TableTotalRowCell from '../../../../../../design-system/components/data-display/table/TableTotalRowCell';
import TotalRetailDemandTotalRetentionCell from './total-retention-cell/TotalRetailDemandTotalRetentionCell';

export type Props = Readonly<{
  totalDemandShares: number | null;
  totalRoundLots: number | null;
  totalRetention: number | null;
  retailTarget: number | null;
  isReadOnly: boolean;
}>;

const TotalRetailDemandTableTotalRow: React.FC<Props> = ({
  totalDemandShares,
  totalRoundLots,
  totalRetention,
  retailTarget,
  isReadOnly,
}) => {
  return (
    <TableTotalRow>
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium">Total</TableTotalRowCell>
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell isEditable={!isReadOnly} align="right" size="medium">
        {numericUtil.getDisplayValueForInteger(totalDemandShares)}
      </TableTotalRowCell>
      <TableTotalRowCell isEditable={!isReadOnly} align="right" size="medium">
        {numericUtil.getDisplayValueForInteger(totalRoundLots)}
      </TableTotalRowCell>
      <TotalRetailDemandTotalRetentionCell
        totalRetention={totalRetention}
        retailTarget={retailTarget}
        isEditable={!isReadOnly}
      />
    </TableTotalRow>
  );
};

export default TotalRetailDemandTableTotalRow;
