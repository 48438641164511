import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import offeringSidePanelBuySideRouteFactory from '../offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from '../OfferingSidePanelContainer';
import CommunicatedDemandRoute from './ioi-tracker/CommunicatedDemandRoute';

const OrderAllocationRoute: React.FC = () => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  return (
    <Switch>
      <Route
        path={offeringSidePanelBuySideRouteFactory.communicatedDemand.routePath}
        component={CommunicatedDemandRoute}
      />
      <Redirect
        to={baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.communicatedDemand.getUrlPath()}
      />
    </Switch>
  );
};

export default OrderAllocationRoute;
