import { numericUtil } from '@cmg/common';
import { InputAdornment, Table, TableCell, TableContainer, TableRow } from '@cmg/design-system';
import { FormikCheckboxField } from '@cmg/design-system-formik';
import { VFC } from 'react';

import { FormikNumericField } from '../../../common/components/form/formik-numeric-field/FormikNumericField';
import { OperationType } from '../../../common/components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { DefaultRecipientValues } from '../../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { SyndicateRecipientsUserDataFormFields } from '../../../common/components/recipients-user-data-form-field/SyndicateRecipientsUserDataFormFields';
import { SyndicateWires_BrokerDealersMyRetailQuery } from '../../../common/graphql';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { NonSyndicatePaymentRecipientUserData, Values } from '../NonSyndicatePaymentWireForm.model';
import { RecipientsTableHead } from './RecipientsTableHead';

export type Props = Readonly<{
  managers: SyndicateWireManager[];
  operationType: OperationType;
  retailForManagers: SyndicateWires_BrokerDealersMyRetailQuery['syndicateWires']['brokerDealersMyRetail'];
}>;

const defaultRecipientValues: DefaultRecipientValues<NonSyndicatePaymentRecipientUserData> = {
  payment: null,
  ipoPenaltyTracking: false,
  sellingConcession: null,
};

export const RecipientsTable: VFC<Props> = ({ managers, operationType, retailForManagers }) => {
  return (
    <TableContainer>
      <Table aria-label="Payment Wire Recipients Table">
        <RecipientsTableHead />
        <SyndicateRecipientsUserDataFormFields<NonSyndicatePaymentRecipientUserData, Values>
          managers={managers}
          operationType={operationType}
          numColumns={5}
          defaultRecipientValues={defaultRecipientValues}
          renderRecipientRow={({ manager, managerIndex, onChange }) => {
            const { retentionAmount } =
              retailForManagers.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey) ??
              {};

            return (
              <TableRow key={manager.cmgEntityKey}>
                <TableCell>{manager.firmName}</TableCell>
                <TableCell align="right">
                  <FormikNumericField
                    startAdornment={
                      <InputAdornment
                        data-testid={`${manager.firmName}-payment-input-adornment`}
                        position="start"
                      >
                        $
                      </InputAdornment>
                    }
                    inputProps={{
                      'aria-label': `${manager.firmName} - Payment`,
                    }}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.payment`}
                    onChange={payment => {
                      onChange({ payment });
                    }}
                  />
                </TableCell>
                <TableCell align="right" data-testid={`${manager.firmName}-retention-cell`}>
                  {numericUtil.getDisplayValueForInteger(retentionAmount)}
                </TableCell>
                <TableCell align="right">
                  <FormikNumericField
                    startAdornment={
                      <InputAdornment
                        data-testid={`${manager.firmName}-selling-concession-input-adornment`}
                        position="start"
                      >
                        $
                      </InputAdornment>
                    }
                    inputProps={{
                      'aria-label': `${manager.firmName} - Selling Concession`,
                    }}
                    precision={6}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.sellingConcession`}
                    onChange={sellingConcession => {
                      onChange({ sellingConcession });
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormikCheckboxField
                    inputProps={{
                      'aria-label': `${manager.firmName} - IPO Penalty Tracking`,
                    }}
                    name={`recipientsUserData.${managerIndex}.ipoPenaltyTracking`}
                    onChange={ipoPenaltyTracking => {
                      onChange({ ipoPenaltyTracking });
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};
