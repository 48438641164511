import { IconButton, Menu, MenuItem, MoreVertOutlinedIcon } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

export type Props = {
  disabled?: boolean;
  discardOfferingDisabled?: boolean;
  onClickDiscardOffering: () => void;
};

const ActionsDropdownButton: React.FC<Props> = ({
  disabled,
  discardOfferingDisabled,
  onClickDiscardOffering,
}) => {
  const btnRef = React.useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = React.useState(false);
  return (
    <div>
      <IconButton
        size="small"
        color="primary"
        data-testid={xcSelectors.offeringSetupSummaryMoreDropdownButton.testId}
        aria-label="more"
        disabled={disabled}
        ref={btnRef}
        aria-controls="menu-id"
        onClick={() => setIsOpened(open => !open)}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        open={isOpened}
        onClose={() => setIsOpened(false)}
        id="menu-id"
        anchorEl={btnRef.current}
      >
        <MenuItem
          disableRipple
          disabled={discardOfferingDisabled}
          onClick={() => {
            setIsOpened(false);
            onClickDiscardOffering();
          }}
        >
          Discard Offering
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionsDropdownButton;
