import { useCheckAccountTraits } from '@cmg/auth';
import { Grid, Tooltip } from '@cmg/design-system';
import { FormikAutocompleteField, FormikTextField } from '@cmg/design-system-formik';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { DeliveryInstrument, OfferingType } from '../../../../graphql';
import {
  offeringTypesOptions,
  usOfferingTypeToInternational,
} from '../../../../types/domain/offering/constants';
import { sectorOptions } from '../../../constants';
import {
  OfferingSetup_BasicInfoRoute_BasicInfoQuery,
  OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
} from '../../graphql';
import { getOfferingPricingCountryCode } from '../../utils/offeringTypeUtil';
import {
  getInternationalOfferingSetupTypesOptions,
  getOfferingSetupTypesOptions,
  getOfferingTypeProps,
} from './IssuerSubForm.model';

export type Props = {
  offering?: OfferingSetup_BasicInfoRoute_BasicInfoQuery['offering'];
  publishedOffering?: OfferingSetup_BasicInfoRoute_PublishedOfferingQuery['publishedOffering'];
  submitting?: boolean;
};

const defaultParseFormikValue = (value, options) => {
  return options.find(option => option.value === value);
};
const defaultParseToFormikValue = option => {
  return option?.value;
};

/**
 * A reusable subsection of Fields for Formik forms
 * Used by BasicInfoForm & CreateOfferingForm
 */
export const IssuerSubForm: React.FC<Props> = ({ submitting, offering, publishedOffering }) => {
  const { tooltipTitle, inputIsDisabled, excludeIpo } = getOfferingTypeProps(
    offering,
    publishedOffering
  );

  const hasInternationalOfferingTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const pricingCountryCode = getOfferingPricingCountryCode({
    instruments: offering?.instruments as DeliveryInstrument[],
    pricingInstrumentId: offering?.pricingInstrumentId!,
  });

  return (
    <React.Fragment>
      <Grid item md={3} sm={6} xs={12}>
        <Tooltip title={tooltipTitle}>
          <span>
            {!hasInternationalOfferingTrait ? (
              <FormikAutocompleteField<(typeof offeringTypesOptions)[0], false, true>
                data-testid={xcSelectors.offeringSetupBasicInfoOfferingType.testId}
                name="type"
                label="Offering Type"
                disableClearable
                required
                parseFormikValue={defaultParseFormikValue}
                parseToFormikValue={defaultParseToFormikValue}
                placeholder="Choose Offering Type..."
                disabled={inputIsDisabled || submitting}
                options={getOfferingSetupTypesOptions({
                  excludeIpo,
                })}
                fullWidth
              />
            ) : (
              <FormikAutocompleteField<(typeof offeringTypesOptions)[0], false, true>
                data-testid={xcSelectors.offeringSetupBasicInfoOfferingType.testId}
                name="type"
                label="Offering Type"
                disableClearable
                required
                parseFormikValue={(value, options) => {
                  if (pricingCountryCode === 'US' || !pricingCountryCode) {
                    return defaultParseFormikValue(value, options);
                  }
                  const internationalValue = usOfferingTypeToInternational[value as OfferingType];
                  return defaultParseFormikValue(internationalValue, options);
                }}
                parseToFormikValue={defaultParseToFormikValue}
                placeholder="Choose Offering Type..."
                disabled={inputIsDisabled || submitting}
                options={getInternationalOfferingSetupTypesOptions({
                  excludeIpo,
                })}
                fullWidth
                groupBy={option => option.group!}
              />
            )}
          </span>
        </Tooltip>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <FormikTextField
          name="issuer.name"
          label="Issuer Name"
          placeholder="Enter Name..."
          disabled={submitting}
          fullWidth
          required
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <FormikTextField
          name="issuer.cik"
          label="Issuer CIK"
          placeholder="Enter CIK..."
          disabled={submitting}
          fullWidth
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <FormikAutocompleteField
          data-testid={xcSelectors.offeringSetupBasicInfoSector.testId}
          label="Sector"
          placeholder="Choose Sector..."
          name="issuer.sector"
          parseFormikValue={defaultParseFormikValue}
          parseToFormikValue={defaultParseToFormikValue}
          disabled={submitting}
          fullWidth
          options={sectorOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name="issuer.businessDescription"
          placeholder="Enter Business Description..."
          disabled={submitting}
          label="Business Description"
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  );
};

export default IssuerSubForm;
