import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateRetentionWireMutation,
  SyndicateWires_RetentionWiresDocument,
  useSyndicateWires_CreateRetentionWireMutation,
} from '../graphql';

export type CreateRetentionWireMutation = SyndicateWires_CreateRetentionWireMutation;

export const useCreateRetentionWireMutation = () => {
  return useSyndicateWires_CreateRetentionWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_RetentionWiresDocument];
  return getManyOperationNames(documentNodes);
}
