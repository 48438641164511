import { numericUtil } from '@cmg/common';
import { WarningIcon } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React, { FC, useMemo } from 'react';

import TableTotalRowCell from '../../../../../../../design-system/components/data-display/table/TableTotalRowCell';

export type Props = Readonly<{
  totalRetention: number | null;
  retailTarget: number | null;
  isEditable: boolean;
}>;

const TotalRetailDemandTotalRetentionCell: FC<Props> = ({
  isEditable,
  totalRetention,
  retailTarget,
}) => {
  const isOverAllocated = useMemo(
    () => !isNil(totalRetention) && !isNil(retailTarget) && totalRetention > retailTarget,
    [retailTarget, totalRetention]
  );

  const secondaryText = useMemo(() => {
    return !isNil(retailTarget) && !isNil(totalRetention)
      ? numericUtil.getDisplayValueForInteger(retailTarget - totalRetention)
      : undefined;
  }, [retailTarget, totalRetention]);

  return (
    <TableTotalRowCell
      align="right"
      size="medium"
      isEditable={isEditable}
      secondaryText={secondaryText}
      IconComponent={isOverAllocated ? <WarningIcon color="error" fontSize="small" /> : null}
    >
      {numericUtil.getDisplayValueForInteger(totalRetention)}
    </TableTotalRowCell>
  );
};

export default TotalRetailDemandTotalRetentionCell;
