import { PrimaryButton, SecondaryButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

const buttonStyles = css`
  min-width: 110px;
  margin: 0 5px;
`;

export const StyledCancelButton = styled(SecondaryButton)`
  ${buttonStyles}
`;

export const StyledConfirmButton = styled(PrimaryButton)`
  ${buttonStyles}
`;

export const SWrapper = styled.div`
  background-color: ${({ theme }) => theme.background.color.white};
  width: 500px;
`;

export const SFooter = styled.div`
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
  padding: 10px 30px 15px 30px;
  display: flex;
  justify-content: flex-end;
`;

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.large};
  padding: 30px 30px 0;
`;

export const SContent = styled.div`
  padding: 10px 30px;
`;
