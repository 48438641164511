import React from 'react';

import Header from '../../../common/components/header/Header';
import { Roadshows_DealTeamPartsFragment } from '../../../common/graphql';

export type Props = {
  dealTeam?: Roadshows_DealTeamPartsFragment;
};

const DealTeamHeader: React.FC<Props> = ({ dealTeam, children }) => {
  return (
    <Header>
      <Header.Title>{dealTeam ? dealTeam.firmName : 'Create New Deal Team'}</Header.Title>
      <Header.EmptyContent />
      {children}
    </Header>
  );
};

export default DealTeamHeader;
