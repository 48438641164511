import { numericUtil, timeUtil } from '@cmg/common';
import { ListItemText, Stack, Typography } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../graphql/__generated__';

export type Props = Readonly<{
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment;
}>;

const { getDisplayValueForInteger, getDisplayValueForPercents, divide, sum, negate } = numericUtil;
const { formatAsDisplayDateTime } = timeUtil;

const AllocationSetListItemText: React.FC<Props> = ({ allocationSet }) => {
  const updatedAt = allocationSet.auditInfo.modifiedAt;
  const offeringSize = allocationSet.dealLayout.offeringSize;
  const institutionalTarget = allocationSet.dealLayout.institutionalTarget;

  const retail = React.useMemo(
    () => sum(offeringSize, negate(institutionalTarget)),
    [offeringSize, institutionalTarget]
  );

  return (
    <ListItemText>
      <Stack>
        {allocationSet.name}
        {updatedAt && (
          <Typography variant="body2" color="text.secondary" data-testid="last-updated">
            Last Updated: {formatAsDisplayDateTime(updatedAt)}
          </Typography>
        )}
        {!isNil(offeringSize) && (
          <Typography variant="body2" color="text.secondary" data-testid="offering-size">
            Offering Size: {getDisplayValueForInteger(offeringSize)}
          </Typography>
        )}
        {!isNil(institutionalTarget) && !isNil(offeringSize) && (
          <Typography variant="body2" color="text.secondary" data-testid="institutional-target">
            Institutional: {getDisplayValueForInteger(institutionalTarget)} (
            {getDisplayValueForPercents(divide(institutionalTarget, offeringSize))})
          </Typography>
        )}
        {!isNil(retail) && !isNil(offeringSize) && (
          <Typography variant="body2" color="text.secondary" data-testid="retail-target">
            Retail: {getDisplayValueForInteger(retail)} (
            {getDisplayValueForPercents(divide(retail, offeringSize))})
          </Typography>
        )}
      </Stack>
    </ListItemText>
  );
};

export default AllocationSetListItemText;
