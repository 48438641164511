import { PrimaryButton, ToastManager } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { Roadshows_RoadshowPartsFragment } from '../../../common/graphql';
import RoadshowCreationStep from '../common/creation-step/RoadshowCreationStep';
import RoadshowDaysAndLocations from './days-and-locations/RoadshowDaysAndLocations';
import { useRoadshowUniqueNameValidation } from './hooks/useRoadshowUniqueNameValidation';
import { useUpdateRoadshowMutation } from './hooks/useUpdateRoadshowMutation';
import RoadshowInformation from './roadshow-information/RoadshowInformation';
import {
  getRoadshowDetailsSchema,
  mapDataToForm,
  mapFormToRoadshow,
  RoadshowDetailsValues,
} from './RoadshowDetails.model';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
};

const RoadshowDetails: React.FC<Props> = ({ roadshow }) => {
  const [updateRoadshow, { loading: updateIsLoading }] = useUpdateRoadshowMutation();
  const validateRoadshowUniqueName = useRoadshowUniqueNameValidation();

  const handleUpdate = async (values: RoadshowDetailsValues) => {
    try {
      await updateRoadshow({
        variables: {
          roadshow: mapFormToRoadshow(values),
          roadshowId: roadshow.id,
        },
      });

      ToastManager.success('Successfully updated Roadshow');
    } catch {
      ToastManager.error('There was an error while updating Roadshow');
    }
  };

  const formik = useFormik<RoadshowDetailsValues>({
    validationSchema: getRoadshowDetailsSchema(validateRoadshowUniqueName, roadshow.roadshowName),
    initialValues: mapDataToForm(roadshow),
    onSubmit: handleUpdate,
  });

  return (
    <RoadshowCreationStep data-test-id="roadshows / single-roadshow / configuration / details">
      <RoadshowCreationStep.Header title="Roadshow Details">
        <PrimaryButton
          testId={xcSelectors.roadshowConfigSaveButton.testId}
          onClick={() => formik.submitForm()}
          loading={updateIsLoading}
        >
          Save Changes
        </PrimaryButton>
      </RoadshowCreationStep.Header>
      <RoadshowCreationStep.Content>
        <FormikProvider value={formik}>
          <Form>
            <RoadshowInformation />
            <RoadshowDaysAndLocations />
          </Form>
        </FormikProvider>
      </RoadshowCreationStep.Content>
    </RoadshowCreationStep>
  );
};

export default RoadshowDetails;
