import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';

import routeFactory from '../../../common/util/routeFactory';
import { stepIds } from '../../offering-setup/constants';
import { StyledLink } from './OfferingLink.styles';

type Props = {
  offeringId: UUID;
  isSharedDraft?: boolean;
  isDraft?: boolean;
  isAuthor?: boolean;
};

export const OfferingLink: React.FC<Props> = ({
  offeringId,
  children,
  isDraft = false,
  isSharedDraft = false,
  isAuthor = false,
}) => {
  const hasFullOfferingPermissions = useCheckPermissions([permissionsByEntity.Offering.FULL]);

  const canAccessOfferingSetup =
    hasFullOfferingPermissions && !isSharedDraft && (isDraft || isAuthor);

  let offeringLink: string = '';

  if (isSharedDraft) {
    offeringLink = routeFactory.sharedDraftOffering.getUrlPath({
      offeringId,
    });
  } else if (canAccessOfferingSetup) {
    offeringLink = routeFactory.offeringSetup.getUrlPath({
      stepId: stepIds.SUMMARY,
      offeringId,
    });
  } else {
    offeringLink = routeFactory.offeringDetails.getUrlPath({
      offeringId,
    });
  }

  return (
    <StyledLink to={offeringLink} data-test-id={xcSelectors.myOfferingsOfferingLink.testId}>
      {children}
    </StyledLink>
  );
};
