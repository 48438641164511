import styled from 'styled-components/macro';

export const SToolbarWrapper = styled.div.attrs({ role: 'presentation' })`
  display: flex;
  justify-content: space-between;
`;

export const SToolbarSection = styled.div.attrs({ role: 'presentation' })<{
  align?: 'left' | 'right';
}>`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 24px 0 16px;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SButtonWrapper = styled.div.attrs({ role: 'presentation' })`
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 8px;
  }
  padding-left: 11px;
`;

export const SIconButton = styled.div.attrs({ role: 'button' })`
  margin: 0 5px;
  padding: 3px;
  cursor: pointer;
`;
