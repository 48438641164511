import React from 'react';

import { getShareHistoryDataForLastVersion } from '../share-offering-draft-modal/ShareOfferingDraftModal.model';
import { useGetShareOfferingHistory } from './useGetShareOfferingHistory';

export const useGetLastShareHistory = ({
  offeringId,
  offeringVersion,
}: {
  offeringId: string;
  offeringVersion: string | undefined;
}) => {
  const { data } = useGetShareOfferingHistory({ offeringId });

  const lastShareHistoryData = React.useMemo(
    () => getShareHistoryDataForLastVersion(data?.shareHistory ?? [], offeringVersion),
    [data, offeringVersion]
  );
  return { lastShareHistoryData };
};
