import React from 'react';
import { Redirect } from 'react-router-dom';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../../../common/util/routeFactory';
import {
  useRoadshows_CoveredRoadshowDetailsQuery,
  useRoadshows_CoveredRoadshowEventsQuery,
} from './graphql';
import SalesAndTradingCalendar from './sales-and-trading-calendar/SalesAndTradingCalendar';

export type Props = { roadshowId: string };

const RoadshowSalesAndTradingCalendar: React.FC<Props> = ({ roadshowId }) => {
  const {
    data: roadshowData,
    loading: roadshowLoading,
    error: roadshowError,
  } = useRoadshows_CoveredRoadshowDetailsQuery({
    variables: { roadshowId },
  });
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useRoadshows_CoveredRoadshowEventsQuery({
    variables: { roadshowId },
  });

  const isLoading = roadshowLoading || eventsLoading;
  const error = roadshowError || eventsError;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const { coveredRoadshow } = roadshowData || {};

  if (!coveredRoadshow) {
    return <Redirect to={routeFactory.roadshowsList.getUrlPath()} />;
  }

  return (
    <SalesAndTradingCalendar
      roadshow={coveredRoadshow}
      events={eventsData?.coveredRoadshowEvents || []}
    />
  );
};

export default RoadshowSalesAndTradingCalendar;
