import { Alert, AlertTitle, Typography } from '@cmg/design-system';
import React from 'react';
import * as yup from 'yup';

import { useFormikErrors, UseFormikErrorsProps } from '../../../util/useFormikErrors';

export const InvalidFormFieldsAlert = <TValues extends yup.Maybe<yup.AnyObject>>(
  props: UseFormikErrorsProps<TValues>
) => {
  const { showInvalidFields, invalidFormFieldLabels } = useFormikErrors(props);

  if (!showInvalidFields) {
    return null;
  }

  return (
    <Alert
      severity="error"
      aria-label="An entry is required or has invalid value for the following fields:"
    >
      <AlertTitle>An entry is required or has invalid value for the following fields:</AlertTitle>
      <Typography variant="body2">{invalidFormFieldLabels.join(', ')}</Typography>
    </Alert>
  );
};
