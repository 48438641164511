import isNil from 'lodash/isNil';

import { CalendarGridRendererProps } from '../../../../calendar.model';
import { SCountryContainer, SFlagIcon } from './CountryRenderer.styles';

export const CountryRenderer: React.FC<CalendarGridRendererProps> = ({ data, value }) => {
  return isNil(value) ? (
    <span>-</span>
  ) : (
    <SCountryContainer>
      {data.pricingInstrumentCountryCode && (
        <SFlagIcon countryCode={data.pricingInstrumentCountryCode} />
      )}
      {value}
    </SCountryContainer>
  );
};
