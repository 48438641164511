import { numericUtil } from '@cmg/common';

import { getPnLValues } from '../../../common/utils';
import { MyRetailDemandBulkSaveValues } from '../BulkEditInternalRetailDemandView.model';

const { sum } = numericUtil;

export const calculateEditTotals = (
  values: MyRetailDemandBulkSaveValues,
  sellingConcession: number | null | undefined
) => {
  const demandShares = sum(...values.brokers.map(({ demandShares }) => demandShares));
  const demandRoundLots = sum(...values.brokers.map(({ demandRoundLots }) => demandRoundLots));
  const retentionShares = sum(...values.brokers.map(({ retentionShares }) => retentionShares));
  const retentionRoundLots = sum(
    ...values.brokers.map(({ retentionRoundLots }) => retentionRoundLots)
  );
  const pnlValues = values.brokers.map(broker =>
    getPnLValues({
      sellingConcession,
      sellingConcessionPercentage: broker.sellingConcessionPercentage,
      retentionShares: broker.retentionShares,
    })
  );

  const retainedByTheBank = sum(...pnlValues.map(({ retainedByTheBank }) => retainedByTheBank));

  return { demandShares, demandRoundLots, retentionShares, retentionRoundLots, retainedByTheBank };
};
