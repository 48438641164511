import styled from 'styled-components/macro';

export const SLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  color: ${({ theme }) => theme.text.color.light};
  padding-bottom: 5px;
`;

export const SField = styled.div`
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const SReadOnlyField = styled(SField)`
  color: ${({ theme }) => theme.text.color.light};
  font-size: 12px;
`;
