import styled from 'styled-components/macro';

export const SBDAgentFormWrapper = styled.div`
  & > div {
    padding: 0px;
  }
`;

export const SPrompt = styled.div`
  margin-bottom: 20px;
`;
