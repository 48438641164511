/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_AssignCompanyMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type Roadshows_AssignCompanyMutation = {
  readonly __typename?: 'Mutation';
  readonly assignCompany: {
    readonly __typename?: 'Roadshow';
    readonly endDate: string;
    readonly id: string;
    readonly offeringId?: string | null;
    readonly offeringIssuerName?: string | null;
    readonly roadshowName: string;
    readonly roadshowType: Types.RoadshowType;
    readonly startDate: string;
    readonly status: Types.RoadshowStatus;
    readonly companyName?: string | null;
    readonly companyId?: string | null;
    readonly defaultTimeZone?: string | null;
    readonly roadshowDays: ReadonlyArray<{
      readonly __typename?: 'RoadshowDay';
      readonly date: string;
      readonly timeZone?: string | null;
      readonly isClosed: boolean;
      readonly isVirtual: boolean;
      readonly cities: ReadonlyArray<{
        readonly __typename?: 'City';
        readonly name: string;
        readonly timeZone: string;
      }>;
    }>;
  };
};

export type Roadshows_CompanyDetailsQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type Roadshows_CompanyDetailsQuery = {
  readonly __typename?: 'Query';
  readonly companyDetails: {
    readonly __typename?: 'Company';
    readonly id: string;
    readonly name: string;
    readonly website?: string | null;
    readonly industryTypes: ReadonlyArray<Types.FirmIndustryType>;
    readonly address?: {
      readonly __typename?: 'CompanyAddress';
      readonly streetLine1?: string | null;
      readonly streetLine2?: string | null;
      readonly streetLine3?: string | null;
      readonly city?: string | null;
      readonly state?: string | null;
      readonly country?: string | null;
    } | null;
  };
};

export type Roadshows_RoadshowManagementTeamQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_RoadshowManagementTeamQuery = {
  readonly __typename?: 'Query';
  readonly roadshowManagementTeam: {
    readonly __typename?: 'ManagementTeam';
    readonly id: string;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'ManagementTeamMember';
      readonly id: string;
      readonly name: string;
      readonly title?: string | null;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly email: string;
      readonly isParticipating: boolean;
    }>;
  };
};

export const Roadshows_AssignCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_AssignCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Roadshow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVirtual' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_AssignCompanyMutationFn = Apollo.MutationFunction<
  Roadshows_AssignCompanyMutation,
  Roadshows_AssignCompanyMutationVariables
>;

/**
 * __useRoadshows_AssignCompanyMutation__
 *
 * To run a mutation, you first call `useRoadshows_AssignCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_AssignCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsAssignCompanyMutation, { data, loading, error }] = useRoadshows_AssignCompanyMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRoadshows_AssignCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_AssignCompanyMutation,
    Roadshows_AssignCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_AssignCompanyMutation,
    Roadshows_AssignCompanyMutationVariables
  >(Roadshows_AssignCompanyDocument, options);
}
export type Roadshows_AssignCompanyMutationHookResult = ReturnType<
  typeof useRoadshows_AssignCompanyMutation
>;
export type Roadshows_AssignCompanyMutationResult =
  Apollo.MutationResult<Roadshows_AssignCompanyMutation>;
export type Roadshows_AssignCompanyMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_AssignCompanyMutation,
  Roadshows_AssignCompanyMutationVariables
>;
export const Roadshows_CompanyDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CompanyDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Roadshows_CompanyParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_CompanyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryTypes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CompanyDetailsQuery__
 *
 * To run a query within a React component, call `useRoadshows_CompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CompanyDetailsQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRoadshows_CompanyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CompanyDetailsQuery,
    Roadshows_CompanyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_CompanyDetailsQuery, Roadshows_CompanyDetailsQueryVariables>(
    Roadshows_CompanyDetailsDocument,
    options
  );
}
export function useRoadshows_CompanyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CompanyDetailsQuery,
    Roadshows_CompanyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_CompanyDetailsQuery, Roadshows_CompanyDetailsQueryVariables>(
    Roadshows_CompanyDetailsDocument,
    options
  );
}
export type Roadshows_CompanyDetailsQueryHookResult = ReturnType<
  typeof useRoadshows_CompanyDetailsQuery
>;
export type Roadshows_CompanyDetailsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CompanyDetailsLazyQuery
>;
export type Roadshows_CompanyDetailsQueryResult = Apollo.QueryResult<
  Roadshows_CompanyDetailsQuery,
  Roadshows_CompanyDetailsQueryVariables
>;
export const Roadshows_RoadshowManagementTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowManagementTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowManagementTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowManagementTeamQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowManagementTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowManagementTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowManagementTeamQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_RoadshowManagementTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowManagementTeamQuery,
    Roadshows_RoadshowManagementTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowManagementTeamQuery,
    Roadshows_RoadshowManagementTeamQueryVariables
  >(Roadshows_RoadshowManagementTeamDocument, options);
}
export function useRoadshows_RoadshowManagementTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowManagementTeamQuery,
    Roadshows_RoadshowManagementTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowManagementTeamQuery,
    Roadshows_RoadshowManagementTeamQueryVariables
  >(Roadshows_RoadshowManagementTeamDocument, options);
}
export type Roadshows_RoadshowManagementTeamQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowManagementTeamQuery
>;
export type Roadshows_RoadshowManagementTeamLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowManagementTeamLazyQuery
>;
export type Roadshows_RoadshowManagementTeamQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowManagementTeamQuery,
  Roadshows_RoadshowManagementTeamQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRoadshows_CompanyDetailsMockResponse = makeMockResponse<
  Roadshows_CompanyDetailsQueryVariables,
  Roadshows_CompanyDetailsQuery
>(Roadshows_CompanyDetailsDocument);

export const makeRoadshows_RoadshowManagementTeamMockResponse = makeMockResponse<
  Roadshows_RoadshowManagementTeamQueryVariables,
  Roadshows_RoadshowManagementTeamQuery
>(Roadshows_RoadshowManagementTeamDocument);
