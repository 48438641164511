import { Step } from '../../../../../../../common/components/navigation/side-nav/SideNav';

export enum MeetingNavigationSteps {
  DETAILS = 'DETAILS',
  TEAMS = 'TEAMS',
  INVESTORS = 'INVESTORS',
}

export const meetingNavigationSteps: Step[] = [
  {
    label: 'Meeting Details',
    id: MeetingNavigationSteps.DETAILS,
  },
  {
    label: 'Management & Deal Team',
    id: MeetingNavigationSteps.TEAMS,
  },
  {
    label: 'Investors',
    id: MeetingNavigationSteps.INVESTORS,
  },
];
