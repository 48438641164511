import * as yup from 'yup';

import { isPercentageValid } from '../../../common/util/validation';
import { OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery } from '../graphql';

export type UndewritingFeesAndDiscountFormValues = {
  grossSpreadBase: number | null;
  incentiveFee: number | null;
  baseGrossSpreadManagementFee: number | null;
  baseGrossSpreadUnderwritingFee: number | null;
  baseGrossSpreadSellingConcession: number | null;
  incentiveFeeManagementFee: number | null;
  incentiveFeeUnderwritingFee: number | null;
  incentiveFeeSellingConcession: number | null;
  reallowance: number | null;
  sharesExcludedFromGrossSpread: number | null;
};

const getGrossSpreadFieldValues = (context: yup.TestContext) => {
  const baseGrossSpreadUnderwritingFee = context.resolve<number | null>(
    yup.ref('baseGrossSpreadUnderwritingFee')
  );
  const baseGrossSpreadSellingConcession = context.resolve<number | null>(
    yup.ref('baseGrossSpreadSellingConcession')
  );
  const baseGrossSpreadManagementFee = context.resolve<number | null>(
    yup.ref('baseGrossSpreadManagementFee')
  );

  return {
    baseGrossSpreadManagementFee,
    baseGrossSpreadUnderwritingFee,
    baseGrossSpreadSellingConcession,
  };
};

const getIncentiveFeeFieldValues = (context: yup.TestContext) => {
  const incentiveFeeUnderwritingFee = context.resolve<number | null>(
    yup.ref('incentiveFeeUnderwritingFee')
  );
  const incentiveFeeSellingConcession = context.resolve<number | null>(
    yup.ref('incentiveFeeSellingConcession')
  );
  const incentiveFeeManagementFee = context.resolve<number | null>(
    yup.ref('incentiveFeeManagementFee')
  );

  return { incentiveFeeUnderwritingFee, incentiveFeeSellingConcession, incentiveFeeManagementFee };
};

const validatePercentageValues = (
  fieldLabel: string,
  context: yup.TestContext,
  value: number | null | undefined,
  otherValues: (number | null)[]
) => {
  const allValues = [value, ...otherValues];

  if (allValues.every(x => !x)) {
    return true;
  }

  if ((value ?? 0) < 0) {
    return context.createError({
      message: `${fieldLabel} must be greater or equal to 0`,
    });
  }

  const percentageSummary = allValues.reduce<number>((acc, curr) => {
    return (curr ?? 0) + acc;
  }, 0);

  if (!isPercentageValid(percentageSummary, [0, 1])) {
    return context.createError({
      message: `The sum of management fee, undewriting fee and selling concession percent should be 0 or 100%`,
    });
  }

  return true;
};

export const getUnderwritingFeesAndDiscountsFormSchema = ({
  offerPrice,
}: {
  offerPrice: number;
}) => {
  return yup.object().shape({
    grossSpreadBase: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread must be greater or equal to 0')
      .max(offerPrice, 'Base Gross Spread must be less than or equal to the Offer Price'),
    incentiveFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee must be greater or equal to 0')
      .max(offerPrice, 'Incentive Fee must be less than or equal to the Offer Price'),
    baseGrossSpreadManagementFee: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Management Fee must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { baseGrossSpreadSellingConcession, baseGrossSpreadUnderwritingFee } =
          getGrossSpreadFieldValues(this);

        return validatePercentageValues('Base Gross Spread Management Fee', this, value, [
          baseGrossSpreadSellingConcession,
          baseGrossSpreadUnderwritingFee,
        ]);
      }),
    baseGrossSpreadUnderwritingFee: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Underwriting Fee must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { baseGrossSpreadSellingConcession, baseGrossSpreadManagementFee } =
          getGrossSpreadFieldValues(this);

        return validatePercentageValues('Base Gross Spread Underwriting Fee', this, value, [
          baseGrossSpreadSellingConcession,
          baseGrossSpreadManagementFee,
        ]);
      }),
    baseGrossSpreadSellingConcession: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Selling Concession must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { baseGrossSpreadUnderwritingFee, baseGrossSpreadManagementFee } =
          getGrossSpreadFieldValues(this);

        return validatePercentageValues('Base Gross Spread Selling Concession', this, value, [
          baseGrossSpreadManagementFee,
          baseGrossSpreadUnderwritingFee,
        ]);
      }),
    incentiveFeeManagementFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Management Fee must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { incentiveFeeUnderwritingFee, incentiveFeeSellingConcession } =
          getIncentiveFeeFieldValues(this);

        return validatePercentageValues('Incentive Fee Management Fee', this, value, [
          incentiveFeeUnderwritingFee,
          incentiveFeeSellingConcession,
        ]);
      }),
    incentiveFeeUnderwritingFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Underwriting Fee must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { incentiveFeeManagementFee, incentiveFeeSellingConcession } =
          getIncentiveFeeFieldValues(this);

        return validatePercentageValues('Incentive Fee Underwriting Fee', this, value, [
          incentiveFeeManagementFee,
          incentiveFeeSellingConcession,
        ]);
      }),
    incentiveFeeSellingConcession: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Selling Concession must be greater or equal to 0')
      .max(1)
      .test(function (this, value) {
        const { incentiveFeeManagementFee, incentiveFeeUnderwritingFee } =
          getIncentiveFeeFieldValues(this);

        return validatePercentageValues('Incentive Fee Selling Concession', this, value, [
          incentiveFeeManagementFee,
          incentiveFeeUnderwritingFee,
        ]);
      }),
    reallowance: yup.number().nullable().min(0, 'Reallowance Fee must be greater or equal to 0'),
    sharesExcludedFromGrossSpread: yup
      .number()
      .nullable()
      .min(0, 'Shared Excluded from Gross Spread must be greater or equal to 0'),
  });
};

export const getInitialValues = (
  underwritingFeesDiscounts:
    | OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery
    | undefined
) => {
  return {
    grossSpreadBase:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.grossSpreadBaseData
        ?.grossSpreadBase ?? null,
    incentiveFee:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.incentiveFeeData?.incentiveFee ??
      null,
    baseGrossSpreadManagementFee:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.grossSpreadBaseData
        ?.managementFeePercentage ?? null,
    baseGrossSpreadUnderwritingFee:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.grossSpreadBaseData
        ?.underwritingFeePercentage ?? null,
    baseGrossSpreadSellingConcession:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.grossSpreadBaseData
        ?.sellingConcessionPercentage ?? null,
    incentiveFeeManagementFee:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.incentiveFeeData
        ?.managementFeePercentage ?? null,
    incentiveFeeUnderwritingFee:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.incentiveFeeData
        ?.underwritingFeePercentage ?? null,
    incentiveFeeSellingConcession:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.incentiveFeeData
        ?.sellingConcessionPercentage ?? null,
    reallowance: underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.reallowance ?? null,
    sharesExcludedFromGrossSpread:
      underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.sharesExcludedFromGrossSpread ??
      null,
  };
};
