/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestorFirmSearch_InvestorFirmBasicPartsFragment = {
  readonly __typename?: 'InvestorFirmBasic';
  readonly id: string;
  readonly firmKey?: string | null;
  readonly cmgEntityKey?: string | null;
  readonly name: string;
  readonly firmType: Types.RolodexFirmCategoryType;
  readonly address1?: string | null;
  readonly address2?: string | null;
  readonly status: Types.RolodexRecordStatus;
};

export type InvestorFirmSearch_InvestorFirmsQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  perPage: Types.Scalars['Int'];
}>;

export type InvestorFirmSearch_InvestorFirmsQuery = {
  readonly __typename?: 'Query';
  readonly investorFirms: {
    readonly __typename?: 'InvestorFirmBasicPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'InvestorFirmBasic';
      readonly id: string;
      readonly firmKey?: string | null;
      readonly cmgEntityKey?: string | null;
      readonly name: string;
      readonly firmType: Types.RolodexFirmCategoryType;
      readonly address1?: string | null;
      readonly address2?: string | null;
      readonly status: Types.RolodexRecordStatus;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly hasNext?: boolean | null;
    };
  };
};

export type InvestorFirmSearch_CoveredInvestorFirmsQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  perPage: Types.Scalars['Int'];
  firmKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type InvestorFirmSearch_CoveredInvestorFirmsQuery = {
  readonly __typename?: 'Query';
  readonly coveredInvestorFirms: {
    readonly __typename?: 'InvestorFirmBasicPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'InvestorFirmBasic';
      readonly id: string;
      readonly firmKey?: string | null;
      readonly cmgEntityKey?: string | null;
      readonly name: string;
      readonly firmType: Types.RolodexFirmCategoryType;
      readonly address1?: string | null;
      readonly address2?: string | null;
      readonly status: Types.RolodexRecordStatus;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly hasNext?: boolean | null;
    };
  };
};

export const InvestorFirmSearch_InvestorFirmBasicPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirmBasicParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InvestorFirmSearch_InvestorFirmsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirmBasicParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNext' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirmBasicParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorFirmSearch_InvestorFirmsQuery__
 *
 * To run a query within a React component, call `useInvestorFirmSearch_InvestorFirmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorFirmSearch_InvestorFirmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorFirmSearch_InvestorFirmsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useInvestorFirmSearch_InvestorFirmsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorFirmSearch_InvestorFirmsQuery,
    InvestorFirmSearch_InvestorFirmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorFirmSearch_InvestorFirmsQuery,
    InvestorFirmSearch_InvestorFirmsQueryVariables
  >(InvestorFirmSearch_InvestorFirmsDocument, options);
}
export function useInvestorFirmSearch_InvestorFirmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorFirmSearch_InvestorFirmsQuery,
    InvestorFirmSearch_InvestorFirmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorFirmSearch_InvestorFirmsQuery,
    InvestorFirmSearch_InvestorFirmsQueryVariables
  >(InvestorFirmSearch_InvestorFirmsDocument, options);
}
export type InvestorFirmSearch_InvestorFirmsQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_InvestorFirmsQuery
>;
export type InvestorFirmSearch_InvestorFirmsLazyQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_InvestorFirmsLazyQuery
>;
export type InvestorFirmSearch_InvestorFirmsQueryResult = Apollo.QueryResult<
  InvestorFirmSearch_InvestorFirmsQuery,
  InvestorFirmSearch_InvestorFirmsQueryVariables
>;
export const InvestorFirmSearch_CoveredInvestorFirmsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorFirmSearch_CoveredInvestorFirms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredInvestorFirms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirmBasicParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNext' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmSearch_InvestorFirmBasicParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorFirmSearch_CoveredInvestorFirmsQuery__
 *
 * To run a query within a React component, call `useInvestorFirmSearch_CoveredInvestorFirmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorFirmSearch_CoveredInvestorFirmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorFirmSearch_CoveredInvestorFirmsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      firmKey: // value for 'firmKey'
 *   },
 * });
 */
export function useInvestorFirmSearch_CoveredInvestorFirmsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorFirmSearch_CoveredInvestorFirmsQuery,
    InvestorFirmSearch_CoveredInvestorFirmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorFirmSearch_CoveredInvestorFirmsQuery,
    InvestorFirmSearch_CoveredInvestorFirmsQueryVariables
  >(InvestorFirmSearch_CoveredInvestorFirmsDocument, options);
}
export function useInvestorFirmSearch_CoveredInvestorFirmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorFirmSearch_CoveredInvestorFirmsQuery,
    InvestorFirmSearch_CoveredInvestorFirmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorFirmSearch_CoveredInvestorFirmsQuery,
    InvestorFirmSearch_CoveredInvestorFirmsQueryVariables
  >(InvestorFirmSearch_CoveredInvestorFirmsDocument, options);
}
export type InvestorFirmSearch_CoveredInvestorFirmsQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_CoveredInvestorFirmsQuery
>;
export type InvestorFirmSearch_CoveredInvestorFirmsLazyQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_CoveredInvestorFirmsLazyQuery
>;
export type InvestorFirmSearch_CoveredInvestorFirmsQueryResult = Apollo.QueryResult<
  InvestorFirmSearch_CoveredInvestorFirmsQuery,
  InvestorFirmSearch_CoveredInvestorFirmsQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeInvestorFirmSearch_InvestorFirmsMockResponse = makeMockResponse<
  InvestorFirmSearch_InvestorFirmsQueryVariables,
  InvestorFirmSearch_InvestorFirmsQuery
>(InvestorFirmSearch_InvestorFirmsDocument);

export const makeInvestorFirmSearch_CoveredInvestorFirmsMockResponse = makeMockResponse<
  InvestorFirmSearch_CoveredInvestorFirmsQueryVariables,
  InvestorFirmSearch_CoveredInvestorFirmsQuery
>(InvestorFirmSearch_CoveredInvestorFirmsDocument);
