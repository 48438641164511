import { ColDef, dateTimeUtil } from '@cmg/common';
import { Theme, useTheme } from '@cmg/design-system';
import { useMemo } from 'react';

import { CertificateType } from '../../../../graphql/__generated__';
import { CertificateLibrary_FetchCertificates_FirmCertificatesFragment } from '../../graphql/__generated__';
import CertificateRelationshipRenderer from './renderers/CertificateRelationshipRenderer';
import CertificateRenderer from './renderers/CertificateRenderer';
import FirmNameRenderer from './renderers/FirmNameRenderer';

const getDefaultCellStyle = (theme: Theme) => ({
  backgroundColor: `${theme.palette.common.white}`,
  textOverflow: 'ellipsis',
  borderBottom: `${theme.spacing(0.125)} solid ${theme.palette.grey[300]}`,
  borderLeft: 'none',
  borderRight: 'none',
  whiteSpace: 'nowrap',
  lineHeight: '50px',
});

const getCellStyle = (theme: Theme, pinnedSide?: 'left' | 'right' | null) => ({
  ...getDefaultCellStyle(theme),
  backgroundColor: `${theme.palette.common.white}`,
  borderLeft: pinnedSide === 'right' ? `1px solid ${theme.palette.grey[300]}` : 'none',
  borderRight: pinnedSide === 'left' ? `1px solid ${theme.palette.grey[300]}` : 'none',
});

const commonColumnDefinition = {
  suppressColumnsToolPanel: true,
  wrapText: false,
  suppressSizeToFit: true,
};

const commonCertificateColumnDefinition = {
  minWidth: 150,
  maxWidth: 300,
  suppressSizeToFit: true,
};

export const columnsDefinition = {
  firmName: {
    field: 'firmName',
    headerName: 'Firm Name',
    pinned: 'left',
    minWidth: 235,
    maxWidth: 600,
    cellRendererFramework: FirmNameRenderer,
  },
  certificate51305131: {
    ...commonCertificateColumnDefinition,
    headerName: '5130/31',
    field: 'certificate51305131',
    valueGetter: ({ data }) =>
      data?.certificates?.find(c => c.certificateType === CertificateType.Form51305131) ?? [],
    cellRendererFramework: CertificateRenderer,
  },
  /* Commented out because it's going to be implemented in a future project
  certificateQib: {
    ...commonCertificateColumnDefinition,
    field: 'certificateQib',
    headerName: 'QIB',
    valueGetter: ({ data }) =>
      data?.certificates.find(c => c.certificateType === CertificateType.Qib),
    cellRendererFramework: CertificateRenderer,
  },
  certificateRegS: {
    ...commonCertificateColumnDefinition,
    headerName: 'Reg S',
    field: 'certificateRegS',
    valueGetter: ({ data }) =>
      data?.certificates.find(c => c.certificateType === CertificateType.RegS),
    cellRendererFramework: CertificateRenderer,
  },
  */
  lastUpdate: {
    field: 'lastUpdate',
    headerName: 'Last Updated',
    minWidth: 180,
    maxWidth: 300,
    valueFormatter: ({ value }) =>
      value ? dateTimeUtil.getDisplayValueForDateAndTime(value as string) : '-',
  },
  bankInvestorKey: {
    field: 'bankInvestorKey',
    headerName: 'Bank Investor Key (BIK)',
    minWidth: 180,
    maxWidth: 300,
    valueFormatter: ({ value }) => value ?? '-',
  },
  hasRelationship: {
    field: 'hasRelationship',
    headerName: 'Relationship',
    minWidth: 120,
    maxWidth: 300,
    cellRendererFramework: CertificateRelationshipRenderer,
  },
  firmAddress: {
    field: 'firmAddress',
    headerName: 'Firm Address',
    minWidth: 230,
    maxWidth: 800,
    suppressSizeToFit: false,
    valueFormatter: ({ value }) => value ?? '-',
  },
};

export const columns: ColDef<CertificateLibrary_FetchCertificates_FirmCertificatesFragment>[] = [
  columnsDefinition.firmName,
  columnsDefinition.certificate51305131,
  // Commented out because it's going to be implemented in a future project
  // columnsDefinition.certificateQib,
  // columnsDefinition.certificateRegS,
  columnsDefinition.lastUpdate,
  columnsDefinition.bankInvestorKey,
  columnsDefinition.hasRelationship,
  columnsDefinition.firmAddress,
];

export const useCertificateGridColDefs = () => {
  const theme = useTheme();

  return useMemo(
    () =>
      columns.map(col => ({
        ...commonColumnDefinition,
        cellStyle: params => ({
          ...getCellStyle(theme, params.colDef.pinned),
        }),
        ...col,
      })),
    [theme]
  );
};
