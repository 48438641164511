import isNil from 'lodash/isNil';

export type PnLValues = Readonly<{
  perShare: number | null;
  receivedByTheFirm: number | null;
  retainedByTheBank: number | null;
}>;

export type PnLProps = Readonly<{
  sellingConcession: number | null | undefined;
  sellingConcessionPercentage: number | null | undefined;
  retentionShares: number | null | undefined;
}>;

/**
 * Compute PnL values based on the offering selling concession, broker's selling concession percentage and broker's retention shares
 * @param sellingConcession
 * @param sellingConcessionPercentage
 * @param retentionShares
 */
export const getPnLValues = ({
  sellingConcession,
  sellingConcessionPercentage,
  retentionShares,
}: PnLProps): PnLValues => {
  if (isNil(sellingConcession) || isNil(sellingConcessionPercentage)) {
    return { perShare: null, receivedByTheFirm: null, retainedByTheBank: null };
  }

  const perShare = sellingConcession * sellingConcessionPercentage;

  if (isNil(retentionShares)) {
    return {
      perShare,
      receivedByTheFirm: null,
      retainedByTheBank: null,
    };
  }

  const receivedByTheFirm = perShare * retentionShares;
  const retainedByTheBank = sellingConcession * retentionShares - receivedByTheFirm;

  return {
    perShare,
    receivedByTheFirm,
    retainedByTheBank,
  };
};
