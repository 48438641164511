import {
  SyndicateWires_UpdatePotAllocationWireMutation,
  useSyndicateWires_UpdatePotAllocationWireMutation,
} from '../graphql';

export type UpdatePotAllocationWireMutation = SyndicateWires_UpdatePotAllocationWireMutation;

export const useUpdatePotAllocationWireMutation = () => {
  return useSyndicateWires_UpdatePotAllocationWireMutation();
};
