import { urlUtil } from '@cmg/common';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parseFirmKey, serializeFirmKey } from '../../../common/util/rolodex/firm-key-type.util';

export { parseFirmKey, serializeFirmKey };

const useGetCmgEntityKeyQueryParam = () => {
  const { search } = useLocation();
  return (urlUtil.queryParse(search)?.cmgEntityKey as string) || undefined;
};

const useUpdateCmgEntityKey = () => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  return (newCmgEntityKey?: string) => {
    const { cmgEntityKey, ...restCurrentQueryParams } = urlUtil.queryParse(search);

    // If use uses investor dropdown to navigate to a new investor, we want to
    // delete indicationId query param from the URL.
    if (restCurrentQueryParams.indicationId) {
      delete restCurrentQueryParams.indicationId;
    }
    // If the new CMG Entity Key value is truthy, add or update it in the
    // query string - and persist all other query params.
    const updatedSearch = {
      ...restCurrentQueryParams,
      ...(newCmgEntityKey ? { cmgEntityKey: newCmgEntityKey } : {}),
    };

    push({
      pathname,
      search: urlUtil.queryStringify(updatedSearch),
    });
  };
};

const CmgEntityKeyContext = React.createContext<{
  cmgEntityKey?: string;
  updateCmgEntityKey: (cmgEntityKey?: string) => void;
}>({
  cmgEntityKey: undefined,
  updateCmgEntityKey: useUpdateCmgEntityKey,
});

/**
 * Hook that exposes a reference to the CMG Entity Key Query param,
 * as well as a function to modify that query param.
 */
export const useCmgEntityKeyContext = () => React.useContext(CmgEntityKeyContext);

/**
 * Central interface for dealing with CMG Entity Keys in Routes.
 *
 * React context provider that parses the CMG Entity Key
 * Query Parameter from the current URL and exposes it to context
 * consumers.  Also provides a utility for updating the CMG Entity Key
 * Query Param.
 */
export const CmgEntityKeyProvider: React.FC = ({ children }) => {
  const cmgEntityKey = useGetCmgEntityKeyQueryParam();
  const updateCmgEntityKey = useUpdateCmgEntityKey();

  return (
    <CmgEntityKeyContext.Provider value={{ cmgEntityKey, updateCmgEntityKey }}>
      {children}
    </CmgEntityKeyContext.Provider>
  );
};
