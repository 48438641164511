import { Option } from '@cmg/common';

import { InvestorContactSearch_InvestorEmployeePartsFragment } from './graphql/__generated__';

export type InvestorContactOption = Option<string | null>;

export const getContactsOptions = (
  contacts: readonly InvestorContactSearch_InvestorEmployeePartsFragment[]
) => {
  return contacts.map<InvestorContactOption>(contact => {
    const email = contact.email1 || contact.email2;

    return {
      value: contact.id,
      label: contact.name,
      email,
    };
  });
};
