import { UUID } from '@cmg/common';
import * as yup from 'yup';

import { MAX_32_BIT_INT, MAX_53_BIT_INT } from '../../../../../types/graphql/constants';
import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../graphql';
import { OrderBook_UpdateTotalRetailDemandMutationVariables } from './graphql';

export type TotalRetailRowValues = {
  managerCmgEntityKey: UUID;
  demandShares: number | null;
  roundLots: number | null | undefined;
  retentionShares: number | null;
};

export type TotalRetailBulkSaveValues = { demands: TotalRetailRowValues[] };

export const createInitialValues = (
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[]
): TotalRetailBulkSaveValues => {
  return {
    demands: rows.map(row => ({
      managerCmgEntityKey: row.manager.cmgEntityKey,
      demandShares: row.demand.shareQuantity ?? null,
      roundLots: row.demand.roundLots ?? null,
      retentionShares: row.retention.shareQuantity ?? null,
    })),
  };
};

export const validationSchema = yup.object({
  demands: yup.array().of(
    yup.object({
      demandShares: yup
        .number()
        .integer()
        .label('Demand')
        .min(0)
        .max(MAX_53_BIT_INT)
        .nullable()
        .required(),
      roundLots: yup.number().integer().label('Round Lots').min(0).max(MAX_32_BIT_INT).nullable(),
      retentionShares: yup
        .number()
        .integer()
        .label('Retention')
        .min(0)
        .max(MAX_53_BIT_INT)
        .nullable()
        .required(),
    })
  ),
});

export const getRowsWithChangedRetention = (
  originalValues: readonly OrderBook_TotalRetailSummaryItemPartsFragment[],
  values: TotalRetailBulkSaveValues
): [OrderBook_TotalRetailSummaryItemPartsFragment, TotalRetailRowValues][] => {
  return originalValues.reduce<
    [OrderBook_TotalRetailSummaryItemPartsFragment, TotalRetailRowValues][]
  >((acc, curr) => {
    const originalRetentionShares = curr.retention.shareQuantity;

    const formValue = values.demands.find(
      ({ managerCmgEntityKey }) => managerCmgEntityKey === curr.manager.cmgEntityKey
    )!;

    if (originalRetentionShares !== formValue.retentionShares) {
      return [...acc, [curr, formValue]];
    }

    return acc;
  }, []);
};

export const createUpdateRetailDemandVariables = ({
  offeringId,
  instrumentId,
  trancheId,
  values,
}: {
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
  values: TotalRetailBulkSaveValues;
}): OrderBook_UpdateTotalRetailDemandMutationVariables => {
  return {
    offeringId,
    payload: values.demands.map(rowValue => ({
      managerCmgEntityKey: rowValue.managerCmgEntityKey,
      demandShareQuantity: rowValue.demandShares!,
      retentionShareQuantity: rowValue.retentionShares!,
      roundLots: rowValue.roundLots,
      instrumentId,
      trancheId,
    })),
  };
};
