import styled from 'styled-components/macro';

export const SPopoverText = styled.span`
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
  border-bottom: 1px dashed ${({ theme }) => theme.designSystem.colors.gray[500]};
`;

export const SPopoverSmallText = styled.div`
  font-size: ${({ theme }) => theme.text.size.tiny};
`;
