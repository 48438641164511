import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../common/util/routeFactory';
import { Roadshows_RoadshowPartsFragment } from '../../../../common/graphql';
import MeetingDetailsForm from '../common/meeting-details-form/MeetingDetailsForm';
import {
  getMeetingPayload,
  MeetingValues,
} from '../common/meeting-details-form/MeetingDetailsForm.model';
import MeetingSidePanel from '../common/meeting-side-panel/MeetingSidePanel';
import { MeetingNavigationSteps } from '../common/meeting-side-panel/MeetingSidePanel.model';
import { useCreateMeetingMutation } from './hooks/useCreateMeetingMutation';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  onClose: () => void;
  selectedDate?: string;
};

const CreateMeetingPanel: React.FC<Props> = ({ onClose, roadshow, selectedDate }) => {
  const { push } = useHistory();

  const [createMeetingMutation, { loading: isCreating }] = useCreateMeetingMutation();

  const navigateToMeeting = (meetingId: UUID) => {
    push(routeFactory.roadshowMeetingDetails.getUrlPath({ roadshowId: roadshow.id, meetingId }));
  };

  const handleSubmit = async (values: MeetingValues) => {
    try {
      const { data } = await createMeetingMutation({
        variables: {
          roadshowId: roadshow.id,
          payload: getMeetingPayload(values),
        },
      });

      if (data) {
        ToastManager.success('Meeting draft created successfully');
        navigateToMeeting(data.createMeeting.id);
      }
    } catch {
      ToastManager.error('Failed to create meeting draft');
    }
  };

  return (
    <MeetingSidePanel
      title="Create New Meeting"
      onClose={onClose}
      activeNavigationStepId={MeetingNavigationSteps.DETAILS}
      testId="roadshows / single-roadshow / calendar / new meeting panel"
    >
      <FlexLayout expand direction="column">
        <MeetingDetailsForm
          roadshow={roadshow}
          onSubmit={handleSubmit}
          isSaving={isCreating}
          meetingHeaderInfo={null}
          selectedDate={selectedDate}
        />
      </FlexLayout>
    </MeetingSidePanel>
  );
};

export default CreateMeetingPanel;
