import { TableCell, TableHead, TableRow } from '@cmg/design-system';

export type Props = Readonly<{
  readonly sharesColLabel: string;
}>;

export const ManagerEconomicSharesTableHead: React.FC<Props> = ({ sharesColLabel }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Underwriter</TableCell>
        <TableCell align="right">{sharesColLabel}</TableCell>
      </TableRow>
    </TableHead>
  );
};
