import { numericUtil } from '@cmg/common';

import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import { getPnLValues, PnLValues } from '../../common/utils';

const { sum } = numericUtil;

export type MyRetailRowData = Readonly<OrderBook_RetailBrokerDealerPartsFragment & PnLValues>;

export const calculateReadOnlyTotals = (
  rows: (OrderBook_RetailBrokerDealerPartsFragment & PnLValues)[]
) => {
  const demandShares = sum(...rows.map(({ demand }) => demand.shareQuantity));
  const demandRoundLots = sum(...rows.map(({ demand }) => demand.roundLots));
  const retentionShares = sum(...rows.map(({ retention }) => retention.shareQuantity));
  const retentionRoundLots = sum(...rows.map(({ retention }) => retention.roundLots));
  const retainedByTheBank = sum(...rows.map(({ retainedByTheBank }) => retainedByTheBank));

  return { demandShares, demandRoundLots, retentionShares, retentionRoundLots, retainedByTheBank };
};

export const createBrokerRows = (
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[],
  sellingConcession: number | null | undefined
): MyRetailRowData[] => {
  return brokers.map(broker => {
    const pnlValues = getPnLValues({
      sellingConcession,
      sellingConcessionPercentage: broker.sellingConcessionPercentage,
      retentionShares: broker.retention.shareQuantity,
    });

    return { ...broker, ...pnlValues };
  });
};
