import { AccessControl } from '@cmg/auth';
import {
  AppBar as DesignSystemAppBar,
  AppBarLogo,
  AppBarNavigationMenu,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@cmg/design-system';
import { VFC } from 'react';

import Logo from '../../../common/assets/cmg-xc-logo.svg';
import { AppBarSecondarySection } from './AppBarSecondarySection';
import { useNavItems } from './hooks';
import { TopLevelItems } from './TopLevelItems';

export const AppBar: VFC = () => {
  const navItems = useNavItems();

  return (
    <AccessControl>
      <DesignSystemAppBar
        logoSection={<AppBarLogo imgSrc={Logo} imgAlt="CMG XC Logo" />}
        navigationSectionLinks={<TopLevelItems navItems={navItems} />}
        navigationSectionMenu={
          <AppBarNavigationMenu
            label="Main Menu"
            renderItems={({ closeMenu }) =>
              navItems.map(navDefinition => (
                <ListItem disablePadding role="menuitem" key={navDefinition.id} onClick={closeMenu}>
                  <ListItemButton href={navDefinition.href} target={navDefinition.target}>
                    <ListItemText primary={navDefinition.label} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          />
        }
        secondarySection={<AppBarSecondarySection />}
      />
    </AccessControl>
  );
};
