import { urlUtil, UUID } from '@cmg/common';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import routeFactory from '../../common/util/routeFactory';
import { useCmgEntityKeyContext } from './contexts/CmgEntityKeyContext';
import OfferingSidePanel from './OfferingSidePanel';
import { getSidePanelMatchUrl } from './OfferingSidePanel.model';
import { OfferingSidePanelContextProvider } from './order-book/indication-activity/context-old/OfferingSidePanelContext';

// we cache the original match.url and use it internally as the prefixRoutePath for
// all internal side-panel routes
export const OfferingSidePanelRouteContext = React.createContext('');

export const OfferingSidePanelContainer: React.FC = () => {
  const sidePanelMatch = useRouteMatch<{ offeringId: UUID }>(
    routeFactory.offeringSidePanel.routePath
  );
  const offeringId = sidePanelMatch?.params.offeringId;
  const { cmgEntityKey: cmgEntityKeyFromUrlQueryParam } = useCmgEntityKeyContext();

  const { search } = useLocation();
  const indicationId = urlUtil.queryParse(search)?.indicationId as string;

  return (
    <OfferingSidePanelRouteContext.Provider value={getSidePanelMatchUrl(sidePanelMatch?.url)}>
      <OfferingSidePanelContextProvider
        offeringId={offeringId!}
        cmgEntityKey={cmgEntityKeyFromUrlQueryParam}
        indicationId={indicationId}
      >
        <OfferingSidePanel />
      </OfferingSidePanelContextProvider>
    </OfferingSidePanelRouteContext.Provider>
  );
};
