import { UUID } from '@cmg/common';
import React from 'react';

import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import { useComputeDiffOnBillingAgent } from './useComputeDiffOnBillingAgent';
import { useComputeDiffOnFinalAllocationSet } from './useComputeDiffOnFinalAllocationSet';
import { useComputeDiffOnInstitutionalAcknowledgments } from './useComputeDiffOnInstitutionalAcknowledgements';
import { useComputeDiffOnInstitutionalIndicationDemands } from './useComputeDiffOnInstitutionalIndicationDemands';
import { useComputeDiffOnSharedDraftAllocationSets } from './useComputeDiffOnSharedDraftAllocationSets';

export interface InstitutionalDemandGridUpdate {
  timeStamp?: Date | null | undefined;
  type: string;
  investorName?: string | null;
  indicationId?: string | null;
  pendingUpdateLabel: string[];
  id: UUID;
  read?: boolean;
}

export enum UpdateType {
  NEW_FINAL_ALLOCATION = 'New Final Allocation',
  FINAL_ALLOCATION_UPDATE = 'Final Allocation Updates',
  NEW_DRAFT_ALLOCATION = 'New Draft Allocation',
  DRAFT_ALLOCATION_UPDATE = 'Draft Allocation Updates',
  NEW_ORDERS = 'New Orders',
  ORDER_UPDATE = 'Order Updates',
  ACKNOWLEDGMENTS_UPDATE = 'Acknowledgments Updates',
  BD_AGENT_UPDATES = 'B&D Agent updates',
}

const mapInvestorName = function (updates: InstitutionalDemandGridUpdate[], indications) {
  for (const item of updates) {
    const indicationId = item.indicationId;

    if (indicationId) {
      const indication = indications.find(indication => indication.id === indicationId);
      item.investorName =
        indication?.investorInformation?.bankInvestorName ||
        indication?.investorInformation?.cmgEntityName;
    } else {
      item.investorName = 'N/A';
    }
    item.read = false;
  }

  return updates;
};

export type InstitutionalDemandGridArg =
  | InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']
  | undefined;

export const useComputeDiffOnInstitutionalDemandGrid = function (
  currDemandGrid: InstitutionalDemandGridArg,
  nextDemandGrid: InstitutionalDemandGridArg,
  settlementAgent: string | null
) {
  const [pendingUpdates, setPendingUpdates] = React.useState<InstitutionalDemandGridUpdate[]>([]);
  const [recentUpdates, setRecentUpdates] = React.useState<InstitutionalDemandGridUpdate[]>([]);

  const finallAllocationDiffs = useComputeDiffOnFinalAllocationSet(
    currDemandGrid?.institutionalFinalAllocationSet,
    nextDemandGrid?.institutionalFinalAllocationSet
  );

  const draftAllocationSetsDiffs = useComputeDiffOnSharedDraftAllocationSets(
    currDemandGrid?.institutionalDraftAllocationSets,
    nextDemandGrid?.institutionalDraftAllocationSets
  );

  const acknowledgmentsDiffs = useComputeDiffOnInstitutionalAcknowledgments(
    currDemandGrid?.institutionalIndicationDemands,
    nextDemandGrid?.institutionalIndicationDemands
  );

  const billingAgentDiffs = useComputeDiffOnBillingAgent(
    currDemandGrid?.institutionalIndicationDemands,
    nextDemandGrid?.institutionalIndicationDemands,
    settlementAgent
  );

  const indicationDemandsDiffs = useComputeDiffOnInstitutionalIndicationDemands(
    currDemandGrid?.institutionalIndicationDemands,
    nextDemandGrid?.institutionalIndicationDemands
  );

  const results = React.useMemo(() => {
    return mapInvestorName(
      [
        ...draftAllocationSetsDiffs,
        ...acknowledgmentsDiffs,
        ...billingAgentDiffs,
        ...indicationDemandsDiffs,
        ...finallAllocationDiffs,
      ],
      nextDemandGrid?.institutionalIndicationDemands
    );
  }, [
    draftAllocationSetsDiffs,
    acknowledgmentsDiffs,
    billingAgentDiffs,
    indicationDemandsDiffs,
    nextDemandGrid,
    finallAllocationDiffs,
  ]);

  React.useEffect(() => {
    setRecentUpdates(prev => [...results, ...prev]);
  }, [results]);

  return {
    pendingUpdates,
    setPendingUpdates,
    recentUpdates,
    setRecentUpdates,
  };
};
