import styled, { css } from 'styled-components/macro';

/**
 * Marks an instrument in a dropdown as Depositary Receipt.
 * We want the color to be gray by default, but stay white
 * when rendering a selected option (which has a dark background)
 */
export const SDepositaryReceiptMarker = styled.div<{ shouldOverrideColor: boolean }>`
  ${({ theme, shouldOverrideColor }) =>
    shouldOverrideColor
      ? css`
          color: ${theme.text.color.darkGray};
        `
      : ''};
  font-size: ${({ theme }) => theme.text.size.tiny};
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin-left: 1em;
`;
