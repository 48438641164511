import { UUID } from '@cmg/common';

import { SalesCredits_HouseAccountsQuery } from './graphql';

export type HouseAccountValue = {
  firmName: string;
  bankInvestorKey: string | null | undefined;
  location: string | null | undefined;
  id: UUID;
};

export type SalesCreditsHouseAccountsFormValues = {
  houseAccounts: HouseAccountValue[];
};

export const getInitialValues = (
  data?: SalesCredits_HouseAccountsQuery
): SalesCreditsHouseAccountsFormValues => {
  if (!data) {
    return { houseAccounts: [] };
  }

  return {
    houseAccounts: data.salesCreditsHouseAccounts.map<HouseAccountValue>(
      ({ investorFirmName, bankInvestorKey, investorAddress, firmId, cmgEntityKey }) => ({
        firmName: investorFirmName,
        bankInvestorKey,
        location: investorAddress,
        id: firmId,
      })
    ),
  };
};
