import { isInternationalOffering } from '../../../common/util/offering/offering-type.util';
import { DeliveryInstrument, OfferingType } from '../../../graphql';
import {
  internationalOfferingTypeLabels,
  offeringTypeLabels,
  usOfferingTypeToInternational,
} from '../../../types/domain/offering/constants';

export function getOfferingPricingCountryCode({
  instruments,
  pricingInstrumentId,
}: {
  pricingInstrumentId: string | null | undefined;
  instruments: DeliveryInstrument[];
}) {
  return instruments?.find(instrument => instrument.id === pricingInstrumentId)?.countryCode;
}

export function getOfferingTypeLabel({
  offeringType,
  pricingInstrumentCountryCode,
  isExecutingOnPlatform,
}: {
  offeringType: OfferingType;
  pricingInstrumentCountryCode?: string | null;
  isExecutingOnPlatform?: boolean;
}) {
  if (isInternationalOffering({ pricingInstrumentCountryCode, isExecutingOnPlatform })) {
    const internationalOfferingType = usOfferingTypeToInternational[offeringType];
    return internationalOfferingTypeLabels[internationalOfferingType];
  }
  return offeringTypeLabels[offeringType];
}
