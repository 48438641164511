import { ApolloError } from '@apollo/client';
import { DatePickerField, DateTimePickerField, ISODate, SelectField } from '@cmg/common';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';

import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import { MarketTiming } from '../../../graphql';
import { marketTimingOptions } from '../../../types/domain/timing/constants';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { OfferingSetup_TimingPartsFragment } from './graphql';
import { useCalculateMinDates } from './hooks/useCalculateMinDates';
import { TimingSchema } from './TimingForm.model';
import { StyledDateTimeColumn, StyledPrimaryButton } from './TimingForm.styles';

type FormType = {
  confidentialFilingDate: string | null;
  publicFilingDate: string | null;
  filingOccurred: MarketTiming | null;
  launchOccurred: MarketTiming | null;
  launchDate: string | null;
  booksCloseAt: string | null;
  timeZone: string | null;
  pricingDate: string | null;
  firstTradeDate: string | null;
  tradeDate: string | null;
  settlementDate: string | null;
  postponedDate: string | null;
  withdrawnDate: string | null;
  terminatedDate: string | null;
};

type BooksCloseAtField = {
  booksCloseAtField: {
    date: string | null;
    timezone: string | null;
  };
};

export type Props = {
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onSubmit: (payload: FormType) => void;
  data?: OfferingSetup_TimingPartsFragment;
};

// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export const TimingFormComponent: React.FC<Props> = ({ disabled, loading, data, onSubmit }) => {
  const formikOptions: FormikConfig<FormType & BooksCloseAtField> = {
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: TimingSchema,
    initialValues: {
      confidentialFilingDate: data?.confidentialFilingDate ?? null,
      publicFilingDate: data?.publicFilingDate ?? null,
      filingOccurred: data?.filingOccurred ?? null,
      launchOccurred: data?.launchOccurred ?? null,
      launchDate: data?.launchDate ?? null,
      pricingDate: data?.pricingDate ?? null,
      firstTradeDate: data?.firstTradeDate ?? null,
      tradeDate: data?.tradeDate ?? null,
      settlementDate: data?.settlementDate ?? null,
      postponedDate: data?.postponedDate ?? null,
      withdrawnDate: data?.withdrawnDate ?? null,
      terminatedDate: data?.terminatedDate ?? null,
      booksCloseAt: data?.booksCloseAt ?? null,
      timeZone: data?.timeZone ?? null,
      // this fake formik field is used by DateTimePicker for booksCloseAt, timeZone
      // not part of the DTO
      booksCloseAtField: {
        date: data?.booksCloseAt ?? null,
        timezone: data?.timeZone ?? null,
      },
    },
    onSubmit: async ({ booksCloseAtField, ...values }) => {
      // submit correct DTO values without booksCloseAtField
      onSubmit({
        ...values,
        booksCloseAt: booksCloseAtField.date,
        timeZone: booksCloseAtField.timezone,
      } as FormType);
    },
  };

  const formik = useFormik(formikOptions);

  const { values, handleSubmit, setFieldTouched, dirty } = formik;

  // custom hook which returns the minimum date for each field validation
  const calculatedMinDates = {
    // booksCloseAt must be after these dates below, and so on
    booksCloseAt: useCalculateMinDates([
      values?.confidentialFilingDate,
      values?.publicFilingDate,
      values?.launchDate,
    ]),
    pricingDate: useCalculateMinDates([
      values?.confidentialFilingDate,
      values?.publicFilingDate,
      values?.launchDate,
      values?.booksCloseAtField?.date,
    ]),
    firstTradeDate: useCalculateMinDates([
      values?.confidentialFilingDate,
      values?.publicFilingDate,
      values?.launchDate,
      values?.booksCloseAtField?.date,
      values?.pricingDate,
    ]),
    tradeDate: useCalculateMinDates([
      values?.confidentialFilingDate,
      values?.publicFilingDate,
      values?.launchDate,
      values?.booksCloseAtField?.date,
      values?.pricingDate,
    ]),
    settlementDate: useCalculateMinDates([
      values?.confidentialFilingDate,
      values?.publicFilingDate,
      values?.launchDate,
      values?.booksCloseAtField?.date,
      values?.pricingDate,
    ]),
    launchDate: useCalculateMinDates([values?.confidentialFilingDate, values?.publicFilingDate]),
  };

  const fieldProps = {
    disabled: disabled || loading,
    fullWidth: true,
    withMargin: true,
  };

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard>
        <SetupScreen.Header
          rightContent={
            <StyledPrimaryButton disabled={!dirty || loading} onClick={() => handleSubmit()}>
              Save
            </StyledPrimaryButton>
          }
        />

        <SetupForm title="Timing Information">
          {data && (
            <SetupForm.Card>
              <SetupForm.Row>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="confidentialFilingDate"
                    label="Confidential Filing Date"
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('confidentialFilingDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="publicFilingDate"
                    label="Public Filing Date"
                    minDate={values?.confidentialFilingDate as ISODate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('publicFilingDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <SelectField
                    {...fieldProps}
                    options={marketTimingOptions}
                    name="filingOccurred"
                    label="Filing Occurred"
                  />
                </SetupForm.Column>
              </SetupForm.Row>
              <SetupForm.Row>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="launchDate"
                    label="Launch Date"
                    minDate={calculatedMinDates.launchDate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('launchDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <SelectField
                    {...fieldProps}
                    options={marketTimingOptions}
                    name="launchOccurred"
                    label="Launch Occurred"
                  />
                </SetupForm.Column>
              </SetupForm.Row>
              <SetupForm.Row>
                <StyledDateTimeColumn>
                  <DateTimePickerField
                    {...fieldProps}
                    showTimezones
                    label="Books Close at"
                    name="booksCloseAtField"
                    minDate={calculatedMinDates.booksCloseAt}
                    onCalendarClose={() => setFieldTouched('booksCloseAtField')}
                  />
                </StyledDateTimeColumn>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="pricingDate"
                    label="Pricing Date"
                    minDate={calculatedMinDates.pricingDate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('pricingDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="tradeDate"
                    label="Trade Date"
                    minDate={calculatedMinDates.tradeDate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('tradeDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="firstTradeDate"
                    label="First Trade Date"
                    minDate={calculatedMinDates.firstTradeDate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('firstTradeDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="settlementDate"
                    label="Settlement Date"
                    minDate={calculatedMinDates.settlementDate}
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('settlementDate')}
                  />
                </SetupForm.Column>
              </SetupForm.Row>
              <SetupForm.Row>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="postponedDate"
                    label="Postponed Date"
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('postponedDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="withdrawnDate"
                    label="Withdrawn Date"
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('withdrawnDate')}
                  />
                </SetupForm.Column>
                <SetupForm.Column>
                  <DatePickerField
                    {...fieldProps}
                    name="terminatedDate"
                    label="Terminated Date"
                    /** workaround to show field errors because onBlur is not called when day is picked */
                    onCalendarClose={() => setFieldTouched('terminatedDate')}
                  />
                </SetupForm.Column>
              </SetupForm.Row>
            </SetupForm.Card>
          )}
        </SetupForm>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default TimingFormComponent;
