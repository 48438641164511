import usePollingWhenVisible from '../../../../../../../common/hooks/usePollingWhenVisible';
import { isValidCmgEntityKey } from '../../../../../../../common/util/isValidCmgEntityKey';
import { canBillingAndDeliveryAgentUpdateInstitutionalIndication } from '../../../../../../../common/util/offering/syndicate-entitlements';
import { OrderBookPersonaType } from '../../../../hooks/useIdentifyOrderBookPersona';
import { useSalesAndTradingIndicationQuery } from '../../../context/hooks/sales-and-trading/__generated__';
import {
  OfferingSidePanelModel,
  offeringSidePanelModelDefaults,
  PersonaProps,
} from '../useOfferingSidePanelModel';

export type Props = PersonaProps & {
  hasComplianceWarning: boolean;
  cmgEntityKey: string | undefined;
  indicationId?: string | null;
};

export const useSalesAndTradingIndicationModel = ({
  offeringId,
  type,
  cmgEntityKey,
  hasComplianceWarning,
  indicationId,
}: Props): OfferingSidePanelModel => {
  const {
    loading: indicationLoading,
    data: indicationData,
    error: indicationsError,
    stopPolling,
    startPolling,
    called,
  } = useSalesAndTradingIndicationQuery({
    variables: {
      offeringId,
      cmgEntityKey: cmgEntityKey!,
      indicationId,
      skipIndicationPart: !isValidCmgEntityKey(cmgEntityKey),
    },
    skip: !cmgEntityKey || type !== OrderBookPersonaType.SALES_AND_TRADING || !offeringId,
    fetchPolicy: 'cache-and-network',
  });

  usePollingWhenVisible({ pollInterval: 5_000, startPolling, stopPolling, called });

  const coveredIndication = indicationData?.coveredIndication ?? null;
  const offering = indicationData?.basicPublishedOffering!;

  return {
    ...offeringSidePanelModelDefaults,
    indication: {
      data: coveredIndication,
      canRead: true,
      canUpdate: !hasComplianceWarning,
      canCreate: !hasComplianceWarning,
    },
    indicationAcknowledgment: {
      canRead: true,
      canUpdate: !hasComplianceWarning,
    },
    allocationAcknowledgment: {
      canRead: true,
      canUpdate: true,
      canBillingAndDeliveryAgentUpdate: canBillingAndDeliveryAgentUpdateInstitutionalIndication({
        billingAndDeliveryAgentCmgEntityKey: coveredIndication?.billingAndDeliveryAgentCmgEntityKey,
        syndicatesEntitlements: offering?.syndicateEntitlements!.syndicateEntitlements,
      }),
    },
    queryResult: {
      data: {
        ...indicationData!,
        persona: OrderBookPersonaType.SALES_AND_TRADING,
      },
      loading: indicationLoading,
      error: indicationsError ?? null,
      stopPolling: stopPolling,
    },
  };
};
