/**
 * Finds the item added or removed given values and previous values
 * Calls onDelete or onAdd with affected item
 */
export const handleAddOrDeleteOnChange = (
  previousValues: string[],
  values: string[],
  { onDelete, onAdd }: { onDelete: (id: string) => void; onAdd: (id: string) => void }
) => {
  if (previousValues.length === values.length) {
    return;
  }

  const shouldDelete = previousValues.length > values.length;

  const [id] = shouldDelete
    ? previousValues.filter(d => !values.includes(d))
    : values.filter(d => !previousValues.includes(d));

  shouldDelete ? onDelete(id) : onAdd(id);
};
