import styled from 'styled-components/macro';

export const SCertificatesInfo = styled.div`
  padding 5px 0 5px 0;
  display: inline;
`;

export const SPopoverContentWrapper = styled.div`
  max-width: 248px;
  line-height: 1.43;
`;
