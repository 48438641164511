import { Alert, AlertTitle, Button } from '@cmg/design-system';
import React from 'react';

type Recipient = Readonly<{
  cmgEntityKey: string;
  distributionList: readonly string[];
  firmName: string;
}>;

export type Props<TRecipient extends Recipient = Recipient> = Readonly<{
  readonly recipients: TRecipient[];
  readonly onContactSupport: () => void;
  // Temporary property, should be removed when rewriting the module to design-system layout
  readonly withMargin?: boolean;
}>;

const EmptyDistributionListWarning = <TRecipient extends Recipient>({
  recipients,
  withMargin,
  onContactSupport,
}: Props<TRecipient>) => {
  const recipientsWithoutEmails = React.useMemo(
    () => recipients.filter(({ distributionList }) => !distributionList.length),
    [recipients]
  );

  if (!recipientsWithoutEmails.length) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      title="Email distribution lists are missing for some recipients."
      action={
        <Button color="inherit" onClick={() => onContactSupport()}>
          Contact Support
        </Button>
      }
      sx={{ marginBottom: withMargin ? 2 : 0 }}
    >
      <AlertTitle>Email distribution lists are missing for some recipients.</AlertTitle>
      {recipientsWithoutEmails.map(({ firmName }) => firmName).join(', ')}
    </Alert>
  );
};

export default EmptyDistributionListWarning;
