import { numericUtil } from '@cmg/common';

import { FinalSettlement_SettlementLetterQuery } from '../graphql';

function splitValues(value: number) {
  return {
    gain: value < 0 ? 0 : value,
    loss: value < 0 ? -value : 0,
  };
}

export function getTotalValues({
  managementFee,
  underwritingFee,
  sellingConcession,
  managerExpensesReimbursements,
  managerDealRelatedExpenses,
  adjustmentValue,
  managerGainLossOnOversale,
  finalSettlement,
}: FinalSettlement_SettlementLetterQuery['settlementLetter']) {
  const { gain: managerGainOnOversale, loss: managerLossOnOversale } = splitValues(
    managerGainLossOnOversale ?? 0
  );
  const { gain: manualAdjustmentGain, loss: manualAdjustmentLoss } = splitValues(
    adjustmentValue ?? 0
  );

  return {
    totalDebit: numericUtil.sum(
      numericUtil.negate(managerDealRelatedExpenses), // managerDealRelatedExpenses are always negative
      managerLossOnOversale,
      manualAdjustmentLoss,
      finalSettlement
    ),
    totalCredit: numericUtil.sum(
      managementFee,
      underwritingFee,
      sellingConcession,
      managerExpensesReimbursements,
      managerGainOnOversale,
      manualAdjustmentGain
    ),
  };
}
