import { DangerButton, PrimaryButton, SecondaryButton, SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { useFormikContext } from 'formik';
import React from 'react';

import { EventStatus, RoadshowStatus } from '../../../../../../../graphql';
import {
  Roadshows_EventParticipantsTeamPartsFragment,
  Roadshows_ManagementTeamMemberPartsFragment,
} from '../../../../../common/graphql';
import EventHeaderInfo from '../../../common/event-header-info/EventHeaderInfo';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import {
  investorsContainMembers,
  participantsContainManagementMember,
} from './MeetingButtonGroup.model';
import { SFormTitle, SMeetingHeader } from './MeetingButtonGroup.styles';

export type Props = {
  meetingHeaderInfo: EventInfo | null;
  meetingStatus?: EventStatus;
  roadshowStatus: RoadshowStatus;
  meetingInvestors?: readonly Roadshows_EventParticipantsTeamPartsFragment[];
  eventParticipantsTeam?: readonly Roadshows_EventParticipantsTeamPartsFragment[];
  meetingManagementMembers?: readonly Roadshows_ManagementTeamMemberPartsFragment[];

  onDelete?: () => void;
  onCancel?: () => void;
  onOpenToSales?: () => void;
  onConfirm?: () => void;

  isDeleting?: boolean;
  isCanceling?: boolean;
  isOpeningToSales?: boolean;
  isConfirming?: boolean;
  isSaving: boolean;
};

const MeetingButtonGroup: React.FC<Props> = ({
  meetingHeaderInfo,
  meetingStatus,
  roadshowStatus,
  meetingInvestors = [],
  meetingManagementMembers = [],
  eventParticipantsTeam = [],
  onDelete,
  onCancel,
  onOpenToSales,
  onConfirm,
  isDeleting = false,
  isCanceling = false,
  isOpeningToSales = false,
  isConfirming = false,
  isSaving,
}) => {
  const { dirty, resetForm, isValid, submitForm } = useFormikContext();

  const isOperationRunning =
    isOpeningToSales || isConfirming || isDeleting || isSaving || isCanceling;

  const canDelete = onDelete && !dirty && meetingStatus === EventStatus.Draft;
  const canOpenToSales = onOpenToSales && !dirty && meetingStatus === EventStatus.Draft;
  const canConfirm =
    onConfirm &&
    !dirty &&
    !!meetingStatus &&
    [EventStatus.Draft, EventStatus.OpenToSales].includes(meetingStatus);

  const canCreate = dirty && !meetingStatus;
  const canUpdate = dirty && !!meetingStatus;
  const canCancel =
    onCancel &&
    !dirty &&
    meetingStatus &&
    [EventStatus.Confirmed, EventStatus.OpenToSales].includes(meetingStatus);

  return (
    <SMeetingHeader>
      {!meetingStatus && <SFormTitle>Create New Meeting</SFormTitle>}
      {!!meetingHeaderInfo && <EventHeaderInfo eventInfo={meetingHeaderInfo} />}

      {canDelete && (
        <SecondaryButton
          testId={xcSelectors.roadshowMeetingDetailsFormDeleteMeetingDraftButton.testId}
          onClick={onDelete}
          loading={isDeleting}
          loadingLabel="Deleting"
          disabled={isOperationRunning}
        >
          Delete Draft
        </SecondaryButton>
      )}

      {canCancel && (
        <DangerButton
          testId={xcSelectors.roadshowMeetingDetailsFormCancelButton.testId}
          onClick={onCancel}
          loading={isCanceling}
          loadingLabel="Canceling"
          disabled={isOperationRunning}
        >
          Cancel Meeting
        </DangerButton>
      )}
      {canOpenToSales && (
        <PrimaryButton
          testId="open a meeting to sales button"
          onClick={onOpenToSales}
          loading={isOpeningToSales}
          loadingLabel="Opening To Sales"
          disabled={isOperationRunning || roadshowStatus !== RoadshowStatus.PublishedInternally}
        >
          Open To Sales
        </PrimaryButton>
      )}
      {canConfirm && (
        <SuccessButton
          testId={xcSelectors.roadshowMeetingDetailsFormConfirmButton.testId}
          onClick={onConfirm}
          loading={isConfirming}
          loadingLabel="Confirming"
          disabled={
            isOperationRunning ||
            !investorsContainMembers(meetingInvestors) ||
            !participantsContainManagementMember({
              meetingManagementMembers,
              eventParticipantsTeam,
            })
          }
        >
          Confirm
        </SuccessButton>
      )}

      {canCreate && (
        <PrimaryButton
          testId="save a new meeting event button"
          onClick={submitForm}
          loading={isSaving}
          loadingLabel="Saving"
          disabled={isOperationRunning || !isValid}
        >
          Save Draft
        </PrimaryButton>
      )}
      {canUpdate && (
        <SecondaryButton
          testId={xcSelectors.roadshowMeetingDetailsFormDiscardChangesButton.testId}
          onClick={() => resetForm()}
          disabled={isOperationRunning}
        >
          Discard Changes
        </SecondaryButton>
      )}
      {canUpdate && (
        <PrimaryButton
          testId={xcSelectors.roadshowMeetingDetailsFormSaveChangesButton.testId}
          onClick={submitForm}
          loading={isSaving}
          loadingLabel="Saving"
          disabled={isOperationRunning || !isValid}
        >
          Save Changes
        </PrimaryButton>
      )}
    </SMeetingHeader>
  );
};

export default MeetingButtonGroup;
