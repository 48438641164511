import { AgGridApi, Checkbox, DropdownSelect, Icon } from '@cmg/common';
import React, { useCallback } from 'react';

import { SelectionFilter } from '../../../../DesignationMonitorRoute.model';
import { DesignationMonitorGridContext } from '../../../../hooks/useDesignationContext';
import { isDesignationRowSelectablePredicate } from '../../DesignationMonitorGrid.model';
import { SButtonWrapper, SIconButton } from './HeaderGroupCheckbox.styles';

export type Props = {
  context: DesignationMonitorGridContext;
  api: AgGridApi;
};

const dropDownItems = [
  { label: 'Select All', value: 'select-all' },
  { label: 'Unedited', value: 'unedited' },
  { label: 'Edited', value: 'edited' },
  { label: 'Out of Balance', value: 'out-of-balance' },
  { label: 'Balanced', value: 'balanced' },
];

export const HeaderGroupCheckbox: React.FC<Props> = ({ api, context }) => {
  const allSelectableRows = React.useMemo(
    () =>
      context.investorRows.filter(row => isDesignationRowSelectablePredicate({ data: row })).length,
    [context.investorRows]
  );
  const selectedRowsLength = api.getSelectedNodes().length;
  const isSelected = selectedRowsLength > 0 && allSelectableRows === selectedRowsLength;

  const onDropdownSelectChange = useCallback(
    (values: string[]) => {
      context?.onSelectionFilterChange?.(values as SelectionFilter[]);
    },
    [context]
  );

  const onCheckboxChange = useCallback(
    (value: boolean) => {
      context?.onSelectionFilterChange?.([value ? 'select-all' : 'unselect-all']);
    },
    [context]
  );

  return (
    <SButtonWrapper>
      <Checkbox onChange={onCheckboxChange} value={isSelected} />
      <DropdownSelect
        items={dropDownItems}
        onChange={onDropdownSelectChange}
        popoverVariant="LIGHT"
        label={
          <SIconButton>
            <Icon name="chevron-down" variant="regular" color="black" />
          </SIconButton>
        }
      />
    </SButtonWrapper>
  );
};

export default HeaderGroupCheckbox;
