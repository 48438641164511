/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RoadshowListQueryVariables = Types.Exact<{
  filter: Types.RoadshowFilterInput;
  pagination: Types.PaginationDataInput;
}>;

export type Roadshows_RoadshowListQuery = {
  readonly __typename?: 'Query';
  readonly roadshows: {
    readonly __typename?: 'RoadshowLimitedPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'RoadshowLimited';
      readonly id: string;
      readonly roadshowName: string;
      readonly roadshowType: Types.RoadshowType;
      readonly companyName?: string | null;
      readonly startDate: string;
      readonly endDate: string;
      readonly offeringId?: string | null;
      readonly offeringIssuerName?: string | null;
    }>;
  };
};

export const Roadshows_RoadshowListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshows' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Roadshows_RoadshowLimitedParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowListQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRoadshows_RoadshowListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowListQuery,
    Roadshows_RoadshowListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_RoadshowListQuery, Roadshows_RoadshowListQueryVariables>(
    Roadshows_RoadshowListDocument,
    options
  );
}
export function useRoadshows_RoadshowListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowListQuery,
    Roadshows_RoadshowListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_RoadshowListQuery, Roadshows_RoadshowListQueryVariables>(
    Roadshows_RoadshowListDocument,
    options
  );
}
export type Roadshows_RoadshowListQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowListQuery
>;
export type Roadshows_RoadshowListLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowListLazyQuery
>;
export type Roadshows_RoadshowListQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowListQuery,
  Roadshows_RoadshowListQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowListMockResponse = makeMockResponse<
  Roadshows_RoadshowListQueryVariables,
  Roadshows_RoadshowListQuery
>(Roadshows_RoadshowListDocument);
