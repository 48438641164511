import React from 'react';

import { filtersInitialValues } from '../../filters/Filters.model';
import {
  paginationInitialValues,
  RoadshowPaginatedList,
} from '../../grid/RoadshowsListDataGrid.model';
import { useRoadshows_RoadshowListQuery } from '../graphql';

export const useRoadshowPaginatedListQuery = (): RoadshowPaginatedList => {
  const [filter, setFilter] = React.useState(filtersInitialValues);
  const [pagination, setPagination] = React.useState(paginationInitialValues);

  const { data, loading, error } = useRoadshows_RoadshowListQuery({
    variables: { filter, pagination },
    fetchPolicy: 'cache-and-network',
  });

  return { data: data?.roadshows.data || [], loading, error, setFilter, setPagination };
};
