import { numericUtil } from '@cmg/common';

import { FinalSettlement_StabilizationInformationPartsFragment } from '../../../graphql';

export const getTotalShortPosition = ({
  finalAuthorizedOverallotment,
  additionalShortPosition,
}: FinalSettlement_StabilizationInformationPartsFragment) => {
  return -finalAuthorizedOverallotment + additionalShortPosition;
};

export const getTotalRemaining = ({
  totalShortPosition,
  sharesRepurchased,
  sharesRefreshed,
  overallotmentExcercised,
}: {
  totalShortPosition: number;
  sharesRepurchased: number | null;
  sharesRefreshed: number | null;
  overallotmentExcercised: number | null | undefined;
}) => {
  return numericUtil.sum(
    totalShortPosition,
    sharesRepurchased,
    sharesRefreshed,
    overallotmentExcercised
  );
};
