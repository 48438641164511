import styled from 'styled-components/macro';

export const SUnderwriterOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OnPlatformLogo = styled.img<{ visible?: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: auto;
  width: 23px;
`;

export const SUnderwriterOptionInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2px;
`;

export const SUnderwriterOptionTitle = styled.span`
  font-size: ${({ theme }) => theme.text.size.s};
  color: rgb(3, 5, 7);
  line-height: 18px;
`;

export const SUnderwriterOptionDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SUnderwriterOptionDetail = styled.span`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: rgba(3, 5, 7, 0.55);
  line-height: 16px;
`;
