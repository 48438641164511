/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CompanySearchPartsFragment = {
  readonly __typename?: 'CompanySearch';
  readonly id: string;
  readonly cmgEntityKey?: string | null;
  readonly factSetFirmId?: string | null;
  readonly name: string;
};

export type Roadshows_CompaniesSearchQueryVariables = Types.Exact<{
  filter: Types.CompanyFilterInput;
  pagination: Types.PaginationDataInput;
}>;

export type Roadshows_CompaniesSearchQuery = {
  readonly __typename?: 'Query';
  readonly companySearch: {
    readonly __typename?: 'CompanySearchPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'CompanySearch';
      readonly id: string;
      readonly cmgEntityKey?: string | null;
      readonly factSetFirmId?: string | null;
      readonly name: string;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly activePage: number;
      readonly perPage: number;
      readonly totalPages?: number | null;
      readonly hasNext?: boolean | null;
      readonly hasPrevious: boolean;
      readonly totalCount?: number | null;
    };
  };
};

export const Roadshows_CompanySearchPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_CompanySearchParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanySearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factSetFirmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_CompaniesSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CompaniesSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companySearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Roadshows_CompanySearchParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'activePage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPrevious' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_CompanySearchParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanySearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'factSetFirmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CompaniesSearchQuery__
 *
 * To run a query within a React component, call `useRoadshows_CompaniesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CompaniesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CompaniesSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRoadshows_CompaniesSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CompaniesSearchQuery,
    Roadshows_CompaniesSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_CompaniesSearchQuery, Roadshows_CompaniesSearchQueryVariables>(
    Roadshows_CompaniesSearchDocument,
    options
  );
}
export function useRoadshows_CompaniesSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CompaniesSearchQuery,
    Roadshows_CompaniesSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_CompaniesSearchQuery,
    Roadshows_CompaniesSearchQueryVariables
  >(Roadshows_CompaniesSearchDocument, options);
}
export type Roadshows_CompaniesSearchQueryHookResult = ReturnType<
  typeof useRoadshows_CompaniesSearchQuery
>;
export type Roadshows_CompaniesSearchLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CompaniesSearchLazyQuery
>;
export type Roadshows_CompaniesSearchQueryResult = Apollo.QueryResult<
  Roadshows_CompaniesSearchQuery,
  Roadshows_CompaniesSearchQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_CompaniesSearchMockResponse = makeMockResponse<
  Roadshows_CompaniesSearchQueryVariables,
  Roadshows_CompaniesSearchQuery
>(Roadshows_CompaniesSearchDocument);
