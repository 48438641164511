/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_BankInvestorsQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type SalesCredits_BankInvestorsQuery = {
  readonly __typename?: 'Query';
  readonly allInvestorFirmsMappedToCmgEntityKey: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmBasic';
    readonly id: string;
    readonly cmgEntityKey?: string | null;
    readonly name: string;
    readonly address1?: string | null;
    readonly address2?: string | null;
    readonly firmKey?: string | null;
  }>;
  readonly salesCreditsHouseAccounts: ReadonlyArray<{
    readonly __typename?: 'HouseAccount';
    readonly id: string;
    readonly investorFirmName: string;
    readonly bankInvestorKey?: string | null;
    readonly cmgEntityKey: string;
    readonly firmId: string;
    readonly investorAddress?: string | null;
  }>;
};

export const SalesCredits_BankInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesCredits_BankInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInvestorFirmsMappedToCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCreditsHouseAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_HouseAccountParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesCredits_BankInvestorsQuery__
 *
 * To run a query within a React component, call `useSalesCredits_BankInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_BankInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCredits_BankInvestorsQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSalesCredits_BankInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesCredits_BankInvestorsQuery,
    SalesCredits_BankInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesCredits_BankInvestorsQuery, SalesCredits_BankInvestorsQueryVariables>(
    SalesCredits_BankInvestorsDocument,
    options
  );
}
export function useSalesCredits_BankInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCredits_BankInvestorsQuery,
    SalesCredits_BankInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesCredits_BankInvestorsQuery,
    SalesCredits_BankInvestorsQueryVariables
  >(SalesCredits_BankInvestorsDocument, options);
}
export type SalesCredits_BankInvestorsQueryHookResult = ReturnType<
  typeof useSalesCredits_BankInvestorsQuery
>;
export type SalesCredits_BankInvestorsLazyQueryHookResult = ReturnType<
  typeof useSalesCredits_BankInvestorsLazyQuery
>;
export type SalesCredits_BankInvestorsQueryResult = Apollo.QueryResult<
  SalesCredits_BankInvestorsQuery,
  SalesCredits_BankInvestorsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../../../graphql/mock-response';

export const makeSalesCredits_BankInvestorsMockResponse = makeMockResponse<
  SalesCredits_BankInvestorsQueryVariables,
  SalesCredits_BankInvestorsQuery
>(SalesCredits_BankInvestorsDocument);
