import { AgGridApi } from '@cmg/common';
import { IconButton, KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '@cmg/design-system';
import React from 'react';

import { IndicationWithDemandLevels } from '../../types';

export type Props = {
  readonly api: AgGridApi;
  data: IndicationWithDemandLevels;
};

export const DupeGroupCellRenderer: React.FC<Props> = ({ api, data }) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const currentNode = api.getRenderedNodes().find(n => n.data.id === data.id);

  const childNodes = api
    .getRenderedNodes()
    .filter(n => currentNode?.data.duplicateIndicationIds.includes(n.data.id));

  React.useEffect(() => {
    if (childNodes.length > 0) {
      childNodes.forEach(node => {
        node.setRowHeight(0);
      });
      api.onRowHeightChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (childNodes.length === 0) {
    return <div></div>;
  }

  const handleOnClick = () => {
    childNodes.forEach(node => {
      node.setRowHeight(!isExpanded ? 36 : 0);
    });
    api.onRowHeightChanged();
    setIsExpanded(!isExpanded);
  };

  return (
    <IconButton
      onClick={handleOnClick}
      aria-label="group-dupe"
      sx={{ backgroundColor: 'transparent', padding: 0 }}
      disableFocusRipple
      disableRipple
    >
      {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};
