import { GridApi } from 'ag-grid-community';

import { tradeColsMap } from '../../grid/TradeReleaseGrid.model';

export function unselectAllRows(gridApi?: GridApi) {
  gridApi?.deselectAll();
  gridApi?.refreshCells({
    force: true,
    columns: [tradeColsMap.selected],
  });
}
