import { UUID } from '@cmg/common';

import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';
import { useBrokerDealersMyRetailQuery } from './useBrokerDealersMyRetailQuery';

/**
 * For Non-Syndicate wires the managers/recipients come from Retail Demand rather than Offering Details
 */
export function createUseDefaultIsNonSyndicateWireManagerListOutdated() {
  return ({
    offeringId,
    syndicateWireId,
  }: {
    offeringId: UUID;
    syndicateWireId: UUID;
  }): boolean | undefined => {
    const { useManagers } = useWireTypeConfigContext();

    const brokerDealersMyRetailQuery = useBrokerDealersMyRetailQuery({
      offeringId,
    });

    const wireManagersQuery = useManagers({ offeringId, syndicateWireId });

    if (
      brokerDealersMyRetailQuery.loading ||
      wireManagersQuery.isLoading ||
      brokerDealersMyRetailQuery.error ||
      wireManagersQuery.error
    ) {
      return undefined;
    }

    const wireManagers = wireManagersQuery.data ?? [];
    const brokerDealers = brokerDealersMyRetailQuery.data ?? [];

    return (
      wireManagers.length !== brokerDealers.length ||
      wireManagers.some(wireManager =>
        brokerDealers.every(brokerDealer => brokerDealer.cmgEntityKey !== wireManager.cmgEntityKey)
      )
    );
  };
}
