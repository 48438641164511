import styled from 'styled-components/macro';

export const SArticle = styled.article`
  display: flex;
  font-size: ${({ theme }) => theme.text.size.large};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};

  > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const SPresentation = styled.div.attrs(() => ({ role: 'presentation' }))``;

export const SQtyLayout = styled(SPresentation)`
  display: flex;
  flex-direction: column;
`;

export const SLabel = styled.label`
  margin-top: 4px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.text.size.xxs};
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  letter-spacing: 0.05em;
  width: 100%;
  text-align: center;
`;

export const ExclamationContainer = styled(SPresentation)`
  display: flex;
  align-items: center;
  > * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;
