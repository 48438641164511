import React from 'react';

import Loading from '../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { Roadshows_RoadshowPartsFragment } from '../../../common/graphql';
import EmptyCompanyDetails from './empty-company-details/EmptyCompanyDetails';
import {
  useRoadshows_CompanyDetailsQuery,
  useRoadshows_RoadshowManagementTeamQuery,
} from './graphql';
import SingleCompanyDetails from './single-company-details/SingleCompanyDetails';

export type Props = { roadshow: Roadshows_RoadshowPartsFragment };

const CompanyDetails: React.FC<Props> = ({ roadshow }) => {
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useRoadshows_CompanyDetailsQuery({
    variables: { cmgEntityKey: roadshow.companyId ?? '' },
    skip: roadshow.companyId === null,
  });
  const {
    data: managementTeamData,
    loading: managementTeamLoading,
    error: managementTeamError,
  } = useRoadshows_RoadshowManagementTeamQuery({
    variables: { roadshowId: roadshow.id },
    skip: !roadshow.companyId,
  });

  const error = companyError || managementTeamError;
  const loading = companyLoading || managementTeamLoading;

  if (!roadshow.companyId) {
    return <EmptyCompanyDetails roadshowId={roadshow.id} />;
  }

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <SingleCompanyDetails
      roadshowId={roadshow.id}
      companyDetails={companyData!.companyDetails}
      managementTeam={managementTeamData!.roadshowManagementTeam}
    />
  );
};

export default CompanyDetails;
