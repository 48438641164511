import { MemberFirmType, RecordStatus } from '../../../graphql';
import { RPNFilingFinraMemberFirm } from '../filings/restricted-period-notification/form/RPNForm.model';
import { RegulatoryFilings_RolodexSyndicateManagerPartsFragment } from '../graphql';
import {
  AffiliateWithOwnerCmgEntityKey,
  FinraMemberFirmForm,
  FinraNonMemberFirm,
} from './content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { AffiliatesAndDistributionParticipants } from './regulatory-filings.types';

export const affiliateToRPNFinraMemberAndNonMembers = (
  affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants
) => {
  const { affiliates, distributionParticipants } = affiliatesAndDistributionParticipants;

  const finraMemberFirms: RPNFilingFinraMemberFirm[] = [];
  const finraNonMemberFirms: FinraNonMemberFirm[] = [];

  distributionParticipants.forEach(({ cmgEntityKey, finraInfo, firmName }) => {
    if (typeof finraInfo?.crdNumber === 'number') {
      const formIndex = finraMemberFirms.length;
      finraMemberFirms.push({
        firmName: finraInfo.name ?? '',
        firmMpid: finraInfo.mpid ?? '',
        ownerCmgEntityKey: null,
        firmCrdNumber: finraInfo.crdNumber.toString(),
        formIndex,
        affiliateMemberId: null,
        cmgEntityKey,
        excusedOrPassive: MemberFirmType.Excused,
      });
    } else {
      finraNonMemberFirms.push({
        firmName,
        cmgEntityKey,
        ownerCmgEntityKey: null,
      });
    }
  });

  affiliates.forEach(({ id, crdNumber, mpid, name, ownerCmgEntityKey }) => {
    if (typeof crdNumber === 'number') {
      const formIndex = finraMemberFirms.length;
      finraMemberFirms.push({
        firmName: name,
        firmMpid: mpid ?? '',
        ownerCmgEntityKey,
        firmCrdNumber: crdNumber.toString(),
        formIndex,
        affiliateMemberId: id,
        cmgEntityKey: null,
        excusedOrPassive: MemberFirmType.Excused,
      });
    } else {
      finraNonMemberFirms.push({
        firmName: name,
        cmgEntityKey: null,
        ownerCmgEntityKey,
      });
    }
  });

  return { finraMemberFirms, finraNonMemberFirms };
};

export const affiliateToTNFinraMemberAndNonMembers = (
  affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants
) => {
  const { affiliates, distributionParticipants } = affiliatesAndDistributionParticipants;

  const finraMemberFirms: FinraMemberFirmForm[] = [];
  const finraNonMemberFirms: FinraNonMemberFirm[] = [];

  distributionParticipants.forEach(({ finraInfo, firmName, cmgEntityKey }) => {
    if (typeof finraInfo?.crdNumber === 'number') {
      const formIndex = finraMemberFirms.length;
      finraMemberFirms.push({
        firmName: finraInfo.name ?? '',
        firmMpid: finraInfo.mpid ?? '',
        ownerCmgEntityKey: null,
        firmCrdNumber: finraInfo.crdNumber.toString(),
        formIndex,
        affiliateMemberId: null,
        cmgEntityKey,
      });
    } else {
      finraNonMemberFirms.push({
        firmName,
        cmgEntityKey,
        ownerCmgEntityKey: null,
      });
    }
  });

  affiliates.forEach(({ id, crdNumber, mpid, name, ownerCmgEntityKey }) => {
    if (typeof crdNumber === 'number') {
      const formIndex = finraMemberFirms.length;
      finraMemberFirms.push({
        firmName: name,
        firmMpid: mpid ?? '',
        ownerCmgEntityKey,
        firmCrdNumber: crdNumber.toString(),
        formIndex,
        affiliateMemberId: id,
        cmgEntityKey: null,
      });
    } else {
      finraNonMemberFirms.push({
        firmName: name,
        cmgEntityKey: null,
        ownerCmgEntityKey,
      });
    }
  });

  return { finraMemberFirms, finraNonMemberFirms };
};

export const getAffiliatesAndDistributionParticipants = (
  offeringManagersWithAffiliates: Readonly<RegulatoryFilings_RolodexSyndicateManagerPartsFragment[]>
): {
  affiliates: AffiliateWithOwnerCmgEntityKey[];
  distributionParticipants: RegulatoryFilings_RolodexSyndicateManagerPartsFragment[];
} => {
  const distributionParticipantsCrdNumbers = new Set<string>();
  const distributionParticipants: RegulatoryFilings_RolodexSyndicateManagerPartsFragment[] = [];

  offeringManagersWithAffiliates.forEach(manager => {
    if (manager.recordStatus === RecordStatus.Effective && manager.isParticipating) {
      distributionParticipants.push(manager);

      if (manager.finraInfo?.crdNumber) {
        distributionParticipantsCrdNumbers.add(manager.finraInfo.crdNumber.toString());
      }
    }
  });

  const affiliates: AffiliateWithOwnerCmgEntityKey[] = distributionParticipants.flatMap(manager => {
    return manager.affiliates
      .filter(a =>
        typeof a.crdNumber === 'number'
          ? // exclude affiliates that are FINRA distribution participants
            !distributionParticipantsCrdNumbers.has(a.crdNumber.toString())
          : true
      )
      .map(affiliate => ({
        ...affiliate,
        ownerCmgEntityKey: manager.cmgEntityKey,
      }));
  });

  return { affiliates, distributionParticipants };
};
