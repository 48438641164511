import { UUID } from '@cmg/common';
import { FC, useCallback, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import routeFactory from '../../../../../common/util/routeFactory';
import OverwriteDesignationSidePanel from './overwrite-designation-side-panel/OverwriteDesignationSidePanel';

export type OverwriteDesignationRouteParams = { offeringId: UUID; indicationId: UUID };
type RouteProps = RouteComponentProps<OverwriteDesignationRouteParams>;
export type Props = RouteProps;

/**
 * Primary Route for the Overwrite Designation.
 */
export const OverwriteDesignationRoute: FC<Props> = ({ match }) => {
  const history = useHistory();
  const { offeringId, indicationId } = match.params;

  const [isOverwriteDesignationSidePanelOpen, setOverwriteDesignationSidePanelOpen] =
    useState(true);
  const onClose = useCallback(() => {
    setOverwriteDesignationSidePanelOpen(false);
    history.replace(routeFactory.finalSettlementDesignationMonitor.getUrlPath({ offeringId }));
  }, [history, offeringId]);

  return (
    <OverwriteDesignationSidePanel
      isOpen={isOverwriteDesignationSidePanelOpen}
      onClose={onClose}
      offeringId={offeringId}
      indicationId={indicationId}
    />
  );
};

export default OverwriteDesignationRoute;
