import { UUID } from '@cmg/common';
import {
  DropdownButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { useIsUserSettlementAgent } from '../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { OfferingStatus, OrderBookState } from '../../../../../graphql';

export type Props = Readonly<{
  offeringId: UUID;
  onUpdateOrderBookStatus: () => void;
  orderBookState: OrderBookState | undefined;
  orderBookSalesState: OrderBookState | undefined;
  onUpdateOrderBookSalesState: () => void;
  offeringStatus: OfferingStatus | undefined;
}>;

export const OrderBookStatusDropdownButton: React.FC<Props> = ({
  offeringId,
  onUpdateOrderBookStatus,
  orderBookState,
  orderBookSalesState,
  onUpdateOrderBookSalesState,
  offeringStatus,
}) => {
  const btnRef = React.useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = React.useState(false);

  const { isSettlementAgent } = useIsUserSettlementAgent({ offeringId });
  const isPricedOffering = offeringStatus === OfferingStatus.Priced;

  return (
    <Stack>
      <DropdownButton
        ref={btnRef}
        aria-controls="order-book-status-menu"
        isExpanded={isOpened}
        onClick={() => setIsOpened(o => !o)}
        variant="outlined"
      >
        Order Book Status
      </DropdownButton>
      <Menu
        open={isOpened}
        onClose={() => setIsOpened(false)}
        id="menu-id"
        anchorEl={btnRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ListSubheader> Allow Indications </ListSubheader>
        {isSettlementAgent && (
          <MenuItem>
            <Switch
              onClick={onUpdateOrderBookStatus}
              checked={orderBookState === OrderBookState.Open}
              disabled={isPricedOffering}
            />
            <ListItemText secondary="Sell-Side & Buy-Side">
              <Typography color={isPricedOffering ? 'text.secondary' : 'text.primary'}>
                Syndicate
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem>
          <Switch
            onClick={onUpdateOrderBookSalesState}
            checked={orderBookSalesState === OrderBookState.Open}
          />
          <ListItemText secondary="Sales & Trading">
            <Typography>Internal</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
