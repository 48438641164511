import { RowNode } from 'ag-grid-community';
import React from 'react';

import { SalesCreditReleaseStatus } from '../../../../../graphql';
import ReleaseStatus from './release-status/ReleaseStatus';

export type Props = { value: SalesCreditReleaseStatus; node: RowNode };

const ReleaseStatusRenderer: React.FC<Props> = ({ value, node }) => {
  if (node.isRowPinned()) {
    return null;
  }

  return <ReleaseStatus status={value} />;
};

export default ReleaseStatusRenderer;
