import { PrimaryButton, SecondaryButton, SuccessButton, Table } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import React from 'react';

import ServerErrorsBanner from '../../../../indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../layout/flex-layout/FlexLayout';
import Spinner from '../../../../overlays/spinner/Spinner';
import {
  ButtonGroup,
  Divider,
  Footer,
  ModalContent,
} from '../AdvancedInvestorSearchFlowModal.styles';
import { InvestorFirmSearch_Advanced_InvestorBasicPartsFragment } from '../graphql';
import {
  SSearchResultRow,
  StyledNoResultsFound,
  StyledSearchResultsHeader,
} from './AdvancedInvestorSearch.styles';
import AdvancedInvestorSearchForm from './AdvancedInvestorSearchForm';
import { useInvestorFirmSearch_Advanced_InvestorFirmsLazyQuery } from './graphql';

export type Props = {
  onApply: (investor: InvestorFirmSearch_Advanced_InvestorBasicPartsFragment) => void;
  onClose: () => void;
  onCreateNewInvestor: () => void;
  showCreateNewInvestor: boolean;
};

/**
 * AdvancedInvestorSearch - advanced search modal content lets the user search for a CRM investor firm using additional fields
 * if the value of the main investor search is populated, it will automatically be populated in the advanced search and a search will be invoked
 *
 * The user can select an investor from the results list and press "Apply" to submit their selection as the investor,
 * if the investor firm was not found the user can select "Create New" and add a new investor firm
 */
export const AdvancedInvestorSearch: React.FC<Props> = ({
  onClose,
  onApply,
  onCreateNewInvestor,
  showCreateNewInvestor,
}) => {
  const [selectedInvestor, setSelectedInvestor] =
    React.useState<InvestorFirmSearch_Advanced_InvestorBasicPartsFragment | null>(null);
  const [searchInvestorFirms, { data, loading, error, called }] =
    useInvestorFirmSearch_Advanced_InvestorFirmsLazyQuery();

  const handleApply = () => {
    onApply(selectedInvestor!);
    onClose();
  };

  const handleSelectInvestor = investor => {
    // deselect investor if already selected
    if (isEqual(investor, selectedInvestor)) {
      setSelectedInvestor(null);
      return;
    }

    setSelectedInvestor(investor);
  };

  const hasData = !!data && !!data?.investorFirms?.data && data.investorFirms.data.length > 0;
  const noResultsFound = !loading && !error && called && !hasData;

  return (
    <ModalContent direction="column" expand>
      <h3>Advanced Search</h3>
      <p>
        Please provide the name of the investor or any additional values to find a specific
        investor.
      </p>
      <AdvancedInvestorSearchForm onSubmit={values => searchInvestorFirms({ variables: values })} />
      <Divider />
      <FlexLayout direction="column" expand>
        <StyledSearchResultsHeader direction="row">
          <h3>Search Results</h3>
          {showCreateNewInvestor && (
            <FlexLayout direction="row">
              <p>Can't find the right investor?</p>
              <div>
                <PrimaryButton onClick={onCreateNewInvestor}>Create Investor</PrimaryButton>
              </div>
            </FlexLayout>
          )}
        </StyledSearchResultsHeader>
        {error && <ServerErrorsBanner error={error} />}
        {loading && <Spinner show fullHeight />}
        {data && (
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Bank CRM Key</th>
                <th>Location</th>
                <th>Website</th>
                <th>CIK</th>
                <th>LEI</th>
                <th>CRD</th>
              </tr>
            </thead>
            <tbody>
              {hasData &&
                data.investorFirms.data!.map(investor => (
                  <SSearchResultRow
                    key={investor.id}
                    onClick={() => handleSelectInvestor(investor)}
                    selected={isEqual(investor, selectedInvestor)}
                  >
                    <td>{investor.name}</td>
                    <td>{investor.firmKey || '-'}</td>
                    <td>
                      {!isNil(investor.address1) ? investor.address1 : investor.address2 || '-'}
                    </td>
                    <td>
                      {!isNil(investor.website1) ? investor.website1 : investor.website2 || '-'}
                    </td>
                    <td>{!isNil(investor.cik1) ? investor.cik1 : investor.cik2 || '-'}</td>
                    <td>{investor.lei || '-'}</td>
                    <td>{investor.crd || '-'}</td>
                  </SSearchResultRow>
                ))}
            </tbody>
          </Table>
        )}
        {noResultsFound && <StyledNoResultsFound expand>No results found</StyledNoResultsFound>}
      </FlexLayout>
      <Footer>
        <ButtonGroup>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <SuccessButton disabled={!selectedInvestor} onClick={handleApply}>
            Apply
          </SuccessButton>
        </ButtonGroup>
      </Footer>
    </ModalContent>
  );
};

export default AdvancedInvestorSearch;
