import { SecondaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin: -8px;
  margin-left: auto;
`;

export const STextWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  padding-top: 36px;
`;

export const SErrorWrapper = styled.div`
  color: ${({ theme }) => theme.text.color.error};
`;
