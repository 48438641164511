import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument,
  useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
} from '../graphql';

export type DeleteNonSyndicateSellingGroupTermsRetentionWireMutation =
  SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation;

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}

export const useDeleteNonSyndicateSellingGroupTermsRetentionWireMutation = () => {
  return useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
