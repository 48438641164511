import { Option, UUID } from '@cmg/common';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import * as yup from 'yup';

import {
  Roadshows_DealTeamMemberPartsFragment,
  Roadshows_EventParticipantPartsFragment,
  Roadshows_EventParticipantsTeamPartsFragment,
  Roadshows_ManagementTeamMemberPartsFragment,
} from '../../../../common/graphql';
import { Roadshows_RosterQuery } from '../graphql';

export type Values = {
  participants: Roadshows_EventParticipantPartsFragment[];
};

type ParticipantOption = Option & {
  teamId?: UUID;
  teamName: string | null | undefined;
  member: Roadshows_DealTeamMemberPartsFragment | Roadshows_ManagementTeamMemberPartsFragment;
};

/**
 * Maps collection of team members to options
 */
export const getTeamMemberOptions = (
  members: readonly (
    | Roadshows_DealTeamMemberPartsFragment
    | Roadshows_ManagementTeamMemberPartsFragment
  )[],
  teamName: string | null | undefined,
  teamId?: string
): ParticipantOption[] => {
  return members.map(member => ({
    label: member.name,
    value: member.id,
    teamId,
    teamName,
    member,
  }));
};

/**
 * Maps roster team members to to flat list of participant options
 */
export const getParticipantOptions = ({
  roster,
  companyName,
  excludedParticipantIds,
}: {
  roster: Roadshows_RosterQuery;
  companyName: string | null | undefined;
  excludedParticipantIds: UUID[];
}): ParticipantOption[] => {
  const { roadshowManagementTeam, roadshowDealTeams } = roster;
  const dealTeamMemberOptions = flatten(
    roadshowDealTeams.map(team => getTeamMemberOptions(team.members, team.firmName, team.id))
  );
  const managementTeamMemberOptions = getTeamMemberOptions(
    roadshowManagementTeam.members,
    companyName,
    roadshowManagementTeam.id
  );

  return [
    ...orderBy(managementTeamMemberOptions, ['label'], 'asc'),
    ...orderBy(dealTeamMemberOptions, ['teamName', 'label'], ['asc', 'asc']),
  ].filter(({ value }) => !excludedParticipantIds.includes(value));
};

/**
 * Filters all participants matching given team members
 */
export const getTeamParticipants = (
  members: readonly (
    | Roadshows_DealTeamMemberPartsFragment
    | Roadshows_ManagementTeamMemberPartsFragment
  )[],
  participants: Roadshows_EventParticipantPartsFragment[]
) => {
  return participants.filter(
    ({ id, isAdHoc }) => members.some(member => member.id === id) && !isAdHoc
  );
};

export const getAdHocParticipants = (participants: Roadshows_EventParticipantPartsFragment[]) => {
  return participants.filter(item => item.isAdHoc);
};

/**
 * Group participants into corresponding teams
 */
export const groupParticipantsByTeam = ({
  roster,
  companyName,
  participants,
}: {
  roster: Roadshows_RosterQuery;
  companyName: string;
  participants: Roadshows_EventParticipantPartsFragment[];
}) => {
  const managementTeam = {
    id: roster.roadshowManagementTeam.id,
    firmName: companyName,
    participants: [
      ...getTeamParticipants(roster.roadshowManagementTeam.members, participants),
      ...getAdHocParticipants(participants),
    ],
    showExtendedInfo: true,
  };

  const dealTeams = roster.roadshowDealTeams.map(team => {
    return {
      id: team.id,
      firmName: team.firmName,
      participants: getTeamParticipants(team.members, participants),
      showExtendedInfo: false,
    };
  });

  return [managementTeam, ...dealTeams];
};

export const getValidationSchema = (managementTeamMemberIds: UUID[]) =>
  yup.object().shape({
    participants: yup
      .array()
      .test(
        'management-team-is-required',
        'At least one member of Management Team is required to participate the meeting',
        (value: Roadshows_EventParticipantPartsFragment[] | undefined) => {
          if (value) {
            return value.some(({ id, isAdHoc }) => isAdHoc || managementTeamMemberIds.includes(id));
          } else {
            return false;
          }
        }
      ),
  });

export const getInitialValues = (
  participantTeams: readonly Roadshows_EventParticipantsTeamPartsFragment[]
) => {
  return flatten(participantTeams.map(team => team.participants));
};
