import { FormLabel, MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { OfferingType } from '../../../../../../graphql';
import {
  internationalOfferingTypeLabels,
  offeringTypeLabels,
} from '../../../../../../types/domain/offering/constants';

export const SOfferingTypeFilter = styled.div`
  width: 280px;
  margin: 0 20px;
`;

export type Props = {
  offeringTypes: string[];
  onChange: (value: string[]) => void;
  values: string[];
};

const OfferingTypeFilter: React.FC<Props> = ({ offeringTypes, onChange, values }) => {
  const options = offeringTypes.map<Option<string>>(type => ({
    label: Object.values(OfferingType).includes(type as OfferingType)
      ? offeringTypeLabels[type]
      : internationalOfferingTypeLabels[type],
    value: type,
  }));

  return (
    <SOfferingTypeFilter>
      <FormLabel>Offering Type</FormLabel>
      <MultiSelect<string>
        options={options}
        onChange={onChange}
        value={values}
        maxDisplayedValues={2}
      />
    </SOfferingTypeFilter>
  );
};

export default OfferingTypeFilter;
