import { xcSelectors } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import { FormikProvider, useFormik } from 'formik';
import includes from 'lodash/includes';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { RowAlertsObject } from '../../components/row-alert/RowAlert.model';
import { useInstitutionalDemandPreferencesContext } from '../../context/InstitutionalDemandPreferencesContext';
import { useGetFinalAllocationColumns } from '../../grid-columns/columns-configuration/allocation-set-columns/FinalAllocationSetColumn';
import { useCmgColumns } from '../../grid-columns/columns-configuration/CmgColumns';
import { useComplianceColumns } from '../../grid-columns/columns-configuration/ComplianceColumns';
import { useGetDemandColumns } from '../../grid-columns/columns-configuration/DemandLevelColumns';
import { useGetNotesColumns } from '../../grid-columns/columns-configuration/NotesColumns';
import { InstitutionalDemandColumnConfig } from '../../types';
import {
  getHeaderName,
  hideFilter,
  isReadOnly,
  validationSchema,
} from './InstitutionalDemandGridColumnMenu.model';
import {
  SDemandColumnFieldsWrapper,
  SDemandColumnTitle,
  SMenuContainer,
  StyledCheckbox,
  StyledNumericInputField,
  StyledReferencePriceInput,
} from './InstitutionalDemandGridColumnMenu.styles';

export type Props = {
  activeDemandColumnConfig: InstitutionalDemandColumnConfig;
  onDemandColumnConfigChange: (values: InstitutionalDemandColumnConfig) => void;
  isFinalAllocationSetReleased: boolean;
  finalAllocationExists: boolean;
  offeringId: string;
};

/**
 * Renders the menu for the Institutional Demand Grid Column Dropdown
 */
export const InstitutionalDemandGridColumnMenuComponent: React.FC<Props> = ({
  onDemandColumnConfigChange,
  activeDemandColumnConfig,
  isFinalAllocationSetReleased,
  finalAllocationExists,
  offeringId,
}) => {
  const formik = useFormik<InstitutionalDemandColumnConfig>({
    initialValues: activeDemandColumnConfig,
    validationSchema,
    onSubmit: onDemandColumnConfigChange,
  });

  const { visibleColumns, setVisibleColumns } = useInstitutionalDemandPreferencesContext();

  const { handleSubmit } = formik;
  const debouncedHandleSubmit = useDebouncedCallback(() => {
    handleSubmit();
  }, 700);

  const onSelectOption = (colId: string, newValue: boolean) => {
    const newColumns = newValue
      ? [...visibleColumns, colId]
      : visibleColumns.filter(c => c !== colId);
    setVisibleColumns(newColumns);
  };

  const generateSelectableOption = (c: ColDef): JSX.Element => (
    <StyledCheckbox
      key={c.colId!}
      id={c.colId!}
      disabled={isReadOnly(c)}
      onChange={value => !isReadOnly(c) && onSelectOption(c.colId!, value)}
      value={isReadOnly(c) || includes(visibleColumns, c.colId!)}
    >
      {getHeaderName(c)}
    </StyledCheckbox>
  );
  const defaultRowAlertObject: RowAlertsObject = {
    errors: 0,
    warnings: 0,
  };

  const columns = useCmgColumns(offeringId, defaultRowAlertObject);
  const demandColumns = useGetDemandColumns();
  const complianceColumns = useComplianceColumns();
  const notesColumns = useGetNotesColumns();
  const finalAllocationColumns = useGetFinalAllocationColumns({
    finalAllocationExists,
    isFinalAllocationSetReleased,
    offeringId,
  });

  return (
    <FormikProvider value={formik}>
      <SMenuContainer data-test-id={xcSelectors.institutionalDemandGridColumnsDropdownMenu.testId}>
        {columns.filter(hideFilter).map(generateSelectableOption)}
        <SDemandColumnTitle>Compliance</SDemandColumnTitle>
        {complianceColumns.filter(hideFilter).map(generateSelectableOption)}
        <SDemandColumnTitle>Other</SDemandColumnTitle>
        {notesColumns.filter(hideFilter).map(generateSelectableOption)}
        <SDemandColumnTitle>Demand Columns</SDemandColumnTitle>
        <SDemandColumnFieldsWrapper>
          {/* // TODO - add currency code */}
          <StyledNumericInputField
            name="min"
            label="Low"
            onChange={() => debouncedHandleSubmit()}
          />
          {/* // TODO - add currency code */}
          <StyledNumericInputField
            name="max"
            label="High"
            onChange={() => debouncedHandleSubmit()}
          />
          <StyledNumericInputField
            name="increment"
            label="Increment"
            onChange={() => debouncedHandleSubmit()}
          />
        </SDemandColumnFieldsWrapper>
        {/* // TODO - add currency code */}
        <StyledReferencePriceInput
          label="Reference Price"
          name="referencePrice"
          precision={2}
          onChange={() => debouncedHandleSubmit()}
        ></StyledReferencePriceInput>
        {demandColumns.filter(hideFilter).map(generateSelectableOption)}
        {finalAllocationColumns.filter(hideFilter).map(generateSelectableOption)}
      </SMenuContainer>
    </FormikProvider>
  );
};
