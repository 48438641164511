import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import {
  BaseAgGrid,
  defaultFrameworkComponents,
  exchangeMicLabels,
  valueFormatters,
} from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import sortByDateComparator from '../../../../../common/util/sortByDateComparator';
import { sortCaseInsensitive } from '../../../../../common/util/sortCaseInsensitive';
import { securityTypeLabels } from '../../../../../types/domain/security/constants';
import { defaultCellStyle } from '../../../../final-settlement/designation-monitor/designation-monitor-details/designation-monitor-grid/DesignationMonitorGrid.model';
import { TradeRelease_TradeGridItemFragment } from '../../graphql/__generated__';
import TradeActionsRenderer from '../grid-columns/actions/TradeActionsRenderer';
import InvestorNameRenderer from '../grid-columns/investor-name/InvestorNameRenderer';
import TotalInvestorNameRenderer from '../grid-columns/investor-name/TotalInvestorNameRenderer';
import { AcknowledgementStatusRenderer } from '../grid-columns/investor-reply-status/AcknowledgementStatusRenderer';
import HeaderGroupCheckboxRenderer from '../grid-columns/selected/HeaderGroupCheckboxRenderer';
import { selectedColumnCellRendererSelector } from '../grid-columns/selected/selectedColumnCellRendererSelector';
import { sharesReleasedCellRendererSelector } from '../grid-columns/shares-released/sharesReleasedCellRendererSelector';
import ReleaseStatusRenderer from '../grid-columns/trade-status/ReleaseStatusRenderer';
import TotalWarningRenderer from '../grid-columns/trade-warning/TotalWarningRenderer';
import TradeWarningRenderer from '../grid-columns/trade-warning/TradeWarningRenderer';
import { tradeColsMap } from '../TradeReleaseGrid.model';

const defaultColumnDefinition = {
  editable: false,
  lockPinned: true,
  valueFormatter: valueFormatters.getStringValueFormatter(),
};
const pinnedColumnDefinition = {
  ...defaultColumnDefinition,
  suppressAutoSize: true,
  suppressMovable: true,
};

export const exchangeValueGetter = ({ data }: { data: TradeRelease_TradeGridItemFragment }) =>
  data.exchange ? exchangeMicLabels[data.exchange].shortName : null;

export const securityTypeValueGetter = ({ data }: { data: TradeRelease_TradeGridItemFragment }) =>
  securityTypeLabels[data.securityType];

export const useTradeGridColumns = (): Parameters<typeof BaseAgGrid>[0]['columns'] => {
  const theme = useTheme();
  const hasFullAccess = useCheckPermissions([permissionsByEntity.Trade.FULL]);

  return React.useMemo(
    () => [
      {
        ...pinnedColumnDefinition,
        pinned: 'left',
        colId: tradeColsMap.warnings,
        field: tradeColsMap.warnings,
        headerName: '',
        sortable: false,
        maxWidth: 32,
        minWidth: 32,
        cellRendererFramework: TradeWarningRenderer,
        pinnedRowCellRendererFramework: TotalWarningRenderer,
      },
      ...(hasFullAccess
        ? [
            {
              ...pinnedColumnDefinition,
              pinned: 'left',
              colId: tradeColsMap.selected,
              field: tradeColsMap.selected,
              headerName: '',
              sortable: false,
              maxWidth: 64,
              minWidth: 64,
              cellRendererSelector: selectedColumnCellRendererSelector,
              headerComponentFramework: HeaderGroupCheckboxRenderer,
            },
          ]
        : []),
      {
        ...pinnedColumnDefinition,
        pinned: 'left',
        colId: tradeColsMap.investorFirmName,
        field: tradeColsMap.investorFirmName,
        headerName: 'Investor',
        suppressAutoSize: false,
        minWidth: 225,
        maxWidth: 225,
        cellRendererFramework: InvestorNameRenderer,
        pinnedRowCellRendererFramework: TotalInvestorNameRenderer,
        equals: () => true,
        cellStyle: {
          borderRight: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
          display: 'flex',
          alignItems: 'center',
        },
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.allocationShares,
        field: tradeColsMap.allocationShares,
        headerName: 'Final Allocation—B&D',
        minWidth: 160,
        valueFormatter: valueFormatters.getIntegerValueFormatter({ shouldFormatPinnedRow: true }),
        headerClass: 'ag-header-right',
        cellStyle: defaultCellStyle,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.sharesReleased,
        field: tradeColsMap.sharesReleased,
        headerName: 'Quantity Released',
        minWidth: 160,
        cellRendererSelector: sharesReleasedCellRendererSelector,
        valueFormatter: valueFormatters.getIntegerValueFormatter({ shouldFormatPinnedRow: true }),
        headerClass: 'ag-header-right',
        cellStyle: defaultCellStyle,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.investorReplyStatus,
        field: tradeColsMap.investorReplyStatus,
        headerName: 'Allocation Status',
        minWidth: 200,
        cellRendererFramework: AcknowledgementStatusRenderer,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.statusDetails,
        field: tradeColsMap.statusDetails,
        headerName: 'Status Details',
        minWidth: 200,
        cellRendererFramework: defaultFrameworkComponents.longStringCellRenderer,
        comparator: sortCaseInsensitive,
        cellStyle: { overflow: 'hidden' },
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.indicationSubmittedBy,
        field: tradeColsMap.indicationSubmittedBy,
        headerName: 'Indication Submitted By',
        minWidth: 200,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.indicationInvestorContactName,
        field: tradeColsMap.indicationInvestorContactName,
        headerName: 'Investor Contact (Name)',
        minWidth: 200,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.indicationInvestorContactEmail,
        field: tradeColsMap.indicationInvestorContactEmail,
        headerName: 'Investor Contact (Email)',
        minWidth: 200,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.investorReplyName,
        field: tradeColsMap.investorReplyName,
        headerName: 'Final Allocation acknowledged by (Name)',
        minWidth: 300,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.investorReplyEmail,
        field: tradeColsMap.investorReplyEmail,
        headerName: 'Final Allocation acknowledged by (Email)',
        minWidth: 300,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.indicationAcknowledgedByName,
        field: tradeColsMap.indicationAcknowledgedByName,
        headerName: 'Indication Acknowledged by (Name)',
        minWidth: 300,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.indicationAcknowledgedByEmail,
        field: tradeColsMap.indicationAcknowledgedByEmail,
        headerName: 'Indication Acknowledged by (Email)',
        minWidth: 300,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.releasedAt,
        field: tradeColsMap.releasedAt,
        headerName: 'Released At',
        minWidth: 300,
        valueFormatter: valueFormatters.getDateTimeValueFormatter(),
        comparator: sortByDateComparator('desc'),
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.releasedByName,
        field: tradeColsMap.releasedByName,
        headerName: 'Released By (Name)',
        minWidth: 200,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.releasedByEmail,
        field: tradeColsMap.releasedByEmail,
        headerName: 'Released By (Email)',
        minWidth: 200,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.symbol,
        field: tradeColsMap.symbol,
        headerName: 'Symbol',
        minWidth: 150,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.exchange,
        field: tradeColsMap.exchange,
        headerName: 'Exchange',
        minWidth: 150,
        valueGetter: exchangeValueGetter,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.cusip,
        field: tradeColsMap.cusip,
        headerName: 'CUSIP',
        minWidth: 150,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.offerPrice,
        field: tradeColsMap.offerPrice,
        headerName: 'Offer Price',
        minWidth: 150,
        valueFormatter: valueFormatters.getCurrencyValueFormatter(),
        headerClass: 'ag-header-right',
        cellStyle: defaultCellStyle,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.isin,
        field: tradeColsMap.isin,
        headerName: 'ISIN',
        minWidth: 150,
        comparator: sortCaseInsensitive,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.securityType,
        field: tradeColsMap.securityType,
        headerName: 'Security Type',
        minWidth: 200,
        valueGetter: securityTypeValueGetter,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.sellingConcession,
        field: tradeColsMap.sellingConcession,
        headerName: 'Selling Concession',
        minWidth: 200,
        valueFormatter: valueFormatters.getCurrencyValueFormatter({ precision: 6 }),
        headerClass: 'ag-header-right',
        cellStyle: defaultCellStyle,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.tradeDate,
        field: tradeColsMap.tradeDate,
        headerName: 'Trade Date',
        minWidth: 200,
        valueFormatter: valueFormatters.getDateValueFormatter(),
        comparator: sortByDateComparator('desc'),
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.settlementDate,
        field: tradeColsMap.settlementDate,
        headerName: 'Settlement Date',
        minWidth: 200,
        valueFormatter: valueFormatters.getDateValueFormatter(),
        comparator: sortByDateComparator('desc'),
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.settlementCurrency,
        field: tradeColsMap.settlementCurrency,
        headerName: 'Settlement Currency',
        minWidth: 150,
      },
      {
        ...defaultColumnDefinition,
        colId: tradeColsMap.offeringCurrency,
        field: tradeColsMap.offeringCurrency,
        headerName: 'Offering Currency',
        minWidth: 150,
      },
      {
        ...pinnedColumnDefinition,
        pinned: 'right',
        colId: tradeColsMap.tradeStatus,
        field: tradeColsMap.tradeStatus,
        headerName: 'Trade Release Status',
        minWidth: 170,
        cellRendererFramework: ReleaseStatusRenderer,
        cellStyle: {
          borderLeft: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
        },
      },
      ...(hasFullAccess
        ? [
            {
              ...pinnedColumnDefinition,
              pinned: 'right',
              colId: tradeColsMap.allowedStatusTransitions,
              fieldId: tradeColsMap.allowedStatusTransitions,
              headerName: '',
              sortable: false,
              maxWidth: 32,
              minWidth: 32,
              cellRendererFramework: TradeActionsRenderer,
            },
          ]
        : []),
    ],
    [hasFullAccess, theme]
  );
};
