import React from 'react';

import { orderBookApiClient } from '../../../../../common/api/orderBookApiClient';
import { encodeFileName } from '../../../../../common/util/encodeFileName';
import { OnExportResult } from '../../../components/export-button/ExportButton';

export function useExportTotalRetailDemand({ offeringIssuerName, offeringId }) {
  const [loading, setLoading] = React.useState(false);

  const handleExport = React.useCallback(async (): Promise<OnExportResult> => {
    setLoading(true);
    try {
      const response = await orderBookApiClient.retailDemands_GetTotalRetailExport(offeringId);
      return {
        fileContentBlob: response.data,
        encodedFileName: encodeFileName(offeringIssuerName, 'Total_Retail'),
      };
    } finally {
      setLoading(false);
    }
  }, [offeringId, offeringIssuerName]);

  return { handleExport, loading };
}
