import { useState } from 'react';

import { OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery } from '../../graphql';
import { ManagerEconomicBreakdownRow } from '../components/economics-breakdown-grid/EconomicBreakdownGrid.model';
import { isEconomicBreakdownDataValid } from './useValidateEconomicBreakdownGrid.model';

type UnderwritingFeesAndDiscounts =
  OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery['underwritingTermsFeesAndDiscounts'];

export type EconomicsBreakdownErrors = {
  totalIncentiveFee: boolean | null;
  totalUnderwritingFee: boolean | null;
  missingOfferingSize: boolean | null;
  missingBaseGrossSpread: boolean | null;
  missingGrossSpreadAllocation: boolean | null;
  missingOverallotmentExercised: boolean | null;
  missingIncentiveFeeAllocation: boolean | null;
};

export type Props = {
  totalValues: ManagerEconomicBreakdownRow;
  grossSpreadBaseData: UnderwritingFeesAndDiscounts['grossSpreadBaseData'] | null | undefined;
  incentiveFeeData: UnderwritingFeesAndDiscounts['incentiveFeeData'] | null | undefined;
  totalSharesBaseOffering: number | null | undefined;
  totalSharesOverallotmentExercised: number | null | undefined;
};

export enum EconomicBreakdownValidationTypes {
  ECONOMIC_BREAKDOWN = 'economics-breakdown',
  EXERCISED_OVERALLTOMENT = 'exercised-overallotment',
}

export const useValidateEconomicBreakdownGrid = (props: Props) => {
  const [errors, setErrors] = useState<EconomicsBreakdownErrors>({
    totalIncentiveFee: null,
    totalUnderwritingFee: null,
    missingOfferingSize: null,
    missingBaseGrossSpread: null,
    missingGrossSpreadAllocation: null,
    missingOverallotmentExercised: null,
    missingIncentiveFeeAllocation: null,
  });

  const validateData = (type: EconomicBreakdownValidationTypes) => {
    const { errors, isValid } = isEconomicBreakdownDataValid({ ...props, type });

    setErrors(errors);

    return isValid;
  };

  return { errors, validateData };
};
