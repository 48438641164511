import { UUID } from '@cmg/common';
import { Checkbox, ListItem, ListItemIcon, SnackbarManager } from '@cmg/design-system';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import React from 'react';

import { useCheckDraftAllocationAccess } from '../../../../../../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess as useCheckAllocationAccess } from '../../../../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { useCheckInstitutionalPassiveOrderBookAccess as useCheckPassiveOrderBookAccess } from '../../../../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { useInstitutionalDemandPreferencesContext } from '../../../../../context/InstitutionalDemandPreferencesContext';
import { getDraftAllocationId, InstitutionalDraftSet } from '../../../../../types';
import AllocationSetListItemText from '../../common/allocation-set-list-item-text/AllocationSetListItemText';
import MenuItemKebabMenu from '../../common/menu-item-kebab-menu/MenuItemKebabMenu';
import { useDuplicateDraftAllocationSetMutation } from './hooks/useDuplicateDraftAllocationSetMutation';

export type Props = Readonly<{
  offeringId: UUID;
  draftAllocationSet: InstitutionalDraftSet;
  onEditDraftAllocationSet: () => void;
  onDeleteDraftAllocationSet: () => void;
  onShareDraftAllocationSet: () => void;
  onPromoteDraftAllocationSetToFinal: () => void;
  onCopyDraftAllocationSetToDefault: () => void;
  onCloseAllocationsDropdown: () => void;
}>;

const DraftAllocationListItem: React.FC<Props> = ({
  offeringId,
  draftAllocationSet,
  onEditDraftAllocationSet,
  onDeleteDraftAllocationSet,
  onShareDraftAllocationSet,
  onPromoteDraftAllocationSetToFinal,
  onCopyDraftAllocationSetToDefault,
  onCloseAllocationsDropdown,
}) => {
  const [duplicate] = useDuplicateDraftAllocationSetMutation();

  const { visibleColumns, setVisibleColumns } = useInstitutionalDemandPreferencesContext();
  const { canManage: canManageAllocation } = useCheckAllocationAccess({ offeringId });
  const { canManage: canManagePassiveOrderBook } = useCheckPassiveOrderBookAccess({ offeringId });
  const { canManage: canManageDraftAllocation } = useCheckDraftAllocationAccess({ offeringId });

  const { name, id, dealLayout, auditInfo, isDefault } = draftAllocationSet;

  const draftAllocationId = React.useMemo(() => getDraftAllocationId(id!), [id]);
  const isSelected = React.useMemo(
    () => includes(visibleColumns, draftAllocationId),
    [draftAllocationId, visibleColumns]
  );

  const canDelete = !draftAllocationSet.isDefault && isNil(draftAllocationSet.shareTimestamp);

  const handleCheckColumnVisibility = React.useCallback(
    (_, isChecked: boolean) => {
      const nextVisibleColumns = isChecked
        ? [...visibleColumns, draftAllocationId]
        : visibleColumns.filter(c => c !== draftAllocationId);

      setVisibleColumns(nextVisibleColumns);
    },
    [draftAllocationId, setVisibleColumns, visibleColumns]
  );

  const handleDuplicate = React.useCallback(async () => {
    try {
      await duplicate({ variables: { offeringId, setId: id! } });
      SnackbarManager.success('Successfully duplicated the allocation set');
      handleCheckColumnVisibility(undefined, true);
      onCloseAllocationsDropdown();
    } catch {
      SnackbarManager.error('Failed to duplicate the allocation set');
    }
  }, [duplicate, handleCheckColumnVisibility, id, offeringId, onCloseAllocationsDropdown]);

  return (
    <ListItem
      aria-label={`Draft Allocation Set: ${name ?? 'Draft'}`}
      secondaryAction={
        <MenuItemKebabMenu
          colId={draftAllocationId}
          onCloseAllocationsDropdown={onCloseAllocationsDropdown}
          canEdit={isNil(draftAllocationSet.shareTimestamp)}
          onEdit={onEditDraftAllocationSet}
          canDelete={canDelete}
          onDelete={onDeleteDraftAllocationSet}
          canDuplicate
          onDuplicate={handleDuplicate}
          canShare={!canManagePassiveOrderBook && canManageDraftAllocation && isDefault}
          onShare={onShareDraftAllocationSet}
          canPromoteToFinal={canManageAllocation}
          onPromoteToFinal={onPromoteDraftAllocationSetToFinal}
          canCopyToDefault={!isDefault}
          onCopyToDefault={onCopyDraftAllocationSetToDefault}
        />
      }
    >
      <ListItemIcon>
        <Checkbox checked={isSelected} onChange={handleCheckColumnVisibility} />
      </ListItemIcon>
      <AllocationSetListItemText
        name={name ?? 'Draft'}
        updatedAt={auditInfo?.modifiedAt ?? null}
        offeringSize={dealLayout.offeringSize}
        institutionalTarget={dealLayout.institutionalTarget}
      />
    </ListItem>
  );
};

export default DraftAllocationListItem;
