import { DropdownButton, IconButton, Popover } from '@cmg/common';
import { RowNode } from 'ag-grid-community';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import {
  GridTransitionSalesCreditReleaseStatus,
  InternalSalesCreditReleaseStatus,
  SalesCreditWarning,
} from '../../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { SalesCreditsGridContext } from '../SalesCreditsGrid.model';

export type Props = {
  node: RowNode;
  data?: SalesCredits_SalesCreditItemPartsFragment;
  context: SalesCreditsGridContext;
};

const ActionsRenderer: React.FC<Props> = ({ node, data, context }) => {
  const theme = useTheme();

  const isTotalRow = node.isRowPinned();
  const { onRelease, onUpdateStatus } = context;

  const allowedTransitions = data?.allowedStatusTransitions ?? [];
  const canRelease = allowedTransitions.includes(GridTransitionSalesCreditReleaseStatus.Released);
  const canCancel = allowedTransitions.includes(
    GridTransitionSalesCreditReleaseStatus.CancelPending
  );
  const canBookManual = allowedTransitions.includes(
    GridTransitionSalesCreditReleaseStatus.BookedManual
  );
  const canCancelManual = allowedTransitions.includes(
    GridTransitionSalesCreditReleaseStatus.CancelledManual
  );
  const isDisabled = data?.warnings?.includes(SalesCreditWarning.OrderBookBankInvestorKeyChanged);

  const handleRelease = () => {
    if (data) {
      onRelease([data.investorFirmCmgEntityKey]);
    }
  };
  const handleCancel = () => {
    if (data) {
      onUpdateStatus(InternalSalesCreditReleaseStatus.CancelPending, [data.salesCreditsId!]);
    }
  };
  const handleBookedManual = () => {
    if (data) {
      onUpdateStatus(InternalSalesCreditReleaseStatus.BookedManual, [data.salesCreditsId!]);
    }
  };
  const handleCancelManual = () => {
    if (data) {
      onUpdateStatus(InternalSalesCreditReleaseStatus.CancelledManual, [data.salesCreditsId!]);
    }
  };

  if (isTotalRow || allowedTransitions.length === 0) {
    return null;
  }

  return (
    <Popover
      variant="DARK"
      content={
        <span>Preview the modified Bank Investor Key before taking any further actions</span>
      }
      placement="topRight"
      arrowPointAtCenter
      disabled={!isDisabled}
    >
      <span>
        <DropdownButton
          disabled={isDisabled}
          buttonComponent={() => (
            <IconButton
              icon={{
                name: 'ellipsis-v',
                variant: 'regular',
                size: '1x',
              }}
              color={theme.designSystem.colors.darkBlue[200]}
              disabled={isDisabled}
            />
          )}
          options={[
            {
              testId: 'release-sales-credits',
              label: 'Release Sales Credits',
              onClick: handleRelease,
              disabled: !canRelease,
            },
            {
              testId: 'cancel-release',
              label: 'Cancel Release',
              onClick: handleCancel,
              disabled: !canCancel,
            },
            {
              testId: 'mark-booked',
              label: 'Mark as Booked (Manual)',
              onClick: handleBookedManual,
              disabled: !canBookManual,
            },
            {
              testId: 'mark-cancelled',
              label: 'Mark as Cancelled (Manual)',
              onClick: handleCancelManual,
              disabled: !canCancelManual,
            },
          ]}
          popoverVariant="LIGHT"
        />
      </span>
    </Popover>
  );
};

export default ActionsRenderer;
