import type { FilterValueGetterFn, ValueFormatterFunc } from '@cmg/data-grid';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import type { DemandGridRowData, DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { OnPlatformCellRenderer } from './OnPlatformCellRenderer';

/**
 * Exported for testing purposes
 */
export const filterValueGetter: FilterValueGetterFn = filterValue => {
  const setFilterValues = get(filterValue, 'in');

  if (!isArray(setFilterValues)) {
    return {};
  }

  return setFilterValues[0] === 'true' ? { eq: true } : { eq: false };
};

/**
 * Exported for testing purposes
 */
export const filterValueFormatter: ValueFormatterFunc<DemandGridRowData, boolean> = ({ value }) => {
  return value ? 'On Platform' : 'Off Platform';
};

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<boolean>({
  gridRow: { component: OnPlatformCellRenderer },
});

/**
 * Exported for testing purposes
 */
export const valueFormatter = getValueFormatter<boolean>({
  gridRow: ({ value }) => (value ? 'On Platform' : 'Off Platform'),
});

const onPlatformColDef: DemandGridServerColDef<boolean> = {
  field: 'investor.isOnPlatform',
  headerName: 'On Platform',
  width: 130,
  minWidth: 24,
  valueFormatter,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [true, false],
    valueFormatter: filterValueFormatter,
  },
  server: {
    filterValueGetter,
  },
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default onPlatformColDef;
