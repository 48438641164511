import { MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { ManagerProfile } from '../../../../../../graphql';

export const SUnderwriterFilter = styled.div`
  min-width: 220px;
`;

type CmgEntityKey = ManagerProfile['cmgEntityKey'];

type Underwriter = { firmName: string; cmgEntityKey: CmgEntityKey };

export type Props = {
  underwriters: Underwriter[];
  onChange: (value: CmgEntityKey[]) => void;
  values: CmgEntityKey[];
};

export const UnderwriterFilter: React.FC<Props> = ({ underwriters, onChange, values }) => {
  const options: Option<CmgEntityKey>[] = underwriters.map<Option<CmgEntityKey>>(underwriter => ({
    label: underwriter.firmName,
    value: underwriter.cmgEntityKey,
  }));

  return (
    <SUnderwriterFilter>
      <MultiSelect<CmgEntityKey>
        options={options}
        onChange={onChange}
        value={values}
        placeholder="Underwriter..."
      />
    </SUnderwriterFilter>
  );
};
