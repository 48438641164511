import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { StyledDeleteLinkButton } from './DeleteDealTeamCellRenderer.styles';

export type Props = {
  onDelete: () => void;
  index: number;
};

const DeleteDealTeamCellRenderer: React.FC<Props> = ({ onDelete, index }) => {
  return (
    <StyledDeleteLinkButton
      testId={`${xcSelectors.listDeleteDealTeamButton.testId}.${index}`}
      inline
      iconLeft={{ name: 'trash-alt', size: '1x' }}
      onClick={onDelete}
    />
  );
};

export default DeleteDealTeamCellRenderer;
