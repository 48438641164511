import { Button, SnackbarManager, Tooltip } from '@cmg/design-system';
import { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useAppSettings } from '../../../../../../../../common/config';

const CopyLinkActionBtn = () => {
  const environment = useAppSettings().env.clusterEnvironment;
  const buttonText = 'Copy link';
  let textToCopy = 'Attestation link not available in this environment';

  if (environment === 'int') {
    textToCopy =
      'https://powerforms-d.docusign.net/c5a43341-1d0b-41d2-94e8-21d82a08fa09?env=demo&acct=8197215a-f547-48bf-b848-86f239bd59b8&accountId=8197215a-f547-48bf-b848-86f239bd59b8';
  }

  if (environment === 'prod') {
    textToCopy =
      'https://powerforms.docusign.net/75cc02ec-b3fc-4457-bf20-c9d566069384?env=na2&acct=2ca4cd7f-f96e-4e2c-acd5-eeedc1033504&accountId=2ca4cd7f-f96e-4e2c-acd5-eeedc1033504';
  }

  const onCopy = useCallback(() => {
    SnackbarManager.success('Link copied to clipboard.');
  }, []);

  return (
    <CopyToClipboard text={textToCopy} onCopy={onCopy}>
      <Tooltip title="Copy link to clipboard" placement="top">
        <Button sx={{ minWidth: '0px' }} size="small" variant="text" aria-label={buttonText}>
          {buttonText}
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyLinkActionBtn;
