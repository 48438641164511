import { useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';

export type Ability = {
  entitlement: string;
  permissions: string[];
};

export type Props = {
  offeringId: string;
  abilities: {
    canRead?: Ability;
    canUpdate?: Ability;
    canManage?: Ability;
  };
};

export type AccessCheckResult = {
  loading: boolean;
  canRead: boolean | undefined;
  canUpdate: boolean | undefined;
  canManage: boolean | undefined;
  isSellSideAccount: boolean;
};

const defaultEntitlements = [];

const checkEntitlements = (
  abilityEntitlement: string | undefined,
  entitlements: readonly string[] | undefined
) => {
  return abilityEntitlement && entitlements ? entitlements.includes(abilityEntitlement) : false;
};

/**
 * Checks accessibility for Offering entitlements
 */
export function useOfferingCheckAccess({ offeringId, abilities }: Props): AccessCheckResult {
  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? defaultEntitlements;
  const isSellSideAccount = useCheckAccountType('SELL_SIDE');

  const hasRead = useCheckPermissions(abilities.canRead?.permissions ?? []);
  const hasUpdate = useCheckPermissions(abilities.canUpdate?.permissions ?? []);
  const hasManage = useCheckPermissions(abilities.canManage?.permissions ?? []);

  const accessChecks = React.useMemo(
    () => ({
      canRead: abilities.canRead
        ? hasRead &&
          (!isSellSideAccount || checkEntitlements(abilities.canRead?.entitlement, entitlements))
        : undefined,
      canUpdate: abilities.canUpdate
        ? hasUpdate &&
          (!isSellSideAccount || checkEntitlements(abilities.canUpdate?.entitlement, entitlements))
        : undefined,
      canManage: abilities.canManage
        ? hasManage &&
          (!isSellSideAccount || checkEntitlements(abilities.canManage?.entitlement, entitlements))
        : undefined,
    }),
    [
      abilities.canRead,
      abilities.canUpdate,
      abilities.canManage,
      isSellSideAccount,
      hasRead,
      entitlements,
      hasUpdate,
      hasManage,
    ]
  );

  return { loading, ...accessChecks, isSellSideAccount };
}
