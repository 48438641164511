import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_TIMEOUT } from '../../../../../constants';
import { useRoadshows_ValidateRoadshowNameLazyQuery as validateRoadshowNameLazyQuery } from '../graphql';

export const useRoadshowUniqueNameValidation = () => {
  const [validateRoadshowName] = validateRoadshowNameLazyQuery();

  const validate = async (roadshowName: string) => {
    const { data } = await validateRoadshowName({ variables: { roadshowName } });
    return !(data?.validateRoadshowName.errors && data.validateRoadshowName.errors.length > 0);
  };

  return useDebouncedCallback(validate, DEBOUNCE_TIMEOUT);
};
