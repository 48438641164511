import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../../../components/form/OfferingSetupForm';

export const StyledRow = styled(SetupForm.Row)`
  justify-content: space-between;
`;

export const StyledColumn = styled(SetupForm.Column)`
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-top: 10px;
`;
