import { Icon, Popover, SubNavigation } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import routeFactory from '../../../common/util/routeFactory';
import CreateNewOfferingButton from './CreateNewOfferingButton';

export const StyledSubNavigation = styled(SubNavigation)`
  border-bottom: ${({ theme }) => theme.border.smallSolidLight};
  display: flex;
  justify-content: space-between;
`;

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  padding-bottom: 15px;
`;

export const StyledInfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SPopoverContent = styled.div`
  max-width: 440px;
  overflow: wrap;
`;

export const SInfoWrapper = styled.span`
  margin-left: 4px;
`;

type MyOfferingsSubNavigationProps = {
  hideDraftLinks?: boolean;
};

/**
 * Sub-navigation/breadcrumbs for the my offerings module
 */
const MyOfferingsSubNavigation: React.FC<MyOfferingsSubNavigationProps> = ({ hideDraftLinks }) => (
  <StyledSubNavigation testId={xcSelectors.myOfferingsNavbar.testId}>
    <SubNavigation.Group>
      <SubNavigation.Item>
        <StyledSubNavigationLink
          to={routeFactory.myOfferingsOfferings.getUrlPath()}
          data-test-id={xcSelectors.myOfferingsNavbarOfferings.testId}
        >
          Offerings
        </StyledSubNavigationLink>
        <Popover
          content={
            <SPopoverContent>
              Includes offerings published by your firm, or managed on the CMG XC platform and
              visible to your firm as an underwriter.
            </SPopoverContent>
          }
          variant="DARK"
          placement="bottomLeft"
          autoAdjustOverflow
          arrowPointAtCenter
        >
          <SInfoWrapper>
            <StyledInfoIcon name="info-circle" variant="solid" />
          </SInfoWrapper>
        </Popover>
      </SubNavigation.Item>
      {!hideDraftLinks && (
        <React.Fragment>
          <SubNavigation.Item>
            <StyledSubNavigationLink
              to={routeFactory.myOfferingsDrafts.getUrlPath()}
              data-test-id={xcSelectors.myOfferingsNavbarDrafts.testId}
            >
              Drafts
            </StyledSubNavigationLink>
          </SubNavigation.Item>
          <SubNavigation.Item>
            <StyledSubNavigationLink
              to={routeFactory.myOfferingsSharedDrafts.getUrlPath()}
              data-test-id={xcSelectors.myOfferingsNavbarSharedDrafts.testId}
            >
              Shared Drafts
            </StyledSubNavigationLink>
          </SubNavigation.Item>
        </React.Fragment>
      )}
    </SubNavigation.Group>
    <CreateNewOfferingButton />
  </StyledSubNavigation>
);

export default MyOfferingsSubNavigation;
