import { timeUtil } from '@cmg/common';
import React from 'react';

export type Props = {
  booksCloseAt: string | null | undefined;
  timeZone: string | null | undefined;
};

const BooksClose: React.FC<Props> = ({ booksCloseAt, timeZone }) => {
  return (
    <div>
      {booksCloseAt && timeZone ? (
        <React.Fragment>
          {timeUtil.formatAsDisplayDateTime(booksCloseAt, timeZone)}{' '}
          {timeUtil.formatAsTimeZoneAbbreviation(booksCloseAt, timeZone)}
        </React.Fragment>
      ) : (
        '-'
      )}
    </div>
  );
};

export default BooksClose;
