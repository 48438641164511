import { numericUtil } from '@cmg/common';
import { FormikErrors } from 'formik';
import * as yup from 'yup';

import {
  CreateExpensesRevisionInput,
  CreateManagerExpensesInput,
  ExpenseCategory,
  ExpensesManagerRole,
} from '../../../../graphql';
import { MAX_32_BIT_INT } from '../../../../types/graphql/constants';
import { FinalSettlement_ExpensesPartsFragment } from '../graphql';

export type DealRelatedExpense = {
  category: ExpenseCategory | null;
  description: string;
  value: number | null;
};

export type ManagerExpenses = Required<CreateManagerExpensesInput> &
  Pick<
    FinalSettlement_ExpensesPartsFragment['managerExpenses'][number],
    'managerName' | 'managerRole'
  >;

export type ManagerExpensesTotals = Pick<
  ManagerExpenses,
  'miscellaneous' | 'reimbursement' | 'technologyAndData' | 'travelAndMeals'
>;

export type ExpensesValues = {
  notes: string;
  managerExpenses: ManagerExpenses[];
  dealRelatedExpenses: DealRelatedExpense[];
};

type ManagerValidationFields = Pick<
  ManagerExpenses,
  'miscellaneous' | 'technologyAndData' | 'travelAndMeals' | 'reimbursement'
>;

type DealRelatedValidationFields = Pick<DealRelatedExpense, 'category' | 'description' | 'value'>;

export const managerExpensesSchema: yup.ObjectSchema<Partial<ManagerValidationFields>> = yup
  .object()
  .shape({
    miscellaneous: yup
      .number()
      .min(0)
      .max(MAX_32_BIT_INT, 'Invalid Miscellaneous value')
      .nullable()
      .label('Miscellaneous'),
    technologyAndData: yup
      .number()
      .min(0)
      .max(MAX_32_BIT_INT, 'Invalid Technology / Data value')
      .nullable()
      .label('Technology / Data'),
    travelAndMeals: yup
      .number()
      .min(0)
      .max(MAX_32_BIT_INT, 'Invalid Travel / Meals value')
      .nullable()
      .label('Travel / Meals'),
    reimbursement: yup
      .number()
      .min(0)
      .max(MAX_32_BIT_INT, 'Invalid Reimbursement value')
      .nullable()
      .label('Reimbursement')
      .test(
        'reimbursement-test',
        // eslint-disable-next-line no-template-curly-in-string
        '${path} must be less than or equal to Total Submitted',
        (value, context) => {
          if (value) {
            return (
              value <=
              context.parent.miscellaneous +
                context.parent.technologyAndData +
                context.parent.travelAndMeals
            );
          } else {
            return true;
          }
        }
      ),
  });

export const dealRelatedExpenseSchema: yup.ObjectSchema<Partial<DealRelatedValidationFields>> = yup
  .object()
  .shape({
    category: yup.mixed<ExpenseCategory>().nullable().required().label('Category'),
    description: yup.string().label('Description').max(100),
    value: yup
      .number()
      .min(-MAX_32_BIT_INT, 'Invalid Total value')
      .max(MAX_32_BIT_INT, 'Invalid Total value')
      .nullable()
      .required()
      .label('Total'),
  });

export const expensesSchema = yup.object().shape({
  notes: yup.string().max(1000).label('Notes'),
  managerExpenses: yup.array().of(managerExpensesSchema),
  dealRelatedExpenses: yup.array().of(dealRelatedExpenseSchema),
});

function hasRequiredRole(role: ExpensesManagerRole) {
  return [
    ExpensesManagerRole.ActiveBookrunner,
    ExpensesManagerRole.Bookrunner,
    ExpensesManagerRole.CoManager,
    ExpensesManagerRole.CoLead,
  ].includes(role);
}

export const newDealRelatedExpense: DealRelatedExpense = {
  category: null,
  description: '',
  value: null,
};

export function getInitialValues(expenses: FinalSettlement_ExpensesPartsFragment): ExpensesValues {
  return {
    notes: expenses.notes ?? '',
    managerExpenses: expenses.managers.reduce<ExpensesValues['managerExpenses']>(
      (result, currentManager) => {
        if (hasRequiredRole(currentManager.role)) {
          const managerExpenses = expenses.managerExpenses.find(
            ({ cmgEntityKey }) => cmgEntityKey === currentManager.cmgEntityKey
          );
          result.push({
            cmgEntityKey: currentManager.cmgEntityKey,
            miscellaneous: managerExpenses?.miscellaneous ?? null,
            reimbursement: managerExpenses?.reimbursement ?? null,
            technologyAndData: managerExpenses?.technologyAndData ?? null,
            travelAndMeals: managerExpenses?.travelAndMeals ?? null,
            managerName: currentManager.name,
            managerRole: currentManager.role,
          });
        }
        return result;
      },
      []
    ),
    dealRelatedExpenses: expenses.dealRelatedExpenses.map(item => ({
      category: item.category,
      description: item.description,
      value: item.value,
    })),
  };
}

export function formValuesToPayload(values: ExpensesValues): CreateExpensesRevisionInput {
  return {
    notes: values.notes,
    managerExpenses: values.managerExpenses.map(item => ({
      cmgEntityKey: item.cmgEntityKey,
      miscellaneous: item.miscellaneous,
      reimbursement: item.reimbursement,
      technologyAndData: item.technologyAndData,
      travelAndMeals: item.travelAndMeals,
    })),
    dealRelatedExpenses: values.dealRelatedExpenses.map(item => ({
      category: item.category!,
      description: item.description,
      value: item.value!,
    })),
  };
}

export const managerExpensesFieldDictionary: Record<keyof ManagerValidationFields, string> = {
  miscellaneous: 'Expense Miscellaneous',
  reimbursement: 'Expense Reimbursement',
  technologyAndData: 'Expense Technology / Data',
  travelAndMeals: 'Expense Travel / Meals',
};

export const dealRelatedExpenseFieldDictionary: Record<keyof DealRelatedValidationFields, string> =
  {
    category: 'Expense Category',
    description: 'Expense Description',
    value: 'Expense Total',
  };

export function getErrors(
  managerExpenses: FormikErrors<ExpensesValues>['managerExpenses'],
  dealRelatedExpenses: FormikErrors<ExpensesValues>['dealRelatedExpenses']
) {
  const result = new Set<string>();
  let hasManagerExpensesError = false;
  let hasDealRelatedExpensesError = false;
  if (managerExpenses) {
    for (const managerErrors of managerExpenses) {
      if (managerErrors) {
        Object.keys(managerErrors).forEach(item => {
          result.add(managerExpensesFieldDictionary[item] ?? item);
        });
        hasManagerExpensesError = true;
      }
    }
  }
  if (dealRelatedExpenses) {
    for (const dealRelatedErrors of dealRelatedExpenses) {
      if (dealRelatedErrors) {
        Object.keys(dealRelatedErrors).forEach(item => {
          result.add(dealRelatedExpenseFieldDictionary[item] ?? item);
        });
        hasDealRelatedExpensesError = true;
      }
    }
  }
  return {
    hasManagerExpensesError,
    hasDealRelatedExpensesError,
    errors: Array.from(result).sort((a, b) => a.localeCompare(b)),
  };
}

export function getManagerExpensesTotals(
  managerExpenses: ManagerExpenses[]
): ManagerExpensesTotals {
  return managerExpenses.reduce<ManagerExpensesTotals>(
    (prev, current) => ({
      travelAndMeals: numericUtil.sum(prev.travelAndMeals, current.travelAndMeals),
      technologyAndData: numericUtil.sum(prev.technologyAndData, current.technologyAndData),
      reimbursement: numericUtil.sum(prev.reimbursement, current.reimbursement),
      miscellaneous: numericUtil.sum(prev.miscellaneous, current.miscellaneous),
    }),
    {
      travelAndMeals: null,
      technologyAndData: null,
      reimbursement: null,
      miscellaneous: null,
    }
  );
}

export function getDealRelatedExpensesTotals(
  dealRelatedExpenses: DealRelatedExpense[]
): number | null {
  return dealRelatedExpenses.reduce<number | null>(
    (prev, current) => numericUtil.sum(prev, current.value),
    null
  );
}

export function mapExpensesFilter(categoryFilter: string, descriptionFilter: string) {
  return (item: DealRelatedExpense) => {
    const categoryPredicate = categoryFilter === '' ? true : categoryFilter === item.category;
    const descriptionPredicate =
      descriptionFilter === ''
        ? true
        : item.description?.toLowerCase().includes(descriptionFilter.toLowerCase());
    const shouldDisplay = categoryPredicate && descriptionPredicate;
    return {
      ...item,
      ...{
        shouldDisplay,
      },
    };
  };
}
