import { useStoredPreference } from '@cmg/auth';

import { useFeatureToggles } from '../../../common/config';

export const useDemandGridV2FeaturePreference = () => {
  const { isOrderBookInstitutionalDemandGridV2ButtonOn } = useFeatureToggles();

  const [isPreferenceEnabled, setIsPreferenceEnabled] = useStoredPreference<boolean>({
    key: 'orderBookDemandGridV2Enabled',
    initialValue: false,
  });

  return {
    isToggleOn: isOrderBookInstitutionalDemandGridV2ButtonOn,
    isPreferenceOn: isOrderBookInstitutionalDemandGridV2ButtonOn ? isPreferenceEnabled : false,
    setPreference: setIsPreferenceEnabled,
  };
};
