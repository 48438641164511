import { Alert, AlertTitle, Link, Skeleton } from '@cmg/design-system';
import React from 'react';

import { OfferingSidePanelRouteContext } from '../../../../../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offeringSidePanelSellSideRouteFactory';

export type Props = Readonly<{
  duplicateCmgEntityKey: string | null | undefined;
  bankInvestorName: string | null | undefined;
  cmgEntityName: string | null | undefined;
  isLoading: boolean;
}>;

const DuplicateIndicationBanner: React.FC<Props> = ({
  duplicateCmgEntityKey,
  cmgEntityName,
  bankInvestorName,
  isLoading,
}) => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);
  const linkToIndication = `${baseSidePanelUrl}${offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath(
    { cmgEntityKey: duplicateCmgEntityKey! }
  )}`;

  return (
    <Alert severity="info">
      <AlertTitle>This indication is a duplicate</AlertTitle>
      {isLoading && <Skeleton role="progressbar" />}
      {!isLoading && (
        <div>
          You can find surviving indication here{' '}
          <Link href={linkToIndication} component="a">
            {bankInvestorName ?? cmgEntityName}
          </Link>
        </div>
      )}
    </Alert>
  );
};

export default DuplicateIndicationBanner;
