import { Option } from '@cmg/common';

import { OfferingStatus } from '../../../../../../../graphql';
import { Roadshows_SearchOfferingsQuery } from './graphql';

export type RoadshowOfferingOption = Option & {
  status: OfferingStatus;
  ticker: string | null | undefined;
};

export function createRoadshowOfferingOptions(
  data: Roadshows_SearchOfferingsQuery['offerings']['data']
) {
  return data.map<RoadshowOfferingOption>(
    ({ id, issuerName, status, pricingInstrumentStockSymbol }) => ({
      value: id,
      label: issuerName ?? id,
      status,
      ticker: pricingInstrumentStockSymbol,
    })
  );
}
