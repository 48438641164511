import { Option } from '@cmg/common';

import { SecurityShareClass, SecurityType } from '../../../graphql';

export const securityTypeLabels = {
  [SecurityType.CommonOrdinaryShare]: 'Common/Ordinary Shares',
  [SecurityType.ConvertibleBond]: 'Convertible Bonds',
  [SecurityType.LimitedPartnershipUnit]: 'Limited Partnership Units',
  [SecurityType.PreferredShare]: 'Preferred Shares',
  [SecurityType.UnitShareAndWarrants]: 'Unit Share and Warrants',
};

export const securityTypeOptions = Object.values(SecurityType).map(
  type =>
    ({
      label: securityTypeLabels[type],
      value: type,
    } as Option<SecurityType>)
);

export const securityShareClassOptions = Object.values(SecurityShareClass).map(
  value =>
    ({
      label: value,
      value,
    } as Option<SecurityShareClass>)
);
