import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import { Stack } from '@cmg/design-system';
import React from 'react';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { getFeatureToggles } from '../../../../../../common/config';
import {
  AllocationAcknowledgement,
  InstitutionalIndicationOrderType,
  MdlAllocationRanking,
} from '../../../../../../graphql';
import { useIndicationActivityBuySideContext } from '../../context/IndicationActivityBuySideContext';
import Allocation from '../allocation/Allocation';
import IndicationSectionCreateView from './buy-side/indication-section-create-view/IndicationSectionCreateView';
import IndicationSectionEditView from './buy-side/indication-section-edit-view/IndicationSectionEditView';
import IndicationSectionReadView from './buy-side/indication-section-read-view/IndicationSectionReadView';
import ComplianceErrorBanner from './common/compliance-requited-banner/ComplianceErrorBanner';
import { useManageIndication_MdlAllocationRankingLazyQuery } from './graphql/__generated__';
import { useSetAllocationRankingOnOfferingMutation } from './hooks/useSetAllocationRankingOnOfferingMutation';

export type Props = Readonly<{
  editable: boolean;
  isOrderBookOpen: boolean;
}>;

const refetchTimeout = 2000;
const ManageBuySideIndication: React.FC<Props> = ({ editable, isOrderBookOpen }) => {
  const {
    offeringId,
    basicPublishedOffering,
    attestation,
    complianceWarning: { hasComplianceWarning },
  } = useIndicationActivityBuySideContext();

  const offering = basicPublishedOffering!;
  const indication = offering.myInstitutionalIndication;

  const { isOrderBookBuySideInvestorReplyOn } = getFeatureToggles();
  const hasIoiRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [isEditingIndication, setIsEditingIndication] = React.useState(false);

  const finalAllocation = indication?.finalAllocation;
  const isFinalAllocationAcknowledged =
    finalAllocation?.investorReply?.status === AllocationAcknowledgement.Acknowledged;

  const [
    getAllocationRanking,
    {
      data: allocationRankingData,
      loading: getAllocationRankingLoading,
      error: getAllocationRankingError,
    },
  ] = useManageIndication_MdlAllocationRankingLazyQuery({
    onCompleted: ({ mdlAllocationRanking }) => {
      const allocationRankingEmpty = !mdlAllocationRanking?.ranking;

      // Since DL is still processing the creation of the allocation we need to keep
      // pulling until the allocation is created on DL
      if (hasIoiRead && allocationRankingEmpty && isFinalAllocationAcknowledged) {
        setTimeout(() => {
          getAllocationRanking({ variables: { offeringId } });
        }, refetchTimeout);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    setAllocationRankingOnOffering,
    { loading: setAllocationRankingOnOfferingLoading, error: setAllocationRankingOnOfferingError },
  ] = useSetAllocationRankingOnOfferingMutation({
    onCompleted: () => {
      ToastManager.success('Successfully updated allocation ranking');
    },
  });

  const onChangeAllocationRanking = (ranking: MdlAllocationRanking) => {
    setAllocationRankingOnOffering({
      variables: { id: allocationRankingData?.mdlAllocationRanking?.id!, ranking },
    });
  };

  React.useEffect(() => {
    if (isFinalAllocationAcknowledged && hasIoiRead) {
      getAllocationRanking({ variables: { offeringId } });
    }
  }, [
    finalAllocation,
    offeringId,
    getAllocationRanking,
    hasIoiRead,
    isFinalAllocationAcknowledged,
  ]);

  const loading = setAllocationRankingOnOfferingLoading || getAllocationRankingLoading;

  const isCreatingIndication = !indication && !hasComplianceWarning;

  if (loading) {
    return <Loading />;
  }

  return (
    <div aria-label="Manage Indication">
      <Stack gap={1} pb={1}>
        {setAllocationRankingOnOfferingError && (
          <ServerErrorsBanner error={setAllocationRankingOnOfferingError} />
        )}
        {getAllocationRankingError && <ServerErrorsBanner error={getAllocationRankingError} />}
        <ComplianceErrorBanner
          isMissingContacts={false}
          attestationStatus={hasComplianceWarning ? attestation?.formStatus : undefined}
        />
      </Stack>
      {isCreatingIndication && isOrderBookOpen && <IndicationSectionCreateView />}
      {indication && isEditingIndication && isOrderBookOpen && (
        <IndicationSectionEditView
          onFinishEditing={() => setIsEditingIndication(false)}
          hasAllocationRanking={!!allocationRankingData?.mdlAllocationRanking?.ranking}
        />
      )}
      {indication && !isEditingIndication && (
        <IndicationSectionReadView
          onStartEditing={() => setIsEditingIndication(true)}
          hasAllocationRanking={!!allocationRankingData?.mdlAllocationRanking?.ranking}
        />
      )}

      {indication && indication.type !== InstitutionalIndicationOrderType.Pass && (
        <Allocation
          allocation={finalAllocation}
          indication={{
            ...indication,
            offeringId,
          }}
          offering={basicPublishedOffering!}
          isCoveredAccount={false}
          isOrderBookOpen={isOrderBookOpen}
          onChangeAllocationRanking={onChangeAllocationRanking}
          allocationRanking={allocationRankingData?.mdlAllocationRanking?.ranking}
          getAllocationRanking={getAllocationRanking}
          billingAndDeliveryAgentCmgEntityKey={indication.billingAndDeliveryAgentCmgEntityKey}
          isSellSideAccount={false}
          canUpdateAllocation={isOrderBookBuySideInvestorReplyOn}
          canBillingAndDeliveryAgentUpdate
        />
      )}
    </div>
  );
};

export default ManageBuySideIndication;
