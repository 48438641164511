import React from 'react';

import {
  SContent,
  SFooter,
  STitle,
  StyledCancelButton,
  StyledConfirmButton,
  SWrapper,
} from './ConfirmationPanel.styles';

export const ConfirmationPanel: React.FC<{
  title: string;
  onCancel?: () => void;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  confirmButtonLabel?: string;
}> = ({
  title,
  children,
  onConfirm,
  confirmButtonLabel = 'Confirm',
  onCancel,
  cancelButtonLabel = 'Cancel',
}) => {
  return (
    <SWrapper>
      <STitle>{title}</STitle>
      <SContent>{children}</SContent>
      <SFooter>
        <StyledCancelButton onClick={onCancel}>{cancelButtonLabel}</StyledCancelButton>
        <StyledConfirmButton onClick={onConfirm}>{confirmButtonLabel}</StyledConfirmButton>
      </SFooter>
    </SWrapper>
  );
};
