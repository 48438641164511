import { ButtonsContainer } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../components/form/OfferingSetupForm';
import Modal from './../../../common/components/overlays/modal/Modal';

export const StyledTypeColumn = styled(SetupForm.Column)`
  flex-basis: 90%;
`;

export const StyledButtonsContainer = styled(ButtonsContainer)`
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
  padding-top: 10px;
  padding-right: 15px;
  width: 100%;
`;

export const StyledModalContent = styled(Modal.Content)`
  padding-top: 8px;
`;

export const SOfferingSettlementAgentAuthoringBannerContainer = styled.div`
  margin-right: 15px;
`;
