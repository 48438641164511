/**
 * Types
 */

export type Props<UnitType, PricingType, LimitType> = {
  isEditing?: boolean;
  useFirstItemUnitType?: boolean;
  children: (
    props: InterestLevelChildrenProps<UnitType, PricingType, LimitType>
  ) => React.ReactElement<InterestLevelProps<UnitType, PricingType, LimitType>>[];
};

export type InterestLevelFormValues<UnitType, PricingType, LimitType> = {
  unitType: UnitType;
  // quantity used in place of dollars/percentage/shares until unitType is selected:
  quantity?: number | null | undefined;
  dollars?: number | null | undefined;
  percentage?: number | null | undefined;
  shares?: number | null;
  pricingType: PricingType;
  limitPrice?: number | null | undefined;
  realDemandDollars?: number | null | undefined;
  realDemandPercentage?: number | null | undefined;
  realDemandShares?: number | null | undefined;
  limitType?: LimitType | null | undefined;
  limitPercentage?: number | null | undefined;
};

export type InterestLevelProps<UnitType, PricingType, LimitType> = {
  isEditing?: boolean;
  isSubmitting?: boolean;
  index: number;
  replace: (
    index: number,
    value: InterestLevelFormValues<UnitType, PricingType, LimitType>
  ) => void;
  remove: (index: number) => void;
};

export type FormValues<UnitType, PricingType, LimitType> = {
  interestLevels: InterestLevelFormValues<UnitType, PricingType, LimitType>[];
};

export type InterestLevelChildrenProps<UnitType, PricingType, LimitType> = Omit<
  InterestLevelProps<UnitType, PricingType, LimitType>,
  'index'
> & { interestLevels: InterestLevelFormValues<UnitType, PricingType, LimitType>[] };

/**
 * Initial values
 */

export const emptyInterestLevel = {
  unitType: null,
  dollars: null,
  percentage: null,
  shares: null,
  pricingType: null,
  limitPrice: null,
  realDemandDollars: null,
  realDemandPercentage: null,
  realDemandShares: null,
  limitType: null,
  limitPercentage: null,
};
