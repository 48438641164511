import { FlexContainer, Icon, numericUtil, SubNavigation, UUID } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import routeFactory from '../../../../../common/util/routeFactory';
import InfoIconPopover from '../../../common/components/info-icon-popover/InfoIconPopover';
import { StyledSubNavigationLink } from '../../../FinalSettlementRoute.styles';
import { FinalSettlement_StabilizationInformationPartsFragment } from '../../graphql';
import { Values } from '../StabilizationForm.model';
import OverallotmentRefresh from './overallotment-refresh/OverallotmentRefresh';
import { getTotalSellGain } from './overallotment-refresh/OverallotmentRefresh.model';
import StabilizationCalculationsDetails from './stabilization-calculations-details/StabilizationCalculationsDetails';
import StabilizationTrades from './stabilization-trades/StabilizationTrades';
import { getTotalBuyGain } from './stabilization-trades/StabilizationTrades.model';
import { getTotalGainLoss } from './StabilizationFormContent.model';
import {
  SCalculationWrapper,
  SContentWrapper,
  SIconWrapper,
  SNavigationWrapper,
  StyledSubNavigation,
} from './StabilizationFormContent.styles';
import TotalGainLossInfo from './total-gain-loss-info/TotalGainLossInfo';
import { SBoldText } from './total-gain-loss-info/TotalGainLossInfo.styles';

export type Props = {
  isEditable: boolean;
  offeringId: UUID;
  offeringInformation: FinalSettlement_StabilizationInformationPartsFragment;
};

const StabilizationFormContent: React.FC<Props> = ({
  isEditable,
  offeringId,
  offeringInformation,
}) => {
  const theme = useTheme();
  const { errors, values } = useFormikContext<Values>();

  const stabilizationTrades = getTotalBuyGain(values.buy, offeringInformation.offeringPrice);
  const overallotmentRefresh = getTotalSellGain(values.sell, offeringInformation.offeringPrice);

  const buyTradesLength = values.buy.length;
  const hasBuyErrors = !!errors.buy;
  const sellTradesLength = values.sell.length;
  const hasSellErrors = !!errors.sell;

  return (
    <FlexContainer direction="row" gap={60}>
      <SContentWrapper>
        <SNavigationWrapper>
          <StyledSubNavigation>
            <SubNavigation.Group>
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementStabilizationBuy.getUrlPath({ offeringId })}
                >
                  Stabilization Trades ({buyTradesLength})
                  {hasBuyErrors && (
                    <SIconWrapper>
                      <Icon
                        name="exclamation-triangle"
                        variant="solid"
                        color={theme.foundation.colors.error[300]}
                      />
                    </SIconWrapper>
                  )}
                </StyledSubNavigationLink>
              </SubNavigation.Item>
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementStabilizationSell.getUrlPath({ offeringId })}
                >
                  Overallotment Refresh ({sellTradesLength})
                  {hasSellErrors && (
                    <SIconWrapper>
                      <Icon
                        name="exclamation-triangle"
                        variant="solid"
                        color={theme.foundation.colors.error[300]}
                      />
                    </SIconWrapper>
                  )}
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            </SubNavigation.Group>
          </StyledSubNavigation>
          <SBoldText>
            Total Gain/(Loss):{' '}
            {numericUtil.getDisplayValueForCurrency(
              getTotalGainLoss(stabilizationTrades, overallotmentRefresh),
              6
            )}
          </SBoldText>
          <InfoIconPopover>
            <TotalGainLossInfo
              stabilizationTrades={stabilizationTrades}
              overallotmentRefresh={overallotmentRefresh}
            />
          </InfoIconPopover>
        </SNavigationWrapper>

        <Switch>
          <Route
            path={routeFactory.finalSettlementStabilizationBuy.routePath}
            render={() => (
              <StabilizationTrades
                isEditable={isEditable}
                offeringPrice={offeringInformation.offeringPrice}
              />
            )}
            exact
          />
          <Route
            path={routeFactory.finalSettlementStabilizationSell.routePath}
            render={() => (
              <OverallotmentRefresh
                isEditable={isEditable}
                offeringPrice={offeringInformation.offeringPrice}
              />
            )}
            exact
          />
          <Route
            path={routeFactory.finalSettlementStabilization.routePath}
            render={() => (
              <Redirect
                to={routeFactory.finalSettlementStabilizationBuy.getUrlPath({ offeringId })}
              />
            )}
          />
        </Switch>
      </SContentWrapper>

      <SCalculationWrapper>
        <StabilizationCalculationsDetails offeringInformation={offeringInformation} />
      </SCalculationWrapper>
    </FlexContainer>
  );
};

export default StabilizationFormContent;
