import { IconButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SHeader = styled.div`
  padding: 32px 32px 8px;
  display: flex;
`;

export const STitle = styled.div`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.text.size.large};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
`;

export type Props = {
  onHide?: () => void;
};
const ModalHeader: React.FC<Props> = ({ children, onHide }) => {
  return (
    <SHeader>
      <STitle>{children}</STitle>
      {onHide && <StyledIconButton icon={{ name: 'times' }} size="large" onClick={onHide} />}
    </SHeader>
  );
};

export default ModalHeader;
