import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { expensesCategoryLabels } from '../../../../types/domain/final-settlement/constants';
import { FinalSettlement_SettlementLetterPartsFragment } from '../graphql';
import {
  StyledTable,
  StyledTableHeader,
  StyledUnderlinedCell,
} from './UnderwritingGroupExpensesTable.styles';

type Props = {
  data: FinalSettlement_SettlementLetterPartsFragment;
};

const UnderwritingGroupExpensesTable: React.FC<Props> = ({
  data: {
    dealRelatedExpenses,
    totalManagerExpensesReimbursements,
    totalLossOnOversale,
    totalUnderwritingGroupExpense,
  },
}) => {
  return (
    <StyledTable gridTemplateColumns="75% 25%">
      <Table.Row>
        <StyledTableHeader withRightBorder={false} bold>
          Underwriting Group Expenses
        </StyledTableHeader>
        <StyledTableHeader withRightBorder={false} />
      </Table.Row>

      {dealRelatedExpenses.map((dealRelatedExpense, index) => (
        <Table.Row key={index} isFocused={false} suppressHighlightingOnHover>
          <Table.Cell paddingSize="S">
            {expensesCategoryLabels[dealRelatedExpense.category]}
          </Table.Cell>
          <Table.Cell align="right" paddingSize="S">
            {numericUtil.formatCurrency(dealRelatedExpense.value, 2)}
          </Table.Cell>
        </Table.Row>
      ))}

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Manager Expense Reimbursements</Table.Cell>
        <Table.Cell align="right" paddingSize="S">
          {numericUtil.getDisplayValueForCurrency(totalManagerExpensesReimbursements, 2)}
        </Table.Cell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <StyledUnderlinedCell paddingSize="S">Total (Gain)/Loss on Oversale</StyledUnderlinedCell>
        <StyledUnderlinedCell align="right" paddingSize="S">
          {numericUtil.getDisplayValueForCurrency(numericUtil.negate(totalLossOnOversale), 2)}
        </StyledUnderlinedCell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S" withBottomBorder={false} bold>
          Total
        </Table.Cell>
        <Table.Cell align="right" paddingSize="S" withBottomBorder={false} bold>
          {numericUtil.getDisplayValueForCurrency(totalUnderwritingGroupExpense, 2)}
        </Table.Cell>
      </Table.Row>
    </StyledTable>
  );
};

export default UnderwritingGroupExpensesTable;
