import { PinnedColumnsType } from '../../../../../preferences/useInstitutionalDemandPreferences';

export const generatePinnedColumns = ({
  pinnedColumns,
  colId,
  isPinnedRight = false,
  isPinnedLeft = false,
}: {
  pinnedColumns: PinnedColumnsType;
  colId: string;
  isPinnedRight?: boolean;
  isPinnedLeft?: boolean;
}): PinnedColumnsType => {
  const pinnedRightColumns = pinnedColumns.right.filter(c => c !== colId);
  const pinnedLeftColumns = pinnedColumns.left.filter(c => c !== colId);
  return {
    right: isPinnedRight ? [...pinnedRightColumns, colId] : pinnedRightColumns,
    left: isPinnedLeft ? [...pinnedLeftColumns, colId] : pinnedLeftColumns,
  };
};
