import { CurrencyInputField, NumericInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledNumericInputField = styled(NumericInputField)`
  min-width: 80px;
  width: 80px;
  margin-bottom: 10px;

  &:not(:first-child):not(:last-child) {
    margin: 0 5px;
  }
`;

export const SCMGDemandColumnTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin: 0px 0 7px;
  padding: 0px 0px 5px 0px;
`;

export const StyledReferencePriceInput = styled(CurrencyInputField)`
  width: 100%;
  margin-bottom: 10px;
`;

export const SDemandSeparatorTitle = styled.div`
  margin: 15px 0 10px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  padding-top: 17px;
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SOptionContainer = styled.div<{ fractions: number }>`
  display: grid;
  grid-template-columns: repeat(${p => p.fractions}, 1fr);
  grid-column-gap: 5px;
  padding: 0px 0px 6px;
`;

export const MenuHeaderOption = styled.div`
  height: 30px;
  color: ${({ theme }) => theme.text.color.darkGray};
  line-height: 28px;
`;

export const MenuSeparatorOption = styled.div`
  height: 37px;
  color: ${({ theme }) => theme.text.color.darkGray};
  line-height: 35px;
`;

export const ModalBodyContainer = styled.div`
  margin: 0px 10px 0px 10px;
`;

export const TitleContainer = styled.div`
  margin: 0px 10px 0px 10px;
`;

export const SHeaderInfo = styled.div`
  margin: 0px 0 15px 0;
`;
