import {
  SyndicateWires_UpdateGreenShoeWireMutation,
  useSyndicateWires_UpdateGreenShoeWireMutation,
} from '../graphql';

export type UpdateGreenShoeWireMutation = SyndicateWires_UpdateGreenShoeWireMutation;

export const useUpdateGreenShoeWireMutation = () => {
  return useSyndicateWires_UpdateGreenShoeWireMutation();
};
