import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

export const getFormattedValue = (value: string | number | null) => {
  if (typeof value === 'number') {
    return numericUtil.formatPercents(value, 4);
  }

  return value;
};

export const hasInvalidValue = ({
  value,
  formattedValue,
  acceptZero,
  targetValue,
}: {
  value: string | number | null;
  formattedValue: string | number | null;
  acceptZero: boolean;
  targetValue: string | number | null | undefined;
}): boolean => {
  return !!(
    (
      targetValue && // we should consider anything valid if targetValue is not set
      formattedValue !== targetValue &&
      !isNil(value) && // if value if null, we should consider everything valid
      (!acceptZero || value !== 0)
    ) // accept zero defines if we should make comparisons when value is zero
  );
};
