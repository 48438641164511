import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
} from '../../../../../../../../graphql';
import { OrderBookPersonaType } from '../../../../../hooks/useIdentifyOrderBookPersona';

export type LabelType = 'DUPLICATE' | 'CANCELLED' | 'PASS';

export type IndicationStateMessagePart = {
  type: LabelType;
  prefix: string;
  state: string;
  suffix: string;
};

type LabelArgs = {
  personaType: OrderBookPersonaType;
  indicationStatus: IndicationStatus | null;
  orderType: InstitutionalIndicationOrderType | null;
  isOrderBookOpen: boolean;
};

const labelMap: { [key in LabelType]: [string, string, string] } = {
  [InstitutionalIndicationOrderType.Pass]: ['This indication has been marked as ', 'pass', '.'],
  [IndicationStatus.Duplicate]: [
    'This indication is a ',
    'duplicate',
    ' and will not receive an allocation.',
  ],
  [IndicationStatus.Cancelled]: ['This indication has been ', 'cancelled', '.'],
};

/**
 * Returns the indication state message for the indication status and type.
 */
export const getIndicationStateMessagePart = (
  indicationStatus: IndicationStatus | null,
  orderType: InstitutionalIndicationOrderType | null
): IndicationStateMessagePart | null => {
  const statusLabel = indicationStatus ? labelMap[indicationStatus] : null;
  const typeLabel = orderType ? labelMap[orderType] : null;

  if (!statusLabel && !typeLabel) {
    return null;
  }

  const [prefix, state, suffix] = statusLabel ?? typeLabel;
  const type = statusLabel ? indicationStatus : orderType;

  return {
    type: type as LabelType,
    prefix,
    state,
    suffix,
  };
};

export const getActionMessageForSyndicatePersona = (labelType: LabelType) => {
  if (labelType === 'DUPLICATE') {
    return [
      'If you feel this is a mistake please un-mark indication as duplicate.',
      'The allocation will not be shown to XC Sales & Trading or Buy-Side users.',
    ].join(' ');
  }

  return [
    'If the investor would like to receive an allocation for this offering please re-activate indication.',
    'The allocation will not be shown to XC Sales & Trading or Buy-Side users.',
  ].join(' ');
};

export const getActionMessageForSalesAndTradingPersona = (
  labelType: LabelType,
  isOrderBookOpen: boolean
) => {
  if (labelType === 'DUPLICATE') {
    return 'If you feel this is a mistake please reach out to Syndicate.';
  }

  return `If the investor would like to receive an allocation for this offering please ${
    isOrderBookOpen ? 're-activate indication' : 'reach out to Syndicate'
  }.`;
};

export const getActionMessageForBuySidePersona = () => {
  return 'If you would like to receive an allocation for this offering please reach out to your sell-side representative.';
};

/**
 * Returns the action message for given persona.
 */
export const getPersonaActionMessagePart = (
  personaType: OrderBookPersonaType,
  labelType: LabelType,
  isOrderBookOpen: boolean
) => {
  switch (personaType) {
    case OrderBookPersonaType.BUY_SIDE:
      return getActionMessageForBuySidePersona();
    case OrderBookPersonaType.SALES_AND_TRADING:
      return getActionMessageForSalesAndTradingPersona(labelType, isOrderBookOpen);
    case OrderBookPersonaType.SYNDICATE:
      return getActionMessageForSyndicatePersona(labelType);
    default:
      return null;
  }
};

/**
 * Returns the message for the indication status, type and persona.
 */
export const getMessage = ({
  personaType,
  orderType,
  indicationStatus,
  isOrderBookOpen,
}: LabelArgs) => {
  const indicationStateMessagePart = getIndicationStateMessagePart(indicationStatus, orderType);

  if (!indicationStateMessagePart) {
    return null;
  }

  const personaLabel = getPersonaActionMessagePart(
    personaType,
    indicationStateMessagePart.type,
    isOrderBookOpen
  );

  return personaLabel
    ? {
        ...indicationStateMessagePart,
        suffix: [indicationStateMessagePart.suffix, personaLabel].join(' '),
      }
    : indicationStateMessagePart;
};
