import { ButtonsContainer, SecondaryButton, SuccessButton } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../../common/util/routeFactory';
import RoadshowHeader from '../../../common/roadshow-header/RoadshowHeader';

export type Props = {
  onCreate: () => void;
  createDisabled: boolean;
  createLoading: boolean;
};

const CreateRoadshowHeader: React.FC<Props> = ({ onCreate, createDisabled, createLoading }) => {
  const { push } = useHistory();

  const handleCancel = () => {
    push(routeFactory.roadshowsList.getUrlPath());
  };

  return (
    <RoadshowHeader>
      <ButtonsContainer>
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        <SuccessButton
          testId="save button"
          onClick={onCreate}
          disabled={createDisabled}
          loading={createLoading}
        >
          Create Roadshow
        </SuccessButton>
      </ButtonsContainer>
    </RoadshowHeader>
  );
};

export default CreateRoadshowHeader;
