import * as yup from 'yup';

import { FilingType } from '../../../../graphql';
import { filingTypeOptions } from '../../../../types/domain/filing/constants';

export const availableFilingTypeOptions = (hasInitialFiling?: boolean) => {
  return filingTypeOptions.filter(({ value }) => {
    if (hasInitialFiling) {
      return value !== FilingType.Initial;
    } else {
      return value === FilingType.Initial;
    }
  });
};

export const FilingSchema = yup.object().shape({
  filingType: yup.string().label('Type').nullable().required('Type is required.'),
  initialRegistrationValue: yup.number().label('Initial Registration Value').min(0).nullable(),
  primarySharesBaseOffering: yup
    .number()
    .label('Primary Shares Base Offering')
    .min(0)
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema => schema.nullable().required('Primary Shares Base Offering is required.'),
    }),
  secondarySharesBaseOffering: yup
    .number()
    .label('Secondary Shares Base Offering')
    .min(0)
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema => schema.nullable().required('Secondary Shares Base Offering is required.'),
    }),
  notionalOverAllotmentAuthorized: yup
    .number()
    .label('Over-allotment Authorized')
    .min(0)
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema => schema.nullable(),
    }),
  primarySharesOverAllotmentAuthorized: yup
    .number()
    .label('Primary Shares Over-allotment Authorized')
    .min(0)
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema =>
        schema.nullable().required('Primary Shares Over-allotment Authorized is required.'),
    }),
  secondarySharesOverAllotmentAuthorized: yup
    .number()
    .label('Secondary Shares Over-allotment Authorized')
    .min(0)
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema =>
        schema.nullable().required('Secondary Shares Over-allotment Authorized is required.'),
    }),
  notionalOverAllotmentExercised: yup.number().label('Over-allotment Exercised').min(0).nullable(),
  primarySharesOverAllotmentExercised: yup
    .number()
    .label('Primary Shares Over-allotment Exercised')
    .nullable()
    .min(0),
  secondarySharesOverAllotmentExercised: yup
    .number()
    .label('Secondary Shares Over-allotment Exercised')
    .min(0)
    .nullable(),
  ipoRangeLow: yup.number().label('IPO Range Low').positive().nullable(),
  ipoRangeHigh: yup
    .number()
    .label('IPO Range High')
    .positive()
    .nullable()
    .when('ipoRangeLow', ([ipoRangeLow], schema) => {
      return ipoRangeLow
        ? schema.test({
            test: ipoRangeHigh => !!ipoRangeLow && !!ipoRangeHigh && ipoRangeLow <= ipoRangeHigh,
            message: 'Should be greater than or equal to IPO Range Low',
          })
        : schema;
    }),
  offerPrice: yup
    .number()
    .label('Offer Price')
    .positive()
    .nullable()
    .when('filingType', {
      is: type => type === FilingType.Final,
      then: schema => schema.nullable().required('Offer Price is required.'),
    }),
  postOfferingShares: yup.number().label('Post Offering Shares').positive().nullable(),
});
