import { Icon, Option, RenderOption } from '@cmg/common';
import React from 'react';

import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { SDepositaryReceiptMarker } from './DeliveryInstrumentRoute.styles';

export const DeliveryInstrumentSelectOption: RenderOption<
  Option<string> & { isDepositaryReceipt?: boolean }
> = (option, { context, selectValue }) => {
  const isOptionSelected = Array.isArray(selectValue)
    ? selectValue.includes(option)
    : selectValue === option;

  return (
    <FlexLayout direction="row">
      {option.label}
      {option.isDepositaryReceipt && (
        <SDepositaryReceiptMarker shouldOverrideColor={context === 'value' || !isOptionSelected}>
          <Icon name="check-circle" variant="solid" />
          Depositary Receipt
        </SDepositaryReceiptMarker>
      )}
    </FlexLayout>
  );
};
