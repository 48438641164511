import { numericUtil, timeUtil } from '@cmg/common';
import { DotStatus, Grid, Typography } from '@cmg/design-system';
import React from 'react';

import { OrderBook_TotalRetailInfoPartsFragment } from '../../graphql';

const { formatAsDisplayDateTime } = timeUtil;
const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  summaryInfo: OrderBook_TotalRetailInfoPartsFragment;
}>;

const MyRetailDemandInfoBox: React.FC<Props> = ({ summaryInfo }) => {
  const { submittedRetailDemand, releasedRetailRetention } = summaryInfo ?? {};

  return (
    <Grid
      container
      p={2}
      gap={2}
      alignItems="baseline"
      sx={{ backgroundColor: 'background.lightGrey' }}
    >
      <Grid item xs={5}>
        <Typography variant="body1">Total Submitted Retail Demand</Typography>
      </Grid>
      {(submittedRetailDemand || releasedRetailRetention) && (
        <Grid item xs={2}>
          {submittedRetailDemand && (
            <Typography variant="h4" textAlign="end">
              {getDisplayValueForInteger(submittedRetailDemand.shareQuantity)}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={4}>
        {submittedRetailDemand ? (
          <DotStatus
            label={`Submitted ${formatAsDisplayDateTime(submittedRetailDemand.submittedDateTime)}`}
            color="success"
          />
        ) : (
          <DotStatus label="Not Submitted" color="disabled" />
        )}
      </Grid>

      <Grid item xs={5}>
        <Typography variant="body1">Total Retention</Typography>
      </Grid>
      {(submittedRetailDemand || releasedRetailRetention) && (
        <Grid item xs={2}>
          {releasedRetailRetention && (
            <Typography variant="h4" textAlign="end">
              {getDisplayValueForInteger(releasedRetailRetention?.shareQuantity)}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={4}>
        {releasedRetailRetention ? (
          <DotStatus
            label={`Released ${formatAsDisplayDateTime(releasedRetailRetention.releasedDateTime)}`}
            color="success"
          />
        ) : (
          <DotStatus label="Not Released" color="disabled" />
        )}
      </Grid>
    </Grid>
  );
};

export default MyRetailDemandInfoBox;
