import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_MeetingDocument,
  Roadshows_UpdateMeetingMutation,
  useRoadshows_UpdateMeetingMutation,
} from '../graphql';

export type UpdateMeetingMutation = Roadshows_UpdateMeetingMutation;

export const refetchQueries = () => {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument);
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetingDetails!, refetchMeetings!];
};

export const useUpdateMeetingMutation = () => {
  return useRoadshows_UpdateMeetingMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
