import { ApolloCache } from '@apollo/client';

import { Roadshows_CreateDealTeamMutation, useRoadshows_CreateDealTeamMutation } from '../graphql';

export type CreateDealTeamMutation = Roadshows_CreateDealTeamMutation;

export const updateCache = (cache: ApolloCache<Roadshows_CreateDealTeamMutation>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'dealTeamExists' });
};

export const useCreateDealTeamMutation = () => {
  return useRoadshows_CreateDealTeamMutation({
    // reset the cache of validation endpoint
    update: updateCache,
  });
};
