import { DropdownButton, Icon, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { useExportInstitutionalDemandProspectusEmails } from './hooks/useExportInstitutionalDemandProspectusEmails';
import { useExportInstitutionalDemandRevisionHistory } from './hooks/useExportInstitutionalDemandRevisionHistory';
import { SDropdownContent } from './InstitutionalDemandOrdersAndAllocationsExportDropdownButton.styles';

type Props = {
  offeringId: string;
  onExportToCsv: () => void;
};

const InstitutionalDemandOrdersAndAllocationsExportDropdownButton: React.FC<Props> = ({
  offeringId,
  onExportToCsv,
}) => {
  const { handleExport: handleExportRevisionHistory, loading: revisionHistoryLoading } =
    useExportInstitutionalDemandRevisionHistory({ offeringId });

  const { handleExport: handleExportProspectusEmails, loading: prospectusEmailsLoading } =
    useExportInstitutionalDemandProspectusEmails({ offeringId });

  return (
    <DropdownButton
      trigger="click"
      popoverVariant="LIGHT"
      testId={xcSelectors.institutionalDemandOrdersAndAllocationsExportDropdownButton.testId}
      dropDownContent={() => (
        <SDropdownContent>
          <SecondaryButton
            onClick={onExportToCsv}
            testId={
              xcSelectors.institutionalDemandOrdersAndAllocationsExportOrderBookGridButton.testId
            }
            disabled={revisionHistoryLoading}
          >
            Export Order Book Grid
          </SecondaryButton>
          <SecondaryButton
            onClick={handleExportRevisionHistory}
            testId={
              xcSelectors.institutionalDemandOrdersAndAllocationsExportRevisionHistoryButton.testId
            }
            disabled={revisionHistoryLoading}
          >
            Export Revision History
          </SecondaryButton>
          <SecondaryButton
            onClick={handleExportProspectusEmails}
            disabled={prospectusEmailsLoading}
          >
            Export Prospectus Email History
          </SecondaryButton>
        </SDropdownContent>
      )}
    >
      <Icon variant="regular" name="file-export" />
    </DropdownButton>
  );
};

export default InstitutionalDemandOrdersAndAllocationsExportDropdownButton;
