import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import MandatoryAsterisk from '../../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { StabilizationTrade } from '../../../../StabilizationForm.model';
import { StyledGainLossCell } from '../../../common/gain-loss-table-cell/GainLossTableCell';
import { StyledTotalRow } from '../../../common/total-row/TotalRow';
import { getCumulativeAveragePrice, getTotalShares } from '../../../StabilizationFormContent.model';
import { getTotalSellGain } from '../../OverallotmentRefresh.model';

export type Props = { isEditable: boolean; trades: StabilizationTrade[]; offeringPrice: number };

const OverallotmentRefreshTableHeader: React.FC<Props> = ({
  isEditable,
  trades,
  offeringPrice,
}) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.TableHeaderCell align="left">
          Trade Date <MandatoryAsterisk />
        </Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">
          Shares <MandatoryAsterisk />
        </Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">
          Average Price <MandatoryAsterisk />
        </Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Gain/(Loss)</Table.TableHeaderCell>
        {isEditable && <Table.TableHeaderCell />}
      </Table.Row>

      <StyledTotalRow>
        <Table.Cell paddingSize="S">Total</Table.Cell>
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForNumber(getTotalShares(trades), 0)}
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(getCumulativeAveragePrice(trades), 6)}
        </Table.Cell>
        <StyledGainLossCell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(getTotalSellGain(trades, offeringPrice), 6)}
        </StyledGainLossCell>
        {isEditable && <Table.Cell />}
      </StyledTotalRow>
    </React.Fragment>
  );
};

export default OverallotmentRefreshTableHeader;
