import {
  CurrencyInputField,
  LinkButton,
  ODSTable as Table,
  SelectField,
  TextInputField,
} from '@cmg/common';
import React from 'react';

import { expensesCategoryOptions } from '../../../../../types/domain/final-settlement/constants';
import { StyledRow } from '../../ExpensesRoute.styles';

export type Props = {
  index: number;
  onRemoveExpense: (index: number) => void;
};

const EditableDealRelatedExpenseRow: React.FC<Props> = ({ index, onRemoveExpense }) => {
  return (
    <StyledRow suppressHighlightingOnHover isFocused={false}>
      <Table.Cell>
        <SelectField
          placeholder="Select Category"
          name={`dealRelatedExpenses.${index}.category`}
          fullWidth
          options={expensesCategoryOptions}
          maxMenuHeight={216}
        />
      </Table.Cell>
      <Table.Cell>
        <TextInputField name={`dealRelatedExpenses.${index}.description`} fullWidth />
      </Table.Cell>
      <Table.Cell>
        <CurrencyInputField name={`dealRelatedExpenses.${index}.value`} fullWidth />
      </Table.Cell>
      <Table.Cell>
        <LinkButton
          testId={`delete-expense-button.${index}`}
          iconLeft={{ name: 'trash-alt', size: 'lg' }}
          inline
          onClick={() => onRemoveExpense(index)}
        />
      </Table.Cell>
    </StyledRow>
  );
};

// performance optimization
export default React.memo(EditableDealRelatedExpenseRow);
