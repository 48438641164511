import styled from 'styled-components/macro';

export const SEmptyNotes = styled.div`
  color: ${({ theme }) => theme.foundation.colors.secondary['500']};
  font-size: ${({ theme }) => theme.text.size.xs};
`;

export const SNotes = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  word-break: break-word;
  white-space: pre-wrap;
`;
