import styled from 'styled-components/macro';

export const SFundIoisGridContainer = styled.div`
  .full-width-flex-child > * {
    width: 100%;
    display: flex;
  }

  && > * {
    padding: 0 10px;
  }

  && > * > * {
    border-bottom: none;
  }

  .ag-header-cell:last-child {
    &:after {
      background-color: unset;
    }

    .ag-header-cell-label,
    .ag-header-cell-text {
      overflow: visible;
    }
  }
`;

export const SPinnedRowText = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: 500;
  width: 100%;
`;

export const SMoreLink = styled.div`
  color: ${({ theme }) => theme.text.color.link};
`;

export const SSuffix = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SPriceValue = styled.span`
  display: inline-block;
  width: 100%;
  text-align: right;
`;

export const SPercentValue = styled.span`
  display: inline-block;
  width: 60px;
  text-align: right;
`;

export const SActionButtons = styled.div`
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: nowrap;

  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
