import React from 'react';

import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import SidePanel from '../../../../../../../common/components/layout/side-panel/SidePanel';
import Spinner from '../../../../../../../common/components/overlays/spinner/Spinner';
import EventNavigationStep from '../../../../common/event-navigation-step/EventNavigationStep';
import { LogisticsNavigationSteps } from './LogisticsSidePanel.model';
import { SHeader, SNavigation } from './LogisticsSidePanel.styles';

export type Props = {
  onClose: () => void;
  onNavigationChange?: (activeStepId: LogisticsNavigationSteps) => void;
  title?: string;
  activeNavigationStepId: LogisticsNavigationSteps;
  loading?: boolean;
  testId?: string;
};

const LogisticsSidePanel: React.FC<Props> = ({
  onClose,
  onNavigationChange,
  title,
  activeNavigationStepId,
  loading,
  children,
  testId,
}) => (
  <SidePanel isOpen onClose={onClose} testId={testId}>
    <FlexLayout expand direction="row">
      <SNavigation>
        <SHeader>{title}</SHeader>

        <EventNavigationStep
          label="Logistics Details"
          id={LogisticsNavigationSteps.DETAILS}
          icon="file-alt"
          activeStep={activeNavigationStepId}
          onChangeStep={onNavigationChange}
        />
        <EventNavigationStep
          label="Participants"
          id={LogisticsNavigationSteps.PARTICIPANTS}
          icon="user-friends"
          activeStep={activeNavigationStepId}
          onChangeStep={onNavigationChange}
        />
      </SNavigation>

      {loading ? <Spinner show fullHeight variant="DARK" /> : children}
    </FlexLayout>
  </SidePanel>
);

export default LogisticsSidePanel;
