export function replaceTextInDisclaimer(
  disclaimer: string | null,
  text: string,
  startIndex: number,
  endIndex: number
): string {
  if (!disclaimer) {
    return text;
  }

  const textBeforeSelection = disclaimer.substring(0, startIndex);
  const textAfterSelection = disclaimer.substring(endIndex, disclaimer.length);

  return `${textBeforeSelection}${text}${textAfterSelection}`;
}
