import { timeUtil } from '@cmg/common';
import { Grid, Stack, Typography } from '@cmg/design-system';
import React from 'react';

import GridRowPair from '../indication-section-read-view/grid-row-pair/GridRowPair';

const { formatAsDisplayDateTime } = timeUtil;

export type Props = Readonly<{
  submittedOn: string | null | undefined;
  firmName?: string | null;
}>;

const IndicationSectionPassedReadContent: React.FC<Props> = ({ submittedOn, firmName }) => {
  return (
    <Stack gap={1}>
      <Grid container rowGap={1}>
        <GridRowPair
          label="Submitted On:"
          value={submittedOn}
          formatter={formatAsDisplayDateTime}
        />
      </Grid>

      <div>
        <Typography variant="h4" paddingY={1}>
          Pass
        </Typography>
        <div>{firmName ?? 'Your firm'} has submitted a pass for this offering.</div>
      </div>
    </Stack>
  );
};

export default IndicationSectionPassedReadContent;
