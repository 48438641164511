import { ColDef, ColGroupDef, GridState } from '@cmg/data-grid';

import {
  acknowledgementsColDef,
  allocationAckStatusColDef,
  allocationConfirmedByColDef,
  attestationColDef,
  cmgEntityNameColDef,
  createdAtColDef,
  demandAtMarketColDef,
  demandMaxColDef,
  firmKeyColDef,
  getBndAgentColDef,
  modifiedAtDolDef,
  onPlatformColDef,
  prospectusColDef,
  relationshipColDef,
  rowAlertColDef,
  salesCoverageColDef,
  statusColDef,
  submittedByColDef,
  typeColDef,
} from '../columns';

type ColDefsType = (ColDef | ColGroupDef)[] | undefined;

export const CMG_TEMPLATES = {
  'CMG Default': getCMGDefaultGridState,
} as const;

export function isDynamicField(field: string) {
  return !!field.match(/^demandLevelAtPrice_.+$|^allocations_.+$/);
}

export function getNotesFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('field' in item && item.field?.match(/^notes\..+$/)) {
      return [...acc, item.field];
    }
    return acc;
  }, []);
}

export function getDemandFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('colId' in item && item.colId?.match(/^demandLevelAtPrice_.+$/)) {
      return [...acc, item.colId];
    }
    return acc;
  }, []);
}

export function getAllocationFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('colId' in item && item.colId?.match(/^allocations_.+$/)) {
      return [...acc, item.colId];
    }
    return acc;
  }, []);
}

export function getCustomInvestorFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('field' in item && item.field?.match(/^investor.extensions\..+$/)) {
      return [...acc, item.field];
    }
    return acc;
  }, []);
}

export function getCMGDefaultGridState({ colDefs }: { colDefs: ColDefsType }): GridState {
  const notesFields = getNotesFields({ colDefs }) ?? [];
  const demandFields = getDemandFields({ colDefs }) ?? [];
  const allocationFields = getAllocationFields({ colDefs }) ?? [];
  const customInvestorFields = getCustomInvestorFields({ colDefs }) ?? [];

  return {
    columnVisibility: {
      hiddenColIds: [
        cmgEntityNameColDef.field!,
        firmKeyColDef.field!,
        ...notesFields,
        ...customInvestorFields,
      ],
    },
    columnOrder: {
      orderedColIds: [
        rowAlertColDef.field!,
        'ag-Grid-AutoColumn',
        onPlatformColDef.field!,
        statusColDef.field!,
        demandMaxColDef.field!,
        typeColDef.field!,
        demandAtMarketColDef.field!,
        ...demandFields,
        acknowledgementsColDef.field!,
        relationshipColDef.field!,
        ...allocationFields,
        allocationAckStatusColDef.field!,
        getBndAgentColDef({ canUpdate: true }).field!,
        allocationConfirmedByColDef.field!,
        salesCoverageColDef.field!,
        submittedByColDef.field!,
        createdAtColDef.field!,
        modifiedAtDolDef.field!,
        attestationColDef.field!,
        prospectusColDef.field!,
        cmgEntityNameColDef.field!,
        firmKeyColDef.field!,
        ...notesFields,
        ...customInvestorFields,
      ],
    },
    columnPinning: {
      leftColIds: [rowAlertColDef.field!, 'ag-Grid-AutoColumn'],
      rightColIds: [allocationAckStatusColDef.field!, ...allocationFields],
    },
  };
}
