import { timeUtil } from '@cmg/common';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';

import { TYPE_LABELS } from '../../../../../../certificate-library.model';
import { CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment } from '../../../../../../graphql/__generated__';
import { useCertificateQueryParams } from '../../../../../../hooks/useCertificateQueryParams';
import DownloadPdfButton from '../download-pdf-button/DownloadPdfButton';

type Props = {
  certificates: readonly CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment[];
};

const PastCertificateTable: React.FC<Props> = ({ certificates }) => {
  const { cmgEntityKey } = useCertificateQueryParams();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Certificate</TableCell>
            <TableCell>Signature Date</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certificates.map(certificate => (
            <TableRow key={certificate.certificateId}>
              <TableCell>{TYPE_LABELS[certificate.certificateType]}</TableCell>
              <TableCell>
                {certificate.signatoryDate &&
                  timeUtil.formatAsDisplayDate(certificate.signatoryDate)}
              </TableCell>
              <TableCell>
                {certificate.expirationDate &&
                  timeUtil.formatAsDisplayDate(certificate.expirationDate)}
              </TableCell>
              <TableCell>
                {cmgEntityKey && (
                  <DownloadPdfButton
                    cmgEntityKey={cmgEntityKey}
                    certificateId={certificate.certificateId}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PastCertificateTable;
