import styled from 'styled-components/macro';

export const StyledActionsContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
`;

export const StyledTitle = styled.p`
  display: inline-block;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  margin: 0 0 0 10px;
`;
