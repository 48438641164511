import { ApolloError } from '@apollo/client';
import { Icon, Option, SelectField, SwitchField } from '@cmg/common';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { ManagerRole } from '../../../../graphql';
import {
  JuniorRole,
  juniorRoleLabels,
  managerRoleLabels,
  managerRoleOptions,
  managerRoleOptionsForAuthor,
} from '../../../../types/domain/manager/constants';
import { MAX_32_BIT_INT } from '../../../../types/graphql/constants';
import SetupForm from '../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../components/form/OfferingSetupFormActions';
import { UnderwriterSearchField } from '../../components/underwriter-search/UnderwriterSearch';
import ManagersUnsavedChangesGuard from './components/ManagersUnsavedChangesGuard';
import { OfferingSetup_ManagerPartsFragment } from './graphql';
import {
  SMarginRight,
  SReorderButton,
  SReorderColumn,
  SSwitchWrapper,
  StyledColumn,
  StyledFormField,
  StyledRow,
} from './Managers.styles';

export const ManagerSchema = yup.object().shape({
  cmgEntityKeyField: yup
    .string()
    .nullable()
    .transform(fieldValue => {
      // this field uses an Option for its value so we validate the nested value property
      return (fieldValue && fieldValue.value) || null;
    })
    .required('Firm is required'),
  role: yup.string().nullable().required('Manager Role is required'),
  economics: yup
    .number()
    .nullable()
    .positive('Share value must be greater than 0')
    .integer('Share value must be an integer')
    .max(MAX_32_BIT_INT, 'Share value is invalid'),
});

export type FormType = {
  cmgEntityKey: string;
  role: ManagerRole | JuniorRole; // The role can't REALLY be a junior role, but we have to allow for it, or we'll see type errors in the Managers page.
  isJuniorRole?: boolean | null;
  isParticipating: boolean;
  economics?: number | null;
};

export type FormFieldTypes = {
  cmgEntityKeyField: Option | null;
};

export type Props = {
  adding?: boolean;
  editing?: boolean;
  disabled?: boolean;
  reordering?: boolean;
  reordered?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit: (payload: FormType) => void;
  data?: OfferingSetup_ManagerPartsFragment;
  hasCreateInternationalTrait?: boolean;
  isAuthor?: boolean;
};

export const ManagerFormComponent: React.FC<Props> = ({
  adding,
  editing,
  disabled,
  reordering,
  reordered,
  loading,
  onMoveUp,
  onMoveDown,
  onEdit,
  onCancel,
  onDelete,
  onSubmit,
  data,
  hasCreateInternationalTrait,
  isAuthor,
}) => {
  const getInitialRole = () => {
    if (data?.isJuniorRole) {
      return JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER;
    }
    return data?.role ?? ManagerRole.ActiveBookrunner;
  };

  const getDisplayRole = () => {
    if (data?.isJuniorRole && data?.role === ManagerRole.ActiveBookrunner) {
      return juniorRoleLabels[JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER];
    } else {
      return managerRoleLabels[data!.role];
    }
  };

  const formikOptions: FormikConfig<FormType & FormFieldTypes> = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ManagerSchema,
    initialValues: {
      cmgEntityKey: data?.cmgEntityKey || '',
      cmgEntityKeyField: data?.cmgEntityKey
        ? { label: data?.firmName, value: data?.cmgEntityKey ?? null }
        : null,
      role: getInitialRole(),
      isJuniorRole: data?.isJuniorRole ?? false,
      isParticipating: data?.isParticipating ?? true,
      economics: data?.economics ?? null,
    },
    onSubmit: ({ cmgEntityKeyField, ...values }: FormType & FormFieldTypes) => {
      if (cmgEntityKeyField?.value) {
        onSubmit({ ...values, cmgEntityKey: cmgEntityKeyField.value });
      }
    },
  };

  const formik = useFormik(formikOptions);

  const { handleSubmit, dirty, resetForm } = formik;

  const actionProps = {
    editing,
    disabled,
    loading,
    onEdit,
    onCancel,
    onDelete,
    onSubmit: handleSubmit,
  };

  const roleOptions = React.useMemo(() => {
    if (hasCreateInternationalTrait) {
      if (isAuthor) {
        return managerRoleOptionsForAuthor;
      } else {
        return managerRoleOptions(hasCreateInternationalTrait);
      }
    }
    return managerRoleOptions();
  }, [hasCreateInternationalTrait, isAuthor]);

  return (
    <FormikProvider value={formik}>
      <ManagersUnsavedChangesGuard when={dirty} onLeave={resetForm}>
        <SetupForm.Card withBorder data-test-id="manager-row">
          <SetupForm.CardHeader>
            <SetupForm.Actions>
              <OfferingSetupFormActions {...actionProps} />
              <SReorderColumn reordering={reordering}>
                <SReorderButton onClick={onMoveUp} disabled={reordering ? true : !onMoveUp}>
                  <Icon name="caret-up" variant="solid" />
                </SReorderButton>
                <SReorderButton onClick={onMoveDown} disabled={reordering ? true : !onMoveDown}>
                  <Icon name="caret-down" variant="solid" />
                </SReorderButton>
              </SReorderColumn>
            </SetupForm.Actions>
          </SetupForm.CardHeader>
          <StyledRow dim={reordered}>
            <StyledColumn>
              {adding ? (
                <SMarginRight>
                  <UnderwriterSearchField
                    name="cmgEntityKeyField"
                    label="Firm Name"
                    required
                    fullWidth
                  />
                </SMarginRight>
              ) : (
                <StyledFormField label="Firm Name" fullWidth>
                  {data?.firmName}
                </StyledFormField>
              )}
            </StyledColumn>
            <StyledColumn>
              {editing ? (
                <SMarginRight>
                  <SelectField
                    name="role"
                    options={roleOptions}
                    disabled={disabled}
                    isClearable={false}
                    label="Role"
                    fullWidth
                    required
                  />
                </SMarginRight>
              ) : (
                <StyledFormField label="Role" fullWidth>
                  {data?.role && getDisplayRole()}
                </StyledFormField>
              )}
            </StyledColumn>
            <SetupForm.Column size={1}>
              <StyledFormField label="Is Participating?" fullWidth>
                <SSwitchWrapper>
                  <SMarginRight>
                    <SwitchField
                      name="isParticipating"
                      disabled={!editing || disabled || isAuthor}
                      fullWidth
                      required
                    />
                  </SMarginRight>
                </SSwitchWrapper>
              </StyledFormField>
            </SetupForm.Column>
          </StyledRow>
        </SetupForm.Card>
      </ManagersUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default ManagerFormComponent;
