import React from 'react';

import { syndicateWireManagerRoleLabels } from '../../../../../../../types/domain/syndicate-wires/constants';
import { ManagersNavigationProps } from '../../../context/WireTypeConfigContext.model';
import { WireStatus } from '../../wire-status/WireStatus';
import { ManagersNavigation as DefaultManagersNavigation } from '../managers-side-nav/ManagersNavigation';
import {
  SManager,
  SManagerIcon,
  SManagerList,
  SManagerName,
  SPanel,
  SRoleHeader,
} from '../managers-side-nav/ManagersNavigation.styles';

export const PricingCommitteeSideNav: React.FC<ManagersNavigationProps> = props => {
  if (props.managers.length) {
    return <DefaultManagersNavigation {...props} />;
  }

  return (
    <SPanel>
      <SRoleHeader>{syndicateWireManagerRoleLabels['PRICING_COMMITTEE']}</SRoleHeader>
      <SManagerList>
        <SManager isActive={false} disabled={true}>
          <SManagerIcon>
            <WireStatus status="empty" isActive={false} />
          </SManagerIcon>
          <SManagerName disabled={true} isActive={false}>
            Recipients not set
          </SManagerName>
        </SManager>
      </SManagerList>
    </SPanel>
  );
};
