export type FiltersCriteria<TInput> = {
  /** key is the filter name and value is a filter function that receives the object as an argument */
  [key: string]: (value: TInput) => boolean;
};

/**
 * multiFilterArray - Filters an array of objects using custom predicates.
 * filters are criteria functions
 *
 * filters are an object of
 * {
 *  [filterName]: (object instance) => {filterLogic}
 * }
 */
export const multiFilterArray = <TInput extends Object = {}>(
  array: readonly TInput[],
  filters: FiltersCriteria<TInput>
): readonly TInput[] => {
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') {
        return true;
      }

      return filters[key](item);
    });
  });
};
