import { AgGridApi, ButtonsContainer, DropdownButton, SecondaryButton, UUID } from '@cmg/common';
import React from 'react';

import { TradeRelease_TradeGridItemFragment } from '../graphql/__generated__';
import { STextWrapper, SWrapper } from './GridActions.styles';
import { useBulkGridActions } from './hooks/useBulkGridActions';

export type Props = Readonly<{
  offeringId: UUID;
  gridApi: AgGridApi | undefined;
  selectedRows: TradeRelease_TradeGridItemFragment[];
  setUnchangedTradesCount: (unchangedTradesCount: number) => void;
}>;

const GridActions: React.FC<Props> = ({
  offeringId,
  gridApi,
  selectedRows,
  setUnchangedTradesCount,
}) => {
  const {
    executeReleaseTrades,
    executeCancelRelease,
    executeCancelManually,
    executeMarkAsBooked,
    ...loading
  } = useBulkGridActions(offeringId, gridApi, setUnchangedTradesCount);

  return (
    <SWrapper>
      <STextWrapper>{selectedRows.length} Selected</STextWrapper>
      <ButtonsContainer>
        <SecondaryButton
          testId="btn-cancel-trades"
          disabled={loading.isLoading}
          loading={loading.isCancelling}
          onClick={() => executeCancelRelease(selectedRows)}
        >
          Cancel Release
        </SecondaryButton>
        <SecondaryButton
          testId="btn-release-trades"
          disabled={loading.isLoading}
          loading={loading.isReleasingTrades}
          onClick={() => executeReleaseTrades(selectedRows)}
        >
          Release Trades
        </SecondaryButton>
        <DropdownButton
          buttonComponent={() => (
            <SecondaryButton iconLeft={{ name: 'ellipsis-v' }}>More</SecondaryButton>
          )}
          popoverVariant="LIGHT"
          options={[
            {
              testId: 'btn-manually-book-trades',
              disabled: loading.isLoading,
              label: 'Mark as Booked (Manual)',
              onClick: () => {
                executeMarkAsBooked(selectedRows);
              },
            },
            {
              testId: 'btn-manually-cancel-trades',
              disabled: loading.isLoading,
              label: 'Mark as Cancelled (Manual)',
              onClick: () => {
                executeCancelManually(selectedRows);
              },
            },
          ]}
        />
      </ButtonsContainer>
    </SWrapper>
  );
};

export default GridActions;
