import styled from 'styled-components/macro';

export const SSectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SSectionHeader = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.medium};
  margin: 10px 10px 10px 0px;
`;
