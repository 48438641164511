import { ODSTable as Table, timeUtil } from '@cmg/common';
import React from 'react';

import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { offeringTypeLabels } from '../../../../types/domain/offering/constants';
import { FinalSettlement_SettlementLetterQuery } from '../graphql';

type Props = {
  data: FinalSettlement_SettlementLetterQuery['settlementLetter'];
};

const SettlementDetailsTable: React.FC<Props> = ({
  data: { issuerName, trancheName, offeringType, tradeDate, finalSettlementDate, managerRole },
}) => {
  return (
    <Table gridTemplateColumns="50% 50%">
      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Issuer Name:
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {issuerName}
        </Table.Cell>
      </Table.Row>

      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Tranche Name:
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {trancheName}
        </Table.Cell>
      </Table.Row>

      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Offering Type:
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {offeringTypeLabels[offeringType]}
        </Table.Cell>
      </Table.Row>

      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Trade Date:
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {timeUtil.formatAsDisplayDate(tradeDate ?? '')}
        </Table.Cell>
      </Table.Row>

      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Final Settlement Date:
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {timeUtil.formatAsDisplayDate(finalSettlementDate ?? '')}
        </Table.Cell>
      </Table.Row>

      <Table.Row suppressHighlightingOnHover isFocused={false}>
        <Table.Cell paddingSize="XS" withBottomBorder={false}>
          Your Role
        </Table.Cell>
        <Table.Cell paddingSize="XS" withBottomBorder={false} bold>
          {managerRoleLabels[managerRole]}
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};

export default SettlementDetailsTable;
