import { FormLabel, LinkButton, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';

import ButtonTooltip from '../../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import Table from '../../../../../common/components/layout/table/Table';
import { TNFilingValues } from '../../../filings/trading-notification/form/TNFilingForm.model';
import SymbolOfCoveredSecuritiesInfo from '../symbol-of-covered-securities-info/SymbolOfCoveredSecuritiesInfo';
import { SHeader } from './SymbolsOfCoveredCurrenciesForm.styles';

const maxItems = 10;

const SymbolsOfCoveredCurrenciesForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<TNFilingValues>();
  const { issuerSymbol } = values;

  React.useEffect(() => {
    if (issuerSymbol) {
      setFieldValue('symbolsOfCoveredSecurities[0]', issuerSymbol);
    } else if (issuerSymbol === null) {
      setFieldValue('symbolsOfCoveredSecurities[0]', '');
    }
  }, [issuerSymbol, setFieldValue]);

  return (
    <FieldArray name="symbolsOfCoveredSecurities">
      {({ push, remove }) => (
        <div>
          <SHeader>
            <h3>Symbols of covered securities</h3>
            <ButtonTooltip
              content={`Symbols of covered securities is limited to ${maxItems} items.`}
              disabled={values.symbolsOfCoveredSecurities.length < maxItems}
              variant="DARK"
            >
              <LinkButton
                testId={xcSelectors.regulatoryFilingsAddSymbol.testId}
                onClick={() => {
                  push('');
                }}
                iconLeft={{ name: 'plus' }}
                disabled={values.symbolsOfCoveredSecurities.length >= maxItems}
              >
                Add Symbol
              </LinkButton>
            </ButtonTooltip>
          </SHeader>

          <FormLabel renderInfo={() => <SymbolOfCoveredSecuritiesInfo />}>Symbol</FormLabel>

          <Table>
            <tbody>
              {values.symbolsOfCoveredSecurities.map((value, index) => (
                <tr key={index}>
                  <td>
                    <TextInputField
                      disabled={value === issuerSymbol && index === 0}
                      required
                      name={`symbolsOfCoveredSecurities.${index}`}
                    />
                  </td>
                  <td>
                    {index > 0 && (
                      <LinkButton
                        title="Delete Symbol"
                        onClick={() => {
                          remove(index);
                        }}
                        iconLeft={{ name: 'trash-alt' }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </FieldArray>
  );
};

export default SymbolsOfCoveredCurrenciesForm;
