import { UUID } from '@cmg/common';
import { Table, TableBody, TableContainer } from '@cmg/design-system';
import React from 'react';

import {
  OrderBook_TotalRetailSummaryItemManagerPartsFragment,
  OrderBook_TotalRetailSummaryItemPartsFragment,
} from '../../../graphql';
import TotalRetailDemandTableHeader from '../../common/table-header/TotalRetailDemandTableHeader';
import TotalRetailDemandTableTotalRow from '../../common/total-row/TotalRetailDemandTableTotalRow';
import {
  AssignDemandVariables,
  AssignRetentionVariables,
} from '../AutoSaveTotalRetailDemand.model';
import { useAggregateFormsValues } from './hooks/useAggregateFormsValues';
import TotalRetailDemandTableRow from './table-row/TotalRetailDemandTableRow';

export type Props = Readonly<{
  offeringId: UUID;
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[];
  onAssignDemand: (variables: AssignDemandVariables) => Promise<void>;
  onAssignRetention: (variables: AssignRetentionVariables) => Promise<void>;
  onFocus: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onSaveFinished: () => void;
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
  retailTarget: number | null;
}>;

const AutoSaveTotalRetailDemandTable: React.FC<Props> = ({
  offeringId,
  rows,
  onAssignDemand,
  onAssignRetention,
  onFocus,
  onBlur,
  onSaveFinished,
  allManagers,
  retailTarget,
}) => {
  const { values, totals, setValue } = useAggregateFormsValues(rows);

  return (
    <TableContainer>
      <Table>
        <TotalRetailDemandTableHeader />

        <TableBody>
          <TotalRetailDemandTableTotalRow
            {...totals}
            retailTarget={retailTarget}
            isReadOnly={false}
          />

          {rows.map(row => (
            <TotalRetailDemandTableRow
              key={row.id}
              offeringId={offeringId}
              row={row}
              demandValues={values[row.manager.cmgEntityKey].demand}
              retentionValues={values[row.manager.cmgEntityKey].retention}
              allManagers={allManagers}
              onAssignDemand={onAssignDemand}
              onAssignRetention={onAssignRetention}
              onValueChange={setValue}
              onFocus={onFocus}
              onBlur={onBlur}
              onSaveFinished={onSaveFinished}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutoSaveTotalRetailDemandTable;
