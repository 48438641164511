import { UUID } from '@cmg/common';
import { Box, Table, TableBody, TableContainer } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  OrderBook_TotalRetailSummaryItemManagerPartsFragment,
  OrderBook_TotalRetailSummaryItemPartsFragment,
} from '../../../graphql';
import TotalRetailDemandTableHeader from '../../common/table-header/TotalRetailDemandTableHeader';
import TotalRetailDemandTableTotalRow from '../../common/total-row/TotalRetailDemandTableTotalRow';
import { TotalRetailBulkSaveValues } from '../BulkSaveTotalRetailDemand.model';
import { calculateTotals } from './BulkSaveTotalRetailDemandTable.model';
import TotalRetailDemandTableRow from './table-row/TotalRetailDemandTableRow';

export type Props = Readonly<{
  offeringId: UUID;
  retailTarget: number | null;
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[];
  isEditing: boolean;
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
}>;

const BulkSaveTotalRetailDemandTable: React.FC<Props> = ({
  offeringId,
  rows,
  isEditing,
  allManagers,
  retailTarget,
}) => {
  const { values } = useFormikContext<TotalRetailBulkSaveValues>();

  const totals = React.useMemo(() => calculateTotals(values), [values]);

  return (
    <Box overflow="auto" width="100%">
      <TableContainer>
        <Table>
          <TotalRetailDemandTableHeader sharesRequired />

          <TableBody>
            <TotalRetailDemandTableTotalRow
              {...totals}
              retailTarget={retailTarget}
              isReadOnly={!isEditing}
            />

            {rows.map((row, index) => (
              <TotalRetailDemandTableRow
                key={row.id}
                offeringId={offeringId}
                row={row}
                allManagers={allManagers}
                isEditing={isEditing}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BulkSaveTotalRetailDemandTable;
