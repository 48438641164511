import type { OrderComparator, OrderComparatorFn } from '@cmg/common';

import { Calendar_FiledCalendarOfferingsListQuery } from '../../filed-calendar/graphql';
import { Calendar_LiveCalendarOfferingsListQuery } from '../../live-calendar/graphql';
import { Calendar_PostponedCalendarOfferingsListQuery } from '../../postponed-calendar/graphql';
import { Calendar_PricedCalendarOfferingsListQuery } from '../../priced-calendar/graphql';
import { CalendarGridOrderField } from './CalendarGridColumns';

export type CalendarGridOffering =
  | Calendar_FiledCalendarOfferingsListQuery['filedPublishedOfferings']['data'][number]
  | Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
  | Calendar_PostponedCalendarOfferingsListQuery['postponedPublishedOfferings']['data'][number]
  | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number];

export const numberComparator: OrderComparatorFn<number> = (a, b, direction) => {
  const factor = direction === 'desc' ? -1 : 1;
  return (a - b) * factor;
};

export const priceRangeComparator: OrderComparatorFn<unknown, CalendarGridOffering> = (
  _a,
  _b,
  direction,
  itemDataA,
  itemDataB
) => {
  const factor = direction === 'desc' ? -1 : 1;

  const aPriceRangeLow = itemDataA?.priceRangeLow || 0;
  const aPriceRangeHigh = itemDataA?.priceRangeHigh || 0;
  const bPriceRangeLow = itemDataB?.priceRangeLow || 0;
  const bPriceRangeHigh = itemDataB?.priceRangeHigh || 0;

  if (aPriceRangeLow === bPriceRangeLow) {
    return (aPriceRangeHigh - bPriceRangeHigh) * factor;
  }

  return (aPriceRangeLow - bPriceRangeLow) * factor;
};

export const orderComparator: OrderComparator<CalendarGridOffering> = {
  issuerName: 'string',
  [CalendarGridOrderField.SIZE_IN_CURRENCY]: numberComparator,
  [CalendarGridOrderField.INTERNATIONAL_SIZE_IN_CURRENCY]: numberComparator,
  [CalendarGridOrderField.SIZE_IN_SHARES]: numberComparator,
  [CalendarGridOrderField.PRICE_RANGE]: priceRangeComparator,
  [CalendarGridOrderField.INTERNATIONAL_PRICE_RANGE]: priceRangeComparator,
  [CalendarGridOrderField.FINAL_FILING_OFFER_PRICE]: numberComparator,
};
