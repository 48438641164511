import { numericUtil } from '@cmg/common';
import React, { FC } from 'react';

import { DesignationManager } from '../../../../hooks/useDesignationContext';
import { DesignationRow } from '../../DesignationMonitorGrid.model';
import { getAllInvestments } from './FixedPotTooltip.model';
import { STable } from './FixedPotTooltip.styles';

export type Props = {
  investorRows: DesignationRow[];
  managerColumns: DesignationManager[];
};

export const FixedPotTooltip: FC<Props> = ({ investorRows, managerColumns }) => {
  const allInvestments = React.useMemo(
    () => getAllInvestments(investorRows, managerColumns),
    [investorRows, managerColumns]
  );

  const totalAllocatedShares = React.useMemo(
    () => numericUtil.sum(...allInvestments.map(({ fixedPot }) => fixedPot)),
    [allInvestments]
  );

  return (
    <div>
      <STable>
        <thead>
          <tr>
            <th>Manager</th>
            <th />
            <th>Fixed Pot</th>
            <th />
            <th>Final Pot</th>
          </tr>
        </thead>

        <tbody>
          {allInvestments.map(({ managerName, fixedPot, finalPot }, index) => {
            return (
              <tr key={index}>
                <td width="50%">{managerName}</td>
                <td width="5%" />
                <td width="20%">
                  {numericUtil.getDisplayValueForPercents(
                    numericUtil.divide(fixedPot, totalAllocatedShares),
                    4
                  )}
                </td>
                <td width="5%" />
                <td width="20%">
                  {numericUtil.getDisplayValueForPercents(
                    numericUtil.divide(finalPot, totalAllocatedShares),
                    4
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>

        <tfoot>
          <tr>
            <td>Total</td>
            <td />
            <td>{numericUtil.getDisplayValueForPercents(1, 0)}</td>
            <td />
            <td>
              {numericUtil.getDisplayValueForPercents(
                numericUtil.divide(
                  numericUtil.sum(...allInvestments.map(({ finalPot }) => finalPot)),
                  totalAllocatedShares
                ),
                0
              )}
            </td>
          </tr>
        </tfoot>
      </STable>
    </div>
  );
};

export default FixedPotTooltip;
