import { UUID } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import SideNav from '../../../../../../../common/components/navigation/side-navigation/SideNav';
import {
  useCheckNonSyndicateCommunicationAccess,
  useCheckSyndicateCommunicationAccess,
} from '../../../../../../../common/util/check-access/useCheckSyndicateCommunicationAccess';
import { OfferingType } from '../../../../../../../graphql';
import { wireTypeConfigDesignation } from '../../../../wires/designation/DesignationWire.model';
import { wireTypeConfigFreeForm } from '../../../../wires/free-form/FreeFormWire.model';
import { wireTypeConfigGreenShoe } from '../../../../wires/green-shoe/GreenShoeWire.model';
import { wireTypeConfigManagerBd } from '../../../../wires/manager-bd/ManagerBdWire.model';
import { wireTypeConfigNonSyndicateFreeForm } from '../../../../wires/non-syndicate-free-form/NonSyndicateFreeFormWire.model';
import { wireTypeConfigNonSyndicatePayment } from '../../../../wires/non-syndicate-payment/NonSyndicatePaymentWire.model';
import { createWireTypeConfigNonSyndicateSellingGroupInvitation } from '../../../../wires/non-syndicate-selling-group-invitation/NonSyndicateSellingGroupInvitationWire.model';
import { wireTypeConfigNonSyndicateSellingGroupTermsRetention } from '../../../../wires/non-syndicate-selling-group-terms-retention/NonSyndicateSellingGroupTermsRetentionWire.model';
import { wireTypeConfigNonSyndicateTermination } from '../../../../wires/non-syndicate-termination/NonSyndicateTerminationWire.model';
import { wireTypeConfigPayment } from '../../../../wires/payment/PaymentWire.model';
import { wireTypeConfigPotAccountNames } from '../../../../wires/pot-account-names/PotAccountNamesWire.model';
import { wireTypeConfigPotAllocation } from '../../../../wires/pot-allocation/PotAllocationWire.model';
import { wireTypeConfigPricingTerms } from '../../../../wires/pricing-terms/PricingTermsWire.model';
import { wireTypeConfigPricingTermsRetention } from '../../../../wires/pricing-terms-retention/PricingTermsRetentionWire.model';
import { wireTypeConfigRegM } from '../../../../wires/reg-m/RegMWire.model';
import { wireTypeConfigRetention } from '../../../../wires/retention/RetentionWire.model';
import { createWireTypeConfigSellingGroupInvitation } from '../../../../wires/selling-group-invitation/SellingGroupInvitationWire.model';
import { wireTypeConfigSellingGroupTermsRetention } from '../../../../wires/selling-group-terms-retention/SellingGroupTermsRetentionWire.model';
import { wireTypeConfigTermination } from '../../../../wires/termination/TerminationWire.model';
import { createWireTypeConfigUnderwriterInvitation } from '../../../../wires/underwriter-invitation/UnderwriterInvitationWire.model';
import MenuHeading from '../../menu-heading/MenuHeading';
import { WireTypeNavigationItem } from '../wire-type-navigation-item/WireTypeNavigationItem';
import { useWireTemplateStatus } from './hooks/useWireTemplateStatus';
import { IssuerCommunicationReportsSection } from './IssuerCommunicationReportsSection';

export type Props = {
  offeringId: UUID;
  isOfferingIpo: boolean;
  offeringType: OfferingType;
};

export const StyledMenuHeading = styled(MenuHeading)`
  padding-left: 8px;
`;

export const SyndicateWiresSideNav: React.FC<Props> = ({
  offeringId,
  isOfferingIpo,
  offeringType,
}) => {
  const { canRead: canReadSyndicateCommunicationWires } =
    useCheckSyndicateCommunicationAccess(offeringId);
  const { canRead: canReadNonSyndicateCommunicationWires } =
    useCheckNonSyndicateCommunicationAccess(offeringId);

  const wireTypeConfigNonSyndicateSellingGroupInvitation =
    createWireTypeConfigNonSyndicateSellingGroupInvitation({ offeringType });
  const wireTypeConfigSellingGroupInvitation = createWireTypeConfigSellingGroupInvitation({
    offeringType,
  });
  const wireTypeConfigUnderwriterInvitation = createWireTypeConfigUnderwriterInvitation({
    offeringType,
  });

  const { getWireInfo } = useWireTemplateStatus({
    offeringId,
    canReadSyndicateWires: canReadSyndicateCommunicationWires,
    canReadNonSyndicateWires: canReadNonSyndicateCommunicationWires,
  });

  return (
    <SideNav testId="wires-side-nav">
      {canReadSyndicateCommunicationWires && (
        <React.Fragment>
          <StyledMenuHeading>Syndicate Communication</StyledMenuHeading>
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigUnderwriterInvitation.wireTypeName}
            wireTypeRoute={wireTypeConfigUnderwriterInvitation.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigUnderwriterInvitation.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigSellingGroupInvitation.wireTypeName}
            wireTypeRoute={wireTypeConfigSellingGroupInvitation.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigSellingGroupInvitation.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigRegM.wireTypeName}
            wireTypeRoute={wireTypeConfigRegM.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigRegM.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigPricingTerms.wireTypeName}
            wireTypeRoute={wireTypeConfigPricingTerms.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigPricingTerms.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigPricingTermsRetention.wireTypeName}
            wireTypeRoute={wireTypeConfigPricingTermsRetention.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigPricingTermsRetention.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigSellingGroupTermsRetention.wireTypeName}
            wireTypeRoute={wireTypeConfigSellingGroupTermsRetention.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigSellingGroupTermsRetention.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigRetention.wireTypeName}
            wireTypeRoute={wireTypeConfigRetention.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigRetention.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigTermination.wireTypeName}
            wireTypeRoute={wireTypeConfigTermination.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigTermination.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigPotAccountNames.wireTypeName}
            wireTypeRoute={wireTypeConfigPotAccountNames.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigPotAccountNames.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigPotAllocation.wireTypeName}
            wireTypeRoute={wireTypeConfigPotAllocation.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigPotAllocation.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigGreenShoe.wireTypeName}
            wireTypeRoute={wireTypeConfigGreenShoe.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigGreenShoe.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigDesignation.wireTypeName}
            wireTypeRoute={wireTypeConfigDesignation.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigDesignation.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigPayment.wireTypeName}
            wireTypeRoute={wireTypeConfigPayment.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigPayment.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigManagerBd.wireTypeName}
            wireTypeRoute={wireTypeConfigManagerBd.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigManagerBd.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigFreeForm.wireTypeName}
            wireTypeRoute={wireTypeConfigFreeForm.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigFreeForm.wireTemplateType)}
          />
          {isOfferingIpo && (
            <IssuerCommunicationReportsSection offeringId={offeringId} getWireInfo={getWireInfo} />
          )}
        </React.Fragment>
      )}
      {canReadNonSyndicateCommunicationWires && (
        <div data-testid="wires-side-nav-non-syndicate-communication">
          <StyledMenuHeading>Non-Syndicate Communication</StyledMenuHeading>
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigNonSyndicateSellingGroupInvitation.wireTypeName}
            wireTypeRoute={wireTypeConfigNonSyndicateSellingGroupInvitation.wireTypeRoute}
            wireInfo={getWireInfo(
              wireTypeConfigNonSyndicateSellingGroupInvitation.wireTemplateType
            )}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigNonSyndicateSellingGroupTermsRetention.wireTypeName}
            wireTypeRoute={wireTypeConfigNonSyndicateSellingGroupTermsRetention.wireTypeRoute}
            wireInfo={getWireInfo(
              wireTypeConfigNonSyndicateSellingGroupTermsRetention.wireTemplateType
            )}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigNonSyndicateTermination.wireTypeName}
            wireTypeRoute={wireTypeConfigNonSyndicateTermination.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigNonSyndicateTermination.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigNonSyndicatePayment.wireTypeName}
            wireTypeRoute={wireTypeConfigNonSyndicatePayment.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigNonSyndicatePayment.wireTemplateType)}
          />
          <WireTypeNavigationItem
            offeringId={offeringId}
            wireTypeName={wireTypeConfigNonSyndicateFreeForm.wireTypeName}
            wireTypeRoute={wireTypeConfigNonSyndicateFreeForm.wireTypeRoute}
            wireInfo={getWireInfo(wireTypeConfigNonSyndicateFreeForm.wireTemplateType)}
          />
        </div>
      )}
    </SideNav>
  );
};
