import { UnsavedChangesGuard, UnsavedChangesGuardProps } from '@cmg/common';
import React from 'react';

import entitlementsRouteFactory from '../../entitlements/entitlementsRouteFactory';

export type Props = Pick<UnsavedChangesGuardProps, 'when' | 'onLeave'>;

/**
 * ManagersUnsavedChangesGuard is a wrapper around UnsavedChangesGuard that ignores the unsaved changes guard when
 * the user interacts with entitlements routes which are sub-routes of offering-setup/managers
 */
const ManagersUnsavedChangesGuard: React.FC<Props> = ({ children, when, onLeave }) => {
  return (
    <UnsavedChangesGuard
      when={when}
      onLeave={onLeave}
      ignoreRoutes={[entitlementsRouteFactory.entitlements.routePath]}
    >
      {children}
    </UnsavedChangesGuard>
  );
};

export default ManagersUnsavedChangesGuard;
