import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import AccountingDetails from './accounting-details/AccountingDetails';
import { useFinalSettlement_SettlementAccountingQuery } from './graphql';

export type Props = RouteComponentProps<{ offeringId: UUID }> & {
  offeringIssuerName: string | undefined;
};

const AccountingRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.finalSettlementAccounting.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const { data, loading, error } = useFinalSettlement_SettlementAccountingQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <AccountingDetails
      offeringId={offeringId}
      accounting={data!.settlementAccounting}
      offeringIssuerName={offeringIssuerName ?? 'issuer'}
    />
  );
};

export default AccountingRoute;
