import { createCellStyle, defaultFrameworkComponents, numericUtil, UUID } from '@cmg/common';
import { RowNode } from 'ag-grid-community';

import routeFactory from '../../../../../common/util/routeFactory';
import { DesignationWarning } from '../../../../../graphql';
import {
  DesignationManager,
  DesignationMonitorGridContext,
} from '../../hooks/useDesignationContext';
import DesignationMonitorLinkRenderer from './components/designation-monitor-link-renderer/DesignationMonitorLinkRenderer';
import DisabledRowCellRenderer from './components/disabled-row-cell-renderer/DisabledRowCellRenderer';
import FixedPotTooltip from './components/fixed-pot-tooltip/FixedPotTooltip';
import HeaderEmptyComponent from './components/header-group-checkbox/HeaderEmptyComponent';
import HeaderGroupCheckbox from './components/header-group-checkbox/HeaderGroupCheckbox';
import LinkRendererWithEditedValue from './components/link-renderer-with-edited-value/LinkRendererWithEditedValue';
import { rowContainsNonApplicableWarning } from './components/warnings-renderer/DesignationMonitorWarningsRenderer.model';

export const frameworkComponents = {
  headerGroupCheckboxRenderer: HeaderGroupCheckbox,
  headerEmptyComponentRenderer: HeaderEmptyComponent,
  linkRendererWithEditedValue: LinkRendererWithEditedValue,
  designationMonitorLinkRenderer: DesignationMonitorLinkRenderer,
  disabledRowCellRenderer: DisabledRowCellRenderer,
};

export enum DesignationMonitorColumns {
  ID = 'id',
  WARNINGS = 'warnings',
  INDICATION_ID = 'indicationId',
  INVESTOR_NAME = 'investorName',
  SELECTED = 'selected',
  ALLOCATION = 'shareQuantity',
  TOTAL = 'total',
  INVESTMENTS = 'investments',
  TOTAL_DESIGNATIONS = 'totalDesignations',
  TOTAL_PERCENT = 'totalPercent',
  IS_EDITED = 'isEdited',
}

export interface DesignationRow {
  readonly warnings: readonly DesignationWarning[];
  readonly selected?: boolean;
  readonly id: string;
  readonly indicationId: string;
  readonly investorName: string;
  readonly shareQuantity: number;
  readonly investments?: Record<string, ManagerInvestment>;
  readonly totalDesignations?: number | null;
  readonly totalPercent?: number | null;
  readonly isEdited: boolean;
}

export interface ManagerInvestment {
  readonly id: string;
  readonly name: string;
  readonly shareQuantity?: number | null;
  readonly fixedEconomicsShares?: number | null;
  readonly percent?: number | null;
}

const isDisabledCellVisible = (data: DesignationRow, node: RowNode) => {
  return !node.isRowPinned() && rowContainsNonApplicableWarning(data.warnings);
};

export const defaultCellStyle = createCellStyle<DesignationRow>(() => ({
  justifyContent: 'flex-end',
}));

export const getInvestorNameCellRendererComponent = (
  isRowPinned: boolean,
  isEdited: boolean
): keyof typeof defaultFrameworkComponents | keyof typeof frameworkComponents => {
  if (isRowPinned) {
    return 'iconLabelRenderer';
  }
  if (isEdited) {
    return 'linkRendererWithEditedValue';
  }
  return 'designationMonitorLinkRenderer';
};

type CellRendererSelectorParams = {
  node: RowNode;
  data: DesignationRow;
  context: DesignationMonitorGridContext;
};

export const validateAllocationAndDesignationRendererSelector = ({
  data,
  context,
  node,
}: CellRendererSelectorParams) => {
  if (isDisabledCellVisible(data, node)) {
    return { component: 'disabledRowCellRenderer' };
  }

  if (
    !context.noEconomicsApplied &&
    data.warnings.includes(DesignationWarning.DesignationsAreOutOfBalance)
  ) {
    return {
      component: 'validationRenderer',
      params: {
        validation: 'error',
        justifyContent: 'flex-end',
        showWarningIcon: false,
        'data-test-id': 'invalid-shares-count-cell',
      },
    };
  }
  return {};
};

export const designationSelectionCellRendererSelector = ({
  node,
  data,
}: CellRendererSelectorParams) => {
  if (isDisabledCellVisible(data, node)) {
    return { component: 'disabledRowCellRenderer' };
  }
  if (node.isRowPinned()) {
    return { component: 'headerEmptyComponentRenderer' };
  }

  return { component: 'rowSelectorRenderer' };
};

export const investorNameRendererSelector = ({
  node,
  context,
  data,
}: CellRendererSelectorParams) => {
  if (isDisabledCellVisible(data, node)) {
    return { component: 'disabledRowCellRenderer' };
  }

  return {
    component: getInvestorNameCellRendererComponent(node.isRowPinned(), data.isEdited),
    params: {
      to: routeFactory.finalSettlementDesignationMonitorOverwrite.getUrlPath({
        offeringId: context.offeringId,
        indicationId: data.indicationId,
      }),
      replace: true,
      children: data.investorName,
      iconPosition: 'right',
      tooltip: (
        <FixedPotTooltip investorRows={context.investorRows} managerColumns={context.managers} />
      ),
    },
  };
};

export const commonCellRendererSelector = ({ node, data }: CellRendererSelectorParams) => {
  if (isDisabledCellVisible(data, node)) {
    return { component: 'disabledRowCellRenderer' };
  }
  return {};
};

export const isDesignationRowSelectablePredicate = ({ data }: { data: DesignationRow }) => {
  return !rowContainsNonApplicableWarning(data.warnings);
};

export const calculateInvestmentShareQuantity = (
  rows: DesignationRow[],
  managerId: UUID
): number | null => {
  return numericUtil.sum(...rows.map(({ investments }) => investments?.[managerId].shareQuantity));
};

export const calculateInvestmentPercentage = (
  isZeroState: boolean,
  managerCmgEntityKey: string,
  rows: DesignationRow[]
) => {
  const totalAllocatedShares = numericUtil.sum(...rows.map(({ shareQuantity }) => shareQuantity));

  if (isZeroState) {
    const totalInvestorFixedShares = numericUtil.sum(
      ...rows.map(({ investments }) => investments?.[managerCmgEntityKey].fixedEconomicsShares)
    );

    return numericUtil.divide(totalInvestorFixedShares, totalAllocatedShares);
  }

  return numericUtil.divide(
    numericUtil.sum(
      ...rows.map(({ investments }) => investments?.[managerCmgEntityKey].shareQuantity)
    ),
    totalAllocatedShares
  );
};

export const calculateTotalInvestmentPercentage = (
  isZeroState: boolean,
  rows: DesignationRow[]
) => {
  const totalAllocatedShares = numericUtil.sum(...rows.map(({ shareQuantity }) => shareQuantity));

  if (isZeroState) {
    const totalFixedShares = numericUtil.sum(
      ...rows
        .flatMap(({ investments }) => Object.values(investments ?? []))
        .map(({ fixedEconomicsShares }) => fixedEconomicsShares)
    );

    return numericUtil.divide(totalFixedShares, totalAllocatedShares);
  }

  return numericUtil.divide(
    numericUtil.sum(...rows.map(({ totalDesignations }) => totalDesignations)),
    totalAllocatedShares
  );
};

export const getDesignationTotalsHeader = (
  rows: DesignationRow[],
  managers: DesignationManager[],
  isZeroState: boolean
): DesignationRow[] => {
  const applicableRows = rows.filter(({ warnings }) => !rowContainsNonApplicableWarning(warnings));

  return rows.length > 0
    ? [
        {
          [DesignationMonitorColumns.ID]: 'header-1',
          [DesignationMonitorColumns.WARNINGS]: [],
          [DesignationMonitorColumns.INDICATION_ID]: 'INDICATION ID',
          [DesignationMonitorColumns.SELECTED]: false,
          [DesignationMonitorColumns.INVESTOR_NAME]: 'TOTAL',
          [DesignationMonitorColumns.ALLOCATION]: numericUtil.sum(
            ...applicableRows.map(({ shareQuantity }) => shareQuantity)
          )!,
          [DesignationMonitorColumns.INVESTMENTS]: managers.reduce<
            Record<string, ManagerInvestment>
          >((result, manager) => {
            result[manager.cmgEntityKey] = {
              shareQuantity: calculateInvestmentShareQuantity(applicableRows, manager.cmgEntityKey),
              percent: calculateInvestmentPercentage(
                isZeroState,
                manager.cmgEntityKey,
                applicableRows
              ),
              id: manager.cmgEntityKey,
              name: manager.cmgEntityName,
            };

            return result;
          }, {}),
          [DesignationMonitorColumns.TOTAL_DESIGNATIONS]: numericUtil.sum(
            ...applicableRows.map(({ totalDesignations }) => totalDesignations)
          ),
          [DesignationMonitorColumns.TOTAL_PERCENT]: calculateTotalInvestmentPercentage(
            isZeroState,
            applicableRows
          ),
          [DesignationMonitorColumns.IS_EDITED]: false,
        },
      ]
    : [];
};
