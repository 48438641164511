import { PopoverProps } from '@cmg/common';

import { TradeReleaseGridWarning } from '../../../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { CellRendererSelectorParams, TradeReleaseGridContext } from '../../TradeReleaseGrid.model';
import { QuantityReleasedPopoverContent } from './QuantityReleasedPopoverContent';
import SummaryQuantityReleasedPopoverContent from './SummaryQuantityReleasedPopoverContent';

const headerQuantityReleasedCellRendererSelector = (context: TradeReleaseGridContext) => {
  const rowsWithWarning = context.filteredRows.filter(({ warnings }) =>
    warnings.includes(TradeReleaseGridWarning.QuantityToReleaseChanged)
  );

  if (rowsWithWarning.length === 0) {
    return {};
  }

  const popoverProps: PopoverProps = {
    variant: 'LIGHT',
    disabled: false,
    placement: 'topLeft',
    arrowPointAtCenter: true,
    content: (
      <SummaryQuantityReleasedPopoverContent numOfTradesOutOfBalance={rowsWithWarning.length} />
    ),
  };

  return {
    component: 'validationRenderer',
    params: { validation: 'error', justifyContent: 'space-between', popoverProps },
  };
};

const bodyQuantityReleasedCellRendererSelector = (data: TradeRelease_TradeGridItemFragment) => {
  if (!data.warnings.includes(TradeReleaseGridWarning.QuantityToReleaseChanged)) {
    return {};
  }

  const popoverProps = {
    variant: 'LIGHT',
    disabled: false,
    placement: 'bottomLeft',
    arrowPointAtCenter: true,
    content: (
      <QuantityReleasedPopoverContent
        investorName={data.investorFirmName}
        finalAllocation={data.allocationShares}
        finalAllocationReleased={data.sharesReleased}
      />
    ),
  };

  return {
    component: 'validationRenderer',
    params: { validation: 'error', justifyContent: 'space-between', popoverProps },
  };
};

export const sharesReleasedCellRendererSelector = ({
  data,
  node,
  context,
}: CellRendererSelectorParams) => {
  const isHeaderRow = node.isRowPinned();

  if (isHeaderRow) {
    return headerQuantityReleasedCellRendererSelector(context);
  }

  return bodyQuantityReleasedCellRendererSelector(data);
};
