import { UUID } from '@cmg/common';

import { addArrayItemToGraphQLCacheFactory } from '../../../../../../../../../common/util/graphql';
import {
  Roadshows_CreateRoadshowManagementTeamMemberMutation,
  useRoadshows_CreateRoadshowManagementTeamMemberMutation,
} from '../graphql';

export type CreateTeamMemberMutation = Roadshows_CreateRoadshowManagementTeamMemberMutation;

// Management team members cache is updated instead of refetching (preffered) for user experience
export const useCreateTeamMemberMutation = (roadshowId: UUID) => {
  return useRoadshows_CreateRoadshowManagementTeamMemberMutation({
    update: addArrayItemToGraphQLCacheFactory(
      { __typename: 'ManagementTeam', id: roadshowId },
      'members',
      result => result.data?.createRoadshowManagementTeamMember
    ),
  });
};
