import { numericUtil, ODSTable as Table, PercentInputField, Popover } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { InfoBox } from '../../../common/components/info-box/InfoBox';
import { WarningIcon } from '../../../common/components/warning-icon/WarningIcon';
import { isPercentageValid } from '../../../common/util/validation';
import SetupForm from '../components/form/OfferingSetupForm';
import { calculateSum, getPercentageAllocationFee } from './GrossSpreadAllocationForm.model';
import { UndewritingFeesAndDiscountFormValues } from './UnderwritingDiscountsAndFeesRoute.model';
import {
  SCellContainer,
  SColumnHeader,
  SColumnHeaderContainer,
  SDivider,
  SFieldGrid,
  SFirtEmptyCell,
  SInfoPanelSubtitle,
  SRow,
  SRowTitleContainer,
  STotalCellContainer,
  STotalContainer,
  STotalRowTitleContainer,
} from './UnderwritingDiscountsAndFeesRoute.styles';

type Props = {
  pricingCurrencyCode?: string;
};

export const GrossSpreadAllocationForm: React.FC<Props> = ({ pricingCurrencyCode }: Props) => {
  const { values } = useFormikContext<UndewritingFeesAndDiscountFormValues>();

  const grossSpreadBaseTotalAllocation = calculateSum([
    values.baseGrossSpreadManagementFee,
    values.baseGrossSpreadUnderwritingFee,
    values.baseGrossSpreadSellingConcession,
  ]);

  const incentiveFeeTotalAllocation = calculateSum([
    values.incentiveFeeManagementFee,
    values.incentiveFeeUnderwritingFee,
    values.incentiveFeeSellingConcession,
  ]);

  const managementFee = getPercentageAllocationFee({
    grossSpreadBase: values.grossSpreadBase,
    grossSpreadBaseAllocationFee: values.baseGrossSpreadManagementFee,
    incentiveFee: values.incentiveFee,
    incentiveAllocationFee: values.incentiveFeeManagementFee,
  });

  const underwritingFee = getPercentageAllocationFee({
    grossSpreadBase: values.grossSpreadBase,
    grossSpreadBaseAllocationFee: values.baseGrossSpreadUnderwritingFee,
    incentiveFee: values.incentiveFee,
    incentiveAllocationFee: values.incentiveFeeUnderwritingFee,
  });

  const sellingConcession = getPercentageAllocationFee({
    grossSpreadBase: values.grossSpreadBase,
    grossSpreadBaseAllocationFee: values.baseGrossSpreadSellingConcession,
    incentiveFee: values.incentiveFee,
    incentiveAllocationFee: values.incentiveFeeSellingConcession,
  });

  const grossSpreadTotalDisplayValue = calculateSum([
    managementFee,
    underwritingFee,
    sellingConcession,
  ]);

  return (
    <SetupForm title="Gross Spread Allocation">
      <SRow>
        <SFieldGrid>
          <SColumnHeaderContainer>
            <SFirtEmptyCell />
          </SColumnHeaderContainer>
          <SColumnHeaderContainer>
            <SColumnHeader>Base</SColumnHeader>
          </SColumnHeaderContainer>
          <SColumnHeaderContainer>
            <SColumnHeader>Incentive Fee</SColumnHeader>
          </SColumnHeaderContainer>
          <SRowTitleContainer>Management Fee</SRowTitleContainer>
          <SCellContainer>
            <PercentInputField
              name="baseGrossSpreadManagementFee"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <SCellContainer>
            <PercentInputField
              name="incentiveFeeManagementFee"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <SRowTitleContainer>Underwriting Fee</SRowTitleContainer>
          <SCellContainer>
            <PercentInputField
              name="baseGrossSpreadUnderwritingFee"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <SCellContainer>
            <PercentInputField
              name="incentiveFeeUnderwritingFee"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <SRowTitleContainer>Selling Concession</SRowTitleContainer>
          <SCellContainer>
            <PercentInputField
              name="baseGrossSpreadSellingConcession"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <SCellContainer>
            <PercentInputField
              name="incentiveFeeSellingConcession"
              precision={2}
              displayPrecision
              hideError
            />
          </SCellContainer>
          <STotalRowTitleContainer>Total Gross Spread</STotalRowTitleContainer>
          <STotalCellContainer>
            {grossSpreadBaseTotalAllocation ? (
              <Popover
                variant="TOOLTIP"
                trigger="hover"
                disabled={isPercentageValid(grossSpreadBaseTotalAllocation)}
                content="Base Gross Spread Allocation must equal 0 or 100%"
              >
                <STotalContainer>
                  {!isPercentageValid(grossSpreadBaseTotalAllocation) && (
                    <WarningIcon type="error" />
                  )}
                  {numericUtil.formatPercents(grossSpreadBaseTotalAllocation, 2)}
                </STotalContainer>
              </Popover>
            ) : (
              '-'
            )}
          </STotalCellContainer>
          <STotalCellContainer>
            {incentiveFeeTotalAllocation ? (
              <Popover
                variant="TOOLTIP"
                trigger="hover"
                disabled={isPercentageValid(incentiveFeeTotalAllocation)}
                content="Incentive Fee Allocation must equal 0 or 100%"
              >
                <STotalContainer>
                  {!isPercentageValid(incentiveFeeTotalAllocation) && <WarningIcon type="error" />}
                  {numericUtil.formatPercents(incentiveFeeTotalAllocation, 2)}
                </STotalContainer>
              </Popover>
            ) : (
              '-'
            )}
          </STotalCellContainer>
        </SFieldGrid>
        <InfoBox gridTemplateColumns="3fr 10px 1.5fr">
          <Table.Cell paddingSize="XS" colSpan={3} withBottomBorder={false} bold>
            Total Gross Spread
          </Table.Cell>
          <Table.Cell paddingSize="XS" colSpan={3} withBottomBorder={false}>
            <SInfoPanelSubtitle>
              Breakdown can be calculated as soon as Total Gross Spread sums up to 100%
            </SInfoPanelSubtitle>
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Management Fee
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(managementFee, 6, pricingCurrencyCode)}
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false}>
            Underwriting Fee
          </Table.Cell>
          <Table.Cell paddingSize="S" bold withBottomBorder={false}>
            {underwritingFee ? '+' : null}
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(underwritingFee, 6, pricingCurrencyCode)}
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false}>
            Selling Concession
          </Table.Cell>
          <Table.Cell paddingSize="S" bold withBottomBorder={false}>
            {sellingConcession ? '+' : null}
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(sellingConcession, 6, pricingCurrencyCode)}
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
            <SDivider />
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
            Total Gross Spread
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" colSpan={1} bold withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(
              grossSpreadTotalDisplayValue,
              6,
              pricingCurrencyCode
            )}
          </Table.Cell>
        </InfoBox>
      </SRow>
    </SetupForm>
  );
};
