import { Popover } from '@cmg/common';
import React from 'react';

import { ShareActivityRowProps } from './ShareActivityRow';
import { SContainer, SIconWrapper } from './ShareActivityTooltip.styles';
import ShareIcon from './ShareIcon';
import ShareTooltipContent from './ShareTooltipContent';

export type Props = {
  shareHistory: ShareActivityRowProps[];
};

const ShareActivityTooltip: React.FC<Props> = ({ shareHistory }) => {
  return (
    <SContainer>
      <Popover
        variant="DARK"
        content={<ShareTooltipContent shareHistory={shareHistory} />}
        placement="right"
      >
        <SIconWrapper>
          <ShareIcon />
        </SIconWrapper>
      </Popover>
    </SContainer>
  );
};

export default ShareActivityTooltip;
