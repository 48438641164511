import React from 'react';

import { Props as FilingConfirmationModalProps } from '../FilingConfirmationModal';
import { FilingConfirmationModalAction } from '../FilingConfirmationModal.model';

export type Props = {
  filingName: string;
  action: FilingConfirmationModalAction;
  getContentMessage?: (action: FilingConfirmationModalAction) => string;
  onConfirm: () => Promise<void>;
};

export type Actions = {
  openConfirmationModal: () => void;
};

export type UseFilingConfirmationModalResult = [
  modalProps: FilingConfirmationModalProps,
  actions: Actions
];

export function useFilingConfirmationModal({
  filingName,
  action,
  onConfirm,
  getContentMessage,
}: Props): UseFilingConfirmationModalResult {
  const [isOpen, setOpen] = React.useState(false);

  const confirm = React.useCallback(async () => {
    setOpen(false);

    await onConfirm();
  }, [onConfirm]);

  const open = React.useCallback(() => {
    setOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setOpen(false);
  }, []);

  return [
    {
      show: isOpen,
      action,
      filingName,
      getContentMessage,
      onCancel: close,
      onConfirm: confirm,
    },
    {
      openConfirmationModal: open,
    },
  ];
}
