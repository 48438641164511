import { ColDef } from 'ag-grid-community';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { getFeatureToggles } from '../../../common/config';
import { ColDefCalendarGrid } from '../calendar.model';
import CalendarColumnHeader from '../components/calendar-column-header/CalendarColumnHeader';
import {
  calendarGridColumns,
  CalendarGridOrderField,
  commonColumnDefinition,
  numericCellStyle,
} from '../components/calendar-grid/CalendarGridColumns';
import { useInternationalColumns } from '../components/calendar-grid/hooks/useInternationalColumns';
import TodayTag from '../components/TodayTag';
import BuySideDemandCellRenderer from '../live-calendar/components/BuySideDemandCellRenderer';
import BuySideAllocationCurrencyCell from './components/BuySideAllocationCurrencyCell';
import BuySideAllocationSharesCell from './components/BuySideAllocationSharesCell';
import BuySideStatusCell from './components/BuySideStatusCell';
import { Calendar_PricedCalendarOfferingsListQuery } from './graphql';

export enum PricedCalendarSecondarySources {
  IOI = 'IOI',
  ALLOCATION_SHARES = 'ALLOCATION_SHARES',
  ALLOCATION_CURRENCY = 'ALLOCATION_CURRENCY',
  ACKNOWLEDGMENT_STATUS = 'ACKNOWLEDGMENT_STATUS',
}

// We need a little workaround to be able to use CalendarGridOffering fields here.
const calendarGridColumnsTypeFix = calendarGridColumns as {
  [key in keyof typeof calendarGridColumns]: ColDefCalendarGrid<
    Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
  >;
};

export const useBuySideColumns = (): ColDefCalendarGrid<
  Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
>[] => {
  const {
    regionAndCountryColumns,
    sizeInCurrencyColumn,
    priceRangeColumn,
    offerPriceColumn,
    firstTradeDateColumn,
  } =
    useInternationalColumns<
      Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
    >();

  const { isOrderBookBuySideInvestorReplyOn } = getFeatureToggles();

  const hideFilter = (c: ColDef) => {
    const colId: string = c.colId!;
    const excludedColumns = [
      ...(!isOrderBookBuySideInvestorReplyOn
        ? [PricedCalendarSecondarySources.ACKNOWLEDGMENT_STATUS]
        : []),
    ];
    return !includes(excludedColumns, colId);
  };

  return [
    calendarGridColumnsTypeFix[CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM],
    {
      ...calendarGridColumnsTypeFix[CalendarGridOrderField.ISSUER_NAME],
      cellRendererParams: ({ data }) => ({
        children: <TodayTag launchDate={data.launchDate} />,
      }),
    },
    calendarGridColumnsTypeFix[CalendarGridOrderField.SYMBOL],
    ...regionAndCountryColumns,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECTOR],
    calendarGridColumnsTypeFix[CalendarGridOrderField.TYPE],
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECURITY_TYPE],
    firstTradeDateColumn,
    sizeInCurrencyColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SIZE_IN_SHARES],
    priceRangeColumn,
    offerPriceColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.LEFT_LEAD],
    // SECONDARY SOURCES
    {
      ...commonColumnDefinition,
      colId: PricedCalendarSecondarySources.IOI,
      headerComponentFramework: () => <CalendarColumnHeader title="IOI (Shares)" />,
      headerName: 'IOI (Shares)',
      minWidth: 210,
      maxWidth: 400,
      suppressSizeToFit: true,
      cellRendererFramework: ({ data }) => (
        <BuySideDemandCellRenderer
          myInstitutionalIndication={data.myInstitutionalIndication}
          mdlIndications={{
            indications: [],
            ...data.mdlIndications,
            ioiType: data.mdlAllocation?.allocation?.ioiType, // Send ioiType (PASS, REG-M, NORMAL) retrieved from the allocation endpoint
          }}
          pricingCurrencyCode={data.pricingCurrencyCode}
        />
      ),
      // re-render the cell when myInstitutionalIndication & mdlIndications changes
      equals: (dataA, dataB) =>
        dataA &&
        dataB &&
        isEqual(dataA.myInstitutionalIndication, dataB.myInstitutionalIndication) &&
        isEqual(dataA.mdlIndications, dataB.mdlIndications),
    },
    {
      ...commonColumnDefinition,
      cellStyle: numericCellStyle,
      minWidth: 130,
      maxWidth: 300,
      colId: PricedCalendarSecondarySources.ALLOCATION_SHARES,
      headerComponentFramework: () => <CalendarColumnHeader title="Expected Allocation (Shares)" />,
      headerName: 'Expected Allocation (Shares)',
      cellRendererFramework: ({ data }) => (
        <BuySideAllocationSharesCell
          mdlAllocation={data.mdlAllocation}
          finalAllocation={data.finalAllocation}
        />
      ),
      equals: (dataA, dataB) =>
        dataA &&
        dataB &&
        isEqual(dataA.mdlAllocation, dataB.mdlAllocation) &&
        isEqual(dataA.finalAllocation, dataB.finalAllocation),
    },
    {
      ...commonColumnDefinition,
      cellStyle: numericCellStyle,
      minWidth: 130,
      maxWidth: 300,
      colId: PricedCalendarSecondarySources.ALLOCATION_CURRENCY,
      headerComponentFramework: () => (
        <CalendarColumnHeader title="Expected Allocation (Currency)" />
      ),
      headerName: 'Expected Allocation (Currency)',
      cellRendererFramework: ({ data }) => (
        <BuySideAllocationCurrencyCell
          mdlAllocation={data.mdlAllocation}
          finalAllocation={data.finalAllocation}
          finalFilingOfferPrice={data.finalFilingOfferPrice}
          pricingCurrencyCode={data.pricingCurrencyCode}
        />
      ),
      equals: (dataA, dataB) =>
        dataA &&
        dataB &&
        isEqual(dataA.mdlAllocation, dataB.mdlAllocation) &&
        isEqual(dataA.finalAllocation, dataB.finalAllocation) &&
        isEqual(dataA.finalFilingOfferPrice, dataB.finalFilingOfferPrice),
    },
    {
      ...commonColumnDefinition,
      colId: PricedCalendarSecondarySources.ACKNOWLEDGMENT_STATUS,
      headerComponentFramework: () => <CalendarColumnHeader title="Status" />,
      headerName: 'Status',
      minWidth: 140,
      maxWidth: 300,
      cellRendererFramework: ({ data, context }) => (
        <BuySideStatusCell id={data.id} finalAllocation={data.finalAllocation} context={context} />
      ),
      equals: (dataA, dataB) =>
        dataA &&
        dataB &&
        isEqual(dataA.id, dataB.id) &&
        isEqual(dataA.finalAllocation, dataB.finalAllocation),
    },
  ].filter(hideFilter);
};
