import { timeUtil } from '@cmg/common';
import React from 'react';

import { SLabel } from './DateRangeLabel.styles';

export type Props = {
  date: string;
};

const DateRangeLabel: React.FC<Props> = ({ date }) => (
  <SLabel>{timeUtil.formatAsMonthDate(date)}</SLabel>
);

export default DateRangeLabel;
