import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import { ManagerRole } from '../../../../graphql';
import {
  OfferingSetup_Summary_AvailableShareDraftManagersQuery,
  OfferingSetup_Summary_ManagerLimitedFragment,
  OfferingSetup_Summary_OfferingShareHistoryQuery,
} from '../../graphql';

type ShareHistory = OfferingSetup_Summary_OfferingShareHistoryQuery['shareHistory'];

export type ManagerGroupMap = { [key: string]: OfferingSetup_Summary_ManagerLimitedFragment[] };
const managerRolesOrder = [
  ManagerRole.ActiveBookrunner,
  ManagerRole.Bookrunner,
  ManagerRole.CoLead,
  ManagerRole.CoManager,
  ManagerRole.Underwriter,
];

/**
 * Sort manager group map by manager role array
 * @param managerGroupsMap manager groups to sort
 * @param sortingManagerRoles manager role array to sort by
 */
export const orderManagerGroupsByRole = (
  managerGroupsMap: ManagerGroupMap,
  sortingManagerRoles: ManagerRole[] = managerRolesOrder
): ManagerGroupMap => {
  const managerRoles = Object.keys(managerGroupsMap) as ManagerRole[];
  const orderedManagerRoles = managerRoles.sort(
    (managerRoleA, managerRoleB) =>
      sortingManagerRoles.indexOf(managerRoleA) - sortingManagerRoles.indexOf(managerRoleB)
  );
  const orderedManagerGroups: ManagerGroupMap = orderedManagerRoles.reduce(
    (acc, managerRole) => ({ ...acc, [managerRole]: managerGroupsMap[managerRole] }),
    {}
  );
  return orderedManagerGroups;
};

export const sortSharedDraftHistoryByVersion = (shareHistory: ShareHistory): ShareHistory => {
  return shareHistory.slice().sort((prevShareHistory, nextShareHistory) => {
    if (!prevShareHistory.version && !nextShareHistory.version) {
      return 0;
    }
    if (!prevShareHistory.version) {
      return 1;
    }
    if (!nextShareHistory.version) {
      return -1;
    }
    return parseInt(nextShareHistory.version) - parseInt(prevShareHistory.version);
  });
};

export const getShareHistoryDataForLastVersion = (
  shareHistory: ShareHistory,
  offeringVersion: string | undefined
): ShareHistory[0] | null => {
  const orderedShareHistory = sortSharedDraftHistoryByVersion(shareHistory);
  const lastVersionShares = orderedShareHistory.filter(share => share.version === offeringVersion);
  const [lastShareHistory] = lastVersionShares;
  if (!lastShareHistory) {
    return null;
  }
  return lastVersionShares.reduce(
    (acc, share) => {
      return { ...acc, managerKeys: uniq([...acc.managerKeys, ...share.managerKeys]) };
    },
    { ...lastShareHistory }
  );
};

/**
 * Group managers by role and sort them
 * @param managers managers to group by role
 */
export const getManagerGroups = (
  managers: OfferingSetup_Summary_AvailableShareDraftManagersQuery['availableShareDraftManagers']
): ManagerGroupMap => {
  const managerGroups = groupBy(managers, 'role');

  // sort manager groups by role
  return orderManagerGroupsByRole(managerGroups);
};

/**
 * Merges manager new manager keys and removes keys already selected
 * @param prevManagerKeys previous selected keys
 * @param managerKeysToSet selected keys to set or remove
 */
export const mergeManagerKeys = (prevManagerKeys: string[], managerKeysToSet: string[]) => {
  const newManagerKeys: string[] = [];
  const prevKeys = [...prevManagerKeys];
  managerKeysToSet.forEach(managerKey => {
    const managerKeyIndex = prevKeys.indexOf(managerKey);
    const managerKeyExists = managerKeyIndex >= 0;
    // If the key already exists remove it from the selection
    if (managerKeyExists) {
      prevKeys.splice(managerKeyIndex, 1);
    }
    // Else the manager key is new
    else {
      newManagerKeys.push(managerKey);
    }
  });

  // Return the previous keys that haven't been selected and the new keys
  return [...prevKeys, ...newManagerKeys];
};
