import { UUID } from '@cmg/common';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSkeleton,
} from '@cmg/design-system';
import { VFC } from 'react';

import { OperationType } from '../../../common/components/dialogs/create-or-update-wire-dialog/CreateOrUpdateWireDialog.model';
import { LoadingErrorSection } from '../../../common/components/dialogs/create-or-update-wire-dialog/error-loading-recipients-data-section/LoadingErrorSection';
import { FormikNumericField } from '../../../common/components/form/formik-numeric-field/FormikNumericField';
import { WireFormLayoutItem } from '../../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { NonSyndicateRecipientsUserDataFormFields } from '../../../common/components/recipients-user-data-form-field/NonSyndicateRecipientsUserDataFormFields';
import { DefaultRecipientValues } from '../../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { useNonSyndicateWireManagersQuery } from '../../../common/hooks/useNonSyndicateWireManagersQuery';
import { RetentionRecipientUserData } from '../../../wires/non-syndicate-selling-group-invitation/NonSyndicateSellingGroupInvitationWireForm.model';
import { RecipientsTableHead } from './RecipientsTableHead';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly operationType: OperationType;
}>;

const label = 'Broker Dealer Economics Table';

const defaultRecipientValues: DefaultRecipientValues<RetentionRecipientUserData> = {
  retention: null,
};

export const RecipientsTable: VFC<Props> = ({ offeringId, operationType }) => {
  const { data, loading, error, refetch } = useNonSyndicateWireManagersQuery(offeringId);

  if (loading) {
    return <TableSkeleton aria-label={label} cellSize="medium" numOfColumns={2} />;
  }

  if (error) {
    return (
      <LoadingErrorSection
        tableLabel={label}
        tableHead={<RecipientsTableHead />}
        onRefetch={refetch}
      />
    );
  }

  return (
    <Stack gap={3}>
      <WireFormLayoutItem>
        <WireFormBanner variant="manager-specific-input" />
      </WireFormLayoutItem>
      <TableContainer>
        <Table aria-label={label}>
          <RecipientsTableHead />
          <TableBody>
            <NonSyndicateRecipientsUserDataFormFields
              managers={data}
              operationType={operationType}
              defaultRecipientValues={defaultRecipientValues}
              renderRecipientRow={({ manager, managerIndex, onChange }) => {
                return (
                  <TableRow aria-label={manager.name} key={manager.cmgEntityKey}>
                    <TableCell>{manager.name}</TableCell>
                    <TableCell align="right">
                      <FormikNumericField
                        inputProps={{
                          'aria-label': `${manager.name} - Retention`,
                        }}
                        required
                        showHelperTextInTooltip
                        name={`recipientsUserData.${managerIndex}.retention`}
                        onChange={retention => {
                          onChange({ retention });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
