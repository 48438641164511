import { useApolloClient } from '@apollo/client';
import { AsyncPaginatedSelectField, AsyncPaginatedSelectFieldProps } from '@cmg/common';
import React from 'react';

import {
  OfferingSetup_Components_SearchUnderwritersDocument,
  OfferingSetup_Components_SearchUnderwritersQuery,
  OfferingSetup_Components_SearchUnderwritersQueryVariables,
} from '../../graphql';
import { UnderwriterOption } from './components/UnderwriterOption';
import { UnderwriterSearchOption } from './UnderwriterSearch.types';

// omit loadOptions since we handle it in this component wrapper
export type Props = Omit<AsyncPaginatedSelectFieldProps<string>, 'loadOptions'>;

export const UnderwriterSearchField: React.FC<Props> = props => {
  // grab the apollo client
  const client = useApolloClient();

  return (
    <AsyncPaginatedSelectField
      {...props}
      components={{ Option: UnderwriterOption }}
      loadOptions={async (searchText, loadedOptions, additionalArgs) => {
        const page = additionalArgs?.page ?? 1;
        // don't search if empty text
        if (!searchText) {
          return { options: loadedOptions };
        }

        try {
          // execute the query using apollo client directly
          const result = await client.query<
            OfferingSetup_Components_SearchUnderwritersQuery,
            OfferingSetup_Components_SearchUnderwritersQueryVariables
          >({
            query: OfferingSetup_Components_SearchUnderwritersDocument,
            variables: { name: searchText, page, perPage: 15 },
          });

          // get the data and pagination from response
          const { data, pagination } = result.data.underwriters;

          // map search results to options
          const options =
            data?.map<UnderwriterSearchOption>(
              ({ cmgEntityKey, displayName, mpid, crd, isOnPlatform }) => ({
                value: cmgEntityKey,
                label: displayName ?? cmgEntityKey,
                underwriterData: {
                  mpid,
                  crd,
                  isOnPlatform,
                },
              })
            ) ?? [];

          // component wants options[] and for infinite scroll pagination info like, hasMore/hasNext
          return {
            options: options,
            hasMore: !!pagination?.hasNext,
            additional: {
              page: page + 1,
            },
          };
        } catch {
          return { options: loadedOptions };
        }
      }}
    />
  );
};

export default UnderwriterSearchField;
