import { Box, DotStatus } from '@cmg/design-system';
import React from 'react';

import { AttestationFormStatus } from '../../../../../../graphql';
import type { DemandGridCellRendererProps } from '../../types';
import { getAttestationStatusColor } from './Attestation.model';

export type Props = DemandGridCellRendererProps<AttestationFormStatus>;

export const AttestationStatusCellRenderer: React.FC<Props> = ({ value, valueFormatted }) => {
  const attestationStatus = value ?? AttestationFormStatus.NotOnFile;
  return (
    <Box display="flex" alignItems="center" height="100%">
      <DotStatus
        color={getAttestationStatusColor(attestationStatus)}
        label={value ? valueFormatted! : 'Not on file'}
      />
    </Box>
  );
};
