/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_ResubmitRpnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_ResubmitRpnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly resubmitRPNFiling: {
    readonly __typename?: 'RPNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
    readonly restrictedPeriod: Types.RpnRestrictedPeriodType;
    readonly basisCode: Types.RpnBasisCodeType;
    readonly isConcurrentConvertible: boolean;
    readonly transactionType: Types.TransactionType;
    readonly anticipatedPricingDate: string;
    readonly transactionTypeDescription?: string | null;
    readonly uarDate?: string | null;
    readonly adtvNumber?: number | null;
    readonly adtvSource?: string | null;
    readonly publicFloatValue?: number | null;
    readonly publicFloatValueSource?: string | null;
    readonly otherBasisDescription?: string | null;
    readonly restrictionStartTime: string;
    readonly restrictionStartTimezone: string;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly finraMemberFirms: ReadonlyArray<{
      readonly __typename?: 'MemberFirm';
      readonly firmName: string;
      readonly firmMpid?: string | null;
      readonly firmCrdNumber: string;
      readonly excusedOrPassive: Types.MemberFirmType;
      readonly cmgEntityKey?: string | null;
      readonly affiliateMemberId?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly nonMemberFirms: ReadonlyArray<{
      readonly __typename?: 'NonMemberFirm';
      readonly firmName: string;
      readonly cmgEntityKey?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
  };
};

export type RegulatoryFilings_RpnFilingDetailQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_RpnFilingDetailQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly RPNFiling: {
        readonly __typename?: 'RPNFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
        readonly firmName: string;
        readonly firmCrdNumber: string;
        readonly firmMpid: string;
        readonly contactName: string;
        readonly contactTitle: string;
        readonly telephoneNumber: string;
        readonly emailAddress: string;
        readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
        readonly restrictedPeriod: Types.RpnRestrictedPeriodType;
        readonly basisCode: Types.RpnBasisCodeType;
        readonly isConcurrentConvertible: boolean;
        readonly transactionType: Types.TransactionType;
        readonly anticipatedPricingDate: string;
        readonly transactionTypeDescription?: string | null;
        readonly uarDate?: string | null;
        readonly adtvNumber?: number | null;
        readonly adtvSource?: string | null;
        readonly publicFloatValue?: number | null;
        readonly publicFloatValueSource?: string | null;
        readonly otherBasisDescription?: string | null;
        readonly restrictionStartTime: string;
        readonly restrictionStartTimezone: string;
        readonly finraErrorMessages: ReadonlyArray<string>;
        readonly issuerName: string;
        readonly issuerSymbol: string;
        readonly firmDealId: string;
        readonly formUniqueId: string;
        readonly finraMemberFirms: ReadonlyArray<{
          readonly __typename?: 'MemberFirm';
          readonly firmName: string;
          readonly firmMpid?: string | null;
          readonly firmCrdNumber: string;
          readonly excusedOrPassive: Types.MemberFirmType;
          readonly cmgEntityKey?: string | null;
          readonly affiliateMemberId?: string | null;
          readonly ownerCmgEntityKey?: string | null;
        }>;
        readonly nonMemberFirms: ReadonlyArray<{
          readonly __typename?: 'NonMemberFirm';
          readonly firmName: string;
          readonly cmgEntityKey?: string | null;
          readonly ownerCmgEntityKey?: string | null;
        }>;
      };
      readonly notifications: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingNotificationMessage';
        readonly id: string;
        readonly status: Types.NotificationMessageStatus;
        readonly sentAt?: string | null;
        readonly distributionList: ReadonlyArray<string>;
        readonly bccEmailAddresses: ReadonlyArray<string>;
        readonly managerSnapshot: {
          readonly __typename?: 'FinraManagerSnapshot';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
        };
      }>;
    };
  };
};

export type RegulatoryFilings_RpnFiling_MemberFirmPartsFragment = {
  readonly __typename?: 'MemberFirm';
  readonly firmName: string;
  readonly firmMpid?: string | null;
  readonly firmCrdNumber: string;
  readonly excusedOrPassive: Types.MemberFirmType;
  readonly cmgEntityKey?: string | null;
  readonly affiliateMemberId?: string | null;
  readonly ownerCmgEntityKey?: string | null;
};

export type RegulatoryFilings_RpnFilingPartsFragment = {
  readonly __typename?: 'RPNFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
  readonly firmName: string;
  readonly firmCrdNumber: string;
  readonly firmMpid: string;
  readonly contactName: string;
  readonly contactTitle: string;
  readonly telephoneNumber: string;
  readonly emailAddress: string;
  readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
  readonly restrictedPeriod: Types.RpnRestrictedPeriodType;
  readonly basisCode: Types.RpnBasisCodeType;
  readonly isConcurrentConvertible: boolean;
  readonly transactionType: Types.TransactionType;
  readonly anticipatedPricingDate: string;
  readonly transactionTypeDescription?: string | null;
  readonly uarDate?: string | null;
  readonly adtvNumber?: number | null;
  readonly adtvSource?: string | null;
  readonly publicFloatValue?: number | null;
  readonly publicFloatValueSource?: string | null;
  readonly otherBasisDescription?: string | null;
  readonly restrictionStartTime: string;
  readonly restrictionStartTimezone: string;
  readonly finraErrorMessages: ReadonlyArray<string>;
  readonly issuerName: string;
  readonly issuerSymbol: string;
  readonly firmDealId: string;
  readonly formUniqueId: string;
  readonly finraMemberFirms: ReadonlyArray<{
    readonly __typename?: 'MemberFirm';
    readonly firmName: string;
    readonly firmMpid?: string | null;
    readonly firmCrdNumber: string;
    readonly excusedOrPassive: Types.MemberFirmType;
    readonly cmgEntityKey?: string | null;
    readonly affiliateMemberId?: string | null;
    readonly ownerCmgEntityKey?: string | null;
  }>;
  readonly nonMemberFirms: ReadonlyArray<{
    readonly __typename?: 'NonMemberFirm';
    readonly firmName: string;
    readonly cmgEntityKey?: string | null;
    readonly ownerCmgEntityKey?: string | null;
  }>;
};

export type RegulatoryFilings_RpnFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_RpnFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly RPNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export type RegulatoryFilings_SubmitRpnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateRpnFilingInput;
}>;

export type RegulatoryFilings_SubmitRpnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly submitRPNFiling: {
    readonly __typename?: 'RPNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
    readonly restrictedPeriod: Types.RpnRestrictedPeriodType;
    readonly basisCode: Types.RpnBasisCodeType;
    readonly isConcurrentConvertible: boolean;
    readonly transactionType: Types.TransactionType;
    readonly anticipatedPricingDate: string;
    readonly transactionTypeDescription?: string | null;
    readonly uarDate?: string | null;
    readonly adtvNumber?: number | null;
    readonly adtvSource?: string | null;
    readonly publicFloatValue?: number | null;
    readonly publicFloatValueSource?: string | null;
    readonly otherBasisDescription?: string | null;
    readonly restrictionStartTime: string;
    readonly restrictionStartTimezone: string;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly finraMemberFirms: ReadonlyArray<{
      readonly __typename?: 'MemberFirm';
      readonly firmName: string;
      readonly firmMpid?: string | null;
      readonly firmCrdNumber: string;
      readonly excusedOrPassive: Types.MemberFirmType;
      readonly cmgEntityKey?: string | null;
      readonly affiliateMemberId?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly nonMemberFirms: ReadonlyArray<{
      readonly __typename?: 'NonMemberFirm';
      readonly firmName: string;
      readonly cmgEntityKey?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
  };
};

export const RegulatoryFilings_RpnFiling_MemberFirmPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excusedOrPassive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RpnFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RPNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anticipatedPricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excusedOrPassive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_ResubmitRpnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_ResubmitRpnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitRPNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excusedOrPassive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RPNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anticipatedPricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_ResubmitRpnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_ResubmitRpnFilingMutation,
  RegulatoryFilings_ResubmitRpnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_ResubmitRpnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_ResubmitRpnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_ResubmitRpnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsResubmitRpnFilingMutation, { data, loading, error }] = useRegulatoryFilings_ResubmitRpnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_ResubmitRpnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_ResubmitRpnFilingMutation,
    RegulatoryFilings_ResubmitRpnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_ResubmitRpnFilingMutation,
    RegulatoryFilings_ResubmitRpnFilingMutationVariables
  >(RegulatoryFilings_ResubmitRpnFilingDocument, options);
}
export type RegulatoryFilings_ResubmitRpnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_ResubmitRpnFilingMutation
>;
export type RegulatoryFilings_ResubmitRpnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_ResubmitRpnFilingMutation>;
export type RegulatoryFilings_ResubmitRpnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_ResubmitRpnFilingMutation,
  RegulatoryFilings_ResubmitRpnFilingMutationVariables
>;
export const RegulatoryFilings_RpnFilingDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RPNFiling' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_FilingNotificationMessageParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excusedOrPassive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RPNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anticipatedPricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_RpnFilingDetailQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_RpnFilingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_RpnFilingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_RpnFilingDetailQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_RpnFilingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_RpnFilingDetailQuery,
    RegulatoryFilings_RpnFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_RpnFilingDetailQuery,
    RegulatoryFilings_RpnFilingDetailQueryVariables
  >(RegulatoryFilings_RpnFilingDetailDocument, options);
}
export function useRegulatoryFilings_RpnFilingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_RpnFilingDetailQuery,
    RegulatoryFilings_RpnFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_RpnFilingDetailQuery,
    RegulatoryFilings_RpnFilingDetailQueryVariables
  >(RegulatoryFilings_RpnFilingDetailDocument, options);
}
export type RegulatoryFilings_RpnFilingDetailQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RpnFilingDetailQuery
>;
export type RegulatoryFilings_RpnFilingDetailLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RpnFilingDetailLazyQuery
>;
export type RegulatoryFilings_RpnFilingDetailQueryResult = Apollo.QueryResult<
  RegulatoryFilings_RpnFilingDetailQuery,
  RegulatoryFilings_RpnFilingDetailQueryVariables
>;
export const RegulatoryFilings_RpnFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RPNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_RpnFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_RpnFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_RpnFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_RpnFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_RpnFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_RpnFilingsQuery,
    RegulatoryFilings_RpnFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_RpnFilingsQuery,
    RegulatoryFilings_RpnFilingsQueryVariables
  >(RegulatoryFilings_RpnFilingsDocument, options);
}
export function useRegulatoryFilings_RpnFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_RpnFilingsQuery,
    RegulatoryFilings_RpnFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_RpnFilingsQuery,
    RegulatoryFilings_RpnFilingsQueryVariables
  >(RegulatoryFilings_RpnFilingsDocument, options);
}
export type RegulatoryFilings_RpnFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RpnFilingsQuery
>;
export type RegulatoryFilings_RpnFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RpnFilingsLazyQuery
>;
export type RegulatoryFilings_RpnFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_RpnFilingsQuery,
  RegulatoryFilings_RpnFilingsQueryVariables
>;
export const RegulatoryFilings_SubmitRpnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SubmitRpnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRPNFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitRPNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excusedOrPassive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RpnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RPNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anticipatedPricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RpnFiling_MemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictionStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SubmitRpnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SubmitRpnFilingMutation,
  RegulatoryFilings_SubmitRpnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_SubmitRpnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SubmitRpnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SubmitRpnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSubmitRpnFilingMutation, { data, loading, error }] = useRegulatoryFilings_SubmitRpnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegulatoryFilings_SubmitRpnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SubmitRpnFilingMutation,
    RegulatoryFilings_SubmitRpnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SubmitRpnFilingMutation,
    RegulatoryFilings_SubmitRpnFilingMutationVariables
  >(RegulatoryFilings_SubmitRpnFilingDocument, options);
}
export type RegulatoryFilings_SubmitRpnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SubmitRpnFilingMutation
>;
export type RegulatoryFilings_SubmitRpnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SubmitRpnFilingMutation>;
export type RegulatoryFilings_SubmitRpnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_SubmitRpnFilingMutation,
  RegulatoryFilings_SubmitRpnFilingMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_RpnFilingDetailMockResponse = makeMockResponse<
  RegulatoryFilings_RpnFilingDetailQueryVariables,
  RegulatoryFilings_RpnFilingDetailQuery
>(RegulatoryFilings_RpnFilingDetailDocument);

export const makeRegulatoryFilings_RpnFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_RpnFilingsQueryVariables,
  RegulatoryFilings_RpnFilingsQuery
>(RegulatoryFilings_RpnFilingsDocument);
