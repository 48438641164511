import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';

import { useOffering_OfferingHeaderQuery } from '../graphql';

/**
 * useOfferingHeaderQuery fetch offering data
 */
export const useOfferingHeaderQuery = ({ offeringId }: { offeringId: string }) => {
  const hasOfferingReadPermission = useCheckPermissions([permissionsByEntity.Offering.READ]);

  return useOffering_OfferingHeaderQuery({
    variables: {
      id: offeringId!,
      hasOfferingReadPermission,
    },
    skip: !offeringId,
    fetchPolicy: 'no-cache',
  });
};
