import { DesignSystemTooltipProps } from '@cmg/data-grid';

import { sortCaseInsensitive } from '../../../../../../common/util/sortCaseInsensitive';
import { IndicationStatus } from '../../../../../../graphql';
import { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import { DemandGridRowData } from '../../types';

export function getSeverity(
  acknowledgements: OrderBook_InstitutionalDemand_GridRowPartsFragment['acknowledgements']
): ReturnType<NonNullable<DesignSystemTooltipProps['getVariant']>> {
  if (acknowledgements?.find(item => item.isRevised)) {
    return 'warning';
  }
  return 'info';
}

export const getVariant: DesignSystemTooltipProps<OrderBook_InstitutionalDemand_GridRowPartsFragment>['getVariant'] =
  ({ data }) => {
    return getSeverity(data?.acknowledgements);
  };

export function sortAcknowledgements(
  acknowledgements: OrderBook_InstitutionalDemand_GridRowPartsFragment['acknowledgements'],
  oidcUserCmgEntityKey: string | null
) {
  return (
    acknowledgements?.slice().sort((a, b) => {
      if (a.manager.firmKey === oidcUserCmgEntityKey) {
        return -1;
      }
      if (b.manager.firmKey === oidcUserCmgEntityKey) {
        return 1;
      }

      return sortCaseInsensitive(
        a.manager.firmNameAbbreviation || a.manager.firmName,
        b.manager.firmNameAbbreviation || b.manager.firmName
      );
    }) ?? []
  );
}

export const getTooltipValue = ({
  data,
  value,
}: {
  data?: DemandGridRowData;
  value?: OrderBook_InstitutionalDemand_GridRowPartsFragment['acknowledgedByFirmNames'];
}) => {
  if (
    data?.__typename !== 'SyndicateInstitutionalGridRow' ||
    data?.status === IndicationStatus.Duplicate
  ) {
    return undefined; // This disables the tooltip
  }
  return value;
};
