import { SecondaryButton, SuccessButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SCancel = styled(SecondaryButton)`
  flex-basis: 30%;
  margin-right: 10px;
`;

export const SConfirmButton = styled(SuccessButton)`
  flex-basis: 70%;
`;

export const SFlexContainer = styled.div`
  display: flex;
`;
