import {
  PrimaryButton,
  Ribbon,
  SecondaryButton,
  SuccessButton,
  ToastManager,
  urlUtil,
  UUID,
} from '@cmg/common';
import { Typography } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import capitalize from 'lodash/capitalize';
import React, { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ButtonTooltip from '../../../../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import SelectRecipientsDialog from '../../../../../../../common/components/dialogs/select-recipients-dialog/SelectRecipientsDialog';
import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { filterRecipientsWithMissingEmails } from '../../../../../../../common/context/ContactSupportModalContext';
import { useContactSupportModalContext } from '../../../../../../../common/context/hooks/useContactSupportModalContext';
import { useCheckSyndicateWiresAccess } from '../../../../../../../common/util/check-access/useCheckSyndicateWiresAccess';
import { OfferingType } from '../../../../../../../graphql';
import { syndicateWireManagerRoleLabels } from '../../../../../../../types/domain/syndicate-wires/constants';
import useContactSupportDialog from '../../../../../../regulatory-filings/hooks/useContactSupportDialog';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useManagersMissingEmail } from '../../../hooks/useManagersMissingEmail';
import { useSendWireManagers } from '../../../hooks/useSendWireManagers';
import { DisclaimerContextProvider } from '../../dialogs/create-or-update-wire-dialog/context/DisclaimerContext';
import { CreateOrUpdateWireDialog } from '../../dialogs/create-or-update-wire-dialog/CreateOrUpdateWireDialog';
import { DeleteWireDialog } from '../../dialogs/delete-wire-dialog/DeleteWireDialog';
import { CCMyFirmSection } from '../../dialogs/send-wire-dialog/CCMyFirmSection';
import { useWireRecipientsDialog } from '../../dialogs/send-wire-dialog/hooks/useWireRecipientsDialog';
import MenuHeading from '../../menu-heading/MenuHeading';
import { OperationType } from '../../modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import SyndicateWireContent from '../../syndicate-wire-content/SyndicateWireContent';
import { SyndicateWireHeader } from '../../syndicate-wire-header/SyndicateWireHeader';
import { SyndicateWiresErrorBanner } from '../../syndicate-wires-error-banner/SyndicateWiresErrorBanner';
import { MissingDistributionListBanner } from '../../validation/missing-distribution-list-banner/MissingDistributionListBanner';
import { WireRibbon } from '../../wire-ribbon/WireRibbon';
import { SelectWireManager } from '../../zero-state/select-wire-manager/SelectWireManager';
import { WireCreated } from '../../zero-state/wire-created/WireCreated';
import { WireUpdated } from '../../zero-state/wire-updated/WireUpdated';
import { allRecipientsHaveUserInputData } from './DraftStage.model';

export enum SendOperationType {
  SEND = 'send',
}

export type Props = {
  offeringId: UUID;
  offeringType: OfferingType;
  syndicateWireId: UUID;
  managerId?: string;
  managers: SyndicateWireManager[];
  nextRecipients: SyndicateWireManager[];
};

export const DraftStage: React.FC<Props> = ({
  offeringId,
  offeringType,
  syndicateWireId,
  managerId,
  managers,
  nextRecipients,
}) => {
  const { location, push } = useHistory();
  const { operation } = urlUtil.queryParse<{ operation?: OperationType }>(location.search);
  const { canManage } = useCheckSyndicateWiresAccess(offeringId);

  const {
    wireTypeName,
    wireTypeRoute,
    wireTypeCategory,
    useWireDetails,
    useGenerateWirePreview,
    useSendMutation,
    useDeleteMutation,
    useSendIsDisabledReason,
    ManagersNavigation,
  } = useWireTypeConfigContext();

  const wireDetails = useWireDetails({
    offeringId,
    syndicateWireId,
  });
  const wirePreview = useGenerateWirePreview({
    offeringId,
    syndicateWireId,
    cmgEntityKey: managerId,
  });
  const sendIsDisabledReason = useSendIsDisabledReason({ offeringId, syndicateWireId });
  const managersMissingEmail = filterRecipientsWithMissingEmails(
    managers,
    useManagersMissingEmail({ offeringId })
  );

  const { mutation: deleteWireMutation, isLoading: isDeleting } = useDeleteMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [shouldCCMyFirm, setShouldCCMyFirm] = React.useState(false);

  const templateHTML = React.useMemo(() => {
    return { __html: wirePreview.data ?? '' };
  }, [wirePreview.data]);

  const recipients = useSendWireManagers({ managers, wire: wireDetails.data?.wire, offeringId });

  const hasInputDataForAllRecipients = allRecipientsHaveUserInputData(
    managers,
    wireDetails.data?.wire?.recipientsUserData
  );

  const { open: openSupportModal } = useContactSupportModalContext();

  const {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen: isRecipientsDialogOpen,
    open: openRecipientsDialog,
    close: closeRecipientsDialog,
  } = useWireRecipientsDialog({ recipients, wireTypeCategory });

  const { handleContactSupport } = useContactSupportDialog({
    recipients,
    onOpen: closeRecipientsDialog,
    onClose: useCallback(
      () => (isRecipientsDialogOpen ? openRecipientsDialog() : undefined),
      [isRecipientsDialogOpen, openRecipientsDialog]
    ),
  });

  const handleDeleteWire = async () => {
    try {
      await deleteWireMutation({
        offeringId,
        syndicateWireId,
      });
      ToastManager.success(`${wireTypeName} ${wireTypeCategory} deleted.`);
      push(wireTypeRoute.getUrlPath({ offeringId }));
    } catch {
      ToastManager.error(`Failed to delete ${wireTypeName} ${wireTypeCategory}.`);
    }
  };

  const { mutation: sendWire, isLoading: isSending } = useSendMutation();

  const handleOpenContactSupportModal = React.useCallback(() => {
    openSupportModal(managersMissingEmail, openRecipientsDialog);
    closeRecipientsDialog();
  }, [closeRecipientsDialog, managersMissingEmail, openRecipientsDialog, openSupportModal]);

  const handleSend = React.useCallback(async () => {
    try {
      await sendWire({
        offeringId,
        syndicateWireId: wireDetails.data?.wire!.id!,
        managerIds: selectedEnabledRecipientKeys,
        shouldAddSenderToCc: shouldCCMyFirm,
      });

      closeRecipientsDialog();
      push(
        wireTypeRoute.getUrlPath({ offeringId, syndicateWireId: wireDetails.data?.wire!.id! }) +
          `?operation=${SendOperationType.SEND}`
      );
    } catch {
      ToastManager.error(
        `An error has occurred while sending the ${wireTypeName} ${wireTypeCategory}. Please refresh the page and try again.`
      );
    }
  }, [
    sendWire,
    offeringId,
    wireDetails.data?.wire,
    selectedEnabledRecipientKeys,
    shouldCCMyFirm,
    push,
    wireTypeRoute,
    closeRecipientsDialog,
    wireTypeName,
    wireTypeCategory,
  ]);

  if (wireDetails.error) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner loadedEntity={`the ${wireTypeName} ${wireTypeCategory}`} />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  return (
    <SyndicateWireContent data-test-id="wire-draft-stage">
      <SyndicateWireHeader title={wireTypeName}>
        {canManage && (
          <React.Fragment>
            <SecondaryButton
              onClick={() => setIsDeleteModalOpen(true)}
              disabled={isDeleting}
              testId={xcSelectors.wiresDraftStageDeleteButton.testId}
            >
              Delete Version
            </SecondaryButton>
            <PrimaryButton
              onClick={() => setIsEditModalOpen(true)}
              testId={xcSelectors.wiresDraftStageEditButton.testId}
            >
              Edit Version
            </PrimaryButton>
            <ButtonTooltip
              disabled={!sendIsDisabledReason}
              content={sendIsDisabledReason}
              variant="DARK"
              placement="topRight"
            >
              <SuccessButton
                onClick={() => openRecipientsDialog()}
                disabled={!!sendIsDisabledReason}
                testId={xcSelectors.wiresDraftStageSendButton.testId}
              >
                Ready to Send
              </SuccessButton>
            </ButtonTooltip>
          </React.Fragment>
        )}
      </SyndicateWireHeader>

      <SyndicateWireContent.ContentSection>
        {!!managersMissingEmail.length && (
          <MissingDistributionListBanner
            onContactSupport={() => openSupportModal(managersMissingEmail)}
            firmNames={managersMissingEmail.map(manager => manager.firmName)}
          />
        )}

        <SyndicateWireContent.Body>
          {wireDetails.isLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <SyndicateWireContent.ManagerList>
                <MenuHeading>Recipients</MenuHeading>
                <ManagersNavigation
                  managers={managers}
                  offeringId={offeringId}
                  wire={wireDetails.data?.wire}
                  managerId={managerId}
                />
              </SyndicateWireContent.ManagerList>

              <SyndicateWireContent.Template>
                {wirePreview.isLoading ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    {wirePreview.error ? (
                      <SyndicateWiresErrorBanner
                        loadedEntity={`the ${wireTypeName} ${wireTypeCategory} preview`}
                      />
                    ) : (
                      <React.Fragment>
                        {managerId ? (
                          <Ribbon.Wrapper>
                            <WireRibbon stage="draft" />
                            <div
                              data-test-id="wire-template"
                              dangerouslySetInnerHTML={templateHTML}
                            />
                          </Ribbon.Wrapper>
                        ) : (
                          <React.Fragment>
                            {operation === OperationType.CREATE && (
                              <WireCreated
                                hasInputDataForAllRecipients={hasInputDataForAllRecipients}
                              />
                            )}
                            {operation === OperationType.UPDATE && <WireUpdated />}
                            {operation === undefined && (
                              <SelectWireManager isSendDisabled={false} />
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </SyndicateWireContent.Template>
            </React.Fragment>
          )}
        </SyndicateWireContent.Body>
      </SyndicateWireContent.ContentSection>
      {isDeleteModalOpen && (
        <Fragment>
          <DeleteWireDialog
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={handleDeleteWire}
          />
        </Fragment>
      )}
      {isEditModalOpen && (
        <DisclaimerContextProvider>
          <CreateOrUpdateWireDialog
            onClose={() => setIsEditModalOpen(false)}
            operationType={OperationType.UPDATE}
            offeringId={offeringId}
            syndicateWireId={syndicateWireId}
            wire={wireDetails.data?.wire}
            nextRecipients={nextRecipients}
            offeringType={offeringType}
          />
        </DisclaimerContextProvider>
      )}
      {wireDetails.data?.wire && (
        <Fragment>
          {isRecipientsDialogOpen && (
            <SelectRecipientsDialog
              open={isRecipientsDialogOpen}
              recipients={selectableRecipients}
              onSelectionChange={handleRecipientSelectionChange}
              onCancel={() => {
                closeRecipientsDialog();
              }}
              onSubmit={handleSend}
              isSubmitting={isSending}
              submitButtonLabel="Send"
              disableSubmitWithoutSelectedRecipient={true}
              title={`Send ${wireTypeName} ${capitalize(wireTypeCategory)}`}
              message={
                <Typography>
                  {`Please select recipients you would like the ${wireTypeCategory} to be sent to:`}
                </Typography>
              }
              onContactSupport={handleContactSupport}
              ccmyfirm={
                <CCMyFirmSection
                  offeringId={offeringId}
                  onChange={() => setShouldCCMyFirm(!shouldCCMyFirm)}
                  onContactSupport={handleOpenContactSupportModal}
                  shouldCCMyFirm={shouldCCMyFirm}
                />
              }
              getManagerRoleLabel={role => syndicateWireManagerRoleLabels[role]}
              withDistributionList={wireTypeCategory === 'wire'}
            />
          )}
        </Fragment>
      )}
    </SyndicateWireContent>
  );
};
