import { ColDefCalendarGrid } from '../calendar.model';
import {
  calendarGridColumns,
  CalendarGridOrderField,
} from '../components/calendar-grid/CalendarGridColumns';
import { useInternationalColumns } from '../components/calendar-grid/hooks/useInternationalColumns';
import TodayTag from '../components/TodayTag';
import { Calendar_PricedCalendarOfferingsListQuery } from './graphql';

// We need a little workaround to be able to use CalendarGridOffering fields here.
const calendarGridColumnsTypeFix = calendarGridColumns as {
  [key in keyof typeof calendarGridColumns]: ColDefCalendarGrid<
    Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
  >;
};

export const useSellSideColumns = (): ColDefCalendarGrid<
  Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
>[] => {
  const {
    regionAndCountryColumns,
    sizeInCurrencyColumn,
    priceRangeColumn,
    offerPriceColumn,
    firstTradeDateColumn,
  } =
    useInternationalColumns<
      Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]
    >();

  return [
    calendarGridColumnsTypeFix[CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM],
    {
      ...calendarGridColumnsTypeFix[CalendarGridOrderField.ISSUER_NAME],
      cellRendererParams: ({ data }) => ({
        children: <TodayTag launchDate={data.launchDate} />,
      }),
    },
    calendarGridColumnsTypeFix[CalendarGridOrderField.SYMBOL],
    ...regionAndCountryColumns,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECTOR],
    calendarGridColumnsTypeFix[CalendarGridOrderField.TYPE],
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECURITY_TYPE],
    firstTradeDateColumn,
    sizeInCurrencyColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SIZE_IN_SHARES],
    priceRangeColumn,
    offerPriceColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.LEFT_LEAD],
  ];
};
