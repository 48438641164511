import { TableCell, TableRow, useTheme } from '@cmg/design-system';
import React from 'react';

import MandatoryAsterisk from '../../../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import LimitConfigurationTooltip from './limit-configuration-tooltip/LimitConfigurationTooltip';

export type Props = Readonly<{
  isEditing?: boolean;
}>;

const InterestLevelsHeaderRow: React.FC<Props> = ({ isEditing }) => {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell align="right" sx={{ width: theme.spacing(19) }}>
        Demand Quantity {isEditing && <MandatoryAsterisk />}
      </TableCell>
      <TableCell sx={{ width: theme.spacing(15) }}>
        Demand Unit {isEditing && <MandatoryAsterisk />}
      </TableCell>
      <TableCell sx={{ width: theme.spacing(15) }}>
        Order Type {isEditing && <MandatoryAsterisk />}
      </TableCell>
      <TableCell>
        Limit Configuration {isEditing && <MandatoryAsterisk />} <LimitConfigurationTooltip />
      </TableCell>
      <TableCell sx={{ width: theme.spacing(7) }} />
    </TableRow>
  );
};

export default InterestLevelsHeaderRow;
