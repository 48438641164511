import { numericUtil, ODSTable as Table, UUID } from '@cmg/common';

import { InfoBox } from '../../../../../common/components/info-box/InfoBox';
import routeFactory from '../../../../../common/util/routeFactory';
import { stepIds } from '../../../constants';
import { SDivider } from '../../ManagerEconomicsRoute.styles';
import {
  calculateTotalGrossSpread,
  GrossSpreadAllocations,
} from './GrossSpreadAllocationCard.model';
import { STableSubHeader } from './GrossSpreadAllocationCard.styles';

type Props = {
  offeringId: UUID;
  base?: GrossSpreadAllocations | null;
  incentiveFee?: GrossSpreadAllocations | null;
};

export const GrossSpreadAllocationCard: React.FC<Props> = ({ offeringId, base, incentiveFee }) => {
  const totalGrossSpreadBase = base ? calculateTotalGrossSpread(base) : 0;

  const totalGrossSpreadIncentiveFee = incentiveFee ? calculateTotalGrossSpread(incentiveFee) : 0;

  return (
    <InfoBox
      gridTemplateColumns="2fr 1fr 1fr"
      sourceOfData={{
        text: 'Offering Setup: U/W Discounts & Fees',
        link: routeFactory.offeringSetup.getUrlPath({
          stepId: stepIds.UW_DISCOUNTS_FEES,
          offeringId,
        }),
      }}
    >
      <Table.Cell paddingSize="S" withBottomBorder={false} bold>
        Gross Spread Allocation
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        <STableSubHeader>Base</STableSubHeader>
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        <STableSubHeader>Incentive Fee</STableSubHeader>
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Management Fee
      </Table.Cell>
      <Table.Cell
        data-test-id="base-management-fee"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(base?.managementFeePercentage, 2)}
      </Table.Cell>
      <Table.Cell
        data-test-id="incentive-management-fee"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(incentiveFee?.managementFeePercentage, 2)}
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Underwriting Fee
      </Table.Cell>
      <Table.Cell
        data-test-id="base-underwriting-fee"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(base?.underwritingFeePercentage, 2)}
      </Table.Cell>
      <Table.Cell
        data-test-id="incentive-underwriting-fee"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(incentiveFee?.underwritingFeePercentage, 2)}
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Selling Concession
      </Table.Cell>
      <Table.Cell
        data-test-id="base-selling-concession"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(base?.sellingConcessionPercentage, 2)}
      </Table.Cell>
      <Table.Cell
        data-test-id="incentive-selling-concession"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(incentiveFee?.sellingConcessionPercentage, 2)}
      </Table.Cell>
      <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
        <SDivider />
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false} bold>
        Total Gross Spread
      </Table.Cell>
      <Table.Cell
        data-test-id="base-total-gross-spread"
        paddingSize="S"
        bold
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(totalGrossSpreadBase, 2)}
      </Table.Cell>
      <Table.Cell
        data-test-id="incentive-total-gross-spread"
        paddingSize="S"
        bold
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForPercents(totalGrossSpreadIncentiveFee, 2)}
      </Table.Cell>
    </InfoBox>
  );
};
