import { getCurrencySymbol, numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { InterestLevelInterestUnit, InterestLevelLimitType } from '../../../graphql';

type InterestLevel = {
  interestQuantity?: number | null;
  interestUnit: InterestLevelInterestUnit;
  limitPrice?: number | null;
  limitType?: InterestLevelLimitType | null;
};

type FormatInterestLevelProps = Readonly<{
  interestLevel: InterestLevel;
  pricingCurrencyCode: string;
  demandCurrencyCode: string | null | undefined;
}>;

export const formatInterestLevel = ({
  interestLevel,
  demandCurrencyCode,
  pricingCurrencyCode,
}: FormatInterestLevelProps) => {
  const priceLabel = formatInterestLevelPrice({ interestLevel, pricingCurrencyCode });
  const quantityLabel = formatInterestLevelQuantity({
    interestLevel,
    demandCurrencyCode,
  });

  return `${quantityLabel} @ ${priceLabel}`;
};

type FormatInterestLimitPriceProps = Pick<
  FormatInterestLevelProps,
  'interestLevel' | 'pricingCurrencyCode'
>;

export const formatInterestLevelPrice = ({
  interestLevel: { limitPrice, limitType },
  pricingCurrencyCode,
}: FormatInterestLimitPriceProps) => {
  if (isNil(limitPrice)) {
    return 'Market';
  }

  if (limitType === InterestLevelLimitType.Discount) {
    return `Discount ${numericUtil.formatPercents(limitPrice, 2)}`;
  } else if (limitType === InterestLevelLimitType.Premium) {
    return `Premium ${numericUtil.formatPercents(limitPrice, 2)}`;
  }
  return numericUtil.formatCurrency(limitPrice, 2, getCurrencySymbol(pricingCurrencyCode));
};

type FormatInterestQuantityProps = Pick<
  FormatInterestLevelProps,
  'interestLevel' | 'demandCurrencyCode'
>;

export const formatInterestLevelQuantity = ({
  interestLevel: { interestQuantity, interestUnit },
  demandCurrencyCode,
}: FormatInterestQuantityProps) => {
  switch (interestUnit) {
    case InterestLevelInterestUnit.Percent:
      return `${numericUtil.formatPercents(interestQuantity, 2)}`;
    case InterestLevelInterestUnit.Shares:
      return `${numericUtil.formatInteger(interestQuantity)} Shares`;
    case InterestLevelInterestUnit.Currency:
      return `${numericUtil.formatCurrency(
        interestQuantity,
        2,
        getCurrencySymbol(demandCurrencyCode)
      )}`;
    default:
      return '-';
  }
};
