import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: baseline;
`;

export const StyledIcon = styled(Icon)`
  margin-left: 4px;
`;

export const SLabel = styled.div`
  flex-grow: 1;
  margin: 0 4px 0 4px;
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SSymbol = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
`;
