import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { WireTemplateType } from '../../../../../graphql';
import {
  createUseDefaultNonSyndicateManagers,
  createUseDefaultNonSyndicateNextRecipients,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createUseDefaultOutdatedNonSyndicateManagersList } from '../../common/hooks/createDefaultUseOutdatedNonSyndicateManagersList';
import { createUseDefaultIsNonSyndicateWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsNonSyndicateWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import {
  SyndicateWires_NonSyndicateTerminationWirePartsFragment,
  SyndicateWires_NonSyndicateTerminationWireValidationPartsFragment,
  useSyndicateWires_NonSyndicateTerminationWireDetailsQuery,
  useSyndicateWires_NonSyndicateTerminationWirePreviewQuery,
  useSyndicateWires_NonSyndicateTerminationWiresQuery,
  useSyndicateWires_NonSyndicateTerminationWireValidationQuery,
} from './graphql';
import { useCreateNonSyndicateTerminationWireMutation } from './hooks/useCreateNonSyndicateTerminationWireMutation';
import { useDeleteNonSyndicateTerminationWireMutation } from './hooks/useDeleteNonSyndicateTerminationWireMutation';
import { useSendNonSyndicateTerminationWireMutation } from './hooks/useSendNonSyndicateTerminationWireMutation';
import { useUpdateNonSyndicateTerminationWireMutation } from './hooks/useUpdateNonSyndicateTerminationWireMutation';
import { NonSyndicateTerminationWireForm } from './NonSyndicateTerminationWireForm';
import { validationSchema, Values } from './NonSyndicateTerminationWireForm.model';

const wireTypeName = 'Termination';

export const wireTypeConfigNonSyndicateTermination = createWireTypeConfig<
  Values,
  SyndicateWires_NonSyndicateTerminationWirePartsFragment,
  SyndicateWires_NonSyndicateTerminationWireValidationPartsFragment,
  typeof routeFactory.nonSyndicateWiresTermination
>({
  wireTypeName,
  wireTypeRoute: routeFactory.nonSyndicateWiresTermination,
  wireTemplateType: WireTemplateType.NonSyndicateMemberTermination,
  useManagers: createUseDefaultNonSyndicateManagers(),
  useNextRecipients: createUseDefaultNonSyndicateNextRecipients(),
  useIsWireManagerListOutdated: createUseDefaultIsNonSyndicateWireManagerListOutdated(),
  useOutdatedManagersList: createUseDefaultOutdatedNonSyndicateManagersList(),
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicateTerminationWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.nonSyndicateTerminationWireDetails,
        stage: data.syndicateWires.publishedOrPrivateOffering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicateTerminationWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_NonSyndicateTerminationWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
      });

    return {
      data: data?.nonSyndicateTerminationWireValidation,
      isLoading: loading,
      error,
      refetch,
    };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateNonSyndicateTerminationWireMutation();

    return {
      mutation: async ({
        offeringId,
        values: {
          terminationDate: { date, timezone },
          disclaimer,
        },
      }) => {
        if (!date || !timezone) {
          throw new Error('Date and timezone are required fields.');
        }

        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              terminationDate: date,
              terminationTimeZone: timezone,
              disclaimer,
            },
          },
        });

        if (result.data?.createNonSyndicateTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createNonSyndicateTerminationWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateNonSyndicateTerminationWireMutation();

    return {
      mutation: async ({
        offeringId,
        syndicateWireId,
        values: {
          terminationDate: { date, timezone },
          disclaimer,
        },
      }) => {
        if (!date || !timezone) {
          throw new Error('Date and timezone are required fields.');
        }

        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: { terminationDate: date, terminationTimeZone: timezone, disclaimer },
          },
        });

        if (result.data?.updateNonSyndicateTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateNonSyndicateTerminationWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteNonSyndicateTerminationWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteNonSyndicateTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendNonSyndicateTerminationWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendNonSyndicateTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendNonSyndicateTerminationWire;
      },
      isLoading: loading,
    };
  },

  CreateOrUpdateForm: NonSyndicateTerminationWireForm,
  // @ts-expect-error schema type mismatch on Date
  formValidation: validationSchema,
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const initialValues = React.useMemo<Values>(() => {
      return {
        terminationDate: {
          date: wire?.terminationDate.date ?? '',
          timezone: wire?.terminationDate.timezone ?? '',
        },
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [
      wire?.terminationDate.date,
      wire?.terminationDate.timezone,
      wire?.disclaimer,
      operationType,
      disclaimerData?.syndicateWires.wiresDisclaimer,
    ]);

    return { isLoading: disclaimerLoading, data: initialValues, error };
  },
});
