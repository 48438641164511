/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_OrganizationSearchPagedListPartsFragment = {
  readonly __typename?: 'FirmLimitedPagedList';
  readonly data: ReadonlyArray<{
    readonly __typename?: 'FirmLimited';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly displayName?: string | null;
  }>;
  readonly pagination: {
    readonly __typename?: 'PaginationData';
    readonly activePage: number;
    readonly perPage: number;
    readonly totalPages?: number | null;
    readonly hasNext?: boolean | null;
    readonly hasPrevious: boolean;
    readonly totalCount?: number | null;
  };
};

export type Roadshows_OrganizationSearchQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
  pagination: Types.PaginationDataInput;
}>;

export type Roadshows_OrganizationSearchQuery = {
  readonly __typename?: 'Query';
  readonly underwriters: {
    readonly __typename?: 'FirmLimitedPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'FirmLimited';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly displayName?: string | null;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly activePage: number;
      readonly perPage: number;
      readonly totalPages?: number | null;
      readonly hasNext?: boolean | null;
      readonly hasPrevious: boolean;
      readonly totalCount?: number | null;
    };
  };
};

export const Roadshows_OrganizationSearchPagedListPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_OrganizationSearchPagedListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmLimitedPagedList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activePage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevious' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_OrganizationSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_OrganizationSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_OrganizationSearchPagedListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_OrganizationSearchPagedListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmLimitedPagedList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activePage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPrevious' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_OrganizationSearchQuery__
 *
 * To run a query within a React component, call `useRoadshows_OrganizationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_OrganizationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_OrganizationSearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRoadshows_OrganizationSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_OrganizationSearchQuery,
    Roadshows_OrganizationSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_OrganizationSearchQuery,
    Roadshows_OrganizationSearchQueryVariables
  >(Roadshows_OrganizationSearchDocument, options);
}
export function useRoadshows_OrganizationSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_OrganizationSearchQuery,
    Roadshows_OrganizationSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_OrganizationSearchQuery,
    Roadshows_OrganizationSearchQueryVariables
  >(Roadshows_OrganizationSearchDocument, options);
}
export type Roadshows_OrganizationSearchQueryHookResult = ReturnType<
  typeof useRoadshows_OrganizationSearchQuery
>;
export type Roadshows_OrganizationSearchLazyQueryHookResult = ReturnType<
  typeof useRoadshows_OrganizationSearchLazyQuery
>;
export type Roadshows_OrganizationSearchQueryResult = Apollo.QueryResult<
  Roadshows_OrganizationSearchQuery,
  Roadshows_OrganizationSearchQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeRoadshows_OrganizationSearchMockResponse = makeMockResponse<
  Roadshows_OrganizationSearchQueryVariables,
  Roadshows_OrganizationSearchQuery
>(Roadshows_OrganizationSearchDocument);
