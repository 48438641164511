import flatten from 'lodash/flatten';

import {
  Roadshows_EventParticipantsTeamPartsFragment,
  Roadshows_ManagementTeamMemberPartsFragment,
} from '../../../../../common/graphql';

export function participantsContainManagementMember({
  eventParticipantsTeam,
  meetingManagementMembers,
}: {
  eventParticipantsTeam: readonly Roadshows_EventParticipantsTeamPartsFragment[];
  meetingManagementMembers: readonly Roadshows_ManagementTeamMemberPartsFragment[];
}) {
  const participants = flatten(eventParticipantsTeam.map(team => team.participants));
  const managementMembersIds = meetingManagementMembers.map(member => member.id) ?? [];

  return participants.some(participant => managementMembersIds.includes(participant.id));
}

export function investorsContainMembers(
  meetingInvestors: readonly Roadshows_EventParticipantsTeamPartsFragment[]
) {
  return meetingInvestors.some(investor => investor.participants.length > 0);
}
