import { Comparator, ODSTable } from '@cmg/common';

import { EmailDeliveryStatus } from '../../../../../../../graphql';
import { ComplianceProspectus_LatestEmailStatusPartsFragment } from '../../graphql';

export enum ProspectusHistoryTableColumn {
  ERROR_INDICATOR = 'ERROR_INDICATOR',
  SEND_REQUESTED_AT = 'SEND_REQUESTED_AT',
  STATUS = 'STATUS',
  RECIPIENT = 'RECIPIENT',
}

const { comparator } = ODSTable.Orderable;

export const comparators: {
  [column in ProspectusHistoryTableColumn]?: Comparator<ComplianceProspectus_LatestEmailStatusPartsFragment>;
} = {
  [ProspectusHistoryTableColumn.ERROR_INDICATOR]: comparator.withAccessor(
    comparator.number,
    row => {
      return row.status === EmailDeliveryStatus.Failed ? 1 : 0;
    }
  ),
  [ProspectusHistoryTableColumn.SEND_REQUESTED_AT]: comparator.withAccessor(
    comparator.date,
    row => row.sendRequestedAt
  ),
  [ProspectusHistoryTableColumn.STATUS]: comparator.withAccessor(
    comparator.string,
    row => row.status
  ),
  [ProspectusHistoryTableColumn.RECIPIENT]: comparator.withAccessor(
    comparator.string,
    row => `${row.recipientName} ${row.recipientEmail}`
  ),
};
