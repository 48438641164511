import styled from 'styled-components/macro';

export const SEmptyMembers = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SSelectedMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.text.color.white};
  background: ${({ theme }) => theme.form.field.pill.baseColor};
  padding: 4px 8px;
  border-radius: 2px;
`;

export const SSelectedMember = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  line-height: ${({ theme }) => theme.text.size.medium};
  flex-grow: 1;
`;
