import { getOperationName } from '@apollo/client/utilities';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../../../graphql';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../graphql';
import { useOrderBook_DuplicateDraftAllocationSetMutation } from '../graphql/__generated__';

export const refetchQueries = () => {
  const refetchSummary = getOperationName(InstitutionalDemandGrid_SummaryDocument)!;
  const refetchDemand = getOperationName(OrderBook_OrderBookRouteDocument)!;
  return [refetchSummary!, refetchDemand!];
};

export const useDuplicateDraftAllocationSetMutation = () => {
  return useOrderBook_DuplicateDraftAllocationSetMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
