import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SAcknowledgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const StyledAcknowledgeButton = styled(PrimaryButton)`
  padding: 5px 12px;
`;
