import { FormField, NumericInputField, SwitchField } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../components/form/OfferingSetupForm';

export const StyledNumericInputField = styled(NumericInputField)`
  max-width: 80px;
  input {
    text-align: left;
  }
`;

export const StyledFormField = styled(FormField)`
  label {
    display: block;
  }
`;

export const StyledActions = styled(SetupForm.Actions)`
  margin-left: auto;
`;

export const SSelected = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.link};
  padding-top: 5px;
`;

export const SFractionWrapper = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;
`;

export const SFraction = styled.span`
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: 2px 4px;
  margin: 0 2px;
`;

export const StyledSwitchColumn = styled(SetupForm.Column)`
  align-self: flex-start;
`;

export const StyledSwitchField = styled(SwitchField)`
  padding: 6px 0;
`;
