import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Alert, LinkButton, UUID } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';

export type Props = {
  offeringId: UUID;
  numOfInvestorsWithoutBDAgent: number;
};

export const InvestorsWithoutBDAgentAlert: React.FC<Props> = ({
  offeringId,
  numOfInvestorsWithoutBDAgent,
}) => {
  const canAccessOrderbook = useCheckPermissions([permissionsByEntity.OrderBook.READ]);

  const getInfoAction = React.useCallback(
    (hasAccess: boolean) => {
      if (!hasAccess) {
        return null;
      }

      return (
        <LinkButton inline>
          <Link to={routeFactory.orderBook.getUrlPath({ offeringId })} target="_blank">
            View in Order Book
          </Link>
        </LinkButton>
      );
    },
    [offeringId]
  );

  return (
    <div>
      <Alert severity="warning" withMargin={true} action={getInfoAction(canAccessOrderbook)}>
        <Alert.Title>
          {numOfInvestorsWithoutBDAgent === 1
            ? 'There is 1 investor that has not been assigned a B&D agent'
            : `There are ${numOfInvestorsWithoutBDAgent} investors that have not been assigned a B&D agent`}
        </Alert.Title>
        <Alert.Details>
          Assign a B&D agent from Order Book, so all trades can be released & booked
        </Alert.Details>
      </Alert>
    </div>
  );
};
