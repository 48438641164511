import {
  SyndicateWires_UpdateFreeFormWireMutation,
  useSyndicateWires_UpdateFreeFormWireMutation,
} from '../graphql';

export type UpdateFreeFormWireMutation = SyndicateWires_UpdateFreeFormWireMutation;

export const useUpdateFreeFormWireMutation = () => {
  return useSyndicateWires_UpdateFreeFormWireMutation();
};
