/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSidePanel_CoveredIndicationEntityNameQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OfferingSidePanel_CoveredIndicationEntityNameQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndication?: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly cmgEntityName: string;
      readonly cmgEntityKey: string;
      readonly bankInvestorKey?: string | null;
      readonly bankInvestorName?: string | null;
    };
  } | null;
};

export type OfferingSidePanel_IndicationEntityNameQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OfferingSidePanel_IndicationEntityNameQuery = {
  readonly __typename?: 'Query';
  readonly institutionalIndication?: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly cmgEntityName: string;
      readonly cmgEntityKey: string;
      readonly bankInvestorKey?: string | null;
      readonly bankInvestorName?: string | null;
    };
  } | null;
};

export type OfferingSidePanel_InvestorFirmSearchQueryVariables = Types.Exact<{
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  firmKeyOrId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OfferingSidePanel_InvestorFirmSearchQuery = {
  readonly __typename?: 'Query';
  readonly investorFirm: {
    readonly __typename?: 'InvestorFirmBasic';
    readonly id: string;
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
  };
};

export type OfferingSidePanel_LinkedOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSidePanel_LinkedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly issuerName: string;
  };
};

export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    indicationId: Types.Scalars['UUID'];
    versionId: Types.Scalars['String'];
  }>;

export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly unacknowledgeCoveredInstitutionalFinalAllocation: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
    readonly allocationVersion?: string | null;
  };
};

export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    indicationId: Types.Scalars['UUID'];
    versionId: Types.Scalars['String'];
  }>;

export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly unacknowledgeInstitutionalFinalAllocation: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly indicationId: string;
    readonly shareQuantity?: number | null;
    readonly version: string;
    readonly investorReply?: {
      readonly __typename?: 'InvestorReply';
      readonly status: Types.AllocationAcknowledgement;
    } | null;
  };
};

export const OfferingSidePanel_CoveredIndicationEntityNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSidePanel_CoveredIndicationEntityName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSidePanel_CoveredIndicationEntityNameQuery__
 *
 * To run a query within a React component, call `useOfferingSidePanel_CoveredIndicationEntityNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_CoveredIndicationEntityNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSidePanel_CoveredIndicationEntityNameQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useOfferingSidePanel_CoveredIndicationEntityNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSidePanel_CoveredIndicationEntityNameQuery,
    OfferingSidePanel_CoveredIndicationEntityNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSidePanel_CoveredIndicationEntityNameQuery,
    OfferingSidePanel_CoveredIndicationEntityNameQueryVariables
  >(OfferingSidePanel_CoveredIndicationEntityNameDocument, options);
}
export function useOfferingSidePanel_CoveredIndicationEntityNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSidePanel_CoveredIndicationEntityNameQuery,
    OfferingSidePanel_CoveredIndicationEntityNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSidePanel_CoveredIndicationEntityNameQuery,
    OfferingSidePanel_CoveredIndicationEntityNameQueryVariables
  >(OfferingSidePanel_CoveredIndicationEntityNameDocument, options);
}
export type OfferingSidePanel_CoveredIndicationEntityNameQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_CoveredIndicationEntityNameQuery
>;
export type OfferingSidePanel_CoveredIndicationEntityNameLazyQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_CoveredIndicationEntityNameLazyQuery
>;
export type OfferingSidePanel_CoveredIndicationEntityNameQueryResult = Apollo.QueryResult<
  OfferingSidePanel_CoveredIndicationEntityNameQuery,
  OfferingSidePanel_CoveredIndicationEntityNameQueryVariables
>;
export const OfferingSidePanel_IndicationEntityNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSidePanel_IndicationEntityName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSidePanel_IndicationEntityNameQuery__
 *
 * To run a query within a React component, call `useOfferingSidePanel_IndicationEntityNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_IndicationEntityNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSidePanel_IndicationEntityNameQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useOfferingSidePanel_IndicationEntityNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSidePanel_IndicationEntityNameQuery,
    OfferingSidePanel_IndicationEntityNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSidePanel_IndicationEntityNameQuery,
    OfferingSidePanel_IndicationEntityNameQueryVariables
  >(OfferingSidePanel_IndicationEntityNameDocument, options);
}
export function useOfferingSidePanel_IndicationEntityNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSidePanel_IndicationEntityNameQuery,
    OfferingSidePanel_IndicationEntityNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSidePanel_IndicationEntityNameQuery,
    OfferingSidePanel_IndicationEntityNameQueryVariables
  >(OfferingSidePanel_IndicationEntityNameDocument, options);
}
export type OfferingSidePanel_IndicationEntityNameQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_IndicationEntityNameQuery
>;
export type OfferingSidePanel_IndicationEntityNameLazyQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_IndicationEntityNameLazyQuery
>;
export type OfferingSidePanel_IndicationEntityNameQueryResult = Apollo.QueryResult<
  OfferingSidePanel_IndicationEntityNameQuery,
  OfferingSidePanel_IndicationEntityNameQueryVariables
>;
export const OfferingSidePanel_InvestorFirmSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSidePanel_InvestorFirmSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeyOrId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKeyOrId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeyOrId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSidePanel_InvestorFirmSearchQuery__
 *
 * To run a query within a React component, call `useOfferingSidePanel_InvestorFirmSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_InvestorFirmSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSidePanel_InvestorFirmSearchQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      firmKeyOrId: // value for 'firmKeyOrId'
 *   },
 * });
 */
export function useOfferingSidePanel_InvestorFirmSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingSidePanel_InvestorFirmSearchQuery,
    OfferingSidePanel_InvestorFirmSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSidePanel_InvestorFirmSearchQuery,
    OfferingSidePanel_InvestorFirmSearchQueryVariables
  >(OfferingSidePanel_InvestorFirmSearchDocument, options);
}
export function useOfferingSidePanel_InvestorFirmSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSidePanel_InvestorFirmSearchQuery,
    OfferingSidePanel_InvestorFirmSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSidePanel_InvestorFirmSearchQuery,
    OfferingSidePanel_InvestorFirmSearchQueryVariables
  >(OfferingSidePanel_InvestorFirmSearchDocument, options);
}
export type OfferingSidePanel_InvestorFirmSearchQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_InvestorFirmSearchQuery
>;
export type OfferingSidePanel_InvestorFirmSearchLazyQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_InvestorFirmSearchLazyQuery
>;
export type OfferingSidePanel_InvestorFirmSearchQueryResult = Apollo.QueryResult<
  OfferingSidePanel_InvestorFirmSearchQuery,
  OfferingSidePanel_InvestorFirmSearchQueryVariables
>;
export const OfferingSidePanel_LinkedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSidePanel_LinkedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'issuerName' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSidePanel_LinkedOfferingQuery__
 *
 * To run a query within a React component, call `useOfferingSidePanel_LinkedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_LinkedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSidePanel_LinkedOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSidePanel_LinkedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSidePanel_LinkedOfferingQuery,
    OfferingSidePanel_LinkedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSidePanel_LinkedOfferingQuery,
    OfferingSidePanel_LinkedOfferingQueryVariables
  >(OfferingSidePanel_LinkedOfferingDocument, options);
}
export function useOfferingSidePanel_LinkedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSidePanel_LinkedOfferingQuery,
    OfferingSidePanel_LinkedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSidePanel_LinkedOfferingQuery,
    OfferingSidePanel_LinkedOfferingQueryVariables
  >(OfferingSidePanel_LinkedOfferingDocument, options);
}
export type OfferingSidePanel_LinkedOfferingQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_LinkedOfferingQuery
>;
export type OfferingSidePanel_LinkedOfferingLazyQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_LinkedOfferingLazyQuery
>;
export type OfferingSidePanel_LinkedOfferingQueryResult = Apollo.QueryResult<
  OfferingSidePanel_LinkedOfferingQuery,
  OfferingSidePanel_LinkedOfferingQueryVariables
>;
export const OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unacknowledgeCoveredInstitutionalFinalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'versionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationFn =
  Apollo.MutationFunction<
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationVariables
  >;

/**
 * __useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation__
 *
 * To run a mutation, you first call `useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSidePanelUnackknowledgeCoveredInstutionalFinalAllocationMutation, { data, loading, error }] = useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationVariables
  >(OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationDocument, options);
}
export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationHookResult =
  ReturnType<typeof useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation>;
export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationResult =
  Apollo.MutationResult<OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation>;
export type OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutationVariables
  >;
export const OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSidePanel_UnackknowledgeInstutionalFinalAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unacknowledgeInstitutionalFinalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'versionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorReply' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationFn =
  Apollo.MutationFunction<
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationVariables
  >;

/**
 * __useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation__
 *
 * To run a mutation, you first call `useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSidePanelUnackknowledgeInstutionalFinalAllocationMutation, { data, loading, error }] = useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationVariables
  >(OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationDocument, options);
}
export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationHookResult =
  ReturnType<typeof useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation>;
export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationResult =
  Apollo.MutationResult<OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation>;
export type OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation,
    OfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutationVariables
  >;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeOfferingSidePanel_CoveredIndicationEntityNameMockResponse = makeMockResponse<
  OfferingSidePanel_CoveredIndicationEntityNameQueryVariables,
  OfferingSidePanel_CoveredIndicationEntityNameQuery
>(OfferingSidePanel_CoveredIndicationEntityNameDocument);

export const makeOfferingSidePanel_IndicationEntityNameMockResponse = makeMockResponse<
  OfferingSidePanel_IndicationEntityNameQueryVariables,
  OfferingSidePanel_IndicationEntityNameQuery
>(OfferingSidePanel_IndicationEntityNameDocument);

export const makeOfferingSidePanel_InvestorFirmSearchMockResponse = makeMockResponse<
  OfferingSidePanel_InvestorFirmSearchQueryVariables,
  OfferingSidePanel_InvestorFirmSearchQuery
>(OfferingSidePanel_InvestorFirmSearchDocument);

export const makeOfferingSidePanel_LinkedOfferingMockResponse = makeMockResponse<
  OfferingSidePanel_LinkedOfferingQueryVariables,
  OfferingSidePanel_LinkedOfferingQuery
>(OfferingSidePanel_LinkedOfferingDocument);
