import * as yup from 'yup';

export type Values = {
  header: string;
  body: string;
};

export const validationSchema = yup.object().shape({
  header: yup.string().trim().label('Wire Title').nullable().required(),
  body: yup.string().trim().label('Wire Text').nullable().required(),
});
