import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  RegularBreakpoints,
} from '@cmg/design-system';
import { ReactNode, VFC } from 'react';

export type ConfirmationDialogProps = Readonly<{
  readonly fullWidth?: boolean;
  readonly maxWidth?: keyof RegularBreakpoints;
  readonly isOpen: boolean;
  readonly isLoading?: boolean;
  readonly isSubmitting?: boolean;
  readonly title: string;
  readonly content: ReactNode;
  readonly cancelButtonLabel?: string;
  readonly onCancel?: () => void;
  readonly isSubmitDisabled?: boolean;
  readonly submitButtonLabel: string;
  readonly onSubmit: () => void;
}>;

export const ConfirmationDialog: VFC<ConfirmationDialogProps> = ({
  fullWidth,
  maxWidth,
  isOpen,
  title,
  content,
  isLoading,
  isSubmitting,
  cancelButtonLabel,
  onCancel,
  submitButtonLabel,
  isSubmitDisabled,
  onSubmit,
}) => {
  const disableActions = isLoading || isSubmitting;

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined" color="primary" disabled={disableActions}>
            {cancelButtonLabel}
          </Button>
        )}
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          disabled={disableActions || isSubmitDisabled}
          onClick={onSubmit}
        >
          {submitButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
