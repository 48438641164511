import { ApolloError } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  CloseIcon,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { useDismissedErrors } from './hooks/useDismissedErrors';

type Props = Readonly<{
  errors: { [key: string]: ApolloError[] };
  title?: string;
  body?: string;
  action?: React.ReactNode;
  prefixMessageMap?: { [key: string]: string };
}>;

export const DismissibleErrorsBanner: React.FC<Props> = ({
  errors,
  title,
  body,
  action = null,
  prefixMessageMap,
}) => {
  const { errors: formattedAndFilteredErrors, onDismissErrors } = useDismissedErrors({
    errors,
    prefixMessageMap,
  });

  if (formattedAndFilteredErrors.length === 0) {
    return null;
  }

  return (
    <Alert
      severity="error"
      sx={{ margin: 2, mb: 0 }}
      onClose={onDismissErrors}
      action={
        <Box sx={{ flexDirection: 'row' }}>
          {action}
          <IconButton onClick={onDismissErrors}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {body && <Typography variant="body2">{body}</Typography>}
      <List sx={{ listStyleType: 'disc', padding: 0, pl: 2 }}>
        {formattedAndFilteredErrors.map((error, index) => (
          <ListItem key={error.message + index} sx={{ display: 'list-item', padding: 0 }}>
            <Typography variant="body2">{error.message}</Typography>
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};
