import React from 'react';
import { Link } from 'react-router-dom';

import offeringSidePanelSellSideRouteFactory from '../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { useGetSidePanelPath } from '../../hooks/useGetSidePanelPath';
import type { DemandGridCellRendererProps } from '../../types';

export type Props = DemandGridCellRendererProps<string>;

export const InvestorCellRenderer: React.FC<Props> = ({ data }) => {
  const path = useGetSidePanelPath({
    sidePanelRoutePath:
      offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
        cmgEntityKey: data?.investor.cmgEntityKey,
        indicationId: data?.id,
      }),
  });

  return <Link to={path}>{data?.investor.displayName}</Link>;
};
