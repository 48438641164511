import React from 'react';

import { FilingType } from '../../../../graphql';
import {
  OfferingSetup_UnderwritingTerms_FilingsQuery,
  useOfferingSetup_UnderwritingTerms_FilingsQuery,
} from '../../graphql';

export type FinalFilingTerms =
  OfferingSetup_UnderwritingTerms_FilingsQuery['offering']['filings'][number] & {
    offerPrice: number;
  };

export function useOfferingData(offeringId: string) {
  const { data, loading, error } = useOfferingSetup_UnderwritingTerms_FilingsQuery({
    variables: { offeringId },
  });

  const finalTermsData = React.useMemo(() => {
    return data?.offering.filings.find(filing => filing.filingType === FilingType.Final) as
      | FinalFilingTerms
      | undefined;
  }, [data]);

  return {
    currencyCode: data?.offering.pricingCurrencyCode,
    finalTermsData,
    loading,
    error,
  };
}
