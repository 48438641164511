import { AttestationFormStatus, FirmCertificateSource } from '../../../../graphql/__generated__';
import { SOURCE_LABELS, STATUS_LABELS } from '../../certificate-library.model';

type FilterOptions<T> = {
  title: string;
  value: T;
};

export const certificateExpiresArray: ('15' | '30' | '60' | '90')[] = ['15', '30', '60', '90'];

export type CertificateExpiresValue = (typeof certificateExpiresArray)[number];

export type CertificateStatusFilterOption = FilterOptions<AttestationFormStatus>;

export type CertificateSourceFilterOption = FilterOptions<FirmCertificateSource>;

export type CertificateExpiresInFilterOption = FilterOptions<CertificateExpiresValue>;

export const certificateStatusFilterOptions = Object.keys(
  STATUS_LABELS
).map<CertificateStatusFilterOption>(key => ({
  title: STATUS_LABELS[key],
  value: key as AttestationFormStatus,
}));

export const certificateSourceFilterOptions = Object.keys(
  SOURCE_LABELS
).map<CertificateSourceFilterOption>(key => ({
  title: SOURCE_LABELS[key],
  value: key as FirmCertificateSource,
}));

export const certificateExpiresInFilterOptions: CertificateExpiresInFilterOption[] = [
  { title: '15 Days', value: '15' },
  { title: '30 Days', value: '30' },
  { title: '60 Days', value: '60' },
  { title: '90 Days', value: '90' },
];
