import isNil from 'lodash/isNil';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  CreateNonSyndicateSellingGroupTermsRetentionWireInputData,
  WireTemplateType,
} from '../../../../../graphql';
import { createUseDefaultSendIsDisabledReason } from '../../common/context/hooks/useDefaultSendIsDisabledReason';
import {
  createUseDefaultNonSyndicateManagers,
  createUseDefaultNonSyndicateNextRecipients,
  createWireTypeConfig,
  WireTypeConfigInitialValues,
} from '../../common/context/WireTypeConfigContext.model';
import {
  SyndicateWires_MyRetailForBrokerDealerPartsFragment,
  useSyndicateWires_WiresDisclaimerQuery,
} from '../../common/graphql';
import { createUseDefaultOutdatedNonSyndicateManagersList } from '../../common/hooks/createDefaultUseOutdatedNonSyndicateManagersList';
import { createUseDefaultIsNonSyndicateWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsNonSyndicateWireManagerListOutdated';
import { useBrokerDealersMyRetailQuery } from '../../common/hooks/useBrokerDealersMyRetailQuery';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePartsFragment,
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationPartsFragment,
  useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
  useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
  useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
  useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
} from './graphql';
import { useCreateNonSyndicateSellingGroupTermsRetentionWireMutation } from './hooks/useCreateNonSyndicateSellingGroupTermsRetentionWireMutation';
import { useDeleteNonSyndicateSellingGroupTermsRetentionWireMutation } from './hooks/useDeleteNonSyndicateSellingGroupTermsRetentionWireMutation';
import { useSendNonSyndicateSellingGroupTermsRetentionWireMutation } from './hooks/useSendNonSyndicateSellingGroupTermsRetentionWireMutation';
import { useUpdateNonSyndicateSellingGroupTermsRetentionWireMutation } from './hooks/useUpdateNonSyndicateSellingGroupTermsRetentionWireMutation';
import { NonSyndicateSellingGroupTermsRetentionWireForm } from './NonSyndicateSellingGroupTermsRetentionWireForm';
import {
  NonSyndicateSellingGroupTermsRetentionRecipientUserData,
  validationSchema,
  Values,
} from './NonSyndicateSellingGroupTermsRetentionWireForm.model';

const wireTypeName = 'Selling Group Terms/Retention';

export const getPredefinedRecipientUserData = (
  manager: SyndicateWireManager,
  initialRecipientsUserData: (NonSyndicateSellingGroupTermsRetentionRecipientUserData | null)[],
  retail: SyndicateWires_MyRetailForBrokerDealerPartsFragment[]
): NonSyndicateSellingGroupTermsRetentionRecipientUserData | null => {
  const initialManagerValues = initialRecipientsUserData.find(
    initialUserData => initialUserData?.recipient === manager.cmgEntityKey
  );

  if (!isNil(initialManagerValues?.sellingConcession)) {
    return initialManagerValues!;
  }

  const sellingConcession =
    retail.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey)?.sellingConcession ??
    null;

  if (sellingConcession !== null) {
    return {
      recipient: manager.cmgEntityKey,
      sellingConcession,
    };
  }

  return null;
};

const getNonSyndicateSellingGroupTermsRetentionWireInputData = (formValues: Values) => {
  const { disclaimer, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientsUserData => recipientsUserData !== null)
    .map<CreateNonSyndicateSellingGroupTermsRetentionWireInputData>(recipientsUserData => {
      return {
        recipient: recipientsUserData!.recipient,
        sellingConcession: recipientsUserData!.sellingConcession!,
        disclaimer,
      };
    });
};

export const wireTypeConfigNonSyndicateSellingGroupTermsRetention = createWireTypeConfig<
  Values,
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePartsFragment,
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationPartsFragment,
  typeof routeFactory.nonSyndicateWiresSellingGroupTermsRetention
>({
  wireTypeName,
  wireTypeRoute: routeFactory.nonSyndicateWiresSellingGroupTermsRetention,
  wireTemplateType: WireTemplateType.NonSyndicateMemberSellingGroupTermsRetention,

  CreateOrUpdateForm: NonSyndicateSellingGroupTermsRetentionWireForm,
  formValidation: validationSchema,

  useSendIsDisabledReason: createUseDefaultSendIsDisabledReason({
    canBeSentForDraftOffering: true,
  }),
  useManagers: createUseDefaultNonSyndicateManagers(),
  useNextRecipients: createUseDefaultNonSyndicateNextRecipients(),
  useIsWireManagerListOutdated: createUseDefaultIsNonSyndicateWireManagerListOutdated(),
  useOutdatedManagersList: createUseDefaultOutdatedNonSyndicateManagersList(),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } =
      useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery({
        variables: queryArgs,
        fetchPolicy: 'cache-and-network',
      });

    return {
      data: data?.nonSyndicateSellingGroupTermsRetentionWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } =
      useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery({
        variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
        skip: !queryArgs.syndicateWireId,
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
      });

    return {
      data: data && {
        wire: data.nonSyndicateSellingGroupTermsRetentionWireDetails,
        stage: data.syndicateWires.publishedOrPrivateOffering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } =
      useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });

    return {
      data: data?.nonSyndicateSellingGroupTermsRetentionWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
      });

    return {
      data: data?.nonSyndicateSellingGroupTermsRetentionWireValidation,
      isLoading: loading,
      error,
      refetch,
    };
  },
  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateNonSyndicateSellingGroupTermsRetentionWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getNonSyndicateSellingGroupTermsRetentionWireInputData(values),
            },
          },
        });

        if (
          result.data?.createNonSyndicateSellingGroupTermsRetentionWire.__typename ===
          'ServiceError'
        ) {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createNonSyndicateSellingGroupTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateNonSyndicateSellingGroupTermsRetentionWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getNonSyndicateSellingGroupTermsRetentionWireInputData(values),
            },
          },
        });

        if (
          result.data?.updateNonSyndicateSellingGroupTermsRetentionWire.__typename ===
          'ServiceError'
        ) {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateNonSyndicateSellingGroupTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteNonSyndicateSellingGroupTermsRetentionWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (
          result.data?.deleteNonSyndicateSellingGroupTermsRetentionWire.__typename ===
          'ServiceError'
        ) {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendNonSyndicateSellingGroupTermsRetentionWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (
          result.data?.sendNonSyndicateSellingGroupTermsRetentionWire.__typename === 'ServiceError'
        ) {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendNonSyndicateSellingGroupTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    managers,
    wire,
    offeringType,
    offeringId,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const {
      data: retailData,
      loading: retailLoading,
      error: retailError,
    } = useBrokerDealersMyRetailQuery({ offeringId });

    const initialUserData = React.useMemo(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0].disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const initialRecipientUserData =
        managers.map<NonSyndicateSellingGroupTermsRetentionRecipientUserData | null>(manager => {
          const managerValues = wire?.recipientsUserData?.find(
            ({ recipient }) => recipient === manager.cmgEntityKey
          );

          return !managerValues
            ? null
            : {
                recipient: managerValues.recipient,
                sellingConcession: managerValues.sellingConcession,
              };
        });

      const predefinedRecipientUserData =
        managers.map<NonSyndicateSellingGroupTermsRetentionRecipientUserData | null>(manager => {
          return getPredefinedRecipientUserData(
            manager,
            initialRecipientUserData,
            retailData ?? []
          );
        });

      const initialValues: WireTypeConfigInitialValues<Values> = {
        data: { disclaimer, recipientsUserData: initialRecipientUserData },
        predefinedData: { disclaimer, recipientsUserData: predefinedRecipientUserData },
      };

      return initialValues;
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
      retailData,
    ]);

    const isLoading = disclaimerLoading || retailLoading;
    const error = disclaimerError || retailError;

    return {
      isLoading,
      error,
      data: initialUserData.data,
      predefinedData: initialUserData.predefinedData,
    };
  },
});
