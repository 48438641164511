import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { useIndicationForm_UnmarkIndicationAsDuplicateMutation } from '../../../../../../../indication-activity/components/indication-form/components/indication-details/components/dupes/graphql/__generated__';

export const refetchQueries = [getOperationName(InstitutionalDemandGrid_SummaryDocument)!];

export const useUnmarkDuplicate = (
  offeringId: UUID,
  indicationId: UUID,
  duplicateIndicationId: UUID,
  successCallback: () => void
): [() => Promise<void>, boolean] => {
  const [unmarkDuplicate, { loading }] = useIndicationForm_UnmarkIndicationAsDuplicateMutation();

  const handleUnmarkDuplicate = React.useCallback(async () => {
    try {
      await unmarkDuplicate({
        variables: { offeringId, indicationId, duplicateIndicationId },
        awaitRefetchQueries: true,
        refetchQueries,
      });

      SnackbarManager.success('Duplicate indication successfully unmarked');
      successCallback();
    } catch {
      SnackbarManager.error('Failed to unmark indication as duplicate');
    }
  }, [duplicateIndicationId, indicationId, offeringId, successCallback, unmarkDuplicate]);

  return [handleUnmarkDuplicate, loading];
};
