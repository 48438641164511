import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { StyledTable } from './NetDealFeesReconciliationInfo.styles';

export type Props = {
  finalSettlement: number | null;
  totalRevenue: number | null;
  netDealFeesDifference: number | null;
};

const NetDealFeesReconciliationInfo: React.FC<Props> = ({
  finalSettlement,
  totalRevenue,
  netDealFeesDifference,
}) => {
  return (
    <StyledTable gridTemplateColumns="3fr 10px 1fr">
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Final Settlement Calculation
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(finalSettlement, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Total Revenue & Expenses Reconciliation</Table.Cell>
      <Table.Cell paddingSize="S">-</Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(totalRevenue, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} bold withBottomBorder={false}>
        Net Deal Fees Reconciliation
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" bold withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(netDealFeesDifference)}
      </Table.Cell>
    </StyledTable>
  );
};

export default NetDealFeesReconciliationInfo;
