import { numericUtil, Theme } from '@cmg/common';
import { ColDef } from 'ag-grid-community';

import { FinalSettlement_AccountingManagerPartsFragment } from '../../graphql';
import {
  accountingGridExpensesManagerRoleFormatter,
  accountingGridIntegerFormatter,
  accountingGridPercentFormatter,
  createAccountingColumn,
  createAccountingFixedColumn,
  getTotal,
} from '../common/accounting-data-grid/AccountingDataGrid.model';

export const getTotalRow = (rows: readonly FinalSettlement_AccountingManagerPartsFragment[]) => {
  const baseUnderwritingShares = getTotal(rows, 'baseUnderwritingShares');
  const overallotmentUnderwritingShares = getTotal(rows, 'overallotmentUnderwritingShares');

  return {
    id: 'pinned-total-row',
    firmName: 'Total',
    incentiveFeePercentage: getTotal(rows, 'incentiveFeePercentage'),
    managementFeesPercentage: getTotal(rows, 'managementFeesPercentage'),
    baseUnderwritingPercentage: getTotal(rows, 'baseUnderwritingPercentage'),
    potEconomics: getTotal(rows, 'potEconomics'),
    potEconomicsWithOverwrites: getTotal(rows, 'potEconomicsWithOverwrites'),
    designatedShares: getTotal(rows, 'designatedShares'),
    baseUnderwritingShares,
    overallotmentUnderwritingShares,
    underwritingSharesSum: numericUtil.sum(baseUnderwritingShares, overallotmentUnderwritingShares),
    retailRetention: getTotal(rows, 'retailRetention')!,
  };
};

export const underwritingSharesSumGetter = ({
  data: { baseUnderwritingShares, overallotmentUnderwritingShares },
}: {
  data: FinalSettlement_AccountingManagerPartsFragment;
}) => {
  return numericUtil.sum(baseUnderwritingShares, overallotmentUnderwritingShares) ?? '';
};

const createGroupColumn = (groupHeaderName: string, ...columns: ColDef[]) => ({
  headerName: groupHeaderName,
  headerClass: 'ag-header-center',
  children: columns,
});

export const generateColumns = (theme: Theme) => [
  createGroupColumn(
    '',
    createAccountingFixedColumn({
      field: 'managerFirmName',
      headerName: 'Underwriter',
      pinned: 'left',
      theme,
    }),
    createAccountingColumn({
      field: 'managerRole',
      headerName: 'Role',
      valueFormatter: accountingGridExpensesManagerRoleFormatter,
    })
  ),
  createGroupColumn(
    'Sourced from Offering Setup',
    createAccountingColumn({
      field: 'incentiveFeePercentage',
      headerName: 'Incentive Fee %',
      valueFormatter: accountingGridPercentFormatter,
      cellAlign: 'right',
    }),
    createAccountingColumn({
      field: 'managementFeesPercentage',
      headerName: 'Mgmt %',
      valueFormatter: accountingGridPercentFormatter,
      cellAlign: 'right',
    }),
    createAccountingColumn({
      field: 'baseUnderwritingPercentage',
      headerName: 'U/W % (Base)',
      valueFormatter: accountingGridPercentFormatter,
      cellAlign: 'right',
    }),
    createAccountingColumn({
      field: 'potEconomics',
      headerName: 'Pot Economics %',
      valueFormatter: accountingGridPercentFormatter,
      cellAlign: 'right',
    })
  ),
  createGroupColumn(
    'Sourced from Designation Monitor',
    createAccountingColumn({
      field: 'potEconomicsWithOverwrites',
      headerName: 'Pot Economics % (including any over-writes)',
      valueFormatter: accountingGridPercentFormatter,
      cellAlign: 'right',
      minWidth: 350,
    }),
    createAccountingColumn({
      field: 'designatedShares',
      headerName: 'Designated Shares',
      valueFormatter: accountingGridIntegerFormatter,
      cellAlign: 'right',
    })
  ),
  createGroupColumn(
    '',
    createAccountingColumn({
      field: 'baseUnderwritingShares',
      headerName: 'U/W Shares (Base)',
      valueFormatter: accountingGridIntegerFormatter,
      cellAlign: 'right',
    }),
    createAccountingColumn({
      field: 'overallotmentUnderwritingShares',
      headerName: 'U/W Shares (Overallotment)',
      valueFormatter: accountingGridIntegerFormatter,
      cellAlign: 'right',
      minWidth: 250,
    }),
    createAccountingColumn({
      field: 'underwritingSharesSum',
      headerName: 'U/W Shares (Base + Overallotment)',
      valueFormatter: accountingGridIntegerFormatter,
      valueGetter: underwritingSharesSumGetter,
      cellAlign: 'right',
      minWidth: 280,
    }),
    createAccountingColumn({
      field: 'retailRetention',
      headerName: 'Retail Retention',
      valueFormatter: accountingGridIntegerFormatter,
      cellAlign: 'right',
    })
  ),
];
