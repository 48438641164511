import { ApolloError, ServerError } from '@apollo/client';

export const DISMISSED_BANNER_ERRORS_KEY = 'DISMISSED_BANNER_ERRORS_KEY';

export const isServerError = (
  error: ApolloError['networkError']
): error is ServerError & {
  result: { errors: { message: string }[] };
  message: string;
} => {
  if (!error) {
    return false;
  }

  return 'result' in error;
};

export const getNetworkError = (
  error: ApolloError['networkError'],
  prefix: string = ''
): { message: string }[] => {
  if (!error) {
    return [];
  }

  if (isServerError(error)) {
    return error.result.errors.map(err => ({
      message: `${prefix}${error.message}; ${err.message}`,
    }));
  }

  return [{ message: `${error.message}` }];
};

export const transformApolloErrors = (
  errors: { [key: string]: ApolloError[] },
  prefixMessageMap: { [key: string]: string } = {}
) => {
  const finalErrors: { message: string }[] = [];
  Object.keys(errors).forEach(key => {
    const prefix = prefixMessageMap[key] ? `${prefixMessageMap[key]} — ` : '';
    const allErrors = errors[key].reduce<{ message: string }[]>((prev, current) => {
      const clientErrors = current.clientErrors?.map(error => ({
        message: `${prefix}${error.message}`,
      }));
      const graphQLErrors = current.graphQLErrors?.map(error => ({
        message: error.path?.length
          ? `${prefix}${error.message}; ${error.path?.join(', ')}`
          : `${prefix}${error.message}`,
      }));
      const networkErrors = getNetworkError(current.networkError, prefix);

      return [...prev, ...clientErrors, ...graphQLErrors, ...networkErrors].filter(
        (error): error is { message: string } => !!error
      );
    }, []);

    finalErrors.push(...allErrors);
  });
  return finalErrors;
};
