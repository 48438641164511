import { UUID } from '@cmg/common';
import React from 'react';

import { getFeatureToggles } from '../../../../../../../common/config';
import { useCheckDraftAllocationAccess } from '../../../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { InstitutionalDraftSet, InstitutionalFinalSet } from '../../types';
import AllocationsDropdownButton from './allocations-dropdown-buton/AllocationsDropdownButton';

export type Props = {
  offeringId: UUID;
  finalInstitutionalAllocationSet: InstitutionalFinalSet | null;
  draftAllocationSets: readonly InstitutionalDraftSet[];
};

/**
 * Renders the Institutional Demand Allocations Dropdown Menu Button
 */
const InstitutionalDemandAllocationsDropdownButton: React.FC<Props> = ({
  offeringId,
  draftAllocationSets,
  finalInstitutionalAllocationSet,
}) => {
  const { canRead: canReadDraftAllocations } = useCheckDraftAllocationAccess({
    offeringId,
  });
  const { canRead: canReadFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { isDraftAllocationOn } = getFeatureToggles();

  // If the user cannot interact with allocations, return an empty render
  // so that the "Allocations" dropdown button does not appear.
  if (!canReadFinalAllocations && (!canReadDraftAllocations || !isDraftAllocationOn)) {
    return null;
  }

  return (
    <AllocationsDropdownButton
      offeringId={offeringId}
      draftAllocationSets={draftAllocationSets}
      finalInstitutionalAllocationSet={finalInstitutionalAllocationSet}
    />
  );
};

export default InstitutionalDemandAllocationsDropdownButton;
