import React from 'react';
import { useTheme } from 'styled-components/macro';

export const ExclamationIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="status"
    >
      <path
        d="M15.8199 12.9955C16.3326 13.8589 15.6891 14.9383 14.665 14.9383H1.33484C0.308813 14.9383 -0.331798 13.8573 0.179925 12.9955L6.84509 1.76744C7.35806 0.903561 8.64287 0.905126 9.15492 1.76744L15.8199 12.9955ZM8 10.6741C7.29431 10.6741 6.72223 11.2299 6.72223 11.9156C6.72223 12.6012 7.29431 13.157 8 13.157C8.7057 13.157 9.27778 12.6012 9.27778 11.9156C9.27778 11.2299 8.7057 10.6741 8 10.6741ZM6.78687 6.21163L6.99292 9.88208C7.00256 10.0538 7.14873 10.1883 7.32576 10.1883H8.67426C8.85128 10.1883 8.99745 10.0538 9.00709 9.88208L9.21314 6.21163C9.22356 6.02611 9.07153 5.87012 8.88031 5.87012H7.11967C6.92845 5.87012 6.77645 6.02611 6.78687 6.21163Z"
        fill={theme.designSystem.colors.red['300']}
      />
    </svg>
  );
};

export default ExclamationIcon;
