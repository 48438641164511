import { getOperationName } from '@apollo/client/utilities';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../graphql';
import { useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchInstitutionalDemandSummaryGridQuery = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  );

  return [refetchInstitutionalDemandSummaryGridQuery!];
};

export const useSaveDupesMutation = () => {
  return useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
