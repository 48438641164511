import styled from 'styled-components/macro';

type SContainerProps = {
  readonly isNormalIoiType: boolean;
};

export const SContainer = styled.div<SContainerProps>`
  margin: ${({ isNormalIoiType }) => (isNormalIoiType ? '16px 16px 32px 16px' : '16px')};
`;

export const SMessage = styled.p`
  color: ${({ theme }) => theme.text.color.light};
`;

export const SSpinnerContainer = styled.div`
  width: 100%;
  height: 64px;
  margin: 16px 0;
`;
