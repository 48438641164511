import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Recipient as SelectableRecipient } from '../../../common/components/dialogs/select-recipients-dialog/hooks/useSelectRecipients';
import { ManagerRole, NotificationMessageStatus } from '../../../graphql';
import { RegulatoryFilingRecipient } from './useFilingRecipientsDialog.model';

export type Props = Readonly<{
  readonly recipients: RegulatoryFilingRecipient[] | undefined;
}>;

export const useFilingRecipientsDialog = ({ recipients }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectableRecipients, setSelectableRecipients] = useState<
    SelectableRecipient<ManagerRole>[]
  >([]);

  const selectedEnabledRecipientKeys = useMemo(
    () =>
      selectableRecipients.reduce<string[]>((acc, curr) => {
        if (curr.isSelected && !curr.isDisabled) {
          return [...acc, curr.cmgEntityKey];
        }

        return acc;
      }, []),
    [selectableRecipients]
  );

  const isDisabledRule = useCallback((recipient: RegulatoryFilingRecipient) => {
    return (
      !recipient.distributionList.length || recipient.status === NotificationMessageStatus.Sent
    );
  }, []);

  useEffect(() => {
    setSelectableRecipients(
      recipients?.map<SelectableRecipient<ManagerRole>>(recipient => {
        const isDisabled = isDisabledRule(recipient);

        return {
          ...recipient,
          isSelected: !isDisabled || recipient.status === NotificationMessageStatus.Sent,
          isDisabled,
        };
      }) ?? []
    );
  }, [isDisabledRule, recipients]);

  const handleRecipientSelectionChange = useCallback(
    (cmgEntityKeys: string[]) => {
      const nextSelectedRecipients =
        recipients?.map<SelectableRecipient<ManagerRole>>(recipient => ({
          ...recipient,
          isSelected: cmgEntityKeys.includes(recipient.cmgEntityKey),
          isDisabled: isDisabledRule(recipient),
        })) ?? [];

      setSelectableRecipients(nextSelectedRecipients);
    },
    [isDisabledRule, recipients]
  );

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen,
    open,
    close,
  };
};
