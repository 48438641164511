import { useAuth } from '@cmg/auth';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import { getEnvVars } from '../../../config/appSettings';
import Modal from '../../overlays/modal/Modal';
import HubspotForm from './HubspotForm';
import { getHubspotIFrame, setInputValue } from './SupportRequestModal.model';

export type OwnProps = {
  token: string;
  /**
   * Modal window title
   */
  title?: string;
  /**
   * Text area description text
   */
  description?: string;
  /**
   * Custom submit button text
   */
  submitButtonCaption?: string;
  presetCategory?: string;
  presetSubject?: string;
  presetDescription?: string;
};

type Props = InjectedProps & OwnProps;

/**
 * Modal window component to send feedback message to CMG support.
 */
export const SupportRequestModal: React.FC<Props> = ({
  handleHide,
  title = 'Please let us know of any questions or comments',
  presetCategory,
  presetSubject,
  presetDescription,
}) => {
  const { oidcUserEmail, oidcUserGivenName, oidcUserFamilyName } = useAuth();
  const { hubspotSupportFormPortalId, hubspotSupportFormId } = getEnvVars();

  const handleSubmit = () => {
    const hubspotIFrame = getHubspotIFrame();
    setInputValue({
      hubspotIFrame,
      inputSelector: 'input[name="firstname"]',
      value: oidcUserGivenName ?? '',
    });

    setInputValue({
      hubspotIFrame,
      inputSelector: 'input[name="lastname"]',
      value: oidcUserFamilyName ?? '',
    });

    setInputValue({
      hubspotIFrame,
      inputSelector: 'input[name="email"]',
      value: oidcUserEmail ?? '',
    });
  };

  const handleOnFormReady = React.useCallback(() => {
    const hubspotIFrame = getHubspotIFrame();

    if (presetCategory) {
      setInputValue({
        hubspotIFrame,
        inputSelector: 'select[name="TICKET.hs_ticket_category"]',
        value: presetCategory,
      });
    }
    if (presetSubject) {
      setInputValue({
        hubspotIFrame,
        inputSelector: 'input[name="TICKET.subject"]',
        value: presetSubject,
      });
    }
    if (presetDescription) {
      setInputValue({
        hubspotIFrame,
        inputSelector: 'textarea[name="TICKET.content"]',
        value: presetDescription,
      });
    }
  }, [presetCategory, presetSubject, presetDescription]);

  return (
    <Modal show title={title} onHide={handleHide}>
      <Modal.Content>
        <HubspotForm
          portalId={hubspotSupportFormPortalId ?? ''}
          formId={hubspotSupportFormId ?? ''}
          onFormSubmit={handleSubmit}
          onFormReady={handleOnFormReady}
        />
      </Modal.Content>
    </Modal>
  );
};

const name = 'SUPPORT/SUPPORT_REQUEST_MODAL';
export const openSupportRequestModal = (props: OwnProps) => show(name, props);
export const closeSupportRequestModal = () => hide(name);

export default connectModal({ name })(SupportRequestModal);
