import { ApolloError } from '@apollo/client';
import { PaginationQueryParams, Theme, timeUtil } from '@cmg/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import React from 'react';

import {
  PaginationDataInput,
  RoadshowFilterInput,
  RoadshowType,
  SortOrderDirection,
} from '../../../../../graphql';
import { Roadshows_RoadshowLimitedPartsFragment } from '../../../common/graphql';
import RoadshowNameCellRenderer from './roadshow-name-cell-renderer/RoadshowNameCellRenderer';

export const RoadshowTypes = {
  [RoadshowType.Ttw]: 'TTW',
  [RoadshowType.Ecm]: 'ECM',
  [RoadshowType.Pdie]: 'PDIE',
  [RoadshowType.Dcim]: 'DCIM',
  [RoadshowType.NonDeal]: 'Non-Deal',
};

export const formatRoadshowType = (value: ValueFormatterParams) => {
  return RoadshowTypes[value.data?.roadshowType];
};
export const formatRoadshowStartDate = (value: ValueFormatterParams) => {
  return timeUtil.formatAsDisplayDate(value.data.startDate);
};
export const formatRoadshowEndDate = (value: ValueFormatterParams) => {
  return value.data?.endDate ? timeUtil.formatAsDisplayDate(value.data.endDate) : '-';
};
export const formatRoadshowOffering = (value: ValueFormatterParams) => {
  return value.data?.offeringIssuerName ?? '-';
};

export const createColumns = (theme: Theme, defaultPagination: PaginationDataInput): ColDef[] =>
  [
    {
      field: 'roadshowName',
      headerName: 'Project/Name',
      suppressMovable: true,
      sortable: true,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
      cellRendererFramework: ({ value, data }) => (
        <RoadshowNameCellRenderer value={value} data={data} />
      ),
    },
    {
      field: 'roadshowType',
      headerName: 'Type',
      suppressMovable: true,
      sortable: true,
      valueFormatter: formatRoadshowType,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      suppressMovable: true,
      sortable: true,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      suppressMovable: true,
      sortable: true,
      valueFormatter: formatRoadshowStartDate,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      suppressMovable: true,
      sortable: true,
      valueFormatter: formatRoadshowEndDate,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
    {
      field: 'offeringIssuerName',
      headerName: 'Related Offering',
      suppressMovable: true,
      sortable: true,
      valueFormatter: formatRoadshowOffering,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
  ].map((item: ColDef) => {
    if (item.field === defaultPagination.orderField) {
      item.initialSort =
        defaultPagination.orderDirection === SortOrderDirection.Asc ? 'asc' : 'desc';
    }
    return item;
  });

export type RoadshowPaginatedList = {
  data: readonly Roadshows_RoadshowLimitedPartsFragment[];
  loading: boolean;
  error?: ApolloError;
  setFilter: (filterInput: RoadshowFilterInput) => void;
  setPagination: (pagination: PaginationDataInput) => void;
};

export const paginationInitialValues: PaginationDataInput = {
  orderDirection: SortOrderDirection.Desc,
  orderField: 'startDate',
};

export function toPagination(params: PaginationQueryParams): PaginationDataInput {
  if (params.orderField) {
    return {
      orderField: params.orderField,
      orderDirection:
        params.orderDirection === 'asc' ? SortOrderDirection.Asc : SortOrderDirection.Desc,
    };
  } else {
    return {
      orderField: params.orderField,
      orderDirection: null,
    };
  }
}
