import { TextInputField } from '@cmg/common';
import styled from 'styled-components/macro';

import FlexLayout from '../../../../layout/flex-layout/FlexLayout';
import { Divider, Footer } from '../AdvancedInvestorSearchFlowModal.styles';

export const StyledTextInput = styled(TextInputField)`
  min-width: unset;
  width: 250px;
`;

export const AddressTextInput = styled(StyledTextInput)`
  width: 450px;
`;

export const StyledFlexLayout = styled(FlexLayout)`
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;

export const StyledFooter = styled(Footer)`
  justify-content: space-between;
`;
