import { getOperationName } from '@apollo/client/utilities';

import { pendingOrderBookGridForcedRefreshVar } from '../../../../variables';
import {
  InstitutionalDemandGrid_SummaryDocument,
  useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation,
} from '../graphql';

export const refetchQueries = () => {
  const refetchSummaryGridQuery = getOperationName(InstitutionalDemandGrid_SummaryDocument);

  return [refetchSummaryGridQuery!];
};

export const useUpdateBillingAndDeliveryAgent = () => {
  return useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      pendingOrderBookGridForcedRefreshVar(true);
    },
  });
};
