import { Panel, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import routeFactory from '../../../common/util/routeFactory';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useOfferingSetup_Summary_OfferingSummaryQuery } from '../graphql';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import OfferingSummaryView from './view/OfferingSummaryView';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Offering Summary Route for Offering Setup
 */
export const OfferingSummaryRoute: React.FC<RouteProps> = ({ match, history }) => {
  const { offeringId } = match.params;

  const { data, loading, error } = useOfferingSetup_Summary_OfferingSummaryQuery({
    variables: { offeringId: offeringId },
    fetchPolicy: 'network-only',
  });

  const handleEdit = (step: string) => {
    history.push(
      routeFactory.offeringSetup.getUrlPath({
        offeringId,
        stepId: step,
      })
    );
  };

  const { validationErrorsByCategory } = useValidateOffering(offeringId);

  if (loading) {
    return <Spinner show={true} fullHeight={true} />;
  }

  if (error) {
    return (
      <Panel fillViewport>
        <ServerErrorsBanner error={error} />
      </Panel>
    );
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport testId={xcSelectors.offeringSetupSummaryScreen.testId}>
        <OfferingSummaryView
          offering={data!.offering}
          handleEdit={handleEdit}
          validationErrorsByCategory={validationErrorsByCategory}
        />
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default OfferingSummaryRoute;
