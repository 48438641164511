import { useCheckAccountType } from '@cmg/auth';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';
import { useCheckInstitutionalPassiveOrderBookAccess } from './useCheckInstitutionalPassiveOrderBookAccess';

type Props = {
  offeringId: string;
};

type AccessCheckResult = {
  loading: boolean;
  canManageDuplicate: boolean;
  isSellSideAccount: boolean;
};

const emptyArray = [];

/**
 * Checks accessibility of an Institutional Duplicate Indication.
 */
export function useCheckInstitutionalDuplicateAccess({ offeringId }: Props): AccessCheckResult {
  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? emptyArray;
  const isSellSideAccount = useCheckAccountType('SELL_SIDE');
  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });

  const accessChecks = React.useMemo(
    () => ({
      canManageDuplicate:
        isSellSideAccount &&
        !canManagePassiveOrderBook &&
        entitlements.includes('MANAGE:INSTITUTIONAL_DUPLICATE_INDICATION'),
    }),
    [entitlements, isSellSideAccount, canManagePassiveOrderBook]
  );

  return { loading, ...accessChecks, isSellSideAccount };
}

export default useCheckInstitutionalDuplicateAccess;
