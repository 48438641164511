import * as yup from 'yup';

import { Roadshows_EventParticipantsTeamPartsFragment } from '../../../../../../common/graphql';

type InvestorTeamMember = {
  email: string;
  name: string;
};

export type InvestorValues = {
  name: string;
  cmgEntityKey: string;
  members: InvestorTeamMember[];
};

export type Values = {
  investors: InvestorValues[];
};

export const validationSchema = yup.object().shape({
  investors: yup.array().of(
    yup.object().shape({
      cmgEntityKey: yup.string().label('CMG Entity Key').nullable().required(),
      members: yup.array().of(
        yup.object().shape({
          name: yup.string().label('Name').nullable().required(),
          email: yup.string().email().label('Email').nullable().required(),
        })
      ),
    })
  ),
});

export const getInitialValues = (
  investors: readonly Roadshows_EventParticipantsTeamPartsFragment[]
): Values => {
  return {
    investors: investors.map(investorTeam => ({
      name: investorTeam.name,
      members: investorTeam.participants.map(investor => ({
        name: investor.name,
        email: investor.email,
      })),
      cmgEntityKey: investorTeam.cmgEntityKey,
    })),
  };
};
