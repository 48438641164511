import isNil from 'lodash/isNil';

import { ManagerRole } from '../../../../../graphql';

export type EconomicBreakdownNumber = number | null;

export type EconomicBreakdownRowNumbers = {
  incentiveFee: EconomicBreakdownNumber;
  underwritingFee: EconomicBreakdownNumber;
  underwritingWithIncentiveFee: EconomicBreakdownNumber;
  exercisedOverallotmentShares: EconomicBreakdownNumber;
  underwritingShares: EconomicBreakdownNumber;
  managementFee: EconomicBreakdownNumber;
  sellingConcession: EconomicBreakdownNumber;
  totalUnderwritingShares: EconomicBreakdownNumber;
};

export type EconomicBreakdownRowInfo = {
  manager: string;
  managerRole: ManagerRole | null;
  id: string;
};

export type ManagerEconomicBreakdownRow = EconomicBreakdownRowNumbers & EconomicBreakdownRowInfo;

export type AdditionalActionsType = {
  header: string;
  subItems: string[];
};

export const getTotalUnderwritingShares = (
  rows: Record<number, ManagerEconomicBreakdownRow>
): EconomicBreakdownNumber => {
  return Object.values(rows).reduce(
    (acc, row) => acc + (row.exercisedOverallotmentShares ?? 0) + (row.underwritingShares ?? 0),
    0
  );
};

export const generateTotalValues = (
  rows: Record<number, ManagerEconomicBreakdownRow>
): ManagerEconomicBreakdownRow => {
  const getTotalValue = (key: keyof EconomicBreakdownRowNumbers) =>
    Object.values(rows).every(row => row[key] === null)
      ? null
      : Number(
          Object.values(rows)
            .reduce((acc, row) => acc + (row[key] ?? 0), 0)
            .toFixed(6)
        );

  return {
    exercisedOverallotmentShares: getTotalValue('exercisedOverallotmentShares'),
    id: 'total',
    managementFee: getTotalValue('managementFee'),
    incentiveFee: getTotalValue('incentiveFee'),
    manager: '',
    managerRole: null,
    sellingConcession: getTotalValue('sellingConcession'),
    underwritingFee: getTotalValue('underwritingFee'),
    underwritingShares: getTotalValue('underwritingShares'),
    underwritingWithIncentiveFee: getTotalValue('underwritingWithIncentiveFee'),
    totalUnderwritingShares: getTotalUnderwritingShares(rows),
  };
};

export const generateOverlayInfo = ({
  offeringSizeInShares,
  grossSpreadBase,
  baseGrossSpreadAllocation,
  incentiveGrossSpreadAllocation,
  incentiveFee,
}) => {
  const baseGrossSpreadManagementFee = baseGrossSpreadAllocation?.managementFeePercentage;
  const baseGrossSpreadUnderwritingFee = baseGrossSpreadAllocation?.underwritingFeePercentage;
  const baseGrossSpreadSellingConcession = baseGrossSpreadAllocation?.sellingConcessionPercentage;
  const incentiveGrossSpreadManagementFee = incentiveGrossSpreadAllocation?.managementFeePercentage;
  const incentiveGrossSpreadUnderwritingFee =
    incentiveGrossSpreadAllocation?.underwritingFeePercentage;
  const incentiveGrossSpreadSellingConcession =
    incentiveGrossSpreadAllocation?.sellingConcessionPercentage;

  const termsErrors: AdditionalActionsType = { header: 'Terms', subItems: [] };
  const discountsAndFeesErrors: AdditionalActionsType = {
    header: 'U/W Discounts & Fees',
    subItems: [],
  };
  if (isNil(offeringSizeInShares)) {
    termsErrors.subItems.push('Offering Size');
  }

  // Base Gross Spred and Allocation
  if (isNil(grossSpreadBase)) {
    // Base Gross Spread haves priority over allocation
    discountsAndFeesErrors.subItems.push('Base Gross Spread');
  } else {
    // If all fields are null it means is an incomplete allocation
    if (
      isNil(baseGrossSpreadManagementFee) &&
      isNil(baseGrossSpreadUnderwritingFee) &&
      isNil(baseGrossSpreadSellingConcession)
    ) {
      discountsAndFeesErrors.subItems.push('Base Gross Spread Allocation');
    }
  }

  // Incentive Fee Allocation if it is an incomplete allocation and there is an incentive fee we should this error
  if (
    !isNil(incentiveFee) &&
    incentiveFee !== 0 &&
    isNil(incentiveGrossSpreadManagementFee) &&
    isNil(incentiveGrossSpreadUnderwritingFee) &&
    isNil(incentiveGrossSpreadSellingConcession)
  ) {
    discountsAndFeesErrors.subItems.push('Incentive Fee Allocation');
  }

  const showOverlay = !!(termsErrors.subItems.length || discountsAndFeesErrors.subItems.length);

  return { termsErrors, discountsAndFeesErrors, showOverlay };
};
