import { IconButton, TextInputField } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

import InvestorFirmsSearch from '../../../../../../../../common/components/form/investor-firm-search/InvestorFirmSearch';
import FlexLayout from '../../../../../../../../common/components/layout/flex-layout/FlexLayout';

export const StyledForm = styled(Form)`
  flex-grow: 1;
  overflow: auto;
`;

export const StyledInvestorSearch = styled(InvestorFirmsSearch)`
  flex-grow: 1;
  margin-right: 8px;
`;

export const SMemberHeaderCell = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.text.color.light};
  padding: 5px 0;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;

export const SMemberList = styled.div`
  margin: 0 10px;
`;

export const StyledMemberRow = styled(FlexLayout)`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};

  &:last-of-type {
    border: none;
  }
`;

export const StyledTextInputField = styled(TextInputField)`
  flex: 1;
  margin: 10px 5px;
`;

export const SDeleteHeaderCell = styled.div`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
  width: 45px;
`;

export const StyledDeleteIconButton = styled(IconButton)`
  width: 45px;
`;

export const StyledFlexLayout = styled(FlexLayout)`
  margin-bottom: 16px;
`;
