import { numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import MandatoryAsterisk from '../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { StyledFooterRow } from '../../ExpensesRoute.styles';
import { ExpensesValues, getDealRelatedExpensesTotals } from '../../form/ExpensesForm.model';

export type Props = {
  editable: boolean;
};

const DealRelatedExpensesTableHeader: React.FC<Props> = ({ editable }) => {
  const { values } = useFormikContext<ExpensesValues>();

  const totalExpenses = getDealRelatedExpensesTotals(values.dealRelatedExpenses);

  return (
    <React.Fragment>
      <Table.TableHeaderCell>
        Category
        <MandatoryAsterisk />
      </Table.TableHeaderCell>
      <Table.TableHeaderCell>Detailed Description</Table.TableHeaderCell>
      <Table.TableHeaderCell align="right">
        Total
        <MandatoryAsterisk />
      </Table.TableHeaderCell>
      {editable && <Table.TableHeaderCell />}
      <StyledFooterRow>
        <Table.Cell colSpan={2} bold>
          Total
        </Table.Cell>
        <Table.Cell align="right" bold>
          {numericUtil.getDisplayValueForCurrency(totalExpenses)}
        </Table.Cell>
        {editable && <Table.Cell />}
      </StyledFooterRow>
    </React.Fragment>
  );
};

export default DealRelatedExpensesTableHeader;
