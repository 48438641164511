import { useAuth } from '@cmg/auth';
import { useEffect, useMemo } from 'react';

import { FilingType, OfferingStatus } from '../../../../../graphql';
import {
  MyOrders_PublishedOfferingsList_OfferingFullPartsFragment,
  useMyOrders_PublishedOfferingsListQuery,
  useWithMyOrders_CoveredIndicationChangedSubscription,
} from '../../../../my-orders/graphql/__generated__';
import { MY_ORDERS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT } from '../../../graphql/constants';
import { matchesCoveredIndicationChangePattern } from '../../../graphql/types/CoveredIndicationChanged';
import { MyOrdersFilters } from '../../../hooks/useFilters';
import { PricedAndLiveOfferings } from '../MyOrdersGrid.model';

export const mapPublishedOfferingToOfferingLimited = (
  publishedOffering: MyOrders_PublishedOfferingsList_OfferingFullPartsFragment
): PricedAndLiveOfferings => ({
  id: publishedOffering.id,
  isExecutingOnPlatform: publishedOffering.isExecutingOnPlatform,
  issuerName: publishedOffering.issuer.name,
  type: publishedOffering.type,
  status: publishedOffering.status,
  pricingDate: publishedOffering.timing.pricingDate,
  finalFilingOfferPrice: publishedOffering.terms.filings.find(
    ({ filingType }) => filingType === FilingType.Final
  )?.offerPrice,
  pricingCurrencyCode: publishedOffering.pricingCurrencyCode,
  managers: publishedOffering.syndicate.managers.map(
    ({ firmName, cmgEntityKey, firmNameAbbreviation }) => ({
      firmName,
      cmgEntityKey,
      firmNameAbbreviation,
    })
  ),
  syndicateEntitlements: publishedOffering.syndicate.managers.map(
    ({ cmgEntityKey, entitlements }) => ({
      cmgEntityKey,
      entitlements: entitlements ?? [],
    })
  ),
  pricingInstrumentCountryCode: publishedOffering.instruments.find(
    ({ id }) => id === publishedOffering.pricingInstrumentId
  )?.countryCode,
});

export const useMyOrdersPublishedOfferingsQuery = ({
  shouldSkip,
  pricingDateFilters,
  isOrderBookMyOrderGridSubscriptionOn,
}: {
  pricingDateFilters: MyOrdersFilters['pricingDate'];
  isOrderBookMyOrderGridSubscriptionOn: boolean;
  shouldSkip: boolean;
}) => {
  const { oidcUserCmgEntityKey } = useAuth();

  let publishedOfferingsListQueryResult = useMyOrders_PublishedOfferingsListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: 0,
      take: MY_ORDERS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT,
      includeTestOfferings: true,
      where: {
        syndicate: { managers: { some: { cmgEntityKey: { eq: oidcUserCmgEntityKey } } } },
        or: [
          {
            status: {
              eq: OfferingStatus.Live,
            },
          },
          {
            status: {
              eq: OfferingStatus.Priced,
            },
            timing: {
              pricingDate: {
                gte: pricingDateFilters.start,
                lte: pricingDateFilters.end,
              },
            },
          },
        ],
      },
    },
    skip: shouldSkip,
  });

  publishedOfferingsListQueryResult = useWithMyOrders_CoveredIndicationChangedSubscription(
    publishedOfferingsListQueryResult,
    {
      isEnabled: isOrderBookMyOrderGridSubscriptionOn && !shouldSkip,
      loadingStateBehavior: 'false-on-refetch',
      updateQuery:
        refetch =>
        (prev, { subscriptionData: { data } }) =>
          matchesCoveredIndicationChangePattern(data, [
            'OrderBookNotIndicationChange',
            'OrderBookIndicationChange',
            'AttestationChange',
          ])
            ? refetch(prev)
            : prev,
    }
  );

  const { data, loading, error, called, fetchMore, refetch } = publishedOfferingsListQueryResult;

  useEffect(
    function fetchRemainingPaginatedData() {
      if (!data?.publishedOfferings) {
        return;
      }

      const { pageInfo, items } = data.publishedOfferings;

      if (items && pageInfo.hasNextPage) {
        fetchMore({
          variables: { skip: items.length },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult.publishedOfferings?.items) {
              return prev;
            }

            return {
              ...prev,
              privateOfferings: {
                ...prev.publishedOfferings,
                ...fetchMoreResult.publishedOfferings,
                items: [
                  ...(prev.publishedOfferings?.items ?? []),
                  ...fetchMoreResult.publishedOfferings.items,
                ],
              },
            };
          },
        });
      }
    },
    [data?.publishedOfferings, fetchMore]
  );

  return useMemo(
    () => ({
      publishedOfferingsError: error,
      isPublishedOfferingsLoading: loading,
      hasCalledPublishedOfferings: called,
      publishedOfferings:
        data?.publishedOfferings?.items?.map(mapPublishedOfferingToOfferingLimited) ?? [],
      refetchPublishedOfferings: refetch,
    }),
    [data?.publishedOfferings?.items, error, loading, called, refetch]
  );
};
