import { Skeleton } from '@cmg/design-system';
import React from 'react';

import { ProspectusDeliveryStatus } from '../../../../../../../graphql';
import ProspectusDeliveryStatusComponent from '../../../../../components/prospectus-delivery-status/ProspectusDeliveryStatus';
import { IndicationWithDemandLevels } from '../../types';

export type Props = {
  data: IndicationWithDemandLevels;
  context: { loadingProspectusData?: boolean };
};

const ProspectusDeliveryStatusCell: React.FC<Props> = ({ data, context }) => {
  const {
    overallDeliveryStatus = ProspectusDeliveryStatus.NotSent,
    sentEmailCount = 0,
    totalEmailCount = 0,
  } = data.prospectusDeliveryStatus ?? {};

  if (context.loadingProspectusData) {
    return <Skeleton role="loading" variant="rectangular" height={10} />;
  }

  return (
    <ProspectusDeliveryStatusComponent
      overallDeliveryStatus={overallDeliveryStatus}
      sentEmailCount={sentEmailCount}
      totalEmailCount={totalEmailCount}
    />
  );
};

export default ProspectusDeliveryStatusCell;
