import { Step } from '../../../../../../../common/components/navigation/side-nav/SideNav';

export enum LogisticsNavigationSteps {
  DETAILS = 'DETAILS',
  PARTICIPANTS = 'PARTICIPANTS',
}

export const logisticsNavigationSteps: Step[] = [
  {
    label: 'Logistics Details',
    id: LogisticsNavigationSteps.DETAILS,
  },
  {
    label: 'Participants',
    id: LogisticsNavigationSteps.PARTICIPANTS,
  },
];
