import { Alert, IconButton, numericUtil } from '@cmg/common';
import React from 'react';

export type Props = {
  onDismiss: () => void;
  tradesCount: number;
};

export const StatusNotChangedAlert: React.FC<Props> = ({ onDismiss, tradesCount }) => {
  const tradeDisplayNumber = numericUtil.getDisplayValueForNumber(tradesCount, 0);

  return (
    <Alert
      severity="error"
      withMargin={true}
      action={<IconButton icon={{ name: 'times', size: 'lg' }} onClick={onDismiss} />}
    >
      <Alert.Title>{`Status not changed for ${tradeDisplayNumber} ${
        tradesCount > 1 ? 'trades' : 'trade'
      }`}</Alert.Title>
      <Alert.Details>Trade release status does not allow this action.</Alert.Details>
    </Alert>
  );
};
