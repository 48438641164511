import {
  Icon,
  LinkButton,
  ODSTable as Table,
  SwitchField,
  TextInputField,
  ToastManager,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useDebouncedCallback } from 'use-debounce';

import { useRoadshows_RoadshowManagementTeamLazyQuery } from '../../../graphql';
import { useCreateTeamMemberMutation } from './hooks/useCreateTeamMemberMutation';
import { useDeleteTeamMemberMutation } from './hooks/useDeleteTeamMemberMutation';
import { useUpdateTeamMemberMutation } from './hooks/useUpdateTeamMemberMutation';
import {
  getValidate,
  ManagementTeamMember,
  mapFormToInput,
} from './ManagementTeamMemberForm.model';
import {
  StyledCenteredCell,
  StyledForm,
  StyledStatusCell,
} from './ManagementTeamMemberForm.styles';

export type Props = {
  teamMember: ManagementTeamMember;
  roadshowId: string;
  onDelete: () => void;
};

const ManagementTeamMemberForm: React.FC<Props> = ({ teamMember, roadshowId, onDelete }) => {
  const [managementTeamQuery] = useRoadshows_RoadshowManagementTeamLazyQuery({
    variables: { roadshowId },
  });
  const [createTeamMember, createStatus] = useCreateTeamMemberMutation(roadshowId);
  const [updateTeamMember, updateStatus] = useUpdateTeamMemberMutation();
  const [deleteTeamMember, deleteStatus] = useDeleteTeamMemberMutation(roadshowId);

  const [savedMemberId, setSavedMemberId] = React.useState(teamMember.id);
  const [hasFocus, setHasFocus] = React.useState(false);

  const theme = useTheme();

  const handleUpdateTeamMember = async (values: ManagementTeamMember) => {
    const variables = {
      roadshowId,
      teamMemberId: savedMemberId!,
      payload: mapFormToInput(values),
    };

    try {
      await updateTeamMember({ variables });
    } catch {
      ToastManager.error('Failed to update team member');
    }
  };

  const handleCreateTeamMember = async (values: ManagementTeamMember) => {
    const variables = { roadshowId, payload: mapFormToInput(values) };

    try {
      const { data } = await createTeamMember({ variables });
      setSavedMemberId(data!.createRoadshowManagementTeamMember.id);
    } catch {
      ToastManager.error('Failed to create team member');
    }
  };

  const formik = useFormik<ManagementTeamMember>({
    initialValues: teamMember,
    validate: values => getValidate(values, managementTeamQuery, savedMemberId),
    onSubmit: async values => {
      if (savedMemberId) {
        await handleUpdateTeamMember(values);
      } else {
        await handleCreateTeamMember(values);
      }
    },
  });

  const debouncedSubmit = useDebouncedCallback(() => {
    if (!hasFocus && formik.dirty) {
      formik.handleSubmit();
    }
    // switching focus between inputs of the same form triggers blur (for prev element) and focus (for new element)
    // this event is propagated to their parent, so simple submit upon 'onBlur' would not work as desired, since user
    // has not left form yet
  }, 100);

  const handleOnBlur = () => {
    setHasFocus(false);
    debouncedSubmit();
  };
  const handleOnFocus = () => {
    setHasFocus(true);
  };

  const handleDelete = async () => {
    if (savedMemberId) {
      try {
        await deleteTeamMember({ variables: { teamMemberId: savedMemberId, roadshowId } });
        onDelete();
      } catch {
        ToastManager.error('Failed to delete team member');
      }
    } else {
      // newly added but not yet saved entity
      onDelete();
    }
  };

  const isLoading = createStatus.loading || updateStatus.loading || deleteStatus.loading;

  const isRecentlySaved = !!(
    (createStatus.called && createStatus.data) ||
    (updateStatus.called && updateStatus.data)
  );

  return (
    <FormikProvider value={formik}>
      <StyledForm onBlur={handleOnBlur} onFocus={handleOnFocus}>
        <Table.Row>
          <StyledCenteredCell>
            <SwitchField name="isParticipating" fullWidth />
          </StyledCenteredCell>
          <Table.Cell>
            <TextInputField name="name" fullWidth />
          </Table.Cell>
          <Table.Cell>
            <TextInputField name="title" fullWidth />
          </Table.Cell>
          <Table.Cell>
            <TextInputField name="mobilePhone" fullWidth />
          </Table.Cell>
          <Table.Cell>
            <TextInputField name="officePhone" fullWidth />
          </Table.Cell>
          <Table.Cell>
            <TextInputField name="email" fullWidth />
          </Table.Cell>
          <StyledStatusCell>
            {isRecentlySaved && !isLoading && (
              <Icon
                name="check-circle"
                variant="regular"
                size="lg"
                className="saved-state-icon"
                color={theme.text.color.positive}
              />
            )}
            {isLoading && <Icon name="spinner-third" variant="light" size="lg" spin />}
            <LinkButton
              title="Delete Team Member"
              iconLeft={{ name: 'trash-alt', size: 'lg' }}
              inline
              onClick={handleDelete}
            />
          </StyledStatusCell>
        </Table.Row>
      </StyledForm>
    </FormikProvider>
  );
};

export default ManagementTeamMemberForm;
