import { UUID } from '@cmg/common';
import React from 'react';

// The purpose of this context is to abstract AgGrid's API from the component that needs to
// call AgGrid api. Right now we're passing grid reference to child/sibling components and
// those components call api functions directly. This is not ideal because it makes those components
// directly depends on grid api. This context will allow us to abstract that dependency and make
// it easier to access grid api from any component that needs it.
export type OnDuplicateProps = { survivorId: UUID };
export type OnDuplicateDelegate = (props: OnDuplicateProps) => void;

export type InstitutionalDemandGridReferenceContextType = {
  onDuplicate: OnDuplicateDelegate;
  setOnDuplicate: React.Dispatch<React.SetStateAction<OnDuplicateDelegate>>;
};

export const InstitutionalDemandGridReferenceContext =
  React.createContext<InstitutionalDemandGridReferenceContextType>({
    onDuplicate: () => {},
    setOnDuplicate: () => {},
  });

export const useInstitutionalDemandGridReferenceContext = () => {
  const context = React.useContext(InstitutionalDemandGridReferenceContext);

  if (context === undefined) {
    throw new Error('No Provider found for InstitutionalDemandGridReferenceContext');
  }

  return context;
};

export const InstitutionalDemandGridReferenceContextProvider: React.FC = ({ children }) => {
  const [onDuplicate, setOnDuplicate] = React.useState<OnDuplicateDelegate>(() => {});

  return (
    <InstitutionalDemandGridReferenceContext.Provider value={{ onDuplicate, setOnDuplicate }}>
      {children}
    </InstitutionalDemandGridReferenceContext.Provider>
  );
};
