import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { expensesCategoryLabels } from '../../../../../types/domain/final-settlement/constants';
import { StyledCell, StyledRow } from '../../ExpensesRoute.styles';
import { DealRelatedExpense } from '../../form/ExpensesForm.model';

export type Props = {
  expense: DealRelatedExpense;
};

const ReadOnlyDealRelatedExpenseRow: React.FC<Props> = ({ expense }) => {
  return (
    <StyledRow suppressHighlightingOnHover isFocused={false}>
      <Table.Cell>{expensesCategoryLabels[expense.category!]}</Table.Cell>
      <Table.Cell>{expense.description}</Table.Cell>
      <StyledCell bold>{numericUtil.formatCurrency(expense.value)}</StyledCell>
    </StyledRow>
  );
};

export default ReadOnlyDealRelatedExpenseRow;
