import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { OfferingStatus, OrderBookState } from '../../../graphql';
import { OrderBookPageLayout } from '../components/order-book-page-layout/OrderBookPageLayout';
import { UpdateOrderBookStatusModal } from '../components/update-orderbook-status-modal/UpdateOrderBookStatusModal';
import { useUpdateOrderBookStatus } from '../hooks/useUpdateOrderBookStatus';
import ReleaseFinalAllocationDialog from '../institutional-demand-v2/dialogs/ReleaseFinalAllocationDialog';
import ComplianceAllocationReleaseModal from './components/compliance-allocation-release-modal/ComplianceAllocationReleaseModal';
import { InstitutionalDemandPreferencesProvider } from './components/institutional-demand-grid/context/InstitutionalDemandPreferencesContext';
import InstitutionalDemandContainer from './components/institutional-demand-grid/InstitutionalDemandContainer';
import UpdateFinalInstitutionalAllocationsModal from './components/update-final-institutional-allocations-modal/UpdateFinalInstitutionalAllocationsModal';
import { FinalAllocationsProvider } from './contexts/finalAllocationsContext';
import { useInstitutionalDemand_InstitutionalDemandOfferingTypeQuery } from './graphql';
import { useSubNavActions } from './hooks/useSubNavActions';

export type Props = Readonly<{
  issuerName?: string;
  offeringId: UUID;
  offeringStatus?: OfferingStatus;
  orderBookState?: OrderBookState;
  isFinalAllocationSetReleased: boolean;
  isVisibleToNonSyndicate: boolean;
}>;

/**
 * Route for the Order Book Institutional Demand Page
 */
export const InstitutionalDemandRoute: React.FC<Props> = ({
  offeringId,
  issuerName,
  offeringStatus,
  orderBookState,
  isFinalAllocationSetReleased,
  isVisibleToNonSyndicate,
}) => {
  useDocumentTitle(
    routeFactory.orderBookInstitutionalDemand.getDocumentTitle({
      issuerName,
    })
  );

  const { data: dataOfferingType, error: errorOfferingType } =
    useInstitutionalDemand_InstitutionalDemandOfferingTypeQuery({
      variables: { offeringId },
    });

  const offeringType = dataOfferingType?.publishedOffering.type;

  const {
    loading: isUpdateOrderBookStatusLoading,
    isUpdateOrderBookStatusModalOpen,
    handleOnUpdateOrderBookStatus,
    handleOpenUpdateOrderBookStatusModal,
    handleCloseUpdateOrderBookStatusModal,
  } = useUpdateOrderBookStatus({ offeringId });

  const subNavActions = useSubNavActions({
    offeringId,
    offeringStatus,
    orderBookState,
    isFinalAllocationSetReleased,
    isUpdateOrderBookStatusLoading,
    onUpdateOrderBookStatus: handleOpenUpdateOrderBookStatusModal,
    offeringName: issuerName,
    isVisibleToNonSyndicate,
  });

  return (
    <OrderBookPageLayout subNavigationProps={{ offeringId, actions: subNavActions }}>
      <FlexLayout
        data-test-id={xcSelectors.institutionalDemandScreen.testId}
        expand
        direction="column"
      >
        {errorOfferingType && <ServerErrorsBanner error={errorOfferingType} />}
        <FinalAllocationsProvider>
          <InstitutionalDemandPreferencesProvider offeringId={offeringId}>
            <InstitutionalDemandContainer offeringType={offeringType} />
          </InstitutionalDemandPreferencesProvider>
          <UpdateFinalInstitutionalAllocationsModal />
          <ComplianceAllocationReleaseModal />
          <ReleaseFinalAllocationDialog />
          <UpdateOrderBookStatusModal
            open={isUpdateOrderBookStatusModalOpen}
            onClose={handleCloseUpdateOrderBookStatusModal}
            onUpdateOrderBookStatus={handleOnUpdateOrderBookStatus}
            orderBookState={orderBookState}
          />
        </FinalAllocationsProvider>
      </FlexLayout>
    </OrderBookPageLayout>
  );
};

export default React.memo(InstitutionalDemandRoute);
