import styled from 'styled-components/macro';

import Column from '../../../../../../../../common/components/layout/grid-layout/Column';

export const StyledTitleColumn = styled(Column)`
  color: ${({ theme }) => theme.text.color.light};
`;

export const StyledValueColumn = styled(Column)`
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SDetailsWrapper = styled.div`
  & > * {
    margin: 8px 0;
  }
`;
