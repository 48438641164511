import styled from 'styled-components/macro';

export const SInvestorRenderWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledOnPlatform = styled.img`
  height: 11.31px;
  width: 12px;
  margin: 0px 4px 0px 4px;
`;

export const StyledInvestorName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledInvestorNameLinkWithoutLogo = styled.span`
  margin-left: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
