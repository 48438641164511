import { useAccessToken } from '@cmg/auth';
import {
  FlexContainer,
  LinkButton,
  Popover,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openSupportRequestModal } from '../../../../../../../../common/components/modals/support-request-modal/SupportRequestModal';
import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import SalesCreditsBankInvestorKeyTable from './bank-investor-key-table/SalesCreditsBankInvestorKeyTable';
import { useSalesCredits_UpdateBankInvestorKeyMutation } from './graphql';
import SalesCreditsInvestorKeyInfoBanner from './investor-key-info-banner/SalesCreditsInvestorKeyInfoBanner';
import {
  SPopoverSmallText,
  SPopoverText,
  SSubText,
} from './SalesCreditsUpdateCrmBankInvestorKeyModal.styles';

export type Props = {
  show: boolean;
  onHide: () => void;
  offeringId: UUID;
  firmCmgEntityKey: UUID;
  firmName: string;
  firmId: UUID | null;
  firmAddress: string | null;
  firmBankInvestorKey: string | null;
};

const SalesCreditsUpdateCrmBankInvestorKeyModal: React.FC<Props> = ({
  show,
  onHide,
  offeringId,
  firmCmgEntityKey,
  firmName,
  firmId,
  firmAddress,
  firmBankInvestorKey,
}) => {
  const token = useAccessToken();
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = React.useState<UUID | null>(firmId);

  const [updateKey, { loading: isUpdating }] = useSalesCredits_UpdateBankInvestorKeyMutation();

  const handleSaveBankInvestorKey = async () => {
    try {
      await updateKey({
        variables: {
          offeringId,
          cmgEntityKey: firmCmgEntityKey,
          firmId: selectedId!,
        },
      });

      onHide();
      ToastManager.success('Successfully updated bank investor key');
    } catch {
      ToastManager.error('Failed to update bank investor key');
    }
  };

  const handleOpenSupportModal = () => {
    token && dispatch(openSupportRequestModal({ token }));
  };

  const handleCloseModal = () => {
    setSelectedId(firmId);

    onHide();
  };

  return (
    <Modal show={show} size="large" title="Select CRM Bank Investor Key">
      <Modal.Content>
        <SalesCreditsInvestorKeyInfoBanner />

        <SSubText>
          Please select the correct CRM Bank Investor Key you would like to use.{' '}
          <Popover
            variant="DARK"
            arrowPointAtCenter
            content={() => (
              <div>
                <div>
                  Please contact
                  <LinkButton onClick={handleOpenSupportModal}>CMG Support</LinkButton>if the bank
                  investor key you need is missing.
                </div>
                <SPopoverSmallText>
                  The bank investor key could be missing because it is not mapped to the right
                  investor or needs to be added to your CRM data.
                </SPopoverSmallText>
              </div>
            )}
          >
            <SPopoverText>Don't see the right investor key?</SPopoverText>
          </Popover>
        </SSubText>

        <SalesCreditsBankInvestorKeyTable
          firmCmgEntityKey={firmCmgEntityKey}
          firmName={firmName}
          firmId={firmId}
          firmBankInvestorKey={firmBankInvestorKey}
          firmLocation={firmAddress}
          selectedFirmId={selectedId}
          onFirmChange={setSelectedId}
        />
      </Modal.Content>

      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={8}>
          <PrimaryButton
            loading={isUpdating}
            disabled={!selectedId}
            onClick={handleSaveBankInvestorKey}
          >
            Save
          </PrimaryButton>
          <SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesCreditsUpdateCrmBankInvestorKeyModal;
