import * as yup from 'yup';

import { OfferingNoteSubject } from '../../../../../graphql';

export const OfferingNoteFormValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .typeError('Please select a Topic')
    .required('Please select a Topic')
    .oneOf(Object.values(OfferingNoteSubject)),
  note: yup.string().typeError('Please enter a Note').required('Please enter a Note'),
});
