import { UUID } from '@cmg/common';
import React from 'react';

import Column from '../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../common/components/layout/grid-layout/GridLayout';
import { FinalSettlement_AccountingPartsFragment } from '../graphql';
import AccountingDetailsHeader from './accounting-details-header/AccountingDetailsHeader';
import { StyledColumn } from './AccountingDetails.styles';
import { AccountingContextProvider } from './common/accounting-context/AccountingContext';
import DealLayoutPanel from './deal-layout-panel/DealLayoutPanel';
import EconomicsBreakdownPanel from './economics-breakdown-panel/EconomicsBreakdownPanel';
import ExpensesPanel from './expenses-panel/ExpensesPanel';
import FinalSettlementPanel from './final-settlement-panel/FinalSettlementPanel';
import GrossSpreadPanel from './gross-spread-panel /GrossSpreadPanel';
import NetDealFeesPanel from './net-deal-fees-panel/NetDealFeesPanel';
import NotesPanel from './notes-panel/NotesPanel';
import StabilizationPanel from './stabilization-panel/StabilizationPanel';

export type Props = {
  accounting: FinalSettlement_AccountingPartsFragment;
  offeringId: UUID;
  offeringIssuerName: string;
};

const AccountingDetails: React.FC<Props> = ({ accounting, offeringId, offeringIssuerName }) => {
  return (
    <div>
      <AccountingContextProvider accounting={accounting}>
        <AccountingDetailsHeader offeringId={offeringId} />
        <GridLayout>
          <Column span={4}>
            <NotesPanel />
          </Column>
          <StyledColumn span={8}>
            <NetDealFeesPanel />
          </StyledColumn>
        </GridLayout>
        <GridLayout>
          <Column span={4}>
            <DealLayoutPanel />
          </Column>
          <StyledColumn span={8}>
            <GrossSpreadPanel />
          </StyledColumn>
        </GridLayout>
        <GridLayout>
          <Column span={4}>
            <ExpensesPanel />
          </Column>
          <StyledColumn span={8}>
            <StabilizationPanel />
          </StyledColumn>
        </GridLayout>
        <EconomicsBreakdownPanel />
        <FinalSettlementPanel offeringId={offeringId} offeringIssuerName={offeringIssuerName} />
      </AccountingContextProvider>
    </div>
  );
};

export default AccountingDetails;
