import { ValidationStep } from '../wire-validation/WireValidation.model';

const templateFieldsOrder: string[] = [
  ValidationStep.TERMS_INITIAL,
  ValidationStep.TERMS,
  ValidationStep.TERMS_FINAL,
];

type ComparedEntry = [string, string[]];

export const managersValidationSortFn: (A: ComparedEntry, B: ComparedEntry) => number = (
  [A],
  [B]
) => {
  // Economics has to be the last item

  if (A === ValidationStep.ECONOMICS) {
    return 1;
  }

  if (B === ValidationStep.ECONOMICS) {
    return -1;
  }

  return 0;
};

export const offeringValidationSortFn: (A: ComparedEntry, B: ComparedEntry) => number = (
  [A],
  [B]
) => {
  return templateFieldsOrder.indexOf(A) - templateFieldsOrder.indexOf(B);
};
