import { numericUtil } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { MdlAllocationRanking } from '../../../../../../../../graphql';
import AllocationRanking from '../../../../../../components/allocation-ranking/AllocationRanking';
import { CommunicatedDemandForm_MdlAllocationPartsFragment } from '../../graphql';
import { FormValues } from './Allocation.model';
import {
  SAllocationContainer,
  SAllocationHeader,
  SAllocationRow,
  SColumn,
  SSharesColumn,
} from './Allocation.styles';
import AllocationSharesField from './components/allocation-shares-field/AllocationSharesField';
import AllocationTableHeader from './components/allocation-table-header/AllocationTableHeader';

export type Props = {
  isEditing?: boolean;
  allocation?: CommunicatedDemandForm_MdlAllocationPartsFragment | null;
  onChangeAllocationRanking: (ranking: MdlAllocationRanking) => void;
};

const Allocation: React.FC<Props> = ({
  isEditing = false,
  allocation,
  onChangeAllocationRanking,
}) => {
  const { setFieldValue } = useFormikContext<FormValues>();

  const investment = allocation?.investment || null;
  const shares = allocation?.shares;

  React.useEffect(() => {
    if (isEditing && !allocation) {
      setFieldValue('allocation.shares', null);
    }
  }, [isEditing, allocation, setFieldValue]);

  return (
    <SAllocationContainer>
      <SAllocationHeader>Allocation</SAllocationHeader>
      <AllocationTableHeader isEditing={isEditing} shares={shares} />
      <SAllocationRow>
        <SSharesColumn align="left">
          <AllocationSharesField isEditing={isEditing} shares={shares} />
        </SSharesColumn>
        {shares != null && !isEditing ? (
          <SColumn align="left" data-test-id="invesment-currency">
            {investment ? numericUtil.formatCurrency(investment) : 'N/A'}
          </SColumn>
        ) : null}
      </SAllocationRow>
      {allocation && <AllocationRanking onChangeAllocationRanking={onChangeAllocationRanking} />}
    </SAllocationContainer>
  );
};

export default Allocation;
