import { Alert, ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../../../../common/components/overlays/modal/Modal';
import { SList } from '../../../../../common/components/delete-deal-team-modal/DeleteDealTeamModal.styles';
import { useRoadshows_ParticipatedRoadshowNamesQuery } from './graphql';

export type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dealTeamMemberId: string;
  dealTeamId: string;
};

const DeleteDealTeamMemberModal: React.FC<Props> = ({
  show,
  onClose,
  onConfirm,
  dealTeamMemberId,
  dealTeamId,
}) => {
  const { data, loading, error } = useRoadshows_ParticipatedRoadshowNamesQuery({
    variables: { cmgEntityKey: dealTeamId, memberId: dealTeamMemberId },
  });

  return (
    <Modal show={show} title="Delete Deal Team Member" onHide={onClose}>
      <Modal.Content>
        <Alert severity="warning">
          By deleting Deal Team member you will be removing this member from database and all
          related Roadshows
        </Alert>
        {error && <ServerErrorsBanner error={error} />}
        {loading && <Loading />}
        {data && data.dealTeamMemberRoadshowNames?.participatedRoadshowNames?.length > 0 && (
          <React.Fragment>
            <p>The following projects would be affected:</p>
            <SList>
              {data.dealTeamMemberRoadshowNames.participatedRoadshowNames.map(roadshowName => (
                <li key={roadshowName}>{roadshowName}</li>
              ))}
            </SList>
          </React.Fragment>
        )}
        <p>This action can't be reverted. Would you like to proceed?</p>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right">
          <SecondaryButton
            testId={xcSelectors.cancelDeleteDealTeamMemberButton.testId}
            onClick={onClose}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.confirmDeleteDealTeamMemberButton.testId}
            onClick={onConfirm}
          >
            Confirm
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDealTeamMemberModal;
