import { FlexContainer } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import GrossSpreadTable from './gross-spread-table/GrossSpreadTable';
import { StyledFlex } from './GrossSpreadPanel.styles';
import NetPriceInfoBox from './net-price-info-box/NetPriceInfoBox';
import TotalGrossSpreadInfoBox from './total-gross-spread-info-box/TotalGrossSpreadInfoBox';

const GrossSpreadPanel: React.FC = () => {
  return (
    <Panel>
      <Panel.Header title="Gross Spread" />
      <Panel.Content>
        <FlexContainer direction="column" gap={16}>
          <StyledFlex direction="row" gap={16}>
            <NetPriceInfoBox />

            <TotalGrossSpreadInfoBox />
          </StyledFlex>

          <GrossSpreadTable />
        </FlexContainer>
      </Panel.Content>
    </Panel>
  );
};

export default GrossSpreadPanel;
