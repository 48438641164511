import React from 'react';

import {
  SActions,
  SCard,
  SCardHeader,
  SColumn,
  SField,
  SForm,
  SFormContent,
  SFormHeader,
  SFormHeaderTitle,
  SRow,
  STable,
  STableHeader,
  StyledPanel,
} from './OfferingSetupForm.styles';

type Props = {
  testId?: string;
  title?: string;
  headerContent?: () => React.ReactNode;
};

export const OfferingSetupForm: React.FC<Props> = ({
  children,
  testId,
  title,
  headerContent,
  ...rest
}) => (
  <SForm data-test-id={testId} {...rest}>
    {(title || headerContent) && (
      <SFormHeader>
        {title && <SFormHeaderTitle>{title}</SFormHeaderTitle>}
        {headerContent && headerContent()}
      </SFormHeader>
    )}
    <SFormContent>{children}</SFormContent>
  </SForm>
);

export default Object.assign(OfferingSetupForm, {
  Table: STable,
  TableHeader: STableHeader,
  Row: SRow,
  Column: SColumn,
  Field: SField,
  Actions: SActions,
  Card: SCard,
  CardHeader: SCardHeader,
  Panel: StyledPanel,
});
