import { Grid, Skeleton, Stack } from '@cmg/design-system';
import { Fragment, useMemo, VFC } from 'react';

export const CreateOrUpdateWireDialogSkeleton: VFC = () => {
  const gridRows = useMemo(() => Array.from(Array(3).keys()), []);

  return (
    <Stack tabIndex={0} role="progressbar" aria-label="Wire Form" gap={4}>
      <Skeleton variant="rounded" height={40} />
      <Skeleton variant="rounded" height={120} />
      <Grid container columnSpacing={4} rowSpacing={3}>
        {gridRows.map(rowNumber => (
          <Fragment key={rowNumber}>
            <Grid item xs={6}>
              <Skeleton variant="rounded" height={40} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded" height={40} />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Stack>
  );
};
