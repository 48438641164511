import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
  SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument,
  useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
} from '../graphql';

export type CreateNonSyndicateSellingGroupTermsRetentionWireMutation =
  SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation;

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}

export const useCreateNonSyndicateSellingGroupTermsRetentionWireMutation = () => {
  return useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
