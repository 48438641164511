import { DataGridGroupingClient, DeepWritable, timeUtil } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useFeatureToggles } from '../../../common/config';
import sortByDateComparator from '../../../common/util/sortByDateComparator';
import { nonEditableGridOptions } from '../../constants';
import Filters from '../filters/Filters';
import useFilters from '../filters/hooks/useFilters';
import columns from './DraftColumns';
import { useMyOfferings_DraftsQuery } from './graphql';
import { usePrivateOfferingsQuery } from './hooks/usePrivateOfferingsQuery';

export const GroupHeader = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};

  & span {
    margin-left: 5px;
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
`;

const FALLBACK_SOURCE_OFFERINGS = [];

const MemoizedDataGridGroupingClient = React.memo(DataGridGroupingClient);

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

const shouldShowStatusFilter = false;

const DraftsRoute: React.FC<Props> = () => {
  const theme = useTheme();
  const { isOfferingSetupMyOfferingsOfferingFullGql } = useFeatureToggles();
  const { data, loading, error } = useMyOfferings_DraftsQuery({
    fetchPolicy: 'network-only',
    skip: isOfferingSetupMyOfferingsOfferingFullGql,
  });
  const { privateOfferings, isLoadingPrivateOfferings, privateOfferingsError } =
    usePrivateOfferingsQuery(!isOfferingSetupMyOfferingsOfferingFullGql);

  const { myOfferingsPrivateOfferings, myOfferingsPrivateOfferingsData, isLoading, serverError } =
    isOfferingSetupMyOfferingsOfferingFullGql
      ? {
          myOfferingsPrivateOfferings: privateOfferings,
          myOfferingsPrivateOfferingsData: privateOfferings.data,
          isLoading: isLoadingPrivateOfferings,
          serverError: privateOfferingsError,
        }
      : {
          myOfferingsPrivateOfferings: data?.allOfferings,
          myOfferingsPrivateOfferingsData: data?.allOfferings.data,
          isLoading: loading,
          serverError: error,
        };

  const {
    filters: draftOfferingsFilters,
    setFilters: setDraftOfferingsFilters,
    filteredData: filteredDraftOfferingsData,
  } = useFilters({
    sourceOfferings: myOfferingsPrivateOfferingsData ?? FALLBACK_SOURCE_OFFERINGS,
  });

  return (
    <FlexLayout direction="column" data-test-id={xcSelectors.myOfferingsDraftsTab.testId}>
      <Filters
        offerings={myOfferingsPrivateOfferings}
        onChange={setDraftOfferingsFilters}
        filters={draftOfferingsFilters}
        showStatusFilter={shouldShowStatusFilter}
      />
      {serverError && <ServerErrorsBanner error={serverError} />}
      <MemoizedDataGridGroupingClient
        groupByField="pricingDate"
        groupHeaderRenderer={groupValue => (
          <GroupHeader>
            Expected Pricing Date:
            <span>{groupValue ? timeUtil.formatAsDisplayDayAndDate(groupValue) : 'TBD'}</span>
          </GroupHeader>
        )}
        groupHeaderComparator={sortByDateComparator('desc')}
        rows={filteredDraftOfferingsData as DeepWritable<typeof filteredDraftOfferingsData>}
        columns={columns}
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          fillViewport: true,
        }}
        loading={isLoading}
        resizeBy="grid"
        gridOptions={{
          ...nonEditableGridOptions,
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressBrowserResizeObserver: true,
          rowStyle: {
            background: theme.background.color.white,
            borderBottom: theme.border.smallSolidLight,
          },
        }}
      />
    </FlexLayout>
  );
};

export default DraftsRoute;
