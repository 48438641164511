import { ApolloError } from '@apollo/client';
import React from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import usePollingWhenVisible from '../../../../../common/hooks/usePollingWhenVisible';
import { useMyOrders_ProspectusDeliverySummariesLazyQuery } from '../../../graphql';
import { useWithMyOrders_CoveredIndicationChangedLazySubscription } from '../../../graphql/__generated__';
import { matchesCoveredIndicationChangePattern } from '../../../graphql/types/CoveredIndicationChanged';
import { defaultPollingInterval } from '../MyOrdersGrid.model';

export const useMyOrdersGridSecondaryQueries = () => {
  const { isOrderBookMyOrderGridSubscriptionOn } = useFeatureToggles();

  let prospectusDeliverySummariesLazyQueryResultTuple =
    useMyOrders_ProspectusDeliverySummariesLazyQuery();

  prospectusDeliverySummariesLazyQueryResultTuple =
    useWithMyOrders_CoveredIndicationChangedLazySubscription(
      prospectusDeliverySummariesLazyQueryResultTuple,
      {
        isEnabled: isOrderBookMyOrderGridSubscriptionOn,
        loadingStateBehavior: 'false-on-refetch',
        updateQuery:
          refetch =>
          (prev, { subscriptionData: { data } }) =>
            matchesCoveredIndicationChangePattern(data, ['ProspectusChange'])
              ? refetch(prev)
              : prev,
      }
    );

  const [
    getProspectusDeliverySummaries,
    {
      data: prospectusDeliverySummaries,
      error: prospectusDeliverySummariesError,
      stopPolling: stopProspectusDeliverySummariesPolling,
      startPolling: startProspectusDeliverySummariesPolling,
      called: hasCalledProspectusDeliverySummaries,
      loading: prospectusDeliverySummariesErrorLoading,
    },
  ] = prospectusDeliverySummariesLazyQueryResultTuple;

  usePollingWhenVisible({
    pollInterval: defaultPollingInterval,
    isEnabled: !isOrderBookMyOrderGridSubscriptionOn,
    startPolling: startProspectusDeliverySummariesPolling,
    stopPolling: stopProspectusDeliverySummariesPolling,
    called: hasCalledProspectusDeliverySummaries,
  });

  React.useEffect(() => {
    if (prospectusDeliverySummariesError) {
      stopProspectusDeliverySummariesPolling();
    }
  }, [prospectusDeliverySummariesError, stopProspectusDeliverySummariesPolling]);

  return React.useMemo(() => {
    return {
      prospectusDeliverySummaries,
      getProspectusDeliverySummaries,
      hasCalled: hasCalledProspectusDeliverySummaries,
      loading: prospectusDeliverySummariesErrorLoading,
      errors: {
        useMyOrdersGridProspectusDeliverySummariesLazyQuery: [
          prospectusDeliverySummariesError,
        ].filter((error): error is ApolloError => !!error),
      },
    };
  }, [
    getProspectusDeliverySummaries,
    prospectusDeliverySummaries,
    prospectusDeliverySummariesError,
    prospectusDeliverySummariesErrorLoading,
    hasCalledProspectusDeliverySummaries,
  ]);
};
