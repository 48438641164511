import { MyOfferings_SharedDraftsQuery } from '../../shared-drafts/graphql';
import { MyOfferings_DraftsQuery } from '../graphql';
import { ShareActivityRowProps } from './ShareActivityRow';

export const findManagerName = (
  managerKey: string,
  managers: MyOfferings_DraftsQuery['allOfferings']['data'][number]['managers']
) => {
  return managers.find(manager => manager.cmgEntityKey === managerKey)?.firmName ?? '-';
};

export const generateShareHistory = (
  data:
    | MyOfferings_DraftsQuery['allOfferings']['data'][number]
    | MyOfferings_SharedDraftsQuery['allSharedDrafts']['data'][number]
): ShareActivityRowProps[] => {
  if (data.__typename === 'OfferingLimited') {
    return data.shareHistory.shareDraftHistories.map(shareActivity => ({
      shareDatetime: shareActivity.sharedTime,
      managers: shareActivity.managerKeys.map(managerKey =>
        findManagerName(managerKey, data.managers)
      ),
    }));
  }
  return [];
};
