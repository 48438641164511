import { numericUtil } from '@cmg/common';

export const calculateSalesCreditsDifference = ({
  sellingConcession,
  totalSalesCredits,
  totalDesignationShares,
}: {
  sellingConcession: number | null | undefined;
  totalSalesCredits: number | null;
  totalDesignationShares: number | null;
}) => {
  const expectedSalesCredits = numericUtil.multiply(totalDesignationShares, sellingConcession);

  const difference = (expectedSalesCredits ?? 0) - (totalSalesCredits ?? 0);

  return Math.abs(difference) < 0.01 ? 0 : difference;
};
