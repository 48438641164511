import type { GraphqlQueryVariables } from '@cmg/data-grid';
import { getDataSource } from '@cmg/data-grid';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { useCallback, useMemo } from 'react';

import type { SyndicateInstitutionalGridRowSortInput } from '../../../../../graphql';
import { AttestationFormStatus, SyndicateGridGroupingColumn } from '../../../../../graphql';
import type {
  OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
} from '../../graphql/__generated__';
import {
  useOrderBook_InstitutionalDemand_SummariesLazyQuery,
  useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsLazyQuery,
  useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridLazyQuery,
} from '../../graphql/__generated__';
import type {
  DemandConfigValues,
  DemandGridDataContext,
  DemandGridRowData,
  TotalRow,
} from '../types';

type Metadata = Pick<DemandGridDataContext, 'totalSummaryMetrics' | 'totalRow' | 'groupKeys'>;

export type Props = Readonly<{
  offeringId: string;
  demandConfig: DemandConfigValues | null;
  updateContext: (nextContext: Partial<DemandGridDataContext>) => void;
}>;

type GroupConfig = {
  groupBy: SyndicateGridGroupingColumn;
  where: (filterValue: string, context: DemandGridDataContext) => Record<string, unknown>;
};

const getInvestorTypeRowGroupConfig = (
  filterPath: string,
  groupBy: SyndicateGridGroupingColumn
) => {
  return {
    groupBy,
    where: (filterValue: string, context: DemandGridDataContext) => {
      return set({}, filterPath, filterValue ? { eq: filterValue } : { nin: context.groupKeys });
    },
  };
};

export const ColIdGroupConfigMap = {
  attestationStatus: {
    groupBy: SyndicateGridGroupingColumn.AttestationStatus,
    where: (filterValue: string) => {
      return {
        attestationStatus: filterValue
          ? { eq: filterValue }
          : { nin: Object.values(AttestationFormStatus) },
      };
    },
  },
  'auditInfo.createdAt': {
    groupBy: SyndicateGridGroupingColumn.SubmittedOn,
    where: (filterValue: string) => {
      const startDateTime = `${filterValue}T00:00:00.000Z`;
      const endDateTime = `${filterValue}T23:59:59.999Z`;

      return {
        auditInfo: { createdAt: { gte: startDateTime, lte: endDateTime } },
      };
    },
  },
  bndAgent: {
    groupBy: SyndicateGridGroupingColumn.BndAgentFirmDisplayName,
    where: (filterValue: string, context: DemandGridDataContext) => {
      const allManagerKeys = Object.values(context.syndicateManagers).map(
        manager => manager.cmgEntityKey
      );

      return {
        bndAgent: filterValue
          ? { firmDisplayName: { eq: filterValue } }
          : { firmKey: { nin: allManagerKeys } },
      };
    },
  },
  'prospectusDeliverySummary.overallStatusDisplayValue': {
    groupBy: SyndicateGridGroupingColumn.ProspectusDeliveryStatus,
    where: (filterValue: string) => {
      return {
        prospectusDeliverySummary: { overallStatus: { eq: filterValue } },
      };
    },
  },
  'investor.extensions.investorType1': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorType1',
    SyndicateGridGroupingColumn.InvestorType1
  ),
  'investor.extensions.investorType2': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorType2',
    SyndicateGridGroupingColumn.InvestorType2
  ),
  'investor.extensions.investorType3': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorType3',
    SyndicateGridGroupingColumn.InvestorType3
  ),
  'investor.extensions.investorType4': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorType4',
    SyndicateGridGroupingColumn.InvestorType4
  ),
  'investor.extensions.investorType5': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorType5',
    SyndicateGridGroupingColumn.InvestorType5
  ),
  'investor.extensions.investorRegion1': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorRegion1',
    SyndicateGridGroupingColumn.InvestorRegion1
  ),
  'investor.extensions.investorRegion2': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorRegion2',
    SyndicateGridGroupingColumn.InvestorRegion2
  ),
  'investor.extensions.investorRegion3': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorRegion3',
    SyndicateGridGroupingColumn.InvestorRegion3
  ),
  'investor.extensions.investorRegion4': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorRegion4',
    SyndicateGridGroupingColumn.InvestorRegion4
  ),
  'investor.extensions.investorRegion5': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorRegion5',
    SyndicateGridGroupingColumn.InvestorRegion5
  ),
  'investor.extensions.investorCompliance1': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorCompliance1',
    SyndicateGridGroupingColumn.InvestorCompliance1
  ),
  'investor.extensions.investorCompliance2': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorCompliance2',
    SyndicateGridGroupingColumn.InvestorCompliance2
  ),
  'investor.extensions.investorCompliance3': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorCompliance3',
    SyndicateGridGroupingColumn.InvestorCompliance3
  ),
  'investor.extensions.investorCompliance4': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorCompliance4',
    SyndicateGridGroupingColumn.InvestorCompliance4
  ),
  'investor.extensions.investorCompliance5': getInvestorTypeRowGroupConfig(
    'investor.extensions.investorCompliance5',
    SyndicateGridGroupingColumn.InvestorCompliance5
  ),
} satisfies Record<string, GroupConfig>;

const getGridRowsQueryVariables = (
  variables: GraphqlQueryVariables<SyndicateInstitutionalGridRowSortInput>,
  groupKeys: string[],
  groupColIds: string[],
  context: DemandGridDataContext
) => {
  const groupFilterVariables = groupKeys.reduce(
    (acc, groupKey, index) => {
      const groupColId = groupColIds[index];
      const whereFn: GroupConfig['where'] = groupColId
        ? ColIdGroupConfigMap[groupColId].where
        : (filterValue: string) => ({
            duplicateOfIndicationId: { eq: filterValue },
          });

      return { ...acc, ...whereFn(groupKey, context) };
    },
    { duplicateOfIndicationId: { eq: null } }
  );

  const { duplicateOfIndicationId } = groupFilterVariables;

  if (isNil(duplicateOfIndicationId.eq)) {
    return merge(variables, { where: groupFilterVariables });
  }

  return { ...variables, where: { duplicateOfIndicationId } };
};

const getRowCount = (totalRow: TotalRow | null, rowData: DemandGridRowData | null | undefined) => {
  if (rowData?.__typename === 'Group') {
    return rowData.count;
  }

  return !rowData ? totalRow?.indicationCount : undefined;
};

export const useDemandGridDataSource = ({ demandConfig, offeringId, updateContext }: Props) => {
  const [loadRows] = useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridLazyQuery();
  const [loadGroups] = useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsLazyQuery();
  const [loadSummaries] = useOrderBook_InstitutionalDemand_SummariesLazyQuery();

  const handleLoadSummaries = useCallback(
    async (
      variables: GraphqlQueryVariables<SyndicateInstitutionalGridRowSortInput>,
      skip: boolean
    ) => {
      if (skip) {
        return;
      }

      const { data, error } = await loadSummaries({
        variables: {
          ...demandConfig!,
          offeringId,
          where: { ...variables.where, duplicateOfIndicationId: { eq: null } },
        },
        fetchPolicy: 'cache-and-network',
      });

      if (error) {
        throw error;
      }

      const { totals, summaryMetrics } = data?.syndicateInstitutionalGrid?.summaries ?? {};

      return {
        totalSummaryMetrics: summaryMetrics,
        totalRow: totals,
      };
    },
    [demandConfig, loadSummaries, offeringId]
  );

  const handleLoadGroupRows = useCallback(
    async (
      rowGroupColId: string,
      variables: GraphqlQueryVariables<SyndicateInstitutionalGridRowSortInput>
    ) => {
      const { data, error } = await loadGroups({
        variables: {
          ...demandConfig!,
          offeringId,
          groupBy: ColIdGroupConfigMap[rowGroupColId].groupBy,
          where: {
            ...variables.where,
            duplicateOfIndicationId: { eq: null },
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        throw error;
      }

      const rowData = (data?.syndicateInstitutionalGridGroupKeys ??
        []) as OrderBook_InstitutionalDemand_GridGroupRowPartsFragment[];

      const groupKeys = rowData.reduce<string[]>((acc, group) => {
        return group.groupKey ? [...acc, group.groupKey] : acc;
      }, []);

      return {
        rowData: rowData,
        rowCount: rowData.length,
        meta: { groupKeys },
      };
    },
    [demandConfig, loadGroups, offeringId]
  );

  const handleLoadRows = useCallback(
    async (variables: GraphqlQueryVariables<SyndicateInstitutionalGridRowSortInput>) => {
      const { data, error } = await loadRows({
        variables: {
          ...variables,
          ...demandConfig!,
          offeringId,
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        throw error;
      }

      return (data?.syndicateInstitutionalGrid?.items ??
        []) as OrderBook_InstitutionalDemand_GridRowPartsFragment[];
    },
    [demandConfig, loadRows, offeringId]
  );

  return useMemo(() => {
    return getDataSource<
      | OrderBook_InstitutionalDemand_GridRowPartsFragment
      | OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
      SyndicateInstitutionalGridRowSortInput,
      Partial<Metadata>,
      DemandGridDataContext
    >({
      getData: async (variables, { groupKeys, rowGroupCols }, context, parentNode) => {
        if (!demandConfig) {
          return { rowData: [] };
        }

        const [firstGroupKey] = groupKeys;
        const [rowGroupCol] = rowGroupCols;

        const skipSummaries = parentNode.level >= 0 || variables.skip !== 0;
        const summariesMetaPromise = handleLoadSummaries(variables, skipSummaries);

        // Load group rows based on the given group column
        if (rowGroupCol && isNil(firstGroupKey)) {
          const [summariesMeta, groupResult] = await Promise.all([
            summariesMetaPromise,
            handleLoadGroupRows(rowGroupCol.id, variables),
          ]);

          return { ...groupResult, meta: { ...groupResult.meta, ...summariesMeta } };
        }

        const rowsQueryVariables = getGridRowsQueryVariables(
          variables,
          groupKeys,
          rowGroupCols.map(col => col.id),
          context
        );

        const [summariesMeta, rowData] = await Promise.all([
          summariesMetaPromise,
          handleLoadRows(rowsQueryVariables),
        ]);

        const rowCount = getRowCount(summariesMeta?.totalRow ?? context.totalRow, parentNode.data);

        return { rowData, rowCount, meta: summariesMeta };
      },
      onDataReceived: (_, meta) => {
        if (meta) {
          updateContext(meta);
        }
      },
    });
  }, [demandConfig, handleLoadGroupRows, handleLoadRows, handleLoadSummaries, updateContext]);
};
