import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

import { SFormHeaderTitle } from '../components/form/OfferingSetupForm.styles';

export const SHeaderTitle = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
`;

export const SDivider = styled.hr`
  border-width: ${({ theme }) => `${theme.border.width.small}`};
  border-style: solid;
  width: 100%;
  border-color: ${({ theme }) => theme.designSystem.colors.gray['300']};
`;

export const StyledSectionHeader = styled(SFormHeaderTitle)`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
`;
