import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { createCellStyle, theme, valueFormatters } from '@cmg/common';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import React from 'react';

import { sortCaseInsensitive } from '../../../../../../common/util/sortCaseInsensitive';
import { DesignationManager } from '../../../hooks/useDesignationContext';
import DesignationMonitorHeaderWarningRenderer from '../components/warnings-renderer/DesignationMonitorHeaderWarningRenderer';
import DesignationMonitorWarningsRenderer from '../components/warnings-renderer/DesignationMonitorWarningsRenderer';
import {
  commonCellRendererSelector,
  defaultCellStyle,
  DesignationMonitorColumns,
  designationSelectionCellRendererSelector,
  investorNameRendererSelector,
  validateAllocationAndDesignationRendererSelector,
} from '../DesignationMonitorGrid.model';
import { SManagerHeader } from './useDesignationMonitorColumns.styles';

const defaultColumnDef: ColDef | ColGroupDef = {
  editable: false,
  lockPinned: true,
};
const defaultPinnedColumnDef: ColDef | ColGroupDef = {
  ...defaultColumnDef,
  suppressAutoSize: true,
  suppressMovable: true,
};
const numberColumnDef: ColDef | ColGroupDef = {
  cellRendererSelector: commonCellRendererSelector,
  headerClass: 'ag-header-right',
  cellStyle: defaultCellStyle,
};
const integerColumnDef: ColDef | ColGroupDef = {
  ...numberColumnDef,
  valueFormatter: valueFormatters.getIntegerValueFormatter({ shouldFormatPinnedRow: true }),
};
const percentageColumnDef: ColDef | ColGroupDef = {
  ...numberColumnDef,
  headerName: '%',
  valueFormatter: valueFormatters.getPercentsValueFormatter({ shouldFormatPinnedRow: true }),
};

export const useDesignationMonitorColumns = (managers: DesignationManager[]) => {
  const canEditDesignation = useCheckPermissions([permissionsByEntity.Designation.FULL]);

  return React.useMemo(
    (): (ColDef | ColGroupDef)[] => [
      {
        colId: DesignationMonitorColumns.ID,
        field: DesignationMonitorColumns.ID,
        hide: true,
      },
      {
        colId: DesignationMonitorColumns.WARNINGS,
        field: DesignationMonitorColumns.WARNINGS,
        ...defaultPinnedColumnDef,
        maxWidth: 42,
        minWidth: 42,
        headerName: '',
        pinned: 'left',
        sortable: false,
        headerComponentFramework: DesignationMonitorHeaderWarningRenderer,
        cellRendererFramework: DesignationMonitorWarningsRenderer,
      },
      ...(canEditDesignation
        ? [
            {
              colId: DesignationMonitorColumns.SELECTED,
              field: DesignationMonitorColumns.SELECTED,
              headerName: '',
              headerComponent: 'headerGroupCheckboxRenderer',
              pinned: 'left',
              ...defaultPinnedColumnDef,
              maxWidth: 64,
              minWidth: 64,
              sortable: false,
              cellRendererSelector: designationSelectionCellRendererSelector,
            },
          ]
        : []),
      {
        colId: DesignationMonitorColumns.INVESTOR_NAME,
        field: DesignationMonitorColumns.INVESTOR_NAME,
        headerName: 'Investor',
        ...defaultPinnedColumnDef,
        minWidth: 225,
        flex: 2,
        pinned: 'left',
        cellStyle: createCellStyle(() => ({
          borderRight: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
        })),
        comparator: sortCaseInsensitive,
        cellRendererSelector: investorNameRendererSelector,
      },
      {
        colId: DesignationMonitorColumns.ALLOCATION,
        field: DesignationMonitorColumns.ALLOCATION,
        ...defaultColumnDef,
        ...integerColumnDef,
        headerName: 'Allocations',
        minWidth: 112,
        cellRendererSelector: validateAllocationAndDesignationRendererSelector,
      },
      ...managers.map(({ cmgEntityName, cmgEntityKey }) => ({
        colId: cmgEntityKey,
        headerName: cmgEntityName,
        ...defaultColumnDef,
        headerClass: 'ag-header-center',
        headerGroupComponentFramework: () => (
          <SManagerHeader role="manager-header">{cmgEntityName}</SManagerHeader>
        ),
        children: [
          {
            colId: `investments.${cmgEntityKey}.shareQuantity`,
            field: `investments.${cmgEntityKey}.shareQuantity`,
            ...defaultColumnDef,
            ...integerColumnDef,
            headerName: 'Shares',
            minWidth: 87,
          },
          {
            colId: `investments.${cmgEntityKey}.percent`,
            field: `investments.${cmgEntityKey}.percent`,
            ...defaultColumnDef,
            ...percentageColumnDef,
            minWidth: 87,
          },
        ],
      })),
      {
        colId: DesignationMonitorColumns.TOTAL,
        headerName: 'Total Designations',
        headerClass: 'ag-header-center',
        ...defaultPinnedColumnDef,
        pinned: 'right',
        children: [
          {
            colId: DesignationMonitorColumns.TOTAL_DESIGNATIONS,
            field: DesignationMonitorColumns.TOTAL_DESIGNATIONS,
            ...defaultPinnedColumnDef,
            ...integerColumnDef,
            pinned: 'right',
            headerName: 'Shares',
            minWidth: 112,
            cellRendererSelector: validateAllocationAndDesignationRendererSelector,
            cellStyle: createCellStyle(() => ({
              justifyContent: 'flex-end',
              fontWeight: theme.text.weight.bold,
              borderLeft: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
            })),
          },
          {
            colId: DesignationMonitorColumns.TOTAL_PERCENT,
            field: DesignationMonitorColumns.TOTAL_PERCENT,
            ...defaultPinnedColumnDef,
            ...percentageColumnDef,
            pinned: 'right',
            minWidth: 112,
            cellStyle: createCellStyle(() => ({
              justifyContent: 'flex-end',
              fontWeight: theme.text.weight.bold,
            })),
          },
        ],
      },
    ],
    [canEditDesignation, managers]
  );
};
