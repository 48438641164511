import { UUID } from '@cmg/common';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import ColumnLayout from '../../common/components/layout/column-layout/ColumnLayout';
import routeFactory from '../../common/util/routeFactory';
import RegulatoryFilingsSideNav from './common/navbar/RegulatoryFilingsSideNav';
import DSFilingDetailRoute from './filings/deal-status/DSFilingDetailRoute';
import DSFilingFormRoute from './filings/deal-status/DSFilingFormRoute';
import DSFilingRoute from './filings/deal-status/DSFilingRoute';
import NOIFilingDetailRoute from './filings/notice-of-intent/NOIFilingDetailRoute';
import NOIFilingFormRoute from './filings/notice-of-intent/NOIFilingFormRoute';
import NOIFilingRoute from './filings/notice-of-intent/NOIFilingRoute';
import RPNFilingDetailRoute from './filings/restricted-period-notification/RPNFilingDetailRoute';
import RPNFilingFormRoute from './filings/restricted-period-notification/RPNFilingFormRoute';
import RPNFilingRoute from './filings/restricted-period-notification/RPNFilingRoute';
import TNFilingDetailRoute from './filings/trading-notification/TNFilingDetailRoute';
import TNFilingFormRoute from './filings/trading-notification/TNFilingFormRoute';
import TNFilingRoute from './filings/trading-notification/TNFilingRoute';
import USDTNFilingDetailRoute from './filings/usdtn/USDTNFilingDetailRoute';
import USDTNFilingFormRoute from './filings/usdtn/USDTNFilingFormRoute';
import USDTNFilingRoute from './filings/usdtn/USDTNFilingRoute';
import { useRegulatoryFilings_RegulatoryFilingsQuery } from './graphql';
import { useFilingDocumentTitle } from './hooks/useFilingDocumentTitle';
import OverviewRoute from './overview/OverviewRoute';
import { createNavigation } from './RegulatoryFilingsRoute.model';
import { SContainer, SNavigationContainer, SPageContent } from './RegulatoryFilingsRoute.styles';

export type Props = { issuerName?: string } & RouteComponentProps<{ offeringId: UUID }>;

const RegulatoryFilingsRoute: React.FC<Props> = ({ match, location, issuerName }) => {
  const { offeringId } = match.params;

  const { data } = useRegulatoryFilings_RegulatoryFilingsQuery({ variables: { offeringId } });

  useFilingDocumentTitle({ issuerName, pathname: location.pathname });

  const rpnFilings = createNavigation(data?.offering.regulatoryFilings.RPNFilings, filing =>
    routeFactory.regulatoryFilingsRPNFilingDetail.getUrlPath({
      offeringId,
      filingId: filing.id,
    })
  );
  const noiFilings = createNavigation(data?.offering.regulatoryFilings.NOIFilings, filing =>
    routeFactory.regulatoryFilingsNOIFilingDetail.getUrlPath({
      offeringId,
      filingId: filing.id,
    })
  );
  const tnFilings = createNavigation(data?.offering.regulatoryFilings.TNFilings, filing =>
    routeFactory.regulatoryFilingsTNFilingDetail.getUrlPath({
      offeringId,
      filingId: filing.id,
    })
  );
  const usdtnFilings = createNavigation(data?.offering.regulatoryFilings.USDTNFilings, filing =>
    routeFactory.regulatoryFilingsUSDTNFilingDetail.getUrlPath({
      offeringId,
      filingId: filing.id,
    })
  );
  const dsFilings = createNavigation(data?.offering.regulatoryFilings.DSFilings, filing =>
    routeFactory.regulatoryFilingsDSFilingDetail.getUrlPath({
      offeringId,
      filingId: filing.id,
    })
  );

  return (
    <SContainer>
      <ColumnLayout>
        <SNavigationContainer>
          <RegulatoryFilingsSideNav
            offeringId={offeringId}
            rpnFilings={rpnFilings}
            noiFilings={noiFilings}
            tnFilings={tnFilings}
            usdtnFilings={usdtnFilings}
            dsFilings={dsFilings}
          />
        </SNavigationContainer>
        <SPageContent>
          <Switch>
            <Route
              exact
              path={routeFactory.regulatoryFilings.routePath}
              component={OverviewRoute}
            />

            <Route
              exact
              path={routeFactory.regulatoryFilingsRPNFiling.routePath}
              component={RPNFilingRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsRPNFilingForm.routePath}
              component={RPNFilingFormRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsRPNFilingDetail.routePath}
              component={RPNFilingDetailRoute}
            />

            <Route
              exact
              path={routeFactory.regulatoryFilingsTNFiling.routePath}
              component={TNFilingRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsTNFilingForm.routePath}
              component={TNFilingFormRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsTNFilingDetail.routePath}
              component={TNFilingDetailRoute}
            />

            <Route
              exact
              path={routeFactory.regulatoryFilingsUSDTNFiling.routePath}
              component={USDTNFilingRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsUSDTNFilingForm.routePath}
              component={USDTNFilingFormRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsUSDTNFilingDetail.routePath}
              component={USDTNFilingDetailRoute}
            />

            <Route
              exact
              path={routeFactory.regulatoryFilingsNOIFiling.routePath}
              component={NOIFilingRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsNOIFilingForm.routePath}
              component={NOIFilingFormRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsNOIFilingDetail.routePath}
              component={NOIFilingDetailRoute}
            />

            <Route
              exact
              path={routeFactory.regulatoryFilingsDSFiling.routePath}
              component={DSFilingRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsDSFilingForm.routePath}
              component={DSFilingFormRoute}
            />
            <Route
              exact
              path={routeFactory.regulatoryFilingsDSFilingDetail.routePath}
              component={DSFilingDetailRoute}
            />

            {/* Unknown url */}
            <Route
              render={() => (
                <Redirect
                  to={routeFactory.regulatoryFilingsRPNFiling.getUrlPath({
                    offeringId,
                  })}
                />
              )}
            />
          </Switch>
        </SPageContent>
      </ColumnLayout>
    </SContainer>
  );
};

export default RegulatoryFilingsRoute;
