import { LinkButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SCmgSupportMessage = styled.div`
  margin-top: 10px;
  font-size: 11px;
`;

export const StyledLinkCmgSupport = styled(LinkButton)`
  padding: 0px;
`;
