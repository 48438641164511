import styled from 'styled-components/macro';

import { releasedFinalAllocationHeaderClass } from './InstitutionalDemandGrid.model';

export const SDataGridWrapper = styled.div`
  // The overrides below that deals with removing borders from ag-grid
  // exists so that the larger border that's being applied to the pinned
  // left column does not have gaps in between cells.

  // Adding a larger border to the pinned left header column
  .ag-theme-balham .ag-pinned-left-header {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  .ag-theme-balham .ag-checkbox-input-wrapper {
    line-height: 12px;
  }

  .ag-theme-balham .ag-row-selected {
    background-color: ${({ theme }) => theme.background.color.highlighted}!important;
  }

  .ag-theme-balham .ag-pinned-left-cols-container {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  .ag-theme-balham .ag-pinned-left-floating-top {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Adding a larger border to the pinned right header column
  .ag-theme-balham .ag-pinned-right-header {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  .ag-theme-balham .ag-pinned-right-cols-container {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  .ag-theme-balham .ag-pinned-right-floating-top {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Removing borders from the header rows.
  .ag-header {
    border: 0;
  }

  // Removing borders in all grid cells and rows
  .ag-theme-balham .ag-cell,
  .ag-row {
    border-bottom-width: 0;
    border-top-width: 0;
  }

  // Setting the width of the wrapper around the Investor name cell
  // to 100% so that we can properly handle text overflow (and add ellipsis).
  .bd-agent-cell .ag-react-container,
  .ag-pinned-left-cols-container .ag-react-container,
  .ag-pinned-right-cols-container .ag-react-container,
  .ag-pinned-right-floating-top .ag-react-container {
    width: 100%;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SGridWrapper = styled.div`
  height: 100%;

  .ag-react-container {
    width: 100%;
  }
`;

export const SGrid = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  .${releasedFinalAllocationHeaderClass} {
    color: ${({ theme }) => theme.designSystem.colors.green['300']};
  }
`;
