import {
  SyndicateWires_UpdateNonSyndicateFreeFormWireMutation,
  useSyndicateWires_UpdateNonSyndicateFreeFormWireMutation,
} from '../graphql';

export type UpdateNonSyndicateFreeFormWireMutation =
  SyndicateWires_UpdateNonSyndicateFreeFormWireMutation;

export const useUpdateNonSyndicateFreeFormWireMutation = () => {
  return useSyndicateWires_UpdateNonSyndicateFreeFormWireMutation();
};
