import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';
import { calculateTotalRevenueValues } from './TotalRevenueInfoBox.model';
import { SSmallInfo } from './TotalRevenueInfoBox.styles';

const TotalRevenueInfoBox: React.FC = () => {
  const accounting = useAccountingContext();
  const { dealRelatedExpenses, stabilizationGainLoss } = accounting;

  const { totalRevenue, netDealFees } = calculateTotalRevenueValues(accounting);

  return (
    <AccountingInfoBox gridTemplateColumns="3fr 10px 1.5fr">
      <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
        Total Revenue & Expenses Reconciliation
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Total Revenue from Gross Spread
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(totalRevenue, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Deal Related Expenses
      </Table.Cell>
      <Table.Cell paddingSize="S" bold withBottomBorder={false}>
        -
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(dealRelatedExpenses, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S">
        Gain/(Loss) on Stabilization <SSmallInfo>(excl. Selling Con.)</SSmallInfo>
      </Table.Cell>
      <Table.Cell paddingSize="S" bold withBottomBorder={false}>
        +
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(stabilizationGainLoss, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} bold withBottomBorder={false}>
        Net Deal Fees
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForCurrency(netDealFees, 2)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default TotalRevenueInfoBox;
