import { urlUtil } from '@cmg/common';
import { useHistory } from 'react-router';

import { NotesFilters } from '../NotesModel.util';

type NoteSearchParams = {
  noteType: NotesFilters;
  cmgEntityKey?: string;
};

export const useNoteSearchParams = (defaultValue: NoteSearchParams): NoteSearchParams => {
  const { location } = useHistory();
  const { noteType, cmgEntityKey } = urlUtil.queryParse<{
    noteType: NotesFilters;
    cmgEntityKey: string;
  }>(location.search);

  const isNoteTypeValid = Object.values(NotesFilters).includes(noteType);

  return {
    cmgEntityKey: cmgEntityKey ?? defaultValue.cmgEntityKey,
    noteType: isNoteTypeValid ? noteType : defaultValue.noteType,
  };
};
