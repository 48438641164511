import React from 'react';

import { ConfirmationPanel } from '../confirmation-panel/ConfirmationPanel';

type Props = {
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const LegalAgreementPanel: React.FC<Props> = ({ onConfirm, onCancel, children }) => {
  return (
    <ConfirmationPanel
      title="Legal Review"
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelButtonLabel="Decline"
      confirmButtonLabel="Accept"
    >
      {children}
    </ConfirmationPanel>
  );
};
