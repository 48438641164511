import styled from 'styled-components/macro';

const SectionHeader = styled.div`
  height: 46px;
  background: ${({ theme }) => theme.background.color.light};
  padding-left: 24px;
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

const SectionContent = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default Object.assign({}, { Header: SectionHeader, Content: SectionContent });
