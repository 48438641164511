import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SnackbarManager,
} from '@cmg/design-system';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalDraftSet } from '../../../../types';
import { useOrderBook_DeleteDraftAllocationSetMutation } from './graphql/__generated__';

export type Props = Readonly<{
  offeringId: UUID;
  allocationSet:
    | InstitutionalDraftSet
    | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
    | null;
  isOpen: boolean;
  onClose: () => void;
}>;

const DeleteDraftAllocationSetDialog: React.FC<Props> = ({
  allocationSet,
  offeringId,
  isOpen,
  onClose,
}) => {
  const [deleteDraftAllocationSet, { loading: isDeleting }] =
    useOrderBook_DeleteDraftAllocationSetMutation({
      refetchQueries: [getOperationName(OrderBook_OrderBookRouteDocument)!],
    });

  const handleDelete = async () => {
    try {
      await deleteDraftAllocationSet({ variables: { offeringId, id: allocationSet!.id! } });
      onClose();
      SnackbarManager.success('Draft Allocation Set Successfully Deleted');
    } catch {
      SnackbarManager.error('Failed To Delete Draft Allocation Set');
    }
  };

  if (!allocationSet) {
    return null;
  }

  return (
    <Dialog
      title="Delete Draft Allocation Set dialog"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Delete {allocationSet.name} Draft Allocation Set</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete the {allocationSet.name} draft allocation set? This action
        cannot be reverted.
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isDeleting}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={handleDelete} loading={isDeleting}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDraftAllocationSetDialog;
