import { numericUtil } from '@cmg/common';
import React from 'react';

import {
  SActionsLabel,
  SLabel,
  SMainTitle,
  STitle,
  SWrapper,
} from './SalesCreditsDetailsHeader.styles';

export type Props = {
  sellingConcession: number | null | undefined;
  settlementCurrency: string;
  numOfRowsNeedingAction: number;
};

const SalesCreditsDetailsHeader: React.FC<Props> = ({
  sellingConcession,
  settlementCurrency,
  numOfRowsNeedingAction,
}) => {
  return (
    <SWrapper direction="row" alignItems="baseline" gap={8}>
      <SMainTitle>Sales Credits Release</SMainTitle>
      <SLabel>Selling Concession</SLabel>
      <STitle>{numericUtil.getDisplayValueForCurrency(sellingConcession, 6)}</STitle>
      <SLabel>Currency</SLabel>
      <STitle>{settlementCurrency}</STitle>
      <SActionsLabel>Rows requiring action: {numOfRowsNeedingAction}</SActionsLabel>
    </SWrapper>
  );
};

export default SalesCreditsDetailsHeader;
