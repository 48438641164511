import { LinkButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWarning = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.error};
  max-width: 300px;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-left: 0;
  padding-left: 0;
`;
