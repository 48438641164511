import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import * as yup from 'yup';

import { AssignDealLayoutInput } from '../../../../../../../../../graphql';
import { MAX_53_BIT_INT } from '../../../../../../../../../types/graphql/constants';

export enum EnteredValueType {
  SHARES = 'SHARES',
  PERCENTAGE = 'PERCENTAGE',
}

export const enteredValueOptions = [
  { label: 'Shares', value: EnteredValueType.SHARES },
  { label: 'Percentage', value: EnteredValueType.PERCENTAGE },
];

export type AllocationSetValues = {
  name: string | null;
  offeringSize: number | null;
  enteredValueType: EnteredValueType;
  institutionalTarget: number | null;
  retailTarget: number | null;
};

const offeringSizeBaseSchema = yup
  .number()
  .min(0)
  .max(MAX_53_BIT_INT)
  .label('Offering Size')
  .nullable();
const institutionalTargetBaseSchema = yup.number().min(0).label('Institutional Target').nullable();
const maxSharesInstitutionalTargetSchema = institutionalTargetBaseSchema.max(MAX_53_BIT_INT);
const maxPercentsInstitutionalTargetSchema = institutionalTargetBaseSchema.max(
  1,
  'Institutional Target must be less than or equal to 100'
);
const retailTargetBaseSchema = yup.number().min(0).label('Retail Target').nullable();
const maxSharesRetailTargetSchema = retailTargetBaseSchema.max(MAX_53_BIT_INT);
const maxPercentsRetailTargetSchema = retailTargetBaseSchema.max(
  1,
  'Retail Target must be less than or equal to 100'
);

const maxSharesTest: yup.TestConfig<number | null | undefined> = {
  name: 'max-shares-value',
  message: 'Sum of Institutional and Retail Targets must equal Offering Size',
  test: (_, context) => {
    const { offeringSize, institutionalTarget, retailTarget } =
      context.parent as AllocationSetValues;

    return offeringSize === numericUtil.sum(institutionalTarget, retailTarget);
  },
};
const maxPercentsTest: yup.TestConfig<number | null | undefined> = {
  name: 'max-percents-value',
  message: 'Sum of Institutional and Retail Targets must equal 100',
  test: (_, context) => {
    const { institutionalTarget, retailTarget } = context.parent as AllocationSetValues;
    const sum = numericUtil.sum(institutionalTarget, retailTarget);

    return sum === null || sum === 1;
  },
};

export const validationSchema = yup.object({
  name: yup.string().label('Name').nullable().required(),
  offeringSize: yup.number().when(['institutionalTarget', 'retailTarget'], {
    is: (institutionalTarget: number | null | undefined, retailTarget: number | null | undefined) =>
      !isNil(institutionalTarget) || !isNil(retailTarget),
    then: () => offeringSizeBaseSchema.required(),
    otherwise: () => offeringSizeBaseSchema,
  }),
  enteredValueType: yup
    .string()
    .oneOf([...Object.values(EnteredValueType)])
    .label('Value'),
  institutionalTarget: yup.number().when('enteredValueType', {
    is: EnteredValueType.SHARES,
    then: schema =>
      schema.when(['offeringSize', 'retailTarget'], {
        is: (offeringSize: number | null | undefined, retailTarget: number | null | undefined) =>
          !isNil(offeringSize) || !isNil(retailTarget),
        then: () => maxSharesInstitutionalTargetSchema.required().test(maxSharesTest),
        otherwise: () => maxSharesInstitutionalTargetSchema,
      }),
    otherwise: schema =>
      schema.when(['offeringSize', 'retailTarget'], {
        is: (offeringSize: number | null | undefined, retailTarget: number | null | undefined) =>
          !isNil(offeringSize) || !isNil(retailTarget),
        then: () => maxPercentsInstitutionalTargetSchema.required().test(maxPercentsTest),
        otherwise: () => maxPercentsInstitutionalTargetSchema,
      }),
  }),
  retailTarget: yup.number().when('enteredValueType', {
    is: EnteredValueType.SHARES,
    then: schema =>
      schema.when(['offeringSize', 'institutionalTarget'], {
        is: (
          offeringSize: number | null | undefined,
          institutionalTarget: number | null | undefined
        ) => !isNil(offeringSize) || !isNil(institutionalTarget),
        then: () => maxSharesRetailTargetSchema.required().test(maxSharesTest),
        otherwise: () => maxSharesRetailTargetSchema,
      }),
    otherwise: schema =>
      schema.when(['offeringSize', 'institutionalTarget'], {
        is: (
          offeringSize: number | null | undefined,
          institutionalTarget: number | null | undefined
        ) => !isNil(offeringSize) || !isNil(institutionalTarget),
        then: () => maxPercentsRetailTargetSchema.required().test(maxPercentsTest),
        otherwise: () => maxPercentsRetailTargetSchema,
      }),
  }),
});

export const getInitialValues = ({
  name,
  offeringSize,
  institutionalTarget,
}: {
  name: string | null | undefined;
  offeringSize: number | null | undefined;
  institutionalTarget: number | null | undefined;
}): AllocationSetValues => {
  return {
    name: name ?? '',
    offeringSize: offeringSize ?? null,
    enteredValueType: EnteredValueType.SHARES,
    institutionalTarget: institutionalTarget ?? null,
    retailTarget: numericUtil.sum(offeringSize, numericUtil.negate(institutionalTarget)),
  };
};

export const getDealLayoutInput = (values: AllocationSetValues): AssignDealLayoutInput => {
  if (values.enteredValueType === EnteredValueType.SHARES) {
    return { offeringSize: values.offeringSize!, institutionalTarget: values.institutionalTarget! };
  }

  const institutionalTarget = numericUtil.multiply(
    values.offeringSize,
    values.institutionalTarget
  )!;
  const institutionalTargetShares = parseInt(institutionalTarget.toFixed(0));

  return {
    offeringSize: values.offeringSize!,
    institutionalTarget: institutionalTargetShares,
  };
};
