import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateManagerBdWireMutation,
  SyndicateWires_ManagerBdWiresDocument,
  useSyndicateWires_CreateManagerBdWireMutation,
} from '../graphql';

export type CreateManagerBdWireMutation = SyndicateWires_CreateManagerBdWireMutation;

export const useCreateManagerBdWireMutation = () => {
  return useSyndicateWires_CreateManagerBdWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_ManagerBdWiresDocument];
  return getManyOperationNames(documentNodes);
}
