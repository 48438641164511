import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_CreateLogisticsMutation,
  useRoadshows_CreateLogisticsMutation,
} from '../graphql';

export type CreateLogisticsMutation = Roadshows_CreateLogisticsMutation;

export const refetchQueries = () => {
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetings!];
};

export const useCreateLogisticsMutation = () => {
  return useRoadshows_CreateLogisticsMutation({ awaitRefetchQueries: true, refetchQueries });
};
