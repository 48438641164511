import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const STitle = styled.span<{ highlight?: boolean }>`
  ${({ theme, highlight }) => (highlight ? `color: ${theme.color.blue2};` : '')}
`;

export const SSubtitle = styled.span.attrs<{ children: React.ReactChildren }>(({ children }) => ({
  children: children && (
    <React.Fragment>
      <Icon name="chevron-right" className="separator" />
      {children}
    </React.Fragment>
  ),
}))`
  .separator {
    margin: 0 10px;
  }
`;

export const SHeaderContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
`;

export const SRightSideGroup = styled.div`
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: nowrap;

  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
