import { assets, FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledFlexContainer = styled(FlexContainer)`
  background-image: url(${assets.terrainBgImage1600});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  flex-grow: 1;
`;

export const SPre = styled.pre`
  word-wrap: break-word;
  white-space: break-spaces;
  word-break: break-word;

  > pre {
    white-space: break-spaces;
  }
`;

export const STimeStamp = styled.span`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  margin-top: 16px;
`;
