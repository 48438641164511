import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import shareIcon from '../../../../common/assets/share-icon.svg';
import { SLogo, SLogoContainer } from './ShareIcon.styles';

const ShareIcon: React.FC = () => (
  <SLogoContainer>
    <SLogo
      src={shareIcon}
      alt="Share icon"
      data-test-id={xcSelectors.myOfferingsDraftOfferingShareHistoryIcon.testId}
    />
  </SLogoContainer>
);

export default ShareIcon;
