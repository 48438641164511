import { Form } from 'formik';
import React from 'react';

import DealTeamMembers from '../../deal-team-details/deal-team-form/deal-team-members-panel/DealTeamMembersPanel';
import Departments from './departments-panel/DepartmentsPanel';

export type Props = {
  dealTeamId: string;
};

const DealTeamForm: React.FC<Props> = ({ dealTeamId }) => {
  return (
    <Form>
      <Departments />
      <DealTeamMembers dealTeamId={dealTeamId} />
    </Form>
  );
};

export default DealTeamForm;
