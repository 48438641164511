import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { ActivitiesTableHeader } from '../../common/activity/ActivitiesTableHeader';
import { RegulatoryFilings_TnFilingPartsFragment } from '../../graphql';
import { ActivitiesTableGroup } from './ActivitiesTableGroup';

export type Props = {
  customActivities: RegulatoryFilings_TnFilingPartsFragment['activities'];
  stabilizationActivities: RegulatoryFilings_TnFilingPartsFragment['stabilizationActivities'];
};

export const ActivityDetail: React.FC<Props> = ({ customActivities, stabilizationActivities }) => {
  return (
    <Panel>
      <Panel.Header title="Activities" />
      <Panel.Content>
        <Table numColumns={3}>
          <ActivitiesTableHeader mode="view" />
          <ActivitiesTableGroup groupType="custom" activities={customActivities} />
          <ActivitiesTableGroup groupType="stabilization" activities={stabilizationActivities} />
        </Table>
      </Panel.Content>
    </Panel>
  );
};
