import { useAuth } from '@cmg/auth';
import { useListCompare } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import {
  InstitutionalDemandGridUpdate,
  UpdateType,
} from './useComputeDiffOnInstitutionalDemandGrid';

const diffKeys = [
  'auditInfo',
  'createdAt',
  'modifiedAt',
  'modifiedByFirmKey',
  'modifiedByUserEmail',
  'submittedByCmgEntityKey',
  'trancheId',
  'instrumentId',
  'currencyCode',
  'interestLevels',
  'interestQuantity',
  'interestUnit',
  'limitPrice',
  'limitType',
  'status',
];

export const indicationChangeLabels = {
  interestQuantity: 'Demand Amount',
  trancheId: 'Tranche',
  instrumentId: 'Instrument',
  currencyCode: 'Currency',
  limitPrice: 'Limit Price',
  limitType: 'Limit Type',
  interestUnit: 'Interest Unit',
  status: 'Status',
};

export enum SpecialChangeLabels {
  NEW_ORDER_ADDED = 'New Order Added',
  NEW_INTEREST_LEVEL_ADDED = 'Interest Level Added/Deleted',
}

type InterestLevel =
  InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number]['interestLevels'][number];

const sortInterestLevel = function (interestLevels: readonly InterestLevel[]) {
  let result: InterestLevel[] = [];
  const copy = [...interestLevels];
  const marketInterestIndex = copy.findIndex(interest => interest.limitType == null);

  if (marketInterestIndex > -1) {
    result.push(copy[marketInterestIndex]);
    copy.splice(marketInterestIndex, 1);
  }

  copy
    .sort((a, b) => b.limitPrice! - a.limitPrice!)
    .sort((a, b) => b.interestQuantity - a.interestQuantity);

  result = result.concat(copy);

  return result;
};

type Indication =
  InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number];

// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
const objectDeepCompare = function (obj1: Indication, obj2: Indication) {
  if (!obj1 || !obj2) {
    return null;
  }

  const result: {
    different: string[];
  } = {
    different: [],
  };

  reduce(
    obj1,
    function (result, value, key) {
      if (
        Object.prototype.hasOwnProperty.call(obj2, key) &&
        ((diffKeys.includes(key) && typeof key != 'number') || typeof key === 'number')
      ) {
        if (isEqual(value, obj2[key])) {
          return result;
        } else {
          // property is not an object, return
          if (typeof obj1[key] != typeof {} || typeof obj2[key] != typeof {}) {
            result.different.push(key);
            return result;
          } else {
            const copyObj1 = key === 'interestLevels' ? sortInterestLevel(obj1[key]) : obj1[key];
            const copyObj2 = key === 'interestLevels' ? sortInterestLevel(obj2[key]) : obj2[key];
            const deeper = objectDeepCompare(copyObj1, copyObj2);
            result.different = result.different.concat(
              map(deeper!.different, sub_path => {
                return key + '.' + sub_path;
              })
            );
            return result;
          }
        }
      } else {
        return result;
      }
    },
    result
  );

  return result;
};

export type institituionalIndicationDemandsArg =
  | InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands']
  | undefined;

export const useComputeDiffOnInstitutionalIndicationDemands = function (
  currInstitutionalIndicationDemands: institituionalIndicationDemandsArg,
  nextInstitutionalIndicationDemands: institituionalIndicationDemandsArg
) {
  const { oidcUserEmail: currentUserEmail } = useAuth();

  const { addedItems: addedIndications, mutatedItems: mutatatedIndications } = useListCompare(
    currInstitutionalIndicationDemands,
    nextInstitutionalIndicationDemands,
    {
      key: item => item.id,
      equals: (a, b) => a.version === b.version,
    }
  );

  return React.useMemo(() => {
    const indicationDemandsChanges: InstitutionalDemandGridUpdate[] = [];

    if (addedIndications.length > 0) {
      for (const item of addedIndications) {
        if (item.auditInfo.modifiedByUserEmail === currentUserEmail) {
          continue;
        }
        indicationDemandsChanges.push({
          type: UpdateType.NEW_ORDERS,
          pendingUpdateLabel: [SpecialChangeLabels.NEW_ORDER_ADDED],
          indicationId: item.id,
          timeStamp: new Date(item.auditInfo.createdAt!),
          id: uuidV4(),
        });
      }
    }

    if (mutatatedIndications.length > 0) {
      for (const item of mutatatedIndications) {
        const prevIndication = item[0];
        const nextIndication = item[1];

        if (nextIndication.auditInfo.modifiedByUserEmail === currentUserEmail) {
          continue;
        }

        if (prevIndication?.interestLevels.length !== nextIndication.interestLevels.length) {
          indicationDemandsChanges.push({
            type: UpdateType.ORDER_UPDATE,
            pendingUpdateLabel: [SpecialChangeLabels.NEW_INTEREST_LEVEL_ADDED],
            indicationId: nextIndication.id,
            timeStamp: new Date(nextIndication.auditInfo.modifiedAt!),
            id: uuidV4(),
          });
        }

        const diffs = objectDeepCompare(prevIndication, nextIndication);
        const changeLabels = Object.keys(indicationChangeLabels)
          .filter(key => {
            return diffs?.different.some(diff => diff.includes(key));
          })
          .map(changeKey => {
            return indicationChangeLabels[changeKey];
          });
        indicationDemandsChanges.push({
          type: UpdateType.ORDER_UPDATE,
          pendingUpdateLabel: changeLabels,
          indicationId: nextIndication.id,
          timeStamp: new Date(nextIndication.auditInfo.modifiedAt!),
          id: uuidV4(),
        });
      }
    }

    return indicationDemandsChanges;
  }, [addedIndications, mutatatedIndications, currentUserEmail]);
};
