import { DocumentNode, OperationVariables } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';

export const makeMockResponse: <TQueryVariables extends OperationVariables, TQueryData extends {}>(
  query: DocumentNode
) => (variables: TQueryVariables, data: TQueryData, delay?: number) => MockedResponse =
  query => (variables, data, delay) => ({
    request: {
      query,
      variables,
    },
    result: {
      data,
    },
    delay,
  });
