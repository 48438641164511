import { Link, Tooltip, Typography, WarningAmber } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  onContactSupport: () => void;
}>;

const RecipientListWarningIcon: React.VFC<Props> = ({ onContactSupport }) => {
  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <React.Fragment>
          <Typography variant="body1">Email distribution lists are not set.</Typography>
          <Link title="Contact Support" onClick={() => onContactSupport()}>
            Contact Support
          </Link>
        </React.Fragment>
      }
    >
      <WarningAmber color="warning" fontSize="small" />
    </Tooltip>
  );
};

export default RecipientListWarningIcon;
