import { numericUtil } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LoadingButton,
  Stack,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../../graphql';
import {
  getRowsWithChangedRetention,
  TotalRetailBulkSaveValues,
} from '../BulkSaveTotalRetailDemand.model';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  open: boolean;
  onCancel: () => void;
  onRelease: () => void;
  isReleasing: boolean;
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[];
  values: TotalRetailBulkSaveValues;
}>;

const ReleaseTotalRetailDemandDialog: React.FC<Props> = ({
  open,
  onCancel,
  onRelease,
  isReleasing,
  rows,
  values,
}) => {
  const changedRows = React.useMemo(
    () => getRowsWithChangedRetention(rows, values),
    [rows, values]
  );

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Release Retentions</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <div>
            You are about to make the following updates to the Retail Retentions. These changes will
            be communicated to the affected underwriters
          </div>

          <Grid container rowGap={2}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                Change From
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                to
              </Typography>
            </Grid>

            {changedRows.map(([{ manager, retention }, { retentionShares }]) => (
              <React.Fragment key={manager.cmgEntityKey}>
                <Grid item xs={4}>
                  {manager.name}
                </Grid>
                <Grid item xs={4}>
                  {getDisplayValueForInteger(retention.shareQuantity)} shares
                </Grid>
                <Grid item xs={4}>
                  {getDisplayValueForInteger(retentionShares)} shares
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} disabled={isReleasing}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={onRelease} loading={isReleasing}>
          Release
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseTotalRetailDemandDialog;
