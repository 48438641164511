import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { FlexContainer, Icon, UUID } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import salesCreditsZeroState from '../../../common/assets/sales-credits-zero-state.svg';
import routeFactory from '../../../common/util/routeFactory';
import { SDetails, SImg, SSubTitle, STitle, SWrapper } from './SalesCreditsEmptyState.styles';

export type Props = { offeringId: UUID; offeringIssuerName?: string };

const SalesCreditsEmptyState: React.FC<Props> = ({ offeringIssuerName, offeringId }) => {
  const theme = useTheme();
  const canPublishDesignations = useCheckPermissions([permissionsByEntity.Designation.FULL]);

  return (
    <SWrapper>
      <STitle>{offeringIssuerName} - Sales Credits Release needs additional actions</STitle>
      <FlexContainer direction="row" gap={8}>
        <div>
          <Icon name="check-circle" variant="solid" color={theme.designSystem.colors.gray['300']} />
        </div>
        <div>
          {canPublishDesignations ? (
            <Link
              to={routeFactory.finalSettlementDesignationMonitor.getUrlPath({ offeringId })}
              target="_blank"
            >
              Final Settlement: Designation Monitor
            </Link>
          ) : (
            <FlexContainer direction="row" gap={8}>
              <SSubTitle>Final Settlement: Designation Monitor</SSubTitle>
              <Icon name="lock-alt" variant="solid" color={theme.text.color.darkGray} />
            </FlexContainer>
          )}
          <SDetails>Designations must be published by the Settlement Agent</SDetails>
        </div>
      </FlexContainer>

      <SImg alt="Sales Credits Release zero state" src={salesCreditsZeroState} />
    </SWrapper>
  );
};

export default SalesCreditsEmptyState;
