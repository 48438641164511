import styled, { css } from 'styled-components/macro';

type sizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type Props = {
  span?: sizes;
};

const Column = styled.div<Props>`
  flex-grow: 0;
  flex-shrink: 0;
  ${({ span, theme }) => css`
    ${span &&
    css`
      flex-basis: ${(span / 12) * 100}%;
    `}
  `}
`;

export default Column;
