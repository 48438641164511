import styled from 'styled-components/macro';

export const SHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  background: ${({ theme }) => theme.background.color.white};
  height: 64px;
  padding: 16px 32px;
  align-items: baseline;
`;

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.large};
  color: ${({ theme }) => theme.text.color.darkGray};
  margin-right: 32px;
`;

export const SLabel = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.tiny};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
  margin-right: 8px;
`;

export const SText = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
  margin-right: 32px;
`;

export const SEmptyContent = styled.div`
  flex-grow: 1;
`;
