import { Option } from '@cmg/common';
import { isBefore, isEqual } from 'date-fns';
import isNil from 'lodash/isNil';
import * as Yup from 'yup';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import {
  ActivityType,
  CreateTnFilingInput,
  PricingBasisType,
  StabilizationTnActivityType,
  TnBasisCodeType,
  TnRestrictedPeriodType,
  TnSecurityType,
  TradeCurrencyType,
  TransactionType,
} from '../../../../../graphql';
import {
  CommonFields,
  commonFilingSchemaShape,
  createInitialCommonFilingValues,
  dateTimeSchema,
  FinraMemberFirmForm,
  FinraNonMemberFirm,
  firmCrdSchema,
  firmMpidNullableSchema,
  getOtherBasisDescription,
  getValueOrNull,
  InitialFilingExtendedValuesParameters,
} from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { affiliateToTNFinraMemberAndNonMembers } from '../../../common/offering-affiliates-utils';
import { AffiliatesAndDistributionParticipants } from '../../../common/regulatory-filings.types';
import { RegulatoryFilings_RolodexUnderwriterFirmPartsFragment } from '../../../graphql';
import { RegulatoryFilings_TnFilingPartsFragment } from '../graphql';

/**
 * Types
 */

export type Activity = {
  activityType: ActivityType | null;
  date: string | null;
  aggregateNumberOfShares: number | null;
};

export type StabilizationActivity = {
  stabilizationActivityType: StabilizationTnActivityType | null;
  date: string | null;
  shares: number | null;
};

export type AtmTrade = {
  tradeDate: string | null;
  numberOfShares: number | null;
  averagePrice: number | null;
  currency: TradeCurrencyType | null;
};

export type TNFilingValues = CommonFields & {
  symbolsOfCoveredSecurities: readonly string[];
  isConcurrentConvertible: string;
  transactionType: TransactionType | null;
  atmTrades: AtmTrade[];
  numberOfShares: number | null;
  offeringPrice: number | null;
  currency: TradeCurrencyType | null;
  pricingDate: string | null;
  secEffective: { date: string | null; timezone: string | null };
  tradeDate: string | null;
  lastSaleBeforeDistributionPrice: number | null;
  lastSaleBeforeDistributionDate: string | null;
  lastSaleBeforeDistributionCurrency: TradeCurrencyType | null;
  transactionTypeDescription: string;
  securityType: TnSecurityType | null;
  securityTypeDescription: string | null | undefined;
  pricingBasisType: PricingBasisType | null;
  pricingBasisDescription: string | null | undefined;
  restrictedPeriod: TnRestrictedPeriodType;
  basisCode: TnBasisCodeType | null | undefined;
  uarDate: string | null;
  adtvNumber: number | null;
  adtvSource: string;
  publicFloatValue: number | null;
  publicFloatValueSource: string;
  otherBasisDescription: string;
  restrictedPeriodStart: {
    date: string | null;
    timezone: string | null;
  };
  restrictedPeriodEnd: {
    date: string | null;
    timezone: string | null;
  };
  finraMemberFirms: FinraMemberFirmForm[];
  nonMemberFirms: FinraNonMemberFirm[];
  activities: Activity[];
  stabilizationActivities: readonly StabilizationActivity[];
};

export const basisCodeOptions: Option<TnBasisCodeType>[] = [
  { label: 'Underwriting Activity Report', value: TnBasisCodeType.Uar },
  { label: 'ADTV and Public Float value', value: TnBasisCodeType.AdtvPfv },
  { label: 'Other', value: TnBasisCodeType.Other },
];

export const typeOfSecurityOptions: Option<TnSecurityType>[] = [
  { label: 'Equity', value: TnSecurityType.Eqty },
  { label: 'Convertible', value: TnSecurityType.Cnvrb },
  { label: 'Preferred', value: TnSecurityType.Prfrd },
  { label: 'Warrant', value: TnSecurityType.Wrrnt },
  { label: 'Other', value: TnSecurityType.Other },
];

export const pricingBasisOptions: Option<PricingBasisType>[] = [
  { label: 'Last Sale', value: PricingBasisType.Lstsl },
  { label: 'Discount to the Last Sale', value: PricingBasisType.Dlsts },
  { label: 'Negotiated', value: PricingBasisType.Ngttd },
  { label: 'At The Market', value: PricingBasisType.Atm },
  { label: 'Other', value: PricingBasisType.Other },
];

export const concurrentConvertibleOptions: Option<string>[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const restrictedPeriodOptions: Option<TnRestrictedPeriodType>[] = [
  { label: '1D', value: TnRestrictedPeriodType.OneDay },
  { label: '5D', value: TnRestrictedPeriodType.FiveDays },
  { label: 'Actively Traded', value: TnRestrictedPeriodType.Na },
];

export const transactionTypeOptions: Option<TransactionType>[] = [
  { label: 'Convertible Equity', value: TransactionType.Cnveq },
  { label: 'Convertible Debt', value: TransactionType.Cnvdt },
  { label: 'Block', value: TransactionType.Block },
  { label: 'Bought Deal', value: TransactionType.Bgtdl },
  { label: 'Exchange Offer', value: TransactionType.Excof },
  { label: 'Merger', value: TransactionType.Mergr },
  { label: 'Acquisition', value: TransactionType.Acqsn },
  { label: 'Best Efforts', value: TransactionType.Bstef },
  { label: 'Non-Convertible Preferred', value: TransactionType.Ncvpr },
  { label: 'Non-Convertible Debt', value: TransactionType.Ncvdt },
  { label: 'Bank Conversion', value: TransactionType.Bnkcv },
  { label: 'Rights Offering', value: TransactionType.Rgtof },
  { label: 'Registered', value: TransactionType.Rgstd },
  { label: 'At-the-Market', value: TransactionType.Atm },
  { label: 'Private Placement or PIPE', value: TransactionType.Pipe },
  { label: 'IPO', value: TransactionType.Ipo },
  { label: 'Follow On', value: TransactionType.Flwon },
  { label: 'Registered Direct', value: TransactionType.Regd },
  { label: 'Other', value: TransactionType.Other },
];

export const currencyOptions: Option<TradeCurrencyType>[] = [
  { label: 'USD', value: TradeCurrencyType.Usd },
  { label: 'CAD', value: TradeCurrencyType.Cad },
];

export const activityTypeOptions: Option<ActivityType>[] = [
  { label: 'Syndicate covering transaction', value: ActivityType.Sct },
  { label: 'Imposition of penalty bid', value: ActivityType.Iopb },
  { label: 'Pre-Stabilization bid', value: ActivityType.Sb },
];

export const stabilizationActivityTypeOptions: Option<StabilizationTnActivityType>[] = [
  { label: 'Syndicate covering transaction', value: StabilizationTnActivityType.Sct },
  { label: 'Pre-Stabilization bid', value: StabilizationTnActivityType.Sb },
];

/**
 * Form Schema
 */

export function isLastSaleBeforeDistributionRequired(transactionType: TransactionType | null) {
  if (!transactionType) {
    return false;
  }

  return ![
    TransactionType.Ipo,
    TransactionType.Cnvdt,
    TransactionType.Cnveq,
    TransactionType.Ncvdt,
    TransactionType.Ncvpr,
    TransactionType.Bnkcv,
    TransactionType.Other,
  ].includes(transactionType);
}

const isUarDateRequired = (
  basisCode: TnBasisCodeType | null | undefined,
  restrictedPeriod: TnRestrictedPeriodType | null
) => restrictedPeriod === TnRestrictedPeriodType.Na && basisCode === TnBasisCodeType.Uar;

const isOtherBasisDescriptionRequired = (
  basisCode: TnBasisCodeType | null | undefined,
  restrictedPeriod: TnRestrictedPeriodType | null
) => restrictedPeriod === TnRestrictedPeriodType.Na && basisCode === TnBasisCodeType.Other;

const isAdtvAndPfvRequired = (
  basisCode: TnBasisCodeType | null | undefined,
  restrictedPeriod: TnRestrictedPeriodType | null
) => restrictedPeriod === TnRestrictedPeriodType.Na && basisCode === TnBasisCodeType.AdtvPfv;

export const isRestrictedPeriodEndAfterStart = (
  restrictedPeriodEnd: string | undefined | null,
  { parent }: { parent: { restrictedPeriodStart?: string | null } }
) => {
  const { restrictedPeriodStart } = parent;

  if (!restrictedPeriodStart || !restrictedPeriodEnd) {
    return true;
  }

  const earlierDate = new Date(restrictedPeriodStart);
  const laterDate = new Date(restrictedPeriodEnd);

  return !isEqual(earlierDate, laterDate) && isBefore(earlierDate, laterDate);
};

export const activitySchemaShape = Yup.object().shape({
  activityType: Yup.string().label('Activity Type').nullable().required(),
  date: Yup.string().label('Activity Date').nullable().required(),
  aggregateNumberOfShares: Yup.number()
    .nullable()
    .positive()
    .label('Aggregate Number of Shares')
    .required(),
});

export const stabilizationActivitySchemaShape = Yup.object().shape({
  stabilizationActivityType: Yup.string().label('Activity Type').nullable().required(),
  date: Yup.string().label('Activity Date').nullable().required(),
  shares: Yup.number().nullable().positive().label('Aggregate Number of Shares').required(),
});

export const FINRAMemberFirmSchema = Yup.object()
  .shape({
    firmName: Yup.string().label('Firm Name').max(1000).required('Firm Name is required'),
    firmCrdNumber: firmCrdSchema
      .label('Member Firm CRD #')
      .required('Member Firm CRD # is required'),
    firmMpid: firmMpidNullableSchema.label('Firm MPID'),
  })
  .required();

export const formSchema = Yup.object().shape({
  ...commonFilingSchemaShape,
  symbolsOfCoveredSecurities: Yup.array()
    .label('Symbols of covered securities')
    .max(10)
    .of(Yup.string().label('Symbol of covered securities').required().max(6)),
  isConcurrentConvertible: Yup.string().label('Concurrent Convertible').required(),
  transactionType: Yup.string().label('Transaction Type').nullable().required(),

  transactionTypeDescription: Yup.string()
    .nullable()
    .label('Transaction Type Description')
    .when('transactionType', {
      is: TransactionType.Other,
      then: schema => schema.max(400).required(),
    }),

  atmTrades: Yup.array().of(
    Yup.object().shape({
      tradeDate: Yup.string().label('Trade Date').nullable().required(),
      numberOfShares: Yup.number().positive().label('Number of Shares').nullable().required(),
      averagePrice: Yup.number().positive().label('Average Price').nullable().required(),
      currency: Yup.string().label('Currency').nullable().required(),
    })
  ),

  securityType: Yup.string().label('Type of Security Offered').nullable().required(),
  securityTypeDescription: Yup.string()
    .nullable()
    .label('Type of Security Offered Description')
    .when('securityType', {
      is: TnSecurityType.Other,
      then: schema => schema.max(400).required(),
    }),

  numberOfShares: Yup.number()
    .positive()
    .nullable()
    .label('Number of Shares')
    .when('transactionType', {
      is: transactionType => transactionType !== TransactionType.Atm,
      then: schema => schema.nullable().required(),
    }),
  offeringPrice: Yup.number()
    .positive()
    .nullable()
    .label('Offering Price')
    .when('transactionType', {
      is: transactionType => transactionType !== TransactionType.Atm,
      then: schema => schema.nullable().required(),
    }),
  currency: Yup.string()
    .nullable()
    .label('Currency')
    .when('transactionType', {
      is: transactionType => transactionType !== TransactionType.Atm,
      then: schema => schema.nullable().required(),
    }),
  pricingDate: Yup.string()
    .nullable()
    .label('Pricing Date')
    .when('transactionType', {
      is: transactionType => transactionType !== TransactionType.Atm,
      then: schema => schema.nullable().required(),
    }),
  secEffective: dateTimeSchema.label('SEC Effective Date and Time'),
  tradeDate: Yup.string()
    .nullable()
    .label('Trade Date')
    .when('transactionType', {
      is: transactionType => transactionType !== TransactionType.Atm,
      then: schema => schema.nullable().required(),
    }),
  lastSaleBeforeDistributionPrice: Yup.number()
    .positive()
    .nullable()
    .label('Last Date Before Distribution Price')
    .when('transactionType', {
      is: transactionType =>
        transactionType !== TransactionType.Atm &&
        isLastSaleBeforeDistributionRequired(transactionType),
      then: schema => schema.nullable().required(), // TODO 6 decimals
    }),
  lastSaleBeforeDistributionCurrency: Yup.string()
    .nullable()
    .label('Last Date Before Distribution Currency')
    .when('transactionType', {
      is: transactionType =>
        transactionType !== TransactionType.Atm &&
        isLastSaleBeforeDistributionRequired(transactionType),
      then: schema => schema.nullable().required(),
    }),
  lastSaleBeforeDistributionDate: Yup.string()
    .nullable()
    .label('Last Date Before Distribution Currency Date')
    .when('transactionType', {
      is: transactionType =>
        transactionType !== TransactionType.Atm &&
        isLastSaleBeforeDistributionRequired(transactionType),
      then: schema => schema.nullable().required(),
    }),

  pricingBasisType: Yup.string().label('Pricing Basis').nullable().required(),
  pricingBasisDescription: Yup.string()
    .nullable()
    .label('Pricing Basis Description')
    .when('pricingBasisType', {
      is: PricingBasisType.Other,
      then: schema => schema.max(400).required(),
    }),

  restrictedPeriod: Yup.string().label('Restricted Period').required(),

  restrictedPeriodStart: dateTimeSchema
    .label('Restricted Period Commencement Date and Time')
    .when('restrictedPeriod', {
      is: restrictedPeriod =>
        restrictedPeriod === TnRestrictedPeriodType.OneDay ||
        restrictedPeriod === TnRestrictedPeriodType.FiveDays,
      then: schema => schema.required(),
    }),
  restrictedPeriodEnd: dateTimeSchema
    .label('Restricted Period End Date and Time')
    .when('restrictedPeriod', {
      is: restrictedPeriod =>
        restrictedPeriod === TnRestrictedPeriodType.OneDay ||
        restrictedPeriod === TnRestrictedPeriodType.FiveDays,
      then: schema => schema.required(),
    })
    .test(
      'end-date-after-start',
      'Restricted Period End Date and Time must be later than the start date',
      isRestrictedPeriodEndAfterStart
    ),

  finraMemberFirms: Yup.array().of(FINRAMemberFirmSchema).required(),

  nonMemberFirms: Yup.array()
    .of(
      Yup.object()
        .required()
        .shape({
          firmName: Yup.string().label('Firm Name').max(1000).required(),
        })
    )
    .required(),

  basisCode: Yup.string()
    .label('Basis Code')
    .nullable()
    .when('restrictedPeriod', {
      is: TnRestrictedPeriodType.Na,
      then: schema => schema.required(),
    }),

  uarDate: Yup.string()
    .nullable()
    .label('Underwriter Activity Report Date')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isUarDateRequired,
      then: schema => schema.nullable().required(),
    }),
  adtvNumber: Yup.number()
    .positive()
    .nullable()
    .label('ADTV Number')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isAdtvAndPfvRequired,
      then: schema => schema.nullable().required(),
    }),
  adtvSource: Yup.string()
    .nullable()
    .label('ADTV Number Source')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isAdtvAndPfvRequired,
      then: schema => schema.nullable().max(250).required(),
    }),
  publicFloatValue: Yup.number()
    .positive()
    .nullable()
    .label('Public Float Value')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isAdtvAndPfvRequired,
      then: schema => schema.nullable().required(),
    }),
  publicFloatValueSource: Yup.string()
    .nullable()
    .label('Public Float Value Source')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isAdtvAndPfvRequired,
      then: schema => schema.nullable().max(250).required(),
    }),
  otherBasisDescription: Yup.string()
    .nullable()
    .label('Basis Code Description')
    .when(['basisCode', 'restrictedPeriod'], {
      is: isOtherBasisDescriptionRequired,
      then: schema => schema.nullable().required(),
    }),

  activities: Yup.array().of(activitySchemaShape).required(),
  stabilizationActivities: Yup.array().of(stabilizationActivitySchemaShape).required(),
});

/**
 * Filing => Form
 */
// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export function mapFilingToFormValues(
  filing: RegulatoryFilings_TnFilingPartsFragment,
  stabilizationActivities: readonly StabilizationActivity[] = [],
  affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants,
  underwriter?: RegulatoryFilings_RolodexUnderwriterFirmPartsFragment
): TNFilingValues {
  const { finraMemberFirms, finraNonMemberFirms } = affiliateToTNFinraMemberAndNonMembers(
    affiliatesAndDistributionParticipants
  );

  return {
    firmName: filing.firmName,
    firmCrdNumber: underwriter?.crd ?? '',
    firmMpid: underwriter?.mpid ?? '',
    contactName: filing.contactName,
    contactTitle: filing.contactTitle,
    telephoneNumber: filing.telephoneNumber,
    emailAddress: filing.emailAddress,
    symbolsOfCoveredSecurities: filing.symbolsOfCoveredSecurities,
    isConcurrentConvertible: filing.isConcurrentConvertible ? 'true' : 'false',
    transactionType: filing.transactionType,
    atmTrades: filing.atmTrades.map(({ __typename, ...trade }) => trade),
    numberOfShares: filing.nonAtmTradeInfo?.numberOfShares ?? null,
    offeringPrice: filing.nonAtmTradeInfo?.offeringPrice ?? null,
    currency: filing.nonAtmTradeInfo?.currency ?? null,
    pricingDate: filing.nonAtmTradeInfo?.pricingDate ?? null,
    secEffective: {
      date: filing.nonAtmTradeInfo?.secEffectiveDateTime ?? null,
      timezone: filing.nonAtmTradeInfo?.secEffectiveTimezone ?? null,
    },
    tradeDate: filing.nonAtmTradeInfo?.tradeDate ?? null,
    lastSaleBeforeDistributionPrice:
      filing.nonAtmTradeInfo?.lastSaleBeforeDistributionPrice ?? null,
    lastSaleBeforeDistributionDate: filing.nonAtmTradeInfo?.lastSaleBeforeDistributionDate ?? null,
    lastSaleBeforeDistributionCurrency:
      filing.nonAtmTradeInfo?.lastSaleBeforeDistributionCurrency ?? null,
    transactionTypeDescription: filing.transactionTypeDescription || '',
    securityType: filing.securityType,
    securityTypeDescription: filing.securityTypeDescription,
    pricingBasisType: filing.pricingBasisType,
    pricingBasisDescription: filing.pricingBasisDescription,
    restrictedPeriod: filing.restrictedPeriod,
    basisCode: filing.basisCode,
    uarDate: filing.uarDate || '',
    adtvNumber: !isNil(filing.adtvNumber) ? filing.adtvNumber : null,
    adtvSource: filing.adtvSource || '',
    publicFloatValue: !isNil(filing.publicFloatValue) ? filing.publicFloatValue! : null,
    publicFloatValueSource: filing.publicFloatValueSource || '',
    otherBasisDescription: filing.otherBasisDescription || '',
    restrictedPeriodStart: {
      date: filing.restrictedPeriodStartDateTime ?? '',
      timezone: filing.restrictedPeriodStartTimezone ?? '',
    },
    restrictedPeriodEnd: {
      date: filing.restrictedPeriodEndDateTime ?? '',
      timezone: filing.restrictedPeriodEndTimezone ?? '',
    },
    finraMemberFirms,
    nonMemberFirms: finraNonMemberFirms,
    activities: filing.activities.map(({ activityType, date, aggregateNumberOfShares }) => ({
      activityType,
      date,
      aggregateNumberOfShares,
    })),
    stabilizationActivities,
    issuerSymbol: filing.issuerSymbol,
    issuerName: filing.issuerName,
  };
}

/**
 * Form => Filing
 */
// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export function mapFormValuesToFiling(
  values: TNFilingValues,
  recipients: string[],
  shouldAddSenderToBcc: boolean
): CreateTnFilingInput {
  return {
    recipients,
    filingFormData: {
      activities: values.activities as RegulatoryFilings_TnFilingPartsFragment['activities'],
      stabilizationActivities: values.stabilizationActivities.map(activity => ({
        stabilizationActivityType: activity.stabilizationActivityType!,
        date: activity.date!,
        shares: activity.shares!,
      })),
      atmTrades:
        values.transactionType === TransactionType.Atm
          ? (values.atmTrades as RegulatoryFilings_TnFilingPartsFragment['atmTrades'])
          : [],
      isConcurrentConvertible: values.isConcurrentConvertible === 'true',
      contactName: values.contactName,
      contactTitle: values.contactTitle,
      emailAddress: values.emailAddress,
      firmCrdNumber: values.firmCrdNumber,
      firmMpid: values.firmMpid,
      firmName: values.firmName,
      memberFirms: values.finraMemberFirms.map(({ formIndex, ownerCmgEntityKey, ...formData }) => ({
        ...formData,
        firmMpid: getValueOrNull(formData.firmMpid),
      })),
      nonAtmTradeInfo:
        values.transactionType !== TransactionType.Atm
          ? {
              numberOfShares: values.numberOfShares!,
              offeringPrice: values.offeringPrice!,
              currency: values.currency!,
              pricingDate: values.pricingDate!,
              secEffectiveDateTime: getValueOrNull(values.secEffective.date),
              secEffectiveTimezone: getValueOrNull(values.secEffective.timezone),
              tradeDate: values.tradeDate!,
              lastSaleBeforeDistributionPrice: isLastSaleBeforeDistributionRequired(
                values.transactionType
              )
                ? values.lastSaleBeforeDistributionPrice!
                : null,
              lastSaleBeforeDistributionDate: isLastSaleBeforeDistributionRequired(
                values.transactionType
              )
                ? values.lastSaleBeforeDistributionDate!
                : null,
              lastSaleBeforeDistributionCurrency: isLastSaleBeforeDistributionRequired(
                values.transactionType
              )
                ? values.lastSaleBeforeDistributionCurrency!
                : null,
            }
          : null,
      nonMemberFirms: values.nonMemberFirms,
      pricingBasisType: values.pricingBasisType!,
      pricingBasisDescription:
        values.pricingBasisType === PricingBasisType.Other ? values.pricingBasisDescription : null,
      basisCode: values.restrictedPeriod === TnRestrictedPeriodType.Na ? values.basisCode : null,
      uarDate: isUarDateRequired(values.basisCode, values.restrictedPeriod)
        ? getValueOrNull(values.uarDate)
        : null,
      adtvNumber: isAdtvAndPfvRequired(values.basisCode, values.restrictedPeriod)
        ? values.adtvNumber
        : null,
      adtvSource: isAdtvAndPfvRequired(values.basisCode, values.restrictedPeriod)
        ? values.adtvSource
        : null,
      publicFloatValue: isAdtvAndPfvRequired(values.basisCode, values.restrictedPeriod)
        ? values.publicFloatValue
        : null,
      publicFloatValueSource: isAdtvAndPfvRequired(values.basisCode, values.restrictedPeriod)
        ? values.publicFloatValueSource
        : null,
      otherBasisDescription: isOtherBasisDescriptionRequired(
        values.basisCode,
        values.restrictedPeriod
      )
        ? values.otherBasisDescription
        : null,
      restrictedPeriod: values.restrictedPeriod,
      restrictedPeriodStartDateTime:
        values.restrictedPeriod !== TnRestrictedPeriodType.Na
          ? values.restrictedPeriodStart?.date
          : null,
      restrictedPeriodStartTimezone:
        values.restrictedPeriod !== TnRestrictedPeriodType.Na
          ? values.restrictedPeriodStart?.timezone
          : null,
      restrictedPeriodEndDateTime:
        values.restrictedPeriod !== TnRestrictedPeriodType.Na
          ? values.restrictedPeriodEnd?.date
          : null,
      restrictedPeriodEndTimezone:
        values.restrictedPeriod !== TnRestrictedPeriodType.Na
          ? values.restrictedPeriodEnd?.timezone
          : null,
      securityType: values.securityType!,
      securityTypeDescription:
        values.securityType === TnSecurityType.Other ? values.securityTypeDescription : null,
      symbolsOfCoveredSecurities: values.symbolsOfCoveredSecurities,
      telephoneNumber: values.telephoneNumber,
      transactionType: values.transactionType!,
      transactionTypeDescription:
        values.transactionType === TransactionType.Other ? values.transactionTypeDescription : null,
      issuerSymbol: values.issuerSymbol!,
    },
    submissionTimeZone: generatePayloadDateTimeData().sendDateTimeZone,
    shouldAddSenderToBcc,
  };
}

/**
 * Initial values
 */

export function createInitialFormValues(
  {
    offeringType,
    underwriter,
    filing,
    lastCommonValues,
    offeringDetail,
    stabilizationActivities,
    restrictedPeriod = TnRestrictedPeriodType.Na,
    affiliatesAndDistributionParticipants,
  }: InitialFilingExtendedValuesParameters<RegulatoryFilings_TnFilingPartsFragment> & {
    stabilizationActivities?: readonly StabilizationActivity[];
    restrictedPeriod?: TnRestrictedPeriodType;
  } = {
    affiliatesAndDistributionParticipants: {
      affiliates: [],
      distributionParticipants: [],
    },
  }
): TNFilingValues {
  if (filing) {
    return mapFilingToFormValues(
      filing,
      stabilizationActivities,
      affiliatesAndDistributionParticipants,
      underwriter
    );
  }

  const { finraMemberFirms, finraNonMemberFirms } = affiliateToTNFinraMemberAndNonMembers(
    affiliatesAndDistributionParticipants
  );

  return {
    ...createInitialCommonFilingValues({ underwriter, lastCommonValues, offeringDetail }),
    symbolsOfCoveredSecurities: [underwriter?.ticker || ''],
    isConcurrentConvertible: 'false',
    transactionType: null,
    atmTrades: [],
    numberOfShares: offeringDetail?.totalSharesBaseOffering!,
    offeringPrice: offeringDetail?.offerPrice!,
    currency: TradeCurrencyType.Usd,
    pricingDate: offeringDetail?.pricingDate!,
    secEffective: {
      date: null,
      timezone: null,
    },
    tradeDate: offeringDetail?.tradeDate!,
    lastSaleBeforeDistributionPrice: offeringDetail?.lastTradeBeforeOffer!,
    lastSaleBeforeDistributionDate: null,
    lastSaleBeforeDistributionCurrency: null,
    transactionTypeDescription: '',
    securityType: null,
    securityTypeDescription: '',
    pricingBasisType: null,
    pricingBasisDescription: '',
    restrictedPeriod,
    basisCode: TnBasisCodeType.Other,
    uarDate: null,
    adtvNumber: null,
    adtvSource: '',
    publicFloatValue: null,
    publicFloatValueSource: '',
    otherBasisDescription: getOtherBasisDescription(offeringType),
    restrictedPeriodStart: {
      date: null,
      timezone: null,
    },
    restrictedPeriodEnd: {
      date: null,
      timezone: null,
    },
    finraMemberFirms,
    nonMemberFirms: finraNonMemberFirms,
    activities: [],
    stabilizationActivities: stabilizationActivities ?? [],
  };
}
