import { numericUtil, UUID } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LoadingButton,
  SnackbarManager,
  Stack,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { useSubmitRetailDemandMutation } from './hooks/useSubmitRetailDemandMutation';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  open: boolean;
  onClose: () => void;
  totalRetailDemand: number | null;
  totalRoundLots: number | null;
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
}>;

const SubmitRetailDemandDialog: React.FC<Props> = ({
  open,
  onClose,
  totalRetailDemand,
  totalRoundLots,
  offeringId,
  instrumentId,
  trancheId,
}) => {
  const [submitRetail, { loading }] = useSubmitRetailDemandMutation();

  const handleSubmit = React.useCallback(async () => {
    try {
      await submitRetail({ variables: { offeringId, payload: { instrumentId, trancheId } } });

      onClose();
    } catch {
      SnackbarManager.error('Failed to submit Retail Demand');
    }
  }, [offeringId, instrumentId, trancheId, onClose, submitRetail]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Submit Retail Demand to Settlement Agent</DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" gap={2}>
          <Typography variant="body1">
            Are you sure you want to submit the following information to the settlement agent:
          </Typography>

          <Grid container rowGap={2}>
            <Grid item xs={5}>
              <Typography variant="body1" color="text.secondary">
                Total Retail Demand
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1">
                {getDisplayValueForInteger(totalRetailDemand)}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="body1" color="text.secondary">
                Round Lots
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1">{getDisplayValueForInteger(totalRoundLots)}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitRetailDemandDialog;
