import { IconButton, ListItemText, Menu, MenuItem, MoreVertOutlinedIcon } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  actions: {
    label: string;
    onClick: () => void;
  }[];
  'aria-label'?: string;
}>;

const ListItemKebabMenu: React.FC<Props> = ({ actions, 'aria-label': label }) => {
  const btnRef = React.useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = React.useState(false);

  const isKebabEnabled = actions.length > 0;

  const handleDropdownClick = React.useCallback(() => {
    setIsOpened(isOpen => !isOpen);
  }, []);
  const handleClose = React.useCallback(() => {
    setIsOpened(false);
  }, []);

  return (
    <div>
      <IconButton
        aria-label={label}
        edge="end"
        ref={btnRef}
        onClick={handleDropdownClick}
        disabled={!isKebabEnabled}
      >
        <MoreVertOutlinedIcon />
      </IconButton>

      <Menu open={isOpened} onClose={handleClose} anchorEl={btnRef.current} onBlur={handleClose}>
        {actions.map(({ label, onClick }) => (
          <MenuItem
            dense
            key={label}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ListItemKebabMenu;
