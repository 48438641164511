import { Stack, styled, TableCell, TableCellProps, Typography } from '@cmg/design-system';
import React from 'react';

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== 'isEditable';
  },
})<{ isEditable?: boolean }>`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  ${({ theme, isEditable }) => isEditable && `padding-right: ${theme.spacing(3)}`};
`;

export type Props = TableCellProps &
  Readonly<{
    isEditable?: boolean;
    IconComponent?: React.ReactNode;
    secondaryText?: string;
  }>;

const TableTotalRowCell: React.FC<Props> = ({
  isEditable,
  IconComponent,
  secondaryText,
  children,
  ...props
}) => {
  return (
    <TableCellStyled isEditable={isEditable} {...props}>
      <Stack direction="row" alignItems="center">
        {IconComponent}
        <Stack direction="column" flex={1}>
          {children}
          {secondaryText && <Typography variant="caption">{secondaryText}</Typography>}
        </Stack>
      </Stack>
    </TableCellStyled>
  );
};

export default TableTotalRowCell;
