import { FlexContainer, ODSTable as Table, timeUtil } from '@cmg/common';
import React from 'react';

import { EmailDeliveryStatus } from '../../../../../../../graphql';
import ProspectusDeliveryEmailStatus from '../../../../../../order-book/components/prospectus-delivery-status/ProspectusDeliveryEmailStatus';
import { ErrorIcon } from '../../../components/error-icon/ErrorIcon';
import { ComplianceProspectus_LatestEmailStatusPartsFragment } from '../../graphql';

export type Props = {
  revision: ComplianceProspectus_LatestEmailStatusPartsFragment;
};

export const ProspectusHistoryTableRow: React.FC<Props> = ({ revision }) => {
  const hasError = revision.status === EmailDeliveryStatus.Failed;

  return (
    <Table.Row>
      <Table.Cell centered paddingSize="S">
        {hasError && <ErrorIcon errorMessage={revision.statusInfo} />}
      </Table.Cell>
      <Table.Cell centered paddingSize="S">
        {timeUtil.formatAsDisplayDateTime(revision.sendRequestedAt)}
      </Table.Cell>
      <Table.Cell centered paddingSize="S">
        <ProspectusDeliveryEmailStatus status={revision.status} />
      </Table.Cell>
      <Table.Cell centered paddingSize="S">
        <FlexContainer direction="column">
          <div>{revision.recipientName}</div>
          <div>{revision.recipientEmail}</div>
        </FlexContainer>
      </Table.Cell>
    </Table.Row>
  );
};
