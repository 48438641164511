import { Option } from '@cmg/common';
import { GridApi } from 'ag-grid-community';

import { SalesCreditReleaseStatus } from '../../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { salesCreditsCols } from '../SalesCreditsGrid.model';

export type DropdownItem = SalesCreditReleaseStatus | 'ALL';
export const dropdownItems: Option<DropdownItem>[] = [
  { label: 'Select All', value: 'ALL' },
  { label: 'Unreleased', value: SalesCreditReleaseStatus.Unreleased },
  { label: 'Released', value: SalesCreditReleaseStatus.Released },
  { label: 'Cancel Pending', value: SalesCreditReleaseStatus.CancelPending },
  { label: 'Release Failed', value: SalesCreditReleaseStatus.ReleaseFailed },
];

const refreshTotalRow = (api: GridApi) => {
  api.refreshCells({
    force: true,
    columns: [salesCreditsCols.selected],
  });
};

export const handleCheckboxChange = (api: GridApi, isChecked: boolean) => {
  if (isChecked) {
    api.selectAll();
  } else {
    api.deselectAll();
  }

  refreshTotalRow(api);
};

export const handleDropdownSelectChange = (api: GridApi, values: DropdownItem[]) => {
  api.deselectAll();
  values.forEach(value => {
    api.forEachNode(node => {
      const { status } = node.data as SalesCredits_SalesCreditItemPartsFragment;

      if (value === 'ALL' || status === value) {
        node.setSelected(true);
      }
    });
  });

  refreshTotalRow(api);
};
