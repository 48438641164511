import { Icon, SubNavigation, UUID } from '@cmg/common';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../common/components/layout/panel/Panel';
import routeFactory from '../../../../common/util/routeFactory';
import { StyledSubNavigationLink } from '../../FinalSettlementRoute.styles';
import DealRelatedExpensesRoute from '../deal-related-expenses/DealRelatedExpensesRoute';
import { FinalSettlement_ExpensesQuery } from '../graphql';
import ManagerExpensesRoute from '../manager-expenses/ManagerExpensesRoute';
import { SIconWrapper, SSubNavigationWrapper, SWrapper } from './ExpensesPanel.styles';
import TotalExpenses from './total-expenses/TotalExpenses';

export type Props = {
  offeringId: UUID;
  editable: boolean;
  managers: FinalSettlement_ExpensesQuery['expenses']['managers'];
  managerExpensesError: boolean;
  dealRelatedExpensesError: boolean;
};

const ExpensesPanel: React.FC<Props> = ({
  offeringId,
  editable,
  managers,
  managerExpensesError,
  dealRelatedExpensesError,
}) => {
  const theme = useTheme();

  return (
    <Panel>
      <Panel.Header title="Expenses" />
      <Panel.Content paddingSize="S">
        <SWrapper>
          <SSubNavigationWrapper>
            <SubNavigation>
              <SubNavigation.Group>
                <SubNavigation.Item>
                  <StyledSubNavigationLink
                    to={routeFactory.finalSettlementManagerExpenses.getUrlPath({
                      offeringId,
                    })}
                  >
                    Manager Expenses
                    {managerExpensesError && (
                      <SIconWrapper>
                        <Icon
                          name="exclamation-triangle"
                          variant="solid"
                          color={theme.designSystem.colors.red['300']}
                        />
                      </SIconWrapper>
                    )}
                  </StyledSubNavigationLink>
                </SubNavigation.Item>
                <SubNavigation.Item>
                  <StyledSubNavigationLink
                    to={routeFactory.finalSettlementDealRelatedExpenses.getUrlPath({
                      offeringId,
                    })}
                  >
                    Deal Related Expenses
                    {dealRelatedExpensesError && (
                      <SIconWrapper>
                        <Icon
                          name="exclamation-triangle"
                          variant="solid"
                          color={theme.designSystem.colors.red['300']}
                        />
                      </SIconWrapper>
                    )}
                  </StyledSubNavigationLink>
                </SubNavigation.Item>
              </SubNavigation.Group>
            </SubNavigation>
          </SSubNavigationWrapper>
          <TotalExpenses />
        </SWrapper>
        <Switch>
          <Route
            path={routeFactory.finalSettlementManagerExpenses.routePath}
            render={() => <ManagerExpensesRoute managers={managers} editable={editable} />}
          />
          <Route
            path={routeFactory.finalSettlementDealRelatedExpenses.routePath}
            render={() => <DealRelatedExpensesRoute editable={editable} />}
          />
          <Route
            path={routeFactory.finalSettlementExpenses.routePath}
            render={() => (
              <Redirect
                to={routeFactory.finalSettlementManagerExpenses.getUrlPath({ offeringId })}
              />
            )}
          />
        </Switch>
      </Panel.Content>
    </Panel>
  );
};

export default ExpensesPanel;
