import { UUID } from '@cmg/common';

import { useFinalSettlement_UpdateAccountingNotesMutation } from '../graphql';

export const useUpdateAccountingNotesMutation = (offeringId: UUID) => {
  return useFinalSettlement_UpdateAccountingNotesMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({ __typename: 'Accounting', id: offeringId }),
        fields: {
          notes: () => {
            return data?.updateAccountingNotes.notes ?? '';
          },
        },
      });
    },
  });
};
