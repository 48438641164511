import { ApolloError } from '@apollo/client';
import { numericUtil } from '@cmg/common';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableRow,
  TableSkeleton,
} from '@cmg/design-system';
import React from 'react';

import { SyndicateWiresManagerRole } from '../../../../../../graphql';
import { syndicateWireManagerRoleLabels } from '../../../../../../types/domain/syndicate-wires/constants';
import { useOnFormDataLoaded } from '../../hooks/useOnFormDataLoaded';
import { LoadingErrorSection } from '../dialogs/create-or-update-wire-dialog/error-loading-recipients-data-section/LoadingErrorSection';
import { WireFormBanner } from '../modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { ManagerEconomicSharesTableHead } from './ManagerEconomicSharesTableHead';

type Manager = {
  firmName: string;
  cmgEntityKey: string;
  shares: number | null | undefined;
};

export type ManagerEconomicsRoleGroup = [SyndicateWiresManagerRole, Manager[]];

export type Props = Readonly<{
  readonly label?: string;
  readonly managerGroups: ManagerEconomicsRoleGroup[];
  readonly loading: boolean;
  readonly refetch: () => void;
  readonly error: ApolloError | undefined;
  readonly sharesColLabel?: string;
  readonly onDataLoaded?: (isSuccess: boolean) => void;
}>;

export const ManagerEconomicSharesTable: React.FC<Props> = ({
  label = 'Manager Economic Shares',
  managerGroups,
  loading,
  error,
  refetch,
  sharesColLabel = 'Underwriting Commitment (Shares)',
  onDataLoaded,
}) => {
  useOnFormDataLoaded({ onFormDataLoaded: onDataLoaded, loading, error });

  if (loading) {
    return <TableSkeleton aria-label={label} cellSize="medium" numOfColumns={2} />;
  }

  if (error) {
    return (
      <LoadingErrorSection
        tableLabel={label}
        tableHead={<ManagerEconomicSharesTableHead sharesColLabel={sharesColLabel} />}
        onRefetch={refetch}
      />
    );
  }

  return (
    <Stack gap={3}>
      <WireFormBanner variant="manager-specific-readonly" />
      <TableContainer>
        <Table aria-label={label}>
          <ManagerEconomicSharesTableHead sharesColLabel={sharesColLabel} />
          {managerGroups.map(([role, managers]) => (
            <TableBody key={role} aria-label={syndicateWireManagerRoleLabels[role]}>
              <TableGroupHeader>
                <TableGroupHeaderCell colSpan={2}>
                  {syndicateWireManagerRoleLabels[role]}
                </TableGroupHeaderCell>
              </TableGroupHeader>
              {managers.map(manager => (
                <TableRow key={manager.cmgEntityKey}>
                  <TableCell size="medium">{manager.firmName}</TableCell>
                  <TableCell size="medium" align="right">
                    {numericUtil.getDisplayValueForNumber(manager.shares, 0)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </Stack>
  );
};
