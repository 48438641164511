import { AgGridColumnApi } from '@cmg/common';
import { Link } from '@cmg/design-system';
import React from 'react';

import GridWarningTooltip from '../../../../../../../design-system/components/data-display/grid-warning-tooltip/GridWarningTooltip';
import { DesignationMonitorGridContext } from '../../../../hooks/useDesignationContext';
import { DesignationMonitorColumns } from '../../DesignationMonitorGrid.model';
import {
  rowContainsErrors,
  rowContainsNonApplicableWarning,
} from './DesignationMonitorWarningsRenderer.model';

const sorting = new Map<string | null | undefined, string | null>([
  ['asc', null],
  ['desc', 'asc'],
  [null, 'desc'],
]);

export type Props = Readonly<{
  context: DesignationMonitorGridContext;
  columnApi: AgGridColumnApi;
  setSort: (sort: string | null | undefined) => void;
}>;

const DesignationMonitorHeaderWarningRenderer: React.FC<Props> = ({
  context,
  columnApi,
  setSort,
}) => {
  const handleSort = React.useCallback(() => {
    const currentSort = columnApi
      .getColumnState()
      .find(col => col.colId === DesignationMonitorColumns.WARNINGS)?.sort;
    const nextSort = sorting.get(currentSort);

    setSort(nextSort);
  }, [columnApi, setSort]);

  const { isErrorVariant, rowsRequiringAttention } = React.useMemo(() => {
    const rowsRequiringAttention = context.investorRows.filter(
      ({ warnings }) => warnings.length > 0
    ).length;
    const isErrorVariant = context.investorRows.some(
      ({ warnings }) => rowContainsErrors(warnings) && !rowContainsNonApplicableWarning(warnings)
    );

    return {
      isErrorVariant,
      rowsRequiringAttention,
    };
  }, [context.investorRows]);

  if (rowsRequiringAttention > 0) {
    return (
      <GridWarningTooltip variant={isErrorVariant ? 'error' : 'warning'}>
        <div>
          {rowsRequiringAttention} investor{rowsRequiringAttention > 1 ? 's' : ''} requires
          attention
        </div>
        <Link component="button" variant="caption" onClick={handleSort}>
          Sort by investors with alerts
        </Link>
      </GridWarningTooltip>
    );
  }

  return <div />;
};

export default DesignationMonitorHeaderWarningRenderer;
