import { dateTimeUtil } from '@cmg/common';
import { ListItem, ListItemText, ListStatusItem, ListStatusItemContent } from '@cmg/design-system';
import React from 'react';

import type { RecentUpdate } from '../hooks/useRecentUpdates';
import { ChangeTypeContentMap } from './RecentUpdateListItem.model';

export type Props = Readonly<{
  divider: boolean;
  update: RecentUpdate;
  pricingCurrencyCode: string;
}>;

export const RecentUpdateListItem: React.FC<Props> = ({ divider, ...rest }) => {
  const { update } = rest;
  const subtitle = `${update.manager} | ${dateTimeUtil.getDisplayValueForDateAndTime(
    update.timestamp
  )}`;

  const ContentComponent = ChangeTypeContentMap[update.changeType];

  if (!ContentComponent) {
    return null;
  }

  if (update.isRead) {
    return (
      <ListItem divider={divider}>
        <ListItemText primary={<ContentComponent {...rest} />} secondary={subtitle} />
      </ListItem>
    );
  }

  return (
    <ListStatusItem divider={divider}>
      <ListStatusItemContent
        status="error"
        title={<ContentComponent {...rest} />}
        subTitle={subtitle}
      />
    </ListStatusItem>
  );
};
