import {
  DatePickerField,
  ISODate,
  ODSTimePickerField as TimePickerField,
  SelectField,
  TextAreaField,
  TextInputField,
  UnsavedChangesGuard,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import Column from '../../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../../common/components/layout/grid-layout/GridLayout';
import { MeetingFormat } from '../../../../../../../graphql';
import {
  Roadshows_ManagementTeamMemberPartsFragment,
  Roadshows_MeetingPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';
import { useRoadshows_RoadshowDealTeamsQuery } from '../../../../roadshow-configuration/deal-team-details/graphql';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import EventFormSubHeader from '../event-form-subheader/EventFormSubHeader';
import MeetingButtonGroup from '../meeting-button-group/MeetingButtonGroup';
import {
  getLocationOptions,
  getMeetingFormValues,
  getUnderwriterOptions,
  getValidationSchema,
  isRoadshowDayVirtual,
  meetingFormatOptions,
  meetingTypeOptions,
  MeetingValues,
} from './MeetingDetailsForm.model';
import { StyledForm } from './MeetingDetailsForm.styles';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  meeting?: Roadshows_MeetingPartsFragment;
  meetingManagementMembers?: readonly Roadshows_ManagementTeamMemberPartsFragment[];
  meetingHeaderInfo: EventInfo | null;
  selectedDate?: string;
  onSubmit: (payload: MeetingValues) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onOpenToSales?: () => void;
  onConfirm?: () => void;
  isSaving: boolean;
  isCanceling?: boolean;
  isDeleting?: boolean;
  isOpeningToSales?: boolean;
  isConfirming?: boolean;
};

const MeetingDetailsForm: React.FC<Props> = ({
  onSubmit,
  roadshow,
  meeting,
  onDelete,
  onCancel,
  onOpenToSales,
  onConfirm,
  meetingHeaderInfo,
  isSaving,
  isDeleting = false,
  isOpeningToSales = false,
  isConfirming = false,
  isCanceling = false,
  selectedDate,
  meetingManagementMembers,
}) => {
  const { id: roadshowId, roadshowDays, startDate, endDate, status: roadshowStatus } = roadshow;

  const formik = useFormik<MeetingValues>({
    validationSchema: getValidationSchema(roadshowDays),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: getMeetingFormValues({
      meeting: meeting?.meetingDetails,
      selectedDate,
      roadshowDays,
    }),
    onSubmit,
  });
  const { data: dealTeamsData, loading: dealTeamsLoading } = useRoadshows_RoadshowDealTeamsQuery({
    variables: { roadshowId },
  });

  const underwriterOptions = React.useMemo(() => {
    return getUnderwriterOptions(dealTeamsData);
  }, [dealTeamsData]);

  const { dirty, values, setFieldValue, resetForm, isSubmitting, setFieldTouched } = formik;

  const isVirtualDay = isRoadshowDayVirtual(roadshowDays, values.date);
  const locationOptions = getLocationOptions(values.date, roadshowDays);
  const isLocationVisible = !!values.date;

  const handleDateChange = (nextDate: ISODate | null) => {
    const roadshowDay = roadshowDays.find(day => day.date === nextDate);
    const dateLocations = getLocationOptions(nextDate, roadshowDays);
    const dateLocationValues = dateLocations.map(({ value }) => value);

    // Reset timezone value when user clears date
    if (!nextDate) {
      setFieldValue('timeZone', null);
    }

    if (roadshowDay?.isVirtual) {
      // Autoselect virtual meeting format for virtual roadshow day
      setFieldValue('meetingFormat', 'VIRTUAL');
      setFieldValue('timeZone', roadshowDay.timeZone);
    } else {
      // Autoselect in person meeting format for non-virtual roadshow day
      setFieldValue('meetingFormat', 'IN_PERSON');
    }

    // Reset selected location if its not valid for selected date
    if (values.location && !dateLocationValues.includes(values.location)) {
      setFieldValue('location', null);
    }

    // If no location is picked autoselect first available option
    if (!values.location && dateLocations.length > 0) {
      const [firstLocation] = dateLocations;

      setFieldValue('location', firstLocation.value);
      setFieldValue('timeZone', firstLocation.timeZone);
    }
  };

  const handleLocationChange = (nextLocation: string | null) => {
    const location = locationOptions.find(option => option.value === nextLocation);
    setFieldValue('timeZone', location?.timeZone ?? null);
  };

  const handleMeetingFormatChange = (nextFormat: MeetingFormat) => {
    if (nextFormat === MeetingFormat.Virtual && isVirtualDay) {
      // virtual meeting on virtual day
      setFieldValue('location', null);
      setFieldValue('timeZone', 'America/New_York'); // TODO: this field should be removed from schema as we are taking it from roadshow days configuration
    } else {
      // in person & hybrid meeting or virtual meeting on physical day
      const dateLocations = getLocationOptions(values.date, roadshowDays);
      if (!values.location && dateLocations.length > 0) {
        // set first location as there is no previous value
        const [firstLocation] = dateLocations;

        setFieldValue('location', firstLocation.value);
        setFieldValue('timeZone', firstLocation.timeZone);
      }
    }
  };

  const handleOnDelete = () => {
    onDelete?.();
  };

  const handleOnCancel = () => {
    onCancel?.();
  };

  const handleOnOpenToSales = () => {
    onOpenToSales?.();
  };

  const handleOnConfirm = () => {
    onConfirm?.();
  };

  const isMeetingPersonalOrHybrid = [MeetingFormat.InPerson, MeetingFormat.Hybrid].includes(
    values.meetingFormat
  );
  const isMeetingVirtualOrHybrid = [MeetingFormat.Virtual, MeetingFormat.Hybrid].includes(
    values.meetingFormat
  );

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty && !isSubmitting} onLeave={resetForm}>
        <MeetingButtonGroup
          meetingHeaderInfo={meetingHeaderInfo}
          meetingStatus={meeting?.meetingDetails.status}
          roadshowStatus={roadshowStatus}
          meetingInvestors={meeting?.investors}
          meetingManagementMembers={meetingManagementMembers}
          eventParticipantsTeam={meeting?.participantsTeam}
          onDelete={handleOnDelete}
          onCancel={handleOnCancel}
          onOpenToSales={handleOnOpenToSales}
          onConfirm={handleOnConfirm}
          isDeleting={isDeleting}
          isCanceling={isCanceling}
          isOpeningToSales={isOpeningToSales}
          isConfirming={isConfirming}
          isSaving={isSaving}
        />

        <StyledForm data-test-id="meeting details form">
          <EventFormSubHeader title="Meeting Details">
            <TextInputField name="title" label="Meeting Title" required fullWidth withMargin />

            <GridLayout>
              <Column span={4}>
                <DatePickerField
                  name="date"
                  label="Date"
                  minDate={startDate}
                  maxDate={endDate}
                  onChange={handleDateChange}
                  required
                  fullWidth
                  withMargin
                  onCalendarClose={() => {
                    setFieldTouched('date');
                  }}
                />
              </Column>
              <Column span={4}>
                <TimePickerField
                  name="startTime"
                  label="Start Time"
                  required
                  fullWidth
                  withMargin
                />
              </Column>
              <Column span={4}>
                <TimePickerField name="endTime" label="End Time" required fullWidth withMargin />
              </Column>
            </GridLayout>
            <GridLayout>
              <Column span={4}>
                <SelectField
                  label="Meeting Format"
                  name="meetingFormat"
                  disabled={isVirtualDay}
                  options={meetingFormatOptions}
                  onChange={handleMeetingFormatChange}
                  required
                  fullWidth
                  withMargin
                />
              </Column>
              <Column span={4}>
                <SelectField
                  name="meetingType"
                  label="Meeting Type"
                  options={meetingTypeOptions}
                  fullWidth
                  withMargin
                />
              </Column>
            </GridLayout>
          </EventFormSubHeader>

          <EventFormSubHeader title="Location">
            {isLocationVisible ? (
              <React.Fragment>
                {isMeetingPersonalOrHybrid && (
                  <React.Fragment>
                    <GridLayout>
                      <Column span={4}>
                        <SelectField
                          name="location"
                          label="Location"
                          options={locationOptions}
                          disabled={!values.date}
                          isClearable={false}
                          onChange={handleLocationChange}
                          required
                          fullWidth
                          withMargin
                        />
                      </Column>
                      <Column span={8}>
                        <TextInputField name="venue" label="Venue" fullWidth withMargin />
                      </Column>
                    </GridLayout>

                    <TextInputField name="address" label="Address" fullWidth withMargin />

                    <GridLayout>
                      <Column span={6}>
                        <TextInputField name="roomName" label="Room name" fullWidth withMargin />
                      </Column>
                    </GridLayout>
                  </React.Fragment>
                )}

                {isMeetingVirtualOrHybrid && (
                  <React.Fragment>
                    {values.meetingFormat === MeetingFormat.Virtual && !isVirtualDay && (
                      <GridLayout>
                        <Column span={4}>
                          <SelectField
                            name="location"
                            label="Location"
                            options={locationOptions}
                            disabled={!values.date}
                            isClearable={false}
                            onChange={handleLocationChange}
                            required
                            fullWidth
                            withMargin
                          />
                        </Column>
                      </GridLayout>
                    )}

                    <TextAreaField
                      name="dialInDetails"
                      label="Virtual Meeting Details"
                      resize="none"
                      required
                      fullWidth
                      withMargin
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <div>You will be able to set location once the meeting date is selected</div>
            )}
          </EventFormSubHeader>
          <EventFormSubHeader title="Public Notes">
            <TextAreaField
              name="publicNotes"
              label="Public Notes"
              resize="none"
              fullWidth
              withMargin
            />
          </EventFormSubHeader>

          <EventFormSubHeader title="Hosted by (Optional)">
            <GridLayout>
              <Column span={4}>
                <SelectField
                  name="hostFirmCmgEntityKey"
                  label="Underwriter"
                  isLoading={dealTeamsLoading}
                  options={underwriterOptions}
                  fullWidth
                  withMargin
                />
              </Column>
              <Column span={4}>
                <TextInputField
                  name="hostPersonFullName"
                  label="Member's Name"
                  fullWidth
                  withMargin
                />
              </Column>
              <Column span={4}>
                <TextInputField name="hostPersonEmail" label="Email" fullWidth withMargin />
              </Column>
            </GridLayout>
          </EventFormSubHeader>
        </StyledForm>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default MeetingDetailsForm;
