import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteRegMWireMutation,
  SyndicateWires_RegMWiresDocument,
  useSyndicateWires_DeleteRegMWireMutation,
} from '../graphql';

export type DeleteRegMWireMutation = SyndicateWires_DeleteRegMWireMutation;

export const useDeleteRegMWireMutation = () => {
  return useSyndicateWires_DeleteRegMWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_RegMWiresDocument];
  return getManyOperationNames(documentNodes);
}
