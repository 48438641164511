import { ODSTable as Table, Popover, ToastManager } from '@cmg/common';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getEnvVars } from '../../../../../../common/config/appSettings';
import { isAttestationStatusValid } from '../../../../../order-book/attestation/Attestation.model';
import AttestationFormStatusRenderer from '../../../../../order-book/attestation/AttestationFormStatusRenderer';
import { ErrorIcon } from '../../components/error-icon/ErrorIcon';
import { useComplianceContext } from '../../context/ComplianceContext';
import { Attestation } from '../../context/hooks/certificates/useCertificatesModel';
import { StyledComplianceLink } from '../ComplianceCertificates.styles';
import CertificateDownloadLink from './CertificateDownloadLink';
import { SCellText } from './CertificateTableRow.styles';

export type Props = {
  certificateType: string;
  attestation: Attestation;
};

export const CertificateTableRow: React.FC<Props> = ({ certificateType, attestation }) => {
  const { signatoryDate = '', expirationDate = '' } = attestation.attestationForm ?? {};
  const { tooltipMessage } = useComplianceContext().certificates.warning;
  const isStatusValid = isAttestationStatusValid(attestation.formStatus);
  const { cmgEntityKey } = useComplianceContext().certificates.attestation!;

  const { attestationCopyLinkUrl } = getEnvVars();

  const handleCopy = () => {
    ToastManager.success('Link copied to clipboard.');
  };

  const copyContent = attestationCopyLinkUrl
    ? attestationCopyLinkUrl
    : 'Attestation link not available in this environment';

  return (
    <Table.Row suppressHighlightingOnHover isFocused={false}>
      <Table.Cell>
        {!isStatusValid && (
          <ErrorIcon
            data-test-id="certificate-error-icon"
            errorMessage={tooltipMessage}
            maxWidth={321}
          />
        )}
      </Table.Cell>
      <Table.Cell data-test-id="certificate-type">{certificateType}</Table.Cell>
      <Table.Cell>
        <AttestationFormStatusRenderer status={attestation.formStatus} />
      </Table.Cell>
      <Table.Cell data-test-id="certificate-signatory-date">
        {signatoryDate ? format(parseISO(signatoryDate), 'MM/dd/yyyy') : ''}
      </Table.Cell>
      <Table.Cell data-test-id="certificate-expiration-date">
        <SCellText status={attestation.formStatus}>
          {expirationDate ? format(parseISO(expirationDate), 'MM/dd/yyyy') : ''}
        </SCellText>
      </Table.Cell>
      <Table.Cell>
        {!isStatusValid && (
          <CopyToClipboard text={copyContent} onCopy={handleCopy}>
            <Popover content="Copy link to clipboard" variant="DARK">
              <StyledComplianceLink data-test-id="certificate-link">Copy link</StyledComplianceLink>
            </Popover>
          </CopyToClipboard>
        )}
        {isStatusValid && <CertificateDownloadLink cmgEntityKey={cmgEntityKey} />}
      </Table.Cell>
    </Table.Row>
  );
};
