import styled from 'styled-components/macro';

import SetupForm from '../../../components/form/OfferingSetupForm';

export const StyledHeaderColumn = styled(SetupForm.Column)`
  border-right: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.light};
`;

export const SWrapper = styled.div`
  padding: 8px;
`;

export const SUpdateFileWrapper = styled.div`
  margin-right: 8px;
`;

export const StyledBodyColumn = styled(SetupForm.Column)`
  padding: 0 5px;
`;

export const StyledCenteredColumn = styled(SetupForm.Column)`
  padding: 0 5px;
  display: flex;
  justify-content: center;
  & > div {
    flex: 0;
  }
`;
