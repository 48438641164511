import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_BasicInfoRoute_CreateOfferingDraftDocument,
  OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
  OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables,
} from '../../graphql';

export const useCreateOfferingDraftMutation = () =>
  useServiceMutation<
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables
  >(OfferingSetup_BasicInfoRoute_CreateOfferingDraftDocument, {});
