import {
  ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables,
  useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
} from '../graphql';
import { useCheckProspectusOnSendValidationPermissions } from './useCheckProspectusOnSendValidationPermissions';

export const useProspectusDeliveryOnSendValidationQuery = (
  variables: ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
) => {
  const hasPermissions = useCheckProspectusOnSendValidationPermissions(
    variables.isSyndicatePersona
  );

  return useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !hasPermissions,
  });
};
