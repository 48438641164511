import React from 'react';

import { SCellWrapper, StyledLink } from './DesignationMonitorLinkRenderer.styles';

export type Props = { value: string; to: string; replace: boolean };

const DesignationMonitorLinkRenderer: React.FC<Props> = ({ value, to, replace }) => {
  return (
    <SCellWrapper>
      <StyledLink to={to} replace={replace}>
        {value}
      </StyledLink>
    </SCellWrapper>
  );
};

export default DesignationMonitorLinkRenderer;
