import { Tooltip } from '@cmg/design-system';
import React from 'react';

const LimitConfigurationTooltip: React.FC = () => {
  return (
    <Tooltip arrow placement="top" title="Limit Price expressed in pricing currency">
      <span>ⓘ</span>
    </Tooltip>
  );
};

export default LimitConfigurationTooltip;
