import { ISODate } from '@cmg/common';
import React from 'react';

import { getMaxISODate } from '../TimingForm.model';

/**
 * Custom hook for calculating the max of a given dates array
 * Used to restrict date selection via minDate on DatePicker component
 *
 * Ex. Date C should always be after Date A or Date B:
 * getMaxISODate([A, B]) will return the date Date C should be after
 * DatePicker C's minDate should be the result of above
 */
export const useCalculateMinDates = (dates: Array<ISODate | undefined | null>) => {
  return React.useMemo(() => getMaxISODate(dates), [dates]);
};
