import {
  Alert,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  SearchTextField,
  Stack,
  Tooltip,
  Typography,
  WarningAmber,
} from '@cmg/design-system';
import React from 'react';

import { SyndicateWires_CustomVariablesQuery } from '../../../../graphql';
import { useDisclaimerVariables } from '../hooks/useDisclaimerVariables';
import { filterCustomVariables } from './CustomVariables.model';
import CustomVariablesSkeleton from './skeleton/CustomVariablesSkeleton';

export type Props = {
  customVariables: SyndicateWires_CustomVariablesQuery['syndicateWires']['customVariables'];
  isLoading: boolean;
  hasError: boolean;
};

const CustomVariables: React.FC<Props> = ({ customVariables, isLoading, hasError }) => {
  const { pasteVariable } = useDisclaimerVariables();
  const [filteredVariables, setFilteredVariables] = React.useState(customVariables);
  const handleTextChange = React.useCallback(
    (searchText: string) => {
      setFilteredVariables(filterCustomVariables(customVariables, searchText));
    },
    [customVariables]
  );

  return (
    <Stack gap={2} sx={{ height: '100%' }}>
      <Typography variant="h3">Variables</Typography>
      <Typography>
        Use variables to customize disclaimer with additional data. Variables include the following:
      </Typography>
      <SearchTextField
        label="Search"
        placeholder="Search variable..."
        disabled={isLoading}
        onChange={e => handleTextChange(e.target.value)}
      />
      {hasError ? (
        <Alert severity="error" aria-label="An error has occurred while loading variables.">
          An error has occurred while loading variables.
        </Alert>
      ) : (
        <Box height="100%" overflow="auto">
          <Box sx={{ minHeight: '100%', height: 206 }}>
            {isLoading ? (
              <CustomVariablesSkeleton />
            ) : (
              <List>
                {filteredVariables.length === 0 && (
                  <Box textAlign="center" m={2}>
                    <Typography m={1} variant="h3">
                      No Results
                    </Typography>
                    <Typography color="text.secondary">
                      There are no matches for your search. Please try another term.
                    </Typography>
                  </Box>
                )}
                {filteredVariables.map(group => (
                  <List
                    key={group.id}
                    dense
                    subheader={
                      <ListSubheader disableSticky>
                        <Typography variant="subtitle1">{group.displayName}</Typography>
                      </ListSubheader>
                    }
                  >
                    {group.variables.map(variable => {
                      const variableName = `{{${variable.name}}}`;
                      return (
                        <ListItemButton
                          onMouseDown={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            pasteVariable(variableName);
                          }}
                          divider
                          key={variable.id}
                          component="li"
                        >
                          <ListItemText
                            primary={variable.displayName}
                            primaryTypographyProps={{ variant: 'body1' }}
                            secondary={variableName}
                            secondaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                          />
                          {variable.value === null && (
                            <Tooltip title="Variable is missing from the offering. Add value in Offering details or contact offering author.">
                              <WarningAmber fontSize="small" color="warning" />
                            </Tooltip>
                          )}
                        </ListItemButton>
                      );
                    })}
                  </List>
                ))}
              </List>
            )}
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default CustomVariables;
