import { LinkButton, PrimaryButton, UUID } from '@cmg/common';
import React from 'react';

import dealTeam from '../../../../../../common/assets/deal-team.svg';
import { HeroGraphics } from '../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import RoadshowCreationStep from '../../common/creation-step/RoadshowCreationStep';
import AddMembersModal from '../add-members-modal/AddMembersModal';
import CreateDealTeamMembersModal from '../create-deal-team-members-modal/CreateDealTeamMembersModal';
import { SContentWrapper } from './EmptyDealTeamDetails.styles';

export type Props = { roadshowId: UUID };

const EmptyDealTeamDetails: React.FC<Props> = ({ roadshowId }) => {
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = React.useState<boolean>(false);
  const [isCreateMembersModalOpen, setIsCreateMembersModalOpen] = React.useState<boolean>(false);

  const handleOpenAddMembersModal = () => {
    setIsAddMembersModalOpen(true);
  };

  const handleCloseAddMembersModal = () => {
    setIsAddMembersModalOpen(false);
  };

  const handleOpenCreateMembersModal = () => {
    setIsAddMembersModalOpen(false);
    setIsCreateMembersModalOpen(true);
  };

  const handleCloseCreateMembersModal = () => {
    setIsCreateMembersModalOpen(false);
  };

  return (
    <RoadshowCreationStep data-test-id="roadshows / single-roadshow / configuration / deal-team">
      <RoadshowCreationStep.Header title="Deal Team">
        <PrimaryButton testId="add members" onClick={handleOpenAddMembersModal}>
          Add Members
        </PrimaryButton>
      </RoadshowCreationStep.Header>

      <RoadshowCreationStep.Content>
        <SContentWrapper>
          <HeroGraphics
            image={dealTeam}
            imageAlt="Empty Deal Team Details"
            title="Deal Team Not Configured"
            subtitle="Search and add members to participate in the Roadshow"
          />
          <LinkButton testId="add members link button" onClick={handleOpenAddMembersModal}>
            Add Deal Team Members
          </LinkButton>
        </SContentWrapper>
      </RoadshowCreationStep.Content>

      <AddMembersModal
        participatingTeamMembers={[]}
        roadshowId={roadshowId}
        show={isAddMembersModalOpen}
        onHide={handleCloseAddMembersModal}
        onOpenCreateMembersModal={handleOpenCreateMembersModal}
      />
      <CreateDealTeamMembersModal
        show={isCreateMembersModalOpen}
        onHide={handleCloseCreateMembersModal}
        participatingDealTeams={[]}
        roadshowId={roadshowId}
      />
    </RoadshowCreationStep>
  );
};

export default EmptyDealTeamDetails;
