import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import { SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getFeatureToggles } from '../../../common/config/appSettings';
import routeFactory from '../../../common/util/routeFactory';
import { BasicInfoModal } from '../../offering-setup/basic-info-modal/BasicInfoModal';

export const StyledCreateOfferingButton = styled(SuccessButton)`
  align-self: flex-start;
`;

/**
 * Render a Create New Offering button if permissions exist.
 */

export const CreateNewOfferingButton: React.FC = () => {
  const { isOfferingSetupNewFlowOn } = getFeatureToggles();
  const isSellSideAccount = useCheckAccountType('SELL_SIDE');
  const hasFullOfferingPermissions = useCheckPermissions([permissionsByEntity.Offering.FULL]);

  const [isBasicInfoModalOpen, setIsBasicInfoModalOpen] = useState<boolean>(false);

  if (isSellSideAccount && hasFullOfferingPermissions) {
    if (isOfferingSetupNewFlowOn) {
      return (
        <React.Fragment>
          <StyledCreateOfferingButton
            testId={xcSelectors.myOfferingsCreateNewOfferingButton.testId}
            onClick={() => {
              setIsBasicInfoModalOpen(true);
            }}
          >
            Create New Offering
          </StyledCreateOfferingButton>
          {isBasicInfoModalOpen && (
            <BasicInfoModal
              onHide={() => {
                setIsBasicInfoModalOpen(false);
              }}
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <StyledCreateOfferingButton
          testId={xcSelectors.myOfferingsCreateNewOfferingButton.testId}
          asComponent={NavLink}
          to={routeFactory.offeringCreate.getUrlPath()}
        >
          Create New Offering
        </StyledCreateOfferingButton>
      );
    }
  } else {
    return null;
  }
};

export default CreateNewOfferingButton;
