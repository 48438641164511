import { numericUtil } from '@cmg/common';
import React from 'react';

import { useSalesCreditsContext } from '../../../context/SalesCreditsContext';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { StyledCurrencyInputField, SWrapper } from './SalesCreditsRenderer.styles';

export type Props = {
  value: number | null;
  data: SalesCredits_SalesCreditItemPartsFragment;
};

const SalesCreditsRenderer: React.FC<Props> = ({ value, data }) => {
  const { isEditing } = useSalesCreditsContext();

  if (isEditing) {
    return (
      <StyledCurrencyInputField fullWidth name={`salesCredits.${data.investorFirmCmgEntityKey}`} />
    );
  }

  return <SWrapper>{numericUtil.getDisplayValueForCurrency(value, 2)}</SWrapper>;
};

export default SalesCreditsRenderer;
