import { Icon } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import { getFeatureToggles } from '../../../../common/config/appSettings';
import routeFactory from '../../../../common/util/routeFactory';
import { AllocationAcknowledgement } from '../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { Calendar_PricedCalendarOfferingsListQuery } from '../graphql';
import { SAcknowledgeWrapper, StyledAcknowledgeButton } from './BuySideStatusCell.styles';

type Props = {
  id: string;
  finalAllocation:
    | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]['finalAllocation']
    | null;
  context: { matchUrl: string };
};

const BuySideStatusCell: React.FC<Props> = ({ id, finalAllocation, context }) => {
  const { isOrderBookBuySideInvestorReplyOn } = getFeatureToggles();
  const { push } = useHistory();
  const { background } = useTheme();

  if (!finalAllocation) {
    return <div>-</div>;
  }
  if (finalAllocation.investorReply?.status === AllocationAcknowledgement.Acknowledged) {
    return (
      <SAcknowledgeWrapper>
        <Icon name="circle" variant="solid" size="xs" color={background.color.positive} />
        Acknowledged
      </SAcknowledgeWrapper>
    );
  }
  if (finalAllocation.investorReply?.status === AllocationAcknowledgement.Rejected) {
    return <div>Rejected</div>;
  }
  if (
    finalAllocation.investorReply?.status === AllocationAcknowledgement.Unacknowledged &&
    isOrderBookBuySideInvestorReplyOn
  ) {
    return (
      <StyledAcknowledgeButton
        onClick={() =>
          push(
            routeFactory.offeringSidePanel.getUrlPath({
              offeringId: id,
              prefixRoutePath: context.matchUrl,
              sidePanelRoutePath:
                offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({}),
            })
          )
        }
      >
        Acknowledge
      </StyledAcknowledgeButton>
    );
  }
  return <div>-</div>;
};

export default BuySideStatusCell;
