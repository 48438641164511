/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSidePanel_OfferingNotesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSidePanel_OfferingNotesQuery = {
  readonly __typename?: 'Query';
  readonly offeringNotes: {
    readonly __typename?: 'MdlOfferingNotes';
    readonly id: string;
    readonly notes: ReadonlyArray<{
      readonly __typename?: 'MdlOfferingNote';
      readonly id: string;
      readonly lastEditedAt: string;
      readonly lastEditedBy: string;
      readonly note: string;
      readonly subject: Types.OfferingNoteSubject;
    }>;
  };
};

export type OfferingSidePanel_CreateOfferingNoteMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.CreateMdlOfferingNoteInput;
}>;

export type OfferingSidePanel_CreateOfferingNoteMutation = {
  readonly __typename?: 'Mutation';
  readonly createOfferingNote: {
    readonly __typename?: 'MdlOfferingNotes';
    readonly id: string;
    readonly notes: ReadonlyArray<{
      readonly __typename?: 'MdlOfferingNote';
      readonly id: string;
      readonly lastEditedAt: string;
      readonly lastEditedBy: string;
      readonly note: string;
      readonly subject: Types.OfferingNoteSubject;
    }>;
  };
};

export type OfferingSidePanel_UpdateOfferingNoteMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.UpdateMdlOfferingNoteInput;
}>;

export type OfferingSidePanel_UpdateOfferingNoteMutation = {
  readonly __typename?: 'Mutation';
  readonly updateOfferingNote: {
    readonly __typename?: 'MdlOfferingNotes';
    readonly id: string;
    readonly notes: ReadonlyArray<{
      readonly __typename?: 'MdlOfferingNote';
      readonly id: string;
      readonly lastEditedAt: string;
      readonly lastEditedBy: string;
      readonly note: string;
      readonly subject: Types.OfferingNoteSubject;
    }>;
  };
};

export type OfferingSidePanel_DeleteOfferingNoteMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.DeleteMdlOfferingNoteInput;
}>;

export type OfferingSidePanel_DeleteOfferingNoteMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteOfferingNote: {
    readonly __typename?: 'MdlOfferingNotes';
    readonly id: string;
    readonly notes: ReadonlyArray<{
      readonly __typename?: 'MdlOfferingNote';
      readonly id: string;
      readonly lastEditedAt: string;
      readonly lastEditedBy: string;
      readonly note: string;
      readonly subject: Types.OfferingNoteSubject;
    }>;
  };
};

export const OfferingSidePanel_OfferingNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSidePanel_OfferingNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offeringNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSidePanel_OfferingNotesQuery__
 *
 * To run a query within a React component, call `useOfferingSidePanel_OfferingNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_OfferingNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSidePanel_OfferingNotesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSidePanel_OfferingNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSidePanel_OfferingNotesQuery,
    OfferingSidePanel_OfferingNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSidePanel_OfferingNotesQuery,
    OfferingSidePanel_OfferingNotesQueryVariables
  >(OfferingSidePanel_OfferingNotesDocument, options);
}
export function useOfferingSidePanel_OfferingNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSidePanel_OfferingNotesQuery,
    OfferingSidePanel_OfferingNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSidePanel_OfferingNotesQuery,
    OfferingSidePanel_OfferingNotesQueryVariables
  >(OfferingSidePanel_OfferingNotesDocument, options);
}
export type OfferingSidePanel_OfferingNotesQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_OfferingNotesQuery
>;
export type OfferingSidePanel_OfferingNotesLazyQueryHookResult = ReturnType<
  typeof useOfferingSidePanel_OfferingNotesLazyQuery
>;
export type OfferingSidePanel_OfferingNotesQueryResult = Apollo.QueryResult<
  OfferingSidePanel_OfferingNotesQuery,
  OfferingSidePanel_OfferingNotesQueryVariables
>;
export const OfferingSidePanel_CreateOfferingNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSidePanel_CreateOfferingNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMdlOfferingNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOfferingNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSidePanel_CreateOfferingNoteMutationFn = Apollo.MutationFunction<
  OfferingSidePanel_CreateOfferingNoteMutation,
  OfferingSidePanel_CreateOfferingNoteMutationVariables
>;

/**
 * __useOfferingSidePanel_CreateOfferingNoteMutation__
 *
 * To run a mutation, you first call `useOfferingSidePanel_CreateOfferingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_CreateOfferingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSidePanelCreateOfferingNoteMutation, { data, loading, error }] = useOfferingSidePanel_CreateOfferingNoteMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSidePanel_CreateOfferingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSidePanel_CreateOfferingNoteMutation,
    OfferingSidePanel_CreateOfferingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSidePanel_CreateOfferingNoteMutation,
    OfferingSidePanel_CreateOfferingNoteMutationVariables
  >(OfferingSidePanel_CreateOfferingNoteDocument, options);
}
export type OfferingSidePanel_CreateOfferingNoteMutationHookResult = ReturnType<
  typeof useOfferingSidePanel_CreateOfferingNoteMutation
>;
export type OfferingSidePanel_CreateOfferingNoteMutationResult =
  Apollo.MutationResult<OfferingSidePanel_CreateOfferingNoteMutation>;
export type OfferingSidePanel_CreateOfferingNoteMutationOptions = Apollo.BaseMutationOptions<
  OfferingSidePanel_CreateOfferingNoteMutation,
  OfferingSidePanel_CreateOfferingNoteMutationVariables
>;
export const OfferingSidePanel_UpdateOfferingNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSidePanel_UpdateOfferingNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMdlOfferingNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOfferingNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSidePanel_UpdateOfferingNoteMutationFn = Apollo.MutationFunction<
  OfferingSidePanel_UpdateOfferingNoteMutation,
  OfferingSidePanel_UpdateOfferingNoteMutationVariables
>;

/**
 * __useOfferingSidePanel_UpdateOfferingNoteMutation__
 *
 * To run a mutation, you first call `useOfferingSidePanel_UpdateOfferingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_UpdateOfferingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSidePanelUpdateOfferingNoteMutation, { data, loading, error }] = useOfferingSidePanel_UpdateOfferingNoteMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSidePanel_UpdateOfferingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSidePanel_UpdateOfferingNoteMutation,
    OfferingSidePanel_UpdateOfferingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSidePanel_UpdateOfferingNoteMutation,
    OfferingSidePanel_UpdateOfferingNoteMutationVariables
  >(OfferingSidePanel_UpdateOfferingNoteDocument, options);
}
export type OfferingSidePanel_UpdateOfferingNoteMutationHookResult = ReturnType<
  typeof useOfferingSidePanel_UpdateOfferingNoteMutation
>;
export type OfferingSidePanel_UpdateOfferingNoteMutationResult =
  Apollo.MutationResult<OfferingSidePanel_UpdateOfferingNoteMutation>;
export type OfferingSidePanel_UpdateOfferingNoteMutationOptions = Apollo.BaseMutationOptions<
  OfferingSidePanel_UpdateOfferingNoteMutation,
  OfferingSidePanel_UpdateOfferingNoteMutationVariables
>;
export const OfferingSidePanel_DeleteOfferingNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSidePanel_DeleteOfferingNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteMdlOfferingNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOfferingNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNotesListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingNotes_OfferingNoteParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlOfferingNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSidePanel_DeleteOfferingNoteMutationFn = Apollo.MutationFunction<
  OfferingSidePanel_DeleteOfferingNoteMutation,
  OfferingSidePanel_DeleteOfferingNoteMutationVariables
>;

/**
 * __useOfferingSidePanel_DeleteOfferingNoteMutation__
 *
 * To run a mutation, you first call `useOfferingSidePanel_DeleteOfferingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSidePanel_DeleteOfferingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSidePanelDeleteOfferingNoteMutation, { data, loading, error }] = useOfferingSidePanel_DeleteOfferingNoteMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSidePanel_DeleteOfferingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSidePanel_DeleteOfferingNoteMutation,
    OfferingSidePanel_DeleteOfferingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSidePanel_DeleteOfferingNoteMutation,
    OfferingSidePanel_DeleteOfferingNoteMutationVariables
  >(OfferingSidePanel_DeleteOfferingNoteDocument, options);
}
export type OfferingSidePanel_DeleteOfferingNoteMutationHookResult = ReturnType<
  typeof useOfferingSidePanel_DeleteOfferingNoteMutation
>;
export type OfferingSidePanel_DeleteOfferingNoteMutationResult =
  Apollo.MutationResult<OfferingSidePanel_DeleteOfferingNoteMutation>;
export type OfferingSidePanel_DeleteOfferingNoteMutationOptions = Apollo.BaseMutationOptions<
  OfferingSidePanel_DeleteOfferingNoteMutation,
  OfferingSidePanel_DeleteOfferingNoteMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSidePanel_OfferingNotesMockResponse = makeMockResponse<
  OfferingSidePanel_OfferingNotesQueryVariables,
  OfferingSidePanel_OfferingNotesQuery
>(OfferingSidePanel_OfferingNotesDocument);
