import { Option, Select } from '@cmg/common';
import React from 'react';

import {
  SInvestorDropdownContainer,
  SInvestorSearch,
  SInvestorSearchLabel,
} from './InvestorSearch.styles';

export type Investor = {
  key: string;
  name: string;
};

export type Props = {
  investors: Investor[];
  onChange: (key: string | null) => void;
  currentKey: string | null;
  placehorder?: string;
};

/**
 * InvestorSearch is a component that receives the list of investors and have a onChange callback
 */
const InvestorSearch: React.FC<Props> = ({ investors, onChange, currentKey, placehorder }) => {
  return (
    <SInvestorSearch>
      <SInvestorSearchLabel>Investor</SInvestorSearchLabel>
      <SInvestorDropdownContainer>
        <Select<string>
          placeholder={placehorder ?? 'Investor...'}
          options={investors.map<Option<string>>(d => {
            return {
              label: d.name,
              value: d.key,
            };
          })}
          onChange={onChange}
          value={currentKey}
          isClearable={false}
        />
      </SInvestorDropdownContainer>
    </SInvestorSearch>
  );
};

export default InvestorSearch;
