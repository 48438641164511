import { PrimaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import { StyledCancelButton } from './ConfirmMeetingConfirmModal.styles';

export type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
};

const ConfirmMeetingConfirmModal: React.FC<Props> = ({ onConfirm, show, onClose, loading }) => {
  return (
    <Modal title="Confirm Meeting" show={show} onHide={onClose}>
      <Modal.Content>Would you like to confirm this Meeting?</Modal.Content>
      <Modal.Footer>
        <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
        <PrimaryButton loading={loading} onClick={onConfirm}>
          Confirm
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmMeetingConfirmModal;
