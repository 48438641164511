import { Panel } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';
import { View } from '../../../common/calendar-filters/CalendarFilters.model';
import CalendarHeader from '../../../common/calendar-header/CalendarHeader';
import { LogisticsNavigationSteps } from '../../../panel-logistics-event/common/logistics-side-panel/LogisticsSidePanel.model';
import CreateLogisticsPanel from '../../../panel-logistics-event/create-logistics-panel/CreateLogisticsPanel';
import RoadshowCoordinatorLogisticsDetailsPanel from '../../../panel-logistics-event/logistics-details-panel/roadshow-coordinator-logistics-details-panel/RoadshowCoordinatorLogisticsDetailsPanel';
import { MeetingNavigationSteps } from '../../../panel-meetings-event/common/meeting-side-panel/MeetingSidePanel.model';
import CreateMeetingPanel from '../../../panel-meetings-event/create-meeting-panel/CreateMeetingPanel';
import RoadshowCoordinatorDetailsPanel from '../../../panel-meetings-event/meeting-details-panel/roadshow-coordinator-details-panel/RoadshowCoordinatorDetailsPanel';
import CalendarViewDaily from '../../../view-daily-events/CalendarViewDaily';
import CalendarViewList from '../../../view-list-all-events/CalendarViewList';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  events: readonly Roadshows_RoadshowEventPartsFragment[];
  testId?: string;
};

const CoordinatorCalendar: React.FC<Props> = ({ roadshow, events, testId }) => {
  const [view, setView] = React.useState<View>(View.DAY);
  const { push } = useHistory();

  const handleSidePanelClose = () => {
    push(routeFactory.roadshowCalendar.getUrlPath({ roadshowId: roadshow.id }));
  };

  return (
    <FlexLayout direction="column" data-test-id={testId}>
      <CalendarHeader roadshow={roadshow} />
      <Panel fillViewport>
        {view === View.DAY && (
          <CalendarViewDaily allEvents={events} roadshow={roadshow} onViewChange={setView} />
        )}
        {view === View.LIST && (
          <CalendarViewList allEvents={events} roadshow={roadshow} onViewChange={setView} />
        )}
      </Panel>
      <Switch>
        <Route
          exact
          path={routeFactory.roadshowCreateMeeting.routePath}
          render={({ match }) => (
            <CreateMeetingPanel
              roadshow={roadshow}
              onClose={handleSidePanelClose}
              selectedDate={roadshow.roadshowDays.find(day => day.date === match.params.date)?.date}
            />
          )}
        />
        <Route
          exact
          path={routeFactory.roadshowMeetingDetails.routePath}
          render={({ match }) => (
            <RoadshowCoordinatorDetailsPanel
              meetingId={match.params.meetingId}
              roadshow={roadshow}
              onClose={handleSidePanelClose}
              stepId={match.params.stepId as MeetingNavigationSteps}
            />
          )}
        />
        <Route
          exact
          path={routeFactory.roadshowCreateLogistics.routePath}
          render={({ match }) => (
            <CreateLogisticsPanel
              roadshow={roadshow}
              onClose={handleSidePanelClose}
              selectedDate={roadshow.roadshowDays.find(day => day.date === match.params.date)?.date}
            />
          )}
        />
        <Route
          exact
          path={routeFactory.roadshowLogisticsDetails.routePath}
          render={({ match }) => (
            <RoadshowCoordinatorLogisticsDetailsPanel
              roadshow={roadshow}
              onClose={handleSidePanelClose}
              logisticsId={match.params.logisticsId}
              stepId={match.params.stepId as LogisticsNavigationSteps}
            />
          )}
        />
      </Switch>
    </FlexLayout>
  );
};

export default CoordinatorCalendar;
