import Loader from 'react-loader-advanced';
import styled from 'styled-components/macro';

import { ReactComponent as SpinnerIcon } from './spinner.svg';

export const StyledSpinnerIcon = styled(SpinnerIcon)<{ variant: 'LIGHT' | 'DARK' }>`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  max-width: 52px;
  max-height: 52px;

  path {
    width: 100%;
    height: 100%;
    fill: ${({ variant, theme }) =>
      variant === 'LIGHT' ? theme.border.color.dark : theme.background.color.menu};
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const StyledLoader = styled(Loader)<{ fullHeight?: boolean }>`
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  width: 100%;
`;
