import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';

type Props = { enableMDL?: boolean; enableSecondarySources?: boolean };

export function useCalendarAccess({ enableMDL = true, enableSecondarySources = true }: Props = {}) {
  const isBuySideAccount = useCheckAccountType('BUY_SIDE');
  const hasMDLPermission = useCheckPermissions([permissionsByEntity.Ioi.READ]);
  const hasIndicationPermission = useCheckPermissions([
    permissionsByEntity.InstitutionalIndication.READ,
  ]);
  const hasAllocationPermission = useCheckPermissions([
    permissionsByEntity.InstitutionalAllocation.READ,
  ]);

  const isMDL = isBuySideAccount && hasMDLPermission && enableMDL && enableSecondarySources;
  const isXC =
    isBuySideAccount && !hasMDLPermission && hasIndicationPermission && enableSecondarySources;
  const isBuySide = isBuySideAccount && enableSecondarySources;

  return {
    allowMyInstitutionalIndication: isXC,
    allowFinalAllocation: isBuySide && hasAllocationPermission,
    allowMDLAllocation: isMDL,
    allowMDLIndications: isMDL,
    allowBooksCloseAt: isBuySide,
  };
}
