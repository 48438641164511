import { ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../common/util/routeFactory';
import { RoadshowStatus } from '../../../../../graphql';
import { Roadshows_RoadshowPartsFragment } from '../../../common/graphql';
import RoadshowHeader from '../../common/roadshow-header/RoadshowHeader';
import PublishRoadshowModal from '../../roadshow-calendar/common/calendar-header/publish-roadshow-modal/PublishRoadshowModal';

export type Props = { roadshow: Roadshows_RoadshowPartsFragment };

const RoadshowDetailsHeader: React.FC<Props> = ({ roadshow }) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleNavigateToCalendar = () => {
    history.push(routeFactory.roadshowCalendar.getUrlPath({ roadshowId: roadshow.id }));
  };

  const openPublishModal = () => {
    setIsModalOpen(true);
  };

  return (
    <RoadshowHeader roadshow={roadshow}>
      <ButtonsContainer>
        {roadshow.status === RoadshowStatus.Open && (
          <SecondaryButton
            onClick={openPublishModal}
            testId={xcSelectors.roadshowConfigPublishRoadshowButton.testId}
          >
            Publish
          </SecondaryButton>
        )}
        <PrimaryButton
          testId={xcSelectors.roadshowConfigViewCalendarButton.testId}
          onClick={handleNavigateToCalendar}
        >
          View Calendar
        </PrimaryButton>
      </ButtonsContainer>
      <PublishRoadshowModal
        roadshowId={roadshow.id}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </RoadshowHeader>
  );
};

export default RoadshowDetailsHeader;
