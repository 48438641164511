import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeletePricingTermsRetentionWireMutation,
  SyndicateWires_PricingTermsRetentionWiresDocument,
  useSyndicateWires_DeletePricingTermsRetentionWireMutation,
} from '../graphql';

export type DeletePricingTermsRetentionWireMutation =
  SyndicateWires_DeletePricingTermsRetentionWireMutation;

export const refetchQueries = () => {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_PricingTermsRetentionWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
};

export const useDeletePricingTermsRetentionWireMutation = () => {
  return useSyndicateWires_DeletePricingTermsRetentionWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
