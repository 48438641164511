import { FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWrapper = styled(FlexContainer)`
  margin: 16px;
`;

export const SSearchIconWrapper = styled.div`
  padding: 0 10px;
  border-left: ${({ theme }) => theme.border.smallSolidDark};
`;

export const SButtonWrapper = styled(FlexContainer)`
  flex-grow: 1;
`;
