/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_CreateExpensesRevisionMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateExpensesRevisionInput;
}>;

export type FinalSettlement_CreateExpensesRevisionMutation = {
  readonly __typename?: 'Mutation';
  readonly createExpensesRevision: {
    readonly __typename?: 'Expenses';
    readonly id: string;
    readonly notes?: string | null;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ExpensesManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
      readonly role: Types.ExpensesManagerRole;
    }>;
    readonly managerExpenses: ReadonlyArray<{
      readonly __typename?: 'ManagerExpenses';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly managerName: string;
      readonly managerRole: Types.ExpensesManagerRole;
      readonly travelAndMeals?: number | null;
      readonly technologyAndData?: number | null;
      readonly miscellaneous?: number | null;
      readonly reimbursement?: number | null;
    }>;
    readonly dealRelatedExpenses: ReadonlyArray<{
      readonly __typename?: 'DealRelatedExpense';
      readonly category: Types.ExpenseCategory;
      readonly description: string;
      readonly value: number;
    }>;
  };
};

export type FinalSettlement_ExpensesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_ExpensesQuery = {
  readonly __typename?: 'Query';
  readonly expenses: {
    readonly __typename?: 'Expenses';
    readonly id: string;
    readonly notes?: string | null;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ExpensesManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
      readonly role: Types.ExpensesManagerRole;
    }>;
    readonly managerExpenses: ReadonlyArray<{
      readonly __typename?: 'ManagerExpenses';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly managerName: string;
      readonly managerRole: Types.ExpensesManagerRole;
      readonly travelAndMeals?: number | null;
      readonly technologyAndData?: number | null;
      readonly miscellaneous?: number | null;
      readonly reimbursement?: number | null;
    }>;
    readonly dealRelatedExpenses: ReadonlyArray<{
      readonly __typename?: 'DealRelatedExpense';
      readonly category: Types.ExpenseCategory;
      readonly description: string;
      readonly value: number;
    }>;
  };
};

export type FinalSettlement_ExpensesPartsFragment = {
  readonly __typename?: 'Expenses';
  readonly id: string;
  readonly notes?: string | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ExpensesManager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly name: string;
    readonly role: Types.ExpensesManagerRole;
  }>;
  readonly managerExpenses: ReadonlyArray<{
    readonly __typename?: 'ManagerExpenses';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly managerName: string;
    readonly managerRole: Types.ExpensesManagerRole;
    readonly travelAndMeals?: number | null;
    readonly technologyAndData?: number | null;
    readonly miscellaneous?: number | null;
    readonly reimbursement?: number | null;
  }>;
  readonly dealRelatedExpenses: ReadonlyArray<{
    readonly __typename?: 'DealRelatedExpense';
    readonly category: Types.ExpenseCategory;
    readonly description: string;
    readonly value: number;
  }>;
};

export const FinalSettlement_ExpensesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ExpensesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Expenses' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'travelAndMeals' } },
                { kind: 'Field', name: { kind: 'Name', value: 'technologyAndData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'miscellaneous' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reimbursement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealRelatedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_CreateExpensesRevisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_CreateExpensesRevision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateExpensesRevisionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createExpensesRevision' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_ExpensesParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ExpensesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Expenses' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'travelAndMeals' } },
                { kind: 'Field', name: { kind: 'Name', value: 'technologyAndData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'miscellaneous' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reimbursement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealRelatedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_CreateExpensesRevisionMutationFn = Apollo.MutationFunction<
  FinalSettlement_CreateExpensesRevisionMutation,
  FinalSettlement_CreateExpensesRevisionMutationVariables
>;

/**
 * __useFinalSettlement_CreateExpensesRevisionMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_CreateExpensesRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_CreateExpensesRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementCreateExpensesRevisionMutation, { data, loading, error }] = useFinalSettlement_CreateExpensesRevisionMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useFinalSettlement_CreateExpensesRevisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_CreateExpensesRevisionMutation,
    FinalSettlement_CreateExpensesRevisionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_CreateExpensesRevisionMutation,
    FinalSettlement_CreateExpensesRevisionMutationVariables
  >(FinalSettlement_CreateExpensesRevisionDocument, options);
}
export type FinalSettlement_CreateExpensesRevisionMutationHookResult = ReturnType<
  typeof useFinalSettlement_CreateExpensesRevisionMutation
>;
export type FinalSettlement_CreateExpensesRevisionMutationResult =
  Apollo.MutationResult<FinalSettlement_CreateExpensesRevisionMutation>;
export type FinalSettlement_CreateExpensesRevisionMutationOptions = Apollo.BaseMutationOptions<
  FinalSettlement_CreateExpensesRevisionMutation,
  FinalSettlement_CreateExpensesRevisionMutationVariables
>;
export const FinalSettlement_ExpensesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_Expenses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_ExpensesParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ExpensesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Expenses' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'travelAndMeals' } },
                { kind: 'Field', name: { kind: 'Name', value: 'technologyAndData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'miscellaneous' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reimbursement' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealRelatedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_ExpensesQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_ExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_ExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_ExpensesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_ExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_ExpensesQuery,
    FinalSettlement_ExpensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinalSettlement_ExpensesQuery, FinalSettlement_ExpensesQueryVariables>(
    FinalSettlement_ExpensesDocument,
    options
  );
}
export function useFinalSettlement_ExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_ExpensesQuery,
    FinalSettlement_ExpensesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FinalSettlement_ExpensesQuery, FinalSettlement_ExpensesQueryVariables>(
    FinalSettlement_ExpensesDocument,
    options
  );
}
export type FinalSettlement_ExpensesQueryHookResult = ReturnType<
  typeof useFinalSettlement_ExpensesQuery
>;
export type FinalSettlement_ExpensesLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_ExpensesLazyQuery
>;
export type FinalSettlement_ExpensesQueryResult = Apollo.QueryResult<
  FinalSettlement_ExpensesQuery,
  FinalSettlement_ExpensesQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeFinalSettlement_ExpensesMockResponse = makeMockResponse<
  FinalSettlement_ExpensesQueryVariables,
  FinalSettlement_ExpensesQuery
>(FinalSettlement_ExpensesDocument);
