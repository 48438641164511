import { SelectField, TextInputField } from '@cmg/common';
import { Form, useFormikContext } from 'formik';
import React from 'react';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Column from '../../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../../common/components/layout/grid-layout/GridLayout';
import { useRoadshows_RoadshowDealTeamOrganizationsQuery } from '../../graphql';
import { SearchValues } from '../AddMembersModal.model';
import { getDepartmentOptions, getOrganizationOptions } from './AddMembersSearchFilter.model';

const AddMembersSearchFilter: React.FC = () => {
  const { data, loading, error } = useRoadshows_RoadshowDealTeamOrganizationsQuery();

  const { values, setFieldValue, submitForm } = useFormikContext<SearchValues>();

  const handleOrganizationChange = (nextOrganizationValue: string | null) => {
    // reset selected department when clearing organization
    if (!nextOrganizationValue) {
      setFieldValue('department', null);
    }

    submitForm();
  };

  const organizations = data?.roadshowDealTeamOrganizations ?? [];

  return (
    <Form>
      {error && <ServerErrorsBanner error={error} />}

      {loading && <Loading />}

      {!loading && (
        <GridLayout>
          <Column span={4}>
            <TextInputField
              name="fullName"
              label="Deal Team Members"
              onChange={submitForm}
              fullWidth
              withMargin
            />
          </Column>
          <Column span={4}>
            <SelectField
              name="firmCmgEntityKey"
              label="Organization"
              options={getOrganizationOptions(organizations)}
              onChange={handleOrganizationChange}
              fullWidth
              withMargin
            />
          </Column>
          <Column span={4}>
            <SelectField
              name="department"
              label="Department"
              onChange={submitForm}
              disabled={!values.firmCmgEntityKey}
              options={getDepartmentOptions(values.firmCmgEntityKey, organizations)}
              fullWidth
              withMargin
            />
          </Column>
        </GridLayout>
      )}
    </Form>
  );
};

export default AddMembersSearchFilter;
