import { numericUtil } from '@cmg/common';

export const calculateFinalSettlement = ({
  netDealFees,
  retailRetentionFees,
  manualAdjustment,
}: {
  netDealFees: number | null | undefined;
  retailRetentionFees: number | null | undefined;
  manualAdjustment: number | null | undefined;
}) => {
  return numericUtil.sum(
    netDealFees,
    retailRetentionFees ? -retailRetentionFees : null,
    manualAdjustment
  );
};
