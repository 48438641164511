import type { UUID } from '@cmg/common';
import { numericUtil } from '@cmg/common';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Typography,
} from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React, { useEffect } from 'react';

import { AlertCodes } from '../demand-grid/columns/columns.model';
import { useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery } from '../graphql/__generated__';

export type Props = Readonly<{
  offeringId: UUID;
  isOpen: boolean;
  onClose: () => void;
  onContinue?: () => void;
  onShowInvestors: () => void;
}>;

export const ReleaseFinalAllocationComplianceDialog: React.FC<Props> = ({
  isOpen,
  offeringId,
  onClose,
  onContinue,
  onShowInvestors,
}) => {
  const { data, loading, error } = useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery({
    variables: {
      offeringId,
      errorCodes: [AlertCodes.AttestationNotOnFile, AlertCodes.AttestationExpired],
    },
    fetchPolicy: 'no-cache',
    skip: !isOpen,
  });

  const complianceErrorCount = data?.syndicateInstitutionalGrid?.summaries.totals.indicationCount;
  const hasComplianceErrors = !isNil(complianceErrorCount) ? complianceErrorCount > 0 : true;

  useEffect(() => {
    if (isOpen && !loading && !error && !hasComplianceErrors) {
      onContinue?.();
    }
  }, [isOpen, hasComplianceErrors, onContinue, loading, error]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Please Review Compliance Actions</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && (
          <Stack role="progressbar">
            <Skeleton width="50%" />
            <Skeleton />
            <Skeleton />
          </Stack>
        )}
        {!loading && hasComplianceErrors && (
          <React.Fragment>
            <Alert
              severity="warning"
              action={
                <Button variant="text" onClick={onShowInvestors}>
                  Show Investors
                </Button>
              }
            >
              Please review before continuing to release final allocations:
              <br />
              <Typography component="span" variant="highlight2">
                {numericUtil.getDisplayValueForInteger(complianceErrorCount)}{' '}
                {complianceErrorCount && complianceErrorCount > 1 ? 'investors' : 'investor'}
              </Typography>{' '}
              do not have a valid 5130/31 certificate on file with CMG XC.
            </Alert>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
