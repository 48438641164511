import { FormikSelectField, FormikTextField } from '@cmg/design-system-formik';
import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { notificationPeriodOptions } from './RegMWireForm.model';

export const RegMWireForm: React.FC = () => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem xs={6}>
        <FormikSelectField
          options={notificationPeriodOptions}
          label="Notification Period"
          name="notificationPeriod"
          required
        />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <FormikTextField name="offeringResult" label="Offering Result" required />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
