import { TradeRelease_TradeReleaseSettingsQuery } from './graphql';

export type ReleaseSettingsValues = {
  isAutoreleaseEnabled: boolean;
};

export const getInitialValues = (
  data?: TradeRelease_TradeReleaseSettingsQuery
): ReleaseSettingsValues => {
  return { isAutoreleaseEnabled: data?.tradeReleaseSettings.isAutoreleaseEnabled ?? false };
};
