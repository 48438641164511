import { ApolloError } from '@apollo/client';
import { AccountType, useAuth } from '@cmg/auth';
import React from 'react';

import {
  SNoRelationshipBanner,
  SNoRelationshipWBannerSellSide,
} from '../../../features/offering-side-panel/SNoRelationshipBanners';
import ServerErrorsBanner from '../indicators/server-error/ServerErrorsBanner';
import { errorCodes } from './error-codes/errorCodes';
import { SNotFound } from './SNotFound/SNotFound';

type Props = { error: ApolloError };

const ErrorRenderer: React.FC<Props> = ({ error }) => {
  const accountType = useAuth().oidcUserAccountType;

  const noPermission =
    accountType === AccountType.SELL_SIDE ? (
      <SNoRelationshipWBannerSellSide />
    ) : (
      <SNoRelationshipBanner />
    );

  switch (error.message) {
    case errorCodes['403']:
      return noPermission;
    case errorCodes['404']:
      return <SNotFound />;
    case errorCodes['422']:
      return null;
    default:
      return <ServerErrorsBanner error={error} />;
  }
};

export default ErrorRenderer;
