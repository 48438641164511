import { Option } from '@cmg/common';

import {
  InterestLevelInterestUnit,
  InterestLevelLimitType,
  OrderBookState,
} from '../../../graphql';

export const interestUnitLabels: { [key in InterestLevelInterestUnit]: string } = {
  [InterestLevelInterestUnit.Currency]: 'Currency',
  [InterestLevelInterestUnit.Percent]: 'Percent',
  [InterestLevelInterestUnit.Shares]: 'Shares',
};

export const interestUnitOptions = Object.values(InterestLevelInterestUnit).map(
  type =>
    ({
      label: interestUnitLabels[type],
      value: type,
    } as Option<InterestLevelInterestUnit>)
);

export const limitTypeLabels = {
  [InterestLevelLimitType.Price]: 'Price',
  [InterestLevelLimitType.Discount]: 'Discount',
  [InterestLevelLimitType.Premium]: 'Premium',
};

export const orderBookStateLabels = {
  [OrderBookState.Open]: 'Open',
  [OrderBookState.Closed]: 'Closed',
  [OrderBookState.NeverOpened]: 'Awaiting Offering Publish',
};
