/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_ReleaseTotalRetailDemandMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_ReleaseTotalRetailDemandMutation = {
  readonly __typename?: 'Mutation';
  readonly releaseRetailRetention: ReadonlyArray<{
    readonly __typename?: 'ReleasedRetailRetention';
    readonly id: string;
    readonly managerCmgEntityKey: string;
    readonly shareQuantity: number;
    readonly isReleased: boolean;
  }>;
};

export const OrderBook_ReleaseTotalRetailDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_ReleaseTotalRetailDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseRetailRetention' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_ReleaseTotalRetailDemandMutationFn = Apollo.MutationFunction<
  OrderBook_ReleaseTotalRetailDemandMutation,
  OrderBook_ReleaseTotalRetailDemandMutationVariables
>;

/**
 * __useOrderBook_ReleaseTotalRetailDemandMutation__
 *
 * To run a mutation, you first call `useOrderBook_ReleaseTotalRetailDemandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_ReleaseTotalRetailDemandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookReleaseTotalRetailDemandMutation, { data, loading, error }] = useOrderBook_ReleaseTotalRetailDemandMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_ReleaseTotalRetailDemandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_ReleaseTotalRetailDemandMutation,
    OrderBook_ReleaseTotalRetailDemandMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_ReleaseTotalRetailDemandMutation,
    OrderBook_ReleaseTotalRetailDemandMutationVariables
  >(OrderBook_ReleaseTotalRetailDemandDocument, options);
}
export type OrderBook_ReleaseTotalRetailDemandMutationHookResult = ReturnType<
  typeof useOrderBook_ReleaseTotalRetailDemandMutation
>;
export type OrderBook_ReleaseTotalRetailDemandMutationResult =
  Apollo.MutationResult<OrderBook_ReleaseTotalRetailDemandMutation>;
export type OrderBook_ReleaseTotalRetailDemandMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_ReleaseTotalRetailDemandMutation,
  OrderBook_ReleaseTotalRetailDemandMutationVariables
>;
