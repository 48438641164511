import { numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { managerRoleLabels } from '../../../../../types/domain/manager/constants';
import { StyledCell, StyledRow } from '../../ExpensesRoute.styles';
import { ExpensesValues } from '../../form/ExpensesForm.model';
import { FinalSettlement_ExpensesQuery } from '../../graphql';

export type Props = {
  managers: FinalSettlement_ExpensesQuery['expenses']['managers'];
};

const ReadOnlyManagerExpenses: React.FC<Props> = ({ managers }) => {
  const { values } = useFormikContext<ExpensesValues>();

  return (
    <React.Fragment>
      {values.managerExpenses.map(item => {
        const total = numericUtil.sum(
          item.travelAndMeals,
          item.technologyAndData,
          item.miscellaneous
        );
        const manager = managers.find(({ cmgEntityKey }) => cmgEntityKey === item.cmgEntityKey);
        if (manager) {
          return (
            <StyledRow key={item.cmgEntityKey} suppressHighlightingOnHover isFocused={false}>
              <Table.Cell>{manager.name}</Table.Cell>
              <Table.Cell>{managerRoleLabels[manager.role]}</Table.Cell>
              <StyledCell>{numericUtil.getDisplayValueForCurrency(item.travelAndMeals)}</StyledCell>
              <StyledCell>
                {numericUtil.getDisplayValueForCurrency(item.technologyAndData)}
              </StyledCell>
              <StyledCell>{numericUtil.getDisplayValueForCurrency(item.miscellaneous)}</StyledCell>
              <StyledCell bold>{numericUtil.getDisplayValueForCurrency(total)}</StyledCell>
              <StyledCell bold>
                {numericUtil.getDisplayValueForCurrency(item.reimbursement)}
              </StyledCell>
            </StyledRow>
          );
        } else {
          return null;
        }
      })}
    </React.Fragment>
  );
};

export default ReadOnlyManagerExpenses;
