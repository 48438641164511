import { IconButton } from '@cmg/common';
import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';

export const SColumn = styled.div`
  flex: 1;
`;
export const SIconHeaderColumn = styled.div`
  width: 20px;
`;
export const SHeaderColumn = styled(SColumn)`
  color: ${({ theme }) => theme.text.color.light};
`;
export const StyledFlexRow = styled(FlexLayout)`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};

  ${SColumn} {
    padding: 15px 10px;
  }
`;
export const SHeaderRow = styled(StyledFlexRow)`
  ${SColumn} {
    padding: 5px 10px;
  }
`;
export const StyledIconButton = styled(IconButton)`
  width: 20px;
`;
