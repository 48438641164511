/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_AssignDealTeamsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  payload: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;

export type Roadshows_AssignDealTeamsMutation = {
  readonly __typename?: 'Mutation';
  readonly assignDealTeamMembersToRoadshow: ReadonlyArray<{
    readonly __typename?: 'DealTeamMember';
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly mobilePhone?: string | null;
    readonly officePhone?: string | null;
    readonly department?: string | null;
    readonly type: Types.DealTeamMemberType;
    readonly status: Types.DealTeamMemberStatus;
  }>;
};

export type Roadshows_RoadshowDealTeamOrganizationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type Roadshows_RoadshowDealTeamOrganizationsQuery = {
  readonly __typename?: 'Query';
  readonly roadshowDealTeamOrganizations: ReadonlyArray<{
    readonly __typename?: 'DealTeamNameAndDepartments';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly departments: ReadonlyArray<string>;
  }>;
};

export type Roadshows_RoadshowDealTeamsQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_RoadshowDealTeamsQuery = {
  readonly __typename?: 'Query';
  readonly roadshowDealTeams: ReadonlyArray<{
    readonly __typename?: 'RoadshowsDealTeam';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'DealTeamMember';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly mobilePhone?: string | null;
      readonly officePhone?: string | null;
      readonly department?: string | null;
      readonly type: Types.DealTeamMemberType;
      readonly status: Types.DealTeamMemberStatus;
    }>;
  }>;
};

export const Roadshows_AssignDealTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_AssignDealTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignDealTeamMembersToRoadshow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_AssignDealTeamsMutationFn = Apollo.MutationFunction<
  Roadshows_AssignDealTeamsMutation,
  Roadshows_AssignDealTeamsMutationVariables
>;

/**
 * __useRoadshows_AssignDealTeamsMutation__
 *
 * To run a mutation, you first call `useRoadshows_AssignDealTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_AssignDealTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsAssignDealTeamsMutation, { data, loading, error }] = useRoadshows_AssignDealTeamsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_AssignDealTeamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_AssignDealTeamsMutation,
    Roadshows_AssignDealTeamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_AssignDealTeamsMutation,
    Roadshows_AssignDealTeamsMutationVariables
  >(Roadshows_AssignDealTeamsDocument, options);
}
export type Roadshows_AssignDealTeamsMutationHookResult = ReturnType<
  typeof useRoadshows_AssignDealTeamsMutation
>;
export type Roadshows_AssignDealTeamsMutationResult =
  Apollo.MutationResult<Roadshows_AssignDealTeamsMutation>;
export type Roadshows_AssignDealTeamsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_AssignDealTeamsMutation,
  Roadshows_AssignDealTeamsMutationVariables
>;
export const Roadshows_RoadshowDealTeamOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowDealTeamOrganizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDealTeamOrganizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamNameAndDepartmentsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamNameAndDepartmentsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DealTeamNameAndDepartments' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowDealTeamOrganizationsQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowDealTeamOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowDealTeamOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowDealTeamOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoadshows_RoadshowDealTeamOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Roadshows_RoadshowDealTeamOrganizationsQuery,
    Roadshows_RoadshowDealTeamOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowDealTeamOrganizationsQuery,
    Roadshows_RoadshowDealTeamOrganizationsQueryVariables
  >(Roadshows_RoadshowDealTeamOrganizationsDocument, options);
}
export function useRoadshows_RoadshowDealTeamOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowDealTeamOrganizationsQuery,
    Roadshows_RoadshowDealTeamOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowDealTeamOrganizationsQuery,
    Roadshows_RoadshowDealTeamOrganizationsQueryVariables
  >(Roadshows_RoadshowDealTeamOrganizationsDocument, options);
}
export type Roadshows_RoadshowDealTeamOrganizationsQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamOrganizationsQuery
>;
export type Roadshows_RoadshowDealTeamOrganizationsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamOrganizationsLazyQuery
>;
export type Roadshows_RoadshowDealTeamOrganizationsQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowDealTeamOrganizationsQuery,
  Roadshows_RoadshowDealTeamOrganizationsQueryVariables
>;
export const Roadshows_RoadshowDealTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowDealTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDealTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowsDealTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowsDealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowsDealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowDealTeamsQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowDealTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowDealTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowDealTeamsQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_RoadshowDealTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowDealTeamsQuery,
    Roadshows_RoadshowDealTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowDealTeamsQuery,
    Roadshows_RoadshowDealTeamsQueryVariables
  >(Roadshows_RoadshowDealTeamsDocument, options);
}
export function useRoadshows_RoadshowDealTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowDealTeamsQuery,
    Roadshows_RoadshowDealTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowDealTeamsQuery,
    Roadshows_RoadshowDealTeamsQueryVariables
  >(Roadshows_RoadshowDealTeamsDocument, options);
}
export type Roadshows_RoadshowDealTeamsQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamsQuery
>;
export type Roadshows_RoadshowDealTeamsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamsLazyQuery
>;
export type Roadshows_RoadshowDealTeamsQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowDealTeamsQuery,
  Roadshows_RoadshowDealTeamsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowDealTeamOrganizationsMockResponse = makeMockResponse<
  Roadshows_RoadshowDealTeamOrganizationsQueryVariables,
  Roadshows_RoadshowDealTeamOrganizationsQuery
>(Roadshows_RoadshowDealTeamOrganizationsDocument);

export const makeRoadshows_RoadshowDealTeamsMockResponse = makeMockResponse<
  Roadshows_RoadshowDealTeamsQueryVariables,
  Roadshows_RoadshowDealTeamsQuery
>(Roadshows_RoadshowDealTeamsDocument);
