/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_FilingPartsFragment = {
  readonly __typename?: 'Filing';
  readonly id: string;
  readonly filingType: Types.FilingType;
  readonly initialRegistrationValue?: number | null;
  readonly primarySharesBaseOffering?: number | null;
  readonly secondarySharesBaseOffering?: number | null;
  readonly notionalOverAllotmentAuthorized?: number | null;
  readonly primarySharesOverAllotmentAuthorized?: number | null;
  readonly secondarySharesOverAllotmentAuthorized?: number | null;
  readonly notionalOverAllotmentExercised?: number | null;
  readonly primarySharesOverAllotmentExercised?: number | null;
  readonly secondarySharesOverAllotmentExercised?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly offerPrice?: number | null;
  readonly postOfferingShares?: number | null;
  readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
  readonly lockUpPeriod?: number | null;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_FollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly id: string;
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type OfferingSetup_TermsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_TermsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly pricingCurrencyCode: string;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly totalSharesBaseOffering?: number | null;
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly notionalOverAllotmentExercised?: number | null;
      readonly primarySharesOverAllotmentExercised?: number | null;
      readonly secondarySharesOverAllotmentExercised?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
    readonly followOnPricing: {
      readonly __typename?: 'FollowOnPricing';
      readonly id: string;
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    };
  };
};

export type OfferingSetup_UpdateFollowOnPricingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateFollowOnPricingInput;
}>;

export type OfferingSetup_UpdateFollowOnPricingMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFollowOnPricing:
    | {
        readonly __typename?: 'FollowOnPricing';
        readonly id: string;
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_CreateFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateFilingInput;
}>;

export type OfferingSetup_CreateFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly createFiling:
    | {
        readonly __typename?: 'Filing';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly notionalOverAllotmentExercised?: number | null;
        readonly primarySharesOverAllotmentExercised?: number | null;
        readonly secondarySharesOverAllotmentExercised?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
        readonly recordStatus: Types.RecordStatus;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_UpdateFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
  input: Types.UpdateFilingInput;
}>;

export type OfferingSetup_UpdateFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFiling:
    | {
        readonly __typename?: 'Filing';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly notionalOverAllotmentExercised?: number | null;
        readonly primarySharesOverAllotmentExercised?: number | null;
        readonly secondarySharesOverAllotmentExercised?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
        readonly recordStatus: Types.RecordStatus;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_DeleteFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DeleteFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteFiling: string;
};

export const OfferingSetup_FilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_FollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_TermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Terms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_FollowOnPricingParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_TermsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_TermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_TermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_TermsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_TermsQuery(
  baseOptions: Apollo.QueryHookOptions<OfferingSetup_TermsQuery, OfferingSetup_TermsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_TermsQuery, OfferingSetup_TermsQueryVariables>(
    OfferingSetup_TermsDocument,
    options
  );
}
export function useOfferingSetup_TermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_TermsQuery,
    OfferingSetup_TermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_TermsQuery, OfferingSetup_TermsQueryVariables>(
    OfferingSetup_TermsDocument,
    options
  );
}
export type OfferingSetup_TermsQueryHookResult = ReturnType<typeof useOfferingSetup_TermsQuery>;
export type OfferingSetup_TermsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_TermsLazyQuery
>;
export type OfferingSetup_TermsQueryResult = Apollo.QueryResult<
  OfferingSetup_TermsQuery,
  OfferingSetup_TermsQueryVariables
>;
export const OfferingSetup_UpdateFollowOnPricingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateFollowOnPricing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFollowOnPricingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFollowOnPricing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_FollowOnPricingParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateFollowOnPricingMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateFollowOnPricingMutation,
  OfferingSetup_UpdateFollowOnPricingMutationVariables
>;

/**
 * __useOfferingSetup_UpdateFollowOnPricingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateFollowOnPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateFollowOnPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateFollowOnPricingMutation, { data, loading, error }] = useOfferingSetup_UpdateFollowOnPricingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateFollowOnPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateFollowOnPricingMutation,
    OfferingSetup_UpdateFollowOnPricingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateFollowOnPricingMutation,
    OfferingSetup_UpdateFollowOnPricingMutationVariables
  >(OfferingSetup_UpdateFollowOnPricingDocument, options);
}
export type OfferingSetup_UpdateFollowOnPricingMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateFollowOnPricingMutation
>;
export type OfferingSetup_UpdateFollowOnPricingMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateFollowOnPricingMutation>;
export type OfferingSetup_UpdateFollowOnPricingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateFollowOnPricingMutation,
  OfferingSetup_UpdateFollowOnPricingMutationVariables
>;
export const OfferingSetup_CreateFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateFilingMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateFilingMutation,
  OfferingSetup_CreateFilingMutationVariables
>;

/**
 * __useOfferingSetup_CreateFilingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateFilingMutation, { data, loading, error }] = useOfferingSetup_CreateFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateFilingMutation,
    OfferingSetup_CreateFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateFilingMutation,
    OfferingSetup_CreateFilingMutationVariables
  >(OfferingSetup_CreateFilingDocument, options);
}
export type OfferingSetup_CreateFilingMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateFilingMutation
>;
export type OfferingSetup_CreateFilingMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateFilingMutation>;
export type OfferingSetup_CreateFilingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_CreateFilingMutation,
  OfferingSetup_CreateFilingMutationVariables
>;
export const OfferingSetup_UpdateFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateFilingMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateFilingMutation,
  OfferingSetup_UpdateFilingMutationVariables
>;

/**
 * __useOfferingSetup_UpdateFilingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateFilingMutation, { data, loading, error }] = useOfferingSetup_UpdateFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateFilingMutation,
    OfferingSetup_UpdateFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateFilingMutation,
    OfferingSetup_UpdateFilingMutationVariables
  >(OfferingSetup_UpdateFilingDocument, options);
}
export type OfferingSetup_UpdateFilingMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateFilingMutation
>;
export type OfferingSetup_UpdateFilingMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateFilingMutation>;
export type OfferingSetup_UpdateFilingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateFilingMutation,
  OfferingSetup_UpdateFilingMutationVariables
>;
export const OfferingSetup_DeleteFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteFilingMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteFilingMutation,
  OfferingSetup_DeleteFilingMutationVariables
>;

/**
 * __useOfferingSetup_DeleteFilingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteFilingMutation, { data, loading, error }] = useOfferingSetup_DeleteFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useOfferingSetup_DeleteFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteFilingMutation,
    OfferingSetup_DeleteFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteFilingMutation,
    OfferingSetup_DeleteFilingMutationVariables
  >(OfferingSetup_DeleteFilingDocument, options);
}
export type OfferingSetup_DeleteFilingMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteFilingMutation
>;
export type OfferingSetup_DeleteFilingMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteFilingMutation>;
export type OfferingSetup_DeleteFilingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteFilingMutation,
  OfferingSetup_DeleteFilingMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_TermsMockResponse = makeMockResponse<
  OfferingSetup_TermsQueryVariables,
  OfferingSetup_TermsQuery
>(OfferingSetup_TermsDocument);
