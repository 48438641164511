import { Roadshows_EventParticipantPartsFragment } from '../../../../../common/graphql';

export function filterParticipants(participants: Roadshows_EventParticipantPartsFragment[]) {
  return {
    participantsIds: participants.filter(({ isAdHoc }) => !isAdHoc).map(({ id }) => id),
    adHocParticipants: participants
      .filter(({ isAdHoc }) => isAdHoc)
      .map(item => ({
        email: item.email,
        name: item.name,
        mobilePhone: item.mobilePhone,
        officePhone: item.officePhone,
        title: item.title,
      })),
  };
}
