import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { EventStatus, InvestorTeamInput } from '../../../../../../../graphql';
import { Roadshows_RoadshowPartsFragment } from '../../../../../common/graphql';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import { useRoadshows_RosterQuery } from '../../../common/graphql';
import { Values as ParticipantValues } from '../../../common/participants-form/ParticipantsForm.model';
import MeetingDetailsForm from '../../common/meeting-details-form/MeetingDetailsForm';
import {
  getMeetingPayload,
  MeetingValues,
} from '../../common/meeting-details-form/MeetingDetailsForm.model';
import MeetingSidePanel from '../../common/meeting-side-panel/MeetingSidePanel';
import { MeetingNavigationSteps } from '../../common/meeting-side-panel/MeetingSidePanel.model';
import InvestorsForm from '../common/investors-form/InvestorsForm';
import { Values as InvestorTeamsValues } from '../common/investors-form/InvestorsForm.model';
import MeetingDetails from '../sales-and-trading-details-panel/meeting-details/MeetingDetails';
import MeetingInvestors from '../sales-and-trading-details-panel/meeting-investors/MeetingInvestors';
import MeetingParticipants from '../sales-and-trading-details-panel/meeting-participants/MeetingParticipants';
import { getRoadshowDay } from '../sales-and-trading-details-panel/SalesAndTradingDetailsPanel.model';
import { CancelMeetingConfirmModal } from './cancel-meeting-confirm-modal/CancelMeetingConfirmModal';
import ConfirmMeetingConfirmModal from './confirm-meeting-confirm-modal/ConfirmMeetingConfirmModal';
import { DeleteMeetingConfirmModal } from './delete-meeting-confirm-modal/DeleteMeetingConfirmModal';
import { useRoadshows_MeetingQuery } from './graphql';
import { useCancelMeetingMutation } from './hooks/useCancelMeetingMutation';
import { useConfirmMeetingMutation } from './hooks/useConfirmMeetingMutation';
import { useDeleteMeetingMutation } from './hooks/useDeleteMeetingMutation';
import { useOpenMeetingToSalesMutation } from './hooks/useOpenToSalesMutation';
import { useUpdateInvestorTeamsMutation } from './hooks/useUpdateInvestorTeamsMutation';
import { useUpdateMeetingMutation } from './hooks/useUpdateMeetingMutation';
import { useUpdateMeetingParticipantsMutation } from './hooks/useUpdateMeetingParticipantsMutation';
import MeetingParticipantsForm from './meeting-participants-form/MeetingParticipantsForm';
import OpenToSalesConfirmModal from './open-to-sales-confirm-modal/OpenToSalesConfirmModal';
import { filterParticipants } from './RoadshowCoordinatorDetailsPanel.model';

export type Props = {
  meetingId: UUID;
  roadshow: Roadshows_RoadshowPartsFragment;
  onClose: () => void;
  stepId?: MeetingNavigationSteps;
};

const RoadshowCoordinatorDetailsPanel: React.FC<Props> = ({
  meetingId,
  onClose,
  roadshow,
  stepId = MeetingNavigationSteps.DETAILS,
}) => {
  const { push } = useHistory();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = React.useState(false);
  const [meetingHeaderInfo, setMeetingHeaderInfo] = React.useState<EventInfo | null>(null);
  const [showConfirmOpenToSalesModal, setShowConfirmOpenToSalesModal] = React.useState(false);
  const [showConfirmMeetingModal, setShowConfirmMeetingModal] = React.useState(false);
  const [updateMeeting, { loading: isSavingMeeting }] = useUpdateMeetingMutation();
  const [deleteMeeting, { loading: isDeletingMeeting }] = useDeleteMeetingMutation();
  const [cancelMeeting, { loading: isCancelingMeeting }] = useCancelMeetingMutation();
  const [updateParticipants, { loading: isSavingParticipants }] =
    useUpdateMeetingParticipantsMutation();
  const [updateInvestors, { loading: isSavingInvestors }] = useUpdateInvestorTeamsMutation();
  const [openToSales, { loading: isOpeningToSales }] = useOpenMeetingToSalesMutation();
  const [confirmMeeting, { loading: isConfirmingMeeting }] = useConfirmMeetingMutation();
  const {
    data: meetingData,
    loading: meetingLoading,
    error: meetingError,
  } = useRoadshows_MeetingQuery({
    variables: { meetingId, roadshowId: roadshow.id },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: rosterData,
    loading: rosterLoading,
    error: rosterError,
  } = useRoadshows_RosterQuery({
    variables: { roadshowId: roadshow.id },
    skip: !roadshow.companyName,
    fetchPolicy: 'cache-and-network',
  });

  const handleUpdateMeeting = async (values: MeetingValues) => {
    try {
      await updateMeeting({
        variables: {
          roadshowId: roadshow.id,
          meetingId,
          payload: getMeetingPayload(values),
        },
      });

      setMeetingHeaderInfo(EventInfo.MEETING_UPDATED);
    } catch {
      ToastManager.error('Failed to update meeting draft');
    }
  };

  const handleDeleteMeeting = async () => {
    try {
      await deleteMeeting({ variables: { meetingId, roadshowId: roadshow.id } });

      ToastManager.success('Meeting draft successfully deleted');
      onClose();
    } catch {
      ToastManager.error('Failed to delete meeting draft');
    }
  };

  const handleCancelMeeting = async () => {
    try {
      await cancelMeeting({ variables: { meetingId, roadshowId: roadshow.id } });

      ToastManager.success('Meeting successfully canceled');
      onClose();
    } catch {
      ToastManager.error('Failed to cancel meeting');
    }
  };

  const handleSubmitParticipants = async ({ participants }: ParticipantValues) => {
    try {
      const { participantsIds, adHocParticipants } = filterParticipants(participants);

      await updateParticipants({
        variables: { meetingId, roadshowId: roadshow.id, participantsIds, adHocParticipants },
      });

      ToastManager.success('Participants saved successfully');
    } catch {
      ToastManager.error('Failed to save participants');
    }
  };

  const handleSubmitInvestors = async ({ investors }: InvestorTeamsValues) => {
    try {
      const payload: InvestorTeamInput[] = investors.map(investor => ({
        cmgEntityKey: investor.cmgEntityKey,
        members: investor.members,
      }));

      // TBD: GQL migration
      await updateInvestors({ variables: { meetingId, roadshowId: roadshow.id, payload } });

      ToastManager.success('Investors saved successfully');
    } catch {
      ToastManager.error('Failed to save investors');
    }
  };

  const handleOpenToSales = async () => {
    try {
      await openToSales({ variables: { meetingId, roadshowId: roadshow.id } });

      setShowConfirmOpenToSalesModal(false);
      setMeetingHeaderInfo(EventInfo.OPEN_TO_SALES_SUCCESSFULLY);
    } catch {
      ToastManager.error('Failed to open meeting to sales');
    }
  };

  const handleConfirmMeeting = async () => {
    try {
      await confirmMeeting({ variables: { meetingId, roadshowId: roadshow.id } });

      setShowConfirmMeetingModal(false);
      setMeetingHeaderInfo(EventInfo.CONFIRMED_SUCCESSFULLY);
    } catch {
      ToastManager.error('Failed to confirm meeting');
    }
  };

  const handleNavigationChange = (nextStepId: MeetingNavigationSteps) => {
    push(
      routeFactory.roadshowMeetingDetails.getUrlPath({
        meetingId,
        roadshowId: roadshow.id,
        stepId: nextStepId,
      })
    );
  };

  const isSaving = isSavingMeeting || isSavingParticipants || isSavingInvestors;
  const isLoading = meetingLoading || rosterLoading;
  const error = meetingError || rosterError;
  const isCancelled = meetingData?.roadshowMeeting.meetingDetails.status === EventStatus.Cancelled;
  const meetingDetails = isCancelled ? (
    <MeetingDetails
      meeting={meetingData?.roadshowMeeting.meetingDetails}
      roadshowDay={getRoadshowDay(roadshow, meetingData?.roadshowMeeting.meetingDetails.date)}
    />
  ) : (
    <MeetingDetailsForm
      onSubmit={handleUpdateMeeting}
      onDelete={() => setShowConfirmDeleteModal(true)}
      onCancel={() => setShowConfirmCancelModal(true)}
      onOpenToSales={() => setShowConfirmOpenToSalesModal(true)}
      onConfirm={() => setShowConfirmMeetingModal(true)}
      isSaving={isSaving}
      isDeleting={isDeletingMeeting}
      isCanceling={isCancelingMeeting}
      isOpeningToSales={isOpeningToSales}
      isConfirming={isConfirmingMeeting}
      meeting={meetingData?.roadshowMeeting}
      roadshow={roadshow}
      meetingHeaderInfo={meetingHeaderInfo}
      meetingManagementMembers={rosterData?.roadshowManagementTeam.members}
    />
  );

  const meetingParticipants = isCancelled ? (
    <MeetingParticipants participants={meetingData?.roadshowMeeting.participantsTeam} />
  ) : (
    <MeetingParticipantsForm
      onSubmit={handleSubmitParticipants}
      roster={rosterData}
      companyName={roadshow.companyName}
      meetingHeaderInfo={meetingHeaderInfo}
      meetingStatus={meetingData?.roadshowMeeting.meetingDetails.status}
      roadshowStatus={roadshow.status}
      meetingInvestors={meetingData?.roadshowMeeting.investors}
      meetingManagementMembers={rosterData?.roadshowManagementTeam.members}
      participantTeams={meetingData?.roadshowMeeting.participantsTeam}
      onDelete={() => setShowConfirmDeleteModal(true)}
      onOpenToSales={() => setShowConfirmOpenToSalesModal(true)}
      onConfirm={() => setShowConfirmMeetingModal(true)}
      onCancel={() => setShowConfirmCancelModal(true)}
      isDeleting={isDeletingMeeting}
      isOpeningToSales={isOpeningToSales}
      isConfirming={isConfirmingMeeting}
      isSaving={isSaving}
    />
  );

  const meetingInvestors = isCancelled ? (
    <MeetingInvestors investors={meetingData?.roadshowMeeting.investors} />
  ) : (
    <InvestorsForm
      onSubmit={handleSubmitInvestors}
      onDelete={() => setShowConfirmDeleteModal(true)}
      onOpenToSales={() => setShowConfirmOpenToSalesModal(true)}
      onConfirm={() => setShowConfirmMeetingModal(true)}
      onCancel={() => setShowConfirmCancelModal(true)}
      isSaving={isSaving}
      isDeleting={isDeletingMeeting}
      isOpeningToSales={isOpeningToSales}
      isConfirming={isConfirmingMeeting}
      meetingHeaderInfo={meetingHeaderInfo}
      meeting={meetingData?.roadshowMeeting!}
      roadshowStatus={roadshow.status}
      meetingManagementMembers={rosterData?.roadshowManagementTeam.members}
    />
  );
  return (
    <MeetingSidePanel
      title="Meeting Details"
      onClose={onClose}
      eventStatus={meetingData?.roadshowMeeting.meetingDetails.status}
      activeNavigationStepId={stepId}
      onNavigationChange={handleNavigationChange}
      loading={isLoading}
      testId="roadshows / single-roadshow / calendar / meeting panel"
    >
      <FlexLayout expand direction="column">
        {error && <ServerErrorsBanner error={error} />}
        {stepId === MeetingNavigationSteps.DETAILS && meetingDetails}
        {stepId === MeetingNavigationSteps.TEAMS && !!meetingData && meetingParticipants}
        {stepId === MeetingNavigationSteps.INVESTORS && !!meetingData && meetingInvestors}
      </FlexLayout>
      <DeleteMeetingConfirmModal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteMeeting}
      />
      <CancelMeetingConfirmModal
        show={showConfirmCancelModal}
        onClose={() => setShowConfirmCancelModal(false)}
        loading={isCancelingMeeting}
        onConfirm={handleCancelMeeting}
      />
      <OpenToSalesConfirmModal
        show={showConfirmOpenToSalesModal}
        onClose={() => setShowConfirmOpenToSalesModal(false)}
        loading={isOpeningToSales}
        onConfirm={handleOpenToSales}
      />
      <ConfirmMeetingConfirmModal
        show={showConfirmMeetingModal}
        onClose={() => setShowConfirmMeetingModal(false)}
        loading={isConfirmingMeeting}
        onConfirm={handleConfirmMeeting}
      />
    </MeetingSidePanel>
  );
};

export default RoadshowCoordinatorDetailsPanel;
