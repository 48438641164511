import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateSellingGroupInvitationWireMutation,
  SyndicateWires_SellingGroupInvitationWiresDocument,
  useSyndicateWires_CreateSellingGroupInvitationWireMutation,
} from '../graphql';

export type CreateSellingGroupInvitationWireMutation =
  SyndicateWires_CreateSellingGroupInvitationWireMutation;

export const useCreateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_CreateSellingGroupInvitationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
