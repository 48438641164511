import { useCheckAccountType } from '@cmg/auth';
import { Popover, Switch } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SUnderwriterOnDealFilter = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
`;

export const SSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 5px;
`;

export type Props = {
  onChange: (value: boolean) => void;
  checked: boolean;
};
/**
 * UnderwriterOnDealFilter is a switch toggle that filters the calendar data by whether
 * the firm is an underwriter on the deal.
 */
const UnderwriterOnDealFilter: React.FC<Props> = ({ onChange, checked }) => {
  const isSellSide = useCheckAccountType('SELL_SIDE');

  const handleOnChange = checked => {
    onChange(checked);
  };

  return (
    <SUnderwriterOnDealFilter>
      <Popover
        variant="TOOLTIP"
        placement="topRight"
        content={
          isSellSide
            ? 'Show only those offerings on which my firm is an underwriter'
            : 'Show only those offerings for which my firm has submitted an indication'
        }
      >
        <SSwitchWrapper>
          <Switch
            onChange={handleOnChange}
            checkedContent="On"
            unCheckedContent="Off"
            value={checked}
          />
          <div>Participating</div>
        </SSwitchWrapper>
      </Popover>
    </SUnderwriterOnDealFilter>
  );
};

export default UnderwriterOnDealFilter;
