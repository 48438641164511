import { Option } from '@cmg/common';

import { Roadshows_DealTeamNameAndDepartmentsPartsFragment } from '../../../../../common/graphql';

export const getDealTeamOrganisationOptions = (
  organisations: readonly Roadshows_DealTeamNameAndDepartmentsPartsFragment[]
): Option[] => {
  return organisations.map(({ firmCmgEntityKey, firmName }) => ({
    label: firmName,
    value: firmCmgEntityKey,
  }));
};
