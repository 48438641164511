import { UUID } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../../common/components/layout/panel/Panel';
import { ExportButton } from '../../../common/components/export-button/ExportButton';
import { useAccountingContext } from '../common/accounting-context/AccountingContext';
import AccountingDataGrid from '../common/accounting-data-grid/AccountingDataGrid';
import { generateColumns, getTotalRow } from './FinalSettlementPanel.model';
import { SPanelHeaderContent, StyledPanelHeader } from './FinalSettlementPanel.styles';
import { useExportAccountingFinalSettlement } from './hooks/useExportAccountingFinalSettlement';

export type Props = {
  offeringId: UUID;
  offeringIssuerName: string;
};

const FinalSettlementPanel: React.FC<Props> = ({ offeringId, offeringIssuerName }) => {
  const { managers } = useAccountingContext();
  const theme = useTheme();

  const columns = React.useMemo(() => generateColumns(theme), [theme]);
  const totalRow = React.useMemo(() => getTotalRow(managers), [managers]);

  const { handleExport, loading: exportLoading } = useExportAccountingFinalSettlement({
    offeringId,
    offeringIssuerName,
  });

  return (
    <Panel>
      <StyledPanelHeader title="Final Settlement">
        <SPanelHeaderContent>
          <ExportButton
            loading={exportLoading}
            onExport={handleExport}
            testId="export-final-settlement-xls"
          />
        </SPanelHeaderContent>
      </StyledPanelHeader>
      <Panel.Content>
        <AccountingDataGrid columns={columns} rows={managers} totalRow={totalRow} />
      </Panel.Content>
    </Panel>
  );
};

export default FinalSettlementPanel;
