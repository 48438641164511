import { ApolloError } from '@apollo/client';

import { useComplianceCertificatesModel } from '../../../compliance/context/hooks/certificates/useCertificatesModel';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import { CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery } from '../../components/crm-selection/graphql/__generated__';
import { BuySideIndicationQuery } from '../../context/hooks/buy-side/__generated__';
import { useInvestorFirmModel } from '../../context/hooks/investorFirm/useInvestorFirmModel';
import { SalesAndTradingIndicationQuery } from '../../context/hooks/sales-and-trading/__generated__';
import { SyndicateIndicationQuery } from '../../context/hooks/syndicate/__generated__';
import { useBuySideIndicationModel } from './buy-side/useBuySideIndicationModel';
import { useSalesAndTradingIndicationModel } from './sales-and-trading/useSalesAndTradingIndicationModel';
import { useSyndicateIndicationModel } from './syndicate/useSyndicateIndicationModel';

type DefaultModel = {
  canRead: boolean;
  canUpdate: boolean;
  canCreate?: boolean;
};

export type IndicationType =
  | NonNullable<BuySideIndicationQuery['basicPublishedOffering']['myInstitutionalIndication']>
  | NonNullable<
      SyndicateIndicationQuery['basicPublishedOffering']['orderBook']['institutionalIndication']
    >
  | NonNullable<SalesAndTradingIndicationQuery['coveredIndication']>;

export type InstitutionalIndicationModel = DefaultModel & {
  data: IndicationType | null | undefined;
};

export type IndicationAcknowledgementModel = DefaultModel;
export type AllocationAcknowledgementModel = DefaultModel & {
  canBillingAndDeliveryAgentUpdate: boolean;
};
export type InvestorFirmModel = (
  | CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery
  | undefined
) & { loading: boolean };
//   allInvestorFirmsMappedToCmgEntityKey: Array<{
//     id?: string | null;
//     cmgEntityKey?: string | null;
//     firmKey?: string | null;
//     name: string;
//     address1?: string | null;
//     address2?: string | null;
//   }>
// };
export type PassModel = DefaultModel;
export type DupesModel = DefaultModel;
export type IndicationStatusModel = DefaultModel;

export type IndicationQueryType =
  | (SyndicateIndicationQuery & { persona: OrderBookPersonaType.SYNDICATE })
  | (SalesAndTradingIndicationQuery & { persona: OrderBookPersonaType.SALES_AND_TRADING })
  | (BuySideIndicationQuery & { persona: OrderBookPersonaType.BUY_SIDE });

export type QueryResultType = {
  loading: boolean;
  error: ApolloError | null;
  stopPolling?: () => void;
  data: IndicationQueryType | undefined;
};

export type OfferingSidePanelModel = {
  indication: InstitutionalIndicationModel;
  allocation: DefaultModel;
  indicationAcknowledgment: IndicationAcknowledgementModel;
  allocationAcknowledgment: AllocationAcknowledgementModel;
  indicationStatus: IndicationStatusModel;
  pass: PassModel;
  dupes: DupesModel;
  investorFirmInfo: InvestorFirmModel;
  queryResult: QueryResultType;
};

// For now we will not block old functionality and just include partially new funcionality
export const offeringSidePanelModelDefaults: OfferingSidePanelModel = {
  indication: { data: null, canRead: true, canUpdate: true, canCreate: true },
  allocation: { canRead: true, canUpdate: true },
  indicationAcknowledgment: {
    canRead: true,
    canUpdate: true,
  },
  allocationAcknowledgment: {
    canRead: true,
    canUpdate: true,
    canBillingAndDeliveryAgentUpdate: true,
  },
  indicationStatus: {
    canRead: true,
    canUpdate: true,
  },
  pass: {
    canRead: true,
    canUpdate: true,
  },
  dupes: {
    canRead: true,
    canUpdate: true,
  },
  investorFirmInfo: {
    allInvestorFirmsMappedToCmgEntityKey: [],
    loading: false,
  },
  queryResult: {} as QueryResultType,
};

export type Props = {
  offeringId: string;
  cmgEntityKey: string | undefined;
  indicationId?: string;
};

export type PersonaProps = {
  offeringId: string;
  type: OrderBookPersonaType;
};

/*
The useOfferingSidePanelModel is in charge of handling all the side panel data, and also helps us have a 
centralized place that we can use to decide what actions we can do with the side panel.

For example: canRead: This will help decide if Indication should be displayed or not, 
all the logic for deciding to display or not should be inside the property
*/
export const useOfferingSidePanelModel = ({
  offeringId,
  cmgEntityKey,
  indicationId,
}: Props): OfferingSidePanelModel => {
  const { type } = useIdentifyOrderBookPersona({ offeringId });
  const { hasComplianceWarning } = useComplianceCertificatesModel({
    offeringId,
    cmgEntityKey,
  }).warning;

  const investorFirmInfo = useInvestorFirmModel({
    type,
    cmgEntityKey,
  });

  const syndicateModel = useSyndicateIndicationModel({
    type,
    offeringId,
    cmgEntityKey,
    indicationId,
  });
  const salesAndTradingModel = useSalesAndTradingIndicationModel({
    type,
    offeringId,
    cmgEntityKey,
    indicationId,
    hasComplianceWarning,
  });
  const buySideModel = useBuySideIndicationModel({ type, hasComplianceWarning, offeringId });

  switch (type) {
    case OrderBookPersonaType.SYNDICATE: {
      return { ...syndicateModel, investorFirmInfo };
    }
    case OrderBookPersonaType.SALES_AND_TRADING: {
      return { ...salesAndTradingModel, investorFirmInfo };
    }
    case OrderBookPersonaType.BUY_SIDE: {
      return buySideModel;
    }
    default: {
      return offeringSidePanelModelDefaults;
    }
  }
};
