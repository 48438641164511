import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { InterestLevelInput } from '../../../../../../../../../graphql';
import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../my-orders/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import {
  SalesAndTradingIndicationDocument,
  SalesAndTradingIndicationQuery,
} from '../../../../../context/hooks/sales-and-trading/__generated__';
import { IndicationFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useManageIndication_UpdateCoveredIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(MyOrders_CoveredIndicationsDocument)!,
  getOperationName(SalesAndTradingIndicationDocument)!,
];

export const useUpdateCoveredIndication = (
  offeringId: UUID,
  indication: SalesAndTradingIndicationQuery['coveredIndication'],
  onFinishEditing: () => void
) => {
  const [updateCoveredIndication] = useManageIndication_UpdateCoveredIndicationMutation();

  const handleUpdateCoveredIndication = React.useCallback(
    async (values: IndicationFormValues) => {
      try {
        const response = await updateCoveredIndication({
          variables: {
            offeringId,
            indicationId: indication!.id,
            input: {
              instrumentId: values.instrumentId,
              currencyCode: values.currencyCode,
              // leftover from legacy code
              investorInformation: {
                contactName: indication?.investorInformation?.contactName ?? '',
                contactEmail: indication?.investorInformation?.contactEmail ?? '',
              },
              interestLevels: values.interestLevels.map<InterestLevelInput>(
                ({ interestQuantity, interestUnit, limitPrice, limitType }) => ({
                  interestUnit: interestUnit!,
                  interestQuantity: interestQuantity!,
                  limitType,
                  limitPrice,
                })
              ),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries,
        });
        const responseIndication = response.data!.updateCoveredIndication;
        if (responseIndication.__typename === 'ServiceError') {
          throw new Error('Sending update indication failed');
        }

        SnackbarManager.success('Successfully updated Indication');
        pendingOrderBookGridForcedRefreshVar(true);
        onFinishEditing();
      } catch {
        SnackbarManager.error('Failed to update Indication');
      }
    },
    [indication, offeringId, onFinishEditing, updateCoveredIndication]
  );

  return [handleUpdateCoveredIndication];
};
