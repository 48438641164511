/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_DealTeamQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type Roadshows_DealTeamQuery = {
  readonly __typename?: 'Query';
  readonly dealTeam: {
    readonly __typename?: 'DealTeam';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly departments: ReadonlyArray<string>;
    readonly participatedRoadshowNames: ReadonlyArray<string>;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'DealTeamMember';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly mobilePhone?: string | null;
      readonly officePhone?: string | null;
      readonly department?: string | null;
      readonly type: Types.DealTeamMemberType;
      readonly status: Types.DealTeamMemberStatus;
    }>;
  };
};

export type Roadshows_UpdateDealTeamMutationVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
  payload: Types.UpdateDealTeamInput;
}>;

export type Roadshows_UpdateDealTeamMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDealTeam: {
    readonly __typename?: 'DealTeam';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly departments: ReadonlyArray<string>;
    readonly participatedRoadshowNames: ReadonlyArray<string>;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'DealTeamMember';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly mobilePhone?: string | null;
      readonly officePhone?: string | null;
      readonly department?: string | null;
      readonly type: Types.DealTeamMemberType;
      readonly status: Types.DealTeamMemberStatus;
    }>;
  };
};

export const Roadshows_DealTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_DealTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_DealTeamQuery__
 *
 * To run a query within a React component, call `useRoadshows_DealTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DealTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_DealTeamQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRoadshows_DealTeamQuery(
  baseOptions: Apollo.QueryHookOptions<Roadshows_DealTeamQuery, Roadshows_DealTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_DealTeamQuery, Roadshows_DealTeamQueryVariables>(
    Roadshows_DealTeamDocument,
    options
  );
}
export function useRoadshows_DealTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_DealTeamQuery,
    Roadshows_DealTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_DealTeamQuery, Roadshows_DealTeamQueryVariables>(
    Roadshows_DealTeamDocument,
    options
  );
}
export type Roadshows_DealTeamQueryHookResult = ReturnType<typeof useRoadshows_DealTeamQuery>;
export type Roadshows_DealTeamLazyQueryHookResult = ReturnType<
  typeof useRoadshows_DealTeamLazyQuery
>;
export type Roadshows_DealTeamQueryResult = Apollo.QueryResult<
  Roadshows_DealTeamQuery,
  Roadshows_DealTeamQueryVariables
>;
export const Roadshows_UpdateDealTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateDealTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDealTeamInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDealTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateDealTeamMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateDealTeamMutation,
  Roadshows_UpdateDealTeamMutationVariables
>;

/**
 * __useRoadshows_UpdateDealTeamMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateDealTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateDealTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateDealTeamMutation, { data, loading, error }] = useRoadshows_UpdateDealTeamMutation({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateDealTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateDealTeamMutation,
    Roadshows_UpdateDealTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateDealTeamMutation,
    Roadshows_UpdateDealTeamMutationVariables
  >(Roadshows_UpdateDealTeamDocument, options);
}
export type Roadshows_UpdateDealTeamMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateDealTeamMutation
>;
export type Roadshows_UpdateDealTeamMutationResult =
  Apollo.MutationResult<Roadshows_UpdateDealTeamMutation>;
export type Roadshows_UpdateDealTeamMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateDealTeamMutation,
  Roadshows_UpdateDealTeamMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRoadshows_DealTeamMockResponse = makeMockResponse<
  Roadshows_DealTeamQueryVariables,
  Roadshows_DealTeamQuery
>(Roadshows_DealTeamDocument);
