import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreatePaymentWireMutation,
  SyndicateWires_PaymentWiresDocument,
  useSyndicateWires_CreatePaymentWireMutation,
} from '../graphql';

export type CreatePaymentWireMutation = SyndicateWires_CreatePaymentWireMutation;

export const useCreatePaymentWireMutation = () => {
  return useSyndicateWires_CreatePaymentWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PaymentWiresDocument];
  return getManyOperationNames(documentNodes);
}
