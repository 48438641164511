import styled, { css } from 'styled-components/macro';

export const SLeftLead = styled.span<{ hasTooltip: boolean }>`
  ${({ hasTooltip }) =>
    hasTooltip
      ? css`
          cursor: pointer;
          text-decoration: underline dotted;
        `
      : ''}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
