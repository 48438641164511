import { Panel, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ErrorRenderer from '../../common/components/errorRenderer/Error-Renderer';
import Spinner from '../../common/components/overlays/spinner/Spinner';
import { OfferingDomainObject } from '../offering/components/design-system';
import OfferingSummaryView from '../offering-setup/summary/view/OfferingSummaryView';
import { mapOfferingProfile } from '../offering-setup/summary/view/OfferingSummaryView.model';
import {
  useSharedDraftOffering_OfferingHeaderQuery,
  useSharedDraftOffering_SharedDraftOfferingQuery,
} from './graphql';
import { SOfferingScreen } from './SharedDraftOfferingRoute.styles';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;

export type Props = RouteProps;

const SharedDraftOfferingRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const {
    data: headerQueryData,
    loading: headerQueryLoading,
    error: headerQueryError,
  } = useSharedDraftOffering_OfferingHeaderQuery({ variables: { offeringId } });

  const headerData = headerQueryData?.offeringHeader;

  const offeringHeaderProps: React.ComponentProps<typeof OfferingDomainObject> | null = headerData
    ? {
        offeringId: headerData.id,
        stage: headerData.stage,
        status: headerData.status,
        type: headerData.type,
        issuerName: headerData.issuer.name,
        isAuthor: headerData.isAuthor ?? false,
        sector: headerData.issuer.sector,
        isDiscarded: headerData.isDiscarded,
        isSharedDraft: true,
        sizeInShares: headerData.sizeInShares,
        securityType: headerData.security.type,
        terms: headerData.terms,
      }
    : null;

  const {
    loading: offeringQueryLoading,
    error: offeringQueryError,
    data: offeringQueryData,
  } = useSharedDraftOffering_SharedDraftOfferingQuery({
    variables: { offeringId },
    skip: !offeringId,
  });

  const loading = headerQueryLoading || offeringQueryLoading;
  const error = headerQueryError || offeringQueryError;
  const data = headerQueryData && offeringQueryData;

  if (error && !data) {
    return <ErrorRenderer error={error} />;
  }

  if (loading && !data) {
    return <Spinner show />;
  }

  return (
    <SOfferingScreen data-test-id={xcSelectors.sharedDraftOfferingScreen.testId}>
      {offeringId && offeringHeaderProps && <OfferingDomainObject {...offeringHeaderProps} />}
      {offeringQueryData && (
        <Panel>
          <OfferingSummaryView
            offering={mapOfferingProfile(offeringQueryData.sharedDraftOffering)}
          />
        </Panel>
      )}
    </SOfferingScreen>
  );
};

export default SharedDraftOfferingRoute;
