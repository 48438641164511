import styled from 'styled-components/macro';

export const STotalWrapper = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SBoldText = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const STable = styled.table`
  min-width: 300px;
`;
