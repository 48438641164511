import { MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { Sector } from '../../../../../../graphql';
import { sectorLabels } from '../../../../../constants';

export const SSectorFilter = styled.div`
  min-width: 220px;
`;

export type Props = {
  sectors: Sector[];
  onChange: (value: Sector[]) => void;
  values: Sector[];
};

/**
 * SectorFilter is responsible for setting the issuer sector filter value from
 * a list of provided sectors
 */
const SectorFilter: React.FC<Props> = ({ sectors, onChange, values }) => {
  const options = sectors.map<Option<Sector>>(sector => ({
    label: sectorLabels[sector],
    value: sector,
  }));

  return (
    <SSectorFilter>
      <MultiSelect<Sector>
        options={options}
        onChange={onChange}
        value={values}
        maxDisplayedValues={2}
        placeholder="Sector..."
      />
    </SSectorFilter>
  );
};

export default SectorFilter;
