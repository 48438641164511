import { ListItemIcon, styled } from '@cmg/design-system';

const CmgLogoListItemIcon = styled(
  ListItemIcon,
  {}
)(({ theme }) => ({
  minWidth: theme.spacing(3.5),
  paddingRight: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  alignSelf: 'flex-start',
}));

export default CmgLogoListItemIcon;
