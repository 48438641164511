import { numericUtil } from '@cmg/common';

import { TotalRetailBulkSaveValues } from '../BulkSaveTotalRetailDemand.model';

const { sum } = numericUtil;

type RetailDemandTotals = {
  totalDemandShares: number | null;
  totalRoundLots: number | null;
  totalRetention: number | null;
};

export const calculateTotals = (values: TotalRetailBulkSaveValues): RetailDemandTotals => {
  const totalDemandShares = sum(...values.demands.map(({ demandShares }) => demandShares)),
    totalRoundLots = sum(...values.demands.map(({ roundLots }) => roundLots)),
    totalRetention = sum(...values.demands.map(({ retentionShares }) => retentionShares));

  return { totalDemandShares, totalRoundLots, totalRetention };
};
