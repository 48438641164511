import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/** A variation of useEffect that skips the initial render and only invokes effect when subsequent updates to deps occur. */
export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList): void => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
