import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  DesignationRecipientInputData,
  SyndicateWiresManagerRole,
  WireTemplateType,
} from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { DesignationWireForm } from './DesignationWireForm';
import {
  DesignationRecipientUserData,
  validationSchema,
  Values,
} from './DesignationWireForm.model';
import {
  SyndicateWires_DesignationWirePartsFragment,
  SyndicateWires_DesignationWireValidationPartsFragment,
  useSyndicateWires_DesignationWireDetailsQuery,
  useSyndicateWires_DesignationWirePreviewQuery,
  useSyndicateWires_DesignationWiresQuery,
  useSyndicateWires_DesignationWireValidationQuery,
} from './graphql';
import { useCreateDesignationWireMutation } from './hooks/useCreateDesignationWireMutation';
import { useDeleteDesignationWireMutation } from './hooks/useDeleteDesignationWireMutation';
import { useSendDesignationWireMutation } from './hooks/useSendDesignationWireMutation';
import { useUpdateDesignationWireMutation } from './hooks/useUpdateDesignationWireMutation';

const wireTypeName = 'Designation';

const getDesignationRecipientInputData = (formValues: Values) => {
  const { disclaimer, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<DesignationRecipientInputData>(recipientUserData => {
      return {
        ...recipientUserData!,
        disclaimer,
      };
    });
};

const managerFilter = (manager: SyndicateWireManager) => {
  return (
    manager.role !== SyndicateWiresManagerRole.Underwriter &&
    manager.role !== SyndicateWiresManagerRole.SellingGroupMember
  );
};

export const wireTypeConfigDesignation = createWireTypeConfig<
  Values,
  SyndicateWires_DesignationWirePartsFragment,
  SyndicateWires_DesignationWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresDesignation
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresDesignation,
  wireTemplateType: WireTemplateType.DesignationWithIntegration,

  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_DesignationWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.designationWirePreview.htmlContent,
      isLoading: loading,
      error,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_DesignationWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.designationWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_DesignationWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.designationWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_DesignationWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data?.designationWireValidation,
      isLoading: loading,
      error,
      refetch,
    };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateDesignationWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getDesignationRecipientInputData(values),
            },
          },
        });

        if (result.data?.createDesignationWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createDesignationWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateDesignationWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getDesignationRecipientInputData(values),
            },
          },
        });

        if (result.data?.updateDesignationWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateDesignationWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteDesignationWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteDesignationWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendDesignationWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendDesignationWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendDesignationWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    wire,
    offeringId,
    managers,
    offeringType,
    wireTemplateType,
    operationType,
  }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      /**
       * disclaimer is a common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0].disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );
      const recipientsUserData = managers.map<DesignationRecipientUserData | null>(manager => {
        const managerValues = wire?.recipientsUserData?.find(
          ({ recipient }) => recipient === manager.cmgEntityKey
        );

        if (managerValues) {
          return {
            recipient: managerValues.recipient,
            headerNote: managerValues.headerNote,
          };
        }

        return {
          recipient: manager.cmgEntityKey,
          headerNote: null,
        };
      });

      return {
        disclaimer,
        recipientsUserData,
      };
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
    ]);

    return { isLoading: disclaimerLoading, data, error: disclaimerError };
  },

  createOrUpdateModalSize: 'md',
  CreateOrUpdateForm: DesignationWireForm,
  formValidation: validationSchema,
});
