import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteGreenShoeWireMutation,
  SyndicateWires_GreenShoeWiresDocument,
  useSyndicateWires_DeleteGreenShoeWireMutation,
} from '../graphql';

export type DeleteGreenShoeWireMutation = SyndicateWires_DeleteGreenShoeWireMutation;

export const useDeleteGreenShoeWireMutation = () => {
  return useSyndicateWires_DeleteGreenShoeWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_GreenShoeWiresDocument];
  return getManyOperationNames(documentNodes);
}
