import styled from 'styled-components/macro';

import SetupForm from '../components/form/OfferingSetupForm';

export const StyledColumn = styled(SetupForm.Column)`
  align-self: flex-end;
  margin-right: 10px;
`;

export const StyledInstrumentsColumn = styled(StyledColumn)`
  flex-basis: 45%;
`;

export const StyledDefaultInstrumentColumn = styled(StyledColumn)`
  flex: 1;
`;
