import { AttestationFormStatus } from '../../../../../../graphql';

export const isAttestationStatusValid = (status: AttestationFormStatus) => {
  return status === AttestationFormStatus.Blanket || status === AttestationFormStatus.Partial;
};

export const getAttestationStatusColor = (status: AttestationFormStatus) => {
  if (isAttestationStatusValid(status)) {
    return 'success';
  }
  return 'error';
};
