import { Icon, Theme } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ReleaseStatus } from '../../../../graphql';
import { SIconWrapper } from '../../layout/TradeReleaseLayout.styles';

export type Props = {
  status: ReleaseStatus;
};

const releaseStatusMap = new Map<ReleaseStatus, string>([
  [ReleaseStatus.Released, 'Released'],
  [ReleaseStatus.PartiallyBooked, 'Partially Booked'],
  [ReleaseStatus.Booked, 'Booked'],
  [ReleaseStatus.BookedManual, 'Booked (Manual)'],
  [ReleaseStatus.CancelPending, 'Cancel Pending'],
  [ReleaseStatus.Cancelled, 'Cancelled'],
  [ReleaseStatus.CancelledManual, 'Cancelled (Manual)'],
  [ReleaseStatus.ReleaseFailed, 'Release Failed'],
]);

export function getReleaseStatusColorMap(theme: Theme): Record<ReleaseStatus, string> {
  return {
    [ReleaseStatus.Released]: theme.designSystem.colors.turquoise[300],
    [ReleaseStatus.PartiallyBooked]: theme.designSystem.colors.turquoise[300],
    [ReleaseStatus.Booked]: theme.designSystem.colors.green[300],
    [ReleaseStatus.BookedManual]: theme.designSystem.colors.green[300],
    [ReleaseStatus.CancelPending]: theme.designSystem.colors.yellow[300],
    [ReleaseStatus.Cancelled]: theme.designSystem.colors.red[300],
    [ReleaseStatus.CancelledManual]: theme.designSystem.colors.red[300],
    [ReleaseStatus.ReleaseFailed]: theme.designSystem.colors.red[300],
  };
}

export const TradeReleaseStatus: React.FC<Props> = ({ status }) => {
  const theme = useTheme();
  const releaseStatusColorMap = React.useMemo(() => getReleaseStatusColorMap(theme), [theme]);

  return (
    <div>
      <SIconWrapper>
        <Icon name="circle" variant="solid" size="xs" color={releaseStatusColorMap[status]} />
      </SIconWrapper>
      {releaseStatusMap.get(status)}
    </div>
  );
};
