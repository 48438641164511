import { Alert, LinkButton } from '@cmg/common';
import React from 'react';

export type Props = { onEdit: () => void };

const OutOfBalanceAlert: React.FC<Props> = ({ onEdit }) => {
  return (
    <Alert severity="error" action={<LinkButton onClick={onEdit}>Edit Sales Credits</LinkButton>}>
      <Alert.Title>Sales Credits are out of balance</Alert.Title>
      <Alert.Details>
        Designated Shares have been updated and now Sales Credits are out of balance. Please
        allocate the remaining Sales Credits amount before taking any further action.
      </Alert.Details>
    </Alert>
  );
};

export default OutOfBalanceAlert;
