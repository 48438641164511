import { UUID } from '@cmg/common';
import { Alert, AlertTitle } from '@cmg/design-system';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { OrderBookPageLayout } from '../components/order-book-page-layout/OrderBookPageLayout';
import { useOrderBook_MyRetailDemandQuery } from './graphql';
import MyRetailDemandPageLayout from './page-layout/MyRetailDemandPageLayout';
import MyRetailDemandPageSkeleton from './page-skeleton/MyRetailDemandPageSkeleton';

export type Props = {
  issuerName?: string;
} & RouteComponentProps<{ offeringId: UUID }>;

const MyRetailDemand: React.VFC<Props> = ({ issuerName, match }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.orderBookMyRetailDemand.getDocumentTitle({
      issuerName,
    })
  );

  const { data, loading, error } = useOrderBook_MyRetailDemandQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return (
      <OrderBookPageLayout subNavigationProps={{ offeringId }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      </OrderBookPageLayout>
    );
  }

  if (loading) {
    return (
      <OrderBookPageLayout subNavigationProps={{ offeringId }}>
        <MyRetailDemandPageSkeleton />
      </OrderBookPageLayout>
    );
  }

  const instrumentId = data?.publishedOffering.instruments[0].id!;
  const trancheId = data?.publishedOffering.tranches?.[0].id!;

  return (
    <OrderBookPageLayout subNavigationProps={{ offeringId }}>
      <MyRetailDemandPageLayout
        retailSummary={data?.myRetailSummary!}
        offeringId={offeringId}
        instrumentId={instrumentId}
        trancheId={trancheId}
        offeringIssuerName={issuerName ?? 'Issuer'}
      />
    </OrderBookPageLayout>
  );
};

export default MyRetailDemand;
