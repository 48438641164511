import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Banner from '../../../../../common/components/indicators/banner/Banner';
import routeFactory from '../../../../../common/util/routeFactory';
import { stepIds } from '../../../constants';
import { EconomicsBreakdownErrors as EconomicsBreakdownErrorsType } from '../../hooks/useValidateEconomicBreakdownGrid';

type Props = {
  errors: EconomicsBreakdownErrorsType;
  offeringId: string;
};

export const EconomicsBreakdownErrors = ({ errors, offeringId }: Props) => {
  const {
    totalIncentiveFee,
    totalUnderwritingFee,
    missingOfferingSize,
    missingBaseGrossSpread,
    missingGrossSpreadAllocation,
    missingOverallotmentExercised,
    missingIncentiveFeeAllocation,
  } = errors;

  const hasLink =
    missingOfferingSize ||
    missingBaseGrossSpread ||
    missingGrossSpreadAllocation ||
    missingIncentiveFeeAllocation ||
    missingOverallotmentExercised;

  const errorMessage = useMemo(() => {
    let errorMessage: string | null = null;

    if (totalIncentiveFee) {
      errorMessage =
        'Economics cannot be calculated until Total Incentive Fee % is equal to 100.00%';
    } else if (totalUnderwritingFee) {
      errorMessage =
        'Economics cannot be calculated until Total U/W % (unadj. for incentive fee) is equal to 100.00%';
    } else if (
      missingOfferingSize ||
      missingGrossSpreadAllocation ||
      missingOverallotmentExercised ||
      missingIncentiveFeeAllocation ||
      missingBaseGrossSpread
    ) {
      errorMessage = 'Economics cannot be calculated until the following information is populated:';
    }

    return errorMessage;
  }, [
    totalIncentiveFee,
    totalUnderwritingFee,
    missingOfferingSize,
    missingBaseGrossSpread,
    missingGrossSpreadAllocation,
    missingOverallotmentExercised,
    missingIncentiveFeeAllocation,
  ]);

  return errorMessage ? (
    <Banner withoutMargin variant="error">
      {errorMessage}
      {hasLink && <br />}
      {!totalIncentiveFee && !totalUnderwritingFee && (
        <React.Fragment>
          {missingOfferingSize && (
            <Link
              to={routeFactory.offeringSetup.getUrlPath({
                stepId: stepIds.OFFERING_TERMS,
                offeringId,
              })}
            >
              Offering Terms: Offering Size;
            </Link>
          )}
          {(missingGrossSpreadAllocation ||
            missingIncentiveFeeAllocation ||
            missingBaseGrossSpread) && (
            <Link
              to={routeFactory.offeringSetup.getUrlPath({
                stepId: stepIds.UNDERWRITING_TERMS,
                offeringId,
              })}
            >
              U/W Discounts & Fees:{' '}
              {missingBaseGrossSpread
                ? 'Underwriting Discount: Base Gross Spread'
                : 'Gross Spread Allocation'}
            </Link>
          )}
          {missingOverallotmentExercised && (
            <Link
              to={routeFactory.offeringSetup.getUrlPath({
                stepId: stepIds.OFFERING_TERMS,
                offeringId,
              })}
            >
              Offering Terms: Overallotment Exercised;
            </Link>
          )}
        </React.Fragment>
      )}
    </Banner>
  ) : null;
};
