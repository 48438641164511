import { UUID } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import EmptyDealTeamDetails from './empty-deal-team-details/EmptyDealTeamDetails';
import { useRoadshows_RoadshowDealTeamsQuery } from './graphql';
import SingleDealTeamDetails from './single-deal-team-details/SingleDealTeamDetails';

export type Props = { roadshowId: UUID };

const DealTeamDetails: React.FC<Props> = ({ roadshowId }) => {
  const { data, loading, error } = useRoadshows_RoadshowDealTeamsQuery({
    variables: { roadshowId },
  });

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const dealTeams = data?.roadshowDealTeams ?? [];
  const hasDealTeams = dealTeams.length > 0;

  if (hasDealTeams) {
    return <SingleDealTeamDetails roadshowId={roadshowId} participatingDealTeams={dealTeams} />;
  }

  return <EmptyDealTeamDetails roadshowId={roadshowId} />;
};

export default DealTeamDetails;
