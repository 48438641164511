import { DateTimeValue, ISODate, Option } from '@cmg/common';
import { addDays, addMilliseconds } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

// TODO should be part of either xc/common or common package itself (the final location is yet to be decided)
export function getLabelFromOptions<T>(key: T, options: Option<T>[]): string {
  return options.find(option => option.value === key)?.label ?? '';
}

/**
 * Computes the Restriction Start Date/Time based on the Pricing Date and the Restriction Period
 */
export const getRestrictionStartDateTime = (
  date: ISODate | undefined | null,
  restrictionPeriod: 'FIVE_DAYS' | 'ONE_DAY',
  timezone: string
): Required<DateTimeValue> | null => {
  if (!date) {
    return null;
  }
  const dateValue = new Date(date);
  const restrictionPeriodLength = restrictionPeriod === 'FIVE_DAYS' ? 5 : 1;
  const restrictionStartDate = addDays(dateValue, -restrictionPeriodLength);
  const adjustedDateTime = addMilliseconds(
    restrictionStartDate,
    -getTimezoneOffset(timezone, restrictionStartDate)
  ); // we need to adjust the datetime for selected timezone so input field always shows constant time (12:00 AM)

  return { date: adjustedDateTime.toISOString(), timezone };
};
