import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { AssignContactInput } from '../../../../../../../../../graphql';
import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../my-orders/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import {
  parseFirmKey,
  useCmgEntityKeyContext,
} from '../../../../../../../contexts/CmgEntityKeyContext';
import { useFirmKeyOrIdContext } from '../../../../../../../contexts/FirmKeyOrIdContext';
import { SalesAndTradingIndicationDocument } from '../../../../../context/hooks/sales-and-trading/__generated__';
import { IndicationForm_IndicationPartsFragment } from '../../../../indication-form/graphql/__generated__';
import { useInvestorContactInformation_AssignCoveredContactsMutation } from '../../../../investor-contact-information/graphql/__generated__';
import { createInstitutionalIndicationSubmitInputVariables } from '../../../common/utils/CreateIndicationVariables';
import { IndicationCreateFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useCreateIndication_SubmitCoveredIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(MyOrders_CoveredIndicationsDocument)!,
  getOperationName(SalesAndTradingIndicationDocument)!,
];

export const useCreateCoveredIndication = (offeringId: UUID) => {
  const [createCoveredIndication] = useCreateIndication_SubmitCoveredIndicationMutation();
  const [assignCoveredContacts] = useInvestorContactInformation_AssignCoveredContactsMutation();

  const { firmKey } = useFirmKeyOrIdContext();
  const { cmgEntityKey, updateCmgEntityKey } = useCmgEntityKeyContext();
  const firmKeyOnUrl = parseFirmKey(cmgEntityKey);

  const handleCreateCoveredIndication = React.useCallback(
    async (values: IndicationCreateFormValues) => {
      let returnIndication: IndicationForm_IndicationPartsFragment;
      let indicationSubmitted = false;
      try {
        const response = await createCoveredIndication({
          variables: createInstitutionalIndicationSubmitInputVariables({
            offeringId,
            values,
            firmKey,
            cmgEntityKey,
          }),
          awaitRefetchQueries: true,
          refetchQueries,
        });

        const responseIndication = response.data!.submitCoveredInstitutionalIndication;
        if (responseIndication.__typename === 'ServiceError') {
          throw new Error('Sending new indication failed');
        }
        returnIndication = responseIndication;

        // in case the indication was created with investor not linked to a cmg entity
        // (i.e. via firmKey), update cmgEntityKey context with newly created cmg entity
        if (firmKeyOnUrl) {
          updateCmgEntityKey(returnIndication.investorInformation.cmgEntityKey!);
        }

        pendingOrderBookGridForcedRefreshVar(true);
        indicationSubmitted = true;
      } catch {
        SnackbarManager.error('Failed to create Indication');
        return;
      }

      try {
        await assignCoveredContacts({
          variables: {
            indicationId: returnIndication.id,
            offeringId,
            input: values.sellSideContacts.map<AssignContactInput>(({ name, email }) => ({
              email,
              name,
            })),
          },
        });
      } catch {
        SnackbarManager.error('Failed to assign contacts');
      }

      if (indicationSubmitted) {
        SnackbarManager.success('Successfully created Indication');
      }
    },
    [
      assignCoveredContacts,
      cmgEntityKey,
      createCoveredIndication,
      firmKey,
      firmKeyOnUrl,
      offeringId,
      updateCmgEntityKey,
    ]
  );

  return [handleCreateCoveredIndication];
};
