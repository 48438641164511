import styled from 'styled-components/macro';

export const SGridWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  height: 200px;
  display: flex;

  .ag-root-wrapper {
    width: 100%;
    height: 200px !important;
  }
`;

export const SCmgEntityNameWrapper = styled.div`
  height: 24px;
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SFirmNameColumn = styled.div`
  color: ${({ theme }) => theme.text.color.link};
`;

export const SCmgEntityName = styled.div`
  padding-left: 3px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;
