import { useMemo } from 'react';

import { NonSyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { useBrokerDealersMyRetailQuery } from './useBrokerDealersMyRetailQuery';

export const useNonSyndicateWireManagersQuery = (offeringId: string) => {
  const { data, loading, error, refetch } = useBrokerDealersMyRetailQuery({ offeringId });

  const managers: NonSyndicateWireManager[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map<NonSyndicateWireManager>(retail => ({
      cmgEntityKey: retail.cmgEntityKey,
      name: retail.name,
    }));
  }, [data]);

  return {
    data: managers,
    loading,
    error,
    refetch,
  };
};
