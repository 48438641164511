import { UUID } from '@cmg/common';
import * as yup from 'yup';

import { ExpensesManagerRole, UpdateAccountingAdjustmentsInput } from '../../../../../../graphql';
import { MAX_53_BIT_INT } from '../../../../../../types/graphql/constants';
import { FinalSettlement_AccountingManagerPartsFragment } from '../../../graphql';

export type AccountingManagerValues = {
  managerCmgEntityKey: UUID;
  manualAdjustment: number | null | undefined;
  description: string | null | undefined;
};

export type Values = {
  managers: AccountingManagerValues[];
};

export const getInitialValues = (
  managersData: readonly FinalSettlement_AccountingManagerPartsFragment[]
): Values => {
  const managers = managersData
    .filter(({ managerRole }) => managerRole !== ExpensesManagerRole.SellingGroupMember)
    .map(({ managerCmgEntityKey, manualAdjustment, description }) => ({
      managerCmgEntityKey,
      manualAdjustment,
      description,
    }));

  return { managers };
};

export const managerSchema = yup.object({
  manualAdjustment: yup
    .number()
    .min(-MAX_53_BIT_INT, 'Invalid Manual Adjustment value')
    .max(MAX_53_BIT_INT, 'Invalid Manual Adjustment value')
    .label('Manual Adjustment')
    .nullable(),
  description: yup.string().label('Description').max(50).nullable(),
});

export const validationSchema = yup.object({
  managers: yup.array().of(managerSchema),
});

export const getPayload = (values: Values): UpdateAccountingAdjustmentsInput[] => {
  return values.managers.map(({ managerCmgEntityKey, manualAdjustment, description }) => ({
    managerCmgEntityKey,
    manualAdjustment,
    description,
  }));
};

export const getManagerData = (
  managers: readonly FinalSettlement_AccountingManagerPartsFragment[],
  managerId: UUID
) => {
  return managers.find(({ managerCmgEntityKey }) => managerCmgEntityKey === managerId)!;
};
