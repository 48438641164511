import { getOperationName } from '@apollo/client/utilities';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SnackbarManager,
} from '@cmg/design-system';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../graphql';
import { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalDraftSet } from '../../../../types';
import { Props as ShareDialogProps } from '../share-allocation-set-dialog/ShareAllocationSetDialog';
import { useOrderBook_CreateFinalAllocationSetMutation } from './graphql/__generated__';

export type Props = Pick<ShareDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    promotedDraftAllocationSet:
      | InstitutionalDraftSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const PromoteToFinalSetDialog: React.FC<Props> = ({
  promotedDraftAllocationSet,
  offeringId,
  isOpen,
  onClose,
}) => {
  const [createFinalSet, { loading: isPromoting }] = useOrderBook_CreateFinalAllocationSetMutation({
    refetchQueries: [getOperationName(OrderBook_OrderBookRouteDocument)!],
  });

  const handlePromote = async () => {
    try {
      await createFinalSet({
        variables: {
          offeringId,
          input: { allocations: null, draftAllocationsId: promotedDraftAllocationSet!.id! },
        },
      });
      onClose();
      SnackbarManager.success('Draft Allocation Set Successfully Promoted to Final Set');
    } catch {
      SnackbarManager.error('Failed to Promote Draft Set to Final');
    }
  };

  if (!promotedDraftAllocationSet) {
    return null;
  }

  return (
    <Dialog
      title="Promote Draft Allocation Set to Final dialog"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Promote {promotedDraftAllocationSet.name} To Final Allocation</DialogTitle>

      <DialogContent dividers>
        You are about to promote the {promotedDraftAllocationSet.name} to final. You can continue
        modifying allocation after promotion. Do you wish to proceed?
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isPromoting}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={handlePromote} loading={isPromoting}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromoteToFinalSetDialog;
