import { ApolloError } from '@apollo/client';
import {
  FormField,
  MultiSelect,
  Option,
  Select,
  TextInputField,
  UnsavedChangesGuard,
  UUID,
} from '@cmg/common';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useFeatureToggles } from '../../../common/config';
import SetupForm from '../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../components/form/OfferingSetupFormActions';
import {
  OfferingSetup_CurrencyPartsFragment,
  OfferingSetup_InstrumentPartsFragment,
  OfferingSetup_TranchePartsFragment,
} from './graphql';
import { handleAddOrDeleteOnChange } from './TrancheForm.model';
import {
  StyledColumn,
  StyledDefaultInstrumentColumn,
  StyledInstrumentsColumn,
} from './TrancheForm.styles';

export const TrancheSchema = yup.object().shape({
  name: yup.string().nullable().required('Tranche Name is required'),
});

type FormType = {
  name: string;
  region: string | null;
};

export type Props = {
  editing?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  error?: ApolloError;
  tranche?: OfferingSetup_TranchePartsFragment;
  allCurrencies?: readonly OfferingSetup_CurrencyPartsFragment[];
  allInstruments?: readonly OfferingSetup_InstrumentPartsFragment[];
  onEdit?: () => void;
  onCancel?: () => void;
  onSubmit: (payload: FormType) => void;
  onAddCurrency: (currencyCode: string) => void;
  onDeleteCurrency: (currencyCode: string) => void;
  onAddInstrument: (instrumentId: string) => void;
  onDeleteInstrument: (instrumentId: string) => void;
  onDefaultInstrumentChange: (instrumentId: string) => void;
};

const emptyArray = [];

/**
 * Form for a Tranche
 *
 * Displays name & region within a Formik form.
 * All other Select inputs work independently.
 *
 * Note: If an instrument or currency is added/deleted from a Select input,
 * an appropriate callback is triggered: onAddInstrument, onDeleteInstrument, etc.
 * These are wired directly to their mutation.
 */
export const TrancheFormComponent: React.FC<Props> = ({
  editing,
  disabled,
  submitting,
  tranche,
  allCurrencies,
  allInstruments,
  onEdit,
  onCancel,
  onAddCurrency,
  onDeleteCurrency,
  onAddInstrument,
  onDeleteInstrument,
  onDefaultInstrumentChange,
  onSubmit,
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: TrancheSchema,
    initialValues: {
      name: tranche?.name ?? '',
      region: tranche?.region ?? '',
    },
    onSubmit: values => {
      onSubmit({
        name: values.name,
        region: values.region ?? null,
      });
    },
  };

  const formik = useFormik(formikOptions);

  const { isEnableNonUsOnPlatformOfferingsPhase1On } = useFeatureToggles();

  const { handleSubmit, resetForm } = formik;

  const handleCancel = () => {
    resetForm();
    onCancel && onCancel();
  };

  const trancheCurrencyCodes = tranche?.currencies.map<string>(d => d.currencyCode) ?? emptyArray;
  const trancheInstruments = tranche?.deliveryInstruments ?? emptyArray;
  const trancheInstrumentIds = tranche?.deliveryInstruments.map<string>(d => d.id) ?? emptyArray;

  const currencyOptions = React.useMemo(
    () =>
      allCurrencies?.map<Option>(({ currencyCode }) => ({
        label: currencyCode,
        value: currencyCode,
      })),
    [allCurrencies]
  );

  const instrumentOptions = React.useMemo(
    () =>
      allInstruments?.map<Option<UUID>>(d => ({
        label: [d.countryCode, d.currencyCode, d.stockSymbol].filter(d => !!d).join(' - '),
        value: d.id,
      })),
    [allInstruments]
  );

  const trancheInstrumentOptions = React.useMemo(
    () =>
      trancheInstruments?.map<Option<UUID>>(d => ({
        label: [d.countryCode, d.currencyCode, d.stockSymbol].filter(d => !!d).join(' - '),
        value: d.id,
      })),
    [trancheInstruments]
  );

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={!!editing} onLeave={handleCancel}>
        <SetupForm.Card withBorder>
          <SetupForm.Row>
            <StyledColumn>
              <TextInputField label="Name" name="name" disabled={!editing} fullWidth required />
            </StyledColumn>
            <StyledColumn>
              <TextInputField label="Region" name="region" disabled={!editing} fullWidth />
            </StyledColumn>
            <StyledColumn>
              <OfferingSetupFormActions
                editing={editing}
                disabled={disabled}
                loading={submitting}
                onEdit={onEdit}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
              />
            </StyledColumn>
          </SetupForm.Row>

          <SetupForm.Row>
            <StyledColumn>
              <FormField label="Demand Currencies" fullWidth>
                <MultiSelect
                  onChange={values =>
                    handleAddOrDeleteOnChange(trancheCurrencyCodes, values, {
                      onAdd: onAddCurrency,
                      onDelete: onDeleteCurrency,
                    })
                  }
                  options={currencyOptions}
                  value={tranche?.currencies.map(d => d.currencyCode)}
                  maxDisplayedValues={3}
                  disabled // disable this since M1 has only one currency
                />
              </FormField>
            </StyledColumn>
            <StyledInstrumentsColumn>
              <FormField label="Instruments" fullWidth>
                <MultiSelect
                  onChange={values =>
                    handleAddOrDeleteOnChange(trancheInstrumentIds, values, {
                      onAdd: onAddInstrument,
                      onDelete: onDeleteInstrument,
                    })
                  }
                  options={instrumentOptions}
                  value={tranche?.deliveryInstruments.map(d => d.id)}
                  maxDisplayedValues={2}
                  disabled={disabled || editing || isEnableNonUsOnPlatformOfferingsPhase1On}
                />
              </FormField>
            </StyledInstrumentsColumn>
            <StyledDefaultInstrumentColumn>
              <FormField label="Default Pricing Instrument For Tranche" fullWidth>
                <Select<UUID>
                  onChange={value => value && onDefaultInstrumentChange(value)}
                  options={trancheInstrumentOptions}
                  value={tranche?.defaultInstrumentId}
                  disabled={disabled || editing || isEnableNonUsOnPlatformOfferingsPhase1On}
                  isClearable={false}
                />
              </FormField>
            </StyledDefaultInstrumentColumn>
          </SetupForm.Row>
        </SetupForm.Card>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default TrancheFormComponent;
