import { getOperationName } from '@apollo/client/utilities';
import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { OrderBookState } from '../../../../graphql';
import { OfferingNavbar_OrderBookSubNavItem_OrderBookDocument } from '../../../offering/components/navbar/graphql';
import { useUpdateOrderBookStateMutation } from '../../graphql';
import { InstitutionalDemandGrid_SummaryDocument } from '../../institutional-demand/components/institutional-demand-grid/graphql';
import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';

export type Props = Readonly<{
  offeringId: UUID;
  orderBookState: OrderBookState | undefined;
}>;

export const refetchQueries = () => {
  // After the Order Book is closed, we need to refetch the Institutional Demand query
  // to re-fetch final order book data and re-render the grid.
  const refetchInstitutionalDemandSummaryGridQuery = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  );
  // After closing order book, we want to refetch the Order book sub nav
  const refetchOrderSubNav = getOperationName(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument);

  return [refetchInstitutionalDemandSummaryGridQuery!, refetchOrderSubNav!];
};

export const useUpdateOrderBookStatusDialogState = ({ offeringId, orderBookState }: Props) => {
  const dialogState = useDialogState();

  const [updateOrderBookState, { error, loading }] = useUpdateOrderBookStateMutation();

  const onSave = useCallback(async () => {
    const nextState =
      orderBookState === OrderBookState.Closed ? OrderBookState.Open : OrderBookState.Closed;

    await updateOrderBookState({
      variables: {
        offeringId,
        state: nextState,
      },
      awaitRefetchQueries: true,
      refetchQueries,
    });

    if (nextState === OrderBookState.Open) {
      SnackbarManager.success(`Successfully reopened order book.`);
    }

    if (nextState === OrderBookState.Closed) {
      SnackbarManager.success(`Successfully closed order book.`);
    }
  }, [offeringId, orderBookState, updateOrderBookState]);

  return { ...dialogState, onSave, error, loading };
};
