import { ApolloError } from '@apollo/client';
import React from 'react';

import { useBulkResendProspectus } from '../../../../../../features/offering-side-panel/order-book/compliance/prospectus/hooks/useBulkResendProspectus';
import { InstitutionalDemandGrid_SummaryQuery } from '../graphql';
import { getGridSummaryDataFromQuery } from '../InstitutionalDemandGrid.model';
import { useAttestationStatusesLazyQuery } from './useAttestationStatusesQuery';
import { useInvestorFirmsEmployeeRelationshipsLazyQuery } from './useInvestorFirmsEmployeeRelationshipsQuery';
import { useInvestorsExtendedDataQuery } from './useInvestorsExtendedDataQuery';

type Args = {
  institutionalDemandGridSummaryData: InstitutionalDemandGrid_SummaryQuery | undefined;
  offeringId: string;
  bankInvestorKeys: string[];
  cmgEntityKeys: string[];
};

export const useInstitutionalDemandGridSecondaryQueries = ({
  offeringId,
  institutionalDemandGridSummaryData,
  bankInvestorKeys,
  cmgEntityKeys,
}: Args) => {
  const [
    getAttestationStatuses,
    {
      data: attestationStatusesData,
      error: attestationStatusesError,
      stopPolling: stopAttestationStatusPolling,
      loading: attestationStatusesLoading,
    },
  ] = useAttestationStatusesLazyQuery({ cmgEntityKeys });

  const {
    data: investorsExtendedQuery,
    loading: investorsExtendedDataLoading,
    error: investorsExtendedDataError,
  } = useInvestorsExtendedDataQuery({
    firmKeys: getGridSummaryDataFromQuery(institutionalDemandGridSummaryData)
      .indications.map(i => i.investorInformation.bankInvestorKey)
      .filter(f => f !== null)
      .map(f => f!),
  });
  const investorsExtendedData = investorsExtendedQuery?.firmInvestorsExtendedData ?? [];

  const {
    isProspectusDocumentUploaded,
    resendProspectus,
    prospectusOnSendValidation,
    error: bulkResendProspectusError,
    loading: bulkResendProspectusLoading,
  } = useBulkResendProspectus({
    offeringId,
  });

  React.useEffect(() => {
    if (attestationStatusesError) {
      stopAttestationStatusPolling();
    }
  }, [attestationStatusesError, stopAttestationStatusPolling]);

  const [
    getInvestorFirmsEmployeeRelationships,
    {
      data: investorFirmsEmployeeRelationshipsData,
      error: investorFirmsEmployeeRelationshipsError,
      stopPolling: stopInvestorFirmsEmployeeRelationshipsPolling,
      loading: investorFirmsEmployeeRelationshipsLoading,
    },
  ] = useInvestorFirmsEmployeeRelationshipsLazyQuery({ bankInvestorKeys });

  React.useEffect(() => {
    if (investorFirmsEmployeeRelationshipsError) {
      stopInvestorFirmsEmployeeRelationshipsPolling();
    }
  }, [investorFirmsEmployeeRelationshipsError, stopInvestorFirmsEmployeeRelationshipsPolling]);

  const { errors, loading } = React.useMemo(() => {
    return {
      errors: {
        useAttestationStatusesLazyQuery: [attestationStatusesError].filter(
          (error): error is ApolloError => !!error
        ),
        useInvestorsExtendedDataQuery: [investorsExtendedDataError].filter(
          (error): error is ApolloError => !!error
        ),
        useBulkResendProspectus: [bulkResendProspectusError].filter(
          (error): error is ApolloError => !!error
        ),
        useInvestorFirmsEmployeeRelationshipsLazyQuery: [
          investorFirmsEmployeeRelationshipsError,
        ].filter((error): error is ApolloError => !!error),
      },

      loading:
        investorsExtendedDataLoading ||
        bulkResendProspectusLoading ||
        attestationStatusesLoading ||
        investorFirmsEmployeeRelationshipsLoading,
    };
  }, [
    attestationStatusesError,
    investorsExtendedDataError,
    bulkResendProspectusError,
    investorFirmsEmployeeRelationshipsError,
    investorFirmsEmployeeRelationshipsLoading,
    investorsExtendedDataLoading,
    bulkResendProspectusLoading,
    attestationStatusesLoading,
  ]);

  return {
    getAttestationStatuses,
    attestationStatusesData,
    attestationStatusesLoading,
    investorsExtendedData,
    investorsExtendedDataLoading,
    bulkResendProspectusLoading,
    prospectusOnSendValidation,
    isProspectusDocumentUploaded,
    getInvestorFirmsEmployeeRelationships,
    investorFirmsEmployeeRelationshipsData,
    investorFirmsEmployeeRelationshipsLoading,
    loading,
    resendProspectus,
    errors,
  };
};
