import { Option } from '@cmg/common';

import { Sector } from '../graphql';

export const sectorLabels = {
  [Sector.BasicMaterials]: 'Basic Materials',
  [Sector.Consumer]: 'Consumer',
  [Sector.Energy]: 'Energy',
  [Sector.FinancialServices]: 'Financial Services',
  [Sector.Healthcare]: 'Healthcare',
  [Sector.Industrials]: 'Industrials',
  [Sector.Other]: 'Other',
  [Sector.RealEstate]: 'Real Estate',
  [Sector.Tmt]: 'TMT',
  [Sector.Utilities]: 'Utilities',
};

export enum exchangeEnum {
  XNAS = 'NASDAQ',
  XNYS = 'NYSE',
}

export const sectorOptions = Object.values(Sector).map<Option<Sector>>(sector => ({
  label: sectorLabels[sector],
  value: sector as Sector,
}));

export const DEBOUNCE_TIMEOUT = 500;

/**
 * Comomon options for non-editable grids
 */
export const gridFooterMinHeight = 100;

const nonEditableGridOptionsBase = {
  // rowBuffer is the amount of additional rows rendered outside what is currently in view. Since it is rather expensive to render cellRendererFramework
  // cells, limiting this helps keep the overall cell render times low.
  rowBuffer: 5,
};

export const nonEditableGridOptions = {
  ...nonEditableGridOptionsBase,
  rowHeight: 32,
};

export const nonEditableOBGridOptions = {
  ...nonEditableGridOptionsBase,
  rowHeight: 36,
};

export const supportedCurrencyCodes = [
  'ARS',
  'AUD',
  'EUR',
  'BHD',
  'BDT',
  'BRL',
  'CAD',
  'CLP',
  'CNY',
  'COP',
  'HRK',
  'CZK',
  'DKK',
  'EGP',
  'HKD',
  'HUF',
  'ISK',
  'INR',
  'IDR',
  'ILS',
  'JPY',
  'JOD',
  'KES',
  'KWD',
  'MYR',
  'MXN',
  'MAD',
  'NZD',
  'NGN',
  'NOK',
  'OMR',
  'PKR',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'SAR',
  'XOF',
  'SGD',
  'ZAR',
  'KRW',
  'LKR',
  'SEK',
  'CHF',
  'SYP',
  'TWD',
  'THB',
  'TRY',
  'AED',
  'GBP',
  'USD',
  'VND',
  'GBX',
];
