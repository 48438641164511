import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { InterestLevelInput } from '../../../../../../../../../graphql';
import { BuySideIndicationDocument } from '../../../../../context/hooks/buy-side/__generated__';
import { IndicationActivity_BuySideRouteDocument } from '../../../../../graphql/__generated__';
import { IndicationFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useCreateIndication_AssignMyIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(IndicationActivity_BuySideRouteDocument)!,
  getOperationName(BuySideIndicationDocument)!,
];

export const useCreateMyIndication = (offeringId: UUID) => {
  const [createMyInstitutionalIndication] = useCreateIndication_AssignMyIndicationMutation();

  const handleCreateMyInstitutionalIndication = React.useCallback(
    async (values: IndicationFormValues) => {
      try {
        await createMyInstitutionalIndication({
          variables: {
            offeringId,
            input: {
              trancheId: values.trancheId!,
              currencyCode: values.currencyCode,
              instrumentId: values.instrumentId,
              interestLevels: values.interestLevels.map<InterestLevelInput>(
                ({ interestQuantity, interestUnit, limitPrice, limitType }) => ({
                  interestUnit: interestUnit!,
                  interestQuantity: interestQuantity!,
                  limitType,
                  limitPrice,
                })
              ),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries,
        });

        SnackbarManager.success('Successfully created Indication');
      } catch {
        SnackbarManager.error('Failed to create Indication');
      }
    },
    [createMyInstitutionalIndication, offeringId]
  );

  return [handleCreateMyInstitutionalIndication];
};
