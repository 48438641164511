import { useAuth } from '@cmg/auth';
import { useListCompare } from '@cmg/common';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import {
  InstitutionalDemandGridUpdate,
  UpdateType,
} from './useComputeDiffOnInstitutionalDemandGrid';
import { institituionalIndicationDemandsArg } from './useComputeDiffOnInstitutionalIndicationDemands';

export const enum AcknowledgmentsPendingUpdateLabels {
  ACKNOWLEDGMENT_UPDATE = 'Indication Acknowledged',
}

export type Acknowledgment = {
  id: string;
  offeringId: string;
  indicationId: string;
  acknowledgedIndicationVersion: string;
  indicationVersion: string;
  managerCmgEntityKey: string;
  version: string;
  currentIndicationVersion: string;
  auditInfo: InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number]['institutionalIndicationAcknowledgements'][number]['auditInfo'];
};

export const useComputeDiffOnInstitutionalAcknowledgments = function (
  currAcknowledgments: institituionalIndicationDemandsArg,
  nextAcknowledgments: institituionalIndicationDemandsArg
) {
  const { oidcUserCmgEntityKey: userCmgEntityKey } = useAuth();

  const currentAcknowledgements: Acknowledgment[] = React.useMemo(
    () =>
      (currAcknowledgments ?? [])
        .flatMap(a =>
          a.institutionalIndicationAcknowledgements.map(i => {
            return { ...i, currentIndicationVersion: a.version };
          })
        )
        .filter(a => a.managerCmgEntityKey !== userCmgEntityKey)
        .map(a => {
          return {
            ...a,
            id: `${a.indicationId}-${a.managerCmgEntityKey}`,
          };
        }),
    [currAcknowledgments, userCmgEntityKey]
  );

  const nextAcknowledgements: Acknowledgment[] = React.useMemo(
    () =>
      (nextAcknowledgments ?? [])
        .flatMap(a =>
          a.institutionalIndicationAcknowledgements.map(i => {
            return { ...i, currentIndicationVersion: a.version };
          })
        )
        .filter(a => a.managerCmgEntityKey !== userCmgEntityKey)
        .map(a => {
          return {
            ...a,
            id: `${a.indicationId}-${a.managerCmgEntityKey}`,
          };
        }),
    [nextAcknowledgments, userCmgEntityKey]
  );

  const { addedItems, mutatedItems } = useListCompare(
    currentAcknowledgements,
    nextAcknowledgements,
    {
      key: item => item.id,
      equals: (a, b) => a.version === b.version,
    }
  );

  return React.useMemo(() => {
    const acknowledgmentsChanges: InstitutionalDemandGridUpdate[] = [];
    if (addedItems.length > 0) {
      addedItems.forEach(ack => {
        // New Acknowledge Ocurred
        acknowledgmentsChanges.push({
          type: UpdateType.ACKNOWLEDGMENTS_UPDATE,
          pendingUpdateLabel: [`${AcknowledgmentsPendingUpdateLabels.ACKNOWLEDGMENT_UPDATE}`],
          indicationId: ack.indicationId,
          timeStamp: new Date(ack.auditInfo.createdAt!),
          id: uuidV4(),
        });
      });
    }
    if (mutatedItems.length > 0) {
      mutatedItems.forEach(ack => {
        if (
          // If the previous version is unacknowledge
          ack[0]?.currentIndicationVersion !== ack[0]?.acknowledgedIndicationVersion &&
          // and the new version is acknowledge
          ack[1]?.currentIndicationVersion === ack[1]?.acknowledgedIndicationVersion
        ) {
          acknowledgmentsChanges.push({
            type: UpdateType.ACKNOWLEDGMENTS_UPDATE,
            pendingUpdateLabel: [`${AcknowledgmentsPendingUpdateLabels.ACKNOWLEDGMENT_UPDATE}`],
            indicationId: ack[1].indicationId,
            timeStamp: new Date(ack[1].auditInfo.createdAt!),
            id: uuidV4(),
          });
        }
      });
    }
    return acknowledgmentsChanges;
  }, [addedItems, mutatedItems]);
};
