import { FormikTextField } from '@cmg/design-system-formik';
import React from 'react';

import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';

export const FreeFormWireForm: React.VFC = () => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <FormikTextField name="header" label="Wire Title" required />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <FormikTextField name="body" label="Wire Text" required multiline rows={8} />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
