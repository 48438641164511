import { FieldArray } from 'formik';
import React from 'react';

import { RecipientUserData } from '../../../SyndicateWiresRoute.model';
import { OperationType } from '../modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { RecipientFormValues, useRecipientsUserDataForm } from './hooks/useRecipientsUserDataForm';

export type DefaultRecipientValues<TRecipientUserData extends RecipientUserData> = Omit<
  TRecipientUserData,
  'recipient'
>;

export type BaseRecipientsUserDataManager = Readonly<{
  readonly cmgEntityKey: string;
}>;

export type Props<
  TRecipientUserData extends RecipientUserData,
  TManager extends BaseRecipientsUserDataManager
> = Readonly<{
  readonly allManagers: TManager[];
  readonly managersToRender: TManager[];
  readonly operationType: OperationType;
  readonly defaultRecipientValues: DefaultRecipientValues<TRecipientUserData>;
  readonly renderRecipientRow: (args: {
    manager: TManager;
    managerIndex: number;
    onChange: (nextValues: Partial<TRecipientUserData>) => void;
  }) => React.ReactElement;
}>;

export const RecipientsUserDataFormFields = <
  TRecipientUserData extends RecipientUserData,
  TValues extends RecipientFormValues<TRecipientUserData>,
  TManager extends BaseRecipientsUserDataManager
>({
  allManagers,
  managersToRender,
  operationType,
  defaultRecipientValues,
  renderRecipientRow,
}: Props<TRecipientUserData, TManager>) => {
  const { handleManagerValuesChange } = useRecipientsUserDataForm<
    TRecipientUserData,
    TValues,
    TManager
  >({
    defaultRecipientValues,
    managers: allManagers,
    operationType,
  });

  return (
    <FieldArray name="recipientsUserData">
      {helpers =>
        managersToRender.map(manager => {
          const managerIndex = allManagers.findIndex(
            ({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey
          );

          const onChange = (nextValues: Partial<TRecipientUserData>) => {
            handleManagerValuesChange(nextValues, managerIndex, helpers);
          };

          return renderRecipientRow({
            manager,
            managerIndex,
            onChange,
          });
        })
      }
    </FieldArray>
  );
};
