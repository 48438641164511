import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSupersededOfferingContext } from './contexts/SupersededOfferingContext';
import BuySideOfferingDetailsRoute from './offering-details/BuySideOfferingDetailsRoute';
import OfferingNotesRoute from './offering-notes/OfferingNotesRoute';
import offeringSidePanelBuySideRouteFactory from './offeringSidePanelBuySideRouteFactory';
import OrderAllocationRoute from './order-allocation/OrderAllocationRoute';
import OrderBookBuySideRoute from './order-book/OrderBookBuySideRoute';

const OfferingSidePanelBuySideRoutes: React.FC = () => {
  const { isObsoleteOffering, hasNotes, hasOrders } = useSupersededOfferingContext();

  return (
    <Switch>
      <Route
        path={offeringSidePanelBuySideRouteFactory.root.routePath}
        exact
        component={() => null}
      />
      {(!isObsoleteOffering || hasNotes) && (
        <Route
          path={offeringSidePanelBuySideRouteFactory.offeringNotes.routePath}
          component={OfferingNotesRoute}
        />
      )}
      {(!isObsoleteOffering || hasOrders) && (
        <Route
          path={offeringSidePanelBuySideRouteFactory.orderBook.routePath}
          component={OrderBookBuySideRoute}
        />
      )}
      <Route
        path={offeringSidePanelBuySideRouteFactory.offeringDetails.routePath}
        component={BuySideOfferingDetailsRoute}
      />
      <Route
        path={offeringSidePanelBuySideRouteFactory.orderAllocation.routePath}
        component={OrderAllocationRoute}
      />
    </Switch>
  );
};

export default OfferingSidePanelBuySideRoutes;
