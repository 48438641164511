import {
  useOfferingSetup_HeaderQuery,
  useOfferingSetup_PublishedHeaderQuery,
} from '../../../../graphql';

export function useOfferingSetupHeaderQuery({
  offeringId,
  skip,
}: {
  offeringId?: string;
  skip: boolean;
}) {
  const {
    data: headerData,
    loading: headerLoading,
    error,
  } = useOfferingSetup_HeaderQuery({
    variables: { offeringId: offeringId! },
    skip,
    fetchPolicy: 'cache-and-network',
  });
  const { data: publishedHeaderData, loading: publishedHeaderLoading } =
    useOfferingSetup_PublishedHeaderQuery({
      variables: { offeringId: offeringId! },
      skip: skip || headerData?.offering.stage !== 'PUBLISHED',
      fetchPolicy: 'cache-and-network',
    });
  return {
    headerData,
    publishedHeaderData,
    loading: headerLoading || publishedHeaderLoading,
    error,
  };
}
