import { numericUtil } from '@cmg/common';
import React from 'react';

import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { SWrapper } from './CreditsReleasedPopoverContent.styles';

export type Props = {
  rowData: SalesCredits_SalesCreditItemPartsFragment;
};

const CreditsReleasedPopoverContent: React.FC<Props> = ({ rowData }) => {
  const { investorFirmName, salesCredits, salesCreditsManual, salesCreditsReleased } = rowData;

  const outOfBalanceAmount = React.useMemo(() => {
    return numericUtil.getDisplayValueForCurrency(
      Math.abs(
        numericUtil.sum(
          salesCreditsManual ?? salesCredits,
          numericUtil.negate(salesCreditsReleased)
        )!
      )
    );
  }, [salesCreditsManual, salesCredits, salesCreditsReleased]);

  return (
    <SWrapper>
      {investorFirmName}–Sales Credits Released is out of balance by {outOfBalanceAmount}
    </SWrapper>
  );
};

export default CreditsReleasedPopoverContent;
