import { Alert } from '@cmg/common';

import { SDetails, STitle } from './DealTeamMemberBanner.styles';

const DealTeamMemberBanner: React.FC = () => {
  return (
    <Alert severity="warning" withMargin>
      <STitle>
        Some Deal Team Members can no longer access Roadshows as their account on XC platform was
        modified.
      </STitle>
      <SDetails>
        You can remove deactivated members, convert members to custom record or contact your company
        account administrator.
      </SDetails>
    </Alert>
  );
};

export default DealTeamMemberBanner;
