import { FlexContainer, Popover, Tag } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { DesignationRow } from '../../DesignationMonitorGrid.model';
import { useGetEditedInvestorsNames } from './LinkRendererWithEditedValue.model';
import { SCellWrapper, SText, StyledLink } from './LinkRendererWithEditedValue.styles';

export type Props = { value: string; to: string; replace: boolean; data: DesignationRow };

const LinkRendererWithEditedValue: React.FC<Props> = ({ value, to, replace, data }) => {
  const theme = useTheme();

  const revisedInvestors = useGetEditedInvestorsNames(data.investments);

  return (
    <SCellWrapper>
      <StyledLink to={to} replace={replace}>
        {value}
      </StyledLink>

      <Popover
        content={
          <FlexContainer direction="column" gap={8}>
            <div>Revised</div>
            <div>{revisedInvestors.join(', ')}</div>
          </FlexContainer>
        }
        variant="DARK"
        placement="topRight"
        arrowPointAtCenter
      >
        <Tag color={theme.designSystem.colors.darkBlue[200]} testId="designation-monitor-changed">
          <SText>R</SText>
        </Tag>
      </Popover>
    </SCellWrapper>
  );
};

export default LinkRendererWithEditedValue;
