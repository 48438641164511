import { LinkButton, Popover } from '@cmg/common';
import React from 'react';

import { DisabledManagerReason } from '../modals/send-wire-modal/SendWireModal.model';
import { SContent } from './DisabledRecipientPopover.styles';

type Props = {
  reason: DisabledManagerReason;
  onContactSupport: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const disabledReasonLabels: { [key in DisabledManagerReason]: string } = {
  [DisabledManagerReason.MISSING_EMAIL]: 'Email distribution lists not set.',
  [DisabledManagerReason.MANAGER_REMOVED]: 'Recipient removed.',
};

export const DisabledRecipientPopover: React.FC<Props> = ({
  reason,
  onContactSupport,
  children,
}) => {
  return (
    <Popover
      variant="DARK"
      trigger="hover"
      content={() => {
        const reasonLabel = disabledReasonLabels[reason];

        if (reason === DisabledManagerReason.MISSING_EMAIL) {
          return (
            <SContent>
              {reasonLabel}
              <LinkButton inline onClick={onContactSupport}>
                Contact Support
              </LinkButton>
            </SContent>
          );
        }

        return <SContent>{reasonLabel}</SContent>;
      }}
    >
      {children}
    </Popover>
  );
};
