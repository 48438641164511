import { LinkButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { SSectionHeader, SSectionHeaderWrapper } from './InterestLevelsWrapperHeader.styles';

type FormSectionHeaderProps = {
  handleAddClick: () => void;
};

const InterestLevelsWrapperHeader: React.FC<FormSectionHeaderProps> = props => {
  return (
    <SSectionHeaderWrapper>
      <SSectionHeader>Interest Levels</SSectionHeader>
      <LinkButton
        iconLeft={{ name: 'plus', size: 'sm' }}
        inline
        onClick={props.handleAddClick}
        testId={xcSelectors.addCommunicatedDemandInterestLevel.testId}
      >
        Add Interest Level
      </LinkButton>
    </SSectionHeaderWrapper>
  );
};

export default InterestLevelsWrapperHeader;
