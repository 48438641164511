import {
  InvestorContactInformation_ContactsQueryVariables,
  useInvestorContactInformation_ContactsQuery,
  useInvestorContactInformation_CoveredContactsQuery,
} from '../graphql/__generated__';

export const useContactsQuery = (
  variables: InvestorContactInformation_ContactsQueryVariables,
  isCoveredAccount: boolean
) => {
  const {
    data: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useInvestorContactInformation_ContactsQuery({
    variables,
    skip: isCoveredAccount,
  });
  const {
    data: coveredContactsData,
    loading: coveredContactsLoading,
    error: coveredContactsError,
  } = useInvestorContactInformation_CoveredContactsQuery({
    variables,
    skip: !isCoveredAccount,
  });

  return isCoveredAccount
    ? {
        contacts: coveredContactsData?.coveredContacts,
        loading: coveredContactsLoading,
        error: coveredContactsError,
      }
    : {
        contacts: contactsData?.contacts,
        loading: contactsLoading,
        error: contactsError,
      };
};
