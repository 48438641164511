import { Popover, Tag } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import Status from '../../../../common/components/indicators/status/Status';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import { OfferingLink } from '../../components/OfferingLink';
import { MyPublishedOfferingsQuery } from '../hooks/useMyPublishedOfferingsQuery';

export const StyledRevisionTag = styled(Tag)`
  background-color: ${({ theme }) => theme.background.color.menu};
  margin-left: 10px;
`;

export const SIssuerNameCell = styled(FlexLayout)`
  align-items: center;
`;

export type Props = {
  value: string | null | undefined;
  data: MyPublishedOfferingsQuery['myPublishedOfferings']['data'][number];
};

/**
 * IssuerNameCellRenderer renders the issuer name cell in the My Offerings - offerings grid
 *
 * status indicator will be rendered if the user is an author on the offering
 * the status will be yellow if there are unsaved changes on the offering and green otherwise
 */
const IssuerNameCellRenderer: React.FC<Props> = ({
  value,
  data: { isAuthor, hasUnpublishedChanges, id: offeringId, revisedFilingsCount },
}) => {
  if (!value) {
    return <div>-</div>;
  }

  let hoverContent: string | null = null;

  if (isAuthor) {
    hoverContent = hasUnpublishedChanges
      ? 'Published with unsaved changes'
      : 'Published by My Firm';
  }

  return (
    <SIssuerNameCell direction="row">
      <Popover content={hoverContent} variant="DARK" disabled={!hoverContent}>
        <FlexLayout direction="row">
          <Status
            options={theme => ({
              true: {
                label: '',
                color: hasUnpublishedChanges ? theme.color.yellow1 : theme.color.green3,
              },
            })}
            status={isAuthor?.toString() ?? 'false'}
          />
          <OfferingLink offeringId={offeringId} isAuthor={isAuthor}>
            {value}
          </OfferingLink>
        </FlexLayout>
      </Popover>
      {!!revisedFilingsCount && revisedFilingsCount > 0 && (
        <Popover content="Revised" variant="DARK">
          <StyledRevisionTag>R</StyledRevisionTag>
        </Popover>
      )}
    </SIssuerNameCell>
  );
};

export default IssuerNameCellRenderer;
