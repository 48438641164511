import React from 'react';
import styled from 'styled-components/macro';

export const SInfoText = styled.div`
  max-width: 440px;
  text-align: justify;
`;

const SymbolOfCoveredSecuritiesInfo: React.FC = () => {
  return (
    <SInfoText>
      Auto-populates the issuer symbol from above with the ability to add additional reference
      securities. Only securities you intend to restrict should be included in the notifications
      submitted to FINRA.
    </SInfoText>
  );
};

export default SymbolOfCoveredSecuritiesInfo;
