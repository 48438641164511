import { FlexContainer, Popover, Tag } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import routeFactory from '../../../../../common/util/routeFactory';
import { StyledLink } from '../../../../trade-release/layout/grid/grid-columns/investor-name/InvestorNameRenderer.styles';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { SalesCreditsGridContext } from '../SalesCreditsGrid.model';

export type Props = {
  data: SalesCredits_SalesCreditItemPartsFragment;
  context: SalesCreditsGridContext;
};

const InvestorNameRenderer: React.FC<Props> = ({ data, context }) => {
  const theme = useTheme();
  const isRevised =
    data.salesCreditsManual !== null && data.salesCreditsManual !== data.salesCredits;

  return (
    <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
      <StyledLink
        to={routeFactory.salesCreditsHistory.getUrlPath({
          offeringId: context.offeringId,
          investorFirmCmgEntityKey: data.investorFirmCmgEntityKey,
        })}
      >
        {data.investorFirmName}
      </StyledLink>
      {isRevised && (
        <Popover
          content={
            <div>
              Revised:
              <br />
              Sales Credits
            </div>
          }
          variant="DARK"
        >
          <Tag color={theme.designSystem.colors.darkBlue[200]}>R</Tag>
        </Popover>
      )}
    </FlexContainer>
  );
};

export default InvestorNameRenderer;
