import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_UpdateFollowOnPricingDocument,
  OfferingSetup_UpdateFollowOnPricingMutation,
  OfferingSetup_UpdateFollowOnPricingMutationVariables,
} from '../graphql';

export const refetchQueries = () => {
  const offeringHeaderQuery = getOperationName(Offering_OfferingHeaderDocument);
  return [offeringHeaderQuery!];
};

export const useUpdateFollowOnPricingMutation = () =>
  useServiceMutation<
    OfferingSetup_UpdateFollowOnPricingMutation,
    OfferingSetup_UpdateFollowOnPricingMutationVariables
  >(OfferingSetup_UpdateFollowOnPricingDocument, { refetchQueries });
