import { formatters } from '@cmg/data-grid';

import { AttestationFormStatus } from '../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { AttestationStatusCellRenderer } from './AttestationStatusCellRenderer';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['attestationStatus'];

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: { component: AttestationStatusCellRenderer },
});

/**
 * Exported for testing purposes
 */
export const valueFormatter = getValueFormatter<Value>({
  gridRow: formatters.enumValueFormatter,
});

const attestationColDef: DemandGridServerColDef<Value> = {
  field: 'attestationStatus',
  headerName: '5130/31 Status',
  enableRowGroup: true,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  valueFormatter,
  filterParams: {
    values: Object.values(AttestationFormStatus),
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default attestationColDef;
