import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_SendUnderwriterInvitationWireMutation,
  SyndicateWires_UnderwriterInvitationWiresDocument,
  useSyndicateWires_SendUnderwriterInvitationWireMutation,
} from '../graphql';

export type SendUnderwriterInvitationWireMutation =
  SyndicateWires_SendUnderwriterInvitationWireMutation;

export const refetchQueries = () => {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_UnderwriterInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
};

export const useSendUnderwriterInvitationWireMutation = () => {
  return useSyndicateWires_SendUnderwriterInvitationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
