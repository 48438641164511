import { Alert } from '@cmg/design-system';
import React from 'react';

import { useWireFormBanner } from './hooks/useWireFormBanner';
import { WireFormBannerVariant } from './WireFormBanner.model';

export type Props = {
  variant: WireFormBannerVariant;
  messageAppendix?: string;
};

export const WireFormBanner: React.FC<Props> = ({ variant, messageAppendix }) => {
  const { message } = useWireFormBanner({ variant, messageAppendix });

  return <Alert severity="info">{message}</Alert>;
};
