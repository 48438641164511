import { ConfirmModal } from '@cmg/common';
import React from 'react';

export type Props = {
  show?: boolean;
  onHide: () => void;
  onConfirm: () => void;
};

const ConfirmDeleteAllocationDataModal: React.FC<Props> = ({ show = false, onHide, onConfirm }) => (
  <ConfirmModal
    show={show}
    size="small"
    title="Confirm"
    confirmButtonCaption="Yes, delete the Allocation data"
    cancelButtonCaption="No, keep the Allocation data"
    onConfirm={onConfirm}
    onHide={onHide}
    closeButton
  >
    <p>Are you sure you want to delete Allocation data?</p>
  </ConfirmModal>
);

export default ConfirmDeleteAllocationDataModal;
