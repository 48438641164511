import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_HeaderDocument,
  OfferingSetup_Summary_UpdateOfferingStatusDocument,
  OfferingSetup_Summary_UpdateOfferingStatusMutation,
  OfferingSetup_Summary_UpdateOfferingStatusMutationVariables,
} from '../../graphql';

export const refetchQueries = () => {
  const refetchOfferingHeader = getOperationName(Offering_OfferingHeaderDocument);
  const refetchOfferingSetupHeader = getOperationName(OfferingSetup_HeaderDocument);
  return [refetchOfferingHeader!, refetchOfferingSetupHeader!];
};

/**
 * useUpdateOfferingStatusMutation updates the private offering status
 *
 * Note: for the status to be reflected on a published offering the user will have to publish again
 */
export const useUpdateOfferingStatusMutation = () => {
  return useServiceMutation<
    OfferingSetup_Summary_UpdateOfferingStatusMutation,
    OfferingSetup_Summary_UpdateOfferingStatusMutationVariables
  >(OfferingSetup_Summary_UpdateOfferingStatusDocument, { refetchQueries });
};
