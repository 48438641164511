import React from 'react';

import { WireMessageAndResponseStatus, WireStatus } from '../../../../../../../../graphql';
import { Wire } from '../../../../../SyndicateWiresRoute.model';
import { WireStatusType } from '../../../wire-status/WireStatus';

export function useWireTypeStatus(wire: Wire | null): WireStatusType {
  return React.useMemo(() => {
    if (!wire) {
      return 'empty';
    }

    const isDeclined = wire.wireMessages?.some(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.Declined
    );
    const isExpired = wire.wireMessages?.some(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.Expired
    );
    const isReadyToSend = wire.wireMessages?.some(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.ReadyToSend
    );
    const isDraft = wire.status === WireStatus.Draft;
    const isSent = wire.wireMessages?.every(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.Sent
    );
    const isAccepted = wire.wireMessages?.every(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.Accepted
    );
    const isObsolete = wire.wireMessages?.some(
      message => message.wireMessageAndResponseStatus === WireMessageAndResponseStatus.Obsolete
    );

    if (isDeclined) {
      return 'declined';
    }

    if (isExpired) {
      return 'expired';
    }

    if (isDraft) {
      return 'draft';
    }

    if (isObsolete) {
      return 'obsolete';
    }

    if (isReadyToSend) {
      return 'readyToSend';
    }

    if (isAccepted) {
      return 'accepted';
    }

    if (isSent) {
      return 'sent';
    }

    // This is a fallback state that should never be reached.
    // A logging message notifying us of the fact we somehow reached this state
    // should be added once we add a logging service.
    return 'empty';
  }, [wire]);
}
