import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../../../../common/components/layout/flex-layout/FlexLayout';

export const SColumn = styled.div`
  flex: 1;
  padding: 15px 10px;
`;

export const SHeaderColumn = styled(SColumn)`
  color: ${({ theme }) => theme.text.color.light};
  padding: 5px 10px;
`;

export const StyledFlexRow = styled(FlexLayout).attrs(() => ({ direction: 'row', expand: true }))`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
`;
