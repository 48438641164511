import { Option } from '@cmg/common';

import { RecordStatus } from '../../../../graphql';
import { OfferingSetup_DemandCurrenciesQuery } from '../graphql';

export const demandCurrencyToCurrencyOptions = (
  demandCurrencyData: OfferingSetup_DemandCurrenciesQuery | undefined
): Option<string>[] => {
  if (demandCurrencyData === undefined) {
    return [
      {
        label: 'USD',
        value: 'USD',
      },
    ];
  }
  return demandCurrencyData?.offering?.currencies.reduce(function (
    accum: Option<string>[],
    option: OfferingSetup_DemandCurrenciesQuery['offering']['currencies'][number]
  ) {
    if (option.recordStatus === RecordStatus.Effective) {
      accum.push({
        label: option.currencyCode,
        value: option.currencyCode,
      });
    }
    return accum;
  },
  []);
};
