import styled from 'styled-components/macro';

export const SContent = styled.div`
  margin: 10px 0;
`;

export const SEmptyNote = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SConfirmDelete = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.text.color.light};
`;
