import { Icon } from '@cmg/common';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const SSidePanelNavbar = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
`;

export const StyledNavLinkIcon = styled(Icon)`
  margin-right: 10px;
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;

export const baseLinkStyles = css`
  display: block;
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  color: ${({ theme }) => theme.text.color.dark};
  padding: 5px;
  margin: 3px 5px;
`;

export const StyledNavLink = styled(NavLink)`
  ${baseLinkStyles}

  &.activeLink {
    // @todo verify this is the correct value and if we should add it to our theme
    background-color: #e6e9ee;
    font-weight: ${({ theme }) => theme.text.weight.medium};
    color: ${({ theme }) => theme.text.color.link};
  }

  &:hover {
    color: ${({ theme }) => theme.color.blue1};
    text-decoration: none;
  }
`;

export const SInactiveSubNavLink = styled.span`
  ${baseLinkStyles}
  color: ${({ theme }) => theme.text.color.light};
  margin-left: 30px;
`;

export const StyledSubNavLink = styled(StyledNavLink)`
  margin-left: 30px;

  &.activeLink {
    background-color: transparent;
    font-weight: ${({ theme }) => theme.text.weight.medium};
    color: ${({ theme }) => theme.text.color.link};
  }
`;
