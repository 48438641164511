import { ButtonsContainer, SecondaryButton, UUID } from '@cmg/common';
import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import { encodeFileName } from '../../../common/util/encodeFileName';
import routeFactory from '../../../common/util/routeFactory';
import { useFinalSettlement_EligibleManagersQuery } from './graphql';
import SettlementLetterContentRoute from './settlement-letter-content/SettlementLetterContent';
import { sortManagersByRole } from './SettlementLettersRoute.model';
import { SHeader, STitle } from './SettlementLettersRoute.styles';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps & {
  offeringIssuerName?: string;
};

const SettlementLettersRoute: React.FC<Props> = ({ offeringIssuerName, match }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.finalSettlementSettlementLetters.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const { data, loading, error } = useFinalSettlement_EligibleManagersQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: encodeFileName(offeringIssuerName ?? 'issuer', 'Settlement_Letter', ''),
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const managersSortedByRole = sortManagersByRole(data?.letterEligibleManagers!);

  return (
    <div>
      <SHeader>
        <STitle>Settlement Letters</STitle>
        <ButtonsContainer>
          <SecondaryButton testId="print-button" onClick={handlePrint}>
            Print to PDF
          </SecondaryButton>
        </ButtonsContainer>
      </SHeader>

      <Switch>
        <Route
          path={routeFactory.finalSettlementSettlementLetterForManager.routePath}
          render={routeProps => (
            <SettlementLetterContentRoute
              managers={managersSortedByRole}
              {...routeProps}
              innerRef={componentRef}
            />
          )}
        />
        <Route
          render={() => (
            <Redirect
              to={routeFactory.finalSettlementSettlementLetterForManager.getUrlPath({
                offeringId,
                managerId: managersSortedByRole[0].cmgEntityKey!,
              })}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default SettlementLettersRoute;
