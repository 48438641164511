import React from 'react';

import { ProspectusDeliveryStatus } from '../../../../../../../graphql';
import AttestationFormStatusRenderer from '../../../../../attestation/AttestationFormStatusRenderer';
import ProspectusDeliveryStatusComponent from '../../../../../components/prospectus-delivery-status/ProspectusDeliveryStatus';
import {
  ComplianceTableType,
  getBillingAndDeliveryManagerName,
  getSubmittedByManagerName,
} from './ComplianceTable.model';
import { StyledTableCell, StyledTableRow } from './ComplianceTableRow.styles';
import { InstitutionalDemand_ComplianceTableQuery } from './graphql';

export type Props = {
  indication: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications'][number];
  managers: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['syndicate']['managers'];
  investors: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications'][number]['investorInformation'][];
  tableType: ComplianceTableType;
};

const ComplianceTableRow: React.FC<Props> = ({ indication, managers, investors, tableType }) => {
  const {
    investorInformation,
    attestationStatus,
    billingAndDeliveryAgentCmgEntityKey,
    finalAllocation,
    submittedByCmgEntityKey,
    investorProspectusDeliveryStatus,
  } = indication;

  const {
    overallDeliveryStatus = ProspectusDeliveryStatus.NotSent,
    sentEmailCount = 0,
    totalEmailCount = 0,
  } = investorProspectusDeliveryStatus ?? {};

  return (
    <StyledTableRow isFocused={false}>
      <StyledTableCell data-test-id="investor-name-field">
        {investorInformation.bankInvestorName || investorInformation.cmgEntityName}
      </StyledTableCell>
      <StyledTableCell>
        {tableType === ComplianceTableType.CERTIFICATES ? (
          <AttestationFormStatusRenderer status={attestationStatus.status} />
        ) : (
          <ProspectusDeliveryStatusComponent
            overallDeliveryStatus={overallDeliveryStatus}
            sentEmailCount={sentEmailCount}
            totalEmailCount={totalEmailCount}
          />
        )}
      </StyledTableCell>
      <StyledTableCell data-test-id="billing-and-delivery-field">
        {getBillingAndDeliveryManagerName({ managers, billingAndDeliveryAgentCmgEntityKey })}
      </StyledTableCell>
      <StyledTableCell data-test-id="submitted-by-manager-field">
        {getSubmittedByManagerName({ investors, managers, submittedByCmgEntityKey })}
      </StyledTableCell>
      <StyledTableCell>{finalAllocation?.shareQuantity}</StyledTableCell>
    </StyledTableRow>
  );
};

export default ComplianceTableRow;
