import { numericUtil } from '@cmg/common';
import { InputAdornment, Table, TableCell, TableContainer, TableRow } from '@cmg/design-system';
import { FormikCheckboxField } from '@cmg/design-system-formik';
import { VFC } from 'react';

import { FormikNumericField } from '../../../common/components/form/formik-numeric-field/FormikNumericField';
import { OperationType } from '../../../common/components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { DefaultRecipientValues } from '../../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { SyndicateRecipientsUserDataFormFields } from '../../../common/components/recipients-user-data-form-field/SyndicateRecipientsUserDataFormFields';
import { SyndicateWires_ManagerRetailRetentionFeesQuery } from '../../../common/graphql';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { PaymentRecipientUserData, Values } from '../PaymentWireForm.model';
import { RecipientsTableHead } from './RecipientsTableHead';

export type Props = Readonly<{
  readonly managers: SyndicateWireManager[];
  readonly operationType: OperationType;
  readonly retentionFees?: SyndicateWires_ManagerRetailRetentionFeesQuery['syndicateWires']['managerRetailRetentionFees'];
}>;

const defaultRecipientValues: DefaultRecipientValues<PaymentRecipientUserData> = {
  payment: null,
  ipoPenaltyTracking: false,
};

export const RecipientsTable: VFC<Props> = ({ managers, operationType, retentionFees = [] }) => {
  return (
    <TableContainer>
      <Table aria-label="Payment Wire Recipients Table">
        <RecipientsTableHead />
        <SyndicateRecipientsUserDataFormFields<PaymentRecipientUserData, Values>
          managers={managers}
          operationType={operationType}
          numColumns={4}
          defaultRecipientValues={defaultRecipientValues}
          renderRecipientRow={({ manager, managerIndex, onChange }) => {
            const { retention } =
              retentionFees.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey) ?? {};

            return (
              <TableRow key={manager.cmgEntityKey}>
                <TableCell>{manager.firmName}</TableCell>
                <TableCell align="right">
                  <FormikNumericField
                    startAdornment={
                      <InputAdornment
                        data-testid={`${manager.firmName}-payment-input-adornment`}
                        position="start"
                      >
                        $
                      </InputAdornment>
                    }
                    inputProps={{
                      'aria-label': `${manager.firmName} - Payment`,
                    }}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.payment`}
                    onChange={payment => {
                      onChange({ payment });
                    }}
                  />
                </TableCell>
                <TableCell align="right" data-testid={`${manager.firmName}-retention-cell`}>
                  {numericUtil.formatNumber(retention, 0)}
                </TableCell>
                <TableCell align="center">
                  <FormikCheckboxField
                    inputProps={{
                      'aria-label': `${manager.firmName} - IPO Penalty Tracking`,
                    }}
                    name={`recipientsUserData.${managerIndex}.ipoPenaltyTracking`}
                    onChange={ipoPenaltyTracking => {
                      onChange({ ipoPenaltyTracking });
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};
