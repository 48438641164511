import { SelectionChangedEventFn, ToastManager, UUID } from '@cmg/common';
import { CriticalActionDialog } from '@cmg/design-system';
import { AgGridEvent } from 'ag-grid-community';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import { useGridReady } from '../../../common/hooks/useGridReady';
import { useGridRefresh } from '../../../common/hooks/useGridRefresh';
import routeFactory from '../../../common/util/routeFactory';
import { DesignationStrategy } from '../../../graphql';
import { DesignationRow } from './designation-monitor-details/designation-monitor-grid/DesignationMonitorGrid.model';
import DesignationMonitorDetails from './designation-monitor-details/DesignationMonitorDetails';
import DesignationMonitorEmptyScreen from './designation-monitor-empty-screen/DesignationMonitorEmptyScreen';
import {
  useFinalSettlement_AssignDesignationToManagerMutation,
  useFinalSettlement_SettlementDesignationsQuery,
} from './graphql/__generated__';
import { useDesignationContext } from './hooks/useDesignationContext';
import { useExportDesignationMonitor } from './hooks/useExportDesignationMonitor';
import { useIsSellingConcessionChanged } from './hooks/useIsSellingConcessionChanged';
import { usePublishDesignationsMutation } from './hooks/usePublishDesignationsMutation';
import MissingEconomicsInfoBanner from './missing-economics-info-banner/MissingEconomicsInfoBanner';
import PublishErrorAlert from './publish-error-alert/PublishErrorAlert';
import SellingConcessionChangedAlert from './selling-concession-changed-alert/SellingConcessionChangedAlert';

type RouteParams = { offeringId: UUID };
type RouteProps = RouteComponentProps<RouteParams>;
export type Props = RouteProps & {
  offeringIssuerName: string | undefined;
};

/**
 * Primary Route for the Designation Monitor Route module
 */
export const DesignationMonitorRoute: React.FC<Props> = ({ offeringIssuerName, match }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.finalSettlementDesignationMonitor.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const [isPublishModalOpen, setIsPublishModalOpen] = React.useState(false);

  const {
    data: designationMonitorData,
    loading: designationMonitorLoading,
    error: designationMonitorError,
  } = useFinalSettlement_SettlementDesignationsQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const [publishDesignations, { loading: isPublishing }] = usePublishDesignationsMutation();

  const { handleExport, loading: isExportLoading } = useExportDesignationMonitor({
    offeringIssuerName,
    offeringId,
  });

  const [applyFixEconomics, { loading: applyFixEconomicsLoading }] =
    useFinalSettlement_AssignDesignationToManagerMutation();

  const [investorsFilter, setInvestorsFilter] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<DesignationRow[]>([]);
  const [isEditedOnlyFilter, setEditedOnlyFilter] = React.useState(false);
  const [isOutOfBalanceOnlyFilter, setOutOfBalanceFilter] = React.useState(false);
  const [isPublishErrorAlertVisible, setIsPublishErrorAlertVisible] = React.useState(false);
  const isSellingConcessionChanged = useIsSellingConcessionChanged(
    designationMonitorData?.designationsGrid.designations
  );

  const gridRef = React.useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);

  const context = useDesignationContext(
    offeringId,
    designationMonitorData?.designationsGrid.designations,
    designationMonitorData?.designationsGrid.syndicateManagers,
    gridRef
  );
  const { containsErrors, investorRows, noEconomicsApplied } = context;

  useGridRefresh({ gridApi: gridRef.current?.api, rows: investorRows });

  React.useEffect(() => {
    gridRef.current?.api.refreshHeader();
    // we have to update header when selection changes, filter or fetched data
  }, [context.investorRows, investorsFilter, selectedRows]);

  React.useEffect(() => {
    if (isPublishErrorAlertVisible && !containsErrors) {
      setIsPublishErrorAlertVisible(false);
    }
  }, [containsErrors, isPublishErrorAlertVisible]);

  const handleApplyFixedEconomics = async () => {
    try {
      await applyFixEconomics({
        variables: {
          offeringId,
          input: {
            operationType: DesignationStrategy.FixedEconomics,
            indicationIds: selectedRows.map(({ indicationId }) => indicationId),
          },
        },
      });
      setSelectedRows([]);

      ToastManager.success('Designations Saved');
    } catch {
      ToastManager.error('An error has occurred at saving designations');
    }
  };

  const handlePublishButtonClick = () => {
    setIsPublishErrorAlertVisible(containsErrors);
    if (containsErrors) {
      return;
    }

    setIsPublishModalOpen(true);
  };

  const handleClosePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handleClosePublishErrorAlert = () => {
    setIsPublishErrorAlertVisible(false);
  };

  const handlePublish = async () => {
    try {
      await publishDesignations({
        variables: {
          offeringId,
        },
      });

      ToastManager.success('Designations published');
    } catch {
      ToastManager.error('An error has occurred while publishing designations');
    }
  };

  const isApplyFixedEconomicsEnabled = React.useMemo(() => selectedRows.length > 0, [selectedRows]);

  const handleGridSelectionChange = useDebouncedCallback<SelectionChangedEventFn>(({ api }) => {
    const selectedNodes = api.getSelectedNodes().map(node => node.data);

    setSelectedRows(selectedNodes);
  });

  const isLoading = designationMonitorLoading || applyFixEconomicsLoading;
  const areAllocationsReleased =
    designationMonitorData?.designationValidation.areFinalAllocationsReleased;

  if (designationMonitorError) {
    return <ServerErrorsBanner error={designationMonitorError} />;
  }

  if (isLoading) {
    return (
      <FlexLayout expand direction="column">
        <Loading />
      </FlexLayout>
    );
  }

  if (areAllocationsReleased) {
    return (
      <FlexLayout expand direction="column">
        {noEconomicsApplied && <MissingEconomicsInfoBanner />}

        {isPublishErrorAlertVisible && (
          <PublishErrorAlert onDismiss={handleClosePublishErrorAlert} />
        )}

        {isSellingConcessionChanged && <SellingConcessionChangedAlert />}

        <DesignationMonitorDetails
          context={context}
          investorsFilter={investorsFilter}
          setInvestorsFilter={setInvestorsFilter}
          isApplyFixedEconomicsEnabled={isApplyFixedEconomicsEnabled}
          isEditedOnlyFilter={isEditedOnlyFilter}
          isOutOfBalanceOnlyFilter={isOutOfBalanceOnlyFilter}
          onExportClick={handleExport}
          isExportLoading={isExportLoading}
          onApplyFixedEconomicsClick={handleApplyFixedEconomics}
          onEditedOnlyFilterChange={setEditedOnlyFilter}
          onOutOfBalanceOnlyFilterChange={setOutOfBalanceFilter}
          onGridReady={onGridReady}
          onGridSelectionChange={handleGridSelectionChange}
          onPublishClick={handlePublishButtonClick}
          isPublishing={isPublishing}
        />

        <CriticalActionDialog
          open={isPublishModalOpen}
          title={`${offeringIssuerName} — Publish Designations`}
          checkboxLabelText={
            <div>
              I understand that the designations for <b>{offeringIssuerName}</b> will be published
              to the CMG Sales Credits Release module and visible to all managers on the deal.
            </div>
          }
          actionButtonText="Publish Designations"
          handleClose={handleClosePublishModal}
          onSubmit={() => {
            handlePublish();
            handleClosePublishModal();
          }}
        />
      </FlexLayout>
    );
  }

  return <DesignationMonitorEmptyScreen offeringId={offeringId} />;
};

export default DesignationMonitorRoute;
