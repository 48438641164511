import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import MandatoryAsterisk from '../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { Roadshows_RoadshowsDealTeamPartsFragment } from '../../../../../common/graphql';
import { useRoadshows_RoadshowDealTeamOrganizationsQuery } from '../../graphql';
import DealTeamMemberForm from './deal-team-member-form/DealTeamMemberForm';
import { getOrganizationDepartments, getParticipatingMemberIds } from './DealTeamListSection.model';
import {
  StyledListPanel,
  StyledListPanelContent,
  StyledListPanelHeader,
} from './DealTeamListSection.styles';

export type Props = {
  roadshowId: string;
  dealTeamsList: readonly Roadshows_RoadshowsDealTeamPartsFragment[];
};

const DealTeamListSection: React.FC<Props> = ({ roadshowId, dealTeamsList }) => {
  const { data, loading, error } = useRoadshows_RoadshowDealTeamOrganizationsQuery();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  return (
    <Table gridTemplateColumns="32px 1.7fr 1fr 1fr 1.7fr 1.7fr 80px">
      <Table.Row>
        <Table.TableHeaderCell />
        <Table.TableHeaderCell>
          Name <MandatoryAsterisk />
        </Table.TableHeaderCell>
        <Table.TableHeaderCell>Mobile Phone</Table.TableHeaderCell>
        <Table.TableHeaderCell>Office Phone</Table.TableHeaderCell>
        <Table.TableHeaderCell>
          Email <MandatoryAsterisk />
        </Table.TableHeaderCell>
        <Table.TableHeaderCell>Department</Table.TableHeaderCell>
        <Table.TableHeaderCell />
      </Table.Row>

      {dealTeamsList.map(({ firmName, members, firmCmgEntityKey }) => (
        <StyledListPanel key={firmCmgEntityKey}>
          <StyledListPanelHeader title={firmName} />
          <StyledListPanelContent>
            {members.map(member => (
              <DealTeamMemberForm
                key={member.id}
                roadshowId={roadshowId}
                member={member}
                participatingMemberIds={getParticipatingMemberIds(dealTeamsList)}
                organizationDepartments={getOrganizationDepartments(
                  firmCmgEntityKey,
                  data?.roadshowDealTeamOrganizations
                )}
              />
            ))}
          </StyledListPanelContent>
        </StyledListPanel>
      ))}
    </Table>
  );
};

export default DealTeamListSection;
