import { LinkButton, PrimaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import roadshowCompanyZeroState from '../../../../../../common/assets/roadshow-company-zero-state.svg';
import { HeroGraphics } from '../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import RoadshowCreationStep from '../../common/creation-step/RoadshowCreationStep';
import CompanySearchModal from '../company-search-modal/CompanySearchModal';
import CreateCompanyModal from '../create-company-modal/CreateCompanyModal';
import { SContentWrapper } from './EmptyCompanyDetails.styles';

export type Props = {
  roadshowId: string;
};

const EmptyCompanyDetails: React.FC<Props> = ({ roadshowId }) => {
  const [isCompanySearchModalOpen, setIsCompanySearchModalOpen] = React.useState<boolean>(false);
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] = React.useState<boolean>(false);

  const handleCloseCompanySearchModal = () => {
    setIsCompanySearchModalOpen(false);
  };

  const handleOpenCompanySearchModal = () => {
    setIsCompanySearchModalOpen(true);
  };

  const handleCloseCreateCompanyModal = () => {
    setIsCreateCompanyModalOpen(false);
  };

  const handleOpenCreateCompanyModal = () => {
    setIsCreateCompanyModalOpen(true);
  };

  return (
    <React.Fragment>
      <RoadshowCreationStep data-test-id="roadshows / single-roadshow / configuration / company">
        <RoadshowCreationStep.Header title="Company & Management Team">
          <PrimaryButton
            testId={xcSelectors.roadshowManagementTeamConfigAddCompanyButton.testId}
            onClick={handleOpenCompanySearchModal}
          >
            Add Company
          </PrimaryButton>
        </RoadshowCreationStep.Header>

        <RoadshowCreationStep.Content>
          <SContentWrapper>
            <HeroGraphics
              image={roadshowCompanyZeroState}
              imageAlt="Empty Company Details"
              title="No Company Configured"
              subtitle="Add company or create new to manage management team members"
            >
              <LinkButton
                testId={xcSelectors.roadshowManagementTeamConfigAddCompanyLinkButton.testId}
                onClick={handleOpenCompanySearchModal}
                inline
              >
                Add Company
              </LinkButton>
            </HeroGraphics>
          </SContentWrapper>
        </RoadshowCreationStep.Content>
      </RoadshowCreationStep>

      <CompanySearchModal
        show={isCompanySearchModalOpen}
        onHide={handleCloseCompanySearchModal}
        onOpenCreateCompanyModal={handleOpenCreateCompanyModal}
        roadshowId={roadshowId}
      />
      <CreateCompanyModal
        show={isCreateCompanyModalOpen}
        onHide={handleCloseCreateCompanyModal}
        roadshowId={roadshowId}
      />
    </React.Fragment>
  );
};

export default EmptyCompanyDetails;
