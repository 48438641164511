import { SyndicateWiresManagerRole as ManagerRole } from '../../../graphql';
import { managerRoleLabels } from '../manager/constants';

export type SyndicateWireManagerRole = `${ManagerRole}` | 'PRICING_COMMITTEE' | 'BROKER_DEALER';

export const syndicateWireManagerRoleLabels: {
  [role in SyndicateWireManagerRole]: string;
} = {
  ...managerRoleLabels,
  PRICING_COMMITTEE: 'Pricing Committee',
  BROKER_DEALER: 'Broker Dealer',
};
