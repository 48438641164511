import { permissionsByEntity } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import ErrorRenderer from '../../common/components/errorRenderer/Error-Renderer';
import Loading from '../../common/components/indicators/loading/Loading';
import { PrivateRoute } from '../../common/routing/private-route/PrivateRoute';
import { useCheckSyndicateWiresAccess } from '../../common/util/check-access/useCheckSyndicateWiresAccess';
import routeFactory from '../../common/util/routeFactory';
import {
  DeliveryInstrument,
  OfferingStage,
  OfferingStatus,
  OfferingType,
  Sector,
} from '../../graphql';
import { NoAccessRedirect } from '../core/RootRouter';
import FinalSettlementRoute from '../final-settlement/FinalSettlementRoute';
import OfferingSetupRoute from '../offering-setup/OfferingSetupRoute';
import { getOfferingPricingCountryCode } from '../offering-setup/utils/offeringTypeUtil';
import OrderBookRoute from '../order-book/OrderBookRoute';
import RegulatoryFilingsRoute from '../regulatory-filings/RegulatoryFilingsRoute';
import SalesCreditsRoute from '../sales-credits/SalesCreditsRoute';
import { SyndicateWiresRoute } from '../syndicate-wires/private-routes/SyndicateWiresRoute';
import TradeReleaseRoute from '../trade-release/TradeReleaseRoute';
import { OfferingDomainObject } from './components/design-system';
import { useOfferingHeaderQuery } from './hooks/useOfferingHeaderQuery';
import OfferingDetailsRoute from './offering-details/OfferingDetailsRoute';
import { hasOfferingDetailsPermissions } from './OfferingRoute.model';

const SOfferingScreen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.gray2};
`;

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;

export type Props = RouteProps;

const OfferingRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;
  const { data: headerData, loading, error } = useOfferingHeaderQuery({ offeringId });
  const issuerName = headerData?.offeringHeader.issuer.name;

  const data = headerData?.offeringHeader;
  const pricingInstrumentId = (() => {
    if (data?.__typename === 'OfferingProfile') {
      return data.pricingInstrumentId;
    }
    if (data?.__typename === 'Offering') {
      return data.draftPricingInstrumentId;
    }
  })();

  const pricingCountryCode = getOfferingPricingCountryCode({
    instruments: data?.instruments as DeliveryInstrument[],
    pricingInstrumentId,
  });

  const offeringHeaderProps: React.ComponentProps<typeof OfferingDomainObject> | null =
    React.useMemo(() => {
      return data
        ? {
            offeringId: data.id,
            stage: data.stage ?? OfferingStage.Published,
            status: data.status,
            type: data.type,
            issuerName: data.issuer.name,
            isAuthor: data.isAuthor ?? false,
            sector: data.issuer.sector,
            isDiscarded: data.isDiscarded,
            isSharedDraft: false,
            sizeInShares: data.sizeInShares,
            securityType: data.security.type,
            terms: data.terms,
            pricingCountryCode,
            pricingCurrencyCode: data.pricingCurrencyCode,
          }
        : null;
    }, [data, pricingCountryCode]);

  const syndicateWiresAccess = useCheckSyndicateWiresAccess(offeringId);

  const syndicateWiresAccessCheck = React.useCallback(() => {
    const isOfferingDiscarded = !!data?.isDiscarded;

    return !isOfferingDiscarded && (syndicateWiresAccess.canRead || syndicateWiresAccess.canManage);
  }, [data?.isDiscarded, syndicateWiresAccess.canManage, syndicateWiresAccess.canRead]);

  const regMAccessCheck = React.useCallback(() => {
    const isOfferingAuthor = !!data?.isAuthor;
    const isOfferingDiscarded = !!data?.isDiscarded;

    return !isOfferingDiscarded && isOfferingAuthor;
  }, [data?.isAuthor, data?.isDiscarded]);

  if (error) {
    return <ErrorRenderer error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <SOfferingScreen data-test-id={xcSelectors.offeringScreen.testId}>
      {offeringId && offeringHeaderProps && (
        <OfferingDomainObject
          {...offeringHeaderProps}
          status={offeringHeaderProps.status as OfferingStatus}
          stage={offeringHeaderProps.stage as OfferingStage}
          type={offeringHeaderProps.type as OfferingType}
          sector={offeringHeaderProps.sector as Sector}
        />
      )}
      {!offeringId && (
        <OfferingDomainObject
          issuerName="New Offering Draft"
          stage={OfferingStage.Draft}
          isDiscarded={false}
          isAuthor
        />
      )}
      <Switch>
        <PrivateRoute
          path={routeFactory.offeringDetails.routePath}
          render={routeProps => (
            <OfferingDetailsRoute
              {...(routeProps as RouteProps)} // TODO: PrivateRoute is not passing type correctly to render prop
              isPublished={data?.stage === OfferingStage.Published}
              issuerName={issuerName}
              isAuthor={data?.isAuthor}
            />
          )}
          accessCheck={hasOfferingDetailsPermissions}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <PrivateRoute
          path={routeFactory.offeringCreate.routePath}
          render={routeProps => (
            <OfferingSetupRoute
              {...(routeProps as RouteComponentProps<{ offeringId: UUID; stepId: string }>)}
              issuerName={issuerName}
            />
          )}
          requiredPermissions={[permissionsByEntity.Offering.FULL]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <PrivateRoute
          path={routeFactory.offeringSetup.routePath}
          render={routeProps => (
            <OfferingSetupRoute
              {...(routeProps as RouteComponentProps<{ offeringId: UUID; stepId: string }>)}
              issuerName={issuerName}
            />
          )}
          requiredPermissions={[permissionsByEntity.Offering.FULL]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <PrivateRoute
          path={routeFactory.orderBook.routePath}
          render={routeProps => (
            <OrderBookRoute {...(routeProps as RouteProps)} issuerName={issuerName} />
          )}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />

        <PrivateRoute
          path={routeFactory.syndicateWires.routePath}
          render={routeProps => (
            <SyndicateWiresRoute {...(routeProps as RouteProps)} issuerName={issuerName} />
          )}
          requiredPermissions={[permissionsByEntity.SyndicateWire.READ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
          accessCheck={() => syndicateWiresAccessCheck()}
        />

        <PrivateRoute
          path={routeFactory.regulatoryFilings.routePath}
          render={routeProps => (
            <RegulatoryFilingsRoute {...(routeProps as RouteProps)} issuerName={issuerName} />
          )}
          requiredPermissions={[permissionsByEntity.RegulatoryFiling.READ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
          accessCheck={() => regMAccessCheck()}
        />

        <PrivateRoute
          path={routeFactory.finalSettlement.routePath}
          render={routeProps => (
            <FinalSettlementRoute
              offeringIssuerName={offeringHeaderProps?.issuerName}
              {...(routeProps as RouteProps)}
            />
          )}
          requiredPermissions={[
            permissionsByEntity.Designation.READ,
            permissionsByEntity.Expense.READ,
            permissionsByEntity.Stabilization.READ,
            permissionsByEntity.Accounting.READ,
            permissionsByEntity.Letter.READ,
          ]}
          requireAllPermissions={false}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />

        <PrivateRoute
          path={routeFactory.salesCredits.routePath}
          render={routeProps => (
            <SalesCreditsRoute
              {...(routeProps as RouteProps)}
              offeringIssuerName={offeringHeaderProps?.issuerName}
            />
          )}
          requiredPermissions={[permissionsByEntity.SalesCredit.READ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />

        <PrivateRoute
          path={routeFactory.tradeRelease.routePath}
          render={routeProps => (
            <TradeReleaseRoute
              offeringIssuerName={offeringHeaderProps?.issuerName}
              {...(routeProps as RouteProps)}
            />
          )}
          requiredPermissions={[permissionsByEntity.Trade.READ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />

        {/* Default to offering details */}
        <Route
          render={() => <Redirect to={routeFactory.offeringDetails.getUrlPath({ offeringId })} />}
        />
      </Switch>
    </SOfferingScreen>
  );
};

export default OfferingRoute;
