import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { useIndicationActivitySyndicateContext } from '../../../../context/IndicationActivitySyndicateContext';
import { IndicationForm_IndicationAcknowledgementsQuery } from '../../../indication-form/components/indication-details/components/acknowledgement/graphql/__generated__';
import { useAcknowledgeInstitutionalIndicationMutation } from '../../../indication-form/components/indication-details/components/acknowledgement/hooks/useAcknowledgeInstitutionalIndicationMutation';
import { useUnacknowledgeInstitutionalIndicationMutation } from '../../../indication-form/components/indication-details/components/acknowledgement/hooks/useUnacknowledgeInstitutionalIndicationMutation';
import IndicationAcknowledgementBannerComponent from '../../common/indication-acknowledgement-banner/IndicationAcknowledgementBanner';

export type Props = Readonly<{
  acknowledgements: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements'];
}>;

const IndicationAcknowledgementBanner: React.FC<Props> = ({ acknowledgements }) => {
  const { offeringId, basicPublishedOffering } = useIndicationActivitySyndicateContext();
  const offering = basicPublishedOffering!;
  const indication = basicPublishedOffering?.orderBook.institutionalIndication!;

  const [acknowledgeIndication, { loading: isAcknowledging }] =
    useAcknowledgeInstitutionalIndicationMutation({
      offeringId,
      indicationId: indication.id,
      version: indication.version,
    });
  const [unacknowledgeIndication, { loading: isUnacknowledging }] =
    useUnacknowledgeInstitutionalIndicationMutation({
      offeringId,
      indicationId: indication.id,
      version: indication.version,
    });

  const handleAcknowledge = React.useCallback(async () => {
    try {
      await acknowledgeIndication();

      SnackbarManager.success('Successfully acknowledged indication');
    } catch {
      SnackbarManager.error('Failed to acknowledge indication');
    }
  }, [acknowledgeIndication]);
  const handleUnacknowledge = React.useCallback(async () => {
    try {
      await unacknowledgeIndication();

      SnackbarManager.success('Successfully unacknowledged indication');
    } catch {
      SnackbarManager.error('Failed to unacknowledge indication');
    }
  }, [unacknowledgeIndication]);

  return (
    <IndicationAcknowledgementBannerComponent
      onAcknowledge={handleAcknowledge}
      isAcknowledging={isAcknowledging}
      onUnacknowledge={handleUnacknowledge}
      isUnacknowledging={isUnacknowledging}
      acknowledgements={acknowledgements}
      indicationVersion={indication.version}
      offeringType={offering.type}
      indicationModifiedByFirmKey={indication.auditInfo.modifiedByFirmKey}
    />
  );
};

export default IndicationAcknowledgementBanner;
