import { getCurrencySymbol } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../common/config';
import { CMGColumnID, IndicationWithDemandLevels } from '../../types';
import {
  ShareCountTotalCellRenderer,
  shareCountValueFormatter,
} from '../InstitutionalDemandGridColumns';
import { defaultColumnProps, defaultColumnWidth, numericCellStyle } from './Columns.model';

export const useGetDemandColumns = (): ColDef[] => {
  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();
  const lockPinned = !isOrderBookPinnedColumnsOn;

  return useMemo(() => {
    return [
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.DEMAND_AT_MARKET,
        field: 'demandAtMarket',
        headerName: 'Demand @ Market',
        headerClass: 'ag-header-right',
        cellStyle: numericCellStyle,
        valueFormatter: shareCountValueFormatter,
        pinnedRowCellRendererFramework: ShareCountTotalCellRenderer,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.DEMAND_MAX,
        headerName: 'Max Demand',
        headerClass: 'ag-header-right',
        field: 'demandMax',
        cellStyle: numericCellStyle,
        valueFormatter: shareCountValueFormatter,
        pinnedRowCellRendererFramework: ShareCountTotalCellRenderer,
        lockPinned,
      },
    ];
  }, [lockPinned]);
};

/**
 * Generates column configurations for each demand increment.
 *
 * @param demandIncrements - an array of numeric demand increments.
 * @param isOrderBookPinnedColumnsOn
 */
export const generateDemandLevelColumns = (
  demandIncrements: number[],
  isOrderBookPinnedColumnsOn: boolean,
  pricingCurrencyCode?: string
) => {
  return demandIncrements.map<ColDef>(demandIncrement => {
    return {
      ...defaultColumnProps,
      ...defaultColumnWidth,
      colId: `DEMAND.${demandIncrement}`,
      headerName: `@ ${getCurrencySymbol(pricingCurrencyCode)}${demandIncrement.toFixed(2)}`,
      headerClass: 'ag-header-right',
      hide: false,
      valueGetter: ({ data }: { data: IndicationWithDemandLevels }) => {
        return data.demandLevels[demandIncrement];
      },
      cellStyle: numericCellStyle,
      valueFormatter: shareCountValueFormatter,
      pinnedRowCellRendererFramework: ShareCountTotalCellRenderer,
      lockPinned: !isOrderBookPinnedColumnsOn,
    };
  });
};
