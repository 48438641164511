import { QueryResult } from '@apollo/client';
import { UUID } from '@cmg/common';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from './useGetPublishedOfferingEntitlementsQuery';

export type Props = {
  /** offeringId to fetch entitlements */
  offeringId?: UUID;
  /** requiredEntitlements that we want to check for on the offering */
  requiredEntitlements: string[];
  /** requireAllEntitlements whether we want all requiredEntitlements to exist on the offering when true or some when false */
  requireAllEntitlements?: boolean;
};

/**
 * useCheckEntitlements checks if requiredEntitlements are defined for a given offeringId
 * returns the loading, called and error state of the request along with hasEntitlements: boolean
 *
 * While the request is made to fetch entitlements hasEntitlements is undefined
 */
export function useCheckEntitlements({
  offeringId,
  requiredEntitlements = [],
  requireAllEntitlements = true,
}: Props): Pick<QueryResult, 'error' | 'loading' | 'called'> & {
  hasEntitlements?: boolean;
} {
  const { data, error, loading, called } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const configuredEntitlements = data?.publishedOffering.entitlements?.entitlements;

  const hasEntitlements = React.useMemo(() => {
    if (!configuredEntitlements) {
      return undefined;
    }

    return requireAllEntitlements
      ? requiredEntitlements.every(entitlement => configuredEntitlements?.includes(entitlement))
      : requiredEntitlements.some(entitlement => configuredEntitlements?.includes(entitlement));
  }, [configuredEntitlements, requireAllEntitlements, requiredEntitlements]);

  return {
    hasEntitlements,
    loading,
    error,
    called,
  };
}
