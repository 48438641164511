import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../../common/config';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { suppressKeyboardEvent } from '../../../../../../../../common/util/verticalTabbing';
import { releasedFinalAllocationHeaderClass } from '../../../InstitutionalDemandGrid.model';
import { CMGColumnID, IndicationWithDemandLevels } from '../../../types';
import AllocationAcknowledgementCell from '../../allocation-acknowledgement-cell/AllocationAcknowledgementCell';
import FinalAllocationFormCellContainer from '../../allocation-form-cell/FinalAllocationFormCellContainer';
import AllocationSetTotalsCell from '../../allocation-total-cell/AllocationSetTotalsCell';
import { defaultColumnProps, MenuType } from '../Columns.model';

type FinalAllocationField = IndicationWithDemandLevels['finalAllocation'];

export const useGetFinalAllocationColumns = ({
  finalAllocationExists,
  isFinalAllocationSetReleased,
  offeringId,
}: {
  finalAllocationExists: boolean;
  isFinalAllocationSetReleased: boolean;
  offeringId: string;
}): ColDef[] => {
  const { canRead: canReadFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();
  const lockPinned = !isOrderBookPinnedColumnsOn;

  return useMemo(() => {
    const hideFilter = (c: ColDef) => {
      if (c.colId === CMGColumnID.FINAL_ALLOCATION_STATUS) {
        return canReadFinalAllocations && finalAllocationExists && isFinalAllocationSetReleased;
      }
      return canReadFinalAllocations && finalAllocationExists;
    };

    return [
      {
        ...defaultColumnProps,
        colId: CMGColumnID.FINAL_ALLOCATION,
        field: 'finalAllocation',
        headerClass: isFinalAllocationSetReleased ? releasedFinalAllocationHeaderClass : undefined,
        headerName: `Final Allocations`,
        // This cell renderer is causing performance issues
        cellRendererFramework: FinalAllocationFormCellContainer,
        pinnedRowCellRendererFramework: ({ data }) => {
          const { totalAllocation, unallocatedShares, isAnyIndicationHiddenToSalesAndBS } =
            data.filteredFinalAllocationTotals;
          return (
            <AllocationSetTotalsCell
              shareQuantity={totalAllocation}
              unallocatedShares={unallocatedShares}
              hasRowsHiddenToSalesAndBS={isAnyIndicationHiddenToSalesAndBS}
            />
          );
        },
        minWidth: 150,
        suppressMovable: !isOrderBookPinnedColumnsOn,
        pinned: isOrderBookPinnedColumnsOn ? undefined : 'right',
        suppressKeyboardEvent,
        refData: {
          menuDisplayType: MenuType.HIDE,
        },
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0px !important',
        },
        comparator: (valueA: FinalAllocationField, valueB: FinalAllocationField) => {
          const shareQuantityA = valueA?.shareQuantity ?? -1;
          const shareQuantityB = valueB?.shareQuantity ?? -1;
          if (shareQuantityA === shareQuantityB) {
            return 0;
          }
          return shareQuantityA > shareQuantityB ? 1 : -1;
        },
        lockPinned,
      },
      {
        ...defaultColumnProps,
        colId: CMGColumnID.FINAL_ALLOCATION_STATUS,
        minWidth: 170,
        width: 170,
        field: 'finalAllocation.investorReply.status',
        headerName: 'Status',
        refData: {
          menuDisplayType: MenuType.PIN_ONLY,
        },
        hide: false,
        cellRendererFramework: AllocationAcknowledgementCell,
        suppressMovable: !isOrderBookPinnedColumnsOn,
        pinned: isOrderBookPinnedColumnsOn ? undefined : 'right',
        lockPinned,
      },
    ].filter(hideFilter);
  }, [
    isFinalAllocationSetReleased,
    canReadFinalAllocations,
    finalAllocationExists,
    isOrderBookPinnedColumnsOn,
    lockPinned,
  ]);
};
