import { ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Modal from '../../../../../common/components/overlays/modal/Modal';

export type Props = {
  onCancel: () => void;
  onConfirm: () => void;
};

const CancelModal: React.FC<Props> = ({ onCancel, onConfirm }) => {
  return (
    <Modal title="Unsaved Changes" show onHide={onCancel}>
      <Modal.Content>
        <div>Are you sure you want to leave the page without your changes being saved?</div>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right" margin={16}>
          <SecondaryButton
            testId={xcSelectors.regulatoryFilingsCancelModalCancelButton.testId}
            onClick={onCancel}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.regulatoryFilingsCancelModalConfirmButton.testId}
            onClick={onConfirm}
          >
            Continue
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;
