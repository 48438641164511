import {
  SyndicateWires_UpdateUnderwriterInvitationWireMutation,
  useSyndicateWires_UpdateUnderwriterInvitationWireMutation,
} from '../graphql';

export type UpdateUnderwriterInvitationWireMutation =
  SyndicateWires_UpdateUnderwriterInvitationWireMutation;

export const useUpdateUnderwriterInvitationWireMutation = () => {
  return useSyndicateWires_UpdateUnderwriterInvitationWireMutation();
};
