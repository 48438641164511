import { InstitutionalIndicationOrderType } from '../../../../../../../../../graphql';
import { InterestLevelValue } from '../../utils/IndicationSectionValidation';

export const getIsOrderTypeFieldHidden = (
  interestLevels: InterestLevelValue[],
  isCurrentRowOrderTypeLimit: boolean
) => {
  const levelsContainMarket = interestLevels.some(
    ({ orderType }) => orderType === InstitutionalIndicationOrderType.Market
  );

  return levelsContainMarket && isCurrentRowOrderTypeLimit;
};

export const getIsLimitTypeFieldHidden = (
  interestLevels: InterestLevelValue[],
  currentRowIndex: number
) => {
  const firstLimitTypeRowIndex = interestLevels.findIndex(
    ({ limitType, orderType }) => limitType && orderType === InstitutionalIndicationOrderType.Limit
  );

  return firstLimitTypeRowIndex !== -1 && currentRowIndex !== firstLimitTypeRowIndex;
};
