import { ApolloError } from '@apollo/client';

import { ProspectusDeliveryStatus } from '../../../../../../../graphql';
import { ComplianceProspectus_ProspectusDeliveryPartsFragment } from '../../../prospectus/graphql';

export type ProspectusDeliveryModel = {
  readonly canRead: boolean;
  readonly loading: boolean;
  readonly error: ApolloError | undefined;
  readonly prospectusDeliveryHistory:
    | readonly ComplianceProspectus_ProspectusDeliveryPartsFragment[]
    | null;
  readonly isSyndicatePersona: boolean;
};

export const ProspectusDeliveryStatusLabel: { [key in ProspectusDeliveryStatus]: string } = {
  [ProspectusDeliveryStatus.Failed]: 'Failed',
  [ProspectusDeliveryStatus.InProgress]: 'In Progress',
  [ProspectusDeliveryStatus.NotSent]: 'Not Sent',
  [ProspectusDeliveryStatus.Sent]: 'Sent',
};
