import React from 'react';

export type Props = {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
};

const SalesCreditsContext = React.createContext<Props>({
  isEditing: false,
  setIsEditing: () => {},
});

export const useSalesCreditsContext = () => {
  const context = React.useContext(SalesCreditsContext);

  if (context === undefined) {
    throw new Error('No Provider found for SalesCreditsContext');
  }

  return context;
};

export const SalesCreditsProvider: React.FC = ({ children }) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <SalesCreditsContext.Provider value={{ isEditing, setIsEditing }}>
      {children}
    </SalesCreditsContext.Provider>
  );
};
