import {
  AdditionalPagination,
  AsyncPaginatedSelectField,
  AsyncPaginatedSelectFieldProps,
  Group,
  Option,
} from '@cmg/common';
import React from 'react';
import { LoadOptions } from 'react-select-async-paginate';

import { useRoadshows_SearchOfferingsLazyQuery } from './graphql';
import RoadshowOfferingOptionRenderer from './roadshow-offering-option/RoadshowOfferingOptionRenderer';
import {
  createRoadshowOfferingOptions,
  RoadshowOfferingOption,
} from './RoadshowOfferingSearch.model';

export type Props = Omit<AsyncPaginatedSelectFieldProps<string>, 'loadOptions'>;

const RoadshowOfferingSearch: React.FC<Props> = props => {
  const [searchRoadshowOfferings] = useRoadshows_SearchOfferingsLazyQuery();

  const handleLoadOptions: LoadOptions<
    Option<string>,
    Group<string>,
    AdditionalPagination
  > = async (issuerName, loadedOptions, additionalArgs) => {
    try {
      const page = additionalArgs?.page ?? 1;
      if (!issuerName) {
        return { options: loadedOptions };
      }

      const { data } = await searchRoadshowOfferings({
        variables: { issuerName, perPage: 25, page },
      });

      const options = createRoadshowOfferingOptions(data?.offerings.data ?? []);

      return {
        options,
        hasMore: !!data?.offerings.pagination.hasNext,
        additional: { page: page + 1 },
      };
    } catch {
      return { options: loadedOptions };
    }
  };

  return (
    <AsyncPaginatedSelectField
      {...props}
      loadOptions={handleLoadOptions}
      renderOption={option => (
        <RoadshowOfferingOptionRenderer option={option as RoadshowOfferingOption} />
      )}
      renderSingleSelectedOption={option => <div>{option.label}</div>}
    />
  );
};

export default RoadshowOfferingSearch;
