import React from 'react';

import Banner from '../../../../../../../../common/components/indicators/banner/Banner';
import { SErrorList, SText } from './ValidationErrorsBanner.styles';

export type Props = {
  errors: {
    allocations?: string;
    allocationsSet?: string;
  };
};

export const ValidationErrorsBanner: React.FC<Props> = ({ errors }) => {
  return (
    <Banner variant="error">
      <SText>An entry is required or has an invalid value for the following fields:</SText>
      <SErrorList>
        {Object.keys(errors).map((key, index) => {
          const renderComma = index < Object.keys(errors).length - 1;
          if (key === 'allocations') {
            return <span key={key}> Allocations{renderComma && <span>, </span>} </span>;
          }
          return <span key={key}> Allocations Set{renderComma && <span>, </span>} </span>;
        })}
      </SErrorList>
    </Banner>
  );
};
