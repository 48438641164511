import { getOperationName } from '@apollo/client/utilities';
import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { PrimaryButton, SecondaryButton, SuccessButton, ToastManager } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ButtonTooltip from '../../../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import SectionHeader from '../../../../components/section-header/SectionHeader';
import { useSupersededOfferingContext } from '../../../../contexts/SupersededOfferingContext';
import FundIoisForm, { FundIoisValues } from './components/fund-iois-form/FundIoisForm';
import FundIoisGrid from './components/fund-iois-grid/FundIoisGrid';
import { makeDeleteInput, makeUpdateInput } from './FundLevelDemand.model';
import { SConfirmDelete, SContent, SEmptyNote } from './FundLevelDemand.styles';
import {
  FundLevelDemand_FundIoisPartsFragment,
  FundLevelDemand_PublishedOfferingDocument,
  useFundLevelDemand_PublishedOfferingQuery,
  useFundLevelDemand_UpdateFundIoisMutation,
} from './graphql';

const refetchFundLevelDemand_PublishedOfferingQuery = getOperationName(
  FundLevelDemand_PublishedOfferingDocument
)!;

export type Fund = { id: string; name: string };

export type FundItem = {
  fund: Fund;
  allocation: FundLevelDemand_FundIoisPartsFragment['allocations'][number];
  iois: FundLevelDemand_FundIoisPartsFragment['iois'];
};

export type Props = {
  offeringId: string;
};

const FundLevelDemand: React.FC<Props> = ({ offeringId }) => {
  const canRead = useCheckPermissions([permissionsByEntity.FundIoi.READ]);
  const canUpdate = useCheckPermissions([permissionsByEntity.FundIoi.FULL]);

  const { isObsoleteOffering } = useSupersededOfferingContext();

  const { data, loading, error } = useFundLevelDemand_PublishedOfferingQuery({
    variables: { offeringId },
    skip: !canRead,
  });

  const funds = React.useMemo(() => data?.limitedPublishedOffering?.funds ?? [], [data]);
  const allocations = React.useMemo(
    () => data?.limitedPublishedOffering?.fundIois.allocations ?? [],
    [data]
  );
  const iois = React.useMemo(() => data?.limitedPublishedOffering?.fundIois.iois ?? [], [data]);

  // Compose dataset for the grid
  // - allocations are "unique" (for each fund there's only one allocation)
  // - there can be (and often are) more iois for a fund
  const items: FundItem[] = React.useMemo(
    () =>
      allocations.map(allocation => ({
        fund: funds.find(({ id }) => id === allocation.fundId) ?? {
          id: allocation.fundId,
          name: 'unknown',
        },
        allocation,
        iois: iois.filter(({ fundId }) => fundId === allocation.fundId),
      })),
    [funds, allocations, iois]
  );

  const [editedFundId, setEditedFundId] = React.useState<string | null>(null);

  const [deletedFundId, setDeletedFundId] = React.useState<string | null>(null);

  const [updateFundIois, { loading: updateLoading, error: updateError }] =
    useFundLevelDemand_UpdateFundIoisMutation({
      onCompleted: () => {
        ToastManager.success('Successfully updated Fund Iois');
        setEditedFundId(null);
        setDeletedFundId(null);
      },
      onError: () => {
        // no-op to prevent unhandled exception, error displayed via ServerErrorsBanner
      },
    });

  const handleAddItem = React.useCallback(() => {
    setEditedFundId('NEW');
  }, []);

  const handleEditItem = React.useCallback((fundId: string) => {
    setEditedFundId(fundId);
  }, []);

  const handleDiscard = React.useCallback(() => {
    setEditedFundId(null);
  }, []);

  const submitFormRef = React.useRef<() => void>();

  const setSubmit = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const [isEditFormValid, setIsEditFormValid] = React.useState(false);

  const handleSave = React.useCallback(() => {
    if (!submitFormRef.current) {
      return;
    }

    submitFormRef.current();
  }, []);

  const handleSubmitSave = React.useCallback(
    (values: FundIoisValues) => {
      if (!values) {
        return;
      }

      updateFundIois({
        variables: { offeringId, input: makeUpdateInput(values, allocations, iois) },
        refetchQueries: [refetchFundLevelDemand_PublishedOfferingQuery],
      });
    },
    [updateFundIois, offeringId, allocations, iois]
  );

  const handleDeleteItem = React.useCallback((fundId: string) => {
    setDeletedFundId(fundId);
  }, []);

  const handleCancelDelete = React.useCallback(() => {
    setDeletedFundId(null);
  }, []);

  const handleConfirmDelete = React.useCallback(() => {
    if (!deletedFundId) {
      return;
    }

    updateFundIois({
      variables: { offeringId, input: makeDeleteInput(deletedFundId, allocations, iois) },
      refetchQueries: [refetchFundLevelDemand_PublishedOfferingQuery],
    });
  }, [updateFundIois, offeringId, deletedFundId, allocations, iois]);

  // supports only one item per fund - only funds not yet used for any other item are available
  const availableFunds = funds.filter(
    ({ id }) => id === editedFundId || !items.map(({ fund }) => fund.id).includes(id)
  );

  if (!canRead) {
    return null;
  }

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <div data-test-id={xcSelectors.fundLevelDemandScreen.testId}>
      {!loading && error && <ServerErrorsBanner error={error} />}
      {updateError && <ServerErrorsBanner error={updateError} />}
      <SectionHeader
        title="Fund Level Demand"
        subtitle={
          editedFundId === 'NEW'
            ? 'New Fund Level IOI'
            : funds.find(({ id }) => id === editedFundId)?.name || undefined
        }
      >
        {(() => {
          if (!canUpdate) {
            return null;
          }

          if (editedFundId) {
            return (
              <React.Fragment>
                <SecondaryButton
                  testId={xcSelectors.fundLevelDemandEditFundItemDiscardButton.testId}
                  onClick={handleDiscard}
                >
                  Discard
                </SecondaryButton>
                <PrimaryButton
                  testId={xcSelectors.fundLevelDemandEditFundItemSaveButton.testId}
                  onClick={handleSave}
                  loading={updateLoading}
                  disabled={!isEditFormValid}
                >
                  Save
                </PrimaryButton>
              </React.Fragment>
            );
          }

          if (deletedFundId) {
            return (
              <React.Fragment>
                <SecondaryButton
                  testId={xcSelectors.fundLevelDemandDeleteFundItemConfirmNoButton.testId}
                  onClick={handleCancelDelete}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  testId={xcSelectors.fundLevelDemandDeleteFundItemConfirmYesButton.testId}
                  onClick={handleConfirmDelete}
                  loading={updateLoading}
                >
                  Delete
                </PrimaryButton>
              </React.Fragment>
            );
          }

          const fundsTooltip = !funds.length
            ? 'No funds available'
            : 'All funds have submitted an IOI';

          return (
            <ButtonTooltip
              disabled={!!availableFunds.length && !isObsoleteOffering}
              content={
                isObsoleteOffering
                  ? 'Fund Level IOIs cannot be created for an obsolete offering'
                  : fundsTooltip
              }
              hideArrow
              placement="topRight"
            >
              <SuccessButton
                testId={xcSelectors.fundLevelDemandAddFundItemButton.testId}
                onClick={handleAddItem}
                disabled={!availableFunds.length || isObsoleteOffering}
              >
                Add Fund Level IOI
              </SuccessButton>
            </ButtonTooltip>
          );
        })()}
      </SectionHeader>
      <SContent>
        {(() => {
          if (!items.length && !editedFundId) {
            return <SEmptyNote>You can record your fund level demand here</SEmptyNote>;
          }

          if (deletedFundId) {
            return (
              <SConfirmDelete>
                Are you sure you want to delete the{' '}
                {items.find(({ fund }) => fund.id === deletedFundId)?.fund.name} demand?
              </SConfirmDelete>
            );
          }

          if (editedFundId) {
            return (
              <FundIoisForm
                funds={availableFunds}
                item={items.find(({ fund }) => fund.id === editedFundId)}
                setSubmit={setSubmit}
                onSubmit={handleSubmitSave}
                onValidationChange={setIsEditFormValid}
                submitting={updateLoading}
              />
            );
          }

          return (
            <FundIoisGrid
              items={items}
              onEditItem={handleEditItem}
              onDeleteItem={handleDeleteItem}
              canUpdate={canUpdate}
            />
          );
        })()}
      </SContent>
    </div>
  );
};

export default FundLevelDemand;
