import { timeZoneUtil } from '@cmg/common';
import {
  DateTimePickerField,
  DateTimePickerFieldProps,
  ListItemText,
  MenuItem,
} from '@cmg/design-system';
import { FormikSelectField, FormikSelectFieldProps } from '@cmg/design-system-formik';
import { useField, useFormikContext } from 'formik';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { WireFormLayoutItem } from '../form/layout/WireFormLayoutItem';
import { getZonedDate, tryToGetIsoUtcDate } from './FormikDateTimeZonePickerField.model';

export type FormikDateTimeZonePickerFieldFormValues = {
  date: string;
  timezone: string;
};

export type FormikDateTimeZonePickerFieldProps<TOptionValue> = {
  readonly name: string;
  readonly dateTimePickerProps?: Omit<
    DateTimePickerFieldProps,
    'value' | 'onChange' | 'error' | 'helper'
  >;
  readonly timeZoneSelectProps?: Omit<
    FormikSelectFieldProps<TOptionValue>,
    | 'name'
    | 'options'
    | 'renderValue'
    | 'renderOptions'
    | 'labelId'
    | 'notched'
    | 'displayEmpty'
    | 'onChange'
  >;
};

export function FormikDateTimeZonePickerField<TOptionValue>({
  name,
  dateTimePickerProps,
  timeZoneSelectProps,
}: FormikDateTimeZonePickerFieldProps<TOptionValue>): JSX.Element {
  const [fieldDate, metaDate] = useField<string>(`${name}.date`);
  const [fieldTimeZone] = useField<string>(`${name}.timezone`);
  const { setFieldValue } = useFormikContext();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    getZonedDate(fieldDate.value, fieldTimeZone.value)
  );

  useEffect(() => {
    const isoDate = tryToGetIsoUtcDate(selectedDate, fieldTimeZone.value);
    if (isoDate !== fieldDate.value) {
      setFieldValue(fieldDate.name, isoDate);
    }
  }, [fieldDate.name, selectedDate, setFieldValue, fieldTimeZone.value, fieldDate.value]);

  const options = useMemo(
    () => timeZoneUtil.getTimeZoneOptions(fieldDate.value || new Date().toISOString()),
    [fieldDate.value]
  );

  return (
    <Fragment>
      <WireFormLayoutItem xs={6}>
        <DateTimePickerField
          {...dateTimePickerProps}
          {...fieldDate}
          value={selectedDate}
          onChange={setSelectedDate}
          error={metaDate.touched && Boolean(metaDate.error)}
          helperText={metaDate.touched && metaDate.error}
        />
      </WireFormLayoutItem>
      <WireFormLayoutItem xs={3}>
        <FormikSelectField
          {...timeZoneSelectProps}
          name={fieldTimeZone.name}
          options={options}
          inputProps={{
            displayEmpty: true,
            renderValue: value => {
              if (value === '') {
                return 'Select...';
              }

              return options.find(option => option.value === value)?.label;
            },
          }}
          renderOption={({ option }) => (
            <MenuItem key={option.label} value={option.value}>
              <ListItemText
                primary={option.label}
                secondary={timeZoneUtil.timeZoneLabels[option.label]}
              />
            </MenuItem>
          )}
        />
      </WireFormLayoutItem>
    </Fragment>
  );
}
