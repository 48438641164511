import React from 'react';

interface ManagersOrderContext {
  setManagersOrder: React.Dispatch<React.SetStateAction<string[]>>;
  managersOrder: string[];
}

export const ManagersOrder = React.createContext<ManagersOrderContext>({
  managersOrder: [],
  setManagersOrder: () => null,
});
