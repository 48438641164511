import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CloseIcon,
  IconButton,
  Typography,
} from '@cmg/design-system';
import { Fragment } from 'react';

export type Props = Readonly<{
  isShown: boolean;
  linkToIndication: string;
  onHide: () => void;
}>;

export const ProspectusValidationAlert: React.VFC<Props> = ({
  isShown,
  linkToIndication,
  onHide,
}) => {
  if (!isShown) {
    return null;
  }

  return (
    <Box py={2}>
      <Alert
        severity="error"
        aria-label="Prospectus not sent"
        action={
          <Fragment>
            <Button color="primary" size="small" href={linkToIndication}>
              View Indication
            </Button>
            <IconButton aria-label="Close" color="inherit" size="small" onClick={onHide}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Fragment>
        }
      >
        <AlertTitle>Prospectus not sent</AlertTitle>
        <Typography variant="body2">
          An indication must have investor contact information present and be acknowledged in order
          to send Prospectus.
        </Typography>
      </Alert>
    </Box>
  );
};
