import { Icon, Theme } from '@cmg/common';
import styled from 'styled-components/macro';

export type WireStatusType =
  | 'empty'
  | 'draft'
  | 'obsolete'
  | 'sent'
  | 'readyToSend'
  | 'recipientRemoved'
  | 'expired'
  | 'accepted'
  | 'declined';

export type Props = {
  status: WireStatusType;
  isActive?: boolean;
};

const getStatusColor = ({ status, isActive, theme }: Props & { theme: Theme }) => {
  switch (status) {
    case 'empty':
      return isActive
        ? theme.designSystem.colors.gray['000']
        : theme.designSystem.colors.gray['300'];
    case 'draft':
    case 'obsolete':
    case 'recipientRemoved':
      return theme.designSystem.colors.gray['200'];
    case 'sent':
      return theme.designSystem.colors.turquoise['300'];
    case 'readyToSend':
      return theme.designSystem.colors.yellow['300'];
    case 'accepted':
      return theme.designSystem.colors.green['300'];
    case 'expired':
      return theme.designSystem.colors.red['300'];
    case 'declined':
      return theme.designSystem.colors.red['300'];
  }
};

export const WireStatus = styled(Icon).attrs<
  Props, // What is consumed by WireStatus
  { name: 'circle'; variant: 'regular' | 'solid'; color: string } // What gets passed to Icon
>(({ isActive, status, theme }) => ({
  name: 'circle',
  variant: status === 'empty' && !isActive ? 'regular' : 'solid',
  color: getStatusColor({ isActive, status, theme }),
}))<Props>`
  font-size: 8px;
`;
