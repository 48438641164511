import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import React from 'react';

import { DecimalExtendedData, IndicationWithDemandLevels, StringExtendedData } from '../../types';
import { DynamicColumnPopover } from './DynamicColumnPopover';
import { STextWrapper } from './DynamicColumnRenderer.styles';

export type Props = {
  data: IndicationWithDemandLevels;
  colDef: ColDef;
};
export type DynamicDataType = StringExtendedData | DecimalExtendedData;

export const getExtendedData = (data, colDef) => {
  return get<IndicationWithDemandLevels, string, DynamicDataType | null>(data, colDef.field!, null);
};

export const DynamicColumnRenderer: React.FC<Props> = ({ data, colDef }) => {
  const { bankInvestorKey, bankInvestorName, cmgEntityName } = data.investorInformation;
  const extendedData = getExtendedData(data, colDef);

  if (!extendedData) {
    return null;
  }

  return (
    <DynamicColumnPopover
      value={extendedData?.value}
      investorName={bankInvestorName ?? cmgEntityName ?? ''}
      firmKey={bankInvestorKey}
      field={colDef.field!}
    >
      <STextWrapper>{extendedData?.value}</STextWrapper>
    </DynamicColumnPopover>
  );
};

export default DynamicColumnRenderer;
