import { useCallback } from 'react';

import { Recipient as RecipientBase } from '../../../common/components/dialogs/contact-support/ContactSupportDialog.model';
import { useContactSupportModalContext } from '../../../common/context/ContactSupportModalContext';

type Recipient = RecipientBase &
  Readonly<{
    distributionList: readonly string[];
  }>;

export type Props = Readonly<{
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly recipients: readonly Recipient[];
}>;

const useContactSupportDialog = ({ onClose, onOpen, recipients }: Props) => {
  const { open: openContactSupportDialog } = useContactSupportModalContext();

  const handleContactSupport = useCallback(() => {
    const recipientsWithMissingEmails = recipients.filter(
      ({ distributionList }) => !distributionList.length
    );

    onOpen?.();
    openContactSupportDialog(recipientsWithMissingEmails, onClose);
  }, [onClose, onOpen, openContactSupportDialog, recipients]);

  return {
    handleContactSupport,
  };
};

export default useContactSupportDialog;
