import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { LinkButton, UUID } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { ActivityGroupType } from '../../common/activity/utils';

export type Props = {
  groupType: ActivityGroupType;
  mode: 'edit' | 'view';
  onAddCustom?: () => void;
  offeringId?: UUID;
};

export const ZeroActivitiesAction: React.FC<Props> = ({
  groupType,
  mode,
  onAddCustom,
  offeringId = '',
}) => {
  const canAccessStabilizationModule = useCheckPermissions([
    permissionsByEntity.Stabilization.READ,
  ]);

  if (mode === 'view') {
    return null;
  }

  if (groupType === 'stabilization' && canAccessStabilizationModule) {
    return (
      <div>
        <Link
          to={routeFactory.finalSettlementStabilization.getUrlPath({ offeringId })}
          target="_blank"
        >
          View Stabilization Module
        </Link>{' '}
        to add an activity
      </div>
    );
  }
  if (groupType === 'custom') {
    return (
      <React.Fragment>
        Click{' '}
        <LinkButton inline onClick={onAddCustom}>
          + Add Activity
        </LinkButton>{' '}
        to add an activity
      </React.Fragment>
    );
  }

  return null;
};
