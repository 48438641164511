import { DropdownButton, FlexContainer, Popover, ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import { SFormHeader } from '../components/form/OfferingSetupForm.styles';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import {
  useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
  useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
  useOfferingSetup_ManagerEconomics_FilingsQuery,
  useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
  useOfferingSetup_ManagerEconomics_SyndicateQuery,
  useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
  useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation,
} from '../graphql';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import { EconomicsBreakdownErrors } from './components/economics-breakdown-errors/EconomicsBreakdownErrors';
import {
  EconomicBreakdownGrid,
  ManagerEconomicsBreakdownFormikContext,
} from './components/economics-breakdown-grid/EconomicBreakdownGrid';
import { generateTotalValues } from './components/economics-breakdown-grid/EconomicBreakdownGrid.model';
import { GrossSpreadAllocationCard } from './components/gross-spread-allocation-card/GrossSpreadAllocationCard';
import { ManagerEconomicsErrors } from './components/manager-economics-errors/ManagerEconomicsErrors';
import { OfferingSizeCard } from './components/offering-size-card/OfferingSizeCard';
import { OverwriteModal } from './components/overwrite-modal/OverwriteModal';
import { UnderwritingDiscountCard } from './components/underwriting-discount-card/UnderwritingDiscountCard';
import {
  EconomicBreakdownValidationTypes,
  useValidateEconomicBreakdownGrid,
} from './hooks/useValidateEconomicBreakdownGrid';
import { useValidateManagerEconomicsData } from './hooks/useValidateManagerEconomicsData';
import {
  calculatedValuesType,
  createEconomicBreakdownRows,
  createEconomicBreakdownVariables,
  handleCalculateFeesDistributionModel,
  handleCalculateOverallotmentSharesDistributionModel,
  hasNonNullValueInColumn,
} from './ManagerEconomicsRoute.model';
import {
  SHeaderTitle,
  StyledPrimaryButton,
  StyledSectionHeader,
} from './ManagerEconomicsRoute.styles';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

type UpdateManagerEconomicsVariables = ReturnType<typeof createEconomicBreakdownVariables>;

export const ManagerEconomicsRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const { revalidate } = useValidateOffering(offeringId);

  const {
    data: economicsData,
    loading: economicsLoading,
    error: economicsError,
  } = useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });
  const {
    data: filingsData,
    loading: filingsLoading,
    error: filingsError,
  } = useOfferingSetup_ManagerEconomics_FilingsQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });
  const {
    data: feesAndDiscountsData,
    loading: feesAndDiscountsLoading,
    error: feesAndDiscountsError,
  } = useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });
  const {
    data: syndicateData,
    loading: syndicateLoading,
    error: syndicateError,
  } = useOfferingSetup_ManagerEconomics_SyndicateQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });

  // Grabbing pricingCurrencyCode from economicsData query since we're not calling a separate general offering query
  const pricingCurrencyCode = economicsData?.offering.pricingCurrencyCode;

  const underwritingFeesAndDiscounts = feesAndDiscountsData?.getUnderwritingFeesAndDiscounts;

  const latestFiling = filingsData?.getFilings[filingsData.getFilings.length - 1];

  const [userSavedValues, setUserSavedValues] = React.useState<UpdateManagerEconomicsVariables>();

  const rows = React.useMemo(() => {
    if (userSavedValues && syndicateData?.offering.syndicate.managers) {
      return createEconomicBreakdownRows(
        syndicateData?.offering.syndicate.managers,
        userSavedValues
      );
    }
    return syndicateData?.offering.syndicate.managers &&
      economicsData?.offering.syndicate.managerEconomics
      ? createEconomicBreakdownRows(
          syndicateData?.offering.syndicate.managers,
          economicsData?.offering.syndicate.managerEconomics
        )
      : [];
  }, [
    syndicateData?.offering.syndicate.managers,
    economicsData?.offering.syndicate.managerEconomics,
    userSavedValues,
  ]);

  const [updateManagerEconomics, { loading: updateLoading }] =
    useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation();
  const [calculateFeesDistribution, { loading: loadingCalculateFeesDistribution }] =
    useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation();
  const [calculateSharesDistribution, { loading: loadingCalculateSharesDistribution }] =
    useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation();

  const saveStateDisabled =
    updateLoading || loadingCalculateFeesDistribution || loadingCalculateSharesDistribution;
  // Combination variable used ONLY to prevent saving while the user is waiting to hear back about results that will update the table.
  // Does not affect the spinner icon that displays while fetching initial queries.

  const formik = useFormik<ManagerEconomicsBreakdownFormikContext>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      rows,
    },
    onSubmit: async values => {
      if (validateManagerEconomicsData()) {
        try {
          const updateVariables = createEconomicBreakdownVariables(offeringId, values.rows);
          await updateManagerEconomics({
            variables: updateVariables,
          });
          ToastManager.success('Managers economics updated');
          revalidate();
          setUserSavedValues(updateVariables);
        } catch {
          ToastManager.error('Error while updating manager economics');
          throw new Error('Error while updating manager economics');
        }
      } else {
        throw new Error('Validation Failed');
      }
    },
  });

  const { handleSubmit, dirty, values, setFieldValue, setErrors } = formik;

  const totalValues = generateTotalValues(values.rows);

  const { validateData: validateManagerEconomicsData, errors: managerEconomicsErrors } =
    useValidateManagerEconomicsData({
      totalValues,
      incentiveGrossSpread: underwritingFeesAndDiscounts?.incentiveFeeData,
      setErrors,
      values,
    });

  const { validateData: validateEconomicBreakdownData, errors: economicBreakdownErrors } =
    useValidateEconomicBreakdownGrid({
      grossSpreadBaseData: underwritingFeesAndDiscounts?.grossSpreadBaseData,
      incentiveFeeData: underwritingFeesAndDiscounts?.incentiveFeeData,
      totalValues,
      totalSharesBaseOffering: latestFiling?.totalSharesBaseOffering,
      totalSharesOverallotmentExercised: latestFiling?.totalSharesOverAllotmentExercised,
    });

  const pageIsLoading =
    economicsLoading || filingsLoading || feesAndDiscountsLoading || syndicateLoading;
  // Combination variable used ONLY for displaying the spinner while the page initially loads. This will NOT display the spinner on save.

  const popoverParams = React.useMemo(() => {
    let content = '';
    if (!formik.isValid) {
      content = 'Unable to save data. Please review errors below.';
    } else if (!formik.dirty) {
      content = 'No new changes to save.';
    }
    return {
      content,
      disabled: saveStateDisabled || (formik.isValid && formik.dirty) || formik.isSubmitting,
    };
  }, [saveStateDisabled, formik.isValid, formik.dirty, formik.isSubmitting]);

  const handleCalculateFeesDistribution = React.useCallback(async () => {
    if (!loadingCalculateFeesDistribution && !loadingCalculateSharesDistribution) {
      const res = await handleCalculateFeesDistributionModel({
        values,
        calculateFeesDistribution,
        offeringId,
        calculateSharesDistribution,
      });
      if ('error' in res) {
        ToastManager.error('Error while calculating fee distribution');
      } else {
        Object.entries(res as calculatedValuesType).forEach(([cmgEntityKey, feeDistributions]) => {
          feeDistributions.forEach(feeDistribution => {
            setFieldValue(
              `rows[${cmgEntityKey}].${feeDistribution.gridColumn}`,
              feeDistribution.percentageValue !== undefined
                ? feeDistribution.percentageValue
                : feeDistribution.sharesValue
            );
          });
        });
      }
    }
  }, [
    calculateFeesDistribution,
    loadingCalculateFeesDistribution,
    values,
    setFieldValue,
    offeringId,
    calculateSharesDistribution,
    loadingCalculateSharesDistribution,
  ]);

  const handleCalculateOverallotmentSharesDistribution = React.useCallback(async () => {
    if (!loadingCalculateSharesDistribution) {
      const res = await handleCalculateOverallotmentSharesDistributionModel({
        values,
        calculateSharesDistribution,
        offeringId,
      });

      if ('error' in res) {
        ToastManager.error('Error while calculating share distribution');
      } else {
        Object.entries(res as calculatedValuesType).forEach(([cmgEntityKey, feeDistributions]) => {
          feeDistributions.forEach(feeDistribution => {
            setFieldValue(
              `rows[${cmgEntityKey}].${feeDistribution.gridColumn}`,
              feeDistribution.sharesValue
            );
          });
        });
      }
    }
  }, [
    calculateSharesDistribution,
    values,
    setFieldValue,
    offeringId,
    loadingCalculateSharesDistribution,
  ]);

  const [showOverwriteModal, setShowOverwriteModal] = React.useState<boolean>(false);
  const [calculatedField, setCalculatedField] = React.useState<string>('');

  const handleClickCalculateFeesDistribution = React.useCallback(async () => {
    if (validateEconomicBreakdownData(EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN)) {
      const hasNonNullValue = hasNonNullValueInColumn(
        [
          'managementFee',
          'underwritingWithIncentiveFee',
          'sellingConcession',
          'underwritingShares',
        ],
        formik.values.rows
      );

      if (hasNonNullValue) {
        setShowOverwriteModal(true);
        setCalculatedField(EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN);
        return;
      }

      await handleCalculateFeesDistribution();
    }
  }, [formik.values.rows, handleCalculateFeesDistribution, validateEconomicBreakdownData]);

  const handleClickCalculateSharesDistribution = React.useCallback(async () => {
    if (validateEconomicBreakdownData(EconomicBreakdownValidationTypes.EXERCISED_OVERALLTOMENT)) {
      const hasNonNullValue = hasNonNullValueInColumn(
        ['exercisedOverallotmentShares'],
        formik.values.rows
      );

      if (hasNonNullValue) {
        setCalculatedField(EconomicBreakdownValidationTypes.EXERCISED_OVERALLTOMENT);
        setShowOverwriteModal(true);
        return;
      }

      await handleCalculateOverallotmentSharesDistribution();
    }
  }, [
    formik.values.rows,
    handleCalculateOverallotmentSharesDistribution,
    validateEconomicBreakdownData,
  ]);
  const error = economicsError ?? filingsError ?? feesAndDiscountsError ?? syndicateError;
  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        {error && <ServerErrorsBanner error={error} />}
        <SetupScreen.Header
          rightContent={
            <Popover
              content={popoverParams.content}
              disabled={popoverParams.disabled}
              variant="TOOLTIP"
              placement="topLeft"
              trigger="hover"
            >
              <div>
                <StyledPrimaryButton
                  testId={
                    xcSelectors.offeringSetupManagerEconomicsSaveManagerEconomicsButton.testId
                  }
                  loading={updateLoading}
                  disabled={syndicateLoading || economicsLoading || !dirty || !formik.isValid}
                  onClick={() => handleSubmit()}
                >
                  Save
                </StyledPrimaryButton>
              </div>
            </Popover>
          }
        >
          <SHeaderTitle>Manager Economics</SHeaderTitle>
        </SetupScreen.Header>
        {pageIsLoading ? (
          <SetupScreen.LoadingWrapper>
            <Spinner show fullHeight />
          </SetupScreen.LoadingWrapper>
        ) : (
          <FlexContainer direction="column" gap={16}>
            {dirty && <ManagerEconomicsErrors errors={managerEconomicsErrors} />}
            <FlexContainer direction="row" gap={16}>
              <OfferingSizeCard
                offeringId={offeringId}
                baseOfferingShares={latestFiling?.totalSharesBaseOffering}
                exercisedOverallotmentShares={latestFiling?.totalSharesOverAllotmentExercised}
              />
              <UnderwritingDiscountCard
                offeringId={offeringId}
                baseGrossSpread={underwritingFeesAndDiscounts?.grossSpreadBaseData?.grossSpreadBase}
                incentiveFee={underwritingFeesAndDiscounts?.incentiveFeeData?.incentiveFee}
                pricingCurrencyCode={pricingCurrencyCode}
              />
              <GrossSpreadAllocationCard
                offeringId={offeringId}
                base={underwritingFeesAndDiscounts?.grossSpreadBaseData}
                incentiveFee={underwritingFeesAndDiscounts?.incentiveFeeData}
              />
            </FlexContainer>
            <FormikProvider value={formik}>
              <FormikUnsavedChangesGuard>
                <SFormHeader justifyContent="space-between">
                  <StyledSectionHeader>Economics Breakdown</StyledSectionHeader>
                  <OverwriteModal
                    calculatedField={calculatedField}
                    show={showOverwriteModal}
                    onCalculate={
                      calculatedField === EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN
                        ? handleCalculateFeesDistribution
                        : handleCalculateOverallotmentSharesDistribution
                    }
                    onHide={() => setShowOverwriteModal(false)}
                  />
                  <DropdownButton
                    popoverVariant="LIGHT"
                    options={[
                      {
                        label: 'Economics Breakdown',
                        onClick: handleClickCalculateFeesDistribution,
                        testId:
                          xcSelectors.offeringSetupManagerEconomicsCalculateEconomicBreakdownButton
                            .testId,
                      },
                      {
                        label: 'Exercised Overallotment Shares Distribution',
                        onClick: handleClickCalculateSharesDistribution,
                        testId:
                          xcSelectors
                            .offeringSetupManagerEconomicsCalculateEcercisedOverallotmentSharesDistributionButton
                            .testId,
                      },
                    ]}
                  >
                    Calculate
                  </DropdownButton>
                </SFormHeader>
                <EconomicsBreakdownErrors
                  errors={economicBreakdownErrors}
                  offeringId={offeringId}
                />
                <EconomicBreakdownGrid
                  totalSharesOverallotmentExercised={
                    latestFiling?.totalSharesOverAllotmentExercised
                  }
                  totalSharesOverallotmentAuthorized={
                    latestFiling?.totalSharesOverAllotmentAuthorized
                  }
                  totalSharesBaseOffering={latestFiling?.totalSharesBaseOffering}
                  underwritingFeesAndDiscounts={underwritingFeesAndDiscounts}
                  latestFiling={latestFiling}
                  grossSpreadBaseData={underwritingFeesAndDiscounts?.grossSpreadBaseData ?? null}
                  incentiveFeeData={underwritingFeesAndDiscounts?.incentiveFeeData ?? null}
                  totalValues={totalValues}
                />
              </FormikUnsavedChangesGuard>
            </FormikProvider>
          </FlexContainer>
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};
