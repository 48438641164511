import { ToastManager, UUID } from '@cmg/common';
import { Alert, AlertTitle, Grid, Stack } from '@cmg/design-system';
import { Form, FormikProvider, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import { useCallback, useMemo, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';

import { InvalidFormFieldsAlert } from '../../../../../../../common/components/alerts/invalid-form-fields-alert/InvalidFormFieldsAlert';
import { OfferingType } from '../../../../../../../graphql';
import { SyndicateWire, SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useSyndicateWires_CustomVariablesQuery } from '../../../graphql';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { useDisclaimerContext } from './context/DisclaimerContext';
import {
  findMatchedVariables,
  findUnknownVariables,
  getMaxWidth,
  getVariables,
  OperationType,
  operationTypeToText,
} from './CreateOrUpdateWireDialog.model';
import { CreateOrUpdateWireDialogSkeleton } from './CreateOrUpdateWireDialogSkeleton';
import CustomVariablesAlert from './custom-variables/alert/CustomVariablesAlert';
import CustomVariables from './custom-variables/CustomVariables';
import { usePredefinedData } from './hooks/usePredefinedData';
import { usePristineDataWarning } from './hooks/usePristineDataWarning';

export type Props = Readonly<{
  onClose: () => void;
  operationType: OperationType;
  offeringId: UUID;
  syndicateWireId?: UUID;
  wire?: SyndicateWire;
  nextRecipients: SyndicateWireManager[];
  offeringType: OfferingType;
}>;

export const CreateOrUpdateWireDialog: VFC<Props> = <Values extends { disclaimer?: string }>({
  onClose,
  operationType,
  wire,
  offeringId,
  syndicateWireId,
  nextRecipients,
  offeringType,
}: Props) => {
  const { push } = useHistory();
  const [areFormDataLoaded, setFormDataLoaded] = useState(true);
  const { areCustomVariablesVisible } = useDisclaimerContext();

  const {
    wireTypeName,
    wireTypeRoute,
    wireTypeCategory,
    wireTemplateType,
    CreateOrUpdateForm,
    formValidation,
    useCreateMutation,
    useUpdateMutation,
    useGetInitialValues,
    createOrUpdateModalSize = 'sm',
  } = useWireTypeConfigContext<Values>();

  const {
    data: variablesData,
    error: variablesError,
    loading: variablesLoading,
  } = useSyndicateWires_CustomVariablesQuery({
    variables: { offeringId, wireTemplateType },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: initialValues,
    predefinedData,
    error: initialValuesError,
    isLoading: areInitialValuesLoading,
  } = useGetInitialValues({
    managers: nextRecipients,
    wire,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  });

  const customVariables = variablesData?.syndicateWires.customVariables;

  const [unknownVariables, setUnknownVariables] = useState<string[]>([]);

  const formik = useFormik<Values>({
    validateOnChange: true,
    validateOnMount: true,
    validate: async values => {
      // Use memoization of the disclaimer validation if performance becomes an issue
      const { isValid, unknownVariables } = findUnknownVariables({
        disclaimer: values.disclaimer,
        variables,
      });
      setUnknownVariables(unknownVariables);
      try {
        await validateYupSchema(values, formValidation, true, {
          isDisclaimerValid: isValid,
        });
        return {};
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
    initialValues,
    onSubmit: async values => {
      // GraphQL only expects the exact keys specified in the schema and there
      // might be more than that in values, so we need to trim it.
      const graphQLValues = pick(values, Object.keys(initialValues)) as Values;
      // ignore disclaimer if it contains only whitespaces
      graphQLValues.disclaimer =
        values.disclaimer?.trim().length === 0 ? undefined : values.disclaimer;

      if (operationType === OperationType.CREATE) {
        await handleCreateWireDraft(graphQLValues);
      } else {
        await handleUpdateWireDraft(graphQLValues);
      }
    },
    enableReinitialize: true,
  });

  const { setValues, handleSubmit, dirty, errors, touched } = formik;

  usePredefinedData<Values>({
    predefinedData,
    arePredefinedDataLoading: areInitialValuesLoading,
    onSetPredefinedData: setValues,
  });

  const [isPristineDataWarningShown, showPristineDataWarning] = usePristineDataWarning(dirty);

  const { mutation: createWireMutation, isLoading: isCreating } = useCreateMutation();
  const { mutation: updateWireMutation, isLoading: isUpdating } = useUpdateMutation();

  const handleCreateWireDraft = async (values: Values) => {
    try {
      const createdWire = await createWireMutation({ offeringId, values });

      if (!createdWire) {
        return;
      }

      push(
        `${wireTypeRoute.getUrlPath({
          offeringId,
          syndicateWireId: createdWire.id,
        })}?operation=${OperationType.CREATE}`
      );

      onClose();
    } catch {
      ToastManager.error(
        `There was an error creating a ${wireTypeName} ${wireTypeCategory}. Please try refreshing the page.`
      );
    }
  };

  const handleUpdateWireDraft = async (values: Values) => {
    try {
      if (!syndicateWireId) {
        return;
      }

      await updateWireMutation({
        syndicateWireId,
        values,
        offeringId,
      });

      push(
        `${wireTypeRoute.getUrlPath({
          offeringId,
          syndicateWireId,
        })}?operation=${OperationType.UPDATE}`
      );

      onClose();
    } catch {
      ToastManager.error(
        `There was an error updating a ${wireTypeName} ${wireTypeCategory}. Please try refreshing the page.`
      );
    }
  };

  const isSubmitting = isCreating || isUpdating;

  const isLoading = areInitialValuesLoading;
  const error = initialValuesError;

  const disclaimer = formik.values.disclaimer;

  const { emptyVariables, variables } = useMemo(
    () => getVariables(customVariables ?? []),
    [customVariables]
  );
  const matchedEmptyVariables = useMemo(
    () => findMatchedVariables({ disclaimer, emptyVariables }),
    [disclaimer, emptyVariables]
  );

  const submit = useCallback(() => {
    if (!areFormDataLoaded) {
      return;
    }

    if (operationType === OperationType.UPDATE && !dirty) {
      showPristineDataWarning();
      return;
    }

    handleSubmit();
  }, [areFormDataLoaded, dirty, handleSubmit, operationType, showPristineDataWarning]);

  return (
    <ConfirmationDialog
      fullWidth={true}
      maxWidth={getMaxWidth({ areCustomVariablesVisible, createOrUpdateModalSize })}
      isOpen={true}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      title={`${operationTypeToText[operationType]} ${wireTypeName} ${capitalize(
        wireTypeCategory
      )}`}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Continue"
      onCancel={onClose}
      onSubmit={submit}
      content={
        <FormikProvider value={formik}>
          <Grid container spacing={2}>
            {unknownVariables.length > 0 && (
              <Grid item xs={12}>
                <CustomVariablesAlert matchedVariables={unknownVariables} type="error" />
              </Grid>
            )}
            {matchedEmptyVariables.length > 0 && (
              <Grid item xs={12}>
                <CustomVariablesAlert matchedVariables={matchedEmptyVariables} type="warning" />
              </Grid>
            )}
            <Grid item xs={areCustomVariablesVisible ? 8 : 12}>
              <Stack gap={2}>
                {error && (
                  <Alert
                    severity="error"
                    aria-label="An error has occurred while loading default values."
                  >
                    An error has occurred while loading default values.
                  </Alert>
                )}
                {isPristineDataWarningShown && (
                  <Alert
                    severity="warning"
                    aria-label="The version of the wire with the following information already exists."
                  >
                    <AlertTitle>
                      The version of the wire with the following information already exists.
                    </AlertTitle>
                    Please modify the information on the wire to continue.
                  </Alert>
                )}
                <InvalidFormFieldsAlert
                  errors={errors}
                  touched={touched}
                  isSubmitting={formik.isSubmitting}
                  validationSchema={formValidation}
                />
                {isLoading ? (
                  <CreateOrUpdateWireDialogSkeleton />
                ) : (
                  <Form data-test-id="create-or-update-wire-modal-form">
                    <CreateOrUpdateForm
                      offeringId={offeringId}
                      operationType={operationType}
                      managers={nextRecipients}
                      onFormDataLoaded={setFormDataLoaded}
                    />
                  </Form>
                )}
              </Stack>
            </Grid>
            {areCustomVariablesVisible && (
              <Grid item xs={4} gap={2}>
                <CustomVariables
                  hasError={!!variablesError}
                  isLoading={variablesLoading}
                  customVariables={customVariables ?? []}
                />
              </Grid>
            )}
          </Grid>
        </FormikProvider>
      }
    />
  );
};
