import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

import usePrevious from '../../../../../../../../common/util/usePrevious';

export type UsePredefinedDataProps<TValues> = Readonly<{
  readonly arePredefinedDataLoading: boolean;
  readonly predefinedData: TValues | undefined;
  readonly onSetPredefinedData: (predefinedData: TValues) => void;
}>;

export function usePredefinedData<TValues>({
  arePredefinedDataLoading,
  predefinedData,
  onSetPredefinedData,
}: UsePredefinedDataProps<TValues>): void {
  const prevArePredefinedDataLoading = usePrevious(arePredefinedDataLoading);
  const prevPredefinedData = usePrevious(predefinedData);
  useEffect(() => {
    if (arePredefinedDataLoading || !predefinedData) {
      return;
    }

    if (isEqual(predefinedData, prevPredefinedData) && !prevArePredefinedDataLoading) {
      return;
    }

    onSetPredefinedData(predefinedData);
  }, [
    arePredefinedDataLoading,
    prevArePredefinedDataLoading,
    predefinedData,
    prevPredefinedData,
    onSetPredefinedData,
  ]);
}
