import { PrimaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import { StyledCancelButton } from './DeleteLogisticsConfirmModal.styles';

export type Props = { show: boolean; onClose: () => void; onConfirm: () => void; loading: boolean };

const DeleteLogisticsConfirmModal: React.FC<Props> = ({ onClose, show, onConfirm, loading }) => (
  <Modal title="Delete Logistics" show={show} onHide={onClose}>
    <Modal.Content>
      Would you like to delete this Logistics?. This action cannot be reverted.
    </Modal.Content>
    <Modal.Footer>
      <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
      <PrimaryButton onClick={onConfirm} loading={loading}>
        Confirm
      </PrimaryButton>
    </Modal.Footer>
  </Modal>
);

export default DeleteLogisticsConfirmModal;
