import { getCurrencySymbol, numericUtil } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import isNil from 'lodash/isNil';
import React from 'react';

import { offeringStatusLabels } from '../../types/domain/offering/constants';
import { sectorLabels } from '../constants';
import { getOfferingTypeLabel } from '../offering-setup/utils/offeringTypeUtil';
import IssuerNameColCellRenderer from './drafts/components/IssuerNameColCellRenderer';

export enum OfferingGridOrderField {
  SYMBOL = 'pricingInstrumentStockSymbol',
  ISSUER_NAME = 'issuerName',
  TYPE = 'type',
  STATUS = 'status',
  SECTOR = 'issuerSector',
  STAGE = 'stage',
  SIZE_IN_CURRENCY = 'sizeInCurrency',
  SIZE_IN_SHARES = 'sizeInShares',
  PRICE_RANGE = 'priceRange',
  LEFT_LEAD = 'leftLeadFirmName',
}

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal',
  lineHeight: 1.2,
};

const columnDefaultProperties = {
  suppressColumnsToolPanel: true,
  resizable: true,
  flex: 1,
  cellStyle,
};

export const numericCellStyle = {
  ...cellStyle,
  justifyContent: 'flex-end',
};

const formatPriceRange = data => {
  if (isNil(data.priceRangeLow) && isNil(data.priceRangeHigh)) {
    return '-';
  }
  const rangeLow = numericUtil.formatCurrency(
    data.priceRangeLow,
    2,
    getCurrencySymbol(data.pricingCurrencyCode)
  );
  const rangeHigh = numericUtil.formatCurrency(
    data.priceRangeHigh,
    2,
    getCurrencySymbol(data.pricingCurrencyCode)
  );

  return `${rangeLow} - ${rangeHigh}`;
};

export const offeringColumnMap: {
  [key: string]: ColDef;
} = {
  [OfferingGridOrderField.ISSUER_NAME]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.ISSUER_NAME,
    field: 'issuerName',
    headerName: 'Issuer',
    sort: 'asc',
    minWidth: 300,
    sortingOrder: ['asc', 'desc'],
    cellRendererFramework: IssuerNameColCellRenderer,
  },
  [OfferingGridOrderField.SYMBOL]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.SYMBOL,
    headerName: 'Symbol',
    field: 'pricingInstrumentStockSymbol',
    cellRendererFramework: ({ value }) => <div>{value ?? '-'}</div>,
  },
  [OfferingGridOrderField.SECTOR]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.SECTOR,
    headerName: 'Sector',
    field: 'issuerSector',
    cellRendererFramework: ({ value }) => <div>{value ? sectorLabels[value] : '-'}</div>,
  },
  [OfferingGridOrderField.TYPE]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.TYPE,
    field: 'type',
    cellRendererFramework: ({ value, data }) => {
      // ECM-46695 - For international offering
      return (
        <div data-test-id={xcSelectors.myOfferingsDraftOfferingType.testId}>
          {getOfferingTypeLabel({
            offeringType: value,
            isExecutingOnPlatform: data.isExecutingOnPlatform,
            pricingInstrumentCountryCode: data.pricingInstrumentCountryCode,
          })}
        </div>
      );
    },
    headerName: 'Type',
  },
  [OfferingGridOrderField.STATUS]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.STATUS,
    field: 'status',
    cellRendererFramework: ({ value }) => {
      return <div>{offeringStatusLabels[value]}</div>;
    },
    headerName: 'Status',
  },
  [OfferingGridOrderField.SIZE_IN_CURRENCY]: {
    ...columnDefaultProperties,
    cellStyle: numericCellStyle,
    colId: OfferingGridOrderField.SIZE_IN_CURRENCY,
    headerName: 'Size ($M)',
    field: 'sizeInCurrency',
    cellRendererFramework: ({ value, data }) => (
      <div>
        {isNil(value)
          ? '-'
          : numericUtil.formatCurrencyInMillions(
              value,
              undefined,
              getCurrencySymbol(data.pricingCurrencyCode)
            )}
      </div>
    ),
  },
  [OfferingGridOrderField.SIZE_IN_SHARES]: {
    ...columnDefaultProperties,
    cellStyle: numericCellStyle,
    colId: OfferingGridOrderField.SIZE_IN_SHARES,
    headerName: 'Size (SHARES)',
    field: 'sizeInShares',
    cellRendererFramework: ({ value }) => {
      return <div>{isNil(value) ? '-' : numericUtil.formatNumber(value, 0)}</div>;
    },
  },
  [OfferingGridOrderField.PRICE_RANGE]: {
    ...columnDefaultProperties,
    cellStyle: numericCellStyle,
    colId: OfferingGridOrderField.PRICE_RANGE,
    headerName: 'Price Range',
    cellRendererFramework: ({ data }) => {
      return <div>{formatPriceRange(data)}</div>;
    },
  },
  [OfferingGridOrderField.LEFT_LEAD]: {
    ...columnDefaultProperties,
    colId: OfferingGridOrderField.LEFT_LEAD,
    headerName: 'Left Lead',
    field: 'leftLeadFirmName',
    cellRendererFramework: ({ value }) => <div>{value ?? '-'}</div>,
  },
};
