import styled from 'styled-components/macro';

export const SCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.background.color.white};
  gap: 20px;

  .fc-scrollgrid-section-header {
    background-color: ${({ theme }) => theme.background.color.light};
  }

  .fc-col-header-cell-cushion {
    color: ${({ theme }) => theme.text.color.dark};
    font-weight: normal;
  }

  .fc-timegrid-slot {
    height: 52px;
  }

  .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }

  .fc-timegrid-slot-label {
    border: none;
    font-size: ${({ theme }) => theme.text.size.small};
  }

  .fc-timegrid-slot-minor {
    border: none;
  }

  // Position time slot labels vertically to be rendered next to row border
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    position: relative;
    top: -50%;
  }

  // Hide first time slot label to solve header overflow
  .fc-timegrid-slots tr:first-child .fc-timegrid-slot-label:first-child {
    display: none;
  }

  // TODO come up with a better approach to override default styles
  .fc-v-event {
    background-color: white;
    border: none;
  }
`;
