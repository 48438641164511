import { timeUtil } from '@cmg/common';
import React from 'react';

import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import { STooltipTableCell } from './ShareActivityTooltip.styles';

export type ShareActivityRowProps = {
  managers: string[];
  shareDatetime: string;
};

const ShareActivityRow: React.FC<ShareActivityRowProps> = ({ managers, shareDatetime }) => {
  const managerNames = managers.filter(managerName => managerName ?? null);

  return (
    <FlexLayout direction="row" expand>
      <STooltipTableCell>{managerNames.length ? managerNames.join(', ') : '-'}</STooltipTableCell>
      <STooltipTableCell>{timeUtil.formatAsDisplayDateTime(shareDatetime)}</STooltipTableCell>
    </FlexLayout>
  );
};

export default ShareActivityRow;
