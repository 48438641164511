import { FlexContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../common/components/overlays/modal/Modal';

export type Props = { show: boolean; onHide: () => void; onDeleteNotes: () => void };

const DeleteNotesConfirmationModal: React.FC<Props> = ({ show, onHide, onDeleteNotes }) => {
  return (
    <Modal show={show} onHide={onHide} title="Delete Notes">
      <Modal.Content>Are you sure you want to delete Stabilization Notes</Modal.Content>
      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton testId="delete-button" onClick={onDeleteNotes}>
            Delete
          </PrimaryButton>
          <SecondaryButton testId="cancel-button" onClick={onHide}>
            Cancel
          </SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteNotesConfirmationModal;
