import { checkPermissions, permissionsByEntity } from '@cmg/auth';

const SYNDICATE_ACCESS_PERMISSIONS = [
  permissionsByEntity.SalesCredit.READ,
  permissionsByEntity.Trade.READ,
  permissionsByEntity.RetailDemand.READ,
  permissionsByEntity.RetailRetention.READ,
  permissionsByEntity.OrderBook.READ,
  permissionsByEntity.Designation.READ,
  permissionsByEntity.Expense.READ,
  permissionsByEntity.Stabilization.READ,
  permissionsByEntity.Accounting.READ,
  permissionsByEntity.Letter.READ,
  permissionsByEntity.RegulatoryFiling.READ,
  permissionsByEntity.SyndicateWire.READ,
];

export function hasOfferingDetailsPermissions(userPermissions: string[]) {
  const hasPublishedOfferingAccess = checkPermissions(userPermissions, [
    permissionsByEntity.PublishedOffering.READ,
  ]);
  const hasOfferingAccess = checkPermissions(userPermissions, [permissionsByEntity.Offering.READ]);
  const hasSyndicateAccess = checkPermissions(userPermissions, SYNDICATE_ACCESS_PERMISSIONS, false);
  return hasOfferingAccess || (hasPublishedOfferingAccess && hasSyndicateAccess);
}
