import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { useFeatureToggles } from '../../../../../../../../../../common/config';
import {
  IndicationStatus,
  IndicationStatus as IndicationStatusType,
} from '../../../../../../../../../../graphql';
import { Calendar_LiveCalendarOfferingsListDocument } from '../../../../../../../../../calendar/live-calendar/graphql';
import {
  IndicationActivity_BuySideRouteDocument,
  useIndicationActivity_BuySidePassOnOfferingMutation,
} from '../../../../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(IndicationActivity_BuySideRouteDocument)!,
  getOperationName(Calendar_LiveCalendarOfferingsListDocument)!,
];

export const usePassOnOffering = (
  offeringId: UUID,
  indicationStatus: IndicationStatus | null,
  onClose: () => void,
  onFinishEditing?: () => void
): [() => Promise<void>, boolean] => {
  const { isOrderBookSidePanelIndicationStateTransitionOn } = useFeatureToggles();
  const [pass, { loading: isPassing }] = useIndicationActivity_BuySidePassOnOfferingMutation();

  const handlePass = React.useCallback(async () => {
    try {
      await pass({
        variables: {
          offeringId,
          activateIfCancelled: isOrderBookSidePanelIndicationStateTransitionOn
            ? indicationStatus === IndicationStatusType.Cancelled
            : false,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
      SnackbarManager.success('Successfully passed on offering');
      onClose();
      onFinishEditing?.();
    } catch {
      SnackbarManager.error('Failed to pass on offering');
    }
  }, [
    indicationStatus,
    isOrderBookSidePanelIndicationStateTransitionOn,
    offeringId,
    onClose,
    onFinishEditing,
    pass,
  ]);

  return [handlePass, isPassing];
};
