import { xcSelectors } from '@cmg/e2e-selectors';

import routeFactory from '../../../../../../common/util/routeFactory';
import offeringSidePanelSellSideRouteFactory from '../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { CalendarGridRendererProps } from '../../../../calendar.model';
import { StyledLink } from './OfferingSidePanelLinkRenderer.styles';

/**
 * OfferingSidePanelLinkRenderer cell renderer that wraps the value with a link that opens the offering side panel
 */
export const OfferingSidePanelLinkRenderer: React.FC<CalendarGridRendererProps> = ({
  value,
  data,
  context,
}) => (
  <StyledLink
    data-test-id={xcSelectors.calendarOfferingSidePanelLink.testId}
    to={routeFactory.offeringSidePanel.getUrlPath({
      offeringId: data.id,
      prefixRoutePath: context.matchUrl,
      sidePanelRoutePath: offeringSidePanelSellSideRouteFactory.offeringDetails.getUrlPath({}),
    })}
  >
    {value ?? '-'}
  </StyledLink>
);
