import { UUID } from '@cmg/common';

import { OrderBookPersonaType } from '../../../../../hooks/useIdentifyOrderBookPersona';
import { useProspectusDeliveryComplianceQuery } from '../../../../prospectus/hooks/useProspectusDeliveryComplianceQuery';
import { ProspectusDeliveryModel } from '../ProspectusDelivery.model';

export type Props = {
  personaType: OrderBookPersonaType;
  offeringId: UUID;
  cmgEntityKey: string | undefined;
};

const supportedPersonas = [OrderBookPersonaType.SALES_AND_TRADING, OrderBookPersonaType.SYNDICATE];

export const useProspectusDeliverySellSideModel = ({
  personaType,
  offeringId,
  cmgEntityKey,
}: Props): ProspectusDeliveryModel => {
  const canRead = !!cmgEntityKey && supportedPersonas.includes(personaType);
  const isSyndicatePersona = personaType === OrderBookPersonaType.SYNDICATE;

  const sellSideComplianceQuery = useProspectusDeliveryComplianceQuery({
    offeringId,
    cmgEntityKey,
    canRead,
    isSyndicatePersona,
  });

  const prospectusDeliveryHistory = isSyndicatePersona
    ? sellSideComplianceQuery.data?.prospectusDelivery.syndicateHistory ?? null
    : sellSideComplianceQuery.data?.prospectusDelivery.salesAndTradingHistory ?? null;

  return {
    canRead,
    loading: sellSideComplianceQuery.loading,
    error: sellSideComplianceQuery.error,
    prospectusDeliveryHistory,
    isSyndicatePersona,
  };
};
