import { Option } from '@cmg/common';

import { IndicationStatus, InstitutionalIndicationOrderType } from '../../graphql';

export const institutionalIndicationOrderTypeLabels = {
  [InstitutionalIndicationOrderType.Limit]: 'Limit',
  [InstitutionalIndicationOrderType.Market]: 'Market',
  [InstitutionalIndicationOrderType.Pass]: 'Pass',
  [InstitutionalIndicationOrderType.Scaled]: 'Scaled',
};

export const institutionalIndicationOrderTypeOptions = Object.values(
  InstitutionalIndicationOrderType
).map(
  orderType =>
    ({
      label: institutionalIndicationOrderTypeLabels[orderType],
      value: orderType,
    } as Option<InstitutionalIndicationOrderType>)
);

export const institutionalIndicationInterestLevelOrderTypeOptions =
  institutionalIndicationOrderTypeOptions.filter(
    option =>
      option.value === InstitutionalIndicationOrderType.Limit ||
      option.value === InstitutionalIndicationOrderType.Market
  );

export const indicationStatusLabels = {
  [IndicationStatus.Active]: 'Active',
  [IndicationStatus.Cancelled]: 'Cancelled',
  [IndicationStatus.Duplicate]: 'Duplicate',
};

export const indicationStatusOptions = Object.values(IndicationStatus).map<
  Option<IndicationStatus>
>(status => ({
  label: indicationStatusLabels[status],
  value: status as IndicationStatus,
}));
