import { Alert } from '@cmg/common';
import React from 'react';

export const NoActionNeededAlert: React.FC = () => {
  return (
    <Alert severity="success" withMargin={true}>
      <Alert.Title>No further action needed at this time</Alert.Title>
    </Alert>
  );
};
