import { ListItem, ListItemButton } from '@cmg/design-system';
import React from 'react';

import { UserTemplate } from '../../../hooks/useTemplatesState';
import ListItemKebabMenu from '../kebab-menu/ListItemKebabMenu';

export type Props = Readonly<{
  template: UserTemplate;
  onRename: () => void;
  onDelete: () => void;
  onSave: () => void;
  onSaveAsNew: () => void;
  onReset: () => void;
}>;

const UserTemplateListItem: React.FC<Props> = ({
  template,
  onDelete,
  onRename,
  onSaveAsNew,
  onReset,
  onSave,
}) => {
  const { name, active } = template;

  const actions = React.useMemo(
    () => [
      ...(active
        ? [
            {
              label: 'Reset',
              onClick: onReset,
            },
          ]
        : []),
      {
        label: 'Save',
        onClick: onSave,
      },
      {
        label: 'Save as New',
        onClick: onSaveAsNew,
      },
      {
        label: 'Rename',
        onClick: onRename,
      },
      {
        label: 'Delete',
        onClick: onDelete,
      },
    ],
    [active, onReset, onSave, onRename, onDelete, onSaveAsNew]
  );
  return (
    <ListItem
      disableGutters
      aria-label={name}
      secondaryAction={
        <ListItemKebabMenu actions={actions} aria-label={`${name} saved view actions`} />
      }
    >
      <ListItemButton onClick={onReset} selected={active} sx={{ borderRadius: 1 }}>
        {name}
      </ListItemButton>
    </ListItem>
  );
};

export default UserTemplateListItem;
