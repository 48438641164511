import { useFeatureToggles } from '../../../../../../common/config';
import usePollingWhenVisible from '../../../../../../common/hooks/usePollingWhenVisible';
import { useWithOrderBook_IndicationChangedLazySubscription } from '../../../../../../features/order-book/graphql/__generated__';
import {
  InstitutionalDemandGrid_SummaryQueryVariables,
  useInstitutionalDemandGrid_SummaryLazyQuery,
} from '../graphql';
import { InstitutionalFinalAllocation } from '../types';

export type InstitutionalFinalAllocationMap = {
  [key: string]: InstitutionalFinalAllocation;
};

export const DEMAND_SUMMARY_GRID_POLL_INTERVAL_MS = 5_000;

export const useInstitutionalDemandGridSummaryLazyQuery = ({
  offeringId,
}: Pick<InstitutionalDemandGrid_SummaryQueryVariables, 'offeringId'>) => {
  const { isOrderBookDataRefreshOn, isOrderBookInstitutionalDemandGridSubscriptionOn } =
    useFeatureToggles();

  const pollInterval =
    isOrderBookDataRefreshOn && !isOrderBookInstitutionalDemandGridSubscriptionOn
      ? DEMAND_SUMMARY_GRID_POLL_INTERVAL_MS
      : 0;

  let lazyQueryTuple = useInstitutionalDemandGrid_SummaryLazyQuery({
    fetchPolicy: isOrderBookInstitutionalDemandGridSubscriptionOn
      ? 'cache-and-network'
      : 'no-cache',
  });

  lazyQueryTuple = useWithOrderBook_IndicationChangedLazySubscription(lazyQueryTuple, {
    variables: () => ({ offeringId }),
    loadingStateBehavior: 'false-on-refetch',
    isEnabled: isOrderBookInstitutionalDemandGridSubscriptionOn,
    updateQuery: refetch => prev => refetch(prev),
  });

  const [, { called, stopPolling, startPolling }] = lazyQueryTuple;
  usePollingWhenVisible({
    pollInterval,
    called,
    stopPolling,
    startPolling,
    isEnabled: !isOrderBookInstitutionalDemandGridSubscriptionOn,
  });

  return lazyQueryTuple;
};
