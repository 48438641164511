import { UUID } from '@cmg/common';
import {
  ActionPanel,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  RestoreIcon,
  Stack,
  TableSkeleton,
  Typography,
} from '@cmg/design-system';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../../../common/util/routeFactory';
import { OrderBook_TotalRetailSummaryItemManagerPartsFragment } from '../../../graphql';
import { useOrderBook_RetailDemandHistoryQuery } from './graphql';
import TotalRetailDemandHistoryTable from './history-table/TotalRetailDemandHistoryTable';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; cmgEntityKey: UUID }>;
export type Props = RouteProps & {
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
};

const TotalRetailDemandHistoryActionPanel: React.FC<Props> = ({ match, history, allManagers }) => {
  const { offeringId, cmgEntityKey } = match.params;

  const currentManager = React.useMemo(() => {
    return allManagers.find(manager => manager.cmgEntityKey === cmgEntityKey)!;
  }, [allManagers, cmgEntityKey]);

  const { data, loading, error } = useOrderBook_RetailDemandHistoryQuery({
    variables: { offeringId, cmgEntityKey },
  });

  const handleClose = React.useCallback(() => {
    history.push(routeFactory.orderBookTotalRetailDemand.getUrlPath({ offeringId }));
  }, [history, offeringId]);

  return (
    <ActionPanel
      open
      dismissible
      onClose={handleClose}
      menu={
        <Stack gap={1}>
          <Box>
            <Typography p={2} variant="h3">
              Retail Demand
            </Typography>

            <Divider />
          </Box>

          <List>
            <ListItemButton component="li" selected>
              <ListItemIcon>
                <RestoreIcon />
              </ListItemIcon>
              <ListItemText>Update History</ListItemText>
            </ListItemButton>
          </List>
        </Stack>
      }
      header={currentManager.name}
    >
      <Stack mx={1} gap={1}>
        <Box>
          <Typography variant="h3" px={1} py={2}>
            Update History
          </Typography>

          <Divider />
        </Box>

        {error && <ServerErrorsBanner error={error} />}

        {loading && <TableSkeleton />}

        {data && (
          <TotalRetailDemandHistoryTable
            rows={data.retailDemandHistory}
            allManagers={allManagers}
          />
        )}
      </Stack>
    </ActionPanel>
  );
};

export default TotalRetailDemandHistoryActionPanel;
