import React from 'react';

import { ValidationErrorGroup } from '../validation-error-group/ValidationErrorGroup';
import { ValidationErrorGroupList } from '../validation-error-group-list/ValidationErrorGroupList';
import { ValidationErrorSectionHeader } from '../validation-error-section-header/ValidationErrorSectionHeader';
import { ValidationBySteps } from '../wire-validation/WireValidation.model';
import {
  managersValidationSortFn,
  offeringValidationSortFn,
} from './OfferingValidationErrorSection.model';

export type Props = {
  title: string;
  urlPath: string;
  offeringValidation: ValidationBySteps;
  managersValidation: ValidationBySteps;
  hasPermission: boolean;
};

export const OfferingValidationErrorSection: React.FC<Props> = ({
  offeringValidation,
  managersValidation,
  urlPath,
  title,
  hasPermission,
}) => {
  const templateFieldsEntries = Object.entries(offeringValidation).sort(offeringValidationSortFn);
  const managersEntries = Object.entries(managersValidation).sort(managersValidationSortFn);

  return (
    <div>
      <ValidationErrorSectionHeader urlPath={urlPath} title={title} hasPermission={hasPermission} />
      <ValidationErrorGroupList>
        {templateFieldsEntries.map(([step, fields]) => (
          <ValidationErrorGroup key={step} step={step} fields={fields} />
        ))}
        {!!managersEntries.length && (
          <li>
            Managers:
            <ValidationErrorGroupList>
              {managersEntries.map(([step, fields]) => (
                <ValidationErrorGroup key={step} step={step} fields={fields} />
              ))}
            </ValidationErrorGroupList>
          </li>
        )}
      </ValidationErrorGroupList>
    </div>
  );
};
