import {
  SyndicateWires_UpdatePricingTermsWireMutation,
  useSyndicateWires_UpdatePricingTermsWireMutation,
} from '../graphql';

export type UpdatePricingTermsWireMutation = SyndicateWires_UpdatePricingTermsWireMutation;

export const useUpdatePricingTermsWireMutation = () => {
  return useSyndicateWires_UpdatePricingTermsWireMutation();
};
