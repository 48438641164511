import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { ViewMode } from '../../constants';
import OfferingNoteBar from './components/offering-note-bar/OfferingNoteBar';
import { offeringNoteFormInitialValues, offeringNoteFormSubjectOptions } from './constants';
import {
  StyledFormContainer,
  StyledSelectField,
  StyledTextAreaField,
} from './OfferingNoteForm.styles';
import { OfferingNoteFormValidationSchema } from './OfferingNoteFormValidationSchema';
import { OfferingNoteFormValues } from './types';

type OfferingNoteFormProps = {
  handleChangeViewMode: (newViewMode: ViewMode) => void;
  viewMode: ViewMode;
  loading?: boolean;
  handleSaveNote: (values: OfferingNoteFormValues) => void;
  handleDeleteNote: () => void;
  initialValues?: OfferingNoteFormValues;
};

const OfferingNoteForm: React.FC<OfferingNoteFormProps> = ({
  loading,
  handleChangeViewMode,
  viewMode,
  handleSaveNote,
  handleDeleteNote,
  initialValues = offeringNoteFormInitialValues,
}) => {
  const formik = useFormik<OfferingNoteFormValues>({
    initialValues,
    validationSchema: OfferingNoteFormValidationSchema,
    onSubmit: (values, formikBag) => {
      handleSaveNote(values);
      formikBag.resetForm();
    },
    enableReinitialize: true,
  });

  const renderFormContent = () => {
    switch (viewMode) {
      case ViewMode.Create:
      case ViewMode.Edit:
        return (
          <StyledFormContainer>
            <StyledSelectField
              name="subject"
              label="Topic"
              options={offeringNoteFormSubjectOptions}
              fullWidth
            />
            <StyledTextAreaField name="note" label="Note" fullWidth resize="none" />
          </StyledFormContainer>
        );

      case ViewMode.Delete:
        return (
          <StyledFormContainer>
            <p>Are you sure you want to remove the offering note?</p>
          </StyledFormContainer>
        );

      default:
        return null;
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form data-test-id={xcSelectors.offeringNotesForm.testId}>
        <OfferingNoteBar
          viewMode={viewMode}
          handleChangeViewMode={handleChangeViewMode}
          loading={loading}
          handleConfirmDeleteNote={handleDeleteNote}
          handleSubmitForm={formik.submitForm}
        />
        {!loading && renderFormContent()}
      </Form>
    </FormikProvider>
  );
};

export default OfferingNoteForm;
