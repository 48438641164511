import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { DropdownButton, SuccessButton, Toggle } from '@cmg/common';
import React from 'react';

import { View, viewButtons } from './CalendarFilters.model';
import { SFiltersWrapper, SLeftSideControls, SRightSideControls } from './CalendarFilters.styles';

export type Props = {
  view: View;
  onViewChange: (view: View) => void;
  onCreateMeeting: () => void;
  onCreateLogistics: () => void;
};

const CalendarFilters: React.FC<Props> = ({
  onViewChange,
  onCreateMeeting,
  onCreateLogistics,
  view,
  children,
}) => {
  const isCoordinator = useCheckPermissions([permissionsByEntity.Roadshow.FULL]);

  return (
    <SFiltersWrapper>
      <SLeftSideControls>
        <Toggle
          value={view}
          options={viewButtons}
          onChange={value => onViewChange(value as View)}
        />
        <div>{children}</div>
      </SLeftSideControls>

      {isCoordinator && (
        <SRightSideControls>
          {/* TODO <span /> element is a workaround for DropdownButton being a block element (div) by default */}
          <span>
            <DropdownButton
              testId="create event dropdown button"
              buttonComponent={SuccessButton}
              options={[
                {
                  testId: 'create a new meeting event option',
                  label: 'Meeting',
                  onClick: onCreateMeeting,
                },
                {
                  testId: 'create a new logistics event option',
                  label: 'Logistics',
                  onClick: onCreateLogistics,
                },
              ]}
              popoverVariant="LIGHT"
            >
              Create
            </DropdownButton>
          </span>
        </SRightSideControls>
      )}
    </SFiltersWrapper>
  );
};

export default CalendarFilters;
