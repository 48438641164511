import { TableCell, TableHead, TableRow } from '@cmg/design-system';
import React, { VFC } from 'react';

export const RecipientsTableHead: VFC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Broker Dealers</TableCell>
        <TableCell align="right">Retention (Shares)*</TableCell>
      </TableRow>
    </TableHead>
  );
};
