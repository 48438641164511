import { MutationHookOptions } from '@apollo/client';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_SetPricingInstrumentDocument,
  OfferingSetup_SetPricingInstrumentMutation,
  OfferingSetup_SetPricingInstrumentMutationVariables,
} from '../graphql';

export const useSetPricingInstrumentMutation = (
  options: MutationHookOptions<
    OfferingSetup_SetPricingInstrumentMutation,
    OfferingSetup_SetPricingInstrumentMutationVariables
  >
) =>
  useServiceMutation<
    OfferingSetup_SetPricingInstrumentMutation,
    OfferingSetup_SetPricingInstrumentMutationVariables
  >(OfferingSetup_SetPricingInstrumentDocument, options);
