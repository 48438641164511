import styled from 'styled-components/macro';

import Modal from '../../../../../../common/components/overlays/modal/Modal';
import Section from '../../../../common/components/section/Section';

export const StyledSectionContent = styled(Section.Content)`
  height: 460px;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  & > * {
    margin: 0 4px;
  }
`;
