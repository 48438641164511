import { Icon, SolidIconName } from '@cmg/common';
import React, { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components/macro';

import { BannerVariant, getBannerIconName, getIconColor } from './Banner.model';
import { SContainer, SIconContainer, SInnerContainer, STitle } from './Banner.styles';

export type Props = PropsWithChildren<{
  variant: BannerVariant;
  testId?: string;
  withoutMargin?: boolean;
  className?: string;
  iconName?: SolidIconName;
  padding?: string;
  label?: string;
}>;

const Banner: React.FC<Props> = ({
  variant,
  children,
  testId,
  withoutMargin,
  className,
  iconName,
  padding,
  label,
}) => {
  const theme = useTheme();

  return (
    <SContainer
      role="alert"
      aria-label={label}
      variant={variant}
      data-test-id={testId}
      withoutMargin={withoutMargin}
      className={className}
      padding={padding}
    >
      <SIconContainer>
        <Icon
          variant="solid"
          name={iconName ?? getBannerIconName(variant)}
          color={getIconColor(theme, variant)}
        />
      </SIconContainer>
      <SInnerContainer>{children}</SInnerContainer>
    </SContainer>
  );
};

export default Object.assign(Banner, { Title: STitle });
