import { ApolloError } from '@apollo/client';
import { useCheckAccountTraits } from '@cmg/auth';
import {
  FormField,
  Icon,
  Option,
  Select,
  SelectField,
  TextInputField,
  UnsavedChangesGuard,
} from '@cmg/common';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { ExchangeMic } from '../../../graphql';
import { exchangeEnum } from '../../constants';
import SetupForm from '../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../components/form/OfferingSetupFormActions';
import useIntlOptions from '../hooks/useIntlOptions';
import {
  SFraction,
  SFractionWrapper,
  SSelected,
  StyledActions,
  StyledFormField,
  StyledNumericInputField,
  StyledSwitchColumn,
  StyledSwitchField,
} from './DeliveryInstrumentForm.styles';
import { OfferingSetup_DeliveryInstrumentPartsFragment } from './graphql';

export const DeliveryInstrumentSchema = yup.object().shape({
  currencyCode: yup
    .string()
    .nullable()
    .required('Currency Code is required')
    .length(3, 'Currency Code should be a 3 letter ISO code'),
  countryCode: yup
    .string()
    .nullable()
    .required('Country Code is required')
    .default('US')
    .length(2, 'Country Code should be a 2 letter ISO code'),
  depositaryReceiptConversionRatioNumerator: yup
    .number()
    .nullable()
    .positive('Numerator must be more than zero'),
  depositaryReceiptConversionRatioDenominator: yup
    .number()
    .nullable()
    .positive('Denominator must be more than zero'),
  stockSymbol: yup.string().nullable().label('Symbol').max(6),
  isin: yup
    .string()
    .nullable()
    .matches(/^(|[A-Z0-9]{12})$/, 'ISIN should be a 12 character alphanumeric code'),
  cusip: yup
    .string()
    .nullable()
    .matches(/^(|[A-Z0-9]{9})$/, 'CUSIP should be a 9 character alphanumeric code'),
  figi: yup
    .string()
    .nullable()
    .matches(/^(|[A-Z0-9]{12})$/, 'FIGI should be a 12 character alphanumeric code'),
  exchangeMic: yup
    .mixed<ExchangeMic>()
    .nullable()
    .oneOf([...Object.values(ExchangeMic), null]),
});

type FormType = {
  currencyCode: string;
  countryCode: string;
  isDepositaryReceipt: boolean;
  depositaryReceiptConversionRatioNumerator: number | null;
  depositaryReceiptConversionRatioDenominator: number | null;
  stockSymbol: string | null;
  isin: string | null;
  cusip: string | null;
  figi: string | null;
  exchangeMic: ExchangeMic | null;
};

export type OwnProps = {
  testId?: string;
  isPricingInstrument?: boolean;
  adding?: boolean;
  editing?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
  currencyOptions?: Option[];
  exchangeOptions?: Option[];
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit: (payload: FormType) => void;
  data?: OfferingSetup_DeliveryInstrumentPartsFragment;
};

export type Props = OwnProps & FormikProps<FormType>;

export const DeliveryInstrumentFormComponent: React.FC<OwnProps> = ({
  testId,
  isPricingInstrument,
  loading,
  editing,
  disabled,
  onEdit,
  onCancel,
  onDelete,
  onSubmit,
  data,
  currencyOptions,
  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const formikOptions = {
    initialValues: {
      currencyCode: data?.currencyCode || 'USD',
      countryCode: data?.countryCode ?? 'US',
      isDepositaryReceipt: data?.isDepositaryReceipt || false,
      depositaryReceiptConversionRatioNumerator:
        data?.depositaryReceiptConversionRatio?.numerator || null,
      depositaryReceiptConversionRatioDenominator:
        data?.depositaryReceiptConversionRatio?.denominator || null,
      stockSymbol: data?.stockSymbol || '',
      isin: data?.isin || '',
      cusip: data?.cusip || '',
      figi: data?.figi || '',
      exchange: data?.exchange || null,
      exchangeMic: data?.exchangeMic || null,
    },
    validationSchema: DeliveryInstrumentSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: formValues => {
      onSubmit({
        ...formValues,
        stockSymbol: formValues.stockSymbol || null,
        isin: formValues.isin || null,
        cusip: formValues.cusip || null,
        figi: formValues.figi || null,
        exchange: null,
        exchangeMic:
          formValues.exchangeMic && Object.values(ExchangeMic).includes(formValues.exchangeMic)
            ? formValues.exchangeMic
            : null,
      });
    },
  };

  const formik = useFormik(formikOptions);

  const { values, errors, dirty, resetForm, setFieldValue } = formik;

  const actionProps = {
    loading: editing && loading,
    editing,
    disabled,
    disableDelete: isPricingInstrument,
    onEdit,
    onCancel,
    onDelete,
    onSubmit: formik.submitForm,
  };

  const isInternationalFeatureOn = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const { exchanges, countryCodes, currencyCodes } = useIntlOptions(values.countryCode);

  const exchangeOptions = isInternationalFeatureOn
    ? exchanges
    : Object.keys(exchangeEnum).map(key => {
        return { label: exchangeEnum[key], value: key };
      });

  const exchangeValueToDisplay = React.useMemo(() => {
    if (data?.exchangeMic) {
      return isInternationalFeatureOn
        ? exchanges.find(option => option.value === data.exchangeMic)?.label ?? '-'
        : exchangeEnum[data.exchangeMic];
    }

    return '-';
  }, [data?.exchangeMic, exchanges, isInternationalFeatureOn]);

  React.useEffect(() => {
    if (!values.isDepositaryReceipt) {
      setFieldValue('depositaryReceiptConversionRatioNumerator', null);
      setFieldValue('depositaryReceiptConversionRatioDenominator', null);
    }
  }, [values.isDepositaryReceipt, setFieldValue]);

  const onCountryCodeChange = React.useCallback(
    (value: string | null) => {
      setFieldValue('countryCode', value, true);
      isInternationalFeatureOn && setFieldValue('exchangeMic', null, true);
    },
    [setFieldValue, isInternationalFeatureOn]
  );

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
        <SetupForm.Card withBorder data-test-id={testId}>
          <SetupForm.CardHeader>
            {isPricingInstrument && (
              <SSelected>
                <Icon name="check" fixedWidth />
                Current Offering Pricing Instrument
              </SSelected>
            )}
            <StyledActions>
              <OfferingSetupFormActions {...actionProps} />
            </StyledActions>
          </SetupForm.CardHeader>
          <SetupForm.Row>
            <SetupForm.Column>
              {editing ? (
                <SelectField
                  fullWidth
                  withMargin
                  required
                  name="currencyCode"
                  label="Currency Code"
                  options={isInternationalFeatureOn ? currencyCodes : currencyOptions}
                  isClearable={false}
                />
              ) : (
                <StyledFormField fullWidth withMargin label="Currency Code">
                  {data?.currencyCode}
                </StyledFormField>
              )}
            </SetupForm.Column>
            <SetupForm.Column>
              {editing ? (
                <FormField
                  fullWidth
                  withMargin
                  required
                  error={errors?.countryCode}
                  label="Country Code"
                >
                  <Select
                    isClearable={false}
                    hasError={errors?.countryCode != null}
                    options={countryCodes}
                    onChange={onCountryCodeChange}
                    value={values?.countryCode}
                    renderOption={option => <span>{option.label}</span>}
                  />
                </FormField>
              ) : (
                <StyledFormField fullWidth withMargin label="Country Code">
                  {data?.countryCode}
                </StyledFormField>
              )}
            </SetupForm.Column>
            <SetupForm.Column>
              {editing ? (
                <SelectField
                  fullWidth
                  withMargin
                  name="exchangeMic"
                  label="Exchange"
                  options={exchangeOptions}
                  placeholder="Exchange"
                  isClearable={true}
                />
              ) : (
                <StyledFormField fullWidth withMargin label="Exchange">
                  {exchangeValueToDisplay}
                </StyledFormField>
              )}
            </SetupForm.Column>
            <SetupForm.Column>
              {editing ? (
                <TextInputField
                  fullWidth
                  withMargin
                  name="stockSymbol"
                  placeholder="Symbol"
                  label="Symbol"
                />
              ) : (
                <StyledFormField fullWidth withMargin label="Symbol">
                  {data?.stockSymbol}
                </StyledFormField>
              )}
            </SetupForm.Column>
          </SetupForm.Row>
          <SetupForm.Row>
            <SetupForm.Column>
              {editing ? (
                <TextInputField fullWidth withMargin name="isin" placeholder="ISIN" label="ISIN" />
              ) : (
                <StyledFormField fullWidth withMargin label="ISIN">
                  {data?.isin || '-'}
                </StyledFormField>
              )}
            </SetupForm.Column>
            <SetupForm.Column>
              {editing ? (
                <TextInputField
                  fullWidth
                  withMargin
                  name="cusip"
                  placeholder="CUSIP"
                  label="CUSIP"
                />
              ) : (
                <StyledFormField fullWidth withMargin label="CUSIP">
                  {data?.cusip || '-'}
                </StyledFormField>
              )}
            </SetupForm.Column>
            <SetupForm.Column>
              {editing ? (
                <TextInputField fullWidth withMargin name="figi" placeholder="FIGI" label="FIGI" />
              ) : (
                <StyledFormField fullWidth withMargin label="FIGI">
                  {data?.figi || '-'}
                </StyledFormField>
              )}
            </SetupForm.Column>
          </SetupForm.Row>

          <SetupForm.Row>
            <StyledSwitchColumn>
              <FormField label="Depositary Receipt">
                <StyledSwitchField
                  name="isDepositaryReceipt"
                  disabled={!editing}
                  checkedContent="Yes"
                  unCheckedContent="No"
                />
              </FormField>
            </StyledSwitchColumn>
            <SetupForm.Column>
              {editing ? (
                <FormField label="Depositary Receipt Conversion Rate">
                  <SFractionWrapper>
                    <StyledNumericInputField
                      fullWidth
                      disabled={!values.isDepositaryReceipt}
                      name="depositaryReceiptConversionRatioNumerator"
                    />
                    <SFraction>/</SFraction>
                    <StyledNumericInputField
                      fullWidth
                      disabled={!values.isDepositaryReceipt}
                      name="depositaryReceiptConversionRatioDenominator"
                    />
                  </SFractionWrapper>
                </FormField>
              ) : (
                <FormField fullWidth withMargin label="Depositary Receipt Conversion Ratio">
                  <SFractionWrapper>
                    {!data?.depositaryReceiptConversionRatio?.numerator ? (
                      '-'
                    ) : (
                      <React.Fragment>
                        {data?.depositaryReceiptConversionRatio?.numerator}
                        <SFraction>/</SFraction>
                        {data?.depositaryReceiptConversionRatio?.denominator}
                      </React.Fragment>
                    )}
                  </SFractionWrapper>
                </FormField>
              )}
            </SetupForm.Column>
          </SetupForm.Row>
        </SetupForm.Card>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default DeliveryInstrumentFormComponent;
