import {
  SyndicateWires_UpdateIssuerAllocationWireMutation,
  useSyndicateWires_UpdateIssuerAllocationWireMutation,
} from '../graphql';

export type UpdateIssuerAllocationWireMutation = SyndicateWires_UpdateIssuerAllocationWireMutation;

export const useUpdateIssuerAllocationWireMutation = () => {
  return useSyndicateWires_UpdateIssuerAllocationWireMutation();
};
