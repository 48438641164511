import { FetchResult } from '@apollo/client';
import { useAuth } from '@cmg/auth';
import {
  ButtonsContainer,
  FlexContainer,
  FormLabel,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import Modal from '../../../../../../../common/components/overlays/modal/Modal';
import { useInstitutionalDemand_UpdateFinalAllocationsMutation } from '../../../update-final-institutional-allocations-modal/graphql';
import { InstitutionalDraftSet, InstitutionalFinalSet } from '../../types';
import { ServiceErrorBanner } from './components/ServiceErrorBanner';
import { ValidationErrorsBanner } from './components/ValidationErrorsBanner';
import { validationSchema, Values } from './EditAllocationsModal.model';
import {
  SError,
  SPrompt,
  StyledCheckboxField,
  StyledNumericInputField,
} from './EditAllocationsModal.styles';
import { useInstitutionalDemandGrid_UpdateDraftAllocationsMutation } from './graphql';

type OwnProps = {
  selectedIndicationIds: string[];
  draftSets: readonly InstitutionalDraftSet[];
  finalSet: InstitutionalFinalSet | null;
  offeringId: string;
};

export type Props = OwnProps & InjectedProps;

export const EditAllocationsModal: React.FC<Props> = ({
  handleHide,
  selectedIndicationIds,
  draftSets,
  finalSet,
  offeringId,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const [updateDraftAllocations] = useInstitutionalDemandGrid_UpdateDraftAllocationsMutation();
  const [updateFinalAllocations] = useInstitutionalDemand_UpdateFinalAllocationsMutation();
  const [showFailedToUpdateBanner, setShowFailedToUpdateBanner] = React.useState(false);
  const [failedToUpdateSets, setFailedToUpdateSets] = React.useState<string[]>([]);

  const defaultDraftSet = draftSets.find(set => set.isDefault);
  const displayFinalSet = finalSet && finalSet.authorCmgEntityKey === oidcUserCmgEntityKey;

  const processedResults = useCallback(
    (
      results: PromiseSettledResult<FetchResult>[],
      values: Values,
      selectedDraftAllocationSetIds: UUID[]
    ) => {
      const failedSets: string[] = [];
      results.forEach((result, index) => {
        if (result.status !== 'rejected') {
          return;
        }

        if (index === 0 && values.finalAllocations) {
          failedSets.push('Final Allocation');
        } else {
          const failedToUpdateSet = draftSets.find(
            set =>
              set.id === selectedDraftAllocationSetIds[index - (values.finalAllocations ? 1 : 0)]
          );
          if (failedToUpdateSet) {
            failedSets.push(failedToUpdateSet?.name!);
          }
        }
      });
      setFailedToUpdateSets(failedSets);
    },
    [draftSets]
  );

  const formik = useFormik<Values>({
    validationSchema,
    initialValues: {
      allocations: null,
      draftAllocations: draftSets.length === 1 && !finalSet ? { [defaultDraftSet?.id!]: true } : {},
      finalAllocations: null,
    },
    onSubmit: async values => {
      try {
        const selectedDraftAllocationSetIds = Object.keys(values.draftAllocations ?? []).filter(
          id => (values.draftAllocations ? values.draftAllocations[id] : false)
        );

        const allocations = selectedIndicationIds.map(indicationId => {
          return { allocation: { shareQuantity: values.allocations }, indicationId };
        });

        const updateDraftAllocationPromises = selectedDraftAllocationSetIds.map(id => {
          return updateDraftAllocations({
            variables: {
              offeringId,
              setId: id,
              input: {
                allocations,
              },
            },
          });
        });

        const updateFinalAllocationPromises = values.finalAllocations
          ? [
              updateFinalAllocations({
                variables: {
                  offeringId,
                  input: {
                    allocations,
                  },
                },
              }),
            ]
          : [];

        const results = await Promise.allSettled([
          ...updateFinalAllocationPromises,
          ...updateDraftAllocationPromises,
        ]);

        const errors = results.filter(result => result.status === 'rejected');

        processedResults(results, values, selectedDraftAllocationSetIds);

        if (errors.length === 0) {
          ToastManager.success(
            `${selectedIndicationIds.length} Allocations saved to ${
              selectedDraftAllocationSetIds.length + (values.finalAllocations ? 1 : 0)
            } sets`
          );
          setShowFailedToUpdateBanner(false);
        } else {
          setShowFailedToUpdateBanner(true);
        }
      } catch (error) {
        ToastManager.error('Failed to update allocation sets');
      }
    },
  });

  const { submitForm, isValid, errors } = formik;

  const handleSubmitForm = () => {
    if (isValid) {
      submitForm();
      handleHide();
    }
  };

  return (
    <Modal
      show
      title="Enter Allocations"
      onHide={handleHide}
      shouldCloseOnOverlayClick={false}
      size="small"
    >
      <Modal.Content>
        {!isValid && <ValidationErrorsBanner errors={errors} />}
        {showFailedToUpdateBanner && <ServiceErrorBanner errors={failedToUpdateSets} />}
        <SPrompt>Please enter an allocation amount and select the set(s) to apply it to.</SPrompt>
        <FlexContainer direction="column" gap={24}>
          <FormikProvider value={formik}>
            <Form>
              <StyledNumericInputField
                name="allocations"
                label="Allocations"
                placeholder="Enter Allocations"
                required
              />
              <FlexContainer direction="column" gap={4} alignItems="flex-start">
                <FormLabel required>Allocation Set</FormLabel>
                {displayFinalSet && (
                  <StyledCheckboxField name="finalAllocations">
                    Final Allocation Set
                  </StyledCheckboxField>
                )}
                {draftSets
                  .filter(({ authorCmgEntityKey }) => {
                    return authorCmgEntityKey === oidcUserCmgEntityKey;
                  })
                  .map(({ name, id }) => (
                    <StyledCheckboxField
                      name={`draftAllocations[${id!}]`}
                      disabled={draftSets.length === 1 && !finalSet}
                      key={id}
                    >
                      {name}
                    </StyledCheckboxField>
                  ))}
                {(errors.draftAllocations || errors.finalAllocations) && (
                  <SError> Select at least one </SError>
                )}
              </FlexContainer>
            </Form>
          </FormikProvider>
        </FlexContainer>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right" margin={16}>
          <SecondaryButton onClick={handleHide} testId="cancel-button">
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm} testId="save-button">
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

const name = 'ORDERBOOK/EDIT_ALLOCATIONS_MODAL';
export const openEditAllocationsModal = () => show(name);
export const closeEditAllocationsModal = () => hide(name);

export default connectModal({ name })(EditAllocationsModal) as React.ComponentClass<OwnProps>;
