import { dateTimeUtil } from '@cmg/common';

import type { DemandGridServerColDef } from '../../types';
import { getValueFormatter } from '../columns.model';

/**
 * Exported for testing purposes only
 */
export const valueFormatter = getValueFormatter<string>({
  gridRow: ({ value }) => (value ? dateTimeUtil.getDisplayValueForDateAndTime(value) : '-'),
});

const createdAtDolDef: DemandGridServerColDef<string> = {
  type: 'date',
  field: 'auditInfo.createdAt',
  enableRowGroup: true,
  headerName: 'Submitted On',
  filter: 'agDateColumnFilter',
  valueFormatter,
};

export default createdAtDolDef;
