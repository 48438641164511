import { textUtil } from '@cmg/common';

import { RolodexRecordStatus } from '../../../../graphql';
import { serializeFirmKey } from '../../../util/rolodex/firm-key-type.util';
import { InvestorFirmSearch_InvestorFirmBasicPartsFragment } from './graphql';
import { InvestorFirmOption } from './InvestorFirmSearch';

export const getInvestorFirmOptions = (
  firms: readonly InvestorFirmSearch_InvestorFirmBasicPartsFragment[],
  fallbackToFirmKey: boolean
) => {
  return firms
    .filter(investor => investor.status !== RolodexRecordStatus.Deleted)
    .map<InvestorFirmOption>(investor => {
      const location = investor.address1 || investor.address2;

      return {
        value: investor.cmgEntityKey ?? (fallbackToFirmKey ? serializeFirmKey(investor) : null),
        label: investor.name,
        firmType: investor.firmType,
        firmKey: investor.firmKey ? textUtil.sanitizeWhiteSpaces(investor.firmKey) : null,
        firmId: investor.id,
        location: location ? textUtil.sanitizeWhiteSpaces(location) : null,
        status: investor.status,
      };
    });
};
