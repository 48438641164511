import {
  Alert,
  FlexContainer,
  SecondaryButton,
  SuccessButton,
  ToastManager,
  urlUtil,
  UUID,
} from '@cmg/common';
import { Typography } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import capitalize from 'lodash/capitalize';
import React, { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SelectRecipientsDialog from '../../../../../../../common/components/dialogs/select-recipients-dialog/SelectRecipientsDialog';
import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { filterRecipientsWithMissingEmails } from '../../../../../../../common/context/ContactSupportModalContext';
import { useContactSupportModalContext } from '../../../../../../../common/context/hooks/useContactSupportModalContext';
import { useCheckSyndicateWiresAccess } from '../../../../../../../common/util/check-access/useCheckSyndicateWiresAccess';
import { OfferingType } from '../../../../../../../graphql';
import { syndicateWireManagerRoleLabels } from '../../../../../../../types/domain/syndicate-wires/constants';
import useContactSupportDialog from '../../../../../../regulatory-filings/hooks/useContactSupportDialog';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useManagersMissingEmail } from '../../../hooks/useManagersMissingEmail';
import { useSendWireManagers } from '../../../hooks/useSendWireManagers';
import { DisclaimerContextProvider } from '../../dialogs/create-or-update-wire-dialog/context/DisclaimerContext';
import { CreateOrUpdateWireDialog } from '../../dialogs/create-or-update-wire-dialog/CreateOrUpdateWireDialog';
import { CCMyFirmSection } from '../../dialogs/send-wire-dialog/CCMyFirmSection';
import { useWireRecipientsDialog } from '../../dialogs/send-wire-dialog/hooks/useWireRecipientsDialog';
import MenuHeading from '../../menu-heading/MenuHeading';
import { OperationType as CreateUpdateOperation } from '../../modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import {
  OperationType,
  wasWireAlreadySent,
} from '../../modals/send-wire-modal/SendWireModal.model';
import SyndicateWireContent from '../../syndicate-wire-content/SyndicateWireContent';
import { SyndicateWireHeader } from '../../syndicate-wire-header/SyndicateWireHeader';
import { SyndicateWiresErrorBanner } from '../../syndicate-wires-error-banner/SyndicateWiresErrorBanner';
import { MissingDistributionListBanner } from '../../validation/missing-distribution-list-banner/MissingDistributionListBanner';
import { SentStageWireTemplate } from './SentStageWireTemplate';

export type Props = {
  offeringId: UUID;
  offeringType: OfferingType;
  syndicateWireId: UUID;
  managerId?: string;
  managers: SyndicateWireManager[];
  nextRecipients: SyndicateWireManager[];
};

export const SentStage: React.FC<Props> = ({
  offeringId,
  offeringType,
  syndicateWireId,
  managerId,
  managers,
  nextRecipients,
}) => {
  const { location, push } = useHistory();
  const { operation } = urlUtil.queryParse<{ operation?: OperationType }>(location.search);
  const { canManage } = useCheckSyndicateWiresAccess(offeringId);
  const managersWithMissingEmail = filterRecipientsWithMissingEmails(
    managers,
    useManagersMissingEmail({ offeringId })
  );

  const { open: openSupportModal } = useContactSupportModalContext();

  const {
    wireTypeName,
    wireTypeRoute,
    wireTypeCategory,
    useWireDetails,
    useWireList,
    ManagersNavigation,
    useSendMutation,
    useIsWireManagerListOutdated,
  } = useWireTypeConfigContext();
  const hasManagersNotInOffering = useIsWireManagerListOutdated({
    offeringId,
    syndicateWireId,
  });

  const { data: wireDetails, error: wireDetailsError } = useWireDetails({
    offeringId,
    syndicateWireId,
  });
  const wireMessages = wireDetails?.wire.wireMessages;

  const {
    data: wireList,
    isLoading: isLoadingWireList,
    error: wireListError,
  } = useWireList({
    offeringId,
  });
  const isNewestVersion = wireList?.[wireList?.length - 1].id === syndicateWireId;
  const isWireObsolete = !isNewestVersion || !!wireMessages?.some(message => message.expiredAt);

  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [shouldCCMyFirm, setShouldCCMyFirm] = React.useState(false);

  const isSendButtonDisabled = React.useMemo(() => {
    return isWireObsolete || managers.every(manager => wasWireAlreadySent(manager, wireMessages));
  }, [managers, wireMessages, isWireObsolete]);

  const recipients = useSendWireManagers({ managers, wire: wireDetails?.wire, offeringId });
  const managersMissingEmail = filterRecipientsWithMissingEmails(
    managers,
    useManagersMissingEmail({ offeringId })
  );

  const {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen: isRecipientsDialogOpen,
    open: openRecipientsDialog,
    close: closeRecipientsDialog,
  } = useWireRecipientsDialog({ recipients, wireTypeCategory });

  const { handleContactSupport } = useContactSupportDialog({
    recipients,
    onOpen: closeRecipientsDialog,
    onClose: useCallback(
      () => (isRecipientsDialogOpen ? openRecipientsDialog() : undefined),
      [isRecipientsDialogOpen, openRecipientsDialog]
    ),
  });

  const { mutation: sendWire, isLoading: isSending } = useSendMutation();

  const handleOpenContactSupportModal = React.useCallback(() => {
    openSupportModal(managersMissingEmail, openRecipientsDialog);
    closeRecipientsDialog();
  }, [closeRecipientsDialog, managersMissingEmail, openRecipientsDialog, openSupportModal]);

  const handleSend = React.useCallback(async () => {
    try {
      await sendWire({
        offeringId,
        syndicateWireId: wireDetails?.wire!.id!,
        managerIds: selectedEnabledRecipientKeys,
        shouldAddSenderToCc: shouldCCMyFirm,
      });

      push(
        wireTypeRoute.getUrlPath({ offeringId, syndicateWireId: wireDetails?.wire!.id! }) +
          `?operation=${OperationType.SEND}`
      );

      closeRecipientsDialog();
    } catch {
      ToastManager.error(
        `An error has occurred while sending the ${wireTypeName} ${wireTypeCategory}. Please refresh the page and try again.`
      );
    }
  }, [
    sendWire,
    offeringId,
    wireDetails?.wire,
    selectedEnabledRecipientKeys,
    shouldCCMyFirm,
    push,
    wireTypeRoute,
    closeRecipientsDialog,
    wireTypeName,
    wireTypeCategory,
  ]);

  if (wireDetailsError || wireListError) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner loadedEntity={`the ${wireTypeName} ${wireTypeCategory}`} />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  return (
    <SyndicateWireContent>
      <SyndicateWireHeader title={wireTypeName}>
        {canManage && isNewestVersion && (
          <React.Fragment>
            <SecondaryButton
              onClick={() => setIsCreateModalOpen(true)}
              testId={xcSelectors.wiresSentStageCreateButton.testId}
            >
              Create New Version
            </SecondaryButton>
            {!isSendButtonDisabled && (
              <SuccessButton
                onClick={() => openRecipientsDialog()}
                testId={xcSelectors.wiresSentStageSendButton.testId}
              >
                Ready to Send
              </SuccessButton>
            )}
          </React.Fragment>
        )}
      </SyndicateWireHeader>

      <SyndicateWireContent.ContentSection>
        <FlexContainer direction="column" gap={8}>
          {isNewestVersion && hasManagersNotInOffering && (
            <Alert
              severity="warning"
              data-test-id={xcSelectors.wiresSentStageManagerListOutdatedBanner.testId}
            >
              <Alert.Title>The list of eligible recipients has been updated.</Alert.Title>
              <Alert.Details>
                Create a new version to view the most recent data or continue sending the wire to
                available recipients.
              </Alert.Details>
            </Alert>
          )}
          {isNewestVersion && managersWithMissingEmail.length > 0 && (
            <MissingDistributionListBanner
              onContactSupport={() => openSupportModal(managersWithMissingEmail)}
              firmNames={managersWithMissingEmail.map(manager => manager.firmName)}
            />
          )}
        </FlexContainer>
        <SyndicateWireContent.Body>
          <SyndicateWireContent.ManagerList>
            <MenuHeading>Recipients</MenuHeading>
            <ManagersNavigation
              managers={managers}
              offeringId={offeringId}
              syndicateWireId={syndicateWireId}
              wire={wireDetails?.wire}
              managerId={managerId}
              isNewestVersion={isNewestVersion}
            />
          </SyndicateWireContent.ManagerList>

          <SyndicateWireContent.Template>
            {!wireDetails?.wire || isLoadingWireList ? (
              <Loading />
            ) : (
              <SentStageWireTemplate
                managerId={managerId}
                wire={wireDetails?.wire}
                isWireObsolete={isWireObsolete}
                isSendDisabled={isSendButtonDisabled}
                operation={operation}
              />
            )}
          </SyndicateWireContent.Template>
        </SyndicateWireContent.Body>
      </SyndicateWireContent.ContentSection>
      {isCreateModalOpen && (
        <DisclaimerContextProvider>
          <CreateOrUpdateWireDialog
            onClose={() => setIsCreateModalOpen(false)}
            operationType={CreateUpdateOperation.CREATE}
            offeringId={offeringId}
            syndicateWireId={syndicateWireId}
            wire={wireDetails?.wire}
            nextRecipients={nextRecipients}
            offeringType={offeringType}
          />
        </DisclaimerContextProvider>
      )}
      {wireDetails?.wire && (
        <Fragment>
          {isRecipientsDialogOpen && (
            <SelectRecipientsDialog
              open={isRecipientsDialogOpen}
              recipients={selectableRecipients}
              onSelectionChange={handleRecipientSelectionChange}
              onCancel={() => closeRecipientsDialog()}
              onSubmit={handleSend}
              isSubmitting={isSending}
              submitButtonLabel="Send"
              disableSubmitWithoutSelectedRecipient={true}
              title={`Send ${wireTypeName} ${capitalize(wireTypeCategory)}`}
              message={
                <Typography>
                  {`Please select recipients you would like the ${wireTypeCategory} to be sent to:`}
                </Typography>
              }
              onContactSupport={handleContactSupport}
              ccmyfirm={
                <CCMyFirmSection
                  offeringId={offeringId}
                  onChange={() => setShouldCCMyFirm(!shouldCCMyFirm)}
                  onContactSupport={handleOpenContactSupportModal}
                  shouldCCMyFirm={shouldCCMyFirm}
                />
              }
              getManagerRoleLabel={role => syndicateWireManagerRoleLabels[role]}
              withDistributionList={wireTypeCategory === 'wire'}
            />
          )}
        </Fragment>
      )}
    </SyndicateWireContent>
  );
};
