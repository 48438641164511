import { useAuth, useCheckAccountTraits } from '@cmg/auth';
import { Option, ToastManager, UUID } from '@cmg/common';
import React, { useContext } from 'react';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import { getFeatureToggles } from '../../../../common/config/appSettings';
import { ManagerRole } from '../../../../graphql';
import SetupForm from '../../components/form/OfferingSetupForm';
import SetupScreen from '../../components/screen/OfferingSetupScreen';
import { useValidateOffering } from '../../validation/hooks/useValidateOffering';
import { ManagersOrder } from '../ManagersOrder.context';
import { useOfferingSetup_SyndicateQuery } from './graphql';
import { useCreateManagerMutation } from './hooks/useCreateManagerMutation';
import { useDeleteManagerMutation } from './hooks/useDeleteManagerMutation';
import { useUpdateManagerMutation } from './hooks/useUpdateManagerMutation';
import { useUpdateManagerResponsibilitiesMutation } from './hooks/useUpdateManagerResponsibilitiesMutation';
import { useUpdateManagerSequenceMutation } from './hooks/useUpdateManagerSequenceMutation';
import ManagerForm from './ManagerForm';
import ManagerResponsibilitiesForm from './ManagerResponsibilitiesForm';
import { createManagerInput, moveItemInArray, updateManagerInput } from './Managers.model';

export type Props = {
  offeringId: UUID;
};

/**
 * Offering setup - Managers/Managers configuration view
 */
export const Managers: React.FC<Props> = ({ offeringId }) => {
  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [addNew, setAddNew] = React.useState<boolean>(false);
  const [reorderingId, setReorderingId] = React.useState<string | null>(null);

  const { isMultipleUnderwriterEntitySupportOn } = getFeatureToggles();
  const { oidcUserCmgEntityKey } = useAuth();
  const hasCreateInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const handleAddNew = (active: boolean) => {
    setEditingId(null);
    setAddNew(active);
  };

  const handleEditing = (id: string | null) => {
    setAddNew(false);
    setEditingId(id);
  };

  const {
    data,
    loading,
    error,
    refetch: refetchSyndicateQuery,
  } = useOfferingSetup_SyndicateQuery({
    variables: { offeringId: offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const managers = data?.offering.syndicate.managers || [];
  const isAuthor = !!data?.offering?.isAuthor;

  const isEmptyList = managers.length === 0;

  const { setManagersOrder } = useContext(ManagersOrder);
  const { revalidate } = useValidateOffering(offeringId);

  const managerOptions = managers
    .filter(manager => manager.isParticipating && manager.role !== ManagerRole.SellingGroupMember)
    .map<Option>(manager => ({ label: manager.firmName, value: manager.cmgEntityKey }));

  const [createManager, { loading: creating, error: createError }] = useCreateManagerMutation({
    onCompleted: () => {
      refetchSyndicateQuery();
      revalidate();
      handleAddNew(false);
      ToastManager.success('Created Manager');
    },
    onError: () => {
      ToastManager.error('Failed to create Manager');
    },
  });

  const [updateManager, { loading: updating, error: updateError }] = useUpdateManagerMutation({
    onCompleted: () => {
      handleEditing(null);
      setAddNew(false);
      revalidate();
      ToastManager.success('Updated Manager');
    },
    onError: () => {
      ToastManager.error('Failed to update Manager');
    },
  });

  const [deleteManager, { loading: deleting, error: deleteError }] = useDeleteManagerMutation({
    onCompleted: () => {
      handleEditing(null);
      setAddNew(false);
      revalidate();
      refetchSyndicateQuery();
      ToastManager.success('Removed Manager');
    },
    onError: () => {
      ToastManager.error('Failed to remove Manager');
    },
  });

  const [updateManagerSequence, { loading: updatingSequence, error: updateSequenceError }] =
    useUpdateManagerSequenceMutation({
      onCompleted: () => {
        refetchSyndicateQuery();
        setReorderingId(null);
      },
    });

  const [
    updateManagerResponsibilities,
    { loading: updatingResponsibilities, error: updateResponsibilitiesError },
  ] = useUpdateManagerResponsibilitiesMutation({
    onCompleted: () => {
      revalidate();
      ToastManager.success('Updated Manager Responsibilities');
    },
  });

  const handleReorder = (id: string, direction: number) => {
    // if we are already reordering, get unsaved, otherwise start w/ fresh data
    // get the index based on the id
    const itemIndex = managers.findIndex(d => d.cmgEntityKey === id);

    // move the item up or down within the array
    const reorderedManagers = moveItemInArray(
      managers,
      itemIndex,
      itemIndex + direction // up or down -1/+1
    );

    const cmgEntityKeys = reorderedManagers.map(d => d.cmgEntityKey);

    setManagersOrder(cmgEntityKeys);

    updateManagerSequence({
      variables: {
        offeringId,
        input: { cmgEntityKeys },
      },
    });

    // highlight row we moved with fancy ui tricks
    setReorderingId(id);
    // remove highlight animation with null
    setTimeout(() => setReorderingId(null), 500);
  };

  if (loading) {
    return (
      <SetupForm title="Managers">
        <SetupForm.Table>
          <SetupScreen.LoadingWrapper>
            <Spinner show fullHeight />
          </SetupScreen.LoadingWrapper>
        </SetupForm.Table>
      </SetupForm>
    );
  }

  const errors = error || createError || updateError || deleteError || updateSequenceError;

  return (
    <React.Fragment>
      {errors && <ServerErrorsBanner error={errors} />}
      <SetupForm title="Managers" testId="manager-section">
        <SetupForm.Table>
          {managers.map((manager, index) => {
            const isAuthor = manager.cmgEntityKey === oidcUserCmgEntityKey;
            const canDelete = !isAuthor || isMultipleUnderwriterEntitySupportOn;
            return (
              <ManagerForm
                key={manager.cmgEntityKey}
                data={manager}
                loading={updating || deleting}
                disabled={editingId !== null && editingId !== manager.cmgEntityKey}
                reordered={reorderingId === manager.cmgEntityKey}
                editing={editingId === manager.cmgEntityKey}
                reordering={updatingSequence}
                onMoveUp={index > 0 ? () => handleReorder(manager.cmgEntityKey, -1) : undefined}
                onMoveDown={
                  index < managers.length - 1
                    ? () => handleReorder(manager.cmgEntityKey, 1)
                    : undefined
                }
                onEdit={
                  isAuthor && !hasCreateInternationalTrait
                    ? undefined
                    : () => handleEditing(manager.cmgEntityKey)
                }
                onCancel={() => handleEditing(null)}
                onDelete={
                  canDelete
                    ? () =>
                        deleteManager({
                          variables: { offeringId, cmgEntityKey: manager.cmgEntityKey },
                        })
                    : undefined
                }
                onSubmit={values => {
                  updateManager({
                    variables: {
                      offeringId,
                      cmgEntityKey: manager.cmgEntityKey,
                      input: updateManagerInput(values),
                    },
                  });
                }}
                hasCreateInternationalTrait={hasCreateInternationalTrait}
                isAuthor={isAuthor}
              />
            );
          })}
          {(addNew || isEmptyList) && (
            <ManagerForm
              adding
              editing
              loading={creating}
              onCancel={() => handleAddNew(false)}
              hasCreateInternationalTrait={hasCreateInternationalTrait}
              onSubmit={values => {
                createManager({
                  variables: {
                    offeringId,
                    input: createManagerInput(values),
                  },
                });
              }}
            />
          )}
        </SetupForm.Table>
        {!updatingSequence && !addNew && !isEmptyList && !loading && (
          <SetupScreen.LinkButton
            fullWidth={false}
            iconLeft={{ name: 'plus' }}
            onClick={() => handleAddNew(true)}
          >
            Add New Manager
          </SetupScreen.LinkButton>
        )}
      </SetupForm>
      <SetupForm title="Manager Responsibilities">
        <ManagerResponsibilitiesForm
          isAuthor={isAuthor}
          loading={updatingResponsibilities}
          error={updateResponsibilitiesError}
          managerOptions={managerOptions}
          responsibilities={data?.offering.syndicate.managerResponsibilities}
          onSubmit={values =>
            updateManagerResponsibilities({ variables: { offeringId, input: values } })
          }
        />
      </SetupForm>
    </React.Fragment>
  );
};

export default Managers;
