import styled from 'styled-components/macro';

export const SAcknowledgementLayout = styled.div`
  padding: 0 11px;
  align-items: center;
`;

export const SDuplicateAcknowledgement = styled.div`
  padding: 0 11px;
  align-items: center;
`;

export const SSpacer = styled.span`
  margin-right: 3px;
`;

export const SAcknowledgement = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
