import { ISODateTime, LinkButton, timeUtil } from '@cmg/common';
import React from 'react';

import { StyledFlexContainer } from './ProspectusDocument.styles';

export type Props = {
  fileName: string;
  url: string;
  publishedAt: ISODateTime;
};

export const ProspectusDocument: React.FC<Props> = ({ fileName, url, publishedAt }) => {
  return (
    <StyledFlexContainer direction="row">
      <LinkButton inline href={url} target="_blank">
        {fileName}
      </LinkButton>
      Published: {timeUtil.formatAsDisplayDateTime(publishedAt)}
    </StyledFlexContainer>
  );
};
