import { DropdownButton, IconButton, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import {
  GridTransitionSalesCreditReleaseStatus,
  InternalSalesCreditReleaseStatus,
} from '../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';
import {
  ReleaseSalesCreditsFunction,
  selectRowsByAllowedTransition,
  UpdateSalesCreditsStatusFunction,
} from './SalesCreditsGridActions.model';
import { SSelectedLabel, StyledFlexContainer } from './SalesCreditsGridActions.styles';

export type Props = {
  selectedRows: SalesCredits_SalesCreditItemPartsFragment[];
  onRelease: ReleaseSalesCreditsFunction;
  isReleasing: boolean;
  onUpdateStatus: UpdateSalesCreditsStatusFunction;
  isUpdatingStatus: boolean;
};

const SalesCreditsGridActions: React.FC<Props> = ({
  selectedRows,
  onRelease,
  isReleasing,
  onUpdateStatus,
  isUpdatingStatus,
}) => {
  const theme = useTheme();

  const handleCancel = () => {
    const [keysToChange, unchangedRows] = selectRowsByAllowedTransition(
      selectedRows,
      GridTransitionSalesCreditReleaseStatus.CancelPending,
      ({ salesCreditsId }) => salesCreditsId!
    );
    onUpdateStatus(InternalSalesCreditReleaseStatus.CancelPending, keysToChange, unchangedRows);
  };

  const handleBookedManual = () => {
    const [keysToChange, unchangedRows] = selectRowsByAllowedTransition(
      selectedRows,
      GridTransitionSalesCreditReleaseStatus.BookedManual,
      ({ salesCreditsId }) => salesCreditsId!
    );
    onUpdateStatus(InternalSalesCreditReleaseStatus.BookedManual, keysToChange, unchangedRows);
  };

  const handleCancelledManual = () => {
    const [keysToChange, unchangedRows] = selectRowsByAllowedTransition(
      selectedRows,
      GridTransitionSalesCreditReleaseStatus.CancelledManual,
      ({ salesCreditsId }) => salesCreditsId!
    );
    onUpdateStatus(InternalSalesCreditReleaseStatus.CancelledManual, keysToChange, unchangedRows);
  };

  const handleReleaseSalesCredits = () => {
    const [keysToRelease, unchangedRows] = selectRowsByAllowedTransition(
      selectedRows,
      GridTransitionSalesCreditReleaseStatus.Released,
      ({ investorFirmCmgEntityKey }) => investorFirmCmgEntityKey
    );
    onRelease(keysToRelease, unchangedRows);
  };

  return (
    <StyledFlexContainer direction="row" gap={8} aria-label="Sales Credits grid action panel">
      <SSelectedLabel>{selectedRows.length} Selected</SSelectedLabel>

      <SecondaryButton onClick={handleCancel} loading={isUpdatingStatus}>
        Cancel Release
      </SecondaryButton>

      <PrimaryButton onClick={handleReleaseSalesCredits} loading={isReleasing}>
        Release Sales Credits
      </PrimaryButton>

      <DropdownButton
        buttonComponent={() => (
          <IconButton
            icon={{
              name: 'ellipsis-v',
              variant: 'regular',
              size: '1x',
            }}
            color={theme.designSystem.colors.darkBlue[200]}
          />
        )}
        options={[
          {
            testId: 'mark-booked-manual',
            label: 'Mark as Booked (Manual)',
            onClick: handleBookedManual,
          },
          {
            testId: 'mark-cancelled-manual',
            label: 'Mark as Cancelled (Manual)',
            onClick: handleCancelledManual,
          },
        ]}
        popoverVariant="LIGHT"
      >
        More
      </DropdownButton>
    </StyledFlexContainer>
  );
};

export default SalesCreditsGridActions;
