import unique from 'lodash/uniq';
import React from 'react';

import {
  RecipientListManagerRole,
  recipientsToRoleGroupedEntries,
} from '../../../lists/recipient-list/RecipientList.model';

export type Recipient<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly cmgEntityKey: string;
  readonly distributionList: readonly string[];
  readonly firmName: string;
  readonly isDisabled: boolean;
  readonly isSelected: boolean;
  readonly role: TManagerRole;
}>;

export type RecipientRoleGroup<TManagerRole extends RecipientListManagerRole> = Readonly<{
  isDisabled: boolean;
  isIndeterminate: boolean;
  isSelected: boolean;
  role: TManagerRole;
}>;

export type Props<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly recipients: Recipient<TManagerRole>[];
  readonly onSelectionChange: (selectedRecipients: string[]) => void;
}>;

const useSelectRecipients = <TManagerRole extends RecipientListManagerRole>({
  recipients,
  onSelectionChange,
}: Props<TManagerRole>) => {
  const selectedRecipients = React.useMemo(
    () =>
      recipients.filter(recipient => recipient.isSelected).map(recipient => recipient.cmgEntityKey),
    [recipients]
  );

  const groupedRecipients = React.useMemo<
    [RecipientRoleGroup<TManagerRole>, Recipient<TManagerRole>[]][]
  >(() => {
    const grouped = recipientsToRoleGroupedEntries<TManagerRole, Recipient<TManagerRole>>(
      recipients
    );

    return grouped.map(([role, roleRecipients]) => {
      const isRoleDisabled = roleRecipients.every(recipient => recipient.isDisabled);
      const isRoleSelected = roleRecipients.some(recipient => recipient.isSelected);
      const isRoleIndeterminate =
        isRoleSelected && roleRecipients.some(recipient => !recipient.isSelected);

      return [
        {
          role,
          isSelected: isRoleSelected,
          isIndeterminate: isRoleIndeterminate,
          isDisabled: isRoleDisabled,
        },
        roleRecipients,
      ];
    });
  }, [recipients]);

  const isSelected = React.useMemo(
    () => recipients.some(recipient => recipient.isSelected),
    [recipients]
  );

  const isIndeterminate = React.useMemo(
    () => isSelected && recipients.some(recipient => !recipient.isSelected),
    [isSelected, recipients]
  );

  const isDisabled = React.useMemo(() => {
    return recipients.every(recipient => recipient.isDisabled);
  }, [recipients]);

  const handleSelectRole = (role: Recipient<TManagerRole>['role']) => {
    const selectedRoleRecipients = recipients
      .filter(recipient => recipient.role === role && !recipient.isDisabled)
      .map(recipient => recipient.cmgEntityKey);

    onSelectionChange(unique([...selectedRecipients, ...selectedRoleRecipients]));
  };

  const handleUnselectRole = (role: Recipient<TManagerRole>['role']) => {
    const roleRecipients = recipients
      .filter(recipient => recipient.role === role)
      .map(recipient => recipient.cmgEntityKey);
    const nextSelectedRecipients = selectedRecipients.filter(key => !roleRecipients.includes(key));

    onSelectionChange(nextSelectedRecipients);
  };

  const handleToggleSelectRole = (role: Recipient<TManagerRole>['role']) => {
    const [group] = groupedRecipients.find(([group]) => group.role === role) ?? [];

    // Non-existing role group
    if (!group) {
      return;
    }

    group.isSelected ? handleUnselectRole(role) : handleSelectRole(role);
  };

  const handleToggleSelectAll = () => {
    const hasSelectedEnabledRecipients = recipients.some(
      recipient => recipient.isSelected && !recipient.isDisabled
    );

    const nexSelectedRecipients = recipients.reduce<string[]>((acc, curr) => {
      const isSelectable =
        (curr.isSelected && curr.isDisabled) || (!hasSelectedEnabledRecipients && !curr.isDisabled);

      return isSelectable ? [...acc, curr.cmgEntityKey] : acc;
    }, []);

    onSelectionChange(unique(nexSelectedRecipients));
  };

  const handleToggleSelectRecipient = (cmgEntityKey: string) => {
    const isSelected = selectedRecipients.includes(cmgEntityKey);

    if (isSelected) {
      onSelectionChange(selectedRecipients.filter(key => key !== cmgEntityKey));
    } else {
      onSelectionChange(unique([...selectedRecipients, cmgEntityKey]));
    }
  };

  return {
    isSelected,
    isIndeterminate,
    isDisabled,
    groupedRecipients,
    handleToggleSelectAll,
    handleToggleSelectRole,
    handleToggleSelectRecipient,
  };
};

export default useSelectRecipients;
