import { ValueFormatterParams } from 'ag-grid-community';

import { getFeatureToggles } from '../../../../common/config/appSettings';

export const getMappedLabelRenderer = (labelMap: {
  [key: string]: string;
}): ((params: ValueFormatterParams) => string) => {
  return ({ value }) => {
    let displayValue = '-';
    if (typeof value === 'string' && value in labelMap) {
      displayValue = labelMap[value];
    }

    return displayValue;
  };
};

export const getStringLabelRenderer = (
  labelFn: (input: string) => string | undefined | null
): ((params: ValueFormatterParams) => string) => {
  return ({ value }) => {
    let displayValue = '-';

    if (typeof value === 'string') {
      displayValue = labelFn(value) ?? displayValue;
    }

    return displayValue;
  };
};

export const optionalStringRenderer = ({ value }: { value?: string | null }): string =>
  value ?? '-';

export const getNumberLabelRenderer = (
  labelFn: (input: number) => string | undefined | null
): ((params: ValueFormatterParams) => string) => {
  return ({ value }) => {
    let displayValue = '-';

    if (typeof value === 'number') {
      displayValue = labelFn(value) ?? displayValue;
    }

    return displayValue;
  };
};

export const getInternationalString = (
  hasInternationalPermission: boolean,
  international: string,
  domestic?: string
) => {
  const { isInternationalDisplayOn } = getFeatureToggles();
  return isInternationalDisplayOn && hasInternationalPermission ? international : domestic;
};
