import { Option } from '@cmg/common';

import { FilingType, UseOfProceeds } from '../../../graphql';

export const filingTypeLabels = {
  [FilingType.Initial]: 'Initial',
  [FilingType.Revised]: 'Revised',
  [FilingType.Final]: 'Final',
};

export const filingTypeOptions = Object.values(FilingType).map(
  type =>
    ({
      label: filingTypeLabels[type],
      value: type,
    } as Option<FilingType>)
);

export const useOfProceedsLabels = {
  [UseOfProceeds.Acquisitions]: 'Acquisitions',
  [UseOfProceeds.CapitalExpenditures]: 'Capital Expenditures',
  [UseOfProceeds.DebtRepayment]: 'Debt Repayment',
  [UseOfProceeds.FutureAcquisitions]: 'Future Acquisitions',
  [UseOfProceeds.GeneralCorporatePurposes]: 'General Corporate Purposes',
  [UseOfProceeds.GrowthCapital]: 'Growth Capital',
  [UseOfProceeds.Investments]: 'Investments',
  [UseOfProceeds.NoProceedsToIssuer]: 'No Proceeds to Issuer',
  [UseOfProceeds.ResearchAndDevelopment]: 'Research and Development',
  [UseOfProceeds.ShareholderDividends]: 'Shareholder Dividends',
  [UseOfProceeds.ShareRepurchase]: 'Share Repurchase',
  [UseOfProceeds.WorkingCapital]: 'Working Capital',
};

export const useOfProceedsOptions = Object.values(UseOfProceeds).map(
  type =>
    ({
      label: useOfProceedsLabels[type],
      value: type,
    } as Option<UseOfProceeds>)
);
