import { FlexContainer } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import AverageBuybackPriceInfoBox from './average-buyback-price-info-box/AverageBuybackPriceInfoBox';
import { StyledFlexContainer } from './StabilizationPanel.styles';
import TotalGainLossInfoBox from './total-gain-loss-info-box/TotalGainLossInfoBox';
import TotalOverallotmentSharesInfoBox from './total-overallotment-shares-info-box/TotalOverallotmentSharesInfoBox';

const StabilizationPanel: React.FC = () => {
  return (
    <Panel>
      <Panel.Header title="Stabilization" />
      <Panel.Content>
        <FlexContainer direction="row" gap={16}>
          <TotalOverallotmentSharesInfoBox />

          <StyledFlexContainer direction="column" gap={8}>
            <AverageBuybackPriceInfoBox />

            <TotalGainLossInfoBox />
          </StyledFlexContainer>
        </FlexContainer>
      </Panel.Content>
    </Panel>
  );
};

export default StabilizationPanel;
