import { PrimaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import { StyledCancelButton } from './OpenToSalesConfirmModal.styles';

export type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
};

const OpenToSalesConfirmModal: React.FC<Props> = ({ onConfirm, show, onClose, loading }) => {
  return (
    <Modal
      title="Open Meeting to Sales"
      show={show}
      onHide={onClose}
      testId="confirm open to sales modal"
    >
      <Modal.Content>
        Would you like to open this Meeting to Sales? Deal Team will be notified of the opened
        meeting.
      </Modal.Content>
      <Modal.Footer>
        <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
        <PrimaryButton
          testId="confirm and open a meeting to sales button"
          loading={loading}
          onClick={onConfirm}
        >
          Confirm
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export default OpenToSalesConfirmModal;
