import {
  CurrencyInputField,
  DangerButton,
  Icon,
  NumericInputField,
  numericUtil,
  PercentInputField,
  SelectField,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import capitalize from 'lodash/capitalize';
import React from 'react';

import {
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
  OfferingType,
} from '../../../../../../../../../../graphql';
import {
  InterestLevelFormValues,
  InterestLevelProps,
} from '../../../../../../../../components/interest-levels-wrapper/InterestLevelsWrapper.model';
import LimitPriceInput from '../../../../../../../../components/limit-price-input/LimitPriceInput';
import { pricingTypeOptions, unitTypeOptions, unitTypeToFieldName } from './InterestLevel.model';
import {
  SColumn,
  SDeleteColumn,
  SIndicationLevelRow,
  SLimitPriceColumn,
} from './InterestLevel.styles';

type OwnProps = InterestLevelProps<
  MdlIndicationUnitType,
  MdlIndicationPricingType,
  MdlIndicationLimitType
> & {
  hasMarketPricingType?: boolean;
  interestLevel: InterestLevelFormValues<
    MdlIndicationUnitType,
    MdlIndicationPricingType,
    MdlIndicationLimitType
  >;
  offeringType: OfferingType;
  interestLevels: InterestLevelFormValues<
    MdlIndicationUnitType,
    MdlIndicationPricingType,
    MdlIndicationLimitType
  >[];
};

const InterestLevel: React.FC<OwnProps> = ({
  isEditing = false,
  isSubmitting = false,
  index,
  replace,
  remove,
  hasMarketPricingType,
  interestLevel,
  offeringType,
  interestLevels,
}) => {
  const baseName = `interestLevels[${index}]`;

  const handleChangeUnitType = (unitType: MdlIndicationUnitType) => {
    if (unitType !== interestLevel.unitType) {
      interestLevels.forEach((level, levelIndex) => {
        if (unitType !== level.unitType) {
          const quantity = !interestLevel.unitType ? interestLevel.quantity : null;
          replace(levelIndex, {
            ...level,
            realDemandDollars: null,
            realDemandPercentage: null,
            realDemandShares: null,
            [unitTypeToFieldName(unitType)]:
              quantity && !interestLevel.unitType && unitType === MdlIndicationUnitType.Percentage
                ? quantity / 100
                : quantity,
            unitType,
          });
        }
      });
    }
  };

  const handleChangePricingType = (pricingType: MdlIndicationPricingType) => {
    if (pricingType !== interestLevel.pricingType) {
      const [firstInterestLevel] = interestLevels;
      const unitType = index > 0 ? firstInterestLevel.unitType : interestLevel.unitType;

      replace(index, {
        ...interestLevel,
        limitPrice: null,
        limitPercentage: null,
        limitType:
          pricingType === MdlIndicationPricingType.Market ? MdlIndicationLimitType.Null : null,
        pricingType,
        unitType,
      });
    }
  };

  const handleChangeLimitType = (limitType: MdlIndicationLimitType) => {
    if (limitType !== interestLevel.limitType) {
      replace(index, {
        ...interestLevel,
        limitPrice: null,
        limitPercentage: null,
        limitType,
      });
    }
  };

  const renderUnitTypeSelect = () => {
    return (
      <SelectField
        name={`${baseName}.unitType`}
        placeholder="Select..."
        disabled={isSubmitting || index > 0}
        options={unitTypeOptions}
        fullWidth
        required
        isClearable={false}
        onChange={handleChangeUnitType}
      />
    );
  };

  const renderDemandQuantityView = () => {
    switch (interestLevel.unitType) {
      case MdlIndicationUnitType.Dollars:
        return numericUtil.formatCurrency(interestLevel.realDemandDollars);
      case MdlIndicationUnitType.Percentage:
        return numericUtil.formatPercents(interestLevel.realDemandPercentage, 0);
      case MdlIndicationUnitType.Shares:
        return numericUtil.formatNumber(interestLevel.realDemandShares, 0);
      default:
        return null;
    }
  };

  const renderDemandQuantityInput = () => {
    const baseProps = {
      disabled: isSubmitting,
      fullWidth: true,
      required: true,
    };

    switch (interestLevel.unitType) {
      case MdlIndicationUnitType.Dollars:
        return <CurrencyInputField {...baseProps} name={`${baseName}.realDemandDollars`} />;
      case MdlIndicationUnitType.Percentage:
        return (
          <PercentInputField
            {...baseProps}
            name={`${baseName}.realDemandPercentage`}
            precision={0}
          />
        );
      case MdlIndicationUnitType.Shares:
        return (
          <NumericInputField {...baseProps} name={`${baseName}.realDemandShares`} precision={0} />
        );
      default:
        return <NumericInputField {...baseProps} name={`${baseName}.quantity`} precision={0} />;
    }
  };

  const removeInterestLevel = () => {
    remove(index);
  };

  return (
    <SIndicationLevelRow data-test-id={xcSelectors.firmLevelDemandInterestLevel.testId}>
      <SColumn align="left">
        {isEditing ? renderDemandQuantityInput() : renderDemandQuantityView()}
      </SColumn>
      <SColumn align="left">
        {isEditing ? renderUnitTypeSelect() : capitalize(interestLevel.unitType!)}
      </SColumn>
      <SColumn align="left">
        {isEditing ? (
          <SelectField
            name={`${baseName}.pricingType`}
            placeholder="Select..."
            disabled={isSubmitting}
            options={pricingTypeOptions.filter(option => {
              // Hide the "Market" order type option if an interest level already has market
              // only one market order is allowed.
              return (
                !hasMarketPricingType ||
                (!!hasMarketPricingType &&
                  interestLevel.pricingType === MdlIndicationPricingType.Market) ||
                option.value !== MdlIndicationPricingType.Market
              );
            })}
            fullWidth
            required
            isClearable={false}
            onChange={handleChangePricingType}
          />
        ) : (
          capitalize(interestLevel.pricingType!)
        )}
      </SColumn>
      <SLimitPriceColumn align="left">
        <LimitPriceInput
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          interestLevel={interestLevel}
          offeringType={offeringType}
          baseName={baseName}
          handleChangeLimitType={handleChangeLimitType}
        />
      </SLimitPriceColumn>
      {isEditing && (
        <SDeleteColumn align="right">
          <DangerButton
            onClick={removeInterestLevel}
            testId={xcSelectors.firmLevelDemandInterestLevelDelete.testId}
          >
            <Icon name="trash-alt" />
          </DangerButton>
        </SDeleteColumn>
      )}
    </SIndicationLevelRow>
  );
};

export default InterestLevel;
