import { ToastManager } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useFeatureToggles } from '../../../../common/config';
import { SidePanelTitle } from '../../components/side-bar/components/design-system';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import ComplianceCertificates from './certificates/ComplianceCertificates';
import { SSectionWrapper } from './ComplianceRoute.styles';
import { ComplianceContextProvider } from './context/ComplianceContext';
import ComplianceProspectus from './prospectus/ComplianceProspectus';
import { useResendProspectusMutation } from './prospectus/hooks/useResendProspectusMutation';

export type Props = RouteComponentProps<{
  offeringId: string;
}>;

const ComplianceRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const { isOrderBookAttestationOn } = useFeatureToggles();

  const [resendProspectus, { loading: isResending }] = useResendProspectusMutation({ offeringId });

  const handleResendProspectus = async () => {
    try {
      const { validationError } = await resendProspectus({
        investorKeys: [cmgEntityKey!],
      });

      if (validationError) {
        ToastManager.error(validationError);
      } else {
        ToastManager.success('Prospectus was sent successfully.');
      }
    } catch {
      ToastManager.error('Prospectus was not sent. Required data missing.');
    }
  };

  return (
    <div>
      <SidePanelTitle>Compliance</SidePanelTitle>
      <ComplianceContextProvider offeringId={offeringId} cmgEntityKey={cmgEntityKey}>
        {isOrderBookAttestationOn && (
          <SSectionWrapper data-test-id="compliance-certificates-wrapper">
            <ComplianceCertificates offeringId={offeringId} />
          </SSectionWrapper>
        )}

        {/* We need to move into the persona centric design  when working on other personas */}
        <SSectionWrapper data-test-id="compliance-prospectus-wrapper">
          <ComplianceProspectus
            offeringId={offeringId}
            cmgEntityKey={cmgEntityKey!}
            onResendProspectus={handleResendProspectus}
            isResending={isResending}
          />
        </SSectionWrapper>
      </ComplianceContextProvider>
    </div>
  );
};

export default ComplianceRoute;
