import { Modal, SecondaryButton } from '@cmg/common';
import get from 'lodash/get';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { DynamicDataType } from '../../grid-columns/dynamic-columns/DynamicColumnRenderer';
import {
  InvestorExtendedDataQuery,
  useInvestorExtendedDataQuery,
} from '../../hooks/useInvestorExtendedDataQuery';
import { getSingleMappedExtendedData } from '../../InstitutionalDemandGrid.model';
import {
  SFooterContainer,
  SModalBodyContainer,
  STitleCategory,
  STitleContainer,
} from './InstitutionalDemandMenuModal.styles';

type ExtendedDataField = {
  investorExtendedData: InvestorExtendedDataQuery['firmInvestorsExtendedData'][number] | null;
};

type OwnProps = {
  investorName?: string;
  firmKey?: string | null;
  field?: string;
};

export type Props = OwnProps & InjectedProps;

export const NOT_AVAILABLE_MESSAGE_TITLE = 'Data not available';
export const NOT_AVAILABLE_MESSAGE_CONTENT =
  'This data is no longer available. Please refresh your browser.';

export const InstitutionalDemandInvestorExtendedDataModal: React.FC<Props> = ({
  investorName,
  handleHide,
  firmKey,
  field = '',
}) => {
  const { data: investorExtendedDataQuery, loading: investorExtendedDataLoading } =
    useInvestorExtendedDataQuery({
      firmKey,
    });
  const investorExtendedData = investorExtendedDataQuery?.firmInvestorExtendedData
    ? getSingleMappedExtendedData(investorExtendedDataQuery?.firmInvestorExtendedData)
    : null;
  const extendedData = get<ExtendedDataField, string, DynamicDataType | null>(
    { investorExtendedData },
    field,
    null
  );

  return (
    <Modal
      show
      title={
        <STitleContainer>
          {!investorExtendedDataLoading && (
            <React.Fragment>
              {!extendedData?.value && (
                <React.Fragment>
                  <div data-test-id="extended-data-investor-name">
                    {NOT_AVAILABLE_MESSAGE_TITLE}
                  </div>
                </React.Fragment>
              )}
              {extendedData?.value && (
                <React.Fragment>
                  <div data-test-id="extended-data-investor-name">{investorName}</div>
                  <STitleCategory data-test-id="extended-data-column-name">
                    {extendedData?.name}
                  </STitleCategory>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </STitleContainer>
      }
      onHide={handleHide}
      closeButton
      shouldCloseOnOverlayClick
      size="small"
      footer={
        <SFooterContainer>
          <SecondaryButton data-test-id="extended-data-close-button" onClick={handleHide}>
            Close
          </SecondaryButton>
        </SFooterContainer>
      }
    >
      <SModalBodyContainer data-test-id="investor-extended-data-content">
        {investorExtendedDataLoading && <Loading />}
        {!investorExtendedDataLoading && (extendedData?.value ?? NOT_AVAILABLE_MESSAGE_CONTENT)}
      </SModalBodyContainer>
    </Modal>
  );
};

const name = 'ORDERBOOK/DEMAND_GRID_INVESTOR_EXTENDED_DATA_MODAL';

export const openInstitutionalInvestorExtendedDataModal = (
  props: Pick<OwnProps, 'investorName' | 'firmKey' | 'field'>
) => show(name, props);

export const closeInstitutionalInvestorExtendedDataModal = () => hide(name);

export default connectModal({ name })(
  InstitutionalDemandInvestorExtendedDataModal
) as React.ComponentClass<OwnProps>;
