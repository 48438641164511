import { UUID } from '@cmg/common';
import isNil from 'lodash/isNil';

import { Maybe } from '../../../../../../../../../graphql';
import { ManagerShareInfo } from '../../../../types';
import { OrderBook_GetAllocationSetShareInformationQuery } from './graphql';

export type SyndicateManager = {
  cmgEntityKey: UUID;
  firmName: string;
  shareInfo: Maybe<Pick<ManagerShareInfo, 'isActive' | 'timestamp'>>;
};

export const getFirms = (
  authorCmgEntityKey: UUID,
  data: OrderBook_GetAllocationSetShareInformationQuery | undefined,
  sharedWithManagers: readonly ManagerShareInfo[]
): SyndicateManager[] => {
  const sharedWithManagersMap = new Map(
    sharedWithManagers.map(({ cmgEntityKey, isActive, timestamp }) => [
      cmgEntityKey,
      { isActive, timestamp },
    ])
  );

  const managersWithEntitlements =
    data?.publishedOffering.syndicateEntitlements.syndicateEntitlements
      .filter(({ entitlements }) => {
        return (
          entitlements.includes('READ:INSTITUTIONAL_ALLOCATION_DRAFT') &&
          !entitlements.includes('MANAGE:PASSIVE_ORDER_BOOK')
        );
      })
      .map(({ cmgEntityKey }) => cmgEntityKey) ?? [];

  return (
    data?.publishedOffering.syndicate.managers
      .filter(({ cmgEntityKey }) => {
        return (
          cmgEntityKey !== authorCmgEntityKey && managersWithEntitlements.includes(cmgEntityKey)
        );
      })
      .map(({ cmgEntityKey, firmName }) => {
        return {
          cmgEntityKey,
          firmName,
          shareInfo: sharedWithManagersMap.get(cmgEntityKey) ?? null,
        };
      }) ?? []
  );
};

export const getCheckedState = (values: ShareAllocationSetValues) => {
  const checkedCount = Object.values(values).filter(Boolean).length;
  const valuesCount = Object.entries(values).length;

  const isAllChecked = valuesCount === checkedCount;
  const isSomeChecked = checkedCount > 0 && !isAllChecked;

  return { isAllChecked, isSomeChecked };
};

export type ShareAllocationSetValues = Record<UUID, boolean>;

export const getInitialValues = (firms: SyndicateManager[]) => {
  return firms.reduce<ShareAllocationSetValues>((result, current) => {
    return {
      ...result,
      [current.cmgEntityKey]: !isNil(current.shareInfo) && current.shareInfo.isActive,
    };
  }, {});
};

export const getCheckedCmgEntityKeys = (values: ShareAllocationSetValues): UUID[] => {
  return Object.entries(values)
    .filter(([, isChecked]) => isChecked)
    .map(([cmgEntityKey]) => cmgEntityKey);
};

export const getToggledValues = (
  isCheckAllChecked: boolean,
  initialValues: ShareAllocationSetValues
) => {
  return Object.keys(initialValues).reduce(
    (result, cmgEntityKey) => ({
      ...result,
      [cmgEntityKey]: isCheckAllChecked,
    }),
    {}
  );
};
