import { TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../common/components/layout/panel/Panel';

const FilingCommonFieldsForm: React.FC = () => {
  return (
    <Panel testId={xcSelectors.regulatoryFilingsCommonForm.testId}>
      <Panel.Header title="Contact Information" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Firm Name"
              aria-label="Firm Name"
              name="firmName"
            />
          </Column>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Firm CRD Number"
              aria-label="Firm CRD Number"
              name="firmCrdNumber"
            />
          </Column>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Firm MPID"
              aria-label="Firm MPID"
              name="firmMpid"
            />
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Contact Name"
              aria-label="Contact Name"
              name="contactName"
            />
          </Column>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Contact Title"
              aria-label="Contact Title"
              name="contactTitle"
            />
          </Column>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Telephone Number"
              aria-label="Telephone Number"
              name="telephoneNumber"
            />
          </Column>
          <Column span={3}>
            <TextInputField
              withMargin
              fullWidth
              required
              label="Email Address"
              aria-label="Email Address"
              name="emailAddress"
            />
          </Column>
        </GridLayout>
      </Panel.Content>
    </Panel>
  );
};

export default FilingCommonFieldsForm;
