/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CreateMeetingMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  payload: Types.MeetingInput;
}>;

export type Roadshows_CreateMeetingMutation = {
  readonly __typename?: 'Mutation';
  readonly createMeeting: {
    readonly __typename?: 'MeetingDetails';
    readonly id: string;
    readonly date: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly timezone: string;
    readonly title: string;
    readonly location?: string | null;
    readonly address?: string | null;
    readonly venue?: string | null;
    readonly roomName?: string | null;
    readonly publicNotes?: string | null;
    readonly type: Types.MeetingType;
    readonly status: Types.EventStatus;
    readonly isPublic: boolean;
    readonly format: Types.MeetingFormat;
    readonly dialInDetails?: string | null;
    readonly hostPersonEmail?: string | null;
    readonly hostPersonFullName?: string | null;
    readonly hostFirmCmgEntityKey?: string | null;
  };
};

export const Roadshows_CreateMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CreateMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CreateMeetingMutationFn = Apollo.MutationFunction<
  Roadshows_CreateMeetingMutation,
  Roadshows_CreateMeetingMutationVariables
>;

/**
 * __useRoadshows_CreateMeetingMutation__
 *
 * To run a mutation, you first call `useRoadshows_CreateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CreateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCreateMeetingMutation, { data, loading, error }] = useRoadshows_CreateMeetingMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_CreateMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CreateMeetingMutation,
    Roadshows_CreateMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CreateMeetingMutation,
    Roadshows_CreateMeetingMutationVariables
  >(Roadshows_CreateMeetingDocument, options);
}
export type Roadshows_CreateMeetingMutationHookResult = ReturnType<
  typeof useRoadshows_CreateMeetingMutation
>;
export type Roadshows_CreateMeetingMutationResult =
  Apollo.MutationResult<Roadshows_CreateMeetingMutation>;
export type Roadshows_CreateMeetingMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_CreateMeetingMutation,
  Roadshows_CreateMeetingMutationVariables
>;
