import styled from 'styled-components/macro';

export const SContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SHeading = styled.div`
  display: flex;
`;

export const STitle = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  padding-right: 20px;
`;

export const SText = styled.div`
  max-width: 500px;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SAuditInfo = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.designSystem.colors.gray[300]};
`;
