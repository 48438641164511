import { numericUtil } from '@cmg/common';
import { AgGridEvent, GridApi } from 'ag-grid-community';
import React from 'react';

import { DesignationWarning } from '../../../../graphql';
import {
  FinalSettlement_DesignationsGridDesignationManagerFragment,
  FinalSettlement_DesignationsGridItemFragment,
} from '../common/graphql/__generated__';
import {
  rowContainsErrors,
  rowContainsNonApplicableWarning,
} from '../designation-monitor-details/designation-monitor-grid/components/warnings-renderer/DesignationMonitorWarningsRenderer.model';
import {
  DesignationMonitorColumns,
  DesignationRow,
} from '../designation-monitor-details/designation-monitor-grid/DesignationMonitorGrid.model';
import { OnSelectionChangeFn, SelectionFilter } from '../DesignationMonitorRoute.model';

export const getAllManagers = (
  syndicateManagers: readonly FinalSettlement_DesignationsGridDesignationManagerFragment[]
): DesignationManager[] => {
  return syndicateManagers.map(({ cmgEntityKey, name }) => ({ cmgEntityKey, cmgEntityName: name }));
};

export const getInvestorsDesignations = (
  allocationDesignations: readonly FinalSettlement_DesignationsGridItemFragment[],
  syndicateManagers: readonly FinalSettlement_DesignationsGridDesignationManagerFragment[]
): DesignationRow[] => {
  const managers = syndicateManagers.reduce((result, current) => {
    result[current.cmgEntityKey] = current.name;
    return result;
  }, {});

  return allocationDesignations.map(({ designations, allocation, warnings }) => {
    const { investorName, investorCmgEntityKey, shares, id } = allocation;

    const totalDesignations = numericUtil.sum(...designations.map(({ shares }) => shares));

    const isEdited = designations.some(
      ({ shares, fixedEconomicsShares }) => shares && shares !== fixedEconomicsShares
    );

    const investments = designations.reduce((result, designation) => {
      result[designation.managerCmgEntityKey] = {
        id: designation.managerCmgEntityKey,
        name: managers[designation.managerCmgEntityKey],
        fixedEconomicsShares: designation.fixedEconomicsShares,
        shareQuantity: designation.shares,
        percent: numericUtil.divide(designation.shares, shares),
      };
      return result;
    }, {});

    return {
      id: investorCmgEntityKey,
      warnings,
      investorName: investorName,
      shareQuantity: shares,
      totalDesignations,
      totalPercent: numericUtil.divide(totalDesignations, shares),
      indicationId: id,
      investments,
      isEdited,
    };
  });
};

export const getDesignationMonitorContext = (
  offeringId: string,
  allocationDesignations: readonly FinalSettlement_DesignationsGridItemFragment[],
  syndicateManagers: readonly FinalSettlement_DesignationsGridDesignationManagerFragment[]
): DesignationMonitorGridContext => {
  const managers = getAllManagers(syndicateManagers);
  const investorRows = getInvestorsDesignations(allocationDesignations, syndicateManagers);
  const noEconomicsApplied = areNoEconomicsApplied(allocationDesignations);

  return {
    offeringId,
    designations: allocationDesignations,
    managers,
    investorRows,
    noEconomicsApplied,
    containsErrors: designationsContainError(!noEconomicsApplied, investorRows),
    isPublished: allocationDesignations.every(item => item.isPublished),
  };
};

export function areNoEconomicsApplied(
  designations: readonly FinalSettlement_DesignationsGridItemFragment[] = []
): boolean {
  return designations.every(designation => !designation.version);
}

export function designationsContainError(
  economicsApplied: boolean,
  designations: DesignationRow[] = []
): boolean {
  return designations.some(
    designation =>
      economicsApplied &&
      !rowContainsNonApplicableWarning(designation.warnings) &&
      rowContainsErrors(designation.warnings)
  );
}

export const refreshSelectionCellParams = {
  force: true,
  columns: [DesignationMonitorColumns.SELECTED],
};

export const applySelectionFilter = (api: GridApi, values: SelectionFilter[]) => {
  if (values.includes('select-all')) {
    api.selectAll();
  }

  if (values.includes('unselect-all')) {
    api.deselectAll();
  }

  if (values.includes('balanced')) {
    api.forEachNode(node => {
      node.setSelected(
        !node.data.warnings.includes(DesignationWarning.DesignationsAreOutOfBalance)
      );
    });
  }

  if (values.includes('out-of-balance')) {
    api.forEachNode(node => {
      node.setSelected(node.data.warnings.includes(DesignationWarning.DesignationsAreOutOfBalance));
    });
  }

  if (values.includes('edited')) {
    api.forEachNode(node => {
      node.setSelected(node.data.isEdited);
    });
  }

  if (values.includes('unedited')) {
    api.forEachNode(node => {
      node.setSelected(!node.data.isEdited);
    });
  }

  api.refreshCells(refreshSelectionCellParams);
};

export const useSelectionFilterChange = (
  gridRef: React.MutableRefObject<AgGridEvent | undefined>
) => {
  return React.useCallback(
    (values: SelectionFilter[]) => {
      const api = gridRef.current?.api;
      if (!api) {
        return;
      }
      applySelectionFilter(api, values);
    },
    [gridRef]
  );
};

export type DesignationManager = {
  cmgEntityName: string;
  cmgEntityKey: string;
};

export type DesignationMonitorGridContext = {
  offeringId: string;
  designations: readonly FinalSettlement_DesignationsGridItemFragment[];
  managers: DesignationManager[];
  investorRows: DesignationRow[];
  noEconomicsApplied: boolean;
  containsErrors: boolean;
  onSelectionFilterChange?: OnSelectionChangeFn;
  isPublished: boolean;
};

export const useDesignationContext = (
  offeringId: string,
  allocationDesignations: readonly FinalSettlement_DesignationsGridItemFragment[] = [],
  syndicateManagers: readonly FinalSettlement_DesignationsGridDesignationManagerFragment[] = [],
  gridRef: React.MutableRefObject<AgGridEvent | undefined>
): DesignationMonitorGridContext => {
  const onSelectionFilterChange = useSelectionFilterChange(gridRef);

  return React.useMemo<DesignationMonitorGridContext>(() => {
    return {
      ...getDesignationMonitorContext(offeringId, allocationDesignations, syndicateManagers),
      onSelectionFilterChange,
    };
  }, [offeringId, allocationDesignations, syndicateManagers, onSelectionFilterChange]);
};
