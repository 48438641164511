import { numericUtil, UUID } from '@cmg/common';

import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../../../graphql';
import { DemandValues, RetentionValues } from '../table-row/TotalRetailDemandTableRow.model';

const { sum } = numericUtil;

export type TotalRetailDemandRow = {
  demand: DemandValues;
  retention: RetentionValues;
};

export type TotalRetailDemandValues = Record<UUID, TotalRetailDemandRow>;

export const getInitialValues = (
  initialValues: readonly OrderBook_TotalRetailSummaryItemPartsFragment[]
): TotalRetailDemandValues => {
  return initialValues.reduce<TotalRetailDemandValues>((acc, curr) => {
    acc[curr.manager.cmgEntityKey] = {
      demand: {
        demandShares: curr.demand.shareQuantity ?? null,
        roundLots: curr.demand.roundLots ?? null,
      },
      retention: {
        retentionShares: curr.retention.shareQuantity ?? null,
      },
    };
    return acc;
  }, {});
};

export const calculateTotals = (values: TotalRetailDemandValues) => {
  const rows = Object.values(values);
  const totalDemandShares = sum(...rows.map(({ demand }) => demand.demandShares));
  const totalRoundLots = sum(...rows.map(({ demand }) => demand.roundLots));
  const totalRetention = sum(...rows.map(({ retention }) => retention.retentionShares));

  return { totalDemandShares, totalRoundLots, totalRetention };
};
