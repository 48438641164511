import { ApolloCache } from '@apollo/client';

import { Roadshows_CreateRoadshowMutation, useRoadshows_CreateRoadshowMutation } from '../graphql';

export type CreateRoadshowMutation = Roadshows_CreateRoadshowMutation;

export const updateCache = (cache: ApolloCache<CreateRoadshowMutation>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'validateRoadshowName' });
};

export const useCreateRoadshowMutation = () => {
  return useRoadshows_CreateRoadshowMutation({
    // reset the cache of validation endpoint
    update: updateCache,
  });
};
