import { useField, useFormikContext } from 'formik';

import { EmailAddressesPicker, EmailAddressesPickerProps } from './EmailAddressesPicker';

export type FormikEmailAddressesPickerProps<TFormValues extends Record<string, unknown>> =
  Readonly<{
    readonly name: keyof TFormValues & string;
    readonly TextFieldProps: Omit<
      EmailAddressesPickerProps['TextFieldProps'],
      'error' | 'helperText' | 'name'
    >;
  }>;

export function FormikEmailAddressesPicker<TFormValues extends Record<string, unknown>>({
  name,
  TextFieldProps,
}: FormikEmailAddressesPickerProps<TFormValues>) {
  const [field, meta] = useField<string[]>(name);
  const { setFieldValue } = useFormikContext();

  return (
    <EmailAddressesPicker
      selectedEmailAddresses={field.value}
      onChange={emailAddresses => setFieldValue(name, emailAddresses)}
      onBlur={field.onBlur}
      TextFieldProps={{
        ...TextFieldProps,
        error: meta.touched && Boolean(meta.error),
        helperText: meta.touched && meta.error,
        name: field.name,
      }}
    />
  );
}
