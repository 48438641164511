import { Table } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { Action } from '../../../../../../graphql';
import { OfferingSetup_EntitlementsConfigurationQuery } from '../../graphql';
import { EntitlementData } from '../../hooks/EntitlementsDataByManagerContext';
import {
  SActionRow,
  SHeader,
  SResourceRow,
  StyledBanner,
  StyledCheckIcon,
} from './ConfigurationTable.styles';

const actionLabels = {
  [Action.Create]: 'Create',
  [Action.Read]: 'Read',
  [Action.Update]: 'Update',
  [Action.Delete]: 'Delete',
  [Action.Manage]: 'Manage',
  [Action.Release]: 'Release',
  [Action.Acknowledge]: 'Acknowledge',
  [Action.LimitedRead]: 'Limited Read',
  [Action.Receive]: 'Receive',
};

export type Props = {
  configuration: OfferingSetup_EntitlementsConfigurationQuery['entitlementsConfiguration'] | null;
  managerEntitlements: EntitlementData | null;
};

/**
 * ConfigurationTable displays an entitlement configuration table for a certain boundedContext
 */
const ConfigurationTable: React.FC<Props> = ({ configuration, managerEntitlements }) => {
  const theme = useTheme();

  if (!configuration) {
    return (
      <StyledBanner variant="information">Entitlement configurations are unavailable.</StyledBanner>
    );
  }

  return (
    <Table>
      <SHeader>
        <tr>
          <th>Resources</th>
          <th>Descriptions</th>
        </tr>
      </SHeader>
      <tbody>
        {configuration.map((config, idx) => (
          <React.Fragment key={`${config.resource}-${idx}`}>
            <SResourceRow>
              <td colSpan={2}>{config.displayName}</td>
            </SResourceRow>
            {config.actionConfigurations.map(action => {
              const hasEntitlement = managerEntitlements?.entitlements.some(
                entitlement => entitlement === `${action.action}:${config.resource}`
              );

              return (
                <SActionRow key={`${config.resource}-${idx}-${action.action}`}>
                  <td>
                    <StyledCheckIcon
                      name={hasEntitlement ? 'check-circle' : 'times-circle'}
                      color={
                        hasEntitlement
                          ? theme.brand.color.successAlternate
                          : theme.brand.color.danger
                      }
                      variant="solid"
                    />
                    {actionLabels[action.action]}
                  </td>
                  <td>{action.description}</td>
                </SActionRow>
              );
            })}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default ConfigurationTable;
