import { UUID } from '@cmg/common';
import React from 'react';

import ListItemText from '../../../../../../common/components/lists/list/ListItemText';
import NavItem from '../../../../../../common/components/navigation/side-navigation/NavItem';
import SideNav from '../../../../../../common/components/navigation/side-navigation/SideNav';
import routeFactory from '../../../../../../common/util/routeFactory';
import { SWrapper } from './RoadshowSideNav.styles';

export type Props = {
  roadshowId?: UUID;
};

const RoadshowSideNav: React.FC<Props> = ({ roadshowId }) => {
  return (
    <SWrapper>
      <SideNav>
        <NavItem
          url={
            roadshowId
              ? routeFactory.roadshowDetails.getUrlPath({ roadshowId })
              : routeFactory.roadshowCreate.getUrlPath()
          }
        >
          <ListItemText>Roadshow Details</ListItemText>
        </NavItem>
        <NavItem
          url={
            roadshowId ? routeFactory.roadshowCompanyDetails.getUrlPath({ roadshowId }) : undefined
          }
          disabled={!roadshowId}
        >
          <ListItemText>Company & Management Team</ListItemText>
        </NavItem>
        <NavItem
          url={
            roadshowId ? routeFactory.roadshowDealTeamDetails.getUrlPath({ roadshowId }) : undefined
          }
          disabled={!roadshowId}
        >
          <ListItemText>Deal Team</ListItemText>
        </NavItem>
      </SideNav>
    </SWrapper>
  );
};

export default RoadshowSideNav;
