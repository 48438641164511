import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Recipient as SelectableRecipient } from '../../../../../../../../common/components/dialogs/select-recipients-dialog/hooks/useSelectRecipients';
import { SyndicateWireManagerRole } from '../../../../../../../../types/domain/syndicate-wires/constants';
import { WireTypeCategory } from '../../../../context/WireTypeConfigContext.model';
import { WireRecipient } from './useWireRecipientsDialog.model';

export type Props = Readonly<{
  readonly recipients: readonly WireRecipient[] | undefined;
  readonly wireTypeCategory: WireTypeCategory;
}>;

export const useWireRecipientsDialog = ({ recipients, wireTypeCategory }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectableRecipients, setSelectableRecipients] = useState<
    SelectableRecipient<SyndicateWireManagerRole>[]
  >([]);

  const selectedEnabledRecipientKeys = useMemo(
    () =>
      selectableRecipients.reduce<string[]>((acc, curr) => {
        if (curr.isSelected && !curr.isDisabled) {
          return [...acc, curr.cmgEntityKey];
        }

        return acc;
      }, []),
    [selectableRecipients]
  );

  const isDisabledRule = useCallback(
    (recipient: WireRecipient) => {
      return (
        (wireTypeCategory === 'wire' && !recipient.distributionList.length) || recipient.isDisabled
      );
    },
    [wireTypeCategory]
  );

  useEffect(() => {
    setSelectableRecipients(
      recipients?.map<SelectableRecipient<SyndicateWireManagerRole>>(recipient => {
        const isDisabled = isDisabledRule(recipient);

        return {
          cmgEntityKey: recipient.cmgEntityKey,
          distributionList: recipient.distributionList,
          firmName: recipient.firmName,
          isSelected: !isDisabled || recipient.isToggled,
          isDisabled,
          role: recipient.role,
        };
      }) ?? []
    );
  }, [isDisabledRule, recipients]);

  const handleRecipientSelectionChange = useCallback(
    (cmgEntityKeys: string[]) => {
      const nextSelectedRecipients =
        recipients?.map<SelectableRecipient<SyndicateWireManagerRole>>(recipient => ({
          ...recipient,
          isSelected: cmgEntityKeys.includes(recipient.cmgEntityKey),
          isDisabled: isDisabledRule(recipient),
          role: recipient.role,
        })) ?? [];

      setSelectableRecipients(nextSelectedRecipients);
    },
    [isDisabledRule, recipients]
  );

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen,
    open,
    close,
  };
};
