import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import find from 'lodash/find';
import React from 'react';

import Spinner from '../../../../../../../../common/components/overlays/spinner/Spinner';
import {
  MdlAllocationIoiType,
  MdlAllocationRanking,
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
  OfferingType,
} from '../../../../../../../../graphql';
import FirmLevelDemandBar from '../../../../../../components/firm-level-demand-bar/FirmLevelDemandBar';
import InterestLevelsWrapper from '../../../../../../components/interest-levels-wrapper/InterestLevelsWrapper';
import { InterestLevelChildrenProps } from '../../../../../../components/interest-levels-wrapper/InterestLevelsWrapper.model';
import {
  FirmLevelDemand_MdlAllocationPartsFragment,
  FirmLevelDemand_MdlIndicationPartsFragment,
} from '../../graphql';
import InterestLevel from './components/interest-level/InterestLevel';
import {
  createInitialValues,
  firmLevelDemandFormValidationSchema,
  FirmLevelDemandFormValues,
  FirmLevelDemandValues,
} from './FirmLevelDemandForm.model';
import { SContainer, SMessage, SSpinnerContainer } from './FirmLevelDemandForm.styles';

export type Props = {
  indications: readonly FirmLevelDemand_MdlIndicationPartsFragment[];
  allocation?: FirmLevelDemand_MdlAllocationPartsFragment | null;
  onSubmit: (
    values: FirmLevelDemandValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  ) => void;
  isEditing?: boolean;
  onChangeEditing: () => void;
  onChangeIoiType: (ioiType: MdlAllocationIoiType) => void;
  loading?: boolean;
  offeringType: OfferingType;
  isExecutingOnPlatform: boolean;
};

export const FirmLevelDemandFormComponent: React.FC<Props> = ({
  indications,
  allocation,
  isEditing = false,
  onChangeEditing,
  onChangeIoiType,
  onSubmit,
  loading = false,
  offeringType,
  isExecutingOnPlatform,
}) => {
  const ioiType = allocation?.ioiType;
  const allocationRanking = allocation?.ranking;

  const formik = useFormik<
    FirmLevelDemandFormValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  >({
    initialValues: createInitialValues(indications),
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: firmLevelDemandFormValidationSchema,
    onSubmit: (
      values: FirmLevelDemandFormValues<
        MdlIndicationUnitType,
        MdlIndicationPricingType,
        MdlIndicationLimitType
      >
    ) => {
      onSubmit({
        interestLevels:
          values.interestLevels.map(({ quantity, ...interestLevel }) => interestLevel) ?? [],
      });
    },
  });

  const isNormalIoiType = !ioiType || ioiType === MdlAllocationIoiType.Normal;

  const hasAllocationRanking =
    allocationRanking && allocationRanking !== MdlAllocationRanking.NotIndicated;

  const onResetForm = () => {
    formik.resetForm();
    onChangeEditing();
  };

  return (
    <FormikProvider value={formik}>
      <Form data-test-id={xcSelectors.firmLevelDemandForm.testId}>
        <FirmLevelDemandBar
          isEditing={isEditing}
          onChangeEditing={onChangeEditing}
          onChangeIoiType={onChangeIoiType}
          ioiType={ioiType}
          onSubmitForm={formik.submitForm}
          onResetForm={onResetForm}
          loading={loading}
          title="Firm Level Demand"
          hasAllocationRanking={!!hasAllocationRanking}
          convertableToPassOrRegM
          isExecutingOnPlatform={isExecutingOnPlatform}
        />
        {loading && (
          <SSpinnerContainer>
            <Spinner show fullHeight />
          </SSpinnerContainer>
        )}
        {!loading && (
          <SContainer isNormalIoiType={isNormalIoiType}>
            {ioiType === MdlAllocationIoiType.Pass && <SMessage>Pass</SMessage>}
            {ioiType === MdlAllocationIoiType.RegM && <SMessage>Reg-M</SMessage>}
            {isNormalIoiType && (
              <InterestLevelsWrapper isEditing={isEditing}>
                {({
                  interestLevels,
                  ...props
                }: InterestLevelChildrenProps<
                  MdlIndicationUnitType,
                  MdlIndicationPricingType,
                  MdlIndicationLimitType
                >) => {
                  const hasMarketPricingType = !!find(interestLevels, [
                    'pricingType',
                    MdlIndicationPricingType.Market,
                  ]);

                  return interestLevels.map((level, index) => (
                    <InterestLevel
                      {...props}
                      key={index}
                      index={index}
                      interestLevel={level}
                      interestLevels={interestLevels}
                      hasMarketPricingType={hasMarketPricingType}
                      offeringType={offeringType}
                    />
                  ));
                }}
              </InterestLevelsWrapper>
            )}
          </SContainer>
        )}
      </Form>
    </FormikProvider>
  );
};

export default FirmLevelDemandFormComponent;
