import { useAuth } from '@cmg/auth';
import { Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

import { getFirmDisplayName } from '../../../../utils';
import { SyndicateManager } from '../../types';
import {
  getRelationshipTooltipContent,
  getSortedRelationshipList,
} from './RelationshipNameCell.model';

export type Props = Readonly<{ managers: (SyndicateManager | null)[] }>;

export const RelationshipNameCell: React.FC<Props> = ({ managers }) => {
  const { oidcUserCmgEntityKey } = useAuth();
  if (!managers.length) {
    return null;
  }
  const managerFirmNames = getSortedRelationshipList(managers, oidcUserCmgEntityKey!);

  return (
    <Tooltip title={getRelationshipTooltipContent(managerFirmNames)} variant="info" placement="top">
      <Typography noWrap overflow="hidden" textOverflow="ellipsis" fontSize={13} component="div">
        {managerFirmNames
          .slice(0, 3)
          .map(manager => getFirmDisplayName(manager ?? null))
          .join(', ')}
        {managerFirmNames.length > 3 && <span>&nbsp;+{managerFirmNames.length - 3}...</span>}
      </Typography>
    </Tooltip>
  );
};
