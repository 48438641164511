import { Autocomplete, SearchTextField, Stack, StackProps } from '@cmg/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AttestationFormStatus, FirmCertificateSource } from '../../../../graphql/__generated__';
import useCertificateFilters from '../../hooks/useCertificateFilters/useCertificateFilters';
import {
  CertificateExpiresInFilterOption,
  certificateExpiresInFilterOptions,
  CertificateSourceFilterOption,
  certificateSourceFilterOptions,
  CertificateStatusFilterOption,
  certificateStatusFilterOptions,
} from './certificate-filters.model';

type Props = {
  stackComponentProps?: StackProps;
};

const CertificateFilters = (props: Props) => {
  const {
    search,
    expirationDays,
    certificateStatuses,
    certificateLibrary,
    setCertificateLibrary,
    setCertificateStatuses,
    setExpirationDays,
    setSearch,
  } = useCertificateFilters();
  const onSearch = useCallback(
    (searchText: string | undefined) => {
      setSearch(searchText ?? null);
    },
    [setSearch]
  );
  const [localSearch, setLocalSearch] = useState<string>(search ?? '');
  const debouncedSearch = useDebouncedCallback<(arg: string | undefined) => void>(onSearch, 500);

  const defaultLibraryValue = useMemo(
    () =>
      certificateSourceFilterOptions.find(options => options.value === FirmCertificateSource.Crm),
    []
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocalSearch(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch]
  );

  const handleStatusChange = useCallback(
    (_event, value: CertificateStatusFilterOption[] | null) => {
      const statuses = value?.map(option => option.value) ?? [];
      setCertificateStatuses(statuses);
    },
    [setCertificateStatuses]
  );

  const handleLibraryChange = useCallback(
    (_event, value: CertificateSourceFilterOption | null) => {
      setCertificateLibrary(value?.value ?? null, certificateStatuses);
    },
    [certificateStatuses, setCertificateLibrary]
  );

  const onExpiresInChange = useCallback(
    (_event, value: CertificateExpiresInFilterOption | null) => {
      setExpirationDays(value?.value ?? null);
    },
    [setExpirationDays]
  );

  const certificateLibraryOption = certificateSourceFilterOptions.find(
    options => options.value === certificateLibrary
  );

  const values = certificateStatuses
    ? certificateStatusFilterOptions.filter(options => certificateStatuses.includes(options.value))
    : [];

  const calculatedCertificateStatusOptions =
    certificateLibrary === FirmCertificateSource.Cmg
      ? certificateStatusFilterOptions.filter(
          option => option.value !== AttestationFormStatus.NotOnFile
        )
      : certificateStatusFilterOptions;

  return (
    <Stack
      spacing={2}
      direction="row"
      paddingX={theme => theme.spacing(4)}
      {...props.stackComponentProps}
    >
      <SearchTextField
        placeholder="Search..."
        value={localSearch ?? search}
        label="Firm Name or BIK"
        onChange={handleSearchChange}
      />
      <Autocomplete<CertificateStatusFilterOption, true>
        multiple
        options={calculatedCertificateStatusOptions}
        value={values}
        getOptionLabel={option => option.title}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        TextFieldProps={{ label: 'Certificate Status', placeholder: 'Select...' }}
        onChange={handleStatusChange}
      />
      <Autocomplete<CertificateSourceFilterOption, false, true>
        disableClearable={true}
        options={certificateSourceFilterOptions}
        value={certificateLibrary ? certificateLibraryOption : defaultLibraryValue}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={option => option.title}
        TextFieldProps={{ label: 'Certificate Library', placeholder: 'Select...' }}
        onChange={handleLibraryChange}
      />
      <Autocomplete<CertificateExpiresInFilterOption>
        options={certificateExpiresInFilterOptions}
        value={
          expirationDays
            ? certificateExpiresInFilterOptions.find(({ value }) => value === expirationDays)
            : null
        }
        getOptionLabel={option => option.title}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        TextFieldProps={{ label: 'Expires In', placeholder: 'Select...' }}
        onChange={onExpiresInChange}
      />
    </Stack>
  );
};

export default CertificateFilters;
