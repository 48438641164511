import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_Summary_ShareDraftOfferingDocument,
  OfferingSetup_Summary_ShareDraftOfferingMutation,
  OfferingSetup_Summary_ShareDraftOfferingMutationVariables,
} from '../../graphql';

export const refetchQueries = () => {
  // Refetch the offering share history after a new share
  const getOfferingShareHistory = getOperationName(
    OfferingSetup_Summary_ShareDraftOfferingDocument
  )!;
  return [getOfferingShareHistory];
};

/**
 * useShareDraftOfferingMutation publishes the offering
 */
export const useShareDraftOfferingMutation = () => {
  return useServiceMutation<
    OfferingSetup_Summary_ShareDraftOfferingMutation,
    OfferingSetup_Summary_ShareDraftOfferingMutationVariables
  >(OfferingSetup_Summary_ShareDraftOfferingDocument, { refetchQueries });
};
