import React from 'react';

import { useGridTotals } from '../hooks/useGridTotals';
import { SalesCreditsGridContext } from '../SalesCreditsGrid.model';
import { calculateSalesCreditsDifference } from '../total-sales-credits-renderer/TotalSalesCreditsRenderer.model';
import { SPinnedRowText, STotalInvestorsWithDiffWrapper } from './TotalInvestorRenderer.styles';

export type Props = {
  data: ReturnType<typeof useGridTotals>;
  context: SalesCreditsGridContext;
};

const TotalInvestorRenderer: React.FC<Props> = ({ data, context }) => {
  const salesCreditsDifference = calculateSalesCreditsDifference({
    sellingConcession: context.sellingConcession,
    totalSalesCredits: data.salesCredits,
    totalDesignationShares: data.designationShares,
  });

  if (data.investorFirmName === 0) {
    return null;
  }

  if (salesCreditsDifference === 0) {
    return <SPinnedRowText>Total {data.investorFirmName}</SPinnedRowText>;
  }

  return (
    <STotalInvestorsWithDiffWrapper>
      <div>Total {data.investorFirmName}</div>
      <div>Difference</div>
    </STotalInvestorsWithDiffWrapper>
  );
};

export default TotalInvestorRenderer;
