import React from 'react';

import { IndicationWithDemandLevels } from '../../types';
import { SLabel, StyledPopover } from './AlloConfirmedByCell.styles';

type Props = {
  data: IndicationWithDemandLevels;
};

/**
 * Renders the Allo Confirmed By Cell within the Institutional Demand Grid.
 */
const AlloConfirmedByCell: React.FC<Props> = ({ data }) => {
  const investorReplySubmitterName = data.finalAllocation?.investorReplySubmitterName;
  const userEmail = data.finalAllocation?.investorReply?.auditInfo.createdByUserEmail;

  return (
    <StyledPopover content={userEmail} variant="DARK" placement="topLeft">
      {investorReplySubmitterName ? <SLabel>{investorReplySubmitterName}</SLabel> : '-'}
    </StyledPopover>
  );
};

export default AlloConfirmedByCell;
