import { useMemo } from 'react';

import { sortCaseInsensitive } from '../../../../../../common/util/sortCaseInsensitive';
import { NonSyndicateWireManager, RecipientUserData } from '../../../SyndicateWiresRoute.model';
import { RecipientFormValues } from './hooks/useRecipientsUserDataForm';
import {
  Props as RecipientsUserDataFormFieldsProps,
  RecipientsUserDataFormFields,
} from './RecipientsUserDataFormFields';

export type Props<TRecipientUserData extends RecipientUserData> = Omit<
  RecipientsUserDataFormFieldsProps<TRecipientUserData, NonSyndicateWireManager>,
  'allManagers' | 'managersToRender'
> &
  Readonly<{
    readonly managers: NonSyndicateWireManager[];
  }>;

export const NonSyndicateRecipientsUserDataFormFields = <
  TRecipientUserData extends RecipientUserData,
  TValues extends RecipientFormValues<TRecipientUserData>
>({
  managers,
  operationType,
  defaultRecipientValues,
  renderRecipientRow,
}: Props<TRecipientUserData>) => {
  const sortedManagers = useMemo(
    () => [...managers].sort((a, b) => sortCaseInsensitive(a.name, b.name)),
    [managers]
  );

  return (
    <RecipientsUserDataFormFields<TRecipientUserData, TValues, NonSyndicateWireManager>
      allManagers={managers}
      managersToRender={sortedManagers}
      defaultRecipientValues={defaultRecipientValues}
      operationType={operationType}
      renderRecipientRow={renderRecipientRow}
    />
  );
};
