import { isValidCmgEntityKey } from '../../../../../../../../common/util/isValidCmgEntityKey';
import { AttestationFormStatus, OfferingType } from '../../../../../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { OrderBookPersonaType } from '../../../../../hooks/useIdentifyOrderBookPersona';
import { useCoveredAttestationQuery } from '../../sale-and-trading/useCoveredAttestationQuery';
import { CertificateModel, PersonaProps } from '../useCertificatesModel';

export type Props = PersonaProps & {
  offeringId: string;
  cmgEntityKey: string | undefined;
};

export const COMPLIANCE_WARNING_MESSAGE_SALE_AND_TRADING =
  'To submit, modify, and acknowledge indications, review the outstanding requirements in the Compliance section.';

export const useSalesAndTradingCertificatesModel = ({
  type,
  cmgEntityKey,
  offeringId,
}: Props): CertificateModel => {
  const isSaleTrading = type === OrderBookPersonaType.SALES_AND_TRADING;

  const coveredAttestationQuery = useCoveredAttestationQuery(
    { cmgEntityKey: cmgEntityKey!, offeringId },
    { skip: !isSaleTrading || !isValidCmgEntityKey(cmgEntityKey) || !offeringId }
  );

  const { coveredAttestation = null, basicPublishedOffering = null } =
    coveredAttestationQuery.data ?? {};
  const { type: offeringType } = basicPublishedOffering || {};

  const hasComplianceWarning =
    (coveredAttestation?.formStatus === AttestationFormStatus.Expired ||
      coveredAttestation?.formStatus === AttestationFormStatus.NotOnFile) &&
    offeringType === OfferingType.Ipo;

  const redirectUrl = cmgEntityKey
    ? offeringSidePanelSellSideRouteFactory.orderBookCompliance.getUrlPath({
        cmgEntityKey: cmgEntityKey,
      })
    : null;

  return {
    canRead: !!cmgEntityKey && isSaleTrading,
    attestation: coveredAttestation,
    loading: coveredAttestationQuery.loading,
    offeringType: offeringType,
    warning: {
      hasComplianceWarning,
      canByPassComplianceWarning: false,
      message: COMPLIANCE_WARNING_MESSAGE_SALE_AND_TRADING,
      redirectUrl,
      tooltipMessage: null,
    },
  };
};
