import {
  FlexContainer,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { PaginationDataInput } from '../../../../../../graphql';
import { Roadshows_DealTeamMemberLimitedPartsFragment } from '../../../../common/graphql';
import { useAssignDealTeamMembersMutation } from '../hooks/useAssignDealTeamMembersMutation';
import { initialValues, SearchValues, SelectedDealTeamMember } from './AddMembersModal.model';
import {
  SEmptyFiller,
  StyledModalFooter,
  StyledSearchPanel,
  StyledSelectedMembersPanel,
} from './AddMembersModal.styles';
import { useRoadshows_RoadshowDealTeamSearchLazyQuery } from './graphql';
import usePaginatedInfiniteScroll from './hooks/usePaginatedInfiniteScroll';
import AddMembersSearchFilter from './search-filter/AddMembersSearchFilter';
import AddMembersSearchResults from './search-results/AddMembersSearchResults';
import SelectedDealTeamMembers from './selected-members/SelectedDealTeamMembers';

export type Props = {
  show: boolean;
  onHide: () => void;
  onOpenCreateMembersModal: () => void;
  roadshowId: UUID;
  participatingTeamMembers: SelectedDealTeamMember[];
};

const AddMembersModal: React.FC<Props> = ({
  show,
  onHide,
  onOpenCreateMembersModal,
  roadshowId,
  participatingTeamMembers,
}) => {
  const [selectedMembers, setSelectedMembers] = React.useState<SelectedDealTeamMember[]>([]);

  const [assignTeams, { loading: isAssigning }] = useAssignDealTeamMembersMutation();

  const formik = useFormik<SearchValues>({
    initialValues,
    onSubmit: () => {
      fetchNewData();
    },
  });

  const { values, resetForm } = formik;

  const [searchDealTeams] = useRoadshows_RoadshowDealTeamSearchLazyQuery();

  const getData = async (pagination: PaginationDataInput) => {
    try {
      const { data } = await searchDealTeams({ variables: { filter: values, pagination } });

      return data?.roadshowDealTeamMembersSearchResult;
    } catch {
      ToastManager.error('Error occurred fetching Deal Team Members');
    }
  };

  const hasEmptySearch = !values.fullName && !values.department && !values.firmCmgEntityKey;

  const { fetchNewData, scrollRef, dataRows, isLoading } = usePaginatedInfiniteScroll(
    getData,
    hasEmptySearch
  );

  const handleHideModal = () => {
    setSelectedMembers([]);
    resetForm();

    onHide();
  };

  const handleAssignMembersToRoadshow = async () => {
    try {
      const participants = participatingTeamMembers.map(({ id }) => id);
      const selected = selectedMembers.map(({ id }) => id);
      await assignTeams({ variables: { roadshowId, payload: [...participants, ...selected] } });
      handleHideModal();
    } catch {
      ToastManager.error('Error occurred while adding Deal Team Members');
    }
  };

  const handleAddMember = ({
    id,
    fullName,
    firmName,
  }: Roadshows_DealTeamMemberLimitedPartsFragment) => {
    setSelectedMembers([...selectedMembers, { id, fullName, firmName }]);
  };

  const handleRemoveMember = (memberId: UUID) => {
    setSelectedMembers(selectedMembers.filter(({ id }) => id !== memberId));
  };

  const hasSelectedMembers = selectedMembers.length > 0;

  return (
    <Modal show={show} title="Add Deal Team Members" size="xlarge" testId="add-members-modal">
      <Modal.Content>
        <FlexContainer direction="row" gap={16}>
          <StyledSearchPanel>
            <Panel>
              <Panel.Header title="Deal Teams" />
              <Panel.Content paddingSize="MODAL">
                <FormikProvider value={formik}>
                  <AddMembersSearchFilter />
                </FormikProvider>
              </Panel.Content>
            </Panel>

            <Panel>
              <Panel.Header title="Search Results" />
              <Panel.Content paddingSize="MODAL">
                <AddMembersSearchResults
                  hasEmptySearch={hasEmptySearch}
                  infiniteScrollRef={scrollRef}
                  memberList={dataRows}
                  selectedMemberList={selectedMembers}
                  participatingMemberList={participatingTeamMembers}
                  isLoading={isLoading}
                  onAddMember={handleAddMember}
                />
              </Panel.Content>
            </Panel>
          </StyledSearchPanel>

          <FlexContainer.Divider direction="row" />

          <StyledSelectedMembersPanel>
            <Panel.Header title="Deal Teams" />
            <Panel.Content paddingSize="S">
              <SelectedDealTeamMembers
                selectedMemberList={selectedMembers}
                onRemoveMember={handleRemoveMember}
              />
            </Panel.Content>
          </StyledSelectedMembersPanel>
        </FlexContainer>
      </Modal.Content>

      <StyledModalFooter>
        <LinkButton
          testId="create new member button"
          onClick={onOpenCreateMembersModal}
          iconLeft={{ name: 'plus' }}
          inline
        >
          Create New Member
        </LinkButton>
        <SEmptyFiller />
        <SecondaryButton testId="cancel button" onClick={handleHideModal} disabled={isAssigning}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          testId="add members button"
          onClick={handleAssignMembersToRoadshow}
          disabled={isAssigning || !hasSelectedMembers}
          loading={isAssigning}
        >
          Add Members
        </PrimaryButton>
      </StyledModalFooter>
    </Modal>
  );
};

export default AddMembersModal;
