import { OnGridReadyEventFn } from '@cmg/common';
import { AgGridEvent } from 'ag-grid-community';
import React from 'react';

export const useGridReady = (gridRef: React.MutableRefObject<AgGridEvent | undefined>) => {
  return React.useCallback<OnGridReadyEventFn>(
    event => {
      gridRef.current = event;
    },
    [gridRef]
  );
};
