import React from 'react';

import { TradeReleaseStatus } from '../../../../graphql';
import { TradeGridFilters } from './useTradeGrid.model';

export function useTradeGridFilters(): TradeGridFilters {
  const [investorName, setInvestorName] = React.useState<string>('');
  const [releaseStatus, setReleaseStatus] = React.useState<TradeReleaseStatus | null>(null);
  const [containsErrors, setContainsErrors] = React.useState(false);
  return {
    containsErrors,
    setContainsErrors,
    releaseStatus,
    setReleaseStatus,
    investorName,
    setInvestorName,
  };
}
