import { Icon } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { SIconWrapper } from '../../../TradeReleaseLayout.styles';
import { getReleaseStatusColorMap, releaseStatusMap } from './ReleaseStatusRenderer.model';

export type Props = Readonly<{
  value: TradeRelease_TradeGridItemFragment['tradeStatus'] | null;
}>;

const ReleaseStatusRenderer: React.FC<Props> = ({ value }) => {
  const theme = useTheme();
  const releaseStatusColorMap = React.useMemo(() => getReleaseStatusColorMap(theme), [theme]);

  if (value == null) {
    return null;
  }

  return (
    <div>
      <SIconWrapper>
        <Icon name="circle" variant="solid" size="xs" color={releaseStatusColorMap[value]} />
      </SIconWrapper>
      {releaseStatusMap.get(value)}
    </div>
  );
};

export default ReleaseStatusRenderer;
