import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { useCallback, useState } from 'react';

import { getBulkActionsFilterInput } from '../demand-grid/DemandGrid.model';
import type { DemandConfigValues } from '../demand-grid/types';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../graphql/__generated__';
import { useOrderBook_InstitutionalDemand_InvestorKeysLazyQuery } from '../graphql/__generated__';

const PAGE_SIZE = 100;

type Investor = { cmgEntityKey: string; displayName: string | null | undefined };
type State = { investors: Investor[]; loading: boolean };

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
}>;

/**
 * Hook to get Demand Grid investor keys based on the row selection state.
 * If rowSelectionState.selectAll is true, it fetches all investor keys from the server.
 * If rowSelectionState.selectAll is false, it gets investor keys from the AgGrid row data state.
 *
 * @param offeringId - Related offering id
 * @param demandConfig - Demand config values
 */
export const useGetInvestorKeys = ({ offeringId, demandConfig }: Props) => {
  const [{ loading, investors }, setState] = useState<State>({
    /**
     * Investors consist of cmgEntityKey and displayName.
     * It's used to display investor names in the validation error alert/modal.
     */
    investors: [],
    /**
     * Loading state is handled by the component itself to avoid unnecessary re-renders.
     * These are caused by running the investor keys lazy query multiple times for larger books with multiple pages of data.
     */
    loading: false,
  });

  const { getRowData, getFilters } = useDataGridContext();

  const [loadInvestorKeysPage, queryResult] =
    useOrderBook_InstitutionalDemand_InvestorKeysLazyQuery();

  const getInvestorKeysFromSelectedRowData = useCallback(
    (selectedRowIds: string[]) => {
      const investors = getRowData<OrderBook_InstitutionalDemand_GridRowPartsFragment>(
        selectedRowIds
      ).map(({ investor }) => ({
        displayName: investor.displayName,
        cmgEntityKey: investor.cmgEntityKey,
      }));

      setState({ investors, loading: false });
      return investors.map(({ cmgEntityKey }) => cmgEntityKey);
    },
    [getRowData]
  );

  const getInvestorKeysFromServer = useCallback(
    async (rowSelectionState: GridRowSelectionState) => {
      let investors: Investor[] = [];
      let hasNextPage = true;
      let pageCounter = 0;

      if (!demandConfig) {
        setState({ investors: [], loading: false });
        return [];
      }

      setState(prevState => ({ ...prevState, loading: true }));

      /**
       * Fetches all investor keys from the server by paginating through the data.
       */
      while (hasNextPage) {
        const { data } = await loadInvestorKeysPage({
          fetchPolicy: 'no-cache',
          variables: {
            ...demandConfig,
            offeringId,
            skip: pageCounter++ * PAGE_SIZE,
            take: PAGE_SIZE,
            where: getBulkActionsFilterInput(rowSelectionState, getFilters()?.filterInput),
          },
        });

        const { pageInfo, items } = data?.syndicateInstitutionalGrid ?? {};
        const nextInvestors =
          items?.map(({ investor }) => ({
            displayName: investor.displayName,
            cmgEntityKey: investor.cmgEntityKey,
          })) ?? [];

        investors = [...investors, ...nextInvestors];
        hasNextPage = !!pageInfo?.hasNextPage;
      }

      setState({ investors, loading: false });
      return investors.map(({ cmgEntityKey }) => cmgEntityKey);
    },
    [demandConfig, getFilters, loadInvestorKeysPage, offeringId]
  );

  const getInvestorKeys = useCallback(
    async (rowSelectionState: GridRowSelectionState) => {
      if (!rowSelectionState.selectAll) {
        return getInvestorKeysFromSelectedRowData(rowSelectionState.selectedRowIdsFlat);
      }

      return await getInvestorKeysFromServer(rowSelectionState);
    },
    [getInvestorKeysFromSelectedRowData, getInvestorKeysFromServer]
  );

  return {
    getInvestorKeys,
    loading,
    error: queryResult.error,
    investors,
  };
};
