import { urlUtil, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../../../common/util/routeFactory';
import { IndicationNoteType } from '../../../../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { NotesFilters } from '../../../../../../offering-side-panel/order-book/notes/NotesModel.util';
import { NotePopover } from '../../components/note-popover/NotePopover';
import {
  IndicationNote,
  IndicationWithDemandLevels,
  InstitutionalDemandGridContext,
} from '../../types';
import { SText } from './NoteCell.styles';

const IndicationNoteTitle: { [key in IndicationNoteType]: string } = {
  [IndicationNoteType.SalesAndTradingNote]: 'Sales & Trading Notes',
  [IndicationNoteType.SyndicateNote]: 'Syndicate Notes',
  [IndicationNoteType.SalesContactNote]: 'Sales Contact',
  [IndicationNoteType.SalesDepartmentNote]: 'Sales Department',
};

const NoteTypeSearchParamMapping: { [key in IndicationNoteType]: NotesFilters } = {
  [IndicationNoteType.SalesAndTradingNote]: NotesFilters.SALES_TRADER,
  [IndicationNoteType.SyndicateNote]: NotesFilters.SYNDICATE,
  [IndicationNoteType.SalesContactNote]: NotesFilters.SALES_CONTACT_NOTE,
  [IndicationNoteType.SalesDepartmentNote]: NotesFilters.SALES_DEPARTMENT_NOTE,
};

export type Props = {
  offeringId: UUID;
  investorCmgEntityKey?: string | null;
  noteType: IndicationNoteType;
  note: IndicationNote | null;
  matchUrl: string;
};

const NoteCell: React.FC<Props> = ({
  offeringId,
  investorCmgEntityKey,
  note,
  noteType,
  matchUrl,
}) => {
  const history = useHistory();

  const handleOnClick = () => {
    const pathname = routeFactory.offeringSidePanel.getUrlPath({
      offeringId,
      sidePanelRoutePath: offeringSidePanelSellSideRouteFactory.orderBookNotes.getUrlPath({}),
      prefixRoutePath: matchUrl,
    });

    const search = urlUtil.queryStringify({
      cmgEntityKey: investorCmgEntityKey,
      noteType: NoteTypeSearchParamMapping[noteType],
    });

    history.push({ pathname, search });
  };

  if (!note) {
    return <span>-</span>;
  }

  return (
    <NotePopover
      title={IndicationNoteTitle[noteType]}
      type={noteType}
      text={note.text}
      modifiedAt={note.modifiedAt}
      onClick={handleOnClick}
    >
      <SText>{note.text || '-'}</SText>
    </NotePopover>
  );
};

type CellRendererProps = {
  data: IndicationWithDemandLevels;
  context: InstitutionalDemandGridContext;
};

export const getNoteCellRenderer = (noteType: IndicationNoteType) => {
  /**
   * Wrapping NoteCell renderer into the factory function that returns an anonymous Class component with getReactContainerStyle method.
   * There are several reasons for this:
   * 1. We need to adjust the styling of `ag-react-container` div which is done trough getReactContainerStyle method
   * 2. getReactContainerStyle approach won't work unless put into a class component
   * 3. NoteCell calls useHistory hook which is not usable in class components
   * 4. It's easier to test the NoteCell component separately from the factory function
   */

  const getNoteType = (type: IndicationNoteType, data: IndicationWithDemandLevels) => {
    switch (type) {
      case IndicationNoteType.SalesAndTradingNote: {
        return data.notes.salesAndTrading;
      }
      case IndicationNoteType.SalesContactNote: {
        return data.notes.salesContact;
      }
      case IndicationNoteType.SalesDepartmentNote: {
        return data.notes.salesDepartment;
      }
      default: {
        return data.notes.syndicate;
      }
    }
  };

  return class extends React.Component<CellRendererProps> {
    getReactContainerStyle() {
      return {
        minWidth: 0,
      };
    }

    render() {
      const { data, context } = this.props;
      const note = getNoteType(noteType, data);

      return (
        <NoteCell
          offeringId={data.offeringId}
          investorCmgEntityKey={data.investorInformation.cmgEntityKey}
          note={note}
          noteType={noteType}
          matchUrl={context.matchUrl}
        />
      );
    }
  };
};

export default NoteCell;
