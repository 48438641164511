import { useAuth } from '@cmg/auth';
import { assets, getCurrencySymbol, numericUtil } from '@cmg/common';
import { Divider, Grid, InfoOutlinedIcon, Typography } from '@cmg/design-system';
import React from 'react';

import CurrencyFormatter from '../../../../../../common/components/format/currency-format/CurrencyFormatter';
import { OfferingStatusChip } from '../../../../../../design-system/components/data-display/offering-status-chip/OfferingStatusChip';
import { sectorLabels } from '../../../../../constants';
import { getOfferingTypeLabel } from '../../../../../offering-setup/utils/offeringTypeUtil';
import { SideBar_LimitedPublishedOfferingPartsFragment } from '../../graphql';
import { SidePanelNavBarSection } from '../design-system';
import { CurrencyRangeValue } from './CurrencyRangeValue';
import { CountryCodeLayout, Flag, OnPlatformLogo } from './design-system';

export type Props = {
  readonly isBusy?: boolean;
  readonly publishedOffering: SideBar_LimitedPublishedOfferingPartsFragment;
};

const OfferingStatusIndicator: React.FC<Props> = props => {
  const { publishedOffering, isBusy } = props;
  const { oidcUserCmgEntityKey } = useAuth();
  const isCurrentUsersFirmUnderwriting = publishedOffering.managers.some(
    manager => manager.cmgEntityKey === oidcUserCmgEntityKey
  );

  return (
    <React.Fragment>
      <SidePanelNavBarSection isBusy={isBusy}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Typography variant="h3">{publishedOffering.issuerName}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="body2" color="text.secondary">
              {publishedOffering.pricingInstrumentStockSymbol}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1.5}>
          {publishedOffering.isExecutingOnPlatform && (
            <React.Fragment>
              <Grid item sm={2}>
                <OnPlatformLogo
                  src={assets.logoNoText}
                  alt="This offering is being managed on the CMG Platform."
                />
              </Grid>
              <Grid item sm={10}>
                <Typography variant="body2" color="text.secondary">
                  This offering is being managed on the CMG Platform.
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          {isCurrentUsersFirmUnderwriting && (
            <React.Fragment>
              <Grid item sm={2}>
                <Typography variant="body2" color="info.main">
                  <InfoOutlinedIcon fontSize="small" />
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <Typography variant="body2" color="text.secondary">
                  My firm is participating in this deal
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </SidePanelNavBarSection>
      <Divider />
      <SidePanelNavBarSection isBusy={isBusy}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Type
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="highlight2" color="text.primary">
              {getOfferingTypeLabel({
                isExecutingOnPlatform: publishedOffering.isExecutingOnPlatform,
                pricingInstrumentCountryCode: publishedOffering.pricingInstrumentCountryCode,
                offeringType: publishedOffering.type,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <OfferingStatusChip status={publishedOffering.status} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Sector
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="highlight2" color="text.primary">
              {publishedOffering.issuerSector ? sectorLabels[publishedOffering.issuerSector] : null}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Region
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="highlight2" color="text.primary">
              {publishedOffering.pricingInstrumentRegion
                ? publishedOffering.pricingInstrumentRegion
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Country
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CountryCodeLayout>
              <Flag countryCode={publishedOffering.pricingInstrumentCountryCode} />
              <Typography variant="highlight2" color="text.primary">
                {publishedOffering.pricingInstrumentCountryCode}
              </Typography>
            </CountryCodeLayout>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Offering Size
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="highlight2" color="text.primary">
              {publishedOffering.sizeInCurrency! && publishedOffering.sizeInCurrency < 500000 ? (
                <CurrencyFormatter
                  value={publishedOffering.sizeInCurrency}
                  currency={publishedOffering.pricingCurrencyCode}
                />
              ) : (
                numericUtil.formatCurrencyInMillions(
                  publishedOffering.sizeInCurrency,
                  undefined,
                  getCurrencySymbol(publishedOffering.pricingCurrencyCode)
                )
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="specialLabel" color="text.secondary">
              Price Range
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CurrencyRangeValue
              currencyCode={publishedOffering.pricingCurrencyCode}
              rangeLow={publishedOffering.priceRangeLow}
              rangeHigh={publishedOffering.priceRangeHigh}
            />
          </Grid>
        </Grid>
      </SidePanelNavBarSection>
    </React.Fragment>
  );
};

export default OfferingStatusIndicator;
