import styled from 'styled-components/macro';

export const SComplianceActionRequiredTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  padding-bottom: 7px;
`;

export const SComplianceTitleWrapper = styled.div`
  display: flex;
`;

export const SComplianceTitleLink = styled.div`
  margin-left: auto;
  padding-right: 8px;
`;

interface SComplianceBannerWrapperProps {
  padding?: string;
}

export const SComplianceBannerWrapper = styled.div<SComplianceBannerWrapperProps>`
  margin: ${p => p.padding ?? '0px 24px 0px 14px'};
`;
