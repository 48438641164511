import { Icon, numericUtil, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { useGridTotals } from '../hooks/useGridTotals';
import { SalesCreditsGridContext } from '../SalesCreditsGrid.model';
import { calculateSalesCreditsDifference } from './TotalSalesCreditsRenderer.model';
import { SSalesCreditsWrapper, SWarningText, SWrapper } from './TotalSalesCreditsRenderer.styles';

export type Props = {
  data: ReturnType<typeof useGridTotals>;
  context: SalesCreditsGridContext;
};

const TotalSalesCreditsRenderer: React.FC<Props> = ({ data, context }) => {
  const theme = useTheme();

  const salesCreditsDifference = calculateSalesCreditsDifference({
    sellingConcession: context.sellingConcession,
    totalSalesCredits: data.salesCredits,
    totalDesignationShares: data.designationShares,
  });

  if (salesCreditsDifference === 0) {
    return <SWrapper>{numericUtil.getDisplayValueForCurrency(data.salesCredits, 2)}</SWrapper>;
  } else {
    return (
      <SWrapper>
        {salesCreditsDifference !== 0 && (
          <Popover
            variant="LIGHT"
            placement="topLeft"
            arrowPointAtCenter
            content={<SWarningText>Sales Credits Total is out of balance</SWarningText>}
          >
            <span>
              <Icon
                name="exclamation-triangle"
                variant="solid"
                size="lg"
                color={theme.designSystem.colors.red['300']}
              />
            </span>
          </Popover>
        )}
        <SSalesCreditsWrapper>
          <div>{numericUtil.getDisplayValueForCurrency(data.salesCredits, 2)}</div>
          <div>{numericUtil.getDisplayValueForCurrency(salesCreditsDifference, 2)}</div>
        </SSalesCreditsWrapper>
      </SWrapper>
    );
  }
};

export default TotalSalesCreditsRenderer;
