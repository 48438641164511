import { AgGridApi, Icon, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { SWarningTextWrapper } from './TotalWarningRenderer.styles';

export type Props = { api: AgGridApi };

const TotalWarningRenderer: React.FC<Props> = ({ api }) => {
  const theme = useTheme();

  const warningCount = api
    .getRenderedNodes()
    .filter(
      ({ data }: { data: SalesCredits_SalesCreditItemPartsFragment }) => data.warnings.length > 0
    ).length;

  if (warningCount > 0) {
    return (
      <Popover
        variant="LIGHT"
        placement="topRight"
        arrowPointAtCenter
        content={
          <SWarningTextWrapper>
            {`${warningCount} row${warningCount === 1 ? '' : 's'} requires attention`}
          </SWarningTextWrapper>
        }
      >
        <span>
          <Icon
            name="exclamation-triangle"
            variant="solid"
            size="lg"
            color={theme.designSystem.colors.red['300']}
          />
        </span>
      </Popover>
    );
  }

  return null;
};

export default TotalWarningRenderer;
