import { Alert, ButtonsContainer, PrimaryButton, SecondaryButton, ToastManager } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Loading from '../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../../common/components/overlays/modal/Modal';
import { useRoadshows_DealTeamQuery } from '../../../single-deal-team/deal-team-details/graphql';
import { SList } from './DeleteDealTeamModal.styles';
import { useDeleteDealTeamMutation } from './hooks/useDeleteDealTeamMutation';

export type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dealTeamId?: string;
};

const DeleteDealTeamMemberModal: React.FC<Props> = ({ show, onClose, onConfirm, dealTeamId }) => {
  const { data, loading, error } = useRoadshows_DealTeamQuery({
    variables: { cmgEntityKey: dealTeamId! },
    skip: !dealTeamId,
  });
  const [deleteDealTeam, { loading: deleteLoading }] = useDeleteDealTeamMutation();

  const handleDelete = async () => {
    try {
      await deleteDealTeam({
        variables: {
          cmgEntityKey: dealTeamId!,
        },
      });
      ToastManager.success('Successfully deleted Deal Team');
      onConfirm();
    } catch {
      ToastManager.error('There was an error while deleting Deal Team');
    }
  };

  return (
    <Modal
      show={show}
      title="Delete Deal Team"
      onHide={onClose}
      testId="roadshows / delete deal team modal"
    >
      <Modal.Content>
        <Alert severity="warning">
          By deleting Deal Team you will be removing its Deal Team members from all related
          Roadshows
        </Alert>
        {error && <ServerErrorsBanner error={error} />}
        {loading && <Loading />}
        {data && data.dealTeam?.participatedRoadshowNames?.length > 0 && (
          <React.Fragment>
            <p>The following projects would be affected:</p>
            <SList>
              {data.dealTeam.participatedRoadshowNames.map(item => (
                <li key={item}>{item}</li>
              ))}
            </SList>
          </React.Fragment>
        )}
        <p>This action can't be reverted. Would you like to proceed?</p>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right">
          <SecondaryButton
            testId={xcSelectors.cancelDeleteDealTeamButton.testId}
            onClick={onClose}
            disabled={deleteLoading}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.confirmDeleteDealTeamButton.testId}
            onClick={handleDelete}
            loading={deleteLoading}
          >
            Confirm
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDealTeamMemberModal;
