import styled from 'styled-components/macro';

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
`;

export default ColumnLayout;
