import { Icon } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { STitle, SWrapper } from './InvitationResponsePanel.styles';

export type Props = {
  /**
   * Color and icon variant
   */
  variant?: 'info' | 'error' | 'success';
  /**
   * Panel title
   */
  title?: string;
  /**
   * Supports custom styling
   */
  className?: string;
};

/**
 * Confirmation panel with custom body
 */
const InvitationResponsePanel: React.FC<Props> = ({
  variant = 'info',
  children,
  title,
  className,
}) => {
  const theme = useTheme();
  const getIcon = (variant: 'info' | 'error' | 'success') => {
    switch (variant) {
      case 'error':
        return (
          <Icon name="times-circle" variant="solid" size="3x" color={theme.brand.color.error} />
        );
      case 'info':
        return <Icon name="info-circle" variant="solid" size="3x" color={theme.brand.color.info} />;
      case 'success':
        return (
          <Icon name="check-circle" variant="solid" size="3x" color={theme.brand.color.success} />
        );
    }
  };

  return (
    <SWrapper variant={variant} className={className}>
      {getIcon(variant)}
      <STitle>{title}</STitle>
      {children}
    </SWrapper>
  );
};

export default InvitationResponsePanel;
