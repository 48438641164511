import styled from 'styled-components/macro';

export const SSettlementHeaderWrapper = styled.div`
  display: flex;
  padding: 0 16px 16px 16px;
  align-items: center;
`;

export const SSettlementHeaderText = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  flex: auto;
`;
