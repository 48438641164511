import { CreateManagerInput, ManagerRole, UpdateManagerInput } from '../../../../graphql';
import { JuniorRole } from '../../../../types/domain/manager/constants';
import { FormType } from './ManagerForm';

/** util function for moving item in a given array */
export function moveItemInArray<T>(array: readonly T[], from: number, to: number) {
  const items: T[] = [...array];
  const cut = items.splice(from, 1)[0];
  items.splice(to, 0, cut);
  return items;
}

export function updateManagerInput(values: FormType): UpdateManagerInput {
  return {
    role:
      values.role === JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER
        ? ManagerRole.ActiveBookrunner
        : values.role,
    isParticipating: values.isParticipating,
    economics: values.economics,
    isJuniorRole: values.role === JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER,
  };
}

export function createManagerInput(values: FormType): CreateManagerInput {
  return {
    ...values,
    role:
      values.role === JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER
        ? ManagerRole.ActiveBookrunner
        : values.role,
    isJuniorRole: values.role === JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER,
  };
}
