import { DataGridGroupingClient, DeepWritable, timeUtil } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useFeatureToggles } from '../../../common/config';
import sortByDateComparator from '../../../common/util/sortByDateComparator';
import { nonEditableGridOptions } from '../../constants';
import Filters from '../filters/Filters';
import useFilters from '../filters/hooks/useFilters';
import { useMyOfferings_SharedDraftsQuery } from './graphql';
import { useSharedOfferingsQuery } from './hooks/useSharedOfferingsQuery';
import columns from './SharedDraftsColumns';

export const GroupHeader = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};

  & span {
    margin-left: 5px;
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
`;

const FALLBACK_SOURCE_OFFERINGS = [];

const MemoizedDataGridGroupingClient = React.memo(DataGridGroupingClient);

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

const getRowStyle = params => {
  return params.data.isObsolete ? { opacity: '0.5' } : {};
};

const SharedDraftsRoute: React.FC<Props> = () => {
  const theme = useTheme();
  const { isOfferingSetupMyOfferingsOfferingFullGql } = useFeatureToggles();
  const { data, loading, error } = useMyOfferings_SharedDraftsQuery({
    fetchPolicy: 'network-only',
    skip: isOfferingSetupMyOfferingsOfferingFullGql,
  });

  const { sharedOfferings, isLoadingSharedOfferings, sharedOfferingsError } =
    useSharedOfferingsQuery({ shouldSkip: !isOfferingSetupMyOfferingsOfferingFullGql });

  const { sharedDrafts, sharedDraftsData, isLoading, serverError } =
    isOfferingSetupMyOfferingsOfferingFullGql
      ? {
          sharedDrafts: sharedOfferings,
          sharedDraftsData: sharedOfferings.data,
          isLoading: isLoadingSharedOfferings,
          serverError: sharedOfferingsError,
        }
      : {
          sharedDrafts: data?.allSharedDrafts,
          sharedDraftsData: data?.allSharedDrafts.data,
          isLoading: loading,
          serverError: error,
        };

  const { filters, setFilters, filteredData } = useFilters({
    sourceOfferings: sharedDraftsData ?? FALLBACK_SOURCE_OFFERINGS,
  });

  return (
    <FlexLayout direction="column" data-test-id={xcSelectors.myOfferingsSharedDraftsTab.testId}>
      <Filters
        offerings={sharedDrafts}
        onChange={setFilters}
        filters={filters}
        showObsoleteFilter={true}
        showStatusFilter={false}
      />
      {serverError && <ServerErrorsBanner error={serverError} />}
      <MemoizedDataGridGroupingClient
        groupByField="pricingDate"
        groupHeaderRenderer={groupValue => (
          <GroupHeader>
            Expected Pricing Date:
            <span>{groupValue ? timeUtil.formatAsDisplayDayAndDate(groupValue) : 'TBD'}</span>
          </GroupHeader>
        )}
        groupHeaderComparator={sortByDateComparator('desc')}
        rows={filteredData as DeepWritable<typeof filteredData>}
        columns={columns}
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          fillViewport: true,
        }}
        loading={isLoading}
        resizeBy="grid"
        gridOptions={{
          ...nonEditableGridOptions,
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressBrowserResizeObserver: true,
          rowStyle: {
            background: theme.background.color.white,
            borderBottom: theme.border.smallSolidLight,
          },
          getRowStyle,
        }}
      />
    </FlexLayout>
  );
};

export default SharedDraftsRoute;
