import styled from 'styled-components/macro';

export const SCompanyNameCellWrapper = styled.div`
  display: flex;
  line-height: 1.2;
  padding-top: 5px;
`;

export const SEmptyLogoCompanyCell = styled.span`
  width: 32px;
`;

export const SCompanyName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
`;
