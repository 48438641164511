import React from 'react';

import { EmptyState } from '../../../../../../../design-system/components/data-display/empty-state/EmptyState';

const DesignationMonitorNoResults: React.FC = () => {
  return (
    <EmptyState title="No results found" message="Adjust your search or filter and try again" />
  );
};

export default DesignationMonitorNoResults;
