import React from 'react';
import styled from 'styled-components/macro';

type SideBarSectionHeaderProps = {
  padding?: string;
};

export const SSideBarSectionHeader = styled.div<SideBarSectionHeaderProps>`
  background-color: ${({ theme }) => theme.background.color.light};
  padding: ${p => p.padding ?? '10px'};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 54px;
`;

export const SSideBarSectionTitle = styled.div`
  margin-right: 15px;

  &,
  [type='button'] {
    font-weight: ${({ theme }) => theme.text.weight.medium};
    font-size: ${({ theme }) => theme.text.size.medium};
    padding: 0;
    border: 0;
  }
`;

export type Props = {
  /**
   * The title displayed in the Side Bar Header.
   */
  title: React.ReactNode;

  padding?: string;
};

/**
 * Renders the Header used in the Side Bar component.
 * Children are supported, and are rendered to the right
 * of the header text.
 */
const SideBarHeader: React.FC<Props> = ({ title, children, padding }) => (
  <SSideBarSectionHeader padding={padding}>
    <SSideBarSectionTitle>{title}</SSideBarSectionTitle>
    {children}
  </SSideBarSectionHeader>
);

export default SideBarHeader;
