import { getOperationName } from '@apollo/client/utilities';

import { OfferingSetup_Summary_AvailableShareDraftManagersDocument } from '../../../graphql';
import { OfferingSetup_OfferingEntitlementsDocument } from '../../entitlements/graphql';
import { useOfferingSetup_DeleteManagerMutation } from '../graphql';

export const refetchQueries = () => {
  // Refetch offering entitlements on manager delete to remove the manager
  const getOfferingEntitlements = getOperationName(OfferingSetup_OfferingEntitlementsDocument)!;
  const getOfferingManagers = getOperationName(
    OfferingSetup_Summary_AvailableShareDraftManagersDocument
  )!;
  return [getOfferingEntitlements, getOfferingManagers];
};

export const useDeleteManagerMutation = (
  options: Parameters<typeof useOfferingSetup_DeleteManagerMutation>[0]
) =>
  useOfferingSetup_DeleteManagerMutation({
    ...options,
    refetchQueries,
  });
