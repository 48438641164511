/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RoadshowEventsQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_RoadshowEventsQuery = {
  readonly __typename?: 'Query';
  readonly roadshowEvents: ReadonlyArray<{
    readonly __typename?: 'RoadshowEvent';
    readonly id: string;
    readonly endTime: string;
    readonly endDate: string;
    readonly hostFirm?: string | null;
    readonly hostName?: string | null;
    readonly location?: string | null;
    readonly publicNotes?: string | null;
    readonly roadshowEventType: Types.RoadshowEventType;
    readonly startTime: string;
    readonly startDate: string;
    readonly status?: Types.EventStatus | null;
    readonly title: string;
    readonly type?: Types.MeetingType | null;
    readonly participantTeams: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
  }>;
};

export const Roadshows_RoadshowEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowEventParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowEventParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowEvent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowEventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantTeams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowEventsQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowEventsQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_RoadshowEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowEventsQuery,
    Roadshows_RoadshowEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_RoadshowEventsQuery, Roadshows_RoadshowEventsQueryVariables>(
    Roadshows_RoadshowEventsDocument,
    options
  );
}
export function useRoadshows_RoadshowEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowEventsQuery,
    Roadshows_RoadshowEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_RoadshowEventsQuery, Roadshows_RoadshowEventsQueryVariables>(
    Roadshows_RoadshowEventsDocument,
    options
  );
}
export type Roadshows_RoadshowEventsQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowEventsQuery
>;
export type Roadshows_RoadshowEventsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowEventsLazyQuery
>;
export type Roadshows_RoadshowEventsQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowEventsQuery,
  Roadshows_RoadshowEventsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowEventsMockResponse = makeMockResponse<
  Roadshows_RoadshowEventsQueryVariables,
  Roadshows_RoadshowEventsQuery
>(Roadshows_RoadshowEventsDocument);
