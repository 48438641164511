import { Icon } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

import FlexLayout from '../../common/components/layout/flex-layout/FlexLayout';

export const SHeader = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const STimingHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const SPopoverIcon = styled(Icon)`
  margin-left: 5px;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SContent = styled.div`
  padding: 20px;
`;

export const SFootnote = styled.span`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.text.color.light};
`;

export const SHr = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.text.color.light};
`;

export const SRule134Legend = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.light};
`;

export const SLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  color: ${({ theme }) => theme.text.color.light};
  padding-bottom: 5px;
`;

export const STableHeader = styled(SLabel)`
  flex: 1;
`;

export const SRole = styled.div`
  flex-basis: 25%;
  color: ${({ theme }) => theme.text.color.light};
`;

export const SField = styled.div<{ hide?: boolean }>`
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;

  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}
`;

export const StyledFlexLayout = styled(FlexLayout)<{ withBorder?: boolean; alignBottom?: boolean }>`
  height: auto;
  margin-bottom: 10px;

  ${({ withBorder, theme }) =>
    withBorder &&
    css`
      border-bottom: ${theme.border.smallSolidLight};
    `}

  ${({ alignBottom }) =>
    alignBottom &&
    css`
      align-items: flex-end;
    `}
`;

export const SRegistrationLinksField = styled(SField)`
  color: ${({ theme }) => theme.text.color.dark};
  margin-top: 20px;
`;

export const StyledProspectusLinksField = styled(SField)`
  color: ${({ theme }) => theme.text.color.dark};
`;

export const SProspectusLinkContainer = styled.div`
  margin: 15px 0;
`;

export const SProspectusLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
