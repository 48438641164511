import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  PricingTermsRetentionUserDataForRecipientInputData,
  SyndicateWiresManagerRole,
  WireTemplateType,
} from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_PricingTermsRetentionWirePartsFragment,
  SyndicateWires_PricingTermsRetentionWireValidationPartsFragment,
  useSyndicateWires_PricingTermsRetentionWireDetailsQuery,
  useSyndicateWires_PricingTermsRetentionWirePreviewQuery,
  useSyndicateWires_PricingTermsRetentionWiresQuery,
  useSyndicateWires_PricingTermsRetentionWireValidationQuery,
} from './graphql';
import { useCreatePricingTermsRetentionWireMutation } from './hooks/useCreatePricingTermsRetentionWireMutation';
import { useDeletePricingTermsRetentionWireMutation } from './hooks/useDeletePricingTermsRetentionWireMutation';
import { useSendPricingTermsRetentionWireMutation } from './hooks/useSendPricingTermsRetentionWireMutation';
import { useUpdatePricingTermsRetentionWireMutation } from './hooks/useUpdatePricingTermsRetentionWireMutation';
import { PricingTermsRetentionWireForm } from './PricingTermsRetentionWireForm';
import {
  RetentionRecipientUserData,
  validationSchema,
  Values,
} from './PricingTermsRetentionWireForm.model';

const wireTypeName = 'Pricing Terms/Retention';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role !== SyndicateWiresManagerRole.SellingGroupMember;
};

const getPricingTermsRecipientInputData = (formValues: Values) => {
  const { disclaimer, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<PricingTermsRetentionUserDataForRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        disclaimer,
      };
    });
};

export const wireTypeConfigPricingTermsRetention = createWireTypeConfig<
  Values,
  SyndicateWires_PricingTermsRetentionWirePartsFragment,
  SyndicateWires_PricingTermsRetentionWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresPricingTermsRetention
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresPricingTermsRetention,
  wireTemplateType: WireTemplateType.PricingTermsRetention,
  CreateOrUpdateForm: PricingTermsRetentionWireForm,
  formValidation: validationSchema,

  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_PricingTermsRetentionWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.pricingTermsRetentionWireList,
      isLoading: loading,
      error,
    };
  },
  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_PricingTermsRetentionWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.pricingTermsRetentionWirePreview.htmlContent,
      isLoading: loading,
      error,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_PricingTermsRetentionWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.pricingTermsRetentionWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_PricingTermsRetentionWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy || 'cache-and-network',
      });

    return { data: data?.pricingTermsRetentionWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreatePricingTermsRetentionWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: { userDataForRecipients: getPricingTermsRecipientInputData(values) },
          },
        });

        if (result.data?.createPricingTermsRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createPricingTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdatePricingTermsRetentionWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: { userDataForRecipients: getPricingTermsRecipientInputData(values) },
          },
        });

        if (result.data?.updatePricingTermsRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updatePricingTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeletePricingTermsRetentionWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deletePricingTermsRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendPricingTermsRetentionWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendPricingTermsRetentionWire.__typename === 'ServiceError') {
          throw new Error(
            `Sending the ${wireTypeName} wire failed. 
${result.data.sendPricingTermsRetentionWire?.message}`
          );
        }

        return result.data?.sendPricingTermsRetentionWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    wire,
    managers,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: disclaimerData,
      loading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0]?.disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const recipientsUserData = managers.map<RetentionRecipientUserData>(manager => ({
        recipient: manager.cmgEntityKey,
      }));
      return {
        disclaimer,
        recipientsUserData,
      };
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
    ]);

    return { isLoading: loading, data, error };
  },
});
