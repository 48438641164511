import {
  Divider,
  List,
  ListStatusItem,
  ListStatusItemContent,
  ListSubheader,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { useContactSupportModalContext } from '../../../context/ContactSupportModalContext';
import {
  Recipient,
  RecipientListManagerRole,
  recipientsToRoleGroupedEntries,
} from './RecipientList.model';
import RecipientListWarningIcon from './RecipientListWarningIcon';

export type Props<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly recipients: Recipient<TManagerRole>[];
  readonly title: string;
  readonly withStatusIcon?: boolean;
  readonly noRecipientsComponent?: React.ReactNode;
}>;

function RecipientList<TManagerRole extends RecipientListManagerRole>({
  title,
  recipients,
  withStatusIcon,
  noRecipientsComponent,
}: Props<TManagerRole>) {
  const { open: openContactSupportDialog } = useContactSupportModalContext();

  const groupedRecipients = React.useMemo(() => {
    return recipientsToRoleGroupedEntries(recipients);
  }, [recipients]);

  const handleContactSupport = () => {
    const recipientsWithMissingEmails = recipients.filter(
      ({ distributionList }) => !distributionList.length
    );
    openContactSupportDialog(recipientsWithMissingEmails);
  };

  return (
    <List
      subheader={
        <ListSubheader disableSticky>
          <Typography variant="h4">{title}</Typography>
        </ListSubheader>
      }
    >
      <Divider />
      {groupedRecipients.length === 0 && noRecipientsComponent}
      {groupedRecipients.map(([role, recipients]) => (
        <List
          key={role}
          title={managerRoleLabels[role]}
          dense
          disablePadding
          subheader={
            <ListSubheader disableSticky>
              <Typography variant="subtitle1">{managerRoleLabels[role]}</Typography>
            </ListSubheader>
          }
        >
          {recipients.map(recipient => {
            const hasDistributionEmails = !!recipient.distributionList.length;

            return (
              <ListStatusItem
                title={recipient.firmName}
                key={recipient.cmgEntityKey}
                secondaryAction={
                  !hasDistributionEmails && (
                    <RecipientListWarningIcon onContactSupport={handleContactSupport} />
                  )
                }
              >
                <ListStatusItemContent
                  title={recipient.firmName}
                  status={withStatusIcon ? recipient.status?.color : undefined}
                  subTitle={recipient.status?.label}
                />
              </ListStatusItem>
            );
          })}
        </List>
      ))}
    </List>
  );
}

export default RecipientList;
