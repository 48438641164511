import { getUserFamilyName, getUserGivenName, getUserId } from '@cmg/auth';
import { loggerUtil, mixpanelUtil } from '@cmg/common';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import mixpanelMiddleware from 'remimi';

import { getAppSettings } from '../config/appSettings';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const appSettings = getAppSettings();

const sagaMiddleware = createSagaMiddleware({
  ...(!appSettings.env.isDevelopment
    ? {
        onError: (error, errorInfo) => {
          loggerUtil.error(error, errorInfo);
        },
      }
    : {}),
});

const middlewares = [sagaMiddleware];

if (appSettings.logging.mixPanelToken) {
  const mixpanelOptions = mixpanelUtil.getMixPanelOptions(
    getUserId,
    getUserGivenName,
    getUserFamilyName
  );

  middlewares.push(mixpanelMiddleware(appSettings.logging.mixPanelToken, mixpanelOptions));
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
