import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export type Values = {
  terminationDate: {
    date: string | null;
    timezone: string | null;
  };
  disclaimer: string | null;
};

export const validationSchema = yup
  .object({
    terminationDate: yup.object({
      date: yup
        .date()
        .label('Termination Date')
        .typeError('Invalid date format')
        .nullable()
        .required(),
      timezone: yup.string().label('Time Zone').nullable().required(),
    }),
  })
  .concat(wiresFormDisclaimerSchema);
