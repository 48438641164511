import React from 'react';

import { settlementApiClient } from '../../../../../common/api/settlementApiClient';
import { encodeFileName } from '../../../../../common/util/encodeFileName';
import { OnExportResult } from '../../../common/components/export-button/ExportButton.model';

export function useExportOfferingStabilization({ offeringId, offeringIssuerName }) {
  const [loading, setLoading] = React.useState(false);

  const handleExport = React.useCallback(async (): Promise<OnExportResult> => {
    setLoading(true);
    try {
      const response = await settlementApiClient.stabilization_ExportOfferingExpenses(offeringId);
      return {
        fileContentBlob: response.data,
        encodedFileName: encodeFileName(offeringIssuerName, 'Stabilization'),
      };
    } finally {
      setLoading(false);
    }
  }, [offeringId, offeringIssuerName]);

  return { handleExport, loading };
}
