import {
  NotificationsActiveOutlinedIcon,
  NotificationsNoneOutlinedIcon,
  NotificationsOffOutlinedIcon,
  NotificationsPausedOutlinedIcon,
  SvgIconProps,
} from '@cmg/design-system';
import React from 'react';

import { PushNotificationStatus } from '../types';

export const PushNotificationIcon = ({
  status,
  ...props
}: { status: PushNotificationStatus } & SvgIconProps) => (
  <React.Fragment>
    {{
      enabled: () => <NotificationsActiveOutlinedIcon {...props} />,
      'disabled-byUser': () => <NotificationsPausedOutlinedIcon {...props} />,
      'disabled-byBrowser': () => <NotificationsOffOutlinedIcon {...props} />,
      default: () => <NotificationsNoneOutlinedIcon {...props} />,
    }[status]()}
  </React.Fragment>
);
