import { ApolloError } from '@apollo/client';
import { FlexContainer, UnsavedChangesGuard } from '@cmg/common';
import { FormikConfig, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import SetupForm from '../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../components/form/OfferingSetupFormActions';
import { OfferingSetup_DisclaimersPartsFragment } from '../graphql';
import {
  SErrorSpan,
  SLinkWrapper,
  StyledLinkButton,
  StyledTextInput,
} from './RegistrationLinksForm.styles';

export const RegistrationLinksSchema = yup.object().shape({
  registrationLinks: yup.array().of(
    yup.string().when(([value], schema) => {
      if (!value) return schema;
      return (
        schema
          .test({
            name: 'unique',
            message: 'This field must be unique.',
            test: function (this, value) {
              const matches = this.parent.filter(p => p === value);
              // array is not unique if more than 2 of the same item
              // value already exists in the array once, hence < 2
              return matches.length < 2;
            },
          })
          // The url() yup validation does require the protocol, so strings must contain http or https,
          // disallowing urls like abc.com or www.abc.com. With the custom regex both formats are allowed (with and without protocol)
          .test({
            name: 'invalid',
            message: 'URL is invalid',
            test: function (this, value) {
              const pattern = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])?)\\.)+[a-z]{2,}|' + // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+:]*)*' + // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                  '(\\#[-a-z\\d_]*)?$',
                'i'
              );

              return pattern.test(value!);
            },
          })
      );
    })
  ),
});

type FormType = {
  registrationLinks: readonly string[];
};

export type Props = {
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (payload: FormType) => void;
  disclaimers?: OfferingSetup_DisclaimersPartsFragment;
  errors?: ApolloError;
};

// used in FieldArray to denote which field is actively editing
type EditingField = { index: number; adding?: boolean };

const RegistrationLinksForm: React.FC<Props> = ({
  disabled,
  disclaimers,
  onSubmit,
  loading,
  errors: serverErrors,
}) => {
  const initialValues = {
    registrationLinks: disclaimers?.registrationLinks?.length
      ? disclaimers.registrationLinks
      : [''],
  };

  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    initialValues,
    onSubmit: data => {
      onSubmit({
        registrationLinks:
          data?.registrationLinks && data?.registrationLinks.length > 0
            ? data.registrationLinks
            : [],
      });
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: RegistrationLinksSchema,
  };

  const formik = useFormik(formikOptions);

  const {
    values,
    handleSubmit,
    resetForm,
    touched,
    errors,
    dirty,
    isValid,
    setTouched,
    setFieldValue,
    handleBlur,
  } = formik;
  const [editing, setEditing] = React.useState<EditingField | null>(null);

  // withFormik sets [0] = '' instead of empty array so we can show TextInputField
  const isFieldArrayEmpty =
    values?.registrationLinks?.length === 1 && values.registrationLinks[0] === '';

  React.useEffect(() => {
    if (isFieldArrayEmpty) {
      // shows the empty TextInputField in the FieldArray
      setEditing({ index: 0, adding: true });
    }
  }, [isFieldArrayEmpty]);

  React.useEffect(() => {
    resetForm();
  }, [serverErrors, resetForm]);

  // adds a new item in the FieldArray
  const handleAdd = () => {
    setFieldValue('registrationLinks', [...values.registrationLinks, '']);
    // we're adding = true for handleCancel
    setEditing({ index: values?.registrationLinks.length, adding: true });
    setTouched({});
  };

  // if valid, will save the form
  const handleSave = () => {
    if (isValid && !isFieldArrayEmpty) {
      // save the form
      handleSubmit();
      setEditing(null);
    }
  };

  // cancel when editing or adding an item
  const handleCancel = (index: number) => {
    if (editing?.adding) {
      // adding, not updating, so we can remove this item
      const registrationLinks = [...values.registrationLinks];
      registrationLinks.splice(index, 1);
      setFieldValue('registrationLinks', registrationLinks);
    } else {
      // revert item to original value if exists
      resetForm();
    }
    setEditing(null);
  };

  // deletes the item from array
  const handleDelete = (index: number) => {
    const registrationLinks = [...values.registrationLinks];
    registrationLinks.splice(index, 1);
    setFieldValue('registrationLinks', registrationLinks);

    // save the form
    handleSubmit();
    setEditing(null);
  };

  const handleOnChange = (index: number, value: string) => {
    const registrationLinks = [...values.registrationLinks];
    registrationLinks[index] = value.trim();
    setFieldValue('registrationLinks', registrationLinks);
  };

  return (
    <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
      <SetupForm title="Registration Statement(s)">
        <SetupForm.Table>
          <React.Fragment>
            {values?.registrationLinks?.map((value, index) => (
              <FlexContainer direction="column" key={index}>
                <SLinkWrapper>
                  <StyledTextInput
                    disabled={disabled || editing?.index !== index}
                    autoComplete="off"
                    fullWidth
                    autoFocus
                    onChange={e => handleOnChange(index, e)}
                    value={value}
                    onBlur={handleBlur('registrationLinks')}
                  />
                  {(editing?.index === index || !editing) && (
                    <OfferingSetupFormActions
                      disabled={disabled}
                      disableSave={!value}
                      loading={loading}
                      editing={editing?.index === index}
                      onEdit={() => setEditing({ index })}
                      onSubmit={() => handleSave()}
                      onCancel={() => handleCancel(index)}
                      onDelete={isFieldArrayEmpty ? undefined : () => handleDelete(index)}
                    />
                  )}
                </SLinkWrapper>
                {errors.registrationLinks &&
                  touched.registrationLinks &&
                  editing?.index === index && (
                    <SErrorSpan>{errors.registrationLinks[index]}</SErrorSpan>
                  )}
              </FlexContainer>
            ))}
            <StyledLinkButton
              iconLeft={{ name: 'plus' }}
              onClick={() => handleAdd()}
              disabled={!!editing}
            >
              Add New Link
            </StyledLinkButton>
          </React.Fragment>
        </SetupForm.Table>
      </SetupForm>
    </UnsavedChangesGuard>
  );
};

export default RegistrationLinksForm;
