import { Popover, SelectField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SColumn = styled.div`
  padding: 4px;
  line-height: 1.2;
`;

export const StyledSelectField = styled(SelectField)`
  min-width: 180px;
`;

export const StyledBDAlertPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 5px 8px;
    background-color: ${({ theme }) => theme.background.color.white};
    border-radius: 5px;
    border-color: ${({ theme }) => theme.border.color.light};
  }

  .ant-popover-arrow-content {
    background-color: ${({ theme }) => theme.background.color.white};
    color: ${({ theme }) => theme.background.color.white};
  }
`;

export const SBDAlertPopoverText = styled.div`
  color: ${({ theme }) => theme.color.red1};
`;

export const SSelectFieldWrapper = styled.div<{ bdAlertEnabled?: boolean }>`
  ${({ bdAlertEnabled, theme }) => bdAlertEnabled && `border: 1px solid ${theme.color.red1};`}
  ${({ bdAlertEnabled }) => bdAlertEnabled && `border-radius: 5px;`}
`;
