import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_ManagerBdWiresDocument,
  SyndicateWires_SendManagerBdWireMutation,
  useSyndicateWires_SendManagerBdWireMutation,
} from '../graphql';

export type SendManagerBdWireMutation = SyndicateWires_SendManagerBdWireMutation;

export const useSendManagerBdWireMutation = () => {
  return useSyndicateWires_SendManagerBdWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_ManagerBdWiresDocument];
  return getManyOperationNames(documentNodes);
}
