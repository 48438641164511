import { SalesCreditReleaseStatus } from '../../../../graphql';
import { releaseStatusMap } from '../grid/release-status-renderer/release-status/ReleaseStatus.model';

export type SalesCreditsGridFilter = {
  investor: string;
  releaseStatus: SalesCreditReleaseStatus | null;
  containsErrors: boolean;
};

export const filterInitialValues: SalesCreditsGridFilter = {
  investor: '',
  releaseStatus: null,
  containsErrors: false,
};

export const releaseStatusOptions = Array.from(releaseStatusMap.entries()).map(
  ([value, label]) => ({ value, label })
);
