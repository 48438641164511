import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  RetentionRecipientInputData,
  SyndicateWiresManagerRole,
  WireTemplateType,
} from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_RetentionWirePartsFragment,
  SyndicateWires_RetentionWireValidationPartsFragment,
  useSyndicateWires_RetentionWireDetailsQuery,
  useSyndicateWires_RetentionWirePreviewQuery,
  useSyndicateWires_RetentionWiresQuery,
  useSyndicateWires_RetentionWireValidationQuery,
} from './graphql';
import { useCreateRetentionWireMutation } from './hooks/useCreateRetentionWireMutation';
import { useDeleteRetentionWireMutation } from './hooks/useDeleteRetentionWireMutation';
import { useSendRetentionWireMutation } from './hooks/useSendRetentionWireMutation';
import { useUpdateRetentionWireMutation } from './hooks/useUpdateRetentionWireMutation';
import { RetentionWireForm } from './RetentionWireForm';
import { RetentionRecipientUserData, validationSchema, Values } from './RetentionWireForm.model';

const wireTypeName = 'Retention';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role !== SyndicateWiresManagerRole.SellingGroupMember;
};

const getRetentionRecipientInputData = (formValues: Values) => {
  const { disclaimer, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<RetentionRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        disclaimer,
      };
    });
};

export const wireTypeConfigRetention = createWireTypeConfig<
  Values,
  SyndicateWires_RetentionWirePartsFragment,
  SyndicateWires_RetentionWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresRetention
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresRetention,
  wireTemplateType: WireTemplateType.Retention,
  CreateOrUpdateForm: RetentionWireForm,
  formValidation: validationSchema,

  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useGetInitialValues: ({
    wire,
    managers,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0]?.disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const recipientsUserData = managers.map<RetentionRecipientUserData>(manager => ({
        recipient: manager.cmgEntityKey,
      }));

      return { disclaimer, recipientsUserData };
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
    ]);

    return { isLoading: disclaimerLoading, data, error };
  },

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_RetentionWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.retentionWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_RetentionWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.retentionWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_RetentionWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.retentionWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_RetentionWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.retentionWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateRetentionWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getRetentionRecipientInputData(values),
            },
          },
        });

        if (result.data?.createRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createRetentionWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateRetentionWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getRetentionRecipientInputData(values),
            },
          },
        });

        if (result.data?.updateRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateRetentionWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteRetentionWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId }) => {
        const result = await deleteWire({ variables: { offeringId, syndicateWireId } });

        if (result.data?.deleteRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendRetentionWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendRetentionWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendRetentionWire;
      },
      isLoading: loading,
    };
  },
});
