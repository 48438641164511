import { UUID } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import SetupForm from '../../components/form/OfferingSetupForm';
import { SFormContent } from '../../components/form/OfferingSetupForm.styles';
import SetupScreen from '../../components/screen/OfferingSetupScreen';
import EntitlementsSubNavigation from './components/entitlements-subnavigation/EntitlementsSubNavigation';
import ManagersSidebar from './components/managers-sidebar/ManagersSidebar';
import EntitlementsRoute from './EntitlementsRoute';
import {
  useOfferingSetup_EntitlementsConfigurationQuery,
  useOfferingSetup_OfferingEntitlementsQuery,
} from './graphql';
import {
  ConfigurationByBoundedContext,
  EntitlementsDataByManagerContext,
} from './hooks/EntitlementsDataByManagerContext';
import { useEntitlementByManager } from './hooks/useEntitlementByManager';

const StyledSetupForm = styled(SetupForm)`
  flex: 1;

  & ${SFormContent} {
    flex: 1;
  }
`;

export type Props = {
  offeringId: UUID;
};

/**
 * Offering setup - Managers/Entitlements configuration view
 */
export const Entitlements: React.FC<Props> = ({ offeringId }) => {
  const [selectedManager, setSelectedManager] = React.useState<string | null>(null);
  const { data, loading, error } = useOfferingSetup_OfferingEntitlementsQuery({
    variables: { offeringId },
  });
  const entitlementsByManager = useEntitlementByManager({ data });
  const { data: configuration, error: configurationError } =
    useOfferingSetup_EntitlementsConfigurationQuery();
  const entitlementsConfiguration: ConfigurationByBoundedContext | undefined = React.useMemo(
    () =>
      configuration?.entitlementsConfiguration.reduce((total, current) => {
        return {
          ...total,
          [current.boundedContext]: total[current.boundedContext]
            ? [...total[current.boundedContext], current]
            : [current],
        };
      }, {}),
    [configuration]
  );
  // Set the already selected manager or the first manager on load
  React.useEffect(() => {
    if (!entitlementsByManager) {
      return;
    }
    const cmgEntityKeys = Object.keys(entitlementsByManager);
    if (!cmgEntityKeys.length) {
      return;
    }
    const selectedManagerExists = cmgEntityKeys.includes(selectedManager ?? '');
    const managerToSelect = selectedManagerExists ? selectedManager : cmgEntityKeys[0];
    setSelectedManager(managerToSelect);
  }, [entitlementsByManager, selectedManager]);

  return (
    <StyledSetupForm title="Entitlements">
      {configurationError && <ServerErrorsBanner error={configurationError} />}
      {error && <ServerErrorsBanner error={error} />}
      {loading && (
        <SetupScreen.LoadingWrapper>
          <Spinner show />
        </SetupScreen.LoadingWrapper>
      )}
      {data && (
        <EntitlementsDataByManagerContext.Provider
          value={{
            entitlementsByManager,
            entitlementsConfiguration: entitlementsConfiguration,
            selectedManagerEntitlements: selectedManager
              ? entitlementsByManager[selectedManager]
              : null,
          }}
        >
          <FlexLayout direction="row" expand>
            <ManagersSidebar
              selectedManager={selectedManager}
              onSelect={setSelectedManager}
              managers={entitlementsByManager}
            />
            <FlexLayout direction="column" expand>
              <EntitlementsSubNavigation offeringId={offeringId} />
              <EntitlementsRoute offeringId={offeringId} />
            </FlexLayout>
          </FlexLayout>
        </EntitlementsDataByManagerContext.Provider>
      )}
    </StyledSetupForm>
  );
};

export default Entitlements;
