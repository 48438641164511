import { Button, InputAdornment, SvgIcon, useTheme } from '@cmg/design-system';
import { FormikAsyncAutocompleteField } from '@cmg/design-system-formik';
import { useField } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { DEBOUNCE_TIMEOUT } from '../../../../features/constants';
import { Props as CreateFirmDialogProps } from '../../../../features/order-book/my-retail-demand/page-layout/auto-save/table-row/create-broker-dealer-dialog/CreateBrokerDealerDialog';
import { BrokerValues } from '../../../../features/order-book/my-retail-demand/page-layout/auto-save/table-row/InternalRetailDemandTableRow.model';
import { ReactComponent as CmgLogo } from '../../../assets/identity-icon.svg';
import { useUnderwriterFirmSearch_UnderwritersLazyQuery } from './graphql';
import UnderwriterFirmListItem from './UnderwriterFirmListItem';
import { createOptions, UnderwriterFirm } from './UnderwriterFirmSearchField.model';

export type UnderwriterFirmSearchFieldProps = Readonly<{
  name: string;
  allBrokers: BrokerValues['broker'][];
  disabled: boolean;
  showHelperTextInTooltip?: boolean;
  noOptionsText?: string;
  CreateFirmDialogComponent?: React.ElementType<CreateFirmDialogProps>;
}>;

const UnderwriterFirmSearchField: React.FC<UnderwriterFirmSearchFieldProps> = ({
  name,
  allBrokers,
  disabled,
  showHelperTextInTooltip,
  noOptionsText = 'Start typing to load Underwriters or Create new',
  CreateFirmDialogComponent,
}) => {
  const theme = useTheme();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [field, , fieldHelpers] = useField<UnderwriterFirm | undefined>(name);

  const [fetchUnderwriters, { loading }] = useUnderwriterFirmSearch_UnderwritersLazyQuery();
  const handleFetchOptions = React.useCallback(
    async (searchedString: string, page?: number) => {
      const { data } = await fetchUnderwriters({
        variables: { searchedString, page: page ?? 1 },
      });

      const hasNext = !!data?.underwriters?.pagination?.hasNext;

      return {
        data: createOptions(data?.underwriters?.data ?? []),
        pagination: { ...(data?.underwriters?.pagination ?? {}), hasNext },
      };
    },
    [fetchUnderwriters]
  );

  const handleCreateFirm = React.useCallback(
    (firmName: string) => {
      const underwriterFirm: UnderwriterFirm = {
        firmCmgEntityKey: null,
        firmName,
        isOnPlatform: false,
        mpid: null,
        crd: null,
      };
      fieldHelpers.setValue(underwriterFirm);
      setIsCreateDialogOpen(false);
    },
    [fieldHelpers]
  );

  return (
    <React.Fragment>
      <FormikAsyncAutocompleteField<UnderwriterFirm>
        aria-label="underwriter firm search field"
        showHelperTextInTooltip={showHelperTextInTooltip}
        onFetchOptions={handleFetchOptions}
        fetchOptionsDebounceTimeout={DEBOUNCE_TIMEOUT}
        name={name}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }

          return option.firmName ?? '';
        }}
        renderOption={(props, option) => <UnderwriterFirmListItem {...props} option={option} />}
        ListboxProps={{
          style: {
            maxHeight: theme.spacing(40),
          },
        }}
        isOptionEqualToValue={isEqual}
        footerButton={
          <Button variant="text" onClick={() => setIsCreateDialogOpen(true)}>
            Create New
          </Button>
        }
        TextFieldProps={{
          InputProps: {
            startAdornment: field.value?.isOnPlatform && (
              <InputAdornment position="start">
                <SvgIcon fontSize="medium">
                  <CmgLogo />
                </SvgIcon>
              </InputAdornment>
            ),
          },
        }}
        isLoadingOptions={loading}
        disabled={disabled}
        noOptionsText={noOptionsText}
        filterSelectedOptions
        required
        fullWidth
      />
      {CreateFirmDialogComponent && (
        <CreateFirmDialogComponent
          allBrokers={allBrokers}
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onCreate={handleCreateFirm}
        />
      )}
    </React.Fragment>
  );
};

export default UnderwriterFirmSearchField;
