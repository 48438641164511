import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

// Exported for testing
export const getRecipientUserDataValidationSchema = () => {
  return yup
    .object()
    .shape({
      // .default(null) is to comply with yup.SchemaOf<Values> instead we would get error prompting we cannot have undefined in schema
      headerNote: yup.string().label('Header note').nullable().default(null),
      recipient: yup.string().label('Recipient').required(),
    })
    .nullable();
};

export const validationSchema = yup
  .object()
  .shape({
    recipientsUserData: yup
      .array()
      .of(getRecipientUserDataValidationSchema())
      .label('Recipients')
      .min(1)
      .required()
      .test(
        'non-nullable-data',
        'At least one manager must have user data',
        (value: Values['recipientsUserData'] | undefined) => {
          return !!value?.some(data => data !== null);
        }
      ),
  })
  .concat(wiresFormDisclaimerSchema);

export type DesignationRecipientUserData = {
  recipient: string;
  headerNote: string | null | undefined;
};

export type Values = {
  disclaimer: string | null;
  recipientsUserData: Array<DesignationRecipientUserData | null>;
};
