import styled from 'styled-components/macro';

export const SIconWrapper = styled.div`
  padding: 4px 8px;
  border-left: ${({ theme }) => theme.border.smallSolidLight};
`;

export const SWrapper = styled.div`
  max-width: 400px;
`;
