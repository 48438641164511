import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EventInfo, eventInfoString } from './EventHeaderInfo.model';
import { StyledIcon, SWrapper } from './EventHeaderInfo.styles';

export type Props = { eventInfo: EventInfo };

const EventHeaderInfo: React.FC<Props> = ({ eventInfo }) => {
  const theme = useTheme();

  return (
    <SWrapper data-test-id="event header info">
      <StyledIcon
        name="check-circle"
        variant="regular"
        size="lg"
        className="saved-state-icon"
        color={theme.text.color.positive}
      />
      {eventInfoString[eventInfo]}
    </SWrapper>
  );
};

export default EventHeaderInfo;
