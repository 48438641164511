import {
  ErrorIcon,
  Stack,
  TableCell,
  TableCellProps,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import React from 'react';

type TableTotalCellProps = Readonly<{
  primaryText: string;
  secondaryText: string;
  tableCellProps?: TableCellProps;
  errorMessage?: string;
  mimicFormInputPaddings?: boolean;
}>;

export function TableTotalCell({
  tableCellProps,
  primaryText,
  secondaryText,
  errorMessage,
  mimicFormInputPaddings,
}: TableTotalCellProps) {
  return (
    <TableCell {...tableCellProps} sx={mimicFormInputPaddings ? { paddingRight: '22px' } : {}}>
      <Stack justifyContent="right" direction="row" alignItems="center" gap={1}>
        {errorMessage && (
          <Tooltip title={errorMessage} variant="error">
            <ErrorIcon color="error" fontSize="small" />
          </Tooltip>
        )}
        <Stack alignItems="end">
          <Typography variant="highlight1" color="white">
            {primaryText}
          </Typography>
          <Typography variant="body2" color="secondaryDarkBackground.main">
            {secondaryText}
          </Typography>
        </Stack>
      </Stack>
    </TableCell>
  );
}
