import { NetworkStatus } from '@apollo/client';

export type loadingStateBehavior = 'default' | 'false-on-refetch';
type loadingStateBehaviorHandler = (loading: boolean, networkStatus: NetworkStatus) => boolean;

/**
 * Loading state of a wrapped query can be controled through withSubscription. Since withSubscription calls refetch, and refetch affects loading state for the
 * associated query, this map contains the keyed handlers that help control the query's loading state.
 **/
export const loadingStateBehaviorMap: Record<loadingStateBehavior, loadingStateBehaviorHandler> = {
  // default behavior is simply pass-through.
  default: loading => loading,
  // false-on-refetch behavior blocks loading state changes when the query is refetching.
  // This is necessary to block loading: true when calling refetch when using cache-and-network fetch policy.
  // See video with background: https://github.com/capital-markets-gateway/js/pull/9517/files#r1430320453
  'false-on-refetch': (loading, networkStatus) =>
    loading && networkStatus !== NetworkStatus.refetch,
};
