import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import UnsavedChangesModal from '../unsaved-changes-modal/UnsavedChangesModal';

type FormikUnsavedChangesModalProps = {
  show: boolean;
  onLeave?: () => void;
  onStay?: () => void;
  onHide?: () => void;
};

const FormikUnsavedChangesModal: React.FC<FormikUnsavedChangesModalProps> = ({
  show,
  onLeave,
  onStay,
  onHide,
  ...props
}) => {
  const formik = useFormikContext();

  const { submitForm, isSubmitting, isValid } = formik;

  const handleContinueEditing = useCallback(() => {
    if (onStay && onHide) {
      onStay();
      onHide();
    }
  }, [onStay, onHide]);

  const handleDiscardAndLeave = useCallback(() => {
    if (onLeave && onHide) {
      onLeave();
      onHide();
    }
  }, [onLeave, onHide]);

  const handleSave = useCallback(async () => {
    try {
      await submitForm();
      onLeave && onLeave();
    } catch (error) {
      onStay && onStay();
    }

    onHide && onHide();
  }, [onHide, onLeave, onStay, submitForm]);

  return (
    <UnsavedChangesModal
      open={show}
      isSaving={isSubmitting}
      onContinueEditing={handleContinueEditing}
      onSave={handleSave}
      onDiscardAndLeave={handleDiscardAndLeave}
      isSaveButtonDisabled={!isValid}
    />
  );
};

export default FormikUnsavedChangesModal;
