import { getOperationName } from '@apollo/client/utilities';
import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import merge from 'lodash/merge';

import { OrderBookState } from '../../../graphql';
import {
  OrderBook_OrderBookGraphDocument,
  useOrderBook_OrderBookGraphQuery,
  useOrderBook_UpdateOrderBookSalesStateMutation,
  useWithOrderBook_OrderBookSalesStateChangedSubscription,
} from '../graphql';

export type Props = Readonly<{
  offeringId: UUID;
}>;

export const useUpdateOrderBookStatusForSales = ({ offeringId }) => {
  const query = useOrderBook_OrderBookGraphQuery({
    variables: { offeringId },
  });

  const { data, loading } = query;
  const orderBookSalesState = data?.orderBookGraph?.salesState;

  const [updateOrderBookSalesState] = useOrderBook_UpdateOrderBookSalesStateMutation();

  useWithOrderBook_OrderBookSalesStateChangedSubscription(query, {
    variables: () => {
      return { offeringId };
    },
    loadingStateBehavior: 'false-on-refetch',
    updateQuery:
      () =>
      (prev, { subscriptionData }) =>
        merge({}, prev, {
          orderBookGraph: {
            salesState: subscriptionData.data.orderBookSalesStateChanged.salesState,
          },
        }),
  });

  const handleOnUpdateOrderBookSalesState = () => {
    updateOrderBookSalesState({
      variables: {
        input: {
          offeringId,
          state:
            orderBookSalesState === OrderBookState.Closed
              ? OrderBookState.Open
              : OrderBookState.Closed,
        },
      },
      onCompleted: data => {
        if (data.updateOrderBookSalesState?.orderBookState === OrderBookState.Closed) {
          SnackbarManager.success('Internal Order Book has been closed.');
        } else if (data.updateOrderBookSalesState?.orderBookState === OrderBookState.Open) {
          SnackbarManager.success('Internal Order Book has been opened.');
        }
      },
      onError: () => {
        SnackbarManager.error('Failed to close Internal Order Book.');
      },
      refetchQueries: [getOperationName(OrderBook_OrderBookGraphDocument)!],
    });
  };

  return {
    loading,
    orderBookSalesState,
    handleOnUpdateOrderBookSalesState,
  };
};
