import { useReactiveVar } from '@apollo/client';
import { AgGridApi } from '@cmg/common';
import { AgGridEvent, ColDef } from 'ag-grid-community';
import React, { useState } from 'react';

import { pendingOrderBookGridForcedRefreshVar } from '../../../../variables';
import {
  InstitutionalDemandGrid_AttestationStatusesQuery,
  InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
  InstitutionalDemandGrid_SummaryQuery,
} from '../graphql';
import {
  exportDataAsCsvProcessCellCallback,
  getGridSummaryDataFromQuery,
} from '../InstitutionalDemandGrid.model';
import { CMGColumnID, InstitutionalDemand, InstitutionalGridSummary } from '../types';
import { useComputeDiffOnInstitutionalDemandGrid } from './data-refresh-hooks/useComputeDiffOnInstitutionalDemandGrid';

const DEFAULT_COMPARATOR: UseOnDemandRefreshComparatorFn<unknown> = (a, b) => a !== b;
export type GridColumnType = 'grid' | 'text';
export type UseOnDemandRefreshComparatorFn<T> = (a: T, b: T) => boolean;
export type UseOnDemandRefreshFn = () => void;
export interface UseOnDemandRefreshResult<T> {
  readonly isDiff: boolean;
  readonly value: T;
  readonly prevValue: T;
  readonly refresh: UseOnDemandRefreshFn;
}

export const useHandleDataRefresh = (
  institutionalDemandGridSummary:
    | InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']
    | undefined,
  nextInstitutionalDemandGridSummary:
    | InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']
    | undefined,
  refresh: UseOnDemandRefreshFn,
  settlementAgent: string | null
) => {
  const { recentUpdates, setRecentUpdates } = useComputeDiffOnInstitutionalDemandGrid(
    institutionalDemandGridSummary,
    nextInstitutionalDemandGridSummary,
    settlementAgent
  );

  const updatesContextValue = React.useMemo(
    () => ({ recentUpdates, setRecentUpdates }),
    [recentUpdates, setRecentUpdates]
  );

  React.useEffect(() => {
    if (recentUpdates.length > 0) {
      refresh();
    }
  }, [recentUpdates, refresh]);

  // We listen to this observable variable that is being updated across the app when this query has been forced to be fetched.
  const pendingGridForcedRefresh = useReactiveVar(pendingOrderBookGridForcedRefreshVar);
  React.useEffect(() => {
    if (pendingGridForcedRefresh) {
      refresh();
      pendingOrderBookGridForcedRefreshVar(false);
    }
  }, [pendingGridForcedRefresh, refresh]);

  return { recentUpdates, updatesContextValue };
};

const findInvestorEmployeeRelationships: (options: {
  investorFirmsEmployeeRelationshipsData:
    | InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery
    | undefined;
  bankInvestorKey: string | undefined | null;
}) => InstitutionalDemand['investorEmployeeRelationships'] | null = ({
  investorFirmsEmployeeRelationshipsData,
  bankInvestorKey,
}) => {
  if (investorFirmsEmployeeRelationshipsData) {
    return bankInvestorKey
      ? investorFirmsEmployeeRelationshipsData.investorFirmsEmployeeRelationships.find(
          investorFirmsEmployeeRelationship =>
            investorFirmsEmployeeRelationship.bankInvestorKey === bankInvestorKey
        )?.relationships || null
      : null;
  }

  return null;
};

export const useGetDemandGridSummaryData = (
  institutionalDemandGridSummaryData: InstitutionalDemandGrid_SummaryQuery | undefined,
  attestationStatusesData: InstitutionalDemandGrid_AttestationStatusesQuery | undefined,
  investorFirmsEmployeeRelationshipsData:
    | InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery
    | undefined
): InstitutionalGridSummary | undefined => {
  const [institutionalGridSummary, setInstitutionalGridSummary] = useState<
    InstitutionalGridSummary | undefined
  >(undefined);

  React.useEffect(() => {
    if (institutionalDemandGridSummaryData !== undefined) {
      const mappedGridSummaryData = getGridSummaryDataFromQuery(institutionalDemandGridSummaryData);

      setInstitutionalGridSummary({
        ...mappedGridSummaryData,
        indicationDemands: mappedGridSummaryData.indicationDemands.map(
          (indicationDemand, index) => {
            return {
              ...indicationDemand,
              attestationStatus: attestationStatusesData?.attestationStatuses[index] ?? null,
              investorEmployeeRelationships: findInvestorEmployeeRelationships({
                investorFirmsEmployeeRelationshipsData,
                bankInvestorKey: indicationDemand.investorInformation.bankInvestorKey,
              }),
            };
          }
        ),
      });
    }
  }, [
    institutionalDemandGridSummaryData,
    investorFirmsEmployeeRelationshipsData,
    attestationStatusesData?.attestationStatuses,
    attestationStatusesData,
  ]);

  return institutionalGridSummary;
};

export const useExportToCSV = (
  exportToCsv: React.MutableRefObject<Function>,
  gridRef: React.MutableRefObject<AgGridEvent | undefined>
) => {
  React.useEffect(() => {
    exportToCsv.current = () => {
      if (!gridRef?.current) {
        return;
      }
      const displayedColumns = [
        // Adding the indication status column to the export only. This column is not displayed in the grid.
        CMGColumnID.INDICATION_STATUS,
        ...gridRef.current.columnApi.getAllDisplayedColumns().map(column => column.getColId()),
      ];

      gridRef.current.api.exportDataAsCsv({
        fileName: `institutional_demand`,
        processCellCallback: exportDataAsCsvProcessCellCallback,
        shouldRowBeSkipped: row => row.node.isRowPinned(),
        columnKeys: [
          ...displayedColumns.filter(
            id =>
              id !== CMGColumnID.ROW_ALERT &&
              id !== CMGColumnID.SELECTED &&
              id !== CMGColumnID.DUPE_GROUP
          ),
        ],
      });
    };
  }, [exportToCsv, gridRef]);
};

export const useDeselectAllRows = (
  deselectRows: React.MutableRefObject<Function>,
  gridRef: React.MutableRefObject<AgGridEvent | undefined>
) => {
  React.useEffect(() => {
    deselectRows.current = () => {
      if (!gridRef?.current) {
        return;
      }
      gridRef.current.api.deselectAll();
    };
  }, [deselectRows, gridRef]);
};

/**
 * Keeps track of a changing value, only returning the latest value when using the `refresh()` callback.
 * * @example
 * Here's a simple example:
 * ```
 * // We set the initial value:
 * const { isDiff, value, refresh() } = useOnDemandRefresh(data)
 * ...
 * // On some callback
 * const onClick = () => {
 *     if(isDiff === true) {
 *        refresh()
 *     }
 * }
 * ```
 * @param nextValue Pass the next value.
 * @param comparator A {@link UseOnDemandRefreshComparatorFn comparator function}, returns `true` if a and b are different, `false` otherwise.
 * Defaults to shallow comparison.
 * @returns Returns {@link UseOnDemandRefreshResult a result} with the current value and an `isDiff` flag
 */
export const useOnDemandRefresh = <T>(
  nextValue: T,
  comparator: UseOnDemandRefreshComparatorFn<T> = DEFAULT_COMPARATOR
): UseOnDemandRefreshResult<T> => {
  const [currValue, setCurrValue] = React.useState<T>(nextValue);
  const [prevValue, setPrevValue] = React.useState<T>(nextValue);
  const hasFiredRefreshOnceRef = React.useRef(false);

  const refresh = React.useCallback(() => {
    if (currValue) {
      setPrevValue(currValue);
    }
    if (nextValue) {
      setCurrValue(nextValue);
    }
  }, [currValue, nextValue]);

  const isDiff = comparator(currValue, nextValue);

  const result = React.useMemo<UseOnDemandRefreshResult<T>>(
    () => ({ isDiff, refresh, value: currValue, prevValue }),
    [currValue, isDiff, prevValue, refresh]
  );

  if (!hasFiredRefreshOnceRef.current && nextValue) {
    refresh();
    hasFiredRefreshOnceRef.current = true;
  }

  return result;
};

export type GridManagerArgs = {
  gridApi: AgGridApi | null;
  columns: ColDef[];
  sortFn?: (a: ColDef, b: ColDef) => number;
};
