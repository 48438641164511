/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemandGrid_UpdateDraftAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  setId: Types.Scalars['String'];
  indicationId: Types.Scalars['UUID'];
  input: Types.DraftAllocationUpdateInput;
}>;

export type InstitutionalDemandGrid_UpdateDraftAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDraftInstitutionalAllocation: {
    readonly __typename?: 'DraftAllocation';
    readonly id: string;
    readonly shareQuantity?: number | null;
  };
};

export type InstitutionalDemandGrid_UpdateFinalAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  input: Types.FinalAllocationUpdateInput;
}>;

export type InstitutionalDemandGrid_UpdateFinalAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFinalInstitutionalAllocation: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly shareQuantity?: number | null;
  };
};

export const InstitutionalDemandGrid_UpdateDraftAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_UpdateDraftAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DraftAllocationUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftInstitutionalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_UpdateDraftAllocationMutationFn = Apollo.MutationFunction<
  InstitutionalDemandGrid_UpdateDraftAllocationMutation,
  InstitutionalDemandGrid_UpdateDraftAllocationMutationVariables
>;

/**
 * __useInstitutionalDemandGrid_UpdateDraftAllocationMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_UpdateDraftAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_UpdateDraftAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridUpdateDraftAllocationMutation, { data, loading, error }] = useInstitutionalDemandGrid_UpdateDraftAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      setId: // value for 'setId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_UpdateDraftAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_UpdateDraftAllocationMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_UpdateDraftAllocationMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationMutationVariables
  >(InstitutionalDemandGrid_UpdateDraftAllocationDocument, options);
}
export type InstitutionalDemandGrid_UpdateDraftAllocationMutationHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_UpdateDraftAllocationMutation
>;
export type InstitutionalDemandGrid_UpdateDraftAllocationMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_UpdateDraftAllocationMutation>;
export type InstitutionalDemandGrid_UpdateDraftAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_UpdateDraftAllocationMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationMutationVariables
  >;
export const InstitutionalDemandGrid_UpdateFinalAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_UpdateFinalAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FinalAllocationUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinalInstitutionalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_UpdateFinalAllocationMutationFn = Apollo.MutationFunction<
  InstitutionalDemandGrid_UpdateFinalAllocationMutation,
  InstitutionalDemandGrid_UpdateFinalAllocationMutationVariables
>;

/**
 * __useInstitutionalDemandGrid_UpdateFinalAllocationMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_UpdateFinalAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_UpdateFinalAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridUpdateFinalAllocationMutation, { data, loading, error }] = useInstitutionalDemandGrid_UpdateFinalAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_UpdateFinalAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_UpdateFinalAllocationMutation,
    InstitutionalDemandGrid_UpdateFinalAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_UpdateFinalAllocationMutation,
    InstitutionalDemandGrid_UpdateFinalAllocationMutationVariables
  >(InstitutionalDemandGrid_UpdateFinalAllocationDocument, options);
}
export type InstitutionalDemandGrid_UpdateFinalAllocationMutationHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_UpdateFinalAllocationMutation
>;
export type InstitutionalDemandGrid_UpdateFinalAllocationMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_UpdateFinalAllocationMutation>;
export type InstitutionalDemandGrid_UpdateFinalAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_UpdateFinalAllocationMutation,
    InstitutionalDemandGrid_UpdateFinalAllocationMutationVariables
  >;
