import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Loading from '../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import { InternalSalesCreditReleaseStatus } from '../../graphql';
import { SalesCreditsProvider } from './context/SalesCreditsContext';
import SalesCreditsDetailsPanel from './details-panel/SalesCreditsDetailsPanel';
import SalesCreditsEmptyState from './empty-state/SalesCreditsEmptyState';
import {
  useSalesCredits_ReleaseSalesCreditsMutation,
  useSalesCredits_SalesCreditsDetailsQuery,
  useSalesCredits_UpdateSalesCreditsStatusMutation,
} from './graphql';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps & {
  offeringIssuerName?: string;
};

const SalesCreditsRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.salesCredits.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const { data, loading, error } = useSalesCredits_SalesCreditsDetailsQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const [releaseSalesCredits, { loading: isReleasingSalesCredits }] =
    useSalesCredits_ReleaseSalesCreditsMutation();
  const [updateSalesCreditsStatus, { loading: isUpdatingSalesCreditsStatus }] =
    useSalesCredits_UpdateSalesCreditsStatusMutation();

  const handleReleaseSalesCredits = React.useCallback(
    async (investorFirmCmgEntityKeys: UUID[]) => {
      try {
        if (investorFirmCmgEntityKeys.length === 0) {
          return;
        }

        await releaseSalesCredits({ variables: { offeringId, investorFirmCmgEntityKeys } });

        ToastManager.success('Sales Credits released');
      } catch {
        ToastManager.error('Failed to release Sales Credits');
      }
    },
    [releaseSalesCredits, offeringId]
  );

  const handleUpdateSalesCreditStatus = React.useCallback(
    async (status: InternalSalesCreditReleaseStatus, salesCreditIds: string[]) => {
      try {
        if (salesCreditIds.length === 0) {
          return;
        }

        await updateSalesCreditsStatus({
          variables: { offeringId, payload: { status, salesCreditIds } },
        });

        ToastManager.success('Successfully updated Sales Credits status');
      } catch {
        ToastManager.error('Failed to update Sales Credits status');
      }
    },
    [updateSalesCreditsStatus, offeringId]
  );

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (data?.salesCredits.salesCredits.length !== 0) {
    return (
      <SalesCreditsProvider>
        <SalesCreditsDetailsPanel
          offeringIssuerName={offeringIssuerName ?? 'issuer'}
          salesCreditsData={data?.salesCredits!}
          offeringId={offeringId}
          onRelease={handleReleaseSalesCredits}
          isReleasing={isReleasingSalesCredits}
          onUpdateStatus={handleUpdateSalesCreditStatus}
          isUpdatingStatus={isUpdatingSalesCreditsStatus}
        />
      </SalesCreditsProvider>
    );
  }

  return <SalesCreditsEmptyState offeringId={offeringId} offeringIssuerName={offeringIssuerName} />;
};

export default SalesCreditsRoute;
