import { numericUtil, ODSTable as Table } from '@cmg/common';
import isNil from 'lodash/isNil';

import { FinalSettlement_SettlementLetterQuery } from '../graphql';
import { getTotalValues } from './SettlementLetterDebitCreditTable.model';
import {
  StyledTable,
  StyledTableHeader,
  StyledUnderlinedCell,
} from './SettlementLetterDebitCreditTable.styles';

type Props = {
  data: FinalSettlement_SettlementLetterQuery['settlementLetter'];
};

const DebitCreditTable: React.FC<Props> = ({ data }) => {
  const { totalCredit, totalDebit } = getTotalValues(data);
  const {
    managementFee,
    managementPercentage,
    underwritingFee,
    underwritingShares,
    underwritingSpread,
    designatedShares,
    sellingConcession,
    sellingConcessionSpread,
    managerExpensesReimbursements,
    managerDealRelatedExpenses,
    adjustmentValue,
    managerGainLossOnOversale,
    adjustmentDescription,
    underwritingPercentage,
    finalSettlement,
  } = data;
  return (
    <StyledTable gridTemplateColumns="28% 36% 18% 18%">
      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <StyledTableHeader withRightBorder={false} bold>
          Description
        </StyledTableHeader>
        <StyledTableHeader withRightBorder={false} bold>
          Details
        </StyledTableHeader>
        <StyledTableHeader withRightBorder={false} bold align="right">
          Debit
        </StyledTableHeader>
        <StyledTableHeader withRightBorder={false} bold align="right">
          Credit
        </StyledTableHeader>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Management Fee</Table.Cell>
        <Table.Cell paddingSize="S">
          {numericUtil.getDisplayValueForPercents(managementPercentage, 2)}
        </Table.Cell>
        <Table.Cell paddingSize="S" />
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(managementFee, 2)}
        </Table.Cell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Underwriting Fee</Table.Cell>
        <Table.Cell paddingSize="S">
          {numericUtil.getDisplayValueForCurrency(underwritingSpread, 2)} on{' '}
          {numericUtil.getDisplayValueForNumber(underwritingShares, 0)} shares
        </Table.Cell>
        <Table.Cell paddingSize="S" />
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(underwritingFee, 2)}
        </Table.Cell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Selling Concession on Designated shares</Table.Cell>
        <Table.Cell paddingSize="S" centered>
          {numericUtil.getDisplayValueForCurrency(sellingConcessionSpread, 2)} on{' '}
          {numericUtil.getDisplayValueForNumber(designatedShares, 0)} shares
        </Table.Cell>
        <Table.Cell paddingSize="S" />
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(sellingConcession, 2)}
        </Table.Cell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Manager Expense Reimbursement</Table.Cell>
        <Table.Cell paddingSize="S" />
        <Table.Cell paddingSize="S" />
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(managerExpensesReimbursements, 2)}
        </Table.Cell>
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">Deal Related Expenses </Table.Cell>
        <Table.Cell paddingSize="S">
          at {numericUtil.getDisplayValueForPercents(underwritingPercentage)}
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right">
          {numericUtil.getDisplayValueForCurrency(
            numericUtil.negate(managerDealRelatedExpenses),
            2
          )}
        </Table.Cell>
        <Table.Cell paddingSize="S" />
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S">
          {isNil(managerGainLossOnOversale) || managerGainLossOnOversale < 0
            ? 'Total Loss on Oversale'
            : 'Total Gain on Oversale'}
        </Table.Cell>
        <Table.Cell paddingSize="S">
          at {numericUtil.getDisplayValueForPercents(underwritingPercentage)}
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right">
          {(isNil(managerGainLossOnOversale) || managerGainLossOnOversale < 0) &&
            numericUtil.getDisplayValueForCurrency(
              numericUtil.negate(managerGainLossOnOversale),
              2
            )}
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right">
          {!isNil(managerGainLossOnOversale) &&
            managerGainLossOnOversale > 0 &&
            numericUtil.getDisplayValueForCurrency(managerGainLossOnOversale, 2)}
        </Table.Cell>
      </Table.Row>

      {adjustmentValue !== 0 && (
        <Table.Row data-test-id="manual-adjustment" isFocused={false} suppressHighlightingOnHover>
          <Table.Cell paddingSize="S">Manual Adjustment</Table.Cell>
          <Table.Cell paddingSize="S">{adjustmentDescription}</Table.Cell>
          <Table.Cell paddingSize="S" align="right">
            {(isNil(adjustmentValue) || adjustmentValue < 0) &&
              numericUtil.getDisplayValueForCurrency(numericUtil.negate(adjustmentValue), 2)}
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right">
            {!isNil(adjustmentValue) &&
              adjustmentValue > 0 &&
              numericUtil.getDisplayValueForCurrency(adjustmentValue, 2)}
          </Table.Cell>
        </Table.Row>
      )}

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <StyledUnderlinedCell paddingSize="S" bold>
          Check/Wire Payable
        </StyledUnderlinedCell>
        <StyledUnderlinedCell paddingSize="S" />
        <StyledUnderlinedCell paddingSize="S" align="right" bold>
          {numericUtil.getDisplayValueForCurrency(finalSettlement, 2)}
        </StyledUnderlinedCell>
        <StyledUnderlinedCell paddingSize="S" align="right" bold />
      </Table.Row>

      <Table.Row isFocused={false} suppressHighlightingOnHover>
        <Table.Cell paddingSize="S" withBottomBorder={false} />
        <Table.Cell paddingSize="S" withBottomBorder={false} />
        <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
          {numericUtil.getDisplayValueForCurrency(totalDebit, 2)}
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
          {numericUtil.getDisplayValueForCurrency(totalCredit, 2)}
        </Table.Cell>
      </Table.Row>
    </StyledTable>
  );
};

export default DebitCreditTable;
