import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_LogisticsDocument,
  Roadshows_UpdateLogisticsParticipantsMutation,
  useRoadshows_UpdateLogisticsParticipantsMutation,
} from '../graphql';

export type UpdateLogisticsParticipantsMutation = Roadshows_UpdateLogisticsParticipantsMutation;

export function refetchQueries() {
  const refetchLogisticsDetails = getOperationName(Roadshows_LogisticsDocument)!;
  const refetchEventList = getOperationName(Roadshows_RoadshowEventsDocument)!;

  return [refetchLogisticsDetails, refetchEventList];
}

export const useUpdateLogisticsParticipantsMutation = () => {
  return useRoadshows_UpdateLogisticsParticipantsMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
