import React from 'react';
import { Link } from 'react-router-dom';

import ErrorRenderer from '../../../../common/components/errorRenderer/Error-Renderer';
import Banner from '../../../../common/components/indicators/banner/Banner';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import { useOfferingSidePanel_LinkedOfferingQuery } from '../../graphql';
import { OfferingSidePanelRouteContext } from '../../OfferingSidePanelContainer';
import {
  buildOfferingSidePanelLink,
  getNotesAndOrdersAndCommunicatedDemandText,
} from './SupersedeOfferingBanner.model';
import {
  SBannerContainer,
  SContent,
  SHeader,
  SHeaderContainer,
} from './SupersedeOfferingBanner.styles';

export const SupersedeOfferingBanner = ({ isSellSide }: { isSellSide: boolean }) => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  const {
    supersededByOfferingId,
    supersededOfferingId,
    isObsoleteOffering,
    hasNotes,
    hasOrders,
    hasCommunicatedDemand,
  } = useSupersededOfferingContext();

  const linkedOfferingId = supersededByOfferingId || supersededOfferingId;

  const {
    data: linkedOfferingData,
    loading: isLinkedOfferingLoading,
    error: linkedOfferingError,
  } = useOfferingSidePanel_LinkedOfferingQuery({
    variables: { offeringId: linkedOfferingId! },
    skip: !linkedOfferingId,
  });

  if (linkedOfferingError) {
    return <ErrorRenderer error={linkedOfferingError} />;
  }

  if (isLinkedOfferingLoading) {
    return <Spinner show />;
  }

  const notesAndOrdersAndCommunicatedDemand = getNotesAndOrdersAndCommunicatedDemandText({
    hasNotes,
    hasOrders,
    hasCommunicatedDemand,
  });

  if (!(hasNotes || hasOrders || isObsoleteOffering || hasCommunicatedDemand)) {
    return null;
  }

  return (
    <Banner variant={isObsoleteOffering ? 'error' : 'information'}>
      <SBannerContainer>
        <SHeaderContainer>
          <SHeader>{isObsoleteOffering ? 'Obsolete' : 'Digital'} Offering</SHeader>
          <Link
            to={buildOfferingSidePanelLink({
              actualBaseUrl: baseSidePanelUrl,
              offeringId: linkedOfferingId!,
              hasNotes,
              isObsoleteOffering: !isObsoleteOffering,
            })}
          >
            View {isObsoleteOffering ? 'Digital' : 'Obsolete'} Offering
          </Link>
        </SHeaderContainer>
        <SContent>
          {isObsoleteOffering
            ? `This offering record has been replaced by a digital offering ${linkedOfferingData?.basicPublishedOffering.issuerName} that is communicated via the CMG XC network.`
            : `This offering will be digitally communicated via the CMG XC network and replaces the previous record ${linkedOfferingData?.basicPublishedOffering.issuerName}.`}{' '}
          {!isSellSide &&
            (hasNotes || hasOrders || hasCommunicatedDemand) &&
            (isObsoleteOffering
              ? `You have ${notesAndOrdersAndCommunicatedDemand} recorded on this offering that should be copied to the digital offering record.`
              : `You had entered ${notesAndOrdersAndCommunicatedDemand} on the previous offering which should be copied to the current digital offering.`)}
        </SContent>
      </SBannerContainer>
    </Banner>
  );
};
