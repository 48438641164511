import { isValidCmgEntityKey } from '../../../../../../common/util/isValidCmgEntityKey';
import {
  ComplianceProspectus_ProspectusDeliveryQueryVariables,
  useComplianceProspectus_ProspectusDeliveryQuery,
} from '../graphql';

export type QueryVariables = Omit<
  ComplianceProspectus_ProspectusDeliveryQueryVariables,
  'cmgEntityKey'
> & {
  cmgEntityKey: string | undefined;
  canRead: boolean;
};

export const useProspectusDeliveryComplianceQuery = ({
  offeringId,
  cmgEntityKey,
  canRead,
  isSyndicatePersona,
}: QueryVariables) => {
  const skip = !isValidCmgEntityKey(cmgEntityKey) || !canRead;

  return useComplianceProspectus_ProspectusDeliveryQuery({
    variables: {
      offeringId,
      cmgEntityKey: cmgEntityKey!,
      isSyndicatePersona,
    },
    fetchPolicy: 'cache-and-network',
    skip,
  });
};
