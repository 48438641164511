import React from 'react';

import { StyledBanner } from './CreateMemberInfoBanner.styles';

const CreateMemberInfoBanner: React.FC = () => {
  return (
    <StyledBanner variant="information">
      <div>You are adding custom Deal Team member not linked to CMG account.</div>
      <div>
        This Deal Team member will not be able to add investors to the meeting from XC platform.
      </div>
    </StyledBanner>
  );
};

export default CreateMemberInfoBanner;
