import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import {
  useOfferingSetup_TimingInfoQuery,
  useOfferingSetup_UpdateTimingInfoMutation,
} from './graphql';
import TimingForm from './TimingForm';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Primary Route for the Offering Setup module
 */
export const TimingRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const { data, loading, error, refetch } = useOfferingSetup_TimingInfoQuery({
    variables: { offeringId: offeringId },
  });

  const [updateTiming, { loading: updating, error: updateError }] =
    useOfferingSetup_UpdateTimingInfoMutation({});

  const { revalidate } = useValidateOffering(offeringId);

  const timing = data?.offering.timing;
  const errors = error || updateError;

  if (loading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        {errors && <ServerErrorsBanner error={errors} />}
        {data && (
          <TimingForm
            data={timing}
            loading={updating}
            onSubmit={async values => {
              try {
                await updateTiming({
                  variables: {
                    offeringId,
                    input: values,
                  },
                });

                ToastManager.success('Updated Timing Information');
                revalidate();
                refetch();
              } catch (e) {
                ToastManager.error('Failed to update Timing Information');
                throw new Error('Failed to update Timing Information');
              }
            }}
          />
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default TimingRoute;
