import { useAuth } from '@cmg/auth';
import React from 'react';

import { IndicationWithDemandLevels } from '../../types';
import { SLabel, StyledPopover } from './IOIAckByCell.styles';

type Props = {
  data: IndicationWithDemandLevels;
};

/**
 * Renders the IOI Ack By Cell within the Institutional Demand Grid.
 */
const IOIAckByCell: React.FC<Props> = ({ data }) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const acknowledgingUser = data.acknowledgingUser;
  const acknowledgingUserEmail = data.institutionalIndicationAcknowledgements
    .filter(ack => ack.managerCmgEntityKey === oidcUserCmgEntityKey)
    .map(ack => {
      return ack.auditInfo!.createdByUserEmail;
    });

  return (
    <StyledPopover content={acknowledgingUserEmail} variant="DARK" placement="topLeft">
      {acknowledgingUser ? <SLabel>{acknowledgingUser}</SLabel> : '-'}
    </StyledPopover>
  );
};

export default IOIAckByCell;
