import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { FormikEmailAddressesPicker } from '../../common/components/form/emails-picker/FormikEmailAddressesPicker';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';

export const IssuerAllocationWireForm: React.FC = () => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="report-emails" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <FormikEmailAddressesPicker
          name="emailAddresses"
          TextFieldProps={{ label: 'Email Addresses', required: true }}
        />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
