import { MultiSelect } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';

export const StyledForm = styled(Form)`
  flex-grow: 1;
  overflow: auto;
`;

export const StyledParticipantsMultiSelect = styled(MultiSelect)`
  flex-grow: 1;
  margin-right: 10px;
`;

export const SOption = styled.div`
  display: flex;
`;

export const STeamMemberName = styled.span`
  flex: 1;
`;

export const STeamName = styled.span`
  color: ${({ theme }) => theme.text.color.light};
`;

export const SSelectedMemberWrapper = styled.div`
  ${STeamName} {
    display: none;
  }
`;

export const StyledFlexLayout = styled(FlexLayout)`
  margin-bottom: 16px;
`;

export const SNewParticipantWrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 38px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background.color.light};
  color: ${({ theme }) => theme.text.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
`;
