import { CustomCellRendererProps } from '@cmg/common';
import { Box, Link } from '@cmg/design-system';
import React from 'react';

import { CertificateLibrary_FetchCertificates_FirmCertificatesFragment } from '../../../graphql/__generated__';
import useRouteWithSearchParams, {
  CertificateRouteName,
} from '../../../hooks/useRouteWithSearchParams';

type Props = CustomCellRendererProps<
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment,
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment['firmName']
>;

const FirmNameRenderer: React.FC<Props> = props => {
  const route = useRouteWithSearchParams(CertificateRouteName.CurrentCertificates, {
    cmgEntityKey: props.data?.firmCmgEntityKey ? props.data.firmCmgEntityKey : undefined,
    crmInvestorId: props.data?.crmInvestorId ? props.data.crmInvestorId : undefined,
  });

  return (
    <Box
      width="100%"
      textOverflow="ellipsis"
      overflow="hidden"
      color={theme => theme.palette.primary.main}
    >
      <Link href={route}>{props.data?.firmName}</Link>
    </Box>
  );
};

export default FirmNameRenderer;
