import { UUID } from '@cmg/common';
import { Box, Divider, PageLayout, SnackbarManager } from '@cmg/design-system';
import React from 'react';
import { Route } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../graphql';
import TotalRetailDemandHistoryActionPanel, {
  RouteProps,
} from '../common/history-action-panel/TotalRetailDemandHistoryActionPanel';
import {
  AssignDemandVariables,
  AssignRetentionVariables,
  getLatestModifiedAt,
} from './AutoSaveTotalRetailDemand.model';
import {
  useOrderBook_AssignRetailDemandMutation,
  useOrderBook_AssignRetailRetentionMutation,
} from './graphql';
import AutoSaveTotalRetailDemandPageHeader from './page-header/AutoSaveTotalRetailDemandPageHeader';
import AutoSaveTotalRetailDemandTable from './table/AutoSaveTotalRetailDemandTable';

export type Props = Readonly<{
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[];
  retailTarget: number | null;
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
  offeringIssuerName: string;
}>;

const AutoSaveTotalRetailDemand: React.FC<Props> = ({
  rows,
  offeringId,
  instrumentId,
  trancheId,
  offeringIssuerName,
  retailTarget,
}) => {
  const modifiedAt = React.useMemo(() => getLatestModifiedAt(rows), [rows]);
  const allManagers = React.useMemo(() => rows.map(({ manager }) => manager), [rows]);

  const focusedInputRef = React.useRef<HTMLElement | null>();

  const [assignDemand, { loading: isAssigningDemand }] = useOrderBook_AssignRetailDemandMutation();
  const [assignRetention, { loading: isAssigningRetention }] =
    useOrderBook_AssignRetailRetentionMutation();

  const handleAssignDemand = React.useCallback(
    async ({ cmgEntityKey, demandShares, roundLots, underwriterName }: AssignDemandVariables) => {
      try {
        await assignDemand({
          variables: {
            offeringId,
            cmgEntityKey,
            payload: { instrumentId, trancheId, roundLots, shareQuantity: demandShares },
          },
        });
      } catch {
        SnackbarManager.error(
          `Failed to auto-save Retail Demand & Round Lots for ${underwriterName}`
        );
      }
    },
    [assignDemand, offeringId, instrumentId, trancheId]
  );
  const handleAssignRetention = React.useCallback(
    async ({ cmgEntityKey, retentionShares, underwriterName }: AssignRetentionVariables) => {
      try {
        await assignRetention({
          variables: { offeringId, cmgEntityKey, payload: { shareQuantity: retentionShares } },
        });
      } catch {
        SnackbarManager.error(`Failed to auto-save Retail Retention for ${underwriterName}`);
      }
    },
    [assignRetention, offeringId]
  );

  return (
    <PageLayout
      header={
        <React.Fragment>
          <AutoSaveTotalRetailDemandPageHeader
            offeringIssuerName={offeringIssuerName}
            offeringId={offeringId}
            hasRows={rows.length > 0}
            isSavingData={isAssigningDemand || isAssigningRetention}
            modifiedAt={modifiedAt}
          />
          <Divider />
        </React.Fragment>
      }
    >
      <Box py={2} data-testid="auto-save-layout">
        <AutoSaveTotalRetailDemandTable
          offeringId={offeringId}
          allManagers={allManagers}
          rows={rows}
          onAssignDemand={handleAssignDemand}
          onAssignRetention={handleAssignRetention}
          retailTarget={retailTarget}
          onFocus={event => {
            focusedInputRef.current = event.target;
          }}
          onBlur={() => {
            focusedInputRef.current = null;
          }}
          onSaveFinished={() => {
            focusedInputRef.current?.focus();
          }}
        />
      </Box>

      <Route
        path={routeFactory.orderBookTotalRetailDemandHistory.routePath}
        render={routeProps => (
          <TotalRetailDemandHistoryActionPanel
            {...(routeProps as RouteProps)}
            allManagers={allManagers}
          />
        )}
      />
    </PageLayout>
  );
};

export default AutoSaveTotalRetailDemand;
