import { Stack, Table, TableContainer } from '@cmg/design-system';
import { VFC } from 'react';

import { ErrorLoadingRecipientsAlert } from './ErrorLoadingRecipientsAlert';
import { LoadingErrorEmptyState } from './LoadingErrorEmptyState';

export type Props = Readonly<{
  readonly tableLabel: string;
  readonly tableHead: React.ReactNode;
  readonly onRefetch: () => void;
}>;

export const LoadingErrorSection: VFC<Props> = ({ tableLabel, tableHead, onRefetch }) => {
  return (
    <Stack gap={3}>
      <ErrorLoadingRecipientsAlert refetch={onRefetch} />
      <TableContainer>
        <Table aria-label={tableLabel}>{tableHead}</Table>
      </TableContainer>
      <LoadingErrorEmptyState />
    </Stack>
  );
};
