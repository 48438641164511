/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_ResubmitUsdtnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_ResubmitUsdtnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly resubmitUSDTNFiling: {
    readonly __typename?: 'USDTNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly transactions: ReadonlyArray<{
      readonly __typename?: 'Transaction';
      readonly executionDatetime: string;
      readonly executionTimezone: string;
      readonly shares: number;
      readonly executionPrice: number;
      readonly executionPriceCurrency: Types.TradeCurrencyType;
      readonly indicator: Types.BuySellType;
      readonly counterPartyFirmName?: string | null;
      readonly counterPartyFirmCrdNumber?: string | null;
    }>;
  };
};

export type RegulatoryFilings_SubmitUsdtnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateUsdtnFilingInput;
}>;

export type RegulatoryFilings_SubmitUsdtnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly submitUSDTNFiling: {
    readonly __typename?: 'USDTNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly transactions: ReadonlyArray<{
      readonly __typename?: 'Transaction';
      readonly executionDatetime: string;
      readonly executionTimezone: string;
      readonly shares: number;
      readonly executionPrice: number;
      readonly executionPriceCurrency: Types.TradeCurrencyType;
      readonly indicator: Types.BuySellType;
      readonly counterPartyFirmName?: string | null;
      readonly counterPartyFirmCrdNumber?: string | null;
    }>;
  };
};

export type RegulatoryFilings_UsdtnFilingDetailQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_UsdtnFilingDetailQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly USDTNFiling: {
        readonly __typename?: 'USDTNFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
        readonly firmName: string;
        readonly firmCrdNumber: string;
        readonly firmMpid: string;
        readonly contactName: string;
        readonly contactTitle: string;
        readonly telephoneNumber: string;
        readonly emailAddress: string;
        readonly finraErrorMessages: ReadonlyArray<string>;
        readonly issuerName: string;
        readonly issuerSymbol: string;
        readonly firmDealId: string;
        readonly formUniqueId: string;
        readonly transactions: ReadonlyArray<{
          readonly __typename?: 'Transaction';
          readonly executionDatetime: string;
          readonly executionTimezone: string;
          readonly shares: number;
          readonly executionPrice: number;
          readonly executionPriceCurrency: Types.TradeCurrencyType;
          readonly indicator: Types.BuySellType;
          readonly counterPartyFirmName?: string | null;
          readonly counterPartyFirmCrdNumber?: string | null;
        }>;
      };
      readonly notifications: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingNotificationMessage';
        readonly id: string;
        readonly status: Types.NotificationMessageStatus;
        readonly sentAt?: string | null;
        readonly distributionList: ReadonlyArray<string>;
        readonly bccEmailAddresses: ReadonlyArray<string>;
        readonly managerSnapshot: {
          readonly __typename?: 'FinraManagerSnapshot';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
        };
      }>;
    };
  };
};

export type RegulatoryFilings_UsdtnFilingPartsFragment = {
  readonly __typename?: 'USDTNFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
  readonly firmName: string;
  readonly firmCrdNumber: string;
  readonly firmMpid: string;
  readonly contactName: string;
  readonly contactTitle: string;
  readonly telephoneNumber: string;
  readonly emailAddress: string;
  readonly finraErrorMessages: ReadonlyArray<string>;
  readonly issuerName: string;
  readonly issuerSymbol: string;
  readonly firmDealId: string;
  readonly formUniqueId: string;
  readonly transactions: ReadonlyArray<{
    readonly __typename?: 'Transaction';
    readonly executionDatetime: string;
    readonly executionTimezone: string;
    readonly shares: number;
    readonly executionPrice: number;
    readonly executionPriceCurrency: Types.TradeCurrencyType;
    readonly indicator: Types.BuySellType;
    readonly counterPartyFirmName?: string | null;
    readonly counterPartyFirmCrdNumber?: string | null;
  }>;
};

export type RegulatoryFilings_UsdtnFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_UsdtnFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly USDTNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export const RegulatoryFilings_UsdtnFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'USDTNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'executionDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPriceCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmCrdNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_ResubmitUsdtnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_ResubmitUsdtnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitUSDTNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'USDTNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'executionDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPriceCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmCrdNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_ResubmitUsdtnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_ResubmitUsdtnFilingMutation,
  RegulatoryFilings_ResubmitUsdtnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_ResubmitUsdtnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_ResubmitUsdtnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_ResubmitUsdtnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsResubmitUsdtnFilingMutation, { data, loading, error }] = useRegulatoryFilings_ResubmitUsdtnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_ResubmitUsdtnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_ResubmitUsdtnFilingMutation,
    RegulatoryFilings_ResubmitUsdtnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_ResubmitUsdtnFilingMutation,
    RegulatoryFilings_ResubmitUsdtnFilingMutationVariables
  >(RegulatoryFilings_ResubmitUsdtnFilingDocument, options);
}
export type RegulatoryFilings_ResubmitUsdtnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_ResubmitUsdtnFilingMutation
>;
export type RegulatoryFilings_ResubmitUsdtnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_ResubmitUsdtnFilingMutation>;
export type RegulatoryFilings_ResubmitUsdtnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_ResubmitUsdtnFilingMutation,
  RegulatoryFilings_ResubmitUsdtnFilingMutationVariables
>;
export const RegulatoryFilings_SubmitUsdtnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SubmitUsdtnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUSDTNFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitUSDTNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'USDTNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'executionDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPriceCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmCrdNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SubmitUsdtnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SubmitUsdtnFilingMutation,
  RegulatoryFilings_SubmitUsdtnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_SubmitUsdtnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SubmitUsdtnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SubmitUsdtnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSubmitUsdtnFilingMutation, { data, loading, error }] = useRegulatoryFilings_SubmitUsdtnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegulatoryFilings_SubmitUsdtnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SubmitUsdtnFilingMutation,
    RegulatoryFilings_SubmitUsdtnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SubmitUsdtnFilingMutation,
    RegulatoryFilings_SubmitUsdtnFilingMutationVariables
  >(RegulatoryFilings_SubmitUsdtnFilingDocument, options);
}
export type RegulatoryFilings_SubmitUsdtnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SubmitUsdtnFilingMutation
>;
export type RegulatoryFilings_SubmitUsdtnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SubmitUsdtnFilingMutation>;
export type RegulatoryFilings_SubmitUsdtnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_SubmitUsdtnFilingMutation,
  RegulatoryFilings_SubmitUsdtnFilingMutationVariables
>;
export const RegulatoryFilings_UsdtnFilingDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'USDTNFiling' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_FilingNotificationMessageParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'USDTNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'executionDatetime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'executionPriceCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counterPartyFirmCrdNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_UsdtnFilingDetailQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_UsdtnFilingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_UsdtnFilingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_UsdtnFilingDetailQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_UsdtnFilingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_UsdtnFilingDetailQuery,
    RegulatoryFilings_UsdtnFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_UsdtnFilingDetailQuery,
    RegulatoryFilings_UsdtnFilingDetailQueryVariables
  >(RegulatoryFilings_UsdtnFilingDetailDocument, options);
}
export function useRegulatoryFilings_UsdtnFilingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_UsdtnFilingDetailQuery,
    RegulatoryFilings_UsdtnFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_UsdtnFilingDetailQuery,
    RegulatoryFilings_UsdtnFilingDetailQueryVariables
  >(RegulatoryFilings_UsdtnFilingDetailDocument, options);
}
export type RegulatoryFilings_UsdtnFilingDetailQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_UsdtnFilingDetailQuery
>;
export type RegulatoryFilings_UsdtnFilingDetailLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_UsdtnFilingDetailLazyQuery
>;
export type RegulatoryFilings_UsdtnFilingDetailQueryResult = Apollo.QueryResult<
  RegulatoryFilings_UsdtnFilingDetailQuery,
  RegulatoryFilings_UsdtnFilingDetailQueryVariables
>;
export const RegulatoryFilings_UsdtnFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_UsdtnFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'USDTNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_UsdtnFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_UsdtnFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_UsdtnFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_UsdtnFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_UsdtnFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_UsdtnFilingsQuery,
    RegulatoryFilings_UsdtnFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_UsdtnFilingsQuery,
    RegulatoryFilings_UsdtnFilingsQueryVariables
  >(RegulatoryFilings_UsdtnFilingsDocument, options);
}
export function useRegulatoryFilings_UsdtnFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_UsdtnFilingsQuery,
    RegulatoryFilings_UsdtnFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_UsdtnFilingsQuery,
    RegulatoryFilings_UsdtnFilingsQueryVariables
  >(RegulatoryFilings_UsdtnFilingsDocument, options);
}
export type RegulatoryFilings_UsdtnFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_UsdtnFilingsQuery
>;
export type RegulatoryFilings_UsdtnFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_UsdtnFilingsLazyQuery
>;
export type RegulatoryFilings_UsdtnFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_UsdtnFilingsQuery,
  RegulatoryFilings_UsdtnFilingsQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_UsdtnFilingDetailMockResponse = makeMockResponse<
  RegulatoryFilings_UsdtnFilingDetailQueryVariables,
  RegulatoryFilings_UsdtnFilingDetailQuery
>(RegulatoryFilings_UsdtnFilingDetailDocument);

export const makeRegulatoryFilings_UsdtnFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_UsdtnFilingsQueryVariables,
  RegulatoryFilings_UsdtnFilingsQuery
>(RegulatoryFilings_UsdtnFilingsDocument);
