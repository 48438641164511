import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_NonSyndicateTerminationWiresDocument,
  SyndicateWires_SendNonSyndicateTerminationWireMutation,
  useSyndicateWires_SendNonSyndicateTerminationWireMutation,
} from '../graphql';

export type SendNonSyndicateTerminationWireMutation =
  SyndicateWires_SendNonSyndicateTerminationWireMutation;

export const useSendNonSyndicateTerminationWireMutation = () => {
  return useSyndicateWires_SendNonSyndicateTerminationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateTerminationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
