import { Icon, UUID } from '@cmg/common';
import React from 'react';

import NavItem from '../../../common/components/navigation/side-navigation/NavItem';
import { HorizontalLine } from '../../../common/components/presentation';
import routeFactory from '../../../common/util/routeFactory';
import { SHeading, SItem, SWrapper } from './TradeReleaseHistorySidePanelNavbar.styles';

export type Props = {
  offeringId: UUID;
  indicationId: UUID;
};

export const TradeReleaseHistorySidePanelNavbar: React.FC<Props> = ({
  offeringId,
  indicationId,
}) => {
  return (
    <SWrapper>
      <SHeading>Trade Release</SHeading>
      <HorizontalLine />
      <SItem>
        <NavItem url={routeFactory.tradeReleaseHistory.getUrlPath({ offeringId, indicationId })}>
          <Icon name="history" />
          History
        </NavItem>
      </SItem>
    </SWrapper>
  );
};
