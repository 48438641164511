import { ODSTable as Table } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

export const StyledIconCell = styled(Table.Cell)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const StyledForm = styled(Form)`
  display: contents;
`;
