import { UUID } from '@cmg/common';
import React from 'react';

import { SyndicateWireManagerRole } from '../../../../../../../types/domain/syndicate-wires/constants';
import { managersToRoleGroupedEntries } from '../../../../common/components/navigation/managers-side-nav/ManagersNavigation.model';
import {
  SyndicateWires_SyndicateWiresManagerPartsFragment,
  useSyndicateWires_ManagerRetailRetentionFeesQuery,
} from '../../../../common/graphql';
import { ManagerRetentionTable } from './ManagerRetentionTable';

export type ManagerRetentionProps = {
  offeringId: UUID;
  onFormDataLoaded?: (isSuccess: boolean) => void;
  managers: (Pick<
    SyndicateWires_SyndicateWiresManagerPartsFragment,
    'cmgEntityKey' | 'firmName'
  > & {
    role: SyndicateWireManagerRole;
  })[];
};

export const ManagerRetention: React.FC<ManagerRetentionProps> = ({
  offeringId,
  onFormDataLoaded,
  managers,
}) => {
  const { data, error, loading, refetch } = useSyndicateWires_ManagerRetailRetentionFeesQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const managersByRoles = React.useMemo(() => managersToRoleGroupedEntries(managers), [managers]);

  return (
    <ManagerRetentionTable
      managersByRoles={managersByRoles}
      refetch={refetch}
      error={error}
      loading={loading}
      onFormDataLoaded={onFormDataLoaded}
      data={data}
    />
  );
};
