import { numericUtil } from '@cmg/common';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';
import { calculateOverallotmentShares } from '../base-deal-shares-info-box/BaseDealSharesInfoBox.model';

const calculatePotAvailable = (accounting: FinalSettlement_AccountingPartsFragment) => {
  const { retailRetention, baseDealShares } = accounting;

  const totalDealShares = numericUtil.sum(baseDealShares, calculateOverallotmentShares(accounting));
  const potAvailable = numericUtil.sum(totalDealShares, retailRetention ? -retailRetention : null);

  return { totalDealShares, potAvailable };
};

export const calculateRemainingSharesValues = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const { potAllocated } = accounting;

  const { totalDealShares, potAvailable } = calculatePotAvailable(accounting);
  const remainingShares = numericUtil.sum(potAvailable, -potAllocated);

  return { totalDealShares, potAvailable, remainingShares };
};
