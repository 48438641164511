/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_NoiFilingDetailQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_NoiFilingDetailQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly NOIFiling: {
        readonly __typename?: 'NOIFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
        readonly firmName: string;
        readonly firmCrdNumber: string;
        readonly firmMpid: string;
        readonly contactName: string;
        readonly contactTitle: string;
        readonly telephoneNumber: string;
        readonly emailAddress: string;
        readonly additionalInformation?: string | null;
        readonly finraErrorMessages: ReadonlyArray<string>;
        readonly issuerName: string;
        readonly issuerSymbol: string;
        readonly firmDealId: string;
        readonly formUniqueId: string;
        readonly activities: ReadonlyArray<{
          readonly __typename?: 'NOIActivity';
          readonly activityType: Types.ActivityType;
          readonly date: string;
        }>;
      };
      readonly notifications: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingNotificationMessage';
        readonly id: string;
        readonly status: Types.NotificationMessageStatus;
        readonly sentAt?: string | null;
        readonly distributionList: ReadonlyArray<string>;
        readonly bccEmailAddresses: ReadonlyArray<string>;
        readonly managerSnapshot: {
          readonly __typename?: 'FinraManagerSnapshot';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
        };
      }>;
    };
  };
};

export type RegulatoryFilings_NoiFilingPartsFragment = {
  readonly __typename?: 'NOIFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
  readonly firmName: string;
  readonly firmCrdNumber: string;
  readonly firmMpid: string;
  readonly contactName: string;
  readonly contactTitle: string;
  readonly telephoneNumber: string;
  readonly emailAddress: string;
  readonly additionalInformation?: string | null;
  readonly finraErrorMessages: ReadonlyArray<string>;
  readonly issuerName: string;
  readonly issuerSymbol: string;
  readonly firmDealId: string;
  readonly formUniqueId: string;
  readonly activities: ReadonlyArray<{
    readonly __typename?: 'NOIActivity';
    readonly activityType: Types.ActivityType;
    readonly date: string;
  }>;
};

export type RegulatoryFilings_NoiFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_NoiFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly NOIFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export type RegulatoryFilings_ResubmitNoiFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_ResubmitNoiFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly resubmitNOIFiling: {
    readonly __typename?: 'NOIFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly additionalInformation?: string | null;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly activities: ReadonlyArray<{
      readonly __typename?: 'NOIActivity';
      readonly activityType: Types.ActivityType;
      readonly date: string;
    }>;
  };
};

export type RegulatoryFilings_SubmitNoiFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateNoiFilingInput;
}>;

export type RegulatoryFilings_SubmitNoiFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly submitNOIFiling: {
    readonly __typename?: 'NOIFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly additionalInformation?: string | null;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly activities: ReadonlyArray<{
      readonly __typename?: 'NOIActivity';
      readonly activityType: Types.ActivityType;
      readonly date: string;
    }>;
  };
};

export const RegulatoryFilings_NoiFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NOIFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_NoiFilingDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NOIFiling' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_FilingNotificationMessageParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NOIFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_NoiFilingDetailQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_NoiFilingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_NoiFilingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_NoiFilingDetailQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_NoiFilingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_NoiFilingDetailQuery,
    RegulatoryFilings_NoiFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_NoiFilingDetailQuery,
    RegulatoryFilings_NoiFilingDetailQueryVariables
  >(RegulatoryFilings_NoiFilingDetailDocument, options);
}
export function useRegulatoryFilings_NoiFilingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_NoiFilingDetailQuery,
    RegulatoryFilings_NoiFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_NoiFilingDetailQuery,
    RegulatoryFilings_NoiFilingDetailQueryVariables
  >(RegulatoryFilings_NoiFilingDetailDocument, options);
}
export type RegulatoryFilings_NoiFilingDetailQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_NoiFilingDetailQuery
>;
export type RegulatoryFilings_NoiFilingDetailLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_NoiFilingDetailLazyQuery
>;
export type RegulatoryFilings_NoiFilingDetailQueryResult = Apollo.QueryResult<
  RegulatoryFilings_NoiFilingDetailQuery,
  RegulatoryFilings_NoiFilingDetailQueryVariables
>;
export const RegulatoryFilings_NoiFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NOIFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_NoiFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_NoiFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_NoiFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_NoiFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_NoiFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_NoiFilingsQuery,
    RegulatoryFilings_NoiFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_NoiFilingsQuery,
    RegulatoryFilings_NoiFilingsQueryVariables
  >(RegulatoryFilings_NoiFilingsDocument, options);
}
export function useRegulatoryFilings_NoiFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_NoiFilingsQuery,
    RegulatoryFilings_NoiFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_NoiFilingsQuery,
    RegulatoryFilings_NoiFilingsQueryVariables
  >(RegulatoryFilings_NoiFilingsDocument, options);
}
export type RegulatoryFilings_NoiFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_NoiFilingsQuery
>;
export type RegulatoryFilings_NoiFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_NoiFilingsLazyQuery
>;
export type RegulatoryFilings_NoiFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_NoiFilingsQuery,
  RegulatoryFilings_NoiFilingsQueryVariables
>;
export const RegulatoryFilings_ResubmitNoiFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_ResubmitNoiFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitNOIFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NOIFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_ResubmitNoiFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_ResubmitNoiFilingMutation,
  RegulatoryFilings_ResubmitNoiFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_ResubmitNoiFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_ResubmitNoiFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_ResubmitNoiFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsResubmitNoiFilingMutation, { data, loading, error }] = useRegulatoryFilings_ResubmitNoiFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_ResubmitNoiFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_ResubmitNoiFilingMutation,
    RegulatoryFilings_ResubmitNoiFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_ResubmitNoiFilingMutation,
    RegulatoryFilings_ResubmitNoiFilingMutationVariables
  >(RegulatoryFilings_ResubmitNoiFilingDocument, options);
}
export type RegulatoryFilings_ResubmitNoiFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_ResubmitNoiFilingMutation
>;
export type RegulatoryFilings_ResubmitNoiFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_ResubmitNoiFilingMutation>;
export type RegulatoryFilings_ResubmitNoiFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_ResubmitNoiFilingMutation,
  RegulatoryFilings_ResubmitNoiFilingMutationVariables
>;
export const RegulatoryFilings_SubmitNoiFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SubmitNoiFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNOIFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitNOIFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_NoiFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NOIFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInformation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SubmitNoiFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SubmitNoiFilingMutation,
  RegulatoryFilings_SubmitNoiFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_SubmitNoiFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SubmitNoiFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SubmitNoiFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSubmitNoiFilingMutation, { data, loading, error }] = useRegulatoryFilings_SubmitNoiFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegulatoryFilings_SubmitNoiFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SubmitNoiFilingMutation,
    RegulatoryFilings_SubmitNoiFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SubmitNoiFilingMutation,
    RegulatoryFilings_SubmitNoiFilingMutationVariables
  >(RegulatoryFilings_SubmitNoiFilingDocument, options);
}
export type RegulatoryFilings_SubmitNoiFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SubmitNoiFilingMutation
>;
export type RegulatoryFilings_SubmitNoiFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SubmitNoiFilingMutation>;
export type RegulatoryFilings_SubmitNoiFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_SubmitNoiFilingMutation,
  RegulatoryFilings_SubmitNoiFilingMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_NoiFilingDetailMockResponse = makeMockResponse<
  RegulatoryFilings_NoiFilingDetailQueryVariables,
  RegulatoryFilings_NoiFilingDetailQuery
>(RegulatoryFilings_NoiFilingDetailDocument);

export const makeRegulatoryFilings_NoiFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_NoiFilingsQueryVariables,
  RegulatoryFilings_NoiFilingsQuery
>(RegulatoryFilings_NoiFilingsDocument);
