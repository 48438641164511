import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import {
  ComplianceTableType,
  getIndicationsWithComplianceCertificatesWarning,
  getIndicationsWithComplianceProspectusWarning,
  investorNameSortFn,
} from './ComplianceTable.model';
import { SContentWrapper } from './ComplianceTable.styles';
import ComplianceTableRow from './ComplianceTableRow';
import { useInstitutionalDemand_ComplianceTableQuery } from './graphql';

export type Props = {
  offeringId: string;
  tableType: ComplianceTableType;
};

const ComplianceTable: React.FC<Props> = ({ offeringId, tableType }) => {
  const { loading, data } = useInstitutionalDemand_ComplianceTableQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const { syndicate, orderBook, type } = data?.publishedOffering ?? {};
  const complianceIndications = React.useMemo(() => {
    const data =
      tableType === ComplianceTableType.CERTIFICATES
        ? getIndicationsWithComplianceCertificatesWarning({
            indications: orderBook?.institutionalIndications,
            offeringType: type,
          })
        : getIndicationsWithComplianceProspectusWarning({
            indications: orderBook?.institutionalIndications,
          });
    return data.sort(investorNameSortFn);
  }, [tableType, orderBook?.institutionalIndications, type]);

  const managers = syndicate?.managers ?? [];
  const investors = complianceIndications.map(({ investorInformation }) => investorInformation);

  if (loading) {
    return <Loading />;
  }

  if (complianceIndications.length === 0) {
    return null;
  }

  return (
    <SContentWrapper>
      <Table gridTemplateColumns="1.2fr 1fr 1fr 1fr 1fr">
        <Table.TableHeaderCell>Investor</Table.TableHeaderCell>
        <Table.TableHeaderCell>
          {tableType === ComplianceTableType.CERTIFICATES ? '5130/31 Status' : 'Prospectus Status'}
        </Table.TableHeaderCell>
        <Table.TableHeaderCell>B&D Agent</Table.TableHeaderCell>
        <Table.TableHeaderCell>Submitted by</Table.TableHeaderCell>
        <Table.TableHeaderCell>Final Allocation</Table.TableHeaderCell>
        {complianceIndications.map(i => (
          <ComplianceTableRow
            key={i.id}
            indication={i}
            managers={managers}
            investors={investors}
            tableType={tableType}
          />
        ))}
      </Table>
    </SContentWrapper>
  );
};

export default ComplianceTable;
