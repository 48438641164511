import { numericUtil } from '@cmg/common';
import React from 'react';

import { TradeRelease_TradeGridItemFragment } from '../../graphql/__generated__';
import { tradeColsMap } from '../TradeReleaseGrid.model';

const { sum } = numericUtil;

export const useTradeReleaseGridTotals = (filteredRows: TradeRelease_TradeGridItemFragment[]) => {
  return React.useMemo(() => {
    const totalNumOfInvestors = filteredRows.length;
    const totalAllocationShares = sum(...filteredRows.map(row => row.allocationShares));
    const totalReleasedShares = sum(...filteredRows.map(row => row.sharesReleased));

    return [
      {
        [tradeColsMap.investorFirmName]: totalNumOfInvestors,
        [tradeColsMap.allocationShares]: totalAllocationShares,
        [tradeColsMap.sharesReleased]: totalReleasedShares,
      },
    ];
  }, [filteredRows]);
};
