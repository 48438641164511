import React from 'react';

import Banner from '../../common/components/indicators/banner/Banner';

// TODO: This file should be split into three separate components, also using S prefix is very misleading here

// buy side banners

export const SNoRelationshipBanner = () => (
  <Banner variant="warning">
    <Banner.Title>No relationships with the sell side on the offering</Banner.Title>
    <p>
      We are unable to verify you have a relationship with one or more active bookrunners on the
      offering. Please contact your broker relationship(s) to communicate interest.
    </p>
  </Banner>
);

// Different Banner without title for the legal changes
export const NoRelationshipLegalBanner = () => (
  <Banner variant="warning">
    You do not have the ability to view the Offering Details because we are unable to verify that
    you have a relationship with one or more active bookrunners on this offering.
  </Banner>
);

export const NoRelationshipOrderbookLegalBanner = () => (
  <Banner variant="warning">
    You do not have the ability to place indications on this offering because we are unable to
    verify that you have a relationship with one or more active bookrunners on this offering.
  </Banner>
);

export const SNoRelationshipWithActiveBookrunnerBanner = () => (
  <Banner variant="warning">
    <Banner.Title>No relationship with Active Bookrunner</Banner.Title>
    <p>
      Your firm does not have an existing relationship with an active bookrunner on this offering.
      Please contact your sell side representative for further information
    </p>
  </Banner>
);

// sell side banners

export const SNoRelationshipWBannerSellSide = () => (
  <Banner variant="warning">
    <Banner.Title>Your firm is not participating as an Underwriter on this offering</Banner.Title>
    <p>Offering Details are only available to participating underwriter firms on an offering.</p>
  </Banner>
);

export const NoSyndicateInvestorFirmRelationshipBanner = () => (
  <Banner variant="information">
    <Banner.Title>You cannot edit this indication</Banner.Title>
    <p>
      We are unable to verify that you have a relationship with this investor. If you believe this
      is an error please contact <a href="mailto: support@cmgx.io">support@cmgx.io</a>
    </p>
  </Banner>
);
