import { ApolloError } from '@apollo/client';
import { Button } from '@cmg/design-system';
import React from 'react';

import { useSupportRequestModal } from '../../hooks/useSupportRequestModal';
import { DismissibleErrorsBanner } from './DismissibleErrorsBanner';
import { useDismissedErrors } from './hooks/useDismissedErrors';

type Props = Readonly<{
  errors: { [key: string]: ApolloError[] };
  prefixMessageMap?: { [key: string]: string };
  dialogProps: {
    title?: string;
    presetCategory?: string;
    presetSubject?: string;
    setErrorsOnDescription?: boolean;
  };
  bannerProps: {
    title?: string;
    body?: string;
  };
}>;

export const SupportModalDismissibleErrorsBanner: React.FC<Props> = ({
  errors,
  bannerProps,
  dialogProps,
  prefixMessageMap,
}) => {
  const { open } = useSupportRequestModal();
  const { errors: visibleErrors } = useDismissedErrors({ errors, prefixMessageMap });

  const openSupportModal = () => {
    open({
      ...dialogProps,
      presetDescription: dialogProps.setErrorsOnDescription
        ? visibleErrors.map(error => error.message).join('\n')
        : '',
    });
  };
  return (
    <DismissibleErrorsBanner
      {...bannerProps}
      prefixMessageMap={prefixMessageMap}
      errors={errors}
      action={
        <Button color="primary" onClick={openSupportModal}>
          Contact Support
        </Button>
      }
    />
  );
};
