import React from 'react';
import styled from 'styled-components/macro';

import ColumnLayout from '../column-layout/ColumnLayout';

const Layout = styled(ColumnLayout)`
  flex-wrap: wrap;
`;

const GridLayout: React.FC = ({ children }) => {
  return <Layout>{children}</Layout>;
};

export default GridLayout;
