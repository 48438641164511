import { textUtil } from '@cmg/common';
import { CriticalActionDialog, CriticalActionDialogProps, Typography } from '@cmg/design-system';
import React from 'react';

import { OfferingStatus } from '../../../../graphql';

type Props = CriticalActionDialogProps & {
  name: string;
  status: OfferingStatus;
};

const getTitleText = (name: string, status: OfferingStatus) => {
  if (status === OfferingStatus.Withdrawn) {
    return `${name} - Withdraw Offering`;
  }

  if (status === OfferingStatus.Terminated) {
    return `${name} - Terminate Offering`;
  }

  if (status === OfferingStatus.Postponed) {
    return `${name} - Postpone Offering`;
  }

  return `${name} — Publish to ${textUtil.capitalizeFirstLetter(
    status.toLocaleLowerCase() || ''
  )} Calendar`;
};

const getAcknowledgementText = (name: string, status: OfferingStatus) => {
  if (status === OfferingStatus.Withdrawn) {
    return (
      <Typography component="span" role="status">
        I understand that the offering for{' '}
        <Typography component="span" fontWeight="bold">
          {name}
        </Typography>{' '}
        will be withdrawn and removed from all CMG calendars.
      </Typography>
    );
  }

  if (status === OfferingStatus.Terminated) {
    return (
      <Typography component="span" role="status">
        I understand that the offering for{' '}
        <Typography component="span" fontWeight="bold">
          {name}
        </Typography>{' '}
        will be terminated and removed from all CMG calendars.
      </Typography>
    );
  }

  return (
    <Typography component="span" role="status">
      I understand that the offering for{' '}
      <Typography component="span" fontWeight="bold">
        {name}
      </Typography>{' '}
      will be published to the CMG{' '}
      {textUtil.capitalizeFirstLetter(status.toLocaleLowerCase() || '')} Calendar and visible to all
      users.
    </Typography>
  );
};

const getActionText = (name: string, status: OfferingStatus) => {
  if (status === OfferingStatus.Withdrawn) {
    return 'Withdraw Offering';
  }

  if (status === OfferingStatus.Terminated) {
    return 'Terminate Offering';
  }

  if (status === OfferingStatus.Postponed) {
    return 'Postpone Offering';
  }

  return `Publish To ${textUtil.capitalizeFirstLetter(status.toLocaleLowerCase() || '')} Calendar`;
};

export const PublishOfferingConfirmationDialog: React.FC<Props> = ({ name, status, ...props }) => (
  <CriticalActionDialog
    {...props}
    title={getTitleText(name, status)}
    actionButtonText={getActionText(name, status)}
    checkboxLabelText={getAcknowledgementText(name, status)}
  />
);
