import { FilingType, OfferingStatus } from '../../../graphql';
import {
  Calendar_PublishedOfferingsBuySideQuery,
  Calendar_PublishedOfferingsQuery,
} from '../components/calendar-grid/graphql/__generated__';
import { Calendar_FiledCalendarOfferingsListQuery } from '../filed-calendar/graphql';
import { Calendar_LiveCalendarOfferingsListQuery } from '../live-calendar/graphql';
import { Calendar_PostponedCalendarOfferingsListQuery } from '../postponed-calendar/graphql';
import { Calendar_PricedCalendarOfferingsListQuery } from '../priced-calendar/graphql';

type BuySideRowItem = NonNullable<
  NonNullable<Calendar_PublishedOfferingsBuySideQuery['publishedOfferings']>['items']
>[number];
type SellSideRowItem = NonNullable<
  NonNullable<Calendar_PublishedOfferingsQuery['publishedOfferings']>['items']
>[number];

export type IncomeFormat = SellSideRowItem | BuySideRowItem;

type LiveCalendarOfferingsListItem =
  Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number];
type PricedCalendarOfferingsListItem =
  Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number];
type FiledCalendarOfferingsListItem =
  Calendar_FiledCalendarOfferingsListQuery['filedPublishedOfferings']['data'][number];
type PostponedCalendarOfferingsListItem =
  Calendar_PostponedCalendarOfferingsListQuery['postponedPublishedOfferings']['data'][number];

type OutComeFormats =
  | LiveCalendarOfferingsListItem
  | PricedCalendarOfferingsListItem
  | FiledCalendarOfferingsListItem
  | PostponedCalendarOfferingsListItem;

// temporary solution to avoid high complexity of rendering components based on feature flag
// * same data types passed through props
// * same value getters
// at the time of the feature flag removal data types & value getters will be adjusted and this function will be removed
// FF REACT_APP__ENABLE_FEATURE__CALENDAR__USING_OFFERING_FULL
function mapCalendarGridDataToOldFormatCommonParts({
  id,
  type,
  status,
  sizeInCurrency,
  sizeInShares,
  pricingCurrencyCode,
  pricingInstrumentStockSymbol,
  priceRangeLow,
  priceRangeHigh,
  isExecutingOnPlatform,
  syndicate,
  timing,
  security,
  issuer,
  terms,
  instruments: [firstInstrument],
}: IncomeFormat): OutComeFormats {
  const { region, countryDisplayName, countryCode } = firstInstrument ?? {};
  return {
    id,
    type,
    status,
    sizeInCurrency,
    sizeInShares,
    pricingCurrencyCode,
    pricingInstrumentStockSymbol,
    pricingInstrumentRegion: region,
    pricingInstrumentCountryDisplayName: countryDisplayName,
    pricingInstrumentCountryCode: countryCode,
    managers: syndicate.managers.map(({ cmgEntityKey, firmName, role, firmNameAbbreviation }) => ({
      cmgEntityKey,
      firmName,
      role,
      firmNameAbbreviation,
    })),
    pricingDate: timing.pricingDate,
    postponedDate: timing.postponedDate,
    launchDate: timing.launchDate,
    priceRangeLow,
    priceRangeHigh,
    isExecutingOnPlatform,
    securityType: security.type,
    issuerName: issuer.name,
    issuerSector: issuer.sector,
    leftLeadFirmName: syndicate.managers.find(
      ({ cmgEntityKey }) => cmgEntityKey === syndicate.managerResponsibilities.leftLead
    )?.firmName,
    revisedFilingsCount: terms.filings.filter(filing => filing.filingType === FilingType.Revised)
      .length,
  };
}

export const mapCalendarGridDataToOldFormatByStatus = {
  [OfferingStatus.Live]: (data: IncomeFormat): LiveCalendarOfferingsListItem => ({
    ...mapCalendarGridDataToOldFormatCommonParts(data),
    ...mapBuySideDatalabAndOrderbookDataToOldFormat(data),
  }),
  [OfferingStatus.Priced]: (data: IncomeFormat): PricedCalendarOfferingsListItem => ({
    ...mapCalendarGridDataToOldFormatCommonParts(data),
    ...mapBuySideDatalabAndOrderbookDataToOldFormat(data),
    firstTradeDate: data.timing.firstTradeDate,
    finalFilingOfferPrice: data.terms.filings.find(filing => filing.filingType === FilingType.Final)
      ?.offerPrice,
  }),
  [OfferingStatus.Filed]: (data: IncomeFormat): FiledCalendarOfferingsListItem => ({
    ...mapCalendarGridDataToOldFormatCommonParts(data),
    publicFilingDate: data.timing.publicFilingDate,
  }),
  [OfferingStatus.Postponed]: (data: IncomeFormat): PostponedCalendarOfferingsListItem =>
    mapCalendarGridDataToOldFormatCommonParts(data),
};

function isBuySideData(data: IncomeFormat): data is BuySideRowItem {
  return 'orderBookFull' in data || 'dataLabFull' in data;
}

function mapBuySideDatalabAndOrderbookDataToOldFormat(data: IncomeFormat): Partial<OutComeFormats> {
  if (!isBuySideData(data)) {
    return {};
  }
  return {
    mdlIndications: data.dataLabFull?.mdlIndications,
    mdlAllocation: data.dataLabFull?.mdlAllocation,
    myInstitutionalIndication: data.orderBookFull?.myInstitutionalIndication,
    finalAllocation: data.orderBookFull?.finalAllocation,
    booksCloseAt: data.timing.booksCloseAt,
    timeZone: data.timing.timeZone,
  };
}
