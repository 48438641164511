import { FlexContainer, UnsavedChangesGuard } from '@cmg/common';
import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';

import SetupForm from '../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../components/form/OfferingSetupFormActions';
import { OfferingSetup_DisclaimersPartsFragment } from '../graphql';
import { SActions, StyledTextAreaField } from './DisclaimersForm.styles';

type FormType = {
  restrictions?: string | null;
  footnotes?: string | null;
};

export type Props = {
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (payload: FormType) => void;
  disclaimers?: OfferingSetup_DisclaimersPartsFragment;
};

// used in FieldArray to denote which field is actively editing
type EditingField = { field: string; originalValue?: string | null };

export const DisclaimersFormComponent: React.FC<Props> = ({
  disabled,
  loading,
  onSubmit,
  disclaimers,
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    initialValues: {
      restrictions: disclaimers?.restrictions ?? '',
      footnotes: disclaimers?.footnotes ?? '',
    },
    onSubmit: values => {
      onSubmit({
        restrictions: values?.restrictions || null,
        footnotes: values?.footnotes || null,
      });
    },
  };

  const formik = useFormik(formikOptions);

  const { values, handleSubmit, resetForm, dirty } = formik;

  const [editing, setEditing] = React.useState<EditingField | null>(null);

  const actionProps = (field: string) => ({
    editing: editing?.field === field,
    disabled: disabled || (editing && editing?.field !== field) ? true : false,
    onEdit: () => setEditing({ field, originalValue: values?.restrictions }),
    onSubmit: () => {
      handleSubmit();
      setEditing(null);
    },
    onCancel: () => {
      resetForm();
      setEditing(null);
    },
  });

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
        <Form>
          <SetupForm title="Free Form Disclosures">
            <FlexContainer direction="column">
              <StyledTextAreaField
                fullWidth
                label="Geographic/Investor Restrictions"
                placeholder="Add Geographic/Investor Restrictions here"
                name="restrictions"
                disabled={loading || editing?.field !== 'restrictions'}
              />
              <SActions>
                <OfferingSetupFormActions {...actionProps('restrictions')} />
              </SActions>
            </FlexContainer>
            <FlexContainer direction="column">
              <StyledTextAreaField
                fullWidth
                label="Footnote Disclaimers"
                placeholder="Add Footnote Disclaimers here"
                name="footnotes"
                disabled={loading || editing?.field !== 'footnotes'}
              />
              <SActions>
                <OfferingSetupFormActions {...actionProps('footnotes')} />
              </SActions>
            </FlexContainer>
          </SetupForm>
        </Form>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default DisclaimersFormComponent;
