import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_GreenShoeWiresDocument,
  SyndicateWires_SendGreenShoeWireMutation,
  useSyndicateWires_SendGreenShoeWireMutation,
} from '../graphql';

export type SendGreenShoeWireMutation = SyndicateWires_SendGreenShoeWireMutation;

export const useSendGreenShoeWireMutation = () => {
  return useSyndicateWires_SendGreenShoeWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_GreenShoeWiresDocument];
  return getManyOperationNames(documentNodes);
}
