import { UUID } from '@cmg/common';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LoadingButton,
  Stack,
  Typography,
} from '@cmg/design-system';
import {
  FormikNumberField,
  FormikPercentageField,
  FormikSelectField,
  FormikTextField,
} from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import useGetOfferingShares from '../../../../../../../hooks/useGetOfferingShares';
import { InstitutionalDemandGrid_DealLayoutPartsFragment } from '../../../../graphql';
import DealLayoutSharesBox from './deal-layout-shares-box/DealLayoutSharesBox';
import {
  AllocationSetValues,
  enteredValueOptions,
  EnteredValueType,
  getInitialValues,
  validationSchema,
} from './SaveAllocationSetDialog.model';
import TargetRemaining from './target-remaining/TargetRemaining';

export type Props = Readonly<{
  offeringId: UUID;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isEdit: boolean;
  canSetName: boolean;
  name: string | null | undefined;
  dealLayout: Pick<
    InstitutionalDemandGrid_DealLayoutPartsFragment,
    'offeringSize' | 'institutionalTarget'
  >;
  onSubmit: (values: AllocationSetValues) => Promise<void>;
}>;

const SaveAllocationSetDialog: React.FC<Props> = ({
  offeringId,
  title,
  isOpen,
  onClose,
  isEdit,
  canSetName,
  name,
  dealLayout,
  onSubmit,
}) => {
  const { loading, error, shares } = useGetOfferingShares({ offeringId });

  const formik = useFormik<AllocationSetValues>({
    validationSchema,
    initialValues: getInitialValues({
      name,
      offeringSize: dealLayout.offeringSize,
      institutionalTarget: dealLayout.institutionalTarget,
    }),
    onSubmit,
    enableReinitialize: true,
  });
  const { resetForm, isSubmitting, submitForm, values, setFieldValue } = formik;

  const handleCancel = React.useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  const handleChangeFormatType = React.useCallback(() => {
    setFieldValue('institutionalTarget', null);
    setFieldValue('retailTarget', null);
  }, [setFieldValue]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle title={title}>
        {isEdit ? 'Edit Allocation Set' : 'Add Allocation Set'}
      </DialogTitle>

      <DialogContent dividers>
        {error && <Alert severity="error">Failed to fetch offering data</Alert>}

        {!error && (
          <FormikProvider value={formik}>
            <Form>
              <Stack gap={2}>
                {canSetName ? (
                  <FormikTextField name="name" label="Name" fullWidth required />
                ) : (
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{name}</Typography>
                    </Grid>
                  </Grid>
                )}

                <Typography variant="h3">Deal Layout</Typography>
                <Typography>
                  Enter the target distribution between institutional allocation and retail
                  allocation.
                </Typography>

                <DealLayoutSharesBox
                  isLoading={loading}
                  baseDeal={shares?.baseDeal}
                  overallotment={shares?.overallotmentAuthorized}
                  totalOffering={shares?.totalOffering}
                />

                <FormikNumberField
                  name="offeringSize"
                  label="Offering Size"
                  decimalScale={0}
                  fullWidth
                />

                <FormikSelectField
                  name="enteredValueType"
                  label="Value"
                  options={enteredValueOptions}
                  onChange={handleChangeFormatType}
                  fullWidth
                />

                {values.enteredValueType === EnteredValueType.SHARES ? (
                  <FormikNumberField
                    name="institutionalTarget"
                    label="Institutional Target"
                    decimalScale={0}
                    fullWidth
                  />
                ) : (
                  <FormikPercentageField
                    name="institutionalTarget"
                    label="Institutional Target"
                    decimalScale={2}
                    fixedDecimalScale
                    fullWidth
                  />
                )}
                {values.enteredValueType === EnteredValueType.SHARES ? (
                  <FormikNumberField
                    name="retailTarget"
                    label="Retail Target"
                    decimalScale={0}
                    fullWidth
                  />
                ) : (
                  <FormikPercentageField
                    name="retailTarget"
                    label="Retail Target"
                    decimalScale={2}
                    fixedDecimalScale
                    fullWidth
                  />
                )}
              </Stack>

              <TargetRemaining />
            </Form>
          </FormikProvider>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={submitForm} loading={isSubmitting}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAllocationSetDialog;
