/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Calendar_CalendarGrid_OfferingLimitedPartsFragment = {
  readonly __typename?: 'OfferingLimited';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly issuerName: string;
  readonly finalFilingOfferPrice?: number | null;
  readonly securityType: Types.SecurityType;
  readonly launchDate?: string | null;
  readonly leftLead?: string | null;
  readonly sizeInCurrency?: number | null;
  readonly sizeInShares?: number | null;
  readonly pricingDate?: string | null;
  readonly issuerSector?: Types.Sector | null;
  readonly publicFilingDate?: string | null;
  readonly postponedDate?: string | null;
  readonly firstTradeDate?: string | null;
  readonly priceRangeLow?: number | null;
  readonly priceRangeHigh?: number | null;
  readonly isExecutingOnPlatform: boolean;
  readonly leftLeadFirmName?: string | null;
  readonly revisedFilingsCount?: number | null;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly pricingInstrumentCountryCode?: string | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentRegion?: string | null;
  readonly pricingInstrumentCountryDisplayName?: string | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerLimited';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly role: Types.ManagerRole;
    readonly firmNameAbbreviation?: string | null;
  }>;
};

export type Calendar_PublishedOfferingFullRowFragment = {
  readonly __typename?: 'OfferingFull';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly typeDisplayName?: string | null;
  readonly sizeInCurrency?: number | null;
  readonly sizeInShares?: number | null;
  readonly pricingInstrumentId?: string | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly priceRangeLow?: number | null;
  readonly priceRangeHigh?: number | null;
  readonly isExecutingOnPlatform: boolean;
  readonly syndicate: {
    readonly __typename?: 'SyndicateFull';
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilitiesFull';
      readonly leftLead?: string | null;
    };
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerFull';
      readonly cmgEntityKey: string;
      readonly role: Types.ManagerRole;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    }>;
  };
  readonly issuer: {
    readonly __typename?: 'IssuerFull';
    readonly name: string;
    readonly sectorDisplayName?: string | null;
    readonly sector?: Types.Sector | null;
  };
  readonly security: { readonly __typename?: 'SecurityFull'; readonly type: Types.SecurityType };
  readonly timing: {
    readonly __typename?: 'TimingFull';
    readonly firstTradeDate?: string | null;
    readonly publicFilingDate?: string | null;
    readonly pricingDate?: string | null;
    readonly booksCloseAt?: string | null;
    readonly postponedDate?: string | null;
    readonly timeZone?: string | null;
    readonly launchDate?: string | null;
  };
  readonly terms: {
    readonly __typename?: 'TermsFull';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingFull';
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
    }>;
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricingFull';
      readonly lastTradeBeforeOffer?: number | null;
    } | null;
  };
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'InstrumentFull';
    readonly countryCode?: string | null;
    readonly region?: string | null;
    readonly countryDisplayName?: string | null;
  }>;
};

export type Calendar_PublishedOfferingFullRowBuySideFragment = {
  readonly __typename?: 'OfferingFull';
  readonly dataLabFull: {
    readonly __typename?: 'DataLabFull';
    readonly mdlIndications?: {
      readonly __typename?: 'MdlIndications';
      readonly indications: ReadonlyArray<{
        readonly __typename?: 'MdlIndication';
        readonly unitType: Types.MdlIndicationUnitType;
        readonly dollars?: number | null;
        readonly percentage?: number | null;
        readonly shares?: number | null;
        readonly pricingType: Types.MdlIndicationPricingType;
        readonly limitPrice?: number | null;
        readonly realDemandDollars?: number | null;
        readonly realDemandPercentage?: number | null;
        readonly realDemandShares?: number | null;
        readonly limitType: Types.MdlIndicationLimitType;
        readonly limitPercentage?: number | null;
      }>;
    } | null;
    readonly mdlAllocation?: {
      readonly __typename?: 'MdlAllocationResponse';
      readonly allocation?: {
        readonly __typename?: 'MdlAllocation';
        readonly id: string;
        readonly fillPercentage?: number | null;
        readonly investment?: number | null;
        readonly salesConcession?: number | null;
        readonly shares?: number | null;
        readonly ranking: Types.MdlAllocationRanking;
        readonly ioiType: Types.MdlAllocationIoiType;
        readonly lastModified: {
          readonly __typename?: 'MdlAllocationLastModified';
          readonly timestamp?: string | null;
          readonly name?: string | null;
          readonly email?: string | null;
          readonly id?: string | null;
        };
      } | null;
    } | null;
  };
  readonly orderBookFull: {
    readonly __typename?: 'OrderBookFull';
    readonly myInstitutionalIndication?: {
      readonly __typename?: 'MyInstitutionalIndication';
      readonly id: string;
      readonly type: Types.InstitutionalIndicationOrderType;
      readonly status: Types.IndicationStatus;
      readonly currencyCode?: string | null;
      readonly interestLevels: ReadonlyArray<{
        readonly __typename?: 'InterestLevel';
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly interestQuantity: number;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }>;
    } | null;
    readonly finalAllocation?: {
      readonly __typename?: 'FinalAllocation';
      readonly indicationId: string;
      readonly shareQuantity?: number | null;
      readonly version: string;
      readonly acknowledgementStatus: Types.AllocationAcknowledgement;
      readonly investorReply?: {
        readonly __typename?: 'InvestorReply';
        readonly status: Types.AllocationAcknowledgement;
      } | null;
    } | null;
  };
};

export type Calendar_PublishedOfferingsQueryVariables = Types.Exact<{
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
  where: Types.OfferingFullFilterInput;
}>;

export type Calendar_PublishedOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly publishedOfferings?: {
    readonly __typename?: 'PublishedOfferingsCollectionSegment';
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly stage: Types.OfferingStage;
      readonly typeDisplayName?: string | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly pricingInstrumentId?: string | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly isExecutingOnPlatform: boolean;
      readonly syndicate: {
        readonly __typename?: 'SyndicateFull';
        readonly managerResponsibilities: {
          readonly __typename?: 'ManagerResponsibilitiesFull';
          readonly leftLead?: string | null;
        };
        readonly managers: ReadonlyArray<{
          readonly __typename?: 'ManagerFull';
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
        }>;
      };
      readonly issuer: {
        readonly __typename?: 'IssuerFull';
        readonly name: string;
        readonly sectorDisplayName?: string | null;
        readonly sector?: Types.Sector | null;
      };
      readonly security: {
        readonly __typename?: 'SecurityFull';
        readonly type: Types.SecurityType;
      };
      readonly timing: {
        readonly __typename?: 'TimingFull';
        readonly firstTradeDate?: string | null;
        readonly publicFilingDate?: string | null;
        readonly pricingDate?: string | null;
        readonly booksCloseAt?: string | null;
        readonly postponedDate?: string | null;
        readonly timeZone?: string | null;
        readonly launchDate?: string | null;
      };
      readonly terms: {
        readonly __typename?: 'TermsFull';
        readonly filings: ReadonlyArray<{
          readonly __typename?: 'FilingFull';
          readonly filingType: Types.FilingType;
          readonly offerPrice?: number | null;
        }>;
        readonly followOnPricing?: {
          readonly __typename?: 'FollowOnPricingFull';
          readonly lastTradeBeforeOffer?: number | null;
        } | null;
      };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly countryCode?: string | null;
        readonly region?: string | null;
        readonly countryDisplayName?: string | null;
      }>;
    }> | null;
  } | null;
};

export type Calendar_PublishedOfferingsBuySideQueryVariables = Types.Exact<{
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
  where: Types.OfferingFullFilterInput;
}>;

export type Calendar_PublishedOfferingsBuySideQuery = {
  readonly __typename?: 'Query';
  readonly publishedOfferings?: {
    readonly __typename?: 'PublishedOfferingsCollectionSegment';
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly stage: Types.OfferingStage;
      readonly typeDisplayName?: string | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly pricingInstrumentId?: string | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly isExecutingOnPlatform: boolean;
      readonly syndicate: {
        readonly __typename?: 'SyndicateFull';
        readonly managerResponsibilities: {
          readonly __typename?: 'ManagerResponsibilitiesFull';
          readonly leftLead?: string | null;
        };
        readonly managers: ReadonlyArray<{
          readonly __typename?: 'ManagerFull';
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
        }>;
      };
      readonly issuer: {
        readonly __typename?: 'IssuerFull';
        readonly name: string;
        readonly sectorDisplayName?: string | null;
        readonly sector?: Types.Sector | null;
      };
      readonly security: {
        readonly __typename?: 'SecurityFull';
        readonly type: Types.SecurityType;
      };
      readonly timing: {
        readonly __typename?: 'TimingFull';
        readonly firstTradeDate?: string | null;
        readonly publicFilingDate?: string | null;
        readonly pricingDate?: string | null;
        readonly booksCloseAt?: string | null;
        readonly postponedDate?: string | null;
        readonly timeZone?: string | null;
        readonly launchDate?: string | null;
      };
      readonly terms: {
        readonly __typename?: 'TermsFull';
        readonly filings: ReadonlyArray<{
          readonly __typename?: 'FilingFull';
          readonly filingType: Types.FilingType;
          readonly offerPrice?: number | null;
        }>;
        readonly followOnPricing?: {
          readonly __typename?: 'FollowOnPricingFull';
          readonly lastTradeBeforeOffer?: number | null;
        } | null;
      };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly countryCode?: string | null;
        readonly region?: string | null;
        readonly countryDisplayName?: string | null;
      }>;
      readonly dataLabFull: {
        readonly __typename?: 'DataLabFull';
        readonly mdlIndications?: {
          readonly __typename?: 'MdlIndications';
          readonly indications: ReadonlyArray<{
            readonly __typename?: 'MdlIndication';
            readonly unitType: Types.MdlIndicationUnitType;
            readonly dollars?: number | null;
            readonly percentage?: number | null;
            readonly shares?: number | null;
            readonly pricingType: Types.MdlIndicationPricingType;
            readonly limitPrice?: number | null;
            readonly realDemandDollars?: number | null;
            readonly realDemandPercentage?: number | null;
            readonly realDemandShares?: number | null;
            readonly limitType: Types.MdlIndicationLimitType;
            readonly limitPercentage?: number | null;
          }>;
        } | null;
        readonly mdlAllocation?: {
          readonly __typename?: 'MdlAllocationResponse';
          readonly allocation?: {
            readonly __typename?: 'MdlAllocation';
            readonly id: string;
            readonly fillPercentage?: number | null;
            readonly investment?: number | null;
            readonly salesConcession?: number | null;
            readonly shares?: number | null;
            readonly ranking: Types.MdlAllocationRanking;
            readonly ioiType: Types.MdlAllocationIoiType;
            readonly lastModified: {
              readonly __typename?: 'MdlAllocationLastModified';
              readonly timestamp?: string | null;
              readonly name?: string | null;
              readonly email?: string | null;
              readonly id?: string | null;
            };
          } | null;
        } | null;
      };
      readonly orderBookFull: {
        readonly __typename?: 'OrderBookFull';
        readonly myInstitutionalIndication?: {
          readonly __typename?: 'MyInstitutionalIndication';
          readonly id: string;
          readonly type: Types.InstitutionalIndicationOrderType;
          readonly status: Types.IndicationStatus;
          readonly currencyCode?: string | null;
          readonly interestLevels: ReadonlyArray<{
            readonly __typename?: 'InterestLevel';
            readonly interestUnit: Types.InterestLevelInterestUnit;
            readonly interestQuantity: number;
            readonly limitPrice?: number | null;
            readonly limitType?: Types.InterestLevelLimitType | null;
          }>;
        } | null;
        readonly finalAllocation?: {
          readonly __typename?: 'FinalAllocation';
          readonly indicationId: string;
          readonly shareQuantity?: number | null;
          readonly version: string;
          readonly acknowledgementStatus: Types.AllocationAcknowledgement;
          readonly investorReply?: {
            readonly __typename?: 'InvestorReply';
            readonly status: Types.AllocationAcknowledgement;
          } | null;
        } | null;
      };
    }> | null;
  } | null;
};

export const Calendar_CalendarGrid_OfferingLimitedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_CalendarGrid_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLeadFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revisedFilingsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentRegion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryDisplayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Calendar_PublishedOfferingFullRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leftLead' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectorDisplayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryDisplayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Calendar_PublishedOfferingFullRowBuySideFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRowBuySide' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataLabFull' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mdlIndications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'indications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mdlAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookFull' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInstitutionalIndication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_MyInstitutionalIndicationParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MyInstitutionalIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgementStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Calendar_PublishedOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFullFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRow' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leftLead' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectorDisplayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryDisplayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCalendar_PublishedOfferingsQuery__
 *
 * To run a query within a React component, call `useCalendar_PublishedOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendar_PublishedOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendar_PublishedOfferingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCalendar_PublishedOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calendar_PublishedOfferingsQuery,
    Calendar_PublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Calendar_PublishedOfferingsQuery,
    Calendar_PublishedOfferingsQueryVariables
  >(Calendar_PublishedOfferingsDocument, options);
}
export function useCalendar_PublishedOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calendar_PublishedOfferingsQuery,
    Calendar_PublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Calendar_PublishedOfferingsQuery,
    Calendar_PublishedOfferingsQueryVariables
  >(Calendar_PublishedOfferingsDocument, options);
}
export type Calendar_PublishedOfferingsQueryHookResult = ReturnType<
  typeof useCalendar_PublishedOfferingsQuery
>;
export type Calendar_PublishedOfferingsLazyQueryHookResult = ReturnType<
  typeof useCalendar_PublishedOfferingsLazyQuery
>;
export type Calendar_PublishedOfferingsQueryResult = Apollo.QueryResult<
  Calendar_PublishedOfferingsQuery,
  Calendar_PublishedOfferingsQueryVariables
>;
export const Calendar_PublishedOfferingsBuySideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingsBuySide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFullFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRow' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRowBuySide' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leftLead' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectorDisplayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryDisplayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_PublishedOfferingFullRowBuySide' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataLabFull' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mdlIndications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'indications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mdlAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookFull' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInstitutionalIndication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_MyInstitutionalIndicationParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MyInstitutionalIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgementStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCalendar_PublishedOfferingsBuySideQuery__
 *
 * To run a query within a React component, call `useCalendar_PublishedOfferingsBuySideQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendar_PublishedOfferingsBuySideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendar_PublishedOfferingsBuySideQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCalendar_PublishedOfferingsBuySideQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calendar_PublishedOfferingsBuySideQuery,
    Calendar_PublishedOfferingsBuySideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Calendar_PublishedOfferingsBuySideQuery,
    Calendar_PublishedOfferingsBuySideQueryVariables
  >(Calendar_PublishedOfferingsBuySideDocument, options);
}
export function useCalendar_PublishedOfferingsBuySideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calendar_PublishedOfferingsBuySideQuery,
    Calendar_PublishedOfferingsBuySideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Calendar_PublishedOfferingsBuySideQuery,
    Calendar_PublishedOfferingsBuySideQueryVariables
  >(Calendar_PublishedOfferingsBuySideDocument, options);
}
export type Calendar_PublishedOfferingsBuySideQueryHookResult = ReturnType<
  typeof useCalendar_PublishedOfferingsBuySideQuery
>;
export type Calendar_PublishedOfferingsBuySideLazyQueryHookResult = ReturnType<
  typeof useCalendar_PublishedOfferingsBuySideLazyQuery
>;
export type Calendar_PublishedOfferingsBuySideQueryResult = Apollo.QueryResult<
  Calendar_PublishedOfferingsBuySideQuery,
  Calendar_PublishedOfferingsBuySideQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeCalendar_PublishedOfferingsMockResponse = makeMockResponse<
  Calendar_PublishedOfferingsQueryVariables,
  Calendar_PublishedOfferingsQuery
>(Calendar_PublishedOfferingsDocument);

export const makeCalendar_PublishedOfferingsBuySideMockResponse = makeMockResponse<
  Calendar_PublishedOfferingsBuySideQueryVariables,
  Calendar_PublishedOfferingsBuySideQuery
>(Calendar_PublishedOfferingsBuySideDocument);
