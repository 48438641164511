import { List } from '@cmg/design-system';
import React, { VFC } from 'react';
import { useLocation } from 'react-router-dom';

import { useFeatureToggles } from '../../../../../../common/config';
import useCheckOrderBookAccess from '../../../../../../common/util/check-access/useCheckOrderBookAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { checkRouteIsActive } from '../../../../../../common/util/routeFactory';
import { useCmgEntityKeyContext } from '../../../../contexts/CmgEntityKeyContext';
import { useSupersededOfferingContext } from '../../../../contexts/SupersededOfferingContext';
import offeringSidePanelSellSideRouteFactory from '../../../../offeringSidePanelSellSideRouteFactory';
import { useOfferingSidePanelContext } from '../../../../order-book/indication-activity/context-old/OfferingSidePanelContext';
import { SideNavbarItem } from './SideNavbarItem';

export type Props = {
  readonly offeringId: string;
  readonly baseSidePanelUrl: string;
};

export const OrderBookSubNavigation: VFC<Props> = ({ offeringId, baseSidePanelUrl }) => {
  const { pathname } = useLocation();
  const { isOrderBookAttestationOn } = useFeatureToggles();
  const { canRead: canReadOrderBook } = useCheckOrderBookAccess({ offeringId });
  const { canRead: canReadIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const { indication } = useOfferingSidePanelContext();
  const { isFiledOffering } = useSupersededOfferingContext();

  // no point in displaying the Order Book nav group without Indications - require access to both
  // (this might change once there are more nav items than these related to indications)
  if (!canReadOrderBook || !canReadIndications) {
    return null;
  }

  const communicatedDemandUrl =
    baseSidePanelUrl +
    offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
      cmgEntityKey,
    });
  const updateHistoryUrl =
    baseSidePanelUrl +
    offeringSidePanelSellSideRouteFactory.orderBookUpdateHistory.getUrlPath({
      cmgEntityKey: cmgEntityKey!,
    });
  const complianceUrl =
    baseSidePanelUrl +
    offeringSidePanelSellSideRouteFactory.orderBookCompliance.getUrlPath({
      cmgEntityKey: cmgEntityKey!,
    });

  return (
    <React.Fragment>
      <List component="div" role="menubar">
        {canReadIndications && !isFiledOffering && (
          <SideNavbarItem
            href={communicatedDemandUrl}
            selected={checkRouteIsActive(
              offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.routePath,
              pathname
            )}
          >
            Communicated Demand
          </SideNavbarItem>
        )}
        {(checkRouteIsActive(
          offeringSidePanelSellSideRouteFactory.orderBookUpdateHistory.routePath,
          pathname
        ) ||
          indication.data) && (
          <SideNavbarItem
            href={updateHistoryUrl}
            selected={checkRouteIsActive(
              offeringSidePanelSellSideRouteFactory.orderBookUpdateHistory.routePath,
              pathname
            )}
          >
            Update History
          </SideNavbarItem>
        )}
        {isOrderBookAttestationOn && (
          <SideNavbarItem
            href={complianceUrl}
            selected={checkRouteIsActive(
              offeringSidePanelSellSideRouteFactory.orderBookCompliance.routePath,
              pathname
            )}
          >
            Compliance
          </SideNavbarItem>
        )}
      </List>
    </React.Fragment>
  );
};
