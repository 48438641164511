import {
  DeepWritable,
  filterSidePanelEvent,
  useDebouncedCalendarRefreshEvent,
  UUID,
} from '@cmg/common';
import { Skeleton, Stack } from '@cmg/design-system';
import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ErrorRenderer from '../../../common/components/errorRenderer/Error-Renderer';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useBusyDelay } from '../../../common/hooks';
import { useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery } from '../../offering/offering-details/graphql';
import OfferingProfile from '../../offering-profile/OfferingProfile';
import { SidePanelTitle } from '../components/side-bar/components/design-system';

export type Props = RouteComponentProps<{
  readonly offeringId: UUID;
}>;

const SellSideOfferingDetailsRoute: FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const { data, loading, error, refetch } =
    useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery({
      variables: { id: offeringId },
      fetchPolicy: 'no-cache',
    });

  const filterFn = useMemo(() => {
    return filterSidePanelEvent(offeringId);
  }, [offeringId]);

  const isBusy = useBusyDelay(loading, 750);

  useDebouncedCalendarRefreshEvent({
    callback: useCallback(() => {
      refetch();
    }, [refetch]),
    filterFn,
  });

  if (error) {
    return <ErrorRenderer error={error} />;
  }

  if (isBusy) {
    return (
      <Stack spacing={1}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton variant="rectangular" height={118} />
      </Stack>
    );
  }

  return (
    <FlexLayout direction="column">
      <SidePanelTitle>Offering Details</SidePanelTitle>
      {data && (
        <OfferingProfile
          offering={data.basicPublishedOffering as DeepWritable<typeof data.basicPublishedOffering>}
        />
      )}
    </FlexLayout>
  );
};

export default SellSideOfferingDetailsRoute;
