import { numericUtil } from '@cmg/common';

import { DesignationManager } from '../../../../hooks/useDesignationContext';
import { DesignationRow } from '../../DesignationMonitorGrid.model';

type GroupedManagerShares = {
  managerName: string;
  fixedPot: number | null;
  finalPot: number | null;
};

export const getAllInvestments = (
  investorRows: DesignationRow[],
  managers: DesignationManager[]
): GroupedManagerShares[] => {
  return managers.map<GroupedManagerShares>(({ cmgEntityKey, cmgEntityName }) => ({
    managerName: cmgEntityName,
    fixedPot: numericUtil.sum(
      ...investorRows.map(({ investments }) => investments?.[cmgEntityKey].fixedEconomicsShares)
    ),
    finalPot: numericUtil.sum(
      ...investorRows.map(({ investments }) => investments?.[cmgEntityKey].shareQuantity)
    ),
  }));
};
