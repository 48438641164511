import React from 'react';

import StatusComponent from '../../../../common/components/indicators/status/Status';
import { EmailDeliveryStatus } from '../../../../graphql';

export type Props = {
  status: EmailDeliveryStatus;
};

const ProspectusDeliveryEmailStatus: React.FC<Props> = ({ status }) => {
  return (
    <StatusComponent
      size="xs"
      variant="DARK"
      status={status}
      options={theme => ({
        [EmailDeliveryStatus.InProgress]: {
          label: 'In Progress',
          color: theme.designSystem.colors.gray['300'],
        },
        [EmailDeliveryStatus.Sent]: {
          label: 'Sent',
          color: theme.designSystem.colors.green['300'],
        },
        [EmailDeliveryStatus.Failed]: {
          label: 'Failed',
          color: theme.designSystem.colors.red['300'],
        },
        [EmailDeliveryStatus.NotSent]: {
          label: 'Not Sent',
          color: theme.designSystem.colors.gray['300'],
        },
      })}
    />
  );
};

export default ProspectusDeliveryEmailStatus;
