import {
  DateRangeField,
  ODSTable as Table,
  SelectField,
  SwitchField,
  timeUtil,
  timeZoneUtil,
} from '@cmg/common';
import { eachDayOfInterval, format } from 'date-fns';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { RoadshowDay, RoadshowDetailsValues } from '../RoadshowDetails.model';
import RoadshowPlaceMultiSelectField from './roadshow-place-multi-select/RoadshowPlaceMultiSelectField';
import { SErrorWrapper } from './RoadshowDaysAndLocations.styles';

const RoadshowDaysAndLocations: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<RoadshowDetailsValues>();

  const handleRoadshowDateChange = (dateRange: { start?: string; end?: string }) => {
    if (!dateRange.start || !dateRange.end) {
      return;
    }

    const dates = eachDayOfInterval({
      start: new Date(dateRange.start),
      end: new Date(dateRange.end),
    });

    const roadshowDays: RoadshowDay[] = dates.map(date => ({
      date: date.toISOString(),
      isVirtual: true,
      timeZone: values.defaultTimeZone,
      cities: [],
      timezones: [],
    }));
    setFieldValue('roadshowDays', roadshowDays);
  };

  const handleDefaultTimezoneChange = (timeZoneValue: string) => {
    const { roadshowDays, defaultTimeZone } = values;
    roadshowDays.forEach((day, dayIndex) => {
      if (day.isVirtual && (!day.timeZone || day.timeZone === defaultTimeZone)) {
        setFieldValue(`roadshowDays.${dayIndex}.timeZone`, timeZoneValue);
      }
      return day;
    });
  };

  const handleIsVirtualChanged = (dayIndex: number, isVirtual: boolean) => {
    setFieldValue(`roadshowDays.${dayIndex}.timeZone`, isVirtual ? values.defaultTimeZone : null);
    setFieldValue(`roadshowDays.${dayIndex}.timezones`, []);
    setFieldValue(`roadshowDays.${dayIndex}.cities`, []);
  };

  const defaultTimeZoneEnabled = React.useMemo(
    () => values.roadshowDays.some(day => day.isVirtual),
    [values.roadshowDays]
  );

  return (
    <Panel>
      <Panel.Header title="Days and Locations" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            {/* User needs feedback if validation fails, DateRangeField doesn't display it */}
            <ErrorMessage name="dateRange" render={msg => <SErrorWrapper>{msg}</SErrorWrapper>} />
            <DateRangeField
              testId="roadshows date range"
              name="dateRange"
              label="Start and End Date"
              placeholder="Select Start and End Days"
              onChange={handleRoadshowDateChange}
              presetOptions={{}}
              required
              fullWidth
              withMargin
            />
          </Column>
          <Column span={3}>
            <SelectField
              label="Default Timezone for Virtual Days"
              name="defaultTimeZone"
              disabled={!defaultTimeZoneEnabled}
              options={timeZoneUtil.getDefaultTimeZoneOptions()}
              fullWidth
              withMargin
              onChange={handleDefaultTimezoneChange}
            />
          </Column>
        </GridLayout>
        {values.dateRange.start && values.dateRange.end && (
          <Table gridTemplateColumns="25% 10% 50% 15%">
            <Table.TableHeaderCell>Days</Table.TableHeaderCell>
            <Table.TableHeaderCell>Virtual Day</Table.TableHeaderCell>
            <Table.TableHeaderCell>Location</Table.TableHeaderCell>
            <Table.TableHeaderCell>
              Timezone
              <MandatoryAsterisk />
            </Table.TableHeaderCell>
            <FieldArray name="roadshowDays">
              {arrayHelpers =>
                values.roadshowDays.map((day, dayIndex) => (
                  <Table.Row key={dayIndex}>
                    <Table.Cell>{format(new Date(day.date), 'eeee LLLL d, yyyy')}</Table.Cell>
                    <Table.Cell paddingSize="S" centered>
                      <SwitchField
                        name={`roadshowDays.${dayIndex}.isVirtual`}
                        onChange={value => handleIsVirtualChanged(dayIndex, value)}
                      />
                    </Table.Cell>
                    <Table.Cell paddingSize="S" centered>
                      {day.isVirtual ? (
                        'Virtual Day'
                      ) : (
                        <RoadshowPlaceMultiSelectField date={day.date} dayIndex={dayIndex} />
                      )}
                    </Table.Cell>

                    {day.isVirtual ? (
                      <Table.Cell paddingSize="S" centered>
                        <SelectField
                          aria-label="Roadshow Day Timezone"
                          name={`roadshowDays.${dayIndex}.timeZone`}
                          options={timeZoneUtil.getTimeZoneOptions(day.date)}
                          fullWidth
                        />
                      </Table.Cell>
                    ) : (
                      <Table.Cell paddingSize="S" centered>
                        {day.timezones
                          .map(timezone =>
                            timeUtil.formatAsTimeZoneAbbreviation(day.date, timezone)
                          )
                          .join(', ')}
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))
              }
            </FieldArray>
          </Table>
        )}
      </Panel.Content>
    </Panel>
  );
};

export default RoadshowDaysAndLocations;
