import { FormikErrors, FormikTouched } from 'formik';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { getFormikErrorFieldLabels } from './useFormikErrors.model';

export type UseFormikErrorsProps<TValues extends yup.Maybe<yup.AnyObject>> = Readonly<{
  readonly errors: FormikErrors<TValues>;
  readonly touched: FormikTouched<TValues>;
  readonly isSubmitting: boolean;
  readonly validationSchema: yup.ObjectSchema<TValues>;
}>;

export type UseFormikErrorsResult = Readonly<{
  readonly invalidFormFieldLabels: string[];
  readonly showInvalidFields: boolean;
}>;

/**
 * This hook is used to show a list form fields that have validation errors on submit.
 * @param errors
 * @param touched
 * @param isSubmitting
 * @param validationSchema
 */
export function useFormikErrors<TValues extends yup.Maybe<yup.AnyObject>>({
  errors,
  touched,
  isSubmitting,
  validationSchema,
}: UseFormikErrorsProps<TValues>): UseFormikErrorsResult {
  const [invalidFormFieldLabels, setInvalidFormFieldLabels] = useState<string[]>([]);

  /**
   * When the form is submitted, we want to show all the errors that are present at that moment.
   */
  useEffect(() => {
    if (!isSubmitting) {
      return;
    }

    const submitValidationErrors = getFormikErrorFieldLabels(errors, validationSchema);
    setInvalidFormFieldLabels(submitValidationErrors);
  }, [errors, isSubmitting, validationSchema]);

  /**
   * When the user fixes an error, we want to remove it from the list of errors.
   * Exclude all re-occurring errors that were present on last submit but were already fixed.
   * Exclude all new errors that were not present on last submit.
   */
  useEffect(() => {
    if (invalidFormFieldLabels.length === 0) {
      return;
    }

    const currInvalidFormFieldLabels = getFormikErrorFieldLabels(errors, validationSchema);
    const newInvalidFormFieldLabels = intersection(
      invalidFormFieldLabels,
      currInvalidFormFieldLabels
    );

    const diff = difference(invalidFormFieldLabels, newInvalidFormFieldLabels);

    if (diff.length) {
      setInvalidFormFieldLabels(newInvalidFormFieldLabels);
    }
  }, [errors, invalidFormFieldLabels, validationSchema]);

  const showInvalidFields = useMemo(() => {
    return !isEmpty(touched) && !!invalidFormFieldLabels.length;
  }, [invalidFormFieldLabels.length, touched]);

  return {
    invalidFormFieldLabels,
    showInvalidFields,
  };
}
