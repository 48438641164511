import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

import { UnderwritingTermsDiscountsAndFeesFormValues } from '../UnderwritingTermsDiscountsAndFeesSection';
import { getTotalCalculations } from '../UnderwritingTermsDiscountsAndFeesSection.model';

type InputDependentField = {
  fieldName: string;
  baseCalculationFieldName: string;
  valueCalculationFieldName: string;
  calc: (values: UnderwritingTermsDiscountsAndFeesFormValues) => number | null;
};

type TotalDiffDependentField = {
  fieldName: string;
  baseCalculationFieldName?: string;
  valueCalculationFieldName: string;
  calc: (values: UnderwritingTermsDiscountsAndFeesFormValues) => number | null;
};

type TotalDependentField = {
  fieldName: string;
  sumFields: Array<string>;
  calc: (values: UnderwritingTermsDiscountsAndFeesFormValues) => number | null;
};

type DependentFieldMap = {
  [fieldName: string]: Array<InputDependentField | TotalDependentField | TotalDiffDependentField>;
};

const dependentFieldMap: DependentFieldMap = {
  'grossSpreadBaseData.grossSpreadBase': [
    {
      fieldName: 'grossSpreadBaseData.grossSpreadBasePercentage',
      baseCalculationFieldName: 'offerPrice',
      valueCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.managementFeePercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.managementFee',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.underwritingFeePercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.underwritingFee',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.sellingConcessionPercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.sellingConcession',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'grossSpreadBaseData.grossSpreadBasePercentage': [
    {
      fieldName: 'grossSpreadBaseData.grossSpreadBase',
      baseCalculationFieldName: 'offerPrice',
      valueCalculationFieldName: 'grossSpreadBaseData.grossSpreadBasePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.managementFee',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.managementFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.underwritingFee',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.underwritingFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'grossSpreadBaseData.sellingConcession',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.sellingConcessionPercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'grossSpreadBaseData.managementFee': [
    {
      fieldName: 'grossSpreadBaseData.managementFeePercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.managementFee',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'grossSpreadBaseData.managementFeePercentage': [
    {
      fieldName: 'grossSpreadBaseData.managementFee',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.managementFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'grossSpreadBaseData.underwritingFee': [
    {
      fieldName: 'grossSpreadBaseData.underwritingFeePercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.underwritingFee',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'grossSpreadBaseData.underwritingFeePercentage': [
    {
      fieldName: 'grossSpreadBaseData.underwritingFee',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.underwritingFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'grossSpreadBaseData.sellingConcession': [
    {
      fieldName: 'grossSpreadBaseData.sellingConcessionPercentage',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.sellingConcession',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'grossSpreadBaseData.sellingConcessionPercentage': [
    {
      fieldName: 'grossSpreadBaseData.sellingConcession',
      baseCalculationFieldName: 'grossSpreadBaseData.grossSpreadBase',
      valueCalculationFieldName: 'grossSpreadBaseData.sellingConcessionPercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'incentiveFeeData.incentiveFee': [
    {
      fieldName: 'incentiveFeeData.incentiveFeePercentage',
      baseCalculationFieldName: 'offerPrice',
      valueCalculationFieldName: 'incentiveFeeData.incentiveFee',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'incentiveFeeData.managementFeePercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.managementFeePercentage',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'incentiveFeeData.underwritingFeePercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.underwritingFeePercentage',
      calc: dependentFieldPercentageCalculation,
    },
    {
      fieldName: 'incentiveFeeData.sellingConcessionPercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.sellingConcessionPercentage',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'incentiveFeeData.incentiveFeePercentage': [
    {
      fieldName: 'incentiveFeeData.incentiveFee',
      baseCalculationFieldName: 'offerPrice',
      valueCalculationFieldName: 'incentiveFeeData.incentiveFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'incentiveFeeData.managementFee',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.managementFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'incentiveFeeData.underwritingFee',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.underwritingFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
    {
      fieldName: 'incentiveFeeData.sellingConcession',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.sellingConcessionPercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'incentiveFeeData.managementFee': [
    {
      fieldName: 'incentiveFeeData.managementFeePercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.managementFee',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'incentiveFeeData.managementFeePercentage': [
    {
      fieldName: 'incentiveFeeData.managementFee',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.managementFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'incentiveFeeData.underwritingFee': [
    {
      fieldName: 'incentiveFeeData.underwritingFeePercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.underwritingFee',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'incentiveFeeData.underwritingFeePercentage': [
    {
      fieldName: 'incentiveFeeData.underwritingFee',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.underwritingFeePercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
  'incentiveFeeData.sellingConcession': [
    {
      fieldName: 'incentiveFeeData.sellingConcessionPercentage',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.sellingConcession',
      calc: dependentFieldPercentageCalculation,
    },
  ],
  'incentiveFeeData.sellingConcessionPercentage': [
    {
      fieldName: 'incentiveFeeData.sellingConcession',
      baseCalculationFieldName: 'incentiveFeeData.incentiveFee',
      valueCalculationFieldName: 'incentiveFeeData.sellingConcessionPercentage',
      calc: dependentFieldCurrencyCalculation,
    },
  ],
};

export function dependentFieldPercentageCalculation(
  this: InputDependentField,
  values: UnderwritingTermsDiscountsAndFeesFormValues
) {
  const baseValue = get(values, this.baseCalculationFieldName);
  const value = get(values, this.valueCalculationFieldName);
  if (isNil(baseValue) || isNil(value)) return null;
  if (baseValue === 0) return null;
  return value / baseValue;
}

export function dependentFieldCurrencyCalculation(
  this: InputDependentField,
  values: UnderwritingTermsDiscountsAndFeesFormValues
) {
  const baseValue = get(values, this.baseCalculationFieldName);
  const value = get(values, this.valueCalculationFieldName);
  if (isNil(baseValue) || isNil(value)) return null;
  return Number((value * baseValue).toFixed(6));
}

export function useSetDependentFieldValue(name: string) {
  const { values, setValues } = useFormikContext<UnderwritingTermsDiscountsAndFeesFormValues>();

  const updateDependantFieldsOnValueChange = (value: bigint | number | null) => {
    const defaultValues = { ...values };
    set(defaultValues, name, value);
    const dependentFields = dependentFieldMap[name];
    const newValues = dependentFields.reduce((acc, dependentField) => {
      const result = dependentField.calc(acc);
      set(acc, dependentField.fieldName, isNil(result) || isNaN(result) ? null : result);
      return acc;
    }, defaultValues);

    const totals = getTotalCalculations(newValues);

    setValues({ ...newValues, ...totals });
  };
  return { updateDependantFieldsOnValueChange };
}
