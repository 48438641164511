import React from 'react';

import { DesignationRow } from '../../DesignationMonitorGrid.model';
import {
  getWarningReason,
  rowContainsErrors,
  rowContainsNonApplicableWarning,
} from './DesignationMonitorWarningsRenderer.model';
import OutOfBalanceError from './OutOfBalanceError';
import RepublishWarning from './RepublishWarning';

export type Props = Readonly<{
  data: DesignationRow;
}>;

const DesignationMonitorWarningsRenderer: React.FC<Props> = ({ data }) => {
  const { warnings, investorName, totalDesignations, shareQuantity } = data;

  if (warnings.length === 0) {
    return null;
  }

  if (rowContainsNonApplicableWarning(warnings)) {
    return <RepublishWarning investorName={investorName} reason={getWarningReason(warnings)} />;
  }

  if (rowContainsErrors(warnings)) {
    return (
      <OutOfBalanceError
        investorName={investorName}
        allocatedShares={shareQuantity}
        designatedShares={totalDesignations}
      />
    );
  }

  return null; // should not occur unless there is new warning type
};

export default DesignationMonitorWarningsRenderer;
