import { Tag } from '@cmg/common';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';

export const StyledIssuerName = styled(FlexLayout)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledRevisionTagComponent = styled(Tag)`
  background-color: ${({ theme }) => theme.background.color.menu};
  margin-left: 10px;
`;

export const StyledRevisionTag = React.forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
  (props, ref) => (
    <StyledRevisionTagComponent as={Tag} ref={ref}>
      {props.children}
    </StyledRevisionTagComponent>
  )
);
