import { SubNavigation, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import entitlementsRouteFactory from '../../entitlementsRouteFactory';

export const StyledSubNavigation = styled(SubNavigation)`
  border-bottom: ${({ theme }) => theme.border.smallSolidLight};
`;

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  padding-bottom: 15px;
`;

export type Props = {
  offeringId: UUID;
};

/**
 * Sub-navigation/breadcrumbs for the my offerings module
 */
const EntitlementsSubNavigation: React.FC<Props> = ({ offeringId }) => (
  <StyledSubNavigation testId={xcSelectors.offeringSetupEntitlementsSubNavigation.testId}>
    <SubNavigation.Group>
      <SubNavigation.Item>
        <StyledSubNavigationLink
          to={entitlementsRouteFactory.offeringSetupEntitlements.getUrlPath({ offeringId })}
          data-test-id={xcSelectors.offeringSetupEntitlementsSubNavigationOfferingSetup.testId}
        >
          Offering setup
        </StyledSubNavigationLink>
      </SubNavigation.Item>
      <SubNavigation.Item>
        <StyledSubNavigationLink
          to={entitlementsRouteFactory.orderBookEntitlements.getUrlPath({ offeringId })}
          data-test-id={xcSelectors.offeringSetupEntitlementsSubNavigationOrderBook.testId}
        >
          Order Book
        </StyledSubNavigationLink>
      </SubNavigation.Item>
      <SubNavigation.Item>
        <StyledSubNavigationLink
          to={entitlementsRouteFactory.roadshowEntitlements.getUrlPath({ offeringId })}
          data-test-id={xcSelectors.offeringSetupEntitlementsSubNavigationRoadshow.testId}
        >
          Roadshow
        </StyledSubNavigationLink>
      </SubNavigation.Item>
      <SubNavigation.Item>
        <StyledSubNavigationLink
          to={entitlementsRouteFactory.syndicateWiresEntitlements.getUrlPath({ offeringId })}
          data-test-id={xcSelectors.offeringSetupEntitlementsSubNavigationSyndicateWires.testId}
        >
          Wires
        </StyledSubNavigationLink>
      </SubNavigation.Item>
    </SubNavigation.Group>
  </StyledSubNavigation>
);

export default EntitlementsSubNavigation;
