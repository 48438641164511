import { useFormikContext } from 'formik';
import React from 'react';

import { Values } from '../../StabilizationForm.model';
import EditableStabilizationTradesField from './editable-stabilization-trades/EditableStabilizationTrades';
import ReadOnlyStabilizationTrades from './read-only-stabilization-trades/ReadOnlyStabilizationTrades';

export type Props = {
  isEditable: boolean;
  offeringPrice: number;
};

export const StabilizationTrades: React.FC<Props> = ({ offeringPrice, isEditable }) => {
  const { values } = useFormikContext<Values>();

  if (isEditable) {
    return <EditableStabilizationTradesField offeringPrice={offeringPrice} />;
  }

  return <ReadOnlyStabilizationTrades offeringPrice={offeringPrice} trades={values.buy} />;
};

export default StabilizationTrades;
