import { Button, Stack } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { TextareaCharacterCount } from '../../../../../../common/components/form/textarea-character-count/TextareaCharacterCount';
import { useDisclaimerContext } from '../dialogs/create-or-update-wire-dialog/context/DisclaimerContext';
import { DISCLAIMER_TEXT_LIMIT, FIELD_NAME } from './Disclaimer.model';

export const Disclaimer: React.FC = () => {
  const { areCustomVariablesVisible, setCustomVariablesVisible, disclaimerInputRef } =
    useDisclaimerContext();
  const disclaimerCount = disclaimerInputRef.current?.value.length ?? 0;
  const { setFieldTouched, errors } = useFormikContext();

  return (
    <React.Fragment>
      <Stack direction="column" gap={0.5}>
        <FormikTextField
          inputRef={disclaimerInputRef}
          inputProps={{
            onFocus: () => setFieldTouched(FIELD_NAME),
          }}
          showHelperTextInTooltip
          label="Custom Disclaimer"
          multiline
          name={FIELD_NAME}
          rows={4}
        />
        <TextareaCharacterCount
          count={disclaimerCount}
          max={DISCLAIMER_TEXT_LIMIT}
          hasError={!!errors[FIELD_NAME]}
        />
      </Stack>
      <Button onClick={() => setCustomVariablesVisible(!areCustomVariablesVisible)}>
        {areCustomVariablesVisible ? 'Hide Variables' : 'Show Variables'}
      </Button>
    </React.Fragment>
  );
};
