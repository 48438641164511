/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_UpdateSalesCreditsAmountMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: ReadonlyArray<Types.UpdateSalesCreditsAmountInput> | Types.UpdateSalesCreditsAmountInput;
}>;

export type SalesCredits_UpdateSalesCreditsAmountMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSalesCreditsAmount: {
    readonly __typename?: 'SalesCreditGrid';
    readonly id: string;
    readonly settlementCurrency: string;
    readonly sellingConcession?: number | null;
    readonly salesCredits: ReadonlyArray<{
      readonly __typename?: 'SalesCreditItem';
      readonly id: string;
      readonly salesCredits?: number | null;
      readonly salesCreditsReleased?: number | null;
      readonly salesCreditsManual?: number | null;
      readonly salesCreditsId?: string | null;
      readonly investorFirmName: string;
      readonly orderBookBankInvestorFirmName?: string | null;
      readonly investorFirmCmgEntityKey: string;
      readonly designationShares?: number | null;
      readonly firmId?: string | null;
      readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
      readonly allocationShares?: number | null;
      readonly bankInvestorKey?: string | null;
      readonly orderBookBankInvestorKey?: string | null;
      readonly statusDetails?: string | null;
      readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
      readonly status: Types.SalesCreditReleaseStatus;
    }>;
  };
};

export const SalesCredits_UpdateSalesCreditsAmountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SalesCredits_UpdateSalesCreditsAmount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateSalesCreditsAmountInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalesCreditsAmount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SalesCredits_UpdateSalesCreditsAmountMutationFn = Apollo.MutationFunction<
  SalesCredits_UpdateSalesCreditsAmountMutation,
  SalesCredits_UpdateSalesCreditsAmountMutationVariables
>;

/**
 * __useSalesCredits_UpdateSalesCreditsAmountMutation__
 *
 * To run a mutation, you first call `useSalesCredits_UpdateSalesCreditsAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_UpdateSalesCreditsAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesCreditsUpdateSalesCreditsAmountMutation, { data, loading, error }] = useSalesCredits_UpdateSalesCreditsAmountMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSalesCredits_UpdateSalesCreditsAmountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesCredits_UpdateSalesCreditsAmountMutation,
    SalesCredits_UpdateSalesCreditsAmountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesCredits_UpdateSalesCreditsAmountMutation,
    SalesCredits_UpdateSalesCreditsAmountMutationVariables
  >(SalesCredits_UpdateSalesCreditsAmountDocument, options);
}
export type SalesCredits_UpdateSalesCreditsAmountMutationHookResult = ReturnType<
  typeof useSalesCredits_UpdateSalesCreditsAmountMutation
>;
export type SalesCredits_UpdateSalesCreditsAmountMutationResult =
  Apollo.MutationResult<SalesCredits_UpdateSalesCreditsAmountMutation>;
export type SalesCredits_UpdateSalesCreditsAmountMutationOptions = Apollo.BaseMutationOptions<
  SalesCredits_UpdateSalesCreditsAmountMutation,
  SalesCredits_UpdateSalesCreditsAmountMutationVariables
>;
