import { AppBarNavigationLink } from '@cmg/design-system';
import { Fragment, VFC } from 'react';
import { useLocation } from 'react-router';

import { checkRouteIsActive } from '../../../common/util/routeFactory';
import { AppBarNavigationDefinition } from './types';

type TopLevelItemsProps = {
  readonly navItems: readonly AppBarNavigationDefinition[];
};

export const TopLevelItems: VFC<TopLevelItemsProps> = ({ navItems }) => {
  const { pathname: currentPathName } = useLocation();

  return (
    <Fragment>
      {navItems.map(navDefinition => (
        <AppBarNavigationLink
          key={navDefinition.id}
          isActive={checkRouteIsActive(navDefinition.routePath, currentPathName)}
          href={navDefinition.href}
          target={navDefinition.target}
          startIcon={navDefinition.startIcon}
        >
          {navDefinition.label}
        </AppBarNavigationLink>
      ))}
    </Fragment>
  );
};
