import { numericUtil } from '@cmg/common';

import { InternalDemandSummary_MdlIndicationPartsFragment } from './graphql';

/**
 * Format indication preface based on its value type
 */
export const formatPreface = (indication: InternalDemandSummary_MdlIndicationPartsFragment) => {
  const isDollars = indication && !!indication.realDemandDollars;
  const isShares = indication && !!indication.realDemandShares;
  const isPercentage = indication && !!indication.realDemandPercentage;

  if (isDollars) {
    return `$${numericUtil.formatNumber(indication.realDemandDollars)}`;
  }

  if (isShares) {
    return `${numericUtil.formatNumber(indication.realDemandShares, 0)} Shares`;
  }

  if (isPercentage) {
    return `${numericUtil.formatPercents(indication.realDemandPercentage, 0)}`;
  }

  return '';
};
