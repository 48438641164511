import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import InfoIconPopover from '../../../../common/components/info-icon-popover/InfoIconPopover';
import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';
import PotAvailableInfo from './pot-available-info/PotAvailableInfo';
import { calculateRemainingSharesValues } from './RemainingSharesInfoBox.model';

const RemainingSharesInfoBox: React.FC = () => {
  const accounting = useAccountingContext();
  const { potAllocated, retailRetention } = accounting;

  const { totalDealShares, potAvailable, remainingShares } =
    calculateRemainingSharesValues(accounting);

  return (
    <AccountingInfoBox gridTemplateColumns="3fr 10px 1.5fr" sourceOfData="Sourced from Order Book">
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Pot Available
        <InfoIconPopover>
          <PotAvailableInfo
            totalDealShares={totalDealShares}
            retailRetention={retailRetention}
            potAvailable={potAvailable}
          />
        </InfoIconPopover>
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(potAvailable)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Pot Allocated</Table.Cell>
      <Table.Cell paddingSize="S" bold>
        -
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForInteger(potAllocated)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Remaining Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForInteger(remainingShares)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default RemainingSharesInfoBox;
