import { Table } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const StyledTable = styled(Table)`
  max-width: 1800px;
`;

export const SColumnHeader = styled.th<{ maxWidth?: number }>`
  ${({ maxWidth }) => css`
    ${maxWidth &&
    css`
      width: ${maxWidth}px;
    `}
  `}
`;
