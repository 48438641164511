import { isValid } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

/**
 * Tries to convert specified zonedTime and timeZone to ISO UTC Date
 *
 * @param zonedTime Zoned Time
 * @param timeZone Time Zone
 * @returns
 *  - if zonedTime is null then empty string
 *  - if zonedTime is invalid or timeZone is empty then the received zonedTime
 *  - otherwise ISO UTC Date
 */
export function tryToGetIsoUtcDate(zonedTime: Date | null, timeZone: string | null): string | Date {
  if (!zonedTime) {
    return '';
  }

  if (!isValid(zonedTime) || !timeZone) {
    return zonedTime;
  }

  const utcDate = zonedTimeToUtc(zonedTime, timeZone);
  return utcDate.toISOString();
}

export function getZonedDate(isoUtcDate: string, timeZone: string): Date | null {
  return isoUtcDate && timeZone ? utcToZonedTime(isoUtcDate, timeZone) : null;
}
