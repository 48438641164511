import { getCurrencySymbol, Icon, numericUtil, Popover, timeUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';
import { Link } from 'react-router-dom';

import BooksClose from '../../../../common/components/format/books-close/BooksClose';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import { useFeatureToggles } from '../../../../common/config';
import { isIPOType } from '../../../../common/util/offering/offering-type.util';
import { OfferingStatusChip } from '../../../../design-system/components/data-display/offering-status-chip/OfferingStatusChip';
import { DeliveryInstrument, OfferingErrorCategory } from '../../../../graphql';
import { filingTypeLabels, useOfProceedsLabels } from '../../../../types/domain/filing/constants';
import {
  juniorRoleLabelsMappedToManagerRoles,
  managerRoleLabels,
} from '../../../../types/domain/manager/constants';
import { securityTypeLabels } from '../../../../types/domain/security/constants';
import { marketTimingLabels } from '../../../../types/domain/timing/constants';
import { exchangeEnum, sectorLabels } from '../../../constants';
import { stepIds } from '../../constants';
import { OfferingSetup_Summary_OfferingSummaryQuery } from '../../graphql';
import { getOfferingPricingCountryCode, getOfferingTypeLabel } from '../../utils/offeringTypeUtil';
import { ValidationErrorsByCategory } from '../../validation/hooks/useValidateOffering';
import Card from '../components/SummaryCard';
import Field from '../components/SummaryField';
import {
  SBubble,
  SColumns,
  SCurrenciesRow,
  SDetail,
  SDetailLabel,
  SDetails,
  SDetailValue,
  SEmpty,
  SFiling,
  SFilingFields,
  SFilingRow,
  SFilingTitle,
  SLeftColumn,
  SNoProspectusDocumentsContainer,
  SPricingCurrency,
  SProspectusContainer,
  SProspectusLinkContainer,
  SRightColumn,
  SRow,
  StyledCardSection,
  StyledIcon,
  StyledTermsCard,
} from '../OfferingSummaryRoute.styles';

export type Props = {
  offering: Omit<
    OfferingSetup_Summary_OfferingSummaryQuery['offering'],
    'lenders' | 'sponsors' | 'advisors' | 'hasUnpublishedChanges' | 'isDiscarded'
  >;
  handleEdit?: (stepId: string) => void;
  validationErrorsByCategory?: ValidationErrorsByCategory;
};

const OfferingSummaryView: React.FC<Props> = ({
  offering,
  handleEdit,
  validationErrorsByCategory,
  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const onEdit = React.useCallback(
    (stepId: string) => {
      if (handleEdit) {
        return () => handleEdit(stepId);
      } else {
        return undefined;
      }
    },
    [handleEdit]
  );

  const getValidationErrors = React.useCallback(
    (category: OfferingErrorCategory) => {
      if (validationErrorsByCategory) {
        return validationErrorsByCategory[category];
      } else {
        return undefined;
      }
    },
    [validationErrorsByCategory]
  );

  const formatInitialRegistrationValue = (initialRegistrationValue: number | undefined): string => {
    return initialRegistrationValue && initialRegistrationValue < 500000
      ? numericUtil.formatCurrency(
          initialRegistrationValue,
          undefined,
          getCurrencySymbol(offering.pricingCurrencyCode)
        )
      : numericUtil.formatCurrencyInMillions(
          initialRegistrationValue,
          undefined,
          getCurrencySymbol(offering.pricingCurrencyCode)
        );
  };

  const issuer = offering.issuer;
  const prospectusDocuments = (offering.prospectusDocuments ?? []).filter(
    doc => doc.includeOnPublish
  );
  const disclaimers = offering.disclaimers;
  const security = offering.security;
  const timing = offering.timing;
  const followOnPricing = offering.terms.followOnPricing;

  const managers = offering.syndicate.managers;
  const managerResponsibilities = offering.syndicate.managerResponsibilities;
  const { leftLead, settlementAgent, stabilizationAgent, logisticsAgent } = React.useMemo(
    () => ({
      leftLead: managers.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.leftLead
      )?.firmName,
      settlementAgent: managers.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.settlementAgent
      )?.firmName,
      stabilizationAgent: managers.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.stabilizationAgent
      )?.firmName,
      logisticsAgent: managers.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.logisticsAgent
      )?.firmName,
    }),
    [managerResponsibilities, managers]
  );

  const pricingInstrumentCountryCode = getOfferingPricingCountryCode({
    instruments: offering.instruments as DeliveryInstrument[],
    pricingInstrumentId: offering.pricingInstrumentId!,
  });

  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();
  const termsTitle = isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
    ? 'Offering Terms'
    : 'Terms';
  const termsStepId = isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
    ? stepIds.OFFERING_TERMS
    : stepIds.TERMS;

  return (
    <SColumns data-testid="offering-summary">
      <SLeftColumn>
        <Card
          title="Offering Details"
          onEdit={onEdit(stepIds.BASIC_INFO)}
          withFill
          errors={getValidationErrors(OfferingErrorCategory.Type)}
        >
          <SDetails>
            {offering.type && (
              <SDetail>
                {/* ECM-46695 - For international offering */}
                <SDetailValue>
                  {getOfferingTypeLabel({
                    offeringType: offering.type,
                    pricingInstrumentCountryCode,
                    isExecutingOnPlatform: true,
                  })}
                </SDetailValue>
                <SDetailLabel>OFFERING TYPE</SDetailLabel>
              </SDetail>
            )}
            {!isNil(offering.sizeInCurrency) && (
              <SDetail>
                <SDetailValue>
                  {numericUtil.formatCurrencyInMillions(
                    offering.sizeInCurrency,
                    undefined,
                    getCurrencySymbol(offering.pricingCurrencyCode)
                  )}
                </SDetailValue>
                <SDetailLabel>OFFERING SIZE</SDetailLabel>
              </SDetail>
            )}
            {offering.status && (
              <SDetail alignCenter>
                <SDetailValue>
                  <OfferingStatusChip status={offering.status} />
                </SDetailValue>
                <SDetailLabel>STATUS</SDetailLabel>
              </SDetail>
            )}
          </SDetails>
        </Card>
        <Card
          title="Issuer Details"
          onEdit={onEdit(stepIds.BASIC_INFO)}
          errors={getValidationErrors(OfferingErrorCategory.Issuer)}
          withFill
        >
          <Field label="Name">{issuer?.name}</Field>
          <Field label="CIK">{issuer?.cik}</Field>
          <Field label="Sector">{issuer?.sector && sectorLabels[issuer.sector]}</Field>
          <Field label="Business Description">{issuer?.businessDescription}</Field>
        </Card>
        <Card
          title="Prospectus"
          onEdit={onEdit(stepIds.PROSPECTUS)}
          errors={getValidationErrors(OfferingErrorCategory.Prospectus)}
          withFill
        >
          {prospectusDocuments.length > 0 ? (
            prospectusDocuments.map(document => (
              <SProspectusLinkContainer key={document.id}>
                {document.publicUrl ? (
                  <Link
                    to={{ pathname: document.publicUrl }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SProspectusContainer>
                      <span>{document.documentName}</span>
                      <Icon name="external-link-alt" />
                    </SProspectusContainer>
                  </Link>
                ) : (
                  <Popover
                    content="A link will be generated on publishing of the offering"
                    variant="DARK"
                    placement="right"
                  >
                    <SProspectusContainer>
                      <span>{document.documentName}</span>
                      <Icon name="info-circle" size="sm" variant="solid" />
                    </SProspectusContainer>
                  </Popover>
                )}
              </SProspectusLinkContainer>
            ))
          ) : (
            <SNoProspectusDocumentsContainer>
              No Documents to be Published
            </SNoProspectusDocumentsContainer>
          )}
        </Card>
        <Card
          title="Disclaimers"
          onEdit={onEdit(stepIds.DISCLAIMERS)}
          errors={getValidationErrors(OfferingErrorCategory.Disclaimers)}
          withFill
        >
          <Field label="Restrictions">{disclaimers?.restrictions}</Field>
          <Field label="Footnotes">{disclaimers?.footnotes}</Field>
          <Field label="Registration Links">
            {disclaimers?.registrationLinks.map((link, index) => [
              index > 0 && ', ',
              <a key={index} href={link} target="_blank" rel="noopener noreferrer">
                Registration Link {index + 1}
              </a>,
            ])}
          </Field>
          {disclaimers?.rule134Legend && (
            <Field label="Notices and Disclaimers" readOnly>
              {disclaimers?.rule134Legend}
            </Field>
          )}
        </Card>
      </SLeftColumn>
      <SRightColumn>
        <StyledCardSection>
          <FlexLayout direction="row">
            <Card title="Securities" onEdit={onEdit(stepIds.BASIC_INFO)} withBorder>
              {security?.isDepositaryReceipt && (
                <SRow>
                  <SBubble>
                    <StyledIcon name="check" /> This is a depositary receipt
                  </SBubble>
                </SRow>
              )}
              <FlexLayout direction="row" expand>
                <Field label="Type">{security?.type && securityTypeLabels[security?.type]}</Field>
                <Field label="Share Class">{security?.shareClass}</Field>
              </FlexLayout>
            </Card>
            <Card
              title="Currencies"
              onEdit={onEdit(stepIds.CURRENCIES)}
              errors={getValidationErrors(OfferingErrorCategory.DemandCurrencies)}
              withBorder
            >
              <SRow>
                <SBubble>
                  <SCurrenciesRow>
                    Default Pricing Currency
                    <SPricingCurrency>{offering.pricingCurrencyCode}</SPricingCurrency>
                  </SCurrenciesRow>
                </SBubble>
              </SRow>
              {offering.currencies.map((currency, index) => (
                <SCurrenciesRow key={index}>
                  <Field label={!index ? 'Currency Code' : undefined}>
                    {currency.currencyCode ?? '-'}
                  </Field>
                  <Field label={!index ? 'Exchange Rate' : undefined}>
                    {currency.exchangeRate ?? '-'}
                  </Field>
                </SCurrenciesRow>
              ))}
            </Card>
          </FlexLayout>
        </StyledCardSection>
        <Card
          title="Tranches"
          onEdit={onEdit(stepIds.TRANCHES)}
          errors={getValidationErrors(OfferingErrorCategory.Tranches)}
        >
          {offering.tranches.map((tranche, index) => {
            const pricingInstrument = offering.deliveryInstruments.find(
              instrument => instrument.id === tranche.defaultInstrumentId
            );

            return (
              <FlexLayout direction="row" expand key={index}>
                <Field label="Name">{tranche.name ?? '-'}</Field>
                <Field label="Region">{tranche.region ?? '-'}</Field>
                <Field label="Demand Currencies">{tranche.currencyIds.join(', ') ?? '-'}</Field>
                <Field label="Pricing Instrument">
                  {pricingInstrument
                    ? `${pricingInstrument?.countryCode} -
                      ${pricingInstrument?.countryCode} -
                      ${pricingInstrument?.stockSymbol}`
                    : '-'}
                </Field>
              </FlexLayout>
            );
          })}
        </Card>
        <Card
          title="Pricing Instruments"
          onEdit={onEdit(stepIds.INSTRUMENTS)}
          errors={getValidationErrors(OfferingErrorCategory.Instruments)}
        >
          {offering.deliveryInstruments.length === 0 && <SEmpty>No Instruments Found</SEmpty>}
          {offering.deliveryInstruments.map((instrument, index) => (
            <SRow key={index}>
              <Field label="ISIN">{instrument.isin ?? '-'}</Field>
              <Field label="CUSIP">{instrument.cusip ?? '-'}</Field>
              <Field label="FIGI">{instrument.figi ?? '-'}</Field>
              <Field label="Country">{instrument.countryCode ?? '-'}</Field>
              <Field label="Exchange">
                {instrument.exchangeMic && exchangeEnum[instrument.exchangeMic]
                  ? exchangeEnum[instrument.exchangeMic]
                  : '-'}
              </Field>
              <Field label="Currency">{instrument.currencyCode ?? '-'}</Field>
              <Field label="Symbol">{instrument.stockSymbol ?? '-'}</Field>
              <Field label="Conversion Ratio">
                {instrument.depositaryReceiptConversionRatio?.numerator ?? '-'}/
                {instrument.depositaryReceiptConversionRatio?.denominator ?? '-'}
              </Field>
            </SRow>
          ))}
        </Card>
        <Card
          title="Managers"
          onEdit={onEdit(stepIds.MANAGERS)}
          errors={[
            ...(getValidationErrors(OfferingErrorCategory.Syndicate) || []),
            ...(getValidationErrors(OfferingErrorCategory.Managers) || []),
          ]}
        >
          {managers.map((manager, index) => (
            <SRow key={index} data-testid={`manager-row-${index}`}>
              <Field label="Firm Name">{manager.firmName}</Field>
              <Field label="Manager Role">
                {manager.isJuniorRole
                  ? juniorRoleLabelsMappedToManagerRoles[manager.role]
                  : managerRoleLabels[manager.role]}
              </Field>
              <Field label="Is Participating">{manager.isParticipating ? 'Yes' : 'No'}</Field>
              <Field label="Economics (Shares)">
                {numericUtil.getDisplayValueForInteger(manager.economics)}
              </Field>
            </SRow>
          ))}
        </Card>
        <Card
          title="Manager Responsibilities"
          onEdit={onEdit(stepIds.MANAGERS)}
          errors={getValidationErrors(OfferingErrorCategory.ManagerResponsibilities) || []}
        >
          <SRow withoutBorder>
            <Field label="Left Lead">{leftLead ?? '-'}</Field>
            <Field label="Settlement Agent">{settlementAgent ?? '-'}</Field>
            <Field label="Stabilization Agent">{stabilizationAgent ?? '-'}</Field>
            <Field label="Logistics Agent">{logisticsAgent ?? '-'}</Field>
          </SRow>
        </Card>
        <Card
          title="Timing Information"
          onEdit={onEdit(stepIds.TIMING)}
          errors={getValidationErrors(OfferingErrorCategory.Timing)}
        >
          <SRow withoutBorder>
            <Field label="Confidential Filing Date">
              {timing?.confidentialFilingDate
                ? timeUtil.formatAsDisplayDate(timing?.confidentialFilingDate)
                : '-'}
            </Field>
            <Field label="Public Filing Date">
              {timing?.publicFilingDate
                ? timeUtil.formatAsDisplayDate(timing?.publicFilingDate)
                : '-'}
            </Field>
            <Field label="Filing Occurred">
              {(timing?.filingOccurred && marketTimingLabels[timing?.filingOccurred]) ?? '-'}
            </Field>
            <Field label="Launch Occurred">
              {(timing?.launchOccurred && marketTimingLabels[timing?.launchOccurred]) ?? '-'}
            </Field>
            <Field label="Launch">
              {timing?.launchDate ? timeUtil.formatAsDisplayDate(timing?.launchDate) : '-'}
            </Field>
          </SRow>
          <SRow>
            <Field label="Books Close At">
              <BooksClose booksCloseAt={timing?.booksCloseAt} timeZone={timing?.timeZone} />
            </Field>
            <Field label="Pricing Date">
              {timing?.pricingDate ? timeUtil.formatAsDisplayDate(timing?.pricingDate) : '-'}
            </Field>
            <Field label="Trade Date">
              {timing?.tradeDate ? timeUtil.formatAsDisplayDate(timing?.tradeDate) : '-'}
            </Field>
            <Field label="First Trade Date">
              {timing?.firstTradeDate ? timeUtil.formatAsDisplayDate(timing?.firstTradeDate) : '-'}
            </Field>
            <Field label="Settlement Date">
              {timing?.settlementDate ? timeUtil.formatAsDisplayDate(timing?.settlementDate) : '-'}
            </Field>
            <Field label="Withdrawn Date">
              {timing?.withdrawnDate ? timeUtil.formatAsDisplayDate(timing?.withdrawnDate) : '-'}
            </Field>
            {timing?.terminatedDate && (
              <Field label="Terminated Date">
                {timing?.terminatedDate
                  ? timeUtil.formatAsDisplayDate(timing?.terminatedDate)
                  : '-'}
              </Field>
            )}
          </SRow>
        </Card>
        <StyledTermsCard
          title={termsTitle}
          onEdit={onEdit(termsStepId)}
          errors={[
            ...(getValidationErrors(OfferingErrorCategory.FilingsAndRevisions) || []),
            ...(getValidationErrors(OfferingErrorCategory.UnderwritingTerms) || []),
            ...(getValidationErrors(OfferingErrorCategory.FollowonPricing) || []),
          ]}
        >
          <SBubble data-testid="offering-terms-section-subtitle">{termsTitle}</SBubble>
          {offering.filings.length === 0 ? <Field label={`No ${termsTitle} Found`} /> : null}
          <div>
            {offering.filings.map((filing, index) => (
              <SFiling key={index}>
                <Field label="Type">{filingTypeLabels[filing.filingType]}</Field>
                <SFilingTitle>Base Offering</SFilingTitle>
                <SFilingRow data-testid={`filling-base-offering-row-${index}`}>
                  <SFilingFields>
                    <SRow withoutBorder>
                      <Field label="Initial Registration Value">
                        {!isNil(filing.initialRegistrationValue)
                          ? formatInitialRegistrationValue(filing.initialRegistrationValue)
                          : '-'}
                      </Field>
                      <Field label="Primary Shares">
                        {numericUtil.getDisplayValueForInteger(filing.primarySharesBaseOffering)}
                      </Field>
                      <Field label="Secondary Shares">
                        {numericUtil.getDisplayValueForInteger(filing.secondarySharesBaseOffering)}
                      </Field>
                    </SRow>
                    <SRow withoutBorder>
                      <Field label="IPO Range Low">
                        {numericUtil.getDisplayValueForCurrency(
                          filing.ipoRangeLow,
                          undefined,
                          offering.pricingCurrencyCode
                        )}
                      </Field>
                      <Field label="IPO Range High">
                        {numericUtil.getDisplayValueForCurrency(
                          filing.ipoRangeHigh,
                          undefined,
                          offering.pricingCurrencyCode
                        )}
                      </Field>
                      <Field label="Offering Price">
                        {numericUtil.getDisplayValueForCurrency(
                          filing.offerPrice,
                          undefined,
                          offering.pricingCurrencyCode
                        )}
                      </Field>
                    </SRow>
                    <SRow withoutBorder>
                      <Field label="Post Offering Shares">
                        {numericUtil.getDisplayValueForInteger(filing.postOfferingShares)}
                      </Field>
                      <Field label="Lock Up Period">
                        {numericUtil.getDisplayValueForInteger(filing.lockUpPeriod)}
                      </Field>
                      <Field label="Use of Proceeds">
                        {filing.useOfProceeds && filing.useOfProceeds.length
                          ? filing.useOfProceeds.map(d => useOfProceedsLabels[d]).join(', ')
                          : '-'}
                      </Field>
                    </SRow>
                  </SFilingFields>
                </SFilingRow>
                <SFilingTitle>Over-allotment Authorized</SFilingTitle>
                <SFilingRow data-testid={`filling-over-allotment-authorized-row-${index}`}>
                  <Field label="Over-allotment Authorized">
                    {numericUtil.getDisplayValueForCurrency(
                      filing.notionalOverAllotmentAuthorized,
                      undefined,
                      offering.pricingCurrencyCode
                    )}
                  </Field>
                  <Field label="Primary Shares">
                    {numericUtil.getDisplayValueForInteger(
                      filing.primarySharesOverAllotmentAuthorized
                    )}
                  </Field>
                  <Field label="Secondary Shares">
                    {numericUtil.getDisplayValueForInteger(
                      filing.secondarySharesOverAllotmentAuthorized
                    )}
                  </Field>
                </SFilingRow>
                <SFilingTitle>Over-allotment Exercised</SFilingTitle>
                <SFilingRow data-testid={`filling-over-allotment-exercised-row-${index}`}>
                  <Field label="Over-allotment Exercised">
                    {numericUtil.getDisplayValueForCurrency(
                      filing.notionalOverAllotmentExercised,
                      undefined,
                      offering.pricingCurrencyCode
                    )}
                  </Field>
                  <Field label="Primary Shares">
                    {numericUtil.getDisplayValueForInteger(
                      filing.primarySharesOverAllotmentExercised
                    )}
                  </Field>
                  <Field label="Secondary Shares">
                    {numericUtil.getDisplayValueForInteger(
                      filing.secondarySharesOverAllotmentExercised
                    )}
                  </Field>
                </SFilingRow>
              </SFiling>
            ))}
          </div>
          {!isIPOType(offering.type) && (
            <React.Fragment>
              <SBubble>Follow-On Pricing</SBubble>
              <SRow withoutBorder data-testid="follow-on-last-trade-row">
                <Field label="Last Trade Before Filing">
                  {numericUtil.getDisplayValueForCurrency(
                    followOnPricing?.lastTradeBeforeFiling,
                    undefined,
                    offering.pricingCurrencyCode
                  )}
                </Field>
                <Field label="Last Trade Before Launch">
                  {numericUtil.getDisplayValueForCurrency(
                    followOnPricing?.lastTradeBeforeLaunch,
                    undefined,
                    offering.pricingCurrencyCode
                  )}
                </Field>
                <Field label="Last Trade Before Offer">
                  {numericUtil.getDisplayValueForCurrency(
                    followOnPricing?.lastTradeBeforeOffer,
                    undefined,
                    offering.pricingCurrencyCode
                  )}
                </Field>
              </SRow>
              <SRow withoutBorder data-testid="follow-on-re-offer-row">
                <Field label="Re-Offer Low">
                  {numericUtil.getDisplayValueForCurrency(
                    followOnPricing?.reOfferLow,
                    undefined,
                    offering.pricingCurrencyCode
                  )}
                </Field>
                <Field label="Re-Offer High">
                  {numericUtil.getDisplayValueForCurrency(
                    followOnPricing?.reOfferHigh,
                    undefined,
                    offering.pricingCurrencyCode
                  )}
                </Field>
              </SRow>
            </React.Fragment>
          )}
        </StyledTermsCard>
      </SRightColumn>
    </SColumns>
  );
};

export default OfferingSummaryView;
