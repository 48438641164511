/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_PromoteDraftAllocationSetToDefaultMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  setId: Types.Scalars['UUID'];
}>;

export type OrderBook_PromoteDraftAllocationSetToDefaultMutation = {
  readonly __typename?: 'Mutation';
  readonly promoteDraftAllocationSetToDefault: {
    readonly __typename?: 'DraftAllocationSet';
    readonly id: string;
    readonly name: string;
    readonly shareTimestamp?: string | null;
    readonly authorCmgEntityKey: string;
    readonly version: string;
  };
};

export const OrderBook_PromoteDraftAllocationSetToDefaultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_PromoteDraftAllocationSetToDefault' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoteDraftAllocationSetToDefault' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftAllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_PromoteDraftAllocationSetToDefaultMutationFn = Apollo.MutationFunction<
  OrderBook_PromoteDraftAllocationSetToDefaultMutation,
  OrderBook_PromoteDraftAllocationSetToDefaultMutationVariables
>;

/**
 * __useOrderBook_PromoteDraftAllocationSetToDefaultMutation__
 *
 * To run a mutation, you first call `useOrderBook_PromoteDraftAllocationSetToDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_PromoteDraftAllocationSetToDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookPromoteDraftAllocationSetToDefaultMutation, { data, loading, error }] = useOrderBook_PromoteDraftAllocationSetToDefaultMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      setId: // value for 'setId'
 *   },
 * });
 */
export function useOrderBook_PromoteDraftAllocationSetToDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_PromoteDraftAllocationSetToDefaultMutation,
    OrderBook_PromoteDraftAllocationSetToDefaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_PromoteDraftAllocationSetToDefaultMutation,
    OrderBook_PromoteDraftAllocationSetToDefaultMutationVariables
  >(OrderBook_PromoteDraftAllocationSetToDefaultDocument, options);
}
export type OrderBook_PromoteDraftAllocationSetToDefaultMutationHookResult = ReturnType<
  typeof useOrderBook_PromoteDraftAllocationSetToDefaultMutation
>;
export type OrderBook_PromoteDraftAllocationSetToDefaultMutationResult =
  Apollo.MutationResult<OrderBook_PromoteDraftAllocationSetToDefaultMutation>;
export type OrderBook_PromoteDraftAllocationSetToDefaultMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_PromoteDraftAllocationSetToDefaultMutation,
    OrderBook_PromoteDraftAllocationSetToDefaultMutationVariables
  >;
