import { getCurrencySymbol, numericUtil, Popover } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import {
  InstitutionalIndicationOrderType,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
} from '../../../../../../../graphql';
import { institutionalIndicationOrderTypeLabels } from '../../../../../constants';
import { InstitutionalDemandGrid_IndicationDemandsPartsFragment } from '../../graphql';
import { IndicationWithDemandLevels } from '../../types';

type InterestLevels =
  InstitutionalDemandGrid_IndicationDemandsPartsFragment['institutionalIndicationDemands'][number]['interestLevels'];

type TypeConfigurationsArgs = {
  type: InstitutionalIndicationOrderType;
  interestLevels: InterestLevels;
  demandCurrencyCode?: string | null;
  pricingCurrencyCode?: string | null;
  forGridExport?: boolean;
};

type TypeConfiguration =
  | {
      label: string;
      configurations: string | JSX.Element;
      configurationsForExport?: never;
    }
  | {
      label: string;
      configurations?: never;
      configurationsForExport: (string | React.JSX.Element)[];
    };

export const SLabel = styled.div`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 1px;
  text-decoration-color: lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const getFormattedInterestQuantity = ({ interestQuantity, interestUnit, demandCurrencyCode }) => {
  switch (interestUnit) {
    case InterestLevelInterestUnit.Percent:
      return `${numericUtil.formatPercents(interestQuantity, 2)}`;
    case InterestLevelInterestUnit.Shares:
      return `${numericUtil.formatInteger(interestQuantity)} Shares`;
    case InterestLevelInterestUnit.Currency:
      return `${numericUtil.formatCurrency(
        interestQuantity,
        2,
        getCurrencySymbol(demandCurrencyCode)
      )}`;
    default:
      return '-';
  }
};

const getFormattedInterestLimitPrice = ({ limitType, limitPrice, pricingCurrencyCode }) => {
  if (limitType === InterestLevelLimitType.Discount) {
    return `Discount ${numericUtil.formatPercents(limitPrice, 2)}`;
  } else if (limitType === InterestLevelLimitType.Premium) {
    return `Premium ${numericUtil.formatPercents(limitPrice, 2)}`;
  }
  return numericUtil.formatCurrency(limitPrice, 2, getCurrencySymbol(pricingCurrencyCode));
};

const getMarketOrderConfigurations = ({
  interestLevels,
  forGridExport,
  demandCurrencyCode,
}: TypeConfigurationsArgs): TypeConfiguration => {
  const { interestQuantity, interestUnit } = interestLevels[0];
  const formattedInterestQuantity = getFormattedInterestQuantity({
    interestQuantity,
    interestUnit,
    demandCurrencyCode,
  });

  return {
    configurations: `${formattedInterestQuantity} @ Market`,
    label: forGridExport
      ? `${formattedInterestQuantity} @ Market`
      : institutionalIndicationOrderTypeLabels[InstitutionalIndicationOrderType.Market],
  };
};

const getLimitOrderConfigurations = ({
  interestLevels,
  forGridExport,
  demandCurrencyCode,
  pricingCurrencyCode,
}: TypeConfigurationsArgs): TypeConfiguration => {
  const { interestQuantity, interestUnit, limitPrice, limitType } = interestLevels[0];
  const formattedInterestQuantity = getFormattedInterestQuantity({
    interestQuantity,
    interestUnit,
    demandCurrencyCode,
  });
  const formattedPrice = getFormattedInterestLimitPrice({
    limitPrice,
    limitType,
    pricingCurrencyCode,
  });
  return {
    configurations: `${formattedInterestQuantity} @ ${formattedPrice}`,
    label: forGridExport
      ? `${formattedInterestQuantity} @ ${formattedPrice}`
      : `${
          institutionalIndicationOrderTypeLabels[InstitutionalIndicationOrderType.Limit]
        } @ ${formattedPrice}`,
  };
};

const getScaledOrderConfigurations = ({
  interestLevels,
  forGridExport,
  demandCurrencyCode,
  pricingCurrencyCode,
}: TypeConfigurationsArgs): TypeConfiguration => {
  const copy = [...interestLevels];
  const marketInterestIndex = copy.findIndex(({ limitPrice }) => {
    return limitPrice === null;
  });
  let marketInterestElement: string | React.JSX.Element | null = null;
  if (marketInterestIndex > -1) {
    const marketInterest = copy[marketInterestIndex];
    const { interestQuantity, interestUnit } = marketInterest;
    const formattedInterestQuantity = getFormattedInterestQuantity({
      interestQuantity,
      interestUnit,
      demandCurrencyCode,
    });
    marketInterestElement = forGridExport ? (
      `${formattedInterestQuantity} @ Market`
    ) : (
      <div> {formattedInterestQuantity} @ Market</div>
    );
    copy.splice(marketInterestIndex, 1);
  }

  copy.sort((a, b) => {
    if (a.limitPrice! > b.limitPrice!) {
      return -1;
    }
    return 1;
  });
  const limitInterests: string[] = [];

  const limitInterestElements = copy.map((interestLevel, index) => {
    const { interestQuantity, interestUnit, limitPrice, limitType } = interestLevel;
    const formattedPrice =
      limitPrice === null
        ? 'Market'
        : getFormattedInterestLimitPrice({ limitPrice, limitType, pricingCurrencyCode });
    const formattedInterestQuantity = getFormattedInterestQuantity({
      interestQuantity,
      interestUnit,
      demandCurrencyCode,
    });
    if (forGridExport) {
      return limitInterests.push(`${formattedInterestQuantity} @ ${formattedPrice}`);
    } else {
      return (
        <div key={index}>
          {formattedInterestQuantity} @ {formattedPrice}
        </div>
      );
    }
  });

  const label = institutionalIndicationOrderTypeLabels[InstitutionalIndicationOrderType.Scaled];

  if (forGridExport) {
    return {
      label,
      configurationsForExport: marketInterestElement
        ? [marketInterestElement, ...limitInterests]
        : limitInterests,
    };
  } else {
    return {
      label,
      configurations: (
        <div>
          {marketInterestElement}
          {limitInterestElements}
        </div>
      ),
    };
  }
};

const defaultConfigurations = {
  cell: { label: '', configurations: '' },
  grid: { label: '', configurationsForExport: [] },
};

export const getTypeConfigurations = (props: TypeConfigurationsArgs): TypeConfiguration => {
  const defaultConfig = props.forGridExport
    ? defaultConfigurations.grid
    : defaultConfigurations.cell;

  switch (props.type) {
    case InstitutionalIndicationOrderType.Market:
      return getMarketOrderConfigurations(props);
    case InstitutionalIndicationOrderType.Limit:
      return getLimitOrderConfigurations(props);
    case InstitutionalIndicationOrderType.Scaled:
      return getScaledOrderConfigurations(props);
    default:
      return defaultConfig;
  }
};

export type Props = {
  data: IndicationWithDemandLevels;
};

const TypeCellRenderer: React.FC<Props> = ({ data }) => {
  const { configurations, label } = getTypeConfigurations({
    type: data.type,
    interestLevels: data.interestLevels,
    demandCurrencyCode: data.currencyCode,
    pricingCurrencyCode: data.pricingCurrencyCode,
  });

  const isPassed = data.type === InstitutionalIndicationOrderType.Pass;

  return (
    <React.Fragment>
      {!isPassed && (
        <Popover content={configurations} variant="DARK" placement="top">
          <SLabel> {label} </SLabel>
        </Popover>
      )}
      {isPassed && institutionalIndicationOrderTypeLabels[data.type]}
    </React.Fragment>
  );
};

export default TypeCellRenderer;
