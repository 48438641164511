import {
  OfferingSidePanel_IndicationEntityNameQueryVariables,
  useOfferingSidePanel_CoveredIndicationEntityNameQuery,
  useOfferingSidePanel_IndicationEntityNameQuery,
} from '../graphql';

export const useIndicationEntityNameQuery = ({
  variables,
  skip,
  isCoveredAccount,
}: {
  variables: OfferingSidePanel_IndicationEntityNameQueryVariables;
  skip: boolean;
  isCoveredAccount: boolean;
}) =>
  (isCoveredAccount
    ? useOfferingSidePanel_CoveredIndicationEntityNameQuery
    : useOfferingSidePanel_IndicationEntityNameQuery)({
    variables: {
      cmgEntityKey: variables.cmgEntityKey,
      offeringId: variables.offeringId!,
    },
    skip,
  });

export function getIndication(
  queryResult: ReturnType<typeof useIndicationEntityNameQuery>['data']
) {
  if (!queryResult) {
    return undefined;
  }

  const institutionalIndication =
    'institutionalIndication' in queryResult ? queryResult.institutionalIndication : undefined;
  const coveredIndication =
    'coveredIndication' in queryResult ? queryResult.coveredIndication : undefined;

  return institutionalIndication || coveredIndication;
}
