import defaults from 'lodash/defaults';
import orderBy from 'lodash/orderBy';

import type { FundItem } from '../../FundLevelDemand';
import type { FundIoisFormValues, FundIoisValues, InterestLevelFormValues } from './types';

// used to prefill interestLevels with one empty interest level when creating a new item
export const createEmptyInterestLevel = (): InterestLevelFormValues => ({
  unitType: null,
  quantity: null,
  dollars: null,
  percentage: null,
  shares: null,
  pricingType: null,
  limitPrice: null,
  realDemandDollars: null,
  realDemandPercentage: null,
  realDemandShares: null,
});

const getInterestLevels = (item: FundItem | undefined) => {
  if (item?.iois) {
    return orderBy(
      item.iois.map(({ __typename, ...ioi }) => ({
        ...defaults(ioi, createEmptyInterestLevel()),
        quantity: null,
      })),
      ['pricingType'],
      ['desc']
    );
  }

  if (!item?.fund.id) {
    return [createEmptyInterestLevel()];
  }

  return [];
};

export const mapFundItemToFormValues = (item: FundItem | undefined): FundIoisFormValues => ({
  fundId: item?.fund.id || '',
  allocation: { shares: item?.allocation.shares ?? null },
  interestLevels: getInterestLevels(item),
});

export const mapFormValuesToOutputValues = (formValues: FundIoisFormValues): FundIoisValues => ({
  ...formValues,
  interestLevels: formValues.interestLevels.map(({ quantity, ...interestLevel }) => ({
    ...interestLevel,
    unitType: interestLevel.unitType!,
    pricingType: interestLevel.pricingType!,
  })),
});
