/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_DeleteDealTeamMutationVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type Roadshows_DeleteDealTeamMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteDealTeam: string;
};

export const Roadshows_DeleteDealTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_DeleteDealTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDealTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_DeleteDealTeamMutationFn = Apollo.MutationFunction<
  Roadshows_DeleteDealTeamMutation,
  Roadshows_DeleteDealTeamMutationVariables
>;

/**
 * __useRoadshows_DeleteDealTeamMutation__
 *
 * To run a mutation, you first call `useRoadshows_DeleteDealTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DeleteDealTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsDeleteDealTeamMutation, { data, loading, error }] = useRoadshows_DeleteDealTeamMutation({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRoadshows_DeleteDealTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_DeleteDealTeamMutation,
    Roadshows_DeleteDealTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_DeleteDealTeamMutation,
    Roadshows_DeleteDealTeamMutationVariables
  >(Roadshows_DeleteDealTeamDocument, options);
}
export type Roadshows_DeleteDealTeamMutationHookResult = ReturnType<
  typeof useRoadshows_DeleteDealTeamMutation
>;
export type Roadshows_DeleteDealTeamMutationResult =
  Apollo.MutationResult<Roadshows_DeleteDealTeamMutation>;
export type Roadshows_DeleteDealTeamMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_DeleteDealTeamMutation,
  Roadshows_DeleteDealTeamMutationVariables
>;
