import { numericUtil } from '@cmg/common';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import { format } from 'date-fns';
import React from 'react';

import { OrderBook_TotalRetailSummaryItemManagerPartsFragment } from '../../../../graphql';
import { getManagerName } from '../../utils/TableRowUtils';
import { OrderBook_RetailDemandRevisionFragment } from '../graphql';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  rows: readonly OrderBook_RetailDemandRevisionFragment[];
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
}>;

const TotalRetailDemandHistoryTable: React.FC<Props> = ({ rows, allManagers }) => {
  return (
    <TableContainer>
      <Table data-testid="total-retail-history-table">
        <TableHead>
          <TableRow>
            <TableCell>Date & Time</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell align="right">Demand</TableCell>
            <TableCell align="right">Round Lots</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell size="medium">
                {format(new Date(row.modifiedAt), 'MM/dd/yyyy HH:mm a')}
              </TableCell>
              <TableCell size="medium">
                {getManagerName(allManagers, row.modifiedByFirmKey)}
              </TableCell>
              <TableCell size="medium" align="right">
                {getDisplayValueForInteger(row.shareQuantity)}
              </TableCell>
              <TableCell size="medium" align="right">
                {getDisplayValueForInteger(row.roundLots)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TotalRetailDemandHistoryTable;
