import { useFeatureToggles } from '../../../../../../common/config';
import usePollingWhenVisible from '../../../../../../common/hooks/usePollingWhenVisible';
import { useWithOrderBook_AttestationStatusChangedLazySubscription } from '../../../../../../features/order-book/graphql/__generated__';
import {
  InstitutionalDemandGrid_AttestationStatusesQueryVariables,
  useInstitutionalDemandGrid_AttestationStatusesLazyQuery,
} from '../graphql';

export const DEMAND_SUMMARY_GRID_ATTESTATION_STATUSES_POLL_INTERVAL_MS = 5_000;

export const useAttestationStatusesLazyQuery = ({
  cmgEntityKeys,
}: InstitutionalDemandGrid_AttestationStatusesQueryVariables) => {
  const { isOrderBookDataRefreshOn, isOrderBookInstitutionalDemandGridSubscriptionOn } =
    useFeatureToggles();

  const pollInterval =
    isOrderBookDataRefreshOn && !isOrderBookInstitutionalDemandGridSubscriptionOn
      ? DEMAND_SUMMARY_GRID_ATTESTATION_STATUSES_POLL_INTERVAL_MS
      : 0;

  let lazyQueryTuple = useInstitutionalDemandGrid_AttestationStatusesLazyQuery({
    fetchPolicy: isOrderBookInstitutionalDemandGridSubscriptionOn
      ? 'cache-and-network'
      : 'no-cache',
  });

  lazyQueryTuple = useWithOrderBook_AttestationStatusChangedLazySubscription(lazyQueryTuple, {
    variables: () => ({
      cmgEntityKeys,
    }),
    isEnabled: isOrderBookInstitutionalDemandGridSubscriptionOn,
    updateQuery:
      () =>
      (prev, { subscriptionData: { data } }) => {
        const { attestationStatuses } = prev;
        const {
          attestationStatusChanged: { firmId, cmgEntityKey, status },
        } = data;

        return {
          attestationStatuses: attestationStatuses.map(attestationStatus =>
            attestationStatus.firmId === firmId && attestationStatus.cmgEntityKey === cmgEntityKey
              ? { ...attestationStatus, status }
              : attestationStatus
          ),
        };
      },
  });

  const [, { called, stopPolling, startPolling }] = lazyQueryTuple;
  usePollingWhenVisible({
    pollInterval,
    called,
    stopPolling,
    startPolling,
    isEnabled: !isOrderBookInstitutionalDemandGridSubscriptionOn,
  });

  return lazyQueryTuple;
};
