import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const SBoxWrapper = styled.div`
  padding: 8px 8px 6px 8px;
  background-color: ${({ theme }) => theme.foundation.colors.info['000']};
  width: 100%;
  height: min-content;
  flex: 1;
`;

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.text.size.tiny};
  text-align: right;
  color: ${({ theme }) => theme.text.color.link};
`;
