import { useAuth } from '@cmg/auth';
import React from 'react';

import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../../../../common/components/overlays/spinner/Spinner';
import { SecurityType } from '../../../../../../graphql';
import SectionHeader from '../../../../components/section-header/SectionHeader';
import isManagerRelationshipError from '../../../../utils/isManagerRelationshipError';
import { useUpdateHistory_MyIndicationVersionsQuery } from './graphql';
import { IndicationUpdateHistoryList } from './IndicationUpdateHistoryList';

export type Props = {
  offeringId: string;
  pricingCurrencyCode?: string | null;
  securityType?: SecurityType;
};

const BuySideUpdateHistory: React.FC<Props> = ({
  offeringId,
  pricingCurrencyCode,
  securityType,
}) => {
  const myCmgEntityKey = useAuth().oidcUserCmgEntityKey;
  const { data, loading, error } = useUpdateHistory_MyIndicationVersionsQuery({
    variables: {
      offeringId: offeringId,
      myCmgEntityKey: myCmgEntityKey!,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  // We want to hide the error banner when the error is a manager relationship
  const shouldHideErrorsBanner = error && isManagerRelationshipError(error);

  return (
    <div>
      <SectionHeader title="History" />
      {loading && <Spinner show={true} fullHeight={true} />}
      {!loading && error && !shouldHideErrorsBanner && <ServerErrorsBanner error={error} />}
      {!loading && data && (
        <IndicationUpdateHistoryList
          indicationVersions={data?.myIndicationVersions!}
          pricingCurrencyCode={pricingCurrencyCode}
          securityType={securityType}
        />
      )}
    </div>
  );
};

export default BuySideUpdateHistory;
