import groupBy from 'lodash/groupBy';
import React from 'react';

import { OfferingErrorCategory } from '../../../../graphql';
import {
  OfferingSetup_Validation_OfferingErrorFragment,
  useOfferingSetup_Validation_OfferingValidationQuery,
} from '../../graphql';
import { useShouldValidate } from '../OfferingSetupValidationProvider';

export type ValidationErrorsByCategory = {
  [key in OfferingErrorCategory]?: OfferingSetup_Validation_OfferingErrorFragment[];
};

export const useValidateOffering = (offeringId?: string) => {
  // shouldValidate is boolean provided to all routes in offering setup
  // TRUE only when a user has clicked Ready for Publish on Offering Summary
  // all subsequent screens will then validate & show errors
  const { shouldValidate, setShouldValidate } = useShouldValidate();

  // if shouldValidate=true, fetch validation for the offering
  const {
    data,
    refetch,
    loading: validating,
  } = useOfferingSetup_Validation_OfferingValidationQuery({
    skip: !offeringId || !shouldValidate,
    variables: { offeringId: offeringId! },
  });

  const validationErrors = data?.offering.validation.errors;

  // group errors by their category enum
  const validationErrorsByCategory: ValidationErrorsByCategory = React.useMemo(
    () => groupBy(validationErrors, 'category'),
    [validationErrors]
  );

  return {
    shouldValidate,
    setShouldValidate,
    validate: async () => {
      const result = await refetch();
      return result?.data?.offering?.validation?.errors;
    },
    validating,
    revalidate: () => shouldValidate && refetch(),
    validationErrors,
    validationErrorsByCategory,
  };
};
