import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { useIndicationActivitySalesAndTradingContext } from '../../../../context/IndicationActivitySalesAndTradingContext';
import { useAcknowledgeCoveredIndicationMutation } from '../../../indication-form/components/indication-details/components/acknowledgement/hooks/useAcknowledgeCoveredIndicationMutation';
import { useUnacknowledgeCoveredIndicationMutation } from '../../../indication-form/components/indication-details/components/acknowledgement/hooks/useUnacknowledgeCoveredIndicationMutation';
import IndicationAcknowledgementBannerComponent from '../../common/indication-acknowledgement-banner/IndicationAcknowledgementBanner';

export type Props = Readonly<{ acknowledgeButtonDisabledNoContacts: boolean }>;

const IndicationAcknowledgementBanner: React.FC<Props> = ({
  acknowledgeButtonDisabledNoContacts,
}) => {
  const { offeringId, coveredIndication, basicPublishedOffering } =
    useIndicationActivitySalesAndTradingContext();

  const [acknowledgeCoveredIndication, { loading: acknowledgeCoveredIndicationLoading }] =
    useAcknowledgeCoveredIndicationMutation({
      offeringId,
      indicationId: coveredIndication!.id,
      version: coveredIndication!.version,
    });

  const [unacknowledgeCoveredIndication, { loading: useUnacknowledgeCoveredIndicationLoading }] =
    useUnacknowledgeCoveredIndicationMutation({
      offeringId,
      indicationId: coveredIndication!.id,
      version: coveredIndication!.version,
    });

  const handleAcknowledge = React.useCallback(async () => {
    try {
      await acknowledgeCoveredIndication();

      SnackbarManager.success('Successfully acknowledged indication');
    } catch {
      SnackbarManager.error('Failed to acknowledge indication');
    }
  }, [acknowledgeCoveredIndication]);
  const handleUnacknowledge = React.useCallback(async () => {
    try {
      await unacknowledgeCoveredIndication();

      SnackbarManager.success('Successfully unacknowledged indication');
    } catch {
      SnackbarManager.error('Failed to unacknowledge indication');
    }
  }, [unacknowledgeCoveredIndication]);

  return (
    <IndicationAcknowledgementBannerComponent
      acknowledgeButtonDisabledNoContacts={acknowledgeButtonDisabledNoContacts}
      onAcknowledge={handleAcknowledge}
      isAcknowledging={acknowledgeCoveredIndicationLoading}
      onUnacknowledge={handleUnacknowledge}
      isUnacknowledging={useUnacknowledgeCoveredIndicationLoading}
      acknowledgements={coveredIndication!.institutionalIndicationAcknowledgements}
      indicationVersion={coveredIndication!.version}
      offeringType={basicPublishedOffering!.type}
      indicationModifiedByFirmKey={coveredIndication!.auditInfo.modifiedByFirmKey}
    />
  );
};

export default IndicationAcknowledgementBanner;
