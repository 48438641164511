import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_PaymentWiresDocument,
  SyndicateWires_SendPaymentWireMutation,
  useSyndicateWires_SendPaymentWireMutation,
} from '../graphql';

export type SendPaymentWireMutation = SyndicateWires_SendPaymentWireMutation;

export const useSendPaymentWireMutation = () => {
  return useSyndicateWires_SendPaymentWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PaymentWiresDocument];
  return getManyOperationNames(documentNodes);
}
