import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteSellingGroupInvitationWireMutation,
  SyndicateWires_SellingGroupInvitationWiresDocument,
  useSyndicateWires_DeleteSellingGroupInvitationWireMutation,
} from '../graphql';

export type DeleteSellingGroupInvitationWireMutation =
  SyndicateWires_DeleteSellingGroupInvitationWireMutation;

export const useDeleteSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_DeleteSellingGroupInvitationWireMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
