import React from 'react';

import {
  ColumnWidthsType,
  PinnedColumnsType,
  RowSortType,
  useInstitutionalDemandPreferences,
} from '../preferences/useInstitutionalDemandPreferences';
import { InstitutionalDemandFilterArgs } from '../types';

export type InstitutionalDemandPreferencesContextType = {
  pinnedColumns: PinnedColumnsType;
  setPinnedColumns: (value: PinnedColumnsType) => void;
  columnsOrder: string[];
  setColumnsOrder: (value: string[]) => void;
  rowSortOrder: RowSortType;
  setRowSortOrder: (value: RowSortType) => void;
  filters: InstitutionalDemandFilterArgs;
  setFilters: (value: InstitutionalDemandFilterArgs) => void;
  columnWidths: ColumnWidthsType;
  setColumnWidths: (value: ColumnWidthsType) => void;
  visibleColumns: string[];
  setVisibleColumns: (value: string[]) => void;
  metricsVisible: boolean;
  setMetricsVisible: (value: boolean) => void;
};

const InstitutionalDemandPreferencesContext =
  React.createContext<InstitutionalDemandPreferencesContextType>({
    pinnedColumns: {
      right: [],
      left: [],
    },
    setPinnedColumns: () => {},
    columnsOrder: [],
    setColumnsOrder: () => {},
    rowSortOrder: [
      {
        colId: undefined,
        sort: null,
        sortIndex: null,
      },
    ],
    setRowSortOrder: () => {},
    filters: {},
    setFilters: () => {},
    columnWidths: undefined,
    setColumnWidths: () => {},
    visibleColumns: [],
    setVisibleColumns: () => {},
    metricsVisible: true,
    setMetricsVisible: () => {},
  });

export const useInstitutionalDemandPreferencesContext = () => {
  const context = React.useContext(InstitutionalDemandPreferencesContext);

  if (context === undefined) {
    throw new Error('No Provider found for InstitutionalDemandPreferencesContext');
  }

  return context;
};

export const InstitutionalDemandPreferencesProvider: React.FC<{ offeringId: string }> = ({
  children,
  offeringId,
}) => {
  const {
    pinnedColumns,
    setPinnedColumns,
    columnsOrder,
    setColumnsOrder,
    rowSortOrder,
    setRowSortOrder,
    filters,
    setFilters,
    columnWidths,
    setColumnWidths,
    visibleColumns,
    setVisibleColumns,
    metricsVisible,
    setMetricsVisible,
  } = useInstitutionalDemandPreferences(offeringId);

  return (
    <InstitutionalDemandPreferencesContext.Provider
      value={{
        pinnedColumns,
        setPinnedColumns,
        columnsOrder,
        setColumnsOrder,
        rowSortOrder,
        setRowSortOrder,
        filters,
        setFilters,
        columnWidths,
        setColumnWidths,
        visibleColumns,
        setVisibleColumns,
        metricsVisible,
        setMetricsVisible,
      }}
    >
      {children}
    </InstitutionalDemandPreferencesContext.Provider>
  );
};
