import isEqual from 'lodash/isEqual';
import React from 'react';

import { ColDefCalendarGrid } from '../calendar.model';
import CalendarColumnHeader from '../components/calendar-column-header/CalendarColumnHeader';
import {
  calendarGridColumns,
  CalendarGridOrderField,
  commonColumnDefinition,
} from '../components/calendar-grid/CalendarGridColumns';
import { getInternationalString } from '../components/calendar-grid/CalendarGridColumns.model';
import { useInternationalColumns } from '../components/calendar-grid/hooks/useInternationalColumns';
import { booksCloseAtValueGetter } from '../components/calendar-grid/value-getters/BooksCloseAtValueGetter';
import TodayTag from '../components/TodayTag';
import BuySideDemandCellRenderer from './components/BuySideDemandCellRenderer';
import { Calendar_LiveCalendarOfferingsListQuery } from './graphql';

enum LiveCalendarSecondarySources {
  DEMAND = 'demand',
  BOOKS_CLOSE = 'booksCloseAt',
}

// We need a little workaround to be able to use CalendarGridOffering fields here.
const calendarGridColumnsTypeFix = calendarGridColumns as {
  [key in keyof typeof calendarGridColumns]: ColDefCalendarGrid<
    Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
  >;
};

export const useBuySideColumns = (): ColDefCalendarGrid<
  Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
>[] => {
  const {
    regionAndCountryColumns,
    sizeInCurrencyColumn,
    priceRangeColumn,
    pricingDateColumn,
    hasInternational,
  } =
    useInternationalColumns<
      Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
    >();

  return [
    calendarGridColumnsTypeFix[CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM],
    {
      ...calendarGridColumnsTypeFix[CalendarGridOrderField.ISSUER_NAME],
      /**
       * cellRendererParams provides params to the cellRendererFramework while honoring
       * the reactContainer style overrides in the IssuerNameRenderer
       */
      cellRendererParams: ({ data }) => ({
        children: <TodayTag launchDate={data.launchDate} />,
      }),
    },
    calendarGridColumnsTypeFix[CalendarGridOrderField.SYMBOL],
    ...regionAndCountryColumns,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECTOR],
    calendarGridColumnsTypeFix[CalendarGridOrderField.TYPE],
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECURITY_TYPE],
    pricingDateColumn,
    sizeInCurrencyColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SIZE_IN_SHARES],
    priceRangeColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.LEFT_LEAD],
    // secondary sources
    {
      ...commonColumnDefinition,
      minWidth: 210,
      maxWidth: 400,
      suppressSizeToFit: true,
      colId: LiveCalendarSecondarySources.DEMAND,
      headerName: 'Demand',
      headerComponentFramework: () => <CalendarColumnHeader title="Demand" />,
      cellRendererFramework: ({ data }) => (
        <BuySideDemandCellRenderer
          myInstitutionalIndication={data.myInstitutionalIndication}
          mdlIndications={{
            indications: [],
            ...data.mdlIndications,
            ioiType: data.mdlAllocation?.allocation?.ioiType, // Send ioiType (PASS, REG-M, NORMAL) retrieved from the allocation endpoint
          }}
          pricingCurrencyCode={data.pricingCurrencyCode}
        />
      ),
      // re-render the cell when myInstitutionalIndication & mdlIndications changes
      equals: (dataA, dataB) =>
        dataA &&
        dataB &&
        isEqual(dataA.myInstitutionalIndication, dataB.myInstitutionalIndication) &&
        isEqual(dataA.mdlIndications, dataB.mdlIndications),
    },
    {
      ...commonColumnDefinition,
      minWidth: 160,
      maxWidth: 300,
      suppressSizeToFit: true,
      colId: LiveCalendarSecondarySources.BOOKS_CLOSE,
      field: LiveCalendarSecondarySources.BOOKS_CLOSE,
      headerName: getInternationalString(hasInternational, 'Books Close ⓘ', 'Books Close'),
      headerTooltip: getInternationalString(
        hasInternational,
        "Books close date is presented in exchange country's local time zone"
      ),
      valueGetter: booksCloseAtValueGetter,
    },
  ];
};
