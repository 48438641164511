import { useSearchParams } from '@cmg/common';
import { useCallback } from 'react';

import { AttestationFormStatus, FirmCertificateSource } from '../../../../graphql/__generated__';
import {
  certificateExpiresArray,
  CertificateExpiresValue,
} from '../../components/certificate-filters/certificate-filters.model';

const getValidExpirationDays = (
  expirationDays: string | null,
  setExpirationDays: (expirationDays: CertificateExpiresValue | null) => void
): CertificateExpiresValue | null => {
  if (!expirationDays) return null;

  return (
    (certificateExpiresArray.includes(expirationDays as CertificateExpiresValue)
      ? (expirationDays as CertificateExpiresValue)
      : setExpirationDays(null)) ?? null
  );
};

const getValidCertificateStatuses = (
  certificateStatusString: string | null,
  setCertificateStatus: (certificateStatus: AttestationFormStatus[] | null) => void
): AttestationFormStatus[] | null => {
  if (!certificateStatusString) return [];

  const certificateStatusesKeys = certificateStatusString.split(',');

  return certificateStatusesKeys.filter(status =>
    Object.values(AttestationFormStatus).includes(status as AttestationFormStatus)
  ) as AttestationFormStatus[];
};

const getValidCertificateLibrary = (
  certificateLibrary: string | null,
  setCertificateLibrary: (certificateLibrary: FirmCertificateSource | null) => void
): FirmCertificateSource | null => {
  if (!certificateLibrary) {
    setCertificateLibrary(FirmCertificateSource.Crm);
    return null;
  }

  return (
    (Object.values(FirmCertificateSource).includes(certificateLibrary as FirmCertificateSource)
      ? (certificateLibrary as FirmCertificateSource)
      : setCertificateLibrary(FirmCertificateSource.Crm)) ?? null
  );
};

const useCertificateFilters = () => {
  const { searchParams, setSearchParams } = useSearchParams();

  const updateQueryParams = useCallback(
    <T>(params: Record<string, T | null>) => {
      const newParams = new URLSearchParams(searchParams);

      Object.entries(params).forEach(([key, value]) => {
        if (value !== null) {
          newParams.set(key, String(value));
        } else {
          newParams.delete(key);
        }
      });

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const setSearch = (search: string | null) => {
    updateQueryParams({ search });
  };

  const setExpirationDays = (expirationDays: CertificateExpiresValue | null) => {
    updateQueryParams({ expirationDays });
  };

  const setCertificateStatuses = (certificateStatuses: AttestationFormStatus[] | null) => {
    updateQueryParams({ certificateStatuses: (certificateStatuses ?? []).join(',') });
  };

  const setCertificateLibrary = (
    certificateLibrary: FirmCertificateSource | null,
    statuses?: AttestationFormStatus[] | null
  ) => {
    if (certificateLibrary === FirmCertificateSource.Cmg && statuses) {
      return updateQueryParams({
        certificateLibrary,
        certificateStatuses: (statuses ?? [])
          .filter(status => status !== AttestationFormStatus.NotOnFile) // NoOnFile is not supported in CMG Library
          .join(','),
      });
    }

    updateQueryParams({ certificateLibrary });
  };

  const expirationDays = getValidExpirationDays(
    searchParams.get('expirationDays'),
    setExpirationDays
  );
  const certificateStatuses = getValidCertificateStatuses(
    searchParams.get('certificateStatuses'),
    setCertificateStatuses
  );
  const certificateLibrary = getValidCertificateLibrary(
    searchParams.get('certificateLibrary'),
    setCertificateLibrary
  );

  return {
    setSearch,
    setExpirationDays,
    setCertificateStatuses,
    setCertificateLibrary,
    search: searchParams.get('search'),
    expirationDays,
    certificateStatuses,
    certificateLibrary,
  };
};

export default useCertificateFilters;
