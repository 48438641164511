import {
  ButtonsContainer,
  FlexContainer,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import { useInstitutionalDemandPreferencesContext } from '../../context/InstitutionalDemandPreferencesContext';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../../graphql';
import { PinnedColumnsType } from '../../preferences/useInstitutionalDemandPreferences';
import { extendedTypesDisplayName, InstitutionalDemandColumnConfig } from '../../types';
import {
  useGetMenuOptions,
  validationSchema,
} from '../column-dropdown-menu/InstitutionalDemandGridColumnMenu.model';
import {
  MenuHeaderOption,
  ModalBodyContainer,
  SCMGDemandColumnTitle,
  SDemandSeparatorTitle,
  SHeaderInfo,
  SOptionContainer,
  StyledNumericInputField,
  StyledReferencePriceInput,
  TitleContainer,
} from './InstitutionalDemandMenuModal.styles';

type OwnProps = {
  isFinalAllocationSetReleased: boolean;
  finalAllocationExists: boolean;
  offeringId: string;
  activeDemandColumnConfig: InstitutionalDemandColumnConfig;
  onDemandColumnConfigChange: (values: InstitutionalDemandColumnConfig) => void;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  pricingCurrencyCode?: string;
};

export type Props = OwnProps & InjectedProps;

export const InstitutionalDemandMenuModal: React.FC<Props> = ({
  isFinalAllocationSetReleased,
  finalAllocationExists,
  offeringId,
  activeDemandColumnConfig,
  handleHide,
  onDemandColumnConfigChange,
  investorsExtendedData,
  pricingCurrencyCode,
}) => {
  const formik = useFormik<InstitutionalDemandColumnConfig>({
    initialValues: activeDemandColumnConfig,
    validationSchema,
    onSubmit: values => onDemandColumnConfigChange(values),
  });
  const { handleSubmit } = formik;

  const preferencesContext = useInstitutionalDemandPreferencesContext();
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>(
    preferencesContext.visibleColumns
  );
  const [pinnedColumns, setPinnedColumns] = React.useState<PinnedColumnsType>(
    preferencesContext.pinnedColumns
  );

  const { cmg, finalAllocations, demands, compliance, notes, dynamicColumns } = useGetMenuOptions({
    isFinalAllocationSetReleased,
    finalAllocationExists,
    offeringId,
    visibleColumns,
    investorsExtendedData,
    setVisibleColumns,
    height: '30px',
    pinnedColumns,
    setPinnedColumns,
    displayPinIcon: false,
  });

  const handleApplySelection = React.useCallback(() => {
    preferencesContext.setVisibleColumns(visibleColumns);
    preferencesContext.setPinnedColumns(pinnedColumns);
    handleSubmit();
    handleHide();
  }, [preferencesContext, visibleColumns, pinnedColumns, handleSubmit, handleHide]);

  const dynamicKeys = Object.keys(dynamicColumns);
  const generateDynamicMenuColumn = (type: string) => (
    <React.Fragment key={type}>
      <MenuHeaderOption>{extendedTypesDisplayName[type]}</MenuHeaderOption>
      {dynamicColumns[type]}
    </React.Fragment>
  );
  const defaultColumns = [...cmg, finalAllocations];

  return (
    <Modal
      show
      title={<TitleContainer>Manage Columns</TitleContainer>}
      onHide={handleHide}
      closeButton
      shouldCloseOnOverlayClick
      size="small"
      footer={
        <FlexContainer direction="row" justifyContent="end">
          <ButtonsContainer>
            <SecondaryButton onClick={handleHide}>Cancel</SecondaryButton>
            <PrimaryButton onClick={handleApplySelection}>Save</PrimaryButton>
          </ButtonsContainer>
        </FlexContainer>
      }
    >
      <ModalBodyContainer>
        <FormikProvider value={formik}>
          <SHeaderInfo>
            Please select up to 18 columns you would like to see in your Order book.
          </SHeaderInfo>
          <SCMGDemandColumnTitle>Demand Columns</SCMGDemandColumnTitle>
          <SOptionContainer fractions={3}>
            <div>{demands.map((o, index) => index % 2 === 0 && o)}</div>
            <div>{demands.map((o, index) => index % 2 === 1 && o)}</div>
          </SOptionContainer>
          <FlexContainer direction="row">
            <StyledNumericInputField name="min" label="Low" aria-label="Low" />
            <StyledNumericInputField name="max" label="High" aria-label="High" />
            <StyledNumericInputField name="increment" label="Increment" aria-label="Increment" />
            <StyledReferencePriceInput
              aria-label="Reference Price"
              label="Reference Price"
              name="referencePrice"
              precision={2}
              currencyCode={pricingCurrencyCode}
            />
          </FlexContainer>
          <SDemandSeparatorTitle>Default Columns</SDemandSeparatorTitle>
          <SOptionContainer fractions={3}>
            <div>{defaultColumns.map((o, index) => index % 2 === 0 && o)}</div>
            <div>{defaultColumns.map((o, index) => index % 2 === 1 && o)}</div>
            <div>
              <MenuHeaderOption>Compliance</MenuHeaderOption>
              {compliance}
              <MenuHeaderOption>Other</MenuHeaderOption>
              {notes}
            </div>
          </SOptionContainer>
          {dynamicKeys.length > 0 && (
            <React.Fragment>
              <SDemandSeparatorTitle>Additional Columns</SDemandSeparatorTitle>
              <SOptionContainer fractions={3}>
                <div>
                  {dynamicKeys.map(
                    (type, index) => index % 3 === 0 && generateDynamicMenuColumn(type)
                  )}
                </div>
                <div>
                  {dynamicKeys.map(
                    (type, index) => index % 3 === 1 && generateDynamicMenuColumn(type)
                  )}
                </div>
                <div>
                  {dynamicKeys.map(
                    (type, index) => index % 3 === 2 && generateDynamicMenuColumn(type)
                  )}
                </div>
              </SOptionContainer>
            </React.Fragment>
          )}
        </FormikProvider>
      </ModalBodyContainer>
    </Modal>
  );
};

const name = 'ORDERBOOK/DEMAND_GRID_VISIBLE_COLUMNS_MENU_MODAL';
export const openInstitutionalDemandMenuModal = () => show(name);
export const closeInstitutionalDemandMenuModal = () => hide(name);

export default connectModal({ name })(
  InstitutionalDemandMenuModal
) as React.ComponentClass<OwnProps>;
