/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Calendar_PostponedCalendarOfferingsListQueryVariables = Types.Exact<{
  startPostponedDate: Types.Scalars['Date'];
  endPostponedDate: Types.Scalars['Date'];
  includeTestOfferings: Types.Scalars['Boolean'];
}>;

export type Calendar_PostponedCalendarOfferingsListQuery = {
  readonly __typename?: 'Query';
  readonly postponedPublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly issuerName: string;
      readonly finalFilingOfferPrice?: number | null;
      readonly securityType: Types.SecurityType;
      readonly launchDate?: string | null;
      readonly leftLead?: string | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly pricingDate?: string | null;
      readonly issuerSector?: Types.Sector | null;
      readonly publicFilingDate?: string | null;
      readonly postponedDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly isExecutingOnPlatform: boolean;
      readonly leftLeadFirmName?: string | null;
      readonly revisedFilingsCount?: number | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly pricingInstrumentCountryCode?: string | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentRegion?: string | null;
      readonly pricingInstrumentCountryDisplayName?: string | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerLimited';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly role: Types.ManagerRole;
        readonly firmNameAbbreviation?: string | null;
      }>;
    }>;
  };
};

export const Calendar_PostponedCalendarOfferingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Calendar_PostponedCalendarOfferingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startPostponedDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endPostponedDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTestOfferings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postponedPublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startPostponedDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startPostponedDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endPostponedDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endPostponedDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeTestOfferings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeTestOfferings' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_CalendarGrid_OfferingLimitedParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_CalendarGrid_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLeadFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revisedFilingsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentRegion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryDisplayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCalendar_PostponedCalendarOfferingsListQuery__
 *
 * To run a query within a React component, call `useCalendar_PostponedCalendarOfferingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendar_PostponedCalendarOfferingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendar_PostponedCalendarOfferingsListQuery({
 *   variables: {
 *      startPostponedDate: // value for 'startPostponedDate'
 *      endPostponedDate: // value for 'endPostponedDate'
 *      includeTestOfferings: // value for 'includeTestOfferings'
 *   },
 * });
 */
export function useCalendar_PostponedCalendarOfferingsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calendar_PostponedCalendarOfferingsListQuery,
    Calendar_PostponedCalendarOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Calendar_PostponedCalendarOfferingsListQuery,
    Calendar_PostponedCalendarOfferingsListQueryVariables
  >(Calendar_PostponedCalendarOfferingsListDocument, options);
}
export function useCalendar_PostponedCalendarOfferingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calendar_PostponedCalendarOfferingsListQuery,
    Calendar_PostponedCalendarOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Calendar_PostponedCalendarOfferingsListQuery,
    Calendar_PostponedCalendarOfferingsListQueryVariables
  >(Calendar_PostponedCalendarOfferingsListDocument, options);
}
export type Calendar_PostponedCalendarOfferingsListQueryHookResult = ReturnType<
  typeof useCalendar_PostponedCalendarOfferingsListQuery
>;
export type Calendar_PostponedCalendarOfferingsListLazyQueryHookResult = ReturnType<
  typeof useCalendar_PostponedCalendarOfferingsListLazyQuery
>;
export type Calendar_PostponedCalendarOfferingsListQueryResult = Apollo.QueryResult<
  Calendar_PostponedCalendarOfferingsListQuery,
  Calendar_PostponedCalendarOfferingsListQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeCalendar_PostponedCalendarOfferingsListMockResponse = makeMockResponse<
  Calendar_PostponedCalendarOfferingsListQueryVariables,
  Calendar_PostponedCalendarOfferingsListQuery
>(Calendar_PostponedCalendarOfferingsListDocument);
