import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';

export function useCheckProspectusOnSendValidationPermissions(
  isSyndicatePersona: boolean
): boolean {
  const canReadInstitutionalIndication = useCheckPermissions([
    permissionsByEntity.InstitutionalIndication.READ,
  ]);

  const canReadCoveredAccountsInstitutionalIndication = useCheckPermissions([
    permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
  ]);

  return isSyndicatePersona
    ? canReadInstitutionalIndication
    : canReadCoveredAccountsInstitutionalIndication;
}
