import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import { useIsUserSettlementAgent } from '../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage, OfferingStatus } from '../../../../../graphql';

type Props = Readonly<{
  offeringId: UUID | undefined;
  offeringStatus: OfferingStatus | undefined;
  offeringStage: OfferingStage | undefined;
  isAuthor: boolean;
}>;

export const useFinalSettlementRoute = ({
  offeringId,
  offeringStatus,
  offeringStage,
  isAuthor,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const canAccessAnySubmoduleInFS = useCheckPermissions(
    [
      permissionsByEntity.Designation.READ,
      permissionsByEntity.Expense.READ,
      permissionsByEntity.Stabilization.READ,
      permissionsByEntity.Accounting.READ,
      permissionsByEntity.Letter.READ,
    ],
    false
  );
  const { isSettlementAgent } = useIsUserSettlementAgent({ offeringId });

  const isPublished = offeringStage === OfferingStage.Published;
  const isPricedTerminatedWithdrawn = [
    OfferingStatus.Priced,
    OfferingStatus.Withdrawn,
    OfferingStatus.Terminated,
  ].includes(offeringStatus!);

  if (!canAccessAnySubmoduleInFS) {
    return undefined;
  }

  if (!offeringId) {
    return undefined;
  }

  if (!isPublished) {
    return undefined;
  }

  if (!isPricedTerminatedWithdrawn) {
    return undefined;
  }

  if (!isAuthor && !isSettlementAgent) {
    return undefined;
  }

  return {
    href: routeFactory.finalSettlement.getUrlPath({ offeringId }),
    label: 'Final Settlement',
  };
};
