import React from 'react';

import {
  AllocationAcknowledgement,
  IndicationStatus,
  InstitutionalIndicationOrderType,
} from '../../../../../../../../graphql';
import { isIndicationStatusAndTypeValid } from '../../../../../../../order-book/utils';
import { AllocationAcknowledgementStatusLabel } from './AllocationAcknowledgementStatus.model';
import {
  SLabel,
  StyledStatusIndicatorIcon,
  SWrapper,
} from './AllocationAcknowledgementStatus.styles';

export type Props = Readonly<{
  isReleased: boolean;
  investorReplyStatus: AllocationAcknowledgement | null | undefined;
  indicationType: InstitutionalIndicationOrderType | null | undefined;
  indicationStatus: IndicationStatus | null | undefined;
}>;

export const AllocationAcknowledgementStatus: React.FC<Props> = ({
  isReleased,
  investorReplyStatus,
  indicationStatus,
  indicationType,
}) => {
  const isIndicationValid =
    indicationType &&
    indicationStatus &&
    isIndicationStatusAndTypeValid(indicationStatus, indicationType);

  const statusLabel =
    isReleased && investorReplyStatus
      ? AllocationAcknowledgementStatusLabel[investorReplyStatus]
      : null;

  if (!isReleased || !investorReplyStatus || !isIndicationValid) {
    return null;
  }

  return (
    <SWrapper>
      <StyledStatusIndicatorIcon
        name="circle"
        variant="solid"
        size="sm"
        fixedWidth
        status={investorReplyStatus}
      />
      <SLabel>{statusLabel}</SLabel>
    </SWrapper>
  );
};
