import { UUID } from '@cmg/common';
import { CheckCircleIcon, Stack } from '@cmg/design-system';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { EmptyState } from '../../../../design-system/components/data-display/empty-state/EmptyState';

export type Props = { offeringId: UUID };

const DesignationMonitorEmptyScreen: React.FC<Props> = ({ offeringId }) => (
  <EmptyState title="Designation Monitor requires additional actions">
    <Stack flexDirection="row" justifyContent="center" gap={1}>
      <CheckCircleIcon fontSize="small" color="disabled" />
      <Link to={routeFactory.orderBook.getUrlPath({ offeringId })}>
        Release Final Allocations in Order Book
      </Link>
    </Stack>
  </EmptyState>
);

export default DesignationMonitorEmptyScreen;
