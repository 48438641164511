import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { StyledTable } from './PotAvailableInfo.styles';

export type Props = {
  totalDealShares: number | null;
  retailRetention: number | null | undefined;
  potAvailable: number | null;
};

const PotAvailableInfo: React.FC<Props> = ({ totalDealShares, retailRetention, potAvailable }) => {
  return (
    <StyledTable gridTemplateColumns="3fr 10px 1fr">
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Total Deal Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(totalDealShares)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Retail Retention</Table.Cell>
      <Table.Cell paddingSize="S">-</Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForInteger(retailRetention)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Pot Available
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForInteger(potAvailable)}
      </Table.Cell>
    </StyledTable>
  );
};

export default PotAvailableInfo;
