import { ColDef } from 'ag-grid-community';
import React from 'react';

import { offeringColumnMap, OfferingGridOrderField } from '../Columns';
import IssuerNameCellRenderer from './components/IssuerNameCellRenderer';

export const columns: ColDef[] = [
  {
    ...offeringColumnMap[OfferingGridOrderField.ISSUER_NAME],
    cellRendererFramework: ({ value, data }) => (
      <IssuerNameCellRenderer value={value} data={data} />
    ),
  },
  offeringColumnMap[OfferingGridOrderField.SYMBOL],
  offeringColumnMap[OfferingGridOrderField.SECTOR],
  offeringColumnMap[OfferingGridOrderField.TYPE],
  offeringColumnMap[OfferingGridOrderField.STATUS],
];

export default columns;
