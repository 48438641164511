import React from 'react';

import GridLayout from '../../../../../../../../common/components/layout/grid-layout/GridLayout';
import { MeetingFormat } from '../../../../../../../../graphql';
import {
  Roadshows_MeetingDetailsPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../../common/graphql';
import EventFormSubHeader from '../../../common/event-form-subheader/EventFormSubHeader';
import {
  formatDate,
  formatTime,
  getRoadshowTimezone,
  meetingFormatNames,
} from './MeetingDetails.model';
import {
  SColumn,
  SHeaderColumn,
  SSubheaderContent,
  StyledFlexRow,
  StyledTitleColumn,
  StyledValueColumn,
} from './MeetingDetails.styles';

export type Props = {
  meeting: Roadshows_MeetingDetailsPartsFragment;
  roadshowDay: Roadshows_RoadshowPartsFragment['roadshowDays'][number];
};

const MeetingDetails: React.FC<Props> = ({ meeting, roadshowDay }) => {
  return (
    <div>
      <EventFormSubHeader title="Meeting Details">
        <SSubheaderContent>
          <GridLayout>
            <StyledTitleColumn span={4}>Date:</StyledTitleColumn>
            <StyledValueColumn span={8}>{formatDate(meeting.date)}</StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>Time:</StyledTitleColumn>
            <StyledValueColumn span={8}>
              {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)},{' '}
              {getRoadshowTimezone(roadshowDay, meeting)}
            </StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>Meeting Format:</StyledTitleColumn>
            <StyledValueColumn span={8}>{meetingFormatNames[meeting.format]}</StyledValueColumn>
          </GridLayout>
        </SSubheaderContent>
      </EventFormSubHeader>

      <EventFormSubHeader title="Location">
        <SSubheaderContent>
          {meeting.format !== MeetingFormat.Virtual && (
            <React.Fragment>
              <GridLayout>
                <StyledTitleColumn span={4}>Location:</StyledTitleColumn>
                <StyledValueColumn span={8}>{meeting.location}</StyledValueColumn>
              </GridLayout>

              <GridLayout>
                <StyledTitleColumn span={4}>Venue:</StyledTitleColumn>
                <StyledValueColumn span={8}>{meeting.venue}</StyledValueColumn>
              </GridLayout>

              <GridLayout>
                <StyledTitleColumn span={4}>Room Name:</StyledTitleColumn>
                <StyledValueColumn span={8}>{meeting.roomName}</StyledValueColumn>
              </GridLayout>
            </React.Fragment>
          )}

          {meeting.format !== MeetingFormat.InPerson && (
            <GridLayout>
              <StyledTitleColumn span={4}>Virtual Meeting Details:</StyledTitleColumn>
              <StyledValueColumn span={8}>{meeting.dialInDetails}</StyledValueColumn>
            </GridLayout>
          )}
        </SSubheaderContent>
      </EventFormSubHeader>

      <EventFormSubHeader title="Notes">
        <SSubheaderContent>{meeting.publicNotes}</SSubheaderContent>
      </EventFormSubHeader>

      <EventFormSubHeader title="Hosted by">
        <StyledFlexRow>
          <SHeaderColumn>Underwriter</SHeaderColumn>
          <SHeaderColumn>Member's Name</SHeaderColumn>
          <SHeaderColumn>Email</SHeaderColumn>
        </StyledFlexRow>

        <StyledFlexRow>
          {/* TODO: temporarily displaying cmgEntityKey, until deal teams are reworked */}
          <SColumn>{meeting.hostFirmCmgEntityKey}</SColumn>
          <SColumn>{meeting.hostPersonFullName}</SColumn>
          <SColumn>{meeting.hostPersonEmail}</SColumn>
        </StyledFlexRow>
      </EventFormSubHeader>
    </div>
  );
};

export default MeetingDetails;
