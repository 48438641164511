import { AgGridApi, UUID } from '@cmg/common';
import { RowNode } from 'ag-grid-community';

import { InvestorReplyStatus } from '../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../graphql/__generated__';
import { TradeRowActionsAvailability } from '../hooks/useTradeGrid';

export type CellRendererSelectorParams = {
  data: TradeRelease_TradeGridItemFragment;
  node: RowNode;
  api: AgGridApi;
  context: TradeReleaseGridContext;
};

export const isTradeReleaseRowSelectablePredicate = (row: RowNode) => {
  const tradeRow = row.data as TradeRelease_TradeGridItemFragment;

  return tradeRow.allowedStatusTransitions.length > 0;
};

export type TradeReleaseGridContext = {
  offeringId: UUID;
  filteredRows: TradeRelease_TradeGridItemFragment[];
  setNumOfUnchangedTrades: (unchangedTrades: number) => void;
};

type TradeColumns = Omit<
  TradeRelease_TradeGridItemFragment,
  'indicationId' | 'investorCmgEntityKey' | '__typename'
>;

export const tradeColsMap: Record<keyof TradeColumns | 'selected', string> = {
  id: 'id',
  selected: 'selected',
  tradeId: 'tradeId',
  investorBankKey: 'investorBankKey',
  investorFirmName: 'investorFirmName',
  allocationShares: 'allocationShares',
  sharesReleased: 'sharesReleased',
  investorReplyStatus: 'investorReplyStatus',
  investorReplyName: 'investorReplyName',
  investorReplyEmail: 'investorReplyEmail',
  indicationSubmittedBy: 'indicationSubmittedBy',
  indicationInvestorContactName: 'indicationInvestorContactName',
  indicationInvestorContactEmail: 'indicationInvestorContactEmail',
  tradeStatus: 'tradeStatus',
  statusDetails: 'statusDetails',
  allowedStatusTransitions: 'allowedStatusTransitions',
  warnings: 'warnings',
  sellingConcession: 'sellingConcession',
  cusip: 'cusip',
  exchange: 'exchange',
  indicationAcknowledgedByName: 'indicationAcknowledgedByName',
  indicationAcknowledgedByEmail: 'indicationAcknowledgedByEmail',
  indicationStatus: 'indicationStatus',
  investorContactName: 'investorContactName',
  investorContactEmail: 'investorContactEmail',
  isin: 'isin',
  offeringCurrency: 'offeringCurrency',
  releasedByName: 'releasedByName',
  releasedByEmail: 'releasedByEmail',
  releasedAt: 'releasedAt',
  securityType: 'securityType',
  settlementCurrency: 'settlementCurrency',
  settlementDate: 'settlementDate',
  tradeDate: 'tradeDate',
  symbol: 'symbol',
  offerPrice: 'offerPrice',
};

export const getActionsAvailability = (tradeRow: TradeRelease_TradeGridItemFragment) => {
  if (tradeRow.allowedStatusTransitions.length > 0) {
    return TradeRowActionsAvailability.ENABLED;
  }

  if (tradeRow.allocationShares === 0) {
    return TradeRowActionsAvailability.HIDDEN;
  }

  if (tradeRow.investorReplyStatus === InvestorReplyStatus.Rejected) {
    return TradeRowActionsAvailability.HIDDEN;
  }

  return TradeRowActionsAvailability.DISABLED;
};
