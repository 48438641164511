import { ApolloError } from '@apollo/client';
import { numericUtil } from '@cmg/common';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableRow,
  TableSkeleton,
} from '@cmg/design-system';
import React from 'react';

import { SyndicateWiresManagerRole } from '../../../../../../../graphql';
import {
  SyndicateWireManagerRole,
  syndicateWireManagerRoleLabels,
} from '../../../../../../../types/domain/syndicate-wires/constants';
import { LoadingErrorSection } from '../../../../common/components/dialogs/create-or-update-wire-dialog/error-loading-recipients-data-section/LoadingErrorSection';
import { WireFormBanner } from '../../../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import {
  SyndicateWires_ManagerRetailRetentionFeesQuery,
  SyndicateWires_SyndicateWiresManagerPartsFragment,
} from '../../../../common/graphql';
import { useOnFormDataLoaded } from '../../../../common/hooks/useOnFormDataLoaded';
import { ManagerRetentionTableHead } from './ManagerRetentionTableHead';

export type ManagerGroup = [
  SyndicateWiresManagerRole,
  (Pick<SyndicateWires_SyndicateWiresManagerPartsFragment, 'cmgEntityKey' | 'firmName'> & {
    role: SyndicateWireManagerRole;
  })[]
];

export type ManagerRetentionTableProps = Readonly<{
  readonly managersByRoles: ManagerGroup[];
  readonly refetch: () => void;
  readonly error: ApolloError | undefined;
  readonly loading: boolean;
  readonly onFormDataLoaded?: ((isSuccess: boolean) => void) | undefined;
  readonly data: SyndicateWires_ManagerRetailRetentionFeesQuery | undefined;
  readonly label?: string;
}>;

export const ManagerRetentionTable: React.FC<ManagerRetentionTableProps> = ({
  label = 'Manager Retention',
  data,
  error,
  loading,
  refetch,
  onFormDataLoaded,
  managersByRoles,
}) => {
  useOnFormDataLoaded({ onFormDataLoaded, loading, error });

  if (loading) {
    return <TableSkeleton aria-label={label} cellSize="medium" numOfColumns={2} />;
  }

  if (error) {
    return (
      <LoadingErrorSection
        tableLabel={label}
        tableHead={<ManagerRetentionTableHead />}
        onRefetch={refetch}
      />
    );
  }

  return (
    <Stack gap={3}>
      <WireFormBanner variant="manager-specific-readonly" />
      <TableContainer>
        <Table aria-label={label}>
          <ManagerRetentionTableHead />
          {managersByRoles.map(([role, managersOfRole]) => (
            <TableBody key={role} aria-label={syndicateWireManagerRoleLabels[role]}>
              <TableGroupHeader>
                <TableGroupHeaderCell colSpan={2}>
                  {syndicateWireManagerRoleLabels[role]}
                </TableGroupHeaderCell>
              </TableGroupHeader>
              {managersOfRole.map(manager => {
                const { retention } =
                  data?.syndicateWires.managerRetailRetentionFees.find(
                    ({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey
                  ) ?? {};

                return (
                  <TableRow key={manager.cmgEntityKey}>
                    <TableCell size="medium">{manager.firmName}</TableCell>
                    <TableCell size="medium" align="right">
                      {numericUtil.formatNumber(retention, 0)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </Stack>
  );
};
