import { Icon } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { allocationFormSchema } from './AllocationForm.model';
import {
  SColumn,
  SDirtyIndicator,
  SPrefixWrapper,
  StyledNumericInputField,
} from './AllocationForm.styles';

type Values = {
  shareQuantity: number | null;
  modifiedAt?: string | null | undefined;
};

export type Props = Readonly<{
  /**
   * Initial allocation share quantity.
   */
  shareQuantity: number | null;
  /**
   * Whether or not the form field is enabled.
   */
  disabled?: boolean;
  /**
   * Whether or not the input is being saved
   */
  isSaving?: boolean;
  /**
   * Debounced callback triggered when the field changes.
   */
  onUpdateAllocationShareQuantity: ({
    initialValue,
    newValue,
  }: {
    initialValue: number | null;
    newValue: number | null;
  }) => void;
  /**
   * used to show alternative ui state
   */
  isEditing?: boolean;
  /**
   * external toggle to trigger form reset every change
   */
  resetFormToggle?: boolean;

  modifiedAt?: string | null | undefined;

  WarningIconComponent?: React.ReactNode;
}>;

/**
 * Renders the Allocation Form - a form used to input the number of shares
 * for an Allocation.
 */
export const AllocationFormComponent: React.FC<Props> = React.memo(
  ({
    disabled,
    isEditing,
    isSaving,
    shareQuantity,
    modifiedAt,
    resetFormToggle,
    onUpdateAllocationShareQuantity,
    WarningIconComponent,
  }) => {
    const [isDataRecentlyChanged, setIsDataRecentlyChanged] = useState<boolean>(false);
    const [focused, setFocused] = React.useState(false);

    const formik = useFormik<Values>({
      initialValues: {
        shareQuantity,
        modifiedAt,
      },
      validationSchema: allocationFormSchema,
      onSubmit: values => {
        onUpdateAllocationShareQuantity({
          initialValue: shareQuantity,
          newValue: values.shareQuantity,
        });
      },
    });

    const { handleSubmit, resetForm, values } = formik;

    const debounceDataRecentlyChanged = useDebouncedCallback(() => {
      setIsDataRecentlyChanged(false);
    }, 20000);
    const debouncedHandleSubmit = useDebouncedCallback(() => {
      setIsDataRecentlyChanged(true);
      debounceDataRecentlyChanged();
      handleSubmit();
    }, 1000);

    // We will not override users value
    const isFormUpToDate = shareQuantity === values.shareQuantity;
    const handleOnChange = React.useCallback(() => {
      if (focused) {
        setIsDataRecentlyChanged(true);
        debouncedHandleSubmit();
      }
    }, [debouncedHandleSubmit, focused]);

    useEffect(() => {
      if (values.shareQuantity !== shareQuantity) {
        // If we force an update we should update and make the update wait for 20 seconds
        if (resetFormToggle) {
          resetForm({
            values: {
              shareQuantity,
            },
          });
        }
        // If we detect a new change and its not focused and it passed more than 20 seconds from your last update on the field
        if (!isEditing && !isDataRecentlyChanged && !focused) {
          resetForm({
            values: {
              shareQuantity,
            },
          });
        }
      }
    }, [
      isEditing,
      resetFormToggle,
      isDataRecentlyChanged,
      focused,
      values.shareQuantity,
      shareQuantity,
      resetForm,
    ]);

    // If we recently saved something we are also going to keep what we saved for 20 seconds
    useEffect(() => {
      if (isSaving) {
        setIsDataRecentlyChanged(true);
        debounceDataRecentlyChanged();
      }
    }, [isSaving, setIsDataRecentlyChanged, debounceDataRecentlyChanged]);

    return (
      <FormikProvider value={formik}>
        {!isEditing && !isDataRecentlyChanged && !isFormUpToDate && <SDirtyIndicator />}
        <SColumn>
          <StyledNumericInputField
            disabled={disabled}
            name="shareQuantity"
            fullWidth={true}
            onChange={handleOnChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            prefix={
              isSaving ? (
                <SPrefixWrapper>
                  <Icon name="spinner-third" spin={true} />
                </SPrefixWrapper>
              ) : (
                WarningIconComponent
              )
            }
          />
        </SColumn>
      </FormikProvider>
    );
  }
);

export default AllocationFormComponent;
