import { ApolloError } from '@apollo/client';
import flatMap from 'lodash/flatMap';
import React from 'react';

import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import {
  useCrmSelection_InstitutionalIndicationsQuery,
  useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
} from './graphql/__generated__';
import { useGetAllCoveredInvestorFirmsMappedToCmgEntityKey } from './hooks/useGetAllCoveredInvestorFirmsMappedToCmgEntityKey';

export type CrmModel = {
  data: CrmSelectionRow[];
  loading: boolean;
  error?: ApolloError;
};

export type CrmSelectionRow = {
  id: string;
  firmName: string;
  location: string | null;
  internalSalesCoverage: string | null;
};

export const useGetCrmModel = ({
  cmgEntityKey,
  offeringId,
  duplicateIndicationIds,
  indicationId,
}: {
  cmgEntityKey?: string;
  offeringId?: string;
  duplicateIndicationIds?: readonly string[];
  indicationId?: string;
}): CrmModel => {
  const { type } = useIdentifyOrderBookPersona({ offeringId });

  const { loading: loadingIndications, data: indications } =
    useCrmSelection_InstitutionalIndicationsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        offeringId: offeringId!,
      },
      skip: type !== OrderBookPersonaType.SYNDICATE,
    });

  const allIndications = React.useMemo(() => {
    const allIndicationIds = [indicationId, ...(duplicateIndicationIds ?? [])];

    if (indications?.institutionalIndications) {
      return indications.institutionalIndications
        .filter(indication => {
          return allIndicationIds?.find(id => id === indication.id);
        })
        .map(({ investorInformation }) => {
          return {
            cmgEntityKey: investorInformation?.cmgEntityKey,
            cmgEntityName: investorInformation?.cmgEntityName,
          };
        });
    }
    return [];
  }, [indications?.institutionalIndications, indicationId, duplicateIndicationIds]);

  const cmgEntityKeysOfDuplicatedIndications = allIndications.map(
    ({ cmgEntityKey }) => cmgEntityKey ?? ''
  );

  const {
    loading: loadingFirms,
    data: firms,
    error: firmsError,
  } = useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cmgEntityKeys: [cmgEntityKey!, ...cmgEntityKeysOfDuplicatedIndications]!,
    },
    skip: !cmgEntityKey || type !== OrderBookPersonaType.SYNDICATE || loadingIndications,
  });

  const {
    loading: loadingCoveredFirms,
    data: coveredFirms,
    error: coveredFirmsError,
  } = useGetAllCoveredInvestorFirmsMappedToCmgEntityKey({
    variables: { cmgEntityKey: cmgEntityKey! },
    skip: !cmgEntityKey || type !== OrderBookPersonaType.SALES_AND_TRADING,
  });

  const allInvestorFirmsMappedToCmgEntityKeys = flatMap(
    firms?.allInvestorFirmsMappedToCmgEntityKeys,
    ({ investors }) => {
      const mappedWithCmgEntityName = investors.map(investor => {
        const duplicatedIndication = allIndications.find(
          ({ cmgEntityKey }) => cmgEntityKey === investor.cmgEntityKey
        );
        return {
          ...investor,
          cmgEntityName: duplicatedIndication?.cmgEntityName,
        };
      });
      return [...mappedWithCmgEntityName];
    }
  );

  const data: CrmSelectionRow[] = (
    (type === OrderBookPersonaType.SYNDICATE
      ? allInvestorFirmsMappedToCmgEntityKeys
      : coveredFirms?.allCoveredInvestorFirmsMappedToCmgEntityKey) ?? []
  )
    // @ts-ignore: missing cmgEntityName for covered firms, refactor for covered firms later
    .filter(firm => firm.firmKey !== null)
    .map(firm => {
      return {
        id: firm.firmKey!,
        firmName: firm.name,
        location: firm.address1 ?? firm.address2 ?? '',
        internalSalesCoverage: null, // This will be implemented in a future ticket
        cmgEntityName: 'cmgEntityName' in firm ? firm.cmgEntityName : undefined,
      };
    });

  return {
    data,
    loading: loadingIndications || loadingFirms || loadingCoveredFirms,
    error: firmsError ?? coveredFirmsError,
  };
};
