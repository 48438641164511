import { SIconContainer, SSeparator, StyledIconDateRange, StyledSearchIcon } from './Suffix.styles';

export const SearchSuffix = () => (
  <SIconContainer>
    <SSeparator />
    <StyledSearchIcon />
  </SIconContainer>
);

export const DateSuffix = () => (
  <SIconContainer>
    <SSeparator />
    <StyledIconDateRange />
  </SIconContainer>
);
