import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const SText = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SCellWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
