import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';
import { calculateOverallotmentShares } from './BaseDealSharesInfoBox.model';

const BaseDealSharesInfoBox: React.FC = () => {
  const accounting = useAccountingContext();
  const { baseDealShares } = accounting;

  const overallotmentShares = calculateOverallotmentShares(accounting);

  return (
    <AccountingInfoBox
      gridTemplateColumns="3fr 10px 1.5fr"
      sourceOfData="Sourced from Offering Setup"
    >
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Base Deal Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(baseDealShares)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Overallotment Shares</Table.Cell>
      <Table.Cell paddingSize="S" bold>
        +
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForInteger(overallotmentShares)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Total Deal Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForInteger(
          numericUtil.sum(baseDealShares, overallotmentShares)
        )}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default BaseDealSharesInfoBox;
