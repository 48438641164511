import { Grid, RegularBreakpoints } from '@cmg/design-system';
import { FC } from 'react';

export type Props = RegularBreakpoints;

export const WireFormLayoutItem: FC<Props> = ({ children, xs = 12, ...rest }) => {
  return (
    <Grid item xs={xs} {...rest}>
      {children}
    </Grid>
  );
};
