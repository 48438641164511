import { UUID } from '@cmg/common';

import {
  GridTransitionSalesCreditReleaseStatus,
  InternalSalesCreditReleaseStatus,
} from '../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';

export type ReleaseSalesCreditsFunction = (
  investorFirmCmgEntityKeys: UUID[],
  unchangedRows?: SalesCredits_SalesCreditItemPartsFragment[]
) => void;
export type UpdateSalesCreditsStatusFunction = (
  targetStatus: InternalSalesCreditReleaseStatus,
  investorFirmCmgEntityKeys: UUID[],
  unchangedRows?: SalesCredits_SalesCreditItemPartsFragment[]
) => void;
export type ReviewSalesCreditsItemFunction = (
  reviewedItem: SalesCredits_SalesCreditItemPartsFragment
) => void;

export const selectRowsByAllowedTransition = (
  rows: SalesCredits_SalesCreditItemPartsFragment[],
  allowedTransition: GridTransitionSalesCreditReleaseStatus,
  valuePredicate: (value: SalesCredits_SalesCreditItemPartsFragment) => string
): [string[], SalesCredits_SalesCreditItemPartsFragment[]] => {
  const keysToChange = rows
    .filter(({ allowedStatusTransitions }) => allowedStatusTransitions.includes(allowedTransition))
    .map(valuePredicate);
  const unchangedRows = rows.filter(
    ({ allowedStatusTransitions }) => !allowedStatusTransitions.includes(allowedTransition)
  );

  return [keysToChange, unchangedRows];
};
