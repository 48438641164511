import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.gray1};
  padding: 16px 20px;
  margin-bottom: 20px;
  height: 90px;
`;

export const SHeading = styled.h3`
  margin: 0;
`;

export const SRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
  flex-direction: row;
  flex: 1;
`;
