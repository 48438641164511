import styled from 'styled-components/macro';

export const SInfoContent = styled.div`
  width: 66%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const SInfoColumn = styled.div`
  align-items: baseline;
  flex: 1;
`;

export const SInfoColumnTitle = styled.p`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  color: ${({ theme }) => theme.text.color.light};
`;

export const SInfoColumnText = styled.p`
  margin-bottom: 0;
`;
