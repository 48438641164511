import { AlertProps } from '@cmg/design-system';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { OfferingType } from '../../../../../../../../graphql';
import { IndicationForm_IndicationAcknowledgementsQuery } from '../../../indication-form/components/indication-details/components/acknowledgement/graphql/__generated__';

export function getAcknowledgementStatus({
  oidcUserCmgEntityKey,
  acknowledgements,
  indicationVersion,
}: {
  oidcUserCmgEntityKey: string | null;
  acknowledgements: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements'];
  indicationVersion: string;
}) {
  const acknowledgement = acknowledgements?.find(
    ack => ack.managerCmgEntityKey === oidcUserCmgEntityKey
  );

  return {
    isNewIndication: !acknowledgement,
    isUpToDate: acknowledgement?.acknowledgedIndicationVersion === indicationVersion,
  };
}

export function useIndicationAcknowledgementBanner({
  offeringType,
  oidcUserCmgEntityKey,
  indicationVersion,
  acknowledgements,
}: {
  offeringType: OfferingType;
  oidcUserCmgEntityKey: string | null;
  indicationVersion: string;
  acknowledgements: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements'];
}): {
  alertSeverity: AlertProps['severity'];
  bannerBody: ReactNode;
  bannerTitle: string | null;
  isUpToDate: boolean;
} {
  const { search, pathname } = useLocation();

  const { isNewIndication, isUpToDate } = getAcknowledgementStatus({
    acknowledgements,
    indicationVersion,
    oidcUserCmgEntityKey,
  });

  if (isUpToDate) {
    return {
      alertSeverity: 'success',
      bannerBody: 'You have acknowledged this indication.',
      bannerTitle: null,
      isUpToDate,
    };
  }

  if (isNewIndication && [OfferingType.Ipo, OfferingType.IpoSpac].includes(offeringType)) {
    return {
      alertSeverity: 'warning',
      bannerTitle: 'Acknowledge Indication',
      bannerBody: (
        <span>
          Prospectus email will be sent to all investor contacts upon your acknowledgement.{' '}
          <HashLink smooth to={`${pathname}${search}#contact-information`}>
            Add additional contacts
          </HashLink>
        </span>
      ),
      isUpToDate,
    };
  }

  if (isNewIndication) {
    return {
      alertSeverity: 'warning',
      bannerTitle: 'Acknowledge Indication',
      bannerBody: 'You have not yet acknowledged this indication.',
      isUpToDate,
    };
  }

  return {
    alertSeverity: 'warning',
    bannerTitle: 'Indication has changed',
    bannerBody: 'The indication has been revised since your acknowledgement.',
    isUpToDate,
  };
}
