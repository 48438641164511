import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';

const TotalGainLossInfoBox: React.FC = () => {
  const { stabilizationGainLoss } = useAccountingContext();

  return (
    <AccountingInfoBox gridTemplateColumns="2fr 1.5fr">
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Total Gain/(Loss)
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(stabilizationGainLoss, 2)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default TotalGainLossInfoBox;
