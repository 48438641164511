import { OrderBook_InstitutionalDemand_InvestorInfoPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';

export type Value = OrderBook_InstitutionalDemand_InvestorInfoPartsFragment['bankInvestorKey'];

const firmKeyColDef: DemandGridServerColDef<Value> = {
  field: 'investor.bankInvestorKey',
  headerName: 'Firm Key',
};

export default firmKeyColDef;
