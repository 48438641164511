import * as yup from 'yup';

export const DISCLAIMER_TEXT_LIMIT = 200_000;
export const FIELD_NAME = 'disclaimer';

export const wiresFormDisclaimerSchema = yup.object({
  disclaimer: yup
    .string()
    .label('Custom Disclaimer')
    .nullable()
    .max(DISCLAIMER_TEXT_LIMIT)
    .test(
      'invalid-disclaimer',
      'Custom Disclaimer contains invalid characters',
      (_value, context) => {
        return context.options.context?.isDisclaimerValid !== false;
      }
    ) as yup.StringSchema<string | null>, // yup makes TS think disclaimer can also be undefined
});
