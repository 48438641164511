import { FormField, numericUtil, timeUtil } from '@cmg/common';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';
import styled from 'styled-components/macro';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import {
  FilingStatus,
  PricingBasisType,
  TnBasisCodeType,
  TnRestrictedPeriodType,
  TnSecurityType,
  TransactionType,
} from '../../../../../graphql';
import { FinraFilingStatusAlert } from '../../../common/content/alerts/FinraFilingStatusAlert';
import FilingCommonFieldsDetail from '../../../common/content/filing-common-fields-detail/FilingCommonFieldsDetail';
import FilingDetails from '../../../common/content/filing-details/FilingDetails';
import FilingDetailsHeader from '../../../common/content/filing-details-header/FilingDetailsHeader';
import FinraErrorResponseBanner from '../../../common/content/finra-error-response/FinraErrorResponseBanner';
import { NonMemberFirmsDetail } from '../../../common/content/non-member-firms-detail/NonMemberFirmsDetail';
import { getLabelFromOptions } from '../../../common/form-utils';
import { SDescription } from '../../../RegulatoryFilingsRoute.styles';
import {
  basisCodeOptions,
  pricingBasisOptions,
  restrictedPeriodOptions,
  transactionTypeOptions,
  typeOfSecurityOptions,
} from '../form/TNFilingForm.model';
import { RegulatoryFilings_TnFilingPartsFragment } from '../graphql';
import { ActivityDetail } from './activity/ActivityDetail';
import ATMTradeDetail from './atm-trade/ATMTradeDetail';
import MemberFirmsDetail from './member-firm/MemberFirmsDetail';
import NonATMTradeDetail from './non-atm-trade/NonATMTradeDetail';

const SGridWrapper = styled.div`
  max-width: fit-content;
`;

export type Props = {
  filing: RegulatoryFilings_TnFilingPartsFragment;
  version: number;
};

const TNFilingDetail: React.FC<Props> = ({ filing, version }) => {
  return (
    <div>
      <FinraFilingStatusAlert status={filing.status} />
      <FilingDetailsHeader
        version={version}
        firmDealId={filing.firmDealId}
        formUniqueId={filing.formUniqueId}
        status={filing.status}
        submittedDateTime={filing.submittedDateTime}
      />

      {filing.status === FilingStatus.Error && filing.finraErrorMessages && (
        <FinraErrorResponseBanner errors={filing.finraErrorMessages} />
      )}

      <FilingCommonFieldsDetail detail={filing} />
      <FilingDetails issuerSymbol={filing.issuerSymbol} issuerName={filing.issuerName}>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Symbols of covered securities">
              {filing.symbolsOfCoveredSecurities.map((symbol, idx) => (
                <SDescription key={symbol} aria-label={`${idx + 1}. Symbol of covered securities`}>
                  {symbol}
                </SDescription>
              ))}
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Concurrent Convertible">
              <div>{filing.isConcurrentConvertible ? 'Yes' : 'No'}</div>
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Transaction Type">
              <div>{getLabelFromOptions(filing.transactionType, transactionTypeOptions)}</div>
            </FormField>
          </Column>
        </GridLayout>
        {filing.transactionType === TransactionType.Atm && <ATMTradeDetail filing={filing} />}
        {filing.transactionType !== TransactionType.Atm && <NonATMTradeDetail filing={filing} />}
        {filing.transactionType === TransactionType.Other && (
          <GridLayout>
            <Column span={6}>
              <FormField withMargin fullWidth label="Transaction Type Text">
                <SDescription>{filing.transactionTypeDescription}</SDescription>
              </FormField>
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Type of security">
              <div aria-label="Type of security">
                {getLabelFromOptions(filing.securityType, typeOfSecurityOptions)}
              </div>
            </FormField>
          </Column>
        </GridLayout>
        {filing.securityType === TnSecurityType.Other && (
          <GridLayout>
            <Column span={6}>
              <FormField withMargin fullWidth label="Type of Security Description">
                <SDescription aria-label="Type of Security Description">
                  {filing.securityTypeDescription}
                </SDescription>
              </FormField>
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Pricing Basis">
              <div aria-label="Pricing Basis">
                {getLabelFromOptions(filing.pricingBasisType, pricingBasisOptions)}
              </div>
            </FormField>
          </Column>
        </GridLayout>
        {filing.pricingBasisType === PricingBasisType.Other && (
          <GridLayout>
            <Column span={6}>
              <FormField withMargin fullWidth label="Pricing Basis Description">
                <SDescription aria-label="Pricing Basis Description">
                  {filing.pricingBasisDescription}
                </SDescription>
              </FormField>
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Restricted Period">
              <div>{getLabelFromOptions(filing.restrictedPeriod, restrictedPeriodOptions)}</div>
            </FormField>
          </Column>
        </GridLayout>
        {filing.restrictedPeriod === TnRestrictedPeriodType.Na && (
          <React.Fragment>
            <GridLayout>
              <Column span={3}>
                <FormField withMargin fullWidth label="Basis Code">
                  <div aria-label="Basis Code">
                    {getLabelFromOptions(filing.basisCode, basisCodeOptions)}
                  </div>
                </FormField>
              </Column>
            </GridLayout>
            {filing.basisCode === TnBasisCodeType.Uar && (
              <GridLayout>
                <Column span={3}>
                  <FormField withMargin fullWidth label="Underwriter Activity Report Date">
                    <div aria-label="Underwriter Activity Report Date">
                      {filing.uarDate && timeUtil.formatAsDisplayDate(filing.uarDate)}
                    </div>
                  </FormField>
                </Column>
              </GridLayout>
            )}
            {filing.basisCode === TnBasisCodeType.AdtvPfv && (
              <SGridWrapper>
                <GridLayout>
                  <Column span={3}>
                    <FormField withMargin fullWidth label="ADTV Number">
                      <div aria-label="ADTV Number">
                        {numericUtil.formatCurrency(filing.adtvNumber, 6)}
                      </div>
                    </FormField>
                  </Column>
                  <Column span={3}>
                    <FormField withMargin fullWidth label="ADTV Source">
                      <SDescription aria-label="ADTV Source">{filing.adtvSource}</SDescription>
                    </FormField>
                  </Column>
                </GridLayout>
                <GridLayout>
                  <Column span={3}>
                    <FormField withMargin fullWidth label="Public Float Value">
                      <div aria-label="Public Float Value">
                        {numericUtil.formatCurrency(filing.publicFloatValue, 6)}
                      </div>
                    </FormField>
                  </Column>
                  <Column span={3}>
                    <FormField withMargin label="Public Float Value Source">
                      <SDescription aria-label="Public Float Value Source">
                        {filing.publicFloatValueSource}
                      </SDescription>
                    </FormField>
                  </Column>
                </GridLayout>
              </SGridWrapper>
            )}
            {filing.basisCode === TnBasisCodeType.Other && (
              <GridLayout>
                <Column span={6}>
                  <FormField withMargin fullWidth label="Other Basis Text">
                    <SDescription aria-label="Other Basis Text">
                      {filing.otherBasisDescription}
                    </SDescription>
                  </FormField>
                </Column>
              </GridLayout>
            )}
          </React.Fragment>
        )}
        {(filing.restrictedPeriod === TnRestrictedPeriodType.OneDay ||
          filing.restrictedPeriod === TnRestrictedPeriodType.FiveDays) && (
          <GridLayout>
            <Column span={3}>
              <FormField withMargin fullWidth label="Restricted Period Commencement Time">
                <div aria-label="Restricted Period Commencement Time">
                  {filing.restrictedPeriodStartDateTime &&
                    filing.restrictedPeriodStartTimezone &&
                    timeUtil.formatAsDisplayDateTime(
                      utcToZonedTime(
                        filing.restrictedPeriodStartDateTime,
                        filing.restrictedPeriodStartTimezone
                      )
                    )}
                </div>
              </FormField>
            </Column>
            <Column span={3}>
              <FormField withMargin fullWidth label="Restricted Period End Time">
                <div aria-label="Restricted Period End Time">
                  {filing.restrictedPeriodEndDateTime &&
                    filing.restrictedPeriodEndTimezone &&
                    timeUtil.formatAsDisplayDateTime(
                      utcToZonedTime(
                        filing.restrictedPeriodEndDateTime,
                        filing.restrictedPeriodEndTimezone
                      )
                    )}
                </div>
              </FormField>
            </Column>
          </GridLayout>
        )}
      </FilingDetails>

      <MemberFirmsDetail memberFirms={filing.finraMemberFirms} />
      <NonMemberFirmsDetail nonMemberFirms={filing.nonMemberFirms} isDetailView />
      <ActivityDetail
        customActivities={filing.activities}
        stabilizationActivities={filing.stabilizationActivities}
      />
    </div>
  );
};

export default TNFilingDetail;
