import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React from 'react';

type AccessCheckResult = {
  canRead: boolean;
  canUpdate: boolean;
};

/**
 * Checks accessibility of Covered Accounts Institutional Indication
 *
 * Since Sales and Trading users can only act on behalf of their covered accounts,
 * this hook checks whether Sales and Trading indication flow is available.
 *
 * There are places where either Syndicate or Sales Trading version of a feature is used,
 * and decision which one to use is based on [this] permission check - when a user has both,
 * the Syndicate takes precedence (in reality, these roles are exclusive)
 */
function useCheckCoveredInstitutionalIndicationAccess(): AccessCheckResult {
  const hasReadCovered = useCheckPermissions([
    permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
  ]);
  const hasReadSyndicate = useCheckPermissions([permissionsByEntity.InstitutionalIndication.READ]);

  const hasFullCovered = useCheckPermissions([
    permissionsByEntity.CoveredAccountsInstitutionalIndication.FULL,
  ]);

  const accessChecks = React.useMemo(
    () => ({
      canRead: hasReadCovered && !hasReadSyndicate,
      canUpdate: hasFullCovered,
    }),
    [hasReadCovered, hasFullCovered, hasReadSyndicate]
  );

  return { ...accessChecks };
}

export default useCheckCoveredInstitutionalIndicationAccess;
