import { numericUtil } from '@cmg/common';

import type { DemandGridDataContext } from '../types';

type Props = Pick<DemandGridDataContext, 'totalSummaryMetrics' | 'selectedRowsSummaryMetrics'>;

export const getSummaryMetricsMap = ({
  totalSummaryMetrics,
  selectedRowsSummaryMetrics,
}: Props) => {
  const visibleMetrics = selectedRowsSummaryMetrics ?? totalSummaryMetrics;

  const demandRatio = numericUtil.divide(
    visibleMetrics?.totalDemand,
    totalSummaryMetrics?.totalDemand
  );

  const allocationRatio = numericUtil.divide(
    visibleMetrics?.totalAllocatedShares,
    totalSummaryMetrics?.totalAllocatedShares
  );

  const map = {
    Subscription: numericUtil.getDisplayValueForNumber(visibleMetrics?.subscription, 2, 0, 'x'),
    'Total Demand': numericUtil.getDisplayValueForInteger(visibleMetrics?.totalDemand),
    Indications: numericUtil.getDisplayValueForInteger(
      visibleMetrics?.totalActiveNonPassIndicationCount
    ),
    'Avg Demand': numericUtil.getDisplayValueForNumber(visibleMetrics?.averageDemand, 0),
    Market: numericUtil.getDisplayValueForPercents(visibleMetrics?.marketToLimitRatio),
    Limit: numericUtil.getDisplayValueForPercents(visibleMetrics?.limitToMarketRatio),
    'Of Demand': numericUtil.getDisplayValueForPercents(demandRatio),
    'Total Allocated': numericUtil.getDisplayValueForInteger(visibleMetrics?.totalAllocatedShares),
    Allocations: numericUtil.getDisplayValueForInteger(visibleMetrics?.totalAllocationCount),
    'Avg Allocation': numericUtil.getDisplayValueForNumber(visibleMetrics?.averageAllocation, 0),
    'Avg Fill Rate': numericUtil.getDisplayValueForPercents(visibleMetrics?.fillRate),
    'Of Allocated': numericUtil.getDisplayValueForPercents(allocationRatio),
  };

  return Object.entries(map);
};
