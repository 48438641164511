/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_DisclaimersPartsFragment = {
  readonly __typename?: 'Disclaimers';
  readonly id: string;
  readonly restrictions?: string | null;
  readonly footnotes?: string | null;
  readonly registrationLinks: ReadonlyArray<string>;
  readonly rule134Legend?: string | null;
};

export type OfferingSetup_DisclaimersQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DisclaimersQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly disclaimers: {
      readonly __typename?: 'Disclaimers';
      readonly id: string;
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
  };
};

export type OfferingSetup_UpdateDisclaimersMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateDisclaimersInput;
}>;

export type OfferingSetup_UpdateDisclaimersMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDisclaimers: {
    readonly __typename?: 'Disclaimers';
    readonly id: string;
    readonly restrictions?: string | null;
    readonly footnotes?: string | null;
    readonly registrationLinks: ReadonlyArray<string>;
    readonly rule134Legend?: string | null;
  };
};

export const OfferingSetup_DisclaimersPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_DisclaimersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Disclaimers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disclaimers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_DisclaimersParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_DisclaimersQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_DisclaimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DisclaimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_DisclaimersQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_DisclaimersQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_DisclaimersQuery,
    OfferingSetup_DisclaimersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_DisclaimersQuery, OfferingSetup_DisclaimersQueryVariables>(
    OfferingSetup_DisclaimersDocument,
    options
  );
}
export function useOfferingSetup_DisclaimersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_DisclaimersQuery,
    OfferingSetup_DisclaimersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_DisclaimersQuery,
    OfferingSetup_DisclaimersQueryVariables
  >(OfferingSetup_DisclaimersDocument, options);
}
export type OfferingSetup_DisclaimersQueryHookResult = ReturnType<
  typeof useOfferingSetup_DisclaimersQuery
>;
export type OfferingSetup_DisclaimersLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_DisclaimersLazyQuery
>;
export type OfferingSetup_DisclaimersQueryResult = Apollo.QueryResult<
  OfferingSetup_DisclaimersQuery,
  OfferingSetup_DisclaimersQueryVariables
>;
export const OfferingSetup_UpdateDisclaimersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateDisclaimers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDisclaimersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDisclaimers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_DisclaimersParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateDisclaimersMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateDisclaimersMutation,
  OfferingSetup_UpdateDisclaimersMutationVariables
>;

/**
 * __useOfferingSetup_UpdateDisclaimersMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateDisclaimersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateDisclaimersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateDisclaimersMutation, { data, loading, error }] = useOfferingSetup_UpdateDisclaimersMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateDisclaimersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateDisclaimersMutation,
    OfferingSetup_UpdateDisclaimersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateDisclaimersMutation,
    OfferingSetup_UpdateDisclaimersMutationVariables
  >(OfferingSetup_UpdateDisclaimersDocument, options);
}
export type OfferingSetup_UpdateDisclaimersMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateDisclaimersMutation
>;
export type OfferingSetup_UpdateDisclaimersMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateDisclaimersMutation>;
export type OfferingSetup_UpdateDisclaimersMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateDisclaimersMutation,
  OfferingSetup_UpdateDisclaimersMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_DisclaimersMockResponse = makeMockResponse<
  OfferingSetup_DisclaimersQueryVariables,
  OfferingSetup_DisclaimersQuery
>(OfferingSetup_DisclaimersDocument);
