import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import { getBulkActionsFilterInput } from '../demand-grid/DemandGrid.model';
import type { DemandConfigValues } from '../demand-grid/types';
import { useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation } from '../graphql/__generated__';

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
  rowSelectionState: GridRowSelectionState;
}>;

/**
 * Hook for handling the state of the Bulk Enter Allocations dialog including the onSave function handler.
 *
 * @param offeringId
 * @param demandConfig
 * @param rowSelectionState
 */
export const useBulkEnterAllocationsDialogState = ({
  offeringId,
  demandConfig,
  rowSelectionState,
}: Props) => {
  const dialogState = useDialogState();
  const { getFilters } = useDataGridContext();

  const [bulkUpdateAllocations, { error, loading }] =
    useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation();

  const onSave = useCallback(
    async (shareQuantity: number, allocationSetIds: string[]) => {
      if (!demandConfig) {
        return;
      }

      await bulkUpdateAllocations({
        variables: {
          offeringId,
          allocationSetIds,
          shareQuantity,
          applyTo: {
            ...demandConfig,
            where: getBulkActionsFilterInput(rowSelectionState, getFilters()?.filterInput),
          },
        },
      });

      SnackbarManager.success('Allocations have been successfully updated.');
    },
    [bulkUpdateAllocations, demandConfig, getFilters, offeringId, rowSelectionState]
  );

  return { ...dialogState, onSave, error, loading };
};
