import { UnderwriterFirmSearch_UnderwriterFragment } from './graphql';

export type UnderwriterFirm = Readonly<{
  firmCmgEntityKey: string | null | undefined;
  firmName: string;
  isOnPlatform: boolean;
  mpid: string | null;
  crd: string | null;
}>;

export const createOptions = (
  firms: Readonly<UnderwriterFirmSearch_UnderwriterFragment[]>
): UnderwriterFirm[] => {
  return firms.map(({ cmgEntityKey, displayName, isOnPlatform, crd = null, mpid = null }) => ({
    firmCmgEntityKey: cmgEntityKey,
    firmName: displayName ?? '',
    isOnPlatform,
    crd,
    mpid,
  }));
};
