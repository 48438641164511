import { EnableAutoSave, numericUtil, UUID } from '@cmg/common';
import { DeleteIcon, IconButton, SnackbarManager, TableCell, TableRow } from '@cmg/design-system';
import { FormikNumberField, FormikPercentageField } from '@cmg/design-system-formik';
import { FormikErrors, FormikProvider, FormikState, useFormik } from 'formik';
import React from 'react';

import UnderwriterFirmSearchField from '../../../../../../common/components/form/underwriter-firm-search/UnderwriterFirmSearchField';
import FormikUnsavedChangesGuard from '../../../../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import TableCellOnPlatformIcon from '../../../../components/table-cell-on-platform-icon/TableCellOnPlatformIcon';
import { PnLValues } from '../../common/utils';
import {
  OrderBook_CreateBrokerDealerMutation,
  OrderBook_CreateBrokerDealerMutationVariables,
  OrderBook_DeleteBrokerDealerMutationVariables,
  OrderBook_UpdateBrokerDealerMutationVariables,
} from '../graphql';
import CreateBrokerDealerDialog from './create-broker-dealer-dialog/CreateBrokerDealerDialog';
import {
  BrokerValues,
  getCreateVariables,
  getDeleteVariables,
  getUpdateVariables,
  getValidationSchema,
  isUnderwriterNameVisible,
} from './InternalRetailDemandTableRow.model';

export type Props = Readonly<{
  broker: BrokerValues;
  allBrokers: BrokerValues['broker'][];
  pnlValues: PnLValues;
  offeringId: UUID;
  onCreate: (
    variables: OrderBook_CreateBrokerDealerMutationVariables
  ) => Promise<OrderBook_CreateBrokerDealerMutation | null | undefined>;
  onUpdate: (variables: OrderBook_UpdateBrokerDealerMutationVariables) => Promise<void>;
  onDelete: (variables: OrderBook_DeleteBrokerDealerMutationVariables) => Promise<void>;
  onRemove: () => void;
  onSetValue: (
    values: BrokerValues,
    errors: FormikErrors<BrokerValues>,
    reCalculatePnl: boolean
  ) => void;
  onFocus: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onSaveFinished: () => void;
}>;

const InternalRetailDemandTableRow: React.FC<Props> = ({
  broker,
  allBrokers,
  pnlValues,
  offeringId,
  onCreate,
  onUpdate,
  onDelete,
  onRemove,
  onSetValue,
  onFocus,
  onBlur,
  onSaveFinished,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const formik = useFormik<BrokerValues>({
    initialValues: broker,
    validationSchema: getValidationSchema(allBrokers),
    onSubmit: async (values, { resetForm }) => {
      await handleSubmitForm(values, resetForm);
    },
  });

  const isUnderwriterVisible = React.useMemo(
    () => isUnderwriterNameVisible(formik.values, !formik.errors.broker),
    [formik.values, formik.errors.broker]
  );

  React.useEffect(() => {
    onSetValue(formik.values, formik.errors, false);
  }, [onSetValue, formik.values, formik.errors]);

  const handleSubmitForm = async (
    values: BrokerValues,
    resetForm: (next: Partial<FormikState<BrokerValues>>) => void
  ) => {
    try {
      if (values.id) {
        await onUpdate(getUpdateVariables(offeringId, values));
        resetForm({ values });
      } else {
        const data = await onCreate(getCreateVariables(offeringId, values));
        resetForm({ values: { ...values, id: data?.createMyRetailBroker?.id ?? null } });
      }
    } catch {
      SnackbarManager.error(
        `Failed to auto-save Broker Dealer ${values.broker ? values.broker.firmName : ''}`
      );
    } finally {
      onSetValue(values, formik.errors, true);
    }
  };

  const handleDeleteRow = async () => {
    if (!formik.values.id) {
      onRemove();
      return;
    }
    try {
      await onDelete(getDeleteVariables(offeringId, formik.values));
      onRemove();
    } catch {
      SnackbarManager.error(
        `Failed to delete Broker Dealer ${
          formik.values.broker ? formik.values.broker.firmName : ''
        }`
      );
    }
  };

  const handleStartSave = () => {
    if (!formik.isValid) {
      return;
    }

    setIsLoading(true);
  };
  const handleFinishSave = () => {
    setIsLoading(false);
    onSaveFinished();
  };

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard>
        <TableRow data-testid="demand-data-row" onFocus={onFocus} onBlur={onBlur}>
          <EnableAutoSave saveStarted={handleStartSave} saveFinished={handleFinishSave} />

          <TableCellOnPlatformIcon
            size="small"
            isOnPlatform={isUnderwriterVisible && !!formik.values.broker?.isOnPlatform}
          />
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            {isUnderwriterVisible ? (
              <div>{formik.values.broker?.firmName}</div>
            ) : (
              <UnderwriterFirmSearchField
                name="broker"
                allBrokers={allBrokers}
                disabled={isLoading}
                showHelperTextInTooltip
                noOptionsText="Start typing to load Broker Dealers or Create new"
                CreateFirmDialogComponent={CreateBrokerDealerDialog}
              />
            )}
          </TableCell>
          <TableCell>
            <FormikNumberField
              aria-label="demand shares input"
              name="demandShares"
              decimalScale={0}
              disabled={isLoading}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
          <TableCell>
            <FormikNumberField
              aria-label="demand round lots input"
              name="demandRoundLots"
              decimalScale={0}
              disabled={isLoading}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
          <TableCell>
            <FormikNumberField
              aria-label="retention shares input"
              name="retentionShares"
              decimalScale={0}
              disabled={isLoading}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
          <TableCell>
            <FormikNumberField
              aria-label="retention round lots input"
              name="retentionRoundLots"
              decimalScale={0}
              disabled={isLoading}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
          <TableCell>
            <FormikPercentageField
              aria-label="selling concession received input"
              name="sellingConcessionPercentage"
              decimalScale={2}
              disabled={isLoading}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
          <TableCell align="right">
            {numericUtil.getDisplayValueForCurrency(pnlValues.perShare, 6)}
          </TableCell>
          <TableCell align="right">
            {numericUtil.getDisplayValueForCurrency(pnlValues.receivedByTheFirm, 2)}
          </TableCell>
          <TableCell align="right">
            {numericUtil.getDisplayValueForCurrency(pnlValues.retainedByTheBank, 2)}
          </TableCell>
          <TableCell>
            <IconButton
              color="primary"
              title="Delete Broker Dealer row"
              onClick={handleDeleteRow}
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default InternalRetailDemandTableRow;
