import { CriticalActionDialog } from '@cmg/design-system';
import React from 'react';
import type { InjectedProps } from 'redux-modal';
import { connectModal, hide, show } from 'redux-modal';

type OwnProps = Readonly<{
  offeringName: string | undefined;
  onSubmit: () => void;
}>;

export type Props = OwnProps & InjectedProps;

export const ReleaseFinalAllocationDialog: React.FC<Props> = ({
  offeringName,
  handleHide,
  onSubmit,
  show,
}) => {
  return (
    <CriticalActionDialog
      open={show}
      title={`${offeringName} - Release Final Allocations`}
      alertText={`This action will Release Final Institutional Allocations for ${offeringName}. Allocations will be visible to sales and buy-side XC users. Investors without an allocation entered in the system will automatically be allocated 0 shares.`}
      checkboxLabelText={`I understand that ${offeringName} final allocations will be released`}
      actionButtonText="Release Final Allocations"
      handleClose={handleHide}
      onSubmit={onSubmit}
    />
  );
};

const name = 'ORDERBOOK/FINAL_ALLOCATION_RELEASE_MODAL';
export const openReleaseFinalAllocationDialog = (props: OwnProps) => show(name, props);
export const closeReleaseFinalAllocationDialog = () => hide(name);

export default connectModal({ name })(ReleaseFinalAllocationDialog);
