import { ServiceErrorBanner, ToastManager } from '@cmg/common';
import React from 'react';

import { ServiceErrorPartsFragment } from '../../../../../graphql/fragments';
import ServerErrorsBanner from '../../../../indicators/server-error/ServerErrorsBanner';
import { ModalContent } from '../AdvancedInvestorSearchFlowModal.styles';
import { InvestorFirmSearch_Advanced_InvestorBasicPartsFragment } from '../graphql';
import CreateNewInvestorForm from './CreateNewInvestorForm';
import { useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation } from './graphql';

export type Props = {
  onClose: () => void;
  onApply: (investor: InvestorFirmSearch_Advanced_InvestorBasicPartsFragment) => void;
  goBack: () => void;
};

const CreateNewInvestor: React.FC<Props> = ({ onClose, goBack, onApply }) => {
  const [createNewInvestorServiceError, setCreateNewInvestorServiceError] =
    React.useState<ServiceErrorPartsFragment | null>(null);
  const [createNewInvestor, { loading, error }] =
    useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation({
      onCompleted: ({ createInvestorFirmBasic }) => {
        if (createInvestorFirmBasic.__typename === 'ServiceError') {
          setCreateNewInvestorServiceError(createInvestorFirmBasic);
        } else {
          setCreateNewInvestorServiceError(null);
          ToastManager.success('Successfully created a new investor');
          onApply(createInvestorFirmBasic);
          onClose();
        }
      },
      onError: () => {
        ToastManager.error('Failed to create a new investor. Please try again');
      },
    });

  return (
    <ModalContent direction="column" expand>
      <h3>Create Investor</h3>
      <p>Please provide the details required to create a new investor.</p>
      {error && <ServerErrorsBanner error={error} />}
      {createNewInvestorServiceError && (
        <ServiceErrorBanner error={createNewInvestorServiceError} />
      )}
      <CreateNewInvestorForm
        goBack={goBack}
        onClose={onClose}
        onCreate={input => createNewInvestor({ variables: { input } })}
        submitting={loading}
      />
    </ModalContent>
  );
};

export default CreateNewInvestor;
