import React from 'react';
import { useTheme } from 'styled-components/macro';

import { OfferingStatus } from '../../../../../../../../graphql';
import { offeringStatusLabels } from '../../../../../../../../types/domain/offering/constants';
import { RoadshowOfferingOption } from '../RoadshowOfferingSearch.model';
import { SLabel, SSymbol, StyledIcon, SWrapper } from './RoadshowOfferingOptionRenderer.styles';

export type Props = { option: RoadshowOfferingOption };

const RoadshowOfferingOptionRenderer: React.FC<Props> = ({ option: { label, status, ticker } }) => {
  const theme = useTheme();

  const statusColor = {
    [OfferingStatus.Live]: theme.brand.color.successAlternate,
    [OfferingStatus.Priced]: theme.background.color.menu,
    [OfferingStatus.Filed]: theme.background.color.light,
    [OfferingStatus.Postponed]: theme.background.color.warning,
    [OfferingStatus.Withdrawn]: theme.background.color.negative,
    [OfferingStatus.Terminated]: theme.background.color.negative,
  };

  return (
    <SWrapper>
      <SSymbol>{ticker}</SSymbol>
      <SLabel>{label}</SLabel>
      <SSymbol>{offeringStatusLabels[status]}</SSymbol>
      <StyledIcon name="circle" variant="solid" size="xs" color={statusColor[status]} />
    </SWrapper>
  );
};

export default RoadshowOfferingOptionRenderer;
