import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SNoManagersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

export const StyledNoManagersIcon = styled(Icon).attrs(() => ({
  name: 'users',
  size: '2x',
}))`
  color: ${({ theme }) => theme.text.color.link};
  margin-bottom: 10px;
`;
