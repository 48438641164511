import { useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../common/components/layout/panel/Panel';
import NotesField from '../../common/components/notes-field/NotesField';
import { ExpensesValues } from '../form/ExpensesForm.model';

export type Props = {
  editable: boolean;
};

const NotesPanel: React.FC<Props> = ({ editable }) => {
  const { values } = useFormikContext<ExpensesValues>();

  return (
    <Panel>
      <Panel.Header title="Notes" />
      <Panel.Content>
        <NotesField
          isEditable={editable}
          notesValue={values.notes}
          emptyFieldMessage="No Expenses notes have been entered"
        />
      </Panel.Content>
    </Panel>
  );
};

export default NotesPanel;
