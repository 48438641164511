import React from 'react';

import Table from '../../../../../../common/components/layout/table/Table';
import { Roadshows_EventParticipantsTeamPartsFragment } from '../../../../common/graphql';
import { SHeaderRow, SRow } from './InvestorTeamList.styles';

export type Props = {
  investorTeams: readonly Roadshows_EventParticipantsTeamPartsFragment[];
};

const InvestorTeamList: React.FC<Props> = ({ investorTeams }) => {
  return (
    <Table>
      <thead>
        <SHeaderRow>
          <th>Company</th>
          <th>Participant</th>
        </SHeaderRow>
      </thead>
      <tbody>
        {investorTeams.map(investor => (
          <SRow key={investor.cmgEntityKey}>
            <td>{investor.name}</td>
            <td>
              {investor.participants.map(member => (
                <div key={member.email}>{member.name}</div>
              ))}
            </td>
          </SRow>
        ))}
      </tbody>
    </Table>
  );
};

export default InvestorTeamList;
