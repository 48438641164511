import React from 'react';

import { SField, SLabel, SReadOnlyField } from './SummaryField.styles';

export type Props = {
  label?: string;
  readOnly?: boolean;
};

/**
 * SummaryField
 * flexbox column with label above content
 */
export const SummaryField: React.FC<Props> = ({ label, children, readOnly }) => {
  const Container = readOnly ? SReadOnlyField : SField;

  return (
    <Container>
      {label && <SLabel>{label}</SLabel>}
      {children}
    </Container>
  );
};

export default SummaryField;
