import styled from 'styled-components/macro';

const SGroupHeader = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

const SGroupSpan = styled.span`
  margin-left: 5px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export default Object.assign(SGroupHeader, { Value: SGroupSpan });
