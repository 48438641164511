import offeringSidePanelBuySideRouteFactory from '../../offeringSidePanelBuySideRouteFactory';

export const getNotesAndOrdersAndCommunicatedDemandText = ({
  hasNotes,
  hasOrders,
  hasCommunicatedDemand,
}: {
  hasNotes: boolean;
  hasOrders: boolean;
  hasCommunicatedDemand: boolean;
}) => {
  const vals = [
    hasNotes ? 'notes' : '',
    hasOrders ? 'orders' : '',
    hasCommunicatedDemand ? 'communicated demand' : '',
  ];
  return new Intl.ListFormat('en', { style: 'long', type: 'conjunction' }).format(
    vals.filter(val => val)
  );
};

export const buildOfferingSidePanelLink = ({
  actualBaseUrl,
  offeringId,
  hasNotes,
  isObsoleteOffering,
}: {
  actualBaseUrl: string;
  offeringId: string;
  hasNotes: boolean;
  isObsoleteOffering: boolean;
}) => {
  const urlParts = actualBaseUrl.split('/');

  urlParts.splice(-1, 1, offeringId);

  if (isObsoleteOffering) {
    return (
      urlParts.join('/') +
      (hasNotes
        ? offeringSidePanelBuySideRouteFactory.offeringNotes.getUrlPath()
        : offeringSidePanelBuySideRouteFactory.orderBook.getUrlPath())
    );
  }
  return urlParts.join('/') + offeringSidePanelBuySideRouteFactory.offeringDetails.getUrlPath();
};
