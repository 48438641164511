import { Grid, Typography } from '@cmg/design-system';
import React from 'react';

export type Props<TValue> = Readonly<{
  label: string | React.ReactNode;
  value: TValue | null | undefined;
  formatter?: (value: TValue) => string | null;
}>;

const GridRowPair = <TValue,>({ label, value, formatter }: Props<TValue>) => {
  const formattedValue = React.useMemo(() => {
    if (value) {
      return formatter ? formatter(value) : value;
    }
    return '-';
  }, [formatter, value]);

  return (
    <Grid container>
      <Grid item xs={5}>
        {typeof label === 'string' ? (
          <Typography color="text.secondary">{label}</Typography>
        ) : (
          label
        )}
      </Grid>
      <Grid item xs={7}>
        {formattedValue}
      </Grid>
    </Grid>
  );
};

export default GridRowPair;
