import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LoadingButton,
  Stack,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../../graphql';
import {
  getUpdatedAmounts,
  MyRetailDemandBulkSaveValues,
} from '../BulkEditInternalRetailDemandView.model';

export type Props = Readonly<{
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  rows: readonly OrderBook_RetailBrokerDealerPartsFragment[];
  values: MyRetailDemandBulkSaveValues;
}>;

const UpdateRetailDemandDialog: React.FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  isSubmitting,
  rows,
  values,
}) => {
  const {
    totalInitialDemandShares,
    totalInitialDemandRoundLots,
    totalUpdatedDemandShares,
    totalUpdatedDemandRoundLots,
  } = React.useMemo(() => getUpdatedAmounts(rows, values), [rows, values]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Update Retail Demand</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <div>Are you sure you wat to submit the following changes to the settlement agent</div>

          <Grid container rowGap={2}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                Change From
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                to
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                Total Retail Demand
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {totalInitialDemandShares ?? 0} shares
            </Grid>
            <Grid item xs={4}>
              {totalUpdatedDemandShares ?? 0} shares
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" color="text.secondary">
                Round Lots
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {totalInitialDemandRoundLots ?? 0} Lots
            </Grid>
            <Grid item xs={4}>
              {totalUpdatedDemandRoundLots ?? 0} Lots
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={onSubmit} loading={isSubmitting}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateRetailDemandDialog;
