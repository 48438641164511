/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingAndDeliveryPaymentFragment = {
  readonly __typename?: 'BillingAndDeliveryPayment';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly payment: number;
  readonly billAndDeliveryShares: number;
};

export type BillAndDeliverPaymentsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type BillAndDeliverPaymentsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly billAndDeliverPayments: ReadonlyArray<{
      readonly __typename?: 'BillingAndDeliveryPayment';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly payment: number;
      readonly billAndDeliveryShares: number;
    }>;
  };
};

export type SyndicateWires_MyRetailForBrokerDealerPartsFragment = {
  readonly __typename?: 'MyRetailForBrokerDealer';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly name: string;
  readonly retentionAmount: number;
  readonly sellingConcession?: number | null;
};

export type SyndicateWires_BrokerDealersMyRetailQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_BrokerDealersMyRetailQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly brokerDealersMyRetail: ReadonlyArray<{
      readonly __typename?: 'MyRetailForBrokerDealer';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
      readonly retentionAmount: number;
      readonly sellingConcession?: number | null;
    }>;
  };
};

export type SyndicateWires_BrokerDealerMyRetailFeesPartsFragment = {
  readonly __typename?: 'BrokerDealerMyRetailFees';
  readonly id: string;
  readonly brokerCmgEntityKey: string;
  readonly payment?: number | null;
};

export type SyndicateWires_BrokerDealersMyRetailFeesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_BrokerDealersMyRetailFeesQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly brokerDealersMyRetailFees: ReadonlyArray<{
      readonly __typename?: 'BrokerDealerMyRetailFees';
      readonly id: string;
      readonly brokerCmgEntityKey: string;
      readonly payment?: number | null;
    }>;
  };
};

export type SyndicateWires_CustomVariableGroupPartsFragment = {
  readonly __typename?: 'CustomVariableGroup';
  readonly id: string;
  readonly name: string;
  readonly displayName: string;
  readonly variables: ReadonlyArray<{
    readonly __typename?: 'CustomVariable';
    readonly id: string;
    readonly name: string;
    readonly displayName: string;
    readonly value?: string | null;
  }>;
};

export type SyndicateWires_CustomVariablesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  wireTemplateType: Types.WireTemplateType;
}>;

export type SyndicateWires_CustomVariablesQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly customVariables: ReadonlyArray<{
      readonly __typename?: 'CustomVariableGroup';
      readonly id: string;
      readonly name: string;
      readonly displayName: string;
      readonly variables: ReadonlyArray<{
        readonly __typename?: 'CustomVariable';
        readonly id: string;
        readonly name: string;
        readonly displayName: string;
        readonly value?: string | null;
      }>;
    }>;
  };
};

export type SyndicateWires_DeleteWirePartsFragment = {
  readonly __typename?: 'DeleteWire';
  readonly id: string;
};

export type SyndicateWires_ManagerDtcNumbersQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_ManagerDtcNumbersQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly managerDtcNumbers: ReadonlyArray<{
      readonly __typename?: 'ManagerDtcNumber';
      readonly id: string;
      readonly managerKey: string;
      readonly managerDtcNumber?: string | null;
    }>;
  };
};

export type SyndicateWires_ManagerRetailRetentionFeesPartsFragment = {
  readonly __typename?: 'ManagerRetailRetentionFees';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly retention?: number | null;
  readonly payment?: number | null;
};

export type SyndicateWires_ManagerRetailRetentionFeesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_ManagerRetailRetentionFeesQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly managerRetailRetentionFees: ReadonlyArray<{
      readonly __typename?: 'ManagerRetailRetentionFees';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly retention?: number | null;
      readonly payment?: number | null;
    }>;
  };
};

export type SyndicateWires_ManagerRevisedUnderwritingCommitmentPartsFragment = {
  readonly __typename?: 'ManagerRevisedUnderwritingCommitment';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly revisedUnderwritingCommitment?: number | null;
};

export type SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly managerRevisedUnderwritingCommitment: ReadonlyArray<{
      readonly __typename?: 'ManagerRevisedUnderwritingCommitment';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly revisedUnderwritingCommitment?: number | null;
    }>;
  };
};

export type SyndicateWires_ManagerValidationPartsFragment = {
  readonly __typename?: 'ManagerValidation';
  readonly cmgEntityKey: string;
  readonly fields: ReadonlyArray<Types.ManagerErrorType>;
};

export type SyndicateWires_SyndicateWiresManagerListQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SyndicateWiresManagerListQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'SyndicateWiresManager';
      readonly id: string;
      readonly firmName: string;
      readonly role: Types.SyndicateWiresManagerRole;
      readonly cmgEntityKey: string;
      readonly economics?: number | null;
    }>;
  };
};

export type SyndicateWires_SyndicateWiresManagerPartsFragment = {
  readonly __typename?: 'SyndicateWiresManager';
  readonly id: string;
  readonly firmName: string;
  readonly role: Types.SyndicateWiresManagerRole;
  readonly cmgEntityKey: string;
  readonly economics?: number | null;
};

export type SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SyndicateWiresRoute_NotAuthorQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
  };
};

export type SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SyndicateWiresRoute_IsAuthorQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly type: Types.OfferingType;
  };
};

export type SyndicateWires_FirmSnapshotPartsFragment = {
  readonly __typename?: 'FirmSnapshot';
  readonly cmgEntityKey: string;
  readonly firmName: string;
  readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
};

export type SyndicateWires_WireMessagePartsFragment = {
  readonly __typename?: 'WireMessage';
  readonly id: string;
  readonly emailAddresses: ReadonlyArray<string>;
  readonly expiredAt?: string | null;
  readonly respondedAt?: string | null;
  readonly responseStatus?: Types.SentWireResponseStatus | null;
  readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
  readonly sentAt?: string | null;
  readonly htmlBody: string;
  readonly firmSnapshot?: {
    readonly __typename?: 'FirmSnapshot';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
  } | null;
};

export type SyndicateWires_WireMessageFragment = {
  readonly __typename?: 'WireMessageLimited';
  readonly id: string;
  readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
};

export type SyndicateWires_WireFragment = {
  readonly __typename?: 'Wire';
  readonly id: string;
  readonly templateType: Types.WireTemplateType;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessageLimited';
    readonly id: string;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
  }>;
};

export type SyndicateWires_WiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  isNonSyndicate: Types.Scalars['Boolean'];
  includeOnlyLatestWires?: Types.InputMaybe<Types.Scalars['Boolean']>;
  where?: Types.InputMaybe<Types.WireReadDtoFilterInput>;
}>;

export type SyndicateWires_WiresQuery = {
  readonly __typename?: 'Query';
  readonly wires: ReadonlyArray<{
    readonly __typename?: 'Wire';
    readonly id: string;
    readonly templateType: Types.WireTemplateType;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessageLimited';
      readonly id: string;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    }>;
  }>;
};

export type SyndicateWires_WiresDisclaimerQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  offeringType: Types.OfferingType;
  templateType: Types.WireTemplateType;
}>;

export type SyndicateWires_WiresDisclaimerQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly wiresDisclaimer?: string | null;
  };
};

export const BillingAndDeliveryPaymentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingAndDeliveryPayment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingAndDeliveryPayment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billAndDeliveryShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_MyRetailForBrokerDealerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_MyRetailForBrokerDealerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyRetailForBrokerDealer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retentionAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_BrokerDealerMyRetailFeesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_BrokerDealerMyRetailFeesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BrokerDealerMyRetailFees' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brokerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CustomVariableGroupPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_CustomVariableGroupParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomVariableGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_DeleteWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_ManagerRetailRetentionFeesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRetailRetentionFeesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerRetailRetentionFees' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_ManagerRevisedUnderwritingCommitmentPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRevisedUnderwritingCommitmentParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerRevisedUnderwritingCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revisedUnderwritingCommitment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_ManagerValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_SyndicateWiresManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateWiresManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_FirmSnapshotPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_WireMessagePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_WireMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessageLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_WireFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_Wire' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessageLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const BillAndDeliverPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillAndDeliverPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billAndDeliverPayments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BillingAndDeliveryPayment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingAndDeliveryPayment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BillingAndDeliveryPayment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billAndDeliveryShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useBillAndDeliverPaymentsQuery__
 *
 * To run a query within a React component, call `useBillAndDeliverPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillAndDeliverPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillAndDeliverPaymentsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useBillAndDeliverPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillAndDeliverPaymentsQuery,
    BillAndDeliverPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillAndDeliverPaymentsQuery, BillAndDeliverPaymentsQueryVariables>(
    BillAndDeliverPaymentsDocument,
    options
  );
}
export function useBillAndDeliverPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillAndDeliverPaymentsQuery,
    BillAndDeliverPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillAndDeliverPaymentsQuery, BillAndDeliverPaymentsQueryVariables>(
    BillAndDeliverPaymentsDocument,
    options
  );
}
export type BillAndDeliverPaymentsQueryHookResult = ReturnType<
  typeof useBillAndDeliverPaymentsQuery
>;
export type BillAndDeliverPaymentsLazyQueryHookResult = ReturnType<
  typeof useBillAndDeliverPaymentsLazyQuery
>;
export type BillAndDeliverPaymentsQueryResult = Apollo.QueryResult<
  BillAndDeliverPaymentsQuery,
  BillAndDeliverPaymentsQueryVariables
>;
export const SyndicateWires_BrokerDealersMyRetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_BrokerDealersMyRetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brokerDealersMyRetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SyndicateWires_MyRetailForBrokerDealerParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_MyRetailForBrokerDealerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyRetailForBrokerDealer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retentionAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_BrokerDealersMyRetailQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_BrokerDealersMyRetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_BrokerDealersMyRetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_BrokerDealersMyRetailQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_BrokerDealersMyRetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_BrokerDealersMyRetailQuery,
    SyndicateWires_BrokerDealersMyRetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_BrokerDealersMyRetailQuery,
    SyndicateWires_BrokerDealersMyRetailQueryVariables
  >(SyndicateWires_BrokerDealersMyRetailDocument, options);
}
export function useSyndicateWires_BrokerDealersMyRetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_BrokerDealersMyRetailQuery,
    SyndicateWires_BrokerDealersMyRetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_BrokerDealersMyRetailQuery,
    SyndicateWires_BrokerDealersMyRetailQueryVariables
  >(SyndicateWires_BrokerDealersMyRetailDocument, options);
}
export type SyndicateWires_BrokerDealersMyRetailQueryHookResult = ReturnType<
  typeof useSyndicateWires_BrokerDealersMyRetailQuery
>;
export type SyndicateWires_BrokerDealersMyRetailLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_BrokerDealersMyRetailLazyQuery
>;
export type SyndicateWires_BrokerDealersMyRetailQueryResult = Apollo.QueryResult<
  SyndicateWires_BrokerDealersMyRetailQuery,
  SyndicateWires_BrokerDealersMyRetailQueryVariables
>;
export const SyndicateWires_BrokerDealersMyRetailFeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_BrokerDealersMyRetailFees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brokerDealersMyRetailFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SyndicateWires_BrokerDealerMyRetailFeesParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_BrokerDealerMyRetailFeesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BrokerDealerMyRetailFees' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brokerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_BrokerDealersMyRetailFeesQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_BrokerDealersMyRetailFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_BrokerDealersMyRetailFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_BrokerDealersMyRetailFeesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_BrokerDealersMyRetailFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_BrokerDealersMyRetailFeesQuery,
    SyndicateWires_BrokerDealersMyRetailFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_BrokerDealersMyRetailFeesQuery,
    SyndicateWires_BrokerDealersMyRetailFeesQueryVariables
  >(SyndicateWires_BrokerDealersMyRetailFeesDocument, options);
}
export function useSyndicateWires_BrokerDealersMyRetailFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_BrokerDealersMyRetailFeesQuery,
    SyndicateWires_BrokerDealersMyRetailFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_BrokerDealersMyRetailFeesQuery,
    SyndicateWires_BrokerDealersMyRetailFeesQueryVariables
  >(SyndicateWires_BrokerDealersMyRetailFeesDocument, options);
}
export type SyndicateWires_BrokerDealersMyRetailFeesQueryHookResult = ReturnType<
  typeof useSyndicateWires_BrokerDealersMyRetailFeesQuery
>;
export type SyndicateWires_BrokerDealersMyRetailFeesLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_BrokerDealersMyRetailFeesLazyQuery
>;
export type SyndicateWires_BrokerDealersMyRetailFeesQueryResult = Apollo.QueryResult<
  SyndicateWires_BrokerDealersMyRetailFeesQuery,
  SyndicateWires_BrokerDealersMyRetailFeesQueryVariables
>;
export const SyndicateWires_CustomVariablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_CustomVariables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wireTemplateType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WireTemplateType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customVariables' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'wireTemplateType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'wireTemplateType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SyndicateWires_CustomVariableGroupParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_CustomVariableGroupParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomVariableGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variables' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_CustomVariablesQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_CustomVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CustomVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_CustomVariablesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      wireTemplateType: // value for 'wireTemplateType'
 *   },
 * });
 */
export function useSyndicateWires_CustomVariablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_CustomVariablesQuery,
    SyndicateWires_CustomVariablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_CustomVariablesQuery,
    SyndicateWires_CustomVariablesQueryVariables
  >(SyndicateWires_CustomVariablesDocument, options);
}
export function useSyndicateWires_CustomVariablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_CustomVariablesQuery,
    SyndicateWires_CustomVariablesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_CustomVariablesQuery,
    SyndicateWires_CustomVariablesQueryVariables
  >(SyndicateWires_CustomVariablesDocument, options);
}
export type SyndicateWires_CustomVariablesQueryHookResult = ReturnType<
  typeof useSyndicateWires_CustomVariablesQuery
>;
export type SyndicateWires_CustomVariablesLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_CustomVariablesLazyQuery
>;
export type SyndicateWires_CustomVariablesQueryResult = Apollo.QueryResult<
  SyndicateWires_CustomVariablesQuery,
  SyndicateWires_CustomVariablesQueryVariables
>;
export const SyndicateWires_ManagerDtcNumbersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerDtcNumbers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerDtcNumbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managerKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managerDtcNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_ManagerDtcNumbersQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_ManagerDtcNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_ManagerDtcNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_ManagerDtcNumbersQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_ManagerDtcNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_ManagerDtcNumbersQuery,
    SyndicateWires_ManagerDtcNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_ManagerDtcNumbersQuery,
    SyndicateWires_ManagerDtcNumbersQueryVariables
  >(SyndicateWires_ManagerDtcNumbersDocument, options);
}
export function useSyndicateWires_ManagerDtcNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_ManagerDtcNumbersQuery,
    SyndicateWires_ManagerDtcNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_ManagerDtcNumbersQuery,
    SyndicateWires_ManagerDtcNumbersQueryVariables
  >(SyndicateWires_ManagerDtcNumbersDocument, options);
}
export type SyndicateWires_ManagerDtcNumbersQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerDtcNumbersQuery
>;
export type SyndicateWires_ManagerDtcNumbersLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerDtcNumbersLazyQuery
>;
export type SyndicateWires_ManagerDtcNumbersQueryResult = Apollo.QueryResult<
  SyndicateWires_ManagerDtcNumbersQuery,
  SyndicateWires_ManagerDtcNumbersQueryVariables
>;
export const SyndicateWires_ManagerRetailRetentionFeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRetailRetentionFees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerRetailRetentionFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SyndicateWires_ManagerRetailRetentionFeesParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRetailRetentionFeesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerRetailRetentionFees' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_ManagerRetailRetentionFeesQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_ManagerRetailRetentionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_ManagerRetailRetentionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_ManagerRetailRetentionFeesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_ManagerRetailRetentionFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_ManagerRetailRetentionFeesQuery,
    SyndicateWires_ManagerRetailRetentionFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_ManagerRetailRetentionFeesQuery,
    SyndicateWires_ManagerRetailRetentionFeesQueryVariables
  >(SyndicateWires_ManagerRetailRetentionFeesDocument, options);
}
export function useSyndicateWires_ManagerRetailRetentionFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_ManagerRetailRetentionFeesQuery,
    SyndicateWires_ManagerRetailRetentionFeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_ManagerRetailRetentionFeesQuery,
    SyndicateWires_ManagerRetailRetentionFeesQueryVariables
  >(SyndicateWires_ManagerRetailRetentionFeesDocument, options);
}
export type SyndicateWires_ManagerRetailRetentionFeesQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerRetailRetentionFeesQuery
>;
export type SyndicateWires_ManagerRetailRetentionFeesLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerRetailRetentionFeesLazyQuery
>;
export type SyndicateWires_ManagerRetailRetentionFeesQueryResult = Apollo.QueryResult<
  SyndicateWires_ManagerRetailRetentionFeesQuery,
  SyndicateWires_ManagerRetailRetentionFeesQueryVariables
>;
export const SyndicateWires_ManagerRevisedUnderwritingCommitmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRevisedUnderwritingCommitment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerRevisedUnderwritingCommitment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SyndicateWires_ManagerRevisedUnderwritingCommitmentParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerRevisedUnderwritingCommitmentParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerRevisedUnderwritingCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revisedUnderwritingCommitment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery,
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery,
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables
  >(SyndicateWires_ManagerRevisedUnderwritingCommitmentDocument, options);
}
export function useSyndicateWires_ManagerRevisedUnderwritingCommitmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery,
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery,
    SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables
  >(SyndicateWires_ManagerRevisedUnderwritingCommitmentDocument, options);
}
export type SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery
>;
export type SyndicateWires_ManagerRevisedUnderwritingCommitmentLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_ManagerRevisedUnderwritingCommitmentLazyQuery
>;
export type SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryResult = Apollo.QueryResult<
  SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery,
  SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables
>;
export const SyndicateWires_SyndicateWiresManagerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresManagerList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresManagerParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateWiresManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SyndicateWiresManagerListQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SyndicateWiresManagerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SyndicateWiresManagerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SyndicateWiresManagerListQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_SyndicateWiresManagerListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SyndicateWiresManagerListQuery,
    SyndicateWires_SyndicateWiresManagerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SyndicateWiresManagerListQuery,
    SyndicateWires_SyndicateWiresManagerListQueryVariables
  >(SyndicateWires_SyndicateWiresManagerListDocument, options);
}
export function useSyndicateWires_SyndicateWiresManagerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SyndicateWiresManagerListQuery,
    SyndicateWires_SyndicateWiresManagerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SyndicateWiresManagerListQuery,
    SyndicateWires_SyndicateWiresManagerListQueryVariables
  >(SyndicateWires_SyndicateWiresManagerListDocument, options);
}
export type SyndicateWires_SyndicateWiresManagerListQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresManagerListQuery
>;
export type SyndicateWires_SyndicateWiresManagerListLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresManagerListLazyQuery
>;
export type SyndicateWires_SyndicateWiresManagerListQueryResult = Apollo.QueryResult<
  SyndicateWires_SyndicateWiresManagerListQuery,
  SyndicateWires_SyndicateWiresManagerListQueryVariables
>;
export const SyndicateWires_SyndicateWiresRoute_NotAuthorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresRoute_NotAuthor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offering' },
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables
  >(SyndicateWires_SyndicateWiresRoute_NotAuthorDocument, options);
}
export function useSyndicateWires_SyndicateWiresRoute_NotAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables
  >(SyndicateWires_SyndicateWiresRoute_NotAuthorDocument, options);
}
export type SyndicateWires_SyndicateWiresRoute_NotAuthorQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery
>;
export type SyndicateWires_SyndicateWiresRoute_NotAuthorLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresRoute_NotAuthorLazyQuery
>;
export type SyndicateWires_SyndicateWiresRoute_NotAuthorQueryResult = Apollo.QueryResult<
  SyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
  SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables
>;
export const SyndicateWires_SyndicateWiresRoute_IsAuthorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SyndicateWiresRoute_IsAuthor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables
  >(SyndicateWires_SyndicateWiresRoute_IsAuthorDocument, options);
}
export function useSyndicateWires_SyndicateWiresRoute_IsAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
    SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables
  >(SyndicateWires_SyndicateWiresRoute_IsAuthorDocument, options);
}
export type SyndicateWires_SyndicateWiresRoute_IsAuthorQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery
>;
export type SyndicateWires_SyndicateWiresRoute_IsAuthorLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SyndicateWiresRoute_IsAuthorLazyQuery
>;
export type SyndicateWires_SyndicateWiresRoute_IsAuthorQueryResult = Apollo.QueryResult<
  SyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
  SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables
>;
export const SyndicateWires_WiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_Wires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isNonSyndicate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeOnlyLatestWires' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'WireReadDtoFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isNonSyndicate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isNonSyndicate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeOnlyLatestWires' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeOnlyLatestWires' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'templateType' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SyndicateWires_Wire' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessageLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_Wire' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Wire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_WiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_WiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_WiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_WiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      isNonSyndicate: // value for 'isNonSyndicate'
 *      includeOnlyLatestWires: // value for 'includeOnlyLatestWires'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSyndicateWires_WiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_WiresQuery,
    SyndicateWires_WiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyndicateWires_WiresQuery, SyndicateWires_WiresQueryVariables>(
    SyndicateWires_WiresDocument,
    options
  );
}
export function useSyndicateWires_WiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_WiresQuery,
    SyndicateWires_WiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyndicateWires_WiresQuery, SyndicateWires_WiresQueryVariables>(
    SyndicateWires_WiresDocument,
    options
  );
}
export type SyndicateWires_WiresQueryHookResult = ReturnType<typeof useSyndicateWires_WiresQuery>;
export type SyndicateWires_WiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_WiresLazyQuery
>;
export type SyndicateWires_WiresQueryResult = Apollo.QueryResult<
  SyndicateWires_WiresQuery,
  SyndicateWires_WiresQueryVariables
>;
export const SyndicateWires_WiresDisclaimerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_WiresDisclaimer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WireTemplateType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wiresDisclaimer' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offeringType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringType' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'templateType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'templateType' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_WiresDisclaimerQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_WiresDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_WiresDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_WiresDisclaimerQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      offeringType: // value for 'offeringType'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useSyndicateWires_WiresDisclaimerQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_WiresDisclaimerQuery,
    SyndicateWires_WiresDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_WiresDisclaimerQuery,
    SyndicateWires_WiresDisclaimerQueryVariables
  >(SyndicateWires_WiresDisclaimerDocument, options);
}
export function useSyndicateWires_WiresDisclaimerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_WiresDisclaimerQuery,
    SyndicateWires_WiresDisclaimerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_WiresDisclaimerQuery,
    SyndicateWires_WiresDisclaimerQueryVariables
  >(SyndicateWires_WiresDisclaimerDocument, options);
}
export type SyndicateWires_WiresDisclaimerQueryHookResult = ReturnType<
  typeof useSyndicateWires_WiresDisclaimerQuery
>;
export type SyndicateWires_WiresDisclaimerLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_WiresDisclaimerLazyQuery
>;
export type SyndicateWires_WiresDisclaimerQueryResult = Apollo.QueryResult<
  SyndicateWires_WiresDisclaimerQuery,
  SyndicateWires_WiresDisclaimerQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeBillAndDeliverPaymentsMockResponse = makeMockResponse<
  BillAndDeliverPaymentsQueryVariables,
  BillAndDeliverPaymentsQuery
>(BillAndDeliverPaymentsDocument);

export const makeSyndicateWires_BrokerDealersMyRetailMockResponse = makeMockResponse<
  SyndicateWires_BrokerDealersMyRetailQueryVariables,
  SyndicateWires_BrokerDealersMyRetailQuery
>(SyndicateWires_BrokerDealersMyRetailDocument);

export const makeSyndicateWires_BrokerDealersMyRetailFeesMockResponse = makeMockResponse<
  SyndicateWires_BrokerDealersMyRetailFeesQueryVariables,
  SyndicateWires_BrokerDealersMyRetailFeesQuery
>(SyndicateWires_BrokerDealersMyRetailFeesDocument);

export const makeSyndicateWires_CustomVariablesMockResponse = makeMockResponse<
  SyndicateWires_CustomVariablesQueryVariables,
  SyndicateWires_CustomVariablesQuery
>(SyndicateWires_CustomVariablesDocument);

export const makeSyndicateWires_ManagerDtcNumbersMockResponse = makeMockResponse<
  SyndicateWires_ManagerDtcNumbersQueryVariables,
  SyndicateWires_ManagerDtcNumbersQuery
>(SyndicateWires_ManagerDtcNumbersDocument);

export const makeSyndicateWires_ManagerRetailRetentionFeesMockResponse = makeMockResponse<
  SyndicateWires_ManagerRetailRetentionFeesQueryVariables,
  SyndicateWires_ManagerRetailRetentionFeesQuery
>(SyndicateWires_ManagerRetailRetentionFeesDocument);

export const makeSyndicateWires_ManagerRevisedUnderwritingCommitmentMockResponse = makeMockResponse<
  SyndicateWires_ManagerRevisedUnderwritingCommitmentQueryVariables,
  SyndicateWires_ManagerRevisedUnderwritingCommitmentQuery
>(SyndicateWires_ManagerRevisedUnderwritingCommitmentDocument);

export const makeSyndicateWires_SyndicateWiresManagerListMockResponse = makeMockResponse<
  SyndicateWires_SyndicateWiresManagerListQueryVariables,
  SyndicateWires_SyndicateWiresManagerListQuery
>(SyndicateWires_SyndicateWiresManagerListDocument);

export const makeSyndicateWires_SyndicateWiresRoute_NotAuthorMockResponse = makeMockResponse<
  SyndicateWires_SyndicateWiresRoute_NotAuthorQueryVariables,
  SyndicateWires_SyndicateWiresRoute_NotAuthorQuery
>(SyndicateWires_SyndicateWiresRoute_NotAuthorDocument);

export const makeSyndicateWires_SyndicateWiresRoute_IsAuthorMockResponse = makeMockResponse<
  SyndicateWires_SyndicateWiresRoute_IsAuthorQueryVariables,
  SyndicateWires_SyndicateWiresRoute_IsAuthorQuery
>(SyndicateWires_SyndicateWiresRoute_IsAuthorDocument);

export const makeSyndicateWires_WiresMockResponse = makeMockResponse<
  SyndicateWires_WiresQueryVariables,
  SyndicateWires_WiresQuery
>(SyndicateWires_WiresDocument);

export const makeSyndicateWires_WiresDisclaimerMockResponse = makeMockResponse<
  SyndicateWires_WiresDisclaimerQueryVariables,
  SyndicateWires_WiresDisclaimerQuery
>(SyndicateWires_WiresDisclaimerDocument);
