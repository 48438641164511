import { ISODate } from '@cmg/common';
import { format, isValid, max, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import compact from 'lodash/compact';
import * as yup from 'yup';

/**
 * getMaxISODate
 * given an ISODate[], return the max of those dates
 */
export const getMaxISODate = (dates: Array<ISODate | undefined | null>): ISODate | undefined => {
  // filter out undefined, null, or invalid dates
  const available: Date[] = compact<ISODate>(dates)
    .map(d => parseISO(d))
    .filter(d => isValid(d));

  if (available.length > 0) {
    // max() returns the latest of the given dates
    return format(max(available), 'yyyy-MM-dd');
  }

  return undefined;
};

/**
 * TimingForm field labels for creating yup error messages
 */
export const timingFieldLabels = {
  confidentialFilingDate: 'Confidential Filing Date',
  publicFilingDate: 'Public Filing Date',
  launchDate: 'Launch Date',
  booksCloseAtField: 'Books Close At',
  pricingDate: 'Pricing Date',
  firstTradeDate: 'First Trade Date',
  settlementDate: 'Settlement Date',
};

/**
 * yup.date().min() is not chainable -
 * returns custom config for yup test()
 * exclusive=false so we can chain min() together
 */
export const testMinDate = ({ ref }: { ref: string }) => ({
  exclusive: false,
  test: function (this: typeof TimingSchema, value) {
    // get other data in form by resolving ref
    const minDate = this.resolve(yup.ref(ref));

    // if dates don't exist or one is null, don't validate
    if (!minDate || !value) {
      return true;
    }

    // manually check validation on dates using min()
    // yup.min() will take an ISO string and cast it with new Date()
    return (
      yup.date().min(minDate).isValidSync(value) ||
      this.createError({
        message: `Date must occur after ${timingFieldLabels[ref]}`,
      })
    );
  },
});

export const TimingSchema = yup.object().shape({
  confidentialFilingDate: yup.date().nullable(),
  publicFilingDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' })),
  launchDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' })),
  booksCloseAtField: yup
    .date()
    .nullable()
    .transform(fieldValue => {
      // simple transform to grab booksCloseAtField.date for validation
      // DateTimePickerField UTC datetime --> local time and only YYYY-MM-DD (remove time)
      return fieldValue.date
        ? new Date(utcToZonedTime(fieldValue.date, fieldValue.timezone).toDateString())
        : null;
    })
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' }))
    .test(testMinDate({ ref: 'launchDate' })),
  pricingDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' }))
    .test(testMinDate({ ref: 'launchDate' }))
    .test(testMinDate({ ref: 'booksCloseAtField' })),
  firstTradeDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' }))
    .test(testMinDate({ ref: 'launchDate' }))
    .test(testMinDate({ ref: 'booksCloseAtField' }))
    .test(testMinDate({ ref: 'pricingDate' })),
  tradeDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' }))
    .test(testMinDate({ ref: 'launchDate' }))
    .test(testMinDate({ ref: 'booksCloseAtField' }))
    .test(testMinDate({ ref: 'pricingDate' })),
  settlementDate: yup
    .date()
    .nullable()
    .test(testMinDate({ ref: 'confidentialFilingDate' }))
    .test(testMinDate({ ref: 'publicFilingDate' }))
    .test(testMinDate({ ref: 'launchDate' }))
    .test(testMinDate({ ref: 'booksCloseAtField' }))
    .test(testMinDate({ ref: 'pricingDate' }))
    .test(testMinDate({ ref: 'firstTradeDate' })),
});
