import { urlUtil } from '@cmg/common';

import routeFactory from '../../common/util/routeFactory';

const root = {
  routePath: `${routeFactory.offeringSidePanel.routePath}/`,
  getUrlPath: () => `/`,
};

const offeringDetails = {
  routePath: `${routeFactory.offeringSidePanel.routePath}/details`,
  getUrlPath: () => `/details`,
};

const orderBook = {
  routePath: `${routeFactory.offeringSidePanel.routePath}/order-book`,
  getUrlPath: () => '/order-book',
};

const orderBookNewIndication = {
  routePath: `${orderBook.routePath}/indication-activity/new`,
  getUrlPath: () => {
    return '/order-book/indication-activity/new';
  },
};

const orderBookUpdateHistory = {
  routePath: `${orderBook.routePath}/update-history`,
  getUrlPath: (props: { cmgEntityKey: string }) => {
    return `/order-book/update-history${urlUtil.queryStringify({
      cmgEntityKey: props.cmgEntityKey,
    })}`;
  },
};

const orderBookIndicationActivity = {
  routePath: `${orderBook.routePath}/indication-activity`,
  getUrlPath: (props?: { cmgEntityKey: string }) => {
    const queryParam = props?.cmgEntityKey
      ? urlUtil.queryStringify({ cmgEntityKey: props.cmgEntityKey })
      : '';

    return `/order-book/indication-activity${queryParam}`;
  },
};

const orderBookCompliance = {
  routePath: `${orderBook.routePath}/compliance`,
  getUrlPath: () => `/order-book/compliance`,
};

const internalDemand = {
  routePath: `${orderBook.routePath}/my-internal-demand`,
  getUrlPath: () => '/order-book/my-internal-demand',
};

const orderAllocation = {
  routePath: `${routeFactory.offeringSidePanel.routePath}/order-allocation`,
  getUrlPath: () => `/order-allocation`,
};

const communicatedDemand = {
  routePath: `${orderAllocation.routePath}/communicated-demand`,
  getUrlPath: () => '/order-allocation/communicated-demand',
};

const offeringNotes = {
  routePath: `${routeFactory.offeringSidePanel.routePath}/offering_notes`,
  getUrlPath: () => `/offering_notes`,
};

const offeringSidePanelBuySideRoutes = {
  root,
  offeringDetails,
  orderBook,
  orderBookNewIndication,
  orderBookUpdateHistory,
  orderBookIndicationActivity,
  orderBookCompliance,
  orderAllocation,
  communicatedDemand,
  internalDemand,
  offeringNotes,
};

export default offeringSidePanelBuySideRoutes;
