import { LinkButton, Popover } from '@cmg/common';
import React from 'react';

import { OfferingSetup_Validation_OfferingErrorFragment } from '../../graphql';
import {
  SCard,
  SContent,
  SError,
  SErrors,
  SHeader,
  STitle,
  StyledIcon,
} from './SummaryCard.styles';

export type Props = {
  title?: string;
  withBorder?: boolean;
  withFill?: boolean;
  onEdit?: () => void;
  errors?: OfferingSetup_Validation_OfferingErrorFragment[];
};

/**
 * SummaryCard
 * wraps content in a card with title and edit button
 */
export const SummaryCard: React.FC<Props> = ({
  title,
  withBorder,
  withFill,
  onEdit,
  errors,
  children,
}) => (
  <SCard>
    <SHeader>
      {title && <STitle>{title}</STitle>}
      {onEdit && (
        <LinkButton iconLeft={{ name: 'edit' }} onClick={onEdit}>
          Edit
        </LinkButton>
      )}
      {errors && errors.length > 0 && (
        <Popover
          placement="bottomLeft"
          variant="DARK"
          content={
            <SErrors>
              {errors.map((e, i) => (
                <li key={i}>{e.description}</li>
              ))}
            </SErrors>
          }
        >
          <SError>
            <StyledIcon name="times-circle" variant="solid" />
            {`${errors.length} Error${errors.length > 1 ? 's' : ''}`}
          </SError>
        </Popover>
      )}
    </SHeader>
    <SContent withBorder={withBorder} withFill={withFill}>
      {children}
    </SContent>
  </SCard>
);

export default SummaryCard;
