import { Typography, TypographyProps } from '@cmg/design-system';
import React from 'react';

export type Props = Omit<TypographyProps, 'color' | 'variant' | 'component'>;

const MandatoryAsterisk: React.FC<Props> = props => {
  return (
    <Typography {...props} color="error" variant="inherit" component="span">
      *
    </Typography>
  );
};

export default MandatoryAsterisk;
