import React from 'react';

export function useTemplateDialogState() {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [templateId, setTemplateId] = React.useState<string | undefined>(undefined);
  const [templateName, setTemplateName] = React.useState<string | undefined>(undefined);
  const [originalTemplateId, setOriginalTemplateId] = React.useState<string | undefined>(undefined);
  return {
    isDialogOpen,
    templateId,
    templateName,
    originalTemplateId,
    openDialog: ({
      id,
      name,
      originalTemplateId,
    }: {
      id?: string;
      name?: string;
      originalTemplateId?: string;
    }) => {
      setTemplateId(id);
      setTemplateName(name);
      setOriginalTemplateId(originalTemplateId);
      setIsDialogOpen(true);
    },
    closeDialog: () => {
      setTemplateId(undefined);
      setTemplateName(undefined);
      setOriginalTemplateId(undefined);
      setIsDialogOpen(false);
    },
  };
}
