import { useAuth } from '@cmg/auth';
import { UUID } from '@cmg/common';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { SupportModalDismissibleErrorsBanner } from '../../../../../common/components/dismissible-error-banners/SupportModalDismissibleErrorsBanner';
import Banner from '../../../../../common/components/indicators/banner/Banner';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { OfferingType } from '../../../../../graphql';
import { showOrderBookGridToolBarOptions } from '../../../variables';
import IndicationSummaryMetrics from '../indication-summary-metrics/IndicationSummaryMetrics';
import OrderBookUpdatesModal, {
  openOrderBookGridUpdatesModal,
} from '../institutional-demand-grid-updates-modal/InstitutionalDemandGridUpdatesModal';
import InstitutionalDemandInvestorExtendedDataModal from './components/investor-extended-data-modal/InstitutionalDemandMenuModal';
import { ProspectusOnSendValidationAlert } from './components/prospectus-alert/ProspectusOnSendValidationAlert';
import { InstitutionalDemandGridReferenceContextProvider } from './context/InstitutionalDemandGridReferenceContext';
import InstitutionalDemandGridUpdatesContext from './context/InstitutionalDemandGridUpdatesContext';
import { useInstitutionalDemandPreferencesContext } from './context/InstitutionalDemandPreferencesContext';
import { useInstitutionalDemandData } from './hooks/useInstitutionalDemandData';
import {
  createIndicationsWithDemandLevels,
  getMetricsVisibility,
  institutionalDemandGridSecondaryErrorPrefixMap,
} from './InstitutionalDemandContainer.model';
import { SToolbarBannerWrapper } from './InstitutionalDemandContainer.styles';
import InstitutionalDemandGrid from './InstitutionalDemandGrid';
import { SDataGridWrapper } from './InstitutionalDemandGrid.styles';
import InstitutionalDemandMenuModal from './toolbar/demand-grid-menu-modal/InstitutionalDemandMenuModal';
import InstitutionalDemandToolbar from './toolbar/InstitutionalDemandToolbar';

export type Props = {
  offeringType?: OfferingType;
};

const dismissibleBannerProps = {
  bannerProps: {
    title: 'There was an error loading some of the data on the page',
    body: 'Please contact support to report this issue. You are able to continue working with the present data until it is resolved.',
  },
  dialogProps: {
    title: 'Submit Support Request',
    presetCategory: 'TECHNICAL_ISSUE',
    presetSubject: 'Issue loading data in Order Book',
    setErrorsOnDescription: true,
  },
};

/**
 * Renders the Institutional Demand Grid Container
 */
export const InstitutionalDemandContainer: React.FC<Props> = ({ offeringType }) => {
  const {
    params: { offeringId },
  } = useRouteMatch<{ offeringId: UUID }>();
  const dispatch = useDispatch();
  const { oidcUserCmgEntityKey } = useAuth();

  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);

  const {
    filters = {
      searchText: null,
      status: null,
      type: null,
    },
    setFilters,
    metricsVisible,
    setMetricsVisible,
  } = useInstitutionalDemandPreferencesContext();

  showOrderBookGridToolBarOptions(selectedRows.length === 0);

  const dataGridWrapperRef = React.useRef<HTMLDivElement>(null);
  const exportToCsv = React.useRef<Function>(() => undefined);
  const deselectRows = React.useRef<Function>(() => undefined);

  const {
    investors,
    loadingMap,
    dataRefresh,
    prospectusOnSendValidation,
    investorsExtendedData,
    canManagePassiveOrderBook,
    gridSummaryData,
    gridDemandConfiguration,
    offeringGridConfiguration,
    isProspectusDocumentUploaded,
    resendProspectus,
    onDemandRefresh,
    secondaryErrors,
    primaryErrors,
  } = useInstitutionalDemandData({ offeringId, offeringType });

  const handleViewPendingUpdates = useCallback(() => {
    dispatch(openOrderBookGridUpdatesModal({ offeringId }));
  }, [dispatch, offeringId]);

  const primaryError = primaryErrors[0];

  const filteredIndicationWithDemandLevels = React.useMemo(() => {
    if (!gridSummaryData) {
      return undefined;
    }
    const { draftSets, finalSet, indicationDemands } = gridSummaryData;

    return createIndicationsWithDemandLevels({
      filters,
      draftAllocationSets: draftSets,
      finalAllocationSet: finalSet,
      indicationDemands,
      oidcUserCmgEntityKey,
      investorsExtendedData,
      gridOfferingConfig: offeringGridConfiguration,
    });
  }, [
    gridSummaryData,
    filters,
    oidcUserCmgEntityKey,
    investorsExtendedData,
    offeringGridConfiguration,
  ]);

  const { isSwitchVisible: isMetricsSwitchVisible, isMetricsVisible } = getMetricsVisibility({
    isMetricsOn: metricsVisible,
    loading: loadingMap.grid,
    indicationsCount: gridSummaryData?.indicationDemands.length ?? 0,
  });

  return (
    <InstitutionalDemandGridUpdatesContext.Provider value={dataRefresh.updatesContextValue}>
      <InstitutionalDemandGridReferenceContextProvider>
        <SDataGridWrapper ref={dataGridWrapperRef}>
          <React.Fragment>
            {investors && (
              <ProspectusOnSendValidationAlert
                prospectusOnSendValidation={prospectusOnSendValidation}
                investors={investors}
              />
            )}
            {canManagePassiveOrderBook && (
              <SToolbarBannerWrapper>
                <Banner variant="information" iconName="info-circle">
                  <Banner.Title>
                    This collection of indications is private and will not be shared with active
                    bookrunners or investors.
                  </Banner.Title>
                </Banner>
              </SToolbarBannerWrapper>
            )}
            {primaryError && (
              <SToolbarBannerWrapper>
                <ServerErrorsBanner error={primaryError} />
              </SToolbarBannerWrapper>
            )}
            {!primaryError && (
              <SupportModalDismissibleErrorsBanner
                {...dismissibleBannerProps}
                errors={secondaryErrors}
                prefixMessageMap={institutionalDemandGridSecondaryErrorPrefixMap}
              />
            )}

            {filteredIndicationWithDemandLevels && (
              <IndicationSummaryMetrics
                offeringId={offeringId}
                loading={loadingMap.grid}
                isHidden={!isMetricsVisible}
                indications={filteredIndicationWithDemandLevels}
                selectedIndicationIds={selectedRows}
                referencePrice={gridDemandConfiguration.demandColumnConfig.referencePrice ?? null}
              />
            )}
            {gridSummaryData && (
              <InstitutionalDemandToolbar
                offeringId={offeringId}
                gridSummaryData={gridSummaryData}
                investorsExtendedData={investorsExtendedData}
                gridConfiguration={gridDemandConfiguration}
                activeFilters={filters}
                onFiltersChange={setFilters}
                onExportToCsv={() => exportToCsv.current()}
                changeCounter={dataRefresh.recentUpdates.length}
                onDataRefresh={onDemandRefresh}
                onToggleMetrics={setMetricsVisible}
                metricsVisible={metricsVisible}
                withMetricsSwitch={isMetricsSwitchVisible}
                selectedIndicationIds={selectedRows}
                onSaveDupesCompleted={() => {
                  if (deselectRows.current) {
                    deselectRows.current();
                  }
                }}
                gridOfferingConfig={offeringGridConfiguration}
                onViewPendingUpdates={handleViewPendingUpdates}
                isProspectusDocumentUploaded={isProspectusDocumentUploaded}
                resendProspectus={resendProspectus}
                isResendProspectusLoading={loadingMap.secondaryQueriesMap.prospectusData}
              />
            )}
          </React.Fragment>
          {gridDemandConfiguration.isColumnConfigurationApplied && (
            <InstitutionalDemandGrid
              indications={filteredIndicationWithDemandLevels}
              loadingMap={loadingMap}
              gridSummaryData={gridSummaryData}
              gridOfferingConfig={offeringGridConfiguration}
              exportToCsv={exportToCsv}
              onRowSelected={setSelectedRows}
              deselectRows={deselectRows}
              investorsExtendedData={investorsExtendedData}
              offeringType={offeringType}
              pricingCurrencyCode={offeringGridConfiguration?.pricingCurrencyCode ?? undefined}
            />
          )}
        </SDataGridWrapper>
        <OrderBookUpdatesModal offeringId={offeringId} refresh={onDemandRefresh} />
        {gridSummaryData && (
          <InstitutionalDemandMenuModal
            offeringId={offeringId}
            isFinalAllocationSetReleased={gridSummaryData.isFinalAllocationSetReleased}
            finalAllocationExists={!!gridSummaryData.finalSet}
            activeDemandColumnConfig={gridDemandConfiguration.demandColumnConfig}
            onDemandColumnConfigChange={gridDemandConfiguration.setConfiguration}
            investorsExtendedData={investorsExtendedData}
            pricingCurrencyCode={offeringGridConfiguration?.pricingCurrencyCode ?? undefined}
          />
        )}
        <InstitutionalDemandInvestorExtendedDataModal />
      </InstitutionalDemandGridReferenceContextProvider>
    </InstitutionalDemandGridUpdatesContext.Provider>
  );
};

export default InstitutionalDemandContainer;
