import { ODSTable as Table } from '@cmg/common';
import { format } from 'date-fns';
import React from 'react';

import { EmptyState } from '../../../../../../design-system/components/data-display/empty-state/EmptyState';
import { TextWithEllipsis } from '../../../../../trade-release/common/TextWithEllipsis';
import ReleaseStatus from '../../../grid/release-status-renderer/release-status/ReleaseStatus';
import { SalesCredits_InvestorHistoryItemPartsFragment } from '../graphql';

export type Props = {
  salesCreditsHistoryItems: readonly SalesCredits_InvestorHistoryItemPartsFragment[];
};

export const SalesCreditsHistoryTable: React.VFC<Props> = ({ salesCreditsHistoryItems }) => {
  return (
    <Table gridTemplateColumns="1fr 1fr 1fr 1fr">
      <Table.Row>
        <Table.TableHeaderCell>Date</Table.TableHeaderCell>
        <Table.TableHeaderCell>Completed by</Table.TableHeaderCell>
        <Table.TableHeaderCell>Status Details</Table.TableHeaderCell>
        <Table.TableHeaderCell>Release Status</Table.TableHeaderCell>
      </Table.Row>

      {salesCreditsHistoryItems.length === 0 && (
        <Table.Row suppressHighlightingOnHover isFocused={false}>
          <Table.Cell colSpan={4} withBottomBorder={false}>
            <EmptyState
              title="No Sales Credits History"
              message="There is currently no sales credits history for this investor"
            />
          </Table.Cell>
        </Table.Row>
      )}

      {salesCreditsHistoryItems.map(item => (
        <Table.Row key={item.id} isFocused={false} suppressHighlightingOnHover={true}>
          <Table.Cell>{format(new Date(item.createdAt), 'MM/dd/yyyy HH:mm a')}</Table.Cell>
          <Table.Cell>{item.createdByUserName}</Table.Cell>
          <Table.Cell>
            <TextWithEllipsis text={item.statusDetails} maxLength={53} />
          </Table.Cell>
          <Table.Cell>
            <ReleaseStatus status={item.salesCreditStatus} />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};
