import { numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';

import { StyledFooterRow, SWrapper } from '../ExpensesRoute.styles';
import { ExpensesValues, getManagerExpensesTotals } from '../form/ExpensesForm.model';
import { FinalSettlement_ExpensesQuery } from '../graphql';
import EditableManagerExpenses from './editable-expenses/EditableManagerExpenses';
import ReadOnlyManagerExpenses from './read-only-expenses/ReadOnlyManagerExpenses';

export type Props = {
  managers: FinalSettlement_ExpensesQuery['expenses']['managers'];
  editable: boolean;
};

const ManagerExpensesRoute: React.FC<Props> = ({ managers, editable }) => {
  const { values } = useFormikContext<ExpensesValues>();

  const managerExpenses = getManagerExpensesTotals(values.managerExpenses);

  return (
    <SWrapper>
      <Table numColumns={7}>
        <Table.TableHeaderCell>Manager</Table.TableHeaderCell>
        <Table.TableHeaderCell>Role</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Travel / Meals ($)</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Technology / Data ($)</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Miscellaneous ($)</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Total Submitted ($)</Table.TableHeaderCell>
        <Table.TableHeaderCell align="right">Reimbursement ($)</Table.TableHeaderCell>
        <StyledFooterRow>
          <Table.Cell bold colSpan={2}>
            Total Submitted
          </Table.Cell>
          <Table.Cell align="right" bold>
            {numericUtil.getDisplayValueForCurrency(managerExpenses.travelAndMeals)}
          </Table.Cell>
          <Table.Cell align="right" bold>
            {numericUtil.getDisplayValueForCurrency(managerExpenses.technologyAndData)}
          </Table.Cell>
          <Table.Cell align="right" bold>
            {numericUtil.getDisplayValueForCurrency(managerExpenses.miscellaneous)}
          </Table.Cell>
          <Table.Cell align="right" bold>
            {numericUtil.getDisplayValueForCurrency(
              numericUtil.sum(
                managerExpenses.travelAndMeals,
                managerExpenses.technologyAndData,
                managerExpenses.miscellaneous
              )
            )}
          </Table.Cell>
          <Table.Cell align="right" bold>
            {numericUtil.getDisplayValueForCurrency(managerExpenses.reimbursement)}
          </Table.Cell>
        </StyledFooterRow>
        {editable && <EditableManagerExpenses managers={managers} />}
        {!editable && <ReadOnlyManagerExpenses managers={managers} />}
      </Table>
    </SWrapper>
  );
};

export default ManagerExpensesRoute;
