import { Option, SelectField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';

import type { Fund, FundItem } from '../../FundLevelDemand';
import Allocation from './components/allocation/Allocation';
// FundIoisForm uses it's own versions of InterestLevels & Allocation as it's flow is
// different from what's usual - no view, only edit mode (for view see FundIoisGrid)
import InterestLevels from './components/interest-levels/InterestLevels';
import { mapFormValuesToOutputValues, mapFundItemToFormValues } from './FundIoisForm.model';
import { fundIoisValidationSchema } from './fundIoisValidationSchema';
import type { FundIoisFormValues, FundIoisValues } from './types';

const StyledForm = styled(Form)`
  padding: 12px 10px;
  label {
    font-size: 14px;

    :after {
      margin-left: 3px;
    }
  }
`;

export type { FundIoisValues };

export type Props = {
  funds: Fund[];
  item: FundItem | undefined;
  setSubmit: (submitCallback: () => void) => void;
  onSubmit: (item: FundIoisValues) => void;
  onValidationChange: (isValid: boolean) => void;
  submitting: boolean;
};

export const FundIoisForm: React.FC<Props> = ({
  funds,
  item,
  setSubmit,
  onSubmit,
  onValidationChange,
  submitting,
}) => {
  const formik = useFormik<FundIoisFormValues>({
    validateOnBlur: true,
    validationSchema: fundIoisValidationSchema,
    enableReinitialize: true,
    initialValues: mapFundItemToFormValues(item),
    onSubmit: values => {
      onSubmit(mapFormValuesToOutputValues(values));
    },
  });

  React.useEffect(() => {
    setSubmit(formik.handleSubmit);
  }, [setSubmit, formik.handleSubmit]);

  React.useEffect(() => {
    onValidationChange(formik.dirty && formik.isValid);
  }, [onValidationChange, formik.dirty, formik.isValid]);

  const validateInterestLevels = () => {
    formik.validateField('interestLevels');
  };

  // changing fund of existing items not supported (prevents complex dataset reprocessing)
  const isFundIdDisabled = !!item?.fund.id;

  return (
    <FormikProvider value={formik}>
      <StyledForm data-test-id={xcSelectors.fundLevelDemandForm.testId}>
        <SelectField
          name="fundId"
          label="Fund"
          options={funds.map(({ id, name }) => ({ label: name, value: id })) as Option[]}
          placeholder="Select..."
          disabled={isFundIdDisabled || submitting}
          fullWidth
          isClearable={false}
          required
        />

        <InterestLevels
          submitting={submitting}
          onItemAdded={validateInterestLevels}
          onItemRemoved={validateInterestLevels}
        />

        <Allocation submitting={submitting} />
      </StyledForm>
    </FormikProvider>
  );
};

export default FundIoisForm;
