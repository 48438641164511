import { ColDef } from 'ag-grid-community';

import { offeringColumnMap, OfferingGridOrderField } from '../Columns';

const columns: ColDef[] = [
  offeringColumnMap[OfferingGridOrderField.ISSUER_NAME],
  offeringColumnMap[OfferingGridOrderField.SYMBOL],
  offeringColumnMap[OfferingGridOrderField.SECTOR],
  offeringColumnMap[OfferingGridOrderField.TYPE],
  offeringColumnMap[OfferingGridOrderField.SIZE_IN_CURRENCY],
  offeringColumnMap[OfferingGridOrderField.SIZE_IN_SHARES],
  offeringColumnMap[OfferingGridOrderField.PRICE_RANGE],
  offeringColumnMap[OfferingGridOrderField.LEFT_LEAD],
];

export default columns;
