import { assets, Popover } from '@cmg/common';
import React from 'react';

import { getFeatureToggles } from '../../../../../../../common/config';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { IndicationStatus } from '../../../../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { indicationStatusLabels } from '../../../../../constants';
import { IndicationWithDemandLevels, InstitutionalDemandGridContext } from '../../types';
import {
  IconColor,
  SCompanyNameCellWrapper,
  SIconWrapper,
  SOnPlatformIcon,
  SPopoverContentWrapper,
  StyledCompanyNameLink,
  StyledCompanyNameLinkWithoutLogo,
  StyledStatusIcon,
} from './CompanyNameCell.styles';
import DuplicateIcon from './DuplicateIcon';
import SurvivingIcon from './SurvivingIcon';

type Props = {
  data: IndicationWithDemandLevels;
  context: InstitutionalDemandGridContext;
};

/**
 * Renders the Company Name Cell within the Institutional Demand Grid.
 */
const CompanyNameCell: React.FC<Props> = ({ data, context }) => {
  const { isOrderBookDupesOn } = getFeatureToggles();
  const isOnPlatform = data.investorInformation.isOnPlatform;

  const isActive = data.status === IndicationStatus.Active;
  const isMarkedAsDuplicate = data.status === IndicationStatus.Duplicate;
  const color: IconColor = isActive ? 'POSITIVE' : 'DARK';
  const { id: indicationId } = data;

  return (
    <SCompanyNameCellWrapper>
      <Popover
        variant="TOOLTIP"
        trigger="hover"
        content={
          <SPopoverContentWrapper>{indicationStatusLabels[data.status]}</SPopoverContentWrapper>
        }
        transparentBackground
      >
        <span>
          <StyledStatusIcon name="circle" variant="solid" size="1x" color={color} />
        </span>
      </Popover>
      {isOnPlatform ? (
        <React.Fragment>
          <Popover
            content="This investor is on the CMG platform."
            variant="DARK"
            placement="topRight"
            arrowPointAtCenter
          >
            <span>
              <SOnPlatformIcon
                src={assets.logoNoText}
                aria-label="On Platform Icon"
                alt="This investor is on the CMG platform."
              />
            </span>
          </Popover>
          <StyledCompanyNameLink
            to={routeFactory.offeringSidePanel.getUrlPath({
              offeringId: context.offeringId,
              prefixRoutePath: context.matchUrl,
              sidePanelRoutePath:
                offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
                  cmgEntityKey: data.investorInformation?.cmgEntityKey!,
                  indicationId,
                }),
            })}
          >
            {data.investorInformation.bankInvestorName || data.investorInformation.cmgEntityName}
          </StyledCompanyNameLink>
        </React.Fragment>
      ) : (
        <StyledCompanyNameLinkWithoutLogo
          to={routeFactory.offeringSidePanel.getUrlPath({
            offeringId: context.offeringId,
            prefixRoutePath: context.matchUrl,
            sidePanelRoutePath:
              offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
                cmgEntityKey: data.investorInformation?.cmgEntityKey!,
                indicationId,
              }),
          })}
        >
          {data.investorInformation.bankInvestorName || data.investorInformation.cmgEntityName}
        </StyledCompanyNameLinkWithoutLogo>
      )}
      {isOrderBookDupesOn && isMarkedAsDuplicate && (
        <SIconWrapper>
          <DuplicateIcon duplicateOfInvestorName={data.duplicateOfInvestorName}>D</DuplicateIcon>
        </SIconWrapper>
      )}
      {data.duplicateIndicationIds.length > 0 && (
        <SIconWrapper>
          <SurvivingIcon />
        </SIconWrapper>
      )}
    </SCompanyNameCellWrapper>
  );
};

export default CompanyNameCell;
