import { BaseAgGrid, OnGridReadyEventFn, SelectionChangedEventFn, UUID } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useDebouncedCallback } from 'use-debounce';

import { useSalesCreditsContext } from '../../context/SalesCreditsContext';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';
import {
  ReleaseSalesCreditsFunction,
  ReviewSalesCreditsItemFunction,
  UpdateSalesCreditsStatusFunction,
} from '../grid-actions/SalesCreditsGridActions.model';
import { SalesCreditsFormValues } from '../SalesCreditsDetailsPanel.model';
import { useGridTotals } from './hooks/useGridTotals';
import {
  isSalesCreditsRowSelectable,
  salesCreditsColumns,
  SalesCreditsGridContext,
} from './SalesCreditsGrid.model';
import { SWrapper } from './SalesCreditsGrid.styles';

export type Props = {
  onGridReady: OnGridReadyEventFn;
  rows: SalesCredits_SalesCreditItemPartsFragment[];
  offeringId: UUID;
  onSelectionChange: (selectedRows: SalesCredits_SalesCreditItemPartsFragment[]) => void;
  onRelease: ReleaseSalesCreditsFunction;
  onUpdateStatus: UpdateSalesCreditsStatusFunction;
  onOpenReviewModal: ReviewSalesCreditsItemFunction;
  sellingConcession: number | null | undefined;
};

const SalesCreditsGrid: React.FC<Props> = ({
  onGridReady,
  rows,
  offeringId,
  onSelectionChange,
  onRelease,
  onUpdateStatus,
  onOpenReviewModal,
  sellingConcession,
}) => {
  const theme = useTheme();

  const { values } = useFormikContext<SalesCreditsFormValues>();
  const { isEditing } = useSalesCreditsContext();

  const totalRow = useGridTotals({ rows, isEditing, editingValues: values });

  const context: SalesCreditsGridContext = {
    offeringId,
    onRelease,
    onUpdateStatus,
    sellingConcession,
    onOpenReviewModal,
    rows,
  };

  // we have to wrap this in debounce, because this is called multiple times when selecting bulk
  const handleSelectionChange = useDebouncedCallback<SelectionChangedEventFn>(({ api }) => {
    api.refreshHeader();
    onSelectionChange(api.getSelectedRows() as SalesCredits_SalesCreditItemPartsFragment[]);
  });

  return (
    <SWrapper>
      <BaseAgGrid<SalesCredits_SalesCreditItemPartsFragment>
        onGridReady={onGridReady}
        context={context}
        columns={salesCreditsColumns}
        rows={rows}
        onSelectionChanged={handleSelectionChange}
        pinnedTopRows={[totalRow]}
        rowSelection="multiple"
        loading={false}
        frameworkComponents={{ emptyComponent: () => null }}
        rowStyle={{
          background: theme.background.color.white,
          borderBottom: theme.border.smallSolidLight,
        }}
        rowHeight={36}
        isRowSelectable={isSalesCreditsRowSelectable}
      />
    </SWrapper>
  );
};

export default SalesCreditsGrid;
