/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CancelMeetingMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_CancelMeetingMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelMeeting: { readonly __typename?: 'MeetingDetails'; readonly id: string };
};

export type Roadshows_ConfirmMeetingMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_ConfirmMeetingMutation = {
  readonly __typename?: 'Mutation';
  readonly confirmMeeting: { readonly __typename?: 'MeetingDetails'; readonly id: string };
};

export type Roadshows_DeleteMeetingMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_DeleteMeetingMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteMeeting: string;
};

export type Roadshows_MeetingQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_MeetingQuery = {
  readonly __typename?: 'Query';
  readonly roadshowMeeting: {
    readonly __typename?: 'Meeting';
    readonly meetingDetails: {
      readonly __typename?: 'MeetingDetails';
      readonly id: string;
      readonly date: string;
      readonly startTime: string;
      readonly endTime: string;
      readonly timezone: string;
      readonly title: string;
      readonly location?: string | null;
      readonly address?: string | null;
      readonly venue?: string | null;
      readonly roomName?: string | null;
      readonly publicNotes?: string | null;
      readonly type: Types.MeetingType;
      readonly status: Types.EventStatus;
      readonly isPublic: boolean;
      readonly format: Types.MeetingFormat;
      readonly dialInDetails?: string | null;
      readonly hostPersonEmail?: string | null;
      readonly hostPersonFullName?: string | null;
      readonly hostFirmCmgEntityKey?: string | null;
    };
    readonly investors: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
    readonly participantsTeam: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
  };
};

export type Roadshows_OpenMeetingToSalesMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_OpenMeetingToSalesMutation = {
  readonly __typename?: 'Mutation';
  readonly openMeetingToSales: { readonly __typename?: 'MeetingDetails'; readonly id: string };
};

export type Roadshows_UpdateInvestorTeamsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
  payload: ReadonlyArray<Types.InvestorTeamInput> | Types.InvestorTeamInput;
}>;

export type Roadshows_UpdateInvestorTeamsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateInvestorTeams: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export type Roadshows_UpdateMeetingMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
  payload: Types.MeetingInput;
}>;

export type Roadshows_UpdateMeetingMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMeeting: {
    readonly __typename?: 'MeetingDetails';
    readonly id: string;
    readonly date: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly timezone: string;
    readonly title: string;
    readonly location?: string | null;
    readonly address?: string | null;
    readonly venue?: string | null;
    readonly roomName?: string | null;
    readonly publicNotes?: string | null;
    readonly type: Types.MeetingType;
    readonly status: Types.EventStatus;
    readonly isPublic: boolean;
    readonly format: Types.MeetingFormat;
    readonly dialInDetails?: string | null;
    readonly hostPersonEmail?: string | null;
    readonly hostPersonFullName?: string | null;
    readonly hostFirmCmgEntityKey?: string | null;
  };
};

export type Roadshows_UpdateMeetingParticipantsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
  participantsIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  adHocParticipants: ReadonlyArray<Types.AdHocParticipantInput> | Types.AdHocParticipantInput;
}>;

export type Roadshows_UpdateMeetingParticipantsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMeetingParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
  readonly updateAdHocMeetingParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export const Roadshows_CancelMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CancelMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CancelMeetingMutationFn = Apollo.MutationFunction<
  Roadshows_CancelMeetingMutation,
  Roadshows_CancelMeetingMutationVariables
>;

/**
 * __useRoadshows_CancelMeetingMutation__
 *
 * To run a mutation, you first call `useRoadshows_CancelMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CancelMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCancelMeetingMutation, { data, loading, error }] = useRoadshows_CancelMeetingMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_CancelMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CancelMeetingMutation,
    Roadshows_CancelMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CancelMeetingMutation,
    Roadshows_CancelMeetingMutationVariables
  >(Roadshows_CancelMeetingDocument, options);
}
export type Roadshows_CancelMeetingMutationHookResult = ReturnType<
  typeof useRoadshows_CancelMeetingMutation
>;
export type Roadshows_CancelMeetingMutationResult =
  Apollo.MutationResult<Roadshows_CancelMeetingMutation>;
export type Roadshows_CancelMeetingMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_CancelMeetingMutation,
  Roadshows_CancelMeetingMutationVariables
>;
export const Roadshows_ConfirmMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_ConfirmMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_ConfirmMeetingMutationFn = Apollo.MutationFunction<
  Roadshows_ConfirmMeetingMutation,
  Roadshows_ConfirmMeetingMutationVariables
>;

/**
 * __useRoadshows_ConfirmMeetingMutation__
 *
 * To run a mutation, you first call `useRoadshows_ConfirmMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_ConfirmMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsConfirmMeetingMutation, { data, loading, error }] = useRoadshows_ConfirmMeetingMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_ConfirmMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_ConfirmMeetingMutation,
    Roadshows_ConfirmMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_ConfirmMeetingMutation,
    Roadshows_ConfirmMeetingMutationVariables
  >(Roadshows_ConfirmMeetingDocument, options);
}
export type Roadshows_ConfirmMeetingMutationHookResult = ReturnType<
  typeof useRoadshows_ConfirmMeetingMutation
>;
export type Roadshows_ConfirmMeetingMutationResult =
  Apollo.MutationResult<Roadshows_ConfirmMeetingMutation>;
export type Roadshows_ConfirmMeetingMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_ConfirmMeetingMutation,
  Roadshows_ConfirmMeetingMutationVariables
>;
export const Roadshows_DeleteMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_DeleteMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_DeleteMeetingMutationFn = Apollo.MutationFunction<
  Roadshows_DeleteMeetingMutation,
  Roadshows_DeleteMeetingMutationVariables
>;

/**
 * __useRoadshows_DeleteMeetingMutation__
 *
 * To run a mutation, you first call `useRoadshows_DeleteMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DeleteMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsDeleteMeetingMutation, { data, loading, error }] = useRoadshows_DeleteMeetingMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_DeleteMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_DeleteMeetingMutation,
    Roadshows_DeleteMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_DeleteMeetingMutation,
    Roadshows_DeleteMeetingMutationVariables
  >(Roadshows_DeleteMeetingDocument, options);
}
export type Roadshows_DeleteMeetingMutationHookResult = ReturnType<
  typeof useRoadshows_DeleteMeetingMutation
>;
export type Roadshows_DeleteMeetingMutationResult =
  Apollo.MutationResult<Roadshows_DeleteMeetingMutation>;
export type Roadshows_DeleteMeetingMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_DeleteMeetingMutation,
  Roadshows_DeleteMeetingMutationVariables
>;
export const Roadshows_MeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_Meeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Roadshows_MeetingParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meeting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_MeetingQuery__
 *
 * To run a query within a React component, call `useRoadshows_MeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_MeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_MeetingQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_MeetingQuery(
  baseOptions: Apollo.QueryHookOptions<Roadshows_MeetingQuery, Roadshows_MeetingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_MeetingQuery, Roadshows_MeetingQueryVariables>(
    Roadshows_MeetingDocument,
    options
  );
}
export function useRoadshows_MeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Roadshows_MeetingQuery, Roadshows_MeetingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_MeetingQuery, Roadshows_MeetingQueryVariables>(
    Roadshows_MeetingDocument,
    options
  );
}
export type Roadshows_MeetingQueryHookResult = ReturnType<typeof useRoadshows_MeetingQuery>;
export type Roadshows_MeetingLazyQueryHookResult = ReturnType<typeof useRoadshows_MeetingLazyQuery>;
export type Roadshows_MeetingQueryResult = Apollo.QueryResult<
  Roadshows_MeetingQuery,
  Roadshows_MeetingQueryVariables
>;
export const Roadshows_OpenMeetingToSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_OpenMeetingToSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openMeetingToSales' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_OpenMeetingToSalesMutationFn = Apollo.MutationFunction<
  Roadshows_OpenMeetingToSalesMutation,
  Roadshows_OpenMeetingToSalesMutationVariables
>;

/**
 * __useRoadshows_OpenMeetingToSalesMutation__
 *
 * To run a mutation, you first call `useRoadshows_OpenMeetingToSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_OpenMeetingToSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsOpenMeetingToSalesMutation, { data, loading, error }] = useRoadshows_OpenMeetingToSalesMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_OpenMeetingToSalesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_OpenMeetingToSalesMutation,
    Roadshows_OpenMeetingToSalesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_OpenMeetingToSalesMutation,
    Roadshows_OpenMeetingToSalesMutationVariables
  >(Roadshows_OpenMeetingToSalesDocument, options);
}
export type Roadshows_OpenMeetingToSalesMutationHookResult = ReturnType<
  typeof useRoadshows_OpenMeetingToSalesMutation
>;
export type Roadshows_OpenMeetingToSalesMutationResult =
  Apollo.MutationResult<Roadshows_OpenMeetingToSalesMutation>;
export type Roadshows_OpenMeetingToSalesMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_OpenMeetingToSalesMutation,
  Roadshows_OpenMeetingToSalesMutationVariables
>;
export const Roadshows_UpdateInvestorTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateInvestorTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorTeamInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInvestorTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateInvestorTeamsMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateInvestorTeamsMutation,
  Roadshows_UpdateInvestorTeamsMutationVariables
>;

/**
 * __useRoadshows_UpdateInvestorTeamsMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateInvestorTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateInvestorTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateInvestorTeamsMutation, { data, loading, error }] = useRoadshows_UpdateInvestorTeamsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateInvestorTeamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateInvestorTeamsMutation,
    Roadshows_UpdateInvestorTeamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateInvestorTeamsMutation,
    Roadshows_UpdateInvestorTeamsMutationVariables
  >(Roadshows_UpdateInvestorTeamsDocument, options);
}
export type Roadshows_UpdateInvestorTeamsMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateInvestorTeamsMutation
>;
export type Roadshows_UpdateInvestorTeamsMutationResult =
  Apollo.MutationResult<Roadshows_UpdateInvestorTeamsMutation>;
export type Roadshows_UpdateInvestorTeamsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateInvestorTeamsMutation,
  Roadshows_UpdateInvestorTeamsMutationVariables
>;
export const Roadshows_UpdateMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateMeetingMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateMeetingMutation,
  Roadshows_UpdateMeetingMutationVariables
>;

/**
 * __useRoadshows_UpdateMeetingMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateMeetingMutation, { data, loading, error }] = useRoadshows_UpdateMeetingMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateMeetingMutation,
    Roadshows_UpdateMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateMeetingMutation,
    Roadshows_UpdateMeetingMutationVariables
  >(Roadshows_UpdateMeetingDocument, options);
}
export type Roadshows_UpdateMeetingMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateMeetingMutation
>;
export type Roadshows_UpdateMeetingMutationResult =
  Apollo.MutationResult<Roadshows_UpdateMeetingMutation>;
export type Roadshows_UpdateMeetingMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateMeetingMutation,
  Roadshows_UpdateMeetingMutationVariables
>;
export const Roadshows_UpdateMeetingParticipantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateMeetingParticipants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'participantsIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adHocParticipants' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdHocParticipantInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMeetingParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'participantsIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAdHocMeetingParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adHocParticipants' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateMeetingParticipantsMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateMeetingParticipantsMutation,
  Roadshows_UpdateMeetingParticipantsMutationVariables
>;

/**
 * __useRoadshows_UpdateMeetingParticipantsMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateMeetingParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateMeetingParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateMeetingParticipantsMutation, { data, loading, error }] = useRoadshows_UpdateMeetingParticipantsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *      participantsIds: // value for 'participantsIds'
 *      adHocParticipants: // value for 'adHocParticipants'
 *   },
 * });
 */
export function useRoadshows_UpdateMeetingParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateMeetingParticipantsMutation,
    Roadshows_UpdateMeetingParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateMeetingParticipantsMutation,
    Roadshows_UpdateMeetingParticipantsMutationVariables
  >(Roadshows_UpdateMeetingParticipantsDocument, options);
}
export type Roadshows_UpdateMeetingParticipantsMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateMeetingParticipantsMutation
>;
export type Roadshows_UpdateMeetingParticipantsMutationResult =
  Apollo.MutationResult<Roadshows_UpdateMeetingParticipantsMutation>;
export type Roadshows_UpdateMeetingParticipantsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateMeetingParticipantsMutation,
  Roadshows_UpdateMeetingParticipantsMutationVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_MeetingMockResponse = makeMockResponse<
  Roadshows_MeetingQueryVariables,
  Roadshows_MeetingQuery
>(Roadshows_MeetingDocument);
