import { loggerUtil } from '@cmg/common';

import { MyOrders_CoveredIndicationChangedSubscription } from '../__generated__';

/**
 * The CoveredIndicationChangedSubscription has several nullable fields. Different fields combinations map to different patterns.
 * These patterns can be inferred from the field values.
 */
export type CoveredIndicationChangePattern =
  | 'OrderBookNotIndicationChange'
  | 'OrderBookIndicationChange'
  | 'AttestationChange'
  | 'ProspectusChange';

/** Infering the pattern is not ideal, but something we need to deal with until the updated data comes back in the payload. */
export const tryInferCoveredIndicationChangePattern: ({
  coveredIndicationChanged: { offeringId, indicationId, investorKey },
}: MyOrders_CoveredIndicationChangedSubscription) => CoveredIndicationChangePattern | undefined = ({
  coveredIndicationChanged: { offeringId, indicationId, investorKey },
}) => {
  if (offeringId && !indicationId && !investorKey) {
    // "offeringId": "001c0000-ac1c-0242-3236-08db4a5cb4fc",
    // "indicationId": null
    // "investorKey": null
    return 'OrderBookNotIndicationChange';
  } else if (offeringId && indicationId && !investorKey) {
    // "offeringId": "001c0000-ac1c-0242-3236-08db4a5cb4fc",
    // "indicationId": "c9e8ff6d-478a-451f-926b-155cd027537f",
    // "investorKey": null
    return 'OrderBookIndicationChange';
  } else if (!offeringId && !indicationId && investorKey) {
    // "offeringId": null,
    // "indicationId": null,
    // "investorKey": "00001234"
    return 'AttestationChange';
  } else if (offeringId && !indicationId && investorKey) {
    // "offeringId": "001c0000-ac1c-0242-3236-08db4a5cb4fc",
    // "indicationId": null,
    // "investorKey": "00001234"
    return 'ProspectusChange';
  } else {
    return undefined;
  }
};

export const matchesCoveredIndicationChangePattern: (
  data: MyOrders_CoveredIndicationChangedSubscription,
  patterns: CoveredIndicationChangePattern[]
) => boolean = (data, patterns) => {
  const coveredIndicationChangePattern = tryInferCoveredIndicationChangePattern(data);

  if (coveredIndicationChangePattern) {
    return patterns.includes(coveredIndicationChangePattern);
  }

  loggerUtil.warning(
    `Encountered a CoveredIndicationChangedSubscription with an unknown pattern.`,
    {
      data,
    }
  );

  return false;
};
