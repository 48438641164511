import isNil from 'lodash/isNil';
import * as yup from 'yup';

import {
  InstitutionalIndicationOrderType,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
} from '../../../../../../../../graphql';
import {
  contactsArraySchema,
  InvestorContact,
} from '../../../investor-contact-information/InvestorContactInformationForm.model';

export type InterestLevelValue = {
  interestQuantity: number | null;
  interestUnit: InterestLevelInterestUnit | null;
  orderType: InstitutionalIndicationOrderType | null;
  limitPrice: number | null;
  limitType: InterestLevelLimitType | null;
};

export type IndicationFormValues = {
  trancheId: string | null;
  instrumentId: string | null;
  currencyCode: string | null;
  interestLevels: InterestLevelValue[];
};

export const emptyInterestLevelValue: InterestLevelValue = {
  interestQuantity: null,
  interestUnit: null,
  orderType: null,
  limitPrice: null,
  limitType: null,
};

const interestQuantityUniqueTest = (value: number | null, context: yup.TestContext): boolean => {
  const interestLevels: InterestLevelValue[] = context.from?.[1].value.interestLevels ?? [];

  if (isNil(value)) {
    return true;
  }

  const levelsWithSameValue = interestLevels.filter(
    ({ interestQuantity }) => interestQuantity === value
  );

  return levelsWithSameValue.length <= 1;
};

const interestQuantityMarketIncreaseTest = (
  marketInterestQuantity: number | null,
  context: yup.TestContext
): boolean => {
  const interestLevels: InterestLevelValue[] = context.from?.[1].value.interestLevels ?? [];

  if (isNil(marketInterestQuantity)) {
    return true;
  }

  return !interestLevels.some(
    ({ orderType, interestQuantity }) =>
      orderType === InstitutionalIndicationOrderType.Limit &&
      !isNil(interestQuantity) &&
      interestQuantity < marketInterestQuantity
  );
};

const interestQuantityLimitIncreaseTest = (
  limitInterestQuantity: number | null,
  context: yup.TestContext
): boolean => {
  const interestLevels: InterestLevelValue[] = context.from?.[1].value.interestLevels ?? [];
  const currentInterestLevel: InterestLevelValue = context.parent;

  if (
    isNil(limitInterestQuantity) ||
    isNil(currentInterestLevel.limitPrice) ||
    isNil(currentInterestLevel.limitType)
  ) {
    return true;
  }

  if (currentInterestLevel.limitType === InterestLevelLimitType.Discount) {
    return !interestLevels.some(
      ({ interestQuantity, limitPrice, limitType }) =>
        !isNil(interestQuantity) &&
        !isNil(limitPrice) &&
        !isNil(limitType) &&
        ((limitInterestQuantity > interestQuantity &&
          currentInterestLevel.limitPrice! < limitPrice) ||
          (limitInterestQuantity < interestQuantity &&
            currentInterestLevel.limitPrice! > limitPrice))
    );
  } else {
    // Price / Premium
    return !interestLevels.some(
      ({ interestQuantity, limitPrice, limitType }) =>
        !isNil(interestQuantity) &&
        !isNil(limitPrice) &&
        !isNil(limitType) &&
        ((limitInterestQuantity > interestQuantity &&
          currentInterestLevel.limitPrice! > limitPrice) ||
          (limitInterestQuantity < interestQuantity &&
            currentInterestLevel.limitPrice! < limitPrice))
    );
  }
};

const InterestLevelValidationSchema = yup.object().shape({
  orderType: yup.string().label('Order Type').nullable().required(),
  interestUnit: yup.string().label('Demand Unit').nullable().required(),
  interestQuantity: yup
    .number()
    .label('Demand Quantity')
    .positive()
    .nullable()
    .required()
    .when('interestUnit', {
      is: InterestLevelInterestUnit.Percent,
      then: schema => schema.max(1, 'A Percentage-based Interest Quantity cannot exceed 100%'),
    })
    .test(
      'test-interestQuantity-unique',
      'Demand quantity should not be the same',
      interestQuantityUniqueTest
    )
    .when('orderType', {
      is: InstitutionalIndicationOrderType.Market,
      then: schema =>
        schema.test(
          'test-interestQuantity-marketIncrease',
          'Demand quantity should not increase at higher price',
          interestQuantityMarketIncreaseTest
        ),
    })
    .when('orderType', {
      is: InstitutionalIndicationOrderType.Limit,
      then: schema =>
        schema.test(
          'test-interestQuantity-limitIncrease',
          'Demand quantity should not increase at higher price',
          interestQuantityLimitIncreaseTest
        ),
    }),
  limitType: yup
    .string()
    .label('Limit Type')
    .nullable()
    .when('orderType', {
      is: InstitutionalIndicationOrderType.Limit,
      then: schema => schema.required(),
    }),
  limitPrice: yup
    .number()
    .label('Limit Price')
    .nullable()
    .when('orderType', {
      is: InstitutionalIndicationOrderType.Limit,
      then: schema =>
        schema
          .required()
          .positive()
          .test(
            'test-checkUniqueLimitPrice',
            'You cannot submit two interest levels with the same limit configuration',
            function (this, limitPrice) {
              const interestLevels = this.from?.[1].value.interestLevels ?? [];
              return (
                interestLevels.filter(
                  interestLevel =>
                    interestLevel.orderType === InstitutionalIndicationOrderType.Limit &&
                    interestLevel.limitPrice === limitPrice &&
                    interestLevel.limitType === this.parent.limitType
                ).length < 2
              );
            }
          )
          .when('limitType', {
            is: InterestLevelLimitType.Discount,
            then: schema => schema.positive().max(1, 'A Discount Limit cannot exceed 100%'),
          })
          .when('limitType', {
            is: InterestLevelLimitType.Premium,
            then: schema => schema.positive(),
          }),
    }),
});

export const validationSchema = yup.object().shape({
  trancheId: yup.string().label('Tranche').nullable().required(),
  instrumentId: yup.string().label('Demand Instrument').nullable().required(),
  currencyCode: yup.string().label('Demand Currency').nullable().required(),
  interestLevels: yup
    .array()
    .label('Interest Levels')
    .min(1)
    .of(InterestLevelValidationSchema)
    .required(),
});

export type IndicationCreateFormValues = IndicationFormValues & {
  sellSideContacts: InvestorContact[];
};

export const createIndicationValidationSchema = validationSchema.shape({
  sellSideContacts: contactsArraySchema,
});
