import React from 'react';

import { getOfferingTypeFilterOptions } from '../../../../common/util/offering/offering-type.util';
import { OfferingStatus } from '../../../../graphql';
import { MyOrders_PricedOfferingsListQueryVariables } from '../../graphql';
import { MyOrdersFilters } from '../../hooks/useFilters';
import { MyOrdersGridCoveredIndication } from '../my-orders-grid/MyOrdersGrid.model';
import InvestorFilter from './components/investor-filter/InvestorFilter';
import IssuerFilter from './components/issuer-filter/IssuerFilter';
import OfferingStatusFilter from './components/offering-status-filter/OfferingStatusFilter';
import OfferingTypeFilter from './components/offering-type-filter/OfferingTypeFilter';
import PricingDateFilter from './components/pricing-date-filter/PricingDateFilter';
import { SMyOrdersFilters } from './MyOrdersFilters.styles';

export type Props = {
  onChange: (filters: MyOrdersFilters) => void;
  onDateChange: (dateRange: MyOrders_PricedOfferingsListQueryVariables) => void;
  filters: MyOrdersFilters;
  indications?: MyOrdersGridCoveredIndication[];
};

const MyOrdersGridFilter: React.FC<Props> = ({ onChange, onDateChange, indications, filters }) => {
  const offeringTypes: string[] = React.useMemo(
    () =>
      Array.from(
        new Set(
          indications?.map(indication => {
            const { offeringType, pricingInstrumentCountryCode } = indication;

            return getOfferingTypeFilterOptions({
              offeringType,
              pricingInstrumentCountryCode,
            });
          })
        )
      ),
    [indications]
  );

  const offeringStatuses: OfferingStatus[] = React.useMemo(
    () => Array.from(new Set(indications?.map(indication => indication.offeringStatus))),
    [indications]
  );

  return (
    <SMyOrdersFilters>
      <PricingDateFilter
        onChange={value => {
          onChange({ ...filters, pricingDate: value });
          onDateChange({
            startPricingDate: value.start!,
            endPricingDate: value.end!,
          });
        }}
        values={filters.pricingDate}
      />
      <IssuerFilter
        issuerName={filters.issuer ?? ''}
        onChange={value => onChange({ ...filters, issuer: value })}
      />
      <InvestorFilter
        investorName={filters.investor ?? ''}
        onChange={value => onChange({ ...filters, investor: value })}
      />
      <OfferingTypeFilter
        values={filters.type}
        offeringTypes={offeringTypes}
        onChange={value => onChange({ ...filters, type: value })}
      />
      <OfferingStatusFilter
        values={filters.status}
        offeringStatuses={offeringStatuses}
        onChange={value => onChange({ ...filters, status: value })}
      />
    </SMyOrdersFilters>
  );
};

export default MyOrdersGridFilter;
