import { Icon } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SalesCreditReleaseStatus } from '../../../../../../graphql';
import { getReleaseStatusColorMap, releaseStatusMap } from './ReleaseStatus.model';
import { SIconWrapper } from './ReleaseStatus.styles';

export type Props = { status: SalesCreditReleaseStatus };

const ReleaseStatus: React.FC<Props> = ({ status }) => {
  const theme = useTheme();
  const colorsStatusMap = getReleaseStatusColorMap(theme);

  return (
    <div>
      <SIconWrapper>
        <Icon name="circle" variant="solid" size="xs" color={colorsStatusMap.get(status)} />
      </SIconWrapper>
      {releaseStatusMap.get(status)}
    </div>
  );
};

export default ReleaseStatus;
