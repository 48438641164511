import { getOperationName } from '@apollo/client/utilities';

import {
  RegulatoryFilings_RegulatoryFilingsDocument,
  RegulatoryFilings_RolodexCommonFilingDocument,
} from '../../../graphql';
import { useRegulatoryFilings_SubmitNoiFilingMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchRegulatoryFilings_RegulatoryFilingsQuery = getOperationName(
    RegulatoryFilings_RegulatoryFilingsDocument
  );
  const refetchCommonFilingQuery = getOperationName(RegulatoryFilings_RolodexCommonFilingDocument);

  return [refetchRegulatoryFilings_RegulatoryFilingsQuery!, refetchCommonFilingQuery!];
};

export const useSubmitNOIFilingMutation = () => {
  return useRegulatoryFilings_SubmitNoiFilingMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
