import { useAuth } from '@cmg/auth';
import { UUID } from '@cmg/common';

import { useIsUserSettlementAgent_OfferingSettlementAgentQuery } from './graphql';

export function useIsUserSettlementAgent({ offeringId }: { offeringId?: UUID }) {
  const { data, loading, error } = useIsUserSettlementAgent_OfferingSettlementAgentQuery({
    variables: { offeringId: offeringId! },
    skip: !offeringId,
  });
  const { oidcUserCmgEntityKey } = useAuth();

  if (loading) {
    return {
      loading,
      error: undefined,
      isSettlementAgent: undefined,
    };
  }

  if (error) {
    return {
      loading: false,
      error,
      isSettlementAgent: undefined,
    };
  }

  return {
    loading: false,
    error: undefined,
    isSettlementAgent:
      oidcUserCmgEntityKey ===
      data?.publishedOffering?.syndicate.managerResponsibilities.settlementAgent,
  };
}
