import styled, { css } from 'styled-components/macro';

import { BannerVariant } from './Banner.model';

type ContainerProps = {
  readonly variant: BannerVariant;
  readonly withoutMargin?: boolean;
  readonly padding?: string;
};

export const SContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: self-start;
  background-color: ${({ variant, theme }) => {
    switch (variant) {
      case 'error':
        return theme.designSystem.colors.red['000'];

      case 'warning':
        return theme.designSystem.colors.yellow['000'];

      case 'success':
        return theme.designSystem.colors.green['000'];

      default:
        return theme.designSystem.colors.turquoise['000'];
    }
  }};
  padding: ${p => p.padding ?? '11px 16px'};
  ${({ withoutMargin }) =>
    withoutMargin
      ? ''
      : css`
          margin: 16px 0;
        `}
`;

export const SIconContainer = styled.div`
  margin-right: 16px;
`;

export const SInnerContainer = styled.div`
  flex: 1;
`;

export const STitle = styled.p`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
  margin: 0;
`;
