import { useCheckAccountType } from '@cmg/auth';

import { useFeatureToggles } from '../../../../../../../../../../../common/config';
import { useWithOrderBook_IndicationChangedSubscription } from '../../../../../../../../../../../features/order-book/graphql/__generated__';
import {
  IndicationForm_IndicationAcknowledgementsQueryVariables,
  useIndicationForm_IndicationAcknowledgementsQuery,
} from '../graphql/__generated__';

export const useInstitutionalIndicationAcknowledgementsQuery = (
  variables: IndicationForm_IndicationAcknowledgementsQueryVariables,
  skip: boolean = false
) => {
  const { isOrderBookIndicationActivitySubscriptionOn } = useFeatureToggles();

  const isSellSideAccount = useCheckAccountType('SELL_SIDE');

  let queryResult = useIndicationForm_IndicationAcknowledgementsQuery({
    variables,
    pollInterval: isOrderBookIndicationActivitySubscriptionOn ? undefined : 5000,
    fetchPolicy: isOrderBookIndicationActivitySubscriptionOn ? 'cache-and-network' : 'network-only',
    skip: !isSellSideAccount || skip,
  });

  queryResult = useWithOrderBook_IndicationChangedSubscription(queryResult, {
    variables: () => variables,
    isEnabled: isOrderBookIndicationActivitySubscriptionOn,
    loadingStateBehavior: 'false-on-refetch',
    updateQuery: refetch => prev => refetch(prev),
  });

  return queryResult;
};
