import { Skeleton, Stack } from '@cmg/design-system';

const CustomVariablesSkeleton: React.FC = () => {
  return (
    <Stack role="progressbar" gap={2}>
      <Skeleton variant="rectangular" width="50%" />
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={28} />
      ))}
    </Stack>
  );
};

export default CustomVariablesSkeleton;
