import {
  CircularProgress,
  FileDownloadOutlinedIcon,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from '@cmg/design-system';
import React from 'react';

export type ExportButtonProps = Readonly<{
  isExportInstitutionalDemandDisabled: boolean;
  loading: boolean;
  onExportInstitutionalDemand: () => void;
  onExportRevisionHistory: () => void;
  onExportProspectusEmails: () => void;
}>;

const ExportButton: React.FC<ExportButtonProps> = ({
  isExportInstitutionalDemandDisabled,
  loading,
  onExportInstitutionalDemand,
  onExportRevisionHistory,
  onExportProspectusEmails,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton size="small" onClick={handleClick} aria-label="Export options">
        {loading && (
          <CircularProgress
            variant="indeterminate"
            size={theme.spacing(4)}
            sx={{ position: 'absolute' }}
          />
        )}
        <FileDownloadOutlinedIcon color="primary" />
      </IconButton>
      <Menu id="export-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onExportInstitutionalDemand();
          }}
          disabled={isExportInstitutionalDemandDisabled}
        >
          Export Orderbook Grid
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onExportRevisionHistory();
          }}
        >
          Export Revision History
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onExportProspectusEmails();
          }}
        >
          Export Prospectus Email History
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ExportButton;
