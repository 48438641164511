import { UUID } from '@cmg/common';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SidePanel, {
  Props as SidePanelProps,
} from '../../../common/components/layout/side-panel/SidePanel';
import routeFactory from '../../../common/util/routeFactory';
import { Investor, TradeReleaseHistory } from './TradeReleaseHistory';
import { SContent, SLayout, SNavbar } from './TradeReleaseHistorySidePanel.styles';
import { TradeReleaseHistorySidePanelNavbar } from './TradeReleaseHistorySidePanelNavbar';

export type Props = Pick<SidePanelProps, 'isOpen' | 'onClose'> & {
  offeringId: UUID;
  indicationId: UUID;
  investors: Investor[];
};

export const TradeReleaseHistorySidePanel: React.FC<Props> = ({
  isOpen,
  onClose,
  offeringId,
  indicationId,
  investors,
}) => {
  return (
    <SidePanel isOpen={isOpen} onClose={onClose}>
      <SLayout>
        <SNavbar>
          <TradeReleaseHistorySidePanelNavbar offeringId={offeringId} indicationId={indicationId} />
        </SNavbar>
        <SContent>
          <Switch>
            <Route path={routeFactory.tradeReleaseHistory.routePath}>
              <TradeReleaseHistory
                offeringId={offeringId}
                indicationId={indicationId}
                investors={investors}
              />
            </Route>
          </Switch>
        </SContent>
      </SLayout>
    </SidePanel>
  );
};
