import { makeVar, useReactiveVar } from '@apollo/client';
import { useStoredPreference } from '@cmg/auth';
import React from 'react';

import { useIsUserSettlementAgent } from '../../../../../../../src/common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { defaultInstitutionalGridColumns } from '../InstitutionalDemandGrid.model';
import { CMGColumnID, getDraftAllocationId, InstitutionalDraftSet } from '../types';

export const draftSetsVar = makeVar<readonly InstitutionalDraftSet[] | null>(null);

export const useInstitutionalDemandColumns = (offeringId: string) => {
  const [visibleColumns, setVisibleColumns, isPreferenceSetInStorage] = useStoredPreference<
    string[]
  >({
    key: `visibleColumns_${offeringId}`,
    initialValue: defaultInstitutionalGridColumns,
  });
  const { isSettlementAgent, loading: isLoadingIsSettlementAgent } = useIsUserSettlementAgent({
    offeringId,
  });
  const draftSets = useReactiveVar(draftSetsVar);

  React.useEffect(() => {
    if (isPreferenceSetInStorage || !draftSets || isLoadingIsSettlementAgent) {
      return;
    }

    if (isSettlementAgent) {
      setVisibleColumns([...defaultInstitutionalGridColumns, CMGColumnID.FINAL_ALLOCATION]);
      return;
    }

    const [defaultDraftSet] = draftSets;
    if (!defaultDraftSet || !defaultDraftSet.id) {
      setVisibleColumns(defaultInstitutionalGridColumns);
      return;
    }

    const draftAllocationId = getDraftAllocationId(defaultDraftSet.id);
    setVisibleColumns([...defaultInstitutionalGridColumns, draftAllocationId]);
  }, [
    isPreferenceSetInStorage,
    draftSets,
    setVisibleColumns,
    isSettlementAgent,
    isLoadingIsSettlementAgent,
  ]);

  return { visibleColumns, setVisibleColumns };
};
