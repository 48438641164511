import { ButtonsContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledButtonsContainer = styled(ButtonsContainer)`
  flex: 1;
`;

export const SProspectusHistoryWrapper = styled.div`
  margin: 16px;
`;
