import { PrimaryButton, UUID } from '@cmg/common';
import React from 'react';

import { Roadshows_RoadshowsDealTeamPartsFragment } from '../../../../common/graphql';
import RoadshowCreationStep from '../../common/creation-step/RoadshowCreationStep';
import AddMembersModal from '../add-members-modal/AddMembersModal';
import CreateDealTeamMembersModal from '../create-deal-team-members-modal/CreateDealTeamMembersModal';
import DealTeamListSection from './deal-team-list-section/DealTeamListSection';
import { getSelectedTeamMembers } from './SingleDealTeamDetails.model';

export type Props = {
  roadshowId: UUID;
  participatingDealTeams: readonly Roadshows_RoadshowsDealTeamPartsFragment[];
};

const SingleDealTeamDetails: React.FC<Props> = ({ roadshowId, participatingDealTeams }) => {
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = React.useState<boolean>(false);
  const [isCreateMembersModalOpen, setIsCreateMembersModalOpen] = React.useState<boolean>(false);

  const handleOpenAddMembersModal = () => {
    setIsAddMembersModalOpen(true);
  };

  const handleCloseAddMembersModal = () => {
    setIsAddMembersModalOpen(false);
  };

  const handleOpenCreateMembersModal = () => {
    setIsAddMembersModalOpen(false);
    setIsCreateMembersModalOpen(true);
  };

  const handleCloseCreateMembersModal = () => {
    setIsCreateMembersModalOpen(false);
  };

  return (
    <RoadshowCreationStep data-test-id="roadshows / single-roadshow / configuration / deal-team">
      <RoadshowCreationStep.Header title="Deal Team">
        <PrimaryButton testId="add members" onClick={handleOpenAddMembersModal}>
          Add Members
        </PrimaryButton>
      </RoadshowCreationStep.Header>

      <RoadshowCreationStep.Content>
        <DealTeamListSection roadshowId={roadshowId} dealTeamsList={participatingDealTeams} />
      </RoadshowCreationStep.Content>

      <AddMembersModal
        participatingTeamMembers={getSelectedTeamMembers(participatingDealTeams)}
        show={isAddMembersModalOpen}
        onHide={handleCloseAddMembersModal}
        onOpenCreateMembersModal={handleOpenCreateMembersModal}
        roadshowId={roadshowId}
      />
      <CreateDealTeamMembersModal
        show={isCreateMembersModalOpen}
        onHide={handleCloseCreateMembersModal}
        participatingDealTeams={participatingDealTeams}
        roadshowId={roadshowId}
      />
    </RoadshowCreationStep>
  );
};

export default SingleDealTeamDetails;
