import { defined } from '@cmg/common';
import React, { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../common/config';
import { OfferingType } from '../../../../../../graphql';
import { getGridConfig } from '../InstitutionalDemandGrid.model';
import { draftSetsVar } from '../preferences/useInstitutionalDemandColumns';
import {
  useGetDemandGridSummaryData,
  useHandleDataRefresh,
  useOnDemandRefresh,
} from './InstitutionalDemandHooks';
import { useDemandConfiguration } from './useDemandConfiguration';
import { useInstitutionalDemandGridPrimaryQueries } from './useInstitutionalDemandGridPrimaryQueries';
import { useInstitutionalDemandGridSecondaryQueries } from './useInstitutionalDemandGridSecondaryQueries';

type Args = {
  offeringId: string;
  offeringType?: OfferingType;
};

export const useInstitutionalDemandData = ({ offeringId, offeringType }: Args) => {
  const {
    errors: primaryErrors,
    loading: loadingPrimaryQueries,
    gridColumnConfigData,
    canManagePassiveOrderBook,
    institutionalDemandGridSummaryData,
    hasCalled: hasCalledPrimaryQueries,
    getDemandGridSummary,
    startDemandSummaryGridPolling,
    stopDemandSummaryGridPolling,
    refetchDemandGridSummary,
  } = useInstitutionalDemandGridPrimaryQueries({ offeringId });

  const bankInvestorKeys = useMemo(
    () =>
      defined(
        institutionalDemandGridSummaryData?.institutionalDemandGridSummary.institutionalIndicationDemands.map(
          indication => indication.investorInformation.bankInvestorKey
        )
      ),
    [
      institutionalDemandGridSummaryData?.institutionalDemandGridSummary
        .institutionalIndicationDemands,
    ]
  );

  const cmgEntityKeys = useMemo(
    () =>
      defined(
        institutionalDemandGridSummaryData?.institutionalDemandGridSummary.institutionalIndicationDemands.map(
          indication => indication.investorInformation.cmgEntityKey!
        )
      ),
    [
      institutionalDemandGridSummaryData?.institutionalDemandGridSummary
        .institutionalIndicationDemands,
    ]
  );

  const {
    errors: secondaryErrors,
    loading: loadingSecondaryQueries,
    attestationStatusesData,
    getAttestationStatuses,
    prospectusOnSendValidation,
    investorsExtendedData,
    isProspectusDocumentUploaded,
    resendProspectus,
    bulkResendProspectusLoading,
    investorsExtendedDataLoading,
    attestationStatusesLoading,
    getInvestorFirmsEmployeeRelationships,
    investorFirmsEmployeeRelationshipsData,
    investorFirmsEmployeeRelationshipsLoading,
  } = useInstitutionalDemandGridSecondaryQueries({
    institutionalDemandGridSummaryData,
    offeringId,
    bankInvestorKeys,
    cmgEntityKeys,
  });

  const gridSummaryData = useGetDemandGridSummaryData(
    institutionalDemandGridSummaryData,
    attestationStatusesData,
    investorFirmsEmployeeRelationshipsData
  );
  const offeringGridConfiguration = React.useMemo(() => {
    if (gridColumnConfigData?.publishedOffering) {
      if (gridColumnConfigData.syndicateManagers) {
        return getGridConfig(
          gridColumnConfigData.publishedOffering,
          gridColumnConfigData.syndicateManagers
        );
      }
      return getGridConfig(gridColumnConfigData.publishedOffering);
    }
    return null;
  }, [gridColumnConfigData?.publishedOffering, gridColumnConfigData?.syndicateManagers]);

  const gridDemandConfiguration = useDemandConfiguration(
    offeringId,
    offeringGridConfiguration,
    offeringType
  );

  const settlementAgent =
    gridColumnConfigData?.publishedOffering?.syndicate.managerResponsibilities.settlementAgent ??
    null;
  const { value: institutionalDemandGridSummaryCurrentData, refresh: onDemandRefresh } =
    useOnDemandRefresh(institutionalDemandGridSummaryData);
  const dataRefresh = useHandleDataRefresh(
    institutionalDemandGridSummaryCurrentData?.institutionalDemandGridSummary,
    institutionalDemandGridSummaryData?.institutionalDemandGridSummary,
    onDemandRefresh,
    settlementAgent
  );

  const { isOrderBookGridOptimizationOn } = getFeatureToggles();

  React.useEffect(() => {
    if (offeringId && gridDemandConfiguration.isColumnConfigurationApplied) {
      getDemandGridSummary({
        variables: {
          input: {
            useNewStrategy: isOrderBookGridOptimizationOn,
            ...gridDemandConfiguration.demandColumnConfig,
          },
          offeringId,
        },
      });
    }
  }, [
    offeringId,
    getDemandGridSummary,
    gridDemandConfiguration.demandColumnConfig,
    gridDemandConfiguration.isColumnConfigurationApplied,
    isOrderBookGridOptimizationOn,
  ]);

  React.useEffect(() => {
    if (
      institutionalDemandGridSummaryData &&
      institutionalDemandGridSummaryData.institutionalDemandGridSummary
    ) {
      getAttestationStatuses({
        variables: {
          cmgEntityKeys,
        },
      });

      getInvestorFirmsEmployeeRelationships({
        variables: {
          bankInvestorKeys,
        },
      });
    }
  }, [
    institutionalDemandGridSummaryData,
    getAttestationStatuses,
    getInvestorFirmsEmployeeRelationships,
    bankInvestorKeys,
    cmgEntityKeys,
  ]);

  const investors = gridSummaryData?.indicationDemands.map(demand => demand.investorInformation);

  React.useEffect(() => {
    if (!gridSummaryData || gridSummaryData.draftSets?.length === 0) {
      return;
    }
    draftSetsVar(gridSummaryData.draftSets);
  }, [gridSummaryData, gridSummaryData?.draftSets]);

  const loadingMap = React.useMemo(() => {
    return {
      grid: loadingPrimaryQueries || !hasCalledPrimaryQueries,
      primaryQueries: loadingPrimaryQueries,
      secondaryQueries: loadingSecondaryQueries,
      secondaryQueriesMap: {
        prospectusData: bulkResendProspectusLoading,
        investorsExtendedData: investorsExtendedDataLoading,
        attestationStatuses: attestationStatusesLoading,
        investorFirmsEmployeeRelationships: investorFirmsEmployeeRelationshipsLoading,
      },
    };
  }, [
    loadingPrimaryQueries,
    hasCalledPrimaryQueries,
    loadingSecondaryQueries,
    bulkResendProspectusLoading,
    investorsExtendedDataLoading,
    attestationStatusesLoading,
    investorFirmsEmployeeRelationshipsLoading,
  ]);

  return {
    investors,
    loadingMap,
    dataRefresh,
    prospectusOnSendValidation,
    investorsExtendedData,
    canManagePassiveOrderBook,
    settlementAgent,
    gridSummaryData,
    gridDemandConfiguration,
    offeringGridConfiguration,
    isProspectusDocumentUploaded,
    primaryErrors,
    secondaryErrors,
    bulkResendProspectusLoading,
    startDemandSummaryGridPolling,
    stopDemandSummaryGridPolling,
    resendProspectus,
    refetchDemandGridSummary,
    onDemandRefresh,
  };
};
