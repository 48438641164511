import { getOperationName } from '@apollo/client/utilities';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../../graphql';
import { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../../institutional-demand-v2/graphql/__generated__';
import type { InstitutionalDraftSet } from '../../../../../types';
import SaveAllocationSetDialog, {
  Props as AllocationSetDialogProps,
} from '../SaveAllocationSetDialog';
import { getDealLayoutInput } from '../SaveAllocationSetDialog.model';
import {
  useOrderBook_UpdateDraftAllocationSetDealLayoutMutation,
  useOrderBook_UpdateDraftAllocationSetNameMutation,
} from './graphql';

export type Props = Pick<AllocationSetDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    allocationSet:
      | InstitutionalDraftSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const EditDraftAllocationSetDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  allocationSet,
  offeringId,
}) => {
  const [editDraftName] = useOrderBook_UpdateDraftAllocationSetNameMutation();
  const [editDraftDealLayout] = useOrderBook_UpdateDraftAllocationSetDealLayoutMutation({
    refetchQueries: [getOperationName(OrderBook_OrderBookRouteDocument)!],
  });

  const handleSubmit = React.useCallback<AllocationSetDialogProps['onSubmit']>(
    async values => {
      try {
        if (!allocationSet!.isDefault) {
          await editDraftName({
            variables: { offeringId, setId: allocationSet?.id!, name: values.name! },
          });
        }

        await editDraftDealLayout({
          variables: {
            offeringId,
            setId: allocationSet?.id!,
            payload: getDealLayoutInput(values),
          },
        });

        onClose();
        SnackbarManager.success('Successfully edited Draft Allocation set');
      } catch {
        SnackbarManager.error('Failed to edit Draft Allocation set');
      }
    },
    [allocationSet, editDraftDealLayout, editDraftName, offeringId, onClose]
  );

  if (!allocationSet) {
    return null;
  }

  return (
    <SaveAllocationSetDialog
      offeringId={offeringId}
      title="Edit Draft Allocation Set dialog"
      isEdit
      canSetName={!allocationSet.isDefault}
      isOpen={isOpen}
      onClose={onClose}
      name={allocationSet.name}
      dealLayout={allocationSet.dealLayout}
      onSubmit={handleSubmit}
    />
  );
};

export default EditDraftAllocationSetDialog;
