import React from 'react';

import FilingCommonFieldsForm from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm';
import FilingDetailsForm from '../../../common/content/filing-details-form/FilingDetailsForm';
import TransactionForm from './transaction/TransactionForm';

export type Props = Readonly<{
  readonly stockSymbols: readonly string[];
}>;

const USDTNFilingForm: React.FC<Props> = ({ stockSymbols }) => {
  return (
    <div>
      <FilingCommonFieldsForm />
      <FilingDetailsForm stockSymbols={stockSymbols} />
      <TransactionForm />
    </div>
  );
};

export default USDTNFilingForm;
