import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SIssuerNameFilter = styled.div`
  width: 220px;
`;

export const SIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SSeparator = styled.span`
  background-color: #e2e2e2;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 1px;
  min-height: 22px;
`;

export const StyledSearchIcon = styled(Icon).attrs({ name: 'search' })`
  margin: 8px 10px;
`;
