import { useAuth } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import React from 'react';

import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { isIndicationHiddenToSalesAndBS } from '../../../../../utils';
import { useFinalAllocations } from '../../../../contexts/finalAllocationsContext';
import { IndicationWithDemandLevels } from '../../types';
import AllocationForm from './AllocationForm';
import {
  getAllocationUpdateInput,
  isFinalAllocationAllocable,
  isIndicationAllocatable,
} from './AllocationForm.model';
import AllocationFormTooltip from './AllocationFormTooltip';
import { useUpdateFinalAllocationMutation } from './hooks/useUpdateFinalAllocationMutation';

export type Props = {
  data: IndicationWithDemandLevels;
};

/**
 * Container that renders the Allocation Form for a Final Allocation.
 *
 * Changes in grid context (here available via `agGridReact.props.gridOptions.context`)
 * don't trigger re-rendering of cell containers - get context via `useFinalAllocations`
 */
const FinalAllocationFormCellContainer: React.FC<Props> = ({ data }) => {
  const [finalAllocationsState, finalAllocationsDispatch] = useFinalAllocations();
  const [updateFinalAllocation, { loading: isSavingAllocation }] =
    useUpdateFinalAllocationMutation();

  const { id: indicationId, finalAllocation, offeringId } = data;
  const { canAcknowledge, canRead, canManage } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { oidcUserCmgEntityKey } = useAuth();
  const isAuthor =
    !!oidcUserCmgEntityKey && oidcUserCmgEntityKey === data.finalAllocationAuthorCmgEntityKey;

  const handleUpdate = React.useCallback(
    async (shareQuantity: number | null) => {
      const input = getAllocationUpdateInput(shareQuantity);

      try {
        await updateFinalAllocation({
          variables: {
            indicationId,
            offeringId,
            input,
          },
        });
      } catch {
        ToastManager.error('Failed to update final allocation.');
      }
    },
    [indicationId, offeringId, updateFinalAllocation]
  );

  const handleUpdateFinalAllocationShareQuantity = React.useCallback(
    async ({
      initialValue,
      newValue: shareQuantity,
    }: {
      initialValue: number | null;
      newValue: number | null;
    }) => {
      if (!finalAllocationsState.isFinalAllocationSetReleased) {
        await handleUpdate(shareQuantity);
      } else {
        // We're editing the value
        // store it for a batch update
        finalAllocationsDispatch({
          type: 'upsertChangesByIndicationId',
          payload: {
            indicationId,
            initialValue,
            newValue: shareQuantity,
          },
        });
      }
    },
    [
      finalAllocationsDispatch,
      finalAllocationsState.isFinalAllocationSetReleased,
      handleUpdate,
      indicationId,
    ]
  );

  const shareQuantity = finalAllocation?.shareQuantity ?? null;

  const { isAllocable } = React.useMemo(() => {
    return isIndicationAllocatable({ isAuthor });
  }, [isAuthor]);

  const { isFinalAllocationSetReleased } = finalAllocationsState;
  const { isAllocable: isFinalAllocable } = React.useMemo(() => {
    return isFinalAllocationAllocable({
      canRead,
      canManage,
      canAcknowledge,
      isEditing: finalAllocationsState.isEditing,
      isFinalAllocationSetReleased,
    });
  }, [
    canAcknowledge,
    canManage,
    canRead,
    finalAllocationsState.isEditing,
    isFinalAllocationSetReleased,
  ]);

  const isHiddenToSalesAndBS = React.useMemo(
    () => isIndicationHiddenToSalesAndBS(data.status, data.type, shareQuantity),
    [data.status, data.type, shareQuantity]
  );

  return (
    <AllocationForm
      shareQuantity={shareQuantity}
      modifiedAt={data.finalAllocation?.auditInfo.modifiedAt}
      disabled={!isAllocable || !isFinalAllocable}
      isSaving={isSavingAllocation || finalAllocationsState.isUpsertChangesByIndicationIdLoading}
      isEditing={finalAllocationsState.isEditing}
      resetFormToggle={finalAllocationsState.resetFormToggle}
      onUpdateAllocationShareQuantity={handleUpdateFinalAllocationShareQuantity}
      WarningIconComponent={
        <React.Fragment>
          {isHiddenToSalesAndBS && (
            <AllocationFormTooltip>
              <div>
                <Typography variant="h4">Allocation</Typography>
                <Typography>This indication is a Pass, Duplicate, or Cancelled.</Typography>
                <Typography variant="caption">
                  (This allocation will not be shown to XC Sales & trading or Buy-Side users)
                </Typography>
              </div>
            </AllocationFormTooltip>
          )}
        </React.Fragment>
      }
    />
  );
};

export default FinalAllocationFormCellContainer;
