import {
  SyndicateWires_UpdateRetentionWireMutation,
  useSyndicateWires_UpdateRetentionWireMutation,
} from '../graphql';

export type UpdateRetentionWireMutation = SyndicateWires_UpdateRetentionWireMutation;

export const useUpdateRetentionWireMutation = () => {
  return useSyndicateWires_UpdateRetentionWireMutation();
};
