import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import { Roadshows_CreateMeetingMutation, useRoadshows_CreateMeetingMutation } from '../graphql';

export type CreateMeetingMutation = Roadshows_CreateMeetingMutation;

export const refetchQueries = () => {
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetings!];
};

export const useCreateMeetingMutation = () => {
  return useRoadshows_CreateMeetingMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
