import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { SecondaryButton, UUID } from '@cmg/common';
import React from 'react';

import ReleaseSettingsModal from './ReleaseSettingsModal';

export type Props = { offeringId: UUID };

const ReleaseSettings: React.FC<Props> = ({ offeringId }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const canChangeReleaseSettings = useCheckPermissions([permissionsByEntity.Trade.FULL]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (canChangeReleaseSettings) {
    return (
      <div>
        <ReleaseSettingsModal
          show={isModalOpen}
          onCloseModal={handleCloseModal}
          offeringId={offeringId}
        />
        <SecondaryButton
          iconRight={{ name: 'cog', size: 'lg' }}
          onClick={handleOpenModal}
          title="Release Settings"
        />
      </div>
    );
  }

  return null;
};

export default ReleaseSettings;
