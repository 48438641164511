import styled from 'styled-components/macro';

export const SInvestorSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 16px;
`;

export const SInvestorSearchLabel = styled.div`
  border: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
  padding: 6px 10px;
  border-radius: 4px;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }) => theme.background.color.light};
  color: ${({ theme }) => theme.text.color.placeholder};
`;

export const SInvestorDropdownContainer = styled.div`
  flex: 1;
  & div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
