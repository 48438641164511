import { UUID } from '@cmg/common';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import CancelIndicationConfirmDialogComponent from '../../../common/cancel-indication-confirm-dialog/CancelIndicationConfirmDialog';
import { useUpdateIndicationStatus } from '../hooks/useUpdateIndicationStatus';

export type Props = Readonly<{
  offeringId: UUID;
  indicationId: UUID;
  isOpen: boolean;
  onClose: () => void;
}>;

const CancelIndicationConfirmDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  offeringId,
  indicationId,
}) => {
  const [onUpdateStatus, isUpdatingStatus] = useUpdateIndicationStatus(
    offeringId,
    indicationId,
    onClose
  );

  return (
    <CancelIndicationConfirmDialogComponent
      isOpen={isOpen}
      isCancelling={isUpdatingStatus}
      onCancel={() => onUpdateStatus(IndicationStatus.Cancelled)}
      onClose={onClose}
    />
  );
};

export default CancelIndicationConfirmDialog;
