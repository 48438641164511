import React from 'react';

import { FormikDateTimeZonePickerField } from '../../common/components/date-time-zone-picker/FormikDateTimeZonePickerField';
import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';

export const TerminationWireForm: React.VFC = () => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>

      <FormikDateTimeZonePickerField
        name="terminationDate"
        dateTimePickerProps={{
          label: 'Termination Date and Time',
          required: true,
        }}
        timeZoneSelectProps={{
          label: 'Time Zone',
          required: true,
        }}
      />

      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
