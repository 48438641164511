import { SnackbarManager } from '@cmg/design-system';
import { saveAs } from 'file-saver';
import React from 'react';

import { wiresApiClient } from '../../../../../../../../common/api/wiresApiClient';
import { getFormattedDate } from '../utils/getFormattedDate';

export function useExportInstitutionalDemandProspectusEmails({ offeringId }) {
  const [loading, setLoading] = React.useState(false);
  const handleExport = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await wiresApiClient.prospectusDelivery_ExportProspectusEmails(offeringId);
      saveAs(response.data, `ProspectusEmailHistory_${offeringId}_${getFormattedDate()}.zip`);
      SnackbarManager.success('Download successfully completed.');
    } catch {
      SnackbarManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [offeringId]);
  return { handleExport, loading };
}
