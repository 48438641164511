import styled from 'styled-components/macro';

export const SSearchWrapper = styled.div`
  margin-right: 52px;
`;

export const SHeading = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 16px;
`;

export const SHeadingText = styled.h1`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;
