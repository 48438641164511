import styled from 'styled-components/macro';

export const STotalShortPositionCell = styled.td`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const STotalShortPositionValueCell = styled.td`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  text-align: right;
`;
