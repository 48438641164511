import { Checkbox, timeUtil } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import { ManagerRole } from '../../../../graphql';
import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import {
  OfferingSetup_Summary_ManagerLimitedFragment,
  OfferingSetup_Summary_OfferingShareHistoryFragment,
} from '../../graphql';
import {
  SCheckboxItemContainer,
  SGroupHeaderContent,
  SManagerGroupHeader,
  SManagerItem,
  SShareIndicationContainer,
  SShareIndicator,
} from './ShareDraftManagerGroup.styles';

export type Props = {
  managerGroup: OfferingSetup_Summary_ManagerLimitedFragment[];
  managerRole: ManagerRole;
  onSelectManagers: (managerKeys: string[]) => void;
  selectedManagerKeys: string[];
  lastShareHistoryData: OfferingSetup_Summary_OfferingShareHistoryFragment | null;
};
const ShareDraftManagerGroup = ({
  managerGroup,
  managerRole,
  onSelectManagers,
  lastShareHistoryData,
  selectedManagerKeys,
}: Props) => {
  const [areAllManagersSelected, setAllManagersSelected] = React.useState(false);
  const [isPartiallySelected, setIsPartiallySelected] = React.useState(false);
  const [areAllManagersSharedWith, setAreAllManagersSharedWith] = React.useState(false);
  const [areSomeManagersSharedWith, setAreSomeManagersSharedWith] = React.useState(false);
  const [usableManagers, setUsableManagers] = React.useState(managerGroup);

  const handleSelectManager = (manager: OfferingSetup_Summary_ManagerLimitedFragment) => {
    if (lastShareHistoryData?.managerKeys.includes(manager.cmgEntityKey)) {
      return;
    }
    onSelectManagers([manager.cmgEntityKey]);
  };

  const handleSelectManagerGroup = (managers: OfferingSetup_Summary_ManagerLimitedFragment[]) => {
    if (areAllManagersSharedWith) {
      return;
    }
    // Only send new managers when selecting a group, filter already selected managers
    const filteredManagers = managers.filter(
      manager =>
        !selectedManagerKeys.includes(manager.cmgEntityKey) &&
        !lastShareHistoryData?.managerKeys.includes(manager.cmgEntityKey)
    );
    // When its deselect select all managers
    const isDeselectGroup = usableManagers
      .map(manager => manager.cmgEntityKey)
      .every(managerKey => selectedManagerKeys.includes(managerKey));
    const managerKeys = (isDeselectGroup ? managers : filteredManagers).map(
      manager => manager.cmgEntityKey
    );

    onSelectManagers(managerKeys);
  };

  React.useEffect(() => {
    if (!selectedManagerKeys || !managerGroup?.length) {
      return;
    }
    const managersToUse = managerGroup.filter(
      manager => !lastShareHistoryData?.managerKeys.includes(manager.cmgEntityKey)
    );
    const areAllManagersIncluded = managersToUse.every(manager =>
      selectedManagerKeys.includes(manager.cmgEntityKey)
    );
    const areSomeManagersIncluded = managersToUse.some(manager =>
      selectedManagerKeys.includes(manager.cmgEntityKey)
    );
    const areAllManagersShared = managersToUse.every(manager =>
      lastShareHistoryData?.managerKeys.includes(manager.cmgEntityKey)
    );
    const areSomeManagersShared = managerGroup.some(manager =>
      lastShareHistoryData?.managerKeys.includes(manager.cmgEntityKey)
    );
    setAllManagersSelected(areAllManagersIncluded);
    setIsPartiallySelected(areSomeManagersIncluded && !areAllManagersIncluded);
    setUsableManagers(managersToUse);
    setAreAllManagersSharedWith(areAllManagersShared);
    setAreSomeManagersSharedWith(areSomeManagersShared && !areAllManagersShared);
  }, [
    selectedManagerKeys,
    managerGroup,
    setAllManagersSelected,
    setIsPartiallySelected,
    lastShareHistoryData,
  ]);

  const isGroupPartiallySelected =
    (isPartiallySelected || areSomeManagersSharedWith) && !areAllManagersSelected;
  return (
    <FlexLayout direction="column">
      <SManagerGroupHeader>
        <SGroupHeaderContent disabled={areAllManagersSharedWith}>
          <Checkbox
            data-testid={xcSelectors.offeringShareDraftManagerGroupCheckbox.testId}
            value={areAllManagersSelected || areAllManagersSharedWith}
            indeterminate={isGroupPartiallySelected}
            disabled={areAllManagersSharedWith}
            onClick={() => handleSelectManagerGroup(managerGroup)}
          />
          <div onClick={() => handleSelectManagerGroup(managerGroup)}>
            {managerRoleLabels[managerRole]}
          </div>
        </SGroupHeaderContent>
      </SManagerGroupHeader>
      {managerGroup.map(manager => {
        const isLastVersionSharedWithManager = lastShareHistoryData?.managerKeys.includes(
          manager.cmgEntityKey
        );
        const managerCheckboxValue =
          selectedManagerKeys.includes(manager.cmgEntityKey) || isLastVersionSharedWithManager;

        return (
          <SManagerItem key={manager.cmgEntityKey}>
            <SCheckboxItemContainer disabled={isLastVersionSharedWithManager}>
              <Checkbox
                data-test-id={xcSelectors.offeringShareDraftManagerItemCheckbox.testId}
                value={managerCheckboxValue}
                onClick={() => handleSelectManager(manager)}
                disabled={isLastVersionSharedWithManager}
              />
              <div
                data-test-id={xcSelectors.offeringShareDraftManagerItemCheckbox.testId}
                onClick={() => handleSelectManager(manager)}
              >
                {manager.firmName}
              </div>
            </SCheckboxItemContainer>
            {isLastVersionSharedWithManager && lastShareHistoryData && (
              <SShareIndicationContainer>
                <SShareIndicator name="circle" variant="solid" size="sm" />
                Shared on {timeUtil.formatAsDisplayDate(lastShareHistoryData.sharedTime)}
              </SShareIndicationContainer>
            )}
          </SManagerItem>
        );
      })}
    </FlexLayout>
  );
};

export default ShareDraftManagerGroup;
