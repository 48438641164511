import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Popover, SecondaryButton, UUID } from '@cmg/common';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import Loading from '../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import SideBarHeader from '../../../components/side-bar-header/SideBarHeader';
import { useSupersededOfferingContext } from '../../../contexts/SupersededOfferingContext';
import offeringSidePanelBuySideRouteFactory from '../../../offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from '../../../OfferingSidePanelContainer';
import { useComplianceContext } from '../context/ComplianceContext';
import { getValidationErrors } from './ComplianceProspectus.model';
import { SProspectusHistoryWrapper, StyledButtonsContainer } from './ComplianceProspectus.styles';
import { ProspectusHistory } from './components/prospectus-history/ProspectusHistory';
import { ProspectusHistoryValidationErrors } from './components/prospectus-history/ProspectusHistoryValidationErrors';
import { ProspectusHistoryZeroState } from './components/prospectus-history/ProspectusHistoryZeroState';
import { ProspectusValidationAlert } from './components/prospectus-validation-alert/ProspectusValidationAlert';
import { useProspectusDeliverySingleOnSendValidationQuery } from './hooks/useProspectusDeliverySingleOnSendValidationQuery';

export type Props = Readonly<{
  offeringId: UUID;
  cmgEntityKey: string;
  onResendProspectus: () => void;
  isResending: boolean;
}>;

const ComplianceProspectus: React.FC<Props> = ({
  offeringId,
  cmgEntityKey,
  onResendProspectus,
  isResending,
}) => {
  const canManageOrderBook = useCheckPermissions([permissionsByEntity.OrderBook.FULL]);
  const [isValidationAlertShown, setIsValidationAlertShown] = useState(false);
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);
  const linkToIndication = `${baseSidePanelUrl}${offeringSidePanelBuySideRouteFactory.orderBookIndicationActivity.getUrlPath(
    {
      cmgEntityKey,
    }
  )}`;

  const {
    canRead,
    loading: loadingComplianceContext,
    error: errorComplianceContext,
    prospectusDeliveryHistory = [],
    isSyndicatePersona,
  } = useComplianceContext().prospectusDelivery;

  const { isObsoleteOffering } = useSupersededOfferingContext();

  const {
    loading: loadingValidationResult,
    error: errorValidationResult,
    data: dataValidationResult,
    refetch: refetchValidationResult,
  } = useProspectusDeliverySingleOnSendValidationQuery({
    offeringId,
    investorKey: cmgEntityKey,
    isSyndicatePersona,
    currentTenantOnly: true,
  });

  useEffect(() => {
    setIsValidationAlertShown(false);
  }, [cmgEntityKey]);

  const validationErrors = useMemo(
    () => getValidationErrors(cmgEntityKey, isSyndicatePersona, dataValidationResult),
    [cmgEntityKey, dataValidationResult, isSyndicatePersona]
  );

  const resendProspectus = useCallback(async () => {
    const { data, error } = await refetchValidationResult({
      offeringId,
      investorKey: cmgEntityKey,
      isSyndicatePersona,
    });

    if (error) {
      return;
    }

    const errors = getValidationErrors(cmgEntityKey, isSyndicatePersona, data);
    if (!errors.hasValidationError) {
      onResendProspectus();
    }

    setIsValidationAlertShown(errors.hasValidationError);
  }, [
    cmgEntityKey,
    isSyndicatePersona,
    offeringId,
    onResendProspectus,
    refetchValidationResult,
    setIsValidationAlertShown,
  ]);

  if (!canRead) {
    return null;
  }

  if (loadingComplianceContext || (loadingValidationResult && !dataValidationResult)) {
    return <Loading />;
  }

  const error = errorComplianceContext ?? errorValidationResult;
  const hasHistoryRecord = prospectusDeliveryHistory && prospectusDeliveryHistory.length > 0;

  return (
    <div>
      {error && <ServerErrorsBanner error={error} />}
      <SideBarHeader title="Prospectus Delivery" padding="10px 16px 10px 16px">
        <StyledButtonsContainer justifyContent="right">
          {canManageOrderBook && !validationErrors.isProspectusDocumentMissing && (
            <Popover
              content={() => {
                if (isObsoleteOffering) {
                  return <div>You can't send documents for an obsolete offering.</div>;
                }

                if (!validationErrors.isIndicationUnacknowledged) {
                  return (
                    <div>
                      Sends Prospectus to all syndicate
                      <br />
                      contacts. If a contact has already received
                      <br />
                      the latest documents no action is taken.
                    </div>
                  );
                }

                return (
                  <div>
                    The prospectus will be sent once
                    <br />
                    this indication is acknowledged.
                  </div>
                );
              }}
              placement="topRight"
              variant="DARK"
              autoAdjustOverflow
            >
              <div>
                <SecondaryButton
                  size="medium"
                  onClick={resendProspectus}
                  loading={isResending || loadingValidationResult}
                  disabled={isObsoleteOffering}
                >
                  Send
                </SecondaryButton>
              </div>
            </Popover>
          )}
        </StyledButtonsContainer>
      </SideBarHeader>
      <ProspectusValidationAlert
        isShown={isValidationAlertShown}
        onHide={() => setIsValidationAlertShown(false)}
        linkToIndication={linkToIndication}
      />
      <SProspectusHistoryWrapper>
        {!hasHistoryRecord && (
          <ProspectusHistoryValidationErrors
            linkToIndication={linkToIndication}
            offeringId={offeringId}
            validationErrors={validationErrors}
          />
        )}
        {(!validationErrors.hasValidationError || hasHistoryRecord) && (
          <Fragment>
            {hasHistoryRecord ? (
              prospectusDeliveryHistory.map((prospectus, index) => (
                <ProspectusHistory
                  key={prospectus.id}
                  prospectus={prospectus}
                  initialExpanded={index === 0}
                />
              ))
            ) : (
              <ProspectusHistoryZeroState />
            )}
          </Fragment>
        )}
      </SProspectusHistoryWrapper>
    </div>
  );
};

export default ComplianceProspectus;
