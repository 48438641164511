import { PopoverProps } from '@cmg/common';

import {
  IndicationStatus,
  InvestorReplyStatus,
  TradeReleaseGridWarning,
} from '../../../../../../graphql';
import { TradeRowActionsAvailability } from '../../../hooks/useTradeGrid';
import { CellRendererSelectorParams, getActionsAvailability } from '../../TradeReleaseGrid.model';

export const selectedColumnCellRendererSelector = ({ data, node }: CellRendererSelectorParams) => {
  const isHeaderRow = node.isRowPinned();

  if (isHeaderRow) {
    return { component: 'emptyComponent' };
  }

  const actionsAvailability = getActionsAvailability(data);
  if (actionsAvailability === TradeRowActionsAvailability.HIDDEN) {
    return { component: 'emptyComponent' };
  }

  const isCheckboxDisabled = actionsAvailability === TradeRowActionsAvailability.DISABLED;
  const isPopoverDisabled =
    !isCheckboxDisabled ||
    (data.investorReplyStatus === InvestorReplyStatus.Confirmed &&
      data.warnings.includes(TradeReleaseGridWarning.IndicationCancelled));

  const popoverContent =
    data.indicationStatus === IndicationStatus.Cancelled
      ? 'Indication must be active before release.'
      : 'This allocation must be confirmed before it can be released.';

  const popoverProps: PopoverProps = {
    variant: 'DARK',
    disabled: isPopoverDisabled,
    placement: 'topLeft',
    content: <span>{popoverContent}</span>,
  };

  return {
    component: 'rowSelectorRenderer',
    params: {
      disabled: isCheckboxDisabled,
      popoverProps,
    },
  };
};
