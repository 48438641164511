import { ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Modal from '../../../../../common/components/overlays/modal/Modal';
import {
  FilingConfirmationModalAction,
  getModalContentMessage,
  getModalTitle,
} from './FilingConfirmationModal.model';

export type Props = {
  show: boolean;
  filingName: string;
  action: FilingConfirmationModalAction;
  getContentMessage?: (action: FilingConfirmationModalAction) => string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

export const FilingConfirmationModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  filingName,
  action,
  show,
  getContentMessage = getModalContentMessage,
}) => {
  return (
    <Modal title={getModalTitle(filingName, action)} show={show} onHide={onCancel}>
      <Modal.Content>
        <p>{getContentMessage(action)}</p>
        <p>
          Upon receiving a successful response from FINRA, the filing details are emailed to you and
          the underwriters on the offering.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right" margin={16}>
          <SecondaryButton
            testId={xcSelectors.regulatoryFilingsSubmitModalCancelButton.testId}
            onClick={onCancel}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.regulatoryFilingsSubmitModalConfirmButton.testId}
            onClick={onConfirm}
          >
            Continue
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};
