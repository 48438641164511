import { Banner, Modal, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useCallback } from 'react';

import { ManagerRole } from '../../../../graphql';
import {
  OfferingSetup_Summary_AvailableShareDraftManagersQuery,
  OfferingSetup_Summary_OfferingShareHistoryFragment,
} from '../../graphql';
import ShareDraftManagerGroup from './ShareDraftManagerGroup';
import {
  getManagerGroups,
  ManagerGroupMap,
  mergeManagerKeys,
} from './ShareOfferingDraftModal.model';
import {
  SButtonContainer,
  SEmptyManagers,
  SEmptyManagersSubtext,
  SEmptyManagersText,
  SListContainer,
  SManagerGroupsList,
  SSeparator,
  SSubheader,
} from './ShareOfferingDraftModal.styles';

export type Props = {
  onHide: () => void;
  managers: OfferingSetup_Summary_AvailableShareDraftManagersQuery['availableShareDraftManagers'];
  lastShareHistoryData: OfferingSetup_Summary_OfferingShareHistoryFragment | null;
  onSubmit: (managerKeys: string[]) => Promise<void>;
  loadingShareDraft?: boolean;
};
const ShareOfferingDraftModal = ({
  onHide,
  managers,
  lastShareHistoryData,
  onSubmit,
  loadingShareDraft,
}: Props) => {
  const [selectedManagerKeys, setSelectedManagerKeys] = React.useState<string[]>([]);
  const [managerGroups, setManagerGroups] = React.useState<ManagerGroupMap>({});
  React.useEffect(() => {
    const mappedManagerGroups = getManagerGroups(managers);
    setManagerGroups(mappedManagerGroups);
  }, [managers]);

  const handleSelectedManagers = useCallback(
    (managerKeys: string[]) => {
      setSelectedManagerKeys(prevManagerKeys => {
        return mergeManagerKeys(prevManagerKeys, managerKeys);
      });
    },
    [setSelectedManagerKeys]
  );

  const handleShareDraft = useCallback(async () => {
    if (!selectedManagerKeys.length) {
      return;
    }
    await onSubmit(selectedManagerKeys);
    onHide();
  }, [onHide, selectedManagerKeys, onSubmit]);

  const hasManagers = managers.length > 0;
  const disableShareButton = loadingShareDraft || !hasManagers || !selectedManagerKeys.length;

  return (
    <Modal
      size="small"
      show
      title="Share Offering Draft"
      onHide={onHide}
      closeButton
      shouldCloseOnOverlayClick
      testId={xcSelectors.offeringSetupSummaryShareDraftModal.testId}
    >
      <div data-testid="offering-share-draft-modal">
        <Banner variant="info">This draft will be shared with the selected managers</Banner>
        <SListContainer>
          <SSubheader>Send to</SSubheader>
          <SManagerGroupsList>
            {!hasManagers ? (
              <SEmptyManagers>
                <SEmptyManagersText>No managers available</SEmptyManagersText>
                <SEmptyManagersSubtext>
                  Please add managers in the “Manager” step of the Offering Setup in order to share
                  the Offering Draft.
                </SEmptyManagersSubtext>
              </SEmptyManagers>
            ) : (
              (Object.keys(managerGroups) as ManagerRole[]).map(managerRole => (
                <ShareDraftManagerGroup
                  lastShareHistoryData={lastShareHistoryData}
                  key={managerRole}
                  managerRole={managerRole}
                  selectedManagerKeys={selectedManagerKeys}
                  managerGroup={managerGroups[managerRole]}
                  onSelectManagers={handleSelectedManagers}
                />
              ))
            )}
          </SManagerGroupsList>
        </SListContainer>
        <SSeparator />
        <SButtonContainer>
          <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.offeringShareDraftShareButton.testId}
            loading={loadingShareDraft}
            onClick={() => handleShareDraft()}
            disabled={disableShareButton}
          >
            Share
          </PrimaryButton>
        </SButtonContainer>
      </div>
    </Modal>
  );
};

export default ShareOfferingDraftModal;
