import { FlexContainer, TextAreaField } from '@cmg/common';
import React from 'react';

import { SDetails, SEmptyNotes, SNote } from './NotesField.styles';

export type Props = { isEditable: boolean; notesValue: string | null; emptyFieldMessage: string };

const NotesField: React.FC<Props> = ({ isEditable, notesValue, emptyFieldMessage }) => {
  const notesLength = notesValue?.length ?? 0;

  if (isEditable) {
    return (
      <FlexContainer direction="column" gap={4}>
        <TextAreaField name="notes" fullWidth placeholder="Enter Notes..." />
        <SDetails hasError={notesLength > 1000}>{notesLength}/1000 Characters</SDetails>
      </FlexContainer>
    );
  }

  if (notesValue) {
    return <SNote>{notesValue}</SNote>;
  }

  return <SEmptyNotes>{emptyFieldMessage}</SEmptyNotes>;
};

export default NotesField;
