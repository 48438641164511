import { ISODate } from '@cmg/common';
import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export type Values = {
  masterAauDate: ISODate;
  disclaimer: string | null;
};

export const validationSchema = yup
  .object({
    masterAauDate: yup.date().label('Master AAU date').typeError('Invalid date format').required(),
  })
  .concat(wiresFormDisclaimerSchema);
