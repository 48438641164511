import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

import Banner from '../../../../../../common/components/indicators/banner/Banner';

export const StyledCheckIcon = styled(Icon)`
  margin-right: 10px;
`;

export const StyledBanner = styled(Banner)`
  margin: 16px;
`;

export const SResourceRow = styled.tr`
  &&& > td {
    padding-left: 15px;

    background-color: ${({ theme }) => theme.background.color.highlighted};
  }
`;
export const SHeader = styled.thead`
  background-color: ${({ theme }) => theme.brand.color.dark};

  &&& th {
    padding-left: 15px;
    color: ${({ theme }) => theme.text.color.white};
  }
`;

export const SActionRow = styled.tr`
  width: 20%;
  color: ${({ theme }) => theme.text.color.darkGray};

  &&& > td:first-child {
    padding: 10px 10px 10px 50px;
  }
  &&& > td:not(:first-child) {
    padding: 10px 0;
  }
`;
