import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import { Stack } from '@cmg/design-system';
import React from 'react';

import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import {
  AllocationAcknowledgement,
  IndicationStatus,
  InstitutionalIndicationOrderType,
  MdlAllocationRanking,
} from '../../../../../../graphql';
import { NoSyndicateInvestorFirmRelationshipBanner } from '../../../../SNoRelationshipBanners';
import { useIndicationActivitySyndicateContext } from '../../context/IndicationActivitySyndicateContext';
import Allocation from '../allocation/Allocation';
import InvestorContactInformationForm from '../investor-contact-information/InvestorContactInformationForm';
import ComplianceErrorBanner from './common/compliance-requited-banner/ComplianceErrorBanner';
import DuplicateIndications from './components/dupes/DuplicateIndications';
import { useManageIndication_MdlAllocationRankingLazyQuery } from './graphql/__generated__';
import { useSetAllocationRankingOnOfferingMutation } from './hooks/useSetAllocationRankingOnOfferingMutation';
import DuplicateIndicationBanner from './syndicate/duplicate-indication-banner/DuplicateIndicationBanner';
import IndicationSectionCreateView from './syndicate/indication-section-create-view/IndicationSectionCreateView';
import IndicationSectionEditView from './syndicate/indication-section-edit-view/IndicationSectionEditView';
import IndicationSectionReadView from './syndicate/indication-section-read-view/IndicationSectionReadView';

export type Props = {
  editable: boolean;
  isInvestorCovered: boolean;
  setInstitutionalIndicationAcknowledgementsError?: (boolean) => void;
  isProspectusDocumentMissing?: boolean;
  isProspectusContactMandatory?: boolean;
  isOrderBookOpen: boolean;
};

const refetchTimeout = 2000;
/**
 * Container component used for rendering the
 * Manage Indication UI.
 */
const ManageSyndicateIndication: React.FC<Props> = ({
  editable,
  isInvestorCovered,
  isProspectusDocumentMissing,
  isProspectusContactMandatory,
  isOrderBookOpen,
}) => {
  const {
    offeringId,
    basicPublishedOffering,
    attestation,
    finalInstitutionalAllocationSet,
    complianceWarning: { hasComplianceWarning },
    access: { canBillingAndDeliveryAgentUpdateAllocation },
  } = useIndicationActivitySyndicateContext();
  const indication = basicPublishedOffering?.orderBook.institutionalIndication;

  const hasIoiRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [isEditingIndication, setIsEditingIndication] = React.useState(false);
  const [displayMissingContacts, setDisplayMissingContacts] = React.useState(false);

  const prospectusContactMandatory = isProspectusContactMandatory && !isProspectusDocumentMissing;

  const finalAllocation = indication?.finalAllocation;
  const isFinalAllocationAcknowledged =
    finalAllocation?.investorReply?.status === AllocationAcknowledgement.Acknowledged;

  const [getAllocationRanking, { data: allocationRankingData, error: getAllocationRankingError }] =
    useManageIndication_MdlAllocationRankingLazyQuery({
      onCompleted: ({ mdlAllocationRanking }) => {
        const allocationRankingEmpty = !mdlAllocationRanking?.ranking;

        // Since DL is still processing the creation of the allocation we need to keep
        // pulling until the allocation is created on DL
        if (hasIoiRead && allocationRankingEmpty && isFinalAllocationAcknowledged) {
          setTimeout(() => {
            getAllocationRanking({ variables: { offeringId } });
          }, refetchTimeout);
        }
      },
      fetchPolicy: 'cache-and-network',
    });

  /* Allocation cannot be set on SS, it is done in <Allocation /> component which is common to syndicate, s&t and bs */
  const [setAllocationRankingOnOffering, { error: setAllocationRankingOnOfferingError }] =
    useSetAllocationRankingOnOfferingMutation({
      onCompleted: () => {
        ToastManager.success('Successfully updated allocation ranking');
      },
    });

  const onChangeAllocationRanking = (ranking: MdlAllocationRanking) => {
    setAllocationRankingOnOffering({
      variables: { id: allocationRankingData?.mdlAllocationRanking?.id!, ranking },
    });
  };

  const isCreatingIndication = !indication;

  return (
    <div aria-label="Manage Indication">
      <Stack gap={1} pb={1}>
        {!isCreatingIndication && !isInvestorCovered && (
          <NoSyndicateInvestorFirmRelationshipBanner />
        )}

        {setAllocationRankingOnOfferingError && (
          <ServerErrorsBanner error={setAllocationRankingOnOfferingError} />
        )}
        {getAllocationRankingError && <ServerErrorsBanner error={getAllocationRankingError} />}

        {indication?.status === IndicationStatus.Duplicate && <DuplicateIndicationBanner />}

        <ComplianceErrorBanner
          isMissingContacts={displayMissingContacts}
          attestationStatus={hasComplianceWarning ? attestation?.formStatus : undefined}
          prospectusDeliveryStatus={
            indication?.investorProspectusDeliveryStatus?.overallDeliveryStatus
          }
        />
      </Stack>

      {isCreatingIndication && (
        <IndicationSectionCreateView prospectusContactMandatory={!!prospectusContactMandatory} />
      )}

      {!isCreatingIndication && isEditingIndication && (
        <IndicationSectionEditView
          onFinishEditing={() => setIsEditingIndication(false)}
          hasAllocationRanking={!!allocationRankingData?.mdlAllocationRanking?.ranking}
        />
      )}
      {!isCreatingIndication && !isEditingIndication && (
        <IndicationSectionReadView
          onStartEditing={() => setIsEditingIndication(true)}
          hasAllocationRanking={!!allocationRankingData?.mdlAllocationRanking?.ranking}
          isIndicationEditable={editable}
          duplicateIndicationId={indication.duplicateOfIndicationId}
        />
      )}

      {indication && (
        <InvestorContactInformationForm
          prospectusContactMandatory={prospectusContactMandatory}
          offering={basicPublishedOffering!}
          indication={indication}
          isCoveredAccount={false}
          setDisplayMissingContacts={setDisplayMissingContacts}
          displayMissingContacts={displayMissingContacts}
          isInvestorCoveredAndEditable={editable}
        />
      )}

      {indication && indication.type !== InstitutionalIndicationOrderType.Pass && (
        <Allocation
          allocation={finalAllocation}
          indication={{
            ...indication,
            offeringId,
          }}
          allocationSet={finalInstitutionalAllocationSet}
          offering={basicPublishedOffering!}
          isCoveredAccount={false}
          isOrderBookOpen={isOrderBookOpen}
          onChangeAllocationRanking={onChangeAllocationRanking}
          allocationRanking={allocationRankingData?.mdlAllocationRanking?.ranking}
          getAllocationRanking={getAllocationRanking}
          billingAndDeliveryAgentCmgEntityKey={indication.billingAndDeliveryAgentCmgEntityKey}
          isSellSideAccount
          canUpdateAllocation
          canBillingAndDeliveryAgentUpdate={canBillingAndDeliveryAgentUpdateAllocation}
        />
      )}

      {indication && indication.duplicateIndicationIds?.length > 0 && (
        <DuplicateIndications
          duplicateIndicationIds={indication.duplicateIndicationIds ?? []}
          offeringId={offeringId}
        />
      )}
    </div>
  );
};

export default ManageSyndicateIndication;
