import React from 'react';

import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalFinalSet } from '../../../../../types';
import ShareAllocationSetDialog, { Props as ShareDialogProps } from '../ShareAllocationSetDialog';
import {
  getCheckedCmgEntityKeys,
  ShareAllocationSetValues,
} from '../ShareAllocationSetDialog.model';
import { useOrderBook_ShareFinalAllocationSetMutation } from './graphql';

export type Props = Pick<ShareDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    sharedFinalAllocationSet:
      | InstitutionalFinalSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const ShareFinalAllocationSetDialog: React.FC<Props> = ({
  sharedFinalAllocationSet,
  offeringId,
  ...props
}) => {
  const [share] = useOrderBook_ShareFinalAllocationSetMutation();

  const handleSave = React.useCallback(
    async (formValues: ShareAllocationSetValues) => {
      await share({
        variables: {
          offeringId,
          payload: getCheckedCmgEntityKeys(formValues),
        },
      });
    },
    [offeringId, share]
  );

  if (!sharedFinalAllocationSet) {
    return null;
  }

  const authorCmgEntityKey =
    'author' in sharedFinalAllocationSet
      ? sharedFinalAllocationSet.author.firmKey
      : sharedFinalAllocationSet.authorCmgEntityKey!;

  const sharedWithManagers =
    'author' in sharedFinalAllocationSet
      ? sharedFinalAllocationSet.sharedWithManagers?.map(shared => ({
          cmgEntityKey: shared!.manager.firmKey,
          timestamp: shared!.sharedAt,
          isActive: shared!.isActive,
        })) ?? []
      : sharedFinalAllocationSet.sharedWithManagers;

  return (
    <ShareAllocationSetDialog
      {...props}
      title="Share Final Allocation Set dialog"
      offeringId={offeringId}
      sharedWithManagers={sharedWithManagers}
      authorCmgEntityKey={authorCmgEntityKey}
      onSave={handleSave}
    />
  );
};

export default ShareFinalAllocationSetDialog;
