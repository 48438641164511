import { numericUtil, timeUtil } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import Table from '../../../../../../common/components/layout/table/Table';
import { getLabelFromOptions } from '../../../../common/form-utils';
import { currencyOptions } from '../../form/TNFilingForm.model';
import { RegulatoryFilings_TnFilingPartsFragment } from '../../graphql';

const SWrapper = styled.div`
  margin-bottom: 16px;
`;

export type Props = {
  filing: RegulatoryFilings_TnFilingPartsFragment;
};

const ATMTradeDetail: React.FC<Props> = ({ filing }) => {
  if (filing.atmTrades.length === 0) {
    return null;
  }

  return (
    <SWrapper>
      <h3>ATM Trades</h3>
      <Table>
        <thead>
          <tr>
            <th>Trade Date</th>
            <th>Number of Shares</th>
            <th>Average Price</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {filing.atmTrades.map((item, index) => (
            <tr key={index}>
              <td>{item.tradeDate && timeUtil.formatAsDisplayDate(item.tradeDate)}</td>
              <td>{numericUtil.formatInteger(item.numberOfShares)}</td>
              <td>{numericUtil.formatCurrency(item.averagePrice)}</td>
              <td>{getLabelFromOptions(item.currency, currencyOptions)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </SWrapper>
  );
};

export default ATMTradeDetail;
