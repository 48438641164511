import { UUID } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useSyndicateWiresRouteQuery } from '../../../hooks/useSyndicateWiresRouteQuery';
import SyndicateWireContent from '../../syndicate-wire-content/SyndicateWireContent';
import { SyndicateWiresErrorBanner } from '../../syndicate-wires-error-banner/SyndicateWiresErrorBanner';
import { DraftStage } from '../draft-stage/DraftStage';
import { SentStage } from '../sent-stage/SentStage';

export type Props = {
  offeringId: UUID;
  syndicateWireId: UUID;
  managerId?: string;
  managers: SyndicateWireManager[];
  nextRecipients: SyndicateWireManager[];
};

export const WireExistsStage: React.FC<Props> = ({
  offeringId,
  syndicateWireId,
  managerId,
  managers,
  nextRecipients,
}) => {
  const { wireTypeName, wireTypeCategory, useWireDetails } = useWireTypeConfigContext();

  const { data, isLoading, error } = useWireDetails({
    offeringId,
    syndicateWireId,
  });

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useSyndicateWiresRouteQuery(offeringId);

  if ((isLoading || offeringLoading) && !data) {
    return (
      <SyndicateWireContent>
        <Loading />
      </SyndicateWireContent>
    );
  }

  if (error || offeringError) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner loadedEntity={`the ${wireTypeName} ${wireTypeCategory}`} />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  return data?.wire.wireMessages?.length ? (
    <SentStage
      offeringId={offeringId}
      offeringType={offeringData.offeringType}
      managers={managers}
      nextRecipients={nextRecipients}
      syndicateWireId={syndicateWireId}
      managerId={managerId}
    />
  ) : (
    <DraftStage
      offeringId={offeringId}
      offeringType={offeringData.offeringType}
      managers={managers}
      nextRecipients={nextRecipients}
      syndicateWireId={syndicateWireId}
      managerId={managerId}
    />
  );
};
