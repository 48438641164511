import { AsyncMultiSelectField, Icon, ISODate, ToastManager } from '@cmg/common';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import React from 'react';

import { RoadshowDetailsValues } from '../../RoadshowDetails.model';
import {
  useRoadshows_RoadshowMapsAutocompleteLazyQuery,
  useRoadshows_RoadshowTimezoneInfoLazyQuery,
} from './graphql';
import {
  createDefaultOptions,
  createRoadshowPlaceOptions,
} from './RoadshowPlaceMultiSelectField.model';

export type Props = {
  date: ISODate;
  dayIndex: number;
};

const RoadshowPlaceMultiSelectField: React.FC<Props> = ({ dayIndex, date }) => {
  const [fetchRoadshowCities] = useRoadshows_RoadshowMapsAutocompleteLazyQuery();
  const [fetchRoadshowTimezoneInfo] = useRoadshows_RoadshowTimezoneInfoLazyQuery();
  const { setFieldValue, initialValues } = useFormikContext<RoadshowDetailsValues>();

  const defaultOptions = React.useMemo(() => {
    return createDefaultOptions(initialValues, dayIndex);
  }, [initialValues, dayIndex]);

  const handleLoadOptions = async (searchText: string) => {
    if (!searchText) {
      return [];
    }

    try {
      const { data } = await fetchRoadshowCities({ variables: { searchText } });

      return createRoadshowPlaceOptions(data?.roadshowCityAutocomplete ?? []);
    } catch {
      ToastManager.error('Error occurred while fetching cities');
      return [];
    }
  };

  const handleChange = async (values: string[] | null) => {
    if (!values) {
      return;
    }

    try {
      const timezones: string[] = [];

      for (const item of values) {
        const { data } = await fetchRoadshowTimezoneInfo({
          variables: {
            date: format(new Date(date), 'yyyy-MM-dd'),
            address: item,
          },
          fetchPolicy: 'cache-first',
        });
        if (data) {
          timezones.push(data.roadshowTimezoneInfo.timeZoneId);
        }
      }

      setFieldValue(`roadshowDays.${dayIndex}.timezones`, timezones);
    } catch {
      ToastManager.error('Error occurred while fetching timezones');
    }
  };

  return (
    <AsyncMultiSelectField
      renderDropdownIndicator={() => <Icon name="search" size="lg" />}
      loadOptions={handleLoadOptions}
      onChange={handleChange}
      name={`roadshowDays.${dayIndex}.cities`}
      defaultOptions={defaultOptions}
      fullWidth
    />
  );
};

export default RoadshowPlaceMultiSelectField;
