import isNil from 'lodash/isNil';

import CurrencyFormatter from '../../../../../../common/components/format/currency-format/CurrencyFormatter';
import { CalendarGridRendererProps } from '../../../../calendar.model';

export const PriceRangeRenderer: React.FC<CalendarGridRendererProps> = ({
  data: { priceRangeHigh, priceRangeLow, pricingCurrencyCode },
}) => {
  return isNil(priceRangeLow) || isNil(priceRangeHigh) ? (
    <span>-</span>
  ) : (
    <div>
      <CurrencyFormatter value={priceRangeLow ?? 0} currency={pricingCurrencyCode} /> -{' '}
      <CurrencyFormatter value={priceRangeHigh ?? 0} currency={pricingCurrencyCode} />
    </div>
  );
};
