import { numericUtil, ODSTable as Table, timeUtil } from '@cmg/common';
import React from 'react';

import { StabilizationTrade } from '../../../../StabilizationForm.model';
import { getBuyGain } from '../../StabilizationTrades.model';
import { activityTypeLabel } from './ReadOnlyStabilizationTradesRow.model';

export type Props = { trade: StabilizationTrade; offeringPrice: number };

const ReadOnlyStabilizationTradesRow: React.FC<Props> = ({ trade, offeringPrice }) => {
  return (
    <Table.Row suppressHighlightingOnHover isFocused={false}>
      <Table.Cell paddingSize="S" align="left">
        {timeUtil.formatAsDisplayDate(trade.date!)}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForNumber(trade.numberOfShares, 0)}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(trade.averagePrice, 6)}
      </Table.Cell>
      <Table.Cell paddingSize="S">
        {trade.activityType && activityTypeLabel[trade.activityType]}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(getBuyGain(trade, offeringPrice), 6)}
      </Table.Cell>
    </Table.Row>
  );
};

export default ReadOnlyStabilizationTradesRow;
