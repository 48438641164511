import {
  ButtonsContainer,
  SecondaryButton,
  SuccessButton,
  useValidateFormOnSubmit,
  UUID,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { useCheckRegulatoryFilingsAccess } from '../../../../common/util/check-access/useCheckRegulatoryFilingsAccess';
import { SHeader, STitle, STitleWrapper } from './RegulatoryFilingsHeader.styles';

export type Props = {
  offeringId: UUID;
  title: string;
  onSubmitFiling: () => void;
  isAmended?: boolean;
  onCancel?: () => void;
};

const RegulatoryFilingsFormHeader: React.FC<Props> = ({
  offeringId,
  title,
  isAmended,
  onSubmitFiling,
  onCancel,
}) => {
  const { canManage } = useCheckRegulatoryFilingsAccess(offeringId);
  const validateFormOnSubmit = useValidateFormOnSubmit(onSubmitFiling);

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <SHeader>
      <STitleWrapper>
        <STitle>{title}</STitle>
      </STitleWrapper>
      <ButtonsContainer margin={8}>
        {isAmended && canManage && (
          <SecondaryButton
            testId={xcSelectors.regulatoryFilingsCancelButton.testId}
            onClick={handleCancel}
          >
            Cancel
          </SecondaryButton>
        )}
        {canManage && (
          <SuccessButton
            testId={xcSelectors.regulatoryFilingsSubmitButton.testId}
            onClick={validateFormOnSubmit}
          >
            Submit Filing
          </SuccessButton>
        )}
      </ButtonsContainer>
    </SHeader>
  );
};

export default RegulatoryFilingsFormHeader;
