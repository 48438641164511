import { Form } from 'formik';
import styled from 'styled-components/macro';

export const SField = styled.div`
  margin: 0 10px 10px 0;
  width: 100%;

  &:not(:first-child) {
    width: 50%;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const SIconWrapper = styled.div`
  margin-left: 10px;
`;

export const StyledForm = styled(Form)`
  display: flex;
`;
