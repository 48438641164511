import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 24px 32px;
`;

export const SSpacer = styled.div`
  min-height: 28px;
  max-height: 80px;
  flex: 1;
`;

export const SSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.large};
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  padding-bottom: 8px;
`;

export const SSubtitle = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  padding-bottom: 36px;
`;
