import { ISODateTime, UUID } from '@cmg/common';

import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
  SyndicateManagerProfile,
} from '../../../../../graphql';
import {
  InstitutionalDemandGrid_AllocationSetManagerShareInfoPartsFragment,
  InstitutionalDemandGrid_AttestationStatusesQuery,
  InstitutionalDemandGrid_ColumnConfigQuery,
  InstitutionalDemandGrid_DealLayoutPartsFragment,
  InstitutionalDemandGrid_DraftAllocationSetsPartsFragment,
  InstitutionalDemandGrid_FinalAllocationSetPartsFragment,
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment,
  InstitutionalDemandGrid_IndicationDemandsPartsFragment,
  InstitutionalDemandGrid_InvestorDeliveryStatusPartsFragment,
  InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
  InstitutionalDemandGrid_SummaryQuery,
} from './graphql';

type InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary =
  InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary'];
type InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands =
  InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number];

export const MIN_INCREMENT = 0.01;
export const MAX_INCREMENT = 1000;

export type InstitutionalDemandFilterArgs = {
  searchText?: string | null;
  status?: IndicationStatus[] | null;
  type?: InstitutionalIndicationOrderType[] | null;
};

export type InstitutionalDemandColumnConfig = {
  min: number;
  max: number;
  increment: number;
  referencePrice?: number | null;
  useNewStrategy?: boolean;
};

export enum CMGColumnID {
  INDICATION_STATUS = 'CMG.INDICATION_STATUS',
  ROW_ALERT = 'CMG.ROW_ALERT',
  DUPE_GROUP = 'CMG.DUPE_GROUP',
  SELECTED = 'CMG.SELECTED',
  INVESTOR = 'CMG.INVESTOR',
  TYPE = 'CMG.TYPE',
  SYNDICATE_NOTES = 'CMG.SYNDICATE_NOTES',
  SALES_AND_TRADING_NOTES = 'CMG.SALES_AND_TRADING_NOTES',
  SALES_DEPARTMENT_NOTES = 'CMG.SALES_DEPARTMENT_NOTES',
  SALES_CONTACT_NOTES = 'CMG.SALES_CONTACT_NOTES',
  SUBMITTED_BY = 'CMG.SUBMITTED_BY',
  SUBMITTED_ON = 'CMG.SUBMITTED_ON',
  MODIFIED_BY = 'CMG.MODIFIED_BY',
  MODIFIED_ON = 'CMG.MODIFIED_ON',
  RELATIONSHIP = 'CMG.RELATIONSHIP',
  CMG_ENTITY_NAME = 'CMG.CMG_ENTITY_NAME',
  FIRM_KEY = 'CMG.FIRM_KEY',
  BD_AGENT = 'CMG.BD_AGENT',
  ACKNOWLEGMENTS = 'CMG.ACKNOWLEDGEMENTS',
  DEMAND_MAX = 'CMG.DEMAND_MAX',
  DEMAND_AT_MARKET = 'CMG.DEMAND_AT_MARKET',
  ATTESTATION = 'CMG.ATTESTATION',
  ACKNOWLEDGING_USER = 'CMG.ACKNOWLEDGING_USER',
  INVESTOR_REPLY_SUBMITTER = 'CMG.INVESTOR_REPLY_SUBMITTER',
  FINAL_ALLOCATION = 'CMG.FINAL_ALLOCATION',
  FINAL_ALLOCATION_STATUS = 'CMG.FINAL_ALLOCATION_STATUS',
  DRAFT_ALLOCATION_PREFIX = 'CMG.DRAFT_ALLOCATION',
  PROSPECTUS_DELIVERY_STATUS = 'CMG.PROSPECTUS_DELIVERY_STATUS',
  SALES_COVEREAGE = 'CMG.SALES_COVERAGE',
}

export const getDraftAllocationId = (setId: string) =>
  `${CMGColumnID.DRAFT_ALLOCATION_PREFIX}.${setId}`;

export type IndicationNote = {
  text: string;
  modifiedAt?: ISODateTime | null;
};

export type IndicationWithDemandLevels = {
  id: UUID;
  offeringId: UUID;
  status: IndicationStatus;
  type: InstitutionalIndicationOrderType;
  currencyCode?: string | null;
  pricingCurrencyCode?: string | null;
  demandMax: number;
  demandAtMarket: number;
  billingAndDeliveryAgentCmgEntityKey: string | null;
  investorInformation: InstitutionalDemandGrid_IndicationDemandsPartsFragment['institutionalIndicationDemands'][number]['investorInformation'];
  auditInfo: InstitutionalDemandGrid_IndicationDemandsPartsFragment['institutionalIndicationDemands'][number]['auditInfo'];
  demandLevels: {
    [key: number]: number;
  };
  defaultDraftAllocationSetId: UUID | null;
  draftAllocationsBySetId: {
    [key: string]: InstitutionalDraftAllocation;
  };
  finalAllocation: InstitutionalFinalAllocation | null;
  finalAllocationAuthorCmgEntityKey: string | null;
  submittedBy: SyndicateManager | null;
  modifiedBy: SyndicateManager | null;
  interestLevels: InstitutionalDemandGrid_IndicationDemandsPartsFragment['institutionalIndicationDemands'][number]['interestLevels'];
  coveringManagerNames: SyndicateManager[];
  selected: boolean;
  settlementAgent: string | null;
  duplicateOfIndicationId: string | null;
  duplicateOfInvestorName: string | null;
  duplicateIndicationIds: readonly string[];
  notes: {
    syndicate: IndicationNote | null;
    salesAndTrading: IndicationNote | null;
    salesDepartment: IndicationNote | null;
    salesContact: IndicationNote | null;
  };
  institutionalIndicationAcknowledgements: readonly InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment[];
  version: string;
  attestationStatus:
    | InstitutionalDemandGrid_AttestationStatusesQuery['attestationStatuses'][number]
    | null;
  prospectusDeliveryStatus: InstitutionalDemandGrid_InvestorDeliveryStatusPartsFragment | null;
  investorExtendedData:
    | InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'][number]
    | null;
  investorEmployeeRelationships:
    | InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery['investorFirmsEmployeeRelationships'][number]['relationships']
    | null;
  acknowledgingUser: string | null;
};

export type SyndicateManager = Pick<
  SyndicateManagerProfile,
  'cmgEntityKey' | 'firmName' | 'firmNameAbbreviation'
>;

export type InstitutionalIndicationWithFinalAllocation = {
  id: UUID;
  investorInformation: {
    bankInvestorName?: string | null;
    cmgEntityName?: string | null;
    cmgEntityKey?: string | null;
    bankInvestorKey?: string | null;
  };
  finalAllocation: {
    id: UUID;
    shareQuantity?: number | null;
  } | null;
};

export type InstitutionalAllocation = {
  id: string;
  indicationId: string;
  shareQuantity?: number | null;
  version: string;
};

export type InstitutionalDraftAllocation = InstitutionalAllocation & {
  allocationSetId: string;
  auditInfo: InstitutionalDemandGrid_DraftAllocationSetsPartsFragment['institutionalDraftAllocationSets'][number]['allocations'][number]['auditInfo'];
};

export type InstitutionalFinalAllocation = InstitutionalAllocation & {
  investorReplySubmitterName?: string | null;
  investorReply?:
    | NonNullable<
        InstitutionalDemandGrid_FinalAllocationSetPartsFragment['institutionalFinalAllocationSet']
      >['allocations'][number]['investorReply']
    | null;
  auditInfo: NonNullable<
    InstitutionalDemandGrid_FinalAllocationSetPartsFragment['institutionalFinalAllocationSet']
  >['allocations'][number]['auditInfo'];
};

export type ManagerShareInfo = Omit<
  InstitutionalDemandGrid_AllocationSetManagerShareInfoPartsFragment,
  'id'
>;

export type InstitutionalDraftSet = {
  id?: string | null;
  name?: string | null;
  authorCmgEntityKey?: string | null;
  isDefault: boolean;
  shareTimestamp?: string | null;
  sharedWithManagers: readonly ManagerShareInfo[];
  allocations: readonly InstitutionalDraftAllocation[];
  version: string;
  dealLayout: InstitutionalDemandGrid_DealLayoutPartsFragment;
  auditInfo?: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary['institutionalDraftAllocationSets'][number]['auditInfo'];
};

export type InstitutionalFinalSet = {
  isReleased?: boolean | null;
  name: string;
  releaseTimestamp?: string | null;
  totalAllocatedShareQuantity: number;
  shareTimestamp?: string | null;
  sharedWithManagers: readonly ManagerShareInfo[];
  authorCmgEntityKey?: string | null;
  allocations: readonly InstitutionalFinalAllocation[];
  version: string;
  dealLayout: InstitutionalDemandGrid_DealLayoutPartsFragment;
  auditInfo?: NonNullable<
    InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary['institutionalFinalAllocationSet']
  >['auditInfo'];
};

export type InstitutionalDemand = {
  id: string;
  demandMax: number;
  demandAtMarket: number;
  billingAndDeliveryAgentCmgEntityKey?: string | null;
  trancheId?: string | null;
  instrumentId?: string | null;
  currencyCode?: string | null;
  coveringManagerCmgEntityKeys?: readonly string[];
  demandLevels: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands['demandLevels'];
  offeringId: string;
  status: IndicationStatus;
  type: InstitutionalIndicationOrderType;
  investorInformation: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands['investorInformation'];
  submittedByCmgEntityKey: string;
  interestLevels: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands['interestLevels'];
  notes: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands['notes'];
  auditInfo: InstitutionalDemandGrid_SummaryQuery_institutionalDemandGridSummary_institutionalIndicationDemands['auditInfo'];
  duplicateOfIndicationId?: string | null;
  duplicateIndicationIds: readonly string[];
  institutionalIndicationAcknowledgements: readonly InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment[];
  version: string;
  attestationStatus:
    | InstitutionalDemandGrid_AttestationStatusesQuery['attestationStatuses'][number]
    | null;
  prospectusDeliveryStatus?: InstitutionalDemandGrid_InvestorDeliveryStatusPartsFragment | null;
  investorEmployeeRelationships:
    | InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery['investorFirmsEmployeeRelationships'][number]['relationships']
    | null;
  acknowledgingUser?: string | null;
};

export type InstitutionalGridSummary = {
  draftSets: readonly InstitutionalDraftSet[];
  finalSet: InstitutionalFinalSet | null;
  indicationDemands: InstitutionalDemand[];
  indications: InstitutionalIndicationWithFinalAllocation[];
  isFinalAllocationSetReleased: boolean;
};

export interface InstitutionalDemandGridOfferingColumnConfig {
  derivedOfferPrice?: number | null;
  pricingCurrencyCode?: string | null;
  latestFilingPriceRangeLow?: number | null;
  latestFilingPriceRangeHigh?: number | null;
  initialFilingPriceRangeLow?: number | null;
  initialFilingPriceRangeHigh?: number | null;
  syndicate:
    | InstitutionalDemandGrid_ColumnConfigQuery['publishedOffering']['syndicate']
    | NonNullable<InstitutionalDemandGrid_ColumnConfigQuery['syndicateManagers']>;
}

export type InstitutionalDemandGridContext = {
  matchUrl: string;
  offeringId: UUID;
  isFinalAllocationSetReleased: boolean;
  loadingProspectusData: boolean;
  syndicateManagers: Record<string, SyndicateManager>;
};

export type StringExtendedData = {
  key: string;
  name: string | null;
  value: string | null;
};

export type DecimalExtendedData = {
  key: string;
  name: string | null;
  value: number | null;
};

export type TotalsPinnedRow = {
  id: string;
  demandMax: number;
  demandAtMarket: number;
  demandLevels: Record<number, number>;
  offeringId: string;
  filteredIndicationCount: number;
  filteredFinalAllocationTotals: {
    totalAllocation: number;
    unallocatedShares: number | undefined;
    isAnyIndicationHiddenToSalesAndBS: boolean;
  };
  filteredDraftAllocationSetTotals: {
    allocationSetId: string | null | undefined;
    totalAllocation: number;
    unallocatedShares: number | undefined;
  }[];
};

export enum ExtendedTypes {
  COMPLIANCE = 'compliance',
  INVESTOR_COMMENTS = 'investorComments',
  INVESTOR_TYPES = 'investorTypes',
  INVESTOR_REGION = 'investorRegion',
  REVENUES = 'revenues',
  OTHER_INVESTOR_ATTRIBUTES = 'otherInvestorAttributes',
}

export const extendedTypesDisplayName = {
  [ExtendedTypes.INVESTOR_TYPES]: 'Investor Type',
  [ExtendedTypes.COMPLIANCE]: 'Compliance',
  [ExtendedTypes.INVESTOR_COMMENTS]: 'Investor Comments',
  [ExtendedTypes.INVESTOR_REGION]: 'Region',
  [ExtendedTypes.OTHER_INVESTOR_ATTRIBUTES]: 'Other',
  [ExtendedTypes.REVENUES]: 'Revenue',
};
