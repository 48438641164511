import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';
import useCheckInstitutionalIndicationAccess from './useCheckInstitutionalIndicationAccess';

type AccessCheckResult = {
  canRead: boolean;
  canUpdate: boolean;
  isSellSideAccount: boolean;
  loading: boolean;
};

/**
 * Checks accessibility of an Institutional Indication.
 */
export function useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
  offeringId,
}: {
  offeringId?: string;
}): AccessCheckResult {
  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? [];

  const { isSellSideAccount, ...accessChecks } = useCheckInstitutionalIndicationAccess({
    entitlements,
  });

  return { loading, ...accessChecks, isSellSideAccount };
}

// TODO remove
export default useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess;
