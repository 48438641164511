import { Alert, AlertTitle, Typography } from '@cmg/design-system';
import { useFormikContext } from 'formik';

import { useFormikErrors } from '../../../../../../../common/util/useFormikErrors';
import {
  contactsValidationSchema,
  InvestorContactValues,
} from '../InvestorContactInformationForm.model';

export const InvestorContactAlertBanner: React.FC = () => {
  const { errors, touched, isSubmitting } = useFormikContext<InvestorContactValues>();
  const { showInvalidFields, invalidFormFieldLabels } = useFormikErrors({
    errors,
    touched,
    isSubmitting,
    validationSchema: contactsValidationSchema,
  });

  if (!showInvalidFields) {
    return null;
  }

  return (
    <Alert
      severity="error"
      aria-label="An entry is required or has invalid value for the following fields:"
    >
      <AlertTitle>An entry is required or has an invalid value:</AlertTitle>
      <Typography variant="body2">{invalidFormFieldLabels.join(', ')}</Typography>
    </Alert>
  );
};
