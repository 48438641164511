import { Option } from '@cmg/common';
import uniqBy from 'lodash/uniqBy';

export const getIssuerSymbolOptions = (
  stockSymbols: readonly string[],
  customSymbol: string | null
) => {
  return uniqBy(
    [...stockSymbols, customSymbol]
      .filter(stockSymbol => !!stockSymbol)
      .map<Option>(stockSymbol => ({
        label: stockSymbol!,
        value: stockSymbol!,
      })),
    'value'
  );
};

export const getAddNewSymbolLabel = (
  stockSymbols: readonly string[],
  searchInput: string | null
) => {
  if (!stockSymbols.length && !searchInput) {
    return 'No symbols configured in Offering Setup';
  }

  return 'You will be adding a symbol that is not preconfigured on the Offering Details';
};
