import { ExpensesManagerRole } from '../../../graphql';

export const expensesManagerRoleMapping: Record<ExpensesManagerRole, string> = {
  ACTIVE_BOOKRUNNER: 'Active Bookrunner',
  BOOKRUNNER: 'Bookrunner',
  CO_LEAD: 'Co-Lead Manager',
  CO_MANAGER: 'Co-Manager',
  INVALID: 'Invalid',
  SELLING_GROUP_MEMBER: 'Selling Group',
  UNDERWRITER: 'Underwriter',
};
