import * as yup from 'yup';

export const wiresFormEmailAddressesSchema = yup.object({
  emailAddresses: yup
    .array()
    .min(1, 'Email Addresses is a required field')
    .of(
      yup
        .string()
        .email('All email addresses have to be valid')
        .required('All email addresses have to be valid')
    )
    .test(
      'unique',
      'All email addresses must be unique',
      values => (values ?? []).length === new Set(values).size
    )
    .required('Email Addresses is a required field'),
});
