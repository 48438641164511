import { Banner, LinkButton, PrimaryButton, UnsavedChangesGuard, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import {
  Roadshows_EventParticipantPartsFragment,
  Roadshows_EventParticipantsTeamPartsFragment,
} from '../../../../common/graphql';
import EventFormSubHeader from '../../panel-meetings-event/common/event-form-subheader/EventFormSubHeader';
import CreateParticipantModal from '../../panel-meetings-event/meeting-details-panel/roadshow-coordinator-details-panel/create-participant-modal/CreateParticipantModal';
import { AdHocParticipantValues } from '../../panel-meetings-event/meeting-details-panel/roadshow-coordinator-details-panel/create-participant-modal/CreateParticipantModal.model';
import { Roadshows_RosterQuery } from '../graphql';
import ParticipantsPanel from '../participants-panel/ParticipantsPanel';
import {
  getInitialValues,
  getParticipantOptions,
  getValidationSchema,
  groupParticipantsByTeam,
  Values,
} from './ParticipantsForm.model';
import {
  SNewParticipantWrapper,
  SOption,
  SSelectedMemberWrapper,
  STeamMemberName,
  STeamName,
  StyledFlexLayout,
  StyledForm,
  StyledParticipantsMultiSelect,
} from './ParticipantsForm.styles';

export type Props = {
  roster?: Roadshows_RosterQuery;
  companyName: string | null | undefined;
  participantTeams?: readonly Roadshows_EventParticipantsTeamPartsFragment[];
  onSubmit: (values: Values) => void;
  title: string;
};

const ParticipantsForm: React.FC<Props> = ({
  onSubmit,
  roster,
  participantTeams = [],
  companyName,
  title,
  children,
}) => {
  const formik = useFormik<Values>({
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: getValidationSchema(
      roster?.roadshowManagementTeam.members?.map(({ id }) => id) ?? []
    ),
    initialValues: {
      participants: getInitialValues(participantTeams),
    },
    onSubmit,
  });

  const { values, isValid, dirty, setFieldValue, errors, resetForm } = formik;

  const [showNewParticipantModal, setShowNewParticipantModal] = React.useState(false);
  const [newParticipantIds, setNewParticipantIds] = React.useState<string[]>([]);
  const selectedParticipants = values.participants.map(({ id }) => id);
  const participantOptions = roster
    ? getParticipantOptions({ roster, companyName, excludedParticipantIds: selectedParticipants })
    : [];

  const handleAddNewParticipants = () => {
    const newParticipants = participantOptions
      .filter(({ value }) => newParticipantIds.includes(value))
      .map<Roadshows_EventParticipantPartsFragment>(({ member }) => ({
        __typename: 'EventParticipant',
        id: member.id,
        name: member.name,
        email: member.email,
        title: 'title' in member ? member.title : null,
        mobilePhone: member.mobilePhone,
        officePhone: member.officePhone,
        isAdHoc: false,
        status: null, // TODO: this needs to be taken from Roster query in future
      }));

    setFieldValue('participants', [...values.participants, ...newParticipants]);
    setNewParticipantIds([]);
  };

  const handleAddNewAdHocParticipants = (participant: AdHocParticipantValues) => {
    const newParticipant: Roadshows_EventParticipantPartsFragment = {
      __typename: 'EventParticipant',
      id: '',
      name: participant.name,
      email: participant.email,
      title: participant.title,
      mobilePhone: participant.mobilePhone,
      officePhone: participant.officePhone,
      isAdHoc: true,
      status: null,
    };

    setFieldValue('participants', [...values.participants, newParticipant]);
  };

  const handleDeleteParticipant = (id: UUID) => {
    const nextParticipants = values.participants.filter(participant => participant.id !== id);

    setFieldValue('participants', nextParticipants);
  };

  if (!companyName) {
    return <Banner variant="error">This roadshow has no company assigned</Banner>;
  }

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
          {children}

          {!isValid && <Banner variant="error">{errors.participants}</Banner>}
          <StyledForm data-test-id="meeting participants form">
            <EventFormSubHeader title={title}>
              <StyledFlexLayout direction="row">
                <StyledParticipantsMultiSelect
                  testId="select-participants"
                  options={participantOptions}
                  value={newParticipantIds}
                  onChange={nextParticipants => {
                    setNewParticipantIds(nextParticipants as string[]);
                  }}
                  renderOption={option => (
                    <SOption>
                      <STeamMemberName>{option.label}</STeamMemberName>
                      <STeamName>{option.teamName}</STeamName>
                    </SOption>
                  )}
                  renderMultiValueLabel={children => (
                    <SSelectedMemberWrapper>{children}</SSelectedMemberWrapper>
                  )}
                  renderMenu={props => (
                    <React.Fragment>
                      {props.children}
                      <SNewParticipantWrapper>
                        Can't find participant?{' '}
                        <LinkButton onClick={() => setShowNewParticipantModal(true)}>
                          Create New
                        </LinkButton>
                      </SNewParticipantWrapper>
                    </React.Fragment>
                  )}
                />
                <PrimaryButton
                  disabled={newParticipantIds.length === 0}
                  onClick={handleAddNewParticipants}
                  testId={xcSelectors.roadshowParticipantsFormAddParticipantButton.testId}
                >
                  Add
                </PrimaryButton>
              </StyledFlexLayout>
              {roster &&
                groupParticipantsByTeam({ roster, companyName, participants: values.participants })
                  .filter(team => team.participants.length > 0)
                  .map((team, index) => (
                    <ParticipantsPanel
                      key={team.id ?? index}
                      participants={team.participants}
                      teamName={team.firmName}
                      onDelete={handleDeleteParticipant}
                      showExtendedInfo={team.showExtendedInfo}
                    />
                  ))}
            </EventFormSubHeader>
          </StyledForm>
        </UnsavedChangesGuard>
      </FormikProvider>
      <CreateParticipantModal
        show={showNewParticipantModal}
        onClose={() => setShowNewParticipantModal(false)}
        companyName={companyName ?? ''}
        onSubmit={handleAddNewAdHocParticipants}
      />
    </React.Fragment>
  );
};

export default ParticipantsForm;
