import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useFeatureToggles } from '../../common/config';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import BasicInfoRoute from './basic-info/BasicInfoRoute';
import { OfferingSetupSideNav } from './components/side-nav/OfferingSetupSideNav';
import { stepIds, stepTitles } from './constants';
import { DemandCurrencyRoute } from './currencies/DemandCurrencyRoute';
import { DisclaimersRoute } from './disclaimers/DisclaimersRoute';
import { DeliveryInstrumentsRoute } from './instruments/DeliveryInstrumentRoute';
import { ManagerEconomicsRoute } from './manager-economics/ManagerEconomicsRoute';
import { ManagersRoute } from './managers/ManagersRoute';
import { MarketingRoute } from './marketing/MarketingRoute';
import { ProspectusRoute } from './prospectus/ProspectusRoute';
import OfferingSummaryRoute from './summary/OfferingSummaryRoute';
import { TermsRoute } from './terms/TermsRoute';
import { TimingRoute } from './timing/TimingRoute';
import TranchesRoute from './tranches/TranchesRoute';
import { UnderwritingTermsRoute } from './underwriting-terms/UnderwritingTermsRoute';
import { UnderwritingDiscountsAndFeesRoute } from './uw-discounts-fees/UnderwritingDiscountsAndFeesRoute';
import { OfferingSetupValidationProvider } from './validation/OfferingSetupValidationProvider';

type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;
export type Props = { issuerName?: string } & RouteProps;

export const SWrapper = styled.div`
  flex: 1;
  display: flex;
`;

/**
 * Route which wraps component with shouldValidate prop
 */
export const OfferingSetupStep: React.FC<RouteProps> = props => {
  const { isOfferingSetupUnderwritingFeesDollarValuesOn } = useFeatureToggles();

  switch (props.match.params.stepId) {
    case stepIds.BASIC_INFO:
      return <BasicInfoRoute {...props} />;
    case stepIds.CURRENCIES:
      return <DemandCurrencyRoute {...props} />;
    case stepIds.INSTRUMENTS:
      return <DeliveryInstrumentsRoute {...props} />;
    case stepIds.TRANCHES:
      return <TranchesRoute {...props} />;
    case stepIds.MANAGERS:
      return <ManagersRoute {...props} />;
    case stepIds.MANAGER_ECONOMICS:
      return <ManagerEconomicsRoute {...props} />;
    case stepIds.TERMS:
    case stepIds.OFFERING_TERMS:
      return <TermsRoute {...props} />;
    case stepIds.UW_DISCOUNTS_FEES:
      if (isOfferingSetupUnderwritingFeesDollarValuesOn)
        return <UnderwritingTermsRoute {...props} />;
      return <UnderwritingDiscountsAndFeesRoute {...props} />;
    case stepIds.UNDERWRITING_TERMS:
      return <UnderwritingTermsRoute {...props} />;
    case stepIds.TIMING:
      return <TimingRoute {...props} />;
    case stepIds.MARKETING:
      return <MarketingRoute {...props} />;
    case stepIds.PROSPECTUS:
      return <ProspectusRoute {...props} />;
    case stepIds.DISCLAIMERS:
      return <DisclaimersRoute {...props} />;
    case stepIds.SUMMARY:
      return <OfferingSummaryRoute {...props} />;
    default:
      return <Redirect to={routeFactory.calendarLive.getUrlPath()} />;
  }
};

/**
 * Primary Route for the Offering Setup module
 */
export const OfferingSetupRoute: React.FC<Props> = props => {
  const { history, match } = props;
  const { offeringId, stepId } = match.params;

  useDocumentTitle(
    routeFactory.offeringSetup.getDocumentTitle({
      issuerName: props.issuerName,
      stepName: stepTitles[stepId],
    })
  );

  const handleChangeStep = id => {
    history.push(
      routeFactory.offeringSetup.getUrlPath({
        offeringId: offeringId,
        stepId: id,
      })
    );
  };

  return (
    <OfferingSetupValidationProvider>
      <SWrapper>
        <OfferingSetupSideNav
          offeringId={offeringId}
          activeStepId={!offeringId && !stepId ? stepIds.BASIC_INFO : stepId}
          steps={[]}
          onChangeStep={handleChangeStep}
        />
        <Switch>
          <Route path={routeFactory.offeringCreate.routePath} component={BasicInfoRoute} exact />
          <Route path={routeFactory.offeringSetup.routePath} component={OfferingSetupStep} />
          <Redirect to={routeFactory.calendarLive.getUrlPath()} />
        </Switch>
      </SWrapper>
    </OfferingSetupValidationProvider>
  );
};

export default OfferingSetupRoute;
