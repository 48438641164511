import React from 'react';

import { PanelContent, SWrapper } from './Panel.styles';
import PanelHeader from './PanelHeader';

export type Props = {
  testId?: string;
  className?: string;
};

/**
 * Naive implementation of a panel
 * @deprecated - use layout components from @cmg/design-system instead
 */
const Panel: React.FC<Props> = ({ testId, className, children }) => {
  return (
    <SWrapper data-test-id={testId} className={className}>
      {children}
    </SWrapper>
  );
};

PanelContent.displayName = 'PanelContent';

/**
 * @deprecated - use layout components from @cmg/design-system instead
 */
export default Object.assign(Panel, { Header: PanelHeader, Content: PanelContent });
