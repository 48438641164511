import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { UUID } from '@cmg/common';
import { RegularBreakpoints } from '@cmg/design-system';
import React from 'react';
import * as yup from 'yup';

import { OfferingStage, OfferingType, WireTemplateType } from '../../../../../graphql';
import { SyndicateWire, SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { OperationType } from '../components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { ManagersNavigation } from '../components/navigation/managers-side-nav/ManagersNavigation';
import { RouteConfigType } from '../components/navigation/syndicate-wires-side-nav/SyndicateWiresSideNav.model';
import { SyndicateWires_ManagerValidationPartsFragment } from '../graphql';
import { createDefaultUseOutdatedManagersList } from '../hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../hooks/createUseDefaultIsWireManagerListOutdated';
import { useBrokerDealersMyRetailQuery } from '../hooks/useBrokerDealersMyRetailQuery';
import { useSyndicateManagerListQuery } from '../hooks/useSyndicateManagerListQuery';
import { createUseDefaultSendIsDisabledReason } from './hooks/useDefaultSendIsDisabledReason';
import { useWireTypeConfigContext } from './WireTypeConfigContext';
import * as thisModule from './WireTypeConfigContext.model';

export type WireTypeValidation = {
  managers: readonly SyndicateWires_ManagerValidationPartsFragment[];
  sender: SyndicateWires_ManagerValidationPartsFragment;
  offeringErrors: readonly string[];
  orderBookErrors: readonly string[];
  settlementErrors: readonly string[];
};

export type ManagersNavigationProps = {
  managers: SyndicateWireManager[];
  offeringId: UUID;
  syndicateWireId?: UUID;
  wire?: SyndicateWire;
  managerId?: string;
  isNewestVersion?: boolean;
};

export type SyndicateWireContextQueryResult<TData> = {
  data: TData | undefined;
  isLoading: boolean;
  error?: ApolloError;
};

export type SyndicateWireContextMutationResult<TReturn = void, TArgs extends unknown[] = []> = {
  mutation: (...args: TArgs) => Promise<TReturn | undefined>;
  isLoading: boolean;
};

export type UseWireDetailsArgs = {
  offeringId: string;
  syndicateWireId?: string;
};

export type UseGetInitialValuesArgs<TWire extends SyndicateWire> = {
  readonly wire?: TWire;
  readonly managers: SyndicateWireManager[];
  readonly offeringId: UUID;
  readonly offeringType: OfferingType;
  readonly operationType: OperationType;
  readonly wireTemplateType: WireTemplateType;
};

export type CreateOrUpdateFormProps = {
  offeringId: UUID;
  managers: SyndicateWireManager[];
  operationType: OperationType;
  onFormDataLoaded: (isSuccess: boolean) => void;
};

export type UseCreateMutationArgs<TValues> = { offeringId: UUID; values: TValues };
export type UseUpdateMutationArgs<TValues> = {
  offeringId: UUID;
  syndicateWireId: UUID;
  values: TValues;
};

export type WireTypeConfigInitialValues<TValues extends Record<string, unknown>> = {
  data: TValues;
  predefinedData?: TValues;
};

export type WireTypeCategory = 'wire' | 'report';

export type WireTypeConfig<
  TValues extends Record<string, unknown> = Record<string, unknown>,
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  // We need to use the RouteConfig utility type to be able to infer correct parameters from the url.
  // For more info check the RouteConfig definition.
  TRouteConfig extends RouteConfigType = RouteConfigType
> = {
  wireTypeName: string;
  wireTypeCategory: WireTypeCategory;
  wireTypeRoute: TRouteConfig;
  wireTemplateType: WireTemplateType;

  useCreateIsDisabledReason: (args: { offeringId: UUID }) => React.ReactNode | null;
  useSendIsDisabledReason: (args: {
    offeringId: UUID;
    syndicateWireId: UUID;
  }) => React.ReactNode | null;

  isCcMyFirmEnabled: boolean;

  useManagers: (args: {
    offeringId: UUID;
    syndicateWireId?: UUID;
  }) => SyndicateWireContextQueryResult<SyndicateWireManager[]>;
  useIsWireManagerListOutdated: (args: {
    offeringId: UUID;
    syndicateWireId: UUID;
  }) => boolean | undefined;
  useOutdatedManagersList: (args: {
    offeringId: UUID;
    syndicateWireId?: UUID;
  }) => string[] | undefined;
  useNextRecipients: (args: {
    offeringId: UUID;
    syndicateWireId?: UUID;
  }) => SyndicateWireContextQueryResult<SyndicateWireManager[]>;

  useGenerateWirePreview: (args: {
    offeringId: UUID;
    syndicateWireId?: UUID;
    cmgEntityKey?: string;
  }) => SyndicateWireContextQueryResult<string>;
  useWireDetails: (
    args: UseWireDetailsArgs,
    fetchPolicy?: WatchQueryFetchPolicy
  ) => SyndicateWireContextQueryResult<{
    wire: TWire;
    stage: OfferingStage;
  }>;
  useWireList: (args: { offeringId: UUID }) => SyndicateWireContextQueryResult<readonly TWire[]>;
  useWireValidation: (args: {
    offeringId: UUID;
    fetchPolicy?: WatchQueryFetchPolicy;
  }) => SyndicateWireContextQueryResult<TWireValidation> & {
    refetch: <TVariables>(variables?: Partial<TVariables>) => Promise<ApolloQueryResult<unknown>>;
  };

  useCreateMutation: () => SyndicateWireContextMutationResult<
    TWire,
    [UseCreateMutationArgs<TValues>]
  >;
  useUpdateMutation: () => SyndicateWireContextMutationResult<
    TWire,
    [UseUpdateMutationArgs<TValues>]
  >;
  useDeleteMutation: () => SyndicateWireContextMutationResult<
    void,
    [
      {
        offeringId: UUID;
        syndicateWireId: UUID;
      }
    ]
  >;
  useSendMutation: () => SyndicateWireContextMutationResult<
    TWire,
    [{ managerIds: UUID[]; syndicateWireId: UUID; offeringId: UUID; shouldAddSenderToCc: boolean }]
  >;
  /**
   * Returns initial form values.
   * These are either default values for each form element if user is creating new version of the wire.
   * Or values copied from existing wire, if user is editing it.
   */
  useGetInitialValues: (
    args: UseGetInitialValuesArgs<TWire>
  ) => SyndicateWireContextQueryResult<TValues> & WireTypeConfigInitialValues<TValues>;

  CreateOrUpdateForm: React.FC<CreateOrUpdateFormProps>;
  createOrUpdateModalSize?: keyof RegularBreakpoints;
  ManagersNavigation: React.FC<ManagersNavigationProps>;
  formValidation: yup.ObjectSchema<TValues>;
};

export type RequiredFieldsForCreateWireTypeConfig<
  TValues extends {} = {},
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  // We need to use the RouteConfig utility type to be able to infer correct parameters from the url.
  // For more info check the RouteConfig definition.
  TRouteConfig extends RouteConfigType = RouteConfigType
> = Pick<
  WireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig>,
  | 'wireTypeName'
  | 'wireTypeRoute'
  | 'wireTemplateType'
  | 'useGenerateWirePreview'
  | 'useWireDetails'
  | 'useWireList'
  | 'useWireValidation'
  | 'useCreateMutation'
  | 'useUpdateMutation'
  | 'useDeleteMutation'
  | 'useSendMutation'
  | 'CreateOrUpdateForm'
  | 'formValidation'
  | 'useGetInitialValues'
>;

export type OptionalFieldsForCreateWireConfig<
  TValues extends {} = {},
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  // We need to use the RouteConfig utility type to be able to infer correct parameters from the url.
  // For more info check the RouteConfig definition.
  TRouteConfig extends RouteConfigType = RouteConfigType
> = Partial<
  Omit<
    WireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig>,
    keyof RequiredFieldsForCreateWireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig>
  >
>;

export function createWireTypeConfig<
  TValues extends {} = {},
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  // We need to use the RouteConfig utility type to be able to infer correct parameters from the url.
  // For more info check the RouteConfig definition.
  TRouteConfig extends RouteConfigType = RouteConfigType
>(
  config: RequiredFieldsForCreateWireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig> &
    OptionalFieldsForCreateWireConfig<TValues, TWire, TWireValidation, TRouteConfig>
): WireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig> {
  return {
    ManagersNavigation,
    wireTypeCategory: 'wire',
    useManagers: thisModule.createUseDefaultManagers(),
    useNextRecipients: thisModule.createUseDefaultOfferingManagers(),
    useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(),
    useOutdatedManagersList: createDefaultUseOutdatedManagersList(),
    useCreateIsDisabledReason: thisModule.useDefaultCreateIsDisabledReason,
    useSendIsDisabledReason: createUseDefaultSendIsDisabledReason(),
    isCcMyFirmEnabled: true,
    ...config,
  };
}

export function createUseDefaultManagers(
  filter: (manager: SyndicateWireManager) => boolean = () => true
) {
  return ({ offeringId, syndicateWireId }: { offeringId: UUID; syndicateWireId?: UUID }) => {
    const { useWireDetails } = useWireTypeConfigContext();

    const managerListQuery = useSyndicateManagerListQuery({ offeringId });

    const wireDetailsQuery = useWireDetails({ offeringId, syndicateWireId }, 'cache-first');

    const isLoading = managerListQuery.loading || wireDetailsQuery.isLoading;
    const error = managerListQuery.error ?? wireDetailsQuery.error;

    const messages = wireDetailsQuery.data?.wire.wireMessages;

    return React.useMemo(() => {
      if (error) {
        return { isLoading, error, data: undefined };
      }

      if (messages?.length) {
        return {
          isLoading,
          data: messages
            .filter(message => !!message.firmSnapshot)
            .map<SyndicateWireManager>(message => {
              if (!message.firmSnapshot?.offeringManagerRole) {
                throw new Error('Manager role is not defined');
              }

              return {
                cmgEntityKey: message.firmSnapshot.cmgEntityKey,
                firmName: message.firmSnapshot.firmName,
                role: message.firmSnapshot.offeringManagerRole,
              };
            })
            .filter(filter),
        };
      }

      return {
        isLoading,
        data: managerListQuery.data
          ?.map<SyndicateWireManager>(m => ({
            cmgEntityKey: m.cmgEntityKey,
            firmName: m.firmName,
            role: m.role,
          }))
          .filter(filter),
      };
    }, [managerListQuery.data, messages, isLoading, error]);
  };
}

/**
 * For Non-Syndicate wires the managers/recipients come from Retail Demand rather than Offering Details
 */
export function createUseDefaultNonSyndicateManagers() {
  return ({ offeringId, syndicateWireId }: { offeringId: UUID; syndicateWireId?: UUID }) => {
    const { useWireDetails } = useWireTypeConfigContext();

    const brokerDealersMyRetailQuery = useBrokerDealersMyRetailQuery({ offeringId });

    const wireDetailsQuery = useWireDetails({ offeringId, syndicateWireId }, 'cache-first');

    const isLoading = brokerDealersMyRetailQuery.loading || wireDetailsQuery.isLoading;
    const error = brokerDealersMyRetailQuery.error ?? wireDetailsQuery.error;

    const messages = wireDetailsQuery.data?.wire.wireMessages;

    return React.useMemo(() => {
      if (error) {
        return { isLoading, error, data: undefined };
      }

      if (messages?.length) {
        return {
          isLoading,
          data: messages
            .filter(message => !!message.firmSnapshot)
            .map<SyndicateWireManager>(message => ({
              ...message.firmSnapshot!,
              __typename: 'SyndicateWiresManager',
              role: 'BROKER_DEALER',
            })),
        };
      }

      return {
        isLoading,
        data: brokerDealersMyRetailQuery.data?.map<SyndicateWireManager>(manager => ({
          cmgEntityKey: manager.cmgEntityKey,
          firmName: manager.name,
          role: 'BROKER_DEALER',
        })),
      };
    }, [brokerDealersMyRetailQuery.data, messages, isLoading, error]);
  };
}

export function createUseDefaultOfferingManagers(
  filter: (manager: SyndicateWireManager) => boolean = () => true
) {
  return ({
    offeringId,
  }: {
    offeringId: UUID;
  }): SyndicateWireContextQueryResult<SyndicateWireManager[]> => {
    const { loading, error, data } = useSyndicateManagerListQuery({ offeringId });

    return React.useMemo(
      () => ({
        isLoading: loading,
        error: error,
        data: data?.filter(filter),
      }),
      [loading, error, data]
    );
  };
}

/**
 * For Non-Syndicate wires the managers/recipients come from Retail Demand rather than Offering Details
 */
export function createUseDefaultNonSyndicateNextRecipients() {
  return ({ offeringId }: { offeringId: UUID }) => {
    const { error, loading: isLoading, data } = useBrokerDealersMyRetailQuery({ offeringId });

    return React.useMemo(() => {
      if (error) {
        return { isLoading, error, data: undefined };
      }

      return {
        isLoading,
        data: data?.map<SyndicateWireManager>(manager => ({
          cmgEntityKey: manager.cmgEntityKey,
          firmName: manager.name,
          role: 'BROKER_DEALER',
        })),
      };
    }, [data, isLoading, error]);
  };
}

export function useDefaultCreateIsDisabledReason({
  offeringId,
}: {
  offeringId: UUID;
}): React.ReactNode | null {
  const { useManagers } = useWireTypeConfigContext();
  const { data } = useManagers({ offeringId });

  return data?.length ? null : 'There are no eligible recipients configured.';
}
