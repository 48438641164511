import {
  CurrencyInputField,
  DateTimePickerField,
  LinkButton,
  NumericInputField,
  SelectField,
  TextInputField,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { currencyOptions } from '../../../trading-notification/form/TNFilingForm.model';
import { buySellOptions, createTransaction, USDTNFilingFormValues } from '../USDTNFilingForm.model';
import { SColumnHeader, StyledTable } from './TransactionForm.styles';

const TransactionForm: React.FC = () => {
  const { values } = useFormikContext<USDTNFilingFormValues>();

  function addTransaction(arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.push(createTransaction());
  }

  function removeTransaction(arrayHelpers: FieldArrayRenderProps, index: number) {
    arrayHelpers.remove(index);
  }

  return (
    <FieldArray name="transactions">
      {arrayHelpers => (
        <Panel>
          <Panel.Header title="Transactions">
            <LinkButton
              inline
              testId={xcSelectors.usdtnAddTransaction.testId}
              onClick={() => addTransaction(arrayHelpers)}
              iconLeft={{ name: 'plus' }}
            >
              Add Transaction
            </LinkButton>
          </Panel.Header>
          {values.transactions.length > 0 && (
            <Panel.Content>
              <StyledTable responsive={false}>
                <thead>
                  <tr>
                    <SColumnHeader maxWidth={272}>
                      Execution Date and Time
                      <MandatoryAsterisk />
                    </SColumnHeader>
                    <SColumnHeader maxWidth={150}>
                      Number of Shares
                      <MandatoryAsterisk />
                    </SColumnHeader>
                    <SColumnHeader maxWidth={150}>
                      Execution Price
                      <MandatoryAsterisk />
                    </SColumnHeader>
                    <SColumnHeader maxWidth={150}>Currency</SColumnHeader>
                    <SColumnHeader maxWidth={150}>
                      Buy/Sell Indicator
                      <MandatoryAsterisk />
                    </SColumnHeader>
                    <SColumnHeader maxWidth={250}>Contra Party Firm Name</SColumnHeader>
                    <SColumnHeader maxWidth={150}>Contra Party Firm CRD</SColumnHeader>
                    <SColumnHeader maxWidth={50} />
                  </tr>
                </thead>
                <tbody>
                  {values.transactions.map((_, index) => (
                    <tr key={index}>
                      <td>
                        <DateTimePickerField
                          fullWidth
                          name={`transactions.${index}.executionDateTimeField`}
                          showTimezones
                          testId={`Regulatory Filings / Filings / USDTN / ${
                            index + 1
                          }. Execution Date and Time`}
                        />
                      </td>
                      <td>
                        <NumericInputField
                          fullWidth
                          name={`transactions.${index}.shares`}
                          aria-label={`${index + 1}. Transaction Number of Shares`}
                        />
                      </td>
                      <td>
                        <CurrencyInputField
                          fullWidth
                          name={`transactions.${index}.executionPrice`}
                          aria-label={`${index + 1}. Transaction Execution Price`}
                          precision={6}
                        />
                      </td>
                      <td>
                        <SelectField
                          fullWidth
                          name={`transactions.${index}.executionPriceCurrency`}
                          options={currencyOptions}
                          isClearable={false}
                          aria-label={`${index + 1}. Transaction Currency`}
                        />
                      </td>
                      <td>
                        <SelectField
                          fullWidth
                          name={`transactions.${index}.indicator`}
                          options={buySellOptions}
                          isClearable={false}
                          aria-label={`${index + 1}. Transaction Buy/Sell Indicator`}
                        />
                      </td>
                      <td>
                        <TextInputField
                          fullWidth
                          name={`transactions.${index}.counterPartyFirmName`}
                          aria-label={`${index + 1}. Transaction Contra Party Firm Name`}
                        />
                      </td>
                      <td>
                        <TextInputField
                          fullWidth
                          name={`transactions.${index}.counterPartyFirmCrdNumber`}
                          aria-label={`${index + 1}. Transaction Contra Party CRD`}
                        />
                      </td>
                      <td>
                        {values.transactions.length > 1 && (
                          <LinkButton
                            testId={`${xcSelectors.usdtnRemoveTransaction.testId}.${index}`}
                            onClick={() => removeTransaction(arrayHelpers, index)}
                            iconLeft={{ name: 'trash-alt' }}
                          >
                            Delete
                          </LinkButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </Panel.Content>
          )}
        </Panel>
      )}
    </FieldArray>
  );
};

export default TransactionForm;
