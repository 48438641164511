import {
  Roadshows_UpdateRoadshowManagementTeamMemberMutation,
  useRoadshows_UpdateRoadshowManagementTeamMemberMutation,
} from '../graphql';

export type UpdateTeamMemberMutation = Roadshows_UpdateRoadshowManagementTeamMemberMutation;

export const useUpdateTeamMemberMutation = () => {
  return useRoadshows_UpdateRoadshowManagementTeamMemberMutation();
};
