import React from 'react';

import { SHeader, SHeaderTitle } from './Panel.styles';

export type Props = {
  title?: string;
  className?: string;
};

/**
 * Naive implementation of a panel header
 * @deprecated - use layout components from @cmg/design-system instead
 */
const PanelHeader: React.FC<Props> = ({ title, children, className }) => {
  return (
    <SHeader className={className}>
      {title && <SHeaderTitle>{title}</SHeaderTitle>}
      {children}
    </SHeader>
  );
};

export default PanelHeader;
