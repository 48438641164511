import { ApolloError } from '@apollo/client';
import { Grid, Typography } from '@cmg/design-system';
import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import {
  CreateOfferingInput,
  OfferingType,
  SecurityType,
  UpdateBasicInfoInput,
} from '../../../graphql';
import OfferingSettlementAgentAuthoringBanner from '../basic-info-modal/OfferingSettlementAgentAuthoringBanner';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import {
  OfferingSetup_BasicInfoRoute_BasicInfoQuery,
  OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
} from '../graphql';
import IssuerSubForm from './components/IssuerSubForm';
import SecuritySubForm from './components/SecuritySubForm';

export const BasicInfoFormSchema = yup.object().shape({
  type: yup.string().required('Offering type is required'),
  issuer: yup.object().shape({
    name: yup.string().nullable().required('Issuer name is required'),
    cik: yup
      .string()
      .nullable()
      .matches(/^\d+$/, 'Must be numeric')
      .length(10, 'CIK should be a 10 digit number'),
  }),
  security: yup.object().shape({
    type: yup.string().nullable().required('Security Type is required'),
  }),
  pricingCurrencyCode: yup.string().required('Pricing Currency Code is required'),
});

export type FormType = CreateOfferingInput | UpdateBasicInfoInput;

export type Props = {
  offering?: OfferingSetup_BasicInfoRoute_BasicInfoQuery['offering'];
  publishedOffering?: OfferingSetup_BasicInfoRoute_PublishedOfferingQuery['publishedOffering'];
  panelHeaderContent?: React.ReactNode[] | React.ReactNode;
  panelAboveContent?: React.ReactNode[] | React.ReactNode;
  submitting?: boolean;
  disableUnsavedChangesGuard?: boolean;
  error?: ApolloError;
  onSubmit: (payload: FormType) => void;
};

/**
 * Basic Info Form (Offering Type, Issuer, Security)
 * Creates an Offering Draft, or updates info for an Offering
 */
export const BasicInfoFormComponent: React.FC<Props> = ({
  offering,
  publishedOffering,
  panelHeaderContent,
  panelAboveContent,
  submitting,
  onSubmit,
  disableUnsavedChangesGuard = false,
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: BasicInfoFormSchema,
    initialValues: {
      type: offering?.type || OfferingType.Ipo,
      issuer: {
        name: offering?.issuer.name || '',
        cik: offering?.issuer.cik || '',
        businessDescription: offering?.issuer.businessDescription || '',
        sector: offering?.issuer.sector || null,
        address: offering?.issuer.address || null,
        website: offering?.issuer.website || null,
        lei: offering?.issuer.lei || null,
      },
      security: {
        type: offering?.security.type || SecurityType.CommonOrdinaryShare,
        shareClass: offering?.security.shareClass || null,
      },
      pricingCurrencyCode: offering?.pricingCurrencyCode || 'USD',
    },
    onSubmit: values =>
      onSubmit({
        type: values.type || null,
        issuer: {
          name: values.issuer.name,
          lei: values.issuer.lei || null,
          cik: values.issuer.cik || null,
          website: values.issuer.website || null,
          address: values.issuer.address || null,
          businessDescription: values.issuer.businessDescription || null,
          sector: values.issuer.sector || null,
        },
        security: {
          type: values.security.type,
          shareClass: values.security.shareClass || null,
        },
        ...((values as CreateOfferingInput).pricingCurrencyCode
          ? { pricingCurrencyCode: (values as CreateOfferingInput).pricingCurrencyCode }
          : {}),
      }),
  };

  const formik = useFormik(formikOptions);

  const { dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard when={!disableUnsavedChangesGuard && dirty}>
        <Form noValidate>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Issuer Details</Typography>
                </Grid>
                <Grid item>
                  <SetupScreen.Header isDesignSystem={true} rightContent={panelHeaderContent} />
                </Grid>
              </Grid>
              {panelAboveContent && (
                <Grid item xs={12}>
                  {panelAboveContent}
                </Grid>
              )}
              {!offering && (
                <Grid item xs={12}>
                  <OfferingSettlementAgentAuthoringBanner />
                </Grid>
              )}
            </Grid>
            <Grid container item spacing={2}>
              <IssuerSubForm
                offering={offering}
                submitting={submitting}
                publishedOffering={publishedOffering}
              />
            </Grid>
            <SecuritySubForm submitting={submitting} />
          </Grid>
        </Form>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default BasicInfoFormComponent;
