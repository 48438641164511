import React from 'react';

import { InstitutionalIndicationOrderType } from '../../../../../../../../graphql';
import { institutionalIndicationOrderTypeLabels } from '../../../../../../constants';
import { getTypeConfigurations } from '../../../grid-columns/type-cell/TypeCell';
import { DupesGridRow } from '../DupesModal.model';
import { SInterestLevel, SInterestLevelText } from './IndicationCell.styles';

type Props = {
  data: DupesGridRow;
};

/**
 * Renders the Company Name Cell within the Institutional Demand Grid.
 */
const IndicationCell: React.FC<Props> = ({ data }) => {
  const { configurationsForExport, label } = getTypeConfigurations({
    type: data.type,
    interestLevels: data.interestLevels,
    forGridExport: true,
    pricingCurrencyCode: data.pricingCurrencyCode,
    demandCurrencyCode: data.currencyCode,
  });

  const isPassed = data.type === InstitutionalIndicationOrderType.Pass;

  if (isPassed) {
    return <div>{institutionalIndicationOrderTypeLabels[data.type]}</div>;
  }
  return configurationsForExport && configurationsForExport?.length > 0 ? (
    <SInterestLevel>
      {configurationsForExport.map((item, index) => (
        <SInterestLevelText key={index}>
          {item}
          <br />
        </SInterestLevelText>
      ))}
    </SInterestLevel>
  ) : (
    <div>{label}</div>
  );
};

export default IndicationCell;
