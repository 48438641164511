import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ButtonsContainer, PrimaryButton, SecondaryButton, ToastManager, UUID } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import ValidationErrorBanner from '../../common/components/error-banner/ValidationErrorBanner';
import { ExportButton } from '../../common/components/export-button/ExportButton';
import {
  SSettlementHeaderText,
  SSettlementHeaderWrapper,
} from '../../common/components/settlement-header/SettlementHeader';
import { useExpensesFilterContext } from '../context/ExpensesFilterContext';
import ExpensesPanel from '../expenses-panel/ExpensesPanel';
import {
  FinalSettlement_ExpensesQuery,
  useFinalSettlement_CreateExpensesRevisionMutation,
} from '../graphql';
import NotesPanel from '../notes-panel/NotesPanel';
import {
  expensesSchema,
  ExpensesValues,
  formValuesToPayload,
  getErrors,
  getInitialValues,
} from './ExpensesForm.model';
import { useExportOfferingExpenses } from './hooks/useExportOfferingExpenses';

export type Props = {
  offeringId: UUID;
  expenses: FinalSettlement_ExpensesQuery['expenses'];
  offeringIssuerName: string;
};

const ExpensesForm: React.FC<Props> = ({ expenses, offeringId, offeringIssuerName }) => {
  const canEditExpense = useCheckPermissions([permissionsByEntity.Expense.FULL]);
  const isCreateWorkflow = expenses.managerExpenses.length === 0;

  const [editable, setEditable] = React.useState(isCreateWorkflow && canEditExpense);
  const { setCategoryFilter, setDescriptionFilter } = useExpensesFilterContext();

  const [createExpensesRevision, { loading: createLoading }] =
    useFinalSettlement_CreateExpensesRevisionMutation();
  const { handleExport, loading: exportLoading } = useExportOfferingExpenses({
    offeringIssuerName,
    offeringId,
  });

  const formik = useFormik<ExpensesValues>({
    initialValues: getInitialValues(expenses),
    validationSchema: expensesSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async values => {
      try {
        await createExpensesRevision({
          variables: {
            offeringId,
            payload: formValuesToPayload(values),
          },
        });
        setEditable(false);
      } catch {
        ToastManager.error('Error while saving expenses');
      }
    },
  });

  const { errors, hasManagerExpensesError, hasDealRelatedExpensesError } = getErrors(
    formik.errors.managerExpenses,
    formik.errors.dealRelatedExpenses
  );

  return (
    <div data-test-id="final-settlement-expenses">
      <SSettlementHeaderWrapper>
        <SSettlementHeaderText>Expenses</SSettlementHeaderText>
        <ButtonsContainer>
          {editable && !isCreateWorkflow && (
            <SecondaryButton
              testId="cancel-edit-expenses"
              onClick={() => {
                formik.resetForm();
                setEditable(false);
              }}
            >
              Cancel
            </SecondaryButton>
          )}
          {editable && (
            <PrimaryButton
              testId="save-expenses"
              loading={createLoading}
              onClick={() => {
                setCategoryFilter('');
                setDescriptionFilter('');
                formik.submitForm();
              }}
            >
              Save
            </PrimaryButton>
          )}
          {!editable && (
            <React.Fragment>
              <ExportButton testId="export-xls" loading={exportLoading} onExport={handleExport} />
              {canEditExpense && (
                <SecondaryButton testId="edit-expenses" onClick={() => setEditable(true)}>
                  Edit
                </SecondaryButton>
              )}
            </React.Fragment>
          )}
        </ButtonsContainer>
      </SSettlementHeaderWrapper>
      {(hasManagerExpensesError || hasDealRelatedExpensesError) && (
        <ValidationErrorBanner errors={errors} />
      )}
      <FormikProvider value={formik}>
        <Form>
          <NotesPanel editable={editable} />
          <ExpensesPanel
            managerExpensesError={hasManagerExpensesError}
            dealRelatedExpensesError={hasDealRelatedExpensesError}
            editable={editable}
            offeringId={offeringId}
            managers={expenses.managers}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default ExpensesForm;
