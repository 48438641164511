import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { UUID } from '@cmg/common';
import {
  Button,
  Divider,
  DotStatus,
  LoadingButton,
  PageHeader,
  PageLayout,
  SnackbarManager,
  Stack,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Route } from 'react-router-dom';

import FormikUnsavedChangesGuard from '../../../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import routeFactory from '../../../../../common/util/routeFactory';
import ExportButton from '../../../components/export-button/ExportButton';
import { useExportContext } from '../../../components/export-button/ExportContext';
import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../graphql';
import TotalRetailDemandHistoryActionPanel, {
  RouteProps,
} from '../common/history-action-panel/TotalRetailDemandHistoryActionPanel';
import {
  createInitialValues,
  createUpdateRetailDemandVariables,
  getRowsWithChangedRetention,
  TotalRetailBulkSaveValues,
  validationSchema,
} from './BulkSaveTotalRetailDemand.model';
import { useOrderBook_UpdateTotalRetailDemandMutation } from './graphql';
import InvalidFormAlert from './invalid-form-alert/InvalidFormAlert';
import ReleaseTotalRetailDemandDialog from './release-dialog/ReleaseTotalRetailDemandDialog';
import BulkSaveTotalRetailDemandTable from './table/BulkSaveTotalRetailDemandTable';

export type Props = Readonly<{
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[];
  retailTarget: number | null;
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
}>;

const BulkSaveTotalRetailDemand: React.FC<Props> = ({
  rows,
  offeringId,
  instrumentId,
  trancheId,
  retailTarget,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [isReleaseDialogOpen, setIsReleaseDialogOpen] = React.useState(false);

  const allManagers = React.useMemo(() => rows.map(({ manager }) => manager), [rows]);

  const { isExporting, onExport } = useExportContext();

  const [updateRetailDemand, { loading: isUpdating }] =
    useOrderBook_UpdateTotalRetailDemandMutation();

  const handleUpdateRetailDemand = async (values: TotalRetailBulkSaveValues) => {
    try {
      await updateRetailDemand({
        variables: createUpdateRetailDemandVariables({
          offeringId,
          instrumentId,
          trancheId,
          values,
        }),
      });
      setIsReleaseDialogOpen(false);
      resetForm({ values });

      setIsEditing(false);
    } catch {
      SnackbarManager.error('Failed to save Total Demand');
    }
  };
  const handleSubmitForm = async (values: TotalRetailBulkSaveValues) => {
    const changedRows = getRowsWithChangedRetention(rows, values);

    // we have to check for changed rows inside form submission, because FormikUnsavedChangesGuard uses formik.handleSubmit and when we submit through it, we have to show dialog
    if (changedRows.length > 0) {
      setIsReleaseDialogOpen(true);
      return;
    }
    await handleUpdateRetailDemand(values);
  };

  const formik = useFormik<TotalRetailBulkSaveValues>({
    initialValues: createInitialValues(rows),
    validationSchema,
    onSubmit: handleSubmitForm,
  });
  const { resetForm, handleSubmit, dirty, values } = formik;

  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleDiscardChanges = () => {
    resetForm();
    setIsEditing(false);
  };
  const handleCloseReleaseDialog = () => {
    setIsReleaseDialogOpen(false);
  };
  const handleReleaseRetailDemand = async () => {
    await handleUpdateRetailDemand(values);
  };

  return (
    <PageLayout
      header={
        <React.Fragment>
          <PageHeader
            pageTitle="Total Demand"
            status={
              dirty ? (
                <DotStatus color="warning" label="Unreleased Changes" />
              ) : (
                <DotStatus color="success" label="Retentions Released" />
              )
            }
            actions={[
              <ExportButton key="export-button" loading={isExporting} onExport={onExport} />,
              <AccessControl
                key="crud-buttons"
                requiredPermissions={[
                  permissionsByEntity.RetailDemand.FULL,
                  permissionsByEntity.RetailRetention.FULL,
                ]}
                requireAllPermissions={false}
              >
                {!isEditing && (
                  <Button variant="contained" onClick={handleStartEditing}>
                    Edit
                  </Button>
                )}
                {isEditing && (
                  <Button variant="outlined" disabled={isUpdating} onClick={handleDiscardChanges}>
                    Discard Changes
                  </Button>
                )}
                {isEditing && (
                  <LoadingButton
                    variant="contained"
                    loading={isUpdating}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </LoadingButton>
                )}
              </AccessControl>,
            ]}
          />
          <Divider />
        </React.Fragment>
      }
    >
      <Stack py={2} gap={2} data-testid="bulk-save-layout">
        <FormikProvider value={formik}>
          <InvalidFormAlert />

          <FormikUnsavedChangesGuard
            when={dirty || isReleaseDialogOpen}
            onLeave={() => resetForm({ values })}
          >
            <Form>
              <BulkSaveTotalRetailDemandTable
                offeringId={offeringId}
                allManagers={allManagers}
                rows={rows}
                isEditing={isEditing}
                retailTarget={retailTarget}
              />
            </Form>
          </FormikUnsavedChangesGuard>
        </FormikProvider>
      </Stack>

      <ReleaseTotalRetailDemandDialog
        open={isReleaseDialogOpen}
        onCancel={handleCloseReleaseDialog}
        onRelease={handleReleaseRetailDemand}
        isReleasing={isUpdating}
        rows={rows}
        values={values}
      />

      <Route
        path={routeFactory.orderBookTotalRetailDemandHistory.routePath}
        render={routeProps => (
          <TotalRetailDemandHistoryActionPanel
            {...(routeProps as RouteProps)}
            allManagers={allManagers}
          />
        )}
      />
    </PageLayout>
  );
};

export default BulkSaveTotalRetailDemand;
