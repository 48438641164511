import { loggerUtil } from '@cmg/common';
import { Chip, FormControlLabel, Stack, Switch, SwitchProps, Tooltip } from '@cmg/design-system';
import React, { useCallback } from 'react';

import { useDemandGridV2FeaturePreference } from '../../hooks/useDemandGridV2FeaturePreference';

type Props = Readonly<{
  /**
   * Wrapping component
   */
  Component?: React.ComponentType;
}>;

export const DemandGridV2Switch: React.FC<Props> = ({ Component = React.Fragment }) => {
  const { log } = loggerUtil.useMixpanel();
  const demandGridV2Feature = useDemandGridV2FeaturePreference();

  const handleChange = useCallback<NonNullable<SwitchProps['onChange']>>(
    (_, checked) => {
      log(`Switching to Demand Grid ${checked ? 'beta' : 'old'}`);
      demandGridV2Feature.setPreference(checked);
    },
    [demandGridV2Feature, log]
  );

  if (!demandGridV2Feature.isToggleOn) {
    return null;
  }

  return (
    <Component>
      <Stack gap={1} direction="row" alignItems="center">
        <FormControlLabel
          control={<Switch checked={demandGridV2Feature.isPreferenceOn} onChange={handleChange} />}
          label="Try Enhanced Mode"
          sx={{ margin: 0 }}
        />
        <Tooltip
          variant="info"
          placement="top-start"
          title="Turn on to experience the new improved Institutional Demand Grid"
        >
          <Chip label="Beta" variant="outlined" size="small" color="primary" />
        </Tooltip>
      </Stack>
    </Component>
  );
};
