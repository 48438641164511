import { ColDef } from 'ag-grid-community';
import includes from 'lodash/includes';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../common/config/appSettings';
import { ProspectusDeliveryStatus } from '../../../../../../../graphql';
import { ProspectusDeliveryStatusLabel } from '../../../../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';
import { getProspectusDeliveryLabel } from '../../../../../components/prospectus-delivery-status/ProspectusDeliveryStatus';
import { CMGColumnID, IndicationWithDemandLevels } from '../../types';
import AttestationStatusCell from '../attestation-cell/AttestationCell';
import ProspectusDeliveryStatusCell from '../prospectus-delivery-status-cell/ProspectusDeliveryStatusCell';
import { defaultColumnProps, defaultColumnWidth } from './Columns.model';

export const useComplianceColumns = (): ColDef[] => {
  return useMemo(() => {
    const { isOrderBookAttestationOn, isOrderBookPinnedColumnsOn } = getFeatureToggles();
    const lockPinned = !isOrderBookPinnedColumnsOn;

    const hideFilter = (c: ColDef) => {
      const colId: string = c.colId!;
      const excludedColumns = [...(!isOrderBookAttestationOn ? [CMGColumnID.ATTESTATION] : [])];
      return !includes(excludedColumns, colId);
    };

    const colDefs: ColDef[] = [
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.ATTESTATION,
        field: 'attestationStatus',
        headerName: '5130/31 Status',
        cellRendererFramework: AttestationStatusCell,
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.PROSPECTUS_DELIVERY_STATUS,
        field: 'prospectusDeliveryStatus',
        headerName: 'Prospectus Contacts',
        headerValueGetter: () => 'Prospectus Contacts ⓘ',
        headerTooltip: 'Investor contacts that have been sent prospectus documents.',
        valueGetter: ({ data }: { data: IndicationWithDemandLevels }) => {
          const { sentEmailCount = 0, totalEmailCount = 0 } = data.prospectusDeliveryStatus ?? {};

          const label = getProspectusDeliveryLabel({ sentEmailCount, totalEmailCount });
          return totalEmailCount > 0
            ? label
            : ProspectusDeliveryStatusLabel[ProspectusDeliveryStatus.NotSent];
        },
        cellRendererFramework: ProspectusDeliveryStatusCell,
        lockPinned,
      },
    ];

    return colDefs.filter(hideFilter);
  }, []);
};
