import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateUnderwriterInvitationWireMutation,
  SyndicateWires_UnderwriterInvitationWiresDocument,
  useSyndicateWires_CreateUnderwriterInvitationWireMutation,
} from '../graphql';

export type CreateUnderwriterInvitationWireMutation =
  SyndicateWires_CreateUnderwriterInvitationWireMutation;

export const useCreateUnderwriterInvitationWireMutation = () => {
  return useSyndicateWires_CreateUnderwriterInvitationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_UnderwriterInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
