import { useAuth } from '@cmg/auth';
import { numericUtil } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import React from 'react';

import { useFeatureToggles } from '../../../../../../common/config';
import { InstitutionalIndicationOrderType, OfferingType } from '../../../../../../graphql';
import { RowAlertsObject } from '../components/row-alert/RowAlert.model';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../graphql';
import { PinnedColumnsType } from '../preferences/useInstitutionalDemandPreferences';
import { IndicationWithDemandLevels, InstitutionalDraftSet } from '../types';
import { generateDraftAllocationSetColumn } from './columns-configuration/allocation-set-columns/AllocationSetColumns.model';
import { useGetFinalAllocationColumns } from './columns-configuration/allocation-set-columns/FinalAllocationSetColumn';
import { useCmgColumns } from './columns-configuration/CmgColumns';
import { useComplianceColumns } from './columns-configuration/ComplianceColumns';
import {
  generateDemandLevelColumns,
  useGetDemandColumns,
} from './columns-configuration/DemandLevelColumns';
import { useGenerageInvestorExtendedDataColumns } from './columns-configuration/InvestorExtendedDataColumns';
import { useGetNotesColumns } from './columns-configuration/NotesColumns';
import { SPinnedRowDemandText } from './InstitutionalDemandGridColumns.styles';

export const shareCountValueFormatter = ({
  value,
  data,
}: {
  value: number | null;
  data: IndicationWithDemandLevels;
}) => {
  if (data.type === InstitutionalIndicationOrderType.Pass) {
    return '-';
  }
  return !isNil(value) ? numericUtil.formatNumber(value, 0) : '-';
};

export const ShareCountTotalCellRenderer = ({ value }: { value: number }) => {
  return <SPinnedRowDemandText>{numericUtil.formatNumber(value, 0)}</SPinnedRowDemandText>;
};

export function getPinnedColProperty(colDef: ColDef, pinnedColumns: PinnedColumnsType) {
  if (colDef.pinned) {
    return colDef.pinned; // respect the predefined pinned columns
  }
  if (pinnedColumns.left.includes(colDef.colId!)) {
    return 'left';
  }
  if (pinnedColumns.right.includes(colDef.colId!)) {
    return 'right';
  }
  return false;
}

export const useGenerateGridColumns = ({
  offeringId,
  demandIncrements,
  draftSets,
  columnsOrder,
  visibleColumns,
  isFinalAllocationSetReleased,
  investorsExtendedData,
  finalAllocationExists,
  offeringType,
  pricingCurrencyCode,
  pinnedColumns,
  displayDupeGroupColumn,
  rowAlertsObject,
}: {
  offeringId: string;
  demandIncrements: number[];
  draftSets: readonly InstitutionalDraftSet[];
  columnsOrder: string[];
  visibleColumns: string[];
  isFinalAllocationSetReleased: boolean;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  finalAllocationExists: boolean;
  offeringType?: OfferingType;
  pricingCurrencyCode?: string;
  pinnedColumns: PinnedColumnsType;
  displayDupeGroupColumn: boolean;
  rowAlertsObject: RowAlertsObject;
}): ColDef[] => {
  const { oidcUserCmgEntityKey } = useAuth();
  const cmgColumns = useCmgColumns(
    offeringId,
    rowAlertsObject,
    offeringType,
    displayDupeGroupColumn
  );
  const complianceColumns = useComplianceColumns();
  const demandColumns = useGetDemandColumns();
  const notesColumns = useGetNotesColumns();
  const finalAllocationColumns = useGetFinalAllocationColumns({
    finalAllocationExists,
    isFinalAllocationSetReleased,
    offeringId,
  });
  const dynamicColumns = useGenerageInvestorExtendedDataColumns(investorsExtendedData);
  const { isOrderBookPinnedColumnsOn } = useFeatureToggles();

  return React.useMemo(() => {
    const demandLevelColumns = generateDemandLevelColumns(
      demandIncrements,
      isOrderBookPinnedColumnsOn,
      pricingCurrencyCode
    );
    const draftSetsColumns = draftSets.map(draftAllocationSet => {
      const isShared = draftAllocationSet.authorCmgEntityKey !== oidcUserCmgEntityKey;
      return generateDraftAllocationSetColumn(
        draftAllocationSet,
        isShared,
        isOrderBookPinnedColumnsOn
      );
    });

    const sortFn = (a: ColDef, b: ColDef) =>
      columnsOrder.indexOf(a.colId!) - columnsOrder.indexOf(b.colId!);

    const sortedColumns = [
      ...cmgColumns,
      ...dynamicColumns,
      ...complianceColumns,
      ...notesColumns,
      ...demandColumns,
      ...draftSetsColumns,
      ...finalAllocationColumns,
      ...demandLevelColumns,
    ].sort(sortFn);

    return [...sortedColumns].map(colDef => {
      return {
        ...colDef,
        hide: !(colDef.hide === false || includes(visibleColumns, colDef.colId!)),
        pinned: isOrderBookPinnedColumnsOn
          ? getPinnedColProperty(colDef, pinnedColumns)
          : colDef.pinned,
      };
    });
  }, [
    demandIncrements,
    isOrderBookPinnedColumnsOn,
    pricingCurrencyCode,
    draftSets,
    cmgColumns,
    dynamicColumns,
    complianceColumns,
    notesColumns,
    demandColumns,
    finalAllocationColumns,
    oidcUserCmgEntityKey,
    columnsOrder,
    visibleColumns,
    pinnedColumns,
  ]);
};
