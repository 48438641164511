import React from 'react';
import { useHistory, useLocation } from 'react-router';

import ListItem from '../../lists/list/ListItem';

export type Props = {
  url: string;
  isActive?: boolean;
};

const SubNavItem: React.FC<Props> = ({ children, url, isActive: isActiveProp }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isActive = isActiveProp ?? url === pathname;

  const handleOpen = () => {
    if (url) {
      push(url);
    }
  };

  return (
    <ListItem onClick={handleOpen} active={isActive} isChild>
      {children}
    </ListItem>
  );
};

export default SubNavItem;
