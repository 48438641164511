/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_ParticipatedRoadshowNamesQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
  memberId: Types.Scalars['UUID'];
}>;

export type Roadshows_ParticipatedRoadshowNamesQuery = {
  readonly __typename?: 'Query';
  readonly dealTeamMemberRoadshowNames: {
    readonly __typename?: 'DealTeamMemberRoadshowNames';
    readonly participatedRoadshowNames: ReadonlyArray<string>;
  };
};

export const Roadshows_ParticipatedRoadshowNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_ParticipatedRoadshowNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealTeamMemberRoadshowNames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'memberId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberRoadshowNamesParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberRoadshowNamesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DealTeamMemberRoadshowNames' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } }],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_ParticipatedRoadshowNamesQuery__
 *
 * To run a query within a React component, call `useRoadshows_ParticipatedRoadshowNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_ParticipatedRoadshowNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_ParticipatedRoadshowNamesQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRoadshows_ParticipatedRoadshowNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_ParticipatedRoadshowNamesQuery,
    Roadshows_ParticipatedRoadshowNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_ParticipatedRoadshowNamesQuery,
    Roadshows_ParticipatedRoadshowNamesQueryVariables
  >(Roadshows_ParticipatedRoadshowNamesDocument, options);
}
export function useRoadshows_ParticipatedRoadshowNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_ParticipatedRoadshowNamesQuery,
    Roadshows_ParticipatedRoadshowNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_ParticipatedRoadshowNamesQuery,
    Roadshows_ParticipatedRoadshowNamesQueryVariables
  >(Roadshows_ParticipatedRoadshowNamesDocument, options);
}
export type Roadshows_ParticipatedRoadshowNamesQueryHookResult = ReturnType<
  typeof useRoadshows_ParticipatedRoadshowNamesQuery
>;
export type Roadshows_ParticipatedRoadshowNamesLazyQueryHookResult = ReturnType<
  typeof useRoadshows_ParticipatedRoadshowNamesLazyQuery
>;
export type Roadshows_ParticipatedRoadshowNamesQueryResult = Apollo.QueryResult<
  Roadshows_ParticipatedRoadshowNamesQuery,
  Roadshows_ParticipatedRoadshowNamesQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_ParticipatedRoadshowNamesMockResponse = makeMockResponse<
  Roadshows_ParticipatedRoadshowNamesQueryVariables,
  Roadshows_ParticipatedRoadshowNamesQuery
>(Roadshows_ParticipatedRoadshowNamesDocument);
