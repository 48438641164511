import { useAuth } from '@cmg/auth';
import { timeUtil } from '@cmg/common';
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { getDeliveryInstrumentLabel } from '../../../../../../../../common/components/form/delivery-instrument-select-field/DeliveryInstrumentSelectField';
import { useFeatureToggles } from '../../../../../../../../common/config';
import { InstitutionalIndicationOrderType, SecurityType } from '../../../../../../../../graphql';
import { interestUnitLabels } from '../../../../../../../../types/domain/order-book/constants';
import { institutionalIndicationOrderTypeLabels } from '../../../../../../../order-book/constants';
import { IndicationForm_IndicationDetails_OfferingPartsFragment } from '../../../indication-form/components/indication-details/graphql/__generated__';
import { IndicationForm_MyIndicationPartsFragment } from '../../../indication-form/graphql/__generated__';
import GridRowPair from '../indication-section-read-view/grid-row-pair/GridRowPair';
import InterestLevelsHeaderRow from '../indication-section-read-view/interest-levels-header-row/InterestLevelsHeaderRow';
import AcknowledgedByList from './acknowledged-by-list/AcknowledgedByList';
import {
  ACK_BY_TOOLTIP_TEXT,
  getFirms,
  getInterestQuantityCellValue,
  getLimitTypeCellValue,
} from './IndicationSectionReadContent.model';

const { formatAsDisplayDateTime } = timeUtil;

export type Props = Readonly<{
  submittedOn: string | null | undefined;
  demandInstruments: IndicationForm_IndicationDetails_OfferingPartsFragment['orderEntryProfile']['instruments'];
  acknowledgements: IndicationForm_MyIndicationPartsFragment['acknowledgements'];
  indicationVersion: string;
  indicationInstrumentId: string | null | undefined;
  indicationTrancheId: string | null | undefined;
  managers: IndicationForm_IndicationDetails_OfferingPartsFragment['syndicate']['managers'];
  bndAgentCmgEntityKey: string | null | undefined;
  interestLevels: IndicationForm_MyIndicationPartsFragment['interestLevels'];
  tranches: IndicationForm_IndicationDetails_OfferingPartsFragment['orderEntryProfile']['tranches'];
  // this is demand currency
  demandCurrencyCode: string | null | undefined;
  pricingCurrencyCode: string | null | undefined;
  securityType: SecurityType;
}>;
const IndicationSectionReadContent: React.FC<Props> = ({
  submittedOn,
  demandInstruments,
  acknowledgements,
  indicationVersion,
  managers,
  bndAgentCmgEntityKey,
  interestLevels,
  tranches,
  indicationTrancheId,
  demandCurrencyCode,
  pricingCurrencyCode,
  indicationInstrumentId,
  securityType,
}) => {
  const { isOrderBookSidePanelBuySideIndicationAcknowledgementOn } = useFeatureToggles();
  const { oidcUserCmgEntityKey } = useAuth();

  const tranche = tranches.find(({ id }) => id === indicationTrancheId)!;
  const firms = getFirms(acknowledgements, managers, oidcUserCmgEntityKey, indicationVersion);
  const bndAgentName = managers.find(
    ({ cmgEntityKey }) => bndAgentCmgEntityKey === cmgEntityKey
  )?.firmName;
  const demandInstrument = demandInstruments.find(({ id }) => id === indicationInstrumentId);

  return (
    <Stack gap={1}>
      <Grid container rowGap={1}>
        <GridRowPair
          label="Submitted On:"
          value={submittedOn}
          formatter={formatAsDisplayDateTime}
        />
        <GridRowPair label="Tranche:" value={tranche.name} />
        <GridRowPair
          label="Demand Instrument:"
          value={{
            deliveryInstrument: demandInstrument,
            securityType,
          }}
          formatter={getDeliveryInstrumentLabel}
        />
        <GridRowPair label="Demand Currency:" value={demandCurrencyCode} />
        {isOrderBookSidePanelBuySideIndicationAcknowledgementOn && (
          <GridRowPair
            label={
              <Tooltip variant="info" title={ACK_BY_TOOLTIP_TEXT} placement="top-start">
                <Typography color="text.secondary">Acknowledged by ⓘ:</Typography>
              </Tooltip>
            }
            value={<AcknowledgedByList values={firms} />}
          />
        )}
        <GridRowPair label="Billing & Delivery Agent:" value={bndAgentName} />
      </Grid>

      <div>
        <Typography variant="h4" paddingY={1}>
          Interest Levels
        </Typography>

        <TableContainer>
          <Table data-testid="interest-levels-table">
            <TableHead>
              <InterestLevelsHeaderRow />
            </TableHead>

            <TableBody>
              {interestLevels.map(
                ({ interestQuantity, interestUnit, limitPrice, limitType }, index) => {
                  const orderType = limitPrice
                    ? InstitutionalIndicationOrderType.Limit
                    : InstitutionalIndicationOrderType.Market;

                  return (
                    <TableRow key={index}>
                      <TableCell size="medium" align="right">
                        {getInterestQuantityCellValue(
                          interestUnit,
                          interestQuantity,
                          demandCurrencyCode
                        )}
                      </TableCell>
                      <TableCell size="medium">{interestUnitLabels[interestUnit]}</TableCell>
                      <TableCell size="medium">
                        {institutionalIndicationOrderTypeLabels[orderType]}
                      </TableCell>
                      <TableCell size="medium">
                        {getLimitTypeCellValue(
                          orderType,
                          limitType,
                          pricingCurrencyCode,
                          limitPrice
                        )}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Stack>
  );
};

export default IndicationSectionReadContent;
