import type { UUID } from '@cmg/common';
import { ListItem, SnackbarManager } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

import { useCheckDraftAllocationAccess } from '../../../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import useCheckInstitutionalPassiveOrderBookAccess from '../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { useDuplicateDraftAllocationSetMutation } from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/institutional-demand-menu-drafts/draft-allocation-list-item/hooks/useDuplicateDraftAllocationSetMutation';
import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../graphql/__generated__';
import ListItemKebabMenu from '../kebab-menu/ListItemKebabMenu';
import AllocationSetListItemText from './AllocationSetListItemText';

export type Props = Readonly<{
  offeringId: UUID;
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment;
  onEditDraftAllocationSet: () => void;
  onDeleteDraftAllocationSet: () => void;
  onShareDraftAllocationSet: () => void;
  onPromoteDraftAllocationSetToFinal: () => void;
  onCopyDraftAllocationSetToDefault: () => void;
}>;

const DraftAllocationSetListItem: React.FC<Props> = ({
  allocationSet,
  offeringId,
  onEditDraftAllocationSet,
  onDeleteDraftAllocationSet,
  onShareDraftAllocationSet,
  onPromoteDraftAllocationSetToFinal,
  onCopyDraftAllocationSetToDefault,
}) => {
  const { canManage: canManageAllocation } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });
  const { canManage: canManageDraftAllocation } = useCheckDraftAllocationAccess({ offeringId });

  const [duplicate] = useDuplicateDraftAllocationSetMutation();

  const handleDuplicate = React.useCallback(async () => {
    try {
      await duplicate({ variables: { offeringId, setId: allocationSet.id } });
      SnackbarManager.success('Successfully duplicated the allocation set');
    } catch {
      SnackbarManager.error('Failed to duplicate the allocation set');
    }
  }, [duplicate, allocationSet.id, offeringId]);

  const canDelete = !allocationSet.isDefault && isNil(allocationSet.shareTimestamp);
  const canShare =
    !canManagePassiveOrderBook && canManageDraftAllocation && allocationSet.isDefault;
  const canEdit = isNil(allocationSet.shareTimestamp);
  const canCopyToDefault = !allocationSet.isDefault;

  const actions = React.useMemo(
    () => [
      ...(canEdit
        ? [
            {
              label: 'Edit',
              onClick: onEditDraftAllocationSet,
            },
          ]
        : []),
      ...(canDelete
        ? [
            {
              label: 'Delete',
              onClick: onDeleteDraftAllocationSet,
            },
          ]
        : []),
      {
        label: 'Duplicate',
        onClick: () => {
          handleDuplicate();
        },
      },
      ...(canShare
        ? [
            {
              label: 'Share',
              onClick: onShareDraftAllocationSet,
            },
          ]
        : []),
      ...(canCopyToDefault
        ? [
            {
              label: 'Copy to Default',
              onClick: onCopyDraftAllocationSetToDefault,
            },
          ]
        : []),
      ...(canManageAllocation
        ? [
            {
              label: 'Promote to Final',
              onClick: onPromoteDraftAllocationSetToFinal,
            },
          ]
        : []),
    ],
    [
      canCopyToDefault,
      canDelete,
      canEdit,
      canManageAllocation,
      canShare,
      handleDuplicate,
      onCopyDraftAllocationSetToDefault,
      onDeleteDraftAllocationSet,
      onEditDraftAllocationSet,
      onPromoteDraftAllocationSetToFinal,
      onShareDraftAllocationSet,
    ]
  );

  return (
    <ListItem
      disableGutters
      aria-label={`Draft Allocation Set: ${allocationSet.name ?? 'Draft'}`}
      secondaryAction={
        <ListItemKebabMenu aria-label="Toggle allocations dropdown" actions={actions} />
      }
    >
      <AllocationSetListItemText allocationSet={allocationSet} />
    </ListItem>
  );
};

export default DraftAllocationSetListItem;
