import { Ribbon } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { SyndicateWire } from '../../../../SyndicateWiresRoute.model';
import { OperationType } from '../../modals/send-wire-modal/SendWireModal.model';
import { getWireMessageForManager } from '../../navigation/managers-side-nav/ManagersNavigation.model';
import { WireRibbon } from '../../wire-ribbon/WireRibbon';
import { SelectWireManager } from '../../zero-state/select-wire-manager/SelectWireManager';
import { WireObsolete } from '../../zero-state/wire-obsolete/WireObsolete';
import { WireSent } from '../../zero-state/wire-sent/WireSent';
import {
  getWirePreviewHtml,
  wireMessageStatusToWireStageType,
} from './SentStageWireTemplate.model';

export type Props = {
  managerId: string | undefined;
  wire: SyndicateWire;
  isWireObsolete: boolean;
  operation: OperationType | undefined;
  isSendDisabled: boolean;
};

export const SentStageWireTemplate: React.FC<Props> = ({
  managerId,
  wire,
  isWireObsolete,
  operation,
  isSendDisabled,
}) => {
  const templateHTML = {
    __html: getWirePreviewHtml(managerId, wire),
  };

  const wireMessageStatus = getWireMessageForManager(
    managerId,
    wire?.wireMessages
  )?.wireMessageAndResponseStatus;

  if (wireMessageStatus) {
    return (
      <Ribbon.Wrapper>
        <WireRibbon stage={wireMessageStatusToWireStageType[wireMessageStatus]} />
        <div
          data-test-id={xcSelectors.wireSentStagePreviewHtml.testId}
          dangerouslySetInnerHTML={templateHTML}
        />
      </Ribbon.Wrapper>
    );
  }

  if (operation === OperationType.SEND) {
    return <WireSent />;
  }

  if (isWireObsolete) {
    return <WireObsolete />;
  }

  return <SelectWireManager isSendDisabled={isSendDisabled} />;
};
