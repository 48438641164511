/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_ReleaseSalesCreditsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  investorFirmCmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type SalesCredits_ReleaseSalesCreditsMutation = {
  readonly __typename?: 'Mutation';
  readonly releaseSalesCredits: {
    readonly __typename?: 'SalesCreditGrid';
    readonly id: string;
    readonly settlementCurrency: string;
    readonly sellingConcession?: number | null;
    readonly salesCredits: ReadonlyArray<{
      readonly __typename?: 'SalesCreditItem';
      readonly id: string;
      readonly salesCredits?: number | null;
      readonly salesCreditsReleased?: number | null;
      readonly salesCreditsManual?: number | null;
      readonly salesCreditsId?: string | null;
      readonly investorFirmName: string;
      readonly orderBookBankInvestorFirmName?: string | null;
      readonly investorFirmCmgEntityKey: string;
      readonly designationShares?: number | null;
      readonly firmId?: string | null;
      readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
      readonly allocationShares?: number | null;
      readonly bankInvestorKey?: string | null;
      readonly orderBookBankInvestorKey?: string | null;
      readonly statusDetails?: string | null;
      readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
      readonly status: Types.SalesCreditReleaseStatus;
    }>;
  };
};

export type SalesCredits_SalesCreditGridPartsFragment = {
  readonly __typename?: 'SalesCreditGrid';
  readonly id: string;
  readonly settlementCurrency: string;
  readonly sellingConcession?: number | null;
  readonly salesCredits: ReadonlyArray<{
    readonly __typename?: 'SalesCreditItem';
    readonly id: string;
    readonly salesCredits?: number | null;
    readonly salesCreditsReleased?: number | null;
    readonly salesCreditsManual?: number | null;
    readonly salesCreditsId?: string | null;
    readonly investorFirmName: string;
    readonly orderBookBankInvestorFirmName?: string | null;
    readonly investorFirmCmgEntityKey: string;
    readonly designationShares?: number | null;
    readonly firmId?: string | null;
    readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
    readonly allocationShares?: number | null;
    readonly bankInvestorKey?: string | null;
    readonly orderBookBankInvestorKey?: string | null;
    readonly statusDetails?: string | null;
    readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
    readonly status: Types.SalesCreditReleaseStatus;
  }>;
};

export type SalesCredits_SalesCreditItemPartsFragment = {
  readonly __typename?: 'SalesCreditItem';
  readonly id: string;
  readonly salesCredits?: number | null;
  readonly salesCreditsReleased?: number | null;
  readonly salesCreditsManual?: number | null;
  readonly salesCreditsId?: string | null;
  readonly investorFirmName: string;
  readonly orderBookBankInvestorFirmName?: string | null;
  readonly investorFirmCmgEntityKey: string;
  readonly designationShares?: number | null;
  readonly firmId?: string | null;
  readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
  readonly allocationShares?: number | null;
  readonly bankInvestorKey?: string | null;
  readonly orderBookBankInvestorKey?: string | null;
  readonly statusDetails?: string | null;
  readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
  readonly status: Types.SalesCreditReleaseStatus;
};

export type SalesCredits_SalesCreditsDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SalesCredits_SalesCreditsDetailsQuery = {
  readonly __typename?: 'Query';
  readonly salesCredits: {
    readonly __typename?: 'SalesCreditGrid';
    readonly id: string;
    readonly settlementCurrency: string;
    readonly sellingConcession?: number | null;
    readonly salesCredits: ReadonlyArray<{
      readonly __typename?: 'SalesCreditItem';
      readonly id: string;
      readonly salesCredits?: number | null;
      readonly salesCreditsReleased?: number | null;
      readonly salesCreditsManual?: number | null;
      readonly salesCreditsId?: string | null;
      readonly investorFirmName: string;
      readonly orderBookBankInvestorFirmName?: string | null;
      readonly investorFirmCmgEntityKey: string;
      readonly designationShares?: number | null;
      readonly firmId?: string | null;
      readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
      readonly allocationShares?: number | null;
      readonly bankInvestorKey?: string | null;
      readonly orderBookBankInvestorKey?: string | null;
      readonly statusDetails?: string | null;
      readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
      readonly status: Types.SalesCreditReleaseStatus;
    }>;
  };
};

export type SalesCredits_UpdateSalesCreditsStatusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.UpdateSalesCreditsInput;
}>;

export type SalesCredits_UpdateSalesCreditsStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSalesCreditsStatus: {
    readonly __typename?: 'SalesCreditGrid';
    readonly id: string;
    readonly settlementCurrency: string;
    readonly sellingConcession?: number | null;
    readonly salesCredits: ReadonlyArray<{
      readonly __typename?: 'SalesCreditItem';
      readonly id: string;
      readonly salesCredits?: number | null;
      readonly salesCreditsReleased?: number | null;
      readonly salesCreditsManual?: number | null;
      readonly salesCreditsId?: string | null;
      readonly investorFirmName: string;
      readonly orderBookBankInvestorFirmName?: string | null;
      readonly investorFirmCmgEntityKey: string;
      readonly designationShares?: number | null;
      readonly firmId?: string | null;
      readonly allowedStatusTransitions: ReadonlyArray<Types.GridTransitionSalesCreditReleaseStatus>;
      readonly allocationShares?: number | null;
      readonly bankInvestorKey?: string | null;
      readonly orderBookBankInvestorKey?: string | null;
      readonly statusDetails?: string | null;
      readonly warnings: ReadonlyArray<Types.SalesCreditWarning>;
      readonly status: Types.SalesCreditReleaseStatus;
    }>;
  };
};

export const SalesCredits_SalesCreditItemPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SalesCredits_SalesCreditGridPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SalesCredits_ReleaseSalesCreditsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SalesCredits_ReleaseSalesCredits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'investorFirmCmgEntityKeys' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseSalesCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorFirmCmgEntityKeys' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'investorFirmCmgEntityKeys' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SalesCredits_ReleaseSalesCreditsMutationFn = Apollo.MutationFunction<
  SalesCredits_ReleaseSalesCreditsMutation,
  SalesCredits_ReleaseSalesCreditsMutationVariables
>;

/**
 * __useSalesCredits_ReleaseSalesCreditsMutation__
 *
 * To run a mutation, you first call `useSalesCredits_ReleaseSalesCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_ReleaseSalesCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesCreditsReleaseSalesCreditsMutation, { data, loading, error }] = useSalesCredits_ReleaseSalesCreditsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      investorFirmCmgEntityKeys: // value for 'investorFirmCmgEntityKeys'
 *   },
 * });
 */
export function useSalesCredits_ReleaseSalesCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesCredits_ReleaseSalesCreditsMutation,
    SalesCredits_ReleaseSalesCreditsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesCredits_ReleaseSalesCreditsMutation,
    SalesCredits_ReleaseSalesCreditsMutationVariables
  >(SalesCredits_ReleaseSalesCreditsDocument, options);
}
export type SalesCredits_ReleaseSalesCreditsMutationHookResult = ReturnType<
  typeof useSalesCredits_ReleaseSalesCreditsMutation
>;
export type SalesCredits_ReleaseSalesCreditsMutationResult =
  Apollo.MutationResult<SalesCredits_ReleaseSalesCreditsMutation>;
export type SalesCredits_ReleaseSalesCreditsMutationOptions = Apollo.BaseMutationOptions<
  SalesCredits_ReleaseSalesCreditsMutation,
  SalesCredits_ReleaseSalesCreditsMutationVariables
>;
export const SalesCredits_SalesCreditsDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditsDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesCredits_SalesCreditsDetailsQuery__
 *
 * To run a query within a React component, call `useSalesCredits_SalesCreditsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_SalesCreditsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCredits_SalesCreditsDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSalesCredits_SalesCreditsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesCredits_SalesCreditsDetailsQuery,
    SalesCredits_SalesCreditsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesCredits_SalesCreditsDetailsQuery,
    SalesCredits_SalesCreditsDetailsQueryVariables
  >(SalesCredits_SalesCreditsDetailsDocument, options);
}
export function useSalesCredits_SalesCreditsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCredits_SalesCreditsDetailsQuery,
    SalesCredits_SalesCreditsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesCredits_SalesCreditsDetailsQuery,
    SalesCredits_SalesCreditsDetailsQueryVariables
  >(SalesCredits_SalesCreditsDetailsDocument, options);
}
export type SalesCredits_SalesCreditsDetailsQueryHookResult = ReturnType<
  typeof useSalesCredits_SalesCreditsDetailsQuery
>;
export type SalesCredits_SalesCreditsDetailsLazyQueryHookResult = ReturnType<
  typeof useSalesCredits_SalesCreditsDetailsLazyQuery
>;
export type SalesCredits_SalesCreditsDetailsQueryResult = Apollo.QueryResult<
  SalesCredits_SalesCreditsDetailsQuery,
  SalesCredits_SalesCreditsDetailsQueryVariables
>;
export const SalesCredits_UpdateSalesCreditsStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SalesCredits_UpdateSalesCreditsStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSalesCreditsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalesCreditsStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCredits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_SalesCreditItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesCreditItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCredits' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookBankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SalesCredits_UpdateSalesCreditsStatusMutationFn = Apollo.MutationFunction<
  SalesCredits_UpdateSalesCreditsStatusMutation,
  SalesCredits_UpdateSalesCreditsStatusMutationVariables
>;

/**
 * __useSalesCredits_UpdateSalesCreditsStatusMutation__
 *
 * To run a mutation, you first call `useSalesCredits_UpdateSalesCreditsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_UpdateSalesCreditsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesCreditsUpdateSalesCreditsStatusMutation, { data, loading, error }] = useSalesCredits_UpdateSalesCreditsStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSalesCredits_UpdateSalesCreditsStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesCredits_UpdateSalesCreditsStatusMutation,
    SalesCredits_UpdateSalesCreditsStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesCredits_UpdateSalesCreditsStatusMutation,
    SalesCredits_UpdateSalesCreditsStatusMutationVariables
  >(SalesCredits_UpdateSalesCreditsStatusDocument, options);
}
export type SalesCredits_UpdateSalesCreditsStatusMutationHookResult = ReturnType<
  typeof useSalesCredits_UpdateSalesCreditsStatusMutation
>;
export type SalesCredits_UpdateSalesCreditsStatusMutationResult =
  Apollo.MutationResult<SalesCredits_UpdateSalesCreditsStatusMutation>;
export type SalesCredits_UpdateSalesCreditsStatusMutationOptions = Apollo.BaseMutationOptions<
  SalesCredits_UpdateSalesCreditsStatusMutation,
  SalesCredits_UpdateSalesCreditsStatusMutationVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeSalesCredits_SalesCreditsDetailsMockResponse = makeMockResponse<
  SalesCredits_SalesCreditsDetailsQueryVariables,
  SalesCredits_SalesCreditsDetailsQuery
>(SalesCredits_SalesCreditsDetailsDocument);
