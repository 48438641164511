import React from 'react';

import {
  SCreateNewOfferingBannerContent,
  SCreateNewOfferingBannerContentContainer,
  SCreateNewOfferingBannerTitle,
  StyledBanner,
} from './OfferingSettlementAgentAuthoringBanner.styles';

const OfferingSettlementAgentAuthoringBanner = () => (
  <StyledBanner variant="information">
    <SCreateNewOfferingBannerContentContainer>
      <SCreateNewOfferingBannerTitle>
        Offerings should be authored by the Settlement Agent.
      </SCreateNewOfferingBannerTitle>
      <SCreateNewOfferingBannerContent>
        The Settlement Agent responsibility can only be assigned to the offering author.
      </SCreateNewOfferingBannerContent>
    </SCreateNewOfferingBannerContentContainer>
  </StyledBanner>
);

export default OfferingSettlementAgentAuthoringBanner;
