import styled from 'styled-components/macro';

export const SAllocationContainer = styled.div`
  margin-top: 32px;
`;

export const SAllocationHeader = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.medium};
  margin: 8px 8px 8px 0px;
`;

export const SAllocationRow = styled.div`
  padding: 8px 0;
  display: flex;
  height: 34px;
`;

export const SAllocationTableHeader = styled(SAllocationRow)`
  color: ${({ theme }) => theme.text.color.light};
`;

export const SColumn = styled.div<{ align: 'left' | 'center' | 'right' }>`
  padding-right: 8px;
  text-align: ${props => props.align};
  align-self: center;
`;

export const SSharesColumn = styled(SColumn)`
  flex: 0 0 160px;
`;
