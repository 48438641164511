import { NumericInputField } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { FundIoisFormValues } from '../../types';
import FormSectionHeader from '../form-section-header/FormSectionHeader';
import { SAllocationContainer, SAllocationRow, SSharesColumn } from './Allocation.styles';

export type Props = {
  submitting: boolean;
};

const Allocation: React.FC<Props> = ({ submitting }) => {
  const { values, setFieldValue } = useFormikContext<FundIoisFormValues>();
  const { allocation } = values;

  React.useEffect(() => {
    if (!allocation) {
      setFieldValue('allocation.shares', null);
    }
  }, [allocation, setFieldValue]);

  return (
    <SAllocationContainer>
      <FormSectionHeader title="Allocation" isAddEnabled={false} />
      <SAllocationRow>
        <SSharesColumn align="left">
          <NumericInputField
            label="# Shares"
            name="allocation.shares"
            fullWidth
            precision={0}
            disabled={submitting}
          />
        </SSharesColumn>
      </SAllocationRow>
    </SAllocationContainer>
  );
};

export default Allocation;
