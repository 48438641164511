import React from 'react';

import SideBarHeader from '../side-bar-header/SideBarHeader';
import { SHeaderContent, SRightSideGroup, SSubtitle, STitle } from './SectionHeader.styles';

type Props = {
  title: React.ReactNode;
  subtitle?: string;
};

const SectionHeader: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <SideBarHeader
      title={
        <React.Fragment>
          <STitle highlight={!!subtitle}>{title}</STitle>
          <SSubtitle>{subtitle}</SSubtitle>
        </React.Fragment>
      }
    >
      <SHeaderContent>
        <SRightSideGroup>{children}</SRightSideGroup>
      </SHeaderContent>
    </SideBarHeader>
  );
};

export default SectionHeader;
