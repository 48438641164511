import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import Banner from '../../../../common/components/indicators/banner/Banner';
import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import { OrderBookState } from '../../../../graphql';
import InternalDemandSummary from '../../components/internal-demand-summary/InternalDemandSummary';
import { NoRelationshipOrderbookLegalBanner } from '../../SNoRelationshipBanners';
import isManagerRelationshipError from '../../utils/isManagerRelationshipError';
import AllocationDiscrepancyModal from './components/allocation-discrepancy-modal/AllocationDiscrepancyModal';
import ManageBuySideIndication from './components/manage-indication/ManageBuySideIndication';
import { NoticesAndDisclaimers } from './components/notices-and-disclaimers/NoticesAndDisclaimers';
import { useIndicationActivityBuySideContext } from './context/IndicationActivityBuySideContext';

/**
 * Main entry point for the Buy Side Indication Activity Side Panel
 * route.  Determines whether to show the view for managing an
 * existing indication or creating an indication.
 */
const IndicationActivityBuySide: React.FC = () => {
  const {
    basicPublishedOffering,
    loading,
    error,
    complianceWarning: { hasComplianceWarning },
    offeringId,
    access,
  } = useIndicationActivityBuySideContext();

  const hasMDLIOIPermissions = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const indicationExists = !!basicPublishedOffering?.myInstitutionalIndication;
  const isInternalSummaryVisible =
    !!basicPublishedOffering && hasMDLIOIPermissions && !hasComplianceWarning;
  const orderBookIsClosed =
    basicPublishedOffering?.publicOrderBook?.state === OrderBookState.Closed;

  const { hasRelationshipWithActiveBookrunner, hasRelationshipWithSyndicate } =
    basicPublishedOffering?.investorRelationship ?? {};

  // Account doesn't have a relationship at all
  const hasNoRelationship = !hasRelationshipWithSyndicate && !hasRelationshipWithActiveBookrunner;
  // Account doesn't have a relationship with an active bookrunner
  const hasRelationWithNonActiveBookrunner =
    hasRelationshipWithSyndicate && !hasRelationshipWithActiveBookrunner;
  // Indications should be enabled If the account has a relationship with an ACTIVE bookrunner
  const shouldAllowIndications =
    hasRelationshipWithSyndicate && hasRelationshipWithActiveBookrunner;

  const isOfferingExecutingOnPlatform =
    basicPublishedOffering?.orderEntryProfile.isExecutingOnPlatform;

  if (loading) {
    return <Loading />;
  }

  if (!access.canReadOfferingAndIndication) {
    return <NotAccessible subject="Indication" />;
  }

  // Basically we have two scenarios, when a user have no relationship with an active bookrunner and with the sellside in both we want to show the
  // warning banner and hide the error.
  const isNoRelationshipBannerVisible =
    (basicPublishedOffering && !error && hasNoRelationship) ||
    (error && isManagerRelationshipError(error));
  const isBannerVisible = isNoRelationshipBannerVisible || hasRelationWithNonActiveBookrunner;

  const displayDisclaimerBanner =
    isOfferingExecutingOnPlatform && (indicationExists || !orderBookIsClosed);

  return (
    <FlexLayout>
      <AllocationDiscrepancyModal />
      {!isNoRelationshipBannerVisible && error && <ServerErrorsBanner error={error} />}

      {orderBookIsClosed && (
        <Banner variant="information">
          {indicationExists
            ? 'The order book is closed. Please contact your sell side representative if changes are needed.'
            : 'The order book is closed. Please contact your sell side representative if you have interest in participating.'}
        </Banner>
      )}

      {isInternalSummaryVisible && <InternalDemandSummary offeringId={offeringId} />}

      {basicPublishedOffering && shouldAllowIndications && (
        <ManageBuySideIndication
          editable={!orderBookIsClosed}
          isOrderBookOpen={!orderBookIsClosed}
        />
      )}

      {displayDisclaimerBanner && (
        <NoticesAndDisclaimers disclaimers={basicPublishedOffering?.disclaimers} />
      )}

      {isBannerVisible && <NoRelationshipOrderbookLegalBanner />}
    </FlexLayout>
  );
};

export default React.memo(IndicationActivityBuySide);
