import React from 'react';

import Banner from '../../../../../../common/components/indicators/banner/Banner';
import { SBannerContent } from './IndicationHeader.styles';

const IndicationHeader: React.FC = () => {
  return (
    <div>
      <Banner variant="warning">
        <Banner.Title>This offering is not being managed on the XC platform</Banner.Title>
        <SBannerContent>
          Your indication will not be communicated to the underwriters through the XC platform. You
          can still log your indication on XC for tracking and reporting purposes.
        </SBannerContent>
      </Banner>
    </div>
  );
};

export default IndicationHeader;
