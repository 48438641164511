import { useHistory } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { FinalSettlement_EligibleManagerPartsFragment } from '../graphql';
import { managersToRoleGroupedEntries } from './UnderwritersNavigation.model';
import {
  SManager,
  SManagerList,
  SManagerName,
  SManagerNameContainer,
  SPanel,
  SRoleHeader,
} from './UnderwritersNavigation.styles';

export type Props = {
  offeringId: string;
  managerId: string;
  managers: FinalSettlement_EligibleManagerPartsFragment[];
};

const UnderwritersNavigation: React.FC<Props> = ({ offeringId, managerId, managers }) => {
  const history = useHistory();
  const managersGroupedByRole = managersToRoleGroupedEntries(managers);

  return (
    <SPanel>
      {managersGroupedByRole.map(([role, managersOfRole]) => (
        <div key={role}>
          <SRoleHeader>{managerRoleLabels[role]}</SRoleHeader>
          <SManagerList>
            {managersOfRole.map(manager => {
              return (
                <SManager
                  key={manager.name}
                  isActive={manager.id === managerId}
                  onClick={() => {
                    history.push(
                      routeFactory.finalSettlementSettlementLetterForManager.getUrlPath({
                        offeringId,
                        managerId: manager.id,
                      })
                    );
                  }}
                >
                  <SManagerNameContainer>
                    <SManagerName isActive={manager.id === managerId}>{manager.name}</SManagerName>
                  </SManagerNameContainer>
                </SManager>
              );
            })}
          </SManagerList>
        </div>
      ))}
    </SPanel>
  );
};

export default UnderwritersNavigation;
