/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_AssignRetailDemandMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
  payload: Types.AssignRetailDemandInput;
}>;

export type OrderBook_AssignRetailDemandMutation = {
  readonly __typename?: 'Mutation';
  readonly assignRetailDemand: {
    readonly __typename?: 'TotalRetailSummaryItemDemand';
    readonly id: string;
    readonly shareQuantity?: number | null;
    readonly roundLots?: number | null;
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt?: string | null;
    readonly isSubmitted: boolean;
  };
};

export type OrderBook_AssignRetailRetentionMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
  payload: Types.AssignRetailRetentionInput;
}>;

export type OrderBook_AssignRetailRetentionMutation = {
  readonly __typename?: 'Mutation';
  readonly assignRetailRetention: {
    readonly __typename?: 'TotalRetailSummaryItemRetention';
    readonly id: string;
    readonly shareQuantity?: number | null;
    readonly isReleased: boolean;
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt?: string | null;
  };
};

export const OrderBook_AssignRetailDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_AssignRetailDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignRetailDemandInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignRetailDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemDemand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubmitted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_AssignRetailDemandMutationFn = Apollo.MutationFunction<
  OrderBook_AssignRetailDemandMutation,
  OrderBook_AssignRetailDemandMutationVariables
>;

/**
 * __useOrderBook_AssignRetailDemandMutation__
 *
 * To run a mutation, you first call `useOrderBook_AssignRetailDemandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_AssignRetailDemandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookAssignRetailDemandMutation, { data, loading, error }] = useOrderBook_AssignRetailDemandMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_AssignRetailDemandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_AssignRetailDemandMutation,
    OrderBook_AssignRetailDemandMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_AssignRetailDemandMutation,
    OrderBook_AssignRetailDemandMutationVariables
  >(OrderBook_AssignRetailDemandDocument, options);
}
export type OrderBook_AssignRetailDemandMutationHookResult = ReturnType<
  typeof useOrderBook_AssignRetailDemandMutation
>;
export type OrderBook_AssignRetailDemandMutationResult =
  Apollo.MutationResult<OrderBook_AssignRetailDemandMutation>;
export type OrderBook_AssignRetailDemandMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_AssignRetailDemandMutation,
  OrderBook_AssignRetailDemandMutationVariables
>;
export const OrderBook_AssignRetailRetentionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_AssignRetailRetention' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssignRetailRetentionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignRetailRetention' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemRetention' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_AssignRetailRetentionMutationFn = Apollo.MutationFunction<
  OrderBook_AssignRetailRetentionMutation,
  OrderBook_AssignRetailRetentionMutationVariables
>;

/**
 * __useOrderBook_AssignRetailRetentionMutation__
 *
 * To run a mutation, you first call `useOrderBook_AssignRetailRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_AssignRetailRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookAssignRetailRetentionMutation, { data, loading, error }] = useOrderBook_AssignRetailRetentionMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_AssignRetailRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_AssignRetailRetentionMutation,
    OrderBook_AssignRetailRetentionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_AssignRetailRetentionMutation,
    OrderBook_AssignRetailRetentionMutationVariables
  >(OrderBook_AssignRetailRetentionDocument, options);
}
export type OrderBook_AssignRetailRetentionMutationHookResult = ReturnType<
  typeof useOrderBook_AssignRetailRetentionMutation
>;
export type OrderBook_AssignRetailRetentionMutationResult =
  Apollo.MutationResult<OrderBook_AssignRetailRetentionMutation>;
export type OrderBook_AssignRetailRetentionMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_AssignRetailRetentionMutation,
  OrderBook_AssignRetailRetentionMutationVariables
>;
