import { FormLabel, TextInput } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SInvestorFilter = styled.div`
  width: 280px;
  margin: 0 20px;
`;

export type Props = {
  investorName: string;
  onChange: (value: string) => void;
};

const InvestorFilter: React.FC<Props> = ({ onChange, investorName }) => {
  return (
    <SInvestorFilter>
      <FormLabel>Investor</FormLabel>
      <TextInput
        value={investorName}
        placeholder="Filter by Investor Name..."
        onChange={onChange}
      />
    </SInvestorFilter>
  );
};

export default InvestorFilter;
