import { Panel, SuccessButton } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../../common/util/routeFactory';
import RoadshowsSubNavigation from '../../common/roadshows-sub-navigation/RoadshowsSubNavigation';
import Filters from '../filters/Filters';
import RoadshowsListDataGrid from '../grid/RoadshowsListDataGrid';
import { useRoadshowPaginatedListQuery } from './hooks/useRoadshowPaginatedListQuery';

const CoordinatorRoadshowOverview: React.FC = () => {
  const { data, loading, error, setFilter, setPagination } = useRoadshowPaginatedListQuery();
  const { push } = useHistory();

  return (
    <Panel fillViewport testId="roadshows / overviews / list of roadshows">
      <RoadshowsSubNavigation>
        <SuccessButton
          testId="open a new roadshow dialog button"
          onClick={() => push(routeFactory.roadshowCreate.getUrlPath())}
        >
          Create New Roadshow
        </SuccessButton>
      </RoadshowsSubNavigation>

      <Filters setFilter={setFilter} />

      {error && <ServerErrorsBanner error={error} />}

      <RoadshowsListDataGrid rows={data} isLoading={loading} setPagination={setPagination} />
    </Panel>
  );
};

export default CoordinatorRoadshowOverview;
