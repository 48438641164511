import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_CancelMeetingMutation,
  Roadshows_MeetingDocument,
  useRoadshows_CancelMeetingMutation,
} from '../graphql';

export type CancelMeetingMutation = Roadshows_CancelMeetingMutation;

export const refetchQueries = () => {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument);
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetingDetails!, refetchMeetings!];
};

export const useCancelMeetingMutation = () => {
  return useRoadshows_CancelMeetingMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
