import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Paper, Stack } from '@cmg/design-system';
import React from 'react';

import { NoRecipients } from '../../../../common/components/lists/recipient-list/NoRecipients';
import RecipientList from '../../../../common/components/lists/recipient-list/RecipientList';
import { Recipient } from '../../../../common/components/lists/recipient-list/RecipientList.model';
import routeFactory from '../../../../common/util/routeFactory';
import { ManagerRole } from '../../../../graphql';
import { stepIds } from '../../../offering-setup/constants';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly testId?: string;
  readonly recipients: Recipient<ManagerRole>[];
}>;

export const FilingLayout: React.FC<Props> = ({ offeringId, testId, recipients, children }) => {
  const canManageOffering = useCheckPermissions([permissionsByEntity.Offering.FULL], true);

  return (
    <Stack elevation={0} component={Paper} direction="row" flex={1}>
      <RecipientList
        title="Email Recipients"
        recipients={recipients}
        noRecipientsComponent={
          <NoRecipients
            text={
              canManageOffering
                ? 'Add managers on the offering to send email notifications.'
                : 'No managers added on the offering. Please contact the Offering Author.'
            }
            showLink={canManageOffering}
            linkUrl={routeFactory.offeringSetup.getUrlPath({
              stepId: stepIds.MANAGERS,
              offeringId,
            })}
            linkText="Offering Details"
          />
        }
      />
      <Stack data-test-id={testId} flex={1}>
        {children}
      </Stack>
    </Stack>
  );
};
