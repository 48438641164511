import styled from 'styled-components/macro';

export const STitle = styled.div`
  height: 48px;
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 16px;
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SContent = styled.div`
  margin: 0 16px;
`;

export const SWrapper = styled.div`
  margin: 16px;
`;
