import { LinkButton, ODSTable as Table } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { Roadshows_DealTeamMemberPartsFragment } from '../../../../../common/graphql';
import { DealTeamMember, defaultDealTeamMember } from '../CreateDealTeamMembersModal.model';
import { STableScroll } from './CreateNewMemberSection.styles';
import ExistingDealTeamMemberRow from './existing-deal-team-member-row/ExistingDealTeamMemberRow';
import NewDealTeamMemberRow from './new-deal-team-member-row/NewDealTeamMemberRow';

export type Props = {
  members: DealTeamMember[];
  existingMembers: readonly Roadshows_DealTeamMemberPartsFragment[];
  onAddMember: (value: DealTeamMember) => void;
  onRemoveMember: (index: number) => void;
  departments: readonly string[];
};

export const CreateNewMemberSection: React.FC<Props> = ({
  members,
  existingMembers,
  onAddMember,
  onRemoveMember,
  departments,
}) => {
  const handleAddMember = () => {
    onAddMember(defaultDealTeamMember);
  };

  return (
    <Panel>
      <Panel.Header title="Create New Member">
        <LinkButton
          testId="add member"
          iconLeft={{ name: 'plus' }}
          inline
          onClick={handleAddMember}
        >
          Add Member
        </LinkButton>
      </Panel.Header>
      <Panel.Content>
        <STableScroll>
          <Table gridTemplateColumns="2fr 1fr 1fr 1.5fr 1.5fr 32px">
            <Table.Row>
              <Table.TableHeaderCell>
                Name <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell>Mobile Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>Office Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>
                Email <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell>Department</Table.TableHeaderCell>
              <Table.TableHeaderCell />
            </Table.Row>

            {members.map((member, index) => (
              <NewDealTeamMemberRow
                key={index}
                index={index}
                onDelete={() => onRemoveMember(index)}
                departments={departments}
              />
            ))}

            {existingMembers.map(member => (
              <ExistingDealTeamMemberRow key={member.id} member={member} />
            ))}
          </Table>
        </STableScroll>
      </Panel.Content>
    </Panel>
  );
};

export default CreateNewMemberSection;
