import React from 'react';

import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import SidePanel from '../../../../../../../common/components/layout/side-panel/SidePanel';
import Spinner from '../../../../../../../common/components/overlays/spinner/Spinner';
import { EventStatus } from '../../../../../../../graphql';
import EventNavigationStep from '../../../../common/event-navigation-step/EventNavigationStep';
import MeetingStatus from './meeting-status/MeetingStatus';
import { MeetingNavigationSteps } from './MeetingSidePanel.model';
import { SHeader, SLabel, SNavigation } from './MeetingSidePanel.styles';

export type Props = {
  onClose: () => void;
  onNavigationChange?: (activeStepId: MeetingNavigationSteps) => void;
  title: string;
  activeNavigationStepId: MeetingNavigationSteps;
  eventStatus?: EventStatus;
  loading?: boolean;
  testId?: string;
};

const MeetingSidePanel: React.FC<Props> = ({
  onClose,
  onNavigationChange,
  title,
  children,
  activeNavigationStepId,
  eventStatus,
  loading = false,
  testId,
}) => (
  <SidePanel isOpen onClose={onClose} testId={testId}>
    <FlexLayout expand direction="row">
      <SNavigation>
        <SHeader>
          <SLabel>{title}</SLabel>
          <MeetingStatus status={eventStatus} />
        </SHeader>

        <div>
          <EventNavigationStep
            label="Meeting Details"
            id={MeetingNavigationSteps.DETAILS}
            icon="file-alt"
            activeStep={activeNavigationStepId}
            onChangeStep={onNavigationChange}
          />
          <EventNavigationStep
            label="Management & Deal Team"
            id={MeetingNavigationSteps.TEAMS}
            icon="user-friends"
            activeStep={activeNavigationStepId}
            onChangeStep={onNavigationChange}
          />
          <EventNavigationStep
            label="Investors"
            id={MeetingNavigationSteps.INVESTORS}
            icon="briefcase"
            activeStep={activeNavigationStepId}
            onChangeStep={onNavigationChange}
          />
        </div>
      </SNavigation>
      {loading ? <Spinner show fullHeight variant="DARK" /> : children}
    </FlexLayout>
  </SidePanel>
);

export default MeetingSidePanel;
