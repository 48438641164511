import { useAuth } from '@cmg/auth';
import { ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import EmptyDistributionListWarning from '../../../../common/components/alerts/empty-distribution-list-warning/EmptyDistributionListWarning';
import SelectRecipientsDialog from '../../../../common/components/dialogs/select-recipients-dialog/SelectRecipientsDialog';
import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { RecipientsInfoMessage } from '../../common/content/recipients-info-message/RecipientsInfoMessage';
import StockSymbolWarningBanner from '../../common/content/stock-symbol-warning/StockSymbolWarningBanner';
import CancelModal from '../../common/dialogs/cancel-amend-dialog/CancelModal';
import { BCCSenderSection } from '../../common/dialogs/send-notification-dialog/BCCSenderSection';
import { FilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/FilingConfirmationModal';
import { useSubmitFilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/hooks/useSubmitFilingConfirmationModal';
import { filingNames, getFilingFormLabel } from '../../common/filing-name-utils';
import RegulatoryFilingsFormHeader from '../../common/header/RegulatoryFilingsFormHeader';
import { FilingLayout } from '../../common/layouts/FilingLayout';
import { getRecipientsWithDefaultStatus } from '../../common/recipient-utils';
import useContactSupportDialog from '../../hooks/useContactSupportDialog';
import { useFilingRecipientsDialog } from '../../hooks/useFilingRecipientsDialog';
import { getRecipientsFromRecipientFragments } from '../../hooks/useFilingRecipientsDialog.model';
import { useRolodexCommonFilingQuery } from '../../hooks/useRolodexCommonFilingQuery';
import { SFormContent } from '../../RegulatoryFilingsRoute.styles';
import USDTNFilingForm from './form/USDTNFilingForm';
import {
  createInitialValues,
  formSchema,
  mapFormValuesToFiling,
  USDTNFilingFormValues,
} from './form/USDTNFilingForm.model';
import { useRegulatoryFilings_UsdtnFilingDetailQuery } from './graphql';
import { useSubmitUSDTNFilingMutation } from './hooks/useSubmitUSDTNFilingMutation';

export type Props = RouteComponentProps<{ offeringId: UUID; filingId?: UUID }>;

const USDTNFilingFormRoute: React.FC<Props> = ({ match }) => {
  const { offeringId, filingId } = match.params;

  const { oidcUserCmgEntityKey } = useAuth();
  const [shouldAddSenderToBcc, setShouldAddSenderToBcc] = React.useState(false);

  const {
    data: formData,
    error: formError,
    loading: isFormLoading,
  } = useRolodexCommonFilingQuery({
    offeringId,
    cmgEntityKey: oidcUserCmgEntityKey!,
  });
  const {
    data: detailData,
    error: detailError,
    loading: isDetailLoading,
  } = useRegulatoryFilings_UsdtnFilingDetailQuery({
    variables: { filingId: filingId!, offeringId },
    skip: !filingId,
    fetchPolicy: 'cache-and-network',
  });
  const [submitUSDTNFiling, { loading: isSubmitting, error: submitError }] =
    useSubmitUSDTNFilingMutation();

  const filingData = detailData?.offering.regulatoryFilings.USDTNFiling;

  const { push } = useHistory();
  const [isCancelModalOpen, setCancelModalOpen] = React.useState<boolean>(false);

  const { recipients, notificationDialogRecipients } = React.useMemo(() => {
    return {
      recipients: getRecipientsWithDefaultStatus(formData?.recipients),
      notificationDialogRecipients: getRecipientsFromRecipientFragments(formData?.recipients),
    };
  }, [formData?.recipients]);

  const {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen: isRecipientsDialogOpen,
    open: openRecipientsDialog,
    close: closeRecipientsDialog,
  } = useFilingRecipientsDialog({
    recipients: notificationDialogRecipients,
  });

  const { handleContactSupport } = useContactSupportDialog({
    recipients,
    onOpen: closeRecipientsDialog,
    onClose: useCallback(
      () => (isRecipientsDialogOpen ? openRecipientsDialog() : undefined),
      [isRecipientsDialogOpen, openRecipientsDialog]
    ),
  });

  const formik = useFormik<USDTNFilingFormValues>({
    initialValues: createInitialValues({
      underwriter: formData?.underwriter,
      filing: filingData,
      lastCommonValues: formData?.filingLastCommonValues,
      offeringDetail: formData?.filingOfferingDetail,
    }),
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: async values => {
      closeRecipientsDialog();

      try {
        const { data: submitData } = await submitUSDTNFiling({
          variables: {
            offeringId,
            input: mapFormValuesToFiling(
              values,
              selectedEnabledRecipientKeys,
              shouldAddSenderToBcc
            ),
          },
        });
        const filingId = submitData?.submitUSDTNFiling.id;
        ToastManager.success('Successfully submitted USDTN Form');
        filingId &&
          push(
            routeFactory.regulatoryFilingsUSDTNFilingDetail.getUrlPath({
              offeringId,
              filingId,
            })
          );
      } catch {
        ToastManager.error('Failed to submit USDTN Form. Please try again');
      }
    },
  });

  const { submitForm, isValid } = formik;

  const openCancelModal = () => {
    setCancelModalOpen(true);
  };

  const [confirmationModalProps, { openConfirmationModal }] = useSubmitFilingConfirmationModal({
    filingName: filingNames.USDTN.full,
    isAmending: !!filingId,
    isValid,
    onSubmit: async () => {
      if (notificationDialogRecipients.length > 0) {
        openRecipientsDialog();
      } else {
        await submitForm();
      }
    },
  });

  const handleAmendCancel = () => {
    setCancelModalOpen(false);
    push(
      routeFactory.regulatoryFilingsUSDTNFilingDetail.getUrlPath({
        offeringId,
        filingId: filingId!,
      })
    );
  };

  const handleDismiss = () => {
    setCancelModalOpen(false);
  };

  if (isFormLoading || isSubmitting || isDetailLoading) {
    return <Loading />;
  }

  const error = formError || detailError;

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const isStockSymbolWarningVisible = !formData?.filingOfferingDetail.pricingInstrumentSymbol;
  const stockSymbols = formData?.filingOfferingDetail.stockSymbols ?? [];

  return (
    <FilingLayout
      testId={xcSelectors.usdtnFilingFormScreen.testId}
      recipients={recipients}
      offeringId={offeringId}
    >
      <FormikProvider value={formik}>
        <RegulatoryFilingsFormHeader
          offeringId={offeringId}
          title={getFilingFormLabel('USDTN')}
          isAmended={!!filingId}
          onSubmitFiling={openConfirmationModal}
          onCancel={openCancelModal}
        />
        <SFormContent>
          <EmptyDistributionListWarning
            withMargin
            recipients={recipients}
            onContactSupport={handleContactSupport}
          />
          {isStockSymbolWarningVisible && <StockSymbolWarningBanner offeringId={offeringId} />}
          {submitError && <ServerErrorsBanner error={submitError} />}
          <USDTNFilingForm stockSymbols={stockSymbols} />
        </SFormContent>
        <FilingConfirmationModal {...confirmationModalProps} />
        {isCancelModalOpen && (
          <CancelModal onCancel={handleDismiss} onConfirm={handleAmendCancel} />
        )}
        <SelectRecipientsDialog
          open={isRecipientsDialogOpen}
          recipients={selectableRecipients}
          onSelectionChange={handleRecipientSelectionChange}
          onCancel={() => closeRecipientsDialog()}
          onSubmit={submitForm}
          submitButtonLabel="Confirm And Submit Filing"
          message={<RecipientsInfoMessage />}
          onContactSupport={handleContactSupport}
          getManagerRoleLabel={role => managerRoleLabels[role]}
          ccmyfirm={
            <BCCSenderSection
              offeringId={offeringId}
              canSendNotification
              onChange={() => setShouldAddSenderToBcc(!shouldAddSenderToBcc)}
              shouldAddSenderToBcc={shouldAddSenderToBcc}
              onContactSupport={handleContactSupport}
            />
          }
        />
      </FormikProvider>
    </FilingLayout>
  );
};

export default USDTNFilingFormRoute;
