import { numericUtil } from '@cmg/common';
import { InputAdornment, Table, TableCell, TableContainer, TableRow } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { VFC } from 'react';

import { FormikNumericField } from '../../../common/components/form/formik-numeric-field/FormikNumericField';
import { OperationType } from '../../../common/components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { DefaultRecipientValues } from '../../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { SyndicateRecipientsUserDataFormFields } from '../../../common/components/recipients-user-data-form-field/SyndicateRecipientsUserDataFormFields';
import { BillingAndDeliveryPaymentFragment } from '../../../common/graphql';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { ManagerBdRecipientUserData, Values } from '../ManagerBdWireForm.model';
import { RecipientsTableHead } from './RecipientsTableHead';

export type Props = Readonly<{
  managers: SyndicateWireManager[];
  operationType: OperationType;
  billAndDeliverData: ReadonlyArray<BillingAndDeliveryPaymentFragment>;
}>;

const defaultRecipientValues: DefaultRecipientValues<ManagerBdRecipientUserData> = {
  payment: null,
  recipientDtc: null,
};

export const RecipientsTable: VFC<Props> = ({
  managers,
  operationType,
  billAndDeliverData = [],
}) => {
  return (
    <TableContainer>
      <Table aria-label="Manager B&D Wire Recipients Table">
        <RecipientsTableHead />
        <SyndicateRecipientsUserDataFormFields<ManagerBdRecipientUserData, Values>
          managers={managers}
          operationType={operationType}
          numColumns={4}
          defaultRecipientValues={defaultRecipientValues}
          renderRecipientRow={({ manager, managerIndex, onChange }) => {
            const { billAndDeliveryShares } =
              billAndDeliverData.find(
                ({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey
              ) ?? {};

            return (
              <TableRow key={manager.cmgEntityKey}>
                <TableCell>{manager.firmName}</TableCell>
                <TableCell
                  align="right"
                  data-testid={`${manager.firmName}-billAndDeliveryShares-cell`}
                >
                  {numericUtil.formatNumber(billAndDeliveryShares, 0)}
                </TableCell>
                <TableCell align="right">
                  <FormikNumericField
                    startAdornment={
                      <InputAdornment
                        data-testid={`${manager.firmName}-payment-input-adornment`}
                        position="start"
                      >
                        $
                      </InputAdornment>
                    }
                    inputProps={{
                      'aria-label': `${manager.firmName} - Payment`,
                    }}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.payment`}
                    onChange={payment => {
                      onChange({ payment });
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <FormikTextField
                    inputProps={{
                      'aria-label': `${manager.firmName} - Recipient DTC Number`,
                    }}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.recipientDtc`}
                    onChange={recipientDtc => {
                      onChange({ recipientDtc });
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};
