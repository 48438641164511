import { SvgIcon } from '@cmg/design-system';
import React from 'react';

import { ReactComponent as CmgLogo } from '../../../../../../common/assets/identity-icon.svg';
import type { DemandGridCellRendererProps } from '../../types';

export type Props = DemandGridCellRendererProps<boolean>;

export const OnPlatformCellRenderer: React.FC<Props> = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <SvgIcon fontSize="small" aria-label="On platform" role="img">
      <CmgLogo />
    </SvgIcon>
  );
};
