import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { useManageIndication_UpdateMyInstitutionalIndicationStatusMutation } from '../../../graphql/__generated__';

export const useUpdateMyIndicationStatus = (
  offeringId: UUID,
  successCallback?: () => void
): [(status: IndicationStatus) => Promise<void>, boolean] => {
  const [updateStatus, { loading: isUpdatingStatus }] =
    useManageIndication_UpdateMyInstitutionalIndicationStatusMutation();

  const handleUpdateStatus = React.useCallback(
    async (status: IndicationStatus) => {
      try {
        await updateStatus({
          variables: { offeringId, status },
        });

        SnackbarManager.success('Successfully updated indication status');
        successCallback?.();
        pendingOrderBookGridForcedRefreshVar(true);
      } catch {
        SnackbarManager.error('Failed to update indication status');
      }
    },
    [offeringId, successCallback, updateStatus]
  );

  return [handleUpdateStatus, isUpdatingStatus];
};
