import { FlexContainer, PrimaryButton, SecondaryButton } from '@cmg/common';

import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { ActivityGroupType, getActivitiesGroupLabel } from '../../common/activity/utils';

export type Props = {
  groupType: ActivityGroupType;
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const RemoveAllActivitiesModal: React.FC<Props> = ({
  groupType,
  show,
  onCancel,
  onSubmit,
}) => {
  return (
    <Modal show={show} onHide={onCancel} title="Remove All">
      <Modal.Content>
        {`Are you sure you want to remove all ${getActivitiesGroupLabel(groupType)}?`}
      </Modal.Content>
      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton onClick={onSubmit}>Continue</PrimaryButton>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};
