import { getCurrencySymbol, numericUtil, UUID } from '@cmg/common';

import {
  InstitutionalIndicationOrderType,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
} from '../../../../../../../../graphql';
import { limitTypeLabels } from '../../../../../../../../types/domain/order-book/constants';
import { IndicationForm_IndicationDetails_OfferingPartsFragment } from '../../../indication-form/components/indication-details/graphql/__generated__';
import { IndicationForm_MyIndicationPartsFragment } from '../../../indication-form/graphql/__generated__';

const { formatCurrency, formatPercents, formatInteger } = numericUtil;

export const getInterestQuantityCellValue = (
  interestUnit: InterestLevelInterestUnit,
  interestQuantity: number,
  demandCurrency?: string | null
) => {
  switch (interestUnit) {
    case InterestLevelInterestUnit.Currency:
      return formatCurrency(interestQuantity, undefined, getCurrencySymbol(demandCurrency));
    case InterestLevelInterestUnit.Percent:
      return formatPercents(interestQuantity);
    case InterestLevelInterestUnit.Shares:
      return formatInteger(interestQuantity);
  }
};

export const getLimitTypeCellValue = (
  orderType: InstitutionalIndicationOrderType,
  limitType: InterestLevelLimitType | null | undefined,
  currency: string | null | undefined,
  limitPrice: number | null | undefined
) => {
  if (orderType === InstitutionalIndicationOrderType.Market) {
    return;
  }
  const isLimitTypePrice = limitType === InterestLevelLimitType.Price;

  const formattedCurrency = isLimitTypePrice ? ` (${currency})` : '';
  const formattedLimitPrice = isLimitTypePrice
    ? formatCurrency(limitPrice, undefined, getCurrencySymbol(currency))
    : formatPercents(limitPrice);

  return `${limitTypeLabels[limitType!]}${formattedCurrency}: ${formattedLimitPrice}`;
};

export const getFirms = (
  acknowledgements: IndicationForm_MyIndicationPartsFragment['acknowledgements'],
  managers: IndicationForm_IndicationDetails_OfferingPartsFragment['syndicate']['managers'],
  userCmgEntityKey: UUID | null,
  indicationVersion: string
) => {
  const firms = acknowledgements.filter(
    ({ acknowledgedIndicationVersion, managerCmgEntityKey }) =>
      managerCmgEntityKey === userCmgEntityKey ||
      acknowledgedIndicationVersion === indicationVersion
  );
  return firms.map(({ managerCmgEntityKey, acknowledgedIndicationVersion }) => ({
    firmName:
      managers?.find(({ cmgEntityKey }) => managerCmgEntityKey === cmgEntityKey)?.firmName ?? null,
    isRevised: acknowledgedIndicationVersion !== indicationVersion,
    isUsersFirm: managerCmgEntityKey === userCmgEntityKey,
  }));
};

export const ACK_BY_TOOLTIP_TEXT =
  'Acknowledgement of an indication of interest (IOI) is not a sale, an acceptance of an order, a trade confirmation or a commitment to make an allocation. It merely demonstrates receipt of such IOI.';
