import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Loading from '../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import { useTradeRelease_TradeReleaseValidationQuery } from './graphql';
import NewTradeReleaseLayout from './layout/TradeReleaseLayout';
import { TradeReleaseZeroState } from './zero-state/TradeReleaseZeroState';

export type Props = {
  offeringIssuerName?: string;
} & RouteComponentProps<{ offeringId: UUID }>;

const TradeReleaseRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.tradeRelease.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const { data, loading, error } = useTradeRelease_TradeReleaseValidationQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const isAllocationReleased = data?.tradeReleaseValidation.isInstitutionalAllocationSetReleased;

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (isAllocationReleased) {
    return (
      <NewTradeReleaseLayout offeringId={offeringId} offeringIssuerName={offeringIssuerName} />
    );
  }

  return <TradeReleaseZeroState offeringId={offeringId} offeringIssuerName={offeringIssuerName} />;
};

export default TradeReleaseRoute;
