import {
  CheckboxField,
  FlexContainer,
  Icon,
  PrimaryButton,
  SecondaryButton,
  SelectField,
  TextInputField,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_TIMEOUT } from '../../../constants';
import {
  filterInitialValues,
  releaseStatusOptions,
  SalesCreditsGridFilter,
} from './SalesCreditsGridFilters.model';
import { SButtonWrapper, SSearchIconWrapper, SWrapper } from './SalesCreditsGridFilters.styles';

export type Props = {
  onSetGridFilter: (filter: SalesCreditsGridFilter) => void;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  onExport: () => void;
  onOpenHouseAccountsModal: () => void;
  isEditing: boolean;
  isLoading: boolean;
};

const SalesCreditsGridFilters: React.FC<Props> = ({
  onSetGridFilter,
  onEdit,
  isEditing,
  isLoading,
  onCancel,
  onSave,
  onExport,
  onOpenHouseAccountsModal,
}) => {
  const formik = useFormik<SalesCreditsGridFilter>({
    initialValues: filterInitialValues,
    onSubmit: onSetGridFilter,
  });
  const { submitForm } = formik;

  const debouncedSubmitForm = useDebouncedCallback(() => submitForm(), DEBOUNCE_TIMEOUT);

  return (
    <SWrapper direction="row">
      <FormikProvider value={formik}>
        <Form>
          <FlexContainer direction="row" gap={8} alignItems="baseline">
            <TextInputField
              name="investor"
              placeholder="Search for Investor..."
              onChange={debouncedSubmitForm}
              suffix={
                <SSearchIconWrapper>
                  <Icon name="search" />
                </SSearchIconWrapper>
              }
              disabled={isEditing}
            />
            <SelectField
              name="releaseStatus"
              placeholder="Select Sales Credit Status"
              options={releaseStatusOptions}
              onChange={submitForm}
              disabled={isEditing}
            />
            <CheckboxField name="containsErrors" onChange={submitForm} disabled={isEditing}>
              Contains Errors
            </CheckboxField>
          </FlexContainer>
        </Form>
      </FormikProvider>

      <SButtonWrapper direction="row-reverse" gap={8}>
        {!isEditing && <SecondaryButton onClick={onEdit}>Edit Sales Credits</SecondaryButton>}
        {isEditing && (
          <PrimaryButton onClick={onSave} loading={isLoading}>
            Save
          </PrimaryButton>
        )}
        {isEditing && (
          <SecondaryButton onClick={onCancel} disabled={isLoading}>
            Cancel
          </SecondaryButton>
        )}
        {!isEditing && (
          <SecondaryButton
            title="Update House Accounts"
            onClick={onOpenHouseAccountsModal}
            iconLeft={{ name: 'cog', variant: 'light' }}
          />
        )}
        {!isEditing && (
          <SecondaryButton
            title="Export CSV"
            iconRight={{ name: 'file-export', variant: 'regular' }}
            onClick={onExport}
          />
        )}
      </SButtonWrapper>
    </SWrapper>
  );
};

export default SalesCreditsGridFilters;
