import { ApolloError } from '@apollo/client';
import {
  Icon,
  Popover,
  PrimaryButton,
  SecondaryButton,
  SelectField,
  TextInputField,
  UnsavedChangesGuard,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useFeatureToggles } from '../../../common/config';
import {
  CreateOfferingInput,
  OfferingType,
  SecurityType,
  UpdateBasicInfoInput,
} from '../../../graphql';
import { currencyTypeOptions } from '../../../types/domain/currency/constants';
import { securityTypeOptions } from '../../../types/domain/security/constants';
import { getOfferingSetupTypesOptions } from '../basic-info/components/IssuerSubForm.model';
import SetupForm from '../components/form/OfferingSetupForm';
import { OfferingSetup_BasicInfoRoute_BasicInfoQuery } from '../graphql';
import { StyledButtonsContainer } from './BasicInfoForm.styles';

export const BasicInfoFormSchema = yup.object().shape({
  type: yup.string().required('Offering type is required'),
  issuer: yup.object().shape({
    name: yup.string().nullable().required('Issuer name is required'),
  }),
  security: yup.object().shape({
    type: yup.string().nullable().required('Security Type is required'),
  }),
  pricingCurrencyCode: yup.string().required('Pricing Currency Code is required'),
});

export type FormType = CreateOfferingInput | UpdateBasicInfoInput;

export type OwnProps = {
  offering?: OfferingSetup_BasicInfoRoute_BasicInfoQuery['offering'];
  submitting?: boolean;
  error?: ApolloError;
  onSubmit: (payload: FormType) => void;
  onHide: () => void;
};

export type Props = OwnProps;

export const SPopoverContent = styled.div`
  max-width: 440px;
  overflow: wrap;
`;

export const StyledInfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SInfoWrapper = styled.span`
  margin-left: 4px;
`;

export const currencyLabel = (
  <React.Fragment>
    Pricing Currency
    <Popover
      content={
        <SPopoverContent>USD is the only supported pricing currency at this time.</SPopoverContent>
      }
      variant="DARK"
      placement="top"
      autoAdjustOverflow
      arrowPointAtCenter
    >
      <SInfoWrapper>
        <StyledInfoIcon name="info-circle" variant="solid" />
      </SInfoWrapper>
    </Popover>
  </React.Fragment>
);

/**
 * Basic Info Form (Offering Type, Issuer, Security)
 * Creates an Offering Draft for an Offering
 */
export const ModalBasicInfoFormComponent: React.FC<Props> = ({
  error,
  submitting,
  onSubmit,
  onHide,
}) => {
  const formikOptions = {
    initialValues: {
      type: OfferingType.Ipo,
      issuer: {
        name: '',
      },
      security: {
        type: SecurityType.CommonOrdinaryShare,
      },
      pricingCurrencyCode: 'USD',
    },
    validationSchema: BasicInfoFormSchema,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  };
  const formik = useFormik(formikOptions);
  const { isEnableNonUsOnPlatformOfferingsPhase1On } = useFeatureToggles();

  const { dirty, resetForm } = formik;
  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
        {error && <ServerErrorsBanner error={error} />}
        <Form>
          <SetupForm.Card>
            <SetupForm.Row>
              <SelectField
                testId={xcSelectors.offeringSetupBasicInfoOfferingType.testId}
                name="type"
                label="Offering Type"
                placeholder="Select Offering Type..."
                options={getOfferingSetupTypesOptions({ excludeIpo: false })}
                disabled={submitting}
                isClearable={false}
                withMargin
                fullWidth
                required
              />
            </SetupForm.Row>
            <SetupForm.Row>
              <TextInputField
                testId={xcSelectors.offeringSetupBasicInfoIssuerName.testId}
                name="issuer.name"
                label="Issuer Name"
                placeholder="Enter Issuer Name..."
                disabled={submitting}
                fullWidth
                withMargin
                required
              />
            </SetupForm.Row>
            <SetupForm.Row>
              <SelectField
                testId={xcSelectors.offeringSetupBasicInfoSecurityType.testId}
                name="security.type"
                label="Security Type"
                placeholder="Choose Security Type..."
                disabled={submitting}
                options={securityTypeOptions}
                isClearable={false}
                withMargin
                fullWidth
                required
              />
            </SetupForm.Row>
            {!isEnableNonUsOnPlatformOfferingsPhase1On && (
              <SetupForm.Row>
                <SelectField
                  testId={xcSelectors.offeringSetupBasicInfoPricingCurrency.testId}
                  name="pricingCurrencyCode"
                  label={currencyLabel}
                  placeholder="Ex: USD..."
                  disabled={submitting || currencyTypeOptions.length < 2}
                  options={currencyTypeOptions}
                  isSearchable={true}
                  isClearable={false}
                  withMargin
                  fullWidth
                  required
                />
              </SetupForm.Row>
            )}
            <StyledButtonsContainer justifyContent="right" direction="row" margin={12}>
              <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
              <PrimaryButton type="submit">Create Draft</PrimaryButton>
            </StyledButtonsContainer>
          </SetupForm.Card>
        </Form>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default ModalBasicInfoFormComponent;
