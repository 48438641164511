import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { StabilizationTrade } from '../../StabilizationForm.model';

export const getSellGain = (trade: StabilizationTrade, breakevenPrice: number) => {
  if (isNil(trade.averagePrice) || isNil(trade.numberOfShares)) {
    return null;
  }

  return (trade.averagePrice - breakevenPrice) * trade.numberOfShares;
};

export const getTotalSellGain = (trades: StabilizationTrade[], breakevenPrice: number) => {
  return numericUtil.sum(...trades.map(trade => getSellGain(trade, breakevenPrice)));
};
