import styled from 'styled-components/macro';

export const SFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SButtonGroup = styled.div`
  display: flex;

  > * {
    margin-right: 10px;
  }

  *:last-child {
    margin-right: 0;
  }
`;
