import { ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledFooterRow = styled(Table.Row)`
  & > div {
    background-color: ${({ theme }) => theme.designSystem.colors.darkBlue[300]};
    color: ${({ theme }) => theme.text.color.white};
    pointer-events: none;
    padding: 8px;
  }
`;

export const StyledCell = styled(Table.Cell)`
  justify-content: flex-end;
`;

export const StyledRow = styled(Table.Row)`
  & > div {
    height: 36px;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }
`;

export const SWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SSpacer = styled.div`
  height: 180px;
`;
