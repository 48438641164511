import { DeepWritable, ODSTable } from '@cmg/common';
import React from 'react';

import { ComplianceProspectus_LatestEmailStatusPartsFragment } from '../../graphql';
import { comparators, ProspectusHistoryTableColumn } from './ProspectusHistoryTable.model';
import { ProspectusHistoryTableRow } from './ProspectusHistoryTableRow';

const { Orderable } = ODSTable;

type Props = { revisions: readonly ComplianceProspectus_LatestEmailStatusPartsFragment[] };

export const ProspectusHistoryTable: React.FC<Props> = ({ revisions }) => {
  return (
    <Orderable.Table<
      ProspectusHistoryTableColumn,
      ComplianceProspectus_LatestEmailStatusPartsFragment
    >
      gridTemplateColumns="30px 1.25fr 1fr 2fr"
      comparators={comparators}
      rows={revisions as DeepWritable<typeof revisions>}
    >
      {orderedRows => (
        <React.Fragment>
          <Orderable.TableHeaderCell column={ProspectusHistoryTableColumn.ERROR_INDICATOR} />
          <Orderable.TableHeaderCell column={ProspectusHistoryTableColumn.SEND_REQUESTED_AT}>
            Sent Date
          </Orderable.TableHeaderCell>
          <Orderable.TableHeaderCell column={ProspectusHistoryTableColumn.STATUS}>
            Delivery Status
          </Orderable.TableHeaderCell>
          <Orderable.TableHeaderCell column={ProspectusHistoryTableColumn.RECIPIENT}>
            Recipient
          </Orderable.TableHeaderCell>

          {orderedRows.map(orderedRow => (
            <ProspectusHistoryTableRow revision={orderedRow} key={orderedRow.originalRowIndex} />
          ))}
        </React.Fragment>
      )}
    </Orderable.Table>
  );
};
