import styled from 'styled-components/macro';

export const SLayout = styled.div`
  display: flex;
  min-height: 100%;
`;

export const SNavbar = styled.div`
  flex: 0 0 230px;
  background-color: ${({ theme }) => theme.background.color.light};
`;

export const SContent = styled.div`
  width: 100%;
  padding: 16px;
  overflow: auto;
  height: 90vh;
`;
