import { Box } from '@cmg/design-system';
import type { PropsWithChildren } from 'react';
import React from 'react';

import type { Props as OrderBookSubNavigationProps } from '../order-book-sub-navigation/OrderBookSubNavigation';
import OrderBookSubNavigation from '../order-book-sub-navigation/OrderBookSubNavigation';

export type Props = {
  subNavigationProps: OrderBookSubNavigationProps;
};

export const OrderBookPageLayout: React.FC<PropsWithChildren<Props>> = ({
  children,
  subNavigationProps,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexShrink={1}
      flexBasis="0%"
      px={2}
      sx={{ backgroundColor: 'white' }}
    >
      <OrderBookSubNavigation {...subNavigationProps} />
      {children}
    </Box>
  );
};
