import { EnableAutoSave, numericUtil, UUID } from '@cmg/common';
import { Link, TableCell, TableRow } from '@cmg/design-system';
import { FormikNumberField } from '@cmg/design-system-formik';
import { format } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import FormikUnsavedChangesGuard from '../../../../../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../../../../types/domain/manager/constants';
import TableCellOnPlatformIcon from '../../../../../components/table-cell-on-platform-icon/TableCellOnPlatformIcon';
import {
  OrderBook_TotalRetailSummaryItemManagerPartsFragment,
  OrderBook_TotalRetailSummaryItemPartsFragment,
} from '../../../../graphql';
import { getLatestSummaryItem, getManagerName } from '../../../common/utils/TableRowUtils';
import {
  AssignDemandVariables,
  AssignRetentionVariables,
} from '../../AutoSaveTotalRetailDemand.model';
import {
  demandValidationSchema,
  DemandValues,
  retentionValidationSchema,
  RetentionValues,
} from './TotalRetailDemandTableRow.model';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  offeringId: UUID;
  row: OrderBook_TotalRetailSummaryItemPartsFragment;
  demandValues: DemandValues;
  retentionValues: RetentionValues;
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
  onAssignDemand: (variables: AssignDemandVariables) => Promise<void>;
  onAssignRetention: (variables: AssignRetentionVariables) => Promise<void>;
  onValueChange: (cmgEntityKey: UUID, demand: DemandValues, retention: RetentionValues) => void;
  onFocus: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTableRowElement>) => void;
  onSaveFinished: () => void;
}>;

const TotalRetailDemandTableRow: React.FC<Props> = ({
  offeringId,
  row,
  demandValues,
  retentionValues,
  allManagers,
  onAssignDemand,
  onAssignRetention,
  onValueChange,
  onFocus,
  onBlur,
  onSaveFinished,
}) => {
  const [isSavingDemand, setIsSavingDemand] = React.useState(false);
  const [isSavingRetention, setIsSavingRetention] = React.useState(false);

  const { manager, demand, retention } = row;

  const demandFormik = useFormik<DemandValues>({
    validationSchema: demandValidationSchema,
    initialValues: demandValues,
    onSubmit: async (values, { resetForm }) => {
      await onAssignDemand({
        underwriterName: manager.name,
        cmgEntityKey: manager.cmgEntityKey,
        demandShares: values.demandShares!,
        roundLots: values.roundLots,
      });
      resetForm({ values });
    },
  });
  const retentionFormik = useFormik<RetentionValues>({
    validationSchema: retentionValidationSchema,
    initialValues: retentionValues,
    onSubmit: async (values, { resetForm }) => {
      await onAssignRetention({
        underwriterName: manager.name,
        cmgEntityKey: manager.cmgEntityKey,
        retentionShares: values.retentionShares!,
      });
      resetForm({ values });
    },
  });

  React.useEffect(() => {
    onValueChange(manager.cmgEntityKey, demandFormik.values, retentionFormik.values);
  }, [onValueChange, manager.cmgEntityKey, demandFormik.values, retentionFormik.values]);

  const handleDemandStartSave = () => {
    if (!demandFormik.isValid) {
      return;
    }

    setIsSavingDemand(true);
  };
  const handleDemandFinishSave = () => {
    setIsSavingDemand(false);
    onSaveFinished();
  };
  const handleRetentionStartSave = () => {
    if (!retentionFormik.isValid) {
      return;
    }

    setIsSavingRetention(true);
  };
  const handleRetentionFinishSave = () => {
    setIsSavingRetention(false);
    onSaveFinished();
  };

  const { modifiedByFirmKey, modifiedAt } = getLatestSummaryItem(demand, retention) ?? {};
  const submittedByManager = getManagerName(allManagers, modifiedByFirmKey);

  return (
    <TableRow data-testid="retail-demand-data-row" onFocus={onFocus} onBlur={onBlur}>
      <TableCellOnPlatformIcon size="small" isOnPlatform={manager.isOnPlatform} />
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Link
          href={routeFactory.orderBookTotalRetailDemandHistory.getUrlPath({
            offeringId,
            cmgEntityKey: manager.cmgEntityKey,
          })}
        >
          {manager.name}
        </Link>
      </TableCell>
      <TableCell>{managerRoleLabels[manager.role]}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{submittedByManager || '-'}</TableCell>
      <TableCell>{modifiedAt ? format(new Date(modifiedAt), 'MM/dd/yyyy HH:mm a') : '-'}</TableCell>
      <FormikProvider value={demandFormik}>
        <FormikUnsavedChangesGuard>
          <EnableAutoSave
            saveStarted={handleDemandStartSave}
            saveFinished={handleDemandFinishSave}
          />
          <TableCell align="right">
            {row.demand.isSubmitted ? (
              getDisplayValueForInteger(demandFormik.values.demandShares)
            ) : (
              <FormikNumberField
                aria-label="demand input"
                name="demandShares"
                decimalScale={0}
                disabled={isSavingDemand}
                showHelperTextInTooltip
                fullWidth
              />
            )}
          </TableCell>
          <TableCell align="right">
            {row.demand.isSubmitted ? (
              getDisplayValueForInteger(demandFormik.values.roundLots)
            ) : (
              <FormikNumberField
                aria-label="round lots input"
                name="roundLots"
                decimalScale={0}
                disabled={isSavingDemand}
                showHelperTextInTooltip
                fullWidth
              />
            )}
          </TableCell>
        </FormikUnsavedChangesGuard>
      </FormikProvider>
      <FormikProvider value={retentionFormik}>
        <FormikUnsavedChangesGuard>
          <EnableAutoSave
            saveStarted={handleRetentionStartSave}
            saveFinished={handleRetentionFinishSave}
          />
          <TableCell>
            <FormikNumberField
              aria-label="retention input"
              name="retentionShares"
              decimalScale={0}
              disabled={isSavingRetention}
              showHelperTextInTooltip
              fullWidth
            />
          </TableCell>
        </FormikUnsavedChangesGuard>
      </FormikProvider>
    </TableRow>
  );
};

export default TotalRetailDemandTableRow;
