import { getOperationName } from '@apollo/client/utilities';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { IndicationForm_IndicationBillingAndDeliveryAgentDocument } from '../../billing-and-delivery-manager/graphql/__generated__';
import {
  IndicationForm_AcknowledgeIndicationMutationVariables,
  IndicationForm_IndicationAcknowledgementsDocument,
  useIndicationForm_AcknowledgeIndicationMutation,
} from '../graphql/__generated__';

export function refetchQueries() {
  const refetchInstitutionalIndicationAcknowledgementsQuery = getOperationName(
    IndicationForm_IndicationAcknowledgementsDocument
  );
  const refetchSummaryGridQuery = getOperationName(InstitutionalDemandGrid_SummaryDocument);
  const refetchInstitutionalIndicationBillingAndDeliveryManagerQuery = getOperationName(
    IndicationForm_IndicationBillingAndDeliveryAgentDocument
  );
  return [
    refetchInstitutionalIndicationAcknowledgementsQuery!,
    refetchSummaryGridQuery!,
    refetchInstitutionalIndicationBillingAndDeliveryManagerQuery!,
  ];
}

export const useAcknowledgeInstitutionalIndicationMutation = (
  variables: IndicationForm_AcknowledgeIndicationMutationVariables
) =>
  useIndicationForm_AcknowledgeIndicationMutation({
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
