import { ApolloClient, createHttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { accessTokenExpirationGraphqlInterceptor, getAccessToken } from '@cmg/auth';
import { loggerUtil } from '@cmg/common';

import { typedPolicies, TypedTypePolicies } from '../../graphql';
import { getEnvVars } from '../config/appSettings';
import { SseApolloLink } from './SseApolloLink';

// Simple wrapper around window.fetch for use in apollo http link.
// Set as fetch to window.fetch, which datadog sets up its trace proxy on.
// apollo client httplink must be doing something that is incompatible with how datadog proxies fetch.
// (more info from a similar tool to datadog: https://docs.logrocket.com/docs/graphql)
const fetcher = window.fetch;

export const gatewayHttpLink = createHttpLink({
  uri: '/gw/graphql',
  fetch: fetcher,
  // If we aren't specific on the accepts header, HotChocolate Fusion defaults to application/graphql-response+json
  // And according to the GraphQL spec this causes Fusion to return HttpStatus code 500 if response is { data: null }
  // see: https://github.com/ChilliCream/graphql-platform/blob/d11febb93623212584a5298f39540f155087dd75/src/HotChocolate/AspNetCore/src/AspNetCore/Serialization/DefaultHttpResponseFormatter.cs#L218
  headers: { accept: 'application/json' },
});

export const gatewaySseLink = new SseApolloLink({
  url: `${window.location.origin}/gw/graphql`,
  headers: () => ({
    authorization: `Bearer ${getAccessToken()}`,
  }),
});

export const gatewaySplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  gatewaySseLink,
  gatewayHttpLink
);

const typePolicies: TypedTypePolicies = {
  InstitutionalIndication: {
    fields: {
      investorInformation: {
        // When two of the same investorInformation objects are
        // encountered, just merge them together.
        // The scenario where this occurs most often
        // is when we have multiple queries for investorInformation
        // that query different fields.  So if I query for companyName
        // in one query and cmgEntityKey in another, I expect
        // investorInformation to contain both {companyName, cmgEntityKey}
        merge(existing = {}, incoming: {}) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  InstitutionalIndicationDemand: {
    fields: {
      demandLevels: {
        merge(existing = {}, incoming: {}) {
          return { ...incoming };
        },
      },
    },
  },
  ...typedPolicies,
};

const cache = new InMemoryCache({
  typePolicies,
});

// We'll intercept requests to the GraphQL API, and
// append the authorization token.
export const authLink = setContext((request, previousContext) => {
  const token = getAccessToken();
  return {
    headers: {
      ...previousContext.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const errorLinkErrorHandler: ErrorHandler = ({ graphQLErrors, networkError }) => {
  const errorLog: string[] = [];
  const graphqlErrorCodes: string[] =
    graphQLErrors?.map(({ extensions }) => extensions?.code) ?? [];

  accessTokenExpirationGraphqlInterceptor(graphqlErrorCodes);

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      errorLog.push(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    const envVars = getEnvVars();

    errorLog.push(`[Network error]: ${networkError}`);

    if (!envVars.isDevelopment) {
      loggerUtil.error(networkError, errorLog);
    } else {
      console.error(...errorLog);
    }
  }
};

/**
 * onError link is an error interceptor, it gets invoked automatically whenever there is an error
 */
export const errorLink = onError(errorLinkErrorHandler);

/**
 * Retry link is a network error interceptor, it gets invoked automatically whenever there is a network error.
 * It retries the request for a specified number of times.
 * Mitigates the unstable network connection or transient GQL server connection issues.
 */
export const retryLink = new RetryLink({
  delay: {
    initial: 400,
    max: 3000,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    loggerUtil.warning(`Retry attempt #${count} for operation ${operation.operationName}`, error);

    return count < 3;
  },
});

export const generateApolloLinkList = () => [authLink, errorLink, retryLink, gatewaySplitLink];

const graphqlApiClient = new ApolloClient({
  link: from(generateApolloLinkList()),
  cache,
});

export default graphqlApiClient;
