import { Popover } from '@cmg/common';

import { SPopoverContent, SText } from './TextWithEllipsis.styles';

export type Props = {
  text?: string | null;
  maxLength?: number;
};

export const TextWithEllipsis: React.VFC<Props> = ({ text, maxLength }) => {
  const textHasToBeShortened = maxLength !== undefined && text != null && text?.length > maxLength;
  const shortenedText = textHasToBeShortened ? text.substring(0, maxLength) : text;

  return (
    <Popover
      content={<SPopoverContent>{text}</SPopoverContent>}
      variant="DARK"
      placement="topLeft"
      disabled={!textHasToBeShortened}
    >
      <SText>
        {shortenedText}
        {textHasToBeShortened && '...'}
      </SText>
    </Popover>
  );
};
