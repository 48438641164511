import { ColDefCalendarGrid } from '../calendar.model';
import { CalendarGridOffering } from '../components/calendar-grid/CalendarGrid.model';
import {
  calendarGridColumns,
  CalendarGridOrderField,
} from '../components/calendar-grid/CalendarGridColumns';
import { useInternationalColumns } from '../components/calendar-grid/hooks/useInternationalColumns';

export const useBuySideColumns = (): ColDefCalendarGrid<CalendarGridOffering>[] => {
  const {
    regionAndCountryColumns,
    sizeInCurrencyColumn,
    priceRangeColumn,
    publicFilingDateColumn,
  } = useInternationalColumns<CalendarGridOffering>();

  return [
    calendarGridColumns[CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM],
    calendarGridColumns[CalendarGridOrderField.ISSUER_NAME],
    calendarGridColumns[CalendarGridOrderField.SYMBOL],
    ...regionAndCountryColumns,
    calendarGridColumns[CalendarGridOrderField.SECTOR],
    calendarGridColumns[CalendarGridOrderField.TYPE],
    calendarGridColumns[CalendarGridOrderField.SECURITY_TYPE],
    publicFilingDateColumn,
    sizeInCurrencyColumn,
    calendarGridColumns[CalendarGridOrderField.SIZE_IN_SHARES],
    priceRangeColumn,
    calendarGridColumns[CalendarGridOrderField.LEFT_LEAD],
  ];
};
