import { getOperationName } from '@apollo/client/utilities';

import {
  SalesCredits_HouseAccountsDocument,
  useSalesCredits_UpdateHouseAccountsMutation,
} from '../graphql';

export const refetchQueries = () => {
  const refetchHouseAccounts = getOperationName(SalesCredits_HouseAccountsDocument);

  return [refetchHouseAccounts!];
};

export const useUpdateSalesCreditsHouseAccountsMutation = () => {
  return useSalesCredits_UpdateHouseAccountsMutation({ refetchQueries, awaitRefetchQueries: true });
};
