import {
  DatePickerField,
  Icon,
  LinkButton,
  NumericInputField,
  ODSTable as Table,
  SelectField,
  UUID,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useCallback } from 'react';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import { ActivityGroupType, getActivitiesGroupLabel } from '../../common/activity/utils';
import { ZeroActivitiesTableGroup } from '../../detail/activity/ZeroActivitiesTableGroup';
import {
  Activity,
  activityTypeOptions,
  StabilizationActivity,
  stabilizationActivityTypeOptions,
} from '../TNFilingForm.model';
import { MissingStabilizationActivitiesInfoRow } from './MissingStabilizationActivitiesInfoRow';
import { RemoveAllActivitiesModal } from './RemoveAllActivitiesModal';

export type Props = {
  offeringId: UUID;
  groupType: ActivityGroupType;
  onAddActivity?: () => void;
  activities: readonly Activity[] | readonly StabilizationActivity[];
  onRemoveActivity: (idx: number) => void;
  onRemoveAllActivities: () => void;
  hasNoStabilizationActivities: boolean;
  stabilizationActivitiesLoading: boolean;
};

export const ActivitiesTableGroup: React.FC<Props> = ({
  offeringId,
  groupType,
  activities,
  onAddActivity,
  onRemoveActivity,
  onRemoveAllActivities,
  hasNoStabilizationActivities,
  stabilizationActivitiesLoading,
}) => {
  const [isRemoveAllConfirmationOpen, setIsRemoveAllConfirmationOpen] = React.useState(false);

  const activitiesCollectionFieldName = React.useMemo(() => {
    return groupType === 'custom' ? 'activities' : 'stabilizationActivities';
  }, [groupType]);

  const confirmRemovingAllActivities = useCallback(() => {
    onRemoveAllActivities();
    setIsRemoveAllConfirmationOpen(false);
  }, [onRemoveAllActivities]);

  return (
    <React.Fragment>
      <Table.GroupHeaderCell colSpan={3}>
        {getActivitiesGroupLabel(groupType)}
      </Table.GroupHeaderCell>
      <Table.GroupHeaderCell align="right">
        {activities.length > 0 && (
          <LinkButton
            inline={true}
            onClick={() => setIsRemoveAllConfirmationOpen(true)}
            testId="remove-all-button"
          >
            Remove All
          </LinkButton>
        )}
        <RemoveAllActivitiesModal
          groupType={groupType}
          show={isRemoveAllConfirmationOpen}
          onCancel={() => setIsRemoveAllConfirmationOpen(false)}
          onSubmit={confirmRemovingAllActivities}
        />
      </Table.GroupHeaderCell>
      {groupType === 'stabilization' && hasNoStabilizationActivities && (
        <MissingStabilizationActivitiesInfoRow />
      )}
      {groupType === 'stabilization' && stabilizationActivitiesLoading && (
        <Table.Row suppressHighlightingOnHover isFocused={false}>
          <Table.Cell colSpan={4} withBottomBorder={false}>
            <Loading />
          </Table.Cell>
        </Table.Row>
      )}
      {(groupType !== 'stabilization' || !stabilizationActivitiesLoading) &&
        (activities.length ? (
          activities.map((_, idx) => (
            <Table.Row key={idx}>
              <Table.Cell paddingSize="XS">
                {groupType === 'custom' ? (
                  <SelectField
                    name={`${activitiesCollectionFieldName}.${idx}.activityType`}
                    options={activityTypeOptions}
                    fullWidth
                    aria-label={`${idx + 1}. Custom Activity Type`}
                  />
                ) : (
                  <SelectField
                    name={`${activitiesCollectionFieldName}.${idx}.stabilizationActivityType`}
                    options={stabilizationActivityTypeOptions}
                    aria-label={`${idx + 1}. Stabilization Activity Type`}
                    fullWidth
                  />
                )}
              </Table.Cell>
              <Table.Cell paddingSize="XS">
                <DatePickerField
                  name={`${activitiesCollectionFieldName}.${idx}.date`}
                  fullWidth
                  testId={`${activitiesCollectionFieldName}.${idx}.date`}
                />
              </Table.Cell>
              <Table.Cell paddingSize="XS">
                {groupType === 'custom' ? (
                  <NumericInputField
                    name={`${activitiesCollectionFieldName}.${idx}.aggregateNumberOfShares`}
                    aria-label={`${idx + 1}. Custom Activity Shares`}
                    fullWidth
                  />
                ) : (
                  <NumericInputField
                    name={`${activitiesCollectionFieldName}.${idx}.shares`}
                    aria-label={`${idx + 1}. Stabilization Activity Shares`}
                    fullWidth
                  />
                )}
              </Table.Cell>
              <Table.Cell align="right" paddingSize="XS">
                <LinkButton
                  testId={`${xcSelectors.tnRemoveActivity.testId}.${idx}`}
                  onClick={() => onRemoveActivity(idx)}
                >
                  <Icon name="trash-alt" />
                </LinkButton>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <ZeroActivitiesTableGroup
            groupType={groupType}
            mode="edit"
            onAddCustom={onAddActivity}
            colSpan={4}
            offeringId={offeringId}
          />
        ))}
    </React.Fragment>
  );
};
