import { Typography } from '@cmg/design-system';
import React from 'react';

import { formatInterestLevel } from '../../utils';
import { OrderBook_InstitutionalDemand_IndicationDetailPartsFragment } from '../graphql/__generated__';

export type Props = Readonly<{
  interestLevels: OrderBook_InstitutionalDemand_IndicationDetailPartsFragment['interestLevels'];
  pricingCurrencyCode: string;
  demandCurrencyCode: string | null | undefined;
}>;

export const InterestLevels: React.FC<Props> = ({
  interestLevels,
  pricingCurrencyCode,
  demandCurrencyCode,
}) => {
  const interestLevelsFormatted = (interestLevels ?? []).map(interestLevel =>
    formatInterestLevel({
      interestLevel,
      pricingCurrencyCode,
      demandCurrencyCode,
    })
  );

  return (
    <Typography variant="highlight1" component="span">
      {interestLevelsFormatted.length === 0 && 'Pass'}
      {interestLevelsFormatted.length === 1
        ? interestLevelsFormatted[0]
        : interestLevelsFormatted.map((interestLevel, index) => (
            <React.Fragment key={index}>
              {interestLevel}
              <br />
            </React.Fragment>
          ))}
    </Typography>
  );
};
