/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_CurrencyPartsFragment = {
  readonly __typename?: 'DemandCurrency';
  readonly currencyCode: string;
  readonly exchangeRate: number;
};

export type OfferingSetup_InstrumentPartsFragment = {
  readonly __typename?: 'DeliveryInstrument';
  readonly id: string;
  readonly currencyCode: string;
  readonly countryCode: string;
  readonly stockSymbol?: string | null;
};

export type OfferingSetup_TranchePartsFragment = {
  readonly __typename?: 'Tranche';
  readonly id: string;
  readonly name: string;
  readonly region?: string | null;
  readonly defaultInstrumentId?: string | null;
  readonly currencies: ReadonlyArray<{
    readonly __typename?: 'DemandCurrency';
    readonly currencyCode: string;
    readonly exchangeRate: number;
  }>;
  readonly deliveryInstruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrument';
    readonly id: string;
    readonly currencyCode: string;
    readonly countryCode: string;
    readonly stockSymbol?: string | null;
  }>;
};

export type OfferingSetup_TranchesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_TranchesQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly tranches: ReadonlyArray<{
      readonly __typename?: 'Tranche';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly defaultInstrumentId?: string | null;
      readonly currencies: ReadonlyArray<{
        readonly __typename?: 'DemandCurrency';
        readonly currencyCode: string;
        readonly exchangeRate: number;
      }>;
      readonly deliveryInstruments: ReadonlyArray<{
        readonly __typename?: 'DeliveryInstrument';
        readonly id: string;
        readonly currencyCode: string;
        readonly countryCode: string;
        readonly stockSymbol?: string | null;
      }>;
    }>;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export type OfferingSetup_UpdateTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  input: Types.UpdateTrancheInput;
}>;

export type OfferingSetup_UpdateTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly updateTranche: {
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export type OfferingSetup_AddCurrencyToTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  currencyCode: Types.Scalars['String'];
}>;

export type OfferingSetup_AddCurrencyToTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly addCurrencyToTranche: {
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export type OfferingSetup_DeleteCurrencyFromTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  currencyCode: Types.Scalars['String'];
}>;

export type OfferingSetup_DeleteCurrencyFromTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteCurrencyFromTranche: {
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export type OfferingSetup_AddInstrumentToTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_AddInstrumentToTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly addInstrumentToTranche: {
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export type OfferingSetup_DeleteInstrumentFromTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DeleteInstrumentFromTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteInstrumentFromTranche:
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      }
    | {
        readonly __typename?: 'Tranche';
        readonly id: string;
        readonly name: string;
        readonly region?: string | null;
        readonly defaultInstrumentId?: string | null;
        readonly currencies: ReadonlyArray<{
          readonly __typename?: 'DemandCurrency';
          readonly currencyCode: string;
          readonly exchangeRate: number;
        }>;
        readonly deliveryInstruments: ReadonlyArray<{
          readonly __typename?: 'DeliveryInstrument';
          readonly id: string;
          readonly currencyCode: string;
          readonly countryCode: string;
          readonly stockSymbol?: string | null;
        }>;
      };
};

export type OfferingSetup_SetDefaultInstrumentForTrancheMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  trancheId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_SetDefaultInstrumentForTrancheMutation = {
  readonly __typename?: 'Mutation';
  readonly setDefaultInstrumentForTranche: {
    readonly __typename?: 'Tranche';
    readonly id: string;
    readonly name: string;
    readonly region?: string | null;
    readonly defaultInstrumentId?: string | null;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly stockSymbol?: string | null;
    }>;
  };
};

export const OfferingSetup_CurrencyPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InstrumentPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_TranchePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_TranchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Tranches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_TranchesQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_TranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_TranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_TranchesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_TranchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_TranchesQuery,
    OfferingSetup_TranchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_TranchesQuery, OfferingSetup_TranchesQueryVariables>(
    OfferingSetup_TranchesDocument,
    options
  );
}
export function useOfferingSetup_TranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_TranchesQuery,
    OfferingSetup_TranchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_TranchesQuery, OfferingSetup_TranchesQueryVariables>(
    OfferingSetup_TranchesDocument,
    options
  );
}
export type OfferingSetup_TranchesQueryHookResult = ReturnType<
  typeof useOfferingSetup_TranchesQuery
>;
export type OfferingSetup_TranchesLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_TranchesLazyQuery
>;
export type OfferingSetup_TranchesQueryResult = Apollo.QueryResult<
  OfferingSetup_TranchesQuery,
  OfferingSetup_TranchesQueryVariables
>;
export const OfferingSetup_UpdateTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTrancheInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateTrancheMutation,
  OfferingSetup_UpdateTrancheMutationVariables
>;

/**
 * __useOfferingSetup_UpdateTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateTrancheMutation, { data, loading, error }] = useOfferingSetup_UpdateTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateTrancheMutation,
    OfferingSetup_UpdateTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateTrancheMutation,
    OfferingSetup_UpdateTrancheMutationVariables
  >(OfferingSetup_UpdateTrancheDocument, options);
}
export type OfferingSetup_UpdateTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateTrancheMutation
>;
export type OfferingSetup_UpdateTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateTrancheMutation>;
export type OfferingSetup_UpdateTrancheMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateTrancheMutation,
  OfferingSetup_UpdateTrancheMutationVariables
>;
export const OfferingSetup_AddCurrencyToTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_AddCurrencyToTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCurrencyToTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_AddCurrencyToTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_AddCurrencyToTrancheMutation,
  OfferingSetup_AddCurrencyToTrancheMutationVariables
>;

/**
 * __useOfferingSetup_AddCurrencyToTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_AddCurrencyToTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_AddCurrencyToTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupAddCurrencyToTrancheMutation, { data, loading, error }] = useOfferingSetup_AddCurrencyToTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useOfferingSetup_AddCurrencyToTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_AddCurrencyToTrancheMutation,
    OfferingSetup_AddCurrencyToTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_AddCurrencyToTrancheMutation,
    OfferingSetup_AddCurrencyToTrancheMutationVariables
  >(OfferingSetup_AddCurrencyToTrancheDocument, options);
}
export type OfferingSetup_AddCurrencyToTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_AddCurrencyToTrancheMutation
>;
export type OfferingSetup_AddCurrencyToTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_AddCurrencyToTrancheMutation>;
export type OfferingSetup_AddCurrencyToTrancheMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_AddCurrencyToTrancheMutation,
  OfferingSetup_AddCurrencyToTrancheMutationVariables
>;
export const OfferingSetup_DeleteCurrencyFromTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteCurrencyFromTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCurrencyFromTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteCurrencyFromTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteCurrencyFromTrancheMutation,
  OfferingSetup_DeleteCurrencyFromTrancheMutationVariables
>;

/**
 * __useOfferingSetup_DeleteCurrencyFromTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteCurrencyFromTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteCurrencyFromTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteCurrencyFromTrancheMutation, { data, loading, error }] = useOfferingSetup_DeleteCurrencyFromTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useOfferingSetup_DeleteCurrencyFromTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteCurrencyFromTrancheMutation,
    OfferingSetup_DeleteCurrencyFromTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteCurrencyFromTrancheMutation,
    OfferingSetup_DeleteCurrencyFromTrancheMutationVariables
  >(OfferingSetup_DeleteCurrencyFromTrancheDocument, options);
}
export type OfferingSetup_DeleteCurrencyFromTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteCurrencyFromTrancheMutation
>;
export type OfferingSetup_DeleteCurrencyFromTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteCurrencyFromTrancheMutation>;
export type OfferingSetup_DeleteCurrencyFromTrancheMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteCurrencyFromTrancheMutation,
  OfferingSetup_DeleteCurrencyFromTrancheMutationVariables
>;
export const OfferingSetup_AddInstrumentToTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_AddInstrumentToTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInstrumentToTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_AddInstrumentToTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_AddInstrumentToTrancheMutation,
  OfferingSetup_AddInstrumentToTrancheMutationVariables
>;

/**
 * __useOfferingSetup_AddInstrumentToTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_AddInstrumentToTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_AddInstrumentToTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupAddInstrumentToTrancheMutation, { data, loading, error }] = useOfferingSetup_AddInstrumentToTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useOfferingSetup_AddInstrumentToTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_AddInstrumentToTrancheMutation,
    OfferingSetup_AddInstrumentToTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_AddInstrumentToTrancheMutation,
    OfferingSetup_AddInstrumentToTrancheMutationVariables
  >(OfferingSetup_AddInstrumentToTrancheDocument, options);
}
export type OfferingSetup_AddInstrumentToTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_AddInstrumentToTrancheMutation
>;
export type OfferingSetup_AddInstrumentToTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_AddInstrumentToTrancheMutation>;
export type OfferingSetup_AddInstrumentToTrancheMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_AddInstrumentToTrancheMutation,
  OfferingSetup_AddInstrumentToTrancheMutationVariables
>;
export const OfferingSetup_DeleteInstrumentFromTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteInstrumentFromTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInstrumentFromTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteInstrumentFromTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteInstrumentFromTrancheMutation,
  OfferingSetup_DeleteInstrumentFromTrancheMutationVariables
>;

/**
 * __useOfferingSetup_DeleteInstrumentFromTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteInstrumentFromTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteInstrumentFromTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteInstrumentFromTrancheMutation, { data, loading, error }] = useOfferingSetup_DeleteInstrumentFromTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useOfferingSetup_DeleteInstrumentFromTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteInstrumentFromTrancheMutation,
    OfferingSetup_DeleteInstrumentFromTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteInstrumentFromTrancheMutation,
    OfferingSetup_DeleteInstrumentFromTrancheMutationVariables
  >(OfferingSetup_DeleteInstrumentFromTrancheDocument, options);
}
export type OfferingSetup_DeleteInstrumentFromTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteInstrumentFromTrancheMutation
>;
export type OfferingSetup_DeleteInstrumentFromTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteInstrumentFromTrancheMutation>;
export type OfferingSetup_DeleteInstrumentFromTrancheMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteInstrumentFromTrancheMutation,
  OfferingSetup_DeleteInstrumentFromTrancheMutationVariables
>;
export const OfferingSetup_SetDefaultInstrumentForTrancheDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_SetDefaultInstrumentForTranche' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultInstrumentForTranche' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trancheId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trancheId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TrancheParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tranche' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_CurrencyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryInstruments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recordStatus' },
                      value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_InstrumentParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_SetDefaultInstrumentForTrancheMutationFn = Apollo.MutationFunction<
  OfferingSetup_SetDefaultInstrumentForTrancheMutation,
  OfferingSetup_SetDefaultInstrumentForTrancheMutationVariables
>;

/**
 * __useOfferingSetup_SetDefaultInstrumentForTrancheMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_SetDefaultInstrumentForTrancheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_SetDefaultInstrumentForTrancheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSetDefaultInstrumentForTrancheMutation, { data, loading, error }] = useOfferingSetup_SetDefaultInstrumentForTrancheMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      trancheId: // value for 'trancheId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useOfferingSetup_SetDefaultInstrumentForTrancheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_SetDefaultInstrumentForTrancheMutation,
    OfferingSetup_SetDefaultInstrumentForTrancheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_SetDefaultInstrumentForTrancheMutation,
    OfferingSetup_SetDefaultInstrumentForTrancheMutationVariables
  >(OfferingSetup_SetDefaultInstrumentForTrancheDocument, options);
}
export type OfferingSetup_SetDefaultInstrumentForTrancheMutationHookResult = ReturnType<
  typeof useOfferingSetup_SetDefaultInstrumentForTrancheMutation
>;
export type OfferingSetup_SetDefaultInstrumentForTrancheMutationResult =
  Apollo.MutationResult<OfferingSetup_SetDefaultInstrumentForTrancheMutation>;
export type OfferingSetup_SetDefaultInstrumentForTrancheMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_SetDefaultInstrumentForTrancheMutation,
    OfferingSetup_SetDefaultInstrumentForTrancheMutationVariables
  >;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_TranchesMockResponse = makeMockResponse<
  OfferingSetup_TranchesQueryVariables,
  OfferingSetup_TranchesQuery
>(OfferingSetup_TranchesDocument);
