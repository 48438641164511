import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_MeetingDocument,
  Roadshows_OpenMeetingToSalesMutation,
  useRoadshows_OpenMeetingToSalesMutation,
} from '../graphql';

export type OpenMeetingToSalesMutation = Roadshows_OpenMeetingToSalesMutation;

export function refetchQueries() {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument);
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetingDetails!, refetchMeetings!];
}

export const useOpenMeetingToSalesMutation = () => {
  return useRoadshows_OpenMeetingToSalesMutation({ refetchQueries, awaitRefetchQueries: true });
};
