import { LinkButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SStickyMenuListBottomItem = styled.div`
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-top: ${({ theme }) => theme.border.smallSolidLight};
  padding: 5px;
  background-color: ${({ theme }) => theme.background.color.light};
  color: ${({ theme }) => theme.text.color.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  position: sticky;
`;

export type Props = {
  onCreateNew: () => void;
  showCreateNewInvestor: boolean;
};
/**
 * CreateNewInvestorListOption renders a sticky list option on the bottom of the investor firm search menu list
 */
const AdvancedSearchOrCreateNewInvestorListOption: React.FC<Props> = ({
  onCreateNew,
  showCreateNewInvestor,
}) => {
  return (
    <SStickyMenuListBottomItem onClick={event => event.preventDefault()}>
      Can't find an investor?{' '}
      <LinkButton onClick={onCreateNew}>
        Advanced Search {showCreateNewInvestor && '/ Create New'}
      </LinkButton>
    </SStickyMenuListBottomItem>
  );
};

export default AdvancedSearchOrCreateNewInvestorListOption;
