import styled from 'styled-components/macro';

export const SPageBreak = styled.div`
  @media all {
    display: none;
  }
  @media print {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
`;

export const SLetterTemplateContainer = styled.div`
  padding: 32px;
  width: 100%;

  @media all {
    background-color: ${({ theme }) => theme.designSystem.colors.gray['000']};
  }
  @media print {
    background-color: ${({ theme }) => theme.background.color.white};
  }
`;

export const SCMGFooter = styled.div`
  width: 600px;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px 10px 32px;
  background-color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;

export const SCMGFooterImage = styled.img`
  max-width: 70px;
  height: auto;
  margin-bottom: 8px;
`;

export const SNoWrapSpan = styled.span`
  white-space: nowrap;
`;

export const SCMGFooterText = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.text.size.xs};
  font-weight: 400;
`;

export const SDivider = styled.hr`
  margin: 24px 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.designSystem.colors.gray['100']};
`;

export const SLetterFooter = styled.p`
  font-size: ${({ theme }) => theme.text.size.medium};
  margin-bottom: 0;
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;

export const SLetterFooterDate = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
`;

export const SLetterHeader = styled.p`
  font-size: ${({ theme }) => theme.text.size.large};
  padding: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;

export const SLetterSubHeader = styled.p`
  font-size: ${({ theme }) => theme.text.size.medium};
  padding: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
`;

export const SLetterText = styled.p`
  margin-top: 0;
`;

export const SLetterTextContainer = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;

export const SLetterWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.background.color.white};
  max-width: 600px;
  margin: auto;
`;
