import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { SubNavigation, UUID } from '@cmg/common';
import React, { useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../common/routing/private-route/PrivateRoute';
import routeFactory from '../../common/util/routeFactory';
import { NoAccessRedirect } from '../core/RootRouter';
import AccountingRoute from './accounting/AccountingRoute';
import DesignationMonitorRoute from './designation-monitor/DesignationMonitorRoute';
import ExpensesRoute from './expenses/ExpensesRoute';
import { getDefaultFinalSettlementUrl } from './FinalSettlementRoute.model';
import {
  StyledContent,
  StyledHeader,
  StyledPanel,
  StyledSubNavigationLink,
} from './FinalSettlementRoute.styles';
import SettlementLettersRoute from './settlement-letters/SettlementLettersRoute';
import StabilizationRoute from './stabilization/StabilizationRoute';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps & {
  offeringIssuerName: string | undefined;
};

/**
 * Primary Route for the Order Book module
 */
export const FinalSettlementRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const canViewDesignation = useCheckPermissions([permissionsByEntity.Designation.READ]);
  const canViewExpense = useCheckPermissions([permissionsByEntity.Expense.READ]);
  const canViewStabilization = useCheckPermissions([permissionsByEntity.Stabilization.READ]);
  const canViewAccounting = useCheckPermissions([permissionsByEntity.Accounting.READ]);
  const canViewLetter = useCheckPermissions([permissionsByEntity.Letter.READ]);

  const { offeringId } = match.params;

  const defaultFinalSettlementUrl = useMemo(() => {
    return getDefaultFinalSettlementUrl({
      offeringId,
      canViewDesignation,
      canViewExpense,
      canViewStabilization,
      canViewAccounting,
      canViewLetter,
    });
  }, [
    offeringId,
    canViewDesignation,
    canViewExpense,
    canViewStabilization,
    canViewAccounting,
    canViewLetter,
  ]);

  return (
    <StyledPanel>
      <StyledHeader>
        <SubNavigation data-test-id="final-settlement-navbar">
          <SubNavigation.Group>
            {canViewDesignation && (
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementDesignationMonitor.getUrlPath({
                    offeringId,
                  })}
                >
                  Designation Monitor
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            )}
            {canViewExpense && (
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementExpenses.getUrlPath({
                    offeringId,
                  })}
                >
                  Expenses
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            )}
            {canViewStabilization && (
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementStabilization.getUrlPath({ offeringId })}
                >
                  Stabilization
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            )}
            {canViewAccounting && (
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementAccounting.getUrlPath({ offeringId })}
                >
                  Settlement Accounting
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            )}
            {canViewLetter && (
              <SubNavigation.Item>
                <StyledSubNavigationLink
                  to={routeFactory.finalSettlementSettlementLetters.getUrlPath({
                    offeringId,
                  })}
                >
                  Settlement Letters
                </StyledSubNavigationLink>
              </SubNavigation.Item>
            )}
          </SubNavigation.Group>
        </SubNavigation>
      </StyledHeader>
      <StyledContent>
        <Switch>
          <PrivateRoute
            path={routeFactory.finalSettlementDesignationMonitor.routePath}
            render={routeProps => (
              <DesignationMonitorRoute
                offeringIssuerName={offeringIssuerName}
                {...(routeProps as RouteProps)}
              />
            )}
            renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
            requiredPermissions={[permissionsByEntity.Designation.READ]}
          />
          <PrivateRoute
            path={routeFactory.finalSettlementExpenses.routePath}
            render={routeProps => (
              <ExpensesRoute
                offeringIssuerName={offeringIssuerName}
                {...(routeProps as RouteProps)}
              />
            )}
            renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
            requiredPermissions={[permissionsByEntity.Expense.READ]}
          />
          <PrivateRoute
            path={routeFactory.finalSettlementStabilization.routePath}
            render={routeProps => (
              <StabilizationRoute
                {...(routeProps as RouteProps)}
                offeringIssuerName={offeringIssuerName}
              />
            )}
            renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
            requiredPermissions={[permissionsByEntity.Stabilization.READ]}
          />
          <PrivateRoute
            path={routeFactory.finalSettlementAccounting.routePath}
            render={routeProps => (
              <AccountingRoute
                {...(routeProps as RouteProps)}
                offeringIssuerName={offeringIssuerName}
              />
            )}
            renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
            requiredPermissions={[permissionsByEntity.Accounting.READ]}
          />
          <PrivateRoute
            path={routeFactory.finalSettlementSettlementLetters.routePath}
            render={routeProps => (
              <SettlementLettersRoute
                {...(routeProps as RouteProps)}
                offeringIssuerName={offeringIssuerName}
              />
            )}
            renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
            requiredPermissions={[permissionsByEntity.Letter.READ]}
          />
          {defaultFinalSettlementUrl && (
            <Route
              path={routeFactory.finalSettlement.routePath}
              render={() => <Redirect to={defaultFinalSettlementUrl} />}
            />
          )}
        </Switch>
      </StyledContent>
    </StyledPanel>
  );
};

export default FinalSettlementRoute;
