import { SecondaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

import Modal from '../../../../common/components/overlays/modal/Modal';

export const StyledModalContent = styled(Modal.Content)`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
`;

export const STableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 18px;
`;

export const SDivider = styled.div`
  border-right: 1px solid ${({ theme }) => theme.designSystem.colors.gray[200]};
`;

export const SInvestorDetailsTable = styled.table`
  flex-grow: 1;
  width: 300px;
  table-layout: fixed;

  & > tbody > tr > td {
    min-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const STableHeader = styled.tr`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SLabel = styled.td`
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
`;

export const StyledCancelButton = styled(SecondaryButton)`
  margin-right: auto;
`;
