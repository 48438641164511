import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import { getLabelFromOptions } from '../../../common/form-utils';
import { useGroupAffiliates } from '../../../hooks/useGroupAffiliates';
import { excusedOrPassiveOptions } from '../form/MemberFirmsSubForm';
import { RegulatoryFilings_RpnFilingPartsFragment } from '../graphql';

export type Props = {
  memberFirms: RegulatoryFilings_RpnFilingPartsFragment['finraMemberFirms'];
};

const title = 'FINRA Member Firms';

const MemberFirmsDetail: React.FC<Props> = ({ memberFirms }) => {
  const groupedRPNMemberFirmsDetailData = useGroupAffiliates(memberFirms, true);

  if (!memberFirms.length || !groupedRPNMemberFirmsDetailData) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Content>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Firm Name</TableCell>
                <TableCell>Member Firm CRD#</TableCell>
                <TableCell>Firm MPID</TableCell>
                <TableCell>Excused or Passive</TableCell>
              </TableRow>
            </TableHead>
            {groupedRPNMemberFirmsDetailData.map(([manager, memberFirms]) => (
              <TableBody key={manager} aria-label={manager}>
                <TableGroupHeader>
                  <TableGroupHeaderCell colSpan={4}>{manager}</TableGroupHeaderCell>
                </TableGroupHeader>
                {memberFirms.map(memberFirm => (
                  <TableRow key={memberFirm.firmCrdNumber}>
                    <TableCell size="medium">{memberFirm.firmName}</TableCell>
                    <TableCell size="medium">{memberFirm.firmCrdNumber}</TableCell>
                    <TableCell size="medium">{memberFirm.firmMpid}</TableCell>
                    <TableCell size="medium">
                      {getLabelFromOptions(memberFirm.excusedOrPassive, excusedOrPassiveOptions)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Panel.Content>
    </Panel>
  );
};

export default MemberFirmsDetail;
