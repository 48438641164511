import { Option } from '@cmg/common';
import * as Yup from 'yup';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import { ActivityType, CreateNoiFilingInput, NoiActivityInput } from '../../../../../graphql';
import {
  CommonFields,
  commonFilingSchemaShape,
  createInitialCommonFilingValues,
  getValueOrNull,
  InitialFilingValuesParameters,
} from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { RegulatoryFilings_NoiFilingPartsFragment } from '../graphql';

/**
 * Types
 */

export type ActivityFormValues = {
  activityType: ActivityType | null;
  date: string;
};

export type NOIFilingFormValues = CommonFields & {
  activities: ActivityFormValues[];
  additionalInformation: string;
};

export const activityTypeOptions: Option<ActivityType>[] = [
  {
    label: 'Syndicate covering transaction',
    value: ActivityType.Sct,
  },
  {
    label: 'Imposition of penalty bid',
    value: ActivityType.Iopb,
  },
  {
    label: 'Pre-Stabilization bid',
    value: ActivityType.Sb,
  },
];

/**
 * Form Schema
 */

export const formSchema = Yup.object().shape({
  ...commonFilingSchemaShape,
  activities: Yup.array()
    .label('Activities')
    .of(
      Yup.object().shape({
        activityType: Yup.string().label('Activity Type').nullable().required(),
        date: Yup.string().label('Activity Date').nullable().required(),
      })
    )
    .min(1)
    .required(),
  additionalInformation: Yup.string().max(2000).label('Activity Additional Information'),
});

/**
 * Filing => Form
 */

export const mapFilingToFormValues = (
  values: RegulatoryFilings_NoiFilingPartsFragment
): NOIFilingFormValues => ({
  contactName: values.contactName,
  contactTitle: values.contactTitle,
  emailAddress: values.emailAddress,
  firmCrdNumber: values.firmCrdNumber,
  firmMpid: values.firmMpid,
  firmName: values.firmName,
  telephoneNumber: values.telephoneNumber,
  additionalInformation: values.additionalInformation ?? '',
  activities: values.activities.map(activity => ({
    activityType: activity.activityType,
    date: activity.date,
  })),
  issuerSymbol: values.issuerSymbol,
  issuerName: values.issuerName,
});

/**
 * Form => Filing
 */

const mapActivitiesFormValuesToFilingActivities = (
  input: NOIFilingFormValues['activities']
): NoiActivityInput[] => {
  return input.map(item => ({
    date: item.date,
    activityType: item.activityType!,
  }));
};

export const mapFormValuesToFiling = (
  values: NOIFilingFormValues,
  recipients: string[],
  shouldAddSenderToBcc: boolean
): CreateNoiFilingInput => ({
  recipients,
  filingFormData: {
    contactName: values.contactName,
    contactTitle: values.contactTitle,
    emailAddress: values.emailAddress,
    firmCrdNumber: values.firmCrdNumber,
    firmMpid: values.firmMpid,
    firmName: values.firmName,
    telephoneNumber: values.telephoneNumber,
    additionalInformation: getValueOrNull(values.additionalInformation),
    activities: mapActivitiesFormValuesToFilingActivities(values.activities),
    issuerSymbol: values.issuerSymbol!,
  },
  submissionTimeZone: generatePayloadDateTimeData().sendDateTimeZone,
  shouldAddSenderToBcc,
});

/**
 * Initial values
 */

export function createActivity(): ActivityFormValues {
  return {
    activityType: null,
    date: '',
  };
}

export const createInitialValues = ({
  underwriter,
  filing,
  lastCommonValues,
  offeringDetail,
}: InitialFilingValuesParameters<RegulatoryFilings_NoiFilingPartsFragment> = {}): NOIFilingFormValues => {
  if (filing) {
    return mapFilingToFormValues(filing);
  }
  return {
    ...createInitialCommonFilingValues({ underwriter, lastCommonValues, offeringDetail }),
    activities: [createActivity()],
    additionalInformation: '',
  };
};
