import { useCheckAccountTraits } from '@cmg/auth';

import { useFeatureToggles } from '../../../../common/config';
import { OfferingStatus } from '../../../../graphql';
import { useCalendarPublishedOfferings } from '../../components/calendar-grid/hooks/useCalendarPublishedOfferings';
import { useCalendarAccess } from '../../hooks/useCalendarAccess';
import { useCalendar_LiveCalendarOfferingsListQuery } from '../graphql';

/**
 * Controls logic for the Live Calendar Query
 */
export function useGetLiveCalendarOfferingsQuery() {
  /**
   * Calendar_LiveCalendarOfferingsList Query Logic:
   * Logic to control which resolvers are called in PublishedOfferingsResolver as follows:
   *
   * mdlIndications:
   * - BUY_SIDE=true
   * - has read/full:ioi permission
   *
   * mdlAllocation:
   * - BUY_SIDE=true
   * - has read/full:ioi permission
   *
   * myInstitutionalIndication:
   * - DOES NOT HAVE read/full:ioi permissions
   * - BUY_SIDE=true
   * - has read/full:institutional_indication permission
   * - InvestorRelationship (fetched in GraphQL resolver) -> hasRelationshipWithActiveBookrunner=true
   */
  const {
    allowBooksCloseAt,
    allowFinalAllocation,
    allowMDLIndications,
    allowMDLAllocation,
    allowMyInstitutionalIndication,
  } = useCalendarAccess({
    enableSecondarySources: true,
  });

  const includeTestOfferings = useCheckAccountTraits(['XC_TEST_OFFERINGS_IN_CALENDAR']);
  const { isCalendarUsingOfferingFull } = useFeatureToggles();

  const offeringFullQuery = useCalendarPublishedOfferings({
    skip: !isCalendarUsingOfferingFull,
    where: {
      status: { eq: OfferingStatus.Live },
    },
  });

  const { data, ...offeringFullQueryRest } = offeringFullQuery;

  const remappedOfferingFullQuery = {
    data: {
      livePublishedOfferings: {
        data: data?.publishedOfferings?.items,
      },
    },
    ...offeringFullQueryRest,
  };

  const offeringListQuery = useCalendar_LiveCalendarOfferingsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      allowBooksCloseAt,
      allowFinalAllocation,
      allowMDLIndications,
      allowMDLAllocation,
      allowMyInstitutionalIndication,
      includeTestOfferings,
    },
    skip: isCalendarUsingOfferingFull,
  });

  if (remappedOfferingFullQuery.called) {
    return remappedOfferingFullQuery;
  }

  return offeringListQuery;
}
