import { LinkButton, ServiceErrorBanner } from '@cmg/common';
import {
  Alert,
  Box,
  Button,
  Divider,
  LoadingButton,
  MenuItem,
  PageHeader,
  PageLayout,
  Select,
  SnackbarManager,
  Stack,
  Typography,
} from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import {
  OfferingStage,
  OfferingStatus,
  ServiceErrorCode,
} from '../../../../../graphql/__generated__';
import { useOfferingSetup_Summary_AvailableShareDraftManagersQuery } from '../../../graphql';
import ActionsDropdownButton from '../../../summary/components/actions-dropdown-button/ActionsDropdownButton';
import { PublishOfferingConfirmationDialog } from '../../../summary/components/PublishOfferingConfirmationDialog';
import ShareOfferingDraftButton from '../../../summary/components/ShareOfferingDraftButton';
import DiscardOfferingModal from '../../../summary/discard-offering-modal/DiscardOfferingModal';
import { useDiscardOfferingMutation } from '../../../summary/hooks/useDiscardOfferingMutation';
import { useGetLastShareHistory } from '../../../summary/hooks/useGetLastShareHistory';
import { usePublishOfferingMutation } from '../../../summary/hooks/usePublishOfferingMutation';
import { useShareDraftOfferingMutation } from '../../../summary/hooks/useShareOfferingDraftMutation';
import { useUpdateOfferingStatusMutation } from '../../../summary/hooks/useUpdateOfferingStatusMutation';
import { generateOfferingStatusOptions } from '../../../summary/OfferingSummaryRoute.model';
import ShareOfferingDraftModal from '../../../summary/share-offering-draft-modal/ShareOfferingDraftModal';
import { useValidateOffering } from '../../../validation/hooks/useValidateOffering';
import ValidationErrorsAlert from '../validation-errors/ValidationErrorsAlert';

export type Props = {
  offeringId: string;
  stage: OfferingStage;
  status: OfferingStatus;
  publishedStatus?: OfferingStatus;
  issuerName: string;
  isDiscarded: boolean;
  version: string;
  hasUnpublishedChanges: boolean;
};

const OfferingSetupHeader: React.FC<Props> = ({
  children,
  offeringId,
  stage,
  status,
  publishedStatus,
  issuerName,
  isDiscarded,
  version,
  hasUnpublishedChanges,
}) => {
  const [publishOffering, { loading: publishOfferingLoading, serviceError: publishServiceError }] =
    usePublishOfferingMutation();
  const [discardOffering, { loading: discardOfferingLoading, serviceError: discardServiceError }] =
    useDiscardOfferingMutation();
  const [
    updateOfferingStatus,
    { loading: updateStatusLoading, serviceError: updateOfferingStatusServiceError },
  ] = useUpdateOfferingStatusMutation();

  const [shareDraft, { loading: loadingShareDraft }] = useShareDraftOfferingMutation();
  const { lastShareHistoryData } = useGetLastShareHistory({
    offeringId,
    offeringVersion: version,
  });
  const { data: availableManagersData } = useOfferingSetup_Summary_AvailableShareDraftManagersQuery(
    {
      variables: { offeringId: offeringId },
    }
  );

  const [isPublishModalOpen, setIsPublishModalOpen] = React.useState<boolean>(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = React.useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState<boolean>(false);

  const { push } = useHistory();

  const {
    validate,
    validating,
    setShouldValidate,
    validationErrorsByCategory,
    validationErrors,
    revalidate,
  } = useValidateOffering(offeringId);

  const shareDraftManagers = availableManagersData?.availableShareDraftManagers ?? [];
  const isShareable = stage === OfferingStage.Draft;
  const isDiscardable = stage === OfferingStage.Draft || stage === OfferingStage.Published;
  const isPublishButtonLoading = validating || publishOfferingLoading;
  const isPublishButtonDisabled =
    isPublishButtonLoading || (validationErrors && validationErrors.length > 0);
  const hasManagersToShare = shareDraftManagers?.length > 0;
  const disableShareDraft = !hasManagersToShare;
  const serviceError = updateOfferingStatusServiceError || discardServiceError;
  const displayValidationAlert =
    validationErrorsByCategory && Object.keys(validationErrorsByCategory).length > 0;
  const displayRepublishAlert = hasUnpublishedChanges && !displayValidationAlert;

  const handlePublishOffering = React.useCallback(async () => {
    setIsPublishModalOpen(false);

    try {
      const { data: publishOfferingData } = await publishOffering({ variables: { offeringId } });

      if (publishOfferingData?.publishOffering.__typename !== 'ServiceError') {
        SnackbarManager.success('Offering successfully sent for publishing!');
      }
    } catch {
      SnackbarManager.error('Failed to publish offering.');
    }
  }, [publishOffering, offeringId]);

  const handlePublishPrompt = React.useCallback(async () => {
    // validate every other screen in offering setup because publish button was clicked
    setShouldValidate && setShouldValidate(true);

    // await validation
    const errors = await validate();

    // if validation passes with no errors, pop a modal to confirm publishing
    if (!errors || !errors.length) {
      setIsPublishModalOpen(true);
    }
  }, [validate, setShouldValidate]);

  const handleDiscardOffering = React.useCallback(async () => {
    try {
      const { data: discardOfferingData } = await discardOffering({
        variables: {
          offeringId,
        },
      });

      if (discardOfferingData?.discardOffering.__typename === 'Offering') {
        SnackbarManager.success('Successfully discarded the Offering!');

        // offering draft was DISCARDED
        if (discardOfferingData?.discardOffering.stage === OfferingStage.Discarded) {
          push(routeFactory.myOfferingsDrafts.getUrlPath());
        }

        // published offering was discarded and is now ERRONEOUS
        if (discardOfferingData?.discardOffering.stage === OfferingStage.Erroneous) {
          push(routeFactory.myOfferings.getUrlPath());
        }
      }
    } catch {
      SnackbarManager.error('Failed to discard the Offering');
    }
  }, [discardOffering, offeringId, push]);

  const handleShareDraft = React.useCallback(
    async (managerKeys: string[]) => {
      if (!managerKeys.length) {
        return;
      }
      try {
        await shareDraft({ variables: { offeringId, managerKeys } });
        SnackbarManager.success('Successfully sent offering for share');
      } catch (error) {
        SnackbarManager.error('Failed to share offering');
      }
    },
    [shareDraft, offeringId]
  );

  const handleUpdateOfferingStatus = React.useCallback(
    async (value: OfferingStatus) => {
      try {
        const { data: updateOfferingStatusData } = await updateOfferingStatus({
          variables: { offeringId, status: value },
        });
        revalidate();
        if (updateOfferingStatusData?.updateOfferingStatus.__typename !== 'ServiceError') {
          SnackbarManager.success('Successfully updated the offering status!');
        }
      } catch {
        SnackbarManager.error('Failed to update offering status.');
      }
    },
    [offeringId, updateOfferingStatus, revalidate]
  );

  const actions = React.useMemo(
    () => [
      <Box display="flex" key="status-select" alignItems="center" gap={2} px={1}>
        <Typography variant="highlight2" color="text.secondary">
          Offering Status*
        </Typography>
        <Box width={130}>
          <Select
            sx={{ height: theme => theme.spacing(4) }}
            data-testid={xcSelectors.offeringSetupSummaryStatusSelect.testId}
            value={status}
            onChange={e => handleUpdateOfferingStatus(e.target.value as OfferingStatus)}
            disabled={updateStatusLoading}
          >
            {generateOfferingStatusOptions({ publishedStatus, stage }).map(option => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box height={theme => theme.spacing(3)}>
          <Divider orientation="vertical" />
        </Box>
      </Box>,
      ...(isDiscarded
        ? [
            <Button key="discarded" disabled>
              Discarded
            </Button>,
          ]
        : []),
      ...(!isDiscarded && isShareable
        ? [
            <ShareOfferingDraftButton
              key="share-draft"
              onClick={() => setIsShareModalOpen(true)}
              offeringId={offeringId}
              disabled={disableShareDraft}
            />,
          ]
        : []),
      ...(!isDiscarded
        ? [
            <LoadingButton
              key="publish-offering"
              variant="contained"
              color="primary"
              data-testid={xcSelectors.offeringSetupSummaryDiscardButton.testId}
              loading={isPublishButtonLoading}
              onClick={handlePublishPrompt}
              disabled={isPublishButtonDisabled}
            >
              {publishOfferingLoading ? 'Publishing Offering...' : 'Publish Offering'}
            </LoadingButton>,
            <ActionsDropdownButton
              key="action-dropdown"
              disabled={discardOfferingLoading}
              discardOfferingDisabled={!isDiscardable}
              onClickDiscardOffering={() => setIsDiscardModalOpen(true)}
            />,
          ]
        : []),
    ],
    [
      status,
      updateStatusLoading,
      publishedStatus,
      stage,
      isDiscarded,
      isShareable,
      offeringId,
      disableShareDraft,
      isPublishButtonLoading,
      handlePublishPrompt,
      isPublishButtonDisabled,
      publishOfferingLoading,
      discardOfferingLoading,
      isDiscardable,
      handleUpdateOfferingStatus,
    ]
  );

  return (
    <PageLayout
      header={
        <React.Fragment>
          <PageHeader pageTitle="Offering Details" actions={actions} />
          <Divider />
        </React.Fragment>
      }
    >
      <Stack spacing={2} px={4} py={2}>
        {serviceError && <ServiceErrorBanner error={serviceError} />}
        {publishServiceError && (
          <React.Fragment>
            {publishServiceError.code === ServiceErrorCode.NotFound ? (
              <Alert
                severity="warning"
                action={<LinkButton onClick={() => window.location.reload()}>Reload</LinkButton>}
              >
                {publishServiceError.message}
              </Alert>
            ) : (
              <Alert severity="error">{publishServiceError.message}</Alert>
            )}
          </React.Fragment>
        )}
        {displayValidationAlert && (
          <ValidationErrorsAlert offeringId={offeringId} errors={validationErrorsByCategory} />
        )}
        {displayRepublishAlert && (
          <Alert severity="warning">
            This Offering has been published, but it has new changes. Please re-publish again.
          </Alert>
        )}
      </Stack>
      {children}
      <DiscardOfferingModal
        open={isDiscardModalOpen}
        firmName={issuerName}
        offeringStage={stage}
        onSubmit={handleDiscardOffering}
        onHide={() => setIsDiscardModalOpen(false)}
      />
      <PublishOfferingConfirmationDialog
        open={isPublishModalOpen}
        handleClose={() => setIsPublishModalOpen(false)}
        onSubmit={handlePublishOffering}
        name={issuerName}
        status={status}
      />
      {isShareModalOpen && (
        <ShareOfferingDraftModal
          lastShareHistoryData={lastShareHistoryData}
          onHide={() => setIsShareModalOpen(false)}
          onSubmit={handleShareDraft}
          managers={shareDraftManagers}
          loadingShareDraft={loadingShareDraft}
        />
      )}
    </PageLayout>
  );
};

export default OfferingSetupHeader;
