import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';
import { dtcSchema } from '../manager-bd/ManagerBdWireForm.model';

export const recipientUserDataValidationSchema = yup
  .object()
  .shape({
    recipient: yup.string().label('Recipient').required(),
    payment: yup.number().nullable().label('Payment').min(0).required(),
    ipoPenaltyTracking: yup.bool().nullable().label('IPO Penalty Tracking'),
    sellingConcession: yup.number().nullable().label('Selling Concession').min(0).required(),
  })
  .nullable();

export const validationSchema = yup
  .object()
  .shape({
    dtcNumber: dtcSchema.label('Sender DTC Number').required(),
    recipientsUserData: yup
      .array()
      .of(recipientUserDataValidationSchema)
      .label('Recipients')
      .min(1)
      .required()
      .test('non-nullable-data', 'At least one manager must have user data', value => {
        return !!value?.some(data => data !== null);
      }),
  })
  .concat(wiresFormDisclaimerSchema);

export function getGridTemplateColumns() {
  const numberOfCols = 4;
  const columnDefs = new Array(numberOfCols).fill('minmax(190px, auto)');

  return {
    gridTemplateColumns: columnDefs.join(' '),
    numberOfCols,
  };
}

export type NonSyndicatePaymentRecipientUserData = {
  recipient: string;
  payment: number | null;
  ipoPenaltyTracking: boolean;
  sellingConcession: number | null;
};

export type Values = {
  disclaimer: string | null;
  dtcNumber: string | null;
  recipientsUserData: (NonSyndicatePaymentRecipientUserData | null)[];
};
