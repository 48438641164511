import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SManagerGroupHeader = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 8px;
  display: flex;
`;

export const SGroupHeaderContent = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${({ disabled }) =>
    !disabled &&
    `:hover {
      cursor: pointer;
    }`}
`;

export const SManagerItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  justify-content: space-between;
`;

export const SShareIndicator = styled(Icon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.color.green1};
`;

export const SShareIndicationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const SCheckboxItemContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${({ disabled }) =>
    !disabled &&
    `:hover {
      cursor: pointer;
    }`}
`;
