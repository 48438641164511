import type { DeepWritable } from '@cmg/common';

import { SyndicateWires_CustomVariablesQuery } from '../../../../graphql';

export function filterCustomVariables(
  customVariables: SyndicateWires_CustomVariablesQuery['syndicateWires']['customVariables'],
  searchText: string
) {
  const searchTextLowerCase = searchText.toLowerCase();
  return customVariables.reduce<
    DeepWritable<SyndicateWires_CustomVariablesQuery['syndicateWires']['customVariables']>
  >((prev, curr) => {
    const variables = curr.variables.filter(item =>
      item.displayName.toLowerCase().includes(searchTextLowerCase)
    );
    if (variables.length > 0) {
      prev.push({ ...curr, ...{ variables } });
    }
    return prev;
  }, []) as SyndicateWires_CustomVariablesQuery['syndicateWires']['customVariables'];
}
