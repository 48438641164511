import styled, { css } from 'styled-components/macro';

export const SStatusIconWrapper = styled.span`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 36px;
  height: 36px;

  /* line before and after each icon */
  &::before,
  &::after {
    content: '';
    border: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
    opacity: 1;
    position: absolute;
    height: 14px;
    top: 0;
  }

  &::before {
    top: -14px;
  }

  &::after {
    top: 100%;
  }
`;

export const SStatusIcon = styled.div<{
  status?: 'complete' | 'warning' | 'invalid';
  active?: boolean;
}>`
  display: inline-block;
  transition: transform 0.3s;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.tiny};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${({ status, theme }) =>
    status === 'invalid' &&
    css`
      color: ${theme.text.color.negative};
      background: ${theme.background.color.white};
      font-size: ${theme.text.size.medium};
    `};

  ${({ status, theme }) =>
    status === 'warning' &&
    css`
      color: ${theme.text.color.warning};
      background: ${theme.background.color.white};
      font-size: ${theme.text.size.medium};
    `};

  ${({ status, theme }) =>
    status === 'complete' &&
    css`
      color: ${theme.brand.color.success};
    `};

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.text.color.link};
    `};
`;

export const SText = styled.div<{ active?: boolean; disabled?: boolean }>`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme, active }) =>
    active ? theme.text.weight.medium : theme.text.weight.regular};
  color: ${({ theme, disabled }) => (disabled ? theme.text.color.light : theme.text.color.dark)};
`;

export const SStep = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};

  &:first-child {
    ${SStatusIconWrapper} {
      &::before {
        display: none;
      }
    }
  }

  &:last-child {
    ${SStatusIconWrapper} {
      &::after {
        display: none;
      }
    }
  }
`;
