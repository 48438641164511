import { apiTypes, CMGLogo, ServiceErrorBanner, timeUtil, urlUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import routeFactory from '../../../../common/util/routeFactory';
import { InvitationWireResponseInput, SentWireResponseStatus } from '../../../../graphql';
import { DeclineInvitationPanel } from './decline-invitation-panel/DeclineInvitationPanel';
import {
  useSyndicateWires_AcceptInvitationWireMutation,
  useSyndicateWires_DeclineInvitationWireMutation,
  useSyndicateWires_SentInvitationWireQuery,
} from './graphql';
import InvitationResponsePanel from './invitation-response-panel/InvitationResponsePanel';
import { SPre, STimeStamp, StyledFlexContainer } from './InvitationWireResponseRoute.styles';
import { LegalAgreementPanel } from './legal-agreement-panel/LegalAgreementPanel';
import { NewVersionPanel } from './new-version-panel/NewVersionPanel';

type Props = RouteComponentProps<{ sentWireId: string }>;

/**
 * Public route for responding to invitation wires available to non CMG users
 */
export const InvitationWireResponseRoute: React.FC<Props> = ({ location, match, history }) => {
  const { sentWireId } = match.params;
  const { action, token } = urlUtil.queryParse<{ action: string; token: string }>(location.search);
  const [initialStatus, setInitialStatus] = React.useState<SentWireResponseStatus | null>();

  const invitationMutationBody: InvitationWireResponseInput = {
    decisionTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (!token || !sentWireId || !['decline', 'confirm'].includes(action)) {
    history.push(routeFactory.error.getUrlPath({ errorCode: apiTypes.ServiceErrorCode.NOT_FOUND }));
  }

  const {
    loading: fetching,
    data,
    error: fetchError,
  } = useSyndicateWires_SentInvitationWireQuery({
    variables: { sentWireId },
  });

  const [acceptWire, { loading: accepting, data: acceptData }] =
    useSyndicateWires_AcceptInvitationWireMutation({
      variables: { sentWireId, token, body: invitationMutationBody },
    });

  const [declineWire, { loading: declining, data: declineData }] =
    useSyndicateWires_DeclineInvitationWireMutation({
      variables: {
        sentWireId,
        token,
        body: invitationMutationBody,
      },
    });

  const sentWire = data?.public.syndicateWires.sentWire;

  const handleConfirmWire = async () => {
    try {
      await acceptWire();
    } catch (error) {
      history.push(
        routeFactory.error.getUrlPath({ errorCode: apiTypes.ServiceErrorCode.UNHANDLED_ERROR })
      );
    }
  };

  const handleDeclineWire = async () => {
    try {
      await declineWire();
    } catch (error) {
      history.push(
        routeFactory.error.getUrlPath({ errorCode: apiTypes.ServiceErrorCode.UNHANDLED_ERROR })
      );
    }
  };

  React.useEffect(() => {
    if (sentWire && !initialStatus) {
      setInitialStatus(sentWire.responseStatus);
    }
  }, [initialStatus, sentWire]);

  if (fetchError) {
    history.push(routeFactory.error.getUrlPath({ errorCode: apiTypes.ServiceErrorCode.NOT_FOUND }));
  }

  const htmlContent = { __html: sentWire?.legalAgreement ?? '' };
  const isLoading = fetching || declining || accepting;
  const isExpired = !isNil(sentWire?.expiredAt);
  const canRespond = sentWire?.responseStatus === SentWireResponseStatus.Pending && !isExpired;

  return (
    <StyledFlexContainer direction="column" gap={100}>
      {!isLoading && (
        // We only show the logo after the loading is done to prevent it jumping around
        <CMGLogo />
      )}

      {isLoading && <Loading />}
      {sentWire && !isLoading && (
        <div data-test-id="content" aria-label="Wire Content">
          {/* Review legal agreement before confirming/declining the wire */}
          {canRespond && action === 'confirm' && (
            <LegalAgreementPanel
              onConfirm={handleConfirmWire}
              onCancel={() =>
                history.push({
                  search: urlUtil.queryStringify({ action: 'decline', token }),
                  pathname: routeFactory.syndicateWiresInvitationWireResponse.getUrlPath({
                    sentWireId,
                  }),
                })
              }
            >
              {acceptData?.acceptWire.__typename === 'ServiceError' && (
                <ServiceErrorBanner error={acceptData.acceptWire} />
              )}
              <SPre dangerouslySetInnerHTML={htmlContent} />
            </LegalAgreementPanel>
          )}

          {/* Confirm decline action on pending wire */}
          {canRespond && action === 'decline' && (
            <DeclineInvitationPanel
              onConfirm={handleDeclineWire}
              onCancel={() =>
                history.push({
                  search: urlUtil.queryStringify({ action: 'confirm', token }),
                  pathname: routeFactory.syndicateWiresInvitationWireResponse.getUrlPath({
                    sentWireId,
                  }),
                })
              }
            >
              {declineData?.declineWire.__typename === 'ServiceError' && (
                <ServiceErrorBanner error={declineData.declineWire} />
              )}
              <p>
                Are you sure you want to decline the invitation wire? This action cannot be
                reverted.
              </p>
            </DeclineInvitationPanel>
          )}

          {/* Pending wire was successfully declined */}
          {sentWire?.responseStatus === SentWireResponseStatus.Declined &&
            initialStatus === SentWireResponseStatus.Pending && (
              <InvitationResponsePanel variant="error" title="Invitation wire was declined.">
                <STimeStamp>
                  Declined on {timeUtil.formatAsDisplayDateTime(sentWire.respondedAt!)}
                </STimeStamp>
              </InvitationResponsePanel>
            )}

          {/* Pending wire was successfully confirmed */}
          {sentWire?.responseStatus === SentWireResponseStatus.Accepted &&
            initialStatus === SentWireResponseStatus.Pending && (
              <InvitationResponsePanel
                variant="success"
                title="Thanks for confirming the invitation!"
              >
                <STimeStamp>
                  Confirmed on {timeUtil.formatAsDisplayDateTime(sentWire.respondedAt!)}
                </STimeStamp>
              </InvitationResponsePanel>
            )}

          {/* Wire was already confirmed in the past */}
          {initialStatus === SentWireResponseStatus.Accepted && (
            <InvitationResponsePanel variant="info" title="This wire was already accepted!">
              <STimeStamp>
                Accepted on {timeUtil.formatAsDisplayDateTime(sentWire.respondedAt!)}
              </STimeStamp>
            </InvitationResponsePanel>
          )}

          {/* Wire was already declined in the past */}
          {initialStatus === SentWireResponseStatus.Declined && (
            <InvitationResponsePanel variant="info" title="This wire was already declined!">
              <STimeStamp>
                Declined on {timeUtil.formatAsDisplayDateTime(sentWire.respondedAt!)}
              </STimeStamp>
            </InvitationResponsePanel>
          )}

          {/* There is a newer version of the wire */}
          {sentWire?.responseStatus === SentWireResponseStatus.Obsolete && (
            <NewVersionPanel senderEmails={sentWire.senderEmails} />
          )}

          {sentWire?.expiredAt && (
            <InvitationResponsePanel variant="error" title="This invitation has expired">
              <STimeStamp>
                Expired on {timeUtil.formatAsDisplayDateTime(sentWire.expiredAt)}
              </STimeStamp>
            </InvitationResponsePanel>
          )}
        </div>
      )}
    </StyledFlexContainer>
  );
};
