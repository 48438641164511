import { Icon } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

export type Props = {
  type?: 'warning' | 'error';
};
export const WarningIcon: React.FC<Props> = ({ type = 'warning' }) => {
  const theme = useTheme();

  const { color } = React.useMemo(
    () => ({
      color: type === 'error' ? theme.text.color.error : theme.text.color.warning,
    }),
    [type, theme]
  );

  return <Icon name="exclamation-triangle" variant="solid" color={color} size="lg" />;
};
