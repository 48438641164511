import { numericUtil, ODSTable as Table, UUID } from '@cmg/common';

import { InfoBox } from '../../../../../common/components/info-box/InfoBox';
import routeFactory from '../../../../../common/util/routeFactory';
import { stepIds } from '../../../constants';
import { SDivider } from '../../ManagerEconomicsRoute.styles';

type Props = {
  offeringId: UUID;
  baseGrossSpread?: number | null;
  incentiveFee?: number | null;
  pricingCurrencyCode?: string;
};

export const UnderwritingDiscountCard: React.FC<Props> = ({
  offeringId,
  baseGrossSpread,
  incentiveFee,
  pricingCurrencyCode,
}) => {
  const totalGrossSpread = numericUtil.getDisplayValueForCurrency(
    (baseGrossSpread ?? 0) + (incentiveFee ?? 0),
    6,
    pricingCurrencyCode
  );

  return (
    <InfoBox
      gridTemplateColumns="3fr 15px 1fr"
      sourceOfData={{
        text: 'Offering Setup: U/W Discounts & Fees',
        link: routeFactory.offeringSetup.getUrlPath({
          stepId: stepIds.UW_DISCOUNTS_FEES,
          offeringId,
        }),
      }}
    >
      <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
        Underwriting Discount
      </Table.Cell>
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Base Gross Spread
      </Table.Cell>
      <Table.Cell
        data-test-id="base-gross-spread"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForCurrency(baseGrossSpread, 6, pricingCurrencyCode)}
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Incentive Fee
      </Table.Cell>
      <Table.Cell paddingSize="S" bold withBottomBorder={false}>
        +
      </Table.Cell>
      <Table.Cell
        data-test-id="incentive-fee"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForCurrency(incentiveFee, 6, pricingCurrencyCode)}
      </Table.Cell>
      <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
        <SDivider />
      </Table.Cell>
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Total Gross Spread
      </Table.Cell>
      <Table.Cell
        data-test-id="total-gross-spread"
        paddingSize="S"
        bold
        align="right"
        withBottomBorder={false}
      >
        {totalGrossSpread}
      </Table.Cell>
    </InfoBox>
  );
};
