import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import {
  MdlAllocationIoiType,
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
} from '../../../../../../graphql';
import ConfirmDeleteAllocationDataModal from '../../../../components/confirm-delete-allocation-modal/ConfirmDeleteAllocationDataModal';
import FirmLevelDemandForm from './components/firm-level-demand-form/FirmLevelDemandForm';
import { FirmLevelDemandValues } from './components/firm-level-demand-form/FirmLevelDemandForm.model';
import {
  useFirmLevelDemand_ActivateInternalDemandOfferingMutation,
  useFirmLevelDemand_InternalAllocationDemandQuery,
  useFirmLevelDemand_InternalIndicationsDemandQuery,
  useFirmLevelDemand_PassInternalDemandOfferingMutation,
  useFirmLevelDemand_PublishedOfferingTypeQuery,
  useFirmLevelDemand_RegMInternalDemandOfferingMutation,
  useFirmLevelDemand_UpsertInternalDemandIndicationsMutation,
} from './graphql';

export type Props = {
  offeringId: UUID;
};

const FirmLevelDemand: React.FC<Props> = ({ offeringId }) => {
  const canRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [openConfirmDeleteAllocation, setOpenConfirmDeleteAllocation] =
    React.useState<boolean>(false);
  const [newIoiType, setNewIoiType] = React.useState<MdlAllocationIoiType | null>(null);

  const {
    loading: loadingOfferingType,
    data: dataOfferingType,
    error: errorOfferingType,
  } = useFirmLevelDemand_PublishedOfferingTypeQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const {
    loading: loadingIndication,
    data: dataIndication,
    error: errorIndication,
  } = useFirmLevelDemand_InternalIndicationsDemandQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const {
    loading: loadingAllocation,
    data: dataAllocation,
    error: errorAllocation,
  } = useFirmLevelDemand_InternalAllocationDemandQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const [upsertInternalDemandIndication, { loading: loadingUpsertInternalDemandIndication }] =
    useFirmLevelDemand_UpsertInternalDemandIndicationsMutation();

  const [
    passInternalDemandOffering,
    { loading: loadingPassInternalDemandOffering, error: errorPassInternalDemandOffering },
  ] = useFirmLevelDemand_PassInternalDemandOfferingMutation({
    onCompleted: () => {
      ToastManager.success('Successfully updated Internal Demand');
      setIsEditing(false);
      setOpenConfirmDeleteAllocation(false);
    },
  });

  const [
    activateInternalDemandOffering,
    { loading: loadingActivateOffPlatform, error: errorActivateOffPlatform },
  ] = useFirmLevelDemand_ActivateInternalDemandOfferingMutation({
    onCompleted: () => {
      ToastManager.success('Successfully updated Internal Demand');
      setIsEditing(false);
      setOpenConfirmDeleteAllocation(false);
    },
  });

  const [
    regMInternalDemandOffering,
    { loading: loadingRegMInternalDemandOffering, error: errorRegMInternalDemandOffering },
  ] = useFirmLevelDemand_RegMInternalDemandOfferingMutation({
    onCompleted: () => {
      ToastManager.success('Successfully updated Internal Demand');
      setIsEditing(false);
      setOpenConfirmDeleteAllocation(false);
    },
  });

  const handleSubmitForm = async (
    values: FirmLevelDemandValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  ) => {
    const mapped = values.interestLevels.map(level => ({
      ...level,
      limitType: level.limitType ?? MdlIndicationLimitType.Null,
    }));

    try {
      await upsertInternalDemandIndication({
        variables: {
          offeringId,
          input: {
            indications: mapped,
          },
        },
      });

      ToastManager.success('Successfully updated Internal Demand');
      setIsEditing(false);
    } catch {
      ToastManager.error('Failed to update indications and allocation information');
    }
  };

  const loading =
    loadingIndication ||
    loadingUpsertInternalDemandIndication ||
    loadingPassInternalDemandOffering ||
    loadingActivateOffPlatform ||
    loadingRegMInternalDemandOffering ||
    loadingAllocation ||
    loadingOfferingType;

  const error =
    errorIndication ||
    errorPassInternalDemandOffering ||
    errorActivateOffPlatform ||
    errorRegMInternalDemandOffering ||
    errorAllocation ||
    errorOfferingType;

  const indications = dataIndication?.internalIndicationsDemand?.indications;
  const allocation = dataAllocation?.internalAllocationDemand?.allocation;
  const shares = allocation?.shares;
  const offering = dataOfferingType?.limitedPublishedOffering;

  const handleChangeIoiType = (ioiType: MdlAllocationIoiType) => {
    switch (ioiType) {
      case MdlAllocationIoiType.Pass:
        passInternalDemandOffering({
          variables: {
            offeringId,
          },
        });
        break;

      case MdlAllocationIoiType.RegM:
        regMInternalDemandOffering({
          variables: {
            offeringId,
          },
        });
        break;

      default:
        activateInternalDemandOffering({
          variables: {
            offeringId,
          },
        });
        break;
    }
  };

  const onChangeIoiType = (ioiType: MdlAllocationIoiType) => {
    setNewIoiType(ioiType);
    if (shares) {
      setOpenConfirmDeleteAllocation(true);
    } else {
      handleChangeIoiType(ioiType);
    }
  };

  const onConfirmDeleteAllocationModal = () => {
    if (newIoiType) {
      handleChangeIoiType(newIoiType);
    }
  };

  const hideConfirmDeleteAllocationModal = () => {
    setOpenConfirmDeleteAllocation(false);
  };

  const onChangeEditing = () => {
    setIsEditing(editing => !editing);
  };

  if (!canRead) {
    return null;
  }

  return (
    <div data-test-id={xcSelectors.firmLevelDemandScreen.testId}>
      {error && <ServerErrorsBanner error={error} />}
      {indications && offering && (
        <React.Fragment>
          <FirmLevelDemandForm
            indications={indications}
            allocation={allocation}
            onSubmit={handleSubmitForm}
            isEditing={isEditing}
            onChangeEditing={onChangeEditing}
            onChangeIoiType={onChangeIoiType}
            loading={loading}
            offeringType={offering.type}
            isExecutingOnPlatform={offering.isExecutingOnPlatform}
          />
          <ConfirmDeleteAllocationDataModal
            show={openConfirmDeleteAllocation}
            onConfirm={onConfirmDeleteAllocationModal}
            onHide={hideConfirmDeleteAllocationModal}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default FirmLevelDemand;
