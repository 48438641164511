import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from './../../../common/graphql';

export function sortRoadshowDates(
  a: Roadshows_RoadshowPartsFragment['roadshowDays'][number],
  b: Roadshows_RoadshowPartsFragment['roadshowDays'][number]
) {
  return new Date(a.date).getTime() - new Date(b.date).getTime();
}

export function sortEvents(
  a: Roadshows_RoadshowEventPartsFragment,
  b: Roadshows_RoadshowEventPartsFragment
) {
  if (a.startTime !== b.startTime) {
    return a.startTime.localeCompare(b.startTime);
  }
  if (a.endTime !== b.endTime) {
    return a.endTime.localeCompare(b.endTime);
  }
  return 0;
}

export function uniqueLocations(
  value: { location: string | null | undefined },
  index,
  self: { location: string | null | undefined }[]
) {
  const allLocations = self.map(meeting => meeting.location);
  return allLocations.indexOf(value.location) === index;
}

export function groupMeetingsByLocations(
  eventsDuringDay: Roadshows_RoadshowEventPartsFragment[],
  day: Roadshows_RoadshowPartsFragment['roadshowDays'][number]
) {
  const locations = eventsDuringDay
    .map(meeting => ({ location: meeting.location }))
    .filter(uniqueLocations)
    .map(({ location }) => ({
      /* when there is no location the event is virtual, in that case the time zone is not from city but from assigned day timezone */
      locationName: location || 'Virtual',
      locationTimezone: day.cities.find(({ name }) => name === location)?.timeZone ?? day.timeZone!,
      meetings: eventsDuringDay.filter(meeting => meeting.location === location),
    }));

  return {
    date: day.date,
    locations,
  };
}

export function groupEventsByDaysAndLocations(
  roadshowDays: Roadshows_RoadshowPartsFragment['roadshowDays'] = [],
  allEvents: readonly Roadshows_RoadshowEventPartsFragment[] = []
) {
  const sortedDays = [...roadshowDays];
  sortedDays.sort(sortRoadshowDates);

  const sortedEvents = [...allEvents];
  sortedEvents.sort(sortEvents);

  return sortedDays
    .map(day => {
      const eventsDuringDay = sortedEvents.filter(event => event.startDate === day.date);
      return groupMeetingsByLocations(eventsDuringDay, day);
    })
    .filter(day => day.locations.length > 0);
}
