/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment = {
  readonly __typename?: 'Certificate';
  readonly certificateId: string;
  readonly certificateStatus: Types.AttestationFormStatus;
  readonly certificateType: Types.CertificateType;
  readonly expirationDate?: string | null;
  readonly signatoryDate: string;
};

export type CertificateLibrary_FetchCertificateByCmgEntityKey_FirmCertificatesFragment = {
  readonly __typename?: 'FirmCertificates';
  readonly firmName: string;
  readonly firmCmgEntityKey?: string | null;
  readonly hasRelationship: boolean;
  readonly bankInvestorKey?: string | null;
  readonly firmAddress?: string | null;
  readonly lastUpdate?: string | null;
  readonly certificates: ReadonlyArray<{
    readonly __typename?: 'Certificate';
    readonly certificateId: string;
    readonly certificateStatus: Types.AttestationFormStatus;
    readonly certificateType: Types.CertificateType;
    readonly expirationDate?: string | null;
    readonly signatoryDate: string;
  }>;
};

export type CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables = Types.Exact<{
  source: Types.FirmCertificateSource;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  crmInvestorId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type CertificateLibrary_FetchCertificateByCmgEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly firmCertificatesByCmgEntityKeyAndInvestorId?: {
    readonly __typename?: 'FirmCertificates';
    readonly firmName: string;
    readonly firmCmgEntityKey?: string | null;
    readonly hasRelationship: boolean;
    readonly bankInvestorKey?: string | null;
    readonly firmAddress?: string | null;
    readonly lastUpdate?: string | null;
    readonly certificates: ReadonlyArray<{
      readonly __typename?: 'Certificate';
      readonly certificateId: string;
      readonly certificateStatus: Types.AttestationFormStatus;
      readonly certificateType: Types.CertificateType;
      readonly expirationDate?: string | null;
      readonly signatoryDate: string;
    }>;
  } | null;
  readonly pastFirmCertificatesByCmgEntityKey: ReadonlyArray<{
    readonly __typename?: 'Certificate';
    readonly certificateId: string;
    readonly certificateStatus: Types.AttestationFormStatus;
    readonly certificateType: Types.CertificateType;
    readonly expirationDate?: string | null;
    readonly signatoryDate: string;
  }>;
};

export type CertificateLibrary_FetchCertificates_CertificateFragment = {
  readonly __typename?: 'Certificate';
  readonly certificateId: string;
  readonly certificateStatus: Types.AttestationFormStatus;
  readonly certificateType: Types.CertificateType;
  readonly expirationDate?: string | null;
};

export type CertificateLibrary_FetchCertificates_FirmCertificatesFragment = {
  readonly __typename?: 'FirmCertificates';
  readonly firmName: string;
  readonly firmCmgEntityKey?: string | null;
  readonly hasRelationship: boolean;
  readonly bankInvestorKey?: string | null;
  readonly crmInvestorId?: string | null;
  readonly firmAddress?: string | null;
  readonly lastUpdate?: string | null;
  readonly certificates: ReadonlyArray<{
    readonly __typename?: 'Certificate';
    readonly certificateId: string;
    readonly certificateStatus: Types.AttestationFormStatus;
    readonly certificateType: Types.CertificateType;
    readonly expirationDate?: string | null;
  }>;
};

export type CertificateLibrary_FetchCertificatesQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<
    ReadonlyArray<Types.FirmCertificatesReadDtoSortInput> | Types.FirmCertificatesReadDtoSortInput
  >;
  source: Types.FirmCertificateSource;
  where?: Types.InputMaybe<Types.FirmCertificatesReadDtoFilterInput>;
}>;

export type CertificateLibrary_FetchCertificatesQuery = {
  readonly __typename?: 'Query';
  readonly firmsCertificates?: {
    readonly __typename?: 'FirmsCertificatesCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'FirmCertificates';
      readonly firmName: string;
      readonly firmCmgEntityKey?: string | null;
      readonly hasRelationship: boolean;
      readonly bankInvestorKey?: string | null;
      readonly crmInvestorId?: string | null;
      readonly firmAddress?: string | null;
      readonly lastUpdate?: string | null;
      readonly certificates: ReadonlyArray<{
        readonly __typename?: 'Certificate';
        readonly certificateId: string;
        readonly certificateStatus: Types.AttestationFormStatus;
        readonly certificateType: Types.CertificateType;
        readonly expirationDate?: string | null;
      }>;
    }> | null;
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly pageSize: number;
      readonly offset: number;
      readonly hasNextPage: boolean;
    };
  } | null;
};

export const CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CertificateLibrary_FetchCertificateByCmgEntityKey_FirmCertificatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_FirmCertificates',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificates' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasRelationship' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CertificateLibrary_FetchCertificates_CertificateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CertificateLibrary_FetchCertificates_FirmCertificatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_FirmCertificates' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificates' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasRelationship' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmInvestorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_Certificate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CertificateLibrary_FetchCertificateByCmgEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificateByCmgEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificateSource' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmInvestorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmCertificatesByCmgEntityKeyAndInvestorId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmCmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmInvestorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmInvestorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_FirmCertificates',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pastFirmCertificatesByCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_FirmCertificates',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificates' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasRelationship' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CertificateLibrary_FetchCertificateByCmgEntityKey_Certificate',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery__
 *
 * To run a query within a React component, call `useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery({
 *   variables: {
 *      source: // value for 'source'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      crmInvestorId: // value for 'crmInvestorId'
 *   },
 * });
 */
export function useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CertificateLibrary_FetchCertificateByCmgEntityKeyQuery,
    CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CertificateLibrary_FetchCertificateByCmgEntityKeyQuery,
    CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables
  >(CertificateLibrary_FetchCertificateByCmgEntityKeyDocument, options);
}
export function useCertificateLibrary_FetchCertificateByCmgEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CertificateLibrary_FetchCertificateByCmgEntityKeyQuery,
    CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CertificateLibrary_FetchCertificateByCmgEntityKeyQuery,
    CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables
  >(CertificateLibrary_FetchCertificateByCmgEntityKeyDocument, options);
}
export type CertificateLibrary_FetchCertificateByCmgEntityKeyQueryHookResult = ReturnType<
  typeof useCertificateLibrary_FetchCertificateByCmgEntityKeyQuery
>;
export type CertificateLibrary_FetchCertificateByCmgEntityKeyLazyQueryHookResult = ReturnType<
  typeof useCertificateLibrary_FetchCertificateByCmgEntityKeyLazyQuery
>;
export type CertificateLibrary_FetchCertificateByCmgEntityKeyQueryResult = Apollo.QueryResult<
  CertificateLibrary_FetchCertificateByCmgEntityKeyQuery,
  CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables
>;
export const CertificateLibrary_FetchCertificatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FirmCertificatesReadDtoSortInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificateSource' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FirmCertificatesReadDtoFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmsCertificates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'source' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CertificateLibrary_FetchCertificates_FirmCertificates',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_Certificate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Certificate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'certificateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'certificateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_FirmCertificates' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmCertificates' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasRelationship' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmInvestorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateLibrary_FetchCertificates_Certificate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCertificateLibrary_FetchCertificatesQuery__
 *
 * To run a query within a React component, call `useCertificateLibrary_FetchCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateLibrary_FetchCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateLibrary_FetchCertificatesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      source: // value for 'source'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCertificateLibrary_FetchCertificatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CertificateLibrary_FetchCertificatesQuery,
    CertificateLibrary_FetchCertificatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CertificateLibrary_FetchCertificatesQuery,
    CertificateLibrary_FetchCertificatesQueryVariables
  >(CertificateLibrary_FetchCertificatesDocument, options);
}
export function useCertificateLibrary_FetchCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CertificateLibrary_FetchCertificatesQuery,
    CertificateLibrary_FetchCertificatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CertificateLibrary_FetchCertificatesQuery,
    CertificateLibrary_FetchCertificatesQueryVariables
  >(CertificateLibrary_FetchCertificatesDocument, options);
}
export type CertificateLibrary_FetchCertificatesQueryHookResult = ReturnType<
  typeof useCertificateLibrary_FetchCertificatesQuery
>;
export type CertificateLibrary_FetchCertificatesLazyQueryHookResult = ReturnType<
  typeof useCertificateLibrary_FetchCertificatesLazyQuery
>;
export type CertificateLibrary_FetchCertificatesQueryResult = Apollo.QueryResult<
  CertificateLibrary_FetchCertificatesQuery,
  CertificateLibrary_FetchCertificatesQueryVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeCertificateLibrary_FetchCertificateByCmgEntityKeyMockResponse = makeMockResponse<
  CertificateLibrary_FetchCertificateByCmgEntityKeyQueryVariables,
  CertificateLibrary_FetchCertificateByCmgEntityKeyQuery
>(CertificateLibrary_FetchCertificateByCmgEntityKeyDocument);

export const makeCertificateLibrary_FetchCertificatesMockResponse = makeMockResponse<
  CertificateLibrary_FetchCertificatesQueryVariables,
  CertificateLibrary_FetchCertificatesQuery
>(CertificateLibrary_FetchCertificatesDocument);
