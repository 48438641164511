import { ProspectusErrorType, ProspectusInvestorErrorType } from '../../../../../graphql';
import { ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery } from './graphql';

export type ProspectusValidationErrors = Readonly<{
  hasValidationError: boolean;
  isProspectusDocumentMissing: boolean;
  isProspectusMissing: boolean;
  isIndicationUnacknowledged: boolean;
  isContactMissing: boolean;
}>;

export function getValidationErrors(
  cmgEntityKey: string | undefined,
  isSyndicatePersona: boolean,
  onSendValidationQuery:
    | ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery
    | undefined
): ProspectusValidationErrors {
  if (!onSendValidationQuery) {
    return {
      hasValidationError: false,
      isContactMissing: false,
      isIndicationUnacknowledged: false,
      isProspectusDocumentMissing: false,
      isProspectusMissing: false,
    };
  }

  const validation = isSyndicatePersona
    ? onSendValidationQuery.prospectusDelivery.singleOnSendValidationAsSyndicate
    : onSendValidationQuery.prospectusDelivery.singleOnSendValidationAsSalesAndTrading;

  const isProspectusDocumentMissing = !!validation?.prospectusErrors.some(
    x => x.errorType === ProspectusErrorType.ProspectusDocumentsMissing
  );

  const isProspectusMissing = !!validation?.prospectusErrors.some(
    x => x.errorType === ProspectusErrorType.ProspectusMissing
  );

  const isContactMissing = !!validation?.investorErrors.some(
    x =>
      x.investorKey === cmgEntityKey &&
      x.errorType === ProspectusInvestorErrorType.InvestorContactMissing
  );

  const isIndicationUnacknowledged = !!validation?.investorErrors.some(
    x =>
      x.investorKey === cmgEntityKey &&
      x.errorType === ProspectusInvestorErrorType.IndicationNotAcknowledged
  );

  return {
    hasValidationError:
      isContactMissing || isIndicationUnacknowledged || isProspectusDocumentMissing,
    isContactMissing,
    isIndicationUnacknowledged,
    isProspectusDocumentMissing,
    isProspectusMissing,
  };
}
