import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_SendTerminationWireMutation,
  SyndicateWires_TerminationWiresDocument,
  useSyndicateWires_SendTerminationWireMutation,
} from '../graphql';

export type SendTerminationWireMutation = SyndicateWires_SendTerminationWireMutation;

export const useSendTerminationWireMutation = () => {
  return useSyndicateWires_SendTerminationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_TerminationWiresDocument];
  return getManyOperationNames(documentNodes);
}
