import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_FreeFormWiresDocument,
  SyndicateWires_SendFreeFormWireMutation,
  useSyndicateWires_SendFreeFormWireMutation,
} from '../graphql';

export type SendFreeFormWireMutation = SyndicateWires_SendFreeFormWireMutation;

export const useSendFreeFormWireMutation = () => {
  return useSyndicateWires_SendFreeFormWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_FreeFormWiresDocument];
  return getManyOperationNames(documentNodes);
}
