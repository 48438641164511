import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const recipientUserDataValidationSchema = yup
  .object()
  .shape({
    recipient: yup.string().label('Recipient').required(),
    sellingConcession: yup.number().nullable().label('Selling Concession').min(0).required(),
  })
  .nullable();

export const validationSchema = yup
  .object()
  .shape({
    recipientsUserData: yup
      .array()
      .of(recipientUserDataValidationSchema)
      .label('Recipients')
      .min(1)
      .required()
      .test(
        'non-nullable-data',
        'At least one manager must have user data',
        (value: Values['recipientsUserData'] | undefined) => {
          return !!value?.some(data => data !== null);
        }
      ),
  })
  .concat(wiresFormDisclaimerSchema);

export type NonSyndicateSellingGroupTermsRetentionRecipientUserData = {
  recipient: string;
  sellingConcession: number | null;
};

export type Values = {
  disclaimer: string | null;
  recipientsUserData: Array<NonSyndicateSellingGroupTermsRetentionRecipientUserData | null>;
};
