import { SecondaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SContent = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  color: ${({ theme }) => theme.text.color.darkGray};
`;
