import styled from 'styled-components/macro';

export const SRemainingFirms = styled.span`
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
`;

export const SLabel = styled.div`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 1px;
  text-decoration-color: ${({ theme }) => theme.text.color.light};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
