import { useCheckAccountType } from '@cmg/auth';
import { useMemo } from 'react';

import {
  OfferingFullFilterInput,
  OfferingStage,
  OfferingStatus,
} from '../../../../../graphql/__generated__';
import { CALENDAR_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT } from '../../../graphql/constants';
import { mapCalendarGridDataToOldFormatByStatus } from '../../../utils/mapCalendarGridDataToOldFormat';
import {
  useCalendar_PublishedOfferingsBuySideQuery,
  useCalendar_PublishedOfferingsQuery,
} from '../graphql/__generated__';
import { useFetchAllPaginatedPublishedOfferings } from './useFetchAllPaginatedPublishedOfferings';

type RequireStatusEqual = Required<{
  status: {
    eq:
      | OfferingStatus.Live
      | OfferingStatus.Priced
      | OfferingStatus.Filed
      | OfferingStatus.Postponed;
  };
}>;

type CalendarPublishedOfferingsParams = {
  skip: boolean;
  where: OfferingFullFilterInput & RequireStatusEqual;
};

export function useCalendarPublishedOfferings({ skip, where }: CalendarPublishedOfferingsParams) {
  const isSellSide = useCheckAccountType('SELL_SIDE');
  const offeringsFullQuerySellSide = useCalendar_PublishedOfferingsQuery({
    skip: skip || !isSellSide,
    variables: {
      skip: 0,
      take: CALENDAR_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT,
      where: {
        ...where,
        stage: { eq: OfferingStage.Published },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const offeringsFullQueryBuySide = useCalendar_PublishedOfferingsBuySideQuery({
    skip: skip || isSellSide,
    variables: {
      skip: 0,
      take: CALENDAR_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT,
      where: {
        ...where,
        stage: { eq: OfferingStage.Published },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const offeringsFullQuery = isSellSide ? offeringsFullQuerySellSide : offeringsFullQueryBuySide;

  useFetchAllPaginatedPublishedOfferings(offeringsFullQuery);

  const loading =
    offeringsFullQuery.loading ||
    !!offeringsFullQuery.data?.publishedOfferings?.pageInfo.hasNextPage;

  const remappedItems = useMemo(() => {
    if (loading) return;
    return offeringsFullQuery.data?.publishedOfferings?.items?.map(
      mapCalendarGridDataToOldFormatByStatus[where.status.eq]
    );
  }, [loading, offeringsFullQuery.data?.publishedOfferings?.items, where.status.eq]);

  return {
    ...offeringsFullQuery,
    loading,
    data: loading
      ? undefined
      : {
          ...offeringsFullQuery.data,
          publishedOfferings: {
            ...offeringsFullQuery.data?.publishedOfferings,
            items: remappedItems,
          },
        },
  };
}
