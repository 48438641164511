import { useFormik } from 'formik';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

import { OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery } from '../../graphql';
import { ManagerEconomicBreakdownRow } from '../components/economics-breakdown-grid/EconomicBreakdownGrid.model';
import { calculateTotalGrossSpread } from '../components/gross-spread-allocation-card/GrossSpreadAllocationCard.model';

export type ManagerEconomicsErrors = {
  incentiveFee: boolean | null;
  underwritingFee: boolean | null;
  underwritingWithIncentiveFee: boolean | null;
  managementFee: boolean | null;
  sellingConcession: boolean | null;
  feeDistribuitionErrorCount: number | null;
};

export type Props = {
  totalValues: ManagerEconomicBreakdownRow;
  incentiveGrossSpread:
    | OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery['getUnderwritingFeesAndDiscounts']['incentiveFeeData']
    | null
    | undefined;
  setErrors: ReturnType<typeof useFormik>['setErrors'];
  values: { rows: Record<number, ManagerEconomicBreakdownRow> };
};

// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useValidateManagerEconomicsData = (props: Props) => {
  const { totalValues, incentiveGrossSpread, values, setErrors: setFormErrors } = props;
  const totalGrossSpreadIncentiveFee = incentiveGrossSpread
    ? calculateTotalGrossSpread(incentiveGrossSpread)
    : 0;

  const [errors, setErrors] = useState<ManagerEconomicsErrors>({
    incentiveFee: null,
    underwritingFee: null,
    managementFee: null,
    sellingConcession: null,
    feeDistribuitionErrorCount: null,
    underwritingWithIncentiveFee: null,
  });

  const validateIncentiveFee = () =>
    !!(
      totalGrossSpreadIncentiveFee &&
      totalValues.incentiveFee !== 1 &&
      totalValues.incentiveFee !== null
    );

  const validateFeeDistribution = () => ({
    underwritingWithIncentiveFee:
      !isNil(totalValues.underwritingWithIncentiveFee) &&
      totalValues.underwritingWithIncentiveFee !== 1,
    underwritingFee: !isNil(totalValues.underwritingFee) && totalValues.underwritingFee !== 1,
    managementFee: !isNil(totalValues.managementFee) && totalValues.managementFee !== 1,
    sellingConcession: !isNil(totalValues.sellingConcession) && totalValues.sellingConcession !== 1,
  });

  const validateData = () => {
    const incentiveFee = validateIncentiveFee();
    const { underwritingFee, managementFee, sellingConcession, underwritingWithIncentiveFee } =
      validateFeeDistribution();

    const feeDistribuitionErrorCount = [
      managementFee,
      underwritingFee,
      sellingConcession,
      underwritingWithIncentiveFee,
    ].filter(hasError => hasError).length;

    setErrors({
      incentiveFee,
      underwritingFee,
      underwritingWithIncentiveFee,
      managementFee,
      sellingConcession,
      feeDistribuitionErrorCount,
    });

    return feeDistribuitionErrorCount === 0 && !incentiveFee;
  };

  useEffect(() => {
    const {
      incentiveFee,
      managementFee,
      underwritingFee,
      underwritingWithIncentiveFee,
      sellingConcession,
    } = errors;

    let formikErrors: { rows?: Partial<Record<keyof ManagerEconomicsErrors, string>>[] } = {};

    const addFormikError = (key: keyof ManagerEconomicsErrors) => {
      if (!formikErrors.rows) {
        formikErrors = {
          rows: Object.values(values.rows).map(() => ({})),
        };
      }
      formikErrors.rows!.forEach(row => {
        row[key] = 'Values should add up to 100%';
      });
    };

    if (incentiveFee) {
      addFormikError('incentiveFee');
    } else {
      if (managementFee) {
        addFormikError('managementFee');
      }
      if (underwritingFee) {
        addFormikError('underwritingFee');
      }
      if (underwritingWithIncentiveFee) {
        addFormikError('underwritingWithIncentiveFee');
      }
      if (sellingConcession) {
        addFormikError('sellingConcession');
      }
    }

    setFormErrors(formikErrors);
  }, [errors, setFormErrors, values.rows]);

  return { errors, validateData };
};
