import { MutationHookOptions } from '@apollo/client';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_CreateDeliveryInstrumentDocument,
  OfferingSetup_CreateDeliveryInstrumentMutation,
  OfferingSetup_CreateDeliveryInstrumentMutationVariables,
} from '../graphql';

export const useCreateDeliveryInstrumentMutation = (
  options: MutationHookOptions<
    OfferingSetup_CreateDeliveryInstrumentMutation,
    OfferingSetup_CreateDeliveryInstrumentMutationVariables
  >
) =>
  useServiceMutation<
    OfferingSetup_CreateDeliveryInstrumentMutation,
    OfferingSetup_CreateDeliveryInstrumentMutationVariables
  >(OfferingSetup_CreateDeliveryInstrumentDocument, options);
