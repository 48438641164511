export const getCurrentDocumentPath = (index: number) => `prospectusDocuments.${index}`;

export enum FileInputErrorType {
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  UNSUPPORTED_FILENAME = 'UNSUPPORTED_FILENAME',
}

export type FileInputError = Readonly<{
  fileName: string;
  errorType: FileInputErrorType;
}>;

export const errorLabels = {
  [FileInputErrorType.UNSUPPORTED_FILENAME]: (filename: string) =>
    `The document file name is not supported: ${filename} the document name should only contain alphanumeric characters, hyphen, spaces, and periods.`,
  [FileInputErrorType.FILE_TYPE]: (filename: string) =>
    `The document file type is not supported: ${filename} Only PDF files are currently supported.`,
  [FileInputErrorType.FILE_SIZE]: (
    filename: string
  ) => `The document is too large to be uploaded: ${filename} Files larger than 250MB are not
    currently supported.`,
};

export const MAX_FILE_SIZE = 250 * 1024 * 1024; // 250 MB
