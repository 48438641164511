import { LinkButton, Popover, UUID } from '@cmg/common';
import React from 'react';

import { LoadingIndicator } from '../../../../../../../common/components/indicators/loading-indicator/LoadingIndicator';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { SalesCreditsInvestor } from '../../../SalesCreditsReleaseHistoryRoute';
import { useSalesCredits_BankInvestorInformationQuery } from './graphql';
import { SPopoverSmallText, SPopoverText } from './SalesCreditsBankInvestorKey.styles';
import SalesCreditsUpdateCrmBankInvestorKeyModal from './update-crm-bank-investor-key-modal/SalesCreditsUpdateCrmBankInvestorKeyModal';

export type Props = { investor: SalesCreditsInvestor; offeringId: UUID };

const SalesCreditsBankInvestorKeyPanel: React.FC<Props> = ({ investor, offeringId }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data, loading, error } = useSalesCredits_BankInvestorInformationQuery({
    variables: { firmId: investor.firmId! },
    skip: !investor.firmId,
  });
  const { address1, address2, firmKey } = data?.investorFirm ?? {};
  const address = [address1, address2].filter(address => address).join(', ');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  return (
    <div>
      <Popover
        variant="DARK"
        placement="topRight"
        content={
          <div>
            <div>Bank Investor Key: {firmKey || '-'}</div>
            <div>Location: {address || '-'}</div>
            <SPopoverSmallText>
              Note: The bank investor key is your firm's internal identifier for this investor
            </SPopoverSmallText>
          </div>
        }
      >
        <SPopoverText>
          Bank Investor Key: {loading ? <LoadingIndicator size="small" /> : firmKey || '-'}
        </SPopoverText>
      </Popover>
      <LinkButton disabled={loading} onClick={handleOpenModal}>
        Edit
      </LinkButton>

      <SalesCreditsUpdateCrmBankInvestorKeyModal
        show={isModalOpen}
        onHide={handleCloseModal}
        offeringId={offeringId}
        firmCmgEntityKey={investor.cmgEntityKey}
        firmName={investor.name}
        firmId={investor.firmId}
        firmAddress={address}
        firmBankInvestorKey={firmKey!}
      />
    </div>
  );
};

export default SalesCreditsBankInvestorKeyPanel;
