import styled from 'styled-components/macro';

export const SPopoverContent = styled.div`
  max-width: 220px;
  word-wrap: break-word;
`;

export const SText = styled.div`
  word-wrap: anywhere;
`;
