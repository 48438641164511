import { combineReducers } from 'redux';
import { reducer as modal, ReduxModalState } from 'redux-modal';

export type RootState = {
  modal: ReduxModalState;
};

const appReducer = combineReducers<RootState>({
  modal,
});

export default appReducer;
