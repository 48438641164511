import { Icon } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SWrapper } from './ValidationWarningIcon.styles';

export const ValidationWarningIcon = () => {
  const theme = useTheme();

  return (
    <SWrapper>
      <Icon
        name="exclamation-circle"
        variant="solid"
        color={theme.designSystem.colors.yellow['300']}
      />
    </SWrapper>
  );
};
