import { getOperationName } from '@apollo/client/utilities';

import { OfferingSetup_Summary_AvailableShareDraftManagersDocument } from '../../../graphql';
import { OfferingSetup_OfferingEntitlementsDocument } from '../../entitlements/graphql';
import { useOfferingSetup_CreateManagerMutation } from '../graphql';

export const refetchQueries = () => {
  // Refetch offering entitlements once a new manager is created
  const getOfferingEntitlements = getOperationName(OfferingSetup_OfferingEntitlementsDocument)!;
  const getOfferingManagers = getOperationName(
    OfferingSetup_Summary_AvailableShareDraftManagersDocument
  )!;
  return [getOfferingEntitlements, getOfferingManagers];
};

export const useCreateManagerMutation = (
  options: Parameters<typeof useOfferingSetup_CreateManagerMutation>[0]
) =>
  useOfferingSetup_CreateManagerMutation({
    ...options,
    refetchQueries,
  });
