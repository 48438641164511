import { numericUtil } from '@cmg/common';

import { ManagerRole } from '../../../../../graphql';
import { ManagerCellRenderer } from './components/manager-cell-renderer/ManagerCellRenderer';
import { NumericInputRenderer } from './components/numeric-input-renderer/NumericInputRenderer';
import { NumericPinnedCellRenderer } from './components/numeric-pinned-cell-renderer/NumericPinnedCellRenderer';
import { PercentInputRenderer } from './components/percent-input-renderer/PercentInputRenderer';
import { PercentPinnedCellRenderer } from './components/percent-pinned-cell-renderer/PercentPinnedCellRenderer';
import { PinnedCellRenderer } from './components/pinned-cell-renderer/PinnedCellRenderer';

export enum EconomicBreakdownGridColumnOrder {
  MANAGER = 'manager',
  INCENTIVE_FEE = 'incentiveFee',
  UNDERWRITING_FEE = 'underwritingFee',
  MANAGEMENT_FEE = 'managementFee',
  SELLING_CONCESSION = 'sellingConcession',
  UNDERWRITING_WITH_INCENTIVE_FEE = 'underwritingWithIncentiveFee',
  UNDERWRITING_SHARES = 'underwritingShares',
  EXERCISED_OVERALLOTMENT_SHARES = 'exercisedOverallotmentShares',
  TOTAL_UNDERWRITING_SHARES = 'totalUnderwritingShares',
}

const defaultStyle = {
  cellStyle: () => ({
    alignItems: 'right',
    lineHeight: '44px',
    textAlign: 'right',
    justifyContent: 'flex-end',
  }),
  minWidth: 140,
  maxWidth: 300,
  sortable: false,
  suppressMovable: true,
  headerClass: 'economics-breakdown-header-cell',
};

export const economicBreakdownGridColumns = {
  [EconomicBreakdownGridColumnOrder.MANAGER]: {
    ...defaultStyle,
    minWidth: 200,
    maxWidth: undefined,
    colId: EconomicBreakdownGridColumnOrder.MANAGER,
    field: 'manager',
    headerName: 'Manager',
    cellRendererFramework: ManagerCellRenderer,
    pinnedRowCellRendererFramework: () => (
      <PinnedCellRenderer align="left" value="Total" subValue="Difference" />
    ),
  },
  [EconomicBreakdownGridColumnOrder.INCENTIVE_FEE]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.INCENTIVE_FEE,
    field: 'incentiveFee',
    headerName: 'Incentive Fee %',
    cellRendererFramework: ({ column, data }) => (
      <PercentInputRenderer
        column={column}
        data={data}
        disabled={data.managerRole === ManagerRole.SellingGroupMember}
      />
    ),
    aggFunc: 'sum',
    pinnedRowCellRendererFramework: ({ context, value }) => (
      <PercentPinnedCellRenderer
        value={value}
        errorMessage="Incentive Fee % has to add up to 100%"
        acceptZero={!context.incentiveFee}
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.UNDERWRITING_FEE]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.UNDERWRITING_FEE,
    field: 'underwritingFee',
    headerName: 'U/W% (unadj. for incentive fee)',
    cellRendererFramework: ({ column, data }) => (
      <PercentInputRenderer
        column={column}
        data={data}
        disabled={data.managerRole === ManagerRole.SellingGroupMember}
      />
    ),
    pinnedRowCellRendererFramework: ({ value }) => (
      <PercentPinnedCellRenderer
        value={value}
        errorMessage="U/W% (unadj. for incentive fee) has to add up to 100%"
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.MANAGEMENT_FEE]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.MANAGEMENT_FEE,
    field: 'managementFee',
    headerName: 'Mgmt Fee %',
    cellRendererFramework: ({ column, data }) => {
      return (
        <PercentInputRenderer
          column={column}
          data={data}
          disabled={[ManagerRole.SellingGroupMember, ManagerRole.Underwriter].includes(
            data.managerRole
          )}
        />
      );
    },
    pinnedRowCellRendererFramework: ({ value }) => {
      return (
        <PercentPinnedCellRenderer value={value} errorMessage="Mgmt Fee % has to add up to 100%" />
      );
    },
  },
  [EconomicBreakdownGridColumnOrder.SELLING_CONCESSION]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.SELLING_CONCESSION,
    field: 'sellingConcession',
    headerName: 'Selling Concession %',
    cellRendererFramework: ({ column, data }) => (
      <PercentInputRenderer
        column={column}
        data={data}
        disabled={[ManagerRole.SellingGroupMember, ManagerRole.Underwriter].includes(
          data.managerRole
        )}
      />
    ),
    pinnedRowCellRendererFramework: ({ value }) => (
      <PercentPinnedCellRenderer
        value={value}
        errorMessage="Selling Concession % has to add up to 100%"
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.UNDERWRITING_WITH_INCENTIVE_FEE]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.UNDERWRITING_WITH_INCENTIVE_FEE,
    field: 'underwritingWithIncentiveFee',
    headerName: 'U/W % (incl. incentive fee)',
    cellRendererFramework: ({ column, data }) => (
      <PercentInputRenderer
        column={column}
        data={data}
        disabled={data.managerRole === ManagerRole.SellingGroupMember}
      />
    ),
    pinnedRowCellRendererFramework: ({ value }) => (
      <PercentPinnedCellRenderer
        value={value}
        errorMessage="U/W % (incl. incentive fee) has to add up to 100%"
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES,
    field: 'underwritingShares',
    headerName: 'U/W Shares (Base Offering)',
    cellRendererFramework: ({ column, data }) => (
      <NumericInputRenderer
        column={column}
        data={data}
        disabled={data.managerRole === ManagerRole.SellingGroupMember}
        precision={0}
      />
    ),
    pinnedRowCellRendererFramework: ({ value, context }) => (
      <NumericPinnedCellRenderer
        value={value}
        targetValue={context.totalSharesBaseOffering}
        errorMessage="U/W Shares (Base Offering) does not add up to Base Offering Shares (data can still be saved in this state)"
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.EXERCISED_OVERALLOTMENT_SHARES]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.EXERCISED_OVERALLOTMENT_SHARES,
    field: 'exercisedOverallotmentShares',
    headerName: 'U/W Shares (Exercised Overallotment)',
    cellRendererFramework: ({ column, context, data }) => (
      <NumericInputRenderer
        data={data}
        column={column}
        disabled={
          context?.totalSharesOverallotmentExercised === null ||
          data.managerRole === ManagerRole.SellingGroupMember
        }
        precision={0}
      />
    ),
    pinnedRowCellRendererFramework: ({ value, context }) => (
      <NumericPinnedCellRenderer
        value={value}
        targetValue={context?.totalSharesOverallotmentExercised}
        errorMessage="U/W Shares (Exercised Overallotment) does not add up to Exercised Overallotment (data can still be saved in this state)"
      />
    ),
  },
  [EconomicBreakdownGridColumnOrder.TOTAL_UNDERWRITING_SHARES]: {
    ...defaultStyle,
    colId: EconomicBreakdownGridColumnOrder.TOTAL_UNDERWRITING_SHARES,
    field: 'totalUnderwritingShares',
    valueFormatter: ({ value }) => numericUtil.formatNumber(value, 0),
    valueGetter: params =>
      (params.data[EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES] ?? 0) +
      (params.data[EconomicBreakdownGridColumnOrder.EXERCISED_OVERALLOTMENT_SHARES] ?? 0),
    headerName: 'U/W Shares (Base + Exercised Overallotment)',
    pinnedRowCellRendererFramework: ({ value }) => <NumericPinnedCellRenderer value={value} />,
  },
};

export const economicBreakdownGridColumnsOrdered = [
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.MANAGER],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.INCENTIVE_FEE],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.UNDERWRITING_FEE],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.MANAGEMENT_FEE],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.SELLING_CONCESSION],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.UNDERWRITING_WITH_INCENTIVE_FEE],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.EXERCISED_OVERALLOTMENT_SHARES],
  economicBreakdownGridColumns[EconomicBreakdownGridColumnOrder.TOTAL_UNDERWRITING_SHARES],
];
