import { numericUtil, timeUtil } from '@cmg/common';
import { ValueFormatterParams } from 'ag-grid-community';

import { isInternationalOffering } from '../../../../common/util/offering/offering-type.util';
import {
  internationalOfferingTypeLabels,
  offeringTypeLabels,
  usOfferingTypeToInternational,
} from '../../../../types/domain/offering/constants';
import { securityTypeLabels } from '../../../../types/domain/security/constants';
import { sectorLabels } from '../../../constants';
import { ColDefCalendarGrid } from '../../calendar.model';
import {
  getMappedLabelRenderer,
  getNumberLabelRenderer,
  getStringLabelRenderer,
  optionalStringRenderer,
} from './CalendarGridColumns.model';
import { CountryRenderer } from './renderers/country-renderer/CountryRenderer';
import { CurrencyRenderer } from './renderers/currency-renderer/CurrencyRenderer';
import { IsExecutedOnPlatformRenderer } from './renderers/executed-on-platform/IsExecutedOnPlatformRenderer';
import { IssuerNameRenderer } from './renderers/issuer-name/IssuerNameRenderer';
import { LeftLeadRenderer } from './renderers/left-lead/LeftLeadRenderer';
import { OfferingSidePanelLinkRenderer } from './renderers/offering-side-panel-link/OfferingSidePanelLinkRenderer';
import { PriceRangeRenderer } from './renderers/price-range/PriceRangeRenderer';
import { SizeInCurrencyRenderer } from './renderers/size-in-currency/SizeInCurrencyRenderer';

export enum CalendarGridOrderField {
  SYMBOL = 'pricingInstrumentStockSymbol',
  ISSUER_NAME = 'issuerName',
  SECTOR = 'issuerSector',
  TYPE = 'type',
  SIZE_IN_CURRENCY = 'sizeInCurrency',
  INTERNATIONAL_SIZE_IN_CURRENCY = 'internationalSizeInCurrency',
  SIZE_IN_SHARES = 'sizeInShares',
  INTERNATIONAL_PRICE_RANGE = 'internationalPriceRange',
  PRICE_RANGE = 'priceRange',
  LEFT_LEAD = 'leftLeadFirmName',
  IS_EXECUTED_ON_PLATFORM = 'isExecutingOnPlatform',
  PRICING_DATE = 'pricingDate',
  INTERNATIONAL_PRICING_DATE = 'internationalPricingDate',
  POSTPONED_DATE = 'postponedDate',
  INTERNATIONAL_POSTPONED_DATE = 'internationalPostponedDate',
  FIRST_TRADE_DATE = 'firstTradeDate',
  INTERNATIONAL_FIRST_TRADE_DATE = 'internationalFirstTradeDate',
  PUBLIC_FILING_DATE = 'publicFilingDate',
  INTERNATIONAL_PUBLIC_FILING_DATE = 'internationalPublicFilingDate',
  SECURITY_TYPE = 'securityType',
  FINAL_FILING_OFFER_PRICE = 'finalFilingOfferPrice',
  INTERNATIONAL_FINAL_FILING_OFFER_PRICE = 'internationalFinalFilingOfferPrice',
  REGION = 'pricingInstrumentRegion',
  COUNTRY = 'pricingInstrumentCountryDisplayName',
}

const cellStyle = {
  alignItems: 'center',
  lineHeight: '32px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  minWidth: 0,
};

export const numericCellStyle = {
  ...cellStyle,
  textAlign: 'right',
};

export const commonColumnDefinition = {
  suppressColumnsToolPanel: true,
  resizable: true,
  cellStyle,
  wrapText: false,
};

export const calendarGridColumns: { [key: string]: ColDefCalendarGrid } = {
  [CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM]: {
    ...commonColumnDefinition,
    colId: CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM,
    headerName: '',
    minWidth: 30,
    maxWidth: 30,
    field: 'isExecutingOnPlatform',
    cellRendererFramework: IsExecutedOnPlatformRenderer,
    cellStyle: {
      ...cellStyle,
      justifyContent: 'center',
    },
  },
  [CalendarGridOrderField.SYMBOL]: {
    ...commonColumnDefinition,
    minWidth: 80,
    maxWidth: 200,
    colId: CalendarGridOrderField.SYMBOL,
    headerName: 'Symbol',
    field: 'pricingInstrumentStockSymbol',
    cellRendererFramework: OfferingSidePanelLinkRenderer,
  },
  [CalendarGridOrderField.ISSUER_NAME]: {
    ...commonColumnDefinition,
    minWidth: 300,
    maxWidth: 600,
    colId: CalendarGridOrderField.ISSUER_NAME,
    field: 'issuerName',
    headerName: 'Issuer',
    sort: 'asc',
    sortingOrder: ['asc', 'desc'],
    cellRendererFramework: IssuerNameRenderer,
  },
  [CalendarGridOrderField.REGION]: {
    ...commonColumnDefinition,
    minWidth: 80,
    maxWidth: 300,
    colId: CalendarGridOrderField.REGION,
    headerName: 'Region',
    field: 'pricingInstrumentRegion',
    valueFormatter: optionalStringRenderer,
  },
  [CalendarGridOrderField.COUNTRY]: {
    ...commonColumnDefinition,
    minWidth: 140,
    maxWidth: 300,
    colId: CalendarGridOrderField.COUNTRY,
    headerName: 'Country',
    field: 'pricingInstrumentCountryDisplayName',
    cellRendererFramework: CountryRenderer,
  },
  [CalendarGridOrderField.SECTOR]: {
    ...commonColumnDefinition,
    minWidth: 120,
    maxWidth: 300,
    colId: CalendarGridOrderField.SECTOR,
    headerName: 'Sector',
    field: 'issuerSector',
    valueFormatter: getMappedLabelRenderer(sectorLabels),
  },
  [CalendarGridOrderField.TYPE]: {
    ...commonColumnDefinition,
    colId: CalendarGridOrderField.TYPE,
    headerName: 'Type',
    field: 'type',
    minWidth: 140,
    maxWidth: 300,
    valueFormatter: ({ value, data }) => {
      // ECM-46695 - For international offering
      const isInternational = isInternationalOffering({
        isExecutingOnPlatform: data.isExecutingOnPlatform,
        pricingInstrumentCountryCode: data.pricingInstrumentCountryCode,
      });
      const offeringTypeValue = isInternational ? usOfferingTypeToInternational[value] : value;

      return getMappedLabelRenderer(
        isInternational ? internationalOfferingTypeLabels : offeringTypeLabels
      )({ value: offeringTypeValue } as ValueFormatterParams);
    },
  },
  [CalendarGridOrderField.SECURITY_TYPE]: {
    ...commonColumnDefinition,
    minWidth: 160,
    maxWidth: 300,
    colId: CalendarGridOrderField.SECURITY_TYPE,
    headerName: 'Security Type',
    field: 'securityType',
    valueFormatter: getMappedLabelRenderer(securityTypeLabels),
  },
  [CalendarGridOrderField.SIZE_IN_CURRENCY]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 300,
    cellStyle: numericCellStyle,
    colId: CalendarGridOrderField.SIZE_IN_CURRENCY,
    headerName: 'Size ($M)',
    field: 'sizeInCurrency',
    cellRendererFramework: SizeInCurrencyRenderer,
  },
  [CalendarGridOrderField.INTERNATIONAL_SIZE_IN_CURRENCY]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 300,
    cellStyle: numericCellStyle,
    colId: CalendarGridOrderField.SIZE_IN_CURRENCY,
    headerName: 'Size ⓘ',
    headerTooltip: 'Size is shown in local currency',
    field: 'sizeInCurrency',
    cellRendererFramework: SizeInCurrencyRenderer,
  },
  [CalendarGridOrderField.SIZE_IN_SHARES]: {
    ...commonColumnDefinition,
    minWidth: 120,
    maxWidth: 300,
    cellStyle: numericCellStyle,
    colId: CalendarGridOrderField.SIZE_IN_SHARES,
    headerName: 'Size (SHARES)',
    field: 'sizeInShares',
    valueFormatter: getNumberLabelRenderer(val => numericUtil.formatNumber(val, 0)),
  },
  [CalendarGridOrderField.FINAL_FILING_OFFER_PRICE]: {
    ...commonColumnDefinition,
    minWidth: 110,
    maxWidth: 300,
    cellStyle: numericCellStyle,
    colId: CalendarGridOrderField.FINAL_FILING_OFFER_PRICE,
    headerName: 'Offer Price',
    field: 'finalFilingOfferPrice',
    cellRendererFramework: CurrencyRenderer,
  },
  [CalendarGridOrderField.INTERNATIONAL_FINAL_FILING_OFFER_PRICE]: {
    ...commonColumnDefinition,
    minWidth: 110,
    maxWidth: 300,
    cellStyle: numericCellStyle,
    colId: CalendarGridOrderField.FINAL_FILING_OFFER_PRICE,
    headerName: 'Offer Price ⓘ',
    headerTooltip: 'Offer price is shown in local currency',
    field: 'finalFilingOfferPrice',
    cellRendererFramework: CurrencyRenderer,
  },
  [CalendarGridOrderField.INTERNATIONAL_PRICE_RANGE]: {
    ...commonColumnDefinition,
    minWidth: 150,
    maxWidth: 300,
    colId: CalendarGridOrderField.PRICE_RANGE,
    headerName: 'Price Range ⓘ',
    headerTooltip: 'Price Range is shown in local currency',
    valueGetter: ({ data }) =>
      `${data.pricingCurrencyCode}${data.priceRangeHigh}${data.priceRangeLow}`,
    cellRendererFramework: PriceRangeRenderer,
  },
  [CalendarGridOrderField.PRICE_RANGE]: {
    ...commonColumnDefinition,
    minWidth: 150,
    maxWidth: 300,
    colId: CalendarGridOrderField.PRICE_RANGE,
    headerName: 'Price Range',
    valueGetter: ({ data }) =>
      `${data.pricingCurrencyCode}${data.priceRangeHigh}${data.priceRangeLow}`,
    cellRendererFramework: PriceRangeRenderer,
  },
  [CalendarGridOrderField.LEFT_LEAD]: {
    ...commonColumnDefinition,
    minWidth: 200,
    maxWidth: 400,
    colId: CalendarGridOrderField.LEFT_LEAD,
    headerName: 'Left Lead',
    field: 'leftLeadFirmName',
    suppressSizeToFit: true,
    cellRendererFramework: LeftLeadRenderer,
  },
  [CalendarGridOrderField.PRICING_DATE]: {
    ...commonColumnDefinition,
    minWidth: 90,
    maxWidth: 300,
    colId: CalendarGridOrderField.PRICING_DATE,
    field: CalendarGridOrderField.PRICING_DATE,
    headerName: 'Pricing Date',
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.INTERNATIONAL_PRICING_DATE]: {
    ...commonColumnDefinition,
    minWidth: 90,
    maxWidth: 300,
    colId: CalendarGridOrderField.PRICING_DATE,
    field: CalendarGridOrderField.PRICING_DATE,
    headerName: 'Pricing Date ⓘ',
    headerTooltip: "Pricing date is presented in exchange country's local time zone",
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.POSTPONED_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.POSTPONED_DATE,
    field: CalendarGridOrderField.POSTPONED_DATE,
    headerName: 'Postponed Date',
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.INTERNATIONAL_POSTPONED_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.POSTPONED_DATE,
    field: CalendarGridOrderField.POSTPONED_DATE,
    headerName: 'Postponed Date ⓘ',
    headerTooltip: "Postponed date is presented in exchange country's local time zone",
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.FIRST_TRADE_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.FIRST_TRADE_DATE,
    field: CalendarGridOrderField.FIRST_TRADE_DATE,
    headerName: 'First Trade Date',
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.INTERNATIONAL_FIRST_TRADE_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.FIRST_TRADE_DATE,
    field: CalendarGridOrderField.FIRST_TRADE_DATE,
    headerName: 'First Trade Date ⓘ',
    headerTooltip: "First trade date is presented in exchange country's local time zone.",
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.PUBLIC_FILING_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.PUBLIC_FILING_DATE,
    field: CalendarGridOrderField.PUBLIC_FILING_DATE,
    headerName: 'Public Filing Date',
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
  [CalendarGridOrderField.INTERNATIONAL_PUBLIC_FILING_DATE]: {
    ...commonColumnDefinition,
    minWidth: 100,
    maxWidth: 400,
    colId: CalendarGridOrderField.PUBLIC_FILING_DATE,
    field: CalendarGridOrderField.PUBLIC_FILING_DATE,
    headerName: 'Public Filing Date ⓘ',
    headerTooltip: "Public filing date is presented in exchange country's local time zone.",
    valueFormatter: getStringLabelRenderer(timeUtil.formatAsDisplayDate),
  },
};
