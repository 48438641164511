import {
  CurrencyInputField,
  DatePickerField,
  IconButton,
  NumericInputField,
  numericUtil,
  ODSTable as Table,
} from '@cmg/common';
import React from 'react';

import { StabilizationTrade } from '../../../../StabilizationForm.model';
import { StyledGainLossCell } from '../../../common/gain-loss-table-cell/GainLossTableCell';
import { getSellGain } from '../../OverallotmentRefresh.model';

export type Props = {
  index: number;
  trade: StabilizationTrade;
  offeringPrice: number;
  onRemoveRow: () => void;
};

const EditableOverallotmentRefreshRow: React.FC<Props> = ({
  index,
  trade,
  offeringPrice,
  onRemoveRow,
}) => {
  return (
    <Table.Row suppressHighlightingOnHover isFocused={false}>
      <Table.Cell paddingSize="XS">
        <DatePickerField name={`sell.${index}.date`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="XS">
        <NumericInputField name={`sell.${index}.numberOfShares`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="XS" align="right">
        <CurrencyInputField name={`sell.${index}.averagePrice`} precision={6} fullWidth />
      </Table.Cell>
      <StyledGainLossCell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(getSellGain(trade, offeringPrice), 6)}
      </StyledGainLossCell>
      <Table.Cell paddingSize="S">
        <IconButton testId="remove-row-button" icon={{ name: 'trash-alt' }} onClick={onRemoveRow} />
      </Table.Cell>
    </Table.Row>
  );
};

export default EditableOverallotmentRefreshRow;
