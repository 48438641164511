import { Popover } from '@cmg/common';
import React from 'react';

import { OfferingLink } from '../../components/OfferingLink';
import { MyOfferings_SharedDraftsQuery } from '../../shared-drafts/graphql';
import { MyOfferings_DraftsQuery } from '../graphql';
import { generateShareHistory } from './IssuerNameColCellRenderer.model';
import { SIssuerNameWrapper } from './IssuerNameColCellRenderer.styles';
import ShareActivityTooltip from './ShareActivityTooltip';

type Props = {
  data: (
    | MyOfferings_DraftsQuery['allOfferings']['data'][number]
    | MyOfferings_SharedDraftsQuery['allSharedDrafts']['data'][number]
  ) & { isObsolete?: boolean | null };
  value?: string | number;
};

const IssuerNameColCellRenderer: React.FC<Props> = ({ data: offering, value }) => {
  const shareHistory = generateShareHistory(offering);
  const hasShareHistory = shareHistory.length > 0;

  const hoverContent = offering.isObsolete
    ? 'This draft offering has been marked as obsolete.'
    : null;

  return value ? (
    <Popover content={hoverContent} variant="DARK" disabled={!hoverContent}>
      <SIssuerNameWrapper>
        <OfferingLink
          offeringId={offering.id}
          isSharedDraft={offering.__typename === 'SharedDraftOfferingLimited'}
          isDraft
        >
          {value}
        </OfferingLink>
        {hasShareHistory && <ShareActivityTooltip shareHistory={shareHistory} />}
      </SIssuerNameWrapper>
    </Popover>
  ) : (
    <div>-</div>
  );
};

export default IssuerNameColCellRenderer;
