import isEqual from 'lodash/isEqual';
import * as yup from 'yup';

import { OfferingSetup_Prospectus_DocumentMetadataFragment } from '../graphql';

export const FILENAME_REGEX = /^[\w\s-.]+$/i; // alphanumeric, hyphen, spaces, and periods

export type ProspectusFormValues = {
  prospectusDocuments: ProspectusFormDocument[];
};

export type ProspectusFormDocument = Omit<
  OfferingSetup_Prospectus_DocumentMetadataFragment,
  'id' | 'uploadedAt'
> & {
  id: OfferingSetup_Prospectus_DocumentMetadataFragment['id'] | null;
  uploadedAt: OfferingSetup_Prospectus_DocumentMetadataFragment['uploadedAt'] | null;
  file: File | null;
};

export const prospectusValidationSchema = yup.object().shape({
  prospectusDocuments: yup.array().of(
    yup.object().shape({
      documentName: yup
        .string()
        .required()
        .max(255)
        .label('Document name')
        .test('Document Name', (value = '') => FILENAME_REGEX.test(value)),
    })
  ),
});

export const emptyInitialValues: ProspectusFormValues = { prospectusDocuments: [] };

const needsUpload = (doc: ProspectusFormDocument) => !!doc.file && !doc.id;
const needsUpdate = (doc: ProspectusFormDocument) => !!doc.file && !!doc.id;
const hasMetadataChanged = (doc: ProspectusFormDocument, initialValues: ProspectusFormValues) => {
  const initialDocument = initialValues.prospectusDocuments.find(
    initialDoc => initialDoc.id === doc.id
  );
  return !isEqual(initialDocument, doc);
};

const mapForUpload = (doc: ProspectusFormDocument) => ({
  documentName: doc.documentName,
  includeOnPublish: doc.includeOnPublish,
  upload: doc.file!,
});

const mapForUpdate = (doc: ProspectusFormDocument) => ({
  id: doc.id!,
  documentName: doc.documentName,
  includeOnPublish: doc.includeOnPublish,
  upload: doc.file!,
});

const mapForMetadataUpdate = (doc: ProspectusFormDocument) => ({
  id: doc.id!,
  documentName: doc.documentName,
  includeOnPublish: doc.includeOnPublish,
});

export function getDocumentsForSubmission({
  values,
  initialValues,
}: {
  values: ProspectusFormValues;
  initialValues: ProspectusFormValues;
}) {
  // Use the helper functions to create the arrays
  const uploadProspectusDocuments = values.prospectusDocuments
    .filter(needsUpload)
    .map(mapForUpload);

  const updateProspectusDocuments = values.prospectusDocuments
    .filter(needsUpdate)
    .map(mapForUpdate);

  const updateProspectusDocumentsMetadata = values.prospectusDocuments
    .filter(doc => !doc.file && !!doc.id) // Filter non update documents
    .filter(doc => hasMetadataChanged(doc, initialValues))
    .map(mapForMetadataUpdate);

  return {
    updateProspectusDocuments,
    uploadProspectusDocuments,
    updateProspectusDocumentsMetadata,
  };
}
