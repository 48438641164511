import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';
import styled from 'styled-components/macro';

import Modal from '../../../overlays/modal/Modal';
import AdvancedInvestorSearch from './advanced-search/AdvancedInvestorSearch';
import { AdvancedInvestorSearchFlowProvider } from './AdvancedInvestorSearchFlowContext';
import CreateNewInvestor from './create-new-investor/CreateNewInvestor';
import { InvestorFirmSearch_Advanced_InvestorBasicPartsFragment } from './graphql';

export const StyledModal = styled(Modal)`
  min-height: 800px;
  display: flex;
  flex-direction: column;
`;

type OwnProps = {
  onApply: (investor: InvestorFirmSearch_Advanced_InvestorBasicPartsFragment) => void;
  searchText?: string;
  showCreateNewInvestor: boolean;
};

export type Props = OwnProps & InjectedProps;

export const AdvancedInvestorSearchFlowModal: React.FC<Props> = ({
  handleHide,
  onApply,
  searchText,
  showCreateNewInvestor,
}) => {
  const [mode, setMode] = React.useState<'advanced-search' | 'create-new'>('advanced-search');

  return (
    <StyledModal size="xxlarge" show title="Advanced Investor Search" onHide={handleHide}>
      <AdvancedInvestorSearchFlowProvider searchText={searchText}>
        {mode === 'advanced-search' && (
          <AdvancedInvestorSearch
            onApply={onApply}
            onClose={handleHide}
            onCreateNewInvestor={() => setMode('create-new')}
            showCreateNewInvestor={showCreateNewInvestor}
          />
        )}
        {mode === 'create-new' && (
          <CreateNewInvestor
            onClose={handleHide}
            onApply={onApply}
            goBack={() => setMode('advanced-search')}
          />
        )}
      </AdvancedInvestorSearchFlowProvider>
    </StyledModal>
  );
};

const name = 'INVESTOR_SEARCH/ADVANCED_INVESTOR_SEARCH_FLOW_MODAL';
export const openAdvancedInvestorSearchFlowModal = (props: OwnProps) => show(name, props);
export const closeAdvancedInvestorSearchFlowModal = () => hide(name);

export default connectModal({ name })(AdvancedInvestorSearchFlowModal);
