import { css } from 'styled-components/macro';

export const CLOSE_TIMEOUT_MS = 200;
export type ModalSize = 'small' | 'large' | 'xlarge' | 'xxlarge';

export const contentAndOverlayCss = css<{ size?: ModalSize }>`
  width: 95%;
  margin: auto;
  background: ${({ theme }) => theme.background.color.white};
  align-self: flex-start;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex !important;
    height: 100vh;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: ${({ theme }) => theme.zIndex.modal};
    opacity: 0;
    transition: opacity ${CLOSE_TIMEOUT_MS}ms ease-in-out;
    overflow: auto;
    padding: 30px 0;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  max-width: ${({ size = 'small' }) => {
    switch (size) {
      case 'xxlarge':
        return '1280px';
      case 'xlarge':
        return '1100px';
      case 'large':
        return '900px';
      case 'small':
        return '650px';
    }
  }};
`;
