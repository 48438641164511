import React from 'react';

import {
  OfferingSidePanelModel,
  offeringSidePanelModelDefaults,
  useOfferingSidePanelModel,
} from './hooks/useOfferingSidePanelModel';

/*
The OfferingSidePanelContext is in charge of handling all the Offering side panel data, and also helps us have a 
centralized place that we can use to decide what actions we can do with the section.

Here we will detail of the different areas that Indication will have, for example certificates.
*/
const OfferingSidePanelContext = React.createContext<OfferingSidePanelModel>(
  offeringSidePanelModelDefaults
);

export const useOfferingSidePanelContext = (): OfferingSidePanelModel => {
  const context = React.useContext<OfferingSidePanelModel>(OfferingSidePanelContext);
  if (context === undefined) {
    throw new Error('No Provider found for OfferingSidePanelContext');
  }
  return context;
};

type ProviderProps = {
  offeringId: string;
  cmgEntityKey: string | undefined;
  indicationId?: string;
};

export const OfferingSidePanelContextProvider: React.FC<ProviderProps> = ({
  offeringId,
  cmgEntityKey,
  indicationId,
  children,
}) => {
  const sidePanelData = useOfferingSidePanelModel({ offeringId, cmgEntityKey, indicationId });

  return (
    <OfferingSidePanelContext.Provider value={{ ...sidePanelData }}>
      {children}
    </OfferingSidePanelContext.Provider>
  );
};
