import { Panel, ToastManager } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../common/util/routeFactory';
import { validate } from '../deal-team-details/deal-team-form/DealTeamForm.model';
import CreateDealTeamForm from './create-deal-team-form/CreateDealTeamForm';
import {
  CreateDealTeamFormValues,
  createDealTeamSchema,
  getPayload,
} from './create-deal-team-form/CreateDealTeamForm.model';
import CreateDealTeamHeader from './header/CreateDealTeamHeader';
import { useCreateDealTeamMutation } from './hooks/useCreateDealTeamMutation';

const RoadshowsCreateDealTeamRoute: React.FC = () => {
  const [createDealTeam, { loading }] = useCreateDealTeamMutation();
  const { push } = useHistory();

  const formik = useFormik<CreateDealTeamFormValues>({
    initialValues: {
      organizationField: null,
      departments: [],
      dealTeamMembers: [],
    },
    validate: values => validate(values, createDealTeamSchema),
    validateOnBlur: true,
    onSubmit: async values => {
      try {
        await createDealTeam({
          variables: {
            payload: getPayload(values),
          },
        });
        ToastManager.success('Successfully created Deal Team');
        push(routeFactory.roadshowsDealTeams.getUrlPath());
      } catch {
        ToastManager.error('There was an error while creating Deal Team');
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <FlexLayout direction="column" data-test-id="roadshows / single-deal-team / create-deal-team">
        <CreateDealTeamHeader onCreate={formik.submitForm} isLoading={loading} />
        <FlexLayout direction="row">
          <Panel fillViewport>
            <CreateDealTeamForm />
          </Panel>
        </FlexLayout>
      </FlexLayout>
    </FormikProvider>
  );
};

export default RoadshowsCreateDealTeamRoute;
