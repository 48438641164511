import { useCheckAccountTraits } from '@cmg/auth';

import { useFeatureToggles } from '../../../../common/config';
import { OfferingStatus } from '../../../../graphql';
import { useCalendarPublishedOfferings } from '../../components/calendar-grid/hooks/useCalendarPublishedOfferings';
import { useCalendarAccess } from '../../hooks/useCalendarAccess';
import { getPricedOfferingsDateBoundaries } from '../../utils/dates';
import { useCalendar_PricedCalendarOfferingsListQuery } from '../graphql';

export function useGetPricedCalendarOfferingsQuery() {
  const { start, end } = getPricedOfferingsDateBoundaries();

  const { isCalendarUsingOfferingFull } = useFeatureToggles();

  const offeringFullQuery = useCalendarPublishedOfferings({
    skip: !isCalendarUsingOfferingFull,
    where: {
      status: { eq: OfferingStatus.Priced },
      timing: {
        pricingDate: {
          gte: start,
          lte: end,
        },
      },
    },
  });

  const remappedOfferingFullQuery = {
    ...offeringFullQuery,
    data: {
      pricedPublishedOfferings: {
        data: offeringFullQuery.data?.publishedOfferings?.items,
      },
    },
  };

  const {
    allowMyInstitutionalIndication,
    allowMDLIndications,
    allowMDLAllocation,
    allowFinalAllocation,
  } = useCalendarAccess({
    enableSecondarySources: true,
  });

  const includeTestOfferings = useCheckAccountTraits(['XC_TEST_OFFERINGS_IN_CALENDAR']);

  const pricedOfferingsListQuery = useCalendar_PricedCalendarOfferingsListQuery({
    variables: {
      startPricingDate: start,
      endPricingDate: end,
      allowMyInstitutionalIndication,
      allowMDLIndications,
      allowMDLAllocation,
      allowFinalAllocation,
      includeTestOfferings,
    },
    fetchPolicy: 'no-cache',
    skip: isCalendarUsingOfferingFull,
  });

  if (remappedOfferingFullQuery.called) {
    return remappedOfferingFullQuery;
  }
  return pricedOfferingsListQuery;
}
