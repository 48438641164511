import { Alert, IconButton, numericUtil } from '@cmg/common';
import React from 'react';

import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';

export type Props = {
  onDismiss: () => void;
  unchangedInvestors: SalesCredits_SalesCreditItemPartsFragment[];
};

const StatusNotChangedAlert: React.FC<Props> = ({ onDismiss, unchangedInvestors }) => {
  const investorLength = unchangedInvestors.length;
  const investorDisplayNumber = numericUtil.getDisplayValueForNumber(investorLength, 0);

  return (
    <Alert
      severity="error"
      withMargin={true}
      action={<IconButton icon={{ name: 'times', size: 'lg' }} onClick={onDismiss} />}
    >
      <Alert.Title>
        {`Status not changed for ${investorDisplayNumber} investor${investorLength > 1 ? 's' : ''}`}
      </Alert.Title>
      <Alert.Details>Sales Credits Release status does not allow this action.</Alert.Details>
    </Alert>
  );
};

export default StatusNotChangedAlert;
