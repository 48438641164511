import React from 'react';

import { OfferingSetup_Prospectus_ProspectusDocumentsQuery } from '../../../graphql';
import { ProspectusFormDocument } from '../../ProspectusRoute.model';
import { StyledLinkCell } from './FileLinkCell.styles';

type Props = {
  item: ProspectusFormDocument;
  versionInfo?: OfferingSetup_Prospectus_ProspectusDocumentsQuery['offering']['versions'][0];
};

export const FileLinkCell: React.FC<Props> = ({
  item: { publicUrl, firstPublishedAt, includeOnPublish, id, file, uploadedAt },
  versionInfo,
}) => {
  if (
    (file && id) ||
    (uploadedAt && versionInfo && new Date(uploadedAt) > new Date(versionInfo.timestamp))
  ) {
    return (
      <StyledLinkCell>
        {includeOnPublish
          ? 'The existing link will be updated with the new document upon Publish'
          : 'The link will be disabled on publishing of the offering'}
      </StyledLinkCell>
    );
  }

  return (
    <StyledLinkCell>
      {includeOnPublish && !!publicUrl && (
        <a href={publicUrl} target="_blank" rel="noreferrer">
          {publicUrl}
        </a>
      )}

      {includeOnPublish && !publicUrl && 'A link is generated on publishing of the offering'}

      {!includeOnPublish && !!publicUrl && (
        <span>
          <a href={publicUrl} target="_blank" rel="noreferrer">
            {publicUrl}
          </a>
          <br />
          The link will be disabled on publishing of the offering
        </span>
      )}

      {!includeOnPublish && !publicUrl && (firstPublishedAt ? 'Link has been disabled' : '-')}
    </StyledLinkCell>
  );
};
