import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FormikProps } from 'formik';
import React from 'react';

import type { FundIoisFormValues } from '../../types';
import EmptySectionMessage from '../empty-section-message/EmptySectionMessage';
import FormSectionHeader from '../form-section-header/FormSectionHeader';
import InterestLevel from '../interest-level/InterestLevel';
import InterestLevelsTableHeader from '../interest-levels-table-header/InterestLevelsTableHeader';
import { InterestLevelsContainer } from './InterestLevels.styles';

export type Props = {
  onItemAdded: () => void;
  onItemRemoved: () => void;
  submitting: boolean;
};

const InterestLevels: React.FC<Props> = ({ onItemAdded, onItemRemoved, submitting }) => {
  return (
    <InterestLevelsContainer>
      <FieldArray name="interestLevels" validateOnChange={false}>
        {arrayHelpers => {
          const form = arrayHelpers.form as FormikProps<FundIoisFormValues>;
          const interestLevels = form.values.interestLevels;
          const containsLimitType = !!interestLevels?.find(
            ({ pricingType }) => pricingType === 'limit'
          );

          const handleAddInterestLevel = () => {
            arrayHelpers.push({
              unitType: null,
              quantity: null,
              dollars: null,
              percentage: null,
              shares: null,
              pricingType: null,
              limitPrice: null,
            });
            setTimeout(onItemAdded, 0);
          };

          const handleRemoveItem = (index: number) => {
            arrayHelpers.remove(index);
            setTimeout(onItemRemoved, 0);
          };

          return (
            <div>
              <FormSectionHeader
                title="Interest Levels"
                isAddEnabled={true}
                addButtonText="Add Interest Level"
                addButtonTestId={xcSelectors.fundLevelDemandInterestLevelAddButton.testId}
                handleAddClick={handleAddInterestLevel}
              />

              {!Array.isArray(interestLevels) || !interestLevels.length ? (
                <EmptySectionMessage text="No indications of interest have been entered" />
              ) : (
                <div>
                  <InterestLevelsTableHeader displayLimitConfig={containsLimitType} />
                  {interestLevels.map((interestLevel, index) => (
                    <InterestLevel
                      key={index}
                      interestLevel={interestLevel}
                      index={index}
                      replace={arrayHelpers.replace}
                      remove={handleRemoveItem}
                      submitting={submitting}
                    />
                  ))}
                </div>
              )}
            </div>
          );
        }}
      </FieldArray>
    </InterestLevelsContainer>
  );
};

export default InterestLevels;
