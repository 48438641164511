import React from 'react';

import { SContent, STitle, SWrapper } from './EventFormSubHeader.styles';

export type Props = { title: string };

const EventFormSubHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <SWrapper>
      <STitle>{title}</STitle>
      <SContent>{children}</SContent>
    </SWrapper>
  );
};

export default EventFormSubHeader;
