import { Alert, IconButton } from '@cmg/common';
import React from 'react';

export type Props = { onDismiss: () => void };

const FormNotValidAlert: React.FC<Props> = ({ onDismiss }) => {
  return (
    <Alert
      severity="error"
      action={<IconButton icon={{ name: 'times', size: 'lg' }} onClick={onDismiss} />}
      withMargin
    >
      <Alert.Title>
        An entry is required or has an invalid value for the following fields:
      </Alert.Title>
      <Alert.Details>Sales Credits</Alert.Details>
    </Alert>
  );
};

export default FormNotValidAlert;
