import { UUID } from '@cmg/common';
import type { CustomToolPanelProps } from '@cmg/data-grid';
import { Alert, List, ListItem, ListItemText, Skeleton } from '@cmg/design-system';
import React from 'react';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../graphql/__generated__';
import type { DemandGridDataContext } from '../types';
import AllocationSetsPage from './components/allocation-sets/AllocationSetsPage';
import { useSeparateDraftSets } from './hooks/useSeparateDraftSets';
import { ToolPanel } from './ToolPanel';

export type AllocationSetsToolPanelProps = Readonly<{
  offeringId: UUID;
  allocationSets: DemandGridDataContext['allocationSets'];
}>;

type Props = CustomToolPanelProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  DemandGridDataContext
> &
  AllocationSetsToolPanelProps;

export const AllocationSetsToolPanel: React.FC<Props> = ({ offeringId, allocationSets }) => {
  const finalAllocation = allocationSets.final;
  const draftAllocations = Object.values(allocationSets.drafts);

  const { draftSets, isLoadingSettlementAgent, error } = useSeparateDraftSets(
    draftAllocations,
    offeringId
  );

  return (
    <ToolPanel title="Allocations">
      {error && !isLoadingSettlementAgent && (
        <Alert severity="error">Failed to fetch Settlement Agent information</Alert>
      )}
      {isLoadingSettlementAgent && (
        <List>
          <ListItem>
            <ListItemText>
              <Skeleton role="progressbar" />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Skeleton role="progressbar" />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Skeleton role="progressbar" />
            </ListItemText>
          </ListItem>
        </List>
      )}
      {!isLoadingSettlementAgent && (
        <AllocationSetsPage
          offeringId={offeringId}
          finalAllocation={finalAllocation}
          draftSets={draftSets}
        />
      )}
    </ToolPanel>
  );
};
