import { Stack, Tooltip, WarningIcon } from '@cmg/design-system';
import React from 'react';

const AllocationFormTooltip: React.FC = ({ children }) => {
  return (
    <Tooltip title={children} placement="top-start" arrow>
      <Stack alignItems="center" marginLeft={1}>
        <WarningIcon color="warning" />
      </Stack>
    </Tooltip>
  );
};

export default AllocationFormTooltip;
