import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import DisclaimersForm from './forms/DisclaimersForm';
import RegistrationLinksForm from './forms/RegistrationLinksForm';
import {
  useOfferingSetup_DisclaimersQuery,
  useOfferingSetup_UpdateDisclaimersMutation,
} from './graphql';
import Rule134Legend from './rule-134-legend/Rule134Legend';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Disclaimers Route for Offering Setup
 * Displays Registration Links Form & two text areas for disclaimers
 */
export const DisclaimersRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const { data, loading, error, refetch } = useOfferingSetup_DisclaimersQuery({
    variables: { offeringId: offeringId },
  });

  const { revalidate } = useValidateOffering(offeringId);

  const [updateDisclaimers, { loading: updatingDisclaimers, error: updateDisclaimersError }] =
    useOfferingSetup_UpdateDisclaimersMutation({
      onCompleted: () => {
        revalidate();
        refetch();
        ToastManager.success('Updated Disclaimers');
      },
      onError: () => {
        ToastManager.error('Failed to update Disclaimers');
      },
    });

  const disclaimers = data?.offering.disclaimers;
  const errors = error || updateDisclaimersError;

  if (loading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        <SetupScreen.Header />
        {errors && <ServerErrorsBanner error={errors} />}
        {disclaimers && (
          <React.Fragment>
            <RegistrationLinksForm
              errors={errors}
              disclaimers={disclaimers}
              loading={loading || updatingDisclaimers}
              onSubmit={values =>
                updateDisclaimers({
                  variables: {
                    offeringId,
                    input: {
                      ...values,
                      // this form should only update registrationLinks, not restrictions or footnotes
                      // independently update the disclaimers object
                      restrictions: disclaimers.restrictions,
                      footnotes: disclaimers.footnotes,
                    },
                  },
                })
              }
            />
            <DisclaimersForm
              disclaimers={disclaimers}
              loading={loading || updatingDisclaimers}
              onSubmit={values =>
                updateDisclaimers({
                  variables: {
                    offeringId,
                    input: {
                      ...values,
                      // this form should only update restrictions & footnotes, not registrationLinks
                      // independently update the disclaimers object
                      registrationLinks: disclaimers.registrationLinks,
                    },
                  },
                })
              }
            />
            {disclaimers?.rule134Legend && (
              <Rule134Legend>{disclaimers?.rule134Legend}</Rule134Legend>
            )}
          </React.Fragment>
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default DisclaimersRoute;
