import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import * as Yup from 'yup';

import { MAX_32_BIT_INT } from '../../../../../../../types/graphql/constants';

export const getAllocationUpdateInput = (shareQuantity: number | null) => {
  return {
    shareQuantity: isNumber(shareQuantity) ? shareQuantity : null,
  };
};

export type IndicationAllocable = {
  isAllocable: boolean;
};

export const isIndicationAllocatable = ({
  isAuthor,
}: {
  isAuthor: boolean;
}): IndicationAllocable => {
  if (!isAuthor) {
    return {
      isAllocable: false,
    };
  }
  return { isAllocable: true };
};

/**
 * Editing of the allocation field is still possible even when the indication is
 * not allocatable if a share quantity exists.
 * For example, an Indication can be allocated shares - and then cancelled.
 * In this scenario, users will need to edit - or more specifically, remove -
 * those allocated shares in order to release final allocations.
 * If final allocations have been released, editing is disabled, but it's still
 * possible to edit allocations via the Released Allocations Edit mode
 */
export const isFinalAllocationAllocable = ({
  canRead,
  canManage,
  canAcknowledge,
  isEditing,
  isFinalAllocationSetReleased,
}: {
  canRead: boolean;
  canManage: boolean;
  canAcknowledge: boolean;
  isEditing: boolean;
  isFinalAllocationSetReleased: boolean;
}): IndicationAllocable => {
  if (!canRead || !canManage || !canAcknowledge || (!isEditing && isFinalAllocationSetReleased)) {
    return {
      isAllocable: false,
    };
  }

  return { isAllocable: true };
};

export const allocationFormSchema = Yup.object().shape({
  shareQuantity: Yup.mixed<number>()
    .nullable()
    .test('test-shareQuantity', 'Invalid Allocation Amount', value => {
      // Share quantity can be nil or a value greater than or equal to 0.
      return isNil(value) || (value >= 0 && value <= MAX_32_BIT_INT);
    }),
});
