import { Icon, UUID } from '@cmg/common';
import React from 'react';

import NavItem from '../../../../../../../common/components/navigation/side-navigation/NavItem';
import { HorizontalLine } from '../../../../../../../common/components/presentation';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { SSidePanelNavbar } from '../../../../../../offering-side-panel/components/side-bar/SidePanelNavbar.styles';
import { SLeftHeading } from '../OverwriteDesignationSidePanel.styles';

export type Props = {
  offeringId: UUID;
  indicationId: UUID;
};

const OverwriteDesignationSideNavbar: React.FC<Props> = ({ offeringId, indicationId }) => {
  return (
    <SSidePanelNavbar>
      <SLeftHeading>Designation</SLeftHeading>
      <HorizontalLine />
      <NavItem
        url={routeFactory.finalSettlementDesignationMonitorOverwrite.getUrlPath({
          offeringId: offeringId,
          indicationId: indicationId,
        })}
      >
        <Icon name="file-invoice-dollar" />
        Designation Details
      </NavItem>
    </SSidePanelNavbar>
  );
};

export default OverwriteDesignationSideNavbar;
