import { ApolloLink, FetchResult, Operation } from '@apollo/client';
import { Observable } from '@apollo/client/utilities';
import { ExecutionResult, print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-sse';

/* istanbul ignore next */
export class SseApolloLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<ExecutionResult<FetchResult>> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}
