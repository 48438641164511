import { Button, LoadingButton, SnackbarManager } from '@cmg/design-system';
import { Form, FormikProvider, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import React from 'react';

import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { ContactSource } from '../../../../../../graphql';
import { InstitutionalIndicationType } from '../indication-form/components/indication-details/IndicationDetails';
import {
  IndicationForm_IndicationPartsFragment,
  IndicationForm_OfferingPartsFragment,
} from '../indication-form/graphql/__generated__';
import { InvestorContactAlertBanner } from './alert-banner/InvestorContactAlertBanner';
import { useAssignContactsMutation } from './hooks/useAssignContactsMutation';
import { useContactsQuery } from './hooks/useContactsQuery';
import {
  contactsValidationSchema,
  InvestorContactValues,
  mapToFormValues,
  mapValuesToInput,
} from './InvestorContactInformationForm.model';
import InvestorContactInformation from './layout/InvestorContactInformation';

export type Props = {
  isCoveredAccount: boolean;
  setDisplayMissingContacts: (value: boolean) => void;
  displayMissingContacts: boolean;
  indication: InstitutionalIndicationType & {
    investorInformation?: IndicationForm_IndicationPartsFragment['investorInformation'];
  };
  offering: IndicationForm_OfferingPartsFragment;
  prospectusContactMandatory?: boolean;
  isInvestorCoveredAndEditable?: boolean;
};

const InvestorContactInformationForm: React.FC<Props> = ({
  isCoveredAccount,
  setDisplayMissingContacts,
  displayMissingContacts,
  indication,
  offering,
  prospectusContactMandatory = false,
  isInvestorCoveredAndEditable,
}) => {
  const [isEditingContacts, setIsEditingContacts] = React.useState(false);

  const { contacts, loading, error } = useContactsQuery(
    {
      indicationId: indication.id,
      offeringId: offering.id,
    },
    isCoveredAccount
  );

  const [assignContacts, { loading: assignContactsLoading }] =
    useAssignContactsMutation(isCoveredAccount);

  const { buySideContacts, sellSideContacts } = React.useMemo(
    () => ({
      buySideContacts:
        contacts?.contacts
          .filter(item => item.source === ContactSource.BuySide)
          .map(mapToFormValues) ?? [],
      sellSideContacts:
        contacts?.contacts
          .filter(item => item.source === ContactSource.SellSide)
          .map(mapToFormValues) ?? [],
    }),
    [contacts]
  );

  const formik = useFormik<InvestorContactValues>({
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: {
      buySideContacts,
      sellSideContacts,
    },
    onSubmit: async (values, { setValues }) => {
      try {
        await assignContacts({
          variables: {
            indicationId: indication.id,
            offeringId: offering.id,
            input: mapValuesToInput(values.sellSideContacts),
          },
        });

        SnackbarManager.success('Successfully updated Investor contact information');
        setValues(
          Object.assign(values, {
            sellSideContacts: values.sellSideContacts.filter(
              item => item.email !== '' && item.name !== ''
            ),
          })
        ); // remove empty rows from formik
        setIsEditingContacts(false);
      } catch {
        SnackbarManager.error('Failed to update Investor contact information');
      }
    },
    enableReinitialize: true,
    validate: async values => {
      try {
        await validateYupSchema(values, contactsValidationSchema, false, {
          prospectusContactMandatory: prospectusContactMandatory && isCoveredAccount, // prevent sending only for S&T users
        });
        return {};
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
  });

  React.useEffect(() => {
    if (!loading) {
      setDisplayMissingContacts(
        sellSideContacts.length === 0 && buySideContacts.length === 0 && prospectusContactMandatory
      );
    }
  }, [
    sellSideContacts,
    buySideContacts,
    prospectusContactMandatory,
    setDisplayMissingContacts,
    loading,
  ]);

  return (
    <FormikProvider value={formik}>
      {error && <ServerErrorsBanner error={error} />}
      <InvestorContactAlertBanner />
      <Form>
        <InvestorContactInformation
          prospectusContactMandatory={prospectusContactMandatory}
          bankInvestorKey={indication.investorInformation?.bankInvestorKey}
          buySideContacts={formik.values.buySideContacts}
          sellSideContacts={formik.values.sellSideContacts}
          editing={isEditingContacts}
          bankInvestorName={indication.investorInformation?.bankInvestorName}
          saving={assignContactsLoading}
          loading={loading}
          isCoveredAccount={isCoveredAccount}
          displayMissingContacts={displayMissingContacts}
          actions={[
            ...(isEditingContacts
              ? [
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setIsEditingContacts(false);
                    }}
                    disabled={loading}
                    variant="outlined"
                    key="cancel"
                  >
                    Cancel
                  </Button>,
                  <LoadingButton
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    loading={assignContactsLoading}
                    key="save"
                  >
                    Save
                  </LoadingButton>,
                ]
              : []),
            ...(!isEditingContacts && isInvestorCoveredAndEditable
              ? [
                  <Button key="edit" variant="outlined" onClick={() => setIsEditingContacts(true)}>
                    Edit
                  </Button>,
                ]
              : []),
          ]}
        ></InvestorContactInformation>
      </Form>
    </FormikProvider>
  );
};

export default InvestorContactInformationForm;
