import styled from 'styled-components/macro';

export type HeroGraphicsSize = 'small' | 'large';

const SContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const STextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const STitle = styled.div<{ size: HeroGraphicsSize }>`
  font-size: ${({ theme, size }) => (size === 'large' ? theme.text.size.xl : theme.text.size.s)};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 30px;
  padding-top: ${({ size }) => (size === 'large' ? '16px' : '2px')};
`;

const SSubtitle = styled.div<{ size: HeroGraphicsSize }>`
  font-size: ${({ theme, size }) => (size === 'large' ? theme.text.size.m : theme.text.size.s)};
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  line-height: ${({ size }) => (size === 'large' ? '20px' : undefined)};
  padding-top: ${({ size }) => (size === 'large' ? '8px' : '0')};
`;

const SImage = styled.img<{ size: HeroGraphicsSize }>`
  max-width: ${({ size }) => (size === 'large' ? '300px' : '80px')};
  max-height: ${({ size }) => (size === 'large' ? '300px' : '80px')};
`;

const HeroGraphicsLayout = styled.div<{ size: HeroGraphicsSize }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ size }) => (size === 'large' ? '32px' : '16px')};
`;

export default Object.assign({}, HeroGraphicsLayout, {
  Container: SContainer,
  TextContainer: STextContainer,
  Title: STitle,
  Subtitle: SSubtitle,
  Image: SImage,
});
