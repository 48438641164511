/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_SearchOfferingsQueryVariables = Types.Exact<{
  perPage?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  issuerName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type Roadshows_SearchOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly offerings: {
    readonly __typename?: 'OfferingPaginatedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly issuerName: string;
      readonly status: Types.OfferingStatus;
      readonly pricingInstrumentStockSymbol?: string | null;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly perPage: number;
      readonly activePage: number;
      readonly hasNext?: boolean | null;
    };
  };
};

export const Roadshows_SearchOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_SearchOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'issuerName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offerings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'issuerName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'issuerName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeTotals' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'IPO' },
                    { kind: 'EnumValue', value: 'IPO_SPAC' },
                    { kind: 'EnumValue', value: 'MARKETED_FO' },
                    { kind: 'EnumValue', value: 'REGISTERED_BLOCK' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activePage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_SearchOfferingsQuery__
 *
 * To run a query within a React component, call `useRoadshows_SearchOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_SearchOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_SearchOfferingsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      issuerName: // value for 'issuerName'
 *   },
 * });
 */
export function useRoadshows_SearchOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Roadshows_SearchOfferingsQuery,
    Roadshows_SearchOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_SearchOfferingsQuery, Roadshows_SearchOfferingsQueryVariables>(
    Roadshows_SearchOfferingsDocument,
    options
  );
}
export function useRoadshows_SearchOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_SearchOfferingsQuery,
    Roadshows_SearchOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_SearchOfferingsQuery,
    Roadshows_SearchOfferingsQueryVariables
  >(Roadshows_SearchOfferingsDocument, options);
}
export type Roadshows_SearchOfferingsQueryHookResult = ReturnType<
  typeof useRoadshows_SearchOfferingsQuery
>;
export type Roadshows_SearchOfferingsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_SearchOfferingsLazyQuery
>;
export type Roadshows_SearchOfferingsQueryResult = Apollo.QueryResult<
  Roadshows_SearchOfferingsQuery,
  Roadshows_SearchOfferingsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_SearchOfferingsMockResponse = makeMockResponse<
  Roadshows_SearchOfferingsQueryVariables,
  Roadshows_SearchOfferingsQuery
>(Roadshows_SearchOfferingsDocument);
