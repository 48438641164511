import { ButtonsContainer } from '@cmg/common';
import React from 'react';

import { SHeader, STitle } from './SyndicateWireHeader.styles';

type Props = {
  title: React.ReactNode;
};

export const SyndicateWireHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <SHeader>
      <STitle>{title}</STitle>
      <ButtonsContainer>{children}</ButtonsContainer>
    </SHeader>
  );
};
