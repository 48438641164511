import { numericUtil } from '@cmg/common';

import { FundItem } from '../../FundLevelDemand';

/**
 * Format ioi value based on its unit type
 */
export const formatDemandItemValue = (ioi: FundItem['iois'][number]) => {
  if (ioi.unitType === 'dollars') {
    return numericUtil.formatCurrency(ioi.dollars, 0);
  }

  if (ioi.unitType === 'percentage') {
    return numericUtil.formatPercents(ioi.percentage);
  }

  if (ioi.unitType === 'shares') {
    return `${numericUtil.formatNumber(ioi.shares, 0)} Shares`;
  }

  return '';
};
