import styled from 'styled-components/macro';

export const SDupesGridHeader = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
`;
export const SDupesGridAlert = styled.div`
  margin-bottom: 40px;
`;
export const SDupesGridContainer = styled.div`
  height: 200px;
`;
export const SDupeBannerWrapper = styled.div`
  margin-top: -10px;
`;
