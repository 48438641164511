import {
  Compliance_Syndicate_AttestationQueryVariables,
  useCompliance_Syndicate_AttestationQuery,
} from './graphql';

export const useSyndicateAttestationQuery = (
  variables: Compliance_Syndicate_AttestationQueryVariables,
  { skip }: { skip: boolean }
) => {
  return useCompliance_Syndicate_AttestationQuery({
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
  });
};
