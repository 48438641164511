import { useAuth } from '@cmg/auth';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import {
  InstitutionalDemandGridUpdate,
  UpdateType,
} from './useComputeDiffOnInstitutionalDemandGrid';

const sortById = (a, b) => {
  const idA = a.id;
  const idB = b.id;
  if (idA < idB) {
    return -1;
  }
  if (idA > idB) {
    return 1;
  }
  return 0;
};

export const enum FinalAllocationPendingUpdateLabels {
  ALLOCATION_SET_RELEASED = 'Final Allocation Set Released',
  ALLOCATION_SET_SHARED = 'Final Allocation Set Shared',
  ALLOCATION_SHARE_QUANTITY_UPDATED = 'Allocation Share Quantity Updated',
  ALLOCATION_ACK_STATUS_UPDATED = 'Allocation Acknowledgement Status Updated',
}

export type FinalAllocationSetArg =
  InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalFinalAllocationSet'];

export const useComputeDiffOnFinalAllocationSet = function (
  currFinalAllocationSet: FinalAllocationSetArg,
  nextFinalAllocationSet: FinalAllocationSetArg
) {
  const { oidcUserEmail: currentUserEmail } = useAuth();

  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return React.useMemo(() => {
    const finalAllocationChanges: InstitutionalDemandGridUpdate[] = [];

    // share/SA changes/release
    if (!currFinalAllocationSet && nextFinalAllocationSet) {
      if (nextFinalAllocationSet.isReleased) {
        finalAllocationChanges.push({
          type: UpdateType.NEW_FINAL_ALLOCATION,
          indicationId: '',
          pendingUpdateLabel: [FinalAllocationPendingUpdateLabels.ALLOCATION_SET_RELEASED],
          timeStamp: new Date(nextFinalAllocationSet.releaseTimestamp!),
          id: uuidV4(),
        });
      }
      if (nextFinalAllocationSet.shareTimestamp) {
        finalAllocationChanges.push({
          type: UpdateType.NEW_FINAL_ALLOCATION,
          indicationId: '',
          pendingUpdateLabel: [FinalAllocationPendingUpdateLabels.ALLOCATION_SET_SHARED],
          timeStamp: new Date(nextFinalAllocationSet.shareTimestamp!),
          id: uuidV4(),
        });
      }
    }

    // allocation updated
    if (currFinalAllocationSet && nextFinalAllocationSet) {
      const curr = currFinalAllocationSet.allocations;
      const next = nextFinalAllocationSet.allocations;

      if (curr.length !== next.length) {
        const long = next.length > curr.length ? next : curr;
        const short = next.length < curr.length ? next : curr;

        const emptyAllocations = long.filter(alloc => {
          return !short.find(a => a.indicationId === alloc.indicationId);
        });

        emptyAllocations.forEach(emptyAllocation => {
          if (
            emptyAllocation.auditInfo.modifiedByUserEmail !== currentUserEmail ||
            emptyAllocation.auditInfo.modifiedByUserEmail === null
          ) {
            finalAllocationChanges.push({
              indicationId: emptyAllocation.indicationId,
              type: UpdateType.FINAL_ALLOCATION_UPDATE,
              pendingUpdateLabel: [
                FinalAllocationPendingUpdateLabels.ALLOCATION_SHARE_QUANTITY_UPDATED,
              ],
              timeStamp: new Date(emptyAllocation.auditInfo.modifiedAt!),
              id: uuidV4(),
            });
          }
        });
      } else {
        const sortedCurrentAllocations = [...curr].sort(sortById);
        const sortedNextAllocations = [...next].sort(sortById);

        sortedCurrentAllocations.forEach((currentAlloc, alloIndex) => {
          const nextAlloc = sortedNextAllocations[alloIndex];
          const isAllocationVersionDifferent =
            currentAlloc.version !== nextAlloc.version ||
            currentAlloc.investorReply?.version !== nextAlloc.investorReply?.version;
          const isModifiedByDifferentUser =
            nextAlloc.auditInfo?.modifiedByUserEmail !== currentUserEmail ||
            nextAlloc.auditInfo?.modifiedByUserEmail === null;
          if (isAllocationVersionDifferent && isModifiedByDifferentUser) {
            const template: InstitutionalDemandGridUpdate = {
              type: UpdateType.FINAL_ALLOCATION_UPDATE,
              indicationId: nextAlloc.indicationId,
              pendingUpdateLabel: [],
              id: '',
              timeStamp: new Date(nextAlloc.auditInfo.modifiedAt!),
            };

            if (currentAlloc.investorReply?.status !== nextAlloc.investorReply?.status) {
              finalAllocationChanges.push({
                ...template,
                pendingUpdateLabel: [
                  FinalAllocationPendingUpdateLabels.ALLOCATION_ACK_STATUS_UPDATED,
                ],
                id: uuidV4(),
              });
            }
            if (currentAlloc.shareQuantity !== nextAlloc.shareQuantity) {
              finalAllocationChanges.push({
                ...template,
                pendingUpdateLabel: [
                  FinalAllocationPendingUpdateLabels.ALLOCATION_SHARE_QUANTITY_UPDATED,
                ],
                id: uuidV4(),
              });
            }
          }
        });
      }
    }

    return finalAllocationChanges;
  }, [currFinalAllocationSet, nextFinalAllocationSet, currentUserEmail]);
};
