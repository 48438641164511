import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  width: 100%;
  margin: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  background: ${({ theme }) => theme.background.color.white};
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 8px 16px;
`;

export const SHeaderTitle = styled.div`
  flex-grow: 1;
  padding: 8px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SContent = styled.div`
  background: ${({ theme }) => theme.background.color.white};
  padding: 16px;
  flex-grow: 1;
`;
