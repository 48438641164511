import usePollingWhenVisible from '../../../../../../../common/hooks/usePollingWhenVisible';
import { isValidCmgEntityKey } from '../../../../../../../common/util/isValidCmgEntityKey';
import { canBillingAndDeliveryAgentUpdateInstitutionalIndication } from '../../../../../../../common/util/offering/syndicate-entitlements';
import { OrderBookPersonaType } from '../../../../hooks/useIdentifyOrderBookPersona';
import { useSyndicateIndicationQuery } from '../../../context/hooks/syndicate/__generated__';
import {
  OfferingSidePanelModel,
  offeringSidePanelModelDefaults,
  PersonaProps,
} from '../useOfferingSidePanelModel';

export type Props = PersonaProps & {
  cmgEntityKey?: string | null;
  indicationId?: string | null;
};

export const useSyndicateIndicationModel = ({
  offeringId,
  cmgEntityKey,
  type,
  indicationId,
}: Props): OfferingSidePanelModel => {
  const {
    loading,
    data: indicationData,
    error,
    stopPolling,
    startPolling,
    called,
  } = useSyndicateIndicationQuery({
    variables: {
      offeringId,
      // cmgEntityKey context being actually firmKey means investor not paired to any cmg entity
      cmgEntityKey: isValidCmgEntityKey(cmgEntityKey!) ? cmgEntityKey : null,
      indicationId,
    },
    skip: !cmgEntityKey || type !== OrderBookPersonaType.SYNDICATE || !offeringId,
    fetchPolicy: 'cache-and-network',
  });

  usePollingWhenVisible({ pollInterval: 5_000, startPolling, stopPolling, called });

  const offering = indicationData?.basicPublishedOffering!;
  const orderBook = offering?.orderBook;
  const institutionalIndication = orderBook?.institutionalIndication;

  return {
    ...offeringSidePanelModelDefaults,
    indication: {
      data: institutionalIndication,
      canRead: true,
      canUpdate: true,
    },
    allocationAcknowledgment: {
      canRead: true,
      canUpdate: true,
      canBillingAndDeliveryAgentUpdate: canBillingAndDeliveryAgentUpdateInstitutionalIndication({
        billingAndDeliveryAgentCmgEntityKey:
          institutionalIndication?.billingAndDeliveryAgentCmgEntityKey,
        syndicatesEntitlements: offering?.syndicateEntitlements!.syndicateEntitlements,
      }),
    },
    queryResult: {
      data: {
        ...indicationData!,
        persona: OrderBookPersonaType.SYNDICATE,
      },
      loading,
      error: error ?? null,
      stopPolling,
    },
  };
};
