import {
  ListItem,
  ListItemProps,
  ListItemText,
  SvgIcon,
  TypographyProps,
} from '@cmg/design-system';
import { ElementType, FC, useMemo } from 'react';

import { ReactComponent as CmgLogo } from '../../../assets/identity-icon.svg';
import CmgLogoListItemIcon from './CmgLogoListItemIcon';
import { UnderwriterFirm } from './UnderwriterFirmSearchField.model';

export type UnderwriterFirmListItemProps = ListItemProps &
  Readonly<{
    option: UnderwriterFirm | undefined;
  }>;

const TYPOGRAPHY_PROPS: TypographyProps<ElementType> = { variant: 'body2' };

const UnderwriterFirmListItem: FC<UnderwriterFirmListItemProps> = ({ option, ...props }) => {
  const primaryText = option?.firmName ?? '';

  const secondaryText = useMemo(
    () =>
      [option?.mpid ? `MPID: ${option.mpid}` : '', option?.crd ? `CRD: ${option.crd}` : ''].join(
        ' '
      ),
    [option?.mpid, option?.crd]
  );

  return (
    <ListItem title={primaryText} {...props} dense divider disablePadding>
      <CmgLogoListItemIcon>
        {option?.isOnPlatform && (
          <SvgIcon fontSize="small" aria-label="On platform" role="img">
            <CmgLogo />
          </SvgIcon>
        )}
      </CmgLogoListItemIcon>
      <ListItemText
        primaryTypographyProps={TYPOGRAPHY_PROPS}
        secondaryTypographyProps={TYPOGRAPHY_PROPS}
        primary={primaryText}
        secondary={secondaryText}
      />
    </ListItem>
  );
};

export default UnderwriterFirmListItem;
