import { FlexContainer, IconButton, UUID } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SelectedDealTeamMember } from '../AddMembersModal.model';
import {
  SEmptyMembers,
  SSelectedMember,
  SSelectedMemberWrapper,
} from './SelectedDealTeamMembers.styles';

export type Props = {
  selectedMemberList: SelectedDealTeamMember[];
  onRemoveMember: (memberId: UUID) => void;
};

const SelectedDealTeamMembers: React.FC<Props> = ({ selectedMemberList, onRemoveMember }) => {
  const theme = useTheme();

  const isEmpty = selectedMemberList.length === 0;

  if (isEmpty) {
    return <SEmptyMembers>No members selected</SEmptyMembers>;
  }

  return (
    <FlexContainer direction="column" gap={8}>
      {selectedMemberList.map(({ fullName, firmName, id }) => (
        <SSelectedMemberWrapper key={id}>
          <SSelectedMember>
            {fullName} ({firmName})
          </SSelectedMember>

          <IconButton
            testId="remove button"
            icon={{ variant: 'solid', name: 'times', color: theme.text.color.white }}
            onClick={() => onRemoveMember(id)}
          />
        </SSelectedMemberWrapper>
      ))}
    </FlexContainer>
  );
};

export default SelectedDealTeamMembers;
