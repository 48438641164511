import React from 'react';

import ParticipantsForm, {
  Props as ParticipantsFormProps,
} from '../../../../common/participants-form/ParticipantsForm';
import LogisticsButtonGroup, {
  Props as ButtonGroupProps,
} from '../../../common/logistics-button-group/LogisticsButtonGroup';

export type Props = Omit<ParticipantsFormProps, 'title'> & Omit<ButtonGroupProps, 'isNewLogistics'>;

const LogisticsParticipantsForm: React.FC<Props> = ({
  participantTeams,
  logisticsHeaderInfo,
  onSubmit,
  companyName,
  isSaving,
  isDeleting,
  roster,
  onDelete,
}) => (
  <ParticipantsForm
    companyName={companyName}
    participantTeams={participantTeams}
    onSubmit={onSubmit}
    roster={roster}
    title="Participants"
  >
    <LogisticsButtonGroup
      isNewLogistics={false}
      isSaving={isSaving}
      logisticsHeaderInfo={logisticsHeaderInfo}
      isDeleting={isDeleting}
      onDelete={onDelete}
    />
  </ParticipantsForm>
);

export default LogisticsParticipantsForm;
