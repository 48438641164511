/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_DeleteLogisticsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  logisticsId: Types.Scalars['UUID'];
}>;

export type Roadshows_DeleteLogisticsMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteLogistics: string;
};

export type Roadshows_LogisticsQueryVariables = Types.Exact<{
  logisticsId: Types.Scalars['UUID'];
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_LogisticsQuery = {
  readonly __typename?: 'Query';
  readonly roadshowLogisticsDetails: {
    readonly __typename?: 'Logistics';
    readonly id: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly location: string;
    readonly notes?: string | null;
    readonly title: string;
  };
  readonly roadshowLogisticsParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export type Roadshows_UpdateLogisticsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  logisticsId: Types.Scalars['UUID'];
  payload: Types.UpdateLogisticsInput;
}>;

export type Roadshows_UpdateLogisticsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateLogistics: {
    readonly __typename?: 'Logistics';
    readonly id: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly location: string;
    readonly notes?: string | null;
    readonly title: string;
  };
};

export type Roadshows_UpdateLogisticsParticipantsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  logisticsId: Types.Scalars['UUID'];
  participantsIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  adHocParticipants: ReadonlyArray<Types.AdHocParticipantInput> | Types.AdHocParticipantInput;
}>;

export type Roadshows_UpdateLogisticsParticipantsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateRoadshowLogisticsParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
  readonly updateAdHocLogisticsParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export const Roadshows_DeleteLogisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_DeleteLogistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLogistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_DeleteLogisticsMutationFn = Apollo.MutationFunction<
  Roadshows_DeleteLogisticsMutation,
  Roadshows_DeleteLogisticsMutationVariables
>;

/**
 * __useRoadshows_DeleteLogisticsMutation__
 *
 * To run a mutation, you first call `useRoadshows_DeleteLogisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DeleteLogisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsDeleteLogisticsMutation, { data, loading, error }] = useRoadshows_DeleteLogisticsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      logisticsId: // value for 'logisticsId'
 *   },
 * });
 */
export function useRoadshows_DeleteLogisticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_DeleteLogisticsMutation,
    Roadshows_DeleteLogisticsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_DeleteLogisticsMutation,
    Roadshows_DeleteLogisticsMutationVariables
  >(Roadshows_DeleteLogisticsDocument, options);
}
export type Roadshows_DeleteLogisticsMutationHookResult = ReturnType<
  typeof useRoadshows_DeleteLogisticsMutation
>;
export type Roadshows_DeleteLogisticsMutationResult =
  Apollo.MutationResult<Roadshows_DeleteLogisticsMutation>;
export type Roadshows_DeleteLogisticsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_DeleteLogisticsMutation,
  Roadshows_DeleteLogisticsMutationVariables
>;
export const Roadshows_LogisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_Logistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowLogisticsDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowLogisticsParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Logistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_LogisticsQuery__
 *
 * To run a query within a React component, call `useRoadshows_LogisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_LogisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_LogisticsQuery({
 *   variables: {
 *      logisticsId: // value for 'logisticsId'
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_LogisticsQuery(
  baseOptions: Apollo.QueryHookOptions<Roadshows_LogisticsQuery, Roadshows_LogisticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_LogisticsQuery, Roadshows_LogisticsQueryVariables>(
    Roadshows_LogisticsDocument,
    options
  );
}
export function useRoadshows_LogisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_LogisticsQuery,
    Roadshows_LogisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_LogisticsQuery, Roadshows_LogisticsQueryVariables>(
    Roadshows_LogisticsDocument,
    options
  );
}
export type Roadshows_LogisticsQueryHookResult = ReturnType<typeof useRoadshows_LogisticsQuery>;
export type Roadshows_LogisticsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_LogisticsLazyQuery
>;
export type Roadshows_LogisticsQueryResult = Apollo.QueryResult<
  Roadshows_LogisticsQuery,
  Roadshows_LogisticsQueryVariables
>;
export const Roadshows_UpdateLogisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateLogistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateLogisticsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLogistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Logistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateLogisticsMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateLogisticsMutation,
  Roadshows_UpdateLogisticsMutationVariables
>;

/**
 * __useRoadshows_UpdateLogisticsMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateLogisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateLogisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateLogisticsMutation, { data, loading, error }] = useRoadshows_UpdateLogisticsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      logisticsId: // value for 'logisticsId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateLogisticsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateLogisticsMutation,
    Roadshows_UpdateLogisticsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateLogisticsMutation,
    Roadshows_UpdateLogisticsMutationVariables
  >(Roadshows_UpdateLogisticsDocument, options);
}
export type Roadshows_UpdateLogisticsMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateLogisticsMutation
>;
export type Roadshows_UpdateLogisticsMutationResult =
  Apollo.MutationResult<Roadshows_UpdateLogisticsMutation>;
export type Roadshows_UpdateLogisticsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateLogisticsMutation,
  Roadshows_UpdateLogisticsMutationVariables
>;
export const Roadshows_UpdateLogisticsParticipantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateLogisticsParticipants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'participantsIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adHocParticipants' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdHocParticipantInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoadshowLogisticsParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'participantsIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAdHocLogisticsParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adHocParticipants' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateLogisticsParticipantsMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateLogisticsParticipantsMutation,
  Roadshows_UpdateLogisticsParticipantsMutationVariables
>;

/**
 * __useRoadshows_UpdateLogisticsParticipantsMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateLogisticsParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateLogisticsParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateLogisticsParticipantsMutation, { data, loading, error }] = useRoadshows_UpdateLogisticsParticipantsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      logisticsId: // value for 'logisticsId'
 *      participantsIds: // value for 'participantsIds'
 *      adHocParticipants: // value for 'adHocParticipants'
 *   },
 * });
 */
export function useRoadshows_UpdateLogisticsParticipantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateLogisticsParticipantsMutation,
    Roadshows_UpdateLogisticsParticipantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateLogisticsParticipantsMutation,
    Roadshows_UpdateLogisticsParticipantsMutationVariables
  >(Roadshows_UpdateLogisticsParticipantsDocument, options);
}
export type Roadshows_UpdateLogisticsParticipantsMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateLogisticsParticipantsMutation
>;
export type Roadshows_UpdateLogisticsParticipantsMutationResult =
  Apollo.MutationResult<Roadshows_UpdateLogisticsParticipantsMutation>;
export type Roadshows_UpdateLogisticsParticipantsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateLogisticsParticipantsMutation,
  Roadshows_UpdateLogisticsParticipantsMutationVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_LogisticsMockResponse = makeMockResponse<
  Roadshows_LogisticsQueryVariables,
  Roadshows_LogisticsQuery
>(Roadshows_LogisticsDocument);
