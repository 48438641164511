import { LinkButton, ODSTable as Table } from '@cmg/common';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import dealTeam from '../../../../../../../common/assets/deal-team.svg';
import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import Panel from '../../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { Roadshows_ManagementTeamMemberPartsFragment } from '../../../../../common/graphql';
import ManagementTeamMemberForm from './form/ManagementTeamMemberForm';
import {
  ManagementTeamMember,
  mapToFormValues,
  teamMemberInitialValues,
} from './form/ManagementTeamMemberForm.model';

type MemberDictionaryType = {
  id: string;
  member: ManagementTeamMember;
};

export type Props = {
  roadshowId: string;
  members: readonly Roadshows_ManagementTeamMemberPartsFragment[];
};

const ManagementTeamSection: React.FC<Props> = ({ roadshowId, members }) => {
  const [memberDictionary, setMemberDictionary] = React.useState<MemberDictionaryType[]>(() => {
    return members.map(member => ({
      id: member.id,
      member: mapToFormValues(member),
    }));
  });

  const addTeamMember = () => {
    setMemberDictionary(currentValue => [
      { id: uuidV4(), member: teamMemberInitialValues },
      ...currentValue,
    ]);
  };

  const deleteTeamMember = (id: string) => {
    setMemberDictionary(currentValue => currentValue.filter(item => item.id !== id));
  };

  return (
    <Panel>
      <Panel.Header title="Management Team">
        <LinkButton
          iconLeft={{ name: 'plus' }}
          onClick={addTeamMember}
          inline
          testId="add-management-team-member"
        >
          Add Members
        </LinkButton>
      </Panel.Header>
      <Panel.Content paddingSize="S">
        {memberDictionary.length === 0 ? (
          <HeroGraphics
            image={dealTeam}
            imageAlt="Empty Management Team"
            subtitle="No Management Team members configured. Add members to participate in Roadshow"
          />
        ) : (
          <Table
            gridTemplateColumns="120px 1fr 1fr 1fr 1fr 1fr 80px"
            data-test-id="management-team-members-list"
          >
            <Table.Row>
              <Table.TableHeaderCell>Is Participating</Table.TableHeaderCell>
              <Table.TableHeaderCell>
                Name
                <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell>Title</Table.TableHeaderCell>
              <Table.TableHeaderCell>Mobile Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>Office Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>
                Email
                <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell />
            </Table.Row>
            {memberDictionary.map(item => (
              <ManagementTeamMemberForm
                key={item.id}
                teamMember={item.member}
                roadshowId={roadshowId}
                onDelete={() => deleteTeamMember(item.id)}
              />
            ))}
          </Table>
        )}
      </Panel.Content>
    </Panel>
  );
};

export default ManagementTeamSection;
