import { Icon, Popover, SolidIconName } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { RolodexFirmCategoryType } from '../../../../../graphql';
import {
  SFirmMetaData,
  SInfoIconWrapper,
  SListOption,
  SSpan,
  STitleWrapper,
} from './ListOption.styles';

export type Props = {
  label: string;
  firmType: RolodexFirmCategoryType | null;
  firmKey: string | null;
  location: string | null;
  isSelected?: boolean;
};

export const IconPopover: React.FC<{ content: string; name: SolidIconName }> = ({
  content,
  name,
}) => {
  return (
    <Popover trigger="hover" content={content} variant="DARK" placement="top">
      <SInfoIconWrapper>
        <SSpan>
          <Icon name={name} variant="solid" size="sm" color="white" fixedWidth={true} />
        </SSpan>
      </SInfoIconWrapper>
    </Popover>
  );
};

export const RenderIconPopOver: React.FC<{ firmType: RolodexFirmCategoryType | null }> = ({
  firmType,
}) => {
  switch (firmType) {
    case RolodexFirmCategoryType.FreeText:
      return <IconPopover content="Free Text Investor" name="poll-h" />;
    case RolodexFirmCategoryType.Pwm:
      return <IconPopover content="PWM Investor" name="user-tie" />;
    default:
      return null;
  }
};

/**
 * Displays a list item within the investor firm search menu list
 */
const ListOption: React.FC<Props> = ({
  label,
  firmType,
  firmKey,
  location,
  isSelected = false,
}) => {
  return (
    <SListOption
      direction="column"
      data-test-id={xcSelectors.offeringSideBarInvestorSearchSelectOption.testId}
      isSelected={isSelected}
    >
      <STitleWrapper>
        <div>{label}</div>
        <RenderIconPopOver firmType={firmType} />
      </STitleWrapper>
      <SFirmMetaData>
        {firmKey && <div>Bank Investor Key: {firmKey}</div>}
        {location && <div>Location: {location}</div>}
      </SFirmMetaData>
    </SListOption>
  );
};

export default ListOption;
