import {
  Compliance_SaleAndTrading_CoveredAttestationQueryVariables,
  useCompliance_SaleAndTrading_CoveredAttestationQuery,
} from './graphql';

export const useCoveredAttestationQuery = (
  variables: Compliance_SaleAndTrading_CoveredAttestationQueryVariables,
  { skip }: { skip: boolean }
) => {
  return useCompliance_SaleAndTrading_CoveredAttestationQuery({
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
  });
};
