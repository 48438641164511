import { Button, Stack, Typography } from '@cmg/design-system';

import { EmptyState } from '../../../../../../../../design-system/components/data-display/empty-state/EmptyState';

export type Props = {
  handleCreateNew: () => void;
};

export const NoOptions: React.FC<Props> = ({ handleCreateNew }) => {
  return (
    <EmptyState title="No contacts available" component="span">
      <Stack aria-label="empty state actions" alignItems="center" component="span">
        <Stack flexDirection="row" alignItems="baseline" component="span">
          <Typography variant="body1" component="span">
            Click
          </Typography>
          <Button
            onClick={() => {
              handleCreateNew();
            }}
            sx={{ paddingTop: '8px' }}
          >
            Create New
          </Button>
          <Typography variant="body1" component="span">
            to manually
          </Typography>
        </Stack>
        <Typography variant="body1" component="span">
          add a contact
        </Typography>
      </Stack>
    </EmptyState>
  );
};
