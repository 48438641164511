import styled from 'styled-components/macro';

export const SWrapper = styled.div<{ variant: 'info' | 'error' | 'success' }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.background.color.white};
  border-color: ${({ theme, variant }) => {
    switch (variant) {
      case 'error':
        return theme.brand.color.danger;
      case 'info':
        return theme.brand.color.infoAlternate;
      case 'success':
        return theme.brand.color.success;
    }
  }};
  padding: 40px 50px;
  width: 700px;
`;

export const STitle = styled.span`
  font-size: ${({ theme }) => theme.text.size.xlarge};
  margin-top: 30px;
`;
