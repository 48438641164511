import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeletePaymentWireMutation,
  SyndicateWires_PaymentWiresDocument,
  useSyndicateWires_DeletePaymentWireMutation,
} from '../graphql';

export type DeletePaymentWireMutation = SyndicateWires_DeletePaymentWireMutation;

export const useDeletePaymentWireMutation = () => {
  return useSyndicateWires_DeletePaymentWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PaymentWiresDocument];
  return getManyOperationNames(documentNodes);
}
