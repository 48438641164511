import { ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import FlexLayout from '../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../common/util/routeFactory';
import { Roadshows_RoadshowPartsFragment } from '../../../../common/graphql';
import LogisticsDetailsForm from '../common/logistics-details-form/LogisticsDetailsForm';
import {
  getLogisticsPayload,
  LogisticsValues,
} from '../common/logistics-details-form/LogisticsDetailsForm.model';
import LogisticsSidePanel from '../common/logistics-side-panel/LogisticsSidePanel';
import { LogisticsNavigationSteps } from '../common/logistics-side-panel/LogisticsSidePanel.model';
import { useCreateLogisticsMutation } from './hooks/useCreateLogisticsMutation';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  onClose: () => void;
  selectedDate?: string;
};

const CreateLogisticsPanel: React.FC<Props> = ({ roadshow, selectedDate, onClose }) => {
  const { push } = useHistory();

  const [createLogisticsMutation, { loading: isCreating }] = useCreateLogisticsMutation();

  const navigateToLogistics = (logisticsId: UUID) => {
    push(
      routeFactory.roadshowLogisticsDetails.getUrlPath({ roadshowId: roadshow.id, logisticsId })
    );
  };

  const handleSubmit = async (values: LogisticsValues) => {
    try {
      const { data } = await createLogisticsMutation({
        variables: {
          roadshowId: roadshow.id,
          payload: getLogisticsPayload(values),
        },
      });

      if (data) {
        ToastManager.success('Logistics created successfully');
        navigateToLogistics(data.createLogistics.id);
      }
    } catch (e) {
      ToastManager.error('Failed to create Logistics');
    }
  };

  return (
    <LogisticsSidePanel
      title="Create New Logistics"
      onClose={onClose}
      activeNavigationStepId={LogisticsNavigationSteps.DETAILS}
      testId="roadshows / single-roadshow / calendar / new logistics panel"
    >
      <FlexLayout expand direction="column">
        <LogisticsDetailsForm
          roadshow={roadshow}
          onSubmit={handleSubmit}
          isSaving={isCreating}
          selectedDate={selectedDate}
          logisticsHeaderInfo={null}
        />
      </FlexLayout>
    </LogisticsSidePanel>
  );
};

export default CreateLogisticsPanel;
