import { UUID } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { useUnmarkDuplicate } from './hooks/useUnmarkDuplicate';

export type Props = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  offeringId: UUID;
  indicationId: UUID;
  duplicateIndicationId: UUID;
}>;

const UnmarkDuplicateConfirmDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  duplicateIndicationId,
  indicationId,
  offeringId,
}) => {
  const [handleUnmarkDuplicate, loading] = useUnmarkDuplicate(
    offeringId,
    indicationId,
    duplicateIndicationId,
    onClose
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Unmark Duplicate</DialogTitle>

      <DialogContent dividers>
        <Typography variant="body1">Are you sure you want to unmark this duplicate?</Typography>
        <Typography variant="body1">
          Unmarking will remove any temporary CRM linkage. This indication will become an active
          indication.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <LoadingButton variant="contained" onClick={handleUnmarkDuplicate} loading={loading}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnmarkDuplicateConfirmDialog;
