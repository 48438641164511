import { ExpensesManagerRole } from '../../../graphql';

export const roleOrder: ReadonlyArray<ExpensesManagerRole> = [
  ExpensesManagerRole.ActiveBookrunner,
  ExpensesManagerRole.Bookrunner,
  ExpensesManagerRole.CoLead,
  ExpensesManagerRole.CoManager,
  ExpensesManagerRole.Underwriter,
];

export const sortManagersByRole = managers => {
  return [...managers].sort((a, b) => roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role));
};
