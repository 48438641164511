import { useCheckAccountTraits } from '@cmg/auth';

import { useFeatureToggles } from '../../../../common/config';
import { OfferingStatus } from '../../../../graphql';
import { useCalendarPublishedOfferings } from '../../components/calendar-grid/hooks/useCalendarPublishedOfferings';
import { getPostponedOfferingsDateBoundaries } from '../../utils/dates';
import { useCalendar_PostponedCalendarOfferingsListQuery } from '../graphql';

export function useGetPostponedCalendarOfferingsQuery() {
  const { start, end } = getPostponedOfferingsDateBoundaries();
  const includeTestOfferings = useCheckAccountTraits(['XC_TEST_OFFERINGS_IN_CALENDAR']);

  const { isCalendarUsingOfferingFull } = useFeatureToggles();

  const offeringFullQuery = useCalendarPublishedOfferings({
    skip: !isCalendarUsingOfferingFull,
    where: {
      status: { eq: OfferingStatus.Postponed },
      timing: { postponedDate: { gte: start, lte: end } },
    },
  });

  const { data, ...offeringFullQueryRest } = offeringFullQuery;

  const remappedOfferingFullQuery = {
    data: {
      postponedPublishedOfferings: {
        data: data?.publishedOfferings?.items,
      },
    },
    ...offeringFullQueryRest,
  };

  const offeringListQuery = useCalendar_PostponedCalendarOfferingsListQuery({
    variables: {
      startPostponedDate: start,
      endPostponedDate: end,
      includeTestOfferings,
    },
    fetchPolicy: 'no-cache',
    skip: isCalendarUsingOfferingFull,
  });

  if (remappedOfferingFullQuery.called) {
    return remappedOfferingFullQuery;
  }

  return offeringListQuery;
}
