import { TextInputField } from '@cmg/common';
import {
  Table as DesignSystemTable,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { TruncatedTextFormatter } from '../../../../../../common/components/format/truncated-text-format/TruncatedTextFormatter';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { useGroupAffiliates } from '../../../../hooks/useGroupAffiliates';
import { TNFilingValues } from '../TNFilingForm.model';

const title = 'FINRA Member Firms';

const MemberFirmsForm: React.FC = () => {
  const { values, initialValues } = useFormikContext<TNFilingValues>();
  const groupedTNMemberFirmsTableData = useGroupAffiliates(values.finraMemberFirms, true);
  const memberFirmsInitialValues = initialValues.finraMemberFirms;

  if (!values.finraMemberFirms.length || !groupedTNMemberFirmsTableData) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Content>
        <TableContainer>
          <DesignSystemTable>
            <TableHead>
              <TableRow>
                <TableCell width="30%">
                  Firm Name
                  <MandatoryAsterisk />
                </TableCell>
                <TableCell width="30%">
                  Member Firm CRD#
                  <MandatoryAsterisk />
                </TableCell>
                <TableCell>Firm MPID</TableCell>
              </TableRow>
            </TableHead>
            {groupedTNMemberFirmsTableData.map(([manager, memberFirms]) => (
              <TableBody key={manager} aria-label={manager}>
                <TableGroupHeader>
                  <TableGroupHeaderCell colSpan={4}>{manager}</TableGroupHeaderCell>
                </TableGroupHeader>
                {memberFirms.map((firm, index) => {
                  const formIndex = firm.formIndex!; // this will be nonNullable if isOfferingRegMRolodexAffiliatesOn = true
                  return (
                    <TableRow key={firm.firmName}>
                      <TableCell size="medium">
                        <TruncatedTextFormatter
                          color={!firm.firmName.length ? 'error' : undefined}
                          maxLength={40}
                          textContent={firm.firmName}
                        />
                      </TableCell>
                      <TableCell size="medium">
                        <FormikTextField
                          fullWidth
                          aria-label={`${index + 1}. Member Firm CRD#`}
                          showHelperTextInTooltip
                          name={`finraMemberFirms.${formIndex}.firmCrdNumber`}
                          placeholder={`Original value: ${memberFirmsInitialValues[formIndex].firmCrdNumber}`}
                          required
                        />
                      </TableCell>
                      <TableCell size="medium">
                        <TextInputField
                          required
                          name={`finraMemberFirms.${formIndex}.firmMpid`}
                          aria-label={`${index + 1}. Member Firm MPID`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ))}
          </DesignSystemTable>
        </TableContainer>
      </Panel.Content>
    </Panel>
  );
};

export default MemberFirmsForm;
