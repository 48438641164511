import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager, UUID } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import { useFeatureToggles } from '../../../../common/config';
import {
  MdlAllocationRanking,
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
} from '../../../../graphql';
import InternalDemandSummary from '../../components/internal-demand-summary/InternalDemandSummary';
import { useGetLimitedPublishedOfferingQuery } from '../../components/side-bar/hooks/useGetLimitedPublishedOfferingQuery';
import Banner from './../../../../common/components/indicators/banner/Banner';
import { SHeaderTitle } from './CommunicationDemandRoute.styles';
import CommunicatedDemandForm from './components/communicated-demand-form/CommunicatedDemandForm';
import { CommunicatedDemandValues } from './components/communicated-demand-form/CommunicatedDemandForm.model';
import IndicationHeader from './components/indication-header/IndicationHeader';
import {
  useOrderAllocation_CommunicatedAllocationDemandQuery,
  useOrderAllocation_CommunicatedDemandOfferingTypeQuery,
  useOrderAllocation_CommunicatedIndicationsDemandQuery,
  useOrderAllocation_SetAllocationRankingMutation,
  useOrderAllocation_UpsertCommunicatedDemandAllocationMutation,
  useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation,
} from './graphql';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const CommunicatedDemandRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const canRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const {
    loading: loadingOfferingType,
    data: dataOfferingType,
    error: errorOfferingType,
  } = useOrderAllocation_CommunicatedDemandOfferingTypeQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const {
    loading: loadingIndication,
    data: dataIndication,
    error: errorIndication,
  } = useOrderAllocation_CommunicatedIndicationsDemandQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const {
    loading: loadingAllocation,
    data: dataAllocation,
    error: errorAllocation,
  } = useOrderAllocation_CommunicatedAllocationDemandQuery({
    variables: {
      offeringId,
    },
    skip: !canRead,
  });

  const { data: limitedData } = useGetLimitedPublishedOfferingQuery({ variables: { offeringId } });

  const [upsertIndications, { loading: loadingUpsertIndications }] =
    useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation();

  const [upsertAllocation, { loading: loadingUpsertAllocation }] =
    useOrderAllocation_UpsertCommunicatedDemandAllocationMutation();

  const [
    setAllocationRankingOffPlatform,
    { loading: loadingAllocationRankingOffPlatform, error: errorAllocationRankingOffPlatform },
  ] = useOrderAllocation_SetAllocationRankingMutation({
    onCompleted: () => {
      ToastManager.success('Successfully updated Communicated Demand');
      setIsEditing(false);
    },
  });

  const handleSubmitForm = async (
    values: CommunicatedDemandValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  ) => {
    const { interestLevels, allocation } = values;

    const mapped = interestLevels.map(level => ({
      ...level,
      limitType: level.limitType ?? MdlIndicationLimitType.Null,
    }));

    try {
      await upsertIndications({
        variables: {
          offeringId,
          input: mapped,
        },
      });

      await upsertAllocation({
        variables: {
          offeringId,
          input: { shares: allocation?.shares },
        },
      });

      setIsEditing(false);
    } catch {
      ToastManager.error('Failed to update indications and allocation information');
    }
  };

  const loading =
    loadingIndication ||
    loadingUpsertIndications ||
    loadingAllocationRankingOffPlatform ||
    loadingAllocation ||
    loadingOfferingType ||
    loadingUpsertAllocation;

  const error =
    errorIndication || errorAllocationRankingOffPlatform || errorAllocation || errorOfferingType;

  const indications = dataIndication?.communicatedIndicationsDemand?.indications;
  const allocation = dataAllocation?.communicatedAllocationDemand?.allocation;
  const offeringType = dataOfferingType?.basicPublishedOffering.type;

  const onChangeEditing = () => {
    setIsEditing(editing => !editing);
  };

  const onChangeAllocationRanking = (ranking: MdlAllocationRanking) => {
    const allocationId = allocation?.id;

    if (allocationId) {
      setAllocationRankingOffPlatform({
        variables: {
          offeringId,
          allocationId,
          ranking,
        },
      });
    }
  };

  const isUsOffering = limitedData?.limitedPublishedOffering.isUsOffering;
  const isExecutingOnPlatform = limitedData?.limitedPublishedOffering.isExecutingOnPlatform;
  const offeringCountryCode = limitedData?.limitedPublishedOffering.pricingInstrumentCountryCode;

  const showInternalSummary = !!dataIndication && canRead;

  const { isInternationalOfferingsInDL } = useFeatureToggles();

  if (
    canRead &&
    !isUsOffering &&
    !isExecutingOnPlatform &&
    !isInternationalOfferingsInDL &&
    !isNil(offeringCountryCode)
  ) {
    return (
      <Banner variant="warning">
        {' '}
        <Banner.Title>
          This feature is currently not available for international offerings.
        </Banner.Title>
      </Banner>
    );
  }

  return !canRead ? (
    <NotAccessible subject="Communicated Demand" />
  ) : (
    <div>
      <SHeaderTitle>Communicated Demand</SHeaderTitle>
      {showInternalSummary && <InternalDemandSummary offeringId={offeringId} />}
      {error && <ServerErrorsBanner error={error} />}
      <IndicationHeader />
      {indications && offeringType && (
        <React.Fragment>
          <CommunicatedDemandForm
            indications={indications}
            allocation={allocation}
            onSubmit={handleSubmitForm}
            isEditing={isEditing}
            onChangeEditing={onChangeEditing}
            loading={loading}
            onChangeAllocationRanking={onChangeAllocationRanking}
            offeringType={offeringType}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default CommunicatedDemandRoute;
