import { IconButton } from '@cmg/common';
import styled from 'styled-components/macro';

const SIDE_PANEL_WIDTH = 900;
const SIDE_PANEL_SHADOW_WIDTH = 14;

type SSidePanelProps = {
  readonly isOpen?: boolean;
  readonly width?: number;
};

export const SSidePanel = styled.div<SSidePanelProps>`
  position: fixed;
  right: ${({ isOpen, width }) =>
    !isOpen ? `-${(width ?? SIDE_PANEL_WIDTH) + SIDE_PANEL_SHADOW_WIDTH}px` : 0};
  width: ${({ width }) => width ?? SIDE_PANEL_WIDTH}px;
  overflow: auto;
  background-color: ${({ theme }) => theme.background.color.white};
  transition: right 0.33s ${({ isOpen }) => (isOpen ? 'ease-in-out' : 'ease-out')};
  box-shadow: 0 0px ${SIDE_PANEL_SHADOW_WIDTH}px rgba(0, 0, 0, 0.4);
  bottom: 0;
  height: calc(100% - 48px);
  z-index: ${({ theme }) => theme.zIndex.contentOverlay};
  overflow: hidden;
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  padding: 17px;
  padding-right: 25px;
  z-index: ${({ theme }) => theme.zIndex.contentOverlay};

  svg {
    font-size: ${({ theme }) => theme.text.size.large};
  }
`;
