import React from 'react';

import { SNoManagersWrapper, StyledNoManagersIcon } from './NoManagers.styles';

const NoManagers: React.FC = () => {
  return (
    <SNoManagersWrapper>
      <StyledNoManagersIcon />
      <span>No Members</span>
    </SNoManagersWrapper>
  );
};

export default NoManagers;
