import { MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SLeftLeadFilter = styled.div`
  min-width: 220px;
`;

export type Props = {
  leftLeads: Array<string | null | undefined>;
  onChange: (value: string[]) => void;
  values: string[];
};

const LeftLeadFilter: React.FC<Props> = ({ leftLeads, onChange, values }) => {
  const options: Option[] = leftLeads
    .filter(leftLead => !!leftLead)
    .map(leftLead => ({ label: leftLead, value: leftLead } as Option));

  return (
    <SLeftLeadFilter>
      <MultiSelect<string>
        options={options}
        onChange={onChange}
        value={values}
        placeholder="Left Lead..."
      />
    </SLeftLeadFilter>
  );
};

export default LeftLeadFilter;
