import React from 'react';

import { TradeReleaseGridWarning } from '../../../../../../../graphql';

export const useGetWarningMessage = (warnings: readonly TradeReleaseGridWarning[]) => {
  return React.useMemo(() => {
    if (warnings.length === 0) {
      return null;
    }

    if (warnings.includes(TradeReleaseGridWarning.IndicationPassed)) {
      return 'Cancel is required for this trade, the Indication is a pass.';
    }
    if (warnings.includes(TradeReleaseGridWarning.IndicationCancelled)) {
      return 'Cancel is required for this trade, the Indication has been cancelled.';
    }
    if (warnings.includes(TradeReleaseGridWarning.IndicationDuplicated)) {
      return 'Cancel is required for this trade, the Indication has been marked as a duplicate.';
    }
    if (
      warnings.includes(TradeReleaseGridWarning.TradeReassigned) ||
      warnings.includes(TradeReleaseGridWarning.UnassignedBndAgent)
    ) {
      return 'Cancel is required for this trade, you are no longer the B&D agent.';
    }
    if (warnings.includes(TradeReleaseGridWarning.QuantityToReleaseChangedToZero)) {
      return 'Cancel is required for this trade, the Quantity to Release has been modified.';
    }
    if (
      warnings.includes(TradeReleaseGridWarning.QuantityToReleaseChanged) &&
      warnings.includes(TradeReleaseGridWarning.TradeDetailsChanged)
    ) {
      return 'Re-release is required for this trade, the Quantity to Release and Trade details have been modified.';
    }
    if (warnings.includes(TradeReleaseGridWarning.QuantityToReleaseChanged)) {
      return 'Re-release is required for this trade, the Quantity to Release has been modified.';
    }
    if (warnings.includes(TradeReleaseGridWarning.AllocationResponseChanged)) {
      return 'Cancel is required for this trade, the Allocation has been rejected.';
    }
    if (warnings.includes(TradeReleaseGridWarning.TradeDetailsChanged)) {
      return 'Re-release is required for this trade, trade details have been modified.';
    }
    if (warnings.includes(TradeReleaseGridWarning.RereleaseRequired)) {
      return 'Re-release is required for this trade.';
    }

    return null;
  }, [warnings]);
};
