import { ApolloError } from '@apollo/client';
import { UUID } from '@cmg/common';
import React from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import usePollingWhenVisible from '../../../../../common/hooks/usePollingWhenVisible';
import { useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess } from '../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { useBuySideCertificatesModel } from '../../compliance/context/hooks/certificates/buy-side/useCertificatesBuySideModel';
import {
  Attestation,
  certificateModelDefaults,
  ComplianceWarning,
} from '../../compliance/context/hooks/certificates/useCertificatesModel';
import { OrderBookPersonaType } from '../../hooks/useIdentifyOrderBookPersona';
import { BuySideIndicationQuery, useBuySideIndicationQuery } from './hooks/buy-side/__generated__';

type AccessChecks = {
  canReadOfferingAndIndication: boolean;
  canUpdateIndication: boolean;
};

export type IndicationActivityBuySideContextType = {
  offeringId: UUID;
  loading: boolean;
  error?: ApolloError;
  basicPublishedOffering?: BuySideIndicationQuery['basicPublishedOffering'];
  complianceWarning: ComplianceWarning;
  attestation: Attestation | null;
  access: AccessChecks;
};

const IndicationActivityBuySideContext = React.createContext<IndicationActivityBuySideContextType>({
  offeringId: '',
  loading: false,
  complianceWarning: certificateModelDefaults.warning,
  access: {
    canReadOfferingAndIndication: false,
    canUpdateIndication: false,
  },
  attestation: null,
});

export const useIndicationActivityBuySideContext = () => {
  const context = React.useContext(IndicationActivityBuySideContext);

  if (!context) {
    throw new Error('No provider found for IndicationActivityBuySideContext');
  }

  return context;
};

export type IndicationActivityBuySideContextProps = { offeringId: UUID };

export const IndicationActivityBuySideContextProvider: React.FC<
  IndicationActivityBuySideContextProps
> = ({ children, offeringId }) => {
  const { isOrderBookAttestationOn } = useFeatureToggles();

  const {
    canRead: canReadOfferingAndIndication,
    canUpdate: canUpdateIndication,
    loading: isLoadingAccess,
  } = useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
    offeringId,
  });
  const {
    loading: isLoadingOffering,
    data,
    error,
    called,
    startPolling,
    stopPolling,
  } = useBuySideIndicationQuery({
    variables: { offeringId },
    skip: !canReadOfferingAndIndication,
  });

  usePollingWhenVisible({ pollInterval: 5_000, startPolling, stopPolling, called });

  const {
    loading: isLoadingCertificates,
    warning: complianceWarning,
    attestation,
  } = useBuySideCertificatesModel({
    type: OrderBookPersonaType.BUY_SIDE,
    offeringId,
    isOrderBookAttestationOn,
  });

  const context = React.useMemo<IndicationActivityBuySideContextType>(
    () => ({
      offeringId,
      loading: isLoadingOffering || isLoadingCertificates || isLoadingAccess,
      error,
      basicPublishedOffering: data?.basicPublishedOffering,
      complianceWarning,
      attestation,
      access: {
        canReadOfferingAndIndication,
        canUpdateIndication,
      },
    }),
    [
      canReadOfferingAndIndication,
      canUpdateIndication,
      complianceWarning,
      attestation,
      data?.basicPublishedOffering,
      error,
      isLoadingAccess,
      isLoadingCertificates,
      isLoadingOffering,
      offeringId,
    ]
  );

  return (
    <IndicationActivityBuySideContext.Provider value={context}>
      {children}
    </IndicationActivityBuySideContext.Provider>
  );
};
