import { UUID } from '@cmg/common';

import { OfferingStage } from '../../../../../../graphql';
import { allRecipientsHaveUserInputData } from '../../components/wire-stage/draft-stage/DraftStage.model';
import { useWireTypeConfigContext } from '../WireTypeConfigContext';

type Options = { canBeSentForDraftOffering?: boolean };

export function createUseDefaultSendIsDisabledReason(options: Options = {}) {
  return ({ offeringId, syndicateWireId }: { offeringId: UUID; syndicateWireId: UUID }) => {
    const { canBeSentForDraftOffering } = options;
    const { useWireDetails, useManagers, wireTypeCategory } = useWireTypeConfigContext();
    const {
      data: wireDetails,
      isLoading: areWireDetailsLoading,
      error: wireDetailsError,
    } = useWireDetails({ offeringId, syndicateWireId });
    const {
      data: managers,
      isLoading: areManagersLoading,
      error: managersError,
    } = useManagers({ offeringId, syndicateWireId });

    if (areWireDetailsLoading || areManagersLoading) {
      return 'Please wait...';
    }

    if (wireDetailsError || managersError) {
      return (
        'And error has occurred and we cannot verify whether sending should be allowed. ' +
        'Please try refreshing the page.'
      );
    }

    if (!managers?.length) {
      return 'There are no eligible recipients configured.';
    }

    if (
      wireDetails?.stage !== OfferingStage.Published &&
      !(wireDetails?.stage === OfferingStage.Draft && canBeSentForDraftOffering)
    ) {
      return `Your offering needs to be published before sending the ${wireTypeCategory}.`;
    }

    const hasInputDataForAllRecipients = allRecipientsHaveUserInputData(
      managers,
      wireDetails?.wire?.recipientsUserData
    );

    if (!hasInputDataForAllRecipients) {
      return `All recipients must have their data filled out before sending the ${wireTypeCategory}.`;
    }

    return null;
  };
}
