import { Modal, SecondaryButton } from '@cmg/common';
import { Tab, Tabs } from '@cmg/design-system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import Loading from '../../../../../common/components/indicators/loading/Loading';
import {
  SFooter,
  SLoadingWrapper,
  StyledReleaseFinalAllocationButton,
} from './ComplianceAllocationReleaseModal.styles';
import ComplianceTable from './components/compliance-table/ComplianceTable';
import {
  ComplianceTableType,
  getIndicationsWithComplianceCertificatesWarning,
} from './components/compliance-table/ComplianceTable.model';
import { useInstitutionalDemand_ComplianceTableQuery } from './components/compliance-table/graphql';
import { TabPanel } from './components/compliance-table/TabPanel';

type OwnProps = {
  offeringId: string;
  nextModal?: {
    type: string;
    payload: {
      modal: string;
      props: {};
    };
  };
};

export type Props = OwnProps & InjectedProps;

export const UpdateComplianceAllocationReleaseModal: React.FC<Props> = ({
  offeringId,
  handleHide,
  nextModal,
  show,
}) => {
  const dispatch = useDispatch();
  const { loading, data } = useInstitutionalDemand_ComplianceTableQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });
  const { orderBook, type: offeringType } = data?.publishedOffering ?? {};
  const complianceCertificatesWarningsCount = getIndicationsWithComplianceCertificatesWarning({
    indications: orderBook?.institutionalIndications,
    offeringType,
  }).length;
  const complianceProspectusWarningsCount = 0;

  const closeAndContinue = React.useCallback(() => {
    handleHide();
    dispatch(nextModal);
  }, [dispatch, handleHide, nextModal]);

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  React.useEffect(() => {
    if (
      !!data?.publishedOffering &&
      complianceCertificatesWarningsCount === 0 &&
      complianceProspectusWarningsCount === 0
    ) {
      closeAndContinue();
    }
  }, [
    data,
    complianceCertificatesWarningsCount,
    complianceProspectusWarningsCount,
    closeAndContinue,
  ]);

  if (loading) {
    return (
      <Modal show={show} size="small" data-test-id="compliance-loading-modal">
        <SLoadingWrapper>
          <Loading />
        </SLoadingWrapper>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      size="large"
      title="Please review compliance actions"
      onHide={handleHide}
      closeButton={true}
      data-test-id="compliance-modal"
      footer={
        <SFooter>
          <SecondaryButton onClick={handleHide} data-test-id="compliance-cancel-button">
            Cancel
          </SecondaryButton>
          <StyledReleaseFinalAllocationButton
            onClick={() => closeAndContinue()}
            data-test-id="compliance-continue-button"
          >
            Continue
          </StyledReleaseFinalAllocationButton>
        </SFooter>
      }
    >
      {tabIndex === 0 && (
        <p>The following investors do not have a valid certificate on file with CMG XC:</p>
      )}
      {tabIndex === 1 && (
        <p>The following investors have not received a prospectus through CMG XC:</p>
      )}
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label={`Certificates (${complianceCertificatesWarningsCount})`} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <ComplianceTable offeringId={offeringId} tableType={ComplianceTableType.CERTIFICATES} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ComplianceTable offeringId={offeringId} tableType={ComplianceTableType.PROSPECTUS} />
      </TabPanel>
    </Modal>
  );
};

const name = 'ORDERBOOK/COMPLIANCE_RELEASE_MODAL';
export const openUpdateComplianceAllocationReleaseModal = (props: OwnProps) => show(name, props);
export const closeUpdateComplianceAllocationReleaseModal = () => hide(name);

export default connectModal({ name })(UpdateComplianceAllocationReleaseModal);
