import { TextAreaField, UnsavedChangesGuard } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';

import { OfferingSetup_MarketingPartsFragment } from '../../graphql';
import { StyledButton, StyledColumn, StyledRow } from './MarketingForm.styles';

export type FormType = {
  managementTeam: string | null;
  callDetails: string | null;
};

export type OwnProps = {
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (payload: FormType) => void;
  marketing?: OfferingSetup_MarketingPartsFragment;
};

export type Props = OwnProps & FormikProps<FormType>;

export const MarketingFormComponent: React.FC<Props> = ({
  disabled,
  loading,
  dirty,
  handleSubmit,
  resetForm,
}) => {
  return (
    <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
      <Form>
        <StyledRow>
          <StyledColumn>
            <TextAreaField
              fullWidth
              label="Management Team"
              placeholder="Add Management Team here"
              name="managementTeam"
            />
            <StyledButton
              testId={xcSelectors.offeringSetupMarketingSaveButton.testId}
              disabled={disabled || !dirty}
              loading={loading}
              onClick={() => handleSubmit()}
            >
              Save
            </StyledButton>
          </StyledColumn>
          <StyledColumn>
            <TextAreaField
              fullWidth
              label="Group Investor Call Details"
              placeholder="Add Investor Call Details here"
              name="callDetails"
            />
            <StyledButton
              disabled={disabled || !dirty}
              loading={loading}
              onClick={() => handleSubmit()}
            >
              Save
            </StyledButton>
          </StyledColumn>
        </StyledRow>
      </Form>
    </UnsavedChangesGuard>
  );
};

export default withFormik<OwnProps, FormType>({
  enableReinitialize: true,
  mapPropsToValues: ({ marketing }) => ({
    managementTeam: marketing?.managementTeam ?? '',
    callDetails: marketing?.callDetails ?? '',
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({
      managementTeam: values?.managementTeam || null,
      callDetails: values?.callDetails || null,
    });
  },
})(MarketingFormComponent);
