import {
  entitlementsByEntity,
  permissionsByEntity,
  useCheckAccountType,
  useCheckPermissions,
} from '@cmg/auth';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';

type Props = {
  offeringId: string;
};

type AccessCheckResult = {
  loading: boolean;
  canRead: boolean;
  canUpdate: boolean;
  isSellSideAccount: boolean;
};
/**
 * Checks accessibility of an Delivery Instructions.
 */
export function useCheckDeliveryInstructionAccess({ offeringId }: Props): AccessCheckResult {
  const hasRead = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalIndication.READ,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
    ],
    false
  );
  const hasFull = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalIndication.FULL,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
    ],
    false
  );
  const isSellSideAccount = useCheckAccountType('SELL_SIDE');
  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });

  const accessChecks = React.useMemo(() => {
    const entitlements = data?.publishedOffering.entitlements?.entitlements ?? [];
    return {
      canRead:
        hasRead &&
        (!isSellSideAccount ||
          entitlements.includes(entitlementsByEntity.InstitutionalIndication.READ)),
      canUpdate:
        hasFull && (!isSellSideAccount || entitlements.includes('UPDATE:DELIVERY_INSTRUCTIONS')),
    };
  }, [hasRead, hasFull, data, isSellSideAccount]);

  return { loading, ...accessChecks, isSellSideAccount };
}
