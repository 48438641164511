import { Tag } from '@cmg/common';
import styled from 'styled-components/macro';

export const SNoteContainer = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.light};
`;

export const SNoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const STag = styled(Tag)`
  font-size: ${({ theme }) => theme.text.size.medium};
  text-transform: none;
`;

export const SNoteText = styled.p`
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SNoteFooterText = styled.p`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;

export const SNoteFooterTitle = styled.span`
  color: ${({ theme }) => theme.text.color.light};
`;
