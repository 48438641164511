import { SecondaryButton, Select, ToastManager, urlUtil, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import { IndicationNoteType } from '../../../../graphql';
import { useNotes_IndicationNoteQuery } from './graphql';
import { useAssignNoteMutation } from './hooks/useAssignNoteMutation';
import { useNoteSearchParams } from './hooks/useNoteSearchParams';
import Note from './Note';
import NoteForm, { FormType } from './NoteForm';
import { filterOptions, NotesFilters } from './NotesModel.util';
import {
  SAllNotesContainer,
  SNotePrimaryButton,
  SNotesButtonGroup,
  SNoteSectionHeader,
  SNoteSectionTitle,
  SNoteSectionTitleHeader,
  SNotesFilterContainer,
} from './NotesRoute.styles';

export type Props = {
  offeringId: UUID;
  indicationId: UUID;
};

const Notes: React.FC<Props> = ({ offeringId, indicationId }) => {
  const { noteType, ...searchParams } = useNoteSearchParams({ noteType: NotesFilters.ALL });

  const history = useHistory();
  const [editMode, setEditMode] = React.useState<boolean>(false);

  const { data: notesData, loading: notesLoading } = useNotes_IndicationNoteQuery({
    variables: { offeringId, indicationId },
    fetchPolicy: 'no-cache',
  });

  const [assignNote, { loading: assignNoteLoading }] = useAssignNoteMutation({});

  if (notesLoading || assignNoteLoading) {
    return <Loading />;
  }

  const notes = notesData?.getInstitutionalIndicationNotes ?? [];
  const syndicateNote = notes.find(n => n.type === IndicationNoteType.SyndicateNote);
  const salesAndTradingNote = notes.find(n => n.type === IndicationNoteType.SalesAndTradingNote);

  const handleNoteTypeChange = (nextNoteType: NotesFilters) => {
    history.push({
      pathname: history.location.pathname,
      search: urlUtil.queryStringify({ ...searchParams, noteType: nextNoteType }),
    });
  };

  const handleNoteChange = (filterOption: NotesFilters) => {
    setEditMode(true);
    handleNoteTypeChange(filterOption);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
    handleNoteTypeChange(NotesFilters.ALL);
  };

  const onSubmit = async (values: FormType) => {
    if (!values?.text) {
      return;
    }
    try {
      await assignNote({
        variables: {
          offeringId,
          indicationId,
          input: {
            text: values.text,
            type:
              noteType === NotesFilters.SYNDICATE
                ? IndicationNoteType.SyndicateNote
                : IndicationNoteType.SalesAndTradingNote,
          },
        },
      });
      setEditMode(false);
      handleNoteTypeChange(NotesFilters.ALL);
      ToastManager.success('Note Saved');
    } catch (error) {
      ToastManager.error('An error has occurred at saving the note');
    }
  };

  return (
    <div>
      <SNoteSectionHeader>
        <SNoteSectionTitle>
          <SNoteSectionTitleHeader>Notes</SNoteSectionTitleHeader>
          {editMode && (
            <SNotesButtonGroup>
              <SecondaryButton onClick={handleCancelEditMode}>Cancel</SecondaryButton>
              <SNotePrimaryButton form="note-form" type="submit">
                Save
              </SNotePrimaryButton>
            </SNotesButtonGroup>
          )}
        </SNoteSectionTitle>
      </SNoteSectionHeader>

      <SNotesFilterContainer>
        <Select<NotesFilters>
          options={filterOptions}
          onChange={selected => {
            handleNoteTypeChange(selected!);
          }}
          value={noteType}
          isClearable={false}
          disabled={editMode}
        />
      </SNotesFilterContainer>

      {!editMode && (
        <SAllNotesContainer>
          {(noteType === NotesFilters.ALL || noteType === NotesFilters.SYNDICATE) && (
            <Note
              title="Syndicate Notes"
              text={syndicateNote?.text ?? 'No syndicate notes have been entered yet.'}
              filterType={NotesFilters.SYNDICATE}
              onChange={handleNoteChange}
            />
          )}
          {(noteType === NotesFilters.ALL || noteType === NotesFilters.SALES_TRADER) && (
            <Note
              title="Sales & Trading Notes"
              text={salesAndTradingNote?.text ?? 'No sales & trading notes have been entered yet.'}
              filterType={NotesFilters.SALES_TRADER}
              onChange={handleNoteChange}
            />
          )}
        </SAllNotesContainer>
      )}
      {editMode && (
        <NoteForm
          onSubmit={onSubmit}
          text={
            noteType === NotesFilters.SYNDICATE
              ? syndicateNote?.text ?? ''
              : salesAndTradingNote?.text ?? ''
          }
        ></NoteForm>
      )}
    </div>
  );
};

export default Notes;
