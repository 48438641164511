import { UUID } from '@cmg/common';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import CancelIndicationConfirmDialogComponent from '../../../common/cancel-indication-confirm-dialog/CancelIndicationConfirmDialog';
import { useUpdateMyIndicationStatus } from '../hooks/useUpdateMyIndicationStatus';

export type Props = Readonly<{
  offeringId: UUID;
  isOpen: boolean;
  onClose: () => void;
}>;

const CancelIndicationConfirmDialog: React.FC<Props> = ({ offeringId, onClose, isOpen }) => {
  const [onUpdateStatus, isUpdatingStatus] = useUpdateMyIndicationStatus(offeringId, onClose);

  return (
    <CancelIndicationConfirmDialogComponent
      isOpen={isOpen}
      isCancelling={isUpdatingStatus}
      onCancel={() => onUpdateStatus(IndicationStatus.Cancelled)}
      onClose={onClose}
    />
  );
};

export default CancelIndicationConfirmDialog;
