import { numericUtil } from '@cmg/common';

import { FinalSettlement_AccountingPartsFragment } from '../../graphql';
import {
  getTotalFinalSettlement,
  getTotalRetailRetentionFees,
} from './final-settlement-info-box/FinalSettlementInfoBox.model';
import { getTotalRevenue } from './total-revenue-info-box/TotalRevenueInfoBox.model';

export const calculateNetDealFeesDifference = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const { dealRelatedExpenses, stabilizationGainLoss } = accounting;

  const settlementNetDealFees = numericUtil.sum(
    getTotalFinalSettlement(accounting),
    getTotalRetailRetentionFees(accounting)
  );
  const revenueNetDealFees = numericUtil.sum(
    getTotalRevenue(accounting),
    numericUtil.negate(dealRelatedExpenses),
    stabilizationGainLoss
  );

  const netDealFeesDifference = numericUtil.sum(
    settlementNetDealFees,
    revenueNetDealFees ? -revenueNetDealFees : null
  );

  return { settlementNetDealFees, revenueNetDealFees, netDealFeesDifference };
};
