import { LinkButton, ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

import InvestorFirmsSearch from '../../../../../common/components/form/investor-firm-search/InvestorFirmSearch';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

export const SEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledInvestorFirmsSearch = styled(InvestorFirmsSearch)`
  width: 50%;
`;

export const SEmptyStateText = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const StyledTableCell = styled(Table.Cell)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin: 0;
  padding: 0;
`;

export const STableWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  height: 100px;
`;
