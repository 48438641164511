import type { UUID } from '@cmg/common';
import type {
  CellClassFunc,
  CellRendererSelectorFunc,
  CellRendererSelectorResult,
  DesignSystemTooltipProps,
  ITooltipParams,
  RowClassRules,
  ValueFormatterFunc,
  ValueFormatterParams,
} from '@cmg/data-grid';
import isNil from 'lodash/isNil';

import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../../graphql';
import type {
  OrderBook_InstitutionalDemand_AllocationPartsFragment,
  OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
} from '../../graphql/__generated__';
import { getFirmDisplayName } from '../../utils';
import type { DemandGridRowData, DemandGridServerColDef, TotalRow } from '../types';

export const AlertCodes = {
  AttestationNotOnFile: 'ATTESTATION_NOT_ON_FILE',
  AttestationExpired: 'ATTESTATION_EXPIRED',
  ProspectusDeliveryFailed: 'PROSPECTUS_DELIVERY_FAILED',
  BndUnassigned: 'BND_UNASSIGNED',
  AckRevised: 'ACK_REVISED',
  AllocationAssigned: 'ALLOCATION_ASSIGNED',
} as const;

export const ComplianceAlertCodes = {
  [AlertCodes.AttestationNotOnFile]: {
    label: '5130/31 Not on File.',
    filterLabel: '5130/31 Not on File',
    field: 'attestationStatus',
  },
  [AlertCodes.AttestationExpired]: {
    label: '5130/31 Expired.',
    filterLabel: '5130/31 Expired',
    field: 'attestationStatus',
  },
  [AlertCodes.ProspectusDeliveryFailed]: {
    label: 'Prospectus Failed.',
    filterLabel: 'Prospectus Failed',
    field: 'prospectusDeliverySummary.overallStatusDisplayValue',
  },
} as const;

export const DeliveryAlertCodes = {
  [AlertCodes.BndUnassigned]: {
    label: 'B&D Agent needs to be assigned.',
    filterLabel: 'B&D Agent Unassigned',
    field: 'bndAgent',
  },
} as const;

export const AcknowledgementsAlertCodes = {
  [AlertCodes.AckRevised]: {
    label: 'The indication has been revised since your acknowledgement.',
    filterLabel: 'Ack. Revised',
    field: 'acknowledgedByFirmNames',
  },
} as const;

export const AllocationAlertCodes = {
  [AlertCodes.AllocationAssigned]: {
    label: 'Indication marked as a Pass, Duplicate or is Cancelled and has an allocation assigned.',
    filterLabel: 'Invalid Final Allocation',
    field: 'investorReplyStatus',
  },
} as const;

export const alertCodesMap = {
  ...ComplianceAlertCodes,
  ...DeliveryAlertCodes,
  ...AcknowledgementsAlertCodes,
  ...AllocationAlertCodes,
} as const;

export const CELL_ERROR_CLASS = 'data-grid-cell-error';
export const CELL_WARNING_CLASS = 'data-grid-cell-warning';
export const ROW_DISABLED_CLASS = 'data-grid-row-disabled';

export function getCellClassForAlert(
  alert: OrderBook_InstitutionalDemand_GridRowPartsFragment['alert']
) {
  switch (alert.severity) {
    case 'ERROR':
      return CELL_ERROR_CLASS;
    case 'WARNING':
      return CELL_WARNING_CLASS;
    default:
      return undefined;
  }
}

export const getCellClass: CellClassFunc<DemandGridRowData> = ({ colDef, data, node }) => {
  if (data?.__typename !== 'SyndicateInstitutionalGridRow') {
    return;
  }

  const fieldAlertCodes = data?.alert.alertCodes?.filter(
    code => alertCodesMap[code].field === colDef.field
  );
  if (data?.alert && fieldAlertCodes?.length) {
    return getCellClassForAlert(data?.alert);
  } else {
    return;
  }
};

export const rowClassRules: RowClassRules<DemandGridRowData> = {
  [ROW_DISABLED_CLASS]: ({ data }) => {
    if (data?.__typename !== 'SyndicateInstitutionalGridRow') {
      return false;
    }

    return (
      data?.status === IndicationStatus.Cancelled ||
      data?.status === IndicationStatus.Duplicate ||
      data?.type === InstitutionalIndicationOrderType.Pass
    );
  },
};

export const getTooltipValue = ({ colDef, data }: ITooltipParams<DemandGridRowData>) => {
  if (data?.__typename !== 'SyndicateInstitutionalGridRow') {
    return;
  }

  if (!colDef || !('field' in colDef)) {
    return;
  }
  const fieldAlertCodes = data?.alert.alertCodes?.filter(
    code => alertCodesMap[code].field === colDef.field
  );
  if (fieldAlertCodes?.length) {
    return 'ALERT'; // some value needs to be returned in order to display the tooltip even though it's not actually used
  }
};

export function getTooltipVariantForAlert(
  alert: OrderBook_InstitutionalDemand_GridRowPartsFragment['alert']
) {
  switch (alert.severity) {
    case 'ERROR':
      return 'error';
    case 'WARNING':
      return 'warning';
    default:
      return 'info';
  }
}

export const getTooltipVariant: NonNullable<
  DesignSystemTooltipProps<OrderBook_InstitutionalDemand_GridRowPartsFragment>['getVariant']
> = ({ data, colDef }) => {
  if (!colDef || !('field' in colDef)) {
    return 'info';
  }
  const alertCodes =
    data?.alert.alertCodes?.filter(code => alertCodesMap[code].field === colDef.field) ?? [];
  if (alertCodes.length === 0) {
    return 'info';
  }
  if (data?.alert) {
    return getTooltipVariantForAlert(data.alert);
  }
};

export const getUpdatedAllocations = (
  allocations: readonly OrderBook_InstitutionalDemand_AllocationPartsFragment[] | null | undefined,
  allocationSetId: UUID,
  value: number | null | undefined
) => {
  const allocation = allocations?.find(
    allocation => allocation.allocationSetId === allocationSetId
  );

  /**
   * Create new allocation with non-null value
   */
  if (!allocation && !isNil(value)) {
    return [...(allocations ?? []), { allocationSetId, shares: value }];
  }

  /**
   * Update existing allocation with non-null value
   */
  if (allocation && !isNil(value)) {
    return allocations?.map(allocation =>
      allocation.allocationSetId === allocationSetId ? { ...allocation, shares: value } : allocation
    );
  }

  /**
   * Remove existing allocation from the list
   */
  return allocations?.filter(allocation => allocation.allocationSetId !== allocationSetId);
};

export const defaultValueFormatter = <TValue = unknown>({
  data,
  value,
}: ValueFormatterParams<DemandGridRowData, TValue>) => {
  const hasToStringFn = typeof value?.toString === 'function';

  if (data?.__typename === 'SyndicateGridTotalsRow' || data?.__typename === 'Group') {
    return hasToStringFn ? value.toString() : '';
  }

  return hasToStringFn ? value.toString() : '-';
};

type GetValueFormatterProps<TValue> = {
  totalRow?: ValueFormatterFunc<TotalRow, TValue>;
  gridRow: ValueFormatterFunc<OrderBook_InstitutionalDemand_GridRowPartsFragment, TValue>;
  groupRow?: ValueFormatterFunc<OrderBook_InstitutionalDemand_GridGroupRowPartsFragment, TValue>;
};

export const getValueFormatter =
  <TValue>({
    totalRow,
    gridRow,
    groupRow,
  }: GetValueFormatterProps<TValue>): ValueFormatterFunc<DemandGridRowData, TValue> =>
  params => {
    if (params.data?.__typename === 'SyndicateGridTotalsRow') {
      return totalRow
        ? totalRow(params as ValueFormatterParams<TotalRow, TValue>)
        : defaultValueFormatter(params);
    }

    if (params.data?.__typename === 'SyndicateInstitutionalGridRow') {
      return gridRow(
        params as ValueFormatterParams<OrderBook_InstitutionalDemand_GridRowPartsFragment, TValue>
      );
    }

    if (params.data?.__typename === 'Group') {
      return groupRow
        ? groupRow(
            params as ValueFormatterParams<
              OrderBook_InstitutionalDemand_GridGroupRowPartsFragment,
              TValue
            >
          )
        : defaultValueFormatter(params);
    }

    return '-';
  };

type CellRendererSelectorProps = {
  totalRow?: CellRendererSelectorResult;
  gridRow?: CellRendererSelectorResult;
  groupRow?: CellRendererSelectorResult;
};

export const getCellRendererSelector =
  <TValue>(props: CellRendererSelectorProps): CellRendererSelectorFunc<DemandGridRowData, TValue> =>
  ({ data }) => {
    if (data?.__typename === 'SyndicateGridTotalsRow') {
      return props.totalRow;
    }

    if (data?.__typename === 'SyndicateInstitutionalGridRow') {
      return props.gridRow;
    }

    if (data?.__typename === 'Group') {
      return props.groupRow;
    }

    return undefined;
  };

export const managersFilterParams: DemandGridServerColDef<unknown>['filterParams'] = {
  values: ({ context, success }) => success(Object.values(context.syndicateManagers)),
  keyCreator: ({ value }) => value.cmgEntityKey,
  valueFormatter: ({ value }) => getFirmDisplayName(value),
};
