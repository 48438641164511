import { numericUtil } from '@cmg/common';
import React, { FC } from 'react';

import {
  ExclamationContainer,
  SArticle,
  SLabel,
  SPresentation,
  SQtyLayout,
} from './DesignationTotals.styles';
import ExclamationIcon from './ExclamationIcon';

export type Props = {
  allocations?: number;
  totalDesignations?: number;
};

export const DesignationTotals: FC<Props> = ({ allocations, totalDesignations }) => {
  const difference = (allocations ?? 0) - (totalDesignations ?? 0);

  return (
    <SArticle>
      <SQtyLayout>
        <SPresentation data-test-id="allocation">
          {numericUtil.formatInteger(allocations ?? 0)}
        </SPresentation>
        <SLabel>ALLOCATION</SLabel>
      </SQtyLayout>
      <SPresentation>-</SPresentation>
      <SQtyLayout>
        <SPresentation data-test-id="totalDesignations">
          {numericUtil.formatInteger(totalDesignations ?? 0)}
        </SPresentation>
        <SLabel>TOTAL DESIGNATIONS</SLabel>
      </SQtyLayout>
      <SPresentation>=</SPresentation>
      <SQtyLayout>
        <SPresentation>
          <ExclamationContainer>
            {difference !== 0 && <ExclamationIcon />}
            <span data-test-id="difference"> {numericUtil.formatInteger(difference)}</span>
          </ExclamationContainer>
        </SPresentation>
        <SLabel>DIFFERENCE</SLabel>
      </SQtyLayout>
    </SArticle>
  );
};

export default DesignationTotals;
