import { UUID } from '@cmg/common';

import { InvestorFirmOption } from '../../../../../../../../../../common/components/form/investor-firm-search/InvestorFirmSearch';

export const getInitialFirm = (params: {
  allInvestors: UUID[];
  defaultFirmId: UUID | null;
  defaultFirmName: string;
  defaultBankInvestorKey: string | null;
  defaultFirmLocation: string | null;
}): InvestorFirmOption | null => {
  if (params.defaultFirmId === null) {
    return null;
  }
  if (params.allInvestors.includes(params.defaultFirmId)) {
    return null;
  }

  return {
    firmId: params.defaultFirmId,
    label: params.defaultFirmName,
    value: params.defaultFirmId,
    firmKey: params.defaultBankInvestorKey,
    location: params.defaultFirmLocation,
  };
};
