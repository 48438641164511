import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Checkbox, FormControlLabel, Stack } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import RecipientListWarningIcon from '../../../../../../../common/components/lists/recipient-list/RecipientListWarningIcon';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { useIsMyFirmMissingEmail } from './hooks/useIsMyFirmMissingEmail';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly shouldCCMyFirm: boolean;
  readonly onChange: () => void;
  readonly onContactSupport: () => void;
}>;

export const CCMyFirmSection: React.FC<Props> = ({
  offeringId,
  shouldCCMyFirm,
  onChange,
  onContactSupport,
}) => {
  const hasOfferingsPermissions = useCheckPermissions([permissionsByEntity.Offering.FULL]);
  const isMyFirmMissingEmail = useIsMyFirmMissingEmail({ offeringId });
  const { isCcMyFirmEnabled } = useWireTypeConfigContext();

  if (!isCcMyFirmEnabled) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={shouldCCMyFirm}
            onChange={onChange}
            disabled={
              !hasOfferingsPermissions ||
              !!isMyFirmMissingEmail.data ||
              !!isMyFirmMissingEmail.error ||
              isMyFirmMissingEmail.isLoading
            }
            data-test-id={xcSelectors.wiresSendModalCCMyFirmCheckbox.testId}
          />
        }
        label="Send a copy of each sent wire to my firm."
      />
      {isMyFirmMissingEmail.data && (
        <RecipientListWarningIcon onContactSupport={onContactSupport} />
      )}
    </Stack>
  );
};
