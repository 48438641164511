import { AgGridApi, ToastManager, UUID } from '@cmg/common';
import React from 'react';

import { InternalReleaseStatus, TradeReleaseGridStatusTransition } from '../../../../../graphql';
import {
  TradeRelease_TradeGridItemFragment,
  useTradeRelease_ReleaseTradesMutation,
  useTradeRelease_UpdateTradesStatusMutation,
} from '../../graphql/__generated__';
import { unselectAllRows } from './useGridActions.model';

const successStringMap = new Map<TradeReleaseGridStatusTransition, string>([
  [TradeReleaseGridStatusTransition.Released, 'Released'],
  [TradeReleaseGridStatusTransition.CancelPending, 'Cancel Pending'],
  [TradeReleaseGridStatusTransition.CancelledManual, 'Cancelled (Manual)'],
  [TradeReleaseGridStatusTransition.BookedManual, 'Booked (Manual)'],
]);

const getSuccessString = (
  isBulk: boolean,
  affectedRowsCount: number,
  statusTransition: TradeReleaseGridStatusTransition
) => {
  const actionLabel = successStringMap.get(statusTransition);
  if (!isBulk) {
    return `Trade ${actionLabel}`;
  }
  return `${affectedRowsCount} Trade${affectedRowsCount === 1 ? '' : 's'} ${actionLabel}`;
};

export const useGridActions = (
  offeringId: UUID,
  gridApi: AgGridApi | undefined,
  isBulk: boolean = false
) => {
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [isCancellingManually, setIsCancellingManually] = React.useState(false);
  const [isMarkingAsBooked, setIsMarkingAsBooked] = React.useState(false);

  const [release, { loading: isReleasingTrades }] = useTradeRelease_ReleaseTradesMutation();
  const [updateStatus] = useTradeRelease_UpdateTradesStatusMutation();

  const executeReleaseTrades = React.useCallback(
    async (selectedRows: TradeRelease_TradeGridItemFragment[]) => {
      try {
        const indicationIds = selectedRows.map(({ indicationId }) => indicationId);
        await release({ variables: { offeringId, indicationIds } });

        unselectAllRows(gridApi);

        ToastManager.success(
          getSuccessString(isBulk, indicationIds.length, TradeReleaseGridStatusTransition.Released)
        );
      } catch {
        ToastManager.error('Failed to perform selected action');
      }
    },
    [gridApi, isBulk, offeringId, release]
  );
  const executeCancelRelease = React.useCallback(
    async (selectedRows: TradeRelease_TradeGridItemFragment[]) => {
      try {
        setIsCancelling(true);

        const tradeIds = selectedRows.map(({ tradeId }) => tradeId!);
        await updateStatus({
          variables: {
            offeringId,
            input: { status: InternalReleaseStatus.CancelPending, tradeIds },
          },
        });

        unselectAllRows(gridApi);
        ToastManager.success(
          getSuccessString(isBulk, tradeIds.length, TradeReleaseGridStatusTransition.CancelPending)
        );
      } catch {
        ToastManager.error('Failed to perform selected action');
      } finally {
        setIsCancelling(false);
      }
    },
    [gridApi, isBulk, offeringId, updateStatus]
  );
  const executeCancelManually = React.useCallback(
    async (selectedRows: TradeRelease_TradeGridItemFragment[]) => {
      try {
        setIsCancellingManually(true);

        const tradeIds = selectedRows.map(({ tradeId }) => tradeId!);
        await updateStatus({
          variables: {
            offeringId,
            input: { status: InternalReleaseStatus.CancelledManual, tradeIds },
          },
        });

        unselectAllRows(gridApi);
        ToastManager.success(
          getSuccessString(
            isBulk,
            tradeIds.length,
            TradeReleaseGridStatusTransition.CancelledManual
          )
        );
      } catch {
        ToastManager.error('Failed to perform selected action');
      } finally {
        setIsCancellingManually(false);
      }
    },
    [gridApi, isBulk, offeringId, updateStatus]
  );
  const executeMarkAsBooked = React.useCallback(
    async (selectedRows: TradeRelease_TradeGridItemFragment[]) => {
      try {
        setIsMarkingAsBooked(true);

        const tradeIds = selectedRows.map(({ tradeId }) => tradeId!);
        await updateStatus({
          variables: {
            offeringId,
            input: { status: InternalReleaseStatus.BookedManual, tradeIds },
          },
        });

        unselectAllRows(gridApi);
        ToastManager.success(
          getSuccessString(isBulk, tradeIds.length, TradeReleaseGridStatusTransition.BookedManual)
        );
      } catch {
        ToastManager.error('Failed to perform selected action');
      } finally {
        setIsMarkingAsBooked(false);
      }
    },
    [gridApi, isBulk, offeringId, updateStatus]
  );

  return {
    executeReleaseTrades,
    isReleasingTrades,
    executeCancelRelease,
    isCancelling,
    executeCancelManually,
    isCancellingManually,
    executeMarkAsBooked,
    isMarkingAsBooked,
    isLoading: isReleasingTrades || isCancelling || isCancellingManually || isMarkingAsBooked,
  };
};
