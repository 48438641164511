import React from 'react';

import ParticipantsForm, {
  Props as ParticipantsFormProps,
} from '../../../../common/participants-form/ParticipantsForm';
import MeetingButtonGroup, {
  Props as ButtonGroupProps,
} from '../../../common/meeting-button-group/MeetingButtonGroup';

export type Props = Omit<ParticipantsFormProps, 'title'> &
  Omit<ButtonGroupProps, 'eventParticipantsTeam'>;

const MeetingParticipantsForm: React.FC<Props> = ({
  participantTeams,
  meetingManagementMembers,
  meetingStatus,
  roadshowStatus,
  meetingInvestors,
  roster,
  meetingHeaderInfo,
  isSaving,
  isDeleting,
  isOpeningToSales,
  isConfirming,
  onDelete,
  onCancel,
  onOpenToSales,
  onConfirm,
  onSubmit,
  companyName,
}) => (
  <ParticipantsForm
    companyName={companyName}
    participantTeams={participantTeams}
    onSubmit={onSubmit}
    roster={roster}
    title="Management & Deal Team"
  >
    <MeetingButtonGroup
      meetingHeaderInfo={meetingHeaderInfo}
      roadshowStatus={roadshowStatus}
      onDelete={onDelete}
      onCancel={onCancel}
      onOpenToSales={onOpenToSales}
      onConfirm={onConfirm}
      isDeleting={isDeleting}
      isOpeningToSales={isOpeningToSales}
      isConfirming={isConfirming}
      isSaving={isSaving}
      meetingStatus={meetingStatus}
      meetingManagementMembers={meetingManagementMembers}
      eventParticipantsTeam={participantTeams}
      meetingInvestors={meetingInvestors}
    />
  </ParticipantsForm>
);

export default MeetingParticipantsForm;
