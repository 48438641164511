import { ApolloError } from '@apollo/client';
import {
  DatePickerField,
  ISODate,
  TextInputField,
  timeUtil,
  UnsavedChangesGuard,
} from '@cmg/common';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import SetupForm from '../../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../../components/form/OfferingSetupFormActions';
import { OfferingSetup_RoadshowSchedulePartsFragment } from '../../graphql';

export const RoadshowScheduleSchema = yup.object().shape({
  date: yup.date().nullable().required('Date is Required'),
  details: yup.string().nullable().required('Details are Required'),
});

export type FormType = {
  date: ISODate;
  details: string;
};

export type OwnProps = {
  editing?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit: (payload: FormType) => void;
  roadshowSchedule?: OfferingSetup_RoadshowSchedulePartsFragment;
};

export type Props = OwnProps & FormikProps<FormType>;

export const RoadshowScheduleFormComponent: React.FC<Props> = ({
  editing,
  disabled,
  onEdit,
  onCancel,
  onDelete,
  handleSubmit,
  roadshowSchedule,
  dirty,
  resetForm,
}) => {
  const actionProps = {
    editing,
    disabled,
    onEdit,
    onCancel,
    onDelete,
    onSubmit: handleSubmit,
  };

  return (
    <UnsavedChangesGuard when={dirty} onLeave={resetForm}>
      <SetupForm.Row active={editing}>
        <SetupForm.Column>
          {editing ? (
            <DatePickerField fullWidth name="date" />
          ) : (
            <SetupForm.Field>
              {roadshowSchedule?.date && timeUtil.formatAsDisplayDayAndDate(roadshowSchedule.date)}
            </SetupForm.Field>
          )}
        </SetupForm.Column>
        <SetupForm.Column>
          {editing ? (
            <TextInputField fullWidth disabled={!editing} name="details" placeholder="Details" />
          ) : (
            <SetupForm.Field>{roadshowSchedule?.details}</SetupForm.Field>
          )}
        </SetupForm.Column>
        <OfferingSetupFormActions {...actionProps} />
      </SetupForm.Row>
    </UnsavedChangesGuard>
  );
};

export default withFormik<OwnProps, FormType>({
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: RoadshowScheduleSchema,
  mapPropsToValues: ({ roadshowSchedule }) => ({
    date: roadshowSchedule?.date ?? '',
    details: roadshowSchedule?.details ?? '',
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(RoadshowScheduleFormComponent);
