import styled from 'styled-components/macro';

import Panel from '../../../../../common/components/layout/panel/Panel';

export const SPanelHeaderContent = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: end;
`;

export const StyledPanelHeader = styled(Panel.Header)`
  height: 48px;
`;
