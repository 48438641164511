import styled from 'styled-components/macro';

export const SIssuerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  flex-grow: 1;
`;
