import { Popover } from '@cmg/common';
import React from 'react';

import Banner from '../../../../../../common/components/indicators/banner/Banner';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import {
  SComplianceActionRequiredTitle,
  SComplianceBannerWrapper,
  SComplianceTitleWrapper,
} from '../../../indication-activity/compliance/ComplianceActionRequiredBanner.styles';
import { useComplianceContext } from '../../context/ComplianceContext';
import { StyledComplianceLink } from '../ComplianceCertificates.styles';
import { SCertificatesInfo, SPopoverContentWrapper } from './ComplianceInfo.styles';

export type Props = {
  offeringId: string;
};

const ComplianceInfo: React.FC<Props> = ({ offeringId }) => {
  const { type } = useIdentifyOrderBookPersona({ offeringId });
  const { warning } = useComplianceContext().certificates;

  const hoverContent = `If certificates are not on file or expired, provide the associated Action link to the investor to complete. 
    Certificates become eligible typically within five minutes of submission, but may take up to one business day.`;

  return (
    <div>
      <SCertificatesInfo>
        Participation in this offering requires the following eligible certificates.{' '}
      </SCertificatesInfo>
      {type === OrderBookPersonaType.BUY_SIDE && warning.hasComplianceWarning && (
        <SComplianceBannerWrapper
          padding="0px 0px 0px 0px"
          data-test-id="compliance-buy-side-message"
        >
          <Banner variant="information" padding="9px 18px 9px 18px">
            <SComplianceTitleWrapper data-test-id="indication-compliance-error-banner">
              <SComplianceActionRequiredTitle>
                Certificate not on file or expired
              </SComplianceActionRequiredTitle>
            </SComplianceTitleWrapper>
            Click Copy link to submit valid certificates via the provided link. Certificates become
            eligible typically within five minutes of submission, but may take up to one business
            day. For support, contact <a href="mailto:support@cmgx.io">CMG support</a>.
          </Banner>
        </SComplianceBannerWrapper>
      )}
      {type !== OrderBookPersonaType.BUY_SIDE && (
        <Popover
          content={<SPopoverContentWrapper>{hoverContent}</SPopoverContentWrapper>}
          variant="DARK"
        >
          <StyledComplianceLink data-test-id="compliance-message">More info</StyledComplianceLink>
        </Popover>
      )}
    </div>
  );
};

export default ComplianceInfo;
