import { IndicationForm_OfferingPartsFragment } from './graphql/__generated__';

export const computeDefaultValuesForTrancheDemandCurrencyAndInstrument = (
  offering: IndicationForm_OfferingPartsFragment
) => {
  const defaultTranche =
    offering.orderEntryProfile.tranches.length === 1
      ? offering.orderEntryProfile.tranches[0]
      : null;
  const defaultTranchDeliveryInstruments = offering.orderEntryProfile.instruments.filter(item =>
    defaultTranche?.deliveryInstrumentIds?.includes(item.id)
  );
  const defaultDeliveryInstrument =
    defaultTranchDeliveryInstruments.length === 1 ? defaultTranchDeliveryInstruments[0].id : null;

  const defaultCurrencyCode = defaultTranche?.demandCurrencies[0]?.toUpperCase() ?? null;

  return {
    trancheId: defaultTranche?.id,
    instrumentId: defaultDeliveryInstrument,
    currencyCode: defaultCurrencyCode,
  };
};
