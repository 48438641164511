import {
  ApolloCache,
  ApolloError,
  DocumentNode,
  FetchResult,
  Reference,
  StoreObject,
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import type { GraphQLFormattedError } from 'graphql';

import { ServiceErrorCode } from '../../types/domain/error/ServiceErrorCode';
import { ApiError } from '../../types/graphql/error/ApiError';

/**
 * Checks if at least one of api error codes equals to not found.
 */
export const hasNotFoundError = (error?: ApolloError) => {
  const { apiErrors } = parseGQLError(error);

  return apiErrors.some(
    apiError => apiError.extensions?.response.body.error?.code === ServiceErrorCode.NOT_FOUND
  );
};

/**
 * Given Apollo error parses graphQl errors to api errors and server errors.
 * Has error flag will be true if at least one error occurs.
 */
export const parseGQLError = (error?: ApolloError) => {
  if (!error || (!error.graphQLErrors.length && !error.networkError)) {
    return { hasError: false, apiErrors: [], serverErrors: [], networkError: null };
  }

  const [apiErrors, serverErrors] = error.graphQLErrors.reduce<
    [ApiError[], GraphQLFormattedError[]]
  >(
    ([apiErrors, serverErrors], graphQlError) => {
      if (graphQlError.extensions?.response?.body?.error) {
        return [[...apiErrors, graphQlError as ApiError], serverErrors];
      }

      return [apiErrors, [...serverErrors, graphQlError]];
    },
    [[], []]
  );

  return {
    hasError: true,
    apiErrors,
    serverErrors,
    networkError: error.networkError,
  };
};

export interface IEntity {
  id: string;
}

/**
 * Reusable factory to create a callback to update GraphQL cache
 * Callback itself removes an element from an array
 * see original docs https://www.apollographql.com/docs/react/caching/cache-interaction/#example-removing-an-item-from-a-list
 */
export const deleteArrayItemFromGraphQLCacheFactory = <TData = unknown>(
  parentEntity: StoreObject | Reference,
  fieldName: string,
  selectItem: (result: FetchResult<TData>) => IEntity | undefined
) => {
  return (cache: ApolloCache<TData>, mutationResult: FetchResult<TData>): void => {
    const item = selectItem(mutationResult);

    if (!item?.id) {
      return;
    }

    cache.modify<{ [key: string]: (StoreObject | Reference)[] }>({
      id: cache.identify(parentEntity),
      fields: {
        [fieldName]: (existing, { readField }) => {
          return existing.filter(existingIdm => readField('id', existingIdm) !== item.id);
        },
      },
    });
  };
};

/**
 * Reusable factory to create a callback to update GraphQL cache
 * Callback itself updates an existing element in an array
 * see original docs  https://www.apollographql.com/docs/react/caching/cache-interaction/#example-updating-the-cache-after-a-mutation
 */
export const updateArrayItemInGraphQLCacheFactory = <TData>(
  parentEntity: StoreObject | Reference,
  fieldName: string,
  selectItem: (result: FetchResult<TData>) => IEntity | undefined
) => {
  return (cache: ApolloCache<TData>, mutationResult: FetchResult<TData>): void => {
    const item = selectItem(mutationResult);

    if (!item?.id) {
      return;
    }

    cache.modify({
      id: cache.identify(parentEntity),
      fields: {
        [fieldName]: (existing: readonly (StoreObject | Reference)[], { readField }) => {
          return existing.map(existingItem => {
            return readField('id', existingItem) === item.id ? item : existingItem;
          });
        },
      },
    });
  };
};

/**
 * Reusable factory to create a callback to update GraphQL cache
 * Callback itself adds a new existing element to an array
 * see related docs  https://www.apollographql.com/docs/react/caching/cache-interaction/#example-updating-the-cache-after-a-mutation
 */
export const addArrayItemToGraphQLCacheFactory = <TData>(
  parentEntity: StoreObject | Reference,
  fieldName: string,
  selectItem: (result: FetchResult<TData>) => IEntity | undefined
) => {
  return (cache: ApolloCache<TData>, mutationResult: FetchResult<TData>): void => {
    const item = selectItem(mutationResult);

    if (!item) {
      return;
    }

    cache.modify<{ [key: string]: readonly IEntity[] }>({
      id: cache.identify(parentEntity),
      fields: {
        [fieldName]: existing => [...(existing as readonly IEntity[]), item],
      },
    });
  };
};

export const getManyOperationNames = (operations: DocumentNode[]) => {
  return operations.map(o => getOperationName(o)!);
};
