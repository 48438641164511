import { LinkButton, Popover } from '@cmg/common';
import isString from 'lodash/isString';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openInstitutionalInvestorExtendedDataModal } from '../../components/investor-extended-data-modal/InstitutionalDemandMenuModal';
import { SContent, SText } from './DynamicColumnPopover.styles';

export type Props = {
  value?: string | number | null;
  investorName: string;
  firmKey: string | null | undefined;
  field: string;
};

export const DYNAMIC_COLUMN_MAX_LENGTH = 200;

export const DynamicColumnPopover: React.FC<Props> = ({
  children,
  value,
  investorName,
  firmKey,
  field,
}) => {
  const dispatch = useDispatch();
  const hasMoreOption = isString(value) && value.length > DYNAMIC_COLUMN_MAX_LENGTH;
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  return (
    <Popover
      visible={isVisible}
      variant="DARK"
      transparentBackground
      disabled={!isString(value)}
      onVisibleChange={value => setIsVisible(value)}
      content={
        <SContent>
          <SText>
            {value}
            {hasMoreOption && '...'}
          </SText>
          {hasMoreOption && (
            <LinkButton
              inline
              data-test-id="extended-data-more-button"
              onClick={() => {
                dispatch(
                  openInstitutionalInvestorExtendedDataModal({ investorName, firmKey, field })
                );
                setIsVisible(false);
              }}
            >
              More
            </LinkButton>
          )}
        </SContent>
      }
    >
      {children}
    </Popover>
  );
};
