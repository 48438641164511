import { CustomTooltipProps, DesignSystemTooltipProps } from '@cmg/data-grid';
import { Box, Typography } from '@cmg/design-system';
import React from 'react';

import { getFirmDisplayName } from '../../../../utils';
import { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import { DemandGridDataContext } from '../../types';
import { sortAcknowledgements } from './AcknowledgementsCellRenderer.model';

export type Props = CustomTooltipProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  string,
  DemandGridDataContext
>;

const AcknowledgementsTooltip = ({ data, context }: Props) => {
  const sortedAcknowledgements = React.useMemo(
    () => sortAcknowledgements(data?.acknowledgements, context.oidcUserCmgEntityKey),
    [data?.acknowledgements, context.oidcUserCmgEntityKey]
  );

  const hasRevised = React.useMemo(
    () => sortedAcknowledgements?.find(item => item.isRevised),
    [sortedAcknowledgements]
  );
  const content = React.useMemo(
    () =>
      sortedAcknowledgements?.map((acknowledgment, index) => (
        <Typography
          variant="tooltip"
          key={`ack-${index}`}
          component="div"
          fontWeight={
            acknowledgment.manager.firmKey === context.oidcUserCmgEntityKey &&
            acknowledgment.isRevised
              ? 'bold'
              : undefined
          }
          color={acknowledgment.isRevised ? theme => theme.palette.warning.dark : undefined}
        >
          {getFirmDisplayName(acknowledgment.manager, {
            ignoreAbbreviation: true,
            defaultName: acknowledgment.manager.firmKey,
          })}
        </Typography>
      )),
    [context.oidcUserCmgEntityKey, sortedAcknowledgements]
  );

  return (
    <Box width={200}>
      {content}
      {hasRevised && (
        <Typography variant="tooltip" component="div" marginTop={0.5} color="text.disabled">
          Highlighted names show indication has been revised since firm’s last acknowledgement.
        </Typography>
      )}
    </Box>
  );
};

export const getContent: DesignSystemTooltipProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  string,
  DemandGridDataContext
>['getContent'] = () => AcknowledgementsTooltip;

export default AcknowledgementsTooltip;
