/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_BaseRegulatoryFilingPartsFragment = {
  readonly __typename?: 'BaseRegulatoryFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
};

export type RegulatoryFilings_FinraManagerSnapshotPartsFragment = {
  readonly __typename?: 'FinraManagerSnapshot';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly firmName: string;
};

export type RegulatoryFilings_RegulatoryFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_RegulatoryFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly RPNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
      readonly NOIFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
      readonly TNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
      readonly USDTNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
      readonly DSFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export type RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment = {
  readonly __typename?: 'WireOfferingDetail';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly issuerName: string;
  readonly stockSymbols: ReadonlyArray<string>;
  readonly pricingInstrumentSymbol?: string | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly offerPrice?: number | null;
  readonly pricingDate?: string | null;
  readonly totalSharesBaseOffering?: number | null;
  readonly tradeDate?: string | null;
};

export type RegulatoryFilings_RolodexFilingRecipientPartsFragment = {
  readonly __typename?: 'RegulatoryFilingRecipient';
  readonly id: string;
  readonly firmName: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly distributionList: ReadonlyArray<string>;
};

export type RegulatoryFilings_RolodexSyndicateManagerPartsFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly firmName: string;
  readonly recordStatus: Types.RecordStatus;
  readonly isParticipating: boolean;
  readonly finraInfo?: {
    readonly __typename?: 'FinraInfo';
    readonly name?: string | null;
    readonly crdNumber?: number | null;
    readonly mpid?: string | null;
  } | null;
  readonly affiliates: ReadonlyArray<{
    readonly __typename?: 'Affiliate';
    readonly id: string;
    readonly name: string;
    readonly crdNumber?: number | null;
    readonly mpid?: string | null;
  }>;
};

export type RegulatoryFilings_RolodexUnderwriterFirmPartsFragment = {
  readonly __typename?: 'Firm';
  readonly id: string;
  readonly displayName?: string | null;
  readonly ticker?: string | null;
  readonly crd?: string | null;
  readonly mpid?: string | null;
};

export type RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment = {
  readonly __typename?: 'FilingLastCommonValues';
  readonly contactName?: string | null;
  readonly contactTitle?: string | null;
  readonly telephoneNumber?: string | null;
  readonly emailAddress?: string | null;
  readonly issuerSymbol?: string | null;
};

export type RegulatoryFilings_RolodexCommonFilingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type RegulatoryFilings_RolodexCommonFilingQuery = {
  readonly __typename?: 'Query';
  readonly filingLastCommonValues: {
    readonly __typename?: 'FilingLastCommonValues';
    readonly contactName?: string | null;
    readonly contactTitle?: string | null;
    readonly telephoneNumber?: string | null;
    readonly emailAddress?: string | null;
    readonly issuerSymbol?: string | null;
  };
  readonly underwriter: {
    readonly __typename?: 'Firm';
    readonly id: string;
    readonly displayName?: string | null;
    readonly ticker?: string | null;
    readonly crd?: string | null;
    readonly mpid?: string | null;
  };
  readonly filingOfferingDetail: {
    readonly __typename?: 'WireOfferingDetail';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly issuerName: string;
    readonly stockSymbols: ReadonlyArray<string>;
    readonly pricingInstrumentSymbol?: string | null;
    readonly lastTradeBeforeOffer?: number | null;
    readonly offerPrice?: number | null;
    readonly pricingDate?: string | null;
    readonly totalSharesBaseOffering?: number | null;
    readonly tradeDate?: string | null;
  };
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly recipients: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingRecipient';
        readonly id: string;
        readonly firmName: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly distributionList: ReadonlyArray<string>;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly recordStatus: Types.RecordStatus;
        readonly isParticipating: boolean;
        readonly finraInfo?: {
          readonly __typename?: 'FinraInfo';
          readonly name?: string | null;
          readonly crdNumber?: number | null;
          readonly mpid?: string | null;
        } | null;
        readonly affiliates: ReadonlyArray<{
          readonly __typename?: 'Affiliate';
          readonly id: string;
          readonly name: string;
          readonly crdNumber?: number | null;
          readonly mpid?: string | null;
        }>;
      }>;
    };
  };
};

export type RegulatoryFilings_FilingNotificationMessagePartsFragment = {
  readonly __typename?: 'RegulatoryFilingNotificationMessage';
  readonly id: string;
  readonly status: Types.NotificationMessageStatus;
  readonly sentAt?: string | null;
  readonly distributionList: ReadonlyArray<string>;
  readonly bccEmailAddresses: ReadonlyArray<string>;
  readonly managerSnapshot: {
    readonly __typename?: 'FinraManagerSnapshot';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly role: Types.ManagerRole;
    readonly firmName: string;
  };
};

export type RegulatoryFilings_SendFilingEmailNotificationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
  recipients: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  shouldAddSenderToBcc: Types.Scalars['Boolean'];
}>;

export type RegulatoryFilings_SendFilingEmailNotificationMutation = {
  readonly __typename?: 'Mutation';
  readonly sendRegulatoryFilingEmailNotification: ReadonlyArray<{
    readonly __typename?: 'RegulatoryFilingNotificationMessage';
    readonly id: string;
    readonly status: Types.NotificationMessageStatus;
    readonly sentAt?: string | null;
    readonly distributionList: ReadonlyArray<string>;
    readonly bccEmailAddresses: ReadonlyArray<string>;
    readonly managerSnapshot: {
      readonly __typename?: 'FinraManagerSnapshot';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly role: Types.ManagerRole;
      readonly firmName: string;
    };
  }>;
};

export const RegulatoryFilings_BaseRegulatoryFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_BaseRegulatoryFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseRegulatoryFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RolodexFilingOfferingDetailPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingOfferingDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireOfferingDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbols' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RolodexFilingRecipientPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingRecipientParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingRecipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RolodexSyndicateManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexSyndicateManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RolodexUnderwriterFirmPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexUnderwriterFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Firm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingLastCommonValuesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingLastCommonValues' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_FinraManagerSnapshotPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_FilingNotificationMessagePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_RegulatoryFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_RegulatoryFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RPNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_BaseRegulatoryFilingParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NOIFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_BaseRegulatoryFilingParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_BaseRegulatoryFilingParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'USDTNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_BaseRegulatoryFilingParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'DSFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_BaseRegulatoryFilingParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_BaseRegulatoryFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BaseRegulatoryFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_RegulatoryFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_RegulatoryFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_RegulatoryFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_RegulatoryFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_RegulatoryFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_RegulatoryFilingsQuery,
    RegulatoryFilings_RegulatoryFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_RegulatoryFilingsQuery,
    RegulatoryFilings_RegulatoryFilingsQueryVariables
  >(RegulatoryFilings_RegulatoryFilingsDocument, options);
}
export function useRegulatoryFilings_RegulatoryFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_RegulatoryFilingsQuery,
    RegulatoryFilings_RegulatoryFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_RegulatoryFilingsQuery,
    RegulatoryFilings_RegulatoryFilingsQueryVariables
  >(RegulatoryFilings_RegulatoryFilingsDocument, options);
}
export type RegulatoryFilings_RegulatoryFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RegulatoryFilingsQuery
>;
export type RegulatoryFilings_RegulatoryFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RegulatoryFilingsLazyQuery
>;
export type RegulatoryFilings_RegulatoryFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_RegulatoryFilingsQuery,
  RegulatoryFilings_RegulatoryFilingsQueryVariables
>;
export const RegulatoryFilings_RolodexCommonFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexCommonFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingLastCommonValues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_RolodexFilingLastCommonValuesParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_RolodexUnderwriterFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filingOfferingDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_RolodexFilingOfferingDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipients' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_RolodexFilingRecipientParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_RolodexSyndicateManagerParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingOfferingDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireOfferingDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbols' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingRecipientParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingRecipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexSyndicateManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crdNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexUnderwriterFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Firm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_RolodexFilingLastCommonValuesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingLastCommonValues' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_RolodexCommonFilingQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_RolodexCommonFilingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_RolodexCommonFilingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_RolodexCommonFilingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useRegulatoryFilings_RolodexCommonFilingQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_RolodexCommonFilingQuery,
    RegulatoryFilings_RolodexCommonFilingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_RolodexCommonFilingQuery,
    RegulatoryFilings_RolodexCommonFilingQueryVariables
  >(RegulatoryFilings_RolodexCommonFilingDocument, options);
}
export function useRegulatoryFilings_RolodexCommonFilingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_RolodexCommonFilingQuery,
    RegulatoryFilings_RolodexCommonFilingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_RolodexCommonFilingQuery,
    RegulatoryFilings_RolodexCommonFilingQueryVariables
  >(RegulatoryFilings_RolodexCommonFilingDocument, options);
}
export type RegulatoryFilings_RolodexCommonFilingQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RolodexCommonFilingQuery
>;
export type RegulatoryFilings_RolodexCommonFilingLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_RolodexCommonFilingLazyQuery
>;
export type RegulatoryFilings_RolodexCommonFilingQueryResult = Apollo.QueryResult<
  RegulatoryFilings_RolodexCommonFilingQuery,
  RegulatoryFilings_RolodexCommonFilingQueryVariables
>;
export const RegulatoryFilings_SendFilingEmailNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SendFilingEmailNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recipients' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToBcc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendRegulatoryFilingEmailNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recipients' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recipients' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldAddSenderToBcc' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToBcc' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SendFilingEmailNotificationMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SendFilingEmailNotificationMutation,
  RegulatoryFilings_SendFilingEmailNotificationMutationVariables
>;

/**
 * __useRegulatoryFilings_SendFilingEmailNotificationMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SendFilingEmailNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SendFilingEmailNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSendFilingEmailNotificationMutation, { data, loading, error }] = useRegulatoryFilings_SendFilingEmailNotificationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *      recipients: // value for 'recipients'
 *      shouldAddSenderToBcc: // value for 'shouldAddSenderToBcc'
 *   },
 * });
 */
export function useRegulatoryFilings_SendFilingEmailNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SendFilingEmailNotificationMutation,
    RegulatoryFilings_SendFilingEmailNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SendFilingEmailNotificationMutation,
    RegulatoryFilings_SendFilingEmailNotificationMutationVariables
  >(RegulatoryFilings_SendFilingEmailNotificationDocument, options);
}
export type RegulatoryFilings_SendFilingEmailNotificationMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SendFilingEmailNotificationMutation
>;
export type RegulatoryFilings_SendFilingEmailNotificationMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SendFilingEmailNotificationMutation>;
export type RegulatoryFilings_SendFilingEmailNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    RegulatoryFilings_SendFilingEmailNotificationMutation,
    RegulatoryFilings_SendFilingEmailNotificationMutationVariables
  >;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeRegulatoryFilings_RegulatoryFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_RegulatoryFilingsQueryVariables,
  RegulatoryFilings_RegulatoryFilingsQuery
>(RegulatoryFilings_RegulatoryFilingsDocument);

export const makeRegulatoryFilings_RolodexCommonFilingMockResponse = makeMockResponse<
  RegulatoryFilings_RolodexCommonFilingQueryVariables,
  RegulatoryFilings_RolodexCommonFilingQuery
>(RegulatoryFilings_RolodexCommonFilingDocument);
