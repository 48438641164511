import { getOperationName } from '@apollo/client/utilities';

import { FinalSettlement_SettlementAccountingDocument } from '../../../../graphql';
import { useFinalSettlement_UpdateAccountingManualAdjustmentMutation } from '../graphql';

export const refetchQueries = () => {
  const accountingQuery = getOperationName(FinalSettlement_SettlementAccountingDocument);
  return [accountingQuery!];
};

export const useUpdateAccountingManualAdjustmentMutation = () => {
  return useFinalSettlement_UpdateAccountingManualAdjustmentMutation({
    // There is potential performance cost here.
    // Ideally, once the whole accounting module is connected to BE,
    // we could try to surgically update only relevant fields on the underwriters
    // field inside the cahced accounting data.
    refetchQueries,
  });
};
