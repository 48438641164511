import { getCurrencySymbol, NumericInputField, numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import { InfoBox } from '../../../common/components/info-box/InfoBox';
import SetupForm from '../components/form/OfferingSetupForm';
import { OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery } from '../graphql';
import { getPerShareValue, getSumDisplayValue } from './UnderwritingDiscountForm.model';
import { UndewritingFeesAndDiscountFormValues } from './UnderwritingDiscountsAndFeesRoute.model';
import {
  SCellContainer,
  SColumnHeader,
  SColumnHeaderContainer,
  SDivider,
  SFieldGrid,
  SFirtEmptyCell,
  SRow,
  SRowTitleContainer,
  STotalCellContainer,
  STotalRowTitleContainer,
} from './UnderwritingDiscountsAndFeesRoute.styles';

export type Props = {
  underwritingFeesDiscounts?: OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery;
  offerPrice: number | null;
  pricingCurrencyCode?: string;
};

export const UnderwritingDiscountForm: React.FC<Props> = ({
  underwritingFeesDiscounts,
  offerPrice,
  pricingCurrencyCode,
}) => {
  const {
    values: { grossSpreadBase: grossSpreadBaseFormValue, incentiveFee: incentiveFeeFormValue },
    dirty: isFormDirty,
  } = useFormikContext<UndewritingFeesAndDiscountFormValues>();

  const { netPrice = null } = underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts ?? {};
  const grossSpreadBase =
    underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.grossSpreadBaseData
      ?.grossSpreadBase ?? null;
  const incentiveFee =
    underwritingFeesDiscounts?.getUnderwritingFeesAndDiscounts.incentiveFeeData?.incentiveFee ??
    null;
  const grossSpreadBasePercentagePerShare = getPerShareValue(grossSpreadBase, offerPrice);
  const incentiveFeePercentagePerShare = getPerShareValue(incentiveFee, offerPrice);
  const grossSpreadBasePercentagePerShareFormValue = getPerShareValue(
    grossSpreadBaseFormValue,
    offerPrice
  );
  const incentiveFeePercentagePerShareFormValue = getPerShareValue(
    incentiveFeeFormValue,
    offerPrice
  );

  const grossSpreadBasePercentagePerShareDisplayValue = getSumDisplayValue({
    values: [grossSpreadBasePercentagePerShare],
    formValues: [grossSpreadBasePercentagePerShareFormValue],
    isFormDirty,
  });

  const incentiveFeePercentagePerShareDisplayValue = getSumDisplayValue({
    values: [incentiveFeePercentagePerShare],
    formValues: [incentiveFeePercentagePerShareFormValue],
    isFormDirty,
  });

  const totalGrossSpreadCurrencyPerShare = getSumDisplayValue({
    values: [grossSpreadBase, incentiveFee],
    formValues: [grossSpreadBaseFormValue, incentiveFeeFormValue],
    isFormDirty,
  });

  const totalGrossSpreadPercentPerShare = getSumDisplayValue({
    values: [grossSpreadBasePercentagePerShare, incentiveFeePercentagePerShare],
    formValues: [
      grossSpreadBasePercentagePerShareFormValue,
      incentiveFeePercentagePerShareFormValue,
    ],
    isFormDirty,
  });

  const netPriceDisplayValue = getSumDisplayValue({
    values: [netPrice],
    formValues: [(offerPrice ?? 0) - (totalGrossSpreadCurrencyPerShare ?? 0)],
    isFormDirty,
  });

  const displayNetPrice = netPriceDisplayValue && offerPrice;

  return (
    <SetupForm title="Underwriting Discount">
      <SRow>
        <SFieldGrid>
          <SColumnHeaderContainer>
            <SFirtEmptyCell />
          </SColumnHeaderContainer>
          <SColumnHeaderContainer>
            <SColumnHeader>{getCurrencySymbol(pricingCurrencyCode)} per share</SColumnHeader>
          </SColumnHeaderContainer>
          <SColumnHeaderContainer>
            <SColumnHeader>% per share</SColumnHeader>
          </SColumnHeaderContainer>
          <SRowTitleContainer>Base Gross Spread</SRowTitleContainer>
          <SCellContainer>
            <NumericInputField
              suffix={getCurrencySymbol(pricingCurrencyCode)}
              name="grossSpreadBase"
              precision={6}
              displayPrecision
            />
          </SCellContainer>
          <SCellContainer>
            {grossSpreadBasePercentagePerShareDisplayValue
              ? numericUtil.formatPercents(grossSpreadBasePercentagePerShareDisplayValue, 2, 0)
              : '-'}
          </SCellContainer>
          <SRowTitleContainer>Incentive Fee</SRowTitleContainer>
          <SCellContainer>
            <NumericInputField
              suffix={getCurrencySymbol(pricingCurrencyCode)}
              name="incentiveFee"
              precision={6}
              displayPrecision
            />
          </SCellContainer>
          <SCellContainer>
            {incentiveFeePercentagePerShareDisplayValue
              ? numericUtil.formatPercents(incentiveFeePercentagePerShareDisplayValue, 2, 0)
              : '-'}
          </SCellContainer>
          <STotalRowTitleContainer>Total Gross Spread</STotalRowTitleContainer>
          <STotalCellContainer>
            {numericUtil.getDisplayValueForCurrency(
              totalGrossSpreadCurrencyPerShare,
              6,
              pricingCurrencyCode
            )}
          </STotalCellContainer>
          <STotalCellContainer>
            {totalGrossSpreadPercentPerShare
              ? numericUtil.formatPercents(totalGrossSpreadPercentPerShare / 100, 2)
              : '-'}
          </STotalCellContainer>
        </SFieldGrid>
        <InfoBox gridTemplateColumns="3fr 10px 1.5fr">
          <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
            Underwriting Discount
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Offer Price
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(offerPrice, 6, pricingCurrencyCode)}
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false}>
            Total Gross Spread
          </Table.Cell>
          <Table.Cell paddingSize="S" bold withBottomBorder={false}>
            {totalGrossSpreadCurrencyPerShare ? '-' : null}
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
            {numericUtil.getDisplayValueForCurrency(
              totalGrossSpreadCurrencyPerShare,
              6,
              pricingCurrencyCode
            )}
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false} bold>
            <SDivider />
          </Table.Cell>
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
            Net Price
          </Table.Cell>
          <Table.Cell paddingSize="S" bold align="right" withBottomBorder={false}>
            {displayNetPrice
              ? numericUtil.getDisplayValueForCurrency(netPriceDisplayValue, 6, pricingCurrencyCode)
              : '-'}
          </Table.Cell>
        </InfoBox>
      </SRow>
    </SetupForm>
  );
};
