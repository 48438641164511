import { QueryResult } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { useWindowVisibility } from '@cmg/common';
import { useEffect } from 'react';

export type Props<TData, TVariables extends OperationVariables = OperationVariables> = Pick<
  QueryResult<TData, TVariables>,
  'startPolling' | 'stopPolling' | 'called'
> &
  Readonly<{ pollInterval: number; isEnabled?: boolean }>;

/**
 * This hook will pause polling when the window/tab becomes hidden.
 * The recommended poll interval is 5000ms or lower as it also serves as debounced timeout when resuming polling.
 * @param pollInterval
 * @param isEnabled
 * @param startPolling
 * @param stopPolling
 * @param called
 */
const usePollingWhenVisible = <TData, TVariables extends OperationVariables = OperationVariables>({
  pollInterval,
  isEnabled = true, // Enabled by default
  startPolling,
  stopPolling,
  called,
}: Props<TData, TVariables>) => {
  const isWindowVisible = useWindowVisibility();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    /**
     * Polling should be started only if the query was called at least once.
     * This is important when using the hook with lazy query which might not have all the required variables set yet
     * or are intentionally called later in the execution.
     */
    if (!called) {
      return;
    }

    if (isWindowVisible) {
      startPolling(pollInterval);
    }

    if (!isWindowVisible) {
      stopPolling();
    }
  }, [isWindowVisible, startPolling, stopPolling, pollInterval, isEnabled, called]);
};

export default usePollingWhenVisible;
