import { IndicationStatus } from '../../../../../../graphql';

export const getIndicationStatusColor = (status: IndicationStatus) => {
  switch (status) {
    case IndicationStatus.Active:
      return 'success';
    case IndicationStatus.Cancelled:
      return 'action';
    case IndicationStatus.Duplicate:
      return 'disabled';
  }
};
