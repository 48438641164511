import {
  ActionPanelSection,
  ActionPanelSectionContentSkeleton,
  ActionPanelSectionSkeleton,
  Button,
  LoadingButton,
  Stack,
} from '@cmg/design-system';
import React from 'react';

import ServerErrorsBanner from '../../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useFeatureToggles } from '../../../../../../../../common/config';
import usePollingWhenVisible from '../../../../../../../../common/hooks/usePollingWhenVisible';
import useCheckInstitutionalDuplicateAccess from '../../../../../../../../common/util/check-access/useCheckInstitutionalDuplicateAccess';
import useCheckPassiveOrderBookAccess from '../../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import {
  IndicationStatus as IndicationStatusType,
  InstitutionalIndicationOrderType,
} from '../../../../../../../../graphql';
import { useSupersededOfferingContext } from '../../../../../../contexts/SupersededOfferingContext';
import { useIndicationActivitySyndicateContext } from '../../../../context/IndicationActivitySyndicateContext';
import { useInstitutionalIndicationAcknowledgementsQuery } from '../../../indication-form/components/indication-details/components/acknowledgement/hooks/useInstitutionalIndicationAcknowledgementsQuery';
import IndicationSectionPassedReadContent from '../../common/indication-section-passed-read-content/IndicationSectionPassedReadContent';
import IndicationSectionReadContent from '../../common/indication-section-read-content/IndicationSectionReadContent';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import PassInstitutionalIndicationDialog from '../common/pass-institutional-indication-dialog/PassInstitutionalIndicationDialog';
import IndicationAcknowledgementBanner from '../indication-acknowledgement-banner/IndicationAcknowledgementBanner';
import CancelIndicationConfirmDialog from './cancel-indication-confirm-dialog/CancelIndicationConfirmDialog';
import { useUpdateIndicationStatus } from './hooks/useUpdateIndicationStatus';
import UnmarkDuplicateConfirmDialog from './unmark-duplicate-confirm-dialog/UnmarkDuplicateConfirmDialog';

export type Props = Readonly<{
  onStartEditing: () => void;
  hasAllocationRanking: boolean;
  isIndicationEditable: boolean;
  duplicateIndicationId?: string | null;
}>;

const IndicationSectionReadView: React.FC<Props> = ({
  onStartEditing,
  hasAllocationRanking,
  isIndicationEditable,
  duplicateIndicationId,
}) => {
  const { isOrderBookIndicationActivitySubscriptionOn } = useFeatureToggles();
  const {
    offeringId,
    basicPublishedOffering,
    access: { canUpdateIndication },
  } = useIndicationActivitySyndicateContext();
  const { isObsoleteOffering } = useSupersededOfferingContext();
  const offering = basicPublishedOffering!;
  const indication = offering.orderBook.institutionalIndication!;
  const { canManageDuplicate } = useCheckInstitutionalDuplicateAccess({ offeringId });
  const { canManage: canManagePassiveOrderBook } = useCheckPassiveOrderBookAccess({ offeringId });

  const {
    data: acknowledgementsData,
    loading: isLoadingAcknowledgements,
    error: acknowledgementsError,
    stopPolling,
    startPolling,
    called,
  } = useInstitutionalIndicationAcknowledgementsQuery({
    offeringId,
    indicationId: indication.id,
  });
  usePollingWhenVisible({
    pollInterval: 5_000,
    startPolling,
    stopPolling,
    called,
    isEnabled: !isOrderBookIndicationActivitySubscriptionOn,
  });

  const [onUpdateStatus, isUpdatingStatus] = useUpdateIndicationStatus(offeringId, indication.id);

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const [isUnmarkDuplicateDialogOpen, setIsUnmarkDuplicateDialogOpen] = React.useState(false);

  const baseButtonAccess = isIndicationEditable && canUpdateIndication;

  const isPassButtonVisible = indication.type !== InstitutionalIndicationOrderType.Pass;
  const isReactivateButtonVisible = indication.status === IndicationStatusType.Cancelled;
  const isEditAndCancelButtonVisible = indication.status !== IndicationStatusType.Cancelled;
  const isUnmarkDuplicateButtonVisible =
    canManageDuplicate &&
    indication.status === IndicationStatusType.Duplicate &&
    duplicateIndicationId;

  if (isLoadingAcknowledgements) {
    return (
      <ActionPanelSectionSkeleton>
        <ActionPanelSectionContentSkeleton />
      </ActionPanelSectionSkeleton>
    );
  }

  return (
    <ActionPanelSection
      data-testid="read-indication-view"
      title="Indication"
      titleItems={<IndicationStatus status={indication.status} />}
      actions={[
        ...(baseButtonAccess && isPassButtonVisible
          ? [
              <Button
                key="pass"
                variant="outlined"
                disabled={hasAllocationRanking || isUpdatingStatus}
                onClick={() => setIsPassDialogOpen(true)}
              >
                Pass
              </Button>,
            ]
          : []),
        ...(baseButtonAccess && isReactivateButtonVisible
          ? [
              <LoadingButton
                key="reactivate"
                variant="outlined"
                loading={isUpdatingStatus}
                onClick={() => onUpdateStatus(IndicationStatusType.Active)}
              >
                Re-activate
              </LoadingButton>,
            ]
          : []),
        ...(baseButtonAccess && isEditAndCancelButtonVisible
          ? [
              <Button
                key="cancel"
                variant="outlined"
                disabled={isUpdatingStatus}
                onClick={() => setIsCancelDialogOpen(true)}
              >
                Cancel
              </Button>,
              <Button
                key="edit"
                variant="contained"
                disabled={isObsoleteOffering || isUpdatingStatus}
                onClick={onStartEditing}
              >
                Edit
              </Button>,
            ]
          : []),
        ...(isUnmarkDuplicateButtonVisible
          ? [
              <Button
                key="unmark-duplicate"
                variant="outlined"
                onClick={() => setIsUnmarkDuplicateDialogOpen(true)}
              >
                Unmark Duplicate
              </Button>,
            ]
          : []),
      ]}
    >
      <Stack gap={1}>
        {acknowledgementsError && <ServerErrorsBanner error={acknowledgementsError} />}
        {!canManagePassiveOrderBook && !acknowledgementsError && isIndicationEditable && (
          <IndicationAcknowledgementBanner
            acknowledgements={acknowledgementsData?.institutionalIndicationAcknowledgements ?? []}
          />
        )}

        {indication.type === InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionPassedReadContent
            submittedOn={indication.auditInfo.createdAt}
            firmName={
              indication.investorInformation.bankInvestorName ??
              indication.investorInformation.cmgEntityName
            }
          />
        )}
        {indication.type !== InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionReadContent
            submittedOn={indication.auditInfo.createdAt}
            indicationTrancheId={indication.trancheId}
            indicationInstrumentId={indication.instrumentId}
            demandCurrencyCode={indication.currencyCode}
            pricingCurrencyCode={offering.pricingCurrencyCode}
            acknowledgements={acknowledgementsData?.institutionalIndicationAcknowledgements ?? []}
            indicationVersion={indication.version}
            bndAgentCmgEntityKey={indication.billingAndDeliveryAgentCmgEntityKey}
            interestLevels={indication.interestLevels}
            tranches={offering.orderEntryProfile.tranches}
            demandInstruments={offering.orderEntryProfile.instruments}
            managers={offering.syndicate.managers}
            securityType={offering.securityType}
          />
        )}

        <CancelIndicationConfirmDialog
          indicationId={indication.id}
          offeringId={offeringId}
          onClose={() => setIsCancelDialogOpen(false)}
          isOpen={isCancelDialogOpen}
        />
        <PassInstitutionalIndicationDialog
          isOpen={isPassDialogOpen}
          onClose={() => setIsPassDialogOpen(false)}
          offeringId={offeringId}
          indicationId={indication.id}
          indicationStatus={indication.status}
        />
        <UnmarkDuplicateConfirmDialog
          isOpen={isUnmarkDuplicateDialogOpen}
          onClose={() => setIsUnmarkDuplicateDialogOpen(false)}
          offeringId={offeringId}
          indicationId={indication.id}
          duplicateIndicationId={indication.duplicateOfIndicationId!}
        />
      </Stack>
    </ActionPanelSection>
  );
};

export default IndicationSectionReadView;
