import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { UUID } from '@cmg/common';
import {
  Box,
  Button,
  CachedIcon,
  CheckCircleOutline,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@cmg/design-system';
import React from 'react';

import ExportButton from '../../../components/export-button/ExportButton';
import { useExportContext } from '../../../components/export-button/ExportContext';
import { OrderBook_RetailBrokerDealerPartsFragment } from '../../graphql';
import InternalRetailDemand from '../common/internal-retail-demand/InternalRetailDemand';
import InternalRetailDemandTableHeader from '../common/table-header/InternalRetailDemandTableHeader';
import InternalRetailDemandTableTotalRow from '../common/table-total-row/InternalRetailDemandTableTotalRow';
import {
  OrderBook_CreateBrokerDealerMutationVariables,
  OrderBook_DeleteBrokerDealerMutationVariables,
  OrderBook_UpdateBrokerDealerMutationVariables,
  useOrderBook_CreateBrokerDealerMutation,
  useOrderBook_DeleteBrokerDealerMutation,
  useOrderBook_UpdateBrokerDealerMutation,
} from './graphql';
import { useAggregateFormsValues } from './hooks/useAggregateFormsValues';
import SubmitRetailDemandDialog from './submit-dialog/SubmitRetailDemandDialog';
import InternalRetailDemandTableRow from './table-row/InternalRetailDemandTableRow';

export type Props = Readonly<{
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[];
  sellingConcession: number | null | undefined;
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
}>;

const AutoSaveInternalRetailDemand: React.FC<Props> = ({
  brokers,
  sellingConcession,
  offeringId,
  instrumentId,
  trancheId,
}) => {
  const { values, totals, isAllValid, invalidFields, setValue, createValue, removeValue } =
    useAggregateFormsValues(brokers, sellingConcession);
  const allBrokers = React.useMemo(() => values.map(({ broker }) => broker), [values]);

  const [isNoDemandAlertVisible, setIsNoDemandAlertVisible] = React.useState(false);
  const [isValuesNotValidAlertVisible, setIsValuesNotValidAlertVisible] = React.useState(false);
  const [isSubmitRetailDialogVisible, setIsSubmitRetailDialogVisible] = React.useState(false);

  const focusedInputRef = React.useRef<HTMLElement | null>();

  const { isExporting, onExport } = useExportContext();

  const [createBrokerDealer, { loading: isCreatingDealer }] =
    useOrderBook_CreateBrokerDealerMutation();
  const [updateBrokerDealer, { loading: isUpdatingDealer }] =
    useOrderBook_UpdateBrokerDealerMutation();
  const [deleteBrokerDealer, { loading: isDeletingDealer }] =
    useOrderBook_DeleteBrokerDealerMutation();

  React.useEffect(() => {
    if (isNoDemandAlertVisible && values.length > 0) {
      setIsNoDemandAlertVisible(false);
    }
  }, [isNoDemandAlertVisible, values]);
  React.useEffect(() => {
    if (isValuesNotValidAlertVisible && isAllValid) {
      setIsValuesNotValidAlertVisible(false);
    }
  }, [isValuesNotValidAlertVisible, isAllValid]);

  const handleCreate = React.useCallback(
    async (variables: OrderBook_CreateBrokerDealerMutationVariables) => {
      const { data } = await createBrokerDealer({ variables });
      return data;
    },
    [createBrokerDealer]
  );
  const handleUpdate = React.useCallback(
    async (variables: OrderBook_UpdateBrokerDealerMutationVariables) => {
      await updateBrokerDealer({ variables });
    },
    [updateBrokerDealer]
  );
  const handleDelete = React.useCallback(
    async (variables: OrderBook_DeleteBrokerDealerMutationVariables) => {
      await deleteBrokerDealer({ variables });
    },
    [deleteBrokerDealer]
  );

  const handleOpenSubmitDialog = () => {
    if (values.length === 0) {
      setIsNoDemandAlertVisible(true);
      return;
    }
    if (!isAllValid) {
      setIsValuesNotValidAlertVisible(true);
      return;
    }

    setIsSubmitRetailDialogVisible(true);
  };
  const handleCloseSubmitDialog = () => {
    setIsSubmitRetailDialogVisible(false);
  };

  const isSavingData = isCreatingDealer || isUpdatingDealer || isDeletingDealer;

  return (
    <InternalRetailDemand
      pageHeaderActions={[
        <ExportButton key="export-button" loading={isExporting} onExport={onExport} />,

        <AccessControl
          key="submit-retail-demand-button"
          requiredPermissions={[
            permissionsByEntity.RetailDemand.FULL,
            permissionsByEntity.RetailRetention.FULL,
          ]}
          requireAllPermissions={false}
        >
          <Button variant="contained" color="primary" onClick={handleOpenSubmitDialog}>
            Submit Retail Demand
          </Button>
        </AccessControl>,
      ]}
      pageHeaderContent={
        <Stack direction="row" gap={1} alignItems="center">
          {isSavingData ? (
            <React.Fragment>
              <CachedIcon /> Saving
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CheckCircleOutline color="success" /> Saved
            </React.Fragment>
          )}
        </Stack>
      }
      isNoDemandAlertVisible={isNoDemandAlertVisible}
      isValuesNotValidAlertVisible={isValuesNotValidAlertVisible}
      invalidFields={invalidFields}
      isEmptyStateVisible={values.length === 0}
      onAddDemand={createValue}
    >
      <Box overflow="auto" data-testid="my-retail-auto-save">
        <TableContainer>
          <Table>
            <InternalRetailDemandTableHeader />

            <TableBody>
              <InternalRetailDemandTableTotalRow {...totals} />

              {values.map(
                (
                  { rowId, perShare, receivedByTheFirm, retainedByTheBank, ...formValues },
                  index
                ) => (
                  <InternalRetailDemandTableRow
                    key={rowId}
                    onRemove={() => removeValue(index)}
                    broker={formValues}
                    allBrokers={allBrokers}
                    pnlValues={{ perShare, receivedByTheFirm, retainedByTheBank }}
                    offeringId={offeringId}
                    onCreate={handleCreate}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onSetValue={(values, errors, reCalculatePnl) => {
                      setValue(index, values, errors, reCalculatePnl);
                    }}
                    onFocus={event => {
                      focusedInputRef.current = event.target;
                    }}
                    onBlur={() => {
                      focusedInputRef.current = null;
                    }}
                    onSaveFinished={() => focusedInputRef.current?.focus()}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <SubmitRetailDemandDialog
        open={isSubmitRetailDialogVisible}
        onClose={handleCloseSubmitDialog}
        totalRetailDemand={totals.demandShares}
        totalRoundLots={totals.demandRoundLots}
        offeringId={offeringId}
        instrumentId={instrumentId}
        trancheId={trancheId}
      />
    </InternalRetailDemand>
  );
};

export default AutoSaveInternalRetailDemand;
