import { Popover } from '@cmg/common';
import React from 'react';

import { CalendarGridRendererProps } from '../../../../calendar.model';
import { OfferingSidePanelLinkRenderer } from '../offering-side-panel-link/OfferingSidePanelLinkRenderer';
import { StyledIssuerName, StyledRevisionTag } from './IssuerNameRenderer.styles';

/**
 * IssuerNameRenderer cell renderer for the issuer name with styling overrides that are being applied
 * to the ag-react-container. Note: it has to be a class component for this to work as expected.
 * Revision indicator will display on the far right if the offering has a revisedFilingsCount > 0
 *
 * Consumers can override properties by setting new properties in the cellRenderParams on the column definition
 */
export class IssuerNameRenderer extends React.Component<CalendarGridRendererProps> {
  getReactContainerStyle() {
    return {
      width: '100%',
      height: '100%',
    };
  }
  render() {
    const { value, data, context, children } = this.props;
    return (
      <StyledIssuerName direction="row">
        <OfferingSidePanelLinkRenderer value={value} data={data} context={context} />
        <div>
          {!!data.revisedFilingsCount && data.revisedFilingsCount > 0 && (
            <Popover content="Revised" variant="DARK">
              <StyledRevisionTag>R</StyledRevisionTag>
            </Popover>
          )}
          {children}
        </div>
      </StyledIssuerName>
    );
  }
}
