import { useAuth } from '@cmg/auth';
import {
  BaseAgGrid,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Alert, AlertTitle, List, ListItem, Typography } from '@cmg/design-system';
import { AgGridEvent, GridApi, RowNode } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useTheme } from 'styled-components/macro';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../../../../common/components/overlays/modal/Modal';
import { useGridReady } from '../../../../../../../common/hooks/useGridReady';
import { nonEditableGridOptions } from '../../../../../../constants';
import { useInstitutionalDemandGridReferenceContext } from '../../context/InstitutionalDemandGridReferenceContext';
import { RelationshipNameCell } from '../../grid-columns/relationship-cell/RelationshipNameCell';
import { InstitutionalDemand } from '../../types';
import DupesCompanyNameCell from './components/DupesCompanyNameCell';
import DupesRadioCheck from './components/DupesRadioCheck';
import IndicationCell from './components/IndicationCell';
import { DupesGridContext, DupesGridRow, SyndicateManager } from './DupesModal.model';
import { SDupesGridAlert, SDupesGridContainer, SDupesGridHeader } from './DupesModal.styles';
import { useGetDupesContext } from './hooks/DupesModalHooks';
import { useSaveDupesMutation } from './hooks/useAssignDupesMutation';

export type Props = {
  onCancel: () => void;
  indicationIds: string[];
  indications: InstitutionalDemand[];
  managers: readonly SyndicateManager[];
  onSaveCompleted: () => void;
  pricingCurrencyCode?: string;
};

const DupesModal: React.FC<Props> = ({
  onCancel,
  indicationIds,
  indications,
  managers,
  onSaveCompleted,
  pricingCurrencyCode,
}) => {
  const theme = useTheme();
  const {
    params: { offeringId },
  } = useRouteMatch<{ offeringId: UUID }>();
  const { oidcUserCmgEntityKey } = useAuth();
  const gridRef = useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);

  const { onDuplicate } = useInstitutionalDemandGridReferenceContext();

  const context: DupesGridContext = useGetDupesContext(
    indications,
    managers,
    indicationIds,
    oidcUserCmgEntityKey,
    pricingCurrencyCode
  );
  const [saveDupes, { loading: saveDupesLoading, error: saveDupesError }] = useSaveDupesMutation();
  const onConfirmSaveDupes = async () => {
    const selectedRows = (gridRef.current?.api.getSelectedRows() as DupesGridRow[]) ?? [];
    const selectedIndication = selectedRows.length > 0 ? selectedRows[0] : null;
    if (!selectedIndication) {
      return;
    }
    const survivorId = selectedIndication.id;
    try {
      const dupes = indicationIds.filter(i => i !== survivorId);
      await saveDupes({
        variables: {
          offeringId,
          input: {
            survivorId: survivorId,
            markAsDuplicateIds: dupes,
          },
        },
      });

      onSaveCompleted();
      onDuplicate({ survivorId });
      ToastManager.success(
        `Duplicate Indication${dupes.length > 1 ? '(s)' : ''} Successfully Marked`
      );
    } catch (error) {
      ToastManager.error('Failed to Assign Duplicate Indications');
    }
  };

  const warningErrors = useCallback(() => {
    const errors: string[] = [];

    const interestLevelsNotEqual = context.rows.some(
      indication => !isEqual(context.rows[0].interestLevels, indication.interestLevels)
    );

    const { overlappingFirmNames } = context.rows.reduce(
      ({ set, overlappingFirmNames }, row) => {
        const updatedInfo = row.coveringManagerNames.reduce(
          (acc, manager) => {
            const { set, overlapping } = acc;
            if (set.has(manager.firmName)) {
              overlapping.add(manager.firmName);
            } else {
              set.add(manager.firmName);
            }
            return acc;
          },
          { set: set, overlapping: overlappingFirmNames }
        );
        return { set: updatedInfo.set, overlappingFirmNames: updatedInfo.overlapping };
      },
      { set: new Set(), overlappingFirmNames: new Set() }
    );

    if (overlappingFirmNames.size > 0) {
      const formattedFirmNames = [...overlappingFirmNames];
      const formattedFirmName =
        formattedFirmNames.length > 1
          ? `${formattedFirmNames.slice(0, -1).join(', ')} & ${formattedFirmNames.slice(-1)}`
          : formattedFirmNames.join('');
      errors.push(
        `You are attempting to mark multiple indications which are covered by ${formattedFirmName}. Contact ${formattedFirmName} to confirm.`
      );
    }

    interestLevelsNotEqual && errors.push(`Demand is different for the selected indications.`);
    return errors;
  }, [context]);

  const getRowHeight = (params: { node: RowNode; api: GridApi }) => {
    return params.node.data?.interestLevels?.length > 1
      ? (nonEditableGridOptions.rowHeight / 2) * params.node.data?.interestLevels?.length + 10
      : nonEditableGridOptions.rowHeight;
  };

  return (
    <Modal title="Designate Surviving Indication" show onHide={onCancel} size="xlarge">
      <Modal.Content>
        {warningErrors().length > 0 && (
          <SDupesGridAlert>
            <Alert severity="warning" sx={{ margin: 0, mt: -2 }}>
              <AlertTitle>Please review the selected indications</AlertTitle>
              {warningErrors().length === 1 ? (
                <Typography variant="body2" sx={{ padding: 0 }}>
                  {warningErrors()[0]}
                </Typography>
              ) : (
                <List sx={{ listStyleType: 'disc', padding: 0, pl: 2 }}>
                  {warningErrors().map((error, index) => (
                    <ListItem key={error + index} sx={{ display: 'list-item', padding: 0 }}>
                      <Typography variant="body2">{error}</Typography>
                    </ListItem>
                  ))}
                </List>
              )}
            </Alert>
          </SDupesGridAlert>
        )}
        <SDupesGridHeader>
          {saveDupesError && <ServerErrorsBanner error={saveDupesError} />}
          Please select the investor to designate as the surviving indication.
        </SDupesGridHeader>
        {saveDupesLoading && <Loading />}
        {!saveDupesLoading && (
          <SDupesGridContainer>
            <BaseAgGrid<DupesGridRow>
              context={context}
              rows={context.rows}
              loading={saveDupesLoading}
              onGridReady={onGridReady}
              onModelUpdated={({ columnApi }) => {
                setTimeout(() => {
                  columnApi.autoSizeColumns(['indication', 'selected']);
                }, 250); // wait for the grid to render. tried hooking into different ag grid events but none worked.
              }}
              rowStyle={{
                background: theme.background.color.white,
                borderBottom: theme.border.smallSolidLight,
              }}
              gridOptions={{
                getRowHeight,
              }}
            >
              <AgGridColumn
                colId="selected"
                headerName=""
                cellRendererFramework={DupesRadioCheck}
                cellStyle={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
                suppressMovable
                maxWidth={60}
                editable={false}
              />
              <AgGridColumn
                colId="id"
                headerName="Investor (CRM)"
                cellStyle={{
                  textOverflow: 'ellipsis',
                }}
                suppressMovable
                cellRendererFramework={DupesCompanyNameCell}
                editable={false}
              />
              <AgGridColumn
                colId="submittedByCmgEntityName"
                field="submittedByCmgEntityName"
                headerName="Submitted By"
                cellStyle={{
                  justifyContent: 'flex-start',
                }}
                suppressMovable
                editable={false}
              />
              <AgGridColumn
                colId="indication"
                headerName="Indication"
                cellStyle={{
                  textOverflow: 'ellipsis',
                }}
                cellRendererFramework={IndicationCell}
                suppressMovable
                editable={false}
              />
              <AgGridColumn
                colId="cmgEntityName"
                headerName="CMG Entity Name"
                cellStyle={{
                  justifyContent: 'flex-start',
                }}
                field="investorInformation.cmgEntityName"
                suppressMovable
                editable={false}
              />
              <AgGridColumn
                colId="RELATIONSHIP"
                field="relationship"
                headerName="Relationship"
                cellStyle={{
                  justifyContent: 'flex-start',
                  paddingTop: '5px',
                }}
                cellRendererFramework={({ data }) => (
                  <RelationshipNameCell managers={data.coveringManagerNames ?? []} />
                )}
                suppressMovable
                editable={false}
              />
            </BaseAgGrid>
          </SDupesGridContainer>
        )}
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right" margin={16}>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onConfirmSaveDupes} disabled={saveDupesLoading}>
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default DupesModal;
