import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';

type Props = {
  offeringId?: string;
};

type AccessCheckResult = {
  loading: boolean;
  canRead: boolean;
  canUpdate: boolean;
  isSellSideAccount: boolean;
};

const emptyArray = [];

/**
 * Checks accessibility of an Order Book.
 */
export function useCheckOrderBookAccess({ offeringId }: Props): AccessCheckResult {
  const hasRead = useCheckPermissions(
    [
      permissionsByEntity.OrderBook.READ,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
    ],
    false
  );
  const hasFull = useCheckPermissions(
    [
      permissionsByEntity.OrderBook.FULL,
      permissionsByEntity.CoveredAccountsInstitutionalIndication.FULL,
    ],
    false
  );

  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? emptyArray;

  const isSellSideAccount = useCheckAccountType('SELL_SIDE');

  const accessChecks = React.useMemo(
    () => ({
      canRead: hasRead && (!isSellSideAccount || entitlements.includes('READ:ORDER_BOOK')),
      canUpdate: hasFull && (!isSellSideAccount || entitlements.includes('UPDATE:ORDER_BOOK')),
    }),
    [hasRead, hasFull, entitlements, isSellSideAccount]
  );

  return { loading, ...accessChecks, isSellSideAccount };
}

export default useCheckOrderBookAccess;
