import { ISODate, Option } from '@cmg/common';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import orderBy from 'lodash/orderBy';
import * as yup from 'yup';

import { MeetingFormat, MeetingInput, MeetingType } from '../../../../../../../graphql';
import {
  Roadshows_MeetingDetailsPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';
import { Roadshows_RoadshowDealTeamsQuery } from '../../../../roadshow-configuration/deal-team-details/graphql';

export type MeetingVisibility = 'PUBLIC' | 'PRIVATE';

export type MeetingValues = {
  date: ISODate;
  startTime: string[];
  endTime: string[];
  timeZone: string;
  meetingType: MeetingType;
  meetingFormat: MeetingFormat;
  title: string;
  location: string | null;
  venue: string;
  address: string;
  roomName: string;
  publicNotes: string;
  dialInDetails: string;
  hostFirmCmgEntityKey: string | null;
  hostPersonFullName: string | null;
  hostPersonEmail: string | null;
};

/**
 * Filters locations associated with given date and converts them into options array.
 */
export const getLocationOptions = (
  date: ISODate | null,
  roadshowDays: Roadshows_RoadshowPartsFragment['roadshowDays']
): Option[] => {
  const roadshowDay = roadshowDays.find(day => day.date === date);

  if (!roadshowDay) {
    return [];
  }

  const options = roadshowDay.cities.map(({ name, timeZone }) => ({
    value: name,
    label: name,
    timeZone,
  }));

  return orderBy(options ?? [], ['label'], ['asc']);
};

export const meetingFormatOptions: Option<MeetingFormat>[] = [
  { label: 'Physical', value: MeetingFormat.InPerson },
  { label: 'Hybrid', value: MeetingFormat.Hybrid },
  { label: 'Virtual', value: MeetingFormat.Virtual },
];

export const meetingTypeOptions: Option<MeetingType>[] = [
  { label: '1:1', value: MeetingType.OneOnOne },
  { label: '2:1', value: MeetingType.TwoOnOne },
  { label: '3:1', value: MeetingType.ThreeOnOne },
  { label: '4:1', value: MeetingType.FourOnOne },
  { label: 'Small Group', value: MeetingType.SmallGroup },
  { label: 'Large Group', value: MeetingType.LargeGroup },
];

export const meetingTypeValues = meetingTypeOptions.map(({ value }) => value);
export const meetingFormatValues = meetingFormatOptions.map(({ value }) => value);

const validateStartTimeValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value,
  context
) => {
  const endDate = parse(context.parent.endTime.join(' '), 'hh:mm a', new Date());
  const startDate = parse(value!.join(' '), 'hh:mm a', new Date());
  return isBefore(startDate, endDate);
};

const validateEndTimeValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value,
  context
) => {
  const startDate = parse(context.parent.startTime.join(' '), 'hh:mm a', new Date());
  const endDate = parse(value!.join(' '), 'hh:mm a', new Date());
  return isAfter(endDate, startDate);
};

const validateTimePickerValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value = []
) => {
  const [time, meridiemPeriod] = value;
  const result = time && meridiemPeriod && time.length && ['AM', 'PM'].includes(meridiemPeriod);
  return !!result;
};

export const getValidationSchema = (
  roadshowDays: Roadshows_RoadshowPartsFragment['roadshowDays']
) => {
  return yup.object().shape({
    title: yup.string().label('Title').nullable().required(),
    date: yup.date().label('Date').typeError('Invalid date format').required(),
    startTime: yup
      .array()
      .test('has-time', 'Start time is required', validateTimePickerValue)
      .test('is-before-end-time', 'Start time must be before end time', validateStartTimeValue),
    endTime: yup
      .array()
      .test('has-time', 'End time is required', validateTimePickerValue)
      .test('is-after-start-time', 'End time must be after start time', validateEndTimeValue),
    timeZone: yup.string().label('Timezone').nullable().required(),
    meetingType: yup.string().label('Meeting Type').oneOf(meetingTypeValues).nullable().required(),
    meetingFormat: yup
      .string()
      .label('Meeting Format')
      .oneOf(meetingFormatValues)
      .nullable()
      .required(),
    roomName: yup.string(),
    publicNotes: yup.string(),
    venue: yup.string(),
    dialInDetails: yup.string().when('meetingFormat', {
      is: value => value === MeetingFormat.Virtual || value === MeetingFormat.Hybrid,
      then: schema => schema.label('Virtual Meeting Details').nullable().required(),
      otherwise: schema => schema.nullable(),
    }),
    location: yup.string().when(['meetingFormat', 'date'], {
      is: (meetingFormat, date) =>
        [MeetingFormat.InPerson, MeetingFormat.Hybrid].includes(meetingFormat) ||
        (meetingFormat === MeetingFormat.Virtual && isRoadshowDayVirtual(roadshowDays, date)),
      then: schema => schema.label('Location').nullable().required(),
      otherwise: schema => schema.nullable(),
    }),
    address: yup.string().label('Address').nullable(),
    hostFirmCmgEntityKey: yup.string().label('Underwriter').nullable(),
    hostPersonFullName: yup.string().label("Member's Name").nullable(),
    hostPersonEmail: yup.string().email().label('Email').nullable(),
  });
};

/**
 * Converts hh:mm a time string into HH:mm:ss format.
 */
export const getISOTime = (timeString: string) => {
  const date = parse(timeString, 'hh:mm a', new Date());

  return isValid(date) ? format(date, 'HH:mm:ss') : null;
};

export const parseISOTime = (timeString: string) => {
  const date = parse(timeString, 'HH:mm:ss', new Date());

  return format(date, 'hh:mm a').split(' ');
};

export const getMeetingPayload = (values: MeetingValues): MeetingInput => {
  const commonValues = {
    date: values.date,
    startTime: getISOTime(values.startTime.join(' ')) ?? '',
    endTime: getISOTime(values.endTime.join(' ')) ?? '',
    timezone: values.timeZone,
    type: values.meetingType,
    publicNotes: values.publicNotes || null,
    title: values.title,
    hostPersonEmail: values.hostPersonEmail || null,
    hostPersonFullName: values.hostPersonFullName || null,
    hostFirmCmgEntityKey: values.hostFirmCmgEntityKey || null,
    location: values.location,
  };

  if (values.meetingFormat === MeetingFormat.Virtual) {
    return {
      ...commonValues,
      format: MeetingFormat.Virtual,
      isPublic: false,
      address: null,
      venue: null,
      roomName: null,
      dialInDetails: values.dialInDetails || null,
    };
  }

  if (values.meetingFormat === MeetingFormat.Hybrid) {
    return {
      ...commonValues,
      format: MeetingFormat.Hybrid,
      isPublic: true,
      address: values.address,
      venue: values.venue || null,
      roomName: values.roomName || null,
      dialInDetails: values.dialInDetails || null,
    };
  }

  return {
    ...commonValues,
    format: MeetingFormat.InPerson,
    isPublic: true,
    address: values.address,
    venue: values.venue || null,
    roomName: values.roomName || null,
    dialInDetails: null,
  };
};

export const getMeetingFormValues = ({
  meeting,
  selectedDate,
  roadshowDays,
}: {
  meeting?: Roadshows_MeetingDetailsPartsFragment;
  selectedDate?: string;
  roadshowDays?: Roadshows_RoadshowPartsFragment['roadshowDays'];
  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): MeetingValues => {
  const values: MeetingValues = {
    date: meeting?.date ?? '',
    startTime: meeting?.startTime ? parseISOTime(meeting.startTime) : [],
    endTime: meeting?.endTime ? parseISOTime(meeting.endTime) : [],
    timeZone: meeting?.timezone ?? '',
    meetingType: meeting?.type ?? MeetingType.OneOnOne,
    meetingFormat: meeting?.format ?? MeetingFormat.InPerson,
    title: meeting?.title ?? '',
    location: meeting?.location ?? null,
    venue: meeting?.venue ?? '',
    address: meeting?.address ?? '',
    roomName: meeting?.roomName ?? '',
    publicNotes: meeting?.publicNotes ?? '',
    dialInDetails: meeting?.dialInDetails ?? '',
    hostPersonFullName: meeting?.hostPersonFullName ?? null,
    hostPersonEmail: meeting?.hostPersonEmail ?? null,
    hostFirmCmgEntityKey: meeting?.hostFirmCmgEntityKey ?? null,
  };

  // new meeting with selected date
  if (!(!meeting && selectedDate)) {
    return values;
  }

  const roadshowDay = roadshowDays?.find(day => day.date === selectedDate);

  if (roadshowDay?.isVirtual) {
    return {
      ...values,
      date: selectedDate,
      meetingFormat: MeetingFormat.Virtual,
      timeZone: roadshowDay.timeZone!,
      location: null,
    };
  }

  const dateLocation = roadshowDay?.cities;
  if (dateLocation && dateLocation.length > 0) {
    const [firstLocation] = dateLocation;

    return {
      ...values,
      date: selectedDate,
      meetingFormat: MeetingFormat.InPerson,
      location: firstLocation.name,
      timeZone: firstLocation.timeZone,
    };
  }

  return {
    ...values,
    date: selectedDate,
    meetingFormat: MeetingFormat.InPerson,
    location: null,
    timeZone: '',
  };
};

export const isRoadshowDayVirtual = (
  roadshowDays: Roadshows_RoadshowPartsFragment['roadshowDays'],
  date: ISODate
) => {
  return !!roadshowDays.find(day => day.date === date)?.isVirtual;
};

export const getUnderwriterOptions = (dealTeamsData?: Roadshows_RoadshowDealTeamsQuery) => {
  return dealTeamsData?.roadshowDealTeams.map(dealTeam => ({
    label: dealTeam.firmName,
    value: dealTeam.firmCmgEntityKey,
  }));
};
