/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_CreateOrUpdateStabilizationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateOrUpdateStabilizationInput;
}>;

export type FinalSettlement_CreateOrUpdateStabilizationMutation = {
  readonly __typename?: 'Mutation';
  readonly createOrUpdateStabilization: {
    readonly __typename?: 'Stabilization';
    readonly id: string;
    readonly notes: string;
    readonly trades: ReadonlyArray<{
      readonly __typename?: 'StabilizationTrade';
      readonly shares: number;
      readonly date: string;
      readonly averagePrice?: number | null;
      readonly activityType?: Types.TradeActivityType | null;
      readonly type: Types.TradeType;
    }>;
  };
};

export const FinalSettlement_CreateOrUpdateStabilizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_CreateOrUpdateStabilization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrUpdateStabilizationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateStabilization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stabilization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StabilizationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_CreateOrUpdateStabilizationMutationFn = Apollo.MutationFunction<
  FinalSettlement_CreateOrUpdateStabilizationMutation,
  FinalSettlement_CreateOrUpdateStabilizationMutationVariables
>;

/**
 * __useFinalSettlement_CreateOrUpdateStabilizationMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_CreateOrUpdateStabilizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_CreateOrUpdateStabilizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementCreateOrUpdateStabilizationMutation, { data, loading, error }] = useFinalSettlement_CreateOrUpdateStabilizationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useFinalSettlement_CreateOrUpdateStabilizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_CreateOrUpdateStabilizationMutation,
    FinalSettlement_CreateOrUpdateStabilizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_CreateOrUpdateStabilizationMutation,
    FinalSettlement_CreateOrUpdateStabilizationMutationVariables
  >(FinalSettlement_CreateOrUpdateStabilizationDocument, options);
}
export type FinalSettlement_CreateOrUpdateStabilizationMutationHookResult = ReturnType<
  typeof useFinalSettlement_CreateOrUpdateStabilizationMutation
>;
export type FinalSettlement_CreateOrUpdateStabilizationMutationResult =
  Apollo.MutationResult<FinalSettlement_CreateOrUpdateStabilizationMutation>;
export type FinalSettlement_CreateOrUpdateStabilizationMutationOptions = Apollo.BaseMutationOptions<
  FinalSettlement_CreateOrUpdateStabilizationMutation,
  FinalSettlement_CreateOrUpdateStabilizationMutationVariables
>;
