import React from 'react';

import { SHeaderContainer } from './CalendarColumnHeader.styles';

type CalendarColumnHeaderProps = { title: string; popoverText?: string };
const CalendarColumnHeader = ({
  title,
  popoverText = 'This information will soon be available',
}: CalendarColumnHeaderProps) => {
  return (
    <SHeaderContainer className="ag-cell-label-container">
      <span className="ag-header-cell-text">{title}</span>
    </SHeaderContainer>
  );
};

export default CalendarColumnHeader;
