import { getOperationName } from '@apollo/client/utilities';
import type { UUID } from '@cmg/common';
import { AddIcon, Box, Button, List, Typography } from '@cmg/design-system';

import { useCheckDraftAllocationAccess } from '../../../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { useDialogState } from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import CopyToDefaultSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/copy-to-draft-set-dialog/CopyToDefaultSetDialog';
import DeleteDraftAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/delete-allocation-draft-set-dialog/DeleteDraftAllocationSetDialog';
import PromoteToFinalSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/promote-to-final-set-dialog/PromoteToFinalSetDialog';
import CreateDraftAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/save-allocation-set-dialog/create-draft-allocation-set-dialog/CreateDraftAllocationSetDialog';
import EditDraftAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/save-allocation-set-dialog/edit-draft-allocation-set-dialog/EditDraftAllocationSetDialog';
import EditFinalAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/save-allocation-set-dialog/edit-final-allocation-set-dialog/EditFinalAllocationSetDialog';
import ShareDraftAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/share-allocation-set-dialog/share-draft-allocation-set-dialog/ShareDraftAllocationSetDialog';
import ShareFinalAllocationSetDialog from '../../../../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/share-allocation-set-dialog/share-final-allocation-set-dialog/ShareFinalAllocationSetDialog';
import {
  OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  OrderBook_InstitutionalDemandRouteDocument,
} from '../../../../graphql/__generated__';
import DraftAllocationSetListItem from '../list-item/DraftAllocationSetListItem';
import FinalAllocationSetListItem from '../list-item/FinalAllocationSetListItem';

export type Props = Readonly<{
  offeringId: UUID;
  finalAllocation?: OrderBook_InstitutionalDemand_AllocationSetPartsFragment;
  draftSets: {
    defaultSection: OrderBook_InstitutionalDemand_AllocationSetPartsFragment[];
    draftSection: OrderBook_InstitutionalDemand_AllocationSetPartsFragment[];
  };
}>;

const AllocationSetsPage: React.FC<Props> = ({ finalAllocation, offeringId, draftSets }) => {
  const {
    isOpen: isCreateDialogOpen,
    onOpen: onOpenCreateDialog,
    onClose: onCloseCreateDialog,
  } = useDialogState({});
  const {
    isOpen: isEditFinalSetDialogOpen,
    onOpen: onOpenEditFinalSetDialog,
    onClose: onCloseEditFinalSetDialog,
  } = useDialogState({});
  const {
    isOpen: isShareFinalSetDialogOpen,
    onOpen: onOpenShareFinalSetDialog,
    onClose: onCloseShareFinalSetDialog,
  } = useDialogState({});
  const {
    isOpen: isShareDraftSetDialogOpen,
    context: sharedDraftSet,
    onOpen: onOpenShareDraftSetDialog,
    onClose: onCloseShareDraftSetDialog,
  } = useDialogState<OrderBook_InstitutionalDemand_AllocationSetPartsFragment>({});
  const {
    isOpen: isEditDraftSetDialogOpen,
    context: editedDraftSet,
    onOpen: onOpenEditDraftSetDialog,
    onClose: onCloseEditDraftSetDialog,
  } = useDialogState<OrderBook_InstitutionalDemand_AllocationSetPartsFragment>({});
  const {
    isOpen: isDeleteDraftSetDialogOpen,
    context: deletedDraftSet,
    onOpen: onOpenDeleteDraftSetDialog,
    onClose: onCloseDeleteDraftSetDialog,
  } = useDialogState<OrderBook_InstitutionalDemand_AllocationSetPartsFragment>({});
  const {
    isOpen: isPromoteToFinalSetDialogOpen,
    context: promotedDraftSet,
    onOpen: onOpenPromoteToFinalSetDialog,
    onClose: onClosePromoteToFinalSetDialog,
  } = useDialogState<OrderBook_InstitutionalDemand_AllocationSetPartsFragment>({});
  const {
    isOpen: isCopyToDefaultSetDialogOpen,
    context: copyToDefaultDraftSet,
    onOpen: onOpenCopyToDefaultSetDialog,
    onClose: onCloseCopyToDefaultSetDialog,
  } = useDialogState<OrderBook_InstitutionalDemand_AllocationSetPartsFragment>({});

  const { canRead: canViewDraftAllocations } = useCheckDraftAllocationAccess({ offeringId });
  const { canRead: canReadFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });

  return (
    <Box>
      {canReadFinalAllocations && finalAllocation && (
        <List subheader={<Typography variant="subtitle1">Final Allocation</Typography>}>
          <FinalAllocationSetListItem
            allocationSet={finalAllocation}
            offeringId={offeringId}
            onEditFinalAllocationSet={onOpenEditFinalSetDialog}
            onShareFinalAllocationSet={onOpenShareFinalSetDialog}
          />
        </List>
      )}
      {canViewDraftAllocations && draftSets.defaultSection.length > 0 && (
        <List subheader={<Typography variant="subtitle1">Default Allocation</Typography>}>
          {draftSets.defaultSection.map(allocationSet => (
            <DraftAllocationSetListItem
              key={allocationSet.id}
              allocationSet={allocationSet}
              offeringId={offeringId}
              onEditDraftAllocationSet={() => onOpenEditDraftSetDialog(allocationSet)}
              onDeleteDraftAllocationSet={() => onOpenDeleteDraftSetDialog(allocationSet)}
              onShareDraftAllocationSet={() => onOpenShareDraftSetDialog(allocationSet)}
              onPromoteDraftAllocationSetToFinal={() =>
                onOpenPromoteToFinalSetDialog(allocationSet)
              }
              onCopyDraftAllocationSetToDefault={() => onOpenCopyToDefaultSetDialog(allocationSet)}
            />
          ))}
        </List>
      )}

      <List
        subheader={
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="subtitle1">Draft Allocations</Typography>
            <Button onClick={onOpenCreateDialog} startIcon={<AddIcon />}>
              Add New Draft
            </Button>
          </Box>
        }
      >
        {draftSets.draftSection.length > 0 &&
          draftSets.draftSection.map(allocationSet => (
            <DraftAllocationSetListItem
              key={allocationSet.id}
              allocationSet={allocationSet}
              offeringId={offeringId}
              onEditDraftAllocationSet={() => onOpenEditDraftSetDialog(allocationSet)}
              onDeleteDraftAllocationSet={() => onOpenDeleteDraftSetDialog(allocationSet)}
              onShareDraftAllocationSet={() => onOpenShareDraftSetDialog(allocationSet)}
              onPromoteDraftAllocationSetToFinal={() =>
                onOpenPromoteToFinalSetDialog(allocationSet)
              }
              onCopyDraftAllocationSetToDefault={() => onOpenCopyToDefaultSetDialog(allocationSet)}
            />
          ))}
      </List>

      <CreateDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isCreateDialogOpen}
        onClose={onCloseCreateDialog}
        refetchQueries={[getOperationName(OrderBook_InstitutionalDemandRouteDocument)!]}
      />
      <EditDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isEditDraftSetDialogOpen}
        onClose={onCloseEditDraftSetDialog}
        allocationSet={editedDraftSet}
      />
      <DeleteDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isDeleteDraftSetDialogOpen}
        onClose={onCloseDeleteDraftSetDialog}
        allocationSet={deletedDraftSet}
      />
      <EditFinalAllocationSetDialog
        offeringId={offeringId}
        isOpen={isEditFinalSetDialogOpen}
        onClose={onCloseEditFinalSetDialog}
        finalAllocationSet={finalAllocation!}
      />
      <ShareFinalAllocationSetDialog
        isOpen={isShareFinalSetDialogOpen}
        onClose={onCloseShareFinalSetDialog}
        sharedFinalAllocationSet={finalAllocation!}
        offeringId={offeringId}
      />
      <ShareDraftAllocationSetDialog
        isOpen={isShareDraftSetDialogOpen}
        onClose={onCloseShareDraftSetDialog}
        sharedDraftAllocationSet={sharedDraftSet}
        offeringId={offeringId}
      />
      <PromoteToFinalSetDialog
        isOpen={isPromoteToFinalSetDialogOpen}
        onClose={onClosePromoteToFinalSetDialog}
        promotedDraftAllocationSet={promotedDraftSet}
        offeringId={offeringId}
      />
      <CopyToDefaultSetDialog
        isOpen={isCopyToDefaultSetDialogOpen}
        onClose={onCloseCopyToDefaultSetDialog}
        copiedDraftAllocationSet={copyToDefaultDraftSet}
        offeringId={offeringId}
      />
    </Box>
  );
};

export default AllocationSetsPage;
