import { xcSelectors } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import { FormikProvider, useFormik } from 'formik';
import includes from 'lodash/includes';
import React, { MutableRefObject, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { getFeatureToggles } from '../../../../../../../common/config/appSettings';
import { useInstitutionalDemandPreferencesContext } from '../../context/InstitutionalDemandPreferencesContext';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../../graphql';
import { extendedTypesDisplayName, InstitutionalDemandColumnConfig } from '../../types';
import { openInstitutionalDemandMenuModal } from '../demand-grid-menu-modal/InstitutionalDemandMenuModal';
import { MenuSeparatorOption } from '../demand-grid-menu-modal/InstitutionalDemandMenuModal.styles';
import {
  isReadOnly,
  useGetMenuOptions,
  validationSchema,
} from './InstitutionalDemandGridColumnMenu.model';
import {
  SCMGDemandColumnTitle,
  SDemandColumnFieldsWrapper,
  SDemandSeparatorTitle,
  SMenuButtonsContainer,
  SMenuContainer,
  SMenuTitle,
  StyledCancelButton,
  StyledNumericInputField,
  StyledReferencePriceInput,
} from './InstitutionalDemandGridColumnMenu.styles';

export type Props = {
  activeDemandColumnConfig: InstitutionalDemandColumnConfig;
  onDemandColumnConfigChange: (values: InstitutionalDemandColumnConfig) => void;
  isFinalAllocationSetReleased: boolean;
  finalAllocationExists: boolean;
  offeringId: string;
  onVisibleChange: MutableRefObject<Function>;
  close: () => void;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  pricingCurrencyCode?: string | null;
};

/**
 * Renders the menu for the Institutional Demand Grid Column Dropdown
 */
export const InstitutionalDemandGridColumnMenuComponentNewFlow: React.FC<Props> = ({
  onDemandColumnConfigChange,
  activeDemandColumnConfig,
  isFinalAllocationSetReleased,
  finalAllocationExists,
  offeringId,
  onVisibleChange,
  close,
  investorsExtendedData,
  pricingCurrencyCode,
}) => {
  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();

  const { pinnedColumns, setPinnedColumns, visibleColumns, setVisibleColumns } =
    useInstitutionalDemandPreferencesContext();
  const [initialColumns, setInitialColumns] = useState<string[]>(visibleColumns);

  React.useEffect(() => {
    onVisibleChange.current = () => setInitialColumns(visibleColumns);
  }, [onVisibleChange, visibleColumns]);

  const formik = useFormik<InstitutionalDemandColumnConfig>({
    initialValues: activeDemandColumnConfig,
    validationSchema,
    onSubmit: onDemandColumnConfigChange,
  });
  const { handleSubmit } = formik;

  const { cmg, demands, compliance, finalAllocations, notes, dynamicColumns } = useGetMenuOptions({
    isFinalAllocationSetReleased,
    finalAllocationExists,
    visibleColumns,
    setVisibleColumns,
    offeringId,
    investorsExtendedData,
    columnFilter: (c: ColDef) => includes(initialColumns, c.colId!) || isReadOnly(c),
    pinnedColumns,
    setPinnedColumns,
    displayPinIcon: isOrderBookPinnedColumnsOn,
  });
  const dynamicColumnsCategories = Object.keys(dynamicColumns);

  const debouncedHandleSubmit = useDebouncedCallback(() => {
    handleSubmit();
  }, 700);

  const dispatch = useDispatch();
  return (
    <FormikProvider value={formik}>
      <SMenuContainer data-test-id={xcSelectors.institutionalDemandGridColumnsDropdownMenu.testId}>
        <SMenuTitle>Selected Columns</SMenuTitle>
        <SCMGDemandColumnTitle>Demand Columns</SCMGDemandColumnTitle>
        <SDemandColumnFieldsWrapper>
          <StyledNumericInputField
            name="min"
            label="Low"
            onChange={() => debouncedHandleSubmit()}
          />
          <StyledNumericInputField
            name="max"
            label="High"
            onChange={() => debouncedHandleSubmit()}
          />
          <StyledNumericInputField
            name="increment"
            label="Increment"
            onChange={() => debouncedHandleSubmit()}
          />
        </SDemandColumnFieldsWrapper>
        <StyledReferencePriceInput
          label="Reference Price"
          name="referencePrice"
          precision={2}
          currencyCode={pricingCurrencyCode ?? undefined}
          onChange={() => debouncedHandleSubmit()}
        ></StyledReferencePriceInput>
        {demands}
        {cmg.length > 0 && (
          <React.Fragment>
            <SDemandSeparatorTitle>Default Columns</SDemandSeparatorTitle>
            {cmg}
          </React.Fragment>
        )}
        {finalAllocations.length > 0 && <React.Fragment>{finalAllocations}</React.Fragment>}
        {compliance.length > 0 && (
          <React.Fragment>
            <SDemandSeparatorTitle>Compliance</SDemandSeparatorTitle>
            {compliance}
          </React.Fragment>
        )}
        {notes.length > 0 && (
          <React.Fragment>
            <SDemandSeparatorTitle>Other</SDemandSeparatorTitle>
            {notes}
          </React.Fragment>
        )}
        {dynamicColumnsCategories.length > 0 && (
          <React.Fragment>
            <SDemandSeparatorTitle>Additional Columns</SDemandSeparatorTitle>
            {dynamicColumnsCategories.map(category => (
              <React.Fragment key={category}>
                <MenuSeparatorOption>{extendedTypesDisplayName[category]}</MenuSeparatorOption>
                {dynamicColumns[category]}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </SMenuContainer>
      <SMenuButtonsContainer>
        <StyledCancelButton
          data-test-id="manage-columns-button"
          size="medium"
          onClick={() => {
            dispatch(openInstitutionalDemandMenuModal());
            close();
          }}
        >
          Manage Columns
        </StyledCancelButton>
      </SMenuButtonsContainer>
    </FormikProvider>
  );
};
