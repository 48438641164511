import { ApolloError } from '@apollo/client';
import { UUID } from '@cmg/common';
import React from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import usePollingWhenVisible from '../../../../../common/hooks/usePollingWhenVisible';
import { useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess } from '../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { isValidCmgEntityKey } from '../../../../../common/util/isValidCmgEntityKey';
import { canBillingAndDeliveryAgentUpdateInstitutionalIndication } from '../../../../../common/util/offering/syndicate-entitlements';
import { OrderBookState } from '../../../../../graphql/shared-types';
import { useSalesAndTradingCertificatesModel } from '../../compliance/context/hooks/certificates/sale-and-trading/useCertificatesSalesAndTradingModel';
import {
  Attestation,
  certificateModelDefaults,
  ComplianceWarning,
} from '../../compliance/context/hooks/certificates/useCertificatesModel';
import { OrderBookPersonaType } from '../../hooks/useIdentifyOrderBookPersona';
import { CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery } from '../components/crm-selection/graphql/__generated__';
import { useInvestorFirmModel } from './hooks/investorFirm/useInvestorFirmModel';
import {
  SalesAndTradingIndicationQuery,
  useSalesAndTradingIndicationQuery,
} from './hooks/sales-and-trading/__generated__';
import { useIndicationActivityOrderBookSalesStateForSalesTraderQuery } from './hooks/sales-and-trading/useIndicationActivityOrderBookSalesStateForSalesTraderQuery';

type AccessChecks = {
  canReadOfferingAndIndication: boolean;
  canUpdateCoveredIndication: boolean;
  canBillingAndDeliveryAgentUpdateAllocationAcknowledgment: boolean;
};

export type IndicationActivitySalesAndTradingContextType = {
  offeringId: UUID;
  loading: boolean;
  error?: ApolloError;
  basicPublishedOffering?: SalesAndTradingIndicationQuery['basicPublishedOffering'];
  coveredIndication?: SalesAndTradingIndicationQuery['coveredIndication'];
  complianceWarning: ComplianceWarning;
  attestation: Attestation | null;
  allInvestorFirmsMappedToCmgEntityKey?: CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery['allInvestorFirmsMappedToCmgEntityKey'];
  access: AccessChecks;
  orderBookSalesState?: OrderBookState;
};

export const IndicationActivitySalesAndTradingContext =
  React.createContext<IndicationActivitySalesAndTradingContextType>({
    offeringId: '',
    loading: false,
    complianceWarning: certificateModelDefaults.warning,
    access: {
      canReadOfferingAndIndication: false,
      canUpdateCoveredIndication: false,
      canBillingAndDeliveryAgentUpdateAllocationAcknowledgment: false,
    },
    attestation: null,
  });

export const useIndicationActivitySalesAndTradingContext = () => {
  const context = React.useContext(IndicationActivitySalesAndTradingContext);

  if (!context) {
    throw new Error('No provider found for IndicationActivitySalesAndTradingContext');
  }

  return context;
};

export type IndicationActivitySalesAndTradingContextProps = {
  offeringId: UUID;
  cmgEntityKey: UUID | undefined;
  indicationId: UUID;
};

export const IndicationActivitySalesAndTradingContextProvider: React.FC<
  IndicationActivitySalesAndTradingContextProps
> = ({ children, offeringId, cmgEntityKey, indicationId }) => {
  const { isOrderBookAttestationOn } = useFeatureToggles();

  const {
    canRead: canReadOfferingAndIndication,
    canUpdate: canUpdateCoveredIndication,
    loading: indicationAccessLoading,
  } = useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });
  const {
    loading: isLoadingOffering,
    data,
    error,
    startPolling,
    stopPolling,
    called,
  } = useSalesAndTradingIndicationQuery({
    variables: {
      offeringId,
      cmgEntityKey: cmgEntityKey!,
      indicationId,
      skipIndicationPart: !isValidCmgEntityKey(cmgEntityKey),
    },
    skip: !cmgEntityKey || !offeringId || !canReadOfferingAndIndication,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: obSalesStateChangedData,
    loading: obSalesStateChangedLoading,
    error: obSalesStateChangedError,
  } = useIndicationActivityOrderBookSalesStateForSalesTraderQuery(
    {
      offeringId,
    },
    cmgEntityKey,
    canReadOfferingAndIndication
  );

  usePollingWhenVisible({ pollInterval: 5_000, startPolling, stopPolling, called });

  const {
    loading: isLoadingCertificates,
    warning: complianceWarning,
    attestation,
  } = useSalesAndTradingCertificatesModel({
    type: OrderBookPersonaType.SALES_AND_TRADING,
    offeringId,
    cmgEntityKey,
    isOrderBookAttestationOn,
  });
  const { loading: isLoadingInvestorFirm, allInvestorFirmsMappedToCmgEntityKey } =
    useInvestorFirmModel({
      type: OrderBookPersonaType.SALES_AND_TRADING,
      cmgEntityKey,
    });

  const context = React.useMemo<IndicationActivitySalesAndTradingContextType>(
    () => ({
      offeringId,
      loading:
        isLoadingOffering ||
        isLoadingCertificates ||
        isLoadingInvestorFirm ||
        indicationAccessLoading ||
        obSalesStateChangedLoading,
      error: error || obSalesStateChangedError,
      basicPublishedOffering: data?.basicPublishedOffering,
      coveredIndication: data?.coveredIndication,
      complianceWarning,
      attestation,
      allInvestorFirmsMappedToCmgEntityKey,
      access: {
        canReadOfferingAndIndication,
        canUpdateCoveredIndication,
        canBillingAndDeliveryAgentUpdateAllocationAcknowledgment:
          canBillingAndDeliveryAgentUpdateInstitutionalIndication({
            billingAndDeliveryAgentCmgEntityKey:
              data?.coveredIndication?.billingAndDeliveryAgentCmgEntityKey,
            syndicatesEntitlements:
              data?.basicPublishedOffering?.syndicateEntitlements?.syndicateEntitlements ?? [],
          }),
      },
      orderBookSalesState: obSalesStateChangedData?.salesAndTradingOrderBook?.salesState,
    }),
    [
      offeringId,
      isLoadingOffering,
      isLoadingCertificates,
      isLoadingInvestorFirm,
      indicationAccessLoading,
      obSalesStateChangedLoading,
      error,
      obSalesStateChangedError,
      data?.basicPublishedOffering,
      data?.coveredIndication,
      complianceWarning,
      attestation,
      allInvestorFirmsMappedToCmgEntityKey,
      canReadOfferingAndIndication,
      canUpdateCoveredIndication,
      obSalesStateChangedData?.salesAndTradingOrderBook?.salesState,
    ]
  );

  return (
    <IndicationActivitySalesAndTradingContext.Provider value={context}>
      {children}
    </IndicationActivitySalesAndTradingContext.Provider>
  );
};
