import { UUID } from '@cmg/common';
import { RouteComponentProps } from 'react-router-dom';

import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { WireTypeValidation } from '../../context/WireTypeConfigContext.model';
import * as thisModule from './WireTypeRoute.model';

export type WireRouteProps = { issuerName?: string } & RouteComponentProps<{
  offeringId: UUID;
  syndicateWireId?: UUID;
  managerId?: string;
}>;

export type LoadingData = {
  isManagersLoading: boolean;
  isValidationLoading: boolean;
  isNextRecipientsLoading: boolean;
  managers?: SyndicateWireManager[];
  validation?: WireTypeValidation;
  nextRecipients?: SyndicateWireManager[];
};

export function isLoading({
  isManagersLoading,
  isValidationLoading,
  isNextRecipientsLoading,
}: Pick<LoadingData, 'isManagersLoading' | 'isNextRecipientsLoading' | 'isValidationLoading'>) {
  return isManagersLoading || isValidationLoading || isNextRecipientsLoading;
}

export function isAnyUndefined({
  managers,
  validation,
  nextRecipients,
}: Pick<LoadingData, 'managers' | 'validation' | 'nextRecipients'>) {
  return managers === undefined || validation === undefined || nextRecipients === undefined;
}

export function isLoadingFirstTime({
  isManagersLoading,
  isValidationLoading,
  isNextRecipientsLoading,
  managers,
  validation,
  nextRecipients,
}: LoadingData): boolean {
  if (!thisModule.isLoading({ isManagersLoading, isValidationLoading, isNextRecipientsLoading })) {
    return false;
  }

  return thisModule.isAnyUndefined({ managers, validation, nextRecipients });
}
