import { WireTemplateType } from '../../../../../../../../graphql';
import { useSyndicateWires_WiresQuery } from '../../../../graphql';

type Options = {
  offeringId: string;
  templateType: WireTemplateType | undefined;
  isNonSyndicate: boolean;
  isWireTypeActive: boolean;
};

export const useWireVersions = ({
  offeringId,
  templateType,
  isNonSyndicate,
  isWireTypeActive,
}: Options) => {
  const { data, loading, error } = useSyndicateWires_WiresQuery({
    variables: {
      offeringId,
      isNonSyndicate,
      includeOnlyLatestWires: false,
      where: {
        templateType: {
          in: [templateType as WireTemplateType],
        },
      },
    },
    skip: !(templateType && isWireTypeActive),
  });

  return {
    versions: data?.wires ?? [],
    isVersionsLoading: loading,
    isVersionsError: !!error,
  };
};
