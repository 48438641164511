import { Option } from '@cmg/common';

import { TradeActivityType } from '../../../../../../../../graphql';
import { activityTypeLabel } from '../../read-only-stabilization-trades/read-only-stabilization-trades-row/ReadOnlyStabilizationTradesRow.model';

export const activityTypeOptions: Option[] = [
  {
    value: TradeActivityType.SyndicateCovering,
    label: activityTypeLabel.SYNDICATE_COVERING,
  },
  {
    value: TradeActivityType.StabilizingBid,
    label: activityTypeLabel.STABILIZING_BID,
  },
  {
    value: TradeActivityType.NotRule_104Reportable,
    label: activityTypeLabel.NOT_RULE_104_REPORTABLE,
  },
];
