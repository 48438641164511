import React, { useMemo } from 'react';

import Banner from '../../../../../common/components/indicators/banner/Banner';
import { ManagerEconomicsErrors as ManagerEconomicsErrorsProp } from '../../hooks/useValidateManagerEconomicsData';

type Props = {
  errors: ManagerEconomicsErrorsProp;
};

export const ManagerEconomicsErrors = ({ errors }: Props) => {
  const errorMessage = useMemo(() => {
    const {
      incentiveFee,
      managementFee,
      underwritingFee,
      underwritingWithIncentiveFee,
      sellingConcession,
      feeDistribuitionErrorCount,
    } = errors;

    let errorMessage: string | null = null;

    if (incentiveFee) {
      errorMessage =
        'Incentive Fee % cannot be saved until Total Incentive Fee % is equal to 100.00%';
    } else if (feeDistribuitionErrorCount && feeDistribuitionErrorCount > 1) {
      errorMessage =
        'Fee Distributions cannot be saved until each fee distribution total is equal to 100.00%';
    } else if (underwritingFee) {
      errorMessage =
        'U/W% (unadj. for incentive fee) cannot be saved until Total U/W% (unadj. for incentive fee) is equal to 100.00%';
    } else if (managementFee) {
      errorMessage = 'Mgmt Fee % cannot be saved until Total Mgmt Fee % is equal to 100.00%';
    } else if (sellingConcession) {
      errorMessage =
        'Selling Concession % cannot be saved until Total Selling Concession % is equal to 100.00%';
    } else if (underwritingWithIncentiveFee) {
      errorMessage =
        'U/W% (incl. incentive fee) cannot be saved until Total U/W% (incl. for incentive fee) is equal to 100.00%';
    }
    return errorMessage;
  }, [errors]);

  return errorMessage ? <Banner variant="error">{errorMessage}</Banner> : null;
};
