import { Theme } from '@cmg/common';

export const extendedProps = {
  fillViewport: true,
  withMargin: false,
  hideColumnResize: true,
  hideColumnSelector: true,
  hidePagination: true,
};

export const gridOptionsProps = (theme: Theme) => ({
  rowStyle: {
    background: theme.background.color.white,
    borderBottom: theme.border.smallSolidLight,
  },
  rowHeight: 36,
  suppressBrowserResizeObserver: true,
  suppressCellSelection: true,
  suppressRowClickSelection: true,
});
