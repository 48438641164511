import { Popover } from '@cmg/common';
import React from 'react';

import { getFirmDisplayName } from '../../../../utils';
import { SyndicateManager } from '../../types';
import { SLabel, SRemainingFirms } from './FirmListDisplayNameCell.styles';

export type Props = Readonly<{ managers: (SyndicateManager | null)[] }>;

export const FirmListDisplayNameCell: React.FC<Props> = ({ managers }) => {
  if (!managers.length) {
    return null;
  }

  const managerFirmNames = managers.map((manager, index) => (
    <div key={index}>{getFirmDisplayName(manager, { ignoreAbbreviation: true })}</div>
  ));

  const withTooltip =
    managers.length > 1 || managers.some(manager => !!manager?.firmNameAbbreviation);

  return (
    <Popover disabled={!withTooltip} content={managerFirmNames} variant="DARK" placement="topLeft">
      <SLabel>
        {getFirmDisplayName(managers[0])}
        {managerFirmNames.length > 1 && (
          <SRemainingFirms> &nbsp;+{managerFirmNames.length - 1}...</SRemainingFirms>
        )}
      </SLabel>
    </Popover>
  );
};
