import { FilterValueGetterFn } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridDataContext, DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, managersFilterParams } from '../columns.model';
import AcknowledgementsCellRenderer from './AcknowledgementsCellRenderer';
import { getTooltipValue, getVariant } from './AcknowledgementsCellRenderer.model';
import { getContent } from './AcknowledgementsTooltip';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['acknowledgedByFirmNames'];

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: { component: AcknowledgementsCellRenderer },
});

export const filterValueGetter: FilterValueGetterFn = (filterModel, context) => {
  const filterParam = filterModel?.in;
  if (Array.isArray(filterParam) && filterParam.length === 0) {
    const allManagers = Object.keys((context as DemandGridDataContext).syndicateManagers);
    return {
      all: {
        manager: {
          firmKey: {
            nin: allManagers,
          },
        },
      },
    };
  }
  return {
    some: {
      manager: {
        firmKey: filterModel,
      },
    },
  };
};

const acknowledgementsColDef: DemandGridServerColDef<Value> = {
  field: 'acknowledgedByFirmNames',
  headerName: 'Acknowledgements',
  cellRendererSelector,
  tooltipValueGetter: getTooltipValue,
  cellClass: '', // error style is handled by cell renderer
  filter: 'agSetColumnFilter',
  filterParams: managersFilterParams,
  server: {
    filterFieldPath: 'acknowledgements',
    filterValueGetter,
  },
  tooltipComponentParams: {
    getVariant,
    getContent,
  },
};

export default acknowledgementsColDef;
