import React from 'react';
import { useTheme } from 'styled-components/macro';

import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';

export const WireObsolete: React.FC = () => {
  const { wireTypeCategory } = useWireTypeConfigContext();
  const theme = useTheme();

  return (
    <HeroGraphics
      testId="wire-obsolete-hero"
      iconNameParts={{ name: 'exclamation-circle', variant: 'light' }}
      iconColor={theme.designSystem.colors.blue['300']}
      title={`This version of the ${wireTypeCategory} is obsolete`}
      subtitle={`Select a recipient to preview their sent ${wireTypeCategory} or an obsolete draft.`}
    />
  );
};
