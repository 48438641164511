import { UUID } from '@cmg/common';

import { useIsOfferingAuthor_OfferingQuery } from './graphql';

export const useIsOfferingAuthor = (offeringId: UUID | undefined) => {
  const { data, error } = useIsOfferingAuthor_OfferingQuery({
    variables: { offeringId: offeringId! },
    skip: offeringId === undefined,
  });

  return !error && !!data?.offering.isAuthor;
};
