import { ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledTable = styled(Table)`
  padding: 24px 0;
  font-size: ${({ theme }) => theme.text.size.xs};
`;

export const StyledUnderlinedCell = styled(Table.Cell)`
  border-bottom: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.designSystem.colors.gray['300']};
`;

export const StyledTableHeader = styled(Table.TableHeaderCell)`
  background-color: ${({ theme }) => theme.designSystem.colors.gray['000']};
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.xs};
`;
