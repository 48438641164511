import { ConfirmModal, numericUtil } from '@cmg/common';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

type OwnProps = {
  onCancel: () => void;
  onConfirm: () => void;
  newShareQuantity: number;
  promptType: 'acknowledge' | 'decline';
};

export type Props = OwnProps & InjectedProps;

export const AllocationDiscrepancyModal: React.FC<Props> = ({
  handleHide,
  onCancel,
  onConfirm,
  newShareQuantity,
  promptType,
}) => (
  <ConfirmModal
    show
    size="small"
    title="Allocation Discrepancy"
    confirmButtonCaption={upperFirst(promptType)}
    cancelButtonCaption={`Do Not ${upperFirst(promptType)}`}
    onConfirm={() => {
      onConfirm();
      handleHide();
    }}
    onHide={() => {
      onCancel();
      handleHide();
    }}
    closeButton
  >
    <p>
      Your final allocation amount has changed. It is now{' '}
      <b>{numericUtil.formatNumber(newShareQuantity)}</b>. Do you wish to
      {promptType} the new allocation amount?
    </p>
  </ConfirmModal>
);

export const name = 'ORDERBOOK/ALLOCATION_DISCREPANCY_MODAL';
export const openAllocationDiscrepancyModal = (props: OwnProps) => show(name, props);
export const closeAllocationDiscrepancyModal = () => hide(name);

export default connectModal({ name })(AllocationDiscrepancyModal);
