import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_PricingTermsWiresDocument,
  SyndicateWires_SendPricingTermsWireMutation,
  useSyndicateWires_SendPricingTermsWireMutation,
} from '../graphql';

export type SendPricingTermsWireMutation = SyndicateWires_SendPricingTermsWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PricingTermsWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useSendPricingTermsWireMutation = () => {
  return useSyndicateWires_SendPricingTermsWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
