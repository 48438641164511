import {
  SyndicateWires_UpdateIssuerIndicationWireMutation,
  useSyndicateWires_UpdateIssuerIndicationWireMutation,
} from '../graphql';

export type UpdateIssuerIndicationWireMutation = SyndicateWires_UpdateIssuerIndicationWireMutation;

export const useUpdateIssuerIndicationWireMutation = () => {
  return useSyndicateWires_UpdateIssuerIndicationWireMutation();
};
