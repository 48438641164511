import { Banner, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import InvestorSearch from '../../../order-book/components/InvestorSearch';
import { SidePanelTitle } from '../../components/side-bar/components/design-system';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import { OfferingSidePanelRouteContext } from '../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../offeringSidePanelSellSideRouteFactory';
import { useNotes_IndicationInvestorQuery } from './graphql';
import Notes from './Notes';
import { SNotesContainer, SNotesDropdownContainer } from './NotesRoute.styles';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const NotesRoute: React.FC<Props> = ({ match }) => {
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);
  const history = useHistory();
  const { offeringId } = match.params;

  const {
    data: indicationsData,
    loading: indicationsLoading,
    error: indicationsError,
  } = useNotes_IndicationInvestorQuery({ variables: { offeringId } });

  const indications =
    indicationsData?.basicPublishedOffering?.orderBook.institutionalIndications ?? [];
  const currentIndication = indications.find(
    i => i.investorInformation.cmgEntityKey === cmgEntityKey
  );

  if (indicationsLoading) {
    return <Loading />;
  }
  if (indicationsError) {
    return <ServerErrorsBanner error={indicationsError} />;
  }

  return (
    <SNotesContainer>
      <SidePanelTitle>Notes</SidePanelTitle>
      <SNotesDropdownContainer>
        <InvestorSearch
          investors={indications.map(ds => {
            return {
              key: ds.investorInformation.cmgEntityKey!,
              name:
                ds.investorInformation.bankInvestorName ?? ds.investorInformation.cmgEntityName!,
            };
          })}
          onChange={key => {
            key &&
              history.push(
                baseSidePanelUrl +
                  offeringSidePanelSellSideRouteFactory.orderBookNotes.getUrlPath({
                    cmgEntityKey: key,
                  })
              );
          }}
          currentKey={cmgEntityKey!}
          placehorder="Select an Investor"
        />
      </SNotesDropdownContainer>

      <Banner variant="info">The following information is visible for your firm only.</Banner>
      {currentIndication && (
        <Notes offeringId={offeringId} indicationId={currentIndication.id}></Notes>
      )}
    </SNotesContainer>
  );
};

export default NotesRoute;
