import { Panel } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export type Props = {
  rightContent?: React.ReactNode[] | React.ReactNode;
  withoutMargin?: boolean;
  isDesignSystem?: boolean;
};

// HACK: This is a hack to force this component to have the correct styles until we implement top-level save
const SDesignSystemPanelHeader = styled(Panel.Header)`
  margin-bottom: 0;
`;

const OfferingSetupHeader: React.FC<Props> = ({
  children,
  rightContent,
  withoutMargin,
  isDesignSystem,
}) => {
  // HACK: This is a hack to force this component to have the correct styles until we implement top-level save
  const PanelComponent = isDesignSystem ? SDesignSystemPanelHeader : Panel.Header;

  return (
    <PanelComponent rightContent={[rightContent]} withoutMargin={withoutMargin}>
      {children}
    </PanelComponent>
  );
};

export default OfferingSetupHeader;
