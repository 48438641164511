import styled from 'styled-components/macro';

import Panel from '../../../../../../../common/components/layout/panel/Panel';

export const StyledListPanel = styled(Panel)`
  display: contents;
`;

export const StyledListPanelHeader = styled(Panel.Header)`
  grid-column: 1/-1;
  margin: 16px 0;
`;

export const StyledListPanelContent = styled(Panel.Content)`
  display: contents;
`;
