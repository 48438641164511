import { ToastManager, useHubspotForm } from '@cmg/common';
import React from 'react';

import LoadingOverlay from '../../indicators/loading-overlay/LoadingOverlay';
import { SFormContainer } from './HubspotForm.styles';

const hubspotTarget = 'hubspot-form-node';

type Props = {
  portalId: string;
  formId: string;
  onFormSubmit?: (form) => void;
  onFormReady?: () => void;
};

const HubspotForm: React.FC<Props> = ({ portalId, formId, onFormSubmit, onFormReady }) => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId,
    formId,
    target: `#${hubspotTarget}`,
    onFormSubmit,
    onFormReady,
  });

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Failed to load support form');
    }
  }, [error]);

  return (
    <SFormContainer>
      {(!loaded || !formCreated) && <LoadingOverlay message="Loading..." />}
      <div id={hubspotTarget} />
    </SFormContainer>
  );
};

export default HubspotForm;
