import { timeUtil } from '@cmg/common';
import React from 'react';

import { RoadshowEventType } from '../../../../../../graphql';
import { Roadshows_RoadshowEventPartsFragment } from '../../../../common/graphql';
import { getDateFromTime } from './MeetingContent.model';
import { Styles } from './MeetingContent.styles';

export type Props = {
  onEditMeeting: (meetingId: string) => void;
  onEditLogistics: (logisticsId: string) => void;
  event: Roadshows_RoadshowEventPartsFragment;
};

/**
 * Render meeting information in calendar
 */
export const MeetingContent: React.FC<Props> = ({ onEditMeeting, onEditLogistics, event }) => {
  const handleDoubleClick = () => {
    if (event.roadshowEventType === RoadshowEventType.Meeting) {
      onEditMeeting(event.id);
    } else if (event.roadshowEventType === RoadshowEventType.Logistics) {
      onEditLogistics(event.id);
    }
  };

  return (
    <Styles.Box onDoubleClick={handleDoubleClick} event={event}>
      <Styles.Title>{event.title}</Styles.Title>
      <div>
        {timeUtil.formatAsDisplayTimeRange(
          getDateFromTime(event.startTime),
          getDateFromTime(event.endTime)
        )}
      </div>
    </Styles.Box>
  );
};
