import styled from 'styled-components/macro';

export const SContainer = styled.div`
  display: flex;
  overflow: visible;
  position: relative;
  padding: 0 16px;
  align-items: center;
  cursor: default;
`;

export const SIconWrapper = styled.div`
  padding: 4px;
  background-color: ${({ theme }) => theme.background.color.dark};
  border-radius: ${({ theme }) => theme.border.radius.small};
`;

export const STooltipHeader = styled.span`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.text.color.white};
  gap: 8px;
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const STooltipTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 8px;
`;

export const STooltipTableHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const STooltipTableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const STooltipTableCell = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.white};
  flex: 1;
`;

export const STooltipTableHeaderCell = styled.div`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.background.color.highlighted};
  flex: 1;
`;
