import { DataGridGroupingClient } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { InstitutionalDemandGridUpdate } from '../institutional-demand-grid/hooks/data-refresh-hooks/useComputeDiffOnInstitutionalDemandGrid';
import { columns, OrderBookUpdatesGridField } from './InstitutionalDemandUpdatesGridColumns';

export type Props = {
  updates: InstitutionalDemandGridUpdate[];
};

const InstitutionalDemandUpdatesGrid: React.FC<Props> = ({ updates }) => {
  const theme = useTheme();

  return (
    <DataGridGroupingClient
      groupByField={OrderBookUpdatesGridField.TYPE}
      columns={columns}
      rows={updates}
      resizeBy="grid"
      extended={{
        withMargin: false,
        hideColumnResize: true,
        hideColumnSelector: true,
        fillViewport: true,
      }}
      gridOptions={{
        suppressCellSelection: true,
        suppressRowClickSelection: true,
        suppressBrowserResizeObserver: true,
        rowHeight: 50,
        rowStyle: {
          background: theme.background.color.white,
          borderBottom: theme.border.smallSolidLight,
        },
      }}
      loading={!updates}
      getRowNodeId={data => data.id}
    />
  );
};

export default InstitutionalDemandUpdatesGrid;
