import { disclaimerAssets, theme } from '@cmg/common';

// Displays when there are no rows to show. Overrides ag-grid's default "No Rows to Show," but uses the same styles.
// Must be a string for ag-grid to consume it.

export const NoRowsOverlayString = `
<div style="  
  display: flex;
  flex-direction: column;
  height: 100%;
  pointer-events: auto;
  padding-bottom: 30px;
  width: 100%;
">
  <p style="
    font-size: 12 px;
    font-family: sans-serif;
    font-weight: 400;
    align-self: center;
    margin-top: auto;
  ">
      No Rows to Show
      <div style= "  
        align-self: start;
        margin-top: auto;
      ">
        <div style="
          padding-top: 5px;
          color: ${theme.text.color.light};
          font-size: ${theme.text.size.tiny};
          text-align: left;
          max-width: 1300px;
          white-space: normal;
          align-items: end;
          margin-top: auto;
          "
          >
            ${disclaimerAssets.disclaimerText}
          </div>
        </div>
      </p>
    </div>
  `;

export const overlayNoRowsTemplate = NoRowsOverlayString;
