import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { OfferingType, SyndicateWiresManagerRole, WireTemplateType } from '../../../../../graphql';
import { createUseDefaultSendIsDisabledReason } from '../../common/context/hooks/useDefaultSendIsDisabledReason';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { getMasterDate } from '../../common/utils/masterDate';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_UnderwriterInvitationWirePartsFragment,
  SyndicateWires_UnderwriterInvitationWireValidationPartsFragment,
  useSyndicateWires_MasterAauDateQuery,
  useSyndicateWires_UnderwriterInvitationWireDetailsQuery,
  useSyndicateWires_UnderwriterInvitationWirePreviewQuery,
  useSyndicateWires_UnderwriterInvitationWiresQuery,
  useSyndicateWires_UnderwriterInvitationWireValidationQuery,
} from './graphql';
import { useCreateUnderwriterInvitationWireMutation } from './hooks/useCreateUnderwriterInvitationWireMutation';
import { useDeleteUnderwriterInvitationWireMutation } from './hooks/useDeleteUnderwriterInvitationWireMutation';
import { useSendUnderwriterInvitationWireMutation } from './hooks/useSendUnderwriterInvitationWireMutation';
import { useUpdateUnderwriterInvitationWireMutation } from './hooks/useUpdateUnderwriterInvitationWireMutation';
import { UnderwriterInvitationWireForm } from './UnderwriterInvitationWireForm';
import { validationSchema, Values } from './UnderwriterInvitationWireForm.model';

const wireTypeName = 'Underwriter Invitation';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role !== SyndicateWiresManagerRole.SellingGroupMember;
};

export const getWireTemplateType = ({ offeringType }: { offeringType: OfferingType }) =>
  [OfferingType.Ipo, OfferingType.IpoSpac].includes(offeringType)
    ? WireTemplateType.UnderwriterInvitationIpo
    : WireTemplateType.UnderwriterInvitationFo;

export const createWireTypeConfigUnderwriterInvitation = ({
  offeringType,
}: {
  offeringType: OfferingType;
}) =>
  createWireTypeConfig<
    Values,
    SyndicateWires_UnderwriterInvitationWirePartsFragment,
    SyndicateWires_UnderwriterInvitationWireValidationPartsFragment,
    typeof routeFactory.syndicateWiresUnderwriterInvitation
  >({
    wireTypeName,
    wireTypeRoute: routeFactory.syndicateWiresUnderwriterInvitation,
    wireTemplateType: getWireTemplateType({ offeringType }),
    useSendIsDisabledReason: createUseDefaultSendIsDisabledReason({
      canBeSentForDraftOffering: true,
    }),
    useManagers: createUseDefaultManagers(managerFilter),
    useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
    useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
    useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

    useGenerateWirePreview: queryArgs => {
      const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWirePreviewQuery({
        variables: queryArgs,
        fetchPolicy: 'cache-and-network',
      });

      return {
        data: data?.underwriterInvitationWirePreview.htmlContent,
        error,
        isLoading: loading,
      };
    },
    useWireDetails: (queryArgs, fetchPolicy) => {
      const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWireDetailsQuery({
        variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
        skip: !queryArgs.syndicateWireId,
        fetchPolicy: fetchPolicy || 'cache-and-network',
      });

      return {
        data: data && {
          wire: data.underwriterInvitationWireDetails,
          stage: data.offering.stage,
        },
        isLoading: loading,
        error,
      };
    },
    useWireList: ({ offeringId }) => {
      const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWiresQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });

      return {
        data: data?.underwriterInvitationWireList,
        isLoading: loading,
        error,
      };
    },
    useWireValidation: ({ offeringId, fetchPolicy }) => {
      const { data, loading, error, refetch } =
        useSyndicateWires_UnderwriterInvitationWireValidationQuery({
          variables: { offeringId },
          fetchPolicy: fetchPolicy || 'cache-and-network',
        });

      return {
        data: data?.underwriterInvitationWireValidation,
        isLoading: loading,
        error,
        refetch,
      };
    },
    useCreateMutation: () => {
      const [createWire, { loading }] = useCreateUnderwriterInvitationWireMutation();

      return {
        mutation: async ({ offeringId, values }) => {
          const result = await createWire({
            variables: {
              offeringId,
              payload: values,
            },
          });

          if (result.data?.createUnderwriterInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Creating the ${wireTypeName} wire failed.`);
          }

          return result.data?.createUnderwriterInvitationWire;
        },
        isLoading: loading,
      };
    },
    useUpdateMutation: () => {
      const [updateWire, { loading }] = useUpdateUnderwriterInvitationWireMutation();

      return {
        mutation: async ({ offeringId, syndicateWireId, values }) => {
          const result = await updateWire({
            variables: { offeringId, syndicateWireId, payload: values },
          });

          if (result.data?.updateUnderwriterInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Updating the ${wireTypeName} wire failed.`);
          }

          return result.data?.updateUnderwriterInvitationWire;
        },
        isLoading: loading,
      };
    },
    useDeleteMutation: () => {
      const [deleteWire, { loading }] = useDeleteUnderwriterInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await deleteWire({ variables });

          if (result.data?.deleteUnderwriterInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Deleting the ${wireTypeName} wire failed.`);
          }
        },
        isLoading: loading,
      };
    },
    useSendMutation: () => {
      const [sendWire, { loading }] = useSendUnderwriterInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await sendWire({
            variables: { ...variables, ...generatePayloadDateTimeData() },
          });

          if (result.data?.sendUnderwriterInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Sending the ${wireTypeName} wire failed.`);
          }

          return result.data?.sendUnderwriterInvitationWire;
        },
        isLoading: loading,
      };
    },

    CreateOrUpdateForm: UnderwriterInvitationWireForm,
    // @ts-expect-error date field type mismatch
    formValidation: validationSchema,
    useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
      const {
        data: disclaimerData,
        loading: disclaimerLoading,
        error: disclaimerError,
      } = useSyndicateWires_WiresDisclaimerQuery({
        variables: {
          offeringId,
          offeringType,
          templateType: wireTemplateType,
        },
      });

      const {
        data: masterAauData,
        loading: masterAauLoading,
        error: masterAauError,
      } = useSyndicateWires_MasterAauDateQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });

      const initialValues = React.useMemo<Values>(() => {
        const masterAauDate = masterAauData?.syndicateWires.masterAauDate;
        return {
          masterAauDate: getMasterDate(operationType, wire?.masterAauDate, masterAauDate),
          disclaimer: getDisclaimer(
            operationType,
            wire?.disclaimer,
            disclaimerData?.syndicateWires.wiresDisclaimer
          ),
        };
      }, [
        masterAauData?.syndicateWires.masterAauDate,
        wire?.masterAauDate,
        wire?.disclaimer,
        operationType,
        disclaimerData?.syndicateWires.wiresDisclaimer,
      ]);

      const isLoading = disclaimerLoading || masterAauLoading;
      const error = disclaimerError || masterAauError;
      return { isLoading, error, data: initialValues };
    },
  });
