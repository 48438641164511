import { UUID } from '@cmg/common';
import React from 'react';

import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';
import { useBrokerDealersMyRetailQuery } from './useBrokerDealersMyRetailQuery';

/**
 * For Non-Syndicate wires the managers/recipients come from Retail Demand rather than Offering Details
 */
export function createUseDefaultOutdatedNonSyndicateManagersList() {
  return ({
    offeringId,
    syndicateWireId,
  }: {
    offeringId: UUID;
    syndicateWireId?: UUID;
  }): string[] | undefined => {
    const { useManagers } = useWireTypeConfigContext();

    const wireManagers = useManagers({ offeringId, syndicateWireId });
    const brokerDealersMyRetailQuery = useBrokerDealersMyRetailQuery({ offeringId });

    return React.useMemo(() => {
      if (
        brokerDealersMyRetailQuery.loading ||
        brokerDealersMyRetailQuery.error ||
        wireManagers.isLoading ||
        wireManagers.error
      ) {
        return undefined;
      }

      const brokerDealerKeys = brokerDealersMyRetailQuery.data?.map(m => m.cmgEntityKey) ?? [];
      const wireManagerKeys = wireManagers.data?.map(m => m.cmgEntityKey) ?? [];
      return wireManagerKeys.filter(wireManagerKey => !brokerDealerKeys.includes(wireManagerKey));
    }, [
      wireManagers.data,
      brokerDealersMyRetailQuery.data,
      wireManagers.error,
      brokerDealersMyRetailQuery.error,
      wireManagers.isLoading,
      brokerDealersMyRetailQuery.loading,
    ]);
  };
}
