import { Icon, numericUtil, Popover } from '@cmg/common';
import { useFormikContext } from 'formik';

import {
  ExpensesValues,
  getDealRelatedExpensesTotals,
  getManagerExpensesTotals,
} from '../../form/ExpensesForm.model';
import { SBoldText, STable, STotalWrapper } from './TotalExpenses.styles';

const TotalExpenses: React.FC = () => {
  const { values } = useFormikContext<ExpensesValues>();

  const managerExpenses = getManagerExpensesTotals(values.managerExpenses);
  const dealRelatedExpenses = getDealRelatedExpensesTotals(values.dealRelatedExpenses);

  return (
    <STotalWrapper>
      <SBoldText>
        Total Expenses:{' '}
        {numericUtil.getDisplayValueForCurrency(
          numericUtil.sum(managerExpenses.reimbursement, dealRelatedExpenses)
        )}
      </SBoldText>
      <Popover
        placement="topLeft"
        arrowPointAtCenter
        content={
          <STable>
            <tbody>
              <tr>
                <td>Manager Reimbursement</td>
                <td />
                <td align="right">
                  {numericUtil.getDisplayValueForCurrency(managerExpenses.reimbursement)}
                </td>
              </tr>
              <tr>
                <td>Deal Related Expenses</td>
                <td>+</td>
                <td align="right">{numericUtil.getDisplayValueForCurrency(dealRelatedExpenses)}</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>
                  <SBoldText>Total Expenses</SBoldText>
                </td>
                <td />
                <td align="right">
                  <SBoldText>
                    {numericUtil.getDisplayValueForCurrency(
                      numericUtil.sum(managerExpenses.reimbursement, dealRelatedExpenses)
                    )}
                  </SBoldText>
                </td>
              </tr>
            </tbody>
          </STable>
        }
        variant="DARK"
      >
        <div>
          <Icon name="info-circle" variant="solid" />
        </div>
      </Popover>
    </STotalWrapper>
  );
};

export default TotalExpenses;
