import { ISODate, timeUtil } from '@cmg/common';
import { format, parse } from 'date-fns';

import { MeetingFormat } from '../../../../../../../../graphql';
import {
  Roadshows_MeetingDetailsPartsFragment,
  Roadshows_RoadshowPartsFragment,
  Roadshows_RoadshowsDealTeamPartsFragment,
} from '../../../../../../common/graphql';

export const getRoadshowTimezone = (
  roadshowDay: Roadshows_RoadshowPartsFragment['roadshowDays'][number],
  meeting: Roadshows_MeetingDetailsPartsFragment
) => {
  if (roadshowDay.isVirtual) {
    return timeUtil.formatAsTimeZoneAbbreviation(roadshowDay.date, roadshowDay.timeZone!);
  }

  const location = roadshowDay.cities.find(({ name }) => name === meeting.location)!; // for non-virtual meetings there has to exist this location

  return timeUtil.formatAsTimeZoneAbbreviation(roadshowDay.date, location.timeZone);
};

export const formatDate = (dateString: ISODate) => {
  return format(new Date(dateString), 'EEEE, dd/MM/yyyy');
};

export const formatTime = (timeString: string) => {
  const date = parse(timeString, 'HH:mm:ss', new Date());

  return format(date, 'hh:mm a');
};

export const meetingFormatNames = {
  [MeetingFormat.InPerson]: 'Physical',
  [MeetingFormat.Hybrid]: 'Hybrid',
  [MeetingFormat.Virtual]: 'Virtual',
};

export const getMeetingUnderwriter = (
  meetingUnderwriterCmgEntityKey: string | null,
  dealTeams: Roadshows_RoadshowsDealTeamPartsFragment[]
) => {
  if (!meetingUnderwriterCmgEntityKey || !dealTeams) {
    return;
  }

  return dealTeams.find(
    ({ firmCmgEntityKey }) => firmCmgEntityKey === meetingUnderwriterCmgEntityKey
  )?.firmName;
};
