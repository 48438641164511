import { InstitutionalIndicationOrderType } from '../../../../../../../../graphql';
import { BuySideIndicationQuery } from '../../../../context/hooks/buy-side/__generated__';
import {
  emptyInterestLevelValue,
  IndicationFormValues,
} from '../../common/utils/IndicationSectionValidation';

export const createInitialValues = (
  indication: BuySideIndicationQuery['basicPublishedOffering']['myInstitutionalIndication']
): IndicationFormValues => {
  const interestLevels =
    indication?.interestLevels.map(({ interestQuantity, interestUnit, limitType, limitPrice }) => ({
      interestQuantity,
      interestUnit,
      orderType: limitPrice
        ? InstitutionalIndicationOrderType.Limit
        : InstitutionalIndicationOrderType.Market,
      limitPrice: limitPrice ?? null,
      limitType: limitType ?? null,
    })) ?? [];

  return {
    trancheId: indication?.trancheId ?? null,
    instrumentId: indication?.instrumentId ?? null,
    currencyCode: indication?.currencyCode ?? null,
    interestLevels: interestLevels.length > 0 ? interestLevels : [emptyInterestLevelValue],
  };
};
