/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type DupeIndicationBanner_CoveredIndicationEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndication?: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly bankInvestorName?: string | null;
      readonly cmgEntityName: string;
      readonly cmgEntityKey: string;
    };
  } | null;
};

export const DupeIndicationBanner_CoveredIndicationEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DupeIndicationBanner_CoveredIndicationEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useDupeIndicationBanner_CoveredIndicationEntityKeyQuery__
 *
 * To run a query within a React component, call `useDupeIndicationBanner_CoveredIndicationEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDupeIndicationBanner_CoveredIndicationEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDupeIndicationBanner_CoveredIndicationEntityKeyQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useDupeIndicationBanner_CoveredIndicationEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    DupeIndicationBanner_CoveredIndicationEntityKeyQuery,
    DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DupeIndicationBanner_CoveredIndicationEntityKeyQuery,
    DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables
  >(DupeIndicationBanner_CoveredIndicationEntityKeyDocument, options);
}
export function useDupeIndicationBanner_CoveredIndicationEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DupeIndicationBanner_CoveredIndicationEntityKeyQuery,
    DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DupeIndicationBanner_CoveredIndicationEntityKeyQuery,
    DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables
  >(DupeIndicationBanner_CoveredIndicationEntityKeyDocument, options);
}
export type DupeIndicationBanner_CoveredIndicationEntityKeyQueryHookResult = ReturnType<
  typeof useDupeIndicationBanner_CoveredIndicationEntityKeyQuery
>;
export type DupeIndicationBanner_CoveredIndicationEntityKeyLazyQueryHookResult = ReturnType<
  typeof useDupeIndicationBanner_CoveredIndicationEntityKeyLazyQuery
>;
export type DupeIndicationBanner_CoveredIndicationEntityKeyQueryResult = Apollo.QueryResult<
  DupeIndicationBanner_CoveredIndicationEntityKeyQuery,
  DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../../graphql/mock-response';

export const makeDupeIndicationBanner_CoveredIndicationEntityKeyMockResponse = makeMockResponse<
  DupeIndicationBanner_CoveredIndicationEntityKeyQueryVariables,
  DupeIndicationBanner_CoveredIndicationEntityKeyQuery
>(DupeIndicationBanner_CoveredIndicationEntityKeyDocument);
