import { numericUtil } from '@cmg/common';
import { DeleteIcon, IconButton, TableCell, TableRow } from '@cmg/design-system';
import { FormikNumberField, FormikPercentageField } from '@cmg/design-system-formik';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import UnderwriterFirmSearchField from '../../../../../../../../common/components/form/underwriter-firm-search/UnderwriterFirmSearchField';
import TableCellOnPlatformIcon from '../../../../../../components/table-cell-on-platform-icon/TableCellOnPlatformIcon';
import CreateBrokerDealerDialog from '../../../../auto-save/table-row/create-broker-dealer-dialog/CreateBrokerDealerDialog';
import { isUnderwriterNameVisible } from '../../../../auto-save/table-row/InternalRetailDemandTableRow.model';
import { getPnLValues } from '../../../../common/utils';
import { MyRetailDemandBulkSaveValues } from '../../BulkEditInternalRetailDemandView.model';

export type Props = Readonly<{
  onRemoveRow: () => void;
  sellingConcession: number | null | undefined;
  index: number;
}>;

const InternalRetailDemandEditTableRow: React.FC<Props> = ({
  onRemoveRow,
  sellingConcession,
  index,
}) => {
  const { values, isSubmitting } = useFormikContext<MyRetailDemandBulkSaveValues>();
  const [, { error: brokerNameError }] = useField(`brokers.${index}.broker`);

  const rowValue = React.useMemo(() => values.brokers[index], [values, index]);
  const pnlValues = React.useMemo(
    () =>
      getPnLValues({
        sellingConcession,
        retentionShares: rowValue.retentionShares,
        sellingConcessionPercentage: rowValue?.sellingConcessionPercentage,
      }),
    [sellingConcession, rowValue.retentionShares, rowValue.sellingConcessionPercentage]
  );
  const allBrokers = React.useMemo(
    () => values.brokers.map(({ broker }) => broker),
    [values.brokers]
  );
  const isUnderwriterVisible = React.useMemo(
    () => isUnderwriterNameVisible(rowValue, !brokerNameError),
    [rowValue, brokerNameError]
  );

  return (
    <TableRow data-testid="demand-data-row">
      <TableCellOnPlatformIcon
        size="small"
        isOnPlatform={isUnderwriterVisible && !!rowValue.broker?.isOnPlatform}
      />
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {isUnderwriterVisible ? (
          <div>{rowValue.broker?.firmName}</div>
        ) : (
          <UnderwriterFirmSearchField
            name={`brokers.${index}.broker`}
            allBrokers={allBrokers}
            disabled={isSubmitting}
            showHelperTextInTooltip
            noOptionsText="Start typing to load Broker Dealers or Create new"
            CreateFirmDialogComponent={CreateBrokerDealerDialog}
          />
        )}
      </TableCell>
      <TableCell>
        <FormikNumberField
          aria-label="demand shares input"
          name={`brokers.${index}.demandShares`}
          decimalScale={0}
          disabled={isSubmitting}
          showHelperTextInTooltip
          fullWidth
        />
      </TableCell>
      <TableCell>
        <FormikNumberField
          aria-label="demand round lots input"
          name={`brokers.${index}.demandRoundLots`}
          decimalScale={0}
          disabled={isSubmitting}
          showHelperTextInTooltip
          fullWidth
        />
      </TableCell>
      <TableCell>
        <FormikNumberField
          aria-label="retention shares input"
          name={`brokers.${index}.retentionShares`}
          decimalScale={0}
          disabled={isSubmitting}
          showHelperTextInTooltip
          fullWidth
        />
      </TableCell>
      <TableCell>
        <FormikNumberField
          aria-label="retention round lots input"
          name={`brokers.${index}.retentionRoundLots`}
          decimalScale={0}
          disabled={isSubmitting}
          showHelperTextInTooltip
          fullWidth
        />
      </TableCell>
      <TableCell>
        <FormikPercentageField
          aria-label="selling concession received input"
          name={`brokers.${index}.sellingConcessionPercentage`}
          decimalScale={2}
          disabled={isSubmitting}
          showHelperTextInTooltip
          fullWidth
        />
      </TableCell>
      <TableCell align="right">
        {numericUtil.getDisplayValueForCurrency(pnlValues.perShare, 6)}
      </TableCell>
      <TableCell align="right">
        {numericUtil.getDisplayValueForCurrency(pnlValues.receivedByTheFirm, 2)}
      </TableCell>
      <TableCell align="right">
        {numericUtil.getDisplayValueForCurrency(pnlValues.retainedByTheBank, 2)}
      </TableCell>
      <TableCell>
        <IconButton
          color="primary"
          title="Delete Broker Dealer row"
          onClick={onRemoveRow}
          disabled={isSubmitting}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InternalRetailDemandEditTableRow;
