import {
  Icon,
  IconButton,
  ODSTable as Table,
  SelectField,
  TextInputField,
  ToastManager,
  UUID,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_TIMEOUT } from '../../../../../../../constants';
import { Roadshows_DealTeamMemberPartsFragment } from '../../../../../../common/graphql';
import IdentityIcon from '../../../../../../single-deal-team/common/identity-icon/IdentityIcon';
import { useAssignDealTeamMembersMutation } from '../../../hooks/useAssignDealTeamMembersMutation';
import {
  DealTeamMemberValues,
  getDepartmentOptions,
  getInitialValues,
  isIdentityMember,
  validationSchema,
} from './DealTeamMemberForm.model';
import { StyledForm, StyledIconCell } from './DealTeamMemberForm.styles';
import { useRoadshows_UpdateDealTeamMemberMutation } from './graphql';

export type Props = {
  roadshowId: UUID;
  member: Roadshows_DealTeamMemberPartsFragment;
  participatingMemberIds: UUID[];
  organizationDepartments: readonly string[];
};

const DealTeamMemberForm: React.FC<Props> = ({
  roadshowId,
  member,
  participatingMemberIds,
  organizationDepartments,
}) => {
  const [updateMember, { loading: isUpdating, called: updateCalled, data: updateData }] =
    useRoadshows_UpdateDealTeamMemberMutation();
  const [assignMembers, { loading: isAssigning, called: assignCalled, data: assignData }] =
    useAssignDealTeamMembersMutation();

  const theme = useTheme();

  const [hasFocus, setHasFocus] = React.useState(false);

  const formik = useFormik<DealTeamMemberValues>({
    validationSchema,
    initialValues: getInitialValues(member),
    onSubmit: async values => {
      await handleUpdateMember(values);
    },
  });

  const handleUpdateMember = async (values: DealTeamMemberValues) => {
    try {
      await updateMember({ variables: { memberId: member.id, payload: values } });
    } catch {
      ToastManager.error('Failed to update Deal Team Member');
    }
  };

  const handleDeleteMember = async () => {
    try {
      await assignMembers({
        variables: { roadshowId, payload: participatingMemberIds.filter(id => id !== member.id) },
      });
    } catch {
      ToastManager.error('Failed to unassign Deal Team Member');
    }
  };

  const debouncedSubmit = useDebouncedCallback(() => {
    if (!hasFocus && formik.dirty) {
      formik.submitForm();
    }
    // switching focus between inputs of the same form triggers blur (for prev element) and focus (for new element)
    // this event is propagated to their parent, so simple submit upon 'onBlur' would not work as desired, since user
    // has not left form yet
  }, DEBOUNCE_TIMEOUT);

  const handleOnBlur = () => {
    setHasFocus(false);
    debouncedSubmit();
  };
  const handleOnFocus = () => {
    setHasFocus(true);
  };

  const isIdentity = isIdentityMember(member);
  const isLoading = isAssigning || isUpdating;
  const isRecentlySaved = (assignCalled && assignData) || (updateCalled && updateData);

  return (
    <FormikProvider value={formik}>
      <StyledForm onBlur={handleOnBlur} onFocus={handleOnFocus}>
        <Table.Row>
          <StyledIconCell>{isIdentity && <IdentityIcon size={16} />}</StyledIconCell>
          <Table.Cell paddingSize="S">
            <TextInputField name="name" disabled={isIdentity} fullWidth />
          </Table.Cell>
          <Table.Cell paddingSize="S">
            <TextInputField name="mobilePhone" fullWidth />
          </Table.Cell>
          <Table.Cell paddingSize="S">
            <TextInputField name="officePhone" fullWidth />
          </Table.Cell>
          <Table.Cell paddingSize="S">
            <TextInputField name="email" disabled={isIdentity} fullWidth />
          </Table.Cell>
          <Table.Cell paddingSize="S">
            <SelectField
              name="department"
              options={getDepartmentOptions(organizationDepartments)}
              fullWidth
            />
          </Table.Cell>
          <StyledIconCell paddingSize="S">
            {isRecentlySaved && !isLoading && (
              <Icon
                name="check-circle"
                variant="regular"
                size="lg"
                color={theme.text.color.positive}
              />
            )}
            {isLoading && <Icon name="spinner-third" variant="light" size="lg" spin />}
            <IconButton
              testId="delete team member"
              onClick={handleDeleteMember}
              icon={{ name: 'trash-alt', variant: 'light', color: theme.text.color.link }}
            />
          </StyledIconCell>
        </Table.Row>
      </StyledForm>
    </FormikProvider>
  );
};

export default DealTeamMemberForm;
