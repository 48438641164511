import { DateRangePresetOptions, DateRangePresetTypes, dateRangeUtil, timeUtil } from '@cmg/common';
import { addDays, endOfWeek, format, startOfWeek, subWeeks } from 'date-fns';
import memoize from 'memoize-one';

export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');
const thisWeekStart = (currentDate: Date) => startOfWeek(currentDate, { weekStartsOn: 1 });
const thisWeekEnd = (currentDate: Date) => endOfWeek(currentDate, { weekStartsOn: 1 });

/**
 * Date range presets will be used to filter offerings by pricing date on the client-side
 *
 * Note: Unlike datalab we do not use these values to request anything from the server therefore
 * the 'type' field in the preset value does not matter only 'start' and 'end' are being used to filter the data
 */
export const getPresets = (currentDate: Date): DateRangePresetOptions => {
  const { N7D, N14D, N30D, N90D } = dateRangeUtil.getFuturePresetOptions(currentDate);
  const { PM, PQ, PYR, YTD, CUSTOM } = dateRangeUtil.getDefaultPresetOptions(currentDate);

  return {
    TW: {
      label: 'This Week',
      short: 'TW',
      value: {
        type: DateRangePresetTypes.TW,
        start: formatDate(thisWeekStart(currentDate)), // week starts on Monday
        end: formatDate(thisWeekEnd(currentDate)),
      },
    },
    PW: {
      label: 'Prior Week',
      short: 'PW',
      value: {
        type: DateRangePresetTypes.PREV_W,
        start: formatDate(subWeeks(thisWeekStart(currentDate), 1)),
        end: formatDate(addDays(endOfWeek(subWeeks(currentDate, 1)), 1)), // add 1 day because endOfWeek returns saturday, our week ends on sunday
      },
    },
    PM,
    PQ,
    YTD,
    PYR,
    N7D,
    N14D,
    N30D,
    N90D,
    CUSTOM,
  };
};

export const getPresetOptions: (currentDate: Date) => DateRangePresetOptions = memoize(
  getPresets,
  timeUtil.isSameDayEqualityFn
);
