import { ISODate } from '@cmg/common';
import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export type Values = {
  masterSdaDate: ISODate;
  disclaimer: string | null;
};

export const validationSchema = yup
  .object({
    masterSdaDate: yup.date().label('Master SDA date').typeError('Invalid date format').required(),
  })
  .concat(wiresFormDisclaimerSchema);
