import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import StabilizationForm from './form/StabilizationForm';
import {
  useFinalSettlement_StabilizationInformationQuery,
  useFinalSettlement_StabilizationQuery,
} from './graphql';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps & { offeringIssuerName: string | undefined };

const StabilizationRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.finalSettlementStabilization.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const {
    data: stabilizationData,
    loading: stabilizationLoading,
    error: stabilizationError,
  } = useFinalSettlement_StabilizationQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: informationData,
    loading: informationLoading,
    error: informationError,
  } = useFinalSettlement_StabilizationInformationQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  if (stabilizationError || informationError) {
    return <ServerErrorsBanner error={stabilizationError ?? informationError!} />;
  }

  if (stabilizationLoading || informationLoading) {
    return <Loading />;
  }

  return (
    <StabilizationForm
      stabilization={stabilizationData!.stabilization}
      offeringInformation={informationData!.stabilizationInformation}
      offeringId={offeringId}
      issuerName={offeringIssuerName ?? 'issuer'}
    />
  );
};

export default StabilizationRoute;
