import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_UpdateLogisticsMutation,
  useRoadshows_UpdateLogisticsMutation,
} from '../graphql';

export type UpdateLogisticsMutation = Roadshows_UpdateLogisticsMutation;

export const refetchQueries = () => {
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetings!];
};

export const useUpdateLogisticsMutation = () => {
  return useRoadshows_UpdateLogisticsMutation({ awaitRefetchQueries: true, refetchQueries });
};
