import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { DefaultRecipientValues } from '../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';
import { RecipientsTable } from './components/RecipientsTable';
import { DesignationRecipientUserData } from './DesignationWireForm.model';

export const defaultRecipientValues: DefaultRecipientValues<DesignationRecipientUserData> = {
  headerNote: null,
};

export const DesignationWireForm: React.FC<CreateOrUpdateFormProps> = ({
  managers,
  operationType,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner
          variant="common"
          messageAppendix="Designation values pulled from the Designation Monitor will be displayed on the next step."
        />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <WireFormBanner variant="manager-specific-readonly" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <RecipientsTable managers={managers} operationType={operationType} />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
