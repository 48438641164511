import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';

export type Props = {
  mode: 'edit' | 'view';
};

export const ActivitiesTableHeader: React.FC<Props> = ({ mode }) => {
  const isEditing = mode === 'edit';

  return (
    <React.Fragment>
      <Table.TableHeaderCell>
        {'Activity Type '}
        {isEditing && <MandatoryAsterisk aria-label="Required" />}
      </Table.TableHeaderCell>
      <Table.TableHeaderCell>
        {'Activity Date '}
        {isEditing && <MandatoryAsterisk aria-label="Required" />}
      </Table.TableHeaderCell>
      <Table.TableHeaderCell align={isEditing ? 'left' : 'right'}>
        {'Aggregate Number Of Shares '}
        {isEditing && <MandatoryAsterisk aria-label="Required" />}
      </Table.TableHeaderCell>
      {isEditing && <Table.TableHeaderCell aria-label="Action"></Table.TableHeaderCell>}
    </React.Fragment>
  );
};
