import styled from 'styled-components/macro';

export const SContainer = styled.div`
  margin: 16px;
`;

export const SMessage = styled.p`
  color: ${({ theme }) => theme.text.color.light};
`;

export const SSpinnerContainer = styled.div`
  width: 100%;
  height: 64px;
  margin: 16px 0;
`;
