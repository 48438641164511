import React from 'react';

import {
  OfferingSetup_EntitlementsConfigurationQuery,
  OfferingSetup_OfferingEntitlementsQuery,
} from '../graphql';

export type EntitlementData =
  OfferingSetup_OfferingEntitlementsQuery['offering']['entitlements']['syndicateEntitlements'][number] & {
    role: string;
    firmName: string;
  };
export type DataByManager = {
  [key: string]: EntitlementData;
};
export type ConfigurationByBoundedContext = {
  [key: string]: OfferingSetup_EntitlementsConfigurationQuery['entitlementsConfiguration'];
};

export const EntitlementsDataByManagerContext = React.createContext<{
  entitlementsByManager: DataByManager;
  selectedManagerEntitlements: EntitlementData | null;
  entitlementsConfiguration?: ConfigurationByBoundedContext;
}>({
  entitlementsByManager: {},
  selectedManagerEntitlements: null,
  entitlementsConfiguration: undefined,
});
