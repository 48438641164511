import { useCallback, useMemo } from 'react';

import { WireTemplateType } from '../../../../../../../../graphql';
import { Wire } from '../../../../../SyndicateWiresRoute.model';
import { useSyndicateWires_WiresQuery } from '../../../../graphql';

type Options = {
  offeringId: string;
  canReadSyndicateWires: boolean;
  canReadNonSyndicateWires: boolean;
};

export const SYNDICATE_WIRE_TEMPLATES: WireTemplateType[] = [
  WireTemplateType.DesignationWithIntegration,
  WireTemplateType.FreeForm,
  WireTemplateType.GreenShoe,
  WireTemplateType.IssuerAllocation,
  WireTemplateType.IssuerIndication,
  WireTemplateType.ManagerBillingAndDelivery,
  WireTemplateType.Payment,
  WireTemplateType.PotAccount,
  WireTemplateType.PotAllocation,
  WireTemplateType.PricingTerms,
  WireTemplateType.PricingTermsRetention,
  WireTemplateType.RegulationM,
  WireTemplateType.Retention,
  WireTemplateType.SellingGroupInvitationFo,
  WireTemplateType.SellingGroupInvitationIpo,
  WireTemplateType.SellingGroupTerms,
  WireTemplateType.Termination,
  WireTemplateType.UnderwriterInvitationFo,
  WireTemplateType.UnderwriterInvitationIpo,
];

export const NON_SYNDICATE_WIRE_TEMPLATES: WireTemplateType[] = [
  WireTemplateType.NonSyndicateMemberFreeForm,
  WireTemplateType.NonSyndicateMemberPayment,
  WireTemplateType.NonSyndicateMemberSellingGroupInvitationFo,
  WireTemplateType.NonSyndicateMemberSellingGroupInvitationIpo,
  WireTemplateType.NonSyndicateMemberSellingGroupTermsRetention,
  WireTemplateType.NonSyndicateMemberTermination,
];

export const useWireTemplateStatus = ({
  offeringId,
  canReadSyndicateWires,
  canReadNonSyndicateWires,
}: Options) => {
  const {
    data: syndicateWiresQueryData,
    loading: isSyndicateWiresQueryLoading,
    error: syndicateWiresQueryError,
  } = useSyndicateWires_WiresQuery({
    variables: {
      offeringId,
      isNonSyndicate: false,
      includeOnlyLatestWires: true,
      where: {
        templateType: {
          in: SYNDICATE_WIRE_TEMPLATES,
        },
      },
    },
    skip: !canReadSyndicateWires,
  });

  const {
    data: nonSyndicateWiresQueryData,
    loading: isNonSyndicateWiresQueryLoading,
    error: nonSyndicateWiresQueryError,
  } = useSyndicateWires_WiresQuery({
    variables: {
      offeringId,
      isNonSyndicate: true,
      includeOnlyLatestWires: true,
      where: {
        templateType: {
          in: NON_SYNDICATE_WIRE_TEMPLATES,
        },
      },
    },
    skip: !canReadNonSyndicateWires,
  });

  const mappedSyndicateWires = useMemo(() => {
    const result = new Map<WireTemplateType, Wire>();
    syndicateWiresQueryData?.wires.forEach(wire => result.set(wire.templateType, wire));
    return result;
  }, [syndicateWiresQueryData]);

  const mappedNonSyndicateWires = useMemo(() => {
    const result = new Map<WireTemplateType, Wire>();
    nonSyndicateWiresQueryData?.wires.forEach(wire => result.set(wire.templateType, wire));
    return result;
  }, [nonSyndicateWiresQueryData]);

  const getWireInfo = useCallback(
    (templateType: WireTemplateType) => {
      const isNonSyndicate = NON_SYNDICATE_WIRE_TEMPLATES.includes(templateType);

      const templateWire = isNonSyndicate
        ? mappedNonSyndicateWires.get(templateType)
        : mappedSyndicateWires.get(templateType);
      const isLoading = isNonSyndicate
        ? isNonSyndicateWiresQueryLoading
        : isSyndicateWiresQueryLoading;
      const isError = isNonSyndicate ? !!nonSyndicateWiresQueryError : !!syndicateWiresQueryError;
      return { wire: templateWire ?? null, isLoading, isError, isNonSyndicate };
    },
    [
      mappedSyndicateWires,
      mappedNonSyndicateWires,
      nonSyndicateWiresQueryError,
      syndicateWiresQueryError,
      isSyndicateWiresQueryLoading,
      isNonSyndicateWiresQueryLoading,
    ]
  );

  return {
    getWireInfo,
  };
};
