import { IconButton, numericUtil, Popover } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { ColDef } from 'ag-grid-community';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import React from 'react';

import type { FundItem } from '../../FundLevelDemand';
import type { Props } from './FundIoisGrid';
import {
  SActionButtons,
  SMoreLink,
  SPercentValue,
  SPinnedRowText,
  SPriceValue,
} from './FundIoisGrid.styles';
import { formatDemandItemValue } from './FundIoisGridColumn.model';

export const DemandItem = ({ ioi }: { ioi: FundItem['iois'][number] }) => {
  const value = formatDemandItemValue(ioi);
  const limit =
    ioi.pricingType === 'limit'
      ? numericUtil.formatCurrency(ioi.limitPrice)
      : startCase(ioi.pricingType);

  return <div style={{ whiteSpace: 'nowrap' }}>{`${value} @ ${limit}`}</div>;
};

export const DemandItemsTooltip = ({ iois }: { iois: FundItem['iois'] }) => (
  <Popover
    variant="TOOLTIP"
    placement="bottom"
    content={iois.map(ioi => (
      <DemandItem key={ioi.id} ioi={ioi} />
    ))}
  >
    <SMoreLink>{iois.length - 1} more...</SMoreLink>
  </Popover>
);

export const PriceValue = ({ value }: { value: number | null }) =>
  value ? <SPriceValue>{numericUtil.formatNumber(value, 0)}</SPriceValue> : null;

export const PercentValue = ({ value }: { value: number | null }) =>
  value ? <SPercentValue>{numericUtil.formatPercents(value)}</SPercentValue> : null;

export const fundIoisGridFields = {
  fund: 'fund',
  demand: 'demand',
  allocations: 'allocations',
  pctOfFirmAllocation: 'pctOfFirmAllocation',
  actions: 'actions',
} as const;

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal',
  lineHeight: 1.2,
};

const columnDefaults = {
  suppressColumnsToolPanel: true,
  sortable: true,
  resizable: true,
  flex: 1,
  cellStyle,
};

export const getColumns = ({
  onEditItem,
  onDeleteItem,
  canUpdate,
}: Pick<Props, 'onEditItem' | 'onDeleteItem'> & { canUpdate: boolean }): ColDef[] => [
  {
    ...columnDefaults,
    colId: fundIoisGridFields.fund,
    field: 'fund.name',
    headerName: 'Fund',
    pinnedRowCellRendererFramework: () => {
      return <SPinnedRowText>Total</SPinnedRowText>;
    },
    comparator: (valueA: string, valueB: string) => valueA.localeCompare(valueB),
  },
  {
    ...columnDefaults,
    colId: fundIoisGridFields.demand,
    field: 'iois',
    headerName: 'Demand',
    sortable: false,
    cellStyle: { ...cellStyle, overflow: 'visible', minWidth: '150px' },
    cellRendererFramework: ({ data }: { data: FundItem }) => {
      const iois = orderBy(data.iois, ['pricingType'], ['desc']);
      return (
        <React.Fragment>
          {iois[0] && <DemandItem ioi={iois[0]} />}
          {iois.slice(1).length ? <DemandItemsTooltip iois={iois} /> : null}
        </React.Fragment>
      );
    },
  },
  {
    ...columnDefaults,
    colId: fundIoisGridFields.allocations,
    field: 'allocation.shares',
    headerName: 'Allocation',
    cellClass: 'full-width-flex-child',
    cellRendererFramework: ({ value }: { value: number | null }) => <PriceValue value={value} />,
    pinnedRowCellRendererFramework: ({ value }: { value: number }) => (
      <SPinnedRowText>
        <PriceValue value={value} />
      </SPinnedRowText>
    ),
    comparator: (valueA: number | null, valueB: number | null) => (valueA ?? 0) - (valueB ?? 0),
  },
  {
    ...columnDefaults,
    colId: fundIoisGridFields.pctOfFirmAllocation,
    field: 'allocation.pctOfFirmAllocation',
    headerName: '% of Firm Allocation',
    cellClass: 'full-width-flex-child',
    cellRendererFramework: ({ value, data }: { value: number | null; data: FundItem }) => (
      <React.Fragment>
        <PercentValue value={value} />
        {canUpdate && (
          <SActionButtons>
            <IconButton
              icon={{ name: 'pencil-alt' }}
              onClick={() => onEditItem(data.fund.id)}
              testId={xcSelectors.fundLevelDemandEditFundItemButton.testId}
            />
            <IconButton
              icon={{ name: 'trash-alt' }}
              onClick={() => onDeleteItem(data.fund.id)}
              testId={xcSelectors.fundLevelDemandDeleteFundItemButton.testId}
            />
          </SActionButtons>
        )}
      </React.Fragment>
    ),
    pinnedRowCellRendererFramework: ({ value }: { value: number | null }) => (
      <SPinnedRowText>
        <PercentValue value={value} />
      </SPinnedRowText>
    ),
    comparator: (valueA: number | null, valueB: number | null) => (valueA ?? 0) - (valueB ?? 0),
  },
];
