import { useCallback, useState } from 'react';

import { offeringApiClient } from '../../../../common/api/offeringApiClient';

type UploadProspectusDocument = {
  upload: File;
  documentName: string;
  includeOnPublish: boolean;
};

type UpdateProspectusDocumentWithMetadata = {
  upload: File;
  documentName: string;
  includeOnPublish: boolean;
  id: string;
};

export const useProspectusDocumentsRestUpload = () => {
  const [loading, setLoading] = useState(false);

  const uploadMultipleProspectusDocumentsRest = useCallback(
    async ({
      prospectusDocuments,
      offeringId,
    }: {
      prospectusDocuments: readonly UploadProspectusDocument[];
      offeringId: string;
    }) => {
      setLoading(true);
      try {
        for (const document of prospectusDocuments) {
          await offeringApiClient.prospectus_UploadProspectusDocumentService(
            offeringId,
            { data: document.upload, fileName: document.upload.name },
            document.documentName,
            document.includeOnPublish
          );
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const updateProspectusDocumentsWithMetadataRest = useCallback(
    async ({
      prospectusDocuments,
      offeringId,
    }: {
      prospectusDocuments: readonly UpdateProspectusDocumentWithMetadata[];
      offeringId: string;
    }) => {
      setLoading(true);
      try {
        for (const document of prospectusDocuments) {
          await offeringApiClient.prospectus_UpdateProspectusDocumentMetadata(
            offeringId,
            document.id,
            {
              documentName: document.documentName,
              includeOnPublish: document.includeOnPublish,
            }
          );
          // documentName and includeOnPublish are actually ignored in the following request
          await offeringApiClient.prospectus_ReplaceProspectusDocumentService(
            offeringId,
            document.id,
            { data: document.upload, fileName: document.upload.name },
            document.documentName,
            document.includeOnPublish
          );
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    uploadMultipleProspectusDocumentsRest,
    updateProspectusDocumentsWithMetadataRest,
    loading,
  };
};
