import { Tag } from '@cmg/common';
import { isToday, parseISO } from 'date-fns';
import React from 'react';

type Props = {
  launchDate: string | null | undefined;
};

/**
 * TodayTag returns a TODAY tag when the provided launchDate is the same as today
 * when launchDate is not today null is returned
 */
const TodayTag: React.FC<Props> = ({ launchDate }) => {
  const today = React.useMemo(() => {
    if (!launchDate) {
      return false;
    }

    return isToday(parseISO(launchDate));
  }, [launchDate]);

  if (today) {
    return <Tag>Today</Tag>;
  }

  return null;
};

export default TodayTag;
