import { timeUtil } from '@cmg/common';
import orderBy from 'lodash/orderBy';
import React from 'react';

import { ComplianceProspectus_ProspectusDeliveryPartsFragment } from '../../graphql';
import { ExpandableGroup } from '../expandable-group/ExpandableGroup';
import { ProspectusDocument } from '../prospectus-document/ProspectusDocument';
import { SContent, SDocumentList } from './ProspectusHistory.styles';
import { ProspectusHistoryTable } from './ProspectusHistoryTable';

export type Props = {
  prospectus: ComplianceProspectus_ProspectusDeliveryPartsFragment;
  initialExpanded: boolean;
};

export const ProspectusHistory: React.FC<Props> = ({ prospectus, initialExpanded }) => {
  const [isExpanded, setExpanded] = React.useState(initialExpanded);
  const [latestPublishedDocument] = React.useMemo(
    () => orderBy(prospectus.documents, 'publishedAt', 'desc'),
    [prospectus.documents]
  );

  const groupSubTitle =
    'Published: ' + timeUtil.formatAsDisplayDateTime(latestPublishedDocument?.publishedAt);

  return (
    <ExpandableGroup
      isExpanded={isExpanded}
      title="Documents"
      subTitle={groupSubTitle}
      onChange={setExpanded}
    >
      <SContent>
        <SDocumentList>
          {prospectus.documents.map(document => (
            <ProspectusDocument {...document} key={document.url} />
          ))}
        </SDocumentList>
        <ProspectusHistoryTable revisions={prospectus.revisions} />
      </SContent>
    </ExpandableGroup>
  );
};
