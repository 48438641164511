import { SubNavigation } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import styled from 'styled-components/macro';

import routeFactory from '../../../../common/util/routeFactory';
import CreateNewOfferingButton from '../../../my-offerings/components/CreateNewOfferingButton';
import useShowFiledCalendar from '../../hooks/useShowFiledCalendar';

export const StyledCalendarNavbar = styled(SubNavigation)`
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.light}`};
  display: flex;
  justify-content: space-between;
`;

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  padding-bottom: 15px;
`;

const CalendarNavbar: React.FC = () => {
  const canViewFiledCalendar = useShowFiledCalendar();

  return (
    <StyledCalendarNavbar testId={xcSelectors.calendarNavBar.testId}>
      <SubNavigation.Group>
        <SubNavigation.Item>
          <StyledSubNavigationLink
            data-test-id={xcSelectors.calendarNavBarLive.testId}
            to={routeFactory.calendarLive.getUrlPath()}
          >
            Live
          </StyledSubNavigationLink>
        </SubNavigation.Item>
        <SubNavigation.Item>
          <StyledSubNavigationLink
            data-test-id={xcSelectors.calendarNavBarPriced.testId}
            to={routeFactory.calendarPriced.getUrlPath()}
          >
            Priced
          </StyledSubNavigationLink>
        </SubNavigation.Item>
        {canViewFiledCalendar && (
          <SubNavigation.Item>
            <StyledSubNavigationLink
              data-test-id={xcSelectors.calendarNavBarFiled.testId}
              to={routeFactory.calendarFiled.getUrlPath()}
            >
              Filed
            </StyledSubNavigationLink>
          </SubNavigation.Item>
        )}
        <SubNavigation.Item>
          <StyledSubNavigationLink
            data-test-id={xcSelectors.calendarNavBarPostponed.testId}
            to={routeFactory.calendarPostponed.getUrlPath()}
          >
            Postponed
          </StyledSubNavigationLink>
        </SubNavigation.Item>
      </SubNavigation.Group>
      <CreateNewOfferingButton />
    </StyledCalendarNavbar>
  );
};

export default CalendarNavbar;
