import { ToastManager } from '@cmg/common';
import { saveAs } from 'file-saver';
import React from 'react';

import { rolodexApiClient } from '../../../../../../../common/api/rolodexApiClient';

export function useDownloadCertificate(cmgEntityKey: string) {
  const [loading, setLoading] = React.useState(false);

  const handleDownloadCertificate = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await rolodexApiClient.attestationForm_DownloadCertificatePdf(cmgEntityKey);
      saveAs(response.data, '5130/31-Certificate.pdf');
      ToastManager.success('Download successfully completed.');
    } catch {
      ToastManager.error(
        'An error has occurred while trying to download your data. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [cmgEntityKey]);

  return { handleDownloadCertificate, loading };
}
