import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  OfferingSetup_DeleteDeliveryInstrumentDocument,
  OfferingSetup_DeleteDeliveryInstrumentMutation,
  OfferingSetup_DeleteDeliveryInstrumentMutationVariables,
} from '../graphql';

export const useDeleteDeliveryInstrumentMutation = (
  options: MutationHookOptions<
    OfferingSetup_DeleteDeliveryInstrumentMutation,
    OfferingSetup_DeleteDeliveryInstrumentMutationVariables
  >
) =>
  useMutation<
    OfferingSetup_DeleteDeliveryInstrumentMutation,
    OfferingSetup_DeleteDeliveryInstrumentMutationVariables
  >(OfferingSetup_DeleteDeliveryInstrumentDocument, options);
