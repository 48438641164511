import { MutationHookOptions } from '@apollo/client';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_UpdateDeliveryInstrumentDocument,
  OfferingSetup_UpdateDeliveryInstrumentMutation,
  OfferingSetup_UpdateDeliveryInstrumentMutationVariables,
} from '../graphql';

export const useUpdateDeliveryInstrumentMutation = (
  options: MutationHookOptions<
    OfferingSetup_UpdateDeliveryInstrumentMutation,
    OfferingSetup_UpdateDeliveryInstrumentMutationVariables
  >
) =>
  useServiceMutation<
    OfferingSetup_UpdateDeliveryInstrumentMutation,
    OfferingSetup_UpdateDeliveryInstrumentMutationVariables
  >(OfferingSetup_UpdateDeliveryInstrumentDocument, options);
