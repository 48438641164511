import { Box, Button, ShareIcon, Tooltip } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';

export type Props = {
  offeringId: string;
  onClick: () => void;
  disabled: boolean;
};

const ShareOfferingDraftButton: React.FC<Props> = ({ offeringId, onClick, disabled = false }) => (
  <Tooltip
    title={
      disabled ? (
        <Box
          sx={{
            width: 200,
            fontSize: 14 /* TODO: remove fontSize override once fixed in design-system */,
          }}
        >
          In order to share an Offering Draft, you should add Managers to the Offering. You can go
          ahead and add{' '}
          <Link
            to={routeFactory.offeringSetup.getUrlPath({
              offeringId: offeringId,
              stepId: 'managers',
            })}
          >
            Managers here
          </Link>
        </Box>
      ) : (
        ''
      )
    }
    variant="info"
  >
    <Box display="flex">
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
        data-testid={xcSelectors.offeringSetupSummaryShareDraftButton.testId}
        startIcon={<ShareIcon />}
      >
        Share Draft
      </Button>
    </Box>
  </Tooltip>
);

export default ShareOfferingDraftButton;
