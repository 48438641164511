import { getOperationName } from '@apollo/client/utilities';

import { OrderBook_MyRetailDemandDocument } from '../../../../graphql';
import { useOrderBook_SubmitRetailDemandMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchMyRetailQuery = getOperationName(OrderBook_MyRetailDemandDocument);

  return [refetchMyRetailQuery!];
};

export const useSubmitRetailDemandMutation = () => {
  return useOrderBook_SubmitRetailDemandMutation({ refetchQueries, awaitRefetchQueries: true });
};
