import React, { ComponentProps } from 'react';
import styled from 'styled-components/macro';

import { LoadingIndicator } from '../loading-indicator/LoadingIndicator';

export const SLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

type Props = {
  className?: string;
  size?: ComponentProps<typeof LoadingIndicator>['size'];
};

const Loading: React.FC<Props> = ({ className, size = 'medium' }) => {
  return (
    <SLoadingWrapper className={className}>
      <LoadingIndicator size={size} />
    </SLoadingWrapper>
  );
};

export default Loading;
