import { validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { phoneNumberSchema } from '../../../../../common/util/validation';
import {
  DealTeamMemberStatus,
  DealTeamMemberType,
  UpdateDealTeamInput,
} from '../../../../../graphql';
import { Roadshows_DealTeamPartsFragment } from '../../../common/graphql';

export type DealTeamFormValues = {
  departments: readonly string[];
  dealTeamMembers: DealTeamMember[];
};

export type DealTeamMember = {
  id: string | null;
  name: string;
  email: string;
  officePhone: string | null | undefined;
  mobilePhone: string | null | undefined;
  department: string | null | undefined;
  type: DealTeamMemberType;
  status: DealTeamMemberStatus | null;
};

export const initialDealTeamMember: DealTeamMember = {
  id: null,
  name: '',
  email: '',
  mobilePhone: null,
  officePhone: null,
  department: null,
  type: DealTeamMemberType.Manual,
  status: null,
};

export function findDuplicates(array: readonly string[]) {
  return array.filter((item, index) => array.indexOf(item) !== index);
}

export async function validate<TValues extends DealTeamFormValues>(
  values: TValues,
  schema: Yup.AnySchema
) {
  try {
    await validateYupSchema(values, schema, false, {
      duplicateDepartments: findDuplicates(values.departments),
      duplicateEmails: findDuplicates(values.dealTeamMembers.map(member => member.email)),
    });
    return {};
  } catch (err) {
    return yupToFormErrors(err);
  }
}

export const departmentSchema = Yup.string()
  .label('Department')
  .required()
  .when('$duplicateDepartments', ([duplicateDepartments], schema) =>
    schema.notOneOf(duplicateDepartments, 'Department name must be unique')
  );

export const dealTeamMemberSchema = Yup.object().shape({
  name: Yup.string().required().label('Name'),
  email: Yup.string()
    .email('Email must be a valid email address')
    .required()
    .label('Email')
    .when('$duplicateEmails', ([duplicateEmails], schema) =>
      schema.notOneOf(duplicateEmails, 'Email must be unique')
    ),
  mobilePhone: phoneNumberSchema.nullable().label('Mobile Phone'),
  officePhone: phoneNumberSchema.nullable().label('Office Phone'),
  department: Yup.string().nullable().label('Department'),
});

export const dealTeamSchema = Yup.object().shape({
  departments: Yup.array().of(departmentSchema),
  dealTeamMembers: Yup.array()
    .of(dealTeamMemberSchema)
    .min(1, 'At least 1 deal team member is required'),
});

export function getPayload(form: DealTeamFormValues): UpdateDealTeamInput {
  return {
    departments: form.departments,
    members: form.dealTeamMembers.map(item => ({
      id: item.id,
      email: item.email,
      department: item.department,
      name: item.name,
      officePhone: item.officePhone || null,
      mobilePhone: item.mobilePhone || null,
      type: item.type,
    })),
  };
}

export function valuesToForm(values?: Roadshows_DealTeamPartsFragment): DealTeamFormValues {
  if (values) {
    return {
      departments: values.departments,
      dealTeamMembers: values.members.map(item => ({
        id: item.id,
        email: item.email,
        department: item.department,
        name: item.name,
        officePhone: item.officePhone,
        mobilePhone: item.mobilePhone,
        type: item.type,
        status: item.status,
      })),
    };
  } else {
    return {
      departments: [],
      dealTeamMembers: [],
    };
  }
}
