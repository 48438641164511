import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import { InvestorFirmOption } from '../../../../common/components/form/investor-firm-search/InvestorFirmSearch';
import {
  SInvestorSearch,
  SInvestorSearchLabel,
  StyledInvestorSelect,
} from './InvestorSearch.styles';

export type Props = {
  value: InvestorFirmOption | null;
  loading?: boolean;
  onChange: (option: InvestorFirmOption | null) => void;
  isCoveredAccount: boolean;
};
/**
 * InvestorSearch is a wrapper around the InvestorFirmSearch that displays an Investor label
 * and styles
 */
const InvestorSearch: React.FC<Props> = ({ value, loading, onChange, isCoveredAccount }) => {
  const canCreateInvestor = useCheckPermissions(
    [
      permissionsByEntity.CRM.READ,
      permissionsByEntity.Offering.FULL,
      permissionsByEntity.PublishedOffering.FULL,
      permissionsByEntity.CrmBasicInvestorFirm.FULL,
    ],
    false
  );

  return (
    <SInvestorSearch>
      <SInvestorSearchLabel>Investor</SInvestorSearchLabel>
      <StyledInvestorSelect
        value={value}
        isLoading={loading}
        onChange={onChange}
        styledConfig={{
          control: styles => ({
            ...styles,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }),
        }}
        showCreateNewInvestor={canCreateInvestor}
        fallbackToFirmKey
        isCoveredAccount={isCoveredAccount}
      />
    </SInvestorSearch>
  );
};

export default InvestorSearch;
