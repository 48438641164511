import { UUID } from '@cmg/common';
import { SelectOption } from '@cmg/design-system-formik';
import isNil from 'lodash/isNil';

import {
  AllocationAcknowledgement,
  IndicationStatus,
  InstitutionalIndicationOrderType,
  MdlAllocationRanking,
} from '../../../../../../graphql';
import { IndicationActivity_FinalAllocationPartsFragment } from './graphql/__generated__';

export type IndicationType = {
  id: UUID;
  offeringId: UUID;
  type: InstitutionalIndicationOrderType;
  status: IndicationStatus;
  allocationVersion?: string | null;
  allocationShares?: number | null;
  investorReplyStatus?: AllocationAcknowledgement | null;
};

export const canAcknowledgeAllocation = (
  canBillingAndDeliveryAgentUpdateInstitutionalIndication: boolean,
  canUserFirmUpdateInstitutionalIndication: boolean,
  oidcUserCmgEntityKey: string | null,
  billingAndDeliveryAgentCmgEntityKey: string | null | undefined
) => {
  if (
    canBillingAndDeliveryAgentUpdateInstitutionalIndication &&
    oidcUserCmgEntityKey === billingAndDeliveryAgentCmgEntityKey
  ) {
    return true;
  } else {
    return (
      !canBillingAndDeliveryAgentUpdateInstitutionalIndication &&
      canUserFirmUpdateInstitutionalIndication
    );
  }
};

type IsAllocationReleasedProps = {
  isSellSideAccount: boolean;
  allocationShares: number | null | undefined;
  allocation: IndicationActivity_FinalAllocationPartsFragment | null | undefined;
  isVisibleToNonSyndicate: boolean;
  isReleased: boolean;
};

export const getIsAllocationReleased = ({
  allocationShares,
  isSellSideAccount,
  allocation,
  isVisibleToNonSyndicate,
  isReleased,
}: IsAllocationReleasedProps) => {
  if (!isNil(allocationShares)) {
    return true;
  }

  if (isVisibleToNonSyndicate && isReleased) {
    return true;
  }

  return !isSellSideAccount && !isNil(allocation);
};

export const rankingLabels = {
  [MdlAllocationRanking.Top_5]: 'Top 5',
  [MdlAllocationRanking.Top_10]: 'Top 10',
  [MdlAllocationRanking.Top_15]: 'Top 15',
  [MdlAllocationRanking.Top_25]: 'Top 25',
  [MdlAllocationRanking.Top_50]: 'Top 50',
  [MdlAllocationRanking.Over_50]: 'Over 50',
  [MdlAllocationRanking.NotIndicated]: 'Not Indicated',
} as const;

export const getRankingOptions = (): SelectOption<MdlAllocationRanking>[] => {
  return [
    MdlAllocationRanking.Top_5,
    MdlAllocationRanking.Top_10,
    MdlAllocationRanking.Top_15,
    MdlAllocationRanking.Top_25,
    MdlAllocationRanking.Top_50,
    MdlAllocationRanking.Over_50,
    MdlAllocationRanking.NotIndicated,
  ].map(rank => ({ value: rank, label: rankingLabels[rank] }));
};
