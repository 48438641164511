import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.designSystem.colors.gray[100]};
  align-items: baseline;
`;

export const SSubNavigationWrapper = styled.div`
  flex: auto;
`;

export const SIconWrapper = styled.span`
  padding: 0 8px;
`;
