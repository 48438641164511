import { ISODate, timeUtil } from '@cmg/common';

import { previousTwoWeeks, yearFromDate, yearToDateStart } from '../calendar.model';

type DateBoundaries = { start: ISODate; end: ISODate };

export function getPostponedOfferingsDateBoundaries(): DateBoundaries {
  return {
    start: timeUtil.formatAsISODate(yearToDateStart(new Date()))!,
    end: timeUtil.formatAsISODate(yearFromDate(new Date()))!,
  };
}

export function getPricedOfferingsDateBoundaries(): DateBoundaries {
  return {
    start: timeUtil.formatAsISODate(previousTwoWeeks(new Date()))!,
    end: timeUtil.formatAsISODate(yearFromDate(new Date()))!,
  };
}
