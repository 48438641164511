import { UUID } from '@cmg/common';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import { useIsUserSettlementAgent } from '../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import routeFactory from '../../../common/util/routeFactory';
import { OrderBookPageLayout } from '../components/order-book-page-layout/OrderBookPageLayout';
import { useOrderBook_TotalRetailDemandQuery } from './graphql';
import TotalRetailDemandPageLayout from './page-layout/TotalRetailDemandPageLayout';
import TotalRetailDemandSkeleton from './skeleton/TotalRetailDemandSkeleton';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = Readonly<
  {
    issuerName?: string;
  } & RouteProps
>;

const TotalRetailDemand: React.VFC<Props> = ({ issuerName, match }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.orderBookTotalRetailDemand.getDocumentTitle({
      issuerName,
    })
  );

  const {
    isSettlementAgent,
    error: settlementAgentError,
    loading: settlementAgentLoading,
  } = useIsUserSettlementAgent({ offeringId });
  const {
    data,
    loading: retailLoading,
    error: retailError,
  } = useOrderBook_TotalRetailDemandQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  if (settlementAgentLoading) {
    return (
      <OrderBookPageLayout subNavigationProps={{ offeringId }}>
        <Loading />
      </OrderBookPageLayout>
    );
  }

  const error = settlementAgentError || retailError;
  if (error) {
    return (
      <OrderBookPageLayout subNavigationProps={{ offeringId }}>
        <ServerErrorsBanner error={error} />
      </OrderBookPageLayout>
    );
  }

  if (!isSettlementAgent) {
    return <Redirect to={routeFactory.orderBook.getUrlPath({ offeringId })} />;
  }

  if (retailLoading) {
    return (
      <OrderBookPageLayout subNavigationProps={{ offeringId }}>
        <TotalRetailDemandSkeleton />
      </OrderBookPageLayout>
    );
  }

  return (
    <OrderBookPageLayout subNavigationProps={{ offeringId }}>
      <TotalRetailDemandPageLayout
        offeringId={offeringId}
        offeringIssuerName={issuerName ?? 'Issuer'}
        data={data}
      />
    </OrderBookPageLayout>
  );
};

export default TotalRetailDemand;
