import { permissionsByEntity } from '@cmg/auth';
import { loggerUtil, UUID } from '@cmg/common';
import merge from 'lodash/merge';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import NotAccessible from '../../common/components/layout/not-accessible/NotAccessible';
import { PrivateRoute } from '../../common/routing/private-route/PrivateRoute';
import useCheckOrderBookAccess from '../../common/util/check-access/useCheckOrderBookAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import routeFactory from '../../common/util/routeFactory';
import { NoAccessRedirect } from '../core/RootRouter';
import OrderBookNotOpen from './components/order-book-not-open/OrderBookNotOpen';
import DspDemandRoute from './dsp-demand/DspDemandRoute';
import {
  useOrderBook_OrderBookRouteQuery,
  useWithOrderBook_OrderBookStatusChangedSubscription,
} from './graphql';
import { useDemandGridV2FeaturePreference } from './hooks/useDemandGridV2FeaturePreference';
import InstitutionalDemandRoute from './institutional-demand/InstitutionalDemandRoute';
import { InstitutionalDemandRoute as InstitutionalDemandV2Route } from './institutional-demand-v2/InstitutionalDemandRoute';
import MyRetailDemand from './my-retail-demand/MyRetailDemand';
import TotalRetailDemand from './total-retail-demand/TotalRetailDemand';

const MixpanelModuleContextProvider = loggerUtil.MixpanelModuleContext.Provider;

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = { issuerName?: string } & RouteProps;

/**
 * Primary Route for the Order Book module
 */
export const OrderBookRoute: React.FC<Props> = ({ match, issuerName }) => {
  const { offeringId } = match.params;

  const demandGridV2Feature = useDemandGridV2FeaturePreference();

  // no point in displaying an Order Book without Indications - require access to both
  // (this might change once more than the Institutional Demand section get implemented)
  const { canRead: canReadOrderBook } = useCheckOrderBookAccess({
    offeringId,
  });

  const { canRead: canReadIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });

  let queryResult = useOrderBook_OrderBookRouteQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  queryResult = useWithOrderBook_OrderBookStatusChangedSubscription(queryResult, {
    variables: () => ({
      offeringId,
    }),
    updateQuery:
      () =>
      (prev, { subscriptionData }) =>
        merge({}, prev, {
          publishedOffering: { orderBook: subscriptionData.data.orderBookStatusChanged },
        }),
  });

  const { data } = queryResult;

  const error = queryResult.error;

  if (!canReadOrderBook || !canReadIndications) {
    return <NotAccessible subject="Order Book" />;
  }

  if (data?.publishedOffering?.orderBook.state === 'NEVER_OPENED') {
    return <OrderBookNotOpen issuerName={data.publishedOffering.issuer.name} />;
  }

  return (
    <MixpanelModuleContextProvider value={{ moduleName: 'order-book' }}>
      <Switch>
        <PrivateRoute
          path={routeFactory.orderBookInstitutionalDemand.routePath}
          render={routeProps => {
            if (demandGridV2Feature.isPreferenceOn) {
              return (
                <InstitutionalDemandV2Route
                  {...routeProps}
                  offeringId={offeringId}
                  issuerName={issuerName}
                  error={error}
                  offeringStatus={data?.publishedOffering?.status!}
                  offeringType={data?.publishedOffering?.type!}
                  orderBookState={data?.publishedOffering?.orderBook.state}
                />
              );
            }

            return (
              <InstitutionalDemandRoute
                {...routeProps}
                offeringId={offeringId}
                issuerName={issuerName}
                offeringStatus={data?.publishedOffering?.status}
                orderBookState={data?.publishedOffering?.orderBook.state}
                isFinalAllocationSetReleased={!!data?.finalInstitutionalAllocationSet?.isReleased}
                isVisibleToNonSyndicate={
                  !!data?.finalInstitutionalAllocationSet?.isVisibleToNonSyndicate
                }
              />
            );
          }}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <PrivateRoute
          path={routeFactory.orderBookTotalRetailDemand.routePath}
          render={routeProps => (
            <TotalRetailDemand {...(routeProps as RouteProps)} issuerName={issuerName} />
          )}
          requiredPermissions={[
            permissionsByEntity.RetailDemand.READ,
            permissionsByEntity.RetailRetention.READ,
          ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <PrivateRoute
          path={routeFactory.orderBookMyRetailDemand.routePath}
          render={routeProps => (
            <MyRetailDemand {...(routeProps as RouteProps)} issuerName={issuerName} />
          )}
          requiredPermissions={[
            permissionsByEntity.RetailDemand.READ,
            permissionsByEntity.RetailRetention.READ,
          ]}
          renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        />
        <Route path={routeFactory.orderBookDspDemand.routePath} component={DspDemandRoute} />
        <Route
          path={routeFactory.orderBook.routePath}
          render={() => (
            <Redirect
              to={routeFactory.orderBookInstitutionalDemand.getUrlPath({
                offeringId,
              })}
            />
          )}
        />
      </Switch>
    </MixpanelModuleContextProvider>
  );
};

export default OrderBookRoute;
