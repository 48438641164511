import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@cmg/design-system';
import React from 'react';

import { OrderBookState } from '../../../../graphql';

export type Props = Readonly<{
  onUpdateOrderBookStatus: () => void;
  orderBookState: OrderBookState | undefined;
  onClose: () => void;
  open: boolean;
}>;

export const UpdateOrderBookStatusModal: React.FC<Props> = ({
  onUpdateOrderBookStatus,
  orderBookState = OrderBookState.Closed,
  onClose,
  open,
}) => {
  const handleOnUpdateOrderBook = () => {
    onUpdateOrderBookStatus();
    onClose();
  };

  const bodyText = `Are you sure you want to ${
    orderBookState === OrderBookState.Open ? 'close' : 'open'
  } the order book? Institutional investors and sales users will ${
    orderBookState === OrderBookState.Open ? 'not' : ''
  } be able to submit indications.`;
  const modalTitle =
    orderBookState === OrderBookState.Open ? 'Close Order Book' : 'Reopen Order Book';
  const buttonText =
    orderBookState === OrderBookState.Open ? 'Close Order Book' : 'Reopen Order Book';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle> {modalTitle} </DialogTitle>
      <DialogContent>{bodyText}</DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleOnUpdateOrderBook}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
