import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { Box, Button, LoadingButton, Table, TableBody, TableContainer } from '@cmg/design-system';
import { useIsFormAlertVisible } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { getFormikErrorFieldLabels } from '../../../../../../../common/util/useFormikErrors.model';
import ExportButton from '../../../../../components/export-button/ExportButton';
import { useExportContext } from '../../../../../components/export-button/ExportContext';
import { BrokerValues } from '../../../auto-save/table-row/InternalRetailDemandTableRow.model';
import InternalRetailDemand from '../../../common/internal-retail-demand/InternalRetailDemand';
import InternalRetailDemandTableHeader from '../../../common/table-header/InternalRetailDemandTableHeader';
import InternalRetailDemandTableTotalRow from '../../../common/table-total-row/InternalRetailDemandTableTotalRow';
import {
  getValidationSchema,
  MyRetailDemandBulkSaveValues,
} from '../BulkEditInternalRetailDemandView.model';
import { calculateEditTotals } from './BulkEditInternalRetailDemandForm.model';
import InternalRetailDemandEditTableRow from './edit-row/InternalRetailDemandEditTableRow';

export type Props = Readonly<{
  onAddValue: (value: BrokerValues) => void;
  onRemoveValue: (index: number) => void;
  onDiscardChanges: () => void;
  isUpdating: boolean;
  sellingConcession: number | null | undefined;
}>;

const BulkEditInternalRetailDemandForm: React.FC<Props> = ({
  onAddValue,
  onRemoveValue,
  onDiscardChanges,
  isUpdating,
  sellingConcession,
}) => {
  const { isExporting, onExport } = useExportContext();

  const { isValid, values, handleSubmit, errors } =
    useFormikContext<MyRetailDemandBulkSaveValues>();
  const isNoDemandAlertVisible = useIsFormAlertVisible(values.brokers.length === 0);
  const isValuesNotValidAlertVisible = useIsFormAlertVisible(!isValid && values.brokers.length > 0);

  const totals = React.useMemo(
    () => calculateEditTotals(values, sellingConcession),
    [values, sellingConcession]
  );
  const invalidFields = React.useMemo(
    () => getFormikErrorFieldLabels(errors, getValidationSchema(values.brokers)).join(', '),
    [errors, values.brokers]
  );

  const handleAddDemand = React.useCallback(() => {
    onAddValue({
      broker: null,
      id: null,
      demandShares: null,
      demandRoundLots: null,
      retentionShares: null,
      retentionRoundLots: null,
      sellingConcessionPercentage: null,
    });
  }, [onAddValue]);

  return (
    <InternalRetailDemand
      pageHeaderActions={[
        <ExportButton key="export-buttonb" loading={isExporting} onExport={onExport} />,
        <AccessControl
          key="crud-buttons"
          requiredPermissions={[
            permissionsByEntity.RetailDemand.FULL,
            permissionsByEntity.RetailRetention.FULL,
          ]}
          requireAllPermissions={false}
        >
          <Button variant="outlined" disabled={isUpdating} onClick={onDiscardChanges}>
            Discard Changes
          </Button>

          <LoadingButton variant="contained" loading={isUpdating} onClick={() => handleSubmit()}>
            Save
          </LoadingButton>
        </AccessControl>,
      ]}
      onAddDemand={handleAddDemand}
      isValuesNotValidAlertVisible={isValuesNotValidAlertVisible}
      invalidFields={invalidFields}
      isNoDemandAlertVisible={isNoDemandAlertVisible}
      isEmptyStateVisible={values.brokers.length === 0}
    >
      <Box overflow="auto" data-testid="bulk-edit-view">
        <TableContainer>
          <Table>
            <InternalRetailDemandTableHeader isReadOnly={false} />

            <TableBody>
              <InternalRetailDemandTableTotalRow {...totals} isReadOnly={false} />

              {values.brokers.map((value, index) => (
                <InternalRetailDemandEditTableRow
                  key={index}
                  onRemoveRow={() => onRemoveValue(index)}
                  sellingConcession={sellingConcession}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </InternalRetailDemand>
  );
};

export default BulkEditInternalRetailDemandForm;
