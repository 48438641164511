import { numericUtil } from '@cmg/common';
import capitalize from 'lodash/capitalize';
import React from 'react';

import {
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
  OfferingType,
} from '../../../../graphql';
import { InterestLevelFormValues } from '../interest-levels-wrapper/InterestLevelsWrapper.model';
import { limitTypeOptions } from './LimitPriceInput.model';
import {
  StyledLimitPriceContainer,
  StyledLimitPriceCurrencyInputField,
  StyledLimitPricePercentInputField,
  StyledLimitPriceSelectField,
} from './LimitPriceInput.styles';

type Props = {
  interestLevel: InterestLevelFormValues<
    MdlIndicationUnitType,
    MdlIndicationPricingType,
    MdlIndicationLimitType
  >;
  offeringType: OfferingType;
  isEditing?: boolean;
  isSubmitting?: boolean;
  baseName: string;
  handleChangeLimitType: (limitType: MdlIndicationLimitType) => void;
};

const LimitPriceInput: React.FC<Props> = ({
  interestLevel,
  offeringType,
  isEditing = false,
  isSubmitting = false,
  baseName,
  handleChangeLimitType,
}) => {
  if (interestLevel.pricingType !== MdlIndicationPricingType.Limit) {
    return null;
  }

  if (!isEditing) {
    return (
      <StyledLimitPriceContainer>
        {interestLevel.limitType && `${capitalize(interestLevel.limitType)} `}
        {interestLevel.limitPrice && numericUtil.formatCurrency(interestLevel.limitPrice)}
        {interestLevel.limitPercentage &&
          numericUtil.formatPercents(interestLevel.limitPercentage, 0)}
      </StyledLimitPriceContainer>
    );
  }

  const isIPOOfferingType =
    offeringType === OfferingType.Ipo || offeringType === OfferingType.IpoSpac;

  const baseProps = {
    disabled: isSubmitting,
    fullWidth: true,
    required: true,
  };

  return (
    <StyledLimitPriceContainer>
      <StyledLimitPriceSelectField
        name={`${baseName}.limitType`}
        placeholder="Select..."
        disabled={isSubmitting}
        options={limitTypeOptions.filter(option => {
          // Only show the "PRICE" limit type option if the offering type is IPO
          return (
            option.value !== MdlIndicationLimitType.Null &&
            (!isIPOOfferingType || option.value === MdlIndicationLimitType.Price)
          );
        })}
        fullWidth
        required
        isClearable={false}
        onChange={handleChangeLimitType}
      />
      {interestLevel.limitType === MdlIndicationLimitType.Price ? (
        <StyledLimitPriceCurrencyInputField {...baseProps} name={`${baseName}.limitPrice`} />
      ) : (
        <StyledLimitPricePercentInputField name={`${baseName}.limitPercentage`} precision={0} />
      )}
    </StyledLimitPriceContainer>
  );
};

export default LimitPriceInput;
