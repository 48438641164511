import styled from 'styled-components/macro';

import Banner from '../../../common/components/indicators/banner/Banner';

export const StyledBanner = styled(Banner)`
  margin: 0;
`;

export const SCreateNewOfferingBannerContentContainer = styled.div`
  margin-left: 8px;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SCreateNewOfferingBannerTitle = styled.h4`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin-top: 0;
  margin-bottom: 12px;
`;

export const SCreateNewOfferingBannerContent = styled.p`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
  margin: 0;
  line-height: 16px;
`;
