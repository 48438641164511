import { useIsOfferingAuthor } from '../../../../../common/hooks/useIsOfferingAuthor';
import {
  useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery,
  useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery,
} from '../graphql';

export function useSyndicateWiresRouteQuery(offeringId: string) {
  const isOfferingAuthor = useIsOfferingAuthor(offeringId);
  const useGraphQLQuery = isOfferingAuthor
    ? useSyndicateWires_SyndicateWiresRoute_IsAuthorQuery
    : useSyndicateWires_SyndicateWiresRoute_NotAuthorQuery;

  const queryResult = useGraphQLQuery({
    variables: { offeringId },
  });

  const offeringType = queryResult.data?.offering?.type!;

  return {
    ...queryResult,
    data: { offeringType },
  };
}
