import { FlexContainer } from '@cmg/common';
import React from 'react';

import Banner from '../../../../common/components/indicators/banner/Banner';
import { SBannerTitle } from './MissingEconomicsInfoBanner.styles';

const MissingEconomicsInfoBanner: React.FC = () => {
  return (
    <Banner
      variant="information"
      testId="Settlement / Designation Monitor / Missing Economics Infor Banner"
    >
      <FlexContainer direction="column" gap={12}>
        <SBannerTitle>Apply Fixed Economics</SBannerTitle>
        <div>
          Select all investors and 'Apply Fixed Economics' in order to calculate final pot economics
          and designations. (You will be able to edit designations to overwrite fixed economics)
        </div>
      </FlexContainer>
    </Banner>
  );
};

export default MissingEconomicsInfoBanner;
