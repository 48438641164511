import { ISODate } from '@cmg/common';
import { utcToZonedTime } from 'date-fns-tz';
import { validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { phoneNumberSchema } from '../../../../../common/util/validation';
import { Affiliate, OfferingType } from '../../../../../graphql';
import {
  RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment,
  RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment,
  RegulatoryFilings_RolodexUnderwriterFirmPartsFragment,
} from '../../../graphql';
import { AffiliatesAndDistributionParticipants } from '../../regulatory-filings.types';

export const dateTimeSchema = Yup.string().transform(
  (_, fieldValue: { date: ISODate; timezone: string } | null) => {
    return fieldValue?.date
      ? utcToZonedTime(fieldValue.date, fieldValue.timezone).toISOString()
      : undefined;
  }
);

const firmMpidPattern = /^([A-Z0-9]{4})$/;
const firmMpidInvalidPatternMessage =
  // eslint-disable-next-line no-template-curly-in-string
  '${path} must be a 4 characters long alphanumeric upper-cased string';

export const firmMpidSchema = Yup.string()
  .required()
  .matches(firmMpidPattern, firmMpidInvalidPatternMessage);

export const firmMpidNullableSchema = Yup.string().matches(
  firmMpidPattern,
  firmMpidInvalidPatternMessage
);

// eslint-disable-next-line no-template-curly-in-string
export const firmCrdSchema = Yup.string().matches(/^\d*$/, '${path} must be a number');

export type CommonFields = {
  firmName: string;
  firmCrdNumber: string;
  firmMpid: string;
  contactName: string;
  contactTitle: string;
  telephoneNumber: string;
  emailAddress: string;
  issuerName: string;
  issuerSymbol: string | null;
};

export type InitialFilingValuesParameters<TFiling> = {
  underwriter?: RegulatoryFilings_RolodexUnderwriterFirmPartsFragment;
  lastCommonValues?: RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment | null;
  filing?: TFiling;
  offeringDetail?: RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment;
};

export type InitialFilingExtendedValuesParameters<TFiling> =
  InitialFilingValuesParameters<TFiling> & {
    offeringType?: OfferingType;
    affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants;
  };

export type AffiliateWithOwnerCmgEntityKey = Affiliate & {
  ownerCmgEntityKey: string | null;
};

export const commonFilingSchemaShape = {
  firmName: Yup.string().label('Firm Name').max(1000).required(),
  firmCrdNumber: firmCrdSchema.label('Firm CRD').required(),
  firmMpid: firmMpidSchema.label('Firm MPID'),
  contactName: Yup.string().label('Contact Name').max(200).required(),
  contactTitle: Yup.string().label('Contact Title').max(200).required(),
  telephoneNumber: phoneNumberSchema.label('Telephone Number').required(),
  emailAddress: Yup.string().label('Email Address').email().max(200).required(),
  issuerName: Yup.string().label('Issuer Name').required(),
  issuerSymbol: Yup.string().label('Issuer Symbol').nullable().max(6).required(),
};

/**
 * Creates initial values for common part of regulatory filings
 */
export function createInitialCommonFilingValues({
  underwriter,
  lastCommonValues,
  offeringDetail,
}: {
  underwriter?: RegulatoryFilings_RolodexUnderwriterFirmPartsFragment;
  lastCommonValues?: RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment | null;
  offeringDetail?: RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment;
}): CommonFields {
  return {
    firmName: underwriter?.displayName ?? '',
    firmCrdNumber: underwriter?.crd ?? '',
    firmMpid: underwriter?.mpid ?? '',
    contactName: lastCommonValues?.contactName ?? '',
    contactTitle: lastCommonValues?.contactTitle ?? '',
    telephoneNumber: lastCommonValues?.telephoneNumber ?? '',
    emailAddress: lastCommonValues?.emailAddress ?? '',
    issuerSymbol: offeringDetail?.pricingInstrumentSymbol ?? lastCommonValues?.issuerSymbol ?? null,
    issuerName: offeringDetail?.issuerName ?? '',
  };
}

export const getValueOrNull = (value: string | null | undefined) => {
  return value === '' ? null : value;
};

type SyndicateManagerFinraMemberFirm = {
  cmgEntityKey: string;
  affiliateMemberId: null;
};

type AffiliateFinraMemberFirm = {
  cmgEntityKey: null;
  affiliateMemberId: string;
};

export type WithFirmOrOwnerCmgEntityKey = {
  ownerCmgEntityKey?: string | null;
  cmgEntityKey?: string | null;
};

export type FinraMemberFirm = ({
  firmName: string;
  firmCrdNumber: string;
  firmMpid: string | null | undefined;
} & WithFirmOrOwnerCmgEntityKey) &
  (SyndicateManagerFinraMemberFirm | AffiliateFinraMemberFirm);

export type FinraMemberFirmForm = FinraMemberFirm & {
  formIndex?: number; // TODO: should be required when removing isOfferingRegMRolodexAffiliatesOn FF
};

export type FinraNonMemberFirm = {
  firmName: string;
} & WithFirmOrOwnerCmgEntityKey;

export const getOtherBasisDescription = (offeringType?: OfferingType) => {
  if (offeringType === OfferingType.Ipo) {
    return 'IPO';
  }

  if (offeringType === OfferingType.FollowOn) {
    return 'FO';
  }

  return '';
};

export const validateFilingWithFinraMembersContext =
  (formSchema: Yup.ObjectSchema<Yup.AnyObject>) =>
  async <TValues extends { finraMemberFirms: { firmCrdNumber: string }[] }>(values: TValues) => {
    const crdNumbers = values.finraMemberFirms.map(({ firmCrdNumber }) => firmCrdNumber);

    try {
      await validateYupSchema(values, formSchema, false, { crdNumbers });
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  };
