import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { Form } from 'formik';
import React from 'react';

import { HighlightPanel } from '../../../../../../../../common/components/presentation';
import { FinalSettlement_InvestorDesignationPartsFragment } from '../../../graphql/__generated__';
import {
  SDesignationToolbarLabel,
  SSection,
  StyledDesignationToolbar,
  StyledDesignationToolbarGroup,
} from '../../OverwriteDesignationSidePanel.styles';
import DesignationTable from './designation-table/DesignationTable';

export type Props = {
  designation: FinalSettlement_InvestorDesignationPartsFragment;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  isValid: boolean;
};

export const OverwriteDesignationForm: React.FC<Props> = ({
  designation,
  onCancel,
  onSubmit,
  isValid,
}) => {
  const canEditDesignation = useCheckPermissions([permissionsByEntity.Designation.FULL]);

  return (
    <Form>
      <SSection>
        <HighlightPanel>
          <StyledDesignationToolbar>
            <StyledDesignationToolbarGroup>
              <SDesignationToolbarLabel>Designation</SDesignationToolbarLabel>
            </StyledDesignationToolbarGroup>
            {canEditDesignation && (
              <StyledDesignationToolbarGroup>
                <ButtonsContainer>
                  <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  <PrimaryButton
                    testId="submit-overwrite-form"
                    disabled={!isValid}
                    onClick={onSubmit}
                  >
                    Save
                  </PrimaryButton>
                </ButtonsContainer>
              </StyledDesignationToolbarGroup>
            )}
          </StyledDesignationToolbar>
        </HighlightPanel>
      </SSection>
      <SSection>
        <DesignationTable
          canEdit={canEditDesignation}
          totalShares={designation.allocation.shares}
        />
      </SSection>
    </Form>
  );
};

export default OverwriteDesignationForm;
