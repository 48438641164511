import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SSectionHeader = styled.div`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const SStatusWrapper = styled.div`
  display: flex;
`;
export const StyledStatusIndicatorIcon = styled(Icon)<{ active: boolean }>`
  margin-right: 5px;
  color: ${({ active, theme }) =>
    active ? theme.brand.color.success : theme.background.color.dark};
`;

export const SStatusIndicator = styled.div`
  white-space: nowrap;
`;

export const SButtonGroup = styled.div`
  justify-content: flex-end;
  flex-wrap: nowrap;

  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
