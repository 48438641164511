import React from 'react';
import { Link } from 'react-router-dom';

import { OfferingSidePanelRouteContext } from '../../../../../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offeringSidePanelSellSideRouteFactory';
import {
  SDuplicateColumn,
  SDuplicateHeader,
  SDuplicateWrapper,
} from './DuplicateIndications.styles';
import { ManageIndication_DuplicateIndicationsQuery } from './graphql/__generated__';

export type Props = {
  indication: ManageIndication_DuplicateIndicationsQuery['basicPublishedOffering']['orderBook']['institutionalIndications'][number];
};

const DuplicateIndicationRow: React.FC<Props> = ({ indication }) => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  return (
    <SDuplicateWrapper>
      <SDuplicateColumn>
        <SDuplicateHeader>Investor Name</SDuplicateHeader>
        <Link
          to={
            baseSidePanelUrl +
            offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
              cmgEntityKey: indication.investorInformation.cmgEntityKey!,
            })
          }
        >
          {indication.investorInformation.bankInvestorName ??
            indication.investorInformation.cmgEntityName}
        </Link>
      </SDuplicateColumn>
      <SDuplicateColumn>
        <SDuplicateHeader>Submitted By</SDuplicateHeader>
        <div aria-label="Submitted By" data-test-id="indication-manager-name">
          {indication.auditInfo.createdByFirmName}
        </div>
      </SDuplicateColumn>
      <SDuplicateColumn>
        <SDuplicateHeader>CMG Entity Name</SDuplicateHeader>
        <div>{indication.investorInformation.cmgEntityName}</div>
      </SDuplicateColumn>
    </SDuplicateWrapper>
  );
};

export default DuplicateIndicationRow;
