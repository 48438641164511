import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  value?: number;
  currency: string;
  displayVariant?: 'symbol' | 'code' | 'name' | 'narrowSymbol';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const CurrencyFormatter: React.FC<Props> = ({
  value,
  currency,
  displayVariant = 'symbol',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}) => {
  return value ? (
    <FormattedNumber
      // we must disable this rule because we are using a custom currency formatter
      // that requires the currency to be passed in as string
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
      value={value}
      currency={currency}
      currencyDisplay={displayVariant}
    />
  ) : null;
};

export default CurrencyFormatter;
