import { UUID } from '@cmg/common';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SidePanel from '../../../../../common/components/layout/side-panel/SidePanel';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  SContent,
  SLayout,
  SNavbar,
} from '../../../../trade-release/history/TradeReleaseHistorySidePanel.styles';
import { SalesCreditsInvestor } from '../SalesCreditsReleaseHistoryRoute';
import { SalesCreditsHistoryContent } from './content/SalesCreditsHistoryContent';
import { SalesCreditsHistorySidePanelNavbar } from './SalesCreditsHistorySidePanelNavbar';

export type Props = {
  offeringId: UUID;
  investorFirmCmgEntityKey: UUID;
  isOpen: boolean;
  onClose: () => void;
  investors: SalesCreditsInvestor[];
};

const SalesCreditsReleaseHistorySidePanel: React.FC<Props> = ({
  offeringId,
  investorFirmCmgEntityKey,
  isOpen,
  onClose,
  investors,
}) => {
  return (
    <SidePanel isOpen={isOpen} onClose={onClose}>
      <SLayout>
        <SNavbar>
          <SalesCreditsHistorySidePanelNavbar
            offeringId={offeringId}
            investorFirmCmgEntityKey={investorFirmCmgEntityKey}
          />
        </SNavbar>
        <SContent>
          <Switch>
            <Route path={routeFactory.salesCreditsHistory.routePath}>
              <SalesCreditsHistoryContent
                offeringId={offeringId}
                investorFirmCmgEntityKey={investorFirmCmgEntityKey}
                investors={investors}
              />
            </Route>
          </Switch>
        </SContent>
      </SLayout>
    </SidePanel>
  );
};

export default SalesCreditsReleaseHistorySidePanel;
