import { useCheckAccountType } from '@cmg/auth';
import { DataGridGroupingClient, DeepWritable, ISODateTime } from '@cmg/common';
import { GridApi, RowNode } from 'ag-grid-community';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

import sortByDateComparator from '../../../../common/util/sortByDateComparator';
import { gridFooterMinHeight, nonEditableGridOptions } from '../../../constants';
import { ColDefCalendarGrid } from '../../calendar.model';
import useFilters from '../../hooks/useFilters';
import CalendarFilters from '../calendar-filters/CalendarFilters';
import { overlayNoRowsTemplate } from './../overlayNoRowsTemplate/OverlayNoRowsTemplate';
import { CalendarGridOffering, orderComparator } from './CalendarGrid.model';
import { CalendarGridOrderField } from './CalendarGridColumns';

export const GroupHeader = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};

  & span {
    margin-left: 5px;
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
`;

export const SBannerWrapper = styled.div`
  .calendar-info-banner {
    margin: 0;
  }
`;

export type Props<TOffering extends CalendarGridOffering = CalendarGridOffering> = {
  publishedOfferings?: readonly TOffering[];
  groupByField: CalendarGridOrderField;
  groupHeaderRenderer: (groupByField: string, groupRows: TOffering[]) => React.ReactElement;
  loading: boolean;
  columns: ColDefCalendarGrid<TOffering>[];
  lastUpdatedAt: ISODateTime;
};

// Calculate the height of each row in the calendar grid. Primary use is for stretching the disclaimer footer so that it takes up all
// of the vertical space in a mostly empty grid.
export const getRowHeight = (params: { node: RowNode; api: GridApi }) => {
  if (params.node.footer) {
    // Notation is for accessing private 'gridPanel' information without incurring a type error. This is the official Microsoft "escape hatch"
    // for accessing private data. More information:https://github.com/microsoft/TypeScript/issues/19335
    const bodyHeight = params.api['gridBodyCon'].getGridBodyElement().clientHeight;

    const bodyDiff = bodyHeight - ((params.node.rowTop ?? 0) + gridFooterMinHeight);
    if (bodyDiff > gridFooterMinHeight) {
      return bodyDiff;
    } else {
      return gridFooterMinHeight;
    }
  }

  return nonEditableGridOptions.rowHeight;
};

function CalendarGrid<TOffering extends CalendarGridOffering = CalendarGridOffering>({
  loading,
  publishedOfferings,
  groupByField,
  groupHeaderRenderer,
  columns,
  lastUpdatedAt,
}: Props<TOffering>): React.ReactElement {
  const theme = useTheme();
  const match = useRouteMatch();
  const isBuySideAccount = useCheckAccountType('BUY_SIDE');
  const { filters, setFilters, filteredData } = useFilters<CalendarGridOffering>({
    sourceOfferings: publishedOfferings,
  });
  const groupHeaderComparator = React.useMemo(
    () =>
      sortByDateComparator(groupByField === CalendarGridOrderField.PRICING_DATE ? 'asc' : 'desc'),
    [groupByField]
  );

  return (
    <React.Fragment>
      <CalendarFilters
        lastUpdatedAt={lastUpdatedAt}
        onChange={setFilters}
        filters={filters}
        offerings={publishedOfferings}
      />
      <DataGridGroupingClient<TOffering>
        groupByField={groupByField}
        groupHeaderRenderer={groupHeaderRenderer}
        groupHeaderComparator={groupHeaderComparator}
        overlayNoRowsTemplate={isBuySideAccount ? overlayNoRowsTemplate : undefined}
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          fillViewport: true,
        }}
        columns={columns}
        rows={filteredData as DeepWritable<typeof filteredData>}
        resizeBy="grid"
        gridOptions={{
          groupIncludeTotalFooter: isBuySideAccount,
          ...nonEditableGridOptions,
          getRowHeight,
          tooltipShowDelay: 0,
          embedFullWidthRows: true,
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressBrowserResizeObserver: true,
          rowStyle: {
            background: theme.background.color.white,
            borderBottom: theme.border.smallSolidLight,
          },
          context: {
            matchUrl: match.url,
            footerStyles: {
              width: '90vw',
            },
            disclaimerStyles: {
              maxWidth: '1300px',
            },
          },
        }}
        getRowNodeId={row => row.id}
        orderField="issuerName"
        orderDirection="asc"
        orderComparator={orderComparator}
        loading={loading}
      />
    </React.Fragment>
  );
}

const typedMemo: <T>(c: T) => T = React.memo;

export default typedMemo(CalendarGrid);
