import { getCurrencySymbol, NumericInputField } from '@cmg/common';
import React from 'react';

import SetupForm from '../components/form/OfferingSetupForm';
import { SOtherSectionFieldGrid } from './OtherUnderwritingFeesDiscountsForm.styles';
import {
  SCellContainer,
  SRow,
  SRowTitleContainer,
} from './UnderwritingDiscountsAndFeesRoute.styles';

type Props = {
  pricingCurrencyCode?: string;
};

export const OtherUnderwritingFeesDiscountsForm: React.FC<Props> = ({ pricingCurrencyCode }) => {
  return (
    <SetupForm title="Other">
      <SRow>
        <SOtherSectionFieldGrid>
          <SRowTitleContainer>Reallowance</SRowTitleContainer>
          <SCellContainer>
            <NumericInputField
              suffix={getCurrencySymbol(pricingCurrencyCode)}
              name="reallowance"
              precision={6}
              displayPrecision
            />
          </SCellContainer>
          <SRowTitleContainer>Shares Excluded from Gross Spread</SRowTitleContainer>
          <SCellContainer>
            <NumericInputField name="sharesExcludedFromGrossSpread" />
          </SCellContainer>
        </SOtherSectionFieldGrid>
      </SRow>
    </SetupForm>
  );
};
