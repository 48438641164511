import { IconButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SPopoverWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text.color.dark};
`;

export const StyledIconButton = styled(IconButton)`
  font-size: 22px;
`;

export const SPopoverDescription = styled.div`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
`;
