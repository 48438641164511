import styled from 'styled-components/macro';

export const SDesignationMonitorGridContainer = styled.div`
  height: 100%;

  // Adding a larger border to the pinned left header column
  .ag-theme-balham .ag-pinned-left-header {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Adding a larger border to the pinned right header column
  .ag-theme-balham .ag-pinned-right-header {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Removing borders from the header rows.
  .ag-header {
    border: 0;
  }

  .ag-header-group-cell .ag-react-container {
    overflow: hidden;
  }

  // Removing borders in all grid cells and rows
  .ag-theme-balham .ag-cell,
  .ag-row {
    border-bottom-width: 0;
    border-top-width: 0;
  }

  .ag-react-container {
    display: flex;
    flex: 1;
  }
`;
