import { Option } from '@cmg/common';

import { getFirmDisplayName } from '../../../../utils';

export type Manager = {
  cmgEntityKey: string;
  firmName: string;
  firmNameAbbreviation?: string | null;
};

export const defaultUnassignedManager: Manager = {
  cmgEntityKey: 'Unassigned',
  firmName: 'Unassign',
};

export const getMangerOptions = (managerList: Manager[] = []): Option[] => {
  return managerList.map(manager => ({
    label: getFirmDisplayName(manager, { defaultName: '' })!,
    value: manager.cmgEntityKey,
  }));
};
