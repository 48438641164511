import { useFeatureToggles } from '../../../../../../common/config';
import usePollingWhenVisible from '../../../../../../common/hooks/usePollingWhenVisible';
import { useWithOrderBook_InvestorRelationshipChangedLazySubscription } from '../../../../graphql/__generated__';
import {
  InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
  InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables,
  useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsLazyQuery,
} from '../graphql';

export const INVESTOR_FIRMS_EMPLOYEE_RELATIONSHIPS_POLL_INTERVAL_MS = 5_000;

export const useInvestorFirmsEmployeeRelationshipsLazyQuery = ({
  bankInvestorKeys,
}: InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables) => {
  const { isOrderBookDataRefreshOn, isOrderBookInstitutionalDemandGridSubscriptionOn } =
    useFeatureToggles();
  const pollInterval =
    isOrderBookDataRefreshOn && !isOrderBookInstitutionalDemandGridSubscriptionOn
      ? INVESTOR_FIRMS_EMPLOYEE_RELATIONSHIPS_POLL_INTERVAL_MS
      : 0;

  let lazyQueryTuple = useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsLazyQuery();

  lazyQueryTuple = useWithOrderBook_InvestorRelationshipChangedLazySubscription(lazyQueryTuple, {
    variables: () => ({ investorFirmKeysOrIds: bankInvestorKeys }),
    isEnabled: isOrderBookInstitutionalDemandGridSubscriptionOn,
    updateQuery:
      () =>
      (prev, { subscriptionData: { data } }) => {
        const { investorRelationshipChanged } = data;

        return {
          investorFirmsEmployeeRelationships: prev.investorFirmsEmployeeRelationships.map<
            InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery['investorFirmsEmployeeRelationships'][0]
          >(investorFirmsEmployeeRelationship =>
            investorRelationshipChanged.investorFirmKey ===
            investorFirmsEmployeeRelationship.bankInvestorKey
              ? {
                  ...investorFirmsEmployeeRelationship,
                  relationships: investorRelationshipChanged.relationships.map(relationship => ({
                    ...relationship,
                    __typename: 'InvestorFirmEmployeeRelationship',
                  })),
                }
              : investorFirmsEmployeeRelationship
          ),
        };
      },
  });

  const [, { called, stopPolling, startPolling }] = lazyQueryTuple;

  usePollingWhenVisible({
    pollInterval,
    called,
    stopPolling,
    startPolling,
    isEnabled: isOrderBookDataRefreshOn && !isOrderBookInstitutionalDemandGridSubscriptionOn,
  });

  return lazyQueryTuple;
};
