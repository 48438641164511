import { ApolloError } from '@apollo/client';
import React from 'react';

import useCheckInstitutionalPassiveOrderBookAccess from '../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { useInstitutionalDemandGrid_ColumnConfigQuery } from '../graphql';
import { useInstitutionalDemandGridSummaryLazyQuery } from './useInstitutionalDemandGridSummaryQuery';

type Args = {
  offeringId: string;
};
export const useInstitutionalDemandGridPrimaryQueries = ({ offeringId }: Args) => {
  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });

  const {
    data: gridColumnConfigData,
    loading: loadingGridColumnConfigData,
    error: errorGridColumnConfigData,
    called: hasCalledGridColumnConfig,
  } = useInstitutionalDemandGrid_ColumnConfigQuery({
    variables: { offeringId, canManagePassiveOrderBook },
  });

  const [
    getDemandGridSummary,
    {
      loading: loadingDemandGridSummary,
      data: institutionalDemandGridSummaryData,
      error: errorDemandGridSummary,
      startPolling: startDemandSummaryGridPolling,
      stopPolling: stopDemandSummaryGridPolling,
      called: hasCalledDemandGridSummary,
      refetch: refetchDemandGridSummary,
    },
  ] = useInstitutionalDemandGridSummaryLazyQuery({ offeringId });

  React.useEffect(() => {
    if (errorDemandGridSummary) {
      stopDemandSummaryGridPolling();
    }
  }, [errorDemandGridSummary, stopDemandSummaryGridPolling]);

  const { errors, loading, hasCalled } = React.useMemo(() => {
    return {
      errors: [errorDemandGridSummary, errorGridColumnConfigData].filter(
        (error): error is ApolloError => !!error
      ),
      loading: loadingDemandGridSummary || loadingGridColumnConfigData,
      hasCalled: hasCalledDemandGridSummary || hasCalledGridColumnConfig,
    };
  }, [
    errorDemandGridSummary,
    errorGridColumnConfigData,
    loadingDemandGridSummary,
    loadingGridColumnConfigData,
    hasCalledDemandGridSummary,
    hasCalledGridColumnConfig,
  ]);

  return {
    errors,
    loading,
    gridColumnConfigData,
    canManagePassiveOrderBook,
    institutionalDemandGridSummaryData,
    hasCalled,
    getDemandGridSummary,
    startDemandSummaryGridPolling,
    stopDemandSummaryGridPolling,
    refetchDemandGridSummary,
  };
};
