import { useStoredPreference } from '@cmg/auth';

/**
 * A hook that provides and manages the push notification enabled status from local storage.
 * This function is a simple implementation of useStoredPreference.
 *
 * istanbul ignore next: This function is a simple implementation of useStoredPreference.
 */
export const usePushNotificationEnabledStatus = () =>
  useStoredPreference<boolean | undefined>({
    key: `PushNotification_EnabledStatus`,
    initialValue: undefined,
  });

/**
 * A hook that provides and manages the push notification touched status from local storage.
 * This helps blocks the icon animation after it has been touched.
 *
 * istanbul ignore next: This function is a simple implementation of useStoredPreference.
 */
export const usePushNotificationTouchedStatus = () =>
  useStoredPreference<boolean | undefined>({
    key: `PushNotification_TouchedStatus`,
    initialValue: undefined,
  });
