import { useSearchParams } from '@cmg/common';

import { FirmCertificateSource } from '../../../graphql';

export interface CertificateQueryParams {
  source: FirmCertificateSource;
  cmgEntityKey?: string;
  crmInvestorId?: string;
}

export const useCertificateQueryParams = (): CertificateQueryParams => {
  const { searchParams } = useSearchParams();

  const cmgEntityKey = searchParams.get('cmgEntityKey') ?? undefined;
  const crmInvestorId = searchParams.get('crmInvestorId') ?? undefined;
  const source =
    (searchParams.get('certificateLibrary') as FirmCertificateSource) ?? FirmCertificateSource.Crm;

  return {
    crmInvestorId,
    cmgEntityKey,
    source,
  };
};
