/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreatePotAccountWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreatePotAccountWireInput;
}>;

export type SyndicateWires_CreatePotAccountWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createPotAccountWire:
    | {
        readonly __typename?: 'PotAccountWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly headerNote?: string | null;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeletePotAccountWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_DeletePotAccountWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deletePotAccountWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_PotAccountWireDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_PotAccountWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly stage: Types.OfferingStage;
  };
  readonly potAccountWireDetails: {
    readonly __typename?: 'PotAccountWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly headerNote?: string | null;
    readonly createdAt: string;
    readonly sender: string;
    readonly disclaimer?: string | null;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_PotAccountWirePartsFragment = {
  readonly __typename?: 'PotAccountWire';
  readonly id: string;
  readonly status: Types.WireStatus;
  readonly headerNote?: string | null;
  readonly createdAt: string;
  readonly sender: string;
  readonly disclaimer?: string | null;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_PotAccountWirePreviewQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SyndicateWires_PotAccountWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly potAccountWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_PotAccountWireValidationPartsFragment = {
  readonly __typename?: 'PotAccountWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.PotAccountWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_PotAccountWireValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_PotAccountWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly potAccountWireValidation: {
    readonly __typename?: 'PotAccountWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.PotAccountWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_PotAccountWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_PotAccountWiresQuery = {
  readonly __typename?: 'Query';
  readonly potAccountWireList: ReadonlyArray<{
    readonly __typename?: 'PotAccountWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly headerNote?: string | null;
    readonly createdAt: string;
    readonly sender: string;
    readonly disclaimer?: string | null;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendPotAccountWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  sendDateTimeZone: Types.Scalars['String'];
  shouldAddSenderToCc: Types.Scalars['Boolean'];
}>;

export type SyndicateWires_SendPotAccountWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendPotAccountWire:
    | {
        readonly __typename?: 'PotAccountWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly headerNote?: string | null;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_UpdatePotAccountWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  payload: Types.UpdatePotAccountWireInput;
}>;

export type SyndicateWires_UpdatePotAccountWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePotAccountWire:
    | {
        readonly __typename?: 'PotAccountWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly headerNote?: string | null;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_PotAccountWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_PotAccountWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PotAccountWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreatePotAccountWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreatePotAccountWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePotAccountWireInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPotAccountWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreatePotAccountWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_CreatePotAccountWireMutation,
  SyndicateWires_CreatePotAccountWireMutationVariables
>;

/**
 * __useSyndicateWires_CreatePotAccountWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreatePotAccountWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreatePotAccountWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreatePotAccountWireMutation, { data, loading, error }] = useSyndicateWires_CreatePotAccountWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreatePotAccountWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreatePotAccountWireMutation,
    SyndicateWires_CreatePotAccountWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreatePotAccountWireMutation,
    SyndicateWires_CreatePotAccountWireMutationVariables
  >(SyndicateWires_CreatePotAccountWireDocument, options);
}
export type SyndicateWires_CreatePotAccountWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_CreatePotAccountWireMutation
>;
export type SyndicateWires_CreatePotAccountWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreatePotAccountWireMutation>;
export type SyndicateWires_CreatePotAccountWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_CreatePotAccountWireMutation,
  SyndicateWires_CreatePotAccountWireMutationVariables
>;
export const SyndicateWires_DeletePotAccountWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeletePotAccountWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePotAccountWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeletePotAccountWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeletePotAccountWireMutation,
  SyndicateWires_DeletePotAccountWireMutationVariables
>;

/**
 * __useSyndicateWires_DeletePotAccountWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeletePotAccountWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeletePotAccountWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeletePotAccountWireMutation, { data, loading, error }] = useSyndicateWires_DeletePotAccountWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeletePotAccountWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeletePotAccountWireMutation,
    SyndicateWires_DeletePotAccountWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeletePotAccountWireMutation,
    SyndicateWires_DeletePotAccountWireMutationVariables
  >(SyndicateWires_DeletePotAccountWireDocument, options);
}
export type SyndicateWires_DeletePotAccountWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeletePotAccountWireMutation
>;
export type SyndicateWires_DeletePotAccountWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeletePotAccountWireMutation>;
export type SyndicateWires_DeletePotAccountWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_DeletePotAccountWireMutation,
  SyndicateWires_DeletePotAccountWireMutationVariables
>;
export const SyndicateWires_PotAccountWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'potAccountWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_PotAccountWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_PotAccountWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_PotAccountWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_PotAccountWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_PotAccountWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_PotAccountWireDetailsQuery,
    SyndicateWires_PotAccountWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_PotAccountWireDetailsQuery,
    SyndicateWires_PotAccountWireDetailsQueryVariables
  >(SyndicateWires_PotAccountWireDetailsDocument, options);
}
export function useSyndicateWires_PotAccountWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_PotAccountWireDetailsQuery,
    SyndicateWires_PotAccountWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_PotAccountWireDetailsQuery,
    SyndicateWires_PotAccountWireDetailsQueryVariables
  >(SyndicateWires_PotAccountWireDetailsDocument, options);
}
export type SyndicateWires_PotAccountWireDetailsQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWireDetailsQuery
>;
export type SyndicateWires_PotAccountWireDetailsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWireDetailsLazyQuery
>;
export type SyndicateWires_PotAccountWireDetailsQueryResult = Apollo.QueryResult<
  SyndicateWires_PotAccountWireDetailsQuery,
  SyndicateWires_PotAccountWireDetailsQueryVariables
>;
export const SyndicateWires_PotAccountWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'potAccountWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_PotAccountWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_PotAccountWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_PotAccountWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_PotAccountWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_PotAccountWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_PotAccountWirePreviewQuery,
    SyndicateWires_PotAccountWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_PotAccountWirePreviewQuery,
    SyndicateWires_PotAccountWirePreviewQueryVariables
  >(SyndicateWires_PotAccountWirePreviewDocument, options);
}
export function useSyndicateWires_PotAccountWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_PotAccountWirePreviewQuery,
    SyndicateWires_PotAccountWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_PotAccountWirePreviewQuery,
    SyndicateWires_PotAccountWirePreviewQueryVariables
  >(SyndicateWires_PotAccountWirePreviewDocument, options);
}
export type SyndicateWires_PotAccountWirePreviewQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWirePreviewQuery
>;
export type SyndicateWires_PotAccountWirePreviewLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWirePreviewLazyQuery
>;
export type SyndicateWires_PotAccountWirePreviewQueryResult = Apollo.QueryResult<
  SyndicateWires_PotAccountWirePreviewQuery,
  SyndicateWires_PotAccountWirePreviewQueryVariables
>;
export const SyndicateWires_PotAccountWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'potAccountWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PotAccountWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_PotAccountWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_PotAccountWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_PotAccountWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_PotAccountWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_PotAccountWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_PotAccountWireValidationQuery,
    SyndicateWires_PotAccountWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_PotAccountWireValidationQuery,
    SyndicateWires_PotAccountWireValidationQueryVariables
  >(SyndicateWires_PotAccountWireValidationDocument, options);
}
export function useSyndicateWires_PotAccountWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_PotAccountWireValidationQuery,
    SyndicateWires_PotAccountWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_PotAccountWireValidationQuery,
    SyndicateWires_PotAccountWireValidationQueryVariables
  >(SyndicateWires_PotAccountWireValidationDocument, options);
}
export type SyndicateWires_PotAccountWireValidationQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWireValidationQuery
>;
export type SyndicateWires_PotAccountWireValidationLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWireValidationLazyQuery
>;
export type SyndicateWires_PotAccountWireValidationQueryResult = Apollo.QueryResult<
  SyndicateWires_PotAccountWireValidationQuery,
  SyndicateWires_PotAccountWireValidationQueryVariables
>;
export const SyndicateWires_PotAccountWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'potAccountWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_PotAccountWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_PotAccountWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_PotAccountWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_PotAccountWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_PotAccountWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_PotAccountWiresQuery,
    SyndicateWires_PotAccountWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_PotAccountWiresQuery,
    SyndicateWires_PotAccountWiresQueryVariables
  >(SyndicateWires_PotAccountWiresDocument, options);
}
export function useSyndicateWires_PotAccountWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_PotAccountWiresQuery,
    SyndicateWires_PotAccountWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_PotAccountWiresQuery,
    SyndicateWires_PotAccountWiresQueryVariables
  >(SyndicateWires_PotAccountWiresDocument, options);
}
export type SyndicateWires_PotAccountWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWiresQuery
>;
export type SyndicateWires_PotAccountWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_PotAccountWiresLazyQuery
>;
export type SyndicateWires_PotAccountWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_PotAccountWiresQuery,
  SyndicateWires_PotAccountWiresQueryVariables
>;
export const SyndicateWires_SendPotAccountWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendPotAccountWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendPotAccountWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendPotAccountWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_SendPotAccountWireMutation,
  SyndicateWires_SendPotAccountWireMutationVariables
>;

/**
 * __useSyndicateWires_SendPotAccountWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendPotAccountWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendPotAccountWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendPotAccountWireMutation, { data, loading, error }] = useSyndicateWires_SendPotAccountWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendPotAccountWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendPotAccountWireMutation,
    SyndicateWires_SendPotAccountWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendPotAccountWireMutation,
    SyndicateWires_SendPotAccountWireMutationVariables
  >(SyndicateWires_SendPotAccountWireDocument, options);
}
export type SyndicateWires_SendPotAccountWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_SendPotAccountWireMutation
>;
export type SyndicateWires_SendPotAccountWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendPotAccountWireMutation>;
export type SyndicateWires_SendPotAccountWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_SendPotAccountWireMutation,
  SyndicateWires_SendPotAccountWireMutationVariables
>;
export const SyndicateWires_UpdatePotAccountWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdatePotAccountWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePotAccountWireInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePotAccountWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_PotAccountWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PotAccountWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdatePotAccountWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_UpdatePotAccountWireMutation,
  SyndicateWires_UpdatePotAccountWireMutationVariables
>;

/**
 * __useSyndicateWires_UpdatePotAccountWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdatePotAccountWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdatePotAccountWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdatePotAccountWireMutation, { data, loading, error }] = useSyndicateWires_UpdatePotAccountWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdatePotAccountWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdatePotAccountWireMutation,
    SyndicateWires_UpdatePotAccountWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdatePotAccountWireMutation,
    SyndicateWires_UpdatePotAccountWireMutationVariables
  >(SyndicateWires_UpdatePotAccountWireDocument, options);
}
export type SyndicateWires_UpdatePotAccountWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_UpdatePotAccountWireMutation
>;
export type SyndicateWires_UpdatePotAccountWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdatePotAccountWireMutation>;
export type SyndicateWires_UpdatePotAccountWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_UpdatePotAccountWireMutation,
  SyndicateWires_UpdatePotAccountWireMutationVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_PotAccountWireDetailsMockResponse = makeMockResponse<
  SyndicateWires_PotAccountWireDetailsQueryVariables,
  SyndicateWires_PotAccountWireDetailsQuery
>(SyndicateWires_PotAccountWireDetailsDocument);

export const makeSyndicateWires_PotAccountWirePreviewMockResponse = makeMockResponse<
  SyndicateWires_PotAccountWirePreviewQueryVariables,
  SyndicateWires_PotAccountWirePreviewQuery
>(SyndicateWires_PotAccountWirePreviewDocument);

export const makeSyndicateWires_PotAccountWireValidationMockResponse = makeMockResponse<
  SyndicateWires_PotAccountWireValidationQueryVariables,
  SyndicateWires_PotAccountWireValidationQuery
>(SyndicateWires_PotAccountWireValidationDocument);

export const makeSyndicateWires_PotAccountWiresMockResponse = makeMockResponse<
  SyndicateWires_PotAccountWiresQueryVariables,
  SyndicateWires_PotAccountWiresQuery
>(SyndicateWires_PotAccountWiresDocument);
