import { RecipientUserData, SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';

/**
 * Verifies that each manager has associated user input data.
 */
export const allRecipientsHaveUserInputData = (
  managers: SyndicateWireManager[],
  recipientUserData: readonly RecipientUserData[] | undefined
) => {
  // Bulk wires does not have recipientUserData field defined
  if (!recipientUserData) {
    return true;
  }

  return managers.every(manager => {
    return recipientUserData.some(({ recipient }) => recipient === manager.cmgEntityKey);
  });
};
