import styled from 'styled-components/macro';

import Modal from '../../../../../../common/components/overlays/modal/Modal';

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const StyledModalContent = styled(Modal.Content)`
  height: 625px;
`;
