import { EmptyState } from '../../../../../design-system/components/data-display/empty-state/EmptyState';

const TradeReleaseZeroState: React.FC = () => {
  return (
    <EmptyState
      title="You are not assigned as B&D agent for any Investors"
      message="If you have any questions please contact the Settlement Agent"
    />
  );
};

export default TradeReleaseZeroState;
