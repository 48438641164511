import React from 'react';
import { Link } from 'react-router-dom';

import Banner from '../../../../../common/components/indicators/banner/Banner';
import { OfferingSidePanelRouteContext } from '../../../OfferingSidePanelContainer';
import {
  SComplianceActionRequiredTitle,
  SComplianceBannerWrapper,
  SComplianceTitleLink,
  SComplianceTitleWrapper,
} from './ComplianceActionRequiredBanner.styles';

export type Props = {
  url: string | null;
  message: string | null;
  padding?: string;
  withoutMargin?: boolean;
};

const ComplianceActionRequiredBanner = ({ url, message, padding, withoutMargin }: Props) => {
  const baseUrl = React.useContext(OfferingSidePanelRouteContext);

  return (
    <SComplianceBannerWrapper padding={padding}>
      <Banner variant="error" iconName="exclamation-triangle" withoutMargin>
        <SComplianceTitleWrapper data-test-id="indication-compliance-error-banner">
          <SComplianceActionRequiredTitle>
            Compliance action required
          </SComplianceActionRequiredTitle>
          <SComplianceTitleLink>
            {url && <Link to={baseUrl + url}>View Details</Link>}
          </SComplianceTitleLink>
        </SComplianceTitleWrapper>
        {message}
      </Banner>
    </SComplianceBannerWrapper>
  );
};

export default ComplianceActionRequiredBanner;
