import { TableCell, TableHead, TableRow } from '@cmg/design-system';

export const ManagerRetentionTableHead: React.VFC = () => (
  <TableHead>
    <TableRow>
      <TableCell>Recipient</TableCell>
      <TableCell align="right">Total Retail Allocations (Shares)</TableCell>
    </TableRow>
  </TableHead>
);
