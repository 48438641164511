import React from 'react';

import { WireTypeRoute } from '../../common/components/wire-type-route/WireTypeRoute';
import { WireRouteProps } from '../../common/components/wire-type-route/WireTypeRoute.model';
import { WireTypeConfigContextProvider } from '../../common/context/WireTypeConfigContext';
import { wireTypeConfigIssuerIndication } from './IssuerIndicationWire.model';

export const IssuerIndicationWireRoute: React.FC<WireRouteProps> = props => {
  return (
    <WireTypeConfigContextProvider value={wireTypeConfigIssuerIndication}>
      <WireTypeRoute {...props} />
    </WireTypeConfigContextProvider>
  );
};
