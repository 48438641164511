import React from 'react';

import { ValidationErrorGroup } from '../validation-error-group/ValidationErrorGroup';
import { ValidationErrorGroupList } from '../validation-error-group-list/ValidationErrorGroupList';
import { ValidationErrorSectionHeader } from '../validation-error-section-header/ValidationErrorSectionHeader';
import { ValidationBySteps } from '../wire-validation/WireValidation.model';

export type Props = {
  title: string;
  urlPath: string;
  validationBySteps: ValidationBySteps;
  hasPermission: boolean;
};

export const ValidationErrorSection: React.FC<Props> = ({
  validationBySteps,
  urlPath,
  title,
  hasPermission,
}) => {
  return (
    <div>
      <ValidationErrorSectionHeader urlPath={urlPath} title={title} hasPermission={hasPermission} />
      <ValidationErrorGroupList>
        {Object.entries(validationBySteps).map(([step, fields]) => (
          <ValidationErrorGroup key={step} step={step} fields={fields} />
        ))}
      </ValidationErrorGroupList>
    </div>
  );
};
