import { FilingType, RecordStatus } from '../../../../../graphql';
import {
  Offering_OfferingHeader_OfferingFilingsPartsFragment,
  Offering_OfferingHeader_OfferingProfileFilingsPartsFragment,
  Offering_OfferingHeader_OfferingProfileTermsPartsFragment,
  Offering_OfferingHeader_OfferingTermsPartsFragment,
} from '../../../graphql';

type OfferingFiling =
  | Offering_OfferingHeader_OfferingFilingsPartsFragment
  | Offering_OfferingHeader_OfferingProfileFilingsPartsFragment;

type FilingsByType = {
  [FilingType.Final]?: OfferingFiling[];
  [FilingType.Revised]?: OfferingFiling[];
  [FilingType.Initial]?: OfferingFiling[];
};

export type Filings =
  | Offering_OfferingHeader_OfferingTermsPartsFragment['filings']
  | Offering_OfferingHeader_OfferingProfileTermsPartsFragment['filings'];

export function getLatestFilingsPrices(filings?: Filings) {
  const filingsByType: FilingsByType = {};
  for (const filing of filings || []) {
    if (filing.__typename === 'Filing' && filing.recordStatus === RecordStatus.Deleted) continue;
    filingsByType[filing.filingType] ??= [];
    filingsByType[filing.filingType]?.unshift(filing);
  }

  const sortedLatestFilings = [
    filingsByType?.[FilingType.Final],
    filingsByType?.[FilingType.Revised],
    filingsByType?.[FilingType.Initial],
  ].flat();

  return {
    ipoRangeLow: sortedLatestFilings.find(filling => !!filling?.ipoRangeLow)?.ipoRangeLow,
    ipoRangeHigh: sortedLatestFilings.find(filling => !!filling?.ipoRangeHigh)?.ipoRangeHigh,
    offerPrice: sortedLatestFilings.find(filling => !!filling?.offerPrice)?.offerPrice,
  };
}
