import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
  SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
} from '../graphql';

export type DeleteNonSyndicateSellingGroupInvitationWireMutation =
  SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation;

export const useDeleteNonSyndicateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
