import type { CustomToolPanelProps } from '@cmg/data-grid';
import { Box, Button, Grid } from '@cmg/design-system';
import { FormikCurrencyField } from '@cmg/design-system-formik';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../graphql/__generated__';
import { defaultDemandConfigValues } from '../DemandGrid.model';
import type { DemandConfigValues, DemandGridDataContext } from '../types';
import { validationSchema } from './DemandToolPanel.model';
import { ToolPanel } from './ToolPanel';

export type DemandToolPanelProps = Readonly<{
  initialValues: DemandConfigValues | null;
  onApply: (values: DemandConfigValues) => void;
}>;

type Props = CustomToolPanelProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  DemandGridDataContext
> &
  DemandToolPanelProps;

const currencyFieldProps = {
  decimalScale: 2,
  fixedDecimalScale: true,
  showHelperTextInTooltip: true,
};

export const DemandToolPanel: React.FC<Props> = ({ api, initialValues, onApply }) => {
  const formik = useFormik<DemandConfigValues>({
    initialValues: initialValues ?? defaultDemandConfigValues,
    validationSchema,
    onSubmit: values => {
      onApply(values);
    },
    enableReinitialize: true,
    validateOnBlur: true,
  });

  return (
    <ToolPanel title="Demand Settings">
      <Box>
        <FormikProvider value={formik}>
          <Grid container spacing={1.25} rowGap={1.25} pb={2}>
            <Grid item xs={6}>
              <FormikCurrencyField {...currencyFieldProps} name="min" label="Min" />
            </Grid>
            <Grid item xs={6}>
              <FormikCurrencyField {...currencyFieldProps} name="max" label="Max" />
            </Grid>
            <Grid item xs={6}>
              <FormikCurrencyField {...currencyFieldProps} name="increment" label="Increment" />
            </Grid>
            <Grid item xs={6}>
              <FormikCurrencyField
                {...currencyFieldProps}
                name="referencePrice"
                label="Reference Price"
              />
            </Grid>
          </Grid>
        </FormikProvider>
        <Button variant="outlined" onClick={() => formik.handleSubmit()}>
          Apply
        </Button>
      </Box>
    </ToolPanel>
  );
};
