import { useUrlFilterStorage } from '@cmg/common';
import React from 'react';

type Props<TFilters> = {
  withUrlFilterStorage?: boolean;
  defaultFilters: TFilters;
};

export const useFilterStorage = <TFilters>({
  withUrlFilterStorage = false,
  defaultFilters,
}: Props<TFilters>) => {
  const { urlFilters, updateUrlFilters } = useUrlFilterStorage<TFilters>('filters');
  const [stateFilters, setStateFilters] = React.useState<TFilters>(defaultFilters);

  React.useEffect(() => {
    if (Object.keys(urlFilters).length > 0) {
      withUrlFilterStorage && setStateFilters({ ...defaultFilters, ...urlFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // we want setStateFilters state set to urlFilters executed only once after initial function call.

  const filters = React.useMemo(() => {
    if (Object.keys(urlFilters).length > 0) {
      return withUrlFilterStorage ? { ...defaultFilters, ...urlFilters } : stateFilters;
    } else {
      return stateFilters;
    }
  }, [withUrlFilterStorage, defaultFilters, urlFilters, stateFilters]);

  const setUrlFilters = (nextFilters: Partial<TFilters>) => {
    updateUrlFilters(nextFilters);
    setStateFilters({ ...filters, ...nextFilters });
  };

  const handleSetFilters = (nextFilters: Partial<TFilters>) => {
    return withUrlFilterStorage
      ? setUrlFilters(nextFilters)
      : setStateFilters({ ...filters, ...nextFilters });
  };

  return {
    setFilters: handleSetFilters,
    filters,
  };
};
