import React from 'react';

import { Recipient } from '../../components/dialogs/contact-support/ContactSupportDialog.model';
import { ContactSupportModalContextType } from '../ContactSupportModalContext';

export const useContactSupportControl = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [recipientsMissingEmail, setRecipientsMissingEmail] = React.useState<Recipient[]>([]);

  const handleCloseCallbackRef = React.useRef<(() => void) | undefined>(() => undefined);

  const open = React.useCallback<ContactSupportModalContextType['open']>(
    (recipientsMissingEmail, onCloseCallback) => {
      setRecipientsMissingEmail(recipientsMissingEmail);
      handleCloseCallbackRef.current = onCloseCallback;
      setIsVisible(true);
    },
    []
  );
  const close = React.useCallback<ContactSupportModalContextType['close']>(() => {
    setIsVisible(false);
    handleCloseCallbackRef.current?.();
  }, []);

  return { open, close, isVisible, recipientsMissingEmail };
};
