import type { UUID } from '@cmg/common';
import { numericUtil } from '@cmg/common';
import { NumericTotalCellRenderer } from '@cmg/data-grid';
import isNil from 'lodash/isNil';
import React from 'react';

import type { OrderBook_InstitutionalDemand_AllocationPartsFragment } from '../../../graphql/__generated__';
import { DemandGridTotalCellRendererProps, TotalRow } from '../../types';

type Value = OrderBook_InstitutionalDemand_AllocationPartsFragment['shares'];

export type Props = DemandGridTotalCellRendererProps<Value> & {
  allocationSetId: UUID;
};

export const AllocationTotalCellRenderer: React.FC<Props> = ({ allocationSetId, ...props }) => {
  const { allocationSets } = props.context;
  const { dealLayout } = allocationSets.drafts[allocationSetId] ?? allocationSets.final ?? {};

  const totalAllocationDiff =
    isNil(props.value) || isNil(dealLayout?.institutionalTarget)
      ? null
      : dealLayout.institutionalTarget - props.value;

  const isOverAllocated = !isNil(totalAllocationDiff) && totalAllocationDiff < 0;

  const secondaryText = !isNil(totalAllocationDiff)
    ? numericUtil.getDisplayValueForInteger(totalAllocationDiff)
    : undefined;

  return (
    <NumericTotalCellRenderer<TotalRow, Value>
      {...props}
      secondaryText={secondaryText}
      tooltipProps={
        isOverAllocated
          ? {
              title: 'Over-allocated',
              variant: 'error',
              placement: 'top',
            }
          : undefined
      }
    />
  );
};
