import { getOperationName } from '@apollo/client/utilities';
import isNil from 'lodash/isNil';
import React from 'react';

import {
  OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument,
  useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation,
} from '../../graphql';
import {
  FormStatus,
  UnderwritingTermsDiscountsAndFeesFormValues,
} from '../UnderwritingTermsDiscountsAndFeesSection';

type FixPayloadValueOptions = {
  value: number | null | undefined;
  canReplaceNullWithZero: boolean;
  precision: number;
};
export function fixPayloadValue({
  value,
  canReplaceNullWithZero,
  precision,
}: FixPayloadValueOptions): number | null {
  if (!canReplaceNullWithZero && isNil(value)) {
    return null;
  }
  return Number((value || 0).toFixed(precision));
}

export function getCurrencyPayload(values: UnderwritingTermsDiscountsAndFeesFormValues) {
  const canReplaceNullWithZeroInGrossSpreadAllocations = [
    values.grossSpreadBaseData?.managementFee,
    values.grossSpreadBaseData?.underwritingFee,
    values.grossSpreadBaseData?.sellingConcession,
  ].some(Number.isFinite);

  const canReplaceNullWithZeroInIncentiveFeeAllocations = [
    values.incentiveFeeData?.managementFee,
    values.incentiveFeeData?.underwritingFee,
    values.incentiveFeeData?.sellingConcession,
  ].some(Number.isFinite);
  return {
    sharesExcludedFromGrossSpread: values.sharesExcludedFromGrossSpread,
    reallowance: values.reallowance,
    grossSpreadBaseData: {
      grossSpreadBase: fixPayloadValue({
        value: values.grossSpreadBaseData?.grossSpreadBase,
        canReplaceNullWithZero: false,
        precision: 6,
      }),
      managementFee: fixPayloadValue({
        value: values.grossSpreadBaseData?.managementFee,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 6,
      }),
      underwritingFee: fixPayloadValue({
        value: values.grossSpreadBaseData?.underwritingFee,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 6,
      }),
      sellingConcession: fixPayloadValue({
        value: values.grossSpreadBaseData?.sellingConcession,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 6,
      }),
    },
    incentiveFeeData: {
      incentiveFee: fixPayloadValue({
        value: values.incentiveFeeData?.incentiveFee,
        canReplaceNullWithZero: false,
        precision: 6,
      }),
      managementFee: fixPayloadValue({
        value: values.incentiveFeeData?.managementFee,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 6,
      }),
      underwritingFee: fixPayloadValue({
        value: values.incentiveFeeData?.underwritingFee,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 6,
      }),
      sellingConcession: fixPayloadValue({
        value: values.incentiveFeeData?.sellingConcession,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 6,
      }),
    },
  };
}

export function getPercentagePayload(values: UnderwritingTermsDiscountsAndFeesFormValues) {
  const canReplaceNullWithZeroInGrossSpreadAllocations = [
    values.grossSpreadBaseData?.managementFeePercentage,
    values.grossSpreadBaseData?.underwritingFeePercentage,
    values.grossSpreadBaseData?.sellingConcessionPercentage,
  ].some(Number.isFinite);

  const canReplaceNullWithZeroInIncentiveFeeAllocations = [
    values.incentiveFeeData?.managementFeePercentage,
    values.incentiveFeeData?.underwritingFeePercentage,
    values.incentiveFeeData?.sellingConcessionPercentage,
  ].some(Number.isFinite);

  return {
    sharesExcludedFromGrossSpread: values.sharesExcludedFromGrossSpread,
    reallowance: values.reallowance,
    grossSpreadBaseData: {
      grossSpreadBasePercentage: fixPayloadValue({
        value: values.grossSpreadBaseData?.grossSpreadBasePercentage,
        canReplaceNullWithZero: false,
        precision: 10,
      }),
      managementFeePercentage: fixPayloadValue({
        value: values.grossSpreadBaseData?.managementFeePercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 10,
      }),
      underwritingFeePercentage: fixPayloadValue({
        value: values.grossSpreadBaseData?.underwritingFeePercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 10,
      }),
      sellingConcessionPercentage: fixPayloadValue({
        value: values.grossSpreadBaseData?.sellingConcessionPercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInGrossSpreadAllocations,
        precision: 10,
      }),
    },
    incentiveFeeData: {
      incentiveFeePercentage: fixPayloadValue({
        value: values.incentiveFeeData?.incentiveFeePercentage,
        canReplaceNullWithZero: false,
        precision: 10,
      }),
      managementFeePercentage: fixPayloadValue({
        value: values.incentiveFeeData?.managementFeePercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 10,
      }),
      underwritingFeePercentage: fixPayloadValue({
        value: values.incentiveFeeData?.underwritingFeePercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 10,
      }),
      sellingConcessionPercentage: fixPayloadValue({
        value: values.incentiveFeeData?.sellingConcessionPercentage,
        canReplaceNullWithZero: canReplaceNullWithZeroInIncentiveFeeAllocations,
        precision: 10,
      }),
    },
  };
}

function pickValuesByStatus(
  values: UnderwritingTermsDiscountsAndFeesFormValues,
  status: FormStatus
) {
  switch (status) {
    case FormStatus.EditingCurrency:
      return getCurrencyPayload(values);
    case FormStatus.EditingPercentage:
      return getPercentagePayload(values);
    default:
      throw new Error('Form is not editing');
  }
}

export function useUpdateUnderwritingTermsDiscountsFees() {
  const [updateMutation, { error: mutationError }] =
    useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation();

  const updateUnderwritingTermsDiscountsFees = React.useCallback(
    async ({
      values,
      editingStatus,
      offeringId,
    }: {
      values: UnderwritingTermsDiscountsAndFeesFormValues;
      editingStatus: FormStatus;
      offeringId: string;
    }) => {
      const { sharesExcludedFromGrossSpread, reallowance, incentiveFeeData, grossSpreadBaseData } =
        pickValuesByStatus(values, editingStatus);
      const variables = {
        offeringId,
        input: {
          sharesExcludedFromGrossSpread,
          reallowance,
          incentiveFee: incentiveFeeData,
          baseGrossSpread: grossSpreadBaseData,
        },
      };

      await updateMutation({
        variables,
        refetchQueries: [
          getOperationName(OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument)!,
        ],
      });
    },
    [updateMutation]
  );

  return {
    mutationError,
    updateUnderwritingTermsDiscountsFees,
  };
}
