import styled from 'styled-components/macro';

const SBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 8px;
`;

const SManagerList = styled.div`
  flex-shrink: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
`;

const STemplate = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SContentSection = styled.div`
  background: ${({ theme }) => theme.background.color.white};
  padding: 8px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SyndicateWireContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default Object.assign({}, SyndicateWireContent, {
  Body: SBody,
  Template: STemplate,
  ManagerList: SManagerList,
  ContentSection: SContentSection,
});
