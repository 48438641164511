import React from 'react';

import { BoundedContext } from '../../../../../graphql';
import { OfferingSetup_EntitlementsConfigurationQuery } from '../graphql';
import { EntitlementsDataByManagerContext } from './EntitlementsDataByManagerContext';

/**
 * useEntitlementsConfiguration returns the entitlements configuration for a given boundedContext
 */
export function useEntitlementConfigurationByBoundingContext({
  boundedContext,
}: {
  boundedContext: BoundedContext;
}): OfferingSetup_EntitlementsConfigurationQuery['entitlementsConfiguration'] | null {
  const { entitlementsConfiguration } = React.useContext(EntitlementsDataByManagerContext);

  return entitlementsConfiguration ? entitlementsConfiguration[boundedContext] : null;
}
