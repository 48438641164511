import { PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../common/components/overlays/modal/Modal';

export type Props = {
  calculatedField: string;
  show: boolean;
  onCalculate: () => void;
  onHide: () => void;
};

export const OverwriteModal: React.FC<Props> = ({ calculatedField, onCalculate, onHide, show }) => {
  const handleConfirm = () => {
    onHide();
    onCalculate();
  };

  return (
    <Modal show={show} title={`Calculate ${calculatedField}`} size="small">
      <Modal.Content>
        This action may result in overwriting current values. Would you like to continue?
      </Modal.Content>
      <Modal.Footer>
        <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
        <PrimaryButton onClick={handleConfirm}>Calculate</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
