import type { UUID } from '@cmg/common';
import type { CellValueChangedEvent } from '@cmg/data-grid';
import { useCallback } from 'react';

import { allocationColIdMatch } from '../columns';
import type { DemandGridRowData } from '../types';

export type Props = Readonly<{
  onBndAgentChanged: (indicationId: UUID, cmgEntityKey: string | null) => void;
  onAllocationChanged: (indicationId: UUID, allocationSetId: UUID, shares: number | null) => void;
}>;

/**
 * Cell value changed handler
 */
export const useHandleCellValueChanged = ({ onAllocationChanged, onBndAgentChanged }: Props) => {
  return useCallback(
    (event: CellValueChangedEvent<DemandGridRowData>) => {
      const { data, oldValue, newValue, column } = event;
      const colId = column.getColId();

      if (oldValue === newValue || data.__typename !== 'SyndicateInstitutionalGridRow') {
        return;
      }

      if (colId === 'bndAgent') {
        return onBndAgentChanged(data.id, data.bndAgent?.firmKey ?? null);
      }

      if (allocationColIdMatch.test(colId)) {
        const [, allocationSetId] = colId.split('_');
        return onAllocationChanged(data.id, allocationSetId, newValue);
      }
    },
    [onAllocationChanged, onBndAgentChanged]
  );
};
