export type SyndicateEntitlements = { cmgEntityKey: string; entitlements: readonly string[] };

export const findEntitlements: (options: {
  cmgEntityKey: string | null;
  syndicatesEntitlements: readonly SyndicateEntitlements[];
}) => readonly string[] = ({ cmgEntityKey, syndicatesEntitlements }) => {
  return cmgEntityKey
    ? syndicatesEntitlements.find(
        syndicateEntitlements => syndicateEntitlements.cmgEntityKey === cmgEntityKey
      )?.entitlements ?? []
    : [];
};

export const canBillingAndDeliveryAgentUpdateInstitutionalIndication: (options: {
  billingAndDeliveryAgentCmgEntityKey: string | null | undefined;
  syndicatesEntitlements: readonly SyndicateEntitlements[];
}) => boolean = ({ billingAndDeliveryAgentCmgEntityKey, syndicatesEntitlements }) => {
  return billingAndDeliveryAgentCmgEntityKey
    ? findEntitlements({
        cmgEntityKey: billingAndDeliveryAgentCmgEntityKey,
        syndicatesEntitlements,
      }).includes('UPDATE:INSTITUTIONAL_INDICATION')
    : false;
};
