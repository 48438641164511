export const getRegionAndCountryOptions = (values: {
  [key: string]: { title: string; value: string }[];
}) => {
  const regions = Object.keys(values);

  return regions.map(region => {
    return {
      title: region,
      value: values[region]?.map(country => country.value).toString(),
      children: values[region]?.map(country => {
        return {
          title: country.title,
          value: values[region].length > 1 ? country.value : country.title,
        };
      }),
    };
  });
};
