import { useCheckAccountTraits } from '@cmg/auth';
import type { UUID } from '@cmg/common';
import { useMemo } from 'react';

import { useCheckDeliveryInstructionAccess } from '../../../../../common/util/check-access/useCheckDeliveryInstructionAccess';
import {
  acknowledgementsColDef,
  allocationAckStatusColDef,
  allocationConfirmedByColDef,
  attestationColDef,
  cmgEntityNameColDef,
  createdAtColDef,
  demandAtMarketColDef,
  demandMaxColDef,
  firmKeyColDef,
  getBndAgentColDef,
  getNotesColDefs,
  modifiedAtDolDef,
  onPlatformColDef,
  prospectusColDef,
  relationshipColDef,
  rowAlertColDef,
  salesCoverageColDef,
  statusColDef,
  submittedByColDef,
  typeColDef,
} from '../columns';

type Props = { offeringId: UUID };

export const useCreateColumnDefs = ({ offeringId }: Props) => {
  const deliveryInstructionAccess = useCheckDeliveryInstructionAccess({ offeringId });
  const canViewSyndicateNotes = useCheckAccountTraits(['XC_IOI_NOTES']);

  return useMemo(() => {
    return [
      rowAlertColDef,
      onPlatformColDef,
      statusColDef,
      typeColDef,
      getBndAgentColDef({ canUpdate: deliveryInstructionAccess.canUpdate }),
      cmgEntityNameColDef,
      firmKeyColDef,
      acknowledgementsColDef,
      submittedByColDef,
      createdAtColDef,
      modifiedAtDolDef,
      relationshipColDef,
      allocationAckStatusColDef,
      allocationConfirmedByColDef,
      demandAtMarketColDef,
      demandMaxColDef,
      prospectusColDef,
      attestationColDef,
      salesCoverageColDef,
      ...getNotesColDefs({ canViewSyndicateNotes }),
    ];
  }, [deliveryInstructionAccess.canUpdate, canViewSyndicateNotes]);
};
