import {
  CurrencyInputField,
  DatePickerField,
  DateTimePickerField,
  NumericInputField,
  SelectField,
} from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../common/components/layout/grid-layout/GridLayout';
import {
  currencyOptions,
  isLastSaleBeforeDistributionRequired,
  TNFilingValues,
} from '../TNFilingForm.model';

const NonATMTradeForm: React.FC = () => {
  const { values } = useFormikContext<TNFilingValues>();
  const { transactionType } = values;
  return (
    <div>
      <GridLayout>
        <Column span={3}>
          <NumericInputField
            label="Number of Shares"
            aria-label="Number of Shares"
            name="numberOfShares"
            withMargin
            required
            fullWidth
          />
        </Column>
        <Column span={3}>
          <CurrencyInputField
            label="Offering Price"
            aria-label="Offering Price"
            name="offeringPrice"
            withMargin
            required
            fullWidth
            precision={6}
          />
        </Column>
        <Column span={3}>
          <SelectField
            label="Currency"
            aria-label="Currency"
            name="currency"
            options={currencyOptions}
            withMargin
            required
            fullWidth
          />
        </Column>
        <Column span={3}>
          <DatePickerField
            label="Pricing Date"
            testId="Regulatory Filings / Filings / NOI / Pricing Date"
            name="pricingDate"
            withMargin
            required
            fullWidth
          />
        </Column>
      </GridLayout>
      <GridLayout>
        <Column span={3}>
          <DateTimePickerField
            label="SEC Effective Date and Time"
            testId="Regulatory Filings / Filings / NOI / SEC Effective Date and Time"
            name="secEffective"
            showTimezones
            withMargin
            fullWidth
          />
        </Column>
        <Column span={3}>
          <DatePickerField
            label="Trade Date"
            testId="Regulatory Filings / Filings / NOI / Trade Date"
            name="tradeDate"
            withMargin
            required
            fullWidth
          />
        </Column>
      </GridLayout>
      {isLastSaleBeforeDistributionRequired(transactionType) && (
        <GridLayout>
          <Column span={3}>
            <CurrencyInputField
              label="Last Sale Before Distribution Price"
              aria-label="Last Sale Before Distribution Price"
              name="lastSaleBeforeDistributionPrice"
              withMargin
              required
              fullWidth
              precision={6}
            />
          </Column>
          <Column span={3}>
            <SelectField
              label="Last Sale Before Distribution Currency"
              aria-label="Last Sale Before Distribution Currency"
              name="lastSaleBeforeDistributionCurrency"
              options={currencyOptions}
              withMargin
              required
              fullWidth
            />
          </Column>
          <Column span={3}>
            <DatePickerField
              label="Last Sale Before Distribution Date"
              testId="Regulatory Filings / Filings / NOI / Last Sale Before Distribution Date"
              name="lastSaleBeforeDistributionDate"
              withMargin
              required
              fullWidth
            />
          </Column>
        </GridLayout>
      )}
    </div>
  );
};

export default NonATMTradeForm;
