import { useMemo } from 'react';

import { InstitutionalDemand } from '../../../types';
import { DupesGridContext, getDupesContext, SyndicateManager } from '../DupesModal.model';

export const useGetDupesContext = (
  indications: InstitutionalDemand[],
  managers: readonly SyndicateManager[],
  indicationIds: string[],
  oidcUserCmgEntityKey: string | null,
  pricingCurrencyCode?: string
): DupesGridContext => {
  return useMemo(() => {
    return getDupesContext(
      indications,
      managers,
      indicationIds,
      oidcUserCmgEntityKey,
      pricingCurrencyCode
    );
  }, [indications, managers, indicationIds, oidcUserCmgEntityKey, pricingCurrencyCode]);
};
