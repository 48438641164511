import styled from 'styled-components/macro';

export const StyledFilingStatus = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.light};
`;

export const SRegulatoryFilingsSideNavItemWrapper = styled.div`
  margin-left: 10px;
`;
