import { useFeatureToggles } from '../../../../../../../common/config';
import { OrderBookPersonaType } from '../../../../hooks/useIdentifyOrderBookPersona';
import { useBuySideIndicationQuery } from '../../../context/hooks/buy-side/__generated__';
import {
  OfferingSidePanelModel,
  offeringSidePanelModelDefaults,
  PersonaProps,
} from '../useOfferingSidePanelModel';

export type Props = PersonaProps & {
  hasComplianceWarning: boolean;
};

export const useBuySideIndicationModel = ({
  offeringId,
  hasComplianceWarning,
  type,
}: Props): OfferingSidePanelModel => {
  const {
    loading,
    data: indicationData,
    error,
  } = useBuySideIndicationQuery({
    variables: {
      offeringId,
    },
    skip: type !== OrderBookPersonaType.BUY_SIDE || !offeringId,
  });

  const { isOrderBookBuySideInvestorReplyOn } = useFeatureToggles();

  const myIndication = indicationData?.basicPublishedOffering?.myInstitutionalIndication ?? null;

  return {
    ...offeringSidePanelModelDefaults,
    indication: {
      data: myIndication,
      canRead: true,
      canUpdate: !hasComplianceWarning,
    },
    indicationAcknowledgment: {
      canRead: true,
      canUpdate: !hasComplianceWarning,
    },
    allocationAcknowledgment: {
      ...offeringSidePanelModelDefaults.allocationAcknowledgment,
      canUpdate: isOrderBookBuySideInvestorReplyOn,
    },
    queryResult: {
      data: {
        ...indicationData!,
        persona: OrderBookPersonaType.BUY_SIDE,
      },
      loading,
      error: error ?? null,
    },
  };
};
