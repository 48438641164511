import React from 'react';

export type Props = {
  areCustomVariablesVisible: boolean;
  setCustomVariablesVisible: (value: boolean) => void;
  disclaimerInputRef: React.MutableRefObject<HTMLTextAreaElement | undefined>;
};

export const DisclaimerContext = React.createContext<Props>({
  areCustomVariablesVisible: false,
  setCustomVariablesVisible: () => {},
  disclaimerInputRef: {
    current: undefined,
  },
});

export const useDisclaimerContext = () => {
  const context = React.useContext(DisclaimerContext);

  if (context === undefined) {
    throw new Error('No Provider found for DisclaimerContext');
  }

  return context;
};

export const DisclaimerContextProvider: React.FC = ({ children }) => {
  const [areCustomVariablesVisible, setCustomVariablesVisible] = React.useState(false);
  const disclaimerInputRef = React.useRef<HTMLTextAreaElement>();

  return (
    <DisclaimerContext.Provider
      value={{ areCustomVariablesVisible, setCustomVariablesVisible, disclaimerInputRef }}
    >
      {children}
    </DisclaimerContext.Provider>
  );
};
