import { Option } from '@cmg/common';
import * as yup from 'yup';

import { phoneNumberSchema } from '../../../../../../../../common/util/validation';
import { DealTeamMemberType } from '../../../../../../../../graphql';
import { Roadshows_DealTeamMemberPartsFragment } from '../../../../../../common/graphql';

export type DealTeamMemberValues = {
  type: DealTeamMemberType;
  name: string;
  mobilePhone: string | null | undefined;
  officePhone: string | null | undefined;
  email: string;
  department: string | null | undefined;
};

export const getInitialValues = ({
  type,
  name,
  mobilePhone,
  officePhone,
  email,
  department,
}: Roadshows_DealTeamMemberPartsFragment): DealTeamMemberValues => ({
  type,
  name,
  mobilePhone,
  officePhone,
  email,
  department,
});

export const validationSchema = yup.object({
  type: yup.string(),
  name: yup.string().label('Name').nullable().required(),
  mobilePhone: phoneNumberSchema.label('Mobile Phone').nullable(),
  officePhone: phoneNumberSchema.label('Office Phone').nullable(),
  email: yup.string().email().label('Email').nullable().required(),
  department: yup.string().label('Department').nullable(),
});

export const getDepartmentOptions = (departments: readonly string[]): Option[] => {
  return departments.map(department => ({ value: department, label: department }));
};

export const isIdentityMember = ({ type }: Roadshows_DealTeamMemberPartsFragment) => {
  return type === DealTeamMemberType.Identity;
};
