import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SSeparator = styled.span`
  background-color: ${({ theme }) => theme.designSystem.colors.gray[100]};
  margin: 4px 0;
  width: 1px;
  min-height: 22px;
`;

export const StyledSearchIcon = styled(Icon).attrs({ name: 'search' })`
  margin: 8px 10px;
`;

export const StyledIconDateRange = styled(Icon).attrs({ name: 'calendar-alt' })`
  margin: 8px 10px;
`;
