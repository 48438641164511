import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreatePricingTermsWireMutation,
  SyndicateWires_PricingTermsWiresDocument,
  useSyndicateWires_CreatePricingTermsWireMutation,
} from '../graphql';

export type CreatePricingTermsWireMutation = SyndicateWires_CreatePricingTermsWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PricingTermsWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useCreatePricingTermsWireMutation = () => {
  return useSyndicateWires_CreatePricingTermsWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
