import { Skeleton, TableSkeleton } from '@cmg/design-system';
import React from 'react';

const InternalRetailDemandSkeleton: React.FC = () => {
  return (
    <div>
      <Skeleton height={48} />
      <TableSkeleton cellSize="medium" numOfColumns={10} />
    </div>
  );
};

export default InternalRetailDemandSkeleton;
