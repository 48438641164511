import { formatters } from '@cmg/data-grid';

import { ProspectusDeliveryStatus } from '../../../../../../graphql';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector } from '../columns.model';
import ProspectusDeliveryStatusCellRenderer from './ProspectusDeliveryStatusCellRenderer';

/**
 * Exported for testing purposes.
 */
export const cellRendererSelector = getCellRendererSelector<string>({
  gridRow: { component: ProspectusDeliveryStatusCellRenderer },
});

const prospectusColDef: DemandGridServerColDef<string> = {
  field: 'prospectusDeliverySummary.overallStatusDisplayValue',
  headerName: 'Prospectus Contacts ⓘ',
  headerTooltip: 'Investor contacts that have been sent prospectus documents.',
  enableRowGroup: true,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  server: {
    filterFieldPath: 'prospectusDeliverySummary.overallStatus',
  },
  filterParams: {
    values: Object.values(ProspectusDeliveryStatus),
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default prospectusColDef;
