import { numericUtil, Table, timeUtil } from '@cmg/common';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';

import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { FilingStatus } from '../../../../../graphql';
import { FinraFilingStatusAlert } from '../../../common/content/alerts/FinraFilingStatusAlert';
import FilingCommonFieldsDetail from '../../../common/content/filing-common-fields-detail/FilingCommonFieldsDetail';
import FilingDetails from '../../../common/content/filing-details/FilingDetails';
import FilingDetailsHeader from '../../../common/content/filing-details-header/FilingDetailsHeader';
import FinraErrorResponseBanner from '../../../common/content/finra-error-response/FinraErrorResponseBanner';
import { getLabelFromOptions } from '../../../common/form-utils';
import { currencyOptions } from '../../trading-notification/form/TNFilingForm.model';
import { buySellOptions } from '../form/USDTNFilingForm.model';
import { RegulatoryFilings_UsdtnFilingPartsFragment } from '../graphql';

export type Props = {
  filing: RegulatoryFilings_UsdtnFilingPartsFragment;
  version: number;
};

const USDTNDetail: React.FC<Props> = ({ filing, version }) => {
  return (
    <div>
      <FinraFilingStatusAlert status={filing.status} />
      <FilingDetailsHeader
        version={version}
        firmDealId={filing.firmDealId}
        formUniqueId={filing.formUniqueId}
        status={filing.status}
        submittedDateTime={filing.submittedDateTime}
      />

      {filing.status === FilingStatus.Error && filing.finraErrorMessages && (
        <FinraErrorResponseBanner errors={filing.finraErrorMessages} />
      )}

      <FilingCommonFieldsDetail detail={filing} />
      <FilingDetails issuerName={filing.issuerName} issuerSymbol={filing.issuerSymbol}>
        <GridLayout>
          <Table>
            <thead>
              <tr>
                <th>Execution Date and Time</th>
                <th>Number of Shares</th>
                <th>Execution Price</th>
                <th>Currency</th>
                <th>Buy/Sell Indicator</th>
                <th>Contra Party Firm Name</th>
                <th>Contra Party Firm CRD</th>
              </tr>
            </thead>
            <tbody>
              {filing.transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    {timeUtil.formatAsDisplayDateTime(
                      utcToZonedTime(transaction.executionDatetime, transaction.executionTimezone)
                    )}
                  </td>
                  <td>{numericUtil.formatInteger(transaction.shares)}</td>
                  <td>{numericUtil.formatCurrency(transaction.executionPrice, 6)}</td>
                  <td>
                    {getLabelFromOptions(transaction.executionPriceCurrency, currencyOptions)}
                  </td>
                  <td>{getLabelFromOptions(transaction.indicator, buySellOptions)}</td>
                  <td>{transaction.counterPartyFirmName}</td>
                  <td>{transaction.counterPartyFirmCrdNumber}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </GridLayout>
      </FilingDetails>
    </div>
  );
};

export default USDTNDetail;
