import {
  ActionPanel,
  Divider,
  InsertDriveFileOutlinedIcon,
  MenuList,
  Typography,
} from '@cmg/design-system';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import { SideNavbarItem } from '../../../offering-side-panel/components/side-bar/components/design-system/SideNavbarItem';
import { useCertificateQueryParams } from '../../hooks/useCertificateQueryParams';
import useRouteWithSearchParams, {
  CertificateRouteName,
} from '../../hooks/useRouteWithSearchParams';
import CertificatesSection from './components/certificate-section/CertificatesSection';

const CertificateLibrarySidePanel: React.FC = () => {
  const match = useRouteMatch(routeFactory.certificateLibraryFirmSidePanel.routePath);
  const history = useHistory();
  const closeRoute = useRouteWithSearchParams(CertificateRouteName.Library);
  const { cmgEntityKey, crmInvestorId } = useCertificateQueryParams();

  const navBarItemRoute = useRouteWithSearchParams(CertificateRouteName.CurrentCertificates, {
    cmgEntityKey,
    crmInvestorId,
  });

  const isOpen = !!match;

  const handleClose = () => {
    history.push(closeRoute!);
  };

  return (
    <ActionPanel
      open={isOpen}
      variant="persistent"
      menu={
        <React.Fragment>
          <Typography variant="h3" padding={theme => theme.spacing(2)}>
            Certificate Library
          </Typography>
          <Divider light />
          <MenuList>
            <SideNavbarItem
              icon={<InsertDriveFileOutlinedIcon />}
              href={navBarItemRoute}
              selected={true}
            >
              Certificates
            </SideNavbarItem>
          </MenuList>
        </React.Fragment>
      }
      header="Certificates"
      dismissible={true}
      onClose={handleClose}
    >
      {isOpen && <CertificatesSection />}
    </ActionPanel>
  );
};

export default CertificateLibrarySidePanel;
