import React from 'react';

import { STotalCell } from './TotalCellRenderer.styles';

export type Props = { value: number | string; valueFormatted?: string };

const TotalCellRenderer: React.FC<Props> = ({ value, valueFormatted }) => {
  return <STotalCell>{valueFormatted ?? value}</STotalCell>;
};

export default TotalCellRenderer;
