import { SelectField, TextAreaField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledFormContainer = styled.div`
  margin: 10px 30px;
`;

export const StyledTextAreaField = styled(TextAreaField)`
  textarea {
    height: 160px;
  }
  label {
    text-transform: none;
    font-size: ${({ theme }) => theme.text.size.mediumSmall};
    color: ${({ theme }) => theme.text.color.light};
    font-weight: ${({ theme }) => theme.text.weight.regular};
  }
`;

export const StyledSelectField = styled(SelectField)`
  margin-bottom: 10px;
  label {
    text-transform: none;
    font-size: ${({ theme }) => theme.text.size.mediumSmall};
    color: ${({ theme }) => theme.text.color.light};
    font-weight: ${({ theme }) => theme.text.weight.regular};
  }
`;
