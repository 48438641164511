import { DeleteIcon, IconButton, TableCell, TableRow } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';

import { InvestorContact } from '../InvestorContactInformationForm.model';

export type Props = {
  index: number;
  item: InvestorContact;
  editing: boolean;
  handleRemoveContact?: () => void;
};

const InvestorContactsRow: React.FC<Props> = ({ item, index, editing, handleRemoveContact }) => {
  return (
    <TableRow>
      <TableCell>
        {item.isCustom && editing ? (
          <FormikTextField
            autoFocus={index === 0}
            name={`sellSideContacts.${index}.name`}
            fullWidth
            showHelperTextInTooltip
          />
        ) : (
          item.name
        )}
      </TableCell>
      <TableCell>
        {item.isCustom && editing ? (
          <FormikTextField
            name={`sellSideContacts.${index}.email`}
            fullWidth
            showHelperTextInTooltip
          />
        ) : (
          item.email
        )}
      </TableCell>
      {editing && (
        <TableCell>
          <IconButton size="small" color="primary" onClick={handleRemoveContact}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default InvestorContactsRow;
