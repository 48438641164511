import {
  AddIcon,
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  PageHeader,
  PageHeaderProps,
  PageLayout,
  Stack,
} from '@cmg/design-system';
import React from 'react';

import InternalRetailDemandEmptyState from './empty-state/InternalRetailDemandEmptyState';

export type Props = Readonly<{
  pageHeaderActions: PageHeaderProps['actions'];
  pageHeaderContent?: React.ReactElement;
  onAddDemand?: () => void;
  isNoDemandAlertVisible?: boolean;
  isValuesNotValidAlertVisible?: boolean;
  invalidFields?: string;
  isEmptyStateVisible?: boolean;
}>;

const InternalRetailDemand: React.FC<Props> = ({
  pageHeaderActions,
  onAddDemand,
  pageHeaderContent,
  isNoDemandAlertVisible = false,
  isValuesNotValidAlertVisible = false,
  invalidFields,
  isEmptyStateVisible = false,
  children,
}) => {
  return (
    <PageLayout
      header={
        <React.Fragment>
          <PageHeader pageTitle="Internal Retail Demand" actions={pageHeaderActions}>
            <Stack direction="row" flexGrow={1}>
              {onAddDemand && (
                <Box flexGrow={1}>
                  <Button variant="text" onClick={onAddDemand} startIcon={<AddIcon />}>
                    Add Demand
                  </Button>
                </Box>
              )}

              {pageHeaderContent}
            </Stack>
          </PageHeader>
          <Divider />
        </React.Fragment>
      }
    >
      <Stack py={2} gap={2}>
        {isNoDemandAlertVisible && (
          <Alert severity="error">
            <AlertTitle>An entry is required to submit Demand</AlertTitle>
            No demand has been added. Please add your retail demand before you submit.
          </Alert>
        )}
        {isValuesNotValidAlertVisible && (
          <Alert severity="error">
            <AlertTitle>An entry is required to submit Demand</AlertTitle>
            {invalidFields}
          </Alert>
        )}
        <Alert>
          Only the total retail demand and total round lots will be visible to the settlement agent,
          when submitted
        </Alert>

        {children}

        {isEmptyStateVisible && onAddDemand && (
          <InternalRetailDemandEmptyState onAddRow={onAddDemand} />
        )}
      </Stack>
    </PageLayout>
  );
};

export default InternalRetailDemand;
