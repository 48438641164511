import { SecondaryButton } from '@cmg/common';
import React from 'react';

import { SUpdateFileWrapper } from './ProspectusForm.styles';

type Props = {
  handleEditFile: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  index: number;
};

export const EditFileInput = ({ handleEditFile, index }: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <SUpdateFileWrapper>
      <input
        hidden
        type="file"
        accept="application/pdf"
        onChange={event => handleEditFile(event, index)}
        ref={fileInputRef}
      ></input>
      <SecondaryButton
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
          }
        }}
      >
        Choose file...
      </SecondaryButton>
    </SUpdateFileWrapper>
  );
};
