import { useCheckAccountTraits } from '@cmg/auth';
import { UUID } from '@cmg/common';
import {
  Alert,
  DomainObject,
  Highlights,
  HighlightsItem,
  HighlightsTextItem,
} from '@cmg/design-system';
import { FC, Fragment } from 'react';

import { isIPOType } from '../../../../common/util/offering/offering-type.util';
import { OfferingStatusChip } from '../../../../design-system/components/data-display/offering-status-chip/OfferingStatusChip';
import {
  OfferingStage as OfferingStageEnum,
  OfferingStatus as OfferingStatusEnum,
  OfferingType,
  Sector,
  SecurityType,
} from '../../../../graphql';
import { sectorLabels } from '../../../constants';
import { getOfferingTypeLabel } from '../../../offering-setup/utils/offeringTypeUtil';
import {
  Offering_OfferingHeader_OfferingProfileTermsPartsFragment,
  Offering_OfferingHeader_OfferingTermsPartsFragment,
} from '../../graphql';
import LastTradeBeforeTitleItems from './components/LastTradeBeforeTitleItems';
import { FOPricingTitleItem, IPOPricingTitleItem } from './components/PricingTitleItems';
import SizeInSharesTitleItem from './components/SizeInSharesTitleItem';
import { OfferingNavbar } from './OfferingNavbar';

export type Props = {
  offeringId?: UUID;
  status?: OfferingStatusEnum;
  stage: OfferingStageEnum;
  issuerName?: string;
  type?: OfferingType;
  isAuthor: boolean;
  sector?: Sector | null;
  isSharedDraft?: boolean;
  isDiscarded: boolean;
  sizeInShares?: number | null;
  securityType?: SecurityType;
  terms?:
    | Offering_OfferingHeader_OfferingTermsPartsFragment
    | Offering_OfferingHeader_OfferingProfileTermsPartsFragment;
  pricingCountryCode?: string;
  pricingCurrencyCode?: string;
};

/**
 * OfferingHeader displays a summary of the offering and a quick access dropdown
 * to my offerings
 */
export const OfferingDomainObject: FC<Props> = ({
  offeringId,
  status,
  stage,
  issuerName,
  type,
  isAuthor,
  sector,
  isSharedDraft = false,
  isDiscarded,
  sizeInShares,
  securityType,
  terms,
  pricingCountryCode,
  pricingCurrencyCode,
}) => {
  const hasCreateInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);
  const isCreateNewOffering = !offeringId;

  const offeringTypeLabel =
    hasCreateInternationalTrait && !pricingCountryCode
      ? '-'
      : getOfferingTypeLabel({
          pricingInstrumentCountryCode: pricingCountryCode,
          offeringType: type!,
          isExecutingOnPlatform: true,
        });

  return (
    <Fragment>
      {issuerName && (
        <DomainObject
          domainHighlights={
            <Highlights
              data-testid="offering-header-highlights"
              title={issuerName}
              titleItems={
                !isCreateNewOffering && (
                  <Fragment>
                    {type && <HighlightsTextItem name="OFFERING TYPE" text={offeringTypeLabel} />}
                    <HighlightsTextItem name="SECTOR" text={sector ? sectorLabels[sector] : '-'} />
                    {securityType && (
                      <SizeInSharesTitleItem
                        securityType={securityType}
                        sizeInShares={sizeInShares}
                      />
                    )}
                    {isIPOType(type) && (
                      <IPOPricingTitleItem
                        terms={terms}
                        pricingCurrencyCode={pricingCurrencyCode}
                      />
                    )}
                    {!isIPOType(type) && (
                      <FOPricingTitleItem terms={terms} pricingCurrencyCode={pricingCurrencyCode} />
                    )}
                    {!isIPOType(type) && (
                      <LastTradeBeforeTitleItems
                        terms={terms}
                        pricingCurrencyCode={pricingCurrencyCode}
                      />
                    )}
                    {status && (
                      <HighlightsItem
                        name="OFFERING STATUS"
                        value={<OfferingStatusChip status={status} />}
                      />
                    )}
                  </Fragment>
                )
              }
            />
          }
          domainNav={
            <OfferingNavbar
              isAuthor={isAuthor}
              isSharedDraft={isSharedDraft}
              isDiscarded={isDiscarded}
              offeringId={offeringId}
              offeringStage={stage}
              offeringType={type}
              offeringStatus={status}
            />
          }
        />
      )}
      {isDiscarded && <Alert color="error">This offering has been discarded</Alert>}
    </Fragment>
  );
};
