import isNil from 'lodash/isNil';
import { CSSObject } from 'styled-components/macro';

import { SplitCellWrapper } from '../../../split-cell-wrapper/SplitCellWrapper';
import { SCellData, SCellSubData } from './CellData.styles';

type Props = {
  value: string | number | null;
  subValue: string | number | null;
  align?: CSSObject['textAlign'];
};

export const CellData: React.FC<Props> = ({ value, subValue, align }) => {
  const subData = subValue ?? '-';
  const isValueNull = isNil(value);

  return (
    <SplitCellWrapper align={align}>
      <SCellData>{isValueNull ? '-' : value}</SCellData>
      <SCellSubData data-testid="sub-data">{isValueNull ? '' : subData}</SCellSubData>
    </SplitCellWrapper>
  );
};
