import { ToastManager } from '@cmg/common';
import React from 'react';

import { FilingConfirmationModalAction } from '../FilingConfirmationModal.model';
import {
  useFilingConfirmationModal,
  UseFilingConfirmationModalResult,
} from './useFilingConfirmationModal';

export type Props = {
  filingName: string;
  isAmending: boolean;
  isValid: boolean;
  getContentMessage?: (action: FilingConfirmationModalAction) => string;
  onSubmit: () => Promise<void>;
};

export function useSubmitFilingConfirmationModal({
  filingName,
  isAmending,
  isValid,
  getContentMessage,
  onSubmit,
}: Props): UseFilingConfirmationModalResult {
  const submit = React.useCallback(async () => {
    if (!isValid) {
      ToastManager.error('There were validation errors');
    }

    await onSubmit();
  }, [isValid, onSubmit]);

  return useFilingConfirmationModal({
    filingName,
    action: isAmending ? 'AMEND' : 'SUBMIT',
    getContentMessage,
    onConfirm: submit,
  });
}
