import { getOperationName } from '@apollo/client/utilities';

import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../../../my-orders/graphql';
import {
  IndicationForm_AcknowledgeCoveredIndicationMutationVariables,
  IndicationForm_IndicationAcknowledgementsDocument,
  useIndicationForm_AcknowledgeCoveredIndicationMutation,
} from '../graphql/__generated__';

export function refetchQueries() {
  const refetchInstitutionalIndicationAcknowledgementsQuery = getOperationName(
    IndicationForm_IndicationAcknowledgementsDocument
  );
  const refetchCoveredIndicationsQuery = getOperationName(MyOrders_CoveredIndicationsDocument);
  return [refetchInstitutionalIndicationAcknowledgementsQuery!, refetchCoveredIndicationsQuery!];
}

export const useAcknowledgeCoveredIndicationMutation = (
  variables: IndicationForm_AcknowledgeCoveredIndicationMutationVariables
) =>
  useIndicationForm_AcknowledgeCoveredIndicationMutation({
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
