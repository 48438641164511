import { ButtonsContainer } from '@cmg/common';
import React from 'react';

import { SContent, SHeader, SHeaderTitle, SWrapper } from './RoadshowCreationStep.styles';

export type Props = {
  title: string;
};

export const StepHeader: React.FC<Props> = ({ title, children }) => (
  <SHeader>
    <SHeaderTitle title={title}>{title}</SHeaderTitle>
    <ButtonsContainer>{children}</ButtonsContainer>
  </SHeader>
);

export default Object.assign(SWrapper, { Header: StepHeader, Content: SContent });
