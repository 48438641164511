import { Skeleton } from '@cmg/design-system';
import React from 'react';

const MyRetailDemandInfoBoxSkeleton: React.FC = () => {
  return (
    <div role="progressbar">
      <Skeleton height={84} />
    </div>
  );
};

export default MyRetailDemandInfoBoxSkeleton;
