import { ODSTable as Table } from '@cmg/common';
import { Stack, Typography } from '@cmg/design-system';

import { OfferingType } from '../../../../../../graphql';
import { Attestation } from '../../context/hooks/certificates/useCertificatesModel';
import { SComplianceTableWrapper } from '../ComplianceCertificates.styles';
import { CertificateTableRow } from './CertificateTableRow';

export type Props = {
  attestation: Attestation;
  offeringType: OfferingType;
};

const CertificateTable: React.FC<Props> = ({ attestation, offeringType }) => {
  return (
    <SComplianceTableWrapper>
      <Table gridTemplateColumns="32px 1fr 150px 1fr 1fr 1fr">
        <Table.TableHeaderCell />
        <Table.TableHeaderCell>Certificate</Table.TableHeaderCell>
        <Table.TableHeaderCell>Status</Table.TableHeaderCell>
        <Table.TableHeaderCell>Signature Date</Table.TableHeaderCell>
        <Table.TableHeaderCell>Expiration</Table.TableHeaderCell>
        <Table.TableHeaderCell>Action</Table.TableHeaderCell>
        {offeringType === OfferingType.Ipo && (
          <CertificateTableRow certificateType="5130/31" attestation={attestation} />
        )}
      </Table>
      {offeringType !== OfferingType.Ipo && (
        <Stack alignItems="center" width="100%" mt={2}>
          <Typography variant="subtitle1" color="text.primary">
            No certificates specified as required
          </Typography>
        </Stack>
      )}
    </SComplianceTableWrapper>
  );
};

export default CertificateTable;
