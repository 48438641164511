import styled from 'styled-components/macro';

export const SBadgeIconWrapper = styled.div`
  display: flex;
  position: relative;
  vertical-align: middle;
`;

export const SBadge = styled.div`
  background-color: ${({ theme }) => theme.background.color.dark};
  color: ${({ theme }) => theme.text.color.white};
  font-size: ${({ theme }) => theme.text.size.small};
  position: absolute;
  left: 70%;
  top: -5px;
  padding: 0 6px;
  border-radius: 20px;
  line-height: 16px;
  height: 16px;
`;
