import { Button, OpenInNewOutlined } from '@cmg/design-system';
import React, { VFC } from 'react';

import { EmptyState } from '../../../../design-system/components/data-display/empty-state/EmptyState';

export type NoRecipientsProps = Readonly<{
  readonly text: string;
  readonly showLink?: boolean;
  readonly linkText?: string;
  readonly linkUrl?: string;
}>;

export const NoRecipients: VFC<NoRecipientsProps> = ({ text, showLink, linkText, linkUrl }) => {
  const isButtonVisible = showLink && linkText && linkUrl;

  return (
    <EmptyState sx={theme => ({ width: theme.spacing(33) })} title="No Recipients" message={text}>
      {isButtonVisible && (
        <Button href={linkUrl} target="_blank" endIcon={<OpenInNewOutlined />}>
          {linkText}
        </Button>
      )}
    </EmptyState>
  );
};
