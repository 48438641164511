import styled from 'styled-components/macro';

export const SDemandCell = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  display: block;
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SAdditionalDemand = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  display: inline-block;
`;
