import { Roadshows_CompanyPartsFragment } from '../../../../../common/graphql';

export const createAddress = (address: Roadshows_CompanyPartsFragment['address']) => {
  if (!address) {
    return '-';
  }

  const { streetLine1, streetLine2, streetLine3, city, state, country } = address;
  return (
    [streetLine1, streetLine2, streetLine3, city, state, country].filter(Boolean).join(', ') || '-'
  );
};
