import { DataGrid, DeepWritable, PaginationQueryParams } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { PaginationDataInput } from '../../../../../graphql';
import { extendedProps, gridOptionsProps } from '../../../common/dataGridProps';
import { Roadshows_RoadshowLimitedPartsFragment } from '../../../common/graphql';
import {
  createColumns,
  paginationInitialValues,
  toPagination,
} from './RoadshowsListDataGrid.model';

export type Props = {
  rows: readonly Roadshows_RoadshowLimitedPartsFragment[];
  isLoading: boolean;
  setPagination: (pagination: PaginationDataInput) => void;
};

const RoadshowsListDataGrid: React.FC<Props> = ({ rows, isLoading, setPagination }) => {
  const theme = useTheme();

  const onPaginationChange = (params: PaginationQueryParams) => {
    setPagination(toPagination(params));
  };

  return (
    <DataGrid
      columns={createColumns(theme, paginationInitialValues)}
      rows={rows as DeepWritable<typeof rows>}
      onPaginationChange={onPaginationChange}
      loading={isLoading}
      resizeBy="grid"
      extended={extendedProps}
      domLayout="normal"
      gridOptions={gridOptionsProps(theme)}
    />
  );
};

export default RoadshowsListDataGrid;
