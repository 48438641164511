import { DateRangeValue } from '@cmg/common';
import React from 'react';

import { useFilterStorage } from '../../../common/hooks/useFilterStorage';
import { multiFilterArray } from '../../../common/util/multiFilterArray';
import { getOfferingTypeFilterCondition } from '../../../common/util/offering/offering-type.util';
import { MyOrdersGridCoveredIndication } from '../components/my-orders-grid/MyOrdersGrid.model';
import { getDaysRangeFromToday } from '../components/my-orders-grid-filters/utils/getPricingDateRange';

export type MyOrdersFilters = {
  issuer: MyOrdersGridCoveredIndication['issuer'];
  investor: MyOrdersGridCoveredIndication['investorInformation']['bankInvestorName'];
  type: string[];
  status: NonNullable<MyOrdersGridCoveredIndication['offeringStatus']>[];
  pricingDate: DateRangeValue;
};

type FilterConditions<TCoveredIndication> = {
  [key in keyof Omit<MyOrdersFilters, 'pricingDate'>]: (indication: TCoveredIndication) => boolean;
};

type UseFiltersParams<TCoveredIndication> = {
  sourceIndications?: TCoveredIndication[];
};

export const DEFAULT_DATE_RANGE = 7;

export const getDefaultFilters = (): MyOrdersFilters => {
  return {
    issuer: '',
    investor: '',
    type: [],
    status: [],
    pricingDate: getDaysRangeFromToday(DEFAULT_DATE_RANGE, 0),
  };
};

export function useMyOrdersFilters<
  TCoveredIndication extends MyOrdersGridCoveredIndication = MyOrdersGridCoveredIndication
>({ sourceIndications = [] }: UseFiltersParams<TCoveredIndication>) {
  const defaultFilters = React.useMemo(() => getDefaultFilters(), []);
  const { filters, setFilters } = useFilterStorage({ defaultFilters, withUrlFilterStorage: true });

  const filterConditions = React.useMemo<FilterConditions<TCoveredIndication>>(
    () => getFilterConditions(filters),
    [filters]
  );

  const filteredData = React.useMemo(
    () => (sourceIndications.length ? multiFilterArray(sourceIndications, filterConditions) : []),
    [sourceIndications, filterConditions]
  );

  return { filters, setFilters, filterConditions, filteredData };
}

export function getFilterConditions(
  filters: MyOrdersFilters
): FilterConditions<MyOrdersGridCoveredIndication> {
  return {
    type: indication => {
      const { offeringType, pricingInstrumentCountryCode } = indication;

      return getOfferingTypeFilterCondition({
        filters: filters.type,
        offeringType,
        pricingInstrumentCountryCode,
      });
    },
    investor: indication => {
      if (!filters.investor) {
        return true;
      }

      return (
        indication.investorInformation.bankInvestorName
          ?.toLowerCase()
          ?.includes(filters.investor.toLowerCase()) || false
      );
    },
    issuer: indication => {
      if (!filters.issuer) {
        return true;
      }
      return indication.issuer?.toLowerCase().includes(filters.issuer.toLowerCase()) || false;
    },
    status: indication => {
      if (!filters.status.length) {
        return true;
      }

      return filters.status.includes(indication.offeringStatus);
    },
  };
}
