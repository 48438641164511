import React from 'react';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../../common/components/layout/panel/Panel';
import { useAccountingContext } from '../common/accounting-context/AccountingContext';
import AccountingDataGrid from '../common/accounting-data-grid/AccountingDataGrid';
import { generateColumns, getTotalRow } from './EconomicsBreakdownPanel.model';

const EconomicsBreakdownPanel: React.FC = () => {
  const { managers } = useAccountingContext();
  const theme = useTheme();

  const columns = React.useMemo(() => generateColumns(theme), [theme]);
  const totalRow = React.useMemo(() => getTotalRow(managers), [managers]);

  return (
    <Panel>
      <Panel.Header title="Economics Breakdown" />
      <Panel.Content>
        <AccountingDataGrid columns={columns} rows={managers} totalRow={totalRow} />
      </Panel.Content>
    </Panel>
  );
};

export default EconomicsBreakdownPanel;
