import { useQuery } from '@apollo/client';

import {
  OfferingSetup_Summary_OfferingShareHistoryDocument,
  OfferingSetup_Summary_OfferingShareHistoryQuery,
  OfferingSetup_Summary_OfferingShareHistoryQueryVariables,
} from '../../graphql';

export const useGetShareOfferingHistory = ({ offeringId }: { offeringId: string }) => {
  return useQuery<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  >(OfferingSetup_Summary_OfferingShareHistoryDocument, { variables: { offeringId } });
};
