import { numericUtil } from '@cmg/common';

import type { DemandGridServerColDef } from '../../types';

/**
 * Exported for testing purposes only
 *
 * @param value
 */
export const valueFormatter: typeof demandAtMarketColDef.valueFormatter = ({ value }) => {
  return numericUtil.getDisplayValueForNumber(value, 0);
};

const demandAtMarketColDef: DemandGridServerColDef<number> = {
  field: 'demandAtMarket',
  headerName: 'Demand @ Market',
  type: ['numericColumn', 'number'],
  filter: 'agNumberColumnFilter',
  valueFormatter,
};

export default demandAtMarketColDef;
