import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  margin: -12px -16px;
  width: 300px;
  cursor: pointer;
  color: ${({ theme }) => theme.text.color.dark};
  & > div {
    border-bottom: ${({ theme }) => theme.designSystem.colors.gray[100]} 1px solid;
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

export const SItem = styled.div`
  padding: 8px 16px;
`;

export const SDescription = styled.div`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
`;
