import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';

const NetPriceInfoBox: React.FC = () => {
  const { offeringPrice, baseGrossSpreadValue, incentiveFeeValue } = useAccountingContext();

  const totalGrossSpread = numericUtil.sum(baseGrossSpreadValue, incentiveFeeValue);

  return (
    <AccountingInfoBox
      gridTemplateColumns="3fr 10px 1.5fr"
      sourceOfData="Sourced from Offering Setup"
    >
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Offering Price
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(offeringPrice, 6)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Total Gross Spread</Table.Cell>
      <Table.Cell paddingSize="S" bold>
        -
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(totalGrossSpread, 6)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Net Price
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForCurrency(
          numericUtil.sum(offeringPrice, numericUtil.negate(totalGrossSpread)),
          6
        )}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default NetPriceInfoBox;
