import { FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const SLayout = styled(FlexContainer)`
  margin: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.background.color.white};
  height: 100%;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  padding: 0 8px 16px 8px;
  flex-grow: 1;
`;

export const SSubTitle = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SGridContainer = styled.div`
  height: 100%;
  min-height: 450px;

  // Adding a larger border to the pinned left header column
  .ag-theme-balham .ag-pinned-left-header {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Adding a larger border to the pinned right header column
  .ag-theme-balham .ag-pinned-right-header {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Removing borders from the header rows.
  .ag-header {
    border: 0;
  }

  // Removing borders in all grid cells and rows
  .ag-theme-balham .ag-cell,
  .ag-row {
    border-bottom-width: 0;
    border-top-width: 0;
  }

  // Setting the width of the wrapper around the Investor name cell
  // to 100% so that we can properly handle text overflow (and add ellipsis).
  .bd-agent-cell .ag-react-container,
  .ag-pinned-left-cols-container .ag-react-container,
  .ag-pinned-right-cols-container .ag-react-container,
  .ag-pinned-right-floating-top .ag-react-container {
    width: 100%;
  }
`;

export const SIconWrapper = styled.span`
  padding-right: 8px;
`;
