import { FlexContainer } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import routeFactory from '../../../../common/util/routeFactory';
import offeringSidePanelSellSideRouteFactory from '../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { MyOrdersGridRendererProps } from '../my-orders-grid/MyOrdersGrid.model';

const StyledIssuerName = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
`;

const StyledIssuerNameText = styled.div`
  color: ${({ theme }) => theme.color.blue1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * OfferingSidePanelLinkRenderer cell renderer that wraps the value with a link that opens the offering side panel
 */
export const OfferingSidePanelLinkRenderer: React.FC<MyOrdersGridRendererProps> = ({
  value,
  data,
  context,
}) => {
  return (
    <Link
      data-test-id={xcSelectors.myOrdersCoveredIndicationSidePanelLink.testId}
      to={routeFactory.offeringSidePanel.getUrlPath({
        offeringId: data.offeringId,
        prefixRoutePath: context.matchUrl,
        sidePanelRoutePath:
          offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({
            cmgEntityKey: data.investorInformation.cmgEntityKey!,
            indicationId: data.id,
          }),
      })}
    >
      {value ?? '-'}
    </Link>
  );
};

/**
 * IssuerNameRenderer cell renderer for the issuer name with styling overrides that are being applied
 * to the ag-react-container. Note: it has to be a class component for this to work as expected.
 * Revision indicator will display on the far right if the offering has a revisedFilingsCount > 0
 *
 * Consumers can override properties by setting new properties in the cellRenderParams on the column definition
 */
export class IssuerNameRenderer extends React.Component<MyOrdersGridRendererProps> {
  getReactContainerStyle() {
    return {
      width: '100%',
    };
  }
  render() {
    const { value, data, context } = this.props;
    return (
      <StyledIssuerName direction="row">
        <StyledIssuerNameText>
          <OfferingSidePanelLinkRenderer value={value} data={data} context={context} />
        </StyledIssuerNameText>
      </StyledIssuerName>
    );
  }
}
