import { SyndicateGridChangeType } from '../../../../graphql';
import type { RecentUpdate } from '../hooks/useRecentUpdates';
import { AllocationChangeContent } from './AllocationChangeContent';
import { AllocationSetSharedChangeContent } from './AllocationSetSharedChangeContent';
import { IndicationCreatedChangeContent } from './IndicationCreatedChangeContent';
import { IndicationDuplicateStatusChangeContent } from './IndicationDuplicateStatusChangeContent';
import { IndicationInterestLevelChangeContent } from './IndicationInterestLevelChangeContent';
import { IndicationStatusChangeContent } from './IndicationStatusChangeContent';

export type RecentUpdateContentProps = Readonly<{
  update: RecentUpdate;
  pricingCurrencyCode: string;
}>;

export const ChangeTypeContentMap = {
  [SyndicateGridChangeType.AllocationChanged]: AllocationChangeContent,
  [SyndicateGridChangeType.AllocationSetShared]: AllocationSetSharedChangeContent,
  [SyndicateGridChangeType.IndicationCreated]: IndicationCreatedChangeContent,
  [SyndicateGridChangeType.IndicationInterestLevelChanged]: IndicationInterestLevelChangeContent,
  [SyndicateGridChangeType.DuplicationStatusChanged]: IndicationDuplicateStatusChangeContent,
  [SyndicateGridChangeType.IndicationStatusChanged]: IndicationStatusChangeContent,
} as const;
