import { UUID } from '@cmg/common';

import { useFeatureToggles } from '../../../../../../../common/config';
import { OfferingType } from '../../../../../../../graphql';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../../hooks/useIdentifyOrderBookPersona';
import { Compliance_BuySide_AttestationQuery } from '../buy-side/graphql';
import { Compliance_Syndicate_AttestationQuery } from '../syndicate/graphql';
import { useBuySideCertificatesModel } from './buy-side/useCertificatesBuySideModel';
import { useSalesAndTradingCertificatesModel } from './sale-and-trading/useCertificatesSalesAndTradingModel';
import { useSyndicateCertificatesModel } from './syndicate/useCertificatesSyndicateModel';

export type Attestation =
  | Compliance_Syndicate_AttestationQuery['attestation']
  | Compliance_BuySide_AttestationQuery['myAttestation'];

export type ComplianceWarning = {
  hasComplianceWarning: boolean;
  canByPassComplianceWarning: boolean;
  message: string | null;
  redirectUrl: string | null;
  tooltipMessage: string | null;
};

export type CertificateModel = {
  canRead: boolean;
  attestation: Attestation | null;
  loading: boolean;
  offeringType?: OfferingType;
  warning: ComplianceWarning;
};

export const certificateModelDefaults: CertificateModel = {
  canRead: false,
  attestation: null,
  loading: false,
  offeringType: undefined,
  warning: {
    hasComplianceWarning: false,
    canByPassComplianceWarning: false,
    message: null,
    redirectUrl: null,
    tooltipMessage: null,
  },
};

export type Props = {
  offeringId: UUID;
  cmgEntityKey: string | undefined;
};

export type PersonaProps = {
  type: OrderBookPersonaType;
  isOrderBookAttestationOn: boolean;
};
/*
The useComplianceCertificatesModel is in charge of handling all the compliance certificates data, and also helps us have a 
centralized place that we can use to decide what actions we can do with the certificates section.

For example: canRead: This will help decide if compliance certificates should be displayed or not, 
all the logic for deciding to display or not should be inside the property
*/
export const useComplianceCertificatesModel = ({
  offeringId,
  cmgEntityKey,
}: Props): CertificateModel => {
  const { type } = useIdentifyOrderBookPersona({ offeringId });
  const { isOrderBookAttestationOn } = useFeatureToggles();
  const syndicateModel = useSyndicateCertificatesModel({
    type,
    offeringId,
    cmgEntityKey,
    isOrderBookAttestationOn,
  });
  const salesAndTradingModel = useSalesAndTradingCertificatesModel({
    type,
    offeringId,
    cmgEntityKey,
    isOrderBookAttestationOn,
  });
  const buySideModel = useBuySideCertificatesModel({
    type,
    offeringId,
    isOrderBookAttestationOn,
  });

  // If the attestation is not on we will not render attestation
  if (!isOrderBookAttestationOn) {
    return certificateModelDefaults;
  }

  switch (type) {
    case OrderBookPersonaType.SYNDICATE: {
      return syndicateModel;
    }
    case OrderBookPersonaType.SALES_AND_TRADING: {
      return salesAndTradingModel;
    }
    case OrderBookPersonaType.BUY_SIDE: {
      return buySideModel;
    }
    default: {
      return certificateModelDefaults;
    }
  }
};
