import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { Roadshows_RoadshowLimitedPartsFragment } from '../../../../common/graphql';

export type Props = {
  value: string;
  data: Roadshows_RoadshowLimitedPartsFragment;
};

const RoadshowNameCellRenderer: React.FC<Props> = ({ value, data }) => {
  return (
    <Link
      to={routeFactory.roadshowCalendar.getUrlPath({ roadshowId: data.id })}
      data-test-id="link to roadshow calendar"
    >
      {value}
    </Link>
  );
};

export default RoadshowNameCellRenderer;
