import { UUID } from '@cmg/common';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import CancelIndicationConfirmDialogComponent from '../../../common/cancel-indication-confirm-dialog/CancelIndicationConfirmDialog';
import { useUpdateCoveredIndicationStatus } from '../hooks/useUpdateCoveredIndicationStatus';

export type Props = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  offeringId: UUID;
  indicationId: UUID;
}>;

const CancelCoveredIndicationConfirmDialog: React.FC<Props> = ({
  indicationId,
  isOpen,
  onClose,
  offeringId,
}) => {
  const [updateStatus, isUpdatingStatus] = useUpdateCoveredIndicationStatus(
    offeringId,
    indicationId,
    onClose
  );

  return (
    <CancelIndicationConfirmDialogComponent
      isOpen={isOpen}
      isCancelling={isUpdatingStatus}
      onCancel={() => updateStatus(IndicationStatus.Cancelled)}
      onClose={onClose}
    />
  );
};

export default CancelCoveredIndicationConfirmDialog;
