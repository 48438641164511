import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  isOpen: boolean;
  isCancelling: boolean;
  onCancel: () => void;
  onClose: () => void;
}>;

const CancelIndicationConfirmDialog: React.FC<Props> = ({
  isOpen,
  isCancelling,
  onCancel,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Cancel Indication</DialogTitle>

      <DialogContent dividers>Are you sure you want to cancel this indication?</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isCancelling}>
          Close
        </Button>
        <LoadingButton variant="contained" onClick={onCancel} loading={isCancelling}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelIndicationConfirmDialog;
