import type { DynamicColDefsFactory } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment } from '../../graphql/__generated__';
import type { DemandConfigValues, DemandGridDataContext } from '../types';
import getAllocationColDefs, { allocationColIdMatch } from './allocations';
import getDemandLevelColDefs, { demandLevelColIdMatch } from './demand-levels';
import {
  extendedDataFieldNameMatch,
  getExtendedDataComplianceColDefs,
  getExtendedDataInvestorCommentsColDefs,
  getExtendedDataInvestorRegionColDefs,
  getExtendedDataInvestorTypeColDefs,
  getExtendedDataOthersColDefs,
  getExtendedDataRevenueColDefs,
} from './extended-data';

export { default as attestationColDef } from '../columns/attestation';
export { default as prospectusColDef } from '../columns/prospectus';
export { default as salesCoverageColDef } from '../columns/sales-coverage';
export { default as acknowledgementsColDef } from './acknowledgements';
export { default as allocationAckStatusColDef } from './allocation-ack-status';
export { default as allocationConfirmedByColDef } from './allocation-confirmed-by';
export { allocationColIdMatch, default as getAllocationColDefs } from './allocations';
export { default as getBndAgentColDef } from './bnd-agent';
export { default as cmgEntityNameColDef } from './cmg-entity-name';
export { default as createdAtColDef } from './created-at';
export { default as demandAtMarketColDef } from './demand-at-market';
export { demandLevelColIdMatch, default as getDemandLevelColDefs } from './demand-levels';
export { default as demandMaxColDef } from './demand-max';
export {
  extendedDataFieldNameMatch,
  getExtendedDataComplianceColDefs,
  getExtendedDataInvestorCommentsColDefs,
  getExtendedDataInvestorRegionColDefs,
  getExtendedDataInvestorTypeColDefs,
  getExtendedDataOthersColDefs,
  getExtendedDataRevenueColDefs,
} from './extended-data';
export { default as firmKeyColDef } from './firm-key';
export { default as autoGroupColumnDef } from './investor';
export { default as modifiedAtDolDef } from './modified-at';
export { default as getNotesColDefs } from './notes';
export { default as onPlatformColDef } from './on-platform';
export { default as relationshipColDef } from './relationship';
export { default as rowAlertColDef } from './row-alert';
export { default as statusColDef } from './status';
export { default as submittedByColDef } from './submitted-by';
export { default as typeColDef } from './type';

export const allocationSetColDefsFactory =
  (
    allocationSets: DemandGridDataContext['allocationSets'],
    oidcUserCmgEntityKey: string | null
  ): DynamicColDefsFactory =>
  currentColDefs => {
    const allocationColDefs = getAllocationColDefs(allocationSets, oidcUserCmgEntityKey);

    // Replace Allocation columns
    return [
      ...currentColDefs.filter(({ colId }) => !colId?.match(allocationColIdMatch)),
      ...allocationColDefs,
    ];
  };

export const demandLevelColDefsFactory =
  (demandConfig: DemandConfigValues | null): DynamicColDefsFactory =>
  currentColDefs => {
    const demandLevelColDefs = getDemandLevelColDefs(demandConfig);

    // Replace Demand level columns
    return [
      ...currentColDefs.filter(({ colId }) => !colId?.match(demandLevelColIdMatch)),
      ...demandLevelColDefs,
    ];
  };

export const extendedDataColDefsFactory =
  (
    investorExtensionSchema:
      | readonly OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment[]
      | null
  ): DynamicColDefsFactory =>
  currentColDefs => {
    const extendedDataComplianceColDefs = getExtendedDataComplianceColDefs(investorExtensionSchema);
    const extendedDataInvestorCommentsColDefs =
      getExtendedDataInvestorCommentsColDefs(investorExtensionSchema);
    const extendedDataInvestorRegionColDefs =
      getExtendedDataInvestorRegionColDefs(investorExtensionSchema);
    const extendedDataInvestorTypeColDefs =
      getExtendedDataInvestorTypeColDefs(investorExtensionSchema);
    const extendedDataOthersColDefs = getExtendedDataOthersColDefs(investorExtensionSchema);
    const extendedDataRevenueColDefs = getExtendedDataRevenueColDefs(investorExtensionSchema);

    // Replace Extended data columns
    return [
      ...currentColDefs.filter(({ field }) => !field?.match(extendedDataFieldNameMatch)),
      ...extendedDataComplianceColDefs,
      ...extendedDataInvestorCommentsColDefs,
      ...extendedDataInvestorRegionColDefs,
      ...extendedDataInvestorTypeColDefs,
      ...extendedDataOthersColDefs,
      ...extendedDataRevenueColDefs,
    ];
  };
