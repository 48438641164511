import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';

export type Props = Readonly<{ offeringId: UUID }>;

export const useMyRetailDemandRoute = ({
  offeringId,
}: Props): ReactRouterDomainNavTabProps | null => {
  const canAccessRetailDemand = useCheckPermissions([
    permissionsByEntity.RetailDemand.READ,
    permissionsByEntity.RetailRetention.READ,
  ]);
  const href = routeFactory.orderBookMyRetailDemand.getUrlPath({ offeringId });

  if (!canAccessRetailDemand) {
    return null;
  }

  return {
    href,
    label: 'My Retail Demand',
  };
};
