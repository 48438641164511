import {
  SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
  useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
} from '../graphql';

export type UpdateNonSyndicateSellingGroupInvitationWireMutation =
  SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation;

export const useUpdateNonSyndicateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation();
};
