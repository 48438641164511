import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';
import { Icon, IconButton, numericUtil, timeUtil } from '@cmg/common';
import { isAfter, isBefore } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Link } from 'react-router-dom';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import routeFactory from '../../../../common/util/routeFactory';
import { OfferingStatusChip } from '../../../../design-system/components/data-display/offering-status-chip/OfferingStatusChip';
import { offeringTypeLabels } from '../../../../types/domain/offering/constants';
import { previousTwoWeeks } from '../../../calendar/calendar.model';
import { stepIds } from '../../../offering-setup/constants';
import { useMyOfferingsQuickAccess_PublishedOfferingsQuery } from './graphql';
import {
  SMyOfferingsQuickAccess,
  SNoOfferings,
  SOfferingAction,
  SOfferingActions,
  SOfferingDetails,
  SOfferingIssuer,
  SOfferingListItem,
  SOfferingSize,
  SOfferingsList,
  SOfferingStatus,
  SOfferingType,
  SQuickAccessHeader,
  SQuickAccessHeaderTitle,
  SSymbol,
  StyledLinkIcon,
} from './MyOfferingsQuickAccess.styles';

export type Props = {
  onClose: () => void;
};

/**
 * MyOfferingsQuickAccess displays the content of my offerings quick access dropdown
 *
 * Offerings displayed grouped by expected pricing date.
 * Upon hover offering detail links will be displayed.
 */
const MyOfferingsQuickAccess: React.FC<Props> = ({ onClose }) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const startDate = timeUtil.formatAsISODate(previousTwoWeeks(new Date()))!;
  const endDate = timeUtil.formatAsISODate(new Date())!;
  const hasFullOfferingPermissions = useCheckPermissions([permissionsByEntity.Offering.FULL]);
  const { data, error, loading } = useMyOfferingsQuickAccess_PublishedOfferingsQuery({
    variables: {
      startDate,
      endDate,
      cmgEntityKey: oidcUserCmgEntityKey,
    },
  });
  // filter only the live offerings with pricing date
  // within the last two weeks
  const filteredLiveOfferings = React.useMemo(
    () =>
      data
        ? data.livePublishedOfferings.data.filter(offering => {
            const pricingDate = new Date(offering.pricingDate!);
            const withinLastTwoWeeks =
              isAfter(previousTwoWeeks(new Date()), pricingDate) &&
              isBefore(pricingDate, new Date());
            return !withinLastTwoWeeks;
          })
        : [],
    [data]
  );
  const mergedOfferings = React.useMemo(
    () =>
      data
        ? [
            ...sortBy(filteredLiveOfferings, 'issuerName'),
            ...sortBy(data?.pricedPublishedOfferings.data, 'issuerName'),
          ]
        : [],
    [data, filteredLiveOfferings]
  );

  return (
    <SMyOfferingsQuickAccess>
      <SQuickAccessHeader>
        <SQuickAccessHeaderTitle>
          <h2>My Offerings</h2>
          <IconButton icon={{ name: 'times', size: '2x' }} onClick={onClose} />
        </SQuickAccessHeaderTitle>
        <div>
          <Icon name="info-circle" /> List of offerings that your firm has participated in from the
          last two weeks.
        </div>
      </SQuickAccessHeader>
      {error && <ServerErrorsBanner error={error} />}
      <SOfferingsList>
        <Spinner show={loading} />
        {!loading && !mergedOfferings.length && (
          <SNoOfferings>
            <SNoOfferings.Icon />
            <SNoOfferings.Title>No Offerings at this point</SNoOfferings.Title>
            <SNoOfferings.Subtitle>Any available deals will appear here.</SNoOfferings.Subtitle>
          </SNoOfferings>
        )}
        {mergedOfferings.map(offering => (
          <SOfferingListItem key={offering.id} data-testid={offering.id}>
            <SOfferingDetails>
              <SOfferingIssuer>
                {offering.issuerName}
                {' - '}
                <SSymbol>{offering.pricingInstrumentStockSymbol}</SSymbol>
              </SOfferingIssuer>
              <SOfferingType>{offeringTypeLabels[offering.type]}</SOfferingType>
              <SOfferingStatus>
                <OfferingStatusChip status={offering.status} />
              </SOfferingStatus>
              <SOfferingSize>
                {numericUtil.formatCurrencyInMillions(offering.sizeInCurrency)}
              </SOfferingSize>
            </SOfferingDetails>
            <SOfferingActions>
              <SOfferingAction>
                <Link
                  to={getOfferingDetailLink({
                    offeringId: offering.id,
                    isAuthor: offering.isAuthor,
                    hasFullOfferingPermissions,
                  })}
                >
                  <StyledLinkIcon name="file-alt" />
                  Offering Details
                </Link>
              </SOfferingAction>
              {/* @todo is this part of v1 ? */}
              <SOfferingAction style={{ display: 'none' }}>
                <Link to="/">
                  <StyledLinkIcon name="comment-alt-lines" />
                  Feedback
                </Link>
              </SOfferingAction>
              <SOfferingAction>
                <Link to={routeFactory.orderBook.getUrlPath({ offeringId: offering.id })}>
                  <StyledLinkIcon name="file-invoice-dollar" />
                  Order Book
                </Link>
              </SOfferingAction>
            </SOfferingActions>
          </SOfferingListItem>
        ))}
      </SOfferingsList>
    </SMyOfferingsQuickAccess>
  );
};

export default MyOfferingsQuickAccess;

type GetOfferingDetailLink = {
  offeringId: string;
  isAuthor: boolean;
  hasFullOfferingPermissions: boolean;
};

function getOfferingDetailLink({
  isAuthor,
  offeringId,
  hasFullOfferingPermissions,
}: GetOfferingDetailLink) {
  if (isAuthor && hasFullOfferingPermissions) {
    return routeFactory.offeringSetup.getUrlPath({
      stepId: stepIds.SUMMARY,
      offeringId,
    });
  }
  return routeFactory.offeringDetails.getUrlPath({ offeringId });
}
