import { FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledFlexContainer = styled(FlexContainer)`
  padding: 16px;
  background: ${({ theme }) => theme.designSystem.colors.darkBlue[100]};
  align-items: baseline;
`;

export const SSelectedLabel = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.darkGray};
  flex-grow: 1;
  margin-left: 56px;
`;
