import {
  SyndicateWires_UpdateNonSyndicateTerminationWireMutation,
  useSyndicateWires_UpdateNonSyndicateTerminationWireMutation,
} from '../graphql';

export type UpdateNonSyndicateTerminationWireMutation =
  SyndicateWires_UpdateNonSyndicateTerminationWireMutation;

export const useUpdateNonSyndicateTerminationWireMutation = () => {
  return useSyndicateWires_UpdateNonSyndicateTerminationWireMutation();
};
