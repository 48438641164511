import { ISODate } from '@cmg/common';
import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const recipientUserDataValidationSchema = yup
  .object()
  .shape({
    recipient: yup.string().label('Recipient').required(),
    retention: yup.number().nullable().label('Retention').min(0).required(),
  })
  .nullable();

export const validationSchema = yup
  .object()
  .shape({
    masterSdaDate: yup.date().label('Master SDA date').typeError('Invalid date format').required(),
    recipientsUserData: yup
      .array()
      .of(recipientUserDataValidationSchema)
      .label('Recipients')
      .min(1)
      .required()
      .test(
        'non-nullable-data',
        'At least one recipient must have user data',
        (value: Values['recipientsUserData'] | undefined) => {
          return !!value?.some(data => data !== null);
        }
      ),
  })
  .concat(wiresFormDisclaimerSchema);

export type RetentionRecipientUserData = {
  recipient: string;
  retention: number | null;
};

export type Values = {
  masterSdaDate: ISODate;
  disclaimer: string | null;
  recipientsUserData: (RetentionRecipientUserData | null)[];
};
