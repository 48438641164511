import { MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { offeringStatusLabels } from '../../../../../types/domain/offering/constants';

export const SStatusFilter = styled.div`
  min-width: 240px;
  max-width: 400px;
  flex-grow: 1;
  flex: 1 1 0px;
`;

export type Props = {
  statuses: Array<string>;
  onChange: (value: string[]) => void;
  values: string[];
};

/**
 * StatusFilter is responsible for setting the offering status filter value from
 * a list of provided statuses
 */
const StatusFilter: React.FC<Props> = ({ statuses, onChange, values }) => {
  const options = statuses.map<Option>(status => ({
    label: offeringStatusLabels[status],
    value: status,
  }));

  return (
    <SStatusFilter>
      <MultiSelect<string>
        aria-label="status filter"
        options={options}
        onChange={onChange}
        value={values}
        placeholder="Status..."
      />
    </SStatusFilter>
  );
};

export default StatusFilter;
