import { Option } from '@cmg/common';
import * as Yup from 'yup';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import { CreateDsFilingInput, DealStatus } from '../../../../../graphql';
import {
  CommonFields,
  commonFilingSchemaShape,
  createInitialCommonFilingValues,
  InitialFilingValuesParameters,
} from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { filingNames } from '../../../common/filing-name-utils';
import { RegulatoryFilings_DsFilingPartsFragment } from '../graphql';

/**
 * Types
 */

export type DSFilingFormValues = CommonFields & {
  dealStatus: DealStatus | null;
};

export const dealStatusOptions: Option<DealStatus>[] = [
  {
    label: 'Cancelled',
    value: DealStatus.Cncld,
  },
  {
    label: 'Postponed',
    value: DealStatus.Pspnd,
  },
  {
    label: 'Completed',
    value: DealStatus.Cmplt,
  },
];

/**
 * Form Schema
 */

export const formSchema = Yup.object().shape({
  ...commonFilingSchemaShape,
  dealStatus: Yup.string().label(filingNames.DS.full).required(),
});

/**
 * Filing => Form
 */

export const mapFilingToFormValues = (
  values: RegulatoryFilings_DsFilingPartsFragment
): DSFilingFormValues => ({
  contactName: values.contactName,
  contactTitle: values.contactTitle,
  emailAddress: values.emailAddress,
  firmCrdNumber: values.firmCrdNumber,
  firmMpid: values.firmMpid,
  firmName: values.firmName,
  telephoneNumber: values.telephoneNumber,
  dealStatus: values.dealStatus,
  issuerName: values.issuerName,
  issuerSymbol: values.issuerSymbol,
});

/**
 * Form => Filing
 */

export const mapFormValuesToFiling = (
  values: DSFilingFormValues,
  recipients: string[],
  shouldAddSenderToBcc: boolean
): CreateDsFilingInput => ({
  recipients,
  filingFormData: {
    contactName: values.contactName,
    contactTitle: values.contactTitle,
    emailAddress: values.emailAddress,
    firmCrdNumber: values.firmCrdNumber,
    firmMpid: values.firmMpid,
    firmName: values.firmName,
    telephoneNumber: values.telephoneNumber,
    dealStatus: values.dealStatus!,
    issuerSymbol: values.issuerSymbol!,
  },
  submissionTimeZone: generatePayloadDateTimeData().sendDateTimeZone,
  shouldAddSenderToBcc,
});

/**
 * Initial values
 */

export const createInitialValues = ({
  filing,
  underwriter,
  lastCommonValues,
  offeringDetail,
}: InitialFilingValuesParameters<RegulatoryFilings_DsFilingPartsFragment> = {}): DSFilingFormValues => {
  if (filing) {
    return mapFilingToFormValues(filing);
  }
  return {
    ...createInitialCommonFilingValues({ underwriter, lastCommonValues, offeringDetail }),
    dealStatus: null,
  };
};
