import { Typography } from '@cmg/design-system';

export type TextareaCharacterCountProps = {
  count: number;
  max: number;
  hasError?: boolean;
};

export const TextareaCharacterCount = ({ count, max, hasError }: TextareaCharacterCountProps) => {
  const hasErrorOrHasTextLimitExceeded = hasError || count > max;
  return (
    <Typography
      variant="body2"
      marginLeft={theme => theme.spacing(1.75)}
      color={theme => (hasErrorOrHasTextLimitExceeded ? theme.palette.error.main : undefined)}
    >
      {count}/{max} Characters
    </Typography>
  );
};
