import { useAuth } from '@cmg/auth';
import { AgGridColumnApi, DataGridClient, Optional, theme, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { AgGridEvent, TabToNextCellParams } from 'ag-grid-community';
import React, { MutableRefObject, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useGridReady } from '../../../../../common/hooks/useGridReady';
import { useIsUserSettlementAgent } from '../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { tabToNextCell, useCellFocused } from '../../../../../common/util/verticalTabbing';
import { IndicationStatus, OfferingType } from '../../../../../graphql';
import { nonEditableOBGridOptions } from '../../../../constants';
import { InstitutionalDemandSkeleton } from './components/InstitutionalDemandSkeleton';
import { getRowAlertsObject } from './components/row-alert/RowAlert.model';
import { useInstitutionalDemandGridReferenceContext } from './context/InstitutionalDemandGridReferenceContext';
import { useInstitutionalDemandPreferencesContext } from './context/InstitutionalDemandPreferencesContext';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from './graphql';
import { useGenerateGridColumns } from './grid-columns/InstitutionalDemandGridColumns';
import { useDeselectAllRows, useExportToCSV } from './hooks/InstitutionalDemandHooks';
import { getSyndicateManagerMap } from './InstitutionalDemandContainer.model';
import {
  extendedGridOptions,
  frameworkComponents,
  getDemandPriceList,
  getIndicationDemandTotals,
  getTotalsPinnedRow,
  isInstitutionalDemandGridSelectable,
} from './InstitutionalDemandGrid.model';
import { SGrid, SGridWrapper } from './InstitutionalDemandGrid.styles';
import {
  IndicationWithDemandLevels,
  InstitutionalDemandGridContext,
  InstitutionalDemandGridOfferingColumnConfig,
  InstitutionalGridSummary,
} from './types';

export type PropsInternal = {
  indications: IndicationWithDemandLevels[];
  gridSummaryData: InstitutionalGridSummary;
  gridOfferingConfig: InstitutionalDemandGridOfferingColumnConfig | null;
  exportToCsv: MutableRefObject<Function>;
  onRowSelected: (ids: string[]) => void;
  deselectRows: MutableRefObject<Function>;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  offeringType?: OfferingType;
  pricingCurrencyCode?: string;
  loadingMap: {
    grid: boolean;
    secondaryQueriesMap: {
      prospectusData: boolean;
      investorsExtendedData: boolean;
      attestationStatuses: boolean;
    };
  };
};

export const InstitutionalDemandGridInternal: React.FC<PropsInternal> = ({
  gridSummaryData,
  gridOfferingConfig,
  exportToCsv,
  onRowSelected,
  deselectRows,
  investorsExtendedData,
  offeringType,
  pricingCurrencyCode,
  loadingMap,
  indications,
}) => {
  const {
    url,
    params: { offeringId },
  } = useRouteMatch<{ offeringId: UUID }>();
  const gridRef = useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);
  const onCellFocused = useCellFocused();
  const { setOnDuplicate, onDuplicate } = useInstitutionalDemandGridReferenceContext();
  const { oidcUserCmgEntityKey } = useAuth();

  const handleOnMarkDuplicate = React.useCallback(
    params => {
      if (gridRef.current && params) {
        const survivorId = params.survivorId;
        const api = gridRef.current.api;
        const survivingNode = api.getRenderedNodes().find(n => n.data.id === survivorId);

        const dupeNodes = api
          .getRenderedNodes()
          .filter(n => survivingNode?.data.duplicateIndicationIds.includes(n.data.id));

        if (dupeNodes.length > 0) {
          dupeNodes.forEach(node => {
            node.setRowHeight(0);
          });
          api.onRowHeightChanged();
        }
      }
    },
    [gridRef]
  );

  React.useEffect(() => {
    setOnDuplicate(() => handleOnMarkDuplicate);
  }, [setOnDuplicate, handleOnMarkDuplicate, onDuplicate]);

  const {
    pinnedColumns,
    setPinnedColumns,
    columnsOrder,
    setColumnsOrder,
    visibleColumns,
    rowSortOrder,
    setRowSortOrder,
    columnWidths,
    setColumnWidths,
  } = useInstitutionalDemandPreferencesContext();
  const { draftSets, finalSet, isFinalAllocationSetReleased, indicationDemands } = gridSummaryData;

  const demandIncrements = React.useMemo(
    () => getDemandPriceList(indicationDemands ?? []),
    [indicationDemands]
  );

  // Given the list of indications with share counts at all of the demand levels,
  // get the total share counts for indications at each demand level.
  const { demandMaxTotal, demandAtMarketTotal, demandLevelTotals } = React.useMemo(
    () => getIndicationDemandTotals(indications, demandIncrements),
    [demandIncrements, indications]
  );

  const rowsPinnedToTop = React.useMemo(
    () =>
      indications.length > 0
        ? [
            getTotalsPinnedRow(
              demandMaxTotal,
              demandAtMarketTotal,
              demandLevelTotals,
              offeringId,
              indications,
              draftSets,
              finalSet
            ),
          ]
        : [],
    [
      demandAtMarketTotal,
      demandLevelTotals,
      demandMaxTotal,
      draftSets,
      indications,
      finalSet,
      offeringId,
    ]
  );

  const { isSettlementAgent } = useIsUserSettlementAgent({ offeringId });

  const rowAlertsObject = React.useMemo(
    () =>
      getRowAlertsObject({
        indicationDemands: indications,
        isUserSettlementAgent: isSettlementAgent!,
        offeringType: offeringType!,
        oidcUserCmgEntityKey: oidcUserCmgEntityKey!,
      }),
    [indications, isSettlementAgent, offeringType, oidcUserCmgEntityKey]
  );

  const displayDupeGroupColumn = React.useMemo(() => {
    if (indications.length > 0) {
      return indications.filter(i => i.duplicateOfIndicationId).length > 0;
    }
    return false;
  }, [indications]);

  const gridColumns = useGenerateGridColumns({
    offeringId,
    demandIncrements,
    draftSets,
    visibleColumns,
    columnsOrder,
    isFinalAllocationSetReleased,
    investorsExtendedData,
    finalAllocationExists: !!gridSummaryData.finalSet,
    offeringType,
    pricingCurrencyCode,
    pinnedColumns,
    displayDupeGroupColumn,
    rowAlertsObject,
  });

  const processSortChanged = React.useCallback(
    ({ columnApi }: { columnApi: AgGridColumnApi }) => {
      const sortState = columnApi
        .getColumnState()
        .filter(column => {
          return column.sort != null;
        })
        .map(column => {
          return { colId: column.colId, sort: column.sort, sortIndex: column.sortIndex };
        });
      setRowSortOrder(sortState);
    },
    [setRowSortOrder]
  );

  const processColumnResized = React.useCallback(
    (event: { source: string; finished: boolean }) => {
      if (event.finished && event.source === 'uiColumnDragged') {
        const columnWidths = gridRef.current?.columnApi.getColumnState().map(column => {
          return {
            key: column.colId!,
            newWidth: column.width!,
          };
        });
        setColumnWidths(columnWidths);
      }
    },
    [setColumnWidths]
  );

  const processGridSizingAndSort = React.useCallback(
    ({ columnApi }: { columnApi: AgGridColumnApi }) => {
      if (columnWidths) {
        columnApi.setColumnWidths(columnWidths);
      } else {
        columnApi.autoSizeAllColumns(false); // skipHeader set to false in param
      }
      columnApi.applyColumnState({
        state: rowSortOrder,
      });
    },
    [columnWidths, rowSortOrder]
  );

  useDeselectAllRows(deselectRows, gridRef);
  useExportToCSV(exportToCsv, gridRef);

  const context = React.useMemo<InstitutionalDemandGridContext>(() => {
    return {
      matchUrl: url,
      offeringId: offeringId,
      isFinalAllocationSetReleased,
      loadingProspectusData: loadingMap.secondaryQueriesMap.prospectusData,
      syndicateManagers: getSyndicateManagerMap(gridOfferingConfig?.syndicate.managers ?? []),
    };
  }, [
    url,
    offeringId,
    isFinalAllocationSetReleased,
    loadingMap.secondaryQueriesMap.prospectusData,
    gridOfferingConfig?.syndicate.managers,
  ]);

  const getRowNodeId = React.useCallback((row: IndicationWithDemandLevels) => row.id, []);
  const getRowsPinnedToTop = React.useCallback(() => rowsPinnedToTop, [rowsPinnedToTop]);

  const handleSelectionChange = React.useCallback(
    (selectedRows: IndicationWithDemandLevels[]) => {
      onRowSelected(selectedRows.filter(p => p.status === IndicationStatus.Active).map(p => p.id));
      gridRef.current?.api.refreshHeader();
    },
    [onRowSelected, gridRef]
  );

  const handleTabToNextCell = React.useCallback(
    (params: TabToNextCellParams) => tabToNextCell(params, gridRef),
    [gridRef]
  );

  const gridOptions = React.useMemo(() => {
    return {
      ...nonEditableOBGridOptions,
      suppressColumnVirtualisation: false,
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      suppressRowHoverHighlight: true,
      suppressPaginationPanel: true,
      tooltipShowDelay: 0,
      rowStyle: {
        background: theme.background.color.white,
        borderBottom: theme.border.smallSolidLight,
      },
      getRowStyle: () => ({ cursor: 'default' }),
      context,
      onDragStopped: () => {
        const columns = gridRef.current?.columnApi.getAllDisplayedColumns() ?? [];
        setColumnsOrder(columns.map(column => column.getColId()));
        setPinnedColumns({
          right: columns.filter(item => item.isPinnedRight()).map(item => item.getColId()),
          left: columns.filter(item => item.isPinnedLeft()).map(item => item.getColId()),
        });
      },
      postSort: rowNodes => {
        const processedList: string[] = [];
        for (let i = 0; i < rowNodes.length; i++) {
          const rowNode = rowNodes[i];

          if (processedList.includes(rowNode.data.id)) {
            continue;
          }

          processedList.push(rowNode.data.id);
          if (rowNode.data.duplicateOfIndicationId) {
            const parentIndex = rowNodes.findIndex(
              node => node.data.id === rowNode.data.duplicateOfIndicationId
            );
            if (parentIndex > -1) {
              rowNodes.splice(i, 1);
              const parentIndex = rowNodes.findIndex(
                node => node.data.id === rowNode.data.duplicateOfIndicationId
              );
              rowNodes.splice(parentIndex + 1, 0, rowNode);
              i--;
            }
          }
        }
      },
      // Suppress Model Updates After Update Transation is a way of reducing excessive onModelUpdated calls during load that otherwise slows down column resizing.
      // https://www.ag-grid.com/javascript-data-grid/data-update-transactions/#suppress-model-updates
      suppressModelUpdateAfterUpdateTransaction: true,
    };
  }, [context, setColumnsOrder, setPinnedColumns]);

  return (
    <SGridWrapper>
      <SGrid data-test-id={xcSelectors.institutionalDemandGrid.testId}>
        <DataGridClient<IndicationWithDemandLevels>
          onGridReady={onGridReady}
          onSelectionChange={handleSelectionChange}
          extended={extendedGridOptions}
          columns={gridColumns}
          rows={indications}
          rowSelection="multiple"
          resizeBy="grid"
          domLayout="normal"
          resizeStrategy="fit-content"
          onFirstDataRendered={processGridSizingAndSort}
          onModelUpdated={processGridSizingAndSort}
          onSortChanged={processSortChanged}
          onColumnResized={processColumnResized}
          gridOptions={gridOptions}
          getRowsPinnedToTop={getRowsPinnedToTop}
          getRowNodeId={getRowNodeId}
          isRowSelectable={isInstitutionalDemandGridSelectable}
          frameworkComponents={frameworkComponents}
          tabToNextCell={handleTabToNextCell}
          onCellFocused={onCellFocused}
        />
      </SGrid>
    </SGridWrapper>
  );
};

export type Props = Optional<PropsInternal, 'gridSummaryData' | 'indications'>;

/**
 * Renders the Institutional Demand Grid
 */
export const InstitutionalDemandGrid: React.FC<Props> = props => {
  const { gridSummaryData, indications } = props;

  return gridSummaryData === undefined || indications === undefined ? (
    <InstitutionalDemandSkeleton />
  ) : (
    <InstitutionalDemandGridInternal {...{ ...props, gridSummaryData, indications }} />
  );
};

export default InstitutionalDemandGrid;
