/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_UpdateFinalAllocationSetDealLayoutMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.AssignDealLayoutInput;
}>;

export type OrderBook_UpdateFinalAllocationSetDealLayoutMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFinalAllocationSetDealLayout: {
    readonly __typename?: 'FinalInstitutionalAllocationSet';
    readonly releaseTimestamp?: string | null;
  };
};

export const OrderBook_UpdateFinalAllocationSetDealLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateFinalAllocationSetDealLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignDealLayoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinalAllocationSetDealLayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateFinalAllocationSetDealLayoutMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateFinalAllocationSetDealLayoutMutation,
  OrderBook_UpdateFinalAllocationSetDealLayoutMutationVariables
>;

/**
 * __useOrderBook_UpdateFinalAllocationSetDealLayoutMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateFinalAllocationSetDealLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateFinalAllocationSetDealLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateFinalAllocationSetDealLayoutMutation, { data, loading, error }] = useOrderBook_UpdateFinalAllocationSetDealLayoutMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_UpdateFinalAllocationSetDealLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateFinalAllocationSetDealLayoutMutation,
    OrderBook_UpdateFinalAllocationSetDealLayoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateFinalAllocationSetDealLayoutMutation,
    OrderBook_UpdateFinalAllocationSetDealLayoutMutationVariables
  >(OrderBook_UpdateFinalAllocationSetDealLayoutDocument, options);
}
export type OrderBook_UpdateFinalAllocationSetDealLayoutMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateFinalAllocationSetDealLayoutMutation
>;
export type OrderBook_UpdateFinalAllocationSetDealLayoutMutationResult =
  Apollo.MutationResult<OrderBook_UpdateFinalAllocationSetDealLayoutMutation>;
export type OrderBook_UpdateFinalAllocationSetDealLayoutMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_UpdateFinalAllocationSetDealLayoutMutation,
    OrderBook_UpdateFinalAllocationSetDealLayoutMutationVariables
  >;
