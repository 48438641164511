import { useAuth } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import React from 'react';

import { IndicationWithDemandLevels, InstitutionalDraftSet } from '../../types';
import AllocationForm from './AllocationForm';
import { getAllocationUpdateInput, isIndicationAllocatable } from './AllocationForm.model';
import { useUpdateDraftAllocationMutation } from './hooks/useUpdateDraftAllocationMutation';

export type Props = {
  data: IndicationWithDemandLevels;
  allocationSet: InstitutionalDraftSet;
};

/**
 * Container that renders the Allocation Form for a Draft Allocation.
 */
const DraftAllocationFormCellContainer: React.FC<Props> = ({ data, allocationSet }) => {
  const [updateDraftAllocation, { loading }] = useUpdateDraftAllocationMutation();
  const institutionalIndicationAllocation = data.draftAllocationsBySetId[allocationSet.id!];
  const { oidcUserCmgEntityKey } = useAuth();
  const isAuthor =
    !!oidcUserCmgEntityKey && oidcUserCmgEntityKey === allocationSet.authorCmgEntityKey;

  const handleUpdateDraftAllocationShareQuantity = async ({
    newValue: newShareQuantity,
  }: {
    initialValue: number | null;
    newValue: number | null;
  }) => {
    const input = getAllocationUpdateInput(newShareQuantity);
    const payload = {
      indicationId: data.id,
      setId: allocationSet.id!,
      offeringId: data.offeringId,
      input,
    };
    try {
      await updateDraftAllocation({
        variables: payload,
      });
    } catch {
      ToastManager.error('Failed to update draft allocation.');
    }
  };

  const { isAllocable } = React.useMemo(() => {
    return isIndicationAllocatable({ isAuthor });
  }, [isAuthor]);

  return (
    <AllocationForm
      isSaving={loading}
      shareQuantity={institutionalIndicationAllocation?.shareQuantity ?? null}
      modifiedAt={
        institutionalIndicationAllocation?.auditInfo?.modifiedAt ??
        institutionalIndicationAllocation?.auditInfo?.createdAt
      }
      disabled={!isAllocable}
      onUpdateAllocationShareQuantity={handleUpdateDraftAllocationShareQuantity}
    />
  );
};

export default DraftAllocationFormCellContainer;
