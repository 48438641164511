import React from 'react';

const OfferingSetupValidationContext = React.createContext<{
  shouldValidate?: boolean;
  setShouldValidate?: (shouldValidate: boolean) => void;
}>({ shouldValidate: false });

/**
 * Hook exposes shouldValidate boolean context
 * as well as update function to set shouldValidate
 */
export const useShouldValidate = () => React.useContext(OfferingSetupValidationContext);

/**
 * Wraps Offering Setup with a context provider which triggers validation if necessary
 */
export const OfferingSetupValidationProvider: React.FC = props => {
  const [shouldValidate, setShouldValidate] = React.useState<boolean>(false);

  return (
    <OfferingSetupValidationContext.Provider value={{ shouldValidate, setShouldValidate }}>
      {props.children}
    </OfferingSetupValidationContext.Provider>
  );
};

export default OfferingSetupValidationProvider;
