import { isValidCmgEntityKey } from '../../../../../../common/util/isValidCmgEntityKey';
import { useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery } from '../graphql';
import { useCheckProspectusOnSendValidationPermissions } from './useCheckProspectusOnSendValidationPermissions';

export const useProspectusDeliverySingleOnSendValidationQuery = (
  {
    offeringId,
    investorKey,
    currentTenantOnly,
    isSyndicatePersona,
  }: {
    offeringId: string;
    investorKey?: string;
    currentTenantOnly: boolean;
    isSyndicatePersona: boolean;
  },
  skip?: boolean
) => {
  const hasPermissions = useCheckProspectusOnSendValidationPermissions(isSyndicatePersona);

  return useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery({
    variables: {
      offeringId,
      investorKey: investorKey!,
      currentTenantOnly,
      isSyndicatePersona,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !hasPermissions || !isValidCmgEntityKey(investorKey) || skip,
  });
};
