import React from 'react';

import { SDisabledCell } from './DisabledRowCellRenderer.styles';

export type Props = Readonly<{ valueFormatted: string | null; value: string | null }>;

const DisabledRowCellRenderer: React.FC<Props> = ({ valueFormatted, value }) => {
  return <SDisabledCell>{valueFormatted || value}</SDisabledCell>;
};

export default DisabledRowCellRenderer;
