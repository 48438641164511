import { Option } from '@cmg/common';

import { OfferingStage, OfferingStatus } from '../../../graphql';

// Creates a list of valid status options for OfferingSummaryRoute so that a user may only select valid updates for a status and will not
// receive an error.

const filed = { [OfferingStatus.Filed]: 'Filed' };
const live = { [OfferingStatus.Live]: 'Live' };
const priced = { [OfferingStatus.Priced]: 'Priced' };
const postponed = { [OfferingStatus.Postponed]: 'Postponed' };
const withdrawn = { [OfferingStatus.Withdrawn]: 'Withdrawn' };
const terminated = { [OfferingStatus.Terminated]: 'Terminated' };

const generateOptions = (options: { [key in OfferingStatus]?: string }) => {
  return Object.keys(options).map<Option>(status => ({
    label: options[status],
    value: status,
  }));
};

const filedOptions = generateOptions({
  ...filed,
  ...live,
  ...priced,
  ...withdrawn,
});
const liveOptions = generateOptions({
  ...live,
  ...priced,
  ...postponed,
});
const pricedOptions = generateOptions({
  ...priced,
  ...terminated,
});
const postponedOptions = generateOptions({
  ...postponed,
  ...withdrawn,
});
const withdrawnOptions = generateOptions({
  ...withdrawn,
});
const terminatedOptions = generateOptions({
  ...terminated,
});
const allOptions = generateOptions({
  ...filed,
  ...live,
  ...priced,
  ...postponed,
  ...withdrawn,
  ...terminated,
});

export const statusOptions = {
  filedOptions,
  liveOptions,
  pricedOptions,
  postponedOptions,
  withdrawnOptions,
  terminatedOptions,
  allOptions,
};
export const generateOfferingStatusOptions = ({ stage, publishedStatus }) => {
  if (stage !== OfferingStage.Published) {
    return statusOptions.allOptions;
  }

  switch (publishedStatus) {
    case OfferingStatus.Filed:
      return statusOptions.filedOptions;
    case OfferingStatus.Live:
      return statusOptions.liveOptions;
    case OfferingStatus.Priced:
      return statusOptions.pricedOptions;
    case OfferingStatus.Postponed:
      return statusOptions.postponedOptions;
    case OfferingStatus.Withdrawn:
      return statusOptions.withdrawnOptions;
    case OfferingStatus.Terminated:
      return statusOptions.terminatedOptions;
    default:
      return statusOptions.allOptions;
  }
};
