import { useStoredPreference } from '@cmg/auth';

export const useMyOrdersGridPreferences = () => {
  const [columnsOrder, setColumnsOrder] = useStoredPreference<string[]>({
    key: 'myOrdersGrid_columnsOrder',
    initialValue: [],
  });

  return {
    columnsOrder,
    setColumnsOrder,
  };
};
