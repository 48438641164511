import { Option } from '@cmg/common';

/**
 * Calendar view modes, used to change between daily, weekly and list events view.
 * Enum values are specific for FullCalendar library.
 */
export enum View {
  DAY = 'resourceTimeGridDay',
  // WEEK = 'timeGridWeek',
  LIST = 'list',
}

export const viewButtons: Option<View>[] = [
  { value: View.DAY, label: 'Day' },
  // { value: View.WEEK, label: 'Week' },
  { value: View.LIST, label: 'List' },
];
