import { UUID } from '@cmg/common';
import { Box, CircularProgress, Stack } from '@cmg/design-system';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { useFeatureToggles } from '../../../common/config';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery } from '../graphql';
import { useOfferingData } from './hooks/useOfferingData';
import { UnderwritingTermsDiscountsAndFeesSection } from './UnderwritingTermsDiscountsAndFeesSection';
import { UnderwritingTermsEconomicsSection } from './UnderwritingTermsEconomicsSection';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

export const UnderwritingTermsRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();

  const {
    currencyCode,
    finalTermsData,
    error: offeringDataError,
    loading: offeringDataLoading,
  } = useOfferingData(offeringId);
  const underwritingTermsFeesAndDiscounts =
    useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery({
      variables: { offeringId },
      fetchPolicy: 'no-cache',
    });

  const { data: underwritingDiscountsFeesData, loading: getQueryLoading } =
    underwritingTermsFeesAndDiscounts;

  const errors = [offeringDataError];

  const loading = offeringDataLoading || getQueryLoading;

  if (loading) {
    return (
      <OfferingSetupPage offeringId={offeringId} negativeMargin>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={theme => theme.spacing(12)}
        >
          <CircularProgress />
        </Box>
      </OfferingSetupPage>
    );
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        <Stack spacing={4}>
          <UnderwritingTermsDiscountsAndFeesSection
            offeringId={offeringId}
            serverErrors={errors}
            finalTermsData={finalTermsData}
            currencyCode={currencyCode ?? 'USD'}
            underwritingDiscountsFeesData={
              underwritingDiscountsFeesData?.underwritingTermsFeesAndDiscounts
            }
          />
          {isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn && (
            <UnderwritingTermsEconomicsSection
              offeringId={offeringId}
              underwritingTermsFeesAndDiscounts={underwritingTermsFeesAndDiscounts}
            />
          )}
        </Stack>
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};
