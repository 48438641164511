import {
  Alert,
  Icon,
  LinkButton,
  ODSTable,
  Popover,
  SwitchField,
  TextInputField,
  timeUtil,
} from '@cmg/common';
import React from 'react';

import LoadingOverlay from '../../../../../common/components/indicators/loading-overlay/LoadingOverlay';
import { EmptyState } from '../../../../../design-system/components/data-display/empty-state/EmptyState';
import MandatoryAsterisk from '../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import SetupForm from '../../../components/form/OfferingSetupForm';
import { OfferingSetup_Prospectus_ProspectusDocumentsQuery } from '../../../graphql';
import { FILENAME_REGEX, ProspectusFormDocument } from '../../ProspectusRoute.model';
import { AddDocumentButton } from './AddDocumentButton';
import { EditFileInput } from './EditFileInput';
import { FileLinkCell } from './FileLinkCell';
import {
  errorLabels,
  FileInputError,
  FileInputErrorType,
  MAX_FILE_SIZE,
} from './ProspectusForm.model';
import { SWrapper } from './ProspectusForm.styles';

export type Props = {
  onAddFile: (value: ProspectusFormDocument) => void;
  onEditFile: (value: ProspectusFormDocument) => void;
  onRemoveFile: (index: number) => void;
  documents: ProspectusFormDocument[];
  savedDocumentsLength: number;
  isLoading: boolean;
  versionInfo?: OfferingSetup_Prospectus_ProspectusDocumentsQuery['offering']['versions'][0];
};

const ProspectusForm: React.FC<Props> = ({
  onAddFile,
  onEditFile,
  onRemoveFile,
  documents,
  isLoading,
  versionInfo,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = React.useState<FileInputError | null>(null);

  const validateFileInput = React.useCallback((file: File) => {
    const fileName = file.name.toLowerCase();
    const isUnsupportedFilename = !FILENAME_REGEX.test(fileName);

    if (isUnsupportedFilename) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.UNSUPPORTED_FILENAME,
      });
      return false;
    }

    // check for file size
    if (file.size > MAX_FILE_SIZE) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.FILE_SIZE,
      });
      return false;
    }

    const isPdf = fileName.endsWith('.pdf');
    if (!isPdf) {
      setFileError({
        fileName: file.name,
        errorType: FileInputErrorType.FILE_TYPE,
      });
      return false;
    }

    return true;
  }, []);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files?.[0] ?? null; // Single file selection only allowed

    // Clear file input so same file can be added again after deletion
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (!file) {
      return;
    }

    if (!validateFileInput(file)) {
      return;
    }

    const isDuplicateFileName = documents.some(item => item.file?.name === file.name);

    // Same file should not be added twice
    if (!isDuplicateFileName) {
      onAddFile({
        __typename: 'ProspectusDocument',
        id: null,
        includeOnPublish: true,
        documentName: file.name.substring(0, 255).replace(/\.[^/.]+$/, ''),
        firstPublishedAt: null,
        uploadedAt: null,
        publicUrl: null,
        file, // File metadata are stored in formik
      });
      setFileError(null);
    }
  };

  const handleEditFile = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0] ?? null; // Single file selection only allowed
    if (!file) {
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (!file) {
      return;
    }

    if (!validateFileInput(file)) {
      return;
    }

    const isDuplicateFileName = [...documents]
      .splice(index, 1)
      .some(item => item.file?.name === file.name);

    // Same file should not be added twice
    if (isDuplicateFileName) {
      return;
    }

    const documentId = documents[index].id;

    onEditFile({
      __typename: 'ProspectusDocument',
      id: documentId,
      includeOnPublish: true,
      documentName: file.name.substring(0, 255).replace(/\.[^/.]+$/, ''),
      firstPublishedAt: null,
      uploadedAt: null,
      publicUrl: null,
      file, // File metadata is stored in formik
    });
    setFileError(null);
  };

  const onAddDocument = () => {
    fileInputRef.current?.click();
  };

  return (
    <SetupForm
      title="Prospectus"
      headerContent={() => !isLoading && <AddDocumentButton onAdd={onAddDocument} />}
    >
      {isLoading && <LoadingOverlay variant="WHITE_TRANSLUCENT" />}
      {fileError ? (
        <Alert severity="error" withMargin>
          {errorLabels[fileError.errorType](fileError.fileName)}
        </Alert>
      ) : (
        <SWrapper>Supported documents are limited to .PDF documents up to 250MB.</SWrapper>
      )}
      {/** Hidden file input, used to retrieve file reference which is then stored in formik */}
      <input
        hidden
        aria-label="prospectus file input"
        type="file"
        accept="application/pdf"
        data-testid="offering-setup-prospectus-add-file-input"
        onChange={changeHandler}
        ref={fileInputRef}
      />
      <ODSTable
        gridTemplateColumns={
          // prettier-ignore
          `${
            /* include */ '75px'} ${
            /* document name */ 'auto'} ${
            /* uploaded on */ 'max-content'} ${
            /* url */ 'minmax(370px, max-content)'} ${
            /* published on */ 'max-content'} ${
            /* remove */ '45px'
          }`
        }
      >
        <ODSTable.TableHeaderCell>
          Include
          <Popover
            content="Select the documents that you would like to be sent automatically to investors after placing IOI"
            variant="DARK"
            autoAdjustOverflow
          >
            <span>
              <Icon name="info-circle" size="sm" fixedWidth />
            </span>
          </Popover>
        </ODSTable.TableHeaderCell>
        <ODSTable.TableHeaderCell>
          Document Name
          <MandatoryAsterisk />
        </ODSTable.TableHeaderCell>
        <ODSTable.TableHeaderCell>Uploaded On</ODSTable.TableHeaderCell>
        <ODSTable.TableHeaderCell>URL</ODSTable.TableHeaderCell>
        <ODSTable.TableHeaderCell>Published On</ODSTable.TableHeaderCell>
        <ODSTable.TableHeaderCell>&nbsp;</ODSTable.TableHeaderCell>

        {documents.map((item, index) => {
          return (
            <ODSTable.Row key={item.id ?? item.file?.name ?? index}>
              {/* include */}
              <ODSTable.Cell align="center" centered>
                <SwitchField name={`prospectusDocuments.${index}.includeOnPublish`} fullWidth />
              </ODSTable.Cell>
              {/* document name */}
              <ODSTable.Cell centered paddingSize="XS">
                <EditFileInput handleEditFile={handleEditFile} index={index} />
                <TextInputField name={`prospectusDocuments.${index}.documentName`} fullWidth />
              </ODSTable.Cell>
              {/* uploaded on */}
              <ODSTable.Cell centered>
                {item.uploadedAt ? timeUtil.formatAsDisplayDateTime(item.uploadedAt) : '-'}
              </ODSTable.Cell>
              {/* url */}
              <FileLinkCell item={item} versionInfo={versionInfo} />
              {/* published on */}
              <ODSTable.Cell centered>
                {item.firstPublishedAt
                  ? timeUtil.formatAsDisplayDateTime(item.firstPublishedAt)
                  : '-'}
              </ODSTable.Cell>
              {/* remove */}
              <ODSTable.Cell align="center" centered>
                {item.file && (
                  <LinkButton
                    testId={`prospectus-remove-document.${index}`}
                    iconLeft={{ name: 'trash-alt', size: 'lg' }}
                    inline
                    onClick={() => {
                      onRemoveFile(index);
                    }}
                  />
                )}
              </ODSTable.Cell>
            </ODSTable.Row>
          );
        })}
      </ODSTable>

      {documents.length === 0 && (
        <EmptyState
          title="No documents"
          message={
            <span>
              Click
              <AddDocumentButton onAdd={onAddDocument} />
              to upload a new document
            </span>
          }
        />
      )}
    </SetupForm>
  );
};

export default ProspectusForm;
