import { CustomCellRendererProps } from '@cmg/common';
import { CheckIcon, CloseIcon } from '@cmg/design-system';
import React from 'react';

import { CertificateLibrary_FetchCertificates_FirmCertificatesFragment } from '../../../graphql/__generated__';

type Props = CustomCellRendererProps<
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment,
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment['hasRelationship']
>;

const CertificateRelationshipRenderer = ({ data }: Props) => {
  if (!data) return null;

  return (
    <React.Fragment>
      {data?.hasRelationship ? (
        <CheckIcon fontSize="small" color="success" />
      ) : (
        <CloseIcon fontSize="small" color="error" />
      )}
    </React.Fragment>
  );
};

export default CertificateRelationshipRenderer;
