import { OrderComparator, timeUtil } from '@cmg/common';

import { GroupHeader } from '../../../common/components/lists/data-grid';
import sortByDateComparator from '../../../common/util/sortByDateComparator';

export const groupHeaderComparator = sortByDateComparator('desc');
export const extended = {
  withMargin: false,
  hideColumnResize: true,
  hideColumnSelector: true,
  fillViewport: true,
};

export const groupHeaderRenderer = (groupValue: string) => (
  <GroupHeader>
    Expected Pricing Date:
    <GroupHeader.Value>
      {groupValue ? timeUtil.formatAsDisplayDayAndDate(groupValue) : 'TBD'}
    </GroupHeader.Value>
  </GroupHeader>
);

export const baseGridOptions = {
  suppressCellSelection: true,
  suppressRowClickSelection: true,
  suppressBrowserResizeObserver: true,
  rowHeight: 50,
};

export const orderComparator: OrderComparator = {
  issuerName: 'string',
};
