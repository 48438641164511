/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_SenderRecipientsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_SenderRecipientsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly recipients: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingRecipient';
        readonly id: string;
        readonly firmName: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly distributionList: ReadonlyArray<string>;
      }>;
    };
  };
};

export const RegulatoryFilings_SenderRecipientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_SenderRecipients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipients' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_SenderRecipientsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_SenderRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SenderRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_SenderRecipientsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_SenderRecipientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_SenderRecipientsQuery,
    RegulatoryFilings_SenderRecipientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_SenderRecipientsQuery,
    RegulatoryFilings_SenderRecipientsQueryVariables
  >(RegulatoryFilings_SenderRecipientsDocument, options);
}
export function useRegulatoryFilings_SenderRecipientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_SenderRecipientsQuery,
    RegulatoryFilings_SenderRecipientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_SenderRecipientsQuery,
    RegulatoryFilings_SenderRecipientsQueryVariables
  >(RegulatoryFilings_SenderRecipientsDocument, options);
}
export type RegulatoryFilings_SenderRecipientsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_SenderRecipientsQuery
>;
export type RegulatoryFilings_SenderRecipientsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_SenderRecipientsLazyQuery
>;
export type RegulatoryFilings_SenderRecipientsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_SenderRecipientsQuery,
  RegulatoryFilings_SenderRecipientsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_SenderRecipientsMockResponse = makeMockResponse<
  RegulatoryFilings_SenderRecipientsQueryVariables,
  RegulatoryFilings_SenderRecipientsQuery
>(RegulatoryFilings_SenderRecipientsDocument);
