import { FormField, timeUtil } from '@cmg/common';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { FilingStatus, TransactionType } from '../../../../../graphql';
import { FinraFilingStatusAlert } from '../../../common/content/alerts/FinraFilingStatusAlert';
import FilingCommonFieldsDetail from '../../../common/content/filing-common-fields-detail/FilingCommonFieldsDetail';
import FilingDetails from '../../../common/content/filing-details/FilingDetails';
import FilingDetailsHeader from '../../../common/content/filing-details-header/FilingDetailsHeader';
import FinraErrorResponseBanner from '../../../common/content/finra-error-response/FinraErrorResponseBanner';
import { NonMemberFirmsDetail } from '../../../common/content/non-member-firms-detail/NonMemberFirmsDetail';
import { getLabelFromOptions } from '../../../common/form-utils';
import { SDescription } from '../../../RegulatoryFilingsRoute.styles';
import { transactionTypeOptions } from '../../trading-notification/form/TNFilingForm.model';
import { restrictedPeriodOptions } from '../form/RPNForm';
import { RegulatoryFilings_RpnFilingPartsFragment } from '../graphql';
import BasisCodeDetail from './BasisCodeDetail';
import MemberFirmsDetail from './MemberFirmsDetail';

export type Props = {
  filing: RegulatoryFilings_RpnFilingPartsFragment;
  version: number;
};

const RPNDetail: React.FC<Props> = ({ filing, version }) => {
  return (
    <div>
      <FinraFilingStatusAlert status={filing.status} />
      <FilingDetailsHeader
        version={version}
        firmDealId={filing.firmDealId}
        formUniqueId={filing.formUniqueId}
        status={filing.status}
        submittedDateTime={filing.submittedDateTime}
      />
      {filing.status === FilingStatus.Error && filing.finraErrorMessages && (
        <FinraErrorResponseBanner errors={filing.finraErrorMessages} />
      )}
      <FilingCommonFieldsDetail detail={filing} />
      <FilingDetails issuerSymbol={filing.issuerSymbol} issuerName={filing.issuerName}>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Symbols of covered securities">
              {filing.symbolsOfCoveredSecurities.map(symbol => (
                <SDescription key={symbol}>{symbol}</SDescription>
              ))}
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Restricted Period">
              <div>{getLabelFromOptions(filing.restrictedPeriod, restrictedPeriodOptions)}</div>
            </FormField>
          </Column>
        </GridLayout>
        <BasisCodeDetail detail={filing} />
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Concurrent Convertible">
              <div>{filing.isConcurrentConvertible ? 'Yes' : 'No'}</div>
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Transaction Type">
              <div>{getLabelFromOptions(filing.transactionType, transactionTypeOptions)}</div>
            </FormField>
          </Column>
        </GridLayout>
        {filing.transactionType === TransactionType.Other && (
          <GridLayout>
            <Column span={6}>
              <FormField withMargin fullWidth label="Transaction Type Text">
                <SDescription aria-label="Transaction Type Text">
                  {filing.transactionTypeDescription}
                </SDescription>
              </FormField>
            </Column>
          </GridLayout>
        )}
        <GridLayout>
          <Column span={3}>
            <FormField
              withMargin
              fullWidth
              label="Restriction Start Time"
              data-test-id="restriction-start-time"
            >
              <div aria-label="Restriction Start Time">
                {timeUtil.formatAsDisplayDateTime(
                  utcToZonedTime(filing.restrictionStartTime, filing.restrictionStartTimezone)
                )}{' '}
                {timeUtil.formatAsTimeZoneAbbreviation(
                  filing.restrictionStartTime,
                  filing.restrictionStartTimezone
                )}
              </div>
            </FormField>
          </Column>
          <Column span={3}>
            <FormField withMargin fullWidth label="Anticipated Pricing Date">
              <div>{timeUtil.formatAsDisplayDate(filing.anticipatedPricingDate)}</div>
            </FormField>
          </Column>
        </GridLayout>
      </FilingDetails>

      <MemberFirmsDetail memberFirms={filing.finraMemberFirms} />
      <NonMemberFirmsDetail nonMemberFirms={filing.nonMemberFirms} isDetailView />
    </div>
  );
};

export default RPNDetail;
