import { currencyCodes } from '@cmg/common';
import {
  Box,
  CheckIcon,
  DeleteIcon,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@cmg/design-system';
import { FormikAutocompleteField, FormikCurrencyField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { supportedCurrencyCodes } from '../../../constants';
import { CurrencyFormValues } from './CurrencyGrid.model';

export type Props = {
  pricingCurrencyCode: string;
  currencyCode: string;
  exchangeRate: number;
  index: number;
  key: number;
  onRemove: () => void;
};

const defaultParseFormikValue = (value, options) => options.find(option => option.value === value);
const defaultParseToFormikValue = option => option?.value;

export const CurrencyRowForm: React.FC<Props> = ({
  pricingCurrencyCode,
  currencyCode,
  index,
  onRemove,
}) => {
  const isPricingCurrency = currencyCode === pricingCurrencyCode;

  const { values } = useFormikContext<CurrencyFormValues>();

  const selectedCurrenyCodes = values.currencies
    .map(currency => currency.currencyCode)
    .filter(code => code !== currencyCode);

  const currencyCodeOptions = React.useMemo(
    () =>
      Object.values(currencyCodes)
        .map(currency => ({
          label: currency.alphabeticCode,
          value: currency.alphabeticCode,
        }))
        .filter(
          currency =>
            supportedCurrencyCodes.includes(currency.value) &&
            !selectedCurrenyCodes.includes(currency.value) &&
            (isPricingCurrency ? true : currency.value !== pricingCurrencyCode)
        )
        // sorting, USD is always on top then alphabetical
        .sort((a, b) => {
          if (a.value === 'USD') {
            return -1;
          }
          if (b.value === 'USD') {
            return 1;
          }
          return a.value.localeCompare(b.value);
        }),
    [pricingCurrencyCode, isPricingCurrency, selectedCurrenyCodes]
  );

  return (
    <TableRow>
      <TableCell scope="row">
        {isPricingCurrency ? (
          <Typography paddingLeft="14px"> {currencyCode} </Typography>
        ) : (
          <FormikAutocompleteField
            aria-label="currencyCode"
            name={`currencies.${index}.currencyCode`}
            options={currencyCodeOptions}
            fullWidth
            parseFormikValue={defaultParseFormikValue}
            parseToFormikValue={defaultParseToFormikValue}
            placeholder="Select"
          />
        )}
      </TableCell>
      <TableCell align="center">{isPricingCurrency ? <CheckIcon color="primary" /> : ''}</TableCell>
      <TableCell align="left">
        {!isPricingCurrency ? (
          <Stack direction="row" spacing={0} flexGrow={1} alignItems="center" flexBasis="auto">
            <Box flexBasis="25%">
              <Typography> 1 {currencyCode} </Typography>
            </Box>
            <Box flexBasis="10%">
              <Typography> = </Typography>
            </Box>
            <Box sx={{ alignSelf: 'center' }}>
              <FormikCurrencyField
                aria-label="exchangeRate"
                name={`currencies.${index}.exchangeRate`}
                fullWidth
                currencyCode={pricingCurrencyCode}
                placeholder="0.000"
              />
            </Box>
          </Stack>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="center">
        {!isPricingCurrency && (
          <IconButton color="primary" onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
