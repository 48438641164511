import { Alert, AlertTitle, Typography } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import { useFormikErrors } from '../../../../../../../../../common/util/useFormikErrors';
import { createIndicationValidationSchema } from '../../utils/IndicationSectionValidation';

const InvalidFormAlert: React.FC = () => {
  const { errors, touched, isSubmitting } = useFormikContext();
  const { showInvalidFields, invalidFormFieldLabels } = useFormikErrors({
    errors,
    touched,
    isSubmitting,
    validationSchema: createIndicationValidationSchema,
  });

  if (showInvalidFields) {
    return (
      <Alert
        severity="error"
        aria-label="An entry is required or has invalid value for the following fields:"
      >
        <AlertTitle>An entry is required or has invalid value:</AlertTitle>
        <Typography variant="body2">{invalidFormFieldLabels.join(', ')}</Typography>
      </Alert>
    );
  }

  return null;
};

export default InvalidFormAlert;
