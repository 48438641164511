import styled from 'styled-components/macro';

export const SStepWrapper = styled.div<{ isActive?: boolean }>`
  height: 32px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.text.color.link : 'transparent')};
  color: ${({ theme, isActive }) =>
    isActive ? theme.text.color.white : theme.text.color.darkGray};
  font-weight: ${({ theme, isActive }) =>
    isActive ? theme.text.weight.bold : theme.text.weight.regular};
  margin: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SIconWrapper = styled.div`
  width: 40px;
  text-align: center;
`;
