import { ButtonsContainer, SecondaryButton, SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../common/util/routeFactory';
import DealTeamHeader from '../../common/deal-team-header/DealTeamHeader';

export type Props = {
  isLoading: boolean;
  onCreate: () => void;
};

const CreateDealTeamHeader: React.FC<Props> = ({ isLoading, onCreate }) => {
  const { push } = useHistory();

  return (
    <DealTeamHeader>
      <ButtonsContainer>
        <SecondaryButton
          testId={xcSelectors.cancelDealTeamButton.testId}
          disabled={isLoading}
          onClick={() => push(routeFactory.roadshowsDealTeams.getUrlPath())}
        >
          Cancel
        </SecondaryButton>
        <SuccessButton
          onClick={onCreate}
          testId={xcSelectors.createDealTeamButton.testId}
          loading={isLoading}
        >
          Create Deal Team
        </SuccessButton>
      </ButtonsContainer>
    </DealTeamHeader>
  );
};

export default CreateDealTeamHeader;
