import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import { multiFilterArray } from '../../../../../common/util/multiFilterArray';
import { sortCaseInsensitive } from '../../../../../common/util/sortCaseInsensitive';
import { IndicationNoteType } from '../../../../../graphql';
import {
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  InstitutionalDemandGrid_SyndicateManagerPartsFragment,
  InstitutionalDemandGrid_SyndicatePartsFragment,
} from './graphql';
import {
  DecimalExtendedData,
  ExtendedTypes,
  IndicationNote,
  IndicationWithDemandLevels,
  InstitutionalDemand,
  InstitutionalDemandFilterArgs,
  InstitutionalDemandGridOfferingColumnConfig,
  InstitutionalDraftSet,
  InstitutionalFinalSet,
  StringExtendedData,
  SyndicateManager,
} from './types';

type FirmInvestorExtendedData =
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];

type OfferingSyndicateManagers = readonly (
  | InstitutionalDemandGrid_SyndicatePartsFragment['syndicate']['managers'][number]
  | NonNullable<InstitutionalDemandGrid_SyndicateManagerPartsFragment>['managers'][number]
)[];

export const institutionalDemandGridSecondaryErrorPrefixMap = {
  useAttestationStatusesLazyQuery: 'Unable to load 5130/31 Status',
  useInvestorsExtendedDataQuery: 'Unable to load custom columns',
  useBulkResendProspectus: 'Unable to load prospectus contacts',
};

/**
 * Given a list of Institutional Demands and Filters,
 * filters the list of Institutional Demands that meet the
 * filter criteria.
 * @param institutionalDemands - a list of Institutional Demands
 * @param filters - the Filter criteria
 */
export const filterInstitutionalDemands = (
  institutionalDemands: InstitutionalDemand[],
  filters: InstitutionalDemandFilterArgs
) => {
  const filterConfig = {
    status: (item: InstitutionalDemand) =>
      isEmpty(filters.status) || !!filters.status?.includes(item.status),
    type: (item: InstitutionalDemand) =>
      isEmpty(filters.type) || !!filters.type?.includes(item.type),
    investorInformation: (item: InstitutionalDemand) => {
      if (
        !filters.searchText ||
        (!item.investorInformation!.bankInvestorName && !item.investorInformation!.cmgEntityName)
      ) {
        return true;
      }

      return !!(
        item
          .investorInformation!.bankInvestorName?.toLowerCase()
          .includes(filters.searchText.toLowerCase()) ||
        item
          .investorInformation!.cmgEntityName?.toLowerCase()
          .includes(filters.searchText.toLowerCase())
      );
    },
  };

  const filteredArray = multiFilterArray(institutionalDemands, filterConfig);
  const filteredIdsArray = filteredArray.map(item => item.id);

  const addParentChildToArray: InstitutionalDemand[] = [];
  filteredArray.forEach(indication => {
    if (indication.duplicateIndicationIds.length > 0) {
      const childrenIds = indication.duplicateIndicationIds;
      childrenIds.forEach(childId => {
        const child = institutionalDemands.find(id => id.id === childId);
        if (child && !filteredIdsArray.includes(child.id)) {
          addParentChildToArray.push(child);
        }
      });
    }

    if (indication.duplicateOfIndicationId) {
      const parent = institutionalDemands.find(i => i.id === indication.duplicateOfIndicationId);
      const isParentAlreadyAdded = addParentChildToArray.find(i => i.id === parent?.id);
      if (parent && !isParentAlreadyAdded && !filteredIdsArray.includes(parent.id)) {
        addParentChildToArray.push(parent);
      }
    }
  });

  return [...filteredArray, ...addParentChildToArray];
};

/**
 * Gets the draft allocations grouped by indication Id
 */
export const getDraftAllocationsByIndicationId = (
  draftAllocationSets: readonly InstitutionalDraftSet[]
) => {
  return groupBy(
    flatMap(draftAllocationSets.map(({ allocations }) => allocations)),
    'indicationId'
  );
};

/**
 * Gets given indication note type
 */
export const getIndicationNote = (
  notes: InstitutionalDemand['notes'],
  type: IndicationNoteType
): IndicationNote | null => {
  const note = notes.find(item => item.type === type);

  return note ? { text: note.text, modifiedAt: note.auditInfo.modifiedAt } : null;
};

export const getInstitutionalManagers = (
  offeringSyndicateManagers: OfferingSyndicateManagers,
  indicationDemands: InstitutionalDemand[]
) => {
  const investorInformationList = indicationDemands.map(({ investorInformation }) => ({
    cmgEntityKey: investorInformation.cmgEntityKey,
    firmName: investorInformation.bankInvestorName
      ? investorInformation.bankInvestorName
      : investorInformation.cmgEntityName,
    firmNameAbbreviation: investorInformation.firmNameAbbreviation,
    __typename: 'ManagerProfile',
  }));
  const allManagers = investorInformationList
    .concat(offeringSyndicateManagers as typeof investorInformationList)
    .filter(sm => sm.cmgEntityKey !== null)
    .map(sm => {
      return {
        cmgEntityKey: sm.cmgEntityKey!,
        firmName: sm.firmName ?? '',
        firmNameAbbreviation: sm.firmNameAbbreviation,
      };
    });

  return keyBy(allManagers, manager => manager.cmgEntityKey);
};

/*
 * Given the ExtendedTypes we will generate a mapped version of the extended data
 * that will be mapped using key from each sub-category, i.e. investorType key
 */
export const getMappedExtendedData = (investorsExtendedData: FirmInvestorExtendedData) => {
  const extendedTypes = Object.values(ExtendedTypes);
  return investorsExtendedData.map(data => {
    const overrideFields = Object.fromEntries(
      extendedTypes.map((type: string) => [
        type,
        Object.fromEntries(
          (data[type] ?? []).map((p: StringExtendedData | DecimalExtendedData) => [p.key, p])
        ),
      ])
    );
    return {
      ...data,
      ...overrideFields,
    };
  });
};

const getDuplicateInvestorName = (
  indication: InstitutionalDemand,
  duplicateIndication: InstitutionalDemand | undefined
) => {
  if (indication.duplicateOfIndicationId && duplicateIndication) {
    return (
      duplicateIndication.investorInformation.bankInvestorName ||
      duplicateIndication.investorInformation.cmgEntityName
    );
  }

  return null;
};

const getCoveringManagers = (
  coveringManagerCmgEntityKeys: readonly string[],
  investorsAndManagers: Record<string, SyndicateManager>,
  oidcUserCmgEntityKey: string | null
) => {
  return coveringManagerCmgEntityKeys
    .map(cmgEntityKey => investorsAndManagers[cmgEntityKey])
    .filter(manager => !!manager)
    .sort((a, b) => {
      // Return logged in user's bank first everytime.
      if (a?.cmgEntityKey === oidcUserCmgEntityKey) {
        return -1;
      }
      if (b?.cmgEntityKey === oidcUserCmgEntityKey) {
        return 1;
      }

      return sortCaseInsensitive(a?.firmName!, b?.firmName!);
    }) as SyndicateManager[];
};

/**
 * Given a list of offeringSyndicateManagers it will return a map of cmgEntityKey to SyndicateManager
 * @param offeringSyndicateManagers
 */
export const getSyndicateManagerMap = (offeringSyndicateManagers: OfferingSyndicateManagers) => {
  return offeringSyndicateManagers.reduce<Record<string, SyndicateManager>>((acc, curr) => {
    const { firmName, firmNameAbbreviation, cmgEntityKey } = curr;
    return { ...acc, [cmgEntityKey]: { firmName, firmNameAbbreviation, cmgEntityKey } };
  }, {});
};

/**
 * Given a list of institutionalIndications and indicationDemands
 * it create a new objected merged by id
 */
export const createIndicationsWithDemandLevels = ({
  filters,
  draftAllocationSets,
  finalAllocationSet,
  indicationDemands,
  oidcUserCmgEntityKey,
  investorsExtendedData,
  gridOfferingConfig,
}: {
  filters: InstitutionalDemandFilterArgs;
  draftAllocationSets: readonly InstitutionalDraftSet[];
  finalAllocationSet: InstitutionalFinalSet | null | undefined;
  indicationDemands: InstitutionalDemand[];
  oidcUserCmgEntityKey: string | null;
  investorsExtendedData: FirmInvestorExtendedData;
  gridOfferingConfig?: InstitutionalDemandGridOfferingColumnConfig | null;
}): IndicationWithDemandLevels[] => {
  const institutionalDemands = filterInstitutionalDemands(indicationDemands, filters);
  const syndicateManagers: OfferingSyndicateManagers = gridOfferingConfig?.syndicate.managers ?? [];
  const investorsAndManagers = getInstitutionalManagers(syndicateManagers, institutionalDemands);
  const draftAllocationsByIndicationId = getDraftAllocationsByIndicationId(draftAllocationSets);
  const finalAllocationsByIndicationId = keyBy(finalAllocationSet?.allocations, 'indicationId');
  const settlementAgent =
    gridOfferingConfig?.syndicate.managerResponsibilities.settlementAgent ?? null;
  const mappedExtendedData = getMappedExtendedData(investorsExtendedData);
  const defaultDraftAllocationSetId = draftAllocationSets.find(set => set.isDefault)?.id ?? null;

  return institutionalDemands.map(item => {
    const submittedByManager = item?.submittedByCmgEntityKey
      ? investorsAndManagers[item.submittedByCmgEntityKey]
      : null;

    const modifiedByFirm = item?.auditInfo.modifiedByFirmKey
      ? investorsAndManagers[item.auditInfo.modifiedByFirmKey]
      : null;

    const coveringManagers = getCoveringManagers(
      item?.coveringManagerCmgEntityKeys ?? [],
      investorsAndManagers,
      oidcUserCmgEntityKey
    );

    const coveringManagersFirmNames = coveringManagers.map(manager => {
      return {
        firmName: manager?.firmName,
        firmNameAbbreviation: manager?.firmNameAbbreviation,
        cmgEntityKey: manager?.cmgEntityKey,
      };
    });

    const draftAllocations = (item && draftAllocationsByIndicationId[item.id]) ?? [];
    const draftAllocationsBySetId = keyBy(draftAllocations, alloc => alloc.allocationSetId);
    const duplicateIndication = indicationDemands.find(
      id => id.id === item.duplicateOfIndicationId
    );

    const investorExtendedData =
      mappedExtendedData.find(i => i.firmKey === item.investorInformation.bankInvestorKey) ?? null;

    const notes = {
      syndicate: getIndicationNote(item.notes, IndicationNoteType.SyndicateNote),
      salesAndTrading: getIndicationNote(item.notes, IndicationNoteType.SalesAndTradingNote),
      salesContact: getIndicationNote(item.notes, IndicationNoteType.SalesContactNote),
      salesDepartment: getIndicationNote(item.notes, IndicationNoteType.SalesDepartmentNote),
    };

    return {
      ...item,
      prospectusDeliveryStatus: item.prospectusDeliveryStatus ?? null,
      billingAndDeliveryAgentCmgEntityKey: item.billingAndDeliveryAgentCmgEntityKey ?? null,
      acknowledgingUser: item.acknowledgingUser ?? null,
      defaultDraftAllocationSetId,
      draftAllocationsBySetId: draftAllocationsBySetId,
      finalAllocation: (item && finalAllocationsByIndicationId[item.id]) ?? null,
      finalAllocationAuthorCmgEntityKey: finalAllocationSet?.authorCmgEntityKey ?? null,
      submittedBy: submittedByManager ?? null,
      modifiedBy: modifiedByFirm ?? null,
      coveringManagerNames: coveringManagersFirmNames,
      demandLevels: item.demandLevels.reduce((acc, { price, interestInShares }) => {
        acc[price] = interestInShares;
        return acc;
      }, {}),
      pricingCurrencyCode: gridOfferingConfig?.pricingCurrencyCode,
      selected: false,
      settlementAgent,
      duplicateOfInvestorName: getDuplicateInvestorName(item, duplicateIndication),
      duplicateOfIndicationId: item.duplicateOfIndicationId ?? null,
      notes,
      investorExtendedData,
    };
  });
};

export type MetricsVisibilityProps = Readonly<{
  loading: boolean;
  indicationsCount: number;
  isMetricsOn: boolean;
}>;

export const getMetricsVisibility = ({
  indicationsCount,
  isMetricsOn,
  loading,
}: MetricsVisibilityProps) => {
  const isSwitchVisible = indicationsCount > 0 || loading;
  const isMetricsVisible = isMetricsOn && isSwitchVisible;

  return { isSwitchVisible, isMetricsVisible };
};
