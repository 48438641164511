import { SyndicateWireManagerRole } from '../../../../../../../types/domain/syndicate-wires/constants';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { SyndicateWires_WireMessagePartsFragment } from '../../../graphql';
import { SyndicateWires_SendWireDialog_FirmDistributionListPartsFragment } from './graphql';

export enum OperationType {
  SEND = 'send',
}

export enum RoleToggledState {
  ON = 'ON',
  OFF = 'OFF',
  INDETERMINATE = 'INDETERMINATE',
}

export enum DisabledManagerReason {
  MISSING_EMAIL = 'MISSING_EMAIL',
  MANAGER_REMOVED = 'MANAGER_REMOVED',
}

export type ManagerForSendModal = SyndicateWireManager & {
  disabledReason: null | DisabledManagerReason;
  isDisabled: boolean;
  isToggled: boolean;
  distributionList: readonly string[];
};

export function getManagerDistributionList(
  distributionLists: readonly SyndicateWires_SendWireDialog_FirmDistributionListPartsFragment[],
  cmgEntityKey: string
) {
  return (
    distributionLists.find(list => list.cmgEntityKey === cmgEntityKey)?.distributionEmails ?? []
  );
}

/**
 * Adds isDisabled and isToggled to a list of managers,
 * so they are easier to work with in the send wire modal
 */
export function transformManagersForSendDialog({
  managersOnWire,
  managersWithMissingEmails = [],
  outdatedManagers = [],
  wireMessages = [],
  distributionLists = [],
}: {
  managersOnWire: SyndicateWireManager[];
  wireMessages?: readonly SyndicateWires_WireMessagePartsFragment[];
  outdatedManagers?: string[];
  managersWithMissingEmails?: string[];
  distributionLists?: readonly SyndicateWires_SendWireDialog_FirmDistributionListPartsFragment[];
}): ManagerForSendModal[] {
  return managersOnWire.map(manager => {
    const wasWireSent = wasWireAlreadySent(manager, wireMessages);
    const hasEmailSet = hasManagerEmailSet(manager, managersWithMissingEmails);

    let disabledReason: ManagerForSendModal['disabledReason'] = null;
    if (!hasEmailSet) {
      disabledReason = DisabledManagerReason.MISSING_EMAIL;
    }
    if (outdatedManagers.some(m => m === manager.cmgEntityKey)) {
      disabledReason = DisabledManagerReason.MANAGER_REMOVED;
    }

    const isDisabled = !!disabledReason || wasWireSent;

    const isToggled = !isDisabled || wasWireSent;

    const managerForSendModal: ManagerForSendModal = {
      ...manager,
      isDisabled,
      disabledReason,
      isToggled,
      distributionList: getManagerDistributionList(distributionLists, manager.cmgEntityKey),
    };

    return managerForSendModal;
  });
}

/**
 * Decides whether wire has been already sent to manager
 * @returns true when a sent message exists for a manager
 */
export function wasWireAlreadySent(
  manager: SyndicateWireManager,
  wireMessages: readonly SyndicateWires_WireMessagePartsFragment[] = []
): boolean {
  return wireMessages.some(
    message => message.sentAt && message.firmSnapshot?.cmgEntityKey === manager.cmgEntityKey
  );
}

/**
 * Decided whether manager has email set
 * @returns true when manager has email set
 */
export function hasManagerEmailSet(
  manager: SyndicateWireManager,
  managersWithMissingEmails: string[] = []
): boolean {
  return managersWithMissingEmails.every(m => m !== manager.cmgEntityKey);
}

/**
 * Processes an array of managers and decides on their collective toggled state
 * @returns
 * - RoleToggledState.OFF when there are no managers
 * - RoleToggledState.OFF when all are toggled off
 * - RoleToggledState.ON when all are toggled on
 * - RoleToggledState.INDETERMINATE when some are on, some are off
 */
export function getManagersToggledState(managers: ManagerForSendModal[]): RoleToggledState {
  return (
    managers.reduce<undefined | RoleToggledState>((acc, manager) => {
      if (acc === undefined) {
        return manager.isToggled ? RoleToggledState.ON : RoleToggledState.OFF;
      }

      if (
        (manager.isToggled && acc === RoleToggledState.OFF) ||
        (!manager.isToggled && acc === RoleToggledState.ON)
      ) {
        return RoleToggledState.INDETERMINATE;
      }

      return acc;
    }, undefined) ?? RoleToggledState.OFF
  );
}

export function toggleAllManagers(managers: ManagerForSendModal[]): ManagerForSendModal[] {
  const areAllManagersToggled = managers
    .filter(manager => !manager.isDisabled)
    .every(manager => manager.isToggled);

  return managers.map(manager => ({
    ...manager,
    isToggled: manager.isDisabled ? manager.isToggled : !areAllManagersToggled,
  }));
}

export function toggleRoleManagers(
  role: SyndicateWireManagerRole,
  managers: ManagerForSendModal[]
): ManagerForSendModal[] {
  const managersOfRole = managers.filter(manager => manager.role === role && !manager.isDisabled);
  const isSwitchingToOn = managersOfRole.some(manager => !manager.isToggled);

  return managers.map(manager => {
    if (manager.role !== role || manager.isDisabled) {
      return manager;
    }

    return { ...manager, isToggled: isSwitchingToOn };
  });
}

export function toggleManager(
  cmgEntityKey: string,
  managers: ManagerForSendModal[]
): ManagerForSendModal[] {
  return managers.map(manager => {
    if (manager.cmgEntityKey !== cmgEntityKey || manager.isDisabled) {
      return manager;
    }

    return { ...manager, isToggled: !manager.isToggled };
  });
}
