import { useAuth } from '@cmg/auth';

import {
  RegulatoryFilings_SenderRecipientsQueryVariables,
  useRegulatoryFilings_SenderRecipientsQuery,
} from '../graphql/__generated__';

export function useHasSenderDistributionList({
  offeringId,
}: RegulatoryFilings_SenderRecipientsQueryVariables) {
  const { oidcUserCmgEntityKey } = useAuth();

  const { data, loading } = useRegulatoryFilings_SenderRecipientsQuery({
    variables: { offeringId },
  });

  const { distributionList = [] } =
    data?.offering?.regulatoryFilings.recipients.find(
      ({ cmgEntityKey }) => cmgEntityKey === oidcUserCmgEntityKey
    ) ?? {};

  const hasSenderDistributionList = distributionList.length > 0;

  const disableLabel = loading || !hasSenderDistributionList;

  return {
    disableLabel,
    showWaringIcon: loading ? false : !hasSenderDistributionList,
  };
}
