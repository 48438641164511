import { UUID } from '@cmg/common';
import React from 'react';

import { OrderBook_RetailBrokerDealerPartsFragment } from '../../graphql';
import BulkEditInternalRetailDemandView from './bulk-edit-view/BulkEditInternalRetailDemandView';
import ReadOnlyInternalRetailDemandView from './read-only-view/ReadOnlyInternalRetailDemandView';

export type Props = Readonly<{
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[];
  sellingConcession: number | null | undefined;
  offeringId: UUID;
  trancheId: UUID;
  instrumentId: UUID;
}>;

const BulkSaveInternalRetailDemand: React.FC<Props> = ({
  brokers,
  sellingConcession,
  offeringId,
  trancheId,
  instrumentId,
}) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleFinishEditing = () => {
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <BulkEditInternalRetailDemandView
        brokers={brokers}
        sellingConcession={sellingConcession}
        offeringId={offeringId}
        trancheId={trancheId}
        instrumentId={instrumentId}
        onFinishEditing={handleFinishEditing}
      />
    );
  }

  return (
    <ReadOnlyInternalRetailDemandView
      brokers={brokers}
      sellingConcession={sellingConcession}
      onEdit={handleStartEditing}
    />
  );
};

export default BulkSaveInternalRetailDemand;
