import { useAccessToken } from '@cmg/auth';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  openSupportRequestModal,
  OwnProps as SupportRequestModalProps,
} from '../components/modals/support-request-modal/SupportRequestModal';

type OpenModalProps = Omit<SupportRequestModalProps, 'token'>;
export const useSupportRequestModal = () => {
  const dispatch = useDispatch();
  const token = useAccessToken();

  const open = React.useCallback(
    (props: OpenModalProps = {}) => {
      if (!token) {
        return;
      }
      dispatch(openSupportRequestModal({ token, ...props }));
    },
    [token, dispatch]
  );

  return { open };
};
