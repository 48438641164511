import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { ExpensesFilterProvider } from './context/ExpensesFilterContext';
import ExpensesForm from './form/ExpensesForm';
import { useFinalSettlement_ExpensesQuery } from './graphql';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps & {
  offeringIssuerName: string | undefined;
};

const ExpensesRoute: React.FC<Props> = ({ match, offeringIssuerName }) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.finalSettlementExpenses.getDocumentTitle({
      issuerName: offeringIssuerName,
    })
  );

  const {
    data: expensesData,
    loading: expensesLoading,
    error: expensesError,
  } = useFinalSettlement_ExpensesQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });

  if (expensesLoading) {
    return <Loading />;
  }

  if (expensesError) {
    return <ServerErrorsBanner error={expensesError} />;
  }

  return (
    <ExpensesFilterProvider>
      <ExpensesForm
        expenses={expensesData!.expenses}
        offeringId={offeringId}
        offeringIssuerName={offeringIssuerName ?? 'issuer'}
      />
    </ExpensesFilterProvider>
  );
};

export default ExpensesRoute;
