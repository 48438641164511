import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';

type Props = {
  offeringId: string;
};

export type AccessCheckResult = {
  loading: boolean;
  canRead: boolean;
  canAcknowledge: boolean;
  canManage: boolean;
  canUpdate: boolean;
  isSellSideAccount: boolean;
};

const emptyArray = [];

/**
 * Checks accessibility of an Final Institutional Allocation.
 */
export function useCheckFinalInstitutionalAllocationAccess({
  offeringId,
}: Props): AccessCheckResult {
  // TODO: Most of the places we have two calls for pulling permissions for S&T and Syndicate
  //       We should delete the S&T permission here since we have one for S&T => useCoveredFinal....
  //       This permission can lead into bugs when introducing new personas (multi-bank)
  const hasRead = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalAllocation.READ,
      permissionsByEntity.CoveredAccountsInstitutionalAllocation.READ,
    ],
    false
  );
  const hasFull = useCheckPermissions(
    [
      permissionsByEntity.InstitutionalAllocation.FULL,
      permissionsByEntity.CoveredAccountsInstitutionalAllocation.FULL,
    ],
    false
  );

  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? emptyArray;

  const isSellSideAccount = useCheckAccountType('SELL_SIDE');

  const accessChecks = React.useMemo(
    () => ({
      canRead:
        hasRead &&
        (!isSellSideAccount || entitlements.includes('READ:INSTITUTIONAL_ALLOCATION_FINAL')),
      canAcknowledge:
        hasFull &&
        (!isSellSideAccount || entitlements.includes('ACKNOWLEDGE:INSTITUTIONAL_ALLOCATION_FINAL')),
      canUpdate:
        hasFull &&
        (!isSellSideAccount || entitlements.includes('UPDATE:INSTITUTIONAL_ALLOCATION_FINAL')),
      canManage:
        hasFull &&
        (!isSellSideAccount || entitlements.includes('MANAGE:INSTITUTIONAL_ALLOCATION_FINAL')),
    }),
    [hasRead, hasFull, entitlements, isSellSideAccount]
  );

  return { loading, ...accessChecks, isSellSideAccount };
}
