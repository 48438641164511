/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_DeliveryInstrumentPartsFragment = {
  readonly __typename?: 'DeliveryInstrument';
  readonly id: string;
  readonly currencyCode: string;
  readonly countryCode: string;
  readonly isDepositaryReceipt: boolean;
  readonly stockSymbol?: string | null;
  readonly isin?: string | null;
  readonly cusip?: string | null;
  readonly figi?: string | null;
  readonly exchange?: string | null;
  readonly exchangeMic?: Types.ExchangeMic | null;
  readonly depositaryReceiptConversionRatio?: {
    readonly __typename?: 'DepositaryReceiptConversionRatio';
    readonly numerator: number;
    readonly denominator: number;
  } | null;
};

export type OfferingSetup_DeliveryInstrumentsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DeliveryInstrumentsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly pricingInstrumentId?: string | null;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly isin?: string | null;
      readonly cusip?: string | null;
      readonly figi?: string | null;
      readonly exchange?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
  };
};

export type OfferingSetup_CreateDeliveryInstrumentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateDeliveryInstrumentInput;
}>;

export type OfferingSetup_CreateDeliveryInstrumentMutation = {
  readonly __typename?: 'Mutation';
  readonly createDeliveryInstrument:
    | {
        readonly __typename?: 'DeliveryInstrument';
        readonly id: string;
        readonly currencyCode: string;
        readonly countryCode: string;
        readonly isDepositaryReceipt: boolean;
        readonly stockSymbol?: string | null;
        readonly isin?: string | null;
        readonly cusip?: string | null;
        readonly figi?: string | null;
        readonly exchange?: string | null;
        readonly exchangeMic?: Types.ExchangeMic | null;
        readonly depositaryReceiptConversionRatio?: {
          readonly __typename?: 'DepositaryReceiptConversionRatio';
          readonly numerator: number;
          readonly denominator: number;
        } | null;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_UpdateDeliveryInstrumentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
  input: Types.UpdateDeliveryInstrumentInput;
}>;

export type OfferingSetup_UpdateDeliveryInstrumentMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDeliveryInstrument:
    | {
        readonly __typename?: 'DeliveryInstrument';
        readonly id: string;
        readonly currencyCode: string;
        readonly countryCode: string;
        readonly isDepositaryReceipt: boolean;
        readonly stockSymbol?: string | null;
        readonly isin?: string | null;
        readonly cusip?: string | null;
        readonly figi?: string | null;
        readonly exchange?: string | null;
        readonly exchangeMic?: Types.ExchangeMic | null;
        readonly depositaryReceiptConversionRatio?: {
          readonly __typename?: 'DepositaryReceiptConversionRatio';
          readonly numerator: number;
          readonly denominator: number;
        } | null;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_DeleteDeliveryInstrumentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DeleteDeliveryInstrumentMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteDeliveryInstrument: string;
};

export type OfferingSetup_SetPricingInstrumentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  instrumentId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_SetPricingInstrumentMutation = {
  readonly __typename?: 'Mutation';
  readonly setPricingInstrument:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly pricingInstrumentId?: string | null;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const OfferingSetup_DeliveryInstrumentPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_DeliveryInstrumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstruments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_DeliveryInstrumentsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_DeliveryInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeliveryInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_DeliveryInstrumentsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_DeliveryInstrumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_DeliveryInstrumentsQuery,
    OfferingSetup_DeliveryInstrumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_DeliveryInstrumentsQuery,
    OfferingSetup_DeliveryInstrumentsQueryVariables
  >(OfferingSetup_DeliveryInstrumentsDocument, options);
}
export function useOfferingSetup_DeliveryInstrumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_DeliveryInstrumentsQuery,
    OfferingSetup_DeliveryInstrumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_DeliveryInstrumentsQuery,
    OfferingSetup_DeliveryInstrumentsQueryVariables
  >(OfferingSetup_DeliveryInstrumentsDocument, options);
}
export type OfferingSetup_DeliveryInstrumentsQueryHookResult = ReturnType<
  typeof useOfferingSetup_DeliveryInstrumentsQuery
>;
export type OfferingSetup_DeliveryInstrumentsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_DeliveryInstrumentsLazyQuery
>;
export type OfferingSetup_DeliveryInstrumentsQueryResult = Apollo.QueryResult<
  OfferingSetup_DeliveryInstrumentsQuery,
  OfferingSetup_DeliveryInstrumentsQueryVariables
>;
export const OfferingSetup_CreateDeliveryInstrumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateDeliveryInstrument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDeliveryInstrumentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDeliveryInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateDeliveryInstrumentMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateDeliveryInstrumentMutation,
  OfferingSetup_CreateDeliveryInstrumentMutationVariables
>;

/**
 * __useOfferingSetup_CreateDeliveryInstrumentMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateDeliveryInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateDeliveryInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateDeliveryInstrumentMutation, { data, loading, error }] = useOfferingSetup_CreateDeliveryInstrumentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateDeliveryInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateDeliveryInstrumentMutation,
    OfferingSetup_CreateDeliveryInstrumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateDeliveryInstrumentMutation,
    OfferingSetup_CreateDeliveryInstrumentMutationVariables
  >(OfferingSetup_CreateDeliveryInstrumentDocument, options);
}
export type OfferingSetup_CreateDeliveryInstrumentMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateDeliveryInstrumentMutation
>;
export type OfferingSetup_CreateDeliveryInstrumentMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateDeliveryInstrumentMutation>;
export type OfferingSetup_CreateDeliveryInstrumentMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_CreateDeliveryInstrumentMutation,
  OfferingSetup_CreateDeliveryInstrumentMutationVariables
>;
export const OfferingSetup_UpdateDeliveryInstrumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateDeliveryInstrument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDeliveryInstrumentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeliveryInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DeliveryInstrumentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryInstrument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateDeliveryInstrumentMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateDeliveryInstrumentMutation,
  OfferingSetup_UpdateDeliveryInstrumentMutationVariables
>;

/**
 * __useOfferingSetup_UpdateDeliveryInstrumentMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateDeliveryInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateDeliveryInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateDeliveryInstrumentMutation, { data, loading, error }] = useOfferingSetup_UpdateDeliveryInstrumentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      instrumentId: // value for 'instrumentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateDeliveryInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateDeliveryInstrumentMutation,
    OfferingSetup_UpdateDeliveryInstrumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateDeliveryInstrumentMutation,
    OfferingSetup_UpdateDeliveryInstrumentMutationVariables
  >(OfferingSetup_UpdateDeliveryInstrumentDocument, options);
}
export type OfferingSetup_UpdateDeliveryInstrumentMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateDeliveryInstrumentMutation
>;
export type OfferingSetup_UpdateDeliveryInstrumentMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateDeliveryInstrumentMutation>;
export type OfferingSetup_UpdateDeliveryInstrumentMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateDeliveryInstrumentMutation,
  OfferingSetup_UpdateDeliveryInstrumentMutationVariables
>;
export const OfferingSetup_DeleteDeliveryInstrumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteDeliveryInstrument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDeliveryInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteDeliveryInstrumentMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteDeliveryInstrumentMutation,
  OfferingSetup_DeleteDeliveryInstrumentMutationVariables
>;

/**
 * __useOfferingSetup_DeleteDeliveryInstrumentMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteDeliveryInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteDeliveryInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteDeliveryInstrumentMutation, { data, loading, error }] = useOfferingSetup_DeleteDeliveryInstrumentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useOfferingSetup_DeleteDeliveryInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteDeliveryInstrumentMutation,
    OfferingSetup_DeleteDeliveryInstrumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteDeliveryInstrumentMutation,
    OfferingSetup_DeleteDeliveryInstrumentMutationVariables
  >(OfferingSetup_DeleteDeliveryInstrumentDocument, options);
}
export type OfferingSetup_DeleteDeliveryInstrumentMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteDeliveryInstrumentMutation
>;
export type OfferingSetup_DeleteDeliveryInstrumentMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteDeliveryInstrumentMutation>;
export type OfferingSetup_DeleteDeliveryInstrumentMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteDeliveryInstrumentMutation,
  OfferingSetup_DeleteDeliveryInstrumentMutationVariables
>;
export const OfferingSetup_SetPricingInstrumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_SetPricingInstrument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPricingInstrument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instrumentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instrumentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_SetPricingInstrumentMutationFn = Apollo.MutationFunction<
  OfferingSetup_SetPricingInstrumentMutation,
  OfferingSetup_SetPricingInstrumentMutationVariables
>;

/**
 * __useOfferingSetup_SetPricingInstrumentMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_SetPricingInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_SetPricingInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSetPricingInstrumentMutation, { data, loading, error }] = useOfferingSetup_SetPricingInstrumentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useOfferingSetup_SetPricingInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_SetPricingInstrumentMutation,
    OfferingSetup_SetPricingInstrumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_SetPricingInstrumentMutation,
    OfferingSetup_SetPricingInstrumentMutationVariables
  >(OfferingSetup_SetPricingInstrumentDocument, options);
}
export type OfferingSetup_SetPricingInstrumentMutationHookResult = ReturnType<
  typeof useOfferingSetup_SetPricingInstrumentMutation
>;
export type OfferingSetup_SetPricingInstrumentMutationResult =
  Apollo.MutationResult<OfferingSetup_SetPricingInstrumentMutation>;
export type OfferingSetup_SetPricingInstrumentMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_SetPricingInstrumentMutation,
  OfferingSetup_SetPricingInstrumentMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_DeliveryInstrumentsMockResponse = makeMockResponse<
  OfferingSetup_DeliveryInstrumentsQueryVariables,
  OfferingSetup_DeliveryInstrumentsQuery
>(OfferingSetup_DeliveryInstrumentsDocument);
