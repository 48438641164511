import { LinkButton, ODSTable as Table } from '@cmg/common';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';

import { EmptyState } from '../../../../design-system/components/data-display/empty-state/EmptyState';
import { useExpensesFilterContext } from '../context/ExpensesFilterContext';
import { SSpacer, SWrapper } from '../ExpensesRoute.styles';
import {
  ExpensesValues,
  mapExpensesFilter,
  newDealRelatedExpense,
} from '../form/ExpensesForm.model';
import EditableDealRelatedExpenseRow from './editable-expenses/EditableDealRelatedExpenseRow';
import ExpensesFilter from './filter/ExpensesFilter';
import ReadOnlyDealRelatedExpenseRow from './read-only-expenses/ReadOnlyDealRelatedExpenseRow';
import DealRelatedExpensesTableHeader from './table-header/DealRelatedExpensesTableHeader';

export type Props = {
  editable: boolean;
};

const DealRelatedExpensesRoute: React.FC<Props> = ({ editable }) => {
  const { values } = useFormikContext<ExpensesValues>();

  const { categoryFilter, descriptionFilter, setCategoryFilter, setDescriptionFilter } =
    useExpensesFilterContext();

  const filteredExpenses = React.useMemo(
    () => values.dealRelatedExpenses.map(mapExpensesFilter(categoryFilter, descriptionFilter)),
    [values.dealRelatedExpenses, categoryFilter, descriptionFilter]
  );

  const shouldDisplayNotFound = !filteredExpenses.some(({ shouldDisplay }) => shouldDisplay);

  const NotFoundComponent = (
    <EmptyState title="No Results Found" message="Adjust your search or filter and try again" />
  );

  if (editable) {
    return (
      <FieldArray
        validateOnChange={false}
        name="dealRelatedExpenses"
        render={({ unshift, remove }) => {
          const handleAddExpense = () => {
            setCategoryFilter('');
            setDescriptionFilter('');
            unshift(newDealRelatedExpense);
          };
          return (
            <SWrapper>
              <ExpensesFilter>
                <LinkButton
                  inline
                  testId="add-expense-header-button"
                  iconLeft={{ name: 'plus' }}
                  onClick={handleAddExpense}
                >
                  Add Expense
                </LinkButton>
              </ExpensesFilter>
              <Table gridTemplateColumns="2fr 4fr 1fr 40px">
                <DealRelatedExpensesTableHeader editable={true} />
                {filteredExpenses.map(
                  (item, index) =>
                    item.shouldDisplay && (
                      <EditableDealRelatedExpenseRow
                        key={index}
                        index={index}
                        onRemoveExpense={remove}
                      />
                    )
                )}
              </Table>
              {filteredExpenses.length > 0 && !shouldDisplayNotFound && <SSpacer />}
              {filteredExpenses.length === 0 && (
                <EmptyState
                  title="No Deal Related Expenses"
                  message={
                    <span>
                      Click{' '}
                      <LinkButton
                        testId="add-expense-body-button"
                        iconLeft={{ name: 'plus' }}
                        onClick={handleAddExpense}
                        inline
                      >
                        Add Expense
                      </LinkButton>{' '}
                      to add a Deal Related Expense
                    </span>
                  }
                />
              )}
              {shouldDisplayNotFound && filteredExpenses.length > 0 && NotFoundComponent}
            </SWrapper>
          );
        }}
      />
    );
  }

  return (
    <SWrapper>
      <ExpensesFilter />
      <Table gridTemplateColumns="2fr 4fr 1fr">
        <DealRelatedExpensesTableHeader editable={false} />
        {filteredExpenses.map(
          (item, index) =>
            item.shouldDisplay && <ReadOnlyDealRelatedExpenseRow key={index} expense={item} />
        )}
      </Table>
      {shouldDisplayNotFound && filteredExpenses.length > 0 && NotFoundComponent}
    </SWrapper>
  );
};

export default DealRelatedExpensesRoute;
