/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_UpdateAccountingNotesMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  notes: Types.Scalars['String'];
}>;

export type FinalSettlement_UpdateAccountingNotesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAccountingNotes: {
    readonly __typename?: 'AccountingNotes';
    readonly notes: string;
  };
};

export const FinalSettlement_UpdateAccountingNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_UpdateAccountingNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountingNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'notes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_UpdateAccountingNotesMutationFn = Apollo.MutationFunction<
  FinalSettlement_UpdateAccountingNotesMutation,
  FinalSettlement_UpdateAccountingNotesMutationVariables
>;

/**
 * __useFinalSettlement_UpdateAccountingNotesMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_UpdateAccountingNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_UpdateAccountingNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementUpdateAccountingNotesMutation, { data, loading, error }] = useFinalSettlement_UpdateAccountingNotesMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useFinalSettlement_UpdateAccountingNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_UpdateAccountingNotesMutation,
    FinalSettlement_UpdateAccountingNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_UpdateAccountingNotesMutation,
    FinalSettlement_UpdateAccountingNotesMutationVariables
  >(FinalSettlement_UpdateAccountingNotesDocument, options);
}
export type FinalSettlement_UpdateAccountingNotesMutationHookResult = ReturnType<
  typeof useFinalSettlement_UpdateAccountingNotesMutation
>;
export type FinalSettlement_UpdateAccountingNotesMutationResult =
  Apollo.MutationResult<FinalSettlement_UpdateAccountingNotesMutation>;
export type FinalSettlement_UpdateAccountingNotesMutationOptions = Apollo.BaseMutationOptions<
  FinalSettlement_UpdateAccountingNotesMutation,
  FinalSettlement_UpdateAccountingNotesMutationVariables
>;
