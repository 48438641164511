import { Panel } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import CalendarNavbar from './components/navbar/CalendarNavbar';
import FiledCalendarRoute from './filed-calendar/FiledCalendarRoute';
import useShowFiledCalendar from './hooks/useShowFiledCalendar';
import LiveCalendarRoute from './live-calendar/LiveCalendarRoute';
import PostponedCalendarRoute from './postponed-calendar/PostponedCalendarRoute';
import PricedCalendarRoute from './priced-calendar/PricedCalendarRoute';

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

/**
 * Primary Calendar route displays the calendar navbar and
 * sub routes for the different calendar categories
 */
const CalendarRoute: React.FC<Props> = () => {
  const canViewFiledCalendar = useShowFiledCalendar();

  useDocumentTitle(routeFactory.calendar.getDocumentTitle());

  return (
    <Panel fillViewport testId={xcSelectors.calendarScreen.testId}>
      <CalendarNavbar />
      <Switch>
        <Route path={routeFactory.calendarLive.routePath} component={LiveCalendarRoute} />
        <Route path={routeFactory.calendarPriced.routePath} component={PricedCalendarRoute} />
        {canViewFiledCalendar && (
          <Route path={routeFactory.calendarFiled.routePath} component={FiledCalendarRoute} />
        )}
        <Route path={routeFactory.calendarPostponed.routePath} component={PostponedCalendarRoute} />
        {/* Default to account subscription when no other routes match */}
        <Route render={() => <Redirect to={routeFactory.calendarLive.getUrlPath()} />} />
      </Switch>
    </Panel>
  );
};

export default CalendarRoute;
