import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import TotalExpensesInfoBox from './total-expenses-info-box/TotalExpensesInfoBox';

const ExpensesPanel: React.FC = () => {
  return (
    <Panel>
      <Panel.Header title="Expenses" />
      <Panel.Content>
        <TotalExpensesInfoBox />
      </Panel.Content>
    </Panel>
  );
};

export default ExpensesPanel;
