import { PercentInputField } from '@cmg/common';
import React from 'react';

export interface Props {
  column: { colId: string };
  data: {
    id: string;
  };
  disabled?: boolean;
}
export const PercentInputRenderer: React.FC<Props> = ({ column, data, disabled = false }) => {
  const fieldName = 'rows'.concat(`[${data.id}]`).concat(column.colId);
  return (
    <PercentInputField
      displayPrecisionOnBlur
      fullWidth
      precision={4}
      maxLength={8}
      name={fieldName}
      disabled={disabled}
    />
  );
};
