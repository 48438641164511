import React from 'react';

import { SContainer } from './List.styles';

export type Props = {
  className?: string;
};

const List: React.FC<Props> = ({ children, className }) => {
  return <SContainer className={className}>{children}</SContainer>;
};

export default List;
