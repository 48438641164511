import {
  AgGridEvent,
  CellPosition,
  SuppressKeyboardEventParams,
  TabToNextCellParams,
} from 'ag-grid-community';
import React from 'react';

export const tabToNextCell = (
  params: TabToNextCellParams,
  gridRef: React.MutableRefObject<AgGridEvent | undefined>
): CellPosition => {
  const previousCell = params.previousCellPosition;
  const lastRowIndex = previousCell.rowIndex;
  let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
  const renderedRowCount = gridRef.current!.api.getModel().getRowCount();
  if (nextRowIndex < 0) {
    nextRowIndex = -1;
  }
  if (nextRowIndex >= renderedRowCount) {
    nextRowIndex = renderedRowCount - 1;
  }
  return {
    rowIndex: nextRowIndex,
    column: previousCell.column,
    rowPinned: previousCell.rowPinned,
  };
};

export const useCellFocused = () => {
  return React.useCallback(() => {
    const childInputs = document!.activeElement!.querySelectorAll('input');
    if (childInputs.length > 0) {
      childInputs[0].focus();
    }
  }, []);
};

export const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
  // if its a tab, let the grid intercept
  const { key } = params.event;
  if (key === 'Tab') {
    return false;
  }
  // if focused on an element in a cell, don't let the grid intercept
  const element = params.event.target as Element; // casting as Element because .matches is a property of objects of type Element
  return element.matches(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
  );
};
