import {
  AppBarMenu,
  Box,
  Button,
  Divider,
  IconButton,
  OpenInNewOutlined,
  Stack,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import React, { useCallback, useContext } from 'react';

import routeFactory from '../../util/routeFactory';
import { useInstrumentation } from '../hooks';
import { PushNotificationStatusDescriptionMap } from '../types';
import { PushNotificationIcon } from './PushNotificationIcon';
import { PushNotificationContext } from './PushNotificationProvider';

// TODO - replace with external route when it is available
//  const learnMoreURL = externalRouteFactory.knowledgeBase.getUrlPath(
//    '/docs/enable-web-browser-notifications'
//  );
const learnMoreURL = routeFactory.browserNotificationDocumentation.getUrlPath();

export const PushNotificationControl = () => {
  const { record } = useInstrumentation();

  const {
    isLoading,
    pushNotificationStatus: status,
    setPushNotificationEnabledStatus,
    requestPermission,
    pushNotificationTouchedStatus,
    setPushNotificationTouchedStatus,
    sendTestNotification,
  } = useContext(PushNotificationContext);

  const handleMenuOpen = useCallback(() => {
    setPushNotificationTouchedStatus(true);
    record('Icon clicked', {
      pushNotification_isTouched: pushNotificationTouchedStatus ?? false,
      pushNotification_status: status,
    });
  }, [pushNotificationTouchedStatus, record, setPushNotificationTouchedStatus, status]);

  const handleInitialTurnOnClick = useCallback(
    (closeMenu: () => void) => () => {
      closeMenu();
      requestPermission();
      record('Turn On button clicked', { pushNotification_status: status });
    },
    [record, requestPermission, status]
  );

  const handleTurnOffClick = useCallback(
    (closeMenu: () => void) => () => {
      closeMenu();
      setPushNotificationEnabledStatus(false);
      record('Turn Off button clicked', { pushNotification_status: status });
    },
    [record, setPushNotificationEnabledStatus, status]
  );

  const handlePausedTurnOnClick = useCallback(
    (closeMenu: () => void) => () => {
      closeMenu();
      setPushNotificationEnabledStatus(true);
      record('Turn On button clicked', { pushNotification_status: status });
    },
    [record, setPushNotificationEnabledStatus, status]
  );

  const handleSendTestNotificationClick = useCallback(async () => {
    await sendTestNotification();
    record('Send Test Notification button clicked');
  }, [record, sendTestNotification]);

  const handleLearnMoreClick = useCallback(
    (placement: string) => () => {
      record('Learn More button clicked', {
        pushNotification_status: status,
        pushNotification_placement: placement,
      });
    },
    [record, status]
  );

  const renderItems = useCallback(
    ({ closeMenu }) => [
      <Box key="push-notification-menu">
        <Typography sx={{ my: 1, mx: 2 }} variant="h4">
          Push Notifications
        </Typography>
        <Box sx={{ p: 2 }}>
          <Typography>Get browser notifications for XC calendar activities.</Typography>
          {status !== 'disabled-byBrowser' && (
            <Button
              variant="text"
              href={learnMoreURL}
              target="_blank"
              rel="noopener"
              onClick={handleLearnMoreClick('content')}
            >
              Learn More
            </Button>
          )}
        </Box>
        <Divider />
        <Stack direction="row" sx={{ px: 2, py: 1 }} display="flex" alignItems="center">
          <Tooltip title="Send a Test Notification">
            <span>
              <IconButton
                sx={{ p: 0 }}
                color="default"
                onClick={handleSendTestNotificationClick}
                disabled={status !== 'enabled'}
                aria-label="Send Notification"
              >
                <PushNotificationIcon status={status} />
              </IconButton>
            </span>
          </Tooltip>
          <Typography sx={{ ml: 1 }} variant="body2">
            {PushNotificationStatusDescriptionMap[status]}
          </Typography>
          <Box flexGrow={1} />
          {{
            default: () => (
              <Button variant="contained" onClick={handleInitialTurnOnClick(closeMenu)}>
                Turn On
              </Button>
            ),
            enabled: () => (
              <React.Fragment>
                <Button variant="text" onClick={handleTurnOffClick(closeMenu)}>
                  Turn Off
                </Button>
              </React.Fragment>
            ),
            'disabled-byBrowser': () => (
              <Button
                variant="text"
                href={learnMoreURL}
                target="_blank"
                rel="noopener"
                endIcon={<OpenInNewOutlined />}
                onClick={handleLearnMoreClick('footer')}
              >
                Learn More
              </Button>
            ),
            'disabled-byUser': () => (
              <Button variant="contained" onClick={handlePausedTurnOnClick(closeMenu)}>
                Turn On
              </Button>
            ),
          }[status]()}
        </Stack>
      </Box>,
    ],
    [
      handleInitialTurnOnClick,
      handleLearnMoreClick,
      handlePausedTurnOnClick,
      handleSendTestNotificationClick,
      handleTurnOffClick,
      status,
    ]
  );

  return (
    <React.Fragment>
      <style>
        {`
          @keyframes shake-animation {
            0%, 80% { transform: rotate(0deg); }
            82% { transform: rotate(20deg); }
            84% { transform: rotate(-20deg); }
            86% { transform: rotate(20deg); }
            88% { transform: rotate(-20deg); }
            90% { transform: rotate(20deg); }
            92% { transform: rotate(-12deg); }
            94% { transform: rotate(12deg); }
            96% { transform: rotate(-6deg); }
            98% { transform: rotate(6deg); }
            100% { transform: rotate(0deg); }
          }
        `}
      </style>
      <AppBarMenu
        id="push-notification-menu"
        label="Push Notification"
        disabled={isLoading}
        icon={
          <PushNotificationIcon
            status={status}
            style={
              status === 'default' && !pushNotificationTouchedStatus
                ? { animation: 'shake-animation 5s infinite', transformOrigin: 'center center' }
                : undefined
            }
          />
        }
        renderItems={renderItems}
        onOpen={handleMenuOpen}
      />
    </React.Fragment>
  );
};
