import React from 'react';
import { useHistory } from 'react-router-dom';

import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { Roadshows_RoadshowPartsFragment } from '../../../../../common/graphql';
import LogisticsSidePanel from '../../common/logistics-side-panel/LogisticsSidePanel';
import { LogisticsNavigationSteps } from '../../common/logistics-side-panel/LogisticsSidePanel.model';
import { useRoadshows_CoveredLogisticsQuery } from './graphql';
import LogisticsDetails from './logistics-details/LogisticsDetails';
import LogisticsParticipants from './logistics-participants/LogisticsParticipants';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  logisticsId: string;
  onClose: () => void;
  stepId?: LogisticsNavigationSteps;
};

const SalesAndTradingLogisticsDetailsPanel: React.FC<Props> = ({
  logisticsId,
  stepId = LogisticsNavigationSteps.DETAILS,
  onClose,
  roadshow,
}) => {
  const { push } = useHistory();
  const {
    data: logisticsData,
    loading: logisticsLoading,
    error: logisticsError,
  } = useRoadshows_CoveredLogisticsQuery({
    variables: { logisticsId, roadshowId: roadshow.id },
    fetchPolicy: 'cache-and-network',
  });

  const handleNavigationChange = (nextStepId: LogisticsNavigationSteps) => {
    push(
      routeFactory.roadshowLogisticsDetails.getUrlPath({
        logisticsId,
        roadshowId: roadshow.id,
        stepId: nextStepId,
      })
    );
  };

  return (
    <LogisticsSidePanel
      onClose={onClose}
      activeNavigationStepId={stepId}
      title={logisticsData?.coveredRoadshowLogisticsDetails.title}
      loading={logisticsLoading}
      onNavigationChange={handleNavigationChange}
    >
      <FlexLayout expand direction="column">
        {logisticsError && <ServerErrorsBanner error={logisticsError} />}

        {stepId === LogisticsNavigationSteps.DETAILS && logisticsData && (
          <LogisticsDetails logistics={logisticsData.coveredRoadshowLogisticsDetails} />
        )}

        {stepId === LogisticsNavigationSteps.PARTICIPANTS && logisticsData && (
          <LogisticsParticipants
            participants={logisticsData.coveredRoadshowLogisticsParticipants}
          />
        )}
      </FlexLayout>
    </LogisticsSidePanel>
  );
};

export default SalesAndTradingLogisticsDetailsPanel;
