import { UUID } from '@cmg/common';
import { Checkbox, FormControlLabel, Stack } from '@cmg/design-system';
import React from 'react';

import RecipientListWarningIcon from '../../../../../common/components/lists/recipient-list/RecipientListWarningIcon';
import { useHasSenderDistributionList } from './hooks/useHasSenderDistributionList';

export type Props = Readonly<{
  offeringId: UUID;
  canSendNotification: boolean;
  shouldAddSenderToBcc: boolean;
  onChange: () => void;
  onContactSupport: () => void;
}>;

export const BCCSenderSection: React.FC<Props> = ({
  offeringId,
  canSendNotification,
  shouldAddSenderToBcc,
  onChange,
  onContactSupport,
}) => {
  const { disableLabel, showWaringIcon } = useHasSenderDistributionList({ offeringId });

  return (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        disabled={disableLabel || !canSendNotification}
        control={<Checkbox checked={shouldAddSenderToBcc} onChange={onChange} />}
        label="Send a copy of each notification to my firm"
      />
      {showWaringIcon && <RecipientListWarningIcon onContactSupport={onContactSupport} />}
    </Stack>
  );
};
