import { DataGridGroupingClient, DeepWritable } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import { useFeatureToggles } from '../../../common/config';
import { nonEditableGridOptions } from '../../constants';
import Filters from '../filters/Filters';
import useFilters from '../filters/hooks/useFilters';
import {
  MyPublishedOfferingsQuery,
  useMyPublishedOfferingsQuery,
} from './hooks/useMyPublishedOfferingsQuery';
import { usePublishedOfferingsQuery } from './hooks/usePublishedOfferingsQuery';
import columns from './OfferingColumns';
import {
  baseGridOptions,
  extended,
  groupHeaderComparator,
  groupHeaderRenderer,
  orderComparator,
} from './OfferingsRoute.model';

const FALLBACK_SOURCE_OFFERINGS = [];

const MemoizedDataGridGroupingClient = React.memo(DataGridGroupingClient);

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

const OfferingsRoute: React.FC<Props> = () => {
  const theme = useTheme();
  const { isOfferingSetupMyOfferingsOfferingFullGql } = useFeatureToggles();
  const { data, loading, error } = useMyPublishedOfferingsQuery({
    onPlatformOnly: true,
    shouldSkip: isOfferingSetupMyOfferingsOfferingFullGql,
  });
  const { publishedOfferings, isLoadingPublishedOfferings, publishedOfferingsError } =
    usePublishedOfferingsQuery({ shouldSkip: !isOfferingSetupMyOfferingsOfferingFullGql });

  const { myPublishedOfferings, myPublishedOfferingsData, isLoading, serverError } =
    isOfferingSetupMyOfferingsOfferingFullGql
      ? {
          myPublishedOfferings: publishedOfferings,
          myPublishedOfferingsData: publishedOfferings.data,
          isLoading: isLoadingPublishedOfferings,
          serverError: publishedOfferingsError,
        }
      : {
          myPublishedOfferings: data?.myPublishedOfferings,
          myPublishedOfferingsData: data?.myPublishedOfferings.data,
          isLoading: loading,
          serverError: error,
        };

  const gridOptions = useMemo(
    () => ({
      ...baseGridOptions,
      ...nonEditableGridOptions,
      rowStyle: {
        background: theme.background.color.white,
        borderBottom: theme.border.smallSolidLight,
      },
    }),
    [theme]
  );

  const { filters, setFilters, filteredData } = useFilters<
    MyPublishedOfferingsQuery['myPublishedOfferings']['data'][number]
  >({
    sourceOfferings: myPublishedOfferingsData ?? FALLBACK_SOURCE_OFFERINGS,
    withUrlFilters: true,
  });

  return (
    <FlexLayout direction="column" data-test-id={xcSelectors.myOfferingsOfferingsTab.testId}>
      <Filters offerings={myPublishedOfferings} onChange={setFilters} filters={filters} />
      {serverError && <ServerErrorsBanner error={serverError} />}
      <MemoizedDataGridGroupingClient
        groupByField="pricingDate"
        groupHeaderRenderer={groupHeaderRenderer}
        groupHeaderComparator={groupHeaderComparator}
        orderField="issuerName"
        orderDirection="asc"
        rows={filteredData as DeepWritable<typeof filteredData>}
        columns={columns}
        extended={extended}
        loading={isLoading}
        resizeBy="grid"
        gridOptions={gridOptions}
        orderComparator={orderComparator}
      />
    </FlexLayout>
  );
};

export default OfferingsRoute;
