/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_BankInvestorInformationQueryVariables = Types.Exact<{
  firmId: Types.Scalars['String'];
}>;

export type SalesCredits_BankInvestorInformationQuery = {
  readonly __typename?: 'Query';
  readonly investorFirm: {
    readonly __typename?: 'InvestorFirmBasic';
    readonly id: string;
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
    readonly address1?: string | null;
    readonly address2?: string | null;
  };
};

export const SalesCredits_BankInvestorInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesCredits_BankInvestorInformation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKeyOrId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesCredits_BankInvestorInformationQuery__
 *
 * To run a query within a React component, call `useSalesCredits_BankInvestorInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_BankInvestorInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCredits_BankInvestorInformationQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useSalesCredits_BankInvestorInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesCredits_BankInvestorInformationQuery,
    SalesCredits_BankInvestorInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesCredits_BankInvestorInformationQuery,
    SalesCredits_BankInvestorInformationQueryVariables
  >(SalesCredits_BankInvestorInformationDocument, options);
}
export function useSalesCredits_BankInvestorInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCredits_BankInvestorInformationQuery,
    SalesCredits_BankInvestorInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesCredits_BankInvestorInformationQuery,
    SalesCredits_BankInvestorInformationQueryVariables
  >(SalesCredits_BankInvestorInformationDocument, options);
}
export type SalesCredits_BankInvestorInformationQueryHookResult = ReturnType<
  typeof useSalesCredits_BankInvestorInformationQuery
>;
export type SalesCredits_BankInvestorInformationLazyQueryHookResult = ReturnType<
  typeof useSalesCredits_BankInvestorInformationLazyQuery
>;
export type SalesCredits_BankInvestorInformationQueryResult = Apollo.QueryResult<
  SalesCredits_BankInvestorInformationQuery,
  SalesCredits_BankInvestorInformationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeSalesCredits_BankInvestorInformationMockResponse = makeMockResponse<
  SalesCredits_BankInvestorInformationQueryVariables,
  SalesCredits_BankInvestorInformationQuery
>(SalesCredits_BankInvestorInformationDocument);
