import {
  FlexContainer,
  PrimaryButton,
  SecondaryButton,
  TextAreaField,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import { validationSchema, Values } from './AccountingNotesModal.model';
import { SNotesLabel } from './AccountingNotesModal.styles';
import { useUpdateAccountingNotesMutation } from './hooks/useUpdateAccountingNotesMutation';

export type Props = { show: boolean; onHide: () => void; offeringId: UUID };

const AccountingNotesModal: React.FC<Props> = ({ show, onHide, offeringId }) => {
  const { notes } = useAccountingContext();
  const [updateNotes, { loading }] = useUpdateAccountingNotesMutation(offeringId);

  const formik = useFormik<Values>({
    validationSchema,
    initialValues: { notes },
    onSubmit: async values => {
      try {
        await updateNotes({ variables: { offeringId, notes: values.notes ?? '' } });

        ToastManager.success('Successfully updated notes');
        onHide();
      } catch {
        ToastManager.error('Failed to update notes');
      }
    },
  });

  const { values, resetForm, submitForm, isValid } = formik;

  const handleCloseModal = () => {
    resetForm();
    onHide();
  };

  const notesLength = values.notes?.length ?? 0;

  return (
    <Modal show={show} title="Edit Notes">
      <Modal.Content>
        <FormikProvider value={formik}>
          <Form>
            <TextAreaField name="notes" rows={7} fullWidth />
          </Form>

          <SNotesLabel hasError={notesLength > 600}>{notesLength}/600 Characters</SNotesLabel>
        </FormikProvider>
      </Modal.Content>

      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton
            testId="save-button"
            disabled={loading || !isValid}
            loading={loading}
            onClick={submitForm}
          >
            Save
          </PrimaryButton>
          <SecondaryButton testId="cancel-button" disabled={loading} onClick={handleCloseModal}>
            Cancel
          </SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountingNotesModal;
