import { UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { RouteConfigType } from '../../syndicate-wires-side-nav/SyndicateWiresSideNav.model';

export const useRedirectToFirstWire = ({
  isWireTypeActive,
  offeringId,
  syndicateWireId,
  firstWireId,
  wireTypeRoute,
}: {
  isWireTypeActive: boolean;
  offeringId: UUID;
  syndicateWireId: UUID | undefined;
  firstWireId: UUID | undefined;
  wireTypeRoute: RouteConfigType;
}) => {
  const { push } = useHistory();

  React.useEffect(() => {
    if (!isWireTypeActive || syndicateWireId || !firstWireId) {
      return;
    }

    push(wireTypeRoute.getUrlPath({ offeringId, syndicateWireId: firstWireId }));
  }, [isWireTypeActive, offeringId, syndicateWireId, push, firstWireId, wireTypeRoute]);
};
