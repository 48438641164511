/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.InstitutionalIndicationAssignDupesInput;
}>;

export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation = {
  readonly __typename?: 'Mutation';
  readonly markIndicationsAsDuplicate: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
  };
};

export const InstitutionalDemandGrid_MarkIndicationsAsDuplicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_MarkIndicationsAsDuplicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationAssignDupesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markIndicationsAsDuplicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationFn = Apollo.MutationFunction<
  InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation,
  InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationVariables
>;

/**
 * __useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridMarkIndicationsAsDuplicateMutation, { data, loading, error }] = useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation,
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation,
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationVariables
  >(InstitutionalDemandGrid_MarkIndicationsAsDuplicateDocument, options);
}
export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation
>;
export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation>;
export type InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutation,
    InstitutionalDemandGrid_MarkIndicationsAsDuplicateMutationVariables
  >;
