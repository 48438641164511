import { IconButton } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../../../../../../common/components/layout/panel/Panel';
import { SLinkWrapper, StyledLinkButton } from './InvestorPanel.styles';

type Props = {
  name: string;
  memberCount: number;
  onDelete: () => void;
  onAddMember: () => void;
};

/**
 * Render Investor Team header with basic information and action buttons
 */
export const InvestorPanel: React.FC<Props> = ({
  name,
  memberCount,
  onAddMember,
  onDelete,
  children,
}) => {
  const theme = useTheme();

  return (
    <Panel>
      <Panel.Header>
        <span>
          {name} ({memberCount})
        </span>

        <SLinkWrapper>
          <StyledLinkButton onClick={onAddMember}>+ Add Participant</StyledLinkButton>
        </SLinkWrapper>

        <IconButton onClick={onDelete} icon={{ name: 'trash-alt', color: theme.text.color.link }} />
      </Panel.Header>
      <div>{children}</div>
    </Panel>
  );
};
