import { numericUtil, UUID } from '@cmg/common';
import * as yup from 'yup';

import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import {
  BrokerValues,
  getInitialValues,
  getValidationSchema as getBrokerRowSchema,
} from '../../auto-save/table-row/InternalRetailDemandTableRow.model';
import { OrderBook_UpdateMyRetailMutationVariables } from './graphql';

const { sum } = numericUtil;

export type MyRetailDemandBulkSaveValues = {
  brokers: BrokerValues[];
};

export const getValidationSchema = (allBrokers: BrokerValues[]) =>
  yup.object({
    brokers: yup
      .array()
      .of(getBrokerRowSchema(allBrokers.map(({ broker }) => broker)))
      .min(1),
  });

export const createInitialValues = (
  dealers: readonly OrderBook_RetailBrokerDealerPartsFragment[]
): MyRetailDemandBulkSaveValues => {
  return { brokers: dealers.map(dealer => getInitialValues(dealer)) };
};

export const getUpdatedAmounts = (
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[],
  values: MyRetailDemandBulkSaveValues
) => {
  const totalInitialDemandShares = sum(...brokers.map(({ demand }) => demand.shareQuantity));
  const totalInitialDemandRoundLots = sum(...brokers.map(({ demand }) => demand.roundLots));
  const totalUpdatedDemandShares = sum(...values.brokers.map(({ demandShares }) => demandShares));
  const totalUpdatedDemandRoundLots = sum(
    ...values.brokers.map(({ demandRoundLots }) => demandRoundLots)
  );

  return {
    totalInitialDemandShares,
    totalInitialDemandRoundLots,
    totalUpdatedDemandShares,
    totalUpdatedDemandRoundLots,
  };
};

export const createUpdateMyRetailVariables = ({
  values,
  offeringId,
  trancheId,
  instrumentId,
}: {
  values: MyRetailDemandBulkSaveValues;
  offeringId: UUID;
  trancheId: UUID;
  instrumentId: UUID;
}): OrderBook_UpdateMyRetailMutationVariables => {
  return {
    offeringId,
    payload: {
      trancheId,
      instrumentId,
      brokerDealers: values.brokers.map(value => ({
        id: value.id,
        firmName: value.broker!.firmName,
        firmCmgEntityKey: value.broker!.firmCmgEntityKey,
        sellingConcessionPercentage: value.sellingConcessionPercentage,
        demand: { shareQuantity: value.demandShares as number, roundLots: value.demandRoundLots },
        retention: { shareQuantity: value.retentionShares, roundLots: value.retentionRoundLots },
      })),
    },
  };
};
