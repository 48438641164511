import { FlexContainer, PrimaryButton, SecondaryButton, ToastManager } from '@cmg/common';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../common/components/overlays/modal/Modal';
import { useSalesCredits_HouseAccountsQuery } from './graphql';
import { useUpdateSalesCreditsHouseAccountsMutation } from './hooks/useUpdateSalesCreditsHouseAccountsMutation';
import SalesCreditsHouseAccountsForm from './house-accounts-form/SalesCreditsHouseAccountsForm';
import {
  getInitialValues,
  SalesCreditsHouseAccountsFormValues,
} from './SalesCreditsHouseAccountsModal.model';
import { SModalContent, SText, StyledForm } from './SalesCreditsHouseAccountsModal.styles';

export type Props = {
  show: boolean;
  onHide: () => void;
};

const SalesCreditsHouseAccountsModal: React.FC<Props> = ({ show, onHide }) => {
  const { loading: isFetchingHouseAccounts, error, data } = useSalesCredits_HouseAccountsQuery();
  const [updateHouseAccounts, { loading: isUpdatingHouseAccounts }] =
    useUpdateSalesCreditsHouseAccountsMutation();

  const handleCloseModal = () => {
    onHide();
    formik.resetForm();
  };

  const handleSubmit = async (values: SalesCreditsHouseAccountsFormValues) => {
    try {
      await updateHouseAccounts({
        variables: {
          investorsFirmIds: values.houseAccounts.map(({ id }) => id),
        },
      });

      ToastManager.success('Successfully updated house accounts');
      onHide();
    } catch {
      ToastManager.error('Failed to update house accounts');
    }
  };

  const formik = useFormik<SalesCreditsHouseAccountsFormValues>({
    initialValues: getInitialValues(data),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={show} size="large" title="Internal House Account Settings">
      <SModalContent>
        {isFetchingHouseAccounts && <Loading />}

        {error && <ServerErrorsBanner error={error} />}

        {!isFetchingHouseAccounts && !error && data && (
          <FlexContainer direction="column" gap={16} fullHeight>
            <SText>
              Configure which of your CRM Firm names and Bank Investor Keys are your internal house
              accounts. These accounts will be available, for selection on all offerings, if you
              would like to attribute the sales credits to a house account.
            </SText>

            <FormikProvider value={formik}>
              <StyledForm>
                <FieldArray
                  name="houseAccounts"
                  render={({ remove, push, form: { values } }) => (
                    <SalesCreditsHouseAccountsForm
                      onAddHouseAccount={push}
                      onRemoveHouseAccount={remove}
                      houseAccounts={values.houseAccounts}
                    />
                  )}
                />
              </StyledForm>
            </FormikProvider>
          </FlexContainer>
        )}
      </SModalContent>
      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton
            testId="save-house-accounts-button"
            loading={isUpdatingHouseAccounts}
            disabled={!formik.dirty}
            onClick={formik.submitForm}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            testId="cancel-house-accounts-button"
            disabled={isUpdatingHouseAccounts}
            onClick={handleCloseModal}
          >
            Cancel
          </SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesCreditsHouseAccountsModal;
