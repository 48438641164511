import { getOperationName } from '@apollo/client/utilities';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { Notes_IndicationNoteDocument, useNotes_AssignIndicationNoteMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchNotes = getOperationName(Notes_IndicationNoteDocument);
  const refetchInstitutionalDemandSummaryGridQuery = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  );
  return [refetchNotes!, refetchInstitutionalDemandSummaryGridQuery!];
};

export const useAssignNoteMutation = (
  options: Parameters<typeof useNotes_AssignIndicationNoteMutation>[0]
) => {
  return useNotes_AssignIndicationNoteMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    ...options,
  });
};

export default useAssignNoteMutation;
