import { SortModel, useGridDatasource } from '@cmg/common';
import { useMemo } from 'react';

import {
  AttestationFormStatus,
  CertificateType,
  FirmCertificateSource,
  SortEnumType,
} from '../../../graphql/__generated__';
import { columnsDefinition } from '../components/certificate-grid/certificate-grid.model';
import {
  CertificateLibrary_FetchCertificatesDocument,
  CertificateLibrary_FetchCertificatesQuery,
  CertificateLibrary_FetchCertificatesQueryVariables,
} from '../graphql/__generated__';
import useCertificateFilters from './useCertificateFilters/useCertificateFilters';

export const ROWS_PER_PAGE = 100;

const getExpirationDateFilters = (expirationDays: string | null) => {
  if (!expirationDays) {
    return {};
  }

  const lte = new Date(new Date().setDate(new Date().getDate() + parseInt(expirationDays)));
  lte.setHours(23, 59, 59, 999);
  const gte = new Date();
  gte.setHours(0, 0, 0, 0);

  return {
    expirationDate: {
      lte: lte.toISOString(),
      gte: gte.toISOString(),
    },
  };
};

const getCertificateStatusFilters = (certificateStatuses: AttestationFormStatus[]) => {
  if (certificateStatuses.length === 0) {
    return {
      certificateStatus: {
        in: Object.values(AttestationFormStatus).filter(
          certStatus => certStatus !== AttestationFormStatus.NotOnFile
        ),
      },
    };
  }

  return {
    certificateStatus: {
      in: certificateStatuses.filter(certStatus => certStatus !== AttestationFormStatus.NotOnFile),
    },
  };
};

const getCertificatesFilters = ({
  expirationDays,
  certificateStatuses,
  certificateLibrary,
}: {
  expirationDays: string | null;
  certificateStatuses: AttestationFormStatus[];
  certificateLibrary: FirmCertificateSource;
}) => {
  const certificatesStatusesFiltersPart = {
    certificates: {
      some: {
        ...getCertificateStatusFilters(certificateStatuses),
        certificateType: {
          eq: CertificateType.Form51305131,
        },
      },
    },
  };

  const certificatesExpirationDaysFiltersPart = {
    certificates: {
      some: {
        ...getExpirationDateFilters(expirationDays),
      },
    },
  };

  const combinedCertificatesPart = {
    certificates: {
      some: {
        ...certificatesStatusesFiltersPart.certificates.some,
        ...certificatesExpirationDaysFiltersPart.certificates.some,
      },
    },
  };

  const notOnFileCertificatesFiltersPart = {
    certificates: {
      none: {
        certificateType: {
          eq: CertificateType.Form51305131,
        },
      },
    },
  };

  if (certificateLibrary === FirmCertificateSource.Cmg) {
    return combinedCertificatesPart;
  }

  if (
    !certificateStatuses.includes(AttestationFormStatus.NotOnFile) &&
    certificateStatuses.length !== 0
  ) {
    return combinedCertificatesPart;
  }

  if (expirationDays) {
    return combinedCertificatesPart;
  }

  return certificateStatuses.length === 1
    ? notOnFileCertificatesFiltersPart
    : { or: [combinedCertificatesPart, notOnFileCertificatesFiltersPart] };
};

export const mapFilters = ({
  search,
  expirationDays,
  certificateStatuses,
  certificateLibrary,
}: {
  search: string | null;
  expirationDays: string | null;
  certificateStatuses: AttestationFormStatus[];
  certificateLibrary: FirmCertificateSource;
}) => {
  let searchFilters = {};

  if (search) {
    searchFilters = {
      or: [
        {
          firmName: {
            contains: search,
          },
        },
        {
          bankInvestorKey: {
            contains: search,
          },
        },
      ],
    };
  }

  const certificatesFilters = getCertificatesFilters({
    expirationDays,
    certificateStatuses,
    certificateLibrary,
  });

  return search ? { and: [searchFilters, certificatesFilters] } : certificatesFilters;
};

// exported for testing purposes
export const mapSortingModelFn = (sortModel: SortModel<keyof typeof columnsDefinition>[]) =>
  sortModel.map(({ colId, sort }) => {
    if (colId === 'certificate51305131') {
      return {
        certificate51305131: {
          certificateStatus: sort === 'asc' ? SortEnumType.Asc : SortEnumType.Desc,
        },
      };
    }

    return {
      [colId]: sort === 'asc' ? SortEnumType.Asc : SortEnumType.Desc,
    };
  });

const useCertificateLibraryDataSource = () => {
  const { search, expirationDays, certificateStatuses, certificateLibrary } =
    useCertificateFilters();
  const filters = useMemo(
    () =>
      mapFilters({
        search,
        expirationDays,
        certificateStatuses: certificateStatuses ?? [],
        certificateLibrary: certificateLibrary ?? FirmCertificateSource.Crm,
      }),
    [search, expirationDays, certificateStatuses, certificateLibrary]
  );

  const { setGridRef, getRows } = useGridDatasource<
    keyof typeof columnsDefinition,
    CertificateLibrary_FetchCertificatesQuery,
    CertificateLibrary_FetchCertificatesQueryVariables,
    'firmsCertificates'
  >({
    queryName: 'firmsCertificates',
    queryDocument: CertificateLibrary_FetchCertificatesDocument,
    rowsPerPage: ROWS_PER_PAGE,
    filtersMode: 'controlled',
    filters,
    queryParams: {
      source: certificateLibrary ?? FirmCertificateSource.Crm,
    },
    mapSortingModelFn,
  });

  return {
    dataSource: {
      getRows,
    },
    setGridRef,
  };
};

export default useCertificateLibraryDataSource;
