import { addDays } from 'date-fns';
import React from 'react';

import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import DateRangeLabel from '../date-range-label/DateRangeLabel';
import { StyledIconButton } from './DateRangeControl.styles';

export type Props = {
  onPrevDate: () => void;
  onNextDate: () => void;
  currentDate: string;
  startDate: string;
  endDate: string;
};

const DateRangeControl: React.FC<Props> = ({
  onPrevDate,
  onNextDate,
  startDate,
  endDate,
  currentDate,
}) => {
  const isDisabledPrev = () => {
    return new Date(startDate) > addDays(new Date(currentDate), -1);
  };
  const isDisabledNext = () => {
    return new Date(endDate) < addDays(new Date(currentDate), 1);
  };

  // TODO return null if date is invalid

  return (
    <FlexLayout direction="row">
      <StyledIconButton
        icon={{ name: 'arrow-left' }}
        onClick={onPrevDate}
        disabled={isDisabledPrev()}
        testId="previous day button"
      />

      <DateRangeLabel date={currentDate} />

      <StyledIconButton
        icon={{ name: 'arrow-right' }}
        onClick={onNextDate}
        disabled={isDisabledNext()}
        testId="next day button"
      />
    </FlexLayout>
  );
};

export default DateRangeControl;
