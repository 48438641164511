import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getFeatureToggles } from '../../common/config/appSettings';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { useSupersededOfferingContext } from './contexts/SupersededOfferingContext';
import FeedbackRoute from './feedback/FeedbackRoute';
import SellSideOfferingDetailsRoute from './offering-details/SellSideOfferingDetailsRoute';
import offeringSidePanelBuySideRouteFactory from './offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from './OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from './offeringSidePanelSellSideRouteFactory';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from './order-book/hooks/useIdentifyOrderBookPersona';
import NotesRoute from './order-book/notes/NotesRoute';
import OrderBookSellSideRoute from './order-book/OrderBookSellSideRoute';
import RoadshowRoute from './roadshow/RoadshowRoute';

export type Props = {
  offeringId?: string;
};

const OfferingSidePanelSellSideRoutes: React.FC<Props> = ({ offeringId }) => {
  const { type } = useIdentifyOrderBookPersona({ offeringId });
  const { isRoadshowsOn } = getFeatureToggles();
  const { canUpdate: canUpdateInstitutionalIndication } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
      offeringId,
    });

  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  const { isObsoleteOffering } = useSupersededOfferingContext();

  return (
    <Switch>
      <Route
        path={offeringSidePanelSellSideRouteFactory.root.routePath}
        exact
        component={() => null}
      />

      {isObsoleteOffering && (
        <Redirect to={baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.root.getUrlPath()} />
      )}

      <Route
        path={offeringSidePanelSellSideRouteFactory.offeringDetails.routePath}
        component={SellSideOfferingDetailsRoute}
      />
      {isRoadshowsOn && (
        <Route
          path={offeringSidePanelSellSideRouteFactory.roadshow.routePath}
          component={RoadshowRoute}
        />
      )}
      <Route
        path={offeringSidePanelSellSideRouteFactory.orderBook.routePath}
        component={OrderBookSellSideRoute}
      />
      <Route
        path={offeringSidePanelSellSideRouteFactory.feedback.routePath}
        component={FeedbackRoute}
      />
      {type === OrderBookPersonaType.SYNDICATE && canUpdateInstitutionalIndication && (
        <Route
          path={offeringSidePanelSellSideRouteFactory.orderBookNotes.routePath}
          component={NotesRoute}
        />
      )}
    </Switch>
  );
};

export default OfferingSidePanelSellSideRoutes;
