import { Alert, IconButton } from '@cmg/common';
import React from 'react';

export type Props = { onDismiss: () => void };

const EditingOutOfBalanceAlert: React.FC<Props> = ({ onDismiss }) => {
  return (
    <Alert
      severity="error"
      withMargin={true}
      action={<IconButton icon={{ name: 'times', size: 'lg' }} onClick={onDismiss} />}
    >
      <Alert.Title>
        Sales Credits can't be saved until the total amount is balanced. Please allocate the
        remaining amount.
      </Alert.Title>
    </Alert>
  );
};

export default EditingOutOfBalanceAlert;
