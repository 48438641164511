import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateRegMWireMutation,
  SyndicateWires_RegMWiresDocument,
  useSyndicateWires_CreateRegMWireMutation,
} from '../graphql';

export type CreateRegMWireMutation = SyndicateWires_CreateRegMWireMutation;

export const useCreateRegMWireMutation = () => {
  return useSyndicateWires_CreateRegMWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_RegMWiresDocument];
  return getManyOperationNames(documentNodes);
}
