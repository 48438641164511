import { Table, TableCell, TableContainer, TableRow } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { VFC } from 'react';

import { OperationType } from '../../../common/components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { DefaultRecipientValues } from '../../../common/components/recipients-user-data-form-field/RecipientsUserDataFormFields';
import { SyndicateRecipientsUserDataFormFields } from '../../../common/components/recipients-user-data-form-field/SyndicateRecipientsUserDataFormFields';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { DesignationRecipientUserData, Values } from '../DesignationWireForm.model';
import { RecipientsTableHead } from './RecipientsTableHead';

export const defaultRecipientValues: DefaultRecipientValues<DesignationRecipientUserData> = {
  headerNote: null,
};

export type Props = Readonly<{
  readonly managers: SyndicateWireManager[];
  readonly operationType: OperationType;
}>;

export const RecipientsTable: VFC<Props> = ({ managers, operationType }) => {
  return (
    <TableContainer>
      <Table aria-label="Designation Wire Recipients Table">
        <RecipientsTableHead />
        <SyndicateRecipientsUserDataFormFields<DesignationRecipientUserData, Values>
          managers={managers}
          operationType={operationType}
          numColumns={2}
          defaultRecipientValues={defaultRecipientValues}
          renderRecipientRow={({ manager, managerIndex, onChange }) => {
            return (
              <TableRow key={manager.cmgEntityKey}>
                <TableCell>{manager.firmName}</TableCell>
                <TableCell>
                  <FormikTextField
                    multiline
                    rows={4}
                    aria-label={`${manager.firmName} - Header Note`}
                    required
                    showHelperTextInTooltip
                    name={`recipientsUserData.${managerIndex}.headerNote`}
                    onChange={headerNote =>
                      onChange({
                        headerNote: headerNote ? headerNote : defaultRecipientValues.headerNote,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};
