import { Icon } from '@cmg/common';
import { SizeProp as FontAwesomeSize } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

type LoaderSize = 'small' | 'medium' | 'large';

type Props = {
  size?: LoaderSize;
};

const sizeToFontAwesome: { [size in LoaderSize]: FontAwesomeSize } = {
  small: '1x',
  medium: '2x',
  large: '3x',
};

/**
 * An inline loader with adjustable size
 */
export const LoadingIndicator: React.FC<Props> = ({ size = 'medium' }) => {
  return <Icon title="loading" name="spinner-third" spin size={sizeToFontAwesome[size]} />;
};
