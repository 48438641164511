import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import * as yup from 'yup';

import {
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
} from '../../../../../../../../graphql';
import { MAX_53_BIT_INT } from '../../../../../../../../types/graphql/constants';
import { InterestLevelFormValues } from '../../../../../../components/interest-levels-wrapper/InterestLevelsWrapper.model';
import { FirmLevelDemand_MdlIndicationPartsFragment } from '../../graphql';

/**
 * Types
 */

export type FirmLevelDemandFormValues<UnitType, PricingType, LimitType> = {
  interestLevels: InterestLevelFormValues<UnitType, PricingType, LimitType>[];
};

export type FirmLevelDemandValues<UnitType, PricingType, LimitType> = {
  interestLevels: Omit<InterestLevelFormValues<UnitType, PricingType, LimitType>, 'quantity'>[];
};

/**
 * Initial values
 */

export const createInitialValues = (
  indications?: readonly FirmLevelDemand_MdlIndicationPartsFragment[]
): FirmLevelDemandFormValues<
  MdlIndicationUnitType,
  MdlIndicationPricingType,
  MdlIndicationLimitType
> => {
  return {
    interestLevels: Array.isArray(indications)
      ? orderBy(indications, ['pricingType'], ['desc']).map(
          ({
            unitType,
            dollars,
            percentage,
            shares,
            pricingType,
            limitPrice,
            realDemandDollars,
            realDemandPercentage,
            realDemandShares,
            limitType,
            limitPercentage,
          }) => ({
            unitType,
            quantity: null,
            dollars,
            percentage,
            shares,
            pricingType,
            limitPrice,
            realDemandDollars,
            realDemandPercentage,
            realDemandShares,
            limitType,
            limitPercentage,
          })
        )
      : [],
  };
};

/**
 * Validation rules
 */

export const interestLevelValidationSchema = yup.object().shape({
  pricingType: yup
    .string()
    .typeError('Please select a Pricing Type')
    .required('Please select a Pricing Type')
    .oneOf(Object.values(MdlIndicationPricingType)),
  unitType: yup
    .string()
    .typeError('Please select a Unit Type')
    .required('Please select a Unit Type')
    .oneOf(Object.values(MdlIndicationUnitType)),
  realDemandDollars: yup.number().when('unitType', {
    is: unitType => unitType === MdlIndicationUnitType.Dollars,
    then: schema =>
      schema.typeError('Please enter a Dollars value').required('Please enter a Dollars value'),
    otherwise: schema => schema.nullable(),
  }),
  realDemandShares: yup.mixed<number>().when('unitType', {
    is: unitType => unitType === MdlIndicationUnitType.Shares,
    then: schema =>
      schema
        .test('test-sharesInterestLevel', 'Invalid Shares value', function checkValue(value) {
          return !isNil(value) && value >= 0 && value <= MAX_53_BIT_INT;
        })
        .required('Invalid Shares value'),
    otherwise: schema => schema.nullable(),
  }),
  realDemandPercentage: yup.number().when('unitType', {
    is: unitType => unitType === MdlIndicationUnitType.Percentage,
    then: schema =>
      schema
        .typeError('Please enter a Percentage value')
        .required('Please enter a Percentage value')
        .max(1, 'Percentage must be less or equal to 100'),
    otherwise: schema => schema.nullable(),
  }),
  limitType: yup.string().when('pricingType', {
    is: pricingType => pricingType === MdlIndicationPricingType.Limit,
    then: schema =>
      schema
        .typeError('Please select a Limit Type')
        .required('Please select a Limit Type')
        .oneOf(
          Object.values(MdlIndicationLimitType).filter(
            limitType => limitType !== MdlIndicationLimitType.Null
          ),
          'Please select a valid Limit Type value'
        ),
    otherwise: schema => schema.nullable(),
  }),
  limitPrice: yup.number().when('limitType', {
    is: limitType => limitType && limitType === MdlIndicationLimitType.Price,
    then: schema =>
      schema
        .typeError('Please enter a Limit Price value')
        .required('Please enter a Limit Price value'),
    otherwise: schema => schema.nullable(),
  }),
  limitPercentage: yup.number().when('limitType', {
    is: limitType =>
      limitType &&
      limitType !== MdlIndicationLimitType.Price &&
      limitType !== MdlIndicationLimitType.Null,
    then: schema =>
      schema
        .typeError('Please enter a Limit Percentage value')
        .required('Please enter a Limit Percentage value')
        .min(0, 'Limit Percentage must be greater than 0')
        .max(1, 'Limit Percentage must be less or equal to 100'),
    otherwise: schema => schema.nullable(),
  }),
});

export const firmLevelDemandFormValidationSchema = yup.object().shape({
  interestLevels: yup.array().of(interestLevelValidationSchema),
});
