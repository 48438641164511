import { ISODate, timeUtil } from '@cmg/common';
import styled from 'styled-components/macro';

export const SHeaderContainer = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SPricingDateValue = styled.span`
  margin-left: 5px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export type Props = {
  pricingDate: ISODate;
};

export const PricingDateHeader: React.FC<Props> = ({ pricingDate }) => (
  <SHeaderContainer>
    Expected Pricing Date:
    <SPricingDateValue>
      {pricingDate ? timeUtil.formatAsDisplayDayAndDate(pricingDate) : 'TBD'}
    </SPricingDateValue>
  </SHeaderContainer>
);
