import { UUID } from '@cmg/common';

import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../../hooks/useIdentifyOrderBookPersona';
import { ProspectusDeliveryModel } from './ProspectusDelivery.model';
import { useProspectusDeliverySellSideModel } from './sell-side/useProspectusDeliverySellSideModel';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly cmgEntityKey: string | undefined;
}>;

export const prospectusDeliveryModelDefaults: ProspectusDeliveryModel = {
  canRead: false,
  loading: false,
  error: undefined,
  prospectusDeliveryHistory: null,
  isSyndicatePersona: false,
};

export const useProspectusDeliveryModel = ({
  offeringId,
  cmgEntityKey,
}: Props): ProspectusDeliveryModel => {
  const { type: personaType } = useIdentifyOrderBookPersona({ offeringId });

  const sellSideModel = useProspectusDeliverySellSideModel({
    personaType,
    offeringId,
    cmgEntityKey,
  });

  switch (personaType) {
    case OrderBookPersonaType.SYNDICATE:
    case OrderBookPersonaType.SALES_AND_TRADING:
      return sellSideModel;
    default:
      return prospectusDeliveryModelDefaults;
  }
};
