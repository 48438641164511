import { timeUtil } from '@cmg/common';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ExpandMoreIcon,
  FiberManualRecordIcon,
  Grid,
  Stack,
  Typography,
} from '@cmg/design-system';
import startCase from 'lodash/startCase';
import React from 'react';

import { getDeliveryInstrumentLabel } from '../../../../../../../../common/components/form/delivery-instrument-select-field/DeliveryInstrumentSelectField';
import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
  SecurityType,
} from '../../../../../../../../graphql';
import {
  UpdateHistory_CoveredIndicationVersionPartsFragment,
  UpdateHistory_IndicationVersionPartsFragment,
  UpdateHistory_MyIndicationVersionPartsFragment,
} from '../../graphql';
import InterestLevelGridRows from '../interest-level-grid/InterestLevelGridRows';

export type Props = {
  item:
    | UpdateHistory_IndicationVersionPartsFragment
    | UpdateHistory_CoveredIndicationVersionPartsFragment
    | UpdateHistory_MyIndicationVersionPartsFragment;
  pricingCurrencyCode?: string | null;
  handleOnRowExpand: (id: string) => void;
  expanded: boolean;
  securityType?: SecurityType;
};

const getStatusColor = (status: IndicationStatus) => {
  switch (status) {
    case IndicationStatus.Active:
      return 'success';
    case IndicationStatus.Cancelled:
      return 'error';
    default:
      return 'disabled';
  }
};

// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
const IndicationVersionDetail: React.FC<Props> = ({
  item,
  handleOnRowExpand,
  expanded,
  pricingCurrencyCode,
  securityType,
}) => {
  const hasPassed = item?.type === InstitutionalIndicationOrderType.Pass;
  const cancelled = item?.status === IndicationStatus.Cancelled;

  const tranche = item?.tranche;
  const deliveryInstrument = tranche?.deliveryInstruments.find(
    instrument => instrument.id === item.instrumentId
  );
  const currency = tranche?.demandCurrencies
    ?.find(demandCurrency => demandCurrency === item.currencyCode)
    ?.toUpperCase();

  const isSellSideIndicationVersion =
    item?.__typename === 'InstitutionalIndicationVersion' ||
    item?.__typename === 'CoveredInstitutionalIndicationVersion';

  const investorName = isSellSideIndicationVersion
    ? item?.investorInformation?.bankInvestorName || item?.investorInformation?.cmgEntityName
    : 'Your Firm';

  const modifiedByFirmName = item?.isBuySideRevision
    ? investorName
    : item?.auditInfo.modifiedByFirmName;

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      sx={{ boxShadow: 'none' }}
      data-test-id="update-history-indication-version-detail-row"
    >
      <AccordionSummary
        expandIcon={
          !hasPassed && !cancelled ? (
            <ExpandMoreIcon onClick={() => handleOnRowExpand(item.id)} />
          ) : (
            ''
          )
        }
      >
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row">
            <Stack sx={{ flex: '0 0 450px' }}>
              <Typography variant="highlight1" data-testid="update-at-message">
                {`Update at ${
                  item.auditInfo.modifiedAt
                    ? timeUtil.formatAsDisplayDateTime(item.auditInfo.modifiedAt)
                    : '-'
                } by ${modifiedByFirmName}`}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ flex: '0 0 60px', alignItems: 'center' }}>
              <FiberManualRecordIcon
                sx={{ fontSize: '12px', marginRight: '6px' }}
                color={getStatusColor(item.status)}
              />
              {startCase(item?.status?.toLowerCase())}
            </Stack>
          </Stack>
          {hasPassed || cancelled ? (
            <Box sx={{ padding: '16px 16px 0px' }} data-testid="cancel-pass-message">
              {cancelled ? (
                <Typography>
                  {investorName} has <b> cancelled </b> their indication for this offering.
                </Typography>
              ) : (
                <Typography>
                  {investorName} has submitted a <b> pass </b> for this offering.
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: theme => theme.spacing(2),
                padding: theme => `0 ${theme.spacing(2)}`,
              }}
            >
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={4}>
                  <Typography color="gray"> Quantity </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="gray"> Unit </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="gray"> Order </Typography>
                </Grid>
                <InterestLevelGridRows
                  item={item}
                  currency={currency!}
                  pricingCurrencyCode={pricingCurrencyCode}
                />
              </Grid>
            </Box>
          )}
        </Stack>
      </AccordionSummary>
      {!hasPassed && !cancelled && (
        <AccordionDetails sx={{ paddingTop: '0px' }}>
          <Box sx={{ paddingRight: '40px', paddingLeft: theme => theme.spacing(2) }}>
            <Grid container rowSpacing={1} columnSpacing={3} data-testid="tranche-grid">
              <Grid item xs={4}>
                <Typography color="gray">Tranche</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="gray"> Currency </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="gray"> Instrument </Typography>
              </Grid>
              <Grid item xs={4}>
                {item.tranche?.name}
              </Grid>
              <Grid item xs={4}>
                {currency}
              </Grid>
              <Grid item xs={4}>
                {deliveryInstrument &&
                  getDeliveryInstrumentLabel({
                    deliveryInstrument,
                    securityType,
                  })}
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default IndicationVersionDetail;
