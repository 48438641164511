import { Icon, MultiSelectField, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  indicationStatusOptions,
  institutionalIndicationOrderTypeOptions,
} from '../../../../../constants';
import { InstitutionalDemandFilterArgs } from '../../types';
import { SField, SIconWrapper, StyledForm } from './InstitutionalDemandFilters.styles';

type OwnProps = {
  /**
   * The currently active filters
   */
  activeFilters: InstitutionalDemandFilterArgs;
  /**
   * Callback triggered when filter fields change.
   */
  onChange: (values: InstitutionalDemandFilterArgs) => void;
};
export type Props = OwnProps & FormikProps<InstitutionalDemandFilterArgs>;

/**
 * Renders filter inputs for the Institutional Demand Grid
 */
export const InstitutionalDemandFiltersComponent: React.FC<Props> = ({
  handleSubmit: handleFormikSubmit,
}) => {
  const debouncedHandleSubmit = useDebouncedCallback(() => handleFormikSubmit(), 400);

  return (
    <StyledForm>
      <SField>
        <TextInputField
          name="searchText"
          onChange={debouncedHandleSubmit}
          placeholder="Filter by Investor Name..."
          prefix={
            <SIconWrapper>
              <Icon name="search" />
            </SIconWrapper>
          }
          testId={xcSelectors.institutionalDemandInvestorSearchFilter.testId}
        />
      </SField>
      <SField>
        <MultiSelectField
          name="status"
          onChange={() => handleFormikSubmit()}
          placeholder="Filter by Status..."
          options={indicationStatusOptions}
          testId={xcSelectors.institutionalDemandIndicationStatusFilter.testId}
        />
      </SField>
      <SField>
        <MultiSelectField
          name="type"
          onChange={() => handleFormikSubmit()}
          placeholder="Filter by Type..."
          options={institutionalIndicationOrderTypeOptions}
          testId={xcSelectors.institutionalDemandIndicationTypeFilter.testId}
        />
      </SField>
    </StyledForm>
  );
};

const FormikInstitutionalDemandFilters = withFormik<OwnProps, InstitutionalDemandFilterArgs>({
  mapPropsToValues: ({ activeFilters }) => {
    return {
      searchText: activeFilters.searchText || '',
      status: activeFilters.status || undefined,
      type: activeFilters.type || undefined,
    };
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onChange(values);
  },
})(InstitutionalDemandFiltersComponent);

export default React.memo(FormikInstitutionalDemandFilters);
