import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import { useIsOfferingAuthor } from '../../hooks/useIsOfferingAuthor';

type AccessCheckResult = {
  canManage: boolean;
  canRead: boolean;
};

/**
 * Checks accessibility for Regulatory Filings
 */
export function useCheckRegulatoryFilingsAccess(offeringId: UUID | undefined): AccessCheckResult {
  const isAuthor = useIsOfferingAuthor(offeringId);
  const hasRead = useCheckPermissions([permissionsByEntity.RegulatoryFiling.READ]);
  const hasManage = useCheckPermissions([permissionsByEntity.RegulatoryFiling.FULL]);

  return {
    canRead: isAuthor && hasRead,
    canManage: isAuthor && hasManage,
  };
}
