import { Box, Stack, Typography } from '@cmg/design-system';
import React from 'react';
import { Link } from 'react-router-dom';

import { AttestationFormStatus, ProspectusDeliveryStatus } from '../../../../../../../graphql';
import { ProspectusDeliveryStatusLabel } from '../../../../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';
import {
  AttestationFormStatusDisplayName,
  isAttestationStatusValid,
} from '../../../../../attestation/Attestation.model';

export type Props = {
  viewDetailsUrl: string;
  attestationStatus: AttestationFormStatus;
  prospectusDeliveryStatus: ProspectusDeliveryStatus | undefined;
  isComplianceAlert: boolean;
  isUnAssignedBDAlert: boolean;
  isAcknowledgementRevisedWarningAlert: boolean;
};

export const RowAlertTooltipContent: React.FC<Props> = ({
  viewDetailsUrl,
  attestationStatus,
  prospectusDeliveryStatus,
  isComplianceAlert,
  isUnAssignedBDAlert,
  isAcknowledgementRevisedWarningAlert,
}) => {
  return (
    <Box
      sx={{
        width: 200,
      }}
    >
      {isComplianceAlert && (
        <Box>
          <Stack>
            <Typography variant="tooltip" fontWeight="bold">
              Compliance <Link to={viewDetailsUrl}>View details</Link>
            </Typography>
            {!isAttestationStatusValid(attestationStatus) && (
              <Typography variant="tooltip">
                5130/31 {AttestationFormStatusDisplayName[attestationStatus]}
              </Typography>
            )}
            {prospectusDeliveryStatus &&
              prospectusDeliveryStatus === ProspectusDeliveryStatus.Failed && (
                <Typography variant="tooltip">
                  Prospectus {ProspectusDeliveryStatusLabel[prospectusDeliveryStatus]}
                </Typography>
              )}
          </Stack>
        </Box>
      )}

      {isUnAssignedBDAlert && (
        <Box sx={{ mt: isComplianceAlert ? 2 : 0 }}>
          <Stack>
            <Typography variant="tooltip" fontWeight="bold">
              B&D Agent
            </Typography>
            <Typography variant="tooltip">B&D Agent needs to be assigned.</Typography>
          </Stack>
        </Box>
      )}

      {isAcknowledgementRevisedWarningAlert && (
        <Box sx={{ mt: isUnAssignedBDAlert || isComplianceAlert ? 2 : 0 }}>
          <Stack>
            <Typography variant="tooltip" fontWeight="bold">
              IOI Acknowledgements
            </Typography>
            <Typography variant="tooltip">
              The indication has been revised since your acknowledgement.
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
