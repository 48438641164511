import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { WireTemplateType } from '../../../../../graphql';
import {
  createUseDefaultNonSyndicateManagers,
  createUseDefaultNonSyndicateNextRecipients,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { createUseDefaultOutdatedNonSyndicateManagersList } from '../../common/hooks/createDefaultUseOutdatedNonSyndicateManagersList';
import { createUseDefaultIsNonSyndicateWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsNonSyndicateWireManagerListOutdated';
import {
  SyndicateWires_NonSyndicateFreeFormWirePartsFragment,
  SyndicateWires_NonSyndicateFreeFormWireValidationPartsFragment,
  useSyndicateWires_NonSyndicateFreeFormWireDetailsQuery,
  useSyndicateWires_NonSyndicateFreeFormWirePreviewQuery,
  useSyndicateWires_NonSyndicateFreeFormWiresQuery,
  useSyndicateWires_NonSyndicateFreeFormWireValidationQuery,
} from './graphql';
import { useCreateNonSyndicateFreeFormWireMutation } from './hooks/useCreateNonSyndicateFreeFormWireMutation';
import { useDeleteNonSyndicateFreeFormWireMutation } from './hooks/useDeleteNonSyndicateFreeFormWireMutation';
import { useSendNonSyndicateFreeFormWireMutation } from './hooks/useSendNonSyndicateFreeFormWireMutation';
import { useUpdateNonSyndicateFreeFormWireMutation } from './hooks/useUpdateNonSyndicateFreeFormWireMutation';
import { NonSyndicateFreeFormWireForm } from './NonSyndicateFreeFormWireForm';
import { validationSchema, Values } from './NonSyndicateFreeFormWireForm.model';

const wireTypeName = 'Free Form';

export const wireTypeConfigNonSyndicateFreeForm = createWireTypeConfig<
  Values,
  SyndicateWires_NonSyndicateFreeFormWirePartsFragment,
  SyndicateWires_NonSyndicateFreeFormWireValidationPartsFragment,
  typeof routeFactory.nonSyndicateWiresFreeForm
>({
  wireTypeName,
  wireTypeRoute: routeFactory.nonSyndicateWiresFreeForm,
  wireTemplateType: WireTemplateType.NonSyndicateMemberFreeForm,
  CreateOrUpdateForm: NonSyndicateFreeFormWireForm,
  formValidation: validationSchema,
  useManagers: createUseDefaultNonSyndicateManagers(),
  useNextRecipients: createUseDefaultNonSyndicateNextRecipients(),
  useIsWireManagerListOutdated: createUseDefaultIsNonSyndicateWireManagerListOutdated(),
  useOutdatedManagersList: createUseDefaultOutdatedNonSyndicateManagersList(),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateFreeFormWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicateFreeFormWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_NonSyndicateFreeFormWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
      });

    return { data: data?.nonSyndicateFreeFormWireValidation, isLoading: loading, error, refetch };
  },

  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateFreeFormWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.nonSyndicateFreeFormWireDetails,
        stage: data.syndicateWires.publishedOrPrivateOffering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicateFreeFormWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicateFreeFormWireList,
      isLoading: loading,
      error,
    };
  },
  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateNonSyndicateFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: values,
          },
        });

        if (result.data?.createNonSyndicateFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createNonSyndicateFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateNonSyndicateFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: values },
        });

        if (result.data?.updateNonSyndicateFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateNonSyndicateFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteNonSyndicateFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId }) => {
        const result = await deleteWire({ variables: { offeringId, syndicateWireId } });

        if (result.data?.deleteNonSyndicateFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendNonSyndicateFreeFormWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendNonSyndicateFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendNonSyndicateFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire }) => {
    const data = React.useMemo<Values>(() => {
      return {
        body: wire?.body ?? '',
        header: wire?.header ?? '',
      };
    }, [wire?.body, wire?.header]);

    return { isLoading: false, data };
  },
});
