import { Icon, Popover, SecondaryButton } from '@cmg/common';
import React from 'react';

import { SBadge, SBadgeIconWrapper } from './InstitutionalDemandGridDataRefreshButton.styles';

export type Props = {
  changeCounter: Number;
  handleOnViewUpdates?: () => void;
};

const InstitutionalDemandOrdersAndAllocationsExportButton: React.FC<Props> = ({
  changeCounter,
  handleOnViewUpdates,
}) => {
  return (
    <Popover variant="TOOLTIP" placement="topRight" content="View recent Order Book updates">
      <SBadgeIconWrapper>
        <SecondaryButton onClick={handleOnViewUpdates}>
          <Icon variant="light" name="bell" fixedWidth />
          {changeCounter > 0 && <SBadge> {changeCounter <= 999 ? changeCounter : '999+'} </SBadge>}
        </SecondaryButton>
      </SBadgeIconWrapper>
    </Popover>
  );
};

export default InstitutionalDemandOrdersAndAllocationsExportButton;
