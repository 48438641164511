import {
  SyndicateWires_UpdateManagerBdWireMutation,
  useSyndicateWires_UpdateManagerBdWireMutation,
} from '../graphql';

export type UpdateManagerBdWireMutation = SyndicateWires_UpdateManagerBdWireMutation;

export const useUpdateManagerBdWireMutation = () => {
  return useSyndicateWires_UpdateManagerBdWireMutation();
};
