import {
  AttestationFormStatus,
  IndicationStatus,
  InstitutionalIndicationOrderType,
  OfferingType,
  ProspectusDeliveryStatus,
} from '../../../../../../../graphql';
import { InstitutionalDemand_ComplianceTableQuery } from './graphql';

export enum ComplianceTableType {
  CERTIFICATES = 'Certificates',
  PROSPECTUS = 'Prospectus',
}

export const getSubmittedByManagerName = ({
  managers,
  investors,
  submittedByCmgEntityKey,
}: {
  managers: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['syndicate']['managers'];
  investors: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications'][number]['investorInformation'][];
  submittedByCmgEntityKey: string;
}): string => {
  const submittedByManager = managers.find(m => m.cmgEntityKey === submittedByCmgEntityKey);
  const submittedByInvestor = investors.find(i => i.cmgEntityKey === submittedByCmgEntityKey);
  return (
    submittedByManager?.firmNameAbbreviation ??
    submittedByManager?.firmName ??
    submittedByInvestor?.bankInvestorName ??
    submittedByInvestor?.cmgEntityName ??
    ''
  );
};

export const getBillingAndDeliveryManagerName = ({
  managers,
  billingAndDeliveryAgentCmgEntityKey,
}: {
  managers: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['syndicate']['managers'];
  billingAndDeliveryAgentCmgEntityKey: string | null | undefined;
}): string => {
  const billingAndDeliveryManager = managers.find(
    ({ cmgEntityKey }) => cmgEntityKey === billingAndDeliveryAgentCmgEntityKey
  );
  return (
    billingAndDeliveryManager?.firmNameAbbreviation ?? billingAndDeliveryManager?.firmName ?? ''
  );
};

export const getIndicationsWithComplianceCertificatesWarning = ({
  offeringType,
  indications,
}: {
  offeringType: OfferingType | undefined;
  indications:
    | InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications']
    | undefined;
}) => {
  if (offeringType !== OfferingType.Ipo) {
    return [];
  }
  return (indications ?? []).filter(
    i =>
      i.status === IndicationStatus.Active &&
      i.type !== InstitutionalIndicationOrderType.Pass &&
      (i.attestationStatus.status === AttestationFormStatus.Expired ||
        i.attestationStatus.status === AttestationFormStatus.NotOnFile)
  );
};

export const getIndicationsWithComplianceProspectusWarning = ({
  indications,
}: {
  indications:
    | InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications']
    | undefined;
}) => {
  if (!indications) {
    return [];
  }
  return indications.filter(({ investorProspectusDeliveryStatus }) => {
    const { overallDeliveryStatus = ProspectusDeliveryStatus.NotSent } =
      investorProspectusDeliveryStatus ?? {};

    return [
      ProspectusDeliveryStatus.NotSent,
      ProspectusDeliveryStatus.Failed,
      ProspectusDeliveryStatus.InProgress,
    ].includes(overallDeliveryStatus);
  });
};

export const investorNameSortFn = (
  {
    investorInformation: investorInformation1,
  }: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications'][number],
  {
    investorInformation: investorInformation2,
  }: InstitutionalDemand_ComplianceTableQuery['publishedOffering']['orderBook']['institutionalIndications'][number]
) => {
  const name1 = investorInformation1.bankInvestorName || investorInformation1.cmgEntityName;
  const name2 = investorInformation2.bankInvestorName || investorInformation2.cmgEntityName;
  if (!name1) {
    return 1;
  }
  if (!name2) {
    return -1;
  }
  return name1.localeCompare(name2);
};
