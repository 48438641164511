import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_SellingGroupInvitationWiresDocument,
  SyndicateWires_SendSellingGroupInvitationWireMutation,
  useSyndicateWires_SendSellingGroupInvitationWireMutation,
} from '../graphql';

export type SendSellingGroupInvitationWireMutation =
  SyndicateWires_SendSellingGroupInvitationWireMutation;

export const useSendSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_SendSellingGroupInvitationWireMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
