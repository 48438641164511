import { UUID } from '@cmg/common';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../common/util/routeFactory';
import { useRoadshows_RoadshowDetailsQuery } from '../../common/graphql';
import RoadshowSideNav from './common/roadshow-side-nav/RoadshowSideNav';
import CompanyDetails from './company-details/CompanyDetails';
import DealTeamDetails from './deal-team-details/DealTeamDetails';
import RoadshowDetailsHeader from './header/RoadshowDetailsHeader';
import RoadshowDetails from './roadshow-details/RoadshowDetails';
import { RoadshowStepIds } from './RoadshowConfigurationRoute.model';

export type Props = RouteComponentProps<{ roadshowId: UUID }> & {
  activeStepId: RoadshowStepIds;
};

const RoadshowConfigurationRoute: React.FC<Props> = ({ activeStepId, history, match }) => {
  const roadshowId = match.params.roadshowId;

  const { data, loading, error } = useRoadshows_RoadshowDetailsQuery({
    variables: { roadshowId },
  });

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const { roadshow } = data || {};

  if (!roadshow) {
    return <Redirect to={routeFactory.roadshowsList.getUrlPath()} />;
  }

  return (
    <FlexLayout direction="column">
      <RoadshowDetailsHeader roadshow={roadshow} />
      <FlexLayout direction="row">
        <RoadshowSideNav roadshowId={roadshow.id} />
        {activeStepId === RoadshowStepIds.ROADSHOW_DETAILS && (
          <RoadshowDetails roadshow={roadshow} />
        )}

        {activeStepId === RoadshowStepIds.COMPANY_DETAILS && <CompanyDetails roadshow={roadshow} />}

        {activeStepId === RoadshowStepIds.DEAL_TEAM && <DealTeamDetails roadshowId={roadshow.id} />}
      </FlexLayout>
    </FlexLayout>
  );
};

export default RoadshowConfigurationRoute;
