/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.BillingAndDeliveryAgentBatchUpdateInput;
  }>;

export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation =
  {
    readonly __typename?: 'Mutation';
    readonly updateInstitutionalIndicationBillingAndDeliveryAgentForIndications: ReadonlyArray<string>;
  };

export const InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsDocument =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'mutation',
        name: {
          kind: 'Name',
          value: 'InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndications',
        },
        variableDefinitions: [
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
          {
            kind: 'VariableDefinition',
            variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'BillingAndDeliveryAgentBatchUpdateInput' },
              },
            },
          },
        ],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'updateInstitutionalIndicationBillingAndDeliveryAgentForIndications',
              },
              arguments: [
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'offeringId' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
                },
                {
                  kind: 'Argument',
                  name: { kind: 'Name', value: 'input' },
                  value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                },
              ],
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationFn =
  Apollo.MutationFunction<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationVariables
  >;

/**
 * __useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridUpdateIndicationBillingAndDeliveryAgentForIndicationsMutation, { data, loading, error }] = useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationVariables
  >(InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsDocument, options);
}
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationHookResult =
  ReturnType<
    typeof useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation
  >;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation>;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutationVariables
  >;
