import React from 'react';

import { WireTypeRoute } from '../../common/components/wire-type-route/WireTypeRoute';
import { WireRouteProps } from '../../common/components/wire-type-route/WireTypeRoute.model';
import { WireTypeConfigContextProvider } from '../../common/context/WireTypeConfigContext';
import { wireTypeConfigRegM } from './RegMWire.model';

export const RegMWireRoute: React.FC<WireRouteProps> = props => {
  return (
    <WireTypeConfigContextProvider value={wireTypeConfigRegM}>
      <WireTypeRoute {...props} />
    </WireTypeConfigContextProvider>
  );
};
