import { Icon, IconNameParts } from '@cmg/common';
import React from 'react';

import { SStatusIcon, SStatusIconWrapper, SStep, SText } from './SideNavStep.styles';

/**
 * A step in SideNav
 * @param {function} onClick  clicking on this icon or text
 * @param {string} status display icons and appropriate color for specific status
 * @param {boolean} active display active state
 * @param {boolean} disabled disable step
 * @param {string} icon override icon set by status
 * @param {string} testId to add a testId if necessary
 */
export type Props = {
  onClick?: () => void;
  status?: 'complete' | 'warning' | 'invalid';
  active?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  testId?: string;
};

export const SideNavStep: React.FC<Props> = ({
  onClick,
  status,
  active,
  disabled,
  icon,
  children,
  testId,
}) => {
  let iconName: IconNameParts['name'] = 'circle';

  if (status === 'warning') {
    iconName = 'exclamation-circle';
  } else if (status === 'invalid') {
    iconName = 'times-circle';
  }

  return (
    <SStep onClick={!disabled ? onClick : undefined} disabled={disabled} data-test-id={testId}>
      <SStatusIconWrapper>
        <SStatusIcon active={active} status={status}>
          {icon ? (
            icon
          ) : (
            <React.Fragment>
              {status && status !== 'complete' ? (
                <Icon name={iconName} />
              ) : (
                <Icon name="circle" variant="solid" />
              )}
            </React.Fragment>
          )}
        </SStatusIcon>
      </SStatusIconWrapper>
      <SText active={active} disabled={disabled}>
        {children}
      </SText>
    </SStep>
  );
};

export default SideNavStep;
