import {
  CurrencyInputField,
  DangerButton,
  Icon,
  NumericInputField,
  numericUtil,
  PercentInputField,
  SelectField,
} from '@cmg/common';
import { useFormikContext } from 'formik';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import React from 'react';

import {
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
  OfferingType,
} from '../../../../../../../../graphql';
import {
  FormValues,
  InterestLevelProps,
} from '../../../../../../components/interest-levels-wrapper/InterestLevelsWrapper.model';
import LimitPriceInput from '../../../../../../components/limit-price-input/LimitPriceInput';
import { pricingTypeOptions, unitTypeOptions, unitTypeToFieldName } from './InterestLevel.model';
import {
  SColumn,
  SDeleteColumn,
  SIndicationLevelRow,
  SLimitPriceColumn,
} from './InterestLevel.styles';

type Props<MdlIndicationUnitType, MdlIndicationPricingType, MdlIndicationLimitType> =
  InterestLevelProps<MdlIndicationUnitType, MdlIndicationPricingType, MdlIndicationLimitType> & {
    offeringType: OfferingType;
  };

/**
 * Display interest levels in view mode
 */
const InterestLevel: React.FC<
  Props<MdlIndicationUnitType, MdlIndicationPricingType, MdlIndicationLimitType>
> = ({ isEditing = false, isSubmitting = false, index, replace, remove, offeringType }) => {
  const { values } =
    useFormikContext<
      FormValues<MdlIndicationUnitType, MdlIndicationPricingType, MdlIndicationLimitType>
    >();
  const interestLevel = values.interestLevels[index];
  const interestLevels = values.interestLevels;

  const hasMarketPricingType = find(values.interestLevels, [
    'pricingType',
    MdlIndicationPricingType.Market,
  ]);

  const baseName = `interestLevels[${index}]`;

  const handleChangeUnitType = (unitType: MdlIndicationUnitType) => {
    if (unitType !== interestLevel.unitType) {
      interestLevels.forEach((level, levelIndex) => {
        if (unitType !== level.unitType) {
          const quantity = !interestLevel.unitType ? interestLevel.quantity : null;
          replace(levelIndex, {
            ...level,
            dollars: null,
            percentage: null,
            shares: null,
            [unitTypeToFieldName(unitType)]:
              quantity && !interestLevel.unitType && unitType === MdlIndicationUnitType.Percentage
                ? quantity / 100
                : quantity,
            unitType,
          });
        }
      });
    }
  };

  const handleChangePricingType = (pricingType: MdlIndicationPricingType) => {
    if (pricingType !== interestLevel.pricingType) {
      const [firstInterestLevel] = interestLevels;
      const unitType = index > 0 ? firstInterestLevel.unitType : interestLevel.unitType;

      replace(index, {
        ...interestLevel,
        limitPrice: null,
        limitType:
          pricingType === MdlIndicationPricingType.Market ? MdlIndicationLimitType.Null : null,
        pricingType,
        unitType,
      });
    }
  };

  const handleChangeLimitType = (limitType: MdlIndicationLimitType) => {
    if (limitType !== interestLevel.limitType) {
      replace(index, {
        ...interestLevel,
        limitPrice: null,
        limitPercentage: null,
        limitType,
      });
    }
  };

  const formatDemandQuantity = () => {
    switch (interestLevel.unitType) {
      case MdlIndicationUnitType.Dollars:
        return numericUtil.formatCurrency(interestLevel.dollars);
      case MdlIndicationUnitType.Percentage:
        return numericUtil.formatPercents(interestLevel.percentage, 0);
      case MdlIndicationUnitType.Shares:
        return numericUtil.formatNumber(interestLevel.shares, 0);
      default:
        return null;
    }
  };

  const renderDemandQuantityInput = () => {
    const baseProps = {
      disabled: isSubmitting,
      fullWidth: true,
      required: true,
    };

    switch (interestLevel.unitType) {
      case MdlIndicationUnitType.Dollars:
        return <CurrencyInputField {...baseProps} name={`${baseName}.dollars`} />;
      case MdlIndicationUnitType.Percentage:
        return <PercentInputField {...baseProps} name={`${baseName}.percentage`} precision={0} />;
      case MdlIndicationUnitType.Shares:
        return <NumericInputField {...baseProps} name={`${baseName}.shares`} precision={0} />;
      default:
        return <NumericInputField {...baseProps} name={`${baseName}.quantity`} precision={0} />;
    }
  };

  const renderUnitTypeSelect = () => {
    return (
      <SelectField
        name={`${baseName}.unitType`}
        placeholder="Select..."
        disabled={isSubmitting || index > 0}
        options={unitTypeOptions}
        fullWidth
        required
        isClearable={false}
        onChange={handleChangeUnitType}
      />
    );
  };

  const removeInterestLevel = () => {
    remove(index);
  };

  return (
    <SIndicationLevelRow>
      <SColumn align="left">
        {isEditing ? renderDemandQuantityInput() : formatDemandQuantity()}
      </SColumn>
      <SColumn align="left">
        {isEditing ? renderUnitTypeSelect() : capitalize(interestLevel.unitType!)}
      </SColumn>
      <SColumn align="left">
        {isEditing ? (
          <SelectField
            name={`${baseName}.pricingType`}
            placeholder="Select..."
            disabled={isSubmitting}
            options={pricingTypeOptions.filter(option => {
              // Hide the "Market" order type option if an interest level already has market
              // only one market order is allowed.
              return (
                !hasMarketPricingType ||
                (!!hasMarketPricingType &&
                  interestLevel.pricingType === MdlIndicationPricingType.Market) ||
                option.value !== MdlIndicationPricingType.Market
              );
            })}
            fullWidth
            required
            isClearable={false}
            onChange={handleChangePricingType}
          />
        ) : (
          capitalize(interestLevel.pricingType!)
        )}
      </SColumn>
      <SLimitPriceColumn align="left">
        <LimitPriceInput
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          interestLevel={interestLevel}
          offeringType={offeringType}
          baseName={baseName}
          handleChangeLimitType={handleChangeLimitType}
        />
      </SLimitPriceColumn>
      {isEditing && (
        <SDeleteColumn align="right">
          <DangerButton onClick={removeInterestLevel}>
            <Icon name="trash-alt" />
          </DangerButton>
        </SDeleteColumn>
      )}
    </SIndicationLevelRow>
  );
};

export default InterestLevel;
