import { sortCaseInsensitive } from '../../../../../../../common/util/sortCaseInsensitive';
import { InstitutionalIndicationOrderType } from '../../../../../../../graphql';
import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import { InstitutionalDemand } from '../../types';

export type DupesGridRow = {
  id: string;
  demandMax: number;
  demandAtMarket: number;
  coveringManagerCmgEntityKeys?: readonly string[];
  coveringManagerNames: SyndicateManager[];
  type: InstitutionalIndicationOrderType;
  investorInformation: InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number]['investorInformation'];
  submittedByCmgEntityKey: string;
  submittedByCmgEntityName: string | null;
  interestLevels: InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'][number]['interestLevels'];
  pricingCurrencyCode?: string;
  currencyCode?: string | null;
};

export type SyndicateManager = {
  cmgEntityKey: string | null;
  firmName: string | null;
  firmNameAbbreviation?: string | null;
};

export type DupesModalContext = {
  id: string;
  name: string;
};

export type DupesGridContext = {
  rows: DupesGridRow[];
};

const getCoveringManagerFirmNames = (
  row: InstitutionalDemand,
  managers: readonly SyndicateManager[],
  oidcUserCmgEntityKey: string | null
): SyndicateManager[] => {
  const coveringManagers =
    row.coveringManagerCmgEntityKeys
      ?.map(cmgEntityKey => managers.find(manager => manager.cmgEntityKey === cmgEntityKey))
      .sort((a, b) => {
        // Return logged in user's bank first everytime.
        if (a?.cmgEntityKey === oidcUserCmgEntityKey) {
          return -1;
        }
        if (b?.cmgEntityKey === oidcUserCmgEntityKey) {
          return 1;
        }

        return sortCaseInsensitive(a?.firmName!, b?.firmName!);
      }) || [];

  return coveringManagers
    .map(manager => {
      return {
        firmNameAbbreviation: manager?.firmNameAbbreviation ?? null,
        firmName: manager?.firmName ?? null,
        cmgEntityKey: manager?.cmgEntityKey ?? null,
      };
    })
    .filter(m => m.firmName !== null)
    .map(m => m!);
};

export const getDupesContext = (
  indications: InstitutionalDemand[],
  managers: readonly SyndicateManager[],
  indicationIds: string[],
  oidcUserCmgEntityKey: string | null,
  pricingCurrencyCode?: string
) => {
  const investorInformationList: SyndicateManager[] = indications.map(
    ({ investorInformation }) => ({
      cmgEntityKey: investorInformation.cmgEntityKey!,
      firmName: investorInformation.bankInvestorName
        ? investorInformation.bankInvestorName
        : investorInformation.cmgEntityName!,
      firmNameAbbreviation: investorInformation.firmNameAbbreviation,
    })
  );

  const allManagers = investorInformationList.concat(managers);
  return {
    rows: indications
      .filter(i => indicationIds.includes(i.id))
      .map(row => {
        return {
          ...row,
          submittedByCmgEntityName:
            allManagers.find(manager => manager.cmgEntityKey === row?.submittedByCmgEntityKey)
              ?.firmName ?? null,
          coveringManagerNames: getCoveringManagerFirmNames(row, managers, oidcUserCmgEntityKey),
          pricingCurrencyCode,
        };
      }),
  };
};
