import { FlexContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../common/components/overlays/modal/Modal';

export type Props = { show: boolean; onHide: () => void; onEdit: () => void };

const SalesCreditsOutOfBalanceModal: React.FC<Props> = ({ show, onHide, onEdit }) => {
  const handleStartEditing = () => {
    onEdit();
    onHide();
  };

  return (
    <Modal show={show} title="Unable to complete action">
      <Modal.Content>
        Sales Credits must be in balance to update any row status. Please Edit Sales Credits to
        allocate the remaining amount
      </Modal.Content>
      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton onClick={handleStartEditing}>Edit Sales Credits</PrimaryButton>
          <SecondaryButton onClick={onHide}>Close</SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesCreditsOutOfBalanceModal;
