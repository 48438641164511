import React from 'react';

import { SContainer } from './ListItemIcon.styles';

export type Props = {};

const ListItemIcon: React.FC<Props> = ({ children }) => {
  return <SContainer>{children}</SContainer>;
};

export default ListItemIcon;
