import { dateTimeUtil } from '@cmg/common';

import type { DemandGridServerColDef } from '../../types';
import { getValueFormatter } from '../columns.model';

/**
 * Exported for testing purposes only
 */

/**
 * Exported for testing purposes only
 */
export const valueFormatter = getValueFormatter<string>({
  gridRow: ({ value }) => (value ? dateTimeUtil.getDisplayValueForDateAndTime(value) : '-'),
});

const modifiedAtDolDef: DemandGridServerColDef<string> = {
  type: 'date',
  field: 'auditInfo.modifiedAt',
  headerName: 'Modified On',
  filter: 'agDateColumnFilter',
  valueFormatter,
};

export default modifiedAtDolDef;
