import { Popover } from '@cmg/common';
import React from 'react';

import {
  SColumn,
  SDeleteColumn,
  SIndicationLevelsHeader,
  SLimitConfigurationColumn,
  SRequired,
  StyledInfoIcon,
} from './InterestLevelsTableHeader.styles';

export type Props = {
  isEditing?: boolean;
  displayLimitConfig: boolean;
};

const InterestLevelsTableHeader: React.FC<Props> = ({ isEditing = false, displayLimitConfig }) => {
  return (
    <SIndicationLevelsHeader>
      <SColumn align="left">
        <SRequired showIndicator={isEditing}>Demand Quantity</SRequired>
      </SColumn>
      <SColumn align="left">
        <SRequired showIndicator={isEditing}>Demand Unit</SRequired>
      </SColumn>
      <SColumn align="left">
        <SRequired showIndicator={isEditing}>Order Type</SRequired>
      </SColumn>
      <SLimitConfigurationColumn align="left">
        {displayLimitConfig && (
          <SRequired showIndicator={isEditing}>
            Limit Configuration
            <Popover
              variant="TOOLTIP"
              trigger="hover"
              content={<span>Limit Price expressed in pricing currency</span>}
            >
              <span>
                <StyledInfoIcon name="info-circle" variant="solid" size="1x" />
              </span>
            </Popover>
          </SRequired>
        )}
      </SLimitConfigurationColumn>
      {isEditing && <SDeleteColumn align="right" />}
    </SIndicationLevelsHeader>
  );
};

export default InterestLevelsTableHeader;
