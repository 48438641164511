// @ts-nocheck
/* eslint-disable */
// prettier-ignore
import { RelationshipType } from '../shared-types';
import { RelationshipType as InvestorRelationshipType } from '../shared-types';
import { RolodexRecordStatus } from '../shared-types';
import { RolodexRecordStatus as InvestorRelationshipRecordStatus } from '../shared-types';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A 9-digit string representing Cmg entity key identifier. For example: 000000123 */
  CmgEntityKey: any;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: string;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /**
   * Represents a time zone - a mapping between UTC and local time.
   * A time zone maps UTC instants to local times - or, equivalently, to the offset from UTC at any particular instant.
   */
  DateTimeZone: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** Represents an instant on the global timeline, with nanosecond resolution. */
  Instant: string;
  /** LocalDate is an immutable struct representing a date within the calendar, with no reference to a particular time zone or time of day. */
  LocalDate: string;
  /** LocalTime is an immutable struct representing a time of day, with no reference to a particular calendar, time zone or date. */
  LocalTime: string;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  UUID: string;
};

export type Accounting = {
  readonly __typename?: 'Accounting';
  readonly averagePriceOfBuybackShares?: Maybe<Scalars['Decimal']>;
  readonly baseDealShares: Scalars['Long'];
  readonly baseGrossSpreadPercent?: Maybe<Scalars['Decimal']>;
  readonly baseGrossSpreadValue?: Maybe<Scalars['Decimal']>;
  readonly baseManagementFee?: Maybe<Scalars['Decimal']>;
  readonly baseSellingConcession?: Maybe<Scalars['Decimal']>;
  readonly baseUnderwritingFee?: Maybe<Scalars['Decimal']>;
  readonly dealRelatedExpenses?: Maybe<Scalars['Decimal']>;
  readonly excludedShares: Scalars['Long'];
  readonly id: Scalars['ID'];
  readonly incentiveFeePercent?: Maybe<Scalars['Decimal']>;
  readonly incentiveFeeValue?: Maybe<Scalars['Decimal']>;
  readonly incentiveManagementFee?: Maybe<Scalars['Decimal']>;
  readonly incentiveSellingConcession?: Maybe<Scalars['Decimal']>;
  readonly incentiveUnderwritingFee?: Maybe<Scalars['Decimal']>;
  readonly managerExpenseReimbursement?: Maybe<Scalars['Decimal']>;
  readonly managers: ReadonlyArray<AccountingManager>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly offeringPrice: Scalars['Decimal'];
  readonly overallotmentExercised?: Maybe<Scalars['Long']>;
  readonly potAllocated: Scalars['Long'];
  readonly retailRetention?: Maybe<Scalars['Long']>;
  readonly stabilizationGainLoss?: Maybe<Scalars['Decimal']>;
  readonly totalSharesRefreshed?: Maybe<Scalars['Long']>;
  readonly totalSharesRepurchased?: Maybe<Scalars['Long']>;
};

export type AccountingManager = {
  readonly __typename?: 'AccountingManager';
  readonly baseUnderwritingPercentage?: Maybe<Scalars['Decimal']>;
  readonly baseUnderwritingShares?: Maybe<Scalars['Long']>;
  readonly dealExpenses?: Maybe<Scalars['Decimal']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly designatedShares?: Maybe<Scalars['Long']>;
  readonly designationFees?: Maybe<Scalars['Decimal']>;
  readonly finalSettlement?: Maybe<Scalars['Decimal']>;
  readonly id: Scalars['ID'];
  readonly incentiveFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly managementFees?: Maybe<Scalars['Decimal']>;
  readonly managementFeesPercentage?: Maybe<Scalars['Decimal']>;
  readonly managerCmgEntityKey: Scalars['String'];
  readonly managerExpenseReimbursement?: Maybe<Scalars['Decimal']>;
  readonly managerFirmName: Scalars['String'];
  readonly managerRole: ExpensesManagerRole;
  /** Display name for ManagerRole */
  readonly managerRoleDisplayName?: Maybe<Scalars['String']>;
  readonly manualAdjustment?: Maybe<Scalars['Decimal']>;
  readonly netDealFees?: Maybe<Scalars['Decimal']>;
  readonly overallotmentUnderwritingShares?: Maybe<Scalars['Long']>;
  readonly potEconomics?: Maybe<Scalars['Decimal']>;
  readonly potEconomicsWithOverwrites?: Maybe<Scalars['Decimal']>;
  readonly retailRetention?: Maybe<Scalars['Decimal']>;
  readonly retailRetentionFees?: Maybe<Scalars['Decimal']>;
  readonly stabilizationGainLoss?: Maybe<Scalars['Decimal']>;
  readonly underwritingFees?: Maybe<Scalars['Decimal']>;
  readonly underwritingShares?: Maybe<Scalars['Long']>;
};

export type AccountingNotes = {
  readonly __typename?: 'AccountingNotes';
  readonly notes: Scalars['String'];
};

export type AccountTrait = {
  readonly __typename?: 'AccountTrait';
  readonly accountCount: Scalars['Int'];
  readonly code: Scalars['String'];
  readonly description: Scalars['String'];
  readonly name: Scalars['String'];
  readonly permissions: ReadonlyArray<Scalars['String']>;
};

/**
 * Actions are performed against Resources.
 * Warning: If breaking changes are made to this enum, then we will need to write a custom migration to update all entitlements stored in the db.
 */
export enum Action {
  Acknowledge = 'ACKNOWLEDGE',
  Create = 'CREATE',
  Delete = 'DELETE',
  LimitedRead = 'LIMITED_READ',
  Manage = 'MANAGE',
  Read = 'READ',
  Receive = 'RECEIVE',
  Release = 'RELEASE',
  Update = 'UPDATE',
}

/**
 * Configures an Action in the context of a Resource.
 * Includes the ability to ensure that an action may only be configured for a manager if the manager has the proper role and/or responsibility.
 */
export type ActionConfiguration = {
  readonly __typename?: 'ActionConfiguration';
  readonly action: Action;
  readonly description: Scalars['String'];
  readonly managerResponsibilityRestriction?: Maybe<ManagerResponsibility>;
  readonly managerRoleRestriction?: Maybe<ManagerRole>;
};

export enum ActivityType {
  Iopb = 'IOPB',
  Sb = 'SB',
  Sct = 'SCT',
}

export type AdHocParticipantInput = {
  readonly email: Scalars['String'];
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type Advisor = {
  readonly __typename?: 'Advisor';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly recordStatus: RecordStatus;
  readonly role: AdvisorRole;
};

export enum AdvisorRole {
  IssuerAuditor = 'ISSUER_AUDITOR',
  IssuerCounsel = 'ISSUER_COUNSEL',
  Trustee = 'TRUSTEE',
  UnderwriterCounsel = 'UNDERWRITER_COUNSEL',
}

export type Affiliate = {
  readonly __typename?: 'Affiliate';
  readonly crdNumber?: Maybe<Scalars['Long']>;
  readonly id: Scalars['UUID'];
  readonly mpid?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type AffiliateMember = {
  readonly __typename?: 'AffiliateMember';
  readonly firmCrdNumber?: Maybe<Scalars['String']>;
  readonly firmMpid?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
};

export enum AlertSeverity {
  Error = 'ERROR',
  None = 'NONE',
  Warning = 'WARNING',
}

export type AlertSeverityOperationFilterInput = {
  readonly eq?: InputMaybe<AlertSeverity>;
  readonly in?: InputMaybe<ReadonlyArray<AlertSeverity>>;
  readonly neq?: InputMaybe<AlertSeverity>;
  readonly nin?: InputMaybe<ReadonlyArray<AlertSeverity>>;
};

export type Allocation = {
  readonly __typename?: 'Allocation';
  readonly allocationSetId: Scalars['UUID'];
  readonly shares?: Maybe<Scalars['Long']>;
};

export enum AllocationAcknowledgement {
  Acknowledged = 'ACKNOWLEDGED',
  Rejected = 'REJECTED',
  Unacknowledged = 'UNACKNOWLEDGED',
}

export enum AllocationAcknowledgementNewStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Rejected = 'REJECTED',
}

export type AllocationAcknowledgementOperationFilterInput = {
  readonly eq?: InputMaybe<AllocationAcknowledgement>;
  readonly in?: InputMaybe<ReadonlyArray<AllocationAcknowledgement>>;
  readonly neq?: InputMaybe<AllocationAcknowledgement>;
  readonly nin?: InputMaybe<ReadonlyArray<AllocationAcknowledgement>>;
};

export type AllocationChangeDetail = {
  readonly __typename?: 'AllocationChangeDetail';
  readonly new: AllocationRevisionDetail;
  readonly old?: Maybe<AllocationRevisionDetail>;
};

export type AllocationDtoFilterInput = {
  readonly allocationSetId?: InputMaybe<UuidOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<AllocationDtoFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<AllocationDtoFilterInput>>;
  readonly shares?: InputMaybe<LongOperationFilterInput>;
};

export type AllocationDtoSortInput = {
  readonly allocationSetId: Scalars['UUID'];
  readonly shares?: InputMaybe<SortEnumType>;
};

export type AllocationRevisionDetail = {
  readonly __typename?: 'AllocationRevisionDetail';
  readonly allocationVersion: Scalars['Long'];
  readonly indicationId: Scalars['UUID'];
  readonly shares?: Maybe<Scalars['Long']>;
};

export type AllocationSet = {
  readonly __typename?: 'AllocationSet';
  readonly auditInfo: AuditInformation;
  readonly author: FirmInfo;
  readonly dealLayout: OfferingDealLayout;
  readonly edition: RevisionEdition;
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly isFinal: Scalars['Boolean'];
  readonly isReleased: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly releaseTimestamp?: Maybe<Scalars['Instant']>;
  readonly sharedWithManagerCmgEntityKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sharedWithManagers?: Maybe<ReadonlyArray<Maybe<AllocationSetShareInfo>>>;
  readonly shareTimestamp?: Maybe<Scalars['Instant']>;
};

export type AllocationSetDetail = {
  readonly __typename?: 'AllocationSetDetail';
  readonly id: Scalars['UUID'];
  readonly institutionalTarget?: Maybe<Scalars['Long']>;
  readonly isDraft: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly offeringSize?: Maybe<Scalars['Long']>;
  readonly version: Scalars['Long'];
};

export type AllocationSetDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<AllocationSetDtoFilterInput>>;
  readonly auditInfo?: InputMaybe<AuditInformationFilterInput>;
  readonly author?: InputMaybe<FirmInfoFilterInput>;
  readonly dealLayout?: InputMaybe<DealLayoutReadDtoFilterInput>;
  readonly edition?: InputMaybe<RevisionEditionFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isDefault?: InputMaybe<BooleanOperationFilterInput>;
  readonly isFinal?: InputMaybe<BooleanOperationFilterInput>;
  readonly isReleased?: InputMaybe<BooleanOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<AllocationSetDtoFilterInput>>;
  readonly releaseTimestamp?: InputMaybe<InstantOperationFilterInput>;
  readonly sharedWithManagerCmgEntityKeys?: InputMaybe<ListStringOperationFilterInput>;
  readonly shareTimestamp?: InputMaybe<InstantOperationFilterInput>;
};

export type AllocationSetDtoSortInput = {
  readonly auditInfo?: InputMaybe<AuditInformationSortInput>;
  readonly author?: InputMaybe<FirmInfoSortInput>;
  readonly dealLayout?: InputMaybe<DealLayoutReadDtoSortInput>;
  readonly edition?: InputMaybe<RevisionEditionSortInput>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly isDefault?: InputMaybe<SortEnumType>;
  readonly isFinal?: InputMaybe<SortEnumType>;
  readonly isReleased?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly releaseTimestamp?: InputMaybe<SortEnumType>;
  readonly shareTimestamp?: InputMaybe<SortEnumType>;
};

export type AllocationSetManagerShareInfo = {
  readonly __typename?: 'AllocationSetManagerShareInfo';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly timestamp: Scalars['Instant'];
};

export type AllocationSetShareInfo = {
  readonly __typename?: 'AllocationSetShareInfo';
  readonly isActive: Scalars['Boolean'];
  readonly manager: FirmInfo;
  readonly sharedAt: Scalars['Instant'];
};

export type AllocationsInput = {
  readonly allocation: CreateAllocationInput;
  readonly indicationId: Scalars['UUID'];
};

export type AllocationTotalRow = {
  readonly __typename?: 'AllocationTotalRow';
  readonly allocationSetId: Scalars['UUID'];
  readonly shares: Scalars['Long'];
  readonly unAllocatedShares?: Maybe<Scalars['Long']>;
};

export type AllocationUpdateInput = {
  /** The number of shares being allocated. */
  readonly shareQuantity?: InputMaybe<Scalars['Long']>;
};

export type AllOfferingListFiltersInput = {
  readonly offeringIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly stage?: InputMaybe<OfferingStage>;
};

export type AssignContactInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
};

export type AssignDealLayoutInput = {
  readonly institutionalTarget?: InputMaybe<Scalars['Long']>;
  readonly offeringSize?: InputMaybe<Scalars['Long']>;
};

export type AssignInvestorInstitutionalIndicationInput = {
  readonly currencyCode?: InputMaybe<Scalars['String']>;
  readonly instrumentId?: InputMaybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevelInput>;
  readonly trancheId: Scalars['UUID'];
};

export type AssignMyInstitutionalIndicationResponseUnion = MyInstitutionalIndication | ServiceError;

export type AssignNoteInput = {
  readonly text: Scalars['String'];
  readonly type: IndicationNoteType;
};

export type AssignRetailDemandInput = {
  readonly instrumentId: Scalars['UUID'];
  readonly roundLots?: InputMaybe<Scalars['Int']>;
  readonly shareQuantity: Scalars['Long'];
  readonly trancheId: Scalars['UUID'];
};

export type AssignRetailRetentionInput = {
  readonly shareQuantity: Scalars['Long'];
};

export type Attestation = {
  readonly __typename?: 'Attestation';
  readonly attestationForm?: Maybe<AttestationForm>;
  readonly cmgEntityKey: Scalars['String'];
  readonly formStatus: AttestationFormStatus;
};

export type AttestationForm = {
  readonly __typename?: 'AttestationForm';
  readonly addressLine1: Scalars['String'];
  readonly bankRequesterCmgEntityKey?: Maybe<Scalars['String']>;
  readonly cityStateZip: Scalars['String'];
  readonly effectiveDate: Scalars['LocalDate'];
  readonly envelopeId?: Maybe<Scalars['UUID']>;
  readonly expirationDate?: Maybe<Scalars['LocalDate']>;
  readonly firmIdentifier?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['UUID'];
  readonly isBlanketForm: Scalars['Boolean'];
  readonly primaryFirmCmgEntityKey?: Maybe<Scalars['String']>;
  readonly signatoryDate: Scalars['LocalDate'];
  readonly signers: ReadonlyArray<Signer>;
};

export enum AttestationFormStatus {
  Blanket = 'BLANKET',
  Expired = 'EXPIRED',
  NotOnFile = 'NOT_ON_FILE',
  Partial = 'PARTIAL',
}

export type AttestationStatus = {
  readonly __typename?: 'AttestationStatus';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmId: Scalars['UUID'];
  readonly status: AttestationFormStatus;
};

/**
 * Audit information tracked by the CMG platform. This data structure will contain a combination of system level
 * and user level information, where available.
 */
export type AuditInfo = {
  readonly __typename?: 'AuditInfo';
  readonly createdAt: Scalars['Instant'];
  readonly createdByFirmKey?: Maybe<Scalars['String']>;
  readonly createdByFirmName?: Maybe<Scalars['String']>;
  readonly createdByUserEmail?: Maybe<Scalars['String']>;
  readonly firmKeys: Scalars['String'];
  readonly modifiedAt: Scalars['Instant'];
  readonly modifiedByFirmKey?: Maybe<Scalars['String']>;
  readonly modifiedByFirmName?: Maybe<Scalars['String']>;
  readonly modifiedByUserEmail?: Maybe<Scalars['String']>;
};

export type AuditInformation = {
  readonly __typename?: 'AuditInformation';
  readonly createdAt: Scalars['Instant'];
  readonly createdBy?: Maybe<FirmInfo>;
  readonly modifiedAt: Scalars['Instant'];
  readonly modifiedBy?: Maybe<FirmInfo>;
};

export type AuditInformationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<AuditInformationFilterInput>>;
  readonly createdAt?: InputMaybe<InstantOperationFilterInput>;
  readonly createdBy?: InputMaybe<FirmInfoFilterInput>;
  readonly modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  readonly modifiedBy?: InputMaybe<FirmInfoFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<AuditInformationFilterInput>>;
};

export type AuditInformationSortInput = {
  readonly createdAt?: InputMaybe<SortEnumType>;
  readonly createdBy?: InputMaybe<FirmInfoSortInput>;
  readonly modifiedAt?: InputMaybe<SortEnumType>;
  readonly modifiedBy?: InputMaybe<FirmInfoSortInput>;
};

export type BaseRegulatoryFiling = {
  readonly __typename?: 'BaseRegulatoryFiling';
  readonly id: Scalars['ID'];
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
};

export type BasicPublishedOfferingProfile = {
  readonly __typename?: 'BasicPublishedOfferingProfile';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly disclaimers: DisclaimersProfile;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  /** Returns an Investor Relationship on an On-Platform Offering */
  readonly investorRelationship: InvestorRelationship;
  readonly issuerBusinessDescription?: Maybe<Scalars['String']>;
  readonly issuerName: Scalars['String'];
  readonly issuerSector?: Maybe<Sector>;
  /** Display name for IssuerSector */
  readonly issuerSectorDisplayName?: Maybe<Scalars['String']>;
  readonly marketing: Marketing;
  readonly myInstitutionalIndication?: Maybe<MyInstitutionalIndication>;
  readonly offerPrice?: Maybe<Scalars['Decimal']>;
  readonly orderBook: OrderBook;
  readonly orderEntryProfile: OfferingOrderEntryProfile;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly prospectusDocuments: ReadonlyArray<ProspectusDocumentProfile>;
  readonly publicOrderBook: PublicOrderBook;
  readonly roadshowSchedules: ReadonlyArray<RoadshowScheduleProfile>;
  readonly securityType: SecurityType;
  readonly settlementDate?: Maybe<Scalars['LocalDate']>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  readonly syndicate: SyndicateProfile;
  readonly syndicateEntitlements: Entitlements;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly terms: TermsProfile;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
};

export type BasicPublishedOfferingProfileSyndicateEntitlementsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type BillingAndDeliveryAgent = {
  readonly __typename?: 'BillingAndDeliveryAgent';
  readonly auditInfo: AuditInfo;
  readonly indicationId: Scalars['UUID'];
  readonly managerCmgEntityKey: Scalars['String'];
  readonly offeringId: Scalars['UUID'];
  readonly version: Scalars['String'];
};

export type BillingAndDeliveryAgentBatchUpdateInput = {
  readonly billingAndDeliveryAgentKey?: InputMaybe<Scalars['String']>;
  readonly indicationIds: ReadonlyArray<Scalars['UUID']>;
};

export type BillingAndDeliveryAgentUpdateInput = {
  readonly managerCmgEntityKey: Scalars['String'];
};

export type BillingAndDeliveryPayment = {
  readonly __typename?: 'BillingAndDeliveryPayment';
  readonly billAndDeliveryShares: Scalars['Long'];
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly payment: Scalars['Decimal'];
};

export type BndAgentBatchUpdateInput = {
  readonly bndAgentKey?: InputMaybe<Scalars['CmgEntityKey']>;
  readonly indicationId: Scalars['UUID'];
};

export type BndAgentUpdateInput = {
  readonly bndAgentKey?: InputMaybe<Scalars['CmgEntityKey']>;
};

export type BooleanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Boolean']>;
  readonly neq?: InputMaybe<Scalars['Boolean']>;
};

/** Used to group entitlements and explicitly define the owner of each Resource */
export enum BoundedContext {
  OfferingSetup = 'OFFERING_SETUP',
  OrderBook = 'ORDER_BOOK',
  Roadshows = 'ROADSHOWS',
  SyndicateWires = 'SYNDICATE_WIRES',
}

export type BrokerDealerMyRetailFees = {
  readonly __typename?: 'BrokerDealerMyRetailFees';
  readonly brokerCmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly payment?: Maybe<Scalars['Decimal']>;
};

export type BulkAssignBndAgentPayload = {
  readonly __typename?: 'BulkAssignBndAgentPayload';
  readonly updatedIndicationIds: ReadonlyArray<Scalars['UUID']>;
};

export enum BuySellType {
  Buy = 'BUY',
  Sell = 'SELL',
}

export type CalculatedPercentageDistributions = {
  readonly __typename?: 'CalculatedPercentageDistributions';
  readonly managementFeeDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly sellingConcessionDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<PercentagePerCmgEntity>;
};

/** Supply distributions to be used in calculation versus the stored distributions on the offering. */
export type CalculateFeesDistributionsInput = {
  /** Specify an override value for the incentive fee percentage distribution. */
  readonly incentiveFeePercentagesOverride?: InputMaybe<ReadonlyArray<PercentagePerCmgEntityInput>>;
  /** Specify an override value for the underwriting fee percentage distribution. */
  readonly underwritingFeePercentagesOverride?: InputMaybe<
    ReadonlyArray<PercentagePerCmgEntityInput>
  >;
};

export type CalculateShareDistributions = {
  readonly __typename?: 'CalculateShareDistributions';
  readonly overallotmentExercisedShareDistribution: ReadonlyArray<SharesPerCmgEntity>;
  readonly underwritingBaseShareDistribution: ReadonlyArray<SharesPerCmgEntity>;
  readonly underwritingTotalSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
};

export type CalculateSharesDistributionsInput = {
  /** Specify an override value for the underwriting fee total. */
  readonly underwritingFeeTotalDistributionOverride?: InputMaybe<
    ReadonlyArray<PercentagePerCmgEntityInput>
  >;
};

export type Certificate = {
  readonly __typename?: 'Certificate';
  readonly certificateId: Scalars['UUID'];
  readonly certificateStatus: AttestationFormStatus;
  readonly certificateType: CertificateType;
  readonly expirationDate?: Maybe<Scalars['Date']>;
  readonly signatoryDate: Scalars['Date'];
};

export type CertificateReadDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<CertificateReadDtoFilterInput>>;
  readonly certificateId?: InputMaybe<UuidOperationFilterInput>;
  readonly certificateStatus?: InputMaybe<CertificateStatusOperationFilterInput>;
  readonly certificateType?: InputMaybe<CertificateTypeOperationFilterInput>;
  readonly expirationDate?: InputMaybe<DateOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CertificateReadDtoFilterInput>>;
  readonly signatoryDate?: InputMaybe<DateOperationFilterInput>;
};

export type CertificateReadDtoSort51305131Input = {
  readonly certificateId?: InputMaybe<SortEnumType>;
  readonly certificateStatus?: InputMaybe<SortEnumType>;
  readonly certificateType?: InputMaybe<SortEnumType>;
  readonly expirationDate?: InputMaybe<SortEnumType>;
  readonly signatoryDate?: InputMaybe<SortEnumType>;
};

export type CertificateStatusOperationFilterInput = {
  readonly eq?: InputMaybe<AttestationFormStatus>;
  readonly in?: InputMaybe<ReadonlyArray<AttestationFormStatus>>;
  readonly neq?: InputMaybe<AttestationFormStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<AttestationFormStatus>>;
};

export enum CertificateType {
  Form51305131 = 'FORM51305131',
}

export type CertificateTypeOperationFilterInput = {
  readonly eq?: InputMaybe<CertificateType>;
  readonly in?: InputMaybe<ReadonlyArray<CertificateType>>;
  readonly neq?: InputMaybe<CertificateType>;
  readonly nin?: InputMaybe<ReadonlyArray<CertificateType>>;
};

export type City = {
  readonly __typename?: 'City';
  readonly name: Scalars['String'];
  readonly timeZone: Scalars['DateTimeZone'];
};

export type CityInfo = {
  readonly __typename?: 'CityInfo';
  /** Format ["Seattle", "WA", "USA"] */
  readonly cityAddressParts: ReadonlyArray<Scalars['String']>;
  /** Format "Seattle, WA, USA" */
  readonly fullCityAddress: Scalars['String'];
};

export type CmgEntityKeyFilterInput = {
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly or?: InputMaybe<ReadonlyArray<CmgEntityKeyFilterInput>>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  readonly __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** Indicates the start of the requested page. This is either the request 'skip' argument or a server configured default if no argument is provided. */
  readonly offset: Scalars['Int'];
  /** Indicates the size of the requested page. This is either the request 'take' argument or a server configured default if no argument is provided. */
  readonly pageSize: Scalars['Int'];
};

export type Company = {
  readonly __typename?: 'Company';
  readonly address?: Maybe<CompanyAddress>;
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly industryTypes: ReadonlyArray<FirmIndustryType>;
  readonly name: Scalars['String'];
  readonly website?: Maybe<Scalars['String']>;
};

export type CompanyAddress = {
  readonly __typename?: 'CompanyAddress';
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly faxNumber?: Maybe<Scalars['String']>;
  readonly isOverride: Scalars['Boolean'];
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly postalCode?: Maybe<Scalars['String']>;
  readonly recordStatus: RolodexRecordStatus;
  readonly state?: Maybe<Scalars['String']>;
  readonly streetLine1?: Maybe<Scalars['String']>;
  readonly streetLine2?: Maybe<Scalars['String']>;
  readonly streetLine3?: Maybe<Scalars['String']>;
  readonly type: FirmAddressType;
};

export type CompanyFilterInput = {
  readonly searchText?: InputMaybe<Scalars['String']>;
};

export type CompanySearch = {
  readonly __typename?: 'CompanySearch';
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly factSetFirmId?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CompanySearchPagedList = {
  readonly __typename?: 'CompanySearchPagedList';
  readonly data: ReadonlyArray<CompanySearch>;
  readonly pagination: PaginationData;
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly source: ContactSource;
};

export type ContactCollection = {
  readonly __typename?: 'ContactCollection';
  readonly contacts: ReadonlyArray<Contact>;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly version: Scalars['String'];
};

/** The source of a contact, if it is submitted by sell-side or came directly from the buy-side's XC account. */
export enum ContactSource {
  BuySide = 'BUY_SIDE',
  SellSide = 'SELL_SIDE',
}

export type CoveredIndicationChanged = {
  readonly __typename?: 'CoveredIndicationChanged';
  readonly indicationId?: Maybe<Scalars['UUID']>;
  readonly investorKey?: Maybe<Scalars['String']>;
  readonly offeringId?: Maybe<Scalars['UUID']>;
  readonly source: IndicationChangeSource;
};

export type CoveredInstitutionalIndication = {
  readonly __typename?: 'CoveredInstitutionalIndication';
  readonly acknowledgementStatus?: Maybe<AllocationAcknowledgement>;
  readonly allocationCurrency?: Maybe<Scalars['Decimal']>;
  readonly allocationPercentOfOffering?: Maybe<Scalars['Decimal']>;
  readonly allocationShares?: Maybe<Scalars['Long']>;
  readonly allocationVersion?: Maybe<Scalars['String']>;
  readonly auditInfo: AuditInfo;
  readonly billingAndDeliveryAgentCmgEntityKey?: Maybe<Scalars['String']>;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * Delivery instructions will be absent for a Pass Indication.
   * Deprecated; Use Billing and Delivery endpoints instead
   * @deprecated Deprecated. This will be removed in the future.
   */
  readonly deliveryInstructions?: Maybe<DeliveryInstructions>;
  /** For a surviving indication, this property contains the IDs of its duplicate indications */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly indicationPercentFill?: Maybe<Scalars['Decimal']>;
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformation;
  readonly investorReplyStatus?: Maybe<AllocationAcknowledgement>;
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly prospectusStatus?: Maybe<InvestorDeliveryStatus>;
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type CoveredInstitutionalIndicationVersion = {
  readonly __typename?: 'CoveredInstitutionalIndicationVersion';
  readonly acknowledgementStatus?: Maybe<AllocationAcknowledgement>;
  readonly allocationCurrency?: Maybe<Scalars['Decimal']>;
  readonly allocationPercentOfOffering?: Maybe<Scalars['Decimal']>;
  readonly allocationShares?: Maybe<Scalars['Long']>;
  readonly allocationVersion?: Maybe<Scalars['String']>;
  readonly auditInfo: AuditInfo;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * Delivery instructions will be absent for a Pass Indication.
   * Deprecated; Use Billing and Delivery endpoints instead
   * @deprecated Deprecated. This will be removed in the future.
   */
  readonly deliveryInstructions?: Maybe<DeliveryInstructions>;
  /** For a surviving indication, this property contains the IDs of its duplicate indications */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly indicationPercentFill?: Maybe<Scalars['Decimal']>;
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformation;
  readonly investorReplyStatus?: Maybe<AllocationAcknowledgement>;
  readonly isBuySideRevision: Scalars['Boolean'];
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  readonly tranche?: Maybe<TranchePrivileged>;
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type CoveredInstitutionalIndicationWithAttestation = {
  readonly __typename?: 'CoveredInstitutionalIndicationWithAttestation';
  readonly acknowledgementStatus?: Maybe<AllocationAcknowledgement>;
  readonly allocationCurrency?: Maybe<Scalars['Decimal']>;
  readonly allocationPercentOfOffering?: Maybe<Scalars['Decimal']>;
  readonly allocationShares?: Maybe<Scalars['Long']>;
  readonly allocationVersion?: Maybe<Scalars['String']>;
  readonly attestationStatus: AttestationFormStatus;
  readonly auditInfo: AuditInfo;
  readonly billingAndDeliveryAgentCmgEntityKey?: Maybe<Scalars['String']>;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * Delivery instructions will be absent for a Pass Indication.
   * Deprecated; Use Billing and Delivery endpoints instead
   * @deprecated Deprecated. This will be removed in the future.
   */
  readonly deliveryInstructions?: Maybe<DeliveryInstructions>;
  /** For a surviving indication, this property contains the IDs of its duplicate indications */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly indicationPercentFill?: Maybe<Scalars['Decimal']>;
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformation;
  readonly investorReplyStatus?: Maybe<AllocationAcknowledgement>;
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type CreateAllocationInput = {
  /** The number of shares being allocated. */
  readonly shareQuantity: Scalars['Long'];
};

export type CreateDealRelatedExpenseInput = {
  readonly category: ExpenseCategory;
  readonly description: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export type CreateDealTeamInput = {
  readonly departments: ReadonlyArray<Scalars['String']>;
  readonly firmCmgEntityKey: Scalars['String'];
  readonly members: ReadonlyArray<CreateDealTeamMemberInput>;
};

export type CreateDealTeamMemberInput = {
  readonly department?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: InputMaybe<Scalars['String']>;
};

export type CreateDeliveryInstrumentInput = {
  readonly countryCode: Scalars['String'];
  readonly currencyCode: Scalars['String'];
  readonly cusip?: InputMaybe<Scalars['String']>;
  readonly depositaryReceiptConversionRatioDenominator?: InputMaybe<Scalars['Int']>;
  readonly depositaryReceiptConversionRatioNumerator?: InputMaybe<Scalars['Int']>;
  /** Deprecated; Use `ExchangeMic` instead */
  readonly exchange?: InputMaybe<Scalars['String']>;
  readonly exchangeMic?: InputMaybe<ExchangeMic>;
  readonly figi?: InputMaybe<Scalars['String']>;
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly isin?: InputMaybe<Scalars['String']>;
  readonly stockSymbol?: InputMaybe<Scalars['String']>;
  readonly trancheId?: InputMaybe<Scalars['UUID']>;
};

export type CreateDemandCurrencyInput = {
  readonly currencyCode: Scalars['String'];
  readonly exchangeRate: Scalars['Decimal'];
  readonly trancheId?: InputMaybe<Scalars['UUID']>;
};

export type CreateDraftAllocationInput = {
  readonly dealLayout?: InputMaybe<AssignDealLayoutInput>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type CreateDsFilingInput = {
  readonly filingFormData: DsFilingFormDataInput;
  readonly recipients: ReadonlyArray<Scalars['String']>;
  readonly shouldAddSenderToBcc: Scalars['Boolean'];
  readonly submissionTimeZone: Scalars['DateTimeZone'];
};

export type CreateExpensesRevisionInput = {
  readonly dealRelatedExpenses: ReadonlyArray<CreateDealRelatedExpenseInput>;
  readonly managerExpenses: ReadonlyArray<CreateManagerExpensesInput>;
  readonly notes: Scalars['String'];
};

export type CreateFilingInput = {
  readonly filingType: FilingType;
  readonly initialRegistrationValue?: InputMaybe<Scalars['Decimal']>;
  readonly ipoRangeHigh?: InputMaybe<Scalars['Decimal']>;
  readonly ipoRangeLow?: InputMaybe<Scalars['Decimal']>;
  /** This field is deprecated. Please use the LockUpPeriod field in Terms instead. */
  readonly lockUpPeriod?: InputMaybe<Scalars['Int']>;
  readonly notionalOverAllotmentAuthorized?: InputMaybe<Scalars['Decimal']>;
  /** This field is deprecated. Please use the NotionalOverAllotmentExercised field in Terms instead. */
  readonly notionalOverAllotmentExercised?: InputMaybe<Scalars['Decimal']>;
  readonly offerPrice?: InputMaybe<Scalars['Decimal']>;
  readonly postOfferingShares?: InputMaybe<Scalars['Long']>;
  readonly primarySharesBaseOffering?: InputMaybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentAuthorized?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the PrimarySharesOverAllotmentExercised field in Terms instead. */
  readonly primarySharesOverAllotmentExercised?: InputMaybe<Scalars['Long']>;
  readonly secondarySharesBaseOffering?: InputMaybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentAuthorized?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the SecondarySharesOverAllotmentExercised field in Terms instead. */
  readonly secondarySharesOverAllotmentExercised?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the UseOfProceeds field in Terms instead. */
  readonly useOfProceeds?: InputMaybe<ReadonlyArray<UseOfProceeds>>;
};

export type CreateFreeFormWireInput = {
  readonly body: Scalars['String'];
  readonly header: Scalars['String'];
};

export type CreateInvestorFirmBasicInput = {
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly cik1?: InputMaybe<Scalars['String']>;
  readonly cik2?: InputMaybe<Scalars['String']>;
  readonly crd?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly email1?: InputMaybe<Scalars['String']>;
  readonly email2?: InputMaybe<Scalars['String']>;
  readonly factSet?: InputMaybe<Scalars['String']>;
  readonly faxNumber1?: InputMaybe<Scalars['String']>;
  readonly faxNumber2?: InputMaybe<Scalars['String']>;
  readonly firmKey?: InputMaybe<Scalars['String']>;
  readonly investorSubType?: InputMaybe<Scalars['String']>;
  readonly investorType?: InputMaybe<Scalars['String']>;
  readonly lei?: InputMaybe<Scalars['String']>;
  readonly mpid?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber1?: InputMaybe<Scalars['String']>;
  readonly phoneNumber2?: InputMaybe<Scalars['String']>;
  readonly status: RolodexRecordStatus;
  readonly website1?: InputMaybe<Scalars['String']>;
  readonly website2?: InputMaybe<Scalars['String']>;
};

export type CreateIssuerInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly businessDescription?: InputMaybe<Scalars['String']>;
  readonly cik?: InputMaybe<Scalars['String']>;
  readonly lei?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly sector?: InputMaybe<Sector>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type CreateLogisticsInput = {
  readonly endDate: Scalars['LocalDate'];
  readonly endTime: Scalars['LocalTime'];
  readonly location: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly startDate: Scalars['LocalDate'];
  readonly startTime: Scalars['LocalTime'];
  readonly title: Scalars['String'];
};

export type CreateManagerExpensesInput = {
  readonly cmgEntityKey: Scalars['String'];
  readonly miscellaneous?: InputMaybe<Scalars['Decimal']>;
  readonly reimbursement?: InputMaybe<Scalars['Decimal']>;
  readonly technologyAndData?: InputMaybe<Scalars['Decimal']>;
  readonly travelAndMeals?: InputMaybe<Scalars['Decimal']>;
};

export type CreateManagerInput = {
  readonly cmgEntityKey: Scalars['String'];
  /** Deprecated; Use `Underwriting Base Share Distribution` in Manager Economics instead */
  readonly economics?: InputMaybe<Scalars['Long']>;
  readonly isJuniorRole: Scalars['Boolean'];
  readonly isParticipating: Scalars['Boolean'];
  readonly role: ManagerRole;
};

export type CreateMdlOfferingNoteInput = {
  readonly note: Scalars['String'];
  /** The subject type for the note. Possible Values: ALLOCATION, COMPLIANCE, GENERAL, INDICATION, MESSAGING. */
  readonly subject: OfferingNoteSubject;
};

export type CreateMyRetailBrokerInput = {
  readonly demand: MyRetailBrokerDemandInput;
  readonly firmCmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly firmName?: InputMaybe<Scalars['String']>;
  readonly retention: MyRetailRetentionInput;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
};

export type CreateNoiFilingInput = {
  readonly filingFormData: NoiFilingFormDataInput;
  readonly recipients: ReadonlyArray<Scalars['String']>;
  readonly shouldAddSenderToBcc: Scalars['Boolean'];
  readonly submissionTimeZone: Scalars['DateTimeZone'];
};

export type CreateNonSyndicateFreeFormWireInput = {
  readonly body: Scalars['String'];
  readonly header: Scalars['String'];
};

export type CreateNonSyndicateSellingGroupInvitationWireInput = {
  readonly userDataForRecipients: ReadonlyArray<NonSyndicateSellingGroupInvitationDataForRecipient>;
};

export type CreateNonSyndicateSellingGroupTermsRetentionWireInput = {
  readonly userDataForRecipients: ReadonlyArray<CreateNonSyndicateSellingGroupTermsRetentionWireInputData>;
};

export type CreateNonSyndicateSellingGroupTermsRetentionWireInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
  readonly sellingConcession: Scalars['Decimal'];
};

export type CreateNonSyndicateTerminationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly terminationDate: Scalars['Instant'];
  readonly terminationTimeZone: Scalars['DateTimeZone'];
};

export type CreateOfferingInput = {
  readonly issuer: CreateIssuerInput;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly security: CreateSecurityInput;
  readonly type: OfferingType;
};

export type CreateOrUpdateCityInput = {
  readonly name: Scalars['String'];
  readonly timeZone: Scalars['DateTimeZone'];
};

export type CreateOrUpdateDemandCurrenciesInput = {
  readonly demandCurrencies: ReadonlyArray<CreateDemandCurrencyInput>;
  readonly offeringId: Scalars['UUID'];
};

export type CreateOrUpdateDemandCurrenciesPayload = {
  readonly __typename?: 'CreateOrUpdateDemandCurrenciesPayload';
  readonly demandCurrency?: Maybe<ReadonlyArray<DemandCurrency>>;
};

export type CreateOrUpdateRoadshowDayInput = {
  readonly cities: ReadonlyArray<CreateOrUpdateCityInput>;
  readonly date: Scalars['LocalDate'];
  readonly isVirtual: Scalars['Boolean'];
  readonly timeZone?: InputMaybe<Scalars['DateTimeZone']>;
};

export type CreateOrUpdateStabilizationInput = {
  readonly notes: Scalars['String'];
  readonly trades: ReadonlyArray<CreateOrUpdateStabilizationTradeInput>;
};

export type CreateOrUpdateStabilizationTradeInput = {
  readonly activityType?: InputMaybe<TradeActivityType>;
  readonly averagePrice: Scalars['Decimal'];
  readonly date: Scalars['LocalDate'];
  readonly shares: Scalars['Long'];
  readonly type: TradeType;
};

export type CreatePotAccountWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly headerNote?: InputMaybe<Scalars['String']>;
};

export type CreatePotAllocationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly headerNote?: InputMaybe<Scalars['String']>;
};

export type CreatePricingTermsRetentionWireInput = {
  readonly userDataForRecipients: ReadonlyArray<PricingTermsRetentionUserDataForRecipientInputData>;
};

export type CreatePricingTermsWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
};

export type CreateRegMWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly notificationPeriod: RegulationMNotificationPeriod;
  readonly offeringResult: Scalars['String'];
};

export type CreateRpnFilingInput = {
  readonly filingFormData: RpnFilingFormDataInput;
  readonly recipients: ReadonlyArray<Scalars['String']>;
  readonly shouldAddSenderToBcc: Scalars['Boolean'];
  readonly submissionTimeZone: Scalars['DateTimeZone'];
};

export type CreateSecurityInput = {
  readonly isDepositaryReceipt?: InputMaybe<Scalars['Boolean']>;
  readonly shareClass?: InputMaybe<SecurityShareClass>;
  readonly type: SecurityType;
};

export type CreateSellingGroupInvitationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly masterSdaDate: Scalars['LocalDate'];
};

export type CreateSellingGroupTermsWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
};

export type CreateTerminationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly terminationDate: Scalars['Instant'];
  readonly terminationTimeZone: Scalars['DateTimeZone'];
};

export type CreateTnFilingInput = {
  readonly filingFormData: TnFilingFormDataInput;
  readonly recipients: ReadonlyArray<Scalars['String']>;
  readonly shouldAddSenderToBcc: Scalars['Boolean'];
  readonly submissionTimeZone: Scalars['DateTimeZone'];
};

export type CreateUnderwriterInvitationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly masterAauDate: Scalars['LocalDate'];
};

export type CreateUsdtnFilingInput = {
  readonly filingFormData: UsdtnFilingFormDataInput;
  readonly recipients: ReadonlyArray<Scalars['String']>;
  readonly shouldAddSenderToBcc: Scalars['Boolean'];
  readonly submissionTimeZone: Scalars['DateTimeZone'];
};

export type CustomVariable = {
  readonly __typename?: 'CustomVariable';
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly value?: Maybe<Scalars['String']>;
};

export type CustomVariableGroup = {
  readonly __typename?: 'CustomVariableGroup';
  readonly displayName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly variables: ReadonlyArray<CustomVariable>;
};

export type DataLabFull = {
  readonly __typename?: 'DataLabFull';
  readonly mdlAllocation?: Maybe<MdlAllocationResponse>;
  readonly mdlIndications?: Maybe<MdlIndications>;
};

export type DateOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Date']>;
  readonly gt?: InputMaybe<Scalars['Date']>;
  readonly gte?: InputMaybe<Scalars['Date']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Date']>>>;
  readonly lt?: InputMaybe<Scalars['Date']>;
  readonly lte?: InputMaybe<Scalars['Date']>;
  readonly neq?: InputMaybe<Scalars['Date']>;
  readonly ngt?: InputMaybe<Scalars['Date']>;
  readonly ngte?: InputMaybe<Scalars['Date']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Date']>>>;
  readonly nlt?: InputMaybe<Scalars['Date']>;
  readonly nlte?: InputMaybe<Scalars['Date']>;
};

export type DateTimeZoneOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['DateTimeZone']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTimeZone']>>>;
  readonly neq?: InputMaybe<Scalars['DateTimeZone']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTimeZone']>>>;
};

export type DealLayout = {
  readonly __typename?: 'DealLayout';
  readonly id: Scalars['ID'];
  readonly institutionalTarget?: Maybe<Scalars['Long']>;
  readonly offeringSize?: Maybe<Scalars['Long']>;
};

export type DealLayoutReadDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DealLayoutReadDtoFilterInput>>;
  readonly institutionalTarget?: InputMaybe<LongOperationFilterInput>;
  readonly offeringSize?: InputMaybe<LongOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DealLayoutReadDtoFilterInput>>;
};

export type DealLayoutReadDtoSortInput = {
  readonly institutionalTarget?: InputMaybe<SortEnumType>;
  readonly offeringSize?: InputMaybe<SortEnumType>;
};

export type DealRelatedExpense = {
  readonly __typename?: 'DealRelatedExpense';
  readonly category: ExpenseCategory;
  /** Display name for Category */
  readonly categoryDisplayName?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly value: Scalars['Decimal'];
};

export enum DealStatus {
  Cmplt = 'CMPLT',
  Cncld = 'CNCLD',
  Pspnd = 'PSPND',
}

export type DealTeam = {
  readonly __typename?: 'DealTeam';
  readonly departments: ReadonlyArray<Scalars['String']>;
  readonly firmCmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly members: ReadonlyArray<DealTeamMember>;
  readonly participatedRoadshowNames: ReadonlyArray<Scalars['String']>;
};

export type DealTeamLimited = {
  readonly __typename?: 'DealTeamLimited';
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly membersCount: Scalars['Int'];
};

export type DealTeamLimitedPagedList = {
  readonly __typename?: 'DealTeamLimitedPagedList';
  readonly data: ReadonlyArray<DealTeamLimited>;
  readonly pagination: PaginationData;
};

export type DealTeamMember = {
  readonly __typename?: 'DealTeamMember';
  readonly department?: Maybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: Maybe<Scalars['String']>;
  readonly status: DealTeamMemberStatus;
  readonly type: DealTeamMemberType;
};

export type DealTeamMemberLimited = {
  readonly __typename?: 'DealTeamMemberLimited';
  readonly department?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isMyOrganization: Scalars['Boolean'];
};

export type DealTeamMemberLimitedPagedList = {
  readonly __typename?: 'DealTeamMemberLimitedPagedList';
  readonly data: ReadonlyArray<DealTeamMemberLimited>;
  readonly pagination: PaginationData;
};

export type DealTeamMemberRoadshowNames = {
  readonly __typename?: 'DealTeamMemberRoadshowNames';
  readonly participatedRoadshowNames: ReadonlyArray<Scalars['String']>;
};

export enum DealTeamMemberStatus {
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export enum DealTeamMemberType {
  Identity = 'IDENTITY',
  Manual = 'MANUAL',
}

export type DealTeamNameAndDepartments = {
  readonly __typename?: 'DealTeamNameAndDepartments';
  readonly departments: ReadonlyArray<Scalars['String']>;
  readonly firmCmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type DecimalExtendedData = {
  readonly __typename?: 'DecimalExtendedData';
  readonly key: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly value: Scalars['Decimal'];
};

export type DecimalOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Decimal']>;
  readonly gt?: InputMaybe<Scalars['Decimal']>;
  readonly gte?: InputMaybe<Scalars['Decimal']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']>>>;
  readonly lt?: InputMaybe<Scalars['Decimal']>;
  readonly lte?: InputMaybe<Scalars['Decimal']>;
  readonly neq?: InputMaybe<Scalars['Decimal']>;
  readonly ngt?: InputMaybe<Scalars['Decimal']>;
  readonly ngte?: InputMaybe<Scalars['Decimal']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Decimal']>>>;
  readonly nlt?: InputMaybe<Scalars['Decimal']>;
  readonly nlte?: InputMaybe<Scalars['Decimal']>;
};

export type DeleteBillingAndDeliveryAgentResult = {
  readonly __typename?: 'DeleteBillingAndDeliveryAgentResult';
  readonly indicationId: Scalars['UUID'];
};

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteDesignationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteFreeFormWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteGreenShoeWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteIssuerAllocationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteIssuerIndicationWireResponseUnion = DeleteWire | ServiceError;

export type DeleteManager = {
  readonly __typename?: 'DeleteManager';
  readonly id: Scalars['String'];
};

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteManagerBdWireResponseUnion = DeleteWire | ServiceError;

export type DeleteMdlOfferingNoteInput = {
  readonly id: Scalars['ID'];
};

export type DeleteMyRetailDemandBroker = {
  readonly __typename?: 'DeleteMyRetailDemandBroker';
  readonly id: Scalars['ID'];
};

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteNonSyndicateFreeFormWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteNonSyndicatePaymentWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteNonSyndicateSellingGroupInvitationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteNonSyndicateSellingGroupTermsRetentionWireResponseUnion =
  | DeleteWire
  | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteNonSyndicateTerminationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeletePaymentWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeletePotAccountWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeletePotAllocationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeletePricingTermsRetentionWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeletePricingTermsWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteRegMWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteRetentionWireResponseUnion = DeleteWire | ServiceError;

export type DeleteRoadshowSchedule = {
  readonly __typename?: 'DeleteRoadshowSchedule';
  readonly id: Scalars['ID'];
};

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteSellingGroupInvitationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteSellingGroupTermsWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteTerminationWireResponseUnion = DeleteWire | ServiceError;

/** Union of DeleteWireResponse and ServiceError, either a DeleteWireResponse or ServiceError is returned */
export type DeleteUnderwriterInvitationWireResponseUnion = DeleteWire | ServiceError;

/** Response for delete wire mutation */
export type DeleteWire = {
  readonly __typename?: 'DeleteWire';
  readonly id: Scalars['ID'];
};

export type DeliveryInstructions = {
  readonly __typename?: 'DeliveryInstructions';
  /** The manager responsible for this set of delivery instrument splits. */
  readonly cmgEntityKey: Scalars['String'];
  readonly deliveryInstrumentSplits: ReadonlyArray<DeliveryInstrumentSplit>;
};

export type DeliveryInstrument = {
  readonly __typename?: 'DeliveryInstrument';
  readonly countryCode: Scalars['String'];
  readonly currencyCode: Scalars['String'];
  readonly cusip?: Maybe<Scalars['String']>;
  readonly depositaryReceiptConversionRatio?: Maybe<DepositaryReceiptConversionRatio>;
  readonly exchange?: Maybe<Scalars['String']>;
  readonly exchangeMic?: Maybe<ExchangeMic>;
  /** Display name for ExchangeMic */
  readonly exchangeMicDisplayName?: Maybe<Scalars['String']>;
  readonly figi?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly isin?: Maybe<Scalars['String']>;
  readonly recordStatus: RecordStatus;
  readonly stockSymbol?: Maybe<Scalars['String']>;
};

export type DeliveryInstrumentPrivileged = {
  readonly __typename?: 'DeliveryInstrumentPrivileged';
  /** Null is returned if your firm does not have an active bookrunner relationship with this offering. */
  readonly countryCode: Scalars['String'];
  /** Null is returned if your firm does not have an active bookrunner relationship with this offering. */
  readonly currencyCode: Scalars['String'];
  readonly cusip?: Maybe<Scalars['String']>;
  /** Null is returned if your firm does not have an active bookrunner relationship with this offering. */
  readonly depositaryReceiptConversionRatio?: Maybe<DepositaryReceiptConversionRatio>;
  readonly exchange?: Maybe<Scalars['String']>;
  readonly exchangeMic?: Maybe<ExchangeMic>;
  /** Display name for ExchangeMic */
  readonly exchangeMicDisplayName?: Maybe<Scalars['String']>;
  readonly figi?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly isin?: Maybe<Scalars['String']>;
  readonly stockSymbol?: Maybe<Scalars['String']>;
};

export type DeliveryInstrumentSplit = {
  readonly __typename?: 'DeliveryInstrumentSplit';
  readonly deliveryInstrumentId: Scalars['UUID'];
  /** A decimal number which represents the exact percentage split for this manager. */
  readonly splitPercentage: Scalars['Decimal'];
};

/** Union of DeliveryInstrument and ServiceError */
export type DeliveryInstrumentUnion = DeliveryInstrument | ServiceError;

export type DemandCurrency = {
  readonly __typename?: 'DemandCurrency';
  readonly currencyCode: Scalars['String'];
  readonly exchangeRate: Scalars['Decimal'];
  readonly id: Scalars['ID'];
  readonly recordStatus: RecordStatus;
};

export type DemandCurrencyFull = {
  readonly __typename?: 'DemandCurrencyFull';
  readonly currencyCode: Scalars['String'];
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
};

export type DemandCurrencyFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DemandCurrencyFullFilterInput>>;
  readonly currencyCode?: InputMaybe<StringOperationFilterInput>;
  readonly exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DemandCurrencyFullFilterInput>>;
};

export type DemandCurrencyProfile = {
  readonly __typename?: 'DemandCurrencyProfile';
  readonly currencyCode: Scalars['String'];
  readonly exchangeRate?: Maybe<Scalars['Decimal']>;
};

export type DemandLevel = {
  readonly __typename?: 'DemandLevel';
  readonly price: Scalars['Decimal'];
  readonly shares: Scalars['Long'];
};

export type DemandLevelFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DemandLevelFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<DemandLevelFilterInput>>;
  readonly price?: InputMaybe<DecimalOperationFilterInput>;
  readonly shares?: InputMaybe<LongOperationFilterInput>;
};

export type DemandLevelSortInput = {
  readonly price: Scalars['Decimal'];
  readonly shares?: InputMaybe<SortEnumType>;
};

export type DepositaryReceiptConversionRatio = {
  readonly __typename?: 'DepositaryReceiptConversionRatio';
  readonly denominator: Scalars['Int'];
  readonly numerator: Scalars['Int'];
};

/** The Institutional Allocation */
export type DesignationAllocation = {
  readonly __typename?: 'DesignationAllocation';
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly investor: DesignationInvestor;
  readonly shares: Scalars['Long'];
};

export type DesignationInvestor = {
  readonly __typename?: 'DesignationInvestor';
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  readonly bankInvestorName?: Maybe<Scalars['String']>;
  readonly cmgEntityKey: Scalars['ID'];
  readonly cmgEntityName: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type DesignationManager = {
  readonly __typename?: 'DesignationManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly cmgEntityName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly role: ExpensesManagerRole;
  /** Display name for Role */
  readonly roleDisplayName?: Maybe<Scalars['String']>;
};

export type DesignationRecipientData = {
  readonly __typename?: 'DesignationRecipientData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly headerNote?: Maybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type DesignationRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly headerNote?: InputMaybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type DesignationsGrid = {
  readonly __typename?: 'DesignationsGrid';
  readonly designations: ReadonlyArray<DesignationsGridItem>;
  readonly id: Scalars['ID'];
  readonly syndicateManagers: ReadonlyArray<DesignationsGridManager>;
};

export type DesignationsGridAllocation = {
  readonly __typename?: 'DesignationsGridAllocation';
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly investorCmgEntityKey: Scalars['String'];
  readonly investorName: Scalars['String'];
  readonly shares: Scalars['Long'];
};

export type DesignationsGridDesignation = {
  readonly __typename?: 'DesignationsGridDesignation';
  readonly fixedEconomicsShares: Scalars['Long'];
  readonly id: Scalars['ID'];
  readonly managerCmgEntityKey: Scalars['String'];
  readonly sellingConcessionPercentageChanged: Scalars['Boolean'];
  readonly shares?: Maybe<Scalars['Long']>;
};

export type DesignationsGridItem = {
  readonly __typename?: 'DesignationsGridItem';
  readonly allocation: DesignationsGridAllocation;
  readonly designations: ReadonlyArray<DesignationsGridDesignation>;
  readonly id: Scalars['ID'];
  readonly isPublished?: Maybe<Scalars['Boolean']>;
  readonly version?: Maybe<Scalars['String']>;
  readonly warnings: ReadonlyArray<DesignationWarning>;
};

export type DesignationsGridManager = {
  readonly __typename?: 'DesignationsGridManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export enum DesignationStrategy {
  /** Operation type for applying fixed economics to all managers for an allocation. */
  FixedEconomics = 'FIXED_ECONOMICS',
  /** Operation type for manually assigning a designation to a manager for an allocation. */
  ManualDesignation = 'MANUAL_DESIGNATION',
}

export type DesignationUpdateInput = {
  /** The Institutional Indication Id. */
  readonly indicationId: Scalars['ID'];
  /** The CMG Entity Key of given Manager. */
  readonly managerCmgEntityKey: Scalars['ID'];
  /** The quantity of shares that the Manager will get. */
  readonly shares: Scalars['Long'];
};

export type DesignationValidation = {
  readonly __typename?: 'DesignationValidation';
  readonly areFinalAllocationsReleased: Scalars['Boolean'];
};

export enum DesignationWarning {
  AllocationIsRejected = 'ALLOCATION_IS_REJECTED',
  AllocationMustBeGreaterThanZero = 'ALLOCATION_MUST_BE_GREATER_THAN_ZERO',
  DesignationsAreOutOfBalance = 'DESIGNATIONS_ARE_OUT_OF_BALANCE',
  IndicationIsCancelled = 'INDICATION_IS_CANCELLED',
  IndicationIsDuplicate = 'INDICATION_IS_DUPLICATE',
  IndicationIsPass = 'INDICATION_IS_PASS',
}

export type DesignationWire = {
  readonly __typename?: 'DesignationWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<DesignationRecipientData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type DesignationWireInput = {
  readonly userDataForRecipients: ReadonlyArray<DesignationRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type DesignationWireResponseUnion = DesignationWire | ServiceError;

export type DesignationWireValidation = WireValidationBase & {
  readonly __typename?: 'DesignationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<DesignationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum DesignationWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SellingConcession = 'SELLING_CONCESSION',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type Disclaimers = {
  readonly __typename?: 'Disclaimers';
  readonly footnotes?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly registrationLinks: ReadonlyArray<Scalars['String']>;
  readonly restrictions?: Maybe<Scalars['String']>;
  readonly rule134Legend?: Maybe<Scalars['String']>;
};

export type DisclaimersFull = {
  readonly __typename?: 'DisclaimersFull';
  readonly footnotes?: Maybe<Scalars['String']>;
  readonly registrationLinks: ReadonlyArray<Scalars['String']>;
  readonly restrictions?: Maybe<Scalars['String']>;
  readonly rule134Legend?: Maybe<Scalars['String']>;
};

export type DisclaimersFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<DisclaimersFullFilterInput>>;
  readonly footnotes?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<DisclaimersFullFilterInput>>;
  readonly registrationLinks?: InputMaybe<ListStringOperationFilterInput>;
  readonly restrictions?: InputMaybe<StringOperationFilterInput>;
  readonly rule134Legend?: InputMaybe<StringOperationFilterInput>;
};

export type DisclaimersProfile = {
  readonly __typename?: 'DisclaimersProfile';
  readonly footnotes?: Maybe<Scalars['String']>;
  readonly registrationLinks: ReadonlyArray<Scalars['String']>;
  readonly restrictions?: Maybe<Scalars['String']>;
  readonly rule134Legend?: Maybe<Scalars['String']>;
};

export type DraftAllocation = {
  readonly __typename?: 'DraftAllocation';
  readonly allocationSetId: Scalars['UUID'];
  readonly auditInfo: AuditInfo;
  readonly id: Scalars['ID'];
  /** The ID of the IOI to which this Allocation correlates. */
  readonly indicationId: Scalars['UUID'];
  /** The number of shares for this Allocation. */
  readonly shareQuantity?: Maybe<Scalars['Long']>;
  readonly version: Scalars['String'];
};

export type DraftAllocationBatchUpdateInput = {
  readonly allocations: ReadonlyArray<DraftAllocationsUpdateInputAllocations>;
};

export type DraftAllocationSet = {
  readonly __typename?: 'DraftAllocationSet';
  readonly auditInfo: AuditInfo;
  readonly authorCmgEntityKey: Scalars['String'];
  readonly dealLayout: DealLayout;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly offeringId: Scalars['UUID'];
  readonly recordStatus: OrderBookRecordStatus;
  readonly sharedWithManagerCmgEntityKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly shareTimestamp?: Maybe<Scalars['Instant']>;
  readonly version: Scalars['String'];
};

export type DraftAllocationsUpdateInputAllocations = {
  readonly allocation: DraftAllocationUpdateInput;
  readonly indicationId: Scalars['UUID'];
};

export type DraftAllocationUpdateInput = {
  /** The number of shares being allocated. */
  readonly shareQuantity?: InputMaybe<Scalars['Long']>;
};

export type DsFiling = {
  readonly __typename?: 'DSFiling';
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly dealStatus: DealStatus;
  /** Display name for DealStatus */
  readonly dealStatusDisplayName?: Maybe<Scalars['String']>;
  readonly emailAddress: Scalars['String'];
  readonly filingCreatorEmail?: Maybe<Scalars['String']>;
  readonly finraErrorMessages: ReadonlyArray<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmDealId: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly formUniqueId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly issuerName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
  readonly telephoneNumber: Scalars['String'];
};

export type DsFilingFormDataInput = {
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly dealStatus: DealStatus;
  readonly emailAddress: Scalars['String'];
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly telephoneNumber: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
}

export type EmptyMutationResponse = {
  readonly __typename?: 'EmptyMutationResponse';
  /** GraphQL doesn't allow empty object types, so here's something to soothe its rage */
  readonly placeholder: Scalars['Boolean'];
};

export type Entitlements = {
  readonly __typename?: 'Entitlements';
  readonly id: Scalars['ID'];
  readonly syndicateEntitlements: ReadonlyArray<SyndicateEntitlements>;
  readonly version: Scalars['String'];
};

/**
 * Defines the allowed Actions for a Resource and human consumable descriptions for ease of consumption.
 * There may only be one Resource Entitlements Configuration per Resource
 */
export type EntitlementsConfiguration = {
  readonly __typename?: 'EntitlementsConfiguration';
  readonly actionConfigurations: ReadonlyArray<ActionConfiguration>;
  readonly boundedContext: BoundedContext;
  readonly description: Scalars['String'];
  readonly displayName: Scalars['String'];
  readonly resource: Resource;
};

/** Legacy Detail Error object for Apollo Compatibility */
export type ErrorDetail = {
  readonly __typename?: 'ErrorDetail';
  readonly code?: Maybe<Scalars['String']>;
  readonly message?: Maybe<Scalars['String']>;
  readonly target?: Maybe<Scalars['String']>;
};

export type EventParticipant = {
  readonly __typename?: 'EventParticipant';
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isAdHoc: Scalars['Boolean'];
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: Maybe<Scalars['String']>;
  readonly status?: Maybe<DealTeamMemberStatus>;
  readonly title?: Maybe<Scalars['String']>;
};

export type EventParticipantsTeam = {
  readonly __typename?: 'EventParticipantsTeam';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly participants: ReadonlyArray<EventParticipant>;
};

export enum EventStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  OpenToSales = 'OPEN_TO_SALES',
}

export enum ExchangeMic {
  Aqse = 'AQSE',
  Bats = 'BATS',
  Bvmf = 'BVMF',
  Difx = 'DIFX',
  Dsmd = 'DSMD',
  Hstc = 'HSTC',
  Misx = 'MISX',
  Neoe = 'NEOE',
  Notc = 'NOTC',
  Xads = 'XADS',
  Xamm = 'XAMM',
  Xams = 'XAMS',
  Xasx = 'XASX',
  Xath = 'XATH',
  Xbah = 'XBAH',
  Xbkk = 'XBKK',
  Xbog = 'XBOG',
  Xbom = 'XBOM',
  Xbru = 'XBRU',
  Xbrv = 'XBRV',
  Xbse = 'XBSE',
  Xbud = 'XBUD',
  Xbue = 'XBUE',
  Xcai = 'XCAI',
  Xcas = 'XCAS',
  Xcnq = 'XCNQ',
  Xcol = 'XCOL',
  Xcse = 'XCSE',
  Xcys = 'XCYS',
  Xdfm = 'XDFM',
  Xdha = 'XDHA',
  Xdse = 'XDSE',
  Xdub = 'XDUB',
  Xfra = 'XFRA',
  Xhel = 'XHEL',
  Xhkg = 'XHKG',
  Xice = 'XICE',
  Xidx = 'XIDX',
  Xist = 'XIST',
  Xjse = 'XJSE',
  Xkar = 'XKAR',
  Xkls = 'XKLS',
  Xkrx = 'XKRX',
  Xkuw = 'XKUW',
  Xlim = 'XLIM',
  Xlis = 'XLIS',
  Xlju = 'XLJU',
  Xlon = 'XLON',
  Xlux = 'XLUX',
  Xmad = 'XMAD',
  Xmex = 'XMEX',
  Xmil = 'XMIL',
  Xmus = 'XMUS',
  Xnai = 'XNAI',
  Xnas = 'XNAS',
  Xnec = 'XNEC',
  Xngm = 'XNGM',
  Xnsa = 'XNSA',
  Xnse = 'XNSE',
  Xnys = 'XNYS',
  Xnze = 'XNZE',
  Xosl = 'XOSL',
  Xpar = 'XPAR',
  Xphs = 'XPHS',
  Xpra = 'XPRA',
  Xsau = 'XSAU',
  Xses = 'XSES',
  Xsgo = 'XSGO',
  Xshe = 'XSHE',
  Xshg = 'XSHG',
  Xstc = 'XSTC',
  Xsto = 'XSTO',
  Xswx = 'XSWX',
  Xtae = 'XTAE',
  Xtai = 'XTAI',
  Xtal = 'XTAL',
  Xtks = 'XTKS',
  Xtse = 'XTSE',
  Xtsx = 'XTSX',
  Xwar = 'XWAR',
  Xwbo = 'XWBO',
  Xzag = 'XZAG',
}

export enum ExpenseCategory {
  ClosingExpenses = 'CLOSING_EXPENSES',
  DtcCosts = 'DTC_COSTS',
  ExpensesSubjectTo_274N = 'EXPENSES_SUBJECT_TO_274N',
  FinancingSettlement = 'FINANCING_SETTLEMENT',
  LegalFees = 'LEGAL_FEES',
  Operational = 'OPERATIONAL',
  OtherExpenses = 'OTHER_EXPENSES',
  PrintCommunications = 'PRINT_COMMUNICATIONS',
  ProfessionalServices = 'PROFESSIONAL_SERVICES',
  Regulatory = 'REGULATORY',
  ReimbursementToIssuer = 'REIMBURSEMENT_TO_ISSUER',
  Roadshow = 'ROADSHOW',
  TechnologyData = 'TECHNOLOGY_DATA',
  ThirdPartyAdvisors = 'THIRD_PARTY_ADVISORS',
  TravelMeals = 'TRAVEL_MEALS',
}

export type Expenses = {
  readonly __typename?: 'Expenses';
  readonly dealRelatedExpenses: ReadonlyArray<DealRelatedExpense>;
  readonly id: Scalars['ID'];
  readonly managerExpenses: ReadonlyArray<ManagerExpenses>;
  readonly managers: ReadonlyArray<ExpensesManager>;
  readonly notes?: Maybe<Scalars['String']>;
};

export type ExpensesManager = {
  readonly __typename?: 'ExpensesManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly role: ExpensesManagerRole;
  /** Display name for Role */
  readonly roleDisplayName?: Maybe<Scalars['String']>;
};

export enum ExpensesManagerRole {
  ActiveBookrunner = 'ACTIVE_BOOKRUNNER',
  Bookrunner = 'BOOKRUNNER',
  CoLead = 'CO_LEAD',
  CoManager = 'CO_MANAGER',
  Invalid = 'INVALID',
  SellingGroupMember = 'SELLING_GROUP_MEMBER',
  Underwriter = 'UNDERWRITER',
}

export enum ExtendedDataCategory {
  Compliance = 'COMPLIANCE',
  InvestorComments = 'INVESTOR_COMMENTS',
  InvestorRegion = 'INVESTOR_REGION',
  InvestorType = 'INVESTOR_TYPE',
  OtherInvestorAttributes = 'OTHER_INVESTOR_ATTRIBUTES',
  Revenue = 'REVENUE',
}

export type FiledPublishedOfferingListFiltersInput = {
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly endPublicFilingDate?: InputMaybe<Scalars['Date']>;
  readonly includeTestOfferings?: InputMaybe<Scalars['Boolean']>;
  readonly startPublicFilingDate?: InputMaybe<Scalars['Date']>;
};

export type Filing = {
  readonly __typename?: 'Filing';
  readonly filingType: FilingType;
  readonly id: Scalars['ID'];
  readonly initialRegistrationValue?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeLow?: Maybe<Scalars['Decimal']>;
  readonly lockUpPeriod?: Maybe<Scalars['Int']>;
  readonly notionalOverAllotmentAuthorized?: Maybe<Scalars['Decimal']>;
  readonly notionalOverAllotmentExercised?: Maybe<Scalars['Decimal']>;
  readonly offerPrice?: Maybe<Scalars['Decimal']>;
  readonly postOfferingShares?: Maybe<Scalars['Long']>;
  readonly primarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly recordStatus: RecordStatus;
  readonly secondarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly totalSharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly useOfProceeds: ReadonlyArray<UseOfProceeds>;
};

export type FilingFull = {
  readonly __typename?: 'FilingFull';
  readonly filingType: FilingType;
  readonly id: Scalars['UUID'];
  readonly initialRegistrationValue?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeLow?: Maybe<Scalars['Decimal']>;
  readonly lockUpPeriod?: Maybe<Scalars['Int']>;
  readonly notionalOverAllotmentAuthorized?: Maybe<Scalars['Decimal']>;
  readonly notionalOverAllotmentExercised?: Maybe<Scalars['Decimal']>;
  readonly offerPrice?: Maybe<Scalars['Decimal']>;
  readonly postOfferingShares?: Maybe<Scalars['Long']>;
  readonly primarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly secondarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly totalSharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly useOfProceeds: ReadonlyArray<UseOfProceeds>;
};

export type FilingFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FilingFullFilterInput>>;
  readonly filingType?: InputMaybe<FilingTypeFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly initialRegistrationValue?: InputMaybe<DecimalOperationFilterInput>;
  readonly ipoRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  readonly ipoRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  readonly lockUpPeriod?: InputMaybe<IntOperationFilterInput>;
  readonly notionalOverAllotmentAuthorized?: InputMaybe<DecimalOperationFilterInput>;
  readonly notionalOverAllotmentExercised?: InputMaybe<DecimalOperationFilterInput>;
  readonly offerPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FilingFullFilterInput>>;
  readonly postOfferingShares?: InputMaybe<LongOperationFilterInput>;
  readonly primarySharesBaseOffering?: InputMaybe<LongOperationFilterInput>;
  readonly primarySharesOverAllotmentAuthorized?: InputMaybe<LongOperationFilterInput>;
  readonly primarySharesOverAllotmentExercised?: InputMaybe<LongOperationFilterInput>;
  readonly secondarySharesBaseOffering?: InputMaybe<LongOperationFilterInput>;
  readonly secondarySharesOverAllotmentAuthorized?: InputMaybe<LongOperationFilterInput>;
  readonly secondarySharesOverAllotmentExercised?: InputMaybe<LongOperationFilterInput>;
  readonly totalSharesBaseOffering?: InputMaybe<LongOperationFilterInput>;
  readonly totalSharesOverAllotmentAuthorized?: InputMaybe<LongOperationFilterInput>;
  readonly totalSharesOverAllotmentExercised?: InputMaybe<LongOperationFilterInput>;
  readonly useOfProceeds?: InputMaybe<UseOfProceedsListFilterInput>;
};

export type FilingLastCommonValues = {
  readonly __typename?: 'FilingLastCommonValues';
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactTitle?: Maybe<Scalars['String']>;
  readonly emailAddress?: Maybe<Scalars['String']>;
  readonly firmCrdNumber?: Maybe<Scalars['String']>;
  readonly firmMpid?: Maybe<Scalars['String']>;
  readonly firmName?: Maybe<Scalars['String']>;
  readonly issuerSymbol?: Maybe<Scalars['String']>;
  readonly telephoneNumber?: Maybe<Scalars['String']>;
};

export type FilingProfile = {
  readonly __typename?: 'FilingProfile';
  readonly filingType: FilingType;
  readonly id: Scalars['ID'];
  readonly initialRegistrationValue?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly ipoRangeLow?: Maybe<Scalars['Decimal']>;
  readonly lockUpPeriod?: Maybe<Scalars['Int']>;
  readonly notionalOverAllotmentAuthorized?: Maybe<Scalars['Decimal']>;
  readonly notionalOverAllotmentExercised?: Maybe<Scalars['Decimal']>;
  readonly offerPrice?: Maybe<Scalars['Decimal']>;
  /** Always Null on the FilingProfile type, added here for compatibility with Apollo and Front-end Fragments. */
  readonly postOfferingShares?: Maybe<Scalars['Long']>;
  readonly primarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly secondarySharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly totalSharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  readonly totalSharesOverAllotmentExercised?: Maybe<Scalars['Long']>;
  readonly useOfProceeds: ReadonlyArray<UseOfProceeds>;
};

export enum FilingStatus {
  Error = 'ERROR',
  MissingResponse = 'MISSING_RESPONSE',
  Processing = 'PROCESSING',
  SubmissionError = 'SUBMISSION_ERROR',
  Submitted = 'SUBMITTED',
}

export enum FilingType {
  Final = 'FINAL',
  Initial = 'INITIAL',
  Revised = 'REVISED',
}

export type FilingTypeFilterInput = {
  readonly eq?: InputMaybe<FilingType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<FilingType>>>;
  readonly neq?: InputMaybe<FilingType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<FilingType>>>;
};

/** Union of Filing and ServiceError */
export type FilingUnion = Filing | ServiceError;

export type FinalAllocation = {
  readonly __typename?: 'FinalAllocation';
  readonly acknowledgementStatus: AllocationAcknowledgement;
  readonly auditInfo: AuditInfo;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly investorReply?: Maybe<InvestorReply>;
  readonly shareQuantity?: Maybe<Scalars['Long']>;
  readonly version: Scalars['String'];
};

export type FinalAllocations = {
  readonly __typename?: 'FinalAllocations';
  readonly finalAllocations: ReadonlyArray<FinalAllocation>;
};

export type FinalAllocationsSetCreateInput = {
  readonly allocations?: InputMaybe<ReadonlyArray<AllocationsInput>>;
  readonly draftAllocationsId?: InputMaybe<Scalars['String']>;
};

export type FinalAllocationsUpdateInput = {
  readonly allocations: ReadonlyArray<FinalAllocationsUpdateInputAllocations>;
};

export type FinalAllocationsUpdateInputAllocations = {
  readonly allocation: FinalAllocationUpdateInput;
  readonly indicationId: Scalars['UUID'];
};

export type FinalAllocationUpdateInput = {
  /** The number of shares being allocated. */
  readonly shareQuantity?: InputMaybe<Scalars['Long']>;
};

export type FinalInstitutionalAllocationSet = {
  readonly __typename?: 'FinalInstitutionalAllocationSet';
  readonly auditInfo: AuditInfo;
  readonly authorCmgEntityKey: Scalars['String'];
  readonly dealLayout: DealLayout;
  readonly id: Scalars['ID'];
  readonly isReleased: Scalars['Boolean'];
  readonly isVisibleToNonSyndicate: Scalars['Boolean'];
  readonly name?: Maybe<Scalars['String']>;
  readonly releaseTimestamp?: Maybe<Scalars['Instant']>;
  readonly shareTimestamp?: Maybe<Scalars['Instant']>;
  /** Total number of final institutional allocations share quantity */
  readonly totalAllocatedShareQuantity: Scalars['Long'];
  readonly version: Scalars['String'];
};

export type FinalInstitutionalAllocationsUpdateResponse = FinalAllocations | ServiceError;

export enum FinraErrorType {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  Unauthorized = 'UNAUTHORIZED',
}

export type FinraInfo = {
  readonly __typename?: 'FinraInfo';
  readonly crdNumber?: Maybe<Scalars['Long']>;
  readonly mpid?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type FinraManagerSnapshot = {
  readonly __typename?: 'FinraManagerSnapshot';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly role: ManagerRole;
};

export type FinraOfferingManager = {
  readonly __typename?: 'FinraOfferingManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmCrdNumber?: Maybe<Scalars['String']>;
  readonly firmMpid?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type FinraStatus = {
  readonly __typename?: 'FinraStatus';
  readonly finraErrorCode?: Maybe<FinraErrorType>;
  readonly isFailure: Scalars['Boolean'];
  readonly isSuccess: Scalars['Boolean'];
};

export type Firm = {
  readonly __typename?: 'Firm';
  readonly cmgEntityKey: Scalars['String'];
  readonly crd?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly mpid?: Maybe<Scalars['String']>;
  readonly ticker?: Maybe<Scalars['String']>;
};

export enum FirmAddressType {
  Headquarters = 'HEADQUARTERS',
  Other = 'OTHER',
  Previous = 'PREVIOUS',
}

export type FirmCertificates = {
  readonly __typename?: 'FirmCertificates';
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  readonly certificates: ReadonlyArray<Certificate>;
  readonly crmInvestorId?: Maybe<Scalars['UUID']>;
  readonly firmAddress?: Maybe<Scalars['String']>;
  readonly firmCmgEntityKey?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly hasRelationship: Scalars['Boolean'];
  readonly lastUpdate?: Maybe<Scalars['Instant']>;
};

export enum FirmCertificateSource {
  Cmg = 'CMG',
  Crm = 'CRM',
}

export type FirmCertificatesReadDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FirmCertificatesReadDtoFilterInput>>;
  readonly bankInvestorKey?: InputMaybe<StringOperationFilterInput>;
  readonly certificates?: InputMaybe<ListFilterInputTypeOfCertificateReadDtoFilterInput>;
  readonly crmInvestorId?: InputMaybe<UuidOperationFilterInput>;
  readonly firmAddress?: InputMaybe<StringOperationFilterInput>;
  readonly firmCmgEntityKey?: InputMaybe<CmgEntityKeyFilterInput>;
  readonly firmName?: InputMaybe<StringOperationFilterInput>;
  readonly hasRelationship?: InputMaybe<BooleanOperationFilterInput>;
  readonly lastUpdate?: InputMaybe<InstantOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FirmCertificatesReadDtoFilterInput>>;
};

export type FirmCertificatesReadDtoSortInput = {
  readonly bankInvestorKey?: InputMaybe<SortEnumType>;
  readonly certificate51305131?: InputMaybe<CertificateReadDtoSort51305131Input>;
  readonly crmInvestorId?: InputMaybe<SortEnumType>;
  readonly firmAddress?: InputMaybe<SortEnumType>;
  readonly firmCmgEntityKey?: InputMaybe<SortEnumType>;
  readonly firmName?: InputMaybe<SortEnumType>;
  readonly hasRelationship?: InputMaybe<SortEnumType>;
  readonly lastUpdate?: InputMaybe<SortEnumType>;
};

export type FirmDistributionList = {
  readonly __typename?: 'FirmDistributionList';
  readonly cmgEntityKey: Scalars['String'];
  readonly distributionEmails: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export enum FirmIndustryType {
  /** Arbitrage */
  Ar = 'AR',
  /** Bank Investment Division */
  Bm = 'BM',
  /** Broker */
  Br = 'BR',
  /** Corporate */
  Cp = 'CP',
  /** Custodial */
  Cu = 'CU',
  /** Fund of Funds Manager */
  Ff = 'FF',
  /** Fund of Hedge Funds Manager */
  Fh = 'FH',
  /** Foundation/Endowment Manager */
  Fo = 'FO',
  /** Fund Distributor */
  Fs = 'FS',
  /** Fund */
  Fu = 'FU',
  /** Family Office */
  Fy = 'FY',
  /** Govt (Fed/Local/Agency) */
  Gv = 'GV',
  /** Hedge Fund Manager */
  Hf = 'HF',
  /** Investment Adviser */
  Ia = 'IA',
  /** Investment Banking */
  Ib = 'IB',
  /** Investment Company */
  Ic = 'IC',
  /** Insurance Company */
  In = 'IN',
  /** Mutual Fund Manager */
  Mf = 'MF',
  /** Master Ltd Part */
  Ml = 'ML',
  /** Market Maker */
  Mm = 'MM',
  /** Private Banking/Wealth Mgmt */
  Pb = 'PB',
  /** Pension Fund Manager */
  Pf = 'PF',
  /** Real Estate Manager */
  Pp = 'PP',
  /** Research Firm */
  Re = 'RE',
  /** Subsidiary Branch */
  Sb = 'SB',
  /** Stock Borrowing/Lending */
  St = 'ST',
  /** Sovereign Wealth Manager */
  Sv = 'SV',
  /** Venture Capital/Pvt Equity */
  Vc = 'VC',
}

export type FirmInfo = {
  readonly __typename?: 'FirmInfo';
  readonly firmDisplayName: Scalars['String'];
  readonly firmKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
};

export type FirmInfoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FirmInfoFilterInput>>;
  readonly firmDisplayName?: InputMaybe<StringOperationFilterInput>;
  readonly firmKey?: InputMaybe<StringOperationFilterInput>;
  readonly firmName?: InputMaybe<StringOperationFilterInput>;
  readonly firmNameAbbreviation?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FirmInfoFilterInput>>;
};

export type FirmInfoSortInput = {
  readonly firmDisplayName?: InputMaybe<SortEnumType>;
  readonly firmKey?: InputMaybe<SortEnumType>;
  readonly firmName?: InputMaybe<SortEnumType>;
  readonly firmNameAbbreviation?: InputMaybe<SortEnumType>;
};

export enum FirmLegalEntityType {
  /** Asset-Backed */
  Abs = 'ABS',
  /** Aircraft */
  Air = 'AIR',
  /** Business Association (JP) */
  Bas = 'BAS',
  /** Bank Branch */
  Bra = 'BRA',
  /** Consolidated Accounting */
  Cac = 'CAC',
  /** Commodity */
  Cmd = 'CMD',
  /** College/University */
  Col = 'COL',
  /** Corporate Assets */
  Cor = 'COR',
  /** Currency */
  Cur = 'CUR',
  /** Emp Stk Ownership Plan */
  Esp = 'ESP',
  /** Extinct */
  Ext = 'EXT',
  /** Family of Fds (VC/Pvt EQ) */
  Faf = 'FAF',
  /** Foundation/Endowment */
  Fnd = 'FND',
  /** Financing Subsidiary/SPE */
  Fns = 'FNS',
  /** ForEx */
  Frx = 'FRX',
  /** Government */
  Gov = 'GOV',
  /** Hedge Fund */
  Hed = 'HED',
  /** Holding Company */
  Hol = 'HOL',
  /** Index */
  Idx = 'IDX',
  /** Individual */
  Ind = 'IND',
  /** Joint Venture */
  Jvt = 'JVT',
  /** Broadcast Call Sign */
  Med = 'MED',
  /** Mutual Fd-Closed End */
  Muc = 'MUC',
  /** Mutual Fd-ETF */
  Mue = 'MUE',
  /** Mutual Fd-Open End */
  Mut = 'MUT',
  /** Non-Profit Organization */
  Npo = 'NPO',
  /** Operating Division */
  Opd = 'OPD',
  /** Other */
  Oth = 'OTH',
  /** Pension Fund */
  Pef = 'PEF',
  /** Real Property */
  Pro = 'PRO',
  /** Port */
  Prt = 'PRT',
  /** Public Company */
  Pub = 'PUB',
  /** Private Eq Fd/Alt Invt */
  Pvf = 'PVF',
  /** Private Company */
  Pvt = 'PVT',
  /** Ship */
  Shp = 'SHP',
  /** Sovereign Wealth Fund */
  Sov = 'SOV',
  /** Subsidiary */
  Sub = 'SUB',
  /** Trust/Trustee */
  Tru = 'TRU',
  /** Mutual Fd-Unit Invt Tr */
  Uit = 'UIT',
  /** Umbrella Fund */
  Umb = 'UMB',
  /** Venture Capital Fund */
  Ven = 'VEN',
  /** Virtual */
  Vrt = 'VRT',
}

export type FirmLimited = {
  readonly __typename?: 'FirmLimited';
  readonly cmgEntityKey: Scalars['String'];
  readonly crd?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly entityType?: Maybe<FirmLegalEntityType>;
  readonly id: Scalars['ID'];
  readonly industryType?: Maybe<FirmIndustryType>;
  readonly isOnPlatform: Scalars['Boolean'];
  readonly mpid?: Maybe<Scalars['String']>;
  readonly roles?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly status: FirmStatus;
};

export type FirmLimitedPagedList = {
  readonly __typename?: 'FirmLimitedPagedList';
  readonly data: ReadonlyArray<FirmLimited>;
  readonly pagination: PaginationData;
};

/** A segment of a collection. */
export type FirmsCertificatesCollectionSegment = {
  readonly __typename?: 'FirmsCertificatesCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<FirmCertificates>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type FirmSnapshot = {
  readonly __typename?: 'FirmSnapshot';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly offeringManagerRole?: Maybe<SyndicateWiresManagerRole>;
};

export enum FirmStatus {
  Active = 'ACTIVE',
  Defunct = 'DEFUNCT',
}

export type FollowOnPricing = {
  readonly __typename?: 'FollowOnPricing';
  readonly id: Scalars['ID'];
  readonly lastTradeBeforeFiling?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  readonly reOfferHigh?: Maybe<Scalars['Decimal']>;
  readonly reOfferLow?: Maybe<Scalars['Decimal']>;
};

export type FollowOnPricingFull = {
  readonly __typename?: 'FollowOnPricingFull';
  readonly lastTradeBeforeFiling?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  readonly reOfferHigh?: Maybe<Scalars['Decimal']>;
  readonly reOfferLow?: Maybe<Scalars['Decimal']>;
};

export type FollowOnPricingFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<FollowOnPricingFullFilterInput>>;
  readonly lastTradeBeforeFiling?: InputMaybe<DecimalOperationFilterInput>;
  readonly lastTradeBeforeLaunch?: InputMaybe<DecimalOperationFilterInput>;
  readonly lastTradeBeforeOffer?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<FollowOnPricingFullFilterInput>>;
  readonly reOfferHigh?: InputMaybe<DecimalOperationFilterInput>;
  readonly reOfferLow?: InputMaybe<DecimalOperationFilterInput>;
};

export type FollowOnPricingProfile = {
  readonly __typename?: 'FollowOnPricingProfile';
  readonly lastTradeBeforeFiling?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  readonly lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  readonly reOfferHigh?: Maybe<Scalars['Decimal']>;
  readonly reOfferLow?: Maybe<Scalars['Decimal']>;
};

/** Union of FollowOnPricing and ServiceError */
export type FollowOnPricingUnion = FollowOnPricing | ServiceError;

export type FreeFormWire = {
  readonly __typename?: 'FreeFormWire';
  readonly body: Scalars['String'];
  readonly createdAt: Scalars['Instant'];
  readonly header: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type FreeFormWireResponseUnion = FreeFormWire | ServiceError;

export type FreeFormWireValidation = WireValidationBase & {
  readonly __typename?: 'FreeFormWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<FreeFormWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum FreeFormWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type Fund = {
  readonly __typename?: 'Fund';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type FundAllocation = {
  readonly __typename?: 'FundAllocation';
  readonly fillPercentage?: Maybe<Scalars['Decimal']>;
  readonly fundId: Scalars['UUID'];
  readonly id: Scalars['ID'];
  readonly offeringId: Scalars['UUID'];
  readonly pctOfFirmAllocation?: Maybe<Scalars['Decimal']>;
  readonly shares?: Maybe<Scalars['Long']>;
};

export type FundAllocationInput = {
  readonly fillPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly fundId: Scalars['String'];
  readonly id?: InputMaybe<Scalars['String']>;
  readonly offeringId?: InputMaybe<Scalars['String']>;
  readonly pctOfFirmAllocation?: InputMaybe<Scalars['Decimal']>;
  readonly shares?: InputMaybe<Scalars['Long']>;
};

export type FundIoi = {
  readonly __typename?: 'FundIoi';
  readonly calculateShares?: Maybe<Scalars['Long']>;
  readonly dollars?: Maybe<Scalars['Decimal']>;
  readonly fundId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly limitPrice?: Maybe<Scalars['Decimal']>;
  readonly offeringId: Scalars['UUID'];
  readonly percentage?: Maybe<Scalars['Decimal']>;
  readonly pricingType: IoiPricingType;
  readonly realDemandDollars?: Maybe<Scalars['Decimal']>;
  readonly realDemandPercentage?: Maybe<Scalars['Decimal']>;
  readonly realDemandShares?: Maybe<Scalars['Long']>;
  readonly shares?: Maybe<Scalars['Long']>;
  readonly unitType: IoiUnitType;
};

export type FundIoiInput = {
  readonly calculateShares?: InputMaybe<Scalars['Decimal']>;
  readonly dollars?: InputMaybe<Scalars['Decimal']>;
  readonly fundId: Scalars['String'];
  readonly id?: InputMaybe<Scalars['String']>;
  readonly limitPrice?: InputMaybe<Scalars['Decimal']>;
  readonly offeringId?: InputMaybe<Scalars['String']>;
  readonly percentage?: InputMaybe<Scalars['Decimal']>;
  readonly pricingType: IoiPricingType;
  readonly realDemandDollars?: InputMaybe<Scalars['Decimal']>;
  readonly realDemandPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly realDemandShares?: InputMaybe<Scalars['Long']>;
  readonly shares?: InputMaybe<Scalars['Long']>;
  readonly unitType: IoiUnitType;
};

export type FundIois = {
  readonly __typename?: 'FundIois';
  readonly allocations: ReadonlyArray<FundAllocation>;
  readonly iois: ReadonlyArray<FundIoi>;
};

export type GreenShoeRecipientData = {
  readonly __typename?: 'GreenShoeRecipientData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type GreenShoeRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type GreenShoeWire = {
  readonly __typename?: 'GreenShoeWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<GreenShoeRecipientData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type GreenShoeWireInput = {
  readonly userDataForRecipients: ReadonlyArray<GreenShoeRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type GreenShoeWireResponseUnion = GreenShoeWire | ServiceError;

export type GreenShoeWireValidation = WireValidationBase & {
  readonly __typename?: 'GreenShoeWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<GreenShoeWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum GreenShoeWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  OverallotmentShares = 'OVERALLOTMENT_SHARES',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export enum GridTransitionSalesCreditReleaseStatus {
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  CancelledManual = 'CANCELLED_MANUAL',
  Released = 'RELEASED',
}

export type GrossSpreadBase = {
  readonly __typename?: 'GrossSpreadBase';
  readonly grossSpreadBase?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type GrossSpreadBaseFull = {
  readonly __typename?: 'GrossSpreadBaseFull';
  readonly grossSpreadBase?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type GrossSpreadBaseFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<GrossSpreadBaseFullFilterInput>>;
  readonly grossSpreadBase?: InputMaybe<DecimalOperationFilterInput>;
  readonly managementFeePercentage?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<GrossSpreadBaseFullFilterInput>>;
  readonly sellingConcessionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  readonly underwritingFeePercentage?: InputMaybe<DecimalOperationFilterInput>;
};

export type Group = {
  readonly __typename?: 'Group';
  readonly count: Scalars['Int'];
  readonly groupKey?: Maybe<Scalars['String']>;
};

export type HouseAccount = {
  readonly __typename?: 'HouseAccount';
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  readonly cmgEntityKey: Scalars['String'];
  readonly firmId: Scalars['UUID'];
  readonly id: Scalars['ID'];
  readonly investorAddress?: Maybe<Scalars['String']>;
  readonly investorFirmName: Scalars['String'];
};

export type IncentiveFee = {
  readonly __typename?: 'IncentiveFee';
  readonly incentiveFee?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type IncentiveFeeFull = {
  readonly __typename?: 'IncentiveFeeFull';
  readonly incentiveFee?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type IncentiveFeeFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<IncentiveFeeFullFilterInput>>;
  readonly incentiveFee?: InputMaybe<DecimalOperationFilterInput>;
  readonly managementFeePercentage?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<IncentiveFeeFullFilterInput>>;
  readonly sellingConcessionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  readonly underwritingFeePercentage?: InputMaybe<DecimalOperationFilterInput>;
};

export type IndicationAcknowledgement = {
  readonly __typename?: 'IndicationAcknowledgement';
  readonly isRevised: Scalars['Boolean'];
  readonly manager: FirmInfo;
};

export type IndicationAcknowledgementDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<IndicationAcknowledgementDtoFilterInput>>;
  readonly isRevised?: InputMaybe<BooleanOperationFilterInput>;
  readonly manager?: InputMaybe<FirmInfoFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<IndicationAcknowledgementDtoFilterInput>>;
};

export type IndicationChanged = {
  readonly __typename?: 'IndicationChanged';
  readonly batchId?: Maybe<Scalars['UUID']>;
  readonly cmgEntityKey: Scalars['String'];
  readonly eventSource?: Maybe<Scalars['String']>;
  readonly indicationId: Scalars['UUID'];
  readonly offeringId: Scalars['UUID'];
  readonly timestamp: Scalars['DateTime'];
  readonly version: Scalars['String'];
};

export enum IndicationChangeSource {
  AttestationStatusChanged = 'ATTESTATION_STATUS_CHANGED',
  OfferingPublished = 'OFFERING_PUBLISHED',
  ProspectusDeliveryStatusChanged = 'PROSPECTUS_DELIVERY_STATUS_CHANGED',
}

export type IndicationDetail = {
  readonly __typename?: 'IndicationDetail';
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly interestLevels?: Maybe<ReadonlyArray<InterestLevelDetail>>;
  readonly status?: Maybe<IndicationStatus>;
};

export type IndicationNotes = {
  readonly __typename?: 'IndicationNotes';
  readonly salesAndTrading?: Maybe<Scalars['String']>;
  readonly salesContact?: Maybe<Scalars['String']>;
  readonly salesDepartment?: Maybe<Scalars['String']>;
  readonly syndicate?: Maybe<Scalars['String']>;
};

export type IndicationNotesFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<IndicationNotesFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<IndicationNotesFilterInput>>;
  readonly salesAndTrading?: InputMaybe<StringOperationFilterInput>;
  readonly salesContact?: InputMaybe<StringOperationFilterInput>;
  readonly salesDepartment?: InputMaybe<StringOperationFilterInput>;
  readonly syndicate?: InputMaybe<StringOperationFilterInput>;
};

export type IndicationNotesSortInput = {
  readonly salesAndTrading?: InputMaybe<SortEnumType>;
  readonly salesContact?: InputMaybe<SortEnumType>;
  readonly salesDepartment?: InputMaybe<SortEnumType>;
  readonly syndicate?: InputMaybe<SortEnumType>;
};

export enum IndicationNoteType {
  SalesAndTradingNote = 'SALES_AND_TRADING_NOTE',
  SalesContactNote = 'SALES_CONTACT_NOTE',
  SalesDepartmentNote = 'SALES_DEPARTMENT_NOTE',
  SyndicateNote = 'SYNDICATE_NOTE',
}

export enum IndicationStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Duplicate = 'DUPLICATE',
}

export type IndicationStatusFilterInput = {
  readonly eq?: InputMaybe<IndicationStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<IndicationStatus>>>;
  readonly neq?: InputMaybe<IndicationStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<IndicationStatus>>>;
};

export type IndicationTrade = {
  readonly __typename?: 'IndicationTrade';
  readonly createdAt: Scalars['Instant'];
  readonly createdByUserName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly statusDetails?: Maybe<Scalars['String']>;
  readonly tradeId: Scalars['UUID'];
  readonly tradeStatus: ReleaseStatus;
  readonly tradeVersion: Scalars['String'];
};

export type IndicationTypeOperationFilterInput = {
  readonly eq?: InputMaybe<InstitutionalIndicationOrderType>;
  readonly in?: InputMaybe<ReadonlyArray<InstitutionalIndicationOrderType>>;
  readonly neq?: InputMaybe<InstitutionalIndicationOrderType>;
  readonly nin?: InputMaybe<ReadonlyArray<InstitutionalIndicationOrderType>>;
};

export type InstantOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Instant']>;
  readonly gt?: InputMaybe<Scalars['Instant']>;
  readonly gte?: InputMaybe<Scalars['Instant']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Instant']>>>;
  readonly lt?: InputMaybe<Scalars['Instant']>;
  readonly lte?: InputMaybe<Scalars['Instant']>;
  readonly neq?: InputMaybe<Scalars['Instant']>;
  readonly ngt?: InputMaybe<Scalars['Instant']>;
  readonly ngte?: InputMaybe<Scalars['Instant']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Instant']>>>;
  readonly nlt?: InputMaybe<Scalars['Instant']>;
  readonly nlte?: InputMaybe<Scalars['Instant']>;
};

export type InstitutionalDemandGridFinalAllocation = {
  readonly __typename?: 'InstitutionalDemandGridFinalAllocation';
  /**
   * The acknowledgement status of this Allocation.
   * Use `InvestorReply` instead.
   * @deprecated Deprecated. Use `InvestorReply` instead.
   */
  readonly acknowledgementStatus: AllocationAcknowledgement;
  readonly auditInfo: AuditInfo;
  readonly id: Scalars['ID'];
  /** The ID of the IOI to which this Allocation correlates. */
  readonly indicationId: Scalars['UUID'];
  readonly investorReply?: Maybe<InvestorReply>;
  /** The name of the user that submitted the investor reply, or null if the investor reply was added by another firm or is nonexistent. */
  readonly investorReplySubmitterName?: Maybe<Scalars['String']>;
  /** The number of shares for this Allocation. */
  readonly shareQuantity?: Maybe<Scalars['Long']>;
  readonly version: Scalars['String'];
};

export type InstitutionalDemandGridSummary = {
  readonly __typename?: 'InstitutionalDemandGridSummary';
  /**
   * The draft set with the corresponding allocations
   * If the account has no access to the Read Institutional Allocation it will return an empty collection.
   */
  readonly institutionalDraftAllocationSets: ReadonlyArray<InstitutionalDraftAllocationSetSummary>;
  /**
   * The final set with the corresponding allocations
   * If the account has no access to the Read Institutional Allocation Final it will return null.
   */
  readonly institutionalFinalAllocationSet?: Maybe<InstitutionalFinalAllocationSetSummary>;
  /**
   * The indication demands.
   * If the account has no access to the Read Institutional Indication it will return an empty collection.
   */
  readonly institutionalIndicationDemands: ReadonlyArray<InstitutionalIndicationDemand>;
  /** The syndicate managers. */
  readonly syndicateManagers: ReadonlyArray<SyndicateManager>;
};

export type InstitutionalDemandSummaryMetrics = {
  readonly __typename?: 'InstitutionalDemandSummaryMetrics';
  readonly averageAllocation: Scalars['Decimal'];
  readonly averageDemand: Scalars['Decimal'];
  readonly fillRate: Scalars['Decimal'];
  readonly limitToMarketRatio: Scalars['Decimal'];
  readonly marketToLimitRatio: Scalars['Decimal'];
  readonly subscription: Scalars['Decimal'];
  readonly totalActiveNonPassIndicationCount: Scalars['Int'];
  readonly totalAllocatedShares: Scalars['Long'];
  readonly totalAllocationCount: Scalars['Int'];
  readonly totalDemand: Scalars['Long'];
};

export type InstitutionalDraftAllocationSetSummary = {
  readonly __typename?: 'InstitutionalDraftAllocationSetSummary';
  /** The allocations that belong to this set. */
  readonly allocations: ReadonlyArray<DraftAllocation>;
  readonly auditInfo: AuditInfo;
  /** The CMG entity key of the author. */
  readonly authorCmgEntityKey: Scalars['String'];
  readonly dealLayout: DealLayout;
  /** This draft's ID. */
  readonly id: Scalars['ID'];
  /** This property represents if the set is default or not. */
  readonly isDefault: Scalars['Boolean'];
  /** This draft's user-defined name. */
  readonly name: Scalars['String'];
  readonly recordStatus: OrderBookRecordStatus;
  /**
   * The CMG entity keys of the managers with whom this Allocation Set has been shared.
   * This property is only present when the caller is also this Allocation Set's author.
   */
  readonly sharedWithManagerCmgEntityKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sharedWithManagers: ReadonlyArray<AllocationSetManagerShareInfo>;
  /**
   * The timestamp representing when the Allocation Set was shared with the caller.
   * This property is only present when this Allocation Set has been shared with the caller
   * (i.e., the caller is not this set's author).
   */
  readonly shareTimestamp?: Maybe<Scalars['Instant']>;
  readonly version: Scalars['String'];
};

export type InstitutionalFinalAllocationSetSummary = {
  readonly __typename?: 'InstitutionalFinalAllocationSetSummary';
  /** The allocations from the current set. */
  readonly allocations: ReadonlyArray<InstitutionalDemandGridFinalAllocation>;
  readonly auditInfo: AuditInfo;
  /** The CMG entity key of the author. */
  readonly authorCmgEntityKey: Scalars['String'];
  readonly dealLayout: DealLayout;
  readonly id: Scalars['ID'];
  /** Whether or not this Allocation set has been shared with investors. */
  readonly isReleased: Scalars['Boolean'];
  /** Name of allocation set. */
  readonly name: Scalars['String'];
  /** The timestamp representing when the finalized Allocation set was shared with the caller. */
  readonly releaseTimestamp?: Maybe<Scalars['Instant']>;
  /**
   * The IDs of the managers with whom this Allocation set has been shared.
   * This property is only present when the caller is also this Allocation set's author.
   */
  readonly sharedWithManagerCmgEntityKeys?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly sharedWithManagers: ReadonlyArray<AllocationSetManagerShareInfo>;
  /**
   * The timestamp representing when the Allocation Set was shared with the caller.
   * This property is only present when this Allocation Set has been shared with the caller
   * (i.e., the caller is not this set's author).
   */
  readonly shareTimestamp?: Maybe<Scalars['Instant']>;
  readonly totalAllocatedShareQuantity: Scalars['Long'];
  readonly version: Scalars['String'];
};

export type InstitutionalIndication = {
  readonly __typename?: 'InstitutionalIndication';
  readonly attestationStatus: AttestationStatus;
  readonly auditInfo: AuditInfo;
  readonly billingAndDeliveryAgentCmgEntityKey?: Maybe<Scalars['String']>;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * Delivery instructions will be absent for a Pass Indication.
   * Deprecated; Use Billing and Delivery endpoints instead
   * @deprecated Deprecated. This will be removed in the future.
   */
  readonly deliveryInstructions?: Maybe<DeliveryInstructions>;
  /** For a surviving indication, this property contains the IDs of its duplicate indications */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  /** Returns a Final Allocation for an Institutional Indication */
  readonly finalAllocation?: Maybe<FinalAllocation>;
  readonly id: Scalars['ID'];
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformation;
  readonly investorProspectusDeliveryStatus?: Maybe<InvestorDeliveryStatus>;
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type InstitutionalIndicationAcknowledgement = {
  readonly __typename?: 'InstitutionalIndicationAcknowledgement';
  readonly acknowledgedIndicationVersion: Scalars['String'];
  readonly auditInfo: AuditInfo;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly indicationVersion: Scalars['String'];
  readonly managerCmgEntityKey: Scalars['String'];
  readonly offeringId: Scalars['UUID'];
  readonly version: Scalars['String'];
};

export type InstitutionalIndicationAssignDupesInput = {
  readonly markAsDuplicateIds: ReadonlyArray<Scalars['UUID']>;
  readonly survivorId: Scalars['UUID'];
};

export type InstitutionalIndicationDemand = {
  readonly __typename?: 'InstitutionalIndicationDemand';
  readonly acknowledgingUser?: Maybe<Scalars['String']>;
  readonly auditInfo: AuditInfo;
  readonly billingAndDeliveryAgentCmgEntityKey?: Maybe<Scalars['String']>;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * Delivery instructions will be absent for a Pass Indication.
   * Deprecated; Use Billing and Delivery endpoints instead
   * @deprecated Deprecated. This will be removed in the future.
   */
  readonly deliveryInstructions?: Maybe<DeliveryInstructions>;
  readonly demandAtMarket: Scalars['Decimal'];
  readonly demandLevels: ReadonlyArray<InstitutionalIndicationDemandLevel>;
  readonly demandMax: Scalars['Decimal'];
  /** For a surviving indication, this property contains the IDs of its duplicate indications */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformationWithAbbreviation;
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly notes: ReadonlyArray<InstitutionalIndicationNote>;
  readonly offeringId: Scalars['UUID'];
  readonly prospectusDeliveryStatus?: Maybe<InvestorDeliveryStatus>;
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type InstitutionalIndicationDemandLevel = {
  readonly __typename?: 'InstitutionalIndicationDemandLevel';
  readonly interestInShares: Scalars['Long'];
  readonly price: Scalars['Decimal'];
};

export type InstitutionalIndicationDemandQueryInput = {
  readonly increment: Scalars['Decimal'];
  readonly max: Scalars['Decimal'];
  readonly min: Scalars['Decimal'];
  readonly referencePrice?: InputMaybe<Scalars['Decimal']>;
  readonly useNewStrategy?: InputMaybe<Scalars['Boolean']>;
};

export type InstitutionalIndicationListFiltersInput = {
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<IndicationStatus>;
  readonly type?: InputMaybe<InstitutionalIndicationOrderType>;
};

export type InstitutionalIndicationNote = {
  readonly __typename?: 'InstitutionalIndicationNote';
  readonly auditInfo: AuditInfo;
  readonly indicationId: Scalars['UUID'];
  readonly text: Scalars['String'];
  readonly type: IndicationNoteType;
  readonly version: Scalars['String'];
};

/** This is a derived attribute for DTO's to help filter what kind of demand categories this indication falls into. */
export enum InstitutionalIndicationOrderType {
  Limit = 'LIMIT',
  Market = 'MARKET',
  Pass = 'PASS',
  Scaled = 'SCALED',
}

export type InstitutionalIndicationSubmitInput = {
  readonly currencyCode?: InputMaybe<Scalars['String']>;
  readonly instrumentId?: InputMaybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevelInput>;
  /** Investor information fields must be populated to capture buy-side information. */
  readonly investorInformation?: InputMaybe<InvestorInformationInput>;
  readonly trancheId: Scalars['UUID'];
};

export type InstitutionalIndicationSubmitResponse = InstitutionalIndication | ServiceError;

export type InstitutionalIndicationUpdateInput = {
  readonly currencyCode?: InputMaybe<Scalars['String']>;
  readonly instrumentId?: InputMaybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevelInput>;
  readonly investorInformation?: InputMaybe<InvestorInformationInput>;
};

export type InstitutionalIndicationUpdateResponse = InstitutionalIndication | ServiceError;

export type InstitutionalIndicationVersion = {
  readonly __typename?: 'InstitutionalIndicationVersion';
  readonly auditInfo: AuditInfo;
  /** The list of CMG Entity Keys for Managers that cover the Investor. */
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  /**
   * For a surviving indication, this property contains the IDs of its duplicate indications
   * @deprecated Deprecated. This property will be removed in the future.
   */
  readonly duplicateIndicationIds: ReadonlyArray<Scalars['UUID']>;
  /** For a duplicate indication, this property contains the indicationId of the surviving order */
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  /** Investor information fields capture buy-side information. */
  readonly investorInformation: InvestorInformation;
  readonly isBuySideRevision: Scalars['Boolean'];
  /**
   * Describe if this Indication has been marked as a duplicate of another Indication.
   * Use `Status` instead.
   * @deprecated Deprecated. Use `status` instead.
   */
  readonly isMarkedAsDuplicate: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  readonly tranche?: Maybe<TranchePrivileged>;
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type InstrumentFull = {
  readonly __typename?: 'InstrumentFull';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly countryDisplayName?: Maybe<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly cusip?: Maybe<Scalars['String']>;
  readonly depositaryReceiptConversionDenominator?: Maybe<Scalars['Int']>;
  readonly depositaryReceiptConversionNumerator?: Maybe<Scalars['Int']>;
  readonly exchange?: Maybe<Scalars['String']>;
  readonly exchangeMic?: Maybe<ExchangeMic>;
  /** Display name for ExchangeMic */
  readonly exchangeMicDisplayName?: Maybe<Scalars['String']>;
  readonly figi?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly isDepositaryReceipt?: Maybe<Scalars['Boolean']>;
  readonly isin?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly stockSymbol?: Maybe<Scalars['String']>;
};

export type InstrumentFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<InstrumentFullFilterInput>>;
  readonly countryCode?: InputMaybe<StringOperationFilterInput>;
  readonly countryDisplayName?: InputMaybe<StringOperationFilterInput>;
  readonly currencyCode?: InputMaybe<StringOperationFilterInput>;
  readonly cusip?: InputMaybe<StringOperationFilterInput>;
  readonly depositaryReceiptConversionDenominator?: InputMaybe<IntOperationFilterInput>;
  readonly depositaryReceiptConversionNumerator?: InputMaybe<IntOperationFilterInput>;
  readonly exchange?: InputMaybe<StringOperationFilterInput>;
  readonly exchangeMic?: InputMaybe<OfferingSetupExchangeMicFilterInput>;
  readonly figi?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly isDepositaryReceipt?: InputMaybe<BooleanOperationFilterInput>;
  readonly isin?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InstrumentFullFilterInput>>;
  readonly region?: InputMaybe<StringOperationFilterInput>;
  readonly stockSymbol?: InputMaybe<StringOperationFilterInput>;
};

export type InstrumentOrderEntryProfile = {
  readonly __typename?: 'InstrumentOrderEntryProfile';
  readonly countryCode?: Maybe<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly stockSymbol?: Maybe<Scalars['String']>;
};

/**
 * A specification of demand using a given unit and quantity combination.
 * Limit Prices are optional, and if not supplied, are considered market price.
 * Interests with a Price Limit are considered Limit Interests.
 */
export type InterestLevel = {
  readonly __typename?: 'InterestLevel';
  /**
   * The InterestQuantity must be a positive non-zero number.
   * When indicating with InterestUnit of PERCENT, quantity value must be represented as a decimal representations (not whole number).
   * For example, 0.5 means 50%.
   */
  readonly interestQuantity: Scalars['Decimal'];
  readonly interestUnit: InterestLevelInterestUnit;
  /**
   * The LimitPrice is considered in conjunction with LimitType.
   * Percentage values must be represented as their decimal representations (not whole numbers). For example, 0.5 means 50%.
   * Negative values will not be accepted for this field.
   */
  readonly limitPrice?: Maybe<Scalars['Decimal']>;
  /** If a LimitPrice is specified, a LimitType is also required. */
  readonly limitType?: Maybe<InterestLevelLimitType>;
};

export type InterestLevelDetail = {
  readonly __typename?: 'InterestLevelDetail';
  readonly interestQuantity?: Maybe<Scalars['Decimal']>;
  readonly interestUnit: InterestLevelInterestUnit;
  readonly limitPrice?: Maybe<Scalars['Decimal']>;
  readonly limitType?: Maybe<InterestLevelLimitType>;
};

/**
 * A specification of demand using a given unit and quantity combination.
 * Limit Prices are optional, and if not supplied, are considered market price.
 * Interests with a Price Limit are considered Limit Interests.
 */
export type InterestLevelFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<InterestLevelFilterInput>>;
  /**
   * The InterestQuantity must be a positive non-zero number.
   * When indicating with InterestUnit of PERCENT, quantity value must be represented as a decimal representations (not whole number).
   * For example, 0.5 means 50%.
   */
  readonly interestQuantity?: InputMaybe<DecimalOperationFilterInput>;
  readonly interestUnit?: InputMaybe<InterestUnitOperationFilterInput>;
  /**
   * The LimitPrice is considered in conjunction with LimitType.
   * Percentage values must be represented as their decimal representations (not whole numbers). For example, 0.5 means 50%.
   * Negative values will not be accepted for this field.
   */
  readonly limitPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** If a LimitPrice is specified, a LimitType is also required. */
  readonly limitType?: InputMaybe<NullableOfLimitTypeOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InterestLevelFilterInput>>;
};

/**
 * A specification of demand using a given unit and quantity combination.
 * Limit Prices are optional, and if not supplied, are considered market price.
 * Interests with a Price Limit are considered Limit Interests.
 */
export type InterestLevelInput = {
  /**
   * The InterestQuantity must be a positive non-zero number.
   * When indicating with InterestUnit of PERCENT, quantity value must be represented as a decimal representations (not whole number).
   * For example, 0.5 means 50%.
   */
  readonly interestQuantity: Scalars['Decimal'];
  readonly interestUnit: InterestLevelInterestUnit;
  /**
   * The LimitPrice is considered in conjunction with LimitType.
   * Percentage values must be represented as their decimal representations (not whole numbers). For example, 0.5 means 50%.
   * Negative values will not be accepted for this field.
   */
  readonly limitPrice?: InputMaybe<Scalars['Decimal']>;
  /** If a LimitPrice is specified, a LimitType is also required. */
  readonly limitType?: InputMaybe<InterestLevelLimitType>;
};

export enum InterestLevelInterestUnit {
  Currency = 'CURRENCY',
  Percent = 'PERCENT',
  Shares = 'SHARES',
}

export enum InterestLevelLimitType {
  Discount = 'DISCOUNT',
  Premium = 'PREMIUM',
  Price = 'PRICE',
}

export type InterestLevelListFilterInput = {
  readonly all?: InputMaybe<InterestLevelFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<InterestLevelFilterInput>;
  readonly some?: InputMaybe<InterestLevelFilterInput>;
};

export type InterestUnitOperationFilterInput = {
  readonly eq?: InputMaybe<InterestLevelInterestUnit>;
  readonly in?: InputMaybe<ReadonlyArray<InterestLevelInterestUnit>>;
  readonly neq?: InputMaybe<InterestLevelInterestUnit>;
  readonly nin?: InputMaybe<ReadonlyArray<InterestLevelInterestUnit>>;
};

export enum InternalReleaseStatus {
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  CancelledManual = 'CANCELLED_MANUAL',
}

export enum InternalSalesCreditReleaseStatus {
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  CancelledManual = 'CANCELLED_MANUAL',
}

export type IntOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Int']>;
  readonly gt?: InputMaybe<Scalars['Int']>;
  readonly gte?: InputMaybe<Scalars['Int']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly lt?: InputMaybe<Scalars['Int']>;
  readonly lte?: InputMaybe<Scalars['Int']>;
  readonly neq?: InputMaybe<Scalars['Int']>;
  readonly ngt?: InputMaybe<Scalars['Int']>;
  readonly ngte?: InputMaybe<Scalars['Int']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']>;
  readonly nlte?: InputMaybe<Scalars['Int']>;
};

export type InvestorDeliveryStatus = {
  readonly __typename?: 'InvestorDeliveryStatus';
  readonly cmgEntityKey: Scalars['String'];
  readonly failedEmailCount: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly inProgressEmailCount: Scalars['Int'];
  readonly offeringId: Scalars['UUID'];
  readonly overallDeliveryStatus: ProspectusDeliveryStatus;
  readonly sentEmailCount: Scalars['Int'];
  readonly totalEmailCount: Scalars['Int'];
};

export type InvestorDesignation = {
  readonly __typename?: 'InvestorDesignation';
  readonly allocation: DesignationAllocation;
  readonly designations: ReadonlyArray<ManagerDesignation>;
  readonly id: Scalars['ID'];
  readonly isPublished?: Maybe<Scalars['Boolean']>;
  readonly version?: Maybe<Scalars['String']>;
};

export type InvestorEmployee = {
  readonly __typename?: 'InvestorEmployee';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly createdByUserName?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['Instant'];
  readonly email1?: Maybe<Scalars['String']>;
  readonly email2?: Maybe<Scalars['String']>;
  readonly employeeKey: Scalars['String'];
  readonly faxNumber1?: Maybe<Scalars['String']>;
  readonly faxNumber2?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly investorFirmId: Scalars['UUID'];
  readonly modifiedByUserName?: Maybe<Scalars['String']>;
  readonly modifiedOn: Scalars['Instant'];
  readonly name: Scalars['String'];
  readonly phoneNumber1?: Maybe<Scalars['String']>;
  readonly phoneNumber2?: Maybe<Scalars['String']>;
  readonly status: RolodexRecordStatus;
  readonly titles?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type InvestorExtendedData = {
  readonly __typename?: 'InvestorExtendedData';
  readonly compliance: ReadonlyArray<StringExtendedData>;
  readonly firmKey: Scalars['ID'];
  readonly investorComments: ReadonlyArray<StringExtendedData>;
  readonly investorRegion: ReadonlyArray<StringExtendedData>;
  readonly investorTypes: ReadonlyArray<StringExtendedData>;
  readonly otherInvestorAttributes: ReadonlyArray<StringExtendedData>;
  readonly revenues: ReadonlyArray<DecimalExtendedData>;
};

export type InvestorExtensionAttributes = {
  readonly __typename?: 'InvestorExtensionAttributes';
  readonly compliance1?: Maybe<Scalars['String']>;
  readonly compliance2?: Maybe<Scalars['String']>;
  readonly compliance3?: Maybe<Scalars['String']>;
  readonly compliance4?: Maybe<Scalars['String']>;
  readonly compliance5?: Maybe<Scalars['String']>;
  readonly investorComments1?: Maybe<Scalars['String']>;
  readonly investorComments2?: Maybe<Scalars['String']>;
  readonly investorComments3?: Maybe<Scalars['String']>;
  readonly investorComments4?: Maybe<Scalars['String']>;
  readonly investorComments5?: Maybe<Scalars['String']>;
  readonly investorCrmKey: Scalars['String'];
  readonly investorRegion1?: Maybe<Scalars['String']>;
  readonly investorRegion2?: Maybe<Scalars['String']>;
  readonly investorRegion3?: Maybe<Scalars['String']>;
  readonly investorRegion4?: Maybe<Scalars['String']>;
  readonly investorRegion5?: Maybe<Scalars['String']>;
  readonly investorType1?: Maybe<Scalars['String']>;
  readonly investorType2?: Maybe<Scalars['String']>;
  readonly investorType3?: Maybe<Scalars['String']>;
  readonly investorType4?: Maybe<Scalars['String']>;
  readonly investorType5?: Maybe<Scalars['String']>;
  readonly others1?: Maybe<Scalars['String']>;
  readonly others2?: Maybe<Scalars['String']>;
  readonly others3?: Maybe<Scalars['String']>;
  readonly others4?: Maybe<Scalars['String']>;
  readonly others5?: Maybe<Scalars['String']>;
  readonly revenue1?: Maybe<Scalars['String']>;
  readonly revenue2?: Maybe<Scalars['String']>;
  readonly revenue3?: Maybe<Scalars['String']>;
  readonly revenue4?: Maybe<Scalars['String']>;
  readonly revenue5?: Maybe<Scalars['String']>;
};

export type InvestorExtensionAttributesFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<InvestorExtensionAttributesFilterInput>>;
  readonly compliance1?: InputMaybe<StringOperationFilterInput>;
  readonly compliance2?: InputMaybe<StringOperationFilterInput>;
  readonly compliance3?: InputMaybe<StringOperationFilterInput>;
  readonly compliance4?: InputMaybe<StringOperationFilterInput>;
  readonly compliance5?: InputMaybe<StringOperationFilterInput>;
  readonly investorComments1?: InputMaybe<StringOperationFilterInput>;
  readonly investorComments2?: InputMaybe<StringOperationFilterInput>;
  readonly investorComments3?: InputMaybe<StringOperationFilterInput>;
  readonly investorComments4?: InputMaybe<StringOperationFilterInput>;
  readonly investorComments5?: InputMaybe<StringOperationFilterInput>;
  readonly investorCrmKey?: InputMaybe<StringOperationFilterInput>;
  readonly investorRegion1?: InputMaybe<StringOperationFilterInput>;
  readonly investorRegion2?: InputMaybe<StringOperationFilterInput>;
  readonly investorRegion3?: InputMaybe<StringOperationFilterInput>;
  readonly investorRegion4?: InputMaybe<StringOperationFilterInput>;
  readonly investorRegion5?: InputMaybe<StringOperationFilterInput>;
  readonly investorType1?: InputMaybe<StringOperationFilterInput>;
  readonly investorType2?: InputMaybe<StringOperationFilterInput>;
  readonly investorType3?: InputMaybe<StringOperationFilterInput>;
  readonly investorType4?: InputMaybe<StringOperationFilterInput>;
  readonly investorType5?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvestorExtensionAttributesFilterInput>>;
  readonly others1?: InputMaybe<StringOperationFilterInput>;
  readonly others2?: InputMaybe<StringOperationFilterInput>;
  readonly others3?: InputMaybe<StringOperationFilterInput>;
  readonly others4?: InputMaybe<StringOperationFilterInput>;
  readonly others5?: InputMaybe<StringOperationFilterInput>;
  readonly revenue1?: InputMaybe<StringOperationFilterInput>;
  readonly revenue2?: InputMaybe<StringOperationFilterInput>;
  readonly revenue3?: InputMaybe<StringOperationFilterInput>;
  readonly revenue4?: InputMaybe<StringOperationFilterInput>;
  readonly revenue5?: InputMaybe<StringOperationFilterInput>;
};

export type InvestorExtensionAttributesSortInput = {
  readonly compliance1?: InputMaybe<SortEnumType>;
  readonly compliance2?: InputMaybe<SortEnumType>;
  readonly compliance3?: InputMaybe<SortEnumType>;
  readonly compliance4?: InputMaybe<SortEnumType>;
  readonly compliance5?: InputMaybe<SortEnumType>;
  readonly investorComments1?: InputMaybe<SortEnumType>;
  readonly investorComments2?: InputMaybe<SortEnumType>;
  readonly investorComments3?: InputMaybe<SortEnumType>;
  readonly investorComments4?: InputMaybe<SortEnumType>;
  readonly investorComments5?: InputMaybe<SortEnumType>;
  readonly investorCrmKey?: InputMaybe<SortEnumType>;
  readonly investorRegion1?: InputMaybe<SortEnumType>;
  readonly investorRegion2?: InputMaybe<SortEnumType>;
  readonly investorRegion3?: InputMaybe<SortEnumType>;
  readonly investorRegion4?: InputMaybe<SortEnumType>;
  readonly investorRegion5?: InputMaybe<SortEnumType>;
  readonly investorType1?: InputMaybe<SortEnumType>;
  readonly investorType2?: InputMaybe<SortEnumType>;
  readonly investorType3?: InputMaybe<SortEnumType>;
  readonly investorType4?: InputMaybe<SortEnumType>;
  readonly investorType5?: InputMaybe<SortEnumType>;
  readonly others1?: InputMaybe<SortEnumType>;
  readonly others2?: InputMaybe<SortEnumType>;
  readonly others3?: InputMaybe<SortEnumType>;
  readonly others4?: InputMaybe<SortEnumType>;
  readonly others5?: InputMaybe<SortEnumType>;
  readonly revenue1?: InputMaybe<SortEnumType>;
  readonly revenue2?: InputMaybe<SortEnumType>;
  readonly revenue3?: InputMaybe<SortEnumType>;
  readonly revenue4?: InputMaybe<SortEnumType>;
  readonly revenue5?: InputMaybe<SortEnumType>;
};

export type InvestorExtensionSchema = {
  readonly __typename?: 'InvestorExtensionSchema';
  readonly category: ExtendedDataCategory;
  readonly columnName?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
};

export type InvestorFirmBasic = {
  readonly __typename?: 'InvestorFirmBasic';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly cik1?: Maybe<Scalars['String']>;
  readonly cik2?: Maybe<Scalars['String']>;
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly cmgEntityName?: Maybe<Scalars['String']>;
  readonly crd?: Maybe<Scalars['String']>;
  readonly createdByUserName?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['Instant'];
  readonly description?: Maybe<Scalars['String']>;
  readonly email1?: Maybe<Scalars['String']>;
  readonly email2?: Maybe<Scalars['String']>;
  readonly factSet?: Maybe<Scalars['String']>;
  readonly faxNumber1?: Maybe<Scalars['String']>;
  readonly faxNumber2?: Maybe<Scalars['String']>;
  readonly firmKey?: Maybe<Scalars['String']>;
  readonly firmType: RolodexFirmCategoryType;
  readonly id: Scalars['ID'];
  readonly investorSubType?: Maybe<Scalars['String']>;
  readonly investorType?: Maybe<Scalars['String']>;
  readonly isOnPlatform: Scalars['Boolean'];
  readonly lei?: Maybe<Scalars['String']>;
  readonly modifiedByUserName?: Maybe<Scalars['String']>;
  readonly modifiedOn: Scalars['Instant'];
  readonly mpid?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly phoneNumber1?: Maybe<Scalars['String']>;
  readonly phoneNumber2?: Maybe<Scalars['String']>;
  readonly status: RolodexRecordStatus;
  readonly website1?: Maybe<Scalars['String']>;
  readonly website2?: Maybe<Scalars['String']>;
};

export type InvestorFirmBasicPagedList = {
  readonly __typename?: 'InvestorFirmBasicPagedList';
  readonly data: ReadonlyArray<InvestorFirmBasic>;
  readonly pagination: PaginationData;
};

export type InvestorFirmBasicUnion = InvestorFirmBasic | ServiceError;

export type InvestorFirmEmployeeRelationship = {
  readonly __typename?: 'InvestorFirmEmployeeRelationship';
  readonly createdByUserName?: Maybe<Scalars['String']>;
  readonly createdOn: Scalars['Instant'];
  readonly modifiedByUserName?: Maybe<Scalars['String']>;
  readonly modifiedOn: Scalars['Instant'];
  readonly relationshipType: RelationshipType;
  readonly status: RolodexRecordStatus;
  readonly underwriterEmployeeId: Scalars['UUID'];
  readonly underwriterEmployeeKey?: Maybe<Scalars['String']>;
  readonly underwriterEmployeeName?: Maybe<Scalars['String']>;
};

export type InvestorFirmMappedWithCmgEntityKey = {
  readonly __typename?: 'InvestorFirmMappedWithCmgEntityKey';
  readonly cmgEntityKey: Scalars['String'];
  readonly investors: ReadonlyArray<InvestorFirmBasic>;
};

export type InvestorFirmsEmployeeRelationships = {
  readonly __typename?: 'InvestorFirmsEmployeeRelationships';
  readonly bankInvestorKey: Scalars['ID'];
  readonly relationships: ReadonlyArray<InvestorFirmEmployeeRelationship>;
};

export type InvestorInfo = {
  readonly __typename?: 'InvestorInfo';
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  readonly bankInvestorName?: Maybe<Scalars['String']>;
  readonly cmgEntityKey: Scalars['String'];
  readonly contactEmail?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  /** The display name of the Investor, calculated as BankInvestorName ?? FirmName */
  readonly displayName: Scalars['String'];
  readonly extensions?: Maybe<InvestorExtensionAttributes>;
  readonly firmName: Scalars['String'];
  readonly isOnPlatform: Scalars['Boolean'];
};

export type InvestorInfoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<InvestorInfoFilterInput>>;
  readonly bankInvestorKey?: InputMaybe<StringOperationFilterInput>;
  readonly bankInvestorName?: InputMaybe<StringOperationFilterInput>;
  readonly cmgEntityKey?: InputMaybe<StringOperationFilterInput>;
  readonly contactEmail?: InputMaybe<StringOperationFilterInput>;
  readonly contactName?: InputMaybe<StringOperationFilterInput>;
  /** The display name of the Investor, calculated as BankInvestorName ?? FirmName */
  readonly displayName?: InputMaybe<StringOperationFilterInput>;
  readonly extensions?: InputMaybe<InvestorExtensionAttributesFilterInput>;
  readonly firmName?: InputMaybe<StringOperationFilterInput>;
  readonly isOnPlatform?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<InvestorInfoFilterInput>>;
};

export type InvestorInformation = {
  readonly __typename?: 'InvestorInformation';
  /**
   * The CRM key for this investor's CmgEntityKey is looked up dynamically unless it is manually assigned and overridden.
   * If multiple matching firms are found, the oldest firm is returned.
   */
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  /** The name of this investor, corresponds to the BankInvestorKey */
  readonly bankInvestorName?: Maybe<Scalars['String']>;
  readonly cmgEntityKey: Scalars['String'];
  /** The name of this entity, corresponds to the CmgEntityKey */
  readonly cmgEntityName: Scalars['String'];
  readonly contactEmail?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly isOnPlatform: Scalars['Boolean'];
};

export type InvestorInformationInput = {
  /**
   * Submitting bank’s internal identifier for the investor.
   * This identifier value needs to be already reconciled to a CMG master record.
   */
  readonly bankInvestorKey?: InputMaybe<Scalars['String']>;
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly contactEmail?: InputMaybe<Scalars['String']>;
  readonly contactName?: InputMaybe<Scalars['String']>;
};

export type InvestorInformationWithAbbreviation = {
  readonly __typename?: 'InvestorInformationWithAbbreviation';
  /**
   * The CRM key for this investor's CmgEntityKey is looked up dynamically unless it is manually assigned and overridden.
   * If multiple matching firms are found, the oldest firm is returned.
   */
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  /** The name of this investor, corresponds to the BankInvestorKey */
  readonly bankInvestorName?: Maybe<Scalars['String']>;
  readonly cmgEntityKey: Scalars['String'];
  /** The name of this entity, corresponds to the CmgEntityKey */
  readonly cmgEntityName: Scalars['String'];
  readonly contactEmail?: Maybe<Scalars['String']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly isOnPlatform: Scalars['Boolean'];
};

export type InvestorInfoSortInput = {
  readonly bankInvestorKey?: InputMaybe<SortEnumType>;
  readonly bankInvestorName?: InputMaybe<SortEnumType>;
  readonly cmgEntityKey?: InputMaybe<SortEnumType>;
  readonly contactEmail?: InputMaybe<SortEnumType>;
  readonly contactName?: InputMaybe<SortEnumType>;
  /** The display name of the Investor, calculated as BankInvestorName ?? FirmName */
  readonly displayName?: InputMaybe<SortEnumType>;
  readonly extensions?: InputMaybe<InvestorExtensionAttributesSortInput>;
  readonly firmName?: InputMaybe<SortEnumType>;
  readonly isOnPlatform?: InputMaybe<SortEnumType>;
};

export type InvestorRelationship = {
  readonly __typename?: 'InvestorRelationship';
  readonly coveredActiveBookrunnerCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly coveredSyndicateCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  readonly hasRelationshipWithActiveBookrunner: Scalars['Boolean'];
  readonly hasRelationshipWithSyndicate: Scalars['Boolean'];
  readonly relationshipType: InvestorRelationshipType;
  readonly status: InvestorRelationshipRecordStatus;
  readonly underwriterEmployeeName: Scalars['String'];
};

export type InvestorRelationshipChanged = {
  readonly __typename?: 'InvestorRelationshipChanged';
  readonly investorFirmId: Scalars['UUID'];
  readonly investorFirmKey?: Maybe<Scalars['String']>;
  readonly relationships: ReadonlyArray<InvestorRelationship>;
};

export { InvestorRelationshipRecordStatus };

export { InvestorRelationshipType };

export type InvestorReply = {
  readonly __typename?: 'InvestorReply';
  readonly auditInfo: AuditInfo;
  /** The acknowledgement status of this Allocation. */
  readonly status: AllocationAcknowledgement;
  readonly version: Scalars['String'];
};

export enum InvestorReplyStatus {
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
}

export type InvestorTeamInput = {
  readonly cmgEntityKey: Scalars['String'];
  readonly members: ReadonlyArray<InvestorTeamMemberInput>;
};

export type InvestorTeamMemberInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
};

export type InvitationWireResponseInput = {
  readonly decisionTimeZone: Scalars['DateTimeZone'];
};

export enum IoiPricingType {
  Limit = 'limit',
  Market = 'market',
}

export enum IoiUnitType {
  Dollars = 'dollars',
  Percentage = 'percentage',
  Shares = 'shares',
}

export type Issuer = {
  readonly __typename?: 'Issuer';
  readonly address?: Maybe<Scalars['String']>;
  readonly businessDescription?: Maybe<Scalars['String']>;
  readonly cik?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lei?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly sector?: Maybe<Sector>;
  /** Display name for Sector */
  readonly sectorDisplayName?: Maybe<Scalars['String']>;
  readonly website?: Maybe<Scalars['String']>;
};

export enum IssuerAllocationReportValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalInstitutionalAllocations = 'TOTAL_INSTITUTIONAL_ALLOCATIONS',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type IssuerAllocationWire = {
  readonly __typename?: 'IssuerAllocationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type IssuerAllocationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type IssuerAllocationWireResponseUnion = IssuerAllocationWire | ServiceError;

export type IssuerAllocationWireValidation = WireValidationBase & {
  readonly __typename?: 'IssuerAllocationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<IssuerAllocationReportValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export type IssuerFull = {
  readonly __typename?: 'IssuerFull';
  readonly address?: Maybe<Scalars['String']>;
  readonly businessDescription?: Maybe<Scalars['String']>;
  readonly cik?: Maybe<Scalars['String']>;
  readonly lei?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly sector?: Maybe<Sector>;
  /** Display name for Sector */
  readonly sectorDisplayName?: Maybe<Scalars['String']>;
  readonly website?: Maybe<Scalars['String']>;
};

export type IssuerFullFilterInput = {
  readonly address?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<IssuerFullFilterInput>>;
  readonly businessDescription?: InputMaybe<StringOperationFilterInput>;
  readonly cik?: InputMaybe<StringOperationFilterInput>;
  readonly lei?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<IssuerFullFilterInput>>;
  readonly sector?: InputMaybe<SectorFilterInput>;
  readonly website?: InputMaybe<StringOperationFilterInput>;
};

export type IssuerIndicationWire = {
  readonly __typename?: 'IssuerIndicationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type IssuerIndicationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type IssuerIndicationWireResponseUnion = IssuerIndicationWire | ServiceError;

export type IssuerIndicationWireValidation = WireValidationBase & {
  readonly __typename?: 'IssuerIndicationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<IssuerIndicationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum IssuerIndicationWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type LastTnRestrictedPeriod = {
  readonly __typename?: 'LastTnRestrictedPeriod';
  readonly id: Scalars['ID'];
  readonly restrictedPeriod: TnRestrictedPeriodType;
  /** Display name for RestrictedPeriod */
  readonly restrictedPeriodDisplayName?: Maybe<Scalars['String']>;
};

export type LatestEmailStatus = {
  readonly __typename?: 'LatestEmailStatus';
  readonly isOwnedByCurrentTenant: Scalars['Boolean'];
  readonly recipientEmail?: Maybe<Scalars['String']>;
  readonly recipientName?: Maybe<Scalars['String']>;
  readonly sendRequestedAt: Scalars['Instant'];
  readonly status: EmailDeliveryStatus;
  readonly statusInfo?: Maybe<Scalars['String']>;
};

export type Lender = {
  readonly __typename?: 'Lender';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly recordStatus: RecordStatus;
};

export type LetterDealRelatedExpenses = {
  readonly __typename?: 'LetterDealRelatedExpenses';
  readonly category: ExpenseCategory;
  /** Display name for Category */
  readonly categoryDisplayName?: Maybe<Scalars['String']>;
  readonly value: Scalars['Decimal'];
};

export type ListFilterInputTypeOfAllocationDtoFilterInput = {
  readonly all?: InputMaybe<AllocationDtoFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<AllocationDtoFilterInput>;
  readonly some?: InputMaybe<AllocationDtoFilterInput>;
};

export type ListFilterInputTypeOfCertificateReadDtoFilterInput = {
  readonly all?: InputMaybe<CertificateReadDtoFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<CertificateReadDtoFilterInput>;
  readonly some?: InputMaybe<CertificateReadDtoFilterInput>;
};

export type ListFilterInputTypeOfDemandCurrencyFullFilterInput = {
  readonly all?: InputMaybe<DemandCurrencyFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<DemandCurrencyFullFilterInput>;
  readonly some?: InputMaybe<DemandCurrencyFullFilterInput>;
};

export type ListFilterInputTypeOfDemandLevelFilterInput = {
  readonly all?: InputMaybe<DemandLevelFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<DemandLevelFilterInput>;
  readonly some?: InputMaybe<DemandLevelFilterInput>;
};

export type ListFilterInputTypeOfFilingFullFilterInput = {
  readonly all?: InputMaybe<FilingFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<FilingFullFilterInput>;
  readonly some?: InputMaybe<FilingFullFilterInput>;
};

export type ListFilterInputTypeOfFirmInfoFilterInput = {
  readonly all?: InputMaybe<FirmInfoFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<FirmInfoFilterInput>;
  readonly some?: InputMaybe<FirmInfoFilterInput>;
};

export type ListFilterInputTypeOfIndicationAcknowledgementDtoFilterInput = {
  readonly all?: InputMaybe<IndicationAcknowledgementDtoFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<IndicationAcknowledgementDtoFilterInput>;
  readonly some?: InputMaybe<IndicationAcknowledgementDtoFilterInput>;
};

export type ListFilterInputTypeOfInstrumentFullFilterInput = {
  readonly all?: InputMaybe<InstrumentFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<InstrumentFullFilterInput>;
  readonly some?: InputMaybe<InstrumentFullFilterInput>;
};

export type ListFilterInputTypeOfManagerFullFilterInput = {
  readonly all?: InputMaybe<ManagerFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ManagerFullFilterInput>;
  readonly some?: InputMaybe<ManagerFullFilterInput>;
};

export type ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput = {
  readonly all?: InputMaybe<PercentagePerCmgEntityFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<PercentagePerCmgEntityFullFilterInput>;
  readonly some?: InputMaybe<PercentagePerCmgEntityFullFilterInput>;
};

export type ListFilterInputTypeOfProspectusDocumentFullFilterInput = {
  readonly all?: InputMaybe<ProspectusDocumentFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ProspectusDocumentFullFilterInput>;
  readonly some?: InputMaybe<ProspectusDocumentFullFilterInput>;
};

export type ListFilterInputTypeOfRoadshowsFullFilterInput = {
  readonly all?: InputMaybe<RoadshowsFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<RoadshowsFullFilterInput>;
  readonly some?: InputMaybe<RoadshowsFullFilterInput>;
};

export type ListFilterInputTypeOfShareHistoryFullFilterInput = {
  readonly all?: InputMaybe<ShareHistoryFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<ShareHistoryFullFilterInput>;
  readonly some?: InputMaybe<ShareHistoryFullFilterInput>;
};

export type ListFilterInputTypeOfSharesPerCmgEntityFullFilterInput = {
  readonly all?: InputMaybe<SharesPerCmgEntityFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<SharesPerCmgEntityFullFilterInput>;
  readonly some?: InputMaybe<SharesPerCmgEntityFullFilterInput>;
};

export type ListFilterInputTypeOfTrancheFullFilterInput = {
  readonly all?: InputMaybe<TrancheFullFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<TrancheFullFilterInput>;
  readonly some?: InputMaybe<TrancheFullFilterInput>;
};

export type ListFilterInputTypeOfWireMessageLimitedReadDtoFilterInput = {
  readonly all?: InputMaybe<WireMessageLimitedReadDtoFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<WireMessageLimitedReadDtoFilterInput>;
  readonly some?: InputMaybe<WireMessageLimitedReadDtoFilterInput>;
};

export type ListStringOperationFilterInput = {
  readonly all?: InputMaybe<StringOperationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<StringOperationFilterInput>;
  readonly some?: InputMaybe<StringOperationFilterInput>;
};

export type ListUuidOperationFilterInput = {
  readonly all?: InputMaybe<UuidOperationFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<UuidOperationFilterInput>;
  readonly some?: InputMaybe<UuidOperationFilterInput>;
};

export type LivePublishedOfferingListFiltersInput = {
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly includeTestOfferings?: InputMaybe<Scalars['Boolean']>;
};

export type LocalDateOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['LocalDate']>;
  readonly gt?: InputMaybe<Scalars['LocalDate']>;
  readonly gte?: InputMaybe<Scalars['LocalDate']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['LocalDate']>>>;
  readonly lt?: InputMaybe<Scalars['LocalDate']>;
  readonly lte?: InputMaybe<Scalars['LocalDate']>;
  readonly neq?: InputMaybe<Scalars['LocalDate']>;
  readonly ngt?: InputMaybe<Scalars['LocalDate']>;
  readonly ngte?: InputMaybe<Scalars['LocalDate']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['LocalDate']>>>;
  readonly nlt?: InputMaybe<Scalars['LocalDate']>;
  readonly nlte?: InputMaybe<Scalars['LocalDate']>;
};

export type Logistics = {
  readonly __typename?: 'Logistics';
  readonly endDate: Scalars['LocalDate'];
  readonly endTime: Scalars['LocalTime'];
  readonly id: Scalars['ID'];
  readonly location: Scalars['String'];
  readonly notes?: Maybe<Scalars['String']>;
  readonly startDate: Scalars['LocalDate'];
  readonly startTime: Scalars['LocalTime'];
  readonly title: Scalars['String'];
};

export type LongOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Long']>;
  readonly gt?: InputMaybe<Scalars['Long']>;
  readonly gte?: InputMaybe<Scalars['Long']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Long']>>>;
  readonly lt?: InputMaybe<Scalars['Long']>;
  readonly lte?: InputMaybe<Scalars['Long']>;
  readonly neq?: InputMaybe<Scalars['Long']>;
  readonly ngt?: InputMaybe<Scalars['Long']>;
  readonly ngte?: InputMaybe<Scalars['Long']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Long']>>>;
  readonly nlt?: InputMaybe<Scalars['Long']>;
  readonly nlte?: InputMaybe<Scalars['Long']>;
};

export type ManagementTeam = {
  readonly __typename?: 'ManagementTeam';
  readonly id: Scalars['ID'];
  readonly members: ReadonlyArray<ManagementTeamMember>;
};

export type ManagementTeamMember = {
  readonly __typename?: 'ManagementTeamMember';
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isParticipating: Scalars['Boolean'];
  readonly mobilePhone?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type ManagementTeamMemberInput = {
  readonly email: Scalars['String'];
  readonly isParticipating: Scalars['Boolean'];
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type Manager = {
  readonly __typename?: 'Manager';
  readonly affiliates: ReadonlyArray<Affiliate>;
  readonly cmgEntityKey: Scalars['String'];
  /**
   * Deprecated; Use `Underwriting Base Share Distribution` in Manager Economics instead
   * @deprecated Use `Underwriting Base Share Distribution` in Manager Economics instead
   */
  readonly economics?: Maybe<Scalars['Long']>;
  readonly finraInfo?: Maybe<FinraInfo>;
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isJuniorRole: Scalars['Boolean'];
  readonly isParticipating: Scalars['Boolean'];
  readonly recordStatus: RecordStatus;
  readonly role: ManagerRole;
};

export type ManagerBdRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
  readonly recipientDtc?: InputMaybe<Scalars['String']>;
  readonly senderDtc: Scalars['String'];
};

export type ManagerBdRecipientUserData = {
  readonly __typename?: 'ManagerBdRecipientUserData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
  readonly recipientDtc?: Maybe<Scalars['String']>;
  readonly senderDtc: Scalars['String'];
};

export type ManagerBdWire = {
  readonly __typename?: 'ManagerBdWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<ManagerBdRecipientUserData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type ManagerBdWireInput = {
  readonly userDataForRecipients: ReadonlyArray<ManagerBdRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type ManagerBdWireResponseUnion = ManagerBdWire | ServiceError;

export type ManagerBdWireValidation = WireValidationBase & {
  readonly __typename?: 'ManagerBdWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<ManagerBillingAndDeliveryWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum ManagerBillingAndDeliveryWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  ExchangeMic = 'EXCHANGE_MIC',
  IssuerName = 'ISSUER_NAME',
  OfferPrice = 'OFFER_PRICE',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type ManagerDesignation = {
  readonly __typename?: 'ManagerDesignation';
  readonly fixedEconomicsShares?: Maybe<Scalars['Long']>;
  readonly id: Scalars['ID'];
  readonly manager: DesignationManager;
  readonly sellingConcessionPercentageChanged: Scalars['Boolean'];
  readonly shares?: Maybe<Scalars['Long']>;
};

export type ManagerDtcNumber = {
  readonly __typename?: 'ManagerDtcNumber';
  readonly id: Scalars['ID'];
  readonly managerDtcNumber?: Maybe<Scalars['String']>;
  readonly managerKey: Scalars['String'];
};

export type ManagerEconomics = {
  readonly __typename?: 'ManagerEconomics';
  readonly id: Scalars['ID'];
  readonly incentiveFeeDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly managementFeeDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly overallotmentExercisedSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
  readonly sellingConcessionDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly underwritingBaseSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
  readonly underwritingFeeBaseDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  readonly underwritingTotalSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
};

export type ManagerEconomicsFull = {
  readonly __typename?: 'ManagerEconomicsFull';
  readonly incentiveFeeDistribution: ReadonlyArray<PercentagePerCmgEntityFull>;
  readonly managementFeeDistribution: ReadonlyArray<PercentagePerCmgEntityFull>;
  readonly overallotmentExercisedSharesDistribution: ReadonlyArray<SharesPerCmgEntityFull>;
  readonly sellingConcessionDistribution: ReadonlyArray<PercentagePerCmgEntityFull>;
  readonly underwritingBaseSharesDistribution: ReadonlyArray<SharesPerCmgEntityFull>;
  readonly underwritingFeeBaseDistribution: ReadonlyArray<PercentagePerCmgEntityFull>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<PercentagePerCmgEntityFull>;
};

export type ManagerEconomicsFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ManagerEconomicsFullFilterInput>>;
  readonly incentiveFeeDistribution?: InputMaybe<ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput>;
  readonly managementFeeDistribution?: InputMaybe<ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ManagerEconomicsFullFilterInput>>;
  readonly overallotmentExercisedSharesDistribution?: InputMaybe<ListFilterInputTypeOfSharesPerCmgEntityFullFilterInput>;
  readonly sellingConcessionDistribution?: InputMaybe<ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput>;
  readonly underwritingBaseSharesDistribution?: InputMaybe<ListFilterInputTypeOfSharesPerCmgEntityFullFilterInput>;
  readonly underwritingFeeBaseDistribution?: InputMaybe<ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput>;
  readonly underwritingFeeTotalDistribution?: InputMaybe<ListFilterInputTypeOfPercentagePerCmgEntityFullFilterInput>;
};

export type ManagerEconomicsProfile = {
  readonly __typename?: 'ManagerEconomicsProfile';
  readonly underwritingBaseSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
};

export enum ManagerErrorType {
  DistributionList = 'DISTRIBUTION_LIST',
  Economics = 'ECONOMICS',
  UnderwritingTotalShares = 'UNDERWRITING_TOTAL_SHARES',
}

export type ManagerExpenses = {
  readonly __typename?: 'ManagerExpenses';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly managerName: Scalars['String'];
  readonly managerRole: ExpensesManagerRole;
  /** Display name for ManagerRole */
  readonly managerRoleDisplayName?: Maybe<Scalars['String']>;
  readonly miscellaneous?: Maybe<Scalars['Decimal']>;
  readonly reimbursement?: Maybe<Scalars['Decimal']>;
  readonly technologyAndData?: Maybe<Scalars['Decimal']>;
  readonly travelAndMeals?: Maybe<Scalars['Decimal']>;
};

export type ManagerFull = {
  readonly __typename?: 'ManagerFull';
  readonly cmgEntityKey: Scalars['String'];
  readonly entitlements?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly isJuniorRole?: Maybe<Scalars['Boolean']>;
  readonly isParticipating: Scalars['Boolean'];
  readonly role: ManagerRole;
};

export type ManagerFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ManagerFullFilterInput>>;
  readonly cmgEntityKey?: InputMaybe<StringOperationFilterInput>;
  readonly firmName?: InputMaybe<StringOperationFilterInput>;
  readonly firmNameAbbreviation?: InputMaybe<StringOperationFilterInput>;
  readonly isJuniorRole?: InputMaybe<BooleanOperationFilterInput>;
  readonly isParticipating?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ManagerFullFilterInput>>;
  readonly role?: InputMaybe<ManagerRoleFilterInput>;
};

export type ManagerLimited = {
  readonly __typename?: 'ManagerLimited';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly role: ManagerRole;
};

export type ManagerProfile = {
  readonly __typename?: 'ManagerProfile';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly role: ManagerRole;
};

export type ManagerResponsibilities = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: Scalars['ID'];
  readonly leftLead?: Maybe<Scalars['String']>;
  readonly logisticsAgent?: Maybe<Scalars['String']>;
  readonly settlementAgent?: Maybe<Scalars['String']>;
  readonly stabilizationAgent?: Maybe<Scalars['String']>;
};

export type ManagerResponsibilitiesFull = {
  readonly __typename?: 'ManagerResponsibilitiesFull';
  readonly leftLead?: Maybe<Scalars['String']>;
  readonly logisticsAgent?: Maybe<Scalars['String']>;
  readonly settlementAgent?: Maybe<Scalars['String']>;
  readonly stabilizationAgent?: Maybe<Scalars['String']>;
};

export type ManagerResponsibilitiesFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ManagerResponsibilitiesFullFilterInput>>;
  readonly leftLead?: InputMaybe<StringOperationFilterInput>;
  readonly logisticsAgent?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ManagerResponsibilitiesFullFilterInput>>;
  readonly settlementAgent?: InputMaybe<StringOperationFilterInput>;
  readonly stabilizationAgent?: InputMaybe<StringOperationFilterInput>;
};

export type ManagerResponsibilitiesInput = {
  readonly leftLead?: InputMaybe<Scalars['String']>;
  readonly logisticsAgent?: InputMaybe<Scalars['String']>;
  readonly settlementAgent?: InputMaybe<Scalars['String']>;
  readonly stabilizationAgent?: InputMaybe<Scalars['String']>;
};

export type ManagerResponsibilitiesProfile = {
  readonly __typename?: 'ManagerResponsibilitiesProfile';
  readonly leftLead?: Maybe<Scalars['String']>;
  readonly logisticsAgent?: Maybe<Scalars['String']>;
  readonly settlementAgent?: Maybe<Scalars['String']>;
  readonly stabilizationAgent?: Maybe<Scalars['String']>;
};

export enum ManagerResponsibility {
  LeftLead = 'LEFT_LEAD',
  LogisticsAgent = 'LOGISTICS_AGENT',
  SettlementAgent = 'SETTLEMENT_AGENT',
  StabilizationAgent = 'STABILIZATION_AGENT',
}

export type ManagerRetailRetentionFees = {
  readonly __typename?: 'ManagerRetailRetentionFees';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly payment?: Maybe<Scalars['Long']>;
  readonly retention?: Maybe<Scalars['Long']>;
};

export type ManagerRevisedUnderwritingCommitment = {
  readonly __typename?: 'ManagerRevisedUnderwritingCommitment';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly revisedUnderwritingCommitment?: Maybe<Scalars['Long']>;
};

export enum ManagerRole {
  ActiveBookrunner = 'ACTIVE_BOOKRUNNER',
  Bookrunner = 'BOOKRUNNER',
  CoLead = 'CO_LEAD',
  CoManager = 'CO_MANAGER',
  GlobalCoordinator = 'GLOBAL_COORDINATOR',
  SellingGroupMember = 'SELLING_GROUP_MEMBER',
  Underwriter = 'UNDERWRITER',
}

export type ManagerRoleFilterInput = {
  readonly eq?: InputMaybe<ManagerRole>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<ManagerRole>>>;
  readonly neq?: InputMaybe<ManagerRole>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<ManagerRole>>>;
};

export type ManagerValidation = {
  readonly __typename?: 'ManagerValidation';
  readonly cmgEntityKey: Scalars['String'];
  readonly fields: ReadonlyArray<ManagerErrorType>;
};

export type ManualAdjustment = {
  readonly __typename?: 'ManualAdjustment';
  readonly description?: Maybe<Scalars['String']>;
  readonly managerCmgEntityKey: Scalars['ID'];
  readonly manualAdjustment?: Maybe<Scalars['Decimal']>;
};

export type Marketing = {
  readonly __typename?: 'Marketing';
  readonly callDetails?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly managementTeam?: Maybe<Scalars['String']>;
};

export type MarketingFull = {
  readonly __typename?: 'MarketingFull';
  readonly callDetails?: Maybe<Scalars['String']>;
  readonly managementTeam?: Maybe<Scalars['String']>;
};

export type MarketingFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<MarketingFullFilterInput>>;
  readonly callDetails?: InputMaybe<StringOperationFilterInput>;
  readonly managementTeam?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<MarketingFullFilterInput>>;
};

export type MarketingInput = {
  readonly callDetails?: InputMaybe<Scalars['String']>;
  readonly managementTeam?: InputMaybe<Scalars['String']>;
};

export enum MarketTiming {
  IntraDay = 'INTRA_DAY',
  PostClose = 'POST_CLOSE',
  PreOpen = 'PRE_OPEN',
}

export type MarketTimingFilterInput = {
  readonly eq?: InputMaybe<MarketTiming>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<MarketTiming>>>;
  readonly neq?: InputMaybe<MarketTiming>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<MarketTiming>>>;
};

export type MdlAllocation = {
  readonly __typename?: 'MdlAllocation';
  readonly fillPercentage?: Maybe<Scalars['Decimal']>;
  readonly id: Scalars['ID'];
  readonly investment?: Maybe<Scalars['Decimal']>;
  readonly ioiType: MdlAllocationIoiType;
  readonly lastModified: MdlAllocationLastModified;
  readonly offeringId: Scalars['UUID'];
  readonly ranking: MdlAllocationRanking;
  readonly salesConcession?: Maybe<Scalars['Decimal']>;
  readonly shares?: Maybe<Scalars['Long']>;
};

export type MdlAllocationInput = {
  readonly shares?: InputMaybe<Scalars['Long']>;
};

export enum MdlAllocationIoiType {
  Normal = 'NORMAL',
  Pass = 'PASS',
  RegM = 'REG_M',
}

export type MdlAllocationLastModified = {
  readonly __typename?: 'MdlAllocationLastModified';
  readonly email?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly timestamp?: Maybe<Scalars['DateTime']>;
};

export enum MdlAllocationRanking {
  NotIndicated = 'NOT_INDICATED',
  Over_50 = 'OVER_50',
  Top_10 = 'TOP_10',
  Top_15 = 'TOP_15',
  Top_25 = 'TOP_25',
  Top_5 = 'TOP_5',
  Top_50 = 'TOP_50',
}

export type MdlAllocationRankingResponse = {
  readonly __typename?: 'MdlAllocationRankingResponse';
  readonly id: Scalars['ID'];
  readonly ranking: MdlAllocationRanking;
};

export type MdlAllocationResponse = {
  readonly __typename?: 'MdlAllocationResponse';
  readonly allocation?: Maybe<MdlAllocation>;
  readonly id: Scalars['ID'];
};

export type MdlCommunicatedDemandIndications = {
  readonly __typename?: 'MdlCommunicatedDemandIndications';
  readonly id: Scalars['ID'];
  readonly indications: ReadonlyArray<MdlIndication>;
};

export type MdlIndication = {
  readonly __typename?: 'MdlIndication';
  /** Calculated conversion to a number of shares when the indication is saved as a $USD or % of the total base offering size. */
  readonly calculateShares?: Maybe<Scalars['Long']>;
  /** Indications entered as a $USD amount. */
  readonly dollars?: Maybe<Scalars['Decimal']>;
  /** Unique internal identifier relating to the indication entered. */
  readonly id: Scalars['UUID'];
  /** Denotes a premium or a discount percentage */
  readonly limitPercentage?: Maybe<Scalars['Decimal']>;
  /** Offering price limit as submitted in an indication of interest. */
  readonly limitPrice?: Maybe<Scalars['Decimal']>;
  /** Denotes whether an indication of interest has limit prices specified in dollars or as a premium or a discount percentage */
  readonly limitType: MdlIndicationLimitType;
  /** Unique internal identifier for a specific offering. */
  readonly offeringId: Scalars['UUID'];
  /** Indications entered as a % of the total base offering size. */
  readonly percentage?: Maybe<Scalars['Decimal']>;
  /** Denotes whether an indication of interest is placed as an at market or limit price. */
  readonly pricingType: MdlIndicationPricingType;
  /** Firm level demand if different from indication of interest submitted in $USD amounts. */
  readonly realDemandDollars?: Maybe<Scalars['Decimal']>;
  /** Firm level demand if different from indication of interest submitted as a % of the total base offering size. */
  readonly realDemandPercentage?: Maybe<Scalars['Decimal']>;
  /** Firm level demand if different from indication of interest submitted as a number of shares. */
  readonly realDemandShares?: Maybe<Scalars['Long']>;
  /** Indications entered as a number of shares quantity. */
  readonly shares?: Maybe<Scalars['Long']>;
  /** Denotes whether an indication of interest is placed as a $USD amount, % of deal or number of shares. */
  readonly unitType: MdlIndicationUnitType;
};

export type MdlIndicationInput = {
  /** Indications entered as a $USD amount. */
  readonly dollars?: InputMaybe<Scalars['Decimal']>;
  /** Denotes a premium or a discount percentage */
  readonly limitPercentage?: InputMaybe<Scalars['Decimal']>;
  /** Offering price limit as submitted in an indication of interest. */
  readonly limitPrice?: InputMaybe<Scalars['Decimal']>;
  /** Denotes whether an indication of interest has limit prices specified in dollars or as a premium or a discount percentage */
  readonly limitType: MdlIndicationLimitType;
  /** Indications entered as a % of the total base offering size. */
  readonly percentage?: InputMaybe<Scalars['Decimal']>;
  /** Denotes whether an indication of interest is placed as an at market or limit price. */
  readonly pricingType: MdlIndicationPricingType;
  /** Firm level demand if different from indication of interest submitted in $USD amounts. */
  readonly realDemandDollars?: InputMaybe<Scalars['Decimal']>;
  /** Firm level demand if different from indication of interest submitted as a % of the total base offering size. */
  readonly realDemandPercentage?: InputMaybe<Scalars['Decimal']>;
  /** Firm level demand if different from indication of interest submitted as a number of shares. */
  readonly realDemandShares?: InputMaybe<Scalars['Long']>;
  /** Indications entered as a number of shares quantity. */
  readonly shares?: InputMaybe<Scalars['Long']>;
  /** Denotes whether an indication of interest is placed as a $USD amount, % of deal or number of shares. */
  readonly unitType: MdlIndicationUnitType;
};

export enum MdlIndicationLimitType {
  Discount = 'DISCOUNT',
  Null = 'NULL',
  Premium = 'PREMIUM',
  Price = 'PRICE',
}

export enum MdlIndicationPricingType {
  Limit = 'LIMIT',
  Market = 'MARKET',
}

export type MdlIndications = {
  readonly __typename?: 'MdlIndications';
  readonly indications: ReadonlyArray<MdlIndication>;
};

export enum MdlIndicationUnitType {
  Dollars = 'DOLLARS',
  Percentage = 'PERCENTAGE',
  Shares = 'SHARES',
}

export type MdlInternalDemandIndications = {
  readonly __typename?: 'MdlInternalDemandIndications';
  readonly id: Scalars['ID'];
  readonly indications: ReadonlyArray<MdlIndication>;
};

export type MdlOfferingNote = {
  readonly __typename?: 'MdlOfferingNote';
  readonly id: Scalars['ID'];
  readonly lastEditedAt: Scalars['Instant'];
  readonly lastEditedBy: Scalars['String'];
  readonly note: Scalars['String'];
  readonly subject: OfferingNoteSubject;
  /** Display name for Subject */
  readonly subjectDisplayName?: Maybe<Scalars['String']>;
};

export type MdlOfferingNotes = {
  readonly __typename?: 'MdlOfferingNotes';
  readonly id: Scalars['ID'];
  readonly notes: ReadonlyArray<MdlOfferingNote>;
};

export type Meeting = {
  readonly __typename?: 'Meeting';
  readonly investors: ReadonlyArray<EventParticipantsTeam>;
  readonly meetingDetails: MeetingDetails;
  readonly participantsTeam: ReadonlyArray<EventParticipantsTeam>;
};

export type MeetingDetails = {
  readonly __typename?: 'MeetingDetails';
  readonly address?: Maybe<Scalars['String']>;
  readonly date: Scalars['LocalDate'];
  readonly dialInDetails?: Maybe<Scalars['String']>;
  readonly endTime: Scalars['LocalTime'];
  readonly format: MeetingFormat;
  readonly hostFirmCmgEntityKey?: Maybe<Scalars['String']>;
  readonly hostPersonEmail?: Maybe<Scalars['String']>;
  readonly hostPersonFullName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isPublic: Scalars['Boolean'];
  readonly location?: Maybe<Scalars['String']>;
  readonly publicNotes?: Maybe<Scalars['String']>;
  readonly roomName?: Maybe<Scalars['String']>;
  readonly startTime: Scalars['LocalTime'];
  readonly status: EventStatus;
  readonly timezone: Scalars['DateTimeZone'];
  readonly title: Scalars['String'];
  readonly type: MeetingType;
  readonly venue?: Maybe<Scalars['String']>;
};

export enum MeetingFormat {
  Hybrid = 'HYBRID',
  InPerson = 'IN_PERSON',
  Virtual = 'VIRTUAL',
}

export type MeetingInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly capacity?: InputMaybe<Scalars['Int']>;
  readonly date: Scalars['LocalDate'];
  readonly dialInDetails?: InputMaybe<Scalars['String']>;
  readonly endTime: Scalars['LocalTime'];
  readonly format: MeetingFormat;
  readonly hostFirmCmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly hostPersonEmail?: InputMaybe<Scalars['String']>;
  readonly hostPersonFullName?: InputMaybe<Scalars['String']>;
  readonly isPublic: Scalars['Boolean'];
  readonly location?: InputMaybe<Scalars['String']>;
  readonly publicNotes?: InputMaybe<Scalars['String']>;
  readonly roomName?: InputMaybe<Scalars['String']>;
  readonly startTime: Scalars['LocalTime'];
  readonly timezone: Scalars['DateTimeZone'];
  readonly title: Scalars['String'];
  readonly type: MeetingType;
  readonly venue?: InputMaybe<Scalars['String']>;
};

export enum MeetingType {
  FourOnOne = 'FOUR_ON_ONE',
  LargeGroup = 'LARGE_GROUP',
  OneOnOne = 'ONE_ON_ONE',
  SmallGroup = 'SMALL_GROUP',
  ThreeOnOne = 'THREE_ON_ONE',
  TwoOnOne = 'TWO_ON_ONE',
}

export type MemberFirm = {
  readonly __typename?: 'MemberFirm';
  readonly affiliateMemberId?: Maybe<Scalars['UUID']>;
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly excusedOrPassive: MemberFirmType;
  /** Display name for ExcusedOrPassive */
  readonly excusedOrPassiveDisplayName?: Maybe<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly ownerCmgEntityKey?: Maybe<Scalars['String']>;
};

export type MemberFirmInput = {
  readonly affiliateMemberId?: InputMaybe<Scalars['UUID']>;
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly excusedOrPassive: MemberFirmType;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid?: InputMaybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
};

export enum MemberFirmType {
  Excused = 'EXCUSED',
  Passive = 'PASSIVE',
}

export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Accept sent Syndicate Wire invitation */
  readonly acceptWire: WireResponse;
  /** Acknowledge a Covered Indication */
  readonly acknowledgeCoveredIndication: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  /** Acknowledge a Final Institutional Allocation as Covered Sales and Trading */
  readonly acknowledgeCoveredInstitutionalIndication: CoveredInstitutionalIndication;
  /** Acknowledge an Institutional Indication */
  readonly acknowledgeInstitutionalIndication: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  readonly acknowledgeMyFinalInstitutionalAllocation: FinalAllocation;
  readonly activateInternalDemandOffering: MdlAllocationResponse;
  /** Add/Assign Currency to a Tranche */
  readonly addCurrencyToTranche: Tranche;
  /** Add/Assign Instrument to a Tranche */
  readonly addInstrumentToTranche: Tranche;
  readonly assignCompany: Roadshow;
  readonly assignContacts: ContactCollection;
  readonly assignCoveredContacts: ContactCollection;
  /** Deletes/Removes Institutional Indication Acknowledgement. */
  readonly assignCoveredInvestorKey: UpdateInvestorCrmKeyResult;
  readonly assignDealTeamMembersToRoadshow: ReadonlyArray<DealTeamMember>;
  readonly assignDesignationToManager: DesignationsGrid;
  readonly assignFinalAllocationSetVisibility: FinalInstitutionalAllocationSet;
  readonly assignIndicationNote: ReadonlyArray<Note>;
  /** Assign private CRM investor key */
  readonly assignInvestorKey: UpdateInvestorCrmKeyResult;
  /** Assigns a Buy Side Firm's Institutional Indication for an On-Platform Offering */
  readonly assignMyInstitutionalIndication: AssignMyInstitutionalIndicationResponseUnion;
  readonly assignRetailDemand: TotalRetailSummaryItemDemand;
  readonly assignRetailRetention: TotalRetailSummaryItemRetention;
  readonly batchUpdateBndAgent: BulkAssignBndAgentPayload;
  readonly bulkAssignBndAgent: BulkAssignBndAgentPayload;
  readonly bulkUpdateAllocations: UpdatedAllocationSets;
  readonly calculateFeesDistributions: CalculatedPercentageDistributions;
  readonly calculateSharesDistributions: CalculateShareDistributions;
  /** Cancel meeting */
  readonly cancelMeeting: MeetingDetails;
  /** Confirm meeting */
  readonly confirmMeeting: MeetingDetails;
  readonly createCompany: Company;
  readonly createDealTeam: DealTeam;
  /** Create new or activate existing deleted Delivery Instrument */
  readonly createDeliveryInstrument: DeliveryInstrumentUnion;
  readonly createDemandCurrency: DemandCurrency;
  /** Create a DesignationWire */
  readonly createDesignationWire: DesignationWireResponseUnion;
  readonly createDraftAllocationSet: DraftAllocationSet;
  readonly createExpensesRevision: Expenses;
  readonly createFiling: FilingUnion;
  readonly createFinalInstitutionalAllocationSet: FinalInstitutionalAllocationSet;
  /** Create a FreeFormWire */
  readonly createFreeFormWire: FreeFormWireResponseUnion;
  /** Create a GreenShoeWire */
  readonly createGreenShoeWire: GreenShoeWireResponseUnion;
  readonly createInvestorFirmBasic: InvestorFirmBasicUnion;
  /** Create a IssuerAllocationWire */
  readonly createIssuerAllocationWire: IssuerAllocationWireResponseUnion;
  /** Create a IssuerIndicationWire */
  readonly createIssuerIndicationWire: IssuerIndicationWireResponseUnion;
  readonly createLogistics: Logistics;
  /** Add a new Manager or update existing deleted Manager */
  readonly createManager: Manager;
  /** Create a ManagerBdWire */
  readonly createManagerBdWire: ManagerBdWireResponseUnion;
  readonly createMeeting: MeetingDetails;
  readonly createMyRetailBroker: RetailBrokerDealer;
  /** Create a NonSyndicateFreeFormWire */
  readonly createNonSyndicateFreeFormWire: NonSyndicateFreeFormWireResponseUnion;
  /** Create a NonSyndicatePaymentWire */
  readonly createNonSyndicatePaymentWire: NonSyndicatePaymentWireResponseUnion;
  /** Create a NonSyndicateSellingGroupInvitationWire */
  readonly createNonSyndicateSellingGroupInvitationWire: NonSyndicateSellingGroupInvitationWireResponseUnion;
  /** Create a NonSyndicateSellingGroupTermsRetentionWire */
  readonly createNonSyndicateSellingGroupTermsRetentionWire: NonSyndicateSellingGroupTermsRetentionWireResponseUnion;
  /** Create a NonSyndicateTerminationWire */
  readonly createNonSyndicateTerminationWire: NonSyndicateTerminationWireResponseUnion;
  /** Create an Offering Draft */
  readonly createOfferingDraft: OfferingUnion;
  readonly createOfferingNote: MdlOfferingNotes;
  readonly createOrUpdateDemandCurrencies?: Maybe<CreateOrUpdateDemandCurrenciesPayload>;
  readonly createOrUpdateStabilization: Stabilization;
  /** Create a PaymentWire */
  readonly createPaymentWire: PaymentWireResponseUnion;
  /** Create a PotAccountWire */
  readonly createPotAccountWire: PotAccountWireResponseUnion;
  /** Create a PotAllocationWire */
  readonly createPotAllocationWire: PotAllocationWireResponseUnion;
  /** Create a PricingTermsRetentionWire */
  readonly createPricingTermsRetentionWire: PricingTermsRetentionWireResponseUnion;
  /** Create a PricingTermsWire */
  readonly createPricingTermsWire: PricingTermsWireResponseUnion;
  /** Create a RegMWire */
  readonly createRegMWire: RegMWireResponseUnion;
  /** Create a RetentionWire */
  readonly createRetentionWire: RetentionWireResponseUnion;
  readonly createRoadshow: Roadshow;
  readonly createRoadshowManagementTeamMember: ManagementTeamMember;
  /** Create Roadshow Schedules for an offering */
  readonly createRoadshowSchedule: RoadshowSchedule;
  /** Create a SellingGroupInvitationWire */
  readonly createSellingGroupInvitationWire: SellingGroupInvitationWireResponseUnion;
  /** Create a SellingGroupTermsWire */
  readonly createSellingGroupTermsWire: SellingGroupTermsWireResponseUnion;
  /** Create a TerminationWire */
  readonly createTerminationWire: TerminationWireResponseUnion;
  /** Create a UnderwriterInvitationWire */
  readonly createUnderwriterInvitationWire: UnderwriterInvitationWireResponseUnion;
  /** Decline sent Syndicate Wire invitation */
  readonly declineWire: WireResponse;
  /** Delete/Unassign Currency from a Tranche */
  readonly deleteCurrencyFromTranche: Tranche;
  readonly deleteDealTeam: Scalars['ID'];
  /** Delete an Instrument by assigning a Record Status of Deleted */
  readonly deleteDeliveryInstrument: Scalars['ID'];
  /** Marks existing Demand Currency as DELETED */
  readonly deleteDemandCurrency: Scalars['ID'];
  /** Delete a DesignationWire */
  readonly deleteDesignationWire: DeleteDesignationWireResponseUnion;
  readonly deleteDraftInstitutionalAllocationSet: DraftAllocationSet;
  readonly deleteFiling: Scalars['ID'];
  /** Delete a FreeFormWire */
  readonly deleteFreeFormWire: DeleteFreeFormWireResponseUnion;
  /** Delete a GreenShoeWire */
  readonly deleteGreenShoeWire: DeleteGreenShoeWireResponseUnion;
  /** Deletes/Unassigns Billing and Delivery Agent. */
  readonly deleteInstitutionalIndicationBillingAndDeliveryAgent: DeleteBillingAndDeliveryAgentResult;
  /** Delete/Unassign Instrument from a Tranche */
  readonly deleteInstrumentFromTranche: TrancheUnion;
  /** Delete a IssuerAllocationWire */
  readonly deleteIssuerAllocationWire: DeleteIssuerAllocationWireResponseUnion;
  /** Delete a IssuerIndicationWire */
  readonly deleteIssuerIndicationWire: DeleteIssuerIndicationWireResponseUnion;
  readonly deleteLogistics: Scalars['ID'];
  /** Set Manager status to Deleted */
  readonly deleteManager: DeleteManager;
  /** Delete a ManagerBdWire */
  readonly deleteManagerBdWire: DeleteManagerBdWireResponseUnion;
  readonly deleteMeeting: Scalars['ID'];
  readonly deleteMyRetailBroker: DeleteMyRetailDemandBroker;
  /** Delete a NonSyndicateFreeFormWire */
  readonly deleteNonSyndicateFreeFormWire: DeleteNonSyndicateFreeFormWireResponseUnion;
  /** Delete a NonSyndicatePaymentWire */
  readonly deleteNonSyndicatePaymentWire: DeleteNonSyndicatePaymentWireResponseUnion;
  /** Delete a NonSyndicateSellingGroupInvitationWire */
  readonly deleteNonSyndicateSellingGroupInvitationWire: DeleteNonSyndicateSellingGroupInvitationWireResponseUnion;
  /** Delete a NonSyndicateSellingGroupTermsRetentionWire */
  readonly deleteNonSyndicateSellingGroupTermsRetentionWire: DeleteNonSyndicateSellingGroupTermsRetentionWireResponseUnion;
  /** Delete a NonSyndicateTerminationWire */
  readonly deleteNonSyndicateTerminationWire: DeleteNonSyndicateTerminationWireResponseUnion;
  readonly deleteOfferingNote: MdlOfferingNotes;
  /** Delete a PaymentWire */
  readonly deletePaymentWire: DeletePaymentWireResponseUnion;
  /** Delete a PotAccountWire */
  readonly deletePotAccountWire: DeletePotAccountWireResponseUnion;
  /** Delete a PotAllocationWire */
  readonly deletePotAllocationWire: DeletePotAllocationWireResponseUnion;
  /** Delete a PricingTermsRetentionWire */
  readonly deletePricingTermsRetentionWire: DeletePricingTermsRetentionWireResponseUnion;
  /** Delete a PricingTermsWire */
  readonly deletePricingTermsWire: DeletePricingTermsWireResponseUnion;
  /** Delete a RegMWire */
  readonly deleteRegMWire: DeleteRegMWireResponseUnion;
  /** Delete a RetentionWire */
  readonly deleteRetentionWire: DeleteRetentionWireResponseUnion;
  readonly deleteRoadshowManagementTeamMember: Scalars['ID'];
  readonly deleteRoadshowSchedule: DeleteRoadshowSchedule;
  /** Delete a SellingGroupInvitationWire */
  readonly deleteSellingGroupInvitationWire: DeleteSellingGroupInvitationWireResponseUnion;
  /** Delete a SellingGroupTermsWire */
  readonly deleteSellingGroupTermsWire: DeleteSellingGroupTermsWireResponseUnion;
  /** Delete a TerminationWire */
  readonly deleteTerminationWire: DeleteTerminationWireResponseUnion;
  /** Delete a UnderwriterInvitationWire */
  readonly deleteUnderwriterInvitationWire: DeleteUnderwriterInvitationWireResponseUnion;
  /** Discard a Draft or Published Offering */
  readonly discardOffering: OfferingUnion;
  readonly duplicateDraftAllocationSet: DraftAllocationSet;
  readonly importCompany: Company;
  /** Batch - Mark Institutional Indications as duplicate */
  readonly markIndicationsAsDuplicate: InstitutionalIndication;
  /** Open meeting to sales */
  readonly openMeetingToSales: MeetingDetails;
  /** Pass an Offering with existing Covered Institutional Indication */
  readonly passCoveredInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  /** Pass Institutional Indication */
  readonly passInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  readonly passInternalDemandOffering: MdlAllocationResponse;
  readonly passOfferingWithoutCoveredInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  /** Buy Side Firm has opted to pass on an On-Platform Offering */
  readonly passOnOffering: PassOnOfferingResponseUnion;
  /** Pass on Offering without Institutional Indication */
  readonly passOnOfferingWithoutInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  readonly promoteDraftAllocationSetToDefault: DraftAllocationSet;
  readonly publishDesignations: EmptyMutationResponse;
  readonly publishOffering: OfferingUnion;
  readonly publishRoadshowInternally: Roadshow;
  readonly regMInternalDemandOffering: MdlAllocationResponse;
  readonly releaseFinalInstitutionalAllocationSet: FinalInstitutionalAllocationSet;
  readonly releaseRetailRetention: ReadonlyArray<ReleasedRetailRetention>;
  readonly releaseSalesCredits: SalesCreditGrid;
  readonly releaseTradesBatch: ReadonlyArray<TradeGridItem>;
  readonly resendProspectusAsSalesAndTrading: ResendProspectusResponseUnion;
  readonly resendProspectusAsSyndicate: ResendProspectusResponseUnion;
  /** Re-submits DS filing */
  readonly resubmitDSFiling: DsFiling;
  /** Re-submits NOI filing */
  readonly resubmitNOIFiling: NoiFiling;
  /** Re-submits RPN filing */
  readonly resubmitRPNFiling: RpnFiling;
  /** Re-submits TN filing */
  readonly resubmitTNFiling: TnFiling;
  /** Re-submits USDTN filing */
  readonly resubmitUSDTNFiling: UsdtnFiling;
  readonly reviewSalesCreditsBankInvestorKeyChange: SalesCreditGrid;
  /** Send a DesignationWire */
  readonly sendDesignationWire: DesignationWireResponseUnion;
  readonly sendDLSupportRequest: SendDlSupportRequestResponse;
  /** Send a FreeFormWire */
  readonly sendFreeFormWire: FreeFormWireResponseUnion;
  /** Send a GreenShoeWire */
  readonly sendGreenShoeWire: GreenShoeWireResponseUnion;
  /** Send a IssuerAllocationWire */
  readonly sendIssuerAllocationWire: IssuerAllocationWireResponseUnion;
  /** Send a IssuerIndicationWire */
  readonly sendIssuerIndicationWire: IssuerIndicationWireResponseUnion;
  /** Send a ManagerBdWire */
  readonly sendManagerBdWire: ManagerBdWireResponseUnion;
  /** Send a NonSyndicateFreeFormWire */
  readonly sendNonSyndicateFreeFormWire: NonSyndicateFreeFormWireResponseUnion;
  /** Send a NonSyndicatePaymentWire */
  readonly sendNonSyndicatePaymentWire: NonSyndicatePaymentWireResponseUnion;
  /** Send a NonSyndicateSellingGroupInvitationWire */
  readonly sendNonSyndicateSellingGroupInvitationWire: NonSyndicateSellingGroupInvitationWireResponseUnion;
  /** Send a NonSyndicateSellingGroupTermsRetentionWire */
  readonly sendNonSyndicateSellingGroupTermsRetentionWire: NonSyndicateSellingGroupTermsRetentionWireResponseUnion;
  /** Send a NonSyndicateTerminationWire */
  readonly sendNonSyndicateTerminationWire: NonSyndicateTerminationWireResponseUnion;
  /** Send a PaymentWire */
  readonly sendPaymentWire: PaymentWireResponseUnion;
  /** Send a PotAccountWire */
  readonly sendPotAccountWire: PotAccountWireResponseUnion;
  /** Send a PotAllocationWire */
  readonly sendPotAllocationWire: PotAllocationWireResponseUnion;
  /** Send a PricingTermsRetentionWire */
  readonly sendPricingTermsRetentionWire: PricingTermsRetentionWireResponseUnion;
  /** Send a PricingTermsWire */
  readonly sendPricingTermsWire: PricingTermsWireResponseUnion;
  /** Send a RegMWire */
  readonly sendRegMWire: RegMWireResponseUnion;
  readonly sendRegulatoryFilingEmailNotification: ReadonlyArray<RegulatoryFilingNotificationMessage>;
  /** Send a RetentionWire */
  readonly sendRetentionWire: RetentionWireResponseUnion;
  /** Send a SellingGroupInvitationWire */
  readonly sendSellingGroupInvitationWire: SellingGroupInvitationWireResponseUnion;
  /** Send a SellingGroupTermsWire */
  readonly sendSellingGroupTermsWire: SellingGroupTermsWireResponseUnion;
  /** Send a TerminationWire */
  readonly sendTerminationWire: TerminationWireResponseUnion;
  /** Send a UnderwriterInvitationWire */
  readonly sendUnderwriterInvitationWire: UnderwriterInvitationWireResponseUnion;
  readonly setAllocationRanking: MdlAllocationResponse;
  readonly setAllocationRankingOnOffering: MdlAllocationRankingResponse;
  /** Set Default Instrument for a Tranche */
  readonly setDefaultInstrumentForTranche: Tranche;
  /** Set Pricing Currency on Offering */
  readonly setPricingCurrency: Offering;
  readonly setPricingInstrument: OfferingUnion;
  /** Share offering draft with selected managers */
  readonly shareDraft: Offering;
  readonly shareDraftAllocationSet: DraftAllocationSet;
  readonly shareFinalAllocationSet: FinalInstitutionalAllocationSet;
  /** Submits an Covered Institutional Indication */
  readonly submitCoveredInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  /** Submit DS filing */
  readonly submitDSFiling: DsFiling;
  /** Submits an Covered Institutional Indication */
  readonly submitInstitutionalIndication: InstitutionalIndicationSubmitResponse;
  /** Submit NOI filing */
  readonly submitNOIFiling: NoiFiling;
  readonly submitRetailDemand: TotalSubmittedRetailDemandInfo;
  /** Submit RPN filing */
  readonly submitRPNFiling: RpnFiling;
  /** Submit TN filing */
  readonly submitTNFiling: TnFiling;
  /** Submit USDTN filing */
  readonly submitUSDTNFiling: UsdtnFiling;
  /** Deletes/Removes Institutional Indication Acknowledgement. */
  readonly unacknowledgeCoveredIndication: UnacknowledgeInstitutionalIndicationResult;
  /** Unacknowledgement Covered Institutional Final Allocation */
  readonly unacknowledgeCoveredInstitutionalFinalAllocation: CoveredInstitutionalIndication;
  readonly unacknowledgeInstitutionalFinalAllocation: FinalAllocation;
  /** Deletes/Removes Institutional Indication Acknowledgement. */
  readonly unacknowledgeInstitutionalIndication: UnacknowledgeInstitutionalIndicationResult;
  /** Unmark Institutional Indications as duplicate */
  readonly unmarkIndicationsAsDuplicate: InstitutionalIndication;
  readonly updateAccountingAdjustments: ReadonlyArray<ManualAdjustment>;
  readonly updateAccountingNotes: AccountingNotes;
  readonly updateAdHocLogisticsParticipants: ReadonlyArray<EventParticipantsTeam>;
  /** Saves ad-hoc meeting participants */
  readonly updateAdHocMeetingParticipants: ReadonlyArray<EventParticipantsTeam>;
  readonly updateBasicInfo: OfferingUnion;
  /** Update Covered Indication */
  readonly updateCoveredIndication: InstitutionalIndicationUpdateResponse;
  /** Updates an Covered Institutional Indication status */
  readonly updateCoveredIndicationStatus: InstitutionalIndicationUpdateResponse;
  /** Set list of meeting investor teams for covered roadshow */
  readonly updateCoveredInvestorTeams: ReadonlyArray<EventParticipantsTeam>;
  readonly updateDealTeam: DealTeam;
  readonly updateDealTeamMember: DealTeamMember;
  /** Update a Delivery Instrument */
  readonly updateDeliveryInstrument: DeliveryInstrumentUnion;
  readonly updateDemandCurrency: DemandCurrency;
  /** Update a DesignationWire */
  readonly updateDesignationWire: DesignationWireResponseUnion;
  /** Updates the disclaimers for an offering */
  readonly updateDisclaimers: Disclaimers;
  readonly updateDraftAllocationSetDealLayout: DraftAllocationSet;
  readonly updateDraftAllocationSetName: DraftAllocationSet;
  readonly updateDraftInstitutionalAllocation: DraftAllocation;
  readonly updateDraftInstitutionalAllocations: Scalars['String'];
  readonly updateFiling: FilingUnion;
  readonly updateFinalAllocationSetDealLayout: FinalInstitutionalAllocationSet;
  /** Updates a Final Institutional Allocation */
  readonly updateFinalInstitutionalAllocation: FinalAllocation;
  readonly updateFinalInstitutionalAllocationAcknowledgementStatus: FinalAllocation;
  readonly updateFinalInstitutionalAllocations: FinalInstitutionalAllocationsUpdateResponse;
  /** Updates the Follow-On Pricing for an offering */
  readonly updateFollowOnPricing: FollowOnPricingUnion;
  /** Update a FreeFormWire */
  readonly updateFreeFormWire: FreeFormWireResponseUnion;
  readonly updateFundIois: FundIois;
  /** Update a GreenShoeWire */
  readonly updateGreenShoeWire: GreenShoeWireResponseUnion;
  readonly updateIncentiveFeeDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  /** Update Institutional Indication */
  readonly updateInstitutionalIndication: InstitutionalIndicationUpdateResponse;
  /** Updates a Billing and Delivery Agent. */
  readonly updateInstitutionalIndicationBillingAndDeliveryAgent: BillingAndDeliveryAgent;
  /** Batch - Updates a Billing and Delivery Agent for Multiple Indications. */
  readonly updateInstitutionalIndicationBillingAndDeliveryAgentForIndications: ReadonlyArray<
    Scalars['String']
  >;
  /** Updates Institutional Indication status */
  readonly updateInstitutionalIndicationStatus: InstitutionalIndication;
  /** Set list of meeting investor teams */
  readonly updateInvestorTeams: ReadonlyArray<EventParticipantsTeam>;
  /** Update a IssuerAllocationWire */
  readonly updateIssuerAllocationWire: IssuerAllocationWireResponseUnion;
  /** Update a IssuerIndicationWire */
  readonly updateIssuerIndicationWire: IssuerIndicationWireResponseUnion;
  readonly updateLogistics: Logistics;
  readonly updateManagementFeeDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  /** Update a Manager */
  readonly updateManager: Manager;
  /** Update a ManagerBdWire */
  readonly updateManagerBdWire: ManagerBdWireResponseUnion;
  /** Update Manager Responsibilities on an Offering */
  readonly updateManagerResponsibilities: ManagerResponsibilities;
  /** Update Manager Sequence Order on Offering */
  readonly updateManagerSequence: Scalars['Boolean'];
  /** Update Marketing info for an Offering */
  readonly updateMarketing: Marketing;
  readonly updateMeeting: MeetingDetails;
  /** Set list of meeting participants */
  readonly updateMeetingParticipants: ReadonlyArray<EventParticipantsTeam>;
  /** Update my indication status */
  readonly updateMyIndicationStatus: MyInstitutionalIndication;
  readonly updateMyRetail: MyRetailSummary;
  readonly updateMyRetailBroker: RetailBrokerDealer;
  /** Update a NonSyndicateFreeFormWire */
  readonly updateNonSyndicateFreeFormWire: NonSyndicateFreeFormWireResponseUnion;
  /** Update a NonSyndicatePaymentWire */
  readonly updateNonSyndicatePaymentWire: NonSyndicatePaymentWireResponseUnion;
  /** Update a NonSyndicateSellingGroupInvitationWire */
  readonly updateNonSyndicateSellingGroupInvitationWire: NonSyndicateSellingGroupInvitationWireResponseUnion;
  /** Update a NonSyndicateSellingGroupTermsRetentionWire */
  readonly updateNonSyndicateSellingGroupTermsRetentionWire: NonSyndicateSellingGroupTermsRetentionWireResponseUnion;
  /** Update a NonSyndicateTerminationWire */
  readonly updateNonSyndicateTerminationWire: NonSyndicateTerminationWireResponseUnion;
  readonly updateOfferingNote: MdlOfferingNotes;
  /** Update offering status */
  readonly updateOfferingStatus: OfferingUnion;
  readonly updateOrderBookSalesState?: Maybe<UpdateOrderBookSalesStatePayload>;
  readonly updateOrderBookState: OrderBook;
  readonly updateOverallotmentExercisedSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
  /** Update a PaymentWire */
  readonly updatePaymentWire: PaymentWireResponseUnion;
  /** Update a PotAccountWire */
  readonly updatePotAccountWire: PotAccountWireResponseUnion;
  /** Update a PotAllocationWire */
  readonly updatePotAllocationWire: PotAllocationWireResponseUnion;
  /** Update a PricingTermsRetentionWire */
  readonly updatePricingTermsRetentionWire: PricingTermsRetentionWireResponseUnion;
  /** Update a PricingTermsWire */
  readonly updatePricingTermsWire: PricingTermsWireResponseUnion;
  readonly updateProspectusDocumentsMetadata: ReadonlyArray<ProspectusDocument>;
  /** Update a RegMWire */
  readonly updateRegMWire: RegMWireResponseUnion;
  /** Update a RetentionWire */
  readonly updateRetentionWire: RetentionWireResponseUnion;
  readonly updateRoadshow: Roadshow;
  readonly updateRoadshowLogisticsParticipants: ReadonlyArray<EventParticipantsTeam>;
  readonly updateRoadshowManagementTeamMember: ManagementTeamMember;
  /** Update the Roadshow Schedules for an offering */
  readonly updateRoadshowSchedule: RoadshowSchedule;
  readonly updateSalesCreditsAmount: SalesCreditGrid;
  readonly updateSalesCreditsBankInvestorKey: SalesCreditGrid;
  readonly updateSalesCreditsHouseAccounts: ReadonlyArray<HouseAccount>;
  readonly updateSalesCreditsStatus: SalesCreditGrid;
  readonly updateSellingConcessionDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  /** Update a SellingGroupInvitationWire */
  readonly updateSellingGroupInvitationWire: SellingGroupInvitationWireResponseUnion;
  /** Update a SellingGroupTermsWire */
  readonly updateSellingGroupTermsWire: SellingGroupTermsWireResponseUnion;
  /** Update Offering Syndicate */
  readonly updateSyndicate: Syndicate;
  /** Update a TerminationWire */
  readonly updateTerminationWire: TerminationWireResponseUnion;
  /** Returns the Offering's Timing Information */
  readonly updateTimingInfo: Timing;
  readonly updateTotalRetailDemand: TotalRetailSummaryGrid;
  readonly updateTradeReleaseSettings: TradeReleaseSettings;
  readonly updateTradesStatusBatch: ReadonlyArray<TradeGridItem>;
  /** Update a Tranche */
  readonly updateTranche: Tranche;
  /** Update a UnderwriterInvitationWire */
  readonly updateUnderwriterInvitationWire: UnderwriterInvitationWireResponseUnion;
  readonly updateUnderwritingBaseSharesDistribution: ReadonlyArray<SharesPerCmgEntity>;
  readonly updateUnderwritingFeeBaseDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  /** Update Underwriting Fees and Discounts for an Offering */
  readonly updateUnderwritingFeesAndDiscounts: Terms;
  readonly updateUnderwritingFeeTotalDistribution: ReadonlyArray<PercentagePerCmgEntity>;
  /** Update Underwriting Terms Fees and Discounts for an Offering */
  readonly updateUnderwritingTermsFeesAndDiscounts: UpdateUnderwritingTermsFeesAndDiscountsPayload;
  readonly upsertCommunicatedDemandAllocation: MdlAllocationResponse;
  readonly upsertCommunicatedDemandIndications: MdlCommunicatedDemandIndications;
  readonly upsertInternalDemandIndications: MdlInternalDemandIndications;
};

export type MutationAcceptWireArgs = {
  body: InvitationWireResponseInput;
  sentWireId: Scalars['UUID'];
  token: Scalars['String'];
};

export type MutationAcknowledgeCoveredIndicationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  version: Scalars['String'];
};

export type MutationAcknowledgeCoveredInstitutionalIndicationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  versionId: Scalars['String'];
};

export type MutationAcknowledgeInstitutionalIndicationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  version: Scalars['String'];
};

export type MutationAcknowledgeMyFinalInstitutionalAllocationArgs = {
  offeringId: Scalars['UUID'];
  versionId: Scalars['String'];
};

export type MutationActivateInternalDemandOfferingArgs = {
  offeringId: Scalars['ID'];
};

export type MutationAddCurrencyToTrancheArgs = {
  currencyCode: Scalars['String'];
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationAddInstrumentToTrancheArgs = {
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationAssignCompanyArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  roadshowId: Scalars['UUID'];
};

export type MutationAssignContactsArgs = {
  indicationId: Scalars['UUID'];
  input: ReadonlyArray<AssignContactInput>;
  offeringId: Scalars['UUID'];
};

export type MutationAssignCoveredContactsArgs = {
  indicationId: Scalars['UUID'];
  input: ReadonlyArray<AssignContactInput>;
  offeringId: Scalars['UUID'];
};

export type MutationAssignCoveredInvestorKeyArgs = {
  indicationId: Scalars['UUID'];
  investorCrmKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationAssignDealTeamMembersToRoadshowArgs = {
  payload: ReadonlyArray<Scalars['UUID']>;
  roadshowId: Scalars['UUID'];
};

export type MutationAssignDesignationToManagerArgs = {
  input: OverwriteDesignationInput;
  offeringId: Scalars['ID'];
};

export type MutationAssignFinalAllocationSetVisibilityArgs = {
  offeringId: Scalars['UUID'];
  visibility: Scalars['Boolean'];
};

export type MutationAssignIndicationNoteArgs = {
  indicationId: Scalars['UUID'];
  input: AssignNoteInput;
  offeringId: Scalars['UUID'];
};

export type MutationAssignInvestorKeyArgs = {
  indicationId: Scalars['UUID'];
  investorCrmKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationAssignMyInstitutionalIndicationArgs = {
  input: AssignInvestorInstitutionalIndicationInput;
  offeringId: Scalars['ID'];
};

export type MutationAssignRetailDemandArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
  payload: AssignRetailDemandInput;
};

export type MutationAssignRetailRetentionArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
  payload: AssignRetailRetentionInput;
};

export type MutationBatchUpdateBndAgentArgs = {
  input: ReadonlyArray<BndAgentBatchUpdateInput>;
  offeringId: Scalars['UUID'];
};

export type MutationBulkAssignBndAgentArgs = {
  applyTo?: InputMaybe<SyndicateGridQueryInput>;
  input: BndAgentUpdateInput;
  offeringId: Scalars['UUID'];
};

export type MutationBulkUpdateAllocationsArgs = {
  allocation: AllocationUpdateInput;
  allocationSetIds: ReadonlyArray<Scalars['UUID']>;
  applyTo?: InputMaybe<SyndicateGridQueryInput>;
  offeringId: Scalars['UUID'];
};

export type MutationCalculateFeesDistributionsArgs = {
  input?: InputMaybe<CalculateFeesDistributionsInput>;
  offeringId: Scalars['UUID'];
};

export type MutationCalculateSharesDistributionsArgs = {
  input?: InputMaybe<CalculateSharesDistributionsInput>;
  offeringId: Scalars['UUID'];
};

export type MutationCancelMeetingArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type MutationConfirmMeetingArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type MutationCreateCompanyArgs = {
  name: Scalars['String'];
};

export type MutationCreateDealTeamArgs = {
  payload: CreateDealTeamInput;
};

export type MutationCreateDeliveryInstrumentArgs = {
  input: CreateDeliveryInstrumentInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateDemandCurrencyArgs = {
  input: CreateDemandCurrencyInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateDesignationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: DesignationWireInput;
};

export type MutationCreateDraftAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateDraftAllocationInput;
};

export type MutationCreateExpensesRevisionArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateExpensesRevisionInput;
};

export type MutationCreateFilingArgs = {
  input: CreateFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateFinalInstitutionalAllocationSetArgs = {
  input: FinalAllocationsSetCreateInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateFreeFormWireInput;
};

export type MutationCreateGreenShoeWireArgs = {
  offeringId: Scalars['UUID'];
  payload: GreenShoeWireInput;
};

export type MutationCreateInvestorFirmBasicArgs = {
  input: CreateInvestorFirmBasicInput;
};

export type MutationCreateIssuerAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: IssuerAllocationWireInput;
};

export type MutationCreateIssuerIndicationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: IssuerIndicationWireInput;
};

export type MutationCreateLogisticsArgs = {
  payload: CreateLogisticsInput;
  roadshowId: Scalars['UUID'];
};

export type MutationCreateManagerArgs = {
  input: CreateManagerInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateManagerBdWireArgs = {
  offeringId: Scalars['UUID'];
  payload: ManagerBdWireInput;
};

export type MutationCreateMeetingArgs = {
  payload: MeetingInput;
  roadshowId: Scalars['UUID'];
};

export type MutationCreateMyRetailBrokerArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateMyRetailBrokerInput;
};

export type MutationCreateNonSyndicateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateNonSyndicateFreeFormWireInput;
};

export type MutationCreateNonSyndicatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: NonSyndicatePaymentWireInput;
};

export type MutationCreateNonSyndicateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateNonSyndicateSellingGroupInvitationWireInput;
};

export type MutationCreateNonSyndicateSellingGroupTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateNonSyndicateSellingGroupTermsRetentionWireInput;
};

export type MutationCreateNonSyndicateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateNonSyndicateTerminationWireInput;
};

export type MutationCreateOfferingDraftArgs = {
  input: CreateOfferingInput;
};

export type MutationCreateOfferingNoteArgs = {
  input: CreateMdlOfferingNoteInput;
  offeringId: Scalars['ID'];
};

export type MutationCreateOrUpdateDemandCurrenciesArgs = {
  input: CreateOrUpdateDemandCurrenciesInput;
};

export type MutationCreateOrUpdateStabilizationArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateOrUpdateStabilizationInput;
};

export type MutationCreatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: PaymentWireInput;
};

export type MutationCreatePotAccountWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreatePotAccountWireInput;
};

export type MutationCreatePotAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreatePotAllocationWireInput;
};

export type MutationCreatePricingTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreatePricingTermsRetentionWireInput;
};

export type MutationCreatePricingTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreatePricingTermsWireInput;
};

export type MutationCreateRegMWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateRegMWireInput;
};

export type MutationCreateRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: RetentionWireInput;
};

export type MutationCreateRoadshowArgs = {
  payload: RoadshowInput;
};

export type MutationCreateRoadshowManagementTeamMemberArgs = {
  payload: ManagementTeamMemberInput;
  roadshowId: Scalars['UUID'];
};

export type MutationCreateRoadshowScheduleArgs = {
  input: RoadshowScheduleInput;
  offeringId: Scalars['UUID'];
};

export type MutationCreateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateSellingGroupInvitationWireInput;
};

export type MutationCreateSellingGroupTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateSellingGroupTermsWireInput;
};

export type MutationCreateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateTerminationWireInput;
};

export type MutationCreateUnderwriterInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: CreateUnderwriterInvitationWireInput;
};

export type MutationDeclineWireArgs = {
  body: InvitationWireResponseInput;
  sentWireId: Scalars['UUID'];
  token: Scalars['String'];
};

export type MutationDeleteCurrencyFromTrancheArgs = {
  currencyCode: Scalars['String'];
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationDeleteDealTeamArgs = {
  cmgEntityKey: Scalars['String'];
};

export type MutationDeleteDeliveryInstrumentArgs = {
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteDemandCurrencyArgs = {
  currencyCode: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteDesignationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteDraftInstitutionalAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  setId: Scalars['UUID'];
};

export type MutationDeleteFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteGreenShoeWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteInstitutionalIndicationBillingAndDeliveryAgentArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteInstrumentFromTrancheArgs = {
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationDeleteIssuerAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteIssuerIndicationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteLogisticsArgs = {
  logisticsId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type MutationDeleteManagerArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteManagerBdWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteMeetingArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type MutationDeleteMyRetailBrokerArgs = {
  id: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationDeleteNonSyndicateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteNonSyndicatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteNonSyndicateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteNonSyndicateSellingGroupTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteNonSyndicateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteOfferingNoteArgs = {
  input: DeleteMdlOfferingNoteInput;
  offeringId: Scalars['ID'];
};

export type MutationDeletePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeletePotAccountWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeletePotAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeletePricingTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeletePricingTermsWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteRegMWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteRoadshowManagementTeamMemberArgs = {
  roadshowId: Scalars['UUID'];
  teamMemberId: Scalars['UUID'];
};

export type MutationDeleteRoadshowScheduleArgs = {
  offeringId: Scalars['UUID'];
  roadshowScheduleId: Scalars['UUID'];
};

export type MutationDeleteSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteSellingGroupTermsWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDeleteUnderwriterInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type MutationDiscardOfferingArgs = {
  offeringId: Scalars['UUID'];
};

export type MutationDuplicateDraftAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  setId: Scalars['UUID'];
};

export type MutationImportCompanyArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  factSetFirmId?: InputMaybe<Scalars['String']>;
};

export type MutationMarkIndicationsAsDuplicateArgs = {
  input: InstitutionalIndicationAssignDupesInput;
  offeringId: Scalars['ID'];
};

export type MutationOpenMeetingToSalesArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type MutationPassCoveredInstitutionalIndicationArgs = {
  activateIfCancelled: Scalars['Boolean'];
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationPassInstitutionalIndicationArgs = {
  activateIfCancelled: Scalars['Boolean'];
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationPassInternalDemandOfferingArgs = {
  offeringId: Scalars['ID'];
};

export type MutationPassOfferingWithoutCoveredInstitutionalIndicationArgs = {
  input: SubmitPassIndicationInput;
  offeringId: Scalars['UUID'];
};

export type MutationPassOnOfferingArgs = {
  activateIfCancelled?: InputMaybe<Scalars['Boolean']>;
  offeringId: Scalars['ID'];
};

export type MutationPassOnOfferingWithoutInstitutionalIndicationArgs = {
  input: SubmitPassIndicationInput;
  offeringId: Scalars['UUID'];
};

export type MutationPromoteDraftAllocationSetToDefaultArgs = {
  offeringId: Scalars['UUID'];
  setId: Scalars['UUID'];
};

export type MutationPublishDesignationsArgs = {
  offeringId: Scalars['ID'];
};

export type MutationPublishOfferingArgs = {
  offeringId: Scalars['UUID'];
};

export type MutationPublishRoadshowInternallyArgs = {
  roadshowId: Scalars['UUID'];
};

export type MutationRegMInternalDemandOfferingArgs = {
  offeringId: Scalars['ID'];
};

export type MutationReleaseFinalInstitutionalAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  setUnallocatedToZero?: InputMaybe<Scalars['Boolean']>;
};

export type MutationReleaseRetailRetentionArgs = {
  offeringId: Scalars['UUID'];
};

export type MutationReleaseSalesCreditsArgs = {
  investorFirmCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  offeringId: Scalars['UUID'];
};

export type MutationReleaseTradesBatchArgs = {
  indicationIds: ReadonlyArray<Scalars['UUID']>;
  offeringId: Scalars['UUID'];
};

export type MutationResendProspectusAsSalesAndTradingArgs = {
  investorKeys: ReadonlyArray<Scalars['String']>;
  offeringId: Scalars['UUID'];
};

export type MutationResendProspectusAsSyndicateArgs = {
  investorKeys: ReadonlyArray<Scalars['String']>;
  offeringId: Scalars['UUID'];
};

export type MutationResubmitDsFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationResubmitNoiFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationResubmitRpnFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationResubmitTnFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationResubmitUsdtnFilingArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationReviewSalesCreditsBankInvestorKeyChangeArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
  payload: ReviewSalesCreditsBankInvestorKeyChangeInput;
};

export type MutationSendDesignationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendDlSupportRequestArgs = {
  description: Scalars['String'];
  missingEmailsCmgEntityKeys: ReadonlyArray<Scalars['String']>;
  subject: Scalars['String'];
};

export type MutationSendFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendGreenShoeWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendIssuerAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendReportInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendIssuerIndicationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendReportInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendManagerBdWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendNonSyndicateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendNonSyndicatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendNonSyndicateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendNonSyndicateSellingGroupTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendNonSyndicateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendPaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendPotAccountWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendPotAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendPricingTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendPricingTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendRegMWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendRegulatoryFilingEmailNotificationArgs = {
  filingId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  recipients: ReadonlyArray<Scalars['String']>;
  shouldAddSenderToBcc: Scalars['Boolean'];
};

export type MutationSendRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendSellingGroupTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSendUnderwriterInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: SendWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationSetAllocationRankingArgs = {
  allocationId: Scalars['ID'];
  ranking: MdlAllocationRanking;
  xcOfferingId: Scalars['ID'];
};

export type MutationSetAllocationRankingOnOfferingArgs = {
  id: Scalars['ID'];
  ranking: MdlAllocationRanking;
};

export type MutationSetDefaultInstrumentForTrancheArgs = {
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationSetPricingCurrencyArgs = {
  currencyCode: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationSetPricingInstrumentArgs = {
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationShareDraftArgs = {
  managerKeys: ReadonlyArray<Scalars['String']>;
  offeringId: Scalars['UUID'];
};

export type MutationShareDraftAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  payload: ReadonlyArray<Scalars['String']>;
  setId: Scalars['String'];
};

export type MutationShareFinalAllocationSetArgs = {
  offeringId: Scalars['UUID'];
  payload: ReadonlyArray<Scalars['String']>;
};

export type MutationSubmitCoveredInstitutionalIndicationArgs = {
  input: InstitutionalIndicationSubmitInput;
  offeringId: Scalars['ID'];
};

export type MutationSubmitDsFilingArgs = {
  input: CreateDsFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationSubmitInstitutionalIndicationArgs = {
  input: InstitutionalIndicationSubmitInput;
  offeringId: Scalars['ID'];
};

export type MutationSubmitNoiFilingArgs = {
  input: CreateNoiFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationSubmitRetailDemandArgs = {
  offeringId: Scalars['UUID'];
  payload: SubmitMyRetailInput;
};

export type MutationSubmitRpnFilingArgs = {
  input: CreateRpnFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationSubmitTnFilingArgs = {
  input: CreateTnFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationSubmitUsdtnFilingArgs = {
  input: CreateUsdtnFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationUnacknowledgeCoveredIndicationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  version: Scalars['String'];
};

export type MutationUnacknowledgeCoveredInstitutionalFinalAllocationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  versionId: Scalars['String'];
};

export type MutationUnacknowledgeInstitutionalFinalAllocationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  versionId: Scalars['String'];
};

export type MutationUnacknowledgeInstitutionalIndicationArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  version: Scalars['String'];
};

export type MutationUnmarkIndicationsAsDuplicateArgs = {
  duplicateIndicationId: Scalars['UUID'];
  indicationId: Scalars['UUID'];
  offeringId: Scalars['ID'];
};

export type MutationUpdateAccountingAdjustmentsArgs = {
  offeringId: Scalars['UUID'];
  payload: ReadonlyArray<UpdateAccountingAdjustmentsInput>;
};

export type MutationUpdateAccountingNotesArgs = {
  notes: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type MutationUpdateAdHocLogisticsParticipantsArgs = {
  logisticsId: Scalars['UUID'];
  payload: ReadonlyArray<AdHocParticipantInput>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateAdHocMeetingParticipantsArgs = {
  meetingId: Scalars['UUID'];
  payload: ReadonlyArray<AdHocParticipantInput>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateBasicInfoArgs = {
  input: UpdateBasicInfoInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateCoveredIndicationArgs = {
  indicationId: Scalars['ID'];
  input: InstitutionalIndicationUpdateInput;
  offeringId: Scalars['ID'];
};

export type MutationUpdateCoveredIndicationStatusArgs = {
  indicationId: Scalars['ID'];
  offeringId: Scalars['ID'];
  status: IndicationStatus;
};

export type MutationUpdateCoveredInvestorTeamsArgs = {
  meetingId: Scalars['UUID'];
  payload: ReadonlyArray<InvestorTeamInput>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateDealTeamArgs = {
  cmgEntityKey: Scalars['String'];
  payload: UpdateDealTeamInput;
};

export type MutationUpdateDealTeamMemberArgs = {
  memberId: Scalars['UUID'];
  payload: UpdateRoadshowDealTeamMemberInput;
};

export type MutationUpdateDeliveryInstrumentArgs = {
  input: UpdateDeliveryInstrumentInput;
  instrumentId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type MutationUpdateDemandCurrencyArgs = {
  currencyCode: Scalars['String'];
  input: UpdateDemandCurrencyInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateDesignationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: DesignationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateDisclaimersArgs = {
  input: UpdateDisclaimersInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateDraftAllocationSetDealLayoutArgs = {
  offeringId: Scalars['UUID'];
  payload: AssignDealLayoutInput;
  setId: Scalars['String'];
};

export type MutationUpdateDraftAllocationSetNameArgs = {
  name: Scalars['String'];
  offeringId: Scalars['UUID'];
  setId: Scalars['UUID'];
};

export type MutationUpdateDraftInstitutionalAllocationArgs = {
  indicationId: Scalars['UUID'];
  input: DraftAllocationUpdateInput;
  offeringId: Scalars['UUID'];
  setId: Scalars['String'];
};

export type MutationUpdateDraftInstitutionalAllocationsArgs = {
  input: DraftAllocationBatchUpdateInput;
  offeringId: Scalars['UUID'];
  setId: Scalars['String'];
};

export type MutationUpdateFilingArgs = {
  filingId: Scalars['UUID'];
  input: UpdateFilingInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateFinalAllocationSetDealLayoutArgs = {
  offeringId: Scalars['UUID'];
  payload: AssignDealLayoutInput;
};

export type MutationUpdateFinalInstitutionalAllocationArgs = {
  indicationId: Scalars['UUID'];
  input: FinalAllocationUpdateInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateFinalInstitutionalAllocationAcknowledgementStatusArgs = {
  indicationId: Scalars['UUID'];
  investorReplyStatus: AllocationAcknowledgementNewStatus;
  offeringId: Scalars['UUID'];
  versionId: Scalars['String'];
};

export type MutationUpdateFinalInstitutionalAllocationsArgs = {
  input: FinalAllocationsUpdateInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateFollowOnPricingArgs = {
  input: UpdateFollowOnPricingInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateFreeFormWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateFundIoisArgs = {
  input: UpdateFundIoisInput;
  offeringId: Scalars['ID'];
};

export type MutationUpdateGreenShoeWireArgs = {
  offeringId: Scalars['UUID'];
  payload: GreenShoeWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateIncentiveFeeDistributionArgs = {
  input: ReadonlyArray<PercentagePerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateInstitutionalIndicationArgs = {
  indicationId: Scalars['ID'];
  input: InstitutionalIndicationUpdateInput;
  offeringId: Scalars['ID'];
};

export type MutationUpdateInstitutionalIndicationBillingAndDeliveryAgentArgs = {
  indicationId: Scalars['UUID'];
  input: BillingAndDeliveryAgentUpdateInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateInstitutionalIndicationBillingAndDeliveryAgentForIndicationsArgs = {
  input: BillingAndDeliveryAgentBatchUpdateInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateInstitutionalIndicationStatusArgs = {
  indicationId: Scalars['ID'];
  offeringId: Scalars['ID'];
  status: IndicationStatus;
};

export type MutationUpdateInvestorTeamsArgs = {
  meetingId: Scalars['UUID'];
  payload: ReadonlyArray<InvestorTeamInput>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateIssuerAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: IssuerAllocationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateIssuerIndicationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: IssuerIndicationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateLogisticsArgs = {
  logisticsId: Scalars['UUID'];
  payload: UpdateLogisticsInput;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateManagementFeeDistributionArgs = {
  input: ReadonlyArray<PercentagePerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateManagerArgs = {
  cmgEntityKey: Scalars['String'];
  input: UpdateManagerInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateManagerBdWireArgs = {
  offeringId: Scalars['UUID'];
  payload: ManagerBdWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateManagerResponsibilitiesArgs = {
  input: ManagerResponsibilitiesInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateManagerSequenceArgs = {
  input: UpdateManagerSequenceInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateMarketingArgs = {
  input: MarketingInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateMeetingArgs = {
  meetingId: Scalars['UUID'];
  payload: MeetingInput;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateMeetingParticipantsArgs = {
  meetingId: Scalars['UUID'];
  payload: ReadonlyArray<Scalars['UUID']>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateMyIndicationStatusArgs = {
  offeringId: Scalars['UUID'];
  status: IndicationStatus;
};

export type MutationUpdateMyRetailArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateMyRetailInput;
};

export type MutationUpdateMyRetailBrokerArgs = {
  id: Scalars['UUID'];
  offeringId: Scalars['UUID'];
  payload: UpdateMyRetailBrokerInput;
};

export type MutationUpdateNonSyndicateFreeFormWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateNonSyndicateFreeFormWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateNonSyndicatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: NonSyndicatePaymentWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateNonSyndicateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateNonSyndicateSellingGroupInvitationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateNonSyndicateSellingGroupTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateNonSyndicateSellingGroupTermsRetentionWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateNonSyndicateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateNonSyndicateTerminationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateOfferingNoteArgs = {
  input: UpdateMdlOfferingNoteInput;
  offeringId: Scalars['ID'];
};

export type MutationUpdateOfferingStatusArgs = {
  offeringId: Scalars['UUID'];
  status: OfferingStatus;
};

export type MutationUpdateOrderBookSalesStateArgs = {
  input: UpdateOrderBookSalesStateInput;
};

export type MutationUpdateOrderBookStateArgs = {
  offeringId: Scalars['ID'];
  state: OrderBookState;
};

export type MutationUpdateOverallotmentExercisedSharesDistributionArgs = {
  input: ReadonlyArray<SharesPerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdatePaymentWireArgs = {
  offeringId: Scalars['UUID'];
  payload: PaymentWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdatePotAccountWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdatePotAccountWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdatePotAllocationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdatePotAllocationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdatePricingTermsRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdatePricingTermsRetentionWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdatePricingTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdatePricingTermsWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateProspectusDocumentsMetadataArgs = {
  offeringId: Scalars['UUID'];
  prospectusDocuments: ReadonlyArray<ProspectusDocumentUpdateMetadata>;
};

export type MutationUpdateRegMWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateRegMWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateRetentionWireArgs = {
  offeringId: Scalars['UUID'];
  payload: RetentionWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateRoadshowArgs = {
  payload: RoadshowInput;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateRoadshowLogisticsParticipantsArgs = {
  logisticsId: Scalars['UUID'];
  payload: ReadonlyArray<Scalars['UUID']>;
  roadshowId: Scalars['UUID'];
};

export type MutationUpdateRoadshowManagementTeamMemberArgs = {
  payload: ManagementTeamMemberInput;
  roadshowId: Scalars['UUID'];
  teamMemberId: Scalars['UUID'];
};

export type MutationUpdateRoadshowScheduleArgs = {
  input: RoadshowScheduleInput;
  offeringId: Scalars['UUID'];
  roadshowScheduleId: Scalars['UUID'];
};

export type MutationUpdateSalesCreditsAmountArgs = {
  offeringId: Scalars['UUID'];
  payload: ReadonlyArray<UpdateSalesCreditsAmountInput>;
};

export type MutationUpdateSalesCreditsBankInvestorKeyArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
  payload: UpdateSalesCreditsBankInvestorKeyInput;
};

export type MutationUpdateSalesCreditsHouseAccountsArgs = {
  investorsFirmIds: ReadonlyArray<Scalars['UUID']>;
};

export type MutationUpdateSalesCreditsStatusArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateSalesCreditsInput;
};

export type MutationUpdateSellingConcessionDistributionArgs = {
  input: ReadonlyArray<PercentagePerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateSellingGroupInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateSellingGroupInvitationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateSellingGroupTermsWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateSellingGroupTermsWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateSyndicateArgs = {
  input: UpdateSyndicateInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateTerminationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateTerminationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateTimingInfoArgs = {
  input: UpdateTimingInfoInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateTotalRetailDemandArgs = {
  offeringId: Scalars['UUID'];
  payload: ReadonlyArray<UpdateTotalRetailInput>;
};

export type MutationUpdateTradeReleaseSettingsArgs = {
  isAutoreleaseEnabled: Scalars['Boolean'];
  offeringId: Scalars['UUID'];
};

export type MutationUpdateTradesStatusBatchArgs = {
  input: UpdateTradesStatusBatchInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateTrancheArgs = {
  input: UpdateTrancheInput;
  offeringId: Scalars['UUID'];
  trancheId: Scalars['UUID'];
};

export type MutationUpdateUnderwriterInvitationWireArgs = {
  offeringId: Scalars['UUID'];
  payload: UpdateUnderwriterInvitationWireInput;
  syndicateWireId: Scalars['UUID'];
};

export type MutationUpdateUnderwritingBaseSharesDistributionArgs = {
  input: ReadonlyArray<SharesPerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateUnderwritingFeeBaseDistributionArgs = {
  input: ReadonlyArray<PercentagePerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateUnderwritingFeesAndDiscountsArgs = {
  input: UpdateUnderwritingFeesAndDiscountsInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateUnderwritingFeeTotalDistributionArgs = {
  input: ReadonlyArray<PercentagePerCmgEntityInput>;
  offeringId: Scalars['UUID'];
};

export type MutationUpdateUnderwritingTermsFeesAndDiscountsArgs = {
  input: UpdateUnderwritingTermsFeesAndDiscountsInput;
  offeringId: Scalars['UUID'];
};

export type MutationUpsertCommunicatedDemandAllocationArgs = {
  input?: InputMaybe<MdlAllocationInput>;
  offeringId: Scalars['ID'];
};

export type MutationUpsertCommunicatedDemandIndicationsArgs = {
  input?: InputMaybe<ReadonlyArray<MdlIndicationInput>>;
  offeringId: Scalars['ID'];
};

export type MutationUpsertInternalDemandIndicationsArgs = {
  input: UpsertInternalDemandInput;
  offeringId: Scalars['ID'];
};

export type MyInstitutionalIndication = {
  readonly __typename?: 'MyInstitutionalIndication';
  readonly acknowledgements: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  readonly auditInfo: AuditInfo;
  readonly billingAndDeliveryAgentCmgEntityKey?: Maybe<Scalars['String']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly finalAllocation?: Maybe<FinalAllocation>;
  readonly id: Scalars['ID'];
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type MyInstitutionalIndicationVersion = {
  readonly __typename?: 'MyInstitutionalIndicationVersion';
  readonly auditInfo: AuditInfo;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  /** An Instrument ID will not be present for Investors who have Passed on the offering. */
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  /** The demand expression of the indication. */
  readonly interestLevels: ReadonlyArray<InterestLevel>;
  readonly investorContactName?: Maybe<Scalars['String']>;
  readonly isBuySideRevision: Scalars['Boolean'];
  readonly offeringId: Scalars['UUID'];
  readonly status: IndicationStatus;
  /** The CMG entity key for the specific manager submitting this indication. */
  readonly submittedByCmgEntityKey: Scalars['String'];
  readonly tranche?: Maybe<TranchePrivileged>;
  /** A Tranche ID will not be present for Investors who have Passed on the offering. */
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  readonly version: Scalars['String'];
};

export type MyPublishedOfferingListFiltersInput = {
  readonly cmgEntityKey: Scalars['String'];
  readonly onPlatformOnly?: InputMaybe<Scalars['Boolean']>;
};

export type MyRetailBrokerDemandInput = {
  readonly roundLots?: InputMaybe<Scalars['Int']>;
  readonly shareQuantity: Scalars['Long'];
};

export type MyRetailForBrokerDealer = {
  readonly __typename?: 'MyRetailForBrokerDealer';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly retentionAmount: Scalars['Long'];
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
};

export type MyRetailRetentionInput = {
  readonly roundLots?: InputMaybe<Scalars['Int']>;
  readonly shareQuantity?: InputMaybe<Scalars['Long']>;
};

export type MyRetailSummary = {
  readonly __typename?: 'MyRetailSummary';
  readonly brokerDealers: ReadonlyArray<RetailBrokerDealer>;
  readonly id: Scalars['ID'];
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly totalRetailInfo: TotalRetailInfo;
};

export type NoiActivity = {
  readonly __typename?: 'NOIActivity';
  readonly activityType: ActivityType;
  /** Display name for ActivityType */
  readonly activityTypeDisplayName?: Maybe<Scalars['String']>;
  readonly date: Scalars['LocalDate'];
};

export type NoiActivityInput = {
  readonly activityType: ActivityType;
  readonly date: Scalars['LocalDate'];
};

export type NoiFiling = {
  readonly __typename?: 'NOIFiling';
  readonly activities: ReadonlyArray<NoiActivity>;
  readonly additionalInformation?: Maybe<Scalars['String']>;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly filingCreatorEmail?: Maybe<Scalars['String']>;
  readonly finraErrorMessages: ReadonlyArray<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmDealId: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly formUniqueId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly issuerName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
  readonly telephoneNumber: Scalars['String'];
};

export type NoiFilingFormDataInput = {
  readonly activities: ReadonlyArray<NoiActivityInput>;
  readonly additionalInformation?: InputMaybe<Scalars['String']>;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly telephoneNumber: Scalars['String'];
};

export type NonMemberFirm = {
  readonly __typename?: 'NonMemberFirm';
  /** CmgEntityKey of the firm, not null when non member firm is a manager on the offering */
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  /** CmgEntityKey of the manager this non-owner firm is affiliated with */
  readonly ownerCmgEntityKey?: Maybe<Scalars['String']>;
};

export type NonMemberFirmInput = {
  /** CmgEntityKey of the firm, not null when non member firm is a manager on the offering */
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  /** CmgEntityKey of the manager this non-owner firm is affiliated with */
  readonly ownerCmgEntityKey?: InputMaybe<Scalars['String']>;
};

export type NonSyndicateFreeFormWire = {
  readonly __typename?: 'NonSyndicateFreeFormWire';
  readonly body: Scalars['String'];
  readonly createdAt: Scalars['Instant'];
  readonly header: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type NonSyndicateFreeFormWireResponseUnion = NonSyndicateFreeFormWire | ServiceError;

export type NonSyndicateFreeFormWireValidation = WireValidationBase & {
  readonly __typename?: 'NonSyndicateFreeFormWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<NonSyndicateFreeFormWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum NonSyndicateFreeFormWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type NonSyndicatePaymentRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly dtcNumber: Scalars['String'];
  readonly ipoPenaltyTracking: Scalars['Boolean'];
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
  readonly sellingConcession: Scalars['Decimal'];
};

export type NonSyndicatePaymentRecipientUserData = {
  readonly __typename?: 'NonSyndicatePaymentRecipientUserData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly dtcNumber: Scalars['String'];
  readonly ipoPenaltyTracking: Scalars['Boolean'];
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
  readonly sellingConcession: Scalars['Decimal'];
};

export type NonSyndicatePaymentWire = {
  readonly __typename?: 'NonSyndicatePaymentWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<NonSyndicatePaymentRecipientUserData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type NonSyndicatePaymentWireInput = {
  readonly userDataForRecipients: ReadonlyArray<NonSyndicatePaymentRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type NonSyndicatePaymentWireResponseUnion = NonSyndicatePaymentWire | ServiceError;

export type NonSyndicatePaymentWireValidation = WireValidationBase & {
  readonly __typename?: 'NonSyndicatePaymentWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<NonSyndicatePaymentWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum NonSyndicatePaymentWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  PricePerShare = 'PRICE_PER_SHARE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type NonSyndicateSellingGroupInvitationDataForRecipient = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly masterSdaDate: Scalars['LocalDate'];
  readonly recipient: Scalars['String'];
  readonly retention: Scalars['Long'];
};

export type NonSyndicateSellingGroupInvitationRecipientData = {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly masterSdaDate: Scalars['LocalDate'];
  readonly recipient: Scalars['String'];
  readonly retention: Scalars['Long'];
};

export type NonSyndicateSellingGroupInvitationWire = {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<NonSyndicateSellingGroupInvitationRecipientData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type NonSyndicateSellingGroupInvitationWireResponseUnion =
  | NonSyndicateSellingGroupInvitationWire
  | ServiceError;

export type NonSyndicateSellingGroupInvitationWireValidation = WireValidationBase & {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<NonSyndicateSellingGroupInvitationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum NonSyndicateSellingGroupInvitationWireValidationOfferingErrors {
  ExchangeMic = 'EXCHANGE_MIC',
  IpoRangeHigh = 'IPO_RANGE_HIGH',
  IpoRangeLow = 'IPO_RANGE_LOW',
  IssuerName = 'ISSUER_NAME',
  LaunchDate = 'LAUNCH_DATE',
  OfferingType = 'OFFERING_TYPE',
  PricingDate = 'PRICING_DATE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type NonSyndicateSellingGroupTermsRetentionRecipientData = {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
  readonly sellingConcession: Scalars['Decimal'];
};

export type NonSyndicateSellingGroupTermsRetentionWire = {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<NonSyndicateSellingGroupTermsRetentionRecipientData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type NonSyndicateSellingGroupTermsRetentionWireResponseUnion =
  | NonSyndicateSellingGroupTermsRetentionWire
  | ServiceError;

export type NonSyndicateSellingGroupTermsRetentionWireValidation = WireValidationBase & {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<NonSyndicateSellingGroupTermsRetentionWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum NonSyndicateSellingGroupTermsRetentionWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  ExchangeMic = 'EXCHANGE_MIC',
  FirstTradeDate = 'FIRST_TRADE_DATE',
  IssuerName = 'ISSUER_NAME',
  OfferingPrice = 'OFFERING_PRICE',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type NonSyndicateTerminationWire = {
  readonly __typename?: 'NonSyndicateTerminationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly terminationDate: TerminationDate;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type NonSyndicateTerminationWireResponseUnion = NonSyndicateTerminationWire | ServiceError;

export type NonSyndicateTerminationWireValidation = WireValidationBase & {
  readonly __typename?: 'NonSyndicateTerminationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<NonSyndicateTerminationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum NonSyndicateTerminationWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type Note = {
  readonly __typename?: 'Note';
  readonly auditInfo: AuditInfo;
  readonly id: Scalars['ID'];
  readonly indicationId: Scalars['UUID'];
  readonly text: Scalars['String'];
  readonly type: IndicationNoteType;
  readonly version: Scalars['String'];
};

export enum NotificationMessageStatus {
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
}

export type NullableOfCertificateStatusOperationFilterInput = {
  readonly eq?: InputMaybe<AttestationFormStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<AttestationFormStatus>>>;
  readonly neq?: InputMaybe<AttestationFormStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<AttestationFormStatus>>>;
};

export type NullableOfLimitTypeOperationFilterInput = {
  readonly eq?: InputMaybe<InterestLevelLimitType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<InterestLevelLimitType>>>;
  readonly neq?: InputMaybe<InterestLevelLimitType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<InterestLevelLimitType>>>;
};

export type Offering = {
  readonly __typename?: 'Offering';
  readonly advisors: ReadonlyArray<Advisor>;
  /** Filters the Demand Currencies for the offering */
  readonly currencies: ReadonlyArray<DemandCurrency>;
  /** Filters the Delivery Instruments for the Offering */
  readonly deliveryInstruments: ReadonlyArray<DeliveryInstrument>;
  readonly demandCurrencies: ReadonlyArray<DemandCurrency>;
  readonly disclaimers: Disclaimers;
  readonly dspAgent?: Maybe<Scalars['String']>;
  readonly entitlements: Entitlements;
  /** Filters Filings for the Offering */
  readonly filings: ReadonlyArray<Filing>;
  /** Get Follow On Pricing for the Offering */
  readonly followOnPricing: FollowOnPricing;
  /** @deprecated Returns null, here for backwards compatibility */
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  /** Returns hasUnpublishedChanges for a private offering when published and version mismatch */
  readonly hasUnpublishedChanges: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly instruments: ReadonlyArray<DeliveryInstrument>;
  readonly isAuthor: Scalars['Boolean'];
  readonly isDiscarded: Scalars['Boolean'];
  readonly issuer: Issuer;
  readonly lenders: ReadonlyArray<Lender>;
  readonly managerEconomics: ManagerEconomics;
  readonly marketing: Marketing;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingInstrumentId?: Maybe<Scalars['UUID']>;
  readonly pricingInstrumentStockSymbol?: Maybe<Scalars['String']>;
  readonly prospectusDocuments: ReadonlyArray<ProspectusDocument>;
  readonly regulatoryFilings: RegulatoryFilings;
  /** Filters the Roadshow Schedules for the offering */
  readonly roadshowSchedules: ReadonlyArray<RoadshowSchedule>;
  readonly security: Security;
  readonly sizeInCurrency?: Maybe<Scalars['Decimal']>;
  readonly sizeInShares?: Maybe<Scalars['Long']>;
  readonly sponsors: ReadonlyArray<Sponsor>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  /** Returns Offering Syndicate */
  readonly syndicate: Syndicate;
  readonly terms: Terms;
  readonly timing: Timing;
  /** Returns Tranches for an Offering */
  readonly tranches: ReadonlyArray<Tranche>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
  /** Returns Offering validation errors and warnings before publish */
  readonly validation: OfferingValidation;
  readonly version: Scalars['String'];
  /** Return the available versions for this offering */
  readonly versions: ReadonlyArray<OfferingVersion>;
};

export type OfferingCurrenciesArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingDeliveryInstrumentsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingEntitlementsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingFilingsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingRoadshowSchedulesArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingSyndicateArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingTranchesArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type OfferingDealLayout = {
  readonly __typename?: 'OfferingDealLayout';
  readonly institutionalTarget?: Maybe<Scalars['Long']>;
  readonly offeringSize?: Maybe<Scalars['Long']>;
};

export type OfferingError = {
  readonly __typename?: 'OfferingError';
  readonly category: OfferingErrorCategory;
  readonly description: Scalars['String'];
  readonly type: OfferingErrorType;
};

export enum OfferingErrorCategory {
  All = 'ALL',
  DemandCurrencies = 'DEMAND_CURRENCIES',
  Disclaimers = 'DISCLAIMERS',
  FilingsAndRevisions = 'FILINGS_AND_REVISIONS',
  FollowonPricing = 'FOLLOWON_PRICING',
  Instruments = 'INSTRUMENTS',
  Issuer = 'ISSUER',
  ManagerResponsibilities = 'MANAGER_RESPONSIBILITIES',
  Managers = 'MANAGERS',
  Orderbook = 'ORDERBOOK',
  Prospectus = 'PROSPECTUS',
  Roadshow = 'ROADSHOW',
  Security = 'SECURITY',
  SponsorsAndAdvisors = 'SPONSORS_AND_ADVISORS',
  Syndicate = 'SYNDICATE',
  Timing = 'TIMING',
  Tranches = 'TRANCHES',
  Type = 'TYPE',
  UnderwritingTerms = 'UNDERWRITING_TERMS',
}

export enum OfferingErrorType {
  Error = 'ERROR',
  Warning = 'WARNING',
}

export type OfferingFull = {
  readonly __typename?: 'OfferingFull';
  readonly dataLabFull: DataLabFull;
  readonly demandCurrencies: ReadonlyArray<DemandCurrencyFull>;
  readonly disclaimers: DisclaimersFull;
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  readonly hasUnpublishedChanges?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['UUID'];
  readonly instruments: ReadonlyArray<InstrumentFull>;
  /** Returns isAuthor on the private offering */
  readonly isAuthor?: Maybe<Scalars['Boolean']>;
  readonly isExecutingOnPlatform: Scalars['Boolean'];
  readonly isObsolete: Scalars['Boolean'];
  readonly issuer: IssuerFull;
  readonly managerEconomics?: Maybe<ManagerEconomicsFull>;
  readonly marketing: MarketingFull;
  readonly orderBookFull: OrderBookFull;
  readonly priceRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly priceRangeLow?: Maybe<Scalars['Decimal']>;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingInstrumentId?: Maybe<Scalars['UUID']>;
  readonly pricingInstrumentStockSymbol?: Maybe<Scalars['String']>;
  readonly prospectusDocuments: ReadonlyArray<ProspectusDocumentFull>;
  readonly roadshowSchedules: ReadonlyArray<RoadshowsFull>;
  readonly security: SecurityFull;
  readonly shareHistory?: Maybe<ReadonlyArray<ShareHistoryFull>>;
  readonly sizeInCurrency?: Maybe<Scalars['Decimal']>;
  readonly sizeInShares?: Maybe<Scalars['Long']>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  readonly supersededByOfferingId?: Maybe<Scalars['UUID']>;
  readonly syndicate: SyndicateFull;
  readonly terms: TermsFull;
  readonly timing: TimingFull;
  readonly tranches?: Maybe<ReadonlyArray<TrancheFull>>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
  readonly version: Scalars['String'];
};

export type OfferingFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<OfferingFullFilterInput>>;
  readonly demandCurrencies?: InputMaybe<ListFilterInputTypeOfDemandCurrencyFullFilterInput>;
  readonly disclaimers?: InputMaybe<DisclaimersFullFilterInput>;
  readonly hasForwardAgreement?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly instruments?: InputMaybe<ListFilterInputTypeOfInstrumentFullFilterInput>;
  readonly isExecutingOnPlatform?: InputMaybe<BooleanOperationFilterInput>;
  readonly isObsolete?: InputMaybe<BooleanOperationFilterInput>;
  readonly issuer?: InputMaybe<IssuerFullFilterInput>;
  readonly managerEconomics?: InputMaybe<ManagerEconomicsFullFilterInput>;
  readonly marketing?: InputMaybe<MarketingFullFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<OfferingFullFilterInput>>;
  readonly priceRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  readonly priceRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  readonly pricingCurrencyCode?: InputMaybe<StringOperationFilterInput>;
  readonly pricingInstrumentId?: InputMaybe<UuidOperationFilterInput>;
  readonly pricingInstrumentStockSymbol?: InputMaybe<StringOperationFilterInput>;
  readonly prospectusDocuments?: InputMaybe<ListFilterInputTypeOfProspectusDocumentFullFilterInput>;
  readonly roadshowSchedules?: InputMaybe<ListFilterInputTypeOfRoadshowsFullFilterInput>;
  readonly security?: InputMaybe<SecurityFullFilterInput>;
  readonly shareHistory?: InputMaybe<ListFilterInputTypeOfShareHistoryFullFilterInput>;
  readonly sizeInCurrency?: InputMaybe<DecimalOperationFilterInput>;
  readonly sizeInShares?: InputMaybe<LongOperationFilterInput>;
  readonly stage?: InputMaybe<OfferingStageFilterInput>;
  readonly status?: InputMaybe<OfferingStatusFilterInput>;
  readonly supersededByOfferingId?: InputMaybe<UuidOperationFilterInput>;
  readonly syndicate?: InputMaybe<SyndicateFullFilterInput>;
  readonly terms?: InputMaybe<TermsFullFilterInput>;
  readonly timing?: InputMaybe<TimingFullFilterInput>;
  readonly tranches?: InputMaybe<ListFilterInputTypeOfTrancheFullFilterInput>;
  readonly type?: InputMaybe<OfferingTypeFilterInput>;
  readonly version?: InputMaybe<StringOperationFilterInput>;
};

export type OfferingHeaderUnion = Offering | OfferingProfile;

export type OfferingLimited = {
  readonly __typename?: 'OfferingLimited';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly finalAllocation?: Maybe<FinalAllocation>;
  readonly finalFilingOfferPrice?: Maybe<Scalars['Decimal']>;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly fundIois: FundIois;
  readonly funds: ReadonlyArray<Fund>;
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  readonly hasUnpublishedChanges?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['UUID'];
  readonly isAuthor: Scalars['Boolean'];
  readonly isExecutingOnPlatform: Scalars['Boolean'];
  readonly issuerName: Scalars['String'];
  readonly issuerSector?: Maybe<Sector>;
  /** Display name for IssuerSector */
  readonly issuerSectorDisplayName?: Maybe<Scalars['String']>;
  readonly isUsOffering?: Maybe<Scalars['Boolean']>;
  readonly launchDate?: Maybe<Scalars['LocalDate']>;
  readonly leftLead?: Maybe<Scalars['String']>;
  readonly leftLeadFirmName?: Maybe<Scalars['String']>;
  readonly managers: ReadonlyArray<ManagerLimited>;
  readonly mdlAllocation?: Maybe<MdlAllocationResponse>;
  readonly mdlIndications?: Maybe<MdlIndications>;
  readonly myInstitutionalIndication?: Maybe<MyInstitutionalIndication>;
  readonly postponedDate?: Maybe<Scalars['LocalDate']>;
  readonly priceRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly priceRangeLow?: Maybe<Scalars['Decimal']>;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly pricingInstrumentCountryCode?: Maybe<Scalars['String']>;
  readonly pricingInstrumentCountryDisplayName?: Maybe<Scalars['String']>;
  readonly pricingInstrumentRegion?: Maybe<Scalars['String']>;
  readonly pricingInstrumentStockSymbol?: Maybe<Scalars['String']>;
  readonly publicFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly revisedFilingsCount?: Maybe<Scalars['Int']>;
  readonly securityIsDepositaryReceipt?: Maybe<Scalars['Boolean']>;
  readonly securityType: SecurityType;
  readonly shareHistory: OfferingSharedDraftHistory;
  readonly sizeInCurrency?: Maybe<Scalars['Decimal']>;
  readonly sizeInShares?: Maybe<Scalars['Long']>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  readonly supersededByOfferingId?: Maybe<Scalars['UUID']>;
  readonly supersededOfferingId?: Maybe<Scalars['UUID']>;
  readonly syndicateEntitlements: ReadonlyArray<SyndicateEntitlements>;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
  readonly version: Scalars['String'];
  readonly withdrawnDate?: Maybe<Scalars['LocalDate']>;
};

export type OfferingList = {
  readonly __typename?: 'OfferingList';
  readonly data: ReadonlyArray<OfferingLimited>;
};

export enum OfferingNoteSubject {
  Allocation = 'ALLOCATION',
  Compliance = 'COMPLIANCE',
  General = 'GENERAL',
  Indication = 'INDICATION',
  Messaging = 'MESSAGING',
}

export type OfferingOrderEntryProfile = {
  readonly __typename?: 'OfferingOrderEntryProfile';
  readonly id: Scalars['ID'];
  readonly instruments: ReadonlyArray<InstrumentOrderEntryProfile>;
  readonly isExecutingOnPlatform: Scalars['Boolean'];
  readonly tranches: ReadonlyArray<TranchePrivileged>;
};

export type OfferingPaginatedList = {
  readonly __typename?: 'OfferingPaginatedList';
  readonly data: ReadonlyArray<OfferingLimited>;
  readonly pagination: PaginationData;
};

export type OfferingProfile = {
  readonly __typename?: 'OfferingProfile';
  /**
   * Deprecated; Use DemandCurrenciesWithExchangeRates instead.
   * @deprecated Use DemandCurrenciesWithExchangeRates instead.
   */
  readonly demandCurrencies: ReadonlyArray<Scalars['String']>;
  readonly demandCurrenciesWithExchangeRates: ReadonlyArray<DemandCurrencyProfile>;
  /** Returns a derived offering price */
  readonly derivedOfferPrice?: Maybe<Scalars['Decimal']>;
  readonly disclaimers: DisclaimersProfile;
  readonly entitlements: PublishedSyndicateEntitlements;
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  /** Returns high end of price range for IPOs, Overnights, Registered and Unregistered Blocks from the Initial Filing */
  readonly initialFilingPriceRangeHigh?: Maybe<Scalars['Decimal']>;
  /** Returns low end of price range for IPOs, Overnights, Registered and Unregistered Blocks from the Initial Filing */
  readonly initialFilingPriceRangeLow?: Maybe<Scalars['Decimal']>;
  readonly instruments: ReadonlyArray<DeliveryInstrumentPrivileged>;
  readonly isAuthor: Scalars['Boolean'];
  readonly isDiscarded: Scalars['Boolean'];
  readonly isExecutingOnPlatform: Scalars['Boolean'];
  readonly issuer: Issuer;
  /** Returns high end of price range for IPOs, Overnights, Registered and Unregistered Blocks from the most recent filing */
  readonly latestFilingPriceRangeHigh?: Maybe<Scalars['Decimal']>;
  /** Returns low end of price range for IPOs, Overnights, Registered and Unregistered Blocks from the most recent filing */
  readonly latestFilingPriceRangeLow?: Maybe<Scalars['Decimal']>;
  readonly managerEconomics?: Maybe<ManagerEconomicsProfile>;
  readonly marketing: Marketing;
  readonly orderBook: OrderBook;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingInstrumentId: Scalars['UUID'];
  /** Returns the pricing instrument stock symbol for an Offering */
  readonly pricingInstrumentStockSymbol?: Maybe<Scalars['String']>;
  readonly prospectusDocuments: ReadonlyArray<ProspectusDocumentProfile>;
  readonly roadshowSchedules: ReadonlyArray<RoadshowScheduleProfile>;
  readonly security: SecurityProfile;
  readonly sizeInCurrency?: Maybe<Scalars['Decimal']>;
  readonly sizeInShares?: Maybe<Scalars['Long']>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  readonly supersededByOfferingId?: Maybe<Scalars['UUID']>;
  readonly supersededOfferingId?: Maybe<Scalars['UUID']>;
  readonly syndicate: SyndicateProfile;
  readonly syndicateEntitlements: Entitlements;
  readonly terms: TermsProfile;
  readonly timing: TimingProfile;
  /** Null is returned if your firm does not have an active bookrunner relationship with this offering. */
  readonly tranches?: Maybe<ReadonlyArray<TranchePrivileged>>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
  readonly version: Scalars['String'];
};

export type OfferingProfileSyndicateEntitlementsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export enum OfferingSecurityType {
  CommonOrdinaryShare = 'COMMON_ORDINARY_SHARE',
  ConvertibleBond = 'CONVERTIBLE_BOND',
  LimitedPartnershipUnit = 'LIMITED_PARTNERSHIP_UNIT',
  PreferredShare = 'PREFERRED_SHARE',
  UnitShareAndWarrants = 'UNIT_SHARE_AND_WARRANTS',
}

export enum OfferingSetupExchangeMic {
  Aqse = 'AQSE',
  Bats = 'BATS',
  Bvmf = 'BVMF',
  Difx = 'DIFX',
  Dsmd = 'DSMD',
  Hstc = 'HSTC',
  Misx = 'MISX',
  Neoe = 'NEOE',
  Notc = 'NOTC',
  Xads = 'XADS',
  Xamm = 'XAMM',
  Xams = 'XAMS',
  Xasx = 'XASX',
  Xath = 'XATH',
  Xbah = 'XBAH',
  Xbkk = 'XBKK',
  Xbog = 'XBOG',
  Xbom = 'XBOM',
  Xbru = 'XBRU',
  Xbrv = 'XBRV',
  Xbse = 'XBSE',
  Xbud = 'XBUD',
  Xbue = 'XBUE',
  Xcai = 'XCAI',
  Xcas = 'XCAS',
  Xcnq = 'XCNQ',
  Xcol = 'XCOL',
  Xcse = 'XCSE',
  Xcys = 'XCYS',
  Xdfm = 'XDFM',
  Xdha = 'XDHA',
  Xdse = 'XDSE',
  Xdub = 'XDUB',
  Xfra = 'XFRA',
  Xhel = 'XHEL',
  Xhkg = 'XHKG',
  Xice = 'XICE',
  Xidx = 'XIDX',
  Xist = 'XIST',
  Xjse = 'XJSE',
  Xkar = 'XKAR',
  Xkls = 'XKLS',
  Xkrx = 'XKRX',
  Xkuw = 'XKUW',
  Xlim = 'XLIM',
  Xlis = 'XLIS',
  Xlju = 'XLJU',
  Xlon = 'XLON',
  Xlux = 'XLUX',
  Xmad = 'XMAD',
  Xmex = 'XMEX',
  Xmil = 'XMIL',
  Xmus = 'XMUS',
  Xnai = 'XNAI',
  Xnas = 'XNAS',
  Xnec = 'XNEC',
  Xngm = 'XNGM',
  Xnsa = 'XNSA',
  Xnse = 'XNSE',
  Xnys = 'XNYS',
  Xnze = 'XNZE',
  Xosl = 'XOSL',
  Xpar = 'XPAR',
  Xphs = 'XPHS',
  Xpra = 'XPRA',
  Xsau = 'XSAU',
  Xses = 'XSES',
  Xsgo = 'XSGO',
  Xshe = 'XSHE',
  Xshg = 'XSHG',
  Xstc = 'XSTC',
  Xsto = 'XSTO',
  Xswx = 'XSWX',
  Xtae = 'XTAE',
  Xtai = 'XTAI',
  Xtal = 'XTAL',
  Xtks = 'XTKS',
  Xtse = 'XTSE',
  Xtsx = 'XTSX',
  Xwar = 'XWAR',
  Xwbo = 'XWBO',
  Xzag = 'XZAG',
}

export type OfferingSetupExchangeMicFilterInput = {
  readonly eq?: InputMaybe<OfferingSetupExchangeMic>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<OfferingSetupExchangeMic>>>;
  readonly neq?: InputMaybe<OfferingSetupExchangeMic>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<OfferingSetupExchangeMic>>>;
};

export type OfferingSharedDraftHistory = {
  readonly __typename?: 'OfferingSharedDraftHistory';
  readonly id: Scalars['ID'];
  readonly offeringId: Scalars['UUID'];
  readonly shareDraftHistories: ReadonlyArray<SharedDraftHistory>;
};

export enum OfferingStage {
  Discarded = 'DISCARDED',
  Draft = 'DRAFT',
  Erroneous = 'ERRONEOUS',
  Obsolete = 'OBSOLETE',
  Published = 'PUBLISHED',
}

export type OfferingStageFilterInput = {
  readonly eq?: InputMaybe<OfferingStage>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<OfferingStage>>>;
  readonly neq?: InputMaybe<OfferingStage>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<OfferingStage>>>;
};

export enum OfferingStatus {
  Filed = 'FILED',
  Live = 'LIVE',
  Postponed = 'POSTPONED',
  Priced = 'PRICED',
  Terminated = 'TERMINATED',
  Withdrawn = 'WITHDRAWN',
}

export type OfferingStatusFilterInput = {
  readonly eq?: InputMaybe<OfferingStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<OfferingStatus>>>;
  readonly neq?: InputMaybe<OfferingStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<OfferingStatus>>>;
};

export enum OfferingType {
  FollowOn = 'FOLLOW_ON',
  Ipo = 'IPO',
  IpoSpac = 'IPO_SPAC',
  MarketedFo = 'MARKETED_FO',
  OvernightFo = 'OVERNIGHT_FO',
  RegisteredBlock = 'REGISTERED_BLOCK',
  RegisteredDirect = 'REGISTERED_DIRECT',
  Rights = 'RIGHTS',
  UnregisteredBlock = 'UNREGISTERED_BLOCK',
}

export type OfferingTypeFilterInput = {
  readonly eq?: InputMaybe<OfferingType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<OfferingType>>>;
  readonly neq?: InputMaybe<OfferingType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<OfferingType>>>;
};

/** Union of Offering and ServiceError, either an Offering or ServiceError is returned */
export type OfferingUnion = Offering | ServiceError;

export type OfferingValidation = {
  readonly __typename?: 'OfferingValidation';
  readonly errors: ReadonlyArray<OfferingError>;
};

export type OfferingVersion = {
  readonly __typename?: 'OfferingVersion';
  readonly description: Scalars['String'];
  readonly timestamp: Scalars['Instant'];
  readonly version: Scalars['String'];
};

export type OrderBook = {
  readonly __typename?: 'OrderBook';
  readonly auditInfo: AuditInfo;
  readonly finalInstitutionalAllocations: ReadonlyArray<FinalAllocation>;
  readonly id: Scalars['ID'];
  /**
   * *** This property is deprecated and will be removed in a future release. ***
   * @deprecated This property is deprecated and will be removed in a future release.
   */
  readonly indicationMaximum: Scalars['Decimal'];
  readonly institutionalIndication?: Maybe<InstitutionalIndication>;
  readonly institutionalIndications: ReadonlyArray<InstitutionalIndication>;
  /** The ID of the Offering for which this Order Book accepts Indications. */
  readonly offeringId: Scalars['ID'];
  /** The current state of this Order Book. */
  readonly state: OrderBookState;
  readonly version: Scalars['String'];
};

export type OrderBookInstitutionalIndicationArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type OrderBookInstitutionalIndicationsArgs = {
  filters?: InputMaybe<InstitutionalIndicationListFiltersInput>;
};

export enum OrderBookErrorType {
  BillingAndDelivery = 'BILLING_AND_DELIVERY',
  MyRetail = 'MY_RETAIL',
  NoFinalAllocations = 'NO_FINAL_ALLOCATIONS',
  NoIndications = 'NO_INDICATIONS',
  NoReleasedFinalAllocations = 'NO_RELEASED_FINAL_ALLOCATIONS',
  RetailDemand = 'RETAIL_DEMAND',
  RetailRetention = 'RETAIL_RETENTION',
}

export type OrderBookFull = {
  readonly __typename?: 'OrderBookFull';
  readonly finalAllocation?: Maybe<FinalAllocation>;
  readonly myInstitutionalIndication?: Maybe<MyInstitutionalIndication>;
};

export type OrderBookGraph = {
  readonly __typename?: 'OrderBookGraph';
  readonly auditInfo: AuditInformation;
  readonly offeringId: Scalars['UUID'];
  readonly salesState: OrderBookState;
  readonly state: OrderBookState;
  readonly version: Scalars['String'];
};

export enum OrderBookInvestorStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum OrderBookManagerRole {
  ActiveBookrunner = 'ACTIVE_BOOKRUNNER',
  Bookrunner = 'BOOKRUNNER',
  CoLead = 'CO_LEAD',
  CoManager = 'CO_MANAGER',
  GlobalCoordinator = 'GLOBAL_COORDINATOR',
  Invalid = 'INVALID',
  SellingGroupMember = 'SELLING_GROUP_MEMBER',
  Underwriter = 'UNDERWRITER',
}

export enum OrderBookRecordStatus {
  Deleted = 'DELETED',
  Effective = 'EFFECTIVE',
}

export type OrderBookSalesState = {
  readonly __typename?: 'OrderBookSalesState';
  readonly offeringId: Scalars['UUID'];
  readonly salesState: OrderBookState;
};

export enum OrderBookState {
  /** The Closed state, which prevents anyone other than the Settlement Agent from modifying the Order Book. */
  Closed = 'CLOSED',
  /** The Never Opened state, which prevents an Order Book from accepting Indications from Investors. */
  NeverOpened = 'NEVER_OPENED',
  /** The Open state, which allows an Order Book to accept Indications from Investors. */
  Open = 'OPEN',
}

export type OrderBookStatus = {
  readonly __typename?: 'OrderBookStatus';
  readonly offeringId: Scalars['UUID'];
  readonly state: OrderBookState;
};

export type OverAllotmentExercisedData = {
  readonly __typename?: 'OverAllotmentExercisedData';
  readonly notional?: Maybe<Scalars['Decimal']>;
  readonly primaryShares?: Maybe<Scalars['Long']>;
  readonly secondaryShares?: Maybe<Scalars['Long']>;
  readonly totalShares?: Maybe<Scalars['Long']>;
};

export type OverAllotmentExercisedFull = {
  readonly __typename?: 'OverAllotmentExercisedFull';
  readonly notional?: Maybe<Scalars['Decimal']>;
  readonly primaryShares?: Maybe<Scalars['Long']>;
  readonly secondaryShares?: Maybe<Scalars['Long']>;
  readonly totalShares?: Maybe<Scalars['Long']>;
};

export type OverAllotmentExercisedFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<OverAllotmentExercisedFullFilterInput>>;
  readonly notional?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<OverAllotmentExercisedFullFilterInput>>;
  readonly primaryShares?: InputMaybe<LongOperationFilterInput>;
  readonly secondaryShares?: InputMaybe<LongOperationFilterInput>;
  readonly totalShares?: InputMaybe<LongOperationFilterInput>;
};

export type OverwriteDesignationInput = {
  /** Collection of manual designations. */
  readonly designations?: InputMaybe<ReadonlyArray<DesignationUpdateInput>>;
  /** Collection of allocated indications ids to be designated using fixed economics. */
  readonly indicationIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** The type of assign operation. */
  readonly operationType: DesignationStrategy;
};

export type PaginationData = {
  readonly __typename?: 'PaginationData';
  /** Current page */
  readonly activePage: Scalars['Int'];
  /** Indicate if there is a next page */
  readonly hasNext?: Maybe<Scalars['Boolean']>;
  /** Indicate if there is a previous page */
  readonly hasPrevious: Scalars['Boolean'];
  /** Number of records per page */
  readonly perPage: Scalars['Int'];
  /** Total number of records */
  readonly totalCount?: Maybe<Scalars['Int']>;
  /** Total pages */
  readonly totalPages?: Maybe<Scalars['Int']>;
};

export type PaginationDataInput = {
  readonly includeTotals?: InputMaybe<Scalars['Boolean']>;
  readonly orderDirection?: InputMaybe<SortOrderDirection>;
  readonly orderField?: InputMaybe<Scalars['String']>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly perPage?: InputMaybe<Scalars['Int']>;
};

export type PassOnOfferingResponseUnion = MyInstitutionalIndication | ServiceError;

export type PaymentRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly dtcNumber: Scalars['String'];
  readonly ipoPenaltyTracking: Scalars['Boolean'];
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
};

export type PaymentRecipientUserData = {
  readonly __typename?: 'PaymentRecipientUserData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly dtcNumber: Scalars['String'];
  readonly ipoPenaltyTracking: Scalars['Boolean'];
  readonly payment: Scalars['Decimal'];
  readonly recipient: Scalars['String'];
};

export type PaymentWire = {
  readonly __typename?: 'PaymentWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<PaymentRecipientUserData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type PaymentWireInput = {
  readonly userDataForRecipients: ReadonlyArray<PaymentRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type PaymentWireResponseUnion = PaymentWire | ServiceError;

export type PaymentWireValidation = WireValidationBase & {
  readonly __typename?: 'PaymentWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<PaymentWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum PaymentWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  PricePerShare = 'PRICE_PER_SHARE',
  SecurityType = 'SECURITY_TYPE',
  SellingConcessionPerShare = 'SELLING_CONCESSION_PER_SHARE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type PercentagePerCmgEntity = {
  readonly __typename?: 'PercentagePerCmgEntity';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly percentageValue: Scalars['Decimal'];
};

export type PercentagePerCmgEntityFull = {
  readonly __typename?: 'PercentagePerCmgEntityFull';
  readonly cmgEntityKey: Scalars['String'];
  readonly percentageValue: Scalars['Decimal'];
};

export type PercentagePerCmgEntityFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<PercentagePerCmgEntityFullFilterInput>>;
  readonly cmgEntityKey?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<PercentagePerCmgEntityFullFilterInput>>;
  readonly percentageValue?: InputMaybe<DecimalOperationFilterInput>;
};

export type PercentagePerCmgEntityInput = {
  readonly cmgEntityKey: Scalars['String'];
  readonly percentageValue: Scalars['Decimal'];
};

export type PostponedPublishedOfferingListFiltersInput = {
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly endPostponedDate: Scalars['Date'];
  readonly includeTestOfferings?: InputMaybe<Scalars['Boolean']>;
  readonly startPostponedDate: Scalars['Date'];
};

export type PotAccountWire = {
  readonly __typename?: 'PotAccountWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly headerNote?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type PotAccountWireResponseUnion = PotAccountWire | ServiceError;

export type PotAccountWireValidation = WireValidationBase & {
  readonly __typename?: 'PotAccountWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<PotAccountWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum PotAccountWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type PotAllocationWire = {
  readonly __typename?: 'PotAllocationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly headerNote?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type PotAllocationWireResponseUnion = PotAllocationWire | ServiceError;

export type PotAllocationWireValidation = WireValidationBase & {
  readonly __typename?: 'PotAllocationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<PotAllocationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum PotAllocationWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type PricedPublishedOfferingListFiltersInput = {
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly endPricingDate: Scalars['Date'];
  readonly includeTestOfferings?: InputMaybe<Scalars['Boolean']>;
  readonly startPricingDate: Scalars['Date'];
};

export enum PricingBasisType {
  Atm = 'ATM',
  Dlsts = 'DLSTS',
  Lstsl = 'LSTSL',
  Ngttd = 'NGTTD',
  Other = 'OTHER',
}

export type PricingTermsRetentionRecipientUserData = {
  readonly __typename?: 'PricingTermsRetentionRecipientUserData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type PricingTermsRetentionUserDataForRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type PricingTermsRetentionWire = {
  readonly __typename?: 'PricingTermsRetentionWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<PricingTermsRetentionRecipientUserData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type PricingTermsRetentionWireResponseUnion = PricingTermsRetentionWire | ServiceError;

export type PricingTermsRetentionWireValidation = WireValidationBase & {
  readonly __typename?: 'PricingTermsRetentionWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<PricingTermsRetentionWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum PricingTermsRetentionWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  ExchangeMic = 'EXCHANGE_MIC',
  FirstTradeDate = 'FIRST_TRADE_DATE',
  GrossSpread = 'GROSS_SPREAD',
  IssuerName = 'ISSUER_NAME',
  ManagementFee = 'MANAGEMENT_FEE',
  OfferingPrice = 'OFFERING_PRICE',
  OfferingType = 'OFFERING_TYPE',
  ReAllowance = 'RE_ALLOWANCE',
  SecurityType = 'SECURITY_TYPE',
  SellingConcession = 'SELLING_CONCESSION',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
  UnderwritingFee = 'UNDERWRITING_FEE',
}

export type PricingTermsWire = {
  readonly __typename?: 'PricingTermsWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type PricingTermsWireResponseUnion = PricingTermsWire | ServiceError;

export type PricingTermsWireValidation = WireValidationBase & {
  readonly __typename?: 'PricingTermsWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<PricingTermsWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum PricingTermsWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  ExchangeMic = 'EXCHANGE_MIC',
  FirstTradeDate = 'FIRST_TRADE_DATE',
  GrossSpread = 'GROSS_SPREAD',
  IssuerName = 'ISSUER_NAME',
  ManagementFee = 'MANAGEMENT_FEE',
  OfferingPrice = 'OFFERING_PRICE',
  OfferingType = 'OFFERING_TYPE',
  ReAllowance = 'RE_ALLOWANCE',
  SecurityType = 'SECURITY_TYPE',
  SellingConcession = 'SELLING_CONCESSION',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
  UnderwritingFee = 'UNDERWRITING_FEE',
}

/** A segment of a collection. */
export type PrivateOfferingsCollectionSegment = {
  readonly __typename?: 'PrivateOfferingsCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<OfferingFull>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type ProspectusDelivery = {
  readonly __typename?: 'ProspectusDelivery';
  readonly documents: ReadonlyArray<ProspectusDocumentMetadata>;
  readonly id: Scalars['ID'];
  readonly revisions: ReadonlyArray<LatestEmailStatus>;
  readonly version: Scalars['Long'];
};

export type ProspectusDeliveryRoot = {
  readonly __typename?: 'ProspectusDeliveryRoot';
  readonly id: Scalars['UUID'];
  readonly offeringId: Scalars['UUID'];
  readonly onSendValidationAsSalesAndTrading: ProspectusOnSendValidationResult;
  readonly onSendValidationAsSyndicate: ProspectusOnSendValidationResult;
  readonly salesAndTradingHistory: ReadonlyArray<ProspectusDelivery>;
  readonly singleOnSendValidationAsSalesAndTrading: ProspectusOnSendValidationResult;
  readonly singleOnSendValidationAsSyndicate: ProspectusOnSendValidationResult;
  readonly syndicateHistory: ReadonlyArray<ProspectusDelivery>;
};

export type ProspectusDeliveryRootOnSendValidationAsSalesAndTradingArgs = {
  currentTenantOnly: Scalars['Boolean'];
  investorKeys: ReadonlyArray<Scalars['String']>;
};

export type ProspectusDeliveryRootOnSendValidationAsSyndicateArgs = {
  currentTenantOnly: Scalars['Boolean'];
  investorKeys: ReadonlyArray<Scalars['String']>;
};

export type ProspectusDeliveryRootSalesAndTradingHistoryArgs = {
  cmgEntityKey: Scalars['String'];
};

export type ProspectusDeliveryRootSingleOnSendValidationAsSalesAndTradingArgs = {
  currentTenantOnly: Scalars['Boolean'];
  investorKey: Scalars['String'];
};

export type ProspectusDeliveryRootSingleOnSendValidationAsSyndicateArgs = {
  currentTenantOnly: Scalars['Boolean'];
  investorKey: Scalars['String'];
};

export type ProspectusDeliveryRootSyndicateHistoryArgs = {
  cmgEntityKey: Scalars['String'];
};

export enum ProspectusDeliveryStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
}

export type ProspectusDeliveryStatusOperationFilterInput = {
  readonly eq?: InputMaybe<ProspectusDeliveryStatus>;
  readonly in?: InputMaybe<ReadonlyArray<ProspectusDeliveryStatus>>;
  readonly neq?: InputMaybe<ProspectusDeliveryStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<ProspectusDeliveryStatus>>;
};

export type ProspectusDeliverySummary = {
  readonly __typename?: 'ProspectusDeliverySummary';
  readonly failedEmailCount: Scalars['Int'];
  readonly inProgressEmailCount: Scalars['Int'];
  readonly overallStatus: ProspectusDeliveryStatus;
  readonly overallStatusDisplayValue: Scalars['String'];
  readonly sentEmailCount: Scalars['Int'];
  readonly totalEmailCount: Scalars['Int'];
};

export type ProspectusDeliverySummaryDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ProspectusDeliverySummaryDtoFilterInput>>;
  readonly failedEmailCount?: InputMaybe<IntOperationFilterInput>;
  readonly inProgressEmailCount?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ProspectusDeliverySummaryDtoFilterInput>>;
  readonly overallStatus?: InputMaybe<ProspectusDeliveryStatusOperationFilterInput>;
  readonly overallStatusDisplayValue?: InputMaybe<StringOperationFilterInput>;
  readonly sentEmailCount?: InputMaybe<IntOperationFilterInput>;
  readonly totalEmailCount?: InputMaybe<IntOperationFilterInput>;
};

export type ProspectusDeliverySummaryDtoSortInput = {
  readonly failedEmailCount?: InputMaybe<SortEnumType>;
  readonly inProgressEmailCount?: InputMaybe<SortEnumType>;
  readonly overallStatus?: InputMaybe<SortEnumType>;
  readonly overallStatusDisplayValue?: InputMaybe<SortEnumType>;
  readonly sentEmailCount?: InputMaybe<SortEnumType>;
  readonly totalEmailCount?: InputMaybe<SortEnumType>;
};

export type ProspectusDocument = {
  readonly __typename?: 'ProspectusDocument';
  readonly documentName: Scalars['String'];
  readonly firstPublishedAt?: Maybe<Scalars['Instant']>;
  readonly id: Scalars['ID'];
  readonly includeOnPublish: Scalars['Boolean'];
  readonly publicUrl?: Maybe<Scalars['String']>;
  readonly uploadedAt: Scalars['Instant'];
};

export type ProspectusDocumentFull = {
  readonly __typename?: 'ProspectusDocumentFull';
  readonly documentName: Scalars['String'];
  readonly firstPublishedAt: Scalars['Instant'];
  readonly id: Scalars['UUID'];
  readonly publicUrl: Scalars['String'];
};

export type ProspectusDocumentFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ProspectusDocumentFullFilterInput>>;
  readonly documentName?: InputMaybe<StringOperationFilterInput>;
  readonly firstPublishedAt?: InputMaybe<InstantOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ProspectusDocumentFullFilterInput>>;
  readonly publicUrl?: InputMaybe<StringOperationFilterInput>;
};

export type ProspectusDocumentMetadata = {
  readonly __typename?: 'ProspectusDocumentMetadata';
  readonly fileName: Scalars['String'];
  readonly publishedAt: Scalars['Instant'];
  readonly url: Scalars['String'];
};

export type ProspectusDocumentProfile = {
  readonly __typename?: 'ProspectusDocumentProfile';
  readonly documentName: Scalars['String'];
  readonly firstPublishedAt?: Maybe<Scalars['Instant']>;
  readonly id: Scalars['ID'];
  readonly publicUrl: Scalars['String'];
};

export type ProspectusDocumentUpdateMetadata = {
  readonly documentName: Scalars['String'];
  readonly id: Scalars['String'];
  readonly includeOnPublish: Scalars['Boolean'];
};

export enum ProspectusErrorType {
  DisclaimerMissing = 'DISCLAIMER_MISSING',
  ProspectusDocumentsMissing = 'PROSPECTUS_DOCUMENTS_MISSING',
  ProspectusMissing = 'PROSPECTUS_MISSING',
}

export enum ProspectusInvestorErrorType {
  IndicationNotAcknowledged = 'INDICATION_NOT_ACKNOWLEDGED',
  InvestorContactMissing = 'INVESTOR_CONTACT_MISSING',
}

export type ProspectusInvestorValidationError = {
  readonly __typename?: 'ProspectusInvestorValidationError';
  readonly errorType: ProspectusInvestorErrorType;
  readonly id: Scalars['ID'];
  readonly investorKey: Scalars['String'];
  readonly message: Scalars['String'];
};

export type ProspectusOnSendValidationResult = {
  readonly __typename?: 'ProspectusOnSendValidationResult';
  readonly id: Scalars['ID'];
  readonly investorErrors: ReadonlyArray<ProspectusInvestorValidationError>;
  readonly prospectusErrors: ReadonlyArray<ProspectusValidationError>;
};

export type ProspectusValidationError = {
  readonly __typename?: 'ProspectusValidationError';
  readonly errorType: ProspectusErrorType;
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
};

export type Public = {
  readonly __typename?: 'Public';
  readonly syndicateWires: PublicWires;
};

export type PublicOrderBook = {
  readonly __typename?: 'PublicOrderBook';
  /** The ID of the Offering for which this Order Book accepts Indications. */
  readonly offeringId: Scalars['UUID'];
  /** The current state of this Order Book. */
  readonly state: OrderBookState;
};

export type PublicWires = {
  readonly __typename?: 'PublicWires';
  readonly sentWire: SentWireStatus;
};

export type PublicWiresSentWireArgs = {
  sentWireId: Scalars['UUID'];
};

/** A segment of a collection. */
export type PublishedOfferingsCollectionSegment = {
  readonly __typename?: 'PublishedOfferingsCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<OfferingFull>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type PublishedOfferingsListFiltersInput = {
  readonly offeringId: Scalars['UUID'];
};

export type PublishedSyndicateEntitlements = {
  readonly __typename?: 'PublishedSyndicateEntitlements';
  readonly cmgEntityKey: Scalars['String'];
  readonly entitlements: ReadonlyArray<Scalars['String']>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly accountTraits: ReadonlyArray<AccountTrait>;
  readonly allCoveredInvestorFirmsMappedToCmgEntityKey: ReadonlyArray<InvestorFirmBasic>;
  readonly allInvestorFirmsMappedToCmgEntityKey: ReadonlyArray<InvestorFirmBasic>;
  readonly allInvestorFirmsMappedToCmgEntityKeys: ReadonlyArray<InvestorFirmMappedWithCmgEntityKey>;
  readonly allocationSets: ReadonlyArray<AllocationSet>;
  readonly allOfferings: OfferingList;
  readonly allSharedDrafts: SharedDraftOfferingList;
  readonly attestation: Attestation;
  readonly attestationStatuses: ReadonlyArray<AttestationStatus>;
  readonly availableShareDraftManagers: ReadonlyArray<ManagerLimited>;
  /** Returns list of Goldman Sachs affiliates */
  readonly bankAffiliates: ReadonlyArray<AffiliateMember>;
  readonly basicPublishedOffering: BasicPublishedOfferingProfile;
  readonly communicatedAllocationDemand: MdlAllocationResponse;
  readonly communicatedIndicationsDemand: MdlCommunicatedDemandIndications;
  readonly companyDetails: Company;
  readonly companySearch: CompanySearchPagedList;
  readonly contacts: ContactCollection;
  readonly coveredAttestation: Attestation;
  readonly coveredAttestationStatuses: ReadonlyArray<AttestationStatus>;
  readonly coveredContacts: ContactCollection;
  /** Returns Covered Indication for S/T user */
  readonly coveredIndication?: Maybe<CoveredInstitutionalIndication>;
  /** Returns Covered Indications by cmgEntityKey for S/T user */
  readonly coveredIndicationByCmgEntityKey?: Maybe<CoveredInstitutionalIndication>;
  /** Returns Covered Indications for S/T user */
  readonly coveredIndications: ReadonlyArray<CoveredInstitutionalIndicationWithAttestation>;
  readonly coveredIndicationVersions: ReadonlyArray<CoveredInstitutionalIndicationVersion>;
  readonly coveredInvestorFirms: InvestorFirmBasicPagedList;
  readonly coveredInvestorFirmsEmployees: ReadonlyArray<InvestorEmployee>;
  readonly coveredRoadshow: Roadshow;
  /** Retrieves list of covered Roadshow events - meetings and logistics */
  readonly coveredRoadshowEvents: ReadonlyArray<RoadshowEvent>;
  readonly coveredRoadshowLogisticsDetails: Logistics;
  readonly coveredRoadshowLogisticsParticipants: ReadonlyArray<EventParticipantsTeam>;
  /** Returns meeting details for covered roadshow */
  readonly coveredRoadshowMeeting: Meeting;
  readonly coveredRoadshows: RoadshowLimitedPagedList;
  readonly datalabName: Scalars['String'];
  readonly dealTeam: DealTeam;
  readonly dealTeamExists: Scalars['Boolean'];
  readonly dealTeamMemberRoadshowNames: DealTeamMemberRoadshowNames;
  readonly dealTeams: DealTeamLimitedPagedList;
  /** List all designations for given indication. */
  readonly designationByIndicationId: InvestorDesignation;
  readonly designationsGrid: DesignationsGrid;
  /** Get Designation Validation */
  readonly designationValidation: DesignationValidation;
  /** Returns DesignationWire wire details */
  readonly designationWireDetails: DesignationWire;
  /** Returns a list of all DesignationWire wires */
  readonly designationWireList: ReadonlyArray<DesignationWire>;
  /** Returns DesignationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly designationWirePreview: WirePreview;
  /** Returns DesignationWire wire validation */
  readonly designationWireValidation: DesignationWireValidation;
  readonly ensureMyOrganizationIsUpToDate: Scalars['Boolean'];
  /** Returns an Entitlements Configuration */
  readonly entitlementsConfiguration: ReadonlyArray<EntitlementsConfiguration>;
  readonly expenses: Expenses;
  readonly filedPublishedOfferings: OfferingList;
  /** Returns common values, that were sent in last filing */
  readonly filingLastCommonValues: FilingLastCommonValues;
  /** Returns offering details for given regulatory filing' */
  readonly filingOfferingDetail: WireOfferingDetail;
  readonly finalInstitutionalAllocationSet?: Maybe<FinalInstitutionalAllocationSet>;
  /** Get FINRA server availability. */
  readonly finraStatus: FinraStatus;
  readonly firmCertificatesByCmgEntityKeyAndInvestorId?: Maybe<FirmCertificates>;
  readonly firmInvestorExtendedData: InvestorExtendedData;
  readonly firmInvestorsExtendedData: ReadonlyArray<InvestorExtendedData>;
  readonly firmInvestorsRelationships: ReadonlyArray<InvestorFirmBasic>;
  readonly firmsCertificates?: Maybe<FirmsCertificatesCollectionSegment>;
  /** Returns FreeFormWire wire details */
  readonly freeFormWireDetails: FreeFormWire;
  /** Returns a list of all FreeFormWire wires */
  readonly freeFormWireList: ReadonlyArray<FreeFormWire>;
  /** Returns FreeFormWire wire template preview. A null syndicateWireId returns the default template. */
  readonly freeFormWirePreview: WirePreview;
  /** Returns FreeFormWire wire validation */
  readonly freeFormWireValidation: FreeFormWireValidation;
  readonly getFilings: ReadonlyArray<Filing>;
  readonly getInstitutionalIndicationNotes: ReadonlyArray<Note>;
  readonly getUnderwritingFeesAndDiscounts: UnderwritingFeesAndDiscounts;
  /** Returns GreenShoeWire wire details */
  readonly greenShoeWireDetails: GreenShoeWire;
  /** Returns a list of all GreenShoeWire wires */
  readonly greenShoeWireList: ReadonlyArray<GreenShoeWire>;
  /** Returns GreenShoeWire wire template preview. A null syndicateWireId returns the default template. */
  readonly greenShoeWirePreview: WirePreview;
  /** Returns GreenShoeWire wire validation */
  readonly greenShoeWireValidation: GreenShoeWireValidation;
  readonly identityName: Scalars['String'];
  readonly indicationTrades: ReadonlyArray<IndicationTrade>;
  readonly indicationVersions: ReadonlyArray<InstitutionalIndicationVersion>;
  readonly institutionalDemandGridSummary: InstitutionalDemandGridSummary;
  /** Returns a single Institutional Indication by either indicationId or cmgEntityKey */
  readonly institutionalIndication?: Maybe<InstitutionalIndication>;
  /** Return Institutional Indication Acknowledgements */
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<InstitutionalIndicationAcknowledgement>;
  /** Return Institutional Indication Billing And Delivery Agent */
  readonly institutionalIndicationBillingAndDeliveryAgent: BillingAndDeliveryAgent;
  /** Returns the Order Book's Institutional Indications */
  readonly institutionalIndications: ReadonlyArray<InstitutionalIndication>;
  readonly internalAllocationDemand: MdlAllocationResponse;
  readonly internalIndicationsDemand: MdlInternalDemandIndications;
  readonly investorExtensionSchema: ReadonlyArray<InvestorExtensionSchema>;
  readonly investorFirm: InvestorFirmBasic;
  readonly investorFirms: InvestorFirmBasicPagedList;
  readonly investorFirmsEmployeeRelationships: ReadonlyArray<InvestorFirmsEmployeeRelationships>;
  readonly investorFirmsEmployees: ReadonlyArray<InvestorEmployee>;
  /** Returns IssuerAllocationWire wire details */
  readonly issuerAllocationWireDetails: IssuerAllocationWire;
  /** Returns a list of all IssuerAllocationWire wires */
  readonly issuerAllocationWireList: ReadonlyArray<IssuerAllocationWire>;
  /** Returns IssuerAllocationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly issuerAllocationWirePreview: WirePreview;
  /** Returns IssuerAllocationWire wire validation */
  readonly issuerAllocationWireValidation: IssuerAllocationWireValidation;
  /** Returns IssuerIndicationWire wire details */
  readonly issuerIndicationWireDetails: IssuerIndicationWire;
  /** Returns a list of all IssuerIndicationWire wires */
  readonly issuerIndicationWireList: ReadonlyArray<IssuerIndicationWire>;
  /** Returns IssuerIndicationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly issuerIndicationWirePreview: WirePreview;
  /** Returns IssuerIndicationWire wire validation */
  readonly issuerIndicationWireValidation: IssuerIndicationWireValidation;
  /** Returns last Restricted Period for TN Filing */
  readonly lastTnRestrictedPeriod?: Maybe<LastTnRestrictedPeriod>;
  readonly letterEligibleManagers: ReadonlyArray<ExpensesManager>;
  /** Returns Limited Offering data for a given Published Offering ID */
  readonly limitedPublishedOffering: OfferingLimited;
  readonly livePublishedOfferings: OfferingList;
  /** Returns ManagerBdWire wire details */
  readonly managerBdWireDetails: ManagerBdWire;
  /** Returns a list of all ManagerBdWire wires */
  readonly managerBdWireList: ReadonlyArray<ManagerBdWire>;
  /** Returns ManagerBdWire wire template preview. A null syndicateWireId returns the default template. */
  readonly managerBdWirePreview: WirePreview;
  /** Returns ManagerBdWire wire validation */
  readonly managerBdWireValidation: ManagerBdWireValidation;
  readonly mdlAllocationRanking?: Maybe<MdlAllocationRankingResponse>;
  readonly myAttestation: Attestation;
  readonly myIndicationVersions: ReadonlyArray<MyInstitutionalIndicationVersion>;
  readonly myPublishedOfferings: OfferingList;
  readonly myRetailSummary: MyRetailSummary;
  /** Returns NonSyndicateFreeFormWire wire details */
  readonly nonSyndicateFreeFormWireDetails: NonSyndicateFreeFormWire;
  /** Returns a list of all NonSyndicateFreeFormWire wires */
  readonly nonSyndicateFreeFormWireList: ReadonlyArray<NonSyndicateFreeFormWire>;
  /** Returns NonSyndicateFreeFormWire wire template preview. A null syndicateWireId returns the default template. */
  readonly nonSyndicateFreeFormWirePreview: WirePreview;
  /** Returns NonSyndicateFreeFormWire wire validation */
  readonly nonSyndicateFreeFormWireValidation: NonSyndicateFreeFormWireValidation;
  /** Returns NonSyndicatePaymentWire wire details */
  readonly nonSyndicatePaymentWireDetails: NonSyndicatePaymentWire;
  /** Returns a list of all NonSyndicatePaymentWire wires */
  readonly nonSyndicatePaymentWireList: ReadonlyArray<NonSyndicatePaymentWire>;
  /** Returns NonSyndicatePaymentWire wire template preview. A null syndicateWireId returns the default template. */
  readonly nonSyndicatePaymentWirePreview: WirePreview;
  /** Returns NonSyndicatePaymentWire wire validation */
  readonly nonSyndicatePaymentWireValidation: NonSyndicatePaymentWireValidation;
  /** Returns NonSyndicateSellingGroupInvitationWire wire details */
  readonly nonSyndicateSellingGroupInvitationWireDetails: NonSyndicateSellingGroupInvitationWire;
  /** Returns a list of all NonSyndicateSellingGroupInvitationWire wires */
  readonly nonSyndicateSellingGroupInvitationWireList: ReadonlyArray<NonSyndicateSellingGroupInvitationWire>;
  /** Returns NonSyndicateSellingGroupInvitationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly nonSyndicateSellingGroupInvitationWirePreview: WirePreview;
  /** Returns NonSyndicateSellingGroupInvitationWire wire validation */
  readonly nonSyndicateSellingGroupInvitationWireValidation: NonSyndicateSellingGroupInvitationWireValidation;
  /** Returns NonSyndicateSellingGroupTermsRetentionWire wire details */
  readonly nonSyndicateSellingGroupTermsRetentionWireDetails: NonSyndicateSellingGroupTermsRetentionWire;
  /** Returns a list of all NonSyndicateSellingGroupTermsRetentionWire wires */
  readonly nonSyndicateSellingGroupTermsRetentionWireList: ReadonlyArray<NonSyndicateSellingGroupTermsRetentionWire>;
  /** Returns NonSyndicateSellingGroupTermsRetentionWire wire template preview. A null syndicateWireId returns the default template. */
  readonly nonSyndicateSellingGroupTermsRetentionWirePreview: WirePreview;
  /** Returns NonSyndicateSellingGroupTermsRetentionWire wire validation */
  readonly nonSyndicateSellingGroupTermsRetentionWireValidation: NonSyndicateSellingGroupTermsRetentionWireValidation;
  /** Returns NonSyndicateTerminationWire wire details */
  readonly nonSyndicateTerminationWireDetails: NonSyndicateTerminationWire;
  /** Returns a list of all NonSyndicateTerminationWire wires */
  readonly nonSyndicateTerminationWireList: ReadonlyArray<NonSyndicateTerminationWire>;
  /** Returns NonSyndicateTerminationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly nonSyndicateTerminationWirePreview: WirePreview;
  /** Returns NonSyndicateTerminationWire wire validation */
  readonly nonSyndicateTerminationWireValidation: NonSyndicateTerminationWireValidation;
  /** Returns the offering with the given id */
  readonly offering: Offering;
  readonly offeringHeader: OfferingHeaderUnion;
  readonly offeringNotes: MdlOfferingNotes;
  readonly offerings: OfferingPaginatedList;
  readonly offeringSetupName: Scalars['String'];
  readonly orderBook: OrderBook;
  readonly orderBookGraph?: Maybe<OrderBookGraph>;
  readonly orderBookName: Scalars['String'];
  readonly pastFirmCertificatesByCmgEntityKey: ReadonlyArray<Certificate>;
  /** Returns PaymentWire wire details */
  readonly paymentWireDetails: PaymentWire;
  /** Returns a list of all PaymentWire wires */
  readonly paymentWireList: ReadonlyArray<PaymentWire>;
  /** Returns PaymentWire wire template preview. A null syndicateWireId returns the default template. */
  readonly paymentWirePreview: WirePreview;
  /** Returns PaymentWire wire validation */
  readonly paymentWireValidation: PaymentWireValidation;
  readonly postponedPublishedOfferings: OfferingList;
  /** Returns PotAccountWire wire details */
  readonly potAccountWireDetails: PotAccountWire;
  /** Returns a list of all PotAccountWire wires */
  readonly potAccountWireList: ReadonlyArray<PotAccountWire>;
  /** Returns PotAccountWire wire template preview. A null syndicateWireId returns the default template. */
  readonly potAccountWirePreview: WirePreview;
  /** Returns PotAccountWire wire validation */
  readonly potAccountWireValidation: PotAccountWireValidation;
  /** Returns PotAllocationWire wire details */
  readonly potAllocationWireDetails: PotAllocationWire;
  /** Returns a list of all PotAllocationWire wires */
  readonly potAllocationWireList: ReadonlyArray<PotAllocationWire>;
  /** Returns PotAllocationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly potAllocationWirePreview: WirePreview;
  /** Returns PotAllocationWire wire validation */
  readonly potAllocationWireValidation: PotAllocationWireValidation;
  readonly pricedPublishedOfferings: OfferingList;
  /** Returns PricingTermsRetentionWire wire details */
  readonly pricingTermsRetentionWireDetails: PricingTermsRetentionWire;
  /** Returns a list of all PricingTermsRetentionWire wires */
  readonly pricingTermsRetentionWireList: ReadonlyArray<PricingTermsRetentionWire>;
  /** Returns PricingTermsRetentionWire wire template preview. A null syndicateWireId returns the default template. */
  readonly pricingTermsRetentionWirePreview: WirePreview;
  /** Returns PricingTermsRetentionWire wire validation */
  readonly pricingTermsRetentionWireValidation: PricingTermsRetentionWireValidation;
  /** Returns PricingTermsWire wire details */
  readonly pricingTermsWireDetails: PricingTermsWire;
  /** Returns a list of all PricingTermsWire wires */
  readonly pricingTermsWireList: ReadonlyArray<PricingTermsWire>;
  /** Returns PricingTermsWire wire template preview. A null syndicateWireId returns the default template. */
  readonly pricingTermsWirePreview: WirePreview;
  /** Returns PricingTermsWire wire validation */
  readonly pricingTermsWireValidation: PricingTermsWireValidation;
  readonly privateOfferings?: Maybe<PrivateOfferingsCollectionSegment>;
  readonly prospectusDelivery: ProspectusDeliveryRoot;
  readonly prospectusDeliverySummaries: ReadonlyArray<InvestorDeliveryStatus>;
  readonly public: Public;
  readonly publishedOffering: OfferingProfile;
  readonly publishedOfferings?: Maybe<PublishedOfferingsCollectionSegment>;
  /** Returns RegMWire wire details */
  readonly regMWireDetails: RegMWire;
  /** Returns a list of all RegMWire wires */
  readonly regMWireList: ReadonlyArray<RegMWire>;
  /** Returns RegMWire wire template preview. A null syndicateWireId returns the default template. */
  readonly regMWirePreview: WirePreview;
  /** Returns RegMWire wire validation */
  readonly regMWireValidation: RegMWireValidation;
  readonly retailDemandHistory: ReadonlyArray<RetailDemandRevision>;
  /** Returns RetentionWire wire details */
  readonly retentionWireDetails: RetentionWire;
  /** Returns a list of all RetentionWire wires */
  readonly retentionWireList: ReadonlyArray<RetentionWire>;
  /** Returns RetentionWire wire template preview. A null syndicateWireId returns the default template. */
  readonly retentionWirePreview: WirePreview;
  /** Returns RetentionWire wire validation */
  readonly retentionWireValidation: RetentionWireValidation;
  readonly roadshow: Roadshow;
  readonly roadshowCityAutocomplete: ReadonlyArray<CityInfo>;
  /** Returns filtered paginated list of roadshow deal team members */
  readonly roadshowDealTeamMembersSearchResult: DealTeamMemberLimitedPagedList;
  /** Returns list of Deal Team organizations */
  readonly roadshowDealTeamOrganizations: ReadonlyArray<DealTeamNameAndDepartments>;
  /** Returns Roadshow deal teams for given roadshow id */
  readonly roadshowDealTeams: ReadonlyArray<RoadshowsDealTeam>;
  /** Retrieves list of Roadshow events - meetings and logistics */
  readonly roadshowEvents: ReadonlyArray<RoadshowEvent>;
  readonly roadshowLogisticsDetails: Logistics;
  readonly roadshowLogisticsParticipants: ReadonlyArray<EventParticipantsTeam>;
  readonly roadshowManagementTeam: ManagementTeam;
  /** Returns Roadshow meeting details */
  readonly roadshowMeeting: Meeting;
  readonly roadshows: RoadshowLimitedPagedList;
  readonly roadshowsName: Scalars['String'];
  readonly roadshowTimezoneInfo: TimezoneInfo;
  readonly rolodexName: Scalars['String'];
  readonly salesAndTradingOrderBook?: Maybe<OrderBookGraph>;
  readonly salesCredits: SalesCreditGrid;
  readonly salesCreditsHouseAccounts: ReadonlyArray<HouseAccount>;
  readonly salesCreditsInvestorHistory: ReadonlyArray<SalesCreditInvestorHistoryItem>;
  /** Returns SellingGroupInvitationWire wire details */
  readonly sellingGroupInvitationWireDetails: SellingGroupInvitationWire;
  /** Returns a list of all SellingGroupInvitationWire wires */
  readonly sellingGroupInvitationWireList: ReadonlyArray<SellingGroupInvitationWire>;
  /** Returns SellingGroupInvitationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly sellingGroupInvitationWirePreview: WirePreview;
  /** Returns SellingGroupInvitationWire wire validation */
  readonly sellingGroupInvitationWireValidation: SellingGroupInvitationWireValidation;
  /** Returns SellingGroupTermsWire wire details */
  readonly sellingGroupTermsWireDetails: SellingGroupTermsWire;
  /** Returns a list of all SellingGroupTermsWire wires */
  readonly sellingGroupTermsWireList: ReadonlyArray<SellingGroupTermsWire>;
  /** Returns SellingGroupTermsWire wire template preview. A null syndicateWireId returns the default template. */
  readonly sellingGroupTermsWirePreview: WirePreview;
  /** Returns SellingGroupTermsWire wire validation */
  readonly sellingGroupTermsWireValidation: SellingGroupTermsWireValidation;
  readonly settlementAccounting: Accounting;
  readonly settlementLetter: SettlementLetter;
  readonly settlementName: Scalars['String'];
  readonly sharedDraftOffering: OfferingProfile;
  readonly sharedOfferings?: Maybe<SharedOfferingsCollectionSegment>;
  readonly shareHistory: ReadonlyArray<SharedDraftHistory>;
  readonly stabilization: Stabilization;
  readonly stabilizationInformation: StabilizationInformation;
  readonly syndicateInstitutionalGrid?: Maybe<SyndicateInstitutionalGridCollectionSegment>;
  readonly syndicateInstitutionalGridGroupKeys: ReadonlyArray<Group>;
  readonly syndicateManagers: SyndicateManager;
  readonly syndicateWires: SyndicateWires;
  /** Returns TerminationWire wire details */
  readonly terminationWireDetails: TerminationWire;
  /** Returns a list of all TerminationWire wires */
  readonly terminationWireList: ReadonlyArray<TerminationWire>;
  /** Returns TerminationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly terminationWirePreview: WirePreview;
  /** Returns TerminationWire wire validation */
  readonly terminationWireValidation: TerminationWireValidation;
  readonly totalRetailSummary: TotalRetailSummaryGrid;
  readonly tradeGridRows: ReadonlyArray<TradeGridItem>;
  readonly tradeReleaseSettings: TradeReleaseSettings;
  readonly tradeReleaseValidation: TradeReleaseValidation;
  readonly underwriter: Firm;
  /** Returns UnderwriterInvitationWire wire details */
  readonly underwriterInvitationWireDetails: UnderwriterInvitationWire;
  /** Returns a list of all UnderwriterInvitationWire wires */
  readonly underwriterInvitationWireList: ReadonlyArray<UnderwriterInvitationWire>;
  /** Returns UnderwriterInvitationWire wire template preview. A null syndicateWireId returns the default template. */
  readonly underwriterInvitationWirePreview: WirePreview;
  /** Returns UnderwriterInvitationWire wire validation */
  readonly underwriterInvitationWireValidation: UnderwriterInvitationWireValidation;
  readonly underwriters: FirmLimitedPagedList;
  readonly underwritingTermsFeesAndDiscounts: UnderwritingTermsFeesAndDiscounts;
  readonly validateRoadshowName: RoadshowNameValidation;
  readonly wires: ReadonlyArray<Wire>;
  readonly wiresName: Scalars['String'];
};

export type QueryAllCoveredInvestorFirmsMappedToCmgEntityKeyArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryAllInvestorFirmsMappedToCmgEntityKeyArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryAllInvestorFirmsMappedToCmgEntityKeysArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
  recordStatus?: InputMaybe<Scalars['String']>;
};

export type QueryAllocationSetsArgs = {
  offeringId: Scalars['UUID'];
  order?: InputMaybe<ReadonlyArray<AllocationSetDtoSortInput>>;
  where?: InputMaybe<AllocationSetDtoFilterInput>;
};

export type QueryAllOfferingsArgs = {
  filters?: InputMaybe<AllOfferingListFiltersInput>;
};

export type QueryAttestationArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryAttestationStatusesArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type QueryAvailableShareDraftManagersArgs = {
  id: Scalars['UUID'];
};

export type QueryBasicPublishedOfferingArgs = {
  id: Scalars['UUID'];
};

export type QueryCommunicatedAllocationDemandArgs = {
  xcOfferingId: Scalars['UUID'];
};

export type QueryCommunicatedIndicationsDemandArgs = {
  xcOfferingId: Scalars['UUID'];
};

export type QueryCompanyDetailsArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryCompanySearchArgs = {
  filter: CompanyFilterInput;
  pagination: PaginationDataInput;
};

export type QueryContactsArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryCoveredAttestationArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryCoveredAttestationStatusesArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type QueryCoveredContactsArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryCoveredIndicationArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  indicationId?: InputMaybe<Scalars['UUID']>;
  offeringId: Scalars['UUID'];
};

export type QueryCoveredIndicationByCmgEntityKeyArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type QueryCoveredIndicationsArgs = {
  pricingDateFrom: Scalars['Date'];
  pricingDateTo: Scalars['Date'];
  useNewStrategy?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCoveredIndicationVersionsArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryCoveredInvestorFirmsArgs = {
  cik?: InputMaybe<Scalars['String']>;
  crd?: InputMaybe<Scalars['String']>;
  firmKey?: InputMaybe<Scalars['String']>;
  lei?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationDataInput>;
  searchText: Scalars['String'];
};

export type QueryCoveredInvestorFirmsEmployeesArgs = {
  firmKeyOrId: Scalars['String'];
};

export type QueryCoveredRoadshowArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryCoveredRoadshowEventsArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryCoveredRoadshowLogisticsDetailsArgs = {
  logisticsId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryCoveredRoadshowLogisticsParticipantsArgs = {
  logisticsId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryCoveredRoadshowMeetingArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryCoveredRoadshowsArgs = {
  filter: RoadshowFilterInput;
  pagination: PaginationDataInput;
};

export type QueryDealTeamArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryDealTeamExistsArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryDealTeamMemberRoadshowNamesArgs = {
  cmgEntityKey: Scalars['String'];
  memberId: Scalars['UUID'];
};

export type QueryDealTeamsArgs = {
  pagination: PaginationDataInput;
};

export type QueryDesignationByIndicationIdArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryDesignationsGridArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryDesignationValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryDesignationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryDesignationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryDesignationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryDesignationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryExpensesArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryFiledPublishedOfferingsArgs = {
  filters?: InputMaybe<FiledPublishedOfferingListFiltersInput>;
};

export type QueryFilingLastCommonValuesArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryFilingOfferingDetailArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryFinalInstitutionalAllocationSetArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryFirmCertificatesByCmgEntityKeyAndInvestorIdArgs = {
  crmInvestorId?: InputMaybe<Scalars['UUID']>;
  firmCmgEntityKey?: InputMaybe<Scalars['String']>;
  source: FirmCertificateSource;
};

export type QueryFirmInvestorExtendedDataArgs = {
  firmKey: Scalars['String'];
};

export type QueryFirmInvestorsExtendedDataArgs = {
  firmKeys: ReadonlyArray<Scalars['String']>;
};

export type QueryFirmInvestorsRelationshipsArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type QueryFirmsCertificatesArgs = {
  order?: InputMaybe<ReadonlyArray<FirmCertificatesReadDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  source: FirmCertificateSource;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FirmCertificatesReadDtoFilterInput>;
};

export type QueryFreeFormWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryFreeFormWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryFreeFormWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryFreeFormWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryGetFilingsArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryGetInstitutionalIndicationNotesArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryGetUnderwritingFeesAndDiscountsArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryGreenShoeWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryGreenShoeWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryGreenShoeWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryGreenShoeWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryIndicationTradesArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryIndicationVersionsArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryInstitutionalDemandGridSummaryArgs = {
  input: InstitutionalIndicationDemandQueryInput;
  offeringId: Scalars['UUID'];
};

export type QueryInstitutionalIndicationArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  indicationId?: InputMaybe<Scalars['UUID']>;
  offeringId: Scalars['UUID'];
};

export type QueryInstitutionalIndicationAcknowledgementsArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryInstitutionalIndicationBillingAndDeliveryAgentArgs = {
  indicationId: Scalars['UUID'];
  offeringId: Scalars['UUID'];
};

export type QueryInstitutionalIndicationsArgs = {
  filters?: InputMaybe<InstitutionalIndicationListFiltersInput>;
  offeringId: Scalars['UUID'];
};

export type QueryInternalAllocationDemandArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryInternalIndicationsDemandArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryInvestorFirmArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  firmKeyOrId?: InputMaybe<Scalars['String']>;
};

export type QueryInvestorFirmsArgs = {
  cik?: InputMaybe<Scalars['String']>;
  crd?: InputMaybe<Scalars['String']>;
  firmKey?: InputMaybe<Scalars['String']>;
  lei?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationDataInput>;
  searchText: Scalars['String'];
};

export type QueryInvestorFirmsEmployeeRelationshipsArgs = {
  bankInvestorKeys: ReadonlyArray<Scalars['String']>;
};

export type QueryInvestorFirmsEmployeesArgs = {
  firmKeyOrId: Scalars['String'];
};

export type QueryIssuerAllocationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryIssuerAllocationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryIssuerAllocationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryIssuerAllocationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryIssuerIndicationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryIssuerIndicationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryIssuerIndicationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryIssuerIndicationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryLastTnRestrictedPeriodArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryLetterEligibleManagersArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryLimitedPublishedOfferingArgs = {
  filters: PublishedOfferingsListFiltersInput;
};

export type QueryLivePublishedOfferingsArgs = {
  filters?: InputMaybe<LivePublishedOfferingListFiltersInput>;
};

export type QueryManagerBdWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryManagerBdWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryManagerBdWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryManagerBdWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryMdlAllocationRankingArgs = {
  id: Scalars['UUID'];
};

export type QueryMyIndicationVersionsArgs = {
  myCmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type QueryMyPublishedOfferingsArgs = {
  filters: MyPublishedOfferingListFiltersInput;
};

export type QueryMyRetailSummaryArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateFreeFormWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryNonSyndicateFreeFormWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateFreeFormWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryNonSyndicateFreeFormWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicatePaymentWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryNonSyndicatePaymentWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicatePaymentWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryNonSyndicatePaymentWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupInvitationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupInvitationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupInvitationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryNonSyndicateSellingGroupInvitationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupTermsRetentionWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupTermsRetentionWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateSellingGroupTermsRetentionWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryNonSyndicateSellingGroupTermsRetentionWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateTerminationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryNonSyndicateTerminationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryNonSyndicateTerminationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryNonSyndicateTerminationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryOfferingArgs = {
  id: Scalars['UUID'];
};

export type QueryOfferingHeaderArgs = {
  id: Scalars['UUID'];
  isSharedDraft: Scalars['Boolean'];
};

export type QueryOfferingNotesArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryOfferingsArgs = {
  includeDiscardedObsoleteErroneous?: InputMaybe<Scalars['Boolean']>;
  includeTotals?: InputMaybe<Scalars['Boolean']>;
  issuerName?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  orderField?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<ReadonlyArray<OfferingStatus>>;
  types?: InputMaybe<ReadonlyArray<OfferingType>>;
};

export type QueryOrderBookArgs = {
  id: Scalars['UUID'];
};

export type QueryOrderBookGraphArgs = {
  id: Scalars['UUID'];
};

export type QueryPastFirmCertificatesByCmgEntityKeyArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
};

export type QueryPaymentWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryPaymentWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPaymentWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryPaymentWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPostponedPublishedOfferingsArgs = {
  filters: PostponedPublishedOfferingListFiltersInput;
};

export type QueryPotAccountWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryPotAccountWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPotAccountWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryPotAccountWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPotAllocationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryPotAllocationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPotAllocationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryPotAllocationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPricedPublishedOfferingsArgs = {
  filters: PricedPublishedOfferingListFiltersInput;
};

export type QueryPricingTermsRetentionWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryPricingTermsRetentionWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPricingTermsRetentionWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryPricingTermsRetentionWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPricingTermsWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryPricingTermsWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPricingTermsWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryPricingTermsWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryPrivateOfferingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingFullFilterInput>;
};

export type QueryProspectusDeliveryArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryProspectusDeliverySummariesArgs = {
  investorKeys: ReadonlyArray<Scalars['String']>;
  offeringIds: ReadonlyArray<Scalars['UUID']>;
};

export type QueryPublishedOfferingArgs = {
  id: Scalars['UUID'];
};

export type QueryPublishedOfferingsArgs = {
  includeTestOfferings?: Scalars['Boolean'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingFullFilterInput>;
};

export type QueryRegMWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryRegMWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryRegMWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryRegMWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryRetailDemandHistoryArgs = {
  cmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type QueryRetentionWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryRetentionWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryRetentionWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryRetentionWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryRoadshowArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowCityAutocompleteArgs = {
  searchText: Scalars['String'];
};

export type QueryRoadshowDealTeamMembersSearchResultArgs = {
  filter: RoadshowDealTeamFilterInput;
  pagination: PaginationDataInput;
};

export type QueryRoadshowDealTeamsArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowEventsArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowLogisticsDetailsArgs = {
  logisticsId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowLogisticsParticipantsArgs = {
  logisticsId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowManagementTeamArgs = {
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowMeetingArgs = {
  meetingId: Scalars['UUID'];
  roadshowId: Scalars['UUID'];
};

export type QueryRoadshowsArgs = {
  filter: RoadshowFilterInput;
  pagination: PaginationDataInput;
};

export type QueryRoadshowTimezoneInfoArgs = {
  address: Scalars['String'];
  date: Scalars['Date'];
};

export type QuerySalesAndTradingOrderBookArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySalesCreditsArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySalesCreditsInvestorHistoryArgs = {
  investorCmgEntityKey: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type QuerySellingGroupInvitationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QuerySellingGroupInvitationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySellingGroupInvitationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QuerySellingGroupInvitationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySellingGroupTermsWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QuerySellingGroupTermsWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySellingGroupTermsWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QuerySellingGroupTermsWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySettlementAccountingArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySettlementLetterArgs = {
  managerId: Scalars['String'];
  offeringId: Scalars['UUID'];
};

export type QuerySharedDraftOfferingArgs = {
  id: Scalars['UUID'];
};

export type QuerySharedOfferingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingFullFilterInput>;
};

export type QueryShareHistoryArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryStabilizationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryStabilizationInformationArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySyndicateInstitutionalGridArgs = {
  allocationSetIds?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  increment: Scalars['Decimal'];
  max: Scalars['Decimal'];
  min: Scalars['Decimal'];
  offeringId: Scalars['UUID'];
  order?: InputMaybe<ReadonlyArray<SyndicateInstitutionalGridRowSortInput>>;
  referencePrice?: InputMaybe<Scalars['Decimal']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SyndicateInstitutionalGridRowFilterInput>;
};

export type QuerySyndicateInstitutionalGridGroupKeysArgs = {
  allocationSetIds?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  groupBy: SyndicateGridGroupingColumn;
  increment: Scalars['Decimal'];
  max: Scalars['Decimal'];
  min: Scalars['Decimal'];
  offeringId: Scalars['UUID'];
  referencePrice?: InputMaybe<Scalars['Decimal']>;
  where?: InputMaybe<SyndicateInstitutionalGridRowFilterInput>;
};

export type QuerySyndicateManagersArgs = {
  offeringId: Scalars['UUID'];
};

export type QuerySyndicateWiresArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTerminationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryTerminationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTerminationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryTerminationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTotalRetailSummaryArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTradeGridRowsArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTradeReleaseSettingsArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryTradeReleaseValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryUnderwriterArgs = {
  cmgEntityKey: Scalars['String'];
};

export type QueryUnderwriterInvitationWireDetailsArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId: Scalars['UUID'];
};

export type QueryUnderwriterInvitationWireListArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryUnderwriterInvitationWirePreviewArgs = {
  cmgEntityKey?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
  syndicateWireId?: InputMaybe<Scalars['UUID']>;
};

export type QueryUnderwriterInvitationWireValidationArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryUnderwritersArgs = {
  name: Scalars['String'];
  pagination?: InputMaybe<PaginationDataInput>;
};

export type QueryUnderwritingTermsFeesAndDiscountsArgs = {
  offeringId: Scalars['UUID'];
};

export type QueryValidateRoadshowNameArgs = {
  name: Scalars['String'];
};

export type QueryWiresArgs = {
  includeOnlyLatestWires?: InputMaybe<Scalars['Boolean']>;
  isNonSyndicate: Scalars['Boolean'];
  offeringId: Scalars['UUID'];
  order?: InputMaybe<ReadonlyArray<WireReadDtoSortInput>>;
  where?: InputMaybe<WireReadDtoFilterInput>;
};

export enum RecordStatus {
  Deleted = 'DELETED',
  Effective = 'EFFECTIVE',
}

export type RecordStatusFilterInput = {
  readonly recordStatus?: InputMaybe<RecordStatus>;
};

export type RegMWire = {
  readonly __typename?: 'RegMWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly notificationPeriod: RegulationMNotificationPeriod;
  readonly offeringResult: Scalars['String'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type RegMWireResponseUnion = RegMWire | ServiceError;

export type RegMWireValidation = WireValidationBase & {
  readonly __typename?: 'RegMWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<RegulationMWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum RegulationMNotificationPeriod {
  ActivelyTradedSecurity = 'ACTIVELY_TRADED_SECURITY',
  FiveDays = 'FIVE_DAYS',
  OneDay = 'ONE_DAY',
}

export enum RegulationMWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type RegulatoryFilingNotificationMessage = {
  readonly __typename?: 'RegulatoryFilingNotificationMessage';
  readonly bccEmailAddresses: ReadonlyArray<Scalars['String']>;
  readonly distributionList: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly managerSnapshot: FinraManagerSnapshot;
  readonly sentAt?: Maybe<Scalars['Instant']>;
  readonly status: NotificationMessageStatus;
};

export type RegulatoryFilingRecipient = {
  readonly __typename?: 'RegulatoryFilingRecipient';
  readonly cmgEntityKey: Scalars['String'];
  readonly distributionList: ReadonlyArray<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly role: ManagerRole;
};

export type RegulatoryFilings = {
  readonly __typename?: 'RegulatoryFilings';
  /** Returns DS filing */
  readonly DSFiling: DsFiling;
  /** Returns all DS filings */
  readonly DSFilings: ReadonlyArray<BaseRegulatoryFiling>;
  readonly id: Scalars['ID'];
  /** Returns NOI filing */
  readonly NOIFiling: NoiFiling;
  /** Returns all NOI filings */
  readonly NOIFilings: ReadonlyArray<BaseRegulatoryFiling>;
  readonly notifications: ReadonlyArray<RegulatoryFilingNotificationMessage>;
  readonly offeringId: Scalars['UUID'];
  readonly recipients: ReadonlyArray<RegulatoryFilingRecipient>;
  /** Returns RPN filing */
  readonly RPNFiling: RpnFiling;
  /** Returns all RPN filings */
  readonly RPNFilings: ReadonlyArray<BaseRegulatoryFiling>;
  readonly senderDistributionEmails: ReadonlyArray<Scalars['String']>;
  /** Returns stabilization TN activities for an offering */
  readonly stabilizationTNActivities: StabilizationTnFilingActivityResponse;
  /** Returns TN filing */
  readonly TNFiling: TnFiling;
  /** Returns all TN filings */
  readonly TNFilings: ReadonlyArray<BaseRegulatoryFiling>;
  /** Returns USDTN filing */
  readonly USDTNFiling: UsdtnFiling;
  /** Returns all USDTN filings */
  readonly USDTNFilings: ReadonlyArray<BaseRegulatoryFiling>;
};

export type RegulatoryFilingsDsFilingArgs = {
  filingId: Scalars['UUID'];
};

export type RegulatoryFilingsNoiFilingArgs = {
  filingId: Scalars['UUID'];
};

export type RegulatoryFilingsNotificationsArgs = {
  filingId: Scalars['UUID'];
};

export type RegulatoryFilingsRpnFilingArgs = {
  filingId: Scalars['UUID'];
};

export type RegulatoryFilingsTnFilingArgs = {
  filingId: Scalars['UUID'];
};

export type RegulatoryFilingsUsdtnFilingArgs = {
  filingId: Scalars['UUID'];
};

export { RelationshipType };

export type ReleasedRetailRetention = {
  readonly __typename?: 'ReleasedRetailRetention';
  readonly id: Scalars['ID'];
  readonly isReleased: Scalars['Boolean'];
  readonly managerCmgEntityKey: Scalars['String'];
  readonly modifiedAt: Scalars['Instant'];
  readonly modifiedByFirmKey?: Maybe<Scalars['String']>;
  readonly shareQuantity: Scalars['Long'];
};

export enum ReleaseStatus {
  Booked = 'BOOKED',
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  Cancelled = 'CANCELLED',
  CancelledManual = 'CANCELLED_MANUAL',
  PartiallyBooked = 'PARTIALLY_BOOKED',
  ReleaseFailed = 'RELEASE_FAILED',
  Released = 'RELEASED',
}

export type ResendProspectusResponseUnion = EmptyMutationResponse | ServiceError;

/**
 * Resources model the domain of each bounded context.
 * Warning: If breaking changes are made to this enum, then we will need to write a custom migration to update all entitlements stored in the db.
 */
export enum Resource {
  BuySideIndication = 'BUY_SIDE_INDICATION',
  DeliveryInstructions = 'DELIVERY_INSTRUCTIONS',
  DspIndication = 'DSP_INDICATION',
  DspRetention = 'DSP_RETENTION',
  FullSyndicateLineUp = 'FULL_SYNDICATE_LINE_UP',
  IncentiveFee = 'INCENTIVE_FEE',
  InstitutionalAllocationDraft = 'INSTITUTIONAL_ALLOCATION_DRAFT',
  InstitutionalAllocationFinal = 'INSTITUTIONAL_ALLOCATION_FINAL',
  InstitutionalAllocationSet = 'INSTITUTIONAL_ALLOCATION_SET',
  InstitutionalDuplicateIndication = 'INSTITUTIONAL_DUPLICATE_INDICATION',
  InstitutionalIndication = 'INSTITUTIONAL_INDICATION',
  ManagementFee = 'MANAGEMENT_FEE',
  MyRetail = 'MY_RETAIL',
  NonSyndicateCommunication = 'NON_SYNDICATE_COMMUNICATION',
  OfferingProfile = 'OFFERING_PROFILE',
  OrderBook = 'ORDER_BOOK',
  PassiveOrderBook = 'PASSIVE_ORDER_BOOK',
  RetailIndication = 'RETAIL_INDICATION',
  RetailRetention = 'RETAIL_RETENTION',
  SalesOrderBook = 'SALES_ORDER_BOOK',
  SellingConcession = 'SELLING_CONCESSION',
  SharedDrafts = 'SHARED_DRAFTS',
  SyndicateCommunication = 'SYNDICATE_COMMUNICATION',
  UnderwritingFee = 'UNDERWRITING_FEE',
}

export type RetailBrokerDealer = {
  readonly __typename?: 'RetailBrokerDealer';
  readonly demand: RetailBrokerDealerDemand;
  readonly firmCmgEntityKey?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isOnPlatform: Scalars['Boolean'];
  readonly retention: RetailBrokerDealerRetention;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
};

export type RetailBrokerDealerDemand = {
  readonly __typename?: 'RetailBrokerDealerDemand';
  readonly id: Scalars['ID'];
  readonly roundLots?: Maybe<Scalars['Int']>;
  readonly shareQuantity: Scalars['Long'];
};

export type RetailBrokerDealerRetention = {
  readonly __typename?: 'RetailBrokerDealerRetention';
  readonly id: Scalars['ID'];
  readonly roundLots?: Maybe<Scalars['Int']>;
  readonly shareQuantity?: Maybe<Scalars['Long']>;
};

export type RetailDemandRevision = {
  readonly __typename?: 'RetailDemandRevision';
  readonly id: Scalars['ID'];
  readonly modifiedAt: Scalars['Instant'];
  readonly modifiedByFirmKey?: Maybe<Scalars['String']>;
  readonly roundLots?: Maybe<Scalars['Int']>;
  readonly shareQuantity: Scalars['Long'];
};

export type RetentionRecipientData = {
  readonly __typename?: 'RetentionRecipientData';
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type RetentionRecipientInputData = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly recipient: Scalars['String'];
};

export type RetentionWire = {
  readonly __typename?: 'RetentionWire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['ID'];
  readonly recipientsUserData: ReadonlyArray<RetentionRecipientData>;
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

export type RetentionWireInput = {
  readonly userDataForRecipients: ReadonlyArray<RetentionRecipientInputData>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type RetentionWireResponseUnion = RetentionWire | ServiceError;

export type RetentionWireValidation = WireValidationBase & {
  readonly __typename?: 'RetentionWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<RetentionWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum RetentionWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type ReviewSalesCreditsBankInvestorKeyChangeInput = {
  readonly bankInvestorKey: Scalars['String'];
  readonly investorStatus: OrderBookInvestorStatus;
};

export enum RevisionEdition {
  Draft = 'DRAFT',
  Final = 'FINAL',
}

export type RevisionEditionFilterInput = {
  readonly eq?: InputMaybe<RevisionEdition>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<RevisionEdition>>>;
  readonly neq?: InputMaybe<RevisionEdition>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<RevisionEdition>>>;
};

export type RevisionEditionSortInput = {
  readonly name?: InputMaybe<SortEnumType>;
  readonly value?: InputMaybe<SortEnumType>;
};

export type Roadshow = {
  readonly __typename?: 'Roadshow';
  readonly companyId?: Maybe<Scalars['String']>;
  readonly companyName?: Maybe<Scalars['String']>;
  readonly defaultTimeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly endDate: Scalars['LocalDate'];
  readonly id: Scalars['ID'];
  readonly offeringId?: Maybe<Scalars['UUID']>;
  readonly offeringIssuerName?: Maybe<Scalars['String']>;
  readonly roadshowDays: ReadonlyArray<RoadshowDay>;
  readonly roadshowName: Scalars['String'];
  readonly roadshowType: RoadshowType;
  readonly startDate: Scalars['LocalDate'];
  readonly status: RoadshowStatus;
};

export type RoadshowDay = {
  readonly __typename?: 'RoadshowDay';
  readonly cities: ReadonlyArray<City>;
  readonly date: Scalars['LocalDate'];
  readonly isClosed: Scalars['Boolean'];
  readonly isVirtual: Scalars['Boolean'];
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
};

export type RoadshowDealTeamFilterInput = {
  readonly department?: InputMaybe<Scalars['String']>;
  readonly firmCmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly fullName?: InputMaybe<Scalars['String']>;
};

export type RoadshowEvent = {
  readonly __typename?: 'RoadshowEvent';
  readonly endDate: Scalars['LocalDate'];
  readonly endTime: Scalars['String'];
  readonly hostFirm?: Maybe<Scalars['String']>;
  readonly hostName?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly location?: Maybe<Scalars['String']>;
  readonly participantTeams: ReadonlyArray<EventParticipantsTeam>;
  readonly publicNotes?: Maybe<Scalars['String']>;
  readonly roadshowEventType: RoadshowEventType;
  readonly startDate: Scalars['LocalDate'];
  readonly startTime: Scalars['String'];
  readonly status?: Maybe<EventStatus>;
  readonly title: Scalars['String'];
  readonly type?: Maybe<MeetingType>;
};

export enum RoadshowEventType {
  Logistics = 'Logistics',
  Meeting = 'Meeting',
}

export type RoadshowFilterInput = {
  readonly companyName?: InputMaybe<Scalars['String']>;
  readonly endDate?: InputMaybe<Scalars['Date']>;
  readonly roadshowTypes?: InputMaybe<ReadonlyArray<RoadshowType>>;
  readonly searchText?: InputMaybe<Scalars['String']>;
  readonly showArchived?: InputMaybe<Scalars['Boolean']>;
  readonly startDate?: InputMaybe<Scalars['Date']>;
};

export type RoadshowInput = {
  readonly defaultTimeZone?: InputMaybe<Scalars['DateTimeZone']>;
  readonly endDate: Scalars['LocalDate'];
  readonly offeringId?: InputMaybe<Scalars['UUID']>;
  readonly roadshowDays: ReadonlyArray<CreateOrUpdateRoadshowDayInput>;
  readonly roadshowName: Scalars['String'];
  readonly roadshowType: RoadshowType;
  readonly startDate: Scalars['LocalDate'];
};

export type RoadshowLimited = {
  readonly __typename?: 'RoadshowLimited';
  readonly companyName?: Maybe<Scalars['String']>;
  readonly endDate: Scalars['LocalDate'];
  readonly id: Scalars['ID'];
  readonly offeringId?: Maybe<Scalars['UUID']>;
  readonly offeringIssuerName?: Maybe<Scalars['String']>;
  readonly roadshowName: Scalars['String'];
  readonly roadshowType: RoadshowType;
  readonly startDate: Scalars['LocalDate'];
  readonly status: RoadshowStatus;
};

export type RoadshowLimitedPagedList = {
  readonly __typename?: 'RoadshowLimitedPagedList';
  readonly data: ReadonlyArray<RoadshowLimited>;
  readonly pagination: PaginationData;
};

export type RoadshowNameValidation = {
  readonly __typename?: 'RoadshowNameValidation';
  readonly errors: ReadonlyArray<RoadshowNameValidationError>;
};

export enum RoadshowNameValidationError {
  Duplicate = 'DUPLICATE',
}

export type RoadshowSchedule = {
  readonly __typename?: 'RoadshowSchedule';
  readonly date: Scalars['LocalDate'];
  readonly details: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly recordStatus: RecordStatus;
};

export type RoadshowScheduleInput = {
  readonly date: Scalars['LocalDate'];
  readonly details: Scalars['String'];
};

export type RoadshowScheduleProfile = {
  readonly __typename?: 'RoadshowScheduleProfile';
  readonly date: Scalars['LocalDate'];
  readonly details: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type RoadshowsDealTeam = {
  readonly __typename?: 'RoadshowsDealTeam';
  readonly firmCmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly members: ReadonlyArray<DealTeamMember>;
};

export type RoadshowsFull = {
  readonly __typename?: 'RoadshowsFull';
  readonly date: Scalars['LocalDate'];
  readonly details: Scalars['String'];
  readonly id: Scalars['UUID'];
};

export type RoadshowsFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<RoadshowsFullFilterInput>>;
  readonly date?: InputMaybe<LocalDateOperationFilterInput>;
  readonly details?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<RoadshowsFullFilterInput>>;
};

export enum RoadshowStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  PublishedInternally = 'PUBLISHED_INTERNALLY',
}

export enum RoadshowType {
  Dcim = 'DCIM',
  Ecm = 'ECM',
  NonDeal = 'NON_DEAL',
  Pdie = 'PDIE',
  Ttw = 'TTW',
}

export enum RolodexFirmCategoryType {
  Extension = 'EXTENSION',
  FreeText = 'FREE_TEXT',
  Pwm = 'PWM',
  Regular = 'REGULAR',
}

export { RolodexRecordStatus };

export type RowAlert = {
  readonly __typename?: 'RowAlert';
  readonly alertCodes?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly severity: AlertSeverity;
  readonly sortValue?: Maybe<Scalars['String']>;
};

export type RowAlertFilterInput = {
  readonly alertCodes?: InputMaybe<ListStringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<RowAlertFilterInput>>;
  readonly or?: InputMaybe<ReadonlyArray<RowAlertFilterInput>>;
  readonly severity?: InputMaybe<AlertSeverityOperationFilterInput>;
  readonly sortValue?: InputMaybe<StringOperationFilterInput>;
};

export type RowAlertSortInput = {
  readonly severity?: InputMaybe<SortEnumType>;
  readonly sortValue?: InputMaybe<SortEnumType>;
};

export enum RpnBasisCodeType {
  AdtvPfv = 'ADTV_PFV',
  FiveDays = 'FIVE_DAYS',
  Other = 'OTHER',
  Uar = 'UAR',
}

export type RpnFiling = {
  readonly __typename?: 'RPNFiling';
  readonly adtvNumber?: Maybe<Scalars['Float']>;
  readonly adtvSource?: Maybe<Scalars['String']>;
  readonly anticipatedPricingDate: Scalars['LocalDate'];
  readonly basisCode: RpnBasisCodeType;
  /** Display name for BasisCode */
  readonly basisCodeDisplayName?: Maybe<Scalars['String']>;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly filingCreatorEmail?: Maybe<Scalars['String']>;
  readonly finraErrorMessages: ReadonlyArray<Scalars['String']>;
  readonly finraMemberFirms: ReadonlyArray<MemberFirm>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmDealId: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly formUniqueId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isConcurrentConvertible: Scalars['Boolean'];
  readonly issuerName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly nonMemberFirms: ReadonlyArray<NonMemberFirm>;
  readonly otherBasisDescription?: Maybe<Scalars['String']>;
  readonly publicFloatValue?: Maybe<Scalars['Float']>;
  readonly publicFloatValueSource?: Maybe<Scalars['String']>;
  readonly restrictedPeriod: RpnRestrictedPeriodType;
  /** Display name for RestrictedPeriod */
  readonly restrictedPeriodDisplayName?: Maybe<Scalars['String']>;
  readonly restrictionStartTime: Scalars['Instant'];
  readonly restrictionStartTimezone: Scalars['DateTimeZone'];
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
  readonly symbolsOfCoveredSecurities: ReadonlyArray<Scalars['String']>;
  readonly telephoneNumber: Scalars['String'];
  readonly transactionType: TransactionType;
  readonly transactionTypeDescription?: Maybe<Scalars['String']>;
  /** Display name for TransactionType */
  readonly transactionTypeDisplayName?: Maybe<Scalars['String']>;
  readonly uarDate?: Maybe<Scalars['LocalDate']>;
};

export type RpnFilingFormDataInput = {
  readonly adtvNumber?: InputMaybe<Scalars['Float']>;
  readonly adtvSource?: InputMaybe<Scalars['String']>;
  readonly anticipatedPricingDate: Scalars['LocalDate'];
  readonly basisCode: RpnBasisCodeType;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly finraMemberFirms: ReadonlyArray<MemberFirmInput>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly isConcurrentConvertible: Scalars['Boolean'];
  readonly issuerSymbol: Scalars['String'];
  readonly nonMemberFirms: ReadonlyArray<NonMemberFirmInput>;
  readonly otherBasisDescription?: InputMaybe<Scalars['String']>;
  readonly publicFloatValue?: InputMaybe<Scalars['Float']>;
  readonly publicFloatValueSource?: InputMaybe<Scalars['String']>;
  readonly restrictedPeriod: RpnRestrictedPeriodType;
  readonly restrictionStartTime: Scalars['Instant'];
  readonly restrictionStartTimezone: Scalars['DateTimeZone'];
  readonly symbolsOfCoveredSecurities: ReadonlyArray<Scalars['String']>;
  readonly telephoneNumber: Scalars['String'];
  readonly transactionType: TransactionType;
  readonly transactionTypeDescription?: InputMaybe<Scalars['String']>;
  readonly uarDate?: InputMaybe<Scalars['LocalDate']>;
};

export enum RpnRestrictedPeriodType {
  FiveDays = 'FIVE_DAYS',
  OneDay = 'ONE_DAY',
}

export type SalesCreditGrid = {
  readonly __typename?: 'SalesCreditGrid';
  readonly id: Scalars['ID'];
  readonly salesCredits: ReadonlyArray<SalesCreditItem>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly settlementCurrency: Scalars['String'];
};

export type SalesCreditInvestorHistoryItem = {
  readonly __typename?: 'SalesCreditInvestorHistoryItem';
  readonly createdAt: Scalars['Instant'];
  readonly createdByUserName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly salesCreditId: Scalars['UUID'];
  readonly salesCreditStatus: SalesCreditReleaseStatus;
  readonly salesCreditVersion: Scalars['String'];
  readonly statusDetails?: Maybe<Scalars['String']>;
};

export type SalesCreditItem = {
  readonly __typename?: 'SalesCreditItem';
  readonly allocationShares?: Maybe<Scalars['Long']>;
  readonly allowedStatusTransitions: ReadonlyArray<GridTransitionSalesCreditReleaseStatus>;
  readonly bankInvestorKey?: Maybe<Scalars['String']>;
  readonly designationShares?: Maybe<Scalars['Long']>;
  readonly firmId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['ID'];
  readonly investorFirmCmgEntityKey: Scalars['String'];
  readonly investorFirmName: Scalars['String'];
  readonly orderBookBankInvestorFirmName?: Maybe<Scalars['String']>;
  readonly orderBookBankInvestorKey?: Maybe<Scalars['String']>;
  readonly salesCredits?: Maybe<Scalars['Decimal']>;
  readonly salesCreditsId?: Maybe<Scalars['String']>;
  readonly salesCreditsManual?: Maybe<Scalars['Decimal']>;
  readonly salesCreditsReleased?: Maybe<Scalars['Decimal']>;
  readonly status: SalesCreditReleaseStatus;
  readonly statusDetails?: Maybe<Scalars['String']>;
  readonly warnings: ReadonlyArray<SalesCreditWarning>;
};

export enum SalesCreditReleaseStatus {
  Booked = 'BOOKED',
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  Cancelled = 'CANCELLED',
  CancelledManual = 'CANCELLED_MANUAL',
  ReleaseFailed = 'RELEASE_FAILED',
  Released = 'RELEASED',
  Unreleased = 'UNRELEASED',
}

export enum SalesCreditWarning {
  BankInvestorKeyChanged = 'BANK_INVESTOR_KEY_CHANGED',
  DesignationChanged = 'DESIGNATION_CHANGED',
  DesignationNotApplicable = 'DESIGNATION_NOT_APPLICABLE',
  OfferingChanged = 'OFFERING_CHANGED',
  OrderBookBankInvestorKeyChanged = 'ORDER_BOOK_BANK_INVESTOR_KEY_CHANGED',
  PublishedDesignationAmountIsZero = 'PUBLISHED_DESIGNATION_AMOUNT_IS_ZERO',
  SalesCreditChanged = 'SALES_CREDIT_CHANGED',
}

export enum Sector {
  BasicMaterials = 'BASIC_MATERIALS',
  Consumer = 'CONSUMER',
  Energy = 'ENERGY',
  FinancialServices = 'FINANCIAL_SERVICES',
  Healthcare = 'HEALTHCARE',
  Industrials = 'INDUSTRIALS',
  Other = 'OTHER',
  RealEstate = 'REAL_ESTATE',
  Tmt = 'TMT',
  Utilities = 'UTILITIES',
}

export type SectorFilterInput = {
  readonly eq?: InputMaybe<Sector>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Sector>>>;
  readonly neq?: InputMaybe<Sector>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Sector>>>;
};

export type Security = {
  readonly __typename?: 'Security';
  readonly id: Scalars['ID'];
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly shareClass?: Maybe<SecurityShareClass>;
  /** Display name for ShareClass */
  readonly shareClassDisplayName?: Maybe<Scalars['String']>;
  readonly type: SecurityType;
};

export type SecurityFull = {
  readonly __typename?: 'SecurityFull';
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly shareClass?: Maybe<SecurityShareClass>;
  /** Display name for ShareClass */
  readonly shareClassDisplayName?: Maybe<Scalars['String']>;
  readonly type: SecurityType;
};

export type SecurityFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SecurityFullFilterInput>>;
  readonly isDepositaryReceipt?: InputMaybe<BooleanOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SecurityFullFilterInput>>;
  readonly shareClass?: InputMaybe<ShareClassFilterInput>;
  readonly type?: InputMaybe<SecurityTypeFilterInput>;
};

export type SecurityProfile = {
  readonly __typename?: 'SecurityProfile';
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly shareClass?: Maybe<SecurityShareClass>;
  /** Display name for ShareClass */
  readonly shareClassDisplayName?: Maybe<Scalars['String']>;
  readonly type: SecurityType;
};

export enum SecurityShareClass {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B = 'B',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  C = 'C',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  D = 'D',
  E = 'E',
  Eight = 'EIGHT',
  F = 'F',
  Five = 'FIVE',
  Four = 'FOUR',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  Nine = 'NINE',
  O = 'O',
  One = 'ONE',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  Seven = 'SEVEN',
  Six = 'SIX',
  T = 'T',
  Three = 'THREE',
  Two = 'TWO',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum SecurityType {
  CommonOrdinaryShare = 'COMMON_ORDINARY_SHARE',
  ConvertibleBond = 'CONVERTIBLE_BOND',
  LimitedPartnershipUnit = 'LIMITED_PARTNERSHIP_UNIT',
  PreferredShare = 'PREFERRED_SHARE',
  UnitShareAndWarrants = 'UNIT_SHARE_AND_WARRANTS',
}

export type SecurityTypeFilterInput = {
  readonly eq?: InputMaybe<SecurityType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<SecurityType>>>;
  readonly neq?: InputMaybe<SecurityType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<SecurityType>>>;
};

export type SellingGroupInvitationWire = {
  readonly __typename?: 'SellingGroupInvitationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly masterSdaDate: Scalars['LocalDate'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type SellingGroupInvitationWireResponseUnion = SellingGroupInvitationWire | ServiceError;

export type SellingGroupInvitationWireValidation = WireValidationBase & {
  readonly __typename?: 'SellingGroupInvitationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<SellingGroupInvitationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum SellingGroupInvitationWireValidationOfferingErrors {
  ExchangeMic = 'EXCHANGE_MIC',
  IpoRangeHigh = 'IPO_RANGE_HIGH',
  IpoRangeLow = 'IPO_RANGE_LOW',
  IssuerName = 'ISSUER_NAME',
  LaunchDate = 'LAUNCH_DATE',
  OfferingType = 'OFFERING_TYPE',
  PricingDate = 'PRICING_DATE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type SellingGroupTermsWire = {
  readonly __typename?: 'SellingGroupTermsWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type SellingGroupTermsWireResponseUnion = SellingGroupTermsWire | ServiceError;

export type SellingGroupTermsWireValidation = WireValidationBase & {
  readonly __typename?: 'SellingGroupTermsWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<SellingGroupTermsWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum SellingGroupTermsWireValidationOfferingErrors {
  Cusip = 'CUSIP',
  ExchangeMic = 'EXCHANGE_MIC',
  FirstTradeDate = 'FIRST_TRADE_DATE',
  IssuerName = 'ISSUER_NAME',
  OfferingPrice = 'OFFERING_PRICE',
  OfferingType = 'OFFERING_TYPE',
  ReAllowance = 'RE_ALLOWANCE',
  SecurityType = 'SECURITY_TYPE',
  SellingConcession = 'SELLING_CONCESSION',
  SettlementAgent = 'SETTLEMENT_AGENT',
  SettlementDate = 'SETTLEMENT_DATE',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type SendDlSupportRequestResponse = EmptyMutationResponse | ServiceError;

export type SendReportInput = {
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
  readonly sendDateTimeZone: Scalars['String'];
};

export type SendWireInput = {
  readonly sendDateTimeZone: Scalars['String'];
  readonly shouldAddSenderToCc: Scalars['Boolean'];
  readonly underwriterList: ReadonlyArray<Scalars['String']>;
};

export enum SentWireResponseStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Obsolete = 'OBSOLETE',
  Pending = 'PENDING',
}

export type SentWireResponseStatusFilterInput = {
  readonly eq?: InputMaybe<SentWireResponseStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<SentWireResponseStatus>>>;
  readonly neq?: InputMaybe<SentWireResponseStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<SentWireResponseStatus>>>;
};

export type SentWireStatus = {
  readonly __typename?: 'SentWireStatus';
  readonly expiredAt?: Maybe<Scalars['Instant']>;
  readonly id: Scalars['ID'];
  readonly legalAgreement?: Maybe<Scalars['String']>;
  readonly respondedAt?: Maybe<Scalars['Instant']>;
  readonly responseStatus?: Maybe<SentWireResponseStatus>;
  readonly senderEmails: ReadonlyArray<Scalars['String']>;
  readonly sentAt: Scalars['Instant'];
};

/** Legacy Service Error object for Apollo Compatibility */
export type ServiceError = {
  readonly __typename?: 'ServiceError';
  readonly code: ServiceErrorCode;
  readonly details?: Maybe<ReadonlyArray<ErrorDetail>>;
  readonly message: Scalars['String'];
  readonly target?: Maybe<Scalars['String']>;
};

export enum ServiceErrorCode {
  Duplicate = 'DUPLICATE',
  EditConflict = 'EDIT_CONFLICT',
  Forbidden = 'FORBIDDEN',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  IpBlocked = 'IP_BLOCKED',
  LockedOut = 'LOCKED_OUT',
  NetworkFailure = 'NETWORK_FAILURE',
  NotFound = 'NOT_FOUND',
  PasswordExpired = 'PASSWORD_EXPIRED',
  UnhandledError = 'UNHANDLED_ERROR',
  ValidationError = 'VALIDATION_ERROR',
}

export enum SettlementErrorType {
  NoDesignations = 'NO_DESIGNATIONS',
}

export type SettlementLetter = {
  readonly __typename?: 'SettlementLetter';
  readonly adjustmentDescription?: Maybe<Scalars['String']>;
  readonly adjustmentValue?: Maybe<Scalars['Decimal']>;
  readonly dealRelatedExpenses: ReadonlyArray<LetterDealRelatedExpenses>;
  readonly designatedShares?: Maybe<Scalars['Long']>;
  readonly finalSettlement?: Maybe<Scalars['Decimal']>;
  readonly finalSettlementDate?: Maybe<Scalars['LocalDate']>;
  readonly id: Scalars['ID'];
  readonly issuerName: Scalars['String'];
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly managementPercentage?: Maybe<Scalars['Decimal']>;
  readonly managerDealRelatedExpenses?: Maybe<Scalars['Decimal']>;
  readonly managerExpensesReimbursements?: Maybe<Scalars['Decimal']>;
  readonly managerGainLossOnOversale?: Maybe<Scalars['Decimal']>;
  readonly managerName: Scalars['String'];
  readonly managerRole: ExpensesManagerRole;
  /** Display name for ManagerRole */
  readonly managerRoleDisplayName?: Maybe<Scalars['String']>;
  readonly netDealFee?: Maybe<Scalars['Decimal']>;
  readonly offeringType: OfferingType;
  /** Display name for OfferingType */
  readonly offeringTypeDisplayName?: Maybe<Scalars['String']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionSpread?: Maybe<Scalars['Decimal']>;
  readonly totalLossOnOversale?: Maybe<Scalars['Decimal']>;
  readonly totalManagerExpensesReimbursements?: Maybe<Scalars['Decimal']>;
  readonly totalUnderwritingGroupExpense?: Maybe<Scalars['Decimal']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly trancheName?: Maybe<Scalars['String']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
  readonly underwritingPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingShares?: Maybe<Scalars['Long']>;
  readonly underwritingSpread?: Maybe<Scalars['Decimal']>;
  readonly userFirmName: Scalars['String'];
};

export enum ShareClass {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B = 'B',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  C = 'C',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  D = 'D',
  E = 'E',
  Eight = 'EIGHT',
  F = 'F',
  Five = 'FIVE',
  Four = 'FOUR',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  Nine = 'NINE',
  O = 'O',
  One = 'ONE',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  Seven = 'SEVEN',
  Six = 'SIX',
  T = 'T',
  Three = 'THREE',
  Two = 'TWO',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export type ShareClassFilterInput = {
  readonly eq?: InputMaybe<ShareClass>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<ShareClass>>>;
  readonly neq?: InputMaybe<ShareClass>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<ShareClass>>>;
};

export type SharedDraftHistory = {
  readonly __typename?: 'SharedDraftHistory';
  readonly managerKeys: ReadonlyArray<Scalars['String']>;
  readonly sharedTime: Scalars['Instant'];
  readonly version: Scalars['String'];
};

export type SharedDraftOfferingLimited = {
  readonly __typename?: 'SharedDraftOfferingLimited';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly finalFilingOfferPrice?: Maybe<Scalars['Decimal']>;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['UUID'];
  readonly isExecutingOnPlatform: Scalars['Boolean'];
  readonly isObsolete: Scalars['Boolean'];
  readonly issuerName: Scalars['String'];
  readonly issuerSector?: Maybe<Sector>;
  /** Display name for IssuerSector */
  readonly issuerSectorDisplayName?: Maybe<Scalars['String']>;
  readonly isUsOffering?: Maybe<Scalars['Boolean']>;
  readonly launchDate?: Maybe<Scalars['LocalDate']>;
  readonly leftLead?: Maybe<Scalars['String']>;
  readonly leftLeadFirmName?: Maybe<Scalars['String']>;
  readonly managers: ReadonlyArray<ManagerLimited>;
  readonly postponedDate?: Maybe<Scalars['LocalDate']>;
  readonly priceRangeHigh?: Maybe<Scalars['Decimal']>;
  readonly priceRangeLow?: Maybe<Scalars['Decimal']>;
  readonly pricingCurrencyCode: Scalars['String'];
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly pricingInstrumentCountryCode?: Maybe<Scalars['String']>;
  readonly pricingInstrumentCountryDisplayName?: Maybe<Scalars['String']>;
  readonly pricingInstrumentRegion?: Maybe<Scalars['String']>;
  readonly pricingInstrumentStockSymbol?: Maybe<Scalars['String']>;
  readonly publicFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly revisedFilingsCount?: Maybe<Scalars['Int']>;
  readonly securityIsDepositaryReceipt?: Maybe<Scalars['Boolean']>;
  readonly securityType: SecurityType;
  readonly sizeInCurrency?: Maybe<Scalars['Decimal']>;
  readonly sizeInShares?: Maybe<Scalars['Long']>;
  readonly stage: OfferingStage;
  readonly status: OfferingStatus;
  readonly supersededByOfferingId?: Maybe<Scalars['UUID']>;
  readonly supersededOfferingId?: Maybe<Scalars['UUID']>;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
  readonly version: Scalars['String'];
  readonly withdrawnDate?: Maybe<Scalars['LocalDate']>;
};

export type SharedDraftOfferingList = {
  readonly __typename?: 'SharedDraftOfferingList';
  readonly data: ReadonlyArray<SharedDraftOfferingLimited>;
};

/** A segment of a collection. */
export type SharedOfferingsCollectionSegment = {
  readonly __typename?: 'SharedOfferingsCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<OfferingFull>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type ShareHistoryFull = {
  readonly __typename?: 'ShareHistoryFull';
  readonly id: Scalars['UUID'];
  readonly managerKeys: ReadonlyArray<Scalars['String']>;
  readonly sharedAt: Scalars['Instant'];
  readonly version: Scalars['Int'];
};

export type ShareHistoryFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<ShareHistoryFullFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly managerKeys?: InputMaybe<ListStringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ShareHistoryFullFilterInput>>;
  readonly sharedAt?: InputMaybe<InstantOperationFilterInput>;
  readonly version?: InputMaybe<IntOperationFilterInput>;
};

export type SharesPerCmgEntity = {
  readonly __typename?: 'SharesPerCmgEntity';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly sharesValue: Scalars['Long'];
};

export type SharesPerCmgEntityFull = {
  readonly __typename?: 'SharesPerCmgEntityFull';
  readonly cmgEntityKey: Scalars['String'];
  readonly sharesValue: Scalars['Long'];
};

export type SharesPerCmgEntityFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SharesPerCmgEntityFullFilterInput>>;
  readonly cmgEntityKey?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SharesPerCmgEntityFullFilterInput>>;
  readonly sharesValue?: InputMaybe<LongOperationFilterInput>;
};

export type SharesPerCmgEntityInput = {
  readonly cmgEntityKey: Scalars['String'];
  readonly sharesValue: Scalars['Int'];
};

export type Signer = {
  readonly __typename?: 'Signer';
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly telephone: Scalars['String'];
  readonly title: Scalars['String'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Sponsor = {
  readonly __typename?: 'Sponsor';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly postOfferingOwnership?: Maybe<Scalars['Long']>;
  readonly postOfferingOwnershipIncludingShoe?: Maybe<Scalars['Long']>;
  readonly preOfferingOwnership?: Maybe<Scalars['Long']>;
  readonly recordStatus: RecordStatus;
};

export type Stabilization = {
  readonly __typename?: 'Stabilization';
  readonly id: Scalars['ID'];
  readonly notes: Scalars['String'];
  readonly trades: ReadonlyArray<StabilizationTrade>;
};

export type StabilizationInformation = {
  readonly __typename?: 'StabilizationInformation';
  readonly additionalShortPosition: Scalars['Long'];
  readonly finalAuthorizedOverallotment: Scalars['Long'];
  readonly id: Scalars['ID'];
  readonly offeringPrice: Scalars['Decimal'];
  readonly overallotmentExcercised?: Maybe<Scalars['Long']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
};

export enum StabilizationTnActivityType {
  Sb = 'SB',
  Sct = 'SCT',
}

export type StabilizationTnFilingActivity = {
  readonly __typename?: 'StabilizationTNFilingActivity';
  readonly date: Scalars['LocalDate'];
  readonly shares: Scalars['Long'];
  readonly stabilizationActivityType: StabilizationTnActivityType;
  /** Display name for StabilizationActivityType */
  readonly stabilizationActivityTypeDisplayName?: Maybe<Scalars['String']>;
};

export type StabilizationTnFilingActivityInput = {
  readonly date: Scalars['LocalDate'];
  readonly shares: Scalars['Long'];
  readonly stabilizationActivityType: StabilizationTnActivityType;
};

export type StabilizationTnFilingActivityResponse =
  | ServiceError
  | StabilizationTnFilingActivityWrapper;

export type StabilizationTnFilingActivityWrapper = {
  readonly __typename?: 'StabilizationTNFilingActivityWrapper';
  readonly activities: ReadonlyArray<StabilizationTnFilingActivity>;
};

export type StabilizationTrade = {
  readonly __typename?: 'StabilizationTrade';
  readonly activityType?: Maybe<TradeActivityType>;
  /** Display name for ActivityType */
  readonly activityTypeDisplayName?: Maybe<Scalars['String']>;
  readonly averagePrice?: Maybe<Scalars['Decimal']>;
  readonly date: Scalars['LocalDate'];
  readonly shares: Scalars['Long'];
  readonly type: TradeType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
};

export type StringExtendedData = {
  readonly __typename?: 'StringExtendedData';
  readonly key: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly value: Scalars['String'];
};

export type StringOperationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']>;
  readonly endsWith?: InputMaybe<Scalars['String']>;
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly like?: InputMaybe<Scalars['String']>;
  readonly ncontains?: InputMaybe<Scalars['String']>;
  readonly nendsWith?: InputMaybe<Scalars['String']>;
  readonly neq?: InputMaybe<Scalars['String']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']>;
};

export type SubmitMyRetailInput = {
  readonly instrumentId: Scalars['UUID'];
  readonly trancheId: Scalars['UUID'];
};

export type SubmitPassIndicationInput = {
  /** Investor information fields must be populated to capture buy-side information. */
  readonly investorInformation: InvestorInformationInput;
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly attestationStatusChanged: AttestationStatus;
  readonly coveredIndicationChanged: CoveredIndicationChanged;
  readonly indicationChanged: IndicationChanged;
  readonly investorRelationshipChanged: InvestorRelationshipChanged;
  readonly orderBookSalesStateChanged: OrderBookSalesState;
  readonly orderBookSalesStateChangedForSalesTrader: OrderBookSalesState;
  readonly orderBookStatusChanged: OrderBookStatus;
  readonly syndicateInstitutionalGridChanged: SyndicateInstitutionalGridChanged;
  readonly syndicateInstitutionalGridChangedSummaries: SyndicateGridChangeSummary;
};

export type SubscriptionAttestationStatusChangedArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type SubscriptionIndicationChangedArgs = {
  indicationId?: InputMaybe<Scalars['UUID']>;
  offeringId: Scalars['UUID'];
};

export type SubscriptionInvestorRelationshipChangedArgs = {
  investorFirmKeysOrIds: ReadonlyArray<Scalars['String']>;
};

export type SubscriptionOrderBookSalesStateChangedArgs = {
  offeringId: Scalars['UUID'];
};

export type SubscriptionOrderBookSalesStateChangedForSalesTraderArgs = {
  offeringId: Scalars['UUID'];
};

export type SubscriptionOrderBookStatusChangedArgs = {
  offeringId: Scalars['UUID'];
};

export type SubscriptionSyndicateInstitutionalGridChangedArgs = {
  investorFirmCmgEntityKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  offeringId: Scalars['UUID'];
};

export type SubscriptionSyndicateInstitutionalGridChangedSummariesArgs = {
  investorFirmCmgEntityKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  offeringId: Scalars['UUID'];
};

export type Syndicate = {
  readonly __typename?: 'Syndicate';
  /**
   * Deprecated; Use attributes in Underwriting Fees and Discounts under Terms.
   * @deprecated Use attributes in Underwriting Fees and Discounts under Terms.
   */
  readonly grossSpread?: Maybe<Scalars['Decimal']>;
  readonly id: Scalars['ID'];
  readonly managerEconomics: ManagerEconomics;
  readonly managerResponsibilities: ManagerResponsibilities;
  readonly managers: ReadonlyArray<Manager>;
};

export type SyndicateEntitlements = {
  readonly __typename?: 'SyndicateEntitlements';
  readonly cmgEntityKey: Scalars['String'];
  readonly entitlements: ReadonlyArray<Scalars['String']>;
  readonly recordStatus: RecordStatus;
};

export type SyndicateFull = {
  readonly __typename?: 'SyndicateFull';
  readonly managerResponsibilities: ManagerResponsibilitiesFull;
  readonly managers: ReadonlyArray<ManagerFull>;
};

export type SyndicateFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<SyndicateFullFilterInput>>;
  readonly managerResponsibilities?: InputMaybe<ManagerResponsibilitiesFullFilterInput>;
  readonly managers?: InputMaybe<ListFilterInputTypeOfManagerFullFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SyndicateFullFilterInput>>;
};

export type SyndicateGridChangeSummary = {
  readonly __typename?: 'SyndicateGridChangeSummary';
  readonly allocationRevisions?: Maybe<ReadonlyArray<Maybe<AllocationChangeDetail>>>;
  readonly batchId?: Maybe<Scalars['UUID']>;
  readonly changeType: SyndicateGridChangeType;
  readonly investorFirm?: Maybe<Scalars['String']>;
  readonly itemId: Scalars['UUID'];
  readonly manager?: Maybe<Scalars['String']>;
  readonly newAllocationSet?: Maybe<AllocationSetDetail>;
  readonly newIndication?: Maybe<IndicationDetail>;
  readonly offeringId: Scalars['UUID'];
  readonly oldAllocationSet?: Maybe<AllocationSetDetail>;
  readonly oldIndication?: Maybe<IndicationDetail>;
  readonly timestamp: Scalars['DateTime'];
};

export enum SyndicateGridChangeType {
  AllocationChanged = 'ALLOCATION_CHANGED',
  AllocationSetShared = 'ALLOCATION_SET_SHARED',
  DuplicationStatusChanged = 'DUPLICATION_STATUS_CHANGED',
  IndicationCreated = 'INDICATION_CREATED',
  IndicationInterestLevelChanged = 'INDICATION_INTEREST_LEVEL_CHANGED',
  IndicationStatusChanged = 'INDICATION_STATUS_CHANGED',
}

export enum SyndicateGridGroupingColumn {
  AttestationStatus = 'ATTESTATION_STATUS',
  BndAgentFirmDisplayName = 'BND_AGENT_FIRM_DISPLAY_NAME',
  InvestorCompliance1 = 'INVESTOR_COMPLIANCE1',
  InvestorCompliance2 = 'INVESTOR_COMPLIANCE2',
  InvestorCompliance3 = 'INVESTOR_COMPLIANCE3',
  InvestorCompliance4 = 'INVESTOR_COMPLIANCE4',
  InvestorCompliance5 = 'INVESTOR_COMPLIANCE5',
  InvestorRegion1 = 'INVESTOR_REGION1',
  InvestorRegion2 = 'INVESTOR_REGION2',
  InvestorRegion3 = 'INVESTOR_REGION3',
  InvestorRegion4 = 'INVESTOR_REGION4',
  InvestorRegion5 = 'INVESTOR_REGION5',
  InvestorType1 = 'INVESTOR_TYPE1',
  InvestorType2 = 'INVESTOR_TYPE2',
  InvestorType3 = 'INVESTOR_TYPE3',
  InvestorType4 = 'INVESTOR_TYPE4',
  InvestorType5 = 'INVESTOR_TYPE5',
  ProspectusDeliveryStatus = 'PROSPECTUS_DELIVERY_STATUS',
  SubmittedOn = 'SUBMITTED_ON',
}

export type SyndicateGridQueryInput = {
  readonly allocationSetIds?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly increment: Scalars['Decimal'];
  readonly max: Scalars['Decimal'];
  readonly min: Scalars['Decimal'];
  readonly referencePrice?: InputMaybe<Scalars['Decimal']>;
  readonly where?: InputMaybe<SyndicateInstitutionalGridRowFilterInput>;
};

export type SyndicateGridTotalsRow = {
  readonly __typename?: 'SyndicateGridTotalsRow';
  readonly allocations: ReadonlyArray<AllocationTotalRow>;
  readonly demandAtMarket: Scalars['Long'];
  readonly demandLevels: ReadonlyArray<DemandLevel>;
  readonly demandMax: Scalars['Long'];
  readonly indicationCount: Scalars['Int'];
};

export type SyndicateInstitutionalGridChanged = {
  readonly __typename?: 'SyndicateInstitutionalGridChanged';
  readonly eventSource?: Maybe<Scalars['String']>;
  readonly offeringId: Scalars['UUID'];
};

/** A segment of a collection. */
export type SyndicateInstitutionalGridCollectionSegment = {
  readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<SyndicateInstitutionalGridRow>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly summaries: SyndicateInstitutionalGridSummaries;
};

export type SyndicateInstitutionalGridRow = {
  readonly __typename?: 'SyndicateInstitutionalGridRow';
  /** A comma separated list of acknowledging managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly acknowledgedByFirmNames?: Maybe<Scalars['String']>;
  readonly acknowledgedByUser?: Maybe<UserInfo>;
  readonly acknowledgements?: Maybe<ReadonlyArray<IndicationAcknowledgement>>;
  readonly alert: RowAlert;
  readonly allocationAcknowledgedBy?: Maybe<UserInfo>;
  readonly allocations?: Maybe<ReadonlyArray<Allocation>>;
  readonly attestationStatus?: Maybe<AttestationFormStatus>;
  readonly auditInfo: AuditInformation;
  readonly bndAgent?: Maybe<FirmInfo>;
  /** A comma separated list of covering managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly coveringManagerNames?: Maybe<Scalars['String']>;
  readonly coveringManagers?: Maybe<ReadonlyArray<FirmInfo>>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly demandAtMarket: Scalars['Long'];
  readonly demandCurrency?: Maybe<Scalars['String']>;
  readonly demandLevels: ReadonlyArray<DemandLevel>;
  readonly demandMax: Scalars['Long'];
  readonly duplicateOfIndicationId?: Maybe<Scalars['UUID']>;
  readonly hasDuplicates: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly instrumentId?: Maybe<Scalars['UUID']>;
  readonly interestLevels?: Maybe<ReadonlyArray<InterestLevel>>;
  readonly investor: InvestorInfo;
  readonly investorReplyStatus: AllocationAcknowledgement;
  readonly notes?: Maybe<IndicationNotes>;
  readonly offeringId: Scalars['UUID'];
  readonly prospectusDeliverySummary: ProspectusDeliverySummary;
  /** A list of sales users in the current firm who are covering the investor on this indication */
  readonly salesCoverage?: Maybe<ReadonlyArray<Scalars['String']>>;
  /**
   * A comma separated list of sales users in the current firm who are covering the investor on this indication.
   * This can be used for display and sorting in the grid.
   */
  readonly salesCoverageDisplayValue?: Maybe<Scalars['String']>;
  readonly status: IndicationStatus;
  readonly trancheId?: Maybe<Scalars['UUID']>;
  readonly type: InstitutionalIndicationOrderType;
  /** Indication type display value. For 'Limit' type, it's formatted as "Limit @ $12.34". */
  readonly typeDisplayValue: Scalars['String'];
};

export type SyndicateInstitutionalGridRowFilterInput = {
  /** A comma separated list of acknowledging managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly acknowledgedByFirmNames?: InputMaybe<StringOperationFilterInput>;
  readonly acknowledgedByUser?: InputMaybe<UserInfoFilterInput>;
  readonly acknowledgements?: InputMaybe<ListFilterInputTypeOfIndicationAcknowledgementDtoFilterInput>;
  readonly alert?: InputMaybe<RowAlertFilterInput>;
  readonly allocationAcknowledgedBy?: InputMaybe<UserInfoFilterInput>;
  readonly allocations?: InputMaybe<ListFilterInputTypeOfAllocationDtoFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<SyndicateInstitutionalGridRowFilterInput>>;
  readonly attestationStatus?: InputMaybe<NullableOfCertificateStatusOperationFilterInput>;
  readonly auditInfo?: InputMaybe<AuditInformationFilterInput>;
  readonly bndAgent?: InputMaybe<FirmInfoFilterInput>;
  /** A comma separated list of covering managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly coveringManagerNames?: InputMaybe<StringOperationFilterInput>;
  readonly coveringManagers?: InputMaybe<ListFilterInputTypeOfFirmInfoFilterInput>;
  readonly currencyCode?: InputMaybe<StringOperationFilterInput>;
  readonly demandAtMarket?: InputMaybe<LongOperationFilterInput>;
  readonly demandCurrency?: InputMaybe<StringOperationFilterInput>;
  readonly demandLevels?: InputMaybe<ListFilterInputTypeOfDemandLevelFilterInput>;
  readonly demandMax?: InputMaybe<LongOperationFilterInput>;
  readonly duplicateOfIndicationId?: InputMaybe<UuidOperationFilterInput>;
  readonly hasDuplicates?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly instrumentId?: InputMaybe<UuidOperationFilterInput>;
  readonly interestLevels?: InputMaybe<InterestLevelListFilterInput>;
  readonly investor?: InputMaybe<InvestorInfoFilterInput>;
  readonly investorReplyStatus?: InputMaybe<AllocationAcknowledgementOperationFilterInput>;
  readonly notes?: InputMaybe<IndicationNotesFilterInput>;
  readonly offeringId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<SyndicateInstitutionalGridRowFilterInput>>;
  readonly prospectusDeliverySummary?: InputMaybe<ProspectusDeliverySummaryDtoFilterInput>;
  /** A list of sales users in the current firm who are covering the investor on this indication */
  readonly salesCoverage?: InputMaybe<ListStringOperationFilterInput>;
  /**
   * A comma separated list of sales users in the current firm who are covering the investor on this indication.
   * This can be used for display and sorting in the grid.
   */
  readonly salesCoverageDisplayValue?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<IndicationStatusFilterInput>;
  readonly trancheId?: InputMaybe<UuidOperationFilterInput>;
  readonly type?: InputMaybe<IndicationTypeOperationFilterInput>;
  /** Indication type display value. For 'Limit' type, it's formatted as "Limit @ $12.34". */
  readonly typeDisplayValue?: InputMaybe<StringOperationFilterInput>;
};

export type SyndicateInstitutionalGridRowSortInput = {
  /** A comma separated list of acknowledging managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly acknowledgedByFirmNames?: InputMaybe<SortEnumType>;
  readonly acknowledgedByUser?: InputMaybe<UserInfoSortInput>;
  readonly alert?: InputMaybe<RowAlertSortInput>;
  readonly allocationAcknowledgedBy?: InputMaybe<UserInfoSortInput>;
  readonly allocations_1?: InputMaybe<AllocationDtoSortInput>;
  readonly allocations_2?: InputMaybe<AllocationDtoSortInput>;
  readonly allocations_3?: InputMaybe<AllocationDtoSortInput>;
  readonly attestationStatus?: InputMaybe<SortEnumType>;
  readonly auditInfo?: InputMaybe<AuditInformationSortInput>;
  readonly bndAgent?: InputMaybe<FirmInfoSortInput>;
  /** A comma separated list of covering managers' display names, sorted alphabetically, but with my firm's display name always first. */
  readonly coveringManagerNames?: InputMaybe<SortEnumType>;
  readonly currencyCode?: InputMaybe<SortEnumType>;
  readonly demandAtMarket?: InputMaybe<SortEnumType>;
  readonly demandCurrency?: InputMaybe<SortEnumType>;
  readonly demandLevelAtPrice_1?: InputMaybe<DemandLevelSortInput>;
  readonly demandLevelAtPrice_2?: InputMaybe<DemandLevelSortInput>;
  readonly demandLevelAtPrice_3?: InputMaybe<DemandLevelSortInput>;
  readonly demandMax?: InputMaybe<SortEnumType>;
  readonly duplicateOfIndicationId?: InputMaybe<SortEnumType>;
  readonly hasDuplicates?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly instrumentId?: InputMaybe<SortEnumType>;
  readonly investor?: InputMaybe<InvestorInfoSortInput>;
  readonly investorReplyStatus?: InputMaybe<SortEnumType>;
  readonly notes?: InputMaybe<IndicationNotesSortInput>;
  readonly offeringId?: InputMaybe<SortEnumType>;
  readonly prospectusDeliverySummary?: InputMaybe<ProspectusDeliverySummaryDtoSortInput>;
  /**
   * A comma separated list of sales users in the current firm who are covering the investor on this indication.
   * This can be used for display and sorting in the grid.
   */
  readonly salesCoverageDisplayValue?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly trancheId?: InputMaybe<SortEnumType>;
  readonly type?: InputMaybe<SortEnumType>;
  /** Indication type display value. For 'Limit' type, it's formatted as "Limit @ $12.34". */
  readonly typeDisplayValue?: InputMaybe<SortEnumType>;
};

export type SyndicateInstitutionalGridSummaries = {
  readonly __typename?: 'SyndicateInstitutionalGridSummaries';
  readonly summaryMetrics: InstitutionalDemandSummaryMetrics;
  readonly totals: SyndicateGridTotalsRow;
};

export type SyndicateManager = {
  readonly __typename?: 'SyndicateManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly managerResponsibilities: ManagerResponsibilities;
  readonly managers: ReadonlyArray<SyndicateManagerProfile>;
};

export type SyndicateManagerProfile = {
  readonly __typename?: 'SyndicateManagerProfile';
  readonly cmgEntityKey: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly firmNameAbbreviation?: Maybe<Scalars['String']>;
  readonly isJuniorRole: Scalars['Boolean'];
  readonly isParticipating: Scalars['Boolean'];
  readonly role: ManagerRole;
};

export type SyndicateProfile = {
  readonly __typename?: 'SyndicateProfile';
  readonly managerResponsibilities: ManagerResponsibilitiesProfile;
  readonly managers: ReadonlyArray<ManagerProfile>;
};

export type SyndicateWires = {
  readonly __typename?: 'SyndicateWires';
  readonly billAndDeliverPayments: ReadonlyArray<BillingAndDeliveryPayment>;
  readonly brokerDealersMyRetail: ReadonlyArray<MyRetailForBrokerDealer>;
  readonly brokerDealersMyRetailFees: ReadonlyArray<BrokerDealerMyRetailFees>;
  readonly customVariables: ReadonlyArray<CustomVariableGroup>;
  readonly firmDistributionLists: ReadonlyArray<FirmDistributionList>;
  readonly id: Scalars['ID'];
  readonly managerDtcNumbers: ReadonlyArray<ManagerDtcNumber>;
  readonly managerRetailRetentionFees: ReadonlyArray<ManagerRetailRetentionFees>;
  readonly managerRevisedUnderwritingCommitment: ReadonlyArray<ManagerRevisedUnderwritingCommitment>;
  readonly managers: ReadonlyArray<SyndicateWiresManager>;
  readonly masterAauDate?: Maybe<Scalars['Date']>;
  readonly masterSdaDate?: Maybe<Scalars['Date']>;
  readonly offeringId: Scalars['UUID'];
  readonly publishedOrPrivateOffering: SyndicateWiresOffering;
  readonly wiresDisclaimer?: Maybe<Scalars['String']>;
};

export type SyndicateWiresCustomVariablesArgs = {
  wireTemplateType: WireTemplateType;
};

export type SyndicateWiresFirmDistributionListsArgs = {
  cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type SyndicateWiresWiresDisclaimerArgs = {
  offeringType: OfferingType;
  templateType: WireTemplateType;
};

export type SyndicateWiresManager = {
  readonly __typename?: 'SyndicateWiresManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly economics?: Maybe<Scalars['Long']>;
  readonly firmName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly role: SyndicateWiresManagerRole;
};

export enum SyndicateWiresManagerRole {
  ActiveBookrunner = 'ACTIVE_BOOKRUNNER',
  Bookrunner = 'BOOKRUNNER',
  CoLead = 'CO_LEAD',
  CoManager = 'CO_MANAGER',
  SellingGroupMember = 'SELLING_GROUP_MEMBER',
  Underwriter = 'UNDERWRITER',
}

export type SyndicateWiresOffering = {
  readonly __typename?: 'SyndicateWiresOffering';
  readonly id: Scalars['ID'];
  readonly stage: OfferingStage;
};

export type TerminationDate = {
  readonly __typename?: 'TerminationDate';
  readonly dateTime: Scalars['Instant'];
  readonly timeZone: Scalars['DateTimeZone'];
};

export type TerminationWire = {
  readonly __typename?: 'TerminationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly terminationDate: TerminationDate;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type TerminationWireResponseUnion = ServiceError | TerminationWire;

export type TerminationWireValidation = WireValidationBase & {
  readonly __typename?: 'TerminationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<TerminationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum TerminationWireValidationOfferingErrors {
  IssuerName = 'ISSUER_NAME',
  OfferingType = 'OFFERING_TYPE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type Terms = {
  readonly __typename?: 'Terms';
  /** An ordered collections of filings beginning with INITIAL -&gt; REVISED -&gt; FINAL */
  readonly filings: ReadonlyArray<Filing>;
  readonly followOnPricing?: Maybe<FollowOnPricing>;
  readonly lockUpPeriod?: Maybe<Scalars['Int']>;
  readonly overAllotmentExercisedData?: Maybe<OverAllotmentExercisedData>;
  readonly underwritingFeesAndDiscounts?: Maybe<UnderwritingFeesAndDiscounts>;
  readonly useOfProceeds: ReadonlyArray<UseOfProceeds>;
};

export type TermsFull = {
  readonly __typename?: 'TermsFull';
  /** An ordered collections of filings beginning with INITIAL -&gt; REVISED -&gt; FINAL */
  readonly filings: ReadonlyArray<FilingFull>;
  readonly followOnPricing?: Maybe<FollowOnPricingFull>;
  readonly lockUpPeriod?: Maybe<Scalars['Int']>;
  readonly overAllotmentExercisedData?: Maybe<OverAllotmentExercisedFull>;
  readonly underwritingFeesAndDiscounts?: Maybe<UnderwritingFeesAndDiscountsFull>;
  readonly useOfProceeds: ReadonlyArray<UseOfProceeds>;
};

export type TermsFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<TermsFullFilterInput>>;
  /** An ordered collections of filings beginning with INITIAL -&gt; REVISED -&gt; FINAL */
  readonly filings?: InputMaybe<ListFilterInputTypeOfFilingFullFilterInput>;
  readonly followOnPricing?: InputMaybe<FollowOnPricingFullFilterInput>;
  readonly lockUpPeriod?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<TermsFullFilterInput>>;
  readonly overAllotmentExercisedData?: InputMaybe<OverAllotmentExercisedFullFilterInput>;
  readonly underwritingFeesAndDiscounts?: InputMaybe<UnderwritingFeesAndDiscountsFullFilterInput>;
  readonly useOfProceeds?: InputMaybe<UseOfProceedsListFilterInput>;
};

export type TermsProfile = {
  readonly __typename?: 'TermsProfile';
  /** An ordered collections of filings beginning with INITIAL -&gt; REVISED -&gt; FINAL */
  readonly filings: ReadonlyArray<FilingProfile>;
  readonly followOnPricing?: Maybe<FollowOnPricingProfile>;
};

export type TimezoneInfo = {
  readonly __typename?: 'TimezoneInfo';
  readonly timeZoneAcronym: Scalars['String'];
  readonly timeZoneId: Scalars['String'];
  readonly timeZoneName: Scalars['String'];
};

export type Timing = {
  readonly __typename?: 'Timing';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly confidentialFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly filingOccurred?: Maybe<MarketTiming>;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly launchDate?: Maybe<Scalars['LocalDate']>;
  readonly launchOccurred?: Maybe<MarketTiming>;
  readonly postponedDate?: Maybe<Scalars['LocalDate']>;
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly publicFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly settlementDate?: Maybe<Scalars['LocalDate']>;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly withdrawnDate?: Maybe<Scalars['LocalDate']>;
};

export type TimingFull = {
  readonly __typename?: 'TimingFull';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly confidentialFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly filingOccured?: Maybe<MarketTiming>;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly launchDate?: Maybe<Scalars['LocalDate']>;
  readonly launchOccured?: Maybe<MarketTiming>;
  readonly postponedDate?: Maybe<Scalars['LocalDate']>;
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly publicFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly settlementDate?: Maybe<Scalars['LocalDate']>;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly withdrawnDate?: Maybe<Scalars['LocalDate']>;
};

export type TimingFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<TimingFullFilterInput>>;
  readonly booksCloseAt?: InputMaybe<InstantOperationFilterInput>;
  readonly confidentialFilingDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly filingOccured?: InputMaybe<MarketTimingFilterInput>;
  readonly firstTradeDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly launchDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly launchOccured?: InputMaybe<MarketTimingFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<TimingFullFilterInput>>;
  readonly postponedDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly pricingDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly publicFilingDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly settlementDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly terminatedDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly timeZone?: InputMaybe<DateTimeZoneOperationFilterInput>;
  readonly tradeDate?: InputMaybe<LocalDateOperationFilterInput>;
  readonly withdrawnDate?: InputMaybe<LocalDateOperationFilterInput>;
};

export type TimingProfile = {
  readonly __typename?: 'TimingProfile';
  readonly booksCloseAt?: Maybe<Scalars['Instant']>;
  readonly confidentialFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly filingOccurred?: Maybe<MarketTiming>;
  readonly firstTradeDate?: Maybe<Scalars['LocalDate']>;
  readonly launchDate?: Maybe<Scalars['LocalDate']>;
  readonly launchOccurred?: Maybe<MarketTiming>;
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly publicFilingDate?: Maybe<Scalars['LocalDate']>;
  readonly settlementDate?: Maybe<Scalars['LocalDate']>;
  readonly terminatedDate?: Maybe<Scalars['LocalDate']>;
  readonly timeZone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly withdrawnDate?: Maybe<Scalars['LocalDate']>;
};

export enum TnBasisCodeType {
  AdtvPfv = 'ADTV_PFV',
  Other = 'OTHER',
  Uar = 'UAR',
}

export type TnFiling = {
  readonly __typename?: 'TNFiling';
  readonly activities: ReadonlyArray<TnFilingActivity>;
  readonly adtvNumber?: Maybe<Scalars['Float']>;
  readonly adtvSource?: Maybe<Scalars['String']>;
  readonly atmTrades: ReadonlyArray<TnFilingAtmTrade>;
  readonly basisCode?: Maybe<TnBasisCodeType>;
  /** Display name for BasisCode */
  readonly basisCodeDisplayName?: Maybe<Scalars['String']>;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly filingCreatorEmail?: Maybe<Scalars['String']>;
  readonly finraErrorMessages: ReadonlyArray<Scalars['String']>;
  readonly finraMemberFirms: ReadonlyArray<TnFilingMemberFirm>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmDealId: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly formUniqueId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isConcurrentConvertible: Scalars['Boolean'];
  readonly issuerName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly nonAtmTradeInfo?: Maybe<TnFilingNonAtmTrade>;
  readonly nonMemberFirms: ReadonlyArray<TnFilingNonMemberFirm>;
  readonly otherBasisDescription?: Maybe<Scalars['String']>;
  readonly pricingBasisDescription?: Maybe<Scalars['String']>;
  readonly pricingBasisType: PricingBasisType;
  /** Display name for PricingBasisType */
  readonly pricingBasisTypeDisplayName?: Maybe<Scalars['String']>;
  readonly publicFloatValue?: Maybe<Scalars['Float']>;
  readonly publicFloatValueSource?: Maybe<Scalars['String']>;
  readonly restrictedPeriod: TnRestrictedPeriodType;
  /** Display name for RestrictedPeriod */
  readonly restrictedPeriodDisplayName?: Maybe<Scalars['String']>;
  readonly restrictedPeriodEndDateTime?: Maybe<Scalars['Instant']>;
  readonly restrictedPeriodEndTimezone?: Maybe<Scalars['DateTimeZone']>;
  readonly restrictedPeriodStartDateTime?: Maybe<Scalars['Instant']>;
  readonly restrictedPeriodStartTimezone?: Maybe<Scalars['DateTimeZone']>;
  readonly securityType: TnSecurityType;
  readonly securityTypeDescription?: Maybe<Scalars['String']>;
  /** Display name for SecurityType */
  readonly securityTypeDisplayName?: Maybe<Scalars['String']>;
  readonly stabilizationActivities: ReadonlyArray<StabilizationTnFilingActivity>;
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
  readonly symbolsOfCoveredSecurities: ReadonlyArray<Scalars['String']>;
  readonly telephoneNumber: Scalars['String'];
  readonly transactionType: TransactionType;
  readonly transactionTypeDescription?: Maybe<Scalars['String']>;
  /** Display name for TransactionType */
  readonly transactionTypeDisplayName?: Maybe<Scalars['String']>;
  readonly uarDate?: Maybe<Scalars['LocalDate']>;
};

export type TnFilingActivity = {
  readonly __typename?: 'TNFilingActivity';
  readonly activityType: ActivityType;
  /** Display name for ActivityType */
  readonly activityTypeDisplayName?: Maybe<Scalars['String']>;
  readonly aggregateNumberOfShares: Scalars['Long'];
  readonly date: Scalars['LocalDate'];
};

export type TnFilingActivityInput = {
  readonly activityType: ActivityType;
  readonly aggregateNumberOfShares: Scalars['Long'];
  readonly date: Scalars['LocalDate'];
};

export type TnFilingAtmTrade = {
  readonly __typename?: 'TNFilingAtmTrade';
  readonly averagePrice: Scalars['Decimal'];
  readonly currency: TradeCurrencyType;
  readonly numberOfShares: Scalars['Long'];
  readonly tradeDate: Scalars['LocalDate'];
};

export type TnFilingAtmTradeInput = {
  readonly averagePrice: Scalars['Decimal'];
  readonly currency: TradeCurrencyType;
  readonly numberOfShares: Scalars['Long'];
  readonly tradeDate: Scalars['LocalDate'];
};

export type TnFilingFormDataInput = {
  readonly activities: ReadonlyArray<TnFilingActivityInput>;
  readonly adtvNumber?: InputMaybe<Scalars['Float']>;
  readonly adtvSource?: InputMaybe<Scalars['String']>;
  readonly atmTrades: ReadonlyArray<TnFilingAtmTradeInput>;
  readonly basisCode?: InputMaybe<TnBasisCodeType>;
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly isConcurrentConvertible: Scalars['Boolean'];
  readonly issuerSymbol: Scalars['String'];
  readonly memberFirms: ReadonlyArray<TnFilingMemberFirmInput>;
  readonly nonAtmTradeInfo?: InputMaybe<TnFilingNonAtmTradeInput>;
  readonly nonMemberFirms: ReadonlyArray<TnFilingNonMemberFirmInput>;
  readonly otherBasisDescription?: InputMaybe<Scalars['String']>;
  readonly pricingBasisDescription?: InputMaybe<Scalars['String']>;
  readonly pricingBasisType: PricingBasisType;
  readonly publicFloatValue?: InputMaybe<Scalars['Float']>;
  readonly publicFloatValueSource?: InputMaybe<Scalars['String']>;
  readonly restrictedPeriod: TnRestrictedPeriodType;
  readonly restrictedPeriodEndDateTime?: InputMaybe<Scalars['Instant']>;
  readonly restrictedPeriodEndTimezone?: InputMaybe<Scalars['DateTimeZone']>;
  readonly restrictedPeriodStartDateTime?: InputMaybe<Scalars['Instant']>;
  readonly restrictedPeriodStartTimezone?: InputMaybe<Scalars['DateTimeZone']>;
  readonly securityType: TnSecurityType;
  readonly securityTypeDescription?: InputMaybe<Scalars['String']>;
  readonly stabilizationActivities: ReadonlyArray<StabilizationTnFilingActivityInput>;
  readonly symbolsOfCoveredSecurities: ReadonlyArray<Scalars['String']>;
  readonly telephoneNumber: Scalars['String'];
  readonly transactionType: TransactionType;
  readonly transactionTypeDescription?: InputMaybe<Scalars['String']>;
  readonly uarDate?: InputMaybe<Scalars['LocalDate']>;
};

export type TnFilingMemberFirm = {
  readonly __typename?: 'TNFilingMemberFirm';
  readonly affiliateMemberId?: Maybe<Scalars['UUID']>;
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly ownerCmgEntityKey?: Maybe<Scalars['String']>;
};

export type TnFilingMemberFirmInput = {
  readonly affiliateMemberId?: InputMaybe<Scalars['UUID']>;
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid?: InputMaybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
};

export type TnFilingNonAtmTrade = {
  readonly __typename?: 'TNFilingNonAtmTrade';
  readonly currency: TradeCurrencyType;
  readonly lastSaleBeforeDistributionCurrency?: Maybe<TradeCurrencyType>;
  readonly lastSaleBeforeDistributionDate?: Maybe<Scalars['LocalDate']>;
  readonly lastSaleBeforeDistributionPrice?: Maybe<Scalars['Decimal']>;
  readonly numberOfShares: Scalars['Long'];
  readonly offeringPrice: Scalars['Decimal'];
  readonly pricingDate: Scalars['LocalDate'];
  readonly secEffectiveDateTime?: Maybe<Scalars['Instant']>;
  readonly secEffectiveTimezone?: Maybe<Scalars['DateTimeZone']>;
  readonly tradeDate: Scalars['LocalDate'];
};

export type TnFilingNonAtmTradeInput = {
  readonly currency: TradeCurrencyType;
  readonly lastSaleBeforeDistributionCurrency?: InputMaybe<TradeCurrencyType>;
  readonly lastSaleBeforeDistributionDate?: InputMaybe<Scalars['LocalDate']>;
  readonly lastSaleBeforeDistributionPrice?: InputMaybe<Scalars['Decimal']>;
  readonly numberOfShares: Scalars['Long'];
  readonly offeringPrice: Scalars['Decimal'];
  readonly pricingDate: Scalars['LocalDate'];
  readonly secEffectiveDateTime?: InputMaybe<Scalars['Instant']>;
  readonly secEffectiveTimezone?: InputMaybe<Scalars['DateTimeZone']>;
  readonly tradeDate: Scalars['LocalDate'];
};

export type TnFilingNonMemberFirm = {
  readonly __typename?: 'TNFilingNonMemberFirm';
  /** CmgEntityKey of the firm, not null when non member firm is a manager on the offering */
  readonly cmgEntityKey?: Maybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  /** CmgEntityKey of the manager this non-owner firm is affiliated with */
  readonly ownerCmgEntityKey?: Maybe<Scalars['String']>;
};

export type TnFilingNonMemberFirmInput = {
  /** CmgEntityKey of the firm, not null when non member firm is a manager on the offering */
  readonly cmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  /** CmgEntityKey of the manager this non-owner firm is affiliated with */
  readonly ownerCmgEntityKey?: InputMaybe<Scalars['String']>;
};

export enum TnRestrictedPeriodType {
  FiveDays = 'FIVE_DAYS',
  Na = 'NA',
  OneDay = 'ONE_DAY',
}

export enum TnSecurityType {
  Cnvrb = 'CNVRB',
  Eqty = 'EQTY',
  Other = 'OTHER',
  Prfrd = 'PRFRD',
  Wrrnt = 'WRRNT',
}

export type TotalRetailInfo = {
  readonly __typename?: 'TotalRetailInfo';
  readonly id: Scalars['ID'];
  readonly releasedRetailRetention?: Maybe<TotalRetentionInfo>;
  readonly submittedRetailDemand?: Maybe<TotalSubmittedRetailDemandInfo>;
};

export type TotalRetailSummaryGrid = {
  readonly __typename?: 'TotalRetailSummaryGrid';
  readonly id: Scalars['ID'];
  readonly items: ReadonlyArray<TotalRetailSummaryItem>;
  readonly retailTarget?: Maybe<Scalars['Long']>;
};

export type TotalRetailSummaryItem = {
  readonly __typename?: 'TotalRetailSummaryItem';
  readonly demand: TotalRetailSummaryItemDemand;
  readonly id: Scalars['ID'];
  readonly manager: TotalRetailSummaryItemManager;
  readonly retention: TotalRetailSummaryItemRetention;
};

export type TotalRetailSummaryItemDemand = {
  readonly __typename?: 'TotalRetailSummaryItemDemand';
  readonly id: Scalars['ID'];
  readonly isSubmitted: Scalars['Boolean'];
  readonly modifiedAt?: Maybe<Scalars['String']>;
  readonly modifiedByFirmKey?: Maybe<Scalars['String']>;
  readonly roundLots?: Maybe<Scalars['Int']>;
  readonly shareQuantity?: Maybe<Scalars['Long']>;
};

export type TotalRetailSummaryItemManager = {
  readonly __typename?: 'TotalRetailSummaryItemManager';
  readonly cmgEntityKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isOnPlatform: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly role: OrderBookManagerRole;
  /** Display name for Role */
  readonly roleDisplayName?: Maybe<Scalars['String']>;
};

export type TotalRetailSummaryItemRetention = {
  readonly __typename?: 'TotalRetailSummaryItemRetention';
  readonly id: Scalars['ID'];
  readonly isReleased: Scalars['Boolean'];
  readonly modifiedAt?: Maybe<Scalars['String']>;
  readonly modifiedByFirmKey?: Maybe<Scalars['String']>;
  readonly shareQuantity?: Maybe<Scalars['Long']>;
};

export type TotalRetentionInfo = {
  readonly __typename?: 'TotalRetentionInfo';
  readonly id: Scalars['ID'];
  readonly releasedDateTime: Scalars['Instant'];
  readonly shareQuantity: Scalars['Long'];
};

export type TotalSubmittedRetailDemandInfo = {
  readonly __typename?: 'TotalSubmittedRetailDemandInfo';
  readonly id: Scalars['ID'];
  readonly shareQuantity: Scalars['Long'];
  readonly submittedDateTime: Scalars['Instant'];
};

export enum TradeAction {
  Cancelled = 'CANCELLED',
  New = 'NEW',
}

export enum TradeActivityType {
  NotRule_104Reportable = 'NOT_RULE_104_REPORTABLE',
  StabilizingBid = 'STABILIZING_BID',
  SyndicateCovering = 'SYNDICATE_COVERING',
}

export enum TradeCurrencyType {
  Cad = 'CAD',
  Usd = 'USD',
}

export type TradeGridItem = {
  readonly __typename?: 'TradeGridItem';
  readonly allocationShares?: Maybe<Scalars['Long']>;
  readonly allowedStatusTransitions: ReadonlyArray<TradeReleaseGridStatusTransition>;
  readonly cusip?: Maybe<Scalars['String']>;
  readonly exchange?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly indicationAcknowledgedByEmail?: Maybe<Scalars['String']>;
  readonly indicationAcknowledgedByName?: Maybe<Scalars['String']>;
  readonly indicationId: Scalars['UUID'];
  readonly indicationInvestorContactEmail?: Maybe<Scalars['String']>;
  readonly indicationInvestorContactName?: Maybe<Scalars['String']>;
  readonly indicationStatus: IndicationStatus;
  readonly indicationSubmittedBy: Scalars['String'];
  readonly investorBankKey?: Maybe<Scalars['String']>;
  readonly investorCmgEntityKey: Scalars['String'];
  readonly investorContactEmail?: Maybe<Scalars['String']>;
  readonly investorContactName?: Maybe<Scalars['String']>;
  readonly investorFirmName: Scalars['String'];
  readonly investorReplyEmail?: Maybe<Scalars['String']>;
  readonly investorReplyName?: Maybe<Scalars['String']>;
  readonly investorReplyStatus?: Maybe<InvestorReplyStatus>;
  readonly isin?: Maybe<Scalars['String']>;
  readonly offeringCurrency: Scalars['String'];
  readonly offeringIssuerName: Scalars['String'];
  readonly offeringType: OfferingType;
  readonly releasedAt?: Maybe<Scalars['Instant']>;
  readonly releasedByEmail?: Maybe<Scalars['String']>;
  readonly releasedByName?: Maybe<Scalars['String']>;
  readonly securityType: OfferingSecurityType;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly settlementCurrency: Scalars['String'];
  readonly settlementDate: Scalars['LocalDate'];
  readonly sharesReleased?: Maybe<Scalars['Long']>;
  readonly status: TradeReleaseStatus;
  readonly statusDetails?: Maybe<Scalars['String']>;
  readonly symbol?: Maybe<Scalars['String']>;
  readonly tradeAction?: Maybe<TradeAction>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly tradeId?: Maybe<Scalars['UUID']>;
  readonly tradePrice: Scalars['Decimal'];
  readonly warnings: ReadonlyArray<TradeReleaseGridWarning>;
};

export enum TradeReleaseGridStatusTransition {
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  CancelledManual = 'CANCELLED_MANUAL',
  Released = 'RELEASED',
}

export enum TradeReleaseGridWarning {
  AllocationResponseChanged = 'ALLOCATION_RESPONSE_CHANGED',
  IndicationCancelled = 'INDICATION_CANCELLED',
  IndicationDuplicated = 'INDICATION_DUPLICATED',
  IndicationPassed = 'INDICATION_PASSED',
  QuantityToReleaseChanged = 'QUANTITY_TO_RELEASE_CHANGED',
  QuantityToReleaseChangedToZero = 'QUANTITY_TO_RELEASE_CHANGED_TO_ZERO',
  RereleaseRequired = 'RERELEASE_REQUIRED',
  TradeDetailsChanged = 'TRADE_DETAILS_CHANGED',
  TradeReassigned = 'TRADE_REASSIGNED',
  UnassignedBndAgent = 'UNASSIGNED_BND_AGENT',
}

export type TradeReleaseSettings = {
  readonly __typename?: 'TradeReleaseSettings';
  readonly id: Scalars['ID'];
  readonly isAutoreleaseEnabled: Scalars['Boolean'];
};

export enum TradeReleaseStatus {
  Booked = 'BOOKED',
  BookedManual = 'BOOKED_MANUAL',
  CancelPending = 'CANCEL_PENDING',
  Cancelled = 'CANCELLED',
  CancelledManual = 'CANCELLED_MANUAL',
  PartiallyBooked = 'PARTIALLY_BOOKED',
  ReleaseFailed = 'RELEASE_FAILED',
  Released = 'RELEASED',
  Unreleased = 'UNRELEASED',
}

export type TradeReleaseValidation = {
  readonly __typename?: 'TradeReleaseValidation';
  readonly id: Scalars['ID'];
  /** Whether or not Allocations have been released. */
  readonly isInstitutionalAllocationSetReleased: Scalars['Boolean'];
};

export enum TradeType {
  Buy = 'BUY',
  Sell = 'SELL',
}

export type Tranche = {
  readonly __typename?: 'Tranche';
  /** Returns the list of Demand Currencies for a Tranche */
  readonly currencies: ReadonlyArray<DemandCurrency>;
  readonly currencyIds: ReadonlyArray<Scalars['String']>;
  readonly defaultInstrumentId?: Maybe<Scalars['UUID']>;
  readonly deliveryInstrumentIds: ReadonlyArray<Scalars['ID']>;
  /** Returns the list of Delivery Instruments for a Tranche */
  readonly deliveryInstruments: ReadonlyArray<DeliveryInstrument>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly recordStatus: RecordStatus;
  readonly region?: Maybe<Scalars['String']>;
};

export type TrancheCurrenciesArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type TrancheDeliveryInstrumentsArgs = {
  filters?: InputMaybe<RecordStatusFilterInput>;
};

export type TrancheFull = {
  readonly __typename?: 'TrancheFull';
  readonly defaultInstrumentId?: Maybe<Scalars['UUID']>;
  readonly demandCurrencies: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly instruments: ReadonlyArray<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly region?: Maybe<Scalars['String']>;
};

export type TrancheFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<TrancheFullFilterInput>>;
  readonly defaultInstrumentId?: InputMaybe<UuidOperationFilterInput>;
  readonly demandCurrencies?: InputMaybe<ListStringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly instruments?: InputMaybe<ListUuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<TrancheFullFilterInput>>;
  readonly region?: InputMaybe<StringOperationFilterInput>;
};

export type TranchePrivileged = {
  readonly __typename?: 'TranchePrivileged';
  readonly defaultInstrumentId?: Maybe<Scalars['UUID']>;
  readonly deliveryInstrumentIds: ReadonlyArray<Scalars['ID']>;
  /** Returns the list of Delivery Instruments for a Privileged Tranche */
  readonly deliveryInstruments: ReadonlyArray<DeliveryInstrumentPrivileged>;
  readonly demandCurrencies: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly region?: Maybe<Scalars['String']>;
};

/** Union of Tranche and ServiceError */
export type TrancheUnion = ServiceError | Tranche;

export type Transaction = {
  readonly __typename?: 'Transaction';
  readonly counterPartyFirmCrdNumber?: Maybe<Scalars['String']>;
  readonly counterPartyFirmName?: Maybe<Scalars['String']>;
  readonly executionDatetime: Scalars['Instant'];
  readonly executionPrice: Scalars['Decimal'];
  readonly executionPriceCurrency: TradeCurrencyType;
  readonly executionTimezone: Scalars['DateTimeZone'];
  readonly indicator: BuySellType;
  readonly shares: Scalars['Long'];
};

export type TransactionInput = {
  readonly counterPartyFirmCrdNumber?: InputMaybe<Scalars['String']>;
  readonly counterPartyFirmName?: InputMaybe<Scalars['String']>;
  readonly executionDatetime: Scalars['Instant'];
  readonly executionPrice: Scalars['Decimal'];
  readonly executionPriceCurrency: TradeCurrencyType;
  readonly executionTimezone: Scalars['DateTimeZone'];
  readonly indicator: BuySellType;
  readonly shares: Scalars['Long'];
};

export enum TransactionType {
  Acqsn = 'ACQSN',
  Atm = 'ATM',
  Bgtdl = 'BGTDL',
  Block = 'BLOCK',
  Bnkcv = 'BNKCV',
  Bstef = 'BSTEF',
  Cnvdt = 'CNVDT',
  Cnveq = 'CNVEQ',
  Excof = 'EXCOF',
  Flwon = 'FLWON',
  Ipo = 'IPO',
  Mergr = 'MERGR',
  Ncvdt = 'NCVDT',
  Ncvpr = 'NCVPR',
  Other = 'OTHER',
  Pipe = 'PIPE',
  Regd = 'REGD',
  Rgstd = 'RGSTD',
  Rgtof = 'RGTOF',
}

export type UnacknowledgeInstitutionalIndicationResult = {
  readonly __typename?: 'UnacknowledgeInstitutionalIndicationResult';
  readonly indicationId: Scalars['String'];
};

export type UnderwriterInvitationWire = {
  readonly __typename?: 'UnderwriterInvitationWire';
  readonly createdAt: Scalars['Instant'];
  readonly disclaimer?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly masterAauDate: Scalars['LocalDate'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly wireMessages: ReadonlyArray<WireMessage>;
};

/** Union of Wire and ServiceError, either a Wire or ServiceError is returned */
export type UnderwriterInvitationWireResponseUnion = ServiceError | UnderwriterInvitationWire;

export type UnderwriterInvitationWireValidation = WireValidationBase & {
  readonly __typename?: 'UnderwriterInvitationWireValidation';
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly offeringErrors: ReadonlyArray<UnderwriterInvitationWireValidationOfferingErrors>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};

export enum UnderwriterInvitationWireValidationOfferingErrors {
  ExchangeMic = 'EXCHANGE_MIC',
  IpoRangeHigh = 'IPO_RANGE_HIGH',
  IpoRangeLow = 'IPO_RANGE_LOW',
  IssuerName = 'ISSUER_NAME',
  LaunchDate = 'LAUNCH_DATE',
  OfferingType = 'OFFERING_TYPE',
  PricingDate = 'PRICING_DATE',
  SecurityType = 'SECURITY_TYPE',
  SettlementAgent = 'SETTLEMENT_AGENT',
  StockSymbol = 'STOCK_SYMBOL',
  TotalSharesBaseOffering = 'TOTAL_SHARES_BASE_OFFERING',
}

export type UnderwritingFeesAndDiscounts = {
  readonly __typename?: 'UnderwritingFeesAndDiscounts';
  readonly grossSpreadBaseData?: Maybe<GrossSpreadBase>;
  readonly grossSpreadTotal?: Maybe<Scalars['Decimal']>;
  readonly incentiveFeeData?: Maybe<IncentiveFee>;
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly netPrice?: Maybe<Scalars['Decimal']>;
  readonly reallowance?: Maybe<Scalars['Decimal']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sharesExcludedFromGrossSpread?: Maybe<Scalars['Long']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
};

export type UnderwritingFeesAndDiscountsFull = {
  readonly __typename?: 'UnderwritingFeesAndDiscountsFull';
  readonly grossSpreadBaseData?: Maybe<GrossSpreadBaseFull>;
  readonly grossSpreadTotal?: Maybe<Scalars['Decimal']>;
  readonly incentiveFeeData?: Maybe<IncentiveFeeFull>;
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly netPrice?: Maybe<Scalars['Decimal']>;
  readonly reallowance?: Maybe<Scalars['Decimal']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sharesExcludedFromGrossSpread?: Maybe<Scalars['Long']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
};

export type UnderwritingFeesAndDiscountsFullFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<UnderwritingFeesAndDiscountsFullFilterInput>>;
  readonly grossSpreadBaseData?: InputMaybe<GrossSpreadBaseFullFilterInput>;
  readonly grossSpreadTotal?: InputMaybe<DecimalOperationFilterInput>;
  readonly incentiveFeeData?: InputMaybe<IncentiveFeeFullFilterInput>;
  readonly managementFee?: InputMaybe<DecimalOperationFilterInput>;
  readonly netPrice?: InputMaybe<DecimalOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<UnderwritingFeesAndDiscountsFullFilterInput>>;
  readonly reallowance?: InputMaybe<DecimalOperationFilterInput>;
  readonly sellingConcession?: InputMaybe<DecimalOperationFilterInput>;
  readonly sharesExcludedFromGrossSpread?: InputMaybe<LongOperationFilterInput>;
  readonly underwritingFee?: InputMaybe<DecimalOperationFilterInput>;
};

export type UnderwritingTermsFeesAndDiscounts = {
  readonly __typename?: 'UnderwritingTermsFeesAndDiscounts';
  readonly grossSpreadBaseData?: Maybe<UnderwritingTermsGrossSpreadBase>;
  readonly grossSpreadTotal?: Maybe<Scalars['Decimal']>;
  readonly incentiveFeeData?: Maybe<UnderwritingTermsIncentiveFee>;
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly netPrice?: Maybe<Scalars['Decimal']>;
  readonly reallowance?: Maybe<Scalars['Decimal']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sharesExcludedFromGrossSpread?: Maybe<Scalars['Long']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
};

export type UnderwritingTermsGrossSpreadBase = {
  readonly __typename?: 'UnderwritingTermsGrossSpreadBase';
  readonly grossSpreadBase?: Maybe<Scalars['Decimal']>;
  readonly grossSpreadBasePercentage?: Maybe<Scalars['Decimal']>;
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type UnderwritingTermsIncentiveFee = {
  readonly __typename?: 'UnderwritingTermsIncentiveFee';
  readonly incentiveFee?: Maybe<Scalars['Decimal']>;
  readonly incentiveFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly managementFee?: Maybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: Maybe<Scalars['Decimal']>;
  readonly sellingConcession?: Maybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: Maybe<Scalars['Decimal']>;
  readonly underwritingFee?: Maybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: Maybe<Scalars['Decimal']>;
};

export type UpdateAccountingAdjustmentsInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly managerCmgEntityKey: Scalars['String'];
  readonly manualAdjustment?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateBasicInfoInput = {
  readonly issuer: UpdateIssuerInput;
  readonly security: UpdateSecurityInput;
  readonly type: OfferingType;
};

export type UpdatedAllocationSets = {
  readonly __typename?: 'UpdatedAllocationSets';
  readonly updatedAllocationSetIds: ReadonlyArray<Scalars['UUID']>;
};

export type UpdateDealTeamInput = {
  readonly departments: ReadonlyArray<Scalars['String']>;
  readonly members: ReadonlyArray<UpdateDealTeamMemberInput>;
};

export type UpdateDealTeamMemberInput = {
  readonly department?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly id?: InputMaybe<Scalars['UUID']>;
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: InputMaybe<Scalars['String']>;
  readonly type: DealTeamMemberType;
};

export type UpdateDeliveryInstrumentInput = {
  readonly countryCode: Scalars['String'];
  readonly currencyCode: Scalars['String'];
  readonly cusip?: InputMaybe<Scalars['String']>;
  readonly depositaryReceiptConversionRatioDenominator?: InputMaybe<Scalars['Int']>;
  readonly depositaryReceiptConversionRatioNumerator?: InputMaybe<Scalars['Int']>;
  /** Deprecated; Use `ExchangeMic` instead */
  readonly exchange?: InputMaybe<Scalars['String']>;
  readonly exchangeMic?: InputMaybe<ExchangeMic>;
  readonly figi?: InputMaybe<Scalars['String']>;
  readonly isDepositaryReceipt: Scalars['Boolean'];
  readonly isin?: InputMaybe<Scalars['String']>;
  readonly stockSymbol?: InputMaybe<Scalars['String']>;
};

export type UpdateDemandCurrencyInput = {
  readonly exchangeRate: Scalars['Decimal'];
};

export type UpdateDisclaimersInput = {
  readonly footnotes?: InputMaybe<Scalars['String']>;
  readonly registrationLinks?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly restrictions?: InputMaybe<Scalars['String']>;
};

export type UpdateFilingInput = {
  readonly initialRegistrationValue?: InputMaybe<Scalars['Decimal']>;
  readonly ipoRangeHigh?: InputMaybe<Scalars['Decimal']>;
  readonly ipoRangeLow?: InputMaybe<Scalars['Decimal']>;
  /** This field is deprecated. Please use the LockUpPeriod field in Terms instead. */
  readonly lockUpPeriod?: InputMaybe<Scalars['Int']>;
  readonly notionalOverAllotmentAuthorized?: InputMaybe<Scalars['Decimal']>;
  /** This field is deprecated. Please use the NotionalOverAllotmentExercised field in Terms instead. */
  readonly notionalOverAllotmentExercised?: InputMaybe<Scalars['Decimal']>;
  readonly offerPrice?: InputMaybe<Scalars['Decimal']>;
  readonly postOfferingShares?: InputMaybe<Scalars['Long']>;
  readonly primarySharesBaseOffering?: InputMaybe<Scalars['Long']>;
  readonly primarySharesOverAllotmentAuthorized?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the PrimarySharesOverAllotmentExercised field in Terms instead. */
  readonly primarySharesOverAllotmentExercised?: InputMaybe<Scalars['Long']>;
  readonly secondarySharesBaseOffering?: InputMaybe<Scalars['Long']>;
  readonly secondarySharesOverAllotmentAuthorized?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the SecondarySharesOverAllotmentExercised field in Terms instead. */
  readonly secondarySharesOverAllotmentExercised?: InputMaybe<Scalars['Long']>;
  /** This field is deprecated. Please use the UseOfProceeds field in Terms instead. */
  readonly useOfProceeds?: InputMaybe<ReadonlyArray<UseOfProceeds>>;
};

export type UpdateFollowOnPricingInput = {
  readonly lastTradeBeforeFiling?: InputMaybe<Scalars['Decimal']>;
  readonly lastTradeBeforeLaunch?: InputMaybe<Scalars['Decimal']>;
  readonly lastTradeBeforeOffer?: InputMaybe<Scalars['Decimal']>;
  readonly reOfferHigh?: InputMaybe<Scalars['Decimal']>;
  readonly reOfferLow?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateFreeFormWireInput = {
  readonly body: Scalars['String'];
  readonly header: Scalars['String'];
};

export type UpdateFundIoisInput = {
  readonly allocations: ReadonlyArray<FundAllocationInput>;
  readonly iois: ReadonlyArray<FundIoiInput>;
};

export type UpdateGrossSpreadBaseDtoInput = {
  readonly grossSpreadBase?: InputMaybe<Scalars['Decimal']>;
  readonly grossSpreadBasePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly managementFee?: InputMaybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcession?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFee?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateGrossSpreadInput = {
  readonly grossSpreadBase?: InputMaybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateIncentiveFeeDtoInput = {
  readonly incentiveFee?: InputMaybe<Scalars['Decimal']>;
  readonly incentiveFeePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly managementFee?: InputMaybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcession?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFee?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateIncentiveFeeInput = {
  readonly incentiveFee?: InputMaybe<Scalars['Decimal']>;
  readonly managementFeePercentage?: InputMaybe<Scalars['Decimal']>;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
  readonly underwritingFeePercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateInvestorCrmKeyResult = {
  readonly __typename?: 'UpdateInvestorCrmKeyResult';
  readonly indicationId: Scalars['String'];
};

export type UpdateIssuerInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly businessDescription?: InputMaybe<Scalars['String']>;
  readonly cik?: InputMaybe<Scalars['String']>;
  readonly lei?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly sector?: InputMaybe<Sector>;
  readonly website?: InputMaybe<Scalars['String']>;
};

export type UpdateLogisticsInput = {
  readonly endDate: Scalars['LocalDate'];
  readonly endTime: Scalars['LocalTime'];
  readonly location: Scalars['String'];
  readonly notes?: InputMaybe<Scalars['String']>;
  readonly startDate: Scalars['LocalDate'];
  readonly startTime: Scalars['LocalTime'];
  readonly title: Scalars['String'];
};

export type UpdateManagerInput = {
  /** Deprecated; Use `Underwriting Base Share Distribution` in Manager Economics instead */
  readonly economics?: InputMaybe<Scalars['Long']>;
  readonly isJuniorRole: Scalars['Boolean'];
  readonly isParticipating: Scalars['Boolean'];
  readonly role: ManagerRole;
};

export type UpdateManagerSequenceInput = {
  readonly cmgEntityKeys: ReadonlyArray<Scalars['String']>;
};

export type UpdateMdlOfferingNoteInput = {
  readonly id: Scalars['ID'];
  readonly note: Scalars['String'];
  /** The subject type for the note. Possible Values: ALLOCATION, COMPLIANCE, GENERAL, INDICATION, MESSAGING. */
  readonly subject: OfferingNoteSubject;
};

export type UpdateMyRetailBroker = {
  readonly demand: MyRetailBrokerDemandInput;
  readonly firmCmgEntityKey?: InputMaybe<Scalars['String']>;
  readonly firmName: Scalars['String'];
  readonly id?: InputMaybe<Scalars['UUID']>;
  readonly retention: MyRetailRetentionInput;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateMyRetailBrokerInput = {
  readonly demand: MyRetailBrokerDemandInput;
  readonly retention: MyRetailRetentionInput;
  readonly sellingConcessionPercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateMyRetailInput = {
  readonly brokerDealers: ReadonlyArray<UpdateMyRetailBroker>;
  readonly instrumentId?: InputMaybe<Scalars['UUID']>;
  readonly trancheId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateNonSyndicateFreeFormWireInput = {
  readonly body: Scalars['String'];
  readonly header: Scalars['String'];
};

export type UpdateNonSyndicateSellingGroupInvitationWireInput = {
  readonly userDataForRecipients: ReadonlyArray<NonSyndicateSellingGroupInvitationDataForRecipient>;
};

export type UpdateNonSyndicateSellingGroupTermsRetentionWireInput = {
  readonly userDataForRecipients: ReadonlyArray<CreateNonSyndicateSellingGroupTermsRetentionWireInputData>;
};

export type UpdateNonSyndicateTerminationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly terminationDate: Scalars['Instant'];
  readonly terminationTimeZone: Scalars['DateTimeZone'];
};

export type UpdateOrderBookSalesStateInput = {
  readonly offeringId: Scalars['UUID'];
  readonly state: OrderBookState;
};

export type UpdateOrderBookSalesStatePayload = {
  readonly __typename?: 'UpdateOrderBookSalesStatePayload';
  readonly orderBookState?: Maybe<OrderBookState>;
};

export type UpdatePotAccountWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly headerNote?: InputMaybe<Scalars['String']>;
};

export type UpdatePotAllocationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly headerNote?: InputMaybe<Scalars['String']>;
};

export type UpdatePricingTermsRetentionWireInput = {
  readonly userDataForRecipients: ReadonlyArray<PricingTermsRetentionUserDataForRecipientInputData>;
};

export type UpdatePricingTermsWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
};

export type UpdateRegMWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly notificationPeriod: RegulationMNotificationPeriod;
  readonly offeringResult: Scalars['String'];
};

export type UpdateRoadshowDealTeamMemberInput = {
  readonly department?: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly mobilePhone?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly officePhone?: InputMaybe<Scalars['String']>;
  readonly type: DealTeamMemberType;
};

export type UpdateSalesCreditsAmountInput = {
  readonly investorFirmCmgEntityKey: Scalars['String'];
  readonly salesCreditAmount?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateSalesCreditsBankInvestorKeyInput = {
  readonly firmId: Scalars['UUID'];
};

export type UpdateSalesCreditsInput = {
  readonly salesCreditIds: ReadonlyArray<Scalars['UUID']>;
  readonly status: InternalSalesCreditReleaseStatus;
};

export type UpdateSecurityInput = {
  readonly isDepositaryReceipt?: InputMaybe<Scalars['Boolean']>;
  readonly shareClass?: InputMaybe<SecurityShareClass>;
  readonly type: SecurityType;
};

export type UpdateSellingGroupInvitationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly masterSdaDate: Scalars['LocalDate'];
};

export type UpdateSellingGroupTermsWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
};

export type UpdateSyndicateInput = {
  /** Updating this will also update Gross Spread Base in Underwriting Fees and Discounts */
  readonly grossSpread?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateTerminationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly terminationDate: Scalars['Instant'];
  readonly terminationTimeZone: Scalars['DateTimeZone'];
};

export type UpdateTimingInfoInput = {
  readonly booksCloseAt?: InputMaybe<Scalars['DateTime']>;
  readonly confidentialFilingDate?: InputMaybe<Scalars['LocalDate']>;
  readonly filingOccurred?: InputMaybe<MarketTiming>;
  readonly firstTradeDate?: InputMaybe<Scalars['LocalDate']>;
  readonly launchDate?: InputMaybe<Scalars['LocalDate']>;
  readonly launchOccurred?: InputMaybe<MarketTiming>;
  readonly postponedDate?: InputMaybe<Scalars['LocalDate']>;
  readonly pricingDate?: InputMaybe<Scalars['LocalDate']>;
  readonly publicFilingDate?: InputMaybe<Scalars['LocalDate']>;
  readonly settlementDate?: InputMaybe<Scalars['LocalDate']>;
  readonly terminatedDate?: InputMaybe<Scalars['LocalDate']>;
  readonly timeZone?: InputMaybe<Scalars['DateTimeZone']>;
  readonly tradeDate?: InputMaybe<Scalars['LocalDate']>;
  readonly withdrawnDate?: InputMaybe<Scalars['LocalDate']>;
};

export type UpdateTotalRetailInput = {
  readonly demandShareQuantity: Scalars['Long'];
  readonly instrumentId: Scalars['UUID'];
  readonly managerCmgEntityKey: Scalars['String'];
  readonly retentionShareQuantity: Scalars['Long'];
  readonly roundLots?: InputMaybe<Scalars['Int']>;
  readonly trancheId: Scalars['UUID'];
};

export type UpdateTradesStatusBatchInput = {
  readonly status: InternalReleaseStatus;
  readonly tradeIds: ReadonlyArray<Scalars['UUID']>;
};

export type UpdateTrancheInput = {
  readonly name: Scalars['String'];
  readonly region?: InputMaybe<Scalars['String']>;
};

export type UpdateUnderwriterInvitationWireInput = {
  readonly disclaimer?: InputMaybe<Scalars['String']>;
  readonly masterAauDate: Scalars['LocalDate'];
};

export type UpdateUnderwritingFeesAndDiscountsInput = {
  readonly baseGrossSpread?: InputMaybe<UpdateGrossSpreadInput>;
  readonly incentiveFee?: InputMaybe<UpdateIncentiveFeeInput>;
  readonly reallowance?: InputMaybe<Scalars['Decimal']>;
  readonly sharesExcludedFromGrossSpread?: InputMaybe<Scalars['Long']>;
};

export type UpdateUnderwritingTermsFeesAndDiscountsInput = {
  readonly baseGrossSpread?: InputMaybe<UpdateGrossSpreadBaseDtoInput>;
  readonly incentiveFee?: InputMaybe<UpdateIncentiveFeeDtoInput>;
  readonly reallowance?: InputMaybe<Scalars['Decimal']>;
  readonly sharesExcludedFromGrossSpread?: InputMaybe<Scalars['Long']>;
};

export type UpdateUnderwritingTermsFeesAndDiscountsPayload = {
  readonly __typename?: 'UpdateUnderwritingTermsFeesAndDiscountsPayload';
  readonly underwritingTermsFeesAndDiscounts?: Maybe<UnderwritingTermsFeesAndDiscounts>;
};

export type UpsertInternalDemandInput = {
  readonly indications: ReadonlyArray<MdlIndicationInput>;
};

export type UsdtnFiling = {
  readonly __typename?: 'USDTNFiling';
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly filingCreatorEmail?: Maybe<Scalars['String']>;
  readonly finraErrorMessages: ReadonlyArray<Scalars['String']>;
  readonly firmCrdNumber: Scalars['String'];
  readonly firmDealId: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly formUniqueId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly issuerName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly status: FilingStatus;
  readonly submittedDateTime: Scalars['Instant'];
  readonly telephoneNumber: Scalars['String'];
  readonly transactions: ReadonlyArray<Transaction>;
};

export type UsdtnFilingFormDataInput = {
  readonly contactName: Scalars['String'];
  readonly contactTitle: Scalars['String'];
  readonly emailAddress: Scalars['String'];
  readonly firmCrdNumber: Scalars['String'];
  readonly firmMpid: Scalars['String'];
  readonly firmName: Scalars['String'];
  readonly issuerSymbol: Scalars['String'];
  readonly telephoneNumber: Scalars['String'];
  readonly transactions: ReadonlyArray<TransactionInput>;
};

export enum UseOfProceeds {
  Acquisitions = 'ACQUISITIONS',
  CapitalExpenditures = 'CAPITAL_EXPENDITURES',
  DebtRepayment = 'DEBT_REPAYMENT',
  FutureAcquisitions = 'FUTURE_ACQUISITIONS',
  GeneralCorporatePurposes = 'GENERAL_CORPORATE_PURPOSES',
  GrowthCapital = 'GROWTH_CAPITAL',
  Investments = 'INVESTMENTS',
  NoProceedsToIssuer = 'NO_PROCEEDS_TO_ISSUER',
  ResearchAndDevelopment = 'RESEARCH_AND_DEVELOPMENT',
  ShareRepurchase = 'SHARE_REPURCHASE',
  ShareholderDividends = 'SHAREHOLDER_DIVIDENDS',
  WorkingCapital = 'WORKING_CAPITAL',
}

export type UseOfProceedsFilterInput = {
  readonly eq?: InputMaybe<UseOfProceeds>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<UseOfProceeds>>>;
  readonly neq?: InputMaybe<UseOfProceeds>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<UseOfProceeds>>>;
};

export type UseOfProceedsListFilterInput = {
  readonly all?: InputMaybe<UseOfProceedsFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<UseOfProceedsFilterInput>;
  readonly some?: InputMaybe<UseOfProceedsFilterInput>;
};

export type UserInfo = {
  readonly __typename?: 'UserInfo';
  readonly email?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type UserInfoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserInfoFilterInput>>;
  readonly email?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserInfoFilterInput>>;
};

export type UserInfoSortInput = {
  readonly email?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
};

export type UuidOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['UUID']>;
  readonly gt?: InputMaybe<Scalars['UUID']>;
  readonly gte?: InputMaybe<Scalars['UUID']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']>;
  readonly lte?: InputMaybe<Scalars['UUID']>;
  readonly neq?: InputMaybe<Scalars['UUID']>;
  readonly ngt?: InputMaybe<Scalars['UUID']>;
  readonly ngte?: InputMaybe<Scalars['UUID']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']>;
  readonly nlte?: InputMaybe<Scalars['UUID']>;
};

export type Wire = {
  readonly __typename?: 'Wire';
  readonly createdAt: Scalars['Instant'];
  readonly id: Scalars['UUID'];
  readonly sender: Scalars['String'];
  readonly status: WireStatus;
  readonly templateType: WireTemplateType;
  readonly wireMessages: ReadonlyArray<WireMessageLimited>;
};

export type WireMessage = {
  readonly __typename?: 'WireMessage';
  readonly emailAddresses: ReadonlyArray<Scalars['String']>;
  readonly expiredAt?: Maybe<Scalars['Instant']>;
  readonly firmSnapshot?: Maybe<FirmSnapshot>;
  readonly htmlBody: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly respondedAt?: Maybe<Scalars['Instant']>;
  readonly responseStatus?: Maybe<SentWireResponseStatus>;
  readonly sentAt?: Maybe<Scalars['Instant']>;
  readonly wireMessageAndResponseStatus: WireMessageAndResponseStatus;
};

export enum WireMessageAndResponseStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Obsolete = 'OBSOLETE',
  ReadyToSend = 'READY_TO_SEND',
  Sent = 'SENT',
}

export type WireMessageLimited = {
  readonly __typename?: 'WireMessageLimited';
  readonly expiredAt?: Maybe<Scalars['Instant']>;
  readonly firmSnapshot?: Maybe<FirmSnapshot>;
  readonly htmlBody: Scalars['String'];
  readonly id: Scalars['UUID'];
  readonly respondedAt?: Maybe<Scalars['Instant']>;
  readonly responseStatus?: Maybe<SentWireResponseStatus>;
  readonly sentAt?: Maybe<Scalars['Instant']>;
  readonly wireMessageAndResponseStatus: WireMessageAndResponseStatus;
};

export type WireMessageLimitedReadDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<WireMessageLimitedReadDtoFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WireMessageLimitedReadDtoFilterInput>>;
  readonly respondedAt?: InputMaybe<InstantOperationFilterInput>;
  readonly responseStatus?: InputMaybe<SentWireResponseStatusFilterInput>;
  readonly sentAt?: InputMaybe<InstantOperationFilterInput>;
};

export type WireOfferingDetail = {
  readonly __typename?: 'WireOfferingDetail';
  readonly id: Scalars['ID'];
  readonly issuerName: Scalars['String'];
  readonly lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  readonly managers: ReadonlyArray<FinraOfferingManager>;
  readonly offerPrice?: Maybe<Scalars['Decimal']>;
  readonly pricingDate?: Maybe<Scalars['LocalDate']>;
  readonly pricingInstrumentSymbol?: Maybe<Scalars['String']>;
  readonly stockSymbols: ReadonlyArray<Scalars['String']>;
  readonly totalSharesBaseOffering?: Maybe<Scalars['Long']>;
  readonly tradeDate?: Maybe<Scalars['LocalDate']>;
  readonly type: OfferingType;
  /** Display name for Type */
  readonly typeDisplayName?: Maybe<Scalars['String']>;
};

export type WirePreview = {
  readonly __typename?: 'WirePreview';
  readonly htmlContent: Scalars['String'];
};

export type WireReadDtoFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<WireReadDtoFilterInput>>;
  readonly createdAt?: InputMaybe<InstantOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WireReadDtoFilterInput>>;
  readonly sender?: InputMaybe<StringOperationFilterInput>;
  readonly status?: InputMaybe<WireStatusFilterInput>;
  readonly templateType?: InputMaybe<WireTemplateTypeFilterInput>;
  readonly wireMessages?: InputMaybe<ListFilterInputTypeOfWireMessageLimitedReadDtoFilterInput>;
};

export type WireReadDtoSortInput = {
  readonly createdAt?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly sender?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly templateType?: InputMaybe<SortEnumType>;
};

export type WireResponse = SentWireStatus | ServiceError;

export enum WireStatus {
  Draft = 'DRAFT',
  Outdated = 'OUTDATED',
  Sent = 'SENT',
}

export type WireStatusFilterInput = {
  readonly eq?: InputMaybe<WireStatus>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<WireStatus>>>;
  readonly neq?: InputMaybe<WireStatus>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<WireStatus>>>;
};

export enum WireTemplateType {
  DesignationWithIntegration = 'DESIGNATION_WITH_INTEGRATION',
  FreeForm = 'FREE_FORM',
  GreenShoe = 'GREEN_SHOE',
  IssuerAllocation = 'ISSUER_ALLOCATION',
  IssuerIndication = 'ISSUER_INDICATION',
  ManagerBillingAndDelivery = 'MANAGER_BILLING_AND_DELIVERY',
  NonSyndicateMemberFreeForm = 'NON_SYNDICATE_MEMBER_FREE_FORM',
  NonSyndicateMemberPayment = 'NON_SYNDICATE_MEMBER_PAYMENT',
  NonSyndicateMemberSellingGroupInvitationFo = 'NON_SYNDICATE_MEMBER_SELLING_GROUP_INVITATION_FO',
  NonSyndicateMemberSellingGroupInvitationIpo = 'NON_SYNDICATE_MEMBER_SELLING_GROUP_INVITATION_IPO',
  NonSyndicateMemberSellingGroupTermsRetention = 'NON_SYNDICATE_MEMBER_SELLING_GROUP_TERMS_RETENTION',
  NonSyndicateMemberTermination = 'NON_SYNDICATE_MEMBER_TERMINATION',
  Payment = 'PAYMENT',
  PotAccount = 'POT_ACCOUNT',
  PotAllocation = 'POT_ALLOCATION',
  PricingTerms = 'PRICING_TERMS',
  PricingTermsRetention = 'PRICING_TERMS_RETENTION',
  RegulationM = 'REGULATION_M',
  Retention = 'RETENTION',
  SellingGroupInvitationFo = 'SELLING_GROUP_INVITATION_FO',
  SellingGroupInvitationIpo = 'SELLING_GROUP_INVITATION_IPO',
  SellingGroupTerms = 'SELLING_GROUP_TERMS',
  Termination = 'TERMINATION',
  UnderwriterInvitationFo = 'UNDERWRITER_INVITATION_FO',
  UnderwriterInvitationIpo = 'UNDERWRITER_INVITATION_IPO',
}

export type WireTemplateTypeFilterInput = {
  readonly eq?: InputMaybe<WireTemplateType>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<WireTemplateType>>>;
  readonly neq?: InputMaybe<WireTemplateType>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<WireTemplateType>>>;
};

export type WireValidationBase = {
  readonly managers: ReadonlyArray<ManagerValidation>;
  readonly orderBookErrors: ReadonlyArray<OrderBookErrorType>;
  readonly sender: ManagerValidation;
  readonly settlementErrors: ReadonlyArray<SettlementErrorType>;
};
