import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

import { AllocationAcknowledgement } from '../../../../../../../../graphql';

export const StyledStatusIndicatorIcon = styled(Icon)<{
  status?: AllocationAcknowledgement;
}>`
  margin-right: 5px;
  color: ${({ status, theme }) => {
    if (status === AllocationAcknowledgement.Acknowledged) {
      return theme.brand.color.success;
    }

    if (status === AllocationAcknowledgement.Rejected) {
      return theme.brand.color.danger;
    }

    return theme.brand.color.dark;
  }};
`;

export const SWrapper = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const SLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
