import { useAuth } from '@cmg/auth';
import { LinkButton } from '@cmg/common';
import { Alert, AlertTitle } from '@cmg/design-system';
import React from 'react';
import { useDispatch } from 'react-redux';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import { getFeatureToggles } from '../../../../common/config';
import { isValidCmgEntityKey } from '../../../../common/util/isValidCmgEntityKey';
import { IndicationStatus, OfferingType, OrderBookState } from '../../../../graphql';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import AllocationDiscrepancyModal from './components/allocation-discrepancy-modal/AllocationDiscrepancyModal';
import { openCrmSelectionModal } from './components/crm-selection/CrmSelectModal';
import ManageCoveredIndication from './components/manage-indication/ManageCoveredIndication';
import OrderBookClosedBanner from './components/order-book-closed-banner/OrderBookClosedBanner';
import { useIndicationActivitySalesAndTradingContext } from './context/IndicationActivitySalesAndTradingContext';
import { SBankInvestorKey } from './IndicationActivitySellSide.styles';
import { useIndicationCoverage } from './IndicationActivitySellSideHooks';

// eslint-disable-next-line sonarjs/cognitive-complexity
const IndicationActivitySalesAndTrading: React.FC = () => {
  const {
    basicPublishedOffering,
    coveredIndication,
    error,
    offeringId,
    allInvestorFirmsMappedToCmgEntityKey,
    access,
    orderBookSalesState,
  } = useIndicationActivitySalesAndTradingContext();

  const dispatch = useDispatch();
  const { cmgEntityKey } = useCmgEntityKeyContext();

  const { isOrderBookDupesOn } = getFeatureToggles();
  const { prospectusContactMandatory } = useAuth();

  const { isCurrentInvestorCovered } = useIndicationCoverage(offeringId, cmgEntityKey, []);

  if (!access.canReadOfferingAndIndication) {
    return <NotAccessible subject="Indication" />;
  }

  const isProspectusDocumentMissing = basicPublishedOffering?.prospectusDocuments.length === 0;

  const isOrderBookOpen = orderBookSalesState === OrderBookState.Open;

  // Dupes check Feature Flag
  const canEditIfDuplicate =
    isOrderBookDupesOn && coveredIndication?.status !== IndicationStatus.Duplicate;

  // We need to create a way to handle editable in the parent component that will disable
  // all checks for inner components, editable checks should be moved here
  const isIndicationEditable =
    canEditIfDuplicate && (access.canReadOfferingAndIndication || isCurrentInvestorCovered);

  const { bankInvestorKey, cmgEntityName } = coveredIndication?.investorInformation ?? {};
  const { id } = coveredIndication ?? {};

  const displayEditBankInvestorKeyButton =
    bankInvestorKey || allInvestorFirmsMappedToCmgEntityKey?.some(firm => firm.firmKey !== null);

  const displayUnableToValidateComplianceBanner =
    (basicPublishedOffering?.type === OfferingType.Ipo && !isValidCmgEntityKey(cmgEntityKey)) ||
    (basicPublishedOffering?.type !== OfferingType.Ipo && prospectusContactMandatory);

  if (
    cmgEntityKey &&
    !isValidCmgEntityKey(cmgEntityKey) &&
    displayUnableToValidateComplianceBanner
  ) {
    return (
      <Alert severity="error">
        <AlertTitle> Unable to validate compliance </AlertTitle>
        This investor has not been reconciled to a CMG entity, so we cannot validate compliance for
        the investor to participate in this offering. Please contact CMG support to complete the
        reconcillation or work with your syndicate desk to submit the indication for the time being.
      </Alert>
    );
  }

  return (
    <FlexLayout>
      <AllocationDiscrepancyModal />
      {error && <ServerErrorsBanner error={error} />}
      {basicPublishedOffering && !isOrderBookOpen && <OrderBookClosedBanner />}

      {basicPublishedOffering && coveredIndication && (
        <div>
          <SBankInvestorKey>Bank Investor Key: {bankInvestorKey ?? '-'}</SBankInvestorKey>
          {displayEditBankInvestorKeyButton && (
            <LinkButton
              testId="open-crm-modal-button"
              onClick={() =>
                dispatch(
                  openCrmSelectionModal({
                    cmgEntityKey,
                    offeringId,
                    indicationId: id,
                    cmgEntityName,
                    firmKey: bankInvestorKey,
                  })
                )
              }
            >
              Edit
            </LinkButton>
          )}
        </div>
      )}

      {basicPublishedOffering && (
        <ManageCoveredIndication
          editable={isIndicationEditable && access.canUpdateCoveredIndication && isOrderBookOpen}
          isInvestorCoveredAndEditable={isIndicationEditable}
          isProspectusDocumentMissing={isProspectusDocumentMissing}
          isProspectusContactMandatory={prospectusContactMandatory ?? false}
          isOrderBookOpen={isOrderBookOpen}
        />
      )}
    </FlexLayout>
  );
};

export default IndicationActivitySalesAndTrading;
