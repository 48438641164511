import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { OfferingType, SyndicateWiresManagerRole, WireTemplateType } from '../../../../../graphql';
import { createUseDefaultSendIsDisabledReason } from '../../common/context/hooks/useDefaultSendIsDisabledReason';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { getMasterDate } from '../../common/utils/masterDate';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_SellingGroupInvitationWirePartsFragment,
  SyndicateWires_SellingGroupInvitationWireValidationPartsFragment,
  useSyndicateWires_MasterSdaDateQuery,
  useSyndicateWires_SellingGroupInvitationWireDetailsQuery,
  useSyndicateWires_SellingGroupInvitationWirePreviewQuery,
  useSyndicateWires_SellingGroupInvitationWiresQuery,
  useSyndicateWires_SellingGroupInvitationWireValidationQuery,
} from './graphql';
import { useCreateSellingGroupInvitationWireMutation } from './hooks/useCreateSellingGroupInvitationWireMutation';
import { useDeleteSellingGroupInvitationWireMutation } from './hooks/useDeleteSellingGroupInvitationWireMutation';
import { useSendSellingGroupInvitationWireMutation } from './hooks/useSendSellingGroupInvitationWireMutation';
import { useUpdateSellingGroupInvitationWireMutation } from './hooks/useUpdateSellingGroupInvitationWireMutation';
import { SellingGroupInvitationWireForm } from './SellingGroupInvitationWireForm';
import { validationSchema, Values } from './SellingGroupInvitationWireForm.model';

const wireTypeName = 'Selling Group Invitation';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role === SyndicateWiresManagerRole.SellingGroupMember;
};

export const getWireTemplateType = ({ offeringType }: { offeringType: OfferingType }) =>
  [OfferingType.Ipo, OfferingType.IpoSpac].includes(offeringType)
    ? WireTemplateType.SellingGroupInvitationIpo
    : WireTemplateType.SellingGroupInvitationFo;

export const createWireTypeConfigSellingGroupInvitation = ({
  offeringType,
}: {
  offeringType: OfferingType;
}) =>
  createWireTypeConfig<
    Values,
    SyndicateWires_SellingGroupInvitationWirePartsFragment,
    SyndicateWires_SellingGroupInvitationWireValidationPartsFragment,
    typeof routeFactory.syndicateWiresSellingGroupInvitation
  >({
    wireTypeName,
    wireTypeRoute: routeFactory.syndicateWiresSellingGroupInvitation,
    wireTemplateType: getWireTemplateType({ offeringType }),
    useSendIsDisabledReason: createUseDefaultSendIsDisabledReason({
      canBeSentForDraftOffering: true,
    }),
    useManagers: createUseDefaultManagers(managerFilter),
    useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
    useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
    useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

    useGenerateWirePreview: queryArgs => {
      const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWirePreviewQuery({
        variables: queryArgs,
        fetchPolicy: 'cache-and-network',
      });

      return {
        data: data?.sellingGroupInvitationWirePreview.htmlContent,
        error,
        isLoading: loading,
      };
    },
    useWireDetails: (queryArgs, fetchPolicy) => {
      const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWireDetailsQuery({
        variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
        skip: !queryArgs.syndicateWireId,
        fetchPolicy: fetchPolicy || 'cache-and-network',
      });

      return {
        data: data && {
          wire: data.sellingGroupInvitationWireDetails,
          stage: data.offering.stage,
        },
        isLoading: loading,
        error,
      };
    },
    useWireList: ({ offeringId }) => {
      const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWiresQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });

      return {
        data: data?.sellingGroupInvitationWireList,
        isLoading: loading,
        error,
      };
    },
    useWireValidation: ({ offeringId, fetchPolicy }) => {
      const { data, loading, error, refetch } =
        useSyndicateWires_SellingGroupInvitationWireValidationQuery({
          variables: { offeringId },
          fetchPolicy: fetchPolicy || 'cache-and-network',
        });

      return {
        data: data?.sellingGroupInvitationWireValidation,
        isLoading: loading,
        error,
        refetch,
      };
    },

    useCreateMutation: () => {
      const [createWire, { loading }] = useCreateSellingGroupInvitationWireMutation();

      return {
        mutation: async ({ offeringId, values }) => {
          const result = await createWire({ variables: { offeringId, payload: values } });

          if (result.data?.createSellingGroupInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Creating the ${wireTypeName} wire failed.`);
          }

          return result.data?.createSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },
    useUpdateMutation: () => {
      const [updateWire, { loading }] = useUpdateSellingGroupInvitationWireMutation();

      return {
        mutation: async ({ offeringId, values, syndicateWireId }) => {
          const result = await updateWire({
            variables: { syndicateWireId, offeringId, payload: values },
          });

          if (result.data?.updateSellingGroupInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Updating the ${wireTypeName} wire failed.`);
          }

          return result.data?.updateSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },
    useDeleteMutation: () => {
      const [deleteWire, { loading }] = useDeleteSellingGroupInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await deleteWire({ variables });

          if (result.data?.deleteSellingGroupInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Deleting the ${wireTypeName} wire failed.`);
          }
        },
        isLoading: loading,
      };
    },
    useSendMutation: () => {
      const [sendWire, { loading }] = useSendSellingGroupInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await sendWire({
            variables: {
              ...variables,
              ...generatePayloadDateTimeData(),
            },
          });

          if (result.data?.sendSellingGroupInvitationWire.__typename === 'ServiceError') {
            throw new Error(`Sending the ${wireTypeName} wire failed.`);
          }

          return result.data?.sendSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },

    CreateOrUpdateForm: SellingGroupInvitationWireForm,
    // @ts-expect-error date field type mismatch
    formValidation: validationSchema,
    useGetInitialValues: ({ wire, offeringType, wireTemplateType, offeringId, operationType }) => {
      const {
        data: masterSdaData,
        loading: masterSdaLoading,
        error: masterSdaError,
      } = useSyndicateWires_MasterSdaDateQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });
      const {
        data: disclaimerData,
        loading: disclaimerLoading,
        error: disclaimerError,
      } = useSyndicateWires_WiresDisclaimerQuery({
        variables: {
          offeringId,
          offeringType,
          templateType: wireTemplateType,
        },
      });
      const initialValues = React.useMemo<Values>(() => {
        const masterSdaDate = masterSdaData?.syndicateWires.masterSdaDate;
        return {
          masterSdaDate: getMasterDate(operationType, wire?.masterSdaDate, masterSdaDate),
          disclaimer: getDisclaimer(
            operationType,
            wire?.disclaimer,
            disclaimerData?.syndicateWires.wiresDisclaimer
          ),
        };
      }, [
        masterSdaData?.syndicateWires.masterSdaDate,
        wire?.masterSdaDate,
        wire?.disclaimer,
        operationType,
        disclaimerData?.syndicateWires.wiresDisclaimer,
      ]);

      const error = masterSdaError || disclaimerError;

      const isLoading = masterSdaLoading || disclaimerLoading;
      return { isLoading, error, data: initialValues };
    },
  });
