import { useEffect, useMemo } from 'react';

import { OfferingStage } from '../../../../graphql';
import { MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT } from '../../graphql/constants';
import { useMyOfferings_PrivateOferingsQuery } from '../graphql';
import { toDrafts } from '../utils/transformData';

function useFetchRemainingPaginatedData(query) {
  useEffect(
    function fetchRemainingPaginatedData() {
      if (!query.data?.privateOfferings) {
        return;
      }

      const { pageInfo, items } = query.data.privateOfferings;

      if (items && pageInfo.hasNextPage) {
        query.fetchMore({
          variables: { skip: items.length },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult.privateOfferings?.items) {
              return prev;
            }

            return {
              ...prev,
              privateOfferings: {
                ...prev.privateOfferings,
                ...fetchMoreResult.privateOfferings,
                items: [
                  ...(prev.privateOfferings?.items ?? []),
                  ...fetchMoreResult.privateOfferings.items,
                ],
              },
            };
          },
        });
      }
    },
    [query]
  );
}

export function usePrivateOfferingsQuery(shouldSkip: boolean) {
  const query = useMyOfferings_PrivateOferingsQuery({
    variables: {
      skip: 0,
      take: MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT,
      where: { stage: { eq: OfferingStage.Draft } },
    },
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
  });

  useFetchRemainingPaginatedData(query);

  const { data, loading, error } = query;

  const loadingPages = loading || !!data?.privateOfferings?.pageInfo.hasNextPage;

  const privateOfferingsData = useMemo(() => {
    if (loadingPages) {
      return null;
    }
    return toDrafts(data?.privateOfferings?.items ?? null);
  }, [data?.privateOfferings?.items, loadingPages]);

  const offeringTypes = useMemo(
    () =>
      (data?.privateOfferings?.items ?? []).map(
        ({ id, type, instruments, pricingInstrumentId }) => {
          const pricingInstrumentCountryCode =
            instruments.find(instrument => instrument.id === pricingInstrumentId)?.countryCode ??
            null;

          return {
            id,
            type,
            pricingInstrumentCountryCode,
          };
        }
      ),
    [data?.privateOfferings?.items]
  );

  const issuerSectors = useMemo(
    () =>
      (privateOfferingsData ?? []).map(({ id, issuerSector }) => ({
        id,
        issuerSector,
      })),
    [privateOfferingsData]
  );

  return {
    privateOfferings: {
      data: loadingPages ? undefined : privateOfferingsData,
      offeringTypes,
      offeringStatuses: [],
      issuerSectors,
    },
    isLoadingPrivateOfferings: loadingPages,
    privateOfferingsError: error,
  };
}
