import { numericUtil } from '@cmg/common';

import { FinalSettlement_StabilizationInformationPartsFragment } from '../../graphql';
import { StabilizationTrade } from '../StabilizationForm.model';

export const getBreakevenPrice = ({
  offeringPrice,
  sellingConcession,
}: FinalSettlement_StabilizationInformationPartsFragment) => {
  return offeringPrice - (sellingConcession ?? 0);
};

export const getTotalShares = (trades: StabilizationTrade[]) => {
  return numericUtil.sum(...trades.map(t => t.numberOfShares));
};

export const getCumulativeAveragePrice = (trades: StabilizationTrade[]) => {
  const tradesWithSharesAndPrice = trades.filter(
    trade => trade.numberOfShares !== null && trade.averagePrice !== null
  );

  const totalPrice = numericUtil.sum(
    ...tradesWithSharesAndPrice.map(t => t.numberOfShares! * t.averagePrice!)
  );

  const totalShares = numericUtil.sum(...tradesWithSharesAndPrice.map(t => t.numberOfShares));

  if (totalPrice === null || totalShares === null) {
    return null;
  }

  return totalShares === 0 ? 0 : totalPrice / totalShares;
};

export const getTotalGainLoss = (
  stabilizationTrades: number | null,
  overallotmentRefresh: number | null
) => {
  return numericUtil.sum(stabilizationTrades, overallotmentRefresh);
};
