import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { Box, Button, Table, TableBody, TableContainer } from '@cmg/design-system';
import React from 'react';

import ExportButton from '../../../../components/export-button/ExportButton';
import { useExportContext } from '../../../../components/export-button/ExportContext';
import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import InternalRetailDemand from '../../common/internal-retail-demand/InternalRetailDemand';
import InternalRetailDemandTableHeader from '../../common/table-header/InternalRetailDemandTableHeader';
import InternalRetailDemandTableTotalRow from '../../common/table-total-row/InternalRetailDemandTableTotalRow';
import InternalRetailDemandReadOnlyTableRow from './read-only-row/InternalRetailDemandReadOnlyTableRow';
import {
  calculateReadOnlyTotals,
  createBrokerRows,
} from './ReadOnlyInternalRetailDemandView.model';

export type Props = Readonly<{
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[];
  sellingConcession: number | null | undefined;
  onEdit: () => void;
}>;

const ReadOnlyInternalRetailDemandView: React.FC<Props> = ({
  brokers,
  sellingConcession,
  onEdit,
}) => {
  const rows = React.useMemo(
    () => createBrokerRows(brokers, sellingConcession),
    [brokers, sellingConcession]
  );
  const totals = React.useMemo(() => calculateReadOnlyTotals(rows), [rows]);

  const { isExporting, onExport } = useExportContext();

  return (
    <InternalRetailDemand
      pageHeaderActions={[
        <ExportButton key="export-button" loading={isExporting} onExport={onExport} />,
        <AccessControl
          key="edit-button"
          requiredPermissions={[
            permissionsByEntity.RetailDemand.FULL,
            permissionsByEntity.RetailRetention.FULL,
          ]}
          requireAllPermissions={false}
        >
          <Button variant="contained" onClick={onEdit}>
            Edit
          </Button>
        </AccessControl>,
      ]}
    >
      <Box overflow="auto" data-testid="read-only-view">
        <TableContainer>
          <Table>
            <InternalRetailDemandTableHeader isReadOnly />

            <TableBody>
              <InternalRetailDemandTableTotalRow {...totals} isReadOnly />

              {rows.map(rowData => (
                <InternalRetailDemandReadOnlyTableRow key={rowData.id} rowData={rowData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </InternalRetailDemand>
  );
};

export default ReadOnlyInternalRetailDemandView;
