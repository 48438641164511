import { DefaultTheme } from 'styled-components/macro';

import { FilingStatus } from '../../../../graphql';
import { Filing } from './RegulatoryFilingsSideNav';

export const getLastFilingStatus = (filings: Filing[]) => {
  const [lastFiling] = [...filings].sort((A, B) => A.version - B.version).splice(-1);

  return lastFiling?.status;
};

export const getIconColor = (theme: DefaultTheme, status: FilingStatus | undefined): string => {
  if (!status) {
    return theme.text.color.placeholder;
  }

  switch (status) {
    case FilingStatus.Processing:
      return theme.background.color.processing;
    case FilingStatus.Submitted:
      return theme.brand.color.successAlternate;
    case FilingStatus.Error:
    case FilingStatus.SubmissionError:
    case FilingStatus.MissingResponse:
      return theme.brand.color.danger;
  }
};
