import { FlexContainer, ODSTable as Table } from '@cmg/common';
import { FieldArray } from 'formik';
import React from 'react';

import { StabilizationTrade } from '../../../StabilizationForm.model';
import { StyledAddTradeLinkButton } from '../../StabilizationFormContent.styles';
import OverallotmentRefreshTableHeader from '../common/overallotment-refresh-table-header/OverallotmentRefreshTableHeader';
import EditableOverallotmentRefreshRow from './editable-overallotment-refresh-row/EditableOverallotmentRefreshRow';

export type Props = {
  offeringPrice: number;
  trades: StabilizationTrade[];
  onRemoveRow: (row: number) => void;
  onAddRow: (row: StabilizationTrade) => void;
};

export const EditableOverallotmentRefresh: React.FC<Props> = ({
  trades,
  offeringPrice,
  onRemoveRow,
  onAddRow,
}) => {
  const handleAddTrade = () => {
    onAddRow({ date: null, numberOfShares: null, averagePrice: null, activityType: null });
  };

  return (
    <FlexContainer direction="column" gap={20}>
      <StyledAddTradeLinkButton
        testId="add-trade-button"
        iconLeft={{ name: 'plus' }}
        onClick={handleAddTrade}
      >
        Add Trade
      </StyledAddTradeLinkButton>

      <Table gridTemplateColumns="1fr 1fr 1fr 1fr 35px">
        <OverallotmentRefreshTableHeader isEditable trades={trades} offeringPrice={offeringPrice} />

        {trades.map((trade, index) => (
          <EditableOverallotmentRefreshRow
            key={index}
            index={index}
            trade={trade}
            offeringPrice={offeringPrice}
            onRemoveRow={() => onRemoveRow(index)}
          />
        ))}
      </Table>
    </FlexContainer>
  );
};

type FieldProps = { offeringPrice: number };

const EditableOverallotmentRefreshField: React.FC<FieldProps> = ({ offeringPrice }) => {
  return (
    <FieldArray
      name="sell"
      validateOnChange={false}
      render={({ remove, push, form: { values } }) => (
        <EditableOverallotmentRefresh
          offeringPrice={offeringPrice}
          trades={values.sell}
          onRemoveRow={remove}
          onAddRow={push}
        />
      )}
    />
  );
};

export default EditableOverallotmentRefreshField;
