import { ISODate } from '@cmg/common';
import { FormikErrors } from 'formik';
import * as yup from 'yup';

import {
  CreateOrUpdateStabilizationInput,
  CreateOrUpdateStabilizationTradeInput,
  TradeActivityType,
  TradeType,
} from '../../../../graphql';
import { MAX_32_BIT_INT, MAX_53_BIT_INT } from '../../../../types/graphql/constants';
import {
  FinalSettlement_StabilizationPartsFragment,
  FinalSettlement_StabilizationTradePartsFragment,
} from '../graphql';

export const isStabilizationEmpty = (stabilization: FinalSettlement_StabilizationPartsFragment) => {
  return !stabilization.notes && stabilization.trades.length === 0;
};

export type Values = {
  notes: string;
  buy: StabilizationTrade[];
  sell: StabilizationTrade[];
};

export type StabilizationTrade = {
  date: ISODate | null;
  numberOfShares: number | null;
  averagePrice: number | null | undefined;
  activityType: TradeActivityType | null | undefined;
};

export const tradeValidationSchema = yup.object({
  date: yup.date().label('Trade date').nullable().required(),
  numberOfShares: yup
    .number()
    .label('Shares')
    .positive()
    .max(MAX_32_BIT_INT, 'Invalid Shares value')
    .nullable()
    .required(),
  averagePrice: yup
    .number()
    .label('Average Price')
    .positive()
    .max(MAX_53_BIT_INT, 'Invalid Average Price value')
    .nullable()
    .required(),
  activityType: yup
    .string()
    .label('Activity Type')
    .nullable()
    .oneOf([null, ...Object.values(TradeActivityType)]) // because .oneOf() is not respecting .nullable()
    .required(),
});

export const salesValidationSchema = yup.object({
  date: yup.date().label('Trade date').nullable().required(),
  numberOfShares: yup
    .number()
    .label('Shares')
    .positive()
    .max(MAX_32_BIT_INT, 'Invalid Shares value')
    .nullable()
    .required(),
  averagePrice: yup
    .number()
    .label('Average Price')
    .positive()
    .max(MAX_53_BIT_INT, 'Invalid Average Price value')
    .nullable()
    .required(),
});

export const validationSchema = yup.object({
  notes: yup.string().label('Notes').max(1000).nullable(),
  buy: yup.array().of(tradeValidationSchema),
  sell: yup.array().of(salesValidationSchema),
});

export const getInitialValues = (
  stabilization: FinalSettlement_StabilizationPartsFragment
): Values => ({
  notes: stabilization.notes,
  buy: getTrade(stabilization.trades, TradeType.Buy),
  sell: getTrade(stabilization.trades, TradeType.Sell),
});

const getTrade = (
  trades: readonly FinalSettlement_StabilizationTradePartsFragment[],
  tradeType: TradeType
) => {
  return trades.reduce<StabilizationTrade[]>((result, current) => {
    if (current.type === tradeType) {
      result.push({
        date: current.date,
        numberOfShares: current.shares,
        averagePrice: current.averagePrice,
        activityType: current.activityType,
      });
    }

    return result;
  }, []);
};

const getTradePayload = (
  trades: StabilizationTrade[],
  type: TradeType
): CreateOrUpdateStabilizationTradeInput[] => {
  return trades.map(trade => ({
    date: trade.date!,
    activityType: trade.activityType,
    averagePrice: trade.averagePrice!,
    shares: trade.numberOfShares!,
    type,
  }));
};

export const getPayload = (values: Values): CreateOrUpdateStabilizationInput => {
  return {
    notes: values.notes,
    trades: [
      ...getTradePayload(values.buy, TradeType.Buy),
      ...getTradePayload(values.sell, TradeType.Sell),
    ],
  };
};

const stabilizationFieldDictionary = {
  notes: 'Notes',
  date: 'Trade Date',
  numberOfShares: 'Shares',
  averagePrice: 'Average Price',
  activityType: 'Activity Type',
};

export const getErrors = (errors: FormikErrors<Values>) => {
  const stabilizationErrors = new Set<string>();

  if (errors.notes) {
    stabilizationErrors.add(stabilizationFieldDictionary.notes);
  }
  if (errors.buy) {
    for (const buyError of errors.buy) {
      if (buyError) {
        Object.keys(buyError).forEach(item => {
          stabilizationErrors.add(stabilizationFieldDictionary[item]);
        });
      }
    }
  }
  if (errors.sell) {
    for (const sellError of errors.sell) {
      if (sellError) {
        Object.keys(sellError).forEach(item => {
          stabilizationErrors.add(stabilizationFieldDictionary[item]);
        });
      }
    }
  }

  return Array.from(stabilizationErrors).sort((a, b) => a.localeCompare(b));
};
