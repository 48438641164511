import React from 'react';

import SidePanel, {
  Props as SidePanelProps,
} from '../../../../../../common/components/layout/side-panel/SidePanel';
import OverwriteDesignation from './overwrite-designation/OverwriteDesignation';
import OverwriteDesignationSideNavbar from './overwrite-designation-side-navbar/OverwriteDesignationSideNavBar';
import { SContent, SSideBar, SSidePanelLayout } from './OverwriteDesignationSidePanel.styles';

export type Props = SidePanelProps & {
  offeringId: string;
  indicationId: string;
};

const OverwriteDesignationSidePanel: React.FC<Props> = ({
  isOpen,
  onClose,
  offeringId,
  indicationId,
}) => {
  return (
    <SidePanel isOpen={isOpen} onClose={onClose} testId="owerwrite-designation-side-panel">
      <SSidePanelLayout>
        <SSideBar>
          <OverwriteDesignationSideNavbar offeringId={offeringId} indicationId={indicationId} />
        </SSideBar>
        <SContent>
          <OverwriteDesignation offeringId={offeringId} indicationId={indicationId} />
        </SContent>
      </SSidePanelLayout>
    </SidePanel>
  );
};

export default OverwriteDesignationSidePanel;
