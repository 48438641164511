import React from 'react';

import Banner from '../../../../../../common/components/indicators/banner/Banner';
import {
  SComplianceActionRequiredTitle,
  SComplianceBannerWrapper,
  SComplianceTitleWrapper,
} from '../../../indication-activity/compliance/ComplianceActionRequiredBanner.styles';
import { useComplianceContext } from '../../context/ComplianceContext';

const ComplianceActionRequired: React.FC = () => {
  const { hasComplianceWarning } = useComplianceContext().certificates.warning;

  if (!hasComplianceWarning) {
    return null;
  }

  return (
    <SComplianceBannerWrapper
      padding="0px 0px 0px 0px"
      data-test-id="compliance-action-required-banner"
    >
      <Banner variant="error" iconName="exclamation-triangle" padding="7px 0px 0px 18px">
        <SComplianceTitleWrapper data-test-id="indication-compliance-error-banner">
          <SComplianceActionRequiredTitle>Action required</SComplianceActionRequiredTitle>
        </SComplianceTitleWrapper>
      </Banner>
    </SComplianceBannerWrapper>
  );
};

export default ComplianceActionRequired;
