import { numericUtil } from '@cmg/common';
import { TableCell, TableRow } from '@cmg/design-system';
import React from 'react';

import TableCellOnPlatformIcon from '../../../../../components/table-cell-on-platform-icon/TableCellOnPlatformIcon';
import { MyRetailRowData } from '../ReadOnlyInternalRetailDemandView.model';

const { getDisplayValueForInteger, getDisplayValueForPercents, getDisplayValueForCurrency } =
  numericUtil;

export type Props = Readonly<{
  rowData: MyRetailRowData;
}>;

const InternalRetailDemandReadOnlyTableRow: React.FC<Props> = ({ rowData }) => {
  return (
    <TableRow data-testid="demand-data-row">
      <TableCellOnPlatformIcon size="small" isOnPlatform={rowData.isOnPlatform} />
      <TableCell size="medium" sx={{ whiteSpace: 'nowrap' }}>
        {rowData.firmName}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForInteger(rowData.demand.shareQuantity)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForInteger(rowData.demand.roundLots)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForInteger(rowData.retention.shareQuantity)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForInteger(rowData.retention.roundLots)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForPercents(rowData.sellingConcessionPercentage)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForCurrency(rowData.perShare, 6)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForCurrency(rowData.receivedByTheFirm, 2)}
      </TableCell>
      <TableCell size="medium" align="right">
        {getDisplayValueForCurrency(rowData.retainedByTheBank, 2)}
      </TableCell>
    </TableRow>
  );
};

export default InternalRetailDemandReadOnlyTableRow;
