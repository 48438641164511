import React, { VFC } from 'react';

import USAFlag from './flags/USA.svg';

type Props = {
  readonly countryCode?: string | null;
};

export const Flag: VFC<Props> = ({ countryCode }) => {
  return countryCode === 'US' ? <img src={USAFlag} alt="US Flag" aria-label={countryCode} /> : null;
};
