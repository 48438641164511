import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';

import { useCheckSyndicateWiresAccess } from '../../../../../common/util/check-access/useCheckSyndicateWiresAccess';
import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingType } from '../../../../../graphql';

type Props = {
  offeringId: UUID | undefined;
  isSharedDraft: boolean;
  offeringType: OfferingType | undefined;
  isDiscarded: boolean;
};

export const useSyndicateWiresRoute = ({
  offeringId,
  offeringType,
  isSharedDraft,
  isDiscarded,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const { canRead } = useCheckSyndicateWiresAccess(offeringId);

  if (!offeringId) {
    return undefined;
  }

  if (offeringType === OfferingType.UnregisteredBlock) {
    return undefined;
  }

  if (isSharedDraft) {
    return undefined;
  }

  if (isDiscarded) {
    return undefined;
  }

  if (!canRead) {
    return undefined;
  }

  return {
    'data-test-id': xcSelectors.offeringNavBarSyndicateWires.testId,
    href: routeFactory.syndicateWires.getUrlPath({ offeringId }),
    label: 'Wires',
    value: 'wires',
  };
};
