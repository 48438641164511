import { Modal, PrimaryButton, SecondaryButton, ToastManager } from '@cmg/common';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useIndicationActivity_AcknowledgeCoveredIndicationMutation } from '../../../../offering-side-panel/order-book/indication-activity/components/allocation/graphql/__generated__';
import { MyOrdersGridCoveredIndication } from '../MyOrdersGrid.model';
import { SButtonGroup, SFooter } from './AllocationAcknowledgementModal.styles';

type OwnProps = {
  data: MyOrdersGridCoveredIndication;
  canUpdateAllocation: boolean;
};

export type Props = OwnProps & InjectedProps;

export const AllocationAcknowledgementModalComponent: React.FC<Props> = ({
  data,
  canUpdateAllocation,
  handleHide,
}) => {
  const [
    acknowledgeCoveredAllocation,
    { loading: acknowledgeCoveredAllocationLoading, error: acknowledgeCoveredAllocationError },
  ] = useIndicationActivity_AcknowledgeCoveredIndicationMutation({
    onCompleted: () => {
      ToastManager.success('Successfully acknowledged allocation');
      handleHide();
    },
    onError: () => {
      ToastManager.error('Failed to acknowledge allocation');
    },
  });

  const handleConfirm = async () => {
    try {
      if (canUpdateAllocation && data) {
        await acknowledgeCoveredAllocation({
          variables: {
            offeringId: data.offeringId,
            indicationId: data.id,
            versionId: data.allocationVersion!,
          },
        });
      }
    } catch {
      ToastManager.error('Failed to Update Allocations');
    }
  };

  return (
    <React.Fragment>
      {acknowledgeCoveredAllocationError ? (
        <ServerErrorsBanner error={acknowledgeCoveredAllocationError} />
      ) : null}
      <Modal
        show
        size="small"
        title="Allocation Acknowledgement"
        onHide={handleHide}
        closeButton={true}
        footer={
          <SFooter>
            <SButtonGroup>
              <SecondaryButton onClick={handleHide}>Cancel</SecondaryButton>
              <PrimaryButton disabled={acknowledgeCoveredAllocationLoading} onClick={handleConfirm}>
                Confirm
              </PrimaryButton>
            </SButtonGroup>
          </SFooter>
        }
      >
        <p>Are you sure you want to acknowledge this allocation?</p>
      </Modal>
    </React.Fragment>
  );
};

const name = 'ORDERBOOK/ALLOCATION_ACKNOWLEDGEMENT_MODAL';
export const openAllocationAcknowledgementModal = (props: OwnProps) => show(name, props);
export const closeAllocationAcknowledgementModal = () => hide(name);

export default connectModal({ name })(AllocationAcknowledgementModalComponent);
