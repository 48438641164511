import React from 'react';

import Banner from '../../../../../../common/components/indicators/banner/Banner';

export type Props = {
  loadedEntity: string;
};

export const SyndicateWiresErrorBanner: React.FC<Props> = ({ loadedEntity }) => {
  return (
    <Banner variant="error" withoutMargin>
      An error has occurred while loading {loadedEntity}. Please try refreshing the page.
    </Banner>
  );
};
