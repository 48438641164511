import { useAuth } from '@cmg/auth';
import { useEffect, useMemo } from 'react';

import { MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT } from '../../graphql/constants';
import { useMyOfferings_PublishedOfferingsQuery } from '../graphql';
import { toMyPublishedOffering } from '../utils/transformData';

function useFetchRemainingPaginatedData(query) {
  useEffect(
    function fetchRemainingPaginatedData() {
      if (!query.data?.publishedOfferings) {
        return;
      }

      const { pageInfo, items } = query.data.publishedOfferings;

      if (items && pageInfo.hasNextPage) {
        query.fetchMore({
          variables: { skip: items.length },
          updateQuery: (prev, curr) => {
            const { fetchMoreResult } = curr;
            if (!fetchMoreResult.publishedOfferings?.items) {
              return prev;
            }

            return {
              ...prev,
              publishedOfferings: {
                ...prev.publishedOfferings,
                ...fetchMoreResult.publishedOfferings,
                items: [
                  ...(prev.publishedOfferings?.items ?? []),
                  ...fetchMoreResult.publishedOfferings.items,
                ],
              },
            };
          },
        });
      }
    },
    [query]
  );
}

export const usePublishedOfferingsQuery = ({ shouldSkip }: { shouldSkip: boolean }) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const query = useMyOfferings_PublishedOfferingsQuery({
    variables: {
      skip: 0,
      take: MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT,
      where: {
        isExecutingOnPlatform: { eq: true },
        syndicate: { managers: { some: { cmgEntityKey: { eq: oidcUserCmgEntityKey } } } },
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
  });

  useFetchRemainingPaginatedData(query);

  const { data, loading, error } = query;

  const loadingPages = loading || !!data?.publishedOfferings?.pageInfo.hasNextPage;

  const publishedOfferingsData = useMemo(() => {
    if (loadingPages) return null;
    return data?.publishedOfferings?.items?.map(toMyPublishedOffering) ?? null;
  }, [data?.publishedOfferings?.items, loadingPages]);

  const offeringTypes = useMemo(
    () =>
      (publishedOfferingsData ?? []).map(({ id, type, pricingInstrumentCountryCode }) => ({
        id,
        type,
        pricingInstrumentCountryCode,
      })),
    [publishedOfferingsData]
  );

  const offeringStatuses = useMemo(
    () =>
      (publishedOfferingsData ?? []).map(({ id, status }) => ({
        id,
        status,
      })),
    [publishedOfferingsData]
  );

  const issuerSectors = useMemo(
    () =>
      (publishedOfferingsData ?? []).map(({ id, issuerSector }) => ({
        id,
        issuerSector,
      })),
    [publishedOfferingsData]
  );

  return {
    publishedOfferings: {
      data: loadingPages ? undefined : publishedOfferingsData,
      offeringTypes,
      offeringStatuses,
      issuerSectors,
    },
    isLoadingPublishedOfferings: loadingPages,
    publishedOfferingsError: error,
  };
};
