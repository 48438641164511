import { AccountType, useAuth } from '@cmg/auth';

import useCheckCoveredInstitutionalIndicationAccess from '../../../../common/util/check-access/useCheckCoveredInstitutionalIndicationAccess';
import useCheckOrderBookAccess from '../../../../common/util/check-access/useCheckOrderBookAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';

export enum OrderBookPersonaType {
  SYNDICATE,
  SALES_AND_TRADING,
  BUY_SIDE,
  UNKNOWN,
}

export type OrderBookPersona = {
  canReadOrderBook: boolean;
  canReadIndications: boolean;
  canUpdateIndications: boolean;
  type: OrderBookPersonaType;
  isSellSide: boolean;
  loading: boolean;
};

/*
We need a way to identify whetever we are what persona is using the app
*/
export const useIdentifyOrderBookPersona = ({
  offeringId,
}: {
  offeringId?: string;
}): OrderBookPersona => {
  const { oidcUserAccountType } = useAuth();
  const { canRead: canReadOrderBook } = useCheckOrderBookAccess({ offeringId });
  const { canRead: canReadCoveredIndication, canUpdate: canUpdateCoveredIndication } =
    useCheckCoveredInstitutionalIndicationAccess();
  const {
    canRead: canReadInstutionalIndication,
    canUpdate: canUpdateInsitutionalIndication,
    loading: canReadInstutionalIndicationLoading,
  } = useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
    offeringId,
  });
  const isSellSide = oidcUserAccountType === AccountType.SELL_SIDE;
  const canReadIndications =
    canReadOrderBook && (canReadInstutionalIndication || canReadCoveredIndication);

  let type = OrderBookPersonaType.UNKNOWN;
  if (isSellSide && !canReadCoveredIndication && canReadInstutionalIndication) {
    type = OrderBookPersonaType.SYNDICATE;
  } else if (isSellSide && canReadCoveredIndication) {
    type = OrderBookPersonaType.SALES_AND_TRADING;
  } else if (oidcUserAccountType === AccountType.BUY_SIDE) {
    type = OrderBookPersonaType.BUY_SIDE;
  }

  return {
    canReadOrderBook: canReadIndications,
    canReadIndications: canReadIndications,
    canUpdateIndications:
      canReadIndications && (canUpdateInsitutionalIndication || canUpdateCoveredIndication),
    type,
    isSellSide,
    loading: canReadInstutionalIndicationLoading,
  };
};
