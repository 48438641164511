/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    payload: Types.CreateNonSyndicateSellingGroupTermsRetentionWireInput;
  }>;

export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createNonSyndicateSellingGroupTermsRetentionWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly sellingConcession: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteNonSyndicateSellingGroupTermsRetentionWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly publishedOrPrivateOffering: {
      readonly __typename?: 'SyndicateWiresOffering';
      readonly id: string;
      readonly stage: Types.OfferingStage;
    };
  };
  readonly nonSyndicateSellingGroupTermsRetentionWireDetails: {
    readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
      readonly sellingConcession: number;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataPartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
  readonly recipient: string;
  readonly disclaimer?: string | null;
  readonly sellingConcession: number;
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
  readonly id: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly recipientsUserData: ReadonlyArray<{
    readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
    readonly recipient: string;
    readonly disclaimer?: string | null;
    readonly sellingConcession: number;
  }>;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
    cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  }>;

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupTermsRetentionWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationPartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.NonSyndicateSellingGroupTermsRetentionWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupTermsRetentionWireValidation: {
    readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.NonSyndicateSellingGroupTermsRetentionWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupTermsRetentionWireList: ReadonlyArray<{
    readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
      readonly sellingConcession: number;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
    managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
    sendDateTimeZone: Types.Scalars['String'];
    shouldAddSenderToCc: Types.Scalars['Boolean'];
  }>;

export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendNonSyndicateSellingGroupTermsRetentionWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly sellingConcession: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
    payload: Types.UpdateNonSyndicateSellingGroupTermsRetentionWireInput;
  }>;

export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateNonSyndicateSellingGroupTermsRetentionWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupTermsRetentionRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly sellingConcession: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWire',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateNonSyndicateSellingGroupTermsRetentionWireInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNonSyndicateSellingGroupTermsRetentionWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;

/**
 * __useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateNonSyndicateSellingGroupTermsRetentionWireMutation, { data, loading, error }] = useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >(SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireDocument, options);
}
export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;
export const SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWire',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNonSyndicateSellingGroupTermsRetentionWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;

/**
 * __useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteNonSyndicateSellingGroupTermsRetentionWireMutation, { data, loading, error }] = useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >(SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireDocument, options);
}
export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetails',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedOrPrivateOffering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupTermsRetentionWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreview',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupTermsRetentionWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupTermsRetentionWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationLazyQueryHookResult =
  ReturnType<
    typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationLazyQuery
  >;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupTermsRetentionWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery
>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables
  >;
export const SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWire',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendNonSyndicateSellingGroupTermsRetentionWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;

/**
 * __useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendNonSyndicateSellingGroupTermsRetentionWireMutation, { data, loading, error }] = useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >(SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireDocument, options);
}
export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;
export const SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWire',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateNonSyndicateSellingGroupTermsRetentionWireInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNonSyndicateSellingGroupTermsRetentionWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupTermsRetentionWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'SyndicateWires_NonSyndicateSellingGroupTermsRetentionRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;

/**
 * __useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateNonSyndicateSellingGroupTermsRetentionWireMutation, { data, loading, error }] = useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >(SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireDocument, options);
}
export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation>;
export type SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsQuery
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireDetailsDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewQuery
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWirePreviewDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationQuery
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWireValidationDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresQuery
  >(SyndicateWires_NonSyndicateSellingGroupTermsRetentionWiresDocument);
