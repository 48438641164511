import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import React, { useMemo, VFC } from 'react';

export const ADDRESS_LENGTH_THRESHOLD = 45;

export type Props = Readonly<{
  distributionList: readonly string[];
}>;

const RecipientDistributionEmails: VFC<Props> = ({ distributionList }) => {
  const emailAddresses = useMemo(() => distributionList.join(', '), [distributionList]);
  const isTooltipEnabled = useMemo(
    () => emailAddresses.length >= ADDRESS_LENGTH_THRESHOLD,
    [emailAddresses.length]
  );

  if (isTooltipEnabled) {
    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <TableContainer sx={{ maxHeight: theme => theme.spacing(30) }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell size="small">Email Distribution List</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distributionList.map(email => (
                  <TableRow key={email}>
                    <TableCell>{email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      >
        <Typography variant="hint" noWrap overflow="hidden" textOverflow="ellipsis">
          {emailAddresses}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography noWrap overflow="hidden" textOverflow="ellipsis">
      {emailAddresses}
    </Typography>
  );
};

export default RecipientDistributionEmails;
