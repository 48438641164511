/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_SubmitRetailDemandMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.SubmitMyRetailInput;
}>;

export type OrderBook_SubmitRetailDemandMutation = {
  readonly __typename?: 'Mutation';
  readonly submitRetailDemand: {
    readonly __typename?: 'TotalSubmittedRetailDemandInfo';
    readonly id: string;
    readonly shareQuantity: number;
    readonly submittedDateTime: string;
  };
};

export const OrderBook_SubmitRetailDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_SubmitRetailDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmitMyRetailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitRetailDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_SubmitRetailDemandMutationFn = Apollo.MutationFunction<
  OrderBook_SubmitRetailDemandMutation,
  OrderBook_SubmitRetailDemandMutationVariables
>;

/**
 * __useOrderBook_SubmitRetailDemandMutation__
 *
 * To run a mutation, you first call `useOrderBook_SubmitRetailDemandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_SubmitRetailDemandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookSubmitRetailDemandMutation, { data, loading, error }] = useOrderBook_SubmitRetailDemandMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_SubmitRetailDemandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_SubmitRetailDemandMutation,
    OrderBook_SubmitRetailDemandMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_SubmitRetailDemandMutation,
    OrderBook_SubmitRetailDemandMutationVariables
  >(OrderBook_SubmitRetailDemandDocument, options);
}
export type OrderBook_SubmitRetailDemandMutationHookResult = ReturnType<
  typeof useOrderBook_SubmitRetailDemandMutation
>;
export type OrderBook_SubmitRetailDemandMutationResult =
  Apollo.MutationResult<OrderBook_SubmitRetailDemandMutation>;
export type OrderBook_SubmitRetailDemandMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_SubmitRetailDemandMutation,
  OrderBook_SubmitRetailDemandMutationVariables
>;
