import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { PinnedCellRenderer } from '../pinned-cell-renderer/PinnedCellRenderer';

export type Props = {
  value: number | null;
  targetValue?: number | null;
  errorMessage?: string;
};

export const NumericPinnedCellRenderer: React.FC<Props> = ({
  value,
  targetValue,
  errorMessage,
}) => {
  const safeValue = value ?? 0;
  const subValue = isNil(targetValue) ? null : targetValue - safeValue;
  return (
    <PinnedCellRenderer
      value={numericUtil.formatNumber(value ?? 0, 0)}
      subValue={numericUtil.formatNumber(subValue ?? 0, 0)}
      targetValue={targetValue ? numericUtil.formatNumber(targetValue, 0) : null}
      errorMessage={errorMessage}
      errorType="warning"
    />
  );
};
