import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_DeleteLogisticsMutation,
  useRoadshows_DeleteLogisticsMutation,
} from '../graphql';

export type DeleteLogisticsMutation = Roadshows_DeleteLogisticsMutation;

export const refetchQueries = () => {
  const refetchMeetings = getOperationName(Roadshows_RoadshowEventsDocument);

  return [refetchMeetings!];
};

export const useDeleteLogisticsMutation = () => {
  return useRoadshows_DeleteLogisticsMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
