import {
  ControlWithHelperText,
  ControlWithHelperTextProps,
  TextField,
  TextFieldProps,
} from '@cmg/design-system';
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import { ChangeEvent as CleaveChangeEvent, Props as CleaveProps } from 'cleave.js/react/props';
import { useField, useFormikContext } from 'formik';
import set from 'lodash/set';
import React, { PropsWithChildren, useMemo } from 'react';

export type FormikNumericFieldProps<TFormValues extends Record<string, unknown>> = Omit<
  TextFieldProps,
  'name' | 'value' | 'error' | 'onChange' | 'helperText'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> & {
    readonly name: keyof TFormValues & string;
    readonly onChange?: (value: number | null) => void;
    readonly precision?: number;
    readonly digit?: number;
    readonly startAdornment?: JSX.Element;
  };

const CleaveInput = React.forwardRef<HTMLInputElement, PropsWithChildren<CleaveProps>>(
  function CleaveInput(props, ref) {
    return (
      <Cleave
        {...props}
        onInit={owner => {
          // https://github.com/nosir/cleave.js/issues/601#issuecomment-902747682
          set(owner, 'lastInputValue', '');
        }}
        htmlRef={(inputNode: HTMLInputElement) => {
          // opened cleave issue: https://github.com/nosir/cleave.js/issues/497
          if (!ref) {
            return;
          }
          if (typeof ref === 'function') {
            ref(inputNode);
          } else {
            ref.current = inputNode;
          }
        }}
      />
    );
  }
);

/**
 * @deprecated - Use @cmg/design-system-formik instead
 */
export function FormikNumericField<TFormValues extends Record<string, unknown>>({
  name,
  showHelperTextInTooltip,
  onChange,
  precision,
  digit,
  startAdornment,
  ...restProps
}: FormikNumericFieldProps<TFormValues>): JSX.Element {
  const [field, meta] = useField<TFormValues>(name);
  const { setFieldValue } = useFormikContext();

  const options: CleaveOptions = useMemo(
    () => ({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: precision,
      numeralIntegerScale: digit,
      rawValueTrimPrefix: true,
      noImmediatePrefix: true,
    }),
    [digit, precision]
  );

  const handleChange = (e: CleaveChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const rawValue = e.target.rawValue;
    const numericValue = parseFloat(rawValue);

    if (rawValue !== '' && Number.isNaN(numericValue)) {
      return;
    }

    const nextValue = rawValue === '' ? null : numericValue;
    setFieldValue(name, nextValue);
    onChange?.(nextValue);
  };

  return (
    <ControlWithHelperText
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={meta.touched && meta.error}
      renderControl={renderControlProps => {
        return (
          <TextField
            {...restProps}
            {...field}
            InputProps={{
              startAdornment,
              // @ts-expect-error - options are passed to CleaveInput correctly
              inputComponent: CleaveInput,
              inputProps: {
                ...restProps.inputProps,
                ...renderControlProps.inputProps,
                inputMode: 'numeric',
                pattern: '[0-9]*',
                style: { textAlign: 'right' },
                options,
              },
            }}
            helperText={renderControlProps.helperText}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
          />
        );
      }}
    />
  );
}
