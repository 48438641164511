import { ApolloError } from '@apollo/client';

export default function isManagerRelationshipError(error: ApolloError) {
  return error.graphQLErrors.some(graphQLError => {
    return graphQLError.extensions?.response?.body?.error?.details.some(wellFormedDetail => {
      // TODO - we should not be deriving conditional logic from error text. There should be a particular error code
      // returned from the server.
      return (
        wellFormedDetail?.message?.includes(
          'your firm does not have a relationship with a manager on this offering'
        ) || wellFormedDetail?.message?.includes('Investor is not covered by offering manager')
      );
    });
  });
}
