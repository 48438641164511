import React, { Fragment } from 'react';

import type { FeatureToggleKeys } from './appSettings';
import { useFeatureToggles } from './context';

type Props = {
  readonly feature: FeatureToggleKeys | readonly FeatureToggleKeys[];
};

/**
 * Switches children component on or off depending on feature toggles.
 * @param props Component Props
 * @returns
 */
export const FeatureToggleSwitch: React.FC<Props> = props => {
  const { children, feature: featureKeys } = props;
  const features = useFeatureToggles();

  const isEnabled = React.useMemo(
    () =>
      (Array.isArray(featureKeys) ? featureKeys : [featureKeys]).every(
        featureKey => features[featureKey] === true
      ),
    [featureKeys, features]
  );

  return isEnabled ? <Fragment>{children}</Fragment> : null;
};

export default FeatureToggleSwitch;
