import { getOperationName } from '@apollo/client/utilities';

import {
  OrderBook_OrderBookRouteDocument,
  useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation,
} from '../graphql';
import { InstitutionalDemandGrid_SummaryDocument } from '../institutional-demand/components/institutional-demand-grid/graphql';

export const refetchQueries = () => {
  const refetchInstitutionalDemandSummaryGridQuery = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  );
  const refetchOrderBookRouteQuery = getOperationName(OrderBook_OrderBookRouteDocument);

  return [
    // After a release, we need to refetch the Institutional Demand query
    // so that the allocations with released status is displayed and grid
    // displays column as disabled
    refetchInstitutionalDemandSummaryGridQuery!,
    refetchOrderBookRouteQuery!,
  ];
};

export const useReleaseFinalInstitutionalAllocationSetMutation = (
  options: Omit<
    Parameters<typeof useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation>[0],
    'refetchQueries'
  >
) =>
  useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation({
    ...options,
    refetchQueries,
  });
