import styled from 'styled-components/macro';

export const BoxWrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.foundation.colors.secondary['100']};
  width: 100%;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
`;

export const SSource = styled.span`
  font-size: ${({ theme }) => theme.text.size.tiny};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;
