import { UUID } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
} from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import { usePassOnCoveredIndication } from './hooks/usePassOnCoveredIndication';

export type Props = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onFinishEditing?: () => void;
  offeringId: UUID;
  indicationId?: UUID;
  indicationStatus?: IndicationStatus | null;
  isPassingNewIndication?: boolean;
}>;

const PassCoveredIndicationDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onFinishEditing,
  offeringId,
  indicationId,
  indicationStatus,
  isPassingNewIndication = false,
}) => {
  const [onPass, isPassing] = usePassOnCoveredIndication(
    offeringId,
    indicationId,
    indicationStatus,
    onClose,
    isPassingNewIndication,
    onFinishEditing
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Pass on Offering</DialogTitle>

      <DialogContent dividers>Are you sure you want to pass on this offering?</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isPassing}>
          Close
        </Button>
        <LoadingButton variant="contained" onClick={onPass} loading={isPassing}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PassCoveredIndicationDialog;
