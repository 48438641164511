import React from 'react';

import { SContainer } from './ListItemSecondaryAction.styles';

export type Props = {
  onClick?: () => void;
};

const ListItemSecondaryAction: React.FC<Props> = ({ children, onClick }) => {
  const handleClick: React.MouseEventHandler = e => {
    if (!onClick) {
      return;
    }

    // We need to prevent the event from propagating to the list item itself,
    // since clicking on a secondary action (e.g. expand/collapse children) should not trigger a redirect in this case
    e.stopPropagation();
    onClick();
  };

  return <SContainer onClick={handleClick}>{children}</SContainer>;
};

export default ListItemSecondaryAction;
