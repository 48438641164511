import { LinkButton, ODSTable as Table, Popover, theme, timeUtil } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';

import Status from '../../../../../common/components/indicators/status/Status';
import routeFactory from '../../../../../common/util/routeFactory';
import { EventStatus, RoadshowEventType } from '../../../../../graphql';
import { getMeetingTypeLabel } from '../../../common/constants';
import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../common/graphql';
import CalendarContent from '../common/calendar-content/CalendarContent';
import CalendarFilters from '../common/calendar-filters/CalendarFilters';
import { View } from '../common/calendar-filters/CalendarFilters.model';
import { getDateFromTime } from '../view-daily-events/meeting-content/MeetingContent.model';
import { groupEventsByDaysAndLocations } from './CalendarViewList.model';
import {
  SMeetingInvestor,
  SMeetingInvestorName,
  SMeetingInvestorTeamSize,
} from './CalendarViewList.styles';
import InvestorTeamList from './investor-team-list/InvestorTeamList';

export type Props = {
  allEvents: readonly Roadshows_RoadshowEventPartsFragment[];
  roadshow: Roadshows_RoadshowPartsFragment;
  onViewChange: (view: View) => void;
};

const CalendarViewList: React.FC<Props> = ({ allEvents, roadshow, onViewChange }) => {
  const { push } = useHistory();

  const redirectToCreateMeetingPage = () => {
    push(
      routeFactory.roadshowCreateMeeting.getUrlPath({
        roadshowId: roadshow.id,
      })
    );
  };

  const redirectToMeetingDetailPage = (meetingId: string) => {
    push(
      routeFactory.roadshowMeetingDetails.getUrlPath({
        roadshowId: roadshow.id,
        meetingId,
      })
    );
  };

  const redirectToCreateLogisticsPage = () => {
    push(
      routeFactory.roadshowCreateLogistics.getUrlPath({
        roadshowId: roadshow.id,
      })
    );
  };

  const redirectToLogisticsDetailPage = (logisticsId: string) => {
    push(
      routeFactory.roadshowLogisticsDetails.getUrlPath({ roadshowId: roadshow.id, logisticsId })
    );
  };

  const handleOpenEditPanel = (eventId: string, eventType: RoadshowEventType) => {
    if (eventType === RoadshowEventType.Meeting) {
      redirectToMeetingDetailPage(eventId);
    } else if (eventType === RoadshowEventType.Logistics) {
      redirectToLogisticsDetailPage(eventId);
    }
  };

  const events = React.useMemo(() => {
    return groupEventsByDaysAndLocations(roadshow.roadshowDays, allEvents);
  }, [roadshow.roadshowDays, allEvents]);

  return (
    <CalendarContent>
      <CalendarFilters
        view={View.LIST}
        onViewChange={onViewChange}
        onCreateMeeting={redirectToCreateMeetingPage}
        onCreateLogistics={redirectToCreateLogisticsPage}
      />
      <Table numColumns={7}>
        <Table.TableHeaderCell>Time</Table.TableHeaderCell>
        <Table.TableHeaderCell>Event Details</Table.TableHeaderCell>
        <Table.TableHeaderCell>Type</Table.TableHeaderCell>
        <Table.TableHeaderCell>Status</Table.TableHeaderCell>
        <Table.TableHeaderCell>Hosted By</Table.TableHeaderCell>
        <Table.TableHeaderCell>Investor Participants</Table.TableHeaderCell>
        <Table.TableHeaderCell />
        {events.map(day => (
          <React.Fragment key={day.date}>
            <Table.GroupHeaderCell colSpan={7}>
              {timeUtil.formatAsDisplayDayAndDate(day.date)}
            </Table.GroupHeaderCell>
            {day.locations.map(location => (
              <React.Fragment key={location.locationName}>
                <Table.GroupSubHeaderCell colSpan={7}>
                  {location.locationName},{' '}
                  {timeUtil.formatAsTimeZoneAbbreviation(day.date, location.locationTimezone)}
                </Table.GroupSubHeaderCell>
                {location.meetings.map(event => (
                  <Table.Row key={event.id}>
                    <Table.Cell>
                      <div>
                        {timeUtil.formatAsDisplayTimeRange(
                          getDateFromTime(event.startTime),
                          getDateFromTime(event.endTime)
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <b>{event.title}</b>
                      </div>
                      <div>{event.publicNotes}</div>
                    </Table.Cell>
                    <Table.Cell>
                      {event.roadshowEventType === RoadshowEventType.Logistics
                        ? 'Logistics'
                        : getMeetingTypeLabel(event.type)}
                    </Table.Cell>
                    <Table.Cell>
                      {event.status ? (
                        <Status
                          status={event.status}
                          options={() => ({
                            [EventStatus.Draft]: {
                              label: 'Draft',
                              color: theme.designSystem.colors.gray[200],
                            },
                            [EventStatus.OpenToSales]: {
                              label: 'Open To Sales',
                              color: theme.designSystem.colors.yellow[300],
                            },
                            [EventStatus.Confirmed]: {
                              label: 'Confirmed',
                              color: theme.designSystem.colors.green[300],
                            },
                            [EventStatus.Cancelled]: {
                              label: 'Cancelled',
                              color: theme.designSystem.colors.red[100],
                            },
                          })}
                        />
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <b>{event.hostFirm}</b>
                      </div>
                      <div>{event.hostName}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <Popover
                        variant="DARK"
                        content={<InvestorTeamList investorTeams={event.participantTeams} />}
                      >
                        {event.participantTeams.map(investor => (
                          <SMeetingInvestor key={investor.id}>
                            <SMeetingInvestorName>{investor.name}</SMeetingInvestorName>{' '}
                            <SMeetingInvestorTeamSize>
                              ({investor.participants.length})
                            </SMeetingInvestorTeamSize>
                          </SMeetingInvestor>
                        ))}
                      </Popover>
                    </Table.Cell>
                    <Table.Cell align="right">
                      <LinkButton
                        iconLeft={{ name: 'pencil-alt' }}
                        onClick={() => handleOpenEditPanel(event.id, event.roadshowEventType)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Table>
    </CalendarContent>
  );
};

export default CalendarViewList;
