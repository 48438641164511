import { Icon } from '@cmg/common';
import React from 'react';

import { SIconWrapper, SStepWrapper } from './EventNavigationStep.styles';

export type Props<TStep> = {
  label: string;
  id: TStep;
  activeStep: TStep;
  icon: 'file-alt' | 'user-friends' | 'briefcase';
  onChangeStep?: (activeStepId: TStep) => void;
};

const EventNavigationStep = <TStep,>({
  label,
  id,
  activeStep,
  icon,
  onChangeStep,
}: Props<TStep>) => (
  <SStepWrapper title={label} isActive={id === activeStep} onClick={() => onChangeStep?.(id)}>
    <SIconWrapper>
      <Icon size="lg" name={icon} />
    </SIconWrapper>
    {label}
  </SStepWrapper>
);

export default EventNavigationStep;
