import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import { useAccountingContext } from '../common/accounting-context/AccountingContext';
import { SEmptyNotes, SNotes } from './NotesPanel.styles';

const NotesPanel: React.FC = () => {
  const { notes } = useAccountingContext();

  return (
    <Panel>
      <Panel.Header title="Notes" />
      <Panel.Content>
        {!notes && <SEmptyNotes>No notes have been entered</SEmptyNotes>}
        {notes && <SNotes>{notes}</SNotes>}
      </Panel.Content>
    </Panel>
  );
};

export default NotesPanel;
