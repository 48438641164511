import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../common/routing/private-route/PrivateRoute';
import routeFactory from '../../common/util/routeFactory';
import { NoAccessRedirect } from '../core/RootRouter';
import AllRoadshowCompaniesRoute from './overview/all-companies/AllRoadshowCompaniesRoute';
import AllRoadshowsDealTeamsRoute from './overview/all-deal-teams/AllRoadshowsDealTeamsRoute';
import CoordinatorRoadshowOverview from './overview/all-roadshows/roadshow-coordinator/CoordinatorRoadshowOverview';
import SalesAndTradingRoadshowOverview from './overview/all-roadshows/sales-and-trading/SalesAndTradingRoadshowOverview';
import MyOrganizationExistsGuard from './overview/common/my-organization-exists-guard/MyOrganizationExistsGuard';
import RoadshowsCreateDealTeamRoute from './single-deal-team/create-deal-team/RoadshowsCreateDealTeamRoute';
import RoadshowsDealTeamDetailsRoute from './single-deal-team/deal-team-details/RoadshowsDealTeamDetailsRoute';
import RoadshowCoordinatorCalendar from './single-roadshow/roadshow-calendar/roadshow-calendar/roadshow-coordinator-calendar/RoadshowCoordinatorCalendar';
import RoadshowSalesAndTradingCalendar from './single-roadshow/roadshow-calendar/roadshow-calendar/roadshow-sales-and-trading-calendar/RoadshowSalesAndTradingCalendar';
import CreateRoadshowRoute from './single-roadshow/roadshow-configuration/create-roadshow/CreateRoadshowRoute';
import RoadshowConfigurationRoute from './single-roadshow/roadshow-configuration/RoadshowConfigurationRoute';
import { RoadshowStepIds } from './single-roadshow/roadshow-configuration/RoadshowConfigurationRoute.model';

export type Props = {};

const RoadshowsRoute: React.FC<Props> = () => {
  const isRoadshowCoordinator = useCheckPermissions([permissionsByEntity.Roadshow.FULL]);
  const isSalesAndTrading = useCheckPermissions([
    permissionsByEntity.PublishedInternalRoadshow.READ,
  ]);

  return (
    <Switch>
      {isRoadshowCoordinator && (
        <Route
          exact
          path={routeFactory.roadshowsList.routePath}
          render={() => (
            <MyOrganizationExistsGuard>
              <CoordinatorRoadshowOverview />
            </MyOrganizationExistsGuard>
          )}
        />
      )}
      {isSalesAndTrading && (
        <Route
          exact
          path={routeFactory.roadshowsList.routePath}
          render={() => <SalesAndTradingRoadshowOverview />}
        />
      )}

      <PrivateRoute
        exact
        requiredPermissions={[permissionsByEntity.Roadshow.FULL]}
        path={routeFactory.roadshowCreate.routePath}
        renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        render={() => (
          <MyOrganizationExistsGuard>
            <CreateRoadshowRoute />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowDetails.routePath}
        render={routeProps => (
          <MyOrganizationExistsGuard>
            <RoadshowConfigurationRoute
              {...routeProps}
              activeStepId={RoadshowStepIds.ROADSHOW_DETAILS}
            />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowCompanyDetails.routePath}
        render={routeProps => (
          <MyOrganizationExistsGuard>
            <RoadshowConfigurationRoute
              {...routeProps}
              activeStepId={RoadshowStepIds.COMPANY_DETAILS}
            />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowDealTeamDetails.routePath}
        render={routeProps => (
          <MyOrganizationExistsGuard>
            <RoadshowConfigurationRoute {...routeProps} activeStepId={RoadshowStepIds.DEAL_TEAM} />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowsCompanies.routePath}
        render={() => (
          <MyOrganizationExistsGuard>
            <AllRoadshowCompaniesRoute />
          </MyOrganizationExistsGuard>
        )}
      />
      <PrivateRoute
        exact
        path={routeFactory.roadshowsDealTeams.routePath}
        requiredPermissions={[permissionsByEntity.Roadshow.FULL]}
        renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        render={() => (
          <MyOrganizationExistsGuard>
            <AllRoadshowsDealTeamsRoute />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowsCreateDealTeam.routePath}
        render={() => (
          <MyOrganizationExistsGuard>
            <RoadshowsCreateDealTeamRoute />
          </MyOrganizationExistsGuard>
        )}
      />
      <Route
        exact
        path={routeFactory.roadshowsDealTeamDetails.routePath}
        render={routeProps => (
          <MyOrganizationExistsGuard>
            <RoadshowsDealTeamDetailsRoute {...routeProps} />
          </MyOrganizationExistsGuard>
        )}
      />

      {isRoadshowCoordinator && (
        <Route
          path={routeFactory.roadshowCalendar.routePath}
          render={routeProps => (
            <MyOrganizationExistsGuard>
              <RoadshowCoordinatorCalendar roadshowId={routeProps.match.params.roadshowId} />
            </MyOrganizationExistsGuard>
          )}
        />
      )}
      {isSalesAndTrading && (
        <Route
          path={routeFactory.roadshowCalendar.routePath}
          render={routeProps => (
            <RoadshowSalesAndTradingCalendar roadshowId={routeProps.match.params.roadshowId} />
          )}
        />
      )}

      <Route render={() => <Redirect to={routeFactory.roadshowsList.getUrlPath()} />} />
    </Switch>
  );
};

export default RoadshowsRoute;
