import { FlexContainer } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import React from 'react';
import styled from 'styled-components/macro';

export const SWrapper = styled.div<{ withPadding?: boolean; withSecondaryText?: boolean }>`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  padding: ${({ withPadding }) => (withPadding ? '0 8px' : '0')};
  line-height: ${({ withSecondaryText }) => (withSecondaryText ? '16px' : '36px')};
`;

export type Props = Readonly<{
  IconComponent?: React.ReactNode;
  secondaryText?: string;
  isEditable?: boolean;
  testId?: string;
}>;

const TotalCell: React.FC<Props> = ({
  IconComponent,
  secondaryText,
  isEditable,
  testId,
  children,
}) => {
  return (
    <SWrapper withPadding={isEditable} data-test-id={testId} withSecondaryText={!!secondaryText}>
      <FlexContainer direction="row" alignItems="center" fullWidth>
        {IconComponent}
        <FlexContainer direction="column" alignItems="flex-end" fullWidth>
          {children}
          {secondaryText && <Typography variant="caption">{secondaryText}</Typography>}
        </FlexContainer>
      </FlexContainer>
    </SWrapper>
  );
};

export default TotalCell;
