/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemandGrid_UpdateDraftAllocationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  setId: Types.Scalars['String'];
  input: Types.DraftAllocationBatchUpdateInput;
}>;

export type InstitutionalDemandGrid_UpdateDraftAllocationsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDraftInstitutionalAllocations: string;
};

export const InstitutionalDemandGrid_UpdateDraftAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_UpdateDraftAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DraftAllocationBatchUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftInstitutionalAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_UpdateDraftAllocationsMutationFn = Apollo.MutationFunction<
  InstitutionalDemandGrid_UpdateDraftAllocationsMutation,
  InstitutionalDemandGrid_UpdateDraftAllocationsMutationVariables
>;

/**
 * __useInstitutionalDemandGrid_UpdateDraftAllocationsMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_UpdateDraftAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_UpdateDraftAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridUpdateDraftAllocationsMutation, { data, loading, error }] = useInstitutionalDemandGrid_UpdateDraftAllocationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      setId: // value for 'setId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_UpdateDraftAllocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_UpdateDraftAllocationsMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_UpdateDraftAllocationsMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationsMutationVariables
  >(InstitutionalDemandGrid_UpdateDraftAllocationsDocument, options);
}
export type InstitutionalDemandGrid_UpdateDraftAllocationsMutationHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_UpdateDraftAllocationsMutation
>;
export type InstitutionalDemandGrid_UpdateDraftAllocationsMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_UpdateDraftAllocationsMutation>;
export type InstitutionalDemandGrid_UpdateDraftAllocationsMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_UpdateDraftAllocationsMutation,
    InstitutionalDemandGrid_UpdateDraftAllocationsMutationVariables
  >;
