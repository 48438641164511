import { Alert, AlertTitle } from '@cmg/design-system';
import { useIsFormAlertVisible } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { getFormikErrorFieldLabels } from '../../../../../../common/util/useFormikErrors.model';
import { TotalRetailBulkSaveValues, validationSchema } from '../BulkSaveTotalRetailDemand.model';

const InvalidFormAlert: React.FC = () => {
  const { isValid, errors } = useFormikContext<TotalRetailBulkSaveValues>();

  const isValuesNotValidAlertVisible = useIsFormAlertVisible(!isValid);
  const invalidFields = React.useMemo(
    () => getFormikErrorFieldLabels(errors, validationSchema).join(', '),
    [errors]
  );

  if (isValuesNotValidAlertVisible) {
    return (
      <Alert severity="error">
        <AlertTitle>An entry is required to update Total Demand</AlertTitle>
        {invalidFields}
      </Alert>
    );
  }
  return null;
};

export default InvalidFormAlert;
