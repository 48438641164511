import { numericUtil } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';

import { AllocationSetValues, EnteredValueType } from '../SaveAllocationSetDialog.model';

const { sum, negate, getDisplayValueForInteger } = numericUtil;

const TargetRemaining: React.FC = props => {
  const { values } = useFormikContext<AllocationSetValues>();
  const { enteredValueType, offeringSize, institutionalTarget, retailTarget } = values;

  const targetRemaining = React.useMemo(
    () => sum(offeringSize, negate(sum(institutionalTarget, retailTarget))),
    [offeringSize, institutionalTarget, retailTarget]
  );

  if (enteredValueType !== EnteredValueType.SHARES) {
    return null;
  }
  if (isNil(offeringSize)) {
    return null;
  }
  if (targetRemaining === 0) {
    return null;
  }

  return (
    <Typography variant="body2" color="text.secondary" textAlign="right" pt={1}>
      {getDisplayValueForInteger(targetRemaining)} Target Remaining
    </Typography>
  );
};

export default TargetRemaining;
