import { isValidCmgEntityKey } from '../../../../../../../common/util/isValidCmgEntityKey';
import { OrderBookPersonaType } from '../../../../hooks/useIdentifyOrderBookPersona';
import { CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery } from '../../../../indication-activity/components/crm-selection/graphql/__generated__';
import { useGetAllCoveredInvestorFirmsMappedToCmgEntityKey } from '../../../components/crm-selection/hooks/useGetAllCoveredInvestorFirmsMappedToCmgEntityKey';
import { useGetAllInvestorFirmsMappedToCmgEntityKey } from '../../../components/crm-selection/hooks/useGetAllInvestorFirmsMappedToCmgEntityKey';

export type Props = {
  type: OrderBookPersonaType;
  cmgEntityKey: string | undefined;
};

export type InvestorFirmModel = (
  | CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery
  | undefined
) & { loading: boolean };

export const useInvestorFirmModel = ({ type, cmgEntityKey }: Props): InvestorFirmModel => {
  const isCoveredAccount = type === OrderBookPersonaType.SALES_AND_TRADING;

  const { data: allInvestorFirms, loading: allInvestorFirmsLoading } =
    useGetAllInvestorFirmsMappedToCmgEntityKey({
      variables: { cmgEntityKey: cmgEntityKey! },
      skip: !isValidCmgEntityKey(cmgEntityKey) || isCoveredAccount,
    });

  const { data: allCoveredInvestorFirms, loading: allCoveredInvestorFirmsLoading } =
    useGetAllCoveredInvestorFirmsMappedToCmgEntityKey({
      variables: { cmgEntityKey: cmgEntityKey! },
      skip: !isValidCmgEntityKey(cmgEntityKey) || !isCoveredAccount,
    });

  return {
    allInvestorFirmsMappedToCmgEntityKey:
      allInvestorFirms?.allInvestorFirmsMappedToCmgEntityKey ||
      allCoveredInvestorFirms?.allCoveredInvestorFirmsMappedToCmgEntityKey ||
      [],
    loading: allInvestorFirmsLoading || allCoveredInvestorFirmsLoading,
  };
};
