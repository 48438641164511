import { Typography } from '@cmg/design-system';
import { Fragment, VFC } from 'react';

import CurrencyFormatter from '../../../../../../common/components/format/currency-format/CurrencyFormatter';

type Props = {
  readonly currencyCode: string;
  readonly rangeLow?: number | null;
  readonly rangeHigh?: number | null;
};

export const CurrencyRangeValue: VFC<Props> = props => {
  const { currencyCode, rangeLow: priceRangeLow, rangeHigh: priceRangeHigh } = props;

  return (
    <Typography variant="highlight2" color="text.primary" role="cell">
      <Fragment>
        {!!priceRangeLow && <CurrencyFormatter value={priceRangeLow} currency={currencyCode} />}
        <span> - </span>
        {!!priceRangeHigh && <CurrencyFormatter value={priceRangeHigh} currency={currencyCode} />}
      </Fragment>
    </Typography>
  );
};
