import React, { FC, Fragment } from 'react';

import { SidePanelTitle } from '../../components/side-bar/components/design-system';

export const HistoryPanel: FC = props => {
  const { children } = props;
  return (
    <Fragment>
      <SidePanelTitle>Update History</SidePanelTitle>
      {children}
    </Fragment>
  );
};
