import { Alert } from '@cmg/common';
import React from 'react';

import { SHeaderWrapper } from './ValidationErrorBanner.styles';

export type Props = {
  errors: string[];
};

const ValidationErrorBanner: React.FC<Props> = ({ errors }) => {
  return (
    <Alert severity="error" withMargin>
      <SHeaderWrapper>
        An entry is required or has an invalid value for the following fields:
      </SHeaderWrapper>
      {errors.join(', ')}
    </Alert>
  );
};

export default ValidationErrorBanner;
