import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import { Action, Resource } from '../../../graphql';
import { useIsOfferingAuthor } from '../../hooks/useIsOfferingAuthor';
import { useCheckEntitlements } from '../check-entitlements/useCheckEntitlements';

type AccessCheckResult = {
  canManage: boolean;
  canRead: boolean;
  loading: boolean;
};

/**
 * Exported for testing purposes
 */
export const createSyndicateCommunicationAccessCheck =
  (requiredEntitlements: string[]) =>
  (offeringId: UUID | undefined): AccessCheckResult => {
    const isOfferingAuthor = useIsOfferingAuthor(offeringId);
    const { hasEntitlements, loading } = useCheckEntitlements({ offeringId, requiredEntitlements });

    const hasReadPermissions = useCheckPermissions([permissionsByEntity.SyndicateWire.READ]);
    const canRead = hasReadPermissions && (isOfferingAuthor || !!hasEntitlements);

    const hasManagePermissions = useCheckPermissions([permissionsByEntity.SyndicateWire.FULL]);
    const canManage = hasManagePermissions && (isOfferingAuthor || !!hasEntitlements);

    return {
      canRead,
      canManage,
      loading,
    };
  };

/**
 * Check accessibility for syndicate communication Wires
 */
export const useCheckSyndicateCommunicationAccess = createSyndicateCommunicationAccessCheck([
  `${Action.Manage}:${Resource.SyndicateCommunication}`,
]);

/**
 * Check accessibility for non-syndicate communication Wires
 */
export const useCheckNonSyndicateCommunicationAccess = createSyndicateCommunicationAccessCheck([
  `${Action.Manage}:${Resource.NonSyndicateCommunication}`,
]);
