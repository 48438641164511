import { IoiPricingType, IoiUnitType } from '../../../../../../../../../../graphql';

export const pricingTypeLabels = {
  [IoiPricingType.Limit]: 'Limit',
  [IoiPricingType.Market]: 'Market',
};

export const unitTypeLabels = {
  [IoiUnitType.Dollars]: 'Currency',
  [IoiUnitType.Percentage]: 'Percent',
  [IoiUnitType.Shares]: 'Shares',
};

export const pricingTypeOptions = Object.values(IoiPricingType).map(pricingType => ({
  label: pricingTypeLabels[pricingType],
  value: pricingType,
}));

export const unitTypeOptions = Object.values(IoiUnitType).map(unitType => ({
  label: unitTypeLabels[unitType],
  value: unitType,
}));
