import { MutationHookOptions, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_DeleteFilingDocument,
  OfferingSetup_DeleteFilingMutation,
  OfferingSetup_DeleteFilingMutationVariables,
} from '../graphql';

export const refetchQueries = () => {
  const offeringHeaderQuery = getOperationName(Offering_OfferingHeaderDocument);
  return [offeringHeaderQuery!];
};

export const useDeleteFilingMutation = (
  options: MutationHookOptions<
    OfferingSetup_DeleteFilingMutation,
    OfferingSetup_DeleteFilingMutationVariables
  >
) =>
  useMutation<OfferingSetup_DeleteFilingMutation, OfferingSetup_DeleteFilingMutationVariables>(
    OfferingSetup_DeleteFilingDocument,
    { ...options, refetchQueries }
  );
