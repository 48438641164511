import { getOperationName } from '@apollo/client/utilities';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SnackbarManager,
} from '@cmg/design-system';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalDraftSet } from '../../../../types';
import { Props as ShareDialogProps } from '../share-allocation-set-dialog/ShareAllocationSetDialog';
import { useOrderBook_PromoteDraftAllocationSetToDefaultMutation } from './graphql/__generated__';

export type Props = Pick<ShareDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    copiedDraftAllocationSet:
      | InstitutionalDraftSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const CopyToDefaultSetDialog: React.FC<Props> = ({
  copiedDraftAllocationSet,
  offeringId,
  isOpen,
  onClose,
}) => {
  const [copyToDefault, { loading }] = useOrderBook_PromoteDraftAllocationSetToDefaultMutation({
    refetchQueries: [getOperationName(OrderBook_OrderBookRouteDocument)!],
  });

  const handleCopyToDefault = React.useCallback(async () => {
    try {
      await copyToDefault({ variables: { offeringId, setId: copiedDraftAllocationSet?.id! } });
      SnackbarManager.success('Successfully promoted Draft Set to Default Draft Set');
      onClose();
    } catch {
      SnackbarManager.error('Failed to promote to Default Draft Set');
    }
  }, [offeringId, copiedDraftAllocationSet?.id, onClose, copyToDefault]);

  if (!copiedDraftAllocationSet) {
    return null;
  }

  return (
    <Dialog
      title="Copy Draft Allocation Set to Default dialog"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Copy {copiedDraftAllocationSet.name} To Default Allocation Draft</DialogTitle>

      <DialogContent dividers>
        You are about to copy the {copiedDraftAllocationSet.name} to default. You can continue
        modifying allocation after copying. Do you wish to proceed?
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={handleCopyToDefault} loading={loading}>
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyToDefaultSetDialog;
