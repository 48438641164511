import styled from 'styled-components/macro';

export const SPinnedRowText = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SPinnedRowDemandText = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: end;
`;
