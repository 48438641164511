import styled from 'styled-components/macro';

export const SPinnedRowText = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const STotalInvestorsWithDiffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 14px;
  font-size: ${({ theme }) => theme.text.size.tiny};

  & :first-child {
    padding-top: 4px;
    font-size: ${({ theme }) => theme.text.size.medium};
  }
`;
