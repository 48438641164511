import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteTerminationWireMutation,
  SyndicateWires_TerminationWiresDocument,
  useSyndicateWires_DeleteTerminationWireMutation,
} from '../graphql';

export type DeleteTerminationWireMutation = SyndicateWires_DeleteTerminationWireMutation;

export const useDeleteTerminationWireMutation = () => {
  return useSyndicateWires_DeleteTerminationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_TerminationWiresDocument];
  return getManyOperationNames(documentNodes);
}
