import { UUID } from '@cmg/common';
import React from 'react';

import { useIsUserSettlementAgent } from '../../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../graphql/__generated__';

type DraftSets = {
  defaultSection: OrderBook_InstitutionalDemand_AllocationSetPartsFragment[];
  draftSection: OrderBook_InstitutionalDemand_AllocationSetPartsFragment[];
};

export const useSeparateDraftSets = (
  draftAllocationSets: readonly OrderBook_InstitutionalDemand_AllocationSetPartsFragment[],
  offeringId: UUID
) => {
  const { isSettlementAgent, loading, error } = useIsUserSettlementAgent({ offeringId });

  const draftSets = React.useMemo(() => {
    if (isSettlementAgent) {
      return {
        defaultSection: [] as OrderBook_InstitutionalDemand_AllocationSetPartsFragment[],
        draftSection:
          draftAllocationSets as OrderBook_InstitutionalDemand_AllocationSetPartsFragment[],
      };
    }

    return draftAllocationSets.reduce<DraftSets>(
      (result, current) => {
        if (current.isDefault) {
          return { ...result, defaultSection: [...result.defaultSection, current] };
        }
        return { ...result, draftSection: [...result.draftSection, current] };
      },
      {
        defaultSection: [],
        draftSection: [],
      }
    );
  }, [draftAllocationSets, isSettlementAgent]);

  return { isLoadingSettlementAgent: loading, error, draftSets };
};
