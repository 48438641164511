import React from 'react';

import { DesignationRow } from '../designation-monitor-grid/DesignationMonitorGrid.model';

const applyFiltersOnRows = (
  editedRows: DesignationRow[],
  isOutOfBalanceOnlyFilter: boolean,
  isEditedOnlyFilter: boolean,
  investorsFilter: string[]
): DesignationRow[] => {
  return editedRows.filter(row => {
    const outOfBalancePredicate =
      !isOutOfBalanceOnlyFilter || row.shareQuantity !== row.totalDesignations;
    const editedOnlyPredicate = !isEditedOnlyFilter || row.isEdited;

    const investorsPredicate =
      investorsFilter.length <= 0 ||
      investorsFilter.some(investor => investor === row.investorName);

    return outOfBalancePredicate && editedOnlyPredicate && investorsPredicate;
  });
};

export const useFilteredRows = (
  investorRows: DesignationRow[],
  isOutOfBalanceOnlyFilter: boolean,
  isEditedOnlyFilter: boolean,
  investorsFilter: string[]
): DesignationRow[] => {
  return React.useMemo(
    () =>
      applyFiltersOnRows(
        investorRows,
        isOutOfBalanceOnlyFilter,
        isEditedOnlyFilter,
        investorsFilter
      ),
    [investorRows, isOutOfBalanceOnlyFilter, isEditedOnlyFilter, investorsFilter]
  );
};
