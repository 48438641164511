import { Popover } from '@cmg/common';
import React from 'react';

import { StyledComplianceLink } from '../ComplianceCertificates.styles';
import { useDownloadCertificate } from './hooks/useDownloadCertificate';

export type Props = {
  cmgEntityKey: string;
};

const CertificateDownloadButton: React.FC<Props> = ({ cmgEntityKey }) => {
  const { loading, handleDownloadCertificate } = useDownloadCertificate(cmgEntityKey);

  return (
    <Popover content="View certificate" variant="DARK">
      <StyledComplianceLink
        data-test-id="certificate-link"
        disabled={loading}
        onClick={handleDownloadCertificate}
      >
        View
      </StyledComplianceLink>
    </Popover>
  );
};

export default CertificateDownloadButton;
