import React from 'react';

export type Props = {
  categoryFilter: string;
  setCategoryFilter: (value: string) => void;
  descriptionFilter: string;
  setDescriptionFilter: (value: string) => void;
};

const ExpensesFilterContext = React.createContext<Props>({
  categoryFilter: '',
  setCategoryFilter: () => {},
  descriptionFilter: '',
  setDescriptionFilter: () => {},
});

export const useExpensesFilterContext = () => {
  const context = React.useContext(ExpensesFilterContext);

  if (context === undefined) {
    throw new Error('No Provider found for ExpensesFilterContext');
  }

  return context;
};

export const ExpensesFilterProvider: React.FC = ({ children }) => {
  const [categoryFilter, setCategoryFilter] = React.useState('');
  const [descriptionFilter, setDescriptionFilter] = React.useState('');

  return (
    <ExpensesFilterContext.Provider
      value={{ categoryFilter, setCategoryFilter, descriptionFilter, setDescriptionFilter }}
    >
      {children}
    </ExpensesFilterContext.Provider>
  );
};
