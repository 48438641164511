import { Icon, PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SNotesContainer = styled.div`
  margin: 0 16px 16px;
`;

export const SNotesDropdownContainer = styled.div`
  width: 75%;
  margin-left: 12.5%;
`;

export const SNotesFilterContainer = styled.div`
  margin-top: 16px;
  width: 50%;
  margin-bottom: 16px;
`;

export const SAllNotesContainer = styled.div`
  width: 100%;
`;

export const SNoteContainer = styled.div`
  width: 100%;
  padding: 16px 0 16px;
  border-bottom: ${({ theme }) => theme.border.smallSolidLight};
  display: flex;
`;

export const SNoteContainerContent = styled.div`
  width: 95%;
`;

export const SNoteContainerButton = styled.div`
  width: 5%;
  display: block;
  margin: auto;
  text-align: center;
  justify-content: center;
`;

export const StyledNoteEditIcon = styled(Icon)`
  margin: 0 auto;
  height: auto !important;
  display: block;
  width: 16px;
  cursor: pointer;
`;

export const SNoteTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SNoteBody = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  word-wrap: break-word;
  padding-top: 10px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-top: 10px;
`;

export const SNoteAreaFieldContainer = styled.div`
  max-width: 100%;
  display: flex;
`;

export const SNoteLength = styled.div`
  padding: 1px;
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;

export const SNotesButtonGroup = styled.div`
  justify-content: flex-end;
  flex-wrap: nowrap;
  width: 22%;

  > * {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const SNoteSectionTitleHeader = styled.div`
  width: 78%;
  padding: 7px 14px;
`;

export const SNoteSectionTitle = styled.div`
  display: flex;
  width: 100%;
`;

export const SNoteSectionHeader = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 54px;
`;

export const SNotePrimaryButton = styled.button`
  display: inline-block;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.blue2};
  border: ${({ theme }) => theme.color.blue2};
  border-radius: 5px;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-align: center;
  white-space: nowrap;
  line-height: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px;
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SNoteErrorContainer = styled.div`
  margin-top: 10px;
`;
