import * as yup from 'yup';

import { DemandGridDataContext } from '../../demand-grid/types';

export type BulkEnterAllocationValues = {
  allocations: number | null;
  allocationSetSelection: boolean[];
};

export const validationSchema = yup.object({
  allocations: yup.number().min(0).integer().nullable().required().label('Allocations'),
  allocationSetSelection: yup
    .array()
    .of(yup.boolean().required())
    .required()
    .test('test-atLeastOneAllocationSetIsSelected', 'Select at least one Allocation Set', value => {
      return value.some(isSelected => isSelected);
    })
    .label('Allocation Sets'),
});

/**
 * Get the allocation sets that belong to the current user.
 *
 * @param allocationSets
 * @param oidcUserCmgEntityKey
 */
export const getMyAllocationSets = (
  allocationSets: DemandGridDataContext['allocationSets'],
  oidcUserCmgEntityKey: string | null
) => {
  if (!oidcUserCmgEntityKey) {
    return [];
  }

  const { drafts: draftSets, final: finalSet } = allocationSets;
  const myAllocationSets = Object.values(draftSets).filter(
    set => set.author.firmKey === oidcUserCmgEntityKey
  );

  if (finalSet?.author.firmKey === oidcUserCmgEntityKey) {
    return [finalSet, ...myAllocationSets];
  }

  return myAllocationSets;
};
