import type { UUID } from '@cmg/common';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import * as yup from 'yup';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../graphql/__generated__';

export type MarkAsDuplicateFormValues = {
  survivorId: string | null;
  duplicateIds: string[];
};

const getInvalidDuplicates = (
  indications: OrderBook_InstitutionalDemand_GridRowPartsFragment[],
  finalAllocationSetId: UUID | undefined
) => {
  return indications.reduce<Set<UUID>>((acc, indication) => {
    const finalAllocation = indication.allocations?.find(
      ({ allocationSetId }) => allocationSetId === finalAllocationSetId
    );

    if (finalAllocation?.shares || indication.investor.isOnPlatform) {
      acc.add(indication.id);
    }

    return acc;
  }, new Set());
};

export const getValidationSchema = (
  indications: OrderBook_InstitutionalDemand_GridRowPartsFragment[],
  finalAllocationSetId: UUID | undefined
) => {
  const invalidDuplicates = getInvalidDuplicates(indications, finalAllocationSetId);

  return yup.object().shape({
    survivorId: yup.string().nullable().required().label('Surviving indication'),
    duplicateIds: yup
      .array()
      .of(yup.string().required())
      .required()
      .test(
        'canBeMarkedAsDuplicate',
        'Indications with allocations and On platform indications can not be marked as a duplicate.',
        (value, context) => {
          const survivorId = context.parent.survivorId as string | null;

          if (!survivorId) {
            return true;
          }

          return !value.some(id => invalidDuplicates.has(id));
        }
      )
      .label('Duplicate indications'),
  });
};

const getOverlappingCoveringManagerFirmNames = (
  indications: OrderBook_InstitutionalDemand_GridRowPartsFragment[]
) => {
  /**
   * Get all the covering manager names from the selected indications
   */
  const coveringManagerFirmNames = indications.flatMap(
    indication => indication.coveringManagerNames?.split(', ') ?? []
  );

  /**
   * Get firm names that exists more than once in the array
   */
  const overlappingFirmNames = uniq(
    filter(coveringManagerFirmNames, (firmName, index, iteratee) =>
      includes(iteratee, firmName, index + 1)
    )
  );

  if (overlappingFirmNames.length > 1) {
    return overlappingFirmNames.slice(0, -1).join(', ') + ' and ' + overlappingFirmNames.slice(-1);
  }

  return overlappingFirmNames.join('');
};

/**
 * Get warning messages for the selected indications.
 *
 * @param indications - Selected indications
 */
export const getWarningMessages = (
  indications: OrderBook_InstitutionalDemand_GridRowPartsFragment[]
) => {
  const warningMessages: string[] = [];
  const overlappingFirmNames = getOverlappingCoveringManagerFirmNames(indications);
  const interestLevelsNotEqual = indications.some(
    indication => !isEqual(indications[0].interestLevels, indication.interestLevels)
  );

  if (overlappingFirmNames.length) {
    warningMessages.push(
      `You are attempting to mark multiple indications which are covered by ${overlappingFirmNames}. Contact ${overlappingFirmNames} to confirm.`
    );
  }

  if (interestLevelsNotEqual) {
    warningMessages.push('Demand is different for the selected indications.');
  }

  return warningMessages;
};
