import { SelectField } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../../../common/components/layout/panel/Panel';
import { Roadshows_DealTeamNameAndDepartmentsPartsFragment } from '../../../../../common/graphql';
import { Values } from '../CreateDealTeamMembersModal.model';
import { getDealTeamOrganisationOptions } from './DealTeamOrganisationSection.model';

export type Props = {
  organisations: readonly Roadshows_DealTeamNameAndDepartmentsPartsFragment[];
};

const DealTeamOrganisationSection: React.FC<Props> = ({ organisations }) => {
  const { setFieldValue, values } = useFormikContext<Values>();

  const handleOrganisationChange = () => {
    for (let i = 0; i < values.members.length; i++) {
      setFieldValue(`members.${i}.department`, null);
    }
  };

  return (
    <Panel>
      <Panel.Header title="Deal Team Organisation" />
      <Panel.Content paddingSize="MODAL">
        <SelectField
          name="organisationId"
          onChange={handleOrganisationChange}
          options={getDealTeamOrganisationOptions(organisations)}
        />
      </Panel.Content>
    </Panel>
  );
};

export default DealTeamOrganisationSection;
