import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import { useCalendarAccess } from '../../hooks/useCalendarAccess';
import { Calendar_PricedCalendarOfferingsListQuery } from '../graphql';
import { SContainer } from './BuySideAllocationSharesCell.styles';

type Props = {
  mdlAllocation:
    | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]['mdlAllocation']
    | null;
  finalAllocation:
    | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]['finalAllocation']
    | null;
};
const BuySideAllocationSharesCell: React.FC<Props> = ({ mdlAllocation, finalAllocation }) => {
  const { allowMDLAllocation, allowFinalAllocation } = useCalendarAccess();

  if (allowMDLAllocation) {
    const value = !isNil(mdlAllocation?.allocation?.shares)
      ? numericUtil.formatNumber(mdlAllocation?.allocation?.shares, 0)
      : '-';
    return <SContainer>{value}</SContainer>;
  }
  if (allowFinalAllocation) {
    const value = !isNil(finalAllocation?.shareQuantity)
      ? numericUtil.formatNumber(finalAllocation?.shareQuantity, 0)
      : '-';
    return <SContainer>{value}</SContainer>;
  }
  return <div>-</div>;
};

export default BuySideAllocationSharesCell;
