/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateIndicationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  indicationId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type SyndicateIndicationQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly offerPrice?: number | null;
    readonly pricingCurrencyCode: string;
    readonly securityType: Types.SecurityType;
    readonly status: Types.OfferingStatus;
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly institutionalIndication?: {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly status: Types.IndicationStatus;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly investorProspectusDeliveryStatus?: {
          readonly __typename?: 'InvestorDeliveryStatus';
          readonly id: string;
          readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
        } | null;
        readonly finalAllocation?: {
          readonly __typename?: 'FinalAllocation';
          readonly id: string;
          readonly indicationId: string;
          readonly shareQuantity?: number | null;
          readonly version: string;
          readonly investorReply?: {
            readonly __typename?: 'InvestorReply';
            readonly status: Types.AllocationAcknowledgement;
          } | null;
        } | null;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      } | null;
    };
    readonly syndicateEntitlements: {
      readonly __typename?: 'Entitlements';
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
      }>;
    };
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
    }>;
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly orderEntryProfile: {
      readonly __typename?: 'OfferingOrderEntryProfile';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly tranches: ReadonlyArray<{
        readonly __typename?: 'TranchePrivileged';
        readonly id: string;
        readonly defaultInstrumentId?: string | null;
        readonly name: string;
        readonly demandCurrencies: ReadonlyArray<string>;
        readonly deliveryInstrumentIds: ReadonlyArray<string>;
      }>;
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentOrderEntryProfile';
        readonly id: string;
        readonly currencyCode?: string | null;
        readonly countryCode?: string | null;
        readonly stockSymbol?: string | null;
        readonly isDepositaryReceipt: boolean;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
  readonly finalInstitutionalAllocationSet?: {
    readonly __typename?: 'FinalInstitutionalAllocationSet';
    readonly releaseTimestamp?: string | null;
    readonly isReleased: boolean;
    readonly isVisibleToNonSyndicate: boolean;
  } | null;
};

export const SyndicateIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndication' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'cmgEntityKey' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'cmgEntityKey' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'indicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ManageIndication_IndicationParts' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorProspectusDeliveryStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'overallDeliveryStatus' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicateEntitlements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicateEntitlements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalInstitutionalAllocationSet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManageIndication_FinalAllocationSetParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ManageIndicationStatus_IndicationParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndicationStatus_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IndicationForm_OfferingParts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalInstitutionalAllocationSet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationSetParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalInstitutionalAllocationSet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisibleToNonSyndicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateIndicationQuery__
 *
 * To run a query within a React component, call `useSyndicateIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateIndicationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useSyndicateIndicationQuery(
  baseOptions: Apollo.QueryHookOptions<SyndicateIndicationQuery, SyndicateIndicationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyndicateIndicationQuery, SyndicateIndicationQueryVariables>(
    SyndicateIndicationDocument,
    options
  );
}
export function useSyndicateIndicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateIndicationQuery,
    SyndicateIndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyndicateIndicationQuery, SyndicateIndicationQueryVariables>(
    SyndicateIndicationDocument,
    options
  );
}
export type SyndicateIndicationQueryHookResult = ReturnType<typeof useSyndicateIndicationQuery>;
export type SyndicateIndicationLazyQueryHookResult = ReturnType<
  typeof useSyndicateIndicationLazyQuery
>;
export type SyndicateIndicationQueryResult = Apollo.QueryResult<
  SyndicateIndicationQuery,
  SyndicateIndicationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeSyndicateIndicationMockResponse = makeMockResponse<
  SyndicateIndicationQueryVariables,
  SyndicateIndicationQuery
>(SyndicateIndicationDocument);
