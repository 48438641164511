import { LinkButton, ODSTable as Table, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { ActivitiesTableHeader } from '../../common/activity/ActivitiesTableHeader';
import { Activity, TNFilingValues } from '../TNFilingForm.model';
import { ActivitiesTableGroup } from './ActivitiesTableGroup';

export type Props = {
  offeringId: UUID;
  className?: string;
  testId?: string;
  hasNoStabilizationActivities: boolean;
  stabilizationActivitiesLoading: boolean;
};

const ActivityForm: React.FC<Props> = ({
  offeringId,
  className,
  testId,
  hasNoStabilizationActivities,
  stabilizationActivitiesLoading,
}) => {
  const { values, setFieldValue } = useFormikContext<TNFilingValues>();

  function addAtmTrade(arrayHelpers: FieldArrayRenderProps) {
    const activity: Activity = {
      activityType: null,
      date: null,
      aggregateNumberOfShares: null,
    };
    arrayHelpers.push(activity);
  }

  return (
    <div className={className} data-test-id={testId}>
      <FieldArray name="activities">
        {customActivitiesArrayHelpers => (
          <Panel>
            <Panel.Header title="Activities">
              <LinkButton
                inline
                testId={xcSelectors.tnAddActivity.testId}
                onClick={() => addAtmTrade(customActivitiesArrayHelpers)}
                iconLeft={{ name: 'plus' }}
              >
                Add Activity
              </LinkButton>
            </Panel.Header>
            <Panel.Content>
              <Table gridTemplateColumns="260px 130px 200px minmax(200px,  auto)">
                <ActivitiesTableHeader mode="edit" />
                <ActivitiesTableGroup
                  offeringId={offeringId}
                  groupType="custom"
                  activities={values.activities}
                  hasNoStabilizationActivities={false}
                  stabilizationActivitiesLoading={false}
                  onAddActivity={() => addAtmTrade(customActivitiesArrayHelpers)}
                  onRemoveActivity={customActivitiesArrayHelpers.remove}
                  onRemoveAllActivities={() => setFieldValue('activities', [])}
                />
                <FieldArray name="stabilizationActivities">
                  {stabilizationActivitiesArrayHelpers => (
                    <ActivitiesTableGroup
                      offeringId={offeringId}
                      groupType="stabilization"
                      hasNoStabilizationActivities={hasNoStabilizationActivities}
                      stabilizationActivitiesLoading={stabilizationActivitiesLoading}
                      activities={values.stabilizationActivities}
                      onRemoveActivity={stabilizationActivitiesArrayHelpers.remove}
                      onRemoveAllActivities={() => setFieldValue('stabilizationActivities', [])}
                    />
                  )}
                </FieldArray>
              </Table>
            </Panel.Content>
          </Panel>
        )}
      </FieldArray>
    </div>
  );
};

export default ActivityForm;
