/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_CreateBrokerDealerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateMyRetailBrokerInput;
}>;

export type OrderBook_CreateBrokerDealerMutation = {
  readonly __typename?: 'Mutation';
  readonly createMyRetailBroker: {
    readonly __typename?: 'RetailBrokerDealer';
    readonly id: string;
    readonly sellingConcessionPercentage?: number | null;
    readonly firmCmgEntityKey?: string | null;
    readonly firmName: string;
    readonly isOnPlatform: boolean;
    readonly retention: {
      readonly __typename?: 'RetailBrokerDealerRetention';
      readonly id: string;
      readonly shareQuantity?: number | null;
      readonly roundLots?: number | null;
    };
    readonly demand: {
      readonly __typename?: 'RetailBrokerDealerDemand';
      readonly id: string;
      readonly shareQuantity: number;
      readonly roundLots?: number | null;
    };
  };
};

export type OrderBook_DeleteBrokerDealerMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_DeleteBrokerDealerMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteMyRetailBroker: {
    readonly __typename?: 'DeleteMyRetailDemandBroker';
    readonly id: string;
  };
};

export type OrderBook_UpdateBrokerDealerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  id: Types.Scalars['UUID'];
  payload: Types.UpdateMyRetailBrokerInput;
}>;

export type OrderBook_UpdateBrokerDealerMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMyRetailBroker: {
    readonly __typename?: 'RetailBrokerDealer';
    readonly id: string;
    readonly sellingConcessionPercentage?: number | null;
    readonly firmCmgEntityKey?: string | null;
    readonly firmName: string;
    readonly isOnPlatform: boolean;
    readonly retention: {
      readonly __typename?: 'RetailBrokerDealerRetention';
      readonly id: string;
      readonly shareQuantity?: number | null;
      readonly roundLots?: number | null;
    };
    readonly demand: {
      readonly __typename?: 'RetailBrokerDealerDemand';
      readonly id: string;
      readonly shareQuantity: number;
      readonly roundLots?: number | null;
    };
  };
};

export const OrderBook_CreateBrokerDealerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_CreateBrokerDealer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMyRetailBrokerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMyRetailBroker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_CreateBrokerDealerMutationFn = Apollo.MutationFunction<
  OrderBook_CreateBrokerDealerMutation,
  OrderBook_CreateBrokerDealerMutationVariables
>;

/**
 * __useOrderBook_CreateBrokerDealerMutation__
 *
 * To run a mutation, you first call `useOrderBook_CreateBrokerDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_CreateBrokerDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookCreateBrokerDealerMutation, { data, loading, error }] = useOrderBook_CreateBrokerDealerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_CreateBrokerDealerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_CreateBrokerDealerMutation,
    OrderBook_CreateBrokerDealerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_CreateBrokerDealerMutation,
    OrderBook_CreateBrokerDealerMutationVariables
  >(OrderBook_CreateBrokerDealerDocument, options);
}
export type OrderBook_CreateBrokerDealerMutationHookResult = ReturnType<
  typeof useOrderBook_CreateBrokerDealerMutation
>;
export type OrderBook_CreateBrokerDealerMutationResult =
  Apollo.MutationResult<OrderBook_CreateBrokerDealerMutation>;
export type OrderBook_CreateBrokerDealerMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_CreateBrokerDealerMutation,
  OrderBook_CreateBrokerDealerMutationVariables
>;
export const OrderBook_DeleteBrokerDealerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_DeleteBrokerDealer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMyRetailBroker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_DeleteBrokerDealerMutationFn = Apollo.MutationFunction<
  OrderBook_DeleteBrokerDealerMutation,
  OrderBook_DeleteBrokerDealerMutationVariables
>;

/**
 * __useOrderBook_DeleteBrokerDealerMutation__
 *
 * To run a mutation, you first call `useOrderBook_DeleteBrokerDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_DeleteBrokerDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookDeleteBrokerDealerMutation, { data, loading, error }] = useOrderBook_DeleteBrokerDealerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_DeleteBrokerDealerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_DeleteBrokerDealerMutation,
    OrderBook_DeleteBrokerDealerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_DeleteBrokerDealerMutation,
    OrderBook_DeleteBrokerDealerMutationVariables
  >(OrderBook_DeleteBrokerDealerDocument, options);
}
export type OrderBook_DeleteBrokerDealerMutationHookResult = ReturnType<
  typeof useOrderBook_DeleteBrokerDealerMutation
>;
export type OrderBook_DeleteBrokerDealerMutationResult =
  Apollo.MutationResult<OrderBook_DeleteBrokerDealerMutation>;
export type OrderBook_DeleteBrokerDealerMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_DeleteBrokerDealerMutation,
  OrderBook_DeleteBrokerDealerMutationVariables
>;
export const OrderBook_UpdateBrokerDealerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateBrokerDealer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMyRetailBrokerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyRetailBroker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateBrokerDealerMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateBrokerDealerMutation,
  OrderBook_UpdateBrokerDealerMutationVariables
>;

/**
 * __useOrderBook_UpdateBrokerDealerMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateBrokerDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateBrokerDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateBrokerDealerMutation, { data, loading, error }] = useOrderBook_UpdateBrokerDealerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_UpdateBrokerDealerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateBrokerDealerMutation,
    OrderBook_UpdateBrokerDealerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateBrokerDealerMutation,
    OrderBook_UpdateBrokerDealerMutationVariables
  >(OrderBook_UpdateBrokerDealerDocument, options);
}
export type OrderBook_UpdateBrokerDealerMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateBrokerDealerMutation
>;
export type OrderBook_UpdateBrokerDealerMutationResult =
  Apollo.MutationResult<OrderBook_UpdateBrokerDealerMutation>;
export type OrderBook_UpdateBrokerDealerMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_UpdateBrokerDealerMutation,
  OrderBook_UpdateBrokerDealerMutationVariables
>;
