import { compareAsc } from 'date-fns';

type ComparatorFn = (lhs: string, rhs: string) => number;

/**
 * sortByDateComparator takes an orderDirection as an argument and returns a comparator function
 * to order by date and keep "null" values on top
 */
const sortByDateComparator =
  (orderDirection: 'asc' | 'desc'): ComparatorFn =>
  (lhs: string, rhs: string): number => {
    // null should always be placed first
    if (lhs === 'null') {
      return -1;
    }
    if (rhs === 'null') {
      return 1;
    }

    const lhsDate = new Date(lhs);
    const rhsDate = new Date(rhs);

    return orderDirection === 'asc' ? compareAsc(lhsDate, rhsDate) : compareAsc(rhsDate, lhsDate);
  };

export default sortByDateComparator;
