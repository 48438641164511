import { Icon, UUID } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';

import tradeReleaseZeroState from '../../../common/assets/trade-release-zero-state.svg';
import routeFactory from '../../../common/util/routeFactory';
import ReleaseSettings from '../common/release-settings/ReleaseSettings';
import {
  SSettingsWrapper,
  SSpacer,
  SSubtitle,
  STitle,
  SWrapper,
} from './TradeReleaseZeroState.styles';

export type Props = {
  offeringId: UUID;
  offeringIssuerName?: string;
};

export const TradeReleaseZeroState: React.FC<Props> = ({ offeringId, offeringIssuerName }) => {
  const theme = useTheme();

  return (
    <SWrapper>
      <SSettingsWrapper>
        <ReleaseSettings offeringId={offeringId} />
      </SSettingsWrapper>
      <SSpacer />
      <STitle>{`${offeringIssuerName} - Trade Release needs additional actions`}</STitle>
      <SSubtitle>
        <Icon name="check-circle" variant="solid" color={theme.designSystem.colors.gray['300']} />
        <Link
          data-test-id="trade-release-orderbook-link"
          to={routeFactory.orderBook.getUrlPath({ offeringId })}
          target="_blank"
        >
          Release Final Allocations in Order Book
        </Link>
      </SSubtitle>
      <img alt="Trade Release zero state" src={tradeReleaseZeroState} />
    </SWrapper>
  );
};
