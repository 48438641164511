import { numericUtil } from '@cmg/common';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';
import { getTotal } from '../../common/accounting-data-grid/AccountingDataGrid.model';

export const getTotalRevenue = (accounting: FinalSettlement_AccountingPartsFragment) => {
  const { baseGrossSpreadValue, incentiveFeeValue, excludedShares } = accounting;
  const totalGrossSpread = numericUtil.sum(baseGrossSpreadValue, incentiveFeeValue);

  const totalShares = numericUtil.sum(
    getTotal(accounting.managers, 'baseUnderwritingShares'),
    getTotal(accounting.managers, 'overallotmentUnderwritingShares')
  );

  const totalSharesSpread = numericUtil.multiply(totalShares, totalGrossSpread);
  const excludedSharesSpread = numericUtil.multiply(excludedShares, totalGrossSpread);

  return numericUtil.sum(
    totalSharesSpread,
    excludedSharesSpread === null ? null : -excludedSharesSpread
  );
};

export const calculateTotalRevenueValues = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const { dealRelatedExpenses, stabilizationGainLoss } = accounting;

  const totalRevenue = getTotalRevenue(accounting);
  const netDealFees = numericUtil.sum(
    totalRevenue,
    numericUtil.negate(dealRelatedExpenses),
    stabilizationGainLoss
  );

  return { totalRevenue, netDealFees };
};
