import { Popover, PrimaryButton, ToastManager, UUID } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import { FilingType } from '../../../graphql';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useOfferinSetup_UnderwritingDiscountsFees_TermsQuery } from '../graphql';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import { GrossSpreadAllocationForm } from './GrossSpreadAllocationForm';
import { useUndewritingFeesAndDiscountsMutations } from './hooks/useUndewritingFeesAndDiscountsMutations';
import { OtherUnderwritingFeesDiscountsForm } from './OtherUnderwritingFeesDiscountsForm';
import { UnderwritingDiscountForm } from './UnderwritingDiscountForm';
import {
  getInitialValues,
  getUnderwritingFeesAndDiscountsFormSchema,
  UndewritingFeesAndDiscountFormValues,
} from './UnderwritingDiscountsAndFeesRoute.model';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

export const UnderwritingDiscountsAndFeesRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const { revalidate } = useValidateOffering(offeringId);

  const {
    handleSubmit: handleUpdateUWDDisccountsAndFees,
    loading: loadingUndewritingFeesAndDiscounts,
    errors,
    underwritingFeesDiscounts,
  } = useUndewritingFeesAndDiscountsMutations({ offeringId });

  const { data: termsData, loading: loadingTerms } =
    useOfferinSetup_UnderwritingDiscountsFees_TermsQuery({
      variables: { offeringId },
    });

  const { offerPrice } = React.useMemo(() => {
    return {
      offerPrice:
        termsData?.offering.filings.find(filing => filing.filingType === FilingType.Final)
          ?.offerPrice ?? null,
    };
  }, [termsData]);

  const pricingCurrencyCode = termsData?.offering.pricingCurrencyCode;

  const handleSubmit = async (values: UndewritingFeesAndDiscountFormValues) => {
    try {
      await handleUpdateUWDDisccountsAndFees(values);
      ToastManager.success('Discounts & Fees Updated');
      await revalidate();
    } catch {
      ToastManager.error('Failed to Update Discounts & Fees');
      throw new Error('Failed to Update Discounts & Fees');
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: getUnderwritingFeesAndDiscountsFormSchema({
      offerPrice: offerPrice ?? Infinity,
    }),
    onSubmit: handleSubmit,
    initialValues: getInitialValues(underwritingFeesDiscounts),
  });

  const loading = loadingUndewritingFeesAndDiscounts || loadingTerms;

  const popoverParams = React.useMemo(() => {
    let content = '';
    if (!formik.isValid) {
      content = 'Unable to save data. Please review errors below.';
    } else if (!formik.dirty) {
      content = 'No new changes to save.';
    }
    return {
      content,
      disabled: loading || (formik.isValid && formik.dirty) || formik.isSubmitting,
    };
  }, [loading, formik.isValid, formik.dirty, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard>
        <OfferingSetupPage offeringId={offeringId} negativeMargin>
          <SetupScreen.Panel fillViewport>
            <SetupScreen.Header
              rightContent={
                <Popover
                  content={popoverParams.content}
                  disabled={popoverParams.disabled}
                  variant="TOOLTIP"
                  placement="topLeft"
                  trigger="hover"
                >
                  <div>
                    <PrimaryButton
                      loading={loading}
                      disabled={loading || !formik.isValid || formik.isSubmitting || !formik.dirty}
                      onClick={() => formik.handleSubmit()}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Popover>
              }
            />
            {errors &&
              errors.map(error => error && <ServerErrorsBanner error={error} key={error.name} />)}
            <FormikProvider value={formik}>
              <UnderwritingDiscountForm
                underwritingFeesDiscounts={underwritingFeesDiscounts}
                offerPrice={offerPrice}
                pricingCurrencyCode={pricingCurrencyCode}
              />
              <GrossSpreadAllocationForm pricingCurrencyCode={pricingCurrencyCode} />
              <OtherUnderwritingFeesDiscountsForm pricingCurrencyCode={pricingCurrencyCode} />
            </FormikProvider>
          </SetupScreen.Panel>
        </OfferingSetupPage>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};
