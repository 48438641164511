/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CreateDealTeamMutationVariables = Types.Exact<{
  payload: Types.CreateDealTeamInput;
}>;

export type Roadshows_CreateDealTeamMutation = {
  readonly __typename?: 'Mutation';
  readonly createDealTeam: {
    readonly __typename?: 'DealTeam';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly departments: ReadonlyArray<string>;
    readonly participatedRoadshowNames: ReadonlyArray<string>;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'DealTeamMember';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly mobilePhone?: string | null;
      readonly officePhone?: string | null;
      readonly department?: string | null;
      readonly type: Types.DealTeamMemberType;
      readonly status: Types.DealTeamMemberStatus;
    }>;
  };
};

export const Roadshows_CreateDealTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CreateDealTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDealTeamInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDealTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CreateDealTeamMutationFn = Apollo.MutationFunction<
  Roadshows_CreateDealTeamMutation,
  Roadshows_CreateDealTeamMutationVariables
>;

/**
 * __useRoadshows_CreateDealTeamMutation__
 *
 * To run a mutation, you first call `useRoadshows_CreateDealTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CreateDealTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCreateDealTeamMutation, { data, loading, error }] = useRoadshows_CreateDealTeamMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_CreateDealTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CreateDealTeamMutation,
    Roadshows_CreateDealTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CreateDealTeamMutation,
    Roadshows_CreateDealTeamMutationVariables
  >(Roadshows_CreateDealTeamDocument, options);
}
export type Roadshows_CreateDealTeamMutationHookResult = ReturnType<
  typeof useRoadshows_CreateDealTeamMutation
>;
export type Roadshows_CreateDealTeamMutationResult =
  Apollo.MutationResult<Roadshows_CreateDealTeamMutation>;
export type Roadshows_CreateDealTeamMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_CreateDealTeamMutation,
  Roadshows_CreateDealTeamMutationVariables
>;
