import type { UUID } from '@cmg/common';
import { numericUtil } from '@cmg/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSkeleton,
  Typography,
} from '@cmg/design-system';
import has from 'lodash/has';
import React, { useCallback, useMemo } from 'react';

import type { DemandGridDataContext } from '../demand-grid/types';
import { useGetFinalAllocationsToUpdate } from '../hooks/useGetFinalAllocationsToUpdate';

export type OnSaveFinalAllocationChangesFn = (
  changedAllocations: { indicationId: UUID; finalAllocationShares: number | null }[]
) => void;

export type Props = Readonly<{
  isOpen: boolean;
  offeringId: UUID;
  finalAllocationSetId: UUID | undefined;
  explicitChanges: DemandGridDataContext['explicitChanges'] | undefined;
  onClose: () => void;
  onDiscardChanges: () => void;
  onSaveChanges: OnSaveFinalAllocationChangesFn;
}>;

export const UpdateReleasedFinalAllocationDialog: React.FC<Props> = ({
  offeringId,
  finalAllocationSetId,
  explicitChanges,
  onDiscardChanges,
  onSaveChanges,
  isOpen,
  onClose,
}) => {
  const { getFinalAllocations, finalAllocations, loading } = useGetFinalAllocationsToUpdate({
    offeringId,
    finalAllocationSetId,
  });

  const changedIndications = useMemo(() => {
    if (!finalAllocationSetId) {
      return [];
    }

    return Object.entries(explicitChanges ?? {}).filter(([, value]) =>
      has(value.allocations, finalAllocationSetId)
    );
  }, [explicitChanges, finalAllocationSetId]);

  const newTotal = useMemo(
    () =>
      changedIndications.reduce<number | null>((acc, [, value]) => {
        const finalAllocationShares = value.allocations[finalAllocationSetId ?? ''];
        return finalAllocationShares === null ? acc : (acc ?? 0) + finalAllocationShares;
      }, null),
    [changedIndications, finalAllocationSetId]
  );

  const oldTotal = useMemo(
    () =>
      finalAllocations.reduce<number | null>((acc, { finalAllocationShares }) => {
        return finalAllocationShares === null ? acc : (acc ?? 0) + finalAllocationShares;
      }, null),
    [finalAllocations]
  );

  React.useEffect(() => {
    if (changedIndications.length) {
      void getFinalAllocations(changedIndications.map(([id]) => id));
    }
  }, [changedIndications, getFinalAllocations]);

  const handleDiscardChanges = useCallback(() => {
    onDiscardChanges();
    onClose();
  }, [onClose, onDiscardChanges]);

  const handleSaveChanges = useCallback(() => {
    if (!finalAllocationSetId) {
      return;
    }

    const changedAllocations = changedIndications.map(([id, value]) => ({
      indicationId: id,
      finalAllocationShares: value.allocations[finalAllocationSetId],
    }));

    onSaveChanges(changedAllocations);
    onClose();
  }, [changedIndications, finalAllocationSetId, onClose, onSaveChanges]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Final Allocations</DialogTitle>
      <DialogContent dividers>
        <Typography>
          You are about to make the following Final Institutional Allocations Updates:
        </Typography>
        <TableContainer sx={{ maxHeight: 440, padding: theme => `${theme.spacing(2)} 0` }}>
          {loading ? (
            <TableSkeleton numOfColumns={3} numOfRows={changedIndications.length} />
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Investor (CRM)</TableCell>
                  <TableCell>Change From</TableCell>
                  <TableCell>Change To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalAllocations.map(row => (
                  <TableRow key={row.indicationId}>
                    <TableCell>{row.investorDisplayName}</TableCell>
                    <TableCell align="right">
                      {numericUtil.getDisplayValueForInteger(row.finalAllocationShares)}
                    </TableCell>
                    <TableCell align="right">
                      {numericUtil.getDisplayValueForInteger(
                        explicitChanges?.[row.indicationId]?.allocations[finalAllocationSetId ?? '']
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">Total</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {numericUtil.getDisplayValueForInteger(oldTotal)}
                  </TableCell>
                  <TableCell align="right">
                    {numericUtil.getDisplayValueForInteger(newTotal)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Typography>
          These changes will be communicated to the affected investors and all the underwriters that
          final allocations have been shared with.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDiscardChanges}>
          Discard Changes
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Continue Editing
        </Button>
        <Button variant="contained" onClick={handleSaveChanges}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
