import styled from 'styled-components/macro';

export const SHeader = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 10px 15px;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SContent = styled.div`
  padding: 20px 10px;
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.light};
`;
