import { FormField, Icon, TextInput, ToastManager } from '@cmg/common';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_TIMEOUT } from '../../../../../../constants';
import { SIconWrapper, SWrapper } from './CompanySearchField.styles';
import {
  Roadshows_CompanySearchPartsFragment,
  useRoadshows_CompaniesSearchLazyQuery,
} from './graphql';

export type Props = {
  onSetCompanies: (companies: readonly Roadshows_CompanySearchPartsFragment[]) => void;
  onSetLoading: (isLoading: boolean) => void;
  onChange?: (value: string) => void;
};

const CompanySearchField: React.FC<Props> = ({ onSetCompanies, onSetLoading, onChange }) => {
  const [getCompaniesQuery] = useRoadshows_CompaniesSearchLazyQuery();

  const handleOnCompanyChange = useDebouncedCallback(async (companyName: string) => {
    try {
      const { data } = await getCompaniesQuery({
        variables: {
          filter: { searchText: companyName },
          pagination: { page: 1, perPage: 50 },
        },
      });

      onSetLoading(false);
      onSetCompanies(data?.companySearch.data ?? []);
    } catch {
      ToastManager.error('Error occurred when fetching Companies');
    }
  }, DEBOUNCE_TIMEOUT);

  return (
    <SWrapper>
      <FormField label="Search Company" fullWidth>
        <TextInput
          testId="company-search-input"
          onChange={value => {
            onChange?.(value);
            if (!value) {
              onSetCompanies([]);
              return;
            }
            onSetLoading(true);
            handleOnCompanyChange(value);
          }}
          suffix={
            <SIconWrapper>
              <Icon name="search" />
            </SIconWrapper>
          }
        />
      </FormField>
    </SWrapper>
  );
};

export default CompanySearchField;
