import { Icon, Stack, TableCell, TableCellProps } from '@cmg/design-system';
import React from 'react';

import cmgLogo from '../../../../common/assets/identity-icon.svg';

export type Props = Readonly<{
  isOnPlatform: boolean;
}> &
  TableCellProps;

const TableCellOnPlatformIcon: React.FC<Props> = ({ isOnPlatform, ...rest }) => {
  return (
    <TableCell {...rest}>
      {isOnPlatform && (
        <Stack alignItems="center">
          <Icon fontSize="small">
            <img src={cmgLogo} alt="Domain Logo" width="100%" />
          </Icon>
        </Stack>
      )}
    </TableCell>
  );
};

export default TableCellOnPlatformIcon;
