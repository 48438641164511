import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Banner from '../../../common/components/indicators/banner/Banner';
import { SidePanelTitle } from '../components/side-bar/components/design-system';

export type Props = RouteComponentProps;

const RoadshowRoute: React.FC<Props> = () => {
  return (
    <div>
      <SidePanelTitle>Roadshow</SidePanelTitle>
      <Banner variant="warning">
        Roadshows section is under construction and is subjected to change.
      </Banner>
      RoadshowRoute
    </div>
  );
};

export default RoadshowRoute;
