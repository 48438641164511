import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { InterestLevelInput } from '../../../../../../../../../graphql';
import { Calendar_LiveCalendarOfferingsListDocument } from '../../../../../../../../calendar/live-calendar/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { IndicationFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useManageIndication_UpdateMyIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  // After an indication is modified,
  // we need to refetch the live calendar to reflect the changes in the demand column
  getOperationName(Calendar_LiveCalendarOfferingsListDocument)!,
];

export const useUpdateMyIndicationMutation = (offeringId: UUID, onFinishEditing: () => void) => {
  const [updateMyIndication] = useManageIndication_UpdateMyIndicationMutation();

  const handleUpdateMyIndication = React.useCallback(
    async (values: IndicationFormValues) => {
      try {
        await updateMyIndication({
          variables: {
            offeringId,
            input: {
              trancheId: values.trancheId!,
              currencyCode: values.currencyCode,
              instrumentId: values.instrumentId,
              interestLevels: values.interestLevels.map<InterestLevelInput>(
                ({ interestQuantity, interestUnit, limitPrice, limitType }) => ({
                  interestUnit: interestUnit!,
                  interestQuantity: interestQuantity!,
                  limitType,
                  limitPrice,
                })
              ),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries,
        });

        SnackbarManager.success('Successfully updated Indication');
        onFinishEditing();
        pendingOrderBookGridForcedRefreshVar(true);
      } catch {
        SnackbarManager.error('Failed to update Indication');
      }
    },
    [offeringId, onFinishEditing, updateMyIndication]
  );

  return [handleUpdateMyIndication];
};
