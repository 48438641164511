import { Alert, AlertTitle } from '@cmg/design-system';
import React from 'react';
import { Link } from 'react-router-dom';

import { useFeatureToggles } from '../../../../../common/config';
import { OfferingErrorCategory } from '../../../../../graphql';
import { stepIds } from '../../../constants';
import { ValidationErrorsByCategory } from '../../../validation/hooks/useValidateOffering';
import {
  createGetPath,
  extractErrorEntries,
  validationErrorCategoryLabels,
} from '../../../validation/hooks/useValidateOffering.model';

export type Props = {
  offeringId: string;
  errors: ValidationErrorsByCategory;
};

const ValidationErrorsAlert: React.FC<Props> = ({ offeringId, errors }) => {
  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();
  const entries = React.useMemo(() => extractErrorEntries(errors), [errors]);

  const getErrorCategoryLabel = (category: string) => {
    if (
      isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn &&
      category === OfferingErrorCategory.FilingsAndRevisions
    ) {
      return 'Offering Terms';
    }

    return validationErrorCategoryLabels[category].label;
  };

  const getErrorCategoryRoutePath = (category: string) => {
    if (
      isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn &&
      category === OfferingErrorCategory.FilingsAndRevisions
    ) {
      return createGetPath(stepIds.OFFERING_TERMS)(offeringId);
    }

    return validationErrorCategoryLabels[category].getPath(offeringId);
  };

  return (
    <Alert severity="error">
      <AlertTitle>
        Unable to publish Offering. Please review the errors below for each section.
      </AlertTitle>
      {entries.map(([category, errorItems]) => (
        <div key={category}>
          <Link to={getErrorCategoryRoutePath(category)}>
            {getErrorCategoryLabel(category)} ({errorItems.length}){': '}
          </Link>
          {errorItems.map(item => item.description).join(', ')}
        </div>
      ))}
    </Alert>
  );
};

export default ValidationErrorsAlert;
