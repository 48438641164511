import { LinkButton } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import { NotesFilters } from './NotesModel.util';
import {
  SNoteBody,
  SNoteContainer,
  SNoteContainerButton,
  SNoteContainerContent,
  SNoteTitle,
  StyledNoteEditIcon,
} from './NotesRoute.styles';

export type Props = {
  text: string;
  title: string;
  filterType: NotesFilters;
  onChange: (filterType: NotesFilters) => void;
};

const Note: React.FC<Props> = ({ text, title, filterType, onChange }) => {
  const theme = useTheme();

  const { isObsoleteOffering } = useSupersededOfferingContext();

  return (
    <SNoteContainer>
      <SNoteContainerContent>
        <SNoteTitle>{title}</SNoteTitle>
        <SNoteBody>{text}</SNoteBody>
      </SNoteContainerContent>
      <SNoteContainerButton>
        <LinkButton disabled={isObsoleteOffering} onClick={() => onChange(filterType)}>
          <StyledNoteEditIcon name="pencil-alt" color={theme.designSystem.colors.blue['300']} />
        </LinkButton>
      </SNoteContainerButton>
    </SNoteContainer>
  );
};

export default Note;
