import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import {
  StyledInvestorRadio,
  StyledTableTextCell,
} from '../SalesCreditsBankInvestorKeyTable.styles';

export type Props = {
  investorName: string;
  investorFirmKey: string | null | undefined;
  investorAddress: string | null | undefined;
  onSelectInvestor: () => void;
  isSelected: boolean;
};

const InvestorRow: React.FC<Props> = ({
  investorName,
  investorFirmKey,
  investorAddress,
  onSelectInvestor,
  isSelected,
}) => {
  return (
    <Table.Row suppressHighlightingOnHover isFocused={isSelected} onClick={onSelectInvestor}>
      <Table.Cell>
        <StyledInvestorRadio onChange={onSelectInvestor} checked={isSelected} />
      </Table.Cell>
      <StyledTableTextCell>{investorName}</StyledTableTextCell>
      <StyledTableTextCell>{investorFirmKey || '-'}</StyledTableTextCell>
      <StyledTableTextCell>{investorAddress || '-'}</StyledTableTextCell>
    </Table.Row>
  );
};

export default InvestorRow;
