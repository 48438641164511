import React from 'react';

import {
  SHeading,
  SOrderBookNotOpenContainer,
  StyledOrderBookNotOpenImage,
} from './OrderBookNotOpen.styles';

type Props = {
  issuerName: string;
};

const OrderBookNotOpen: React.FC<Props> = ({ issuerName }) => {
  return (
    <SOrderBookNotOpenContainer>
      <SHeading>{issuerName} - Order Book Has Not Been Opened Yet</SHeading>
      It might take a few seconds for the order book to open once the offering is published
      <StyledOrderBookNotOpenImage />
    </SOrderBookNotOpenContainer>
  );
};

export default OrderBookNotOpen;
