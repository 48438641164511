import { Alert, AlertTitle, Button } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  readonly refetch: () => void;
}>;

export const ErrorLoadingRecipientsAlert: React.FC<Props> = ({ refetch }) => {
  return (
    <Alert
      title="There was an error loading information specific for each recipient."
      severity="warning"
      action={
        <Button color="inherit" onClick={refetch}>
          Refresh Data
        </Button>
      }
    >
      <AlertTitle>There was an error loading information specific for each recipient.</AlertTitle>
      In order to continue creating the wire, refresh the data.
    </Alert>
  );
};
