import { errorUtil, loggerUtil } from '@cmg/common';

export const webApi = {
  getVAPID: async ({
    host,
    accessToken,
  }: {
    host: string;
    accessToken: string | null;
  }): Promise<{ publicKey: string } | null> => {
    try {
      const response = await fetch(new URL('/api/v1/pushSubscriptions/vapid', host), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GET /pushSubscriptions/vapid response was not OK with ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(error);
      return null;
    }
  },
  getSubscription: async ({
    endpoint,
    host,
    accessToken,
  }: {
    endpoint: string;
    host: string;
    accessToken: string | null;
  }): Promise<PushSubscription | null> => {
    try {
      const response = await fetch(
        new URL(
          `/api/v1/pushSubscriptions?${new URLSearchParams({
            endpoint,
          })}`,
          host
        ),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GET /pushSubscriptions response was not OK with ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(error);
      return null;
    }
  },
  postSubscription: async ({
    subscription,
    host,
    accessToken,
  }: {
    subscription: PushSubscription | null;
    host: string;
    accessToken: string | null;
  }): Promise<PushSubscription | null> => {
    if (!subscription) return subscription;

    try {
      await fetch(new URL('/api/v1/pushSubscriptions', host), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(subscription.toJSON()),
      });
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(error);
    }

    return subscription;
  },
  deleteSubscription: async ({
    subscription,
    host,
    accessToken,
  }: {
    subscription: PushSubscription | null;
    host: string;
    accessToken: string | null;
  }): Promise<PushSubscription | undefined | null> => {
    if (!subscription) return subscription;

    const { endpoint } = subscription;

    try {
      await fetch(
        new URL(
          `/api/v1/pushSubscriptions?${new URLSearchParams({
            endpoint,
          })}`,
          host
        ),
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(error);
    }

    return undefined;
  },
  send: async ({
    host,
    accessToken,
  }: {
    host: string;
    accessToken: string | null;
  }): Promise<void> => {
    try {
      await fetch(new URL('/api/v1/pushSubscriptions/send', host), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ title: '🚀 Blast off! This is a test launch.' }),
      });
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(error);
    }
  },
};
