import { ColDefCalendarGrid } from '../calendar.model';
import {
  calendarGridColumns,
  CalendarGridOrderField,
} from '../components/calendar-grid/CalendarGridColumns';
import { useInternationalColumns } from '../components/calendar-grid/hooks/useInternationalColumns';
import TodayTag from '../components/TodayTag';
import { Calendar_LiveCalendarOfferingsListQuery } from './graphql';

// We need a little workaround to be able to use CalendarGridOffering fields here.
const calendarGridColumnsTypeFix = calendarGridColumns as {
  [key in keyof typeof calendarGridColumns]: ColDefCalendarGrid<
    Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
  >;
};

export const useSellSideColumns = (): ColDefCalendarGrid<
  Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
>[] => {
  const { regionAndCountryColumns, sizeInCurrencyColumn, priceRangeColumn, pricingDateColumn } =
    useInternationalColumns<
      Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]
    >();

  return [
    calendarGridColumnsTypeFix[CalendarGridOrderField.IS_EXECUTED_ON_PLATFORM],
    {
      ...calendarGridColumnsTypeFix[CalendarGridOrderField.ISSUER_NAME],
      /** cellRendererParams provides params to the cellRendererFramework while honoring
       * the reactContainer style overrides in the IssuerNameRenderer
       */
      cellRendererParams: ({ data }) => ({
        children: <TodayTag launchDate={data.launchDate} />,
      }),
    },
    calendarGridColumnsTypeFix[CalendarGridOrderField.SYMBOL],
    ...regionAndCountryColumns,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECTOR],
    calendarGridColumnsTypeFix[CalendarGridOrderField.TYPE],
    calendarGridColumnsTypeFix[CalendarGridOrderField.SECURITY_TYPE],
    pricingDateColumn,
    sizeInCurrencyColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.SIZE_IN_SHARES],
    priceRangeColumn,
    calendarGridColumnsTypeFix[CalendarGridOrderField.LEFT_LEAD],
  ];
};
