/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CoveredRoadshowDetailsQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_CoveredRoadshowDetailsQuery = {
  readonly __typename?: 'Query';
  readonly coveredRoadshow: {
    readonly __typename?: 'Roadshow';
    readonly endDate: string;
    readonly id: string;
    readonly offeringId?: string | null;
    readonly offeringIssuerName?: string | null;
    readonly roadshowName: string;
    readonly roadshowType: Types.RoadshowType;
    readonly startDate: string;
    readonly status: Types.RoadshowStatus;
    readonly companyName?: string | null;
    readonly companyId?: string | null;
    readonly defaultTimeZone?: string | null;
    readonly roadshowDays: ReadonlyArray<{
      readonly __typename?: 'RoadshowDay';
      readonly date: string;
      readonly timeZone?: string | null;
      readonly isClosed: boolean;
      readonly isVirtual: boolean;
      readonly cities: ReadonlyArray<{
        readonly __typename?: 'City';
        readonly name: string;
        readonly timeZone: string;
      }>;
    }>;
  };
};

export type Roadshows_CoveredRoadshowEventsQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_CoveredRoadshowEventsQuery = {
  readonly __typename?: 'Query';
  readonly coveredRoadshowEvents: ReadonlyArray<{
    readonly __typename?: 'RoadshowEvent';
    readonly id: string;
    readonly endTime: string;
    readonly endDate: string;
    readonly hostFirm?: string | null;
    readonly hostName?: string | null;
    readonly location?: string | null;
    readonly publicNotes?: string | null;
    readonly roadshowEventType: Types.RoadshowEventType;
    readonly startTime: string;
    readonly startDate: string;
    readonly status?: Types.EventStatus | null;
    readonly title: string;
    readonly type?: Types.MeetingType | null;
    readonly participantTeams: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
  }>;
};

export const Roadshows_CoveredRoadshowDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CoveredRoadshowDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredRoadshow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Roadshow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVirtual' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CoveredRoadshowDetailsQuery__
 *
 * To run a query within a React component, call `useRoadshows_CoveredRoadshowDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CoveredRoadshowDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CoveredRoadshowDetailsQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_CoveredRoadshowDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CoveredRoadshowDetailsQuery,
    Roadshows_CoveredRoadshowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_CoveredRoadshowDetailsQuery,
    Roadshows_CoveredRoadshowDetailsQueryVariables
  >(Roadshows_CoveredRoadshowDetailsDocument, options);
}
export function useRoadshows_CoveredRoadshowDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CoveredRoadshowDetailsQuery,
    Roadshows_CoveredRoadshowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_CoveredRoadshowDetailsQuery,
    Roadshows_CoveredRoadshowDetailsQueryVariables
  >(Roadshows_CoveredRoadshowDetailsDocument, options);
}
export type Roadshows_CoveredRoadshowDetailsQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredRoadshowDetailsQuery
>;
export type Roadshows_CoveredRoadshowDetailsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredRoadshowDetailsLazyQuery
>;
export type Roadshows_CoveredRoadshowDetailsQueryResult = Apollo.QueryResult<
  Roadshows_CoveredRoadshowDetailsQuery,
  Roadshows_CoveredRoadshowDetailsQueryVariables
>;
export const Roadshows_CoveredRoadshowEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CoveredRoadshowEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredRoadshowEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowEventParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowEventParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowEvent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowEventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantTeams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CoveredRoadshowEventsQuery__
 *
 * To run a query within a React component, call `useRoadshows_CoveredRoadshowEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CoveredRoadshowEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CoveredRoadshowEventsQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_CoveredRoadshowEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CoveredRoadshowEventsQuery,
    Roadshows_CoveredRoadshowEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_CoveredRoadshowEventsQuery,
    Roadshows_CoveredRoadshowEventsQueryVariables
  >(Roadshows_CoveredRoadshowEventsDocument, options);
}
export function useRoadshows_CoveredRoadshowEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CoveredRoadshowEventsQuery,
    Roadshows_CoveredRoadshowEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_CoveredRoadshowEventsQuery,
    Roadshows_CoveredRoadshowEventsQueryVariables
  >(Roadshows_CoveredRoadshowEventsDocument, options);
}
export type Roadshows_CoveredRoadshowEventsQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredRoadshowEventsQuery
>;
export type Roadshows_CoveredRoadshowEventsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredRoadshowEventsLazyQuery
>;
export type Roadshows_CoveredRoadshowEventsQueryResult = Apollo.QueryResult<
  Roadshows_CoveredRoadshowEventsQuery,
  Roadshows_CoveredRoadshowEventsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeRoadshows_CoveredRoadshowDetailsMockResponse = makeMockResponse<
  Roadshows_CoveredRoadshowDetailsQueryVariables,
  Roadshows_CoveredRoadshowDetailsQuery
>(Roadshows_CoveredRoadshowDetailsDocument);

export const makeRoadshows_CoveredRoadshowEventsMockResponse = makeMockResponse<
  Roadshows_CoveredRoadshowEventsQueryVariables,
  Roadshows_CoveredRoadshowEventsQuery
>(Roadshows_CoveredRoadshowEventsDocument);
