import { PrimaryButton } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import { StyledCancelButton } from './CancelMeetingConfirmModal.styles';

type Props = {
  show?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
};

/**
 * Modal used for confirmation of cancel meeting action
 */
export const CancelMeetingConfirmModal: React.FC<Props> = ({
  show = false,
  onClose,
  onConfirm,
  loading,
}) => {
  return (
    <Modal title="Cancel Meeting" show={show} onHide={onClose}>
      <Modal.Content>
        Would you like to cancel this Meeting? Participants would be notified.
      </Modal.Content>
      <Modal.Footer>
        <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
        <PrimaryButton loading={loading} onClick={onConfirm}>
          Confirm
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
