import { UUID } from '@cmg/common';
import type { DomainNavProps } from '@cmg/design-system';
import { DomainNav } from '@cmg/design-system';
import React from 'react';
import { useLocation } from 'react-router-dom';

import routeFactory, { findActiveRouteGroup } from '../../../../common/util/routeFactory';
import { ReactRouterDomainNavTab } from '../../../../design-system/react-router/ReactRouterDomainNavTab';
import { useInstitutionalDemandRoute } from '../../../offering/components/design-system/hooks/useInstitutionalDemandRoute';
import { useMyRetailDemandRoute } from '../../../offering/components/design-system/hooks/useMyRetailDemandRoute';
import { useTotalRetailDemandRoute } from '../../../offering/components/design-system/hooks/useTotalRetailDemandRoute';

export type Props = Readonly<{
  offeringId: UUID;
}> &
  Pick<DomainNavProps, 'actions'>;

/**
 * Sub-navigation/breadcrumbs for the Order Book module
 */
const OrderBookSubNavigation: React.FC<Props> = ({ offeringId, actions }) => {
  const { pathname } = useLocation();
  const activeRouteGroup = findActiveRouteGroup(pathname, {
    'institutional-demand': routeFactory.orderBookInstitutionalDemand.routePath,
    'total-retail-demand': routeFactory.orderBookTotalRetailDemand.routePath,
    'my-retail-demand': routeFactory.orderBookMyRetailDemand.routePath,
  });
  const institutionalDemandRoute = useInstitutionalDemandRoute({ offeringId });
  const totalRetailDemandRoute = useTotalRetailDemandRoute({ offeringId });
  const myRetailDemandRoute = useMyRetailDemandRoute({ offeringId });

  /**
   * We should revisit the logic here to determine which tabs to show.
   * Retail demand tabs are shown only when the retails demand route definition is not null.
   *
   * Null can actually mean two different things:
   * 1. The user does not have permissions to access the route
   * 2. The access check queries are still loading
   *
   * We set the value parameter even when the queries are still loading which produces and MUI component error:
   * MUI: The `value` provided to the Tabs component is invalid.
   * None of the Tabs' children match with "total-retail-demand".
   */
  return (
    <DomainNav
      value={activeRouteGroup?.[0]}
      role="navigation"
      aria-roledescription="orderbook navbar"
      actions={actions}
    >
      <ReactRouterDomainNavTab {...institutionalDemandRoute} value="institutional-demand" />
      {totalRetailDemandRoute && (
        <ReactRouterDomainNavTab {...totalRetailDemandRoute} value="total-retail-demand" />
      )}
      {myRetailDemandRoute && (
        <ReactRouterDomainNavTab {...myRetailDemandRoute} value="my-retail-demand" />
      )}
    </DomainNav>
  );
};

export default OrderBookSubNavigation;
