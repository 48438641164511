import { numericUtil } from '@cmg/common';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';

export const calculateGrossSpreadTableValues = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const {
    baseManagementFee,
    incentiveManagementFee,
    baseUnderwritingFee,
    incentiveUnderwritingFee,
    baseSellingConcession,
    incentiveSellingConcession,

    baseGrossSpreadValue,
    incentiveFeeValue,
  } = accounting;

  const baseManagementFeeValue = numericUtil.multiply(baseGrossSpreadValue, baseManagementFee);
  const baseUnderwritingFeeValue = numericUtil.multiply(baseGrossSpreadValue, baseUnderwritingFee);
  const baseSellingConcessionValue = numericUtil.multiply(
    baseGrossSpreadValue,
    baseSellingConcession
  );

  const incentiveManagementFeeValue = numericUtil.multiply(
    incentiveFeeValue,
    incentiveManagementFee
  );
  const incentiveUnderwritingFeeValue = numericUtil.multiply(
    incentiveFeeValue,
    incentiveUnderwritingFee
  );
  const incentiveSellingConcessionValue = numericUtil.multiply(
    incentiveFeeValue,
    incentiveSellingConcession
  );

  const totalManagementFeeGrossSpread = numericUtil.sum(
    baseManagementFeeValue,
    incentiveManagementFeeValue
  );
  const totalUnderwritingFeeGrossSpread = numericUtil.sum(
    baseUnderwritingFeeValue,
    incentiveUnderwritingFeeValue
  );
  const totalSellingConcessionGrossSpread = numericUtil.sum(
    baseSellingConcessionValue,
    incentiveSellingConcessionValue
  );

  const totalBaseAllocation = numericUtil.sum(
    baseManagementFee,
    baseUnderwritingFee,
    baseSellingConcession
  );
  const totalIncentiveAllocation = numericUtil.sum(
    incentiveManagementFee,
    incentiveUnderwritingFee,
    incentiveSellingConcession
  );
  const totalBaseDecomposition = numericUtil.sum(
    baseManagementFeeValue,
    baseUnderwritingFeeValue,
    baseSellingConcessionValue
  );
  const totalIncentiveDecomposition = numericUtil.sum(
    incentiveManagementFeeValue,
    incentiveUnderwritingFeeValue,
    incentiveSellingConcessionValue
  );
  const totalGrossSpread = numericUtil.sum(
    totalManagementFeeGrossSpread,
    totalUnderwritingFeeGrossSpread,
    totalSellingConcessionGrossSpread
  );

  return {
    baseManagementFeeValue,
    baseUnderwritingFeeValue,
    baseSellingConcessionValue,
    incentiveManagementFeeValue,
    incentiveUnderwritingFeeValue,
    incentiveSellingConcessionValue,
    totalManagementFeeGrossSpread,
    totalUnderwritingFeeGrossSpread,
    totalSellingConcessionGrossSpread,
    totalBaseAllocation,
    totalIncentiveAllocation,
    totalBaseDecomposition,
    totalIncentiveDecomposition,
    totalGrossSpread,
  };
};
