import { UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { Investor } from './TradeReleaseHistory';
import { TradeReleaseHistorySidePanel } from './TradeReleaseHistorySidePanel';

export type TradeReleaseHistoryRouteParams = {
  offeringId: UUID;
  indicationId: UUID;
};
type OwnProps = Readonly<{
  investors: Investor[];
}>;
export type Props = RouteComponentProps<TradeReleaseHistoryRouteParams> & OwnProps;

export const TradeReleaseHistoryRoute: React.VFC<Props> = ({ match, investors }) => {
  const { replace } = useHistory();
  const { offeringId, indicationId } = match.params;

  const [isSidePanelOpen, setSidePanelOpen] = React.useState(true);
  const onClose = React.useCallback(() => {
    setSidePanelOpen(false);
    replace(routeFactory.tradeRelease.getUrlPath({ offeringId }));
  }, [replace, offeringId]);

  return (
    <TradeReleaseHistorySidePanel
      investors={investors}
      offeringId={offeringId}
      indicationId={indicationId}
      isOpen={isSidePanelOpen}
      onClose={onClose}
    />
  );
};
