import styled from 'styled-components/macro';

export const SManagerList = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const SBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 8px;
`;

export const SHeader = styled.div`
  min-height: 50px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background.color.white};
  border-bottom: 2px solid ${({ theme }) => theme.designSystem.colors.gray['000']};
`;

export const STitle = styled.div`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SMenuHeading = styled.div`
  padding: 12px 0;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;
