import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { DropdownButton, SecondaryButton, UUID } from '@cmg/common';
import React from 'react';

import { SAccountingHeaderText, SAccountingHeaderWrapper } from './AccountingDetailsHeader.styles';
import AccountingManualAdjustmentModal from './manual-adjustment-modal/AccountingManualAdjustmentModal';
import AccountingNotesModal from './notes-modal/AccountingNotesModal';

export type Props = {
  offeringId: UUID;
};

const AccountingDetailsHeader: React.FC<Props> = ({ offeringId }) => {
  const [isManualAdjustmentModalOpen, setIsManualAdjustmentModalOpen] = React.useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = React.useState(false);

  const handleOpenManualAdjustmentModal = () => {
    setIsManualAdjustmentModalOpen(true);
  };

  const handleOpenNotesModal = () => {
    setIsNotesModalOpen(true);
  };

  const handleCloseManualAdjustmentModal = () => {
    setIsManualAdjustmentModalOpen(false);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  return (
    <SAccountingHeaderWrapper>
      <SAccountingHeaderText>Settlement Accounting</SAccountingHeaderText>

      <AccessControl requiredPermissions={[permissionsByEntity.Accounting.FULL]}>
        <DropdownButton
          testId="header-button"
          buttonComponent={SecondaryButton}
          popoverVariant="LIGHT"
          options={[
            {
              label: 'Final Settlement Manual Adjustment',
              onClick: handleOpenManualAdjustmentModal,
            },
            {
              label: 'Notes',
              onClick: handleOpenNotesModal,
            },
          ]}
        >
          Edit
        </DropdownButton>
      </AccessControl>

      <AccountingManualAdjustmentModal
        show={isManualAdjustmentModalOpen}
        onHide={handleCloseManualAdjustmentModal}
        offeringId={offeringId}
      />
      <AccountingNotesModal
        show={isNotesModalOpen}
        onHide={handleCloseNotesModal}
        offeringId={offeringId}
      />
    </SAccountingHeaderWrapper>
  );
};

export default AccountingDetailsHeader;
