import { Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Loading from '../../../../../../../../common/components/indicators/loading/Loading';
import {
  SPopoverDescription,
  SPopoverWrapper,
  StyledIconButton,
} from './UnassignCompanyButton.styles';

export type Props = { onClick: () => void; isLoading: boolean };

const UnassignCompanyButton: React.FC<Props> = ({ onClick, isLoading }) => {
  const theme = useTheme();

  return (
    <Popover
      hideArrow
      placement="bottomLeft"
      trigger="click"
      content={
        <SPopoverWrapper onClick={onClick}>
          Remove from Roadshow
          <SPopoverDescription>
            Unassign Company and all Management Team members from the Roadshow
          </SPopoverDescription>
          {isLoading && <Loading />}
        </SPopoverWrapper>
      }
    >
      <StyledIconButton
        title="Unassign company"
        icon={{
          name: 'ellipsis-v',
          variant: 'regular',
          size: '1x',
        }}
        color={theme.designSystem.colors.gray[400]}
      />
    </Popover>
  );
};

export default UnassignCompanyButton;
