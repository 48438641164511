import {
  DocumentNode,
  LazyQueryExecFunction,
  LazyQueryResultTuple,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getGraphQLSettings } from '../../common/config/appSettings';
import { loadingStateBehavior, loadingStateBehaviorMap } from './types';

type UpdateQueryOptions<TData> = {
  subscriptionData: {
    data: TData;
  };
};

export type UseWithSubscriptionOptions<
  TSubscriptionData extends {},
  TSubscriptionVariables extends OperationVariables,
  TQueryData extends {},
  TQueryVariables extends OperationVariables
> = {
  document: DocumentNode;
  isEnabled?: boolean;
  loadingStateBehavior?: loadingStateBehavior;
  variables?: (queryVariables: TQueryVariables | undefined) => TSubscriptionVariables;
  updateQuery: (
    refetch: (data: TQueryData, backoff?: number) => TQueryData
  ) => (prev: TQueryData, options: UpdateQueryOptions<TSubscriptionData>) => TQueryData;
};

export const useWithSubscription = <
  TSubscriptionData extends {},
  TSubscriptionVariables extends OperationVariables,
  TQueryData extends {},
  TQueryVariables extends OperationVariables
>(
  queryResults: QueryResult<TQueryData, TQueryVariables>,
  options: UseWithSubscriptionOptions<
    TSubscriptionData,
    TSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >
) => {
  const {
    networkStatus,
    subscribeToMore,
    variables: queryVariables,
    refetch: _refetch,
    loading,
  } = queryResults;

  const {
    document,
    isEnabled = true, // Enabled by default
    loadingStateBehavior = 'default',
    variables,
    updateQuery,
    refetch,
  } = useMemo(() => {
    let timeout: NodeJS.Timeout | undefined;

    const {
      subscriptions: {
        refetch: { backoff: defaultRefetchBackoff },
      },
    } = getGraphQLSettings();

    return {
      ...options,
      refetch: (data: TQueryData, backoff: number = defaultRefetchBackoff) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          // Call refetch on a random backoff delay.
          // Staggering the refetches when multiple users receive a subscription events at the same time.
          _refetch(queryVariables);
          timeout = undefined;
        }, Math.random() * backoff);

        return data;
      },
    };
    // Don't enforce exhaustive-deps here because we're essentially preserving the options object which can be volatile.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // isEnabled is an exception because it's the vector by which we can defer the subscription activation from useWithLazySubscription.
    options.isEnabled,
  ]);

  useEffect(() => {
    if (isEnabled) {
      return subscribeToMore<TSubscriptionData, TSubscriptionVariables>({
        document,
        variables: variables ? variables(queryVariables) : undefined,
        updateQuery: updateQuery(refetch),
      });
    }
  }, [document, isEnabled, queryVariables, refetch, subscribeToMore, updateQuery, variables]);

  return {
    ...queryResults,
    loading: loadingStateBehaviorMap[loadingStateBehavior](loading, networkStatus),
  };
};

export const useWithLazySubscription = <
  TSubscriptionData extends {},
  TSubscriptionVariables extends OperationVariables,
  TQueryData extends {},
  TQueryVariables extends OperationVariables
>(
  lazyQueryResults: LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithSubscriptionOptions<
    TSubscriptionData,
    TSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >
) => {
  const isEnabled = options.isEnabled ?? true;
  const [_executeQuery, queryResults] = lazyQueryResults;

  // Due to the deferred nature of the lazy query and since any subscription activation needs to wait until the lazy query has executed,
  // a bit of state management is needed to facilitate that. hasExecuted tracks whether the lazy query has executed or not yet.
  const [hasExecuted, setExecuted] = useState(false);

  const executeQuery: LazyQueryExecFunction<TQueryData, TQueryVariables> = useCallback(
    options => {
      if (isEnabled) {
        setExecuted(true);
      }
      return _executeQuery(options);
    },
    [_executeQuery, isEnabled]
  );

  return [
    executeQuery,
    useWithSubscription(queryResults, {
      ...options,
      isEnabled: isEnabled && hasExecuted, // isEnabled is the vector by which we can defer the subscription activation in useWithSubscription, until the lazy query has executed.
    }),
  ] as LazyQueryResultTuple<TQueryData, TQueryVariables>;
};
