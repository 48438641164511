import React from 'react';

import ErrorRenderer from '../../../../../../../../common/components/errorRenderer/Error-Renderer';
import Loading from '../../../../../../../../common/components/indicators/loading/Loading';
import SideBarHeader from '../../../../../../components/side-bar-header/SideBarHeader';
import DuplicateIndicationRow from './DuplicateIndicationRow';
import {
  SDuplicateIndicationsRowsContainer,
  SDuplicateIndicationsWrapper,
  SSeparatorLine,
} from './DuplicateIndications.styles';
import { useManageIndication_DuplicateIndicationsQuery } from './graphql/__generated__';

export type Props = {
  duplicateIndicationIds: readonly string[];
  offeringId: string;
};

const DuplicateIndications: React.FC<Props> = ({ duplicateIndicationIds, offeringId }) => {
  const { data, loading, error } = useManageIndication_DuplicateIndicationsQuery({
    variables: { offeringId },
  });
  const indications = React.useMemo(
    () =>
      (data?.basicPublishedOffering?.orderBook.institutionalIndications ?? []).filter(i =>
        duplicateIndicationIds.includes(i.id)
      ),
    [data?.basicPublishedOffering?.orderBook.institutionalIndications, duplicateIndicationIds]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <SDuplicateIndicationsWrapper>
      <SideBarHeader title="Linked Duplicates Indications" children={undefined} />
      {error && <ErrorRenderer error={error} />}
      {!error && (
        <SDuplicateIndicationsRowsContainer data-test-id="linked-duplicates-indications-rows">
          {indications.map((indication, index) => (
            <div key={`duplicate-${indication.id}`}>
              {index > 0 && <SSeparatorLine />}
              <DuplicateIndicationRow indication={indication} />
            </div>
          ))}
        </SDuplicateIndicationsRowsContainer>
      )}
    </SDuplicateIndicationsWrapper>
  );
};

export default DuplicateIndications;
