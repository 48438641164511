import { WireMessageAndResponseStatus } from '../../../../../../../graphql';
import { SyndicateWire } from '../../../../SyndicateWiresRoute.model';
import { WireStageType } from '../../wire-ribbon/WireRibbon';
import { WireStatusType } from '../../wire-status/WireStatus';

export const wireMessageStatusToWireStageType: {
  [messageStatus in WireMessageAndResponseStatus]: WireStageType & WireStatusType;
} = {
  [WireMessageAndResponseStatus.Accepted]: 'accepted',
  [WireMessageAndResponseStatus.Declined]: 'declined',
  [WireMessageAndResponseStatus.Expired]: 'expired',
  [WireMessageAndResponseStatus.Obsolete]: 'obsolete',
  [WireMessageAndResponseStatus.ReadyToSend]: 'readyToSend',
  [WireMessageAndResponseStatus.Sent]: 'sent',
};

export const getWirePreviewHtml = (
  recipientId: string | undefined,
  wire: SyndicateWire | undefined
  /*
  wire:
    | {
        wireMessages: {
          htmlBody: string;
          firmSnapshot?: { cmgEntityKey: string } | null;
          emailAddresses: readonly string[] | string[];
        }[];
      }
    | undefined
  */
) => {
  if (!recipientId || !wire) {
    return '';
  }

  const wireMessage = wire.wireMessages.find(
    message =>
      message.firmSnapshot?.cmgEntityKey === recipientId ||
      message.emailAddresses.includes(recipientId)
  );

  return wireMessage?.htmlBody ?? '';
};
