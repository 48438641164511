import styled from 'styled-components/macro';

const SCalendarFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SCalendarFiltersMain = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  min-width: 240px;
  gap: 16px;
`;

const SCalendarFiltersRightSide = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
`;

export const SUpdatedTimeStamp = styled.span`
  margin: 0 16px;
`;

export default Object.assign({}, SCalendarFiltersWrapper, {
  Main: SCalendarFiltersMain,
  RightSide: SCalendarFiltersRightSide,
});
