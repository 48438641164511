/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestorContactSearch_InvestorEmployeePartsFragment = {
  readonly __typename?: 'InvestorEmployee';
  readonly id: string;
  readonly name: string;
  readonly email1?: string | null;
  readonly email2?: string | null;
};

export type InvestorContactSearch_InvestorFirmsEmployeesQueryVariables = Types.Exact<{
  firmKeyOrId: Types.Scalars['String'];
  isCoveredAccount: Types.Scalars['Boolean'];
}>;

export type InvestorContactSearch_InvestorFirmsEmployeesQuery = {
  readonly __typename?: 'Query';
  readonly investorFirmsEmployees?: ReadonlyArray<{
    readonly __typename?: 'InvestorEmployee';
    readonly id: string;
    readonly name: string;
    readonly email1?: string | null;
    readonly email2?: string | null;
  }>;
  readonly coveredInvestorFirmsEmployees?: ReadonlyArray<{
    readonly __typename?: 'InvestorEmployee';
    readonly id: string;
    readonly name: string;
    readonly email1?: string | null;
    readonly email2?: string | null;
  }>;
};

export const InvestorContactSearch_InvestorEmployeePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactSearch_InvestorEmployeeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorEmployee' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InvestorContactSearch_InvestorFirmsEmployeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorContactSearch_InvestorFirmsEmployees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeyOrId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isCoveredAccount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirmsEmployees' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKeyOrId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeyOrId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isCoveredAccount' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvestorContactSearch_InvestorEmployeeParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredInvestorFirmsEmployees' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKeyOrId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeyOrId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isCoveredAccount' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvestorContactSearch_InvestorEmployeeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactSearch_InvestorEmployeeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorEmployee' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorContactSearch_InvestorFirmsEmployeesQuery__
 *
 * To run a query within a React component, call `useInvestorContactSearch_InvestorFirmsEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactSearch_InvestorFirmsEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorContactSearch_InvestorFirmsEmployeesQuery({
 *   variables: {
 *      firmKeyOrId: // value for 'firmKeyOrId'
 *      isCoveredAccount: // value for 'isCoveredAccount'
 *   },
 * });
 */
export function useInvestorContactSearch_InvestorFirmsEmployeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorContactSearch_InvestorFirmsEmployeesQuery,
    InvestorContactSearch_InvestorFirmsEmployeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorContactSearch_InvestorFirmsEmployeesQuery,
    InvestorContactSearch_InvestorFirmsEmployeesQueryVariables
  >(InvestorContactSearch_InvestorFirmsEmployeesDocument, options);
}
export function useInvestorContactSearch_InvestorFirmsEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorContactSearch_InvestorFirmsEmployeesQuery,
    InvestorContactSearch_InvestorFirmsEmployeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorContactSearch_InvestorFirmsEmployeesQuery,
    InvestorContactSearch_InvestorFirmsEmployeesQueryVariables
  >(InvestorContactSearch_InvestorFirmsEmployeesDocument, options);
}
export type InvestorContactSearch_InvestorFirmsEmployeesQueryHookResult = ReturnType<
  typeof useInvestorContactSearch_InvestorFirmsEmployeesQuery
>;
export type InvestorContactSearch_InvestorFirmsEmployeesLazyQueryHookResult = ReturnType<
  typeof useInvestorContactSearch_InvestorFirmsEmployeesLazyQuery
>;
export type InvestorContactSearch_InvestorFirmsEmployeesQueryResult = Apollo.QueryResult<
  InvestorContactSearch_InvestorFirmsEmployeesQuery,
  InvestorContactSearch_InvestorFirmsEmployeesQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeInvestorContactSearch_InvestorFirmsEmployeesMockResponse = makeMockResponse<
  InvestorContactSearch_InvestorFirmsEmployeesQueryVariables,
  InvestorContactSearch_InvestorFirmsEmployeesQuery
>(InvestorContactSearch_InvestorFirmsEmployeesDocument);
