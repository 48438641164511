import FullCalendar from '@fullcalendar/react';
import resourceTimeGridDay from '@fullcalendar/resource-timegrid';
import { addDays, format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';

import { getAuthSettings } from '../../../../../common/config/appSettings';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../common/graphql';
import CalendarContent from '../common/calendar-content/CalendarContent';
import CalendarFilters from '../common/calendar-filters/CalendarFilters';
import { View } from '../common/calendar-filters/CalendarFilters.model';
import DateRangeControl from '../common/calendar-filters/date-range-control/DateRangeControl';
import { createCalendarEvents, createCalendarResources } from './CalendarViewDaily.model';
import { MeetingContent } from './meeting-content/MeetingContent';

export type Props = {
  allEvents: readonly Roadshows_RoadshowEventPartsFragment[];
  roadshow: Roadshows_RoadshowPartsFragment;
  onViewChange: (view: View) => void;
};

const CalendarViewDaily: React.FC<Props> = ({ allEvents, roadshow, onViewChange }) => {
  const calendarRef = React.createRef<FullCalendar>();
  const { push } = useHistory();
  // TODO compute start date based on either roadshow's starting date or current date
  const [currentDate, setCurrentDate] = React.useState<string>(roadshow.startDate);
  const { fullCalendarLicenseKey } = getAuthSettings();

  const handleLoadResources = (fetchInfo, setResource) => {
    setResource(createCalendarResources(roadshow, fetchInfo.start));
  };

  const handleNextDate = () => {
    setCurrentDate(format(addDays(new Date(currentDate), 1), 'yyyy-MM-dd'));
    calendarRef.current && calendarRef.current.getApi().next();
  };

  const handlePrevDate = () => {
    setCurrentDate(format(addDays(new Date(currentDate), -1), 'yyyy-MM-dd'));
    calendarRef.current && calendarRef.current.getApi().prev();
  };

  const redirectToEditMeetingPage = (meetingId: string) => {
    push(
      routeFactory.roadshowMeetingDetails.getUrlPath({
        roadshowId: roadshow.id,
        meetingId,
      })
    );
  };

  const redirectToCreateMeetingPage = () => {
    push(
      routeFactory.roadshowCreateMeeting.getUrlPath({
        roadshowId: roadshow.id,
        date: currentDate,
      })
    );
  };

  const redirectToCreateLogisticsPage = () => {
    push(
      routeFactory.roadshowCreateLogistics.getUrlPath({
        roadshowId: roadshow.id,
        date: currentDate,
      })
    );
  };

  const redirectToEditLogisticsPage = (logisticsId: string) => {
    push(
      routeFactory.roadshowLogisticsDetails.getUrlPath({
        roadshowId: roadshow.id,
        logisticsId,
      })
    );
  };

  const events = React.useMemo(() => {
    return createCalendarEvents(allEvents);
  }, [allEvents]);

  return (
    <CalendarContent>
      <CalendarFilters
        view={View.DAY}
        onViewChange={onViewChange}
        onCreateMeeting={redirectToCreateMeetingPage}
        onCreateLogistics={redirectToCreateLogisticsPage}
      >
        <DateRangeControl
          startDate={roadshow.startDate}
          endDate={roadshow.endDate}
          currentDate={currentDate}
          onPrevDate={handlePrevDate}
          onNextDate={handleNextDate}
        />
      </CalendarFilters>
      <FullCalendar
        ref={calendarRef}
        plugins={[resourceTimeGridDay]}
        height="100%"
        weekends={true}
        headerToolbar={false}
        slotLabelFormat={{ hour: 'numeric' }}
        allDaySlot={false}
        initialDate={roadshow.startDate}
        initialView={View.DAY}
        refetchResourcesOnNavigate={true}
        events={events}
        resources={handleLoadResources}
        eventContent={({ event }) => {
          return (
            <MeetingContent
              event={event.extendedProps as Roadshows_RoadshowEventPartsFragment}
              onEditMeeting={redirectToEditMeetingPage}
              onEditLogistics={redirectToEditLogisticsPage}
            />
          );
        }}
        schedulerLicenseKey={fullCalendarLicenseKey}
      />
    </CalendarContent>
  );
};

export default CalendarViewDaily;
