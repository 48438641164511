import { Button, ButtonGroup, Divider, Stack } from '@cmg/design-system';
import React from 'react';

import { SecurityType } from '../../../../../../graphql';
import IndicationVersionDetail from './components/indication-version-detail/IndicationVersionDetail';
import {
  UpdateHistory_CoveredIndicationVersionPartsFragment,
  UpdateHistory_IndicationVersionPartsFragment,
  UpdateHistory_MyIndicationVersionPartsFragment,
} from './graphql';

export type Props = Readonly<{
  indicationVersions: readonly (
    | UpdateHistory_IndicationVersionPartsFragment
    | UpdateHistory_CoveredIndicationVersionPartsFragment
    | UpdateHistory_MyIndicationVersionPartsFragment
  )[];
  pricingCurrencyCode?: string | null;
  securityType?: SecurityType;
}>;

export const IndicationUpdateHistoryList: React.FC<Props> = ({
  indicationVersions,
  pricingCurrencyCode,
  securityType,
}) => {
  const [versionRows, setVersionRows] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    const context = indicationVersions.reduce<{ [key: string]: boolean }>((acc, version) => {
      acc[version.id] = false;
      return acc;
    }, {});
    setVersionRows(context ?? {});
  }, [indicationVersions, setVersionRows]);

  const handleExpandAll = () => {
    setVersionRows(prev => {
      return Object.keys(prev).reduce<{ [key: string]: boolean }>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
    });
  };

  const handleCollapseAll = () => {
    setVersionRows(prev => {
      return Object.keys(prev).reduce<{ [key: string]: boolean }>((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    });
  };

  const handleOnRowExpand = (versionId: string) => {
    setVersionRows(prev => {
      return {
        ...prev,
        [versionId]: !prev[versionId],
      };
    });
  };

  const isAllExpanded = React.useMemo(() => {
    return Object.values(versionRows).every(row => row);
  }, [versionRows]);

  const isAllCollapsed = React.useMemo(() => {
    return Object.values(versionRows).every(row => !row);
  }, [versionRows]);

  return (
    <Stack spacing={0} direction="column" role="region">
      <ButtonGroup>
        <Button variant="text" disabled={isAllExpanded} onClick={handleExpandAll}>
          Expand All
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Button variant="text" disabled={isAllCollapsed} onClick={handleCollapseAll}>
          Collapse All
        </Button>
      </ButtonGroup>
      <Divider flexItem />
      <div>
        {indicationVersions.map(version => (
          <div key={version.id}>
            <IndicationVersionDetail
              item={version}
              pricingCurrencyCode={pricingCurrencyCode}
              handleOnRowExpand={handleOnRowExpand}
              expanded={versionRows[version.id] ?? false}
              securityType={securityType}
            />
            <Divider flexItem />
          </div>
        ))}
      </div>
    </Stack>
  );
};
