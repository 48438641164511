import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import { Roadshows_MeetingDocument } from '../../roadshow-coordinator-details-panel/graphql';
import {
  Roadshows_UpdateCoveredInvestorTeamsMutation,
  useRoadshows_UpdateCoveredInvestorTeamsMutation,
} from '../graphql';

export type UpdateCoveredInvestorTeamsMutation = Roadshows_UpdateCoveredInvestorTeamsMutation;

export function refetchQueries() {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument)!;
  const refetchRoadshowMeetings = getOperationName(Roadshows_RoadshowEventsDocument)!;

  return [refetchMeetingDetails, refetchRoadshowMeetings];
}

export const useUpdateCoveredInvestorTeamsMutation = () => {
  return useRoadshows_UpdateCoveredInvestorTeamsMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
