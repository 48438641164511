import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

/**
 * Debounce the `loading` state of an asynchronous call.
 * Useful to avoid flash of styles or rapid mount & unmount components
 * when using the `loading` flag.
 * @param loading The loading or isBusy flag to debounce.
 * @param windowMillis The time to debounce in milliseconds.
 * @returns Returns the resultant isBusy state after lapsing a window.
 */
export const useBusyDelay = (loading: boolean, windowMillis: number = 500) => {
  const [isBusy, setIsBusy] = useState(false);
  const debounced = useDebouncedCallback(setIsBusy, windowMillis);
  useEffect(() => {
    debounced(loading);
  }, [debounced, loading]);

  return isBusy;
};
