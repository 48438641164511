import React from 'react';

import { MyOrdersGridCellProps } from '../MyOrdersGrid.model';
import { StyledCellRenderer } from '../MyOrdersGridColumns.styles';

const BDAgentCell: React.FC<MyOrdersGridCellProps> = ({ data, context }) => {
  if (!context.offerings[data.offeringId]) {
    return <StyledCellRenderer> - </StyledCellRenderer>;
  }

  const { managers } = context.offerings[data.offeringId];
  const manager = managers.find(
    ({ cmgEntityKey }) => cmgEntityKey === data.billingAndDeliveryAgentCmgEntityKey
  );
  const { firmName } = manager ?? {};

  return <StyledCellRenderer>{firmName || '-'}</StyledCellRenderer>;
};

export default BDAgentCell;
