import { Panel } from '@cmg/common';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';
import RoadshowHeader from '../../../../common/roadshow-header/RoadshowHeader';
import { View } from '../../../common/calendar-filters/CalendarFilters.model';
import { LogisticsNavigationSteps } from '../../../panel-logistics-event/common/logistics-side-panel/LogisticsSidePanel.model';
import SalesAndTradingLogisticsDetailsPanel from '../../../panel-logistics-event/logistics-details-panel/sales-and-trading-logistics-details-panel/SalesAndTradingLogisticsDetailsPanel';
import { MeetingNavigationSteps } from '../../../panel-meetings-event/common/meeting-side-panel/MeetingSidePanel.model';
import SalesAndTradingDetailsPanel from '../../../panel-meetings-event/meeting-details-panel/sales-and-trading-details-panel/SalesAndTradingDetailsPanel';
import CalendarViewDaily from '../../../view-daily-events/CalendarViewDaily';
import CalendarViewList from '../../../view-list-all-events/CalendarViewList';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  events: readonly Roadshows_RoadshowEventPartsFragment[];
};

const SalesAndTradingCalendar: React.FC<Props> = ({ roadshow, events }) => {
  const [view, setView] = React.useState<View>(View.DAY);
  const { push } = useHistory();

  const handleSidePanelClose = () => {
    push(routeFactory.roadshowCalendar.getUrlPath({ roadshowId: roadshow.id }));
  };

  return (
    <FlexLayout
      direction="column"
      data-test-id="roadshows / single-roadshow / calendar / sales and trading"
    >
      <RoadshowHeader roadshow={roadshow} />
      <Panel fillViewport>
        {view === View.DAY && (
          <CalendarViewDaily allEvents={events} roadshow={roadshow} onViewChange={setView} />
        )}
        {view === View.LIST && (
          <CalendarViewList allEvents={events} roadshow={roadshow} onViewChange={setView} />
        )}
      </Panel>

      <Switch>
        <Route
          exact
          path={routeFactory.roadshowMeetingDetails.routePath}
          render={({ match }) => (
            <SalesAndTradingDetailsPanel
              meetingId={match.params.meetingId}
              roadshow={roadshow}
              onClose={handleSidePanelClose}
              stepId={match.params.stepId as MeetingNavigationSteps}
            />
          )}
        />
        <Route
          exact
          path={routeFactory.roadshowLogisticsDetails.routePath}
          render={({ match }) => (
            <SalesAndTradingLogisticsDetailsPanel
              logisticsId={match.params.logisticsId}
              stepId={match.params.stepId as LogisticsNavigationSteps}
              onClose={handleSidePanelClose}
              roadshow={roadshow}
            />
          )}
        />
      </Switch>
    </FlexLayout>
  );
};

export default SalesAndTradingCalendar;
