import { getCurrencySymbol } from '@cmg/common';
import { styled, ToggleButton, ToggleButtonGroup } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  FormStatus,
  UnderwritingTermsDiscountsAndFeesFormValues,
} from '../UnderwritingTermsDiscountsAndFeesSection';

type InputValues = 'percentage' | 'currency';

// Specific styling of the text mimics the size of an Icon component
const ToggleButtonStyled = styled(
  ToggleButton,
  {}
)(() => ({
  padding: '4px 12px 4px 12px',
  fontSize: 14,
  lineHeight: '24px',
}));

export function InputValuesSwitch({ currencyCode }) {
  const { status, setStatus } = useFormikContext<UnderwritingTermsDiscountsAndFeesFormValues>();

  const handleInputValue = (
    event: React.MouseEvent<HTMLElement>,
    newInputValue: InputValues | null
  ) => {
    if (!newInputValue) return;
    setStatus(newInputValue);
  };

  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={status}
      onChange={handleInputValue}
      color="primary"
    >
      <ToggleButtonStyled size="small" value={FormStatus.EditingPercentage}>
        %
      </ToggleButtonStyled>
      <ToggleButtonStyled size="small" value={FormStatus.EditingCurrency}>
        {getCurrencySymbol(currencyCode)}
      </ToggleButtonStyled>
    </ToggleButtonGroup>
  );
}
