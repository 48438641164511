import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeletePricingTermsWireMutation,
  SyndicateWires_PricingTermsWiresDocument,
  useSyndicateWires_DeletePricingTermsWireMutation,
} from '../graphql';

export type DeletePricingTermsWireMutation = SyndicateWires_DeletePricingTermsWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PricingTermsWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useDeletePricingTermsWireMutation = () => {
  return useSyndicateWires_DeletePricingTermsWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
