import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ISODateTime, timeUtil } from '@cmg/common';
import React from 'react';

import { useFeatureToggles } from '../../../../common/config';
import IssuerFilter from '../../../my-offerings/filters/components/issuer-filter/IssuerFilter';
import { CalendarFilters as CalendarFiltersType } from '../../hooks/useFilters';
import { CalendarExportButton } from '../calendar-export-button/CalendarExportButton';
import { CalendarGridOffering } from '../calendar-grid/CalendarGrid.model';
import {
  getLeftLeads,
  getOfferingTypes,
  getRegionToCountry,
  getSectors,
  getUnderwriters,
} from './CalendarFilters.model';
import SCalendarFilters, { SUpdatedTimeStamp } from './CalendarFilters.styles';
import { CountryFilter } from './components/country-filter/CountryFilter';
import IncludeTestOfferingsFilter from './components/include-test-offerings-filter/IncludeTestOfferingsFilter';
import LeftLeadFilter from './components/left-lead-filter/LeftLeadFilter';
import OfferingTypeFilter from './components/offering-type-filter/OfferingTypeFilter';
import SectorFilter from './components/sector-filter/SectorFilter';
import SpacFilter from './components/spac-filter/SpacFilter';
import { UnderwriterFilter } from './components/underwriter-filter/UnderwriterFilter';
import UnderwriterOnDealFilter from './components/underwriter-on-deal-filter/UnderwriterOnDealFilter';

export type Props = {
  onChange: (filters: CalendarFiltersType) => void;
  filters: CalendarFiltersType;
  offerings?: readonly CalendarGridOffering[];
  lastUpdatedAt: ISODateTime;
};

/**
 * CalendarFilters displays the calendar tab filters.
 * filters only display values that exist in the dataset of offerings provided
 */
const CalendarFilters: React.FC<Props> = ({ onChange, offerings, filters, lastUpdatedAt }) => {
  const offeringTypes = getOfferingTypes(offerings);

  const sectors = getSectors(offerings);

  const leftLeads = getLeftLeads(offerings);

  const regionToCountry = getRegionToCountry(offerings);

  const underwriters = React.useMemo(() => getUnderwriters(offerings), [offerings]);

  const { isInternationalDisplayOn } = useFeatureToggles();
  const hasInternational = useCheckPermissions([permissionsByEntity.InternationalOffering.READ]);

  return (
    <SCalendarFilters>
      <SCalendarFilters.Main>
        <IssuerFilter
          issuerName={filters.issuerName ?? ''}
          onChange={value => onChange({ ...filters, issuerName: value ?? '' })}
        />
        {hasInternational && isInternationalDisplayOn && (
          <CountryFilter
            values={regionToCountry || []}
            onChange={value => {
              onChange({ ...filters, country: value });
            }}
          />
        )}
        <SectorFilter
          values={filters.issuerSector}
          sectors={sectors}
          onChange={value => onChange({ ...filters, issuerSector: value })}
        />
        <OfferingTypeFilter
          values={filters.type}
          offeringTypes={offeringTypes}
          onChange={value => onChange({ ...filters, type: value })}
        />
        <SpacFilter
          value={filters.isSpac}
          hasNoOptions={!offeringTypes.length} // if no offering types, list must be empty
          onChange={isSpac => isSpac && onChange({ ...filters, isSpac })}
        />
        <LeftLeadFilter
          values={filters.leftLeadFirmName}
          leftLeads={leftLeads}
          onChange={value => onChange({ ...filters, leftLeadFirmName: value })}
        />
        <UnderwriterFilter
          values={filters.underwriters}
          underwriters={underwriters}
          onChange={value => onChange({ ...filters, underwriters: value })}
        />
        <UnderwriterOnDealFilter
          checked={filters.isUnderwriterOnDeal}
          onChange={value => onChange({ ...filters, isUnderwriterOnDeal: value })}
        />
        <IncludeTestOfferingsFilter
          checked={filters.includeTestOfferings}
          onChange={value => onChange({ ...filters, includeTestOfferings: value })}
        />
      </SCalendarFilters.Main>
      <SCalendarFilters.RightSide>
        <SUpdatedTimeStamp>
          Updated {timeUtil.formatAsMonthDateTime(lastUpdatedAt)}
        </SUpdatedTimeStamp>
        <CalendarExportButton filters={filters} />
      </SCalendarFilters.RightSide>
    </SCalendarFilters>
  );
};

export default CalendarFilters;
