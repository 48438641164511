import { numericUtil } from '@cmg/common';
import React from 'react';

import { SalesCredits_SalesCreditItemPartsFragment } from '../../../graphql';
import { SalesCreditsFormValues } from '../../SalesCreditsDetailsPanel.model';
import { salesCreditsCols } from '../SalesCreditsGrid.model';

export const useGridTotals = ({
  rows,
  isEditing,
  editingValues,
}: {
  rows: SalesCredits_SalesCreditItemPartsFragment[];
  isEditing: boolean;
  editingValues: SalesCreditsFormValues;
}) => {
  return React.useMemo(() => {
    const totalNumberOfInvestors = rows.length;
    const totalSalesCredits = numericUtil.sum(
      ...rows.map(({ salesCredits, salesCreditsManual }) => salesCreditsManual ?? salesCredits)
    );
    const totalSalesCreditsEditing = numericUtil.sum(...Object.values(editingValues.salesCredits));
    const totalReleasedSalesCredits = numericUtil.sum(
      ...rows.map(({ salesCreditsReleased }) => salesCreditsReleased)
    );
    const totalAllocations = numericUtil.sum(
      ...rows.map(({ allocationShares }) => allocationShares)
    );
    const totalDesignatedShares = numericUtil.sum(
      ...rows.map(({ designationShares }) => designationShares)
    );
    const totalPotEconomics = numericUtil.divide(totalDesignatedShares, totalAllocations);

    return {
      [salesCreditsCols.investorFirmName]: totalNumberOfInvestors,
      [salesCreditsCols.salesCredits]: isEditing ? totalSalesCreditsEditing : totalSalesCredits,
      [salesCreditsCols.salesCreditsReleased]: totalReleasedSalesCredits,
      [salesCreditsCols.allocationShares]: totalAllocations,
      [salesCreditsCols.potEconomics]: totalPotEconomics,
      [salesCreditsCols.designationShares]: totalDesignatedShares,
    };
  }, [rows, isEditing, editingValues]);
};
