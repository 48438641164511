import { AgGridProcessCellForExportParams } from '@cmg/common';
import { GridApi } from 'ag-grid-community';
import React from 'react';

import { encodeFileName } from '../../../../common/util/encodeFileName';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';
import { releaseStatusMap } from '../grid/release-status-renderer/release-status/ReleaseStatus.model';
import { salesCreditsCols } from '../grid/SalesCreditsGrid.model';

export const salesCreditsCellFormatterCallback = (params: AgGridProcessCellForExportParams) => {
  const colId = params.column.getColId();
  const data: SalesCredits_SalesCreditItemPartsFragment = params.node?.data;

  if (colId === salesCreditsCols.status) {
    return releaseStatusMap.get(data.status);
  }

  return params.value;
};

export const useExportSalesCredits = (offeringIssuerName: string) => {
  return React.useCallback(
    (gridApi?: GridApi) => {
      if (!gridApi) {
        return;
      }

      gridApi.exportDataAsCsv({
        fileName: encodeFileName(offeringIssuerName, 'Sales_Credits', ''),
        shouldRowBeSkipped: row => row.node.isRowPinned(),
        columnKeys: [
          salesCreditsCols.investorFirmName,
          salesCreditsCols.bankInvestorKey,
          salesCreditsCols.salesCredits,
          salesCreditsCols.salesCreditsReleased,
          salesCreditsCols.allocationShares,
          salesCreditsCols.potEconomics,
          salesCreditsCols.designationShares,
          salesCreditsCols.statusDetails,
          salesCreditsCols.status,
        ],
        processCellCallback: salesCreditsCellFormatterCallback,
      });
    },
    [offeringIssuerName]
  );
};
