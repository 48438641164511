import { Icon, UUID } from '@cmg/common';
import React from 'react';

import NavItem from '../../../../../common/components/navigation/side-navigation/NavItem';
import { HorizontalLine } from '../../../../../common/components/presentation';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  SHeading,
  SItem,
  SWrapper,
} from '../../../../trade-release/history/TradeReleaseHistorySidePanelNavbar.styles';

export type Props = {
  offeringId: UUID;
  investorFirmCmgEntityKey: UUID;
};

export const SalesCreditsHistorySidePanelNavbar: React.FC<Props> = ({
  offeringId,
  investorFirmCmgEntityKey,
}) => {
  return (
    <SWrapper>
      <SHeading>Sales Credits</SHeading>
      <HorizontalLine />
      <SItem>
        <NavItem
          url={routeFactory.salesCreditsHistory.getUrlPath({
            offeringId,
            investorFirmCmgEntityKey,
          })}
        >
          <Icon name="history" />
          History
        </NavItem>
      </SItem>
    </SWrapper>
  );
};
