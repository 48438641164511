import type { OrderBook_InstitutionalDemand_InvestorInfoPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';

const cmgEntityNameColDef: DemandGridServerColDef<
  OrderBook_InstitutionalDemand_InvestorInfoPartsFragment['firmName']
> = {
  field: 'investor.firmName',
  headerName: 'CMG Entity Name',
};

export default cmgEntityNameColDef;
