import { ArrayHelpers, useFormikContext } from 'formik';

import { RecipientUserData } from '../../../../SyndicateWiresRoute.model';
import {
  canClearRecipientsData,
  OperationType,
} from '../../modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { BaseRecipientsUserDataManager } from '../RecipientsUserDataFormFields';

type Options<
  TRecipientValues extends RecipientUserData,
  TManager extends BaseRecipientsUserDataManager
> = {
  managers: TManager[];
  defaultRecipientValues: Omit<TRecipientValues, 'recipient'>;
  operationType: OperationType;
};

export type RecipientFormValues<TRecipientValues extends RecipientUserData> = {
  recipientsUserData: (TRecipientValues | null)[];
};

/**
 * Returns role-manager tuples and handleManagerValuesChange.
 * Handler function is meant to be used to handle user input data changes for specific recipient accordingly.
 */
export const useRecipientsUserDataForm = <
  TRecipientValues extends RecipientUserData,
  TValues extends RecipientFormValues<TRecipientValues>,
  TManager extends BaseRecipientsUserDataManager
>({
  managers,
  defaultRecipientValues,
  operationType,
}: Options<TRecipientValues, TManager>) => {
  const { values, initialValues, setFieldTouched, touched } = useFormikContext<TValues>();

  const setManagerFieldsTouched = (managerIndex: number, fieldNames: string[]) => {
    const touchedManagerFields = Object.keys(touched.recipientsUserData?.[managerIndex] ?? []);
    const untouchedFields = fieldNames.filter(field => {
      return !touchedManagerFields.includes(field);
    });

    for (const field of untouchedFields) {
      setFieldTouched(`recipientsUserData.${managerIndex}.${field}`);
    }
  };

  const handleManagerValuesChange = (
    nextValues: Partial<TRecipientValues>,
    managerIndex: number,
    helpers: ArrayHelpers
  ) => {
    const { cmgEntityKey } = managers[managerIndex];
    const managerValues = values.recipientsUserData.find(
      value => value?.recipient === cmgEntityKey
    );

    const nextManagerValues = {
      ...defaultRecipientValues,
      ...managerValues,
      ...nextValues,
      recipient: cmgEntityKey,
    };

    setFieldTouched(`recipientsUserData.${managerIndex}`);
    setManagerFieldsTouched(managerIndex, Object.keys(nextManagerValues));

    if (
      canClearRecipientsData({
        initialUserData: initialValues.recipientsUserData,
        cmgEntityKey,
        nextValues: nextManagerValues,
        operationType,
        defaultValues: defaultRecipientValues,
      })
    ) {
      helpers.replace(managerIndex, null);
    } else {
      helpers.replace(managerIndex, nextManagerValues);
    }
  };

  return {
    handleManagerValuesChange,
  };
};
