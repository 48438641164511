import { Filing } from './common/navbar/RegulatoryFilingsSideNav';
import { RegulatoryFilings_BaseRegulatoryFilingPartsFragment } from './graphql';

export const sortByDate = (a: { submittedDateTime: string }, b: { submittedDateTime: string }) => {
  return +new Date(a.submittedDateTime) - +new Date(b.submittedDateTime);
};

export const getLatestFiling = (
  filings: readonly RegulatoryFilings_BaseRegulatoryFilingPartsFragment[]
) => {
  return filings.slice().sort(sortByDate).slice(-1)[0];
};

export function getVersion(
  filings: readonly { submittedDateTime: string }[] | undefined,
  submittedDateTime: string | undefined
) {
  const result = filings
    ?.slice()
    .sort(sortByDate)
    .findIndex(item => item.submittedDateTime === submittedDateTime);
  if (result !== undefined && result >= 0) {
    return result + 1;
  } else {
    return null;
  }
}

export const createNavigation = (
  filings: readonly RegulatoryFilings_BaseRegulatoryFilingPartsFragment[] | undefined,
  createUrl: (filing: RegulatoryFilings_BaseRegulatoryFilingPartsFragment) => string
): Filing[] => {
  return (filings ?? [])
    .slice()
    .sort(sortByDate)
    .reverse()
    .map((item, index, allItems) => ({
      ...item,
      version: allItems.length - index,
      url: createUrl(item),
    }));
};
