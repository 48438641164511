import {
  FilingConfirmationModalAction,
  getModalContentMessage,
} from '../../common/dialogs/submit-filing-confirmation-dialog/FilingConfirmationModal.model';

export function createGetTNFormModalContentMessage(
  loadingStabilizationActivitiesFailed: boolean
): (action: FilingConfirmationModalAction) => string {
  return (action: FilingConfirmationModalAction) => {
    const defaultMessage = getModalContentMessage(action);

    return loadingStabilizationActivitiesFailed
      ? `Only Custom Activities data has been provided. ${defaultMessage}`
      : defaultMessage;
  };
}
