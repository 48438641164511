import React from 'react';

import SideNavStep, { Props as StepProps } from './SideNavStep';

/** Step type is used to construct nav menu items and define status */
export type Step = { id: string; label?: string } & Partial<StepProps>;

/**
 * SideNav component which handles steps and statuses
 * @param {string} activeStepId step to show as active
 * @param {object} steps render steps with status and state for each
 * @param {function} onChangeStep when user clicks on some step, this handler is triggered
 * @param {string} className to add a class if necessary
 * @param {string} testId to add a testId if necessary
 */
export type Props = {
  activeStepId?: string;
  steps: Step[];
  onChangeStep?: (string) => void;
  className?: string;
  testId?: string;
};

export const SideNav: React.FC<Props> = ({
  activeStepId,
  steps = [],
  onChangeStep,
  className,
  testId,
}) => {
  return (
    <div className={className} data-test-id={testId}>
      {steps &&
        steps.map(({ id, status, active, disabled, icon, label, testId: stepTestId }) => (
          <SideNavStep
            key={id}
            status={status}
            active={activeStepId === id || active}
            disabled={disabled}
            icon={icon}
            onClick={() => onChangeStep && onChangeStep(id)}
            testId={stepTestId}
          >
            {label}
          </SideNavStep>
        ))}
    </div>
  );
};

export default SideNav;
