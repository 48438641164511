/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationActivity_BuySidePassOnOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  activateIfCancelled: Types.Scalars['Boolean'];
}>;

export type IndicationActivity_BuySidePassOnOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly passOnOffering:
    | {
        readonly __typename?: 'MyInstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type IndicationActivity_BuySideRouteQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type IndicationActivity_BuySideRouteQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly offerPrice?: number | null;
    readonly pricingCurrencyCode: string;
    readonly securityType: Types.SecurityType;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly publicOrderBook: {
      readonly __typename?: 'PublicOrderBook';
      readonly offeringId: string;
      readonly state: Types.OrderBookState;
    };
    readonly investorRelationship: {
      readonly __typename?: 'InvestorRelationship';
      readonly hasRelationshipWithSyndicate: boolean;
      readonly hasRelationshipWithActiveBookrunner: boolean;
    };
    readonly myInstitutionalIndication?: {
      readonly __typename?: 'MyInstitutionalIndication';
      readonly id: string;
      readonly type: Types.InstitutionalIndicationOrderType;
      readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
      readonly status: Types.IndicationStatus;
      readonly currencyCode?: string | null;
      readonly submittedByCmgEntityKey: string;
      readonly version: string;
      readonly trancheId?: string | null;
      readonly instrumentId?: string | null;
      readonly finalAllocation?: {
        readonly __typename?: 'FinalAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly version: string;
        readonly investorReply?: {
          readonly __typename?: 'InvestorReply';
          readonly status: Types.AllocationAcknowledgement;
        } | null;
      } | null;
      readonly interestLevels: ReadonlyArray<{
        readonly __typename?: 'InterestLevel';
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly interestQuantity: number;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }>;
      readonly acknowledgements: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndicationAcknowledgement';
        readonly managerCmgEntityKey: string;
        readonly indicationVersion: string;
        readonly acknowledgedIndicationVersion: string;
      }>;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
      };
    } | null;
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly orderEntryProfile: {
      readonly __typename?: 'OfferingOrderEntryProfile';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly tranches: ReadonlyArray<{
        readonly __typename?: 'TranchePrivileged';
        readonly id: string;
        readonly defaultInstrumentId?: string | null;
        readonly name: string;
        readonly demandCurrencies: ReadonlyArray<string>;
        readonly deliveryInstrumentIds: ReadonlyArray<string>;
      }>;
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentOrderEntryProfile';
        readonly id: string;
        readonly currencyCode?: string | null;
        readonly countryCode?: string | null;
        readonly stockSymbol?: string | null;
        readonly isDepositaryReceipt: boolean;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
};

export type IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type IndicationActivity_CoveredIndicationByCmgEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndicationByCmgEntityKey?: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly offeringId: string;
    readonly status: Types.IndicationStatus;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly trancheId?: string | null;
    readonly currencyCode?: string | null;
    readonly instrumentId?: string | null;
    readonly version: string;
    readonly allocationShares?: number | null;
    readonly allocationVersion?: string | null;
    readonly allocationCurrency?: number | null;
    readonly allocationPercentOfOffering?: number | null;
    readonly indicationPercentFill?: number | null;
    readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
    readonly duplicateOfIndicationId?: string | null;
    readonly duplicateIndicationIds: ReadonlyArray<string>;
    readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly bankInvestorName?: string | null;
      readonly bankInvestorKey?: string | null;
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
      readonly contactName?: string | null;
      readonly contactEmail?: string | null;
      readonly isOnPlatform: boolean;
    };
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly modifiedByFirmKey?: string | null;
    };
    readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationAcknowledgement';
      readonly offeringId: string;
      readonly indicationId: string;
      readonly indicationVersion: string;
      readonly acknowledgedIndicationVersion: string;
      readonly managerCmgEntityKey: string;
      readonly version: string;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly createdAt: string;
        readonly modifiedByUserEmail?: string | null;
        readonly createdByUserEmail?: string | null;
      };
    }>;
  } | null;
};

export type IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables = Types.Exact<{
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery = {
  readonly __typename?: 'Query';
  readonly firmInvestorsRelationships: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmBasic';
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
  }>;
};

export type IndicationActivity_IndicationsInvestorsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type IndicationActivity_IndicationsInvestorsQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly institutionalIndications: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly cmgEntityKey: string;
          readonly cmgEntityName: string;
          readonly bankInvestorKey?: string | null;
          readonly bankInvestorName?: string | null;
        };
      }>;
    };
  };
};

export type IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type IndicationActivity_InvestorFirmByCmgEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly investorFirm: {
    readonly __typename?: 'InvestorFirmBasic';
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
  };
};

export const IndicationActivity_BuySidePassOnOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationActivity_BuySidePassOnOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passOnOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activateIfCancelled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MyInstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationActivity_BuySidePassOnOfferingMutationFn = Apollo.MutationFunction<
  IndicationActivity_BuySidePassOnOfferingMutation,
  IndicationActivity_BuySidePassOnOfferingMutationVariables
>;

/**
 * __useIndicationActivity_BuySidePassOnOfferingMutation__
 *
 * To run a mutation, you first call `useIndicationActivity_BuySidePassOnOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_BuySidePassOnOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationActivityBuySidePassOnOfferingMutation, { data, loading, error }] = useIndicationActivity_BuySidePassOnOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      activateIfCancelled: // value for 'activateIfCancelled'
 *   },
 * });
 */
export function useIndicationActivity_BuySidePassOnOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationActivity_BuySidePassOnOfferingMutation,
    IndicationActivity_BuySidePassOnOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationActivity_BuySidePassOnOfferingMutation,
    IndicationActivity_BuySidePassOnOfferingMutationVariables
  >(IndicationActivity_BuySidePassOnOfferingDocument, options);
}
export type IndicationActivity_BuySidePassOnOfferingMutationHookResult = ReturnType<
  typeof useIndicationActivity_BuySidePassOnOfferingMutation
>;
export type IndicationActivity_BuySidePassOnOfferingMutationResult =
  Apollo.MutationResult<IndicationActivity_BuySidePassOnOfferingMutation>;
export type IndicationActivity_BuySidePassOnOfferingMutationOptions = Apollo.BaseMutationOptions<
  IndicationActivity_BuySidePassOnOfferingMutation,
  IndicationActivity_BuySidePassOnOfferingMutationVariables
>;
export const IndicationActivity_BuySideRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_BuySideRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publicOrderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorRelationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRelationshipWithSyndicate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRelationshipWithActiveBookrunner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInstitutionalIndication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ManageIndication_MyIndicationParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IndicationForm_OfferingParts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_BuySideRouteQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_BuySideRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_BuySideRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_BuySideRouteQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIndicationActivity_BuySideRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_BuySideRouteQuery,
    IndicationActivity_BuySideRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_BuySideRouteQuery,
    IndicationActivity_BuySideRouteQueryVariables
  >(IndicationActivity_BuySideRouteDocument, options);
}
export function useIndicationActivity_BuySideRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_BuySideRouteQuery,
    IndicationActivity_BuySideRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_BuySideRouteQuery,
    IndicationActivity_BuySideRouteQueryVariables
  >(IndicationActivity_BuySideRouteDocument, options);
}
export type IndicationActivity_BuySideRouteQueryHookResult = ReturnType<
  typeof useIndicationActivity_BuySideRouteQuery
>;
export type IndicationActivity_BuySideRouteLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_BuySideRouteLazyQuery
>;
export type IndicationActivity_BuySideRouteQueryResult = Apollo.QueryResult<
  IndicationActivity_BuySideRouteQuery,
  IndicationActivity_BuySideRouteQueryVariables
>;
export const IndicationActivity_CoveredIndicationByCmgEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_CoveredIndicationByCmgEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndicationByCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interestLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationPercentOfOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationPercentFill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acknowledgedIndicationVersion' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_CoveredIndicationByCmgEntityKeyQuery,
    IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_CoveredIndicationByCmgEntityKeyQuery,
    IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables
  >(IndicationActivity_CoveredIndicationByCmgEntityKeyDocument, options);
}
export function useIndicationActivity_CoveredIndicationByCmgEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_CoveredIndicationByCmgEntityKeyQuery,
    IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_CoveredIndicationByCmgEntityKeyQuery,
    IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables
  >(IndicationActivity_CoveredIndicationByCmgEntityKeyDocument, options);
}
export type IndicationActivity_CoveredIndicationByCmgEntityKeyQueryHookResult = ReturnType<
  typeof useIndicationActivity_CoveredIndicationByCmgEntityKeyQuery
>;
export type IndicationActivity_CoveredIndicationByCmgEntityKeyLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_CoveredIndicationByCmgEntityKeyLazyQuery
>;
export type IndicationActivity_CoveredIndicationByCmgEntityKeyQueryResult = Apollo.QueryResult<
  IndicationActivity_CoveredIndicationByCmgEntityKeyQuery,
  IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables
>;
export const IndicationActivity_FirmRelationshipsByCmgEntityKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_FirmRelationshipsByCmgEntityKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmInvestorsRelationships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery({
 *   variables: {
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables
  >(IndicationActivity_FirmRelationshipsByCmgEntityKeysDocument, options);
}
export function useIndicationActivity_FirmRelationshipsByCmgEntityKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
    IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables
  >(IndicationActivity_FirmRelationshipsByCmgEntityKeysDocument, options);
}
export type IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryHookResult = ReturnType<
  typeof useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery
>;
export type IndicationActivity_FirmRelationshipsByCmgEntityKeysLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_FirmRelationshipsByCmgEntityKeysLazyQuery
>;
export type IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryResult = Apollo.QueryResult<
  IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
  IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables
>;
export const IndicationActivity_IndicationsInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_IndicationsInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorInformation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorKey' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_IndicationsInvestorsQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_IndicationsInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_IndicationsInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_IndicationsInvestorsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIndicationActivity_IndicationsInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_IndicationsInvestorsQuery,
    IndicationActivity_IndicationsInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_IndicationsInvestorsQuery,
    IndicationActivity_IndicationsInvestorsQueryVariables
  >(IndicationActivity_IndicationsInvestorsDocument, options);
}
export function useIndicationActivity_IndicationsInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_IndicationsInvestorsQuery,
    IndicationActivity_IndicationsInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_IndicationsInvestorsQuery,
    IndicationActivity_IndicationsInvestorsQueryVariables
  >(IndicationActivity_IndicationsInvestorsDocument, options);
}
export type IndicationActivity_IndicationsInvestorsQueryHookResult = ReturnType<
  typeof useIndicationActivity_IndicationsInvestorsQuery
>;
export type IndicationActivity_IndicationsInvestorsLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_IndicationsInvestorsLazyQuery
>;
export type IndicationActivity_IndicationsInvestorsQueryResult = Apollo.QueryResult<
  IndicationActivity_IndicationsInvestorsQuery,
  IndicationActivity_IndicationsInvestorsQueryVariables
>;
export const IndicationActivity_InvestorFirmByCmgEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_InvestorFirmByCmgEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_InvestorFirmByCmgEntityKeyQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_InvestorFirmByCmgEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_InvestorFirmByCmgEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_InvestorFirmByCmgEntityKeyQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useIndicationActivity_InvestorFirmByCmgEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_InvestorFirmByCmgEntityKeyQuery,
    IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_InvestorFirmByCmgEntityKeyQuery,
    IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables
  >(IndicationActivity_InvestorFirmByCmgEntityKeyDocument, options);
}
export function useIndicationActivity_InvestorFirmByCmgEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_InvestorFirmByCmgEntityKeyQuery,
    IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_InvestorFirmByCmgEntityKeyQuery,
    IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables
  >(IndicationActivity_InvestorFirmByCmgEntityKeyDocument, options);
}
export type IndicationActivity_InvestorFirmByCmgEntityKeyQueryHookResult = ReturnType<
  typeof useIndicationActivity_InvestorFirmByCmgEntityKeyQuery
>;
export type IndicationActivity_InvestorFirmByCmgEntityKeyLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_InvestorFirmByCmgEntityKeyLazyQuery
>;
export type IndicationActivity_InvestorFirmByCmgEntityKeyQueryResult = Apollo.QueryResult<
  IndicationActivity_InvestorFirmByCmgEntityKeyQuery,
  IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeIndicationActivity_BuySideRouteMockResponse = makeMockResponse<
  IndicationActivity_BuySideRouteQueryVariables,
  IndicationActivity_BuySideRouteQuery
>(IndicationActivity_BuySideRouteDocument);

export const makeIndicationActivity_CoveredIndicationByCmgEntityKeyMockResponse = makeMockResponse<
  IndicationActivity_CoveredIndicationByCmgEntityKeyQueryVariables,
  IndicationActivity_CoveredIndicationByCmgEntityKeyQuery
>(IndicationActivity_CoveredIndicationByCmgEntityKeyDocument);

export const makeIndicationActivity_FirmRelationshipsByCmgEntityKeysMockResponse = makeMockResponse<
  IndicationActivity_FirmRelationshipsByCmgEntityKeysQueryVariables,
  IndicationActivity_FirmRelationshipsByCmgEntityKeysQuery
>(IndicationActivity_FirmRelationshipsByCmgEntityKeysDocument);

export const makeIndicationActivity_IndicationsInvestorsMockResponse = makeMockResponse<
  IndicationActivity_IndicationsInvestorsQueryVariables,
  IndicationActivity_IndicationsInvestorsQuery
>(IndicationActivity_IndicationsInvestorsDocument);

export const makeIndicationActivity_InvestorFirmByCmgEntityKeyMockResponse = makeMockResponse<
  IndicationActivity_InvestorFirmByCmgEntityKeyQueryVariables,
  IndicationActivity_InvestorFirmByCmgEntityKeyQuery
>(IndicationActivity_InvestorFirmByCmgEntityKeyDocument);
