import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_RegMWiresDocument,
  SyndicateWires_SendRegMWireMutation,
  useSyndicateWires_SendRegMWireMutation,
} from '../graphql';

export type SendRegMWireMutation = SyndicateWires_SendRegMWireMutation;

export const useSendRegMWireMutation = () => {
  return useSyndicateWires_SendRegMWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_RegMWiresDocument];
  return getManyOperationNames(documentNodes);
}
