import { numericUtil } from '@cmg/common';
import { Skeleton } from '@cmg/design-system';
import isNil from 'lodash/isNil';

import { OfferingStatusChip } from '../../../../design-system/components/data-display/offering-status-chip/OfferingStatusChip';
import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
  OfferingType,
  ProspectusDeliveryStatus,
} from '../../../../graphql';
import { getOfferingTypeLabel } from '../../../offering-setup/utils/offeringTypeUtil';
import { ProspectusDeliveryStatusLabel } from '../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';
import { AttestationFormStatusDisplayName } from '../../../order-book/attestation/Attestation.model';
import AttestationFormStatusRenderer from '../../../order-book/attestation/AttestationFormStatusRenderer';
import ProspectusDeliveryStatusComponent, {
  getProspectusDeliveryLabel,
} from '../../../order-book/components/prospectus-delivery-status/ProspectusDeliveryStatus';
import { RowAlertCellRenderer } from '../../../order-book/institutional-demand/components/institutional-demand-grid/components/row-alert/RowAlertCellRenderer';
import RowAlertHeaderRenderer from '../../../order-book/institutional-demand/components/institutional-demand-grid/components/row-alert/RowAlertHeaderRenderer';
import { rowAlertValueGetter } from '../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/columns-configuration/value-getters/CmgColumnsValueGetters';
import { CMGColumnID } from '../../../order-book/institutional-demand/components/institutional-demand-grid/types';
import { isIndicationStatusAndTypeValid } from '../../../order-book/utils';
import { InvestorNameRenderer } from '../renderers/InvestorRenderer';
import { IssuerNameRenderer } from '../renderers/issuerRenderer';
import AllocationAcknowledgementCell from './allocation-acknowledgement-cell/AllocationAcknowledgementCell';
import BDAgentCell from './bd-agent-cell/BDAgentCell';
import DemandCellRenderer from './demand-cell/DemandCellRenderer';
import { IndicationAcknowledgementCell } from './indication-acknowledgement-cell/IndicationAcknowledgementCell';
import { ColDefMyOrdersGrid, MyOrdersGridCoveredIndication } from './MyOrdersGrid.model';
import { StyledCellRenderer } from './MyOrdersGridColumns.styles';

export enum MyOrderGridOrderField {
  ISSUER_NAME = 'issuer',
  OFFERING_TYPE = 'offeringType',
  OFFERING_STATUS = 'offeringStatus',
  OFFERING_PRICE = 'offeringPrice',
  INVESTOR = 'investorName',
  DEMAND = 'demand',
  ALLOCATION_SHARES = 'allocationShares',
  ALLOCATION_CURRENCY = 'allocationCurrency',
  ALLOCATION_PERCENT = 'allocationPercentOfOffering',
  INDICATION_PERCENT_FILL = 'indicationPercentFill',
  PRICING_DATE = 'pricingDate',
  ACK_ALLOCATION = 'ACK_ALLOCATION',
  ACK_STATUS = 'acknowledgementStatus',
  INSTITUTIONAL_INDICATION_ACKNOWLEDGEMENTS = 'institutionalIndicationAcknowledgements',
  BD_AGENT = 'bdAgent',
  ATTESTATION = 'attestationStatus',
  PROSPECTUS = 'prospectusStatus',
}

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.2,
};

export const numericCellStyle = {
  ...cellStyle,
  textAlign: 'right',
};

export const commonColumnDefinition = {
  suppressColumnsToolPanel: true,
  resizable: true,
  flex: 1,
  minWidth: 80,
  maxWidth: 400,
  cellStyle,
};

const getAllocationValue = ({
  status,
  type,
  value,
}: {
  status: IndicationStatus;
  type: InstitutionalIndicationOrderType;
  value: number | null | undefined;
}) => {
  if (isNil(value)) {
    return value;
  }

  return isIndicationStatusAndTypeValid(status, type) ? value : null;
};

export const myOrdersGridColumns: { [key: string]: ColDefMyOrdersGrid } = {
  [CMGColumnID.ROW_ALERT]: {
    ...commonColumnDefinition,
    colId: CMGColumnID.ROW_ALERT,
    headerName: '',
    field: 'hasAlerts',
    maxWidth: 42,
    minWidth: 42,
    resizable: false,
    sortable: true,
    lockPosition: true,
    headerComponentFramework: ({ columnApi, context, setSort }) => {
      const alertsCount = context.investorsWithInvalidCompliance.length;

      return (
        <RowAlertHeaderRenderer
          alertsCount={alertsCount}
          columnApi={columnApi}
          sortingOrder="desc"
          setSort={setSort}
        />
      );
    },
    cellRendererFramework: ({ data, context }) => {
      const attestationStatus = data.coveredIndicationAttestationStatus;
      const prospectusDeliveryStatus = data.prospectusStatus?.overallDeliveryStatus;

      if (data.offeringType !== OfferingType.Ipo) {
        return null;
      }

      return (
        <RowAlertCellRenderer
          data={data}
          context={context}
          attestationStatus={attestationStatus}
          prospectusDeliveryStatus={prospectusDeliveryStatus}
          isSalesAndTrading={true}
        />
      );
    },
    valueGetter: ({ data }) => {
      if (data.offeringType === OfferingType.Ipo) {
        return 0;
      }

      return rowAlertValueGetter({
        indicationStatus: data.status,
        attestationStatus: data.coveredIndicationAttestationStatus?.status,
        prospectusDeliveryStatus: data.prospectusStatus?.overallDeliveryStatus,
      });
    },
  },
  [MyOrderGridOrderField.ISSUER_NAME]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.ISSUER_NAME,
    field: 'issuer',
    headerName: 'Issuer',
    sort: 'asc',
    lockPosition: true,
    comparator: (lhs, rhs) => {
      if (lhs < rhs) {
        return -1;
      } else if (lhs > rhs) {
        return 1;
      }

      return 0;
    },
    cellRendererFramework: IssuerNameRenderer,
  },
  [MyOrderGridOrderField.ATTESTATION]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.ATTESTATION,
    field: 'attestationStatus',
    headerName: '5130/31 Status',
    cellRendererFramework: ({ data }) => {
      const status = data.coveredIndicationAttestationStatus || {};
      return <AttestationFormStatusRenderer status={status} />;
    },
    equals: (lhs, rhs) => {
      if (lhs && rhs) {
        return lhs.coveredIndicationAttestationStatus === rhs.coveredIndicationAttestationStatus;
      }

      return false;
    },
    valueGetter: ({ data }) => {
      const status = data.coveredIndicationAttestationStatus?.status;
      if (!status) {
        return status;
      }

      return AttestationFormStatusDisplayName[status];
    },
  },
  [MyOrderGridOrderField.OFFERING_TYPE]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.OFFERING_TYPE,
    headerName: 'Offering Type',
    field: 'offeringType',
    cellRendererFramework: ({ data, context }) => {
      let displayValue = '-';
      const value = data.offeringType;
      const offering = context.offerings[data.offeringId];
      const { isExecutingOnPlatform, pricingInstrumentCountryCode } = offering ?? {};

      if (typeof value === 'string') {
        // ECM-46695 - For international offering
        displayValue = getOfferingTypeLabel({
          offeringType: value,
          isExecutingOnPlatform,
          pricingInstrumentCountryCode,
        });
      }

      return <StyledCellRenderer>{displayValue}</StyledCellRenderer>;
    },
  },
  [MyOrderGridOrderField.OFFERING_STATUS]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.OFFERING_STATUS,
    headerName: 'Offering Status',
    field: 'offeringStatus',
    cellRendererFramework: ({ data }) => <OfferingStatusChip status={data.offeringStatus} />,
  },
  [CMGColumnID.INVESTOR]: {
    ...commonColumnDefinition,
    colId: CMGColumnID.INVESTOR,
    headerName: 'Investor',
    field: 'investorInformation.bankInvestorName',
    cellRendererFramework: InvestorNameRenderer,
    valueGetter: ({ data }: { data?: Partial<MyOrdersGridCoveredIndication> }) => {
      return (
        data?.investorInformation?.bankInvestorName ?? data?.investorInformation?.cmgEntityName
      ); // Using value getter because investorInformation is an object.
    },
  },
  [MyOrderGridOrderField.DEMAND]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.DEMAND,
    headerName: 'Demand',
    field: 'interestLevels',
    cellRendererFramework: DemandCellRenderer,
    sortable: false,
  },
  [MyOrderGridOrderField.ALLOCATION_SHARES]: {
    ...commonColumnDefinition,
    cellStyle: numericCellStyle,
    colId: MyOrderGridOrderField.ALLOCATION_SHARES,
    headerName: 'Allocation (Shares)',
    headerClass: 'ag-header-right',
    field: 'allocationShares',
    cellRendererFramework: ({ data }) => {
      const allocationShares = getAllocationValue({
        status: data.status,
        type: data.type,
        value: data.allocationShares,
      });

      return (
        <StyledCellRenderer>
          {' '}
          {numericUtil.getDisplayValueForNumber(allocationShares, 0)}{' '}
        </StyledCellRenderer>
      );
    },
  },
  [MyOrderGridOrderField.ALLOCATION_CURRENCY]: {
    ...commonColumnDefinition,
    cellStyle: numericCellStyle,
    colId: MyOrderGridOrderField.ALLOCATION_CURRENCY,
    headerName: 'Allocation (Currency)',
    headerClass: 'ag-header-right',
    field: 'interestLevels',
    cellRendererFramework: ({ data }) => {
      const allocationCurrency = getAllocationValue({
        status: data.status,
        type: data.type,
        value: data.allocationCurrency,
      });

      return (
        <StyledCellRenderer>
          {' '}
          {numericUtil.getDisplayValueForCurrency(
            allocationCurrency,
            0,
            data.pricingCurrencyCode
          )}{' '}
        </StyledCellRenderer>
      );
    },
  },
  [MyOrderGridOrderField.ALLOCATION_PERCENT]: {
    ...commonColumnDefinition,
    cellStyle: numericCellStyle,
    colId: MyOrderGridOrderField.ALLOCATION_PERCENT,
    headerName: '% of Offering',
    headerClass: 'ag-header-right',
    field: 'allocationPercentOfOffering',
    cellRendererFramework: ({ data }) => {
      const allocationPercentOfOffering = getAllocationValue({
        status: data.status,
        type: data.type,
        value: data.allocationPercentOfOffering,
      });

      return (
        <StyledCellRenderer>
          {' '}
          {numericUtil.getDisplayValueForPercents(allocationPercentOfOffering, 2)}{' '}
        </StyledCellRenderer>
      );
    },
  },
  [MyOrderGridOrderField.INDICATION_PERCENT_FILL]: {
    ...commonColumnDefinition,
    cellStyle: numericCellStyle,
    colId: MyOrderGridOrderField.INDICATION_PERCENT_FILL,
    headerName: 'Fill(%)',
    headerClass: 'ag-header-right',
    field: 'indicationPercentFill',
    cellRendererFramework: ({ data }) => (
      <StyledCellRenderer>
        {' '}
        {numericUtil.getDisplayValueForPercents(data.indicationPercentFill, 2)}{' '}
      </StyledCellRenderer>
    ),
  },
  [MyOrderGridOrderField.PRICING_DATE]: {
    ...commonColumnDefinition,
    colId: MyOrderGridOrderField.PRICING_DATE,
    field: MyOrderGridOrderField.PRICING_DATE,
    headerName: 'Pricing Date',
    cellRendererFramework: ({ data }) => <div> {data.pricingDate} </div>,
  },
  [MyOrderGridOrderField.OFFERING_PRICE]: {
    ...commonColumnDefinition,
    cellStyle: numericCellStyle,
    colId: MyOrderGridOrderField.OFFERING_PRICE,
    field: MyOrderGridOrderField.OFFERING_PRICE,
    headerName: 'Offering Price',
    headerClass: 'ag-header-right',
    cellRendererFramework: ({ data }) => (
      <StyledCellRenderer>
        {' '}
        {numericUtil.getDisplayValueForCurrency(
          data.offeringPrice,
          2,
          data.pricingCurrencyCode
        )}{' '}
      </StyledCellRenderer>
    ),
  },
  [MyOrderGridOrderField.ACK_STATUS]: {
    ...commonColumnDefinition,
    field: MyOrderGridOrderField.ACK_STATUS,
    colId: MyOrderGridOrderField.ACK_STATUS,
    headerName: 'Allocation Acknowledgement',
    cellRendererFramework: AllocationAcknowledgementCell,
  },
  [MyOrderGridOrderField.INSTITUTIONAL_INDICATION_ACKNOWLEDGEMENTS]: {
    ...commonColumnDefinition,
    field: MyOrderGridOrderField.INSTITUTIONAL_INDICATION_ACKNOWLEDGEMENTS,
    colId: MyOrderGridOrderField.INSTITUTIONAL_INDICATION_ACKNOWLEDGEMENTS,
    headerName: 'Acknowledged',
    cellRendererFramework: IndicationAcknowledgementCell,
    valueGetter: ({ data, context }) => {
      const fieldData = data?.institutionalIndicationAcknowledgements;
      const offering = context.offerings[data.offeringId];

      if (offering == null) {
        return null;
      }

      if (data.status === IndicationStatus.Duplicate) {
        return '-';
      }

      if (Array.isArray(fieldData)) {
        return fieldData
          .map(acknowledgment => {
            const manager = offering.managers.find(
              manager => manager.cmgEntityKey === acknowledgment.managerCmgEntityKey
            );
            const { managerCmgEntityKey } = acknowledgment;
            const { firmNameAbbreviation, firmName } = manager ?? {};
            return firmNameAbbreviation ?? firmName ?? managerCmgEntityKey;
          })
          .filter(Boolean)
          .join(',');
      }

      return fieldData;
    },
  },
  [MyOrderGridOrderField.BD_AGENT]: {
    ...commonColumnDefinition,
    field: 'billingAndDeliveryAgentCmgEntityKey',
    colId: MyOrderGridOrderField.BD_AGENT,
    headerName: 'B&D Agent',
    cellRendererFramework: BDAgentCell,
    valueGetter: ({ data, context }) => {
      const offering = context.offerings[data.offeringId];

      if (offering == null) {
        return null;
      }

      const manager = offering.managers.find(
        ({ cmgEntityKey }) => cmgEntityKey === data.billingAndDeliveryAgentCmgEntityKey
      );

      return manager?.firmName ?? '-';
    },
  },
  [MyOrderGridOrderField.PROSPECTUS]: {
    ...commonColumnDefinition,
    field: MyOrderGridOrderField.PROSPECTUS,
    colId: MyOrderGridOrderField.PROSPECTUS,
    headerName: 'Prospectus Contacts',
    headerValueGetter: () => 'Prospectus Contacts ⓘ',
    headerTooltip: 'Investor contacts that have been sent prospectus documents.',
    valueGetter: ({ data }) => {
      const { sentEmailCount = 0, totalEmailCount = 0 } = data.prospectusDeliveryStatus ?? {};

      const label = getProspectusDeliveryLabel({ sentEmailCount, totalEmailCount });
      return totalEmailCount > 0
        ? label
        : ProspectusDeliveryStatusLabel[ProspectusDeliveryStatus.NotSent];
    },
    cellRendererFramework: ({ data }) => {
      const {
        overallDeliveryStatus = ProspectusDeliveryStatus.NotSent,
        sentEmailCount = 0,
        totalEmailCount = 0,
      } = data.prospectusStatus ?? {};

      const isLoading = data.prospectusStatus === undefined;

      if (isLoading) {
        return <Skeleton role="loading" variant="rectangular" height={10} />;
      }

      return (
        <ProspectusDeliveryStatusComponent
          overallDeliveryStatus={overallDeliveryStatus}
          sentEmailCount={sentEmailCount}
          totalEmailCount={totalEmailCount}
        />
      );
    },
  },
};

export const getColumns = ({
  columnsOrder,
  displayAlert,
}: {
  columnsOrder: string[];
  displayAlert: boolean;
}): ColDefMyOrdersGrid[] => {
  const sortFn = (a: ColDefMyOrdersGrid, b: ColDefMyOrdersGrid) =>
    columnsOrder.indexOf(a.colId!) - columnsOrder.indexOf(b.colId!);
  return [
    {
      ...myOrdersGridColumns[CMGColumnID.ROW_ALERT],
      hide: !displayAlert,
    },
    myOrdersGridColumns[MyOrderGridOrderField.ISSUER_NAME],
    myOrdersGridColumns[MyOrderGridOrderField.OFFERING_TYPE],
    myOrdersGridColumns[MyOrderGridOrderField.OFFERING_STATUS],
    myOrdersGridColumns[CMGColumnID.INVESTOR],
    myOrdersGridColumns[MyOrderGridOrderField.DEMAND],
    myOrdersGridColumns[MyOrderGridOrderField.INSTITUTIONAL_INDICATION_ACKNOWLEDGEMENTS],
    myOrdersGridColumns[MyOrderGridOrderField.BD_AGENT],
    myOrdersGridColumns[MyOrderGridOrderField.OFFERING_PRICE],
    myOrdersGridColumns[MyOrderGridOrderField.ALLOCATION_SHARES],
    myOrdersGridColumns[MyOrderGridOrderField.ALLOCATION_CURRENCY],
    myOrdersGridColumns[MyOrderGridOrderField.ALLOCATION_PERCENT],
    myOrdersGridColumns[MyOrderGridOrderField.INDICATION_PERCENT_FILL],
    myOrdersGridColumns[MyOrderGridOrderField.ACK_STATUS],
    myOrdersGridColumns[MyOrderGridOrderField.ATTESTATION],
    myOrdersGridColumns[MyOrderGridOrderField.PROSPECTUS],
  ].sort(sortFn);
};
