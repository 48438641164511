import { Option } from '@cmg/common';

import { OfferingStatus, OfferingType } from '../../../graphql';

export enum InternationalOfferingType {
  IntIpo = 'INTL_IPO',
  IntIpoSpac = 'INTL_IPO_SPAC',
  FullyMarketed = 'FULLY_MARKETED',
  ABBABO = 'ABB_ABO',
  Block = 'BLOCK',
}

export const internationalOfferingTypeLabels: Record<InternationalOfferingType, string> = {
  [InternationalOfferingType.IntIpo]: 'IPO',
  [InternationalOfferingType.IntIpoSpac]: 'IPO (SPAC)',
  [InternationalOfferingType.FullyMarketed]: 'Fully Marketed Offering',
  [InternationalOfferingType.ABBABO]: 'ABB/ABO',
  [InternationalOfferingType.Block]: 'Block',
};

export const internationalToUsOfferingType: Record<InternationalOfferingType, OfferingType> = {
  [InternationalOfferingType.IntIpo]: OfferingType.Ipo,
  [InternationalOfferingType.IntIpoSpac]: OfferingType.IpoSpac,
  [InternationalOfferingType.FullyMarketed]: OfferingType.MarketedFo,
  [InternationalOfferingType.ABBABO]: OfferingType.OvernightFo,
  [InternationalOfferingType.Block]: OfferingType.UnregisteredBlock,
};

export const offeringTypeLabels: Record<
  Exclude<OfferingType, OfferingType.RegisteredDirect>,
  string
> = {
  [OfferingType.Ipo]: 'IPO',
  [OfferingType.IpoSpac]: 'IPO (SPAC)',
  [OfferingType.FollowOn]: 'Follow-on',
  [OfferingType.MarketedFo]: 'Marketed FO',
  [OfferingType.OvernightFo]: 'Overnight FO',
  [OfferingType.RegisteredBlock]: 'Registered Block',
  [OfferingType.UnregisteredBlock]: 'Unregistered Block',
  [OfferingType.Rights]: 'Rights',
};

export const usOfferingTypeToInternational: Record<OfferingType, InternationalOfferingType> =
  Object.entries(internationalToUsOfferingType).reduce<
    Record<OfferingType, InternationalOfferingType>
  >((acc, [key, value]) => {
    acc[value as OfferingType] = key as InternationalOfferingType;
    return acc;
  }, {} as Record<OfferingType, InternationalOfferingType>); // eslint-disable-line

export const offeringTypesOptions = Object.keys(offeringTypeLabels).map(
  type =>
    ({
      label: offeringTypeLabels[type],
      value: type,
    } as Option<OfferingType>)
);

export const extendedOfferingTypLabels = {
  ...offeringTypeLabels,
  ...internationalOfferingTypeLabels,
};

export const extendedOfferingTypesOptions = Object.keys(extendedOfferingTypLabels).map(
  type =>
    ({
      label: extendedOfferingTypLabels[type],
      value: type,
    } as Option<InternationalOfferingType & OfferingType>)
);

export const offeringStatusLabels: Record<OfferingStatus, string> = {
  [OfferingStatus.Filed]: 'Filed',
  [OfferingStatus.Live]: 'Live',
  [OfferingStatus.Priced]: 'Priced',
  [OfferingStatus.Postponed]: 'Postponed',
  [OfferingStatus.Withdrawn]: 'Withdrawn',
  [OfferingStatus.Terminated]: 'Terminated',
};

export const offeringStatusesOptions = Object.keys(OfferingStatus).map(
  status =>
    ({
      label: offeringStatusLabels[status],
      value: status,
    } as Option<OfferingStatus>)
);
