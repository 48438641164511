import { errorUtil, loggerUtil } from '@cmg/common';

export const pushApi = {
  getSubscription: async ({ registration }: { registration: ServiceWorkerRegistration }) =>
    await registration.pushManager.getSubscription(),
  subscribe: async ({
    registration,
    publicKey,
  }: {
    registration: ServiceWorkerRegistration;
    publicKey: string;
  }) => {
    try {
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey,
      });
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(new Error('PushManager failed to create subscription'), error);
      return null;
    }
  },
  unsubscribe: async ({ subscription }: { subscription: PushSubscription }) => {
    try {
      await subscription.unsubscribe();
      return subscription;
    } catch (error) {
      errorUtil.assertIsError(error);
      loggerUtil.error(new Error('PushManager failed to unsubscribe'), error);
      return null;
    }
  },
};
