import { UUID } from '@cmg/common';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import entitlementsRouteFactory from './entitlementsRouteFactory';
import OfferingSetupEntitlements from './offering-setup/OfferingSetupEntitlements';
import OrderBookEntitlements from './order-book/OrderBookEntitlements';
import RoadshowEntitlements from './roadshow/RoadshowEntitlements';
import SyndicateWiresEntitlements from './syndicate-wires/SyndicateWiresEntitlements';

export type Props = { offeringId: UUID };

/**
 * EntitlementsRoute switch that displays entitlement routes by service
 */
const EntitlementsRoute: React.FC<Props> = ({ offeringId }) => {
  return (
    <Switch>
      <Route
        path={entitlementsRouteFactory.offeringSetupEntitlements.routePath}
        component={OfferingSetupEntitlements}
      />
      <Route
        path={entitlementsRouteFactory.orderBookEntitlements.routePath}
        component={OrderBookEntitlements}
      />
      <Route
        path={entitlementsRouteFactory.roadshowEntitlements.routePath}
        component={RoadshowEntitlements}
      />
      <Route
        path={entitlementsRouteFactory.syndicateWiresEntitlements.routePath}
        component={SyndicateWiresEntitlements}
      />
      <Redirect
        to={entitlementsRouteFactory.offeringSetupEntitlements.getUrlPath({ offeringId })}
      />
    </Switch>
  );
};

export default EntitlementsRoute;
