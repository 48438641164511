import { formatters } from '@cmg/data-grid';
import { Tooltip } from '@cmg/design-system';
import React from 'react';

import { DemandGridCellRendererProps } from '../../types';

export type Props = DemandGridCellRendererProps<string>;

/**
 * Renders the Sales Coverage Employee Relationship Name Cell within the Institutional Demand Grid.
 */
const SalesCoverageCellRenderer: React.FC<Props> = ({ data, valueFormatted }) => {
  return (
    <Tooltip
      variant="info"
      placement="top"
      title={data?.salesCoverage?.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ))}
    >
      <span>{formatters.truncatedArrayValueFormatter(data?.salesCoverage)}</span>
    </Tooltip>
  );
};

export default SalesCoverageCellRenderer;
