import styled from 'styled-components/macro';

export const SManagerTitle = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.dark};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SManagerRole = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.foundation.colors.secondary[500]};
  overflow: hidden;
  text-overflow: ellipsis;
`;
