import { FormField, SelectField } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../../components/form/OfferingSetupForm';

export const StyledFilingGroup = styled(SetupForm.Row)`
  align-items: flex-end;
  justify-content: space-between;
  border-top: ${({ theme }) => theme.border.smallSolidLight};
`;

export const SFilingGroupTitle = styled.div`
  flex-basis: 30%;
  align-self: flex-start;
`;

export const SFilingGroupFields = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledColumn = styled(SetupForm.Column)`
  flex: 1;
`;

export const StyledActions = styled.div`
  margin-left: auto;
`;

export const StyledRowAlignBottom = styled(SetupForm.Row)`
  align-items: flex-end;
`;

export const StyledFilingTypeField = styled(FormField)`
  margin-bottom: 0;
  span {
    margin-left: 10px;
    font-weight: ${({ theme }) => theme.text.weight.medium};
  }
  label {
    margin: 0;
  }
`;

export const StyledFormField = styled(FormField)`
  label {
    display: block;
  }
`;

export const StyledCardHeader = styled(SetupForm.CardHeader)`
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledSelectField = styled(SelectField)`
  label {
  }
`;
