/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemand_UpdateFinalAllocationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.FinalAllocationsUpdateInput;
}>;

export type InstitutionalDemand_UpdateFinalAllocationsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFinalInstitutionalAllocations:
    | {
        readonly __typename?: 'FinalAllocations';
        readonly finalAllocations: ReadonlyArray<{
          readonly __typename?: 'FinalAllocation';
          readonly id: string;
          readonly shareQuantity?: number | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const InstitutionalDemand_UpdateFinalAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InstitutionalDemand_UpdateFinalAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FinalAllocationsUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinalInstitutionalAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FinalAllocations' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finalAllocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemand_UpdateFinalAllocationsMutationFn = Apollo.MutationFunction<
  InstitutionalDemand_UpdateFinalAllocationsMutation,
  InstitutionalDemand_UpdateFinalAllocationsMutationVariables
>;

/**
 * __useInstitutionalDemand_UpdateFinalAllocationsMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemand_UpdateFinalAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemand_UpdateFinalAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandUpdateFinalAllocationsMutation, { data, loading, error }] = useInstitutionalDemand_UpdateFinalAllocationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemand_UpdateFinalAllocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemand_UpdateFinalAllocationsMutation,
    InstitutionalDemand_UpdateFinalAllocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemand_UpdateFinalAllocationsMutation,
    InstitutionalDemand_UpdateFinalAllocationsMutationVariables
  >(InstitutionalDemand_UpdateFinalAllocationsDocument, options);
}
export type InstitutionalDemand_UpdateFinalAllocationsMutationHookResult = ReturnType<
  typeof useInstitutionalDemand_UpdateFinalAllocationsMutation
>;
export type InstitutionalDemand_UpdateFinalAllocationsMutationResult =
  Apollo.MutationResult<InstitutionalDemand_UpdateFinalAllocationsMutation>;
export type InstitutionalDemand_UpdateFinalAllocationsMutationOptions = Apollo.BaseMutationOptions<
  InstitutionalDemand_UpdateFinalAllocationsMutation,
  InstitutionalDemand_UpdateFinalAllocationsMutationVariables
>;
