import styled from 'styled-components/macro';

export const SFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SButtonGroup = styled.div`
  display: flex;

  > * {
    margin-right: 10px;
  }

  *:last-child {
    margin-right: 0;
  }
`;

export const SChangesGrid = styled.div`
  display: grid;
  grid-template-columns: auto 140px 140px;
`;

export const STextLight = styled.span`
  color: ${({ theme }) => `${theme.text.color.light}`};
`;

export const STextBold = styled.span`
  font-weight: ${({ theme }) => `${theme.text.weight.bold}`};
`;
