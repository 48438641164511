import { managerRoleLabels } from '../../../../../../../types/domain/manager/constants';
import { ManagerEconomicBreakdownRow } from '../../EconomicBreakdownGrid.model';
import { SplitCellWrapper } from '../split-cell-wrapper/SplitCellWrapper';
import { SManagerRole, SManagerTitle } from './ManagerCellRenderer.styles';

type Props = { data: ManagerEconomicBreakdownRow };

export const ManagerCellRenderer: React.FC<Props> = ({ data }) => {
  return (
    <SplitCellWrapper align="left">
      <SManagerTitle>{data.manager}</SManagerTitle>
      <SManagerRole>
        {data.managerRole !== null ? managerRoleLabels[data.managerRole] : ''}
      </SManagerRole>
    </SplitCellWrapper>
  );
};
