import { ToastManager } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import FlexLayout from '../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../common/util/routeFactory';
import RoadshowCreationStep from '../common/creation-step/RoadshowCreationStep';
import RoadshowSideNav from '../common/roadshow-side-nav/RoadshowSideNav';
import RoadshowDaysAndLocations from '../roadshow-details/days-and-locations/RoadshowDaysAndLocations';
import { useRoadshowUniqueNameValidation } from '../roadshow-details/hooks/useRoadshowUniqueNameValidation';
import RoadshowInformation from '../roadshow-details/roadshow-information/RoadshowInformation';
import {
  getRoadshowDetailsSchema,
  mapFormToRoadshow,
  roadshowDetailsInitialValues,
  RoadshowDetailsValues,
} from '../roadshow-details/RoadshowDetails.model';
import CreateRoadshowHeader from './header/CreateRoadshowHeader';
import { useCreateRoadshowMutation } from './hooks/useCreateRoadshowMutation';

const CreateRoadshowRoute: React.FC = () => {
  const [createRoadshow, { loading }] = useCreateRoadshowMutation();
  const validateRoadshowUniqueName = useRoadshowUniqueNameValidation();

  const history = useHistory();

  const handleCreate = async (values: RoadshowDetailsValues) => {
    try {
      const { data } = await createRoadshow({
        variables: {
          roadshow: mapFormToRoadshow(values),
        },
      });

      history.push(
        routeFactory.roadshowCompanyDetails.getUrlPath({ roadshowId: data?.createRoadshow.id! })
      );
      ToastManager.success('Roadshow was created successfully.');
    } catch {
      ToastManager.error('There was an error while creating Roadshow');
    }
  };

  const formik = useFormik<RoadshowDetailsValues>({
    validationSchema: getRoadshowDetailsSchema(validateRoadshowUniqueName),
    initialValues: roadshowDetailsInitialValues,
    validateOnMount: true,
    onSubmit: handleCreate,
  });

  return (
    <FlexLayout direction="column" data-test-id="roadshows / single-roadshow / new roadshow">
      <CreateRoadshowHeader
        onCreate={formik.handleSubmit}
        createDisabled={!formik.isValid}
        createLoading={loading}
      />
      <FlexLayout direction="row">
        <RoadshowSideNav />
        <RoadshowCreationStep>
          <RoadshowCreationStep.Header title="Roadshow Details" />
          <RoadshowCreationStep.Content>
            <FormikProvider value={formik}>
              <Form data-test-id="roadshow details form">
                <RoadshowInformation />
                <RoadshowDaysAndLocations />
              </Form>
            </FormikProvider>
          </RoadshowCreationStep.Content>
        </RoadshowCreationStep>
      </FlexLayout>
    </FlexLayout>
  );
};

export default CreateRoadshowRoute;
