import { Box, Typography } from '@cmg/design-system';

import { sortCaseInsensitive } from '../../../../../../../common/util/sortCaseInsensitive';
import { IndicationForm_IndicationAcknowledgementsQuery } from '../../../../../../offering-side-panel/order-book/indication-activity/components/indication-form/components/indication-details/components/acknowledgement/graphql/__generated__';
import { getFirmDisplayName } from '../../../../utils';
import { InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment } from '../../graphql';
import { SyndicateManager } from '../../types';

export const getAcknowledgementsList = (
  institutionalIndicationAcknowledgements: readonly InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment[],
  currentIndicationVersion: string,
  oidcUserCmgEntityKey: string | null
) => {
  if (!institutionalIndicationAcknowledgements) {
    return [];
  }

  return institutionalIndicationAcknowledgements.filter(ack => {
    return (
      ack.managerCmgEntityKey === oidcUserCmgEntityKey ||
      ack.acknowledgedIndicationVersion === currentIndicationVersion
    );
  });
};

export const getAcknowledgmentPopoverContent = (
  acknowledgementsList: readonly InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment[],
  managers: Record<string, SyndicateManager>
) => {
  return acknowledgementsList?.map(acknowledgment => {
    const manager = managers[acknowledgment.managerCmgEntityKey];

    return (
      <div key={manager?.cmgEntityKey}>
        {getFirmDisplayName(manager ?? null, {
          ignoreAbbreviation: true,
          defaultName: manager?.cmgEntityKey,
        })}
      </div>
    );
  });
};

export const getAcknowledgmentTooltipContent = (
  acknowledgementsList: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements'],
  managers: Record<string, SyndicateManager>,
  oidcUserCmgEntityKey: string,
  currentIndicationVersion: string,
  hasRevised: boolean
) => {
  const acknowledgments = acknowledgementsList.map((acknowledgment, index) => (
    <Typography
      variant="tooltip"
      key={`ack-${index}`}
      component="div"
      fontWeight={
        acknowledgment.managerCmgEntityKey === oidcUserCmgEntityKey &&
        acknowledgment.acknowledgedIndicationVersion !== currentIndicationVersion
          ? 'bold'
          : undefined
      }
      color={
        acknowledgment.acknowledgedIndicationVersion !== currentIndicationVersion
          ? theme => theme.palette.warning.dark
          : undefined
      }
    >
      {getFirmDisplayName(managers[acknowledgment.managerCmgEntityKey] ?? null, {
        ignoreAbbreviation: true,
        defaultName: managers[acknowledgment.managerCmgEntityKey]?.cmgEntityKey,
      })}
    </Typography>
  ));
  return (
    <Box
      sx={{
        width: 200,
      }}
    >
      {acknowledgments}
      {hasRevised && (
        <Typography variant="tooltip" component="div" marginTop={0.5} color="text.disabled">
          Highlighted names show indication has been revised since firm’s last acknowledgement.
        </Typography>
      )}
    </Box>
  );
};

export const getSortedAcknowledgementsList = (
  managers: Record<string, SyndicateManager>,
  oidcUserCmgEntityKey: string,
  acknowledgementsList?: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements']
) => {
  return (
    acknowledgementsList?.slice().sort((a, b) => {
      if (a?.managerCmgEntityKey === oidcUserCmgEntityKey) {
        return -1;
      }
      if (b?.managerCmgEntityKey === oidcUserCmgEntityKey) {
        return 1;
      }

      return sortCaseInsensitive(
        managers[a.managerCmgEntityKey]?.firmNameAbbreviation ||
          managers[a.managerCmgEntityKey]?.firmName ||
          '',
        managers[b.managerCmgEntityKey]?.firmNameAbbreviation ||
          managers[b.managerCmgEntityKey]?.firmName ||
          ''
      );
    }) || []
  );
};
