import React from 'react';

import { BoundedContext } from '../../../../../graphql';
import ConfigurationTable from '../components/configuration-table/ConfigurationTable';
import { EntitlementsDataByManagerContext } from '../hooks/EntitlementsDataByManagerContext';
import { useEntitlementConfigurationByBoundingContext } from '../hooks/useEntitlementConfigurationByBoundingContext';

const OrderBookEntitlements: React.FC = () => {
  const entitlementConfiguration = useEntitlementConfigurationByBoundingContext({
    boundedContext: BoundedContext.OrderBook,
  });
  const { selectedManagerEntitlements } = React.useContext(EntitlementsDataByManagerContext);
  return (
    <ConfigurationTable
      managerEntitlements={selectedManagerEntitlements}
      configuration={entitlementConfiguration}
    />
  );
};

export default OrderBookEntitlements;
