import React from 'react';

import { useIndicationActivitySalesAndTradingContext } from '../../../../context/IndicationActivitySalesAndTradingContext';
import DuplicateIndicationBannerComponent from '../../common/duplicate-indication-banner/DuplicateIndicationBanner';
import { useDupeIndicationBanner_CoveredIndicationEntityKeyQuery } from './qraphql/__generated__';

const DuplicateIndicationBanner: React.FC = () => {
  const { offeringId, coveredIndication } = useIndicationActivitySalesAndTradingContext();

  const { data, loading } = useDupeIndicationBanner_CoveredIndicationEntityKeyQuery({
    variables: { indicationId: coveredIndication!.duplicateOfIndicationId, offeringId },
  });

  const investorInformation = data?.coveredIndication?.investorInformation;

  return (
    <DuplicateIndicationBannerComponent
      duplicateCmgEntityKey={investorInformation?.cmgEntityKey}
      cmgEntityName={investorInformation?.cmgEntityName}
      bankInvestorName={investorInformation?.bankInvestorName}
      isLoading={loading}
    />
  );
};

export default DuplicateIndicationBanner;
