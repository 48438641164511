import { UUID } from '@cmg/common';
import { SxProps, Tab, Tabs, Theme } from '@cmg/design-system';
import React from 'react';

import { Metric } from '../../../../../common/components/data-display/metric';
import useGetOfferingShares from '../../../hooks/useGetOfferingShares';
import {
  getIndicationSummaryMetrics,
  getMetricsMap,
  IndicationSummaryMetricsArgs,
} from './IndicationSummaryMetrics.model';

type OwnProps = Readonly<{ offeringId: UUID; isHidden: boolean; loading: boolean }>;

export type Props = Readonly<Omit<IndicationSummaryMetricsArgs, 'baseOfferingSize'>> & OwnProps;

const TAB_STYLE: SxProps<Theme> = {
  flex: 1,
  minWidth: 'fit-content',
};

const IndicationSummaryMetrics: React.FC<Props> = ({
  selectedIndicationIds,
  indications,
  referencePrice,
  offeringId,
  loading,
  isHidden,
}) => {
  const { shares } = useGetOfferingShares({ offeringId });

  const metrics = React.useMemo(() => {
    const metricValues = getIndicationSummaryMetrics({
      indications,
      selectedIndicationIds,
      referencePrice,
      baseOfferingSize: shares?.baseDeal ?? null,
    });

    return Object.entries(getMetricsMap(metricValues));
  }, [indications, referencePrice, selectedIndicationIds, shares?.baseDeal]);

  if (isHidden) {
    return null;
  }

  return (
    <Tabs
      aria-label="Indication Summary Metrics"
      role={loading ? 'progressbar' : undefined}
      value={false}
      variant="scrollable"
      scrollButtons="auto"
    >
      {metrics.map(([label, value]) => (
        <Tab
          key={label}
          sx={TAB_STYLE}
          disabled
          label={<Metric label={label} value={value} loading={loading} />}
        />
      ))}
    </Tabs>
  );
};

export default IndicationSummaryMetrics;
