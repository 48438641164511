import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';
import { ManagerRetention } from './retention-table/components/ManagerRetention';

export const RetentionWireForm: React.FC<CreateOrUpdateFormProps> = ({
  managers,
  offeringId,
  onFormDataLoaded,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <ManagerRetention
          managers={managers}
          offeringId={offeringId}
          onFormDataLoaded={onFormDataLoaded}
        />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
