import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import Entitlements from './entitlements/Entitlements';
import Managers from './managers/Managers';
import { ManagersOrder } from './ManagersOrder.context';

export type RouteProps = RouteComponentProps<{
  offeringId: UUID;
  stepId: string;
}>;

/**
 * Primary Route for the Offering Setup module
 */
export const ManagersRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const [managersOrder, setManagersOrder] = React.useState<string[]>([]);
  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <ManagersOrder.Provider value={{ managersOrder, setManagersOrder }}>
        <SetupScreen>
          <SetupScreen.Header />
          <Managers offeringId={match.params.offeringId} />
          <Entitlements offeringId={match.params.offeringId} />
        </SetupScreen>
      </ManagersOrder.Provider>
    </OfferingSetupPage>
  );
};

export default ManagersRoute;
