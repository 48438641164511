import { FormField, numericUtil, Option, timeUtil } from '@cmg/common';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { RpnBasisCodeType } from '../../../../../graphql';
import { getLabelFromOptions } from '../../../common/form-utils';
import { SDescription } from '../../../RegulatoryFilingsRoute.styles';
import { RegulatoryFilings_RpnFilingPartsFragment } from '../graphql';

const basisCodeOptions: Option<RpnBasisCodeType>[] = [
  {
    label: 'Underwriter Activity Report',
    value: RpnBasisCodeType.Uar,
  },
  {
    label: 'ADTV and Public Float',
    value: RpnBasisCodeType.AdtvPfv,
  },
  {
    label: 'Other',
    value: RpnBasisCodeType.Other,
  },
  {
    label: '5D',
    value: RpnBasisCodeType.FiveDays,
  },
];

export type Props = {
  detail: RegulatoryFilings_RpnFilingPartsFragment;
};

const BasisCodeDetail: React.FC<Props> = ({ detail }) => {
  return (
    <div>
      <GridLayout>
        <Column span={3}>
          <FormField withMargin fullWidth label="Basis Code">
            <div aria-label="Basis Code">
              {getLabelFromOptions(detail.basisCode, basisCodeOptions)}
            </div>
          </FormField>
        </Column>
      </GridLayout>
      {detail.basisCode === RpnBasisCodeType.Uar && (
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Underwriter Activity Report Date">
              <div aria-label="Underwriter Activity Report Date">
                {timeUtil.formatAsDisplayDate(detail.uarDate!)}
              </div>
            </FormField>
          </Column>
        </GridLayout>
      )}
      {detail.basisCode === RpnBasisCodeType.AdtvPfv && (
        <div>
          <GridLayout>
            <Column span={3}>
              <FormField withMargin fullWidth label="ADTV Number">
                <div aria-label="ADTV Number">
                  {numericUtil.formatCurrency(detail.adtvNumber, 6)}
                </div>
              </FormField>
            </Column>
            <Column span={3}>
              <FormField withMargin fullWidth label="ADTV Source">
                <div aria-label="ADTV Source">{detail.adtvSource}</div>
              </FormField>
            </Column>
          </GridLayout>
          <GridLayout>
            <Column span={3}>
              <FormField withMargin fullWidth label="Public Float Value">
                <div aria-label="Public Float Value">
                  {numericUtil.formatCurrency(detail.publicFloatValue, 6)}
                </div>
              </FormField>
            </Column>
            <Column span={3}>
              <FormField withMargin label="Public Float Value Source">
                <div aria-label="Public Float Value Source">{detail.publicFloatValueSource}</div>
              </FormField>
            </Column>
          </GridLayout>
        </div>
      )}
      {detail.basisCode === RpnBasisCodeType.Other && (
        <GridLayout>
          <Column span={6}>
            <FormField withMargin fullWidth label="Other Basis Text">
              <SDescription aria-label="Other Basis Text">
                {detail.otherBasisDescription}
              </SDescription>
            </FormField>
          </Column>
        </GridLayout>
      )}
    </div>
  );
};

export default BasisCodeDetail;
