import { LinkButton, loggerUtil, ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useFeatureToggles } from '../../../../../../../common/config';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { stepIds } from '../../../../../../offering-setup/constants';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { WireTypeValidation } from '../../../context/WireTypeConfigContext.model';
import { OfferingValidationErrorSection } from '../offering-validation-error-section/OfferingValidationErrorSection';
import { ValidationErrorSection } from '../validation-error-section/ValidationErrorSection';
import {
  groupManagerValidationBySteps,
  groupValidationBySteps,
  wireValidationsToString,
} from '../wire-validation/WireValidation.model';
import { SSectionsContainer, StyledCopyToClipboardIcon } from './ValidationErrorList.styles';

export type Props = {
  validation: WireTypeValidation;
  managers: SyndicateWireManager[];
  hasPermissions: boolean;
  offeringId: UUID;
};

export const ValidationErrorList: React.FC<Props> = ({
  validation,
  managers,
  hasPermissions,
  offeringId,
}) => {
  const { log } = loggerUtil.useMixpanel();
  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();

  const handleCopy = () => {
    ToastManager.success('Deal information copied to clipboard.');
    log('Validation copied to clipboard');
  };

  const hasOfferingErrors = !!validation.offeringErrors.length;
  const hasManagerErrors = !!validation.managers.length;
  const hasOrderBookErrors = !!validation.orderBookErrors.length;
  const hasSettlementErrors = !!validation.settlementErrors.length;

  return (
    <SSectionsContainer>
      {(hasOfferingErrors || hasManagerErrors) && (
        <OfferingValidationErrorSection
          title="Offering Details"
          urlPath={routeFactory.offeringSetup.getUrlPath({ stepId: stepIds.SUMMARY, offeringId })}
          offeringValidation={groupValidationBySteps(
            validation.offeringErrors,
            isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
          )}
          managersValidation={groupManagerValidationBySteps(
            validation.managers,
            managers,
            isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
          )}
          hasPermission={hasPermissions}
        />
      )}
      {hasOrderBookErrors && (
        <ValidationErrorSection
          title="Order Book"
          urlPath={routeFactory.orderBookTotalRetailDemand.getUrlPath({ offeringId })}
          validationBySteps={groupValidationBySteps(validation.orderBookErrors)}
          hasPermission={hasPermissions}
        />
      )}
      {hasSettlementErrors && (
        <ValidationErrorSection
          title="Final Settlement"
          urlPath={routeFactory.finalSettlementDesignationMonitor.getUrlPath({ offeringId })}
          validationBySteps={groupValidationBySteps(validation.settlementErrors)}
          hasPermission={hasPermissions}
        />
      )}

      {!hasPermissions && (
        <CopyToClipboard text={wireValidationsToString(validation, managers)} onCopy={handleCopy}>
          <LinkButton inline>
            Copy to Clipboard <StyledCopyToClipboardIcon />
          </LinkButton>
        </CopyToClipboard>
      )}
    </SSectionsContainer>
  );
};
