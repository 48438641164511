import { LinkButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SLinkWrapper = styled.div`
  flex-grow: 1;
  padding-left: 24px;
`;

export const StyledLinkButton = styled(LinkButton)`
  padding: 0;
`;
