import React from 'react';

import { getOfferingTypeFilterOptions } from '../../../common/util/offering/offering-type.util';
import { sortCaseInsensitive } from '../../../common/util/sortCaseInsensitive';
import { Sector } from '../../../graphql';
import IssuerFilter from './components/issuer-filter/IssuerFilter';
import ObsoleteDraftsFilter from './components/obsolete-drafts-filter/ObsoleteDraftsFilter';
import OfferingTypeFilter from './components/offering-type-filter/OfferingTypeFilter';
import PricingDateRangeFilter from './components/pricing-date-filter/PricingDateRangeFilter';
import SectorFilter from './components/sector-filter/SectorFilter';
import StatusFilter from './components/status-filter/StatusFilter';
import { SFilters } from './Filters.styles';
import {
  MyOfferings_Filters_OfferingListPartsFragment,
  MyOfferings_Filters_SharedDraftOfferingListPartsFragment,
} from './graphql';
import { Filters as FiltersType } from './hooks/useFilters';

export type Props = {
  onChange: (filters: FiltersType) => void;
  filters: FiltersType;
  offerings?:
    | MyOfferings_Filters_OfferingListPartsFragment
    | MyOfferings_Filters_SharedDraftOfferingListPartsFragment;
  showObsoleteFilter?: boolean;
  showStatusFilter?: boolean;
};

/**
 * Filters displays a group of base filters for a list of offerings
 * filters only display values that exist in the dataset of offerings provided
 */
const Filters: React.FC<Props> = ({
  onChange,
  offerings,
  filters,
  showObsoleteFilter = false,
  showStatusFilter = true,
}) => {
  const statuses = React.useMemo(
    () =>
      Array.from(
        new Set(offerings?.offeringStatuses.map(({ status }) => status).sort(sortCaseInsensitive))
      ),
    [offerings]
  );
  const offeringTypes = React.useMemo(() => {
    return Array.from(
      new Set(
        offerings?.offeringTypes
          .map(offeringType => {
            const { type, pricingInstrumentCountryCode } = offeringType;
            return getOfferingTypeFilterOptions({
              offeringType: type,
              pricingInstrumentCountryCode,
            });
          })
          .sort(sortCaseInsensitive)
      )
    );
  }, [offerings]) as string[];
  const issuerSectors = React.useMemo(
    () =>
      Array.from(new Set(offerings?.issuerSectors?.map(sectors => sectors.issuerSector)))
        .filter(sector => !!sector)
        .sort(sortCaseInsensitive),
    [offerings]
  );

  return (
    <SFilters>
      <IssuerFilter
        issuerName={filters.issuerName}
        onChange={value => onChange({ ...filters, issuerName: value ?? '' })}
      />
      <SectorFilter
        values={filters.issuerSector}
        sectors={issuerSectors as Sector[]}
        onChange={value => onChange({ ...filters, issuerSector: value as Sector[] })}
      />
      <OfferingTypeFilter
        values={filters.type}
        offeringTypes={offeringTypes}
        onChange={value => onChange({ ...filters, type: value })}
      />
      {showStatusFilter && (
        <StatusFilter
          values={filters.status}
          statuses={statuses}
          onChange={value => onChange({ ...filters, status: value })}
        />
      )}
      <PricingDateRangeFilter
        pricingDateRange={filters.pricingDate}
        onChange={value => onChange({ ...filters, pricingDate: value })}
      />

      {showObsoleteFilter && (
        <ObsoleteDraftsFilter
          isChecked={filters.hideObsolete}
          onChange={value => onChange({ ...filters, hideObsolete: value })}
        />
      )}
    </SFilters>
  );
};

export default Filters;
