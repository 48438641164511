import { IndicationForm_OfferingPartsFragment } from '../../../indication-form/graphql/__generated__';
import { computeDefaultValuesForTrancheDemandCurrencyAndInstrument } from '../../../indication-form/IndicationForm.model';
import { IndicationFormValues } from '../../common/utils/IndicationSectionValidation';

export const createInitialValues = (
  offering: IndicationForm_OfferingPartsFragment
): IndicationFormValues => {
  const { trancheId, instrumentId, currencyCode } =
    computeDefaultValuesForTrancheDemandCurrencyAndInstrument(offering);

  return {
    instrumentId,
    currencyCode,
    trancheId: trancheId ?? null,
    interestLevels: [
      {
        interestQuantity: null,
        interestUnit: null,
        orderType: null,
        limitType: null,
        limitPrice: null,
      },
    ],
  };
};
