import { DatePickerField, DateTimePickerField, Option, RadioGroupField } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { RpnRestrictedPeriodType } from '../../../../../graphql';
import FilingCommonFieldsForm from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm';
import FilingDetailsForm from '../../../common/content/filing-details-form/FilingDetailsForm';
import { NonMemberFirmsDetail } from '../../../common/content/non-member-firms-detail/NonMemberFirmsDetail';
import SymbolsOfCoveredCurrenciesForm from '../../../common/content/symbols-of-covered-currencies/SymbolsOfCoveredCurrenciesForm';
import { getRestrictionStartDateTime } from '../../../common/form-utils';
import BasisCodeSubForm from './BasisCodeSubForm';
import MemberFirmsSubForm from './MemberFirmsSubForm';
import { RPNFilingFormValues } from './RPNForm.model';
import TransactionTypeSubForm from './TransactionTypeSubForm';

export const restrictedPeriodOptions: Option<NonNullable<RpnRestrictedPeriodType>>[] = [
  {
    label: '1D',
    value: RpnRestrictedPeriodType.OneDay,
  },
  {
    label: '5D',
    value: RpnRestrictedPeriodType.FiveDays,
  },
];

const concurrentConvertibleOptions: Option<string>[] = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export type Props = Readonly<{
  readonly stockSymbols: readonly string[];
}>;

const RPNForm: React.FC<Props> = ({ stockSymbols }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<RPNFilingFormValues>();

  const handleRestrictedPeriodChange = (nextRestrictedPeriod: RpnRestrictedPeriodType | null) => {
    setFieldValue('basisCode', null);

    if (values.restrictionStartTimeField && nextRestrictedPeriod) {
      setFieldValue(
        'restrictionStartTimeField',
        getRestrictionStartDateTime(
          values.anticipatedPricingDate,
          nextRestrictedPeriod,
          values.restrictionStartTimeField.timezone ?? 'America/New_York'
        )
      );
    }
  };

  return (
    <div>
      <FilingCommonFieldsForm />
      <FilingDetailsForm stockSymbols={stockSymbols}>
        <SymbolsOfCoveredCurrenciesForm />
        <GridLayout>
          <Column span={3}>
            <RadioGroupField
              withMargin
              required
              fullWidth
              label="Restricted Period"
              name="restrictedPeriod"
              options={restrictedPeriodOptions}
              onChange={value =>
                handleRestrictedPeriodChange(value as RpnRestrictedPeriodType | null)
              }
            />
          </Column>
        </GridLayout>
        <BasisCodeSubForm />
        <GridLayout>
          <Column span={3}>
            <RadioGroupField
              withMargin
              required
              fullWidth
              label="Concurrent Convertible"
              name="isConcurrentConvertible"
              options={concurrentConvertibleOptions}
            />
          </Column>
        </GridLayout>
        <TransactionTypeSubForm />
        <GridLayout>
          <Column span={3}>
            <DateTimePickerField
              withMargin
              required
              fullWidth
              label="Restriction Start Time"
              testId="Regulatory Filings / Filings / RPN / Restricted Start Time"
              name="restrictionStartTimeField"
              showTimezones
            />
          </Column>
          <Column span={3}>
            <DatePickerField
              withMargin
              required
              fullWidth
              label="Anticipated Pricing Date"
              name="anticipatedPricingDate"
              onCalendarClose={() => {
                setFieldTouched('anticipatedPricingDate');
              }}
            />
          </Column>
        </GridLayout>
      </FilingDetailsForm>
      {values.finraMemberFirms.length > 0 && <MemberFirmsSubForm />}
      {values.nonMemberFirms.length > 0 && (
        <NonMemberFirmsDetail nonMemberFirms={values.nonMemberFirms} />
      )}
    </div>
  );
};

export default RPNForm;
