import { permissionsByEntity } from '@cmg/auth';
import { Alert, OnGridReadyEventFn, SelectionChangedEventFn } from '@cmg/common';
import React from 'react';

import { PrivateRoute } from '../../../../common/routing/private-route/PrivateRoute';
import routeFactory from '../../../../common/util/routeFactory';
import { NoAccessRedirect } from '../../../core/RootRouter';
import { OnExportResult } from '../../common/components/export-button/ExportButton.model';
import { DesignationMonitorGridContext } from '../hooks/useDesignationContext';
import { rowContainsNonApplicableWarning } from './designation-monitor-grid/components/warnings-renderer/DesignationMonitorWarningsRenderer.model';
import DesignationMonitorGrid from './designation-monitor-grid/DesignationMonitorGrid';
import DesignationMonitorToolBar from './designation-monitor-toolbar/DesignationMonitorToolBar';
import { useFilteredRows } from './hooks/useFilteredRows';
import OverwriteDesignationRoute from './overwrite-designation/OverwriteDesignationRoute';

export type Props = {
  context: DesignationMonitorGridContext;
  investorsFilter: string[];
  setInvestorsFilter: (investors: string[]) => void;
  isApplyFixedEconomicsEnabled: boolean;
  isEditedOnlyFilter: boolean;
  isOutOfBalanceOnlyFilter: boolean;
  onExportClick: () => Promise<OnExportResult>;
  isExportLoading: boolean;
  onApplyFixedEconomicsClick: () => void;
  onEditedOnlyFilterChange: (filterState: boolean) => void;
  onOutOfBalanceOnlyFilterChange: (filterState: boolean) => void;
  onGridReady: OnGridReadyEventFn;
  onGridSelectionChange: SelectionChangedEventFn;
  onPublishClick: () => void;
  isPublishing: boolean;
};

const DesignationMonitorDetails: React.FC<Props> = ({
  context,
  investorsFilter,
  setInvestorsFilter,
  isApplyFixedEconomicsEnabled,
  isEditedOnlyFilter,
  isOutOfBalanceOnlyFilter,
  onExportClick,
  isExportLoading,
  onApplyFixedEconomicsClick,
  onEditedOnlyFilterChange,
  onOutOfBalanceOnlyFilterChange,
  onGridReady,
  onGridSelectionChange,
  onPublishClick,
  isPublishing,
}) => {
  const isRepublishAlertVisible = React.useMemo(() => {
    return (
      !context.noEconomicsApplied &&
      (!context.isPublished ||
        context.investorRows.some(({ warnings }) => rowContainsNonApplicableWarning(warnings)))
    );
  }, [context.investorRows, context.isPublished, context.noEconomicsApplied]);

  const filteredRows = useFilteredRows(
    context.investorRows,
    isOutOfBalanceOnlyFilter,
    isEditedOnlyFilter,
    investorsFilter
  );
  return (
    <React.Fragment>
      {isRepublishAlertVisible && (
        <Alert severity="warning" withMargin={true}>
          <Alert.Title>
            This version of designations has not been published. Please <i>Publish Designations</i>{' '}
            for this offering.
          </Alert.Title>
        </Alert>
      )}
      <DesignationMonitorToolBar
        investorsFilter={investorsFilter}
        setInvestorsFilter={setInvestorsFilter}
        isApplyFixedEconomicsEnabled={isApplyFixedEconomicsEnabled}
        isEditedOnlyFilter={isEditedOnlyFilter}
        isOutOfBalanceOnlyFilter={isOutOfBalanceOnlyFilter}
        onExportClick={onExportClick}
        isExportLoading={isExportLoading}
        onApplyFixedEconomicsClick={onApplyFixedEconomicsClick}
        onEditedOnlyFilterChange={onEditedOnlyFilterChange}
        onOutOfBalanceOnlyFilterChange={onOutOfBalanceOnlyFilterChange}
        isPublishDisabled={context.noEconomicsApplied}
        onPublishClick={onPublishClick}
        isPublishing={isPublishing}
        investorRows={context.investorRows}
      />
      <DesignationMonitorGrid
        context={context}
        onGridReady={onGridReady}
        filteredInvestorRows={filteredRows}
        onSelectionChange={onGridSelectionChange}
      />
      <PrivateRoute
        path={routeFactory.finalSettlementDesignationMonitorOverwrite.routePath}
        renderNoAccess={routeProps => <NoAccessRedirect {...routeProps} />}
        requiredPermissions={[permissionsByEntity.Designation.READ]}
        component={OverwriteDesignationRoute}
      />
    </React.Fragment>
  );
};

export default DesignationMonitorDetails;
