import { ListItem, ListItemButton } from '@cmg/design-system';
import React from 'react';

import { useTemplatesState } from '../../../hooks/useTemplatesState';
import { CMG_TEMPLATES } from '../../../hooks/useTemplatesState.model';
import ListItemKebabMenu from '../kebab-menu/ListItemKebabMenu';

export type Props = Readonly<{
  onSaveAsNew: () => void;
  onReset: () => void;
  templateName: keyof typeof CMG_TEMPLATES;
}>;

const CmgTemplateListItem: React.FC<Props> = ({ templateName, onReset, onSaveAsNew }) => {
  const { getActiveTemplate } = useTemplatesState();
  const isActive = getActiveTemplate() === undefined;

  const actions = React.useMemo(
    () => [
      ...(isActive
        ? [
            {
              label: 'Reset',
              onClick: onReset,
            },
          ]
        : []),
      {
        label: 'Save as New',
        onClick: onSaveAsNew,
      },
    ],
    [isActive, onReset, onSaveAsNew]
  );

  return (
    <ListItem
      disableGutters
      aria-label={templateName}
      secondaryAction={<ListItemKebabMenu actions={actions} aria-label="Cmg saved view actions" />}
    >
      <ListItemButton onClick={onReset} selected={isActive} sx={{ borderRadius: 1 }}>
        {templateName}
      </ListItemButton>
    </ListItem>
  );
};

export default CmgTemplateListItem;
