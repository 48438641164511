import { ConfirmModal } from '@cmg/common';
import React from 'react';

type Props = {
  show?: boolean;
  onHide: () => void;
  onConfirm: () => void;
};

/**
 * Modal used for confirmation of delete meeting draft action
 */
export const DeleteMeetingConfirmModal: React.FC<Props> = ({ show = false, onHide, onConfirm }) => {
  return (
    <ConfirmModal
      show={show}
      onHide={onHide}
      onConfirm={onConfirm}
      title="Delete meeting draft"
      confirmButtonCaption="Delete"
      cancelButtonCaption="Cancel"
    >
      Do you really want to delete this meeting draft? This action is irreversible.
    </ConfirmModal>
  );
};
