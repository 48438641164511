import { numericUtil } from '@cmg/common';
import React from 'react';
import * as yup from 'yup';

import {
  GridTransitionSalesCreditReleaseStatus,
  UpdateSalesCreditsAmountInput,
} from '../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../graphql';
import { SalesCreditsGridFilter } from './grid-filters/SalesCreditsGridFilters.model';

export const validationSchema = (
  salesCredits: readonly SalesCredits_SalesCreditItemPartsFragment[]
) =>
  yup.object().shape({
    salesCredits: yup.object().shape(
      salesCredits.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.investorFirmCmgEntityKey]: yup
            .number()
            .label('Sales Credits')
            .nullable()
            .required(),
        }),
        {}
      )
    ),
  });

export type SalesCreditsFormValues = {
  salesCredits: Record<string, number | null | undefined>;
};

export function getInitialValues(
  salesCredits: readonly SalesCredits_SalesCreditItemPartsFragment[]
): SalesCreditsFormValues {
  return salesCredits.reduce<SalesCreditsFormValues>(
    (acc, curr) => {
      acc.salesCredits[curr.investorFirmCmgEntityKey] =
        curr.salesCreditsManual ?? curr.salesCredits;
      return acc;
    },
    {
      salesCredits: {},
    }
  );
}

export function formValuesToPayload(
  values: SalesCreditsFormValues
): UpdateSalesCreditsAmountInput[] {
  return Object.entries(values.salesCredits).map(
    ([investorFirmCmgEntityKey, salesCreditAmount]) => ({
      investorFirmCmgEntityKey,
      salesCreditAmount,
    })
  );
}

export const getNumOfRowsNeedingAction = (
  salesCredits: readonly SalesCredits_SalesCreditItemPartsFragment[]
) => {
  return salesCredits.filter(
    ({ warnings, allowedStatusTransitions }) =>
      warnings.length > 0 ||
      allowedStatusTransitions.includes(GridTransitionSalesCreditReleaseStatus.Released)
  ).length;
};

function getSalesCreditValue(item: SalesCredits_SalesCreditItemPartsFragment) {
  return item.salesCreditsManual ?? item.salesCredits;
}

function salesCreditsSortFn(
  a: SalesCredits_SalesCreditItemPartsFragment,
  b: SalesCredits_SalesCreditItemPartsFragment
) {
  if (a.warnings.length !== 0 && b.warnings.length === 0) {
    return -1;
  }
  if (b.warnings.length !== 0 && a.warnings.length === 0) {
    return 1;
  }
  if (getSalesCreditValue(a) === 0 && getSalesCreditValue(b) !== 0) {
    return 1;
  }
  if (getSalesCreditValue(b) === 0 && getSalesCreditValue(a) !== 0) {
    return -1;
  }
  return a.investorFirmName.localeCompare(b.investorFirmName);
}

export const useFilterRows = (
  salesCredits: readonly SalesCredits_SalesCreditItemPartsFragment[],
  gridFilter?: SalesCreditsGridFilter
) => {
  return React.useMemo<SalesCredits_SalesCreditItemPartsFragment[]>(
    () =>
      salesCredits
        .filter(({ investorFirmName, status, warnings }) => {
          if (!gridFilter) {
            return true;
          }
          const investorNamePredicate =
            gridFilter.investor.length === 0 ||
            investorFirmName.toLowerCase().includes(gridFilter.investor.toLowerCase());
          const statusPredicate = gridFilter.releaseStatus
            ? status === gridFilter.releaseStatus
            : true;
          const errorPredicate = gridFilter.containsErrors ? warnings.length > 0 : true;

          return investorNamePredicate && statusPredicate && errorPredicate;
        })
        .sort(salesCreditsSortFn),
    [salesCredits, gridFilter]
  );
};

export const areSalesCreditsOutOfBalance = (
  sellingConcession: number | null | undefined,
  salesCredits: (number | null | undefined)[],
  designationShares: (number | null | undefined)[]
) => {
  if (sellingConcession === null) {
    return false;
  }

  const totalSalesCredits = numericUtil.sum(...salesCredits) ?? 0;
  const totalExpectedSalesCredits =
    numericUtil.multiply(sellingConcession, numericUtil.sum(...designationShares)) ?? 0;
  const diff = Math.abs(totalSalesCredits - totalExpectedSalesCredits);

  return diff >= 0.01;
};

export const useAreSalesCreditsOutOfBalance = (
  sellingConcession: number | null | undefined,
  salesCredits: readonly SalesCredits_SalesCreditItemPartsFragment[]
) => {
  return React.useMemo(
    () =>
      areSalesCreditsOutOfBalance(
        sellingConcession,
        salesCredits.map(getSalesCreditValue),
        salesCredits.map(({ designationShares }) => designationShares)
      ),
    [sellingConcession, salesCredits]
  );
};
