import { FilingType } from '../../../../graphql';
import {
  MyOfferings_MyPublishedOfferingsQuery,
  MyOfferings_PublishedOfferingsQuery,
} from '../graphql';

export type PublishedOfferingsQuery = MyOfferings_PublishedOfferingsQuery;
export type PublishedOffering = NonNullable<
  NonNullable<PublishedOfferingsQuery['publishedOfferings']>['items']
>[number];
export type MyPublishedOffering = NonNullable<
  NonNullable<MyOfferings_MyPublishedOfferingsQuery['myPublishedOfferings']>['data']
>[number];

const US_COUNTRY_CODE = 'US';

export const toMyPublishedOffering = (offering: PublishedOffering): MyPublishedOffering => {
  const pricingInstrumentCountryCode =
    offering.instruments.find(instrument => instrument.id === offering.pricingInstrumentId)
      ?.countryCode ?? null;

  const revisedFilingsCount = offering.terms.filings.filter(
    filing => filing.filingType === FilingType.Revised
  ).length;

  return {
    __typename: 'OfferingLimited',
    id: offering.id,
    type: offering.type,
    status: offering.status,
    stage: offering.stage,
    issuerName: offering.issuer.name,
    pricingDate: offering.timing.pricingDate,
    pricingInstrumentStockSymbol: offering.pricingInstrumentStockSymbol,
    issuerSector: offering.issuer.sector,
    isAuthor: offering.isAuthor ?? false,
    hasUnpublishedChanges: offering.hasUnpublishedChanges,
    isUsOffering: pricingInstrumentCountryCode === US_COUNTRY_CODE,
    revisedFilingsCount,
    isExecutingOnPlatform: offering.isExecutingOnPlatform,
    pricingInstrumentCountryCode,
  };
};
