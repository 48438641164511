import isNil from 'lodash/isNil';

export const calculateSum = (data: (number | null)[]) => {
  return data.some(value => !isNil(value))
    ? data.reduce((acc, value) => (value ?? 0) + (acc ?? 0), 0)
    : null;
};

export const getPercentageAllocationFee = ({
  grossSpreadBase,
  grossSpreadBaseAllocationFee,
  incentiveFee,
  incentiveAllocationFee,
}: {
  grossSpreadBase: number | null;
  grossSpreadBaseAllocationFee: number | null;
  incentiveFee: number | null;
  incentiveAllocationFee: number | null;
}) => {
  const grossSpreadBasePercentageFee =
    isNil(grossSpreadBase) || isNil(grossSpreadBaseAllocationFee)
      ? null
      : grossSpreadBase * grossSpreadBaseAllocationFee;

  const incentivePercentageFee =
    isNil(incentiveFee) || isNil(incentiveAllocationFee)
      ? null
      : incentiveFee * incentiveAllocationFee;

  return isNil(grossSpreadBasePercentageFee) && isNil(incentivePercentageFee)
    ? null
    : (grossSpreadBasePercentageFee ?? 0) + (incentivePercentageFee ?? 0);
};
