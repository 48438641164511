import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_MeetingDocument,
  Roadshows_UpdateInvestorTeamsMutation,
  useRoadshows_UpdateInvestorTeamsMutation,
} from '../graphql';

export type UpdateInvestorTeamsMutation = Roadshows_UpdateInvestorTeamsMutation;

export function refetchQueries() {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument)!;
  const refetchRoadshowMeetings = getOperationName(Roadshows_RoadshowEventsDocument)!;

  return [refetchMeetingDetails, refetchRoadshowMeetings];
}

export const useUpdateInvestorTeamsMutation = () => {
  return useRoadshows_UpdateInvestorTeamsMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
