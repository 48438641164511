import { UUID } from '@cmg/common';
import React from 'react';

import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';
import { useSyndicateManagerListQuery } from './useSyndicateManagerListQuery';

export function createDefaultUseOutdatedManagersList(
  filter: (manager: SyndicateWireManager) => boolean = () => true
) {
  return ({
    offeringId,
    syndicateWireId,
  }: {
    offeringId: UUID;
    syndicateWireId?: UUID;
  }): string[] | undefined => {
    const { useManagers } = useWireTypeConfigContext();

    const wireManagers = useManagers({ offeringId, syndicateWireId });
    const managerListQuery = useSyndicateManagerListQuery({ offeringId }, 'cache-first');

    return React.useMemo(() => {
      if (
        managerListQuery.loading ||
        managerListQuery.error ||
        wireManagers.isLoading ||
        wireManagers.error
      ) {
        return undefined;
      }

      const offeringManagerKeysFiltered =
        managerListQuery.data.filter(filter).map(m => m.cmgEntityKey) ?? [];
      const wireManagerKeysFiltered =
        wireManagers.data?.filter(filter).map(m => m.cmgEntityKey) ?? [];

      return wireManagerKeysFiltered.filter(
        wireManagerKey => !offeringManagerKeysFiltered.includes(wireManagerKey)
      );
    }, [
      wireManagers.data,
      managerListQuery.data,
      wireManagers.error,
      managerListQuery.error,
      wireManagers.isLoading,
      managerListQuery.loading,
    ]);
  };
}
