import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import Banner from '../../indicators/banner/Banner';
import notFound from './../../../../common/assets/page-not-found.svg';
import { SNotFoundImg, SNotFoundWrapper } from './SNotFound.styles';

export const SNotFound = () => (
  <SNotFoundWrapper data-test-id={xcSelectors.offeringDetails404Error.testId}>
    <Banner variant="error">
      <Banner.Title>This Offering could not be found.</Banner.Title>
      <p>
        This Offering ID is no longer valid. The offering may have been terminated, withdrawn, or
        deleted. Please <a href="mailto:support@cmgx.io">contact CMG support</a> for more
        information.
      </p>
    </Banner>
    <SNotFoundImg src={notFound} alt="Page Not Found" />
  </SNotFoundWrapper>
);
