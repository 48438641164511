import React, { Fragment, useMemo } from 'react';

import { ContactSupportDialog } from '../components/dialogs/contact-support/ContactSupportDialog';
import { Recipient } from '../components/dialogs/contact-support/ContactSupportDialog.model';
import { useContactSupportControl } from './hooks/useContactSupportControl';

export function filterRecipientsWithMissingEmails<TRecipient extends Recipient = Recipient>(
  recipients: TRecipient[],
  recipientsWithMissingEmails: string[]
): Recipient[] {
  return recipients
    .filter(recipient => recipientsWithMissingEmails.includes(recipient.cmgEntityKey))
    .map(({ cmgEntityKey, firmName }) => ({ cmgEntityKey, firmName }));
}

export type ContactSupportModalContextType<TRecipient extends Recipient = Recipient> = {
  open: (recipientsMissingEmail: TRecipient[], onCloseCallback?: () => void) => void;
  close: () => void;
};

export const ContactSupportModalContext = React.createContext<ContactSupportModalContextType>({
  open: () => undefined,
  close: () => undefined,
});

export const ContactSupportModalContextProvider: React.FC = ({ children }) => {
  const { open, close, isVisible, recipientsMissingEmail } = useContactSupportControl();

  const contextValue: ContactSupportModalContextType = useMemo(
    () => ({
      open,
      close,
    }),
    [close, open]
  );

  return (
    <ContactSupportModalContext.Provider value={contextValue}>
      {isVisible && (
        <Fragment>
          <ContactSupportDialog
            recipientsMissingEmail={recipientsMissingEmail}
            subject="Email Distribution List"
            title="Submit a Support Request"
          />
        </Fragment>
      )}
      {children}
    </ContactSupportModalContext.Provider>
  );
};

export const useContactSupportModalContext = (): ContactSupportModalContextType => {
  const context = React.useContext(ContactSupportModalContext);
  if (context === undefined) {
    throw new Error(
      'useContactSupportModalContext must be used within a ContactSupportModalContextProvider'
    );
  }

  return context;
};
