import { AgGridApi, DropdownButton, IconButton, Popover } from '@cmg/common';
import { RowNode } from 'ag-grid-community';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import {
  IndicationStatus,
  InvestorReplyStatus,
  TradeReleaseGridStatusTransition,
  TradeReleaseGridWarning,
} from '../../../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { useGridActions } from '../../../grid-actions/hooks/useGridActions';
import { TradeRowActionsAvailability } from '../../../hooks/useTradeGrid';
import { getActionsAvailability, TradeReleaseGridContext } from '../../TradeReleaseGrid.model';

export type Props = Readonly<{
  node: RowNode;
  data: TradeRelease_TradeGridItemFragment;
  context: TradeReleaseGridContext;
  api: AgGridApi;
}>;

const TradeActionsRenderer: React.FC<Props> = ({ node, data, context, api }) => {
  const isHeaderRow = node.isRowPinned();
  const theme = useTheme();
  const { offeringId } = context;
  const {
    executeCancelRelease,
    executeCancelManually,
    executeReleaseTrades,
    executeMarkAsBooked,
    isLoading,
  } = useGridActions(offeringId, api);

  if (isHeaderRow) {
    return null;
  }

  const actionsAvailability = getActionsAvailability(data);
  if (actionsAvailability === TradeRowActionsAvailability.HIDDEN) {
    return null;
  }

  const isMenuDisabled = actionsAvailability === TradeRowActionsAvailability.DISABLED;
  const isPopoverDisabled =
    !isMenuDisabled ||
    (data.investorReplyStatus === InvestorReplyStatus.Confirmed &&
      data.warnings.includes(TradeReleaseGridWarning.IndicationCancelled));

  const popoverContent =
    data.indicationStatus === IndicationStatus.Cancelled
      ? 'Indication must be active before release.'
      : 'This allocation must be confirmed before it can be released.';

  return (
    <Popover
      disabled={isPopoverDisabled}
      variant="DARK"
      content={<span>{popoverContent}</span>}
      placement="topRight"
    >
      <span data-testid="popover-content">
        <DropdownButton
          disabled={isMenuDisabled}
          buttonComponent={() => (
            <IconButton
              disabled={isMenuDisabled}
              icon={{
                name: 'ellipsis-v',
                variant: 'regular',
                size: '1x',
              }}
              color={theme.designSystem.colors.darkBlue[200]}
            />
          )}
          popoverVariant="LIGHT"
          options={[
            {
              testId: 'btn-release-trade',
              label: 'Release Trade',
              onClick: () => {
                executeReleaseTrades([data]);
              },
              disabled:
                isLoading ||
                !data.allowedStatusTransitions.includes(TradeReleaseGridStatusTransition.Released),
            },
            {
              testId: 'btn-cancel-trade',
              label: 'Cancel Release',
              onClick: () => {
                executeCancelRelease([data]);
              },
              disabled:
                isLoading ||
                !data.allowedStatusTransitions.includes(
                  TradeReleaseGridStatusTransition.CancelPending
                ),
            },
            {
              testId: 'btn-manually-book-trade',
              label: 'Mark as Booked (Manual)',
              onClick: () => {
                executeMarkAsBooked([data]);
              },
              disabled:
                isLoading ||
                !data.allowedStatusTransitions.includes(
                  TradeReleaseGridStatusTransition.BookedManual
                ),
            },
            {
              testId: 'btn-manually-cancel-trade',
              label: 'Mark as Cancelled (Manual)',
              onClick: () => {
                executeCancelManually([data]);
              },
              disabled:
                isLoading ||
                !data.allowedStatusTransitions.includes(
                  TradeReleaseGridStatusTransition.CancelledManual
                ),
            },
          ]}
        />
      </span>
    </Popover>
  );
};

export default TradeActionsRenderer;
