import isNil from 'lodash/isNil';
import * as yup from 'yup';

import { IoiPricingType, IoiUnitType } from '../../../../../../../../graphql';
import { MAX_53_BIT_INT } from '../../../../../../../../types/graphql/constants';

export const fundIoisValidationSchema = yup.object().shape({
  fundId: yup.string().nullable().required('Please select a Fund'),
  interestLevels: yup
    .array()
    .of(
      yup.object().shape({
        pricingType: yup
          .string()
          .typeError('Please select a Pricing Type')
          .required('Please select a Pricing Type')
          .oneOf(Object.values(IoiPricingType)),
        unitType: yup
          .string()
          .typeError('Please select a Unit Type')
          .required('Please select a Unit Type')
          .oneOf(Object.values(IoiUnitType)),
        limitPrice: yup.number().when('pricingType', {
          is: pricingType => pricingType === IoiPricingType.Limit,
          then: schema =>
            schema
              .typeError('Please enter a Limit Price value')
              .required('Please enter a Limit Price value'),
          otherwise: schema => schema.nullable(),
        }),
        dollars: yup.number().when('unitType', {
          is: unitType => unitType === IoiUnitType.Dollars,
          then: schema =>
            schema
              .typeError('Please enter a Dollars value')
              .required('Please enter a Dollars value'),
          otherwise: schema => schema.nullable(),
        }),
        shares: yup.mixed<number>().when('unitType', {
          is: unitType => unitType === IoiUnitType.Shares,
          then: schema =>
            schema
              .test('test-sharesInterestLevel', 'Invalid Shares value', function checkValue(value) {
                return !isNil(value) && value >= 0 && value <= MAX_53_BIT_INT;
              })
              .required('Invalid Shares value'),
          otherwise: schema => schema.nullable(),
        }),
        percentage: yup.number().when('unitType', {
          is: unitType => unitType === IoiUnitType.Percentage,
          then: schema =>
            schema
              .typeError('Please enter a Percentage value')
              .required('Please enter a Percentage value')
              .max(1, 'Percentage must be less or equal to 100'),
          otherwise: schema => schema.nullable(),
        }),
      })
    )
    .nullable(),
  allocation: yup
    .object()
    .shape({
      shares: yup
        .mixed<number>()
        .nullable()
        .test('test-sharesAllocation', 'Invalid Shares Amount', function checkValue(value) {
          // Share quantity can be nil or a value greater than or equal to 0.
          return isNil(value) || (value >= 0 && value <= MAX_53_BIT_INT);
        }),
    })
    .nullable(),
});
