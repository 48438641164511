import { SnackbarManager } from '@cmg/design-system';
import { saveAs } from 'file-saver';
import React from 'react';

import { orderBookApiClient } from '../../../../../../../../common/api/orderBookApiClient';
import { getFormattedDate } from '../utils/getFormattedDate';

export function useExportInstitutionalDemandRevisionHistory({ offeringId }) {
  const [loading, setLoading] = React.useState(false);

  const handleExport = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await orderBookApiClient.institutionalIndications_ExportPublishedOfferings(
        offeringId
      );
      saveAs(response.data, `XC_OB_Offerings_${offeringId}_${getFormattedDate()}.xlsx`);
      SnackbarManager.success('Download successfully completed.');
    } catch {
      SnackbarManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [offeringId]);

  return { handleExport, loading };
}
