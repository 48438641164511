import type { UUID } from '@cmg/common';
import {
  Alert,
  AlertListContent,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import { FormikProvider, useFormik } from 'formik';
import omit from 'lodash/omit';
import React, { useEffect, useMemo } from 'react';

import { ReactComponent as CmgLogo } from '../../../../../common/assets/identity-icon.svg';
import { InvalidFormFieldsAlert } from '../../../../../common/components/alerts/invalid-form-fields-alert/InvalidFormFieldsAlert';
import { formatInterestLevel } from '../../../utils';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../graphql/__generated__';
import type { MarkAsDuplicateFormValues } from './MarkAsDuplicateDialog.model';
import { getValidationSchema, getWarningMessages } from './MarkAsDuplicateDialog.model';

export type Props = Readonly<{
  indications: OrderBook_InstitutionalDemand_GridRowPartsFragment[];
  pricingCurrencyCode: string;
  finalAllocationSetId: UUID | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSave: (survivorId: string, markAsDuplicateIds: string[]) => void;
}>;

export const MarkAsDuplicateDialog: React.FC<Props> = ({
  indications,
  pricingCurrencyCode,
  finalAllocationSetId,
  isOpen,
  onClose,
  onSave,
}) => {
  const hasOnPlatformInvestor = indications.some(({ investor }) => investor.isOnPlatform);
  const warningMessages = getWarningMessages(indications);

  const validationSchema = useMemo(
    () => getValidationSchema(indications, finalAllocationSetId),
    [finalAllocationSetId, indications]
  );

  const formik = useFormik<MarkAsDuplicateFormValues>({
    initialValues: {
      survivorId: null,
      duplicateIds: [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      onSave(values.survivorId!, values.duplicateIds);
      onClose();
    },
  });

  const { resetForm, dirty, setFieldValue, values } = formik;
  const isDuplicateIdAlertVisible = !!(formik.errors.duplicateIds && formik.touched.duplicateIds);

  useEffect(() => {
    if (!isOpen && dirty) {
      resetForm();
    }
  }, [resetForm, dirty, isOpen]);

  useEffect(() => {
    if (values.survivorId) {
      const duplicateIds = indications.map(({ id }) => id).filter(id => id !== values.survivorId);
      setFieldValue('duplicateIds', duplicateIds);
    }
  }, [values.survivorId, setFieldValue, indications]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Designate Surviving Indication</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <InvalidFormFieldsAlert
            errors={omit(formik.errors, 'duplicateIds')}
            isSubmitting={formik.isSubmitting}
            touched={formik.touched}
            validationSchema={validationSchema}
          />
          {isDuplicateIdAlertVisible && (
            <Alert severity="error" aria-label="Duplicate indication error">
              <AlertTitle>Please review the selected indications</AlertTitle>
              <AlertListContent messages={formik.errors.duplicateIds ?? []} />
            </Alert>
          )}
          {warningMessages.length > 0 && (
            <Alert severity="warning">
              <AlertTitle>Please review the selected indications</AlertTitle>
              <AlertListContent messages={warningMessages} />
            </Alert>
          )}
          <Typography>
            Please select the investor to designate as the surviving indication.
          </Typography>
          <FormikProvider value={formik}>
            <RadioGroup name="survivorId">
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Investor (CRM)</TableCell>
                      <TableCell>Submitted By</TableCell>
                      <TableCell>Indication</TableCell>
                      <TableCell>CMG Entity Name</TableCell>
                      <TableCell>Relationship</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {indications.map(
                      ({
                        id,
                        investor,
                        auditInfo,
                        interestLevels,
                        currencyCode,
                        coveringManagerNames,
                      }) => (
                        <TableRow key={id}>
                          <TableCell>
                            <Radio
                              value={id}
                              checked={formik.values.survivorId === id}
                              onChange={formik.handleChange}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" gap={0.5}>
                              {hasOnPlatformInvestor && (
                                <SvgIcon
                                  fontSize="small"
                                  aria-label="On platform"
                                  role="img"
                                  visibility={investor.isOnPlatform ? 'visible' : 'hidden'}
                                >
                                  <CmgLogo />
                                </SvgIcon>
                              )}
                              {investor.displayName}
                            </Stack>
                          </TableCell>
                          <TableCell>{auditInfo.createdBy?.firmDisplayName ?? '-'}</TableCell>
                          <TableCell>
                            {interestLevels?.map((interestLevel, index) => (
                              <React.Fragment key={index}>
                                {formatInterestLevel({
                                  interestLevel,
                                  pricingCurrencyCode,
                                  demandCurrencyCode: currencyCode,
                                })}
                                <br />
                              </React.Fragment>
                            )) ?? '-'}
                          </TableCell>
                          <TableCell>{investor.firmName ?? '-'}</TableCell>
                          <TableCell>{coveringManagerNames}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </RadioGroup>
          </FormikProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
