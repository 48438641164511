import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';

import { useFeatureToggles } from '../../../../../common/config';
import { ColDefCalendarGrid } from '../../../calendar.model';
import { CalendarGridOffering } from '../CalendarGrid.model';
import { calendarGridColumns, CalendarGridOrderField } from '../CalendarGridColumns';

function getCalendarGridColumnsTypeFix<T extends CalendarGridOffering>() {
  return calendarGridColumns as {
    [key in keyof typeof calendarGridColumns]: ColDefCalendarGrid<T>;
  };
}

export function useInternationalColumns<T extends CalendarGridOffering>() {
  const calendarGridColumnsTypeFix = getCalendarGridColumnsTypeFix<T>();

  const { isInternationalDisplayOn } = useFeatureToggles();
  const hasInternational = useCheckPermissions([permissionsByEntity.InternationalOffering.READ]);

  if (isInternationalDisplayOn && hasInternational) {
    return {
      regionAndCountryColumns: [
        calendarGridColumnsTypeFix[CalendarGridOrderField.REGION],
        calendarGridColumnsTypeFix[CalendarGridOrderField.COUNTRY],
      ],
      sizeInCurrencyColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_SIZE_IN_CURRENCY],
      priceRangeColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_PRICE_RANGE],
      offerPriceColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_FINAL_FILING_OFFER_PRICE],
      pricingDateColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_PRICING_DATE],
      postponedDateColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_POSTPONED_DATE],
      firstTradeDateColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_FIRST_TRADE_DATE],
      publicFilingDateColumn:
        calendarGridColumnsTypeFix[CalendarGridOrderField.INTERNATIONAL_PUBLIC_FILING_DATE],
      hasInternational,
    };
  }

  return {
    regionAndCountryColumns: [],
    sizeInCurrencyColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.SIZE_IN_CURRENCY],
    priceRangeColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.PRICE_RANGE],
    offerPriceColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.FINAL_FILING_OFFER_PRICE],
    pricingDateColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.PRICING_DATE],
    postponedDateColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.POSTPONED_DATE],
    firstTradeDateColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.FIRST_TRADE_DATE],
    publicFilingDateColumn: calendarGridColumnsTypeFix[CalendarGridOrderField.PUBLIC_FILING_DATE],
    hasInternational,
  };
}
