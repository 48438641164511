import { getOperationName } from '@apollo/client/utilities';

import { RegulatoryFilings_RegulatoryFilingsDocument } from '../../../graphql';
import { useRegulatoryFilings_ResubmitUsdtnFilingMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchRegulatoryFilings_RegulatoryFilingsQuery = getOperationName(
    RegulatoryFilings_RegulatoryFilingsDocument
  );

  return [refetchRegulatoryFilings_RegulatoryFilingsQuery!];
};

export const useResubmitUSDTNFilingMutation = () => {
  return useRegulatoryFilings_ResubmitUsdtnFilingMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
