import { Option } from '@cmg/common';

import { ExpenseCategory } from '../../../graphql';

export const expensesCategoryLabels: { [expenseCategory in ExpenseCategory]: string } = {
  CLOSING_EXPENSES: 'Closing Expenses',
  DTC_COSTS: 'DTC Costs',
  EXPENSES_SUBJECT_TO_274N: 'Expenses Subject to 274(N)',
  FINANCING_SETTLEMENT: 'Financing / Settlement',
  LEGAL_FEES: 'Legal Fees',
  OPERATIONAL: 'Operational',
  PRINT_COMMUNICATIONS: 'Print / Communications',
  PROFESSIONAL_SERVICES: 'Professional Services',
  REGULATORY: 'Regulatory',
  REIMBURSEMENT_TO_ISSUER: 'Reimbursement to/(from) Issuer',
  ROADSHOW: 'Roadshow',
  TECHNOLOGY_DATA: 'Technology / Data',
  THIRD_PARTY_ADVISORS: 'Third Party Advisors',
  TRAVEL_MEALS: 'Travel / Meals',
  OTHER_EXPENSES: 'Other Expenses',
};

export const expensesCategoryOptions = Object.values(ExpenseCategory).map<Option<ExpenseCategory>>(
  type => ({
    label: expensesCategoryLabels[type],
    value: type,
  })
);
