import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage, OfferingStatus } from '../../../../../graphql';

type Props = {
  offeringId: UUID | undefined;
  offeringStatus: OfferingStatus | undefined;
  offeringStage: OfferingStage | undefined;
};

export const useSalesCreditsRoute = ({
  offeringId,
  offeringStatus,
  offeringStage,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const canAccess = useCheckPermissions([permissionsByEntity.SalesCredit.READ]);

  if (!canAccess) {
    return undefined;
  }

  if (!offeringId) {
    return undefined;
  }

  if (offeringStatus !== OfferingStatus.Priced) {
    return undefined;
  }

  if (offeringStage !== OfferingStage.Published) {
    return undefined;
  }

  return {
    href: routeFactory.salesCredits.getUrlPath({ offeringId }),
    label: 'Sales Credits Release',
  };
};
