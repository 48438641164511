import { AgGridApi, Checkbox, DropdownSelect, Icon } from '@cmg/common';
import React from 'react';

import { isSalesCreditsRowSelectable, SalesCreditsGridContext } from '../SalesCreditsGrid.model';
import {
  DropdownItem,
  dropdownItems,
  handleCheckboxChange,
  handleDropdownSelectChange,
} from './HeaderGroupCheckboxRenderer.model';
import { SIconWrapper, SWrapper } from './HeaderGroupCheckboxRenderer.styles';

export type Props = { api: AgGridApi; context: SalesCreditsGridContext };

const HeaderGroupCheckboxRenderer: React.FC<Props> = ({ api, context }) => {
  const selectableRowsCount = React.useMemo(
    () => context.rows.filter(row => isSalesCreditsRowSelectable({ data: row })).length,
    [context.rows]
  );
  const selectedRowsCount = api.getSelectedNodes().length;

  const isSelected = selectedRowsCount > 0 && selectableRowsCount === selectedRowsCount;
  const isDisabled = selectableRowsCount === 0;

  const onCheckboxChange = React.useCallback(
    (value: boolean) => {
      handleCheckboxChange(api, value);
    },
    [api]
  );
  const onDropdownChange = React.useCallback(
    (values: DropdownItem[]) => {
      handleDropdownSelectChange(api, values);
    },
    [api]
  );

  return (
    <SWrapper>
      <Checkbox
        value={isSelected}
        disabled={isDisabled}
        onChange={onCheckboxChange}
        showDisabledIcon
      />
      <DropdownSelect
        popoverVariant="LIGHT"
        items={dropdownItems}
        onChange={onDropdownChange}
        label={
          <SIconWrapper>
            <Icon name="chevron-down" variant="regular" />
          </SIconWrapper>
        }
      />
    </SWrapper>
  );
};

export default HeaderGroupCheckboxRenderer;
