import { numericUtil } from '@cmg/common';
import React from 'react';

import TableTotalRow from '../../../../../../design-system/components/data-display/table/TableTotalRow';
import TableTotalRowCell from '../../../../../../design-system/components/data-display/table/TableTotalRowCell';

const { getDisplayValueForInteger, getDisplayValueForCurrency } = numericUtil;

export type Props = Readonly<{
  demandShares: number | null;
  demandRoundLots: number | null;
  retentionShares: number | null;
  retentionRoundLots: number | null;
  retainedByTheBank: number | null;
  isReadOnly?: boolean;
}>;

const InternalRetailDemandTableTotalRow: React.FC<Props> = ({
  demandShares,
  demandRoundLots,
  retentionShares,
  retentionRoundLots,
  retainedByTheBank,
  isReadOnly = false,
}) => {
  return (
    <TableTotalRow>
      <TableTotalRowCell size="small" />
      <TableTotalRowCell size="medium">Total</TableTotalRowCell>
      <TableTotalRowCell isEditable={!isReadOnly} size="medium" align="right">
        {getDisplayValueForInteger(demandShares)}
      </TableTotalRowCell>
      <TableTotalRowCell isEditable={!isReadOnly} size="medium" align="right">
        {getDisplayValueForInteger(demandRoundLots)}
      </TableTotalRowCell>
      <TableTotalRowCell isEditable={!isReadOnly} size="medium" align="right">
        {getDisplayValueForInteger(retentionShares)}
      </TableTotalRowCell>
      <TableTotalRowCell isEditable={!isReadOnly} size="medium" align="right">
        {getDisplayValueForInteger(retentionRoundLots)}
      </TableTotalRowCell>
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium" />
      <TableTotalRowCell size="medium" align="right">
        {getDisplayValueForCurrency(retainedByTheBank)}
      </TableTotalRowCell>
      {!isReadOnly && <TableTotalRowCell size="medium" />}
    </TableTotalRow>
  );
};

export default InternalRetailDemandTableTotalRow;
