import { AttestationFormStatus, OfferingType } from '../../../../../../../../graphql';
import offeringSidePanelBuySideRoutes from '../../../../../../offeringSidePanelBuySideRouteFactory';
import { OrderBookPersonaType } from '../../../../../hooks/useIdentifyOrderBookPersona';
import { useBuySideAttestationQuery } from '../../buy-side/useAttestationQuery';
import { CertificateModel, PersonaProps } from '../useCertificatesModel';

export type Props = PersonaProps & {
  offeringId: string;
};

export const COMPLIANCE_WARNING_MESSAGE_BUYSIDE =
  'To submit and modify indications, review the outstanding requirements in the Compliance section. For support, contact your broker relationship(s).';

export const COMPLIANCE_TOOLTIP_WARNING_MESSAGE_BUYSIDE = `5130/31 is required to participate in this offering. Click Copy link to submit the necessary certificates via the provided link.`;

export const useBuySideCertificatesModel = ({
  type,
  offeringId,
  isOrderBookAttestationOn,
}: Props): CertificateModel => {
  const isBuySide = type === OrderBookPersonaType.BUY_SIDE;
  const attestationQuery = useBuySideAttestationQuery(
    { offeringId },
    { skip: !isBuySide || !isOrderBookAttestationOn || !offeringId }
  );
  const { myAttestation = null } = attestationQuery.data ?? {};
  const offeringType = attestationQuery.data?.basicPublishedOffering.type;

  const hasComplianceWarning =
    (myAttestation?.formStatus === AttestationFormStatus.Expired ||
      myAttestation?.formStatus === AttestationFormStatus.NotOnFile) &&
    offeringType === OfferingType.Ipo;
  const redirectUrl = offeringSidePanelBuySideRoutes.orderBookCompliance.getUrlPath();

  return {
    canRead: isBuySide && !!myAttestation,
    attestation: myAttestation,
    loading: attestationQuery.loading,
    offeringType: offeringType,
    warning: {
      hasComplianceWarning,
      canByPassComplianceWarning: false,
      message: COMPLIANCE_WARNING_MESSAGE_BUYSIDE,
      redirectUrl,
      tooltipMessage: COMPLIANCE_TOOLTIP_WARNING_MESSAGE_BUYSIDE,
    },
  };
};
