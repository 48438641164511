import React from 'react';

import routeFactory from '../../../../../../common/util/routeFactory';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { TradeReleaseGridContext } from '../../TradeReleaseGrid.model';
import { StyledLink } from './InvestorNameRenderer.styles';

export type Props = Readonly<{
  value: string;
  data: TradeRelease_TradeGridItemFragment;
  context: TradeReleaseGridContext;
}>;

const InvestorNameRenderer: React.FC<Props> = ({ value, data, context }) => {
  return (
    <StyledLink
      to={routeFactory.tradeReleaseHistory.getUrlPath({
        offeringId: context.offeringId,
        indicationId: data.indicationId,
      })}
    >
      {value}
    </StyledLink>
  );
};

export default InvestorNameRenderer;
