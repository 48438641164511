import { numericUtil } from '@cmg/common';
import type {
  AddFilterExtrasFn,
  GetFilterValueFn,
  ServerColDef,
  ValueFormatterParams,
} from '@cmg/data-grid';
import { getListFilterValueGetter, getSortFieldPath } from '@cmg/data-grid';
import sortBy from 'lodash/sortBy';
import sortedUniq from 'lodash/sortedUniq';

import type { DemandLevelFilterInput } from '../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import { DemandConfigValues, DemandGridDataContext } from '../../types';

/**
 * Exported for testing purposes only
 *
 * @param value
 */
export const valueFormatter = ({
  value,
}: ValueFormatterParams<OrderBook_InstitutionalDemand_GridRowPartsFragment, number>) => {
  return numericUtil.getDisplayValueForNumber(value, 0);
};

export const demandLevelColIdMatch = /^demandLevelAtPrice_(\d)+$/;

/**
 * Exported for testing purposes only
 */
export const sortFieldPath = getSortFieldPath(demandLevelColIdMatch, 'demandLevelAtPrice_');

const getFilterValue: GetFilterValueFn<DemandLevelFilterInput> = value => {
  return { shares: { ...value } };
};

const createAddFilterExtras =
  (price: number): AddFilterExtrasFn<DemandLevelFilterInput, DemandLevelFilterInput> =>
  filterValue => {
    return {
      some: { ...filterValue.some, price: { eq: price } },
    };
  };

const generateDemandPriceLevels = (demandConfig: DemandConfigValues | null) => {
  const demandPriceLevels: number[] = [];

  if (!demandConfig) {
    return demandPriceLevels;
  }

  const { min, max, increment, referencePrice } = demandConfig;

  for (let price = min; price <= max; price += increment) {
    demandPriceLevels.push(price);
  }

  if (referencePrice) {
    demandPriceLevels.push(referencePrice);
  }

  return sortedUniq(sortBy(demandPriceLevels));
};

const getDemandLevelColDefs = (demandConfig: DemandGridDataContext['demandConfig']) => {
  const demandPriceLevels = generateDemandPriceLevels(demandConfig);

  return demandPriceLevels.map<ServerColDef>((price, index) => ({
    colId: `demandLevelAtPrice_${index + 1}`,
    headerName: `@ ${numericUtil.formatCurrency(price, 2)}`,
    type: ['numericColumn', 'number'],
    filter: 'agNumberColumnFilter',
    valueFormatter,
    valueGetter: ({ data }) => {
      return data?.demandLevels?.find(({ price: p }) => p === price)?.shares ?? null;
    },
    server: {
      sortFieldPath,
      sortValueGetter: order => ({ price, shares: order }),
      filterFieldPath: 'demandLevels',
      filterValueGetter: getListFilterValueGetter<DemandLevelFilterInput, DemandLevelFilterInput>(
        getFilterValue,
        createAddFilterExtras(price)
      ),
    },
  }));
};

export default getDemandLevelColDefs;
