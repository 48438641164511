import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateDesignationWireMutation,
  SyndicateWires_DesignationWiresDocument,
  useSyndicateWires_CreateDesignationWireMutation,
} from '../graphql';

export type CreateDesignationWireMutation = SyndicateWires_CreateDesignationWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_DesignationWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useCreateDesignationWireMutation = () => {
  return useSyndicateWires_CreateDesignationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
