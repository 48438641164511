import styled from 'styled-components/macro';

export const SRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const SFieldGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin: 0;
`;

export const SCellContainer = styled.div<{ isTotal?: boolean; isColumnHeader?: boolean }>`
  padding: 9px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.designSystem.colors.gray['300']}`};
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
`;

export const STotalCellContainer = styled.div`
  background-color: ${({ theme }) => theme.brand.color.dark};
  height: 46px;
  color: ${({ theme }) => theme.text.color.white};
  padding: 9px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const SColumnHeader = styled.div`
  border: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.designSystem.colors.gray['300']}`};
  border-top-style: none;
  border-left-style: none;
  padding: 4.5px 4px;
  height: 23px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const SColumnHeaderContainer = styled.div`
  padding: 9px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const SFirtEmptyCell = styled.div`
  border: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.designSystem.colors.gray['300']}`};
  border-top-style: none;
  border-left-style: none;
  height: 23px;
  width: 100%;
`;

export const SRowTitleContainer = styled.div<{ isTotal?: boolean }>`
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.designSystem.colors.gray['300']}`};
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
`;

export const STotalRowTitleContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.brand.color.dark};
  height: 46px;
  color: ${({ theme }) => theme.text.color.white};
`;

export const SDivider = styled.hr`
  border-width: ${({ theme }) => `${theme.border.width.small}`};
  border-style: solid;
  width: 100%;
  border-color: ${({ theme }) => theme.designSystem.colors.gray['300']};
`;

export const SInfoPanelSubtitle = styled.div`
  color: ${({ theme }) => theme.foundation.colors.secondary['500']};
  font-size: ${({ theme }) => theme.text.size.xs};
`;

export const STotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
