import { numericUtil } from '@cmg/common';
import { HighlightsTextItem } from '@cmg/design-system';

import { SecurityType } from '../../../../../graphql';
import { securityTypeOfferingHeaderLabels } from './SizeInSharesTitleItem.model';

type SizeInSharesTitleItemProps = {
  securityType: SecurityType;
  sizeInShares?: number | null;
};

export default function SizeInSharesTitleItem({
  securityType,
  sizeInShares,
}: SizeInSharesTitleItemProps) {
  return (
    <HighlightsTextItem
      name="SIZE"
      text={
        sizeInShares
          ? `${numericUtil.getDisplayValueForInteger(sizeInShares)} ${
              securityTypeOfferingHeaderLabels[securityType]
            }`
          : '-'
      }
    />
  );
}
