import { DropdownButton, Icon } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { useFeatureToggles } from '../../../../../../../common/config';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../../graphql';
import { InstitutionalDemandColumnConfig } from '../../types';
import { InstitutionalDemandGridColumnMenuComponent } from './InstitutionalDemandGridColumnMenu';
import { InstitutionalDemandGridColumnMenuComponentNewFlow } from './InstitutionalDemandGridColumnMenuNewFlow';

export type Props = {
  activeDemandColumnConfig: InstitutionalDemandColumnConfig;
  onDemandColumnConfigChange: (config: InstitutionalDemandColumnConfig) => void;
  isFinalAllocationSetReleased: boolean;
  finalAllocationExists: boolean;
  offeringId: string;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  pricingCurrencyCode?: string | null;
};

/**
 * Renders the Institutional Demand Grid Column Dropdown Menu Button
 */
export const InstitutionalDemandGridColumnsDropdownButton: React.FC<Props> = props => {
  const onVisibleChange = React.useRef<Function>(() => undefined);
  const { isOrderBookDynamicColumnsOn } = useFeatureToggles();

  return (
    <React.Fragment>
      {isOrderBookDynamicColumnsOn && (
        <DropdownButton
          trigger="click"
          popoverVariant="LIGHT"
          onVisibleChange={() => onVisibleChange.current && onVisibleChange.current()}
          testId={xcSelectors.institutionalDemandGridColumnsDropdownButton.testId}
          dropDownContent={close => (
            <InstitutionalDemandGridColumnMenuComponentNewFlow
              {...props}
              onVisibleChange={onVisibleChange}
              close={close}
            />
          )}
        >
          <Icon variant="regular" name="columns" />
        </DropdownButton>
      )}
      {!isOrderBookDynamicColumnsOn && (
        <DropdownButton
          trigger="click"
          popoverVariant="LIGHT"
          testId={xcSelectors.institutionalDemandGridColumnsDropdownButton.testId}
          dropDownContent={() => <InstitutionalDemandGridColumnMenuComponent {...props} />}
        >
          <Icon variant="regular" name="columns" />
        </DropdownButton>
      )}
    </React.Fragment>
  );
};

export default InstitutionalDemandGridColumnsDropdownButton;
