import React from 'react';

import Banner from '../../../../../common/components/indicators/banner/Banner';

export type Props = {
  errors: readonly string[];
};

const FinraErrorResponseBanner: React.FC<Props> = ({ errors }) => {
  return (
    <Banner variant="error">
      <Banner.Title>FINRA rejected this filing with the following error(s)</Banner.Title>
      {errors.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </Banner>
  );
};

export default FinraErrorResponseBanner;
