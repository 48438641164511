import { Tooltip } from '@cmg/design-system';
import React from 'react';

import { formatInterestLevel } from '../../../../utils';
import type { DemandGridCellRendererProps } from '../../types';

export type Props = DemandGridCellRendererProps<string>;

export const TypeCellRenderer: React.FC<Props> = ({ data, valueFormatted, context }) => {
  const { pricingCurrencyCode } = context;
  const { currencyCode: demandCurrencyCode } = data ?? {};

  return (
    <Tooltip
      variant="info"
      placement="top"
      title={data?.interestLevels?.map((interestLevel, index) => (
        <React.Fragment key={index}>
          {formatInterestLevel({
            interestLevel,
            pricingCurrencyCode,
            demandCurrencyCode,
          })}
          <br />
        </React.Fragment>
      ))}
    >
      <span>{valueFormatted}</span>
    </Tooltip>
  );
};
