import styled from 'styled-components/macro';

export const SManagerGroupsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.interactive.primary};
  border-radius: 2px;
`;

export const SButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 8px 8px;
`;

export const SSeparator = styled.div`
  border-top: ${({ theme }) => theme.border.smallSolidLight};
  width: 100%;
  margin: 32px 0;
`;

export const SListContainer = styled.div`
  margin-top: 24px;
`;

export const SSubheader = styled.div`
  padding: 8px 0;
`;

export const SEmptyManagers = styled.div`
  text-align: center;
  padding: 24px;
`;

export const SEmptyManagersText = styled.div`
  color: ${({ theme }) => theme.text.color.dark};
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SEmptyManagersSubtext = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;
