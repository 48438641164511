import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

import calendarPricedDeals from '../../../../common/assets/i-calendar-priced-deals.svg';

export const SMyOfferingsQuickAccess = styled.div`
  width: 600px;
`;

export const SQuickAccessHeader = styled.div`
  margin: 15px;
`;

export const SQuickAccessHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & h2 {
    margin: 0;
  }
`;

export const SOfferingsList = styled.div`
  height: 600px;
  overflow-y: auto;
  margin: 0 8px;
`;

/** we only show the offering actions when the offering item is hovered */
export const SOfferingActions = styled.div`
  display: none;
  align-items: center;
`;

export const SOfferingListItem = styled.div`
  padding: 15px;
  height: 80px;
  width: 100%;

  border: ${({ theme }) => `${theme.border.width.small} solid transparent`};

  &:hover {
    border-radius: ${({ theme }) => theme.border.radius.medium};
    border-color: ${({ theme }) => theme.interactive.primary};
    /* show offering actions */
    & > ${SOfferingActions} {
      display: flex;
    }
  }
`;

export const SOfferingDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 10px;
`;

export const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SOfferingIssuer = styled(Truncate)`
  flex: 1 1 40%;
  padding-right: 10px;
`;

export const SOfferingType = styled(Truncate)`
  flex: 1 1 30%;
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SOfferingStatus = styled.div`
  flex: 1 1 15%;
`;

export const SOfferingSize = styled.div`
  display: flex;
  flex: 1 1 15%;
  justify-content: flex-end;
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SOfferingAction = styled.div`
  margin-right: 16px;
`;

export const StyledLinkIcon = styled(Icon)`
  margin-right: 10px;
`;

export const SSymbol = styled.span`
  text-transform: uppercase;
  margin-right: 3px;
`;

const SNoOfferingsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const SNoOfferingsSubtitle = styled.div`
  color: ${({ theme }) => theme.text.color.light};
`;

const StyledNoOfferingsIcon = styled.img.attrs({
  src: calendarPricedDeals,
  alt: 'No recent offerings',
})`
  margin-bottom: 16px;
`;

const SNoOfferingsTitle = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SNoOfferings = Object.assign({}, SNoOfferingsWrapper, {
  Icon: StyledNoOfferingsIcon,
  Title: SNoOfferingsTitle,
  Subtitle: SNoOfferingsSubtitle,
});
