import { getOperationName } from '@apollo/client/utilities';

import {
  FinalSettlement_SettlementDesignationsDocument,
  useFinalSettlement_PublishDesignationsMutation,
} from '../graphql/__generated__';

export function refetchQueries() {
  const refetchDesignations = getOperationName(FinalSettlement_SettlementDesignationsDocument);
  return [refetchDesignations!];
}

export const usePublishDesignationsMutation = () => {
  return useFinalSettlement_PublishDesignationsMutation({
    refetchQueries,
    awaitRefetchQueries: false,
  });
};
