import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteIssuerAllocationWireMutation,
  SyndicateWires_IssuerAllocationWiresDocument,
  useSyndicateWires_DeleteIssuerAllocationWireMutation,
} from '../graphql';

export type DeleteIssuerAllocationWireMutation = SyndicateWires_DeleteIssuerAllocationWireMutation;

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_IssuerAllocationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}

export const useDeleteIssuerAllocationWireMutation = () => {
  return useSyndicateWires_DeleteIssuerAllocationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
