import { getOperationName } from '@apollo/client/utilities';

import { MyOrders_CoveredIndicationsDocument } from '../../../../../../my-orders/graphql';
import { useCrmSelection_AssignCoveredInvestorKeyMutation } from '../graphql/__generated__';

export const refetchQueries = () => {
  return [getOperationName(MyOrders_CoveredIndicationsDocument)!];
};

export const useAssignCoveredInvestorKeyMutation = () => {
  return useCrmSelection_AssignCoveredInvestorKeyMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
