import type { ColDef } from '@cmg/data-grid';

import { ExtendedDataCategory } from '../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridDataContext } from '../../types';

export const extendedDataFieldNameMatch = /^investor.extensions.+$/;

export function createExtendedDataColumn(
  schema: OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment,
  override: ColDef = {}
) {
  const fieldName = schema.columnName
    ? schema.columnName.charAt(0).toLowerCase() + schema.columnName.slice(1)
    : '';
  return {
    field: `investor.extensions.${fieldName}`,
    headerName: schema.displayName!,
    tooltipField: `investor.extensions.${fieldName}`,
    ...override,
  };
}

export const getExtendedDataComplianceColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.Compliance)
      .map(item => createExtendedDataColumn(item, { enableRowGroup: true })) ?? []
  );
};

export const getExtendedDataInvestorCommentsColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.InvestorComments)
      .map(item => createExtendedDataColumn(item)) ?? []
  );
};

export const getExtendedDataInvestorRegionColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.InvestorRegion)
      .map(item => createExtendedDataColumn(item, { enableRowGroup: true })) ?? []
  );
};

export const getExtendedDataInvestorTypeColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.InvestorType)
      .map(item => createExtendedDataColumn(item, { enableRowGroup: true })) ?? []
  );
};

export const getExtendedDataOthersColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.OtherInvestorAttributes)
      .map(item => createExtendedDataColumn(item)) ?? []
  );
};

export const getExtendedDataRevenueColDefs = (
  investorExtensionSchema: DemandGridDataContext['investorExtensionSchema']
) => {
  return (
    investorExtensionSchema
      ?.filter(item => item.category === ExtendedDataCategory.Revenue)
      .map(item =>
        createExtendedDataColumn(item, {
          type: ['numericColumn', 'number'],
          tooltipField: undefined,
        })
      ) ?? []
  );
};
