import { urlUtil } from '@cmg/common';
import { createContext, useContext, useMemo } from 'react';

import { AppSettings, getAppSettings } from './appSettings';

export const AppSettingsContext = createContext<AppSettings>(getAppSettings());

export const useAppSettings = () => {
  return useContext(AppSettingsContext);
};

export const useFeatureToggles = () => {
  const { features } = useAppSettings();
  return features;
};

export const useIdentityServiceHost = () => {
  const {
    env: { identityServiceBaseUrl },
  } = useAppSettings();

  return useMemo(() => {
    return urlUtil.addSubdomainToUrl(
      identityServiceBaseUrl,
      urlUtil.getAccountSubdomain(window.location)
    );
  }, [identityServiceBaseUrl]);
};
