import { timeUtil } from '@cmg/common';
import { ValueGetterParams } from 'ag-grid-community';

export const booksCloseAtValueGetter = ({
  data: { timeZone, booksCloseAt },
}: ValueGetterParams) => {
  return booksCloseAt && timeZone
    ? `${timeUtil.formatAsDisplayDateTime(
        booksCloseAt,
        timeZone
      )} ${timeUtil.formatAsTimeZoneAbbreviation(booksCloseAt, timeZone)}`
    : '-';
};
