import { numericUtil } from '@cmg/common';
import { HighlightsTextItem } from '@cmg/design-system';

import {
  Offering_OfferingHeader_OfferingProfileTermsPartsFragment,
  Offering_OfferingHeader_OfferingTermsPartsFragment,
} from '../../../graphql';
import { getLatestFilingsPrices } from './PricingTitleItems.model';

type PricingTitleItemPros = {
  terms?:
    | Offering_OfferingHeader_OfferingTermsPartsFragment
    | Offering_OfferingHeader_OfferingProfileTermsPartsFragment;
  pricingCurrencyCode?: string;
};

export function IPOPricingTitleItem({ terms, pricingCurrencyCode }: PricingTitleItemPros) {
  const { offerPrice, ipoRangeHigh, ipoRangeLow } = getLatestFilingsPrices(terms?.filings);
  if (offerPrice) {
    return (
      <HighlightsTextItem
        name="PRICE"
        text={`${numericUtil.getDisplayValueForCurrency(
          offerPrice,
          undefined,
          pricingCurrencyCode
        )}`}
      />
    );
  }
  if (ipoRangeHigh && ipoRangeLow) {
    return (
      <HighlightsTextItem
        name="PRICE RANGE"
        text={`${numericUtil.getDisplayValueForCurrency(
          ipoRangeLow,
          undefined,
          pricingCurrencyCode
        )}–${numericUtil.getDisplayValueForCurrency(ipoRangeHigh, undefined, pricingCurrencyCode)}`}
      />
    );
  }
  return <HighlightsTextItem name="PRICE RANGE" text="-" />;
}

export function FOPricingTitleItem({ terms, pricingCurrencyCode }: PricingTitleItemPros) {
  const { offerPrice } = getLatestFilingsPrices(terms?.filings);
  const { reOfferLow, reOfferHigh } = terms?.followOnPricing || {};
  if (offerPrice) {
    return (
      <HighlightsTextItem
        name="PRICE"
        text={numericUtil.getDisplayValueForCurrency(offerPrice, undefined, pricingCurrencyCode)}
      />
    );
  }
  if (reOfferLow && reOfferHigh) {
    return (
      <HighlightsTextItem
        name="PRICE RANGE"
        text={`${numericUtil.getDisplayValueForCurrency(
          reOfferLow,
          undefined,
          pricingCurrencyCode
        )}–${numericUtil.getDisplayValueForCurrency(reOfferHigh, undefined, pricingCurrencyCode)}`}
      />
    );
  }
  return <HighlightsTextItem name="PRICE RANGE" text="-" />;
}
