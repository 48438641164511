import { Stack, SxProps, Tab, Tabs, Theme, Typography } from '@cmg/design-system';
import React, { useMemo } from 'react';

import type { DemandGridCustomStatusPanelProps } from '../types';
import { getSummaryMetricsMap } from './SummaryMetricsStatusPanel.model';

export type Props = DemandGridCustomStatusPanelProps;

const TAB_STYLE: SxProps<Theme> = {
  flex: 1,
  minWidth: 'fit-content',
  padding: 1,
};

export const SummaryMetricsStatusPanel: React.FC<Props> = ({
  context: { totalSummaryMetrics, selectedRowsSummaryMetrics },
}) => {
  const metricsMap = useMemo(
    () => getSummaryMetricsMap({ totalSummaryMetrics, selectedRowsSummaryMetrics }),
    [selectedRowsSummaryMetrics, totalSummaryMetrics]
  );

  return (
    <Tabs aria-label="Summary Metrics" value={false} variant="scrollable" scrollButtons="auto">
      {metricsMap.map(([label, value]) => {
        return (
          <Tab
            key={label}
            disabled
            sx={TAB_STYLE}
            label={
              <Stack key={label} direction="row" aria-label={label}>
                <Typography color="text.secondary">{label}:&nbsp;</Typography>
                <Typography color="text.primary" fontWeight="bold">
                  {value}
                </Typography>
              </Stack>
            }
          />
        );
      })}
    </Tabs>
  );
};
