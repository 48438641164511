import { Form } from 'formik';
import styled from 'styled-components/macro';

import Modal from '../../../../common/components/overlays/modal/Modal';

export const SText = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
`;

export const SModalContent = styled(Modal.Content)`
  height: 450px;
`;

export const StyledForm = styled(Form)`
  display: contents;
`;
