import { NumericInputField } from '@cmg/common';
import styled from 'styled-components/macro';

// the wrapper is needed to make the Popover appear at all when over an Icon (svg)
export const SPrefixWrapper = styled.span`
  margin-left: 10px;
`;
export const SDirtyIndicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.brand.color.warningAlternate};
  border-left: 2px solid rgba(0, 0, 0, 0.15);
`;

export const SColumn = styled.div`
  padding: 4px;
`;

export const StyledNumericInputField = styled(NumericInputField)`
  input {
    height: 100%;
  }
`;
