import { LinkButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SAddCustomSymbolLabel = styled.div<{ withTopPadding?: boolean }>`
  color: ${({ theme }) => theme.text.color.light};
  padding: ${({ withTopPadding }) => (withTopPadding ? `8px 12px` : `0 12px 8px 12px`)};
  text-align: center;
  box-sizing: border-box;
`;

export const StyledAddNewButton = styled(LinkButton)`
  background-color: ${({ theme }) => theme.background.color.light};
  text-align: center;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

export const SOptionsWrapper = styled.div<{ withBorderRadius?: boolean }>`
  ${({ withBorderRadius }) =>
    !withBorderRadius &&
    css`
      [class*='-option']:last-of-type {
        border-radius: 0;
      }
    `}
`;

export const SOptionLabel = styled.div`
  flex: 1;
`;

export const SCustomLabel = styled.span<{ isSelected?: boolean }>`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? theme.text.color.white : theme.text.color.light};
    size: ${theme.text.size.small};
  `}
`;
