import { Alert, AlertTitle, Button } from '@cmg/design-system';
import React from 'react';

import { RegulatoryFilings_RolodexSyndicateManagerPartsFragment } from '../../../graphql';

export const getErrorMessage = (
  distributionParticipants: RegulatoryFilings_RolodexSyndicateManagerPartsFragment[]
): string | null => {
  const invalidFirmsCrdNumbers: number[] = [];

  distributionParticipants.forEach(({ finraInfo }) => {
    // compile the list of firms that have a CRD number but no name
    if (finraInfo?.crdNumber && !finraInfo.name) {
      invalidFirmsCrdNumbers.push(finraInfo.crdNumber);
    }
  });

  if (!invalidFirmsCrdNumbers.length) return null;

  return `CRD Number(s): ${invalidFirmsCrdNumbers.join(', ')}`;
};

export type Props = {
  distributionParticipants: RegulatoryFilings_RolodexSyndicateManagerPartsFragment[] | undefined;
};

export const FinraDistributionParticipantNameAlert: React.FC<Props> = ({
  distributionParticipants,
}) => {
  if (!distributionParticipants) return null;

  const errorMessage = getErrorMessage(distributionParticipants);

  if (!errorMessage) return null;

  return (
    <Alert
      severity="error"
      sx={{ marginY: 2 }}
      action={
        <Button color="inherit" onClick={() => (window.location.href = 'mailto:support@cmgx.io')}>
          Contact Support
        </Button>
      }
    >
      <AlertTitle>FINRA Name not set for some distribution participants.</AlertTitle>
      {errorMessage}
    </Alert>
  );
};
