import { styled } from '@cmg/design-system';

export const OnPlatformLogo = styled('img')({
  height: '12px',
  marginRight: '7px',
});

export const CountryCodeLayout = styled('div')({
  display: 'flex',
  gap: '5px',
  alignItems: 'end',
});
