/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RoadshowPartsFragment = {
  readonly __typename?: 'Roadshow';
  readonly endDate: string;
  readonly id: string;
  readonly offeringId?: string | null;
  readonly offeringIssuerName?: string | null;
  readonly roadshowName: string;
  readonly roadshowType: Types.RoadshowType;
  readonly startDate: string;
  readonly status: Types.RoadshowStatus;
  readonly companyName?: string | null;
  readonly companyId?: string | null;
  readonly defaultTimeZone?: string | null;
  readonly roadshowDays: ReadonlyArray<{
    readonly __typename?: 'RoadshowDay';
    readonly date: string;
    readonly timeZone?: string | null;
    readonly isClosed: boolean;
    readonly isVirtual: boolean;
    readonly cities: ReadonlyArray<{
      readonly __typename?: 'City';
      readonly name: string;
      readonly timeZone: string;
    }>;
  }>;
};

export type Roadshows_RoadshowLimitedPartsFragment = {
  readonly __typename?: 'RoadshowLimited';
  readonly id: string;
  readonly roadshowName: string;
  readonly roadshowType: Types.RoadshowType;
  readonly companyName?: string | null;
  readonly startDate: string;
  readonly endDate: string;
  readonly offeringId?: string | null;
  readonly offeringIssuerName?: string | null;
};

export type Roadshows_ManagementTeamMemberPartsFragment = {
  readonly __typename?: 'ManagementTeamMember';
  readonly id: string;
  readonly name: string;
  readonly title?: string | null;
  readonly officePhone?: string | null;
  readonly mobilePhone?: string | null;
  readonly email: string;
  readonly isParticipating: boolean;
};

export type Roadshows_ManagementTeamPartsFragment = {
  readonly __typename?: 'ManagementTeam';
  readonly id: string;
  readonly members: ReadonlyArray<{
    readonly __typename?: 'ManagementTeamMember';
    readonly id: string;
    readonly name: string;
    readonly title?: string | null;
    readonly officePhone?: string | null;
    readonly mobilePhone?: string | null;
    readonly email: string;
    readonly isParticipating: boolean;
  }>;
};

export type Roadshows_EventParticipantPartsFragment = {
  readonly __typename?: 'EventParticipant';
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly title?: string | null;
  readonly isAdHoc: boolean;
  readonly officePhone?: string | null;
  readonly mobilePhone?: string | null;
  readonly status?: Types.DealTeamMemberStatus | null;
};

export type Roadshows_EventParticipantsTeamPartsFragment = {
  readonly __typename?: 'EventParticipantsTeam';
  readonly id: string;
  readonly name: string;
  readonly cmgEntityKey: string;
  readonly participants: ReadonlyArray<{
    readonly __typename?: 'EventParticipant';
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly title?: string | null;
    readonly isAdHoc: boolean;
    readonly officePhone?: string | null;
    readonly mobilePhone?: string | null;
    readonly status?: Types.DealTeamMemberStatus | null;
  }>;
};

export type Roadshows_MeetingDetailsPartsFragment = {
  readonly __typename?: 'MeetingDetails';
  readonly id: string;
  readonly date: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly timezone: string;
  readonly title: string;
  readonly location?: string | null;
  readonly address?: string | null;
  readonly venue?: string | null;
  readonly roomName?: string | null;
  readonly publicNotes?: string | null;
  readonly type: Types.MeetingType;
  readonly status: Types.EventStatus;
  readonly isPublic: boolean;
  readonly format: Types.MeetingFormat;
  readonly dialInDetails?: string | null;
  readonly hostPersonEmail?: string | null;
  readonly hostPersonFullName?: string | null;
  readonly hostFirmCmgEntityKey?: string | null;
};

export type Roadshows_MeetingPartsFragment = {
  readonly __typename?: 'Meeting';
  readonly meetingDetails: {
    readonly __typename?: 'MeetingDetails';
    readonly id: string;
    readonly date: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly timezone: string;
    readonly title: string;
    readonly location?: string | null;
    readonly address?: string | null;
    readonly venue?: string | null;
    readonly roomName?: string | null;
    readonly publicNotes?: string | null;
    readonly type: Types.MeetingType;
    readonly status: Types.EventStatus;
    readonly isPublic: boolean;
    readonly format: Types.MeetingFormat;
    readonly dialInDetails?: string | null;
    readonly hostPersonEmail?: string | null;
    readonly hostPersonFullName?: string | null;
    readonly hostFirmCmgEntityKey?: string | null;
  };
  readonly investors: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
  readonly participantsTeam: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export type Roadshows_CompanyPartsFragment = {
  readonly __typename?: 'Company';
  readonly id: string;
  readonly name: string;
  readonly website?: string | null;
  readonly industryTypes: ReadonlyArray<Types.FirmIndustryType>;
  readonly address?: {
    readonly __typename?: 'CompanyAddress';
    readonly streetLine1?: string | null;
    readonly streetLine2?: string | null;
    readonly streetLine3?: string | null;
    readonly city?: string | null;
    readonly state?: string | null;
    readonly country?: string | null;
  } | null;
};

export type Roadshows_DealTeamMemberPartsFragment = {
  readonly __typename?: 'DealTeamMember';
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly mobilePhone?: string | null;
  readonly officePhone?: string | null;
  readonly department?: string | null;
  readonly type: Types.DealTeamMemberType;
  readonly status: Types.DealTeamMemberStatus;
};

export type Roadshows_DealTeamPartsFragment = {
  readonly __typename?: 'DealTeam';
  readonly id: string;
  readonly firmCmgEntityKey: string;
  readonly firmName: string;
  readonly departments: ReadonlyArray<string>;
  readonly participatedRoadshowNames: ReadonlyArray<string>;
  readonly members: ReadonlyArray<{
    readonly __typename?: 'DealTeamMember';
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly mobilePhone?: string | null;
    readonly officePhone?: string | null;
    readonly department?: string | null;
    readonly type: Types.DealTeamMemberType;
    readonly status: Types.DealTeamMemberStatus;
  }>;
};

export type Roadshows_DealTeamMemberRoadshowNamesPartsFragment = {
  readonly __typename?: 'DealTeamMemberRoadshowNames';
  readonly participatedRoadshowNames: ReadonlyArray<string>;
};

export type Roadshows_RoadshowsDealTeamPartsFragment = {
  readonly __typename?: 'RoadshowsDealTeam';
  readonly id: string;
  readonly firmCmgEntityKey: string;
  readonly firmName: string;
  readonly members: ReadonlyArray<{
    readonly __typename?: 'DealTeamMember';
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly mobilePhone?: string | null;
    readonly officePhone?: string | null;
    readonly department?: string | null;
    readonly type: Types.DealTeamMemberType;
    readonly status: Types.DealTeamMemberStatus;
  }>;
};

export type Roadshows_LogisticsPartsFragment = {
  readonly __typename?: 'Logistics';
  readonly id: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly location: string;
  readonly notes?: string | null;
  readonly title: string;
};

export type Roadshows_RoadshowEventPartsFragment = {
  readonly __typename?: 'RoadshowEvent';
  readonly id: string;
  readonly endTime: string;
  readonly endDate: string;
  readonly hostFirm?: string | null;
  readonly hostName?: string | null;
  readonly location?: string | null;
  readonly publicNotes?: string | null;
  readonly roadshowEventType: Types.RoadshowEventType;
  readonly startTime: string;
  readonly startDate: string;
  readonly status?: Types.EventStatus | null;
  readonly title: string;
  readonly type?: Types.MeetingType | null;
  readonly participantTeams: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export type Roadshows_DealTeamNameAndDepartmentsPartsFragment = {
  readonly __typename?: 'DealTeamNameAndDepartments';
  readonly id: string;
  readonly firmCmgEntityKey: string;
  readonly firmName: string;
  readonly departments: ReadonlyArray<string>;
};

export type Roadshows_DealTeamMemberLimitedPartsFragment = {
  readonly __typename?: 'DealTeamMemberLimited';
  readonly id: string;
  readonly fullName: string;
  readonly firmName: string;
  readonly isMyOrganization: boolean;
  readonly department?: string | null;
};

export type Roadshows_RoadshowDetailsQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_RoadshowDetailsQuery = {
  readonly __typename?: 'Query';
  readonly roadshow: {
    readonly __typename?: 'Roadshow';
    readonly endDate: string;
    readonly id: string;
    readonly offeringId?: string | null;
    readonly offeringIssuerName?: string | null;
    readonly roadshowName: string;
    readonly roadshowType: Types.RoadshowType;
    readonly startDate: string;
    readonly status: Types.RoadshowStatus;
    readonly companyName?: string | null;
    readonly companyId?: string | null;
    readonly defaultTimeZone?: string | null;
    readonly roadshowDays: ReadonlyArray<{
      readonly __typename?: 'RoadshowDay';
      readonly date: string;
      readonly timeZone?: string | null;
      readonly isClosed: boolean;
      readonly isVirtual: boolean;
      readonly cities: ReadonlyArray<{
        readonly __typename?: 'City';
        readonly name: string;
        readonly timeZone: string;
      }>;
    }>;
  };
};

export const Roadshows_RoadshowPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Roadshow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVirtual' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_RoadshowLimitedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_ManagementTeamMemberPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_ManagementTeamPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_MeetingDetailsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_EventParticipantPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_EventParticipantsTeamPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_MeetingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meeting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_CompanyPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_CompanyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryTypes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_DealTeamMemberPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_DealTeamPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_DealTeamMemberRoadshowNamesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberRoadshowNamesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DealTeamMemberRoadshowNames' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'participatedRoadshowNames' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_RoadshowsDealTeamPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowsDealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowsDealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_LogisticsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Logistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_RoadshowEventPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowEventParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowEvent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowEventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantTeams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_DealTeamNameAndDepartmentsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamNameAndDepartmentsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DealTeamNameAndDepartments' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departments' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_DealTeamMemberLimitedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMemberLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMyOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Roadshows_RoadshowDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Roadshow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVirtual' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowDetailsQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowDetailsQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_RoadshowDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowDetailsQuery,
    Roadshows_RoadshowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_RoadshowDetailsQuery, Roadshows_RoadshowDetailsQueryVariables>(
    Roadshows_RoadshowDetailsDocument,
    options
  );
}
export function useRoadshows_RoadshowDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowDetailsQuery,
    Roadshows_RoadshowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowDetailsQuery,
    Roadshows_RoadshowDetailsQueryVariables
  >(Roadshows_RoadshowDetailsDocument, options);
}
export type Roadshows_RoadshowDetailsQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDetailsQuery
>;
export type Roadshows_RoadshowDetailsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDetailsLazyQuery
>;
export type Roadshows_RoadshowDetailsQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowDetailsQuery,
  Roadshows_RoadshowDetailsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowDetailsMockResponse = makeMockResponse<
  Roadshows_RoadshowDetailsQueryVariables,
  Roadshows_RoadshowDetailsQuery
>(Roadshows_RoadshowDetailsDocument);
