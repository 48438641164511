import omit from 'lodash/omit';
import React from 'react';

export type Action =
  | { type: 'setIsEditing'; payload: boolean }
  | { type: 'setResetFormToggle'; payload: boolean }
  | {
      type: 'upsertChangesByIndicationId';
      payload: {
        indicationId: string;
        initialValue: number | null;
        newValue: number | null;
      };
    }
  | { type: 'setIsFinalAllocationSetReleased'; payload: boolean }
  | { type: 'setIsUpsertChangesByIndicationIdLoading'; payload: boolean };

export type Dispatch = (action: Action) => void;

export type State = {
  isEditing: boolean;
  resetFormToggle: boolean;
  changesByIndicationId: {
    [key: string]: {
      initialValue: number | null;
      newValue: number | null;
    };
  };
  isFinalAllocationSetReleased: boolean;
  isUpsertChangesByIndicationIdLoading: boolean;
};

type FinalAllocationsProviderProps = {
  children: React.ReactNode;
};

export const FinalAllocationsStateContext = React.createContext<State | undefined>(undefined);
export const FinalAllocationsDispatchContext = React.createContext<Dispatch | undefined>(undefined);
export const defaultState = {
  isEditing: false,
  resetFormToggle: false,
  changesByIndicationId: {},
  isFinalAllocationSetReleased: false,
  isUpsertChangesByIndicationIdLoading: false,
};

// TODO - this entire file is a mess and should likely be removed.
export const finalAllocationsReducer = (state: State, action: Action) => {
  switch (action.type) {
    /*
     * Store changes of initialValue(iV) and newValue(nV)
     * if iV === nV discard the change (false positive)
     * and remove key from changesByIndicationId
     */
    case 'upsertChangesByIndicationId': {
      const { indicationId, ...rest } = action.payload;
      const falsePositive = rest.initialValue === rest.newValue;

      return {
        ...state,
        changesByIndicationId: {
          ...(falsePositive
            ? omit(state.changesByIndicationId, indicationId)
            : {
                ...state.changesByIndicationId,
                [indicationId]: rest,
              }),
        },
      };
    }
    case 'setIsEditing': {
      return {
        ...state,
        isEditing: action.payload,
      };
    }
    case 'setResetFormToggle': {
      return {
        ...state,
        resetFormToggle: action.payload,
        changesByIndicationId: {},
      };
    }
    case 'setIsFinalAllocationSetReleased': {
      return {
        ...state,
        isFinalAllocationSetReleased: action.payload,
      };
    }
    case 'setIsUpsertChangesByIndicationIdLoading': {
      return {
        ...state,
        isUpsertChangesByIndicationIdLoading: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
    }
  }
};

export const FinalAllocationsProvider = ({ children }: FinalAllocationsProviderProps) => {
  const [state, dispatch] = React.useReducer(finalAllocationsReducer, defaultState);

  return (
    <FinalAllocationsStateContext.Provider value={state}>
      <FinalAllocationsDispatchContext.Provider value={dispatch}>
        {children}
      </FinalAllocationsDispatchContext.Provider>
    </FinalAllocationsStateContext.Provider>
  );
};

const useFinalAllocationsState = () => {
  const context = React.useContext(FinalAllocationsStateContext);
  if (context === undefined) {
    throw new Error('useFinalAllocationsState must be used within a FinalAllocationsProvider');
  }
  return context;
};

const useFinalAllocationsDispatch = () => {
  const context = React.useContext(FinalAllocationsDispatchContext);
  if (context === undefined) {
    throw new Error('useFinalAllocationsDispatch must be used within a FinalAllocationsProvider');
  }
  return context;
};

export const useFinalAllocations = (): [State, Dispatch] => [
  useFinalAllocationsState(),
  useFinalAllocationsDispatch(),
];
