/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateNonSyndicateTerminationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateNonSyndicateTerminationWireInput;
}>;

export type SyndicateWires_CreateNonSyndicateTerminationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createNonSyndicateTerminationWire:
    | {
        readonly __typename?: 'NonSyndicateTerminationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly terminationDate: {
          readonly __typename?: 'TerminationDate';
          readonly date: string;
          readonly timezone: string;
        };
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeleteNonSyndicateTerminationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_DeleteNonSyndicateTerminationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteNonSyndicateTerminationWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_NonSyndicateTerminationWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly publishedOrPrivateOffering: {
      readonly __typename?: 'SyndicateWiresOffering';
      readonly id: string;
      readonly stage: Types.OfferingStage;
    };
  };
  readonly nonSyndicateTerminationWireDetails: {
    readonly __typename?: 'NonSyndicateTerminationWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly disclaimer?: string | null;
    readonly terminationDate: {
      readonly __typename?: 'TerminationDate';
      readonly date: string;
      readonly timezone: string;
    };
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_NonSyndicateTerminationWirePartsFragment = {
  readonly __typename?: 'NonSyndicateTerminationWire';
  readonly id: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly disclaimer?: string | null;
  readonly terminationDate: {
    readonly __typename?: 'TerminationDate';
    readonly date: string;
    readonly timezone: string;
  };
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SyndicateWires_NonSyndicateTerminationWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateTerminationWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_NonSyndicateTerminationWireValidationPartsFragment = {
  readonly __typename?: 'NonSyndicateTerminationWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.NonSyndicateTerminationWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_NonSyndicateTerminationWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateTerminationWireValidation: {
    readonly __typename?: 'NonSyndicateTerminationWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.NonSyndicateTerminationWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_NonSyndicateTerminationWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_NonSyndicateTerminationWiresQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateTerminationWireList: ReadonlyArray<{
    readonly __typename?: 'NonSyndicateTerminationWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly disclaimer?: string | null;
    readonly terminationDate: {
      readonly __typename?: 'TerminationDate';
      readonly date: string;
      readonly timezone: string;
    };
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendNonSyndicateTerminationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  sendDateTimeZone: Types.Scalars['String'];
  shouldAddSenderToCc: Types.Scalars['Boolean'];
}>;

export type SyndicateWires_SendNonSyndicateTerminationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendNonSyndicateTerminationWire:
    | {
        readonly __typename?: 'NonSyndicateTerminationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly terminationDate: {
          readonly __typename?: 'TerminationDate';
          readonly date: string;
          readonly timezone: string;
        };
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | { readonly __typename?: 'ServiceError' };
};

export type SyndicateWires_UpdateNonSyndicateTerminationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  payload: Types.UpdateNonSyndicateTerminationWireInput;
}>;

export type SyndicateWires_UpdateNonSyndicateTerminationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateNonSyndicateTerminationWire:
    | {
        readonly __typename?: 'NonSyndicateTerminationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly disclaimer?: string | null;
        readonly terminationDate: {
          readonly __typename?: 'TerminationDate';
          readonly date: string;
          readonly timezone: string;
        };
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_NonSyndicateTerminationWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_NonSyndicateTerminationWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateNonSyndicateTerminationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreateNonSyndicateTerminationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateNonSyndicateTerminationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNonSyndicateTerminationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateNonSyndicateTerminationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_CreateNonSyndicateTerminationWireMutation,
  SyndicateWires_CreateNonSyndicateTerminationWireMutationVariables
>;

/**
 * __useSyndicateWires_CreateNonSyndicateTerminationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateNonSyndicateTerminationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateNonSyndicateTerminationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateNonSyndicateTerminationWireMutation, { data, loading, error }] = useSyndicateWires_CreateNonSyndicateTerminationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateNonSyndicateTerminationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateNonSyndicateTerminationWireMutation,
    SyndicateWires_CreateNonSyndicateTerminationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateNonSyndicateTerminationWireMutation,
    SyndicateWires_CreateNonSyndicateTerminationWireMutationVariables
  >(SyndicateWires_CreateNonSyndicateTerminationWireDocument, options);
}
export type SyndicateWires_CreateNonSyndicateTerminationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_CreateNonSyndicateTerminationWireMutation
>;
export type SyndicateWires_CreateNonSyndicateTerminationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateNonSyndicateTerminationWireMutation>;
export type SyndicateWires_CreateNonSyndicateTerminationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_CreateNonSyndicateTerminationWireMutation,
    SyndicateWires_CreateNonSyndicateTerminationWireMutationVariables
  >;
export const SyndicateWires_DeleteNonSyndicateTerminationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteNonSyndicateTerminationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNonSyndicateTerminationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteNonSyndicateTerminationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeleteNonSyndicateTerminationWireMutation,
  SyndicateWires_DeleteNonSyndicateTerminationWireMutationVariables
>;

/**
 * __useSyndicateWires_DeleteNonSyndicateTerminationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteNonSyndicateTerminationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteNonSyndicateTerminationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteNonSyndicateTerminationWireMutation, { data, loading, error }] = useSyndicateWires_DeleteNonSyndicateTerminationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteNonSyndicateTerminationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteNonSyndicateTerminationWireMutation,
    SyndicateWires_DeleteNonSyndicateTerminationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteNonSyndicateTerminationWireMutation,
    SyndicateWires_DeleteNonSyndicateTerminationWireMutationVariables
  >(SyndicateWires_DeleteNonSyndicateTerminationWireDocument, options);
}
export type SyndicateWires_DeleteNonSyndicateTerminationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeleteNonSyndicateTerminationWireMutation
>;
export type SyndicateWires_DeleteNonSyndicateTerminationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteNonSyndicateTerminationWireMutation>;
export type SyndicateWires_DeleteNonSyndicateTerminationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_DeleteNonSyndicateTerminationWireMutation,
    SyndicateWires_DeleteNonSyndicateTerminationWireMutationVariables
  >;
export const SyndicateWires_NonSyndicateTerminationWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedOrPrivateOffering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateTerminationWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateTerminationWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateTerminationWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateTerminationWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateTerminationWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWireDetailsQuery,
    SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateTerminationWireDetailsQuery,
    SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWireDetailsDocument, options);
}
export function useSyndicateWires_NonSyndicateTerminationWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWireDetailsQuery,
    SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateTerminationWireDetailsQuery,
    SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWireDetailsDocument, options);
}
export type SyndicateWires_NonSyndicateTerminationWireDetailsQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWireDetailsQuery
>;
export type SyndicateWires_NonSyndicateTerminationWireDetailsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWireDetailsLazyQuery
>;
export type SyndicateWires_NonSyndicateTerminationWireDetailsQueryResult = Apollo.QueryResult<
  SyndicateWires_NonSyndicateTerminationWireDetailsQuery,
  SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables
>;
export const SyndicateWires_NonSyndicateTerminationWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateTerminationWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateTerminationWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateTerminationWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateTerminationWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateTerminationWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWirePreviewQuery,
    SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateTerminationWirePreviewQuery,
    SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWirePreviewDocument, options);
}
export function useSyndicateWires_NonSyndicateTerminationWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWirePreviewQuery,
    SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateTerminationWirePreviewQuery,
    SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWirePreviewDocument, options);
}
export type SyndicateWires_NonSyndicateTerminationWirePreviewQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWirePreviewQuery
>;
export type SyndicateWires_NonSyndicateTerminationWirePreviewLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWirePreviewLazyQuery
>;
export type SyndicateWires_NonSyndicateTerminationWirePreviewQueryResult = Apollo.QueryResult<
  SyndicateWires_NonSyndicateTerminationWirePreviewQuery,
  SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables
>;
export const SyndicateWires_NonSyndicateTerminationWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateTerminationWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateTerminationWireValidationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateTerminationWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateTerminationWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateTerminationWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateTerminationWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWireValidationQuery,
    SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateTerminationWireValidationQuery,
    SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWireValidationDocument, options);
}
export function useSyndicateWires_NonSyndicateTerminationWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWireValidationQuery,
    SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateTerminationWireValidationQuery,
    SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWireValidationDocument, options);
}
export type SyndicateWires_NonSyndicateTerminationWireValidationQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWireValidationQuery
>;
export type SyndicateWires_NonSyndicateTerminationWireValidationLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWireValidationLazyQuery
>;
export type SyndicateWires_NonSyndicateTerminationWireValidationQueryResult = Apollo.QueryResult<
  SyndicateWires_NonSyndicateTerminationWireValidationQuery,
  SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables
>;
export const SyndicateWires_NonSyndicateTerminationWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateTerminationWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateTerminationWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateTerminationWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateTerminationWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateTerminationWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateTerminationWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWiresQuery,
    SyndicateWires_NonSyndicateTerminationWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateTerminationWiresQuery,
    SyndicateWires_NonSyndicateTerminationWiresQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWiresDocument, options);
}
export function useSyndicateWires_NonSyndicateTerminationWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateTerminationWiresQuery,
    SyndicateWires_NonSyndicateTerminationWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateTerminationWiresQuery,
    SyndicateWires_NonSyndicateTerminationWiresQueryVariables
  >(SyndicateWires_NonSyndicateTerminationWiresDocument, options);
}
export type SyndicateWires_NonSyndicateTerminationWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWiresQuery
>;
export type SyndicateWires_NonSyndicateTerminationWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateTerminationWiresLazyQuery
>;
export type SyndicateWires_NonSyndicateTerminationWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_NonSyndicateTerminationWiresQuery,
  SyndicateWires_NonSyndicateTerminationWiresQueryVariables
>;
export const SyndicateWires_SendNonSyndicateTerminationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendNonSyndicateTerminationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendNonSyndicateTerminationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendNonSyndicateTerminationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_SendNonSyndicateTerminationWireMutation,
  SyndicateWires_SendNonSyndicateTerminationWireMutationVariables
>;

/**
 * __useSyndicateWires_SendNonSyndicateTerminationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendNonSyndicateTerminationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendNonSyndicateTerminationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendNonSyndicateTerminationWireMutation, { data, loading, error }] = useSyndicateWires_SendNonSyndicateTerminationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendNonSyndicateTerminationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendNonSyndicateTerminationWireMutation,
    SyndicateWires_SendNonSyndicateTerminationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendNonSyndicateTerminationWireMutation,
    SyndicateWires_SendNonSyndicateTerminationWireMutationVariables
  >(SyndicateWires_SendNonSyndicateTerminationWireDocument, options);
}
export type SyndicateWires_SendNonSyndicateTerminationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_SendNonSyndicateTerminationWireMutation
>;
export type SyndicateWires_SendNonSyndicateTerminationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendNonSyndicateTerminationWireMutation>;
export type SyndicateWires_SendNonSyndicateTerminationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_SendNonSyndicateTerminationWireMutation,
    SyndicateWires_SendNonSyndicateTerminationWireMutationVariables
  >;
export const SyndicateWires_UpdateNonSyndicateTerminationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdateNonSyndicateTerminationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNonSyndicateTerminationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNonSyndicateTerminationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateTerminationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateTerminationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminationDate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date' },
                  name: { kind: 'Name', value: 'dateTime' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'timezone' },
                  name: { kind: 'Name', value: 'timeZone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateNonSyndicateTerminationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_UpdateNonSyndicateTerminationWireMutation,
  SyndicateWires_UpdateNonSyndicateTerminationWireMutationVariables
>;

/**
 * __useSyndicateWires_UpdateNonSyndicateTerminationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateNonSyndicateTerminationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateNonSyndicateTerminationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateNonSyndicateTerminationWireMutation, { data, loading, error }] = useSyndicateWires_UpdateNonSyndicateTerminationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateNonSyndicateTerminationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateNonSyndicateTerminationWireMutation,
    SyndicateWires_UpdateNonSyndicateTerminationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateNonSyndicateTerminationWireMutation,
    SyndicateWires_UpdateNonSyndicateTerminationWireMutationVariables
  >(SyndicateWires_UpdateNonSyndicateTerminationWireDocument, options);
}
export type SyndicateWires_UpdateNonSyndicateTerminationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_UpdateNonSyndicateTerminationWireMutation
>;
export type SyndicateWires_UpdateNonSyndicateTerminationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateNonSyndicateTerminationWireMutation>;
export type SyndicateWires_UpdateNonSyndicateTerminationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_UpdateNonSyndicateTerminationWireMutation,
    SyndicateWires_UpdateNonSyndicateTerminationWireMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_NonSyndicateTerminationWireDetailsMockResponse = makeMockResponse<
  SyndicateWires_NonSyndicateTerminationWireDetailsQueryVariables,
  SyndicateWires_NonSyndicateTerminationWireDetailsQuery
>(SyndicateWires_NonSyndicateTerminationWireDetailsDocument);

export const makeSyndicateWires_NonSyndicateTerminationWirePreviewMockResponse = makeMockResponse<
  SyndicateWires_NonSyndicateTerminationWirePreviewQueryVariables,
  SyndicateWires_NonSyndicateTerminationWirePreviewQuery
>(SyndicateWires_NonSyndicateTerminationWirePreviewDocument);

export const makeSyndicateWires_NonSyndicateTerminationWireValidationMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateTerminationWireValidationQueryVariables,
    SyndicateWires_NonSyndicateTerminationWireValidationQuery
  >(SyndicateWires_NonSyndicateTerminationWireValidationDocument);

export const makeSyndicateWires_NonSyndicateTerminationWiresMockResponse = makeMockResponse<
  SyndicateWires_NonSyndicateTerminationWiresQueryVariables,
  SyndicateWires_NonSyndicateTerminationWiresQuery
>(SyndicateWires_NonSyndicateTerminationWiresDocument);
