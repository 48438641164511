import React from 'react';

import { OnExportResult } from './ExportButton';

export type ExportContextValue = {
  isExporting: boolean;
  onExport: () => Promise<OnExportResult>;
};

const ExportContext = React.createContext<ExportContextValue | undefined>(undefined);

export const useExportContext = () => {
  const context = React.useContext(ExportContext);
  if (context === undefined) {
    throw new Error('useExportContext must be used within a ExportContextProvider');
  }

  return context;
};

export const ExportContextProvider: React.FC<ExportContextValue> = ({
  isExporting,
  onExport,
  children,
}) => {
  return (
    <ExportContext.Provider value={{ isExporting, onExport }}>{children}</ExportContext.Provider>
  );
};
