import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_UpdateFilingDocument,
  OfferingSetup_UpdateFilingMutation,
  OfferingSetup_UpdateFilingMutationVariables,
} from '../graphql';

export const refetchQueries = () => {
  const offeringHeaderQuery = getOperationName(Offering_OfferingHeaderDocument);
  return [offeringHeaderQuery!];
};

export const useUpdateFilingMutation = () =>
  useServiceMutation<
    OfferingSetup_UpdateFilingMutation,
    OfferingSetup_UpdateFilingMutationVariables
  >(OfferingSetup_UpdateFilingDocument, {
    refetchQueries,
  });
