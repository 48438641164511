import { ProspectusDeliveryStatus } from '../../../../../../graphql';

export const getProspectusStatusColor = ({
  overallStatus,
  sentEmailCount,
}: {
  overallStatus: ProspectusDeliveryStatus;
  sentEmailCount: number;
}) => {
  switch (overallStatus) {
    case ProspectusDeliveryStatus.Sent:
      return 'success';
    case ProspectusDeliveryStatus.Failed:
      return 'error';
    case ProspectusDeliveryStatus.InProgress:
      return sentEmailCount > 0 ? 'success' : 'disabled';
    default:
      return 'disabled';
  }
};
