import { Option } from '@cmg/common';
import * as yup from 'yup';

import { RegulationMNotificationPeriod } from '../../../../../graphql';
import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const notificationPeriodOptions: Option<RegulationMNotificationPeriod>[] = [
  { label: '1 Day', value: RegulationMNotificationPeriod.OneDay },
  { label: '5 Days', value: RegulationMNotificationPeriod.FiveDays },
  {
    label: 'Actively Traded Security',
    value: RegulationMNotificationPeriod.ActivelyTradedSecurity,
  },
];

export const validationSchema = yup
  .object({
    notificationPeriod: yup
      .mixed<RegulationMNotificationPeriod>()
      .nullable()
      .required('Notification period is required.'),
    offeringResult: yup.string().required('Offering result is required'),
  })
  .concat(wiresFormDisclaimerSchema);

export type Values = {
  notificationPeriod: RegulationMNotificationPeriod;
  offeringResult: string;
  disclaimer: string | null;
};
