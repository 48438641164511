import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const recipientUserDataValidationSchema = yup
  .object()
  .shape({
    recipient: yup.string().label('Recipient').required(),
  })
  .nullable();

export const validationSchema = yup
  .object()
  .shape({
    recipientsUserData: yup
      .array()
      .of(recipientUserDataValidationSchema)
      .label('Recipients')
      .min(1)
      .required()
      .test(
        'non-nullable-data',
        'At least one manager must have user data',
        (value: Values['recipientsUserData'] | undefined) => {
          return !!value?.some(data => data !== null);
        }
      ),
  })
  .concat(wiresFormDisclaimerSchema);

export type GreenShoeRecipientUserData = {
  recipient: string;
};

export type Values = {
  disclaimer: string | null;
  recipientsUserData: (GreenShoeRecipientUserData | null)[];
};
