import { UUID } from '@cmg/common';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SnackbarManager,
  Stack,
  TableSkeleton,
  Typography,
  useTheme,
} from '@cmg/design-system';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import emptyState from '../../../../../../../../../common/assets/empty-state-image.svg';
import { ManagerShareInfo } from '../../../../types';
import { useOrderBook_GetAllocationSetShareInformationQuery } from './graphql';
import ShareAllocationSetTable from './share-allocation-set-table/ShareAllocationSetTable';
import {
  getFirms,
  getInitialValues,
  ShareAllocationSetValues,
} from './ShareAllocationSetDialog.model';

export type Props = Readonly<{
  offeringId: UUID;
  authorCmgEntityKey: UUID;
  sharedWithManagers: readonly ManagerShareInfo[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (values: ShareAllocationSetValues) => Promise<void>;
  title: string;
}>;

const ShareAllocationSetDialog: React.FC<Props> = ({
  offeringId,
  authorCmgEntityKey,
  sharedWithManagers,
  isOpen,
  onClose,
  onSave,
  title,
}) => {
  const theme = useTheme();

  const { data, loading, error } = useOrderBook_GetAllocationSetShareInformationQuery({
    variables: { offeringId },
  });
  const firms = React.useMemo(
    () => getFirms(authorCmgEntityKey, data, sharedWithManagers),
    [authorCmgEntityKey, data, sharedWithManagers]
  );

  const formik = useFormik<ShareAllocationSetValues>({
    initialValues: getInitialValues(firms),
    onSubmit: async formValues => {
      try {
        await onSave(formValues);
        SnackbarManager.success('Successfully shared Allocation Set');
        onClose();
      } catch {
        SnackbarManager.error('Failed to share Allocation Set');
      }
    },
    enableReinitialize: true,
  });
  const { resetForm, submitForm, isSubmitting, initialValues } = formik;

  const isEmptyView = React.useMemo(
    () => Object.entries(initialValues).length === 0,
    [initialValues]
  );

  const handleCancel = React.useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle title={title}>Share Allocation Set</DialogTitle>

      <DialogContent dividers>
        {error && <Alert severity="error">Failed to retrieve offering information</Alert>}

        {loading && <TableSkeleton />}
        {!loading && !error && isEmptyView && (
          <Stack gap={1} p={1} alignItems="center">
            <img src={emptyState} alt=" No Underwriters to Share With" width={theme.spacing(10)} />
            <Typography variant="h3">No Underwriters to Share With</Typography>
          </Stack>
        )}
        {!loading && !error && !isEmptyView && (
          <Stack gap={1}>
            <Box>Please select the manager(s) you'd like to share this draft with:</Box>

            <FormikProvider value={formik}>
              <ShareAllocationSetTable firms={firms} />
            </FormikProvider>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={submitForm} loading={isSubmitting}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShareAllocationSetDialog;
