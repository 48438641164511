import { RegularBreakpoints } from '@cmg/design-system';
import isEqual from 'lodash/isEqual';

import { RecipientUserData } from '../../../../SyndicateWiresRoute.model';
import { SyndicateWires_CustomVariablesQuery } from '../../../graphql';

export enum OperationType {
  CREATE = 'create',
  UPDATE = 'update',
}

export const operationTypeToText: { [type in OperationType]: string } = {
  [OperationType.CREATE]: 'Create',
  [OperationType.UPDATE]: 'Edit',
};

/**
 * Returns true if initial data for given recipient does not exist and all next values, except recipient field, are falsy.
 */
export const canClearRecipientsData = <TValues extends RecipientUserData>(args: {
  initialUserData: (RecipientUserData | null)[];
  cmgEntityKey: string;
  nextValues: TValues;
  operationType: OperationType;
  defaultValues: Omit<TValues, 'recipient'>;
}) => {
  const { recipient, ...rest } = args.nextValues || {};
  const isEqualToDefaultValues = isEqual(rest, args.defaultValues);

  if (args.operationType === OperationType.CREATE) {
    return isEqualToDefaultValues;
  }

  // For update scenario check initial value of given manager
  const editingExistingManager = args.initialUserData.some(
    value => value?.recipient === args.cmgEntityKey
  );

  return !editingExistingManager && isEqualToDefaultValues;
};

export function getMaxWidth({
  areCustomVariablesVisible,
  createOrUpdateModalSize,
}: {
  areCustomVariablesVisible: boolean;
  createOrUpdateModalSize: keyof RegularBreakpoints;
}) {
  if (areCustomVariablesVisible) {
    if (createOrUpdateModalSize === 'sm') {
      return 'md';
    }
    if (createOrUpdateModalSize === 'md') {
      return 'lg';
    }
  }
  return createOrUpdateModalSize;
}

export function getVariables(
  customVariables: SyndicateWires_CustomVariablesQuery['syndicateWires']['customVariables']
) {
  const variables = customVariables.flatMap(group =>
    group.variables.map(variable => ({
      name: `{{${variable.name}}}`,
      value: variable.value,
    }))
  );
  return {
    emptyVariables: variables.filter(item => item.value === null).map(item => item.name),
    variables: variables.map(item => item.name),
  };
}

export function findMatchedVariables({
  disclaimer,
  emptyVariables,
}: {
  disclaimer?: string;
  emptyVariables: string[];
}) {
  if (!disclaimer || emptyVariables.length === 0) {
    return [];
  }
  return [...new Set(disclaimer.match(new RegExp(emptyVariables.join('|'), 'g')))];
}

const PLACEHOLDER_PATTERN = new RegExp('{{(.)*}}|{|}', 'g');

export function findUnknownVariables({
  disclaimer,
  variables,
}: {
  disclaimer?: string;
  variables: string[];
}): {
  isValid: boolean;
  unknownVariables: string[];
} {
  if (!disclaimer || variables.length === 0) {
    return {
      isValid: true,
      unknownVariables: [],
    };
  }
  // remove all known variables from disclaimer
  const disclaimerWithoutVariables = disclaimer.replaceAll(
    new RegExp(variables.join('|'), 'g'),
    ''
  );
  // check for all remaining use of placeholders
  const matchedVariables = [...new Set(disclaimerWithoutVariables.match(PLACEHOLDER_PATTERN))];
  return {
    isValid: matchedVariables.length === 0,
    unknownVariables: matchedVariables.filter(item => item !== '{' && item !== '}'),
  };
}
