import { UUID } from '@cmg/common';
import React from 'react';

import { ManagerErrorType } from '../../../../../graphql';
import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';

export function useManagersMissingEmail({ offeringId }: { offeringId: UUID }): string[] {
  const validation = useWireTypeConfigContext().useWireValidation({
    offeringId,
    fetchPolicy: 'cache-first',
  });

  return React.useMemo(() => {
    if (!validation.data) {
      return [];
    }

    return validation.data.managers.reduce<string[]>((acc, managerValidation) => {
      if (!managerValidation.fields.includes(ManagerErrorType.DistributionList)) {
        return acc;
      }

      return [...acc, managerValidation.cmgEntityKey];
    }, []);
  }, [validation.data]);
}
