import React from 'react';

import { SGroup, STitle, StyledIcon } from './ExpandableGroup.styles';

export type Props = {
  title: string;
  subTitle: string | null;
  isExpanded: boolean;
  onChange: (isExpanded: boolean) => void;
};

export const ExpandableGroup: React.FC<React.PropsWithChildren<Props>> = ({
  isExpanded,
  title,
  subTitle,
  onChange,
  children,
}) => {
  return (
    <div>
      <SGroup onClick={() => onChange(!isExpanded)}>
        <StyledIcon name={isExpanded ? 'caret-up' : 'caret-down'} variant="solid" fixedWidth />
        <STitle>{title}</STitle>
        {subTitle && <span>{subTitle}</span>}
      </SGroup>
      {isExpanded && children}
    </div>
  );
};
