import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@cmg/design-system';
import { VFC } from 'react';

import { DialogBodySection } from './DialogBodySection';
import {
  getAlertTitle,
  ProspectusOnSendValidationAlertModel,
} from './ProspectusOnSendValidationAlert.model';

export type ProspectusOnSendValidationAlertDialogProps = Readonly<{
  readonly alertModel: ProspectusOnSendValidationAlertModel;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}>;

export const ProspectusOnSendValidationAlertDialog: VFC<
  ProspectusOnSendValidationAlertDialogProps
> = ({ alertModel, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{getAlertTitle(alertModel.investorsWithError)}</DialogTitle>
      <DialogContent dividers>
        <Stack rowGap={2} pb={2.5}>
          <Typography>
            An indication must have contact information present and be acknowledged in order to send
            Prospectus.
          </Typography>
          <DialogBodySection
            title="Contact information missing"
            investors={alertModel.investorsWithoutContactInfo}
          />
          <DialogBodySection
            title="Indication not acknowledged"
            investors={alertModel.investorsWithoutAcknowledgedIndication}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
