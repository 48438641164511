import { CurrencyInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SIndicationLevelRow = styled.div`
  padding: 10px 0;
  display: flex;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SColumn = styled.div<{ align: 'left' | 'center' | 'right' }>`
  padding-right: 5px;
  text-align: ${props => props.align};
  align-self: center;
  flex: 4;
`;

export const SLimitConfigurationColumn = styled(SColumn)`
  flex: 5;
`;

export const SDeleteColumn = styled(SColumn)`
  flex-grow: 1;
`;

export const SCurrencyInputField = styled(CurrencyInputField)`
  min-width: unset;
`;
