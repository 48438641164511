import { ApolloError } from '@apollo/client';
import { useAuth } from '@cmg/auth';
import { Option, SelectField } from '@cmg/common';
import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { ManagerResponsibilitiesInput } from '../../../../graphql';
import SetupForm from '../../components/form/OfferingSetupForm';
import ManagersUnsavedChangesGuard from './components/ManagersUnsavedChangesGuard';
import { OfferingSetup_ManagerResponsibilitiesPartsFragment } from './graphql';
import { StyledPrimaryButton } from './ManagerResponsibilitiesForm.styles';

export type FormType = ManagerResponsibilitiesInput;

export type Props = {
  isAuthor: boolean;
  managerOptions: Option[];
  responsibilities?: OfferingSetup_ManagerResponsibilitiesPartsFragment;
  loading?: boolean;
  error?: ApolloError;
  onSubmit: (payload: FormType) => void;
};

export const ManagerResponsibilitiesFormComponent: React.FC<Props> = ({
  isAuthor,
  managerOptions,
  loading,
  error,
  responsibilities,
  onSubmit,
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      leftLead: responsibilities?.leftLead,
      settlementAgent: responsibilities?.settlementAgent,
      stabilizationAgent: responsibilities?.stabilizationAgent,
      logisticsAgent: responsibilities?.logisticsAgent,
    },
    onSubmit: values => onSubmit(values),
  };

  const formik = useFormik(formikOptions);
  const { oidcUserCmgEntityKey } = useAuth();
  const settlementAgentOptions = useMemo(
    () =>
      isAuthor
        ? managerOptions.filter(option => option.value === oidcUserCmgEntityKey)
        : managerOptions,
    [isAuthor, managerOptions, oidcUserCmgEntityKey]
  );

  const { dirty, handleSubmit, resetForm, values } = formik;
  return (
    <FormikProvider value={formik}>
      <ManagersUnsavedChangesGuard when={dirty} onLeave={resetForm}>
        <Form>
          {error && <ServerErrorsBanner error={error} />}
          <SetupForm.Row>
            <SetupForm.Column size={1}>
              <SelectField
                name="leftLead"
                label="Left Lead"
                options={managerOptions}
                disabled={loading}
                fullWidth
                withMargin
              />
            </SetupForm.Column>
            <SetupForm.Column size={1}>
              <SelectField
                name="settlementAgent"
                label="Settlement Agent"
                options={settlementAgentOptions}
                disabled={loading || !!values.settlementAgent}
                fullWidth
                withMargin
              />
            </SetupForm.Column>
            <SetupForm.Column size={1}>
              <SelectField
                name="stabilizationAgent"
                label="Stabilization Agent"
                options={managerOptions}
                disabled={loading}
                fullWidth
                withMargin
              />
            </SetupForm.Column>
            <SetupForm.Column size={1}>
              <SelectField
                name="logisticsAgent"
                label="Logistics Agent"
                options={managerOptions}
                disabled={loading}
                fullWidth
                withMargin
              />
            </SetupForm.Column>
            <StyledPrimaryButton disabled={!dirty} loading={loading} onClick={() => handleSubmit()}>
              Save
            </StyledPrimaryButton>
          </SetupForm.Row>
        </Form>
      </ManagersUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default ManagerResponsibilitiesFormComponent;
