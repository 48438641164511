import * as yup from 'yup';

import { MAX_32_BIT_INT } from '../../../../../../../types/graphql/constants';

export type DemandValues = {
  demandShares: number | null;
  roundLots: number | null;
};

export type RetentionValues = {
  retentionShares: number | null;
};

export const demandValidationSchema = yup.object({
  demandShares: yup
    .number()
    .integer()
    .label('Demand')
    .min(0)
    .max(MAX_32_BIT_INT)
    .nullable()
    .required(),
  roundLots: yup.number().integer().label('Round Lots').min(0).max(MAX_32_BIT_INT).nullable(),
});

export const retentionValidationSchema = yup.object({
  retentionShares: yup
    .number()
    .integer()
    .label('Retention')
    .min(0)
    .max(MAX_32_BIT_INT)
    .nullable()
    .required(),
});
