import { SecondaryButton } from '@cmg/common';
import React from 'react';

import FlexLayout from '../../../../../common/components/layout/flex-layout/FlexLayout';
import SideBarHeader from '../../../components/side-bar-header/SideBarHeader';
import { ComplianceWarning } from '../../compliance/context/hooks/certificates/useCertificatesModel';
import { SSectionHeader } from '../components/indication-section-header/IndicationSectionHeader.styles';
import ComplianceActionRequiredBanner from './ComplianceActionRequiredBanner';
import { SIndicationComplianceButtons } from './IndicationComplianceWarningWrapper.styles';

export type Props = Readonly<{
  complianceWarning: ComplianceWarning;
  indicationExists: boolean;
  loading: boolean;
}>;

const IndicationComplianceWarningWrapper: React.FC<Props> = ({
  children,
  complianceWarning,
  indicationExists,
  loading,
}) => {
  const { hasComplianceWarning, canByPassComplianceWarning, message, redirectUrl } =
    complianceWarning;
  const [skipComplianceWarning, setSkipComplianceWarning] = React.useState<boolean>(false);

  const shouldDisplayComplianceWarning =
    hasComplianceWarning && !indicationExists && !skipComplianceWarning;

  return (
    <FlexLayout>
      {shouldDisplayComplianceWarning && (
        <React.Fragment>
          <SideBarHeader
            title="Indication"
            padding="10px 16px 10px 16px"
            data-test-id="compliance-warning-wrapper"
          >
            {canByPassComplianceWarning && (
              <SSectionHeader>
                <SIndicationComplianceButtons>
                  <SecondaryButton
                    data-test-id="skip-compliance-warning-button"
                    onClick={() => setSkipComplianceWarning(true)}
                  >
                    Continue to Submit Indication
                  </SecondaryButton>
                </SIndicationComplianceButtons>
              </SSectionHeader>
            )}
          </SideBarHeader>
          <ComplianceActionRequiredBanner
            url={redirectUrl}
            message={message}
            padding="16px 8px 0px 8px"
          />
        </React.Fragment>
      )}
      {(indicationExists || !shouldDisplayComplianceWarning) && !loading && children}
    </FlexLayout>
  );
};

export default IndicationComplianceWarningWrapper;
