import styled from 'styled-components/macro';

export const SNoticesAndDisclaimersContainer = styled.div`
  margin-top: 20px;
`;

const SContent = styled.div`
  padding: 20px;
`;

export const SNoticesAndDisclaimers = styled(SContent)`
  font-size: ${({ theme }) => theme.text.size.small};
  color: ${({ theme }) => theme.text.color.light};
`;
