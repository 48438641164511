/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TradeRelease_IndicationTradePartsFragment = {
  readonly __typename?: 'IndicationTrade';
  readonly id: string;
  readonly tradeId: string;
  readonly createdAt: string;
  readonly createdByUserName: string;
  readonly statusDetails?: string | null;
  readonly tradeStatus: Types.ReleaseStatus;
};

export type TradeRelease_IndicationTradesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type TradeRelease_IndicationTradesQuery = {
  readonly __typename?: 'Query';
  readonly indicationTrades: ReadonlyArray<{
    readonly __typename?: 'IndicationTrade';
    readonly id: string;
    readonly tradeId: string;
    readonly createdAt: string;
    readonly createdByUserName: string;
    readonly statusDetails?: string | null;
    readonly tradeStatus: Types.ReleaseStatus;
  }>;
};

export const TradeRelease_IndicationTradePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_IndicationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const TradeRelease_IndicationTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TradeRelease_IndicationTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indicationTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TradeRelease_IndicationTradeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_IndicationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTradeRelease_IndicationTradesQuery__
 *
 * To run a query within a React component, call `useTradeRelease_IndicationTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_IndicationTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeRelease_IndicationTradesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useTradeRelease_IndicationTradesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeRelease_IndicationTradesQuery,
    TradeRelease_IndicationTradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TradeRelease_IndicationTradesQuery,
    TradeRelease_IndicationTradesQueryVariables
  >(TradeRelease_IndicationTradesDocument, options);
}
export function useTradeRelease_IndicationTradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeRelease_IndicationTradesQuery,
    TradeRelease_IndicationTradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeRelease_IndicationTradesQuery,
    TradeRelease_IndicationTradesQueryVariables
  >(TradeRelease_IndicationTradesDocument, options);
}
export type TradeRelease_IndicationTradesQueryHookResult = ReturnType<
  typeof useTradeRelease_IndicationTradesQuery
>;
export type TradeRelease_IndicationTradesLazyQueryHookResult = ReturnType<
  typeof useTradeRelease_IndicationTradesLazyQuery
>;
export type TradeRelease_IndicationTradesQueryResult = Apollo.QueryResult<
  TradeRelease_IndicationTradesQuery,
  TradeRelease_IndicationTradesQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeTradeRelease_IndicationTradesMockResponse = makeMockResponse<
  TradeRelease_IndicationTradesQueryVariables,
  TradeRelease_IndicationTradesQuery
>(TradeRelease_IndicationTradesDocument);
