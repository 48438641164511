import { UUID } from '@cmg/common';

import {
  useCheckNonSyndicateCommunicationAccess,
  useCheckSyndicateCommunicationAccess,
} from './useCheckSyndicateCommunicationAccess';

type AccessCheckResult = {
  canManage: boolean;
  canRead: boolean;
  loading: boolean;
};

/**
 * Checks accessibility for Syndicate Wires
 */
export const useCheckSyndicateWiresAccess = (offeringId: UUID | undefined): AccessCheckResult => {
  const syndicateCommunicationAccess = useCheckSyndicateCommunicationAccess(offeringId);
  const nonSyndicateCommunicationAccess = useCheckNonSyndicateCommunicationAccess(offeringId);

  return {
    canRead: syndicateCommunicationAccess.canRead || nonSyndicateCommunicationAccess.canRead,
    canManage: syndicateCommunicationAccess.canManage || nonSyndicateCommunicationAccess.canManage,
    loading: syndicateCommunicationAccess.loading || nonSyndicateCommunicationAccess.loading,
  };
};
