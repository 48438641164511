import React from 'react';

import { OfferingType } from '../../../../../graphql';
import { WireTypeRoute } from '../../common/components/wire-type-route/WireTypeRoute';
import { WireRouteProps } from '../../common/components/wire-type-route/WireTypeRoute.model';
import { WireTypeConfigContextProvider } from '../../common/context/WireTypeConfigContext';
import { createWireTypeConfigSellingGroupInvitation } from './SellingGroupInvitationWire.model';

export const SellingGroupInvitationWireRoute: React.FC<
  WireRouteProps & { offeringType: OfferingType }
> = props => {
  return (
    <WireTypeConfigContextProvider
      value={createWireTypeConfigSellingGroupInvitation({ offeringType: props.offeringType })}
    >
      <WireTypeRoute {...props} />
    </WireTypeConfigContextProvider>
  );
};
