import { ColDef } from 'ag-grid-community';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../common/config/appSettings';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../../graphql';
import { ExtendedTypes } from '../../types';
import DynamicColumnRenderer, { getExtendedData } from '../dynamic-columns/DynamicColumnRenderer';
import { defaultColumnProps, defaultColumnWidth, MenuType } from './Columns.model';

export const getExtendedDataColumnDefinition = ({
  type,
  key,
  name,
}: {
  type: string;
  key: string;
  name: string | null;
}): ColDef => {
  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();
  const lockPinned = !isOrderBookPinnedColumnsOn;

  return {
    ...defaultColumnProps,
    ...defaultColumnWidth,
    colId: `DYNAMIC.${type}.${key}`,
    headerName: name ?? key,
    field: `investorExtendedData.${type}.${key}`,
    refData: {
      menuDisplayType: MenuType.SELECTABLE,
      dynamicType: type,
    },
    cellRendererFramework: DynamicColumnRenderer,
    valueGetter: ({ data, colDef }) => {
      const extendedData = getExtendedData(data, colDef);
      return extendedData?.value ?? '';
    },
    lockPinned,
  };
};

/**
 * Generates column configurations for each dynamic column.
 */
export const useGenerageInvestorExtendedDataColumns = (
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData']
): ColDef[] => {
  return useMemo(() => {
    const extendedTypes = Object.values(ExtendedTypes);
    const uniqueItems = uniqBy(
      investorsExtendedData.flatMap(data =>
        extendedTypes.flatMap(type =>
          (data[type] ?? []).map(element => {
            return { type, key: element.key, name: element.name };
          })
        )
      ),
      element => {
        return [element.type, element.key, element.name].join();
      }
    );
    return uniqueItems.map(u =>
      getExtendedDataColumnDefinition({
        key: u.key,
        name: u.name,
        type: u.type,
      })
    );
  }, [investorsExtendedData]);
};
