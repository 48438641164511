import { useAccessToken } from '@cmg/auth';
import { LinkButton, Popover } from '@cmg/common';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openSupportRequestModal } from '../../../../../../../common/components/modals/support-request-modal/SupportRequestModal';
import { SCmgSupportMessage, StyledLinkCmgSupport } from './CrmSelectModalHeader.styles';

export const CrmSelectModalHeader: React.FC = () => {
  const token = useAccessToken();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const handleOpenSupportModal = () => {
    token && dispatch(openSupportRequestModal({ token }));
    setIsVisible(false);
  };

  return (
    <div>
      Please select the correct CRM Bank Investor Key you would like to use for this indication.
      <LinkButton>
        <Popover
          variant="DARK"
          visible={isVisible}
          transparentBackground
          onVisibleChange={value => setIsVisible(value)}
          content={() => (
            <React.Fragment>
              Please contact{' '}
              <StyledLinkCmgSupport
                data-test-id="cmg-support-link"
                onClick={handleOpenSupportModal}
              >
                CMG Support
              </StyledLinkCmgSupport>{' '}
              if the bank investor key
              <div> you need is missing.</div>
              <SCmgSupportMessage>
                The bank investor key could be missing because it is not mapped to the right
                <div>investor or needs to be added to your CRM data.</div>
              </SCmgSupportMessage>
            </React.Fragment>
          )}
        >
          Don't see the right investor key?
        </Popover>
      </LinkButton>
    </div>
  );
};
