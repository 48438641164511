import {
  SyndicateWires_UpdateSellingGroupTermsWireMutation,
  useSyndicateWires_UpdateSellingGroupTermsWireMutation,
} from '../graphql';

export type UpdateSellingGroupTermsWireMutation =
  SyndicateWires_UpdateSellingGroupTermsWireMutation;

export const useUpdateSellingGroupTermsWireMutation = () => {
  return useSyndicateWires_UpdateSellingGroupTermsWireMutation();
};
