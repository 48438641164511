import React from 'react';

import GridLayout from '../../../../../../../../common/components/layout/grid-layout/GridLayout';
import { Roadshows_LogisticsPartsFragment } from '../../../../../../common/graphql';
import EventFormSubHeader from '../../../../panel-meetings-event/common/event-form-subheader/EventFormSubHeader';
import { formatDate } from './LogisticsDetails.model';
import { SDetailsWrapper, StyledTitleColumn, StyledValueColumn } from './LogisticsDetails.styles';

export type Props = { logistics: Roadshows_LogisticsPartsFragment };

const LogisticsDetails: React.FC<Props> = ({ logistics }) => {
  return (
    <div>
      <EventFormSubHeader title="Logistics Details">
        <SDetailsWrapper>
          <GridLayout>
            <StyledTitleColumn span={4}>Logistics Title:</StyledTitleColumn>
            <StyledValueColumn span={8}>{logistics.title}</StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>Start Date:</StyledTitleColumn>
            <StyledValueColumn span={8}>
              {formatDate(logistics.startDate, logistics.startTime)}
            </StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>End Date:</StyledTitleColumn>
            <StyledValueColumn span={8}>
              {formatDate(logistics.endDate, logistics.endTime)}
            </StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>Location:</StyledTitleColumn>
            <StyledValueColumn span={8}>{logistics.location}</StyledValueColumn>
          </GridLayout>

          <GridLayout>
            <StyledTitleColumn span={4}>Notes:</StyledTitleColumn>
            <StyledValueColumn span={8}>{logistics.notes}</StyledValueColumn>
          </GridLayout>
        </SDetailsWrapper>
      </EventFormSubHeader>
    </div>
  );
};

export default LogisticsDetails;
