import { ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledRow = styled(Table.Row)<{ hasError?: boolean }>`
  & > div {
    background-color: ${({ theme, hasError }) => {
      if (hasError) {
        return theme.designSystem.colors.red['000'];
      }
      return 'unset';
    }};
  }
`;
