import { FlagComponent } from '@cmg/common';
import styled from 'styled-components/macro';

export const SFlagIcon = styled(FlagComponent)`
  margin-right: 8px;
  min-width: 20px;
`;

export const SCountryContainer = styled.div`
  display: flex;
  align-items: center;
`;
