import * as Yup from 'yup';

import { MAX_32_BIT_INT } from '../../../../../types/graphql/constants';

const MAX_DEMAND_COlS = 20;
const INCREMENT_MIN = 0.01;
const INCREMENT_MAX = 1_000;

export const validationSchema = Yup.object().shape({
  min: Yup.number()
    .label('Min')
    .positive()
    .required()
    .test(
      'test-demandMinIsLowerThanDemandMax',
      'Min must be smaller or equal to Max',
      (value, context) => {
        return value <= context.parent.max;
      }
    ),
  max: Yup.number()
    .label('Max')
    .positive()
    .required()
    .test(
      'test-demandMaxIsLowerThanDemandMin',
      'Max must be higher or equal to Min',
      (value, context) => {
        return value >= context.parent.min;
      }
    )
    .test(
      'test-demandMaxDoesNotExceedMaxBigInt32',
      `Max must be less than ${MAX_32_BIT_INT}`,
      value => {
        return value <= MAX_32_BIT_INT;
      }
    ),
  increment: Yup.number()
    .label('Increment')
    .min(INCREMENT_MIN)
    .max(INCREMENT_MAX)
    .required()
    .test(
      'test-demandIncrementDoesNotExceedColumnLimit',
      `The maximum number of demand level columns is ${MAX_DEMAND_COlS}`,
      (value, context) => {
        const { min, max } = context.parent;
        const diff = max - min;
        return diff / value <= MAX_DEMAND_COlS;
      }
    ),
  referencePrice: Yup.number().label('Reference Price').positive().nullable(),
});
