import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { SidePanelTitle } from '../../components/side-bar/components/design-system';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import IndicationComplianceWarningWrapper from './compliance/IndicationComplianceWarningWrapper';
import IndicationFormEmptyState from './components/indication-form-empty-state/IndicationFormEmptyState';
import {
  IndicationActivityBuySideContextProvider,
  useIndicationActivityBuySideContext,
} from './context/IndicationActivityBuySideContext';
import IndicationActivityBuySide from './IndicationActivityBuySide';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const IndicationActivityBuySideComponent: React.FC = () => {
  const { complianceWarning, basicPublishedOffering, loading } =
    useIndicationActivityBuySideContext();

  return (
    <IndicationComplianceWarningWrapper
      complianceWarning={complianceWarning}
      indicationExists={!!basicPublishedOffering?.myInstitutionalIndication}
      loading={loading}
    >
      <IndicationActivityBuySide />
    </IndicationComplianceWarningWrapper>
  );
};

const IndicationActivityBuySideRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;
  const { isFiledOffering } = useSupersededOfferingContext();

  return (
    <IndicationActivityBuySideContextProvider offeringId={offeringId}>
      <SidePanelTitle>Communicated Demand</SidePanelTitle>
      {!isFiledOffering ? <IndicationActivityBuySideComponent /> : <IndicationFormEmptyState />}
    </IndicationActivityBuySideContextProvider>
  );
};

export default IndicationActivityBuySideRoute;
