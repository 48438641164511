import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';

export const calculateOverallotmentShares = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const { overallotmentExercised, totalSharesRefreshed, totalSharesRepurchased } = accounting;

  return numericUtil.sum(
    overallotmentExercised,
    isNil(totalSharesRefreshed) ? null : -totalSharesRefreshed,
    totalSharesRepurchased
  );
};
