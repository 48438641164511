import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { WireTemplateType } from '../../../../../graphql';
import { createWireTypeConfig } from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { getDisclaimer } from '../../common/utils/disclaimer';
import {
  SyndicateWires_TerminationWirePartsFragment,
  SyndicateWires_TerminationWireValidationPartsFragment,
  useSyndicateWires_TerminationWireDetailsQuery,
  useSyndicateWires_TerminationWirePreviewQuery,
  useSyndicateWires_TerminationWiresQuery,
  useSyndicateWires_TerminationWireValidationQuery,
} from './graphql';
import { useCreateTerminationWireMutation } from './hooks/useCreateTerminationWireMutation';
import { useDeleteTerminationWireMutation } from './hooks/useDeleteTerminationWireMutation';
import { useSendTerminationWireMutation } from './hooks/useSendTerminationWireMutation';
import { useUpdateTerminationWireMutation } from './hooks/useUpdateTerminationWireMutation';
import { TerminationWireForm } from './TerminationWireForm';
import { validationSchema, Values } from './TerminationWireForm.model';

const wireTypeName = 'Termination';

export const wireTypeConfigTermination = createWireTypeConfig<
  Values,
  SyndicateWires_TerminationWirePartsFragment,
  SyndicateWires_TerminationWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresTermination
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresTermination,
  wireTemplateType: WireTemplateType.Termination,
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_TerminationWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.terminationWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_TerminationWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.terminationWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_TerminationWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.terminationWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_TerminationWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.terminationWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateTerminationWireMutation();

    return {
      mutation: async ({
        offeringId,
        values: {
          terminationDate: { date, timezone },
          disclaimer,
        },
      }) => {
        if (!date || !timezone) {
          throw new Error('Date and timezone are required fields.');
        }

        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              terminationDate: date,
              terminationTimeZone: timezone,
              disclaimer,
            },
          },
        });

        if (result.data?.createTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createTerminationWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateTerminationWireMutation();

    return {
      mutation: async ({
        offeringId,
        syndicateWireId,
        values: {
          terminationDate: { date, timezone },
          disclaimer,
        },
      }) => {
        if (!date || !timezone) {
          throw new Error('Date and timezone are required fields.');
        }

        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: { terminationDate: date, terminationTimeZone: timezone, disclaimer },
          },
        });

        if (result.data?.updateTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateTerminationWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteTerminationWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendTerminationWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendTerminationWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendTerminationWire;
      },
      isLoading: loading,
    };
  },

  CreateOrUpdateForm: TerminationWireForm,
  // @ts-expect-error date field type mismatch
  formValidation: validationSchema,
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const initialValues = React.useMemo<Values>(() => {
      return {
        terminationDate: {
          date: wire?.terminationDate.date ?? '',
          timezone: wire?.terminationDate.timezone ?? '',
        },
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [
      wire?.terminationDate.date,
      wire?.terminationDate.timezone,
      wire?.disclaimer,
      operationType,
      disclaimerData?.syndicateWires.wiresDisclaimer,
    ]);

    return { isLoading: disclaimerLoading, data: initialValues, error };
  },
});
