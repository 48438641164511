import styled from 'styled-components/macro';

import FlexLayout from '../../../layout/flex-layout/FlexLayout';

export const ModalContent = styled(FlexLayout)`
  height: 100%;
  padding: 0 50px 20px 50px;
`;

export const Divider = styled.hr`
  margin: 10px 0;
  border: ${({ theme }) => theme.border.smallSolidLight};
`;

export const Footer = styled.div`
  padding-top: 15px;
  text-align: center;
  border-top: ${({ theme }) => theme.border.width.small} solid
    ${({ theme }) => theme.border.color.dark};
  display: flex;
  justify-content: flex-end;
`;

export const ButtonGroup = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
