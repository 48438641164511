import { Theme } from '@cmg/common';

import { TradeReleaseStatus } from '../../../../../../graphql';

export const releaseStatusMap = new Map<TradeReleaseStatus, string>([
  [TradeReleaseStatus.Unreleased, 'Unreleased'],
  [TradeReleaseStatus.Released, 'Released'],
  [TradeReleaseStatus.PartiallyBooked, 'Partially Booked'],
  [TradeReleaseStatus.Booked, 'Booked'],
  [TradeReleaseStatus.BookedManual, 'Booked (Manual)'],
  [TradeReleaseStatus.CancelPending, 'Cancel Pending'],
  [TradeReleaseStatus.Cancelled, 'Cancelled'],
  [TradeReleaseStatus.CancelledManual, 'Cancelled (Manual)'],
  [TradeReleaseStatus.ReleaseFailed, 'Release Failed'],
]);

export function getReleaseStatusColorMap(theme: Theme): Record<TradeReleaseStatus, string> {
  return {
    [TradeReleaseStatus.Unreleased]: theme.designSystem.colors.gray[200],
    [TradeReleaseStatus.Released]: theme.designSystem.colors.turquoise[300],
    [TradeReleaseStatus.PartiallyBooked]: theme.designSystem.colors.turquoise[300],
    [TradeReleaseStatus.Booked]: theme.designSystem.colors.green[300],
    [TradeReleaseStatus.BookedManual]: theme.designSystem.colors.green[300],
    [TradeReleaseStatus.CancelPending]: theme.designSystem.colors.yellow[300],
    [TradeReleaseStatus.Cancelled]: theme.designSystem.colors.red[300],
    [TradeReleaseStatus.CancelledManual]: theme.designSystem.colors.red[300],
    [TradeReleaseStatus.ReleaseFailed]: theme.designSystem.colors.red[300],
  };
}
