import { LinkButton } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  StyledExternalLinkIcon,
  SValidationSectionTitle,
  SValidationSectionTitleRow,
} from './ValidationErrorSectionHeader.styles';

export type Props = {
  title: string;
  urlPath: string;
  hasPermission: boolean;
};

export const ValidationErrorSectionHeader: React.FC<Props> = ({
  urlPath,
  title,
  hasPermission,
}) => {
  return (
    <SValidationSectionTitleRow>
      <SValidationSectionTitle>{title}</SValidationSectionTitle>
      {hasPermission && (
        <LinkButton>
          <Link to={urlPath} target="_blank">
            Open {title}
            <StyledExternalLinkIcon />
          </Link>
        </LinkButton>
      )}
    </SValidationSectionTitleRow>
  );
};
