import React from 'react';

type Props = {
  text: string;
};

const EmptySectionMessage: React.FC<Props> = ({ text }) => {
  return (
    <div>
      <p>{text}</p>
    </div>
  );
};

export default EmptySectionMessage;
