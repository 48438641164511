import React from 'react';

import Banner from '../../../../../../common/components/indicators/banner/Banner';

const OrderBookClosedBanner: React.FC = () => {
  return (
    <div>
      <Banner variant="information">
        <Banner.Title>
          The order book is closed. Please contact Syndicate if changes are needed.
        </Banner.Title>
      </Banner>
    </div>
  );
};

export default OrderBookClosedBanner;
