import { Icon, numericUtil } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import isNil from 'lodash/isNil';
import React from 'react';

import {
  DataGridTotalCell,
  DataGridTotalCellValue,
} from '../../../../../../../common/components/lists/data-grid';
import AllocationFormTooltip from '../allocation-form-cell/AllocationFormTooltip';
import { SAllocationSetTotalsCellWrapper } from './AllocationSetTotalsCell.styles';

export type Props = {
  shareQuantity?: number;
  unallocatedShares?: number;
  loading?: boolean;
  hasRowsHiddenToSalesAndBS?: boolean;
};

/**
 * Displays the total allocated share quantity for an Allocation Set.
 */
const AllocationSetTotalsCell: React.FC<Props> = ({
  shareQuantity = 0,
  unallocatedShares,
  loading,
  hasRowsHiddenToSalesAndBS,
}) => {
  const hasUnallocatedShares = !isNil(unallocatedShares);

  const secondaryText = React.useMemo(() => {
    return hasUnallocatedShares
      ? numericUtil.getDisplayValueForInteger(unallocatedShares)
      : undefined;
  }, [hasUnallocatedShares, unallocatedShares]);

  const IconComponent = React.useMemo(() => {
    const isOverAllocated = hasUnallocatedShares && unallocatedShares < 0;

    if (isOverAllocated) {
      return <Icon name="exclamation-triangle" color="red" variant="solid" />;
    }

    if (hasRowsHiddenToSalesAndBS) {
      return (
        <AllocationFormTooltip>
          <div>
            <Typography variant="h4">Allocation</Typography>
            <Typography>
              Set has an allocation on a Pass, Duplicate, or Cancelled Indication.
            </Typography>
            <Typography variant="caption">
              (These allocations will not be shown to XC Sales & trading or Buy-Side users)
            </Typography>
          </div>
        </AllocationFormTooltip>
      );
    }
  }, [hasUnallocatedShares, unallocatedShares, hasRowsHiddenToSalesAndBS]);

  if (loading) {
    return (
      <SAllocationSetTotalsCellWrapper alignItems="center">
        <Icon name="spinner-third" spin />
      </SAllocationSetTotalsCellWrapper>
    );
  }

  return (
    <SAllocationSetTotalsCellWrapper>
      <DataGridTotalCell
        testId={xcSelectors.institutionalDemandAllocationSetTotal.testId}
        isEditable
        IconComponent={IconComponent}
        secondaryText={secondaryText}
      >
        <DataGridTotalCellValue>{numericUtil.formatInteger(shareQuantity)}</DataGridTotalCellValue>
      </DataGridTotalCell>
    </SAllocationSetTotalsCellWrapper>
  );
};

export default AllocationSetTotalsCell;
