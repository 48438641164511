import { Icon } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SIndicationLevelRow = styled.div`
  padding: 10px 0;
  display: flex;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SIndicationLevelsHeader = styled(SIndicationLevelRow)`
  color: ${({ theme }) => theme.text.color.light};
`;

export const SColumn = styled.div<{ align: 'left' | 'center' | 'right' }>`
  padding-right: 5px;
  text-align: ${props => props.align};
  align-self: center;
  flex: 4;
`;

export const SLimitConfigurationColumn = styled(SColumn)`
  flex: 7;
`;

export const SDeleteColumn = styled(SColumn)`
  flex: 1;
`;

export const SRequired = styled.span<{ showIndicator?: boolean }>`
  ${({ showIndicator }) =>
    showIndicator &&
    css`
      ::after {
        margin-left: 3px;
        color: ${({ theme }) => theme.text.color.error};
        content: '*';
      }
    `}
`;

export const StyledInfoIcon = styled(Icon)`
  margin-left: 3px;
`;
