import { Switch } from '@cmg/common';
import React from 'react';

import { SObsoleteDraftsFilter, StyledFlexLayout } from './ObsoleteDraftsFilter.styles';

export type Props = {
  onChange: (value: boolean) => void;
  isChecked: boolean;
};
/**
 * ObsoleteDraftsFilter is a switch toggle that filters the drafts by
 * whether or not they are obsolete. Remove obsolete with true, show with false
 */
const ObsoleteDraftsFilter: React.FC<Props> = ({ onChange, isChecked }) => {
  const handleOnChange = userInput => {
    onChange(userInput);
  };

  return (
    <SObsoleteDraftsFilter>
      <StyledFlexLayout>
        <Switch
          onChange={handleOnChange}
          checkedContent="On"
          unCheckedContent="Off"
          value={isChecked}
        />
        <div>Hide Obsolete Drafts</div>
      </StyledFlexLayout>
    </SObsoleteDraftsFilter>
  );
};

export default ObsoleteDraftsFilter;
