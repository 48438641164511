import { ToastManager } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { Roadshows_RoadshowPartsFragment } from '../../../../../common/graphql';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import { useRoadshows_RosterQuery } from '../../../common/graphql';
import { Values as ParticipantValues } from '../../../common/participants-form/ParticipantsForm.model';
import { filterParticipants } from '../../../panel-meetings-event/meeting-details-panel/roadshow-coordinator-details-panel/RoadshowCoordinatorDetailsPanel.model';
import LogisticsDetailsForm from '../../common/logistics-details-form/LogisticsDetailsForm';
import {
  getLogisticsPayload,
  LogisticsValues,
} from '../../common/logistics-details-form/LogisticsDetailsForm.model';
import LogisticsSidePanel from '../../common/logistics-side-panel/LogisticsSidePanel';
import { LogisticsNavigationSteps } from '../../common/logistics-side-panel/LogisticsSidePanel.model';
import DeleteLogisticsConfirmModal from './delete-logistics-confirm-modal/DeleteLogisticsConfirmModal';
import { useRoadshows_LogisticsQuery } from './graphql';
import { useDeleteLogisticsMutation } from './hooks/useDeleteLogisticsMutation';
import { useUpdateLogisticsMutation } from './hooks/useUpdateLogisticsMutation';
import { useUpdateLogisticsParticipantsMutation } from './hooks/useUpdateLogisticsParticipantsMutation';
import LogisticsParticipantsForm from './logistics-participants-form/LogisticsParticipantsForm';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  logisticsId: string;
  onClose: () => void;
  stepId?: LogisticsNavigationSteps;
};

const RoadshowCoordinatorLogisticsDetailsPanel: React.FC<Props> = ({
  onClose,
  roadshow,
  logisticsId,
  stepId = LogisticsNavigationSteps.DETAILS,
}) => {
  const { push } = useHistory();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);
  const [logisticsHeaderInfo, setLogisticsHeaderInfo] = React.useState<EventInfo | null>(null);

  const {
    data: logisticsData,
    error: logisticsError,
    loading: logisticsLoading,
  } = useRoadshows_LogisticsQuery({
    variables: { logisticsId, roadshowId: roadshow.id },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: rosterData,
    loading: rosterLoading,
    error: rosterError,
  } = useRoadshows_RosterQuery({
    variables: { roadshowId: roadshow.id },
    skip: !roadshow.companyName,
    fetchPolicy: 'cache-and-network',
  });
  const [updateLogistics, { loading: isUpdating }] = useUpdateLogisticsMutation();
  const [deleteLogistics, { loading: isDeleting }] = useDeleteLogisticsMutation();
  const [updateParticipants, { loading: isUpdatingParticipants }] =
    useUpdateLogisticsParticipantsMutation();

  const handleSubmit = async (logisticsValues: LogisticsValues) => {
    try {
      await updateLogistics({
        variables: {
          logisticsId,
          roadshowId: roadshow.id,
          payload: getLogisticsPayload(logisticsValues),
        },
      });

      setLogisticsHeaderInfo(EventInfo.LOGISTICS_UPDATED);
    } catch {
      ToastManager.error('Failed to update meeting draft');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLogistics({ variables: { logisticsId, roadshowId: roadshow.id } });

      ToastManager.success('Logistics was successfully deleted');
      onClose();
    } catch {
      ToastManager.error('Failed to delete Logistics');
    }
  };

  const handleSubmitParticipants = async ({ participants }: ParticipantValues) => {
    try {
      const { participantsIds, adHocParticipants } = filterParticipants(participants);

      await updateParticipants({
        variables: {
          logisticsId,
          roadshowId: roadshow.id,
          participantsIds,
          adHocParticipants,
        },
      });

      ToastManager.success('Participants saved successfully');
    } catch {
      ToastManager.error('Failed to save participants');
    }
  };

  const handleNavigationChange = (nextStepId: LogisticsNavigationSteps) => {
    push(
      routeFactory.roadshowLogisticsDetails.getUrlPath({
        logisticsId,
        roadshowId: roadshow.id,
        stepId: nextStepId,
      })
    );
  };

  const isLoading = logisticsLoading || rosterLoading;
  const error = logisticsError || rosterError;

  return (
    <LogisticsSidePanel
      onClose={onClose}
      activeNavigationStepId={stepId}
      title={logisticsData?.roadshowLogisticsDetails.title}
      loading={isLoading}
      onNavigationChange={handleNavigationChange}
      testId="roadshows / single-roadshow / calendar / logistics panel"
    >
      <FlexLayout expand direction="column">
        {error && <ServerErrorsBanner error={error} />}

        {stepId === LogisticsNavigationSteps.DETAILS && (
          <LogisticsDetailsForm
            roadshow={roadshow}
            logistics={logisticsData?.roadshowLogisticsDetails}
            onSubmit={handleSubmit}
            isSaving={isUpdating}
            logisticsHeaderInfo={logisticsHeaderInfo}
            onDelete={() => setShowConfirmDeleteModal(true)}
            isDeleting={isDeleting}
          />
        )}

        {stepId === LogisticsNavigationSteps.PARTICIPANTS && logisticsData && (
          <LogisticsParticipantsForm
            participantTeams={logisticsData.roadshowLogisticsParticipants}
            companyName={roadshow.companyName}
            logisticsHeaderInfo={logisticsHeaderInfo}
            onDelete={() => setShowConfirmDeleteModal(true)}
            isDeleting={isDeleting}
            onSubmit={handleSubmitParticipants}
            isSaving={isUpdatingParticipants}
            roster={rosterData}
          />
        )}
      </FlexLayout>

      <DeleteLogisticsConfirmModal
        onConfirm={handleDelete}
        loading={isDeleting}
        onClose={() => setShowConfirmDeleteModal(false)}
        show={showConfirmDeleteModal}
      />
    </LogisticsSidePanel>
  );
};

export default RoadshowCoordinatorLogisticsDetailsPanel;
