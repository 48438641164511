/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_ManualAdjustmentPartsFragment = {
  readonly __typename?: 'ManualAdjustment';
  readonly managerCmgEntityKey: string;
  readonly description?: string | null;
  readonly manualAdjustment?: number | null;
};

export type FinalSettlement_UpdateAccountingManualAdjustmentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload:
    | ReadonlyArray<Types.UpdateAccountingAdjustmentsInput>
    | Types.UpdateAccountingAdjustmentsInput;
}>;

export type FinalSettlement_UpdateAccountingManualAdjustmentMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAccountingAdjustments: ReadonlyArray<{
    readonly __typename?: 'ManualAdjustment';
    readonly managerCmgEntityKey: string;
    readonly description?: string | null;
    readonly manualAdjustment?: number | null;
  }>;
};

export const FinalSettlement_ManualAdjustmentPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ManualAdjustmentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManualAdjustment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualAdjustment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_UpdateAccountingManualAdjustmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_UpdateAccountingManualAdjustment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateAccountingAdjustmentsInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountingAdjustments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_ManualAdjustmentParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ManualAdjustmentParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManualAdjustment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualAdjustment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_UpdateAccountingManualAdjustmentMutationFn = Apollo.MutationFunction<
  FinalSettlement_UpdateAccountingManualAdjustmentMutation,
  FinalSettlement_UpdateAccountingManualAdjustmentMutationVariables
>;

/**
 * __useFinalSettlement_UpdateAccountingManualAdjustmentMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_UpdateAccountingManualAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_UpdateAccountingManualAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementUpdateAccountingManualAdjustmentMutation, { data, loading, error }] = useFinalSettlement_UpdateAccountingManualAdjustmentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useFinalSettlement_UpdateAccountingManualAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_UpdateAccountingManualAdjustmentMutation,
    FinalSettlement_UpdateAccountingManualAdjustmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_UpdateAccountingManualAdjustmentMutation,
    FinalSettlement_UpdateAccountingManualAdjustmentMutationVariables
  >(FinalSettlement_UpdateAccountingManualAdjustmentDocument, options);
}
export type FinalSettlement_UpdateAccountingManualAdjustmentMutationHookResult = ReturnType<
  typeof useFinalSettlement_UpdateAccountingManualAdjustmentMutation
>;
export type FinalSettlement_UpdateAccountingManualAdjustmentMutationResult =
  Apollo.MutationResult<FinalSettlement_UpdateAccountingManualAdjustmentMutation>;
export type FinalSettlement_UpdateAccountingManualAdjustmentMutationOptions =
  Apollo.BaseMutationOptions<
    FinalSettlement_UpdateAccountingManualAdjustmentMutation,
    FinalSettlement_UpdateAccountingManualAdjustmentMutationVariables
  >;
