import { Button, Tooltip } from '@cmg/design-system';

import { useDownloadCertificate } from '../../../../../../hooks/useDownloadFirmCertificate';

const DownloadPdfButton = ({
  cmgEntityKey,
  certificateId,
}: {
  cmgEntityKey: string;
  certificateId: string | null | undefined;
}) => {
  const { loading, handleDownloadCertificate } = useDownloadCertificate(
    cmgEntityKey,
    certificateId
  );
  const buttonText = 'View';

  if (loading) {
    return (
      <Button
        sx={{ minWidth: '0px' }}
        size="small"
        variant="text"
        disabled={true}
        onClick={handleDownloadCertificate}
      >
        {buttonText}
      </Button>
    );
  }
  return (
    <Tooltip title="View certificate" placement="top">
      <Button
        sx={{ minWidth: '0px' }}
        size="small"
        variant="text"
        aria-label={buttonText} // Tooltip it's trying to change it's description
        onClick={handleDownloadCertificate}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default DownloadPdfButton;
