import {
  accessTokenExpirationRequestInterceptor,
  getAccessToken,
  getUserProfileLogContext,
} from '@cmg/auth';
import { loggerUtil } from '@cmg/common';
import axios, { InternalAxiosRequestConfig } from 'axios';

/**
 * Add as a request interceptor to an axios client to have the user's access token
 * automatically appended as the 'Authorization' header.
 */
const accessTokenRequestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers.setAuthorization(`Bearer ${accessToken}`);
  }

  return config;
};

const responseErrorInterceptor = (error: unknown) => {
  if (!axios.isAxiosError(error)) {
    return Promise.reject(error);
  }

  // Capture unexpected errors in our monitoring systems
  if (!error.response) {
    // An Axios internal error
    loggerUtil.error(error, getUserProfileLogContext());
  } else if (error.response) {
    const { status } = error.response;
    // 409 - Multiple users may have edited the same entity
    // 404 - Not found
    // 422 - Should be handled by caller
    const ignoredStatusCodes = [404, 409, 422];
    if (!ignoredStatusCodes.includes(status)) {
      loggerUtil.error(error, getUserProfileLogContext());
    }
  }
  return Promise.reject(error);
};

export function createApiClientFactory() {
  const instance = axios.create({ responseType: 'text' }); // generated client expects text response type
  instance.interceptors.request.use(accessTokenExpirationRequestInterceptor);
  instance.interceptors.request.use(accessTokenRequestInterceptor);
  instance.interceptors.response.use(undefined, responseErrorInterceptor);
  return instance;
}
