import { urlUtil } from '@cmg/common';
import { CustomTooltipProps, DesignSystemTooltipProps } from '@cmg/data-grid';
import { Box, Button } from '@cmg/design-system';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import offeringSidePanelSellSideRouteFactory from '../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { NotesFilters } from '../../../../../offering-side-panel/order-book/notes/NotesModel.util';
import { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import { DemandGridDataContext } from '../../types';

export type Props = CustomTooltipProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  string,
  DemandGridDataContext
> & {
  noteType: NotesFilters;
};

const NoteTooltip = ({ context, value, data, noteType, hideTooltipCallback }: Props) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const cmgEntityKey = data?.investor.cmgEntityKey;

  const handleOnClick = () => {
    const pathname = routeFactory.offeringSidePanel.getUrlPath({
      offeringId: context.offeringId,
      sidePanelRoutePath: offeringSidePanelSellSideRouteFactory.orderBookNotes.getUrlPath({}),
      prefixRoutePath: url,
    });

    const search = urlUtil.queryStringify({
      cmgEntityKey,
      noteType,
    });

    history.push({ pathname, search });
    hideTooltipCallback?.();
  };

  return (
    <Box>
      {value}
      <br />
      <Button style={{ pointerEvents: 'all' }} variant="text" onClick={handleOnClick}>
        See All
      </Button>
    </Box>
  );
};

export const getSyndicateNoteTooltipContent: DesignSystemTooltipProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  string,
  DemandGridDataContext
>['getContent'] = () => props => <NoteTooltip {...props} noteType={NotesFilters.SYNDICATE} />;

export const getSalesNoteTooltipContent: DesignSystemTooltipProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  string,
  DemandGridDataContext
>['getContent'] = () => props => <NoteTooltip {...props} noteType={NotesFilters.SALES_TRADER} />;

export default NoteTooltip;
