import { useAuth } from '@cmg/auth';
import { useBrowserStorage } from '@cmg/common';
import { GridState, useDataGridContext } from '@cmg/data-grid';
import React from 'react';

import { OfferingStatus, OfferingType } from '../../../../../graphql';
import { StoredGridState } from './useStoredGridState';
import { CMG_TEMPLATES, isDynamicField } from './useTemplatesState.model';

export type UserTemplate = {
  id: string;
  name: string;
  active: boolean;
  gridState: StoredGridState;
};

export function getCmgTemplateKey({
  offeringType,
  offeringStatus,
}: {
  offeringType: OfferingType;
  offeringStatus: OfferingStatus;
}): keyof typeof CMG_TEMPLATES {
  // TODO: cmg template will be selected based on offeringType and offeringStatus in the future
  return 'CMG Default';
}

export function getGridStateForTemplate(state: GridState | null): StoredGridState {
  if (!state) {
    return {};
  }
  return {
    columnOrder: state?.columnOrder
      ? { orderedColIds: state.columnOrder.orderedColIds.filter(item => !isDynamicField(item)) }
      : undefined,
    columnVisibility: state?.columnVisibility
      ? { hiddenColIds: state.columnVisibility.hiddenColIds.filter(item => !isDynamicField(item)) }
      : undefined,
    columnPinning: state?.columnPinning
      ? {
          leftColIds: state.columnPinning.leftColIds.filter(item => !isDynamicField(item)),
          rightColIds: state.columnPinning.rightColIds.filter(item => !isDynamicField(item)),
        }
      : undefined,
    columnSizing: state?.columnSizing
      ? {
          columnSizingModel: state.columnSizing.columnSizingModel.filter(
            ({ colId }) => !isDynamicField(colId)
          ),
        }
      : undefined,
  };
}

export function useTemplatesState() {
  const { applyGridState, getColDefs, getState } = useDataGridContext();
  const { oidcUserId } = useAuth();
  const [userTemplates, setUserTemplates] = useBrowserStorage<UserTemplate[]>({
    key: `userTemplates_${oidcUserId}`,
    initialValue: [],
    storageType: 'Local',
  });

  const saveTemplate = React.useCallback(
    (id: string) => {
      setUserTemplates(
        userTemplates.map(template => {
          if (template.id === id) {
            return { ...template, gridState: getGridStateForTemplate(getState()) };
          }
          return template;
        })
      );
    },
    [getState, setUserTemplates, userTemplates]
  );

  const addTemplate = React.useCallback(
    ({
      id,
      name,
      originalTemplateId,
    }: {
      id: string;
      name: string;
      originalTemplateId?: string;
    }) => {
      let gridState: StoredGridState | undefined = undefined;
      if (originalTemplateId === undefined) {
        gridState = getGridStateForTemplate(getState());
      } else {
        const originalTemplate = userTemplates.find(template => template.id === originalTemplateId);
        if (originalTemplate) {
          gridState = originalTemplate.gridState;
        } else {
          gridState = CMG_TEMPLATES[originalTemplateId]({ colDefs: getColDefs() });
        }
      }
      if (!gridState) {
        return;
      }
      const newTemplate = {
        id,
        name,
        active: true,
        gridState,
      };
      if (userTemplates.find(template => template.id === id)) {
        setUserTemplates(
          userTemplates.map(template => {
            if (template.id === id) {
              return { ...template, name };
            }
            return template;
          })
        );
      } else {
        setUserTemplates([
          ...userTemplates.map(template => ({ ...template, active: false })),
          newTemplate,
        ]);
      }
      applyGridState(gridState);
    },
    [userTemplates, applyGridState, getState, getColDefs, setUserTemplates]
  );

  const getActiveTemplate = React.useCallback(
    () => userTemplates.find(template => template.active),
    [userTemplates]
  );

  const removeTemplate = React.useCallback(
    ({ id, cmgTemplateKey }: { id: string; cmgTemplateKey: keyof typeof CMG_TEMPLATES }) => {
      const result = userTemplates.filter(template => template.id !== id);
      setUserTemplates(result);
      if (result.find(template => template.active) === undefined) {
        applyGridState(CMG_TEMPLATES[cmgTemplateKey]({ colDefs: getColDefs() }));
      }
    },
    [userTemplates, setUserTemplates, applyGridState, getColDefs]
  );

  const setActiveUserTemplate = React.useCallback(
    (id: string) => {
      const result = userTemplates.map(template => {
        if (template.id === id) {
          applyGridState(template.gridState);
          return { ...template, active: true };
        }
        return { ...template, active: false };
      });
      setUserTemplates(result);
    },
    [applyGridState, setUserTemplates, userTemplates]
  );

  const setActiveCmgTemplate = React.useCallback(
    (cmgTemplateKey: keyof typeof CMG_TEMPLATES) => {
      applyGridState(CMG_TEMPLATES[cmgTemplateKey]({ colDefs: getColDefs() }));
      const result = userTemplates.map(template => {
        return { ...template, active: false };
      });
      setUserTemplates(result);
    },
    [applyGridState, getColDefs, setUserTemplates, userTemplates]
  );

  return {
    userTemplates,
    addTemplate,
    removeTemplate,
    saveTemplate,
    getActiveTemplate,
    setActiveUserTemplate,
    setActiveCmgTemplate,
  };
}
