import { UUID } from '@cmg/common';
import React from 'react';

import { useIsUserSettlementAgent } from '../../../../../../../../../../common/hooks/useIsUserSettlementAgent/useIsUserSettlementAgent';
import { InstitutionalDraftSet } from '../../../../../types';

type DraftSets = {
  defaultSection: InstitutionalDraftSet[];
  draftSection: InstitutionalDraftSet[];
};

export const useSeparateDraftSets = (
  draftAllocationSets: readonly InstitutionalDraftSet[],
  offeringId: UUID
) => {
  const { isSettlementAgent, loading, error } = useIsUserSettlementAgent({ offeringId });

  const draftSets = React.useMemo(() => {
    if (isSettlementAgent) {
      return { defaultSection: [], draftSection: draftAllocationSets };
    }

    return draftAllocationSets.reduce<DraftSets>(
      (result, current) => {
        if (current.isDefault) {
          return { ...result, defaultSection: [...result.defaultSection, current] };
        }
        return { ...result, draftSection: [...result.draftSection, current] };
      },
      {
        defaultSection: [],
        draftSection: [],
      }
    );
  }, [draftAllocationSets, isSettlementAgent]);

  return { isLoadingSettlementAgent: loading, error, draftSets };
};
