import { numericUtil, ODSTable as Table, timeUtil } from '@cmg/common';
import React from 'react';

import { getLabelFromOptions } from '../../../../common/form-utils';
import { ActivityGroupType, getActivitiesGroupLabel } from '../../common/activity/utils';
import {
  activityTypeOptions,
  stabilizationActivityTypeOptions,
} from '../../form/TNFilingForm.model';
import { RegulatoryFilings_TnFilingPartsFragment } from '../../graphql';
import { ZeroActivitiesTableGroup } from './ZeroActivitiesTableGroup';

export type Props = {
  groupType: ActivityGroupType;
  activities:
    | RegulatoryFilings_TnFilingPartsFragment['activities']
    | RegulatoryFilings_TnFilingPartsFragment['stabilizationActivities'];
};

export const ActivitiesTableGroup: React.FC<Props> = ({ groupType, activities }) => {
  return (
    <React.Fragment>
      <Table.GroupHeaderCell colSpan={3}>
        {getActivitiesGroupLabel(groupType)}
      </Table.GroupHeaderCell>
      {activities.length ? (
        activities.map((activity, idx) => (
          <Table.Row key={idx}>
            <Table.Cell paddingSize="S">
              {groupType === 'custom'
                ? getLabelFromOptions(activity.activityType, activityTypeOptions)
                : getLabelFromOptions(
                    activity.stabilizationActivityType,
                    stabilizationActivityTypeOptions
                  )}
            </Table.Cell>
            <Table.Cell paddingSize="S">
              {activity.date && timeUtil.formatAsDisplayDate(activity.date)}
            </Table.Cell>
            <Table.Cell paddingSize="S" align="right">
              {groupType === 'custom'
                ? numericUtil.formatInteger(activity.aggregateNumberOfShares)
                : numericUtil.formatInteger(activity.shares)}
            </Table.Cell>
          </Table.Row>
        ))
      ) : (
        <ZeroActivitiesTableGroup groupType={groupType} mode="view" colSpan={3} />
      )}
    </React.Fragment>
  );
};
