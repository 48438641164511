import { LinkButton, TextInput } from '@cmg/common';
import styled from 'styled-components/macro';

export const SLinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-top: 10px;
`;

export const SErrorSpan = styled.div`
  padding: 8px 0;
  color: ${({ theme }) => theme.text.color.error};
  display: block;
`;

export const StyledTextInput = styled(TextInput)`
  flex-basis: 75%;
  margin-right: 10px;
  label,
  input {
    color: ${({ theme }) => theme.text.color.darkGray};
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 20px;
`;
