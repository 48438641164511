import { DateRange } from '@cmg/common';
import React from 'react';

import { getPresetOptions } from './presets';
import {
  SIconContainer,
  SPricingDateRangeFilter,
  SSeparator,
  StyledIconDateRange,
} from './PricingDateRangeFilter.styles';

export type DateRangeValue = {
  start?: string;
  end?: string;
  type?: string;
};

export type Props = {
  pricingDateRange: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
};
/**
 * PricingDateRangeFilter sets the pricing date range for offerings to be filtered by
 */
const PricingDateRangeFilter: React.FC<Props> = ({ pricingDateRange, onChange }) => {
  return (
    <SPricingDateRangeFilter>
      <DateRange
        presetOptions={getPresetOptions(new Date())}
        value={pricingDateRange}
        onChange={onChange}
        placeholder="Pricing Date..."
        suffix={
          <SIconContainer>
            <SSeparator />
            <StyledIconDateRange />
          </SIconContainer>
        }
      />
    </SPricingDateRangeFilter>
  );
};

export default PricingDateRangeFilter;
