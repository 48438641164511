import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
`;

export const StyledOnPlatformImg = styled.img`
  height: 15px;
  width: 16px;
`;

export const StyledLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const StyledIndentedLink = styled(StyledLink)`
  padding: 0 8px;
`;

export const SLinkWithoutLogoWrapper = styled.span`
  margin-left: 16px;
`;
