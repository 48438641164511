import { Popover } from '@cmg/common';
import React from 'react';

import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import { MdlAllocationIoiType } from '../../../../graphql';
import { Calendar_MdlIndicationPartsFragment } from '../../graphql';
import { mapIOIColumnSources } from '../../utils/IOIColumnsUtils';
import { Calendar_LiveCalendarOfferingsListQuery } from '../graphql';
import { SAdditionalDemand, SDemandCell } from './BuySideDemandCellRenderer.styles';

type Props = {
  myInstitutionalIndication: Calendar_LiveCalendarOfferingsListQuery['livePublishedOfferings']['data'][number]['myInstitutionalIndication'];
  mdlIndications: {
    indications: readonly Calendar_MdlIndicationPartsFragment[];
    ioiType?: MdlAllocationIoiType;
  } | null;
  pricingCurrencyCode?: string;
};
/**
 * BuySide Demand column cell renderer - renders the demand value based on the institutional indication details
 * when a market interest level is logged it will always display face-up, otherwise the highest priced limit will display
 * the remainder interest levels if available will display within a hover
 */
const BuySideDemandCellRenderer: React.FC<Props> = ({
  myInstitutionalIndication,
  mdlIndications,
  pricingCurrencyCode,
}) => {
  const { rootElement, hoverElements } = mapIOIColumnSources({
    mdlIndications,
    myInstitutionalIndication,
    pricingCurrencyCode,
  });

  // Hover elements.
  const hoverContent = hoverElements && hoverElements.length > 1 && (
    <FlexLayout direction="column">
      <div>Indication</div>
      <table>
        <tbody>
          {hoverElements.map((interestLevel, index) => (
            <tr key={index}>
              <td>{interestLevel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </FlexLayout>
  );

  if (hoverContent && hoverElements) {
    return (
      <div>
        <Popover content={hoverContent} variant="DARK">
          <SDemandCell>
            {rootElement}
            <SAdditionalDemand>&nbsp;+{hoverElements.length - 1}...</SAdditionalDemand>
          </SDemandCell>
        </Popover>
      </div>
    );
  }

  return <SDemandCell>{rootElement}</SDemandCell>;
};

export default BuySideDemandCellRenderer;
