import { Panel } from '@cmg/common';
import React from 'react';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Filters from '../filters/Filters';
import RoadshowsListDataGrid from '../grid/RoadshowsListDataGrid';
import { useCoveredRoadshowPaginatedListQuery } from './hooks/useCoveredRoadshowPaginatedListQuery';

const SalesAndTradingRoadshowOverview: React.FC = () => {
  const { data, loading, error, setFilter, setPagination } = useCoveredRoadshowPaginatedListQuery();

  return (
    <Panel fillViewport testId="roadshows / overviews / list of roadshows">
      <Filters setFilter={setFilter} />

      {error && <ServerErrorsBanner error={error} />}

      <RoadshowsListDataGrid rows={data} isLoading={loading} setPagination={setPagination} />
    </Panel>
  );
};

export default SalesAndTradingRoadshowOverview;
