import styled, { css } from 'styled-components/macro';

export const SContainer = styled.div<{ active?: boolean; isChild?: boolean; disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ theme }) => theme.border.radius.small};

  ${({ active, isChild, theme }) =>
    active &&
    !isChild &&
    css`
      background-color: ${theme.brand.color.lightAlternate};
      color: ${theme.text.color.white};
    `}

  ${({ active, isChild, disabled, theme }) => {
    if (active && isChild) {
      return css`
        color: ${theme.brand.color.lightAlternate};
      `;
    }
    if (disabled) {
      return css`
        color: ${theme.designSystem.colors.gray['300']};
        cursor: not-allowed;
      `;
    }
  }}
`;
