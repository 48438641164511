import { Typography } from '@cmg/design-system';
import React from 'react';

import { getFirmDisplayName } from '../../../../utils';
import { SyndicateManager } from '../../types';

type Props = {
  currentIndicationVersion: string;
  acknowledgment: {
    acknowledgedIndicationVersion: string;
    managerCmgEntityKey: string;
  };
  syndicateManagers: Record<string, SyndicateManager>;
  isUsersFirm: boolean;
  numberOfAcknowledgements: number;
  index: number;
};

const IndicationAcknowlegment: React.FC<Props> = ({
  currentIndicationVersion,
  acknowledgment,
  syndicateManagers,
  isUsersFirm,
  numberOfAcknowledgements,
  index,
}) => {
  const isRevised = acknowledgment.acknowledgedIndicationVersion !== currentIndicationVersion;
  const manager = getFirmDisplayName(syndicateManagers[acknowledgment.managerCmgEntityKey] ?? null);
  const firmName = numberOfAcknowledgements === index + 1 || index === 2 ? manager : `${manager}, `;

  return (
    <Typography
      fontSize={13}
      component="span"
      fontWeight={isUsersFirm && isRevised ? 'bold' : undefined}
      color={isRevised ? theme => theme.palette.warning.dark : undefined}
    >
      {firmName}
    </Typography>
  );
};

export default IndicationAcknowlegment;
