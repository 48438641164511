/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationForm_AcknowledgeCoveredIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  version: Types.Scalars['String'];
}>;

export type IndicationForm_AcknowledgeCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly acknowledgeCoveredIndication: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationAcknowledgement';
    readonly managerCmgEntityKey: string;
    readonly version: string;
    readonly indicationVersion: string;
    readonly acknowledgedIndicationVersion: string;
  }>;
};

export type IndicationForm_AcknowledgeIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  version: Types.Scalars['String'];
}>;

export type IndicationForm_AcknowledgeIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly acknowledgeInstitutionalIndication: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationAcknowledgement';
    readonly managerCmgEntityKey: string;
    readonly version: string;
    readonly indicationVersion: string;
    readonly acknowledgedIndicationVersion: string;
  }>;
};

export type IndicationForm_IndicationAcknowledgementsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type IndicationForm_IndicationAcknowledgementsQuery = {
  readonly __typename?: 'Query';
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationAcknowledgement';
    readonly managerCmgEntityKey: string;
    readonly version: string;
    readonly indicationVersion: string;
    readonly acknowledgedIndicationVersion: string;
  }>;
};

export type IndicationForm_UnacknowledgeCoveredIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  version: Types.Scalars['String'];
}>;

export type IndicationForm_UnacknowledgeCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly unacknowledgeCoveredIndication: {
    readonly __typename?: 'UnacknowledgeInstitutionalIndicationResult';
    readonly indicationId: string;
  };
};

export type IndicationForm_UnacknowledgeIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  version: Types.Scalars['String'];
}>;

export type IndicationForm_UnacknowledgeIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly unacknowledgeInstitutionalIndication: {
    readonly __typename?: 'UnacknowledgeInstitutionalIndicationResult';
    readonly indicationId: string;
  };
};

export const IndicationForm_AcknowledgeCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationForm_AcknowledgeCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgeCoveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationForm_AcknowledgeCoveredIndicationMutationFn = Apollo.MutationFunction<
  IndicationForm_AcknowledgeCoveredIndicationMutation,
  IndicationForm_AcknowledgeCoveredIndicationMutationVariables
>;

/**
 * __useIndicationForm_AcknowledgeCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useIndicationForm_AcknowledgeCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_AcknowledgeCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationFormAcknowledgeCoveredIndicationMutation, { data, loading, error }] = useIndicationForm_AcknowledgeCoveredIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIndicationForm_AcknowledgeCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationForm_AcknowledgeCoveredIndicationMutation,
    IndicationForm_AcknowledgeCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationForm_AcknowledgeCoveredIndicationMutation,
    IndicationForm_AcknowledgeCoveredIndicationMutationVariables
  >(IndicationForm_AcknowledgeCoveredIndicationDocument, options);
}
export type IndicationForm_AcknowledgeCoveredIndicationMutationHookResult = ReturnType<
  typeof useIndicationForm_AcknowledgeCoveredIndicationMutation
>;
export type IndicationForm_AcknowledgeCoveredIndicationMutationResult =
  Apollo.MutationResult<IndicationForm_AcknowledgeCoveredIndicationMutation>;
export type IndicationForm_AcknowledgeCoveredIndicationMutationOptions = Apollo.BaseMutationOptions<
  IndicationForm_AcknowledgeCoveredIndicationMutation,
  IndicationForm_AcknowledgeCoveredIndicationMutationVariables
>;
export const IndicationForm_AcknowledgeIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationForm_AcknowledgeIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgeInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationForm_AcknowledgeIndicationMutationFn = Apollo.MutationFunction<
  IndicationForm_AcknowledgeIndicationMutation,
  IndicationForm_AcknowledgeIndicationMutationVariables
>;

/**
 * __useIndicationForm_AcknowledgeIndicationMutation__
 *
 * To run a mutation, you first call `useIndicationForm_AcknowledgeIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_AcknowledgeIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationFormAcknowledgeIndicationMutation, { data, loading, error }] = useIndicationForm_AcknowledgeIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIndicationForm_AcknowledgeIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationForm_AcknowledgeIndicationMutation,
    IndicationForm_AcknowledgeIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationForm_AcknowledgeIndicationMutation,
    IndicationForm_AcknowledgeIndicationMutationVariables
  >(IndicationForm_AcknowledgeIndicationDocument, options);
}
export type IndicationForm_AcknowledgeIndicationMutationHookResult = ReturnType<
  typeof useIndicationForm_AcknowledgeIndicationMutation
>;
export type IndicationForm_AcknowledgeIndicationMutationResult =
  Apollo.MutationResult<IndicationForm_AcknowledgeIndicationMutation>;
export type IndicationForm_AcknowledgeIndicationMutationOptions = Apollo.BaseMutationOptions<
  IndicationForm_AcknowledgeIndicationMutation,
  IndicationForm_AcknowledgeIndicationMutationVariables
>;
export const IndicationForm_IndicationAcknowledgementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationForm_IndicationAcknowledgements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationForm_IndicationAcknowledgementsQuery__
 *
 * To run a query within a React component, call `useIndicationForm_IndicationAcknowledgementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_IndicationAcknowledgementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationForm_IndicationAcknowledgementsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useIndicationForm_IndicationAcknowledgementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationForm_IndicationAcknowledgementsQuery,
    IndicationForm_IndicationAcknowledgementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationForm_IndicationAcknowledgementsQuery,
    IndicationForm_IndicationAcknowledgementsQueryVariables
  >(IndicationForm_IndicationAcknowledgementsDocument, options);
}
export function useIndicationForm_IndicationAcknowledgementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationForm_IndicationAcknowledgementsQuery,
    IndicationForm_IndicationAcknowledgementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationForm_IndicationAcknowledgementsQuery,
    IndicationForm_IndicationAcknowledgementsQueryVariables
  >(IndicationForm_IndicationAcknowledgementsDocument, options);
}
export type IndicationForm_IndicationAcknowledgementsQueryHookResult = ReturnType<
  typeof useIndicationForm_IndicationAcknowledgementsQuery
>;
export type IndicationForm_IndicationAcknowledgementsLazyQueryHookResult = ReturnType<
  typeof useIndicationForm_IndicationAcknowledgementsLazyQuery
>;
export type IndicationForm_IndicationAcknowledgementsQueryResult = Apollo.QueryResult<
  IndicationForm_IndicationAcknowledgementsQuery,
  IndicationForm_IndicationAcknowledgementsQueryVariables
>;
export const IndicationForm_UnacknowledgeCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationForm_UnacknowledgeCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unacknowledgeCoveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationForm_UnacknowledgeCoveredIndicationMutationFn = Apollo.MutationFunction<
  IndicationForm_UnacknowledgeCoveredIndicationMutation,
  IndicationForm_UnacknowledgeCoveredIndicationMutationVariables
>;

/**
 * __useIndicationForm_UnacknowledgeCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useIndicationForm_UnacknowledgeCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_UnacknowledgeCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationFormUnacknowledgeCoveredIndicationMutation, { data, loading, error }] = useIndicationForm_UnacknowledgeCoveredIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIndicationForm_UnacknowledgeCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationForm_UnacknowledgeCoveredIndicationMutation,
    IndicationForm_UnacknowledgeCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationForm_UnacknowledgeCoveredIndicationMutation,
    IndicationForm_UnacknowledgeCoveredIndicationMutationVariables
  >(IndicationForm_UnacknowledgeCoveredIndicationDocument, options);
}
export type IndicationForm_UnacknowledgeCoveredIndicationMutationHookResult = ReturnType<
  typeof useIndicationForm_UnacknowledgeCoveredIndicationMutation
>;
export type IndicationForm_UnacknowledgeCoveredIndicationMutationResult =
  Apollo.MutationResult<IndicationForm_UnacknowledgeCoveredIndicationMutation>;
export type IndicationForm_UnacknowledgeCoveredIndicationMutationOptions =
  Apollo.BaseMutationOptions<
    IndicationForm_UnacknowledgeCoveredIndicationMutation,
    IndicationForm_UnacknowledgeCoveredIndicationMutationVariables
  >;
export const IndicationForm_UnacknowledgeIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationForm_UnacknowledgeIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unacknowledgeInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationForm_UnacknowledgeIndicationMutationFn = Apollo.MutationFunction<
  IndicationForm_UnacknowledgeIndicationMutation,
  IndicationForm_UnacknowledgeIndicationMutationVariables
>;

/**
 * __useIndicationForm_UnacknowledgeIndicationMutation__
 *
 * To run a mutation, you first call `useIndicationForm_UnacknowledgeIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_UnacknowledgeIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationFormUnacknowledgeIndicationMutation, { data, loading, error }] = useIndicationForm_UnacknowledgeIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useIndicationForm_UnacknowledgeIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationForm_UnacknowledgeIndicationMutation,
    IndicationForm_UnacknowledgeIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationForm_UnacknowledgeIndicationMutation,
    IndicationForm_UnacknowledgeIndicationMutationVariables
  >(IndicationForm_UnacknowledgeIndicationDocument, options);
}
export type IndicationForm_UnacknowledgeIndicationMutationHookResult = ReturnType<
  typeof useIndicationForm_UnacknowledgeIndicationMutation
>;
export type IndicationForm_UnacknowledgeIndicationMutationResult =
  Apollo.MutationResult<IndicationForm_UnacknowledgeIndicationMutation>;
export type IndicationForm_UnacknowledgeIndicationMutationOptions = Apollo.BaseMutationOptions<
  IndicationForm_UnacknowledgeIndicationMutation,
  IndicationForm_UnacknowledgeIndicationMutationVariables
>;

import { makeMockResponse } from '../../../../../../../../../../../../graphql/mock-response';

export const makeIndicationForm_IndicationAcknowledgementsMockResponse = makeMockResponse<
  IndicationForm_IndicationAcknowledgementsQueryVariables,
  IndicationForm_IndicationAcknowledgementsQuery
>(IndicationForm_IndicationAcknowledgementsDocument);
