import { xcSelectors } from '@cmg/e2e-selectors';

/**
 * Represents the steps in the wizard, as represented in the UI.
 *
 * ORDER MATTERS!!!
 */
export const stepIds = {
  BASIC_INFO: 'info',
  CURRENCIES: 'currencies',
  INSTRUMENTS: 'instruments',
  TRANCHES: 'tranches',
  MANAGERS: 'managers',
  TIMING: 'timing',
  MARKETING: 'marketing',
  TERMS: 'terms',
  OFFERING_TERMS: 'offering-terms',
  UW_DISCOUNTS_FEES: 'underwriting-discounts-fees',
  MANAGER_ECONOMICS: 'manager_economics',
  UNDERWRITING_TERMS: 'underwriting-terms',
  PROSPECTUS: 'prospectus',
  DISCLAIMERS: 'disclaimers',
  SUMMARY: 'summary',
};

export const stepTitles = {
  [stepIds.BASIC_INFO]: 'Issuer Details',
  [stepIds.CURRENCIES]: 'Currencies',
  [stepIds.INSTRUMENTS]: 'Instruments',
  [stepIds.TRANCHES]: 'Tranches',
  [stepIds.MANAGERS]: 'Managers',
  [stepIds.TIMING]: 'Timing',
  [stepIds.MARKETING]: 'Marketing',
  [stepIds.TERMS]: 'Terms',
  [stepIds.OFFERING_TERMS]: 'Offering Terms',
  [stepIds.UW_DISCOUNTS_FEES]: 'Discounts & Fees',
  [stepIds.MANAGER_ECONOMICS]: 'Manager Economics',
  [stepIds.UNDERWRITING_TERMS]: 'Underwriting Terms',
  [stepIds.PROSPECTUS]: 'Prospectus',
  [stepIds.DISCLAIMERS]: 'Disclaimers',
  [stepIds.SUMMARY]: 'Summary',
};

export const stepDataTestIds = {
  [stepIds.BASIC_INFO]: xcSelectors.offeringSetupSideNavBasicInfo.testId,
  [stepIds.CURRENCIES]: xcSelectors.offeringSetupSideNavCurrencies.testId,
  [stepIds.INSTRUMENTS]: xcSelectors.offeringSetupSideNavInstruments.testId,
  [stepIds.TRANCHES]: xcSelectors.offeringSetupSideNavTranches.testId,
  [stepIds.MANAGERS]: xcSelectors.offeringSetupSideNavManagers.testId,
  [stepIds.MANAGER_ECONOMICS]: xcSelectors.offeringSetupSideNavManagerEconomics.testId,
  [stepIds.TIMING]: xcSelectors.offeringSetupSideNavTiming.testId,
  [stepIds.MARKETING]: xcSelectors.offeringSetupSideNavMarketing.testId,
  [stepIds.TERMS]: xcSelectors.offeringSetupSideNavTerms.testId,
  [stepIds.DISCLAIMERS]: xcSelectors.offeringSetupSideNavDisclaimers.testId,
  [stepIds.SUMMARY]: xcSelectors.offeringSetupSideNavSummary.testId,
};
