import type { UpdateFundIoisInput } from '../../../../../../graphql';
import type { FundIoisValues } from './components/fund-iois-form/FundIoisForm';
import type { FundLevelDemand_FundIoisPartsFragment } from './graphql';

type Allocations = FundLevelDemand_FundIoisPartsFragment['allocations'];
type Iois = FundLevelDemand_FundIoisPartsFragment['iois'];

/**
 * Prepares the `input` arg for `updateFundIois`
 *
 * `allocation` items has to pass in their original `id` (if editing an existing item)
 * otherwise the entry is removed (even though theoretically only `fundId` is important).
 *
 * `iois` don't need that, hence no mapping of old/new items is done, meaning that each
 * time the old items of the edited `fundId` are replaced by the new (items get new ids).
 */
export const makeUpdateInput = (values: FundIoisValues, allocations: Allocations, iois: Iois) => {
  const allocationsInput = allocations.map(
    ({ __typename, ...allocation }) => allocation as UpdateFundIoisInput['allocations'][number]
  );
  const ioisInput = iois.map(
    ({ __typename, ...ioi }) => ioi as UpdateFundIoisInput['iois'][number]
  );

  return {
    allocations: allocationsInput
      .filter(({ fundId }) => fundId !== values.fundId)
      .concat([
        {
          ...allocationsInput.find(({ fundId }) => fundId === values.fundId),
          fundId: values.fundId,
          ...values.allocation,
        },
      ]),
    iois: ioisInput
      .filter(({ fundId }) => fundId !== values.fundId)
      .concat(values.interestLevels.map(level => ({ fundId: values.fundId, ...level }))),
  };
};

export const makeDeleteInput = (deletedFundId: string, allocations: Allocations, iois: Iois) => ({
  allocations: allocations
    .map(({ __typename, ...allocation }) => allocation)
    .filter(({ fundId }) => fundId !== deletedFundId),
  iois: iois.map(({ __typename, ...ioi }) => ioi).filter(({ fundId }) => fundId !== deletedFundId),
});
