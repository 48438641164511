/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationVariables = Types.Exact<{
  input: Types.CreateInvestorFirmBasicInput;
}>;

export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation = {
  readonly __typename?: 'Mutation';
  readonly createInvestorFirmBasic:
    | {
        readonly __typename?: 'InvestorFirmBasic';
        readonly id: string;
        readonly firmKey?: string | null;
        readonly cmgEntityKey?: string | null;
        readonly name: string;
        readonly firmType: Types.RolodexFirmCategoryType;
        readonly lei?: string | null;
        readonly crd?: string | null;
        readonly cik1?: string | null;
        readonly cik2?: string | null;
        readonly address1?: string | null;
        readonly address2?: string | null;
        readonly website1?: string | null;
        readonly website2?: string | null;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const InvestorFirmSearch_Advanced_CreateInvestorFirmBasicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvestorFirmSearch_Advanced_CreateInvestorFirmBasic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInvestorFirmBasicInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInvestorFirmBasic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvestorFirmSearch_Advanced_InvestorBasicParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmSearch_Advanced_InvestorBasicParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website2' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationFn = Apollo.MutationFunction<
  InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation,
  InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationVariables
>;

/**
 * __useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation__
 *
 * To run a mutation, you first call `useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorFirmSearchAdvancedCreateInvestorFirmBasicMutation, { data, loading, error }] = useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation,
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation,
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationVariables
  >(InvestorFirmSearch_Advanced_CreateInvestorFirmBasicDocument, options);
}
export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationHookResult = ReturnType<
  typeof useInvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation
>;
export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationResult =
  Apollo.MutationResult<InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation>;
export type InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationOptions =
  Apollo.BaseMutationOptions<
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutation,
    InvestorFirmSearch_Advanced_CreateInvestorFirmBasicMutationVariables
  >;
