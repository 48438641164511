import type { MutationTuple } from '@apollo/client/react/types/types';
import { DeepWritable, UUID } from '@cmg/common';
import groupBy from 'lodash/groupBy';

import { PercentagePerCmgEntityInput, SharesPerCmgEntityInput } from '../../../graphql';
import type { ExcludeNonArrayFields } from '../../../types/util';
import {
  OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
  OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables,
  OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
  OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables,
  OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
  OfferingSetup_ManagerEconomics_PercentagePerCmgEntityFragment,
  OfferingSetup_ManagerEconomics_SharesPerCmgEntityFragment,
  OfferingSetup_ManagerEconomics_SyndicateQuery,
  OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables,
} from '../graphql';
import { ManagerEconomicsBreakdownFormikContext } from './components/economics-breakdown-grid/EconomicBreakdownGrid';
import { ManagerEconomicBreakdownRow } from './components/economics-breakdown-grid/EconomicBreakdownGrid.model';
import { EconomicBreakdownGridColumnOrder } from './components/economics-breakdown-grid/EconomicBreakdownGridColumns';

// for unknown reason the original UpdateManagerEconomicsMutationVariables's fields
// are generated as union of array and non-array values even though the mutation is
// set to accept only arrays
type UpdateManagerEconomicsMutationVariables = ExcludeNonArrayFields<
  OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables,
  'offeringId'
>;

function findByManagerIdPredicate(cmgEntityKey: string) {
  return (item: { cmgEntityKey: string }) => item.cmgEntityKey === cmgEntityKey;
}

function getManagerPercentage(
  items:
    | readonly OfferingSetup_ManagerEconomics_PercentagePerCmgEntityFragment[]
    | readonly PercentagePerCmgEntityInput[]
    | undefined,
  cmgEntityKey: string
) {
  if (items === undefined) {
    return null;
  }
  return items.find(findByManagerIdPredicate(cmgEntityKey))?.percentageValue ?? null;
}

function getManagerShares(
  items:
    | readonly OfferingSetup_ManagerEconomics_SharesPerCmgEntityFragment[]
    | readonly SharesPerCmgEntityInput[]
    | undefined,
  cmgEntityKey: string
) {
  if (items === undefined) {
    return null;
  }
  return items.find(findByManagerIdPredicate(cmgEntityKey))?.sharesValue ?? null;
}

export const hasNonNullValueInColumn = (
  columns: (keyof ManagerEconomicBreakdownRow)[],
  rows: ManagerEconomicsBreakdownFormikContext['rows']
): boolean => {
  const rowsArray = Object.values(rows);

  return columns.some(column => rowsArray.some(row => row[column] !== null));
};

export function createEconomicBreakdownVariables(
  offeringId: string,
  rows: Record<number, ManagerEconomicBreakdownRow>
): UpdateManagerEconomicsMutationVariables {
  return Object.values(rows).reduce<DeepWritable<UpdateManagerEconomicsMutationVariables>>(
    (prev, curr) => {
      if (curr.incentiveFee !== null) {
        prev.incentiveFeeDistribution.push({
          cmgEntityKey: curr.id,
          percentageValue: curr.incentiveFee,
        });
      }
      if (curr.managementFee !== null) {
        prev.managementFeeDistribution.push({
          cmgEntityKey: curr.id,
          percentageValue: curr.managementFee,
        });
      }
      if (curr.underwritingFee !== null) {
        prev.underwritingFeeBaseDistribution.push({
          cmgEntityKey: curr.id,
          percentageValue: curr.underwritingFee,
        });
      }
      if (curr.sellingConcession !== null) {
        prev.sellingConcessionDistribution.push({
          cmgEntityKey: curr.id,
          percentageValue: curr.sellingConcession,
        });
      }
      if (curr.underwritingWithIncentiveFee !== null) {
        prev.underwritingFeeTotalDistribution.push({
          cmgEntityKey: curr.id,
          percentageValue: curr.underwritingWithIncentiveFee,
        });
      }
      if (curr.underwritingShares !== null) {
        prev.underwritingBaseSharesDistribution.push({
          cmgEntityKey: curr.id,
          sharesValue: curr.underwritingShares,
        });
      }
      if (curr.exercisedOverallotmentShares !== null) {
        prev.overallotmentExercisedSharesDistribution.push({
          cmgEntityKey: curr.id,
          sharesValue: curr.exercisedOverallotmentShares,
        });
      }
      return prev;
    },
    {
      offeringId,
      incentiveFeeDistribution: [],
      managementFeeDistribution: [],
      overallotmentExercisedSharesDistribution: [],
      sellingConcessionDistribution: [],
      underwritingBaseSharesDistribution: [],
      underwritingFeeBaseDistribution: [],
      underwritingFeeTotalDistribution: [],
    }
  );
}

export function createEconomicBreakdownRows(
  managers: OfferingSetup_ManagerEconomics_SyndicateQuery['offering']['syndicate']['managers'],
  managerEconomics?:
    | OfferingSetup_ManagerEconomics_ManagerEconomicsQuery['offering']['syndicate']['managerEconomics']
    | UpdateManagerEconomicsMutationVariables
): Record<number, ManagerEconomicBreakdownRow> {
  return managers
    .filter(item => item.isParticipating)
    .map(manager => {
      const incentiveFee = getManagerPercentage(
        managerEconomics?.incentiveFeeDistribution,
        manager.cmgEntityKey
      );
      const managementFee = getManagerPercentage(
        managerEconomics?.managementFeeDistribution,
        manager.cmgEntityKey
      );
      const exercisedOverallotmentShares = getManagerShares(
        managerEconomics?.overallotmentExercisedSharesDistribution,
        manager.cmgEntityKey
      );
      const sellingConcession = getManagerPercentage(
        managerEconomics?.sellingConcessionDistribution,
        manager.cmgEntityKey
      );
      const underwritingShares = getManagerShares(
        managerEconomics?.underwritingBaseSharesDistribution,
        manager.cmgEntityKey
      );
      const underwritingFee = getManagerPercentage(
        managerEconomics?.underwritingFeeBaseDistribution,
        manager.cmgEntityKey
      );
      const underwritingWithIncentiveFee = getManagerPercentage(
        managerEconomics?.underwritingFeeTotalDistribution,
        manager.cmgEntityKey
      );

      return {
        id: manager.cmgEntityKey,
        manager: manager.firmName,
        managerRole: manager.role,
        exercisedOverallotmentShares,
        incentiveFee,
        managementFee,
        sellingConcession,
        underwritingFee,
        underwritingShares,
        underwritingWithIncentiveFee,
        totalUnderwritingShares: calculateTotalUnderwritingShares(
          underwritingShares,
          exercisedOverallotmentShares
        ),
      };
    })
    .reduce((acc, next) => {
      return {
        ...acc,
        [next.id]: next,
      };
    }, {});
}

export function calculateTotalUnderwritingShares(
  underwritingShares: number | null,
  exercisedOverallotmentShares: number | null
) {
  return underwritingShares !== null && exercisedOverallotmentShares !== null
    ? underwritingShares + exercisedOverallotmentShares
    : null;
}

export const fieldNameGridColumnMap = {
  managementFeeDistribution: EconomicBreakdownGridColumnOrder.MANAGEMENT_FEE,
  underwritingFeeTotalDistribution:
    EconomicBreakdownGridColumnOrder.UNDERWRITING_WITH_INCENTIVE_FEE,
  sellingConcessionDistribution: EconomicBreakdownGridColumnOrder.SELLING_CONCESSION,
};

const getColumnPercentageValues = ({
  rows,
  keyName,
}: {
  rows: ManagerEconomicBreakdownRow[];
  keyName: string;
}) => {
  return rows.map(row => ({
    cmgEntityKey: row.id,
    percentageValue: row[keyName] ?? 0,
  }));
};

export type handleCalculateFeesDistributionModelArgs = {
  values: { rows: Record<number, ManagerEconomicBreakdownRow> };
  calculateFeesDistribution: MutationTuple<
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables
  >[0];
  offeringId: UUID;
  calculateSharesDistribution: MutationTuple<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >[0];
};

export type calculatedValuesType = Record<UUID, cellValueType[]>;

export type cellValueType = {
  __typename: string;
  cmgEntityKey: UUID;
  gridColumn: string;
  id: string;
  percentageValue?: number;
  sharesValue?: number;
};

/**
 * TODO: It makes no sense to keep this function as a handler, it should be a hook directly depending on the mutations instead.
 */
export const handleCalculateFeesDistributionModel = async ({
  values,
  calculateFeesDistribution,
  offeringId,
  calculateSharesDistribution,
}: handleCalculateFeesDistributionModelArgs): Promise<
  { error?: unknown } | calculatedValuesType
> => {
  try {
    const rows = Object.values(values.rows);
    const incentiveFeePercentages = getColumnPercentageValues({ rows, keyName: 'incentiveFee' });

    const underwritingFeePercentages = getColumnPercentageValues({
      rows,
      keyName: 'underwritingFee',
    });

    const { data } = await calculateFeesDistribution({
      variables: {
        offeringId,
        input: {
          incentiveFeePercentagesOverride: incentiveFeePercentages,
          underwritingFeePercentagesOverride: underwritingFeePercentages,
        },
      },
    });

    const underwritingWithIncentiveFee =
      data?.calculateFeesDistributions.underwritingFeeTotalDistribution.map(
        underwritingFeeDistribution => {
          return {
            cmgEntityKey: underwritingFeeDistribution.cmgEntityKey,
            percentageValue: underwritingFeeDistribution.percentageValue,
          };
        }
      );

    const { data: sharesData } = await calculateSharesDistribution({
      variables: {
        offeringId,
        input: { underwritingFeeTotalDistributionOverride: underwritingWithIncentiveFee },
      },
    });

    const feeDistributions = [
      ...(data?.calculateFeesDistributions.managementFeeDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.MANAGEMENT_FEE,
      })) ?? []),
      ...(data?.calculateFeesDistributions.underwritingFeeTotalDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.UNDERWRITING_WITH_INCENTIVE_FEE,
      })) ?? []),
      ...(data?.calculateFeesDistributions.sellingConcessionDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.SELLING_CONCESSION,
      })) ?? []),
      ...(sharesData?.calculateSharesDistributions.underwritingBaseShareDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES,
      })) ?? []),
    ];

    return groupBy(feeDistributions, 'cmgEntityKey');
  } catch (error) {
    return { error };
  }
};

export type handleCalculateSharesDistributionModelArgs = {
  values: { rows: Record<number, ManagerEconomicBreakdownRow> };
  calculateSharesDistribution: MutationTuple<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >[0];
  offeringId: UUID;
};

/**
 * TODO: It makes no sense to keep this function as a handler, it should be a hook directly depending on the mutations instead.
 */
export const handleCalculateOverallotmentSharesDistributionModel = async ({
  values,
  calculateSharesDistribution,
  offeringId,
}: handleCalculateSharesDistributionModelArgs): Promise<
  { error?: unknown } | calculatedValuesType
> => {
  try {
    const rows = Object.values(values.rows);
    const underwritingWithIncentiveFee = getColumnPercentageValues({
      rows,
      keyName: 'underwritingWithIncentiveFee',
    });
    const { data } = await calculateSharesDistribution({
      variables: {
        offeringId,
        input: { underwritingFeeTotalDistributionOverride: underwritingWithIncentiveFee },
      },
    });

    const feeDistributions = [
      ...(data?.calculateSharesDistributions.overallotmentExercisedShareDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.EXERCISED_OVERALLOTMENT_SHARES,
      })) ?? []),
      ...(data?.calculateSharesDistributions.underwritingBaseShareDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.UNDERWRITING_SHARES,
      })) ?? []),
      ...(data?.calculateSharesDistributions.underwritingTotalSharesDistribution.map(fee => ({
        ...fee,
        gridColumn: EconomicBreakdownGridColumnOrder.TOTAL_UNDERWRITING_SHARES,
      })) ?? []),
    ];

    return groupBy(feeDistributions, 'cmgEntityKey');
  } catch (error) {
    return { error };
  }
};
