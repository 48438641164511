import { FormField } from '@cmg/common';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { FilingStatus } from '../../../../../graphql';
import { FinraFilingStatusAlert } from '../../../common/content/alerts/FinraFilingStatusAlert';
import FilingCommonFieldsDetail from '../../../common/content/filing-common-fields-detail/FilingCommonFieldsDetail';
import FilingDetails from '../../../common/content/filing-details/FilingDetails';
import FilingDetailsHeader from '../../../common/content/filing-details-header/FilingDetailsHeader';
import FinraErrorResponseBanner from '../../../common/content/finra-error-response/FinraErrorResponseBanner';
import { filingNames } from '../../../common/filing-name-utils';
import { getLabelFromOptions } from '../../../common/form-utils';
import { dealStatusOptions } from '../form/DSFilingForm.model';
import { RegulatoryFilings_DsFilingPartsFragment } from '../graphql';

export type Props = {
  filing: RegulatoryFilings_DsFilingPartsFragment;
  version: number;
};

const DSDetail: React.FC<Props> = ({ filing, version }) => {
  return (
    <div>
      <FinraFilingStatusAlert status={filing.status} />
      <FilingDetailsHeader
        version={version}
        firmDealId={filing.firmDealId}
        formUniqueId={filing.formUniqueId}
        status={filing.status}
        submittedDateTime={filing.submittedDateTime}
      />
      {filing.status === FilingStatus.Error && filing.finraErrorMessages && (
        <FinraErrorResponseBanner errors={filing.finraErrorMessages} />
      )}
      <FilingCommonFieldsDetail detail={filing} />
      <FilingDetails issuerName={filing.issuerName} issuerSymbol={filing.issuerSymbol}>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label={filingNames.DS.full}>
              <div>{getLabelFromOptions(filing.dealStatus, dealStatusOptions)}</div>
            </FormField>
          </Column>
        </GridLayout>
      </FilingDetails>
    </div>
  );
};

export default DSDetail;
