import { envVarUtil, urlUtil } from '@cmg/common';

const {
  getOrThrow,
  parseBoolOrThrow,
  parseFloatOrThrow,
  getSessionEnvOverrides,
  getLocalStorageEnvOverrides,
} = envVarUtil;

// Environment variables provided by create-react-app build time variable injection.
// Exported for testing purposes
export const buildTimeEnvVars = {
  NODE_ENV: process.env.NODE_ENV, // set based on react-scripts (yarn start vs yarn test vs yarn build)
  REACT_APP_CLUSTER_ENVIRONMENT: process.env.REACT_APP_CLUSTER_ENVIRONMENT,
  REACT_APP_ROOT_DOMAIN: process.env.REACT_APP_ROOT_DOMAIN,
  REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_TRACING_ORIGIN: process.env.REACT_APP_DATADOG_TRACING_ORIGIN,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_AG_GRID_LICENSE_KEY: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
  REACT_APP_OIDC_AUTHORITY_BASE_URL: process.env.REACT_APP_OIDC_AUTHORITY_BASE_URL,
  REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES:
    process.env.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
  REACT_APP_ROADSHOWS_ON: process.env.REACT_APP_ROADSHOWS_ON,
  REACT_APP_ROADSHOWS_DEAL_TEAMS_OVERVIEW_ON:
    process.env.REACT_APP_ROADSHOWS_DEAL_TEAMS_OVERVIEW_ON,
  REACT_APP_IDENTITY_SPA_BASE_URL: process.env.REACT_APP_IDENTITY_SPA_BASE_URL,
  REACT_APP_IDENTITY_SERVICE_BASE_URL: process.env.REACT_APP_IDENTITY_SERVICE_BASE_URL,
  REACT_APP_ENABLE_DRAFT_ALLOCATION: process.env.REACT_APP_ENABLE_DRAFT_ALLOCATION,
  REACT_APP_DL_OFFERING_IDS_PARITY: process.env.REACT_APP_DL_OFFERING_IDS_PARITY,
  REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID: process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID,
  REACT_APP_HUBSPOT_SUPPORT_FORM_ID: process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_ID,
  REACT_APP_SIGNAL_LOG_LEVEL: process.env.REACT_APP_SIGNAL_LOG_LEVEL,
  REACT_APP_IS_OFFERING_SETUP_NEW_FLOW_ON: process.env.REACT_APP_IS_OFFERING_SETUP_NEW_FLOW_ON,
  REACT_APP_IS_ORDER_BOOK_DATA_REFRESH_ON: process.env.REACT_APP_IS_ORDER_BOOK_DATA_REFRESH_ON,
  REACT_APP_IS_ORDER_BOOK_DUPES_ON: process.env.REACT_APP_IS_ORDER_BOOK_DUPES_ON,
  REACT_APP_DISPLAY_INTERNATIONAL_OFFERING_REGION_COUNTRY_DETAILS:
    process.env.REACT_APP_DISPLAY_INTERNATIONAL_OFFERING_REGION_COUNTRY_DETAILS,
  REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL:
    process.env.REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL,
  REACT_APP_ATTESTATION_COPY_LINK_URL: process.env.REACT_APP_ATTESTATION_COPY_LINK_URL,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT:
    process.env
      .REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__BUY_SIDE__INDICATION_ACKNOWLEDGEMENT:
    process.env
      .REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__BUY_SIDE__INDICATION_ACKNOWLEDGEMENT,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_BILLING_AND_DELIVERY_MANAGER:
    process.env
      .REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_BILLING_AND_DELIVERY_MANAGER,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__ATTESTATION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__ATTESTATION,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__BUY_SIDE__INVESTOR_REPLY:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__BUY_SIDE__INVESTOR_REPLY,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__DYNAMIC_COLUMNS:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__DYNAMIC_COLUMNS,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__OPTIMIZATION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__OPTIMIZATION,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__SUBSCRIPTION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__SUBSCRIPTION,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__OPTIMIZATION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__OPTIMIZATION,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__SUBSCRIPTION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__SUBSCRIPTION,
  REACT_APP_BUILD_CLIENT_APP_VERSION: process.env.REACT_APP_BUILD_CLIENT_APP_VERSION,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__PINNED_COLUMNS:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__PINNED_COLUMNS,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MANAGE_DUPLICATE:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MANAGE_DUPLICATE,
  REACT_APP__ENABLE_FEATURE__CALENDAR__SUPERSEDING_OFFERINGS:
    process.env.REACT_APP__ENABLE_FEATURE__CALENDAR__SUPERSEDING_OFFERINGS,
  REACT_APP__ENABLE_FEATURE__OS_INTERNATIONAL_MILESTONE_1_PHASE_1:
    process.env.REACT_APP__ENABLE_FEATURE__OS_INTERNATIONAL_MILESTONE_1_PHASE_1,
  REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__NON_US_ON_PLATFORM_OFFERINGS_PHASE_1:
    process.env.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__NON_US_ON_PLATFORM_OFFERINGS_PHASE_1,
  REACT_APP__ENABLE_FEATURE__MULTIPLE_UNDERWRITER_ENTITY_SUPPORT:
    process.env.REACT_APP__ENABLE_FEATURE__MULTIPLE_UNDERWRITER_ENTITY_SUPPORT,
  REACT_APP__ENABLE_FEATURE__CONVERTS_UI: process.env.REACT_APP__ENABLE_FEATURE__CONVERTS_UI,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INSTITUTIONAL_FINAL_ALLOCATION_UNACKNOWLEDGE:
    process.env
      .REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INSTITUTIONAL_FINAL_ALLOCATION_UNACKNOWLEDGE,
  REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY:
    process.env.REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY,
  REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW:
    process.env.REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW,
  REACT_APP_GRAPHQL_SUBSCRIPTIONS_LOGEVENTS: process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_LOGEVENTS,
  REACT_APP_GRAPHQL_SUBSCRIPTIONS_REFETCH_BACKOFF:
    process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_REFETCH_BACKOFF,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_STATE_TRANSITION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_STATE_TRANSITION,
  REACT_APP__ENABLE_FEATURE__PUSH_NOTIFICATIONS:
    process.env.REACT_APP__ENABLE_FEATURE__PUSH_NOTIFICATIONS,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID_V2_BUTTON:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID_V2_BUTTON,
  REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INDICATION_ACTIVITY__SUBSCRIPTION:
    process.env.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INDICATION_ACTIVITY__SUBSCRIPTION,
  REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__UNDERWRITING_FEES_DOLLAR_VALUES:
    process.env.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__UNDERWRITING_FEES_DOLLAR_VALUES,
  REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MERGE_DISCOUNTS_FEES_WITH_MANAGER_ECONOMICS:
    process.env
      .REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MERGE_DISCOUNTS_FEES_WITH_MANAGER_ECONOMICS,
  REACT_APP__ENABLE_FEATURE__CALENDAR__USING_OFFERING_FULL:
    process.env.REACT_APP__ENABLE_FEATURE__CALENDAR__USING_OFFERING_FULL,
  REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_OFFERINGS_OFFERING_FULL_GQL:
    process.env.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_OFFERINGS_OFFERING_FULL_GQL,
  REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_ORDERS_OFFERING_FULL_GQL:
    process.env.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_ORDERS_OFFERING_FULL_GQL,
};

// Merge runtime variables provided by runtime-env.js which is generated in the docker entrypoint.
// Runtime variables take precedence.
const mergedVars = {
  ...buildTimeEnvVars,
  ...globalThis.env,
  ...getLocalStorageEnvOverrides(),
  ...getSessionEnvOverrides(),
};

const appSettings = {
  env: {
    clusterEnvironment: getOrThrow(mergedVars.REACT_APP_CLUSTER_ENVIRONMENT),
    rootDomain: getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN),
    secureCookies: !urlUtil.isLocalhost(getOrThrow(mergedVars.REACT_APP_ROOT_DOMAIN)),
    isDevelopment: mergedVars.NODE_ENV === 'development',
    identitySpaBaseUrl: getOrThrow(mergedVars.REACT_APP_IDENTITY_SPA_BASE_URL),
    identityServiceBaseUrl: getOrThrow(mergedVars.REACT_APP_IDENTITY_SERVICE_BASE_URL),
    hubspotSupportFormPortalId: mergedVars.REACT_APP_HUBSPOT_SUPPORT_FORM_PORTAL_ID,
    hubspotSupportFormId: mergedVars.REACT_APP_HUBSPOT_SUPPORT_FORM_ID,
    attestationCopyLinkUrl: mergedVars.REACT_APP_ATTESTATION_COPY_LINK_URL,
  },
  features: {
    isRoadshowsOn: parseBoolOrThrow(mergedVars.REACT_APP_ROADSHOWS_ON, false),
    isRoadshowsDealTeamsOverviewOn: parseBoolOrThrow(
      mergedVars.REACT_APP_ROADSHOWS_DEAL_TEAMS_OVERVIEW_ON,
      false
    ),
    isDraftAllocationOn: mergedVars.REACT_APP_ENABLE_DRAFT_ALLOCATION === 'true',
    isDLOfferingIdsParity: parseBoolOrThrow(mergedVars.REACT_APP_DL_OFFERING_IDS_PARITY, false),
    isOfferingSetupNewFlowOn: parseBoolOrThrow(
      mergedVars.REACT_APP_IS_OFFERING_SETUP_NEW_FLOW_ON,
      false
    ),
    isOrderBookDataRefreshOn: parseBoolOrThrow(
      mergedVars.REACT_APP_IS_ORDER_BOOK_DATA_REFRESH_ON,
      false
    ),
    isOrderBookDupesOn: parseBoolOrThrow(mergedVars.REACT_APP_IS_ORDER_BOOK_DUPES_ON, false),
    isInternationalDisplayOn: parseBoolOrThrow(
      mergedVars.REACT_APP_DISPLAY_INTERNATIONAL_OFFERING_REGION_COUNTRY_DETAILS,
      false
    ),
    isInternationalOfferingsInDL: parseBoolOrThrow(
      mergedVars.REACT_APP_ENABLE_INTERNATIONAL_OFFERINGS_IN_DL,
      false
    ),
    isOrderBookSellSideIndicationAcknowledgementOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT,
      false
    ),
    isOrderBookSidePanelSellSideIndicationAcknowledgementOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__SELL_SIDE__INDICATION_ACKNOWLEDGEMENT,
      false
    ),
    isOrderBookSidePanelBuySideIndicationAcknowledgementOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__BUY_SIDE__INDICATION_ACKNOWLEDGEMENT,
      false
    ),
    isOrderBookSidePanelIndicationBillingAndDeliveryManagerOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_BILLING_AND_DELIVERY_MANAGER,
      false
    ),
    isOrderBookAttestationOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__ATTESTATION,
      false
    ),
    isOrderBookBuySideInvestorReplyOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__BUY_SIDE__INVESTOR_REPLY,
      false
    ),
    isOrderBookDynamicColumnsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__DYNAMIC_COLUMNS,
      false
    ),
    isOrderBookGridOptimizationOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__OPTIMIZATION,
      false
    ),
    isOrderBookInstitutionalDemandGridSubscriptionOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID__SUBSCRIPTION,
      false
    ),
    isOrderBookMyOrderGridOptimizationOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__OPTIMIZATION,
      false
    ),
    isOrderBookMyOrderGridSubscriptionOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MY_ORDERS_GRID__SUBSCRIPTION,
      false
    ),
    isOrderBookPinnedColumnsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__PINNED_COLUMNS,
      false
    ),
    isOrderBookManageDuplicateOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__MANAGE_DUPLICATE,
      false
    ),
    isOrderBookSidePanelInstitutionalFinalAllocationUnacknowledgeOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INSTITUTIONAL_FINAL_ALLOCATION_UNACKNOWLEDGE,
      false
    ),
    isOrderBookSidePanelIndicationStateTransitionOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__SIDE_PANEL__INDICATION_STATE_TRANSITION,
      false
    ),
    isCalendarSupersedingOfferingsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CALENDAR__SUPERSEDING_OFFERINGS,
      false
    ),
    /**
     * Please see [Rollout ECM-29520](https://capitalmarketsgateway.atlassian.net/browse/ECM-29520)
     */
    isOsInternationalMilestone1Phase1On: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OS_INTERNATIONAL_MILESTONE_1_PHASE_1,
      false
    ),
    isEnableNonUsOnPlatformOfferingsPhase1On: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__NON_US_ON_PLATFORM_OFFERINGS_PHASE_1,
      false
    ),
    isMultipleUnderwriterEntitySupportOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__MULTIPLE_UNDERWRITER_ENTITY_SUPPORT,
      false
    ),
    isConvertsUiOn: parseBoolOrThrow(mergedVars.REACT_APP__ENABLE_FEATURE__CONVERTS_UI, false),
    isCertificateLibraryOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CERTIFICATE_LIBRARY,
      false
    ),
    isCertificateReviewOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CERTIFICATE_REVIEW,
      false
    ),
    isPushNotificationsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__PUSH_NOTIFICATIONS,
      false
    ),
    isOrderBookInstitutionalDemandGridV2ButtonOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INSTITUTIONAL_DEMAND_GRID_V2_BUTTON,
      false
    ),
    isOrderBookIndicationActivitySubscriptionOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__ORDER_BOOK__INDICATION_ACTIVITY__SUBSCRIPTION,
      false
    ),
    isOfferingSetupUnderwritingFeesDollarValuesOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__UNDERWRITING_FEES_DOLLAR_VALUES,
      false
    ),
    isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MERGE_DISCOUNTS_FEES_WITH_MANAGER_ECONOMICS,
      false
    ),
    isCalendarUsingOfferingFull: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__CALENDAR__USING_OFFERING_FULL,
      false
    ),
    isOfferingSetupMyOfferingsOfferingFullGql: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_OFFERINGS_OFFERING_FULL_GQL,
      false
    ),
    isOfferingSetupMyOrdersOfferingFullGql: parseBoolOrThrow(
      mergedVars.REACT_APP__ENABLE_FEATURE__OFFERING_SETUP__MY_ORDERS_OFFERING_FULL_GQL,
      false
    ),
  },
  client: {
    appName: 'cmg-xc-spa',
    appVersion: getOrThrow(mergedVars.REACT_APP_BUILD_CLIENT_APP_VERSION),
    basename: '/',
  },
  logging: {
    mixPanelToken: getOrThrow(mergedVars.REACT_APP_MIXPANEL_TOKEN, 'mock-token'),
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'applicationId'
    datadogApplicationId: mergedVars.REACT_APP_DATADOG_APPLICATION_ID,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'clientToken'
    datadogClientToken: mergedVars.REACT_APP_DATADOG_CLIENT_TOKEN,
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'allowedTracingOrigins' - single item of array
    datadogTracingOrigin: new RegExp(getOrThrow(mergedVars.REACT_APP_DATADOG_TRACING_ORIGIN)),
    // https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters - 'service'
    datadogServiceName: 'xc-client-web',
  },
  signalR: {
    signalRLogLevel: parseFloatOrThrow(mergedVars.REACT_APP_SIGNAL_LOG_LEVEL, 2),
  },
  auth: {
    clientId: 'cmg-app',
    oidcAuthorityBaseUrl: getOrThrow(mergedVars.REACT_APP_OIDC_AUTHORITY_BASE_URL),
    tokenRenewalIntervalInMinutes: parseFloatOrThrow(
      mergedVars.REACT_APP_AUTH_TOKEN_RENEWAL_INTERVAL_IN_MINUTES,
      30
    ),
    fullCalendarLicenseKey: '0449781451-fcs-1603377079',
    agGridLicenseKey: getOrThrow(mergedVars.REACT_APP_AG_GRID_LICENSE_KEY),
    scopes: {
      xc: 'xc:full',
      dl: 'dl:full',
      mgmt_api: 'mgmt_api:full',
    },
  },
  graphql: {
    subscriptions: {
      logEvents: parseBoolOrThrow(mergedVars.REACT_APP_GRAPHQL_SUBSCRIPTIONS_LOGEVENTS, false),
      refetch: {
        // The random amount of time that every refetch waits before issuing request to smooth BE load
        backoff: parseFloatOrThrow(
          mergedVars.REACT_APP_GRAPHQL_SUBSCRIPTIONS_REFETCH_BACKOFF,
          3000
        ),
      },
    },
  },
};

export type AppSettings = typeof appSettings;

export type FeatureToggles = Readonly<AppSettings['features']>;

export type FeatureToggleKeys = keyof FeatureToggles;

export function getAppSettings(): Readonly<AppSettings> {
  return appSettings;
}

export function getFeatureToggles() {
  return getAppSettings().features;
}

export function getEnvVars() {
  return getAppSettings().env;
}

export function getAuthSettings() {
  return getAppSettings().auth;
}

export function getGraphQLSettings() {
  return getAppSettings().graphql;
}
