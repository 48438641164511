import { LinkButton, PrimaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

/**
 * Save, Cancel, Edit, Delete buttons for handling form items
 */
export type Props = {
  editing?: boolean;
  disabled?: boolean;
  disableDelete?: boolean;
  disableSave?: boolean;
  loading?: boolean;
  onEdit?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
};

export const OfferingSetupFormActions: React.FC<Props> = ({
  editing,
  disabled,
  disableDelete,
  disableSave,
  loading,
  onEdit,
  onSubmit,
  onCancel,
  onDelete,
}) => (
  <React.Fragment>
    {editing ? (
      <React.Fragment>
        <PrimaryButton
          disabled={disabled || disableSave}
          loading={loading}
          onClick={() => onSubmit && onSubmit()}
          iconLeft={{ name: 'check' }}
          testId={xcSelectors.offeringSetupSaveButton.testId}
        >
          Save
        </PrimaryButton>
        {onCancel && (
          <LinkButton
            disabled={disabled}
            onClick={onCancel}
            testId={xcSelectors.offeringSetupCancelButton.testId}
          >
            Cancel
          </LinkButton>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {onEdit && (
          <LinkButton
            disabled={disabled}
            iconLeft={{ name: 'edit' }}
            onClick={() => onEdit && onEdit()}
            testId={xcSelectors.offeringSetupEditButton.testId}
          >
            Edit
          </LinkButton>
        )}
        {onDelete && (
          <LinkButton
            disabled={disabled || disableDelete}
            loading={loading}
            iconLeft={{ name: 'trash-alt' }}
            onClick={onDelete}
            testId={xcSelectors.offeringSetupDeleteButton.testId}
          >
            Delete
          </LinkButton>
        )}
      </React.Fragment>
    )}
  </React.Fragment>
);
