import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_SellingGroupTermsWiresDocument,
  SyndicateWires_SendSellingGroupTermsWireMutation,
  useSyndicateWires_SendSellingGroupTermsWireMutation,
} from '../graphql';

export type SendSellingGroupTermsWireMutation = SyndicateWires_SendSellingGroupTermsWireMutation;

export const refetchQueries = () => {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SellingGroupTermsWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
};

export const useSendSellingGroupTermsWireMutation = () => {
  return useSyndicateWires_SendSellingGroupTermsWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
