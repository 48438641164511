import { TableCell, TableHead, TableRow, useTheme } from '@cmg/design-system';
import React from 'react';

import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';

export type Props = Readonly<{
  sharesRequired?: boolean;
}>;

const TotalRetailDemandTableHeader: React.FC<Props> = ({ sharesRequired }) => {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: theme.spacing(6) }} />
        <TableCell sx={{ width: '20%', minWidth: theme.spacing(32) }}>Underwriter</TableCell>
        <TableCell sx={{ width: '12%', minWidth: theme.spacing(22) }}>Role</TableCell>
        <TableCell sx={{ width: '14%', minWidth: theme.spacing(22) }}>Submitted by</TableCell>
        <TableCell sx={{ width: '12%', minWidth: theme.spacing(22) }}>Last Modified On</TableCell>
        <TableCell sx={{ width: '14%', minWidth: theme.spacing(22) }} align="right">
          Demand (shares) {sharesRequired && <MandatoryAsterisk />}
        </TableCell>
        <TableCell sx={{ width: '14%', minWidth: theme.spacing(22) }} align="right">
          Round Lots
        </TableCell>
        <TableCell sx={{ width: '14%', minWidth: theme.spacing(22) }} align="right">
          Retention (shares) {sharesRequired && <MandatoryAsterisk />}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TotalRetailDemandTableHeader;
