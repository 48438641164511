import { numericUtil } from '@cmg/common';
import { Grid, Skeleton, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  isLoading: boolean;
  baseDeal?: number | null;
  overallotment?: number | null;
  totalOffering?: number | null;
}>;

const DealLayoutSharesBox: React.FC<Props> = ({
  isLoading,
  baseDeal,
  overallotment,
  totalOffering,
}) => {
  return (
    <Grid container rowGap={1} px={4}>
      <Grid item xs={6}>
        <Typography variant="highlight2">Base Deal</Typography>
      </Grid>
      <Grid item xs={6}>
        {isLoading && <Skeleton role="progressbar" />}
        {!isLoading && (
          <Typography variant="subtitle2" aria-label="Base deal shares">
            {numericUtil.getDisplayValueForInteger(baseDeal)} Shares
          </Typography>
        )}
      </Grid>

      <Grid item xs={6}>
        <Typography variant="highlight2">Overallotment</Typography>
      </Grid>
      <Grid item xs={6}>
        {isLoading && <Skeleton role="progressbar" />}
        {!isLoading && (
          <Typography variant="subtitle2" aria-label="Overallotment shares">
            {numericUtil.getDisplayValueForInteger(overallotment)} Shares
          </Typography>
        )}
      </Grid>

      <Grid item xs={6}>
        <Typography variant="highlight2">Total Offering</Typography>
      </Grid>
      <Grid item xs={6}>
        {isLoading && <Skeleton role="progressbar" />}
        {!isLoading && (
          <Typography variant="subtitle2" aria-label="Total offering shares">
            {numericUtil.getDisplayValueForInteger(totalOffering)} Shares
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default DealLayoutSharesBox;
