import {
  SyndicateWires_UpdatePricingTermsRetentionWireMutation,
  useSyndicateWires_UpdatePricingTermsRetentionWireMutation,
} from '../graphql';

export type UpdatePricingTermsRetentionWireMutation =
  SyndicateWires_UpdatePricingTermsRetentionWireMutation;

export const useUpdatePricingTermsRetentionWireMutation = () => {
  return useSyndicateWires_UpdatePricingTermsRetentionWireMutation();
};
