/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_AssignDesignationToManagerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.OverwriteDesignationInput;
}>;

export type FinalSettlement_AssignDesignationToManagerMutation = {
  readonly __typename?: 'Mutation';
  readonly assignDesignationToManager: {
    readonly __typename?: 'DesignationsGrid';
    readonly id: string;
    readonly designations: ReadonlyArray<{
      readonly __typename?: 'DesignationsGridItem';
      readonly id: string;
      readonly version?: string | null;
      readonly isPublished?: boolean | null;
      readonly warnings: ReadonlyArray<Types.DesignationWarning>;
      readonly allocation: {
        readonly __typename?: 'DesignationsGridAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shares: number;
        readonly investorName: string;
        readonly investorCmgEntityKey: string;
      };
      readonly designations: ReadonlyArray<{
        readonly __typename?: 'DesignationsGridDesignation';
        readonly id: string;
        readonly managerCmgEntityKey: string;
        readonly shares?: number | null;
        readonly fixedEconomicsShares: number;
        readonly sellingConcessionPercentageChanged: boolean;
      }>;
    }>;
    readonly syndicateManagers: ReadonlyArray<{
      readonly __typename?: 'DesignationsGridManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
    }>;
  };
};

export type FinalSettlement_DesignationValidationPartsFragment = {
  readonly __typename?: 'DesignationValidation';
  readonly areFinalAllocationsReleased: boolean;
};

export type FinalSettlement_PublishDesignationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
}>;

export type FinalSettlement_PublishDesignationsMutation = {
  readonly __typename?: 'Mutation';
  readonly publishDesignations: { readonly __typename: 'EmptyMutationResponse' };
};

export type FinalSettlement_SettlementDesignationsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_SettlementDesignationsQuery = {
  readonly __typename?: 'Query';
  readonly designationsGrid: {
    readonly __typename?: 'DesignationsGrid';
    readonly id: string;
    readonly designations: ReadonlyArray<{
      readonly __typename?: 'DesignationsGridItem';
      readonly id: string;
      readonly version?: string | null;
      readonly isPublished?: boolean | null;
      readonly warnings: ReadonlyArray<Types.DesignationWarning>;
      readonly allocation: {
        readonly __typename?: 'DesignationsGridAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shares: number;
        readonly investorName: string;
        readonly investorCmgEntityKey: string;
      };
      readonly designations: ReadonlyArray<{
        readonly __typename?: 'DesignationsGridDesignation';
        readonly id: string;
        readonly managerCmgEntityKey: string;
        readonly shares?: number | null;
        readonly fixedEconomicsShares: number;
        readonly sellingConcessionPercentageChanged: boolean;
      }>;
    }>;
    readonly syndicateManagers: ReadonlyArray<{
      readonly __typename?: 'DesignationsGridManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
    }>;
  };
  readonly designationValidation: {
    readonly __typename?: 'DesignationValidation';
    readonly areFinalAllocationsReleased: boolean;
  };
};

export const FinalSettlement_DesignationValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'areFinalAllocationsReleased' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_AssignDesignationToManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_AssignDesignationToManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OverwriteDesignationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignDesignationToManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGrid' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGrid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationsGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateManagers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FinalSettlement_DesignationsGridDesignationManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationsGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignation' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridDesignation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fixedEconomicsShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentageChanged' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignationManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_AssignDesignationToManagerMutationFn = Apollo.MutationFunction<
  FinalSettlement_AssignDesignationToManagerMutation,
  FinalSettlement_AssignDesignationToManagerMutationVariables
>;

/**
 * __useFinalSettlement_AssignDesignationToManagerMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_AssignDesignationToManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_AssignDesignationToManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementAssignDesignationToManagerMutation, { data, loading, error }] = useFinalSettlement_AssignDesignationToManagerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalSettlement_AssignDesignationToManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_AssignDesignationToManagerMutation,
    FinalSettlement_AssignDesignationToManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_AssignDesignationToManagerMutation,
    FinalSettlement_AssignDesignationToManagerMutationVariables
  >(FinalSettlement_AssignDesignationToManagerDocument, options);
}
export type FinalSettlement_AssignDesignationToManagerMutationHookResult = ReturnType<
  typeof useFinalSettlement_AssignDesignationToManagerMutation
>;
export type FinalSettlement_AssignDesignationToManagerMutationResult =
  Apollo.MutationResult<FinalSettlement_AssignDesignationToManagerMutation>;
export type FinalSettlement_AssignDesignationToManagerMutationOptions = Apollo.BaseMutationOptions<
  FinalSettlement_AssignDesignationToManagerMutation,
  FinalSettlement_AssignDesignationToManagerMutationVariables
>;
export const FinalSettlement_PublishDesignationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalSettlement_PublishDesignations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishDesignations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FinalSettlement_PublishDesignationsMutationFn = Apollo.MutationFunction<
  FinalSettlement_PublishDesignationsMutation,
  FinalSettlement_PublishDesignationsMutationVariables
>;

/**
 * __useFinalSettlement_PublishDesignationsMutation__
 *
 * To run a mutation, you first call `useFinalSettlement_PublishDesignationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_PublishDesignationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSettlementPublishDesignationsMutation, { data, loading, error }] = useFinalSettlement_PublishDesignationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_PublishDesignationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalSettlement_PublishDesignationsMutation,
    FinalSettlement_PublishDesignationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalSettlement_PublishDesignationsMutation,
    FinalSettlement_PublishDesignationsMutationVariables
  >(FinalSettlement_PublishDesignationsDocument, options);
}
export type FinalSettlement_PublishDesignationsMutationHookResult = ReturnType<
  typeof useFinalSettlement_PublishDesignationsMutation
>;
export type FinalSettlement_PublishDesignationsMutationResult =
  Apollo.MutationResult<FinalSettlement_PublishDesignationsMutation>;
export type FinalSettlement_PublishDesignationsMutationOptions = Apollo.BaseMutationOptions<
  FinalSettlement_PublishDesignationsMutation,
  FinalSettlement_PublishDesignationsMutationVariables
>;
export const FinalSettlement_SettlementDesignationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementDesignations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designationsGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGrid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designationValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'areFinalAllocationsReleased' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGrid' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationsGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateManagers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FinalSettlement_DesignationsGridDesignationManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationsGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignation' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridDesignation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fixedEconomicsShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentageChanged' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridDesignationManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_SettlementDesignationsQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_SettlementDesignationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_SettlementDesignationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_SettlementDesignationsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_SettlementDesignationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_SettlementDesignationsQuery,
    FinalSettlement_SettlementDesignationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_SettlementDesignationsQuery,
    FinalSettlement_SettlementDesignationsQueryVariables
  >(FinalSettlement_SettlementDesignationsDocument, options);
}
export function useFinalSettlement_SettlementDesignationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_SettlementDesignationsQuery,
    FinalSettlement_SettlementDesignationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_SettlementDesignationsQuery,
    FinalSettlement_SettlementDesignationsQueryVariables
  >(FinalSettlement_SettlementDesignationsDocument, options);
}
export type FinalSettlement_SettlementDesignationsQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementDesignationsQuery
>;
export type FinalSettlement_SettlementDesignationsLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementDesignationsLazyQuery
>;
export type FinalSettlement_SettlementDesignationsQueryResult = Apollo.QueryResult<
  FinalSettlement_SettlementDesignationsQuery,
  FinalSettlement_SettlementDesignationsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeFinalSettlement_SettlementDesignationsMockResponse = makeMockResponse<
  FinalSettlement_SettlementDesignationsQueryVariables,
  FinalSettlement_SettlementDesignationsQuery
>(FinalSettlement_SettlementDesignationsDocument);
