import { Skeleton, Stack, styled } from '@cmg/design-system';
import React, { FC } from 'react';

import { useBusyDelay } from '../../../../../../common/hooks';

const Layout = styled('div')({
  '> *': {
    '&:not(:last-child)': {
      marginBottom: '7px',
    },
  },
  padding: '16px',
});

type Props = {
  readonly isBusy?: boolean;
};

export const SidePanelNavBarSection: FC<Props> = props => {
  const { children, isBusy: loading } = props;
  const isBusy = useBusyDelay(loading ?? false, 750);

  return (
    <Layout>
      {isBusy ? (
        <Stack spacing={1} role="progressbar">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton variant="rectangular" height={118} />
        </Stack>
      ) : (
        children
      )}
    </Layout>
  );
};
