import { ODSTable as Table, ToastManager, UUID } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../../../common/components/layout/panel/Panel';
import { Roadshows_CompanyPartsFragment } from '../../../../../common/graphql';
import { useAssignCompanyMutation } from '../../hooks/useAssignCompanyMutation';
import { createAddress } from './CompanyDetailsSection.model';
import UnassignCompanyButton from './unassign-company-button/UnassignCompanyButton';

export type Props = { roadshowId: UUID; companyDetails: Roadshows_CompanyPartsFragment };

const CompanyDetailsSection: React.FC<Props> = ({ roadshowId, companyDetails }) => {
  const [unassignCompany, { loading: isUnassigning }] = useAssignCompanyMutation();

  const handleUnassignCompany = async () => {
    try {
      await unassignCompany({ variables: { cmgEntityKey: null, roadshowId } });

      ToastManager.success('Successfully unassigned Company');
    } catch {
      ToastManager.error('Failed to unassign Company');
    }
  };

  return (
    <Panel>
      <Panel.Header title="Company Details" />
      <Panel.Content paddingSize="S">
        <Table gridTemplateColumns="2fr 1fr 1fr 2fr 50px" data-test-id="roadshow-company-details">
          <Table.Row>
            <Table.TableHeaderCell>Company Name</Table.TableHeaderCell>
            <Table.TableHeaderCell>Industry Type</Table.TableHeaderCell>
            <Table.TableHeaderCell>Website</Table.TableHeaderCell>
            <Table.TableHeaderCell>Address</Table.TableHeaderCell>
            <Table.TableHeaderCell />
          </Table.Row>

          <Table.Row>
            <Table.Cell>{companyDetails.name}</Table.Cell>
            <Table.Cell>{companyDetails.industryTypes.join(', ') || '-'}</Table.Cell>
            <Table.Cell>{companyDetails.website || '-'}</Table.Cell>
            <Table.Cell>{createAddress(companyDetails.address)}</Table.Cell>
            <Table.Cell centered paddingSize="XS">
              <UnassignCompanyButton onClick={handleUnassignCompany} isLoading={isUnassigning} />
            </Table.Cell>
          </Table.Row>
        </Table>
      </Panel.Content>
    </Panel>
  );
};

export default CompanyDetailsSection;
