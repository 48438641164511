import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { WireTemplateType } from '../../../../../graphql';
import { createWireTypeConfig } from '../../common/context/WireTypeConfigContext.model';
import { FreeFormWireForm } from './FreeFormWireForm';
import { validationSchema, Values } from './FreeFormWireForm.model';
import {
  SyndicateWires_FreeFormWirePartsFragment,
  SyndicateWires_FreeFormWireValidationPartsFragment,
  useSyndicateWires_FreeFormWireDetailsQuery,
  useSyndicateWires_FreeFormWirePreviewQuery,
  useSyndicateWires_FreeFormWiresQuery,
  useSyndicateWires_FreeFormWireValidationQuery,
} from './graphql';
import { useCreateFreeFormWireMutation } from './hooks/useCreateFreeFormWireMutation';
import { useDeleteFreeFormWireMutation } from './hooks/useDeleteFreeFormWireMutation';
import { useSendFreeFormWireMutation } from './hooks/useSendFreeFormWireMutation';
import { useUpdateFreeFormWireMutation } from './hooks/useUpdateFreeFormWireMutation';

const wireTypeName = 'Free Form';

export const wireTypeConfigFreeForm = createWireTypeConfig<
  Values,
  SyndicateWires_FreeFormWirePartsFragment,
  SyndicateWires_FreeFormWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresFreeForm
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresFreeForm,
  wireTemplateType: WireTemplateType.FreeForm,
  CreateOrUpdateForm: FreeFormWireForm,
  formValidation: validationSchema,

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_FreeFormWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.freeFormWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_FreeFormWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.freeFormWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_FreeFormWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.freeFormWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_FreeFormWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return { data: data?.freeFormWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: values,
          },
        });

        if (result.data?.createFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: values },
        });

        if (result.data?.updateFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteFreeFormWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId }) => {
        const result = await deleteWire({ variables: { offeringId, syndicateWireId } });

        if (result.data?.deleteFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendFreeFormWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendFreeFormWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendFreeFormWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire }) => {
    const data = React.useMemo<Values>(() => {
      return {
        body: wire?.body ?? '',
        header: wire?.header ?? '',
      };
    }, [wire?.body, wire?.header]);

    return { isLoading: false, data };
  },
});
