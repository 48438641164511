import { formatters } from '@cmg/data-grid';

import { InstitutionalIndicationOrderType } from '../../../../../../graphql';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { TypeCellRenderer } from './TypeCellRenderer';

/**
 * Exported for testing purposes
 */
export const valueFormatter = getValueFormatter<InstitutionalIndicationOrderType>({
  gridRow: ({ data }) => data?.typeDisplayValue ?? '-',
});

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<InstitutionalIndicationOrderType>({
  gridRow: { component: TypeCellRenderer },
});

const typeColDef: DemandGridServerColDef<InstitutionalIndicationOrderType> = {
  field: 'type',
  headerName: 'IOI Type',
  width: 130,
  cellRendererSelector,
  valueFormatter,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(InstitutionalIndicationOrderType),
    valueFormatter: formatters.enumValueFormatter,
  },
  server: {
    sortFieldPath: 'typeDisplayValue',
  },
};

export default typeColDef;
