import * as yup from 'yup';

import { UNASSIGN_BND_AGENT_VALUE } from '../../demand-grid/DemandGrid.model';
import type { OrderBook_InstitutionalDemand_ManagerProfilePartsFragment } from '../../graphql/__generated__';

export type BulkAssignBndAgentValues = {
  bndAgentKey: string | null;
};

export const getOptions = (
  managers: readonly OrderBook_InstitutionalDemand_ManagerProfilePartsFragment[]
) => {
  const managerOptions = managers.map(manager => ({
    label: manager.firmName,
    value: manager.cmgEntityKey,
  }));

  return [...managerOptions, { label: 'Unassign', value: UNASSIGN_BND_AGENT_VALUE }];
};

export const validationSchema = yup.object().shape({
  bndAgentKey: yup.string().nullable().required().label('B&D Agent'),
});
