import { Select, TextInput } from '@cmg/common';
import styled from 'styled-components/macro';

export const SFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledSelect = styled(Select)`
  width: 216px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 290px;
`;
