import type { UUID } from '@cmg/common';
import { ListItem } from '@cmg/design-system';
import React from 'react';

import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import useCheckInstitutionalPassiveOrderBookAccess from '../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../graphql/__generated__';
import ListItemKebabMenu from '../kebab-menu/ListItemKebabMenu';
import AllocationSetListItemText from './AllocationSetListItemText';

export type Props = Readonly<{
  offeringId: UUID;
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment;
  onEditFinalAllocationSet: () => void;
  onShareFinalAllocationSet: () => void;
}>;

const FinalAllocationSetListItem: React.FC<Props> = ({
  allocationSet,
  offeringId,
  onEditFinalAllocationSet,
  onShareFinalAllocationSet,
}) => {
  const { canManage: canManageAllocation } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { canManage: canManagePassiveOrderBook } = useCheckInstitutionalPassiveOrderBookAccess({
    offeringId,
  });

  const canShare = !allocationSet.isReleased && canManageAllocation && !canManagePassiveOrderBook;

  const actions = React.useMemo(
    () => [
      ...(canManageAllocation
        ? [
            {
              label: 'Edit',
              onClick: onEditFinalAllocationSet,
            },
          ]
        : []),
      ...(canShare
        ? [
            {
              label: 'Share',
              onClick: onShareFinalAllocationSet,
            },
          ]
        : []),
    ],
    [canManageAllocation, canShare, onEditFinalAllocationSet, onShareFinalAllocationSet]
  );

  return (
    <ListItem
      disableGutters
      aria-label={`Final Allocation Set: ${allocationSet.name}`}
      secondaryAction={
        <ListItemKebabMenu actions={actions} aria-label="Toggle allocations dropdown" />
      }
    >
      <AllocationSetListItemText allocationSet={allocationSet} />
    </ListItem>
  );
};

export default FinalAllocationSetListItem;
