import { FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWrapper = styled(FlexContainer)`
  margin: 16px 24px;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin-right: 32px;
`;

export const SMainTitle = styled(STitle)`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
`;

export const SLabel = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
  font-size: ${({ theme }) => theme.text.size.tiny};
  text-transform: uppercase;
`;

export const SActionsLabel = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  padding-left: 20px;
`;
