import { Form, useFormikContext } from 'formik';
import React from 'react';

import DealTeamMembersPanel from '../../deal-team-details/deal-team-form/deal-team-members-panel/DealTeamMembersPanel';
import DepartmentsPanel from '../../deal-team-details/deal-team-form/departments-panel/DepartmentsPanel';
import { CreateDealTeamFormValues } from './CreateDealTeamForm.model';
import OrganizationPanel from './organization-panel/OrganizationPanel';

const CreateDealTeamForm: React.FC = () => {
  const { values } = useFormikContext<CreateDealTeamFormValues>();

  return (
    <Form>
      <OrganizationPanel />
      {values.organizationField && (
        <React.Fragment>
          <DepartmentsPanel />
          <DealTeamMembersPanel />
        </React.Fragment>
      )}
    </Form>
  );
};

export default CreateDealTeamForm;
