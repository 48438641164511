import { UUID } from '@cmg/common';
import React from 'react';

import { SyndicateWire, SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { useSyndicateWires_SendWireDialog_FirmDistributionListsQuery } from '../components/dialogs/send-wire-dialog/graphql';
import { transformManagersForSendDialog } from '../components/dialogs/send-wire-dialog/SendWireDialog.model';
import { ManagerForSendModal } from '../components/modals/send-wire-modal/SendWireModal.model';
import { useWireTypeConfigContext } from '../context/WireTypeConfigContext';
import { useManagersMissingEmail } from './useManagersMissingEmail';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly wire?: SyndicateWire;
  readonly managers: SyndicateWireManager[];
}>;

export const useSendWireManagers = ({ managers, wire, offeringId }: Props) => {
  const [recipients, setRecipients] = React.useState<ManagerForSendModal[]>([]);
  const { useOutdatedManagersList } = useWireTypeConfigContext();
  const missingEmailsManagerKeys = useManagersMissingEmail({ offeringId });
  const outdatedManagers = useOutdatedManagersList({ offeringId, syndicateWireId: wire?.id });

  const managerKeys = React.useMemo(() => {
    return managers.map(({ cmgEntityKey }) => cmgEntityKey);
  }, [managers]);

  const { data: distributionListsData } =
    useSyndicateWires_SendWireDialog_FirmDistributionListsQuery({
      variables: { offeringId, cmgEntityKeys: managerKeys },
      fetchPolicy: 'cache-and-network',
    });

  React.useEffect(() => {
    const distributionLists = distributionListsData?.syndicateWires.firmDistributionLists;

    setRecipients(
      transformManagersForSendDialog({
        managersOnWire: managers,
        outdatedManagers,
        wireMessages: wire?.wireMessages,
        managersWithMissingEmails: missingEmailsManagerKeys,
        distributionLists,
      })
    );
  }, [
    managers,
    outdatedManagers,
    wire?.wireMessages,
    distributionListsData?.syndicateWires.firmDistributionLists,
    missingEmailsManagerKeys,
  ]);

  return recipients;
};
