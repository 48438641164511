import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

import List from '../../lists/list/List';
import ListItem from '../../lists/list/ListItem';

export type Props = {
  url?: string;
  testId?: string;
  nestedItems?: React.ReactNodeArray;
  disabled?: boolean;
};

const NavItem: React.FC<Props> = ({ children, nestedItems, url, testId, disabled }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const hasNestedItems = nestedItems && nestedItems.length > 0;
  const isActive = !!matchPath(pathname, {
    path: url,
    exact: false,
    strict: false,
  });

  const handleClickListItem = () => {
    if (url && !isActive && !disabled) {
      push(url);
    }
  };

  return (
    <div data-test-id={testId}>
      <ListItem onClick={handleClickListItem} active={isActive} disabled={disabled}>
        {children}
      </ListItem>

      {hasNestedItems && isActive && <List>{nestedItems}</List>}
    </div>
  );
};

export default NavItem;
