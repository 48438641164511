import groupBy from 'lodash/groupBy';

import { sortCaseInsensitive } from '../../../../../../../common/util/sortCaseInsensitive';
import {
  SyndicateWiresManagerRole,
  WireMessageAndResponseStatus,
  WireStatus,
} from '../../../../../../../graphql';
import { SyndicateWire, SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { SyndicateWires_WireMessagePartsFragment } from '../../../graphql';
import { wireMessageStatusToWireStageType } from '../../wire-stage/sent-stage/SentStageWireTemplate.model';
import { WireStatusType } from '../../wire-status/WireStatus';
import * as thisModule from './ManagersNavigation.model';

export const roleOrder: ReadonlyArray<`${SyndicateWiresManagerRole}`> = Object.freeze([
  SyndicateWiresManagerRole.ActiveBookrunner,
  SyndicateWiresManagerRole.Bookrunner,
  SyndicateWiresManagerRole.CoLead,
  SyndicateWiresManagerRole.CoManager,
  SyndicateWiresManagerRole.Underwriter,
  SyndicateWiresManagerRole.SellingGroupMember,
]);

export const isManagerDisabled = (cmgEntityKey: string, wire: SyndicateWire | undefined) => {
  if (!wire) {
    return true;
  }

  if (wire.recipientsUserData) {
    return !wire.recipientsUserData.some(({ recipient }) => recipient === cmgEntityKey);
  }

  return false;
};

export const getWireMessageForManager = (
  managerId?: string,
  wireMessages?: readonly SyndicateWires_WireMessagePartsFragment[]
) => {
  return wireMessages?.find(message => message.firmSnapshot?.cmgEntityKey === managerId);
};

export const getStatus = ({
  wire,
  isRecipientRemoved,
  isNewestWire,
  cmgEntityKey,
}: {
  cmgEntityKey: string;
  isNewestWire: boolean;
  isRecipientRemoved: boolean;
  wire: SyndicateWire | undefined;
}): WireStatusType => {
  // Wire does not exist yet
  if (!wire || thisModule.isManagerDisabled(cmgEntityKey, wire)) {
    return 'empty';
  }

  // A draft exists
  if (wire.status === WireStatus.Draft) {
    return 'draft';
  }

  const wireMessageStatus = thisModule.getWireMessageForManager(
    cmgEntityKey,
    wire.wireMessages
  )?.wireMessageAndResponseStatus;

  if (
    wireMessageStatus === WireMessageAndResponseStatus.Obsolete &&
    isRecipientRemoved &&
    isNewestWire
  ) {
    return 'recipientRemoved';
  }

  return wireMessageStatus ? wireMessageStatusToWireStageType[wireMessageStatus] : 'empty';
};

/**
 * Sorts managers by their name and groups them by their role. Groups of managers are than sorted by the role order defined in ECM-12046.
 * Useful when rendering a list of managers grouped by roles.
 */
export function managersToRoleGroupedEntries<T extends SyndicateWireManager>(
  managers: T[]
): Array<[SyndicateWiresManagerRole, T[]]> {
  const sortedManagers = [...managers].sort((a, b) => sortCaseInsensitive(a.firmName, b.firmName));

  return (
    Object.entries(groupBy(sortedManagers, 'role')) as Array<[SyndicateWiresManagerRole, T[]]>
  ).sort(([A], [B]) => roleOrder.indexOf(A) - roleOrder.indexOf(B));
}
