import { useCheckAccountTraits } from '@cmg/auth';
import { ServiceErrorBanner, ToastManager, UUID } from '@cmg/common';
import { LoadingButton } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import routeFactory from '../../../common/util/routeFactory';
import {
  CreateOfferingInput,
  OfferingStage,
  OfferingType,
  UpdateBasicInfoInput,
} from '../../../graphql';
import {
  InternationalOfferingType,
  internationalToUsOfferingType,
} from '../../../types/domain/offering/constants';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import Panel from '../components/design-system/panel/Panel';
import { stepIds } from '../constants';
import {
  useOfferingSetup_BasicInfoRoute_BasicInfoQuery,
  useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
} from '../graphql';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import BasicInfoForm from './BasicInfoForm';
import { useCreateOfferingDraftMutation } from './hooks/useCreateOfferingDraftMutation';
import { useUpdateBasicInfoMutation } from './hooks/useUpdateBasicInfoMutation';

export type RouteProps = RouteComponentProps<{ offeringId?: UUID; stepId?: string }>;

export type ExtenedCreateOfferingInput = Omit<CreateOfferingInput, 'type'> & {
  type: InternationalOfferingType & OfferingType;
};

/**
 * Basic Info Route for Offering Setup
 * Creates an offering draft or updates info for an existing offering
 */
export const BasicInfoRoute: React.FC<RouteProps> = ({ match, history }) => {
  const { offeringId } = match.params;
  const hasCreateInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const { data, loading, error, refetch } = useOfferingSetup_BasicInfoRoute_BasicInfoQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: !offeringId,
    fetchPolicy: 'network-only',
  });
  const {
    data: publishedOfferingData,
    loading: publishedOfferingLoading,
    error: publishedOfferingError,
  } = useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: !offeringId || data?.offering.stage !== OfferingStage.Published,
  });

  const { revalidate } = useValidateOffering(offeringId);

  const [
    createOfferingDraft,
    {
      loading: creating,
      error: createError,
      called: created,
      serviceError: createOfferingDraftServiceError,
    },
  ] = useCreateOfferingDraftMutation();

  const [
    updateBasicInfo,
    { loading: updating, error: updateError, serviceError: updateBasicInfoServiceError },
  ] = useUpdateBasicInfoMutation();

  const handleUpdateBasicInfo = useCallback(
    async (values: UpdateBasicInfoInput, id: UUID) => {
      try {
        const { data: updateBasicInfoData } = await updateBasicInfo({
          variables: { offeringId: id, input: values },
        });

        if (updateBasicInfoData?.updateBasicInfo.__typename !== 'ServiceError') {
          ToastManager.success('Updated Offering Info');
          // re-run validation if necessary to resolve errors/warnings
          revalidate();
          refetch();
        }
      } catch {
        ToastManager.error('Failed to Update Offering Info');
        throw new Error('Failed to Update Offering Info');
      }
    },
    [refetch, revalidate, updateBasicInfo]
  );

  const handleCreateOfferingDraft = useCallback(
    async (values: ExtenedCreateOfferingInput) => {
      let { type } = values;
      const isInternationalOfferingType = Object.values(InternationalOfferingType).includes(
        values.type
      );

      if (isInternationalOfferingType) {
        type = internationalToUsOfferingType[type];
      }
      const input = {
        ...values,
        type,
      };

      try {
        const { data: createOfferingDraftData } = await createOfferingDraft({
          variables: { input },
        });

        if (createOfferingDraftData?.createOfferingDraft.__typename === 'Offering') {
          ToastManager.success('Successfully Created Offering Draft');

          if (createOfferingDraftData?.createOfferingDraft.id) {
            history.push(
              routeFactory.offeringSetup.getUrlPath({
                offeringId: createOfferingDraftData.createOfferingDraft.id,
                stepId: hasCreateInternationalTrait ? stepIds.INSTRUMENTS : stepIds.CURRENCIES,
              }),
              // pass unmapped offering type so side nav knows how to rearrange steps
              { offeringType: values.type }
            );
          }
        }
      } catch {
        ToastManager.error('Failed to Create Offering Draft');
      }
    },
    [createOfferingDraft, history, hasCreateInternationalTrait]
  );

  const handleSubmit = useCallback(
    async (values: CreateOfferingInput | UpdateBasicInfoInput) => {
      if (offeringId) {
        // remove pricingCurrencyCode from DTO - only used for creating offering
        const { pricingCurrencyCode, ...updateValues } = values as CreateOfferingInput;
        await handleUpdateBasicInfo(updateValues as UpdateBasicInfoInput, offeringId);
      } else {
        // if offeringId doesn't exist, we should be creating a draft
        await handleCreateOfferingDraft(values as ExtenedCreateOfferingInput);
      }
    },
    [handleCreateOfferingDraft, handleUpdateBasicInfo, offeringId]
  );

  const errors = error || createError || updateError || publishedOfferingError;
  const serviceError = createOfferingDraftServiceError || updateBasicInfoServiceError;

  if (loading || publishedOfferingLoading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId}>
      <Panel data-testid={xcSelectors.offeringSetupBasicInfoScreen.testId}>
        {errors && <ServerErrorsBanner error={errors} />}
        {serviceError && <ServiceErrorBanner error={serviceError} />}
        <BasicInfoForm
          offering={data?.offering}
          publishedOffering={publishedOfferingData?.publishedOffering}
          panelHeaderContent={
            // TODO: This whole thing will be replaced by the new top-level save implementation.
            data?.offering ? (
              <LoadingButton loading={updating} variant="contained" type="submit">
                Save
              </LoadingButton>
            ) : (
              <LoadingButton loading={creating} variant="contained" type="submit">
                Create Draft
              </LoadingButton>
            )
          }
          disableUnsavedChangesGuard={created && !createError}
          submitting={updating || creating}
          onSubmit={handleSubmit}
        />
      </Panel>
    </OfferingSetupPage>
  );
};

export default BasicInfoRoute;
