import { BaseAgGrid, OnGridReadyEventFn, SelectionChangedEventFn } from '@cmg/common';
import { CellValueChangedEvent } from 'ag-grid-community';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components/macro';

import { DesignationMonitorGridContext } from '../../hooks/useDesignationContext';
import DesignationMonitorNoResults from './components/designation-monitor-no-results/DesignationMonitorNoResults';
import {
  DesignationRow,
  frameworkComponents,
  getDesignationTotalsHeader,
  isDesignationRowSelectablePredicate,
} from './DesignationMonitorGrid.model';
import { SDesignationMonitorGridContainer } from './DesignationMonitorGrid.styles';
import { useDesignationMonitorColumns } from './hooks/useDesignationMonitorColumns';

export type Props = {
  context: DesignationMonitorGridContext;
  onGridReady?: OnGridReadyEventFn;
  onSelectionChange?: SelectionChangedEventFn;
  onCellValueChange?: (item: CellValueChangedEvent) => void;
  filteredInvestorRows: DesignationRow[];
};

/**
 * Designation Monitor DataGrid.
 */
export const DesignationMonitorGrid: React.FC<Props> = ({
  context,
  filteredInvestorRows,
  onGridReady,
  onSelectionChange,
  onCellValueChange,
}) => {
  const theme = useTheme();

  const columns = useDesignationMonitorColumns(context.managers);

  const headerTotalRows = useMemo<DesignationRow[]>(
    () =>
      getDesignationTotalsHeader(
        filteredInvestorRows,
        context.managers,
        context.noEconomicsApplied
      ),
    [filteredInvestorRows, context.managers, context.noEconomicsApplied]
  );

  return (
    <SDesignationMonitorGridContainer data-test-id="designation-monitor-data-grid">
      <BaseAgGrid<DesignationRow>
        context={{ ...context, investorRows: filteredInvestorRows }}
        rows={filteredInvestorRows}
        pinnedTopRows={headerTotalRows}
        rowSelection="multiple"
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChange}
        onSelectionChanged={onSelectionChange}
        loading={false}
        frameworkComponents={frameworkComponents}
        columns={columns}
        noRowsOverlayComponentFramework={DesignationMonitorNoResults}
        gridOptions={{ enableCellTextSelection: true }}
        isRowSelectable={isDesignationRowSelectablePredicate}
        rowStyle={{
          background: theme.background.color.white,
          borderBottom: theme.border.smallSolidLight,
        }}
      />
    </SDesignationMonitorGridContainer>
  );
};

export default DesignationMonitorGrid;
