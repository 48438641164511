import { LinkButton, SubNavigation } from '@cmg/common';
import styled from 'styled-components/macro';

export const SContentWrapper = styled.div`
  flex-grow: 1;
`;

export const SCalculationWrapper = styled.div`
  width: 380px;
`;

export const SNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 1px solid ${({ theme }) => theme.foundation.colors.secondary[100]};
  margin-bottom: 20px;
`;

export const StyledSubNavigation = styled(SubNavigation)`
  flex-grow: 1;
`;

export const SIconWrapper = styled.div`
  margin: 0 8px;
`;

export const StyledAddTradeLinkButton = styled(LinkButton)`
  margin: 0 16px;
  align-self: flex-start;
`;
