import { ManagerErrorType } from '../../../../../../../graphql';
import { SyndicateWireManager } from '../../../../SyndicateWiresRoute.model';
import { WireTypeValidation } from '../../../context/WireTypeConfigContext.model';
import { SyndicateWires_ManagerValidationPartsFragment } from '../../../graphql';

export type ValidationConfig = { [field: string]: { step: ValidationStep; fieldName: string } };

export type ValidationBySteps = { [step: string]: string[] };

export enum ValidationStep {
  BASIC_INFO = 'Basic Info',
  CURRENCIES = 'Currencies',
  INSTRUMENTS = 'Instruments',
  MANAGERS = 'Managers',
  TIMING = 'Timing',
  MARKETING = 'Marketing',
  TERMS = 'Terms',
  OFFERING_TERMS = 'Offering Terms',
  DISCLAIMERS = 'Disclaimers',
  SUMMARY = 'Summary',
  TERMS_INITIAL = 'Terms (Initial Filing)',
  TERMS_FINAL = 'Terms (Final Filing)',
  OFFERING_TERMS_INITIAL = 'Offering Terms (Initial Filing)',
  OFFERING_TERMS_FINAL = 'Offering Terms (Final Filing)',
  INSTITUTIONAL_DEMAND = 'Institutional Demand',
  RETAIL_DEMAND = 'Total Retail Demand',
  ECONOMICS = 'Economics (Shares)',
  DESIGNATION_MONITOR = 'Designation Monitor',
  DISCOUNTS_AND_FEES = 'Discounts and Fees',
  MY_RETAIL_DEMAND = 'My Retail Demand',
  UNDERWRITING_TERMS = 'Underwriting Terms',
}

export enum ValidationFieldName {
  EXCHANGE_MIC = 'Exchange',
  IPO_RANGE_HIGH = 'IPO Range High',
  IPO_RANGE_LOW = 'IPO Range Low',
  ISSUER_NAME = 'Issuer Name',
  OFFERING_TYPE = 'Offering Type',
  PRICING_DATE = 'Pricing Date',
  PUBLIC_FILING_DATE = 'Public Filing Date',
  LAUNCH_DATE = 'Launch Date',
  SECURITY_TYPE = 'Security Type',
  SETTLEMENT_AGENT = 'Settlement Agent',
  STOCK_SYMBOL = 'Symbol',
  TOTAL_SHARES_BASE_OFFERING = 'Primary Shares, Secondary Shares',
  CUSIP = 'CUSIP',
  FIRST_TRADE_DATE = 'First Trade Date',
  SETTLEMENT_DATE = 'Settlement Date',
  OFFERING_PRICE = 'Offer Price',
  GROSS_SPREAD = 'Gross Spread',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PRICE_PER_SHARE = 'Offer Price',
  OVERALLOTMENT_SHARES_FINAL = 'Over-allotment Exercised',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OVERALLOTMENT_SHARES = 'Over-allotment Exercised',
  ECONOMICS = 'Economics (Shares)',
  MANAGER_ECONOMICS_UW_SHARES_BASE_OFFERING = 'Manager Economics - U/W Shares (Base Offering)',
  MANAGER_ECONOMICS_UW_TOTAL_SHARES = 'Manager Economics - U/W Shares (Base + Exercised Overallotment)',
  UNDERWRITING_TERMS_UW_SHARES_BASE_OFFERING = 'Underwriting Terms - U/W Shares (Base Offering)',
  UNDERWRITING_TERMS_UW_TOTAL_SHARES = 'Underwriting Terms - U/W Shares (Base + Exercised Overallotment)',
  TOTAL_INSTITUTIONAL_ALLOCATIONS = 'Total Allocations count missing',
  NO_ALLOCATIONS = 'Allocations missing',
  NO_FINAL_ALLOCATIONS = 'Final Allocations missing',
  NO_RELEASED_FINAL_ALLOCATIONS = 'Released Final Allocations missing',
  NO_INDICATIONS = 'Indications missing',
  RETAIL_DEMAND = 'Total Demand missing',
  RETAIL_RETENTION = 'Retention missing',
  NO_DESIGNATIONS = 'Designations missing',
  MANAGEMENT_FEE = 'Management Fee',
  RE_ALLOWANCE = 'Reallowance',
  SELLING_CONCESSION = 'Selling Concession',
  UNDERWRITING_FEE = 'Underwriting Fee',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MY_RETAIL_DEMAND_RETENTION = 'Retention missing',
  BILLING_AND_DELIVERY = 'B&D Agent missing',
}

const getDefaultOrOverrideValue = <T>(
  useOverrideValue: boolean,
  overrideValue: T,
  defaultValue: T
) => (useOverrideValue ? overrideValue : defaultValue);

/**
 * Exported for testing purposes
 */
export const getOfferingSetupValidationConfig = (
  isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn = false
): ValidationConfig => {
  return {
    EXCHANGE_MIC: {
      step: ValidationStep.INSTRUMENTS,
      fieldName: ValidationFieldName.EXCHANGE_MIC,
    },
    IPO_RANGE_HIGH: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_INITIAL,
        ValidationStep.TERMS_INITIAL
      ),
      fieldName: ValidationFieldName.IPO_RANGE_HIGH,
    },
    IPO_RANGE_LOW: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_INITIAL,
        ValidationStep.TERMS_INITIAL
      ),
      fieldName: ValidationFieldName.IPO_RANGE_LOW,
    },
    ISSUER_NAME: {
      step: ValidationStep.BASIC_INFO,
      fieldName: ValidationFieldName.ISSUER_NAME,
    },
    OFFERING_TYPE: {
      step: ValidationStep.BASIC_INFO,
      fieldName: ValidationFieldName.OFFERING_TYPE,
    },
    PRICING_DATE: {
      step: ValidationStep.TIMING,
      fieldName: ValidationFieldName.PRICING_DATE,
    },
    PUBLIC_FILING_DATE: {
      step: ValidationStep.TIMING,
      fieldName: ValidationFieldName.PUBLIC_FILING_DATE,
    },
    LAUNCH_DATE: {
      step: ValidationStep.TIMING,
      fieldName: ValidationFieldName.LAUNCH_DATE,
    },
    SECURITY_TYPE: {
      step: ValidationStep.BASIC_INFO,
      fieldName: ValidationFieldName.SECURITY_TYPE,
    },
    SETTLEMENT_AGENT: {
      step: ValidationStep.MANAGERS,
      fieldName: ValidationFieldName.SETTLEMENT_AGENT,
    },
    STOCK_SYMBOL: {
      step: ValidationStep.INSTRUMENTS,
      fieldName: ValidationFieldName.STOCK_SYMBOL,
    },
    TOTAL_SHARES_BASE_OFFERING: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS,
        ValidationStep.TERMS
      ),
      fieldName: ValidationFieldName.TOTAL_SHARES_BASE_OFFERING,
    },
    TOTAL_SHARES_BASE_OFFERING_INITIAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_INITIAL,
        ValidationStep.TERMS_INITIAL
      ),
      fieldName: ValidationFieldName.TOTAL_SHARES_BASE_OFFERING,
    },
    TOTAL_SHARES_BASE_OFFERING_FINAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.TOTAL_SHARES_BASE_OFFERING,
    },
    CUSIP: {
      step: ValidationStep.INSTRUMENTS,
      fieldName: ValidationFieldName.CUSIP,
    },
    FIRST_TRADE_DATE: {
      step: ValidationStep.TIMING,
      fieldName: ValidationFieldName.FIRST_TRADE_DATE,
    },
    SETTLEMENT_DATE: {
      step: ValidationStep.TIMING,
      fieldName: ValidationFieldName.SETTLEMENT_DATE,
    },
    OFFERING_PRICE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS,
        ValidationStep.TERMS
      ),
      fieldName: ValidationFieldName.OFFERING_PRICE,
    },
    OFFERING_PRICE_INITIAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_INITIAL,
        ValidationStep.TERMS_INITIAL
      ),
      fieldName: ValidationFieldName.OFFERING_PRICE,
    },
    OFFERING_PRICE_FINAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.OFFERING_PRICE,
    },
    GROSS_SPREAD: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.GROSS_SPREAD,
    },
    PRICE_PER_SHARE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.PRICE_PER_SHARE,
    },
    PRICE_PER_SHARE_FINAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.PRICE_PER_SHARE,
    },
    OVERALLOTMENT_SHARES_FINAL: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.OVERALLOTMENT_SHARES_FINAL,
    },
    OVERALLOTMENT_SHARES: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.OFFERING_TERMS_FINAL,
        ValidationStep.TERMS_FINAL
      ),
      fieldName: ValidationFieldName.OVERALLOTMENT_SHARES,
    },
    UNDERWRITING_TOTAL_SHARES: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.MANAGERS
      ),
      fieldName: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationFieldName.UNDERWRITING_TERMS_UW_TOTAL_SHARES,
        ValidationFieldName.MANAGER_ECONOMICS_UW_TOTAL_SHARES
      ),
    },
    ECONOMICS: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.MANAGERS
      ),
      fieldName: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationFieldName.UNDERWRITING_TERMS_UW_SHARES_BASE_OFFERING,
        ValidationFieldName.MANAGER_ECONOMICS_UW_SHARES_BASE_OFFERING
      ),
    },
    MANAGEMENT_FEE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.MANAGEMENT_FEE,
    },
    RE_ALLOWANCE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.RE_ALLOWANCE,
    },
    SELLING_CONCESSION: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.SELLING_CONCESSION,
    },
    SELLING_CONCESSION_PER_SHARE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.SELLING_CONCESSION,
    },
    UNDERWRITING_FEE: {
      step: getDefaultOrOverrideValue(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn,
        ValidationStep.UNDERWRITING_TERMS,
        ValidationStep.DISCOUNTS_AND_FEES
      ),
      fieldName: ValidationFieldName.UNDERWRITING_FEE,
    },
  };
};

/**
 * Exported for testing purposes
 */
export const orderBookValidationConfig: ValidationConfig = {
  NO_ALLOCATIONS: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.NO_ALLOCATIONS,
  },
  NO_INDICATIONS: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.NO_INDICATIONS,
  },
  TOTAL_INSTITUTIONAL_ALLOCATIONS: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.TOTAL_INSTITUTIONAL_ALLOCATIONS,
  },
  RETAIL_RETENTION: {
    step: ValidationStep.RETAIL_DEMAND,
    fieldName: ValidationFieldName.RETAIL_RETENTION,
  },
  RETAIL_DEMAND: {
    step: ValidationStep.RETAIL_DEMAND,
    fieldName: ValidationFieldName.RETAIL_DEMAND,
  },
  MY_RETAIL: {
    step: ValidationStep.MY_RETAIL_DEMAND,
    fieldName: ValidationFieldName.MY_RETAIL_DEMAND_RETENTION,
  },
  BILLING_AND_DELIVERY: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.BILLING_AND_DELIVERY,
  },
  NO_FINAL_ALLOCATIONS: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.NO_FINAL_ALLOCATIONS,
  },
  NO_RELEASED_FINAL_ALLOCATIONS: {
    step: ValidationStep.INSTITUTIONAL_DEMAND,
    fieldName: ValidationFieldName.NO_RELEASED_FINAL_ALLOCATIONS,
  },
};

/**
 * Exported for testing purposes
 */
export const settlementValidationConfig: ValidationConfig = {
  NO_DESIGNATIONS: {
    step: ValidationStep.DESIGNATION_MONITOR,
    fieldName: ValidationFieldName.NO_DESIGNATIONS,
  },
};

/**
 * Defines a human-readable name and step for each validation field we recognize
 */
export const getValidationConfig = (
  isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn = false
): ValidationConfig => ({
  ...getOfferingSetupValidationConfig(isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn),
  ...orderBookValidationConfig,
  ...settlementValidationConfig,
});

/**
 * Manager error types that are ignored by validation screen
 *
 * Exported for testing purposes
 */
export const ignoredManagerErrorTypes = [ManagerErrorType.DistributionList];

/**
 * Converts a validation grouped by steps to a string representation
 */
const validationByStepsToString = (
  validationBySteps: ValidationBySteps,
  depth: number = 0
): string => {
  const padding = new Array(depth).fill('  ').join();

  return Object.entries(validationBySteps)
    .filter(([, fields]) => fields.length)
    .map(([step, fields]) => `${padding}• ${step}: ${fields.join(', ')}`)
    .join('\r\n');
};

/**
 * Maps manager specific validation errors to human readable plain string.
 */
const managerValidationByStepsToString = (validationBySteps: ValidationBySteps): string => {
  const { [ValidationStep.ECONOMICS]: economics = [], ...restOfValidation } = validationBySteps;

  const restOfValidationString = validationByStepsToString(restOfValidation, 1);
  const economicsValidationString = validationByStepsToString(
    {
      [ValidationStep.ECONOMICS]: economics,
    },
    1
  );

  return [restOfValidationString, economicsValidationString].filter(str => str.length).join('\r\n');
};

/**
 * Group and concat Offering setup and manager specific validation error into human readable plain string.
 */
const offeringDetailsValidationByStepsToString = (
  offeringValidation: ValidationBySteps,
  managersValidation: ValidationBySteps
): string => {
  const templateFieldsString = validationByStepsToString(offeringValidation);
  const managersString = managerValidationByStepsToString(managersValidation);

  if (!managersString) {
    return templateFieldsString;
  }

  return [templateFieldsString, `• ${ValidationStep.MANAGERS}:`, managersString]
    .filter(text => !!text.trim())
    .join(`\r\n`);
};

/**
 * Returns list of given managers without ignored validation error fields specified in `ignoredManagerErrorTypes`.
 */
export const filterIgnoredManagerErrorTypes = (
  managers: readonly SyndicateWires_ManagerValidationPartsFragment[],
  ignoredErrorTypes: ManagerErrorType[] = ignoredManagerErrorTypes
) => {
  return managers.reduce<SyndicateWires_ManagerValidationPartsFragment[]>((acc, curr) => {
    const fields = curr.fields.filter(field => !ignoredErrorTypes.includes(field));

    // Omit managers with nothing but ignored errors
    if (fields.length === 0) {
      return acc;
    }

    return [...acc, { ...curr, fields }];
  }, []);
};

/**
 * Filter out given offering validation errors
 *
 * @param validation
 * @param ignoredErrors
 */
export const filterIgnoredOfferingErrorTypes = <TValidation extends WireTypeValidation>(
  validation: TValidation | undefined,
  ignoredErrors: string[]
): TValidation | undefined => {
  if (!validation) {
    return undefined;
  }

  return {
    ...validation,
    offeringErrors: ignoredErrors.length
      ? validation.offeringErrors.filter(error => !ignoredErrors.includes(error))
      : validation.offeringErrors,
  };
};

type ValidationSection = {
  label: string;
  errors: string | undefined;
};

/**
 * Creates a text representation of validation errors section
 * @param Entity that contains the section information
 * @returns String representing validation errors section
 */
export function createValidationsSection({ label, errors }: ValidationSection): string | null {
  if (!errors) {
    return null;
  }

  return `${label}
----------------------
${errors}`;
}

/**
 * Creates a text representation of the validation errors for the user who does not have
 * the permissions to fix them in associated XC modules such as Offering Setup and Order Book.
 */
export const wireValidationsToString = (
  wireValidations: WireTypeValidation,
  managers: SyndicateWireManager[]
): string => {
  const offeringDetailsSection = createValidationsSection({
    label: 'Offering Details',
    errors:
      !!wireValidations.offeringErrors.length || !!wireValidations.managers.length
        ? offeringDetailsValidationByStepsToString(
            groupValidationBySteps(wireValidations.offeringErrors),
            groupManagerValidationBySteps(wireValidations.managers, managers)
          )
        : undefined,
  });

  const orderBookSection = createValidationsSection({
    label: 'Order Book',
    errors: wireValidations.orderBookErrors.length
      ? validationByStepsToString(groupValidationBySteps(wireValidations.orderBookErrors))
      : undefined,
  });

  const settlementSection = createValidationsSection({
    label: 'Final Settlement',
    errors: wireValidations.settlementErrors.length
      ? validationByStepsToString(groupValidationBySteps(wireValidations.settlementErrors))
      : undefined,
  });

  return [offeringDetailsSection, orderBookSection, settlementSection]
    .filter(Boolean)
    .join('\r\n\r\n');
};

/**
 * Check if there are any validation errors to display
 */
export const hasValidationErrors = (wireValidations: WireTypeValidation | undefined): boolean => {
  return Boolean(
    wireValidations?.offeringErrors.length ||
      wireValidations?.orderBookErrors.length ||
      wireValidations?.managers.length ||
      wireValidations?.settlementErrors.length
  );
};

/**
 * Groups an array of validation fields according to their steps, defined in `validationConfig`
 */
export const groupValidationBySteps = (
  validationFields: readonly string[],
  isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn = false
): ValidationBySteps => {
  return validationFields.reduce<ValidationBySteps>((acc, field) => {
    const configItem = getValidationConfig(isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn)[
      field
    ];

    if (!configItem) {
      return acc;
    }

    return {
      ...acc,
      [configItem.step]: [...(acc[configItem.step] ?? []), configItem.fieldName],
    };
  }, {});
};

/**
 * Groups known manager names by associated validation errors using cmgEntityKey.
 * Example input/output: [managerA: [fieldA], managerB: [fieldA]] => { fieldA: [managerA, managerB] }
 */
export const groupManagerValidationBySteps = (
  managerValidations: readonly SyndicateWires_ManagerValidationPartsFragment[],
  managers: SyndicateWireManager[],
  isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn = false
): ValidationBySteps => {
  return managerValidations.reduce<ValidationBySteps>((managerAcc, managerValidation) => {
    const firmName = managers.find(
      ({ cmgEntityKey }) => managerValidation.cmgEntityKey === cmgEntityKey
    )?.firmName;

    if (!firmName) {
      return managerAcc;
    }

    return managerValidation.fields.reduce<ValidationBySteps>((acc, validation) => {
      const configItem = getValidationConfig(
        isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
      )[validation];

      if (!configItem) {
        return acc;
      }

      return {
        ...acc,
        [configItem.fieldName]: [...(acc[configItem.fieldName] ?? []), firmName],
      };
    }, managerAcc);
  }, {});
};
