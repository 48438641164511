import { Box, Typography } from '@cmg/design-system';
import React from 'react';

import animationGif from './assets/xc-browser-notifications-image-animation.gif';
import highlightBellImage from './assets/xc-browser-notifications-image-highlight-bell.png';
import highlightStatusImage from './assets/xc-browser-notifications-image-highlight-status.png';
import highlightStatusBellImage from './assets/xc-browser-notifications-image-highlight-status-bell.png';
import highlightTestCtaImage from './assets/xc-browser-notifications-image-highlight-test-cta.png';
import highlightTurnOffImage from './assets/xc-browser-notifications-image-highlight-turn-off.png';
import osNotificationImage from './assets/xc-browser-notifications-image-os-notification.png';
import permissionImage from './assets/xc-browser-notifications-image-permission.png';

/**
 * Static documentation informing users how to enable and troubleshoot browser notifications.
 * This will be replaced by documentation in CMG's knowledge base CMS.
 */
export const BrowserNotificationsDocumentationRoute = () => {
  return (
    <Box maxWidth="md" sx={{ margin: '0 auto' }}>
      <div>
        <Typography variant="h1" mt={2}>
          Enable Web Browser Notifications
        </Typography>
        <Typography mt={2}>
          Enable Browser Notification to receive timely alerts for XC Calendar events, even when XC
          is not open in the browser.
        </Typography>
        <Typography mt={1}>
          The notifications help you stay informed of new offerings or updated terms to existing
          deals, and to be promptly notified when offerings are published to Live and Priced status.
          You will also be notified about an offering status change (that is, when an offering is
          updated from Live to Priced status).
        </Typography>
        <div>
          <img
            src={animationGif}
            alt="activating browser notifications within CMG header"
            style={{ maxWidth: '100%', marginTop: '16px' }}
          />
        </div>

        <Typography variant="h2" mt={4} mb={2}>
          Prerequisites
        </Typography>
        <ul style={{ marginLeft: '16px' }}>
          <li>
            <Typography>
              In your system's Notifications settings, confirm that notifications are allowed for
              your browser.
            </Typography>
          </li>
          <li>
            <Typography>
              In your browser's Notifications settings, confirm that the CMG site is allowed to send
              notifications.
            </Typography>
          </li>
        </ul>

        <Typography variant="h2" mt={4} mb={2}>
          Subscribing to notifications
        </Typography>
        <Typography mt={1} mb={1}>
          To receive XC Calendar event-related browser notifications:
        </Typography>
        <ol style={{ marginLeft: '16px' }}>
          <li>
            <Typography>Log into the XC platform.</Typography>
          </li>
          <li>
            <Typography>
              In the header, click the bell icon located in the main menu. The{' '}
              <strong>Push Notifications</strong> window displays.
            </Typography>
          </li>
          <li>
            <Typography>
              Click <strong>Turn On</strong>
              <img
                src={highlightBellImage}
                alt="bell icon in CMG header"
                style={{ maxWidth: '100%', marginTop: '16px', marginBottom: '8px' }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              A prompt displays requesting you to action the notifications. (Prompts vary by
              browser.) Click <strong>Allow</strong>.
              <img
                src={permissionImage}
                alt="browser permission dialog"
                style={{ maxWidth: '100%', marginTop: '16px', marginBottom: '8px' }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              To confirm the subscription status, click the bell icon in the header.{' '}
              <img
                src={highlightStatusImage}
                alt="bell icon in CMG header"
                style={{ maxWidth: '100%', marginTop: '16px' }}
              />
            </Typography>
          </li>
        </ol>

        <Typography mt={2} mb={1} variant="h3">
          Testing the setup
        </Typography>
        <Typography>
          Confirm that notifications have been set up successfully by sending a test.
        </Typography>
        <Typography>
          Click the bell icon in the Push Notifications window and a test notification displays in
          the browser. For support, contact <a href="mailto:support@cmgx.io">CMG Support.</a>
          <img
            src={highlightTestCtaImage}
            alt="bell icon in CMG header"
            style={{ maxWidth: '100%', marginTop: '16px' }}
          />
        </Typography>

        <Typography variant="h2" mt={4} mb={2}>
          Viewing and actioning notifications
        </Typography>
        <Typography>
          As soon as an offering status changes or a new offering is published to the XC Calendar, a
          notification displays in the browser (XC is not required to be open).
        </Typography>
        <Typography>
          <img
            src={osNotificationImage}
            alt="operating system browser notification"
            style={{ maxWidth: '100%', marginTop: '16px', marginBottom: '8px' }}
          />
        </Typography>
        <Typography>
          Once logged in, click the notification and a new browser tab opens to the XC Calendar page
          referenced in the alert (that is, the Live, Priced or Filed page).
        </Typography>

        <Typography variant="h2" mt={4} mb={2}>
          Pausing notifications
        </Typography>
        <Typography mt={1} mb={1}>
          To temporarily pause receiving XC Calendar notifications:
        </Typography>
        <ol style={{ marginLeft: '16px' }}>
          <li>
            <Typography>
              In the header, click the bell icon located in the main menu. The{' '}
              <strong>Push Notifications</strong> window displays.
            </Typography>
          </li>
          <li>
            <Typography>
              Click <strong>Turn Off</strong>.{' '}
              <img
                src={highlightTurnOffImage}
                alt="button to turn off notifications in CMG notifications dropdown"
                style={{ maxWidth: '100%', marginTop: '16px', marginBottom: '8px' }}
              />
            </Typography>
          </li>
        </ol>
        <Typography>
          You will no longer receive XC Calendar event-related notifications. To resume receiving
          notifications, click the bell icon and <strong>Turn On</strong>.
        </Typography>

        <Typography variant="h2" mt={4} mb={2}>
          FAQs &amp; troubleshooting
        </Typography>

        <div>
          <Typography mt={2} mb={1} variant="h3">
            I am not receiving notifications.
          </Typography>
          <Typography mt={1} mb={1}>
            Confirm that your browser notifications are enabled with a test notification:
          </Typography>
          <ol style={{ marginLeft: '16px' }}>
            <li>
              <Typography>
                In the header, click the bell icon located in the main menu. The{' '}
                <strong>Push Notifications</strong> window displays.
              </Typography>
            </li>
            <li>
              <Typography>
                To send a test notification, click the bell icon in the{' '}
                <strong>Push Notifications</strong> window.
              </Typography>
            </li>
          </ol>
          <Typography mt={1} mb={1}>
            Notifications are sent only when an offering transitions to a new state, such as Live or
            Priced.
          </Typography>
          <Typography>
            <img
              src={highlightStatusBellImage}
              alt="status icon in CMG notifications dropdown"
              style={{ maxWidth: '100%', marginTop: '16px', marginBottom: '8px' }}
            />
          </Typography>
        </div>

        <div>
          <Typography mt={2} mb={1} variant="h3">
            I subscribed to receive notifications on a work device using Google Chrome. Am I going
            to receive notifications on a personal device using the same browser?
          </Typography>

          <div>
            <Typography mt={1} mb={1}>
              No, the subscription preference is not shared across different devices.
            </Typography>
            <Typography>
              The subscription is tied to your browser on a specific device, not your CMG user
              account, as permissions are requested on a per browser basis. Subscription preferences
              do not persist across different browsers for the same user
            </Typography>
          </div>
        </div>

        <div>
          <Typography mt={2} mb={1} variant="h3">
            Do I need to have XC Calendar open to receive the notifications?
          </Typography>
          <div>
            <Typography mt={1} mb={1}>
              No, it is not necessary to have XC Calendar open. Once you subscribe, you'll receive
              notifications in your browser regardless of what pages are open.
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};
