/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Offering_OfferingHeader_OfferingFilingsPartsFragment = {
  readonly __typename?: 'Filing';
  readonly filingType: Types.FilingType;
  readonly offerPrice?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly recordStatus: Types.RecordStatus;
};

export type Offering_OfferingHeader_OfferingFollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type Offering_OfferingHeader_OfferingTermsPartsFragment = {
  readonly __typename?: 'Terms';
  readonly filings: ReadonlyArray<{
    readonly __typename?: 'Filing';
    readonly filingType: Types.FilingType;
    readonly offerPrice?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
    readonly recordStatus: Types.RecordStatus;
  }>;
  readonly followOnPricing?: {
    readonly __typename?: 'FollowOnPricing';
    readonly lastTradeBeforeFiling?: number | null;
    readonly lastTradeBeforeOffer?: number | null;
    readonly lastTradeBeforeLaunch?: number | null;
    readonly reOfferLow?: number | null;
    readonly reOfferHigh?: number | null;
  } | null;
};

export type Offering_OfferingHeader_OfferingPartsFragment = {
  readonly __typename?: 'Offering';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly isDiscarded: boolean;
  readonly isAuthor?: boolean;
  readonly type: Types.OfferingType;
  readonly sizeInShares?: number | null;
  readonly pricingCurrencyCode: string;
  readonly draftPricingInstrumentId?: string | null;
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly id: string;
    readonly name: string;
    readonly sector?: Types.Sector | null;
  };
  readonly security: { readonly __typename?: 'Security'; readonly type: Types.SecurityType };
  readonly terms: {
    readonly __typename?: 'Terms';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricing';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
  };
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrument';
    readonly id: string;
    readonly countryCode: string;
  }>;
};

export type Offering_OfferingHeader_OfferingProfileFilingsPartsFragment = {
  readonly __typename?: 'FilingProfile';
  readonly filingType: Types.FilingType;
  readonly offerPrice?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
};

export type Offering_OfferingHeader_OfferingProfileFollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricingProfile';
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type Offering_OfferingHeader_OfferingProfileTermsPartsFragment = {
  readonly __typename?: 'TermsProfile';
  readonly filings: ReadonlyArray<{
    readonly __typename?: 'FilingProfile';
    readonly filingType: Types.FilingType;
    readonly offerPrice?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
  }>;
  readonly followOnPricing?: {
    readonly __typename?: 'FollowOnPricingProfile';
    readonly lastTradeBeforeFiling?: number | null;
    readonly lastTradeBeforeOffer?: number | null;
    readonly lastTradeBeforeLaunch?: number | null;
    readonly reOfferLow?: number | null;
    readonly reOfferHigh?: number | null;
  } | null;
};

export type Offering_OfferingHeader_OfferingProfilePartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly isDiscarded: boolean;
  readonly isAuthor?: boolean;
  readonly type: Types.OfferingType;
  readonly sizeInShares?: number | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentId: string;
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly id: string;
    readonly name: string;
    readonly sector?: Types.Sector | null;
  };
  readonly security: { readonly __typename?: 'SecurityProfile'; readonly type: Types.SecurityType };
  readonly terms: {
    readonly __typename?: 'TermsProfile';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingProfile';
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
    }>;
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricingProfile';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
  };
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'DeliveryInstrumentPrivileged';
    readonly id: string;
    readonly countryCode: string;
  }>;
};

export type Offering_OfferingHeaderQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  hasOfferingReadPermission: Types.Scalars['Boolean'];
}>;

export type Offering_OfferingHeaderQuery = {
  readonly __typename?: 'Query';
  readonly offeringHeader:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly status: Types.OfferingStatus;
        readonly stage: Types.OfferingStage;
        readonly isDiscarded: boolean;
        readonly isAuthor?: boolean;
        readonly type: Types.OfferingType;
        readonly sizeInShares?: number | null;
        readonly pricingCurrencyCode: string;
        readonly draftPricingInstrumentId?: string | null;
        readonly issuer: {
          readonly __typename?: 'Issuer';
          readonly id: string;
          readonly name: string;
          readonly sector?: Types.Sector | null;
        };
        readonly security: { readonly __typename?: 'Security'; readonly type: Types.SecurityType };
        readonly terms: {
          readonly __typename?: 'Terms';
          readonly filings: ReadonlyArray<{
            readonly __typename?: 'Filing';
            readonly filingType: Types.FilingType;
            readonly offerPrice?: number | null;
            readonly ipoRangeLow?: number | null;
            readonly ipoRangeHigh?: number | null;
            readonly recordStatus: Types.RecordStatus;
          }>;
          readonly followOnPricing?: {
            readonly __typename?: 'FollowOnPricing';
            readonly lastTradeBeforeFiling?: number | null;
            readonly lastTradeBeforeOffer?: number | null;
            readonly lastTradeBeforeLaunch?: number | null;
            readonly reOfferLow?: number | null;
            readonly reOfferHigh?: number | null;
          } | null;
        };
        readonly instruments: ReadonlyArray<{
          readonly __typename?: 'DeliveryInstrument';
          readonly id: string;
          readonly countryCode: string;
        }>;
      }
    | {
        readonly __typename?: 'OfferingProfile';
        readonly id: string;
        readonly status: Types.OfferingStatus;
        readonly stage: Types.OfferingStage;
        readonly isDiscarded: boolean;
        readonly isAuthor?: boolean;
        readonly type: Types.OfferingType;
        readonly sizeInShares?: number | null;
        readonly pricingCurrencyCode: string;
        readonly pricingInstrumentId: string;
        readonly issuer: {
          readonly __typename?: 'Issuer';
          readonly id: string;
          readonly name: string;
          readonly sector?: Types.Sector | null;
        };
        readonly security: {
          readonly __typename?: 'SecurityProfile';
          readonly type: Types.SecurityType;
        };
        readonly terms: {
          readonly __typename?: 'TermsProfile';
          readonly filings: ReadonlyArray<{
            readonly __typename?: 'FilingProfile';
            readonly filingType: Types.FilingType;
            readonly offerPrice?: number | null;
            readonly ipoRangeLow?: number | null;
            readonly ipoRangeHigh?: number | null;
          }>;
          readonly followOnPricing?: {
            readonly __typename?: 'FollowOnPricingProfile';
            readonly lastTradeBeforeFiling?: number | null;
            readonly lastTradeBeforeOffer?: number | null;
            readonly lastTradeBeforeLaunch?: number | null;
            readonly reOfferLow?: number | null;
            readonly reOfferHigh?: number | null;
          } | null;
        };
        readonly instruments: ReadonlyArray<{
          readonly __typename?: 'DeliveryInstrumentPrivileged';
          readonly id: string;
          readonly countryCode: string;
        }>;
      };
};

export const Offering_OfferingHeader_OfferingFilingsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingFollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingTermsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthor' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'hasOfferingReadPermission' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftPricingInstrumentId' },
            name: { kind: 'Name', value: 'pricingInstrumentId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingProfileFilingsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingProfileFollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingProfileTermsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeader_OfferingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthor' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'hasOfferingReadPermission' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileTermsParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Offering_OfferingHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Offering_OfferingHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasOfferingReadPermission' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offeringHeader' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSharedDraft' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingParts' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthor' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'hasOfferingReadPermission' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'draftPricingInstrumentId' },
            name: { kind: 'Name', value: 'pricingInstrumentId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAuthor' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'hasOfferingReadPermission' },
                    },
                  },
                ],
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingProfileTermsParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOffering_OfferingHeaderQuery__
 *
 * To run a query within a React component, call `useOffering_OfferingHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffering_OfferingHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffering_OfferingHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hasOfferingReadPermission: // value for 'hasOfferingReadPermission'
 *   },
 * });
 */
export function useOffering_OfferingHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    Offering_OfferingHeaderQuery,
    Offering_OfferingHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Offering_OfferingHeaderQuery, Offering_OfferingHeaderQueryVariables>(
    Offering_OfferingHeaderDocument,
    options
  );
}
export function useOffering_OfferingHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Offering_OfferingHeaderQuery,
    Offering_OfferingHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Offering_OfferingHeaderQuery, Offering_OfferingHeaderQueryVariables>(
    Offering_OfferingHeaderDocument,
    options
  );
}
export type Offering_OfferingHeaderQueryHookResult = ReturnType<
  typeof useOffering_OfferingHeaderQuery
>;
export type Offering_OfferingHeaderLazyQueryHookResult = ReturnType<
  typeof useOffering_OfferingHeaderLazyQuery
>;
export type Offering_OfferingHeaderQueryResult = Apollo.QueryResult<
  Offering_OfferingHeaderQuery,
  Offering_OfferingHeaderQueryVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeOffering_OfferingHeaderMockResponse = makeMockResponse<
  Offering_OfferingHeaderQueryVariables,
  Offering_OfferingHeaderQuery
>(Offering_OfferingHeaderDocument);
