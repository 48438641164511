import { useAuth } from '@cmg/auth';
import { ErrorIcon, Tooltip, WarningIcon } from '@cmg/design-system';
import React from 'react';

import routeFactory from '../../../../../../../common/util/routeFactory';
import {
  AttestationFormStatus,
  OfferingType,
  ProspectusDeliveryStatus,
} from '../../../../../../../graphql';
import { MyOrdersGridCoveredIndication } from '../../../../../../my-orders/components/my-orders-grid/MyOrdersGrid.model';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { IndicationWithDemandLevels } from '../../types';
import {
  isComplianceErrorApplicableForOfferingType,
  isRowAcknowledgementRevised,
  isRowComplianceInvalid,
  isRowValidAndBnDAgentUnassigned,
} from './RowAlert.model';
import { RowAlertTooltipContent } from './RowAlertTooltipContent';

export type Props = {
  data: IndicationWithDemandLevels | MyOrdersGridCoveredIndication;
  context: { matchUrl: string };
  attestationStatus: AttestationFormStatus;
  prospectusDeliveryStatus: ProspectusDeliveryStatus | undefined;
  offeringType?: OfferingType;
  isSettlementAgent?: boolean;
  isSalesAndTrading?: boolean;
};

export const RowAlertCellRenderer: React.FC<Props> = ({
  data,
  context,
  attestationStatus,
  prospectusDeliveryStatus,
  isSettlementAgent,
  offeringType,
  isSalesAndTrading,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const { cmgEntityKey } = data.investorInformation;
  const { status: indicationStatus } = data;
  const { billingAndDeliveryAgentCmgEntityKey, institutionalIndicationAcknowledgements, version } =
    data;
  const finalAllocationShareQuantity =
    'finalAllocation' in data && data.finalAllocation?.shareQuantity;
  const isComplianceAlert =
    isComplianceErrorApplicableForOfferingType(offeringType ?? OfferingType.Ipo) &&
    isRowComplianceInvalid({
      indicationStatus,
      attestationStatus,
      prospectusDeliveryStatus,
    });

  const isAcknowledgementRevisedWarningAlert =
    !isSalesAndTrading &&
    isRowAcknowledgementRevised({
      institutionalIndicationAcknowledgements,
      currentIndicationVersion: version,
      indicationStatus,
      oidcUserCmgEntityKey: oidcUserCmgEntityKey!,
    });

  const isUnAssignedBDAlert = React.useMemo(
    () =>
      !!finalAllocationShareQuantity &&
      isRowValidAndBnDAgentUnassigned({
        billingAndDeliveryAgentCmgEntityKey: billingAndDeliveryAgentCmgEntityKey!,
        finalAllocationShareQuantity: finalAllocationShareQuantity,
        isSettlementAgent: isSettlementAgent ?? false,
        status: data.status,
        type: data.type,
      }),
    [
      billingAndDeliveryAgentCmgEntityKey,
      data.status,
      data.type,
      finalAllocationShareQuantity,
      isSettlementAgent,
    ]
  );

  if (!isComplianceAlert && !isUnAssignedBDAlert && !isAcknowledgementRevisedWarningAlert) {
    return <div></div>;
  }

  const complianceUrl = cmgEntityKey
    ? offeringSidePanelSellSideRouteFactory.orderBookCompliance.getUrlPath({
        cmgEntityKey: cmgEntityKey,
      })
    : '';

  const offeringSidePanelComplianceUrl = routeFactory.offeringSidePanel.getUrlPath({
    offeringId: data.offeringId,
    prefixRoutePath: context.matchUrl,
    sidePanelRoutePath: complianceUrl,
  });

  const isWarningOnly =
    !isComplianceAlert && !isUnAssignedBDAlert && isAcknowledgementRevisedWarningAlert;

  return (
    <Tooltip
      title={
        <RowAlertTooltipContent
          viewDetailsUrl={offeringSidePanelComplianceUrl}
          attestationStatus={attestationStatus}
          prospectusDeliveryStatus={prospectusDeliveryStatus}
          isComplianceAlert={isComplianceAlert}
          isUnAssignedBDAlert={isUnAssignedBDAlert}
          isAcknowledgementRevisedWarningAlert={isAcknowledgementRevisedWarningAlert}
        />
      }
      variant={isWarningOnly ? 'warning' : 'error'}
      placement="top"
    >
      {isWarningOnly ? (
        <WarningIcon color="warning" fontSize="small" />
      ) : (
        <ErrorIcon color="error" fontSize="small" />
      )}
    </Tooltip>
  );
};
