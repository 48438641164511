import styled from 'styled-components/macro';

export const STitleContainer = styled.div`
  margin: 0px 10px 0px 10px;
`;

export const SFooterContainer = styled.div`
  margin-right: 15px;
`;

export const SModalBodyContainer = styled.div`
  margin: 0px 10px 0px 10px;
`;
