import React from 'react';

import { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalDraftSet } from '../../../../../types';
import ShareAllocationSetDialog, { Props as ShareDialogProps } from '../ShareAllocationSetDialog';
import {
  getCheckedCmgEntityKeys,
  ShareAllocationSetValues,
} from '../ShareAllocationSetDialog.model';
import { useOrderBook_ShareDraftAllocationSetMutation } from './graphql';

export type Props = Pick<ShareDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    sharedDraftAllocationSet:
      | InstitutionalDraftSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const ShareDraftAllocationSetDialog: React.FC<Props> = ({
  sharedDraftAllocationSet,
  offeringId,
  ...props
}) => {
  const [share] = useOrderBook_ShareDraftAllocationSetMutation();

  const handleSave = React.useCallback(
    async (formValues: ShareAllocationSetValues) => {
      await share({
        variables: {
          offeringId,
          setId: sharedDraftAllocationSet?.id!,
          payload: getCheckedCmgEntityKeys(formValues),
        },
      });
    },
    [offeringId, share, sharedDraftAllocationSet?.id]
  );

  if (!sharedDraftAllocationSet) {
    return null;
  }

  const authorCmgEntityKey =
    'author' in sharedDraftAllocationSet
      ? sharedDraftAllocationSet.author.firmKey
      : sharedDraftAllocationSet.authorCmgEntityKey!;

  const sharedWithManagers =
    'author' in sharedDraftAllocationSet
      ? sharedDraftAllocationSet.sharedWithManagers?.map(shared => ({
          cmgEntityKey: shared!.manager.firmKey,
          timestamp: shared!.sharedAt,
          isActive: shared!.isActive,
        })) ?? []
      : sharedDraftAllocationSet.sharedWithManagers;

  return (
    <ShareAllocationSetDialog
      {...props}
      title="Share Draft Allocation Set dialog"
      offeringId={offeringId}
      sharedWithManagers={sharedWithManagers}
      authorCmgEntityKey={authorCmgEntityKey}
      onSave={handleSave}
    />
  );
};

export default ShareDraftAllocationSetDialog;
