import { Popover, SecondaryButton } from '@cmg/common';
import React from 'react';

import { CalendarFilters } from '../../hooks/useFilters';
import { useCalendarExportOfferings } from './hooks/useCalendarExportOfferings';

type Props = {
  filters: CalendarFilters;
};

export const CalendarExportButton: React.FC<Props> = ({ filters }) => {
  const { handleExport, loading } = useCalendarExportOfferings(filters);

  return (
    <Popover variant="TOOLTIP" placement="topRight" content="Export As XLSX">
      <SecondaryButton
        onClick={handleExport}
        iconLeft={{
          variant: 'regular',
          name: 'file-export',
        }}
        disabled={loading}
      />
    </Popover>
  );
};
