import { IconNameParts } from '@cmg/common';

import { MeetingType } from '../../../../../../graphql';

/**
 * Return icon name based on given meeting type
 */
export const getMeetingIcon = (meetingType: MeetingType): IconNameParts | null => {
  switch (meetingType) {
    case MeetingType.LargeGroup:
      return { name: 'users' };
    case MeetingType.SmallGroup:
      return { name: 'user-friends' };
    default:
      return null;
  }
};

export const getDateFromTime = (time: string): Date => {
  const [hours, minutes, seconds] = time.split(':');
  const date = new Date();
  date.setHours(Number.parseInt(hours), Number.parseInt(minutes), Number.parseInt(seconds));
  return date;
};
