import { List, ListItem, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = {
  values: {
    firmName: string | null;
    isRevised: boolean;
    isUsersFirm: boolean;
  }[];
};

const AcknowledgedByList: React.FC<Props> = ({ values }) => {
  if (!values.length) {
    return <Typography>-</Typography>;
  }

  return (
    <List disablePadding>
      {values.map(({ firmName, isUsersFirm, isRevised }) => (
        <ListItem disablePadding key={firmName}>
          <Typography
            fontWeight={isUsersFirm && isRevised ? 'bold' : undefined}
            color={isRevised ? theme => theme.palette.warning.dark : undefined}
          >
            {firmName ?? '-'}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default AcknowledgedByList;
