import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import {
  OfferingSetup_Summary_DiscardOfferingDocument,
  OfferingSetup_Summary_DiscardOfferingMutation,
  OfferingSetup_Summary_DiscardOfferingMutationVariables,
} from '../../graphql';

/**
 * useDiscardOfferingMutation discards the offering
 */
export const useDiscardOfferingMutation = () => {
  return useServiceMutation<
    OfferingSetup_Summary_DiscardOfferingMutation,
    OfferingSetup_Summary_DiscardOfferingMutationVariables
  >(OfferingSetup_Summary_DiscardOfferingDocument, {});
};
