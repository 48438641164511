import styled from 'styled-components/macro';

export const SAllocationContainer = styled.div`
  margin-top: 16px;
`;

export const SAllocationRow = styled.div`
  display: flex;
`;

export const SColumn = styled.div<{ align: 'left' | 'center' | 'right' }>`
  padding-right: 5px;
  text-align: ${props => props.align};
  align-self: center;
`;

export const SSharesColumn = styled(SColumn)`
  flex: 0 0 160px;
  padding-right: 60px;
`;
