import { TableBody, TableGroupHeader, TableGroupHeaderCell } from '@cmg/design-system';
import React from 'react';

import { syndicateWireManagerRoleLabels } from '../../../../../../types/domain/syndicate-wires/constants';
import { RecipientUserData, SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { managersToRoleGroupedEntries } from '../navigation/managers-side-nav/ManagersNavigation.model';
import { RecipientFormValues } from './hooks/useRecipientsUserDataForm';
import {
  Props as RecipientsUserDataFormFieldsProps,
  RecipientsUserDataFormFields,
} from './RecipientsUserDataFormFields';

export type Props<TRecipientUserData extends RecipientUserData> = Omit<
  RecipientsUserDataFormFieldsProps<TRecipientUserData, SyndicateWireManager>,
  'allManagers' | 'managersToRender'
> &
  Readonly<{
    readonly managers: SyndicateWireManager[];
    readonly numColumns: number;
  }>;

export const SyndicateRecipientsUserDataFormFields = <
  TRecipientUserData extends RecipientUserData,
  TValues extends RecipientFormValues<TRecipientUserData>
>({
  managers,
  numColumns,
  operationType,
  defaultRecipientValues,
  renderRecipientRow,
}: Props<TRecipientUserData>) => {
  const managersByRoles = React.useMemo(() => managersToRoleGroupedEntries(managers), [managers]);

  return (
    <React.Fragment>
      {managersByRoles.map(([role, roleManagers]) => {
        return (
          <TableBody key={role} aria-label={syndicateWireManagerRoleLabels[role]}>
            <TableGroupHeader>
              <TableGroupHeaderCell colSpan={numColumns}>
                {syndicateWireManagerRoleLabels[role]}
              </TableGroupHeaderCell>
            </TableGroupHeader>
            <RecipientsUserDataFormFields<TRecipientUserData, TValues, SyndicateWireManager>
              allManagers={managers}
              managersToRender={roleManagers}
              defaultRecipientValues={defaultRecipientValues}
              operationType={operationType}
              renderRecipientRow={renderRecipientRow}
            />
          </TableBody>
        );
      })}
    </React.Fragment>
  );
};
