import * as yup from 'yup';

export type ContactSupportModalFormUserValues = {
  subject: string;
  description: string;
};

export type ContactSupportModalFormValues = ContactSupportModalFormUserValues & {
  email: string;
};

export type Recipient = {
  cmgEntityKey: string;
  firmName: string;
};

export const validationSchema = yup.object().shape({
  subject: yup.string().trim().required('Subject is required'),
  description: yup.string().trim().required('Description is required'),
});
