import { UUID } from '@cmg/common';
import React from 'react';

import { ExportContextProvider } from '../../components/export-button/ExportContext';
import { OrderBook_TotalRetailDemandQuery } from '../graphql';
import AutoSaveTotalRetailDemand from './auto-save/AutoSaveTotalRetailDemand';
import BulkSaveTotalRetailDemand from './bulk-save/BulkSaveTotalRetailDemand';
import { useExportTotalRetailDemand } from './hooks/useExportTotalRetailDemand';

export type Props = Readonly<{
  data?: OrderBook_TotalRetailDemandQuery;
  offeringIssuerName: string;
  offeringId: UUID;
}>;

const TotalRetailDemandPageLayout: React.FC<Props> = ({ data, offeringIssuerName, offeringId }) => {
  const { handleExport, loading: isExporting } = useExportTotalRetailDemand({
    offeringId,
    offeringIssuerName,
  });

  const isUsingBulkSave = React.useMemo(
    () =>
      data &&
      data.totalRetailSummary.items.length > 0 &&
      data.totalRetailSummary.items.some(({ retention }) => retention?.isReleased),
    [data]
  );

  return (
    <div data-testid="demand-data-layout">
      <ExportContextProvider isExporting={isExporting} onExport={handleExport}>
        {isUsingBulkSave ? (
          <BulkSaveTotalRetailDemand
            rows={data?.totalRetailSummary.items ?? []}
            offeringId={offeringId}
            instrumentId={data?.publishedOffering.instruments[0].id!}
            trancheId={data?.publishedOffering.tranches![0].id!}
            retailTarget={data?.totalRetailSummary.retailTarget ?? null}
          />
        ) : (
          <AutoSaveTotalRetailDemand
            rows={data?.totalRetailSummary.items ?? []}
            offeringId={offeringId}
            instrumentId={data?.publishedOffering.instruments[0].id!}
            trancheId={data?.publishedOffering.tranches![0].id!}
            offeringIssuerName={offeringIssuerName}
            retailTarget={data?.totalRetailSummary.retailTarget ?? null}
          />
        )}
      </ExportContextProvider>
    </div>
  );
};

export default TotalRetailDemandPageLayout;
