import { Icon } from '@cmg/common';
import { Skeleton } from '@cmg/design-system';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { AttestationFormStatus } from '../../../graphql';
import { AttestationFormStatusDisplayName, getAttestationStatusColor } from './Attestation.model';
import { SStatusLabel } from './AttestationFormStatusRenderer.styles';

export type Props = {
  status: AttestationFormStatus | null | undefined;
};

const AttestationFormStatusRenderer: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  if (!status) {
    return <Skeleton role="loading" variant="rectangular" height={10} />;
  }

  return (
    <div>
      <Icon
        name="circle"
        variant="solid"
        size="xs"
        color={getAttestationStatusColor(status, theme)}
      />
      <SStatusLabel>{AttestationFormStatusDisplayName[status]}</SStatusLabel>
    </div>
  );
};

export default AttestationFormStatusRenderer;
