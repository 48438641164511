import { Stack } from '@cmg/design-system';
import React from 'react';

import { EmptyState } from '../../../../../../../design-system/components/data-display/empty-state/EmptyState';
import { ProspectusHistoryTable } from './ProspectusHistoryTable';

export const ProspectusHistoryZeroState: React.FC = () => {
  return (
    <Stack gap={4} m={2} flexDirection="column">
      <ProspectusHistoryTable revisions={[]} />
      <EmptyState
        title="Prospectus documents not sent"
        message={
          <React.Fragment>
            There has to be a prospectus document present on the public offering.
            <br />
            Investor contacts have to be present on the indication.
            <br />
            Indication has to be acknowledged.
          </React.Fragment>
        }
      />
    </Stack>
  );
};
