import { Popover } from '@cmg/common';
import React from 'react';

import { CalendarGridRendererProps } from '../../../../calendar.model';
import { SLeftLead } from './LeftLeadRenderer.styles';
import { ManagerList } from './ManagerList';

/**
 * LeftLeadRenderer cell renderer that displays the Left Lead and a tooltip with managers and their roles
 */
export const LeftLeadRenderer = ({ value, data: { managers } }: CalendarGridRendererProps) => {
  if (!managers.length) {
    return <SLeftLead hasTooltip={false}>{value ?? '-'}</SLeftLead>;
  }

  return (
    <Popover variant="DARK" placement="left" content={<ManagerList managers={managers} />}>
      <SLeftLead hasTooltip={true}>{value ?? '-'}</SLeftLead>
    </Popover>
  );
};
