import { Option } from '@cmg/common';

import { MarketTiming } from '../../../graphql';

export const marketTimingLabels = {
  [MarketTiming.PreOpen]: 'Pre-open',
  [MarketTiming.IntraDay]: 'Intra-day',
  [MarketTiming.PostClose]: 'Post-close',
};

export const marketTimingOptions = Object.values(MarketTiming).map<Option<MarketTiming>>(value => ({
  label: marketTimingLabels[value],
  value: value as MarketTiming,
}));
