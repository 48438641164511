import { numericUtil, UUID } from '@cmg/common';
import { Link, TableCell, TableRow } from '@cmg/design-system';
import { FormikNumberField } from '@cmg/design-system-formik';
import { format } from 'date-fns';
import React from 'react';

import routeFactory from '../../../../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../../../../types/domain/manager/constants';
import TableCellOnPlatformIcon from '../../../../../components/table-cell-on-platform-icon/TableCellOnPlatformIcon';
import {
  OrderBook_TotalRetailSummaryItemManagerPartsFragment,
  OrderBook_TotalRetailSummaryItemPartsFragment,
} from '../../../../graphql';
import { getLatestSummaryItem, getManagerName } from '../../../common/utils/TableRowUtils';

const { getDisplayValueForInteger } = numericUtil;

export type Props = Readonly<{
  offeringId: UUID;
  row: OrderBook_TotalRetailSummaryItemPartsFragment;
  allManagers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[];
  isEditing: boolean;
  index: number;
}>;

const TotalRetailDemandTableRow: React.FC<Props> = ({
  offeringId,
  row,
  allManagers,
  isEditing,
  index,
}) => {
  const { manager, demand, retention } = row;
  const { modifiedByFirmKey, modifiedAt } = getLatestSummaryItem(demand, retention) ?? {};
  const submittedByManager = getManagerName(allManagers, modifiedByFirmKey);
  const cellSize = isEditing ? 'small' : 'medium';

  return (
    <TableRow data-testid="retail-demand-data-row">
      <TableCellOnPlatformIcon size="small" isOnPlatform={manager.isOnPlatform} />
      <TableCell size={cellSize} sx={{ whiteSpace: 'nowrap' }}>
        <Link
          href={routeFactory.orderBookTotalRetailDemandHistory.getUrlPath({
            offeringId,
            cmgEntityKey: manager.cmgEntityKey,
          })}
        >
          {manager.name}
        </Link>
      </TableCell>
      <TableCell size={cellSize}>{managerRoleLabels[manager.role]}</TableCell>
      <TableCell size={cellSize} sx={{ whiteSpace: 'nowrap' }}>
        {submittedByManager || '-'}
      </TableCell>
      <TableCell size={cellSize}>
        {modifiedAt ? format(new Date(modifiedAt), 'MM/dd/yyyy HH:mm a') : '-'}
      </TableCell>
      <TableCell size={cellSize} align="right">
        {demand.isSubmitted || !isEditing ? (
          getDisplayValueForInteger(demand?.shareQuantity)
        ) : (
          <FormikNumberField
            aria-label="demand input"
            name={`demands.${index}.demandShares`}
            decimalScale={0}
            showHelperTextInTooltip
            fullWidth
          />
        )}
      </TableCell>
      <TableCell size={cellSize} align="right">
        {demand.isSubmitted || !isEditing ? (
          getDisplayValueForInteger(demand?.roundLots)
        ) : (
          <FormikNumberField
            aria-label="round lots input"
            name={`demands.${index}.roundLots`}
            decimalScale={0}
            showHelperTextInTooltip
            fullWidth
          />
        )}
      </TableCell>
      <TableCell size={cellSize} align="right">
        {!isEditing ? (
          getDisplayValueForInteger(retention?.shareQuantity)
        ) : (
          <FormikNumberField
            aria-label="retention input"
            name={`demands.${index}.retentionShares`}
            decimalScale={0}
            showHelperTextInTooltip
            fullWidth
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TotalRetailDemandTableRow;
