import {
  SyndicateWires_UpdateDesignationWireMutation,
  useSyndicateWires_UpdateDesignationWireMutation,
} from '../graphql';

export type UpdateDesignationWireMutation = SyndicateWires_UpdateDesignationWireMutation;

export const useUpdateDesignationWireMutation = () => {
  return useSyndicateWires_UpdateDesignationWireMutation();
};
