import { Icon } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export const SRouteHeader = styled.h2`
  font-weight: 500;
  margin-left: 10px;
`;

export const SVisibilityNote = styled.span.attrs<{ children: React.ReactChildren }>(
  ({ children }) => ({
    children: (
      <React.Fragment>
        <Icon name="info-circle" variant="solid" size="lg" className="icon" />
        {children}
      </React.Fragment>
    ),
  })
)`
  margin-bottom: 15px;
  padding: 10px;
  background-color: ${({ theme }) => theme.brand.color.infoBanner};

  .icon {
    color: ${({ theme }) => theme.brand.color.info};
    margin-right: 10px;
  }
`;
