import { Box, Divider, PageHeader, PageLayout, TableSkeleton } from '@cmg/design-system';
import React from 'react';

const TotalRetailDemandSkeleton: React.FC = () => {
  return (
    <PageLayout
      header={
        <React.Fragment>
          <PageHeader pageTitle="Total Demand" />
          <Divider />
        </React.Fragment>
      }
    >
      <Box py={1}>
        <TableSkeleton cellSize="medium" numOfColumns={8} />
      </Box>
    </PageLayout>
  );
};

export default TotalRetailDemandSkeleton;
