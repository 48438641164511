import {
  QueryInstrumentCountryCode,
  QueryIssuerSector,
  QueryOfferingType,
} from '@capital-markets-gateway/api-client-offering-setup';
import { format } from 'date-fns';

import { CalendarFilters } from '../../hooks/useFilters';
import {
  getPostponedOfferingsDateBoundaries,
  getPricedOfferingsDateBoundaries,
} from '../../utils/dates';
import { SpacFilterOption } from '../calendar-filters/components/spac-filter/SpacFilter';

export function generateFileName() {
  const date = new Date();
  return `XC_Calendar_Offerings_${format(date, 'yyyy-MM-dd')}.xlsx`;
}

// We identified a bug with how we were exporting calendar filters. This solution reduces the surface area of the bug.
// The API will be updated with new fields to allow for isUnderwriterOnDeal and leftLead filters in the future.
// Until then, this function will serve to allow some functionality for both the underwriters filter and the isUnderwriterOnDeal filter.
const calculateCmgEntityKey = ({
  filters,
  userCmgEntityKey,
}: {
  filters: CalendarFilters;
  userCmgEntityKey: string | null;
}) => {
  if (filters.isUnderwriterOnDeal && filters.underwriters.length) {
    return filters.underwriters;
  } else if (filters.isUnderwriterOnDeal && !!userCmgEntityKey) {
    return [userCmgEntityKey];
  } else {
    return filters.underwriters;
  }
};

type RestParams = {
  cmgEntityKey: string[];
  types: QueryOfferingType[];
  issuerSectors: QueryIssuerSector[];
  isSpac: boolean | undefined;
  startPricingDate: string;
  endPricingDate: string;
  startPostponedDate: string;
  endPostponedDate: string;
  pricingInstrumentCountryCode: QueryInstrumentCountryCode[];
};

export function filtersToExportCalendarRestParams(
  filters: CalendarFilters,
  userCmgEntityKey: string | null
): RestParams {
  let isSpacParam: undefined | boolean = undefined;
  if (filters.isSpac === SpacFilterOption.ONLY) {
    isSpacParam = true;
  }
  if (filters.isSpac === SpacFilterOption.EXCLUDE) {
    isSpacParam = false;
  }

  const firstTradeDate = getPricedOfferingsDateBoundaries();
  const postponedDate = getPostponedOfferingsDateBoundaries();

  return {
    startPricingDate: firstTradeDate.start,
    endPricingDate: firstTradeDate.end,
    startPostponedDate: postponedDate.start,
    endPostponedDate: postponedDate.end,
    isSpac: isSpacParam,
    types: filters.type as unknown as QueryOfferingType[],
    issuerSectors: filters.issuerSector as unknown as QueryIssuerSector[],
    cmgEntityKey: calculateCmgEntityKey({ filters, userCmgEntityKey }),
    // The cmgEntityKey field is used to filter by users participating in the syndicate lineup. (analogous to the underwriters filter)
    pricingInstrumentCountryCode: filters.country as unknown as QueryInstrumentCountryCode[],
  };
}
