import styled from 'styled-components/macro';

export const SNotesTitle = styled.h2`
  font-size: ${({ theme }) => theme.text.size.large};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  margin: 20px 0 10px 10px;
`;

export const SNotesContainer = styled.div`
  margin: 10px 30px;
`;
