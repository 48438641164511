import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import type { UUID } from '@cmg/common';
import type { BulkActionToolPanelProps } from '@cmg/data-grid';
import { BulkActionToolPanel } from '@cmg/data-grid';
import { LoadingButton } from '@cmg/design-system';
import React from 'react';

import { useFeatureToggles } from '../../../../common/config';
import { useCheckDeliveryInstructionAccess } from '../../../../common/util/check-access/useCheckDeliveryInstructionAccess';
import { useCheckInstitutionalDuplicateAccess } from '../../../../common/util/check-access/useCheckInstitutionalDuplicateAccess';

type OwnProps = Readonly<{
  offeringId: UUID;
  isProspectusDocumentUploaded: boolean;
  isSavingAllocations: boolean;
  onEnterAllocations: () => void;
  isSavingBndAgent: boolean;
  onAssignBndAgent: () => void;
  isSavingMarkAsDuplicate: boolean;
  onMarkAsDuplicate: () => void;
  isSavingSendProspectus: boolean;
  onSendProspectus: () => void;
}>;

export type Props = Omit<BulkActionToolPanelProps, 'minRowSelectionCount'> & OwnProps;

export const BulkActions: React.FC<Props> = ({
  offeringId,
  isProspectusDocumentUploaded,
  onEnterAllocations,
  isSavingAllocations,
  onAssignBndAgent,
  isSavingBndAgent,
  onMarkAsDuplicate,
  isSavingMarkAsDuplicate,
  onSendProspectus,
  isSavingSendProspectus,
  rowSelectionState,
  ...rest
}) => {
  /**
   * Permission checks
   */
  const canManageOrderBook = useCheckPermissions([permissionsByEntity.OrderBook.FULL]);
  const { canManageDuplicate } = useCheckInstitutionalDuplicateAccess({ offeringId });
  const deliveryInstructionAccess = useCheckDeliveryInstructionAccess({ offeringId });

  const { isOrderBookManageDuplicateOn } = useFeatureToggles();

  const isSendProspectusVisible = canManageOrderBook && isProspectusDocumentUploaded;
  const isAssignBnDAgentVisible = deliveryInstructionAccess.canUpdate;
  const isMarkAsDuplicateVisible =
    !rowSelectionState.selectAll && canManageDuplicate && isOrderBookManageDuplicateOn;

  return (
    <BulkActionToolPanel {...rest} rowSelectionState={rowSelectionState}>
      <LoadingButton variant="outlined" onClick={onEnterAllocations} loading={isSavingAllocations}>
        Enter Allocations
      </LoadingButton>
      {isSendProspectusVisible && (
        <LoadingButton
          variant="outlined"
          onClick={onSendProspectus}
          loading={isSavingSendProspectus}
        >
          Send Prospectus
        </LoadingButton>
      )}
      {isMarkAsDuplicateVisible && (
        <LoadingButton
          variant="outlined"
          onClick={onMarkAsDuplicate}
          loading={isSavingMarkAsDuplicate}
        >
          Mark as Duplicate
        </LoadingButton>
      )}
      {isAssignBnDAgentVisible && (
        <LoadingButton variant="outlined" onClick={onAssignBndAgent} loading={isSavingBndAgent}>
          Assign B&D Agent
        </LoadingButton>
      )}
    </BulkActionToolPanel>
  );
};
