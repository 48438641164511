import styled from 'styled-components/macro';

export const SCellData = styled.span`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.white};
`;

export const SCellSubData = styled.span`
  font-size: ${({ theme }) => theme.text.size.small};
  color: rgba(255, 255, 255, 0.88);
`;
