import { Divider, Stack, Typography } from '@cmg/design-system';
import { VFC } from 'react';

import {
  getInvestorDisplayName,
  ProspectusOnSendValidationAlertInvestor,
} from './ProspectusOnSendValidationAlert.model';

export type DialogBodySectionProps = Readonly<{
  readonly title: string;
  readonly investors: ProspectusOnSendValidationAlertInvestor[];
}>;

export const DialogBodySection: VFC<DialogBodySectionProps> = ({ title, investors }) => {
  if (investors.length === 0) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="h3" px={1} py={2}>
        {title}
      </Typography>
      <Divider />
      <Stack pt={1}>
        {investors.map(investor => (
          <Typography key={investor.cmgEntityKey} px={1}>
            {getInvestorDisplayName(investor)}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};
