import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import React from 'react';

import { RecipientListManagerRole } from '../../lists/recipient-list/RecipientList.model';
import RecipientListWarningIcon from '../../lists/recipient-list/RecipientListWarningIcon';
import useSelectRecipients, { Recipient } from './hooks/useSelectRecipients';
import RecipientDistributionEmails from './RecipientDistributionEmails';

export type Props<TManagerRole extends RecipientListManagerRole> = Readonly<{
  readonly recipients: Recipient<TManagerRole>[];
  readonly getManagerRoleLabel: (role: TManagerRole) => string;
  readonly onSelectionChange: (selectedRecipients: string[]) => void;
  readonly onContactSupport: () => void;
  readonly withDistributionList?: boolean;
}>;

function SelectRecipientsTable<TManagerRole extends RecipientListManagerRole>({
  recipients,
  onSelectionChange,
  onContactSupport,
  withDistributionList = true,
  getManagerRoleLabel,
}: Props<TManagerRole>) {
  const {
    groupedRecipients,
    isDisabled,
    isIndeterminate,
    isSelected,
    handleToggleSelectRecipient,
    handleToggleSelectRole,
    handleToggleSelectAll,
  } = useSelectRecipients({ recipients, onSelectionChange });

  return (
    <TableContainer>
      <Table>
        <TableHead aria-label="Recipients table header">
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                inputProps={{
                  'aria-label': 'Select all recipients',
                }}
                checked={isSelected}
                disabled={isDisabled}
                indeterminate={isIndeterminate}
                onChange={handleToggleSelectAll}
              />
            </TableCell>
            <TableCell width={withDistributionList ? '50%' : undefined}>Recipient</TableCell>
            {withDistributionList && <TableCell width="50%">Email Distribution List</TableCell>}
          </TableRow>
        </TableHead>
        {groupedRecipients.map(([roleGroup, recipients]) => {
          const managerRoleLabel = getManagerRoleLabel(roleGroup.role);
          return (
            <TableBody key={roleGroup.role} title={managerRoleLabel}>
              <TableGroupHeader
                aria-label={`${managerRoleLabel} group header`}
                disabled={roleGroup.isDisabled}
              >
                <TableGroupHeaderCell padding="checkbox">
                  <Checkbox
                    inputProps={{
                      'aria-label': `Select ${managerRoleLabel}`,
                    }}
                    checked={roleGroup.isSelected}
                    disabled={roleGroup.isDisabled}
                    indeterminate={roleGroup.isIndeterminate}
                    onChange={() => handleToggleSelectRole(roleGroup.role)}
                  />
                </TableGroupHeaderCell>
                <TableGroupHeaderCell colSpan={withDistributionList ? 2 : 1}>
                  {managerRoleLabel}
                </TableGroupHeaderCell>
              </TableGroupHeader>
              {recipients.map(recipient => (
                <TableRow
                  key={recipient.cmgEntityKey}
                  aria-selected={recipient.isSelected}
                  selected={recipient.isSelected}
                  title={recipient.firmName}
                  disabled={recipient.isDisabled}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={recipient.isSelected}
                      disabled={recipient.isDisabled}
                      onChange={() => handleToggleSelectRecipient(recipient.cmgEntityKey)}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                      {recipient.firmName}
                      {withDistributionList && !recipient.distributionList.length && (
                        <RecipientListWarningIcon onContactSupport={onContactSupport} />
                      )}
                    </Stack>
                  </TableCell>
                  {withDistributionList && (
                    <TableCell sx={{ maxWidth: 0 }}>
                      <RecipientDistributionEmails distributionList={recipient.distributionList} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
}

export default SelectRecipientsTable;
