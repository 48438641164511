import { FormField } from '@cmg/common';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../common/components/layout/panel/Panel';
import IssuerSymbolInfo from '../issuer-symbol-info/IssuerSymbolInfo';

type Props = {
  issuerName: string;
  issuerSymbol: string;
};

const FilingDetails: React.FC<Props> = ({ issuerName, issuerSymbol, children }) => {
  return (
    <Panel>
      <Panel.Header title="Filing Details" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            <FormField withMargin fullWidth label="Issuer Name">
              <div>{issuerName}</div>
            </FormField>
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <FormField
              withMargin
              fullWidth
              label="Issuer Symbol"
              renderInfo={() => <IssuerSymbolInfo />}
            >
              <div>{issuerSymbol}</div>
            </FormField>
          </Column>
        </GridLayout>
        {children}
      </Panel.Content>
    </Panel>
  );
};

export default FilingDetails;
