import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledStatusIcon = styled(Icon)<{ color?: string }>`
  margin-right: 5px;
  color: ${({ color, theme }) => color || theme.brand.color.danger};
`;

export const SStatus = styled.div`
  display: flex;
  align-items: center;
`;

export type StatusVariant = 'LIGHT' | 'DARK';

export const SLabel = styled.div<{ variant?: StatusVariant }>`
  color: ${({ theme, variant = 'LIGHT' }) =>
    variant === 'LIGHT' ? theme.text.color.light : theme.text.color.dark};
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  text-transform: none;
`;
