import { Banner, SecondaryButton, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Column from '../../../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../../../common/components/layout/grid-layout/GridLayout';
import Modal from '../../../../../../../../common/components/overlays/modal/Modal';
import {
  AdHocParticipantValues,
  initialValues,
  validationSchema,
} from './CreateParticipantModal.model';
import { SModalContent, StyledPrimaryButton } from './CreateParticipantModal.styles';

export type Props = {
  show: boolean;
  onClose: () => void;
  companyName: string;
  onSubmit: (values: AdHocParticipantValues) => void;
};

const CreateParticipantModal: React.FC<Props> = ({ show, onClose, companyName, onSubmit }) => {
  const formik = useFormik<AdHocParticipantValues>({
    validationSchema,
    initialValues,
    onSubmit: values => {
      onClose();
      onSubmit(values);
    },
  });

  const { submitForm } = formik;

  return (
    <Modal
      title="Create New Participant"
      show={show}
      onHide={onClose}
      shouldCloseOnOverlayClick={false}
    >
      <SModalContent>
        <Banner variant="info">
          This participant would be added as a custom member of {companyName} team.
        </Banner>
        <FormikProvider value={formik}>
          <Form>
            <GridLayout>
              <Column span={6}>
                <TextInputField name="name" label="Name" required fullWidth withMargin />
              </Column>
              <Column span={6}>
                <TextInputField name="title" label="Title" fullWidth withMargin />
              </Column>
            </GridLayout>
            <GridLayout>
              <Column span={4}>
                <TextInputField name="mobilePhone" label="Mobile Phone" fullWidth withMargin />
              </Column>
              <Column span={4}>
                <TextInputField name="officePhone" label="Office Phone" fullWidth withMargin />
              </Column>
              <Column span={4}>
                <TextInputField name="email" label="E-mail" required fullWidth withMargin />
              </Column>
            </GridLayout>
          </Form>
        </FormikProvider>
      </SModalContent>
      <Modal.Footer>
        <SecondaryButton
          testId={xcSelectors.roadshowParticipantsCreateModalCancelButton.testId}
          onClick={onClose}
        >
          Cancel
        </SecondaryButton>
        <StyledPrimaryButton
          testId={xcSelectors.roadshowParticipantsCreateModalSubmitButton.testId}
          onClick={submitForm}
        >
          Add Participant
        </StyledPrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateParticipantModal;
