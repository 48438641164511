import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import OfferingNote from './components/offering-note/OfferingNote';
import { OfferingNotes_OfferingNotesListPartsFragment } from './graphql';

type Props = {
  notes: OfferingNotes_OfferingNotesListPartsFragment['notes'];
  handleDeleteOfferingNote: (noteId: string) => void;
  handleEditOfferingNote: (noteId: string) => void;
};

const OfferingNotesList: React.FC<Props> = ({
  notes,
  handleDeleteOfferingNote,
  handleEditOfferingNote,
}) => {
  return (
    <div data-test-id={xcSelectors.offeringNotesList.testId}>
      {notes.map(note => (
        <OfferingNote
          key={note.id}
          note={note}
          handleDeleteOfferingNote={handleDeleteOfferingNote}
          handleEditOfferingNote={handleEditOfferingNote}
        />
      ))}
    </div>
  );
};

export default OfferingNotesList;
