import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { useAuth } from '@cmg/auth';
import { UUID } from '@cmg/common';
import React from 'react';

import { useSyndicateWires_SyndicateWiresManagerListQuery } from '../graphql';

export function useSyndicateManagerListQuery(
  { offeringId }: { offeringId: UUID },
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) {
  const { oidcUserCmgEntityKey } = useAuth();
  const query = useSyndicateWires_SyndicateWiresManagerListQuery({
    variables: { offeringId },
    fetchPolicy,
  });

  const allManagers = query.data?.syndicateWires.managers;
  const managersWithoutCurrent = React.useMemo(() => {
    // Filter out current user firm as it's not allowed to send wires to own firm.
    return (allManagers ?? []).filter(manager => manager.cmgEntityKey !== oidcUserCmgEntityKey);
  }, [allManagers, oidcUserCmgEntityKey]);

  return {
    ...query,
    data: managersWithoutCurrent,
  };
}
