import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';

import { orderBookApiClient } from '../../../../common/api/orderBookApiClient';
import { DemandConfigValues } from '../demand-grid/types';

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
}>;

export const useExportInstitutionalDemand = ({ offeringId, demandConfig }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleExport = useCallback(async () => {
    setLoading(true);

    try {
      const response =
        await orderBookApiClient.orderBookSummary_GetInstitutionalDemandSummaryExport(
          offeringId,
          demandConfig?.min,
          demandConfig?.max,
          demandConfig?.increment,
          demandConfig?.referencePrice
        );
      await saveAs(response.data, `InstitutionalDemandSummary_${Date.now()}.xlsx`);
    } catch {
      SnackbarManager.error('Failed to export Institutional Demand data.');
    } finally {
      setLoading(false);
    }
  }, [
    demandConfig?.increment,
    demandConfig?.max,
    demandConfig?.min,
    demandConfig?.referencePrice,
    offeringId,
  ]);

  return [handleExport, { loading }] as const;
};
