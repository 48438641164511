import { Box, Stack, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = {
  alertsCount: number;
  onSortHandle: () => void;
};

export const RowAlertHeaderTooltipContent: React.FC<Props> = ({ alertsCount, onSortHandle }) => {
  return (
    <Box
      sx={{
        width: 155,
      }}
    >
      <Stack>
        <Typography variant="tooltip" fontWeight="bold">
          {alertsCount === 1
            ? `${alertsCount} investor requires attention`
            : `${alertsCount} investors require attention`}
        </Typography>
        <Typography
          variant="tooltip"
          fontWeight="bold"
          onClick={onSortHandle}
          component="a"
          sx={{ cursor: 'pointer' }}
        >
          Sort by investors with alerts
        </Typography>
      </Stack>
    </Box>
  );
};
