import { numericUtil } from '@cmg/common';
import { TableCell } from '@cmg/design-system';
import { FormikCurrencyField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React from 'react';

import { useSetDependentFieldValue } from '../hooks/useSetDependentFieldValue';
import { UnderwritingTermsDiscountsAndFeesFormValues } from '../UnderwritingTermsDiscountsAndFeesSection';

type TableCellCurrencyProps = {
  name: string;
  tabIndex?: number;
  disabled?: boolean;
  currencyCode: string;
};

export function TableCellCurrency({
  name,
  tabIndex,
  disabled,
  currencyCode,
}: TableCellCurrencyProps) {
  const { values, status } = useFormikContext<UnderwritingTermsDiscountsAndFeesFormValues>();
  const fieldValue = get(values, name);

  const { updateDependantFieldsOnValueChange } = useSetDependentFieldValue(name);

  const isEditable = status === 'editing-currency';
  if (isEditable) {
    return (
      <TableCell align="right">
        <FormikCurrencyField
          onChange={updateDependantFieldsOnValueChange}
          name={name}
          decimalScale={6}
          fixedDecimalScale
          tabIndex={tabIndex}
          showHelperTextInTooltip
          disabled={isNil(fieldValue) && disabled}
          autoComplete="off"
          currencyCode={currencyCode}
          sx={{ maxWidth: 140 }}
          allowNegative={false}
        />
      </TableCell>
    );
  }
  return (
    <TableCell align="right" sx={{ paddingRight: '22px' }}>
      {numericUtil.getDisplayValueForCurrency(fieldValue, 6, currencyCode)}
    </TableCell>
  );
}
