import { timeUtil } from '@cmg/common';
import {
  DotStatus,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';

import { AttestationFormStatus, CertificateType } from '../../../../../../../../graphql';
import {
  STATUS_COLORS,
  STATUS_LABELS,
  TYPE_LABELS,
} from '../../../../../../certificate-library.model';
import { CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment } from '../../../../../../graphql/__generated__';
import { useCertificateQueryParams } from '../../../../../../hooks/useCertificateQueryParams';
import CopyLinkActionBtn from '../copy-link-button/CopyLinkButton';
import DownloadPdfButton from '../download-pdf-button/DownloadPdfButton';

type Props = {
  certificates: readonly CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment[];
};

const CertificateStatusCell: React.FC<{
  certificateStatus: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['certificateStatus'];
}> = ({ certificateStatus }) => (
  <TableCell>
    <DotStatus
      color={STATUS_COLORS[certificateStatus]}
      label={STATUS_LABELS[certificateStatus]}
      titleAccess={STATUS_COLORS[certificateStatus]}
    />
  </TableCell>
);

const CertificateExpirationDateCell: React.FC<{
  certificateStatus: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['certificateStatus'];
  expirationDate?: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['expirationDate'];
}> = ({ certificateStatus, expirationDate }) => (
  <TableCell
    sx={{
      color: theme => (certificateStatus === 'EXPIRED' ? theme.palette.error.main : 'inherit'),
    }}
  >
    {expirationDate && timeUtil.formatAsDisplayDate(expirationDate)}
  </TableCell>
);

const CertificateSignatureDateCell: React.FC<{
  signatoryDate?: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['signatoryDate'];
}> = ({ signatoryDate }) => (
  <TableCell>{signatoryDate && timeUtil.formatAsDisplayDate(signatoryDate)}</TableCell>
);

const CertificateActionCell: React.FC<{
  certificateStatus: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['certificateStatus'];
  certificateId?: CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment['certificateId'];
}> = ({ certificateStatus, certificateId }) => {
  const { cmgEntityKey } = useCertificateQueryParams();

  // No firm id means we don't have a certificate to download
  if (!cmgEntityKey) {
    return (
      <TableCell>
        <CopyLinkActionBtn />
      </TableCell>
    );
  }

  return (
    <TableCell>
      {certificateStatus === AttestationFormStatus.NotOnFile ||
      certificateStatus === AttestationFormStatus.Expired ? (
        <CopyLinkActionBtn />
      ) : (
        <DownloadPdfButton cmgEntityKey={cmgEntityKey} certificateId={certificateId} />
      )}
    </TableCell>
  );
};

const CurrentCertificatesTable: React.FC<Props> = ({ certificates }) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Certificate</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Signature Date</TableCell>
          <TableCell>Expiration</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(CertificateType).map(certType => {
          const cert = certificates.find(c => c.certificateType === certType);

          if (!cert) {
            return (
              <TableRow key={certType}>
                <TableCell>{TYPE_LABELS[certType]}</TableCell>
                <CertificateStatusCell certificateStatus={AttestationFormStatus.NotOnFile} />
                <CertificateSignatureDateCell signatoryDate="" />
                <CertificateExpirationDateCell
                  certificateStatus={AttestationFormStatus.NotOnFile}
                  expirationDate=""
                />
                <CertificateActionCell
                  certificateId=""
                  certificateStatus={AttestationFormStatus.NotOnFile}
                />
              </TableRow>
            );
          }

          return (
            <TableRow key={certType}>
              <TableCell>{TYPE_LABELS[certType]}</TableCell>
              <CertificateStatusCell certificateStatus={cert.certificateStatus} />
              <CertificateSignatureDateCell signatoryDate={cert.signatoryDate} />
              <CertificateExpirationDateCell
                certificateStatus={cert.certificateStatus}
                expirationDate={cert.expirationDate}
              />
              <CertificateActionCell
                certificateId={cert.certificateId}
                certificateStatus={cert.certificateStatus}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CurrentCertificatesTable;
