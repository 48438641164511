import { ToastManager, UUID } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../../../../common/util/routeFactory';
import { DesignationStrategy } from '../../../../../../../graphql';
import InvestorSearch from '../../../../../../order-book/components/InvestorSearch';
import { useFinalSettlement_AssignDesignationToManagerMutation } from '../../../../graphql/__generated__';
import {
  useFinalSettlement_SettlementInvestorDesignationQuery,
  useFinalSettlement_SettlementInvestorsQuery,
} from '../../graphql/__generated__';
import {
  SSearchWrapper,
  SSection,
  SSpacer,
  StyledTotalsContainer,
} from '../OverwriteDesignationSidePanel.styles';
import DesignationTotals from './designation-totals/DesignationTotals';
import OverwriteDesignationForm from './overwrite-designation-form/OverwriteDesignationForm';
import {
  FormDesignation,
  getDesignationTotal,
  getInitialOverwriteDesignations,
  getInvestors,
  overwriteDesignationsValidationSchema,
  OverwriteForm,
} from './OverwriteDesignation.model';

export type Props = {
  offeringId: string;
  indicationId: string;
};

export const OverwriteDesignation: React.FC<Props> = ({ offeringId, indicationId }) => {
  const history = useHistory();
  const {
    data: investorsData,
    loading: investorsLoading,
    error: investorsError,
  } = useFinalSettlement_SettlementInvestorsQuery({ variables: { offeringId } });
  const {
    data: designationData,
    loading: designationLoading,
    error: designationError,
  } = useFinalSettlement_SettlementInvestorDesignationQuery({
    variables: { offeringId, indicationId },
  });
  const designation = designationData?.designationByIndicationId;
  const managers = designationData?.publishedOffering.syndicate.managers;

  const investors = getInvestors(investorsData?.designationsGrid.designations ?? []);

  const [overwriteDesignations, { loading: updateDesignationsLoading }] =
    useFinalSettlement_AssignDesignationToManagerMutation();

  const handleInvestorChange = (indicationId: UUID | null) => {
    indicationId &&
      history.push(
        routeFactory.finalSettlementDesignationMonitorOverwrite.getUrlPath({
          indicationId,
          offeringId,
        })
      );
  };

  const handleSubmitForm = async (values: FormDesignation[]) => {
    try {
      await overwriteDesignations({
        variables: {
          offeringId: offeringId,
          input: {
            operationType: DesignationStrategy.ManualDesignation,
            designations: values.map(({ managerCmgEntityKey, shares }) => ({
              indicationId,
              managerCmgEntityKey,
              shares,
            })),
          },
        },
      });

      ToastManager.success('Designations Saved');
    } catch {
      ToastManager.error('An error has occurred at saving designations');
    }
  };

  const formik = useFormik<OverwriteForm>({
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmitForm(values.formDesignations);
    },
    initialValues: getInitialOverwriteDesignations(designation, managers),
    validationSchema: overwriteDesignationsValidationSchema,
  });
  const { submitForm, isValid } = formik;

  const handleCancelForm = () => {
    history.push(
      routeFactory.finalSettlementDesignationMonitor.getUrlPath({
        offeringId,
      })
    );
  };

  const error = designationError || investorsError;
  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const isLoading = designationLoading || investorsLoading || updateDesignationsLoading;
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div role="presentation">
      <SSpacer />
      <SSearchWrapper>
        <InvestorSearch
          investors={investors}
          onChange={handleInvestorChange}
          currentKey={indicationId}
        />
      </SSearchWrapper>
      <SSection>
        <StyledTotalsContainer>
          <DesignationTotals
            allocations={designation!.allocation.shares}
            totalDesignations={getDesignationTotal(formik.values.formDesignations)}
          />
        </StyledTotalsContainer>
      </SSection>
      <FormikProvider value={formik}>
        <OverwriteDesignationForm
          designation={designation!}
          onCancel={handleCancelForm}
          onSubmit={submitForm}
          isValid={isValid}
        />
      </FormikProvider>
    </div>
  );
};

export default OverwriteDesignation;
