import {
  FileDownloadOutlinedIcon,
  LoadingButton,
  LoadingButtonProps,
  SnackbarManager,
} from '@cmg/design-system';
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';
import React from 'react';

export type OnExportResult = {
  fileContent?: string;
  fileContentBlob?: Blob;
  encodedFileName: string;
};

export type Props = Omit<LoadingButtonProps, 'onClick' | 'startIcon' | 'variant'> & {
  onExport: () => Promise<OnExportResult>;
};

const ExportButton: React.FC<Props> = ({ onExport, ...rest }) => {
  const handleExport = React.useCallback(async () => {
    try {
      const { fileContent, fileContentBlob, encodedFileName } = await onExport();
      if (!fileContent && !fileContentBlob) {
        return;
      }
      const fileBlobToSave = fileContentBlob || new Blob([Buffer.from(fileContent!, 'base64')]);
      saveAs(fileBlobToSave, encodedFileName);
      SnackbarManager.success('Export successfully completed.');
    } catch {
      SnackbarManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    }
  }, [onExport]);

  return (
    <LoadingButton
      {...rest}
      variant="outlined"
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={handleExport}
    >
      Export
    </LoadingButton>
  );
};

export default ExportButton;
