/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_UpdateDealTeamMemberMutationVariables = Types.Exact<{
  memberId: Types.Scalars['UUID'];
  payload: Types.UpdateRoadshowDealTeamMemberInput;
}>;

export type Roadshows_UpdateDealTeamMemberMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDealTeamMember: {
    readonly __typename?: 'DealTeamMember';
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly mobilePhone?: string | null;
    readonly officePhone?: string | null;
    readonly department?: string | null;
    readonly type: Types.DealTeamMemberType;
    readonly status: Types.DealTeamMemberStatus;
  };
};

export const Roadshows_UpdateDealTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateDealTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRoadshowDealTeamMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDealTeamMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'memberId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateDealTeamMemberMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateDealTeamMemberMutation,
  Roadshows_UpdateDealTeamMemberMutationVariables
>;

/**
 * __useRoadshows_UpdateDealTeamMemberMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateDealTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateDealTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateDealTeamMemberMutation, { data, loading, error }] = useRoadshows_UpdateDealTeamMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateDealTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateDealTeamMemberMutation,
    Roadshows_UpdateDealTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateDealTeamMemberMutation,
    Roadshows_UpdateDealTeamMemberMutationVariables
  >(Roadshows_UpdateDealTeamMemberDocument, options);
}
export type Roadshows_UpdateDealTeamMemberMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateDealTeamMemberMutation
>;
export type Roadshows_UpdateDealTeamMemberMutationResult =
  Apollo.MutationResult<Roadshows_UpdateDealTeamMemberMutation>;
export type Roadshows_UpdateDealTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateDealTeamMemberMutation,
  Roadshows_UpdateDealTeamMemberMutationVariables
>;
