import { PrimaryButton, SecondaryButton, ToastManager, UUID } from '@cmg/common';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { Roadshows_RoadshowsDealTeamPartsFragment } from '../../../../common/graphql';
import {
  useRoadshows_DealTeamQuery,
  useRoadshows_UpdateDealTeamMutation,
} from '../../../../single-deal-team/deal-team-details/graphql';
import { useRoadshows_RoadshowDealTeamOrganizationsQuery } from '../graphql';
import { useAssignDealTeamMembersMutation } from '../hooks/useAssignDealTeamMembersMutation';
import CreateMemberInfoBanner from './create-member-info-banner/CreateMemberInfoBanner';
import CreateNewMemberSection from './create-new-member-section/CreateNewMemberSection';
import {
  getAssignVariables,
  getCreateVariables,
  initialValues,
  validateSchema,
} from './CreateDealTeamMembersModal.model';
import { StyledModalContent, StyledModalFooter } from './CreateDealTeamMembersModal.styles';
import DealTeamOrganisationSection from './deal-team-organisation-section/DealTeamOrganisationSection';

export type Props = {
  show: boolean;
  onHide: () => void;
  roadshowId: UUID;
  participatingDealTeams: readonly Roadshows_RoadshowsDealTeamPartsFragment[];
};

const CreateDealTeamMembersModal: React.FC<Props> = ({
  show,
  onHide,
  roadshowId,
  participatingDealTeams,
}) => {
  const {
    data: organisationsData,
    loading: organisationsLoading,
    error: organisationsError,
  } = useRoadshows_RoadshowDealTeamOrganizationsQuery();

  const [createDealTeamMembers, { loading: isCreating }] = useRoadshows_UpdateDealTeamMutation();
  const [assignDealTeamMembers, { loading: isAssigning }] = useAssignDealTeamMembersMutation();

  const handleCreateAndAssignMembers = async () => {
    try {
      const { data } = await createDealTeamMembers({
        variables: getCreateVariables(selectedDealTeamData!.dealTeam, values),
      });

      await assignDealTeamMembers({
        variables: getAssignVariables({
          roadshowId,
          createResponse: data!.updateDealTeam,
          dealTeam: selectedDealTeamData!.dealTeam,
          participatingDealTeams: participatingDealTeams,
        }),
      });

      resetForm();
      onHide();
    } catch {
      ToastManager.error('Failed to create or assign Deal Team Members');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => validateSchema(selectedDealTeamData?.dealTeam),
    onSubmit: handleCreateAndAssignMembers,
    validateOnMount: true,
  });
  const { values, isValid, submitForm, resetForm } = formik;

  const {
    data: selectedDealTeamData,
    loading: selectedDealTeamLoading,
    error: selectedDealTeamError,
  } = useRoadshows_DealTeamQuery({
    variables: { cmgEntityKey: values.organisationId! },
    skip: !values.organisationId,
  });

  const isCreateSectionVisible = !!values.organisationId && !selectedDealTeamLoading;
  const isLoading = isCreating || isAssigning;
  const departments = selectedDealTeamData?.dealTeam.departments ?? [];
  const error = organisationsError || selectedDealTeamError;

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (organisationsLoading) {
    return <Loading />;
  }

  if (organisationsData) {
    return (
      <Modal
        show={show}
        title="Create New Deal Team Members"
        size="xlarge"
        shouldCloseOnOverlayClick={false}
      >
        <StyledModalContent>
          <CreateMemberInfoBanner />

          <FormikProvider value={formik}>
            <Form>
              <DealTeamOrganisationSection
                organisations={organisationsData.roadshowDealTeamOrganizations}
              />

              {selectedDealTeamLoading && <Loading />}

              {isCreateSectionVisible && (
                <FieldArray
                  name="members"
                  render={({ unshift, remove }) => (
                    <CreateNewMemberSection
                      members={values.members}
                      existingMembers={selectedDealTeamData?.dealTeam.members ?? []}
                      onAddMember={unshift}
                      onRemoveMember={remove}
                      departments={departments}
                    />
                  )}
                />
              )}
            </Form>
          </FormikProvider>
        </StyledModalContent>

        <StyledModalFooter>
          <SecondaryButton testId="cancel button" disabled={isLoading} onClick={onHide}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId="create button"
            disabled={isLoading || !isValid}
            loading={isLoading}
            onClick={submitForm}
          >
            Create and Select Members
          </PrimaryButton>
        </StyledModalFooter>
      </Modal>
    );
  }

  return null;
};

export default CreateDealTeamMembersModal;
