import { ManagerRole } from '../../graphql';
import {
  Offering_OfferingDetails_BasicPublishedOfferingProfilePartsFragment,
  Offering_OfferingDetails_OfferingPartsFragment,
  Offering_OfferingDetails_PublishedOfferingPartsFragment,
} from '../offering/offering-details/graphql';
import { OfferingProfile_OfferingProfilePartsFragment } from './graphql';

/**
 * Constant for the six roles to render in the profile component
 */
export const syndicateManagerGroups = [
  ManagerRole.GlobalCoordinator,
  ManagerRole.ActiveBookrunner,
  ManagerRole.Bookrunner,
  ManagerRole.CoLead,
  ManagerRole.CoManager,
  ManagerRole.Underwriter,
];

export type OfferingDetails =
  | Offering_OfferingDetails_OfferingPartsFragment
  | Offering_OfferingDetails_PublishedOfferingPartsFragment
  | OfferingProfile_OfferingProfilePartsFragment
  | Offering_OfferingDetails_BasicPublishedOfferingProfilePartsFragment;

export type ManagersTypeFix = readonly OfferingDetails['syndicate']['managers'][number][];

export function isBasicPublishedProfile(
  offering: OfferingDetails
): offering is Offering_OfferingDetails_BasicPublishedOfferingProfilePartsFragment {
  return offering.__typename === 'BasicPublishedOfferingProfile';
}
