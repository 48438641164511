import { Icon } from '@cmg/common';
import React from 'react';

import { SDuplicate } from './CompanyNameCell.styles';

const SurvivingIcon: React.FC = () => {
  return (
    <SDuplicate>
      <Icon name="link" size="xs" />
    </SDuplicate>
  );
};

export default SurvivingIcon;
