import React from 'react';

import { SWrapper } from './QuantityReleasedPopoverContent.styles';

export type Props = { numOfTradesOutOfBalance: number };

const SummaryQuantityReleasedPopoverContent: React.FC<Props> = ({ numOfTradesOutOfBalance }) => {
  return (
    <SWrapper>
      Quantity Released is out of balance for {numOfTradesOutOfBalance} trade
      {numOfTradesOutOfBalance === 1 ? '' : 's'}
    </SWrapper>
  );
};

export default SummaryQuantityReleasedPopoverContent;
