import React from 'react';

type DialogStateProps = Readonly<{
  onOpenCallback?: () => void;
}>;

export const useDialogState = <T extends {}>({ onOpenCallback }: DialogStateProps = {}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [context, setContext] = React.useState<T | null>(null);

  const onOpen = React.useCallback(
    (dialogContext?: T) => {
      dialogContext && setContext(dialogContext);
      onOpenCallback?.();
      setIsOpen(true);
    },
    [onOpenCallback]
  );

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setContext(null);
  }, []);

  return { isOpen, context, onOpen, onClose };
};
