import styled from 'styled-components/macro';

export const SHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
`;

export const SHeaderContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  flex-direction: row;
`;
