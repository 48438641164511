import { useAuth } from '@cmg/auth';
import { DataGrid, DeepWritable, PaginationQueryParams } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import DeleteDealTeamModal from '../../../common/components/delete-deal-team-modal/DeleteDealTeamModal';
import { extendedProps, gridOptionsProps } from '../../../common/dataGridProps';
import { useDealTeamPaginatedListQuery } from '../hooks/useDealTeamPaginatedListQuery';
import { createColumns, paginationInitialValues, toPagination } from './DealTeamListDataGrid.model';

const DealTeamListDataGrid: React.FC = () => {
  const { data, loading, error, setPagination } = useDealTeamPaginatedListQuery();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [dealTeamId, setDealTeamId] = React.useState<string>();
  const theme = useTheme();
  const { oidcUserCmgEntityKey } = useAuth();

  const onPaginationChange = (params: PaginationQueryParams) => {
    setPagination(toPagination(params));
  };

  const handleOpenModal = (id: string) => {
    setDealTeamId(id);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDealTeamId(undefined);
  };

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  return (
    <React.Fragment>
      <DataGrid
        testId={xcSelectors.roadshowDealTeamsConfigDealTeamsList.testId}
        columns={createColumns(
          theme,
          paginationInitialValues,
          oidcUserCmgEntityKey,
          handleOpenModal
        )}
        rows={data?.dealTeams.data as DeepWritable<NonNullable<typeof data>['dealTeams']['data']>}
        onPaginationChange={onPaginationChange}
        loading={loading}
        resizeBy="grid"
        extended={extendedProps}
        gridOptions={gridOptionsProps(theme)}
      />
      <DeleteDealTeamModal
        dealTeamId={dealTeamId}
        show={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalClose}
      />
    </React.Fragment>
  );
};

export default DealTeamListDataGrid;
