/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CreateRoadshowMutationVariables = Types.Exact<{
  roadshow: Types.RoadshowInput;
}>;

export type Roadshows_CreateRoadshowMutation = {
  readonly __typename?: 'Mutation';
  readonly createRoadshow: {
    readonly __typename?: 'Roadshow';
    readonly endDate: string;
    readonly id: string;
    readonly offeringId?: string | null;
    readonly offeringIssuerName?: string | null;
    readonly roadshowName: string;
    readonly roadshowType: Types.RoadshowType;
    readonly startDate: string;
    readonly status: Types.RoadshowStatus;
    readonly companyName?: string | null;
    readonly companyId?: string | null;
    readonly defaultTimeZone?: string | null;
    readonly roadshowDays: ReadonlyArray<{
      readonly __typename?: 'RoadshowDay';
      readonly date: string;
      readonly timeZone?: string | null;
      readonly isClosed: boolean;
      readonly isVirtual: boolean;
      readonly cities: ReadonlyArray<{
        readonly __typename?: 'City';
        readonly name: string;
        readonly timeZone: string;
      }>;
    }>;
  };
};

export const Roadshows_CreateRoadshowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CreateRoadshow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshow' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoadshow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshow' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Roadshow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringIssuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roadshowType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVirtual' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CreateRoadshowMutationFn = Apollo.MutationFunction<
  Roadshows_CreateRoadshowMutation,
  Roadshows_CreateRoadshowMutationVariables
>;

/**
 * __useRoadshows_CreateRoadshowMutation__
 *
 * To run a mutation, you first call `useRoadshows_CreateRoadshowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CreateRoadshowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCreateRoadshowMutation, { data, loading, error }] = useRoadshows_CreateRoadshowMutation({
 *   variables: {
 *      roadshow: // value for 'roadshow'
 *   },
 * });
 */
export function useRoadshows_CreateRoadshowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CreateRoadshowMutation,
    Roadshows_CreateRoadshowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CreateRoadshowMutation,
    Roadshows_CreateRoadshowMutationVariables
  >(Roadshows_CreateRoadshowDocument, options);
}
export type Roadshows_CreateRoadshowMutationHookResult = ReturnType<
  typeof useRoadshows_CreateRoadshowMutation
>;
export type Roadshows_CreateRoadshowMutationResult =
  Apollo.MutationResult<Roadshows_CreateRoadshowMutation>;
export type Roadshows_CreateRoadshowMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_CreateRoadshowMutation,
  Roadshows_CreateRoadshowMutationVariables
>;
