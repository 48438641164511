import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Popover, SecondaryButton } from '@cmg/common';
import React from 'react';

import { getFeatureToggles } from '../../../../../../common/config';
import { useCheckDeliveryInstructionAccess } from '../../../../../../common/util/check-access/useCheckDeliveryInstructionAccess';
import useCheckInstitutionalDuplicateAccess from '../../../../../../common/util/check-access/useCheckInstitutionalDuplicateAccess';
import {
  SAmmountSelected,
  SButtonsContainer,
  SSelectedRowsToolbarContent,
  SSelectedRowsToolbarWrapper,
} from './SelectedRowsToolbar.styles';

export type Props = Readonly<{
  indicationIds: string[];
  offeringId: string;
  isProspectusDocumentUploaded: boolean;
  onMarkAsDuplicate: () => void;
  onResendProspectus: () => void;
  isResendProspectusLoading: boolean;
  onEnterAllocations: () => void;
  onAssignBDAgent: () => void;
}>;

/**
 * Renders the Selected Rows Toolbar for the Institutional Demand Grid
 */
const SelectedRowsToolbar: React.FC<Props> = ({
  indicationIds,
  offeringId,
  isProspectusDocumentUploaded,
  onMarkAsDuplicate,
  onResendProspectus,
  isResendProspectusLoading,
  onEnterAllocations,
  onAssignBDAgent,
}) => {
  const canManageOrderBook = useCheckPermissions([permissionsByEntity.OrderBook.FULL]);
  const { canUpdate: canUpdateDeliveryInstruction } = useCheckDeliveryInstructionAccess({
    offeringId,
  });
  const { canManageDuplicate } = useCheckInstitutionalDuplicateAccess({ offeringId });
  const { isOrderBookManageDuplicateOn } = getFeatureToggles();
  const isOrderBookManageDuplicateOnFlag = isOrderBookManageDuplicateOn ? canManageDuplicate : true;

  return (
    <SSelectedRowsToolbarWrapper>
      <SSelectedRowsToolbarContent>
        <SAmmountSelected>{indicationIds.length} Selected</SAmmountSelected>
        <SButtonsContainer>
          {indicationIds.length > 0 && (
            <SecondaryButton
              onClick={() => onEnterAllocations()}
              testId="enter-allocations"
              disabled={isResendProspectusLoading}
            >
              Enter Allocations
            </SecondaryButton>
          )}
          {canManageOrderBook && isProspectusDocumentUploaded && indicationIds.length > 0 && (
            <Popover
              content={() => {
                return (
                  <div>
                    Sends Prospectus to all syndicate
                    <br />
                    contacts. If a contact has already received
                    <br />
                    the latest documents no action is taken.
                  </div>
                );
              }}
              placement="topRight"
              variant="DARK"
              autoAdjustOverflow
            >
              <SecondaryButton
                onClick={() => onResendProspectus()}
                testId="send-prospectus"
                loading={isResendProspectusLoading}
              >
                Send Prospectus
              </SecondaryButton>
            </Popover>
          )}
          {indicationIds.length > 1 && isOrderBookManageDuplicateOnFlag && (
            <SecondaryButton
              onClick={() => onMarkAsDuplicate()}
              testId="mark-as-duplicate"
              disabled={isResendProspectusLoading}
            >
              Mark as Duplicate
            </SecondaryButton>
          )}
          {indicationIds.length > 1 && canUpdateDeliveryInstruction && (
            <SecondaryButton
              onClick={() => onAssignBDAgent()}
              testId="assign-BD-agent"
              disabled={isResendProspectusLoading}
            >
              Assign B&D Agent
            </SecondaryButton>
          )}
          {/* For now we we leave this commented since we will add actions later */}
          {/* <SecondaryButton
            iconLeft={{ name: 'ellipsis-v' }}
            onClick={() => {}}
            data-test-id="show-more"
          >
            More
          </SecondaryButton> */}
        </SButtonsContainer>
      </SSelectedRowsToolbarContent>
    </SSelectedRowsToolbarWrapper>
  );
};

export default SelectedRowsToolbar;
