import { useCheckAccountTraits } from '@cmg/auth';
import { ColDef } from 'ag-grid-community';
import includes from 'lodash/includes';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../../../../../common/config/appSettings';
import { IndicationNoteType } from '../../../../../../../graphql';
import { CMGColumnID } from '../../types';
import { getNoteCellRenderer } from '../note-cell/NoteCell';
import { defaultColumnProps, defaultColumnWidth } from './Columns.model';

export const useGetNotesColumns = (): ColDef[] => {
  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();
  const lockPinned = !isOrderBookPinnedColumnsOn;
  const canViewSyndicateNotes = useCheckAccountTraits(['XC_IOI_NOTES']);

  return useMemo(() => {
    const hideFilter = (c: ColDef) => {
      const colId: string = c.colId!;
      const excludedColumns = [
        ...(!canViewSyndicateNotes
          ? [CMGColumnID.SYNDICATE_NOTES, CMGColumnID.SALES_AND_TRADING_NOTES]
          : []),
      ];

      return !includes(excludedColumns, colId);
    };

    return [
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SYNDICATE_NOTES,
        field: 'notes.syndicate.text',
        headerName: 'Syndicate',
        hide: true,
        cellRendererFramework: getNoteCellRenderer(IndicationNoteType.SyndicateNote),
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SALES_AND_TRADING_NOTES,
        field: 'notes.salesAndTrading.text',
        headerName: 'Sales & Trading',
        hide: true,
        cellRendererFramework: getNoteCellRenderer(IndicationNoteType.SalesAndTradingNote),
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SALES_CONTACT_NOTES,
        field: 'notes.salesContact.text',
        headerName: 'Sales Contact',
        cellRendererFramework: getNoteCellRenderer(IndicationNoteType.SalesContactNote),
        lockPinned,
      },
      {
        ...defaultColumnProps,
        ...defaultColumnWidth,
        colId: CMGColumnID.SALES_DEPARTMENT_NOTES,
        field: 'notes.salesDepartment.text',
        headerName: 'Sales Department',
        cellRendererFramework: getNoteCellRenderer(IndicationNoteType.SalesDepartmentNote),
        lockPinned,
      },
    ].filter(hideFilter);
  }, [lockPinned, canViewSyndicateNotes]);
};
