import React from 'react';

import Table from '../../../../../../common/components/layout/table/Table';
import { managerRoleLabels } from '../../../../../../types/domain/manager/constants';
import { CalendarGridOffering } from '../../CalendarGrid.model';
import { SRow } from './ManagerList.styles';

export type Props = { managers: CalendarGridOffering['managers'] };

export const ManagerList: React.FC<Props> = ({ managers }) => {
  return (
    <Table>
      <thead>
        <SRow>
          <th>Underwriter</th>
          <th>Role</th>
        </SRow>
      </thead>
      <tbody>
        {managers.map(manager => (
          <SRow key={manager.cmgEntityKey}>
            <td>{manager.firmName}</td>
            <td>{managerRoleLabels[manager.role]}</td>
          </SRow>
        ))}
      </tbody>
    </Table>
  );
};
