import { CheckboxField, NumericInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledNumericInputField = styled(NumericInputField)`
  input {
    text-align: left;
  }

  margin-bottom: 16px;
`;

export const StyledCheckboxField = styled(CheckboxField)`
  div[aria-checked='true'] {
    background-color: ${({ theme }) => theme.color.blue2};
    border: none;

    svg {
      color: ${({ theme }) => theme.color.white};
      margin-top: 3px;
    }
  }
`;

export const SPrompt = styled.div`
  margin-bottom: 16px;
`;

export const SError = styled.div`
  color: ${({ theme }) => theme.color.red1};
  font-size: 12px;
`;
