import { MyOfferings_DraftsQuery, MyOfferings_PrivateOferingsQuery } from '../graphql';

export type PrivateOfferingsQuery = MyOfferings_PrivateOferingsQuery;
export type PrivateOffering = NonNullable<
  NonNullable<PrivateOfferingsQuery['privateOfferings']>['items']
>[number];
export type Draft = NonNullable<
  NonNullable<MyOfferings_DraftsQuery['allOfferings']>['data']
>[number];

export const STATIC_DUMMY_ID = 'STATIC_DUMMY_ID';

export const toDraft = (offering: PrivateOffering): Draft => {
  let leftLeadFirmName: string | null = null;
  const leftLeadFirmCmgEntityKey = offering.syndicate.managerResponsibilities.leftLead;

  const pricingInstrumentCountryCode =
    offering.instruments.find(instrument => instrument.id === offering.pricingInstrumentId)
      ?.countryCode ?? null;

  if (leftLeadFirmCmgEntityKey) {
    const leftLeadFirm = offering.syndicate.managers.find(
      manager => manager.cmgEntityKey === leftLeadFirmCmgEntityKey
    );
    leftLeadFirmName = leftLeadFirm?.firmName ?? null;
  }

  const managers = offering.syndicate.managers.map(({ cmgEntityKey, firmName }) => ({
    cmgEntityKey,
    firmName,
  }));
  const shareHistory = {
    id: STATIC_DUMMY_ID, // Note: This field is unused but it is required by the schema
    shareDraftHistories:
      offering.shareHistory?.map(({ version, managerKeys, sharedAt }) => ({
        version: version.toString(),
        managerKeys,
        sharedTime: sharedAt,
      })) ?? [],
  };

  return {
    __typename: 'OfferingLimited',
    id: offering.id,
    type: offering.type,
    stage: offering.stage,
    issuerName: offering.issuer.name,
    pricingDate: offering.timing.pricingDate,
    pricingInstrumentStockSymbol: offering.pricingInstrumentStockSymbol,
    issuerSector: offering.issuer.sector,
    priceRangeLow: offering.priceRangeLow,
    priceRangeHigh: offering.priceRangeHigh,
    sizeInCurrency: offering.sizeInCurrency,
    sizeInShares: offering.sizeInShares,
    leftLeadFirmName,
    managers,
    shareHistory,
    pricingInstrumentCountryCode,
    isExecutingOnPlatform: offering.isExecutingOnPlatform,
    pricingCurrencyCode: offering.pricingCurrencyCode,
  };
};

export const toDrafts = (offerings: ReadonlyArray<PrivateOffering> | null): Draft[] | null => {
  if (!offerings) return null;

  return offerings.map(toDraft);
};
