import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteSellingGroupTermsWireMutation,
  SyndicateWires_SellingGroupTermsWiresDocument,
  useSyndicateWires_DeleteSellingGroupTermsWireMutation,
} from '../graphql';

export type DeleteSellingGroupTermsWireMutation =
  SyndicateWires_DeleteSellingGroupTermsWireMutation;

export const refetchQueries = () => {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SellingGroupTermsWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
};

export const useDeleteSellingGroupTermsWireMutation = () => {
  return useSyndicateWires_DeleteSellingGroupTermsWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
