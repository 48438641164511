import React from 'react';

import SideBarSectionHeader from '../../../../components/side-bar-header/SideBarHeader';
import { ManageIndication_OfferingPartsFragment } from '../manage-indication/graphql/__generated__';
import {
  SNoticesAndDisclaimers,
  SNoticesAndDisclaimersContainer,
} from './NoticesAndDisclaimers.styles';

export type Props = {
  disclaimers: ManageIndication_OfferingPartsFragment['disclaimers'];
};

export const NoticesAndDisclaimers: React.FC<Props> = ({ disclaimers }) => {
  return (
    <SNoticesAndDisclaimersContainer data-test-id="notices-and-disclaimers-section">
      <SideBarSectionHeader title="Notices and Disclaimers"></SideBarSectionHeader>
      <SNoticesAndDisclaimers data-test-id="notices-and-disclaimers-text">
        {disclaimers.rule134Legend ?? '-'}
      </SNoticesAndDisclaimers>
    </SNoticesAndDisclaimersContainer>
  );
};
