import { DropdownButton, Menu } from '@cmg/design-system';
import React, { useRef, useState } from 'react';

import MyOfferingsQuickAccess from './MyOfferingsQuickAccess';

export function MyOfferingsQuickAccessDropdown() {
  const btnRef = useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  return (
    <React.Fragment>
      <DropdownButton
        ref={btnRef}
        isExpanded={isOpened}
        color="secondaryDarkBackground"
        onClick={() => setIsOpened(openState => !openState)}
      >
        Quick access
      </DropdownButton>
      <Menu
        open={isOpened}
        onClose={() => setIsOpened(false)}
        id="menu-id"
        anchorEl={btnRef.current}
      >
        <MyOfferingsQuickAccess onClose={() => setIsOpened(false)} />
      </Menu>
    </React.Fragment>
  );
}
