import { numericUtil, ODSTable as Table } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import InfoIconPopover from '../../../../common/components/info-icon-popover/InfoIconPopover';
import { FinalSettlement_StabilizationInformationPartsFragment } from '../../../graphql';
import { Values } from '../../StabilizationForm.model';
import { getBreakevenPrice, getTotalShares } from '../StabilizationFormContent.model';
import { getTotalRemaining, getTotalShortPosition } from './StabilizationCalculationsDetails.model';
import { StyledInformationBlock } from './StabilizationCalculationsDetails.styles';
import TotalShortPositionInfo from './total-short-position-info/TotalShortPositionInfo';

export type Props = {
  offeringInformation: FinalSettlement_StabilizationInformationPartsFragment;
};

const StabilizationCalculationsDetails: React.FC<Props> = ({ offeringInformation }) => {
  const { values } = useFormikContext<Values>();

  const {
    overallotmentExcercised,
    offeringPrice,
    sellingConcession,
    finalAuthorizedOverallotment,
    additionalShortPosition,
  } = offeringInformation;

  const totalShortPosition = getTotalShortPosition(offeringInformation);
  const breakevenPrice = getBreakevenPrice(offeringInformation);

  const sharesRepurchased = getTotalShares(values.buy);
  const sharesRefreshed = getTotalShares(values.sell);
  const negativeSharesRefreshed = sharesRefreshed === null ? sharesRefreshed : -sharesRefreshed;
  const total = getTotalRemaining({
    totalShortPosition,
    sharesRepurchased,
    sharesRefreshed: negativeSharesRefreshed,
    overallotmentExcercised,
  });

  return (
    <div data-test-id="Final Settlement / Stabilization / Calculation Details">
      <StyledInformationBlock>
        <Table gridTemplateColumns="2fr 10px 1fr">
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Offering Price
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right">
            {numericUtil.getDisplayValueForCurrency(offeringPrice, 6)}
          </Table.Cell>

          <Table.Cell paddingSize="S">Selling Concession</Table.Cell>
          <Table.Cell paddingSize="S" bold>
            -
          </Table.Cell>
          <Table.Cell paddingSize="S" align="right" data-test-id="selling-concession-table-cell">
            {numericUtil.getDisplayValueForCurrency(sellingConcession, 6)}
          </Table.Cell>

          <Table.Cell paddingSize="S" withBottomBorder={false} colSpan={2} bold>
            Breakeven Price
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right" bold>
            {numericUtil.getDisplayValueForCurrency(breakevenPrice, 6)}
          </Table.Cell>
        </Table>
      </StyledInformationBlock>

      <StyledInformationBlock>
        <Table gridTemplateColumns="2fr 10px 1fr">
          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Total Short Position
            <InfoIconPopover>
              <TotalShortPositionInfo
                finalOverallotmentAuthorized={finalAuthorizedOverallotment}
                additionalShortPosition={additionalShortPosition}
                totalShortPosition={totalShortPosition}
              />
            </InfoIconPopover>
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right">
            {numericUtil.getDisplayValueForInteger(totalShortPosition)}
          </Table.Cell>

          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Total Shares Repurchased
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right">
            {numericUtil.getDisplayValueForInteger(sharesRepurchased)}
          </Table.Cell>

          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
            Total Shares Refreshed
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right">
            {numericUtil.getDisplayValueForInteger(negativeSharesRefreshed)}
          </Table.Cell>

          <Table.Cell paddingSize="S">Overallotment Exercised</Table.Cell>
          <Table.Cell paddingSize="S" bold>
            +
          </Table.Cell>
          <Table.Cell
            paddingSize="S"
            align="right"
            data-test-id="overallotment-excercised-table-cell"
          >
            {numericUtil.getDisplayValueForInteger(overallotmentExcercised)}
          </Table.Cell>

          <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
            Remaining Shares
          </Table.Cell>
          <Table.Cell paddingSize="S" withBottomBorder={false} align="right" bold>
            {numericUtil.getDisplayValueForInteger(total)}
          </Table.Cell>
        </Table>
      </StyledInformationBlock>
    </div>
  );
};

export default StabilizationCalculationsDetails;
