import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Panel, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ErrorRenderer from '../../../common/components/errorRenderer/Error-Renderer';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';
import { useOfferingSetup_Summary_OfferingSummaryQuery } from '../../offering-setup/graphql';
import OfferingSummaryView from '../../offering-setup/summary/view/OfferingSummaryView';
import {
  mapOffering,
  mapOfferingProfile,
} from '../../offering-setup/summary/view/OfferingSummaryView.model';
import {
  useOffering_OfferingDetails_OfferingQuery,
  useOffering_OfferingDetails_PublishedOfferingQuery,
} from './graphql';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;

export type Props = RouteProps & { isPublished?: boolean; issuerName?: string; isAuthor?: boolean };

const OfferingDetailsRoute: React.FC<Props> = ({
  match,
  isPublished = false,
  issuerName,
  isAuthor,
}) => {
  const { offeringId } = match.params;

  useDocumentTitle(
    routeFactory.offering.getDocumentTitle({
      issuerName,
    })
  );

  const canReadOfferingSummary = useCheckPermissions([permissionsByEntity.Offering.READ]);

  const skipSummaryQuery = !canReadOfferingSummary || !isAuthor;

  const {
    data: offeringSummaryData,
    loading: offeringSummaryLoading,
    error: offeringSummaryError,
  } = useOfferingSetup_Summary_OfferingSummaryQuery({
    variables: { offeringId: offeringId },
    skip: skipSummaryQuery,
  });

  const {
    data: publishedData,
    error: publishedError,
    loading: publishedLoading,
  } = useOffering_OfferingDetails_PublishedOfferingQuery({
    variables: { offeringId },
    skip: !isPublished || !skipSummaryQuery,
  });

  const {
    data: offeringData,
    error: offeringError,
    loading: offeringLoading,
  } = useOffering_OfferingDetails_OfferingQuery({
    variables: { offeringId },
    skip: isPublished || !skipSummaryQuery,
  });

  const error = publishedError || offeringError || offeringSummaryError;

  const loading = publishedLoading || offeringLoading || offeringSummaryLoading;

  if (error) {
    return <ErrorRenderer error={error} />;
  }

  if (loading) {
    return <Spinner show />;
  }

  if (!skipSummaryQuery) {
    return (
      <Panel>
        <OfferingSummaryView offering={offeringSummaryData!.offering} />
      </Panel>
    );
  }

  const offering = publishedData
    ? mapOfferingProfile(publishedData.publishedOffering)
    : mapOffering(offeringData?.offering!);
  return (
    <Panel>
      <OfferingSummaryView offering={offering} />
    </Panel>
  );
};

export default OfferingDetailsRoute;
