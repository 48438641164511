import { FlexContainer, Icon, numericUtil } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../../common/components/layout/panel/Panel';
import InfoIconPopover from '../../../common/components/info-icon-popover/InfoIconPopover';
import { useAccountingContext } from '../common/accounting-context/AccountingContext';
import FinalSettlementInfoBox from './final-settlement-info-box/FinalSettlementInfoBox';
import NetDealFeesReconciliationInfo from './net-deal-fees-reconciliation-info/NetDealFeesReconciliationInfo';
import { calculateNetDealFeesDifference } from './NetDealFeesPanel.model';
import {
  SIconWrapper,
  SReconciliationLabel,
  StyledReconciliationFlex,
} from './NetDealFeesPanel.styles';
import TotalRevenueInfoBox from './total-revenue-info-box/TotalRevenueInfoBox';

const NetDealFeesPanel: React.FC = () => {
  const theme = useTheme();

  const accounting = useAccountingContext();
  const { settlementNetDealFees, revenueNetDealFees, netDealFeesDifference } =
    calculateNetDealFeesDifference(accounting);

  const isAlertVisible = netDealFeesDifference !== 0;

  return (
    <Panel>
      <Panel.Header title="Net Deal Fees" />
      <Panel.Content>
        <FlexContainer direction="row" gap={16}>
          <FinalSettlementInfoBox />

          <TotalRevenueInfoBox />
        </FlexContainer>

        <StyledReconciliationFlex direction="row-reverse">
          <InfoIconPopover>
            <NetDealFeesReconciliationInfo
              finalSettlement={settlementNetDealFees}
              totalRevenue={revenueNetDealFees}
              netDealFeesDifference={netDealFeesDifference}
            />
          </InfoIconPopover>
          <SReconciliationLabel>
            Net Deal Fees Reconciliation: {numericUtil.formatCurrency(netDealFeesDifference, 2)}
          </SReconciliationLabel>

          {isAlertVisible && (
            <SIconWrapper>
              <Icon
                name="exclamation-triangle"
                variant="solid"
                color={theme.foundation.colors.error[300]}
              />
            </SIconWrapper>
          )}
        </StyledReconciliationFlex>
      </Panel.Content>
    </Panel>
  );
};

export default NetDealFeesPanel;
