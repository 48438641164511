import { useEffect, useMemo } from 'react';

import { MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT } from '../../graphql/constants';
import { useMyOfferings_SharedOfferingsQuery } from '../graphql';
import { toSharedDraft } from '../utils/transformData';

function useFetchRemainingPaginatedData(query) {
  useEffect(
    function fetchRemainingPaginatedData() {
      if (!query.data?.sharedOfferings) {
        return;
      }

      const { pageInfo, items } = query.data.sharedOfferings;

      if (items && pageInfo.hasNextPage) {
        query.fetchMore({
          variables: { skip: items.length },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult.sharedOfferings?.items) {
              return prev;
            }

            return {
              ...prev,
              sharedOfferings: {
                ...prev.sharedOfferings,
                ...fetchMoreResult.sharedOfferings,
                items: [
                  ...(prev.sharedOfferings?.items ?? []),
                  ...fetchMoreResult.sharedOfferings.items,
                ],
              },
            };
          },
        });
      }
    },
    [query]
  );
}

export const useSharedOfferingsQuery = ({ shouldSkip }: { shouldSkip: boolean }) => {
  const query = useMyOfferings_SharedOfferingsQuery({
    variables: { skip: 0, take: MY_OFFERINGS_OFFERING_FULL_BACKEND_MAX_TAKE_LIMIT },
    fetchPolicy: 'cache-and-network',
    skip: shouldSkip,
  });

  useFetchRemainingPaginatedData(query);

  const { data, loading, error } = query;

  const loadingPages = loading || !!data?.sharedOfferings?.pageInfo.hasNextPage;

  const sharedOfferingsData = useMemo(() => {
    if (loadingPages) {
      return null;
    }
    return data?.sharedOfferings?.items?.map(toSharedDraft) ?? null;
  }, [data?.sharedOfferings?.items, loadingPages]);

  const offeringTypes = useMemo(
    () =>
      (data?.sharedOfferings?.items ?? []).map(
        ({ id, type, instruments, pricingInstrumentId }) => ({
          id,
          type,
          pricingInstrumentCountryCode:
            instruments.find(instrument => instrument.id === pricingInstrumentId)?.countryCode ??
            null,
        })
      ),
    [data?.sharedOfferings?.items]
  );

  const offeringStatuses = useMemo(
    () =>
      (data?.sharedOfferings?.items ?? []).map(({ id, status }) => ({
        id,
        status,
      })),
    [data?.sharedOfferings?.items]
  );

  const issuerSectors = useMemo(
    () =>
      (sharedOfferingsData ?? []).map(({ id, issuerSector }) => ({
        id,
        issuerSector,
      })),
    [sharedOfferingsData]
  );

  return {
    sharedOfferings: {
      data: loadingPages ? undefined : sharedOfferingsData,
      offeringTypes,
      offeringStatuses,
      issuerSectors,
    },
    isLoadingSharedOfferings: loadingPages,
    sharedOfferingsError: error,
  };
};
