import { useAuth } from '@cmg/auth';

import {
  MyOfferings_MyPublishedOfferingsQuery,
  useMyOfferings_MyPublishedOfferingsQuery,
} from '../graphql';

export type MyPublishedOfferingsQuery = MyOfferings_MyPublishedOfferingsQuery;

export const useMyPublishedOfferingsQuery = ({
  onPlatformOnly = false,
  shouldSkip,
}: {
  onPlatformOnly: boolean;
  shouldSkip: boolean;
}) => {
  const { oidcUserCmgEntityKey } = useAuth();

  return useMyOfferings_MyPublishedOfferingsQuery({
    variables: {
      cmgEntityKey: oidcUserCmgEntityKey!,
      onPlatformOnly: onPlatformOnly,
    },
    skip: !oidcUserCmgEntityKey || shouldSkip,
    fetchPolicy: 'no-cache',
  });
};
