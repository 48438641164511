import { LinkButton, ODSTable as Table, SelectField, TextInputField } from '@cmg/common';
import React from 'react';

import { getDepartmentOptions } from './NewDealTeamMemberRow.model';
import { StyledCell } from './NewDealTeamMemberRow.styles';

export type Props = { index: number; onDelete: () => void; departments: readonly string[] };

const NewDealTeamMemberRow: React.FC<Props> = ({ index, onDelete, departments }) => {
  return (
    <Table.Row>
      <Table.Cell paddingSize="S">
        <TextInputField name={`members.${index}.name`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField name={`members.${index}.mobilePhone`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField name={`members.${index}.officePhone`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField name={`members.${index}.email`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <SelectField
          name={`members.${index}.department`}
          options={getDepartmentOptions(departments)}
          fullWidth
        />
      </Table.Cell>
      <StyledCell paddingSize="S">
        <LinkButton
          testId="delete member"
          iconLeft={{ name: 'trash-alt', size: 'lg' }}
          onClick={onDelete}
          inline
        />
      </StyledCell>
    </Table.Row>
  );
};

export default NewDealTeamMemberRow;
