/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_UpdateDraftAllocationSetDealLayoutMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  setId: Types.Scalars['String'];
  payload: Types.AssignDealLayoutInput;
}>;

export type OrderBook_UpdateDraftAllocationSetDealLayoutMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDraftAllocationSetDealLayout: {
    readonly __typename?: 'DraftAllocationSet';
    readonly id: string;
    readonly name: string;
    readonly shareTimestamp?: string | null;
    readonly authorCmgEntityKey: string;
    readonly version: string;
  };
};

export type OrderBook_UpdateDraftAllocationSetNameMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  setId: Types.Scalars['UUID'];
  name: Types.Scalars['String'];
}>;

export type OrderBook_UpdateDraftAllocationSetNameMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDraftAllocationSetName: {
    readonly __typename?: 'DraftAllocationSet';
    readonly id: string;
    readonly name: string;
    readonly shareTimestamp?: string | null;
    readonly authorCmgEntityKey: string;
    readonly version: string;
  };
};

export const OrderBook_UpdateDraftAllocationSetDealLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateDraftAllocationSetDealLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignDealLayoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftAllocationSetDealLayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftAllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateDraftAllocationSetDealLayoutMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateDraftAllocationSetDealLayoutMutation,
  OrderBook_UpdateDraftAllocationSetDealLayoutMutationVariables
>;

/**
 * __useOrderBook_UpdateDraftAllocationSetDealLayoutMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateDraftAllocationSetDealLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateDraftAllocationSetDealLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateDraftAllocationSetDealLayoutMutation, { data, loading, error }] = useOrderBook_UpdateDraftAllocationSetDealLayoutMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      setId: // value for 'setId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_UpdateDraftAllocationSetDealLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateDraftAllocationSetDealLayoutMutation,
    OrderBook_UpdateDraftAllocationSetDealLayoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateDraftAllocationSetDealLayoutMutation,
    OrderBook_UpdateDraftAllocationSetDealLayoutMutationVariables
  >(OrderBook_UpdateDraftAllocationSetDealLayoutDocument, options);
}
export type OrderBook_UpdateDraftAllocationSetDealLayoutMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateDraftAllocationSetDealLayoutMutation
>;
export type OrderBook_UpdateDraftAllocationSetDealLayoutMutationResult =
  Apollo.MutationResult<OrderBook_UpdateDraftAllocationSetDealLayoutMutation>;
export type OrderBook_UpdateDraftAllocationSetDealLayoutMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_UpdateDraftAllocationSetDealLayoutMutation,
    OrderBook_UpdateDraftAllocationSetDealLayoutMutationVariables
  >;
export const OrderBook_UpdateDraftAllocationSetNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateDraftAllocationSetName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftAllocationSetName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftAllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateDraftAllocationSetNameMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateDraftAllocationSetNameMutation,
  OrderBook_UpdateDraftAllocationSetNameMutationVariables
>;

/**
 * __useOrderBook_UpdateDraftAllocationSetNameMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateDraftAllocationSetNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateDraftAllocationSetNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateDraftAllocationSetNameMutation, { data, loading, error }] = useOrderBook_UpdateDraftAllocationSetNameMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      setId: // value for 'setId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrderBook_UpdateDraftAllocationSetNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateDraftAllocationSetNameMutation,
    OrderBook_UpdateDraftAllocationSetNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateDraftAllocationSetNameMutation,
    OrderBook_UpdateDraftAllocationSetNameMutationVariables
  >(OrderBook_UpdateDraftAllocationSetNameDocument, options);
}
export type OrderBook_UpdateDraftAllocationSetNameMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateDraftAllocationSetNameMutation
>;
export type OrderBook_UpdateDraftAllocationSetNameMutationResult =
  Apollo.MutationResult<OrderBook_UpdateDraftAllocationSetNameMutation>;
export type OrderBook_UpdateDraftAllocationSetNameMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_UpdateDraftAllocationSetNameMutation,
  OrderBook_UpdateDraftAllocationSetNameMutationVariables
>;
