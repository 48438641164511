import { numericUtil } from '@cmg/common';
import { HighlightsTextItem } from '@cmg/design-system';

import {
  Offering_OfferingHeader_OfferingProfileTermsPartsFragment,
  Offering_OfferingHeader_OfferingTermsPartsFragment,
} from '../../../graphql';

type LastTradeBeforeTitleItemsProps = {
  terms?:
    | Offering_OfferingHeader_OfferingTermsPartsFragment
    | Offering_OfferingHeader_OfferingProfileTermsPartsFragment;
  pricingCurrencyCode?: string;
};

export default function LastTradeBeforeTitleItems({
  terms,
  pricingCurrencyCode,
}: LastTradeBeforeTitleItemsProps) {
  if (terms?.followOnPricing?.lastTradeBeforeOffer)
    return (
      <HighlightsTextItem
        name="LT BEFORE OFFER"
        text={numericUtil.getDisplayValueForCurrency(
          terms?.followOnPricing?.lastTradeBeforeOffer,
          undefined,
          pricingCurrencyCode
        )}
      />
    );
  if (terms?.followOnPricing?.lastTradeBeforeLaunch) {
    return (
      <HighlightsTextItem
        name="LT BEFORE LAUNCH"
        text={numericUtil.getDisplayValueForCurrency(
          terms.followOnPricing.lastTradeBeforeLaunch,
          undefined,
          pricingCurrencyCode
        )}
      />
    );
  }
  if (terms?.followOnPricing?.lastTradeBeforeFiling) {
    return (
      <HighlightsTextItem
        name="LT BEFORE FILING"
        text={numericUtil.getDisplayValueForCurrency(
          terms.followOnPricing.lastTradeBeforeFiling,
          undefined,
          pricingCurrencyCode
        )}
      />
    );
  }
  return <HighlightsTextItem name="LT BEFORE OFFER" text="-" />;
}
