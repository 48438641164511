import { UUID } from '@cmg/common';
import { DropdownButton, Menu, MenuProps } from '@cmg/design-system';
import React from 'react';

import { useCheckDraftAllocationAccess } from '../../../../../../../../common/util/check-access/useCheckDraftAllocationAccess';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { InstitutionalDraftSet, InstitutionalFinalSet } from '../../../types';
import { useDialogState } from './common/hooks/useDialogState';
import CopyToDefaultSetDialog from './copy-to-draft-set-dialog/CopyToDefaultSetDialog';
import DeleteDraftAllocationSetDialog from './delete-allocation-draft-set-dialog/DeleteDraftAllocationSetDialog';
import InstitutionalDemandFinalAllocationSet from './institutional-demand-final-allocation-set/InstitutionalDemandFinalAllocationSet';
import InstitutionalDemandMenuDrafts from './institutional-demand-menu-drafts/InstitutionalDemandMenuDrafts';
import PromoteToFinalSetDialog from './promote-to-final-set-dialog/PromoteToFinalSetDialog';
import CreateDraftAllocationSetDialog from './save-allocation-set-dialog/create-draft-allocation-set-dialog/CreateDraftAllocationSetDialog';
import EditDraftAllocationSetDialog from './save-allocation-set-dialog/edit-draft-allocation-set-dialog/EditDraftAllocationSetDialog';
import EditFinalAllocationSetDialog from './save-allocation-set-dialog/edit-final-allocation-set-dialog/EditFinalAllocationSetDialog';
import ShareDraftAllocationSetDialog from './share-allocation-set-dialog/share-draft-allocation-set-dialog/ShareDraftAllocationSetDialog';
import ShareFinalAllocationSetDialog from './share-allocation-set-dialog/share-final-allocation-set-dialog/ShareFinalAllocationSetDialog';

const MENU_ANCHOR_ORIGIN_PROPS: MenuProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right',
};
const MENU_TRANSFORM_ORIGIN_PROPS: MenuProps['transformOrigin'] = {
  vertical: 'top',
  horizontal: 'right',
};

export type Props = Readonly<{
  offeringId: UUID;
  finalInstitutionalAllocationSet: InstitutionalFinalSet | null;
  draftAllocationSets: readonly InstitutionalDraftSet[];
}>;

const AllocationsDropdownButton: React.FC<Props> = ({
  offeringId,
  finalInstitutionalAllocationSet,
  draftAllocationSets,
}) => {
  const btnRef = React.useRef<null | HTMLButtonElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const handleToggleDropdown = React.useCallback(() => {
    setIsDropdownOpen(isOpen => !isOpen);
  }, []);
  const handleCloseDropdown = React.useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const {
    isOpen: isCreateDialogOpen,
    onOpen: onOpenCreateDialog,
    onClose: onCloseCreateDialog,
  } = useDialogState({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isEditFinalSetDialogOpen,
    onOpen: onOpenEditFinalSetDialog,
    onClose: onCloseEditFinalSetDialog,
  } = useDialogState({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isShareFinalSetDialogOpen,
    onOpen: onOpenShareFinalSetDialog,
    onClose: onCloseShareFinalSetDialog,
  } = useDialogState({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isShareDraftSetDialogOpen,
    context: sharedDraftSet,
    onOpen: onOpenShareDraftSetDialog,
    onClose: onCloseShareDraftSetDialog,
  } = useDialogState<InstitutionalDraftSet>({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isEditDraftSetDialogOpen,
    context: editedDraftSet,
    onOpen: onOpenEditDraftSetDialog,
    onClose: onCloseEditDraftSetDialog,
  } = useDialogState<InstitutionalDraftSet>({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isDeleteDraftSetDialogOpen,
    context: deletedDraftSet,
    onOpen: onOpenDeleteDraftSetDialog,
    onClose: onCloseDeleteDraftSetDialog,
  } = useDialogState<InstitutionalDraftSet>({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isPromoteToFinalSetDialogOpen,
    context: promotedDraftSet,
    onOpen: onOpenPromoteToFinalSetDialog,
    onClose: onClosePromoteToFinalSetDialog,
  } = useDialogState<InstitutionalDraftSet>({ onOpenCallback: handleCloseDropdown });
  const {
    isOpen: isCopyToDefaultSetDialogOpen,
    context: copyToDefaultDraftSet,
    onOpen: onOpenCopyToDefaultSetDialog,
    onClose: onCloseCopyToDefaultSetDialog,
  } = useDialogState<InstitutionalDraftSet>({ onOpenCallback: handleCloseDropdown });

  const { canRead: canViewDraftAllocations } = useCheckDraftAllocationAccess({ offeringId });
  const { canRead: canReadFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });

  return (
    <div data-testid="design-system-institutional-demand-allocation-dropdown-menu">
      <DropdownButton
        title="Allocations button"
        variant="contained"
        size="medium"
        ref={btnRef}
        isExpanded={isDropdownOpen}
        onClick={handleToggleDropdown}
      >
        Allocations
      </DropdownButton>
      <Menu
        anchorEl={btnRef.current}
        anchorOrigin={MENU_ANCHOR_ORIGIN_PROPS}
        transformOrigin={MENU_TRANSFORM_ORIGIN_PROPS}
        open={isDropdownOpen}
        onClose={handleCloseDropdown}
        slotProps={{
          paper: {
            sx: { width: theme => theme.spacing(45), maxHeight: theme => theme.spacing(64) },
          },
        }}
      >
        {finalInstitutionalAllocationSet && canReadFinalAllocations && (
          <InstitutionalDemandFinalAllocationSet
            finalInstitutionalAllocationSet={finalInstitutionalAllocationSet}
            offeringId={offeringId}
            onEditFinalAllocationSet={onOpenEditFinalSetDialog}
            onShareFinalAllocationSet={onOpenShareFinalSetDialog}
            onCloseAllocationsDropdown={handleCloseDropdown}
          />
        )}

        {canViewDraftAllocations && (
          <InstitutionalDemandMenuDrafts
            offeringId={offeringId}
            draftAllocationSets={draftAllocationSets}
            onCreateDraftAllocationSet={onOpenCreateDialog}
            onEditDraftAllocationSet={onOpenEditDraftSetDialog}
            onDeleteDraftAllocationSet={onOpenDeleteDraftSetDialog}
            onShareDraftAllocationSet={onOpenShareDraftSetDialog}
            onPromoteDraftAllocationSetToFinal={onOpenPromoteToFinalSetDialog}
            onCopyDraftAllocationSetToDefault={onOpenCopyToDefaultSetDialog}
            onCloseAllocationsDropdown={handleCloseDropdown}
          />
        )}
      </Menu>

      <CreateDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isCreateDialogOpen}
        onClose={onCloseCreateDialog}
      />
      <EditDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isEditDraftSetDialogOpen}
        onClose={onCloseEditDraftSetDialog}
        allocationSet={editedDraftSet}
      />
      <DeleteDraftAllocationSetDialog
        offeringId={offeringId}
        isOpen={isDeleteDraftSetDialogOpen}
        onClose={onCloseDeleteDraftSetDialog}
        allocationSet={deletedDraftSet}
      />
      <EditFinalAllocationSetDialog
        offeringId={offeringId}
        isOpen={isEditFinalSetDialogOpen}
        onClose={onCloseEditFinalSetDialog}
        finalAllocationSet={finalInstitutionalAllocationSet}
      />
      <ShareFinalAllocationSetDialog
        isOpen={isShareFinalSetDialogOpen}
        onClose={onCloseShareFinalSetDialog}
        sharedFinalAllocationSet={finalInstitutionalAllocationSet}
        offeringId={offeringId}
      />
      <ShareDraftAllocationSetDialog
        isOpen={isShareDraftSetDialogOpen}
        onClose={onCloseShareDraftSetDialog}
        sharedDraftAllocationSet={sharedDraftSet}
        offeringId={offeringId}
      />
      <PromoteToFinalSetDialog
        isOpen={isPromoteToFinalSetDialogOpen}
        onClose={onClosePromoteToFinalSetDialog}
        promotedDraftAllocationSet={promotedDraftSet}
        offeringId={offeringId}
      />
      <CopyToDefaultSetDialog
        isOpen={isCopyToDefaultSetDialogOpen}
        onClose={onCloseCopyToDefaultSetDialog}
        copiedDraftAllocationSet={copyToDefaultDraftSet}
        offeringId={offeringId}
      />
    </div>
  );
};

export default AllocationsDropdownButton;
