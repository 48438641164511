/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactSupport_SendDlSupportRequestMutationVariables = Types.Exact<{
  subject: Types.Scalars['String'];
  description: Types.Scalars['String'];
  missingEmailsCmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type ContactSupport_SendDlSupportRequestMutation = {
  readonly __typename?: 'Mutation';
  readonly sendDLSupportRequest:
    | { readonly __typename: 'EmptyMutationResponse'; readonly placeholder: boolean }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const ContactSupport_SendDlSupportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ContactSupport_SendDLSupportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'missingEmailsCmgEntityKeys' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDLSupportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'missingEmailsCmgEntityKeys' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'missingEmailsCmgEntityKeys' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmptyMutationResponseParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmptyMutationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmptyMutationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ContactSupport_SendDlSupportRequestMutationFn = Apollo.MutationFunction<
  ContactSupport_SendDlSupportRequestMutation,
  ContactSupport_SendDlSupportRequestMutationVariables
>;

/**
 * __useContactSupport_SendDlSupportRequestMutation__
 *
 * To run a mutation, you first call `useContactSupport_SendDlSupportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactSupport_SendDlSupportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactSupportSendDlSupportRequestMutation, { data, loading, error }] = useContactSupport_SendDlSupportRequestMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      description: // value for 'description'
 *      missingEmailsCmgEntityKeys: // value for 'missingEmailsCmgEntityKeys'
 *   },
 * });
 */
export function useContactSupport_SendDlSupportRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactSupport_SendDlSupportRequestMutation,
    ContactSupport_SendDlSupportRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContactSupport_SendDlSupportRequestMutation,
    ContactSupport_SendDlSupportRequestMutationVariables
  >(ContactSupport_SendDlSupportRequestDocument, options);
}
export type ContactSupport_SendDlSupportRequestMutationHookResult = ReturnType<
  typeof useContactSupport_SendDlSupportRequestMutation
>;
export type ContactSupport_SendDlSupportRequestMutationResult =
  Apollo.MutationResult<ContactSupport_SendDlSupportRequestMutation>;
export type ContactSupport_SendDlSupportRequestMutationOptions = Apollo.BaseMutationOptions<
  ContactSupport_SendDlSupportRequestMutation,
  ContactSupport_SendDlSupportRequestMutationVariables
>;
