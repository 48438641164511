import styled from 'styled-components/macro';

export const SNavigation = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  height: 100%;
  width: 260px;
  flex-shrink: 0;
`;

export const SHeader = styled.div`
  padding: 15px;
  margin: 0 10px 20px 10px;
  border-bottom: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SLabel = styled.span`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.darkGray};
`;
