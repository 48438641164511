import { FlexContainer, Popover } from '@cmg/common';
import React from 'react';

import { MyOrdersGridCellProps } from '../MyOrdersGrid.model';
import { getDemandColumnValue } from '../MyOrdersGridColumns.model';
import { SAdditionalDemand, SDemandCell } from './DemandCellRenderer.styles';

const DemandCellRenderer: React.FC<MyOrdersGridCellProps> = ({ data }) => {
  const { rootElement, hoverElements } = getDemandColumnValue(data);
  const hoverContent = hoverElements && hoverElements.length > 1 && (
    <FlexContainer direction="column">
      <div>Indication</div>
      <table>
        <tbody>
          {hoverElements.map((interestLevel, index) => (
            <tr key={index}>
              <td>{interestLevel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </FlexContainer>
  );

  if (hoverContent && hoverElements) {
    return (
      <div>
        <Popover content={hoverContent} variant="DARK">
          <SDemandCell>
            {rootElement}
            <SAdditionalDemand>&nbsp;+{hoverElements.length - 1}...</SAdditionalDemand>
          </SDemandCell>
        </Popover>
      </div>
    );
  }

  return <SDemandCell>{rootElement}</SDemandCell>;
};

export default DemandCellRenderer;
