import { Alert } from '@cmg/common';
import React from 'react';

import { FilingStatus } from '../../../../../graphql';

export type Props = {
  status: FilingStatus;
};

export const FinraFilingStatusAlert: React.FC<Props> = ({ status }) => {
  if (status === FilingStatus.SubmissionError) {
    return (
      <Alert severity="error">
        <Alert.Title>A server error occurred during your submission.</Alert.Title>
        <Alert.Details>
          Your filing could not be uploaded to FINRA server for processing. Please amend, or
          resubmit the filing with the same data.
        </Alert.Details>
      </Alert>
    );
  }

  return null;
};
