import {
  CurrencyInputField,
  DangerButton,
  Icon,
  NumericInputField,
  PercentInputField,
  SelectField,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { useFormikContext } from 'formik';
import find from 'lodash/find';
import React from 'react';

import { IoiPricingType, IoiUnitType } from '../../../../../../../../../../graphql';
import { FundIoisFormValues, InterestLevelFormValues } from '../../types';
import { pricingTypeOptions, unitTypeOptions } from './constants';
import {
  SColumn,
  SCurrencyInputField,
  SDeleteColumn,
  SIndicationLevelRow,
  SLimitConfigurationColumn,
} from './InterestLevel.styles';

export type Props = {
  interestLevel: InterestLevelFormValues;
  submitting?: boolean;
  index: number;
  replace: (index: number, value: InterestLevelFormValues) => void;
  remove: (index: number) => void;
};

/**
 * Display interest levels in view mode
 */
const InterestLevel: React.FC<Props> = ({
  interestLevel,
  submitting = false,
  index,
  replace,
  remove,
}) => {
  const { values } = useFormikContext<FundIoisFormValues>();

  const hasMarketPricingType = find(values.interestLevels, ['pricingType', IoiPricingType.Market]);

  const baseName = `interestLevels[${index}]`;

  const handleChangeUnitType = (unitType: IoiUnitType) => {
    if (unitType !== interestLevel.unitType) {
      const quantity = !interestLevel.unitType ? interestLevel.quantity : null;
      replace(index, {
        ...interestLevel,
        quantity: null,
        dollars: null,
        percentage: null,
        shares: null,
        [unitType]:
          quantity && !interestLevel.unitType && unitType === IoiUnitType.Percentage
            ? quantity / 100
            : quantity,
        unitType,
      });
    }
  };

  const handleChangePricingType = (pricingType: IoiPricingType) => {
    if (pricingType !== interestLevel.pricingType) {
      replace(index, {
        ...interestLevel,
        limitPrice: null,
        pricingType,
      });
    }
  };

  const renderLimitPriceInput = () => {
    if (interestLevel.pricingType === IoiPricingType.Limit) {
      return (
        <SCurrencyInputField
          name={`${baseName}.limitPrice`}
          disabled={submitting}
          fullWidth
          required
        />
      );
    }

    return null;
  };

  const renderDemandQuantityInput = () => {
    const baseProps = {
      disabled: submitting,
      fullWidth: true,
      required: true,
    };

    switch (interestLevel.unitType) {
      case IoiUnitType.Dollars:
        return <CurrencyInputField {...baseProps} name={`${baseName}.dollars`} />;
      case IoiUnitType.Percentage:
        return <PercentInputField {...baseProps} name={`${baseName}.percentage`} precision={0} />;
      case IoiUnitType.Shares:
        return <NumericInputField {...baseProps} name={`${baseName}.shares`} precision={0} />;
      default:
        return <NumericInputField {...baseProps} name={`${baseName}.quantity`} precision={0} />;
    }
  };

  const removeInterestLevel = () => {
    remove(index);
  };

  return (
    <SIndicationLevelRow data-test-id={xcSelectors.fundLevelDemandInterestLevel.testId}>
      <SColumn align="left">{renderDemandQuantityInput()}</SColumn>
      <SColumn align="left">
        <SelectField
          name={`${baseName}.unitType`}
          placeholder="Select..."
          disabled={submitting}
          options={unitTypeOptions}
          fullWidth
          required
          isClearable={false}
          onChange={handleChangeUnitType}
        />
      </SColumn>
      <SColumn align="left">
        <SelectField
          name={`${baseName}.pricingType`}
          placeholder="Select..."
          disabled={submitting}
          options={pricingTypeOptions.filter(option => {
            // Hide the "Market" order type option if an interest level already has market
            // only one market order is allowed.
            return (
              !hasMarketPricingType ||
              (!!hasMarketPricingType && interestLevel.pricingType === IoiPricingType.Market) ||
              option.value !== IoiPricingType.Market
            );
          })}
          fullWidth
          required
          isClearable={false}
          onChange={handleChangePricingType}
        />
      </SColumn>
      <SLimitConfigurationColumn align="left">{renderLimitPriceInput()}</SLimitConfigurationColumn>
      <SDeleteColumn align="right">
        <DangerButton
          onClick={removeInterestLevel}
          testId={xcSelectors.fundLevelDemandInterestLevelDeleteButton.testId}
        >
          <Icon name="trash-alt" />
        </DangerButton>
      </SDeleteColumn>
    </SIndicationLevelRow>
  );
};

export default InterestLevel;
