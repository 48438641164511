import { UUID } from '@cmg/common';
import { Checkbox, List, ListItem, ListItemIcon, ListSubheader } from '@cmg/design-system';
import includes from 'lodash/includes';
import React from 'react';

import { useCheckFinalInstitutionalAllocationAccess as useCheckAllocationAccess } from '../../../../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { useCheckInstitutionalPassiveOrderBookAccess as useCheckPassiveOrderBookAccess } from '../../../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import { useInstitutionalDemandPreferencesContext } from '../../../../context/InstitutionalDemandPreferencesContext';
import { CMGColumnID, InstitutionalFinalSet } from '../../../../types';
import AllocationSetListItemText from '../common/allocation-set-list-item-text/AllocationSetListItemText';
import MenuItemKebabMenu from '../common/menu-item-kebab-menu/MenuItemKebabMenu';

export type Props = Readonly<{
  finalInstitutionalAllocationSet: InstitutionalFinalSet;
  offeringId: UUID;
  onEditFinalAllocationSet: () => void;
  onShareFinalAllocationSet: () => void;
  onCloseAllocationsDropdown: () => void;
}>;

const InstitutionalDemandFinalAllocationSet: React.FC<Props> = ({
  finalInstitutionalAllocationSet,
  offeringId,
  onEditFinalAllocationSet,
  onShareFinalAllocationSet,
  onCloseAllocationsDropdown,
}) => {
  const { canManage: canManageAllocation } = useCheckAllocationAccess({ offeringId });
  const { canManage: canManagePassiveOrderBook } = useCheckPassiveOrderBookAccess({ offeringId });
  const { visibleColumns, setVisibleColumns } = useInstitutionalDemandPreferencesContext();
  const { isReleased, dealLayout, auditInfo } = finalInstitutionalAllocationSet;

  const isChecked = React.useMemo(
    () => includes(visibleColumns, CMGColumnID.FINAL_ALLOCATION),
    [visibleColumns]
  );

  const handleCheckColumnVisibility = React.useCallback(
    (_, isChecked: boolean) => {
      const nextVisibleColumns = isChecked
        ? [...visibleColumns, CMGColumnID.FINAL_ALLOCATION]
        : visibleColumns.filter(c => c !== CMGColumnID.FINAL_ALLOCATION);

      setVisibleColumns(nextVisibleColumns);
    },
    [setVisibleColumns, visibleColumns]
  );

  const canShare = !isReleased && canManageAllocation && !canManagePassiveOrderBook;

  return (
    <List subheader={<ListSubheader>Final Allocations</ListSubheader>}>
      <ListItem
        aria-label={`Final Allocation Set: ${finalInstitutionalAllocationSet.name}`}
        secondaryAction={
          <MenuItemKebabMenu
            colId={CMGColumnID.FINAL_ALLOCATION}
            onCloseAllocationsDropdown={onCloseAllocationsDropdown}
            canShare={canShare}
            onShare={onShareFinalAllocationSet}
            canEdit={canManageAllocation}
            onEdit={onEditFinalAllocationSet}
          />
        }
      >
        <ListItemIcon>
          <Checkbox checked={isChecked} onChange={handleCheckColumnVisibility} />
        </ListItemIcon>
        <AllocationSetListItemText
          name={finalInstitutionalAllocationSet.name}
          updatedAt={auditInfo?.modifiedAt ?? null}
          offeringSize={dealLayout.offeringSize}
          institutionalTarget={dealLayout.institutionalTarget}
        />
      </ListItem>
    </List>
  );
};

export default InstitutionalDemandFinalAllocationSet;
