import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../my-orders/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { useManageIndication_UpdateCoveredIndicationStatusMutation } from '../../../graphql/__generated__';

export const refetchQueries = [getOperationName(MyOrders_CoveredIndicationsDocument)!];

export const useUpdateCoveredIndicationStatus = (
  offeringId: UUID,
  indicationId: UUID,
  successCallback?: () => void
): [(status: IndicationStatus) => Promise<void>, boolean] => {
  const [updateStatus, { loading: isUpdatingStatus }] =
    useManageIndication_UpdateCoveredIndicationStatusMutation();

  const handleStatusChange = React.useCallback(
    async (status: IndicationStatus) => {
      try {
        await updateStatus({
          variables: { offeringId, indicationId, status },
          awaitRefetchQueries: true,
          refetchQueries,
        });

        pendingOrderBookGridForcedRefreshVar(true);
        successCallback?.();
        SnackbarManager.success('Successfully updated indication status');
      } catch {
        SnackbarManager.error('Failed to update indication status');
      }
    },
    [indicationId, offeringId, successCallback, updateStatus]
  );

  return [handleStatusChange, isUpdatingStatus];
};
