import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { filterSidePanelEvent, useDebouncedCalendarRefreshEvent, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ErrorRenderer from '../../../../common/components/errorRenderer/Error-Renderer';
import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import offeringSidePanelBuySideRouteFactory from '../../offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from '../../OfferingSidePanelContainer';
import OfferingStatusIndicator from './components/offering-status-indicator/OfferingStatusIndicator';
import { useGetLimitedPublishedOfferingQuery } from './hooks/useGetLimitedPublishedOfferingQuery';
import OrderAllocationSubNavigation from './OrderAllocationSubNavigation';
import OrderBookBuySideSubNavigation from './OrderBookBuySideSubNavigation';
import { SSidePanelNavbar, StyledNavLink, StyledNavLinkIcon } from './SidePanelNavbar.styles';

export type Props = {
  offeringId: UUID;
};

const SidePanelBuySideNavbar: React.FC<Props> = ({ offeringId }) => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  const { data, error, loading, refetch } = useGetLimitedPublishedOfferingQuery({
    variables: {
      offeringId: offeringId,
    },
  });

  const { isObsoleteOffering, hasNotes, hasOrders, hasCommunicatedDemand } =
    useSupersededOfferingContext();

  useDocumentTitle(
    routeFactory.offeringSidePanel.getDocumentTitle({
      ticker: data?.limitedPublishedOffering.pricingInstrumentStockSymbol,
    })
  );

  const filterFn = React.useMemo(() => {
    return filterSidePanelEvent(offeringId);
  }, [offeringId]);

  useDebouncedCalendarRefreshEvent({
    callback: React.useCallback(() => {
      refetch();
    }, [refetch]),
    filterFn,
  });

  if (error) {
    return <ErrorRenderer error={error} />;
  }

  return (
    <SSidePanelNavbar>
      {loading && <div>Loading...</div>}
      {data && (
        <div>
          <OfferingStatusIndicator publishedOffering={data.limitedPublishedOffering} />
          {!isObsoleteOffering && (
            <StyledNavLink
              activeClassName="activeLink"
              to={
                baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.offeringDetails.getUrlPath()
              }
            >
              <StyledNavLinkIcon name="file-alt" />
              Offering Details
            </StyledNavLink>
          )}
          {(!isObsoleteOffering || hasNotes) && (
            <AccessControl requiredPermissions={[permissionsByEntity.Ioi.READ]}>
              <StyledNavLink
                activeClassName="activeLink"
                to={
                  baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.offeringNotes.getUrlPath()
                }
                data-test-id={xcSelectors.offeringSidePanelOfferingNotesLink.testId}
              >
                <StyledNavLinkIcon name="sticky-note" />
                Notes
              </StyledNavLink>
            </AccessControl>
          )}
          {(!isObsoleteOffering || hasOrders || hasCommunicatedDemand) && (
            <React.Fragment>
              {!data.limitedPublishedOffering?.isExecutingOnPlatform && (
                <AccessControl requiredPermissions={[permissionsByEntity.Ioi.READ]}>
                  <StyledNavLink
                    activeClassName="activeLink"
                    to={
                      baseSidePanelUrl +
                      offeringSidePanelBuySideRouteFactory.orderAllocation.getUrlPath()
                    }
                    data-test-id={xcSelectors.offeringSidePanelBuySideOrderLink.testId}
                  >
                    <StyledNavLinkIcon name="list-alt" />
                    Order
                  </StyledNavLink>
                  <OrderAllocationSubNavigation baseSidePanelUrl={baseSidePanelUrl} />
                </AccessControl>
              )}
              {data.limitedPublishedOffering.isExecutingOnPlatform && (
                <OrderBookBuySideSubNavigation
                  offeringId={offeringId}
                  baseSidePanelUrl={baseSidePanelUrl}
                >
                  <StyledNavLink
                    activeClassName="activeLink"
                    to={
                      baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.orderBook.getUrlPath()
                    }
                    data-test-id={xcSelectors.offeringSidePanelBuySideOrderLink.testId}
                  >
                    <StyledNavLinkIcon name="file-invoice-dollar" />
                    Order
                  </StyledNavLink>
                </OrderBookBuySideSubNavigation>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </SSidePanelNavbar>
  );
};

export default SidePanelBuySideNavbar;
