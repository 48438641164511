import { UUID } from '@cmg/common';
import * as yup from 'yup';

import { UnderwriterFirm } from '../../../../../../common/components/form/underwriter-firm-search/UnderwriterFirmSearchField.model';
import { MAX_32_BIT_INT, MAX_53_BIT_INT } from '../../../../../../types/graphql/constants';
import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import {
  OrderBook_CreateBrokerDealerMutationVariables,
  OrderBook_DeleteBrokerDealerMutationVariables,
  OrderBook_UpdateBrokerDealerMutationVariables,
} from '../graphql';

export type BrokerValues = {
  id: UUID | null;
  broker: UnderwriterFirm | null;
  sellingConcessionPercentage: number | null | undefined;
  retentionShares: number | null | undefined;
  retentionRoundLots: number | null | undefined;
  demandShares: number | null | undefined;
  demandRoundLots: number | null | undefined;
};

export const getValidationSchema = (allBrokers: BrokerValues['broker'][]) =>
  yup.object({
    id: yup.string().nullable(),
    broker: yup
      .object({
        firmCmgEntityKey: yup.string().nullable(),
        firmName: yup.string().nullable(),
        isOnPlatform: yup.boolean().nullable(),
        mpid: yup.string().nullable(),
        crd: yup.string().nullable(),
      })
      .label('Broker Dealer')
      .nullable()
      .required()
      .test('broker-unique', 'Broker Dealer has already been added', value => {
        if (value.firmCmgEntityKey) {
          const identicalBrokers = allBrokers.filter(
            broker => broker?.firmCmgEntityKey === value.firmCmgEntityKey
          );

          return identicalBrokers.length <= 1;
        }
        return true;
      }),
    demandShares: yup
      .number()
      .integer()
      .label('Demand Shares')
      .min(0)
      .max(MAX_53_BIT_INT)
      .nullable()
      .required(),
    demandRoundLots: yup
      .number()
      .integer()
      .label('Demand Round Lots')
      .min(0)
      .max(MAX_32_BIT_INT)
      .nullable(),
    retentionShares: yup
      .number()
      .integer()
      .label('Retention Shares')
      .min(0)
      .max(MAX_53_BIT_INT)
      .nullable(),
    retentionRoundLots: yup
      .number()
      .integer()
      .label('Retention Round Lots')
      .min(0)
      .max(MAX_32_BIT_INT)
      .nullable(),
    sellingConcessionPercentage: yup
      .number()
      .label('% Received')
      .min(0)
      .max(1, '% Received must be less than or equal to 100')
      .nullable(),
  });

export const getInitialValues = (
  broker: OrderBook_RetailBrokerDealerPartsFragment
): BrokerValues => {
  return {
    id: broker.id,
    broker: {
      firmCmgEntityKey: broker.firmCmgEntityKey,
      firmName: broker.firmName ?? '',
      isOnPlatform: broker.isOnPlatform,
      crd: null,
      mpid: null,
    },
    demandShares: broker.demand.shareQuantity,
    demandRoundLots: broker.demand.roundLots,
    retentionShares: broker.retention.shareQuantity,
    retentionRoundLots: broker.retention.roundLots,
    sellingConcessionPercentage: broker.sellingConcessionPercentage,
  };
};

export const getCreateVariables = (
  offeringId: UUID,
  brokerValues: BrokerValues
): OrderBook_CreateBrokerDealerMutationVariables => {
  const {
    broker,
    demandShares,
    demandRoundLots,
    retentionShares,
    retentionRoundLots,
    sellingConcessionPercentage,
  } = brokerValues;
  return {
    offeringId,
    payload: {
      firmName: broker!.firmName,
      firmCmgEntityKey: broker!.firmCmgEntityKey,
      sellingConcessionPercentage,
      demand: {
        shareQuantity: demandShares as number,
        roundLots: demandRoundLots,
      },
      retention: {
        shareQuantity: retentionShares,
        roundLots: retentionRoundLots,
      },
    },
  };
};

export const getUpdateVariables = (
  offeringId: UUID,
  brokerValues: BrokerValues
): OrderBook_UpdateBrokerDealerMutationVariables => {
  const {
    id,
    demandShares,
    demandRoundLots,
    retentionShares,
    retentionRoundLots,
    sellingConcessionPercentage,
  } = brokerValues;
  return {
    offeringId,
    id: id!,
    payload: {
      sellingConcessionPercentage,
      demand: {
        shareQuantity: demandShares as number,
        roundLots: demandRoundLots,
      },
      retention: {
        shareQuantity: retentionShares,
        roundLots: retentionRoundLots,
      },
    },
  };
};

export const getDeleteVariables = (
  offeringId: UUID,
  brokerValues: BrokerValues
): OrderBook_DeleteBrokerDealerMutationVariables => {
  return { offeringId, id: brokerValues.id! };
};

/**
 * Returns true either when underwriter was saved or underwriter was entered and it's valid
 */
export const isUnderwriterNameVisible = (values: BrokerValues, isValid: boolean): boolean => {
  return !!(values.id || (values.broker && isValid));
};
