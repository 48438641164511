import { DataGridClient } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import type { FundItem } from '../../FundLevelDemand';
import { SFundIoisGridContainer } from './FundIoisGrid.styles';
import { getColumns } from './FundIoisGridColumns';

export const getFundTotalsRow = (items: FundItem[]) => ({
  id: 'totals-row',
  fund: { name: 'Total' },
  allocation: {
    shares: items.reduce((sum, { allocation }) => sum + (allocation.shares ?? 0), 0),
    pctOfFirmAllocation: items.reduce(
      (sum, { allocation }) => sum + (allocation.pctOfFirmAllocation ?? 0),
      0
    ),
  },
});

export type Props = {
  items: FundItem[];
  onEditItem: (fundId: string) => void;
  onDeleteItem: (fundId: string) => void;
  canUpdate: boolean;
};

const FundIoisTable: React.FC<Props> = ({ items, onEditItem, onDeleteItem, canUpdate }) => {
  return (
    <SFundIoisGridContainer data-test-id={xcSelectors.fundLevelDemandGrid.testId}>
      <DataGridClient<FundItem>
        getRowNodeId={({ fund }) => fund.id}
        columns={getColumns({ onEditItem, onDeleteItem, canUpdate })}
        rows={items}
        domLayout="normal"
        resizeBy="grid"
        gridOptions={{
          getRowHeight: () => 46,
          getRowStyle: () => ({ cursor: 'default' }),
          suppressCellSelection: true,
          suppressRowClickSelection: true,
        }}
        extended={{
          fillViewport: true,
          withMargin: false,
          hidePagination: true,
          hideColumnResize: true,
          hideColumnSelector: true,
        }}
        getRowsPinnedToTop={() => [getFundTotalsRow(items)]}
      />
    </SFundIoisGridContainer>
  );
};

export default FundIoisTable;
