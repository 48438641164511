import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Panel } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import FlexLayout from '../../common/components/layout/flex-layout/FlexLayout';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import MyOfferingsSubNavigation from './components/MyOfferingsSubNavigation';
import DraftsRoute from './drafts/DraftsRoute';
import OfferingsRoute from './offerings/OfferingsRoute';
import SharedDraftsRoute from './shared-drafts/SharedDraftsRoute';

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

const MyOfferingsRoute: React.FC<Props> = () => {
  useDocumentTitle(routeFactory.myOfferings.getDocumentTitle());

  const hideDraft = !useCheckPermissions([permissionsByEntity.Offering.READ]);

  return (
    <FlexLayout direction="column" expand data-test-id={xcSelectors.myOfferingsScreen.testId}>
      <Panel fillViewport>
        <MyOfferingsSubNavigation hideDraftLinks={hideDraft} />
        <Switch>
          <Route path={routeFactory.myOfferingsOfferings.routePath} component={OfferingsRoute} />
          <Route path={routeFactory.myOfferingsDrafts.routePath} component={DraftsRoute} />
          <Route
            path={routeFactory.myOfferingsSharedDrafts.routePath}
            component={SharedDraftsRoute}
          />
          {/* Default to offerings */}
          <Route render={() => <Redirect to={routeFactory.myOfferingsOfferings.getUrlPath()} />} />
        </Switch>
      </Panel>
    </FlexLayout>
  );
};

export default MyOfferingsRoute;
