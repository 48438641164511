import { LinkButton, ServiceErrorBanner, ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { isIPOType } from '../../../common/util/offering/offering-type.util';
import { FilingType } from '../../../graphql';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import FilingForm from './forms/FilingForm';
import FollowOnPricingForm from './forms/FollowOnPricingForm';
import {
  OfferingSetup_CreateFilingMutationVariables,
  OfferingSetup_UpdateFilingMutationVariables,
  OfferingSetup_UpdateFollowOnPricingMutationVariables,
  useOfferingSetup_TermsQuery,
} from './graphql';
import { useCreateFilingMutation } from './hooks/useCreateFilingMutation';
import { useDeleteFilingMutation } from './hooks/useDeleteFilingMutation';
import { useUpdateFilingMutation } from './hooks/useUpdateFilingMutation';
import { useUpdateFollowOnPricingMutation } from './hooks/useUpdateFollowOnPricingMutation';

export const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 20px;
`;

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Primary Route for the Offering Setup module
 */
export const TermsRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [addNew, setAddNew] = React.useState<boolean>(false);
  const [editingFollowOnPricing, setEditingFollowOnPricing] = React.useState<boolean>(false);

  const handleAddNew = (active: boolean) => {
    setEditingId(null);
    setAddNew(active);
  };

  const handleEditing = (id: string | null) => {
    setAddNew(false);
    setEditingId(id);
  };

  const { data, loading, error, refetch } = useOfferingSetup_TermsQuery({
    variables: { offeringId: offeringId },
  });

  const [
    updateFollowOnPricing,
    {
      loading: updatingFollowOnPricing,
      error: updateFollowOnPricingError,
      serviceError: updateFollowOnPricingServiceError,
    },
  ] = useUpdateFollowOnPricingMutation();
  const [
    createFiling,
    { loading: creating, error: createError, serviceError: createServiceError },
  ] = useCreateFilingMutation();
  const [
    updateFiling,
    { loading: updating, error: updateError, serviceError: updateServiceError },
  ] = useUpdateFilingMutation();

  const { revalidate } = useValidateOffering(offeringId);

  const handleUpdateFollowOnPricing = async (
    variables: OfferingSetup_UpdateFollowOnPricingMutationVariables
  ) => {
    try {
      const { data: updateFollowOnPricingData } = await updateFollowOnPricing({ variables });
      if (updateFollowOnPricingData?.updateFollowOnPricing.__typename !== 'ServiceError') {
        revalidate();
        setEditingFollowOnPricing(false);
        refetch();
        ToastManager.success('Updated Follow-On Pricing');
      }
    } catch {
      ToastManager.error('Failed to update Follow-On Pricing');
    }
  };

  const handleCreateFiling = async (variables: OfferingSetup_CreateFilingMutationVariables) => {
    try {
      const { data: createFilingData } = await createFiling({ variables });
      if (createFilingData?.createFiling.__typename !== 'ServiceError') {
        refetch();
        revalidate();
        handleAddNew(false);
        ToastManager.success('Created Filing');
      }
    } catch {
      ToastManager.error('Failed to create Filing');
    }
  };

  const handleUpdateFiling = async (variables: OfferingSetup_UpdateFilingMutationVariables) => {
    try {
      const { data: updateFilingData } = await updateFiling({ variables });
      if (updateFilingData?.updateFiling?.__typename !== 'ServiceError') {
        handleEditing(null);
        revalidate();
        setAddNew(false);
        refetch();
        ToastManager.success('Updated Filing');
      }
    } catch {
      ToastManager.error('Failed to update Filing');
    }
  };

  const [deleteFiling, { loading: deleting, error: deleteError }] = useDeleteFilingMutation({
    update(cache, { data: d }) {
      /* find Filing id to remove */
      const idToRemove = d?.deleteFiling;

      /* update cache instead of refetching */
      cache.modify({
        id: cache.identify({ ...data?.offering }),
        fields: {
          filings(existing, { readField }) {
            return existing.filter(filing => readField('id', filing) !== idToRemove);
          },
        },
      });
    },
    onCompleted: () => {
      revalidate();
      handleEditing(null);
      setAddNew(false);
      ToastManager.success('Removed Filing');
    },
    onError: () => {
      ToastManager.error('Failed to remove Filing');
    },
  });

  const offeringType = data?.offering.type;
  const filings = data?.offering.filings || [];
  const followOnPricing = data?.offering.followOnPricing;
  const hasFollowOnPricing = !isIPOType(offeringType);
  const hasInitialFiling = filings.some(f => f.filingType === FilingType.Initial);
  const hasFinalFiling = filings.some(f => f.filingType === FilingType.Final);
  const errors = error || createError || updateError || deleteError;
  const serviceError = createServiceError || updateServiceError;

  if (loading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        <SetupScreen.Header />
        {errors && <ServerErrorsBanner error={errors} />}
        <SetupForm title="Terms">
          {serviceError && <ServiceErrorBanner error={serviceError} />}

          <SetupForm.Table>
            {filings.map(filing => (
              <FilingForm
                key={filing.id}
                data={filing}
                offeringType={offeringType}
                pricingCurrencyCode={data?.offering.pricingCurrencyCode}
                loading={updating || deleting}
                disabled={editingId !== null && editingId !== filing.id}
                editing={editingId === filing.id}
                onEdit={() => handleEditing(filing.id)}
                onCancel={() => handleEditing(null)}
                onDelete={async () =>
                  filing.filingType === FilingType.Revised &&
                  (await deleteFiling({
                    variables: { offeringId, filingId: filing.id },
                  }))
                }
                onSubmit={({ filingType, ...values }) =>
                  handleUpdateFiling({
                    offeringId,
                    filingId: filing.id,
                    input: values,
                  })
                }
              />
            ))}

            {addNew && (
              <FilingForm
                adding
                editing
                hasInitialFiling={hasInitialFiling}
                loading={creating}
                onCancel={() => handleAddNew(false)}
                onSubmit={values => handleCreateFiling({ offeringId, input: values })}
                offeringType={offeringType}
                pricingCurrencyCode={data?.offering.pricingCurrencyCode}
              />
            )}
          </SetupForm.Table>
          {!addNew && !hasFinalFiling && (
            <StyledLinkButton
              fullWidth={false}
              iconLeft={{ name: 'plus' }}
              onClick={() => handleAddNew(true)}
              testId={xcSelectors.offeringSetupTermsAddNewFiling.testId}
            >
              Add New Terms
            </StyledLinkButton>
          )}
        </SetupForm>
        {hasFollowOnPricing && (
          <SetupForm title="Follow-On Pricing">
            {updateFollowOnPricingServiceError && (
              <ServiceErrorBanner error={updateFollowOnPricingServiceError} />
            )}

            {updateFollowOnPricingError && (
              <ServerErrorsBanner error={updateFollowOnPricingError} />
            )}
            <FollowOnPricingForm
              data={followOnPricing}
              pricingCurrencyCode={data?.offering.pricingCurrencyCode}
              loading={updatingFollowOnPricing || loading}
              editing={editingFollowOnPricing}
              onEdit={() => setEditingFollowOnPricing(true)}
              onCancel={() => setEditingFollowOnPricing(false)}
              onSubmit={values =>
                handleUpdateFollowOnPricing({
                  offeringId,
                  input: values,
                })
              }
            />
          </SetupForm>
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default TermsRoute;
