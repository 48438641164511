import React from 'react';
import { useTheme } from 'styled-components/macro';

import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';

export const WireUpdated: React.FC = () => {
  const { wireTypeName, wireTypeCategory } = useWireTypeConfigContext();
  const theme = useTheme();

  return (
    <HeroGraphics
      iconNameParts={{ name: 'check-circle' }}
      iconColor={theme.designSystem.colors.blue['300']}
      title={`${wireTypeName} ${wireTypeCategory} was updated`}
      subtitle={`Select a recipient to preview their unique ${wireTypeCategory} or click Ready to Send to send the ${wireTypeCategory}.`}
    />
  );
};
