import {
  Compliance_BuySide_AttestationQueryVariables,
  useCompliance_BuySide_AttestationQuery,
} from './graphql';

export const useBuySideAttestationQuery = (
  variables: Compliance_BuySide_AttestationQueryVariables,
  { skip }: { skip: boolean }
) => {
  return useCompliance_BuySide_AttestationQuery({
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
  });
};
