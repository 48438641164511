import type { UUID } from '@cmg/common';
import {
  getServerSideRowSelectionState,
  GridRowSelectionState,
  ServerDataGrid,
} from '@cmg/data-grid';
import { styled } from '@cmg/design-system';
import React, { useCallback, useMemo } from 'react';

import { OfferingStatus, OfferingType } from '../../../../graphql';
import { autoGroupColumnDef } from './columns';
import {
  defaultValueFormatter,
  getCellClass,
  getTooltipValue,
  getTooltipVariant,
  rowClassRules,
} from './columns/columns.model';
import { getCellTooltipContent } from './columns/row-alert/TooltipContent';
import {
  dataTypeDefinitions,
  getChildCount,
  getRowHeight,
  getRowId,
  getServerSideGroupKey,
  isRowSelectable,
  isServerSideGroup,
  statusPanels,
} from './DemandGrid.model';
import { useCreateColumnDefs } from './hooks/useCreateColumnDefs';
import { useDemandGridDataSource } from './hooks/useDemandGridDataSource';
import { useDynamicColDefs } from './hooks/useDynamicColDefs';
import { useHandleCellValueChanged } from './hooks/useHandleCellValueChanged';
import { useExplicitValueChanges } from './hooks/useHandleExplicitValueChanges';
import {
  Props as UseHandleInstitutionalGridChangedProps,
  useHandleInstitutionalGridChanged,
} from './hooks/useHandleInstitutionalGridChanged';
import type { SideBarDefProps } from './hooks/useSideBarDef';
import { useSideBarDef } from './hooks/useSideBarDef';
import { useStoredGridState } from './hooks/useStoredGridState';
import type {
  DemandGridDataContext,
  DemandGridRowData,
  DemandGriSelectionChangedEvent,
} from './types';

const StyledServerDataGrid = styled(ServerDataGrid<DemandGridRowData, DemandGridDataContext>)`
  .ag-status-bar {
    padding: 0;

    .ag-status-bar-left,
    .ag-react-container {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
    }
  }
`;

export type Props = Readonly<{
  offeringType: OfferingType;
  offeringStatus: OfferingStatus;
  demandGridContext: DemandGridDataContext;
  updateContext: (nextContext: Partial<DemandGridDataContext>) => void;
  onBndAgentChanged: (indicationId: UUID, cmgEntityKey: string | null) => void;
  onAllocationChanged: (indicationId: UUID, allocationSetId: UUID, shares: number | null) => void;
  onRowSelectionChanged: (value: GridRowSelectionState) => Promise<void>;
  refetchRouteQuery: UseHandleInstitutionalGridChangedProps['refetchRouteQuery'];
}>;

export const DemandGrid: React.FC<Props> = ({
  demandGridContext,
  updateContext,
  onBndAgentChanged,
  onAllocationChanged,
  onRowSelectionChanged,
  refetchRouteQuery,
  offeringStatus,
  offeringType,
}) => {
  const offeringId = demandGridContext.offeringId;
  const dataSource = useDemandGridDataSource({
    offeringId,
    updateContext,
    demandConfig: demandGridContext.demandConfig,
  });

  /**
   * Subscription to grid changes
   */
  useHandleInstitutionalGridChanged({
    offeringId,
    refetchRouteQuery,
  });

  /**
   * Dynamic columns
   */
  useDynamicColDefs({
    allocationSets: demandGridContext.allocationSets,
    demandConfig: demandGridContext.demandConfig,
    oidcUserCmgEntityKey: demandGridContext.oidcUserCmgEntityKey,
    investorExtensionSchema: demandGridContext.investorExtensionSchema,
  });

  /**
   * Row selection change handler
   */
  const handleSelectionChanged = useCallback(
    async (event: DemandGriSelectionChangedEvent) => {
      const rowSelectionState = getServerSideRowSelectionState(event);

      if (!rowSelectionState) {
        return;
      }

      await onRowSelectionChanged(rowSelectionState);
    },
    [onRowSelectionChanged]
  );

  /**
   * Cell value changed handler
   */
  const handleCellValueChanged = useHandleCellValueChanged({
    onAllocationChanged,
    onBndAgentChanged,
  });

  const handleExplicitValueChange = useExplicitValueChanges({ updateContext, demandGridContext });

  /**
   * Side Bar definition
   */
  const sideBarProps = useMemo<SideBarDefProps>(
    () => ({
      offeringId,
      allocationSetsProps: {
        offeringId,
        allocationSets: demandGridContext.allocationSets,
      },
      demandProps: {
        initialValues: demandGridContext.demandConfig,
        onApply: values => updateContext({ demandConfig: values }),
      },
      templatesProps: {
        offeringStatus,
        offeringType,
      },
    }),
    [
      offeringId,
      demandGridContext.allocationSets,
      demandGridContext.demandConfig,
      offeringStatus,
      offeringType,
      updateContext,
    ]
  );
  const sideBarDef = useSideBarDef(sideBarProps);

  /**
   * Column definitions
   */
  const columnDefs = useCreateColumnDefs({ offeringId });

  /**
   * Initial grid state persisted in session storage
   */
  const [initialGridState, saveGridState] = useStoredGridState({
    offeringId,
    staticColCount: columnDefs.length,
    offeringStatus,
    offeringType,
  });

  /**
   * Total row data
   */
  const pinnedTopRowData = useMemo(
    () => [demandGridContext.totalRow].filter(Boolean),
    [demandGridContext.totalRow]
  );

  return (
    <StyledServerDataGrid
      sortLimit={3}
      filterLimit={3}
      serverSideDatasource={dataSource}
      treeData
      pinnedTopRowData={pinnedTopRowData}
      isRowSelectable={({ data }) => isRowSelectable(data)}
      autoGroupColumnDef={autoGroupColumnDef}
      isServerSideGroup={isServerSideGroup}
      getServerSideGroupKey={getServerSideGroupKey}
      getChildCount={getChildCount}
      getRowId={getRowId}
      columnDefs={columnDefs}
      context={demandGridContext}
      rowSelection="multiple"
      sideBar={sideBarDef}
      statusBar={{ statusPanels }}
      onSelectionChanged={handleSelectionChanged}
      onCellValueChanged={handleCellValueChanged}
      onCellEditingStopped={handleExplicitValueChange}
      rowClassRules={rowClassRules}
      getRowHeight={getRowHeight}
      initialState={initialGridState}
      onGridPreDestroyed={saveGridState}
      dataTypeDefinitions={dataTypeDefinitions}
      defaultColDefOverride={{
        cellClass: getCellClass,
        tooltipValueGetter: getTooltipValue,
        valueFormatter: defaultValueFormatter,
        tooltipComponentParams: {
          getVariant: getTooltipVariant,
          getContent: getCellTooltipContent,
        },
      }}
    />
  );
};
