import { UUID } from '@cmg/common';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Panel from '../../../common/components/layout/panel/Panel';
import routeFactory from '../../../common/util/routeFactory';
import InvestorSearch from '../../order-book/components/InvestorSearch';
import { useTradeRelease_IndicationTradesQuery } from './graphql';
import { TradeReleaseHistoryTable } from './table/TradeReleaseHistoryTable';
import { SSearchWrapper } from './TradeReleaseHistory.styles';

export type Investor = { indicationId: UUID; firmName: string };

export type Props = {
  offeringId: UUID;
  indicationId: UUID;
  investors: Investor[];
};

export const TradeReleaseHistory: React.VFC<Props> = ({ offeringId, indicationId, investors }) => {
  const history = useHistory();

  const { data, loading, error } = useTradeRelease_IndicationTradesQuery({
    variables: { indicationId, offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const investorsForInvestorSearch = React.useMemo(() => {
    return investors.map(investor => ({
      key: investor.indicationId,
      name: investor.firmName,
    }));
  }, [investors]);

  const onInvestorChange = useCallback(
    (nextIndicationId: string | null) => {
      if (nextIndicationId) {
        history.push(
          routeFactory.tradeReleaseHistory.getUrlPath({
            offeringId,
            indicationId: nextIndicationId,
          })
        );
      }
    },
    [history, offeringId]
  );

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <SSearchWrapper>
        <InvestorSearch
          investors={investorsForInvestorSearch}
          onChange={onInvestorChange}
          currentKey={indicationId}
        />
      </SSearchWrapper>
      <Panel>
        <Panel.Header title="History" />
        <Panel.Content paddingSize="S">
          <TradeReleaseHistoryTable trades={data?.indicationTrades ?? []} />
        </Panel.Content>
      </Panel>
    </div>
  );
};
