import { ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledTableRow = styled(Table.Row)`
  line-height: 10px;
`;

export const StyledTableCell = styled(Table.Cell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
