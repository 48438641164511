import styled from 'styled-components/macro';

export const SCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.background.color.white};
  gap: 20px;
`;

export const SMeetingInvestor = styled.div`
  text-decoration: underline dotted ${({ theme }) => theme.text.color.light};
`;

export const SMeetingInvestorName = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SMeetingInvestorTeamSize = styled.span`
  color: ${({ theme }) => theme.text.color.link};
`;
