import { TableCell, TableHead, TableRow } from '@cmg/design-system';
import { VFC } from 'react';

export const RecipientsTableHead: VFC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Underwriter</TableCell>
        <TableCell align="right">Payment*</TableCell>
        <TableCell align="right">Retention (Shares)</TableCell>
        <TableCell align="right">Selling Concession*</TableCell>
        <TableCell align="center">IPO Penalty Tracking</TableCell>
      </TableRow>
    </TableHead>
  );
};
