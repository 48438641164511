import React from 'react';
import { useTheme } from 'styled-components/macro';

import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';

type Props = {
  hasInputDataForAllRecipients: boolean;
};

export const WireCreated: React.FC<Props> = ({ hasInputDataForAllRecipients }) => {
  const { wireTypeName, wireTypeCategory } = useWireTypeConfigContext();
  const theme = useTheme();
  const subtitle = hasInputDataForAllRecipients
    ? `Select a recipient to preview their unique ${wireTypeCategory} or click Ready to Send to send the ${wireTypeCategory}.`
    : `Select a recipient to preview its unique ${wireTypeCategory}. Complete drafts for all recipients in order to send the ${wireTypeCategory}.`;

  return (
    <HeroGraphics
      testId="wire-created-hero"
      iconNameParts={{ name: 'check-circle' }}
      iconColor={theme.designSystem.colors.blue['300']}
      title={`${wireTypeName} ${wireTypeCategory} was created`}
      subtitle={subtitle}
    />
  );
};
