export enum NotesFilters {
  ALL = 'ALL',
  SYNDICATE = 'SYNDICATE_NOTE',
  SALES_TRADER = 'SALES_AND_TRADING_NOTE',
  SALES_CONTACT_NOTE = 'SALES_CONTACT_NOTE',
  SALES_DEPARTMENT_NOTE = 'SALES_DEPARTMENT_NOTE',
}

export const filterOptions = [
  {
    label: 'All notes',
    value: NotesFilters.ALL,
  },
  {
    label: 'Syndicate',
    value: NotesFilters.SYNDICATE,
  },
  {
    label: 'Sales & Trading',
    value: NotesFilters.SALES_TRADER,
  },
];
