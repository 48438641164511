import { UUID } from '@cmg/common';
import { FiberManualRecordIcon, SvgIconColors, Tooltip } from '@cmg/design-system';
import merge from 'lodash/merge';

import useCheckOrderBookAccess from '../../../../../common/util/check-access/useCheckOrderBookAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage, OfferingStatus, OrderBookState } from '../../../../../graphql';
import { orderBookStateLabels } from '../../../../../types/domain/order-book/constants';
import { useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery } from '../../navbar/graphql';
import { useWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription } from '../../navbar/graphql/__generated__';

type Props = {
  offeringId: UUID | undefined;
  // getting the offeringStatus from the parent instead of the local query
  // as the parent query is refetched whenever the offering is updated
  offeringStatus: OfferingStatus | undefined;
  offeringStage: OfferingStage;
};

const stateToColor = (state: OrderBookState): [label?: string, color?: SvgIconColors] => {
  switch (state) {
    case OrderBookState.Open: {
      return [orderBookStateLabels[OrderBookState.Open], 'success'];
    }
    case OrderBookState.Closed: {
      return [orderBookStateLabels[OrderBookState.Closed], 'error'];
    }
    case OrderBookState.NeverOpened: {
      return [orderBookStateLabels[OrderBookState.NeverOpened]];
    }
    default: {
      return [];
    }
  }
};

export const useOrderBookRoute = ({
  offeringId,
  offeringStatus,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  // no point in displaying an Order Book without Indications - require access to both
  // (this might change once more than the Institutional Demand section get implemented)
  const { canRead: canReadOrderBook } = useCheckOrderBookAccess({ offeringId });
  const { canRead: canReadIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });
  const canViewNavItem = !!(
    canReadOrderBook &&
    canReadIndications &&
    offeringId &&
    offeringStatus &&
    offeringStatus !== OfferingStatus.Filed &&
    offeringStatus !== OfferingStatus.Withdrawn
  );

  let queryResult = useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery({
    variables: {
      id: offeringId ?? '',
    },
    skip: !canViewNavItem,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  queryResult = useWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription(
    queryResult,
    {
      variables: () => ({
        offeringId: offeringId ?? '',
      }),
      updateQuery:
        () =>
        (prev, { subscriptionData }) =>
          merge({}, prev, { orderBook: subscriptionData.data.orderBookStatusChanged }),
      isEnabled: canViewNavItem,
    }
  );

  const { loading, data } = queryResult;

  if (!offeringId) {
    return undefined;
  }

  const orderBook = data?.orderBook;
  const status = orderBook ? orderBook.state : OrderBookState.NeverOpened;
  const href = routeFactory.orderBook.getUrlPath({ offeringId });
  const [label, color] = stateToColor(status);
  if (!canViewNavItem) {
    return undefined;
  }

  return {
    href: href,
    label: (
      <Tooltip title={`Order Book Status: ${label}`} placement="top" arrow>
        <span>Order Book</span>
      </Tooltip>
    ),
    iconPosition: 'start',
    icon: loading ? undefined : <FiberManualRecordIcon color={color} />,
  };
};
