import { Chip, ChipProps, Typography } from '@cmg/design-system';
import React from 'react';

import { OfferingStatus } from '../../../../graphql';

export type OfferingStatusChipProps = Readonly<{
  status: OfferingStatus;
}> &
  ChipProps;

export const OfferingStatusChip: React.FC<OfferingStatusChipProps> = ({ status, ...rest }) => {
  let color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  switch (status) {
    case OfferingStatus.Live:
      color = 'success';
      break;
    case OfferingStatus.Priced:
      color = 'secondary';
      break;
    case OfferingStatus.Filed:
      color = 'primary';
      break;
    case OfferingStatus.Postponed:
      color = 'warning';
      break;
    case OfferingStatus.Withdrawn:
      color = 'error';
      break;
    case OfferingStatus.Terminated:
      color = 'error';
      break;
  }

  const formattedStatus = status.charAt(0) + status.slice(1).toLowerCase();

  return (
    <Chip
      {...rest}
      label={<Typography variant="highlight2">{formattedStatus}</Typography>}
      sx={{
        padding: '3px 2px',
      }}
      color={color}
    />
  );
};
