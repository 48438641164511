import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeletePotAccountWireMutation,
  SyndicateWires_PotAccountWiresDocument,
  useSyndicateWires_DeletePotAccountWireMutation,
} from '../graphql';

export type DeletePotAccountWireMutation = SyndicateWires_DeletePotAccountWireMutation;

export const useDeletePotAccountWireMutation = () => {
  return useSyndicateWires_DeletePotAccountWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_PotAccountWiresDocument];
  return getManyOperationNames(documentNodes);
}
