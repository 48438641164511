import {
  BaseAgGrid,
  ColDef,
  createCellStyle,
  defaultFrameworkComponents,
  numericUtil,
  PopoverProps,
  theme,
  UUID,
  valueFormatters,
} from '@cmg/common';
import { GridApi, RowNode, ValueFormatterParams } from 'ag-grid-community';

import { sortCaseInsensitive } from '../../../../common/util/sortCaseInsensitive';
import { SalesCreditWarning } from '../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';
import {
  ReleaseSalesCreditsFunction,
  ReviewSalesCreditsItemFunction,
  UpdateSalesCreditsStatusFunction,
} from '../grid-actions/SalesCreditsGridActions.model';
import ActionsRenderer from './actions-renderer/ActionsRenderer';
import BankInvestorKeyChangedPopoverContent from './bank-investor-key-changed-popover-content/BankInvestorKeyChangedPopoverContent';
import CreditsReleasedPopoverContent from './credits-released-popover-content/CreditsReleasedPopoverContent';
import HeaderGroupCheckboxRenderer from './header-group-checkbox-renderer/HeaderGroupCheckboxRenderer';
import InvestorNameRenderer from './investor-name-renderer/InvestorNameRenderer';
import ReleaseStatusRenderer from './release-status-renderer/ReleaseStatusRenderer';
import SalesCreditsRenderer from './sales-credits-renderer/SalesCreditsRenderer';
import TotalInvestorRenderer from './total-investor-renderer/TotalInvestorRenderer';
import TotalSalesCreditsRenderer from './total-sales-credits-renderer/TotalSalesCreditsRenderer';
import TotalWarningRenderer from './total-warning-renderer/TotalWarningRenderer';
import WarningRenderer from './warning-renderer/WarningRenderer';

export type SalesCreditsGridContext = {
  offeringId: UUID;
  onRelease: ReleaseSalesCreditsFunction;
  onUpdateStatus: UpdateSalesCreditsStatusFunction;
  onOpenReviewModal: ReviewSalesCreditsItemFunction;
  sellingConcession: number | null | undefined;
  rows: SalesCredits_SalesCreditItemPartsFragment[];
};

export type CellRendererSelectorParams = {
  data: SalesCredits_SalesCreditItemPartsFragment;
  node: RowNode;
  api: GridApi;
  context: SalesCreditsGridContext;
};

export const salesCreditsCols = {
  warnings: 'warnings',
  selected: 'selected',
  investorFirmName: 'investorFirmName',
  bankInvestorKey: 'bankInvestorKey',
  salesCredits: 'salesCredits',
  salesCreditsReleased: 'salesCreditsReleased',
  allocationShares: 'allocationShares',
  potEconomics: 'potEconomics',
  designationShares: 'designationShares',
  statusDetails: 'statusDetails',
  status: 'status',
  actions: 'actions',
};

const defaultCellStyle = createCellStyle(() => ({
  justifyContent: 'flex-end',
}));

export const selectedColCellRendererSelector = ({
  data,
  node,
}: CellRendererSelectorParams): ReturnType<Required<ColDef>['cellRendererSelector']> => {
  const isTotalRow = node.isRowPinned();
  if (isTotalRow || data.allowedStatusTransitions.length === 0) {
    return { component: 'emptyComponent' };
  }

  const isDisabled = data.warnings.includes(SalesCreditWarning.OrderBookBankInvestorKeyChanged);
  const popoverProps: PopoverProps = {
    variant: 'DARK',
    placement: 'topLeft',
    arrowPointAtCenter: true,
    disabled: !isDisabled,
    content: <span>Review the modified Bank Investor Key before taking any further action</span>,
  };
  return { component: 'rowSelectorRenderer', params: { disabled: isDisabled, popoverProps } };
};

export const isSalesCreditsRowSelectable = ({
  data,
}: {
  data: SalesCredits_SalesCreditItemPartsFragment;
}): boolean => {
  return (
    data.allowedStatusTransitions.length > 0 &&
    !data.warnings.includes(SalesCreditWarning.OrderBookBankInvestorKeyChanged)
  );
};

export const salesCreditsReleasedRendererSelector = ({
  node,
  data,
}: CellRendererSelectorParams): ReturnType<Required<ColDef>['cellRendererSelector']> => {
  const isTotalRow = node.isRowPinned();
  if (isTotalRow) {
    return {};
  }

  const creditsChanged = data.warnings.includes(SalesCreditWarning.SalesCreditChanged);
  if (!creditsChanged) {
    return {};
  }

  const popoverProps: PopoverProps = {
    variant: 'LIGHT',
    disabled: false,
    placement: 'topLeft',
    arrowPointAtCenter: true,
    content: <CreditsReleasedPopoverContent rowData={data} />,
  };
  return {
    component: 'validationRenderer',
    params: {
      validation: 'error',
      justifyContent: 'space-between',
      popoverProps,
    },
  };
};

export const bankInvestorKeyRendererSelector = ({
  node,
  data,
  context,
}: CellRendererSelectorParams): ReturnType<Required<ColDef>['cellRendererSelector']> => {
  const isTotalRow = node.isRowPinned();
  if (isTotalRow) {
    return {};
  }

  const bankInvestorKeyChanged = data.warnings.includes(
    SalesCreditWarning.OrderBookBankInvestorKeyChanged
  );
  if (!bankInvestorKeyChanged) {
    return {};
  }

  const popoverProps: PopoverProps = {
    variant: 'LIGHT',
    disabled: false,
    placement: 'topLeft',
    arrowPointAtCenter: true,
    content: (
      <BankInvestorKeyChangedPopoverContent
        salesCreditsItem={data}
        onOpenReviewModal={context.onOpenReviewModal}
      />
    ),
  };
  return { component: 'validationRenderer', params: { validation: 'error', popoverProps } };
};

export const bankInvestorKeyFormatter = ({ value }: ValueFormatterParams) => {
  return value || '-';
};

export const salesCreditsCellStyle = createCellStyle<SalesCredits_SalesCreditItemPartsFragment>(
  () => ({
    display: 'block',
    justifyContent: 'flex-end',
    lineHeight: '34px',
    padding: 0,
  })
);

export const salesCreditsColumns: Parameters<typeof BaseAgGrid>[0]['columns'] = [
  {
    colId: salesCreditsCols.warnings,
    field: salesCreditsCols.warnings,
    headerName: '',
    pinned: 'left',
    maxWidth: 32,
    minWidth: 32,
    cellRendererFramework: WarningRenderer,
    pinnedRowCellRendererFramework: TotalWarningRenderer,
    editable: false,
    sortable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
  },
  {
    colId: salesCreditsCols.selected,
    field: salesCreditsCols.selected,
    headerName: '',
    pinned: 'left',
    maxWidth: 64,
    minWidth: 64,
    cellRendererSelector: selectedColCellRendererSelector,
    headerComponentFramework: HeaderGroupCheckboxRenderer,
    editable: false,
    sortable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
  },
  {
    colId: salesCreditsCols.investorFirmName,
    field: salesCreditsCols.investorFirmName,
    headerName: 'Investor',
    pinned: 'left',
    maxWidth: 350,
    minWidth: 225,
    comparator: sortCaseInsensitive,
    pinnedRowCellRendererFramework: TotalInvestorRenderer,
    cellRendererFramework: InvestorNameRenderer,
    editable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
    cellStyle: {
      display: 'block',
      lineHeight: '32px',
    },
  },
  {
    colId: salesCreditsCols.bankInvestorKey,
    field: salesCreditsCols.bankInvestorKey,
    headerName: 'Bank Investor Key',
    pinned: 'left',
    maxWidth: 200,
    minWidth: 160,
    cellRendererSelector: bankInvestorKeyRendererSelector,
    valueFormatter: bankInvestorKeyFormatter,
    cellStyle: {
      borderRight: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
    },
    editable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
  },
  {
    colId: salesCreditsCols.salesCredits,
    field: salesCreditsCols.salesCredits,
    headerName: 'Sales Credits',
    maxWidth: 250,
    minWidth: 160,
    pinnedRowCellRendererFramework: TotalSalesCreditsRenderer,
    cellRendererFramework: SalesCreditsRenderer,
    valueGetter: ({ data }: { data: SalesCredits_SalesCreditItemPartsFragment }) =>
      data.salesCreditsManual ?? data.salesCredits,
    editable: false,
    lockPinned: true,
    type: 'rightAligned',
    cellStyle: salesCreditsCellStyle,
  },
  {
    colId: salesCreditsCols.salesCreditsReleased,
    field: salesCreditsCols.salesCreditsReleased,
    headerName: 'Sales Credits Released',
    maxWidth: 250,
    minWidth: 160,
    valueFormatter: valueFormatters.getCurrencyValueFormatter({ shouldFormatPinnedRow: true }),
    cellRendererSelector: salesCreditsReleasedRendererSelector,
    editable: false,
    lockPinned: true,
    type: 'rightAligned',
    cellStyle: defaultCellStyle,
  },
  {
    colId: salesCreditsCols.allocationShares,
    field: salesCreditsCols.allocationShares,
    headerName: 'Allocations',
    maxWidth: 250,
    minWidth: 160,
    valueFormatter: valueFormatters.getIntegerValueFormatter({ shouldFormatPinnedRow: true }),
    editable: false,
    lockPinned: true,
    type: 'rightAligned',
    cellStyle: defaultCellStyle,
  },
  {
    colId: salesCreditsCols.potEconomics,
    field: salesCreditsCols.potEconomics,
    headerName: 'Pot Economics %',
    maxWidth: 250,
    minWidth: 160,
    valueGetter: ({ data }: { data: SalesCredits_SalesCreditItemPartsFragment }) =>
      calculatePotEconomics(data),
    valueFormatter: valueFormatters.getPercentsValueFormatter({ shouldFormatPinnedRow: true }),
    editable: false,
    lockPinned: true,
    type: 'rightAligned',
    cellStyle: defaultCellStyle,
  },
  {
    colId: salesCreditsCols.designationShares,
    field: salesCreditsCols.designationShares,
    headerName: 'Designated Shares',
    maxWidth: 250,
    minWidth: 160,
    valueFormatter: valueFormatters.getIntegerValueFormatter({ shouldFormatPinnedRow: true }),
    editable: false,
    lockPinned: true,
    type: 'rightAligned',
    cellStyle: defaultCellStyle,
  },
  {
    colId: salesCreditsCols.statusDetails,
    field: salesCreditsCols.statusDetails,
    headerName: 'Status Details',
    maxWidth: 500,
    minWidth: 160,
    cellRendererFramework: defaultFrameworkComponents.longStringCellRenderer,
    cellStyle: { overflow: 'hidden' },
    editable: false,
    lockPinned: true,
  },
  {
    colId: salesCreditsCols.status,
    field: salesCreditsCols.status,
    headerName: 'Sales Credits Release Status',
    pinned: 'right',
    maxWidth: 200,
    minWidth: 200,
    cellRendererFramework: ReleaseStatusRenderer,
    cellStyle: {
      borderLeft: `${theme.border.width.medium} solid ${theme.border.color.dark}`,
    },
    editable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
  },
  {
    colId: salesCreditsCols.actions,
    field: salesCreditsCols.actions,
    headerName: '',
    pinned: 'right',
    maxWidth: 32,
    minWidth: 32,
    cellRendererFramework: ActionsRenderer,
    editable: false,
    suppressAutoSize: true,
    suppressMovable: true,
    lockPinned: true,
    sortable: false,
  },
];

export const calculatePotEconomics = (salesCredit: SalesCredits_SalesCreditItemPartsFragment) => {
  return numericUtil.divide(salesCredit.designationShares, salesCredit.allocationShares);
};
