import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { useCheckDeliveryInstructionAccess } from '../../../../../../../common/util/check-access/useCheckDeliveryInstructionAccess';
import { IndicationStatus } from '../../../../../../../graphql';
import { getFirmDisplayName } from '../../../../utils';
import { useDeleteBillingAndDeliveryAgent } from '../../hooks/useDeleteBillingAndDeliveryAgent';
import { useUpdateBillingAndDeliveryAgent } from '../../hooks/useUpdateBillingAndDeliveryAgent';
import { IndicationWithDemandLevels, InstitutionalDemandGridContext } from '../../types';
import { SColumn } from './BDAgentCell.styles';
import BDAgentForm, { BillingAndDeliveryFormValues } from './BDAgentForm';

export type Props = {
  data: IndicationWithDemandLevels;
  context: InstitutionalDemandGridContext;
};

/**
 * Renders the Billing and Delivery drop down Cell within the Institutional Demand Grid.
 */
const BDAgentCell: React.FC<Props> = ({ data, context }) => {
  const { canUpdate: canUpdateDeliveryInstruction } = useCheckDeliveryInstructionAccess({
    offeringId: data.offeringId,
  });

  const [updateBillingAndDeliveryAgent, { loading: isSavingBillingAndDeliveryAgent }] =
    useUpdateBillingAndDeliveryAgent();
  const [deleteBillingAndDeliveryAgent, { loading: isDeletingBillingAndDeliveryAgent }] =
    useDeleteBillingAndDeliveryAgent();

  let billingAndDeliveryAgentCmgEntityKey = data.billingAndDeliveryAgentCmgEntityKey ?? '';
  const billingAndDeliveryManager = context.syndicateManagers[billingAndDeliveryAgentCmgEntityKey];

  const onBDAgentChange = async (bdAgent: BillingAndDeliveryFormValues) => {
    if (
      bdAgent.billingAndDeliveryAgentCmgEntityKey === 'Unassigned' ||
      bdAgent.billingAndDeliveryAgentCmgEntityKey === null
    ) {
      try {
        await deleteBillingAndDeliveryAgent({
          variables: {
            indicationId: data.id,
            offeringId: data.offeringId,
          },
        });
        SnackbarManager.success('B&D Agent updated successfully.');
      } catch {
        SnackbarManager.error('Failed to update B&D Agent.');
      }
    } else {
      billingAndDeliveryAgentCmgEntityKey = bdAgent.billingAndDeliveryAgentCmgEntityKey;
      try {
        await updateBillingAndDeliveryAgent({
          variables: {
            indicationId: data.id,
            offeringId: data.offeringId,
            input: { managerCmgEntityKey: bdAgent.billingAndDeliveryAgentCmgEntityKey },
          },
        });
        SnackbarManager.success('B&D Agent updated successfully.');
      } catch {
        SnackbarManager.error('Failed to update B&D Agent.');
      }
    }
  };
  const loading = isSavingBillingAndDeliveryAgent || isDeletingBillingAndDeliveryAgent;

  return (
    <div>
      {(!canUpdateDeliveryInstruction || data.status === IndicationStatus.Duplicate) && (
        <SColumn data-test-id="non-sa-billingAndDeliveryAgentFirmName">
          {getFirmDisplayName(billingAndDeliveryManager ?? null, { defaultName: 'Unassigned' })}
        </SColumn>
      )}
      {canUpdateDeliveryInstruction && data.status !== IndicationStatus.Duplicate && (
        <BDAgentForm
          managers={context.syndicateManagers}
          offeringId={data.offeringId}
          billingAndDeliveryAgentCmgEntityKey={billingAndDeliveryAgentCmgEntityKey}
          finalAllocationShareQuantity={data.finalAllocation && data.finalAllocation?.shareQuantity}
          loading={loading}
          onChange={values => onBDAgentChange(values)}
          menuPortalTarget={document.body}
          status={data.status}
          type={data.type}
        />
      )}
    </div>
  );
};

export default BDAgentCell;
