import { UUID } from '@cmg/common';
import {
  CheckIcon,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVertOutlinedIcon,
} from '@cmg/design-system';
import includes from 'lodash/includes';
import React, { useRef, useState } from 'react';

import { getFeatureToggles } from '../../../../../../../../../../common/config';
import { useInstitutionalDemandPreferencesContext } from '../../../../../context/InstitutionalDemandPreferencesContext';
import { generatePinnedColumns } from './MenuItemKebabMenu.model';

export type Props = Readonly<{
  colId: UUID;
  onCloseAllocationsDropdown: () => void;
  canEdit?: boolean;
  onEdit?: () => void;
  canDelete?: boolean;
  onDelete?: () => void;
  canDuplicate?: boolean;
  onDuplicate?: () => Promise<void>;
  canShare?: boolean;
  onShare?: () => void;
  canPromoteToFinal?: boolean;
  onPromoteToFinal?: () => void;
  canCopyToDefault?: boolean;
  onCopyToDefault?: () => void;
}>;

const MenuItemKebabMenu: React.FC<Props> = ({
  colId,
  onCloseAllocationsDropdown,
  canEdit,
  onEdit,
  canDelete,
  onDelete,
  canDuplicate,
  onDuplicate,
  canShare,
  onShare,
  canPromoteToFinal,
  onPromoteToFinal,
  canCopyToDefault,
  onCopyToDefault,
}) => {
  const btnRef = useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  const { isOrderBookPinnedColumnsOn } = getFeatureToggles();
  const { pinnedColumns, setPinnedColumns } = useInstitutionalDemandPreferencesContext();

  const isKebabEnabled = React.useMemo(
    () => canEdit || canDuplicate || canShare || canPromoteToFinal || isOrderBookPinnedColumnsOn,
    [canDuplicate, canEdit, canPromoteToFinal, canShare, isOrderBookPinnedColumnsOn]
  );
  const isLeftPinned = React.useMemo(
    () => includes(pinnedColumns.left, colId),
    [pinnedColumns.left, colId]
  );
  const isRightPinned = React.useMemo(
    () => includes(pinnedColumns.right, colId),
    [pinnedColumns.right, colId]
  );

  const withDivider = React.useMemo(() => {
    return (
      isOrderBookPinnedColumnsOn &&
      (canEdit || canDelete || canDuplicate || canShare || canPromoteToFinal || canCopyToDefault)
    );
  }, [
    canCopyToDefault,
    canDelete,
    canDuplicate,
    canEdit,
    canPromoteToFinal,
    canShare,
    isOrderBookPinnedColumnsOn,
  ]);

  const handleDropdownClick = React.useCallback(() => {
    setIsOpened(isOpen => !isOpen);
  }, []);
  const handleClose = React.useCallback(() => {
    setIsOpened(false);
  }, []);

  const handlePinRight = React.useCallback(() => {
    handleClose();
    onCloseAllocationsDropdown();
    setPinnedColumns(generatePinnedColumns({ pinnedColumns, colId, isPinnedRight: true }));
  }, [colId, handleClose, onCloseAllocationsDropdown, pinnedColumns, setPinnedColumns]);
  const handlePinLeft = React.useCallback(() => {
    handleClose();
    onCloseAllocationsDropdown();
    setPinnedColumns(generatePinnedColumns({ pinnedColumns, colId, isPinnedLeft: true }));
  }, [colId, handleClose, onCloseAllocationsDropdown, pinnedColumns, setPinnedColumns]);
  const handleUnpin = React.useCallback(() => {
    handleClose();
    onCloseAllocationsDropdown();
    setPinnedColumns(generatePinnedColumns({ pinnedColumns, colId }));
  }, [colId, handleClose, onCloseAllocationsDropdown, pinnedColumns, setPinnedColumns]);

  return (
    <div>
      <IconButton
        aria-label="Toggle allocations dropdown"
        edge="end"
        ref={btnRef}
        onClick={handleDropdownClick}
        disabled={!isKebabEnabled}
      >
        <MoreVertOutlinedIcon />
      </IconButton>

      <Menu open={isOpened} onClose={handleClose} anchorEl={btnRef.current}>
        {canEdit && (
          <MenuItem dense onClick={onEdit}>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem dense onClick={onDelete}>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        {canDuplicate && (
          <MenuItem dense onClick={onDuplicate}>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
        )}
        {canShare && (
          <MenuItem dense onClick={onShare}>
            <ListItemText>Share</ListItemText>
          </MenuItem>
        )}
        {canCopyToDefault && (
          <MenuItem dense onClick={onCopyToDefault}>
            <ListItemText>Copy to Default</ListItemText>
          </MenuItem>
        )}
        {canPromoteToFinal && (
          <MenuItem dense onClick={onPromoteToFinal}>
            <ListItemText>Promote to Final</ListItemText>
          </MenuItem>
        )}

        {withDivider && (
          <div>
            {/* when divider is direct child of menu it adds padding to top and bottom */}
            <Divider />
          </div>
        )}

        {isOrderBookPinnedColumnsOn && (
          <MenuItem dense onClick={handlePinRight}>
            {isRightPinned && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText inset={!isRightPinned}>Pin Right</ListItemText>
          </MenuItem>
        )}
        {isOrderBookPinnedColumnsOn && (
          <MenuItem dense onClick={handlePinLeft}>
            {isLeftPinned && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText inset={!isLeftPinned}>Pin Left</ListItemText>
          </MenuItem>
        )}
        {isOrderBookPinnedColumnsOn && (
          <MenuItem dense onClick={handleUnpin}>
            {!isLeftPinned && !isRightPinned && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText inset={isLeftPinned || isRightPinned}>No Pin</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MenuItemKebabMenu;
