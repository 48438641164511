import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_SendNonSyndicatePaymentWireDocument,
  SyndicateWires_SendNonSyndicatePaymentWireMutation,
  useSyndicateWires_SendNonSyndicatePaymentWireMutation,
} from '../graphql';

export type SendNonSyndicatePaymentWireMutation =
  SyndicateWires_SendNonSyndicatePaymentWireMutation;

export const useSendNonSyndicatePaymentWireMutation = () => {
  return useSyndicateWires_SendNonSyndicatePaymentWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_SendNonSyndicatePaymentWireDocument,
  ];
  return getManyOperationNames(documentNodes);
}
