import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import { SBoxWrapper, StyledLink } from './InfoBox.styles';

export type Props = { gridTemplateColumns: string; sourceOfData?: { text: string; link: string } };

export const InfoBox: React.FC<Props> = ({ gridTemplateColumns, sourceOfData, children }) => {
  return (
    <SBoxWrapper>
      <Table gridTemplateColumns={gridTemplateColumns}>{children}</Table>

      {sourceOfData && (
        <StyledLink to={sourceOfData.link}>Sourced from {sourceOfData.text}</StyledLink>
      )}
    </SBoxWrapper>
  );
};
