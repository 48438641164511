import React from 'react';

import { SAllocationTableHeader, SColumn, SSharesColumn } from '../../Allocation.styles';

export type Props = {
  isEditing?: boolean;
  shares?: number | null;
};

const AllocationTableHeader: React.FC<Props> = ({ isEditing = false, shares }) => {
  return (
    <SAllocationTableHeader>
      <SSharesColumn align="left"># Shares</SSharesColumn>
      {!isEditing && shares != null ? (
        <SColumn align="left" data-test-id="allocation-header-shares">
          $ Amount
        </SColumn>
      ) : null}
    </SAllocationTableHeader>
  );
};

export default AllocationTableHeader;
