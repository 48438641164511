import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  padding: 0 11px;
  align-items: center;
  justify-content: flex-end;
`;

export const SSalesCreditsWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  line-height: 14px;
  text-align: right;
  font-size: ${({ theme }) => theme.text.size.tiny};

  & :first-child {
    padding-top: 4px;
    font-size: ${({ theme }) => theme.text.size.medium};
  }
`;

export const SWarningText = styled.div`
  color: ${({ theme }) => theme.text.color.error};
`;
