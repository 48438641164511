/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_DeleteDraftAllocationSetMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  id: Types.Scalars['UUID'];
}>;

export type OrderBook_DeleteDraftAllocationSetMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteDraftInstitutionalAllocationSet: {
    readonly __typename?: 'DraftAllocationSet';
    readonly id: string;
  };
};

export const OrderBook_DeleteDraftAllocationSetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_DeleteDraftAllocationSet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDraftInstitutionalAllocationSet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_DeleteDraftAllocationSetMutationFn = Apollo.MutationFunction<
  OrderBook_DeleteDraftAllocationSetMutation,
  OrderBook_DeleteDraftAllocationSetMutationVariables
>;

/**
 * __useOrderBook_DeleteDraftAllocationSetMutation__
 *
 * To run a mutation, you first call `useOrderBook_DeleteDraftAllocationSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_DeleteDraftAllocationSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookDeleteDraftAllocationSetMutation, { data, loading, error }] = useOrderBook_DeleteDraftAllocationSetMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderBook_DeleteDraftAllocationSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_DeleteDraftAllocationSetMutation,
    OrderBook_DeleteDraftAllocationSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_DeleteDraftAllocationSetMutation,
    OrderBook_DeleteDraftAllocationSetMutationVariables
  >(OrderBook_DeleteDraftAllocationSetDocument, options);
}
export type OrderBook_DeleteDraftAllocationSetMutationHookResult = ReturnType<
  typeof useOrderBook_DeleteDraftAllocationSetMutation
>;
export type OrderBook_DeleteDraftAllocationSetMutationResult =
  Apollo.MutationResult<OrderBook_DeleteDraftAllocationSetMutation>;
export type OrderBook_DeleteDraftAllocationSetMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_DeleteDraftAllocationSetMutation,
  OrderBook_DeleteDraftAllocationSetMutationVariables
>;
