import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  background-color: ${({ theme }) => theme.background.color.white};
`;

/**
 * @deprecated - use layout components from @cmg/design-system instead
 */
export const PanelContent = styled.div<{ paddingSize?: 'S' | 'M' | 'MODAL' }>`
  padding: ${({ paddingSize }) => {
    switch (paddingSize) {
      case 'MODAL':
        return '24px 0';
      case 'S':
        return '16px 8px';
      case 'M':
      default:
        return '24px 16px';
    }
  }};
`;

export const SHeader = styled.div`
  background-color: ${({ theme }) => theme.border.color.light};
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 32px;
`;

export const SHeaderTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  line-height: 16px;
  color: ${({ theme }) => theme.designSystem.colors.darkBlue['200']};
`;
