import { Typography } from '@cmg/design-system';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import React from 'react';

import { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const IndicationStatusChangeContent: React.FC<Props> = ({ update, pricingCurrencyCode }) => {
  const { investorFirm, newIndication, oldIndication } = update;

  return (
    <Typography aria-label="Recent update - Indication status changed" component="span">
      <Typography component="span" variant="highlight1">
        {investorFirm}
      </Typography>{' '}
      status changed from{' '}
      <Typography component="span" variant="highlight1">
        {startCase(lowerCase(oldIndication?.status ?? 'Unknown'))}
      </Typography>{' '}
      to{' '}
      <Typography component="span" variant="highlight1">
        {startCase(lowerCase(newIndication?.status ?? 'Unknown'))}
      </Typography>
      .
    </Typography>
  );
};
