/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmLevelDemand_MdlIndicationPartsFragment = {
  readonly __typename?: 'MdlIndication';
  readonly unitType: Types.MdlIndicationUnitType;
  readonly dollars?: number | null;
  readonly percentage?: number | null;
  readonly shares?: number | null;
  readonly pricingType: Types.MdlIndicationPricingType;
  readonly limitPrice?: number | null;
  readonly realDemandDollars?: number | null;
  readonly realDemandPercentage?: number | null;
  readonly realDemandShares?: number | null;
  readonly limitType: Types.MdlIndicationLimitType;
  readonly limitPercentage?: number | null;
};

export type FirmLevelDemand_MdlAllocationLastModifiedPartsFragment = {
  readonly __typename?: 'MdlAllocationLastModified';
  readonly timestamp?: string | null;
  readonly name?: string | null;
  readonly email?: string | null;
  readonly id?: string | null;
};

export type FirmLevelDemand_MdlAllocationPartsFragment = {
  readonly __typename?: 'MdlAllocation';
  readonly id: string;
  readonly fillPercentage?: number | null;
  readonly investment?: number | null;
  readonly salesConcession?: number | null;
  readonly shares?: number | null;
  readonly ranking: Types.MdlAllocationRanking;
  readonly ioiType: Types.MdlAllocationIoiType;
  readonly lastModified: {
    readonly __typename?: 'MdlAllocationLastModified';
    readonly timestamp?: string | null;
    readonly name?: string | null;
    readonly email?: string | null;
    readonly id?: string | null;
  };
};

export type FirmLevelDemand_MdlInternalDemandIndicationsPartsFragment = {
  readonly __typename?: 'MdlInternalDemandIndications';
  readonly id: string;
  readonly indications: ReadonlyArray<{
    readonly __typename?: 'MdlIndication';
    readonly unitType: Types.MdlIndicationUnitType;
    readonly dollars?: number | null;
    readonly percentage?: number | null;
    readonly shares?: number | null;
    readonly pricingType: Types.MdlIndicationPricingType;
    readonly limitPrice?: number | null;
    readonly realDemandDollars?: number | null;
    readonly realDemandPercentage?: number | null;
    readonly realDemandShares?: number | null;
    readonly limitType: Types.MdlIndicationLimitType;
    readonly limitPercentage?: number | null;
  }>;
};

export type FirmLevelDemand_MdlAllocationResponsePartsFragment = {
  readonly __typename?: 'MdlAllocationResponse';
  readonly id: string;
  readonly allocation?: {
    readonly __typename?: 'MdlAllocation';
    readonly id: string;
    readonly fillPercentage?: number | null;
    readonly investment?: number | null;
    readonly salesConcession?: number | null;
    readonly shares?: number | null;
    readonly ranking: Types.MdlAllocationRanking;
    readonly ioiType: Types.MdlAllocationIoiType;
    readonly lastModified: {
      readonly __typename?: 'MdlAllocationLastModified';
      readonly timestamp?: string | null;
      readonly name?: string | null;
      readonly email?: string | null;
      readonly id?: string | null;
    };
  } | null;
};

export type FirmLevelDemand_InternalIndicationsDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FirmLevelDemand_InternalIndicationsDemandQuery = {
  readonly __typename?: 'Query';
  readonly internalIndicationsDemand: {
    readonly __typename?: 'MdlInternalDemandIndications';
    readonly id: string;
    readonly indications: ReadonlyArray<{
      readonly __typename?: 'MdlIndication';
      readonly unitType: Types.MdlIndicationUnitType;
      readonly dollars?: number | null;
      readonly percentage?: number | null;
      readonly shares?: number | null;
      readonly pricingType: Types.MdlIndicationPricingType;
      readonly limitPrice?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
      readonly limitType: Types.MdlIndicationLimitType;
      readonly limitPercentage?: number | null;
    }>;
  };
};

export type FirmLevelDemand_InternalAllocationDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FirmLevelDemand_InternalAllocationDemandQuery = {
  readonly __typename?: 'Query';
  readonly internalAllocationDemand: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly timestamp?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly id?: string | null;
      };
    } | null;
  };
};

export type FirmLevelDemand_UpsertInternalDemandIndicationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.UpsertInternalDemandInput;
}>;

export type FirmLevelDemand_UpsertInternalDemandIndicationsMutation = {
  readonly __typename?: 'Mutation';
  readonly upsertInternalDemandIndications: {
    readonly __typename?: 'MdlInternalDemandIndications';
    readonly id: string;
    readonly indications: ReadonlyArray<{
      readonly __typename?: 'MdlIndication';
      readonly unitType: Types.MdlIndicationUnitType;
      readonly dollars?: number | null;
      readonly percentage?: number | null;
      readonly shares?: number | null;
      readonly pricingType: Types.MdlIndicationPricingType;
      readonly limitPrice?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
      readonly limitType: Types.MdlIndicationLimitType;
      readonly limitPercentage?: number | null;
    }>;
  };
};

export type FirmLevelDemand_PassInternalDemandOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
}>;

export type FirmLevelDemand_PassInternalDemandOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly passInternalDemandOffering: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly timestamp?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly id?: string | null;
      };
    } | null;
  };
};

export type FirmLevelDemand_RegMInternalDemandOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
}>;

export type FirmLevelDemand_RegMInternalDemandOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly regMInternalDemandOffering: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly timestamp?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly id?: string | null;
      };
    } | null;
  };
};

export type FirmLevelDemand_ActivateInternalDemandOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
}>;

export type FirmLevelDemand_ActivateInternalDemandOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly activateInternalDemandOffering: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly timestamp?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly id?: string | null;
      };
    } | null;
  };
};

export type FirmLevelDemand_PublishedOfferingTypeQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FirmLevelDemand_PublishedOfferingTypeQuery = {
  readonly __typename?: 'Query';
  readonly limitedPublishedOffering: {
    readonly __typename?: 'OfferingLimited';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly isExecutingOnPlatform: boolean;
  };
};

export const FirmLevelDemand_MdlIndicationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FirmLevelDemand_MdlInternalDemandIndicationsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlInternalDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlInternalDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FirmLevelDemand_MdlAllocationLastModifiedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FirmLevelDemand_MdlAllocationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FirmLevelDemand_MdlAllocationResponsePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FirmLevelDemand_InternalIndicationsDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FirmLevelDemand_InternalIndicationsDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalIndicationsDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FirmLevelDemand_MdlInternalDemandIndicationsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlInternalDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlInternalDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFirmLevelDemand_InternalIndicationsDemandQuery__
 *
 * To run a query within a React component, call `useFirmLevelDemand_InternalIndicationsDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_InternalIndicationsDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmLevelDemand_InternalIndicationsDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_InternalIndicationsDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    FirmLevelDemand_InternalIndicationsDemandQuery,
    FirmLevelDemand_InternalIndicationsDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FirmLevelDemand_InternalIndicationsDemandQuery,
    FirmLevelDemand_InternalIndicationsDemandQueryVariables
  >(FirmLevelDemand_InternalIndicationsDemandDocument, options);
}
export function useFirmLevelDemand_InternalIndicationsDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmLevelDemand_InternalIndicationsDemandQuery,
    FirmLevelDemand_InternalIndicationsDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FirmLevelDemand_InternalIndicationsDemandQuery,
    FirmLevelDemand_InternalIndicationsDemandQueryVariables
  >(FirmLevelDemand_InternalIndicationsDemandDocument, options);
}
export type FirmLevelDemand_InternalIndicationsDemandQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_InternalIndicationsDemandQuery
>;
export type FirmLevelDemand_InternalIndicationsDemandLazyQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_InternalIndicationsDemandLazyQuery
>;
export type FirmLevelDemand_InternalIndicationsDemandQueryResult = Apollo.QueryResult<
  FirmLevelDemand_InternalIndicationsDemandQuery,
  FirmLevelDemand_InternalIndicationsDemandQueryVariables
>;
export const FirmLevelDemand_InternalAllocationDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FirmLevelDemand_InternalAllocationDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalAllocationDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFirmLevelDemand_InternalAllocationDemandQuery__
 *
 * To run a query within a React component, call `useFirmLevelDemand_InternalAllocationDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_InternalAllocationDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmLevelDemand_InternalAllocationDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_InternalAllocationDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    FirmLevelDemand_InternalAllocationDemandQuery,
    FirmLevelDemand_InternalAllocationDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FirmLevelDemand_InternalAllocationDemandQuery,
    FirmLevelDemand_InternalAllocationDemandQueryVariables
  >(FirmLevelDemand_InternalAllocationDemandDocument, options);
}
export function useFirmLevelDemand_InternalAllocationDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmLevelDemand_InternalAllocationDemandQuery,
    FirmLevelDemand_InternalAllocationDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FirmLevelDemand_InternalAllocationDemandQuery,
    FirmLevelDemand_InternalAllocationDemandQueryVariables
  >(FirmLevelDemand_InternalAllocationDemandDocument, options);
}
export type FirmLevelDemand_InternalAllocationDemandQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_InternalAllocationDemandQuery
>;
export type FirmLevelDemand_InternalAllocationDemandLazyQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_InternalAllocationDemandLazyQuery
>;
export type FirmLevelDemand_InternalAllocationDemandQueryResult = Apollo.QueryResult<
  FirmLevelDemand_InternalAllocationDemandQuery,
  FirmLevelDemand_InternalAllocationDemandQueryVariables
>;
export const FirmLevelDemand_UpsertInternalDemandIndicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FirmLevelDemand_UpsertInternalDemandIndications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertInternalDemandInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertInternalDemandIndications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'FirmLevelDemand_MdlInternalDemandIndicationsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlInternalDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlInternalDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FirmLevelDemand_UpsertInternalDemandIndicationsMutationFn = Apollo.MutationFunction<
  FirmLevelDemand_UpsertInternalDemandIndicationsMutation,
  FirmLevelDemand_UpsertInternalDemandIndicationsMutationVariables
>;

/**
 * __useFirmLevelDemand_UpsertInternalDemandIndicationsMutation__
 *
 * To run a mutation, you first call `useFirmLevelDemand_UpsertInternalDemandIndicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_UpsertInternalDemandIndicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firmLevelDemandUpsertInternalDemandIndicationsMutation, { data, loading, error }] = useFirmLevelDemand_UpsertInternalDemandIndicationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFirmLevelDemand_UpsertInternalDemandIndicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FirmLevelDemand_UpsertInternalDemandIndicationsMutation,
    FirmLevelDemand_UpsertInternalDemandIndicationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FirmLevelDemand_UpsertInternalDemandIndicationsMutation,
    FirmLevelDemand_UpsertInternalDemandIndicationsMutationVariables
  >(FirmLevelDemand_UpsertInternalDemandIndicationsDocument, options);
}
export type FirmLevelDemand_UpsertInternalDemandIndicationsMutationHookResult = ReturnType<
  typeof useFirmLevelDemand_UpsertInternalDemandIndicationsMutation
>;
export type FirmLevelDemand_UpsertInternalDemandIndicationsMutationResult =
  Apollo.MutationResult<FirmLevelDemand_UpsertInternalDemandIndicationsMutation>;
export type FirmLevelDemand_UpsertInternalDemandIndicationsMutationOptions =
  Apollo.BaseMutationOptions<
    FirmLevelDemand_UpsertInternalDemandIndicationsMutation,
    FirmLevelDemand_UpsertInternalDemandIndicationsMutationVariables
  >;
export const FirmLevelDemand_PassInternalDemandOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FirmLevelDemand_PassInternalDemandOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passInternalDemandOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FirmLevelDemand_PassInternalDemandOfferingMutationFn = Apollo.MutationFunction<
  FirmLevelDemand_PassInternalDemandOfferingMutation,
  FirmLevelDemand_PassInternalDemandOfferingMutationVariables
>;

/**
 * __useFirmLevelDemand_PassInternalDemandOfferingMutation__
 *
 * To run a mutation, you first call `useFirmLevelDemand_PassInternalDemandOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_PassInternalDemandOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firmLevelDemandPassInternalDemandOfferingMutation, { data, loading, error }] = useFirmLevelDemand_PassInternalDemandOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_PassInternalDemandOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FirmLevelDemand_PassInternalDemandOfferingMutation,
    FirmLevelDemand_PassInternalDemandOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FirmLevelDemand_PassInternalDemandOfferingMutation,
    FirmLevelDemand_PassInternalDemandOfferingMutationVariables
  >(FirmLevelDemand_PassInternalDemandOfferingDocument, options);
}
export type FirmLevelDemand_PassInternalDemandOfferingMutationHookResult = ReturnType<
  typeof useFirmLevelDemand_PassInternalDemandOfferingMutation
>;
export type FirmLevelDemand_PassInternalDemandOfferingMutationResult =
  Apollo.MutationResult<FirmLevelDemand_PassInternalDemandOfferingMutation>;
export type FirmLevelDemand_PassInternalDemandOfferingMutationOptions = Apollo.BaseMutationOptions<
  FirmLevelDemand_PassInternalDemandOfferingMutation,
  FirmLevelDemand_PassInternalDemandOfferingMutationVariables
>;
export const FirmLevelDemand_RegMInternalDemandOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FirmLevelDemand_RegMInternalDemandOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regMInternalDemandOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FirmLevelDemand_RegMInternalDemandOfferingMutationFn = Apollo.MutationFunction<
  FirmLevelDemand_RegMInternalDemandOfferingMutation,
  FirmLevelDemand_RegMInternalDemandOfferingMutationVariables
>;

/**
 * __useFirmLevelDemand_RegMInternalDemandOfferingMutation__
 *
 * To run a mutation, you first call `useFirmLevelDemand_RegMInternalDemandOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_RegMInternalDemandOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firmLevelDemandRegMInternalDemandOfferingMutation, { data, loading, error }] = useFirmLevelDemand_RegMInternalDemandOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_RegMInternalDemandOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FirmLevelDemand_RegMInternalDemandOfferingMutation,
    FirmLevelDemand_RegMInternalDemandOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FirmLevelDemand_RegMInternalDemandOfferingMutation,
    FirmLevelDemand_RegMInternalDemandOfferingMutationVariables
  >(FirmLevelDemand_RegMInternalDemandOfferingDocument, options);
}
export type FirmLevelDemand_RegMInternalDemandOfferingMutationHookResult = ReturnType<
  typeof useFirmLevelDemand_RegMInternalDemandOfferingMutation
>;
export type FirmLevelDemand_RegMInternalDemandOfferingMutationResult =
  Apollo.MutationResult<FirmLevelDemand_RegMInternalDemandOfferingMutation>;
export type FirmLevelDemand_RegMInternalDemandOfferingMutationOptions = Apollo.BaseMutationOptions<
  FirmLevelDemand_RegMInternalDemandOfferingMutation,
  FirmLevelDemand_RegMInternalDemandOfferingMutationVariables
>;
export const FirmLevelDemand_ActivateInternalDemandOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FirmLevelDemand_ActivateInternalDemandOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateInternalDemandOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationLastModifiedParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FirmLevelDemand_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FirmLevelDemand_ActivateInternalDemandOfferingMutationFn = Apollo.MutationFunction<
  FirmLevelDemand_ActivateInternalDemandOfferingMutation,
  FirmLevelDemand_ActivateInternalDemandOfferingMutationVariables
>;

/**
 * __useFirmLevelDemand_ActivateInternalDemandOfferingMutation__
 *
 * To run a mutation, you first call `useFirmLevelDemand_ActivateInternalDemandOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_ActivateInternalDemandOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firmLevelDemandActivateInternalDemandOfferingMutation, { data, loading, error }] = useFirmLevelDemand_ActivateInternalDemandOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_ActivateInternalDemandOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FirmLevelDemand_ActivateInternalDemandOfferingMutation,
    FirmLevelDemand_ActivateInternalDemandOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FirmLevelDemand_ActivateInternalDemandOfferingMutation,
    FirmLevelDemand_ActivateInternalDemandOfferingMutationVariables
  >(FirmLevelDemand_ActivateInternalDemandOfferingDocument, options);
}
export type FirmLevelDemand_ActivateInternalDemandOfferingMutationHookResult = ReturnType<
  typeof useFirmLevelDemand_ActivateInternalDemandOfferingMutation
>;
export type FirmLevelDemand_ActivateInternalDemandOfferingMutationResult =
  Apollo.MutationResult<FirmLevelDemand_ActivateInternalDemandOfferingMutation>;
export type FirmLevelDemand_ActivateInternalDemandOfferingMutationOptions =
  Apollo.BaseMutationOptions<
    FirmLevelDemand_ActivateInternalDemandOfferingMutation,
    FirmLevelDemand_ActivateInternalDemandOfferingMutationVariables
  >;
export const FirmLevelDemand_PublishedOfferingTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FirmLevelDemand_PublishedOfferingType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limitedPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'offeringId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFirmLevelDemand_PublishedOfferingTypeQuery__
 *
 * To run a query within a React component, call `useFirmLevelDemand_PublishedOfferingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmLevelDemand_PublishedOfferingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmLevelDemand_PublishedOfferingTypeQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFirmLevelDemand_PublishedOfferingTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FirmLevelDemand_PublishedOfferingTypeQuery,
    FirmLevelDemand_PublishedOfferingTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FirmLevelDemand_PublishedOfferingTypeQuery,
    FirmLevelDemand_PublishedOfferingTypeQueryVariables
  >(FirmLevelDemand_PublishedOfferingTypeDocument, options);
}
export function useFirmLevelDemand_PublishedOfferingTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmLevelDemand_PublishedOfferingTypeQuery,
    FirmLevelDemand_PublishedOfferingTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FirmLevelDemand_PublishedOfferingTypeQuery,
    FirmLevelDemand_PublishedOfferingTypeQueryVariables
  >(FirmLevelDemand_PublishedOfferingTypeDocument, options);
}
export type FirmLevelDemand_PublishedOfferingTypeQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_PublishedOfferingTypeQuery
>;
export type FirmLevelDemand_PublishedOfferingTypeLazyQueryHookResult = ReturnType<
  typeof useFirmLevelDemand_PublishedOfferingTypeLazyQuery
>;
export type FirmLevelDemand_PublishedOfferingTypeQueryResult = Apollo.QueryResult<
  FirmLevelDemand_PublishedOfferingTypeQuery,
  FirmLevelDemand_PublishedOfferingTypeQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeFirmLevelDemand_InternalIndicationsDemandMockResponse = makeMockResponse<
  FirmLevelDemand_InternalIndicationsDemandQueryVariables,
  FirmLevelDemand_InternalIndicationsDemandQuery
>(FirmLevelDemand_InternalIndicationsDemandDocument);

export const makeFirmLevelDemand_InternalAllocationDemandMockResponse = makeMockResponse<
  FirmLevelDemand_InternalAllocationDemandQueryVariables,
  FirmLevelDemand_InternalAllocationDemandQuery
>(FirmLevelDemand_InternalAllocationDemandDocument);

export const makeFirmLevelDemand_PublishedOfferingTypeMockResponse = makeMockResponse<
  FirmLevelDemand_PublishedOfferingTypeQueryVariables,
  FirmLevelDemand_PublishedOfferingTypeQuery
>(FirmLevelDemand_PublishedOfferingTypeDocument);
