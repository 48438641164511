import { useAuth } from '@cmg/auth';
import { PrimaryButton } from '@cmg/common';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useCheckInstitutionalIndicationAccess } from '../../../../../common/util/check-access/useCheckInstitutionalIndicationAccess';
import {
  canBillingAndDeliveryAgentUpdateInstitutionalIndication,
  findEntitlements,
} from '../../../../../common/util/offering/syndicate-entitlements';
import { AllocationAcknowledgement } from '../../../../../graphql';
import { canAcknowledgeAllocation } from '../../../../offering-side-panel/order-book/indication-activity/components/allocation/Allocation.model';
import { AllocationAcknowledgementStatus } from '../../../../offering-side-panel/order-book/indication-activity/components/allocation/components/allocation-acknowledgement-status/AllocationAcknowledgementStatus';
import { isIndicationStatusAndTypeValid } from '../../../../order-book/utils';
import { MyOrdersGridCellProps } from '../MyOrdersGrid.model';
import { openAllocationAcknowledgementModal } from './AllocationAcknowledgementModal';

/**
 * Renders the Allocation AcknowledgementCell Cell within the My Orders Grid.
 */
const AllocationAcknowledgementCell: React.FC<MyOrdersGridCellProps> = ({ data, context }) => {
  const dispatch = useDispatch();
  const { oidcUserCmgEntityKey } = useAuth();

  const { offeringId, billingAndDeliveryAgentCmgEntityKey } = data;
  const { offerings } = context;

  const syndicatesEntitlements = offerings[offeringId].syndicateEntitlements;

  const myFirmOfferingEntitlements = findEntitlements({
    cmgEntityKey: oidcUserCmgEntityKey,
    syndicatesEntitlements,
  });

  const { canUpdate: canUserFirmUpdateInstitutionalIndication } =
    useCheckInstitutionalIndicationAccess({
      entitlements: myFirmOfferingEntitlements,
    });

  const canAcknowledge = canAcknowledgeAllocation(
    canBillingAndDeliveryAgentUpdateInstitutionalIndication({
      billingAndDeliveryAgentCmgEntityKey,
      syndicatesEntitlements,
    }),
    canUserFirmUpdateInstitutionalIndication,
    oidcUserCmgEntityKey,
    data.billingAndDeliveryAgentCmgEntityKey
  );

  const updatedInvestorReplyStatus =
    data.investorReplyStatus ?? AllocationAcknowledgement.Unacknowledged;

  if (!isIndicationStatusAndTypeValid(data.status, data.type) || data.allocationShares === null) {
    return <div>-</div>;
  }

  if (
    canAcknowledge &&
    data.allocationShares !== null &&
    updatedInvestorReplyStatus === AllocationAcknowledgement.Unacknowledged
  ) {
    return (
      <PrimaryButton
        onClick={() =>
          dispatch(
            openAllocationAcknowledgementModal({
              data,
              canUpdateAllocation: canAcknowledge,
            })
          )
        }
      >
        Ack. Allocation
      </PrimaryButton>
    );
  }

  return (
    <AllocationAcknowledgementStatus
      isReleased
      investorReplyStatus={updatedInvestorReplyStatus}
      indicationStatus={data.status}
      indicationType={data.type}
    />
  );
};

export default AllocationAcknowledgementCell;
