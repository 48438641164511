import { DropdownButton, Menu, MenuItem } from '@cmg/design-system';
import React, { useRef, useState } from 'react';

type CalculateDropdownProps = {
  handleClickCalculateFeesDistribution: () => void;
  handleClickCalculateSharesDistribution: () => void;
};

export function CalculateDropdown({
  handleClickCalculateFeesDistribution,
  handleClickCalculateSharesDistribution,
}: CalculateDropdownProps) {
  const btnRef = useRef<null | HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  return (
    <React.Fragment>
      <DropdownButton
        variant="outlined"
        isExpanded={isOpened}
        ref={btnRef}
        onClick={() => setIsOpened(openState => !openState)}
      >
        Calculate
      </DropdownButton>
      <Menu open={isOpened} onClose={() => setIsOpened(false)} anchorEl={btnRef.current}>
        <MenuItem
          onClick={() => {
            handleClickCalculateFeesDistribution();
            setIsOpened(false);
          }}
        >
          Economics Breakdown
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClickCalculateSharesDistribution();
            setIsOpened(false);
          }}
        >
          Exercised Overallotment Shares Distribution
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
