import { UUID } from '@cmg/common';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import routeFactory from '../../../../../../common/util/routeFactory';
import InvestorSearch from '../../../../../order-book/components/InvestorSearch';
import { Investor } from '../../../../../order-book/components/InvestorSearch/InvestorSearch';
import { SSearchWrapper } from '../../../../../trade-release/history/TradeReleaseHistory.styles';
import { SalesCreditsInvestor } from '../../SalesCreditsReleaseHistoryRoute';
import { useSalesCredits_InvestorHistoryQuery } from '../graphql';
import { SalesCreditsHistoryTable } from '../table/SalesCreditsHistoryTable';
import SalesCreditsBankInvestorKeyPanel from './bank-investor-key-panel/SalesCreditsBankInvestorKeyPanel';

export type Props = {
  offeringId: UUID;
  investorFirmCmgEntityKey: UUID;
  investors: SalesCreditsInvestor[];
};

export const SalesCreditsHistoryContent: React.VFC<Props> = ({
  offeringId,
  investorFirmCmgEntityKey,
  investors,
}) => {
  const history = useHistory();

  const { data, loading, error } = useSalesCredits_InvestorHistoryQuery({
    variables: { investorCmgEntityKey: investorFirmCmgEntityKey, offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const currentInvestor = React.useMemo(() => {
    return investors.find(({ cmgEntityKey }) => cmgEntityKey === investorFirmCmgEntityKey)!;
  }, [investors, investorFirmCmgEntityKey]);

  const investorOptions = React.useMemo(() => {
    return investors.map<Investor>(investor => ({
      key: investor.cmgEntityKey,
      name: investor.name,
    }));
  }, [investors]);

  const onInvestorChange = useCallback(
    (nextFirmCmgEntityKey: string | null) => {
      if (nextFirmCmgEntityKey) {
        history.push(
          routeFactory.salesCreditsHistory.getUrlPath({
            offeringId,
            investorFirmCmgEntityKey: nextFirmCmgEntityKey,
          })
        );
      }
    },
    [history, offeringId]
  );

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <SSearchWrapper>
        <InvestorSearch
          investors={investorOptions}
          onChange={onInvestorChange}
          currentKey={investorFirmCmgEntityKey}
        />
      </SSearchWrapper>

      <SalesCreditsBankInvestorKeyPanel investor={currentInvestor} offeringId={offeringId} />

      <Panel>
        <Panel.Header title="History" />
        <Panel.Content paddingSize="S">
          <SalesCreditsHistoryTable
            salesCreditsHistoryItems={data?.salesCreditsInvestorHistory ?? []}
          />
        </Panel.Content>
      </Panel>
    </div>
  );
};
