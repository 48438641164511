import { Alert, LinkButton } from '@cmg/common';
import React from 'react';

type Props = {
  onContactSupport: () => void;
  firmNames: string[];
};

export const MissingDistributionListBanner: React.FC<Props> = ({ onContactSupport, firmNames }) => {
  return (
    <Alert
      severity="warning"
      action={() => <LinkButton onClick={onContactSupport}>Contact Support</LinkButton>}
    >
      <Alert.Title>Email distribution lists are missing for some recipients:</Alert.Title>
      <Alert.Details>{firmNames.join(', ')}.</Alert.Details>
    </Alert>
  );
};
