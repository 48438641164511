import { AsyncAutocomplete, Button, ListItem, ListItemText, Stack } from '@cmg/design-system';
import React from 'react';

import ServerErrorsBanner from '../../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { InvestorContact } from '../InvestorContactInformationForm.model';
import { useInvestorContactSearch_InvestorFirmsEmployeesQuery } from './graphql/__generated__';
import { getContactsOptions, InvestorContactOption } from './InvestorContactSearch.model';
import { NoOptions } from './no-options/NoOptions';

export type Props = {
  handleAddContact: (item: InvestorContact) => void;
  bankInvestorKey: string | undefined;
  disabled?: boolean;
  isCoveredAccount: boolean;
};

const InvestorContactSearch: React.FC<Props> = ({
  handleAddContact,
  bankInvestorKey,
  disabled,
  isCoveredAccount,
}) => {
  const { data, error, loading } = useInvestorContactSearch_InvestorFirmsEmployeesQuery({
    variables: {
      firmKeyOrId: bankInvestorKey!,
      isCoveredAccount,
    },
    skip: !bankInvestorKey,
  });

  const investorFirmsEmployees =
    React.useMemo(() => {
      if (!data) {
        return [];
      }

      if ('investorFirmsEmployees' in data) {
        return data.investorFirmsEmployees;
      }

      if ('coveredInvestorFirmsEmployees' in data) {
        return data.coveredInvestorFirmsEmployees;
      }
    }, [data]) ?? [];

  const noOptionsComponent = React.useMemo(() => {
    return (
      <NoOptions
        handleCreateNew={() => handleAddContact({ name: '', email: '', isCustom: true })}
      />
    );
  }, [handleAddContact]);

  return (
    <Stack>
      {error && <ServerErrorsBanner error={error} />}
      <AsyncAutocomplete<InvestorContactOption, false, false>
        onFetchOptions={() => {
          return Promise.resolve({
            data: getContactsOptions(investorFirmsEmployees),
          });
        }}
        TextFieldProps={{
          name: 'investorContact',
          label: 'Investor Contact',
          placeholder: 'Select a contact...',
        }}
        sx={{ width: '60%', marginBottom: '16px' }}
        disabled={disabled}
        isLoadingOptions={loading}
        value={null}
        footerButton={
          <Button
            variant="text"
            aria-label="create new footer"
            onClick={() => {
              handleAddContact({
                name: '',
                email: '',
                isCustom: true,
              });
            }}
          >
            Create New
          </Button>
        }
        onChange={(_, value) => {
          if (!value) {
            return;
          }

          const selectedValue = typeof value === 'string' ? value : value.value;
          const contact = investorFirmsEmployees.find(item => item.id === selectedValue);

          if (contact) {
            handleAddContact({
              name: contact.name,
              email: contact.email1 ?? contact.email2!, // at least one email is always defined
              isCustom: false,
            });
          }
        }}
        renderOption={(props, option, _, ownerState) => {
          return (
            <ListItem {...props} disablePadding>
              <ListItemText primary={ownerState.getOptionLabel(option)} secondary={option.email} />
            </ListItem>
          );
        }}
        noOptionsText={noOptionsComponent}
      />
    </Stack>
  );
};

export default InvestorContactSearch;
