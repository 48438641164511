import { Icon, Popover, SecondaryButton, UUID } from '@cmg/common';
import React from 'react';

import { TradeReleaseStatus } from '../../../../graphql';
import ReleaseSettings from '../../common/release-settings/ReleaseSettings';
import { TradeGridFilters } from '../hooks/useTradeGrid.model';
import { releaseStatusOptions } from './GridFilters.model';
import {
  SSearchIconWrapper,
  StyledErrorsCheckbox,
  StyledSelect,
  StyledTextInput,
  SWrapper,
} from './GridFilters.styles';

export type Props = {
  filters: TradeGridFilters;
  offeringId: UUID;
  onExportTrades: () => void;
};

export const GridFilters: React.FC<Props> = ({ filters, offeringId, onExportTrades }) => {
  const {
    releaseStatus,
    setReleaseStatus,
    containsErrors,
    setContainsErrors,
    investorName,
    setInvestorName,
  } = filters;

  return (
    <SWrapper>
      <StyledTextInput
        onChange={setInvestorName}
        value={investorName}
        placeholder="Search for Investor..."
        prefix={
          <SSearchIconWrapper>
            <Icon name="search" />
          </SSearchIconWrapper>
        }
        testId="trade-release-investor-name-filter"
      />
      <StyledSelect
        testId="trade-release-status-filter"
        placeholder="Select Trade Release Status..."
        options={releaseStatusOptions}
        value={releaseStatus}
        onChange={value => setReleaseStatus(value as TradeReleaseStatus | null)}
      />
      <StyledErrorsCheckbox
        testId="trade-release-errors-filter"
        value={containsErrors}
        onChange={setContainsErrors}
      >
        Contains Errors
      </StyledErrorsCheckbox>

      <Popover variant="TOOLTIP" placement="topRight" content="Export to CSV">
        <SecondaryButton
          testId="trade-release-export-csv"
          title="Export CSV"
          iconRight={{ name: 'file-export', variant: 'regular' }}
          onClick={onExportTrades}
        />
      </Popover>
      <ReleaseSettings offeringId={offeringId} />
    </SWrapper>
  );
};
