import { Typography } from '@cmg/design-system';
import { VFC } from 'react';

export const RecipientsInfoMessage: VFC = () => {
  return (
    <Typography>
      Upon receiving a successful response from FINRA, the filing details are emailed to you and the
      underwriters on the offering as selected below.
    </Typography>
  );
};
