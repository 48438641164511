import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import {
  RegulatoryFilings_RolodexCommonFilingQueryVariables,
  RegulatoryFilings_RolodexFilingRecipientPartsFragment,
  RegulatoryFilings_RolodexSyndicateManagerPartsFragment,
  useRegulatoryFilings_RolodexCommonFilingQuery,
} from '../graphql';

export type UseRolodexCommonFilingQueryArgs = RegulatoryFilings_RolodexCommonFilingQueryVariables;

type UseRolodexCommonFilingQueryType = ReturnType<
  typeof useRegulatoryFilings_RolodexCommonFilingQuery
>['data'] & {
  recipients: ReadonlyArray<RegulatoryFilings_RolodexFilingRecipientPartsFragment>;
  offering: {
    id: string;
    regulatoryFilings: {
      recipients: ReadonlyArray<RegulatoryFilings_RolodexFilingRecipientPartsFragment>;
    };
    syndicate: {
      managers: ReadonlyArray<RegulatoryFilings_RolodexSyndicateManagerPartsFragment>;
    };
  };
};

type UseRolodexCommonFilingQueryReturnType = {
  loading: boolean;
  error: ApolloError | undefined;
  data: UseRolodexCommonFilingQueryType | undefined;
};

export function useRolodexCommonFilingQuery({
  offeringId,
  cmgEntityKey,
}: UseRolodexCommonFilingQueryArgs): UseRolodexCommonFilingQueryReturnType {
  const {
    data: rolodexCommonFilingQueryData,
    loading,
    error,
  } = useRegulatoryFilings_RolodexCommonFilingQuery({
    fetchPolicy: 'cache-and-network',
    variables: { offeringId, cmgEntityKey },
  });

  const recipients = useMemo(() => {
    const recipientsSource = rolodexCommonFilingQueryData?.offering.regulatoryFilings.recipients;

    if (!recipientsSource) {
      return [];
    }

    return recipientsSource.filter(recipient => recipient.cmgEntityKey !== cmgEntityKey);
  }, [rolodexCommonFilingQueryData?.offering.regulatoryFilings.recipients, cmgEntityKey]);

  const data = useMemo(() => {
    const underwriter = rolodexCommonFilingQueryData?.offering.syndicate.managers.find(
      m => m.cmgEntityKey === cmgEntityKey
    );

    return (
      rolodexCommonFilingQueryData && {
        ...rolodexCommonFilingQueryData,
        filingOfferingDetail: {
          ...rolodexCommonFilingQueryData.filingOfferingDetail,
          managers: [],
        },
        filingLastCommonValues: {
          ...rolodexCommonFilingQueryData.filingLastCommonValues,
          firmCrdNumber: underwriter?.finraInfo?.crdNumber?.toString(),
          firmMpid: underwriter?.finraInfo?.mpid,
        },
        underwriter: {
          ...rolodexCommonFilingQueryData.underwriter,
          crd: underwriter?.finraInfo?.crdNumber?.toString(),
          mpid: underwriter?.finraInfo?.mpid,
        },
        bankAffiliates: [],
        recipients,
      }
    );
  }, [rolodexCommonFilingQueryData, recipients, cmgEntityKey]);

  return { loading, error, data };
}
