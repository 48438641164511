export enum EventInfo {
  MEETING_CREATED = 'MEETING_CREATED',
  MEETING_UPDATED = 'MEETING_UPDATED',
  OPEN_TO_SALES_SUCCESSFULLY = 'OPEN_TO_SALES_SUCCESSFULLY',
  CONFIRMED_SUCCESSFULLY = 'CONFIRMED_SUCCESSFULLY',
  LOGISTICS_CREATED = 'LOGISTICS_CREATED',
  LOGISTICS_UPDATED = 'LOGISTICS_UPDATED',
}

export const eventInfoString = {
  [EventInfo.OPEN_TO_SALES_SUCCESSFULLY]: 'Meeting opened to Sales',
  [EventInfo.MEETING_CREATED]: 'Meeting Draft created',
  [EventInfo.MEETING_UPDATED]: 'Meeting Draft updated',
  [EventInfo.CONFIRMED_SUCCESSFULLY]: 'Meeting confirmed',
  [EventInfo.LOGISTICS_CREATED]: 'Logistics created',
  [EventInfo.LOGISTICS_UPDATED]: 'Logistics updated',
};
