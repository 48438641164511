import { Alert, AlertTitle } from '@cmg/design-system';
import React from 'react';

export type Props = {
  matchedVariables: string[];
  type: 'warning' | 'error';
};

const CustomVariablesAlert: React.FC<Props> = ({ matchedVariables, type }) => {
  const variablesString = matchedVariables.join(', ');
  if (type === 'warning') {
    return (
      <Alert severity="warning" aria-label="Data is missing for some of the variables">
        <AlertTitle>Data is missing for the following variables:</AlertTitle>
        {variablesString}
      </Alert>
    );
  } else {
    return (
      <Alert severity="error" aria-label="Invalid names for some of the variables">
        <AlertTitle>Invalid variable name:</AlertTitle>
        {variablesString}
      </Alert>
    );
  }
};

export default CustomVariablesAlert;
