import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';

export const SidePanelTitle: FC = ({ children }) => {
  const ref = useRef(document.getElementById('offering-side-panel-header'));
  if (!ref.current) {
    return null;
  }

  return createPortal(children, ref.current);
};
