import { Option, Select } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

export enum SpacFilterOption {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
  ONLY = 'ONLY',
}

const spacFilterLabels = {
  [SpacFilterOption.INCLUDE]: 'Include SPACs',
  [SpacFilterOption.EXCLUDE]: 'Exclude SPACs',
  [SpacFilterOption.ONLY]: 'SPACs Only',
};

export const spacFilterOptions = (
  Object.keys(SpacFilterOption).sort((a, b) => a.localeCompare(b)) as SpacFilterOption[]
).map<Option<SpacFilterOption>>(spacFilter => ({
  label: spacFilterLabels[spacFilter],
  value: spacFilter,
}));
export type Props = {
  hasNoOptions?: boolean;
  onChange: (value: SpacFilterOption | null) => void;
  value: SpacFilterOption;
};

export const SSpacFilter = styled.div`
  min-width: 220px;
`;

/**
 * SpacFilter is responsible for including, excluding, or displaying only SPACs on a calendar
 */
const SpacFilter: React.FC<Props> = ({ onChange, hasNoOptions, value }) => {
  return (
    <SSpacFilter>
      <Select<SpacFilterOption>
        options={hasNoOptions ? [] : spacFilterOptions}
        onChange={onChange}
        value={value}
        isClearable={false}
        placeholder="SPAC"
      />
    </SSpacFilter>
  );
};

export default SpacFilter;
