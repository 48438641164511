import React, { useContext } from 'react';

import { managerRoleLabels } from '../../../../../../types/domain/manager/constants';
import { ManagersOrder } from '../../../ManagersOrder.context';
import { DataByManager } from '../../hooks/EntitlementsDataByManagerContext';
import { SManager, SManagersColumn } from './ManagersSidebar.styles';

export type Props = {
  managers: DataByManager;
  selectedManager: string | null;
  onSelect: (cmgEntityKey: string | null) => void;
};

/**
 * ManagersSidebar displays a list of managers, when a manager is selected it will set the cmgEntityKey
 * as selected
 */
const ManagersSidebar: React.FC<Props> = ({ managers, selectedManager, onSelect }) => {
  const { managersOrder } = useContext(ManagersOrder);

  return (
    <SManagersColumn direction="column">
      {(managersOrder.length ? managersOrder : Object.keys(managers)).map(key => (
        <SManager key={key} onClick={() => onSelect(key)} selected={selectedManager === key}>
          <div>{managers[key].firmName}</div>
          <small>{managerRoleLabels[managers[key].role]}</small>
        </SManager>
      ))}
    </SManagersColumn>
  );
};

export default ManagersSidebar;
