import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  GreenShoeRecipientInputData,
  SyndicateWiresManagerRole,
  WireTemplateType,
} from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_GreenShoeWirePartsFragment,
  SyndicateWires_GreenShoeWireValidationPartsFragment,
  useSyndicateWires_GreenShoeWireDetailsQuery,
  useSyndicateWires_GreenShoeWirePreviewQuery,
  useSyndicateWires_GreenShoeWiresQuery,
  useSyndicateWires_GreenShoeWireValidationQuery,
} from './graphql';
import { GreenShoeWireForm } from './GreenShoeWireForm';
import { GreenShoeRecipientUserData, validationSchema, Values } from './GreenShoeWireForm.model';
import { useCreateGreenShoeWireMutation } from './hooks/useCreateGreenShoeWireMutation';
import { useDeleteGreenShoeWireMutation } from './hooks/useDeleteGreenShoeWireMutation';
import { useSendGreenShoeWireMutation } from './hooks/useSendGreenShoeWireMutation';
import { useUpdateGreenShoeWireMutation } from './hooks/useUpdateGreenShoeWireMutation';

const wireTypeName = 'Green Shoe';

const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role !== SyndicateWiresManagerRole.SellingGroupMember;
};

const getGreenShoeRecipientInputData = (formValues: Values) => {
  const { disclaimer, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<GreenShoeRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        disclaimer,
      };
    });
};

export const wireTypeConfigGreenShoe = createWireTypeConfig<
  Values,
  SyndicateWires_GreenShoeWirePartsFragment,
  SyndicateWires_GreenShoeWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresGreenShoe
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresGreenShoe,
  wireTemplateType: WireTemplateType.GreenShoe,
  CreateOrUpdateForm: GreenShoeWireForm,
  formValidation: validationSchema,
  createOrUpdateModalSize: 'sm',
  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_GreenShoeWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.greenShoeWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_GreenShoeWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.greenShoeWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_GreenShoeWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.greenShoeWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_GreenShoeWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return { data: data?.greenShoeWireValidation, isLoading: loading, error, refetch };
  },
  useGetInitialValues: ({
    managers,
    wire,
    offeringType,
    offeringId,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0].disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const recipientsUserData = managers.map<GreenShoeRecipientUserData | null>(manager => {
        return { recipient: manager.cmgEntityKey };
      });

      return {
        disclaimer,
        recipientsUserData,
      };
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
    ]);

    return { isLoading: disclaimerLoading, data, error: disclaimerError };
  },
  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateGreenShoeWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getGreenShoeRecipientInputData(values),
            },
          },
        });

        if (result.data?.createGreenShoeWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createGreenShoeWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateGreenShoeWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getGreenShoeRecipientInputData(values),
            },
          },
        });

        if (result.data?.updateGreenShoeWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateGreenShoeWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteGreenShoeWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteGreenShoeWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendGreenShoeWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendGreenShoeWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendGreenShoeWire;
      },
      isLoading: loading,
    };
  },
});
