import { Option } from '@cmg/common';

import { ManagerRole } from '../../../graphql';

export enum JuniorRole {
  JUNIOR_ACTIVE_BOOKRUNNER = 'JUNIOR_ACTIVE_BOOKRUNNER',
}

export const juniorRoleLabels: { [juniorRole in JuniorRole]: string } = {
  [JuniorRole.JUNIOR_ACTIVE_BOOKRUNNER]: 'Junior Active Bookrunner',
};

export const managerRoleLabels: { [managerRole in ManagerRole]: string } = {
  [ManagerRole.ActiveBookrunner]: 'Active Bookrunner',
  [ManagerRole.Bookrunner]: 'Bookrunner',
  [ManagerRole.CoLead]: 'Co-Lead',
  [ManagerRole.CoManager]: 'Co-Manager',
  [ManagerRole.GlobalCoordinator]: 'Global Coordinator',
  [ManagerRole.SellingGroupMember]: 'Selling Group Member',
  [ManagerRole.Underwriter]: 'Underwriter',
};

export const juniorRoleLabelsMappedToManagerRoles = {
  [ManagerRole.ActiveBookrunner]: juniorRoleLabels.JUNIOR_ACTIVE_BOOKRUNNER,
};

export const managerRoleLabelForAuthor = {
  [ManagerRole.ActiveBookrunner]: 'Active Bookrunner',
  [ManagerRole.GlobalCoordinator]: 'Global Coordinator',
};

const allRoleLabels = { ...juniorRoleLabels, ...managerRoleLabels };

const unsortedManagerRoleOptions = (hasCreateInternationalTrait: boolean) =>
  Object.keys(allRoleLabels)
    .filter(item => (hasCreateInternationalTrait ? true : item !== ManagerRole.GlobalCoordinator))
    .map<Option<ManagerRole & JuniorRole>>(type => ({
      label: allRoleLabels[type],
      value: type as ManagerRole & JuniorRole,
    }));

export const managerRoleOptions = (hasCreateInternationalTrait: boolean = false) =>
  unsortedManagerRoleOptions(hasCreateInternationalTrait).sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

export const managerRoleOptionsForAuthor = Object.keys(managerRoleLabelForAuthor)
  .map(type => ({
    label: allRoleLabels[type],
    value: type,
  }))
  .sort((a, b) => {
    return a?.label?.localeCompare(b?.label);
  });
