import { Popover } from '@cmg/common';
import React from 'react';

import {
  SColumn,
  SDeleteColumn,
  SIndicationLevelsHeader,
  SLimitConfigurationColumn,
  SRequired,
  StyledInfoIcon,
} from './InterestLevelsTableHeader.styles';

export type Props = { displayLimitConfig: boolean };

const InterestLevelsTableHeader: React.FC<Props> = ({ displayLimitConfig }) => {
  return (
    <SIndicationLevelsHeader>
      <SColumn align="left">
        <SRequired showIndicator>Demand Quantity</SRequired>
      </SColumn>
      <SColumn align="left">
        <SRequired showIndicator>Demand Unit</SRequired>
      </SColumn>
      <SColumn align="left">
        <SRequired showIndicator>Order Type</SRequired>
      </SColumn>
      <SLimitConfigurationColumn align="left">
        {displayLimitConfig && (
          <SRequired showIndicator>
            Limit Price
            <Popover
              variant="TOOLTIP"
              trigger="hover"
              content={<span>Limit Price expressed in pricing currency</span>}
            >
              <span>
                <StyledInfoIcon name="info-circle" variant="solid" size="1x" />
              </span>
            </Popover>
          </SRequired>
        )}
      </SLimitConfigurationColumn>
      <SDeleteColumn align="right" />
    </SIndicationLevelsHeader>
  );
};

export default InterestLevelsTableHeader;
