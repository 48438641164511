import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
  useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
} from '../graphql';

export type SendNonSyndicateSellingGroupInvitationWireMutation =
  SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation;

export const useSendNonSyndicateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
