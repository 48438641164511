import styled, { css } from 'styled-components/macro';

/**
 * @deprecated Use FlexContainer from @cmg/common instead
 */
const FlexLayout = styled.div<{ direction?: 'column' | 'row'; expand?: boolean }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  height: 100%;

  ${({ expand }) =>
    expand &&
    css`
      flex: 1;
    `}
`;

export default FlexLayout;
