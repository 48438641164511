import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../../../../common/util/routeFactory';
import { Roadshows_DealTeamListQuery } from '../../graphql';

export type Props = {
  value: string;
  data: Roadshows_DealTeamListQuery['dealTeams']['data'][number];
};

const DealTeamNameCellRenderer: React.FC<Props> = ({ value, data }) => {
  return (
    <Link to={routeFactory.roadshowsDealTeamDetails.getUrlPath({ dealTeamId: data.id })}>
      {value}
    </Link>
  );
};

export default DealTeamNameCellRenderer;
