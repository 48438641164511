import { useCheckAccountType } from '@cmg/auth';
import { timeUtil, useDebouncedCalendarRefreshEvent } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import { SCalendarContainer } from '../Calendar.styles';
import CalendarGrid, { GroupHeader } from '../components/calendar-grid/CalendarGrid';
import { CalendarGridOrderField } from '../components/calendar-grid/CalendarGridColumns';
import { useBuySideColumns } from './BuySideColumns';
import { useGetFiledCalendarOfferingsQuery } from './hooks/useGetFiledCalendarOfferingsQuery';
import { useSellSideColumns } from './SellSideColumns';

export function groupHeaderRenderer(groupValue: string) {
  return (
    <GroupHeader>
      Filing Date:{' '}
      <span>{groupValue ? timeUtil.formatAsDisplayDayAndDate(groupValue) : 'TBD'}</span>
    </GroupHeader>
  );
}

type RouteProps = RouteComponentProps;

export type Props = RouteProps;

const FiledCalendarRoute: React.FC<Props> = () => {
  const { data, error, loading, refetch } = useGetFiledCalendarOfferingsQuery();
  const { lastUpdatedAt } = useDebouncedCalendarRefreshEvent({
    callback: React.useCallback(() => {
      refetch();
    }, [refetch]),
  });

  const buySideColumns = useBuySideColumns();
  const sellSideColumns = useSellSideColumns();

  const isSellSide = useCheckAccountType('SELL_SIDE');

  const gridColumns = React.useMemo(
    () => (isSellSide ? sellSideColumns : buySideColumns),
    // intentionally disabling the eslint check
    // sellSideColumns and buySideColumns does not return stable reference and it's causing blinking of the grid while performing fetchMore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSellSide]
  );

  return (
    <SCalendarContainer data-test-id={xcSelectors.calendarScreen.testId}>
      {error && <ServerErrorsBanner error={error} />}
      <CalendarGrid
        columns={gridColumns}
        publishedOfferings={data?.filedPublishedOfferings.data}
        loading={loading}
        groupByField={CalendarGridOrderField.PUBLIC_FILING_DATE}
        lastUpdatedAt={lastUpdatedAt}
        groupHeaderRenderer={groupHeaderRenderer}
      />
    </SCalendarContainer>
  );
};

export default FiledCalendarRoute;
