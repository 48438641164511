import { FlexContainer, PrimaryButton, SecondaryButton, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { useCheckRegulatoryFilingsAccess } from '../../../../common/util/check-access/useCheckRegulatoryFilingsAccess';
import { FilingStatus } from '../../../../graphql';
import { SHeader, STitle, STitleWrapper } from './RegulatoryFilingsHeader.styles';

export type Props = {
  offeringId: UUID;
  title: string;
  isLoading?: boolean;
  filingStatus: FilingStatus | undefined;
  hideEmailNotificationAction: boolean;
  onAmendFiling: () => void;
  onResubmitFiling: () => void;
  onManageEmailNotifications: () => void;
};

const RegulatoryFilingsDetailHeader: React.FC<Props> = ({
  title,
  offeringId,
  filingStatus,
  isLoading = false,
  hideEmailNotificationAction,
  onAmendFiling,
  onResubmitFiling,
  onManageEmailNotifications,
}) => {
  const { canManage } = useCheckRegulatoryFilingsAccess(offeringId);

  return (
    <SHeader>
      <STitleWrapper>
        <STitle>{title}</STitle>
      </STitleWrapper>
      <FlexContainer direction="row" gap={10}>
        {canManage && (
          <SecondaryButton
            testId={xcSelectors.regulatoryFilingsAmendButton.testId}
            loading={isLoading}
            onClick={onAmendFiling}
          >
            Amend Filing
          </SecondaryButton>
        )}
        {canManage && filingStatus === FilingStatus.SubmissionError && (
          <PrimaryButton
            testId="Regulatory Filings/Header/Resubmit Filing Button"
            loading={isLoading}
            onClick={onResubmitFiling}
          >
            Resubmit Filing
          </PrimaryButton>
        )}
        {canManage && filingStatus === FilingStatus.Submitted && !hideEmailNotificationAction && (
          <PrimaryButton
            testId="Regulatory Filings/Header/Email Notification Button"
            loading={isLoading}
            onClick={onManageEmailNotifications}
          >
            Email Notification
          </PrimaryButton>
        )}
      </FlexContainer>
    </SHeader>
  );
};

export default RegulatoryFilingsDetailHeader;
