import { TextAreaField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SActions = styled.div`
  margin-left: auto;
  flex: 0;
  margin-top: 5px;
`;

export const StyledTextAreaField = styled(TextAreaField)`
  label,
  textarea {
    color: ${({ theme }) => theme.text.color.darkGray};
  }
`;
