import styled from 'styled-components/macro';

export const SMeetingHeader = styled.div`
  display: flex;
  margin: 16px 64px 0 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 32px;

  & > * {
    margin: 0 4px;
  }
`;

export const SFormTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.large};
  color: ${({ theme }) => theme.text.color.darkGray};
  flex-grow: 1;
  margin-left: 16px;
`;
