import routeFactory from '../../../common/util/routeFactory';
import useCertificateFilters from './useCertificateFilters/useCertificateFilters';

export enum CertificateRouteName {
  CurrentCertificates = 'current-certificates',
  PastCertificates = 'past-certificates',
  Library = 'library',
  None = 'none',
}

interface CertificateKeys {
  cmgEntityKey?: string;
  crmInvestorId?: string;
}

const useRouteWithSearchParams = (target: CertificateRouteName, keys: CertificateKeys = {}) => {
  const { certificateLibrary, search, certificateStatuses, expirationDays } =
    useCertificateFilters();
  const filters = {
    certificateLibrary,
    search,
    certificateStatuses: certificateStatuses?.join(',') ?? null,
    expirationDays,
  };

  const searchParams = new URLSearchParams();

  // Set filters
  Object.keys(filters).forEach(key => {
    if (!filters[key]) return;

    searchParams.set(key, filters[key]);
  });

  switch (target) {
    case CertificateRouteName.Library:
      return routeFactory.certificateLibrary.getUrlPath({ searchParams });
    case CertificateRouteName.CurrentCertificates:
      Object.keys(keys).forEach(key => {
        if (!keys[key]) return;

        searchParams.set(key, keys[key]);
      });

      return routeFactory.certificateLibraryFirmCurrentCertificates.getUrlPath({
        searchParams,
      });
    case CertificateRouteName.PastCertificates:
      Object.keys(keys).forEach(key => {
        if (!keys[key]) return;

        searchParams.set(key, keys[key]);
      });

      return routeFactory.certificateLibraryFirmPastCertificates.getUrlPath({
        searchParams,
      });
  }
};

export default useRouteWithSearchParams;
