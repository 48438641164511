import { DateTimeValue } from '@cmg/common';
import * as Yup from 'yup';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import {
  CreateRpnFilingInput,
  MemberFirmType,
  OfferingType,
  RpnBasisCodeType,
  RpnRestrictedPeriodType,
  TransactionType,
} from '../../../../../graphql';
import {
  CommonFields,
  commonFilingSchemaShape,
  createInitialCommonFilingValues,
  dateTimeSchema,
  FinraMemberFirmForm,
  FinraNonMemberFirm,
  firmCrdSchema,
  firmMpidNullableSchema,
  getOtherBasisDescription,
  getValueOrNull,
  InitialFilingExtendedValuesParameters,
} from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { getRestrictionStartDateTime } from '../../../common/form-utils';
import { affiliateToRPNFinraMemberAndNonMembers } from '../../../common/offering-affiliates-utils';
import { AffiliatesAndDistributionParticipants } from '../../../common/regulatory-filings.types';
import {
  RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment,
  RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment,
  RegulatoryFilings_RolodexUnderwriterFirmPartsFragment,
} from '../../../graphql';
import { RegulatoryFilings_RpnFilingPartsFragment } from '../graphql';

/**
 * Types
 */

export type RPNFilingFinraMemberFirm = FinraMemberFirmForm & {
  excusedOrPassive: MemberFirmType;
};

export type RPNFilingFormValues = CommonFields & {
  symbolsOfCoveredSecurities: readonly string[];
  restrictedPeriod: RpnRestrictedPeriodType;
  uarDate: string;
  adtvNumber: number | null | undefined;
  adtvSource: string;
  publicFloatValue: number | null | undefined;
  publicFloatValueSource: string;
  otherBasisDescription: string;
  basisCode: RpnBasisCodeType;
  isConcurrentConvertible: string;
  transactionType: TransactionType | null;
  transactionTypeDescription: string;
  restrictionStartTimeField: DateTimeValue | null;
  anticipatedPricingDate: string;
  finraMemberFirms: RPNFilingFinraMemberFirm[];
  nonMemberFirms: FinraNonMemberFirm[];
};

/**
 * Form Schema
 */
export const FINRAMemberFirmSchema = Yup.object().shape({
  firmCrdNumber: firmCrdSchema.label('Member Firm CRD #').required('Member Firm CRD # is required'),
  firmName: Yup.string().label('Firm Name').max(1000).required('Firm Name is required'),
  firmMpid: firmMpidNullableSchema.label('Firm MPID'),
  excusedOrPassive: Yup.string()
    .label('Excused Or Passive')
    .oneOf(Object.values(MemberFirmType))
    .required('Excused Or Passive is required'),
});

export const formSchema = Yup.object().shape({
  ...commonFilingSchemaShape,
  symbolsOfCoveredSecurities: Yup.array()
    .label('Symbols of covered securities')
    .max(10)
    .of(Yup.string().label('Symbol of covered securities').required().max(6)),
  restrictedPeriod: Yup.string().required().label('Restricted Period'),
  basisCode: Yup.string().required().label('Basis Code'),

  uarDate: Yup.string()
    .label('Underwriter Activity Report Date')
    .nullable()
    .when('basisCode', {
      is: RpnBasisCodeType.Uar,
      then: schema => schema.nullable().required(),
    }),
  adtvNumber: Yup.number()
    .positive()
    .label('ADTV Number')
    .nullable()
    .when('basisCode', {
      is: RpnBasisCodeType.AdtvPfv,
      then: schema => schema.nullable().required(),
    }),
  adtvSource: Yup.string()
    .label('ADTV Source')
    .when('basisCode', {
      is: RpnBasisCodeType.AdtvPfv,
      then: schema => schema.max(250).required(),
    }),
  publicFloatValue: Yup.number()
    .positive()
    .label('Public Float Value')
    .nullable()
    .when('basisCode', {
      is: RpnBasisCodeType.AdtvPfv,
      then: schema => schema.nullable().required(),
    }),
  publicFloatValueSource: Yup.string()
    .label('Public Float Value Source')
    .when('basisCode', {
      is: RpnBasisCodeType.AdtvPfv,
      then: schema => schema.max(250).required(),
    }),
  otherBasisDescription: Yup.string().when('basisCode', {
    is: RpnBasisCodeType.Other,
    then: schema => schema.max(400).label('Basis Code Description').required(),
  }),

  isConcurrentConvertible: Yup.string().label('Concurrent Convertible').required(),
  transactionType: Yup.string().label('Transaction Type').nullable().required(),

  transactionTypeDescription: Yup.string().when('transactionType', {
    is: TransactionType.Other,
    then: schema => schema.label('Transaction Type Description').max(400).required(),
  }),

  restrictionStartTimeField: dateTimeSchema.label('Restriction Start Time').required(),
  anticipatedPricingDate: Yup.string().label('Anticipated Pricing Date').nullable().required(),

  finraMemberFirms: Yup.array().of(FINRAMemberFirmSchema).required(),
  nonMemberFirms: Yup.array()
    .of(
      Yup.object().shape({
        firmName: Yup.string().max(1000).required(),
      })
    )
    .required(),
});

/**
 * Filing => Form
 */
export const mapFilingToFormValues = (
  filing: RegulatoryFilings_RpnFilingPartsFragment,
  affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants,
  underwriter?: RegulatoryFilings_RolodexUnderwriterFirmPartsFragment
): RPNFilingFormValues => {
  const { finraNonMemberFirms, finraMemberFirms } = affiliateToRPNFinraMemberAndNonMembers(
    affiliatesAndDistributionParticipants
  );

  return {
    basisCode: filing.basisCode,
    anticipatedPricingDate: filing.anticipatedPricingDate,
    isConcurrentConvertible: filing.isConcurrentConvertible ? 'true' : 'false',
    contactName: filing.contactName,
    contactTitle: filing.contactTitle,
    emailAddress: filing.emailAddress,
    firmCrdNumber: underwriter?.crd ?? '',
    firmMpid: underwriter?.mpid ?? '',
    firmName: filing.firmName,
    finraMemberFirms: finraMemberFirms,
    nonMemberFirms: finraNonMemberFirms,
    restrictedPeriod: filing.restrictedPeriod,
    restrictionStartTimeField: {
      date: filing.restrictionStartTime,
      timezone: filing.restrictionStartTimezone,
    },
    symbolsOfCoveredSecurities: filing.symbolsOfCoveredSecurities,
    telephoneNumber: filing.telephoneNumber,
    transactionType: filing.transactionType,
    adtvNumber: filing.adtvNumber,
    adtvSource: filing.adtvSource ?? '',
    otherBasisDescription: filing.otherBasisDescription ?? '',
    publicFloatValue: filing.publicFloatValue,
    publicFloatValueSource: filing.publicFloatValueSource ?? '',
    transactionTypeDescription: filing.transactionTypeDescription ?? '',
    uarDate: filing.uarDate ?? '',
    issuerSymbol: filing.issuerSymbol,
    issuerName: filing.issuerName,
  };
};

/**
 * Form => Filing
 */

export const mapFormValuesToFiling = (
  values: RPNFilingFormValues,
  recipients: string[],
  shouldAddSenderToBcc: boolean
): CreateRpnFilingInput => ({
  recipients,
  filingFormData: {
    basisCode: values.basisCode,
    anticipatedPricingDate: values.anticipatedPricingDate,
    isConcurrentConvertible: values.isConcurrentConvertible === 'true',
    contactName: values.contactName,
    contactTitle: values.contactTitle,
    emailAddress: values.emailAddress,
    firmCrdNumber: values.firmCrdNumber,
    firmMpid: values.firmMpid,
    firmName: values.firmName,
    finraMemberFirms: values.finraMemberFirms.map(
      ({ formIndex, ownerCmgEntityKey, ...formData }) => ({
        ...formData,
        firmMpid: getValueOrNull(formData.firmMpid),
      })
    ),
    nonMemberFirms: values.nonMemberFirms,
    restrictedPeriod: values.restrictedPeriod,
    restrictionStartTime: values.restrictionStartTimeField?.date!,
    restrictionStartTimezone: values.restrictionStartTimeField?.timezone!,
    symbolsOfCoveredSecurities: values.symbolsOfCoveredSecurities,
    telephoneNumber: values.telephoneNumber,
    transactionType: values.transactionType!,
    adtvNumber: values.basisCode === RpnBasisCodeType.AdtvPfv ? values.adtvNumber : null,
    adtvSource: values.basisCode === RpnBasisCodeType.AdtvPfv ? values.adtvSource : null,
    publicFloatValue:
      values.basisCode === RpnBasisCodeType.AdtvPfv ? values.publicFloatValue : null,
    publicFloatValueSource:
      values.basisCode === RpnBasisCodeType.AdtvPfv ? values.publicFloatValueSource : null,
    otherBasisDescription:
      values.basisCode === RpnBasisCodeType.Other ? values.otherBasisDescription : null,
    transactionTypeDescription:
      values.transactionType === TransactionType.Other ? values.transactionTypeDescription : null,
    uarDate: values.basisCode === RpnBasisCodeType.Uar ? values.uarDate : null,
    issuerSymbol: values.issuerSymbol!,
  },
  submissionTimeZone: generatePayloadDateTimeData().sendDateTimeZone,
  shouldAddSenderToBcc,
});

/**
 * Initial values
 */

const createDefaultValues = ({
  offeringType,
  underwriter,
  lastCommonValues,
  offeringDetail,
  affiliatesAndDistributionParticipants,
}: {
  offeringType?: OfferingType;
  underwriter?: RegulatoryFilings_RolodexUnderwriterFirmPartsFragment;
  lastCommonValues?: RegulatoryFilings_RolodexFilingLastCommonValuesPartsFragment | null;
  offeringDetail?: RegulatoryFilings_RolodexFilingOfferingDetailPartsFragment;
  affiliatesAndDistributionParticipants: AffiliatesAndDistributionParticipants;
}): RPNFilingFormValues => {
  const { finraMemberFirms, finraNonMemberFirms } = affiliateToRPNFinraMemberAndNonMembers(
    affiliatesAndDistributionParticipants
  );

  return {
    ...createInitialCommonFilingValues({ underwriter, lastCommonValues, offeringDetail }),
    otherBasisDescription: getOtherBasisDescription(offeringType),
    finraMemberFirms,
    nonMemberFirms: finraNonMemberFirms,
    symbolsOfCoveredSecurities: [underwriter?.ticker || ''],
    uarDate: '',
    adtvNumber: null,
    adtvSource: '',
    publicFloatValue: null,
    publicFloatValueSource: '',
    isConcurrentConvertible: 'false',
    transactionTypeDescription: '',
    anticipatedPricingDate: offeringDetail?.pricingDate!,
    restrictionStartTimeField: getRestrictionStartDateTime(
      offeringDetail?.pricingDate,
      RpnRestrictedPeriodType.FiveDays,
      'America/New_York'
    ),
    restrictedPeriod: RpnRestrictedPeriodType.FiveDays,
    basisCode: RpnBasisCodeType.Other,
    transactionType: null,
  };
};

export const createInitialValues = (
  {
    offeringType,
    underwriter,
    filing,
    lastCommonValues,
    offeringDetail,
    affiliatesAndDistributionParticipants,
  }: InitialFilingExtendedValuesParameters<RegulatoryFilings_RpnFilingPartsFragment> = {
    affiliatesAndDistributionParticipants: {
      distributionParticipants: [],
      affiliates: [],
    },
  }
): RPNFilingFormValues => {
  if (filing) {
    return mapFilingToFormValues(filing, affiliatesAndDistributionParticipants, underwriter);
  }

  return createDefaultValues({
    offeringType,
    underwriter,
    lastCommonValues,
    offeringDetail,
    affiliatesAndDistributionParticipants,
  });
};
