import { numericUtil, UUID } from '@cmg/common';
import {
  InputAdornment,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableSkeleton,
} from '@cmg/design-system';
import React, { VFC } from 'react';

import { OperationType } from '../../../common/components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import {
  NonSyndicateSellingGroupTermsRetentionRecipientUserData,
  Values,
} from '../../../wires/non-syndicate-selling-group-terms-retention/NonSyndicateSellingGroupTermsRetentionWireForm.model';
import { useBrokerDealersMyRetailQuery } from '../../hooks/useBrokerDealersMyRetailQuery';
import { LoadingErrorSection } from '../dialogs/create-or-update-wire-dialog/error-loading-recipients-data-section/LoadingErrorSection';
import { FormikNumericField } from '../form/formik-numeric-field/FormikNumericField';
import { WireFormBanner } from '../modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { DefaultRecipientValues } from '../recipients-user-data-form-field/RecipientsUserDataFormFields';
import { SyndicateRecipientsUserDataFormFields } from '../recipients-user-data-form-field/SyndicateRecipientsUserDataFormFields';
import BrokerDealerEconomicsTableHead from './BrokerDealerEconomicsTableHead';

export type Props = Readonly<{
  offeringId: UUID;
  managers: SyndicateWireManager[];
  operationType: OperationType;
}>;

const label = 'Broker Dealer Economics Table';

const defaultRecipientValues: DefaultRecipientValues<NonSyndicateSellingGroupTermsRetentionRecipientUserData> =
  {
    sellingConcession: null,
  };

const BrokerDealerEconomicsTable: VFC<Props> = ({ offeringId, managers, operationType }) => {
  const { data, loading, error, refetch } = useBrokerDealersMyRetailQuery({ offeringId });

  if (loading) {
    return <TableSkeleton aria-label={label} cellSize="medium" numOfColumns={3} />;
  }

  if (error) {
    return (
      <LoadingErrorSection
        tableLabel={label}
        tableHead={<BrokerDealerEconomicsTableHead />}
        onRefetch={refetch}
      />
    );
  }

  return (
    <Stack gap={3}>
      <WireFormBanner variant="manager-specific-input" />
      <TableContainer>
        <Table aria-label={label}>
          <BrokerDealerEconomicsTableHead />
          <SyndicateRecipientsUserDataFormFields<
            NonSyndicateSellingGroupTermsRetentionRecipientUserData,
            Values
          >
            managers={managers}
            operationType={operationType}
            numColumns={3}
            defaultRecipientValues={defaultRecipientValues}
            renderRecipientRow={({ manager, managerIndex, onChange }) => {
              const managerRetail = data?.find(item => item.cmgEntityKey === manager.cmgEntityKey);
              return (
                <TableRow aria-label={manager.firmName} key={manager.cmgEntityKey}>
                  <TableCell>{manager.firmName}</TableCell>
                  <TableCell size="medium" align="right">
                    {numericUtil.formatNumber(managerRetail?.retentionAmount, 0)}
                  </TableCell>
                  <TableCell align="right">
                    <FormikNumericField
                      startAdornment={
                        <InputAdornment
                          data-testid={`${manager.firmName}-selling-concession-input-adornment`}
                          position="start"
                        >
                          $
                        </InputAdornment>
                      }
                      inputProps={{
                        'aria-label': `${manager.firmName} - Selling Concession`,
                      }}
                      precision={6}
                      required
                      showHelperTextInTooltip
                      name={`recipientsUserData.${managerIndex}.sellingConcession`}
                      onChange={sellingConcession => {
                        onChange({ sellingConcession });
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            }}
          />
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default BrokerDealerEconomicsTable;
