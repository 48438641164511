import { DataGridClient } from '@cmg/common';
import { AgGridEvent } from 'ag-grid-community';
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useTheme } from 'styled-components/macro';

import { useGridReady } from '../../../../../common/hooks/useGridReady';
import {
  OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
  OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
} from '../../../graphql';
import { calculateTotalUnderwritingShares } from '../../UnderwritingTermsEconomicsSection.model';
import { NumericInputRenderer } from './components/numeric-input-renderer/NumericInputRenderer';
import { PercentInputRenderer } from './components/percent-input-renderer/PercentInputRenderer';
import { generateOverlayInfo, ManagerEconomicBreakdownRow } from './EconomicBreakdownGrid.model';
import { EconomicBreakdownGridWrapper } from './EconomicBreakdownGrid.styles';
import {
  EconomicBreakdownGridColumnOrder,
  economicBreakdownGridColumnsOrdered,
} from './EconomicBreakdownGridColumns';

type UnderwritingFeesAndDiscounts =
  OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery['underwritingTermsFeesAndDiscounts'];

type Props = {
  totalSharesOverallotmentAuthorized?: number | null;
  totalSharesOverallotmentExercised?: number | null;
  totalSharesBaseOffering?: number | null;
  underwritingFeesAndDiscounts?: UnderwritingFeesAndDiscounts;
  latestFiling?: OfferingSetup_UnderwritingTerms_Economics_FilingsQuery['getFilings'][0];
  totalValues: ManagerEconomicBreakdownRow;
  grossSpreadBaseData: UnderwritingFeesAndDiscounts['grossSpreadBaseData'] | null;
  incentiveFeeData: UnderwritingFeesAndDiscounts['incentiveFeeData'] | null;
};

export type ManagerEconomicsBreakdownFormikContext = {
  rows: Record<number, ManagerEconomicBreakdownRow>;
};

export const EconomicBreakdownGrid: React.FC<Props> = ({
  totalSharesOverallotmentAuthorized,
  totalSharesBaseOffering,
  totalSharesOverallotmentExercised,
  underwritingFeesAndDiscounts,
  latestFiling,
  totalValues,
}) => {
  const theme = useTheme();
  const gridRef = useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);

  const { values, setFieldValue } = useFormikContext<ManagerEconomicsBreakdownFormikContext>();

  const rows = Object.values(values.rows);

  const offeringSizeInShares = latestFiling?.totalSharesBaseOffering;
  const grossSpreadBase = underwritingFeesAndDiscounts?.grossSpreadBaseData?.grossSpreadBase;
  const baseGrossSpreadAllocation = underwritingFeesAndDiscounts?.grossSpreadBaseData;
  const incentiveFee = underwritingFeesAndDiscounts?.incentiveFeeData?.incentiveFee;
  const incentiveGrossSpreadAllocation = underwritingFeesAndDiscounts?.incentiveFeeData;

  const overlayInfo = generateOverlayInfo({
    offeringSizeInShares,
    grossSpreadBase,
    baseGrossSpreadAllocation,
    incentiveGrossSpreadAllocation,
    incentiveFee,
  });

  const numRows = rows.length;
  const updateTotalUnderwritingShares = (
    rowIndex: number,
    underwritingShares: number | null,
    exercisedOverallotmentShares: number | null
  ) => {
    setFieldValue(
      `rows[${rowIndex}].${EconomicBreakdownGridColumnOrder.TOTAL_UNDERWRITING_SHARES}`,
      calculateTotalUnderwritingShares(underwritingShares, exercisedOverallotmentShares),
      false
    );
  };

  return (
    <EconomicBreakdownGridWrapper>
      <DataGridClient<ManagerEconomicBreakdownRow>
        onGridReady={onGridReady}
        loading={false}
        extended={{
          fillViewport: true,
          withMargin: false,
          hidePagination: true,
          hideColumnResize: true,
          hideColumnSelector: true,
          hideHeader: true,
        }}
        columns={economicBreakdownGridColumnsOrdered}
        rows={rows}
        resizeBy="grid"
        resizeStrategy="fit-screen"
        gridOptions={{
          context: {
            incentiveFee,
            totalSharesBaseOffering,
            totalSharesOverallotmentAuthorized,
            totalSharesOverallotmentExercised,
            showOverlay: overlayInfo.showOverlay,
            termsErrors: overlayInfo.termsErrors,
            discountsAndFeesErrors: overlayInfo.discountsAndFeesErrors,
            numRows,
            offeringSizeInShares,
            grossSpreadBase,
            baseGrossSpreadAllocation,
            incentiveGrossSpreadAllocation,
            updateTotalUnderwritingShares,
          },
          rowHeight: 44,
          rowBuffer: 66,
          headerHeight: 44,
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressRowHoverHighlight: true,
          suppressRowTransform: true,
          suppressPaginationPanel: true,
          rowStyle: {
            cursor: 'default',
            background: theme.background.color.white,
            borderBottom: theme.border.smallSolidLight,
          },
        }}
        getRowsPinnedToTop={() => [totalValues]}
        getRowNodeId={row => row.id}
        frameworkComponents={{ PercentInputRenderer, NumericInputRenderer }}
      />
    </EconomicBreakdownGridWrapper>
  );
};
