import { BaseAgGrid, OnGridReadyEventFn, SelectionChangedEventFn, UUID } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { TradeRelease_TradeGridItemFragment } from '../graphql/__generated__';
import { useTradeGridColumns } from './hooks/useTradeGridColumns';
import { useTradeReleaseGridTotals } from './hooks/useTradeReleaseGridTotals';
import {
  isTradeReleaseRowSelectablePredicate,
  TradeReleaseGridContext,
} from './TradeReleaseGrid.model';
import TradeReleaseNoResults from './zero-state/TradeReleaseNoResults';
import TradeReleaseZeroState from './zero-state/TradeReleaseZeroState';

export type Props = Readonly<{
  offeringId: UUID;
  filteredTradeRows: TradeRelease_TradeGridItemFragment[];
  onGridReady: OnGridReadyEventFn;
  onSelectionChange: (selectedRows: TradeRelease_TradeGridItemFragment[]) => void;
  numOfAllRows: number;
  setNumOfUnchangedTrades: (unchangedTrades: number) => void;
}>;

const TradeReleaseGrid: React.FC<Props> = ({
  offeringId,
  filteredTradeRows,
  onGridReady,
  onSelectionChange,
  numOfAllRows,
  setNumOfUnchangedTrades,
}) => {
  const theme = useTheme();

  const gridContext: TradeReleaseGridContext = {
    offeringId,
    filteredRows: filteredTradeRows,
    setNumOfUnchangedTrades,
  };

  const columns = useTradeGridColumns();
  const totals = useTradeReleaseGridTotals(filteredTradeRows);

  const handleSelectionChanged = React.useCallback<SelectionChangedEventFn>(
    ({ api }) => {
      api.refreshHeader();
      onSelectionChange(api.getSelectedRows());
    },
    [onSelectionChange]
  );

  return (
    <BaseAgGrid<TradeRelease_TradeGridItemFragment>
      context={gridContext}
      columns={columns}
      rows={filteredTradeRows}
      pinnedTopRows={totals}
      rowSelection="multiple"
      loading={false}
      onGridReady={onGridReady}
      onSelectionChanged={handleSelectionChanged}
      frameworkComponents={{ emptyComponent: () => null }}
      noRowsOverlayComponentFramework={
        numOfAllRows === 0 ? TradeReleaseZeroState : TradeReleaseNoResults
      }
      isRowSelectable={isTradeReleaseRowSelectablePredicate}
      rowStyle={{
        background: theme.background.color.white,
        borderBottom: theme.border.smallSolidLight,
      }}
    />
  );
};

export default TradeReleaseGrid;
