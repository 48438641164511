import { OperationType } from '../components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';

export function getMasterDate(
  operationType: OperationType,
  wireMasterDate: string | null | undefined,
  sharedMasterDate: string | null | undefined
) {
  if (operationType === 'update') {
    return wireMasterDate ?? '';
  }

  return sharedMasterDate ?? wireMasterDate ?? '';
}
