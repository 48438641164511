import { AttestationFormStatus, CertificateType, FirmCertificateSource } from '../../graphql';

export const STATUS_LABELS: Record<AttestationFormStatus, string> = {
  [AttestationFormStatus.NotOnFile]: 'Not on file',
  [AttestationFormStatus.Blanket]: 'Blanket',
  [AttestationFormStatus.Partial]: 'Partial',
  [AttestationFormStatus.Expired]: 'Expired',
};

export const STATUS_COLORS: Record<AttestationFormStatus, 'success' | 'error'> = {
  [AttestationFormStatus.Blanket]: 'success',
  [AttestationFormStatus.Partial]: 'success',
  [AttestationFormStatus.Expired]: 'error',
  [AttestationFormStatus.NotOnFile]: 'error',
};

export const TYPE_LABELS: Record<CertificateType, string> = {
  [CertificateType.Form51305131]: '5130/31',
  // [CertificateType.Qib]: 'QIB',
  // [CertificateType.RegS]: 'Reg S',
};

export const SOURCE_LABELS: Record<FirmCertificateSource, string> = {
  [FirmCertificateSource.Crm]: 'My CRM',
  [FirmCertificateSource.Cmg]: 'CMG Library',
};
