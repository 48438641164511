import { useAccessToken, useAuth } from '@cmg/auth';
import {
  AccountCircleOutlinedIcon,
  AppBarMenu,
  HelpOutlineIcon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
} from '@cmg/design-system';
import React, { useCallback, VFC } from 'react';
import { useDispatch } from 'react-redux';

import { openSupportRequestModal } from '../../../common/components/modals/support-request-modal/SupportRequestModal';
import { useFeatureToggles } from '../../../common/config';
import { notificationApi, PushNotificationControl } from '../../../common/push-notification';
import externalRouteFactory from '../../../common/util/externalRouteFactory';
import routeFactory from '../../../common/util/routeFactory';
import { MyOfferingsQuickAccessDropdown } from '../../../features/offering/components/my-offerings-quick-access/MyOfferingsQuickAccessDropdown';
import { useHasOfferingAccess } from '../../../features/offering/hooks/useHasOfferingAccess';

const MenuListProps = {
  subheader: <ListSubheader>Account</ListSubheader>,
};
const navigateToProfile = () => {
  window.open(externalRouteFactory.myProfile.getUrlPath(), '_blank', 'noopener');
};

export const AppBarSecondarySection: VFC = () => {
  const token = useAccessToken();
  const dispatch = useDispatch();
  const { oidcUserFamilyName, oidcUserGivenName, oidcUserEmail, oidcUserId } = useAuth();
  const hasOfferingAccess = useHasOfferingAccess();
  const supportRenderItems = useCallback(
    ({ closeMenu }) => [
      <ListItem
        key="support"
        disablePadding
        role="menuitem"
        onClick={() => {
          token && dispatch(openSupportRequestModal({ token }));
          closeMenu();
        }}
      >
        <ListItemButton component="a">
          <ListItemText primary="Support" />
        </ListItemButton>
      </ListItem>,
    ],
    [dispatch, token]
  );
  const accountRenderItems = useCallback(
    ({ closeMenu }) => [
      <MenuItem key="account-info" onClick={navigateToProfile} divider>
        <ListItemIcon>
          <AccountCircleOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={`${oidcUserGivenName} ${oidcUserFamilyName}`}
          secondary={oidcUserEmail}
        />
      </MenuItem>,
      <ListItem key="privacy" disablePadding role="menuitem" onClick={closeMenu}>
        <ListItemButton
          href={externalRouteFactory.privacyPolicy.getUrlPath()}
          target="_blank"
          rel="noopener"
        >
          <ListItemText primary="Privacy" />
        </ListItemButton>
      </ListItem>,
      <ListItem key="log-out" disablePadding role="menuitem" onClick={closeMenu}>
        <ListItemButton href={routeFactory.oidcLogout.getUrlPath()}>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </ListItem>,
    ],
    [oidcUserEmail, oidcUserFamilyName, oidcUserGivenName]
  );

  const { isPushNotificationsOn } = useFeatureToggles();

  if (!oidcUserId) {
    return null;
  }

  return (
    <React.Fragment>
      {hasOfferingAccess && <MyOfferingsQuickAccessDropdown />}
      {isPushNotificationsOn && notificationApi.isSupported() && <PushNotificationControl />}
      <AppBarMenu
        id="support-menu"
        label="Support"
        icon={<HelpOutlineIcon />}
        renderItems={supportRenderItems}
      />
      <AppBarMenu
        id="account-settings-menu"
        label="Account & Settings"
        icon={<AccountCircleOutlinedIcon />}
        MenuListProps={MenuListProps}
        renderItems={accountRenderItems}
      />
    </React.Fragment>
  );
};
