import { UUID } from '@cmg/common';
import { ActionPanel, Typography } from '@cmg/design-system';
import orderBy from 'lodash/orderBy';
import React, { Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { InvestorFirmOption } from '../../common/components/form/investor-firm-search/InvestorFirmSearch';
import { isValidCmgEntityKey } from '../../common/util/isValidCmgEntityKey';
import routeFactory from '../../common/util/routeFactory';
import InvestorSearch from './components/investor-search/InvestorSearch';
import { SidePanelSellSideNavbar } from './components/side-bar/components/design-system';
import SidePanelBuySideNavbar from './components/side-bar/SidePanelBuySideNavbar';
import { SupersedeOfferingBanner } from './components/supersede-offering-banner/SupersedeOfferingBanner';
import { useCmgEntityKeyContext } from './contexts/CmgEntityKeyContext';
import { useFirmKeyOrIdContext } from './contexts/FirmKeyOrIdContext';
import { SupersededOfferingProvider } from './contexts/SupersededOfferingContext';
import { useGetSupersededDataOffering } from './hooks/useGetSupersededDataOffering';
import { getIndication, useIndicationEntityNameQuery } from './hooks/useIndicationEntityNameQuery';
import { useInvestorFirmSearch } from './hooks/useInvestorFirmSearch';
import offeringSidePanelBuySideRouteFactory from './offeringSidePanelBuySideRouteFactory';
import OfferingSidePanelBuySideRoutes from './OfferingSidePanelBuySideRoutes';
import offeringSidePanelSellSideRouteFactory from './offeringSidePanelSellSideRouteFactory';
import OfferingSidePanelSellSideRoutes from './OfferingSidePanelSellSideRoutes';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from './order-book/hooks/useIdentifyOrderBookPersona';

// List of routes sorted from most specific to least specific
const getRoutePaths = (
  routePaths: Record<
    string,
    {
      routePath: string;
    }
  >
) => {
  return orderBy(
    Object.entries(routePaths).map(([, route]) => route.routePath),
    // count how many arguments a routePath has
    path => path.split('/:').length,
    'desc'
  );
};

const sellSideRoutePaths = getRoutePaths(offeringSidePanelSellSideRouteFactory);
const buySideRoutePaths = getRoutePaths(offeringSidePanelBuySideRouteFactory);
/**
 * OfferingSidePanel displays offering data in a side panel whenever
 * the current route matches the offering side panel route
 */
// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
const OfferingSidePanel: React.FC = () => {
  const history = useHistory();

  const routeMatch = useRouteMatch<{ offeringId: UUID; cmgEntityKey?: string }>([
    ...sellSideRoutePaths,
    ...buySideRoutePaths,
  ]);
  const sidePanelMatch = useRouteMatch<{ offeringId: UUID }>(
    routeFactory.offeringSidePanel.routePath
  );

  const offeringId = sidePanelMatch?.params.offeringId;
  const { type, isSellSide } = useIdentifyOrderBookPersona({
    offeringId,
  });
  const isCoveredAccount = type === OrderBookPersonaType.SALES_AND_TRADING;

  const supersededData = useGetSupersededDataOffering({ offeringId });

  const { isObsoleteOffering, isFiledOffering } = supersededData;

  const offeringRoutePath = useRouteMatch(
    offeringSidePanelSellSideRouteFactory.offeringDetails.routePath
  );
  const notesRoutePath = useRouteMatch(
    offeringSidePanelSellSideRouteFactory.orderBookNotes.routePath
  );

  const sidePanelCommunicatedDemandMatch = useRouteMatch([
    offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.routePath,
    offeringSidePanelBuySideRouteFactory.orderBookIndicationActivity.routePath,
  ]);

  const filedOffering = isFiledOffering && !!sidePanelCommunicatedDemandMatch;

  const shouldDisplayInvestorSearch =
    !(!!offeringRoutePath || !!notesRoutePath) && !isObsoleteOffering && !filedOffering;

  const { cmgEntityKey: cmgEntityKeyFromUrlQueryParam, updateCmgEntityKey } =
    useCmgEntityKeyContext();

  // We need to pass firmKey/Id information to Create Indication form
  const { setFirmKeyOrId, firmKeyOrId, firmKey, setFirmKey } = useFirmKeyOrIdContext();

  const { data: entityInfo, loading: entityInfoLoading } = useIndicationEntityNameQuery({
    variables: {
      offeringId: offeringId!,
      cmgEntityKey: cmgEntityKeyFromUrlQueryParam,
    },
    skip: !offeringId || !isValidCmgEntityKey(cmgEntityKeyFromUrlQueryParam),
    isCoveredAccount,
  });

  // if there is existing indication, use bankInvestorKey from the returned indication
  const indicationInfo = getIndication(entityInfo);
  const bankInvestorKey = indicationInfo
    ? indicationInfo.investorInformation.bankInvestorKey
    : firmKey;

  const { data: investorFirm, loading: investorFirmLoading } = useInvestorFirmSearch(
    bankInvestorKey
      ? { firmKeyOrId: bankInvestorKey, cmgEntityKey: cmgEntityKeyFromUrlQueryParam }
      : { cmgEntityKey: cmgEntityKeyFromUrlQueryParam },
    isCoveredAccount
  );

  React.useEffect(() => {
    if (!firmKeyOrId) {
      setFirmKeyOrId(investorFirm?.firmKey ?? investorFirm?.id ?? undefined);
    }
    if (!firmKey) {
      setFirmKey(investorFirm?.firmKey ?? undefined);
    }
  }, [investorFirm, setFirmKeyOrId, firmKeyOrId, firmKey, setFirmKey]);

  const isOpen = !!sidePanelMatch;
  const handleOnClose = () => {
    sidePanelMatch && history.push(sidePanelMatch.params[0]);
  };

  const displayInvestorSearch = isSellSide && shouldDisplayInvestorSearch;

  const getInvestorSearchValue = React.useMemo(() => {
    return (investorFirm || indicationInfo) && cmgEntityKeyFromUrlQueryParam
      ? {
          label:
            indicationInfo?.investorInformation?.bankInvestorName! ??
            indicationInfo?.investorInformation?.cmgEntityName! ??
            investorFirm?.name!,
          value:
            indicationInfo?.investorInformation?.bankInvestorName! ??
            indicationInfo?.investorInformation?.bankInvestorKey! ??
            investorFirm?.cmgEntityKey,
        }
      : null;
  }, [cmgEntityKeyFromUrlQueryParam, indicationInfo, investorFirm]);

  return (
    <div data-testid="offering-side-panel">
      <SupersededOfferingProvider data={supersededData}>
        <ActionPanel
          variant="persistent"
          open={isOpen}
          menu={
            routeMatch &&
            (isSellSide ? (
              <SidePanelSellSideNavbar offeringId={routeMatch.params.offeringId} />
            ) : (
              <SidePanelBuySideNavbar offeringId={routeMatch.params.offeringId} />
            ))
          }
          header={<Typography variant="h2" component="h2" id="offering-side-panel-header" />}
          onClose={handleOnClose}
          dismissible
        >
          <Fragment>
            {/* We display the investor search on all routes except for offering details */}
            {displayInvestorSearch && (
              <InvestorSearch
                loading={investorFirmLoading || entityInfoLoading}
                value={getInvestorSearchValue}
                onChange={(investor: InvestorFirmOption | null) => {
                  updateCmgEntityKey(investor?.value || undefined);
                  setFirmKeyOrId(investor?.firmKey || investor?.id || undefined);
                  setFirmKey(investor?.firmKey || undefined);
                }}
                isCoveredAccount={isCoveredAccount}
              />
            )}
            <div>
              {isObsoleteOffering && <SupersedeOfferingBanner isSellSide={isSellSide} />}
              {isOpen && isSellSide ? (
                <OfferingSidePanelSellSideRoutes offeringId={offeringId} />
              ) : (
                <OfferingSidePanelBuySideRoutes />
              )}
            </div>
          </Fragment>
        </ActionPanel>
      </SupersededOfferingProvider>
    </div>
  );
};

export default OfferingSidePanel;
