import { Popover, PopoverProps } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

/**
 * Fixes Popovers over disabled Buttons problems, specifically:
 * - popover not displayed when the button is disabled (fixed by `pointer-events`)
 * - popover not being hidden on mouseout from the button (fixed by `cursor`)
 * see https://github.com/react-component/tooltip/issues/18 for further details.
 *
 * The prop named deliberately `buttonDisabled` to denote it's related to the `disabled` prop
 * of the expected child button, not the `disabled` prop of the parent popover.
 */
export const SPopoverButtonWrapper = styled.span<{ buttonDisabled?: boolean }>`
  display: inline-block;
  cursor: ${({ buttonDisabled }) => (buttonDisabled ? 'not-allowed' : 'pointer')};

  > * {
    pointer-events: ${({ buttonDisabled }) => (buttonDisabled ? 'none' : 'auto')};
  }
`;
/**
 * Simplifies usage of Popovers over Buttons, dealing with possible problems.
 *
 * Expected usage example:
 * ```
 * <ButtonTooltip content="Editing not permitted" disabled={canUpdate}>
 *   <Button disabled={!canUpdate}>Edit</Button>
 * </ButtonTooltip>
 * ```
 * See tests & stories for more examples.
 */
const ButtonTooltip: React.FC<PopoverProps> = ({
  variant = 'TOOLTIP',
  disabled,
  children,
  ...props
}) => {
  return (
    <Popover variant={variant} disabled={disabled} {...props}>
      <SPopoverButtonWrapper buttonDisabled={typeof disabled === 'boolean' ? !disabled : undefined}>
        {children}
      </SPopoverButtonWrapper>
    </Popover>
  );
};

export default ButtonTooltip;
