/**
 * shared-types is a place to put enums that are defined in multiple graphql schemas but should be the same. For example, OrderBookState is defined in Apollo and OB schema.
 * It's helpful for them to be shared so data between the two can be used interoperable.
 * Refere to gql.codegen.yml for use. Specify the shared enum under all schemas that generate them.
 * generates:
 *   src/graphql/next/{api}/__generated__/index.ts:
 *     config:
 *       enumValues:
 *         OrderBookState: '../../../shared-types#OrderBookState'
 */

export enum OrderBookState {
  Closed = 'CLOSED',
  NeverOpened = 'NEVER_OPENED',
  Open = 'OPEN',
}

export enum RelationshipType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum RolodexRecordStatus {
  Deleted = 'DELETED',
  Effective = 'EFFECTIVE',
}
