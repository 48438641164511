import { FlexContainer, numericUtil, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import logo from '../../../../common/assets/logo.png';
import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import {
  FinalSettlement_EligibleManagerPartsFragment,
  useFinalSettlement_SettlementLetterQuery,
} from '../graphql';
import SettlementDetailsTable from '../settlement-details-table/SettlementDetailsTable';
import SettlementLetterDebitCreditTable from '../settlement-letter-debit-credit-table/SettlementLetterDebitCreditTable';
import { SBody, SManagerList, SMenuHeading } from '../SettlementLettersRoute.styles';
import UnderwritersNavigation from '../underwriters-navigation/UnderwritersNavigation';
import UnderwritingGroupExpensesTable from '../underwriting-group-expenses-table/UnderwritingGroupExpensesTable';
import {
  SCMGFooter,
  SCMGFooterImage,
  SCMGFooterText,
  SDivider,
  SLetterFooter,
  SLetterFooterDate,
  SLetterHeader,
  SLetterSubHeader,
  SLetterTemplateContainer,
  SLetterText,
  SLetterTextContainer,
  SLetterWrapper,
  SNoWrapSpan,
  SPageBreak,
} from './SettlementLetterContent.styles';

export type Props = RouteComponentProps<{ offeringId: UUID; managerId: UUID }> & {
  innerRef: React.Ref<HTMLDivElement>;
  managers: FinalSettlement_EligibleManagerPartsFragment[];
};

const SettlementLetterContentRoute: React.FC<Props> = ({ innerRef, managers, match }) => {
  const { offeringId, managerId } = match.params;

  const { error, loading, data } = useFinalSettlement_SettlementLetterQuery({
    variables: { offeringId, managerId },
    fetchPolicy: 'cache-and-network',
  });

  const { finalSettlement, finalSettlementDate, managerName, trancheName, userFirmName } =
    data?.settlementLetter ?? {};

  return (
    <SBody>
      <SManagerList>
        <SMenuHeading>Underwriters</SMenuHeading>
        <UnderwritersNavigation offeringId={offeringId} managerId={managerId} managers={managers} />
      </SManagerList>

      {loading && <Loading />}

      {error && <ServerErrorsBanner error={error} />}

      {!loading && !error && (
        <SLetterTemplateContainer ref={innerRef}>
          <SLetterWrapper>
            <FlexContainer direction="column" gap={8}>
              <div>
                <SLetterHeader>Final Settlement Letter</SLetterHeader>
                <SLetterSubHeader>
                  Dear <SNoWrapSpan>{managerName}</SNoWrapSpan>, you have received a Final
                  Settlement letter from <SNoWrapSpan>{userFirmName}</SNoWrapSpan>
                </SLetterSubHeader>
                <SDivider />
                <SettlementDetailsTable data={data?.settlementLetter!} />
                <SDivider />
              </div>
              <SLetterTextContainer>
                <SLetterText>
                  We are enclosing a check/wire for {numericUtil.formatCurrency(finalSettlement, 2)}{' '}
                  in settlement of your account as an underwriter for the {trancheName} tranche of
                  the above offering.
                </SLetterText>
                <p>
                  The following letter is a statement of your account related to the above-mentioned
                  issue.
                </p>
              </SLetterTextContainer>
              <SettlementLetterDebitCreditTable data={data?.settlementLetter!} />
            </FlexContainer>

            <SPageBreak />

            <FlexContainer direction="column" gap={8}>
              <UnderwritingGroupExpensesTable data={data?.settlementLetter!} />
              <SLetterFooter>By: {userFirmName}</SLetterFooter>
              <SLetterFooterDate>Created on: {finalSettlementDate}</SLetterFooterDate>
            </FlexContainer>
          </SLetterWrapper>
          <SCMGFooter>
            <SCMGFooterText>
              Powered by CMG &ndash; delivering innovation to capital formation.
            </SCMGFooterText>
            <SCMGFooterImage src={logo} width={70} alt="CMG Blue Arrow Logo" />
          </SCMGFooter>
        </SLetterTemplateContainer>
      )}
    </SBody>
  );
};

export default SettlementLetterContentRoute;
