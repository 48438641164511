import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';
import { calculateOverallotmentShares } from '../../deal-layout-panel/base-deal-shares-info-box/BaseDealSharesInfoBox.model';

const TotalOverallotmentSharesInfoBox: React.FC = () => {
  const accounting = useAccountingContext();
  const { totalSharesRepurchased, totalSharesRefreshed, overallotmentExercised } = accounting;

  const totalOverallotmentShares = calculateOverallotmentShares(accounting);

  return (
    <AccountingInfoBox
      gridTemplateColumns="3fr 10px 1.5fr"
      sourceOfData="Sourced from Offering Setup & Stabilization"
    >
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Total Shares Repurchased
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(totalSharesRepurchased)}
      </Table.Cell>

      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Total Shares Refreshed
      </Table.Cell>
      <Table.Cell paddingSize="S" bold>
        -
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(totalSharesRefreshed)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Overallotment Exercised</Table.Cell>
      <Table.Cell paddingSize="S" bold>
        +
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForInteger(overallotmentExercised)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Total Overallotment Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForInteger(totalOverallotmentShares)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default TotalOverallotmentSharesInfoBox;
