import { ValueGetterParams } from 'ag-grid-community';

import { InstitutionalIndicationOrderType } from '../../../../../../../../graphql';
import { getFirmDisplayName } from '../../../../../utils';
import {
  isComplianceErrorApplicableForOfferingType,
  isRowAcknowledgementRevised,
  isRowComplianceInvalid,
  isRowValidAndBnDAgentUnassigned,
} from '../../../components/row-alert/RowAlert.model';
import { SyndicateManager } from '../../../types';
import { getSortedAcknowledgementsList } from '../../acknowledgements-cell/utils';
import { getAllEmployees } from '../../sales-coverage-cell/SalesCoverageCell';
import { getTypeConfigurations } from '../../type-cell/TypeCell';

export const investorValueGetter = ({ data }: ValueGetterParams) => {
  return data?.investorInformation?.bankInvestorName || data?.investorInformation?.cmgEntityName;
};

export const bdAgentValueGetter = ({ data, context }: ValueGetterParams) => {
  const managers = context?.syndicateManagers;
  const manager = managers ? managers[data?.billingAndDeliveryAgentCmgEntityKey] : null;

  return getFirmDisplayName(manager, { defaultName: 'Unassigned' });
};

export const acknowledgementsValueGetter = (
  { data, context }: ValueGetterParams,
  oidcUserCmgEntityKey: string | null
) => {
  const { syndicateManagers } = context;
  const { institutionalIndicationAcknowledgements } = data;
  const acknowledgementList = getSortedAcknowledgementsList(
    syndicateManagers,
    oidcUserCmgEntityKey!,
    institutionalIndicationAcknowledgements
  );

  return acknowledgementList
    .map(acknowledgment => {
      const manager = syndicateManagers[acknowledgment.managerCmgEntityKey];
      const { managerCmgEntityKey } = acknowledgment;

      return getFirmDisplayName(manager, { defaultName: managerCmgEntityKey });
    })
    .join(', ');
};

export const rowAlertValueGetter = ({
  indicationStatus,
  attestationStatus,
  prospectusDeliveryStatus,
}) => {
  return isRowComplianceInvalid({ indicationStatus, attestationStatus, prospectusDeliveryStatus })
    ? 1
    : 0;
};

// return the total number of warning and error alerts to do sorting for the header
export const cmgColumnsRowAlertValueGetter = ({
  indication,
  offeringType,
  isSettlementAgent,
  prospectusDeliveryStatus,
  oidcUserCmgEntityKey,
}) => {
  const indicationStatus = indication.status;
  const attestationStatus = indication.attestationStatus?.status;
  const finalAllocationShareQuantity = indication.finalAllocation?.shareQuantity;
  const billingAndDeliveryAgentCmgEntityKey = indication.billingAndDeliveryAgentCmgEntityKey;
  const institutionalIndicationAcknowledgements =
    indication.institutionalIndicationAcknowledgements;
  const currentIndicationVersion = indication.version;

  const isComplianceInvalidCount =
    isComplianceErrorApplicableForOfferingType(offeringType) &&
    isRowComplianceInvalid({ indicationStatus, attestationStatus, prospectusDeliveryStatus })
      ? 2
      : 0;

  const isRowUnAssignedBDAgentCount = isRowValidAndBnDAgentUnassigned({
    billingAndDeliveryAgentCmgEntityKey: billingAndDeliveryAgentCmgEntityKey,
    finalAllocationShareQuantity: finalAllocationShareQuantity,
    isSettlementAgent: isSettlementAgent,
    status: indication.status,
    type: indication.type,
  })
    ? 2
    : 0;

  const isAcknowledgementRevisedWarningAlert = isRowAcknowledgementRevised({
    institutionalIndicationAcknowledgements,
    currentIndicationVersion,
    indicationStatus,
    oidcUserCmgEntityKey,
  })
    ? 1
    : 0;

  return (
    isComplianceInvalidCount + isRowUnAssignedBDAgentCount + isAcknowledgementRevisedWarningAlert
  );
};

export const salesCoverageValueGetter = ({ data }: ValueGetterParams) => {
  if (data?.investorEmployeeRelationships) {
    return getAllEmployees(data?.investorEmployeeRelationships).length > 0
      ? getAllEmployees(data?.investorEmployeeRelationships).join(', ')
      : '-';
  } else {
    return '-';
  }
};

export const typeValueGetter = ({ data }: ValueGetterParams) => {
  if (data.type === InstitutionalIndicationOrderType.Pass) {
    return data.type;
  }
  const { label } = getTypeConfigurations({
    type: data.type,
    interestLevels: data.interestLevels,
    demandCurrencyCode: data.currencyCode,
    pricingCurrencyCode: data.pricingCurrencyCode,
  });
  return label;
};

export const firmDisplayNamesValueGetter = (managers: (SyndicateManager | null)[] | undefined) => {
  if (!managers) {
    return '';
  }

  return managers
    .map(manager => getFirmDisplayName(manager, { defaultName: manager?.cmgEntityKey }))
    .join(', ');
};
