import { getOperationName } from '@apollo/client/utilities';

import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../../../my-orders/graphql';
import {
  IndicationForm_IndicationAcknowledgementsDocument,
  IndicationForm_UnacknowledgeCoveredIndicationMutationVariables,
  useIndicationForm_UnacknowledgeCoveredIndicationMutation,
} from '../graphql/__generated__';

export function refetchQueries() {
  const refetchInstitutionalIndicationAcknowledgementsQuery = getOperationName(
    IndicationForm_IndicationAcknowledgementsDocument
  );
  const refetchCoveredIndicationsQuery = getOperationName(MyOrders_CoveredIndicationsDocument);
  return [refetchInstitutionalIndicationAcknowledgementsQuery!, refetchCoveredIndicationsQuery!];
}

export const useUnacknowledgeCoveredIndicationMutation = (
  variables: IndicationForm_UnacknowledgeCoveredIndicationMutationVariables
) =>
  useIndicationForm_UnacknowledgeCoveredIndicationMutation({
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
