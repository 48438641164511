import React from 'react';

import SetupForm from '../components/form/OfferingSetupForm';
import { OfferingSetup_DemandCurrencyPartsFragment } from './graphql';

export type Props = {
  data?: OfferingSetup_DemandCurrencyPartsFragment;
};

export const DemandCurrencyFormComponent: React.FC<Props> = ({ data }) => {
  return (
    <SetupForm.Row>
      <SetupForm.Column>
        <SetupForm.Field>{data?.currencyCode}</SetupForm.Field>
      </SetupForm.Column>
      <SetupForm.Column>
        <SetupForm.Field>{data?.exchangeRate}</SetupForm.Field>
      </SetupForm.Column>
    </SetupForm.Row>
  );
};
export default DemandCurrencyFormComponent;
