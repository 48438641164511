import { apiTypes, routeUtil, urlUtil, UUID } from '@cmg/common';
import History from 'history';
import {
  match,
  ParseOptions,
  RegexpToFunctionOptions,
  TokensToRegexpOptions,
} from 'path-to-regexp';

import { LogisticsNavigationSteps } from '../../features/roadshows/single-roadshow/roadshow-calendar/panel-logistics-event/common/logistics-side-panel/LogisticsSidePanel.model';
import { MeetingNavigationSteps } from '../../features/roadshows/single-roadshow/roadshow-calendar/panel-meetings-event/common/meeting-side-panel/MeetingSidePanel.model';

const { createRoute } = routeUtil;

const getSearchString = (searchParams: URLSearchParams | undefined) =>
  `${Array.from(searchParams ?? []).length ? '?' : ''}${searchParams?.toString() ?? ''}`;

const root = createRoute({
  routePath: '/',
  getUrlPath: () => '/',
});

// ----- OIDC - logging into the SPA
const oidcLogin = createRoute({
  routePath: '/oidc-login',
  getUrlPath: () => '/oidc-login',
  getRouteState: (params: { location: History.Location }) => ({
    from: params.location,
  }),
});

const oidcLogout = createRoute({
  routePath: '/oidc-logout',
  getUrlPath: () => '/oidc-logout',
});

const oidcLoginCallback = createRoute({
  routePath: '/oidc-login-callback',
  getUrlPath: () => '/oidc-login-callback',
});

const oidcLogoutCallback = createRoute({
  routePath: '/oidc-logout-callback',
  getUrlPath: () => '/oidc-logout-callback',
});

const loggedOut = createRoute({
  routePath: '/logged-out',
  getUrlPath: ({ reason }: { reason?: string } = {}) =>
    `/logged-out${urlUtil.queryStringify({ reason })}`,
});

// ----- XC
const calendar = createRoute({
  routePath: '/calendar',
  getUrlPath: () => '/calendar',
  getDocumentTitle: () => 'XC Calendar',
});

const calendarLive = createRoute({
  routePath: '/calendar/live',
  getUrlPath: () => '/calendar/live',
});
const calendarPriced = createRoute({
  routePath: '/calendar/priced',
  getUrlPath: () => '/calendar/priced',
});
const calendarFiled = createRoute({
  routePath: '/calendar/filed',
  getUrlPath: () => '/calendar/filed',
});
const calendarPostponed = createRoute({
  routePath: '/calendar/postponed',
  getUrlPath: () => '/calendar/postponed',
});

const offeringSidePanel = createRoute({
  routePath: '*/offering-side-panel/:offeringId',
  getUrlPath: ({
    offeringId,
    prefixRoutePath,
    sidePanelRoutePath,
  }: {
    offeringId: UUID;
    prefixRoutePath: string;
    sidePanelRoutePath: string;
  }) => `${prefixRoutePath}/offering-side-panel/${offeringId}${sidePanelRoutePath}`,
  getDocumentTitle: ({ ticker }: { ticker?: string | null }) => `${ticker ?? ''} | XC`,
});

const myOfferings = createRoute({
  routePath: '/my-offerings',
  getUrlPath: () => '/my-offerings',
  getDocumentTitle: () => 'My Offerings | XC',
});

const myOrders = createRoute({
  routePath: '/my-orders',
  getUrlPath: () => '/my-orders',
  getDocumentTitle: () => 'My Orders | XC',
});

const myOfferingsOfferings = createRoute({
  routePath: '/my-offerings/offerings',
  getUrlPath: () => '/my-offerings/offerings',
});

const myOfferingsDrafts = createRoute({
  routePath: '/my-offerings/drafts',
  getUrlPath: () => '/my-offerings/drafts',
});

const myOfferingsSharedDrafts = createRoute({
  routePath: '/my-offerings/shared-drafts',
  getUrlPath: () => '/my-offerings/shared-drafts',
});

const offeringCreate = createRoute({
  routePath: '/offerings/create',
  getUrlPath: () => `/offerings/create`,
});

const offering = createRoute({
  routePath: '/offerings/:offeringId',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) => `${issuerName ?? ''} | XC`,
});

const offeringSetup = createRoute({
  routePath: '/offerings/:offeringId/setup/:stepId',
  getUrlPath: ({ offeringId, stepId }: { offeringId: UUID; stepId: string }) =>
    `/offerings/${offeringId}/setup/${stepId}`,
  getDocumentTitle: ({
    issuerName,
    stepName,
  }: {
    issuerName?: string | null;
    stepName?: string | null;
  }) => `${stepName ?? ''} | ${offering.getDocumentTitle({ issuerName })}`,
});

const offeringDetails = createRoute({
  routePath: '/offerings/:offeringId/details',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/details`,
});

const sharedDraftOffering = createRoute({
  routePath: '/shared-draft-offerings/:offeringId',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/shared-draft-offerings/${offeringId}`,
});

// ----- Roadshow
const roadshows = createRoute({
  routePath: '/roadshows',
  getUrlPath: () => '/roadshows',
});

const roadshowsList = createRoute({
  routePath: `${roadshows.routePath}/roadshows-list`,
  getUrlPath: () => `${roadshows.getUrlPath()}/roadshows-list`,
});

const roadshowsDealTeams = createRoute({
  routePath: `${roadshows.routePath}/deal-teams`,
  getUrlPath: () => `${roadshows.getUrlPath()}/deal-teams`,
});

const roadshowsCreateDealTeam = createRoute({
  routePath: `${roadshowsDealTeams.routePath}/create`,
  getUrlPath: () => `${roadshowsDealTeams.getUrlPath()}/create`,
});

const roadshowsDealTeamDetails = createRoute({
  routePath: `${roadshowsDealTeams.routePath}/:dealTeamId/details`,
  getUrlPath: ({ dealTeamId }) => `${roadshowsDealTeams.getUrlPath()}/${dealTeamId}/details`,
});

const roadshowsCompanies = createRoute({
  routePath: `${roadshows.routePath}/companies`,
  getUrlPath: () => `${roadshows.getUrlPath()}/companies`,
});

const roadshowCreate = createRoute({
  routePath: `${roadshows.routePath}/create`,
  getUrlPath: () => `${roadshows.getUrlPath()}/create`,
});

const roadshowCalendar = createRoute({
  routePath: `${roadshows.routePath}/:roadshowId/calendar`,
  getUrlPath: ({ roadshowId }: { roadshowId: UUID }) =>
    `${roadshows.getUrlPath()}/${roadshowId}/calendar`,
});

const roadshowDetails = createRoute({
  routePath: `${roadshows.routePath}/:roadshowId/details/roadshow`,
  getUrlPath: ({ roadshowId }: { roadshowId: UUID }) =>
    `${roadshows.getUrlPath()}/${roadshowId}/details/roadshow`,
});

const roadshowCompanyDetails = createRoute({
  routePath: `${roadshows.routePath}/:roadshowId/details/company`,
  getUrlPath: ({ roadshowId }: { roadshowId: UUID }) =>
    `${roadshows.getUrlPath()}/${roadshowId}/details/company`,
});

const roadshowDealTeamDetails = createRoute({
  routePath: `${roadshows.routePath}/:roadshowId/details/deal-team`,
  getUrlPath: ({ roadshowId }: { roadshowId: UUID }) =>
    `${roadshows.getUrlPath()}/${roadshowId}/details/deal-team`,
});

const roadshowCreateMeeting = createRoute({
  routePath: `${roadshowCalendar.routePath}/create-meeting/:date?`,
  getUrlPath: ({ roadshowId, date }: { roadshowId: UUID; date?: string }) =>
    `${roadshowCalendar.getUrlPath({ roadshowId })}/create-meeting/${date ?? ''}`,
});

const roadshowMeetingDetails = createRoute({
  routePath: `${roadshowCalendar.routePath}/meeting/:meetingId/:stepId?`,
  getUrlPath: ({
    roadshowId,
    meetingId,
    stepId,
  }: {
    roadshowId: UUID;
    meetingId: UUID;
    stepId?: MeetingNavigationSteps;
  }) =>
    `${roadshowCalendar.getUrlPath({ roadshowId: roadshowId })}/meeting/${meetingId}/${
      stepId ?? ''
    }`,
});

const roadshowCreateLogistics = createRoute({
  routePath: `${roadshowCalendar.routePath}/create-logistics/:date?`,
  getUrlPath: ({ roadshowId, date }: { roadshowId: UUID; date?: string }) =>
    `${roadshowCalendar.getUrlPath({ roadshowId })}/create-logistics/${date ?? ''}`,
});

const roadshowLogisticsDetails = createRoute({
  routePath: `${roadshowCalendar.routePath}/logistics/:logisticsId/:stepId?`,
  getUrlPath: ({
    roadshowId,
    logisticsId,
    stepId,
  }: {
    roadshowId: UUID;
    logisticsId: UUID;
    stepId?: LogisticsNavigationSteps;
  }) => `${roadshowCalendar.getUrlPath({ roadshowId })}/logistics/${logisticsId}/${stepId ?? ''}`,
});
// ----- Syndicate Wires - Public Routes

const syndicateWiresInvitationWireResponse = createRoute({
  routePath: '/wires/:sentWireId',
  getUrlPath: ({ sentWireId }: { sentWireId: string }) => `/wires/${sentWireId}`,
});

// ----- Syndicate Wires
type SyndicateWirePathArgs = { offeringId: UUID; syndicateWireId?: UUID; managerId?: string };

/**
 * Exported for testing
 */
export const getSyndicateWireUrlPath = (
  wireTypeName: string,
  { offeringId, syndicateWireId, managerId }: SyndicateWirePathArgs
) => {
  return [
    syndicateWires.getUrlPath({ offeringId }),
    wireTypeName,
    ...[syndicateWireId, managerId].filter(Boolean),
  ].join('/');
};

const syndicateWires = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/syndicate-wires`,
  getDocumentTitle: ({
    issuerName,
    wireName,
  }: {
    issuerName?: string | null;
    wireName?: string | null;
  }) => `${wireName ?? 'Syndicate Wires'} | ${offering.getDocumentTitle({ issuerName })}`,
});

const syndicateWiresUnderwriterInvitation = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/underwriter-invitation/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('underwriter-invitation', args);
  },
});

const syndicateWiresSellingGroupInvitation = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/selling-group-invitation/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('selling-group-invitation', args);
  },
});

const nonSyndicateWiresSellingGroupInvitation = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/non-syndicate-selling-group-invitation/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('non-syndicate-selling-group-invitation', args);
  },
});

const nonSyndicateWiresSellingGroupTermsRetention = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/non-syndicate-selling-group-terms-retention/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('non-syndicate-selling-group-terms-retention', args);
  },
});

const syndicateWiresRegM = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/reg-m/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('reg-m', args);
  },
});

const syndicateWiresPricingTerms = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/pricing-terms/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('pricing-terms', args);
  },
});

const syndicateWiresPricingTermsRetention = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/pricing-terms-retention/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('pricing-terms-retention', args);
  },
});

const syndicateWiresSellingGroupTermsRetention = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/selling-group-terms-retention/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('selling-group-terms-retention', args);
  },
});

const syndicateWiresTermination = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/termination/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('termination', args);
  },
});

const nonSyndicateWiresTermination = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/non-syndicate-termination/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('non-syndicate-termination', args);
  },
});

const syndicateWiresPotAllocation = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/pot-allocation/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('pot-allocation', args);
  },
});

const syndicateWiresPotAccountNames = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/pot-account-names/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('pot-account-names', args);
  },
});

const syndicateWiresPayment = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/payment/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('payment', args);
  },
});

const syndicateWiresManagerBd = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/manager-bd/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('manager-bd', args);
  },
});

const nonSyndicateWiresPayment = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/non-syndicate-payment/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('non-syndicate-payment', args);
  },
});

const syndicateWiresDesignation = createRoute({
  routePath: `/offerings/:offeringId/syndicate-wires/designation/:syndicateWireId?/:managerId?`,
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('designation', args);
  },
});

const syndicateWiresGreenShoe = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/green-shoe/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('green-shoe', args);
  },
});

const syndicateWiresRetention = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/retention/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('retention', args);
  },
});

const syndicateWiresFreeForm = createRoute({
  routePath: '/offerings/:offeringId/syndicate-wires/free-form/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('free-form', args);
  },
});

const nonSyndicateWiresFreeForm = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/non-syndicate-free-form/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('non-syndicate-free-form', args);
  },
});

const syndicateWiresIssuerIndication = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/issuer-indication/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('issuer-indication', args);
  },
});

const syndicateWiresIssuerAllocation = createRoute({
  routePath:
    '/offerings/:offeringId/syndicate-wires/issuer-allocation/:syndicateWireId?/:managerId?',
  getUrlPath: (args: SyndicateWirePathArgs) => {
    return getSyndicateWireUrlPath('issuer-allocation', args);
  },
});

// ----- Regulatory Filings
const regulatoryFilings = createRoute({
  routePath: '/offerings/:offeringId/reg-m',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/reg-m`,
  getDocumentTitle: ({
    issuerName,
    filingName,
  }: {
    issuerName?: string | null;
    filingName?: string | null;
  }) => `${filingName ?? 'RegM'} | ${offering.getDocumentTitle({ issuerName })}`,
});

const regulatoryFilingsRPNFiling = createRoute({
  routePath: '/offerings/:offeringId/reg-m/rpn-filing',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${regulatoryFilings.getUrlPath({ offeringId })}/rpn-filing`,
});
const regulatoryFilingsRPNFilingForm = createRoute({
  routePath: `${regulatoryFilingsRPNFiling.routePath}/create/:filingId?`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId?: UUID }) =>
    `${regulatoryFilingsRPNFiling.getUrlPath({ offeringId })}/create/${filingId ?? ''}`,
});
const regulatoryFilingsRPNFilingDetail = createRoute({
  routePath: `${regulatoryFilingsRPNFiling.routePath}/detail/:filingId`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId: UUID }) =>
    `${regulatoryFilingsRPNFiling.getUrlPath({ offeringId })}/detail/${filingId}`,
});

const regulatoryFilingsTNFiling = createRoute({
  routePath: '/offerings/:offeringId/reg-m/tn-filing',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${regulatoryFilings.getUrlPath({ offeringId })}/tn-filing`,
});
const regulatoryFilingsTNFilingForm = createRoute({
  routePath: `${regulatoryFilingsTNFiling.routePath}/create/:filingId?`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId?: UUID }) =>
    `${regulatoryFilingsTNFiling.getUrlPath({ offeringId })}/create/${filingId ?? ''}`,
});
const regulatoryFilingsTNFilingDetail = createRoute({
  routePath: `${regulatoryFilingsTNFiling.routePath}/detail/:filingId`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId: UUID }) =>
    `${regulatoryFilingsTNFiling.getUrlPath({ offeringId })}/detail/${filingId}`,
});

const regulatoryFilingsUSDTNFiling = createRoute({
  routePath: '/offerings/:offeringId/reg-m/usd-tn-filing',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${regulatoryFilings.getUrlPath({ offeringId })}/usd-tn-filing`,
});
const regulatoryFilingsUSDTNFilingForm = createRoute({
  routePath: `${regulatoryFilingsUSDTNFiling.routePath}/create/:filingId?`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId?: UUID }) =>
    `${regulatoryFilingsUSDTNFiling.getUrlPath({ offeringId })}/create/${filingId ?? ''}`,
});
const regulatoryFilingsUSDTNFilingDetail = createRoute({
  routePath: `${regulatoryFilingsUSDTNFiling.routePath}/detail/:filingId`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId: UUID }) =>
    `${regulatoryFilingsUSDTNFiling.getUrlPath({ offeringId })}/detail/${filingId}`,
});

const regulatoryFilingsNOIFiling = createRoute({
  routePath: '/offerings/:offeringId/reg-m/noi-filing',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${regulatoryFilings.getUrlPath({ offeringId })}/noi-filing`,
});
const regulatoryFilingsNOIFilingForm = createRoute({
  routePath: `${regulatoryFilingsNOIFiling.routePath}/create/:filingId?`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId?: UUID }) =>
    `${regulatoryFilingsNOIFiling.getUrlPath({ offeringId })}/create/${filingId ?? ''}`,
});
const regulatoryFilingsNOIFilingDetail = createRoute({
  routePath: `${regulatoryFilingsNOIFiling.routePath}/detail/:filingId`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId: UUID }) =>
    `${regulatoryFilingsNOIFiling.getUrlPath({ offeringId })}/detail/${filingId}`,
});

const regulatoryFilingsDSFiling = createRoute({
  routePath: '/offerings/:offeringId/reg-m/ds-filing',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${regulatoryFilings.getUrlPath({ offeringId })}/ds-filing`,
});
const regulatoryFilingsDSFilingForm = createRoute({
  routePath: `${regulatoryFilingsDSFiling.routePath}/create/:filingId?`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId?: UUID }) =>
    `${regulatoryFilingsDSFiling.getUrlPath({ offeringId })}/create/${filingId ?? ''}`,
});
const regulatoryFilingsDSFilingDetail = createRoute({
  routePath: `${regulatoryFilingsDSFiling.routePath}/detail/:filingId`,
  getUrlPath: ({ offeringId, filingId }: { offeringId: UUID; filingId: UUID }) =>
    `${regulatoryFilingsDSFiling.getUrlPath({ offeringId })}/detail/${filingId}`,
});

// ----- Order Book
const orderBook = createRoute({
  routePath: '/offerings/:offeringId/order-book',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/order-book`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Order Book | ${offering.getDocumentTitle({ issuerName })}`,
});

const orderBookInstitutionalDemand = createRoute({
  routePath: '/offerings/:offeringId/order-book/institutional-demand',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/order-book/institutional-demand`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Institutional Demand | ${offering.getDocumentTitle({ issuerName })}`,
});

const orderBookInstitutionalDemandRecentUpdates = createRoute({
  routePath: '/offerings/:offeringId/order-book/institutional-demand/recent-updates',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/order-book/institutional-demand/recent-updates`,
});

const orderBookInstitutionalDemandPendingUpdates = createRoute({
  routePath: '/offerings/:offeringId/order-book/institutional-demand/pending-updates',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/order-book/institutional-demand/pending-updates`,
});

const orderBookDspDemand = createRoute({
  routePath: '/offerings/:offeringId/order-book/dsp-demand',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/order-book/dsp-demand`,
});

const orderBookTotalRetailDemand = createRoute({
  routePath: `${orderBook.routePath}/total-retail-demand`,
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${orderBook.getUrlPath({ offeringId })}/total-retail-demand`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Total Retail Demand | ${offering.getDocumentTitle({ issuerName })}`,
});

const orderBookTotalRetailDemandHistory = createRoute({
  routePath: `${orderBookTotalRetailDemand.routePath}/:cmgEntityKey`,
  getUrlPath: ({ offeringId, cmgEntityKey }: { offeringId: UUID; cmgEntityKey: UUID }) =>
    `${orderBookTotalRetailDemand.getUrlPath({ offeringId })}/${cmgEntityKey}`,
});

const orderBookMyRetailDemand = createRoute({
  routePath: `${orderBook.routePath}/my-retail-demand`,
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${orderBook.getUrlPath({ offeringId })}/my-retail-demand`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `My Retail Demand | ${offering.getDocumentTitle({ issuerName })}`,
});

// Final Settlement
const finalSettlement = createRoute({
  routePath: '/offerings/:offeringId/final-settlement',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/final-settlement`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Final Settlement | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementDesignationMonitor = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/designation-monitor',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/designation-monitor`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Designation Monitor | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementDesignationMonitorOverwrite = createRoute({
  routePath:
    '/offerings/:offeringId/final-settlement/designation-monitor/overwrite-designation/:indicationId',
  getUrlPath: ({ offeringId, indicationId }: { offeringId: UUID; indicationId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/designation-monitor/overwrite-designation/${indicationId}`,
});

const finalSettlementExpenses = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/expenses',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/expenses`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Expenses | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementManagerExpenses = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/expenses/manager-expenses',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/expenses/manager-expenses`,
});

const finalSettlementDealRelatedExpenses = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/expenses/deal-related-expenses',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/expenses/deal-related-expenses`,
});

const finalSettlementStabilization = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/stabilization',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/stabilization`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Stabilization | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementStabilizationBuy = createRoute({
  routePath: `${finalSettlementStabilization.routePath}/buy`,
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${finalSettlementStabilization.getUrlPath({ offeringId })}/buy`,
});

const finalSettlementStabilizationSell = createRoute({
  routePath: `${finalSettlementStabilization.routePath}/sell`,
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${finalSettlementStabilization.getUrlPath({ offeringId })}/sell`,
});

const finalSettlementAccounting = createRoute({
  routePath: `${finalSettlement.routePath}/accounting`,
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `${finalSettlement.getUrlPath({ offeringId })}/accounting`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Accounting | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementSettlementLetters = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/settlement-letters',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/final-settlement/settlement-letters`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Settlement Letters | ${offering.getDocumentTitle({ issuerName })}`,
});

const finalSettlementSettlementLetterForManager = createRoute({
  routePath: '/offerings/:offeringId/final-settlement/settlement-letters/:managerId',
  getUrlPath: ({ offeringId, managerId }: { offeringId: UUID; managerId: string }) =>
    `/offerings/${offeringId}/final-settlement/settlement-letters/${managerId}`,
});

// Sales Credits
const salesCredits = createRoute({
  routePath: '/offerings/:offeringId/sales-credits',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/sales-credits`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Sales Credits | ${offering.getDocumentTitle({ issuerName })}`,
});

const salesCreditsHistory = createRoute({
  routePath: `${salesCredits.routePath}/history/:investorFirmCmgEntityKey`,
  getUrlPath: ({
    offeringId,
    investorFirmCmgEntityKey,
  }: {
    offeringId: UUID;
    investorFirmCmgEntityKey: UUID;
  }) => `${salesCredits.getUrlPath({ offeringId })}/history/${investorFirmCmgEntityKey}`,
});

// Trade release
const tradeRelease = createRoute({
  routePath: '/offerings/:offeringId/trade-release',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) => `/offerings/${offeringId}/trade-release`,
  getDocumentTitle: ({ issuerName }: { issuerName?: string | null }) =>
    `Trade Release | ${offering.getDocumentTitle({ issuerName })}`,
});

const tradeReleaseHistory = createRoute({
  routePath: '/offerings/:offeringId/trade-release/history/:indicationId',
  getUrlPath: ({ offeringId, indicationId }: { offeringId: UUID; indicationId: UUID }) =>
    `/offerings/${offeringId}/trade-release/history/${indicationId}`,
});

const certificateLibrary = createRoute({
  routePath: '/certificates',
  getUrlPath: ({ searchParams }: { searchParams?: URLSearchParams } = {}) =>
    `/certificates${getSearchString(searchParams)}`,
  getDocumentTitle: () => 'Certificate Library | XC',
});

const certificateLibraryFirmSidePanel = createRoute({
  routePath: `${certificateLibrary.routePath}/side-panel`,
  getUrlPath: () => `${certificateLibrary.getUrlPath()}/side-panel`,
});

const certificateLibraryFirmCurrentCertificates = createRoute({
  routePath: `${certificateLibraryFirmSidePanel.routePath}/current`,
  getUrlPath: ({ searchParams }: { searchParams: URLSearchParams }) =>
    `${certificateLibraryFirmSidePanel.getUrlPath()}/current${getSearchString(searchParams)}`,
  getDocumentTitle: ({ firmName }: { firmName?: string | null }) =>
    `${firmName ?? 'Firm'} | Current Certificates | XC`,
});

const certificateLibraryFirmPastCertificates = createRoute({
  routePath: `${certificateLibraryFirmSidePanel.routePath}/past`,
  getUrlPath: ({ searchParams }: { searchParams: URLSearchParams }) =>
    `${certificateLibraryFirmSidePanel.getUrlPath()}/past${getSearchString(searchParams)}`,
  getDocumentTitle: ({ firmName }: { firmName?: string | null }) =>
    `${firmName ?? 'Firm'} | Past Certificates | XC`,
});

// ----- Error Page
const error = createRoute({
  routePath: '/error',
  getUrlPath: ({ errorCode }: { errorCode?: apiTypes.ServiceErrorCode }) =>
    `/error${urlUtil.queryStringify({ errorCode })}`,
});

// ----- Documentation (Temporary until Document360 can host this content)
const browserNotificationDocumentation = createRoute({
  routePath: '/documentation/browser-notifications',
  getUrlPath: () => '/documentation/browser-notifications',
});

/**
 * Instead of hardcoding SPA urls throughout the application this factory object provides
 * a configuration key for react-router <Route/> along with a method to generate each of
 * the urls for use in <a/> and <Link/> components.
 */
const routes = {
  root,
  oidcLogin,
  oidcLoginCallback,
  oidcLogout,
  oidcLogoutCallback,
  loggedOut,
  calendar,
  calendarFiled,
  calendarLive,
  calendarPostponed,
  calendarPriced,
  offeringSidePanel,
  myOfferings,
  myOfferingsOfferings,
  myOfferingsDrafts,
  myOfferingsSharedDrafts,
  myOrders,
  offeringCreate,
  offering,
  offeringSetup,
  offeringDetails,
  sharedDraftOffering,
  roadshows,
  roadshowsList,
  roadshowsCompanies,
  roadshowsDealTeams,
  roadshowsCreateDealTeam,
  roadshowsDealTeamDetails,
  roadshowCreate,
  roadshowDetails,
  roadshowCompanyDetails,
  roadshowDealTeamDetails,
  roadshowCalendar,
  roadshowCreateMeeting,
  roadshowMeetingDetails,
  roadshowCreateLogistics,
  roadshowLogisticsDetails,
  syndicateWires,
  syndicateWiresUnderwriterInvitation,
  syndicateWiresInvitationWireResponse,
  syndicateWiresSellingGroupInvitation,
  nonSyndicateWiresFreeForm,
  nonSyndicateWiresPayment,
  nonSyndicateWiresSellingGroupInvitation,
  nonSyndicateWiresSellingGroupTermsRetention,
  nonSyndicateWiresTermination,
  syndicateWiresRegM,
  syndicateWiresPricingTerms,
  syndicateWiresPricingTermsRetention,
  syndicateWiresSellingGroupTermsRetention,
  syndicateWiresTermination,
  syndicateWiresPotAllocation,
  syndicateWiresPotAccountNames,
  syndicateWiresPayment,
  syndicateWiresManagerBd,
  syndicateWiresDesignation,
  syndicateWiresGreenShoe,
  syndicateWiresRetention,
  syndicateWiresFreeForm,
  syndicateWiresIssuerAllocation,
  syndicateWiresIssuerIndication,
  regulatoryFilings,
  regulatoryFilingsRPNFiling,
  regulatoryFilingsRPNFilingDetail,
  regulatoryFilingsRPNFilingForm,
  regulatoryFilingsTNFiling,
  regulatoryFilingsTNFilingDetail,
  regulatoryFilingsTNFilingForm,
  regulatoryFilingsUSDTNFiling,
  regulatoryFilingsUSDTNFilingDetail,
  regulatoryFilingsUSDTNFilingForm,
  regulatoryFilingsNOIFiling,
  regulatoryFilingsNOIFilingDetail,
  regulatoryFilingsNOIFilingForm,
  regulatoryFilingsDSFiling,
  regulatoryFilingsDSFilingDetail,
  regulatoryFilingsDSFilingForm,
  orderBook,
  orderBookDspDemand,
  orderBookInstitutionalDemand,
  orderBookInstitutionalDemandRecentUpdates,
  orderBookInstitutionalDemandPendingUpdates,
  orderBookTotalRetailDemand,
  orderBookTotalRetailDemandHistory,
  orderBookMyRetailDemand,
  finalSettlement,
  finalSettlementDesignationMonitor,
  finalSettlementDesignationMonitorOverwrite,
  finalSettlementExpenses,
  finalSettlementManagerExpenses,
  finalSettlementDealRelatedExpenses,
  finalSettlementStabilization,
  finalSettlementStabilizationBuy,
  finalSettlementStabilizationSell,
  finalSettlementAccounting,
  finalSettlementSettlementLetters,
  finalSettlementSettlementLetterForManager,
  salesCredits,
  salesCreditsHistory,
  tradeRelease,
  tradeReleaseHistory,
  certificateLibrary,
  certificateLibraryFirmSidePanel,
  certificateLibraryFirmCurrentCertificates,
  certificateLibraryFirmPastCertificates,
  error,
  browserNotificationDocumentation,
};

export type RoutePath = string | string[];
export const checkRouteIsActive = (
  routePath: RoutePath,
  pathname: string,
  options?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions
) =>
  match(
    [...(Array.isArray(routePath) ? routePath : [routePath])].map(path =>
      path.replace('*', '(.*)')
    ),
    options ?? { start: true, end: false }
  )(pathname) !== false;

type ActiveRoutePathConfig = {
  readonly routePath: RoutePath;
  readonly options?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions;
};
type RouteConfig = RoutePath | ActiveRoutePathConfig;
export const findActiveRouteGroup = (pathname: string, groups: Record<string, RouteConfig>) =>
  Object.entries(groups).find(([, routeConfig]) =>
    typeof routeConfig === 'string' || Array.isArray(routeConfig)
      ? checkRouteIsActive(routeConfig, pathname)
      : checkRouteIsActive(routeConfig.routePath, pathname, routeConfig.options)
  );

export default routes;
