import styled from 'styled-components/macro';

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  margin: 8px 0;
  line-height: 16px;
`;

export const SDetails = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
`;
