import { getFeatureToggles } from '../../../../../../common/config/appSettings';
import {
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables,
  useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
} from '../graphql';

export type InvestorsExtendedDataQuery = InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery;

export const useInvestorsExtendedDataQuery = (
  variables: InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
) => {
  const { isOrderBookDynamicColumnsOn } = getFeatureToggles();

  return useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery({
    variables,
    skip: !isOrderBookDynamicColumnsOn,
  });
};
