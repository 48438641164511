import React from 'react';

import Header from '../../../common/components/header/Header';
import { Roadshows_RoadshowPartsFragment } from '../../../common/graphql';
import { RoadshowTypes } from '../../../overview/all-roadshows/grid/RoadshowsListDataGrid.model';

export type Props = { roadshow?: Roadshows_RoadshowPartsFragment };

const RoadshowHeader: React.FC<Props> = ({ roadshow, children }) => {
  if (roadshow) {
    return (
      <Header>
        <Header.Title>{roadshow.roadshowName}</Header.Title>
        <Header.Label>ROADSHOW TYPE</Header.Label>
        <Header.Text>{RoadshowTypes[roadshow.roadshowType]}</Header.Text>
        {roadshow.offeringIssuerName && <Header.Label>LINKED OFFERING</Header.Label>}
        {roadshow.offeringIssuerName && <Header.Text>{roadshow.offeringIssuerName}</Header.Text>}
        <Header.EmptyContent />
        {children}
      </Header>
    );
  } else {
    return (
      <Header>
        <Header.Title>New Roadshow</Header.Title>
        <Header.EmptyContent />
        {children}
      </Header>
    );
  }
};

export default RoadshowHeader;
