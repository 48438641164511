import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

import Loading from '../loading/Loading';

type LoadingStyleVariant = 'TRANSPARENT' | 'LIGHT' | 'WHITE_TRANSLUCENT';

const variantToColor: { [variant in LoadingStyleVariant]: (theme: DefaultTheme) => string } = {
  LIGHT: theme => theme.background.color.light,
  TRANSPARENT: () => 'transparent',
  WHITE_TRANSLUCENT: theme => `${theme.background.color.white}b3`,
};

export const SLoadingWrapper = styled.div<{ variant?: LoadingStyleVariant }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.background.color.dark};
  background-color: ${({ theme, variant = 'TRANSPARENT' }) => variantToColor[variant](theme)};
  z-index: 2;
`;

type Props = {
  message?: string;
  variant?: LoadingStyleVariant;
  size?: React.ComponentProps<typeof Loading>['size'];
};

const LoadingOverlay: React.FC<Props> = ({ message, variant, size }) => (
  <SLoadingWrapper variant={variant} data-test-id="loading-overlay">
    <Loading size={size ?? 'large'} />
    {message && <h3>{message}</h3>}
  </SLoadingWrapper>
);

export default LoadingOverlay;
