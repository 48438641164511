import { Stack } from '@cmg/design-system';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import offeringSidePanelSellSideRouteFactory from '../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import type { OrderBook_InstitutionalDemand_RowAlertPartsFragment } from '../../../graphql/__generated__';
import { useGetSidePanelPath } from '../../hooks/useGetSidePanelPath';
import type { DemandGridCustomTooltipProps } from '../../types';
import { alertCodesMap } from '../columns.model';
import { AlertGroup } from './AlertGroup';
import { getAlertGroups } from './TooltipContent.model';

export type Props = DemandGridCustomTooltipProps<{
  alertCodes: OrderBook_InstitutionalDemand_RowAlertPartsFragment['alertCodes'];
}>;

export const TooltipContentComponent = ({ value, data, hideTooltipCallback }: Props) => {
  const alertCodes = useMemo(() => value?.alertCodes ?? [], [value?.alertCodes]);

  const [complianceAlerts, deliveryAlerts, acknowledgementsAlerts, allocationAlerts] = useMemo(
    () => getAlertGroups(alertCodes),
    [alertCodes]
  );

  const { cmgEntityKey } = data?.investor ?? {};
  const complianceUrl = cmgEntityKey
    ? offeringSidePanelSellSideRouteFactory.orderBookCompliance.getUrlPath({
        cmgEntityKey,
      })
    : '';

  const complianceDetailsPath = useGetSidePanelPath({
    sidePanelRoutePath: complianceUrl,
  });

  if (!alertCodes.length) {
    return null;
  }

  return (
    <Stack width={200} gap={2}>
      <AlertGroup
        alertMessages={complianceAlerts}
        title={
          <React.Fragment>
            Compliance{' '}
            <Link
              to={complianceDetailsPath}
              onClick={() => {
                hideTooltipCallback?.();
              }}
            >
              View details
            </Link>
          </React.Fragment>
        }
      />
      <AlertGroup alertMessages={deliveryAlerts} title="B&D Agent" />
      <AlertGroup alertMessages={acknowledgementsAlerts} title="IOI Acknowledgements" />
      <AlertGroup alertMessages={allocationAlerts} title="Allocation" />
    </Stack>
  );
};

export const getRowTooltipContent = ({
  value,
}: DemandGridCustomTooltipProps<OrderBook_InstitutionalDemand_RowAlertPartsFragment>) => {
  const alertCodes = value?.alertCodes ?? [];
  if (alertCodes.length === 0) {
    return undefined;
  }
  return (props: Props) => <TooltipContentComponent {...props} value={{ alertCodes }} />;
};

export const getCellTooltipContent = ({ colDef, data }: DemandGridCustomTooltipProps<unknown>) => {
  if (!data || !colDef || !('field' in colDef)) {
    return undefined;
  }
  const alertCodes =
    data?.alert.alertCodes?.filter(code => alertCodesMap[code].field === colDef.field) ?? [];
  if (alertCodes.length === 0) {
    return undefined;
  }
  return (props: Props) => <TooltipContentComponent {...props} value={{ alertCodes }} />;
};
