import { UUID } from '@cmg/common';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Loading from '../../../common/components/indicators/loading/Loading';
import NotAccessible from '../../../common/components/layout/not-accessible/NotAccessible';
import { useFeatureToggles } from '../../../common/config';
import { useCheckOrderBookAccess } from '../../../common/util/check-access/useCheckOrderBookAccess';
import offeringSidePanelSellSideRouteFactory from '../offeringSidePanelSellSideRouteFactory';
import ComplianceRoute from './compliance/ComplianceRoute';
import IndicationActivitySellSideRoute from './indication-activity/IndicationActivitySellSideRoute';
import UpdateHistoryRoute from './update-history/UpdateHistoryRoute';

type RouteProps = RouteComponentProps<{ offeringId: UUID }>;
export type Props = RouteProps;

/**
 * Side Panel routes for the Sell Side sections of the
 * Order Book module
 */
const OrderBookSellSideRoute: React.FC<Props> = ({ match }) => {
  const { isOrderBookAttestationOn } = useFeatureToggles();
  const { canRead, loading: accessLoading } = useCheckOrderBookAccess({
    offeringId: match.params.offeringId,
  });

  if (accessLoading) {
    return <Loading />;
  }

  if (!canRead) {
    return <NotAccessible subject="Order Book" />;
  }

  return (
    <Switch>
      <Route
        path={offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.routePath}
        component={IndicationActivitySellSideRoute}
      />
      <Route
        path={offeringSidePanelSellSideRouteFactory.orderBookUpdateHistory.routePath}
        component={UpdateHistoryRoute}
      />
      {isOrderBookAttestationOn && (
        <Route
          path={offeringSidePanelSellSideRouteFactory.orderBookCompliance.routePath}
          component={ComplianceRoute}
        />
      )}
      <Redirect
        to={offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath({})}
      />
    </Switch>
  );
};

export default OrderBookSellSideRoute;
