import { AgGridApi, UUID } from '@cmg/common';
import React from 'react';

import { TradeReleaseGridStatusTransition } from '../../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../../graphql/__generated__';
import { useGridActions } from './useGridActions';
import { unselectAllRows } from './useGridActions.model';

export const useBulkGridActions = (
  offeringId: UUID,
  gridApi: AgGridApi | undefined,
  setUnchangedTradesCount: (unchangedTrades: number) => void
) => {
  const {
    executeCancelRelease,
    executeCancelManually,
    executeReleaseTrades,
    executeMarkAsBooked,
    ...rest
  } = useGridActions(offeringId, gridApi, true);

  const bulkAction = React.useCallback(
    (
        transition: TradeReleaseGridStatusTransition,
        actionFn: (selectedRows: TradeRelease_TradeGridItemFragment[]) => Promise<void>
      ) =>
      async (selectedRows: TradeRelease_TradeGridItemFragment[]) => {
        const allowedRows = selectedRows.filter(({ allowedStatusTransitions }) =>
          allowedStatusTransitions.includes(transition)
        );

        if (allowedRows.length > 0) {
          await actionFn(allowedRows);
        } else {
          unselectAllRows(gridApi);
        }

        setUnchangedTradesCount(selectedRows.length - allowedRows.length);
      },
    [gridApi, setUnchangedTradesCount]
  );

  return {
    ...rest,
    executeCancelRelease: bulkAction(
      TradeReleaseGridStatusTransition.CancelPending,
      executeCancelRelease
    ),
    executeCancelManually: bulkAction(
      TradeReleaseGridStatusTransition.CancelledManual,
      executeCancelManually
    ),
    executeReleaseTrades: bulkAction(
      TradeReleaseGridStatusTransition.Released,
      executeReleaseTrades
    ),
    executeMarkAsBooked: bulkAction(
      TradeReleaseGridStatusTransition.BookedManual,
      executeMarkAsBooked
    ),
  };
};
