import { FormLabel, MultiSelect, Option } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { OfferingStatus } from '../../../../../../graphql';
import { offeringStatusLabels } from '../../../../../../types/domain/offering/constants';

export const SOfferingStatusFilter = styled.div`
  width: 280px;
  margin: 0 20px;
`;

export type Props = {
  offeringStatuses: OfferingStatus[];
  onChange: (value: OfferingStatus[]) => void;
  values: OfferingStatus[];
};

const OfferingStatusFilter: React.FC<Props> = ({ offeringStatuses, onChange, values }) => {
  const options = offeringStatuses.map<Option<OfferingStatus>>(type => ({
    label: offeringStatusLabels[type],
    value: type,
  }));

  return (
    <SOfferingStatusFilter>
      <FormLabel>Offering Status</FormLabel>
      <MultiSelect<OfferingStatus>
        options={options}
        onChange={onChange}
        value={values}
        maxDisplayedValues={2}
      />
    </SOfferingStatusFilter>
  );
};

export default OfferingStatusFilter;
