import { ColDef } from 'ag-grid-community';
import groupBy from 'lodash/groupBy';
import React from 'react';

import {
  IndicationStatus,
  InvestorDeliveryStatus,
  OfferingType,
  ProspectusDeliveryStatus,
} from '../../../../graphql';
import { isAttestationStatusValid } from '../../../order-book/attestation/Attestation.model';
import {
  MyOrders_CoveredIndications_CoveredInstitutionalIndicationPartsFragment,
  MyOrders_LiveOfferingsList_OfferingLimitedPartsFragment,
  MyOrders_PricedOfferingsList_OfferingLimitedPartsFragment,
} from '../../graphql';

export type PricedAndLiveOfferings = MyOrders_PricedOfferingsList_OfferingLimitedPartsFragment &
  MyOrders_LiveOfferingsList_OfferingLimitedPartsFragment;

export type MyOrdersGridCoveredIndication =
  MyOrders_CoveredIndications_CoveredInstitutionalIndicationPartsFragment & {
    issuer: PricedAndLiveOfferings['issuerName'];
    offeringType: PricedAndLiveOfferings['type'];
    offeringStatus: PricedAndLiveOfferings['status'];
    pricingDate: PricedAndLiveOfferings['pricingDate'];
    offeringPrice: PricedAndLiveOfferings['finalFilingOfferPrice'];
    pricingCurrencyCode: PricedAndLiveOfferings['pricingCurrencyCode'];
    pricingInstrumentCountryCode: PricedAndLiveOfferings['pricingInstrumentCountryCode'];
    hasAlerts?: boolean;
    prospectusStatus?: InvestorDeliveryStatus;
  };

export type MyOrdersGridContext = {
  matchUrl: string;
  offerings: { [key: string]: PricedAndLiveOfferings };
  investorsWithInvalidCompliance: string[];
};

export type MyOrdersGridRendererProps<
  TIndication extends MyOrdersGridCoveredIndication = MyOrdersGridCoveredIndication
> = {
  data: TIndication;
  value: TIndication[keyof TIndication];
  context: MyOrdersGridContext;
};

type ColDefReplacementFields<
  TIndication extends MyOrdersGridCoveredIndication = MyOrdersGridCoveredIndication
> = {
  cellRendererFramework?: React.ComponentType<MyOrdersGridRendererProps<TIndication>>;
  cellRendererParams?: (props: MyOrdersGridRendererProps<TIndication>) => object;
  equals?: (dataA: TIndication, dataB: TIndication) => boolean;
};

type CoveredIndicationToValidate = Partial<
  Pick<
    MyOrdersGridCoveredIndication,
    'offeringType' | 'status' | 'coveredIndicationAttestationStatus' | 'prospectusStatus'
  >
>;

export type ColDefMyOrdersGrid<
  TIndication extends MyOrdersGridCoveredIndication = MyOrdersGridCoveredIndication
> = Omit<ColDef, keyof ColDefReplacementFields> & ColDefReplacementFields<TIndication>;

export function isProspectusInvalid(indication: CoveredIndicationToValidate) {
  return (
    !!indication.prospectusStatus &&
    indication.prospectusStatus.overallDeliveryStatus === ProspectusDeliveryStatus.Failed
  );
}

export function isIndicationAttestationInvalid(indication: CoveredIndicationToValidate) {
  return (
    indication.offeringType === OfferingType.Ipo &&
    indication.status === IndicationStatus.Active &&
    !isAttestationStatusValid(indication.coveredIndicationAttestationStatus!)
  );
}

export function checkIndicationHasError(indication: CoveredIndicationToValidate) {
  if (isProspectusInvalid(indication)) {
    return true;
  }
  return isIndicationAttestationInvalid(indication);
}

export function getInvestorsWithInvalidCompliance({
  filteredData,
}: {
  filteredData: readonly MyOrdersGridCoveredIndication[];
}) {
  return Object.keys(
    groupBy(
      filteredData.filter(indication => checkIndicationHasError(indication)),
      'investorInformation.cmgEntityKey'
    )
  );
}

export const defaultPollingInterval = 5_000;

export const myOrdersSecondaryErrorPrefixMap = {
  useMyOrdersGridProspectusDeliverySummariesLazyQuery: 'Unable to load Prospectus Status',
};

export type MyOrdersGridCellProps = {
  context: MyOrdersGridContext;
  data: MyOrdersGridCoveredIndication;
};
