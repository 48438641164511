import { useMemo } from 'react';

import { useWireTypeConfigContext } from '../../../../../context/WireTypeConfigContext';
import { WireFormBannerVariant } from '../WireFormBanner.model';

export type UseWireFormBannerProps = Readonly<{
  readonly variant: WireFormBannerVariant;
  readonly messageAppendix?: string;
}>;

export type UseWireFormBannerResult = Readonly<{
  readonly message: string;
}>;

export function useWireFormBanner({
  variant,
  messageAppendix,
}: UseWireFormBannerProps): UseWireFormBannerResult {
  const { wireTypeName, wireTypeCategory } = useWireTypeConfigContext();

  const messagePrefix = useMemo(() => {
    switch (variant) {
      case 'common':
        return `The information entered below will be applied to every ${wireTypeName} ${wireTypeCategory} sent to each recipient.`;
      case 'manager-specific-readonly':
        return 'The information below will be applied to each recipient individually.';
      case 'manager-specific-input':
        return 'The information below will be applied to each recipient individually. Fill in the information for each recipient in order to send the wire.';
      case 'report-emails':
        return 'Insert emails to add recipients.';
    }
  }, [variant, wireTypeCategory, wireTypeName]);

  const message = useMemo(() => {
    return messageAppendix ? `${messagePrefix} ${messageAppendix}` : messagePrefix;
  }, [messagePrefix, messageAppendix]);

  return {
    message,
  };
}
