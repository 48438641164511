import {
  ButtonsContainer,
  Modal,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { AgGridEvent } from 'ag-grid-community';
import React, { useRef } from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useGridReady } from '../../../../../../common/hooks/useGridReady';
import { useFirmKeyOrIdContext } from '../../../../contexts/FirmKeyOrIdContext';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import CrmSelectGrid from './components/CrmSelectGrid';
import { CrmSelectModalHeader } from './components/CrmSelectModalHeader';
import { CrmSelectionRow, useGetCrmModel } from './CrmSelectModal.model';
import { SFooterContainer, SModalBodyContainer, STitleContainer } from './CrmSelectModal.styles';
import { useCrmSelection_AssignInvestorKeyMutation } from './graphql/__generated__';
import { useAssignCoveredInvestorKeyMutation } from './hooks/useCoveredAssignInvestorKeyMutation';

type OwnProps = {
  offeringId?: UUID;
  indicationId?: UUID;
  cmgEntityKey?: string;
  cmgEntityName?: string | null;
  firmKey?: string | null;
  duplicateIndicationIds?: readonly string[];
};

export type Props = OwnProps & InjectedProps;

export const CrmSelectModal: React.FC<Props> = ({
  handleHide,
  offeringId,
  indicationId,
  cmgEntityKey,
  firmKey,
  duplicateIndicationIds,
}) => {
  const { type: personaType } = useIdentifyOrderBookPersona({ offeringId });
  const isSyndicate = personaType === OrderBookPersonaType.SYNDICATE;
  const isSalesAndTrading = personaType === OrderBookPersonaType.SALES_AND_TRADING;

  const {
    data: rows,
    loading: loadingCrmModel,
    error: getCrmError,
  } = useGetCrmModel({ cmgEntityKey, offeringId, indicationId, duplicateIndicationIds });

  const gridRef = useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
  const { setFirmKeyOrId } = useFirmKeyOrIdContext();
  const [assignInvestorKey, { loading: assignInvestorKeyLoading, error: assignInvestorKeyError }] =
    useCrmSelection_AssignInvestorKeyMutation();

  const [
    assignCoveredInvestorKey,
    { loading: assignCoveredInvestorKeyLoading, error: assignCoveredInvestorKeyError },
  ] = useAssignCoveredInvestorKeyMutation();

  const onSave = async () => {
    const selectedRows = (gridRef.current?.api.getSelectedRows() as CrmSelectionRow[]) ?? [];
    const selectedRow = selectedRows.length > 0 ? selectedRows[0] : null;
    if (!selectedRow || !indicationId || !offeringId || !(isSyndicate || isSalesAndTrading)) {
      return;
    }
    isSyndicate &&
      (await assignInvestorKey({
        variables: {
          offeringId: offeringId,
          indicationId: indicationId,
          investorCrmKey: selectedRow.id,
        },
      }));
    isSalesAndTrading &&
      (await assignCoveredInvestorKey({
        variables: {
          offeringId: offeringId,
          indicationId: indicationId,
          investorCrmKey: selectedRow.id,
        },
      }));

    setFirmKeyOrId(selectedRow.id);
    ToastManager.success('Successfully selected investor key');
    handleHide();
  };

  const loading = !!(
    loadingCrmModel ||
    assignInvestorKeyLoading ||
    assignCoveredInvestorKeyLoading
  );
  const error = getCrmError ?? assignInvestorKeyError ?? assignCoveredInvestorKeyError;

  return (
    <Modal
      show
      title={<STitleContainer>Select CRM Bank Investor Key</STitleContainer>}
      onHide={handleHide}
      closeButton
      shouldCloseOnOverlayClick
      size="large"
      footer={
        <SFooterContainer>
          <ButtonsContainer justifyContent="right" margin={16}>
            <SecondaryButton onClick={handleHide}>Close</SecondaryButton>
            <PrimaryButton onClick={onSave} disabled={isSaveDisabled || loading}>
              Save
            </PrimaryButton>
          </ButtonsContainer>
        </SFooterContainer>
      }
    >
      <SModalBodyContainer>
        <CrmSelectModalHeader />
        {error && <ServerErrorsBanner error={error!} />}
        {loading && <Loading />}
        {!loading && (
          <CrmSelectGrid
            data-test-id="crm-select-grid"
            isLoading={loadingCrmModel}
            firmKey={firmKey}
            rows={rows}
            onGridReady={onGridReady}
            onChange={(_firmKey: string) => setIsSaveDisabled(_firmKey === firmKey)}
          />
        )}
      </SModalBodyContainer>
    </Modal>
  );
};

const name = 'ORDERBOOK/CRM_SELECTION_SIDEPANEL_MODAL';

export const openCrmSelectionModal = (props: OwnProps) => show(name, props);
export const closeCrmSelectionModal = () => hide(name);

export default connectModal({ name })(CrmSelectModal) as React.ComponentClass<OwnProps>;
