import { Alert, Panel, SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory } from 'react-router';

import { getFeatureToggles } from '../../../../common/config/appSettings';
import routeFactory from '../../../../common/util/routeFactory';
import RoadshowsSubNavigation from '../common/roadshows-sub-navigation/RoadshowsSubNavigation';
import { SContent } from './AllRoadshowsDealTeamsRoute.styles';
import DealTeamListDataGrid from './deal-team-list-data-grid/DealTeamListDataGrid';

const AllRoadshowsDealTeamsRoute: React.FC = () => {
  const { isRoadshowsDealTeamsOverviewOn } = getFeatureToggles();
  const { push } = useHistory();
  return (
    <Panel fillViewport testId="roadshows / overview / all-deal-teams">
      <RoadshowsSubNavigation>
        {isRoadshowsDealTeamsOverviewOn && (
          <SuccessButton
            onClick={() => push(routeFactory.roadshowsCreateDealTeam.getUrlPath())}
            testId={xcSelectors.roadshowDealTeamsConfigCreateDealTeamButton.testId}
          >
            Create Deal Team
          </SuccessButton>
        )}
      </RoadshowsSubNavigation>
      <SContent>
        {isRoadshowsDealTeamsOverviewOn ? (
          <DealTeamListDataGrid />
        ) : (
          <Alert severity="info" testId={xcSelectors.roadshowDealTeamsComingSoonBanner.testId}>
            Coming soon
          </Alert>
        )}
      </SContent>
    </Panel>
  );
};

export default AllRoadshowsDealTeamsRoute;
