import { ODSTable as Table } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledTotalRow = styled(Table.Row)`
  & > div {
    background-color: ${({ theme }) => theme.designSystem.colors.darkBlue[300]};
    color: ${({ theme }) => theme.text.color.white};
    pointer-events: none;
  }
`;
