import { Icon, Popover, SelectField, TextInputField } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Column from '../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import RoadshowOfferingSearch from './roadshow-offering-search/RoadshowOfferingSearch';
import { getRoadshowTypesOptions } from './RoadshowInformation.model';
import { SRelatedOfferingPopover } from './RoadshowInformation.styles';

const RoadshowInformation: React.FC = () => {
  const roadshowTypeOptions = React.useMemo(() => getRoadshowTypesOptions(), []);
  const theme = useTheme();

  return (
    <Panel>
      <Panel.Header title="Roadshow Information" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            <TextInputField
              aria-label="Roadshow Name"
              label="Roadshow Name"
              name="roadshowName"
              withMargin
              fullWidth
              required
            />
          </Column>
          <Column span={3}>
            <SelectField
              label="Roadshow Type"
              name="roadshowType"
              options={roadshowTypeOptions}
              isClearable={false}
              withMargin
              fullWidth
            />
          </Column>
          <Column span={3}>
            <RoadshowOfferingSearch
              label={
                <Popover
                  content={
                    <SRelatedOfferingPopover>
                      Link Roadshow to Offering on CMG Calendar
                    </SRelatedOfferingPopover>
                  }
                  variant="DARK"
                  placement="top"
                >
                  <span>Related offering </span>
                  <span>
                    <Icon name="info-circle" variant="solid" color={theme.text.color.darkGray} />
                  </span>
                </Popover>
              }
              name="relatedOfferingField"
              isClearable
              withMargin
              fullWidth
            />
          </Column>
        </GridLayout>
      </Panel.Content>
    </Panel>
  );
};

export default RoadshowInformation;
