import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import Banner from '../../../../common/components/indicators/banner/Banner';
import Loading from '../../../../common/components/indicators/loading/Loading';
import routeFactory from '../../../../common/util/routeFactory';
import { getLoadFilingsErrorMessage } from '../../common/filing-name-utils';
import { getLatestFiling } from '../../RegulatoryFilingsRoute.model';
import { useRegulatoryFilings_DsFilingsQuery } from './graphql';

export type Props = RouteComponentProps<{ offeringId: UUID }>;

const DSFilingRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const { data, loading, error } = useRegulatoryFilings_DsFilingsQuery({
    variables: { offeringId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Banner variant="error">{getLoadFilingsErrorMessage('DS')}</Banner>;
  }

  if (data && data.offering.regulatoryFilings.DSFilings.length > 0) {
    const latestFiling = getLatestFiling(data.offering.regulatoryFilings.DSFilings);
    const filingDetailUrl = routeFactory.regulatoryFilingsDSFilingDetail.getUrlPath({
      offeringId,
      filingId: latestFiling.id,
    });
    return <Redirect to={filingDetailUrl} />;
  }

  const filingFormUrl = routeFactory.regulatoryFilingsDSFilingForm.getUrlPath({ offeringId });
  return <Redirect to={filingFormUrl} />;
};

export default DSFilingRoute;
