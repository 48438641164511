import React, { VFC } from 'react';

import BrokerDealerEconomicsTable from '../../common/components/broker-dealer-economics/BrokerDealerEconomicsTable';
import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';

export const NonSyndicateSellingGroupTermsRetentionWireForm: VFC<CreateOrUpdateFormProps> = ({
  offeringId,
  operationType,
  managers,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <BrokerDealerEconomicsTable
          offeringId={offeringId}
          managers={managers}
          operationType={operationType}
        />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
