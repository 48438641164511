import { DataGridGroupingClient, OnGridReadyEventFn } from '@cmg/common';
import type { GridReadyEvent } from 'ag-grid-community';
import React from 'react';

import { CrmSelectionRow } from '../CrmSelectModal.model';
import { getCrmSelectGridColumns } from './CrmSelectColumns';
import { SCmgEntityName, SCmgEntityNameWrapper, SGridWrapper } from './CrmSelectGrid.styles';

export type Props = {
  isLoading: boolean;
  rows: CrmSelectionRow[];
  onGridReady: OnGridReadyEventFn;
  onChange: (value: string) => void;
  firmKey?: string | null;
};

const groupHeaderRenderer = groupValue => (
  <SCmgEntityNameWrapper>
    CMG entity name:
    <SCmgEntityName>{groupValue}</SCmgEntityName>
  </SCmgEntityNameWrapper>
);

const CrmSelectGrid: React.FC<Props> = ({ isLoading, rows, onGridReady, firmKey, onChange }) => {
  const columns = React.useMemo(() => getCrmSelectGridColumns(onChange), [onChange]);

  return (
    <SGridWrapper>
      <DataGridGroupingClient<CrmSelectionRow>
        loading={isLoading}
        rows={rows}
        columns={columns}
        onGridReady={(event: GridReadyEvent) => {
          !!firmKey && event.api.forEachNode(node => node.setSelected(node.data.id === firmKey)); // Selects the node for the firmKey on the first render
          onGridReady(event);
        }}
        resizeBy="grid"
        resizeStrategy="fit-content"
        domLayout="normal"
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          fillViewport: false,
        }}
        groupByField="cmgEntityName"
        onFirstDataRendered={({ api }) => {
          api.sizeColumnsToFit();
        }}
        groupHeaderRenderer={groupHeaderRenderer}
        gridOptions={{
          getRowHeight: params => {
            return params.data['__groupHeader'] ? 24 : 40;
          },
        }}
      />
    </SGridWrapper>
  );
};

export default CrmSelectGrid;
