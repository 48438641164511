import { useDataGridContext } from '@cmg/data-grid';
import { useCallback, useEffect } from 'react';

import { allocationColIdMatch } from '../columns';
import type { DemandGridCellEditingStoppedEvent, DemandGridDataContext } from '../types';

export type Props = {
  demandGridContext: DemandGridDataContext;
  updateContext: (nextContext: Partial<DemandGridDataContext>) => void;
};

export const useExplicitValueChanges = ({ updateContext, demandGridContext }: Props) => {
  const { refreshCells } = useDataGridContext();

  useEffect(() => {
    refreshCells();
  }, [refreshCells, demandGridContext.explicitChanges]);

  const handleAllocationChange = useCallback(
    (colId: string, event: DemandGridCellEditingStoppedEvent) => {
      if (event.data?.__typename !== 'SyndicateInstitutionalGridRow') {
        return;
      }

      const rowId = event.data.id;
      const [, allocationSetId] = colId.split('_');

      updateContext({
        explicitChanges: {
          ...demandGridContext.explicitChanges,
          [rowId]: {
            ...demandGridContext.explicitChanges[rowId],
            allocations: {
              ...demandGridContext.explicitChanges[rowId]?.allocations,
              [allocationSetId]: event.newValue,
            },
          },
        },
      });
    },
    [demandGridContext.explicitChanges, updateContext]
  );

  return useCallback(
    (event: DemandGridCellEditingStoppedEvent) => {
      const colId = event.column.getColId();

      /**
       * Do not update context if value has been changed in the Ag Grid row state.
       */
      if (event.valueChanged || event.oldValue === event.newValue || event.newValue === undefined) {
        return;
      }

      if (allocationColIdMatch.test(colId)) {
        handleAllocationChange(colId, event);
      }
    },
    [handleAllocationChange]
  );
};
