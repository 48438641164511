import styled from 'styled-components/macro';

export const SDetails = styled.span<{ hasError?: boolean }>`
  font-size: ${({ theme }) => theme.text.size.tiny};
  color: ${({ hasError, theme }) =>
    hasError ? theme.designSystem.colors.red[300] : theme.designSystem.colors.gray[500]};
`;

export const SNote = styled.div`
  white-space: pre-line;
  word-break: break-word;
`;

export const SEmptyNotes = styled.span`
  color: ${({ theme }) => theme.foundation.colors.secondary[500]};
`;
