/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationForm_UnmarkIndicationAsDuplicateMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  indicationId: Types.Scalars['UUID'];
  duplicateIndicationId: Types.Scalars['UUID'];
}>;

export type IndicationForm_UnmarkIndicationAsDuplicateMutation = {
  readonly __typename?: 'Mutation';
  readonly unmarkIndicationsAsDuplicate: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
  };
};

export const IndicationForm_UnmarkIndicationAsDuplicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationForm_UnmarkIndicationAsDuplicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateIndicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unmarkIndicationsAsDuplicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'duplicateIndicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'duplicateIndicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationForm_UnmarkIndicationAsDuplicateMutationFn = Apollo.MutationFunction<
  IndicationForm_UnmarkIndicationAsDuplicateMutation,
  IndicationForm_UnmarkIndicationAsDuplicateMutationVariables
>;

/**
 * __useIndicationForm_UnmarkIndicationAsDuplicateMutation__
 *
 * To run a mutation, you first call `useIndicationForm_UnmarkIndicationAsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_UnmarkIndicationAsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationFormUnmarkIndicationAsDuplicateMutation, { data, loading, error }] = useIndicationForm_UnmarkIndicationAsDuplicateMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      duplicateIndicationId: // value for 'duplicateIndicationId'
 *   },
 * });
 */
export function useIndicationForm_UnmarkIndicationAsDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationForm_UnmarkIndicationAsDuplicateMutation,
    IndicationForm_UnmarkIndicationAsDuplicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationForm_UnmarkIndicationAsDuplicateMutation,
    IndicationForm_UnmarkIndicationAsDuplicateMutationVariables
  >(IndicationForm_UnmarkIndicationAsDuplicateDocument, options);
}
export type IndicationForm_UnmarkIndicationAsDuplicateMutationHookResult = ReturnType<
  typeof useIndicationForm_UnmarkIndicationAsDuplicateMutation
>;
export type IndicationForm_UnmarkIndicationAsDuplicateMutationResult =
  Apollo.MutationResult<IndicationForm_UnmarkIndicationAsDuplicateMutation>;
export type IndicationForm_UnmarkIndicationAsDuplicateMutationOptions = Apollo.BaseMutationOptions<
  IndicationForm_UnmarkIndicationAsDuplicateMutation,
  IndicationForm_UnmarkIndicationAsDuplicateMutationVariables
>;
