import { ColDef, RowNode } from 'ag-grid-community';
import { addYears, startOfWeek, startOfYear, subWeeks } from 'date-fns';

import { CalendarGridOffering } from './components/calendar-grid/CalendarGrid.model';

export const XC_VIEW_FILED_CALENDAR_TRAIT = 'XC_OFF_PLATFORM_OFFERINGS';

/**
 * Returns the start date two weeks prior to the current date.
 */
export const previousTwoWeeks = (currentDate: Date) => subWeeks(currentDate, 2);

/**
 * Returns the start date two weeks from the current date
 */
export const previousTwoWeeksStart = (currentDate: Date) =>
  startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });

/**
 * Returns the date one year from the current date
 */
export const yearFromDate = (currentDate: Date) => addYears(currentDate, 1);

/**
 * Returns the year to date from the current date
 */
export const yearToDateStart = (currentDate: Date) => startOfYear(currentDate);

export type CalendarGridRendererProps<
  TOffering extends CalendarGridOffering = CalendarGridOffering
> = {
  data: TOffering;
  value: TOffering[keyof TOffering];
  node?: RowNode;
  /**
   * This comes from a prop on <CalendarGrid />. Hardcoding it is not really a good
   * approach as it's not tied to the source of the data,
   * but at least we now get a typecheck in the renderers.
   */
  context: { matchUrl: string };
};

type ColDefReplacementFields<TOffering extends CalendarGridOffering = CalendarGridOffering> = {
  // The framework-supplied `ColDef` types `field` as string. `keyof` can also be number and symbol.
  // The `& string` part here ensures strict typing, but also makes the framework's ColDef happy.
  field?: keyof TOffering & string;
  cellRendererFramework?: React.ComponentType<CalendarGridRendererProps<TOffering>>;
  cellRendererParams?: (props: CalendarGridRendererProps<TOffering>) => object;
  equals?: (dataA: TOffering, dataB: TOffering) => boolean;
};

export type ColDefCalendarGrid<TOffering extends CalendarGridOffering = CalendarGridOffering> =
  Omit<ColDef, keyof ColDefReplacementFields> & ColDefReplacementFields<TOffering>;
