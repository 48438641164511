/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RosterQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_RosterQuery = {
  readonly __typename?: 'Query';
  readonly roadshowManagementTeam: {
    readonly __typename?: 'ManagementTeam';
    readonly id: string;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'ManagementTeamMember';
      readonly id: string;
      readonly name: string;
      readonly title?: string | null;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly email: string;
      readonly isParticipating: boolean;
    }>;
  };
  readonly roadshowDealTeams: ReadonlyArray<{
    readonly __typename?: 'RoadshowsDealTeam';
    readonly id: string;
    readonly firmCmgEntityKey: string;
    readonly firmName: string;
    readonly members: ReadonlyArray<{
      readonly __typename?: 'DealTeamMember';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly mobilePhone?: string | null;
      readonly officePhone?: string | null;
      readonly department?: string | null;
      readonly type: Types.DealTeamMemberType;
      readonly status: Types.DealTeamMemberStatus;
    }>;
  }>;
};

export const Roadshows_RosterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_Roster' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowManagementTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_ManagementTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDealTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_RoadshowsDealTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_ManagementTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagementTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_RoadshowsDealTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowsDealTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RosterQuery__
 *
 * To run a query within a React component, call `useRoadshows_RosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RosterQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_RosterQuery(
  baseOptions: Apollo.QueryHookOptions<Roadshows_RosterQuery, Roadshows_RosterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_RosterQuery, Roadshows_RosterQueryVariables>(
    Roadshows_RosterDocument,
    options
  );
}
export function useRoadshows_RosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Roadshows_RosterQuery, Roadshows_RosterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_RosterQuery, Roadshows_RosterQueryVariables>(
    Roadshows_RosterDocument,
    options
  );
}
export type Roadshows_RosterQueryHookResult = ReturnType<typeof useRoadshows_RosterQuery>;
export type Roadshows_RosterLazyQueryHookResult = ReturnType<typeof useRoadshows_RosterLazyQuery>;
export type Roadshows_RosterQueryResult = Apollo.QueryResult<
  Roadshows_RosterQuery,
  Roadshows_RosterQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeRoadshows_RosterMockResponse = makeMockResponse<
  Roadshows_RosterQueryVariables,
  Roadshows_RosterQuery
>(Roadshows_RosterDocument);
