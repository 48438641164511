import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SValidationSectionTitleRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;

export const SValidationSectionTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const StyledExternalLinkIcon = styled(Icon).attrs({ name: 'external-link-alt' })`
  margin-left: 8px;
`;
