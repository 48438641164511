import {
  SyndicateWires_UpdateTerminationWireMutation,
  useSyndicateWires_UpdateTerminationWireMutation,
} from '../graphql';

export type UpdateTerminationWireMutation = SyndicateWires_UpdateTerminationWireMutation;

export const useUpdateTerminationWireMutation = () => {
  return useSyndicateWires_UpdateTerminationWireMutation();
};
