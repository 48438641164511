export type FilingConfirmationModalAction = 'SUBMIT' | 'AMEND' | 'RESUBMIT';

export function getModalTitle(filingName: string, action: FilingConfirmationModalAction) {
  switch (action) {
    case 'SUBMIT':
      return `Submit ${filingName} Form to FINRA`;
    case 'AMEND':
      return `Submit Amended ${filingName} Form to FINRA`;
    case 'RESUBMIT':
      return `Resubmit ${filingName} Form to FINRA`;
  }
}

export function getModalContentMessage(action: FilingConfirmationModalAction) {
  switch (action) {
    case 'SUBMIT':
      return 'Are you sure you want to submit your filing to FINRA? This action cannot be reverted.';
    case 'AMEND':
      return 'Are you sure you want to submit your amended filing to FINRA? This action cannot be reverted.';
    case 'RESUBMIT':
      return 'Are you sure you want to resubmit your filing to FINRA? This action cannot be reverted.';
  }
}
