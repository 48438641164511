import { Option, UUID } from '@cmg/common';

import { FilingType } from '../../../graphql';
import { filingTypeLabels } from '../../../types/domain/filing/constants';
import { OfferingProfile_OfferingProfilePartsFragment } from '../graphql';
import { OfferingDetails } from '../OfferingProfile.model';

export type Terms = OfferingDetails['terms'];

export type FilingsTypeFix = readonly Terms['filings'][number][];

/**
 * Endpoint returns sorted filings from INITIAL -> Revised -> FINAL
 * Option labels should read "Initial", "Revised #1", "Revised #2", ... , "Final"
 * @param filings Accepts filings array from offering profile fragment data
 * @returns returns an array of Options with correctly mapped/indexed labels for FilingType=Revised
 */
export const getFilingRevisionOptions = (filings: FilingsTypeFix) => {
  return filings.map<Option<UUID>>((filing, index) => {
    if (filing.filingType === FilingType.Revised) {
      return { label: `Revision #${index}`, value: filing.id };
    } else {
      return { label: filingTypeLabels[filing.filingType], value: filing.id };
    }
  });
};

export const getForwardSaleDisplayValue = (
  hasForwardAgreement: OfferingProfile_OfferingProfilePartsFragment['hasForwardAgreement']
) => {
  if (hasForwardAgreement === null) {
    return '-';
  }
  if (hasForwardAgreement === true) {
    return 'Yes';
  }
  if (hasForwardAgreement === false) {
    return 'No';
  }
};
