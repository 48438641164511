import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';

import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage, OfferingStatus } from '../../../../../graphql';

type Props = Readonly<{
  offeringId: UUID | undefined;
  offeringStatus: OfferingStatus | undefined;
  offeringStage: OfferingStage | undefined;
}>;

export const useTradeReleaseRoute = ({
  offeringId,
  offeringStatus,
  offeringStage,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const canAccess = useCheckPermissions([permissionsByEntity.Trade.READ]);
  const isPriced = offeringStatus === OfferingStatus.Priced;

  if (!offeringId) {
    return undefined;
  }

  if (!isPriced) {
    return undefined;
  }

  if (!canAccess) {
    return undefined;
  }

  if (offeringStage !== OfferingStage.Published) {
    return undefined;
  }

  const href = routeFactory.tradeRelease.getUrlPath({ offeringId });
  return {
    href,
    label: 'Trade Release',
  };
};
