import { Checkbox, CurrencyInputField, NumericInputField, SecondaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SMenuContainer = styled.div`
  overflow-y: auto;
  position: relative;
  padding: 10px;
  max-height: 350px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #88939a;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 15px;
    margin: 40px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }
`;

export const StyledCheckbox = styled(Checkbox)<{ height?: string }>`
  height: ${p => p.height ?? '26px'};
`;

export const SDemandColumnFieldsWrapper = styled.div`
  display: flex;
`;

export const StyledNumericInputField = styled(NumericInputField)`
  min-width: 80px;
  width: 80px;
  margin-bottom: 10px;

  &:not(:first-child):not(:last-child) {
    margin: 0 5px;
  }
`;

export const SCMGDemandColumnTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin: 0px 0 7px;
  padding-top: 0px;
`;

export const SDemandColumnTitle = styled.div`
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin: 5px 0 5px;
  padding-top: 5px;
`;

export const StyledReferencePriceInput = styled(CurrencyInputField)`
  width: 100%;
  margin-bottom: 10px;
`;

export const SMenuTitle = styled.div`
  font-size: 17px;
  padding-bottom: 9px;
`;

export const SMenuButtonsContainer = styled.div`
  margin-top: 10px;
`;

export const StyledCancelButton = styled(SecondaryButton)`
  width: 100%;
`;

export const SDemandSeparatorTitle = styled.div`
  margin: 15px 0 10px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  padding-top: 5px;
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.border.color.dark}`};
`;

export const SRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
