import React from 'react';

import { WireTypeRoute } from '../../common/components/wire-type-route/WireTypeRoute';
import { WireRouteProps } from '../../common/components/wire-type-route/WireTypeRoute.model';
import { WireTypeConfigContextProvider } from '../../common/context/WireTypeConfigContext';
import { wireTypeConfigNonSyndicateSellingGroupTermsRetention } from './NonSyndicateSellingGroupTermsRetentionWire.model';

export const NonSyndicateSellingGroupTermsRetentionWireRoute: React.FC<WireRouteProps> = props => {
  return (
    <WireTypeConfigContextProvider value={wireTypeConfigNonSyndicateSellingGroupTermsRetention}>
      <WireTypeRoute {...props} />
    </WireTypeConfigContextProvider>
  );
};
