import { format } from 'date-fns';

export enum OrderBookUpdatesGridField {
  INVESTOR_NAME = 'investorName',
  PENDING_UPDATE_LABEL = 'pendingUpdateLabel',
  TIME_STAMP = 'timeStamp',
  TYPE = 'type',
}

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal',
  lineHeight: 1.2,
};

export const commonColumnDefinition = {
  suppressColumnsToolPanel: true,
  resizable: true,
  flex: 1,
  cellStyle,
  minWidth: 200,
};

export const updatesGridColumns = {
  [OrderBookUpdatesGridField.INVESTOR_NAME]: {
    ...commonColumnDefinition,
    colId: OrderBookUpdatesGridField.INVESTOR_NAME,
    field: OrderBookUpdatesGridField.INVESTOR_NAME,
    headerName: 'Investor',
  },
  [OrderBookUpdatesGridField.PENDING_UPDATE_LABEL]: {
    ...commonColumnDefinition,
    colId: OrderBookUpdatesGridField.PENDING_UPDATE_LABEL,
    field: OrderBookUpdatesGridField.PENDING_UPDATE_LABEL,
    headerName: 'Changes Made',
  },
  [OrderBookUpdatesGridField.TIME_STAMP]: {
    ...commonColumnDefinition,
    colId: OrderBookUpdatesGridField.TIME_STAMP,
    field: OrderBookUpdatesGridField.TIME_STAMP,
    headerName: 'Time Stamp',
    cellRendererFramework: ({ data }) => (data.timeStamp ? format(data.timeStamp, 'hh:mm a') : ''),
  },
};

export const columns = [
  updatesGridColumns[OrderBookUpdatesGridField.INVESTOR_NAME],
  updatesGridColumns[OrderBookUpdatesGridField.PENDING_UPDATE_LABEL],
  updatesGridColumns[OrderBookUpdatesGridField.TIME_STAMP],
];
