import styled, { css } from 'styled-components/macro';

import FlexLayout from '../../../../layout/flex-layout/FlexLayout';

export const StyledSearchResultsHeader = styled(FlexLayout)`
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: 5px 0;
  background-color: ${({ theme }) => theme.background.color.light};
  color: ${({ theme }) => theme.text.color.darkGray};

  // action container
  & > div {
    align-items: center;
    & > p {
      margin-right: 20px;
    }
  }
`;

export const SSearchResultRow = styled.tr<{ selected?: boolean }>`
  cursor: default;
  &&& td {
    padding: 15px 10px;
  }
  ${({ selected, theme }) =>
    !!selected &&
    css`
      &&& {
        background-color: ${theme.background.color.highlighted};
      }
    `}
  &:hover {
    background-color: ${({ theme }) => theme.background.color.light};
  }
`;

export const StyledNoResultsFound = styled(FlexLayout)`
  align-items: center;
  justify-content: center;
`;
