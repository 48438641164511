import { numericUtil, UUID } from '@cmg/common';
import isNil from 'lodash/isNil';
import sum from 'lodash/sum';

import {
  AllocationAcknowledgement,
  InstitutionalIndicationOrderType,
} from '../../../../../graphql';
import { getTotalsFilterPredicate } from '../../utils';
import { IndicationWithDemandLevels } from '../institutional-demand-grid/types';

export type IndicationSummaryMetricsArgs = Readonly<{
  indications: IndicationWithDemandLevels[];
  selectedIndicationIds: UUID[];
  referencePrice: number | null;
  baseOfferingSize: number | null;
}>;

type IndicationSummaryMetrics = Readonly<{
  allocationCount: number | null;
  selectedIndicationsCount: number | null;
  subscription: number | null;
  fillRate: number | null;
  marketRate: number | null;
  limitRate: number | null;
  averageAllocation: number | null;
  averageDemand: number | null;
  totalAllocatedShares: number | null;
  totalAllocatedSharesPercentage: number | null;
  totalDemand: number | null;
  totalDemandPercentage: number | null;
}>;

export const defaultEmptyMetrics: IndicationSummaryMetrics = {
  allocationCount: null,
  selectedIndicationsCount: null,
  subscription: null,
  fillRate: null,
  marketRate: null,
  limitRate: null,
  averageAllocation: null,
  averageDemand: null,
  totalAllocatedShares: null,
  totalAllocatedSharesPercentage: null,
  totalDemand: null,
  totalDemandPercentage: null,
};

export const getSelectedIndications = (
  indications: IndicationWithDemandLevels[],
  selectedIndicationIds: UUID[]
) => {
  if (selectedIndicationIds.length === 0) {
    return [...indications];
  }

  return indications.filter(indication => selectedIndicationIds.includes(indication.id));
};

export const getTotalDemandAtLevel = (
  indications: IndicationWithDemandLevels[],
  referencePrice: number | null
) => {
  if (isNil(referencePrice)) {
    return null;
  }

  return sum(
    indications.map(
      indication => indication.demandLevels[`${referencePrice}`] ?? indication.demandAtMarket
    )
  );
};

const getFinalAllocatedShares = (indications: IndicationWithDemandLevels[]) => {
  return indications.map(({ finalAllocation }) => {
    const { shareQuantity = null, investorReply = null } = finalAllocation ?? {};

    if (investorReply?.status === AllocationAcknowledgement.Rejected) {
      return null;
    }

    return shareQuantity;
  });
};

const getDraftAllocatedShares = (indications: IndicationWithDemandLevels[]) => {
  return indications.map(({ defaultDraftAllocationSetId, draftAllocationsBySetId }) => {
    if (!defaultDraftAllocationSetId) {
      return null;
    }

    return draftAllocationsBySetId[defaultDraftAllocationSetId]?.shareQuantity ?? null;
  });
};

export const getAllocatedShares = (indications: IndicationWithDemandLevels[]): number[] => {
  if (indications.length === 0) {
    return [];
  }

  const hasFinalAllocations = indications.some(indication => !isNil(indication.finalAllocation));
  const allocatedShares = hasFinalAllocations
    ? getFinalAllocatedShares(indications)
    : getDraftAllocatedShares(indications);

  return allocatedShares.filter(shares => !isNil(shares) && shares > 0) as number[];
};

export const getTotalDemandMetrics = (
  totalDemand: number | null,
  selectedTotalDemand: number | null,
  selectedIndicationsCount: number
) => {
  return {
    totalDemand: selectedTotalDemand,
    totalDemandPercentage: numericUtil.divide(selectedTotalDemand, totalDemand),
    averageDemand: numericUtil.divide(selectedTotalDemand, selectedIndicationsCount),
  };
};

export const getAllocationMetrics = (allAllocations: number[], selectedAllocations: number[]) => {
  const selectedTotalAllocatedShares = sum(selectedAllocations);
  const totalAllocatedShares = sum(allAllocations);

  return {
    allocationCount: selectedAllocations.length,
    totalAllocatedShares: selectedTotalAllocatedShares,
    totalAllocatedSharesPercentage: numericUtil.divide(
      selectedTotalAllocatedShares,
      totalAllocatedShares
    ),
    averageAllocation: numericUtil.divide(selectedTotalAllocatedShares, selectedAllocations.length),
  };
};

export const isMarketIndication = (
  indication: IndicationWithDemandLevels,
  referencePrice: number | null
) => {
  if (indication.type === InstitutionalIndicationOrderType.Market) {
    return true;
  }

  if (indication.type === InstitutionalIndicationOrderType.Scaled && !isNil(referencePrice)) {
    return isNil(indication.demandLevels[`${referencePrice}`]);
  }

  return false;
};

export const getMarketToLimitRate = (
  indications: IndicationWithDemandLevels[],
  referencePrice: number | null
) => {
  if (!indications.length) {
    return {
      marketRate: null,
      limitRate: null,
    };
  }

  const marketCount = indications.filter(indication => {
    return isMarketIndication(indication, referencePrice);
  }).length;

  const marketRate = marketCount / indications.length;
  const limitRate = 1 - marketRate;

  return {
    marketRate,
    limitRate,
  };
};

export const getIndicationSummaryMetrics = ({
  selectedIndicationIds,
  referencePrice,
  baseOfferingSize,
  indications: allIndications,
}: IndicationSummaryMetricsArgs): IndicationSummaryMetrics => {
  const indications = allIndications.filter(
    getTotalsFilterPredicate({ skipFinalAllocationCheck: true })
  );

  // If there are no indications to display, return default empty metrics early
  if (!indications.length) {
    return defaultEmptyMetrics;
  }

  const selectedIndications = getSelectedIndications(indications, selectedIndicationIds);

  const selectedTotalDemand = getTotalDemandAtLevel(selectedIndications, referencePrice);
  const totalDemand = getTotalDemandAtLevel(indications, referencePrice);
  const selectedTotalDemandMetrics = getTotalDemandMetrics(
    totalDemand,
    selectedTotalDemand,
    selectedIndications.length
  );

  const selectedAllocatedShares = getAllocatedShares(selectedIndications);
  const totalAllocatedShares = getAllocatedShares(indications);
  const selectedAllocationMetrics = getAllocationMetrics(
    totalAllocatedShares,
    selectedAllocatedShares
  );

  const { marketRate, limitRate } = getMarketToLimitRate(selectedIndications, referencePrice);

  return {
    selectedIndicationsCount: selectedIndications.length,
    subscription: numericUtil.divide(selectedTotalDemand, baseOfferingSize),
    fillRate: numericUtil.divide(
      selectedAllocationMetrics.totalAllocatedShares,
      selectedTotalDemand
    ),
    marketRate,
    limitRate,
    ...selectedTotalDemandMetrics,
    ...selectedAllocationMetrics,
  };
};

export const getMetricsMap = (metrics: IndicationSummaryMetrics) => ({
  Subscription: numericUtil.getDisplayValueForNumber(metrics.subscription, 2, 0, 'x'),
  'Total Demand': numericUtil.getDisplayValueForInteger(metrics.totalDemand),
  Indications: numericUtil.getDisplayValueForInteger(metrics.selectedIndicationsCount),
  'Avg Demand': numericUtil.getDisplayValueForNumber(metrics.averageDemand),
  Market: numericUtil.getDisplayValueForPercents(metrics.marketRate),
  Limit: numericUtil.getDisplayValueForPercents(metrics.limitRate),
  'Of Demand': numericUtil.getDisplayValueForPercents(metrics.totalDemandPercentage),
  'Total Allocated': numericUtil.getDisplayValueForInteger(metrics.totalAllocatedShares),
  Allocations: numericUtil.getDisplayValueForInteger(metrics.allocationCount),
  'Avg Allocation': numericUtil.getDisplayValueForNumber(metrics.averageAllocation),
  'Avg Fill Rate': numericUtil.getDisplayValueForPercents(metrics.fillRate),
  'Of Allocated': numericUtil.getDisplayValueForPercents(metrics.totalAllocatedSharesPercentage),
});
