import { PaginationQueryParams, Theme } from '@cmg/common';
import { ColDef } from 'ag-grid-community';

import { PaginationDataInput, SortOrderDirection } from '../../../../../graphql';
import DealTeamNameCellRenderer from './deal-team-name-cell-renderer/DealTeamNameCellRenderer';
import DeleteDealTeamCellRenderer from './delete-deal-team-cell-renderer/DeleteDealTeamCellRenderer';

export const createColumns = (
  theme: Theme,
  defaultPagination: PaginationDataInput,
  oidcUserCmgEntityKey: string | null,
  onDelete: (dealTeamId: string) => void
): ColDef[] =>
  [
    {
      field: 'firmName',
      headerName: 'Organization',
      suppressMovable: true,
      sortable: true,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
      cellRendererFramework: ({ data, value }) => (
        <DealTeamNameCellRenderer data={data} value={value} />
      ),
    },
    {
      field: 'membersCount',
      headerName: 'Deal Team Members',
      suppressMovable: true,
      sortable: true,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
        color: theme.text.color.darkGray,
      },
    },
    {
      field: 'delete',
      headerName: '',
      suppressMovable: true,
      sortable: false,
      cellStyle: {
        alignItems: 'center',
        display: 'flex',
      },
      maxWidth: 44,
      cellRendererFramework: ({ data, rowIndex }) => {
        if (data.id === oidcUserCmgEntityKey) {
          return null;
        }
        return <DeleteDealTeamCellRenderer onDelete={() => onDelete(data.id)} index={rowIndex} />;
      },
    },
  ].map((item: ColDef) => {
    if (item.field === defaultPagination.orderField) {
      item.initialSort =
        defaultPagination.orderDirection === SortOrderDirection.Asc ? 'asc' : 'desc';
    }
    return item;
  });

export const paginationInitialValues: PaginationDataInput = {
  orderDirection: SortOrderDirection.Desc,
  orderField: 'membersCount',
};

export function toPagination(params: PaginationQueryParams): PaginationDataInput {
  if (params.orderField) {
    return {
      orderField: params.orderField,
      orderDirection:
        params.orderDirection === 'asc' ? SortOrderDirection.Asc : SortOrderDirection.Desc,
    };
  } else {
    return {
      orderField: params.orderField,
      orderDirection: undefined,
    };
  }
}
