import styled from 'styled-components/macro';

export const SListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  gap: 8px;
`;

export const SItemWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 16px;
  & > div {
    flex: auto;
  }
`;

export const STextWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
`;
