import { numericUtil } from '@cmg/common';
import React from 'react';

import { SWrapper } from './QuantityReleasedPopoverContent.styles';

export type Props = {
  investorName: string;
  finalAllocation: number | null | undefined;
  finalAllocationReleased: number | null | undefined;
};

export const QuantityReleasedPopoverContent: React.VFC<Props> = ({
  investorName,
  finalAllocation,
  finalAllocationReleased,
}) => {
  return (
    <SWrapper>
      <div>
        {`${investorName}–Quantity Released is out of balance by ${numericUtil.getDisplayValueForInteger(
          numericUtil.sum(
            finalAllocation,
            finalAllocationReleased ? -finalAllocationReleased : null
          )
        )}`}
      </div>
    </SWrapper>
  );
};
