import { ODSTable as Table, UUID } from '@cmg/common';
import React from 'react';

import InvestorFirmsSearch, {
  InvestorFirmOption,
} from '../../../../../../../../../../common/components/form/investor-firm-search/InvestorFirmSearch';
import {
  StyledInvestorRadio,
  StyledTableTextCell,
} from '../SalesCreditsBankInvestorKeyTable.styles';
import { getInitialFirm } from './OtherInvestorRow.model';

export type Props = {
  onSelectInvestor: (investorId: UUID | null) => void;
  defaultFirmId: UUID | null;
  defaultFirmName: string;
  defaultBankInvestorKey: string | null;
  defaultFirmLocation: string | null;
  selectedInvestorId: UUID | null;
  allInvestors: UUID[];
};

const OtherInvestorRow: React.FC<Props> = ({
  onSelectInvestor,
  defaultFirmId,
  defaultFirmName,
  defaultBankInvestorKey,
  defaultFirmLocation,
  selectedInvestorId,
  allInvestors,
}) => {
  const [searchedFirm, setSearchedFirm] = React.useState<InvestorFirmOption | null>(
    getInitialFirm({
      allInvestors,
      defaultFirmId,
      defaultFirmName,
      defaultFirmLocation,
      defaultBankInvestorKey,
    })
  );

  const handleSelectInvestor = () => {
    if (searchedFirm && searchedFirm.firmId !== selectedInvestorId) {
      onSelectInvestor(searchedFirm.firmId);
    }
  };

  const handleInvestorFirmChange = (nextValue: InvestorFirmOption | null) => {
    if (nextValue === null) {
      onSelectInvestor(defaultFirmId);
      setSearchedFirm(nextValue);
    } else if (allInvestors.includes(nextValue.firmId)) {
      onSelectInvestor(nextValue.firmId);
      setSearchedFirm(null);
    } else {
      onSelectInvestor(nextValue.firmId);
      setSearchedFirm(nextValue);
    }
  };

  const isChecked = selectedInvestorId === searchedFirm?.firmId;

  return (
    <Table.Row suppressHighlightingOnHover isFocused={isChecked} onClick={handleSelectInvestor}>
      <Table.Cell paddingSize="M" withBottomBorder={false}>
        <StyledInvestorRadio
          disabled={!searchedFirm}
          onChange={handleSelectInvestor}
          checked={isChecked}
        />
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        <InvestorFirmsSearch
          onChange={handleInvestorFirmChange}
          showCreateNewInvestor={false}
          value={searchedFirm}
          placeholder="Select investor..."
          menuPlacement="auto"
        />
      </Table.Cell>
      {searchedFirm && (
        <React.Fragment>
          <StyledTableTextCell paddingSize="M" withBottomBorder={false}>
            {searchedFirm.firmKey || '-'}
          </StyledTableTextCell>
          <StyledTableTextCell paddingSize="M" withBottomBorder={false}>
            {searchedFirm.location || '-'}
          </StyledTableTextCell>
        </React.Fragment>
      )}
    </Table.Row>
  );
};

export default OtherInvestorRow;
