import { OperationType } from '../components/modals/create-or-update-wire-modal/CreateOrUpdateWireModal.model';

export function getDisclaimer(
  operationType: OperationType,
  wireDisclaimer: string | null | undefined,
  sharedDisclaimer: string | null | undefined
): string {
  if (operationType === 'update') {
    return wireDisclaimer ?? '';
  }

  return sharedDisclaimer ?? wireDisclaimer ?? '';
}
