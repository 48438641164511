import { DataGrid, DeepWritable } from '@cmg/common';
import { ColDef } from 'ag-grid-community';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { nonEditableGridOptions } from '../../../../../constants';
import { FinalSettlement_AccountingManagerPartsFragment } from '../../../graphql';
import { SGridWrapper } from './AccountingDataGrid.styles';

export type Props = {
  columns: ColDef[];
  rows: readonly FinalSettlement_AccountingManagerPartsFragment[];
  totalRow: Partial<FinalSettlement_AccountingManagerPartsFragment>;
};

const AccountingDataGrid: React.FC<Props> = ({ columns, rows, totalRow }: Props) => {
  const theme = useTheme();

  return (
    <SGridWrapper>
      <DataGrid
        testId="Settlement / Accounting / Details / Accounting Data Grid"
        columns={columns}
        rows={rows as DeepWritable<typeof rows>}
        domLayout="autoHeight"
        resizeStrategy="fit-content"
        getRowNodeId={row => row.managerCmgEntityKey}
        gridOptions={{
          ...nonEditableGridOptions,
          enableCellTextSelection: true,
          suppressCellSelection: true,
          suppressRowClickSelection: true,
          suppressRowHoverHighlight: true,
          suppressPaginationPanel: true,
          getRowStyle: () => ({ cursor: 'default' }),
          rowStyle: {
            background: theme.background.color.white,
            borderBottom: theme.border.smallSolidLight,
          },
        }}
        getRowsPinnedToTop={() => (rows.length > 0 ? [totalRow] : [])}
      />
    </SGridWrapper>
  );
};

export default AccountingDataGrid;
