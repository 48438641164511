import type { DynamicColDefsFactory } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { useCallback, useEffect } from 'react';

import {
  allocationSetColDefsFactory,
  demandLevelColDefsFactory,
  extendedDataColDefsFactory,
} from '../columns';
import { DemandGridDataContext } from '../types';

export type Props = Pick<
  DemandGridDataContext,
  'oidcUserCmgEntityKey' | 'allocationSets' | 'demandConfig' | 'investorExtensionSchema'
>;

/**
 * Updates the column definitions based on the allocation sets and demand config.
 *
 * @param allocationSets
 * @param demandConfig
 * @param oidcUserCmgEntityKey
 * @param investorExtensionSchema
 */
export const useDynamicColDefs = ({
  allocationSets,
  demandConfig,
  oidcUserCmgEntityKey,
  investorExtensionSchema,
}: Props) => {
  const { setColDefs, isGridReady } = useDataGridContext();

  const handleSetDynamicColDefs = useCallback(
    (colDefFactory: DynamicColDefsFactory) => {
      if (isGridReady) {
        setColDefs(colDefFactory);
      }
    },
    [isGridReady, setColDefs]
  );

  useEffect(() => {
    handleSetDynamicColDefs(allocationSetColDefsFactory(allocationSets, oidcUserCmgEntityKey));
  }, [allocationSets, handleSetDynamicColDefs, oidcUserCmgEntityKey]);

  useEffect(() => {
    handleSetDynamicColDefs(demandLevelColDefsFactory(demandConfig));
  }, [demandConfig, handleSetDynamicColDefs]);

  useEffect(() => {
    handleSetDynamicColDefs(extendedDataColDefsFactory(investorExtensionSchema));
  }, [handleSetDynamicColDefs, investorExtensionSchema]);
};
