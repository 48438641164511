import * as yup from 'yup';

export type Values = {
  allocations: number | null;
  draftAllocations: { [key: string]: boolean };
  finalAllocations: { finalAllocation: boolean } | null;
};

export const validationSchema = yup.object({
  allocations: yup.number().min(0).integer().nullable().required(),
  draftAllocations: yup.object().when(['finalAllocations'], ([finalAllocations], schema) => {
    if (finalAllocations) {
      return schema.test('with-final-set', 'Select at least one', values => {
        const array = [finalAllocations, ...Object.values(values)];
        return array.some(value => value);
      });
    }

    return schema.test('without-final-set', 'Select at least one', values => {
      return Object.values(values).some(value => value);
    });
  }),
});
