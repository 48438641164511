import * as yup from 'yup';

export type Option = Readonly<{
  readonly isValid: boolean;
  readonly validationError: ValidationError | null;
  readonly emailAddress: string;
}>;

export enum ValidationError {
  INVALID_FORMAT = 'INVALID_FORMAT',
  DUPLICATED = 'DUPLICATED',
}

export const EmailValidationSchema = yup.string().required().email('Invalid Email');

export function validateEmailAddress(
  emailAddress: string,
  otherEmailAddresses: string[]
): ValidationError | null {
  if (!EmailValidationSchema.isValidSync(emailAddress)) {
    return ValidationError.INVALID_FORMAT;
  }

  if (otherEmailAddresses.some(address => emailAddress.toLowerCase() === address.toLowerCase())) {
    return ValidationError.DUPLICATED;
  }

  return null;
}

export function createOptionFromEmailAddress(
  emailAddress: string,
  otherEmailAddresses: string[]
): Option | null {
  if (!emailAddress.length) {
    return null;
  }

  const validationError = validateEmailAddress(emailAddress, otherEmailAddresses);
  const isValid = validationError === null;

  return {
    isValid,
    validationError,
    emailAddress,
  };
}

export function getOptionText(option: Option): string {
  if (option.isValid) {
    return `Add "${option.emailAddress}"`;
  }

  if (option.validationError === ValidationError.DUPLICATED) {
    return 'Email has already been added';
  }

  return 'Invalid email';
}
