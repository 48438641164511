import { Grid, GridProps } from '@cmg/design-system';
import * as React from 'react';

export interface PanelProps extends GridProps {
  children?: React.ReactNode;
}

const containerStyles = { background: 'white', padding: '16px 32px', width: '100%' };

const Panel: React.FC<PanelProps> = ({ children, ...props }) => {
  return (
    <Grid sx={containerStyles} {...props}>
      {children}
    </Grid>
  );
};

export default Panel;
