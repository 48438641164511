import { FormField, FormLabel } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../../components/form/OfferingSetupForm';

export const StyledFormField = styled(FormField)`
  label {
    display: block;
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  display: block;
`;

export const SReorderButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.color.disabled : theme.text.color.light};
  &:hover {
    color: ${({ disabled, theme }) =>
      disabled ? theme.text.color.disabled : theme.text.color.dark};
  }
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SForm = styled(FormField)`
  label {
    display: block;
  }
`;

export const StyledColumn = styled(SetupForm.Column)`
  flex-basis: 30%;
`;

export const SReorderColumn = styled.div<{ reordering?: boolean }>`
  width: 40px;
  flex: none;
`;

export const SSwitchWrapper = styled.div`
  min-height: 34px;
  display: flex;
  align-items: center;
`;

export const StyledRow = styled(SetupForm.Row)`
  &:hover ${SReorderColumn} {
    visibility: visible;
  }
`;

export const SMarginRight = styled.div`
  margin-right: 15px;
`;
