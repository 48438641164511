import { Icon, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

export const ManagerRemovedIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <Popover content="Recipient removed." variant="DARK">
      <span>
        <Icon
          name="exclamation-triangle"
          variant="solid"
          color={theme.designSystem.colors.yellow['300']}
        />
      </span>
    </Popover>
  );
};
