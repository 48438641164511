import capitalize from 'lodash/capitalize';

import {
  MdlIndicationPricingType,
  MdlIndicationUnitType,
} from '../../../../../../../../../../graphql';

export const pricingTypeOptions = Object.values(MdlIndicationPricingType).map(pricingType => ({
  label: capitalize(pricingType),
  value: pricingType,
}));

export const unitTypeOptions = Object.values(MdlIndicationUnitType).map(unitType => ({
  label: capitalize(unitType),
  value: unitType,
}));

export const unitTypeToFieldName = (unitType: MdlIndicationUnitType) =>
  `realDemand${capitalize(unitType)}`;
