import React from 'react';

import { SHeading, SNotAccessibleContainer } from './NotAccessible.styles';

export type Props = React.PropsWithChildren<{
  subject?: string;
  heading?: string | null;
  content?: string | null;
}>;

const NotAccessible: React.FC<Props> = ({
  subject = 'Page',
  heading = `${subject} Not Accessible`,
  content = `You are not permitted to access this ${subject}`,
  children,
}) => {
  return (
    <SNotAccessibleContainer>
      {heading && <SHeading>{heading}</SHeading>}
      {content}
      {children}
    </SNotAccessibleContainer>
  );
};

export default NotAccessible;
