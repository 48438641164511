/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_AssignFinalAllocationSetVisibilityMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  visibility: Types.Scalars['Boolean'];
}>;

export type OrderBook_AssignFinalAllocationSetVisibilityMutation = {
  readonly __typename?: 'Mutation';
  readonly assignFinalAllocationSetVisibility: {
    readonly __typename?: 'FinalInstitutionalAllocationSet';
    readonly id: string;
    readonly isVisibleToNonSyndicate: boolean;
  };
};

export type OrderBook_AttestationStatusChangedSubscriptionVariables = Types.Exact<{
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type OrderBook_AttestationStatusChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly attestationStatusChanged: {
    readonly __typename?: 'AttestationStatus';
    readonly cmgEntityKey: string;
    readonly firmId: string;
    readonly status: Types.AttestationFormStatus;
  };
};

export type OrderBook_GetOfferingFilingsSharesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_GetOfferingFilingsSharesQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly terms: {
      readonly __typename?: 'TermsProfile';
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'FilingProfile';
        readonly id: string;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
      }>;
    };
  };
};

export type OrderBook_IndicationChangedSubscriptionVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type OrderBook_IndicationChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly indicationChanged: {
    readonly __typename?: 'IndicationChanged';
    readonly offeringId: string;
    readonly indicationId: string;
    readonly eventSource?: string | null;
  };
};

export type OrderBook_InvestorRelationshipChangedSubscriptionVariables = Types.Exact<{
  investorFirmKeysOrIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type OrderBook_InvestorRelationshipChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly investorRelationshipChanged: {
    readonly __typename?: 'InvestorRelationshipChanged';
    readonly investorFirmKey?: string | null;
    readonly investorFirmId: string;
    readonly relationships: ReadonlyArray<{
      readonly __typename?: 'InvestorRelationship';
      readonly underwriterEmployeeName: string;
      readonly relationshipType: Types.InvestorRelationshipType;
      readonly status: Types.InvestorRelationshipRecordStatus;
    }>;
  };
};

export type OrderBook_OfferingAndOrderBookStatusQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_OfferingAndOrderBookStatusQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly status: Types.OfferingStatus;
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly state: Types.OrderBookState;
    };
  };
};

export type OrderBook_OrderBookGraphQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_OrderBookGraphQuery = {
  readonly __typename?: 'Query';
  readonly orderBookGraph?: {
    readonly __typename?: 'OrderBookGraph';
    readonly salesState: Types.OrderBookState;
  } | null;
};

export type OrderBook_PublishedOfferingPartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly type: Types.OfferingType;
  readonly issuer: { readonly __typename?: 'Issuer'; readonly name: string };
  readonly orderBook: {
    readonly __typename?: 'OrderBook';
    readonly id: string;
    readonly state: Types.OrderBookState;
  };
};

export type OrderBook_OrderBookRouteQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_OrderBookRouteQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly status: Types.OfferingStatus;
    readonly type: Types.OfferingType;
    readonly issuer: { readonly __typename?: 'Issuer'; readonly name: string };
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly state: Types.OrderBookState;
    };
  };
  readonly finalInstitutionalAllocationSet?: {
    readonly __typename?: 'FinalInstitutionalAllocationSet';
    readonly id: string;
    readonly isReleased: boolean;
    readonly isVisibleToNonSyndicate: boolean;
  } | null;
};

export type OrderBook_OrderBookSalesStateChangedSubscriptionVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_OrderBookSalesStateChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly orderBookSalesStateChanged: {
    readonly __typename?: 'OrderBookSalesState';
    readonly offeringId: string;
    readonly salesState: Types.OrderBookState;
  };
};

export type OrderBook_OrderBookStatusChangedSubscriptionVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_OrderBookStatusChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly orderBookStatusChanged: {
    readonly __typename?: 'OrderBookStatus';
    readonly state: Types.OrderBookState;
  };
};

export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutation = {
  readonly __typename?: 'Mutation';
  readonly releaseFinalInstitutionalAllocationSet: {
    readonly __typename?: 'FinalInstitutionalAllocationSet';
    readonly id: string;
    readonly releaseTimestamp?: string | null;
    readonly isReleased: boolean;
  };
};

export type OrderBook_UpdateOrderBookSalesStateMutationVariables = Types.Exact<{
  input: Types.UpdateOrderBookSalesStateInput;
}>;

export type OrderBook_UpdateOrderBookSalesStateMutation = {
  readonly __typename?: 'Mutation';
  readonly updateOrderBookSalesState?: {
    readonly __typename?: 'UpdateOrderBookSalesStatePayload';
    readonly orderBookState?: Types.OrderBookState | null;
  } | null;
};

export type UpdateOrderBookStateMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  state: Types.OrderBookState;
}>;

export type UpdateOrderBookStateMutation = {
  readonly __typename?: 'Mutation';
  readonly updateOrderBookState: {
    readonly __typename?: 'OrderBook';
    readonly id: string;
    readonly state: Types.OrderBookState;
  };
};

export const OrderBook_PublishedOfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_AssignFinalAllocationSetVisibilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_AssignFinalAllocationSetVisibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignFinalAllocationSetVisibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVisibleToNonSyndicate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_AssignFinalAllocationSetVisibilityMutationFn = Apollo.MutationFunction<
  OrderBook_AssignFinalAllocationSetVisibilityMutation,
  OrderBook_AssignFinalAllocationSetVisibilityMutationVariables
>;

/**
 * __useOrderBook_AssignFinalAllocationSetVisibilityMutation__
 *
 * To run a mutation, you first call `useOrderBook_AssignFinalAllocationSetVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_AssignFinalAllocationSetVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookAssignFinalAllocationSetVisibilityMutation, { data, loading, error }] = useOrderBook_AssignFinalAllocationSetVisibilityMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useOrderBook_AssignFinalAllocationSetVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_AssignFinalAllocationSetVisibilityMutation,
    OrderBook_AssignFinalAllocationSetVisibilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_AssignFinalAllocationSetVisibilityMutation,
    OrderBook_AssignFinalAllocationSetVisibilityMutationVariables
  >(OrderBook_AssignFinalAllocationSetVisibilityDocument, options);
}
export type OrderBook_AssignFinalAllocationSetVisibilityMutationHookResult = ReturnType<
  typeof useOrderBook_AssignFinalAllocationSetVisibilityMutation
>;
export type OrderBook_AssignFinalAllocationSetVisibilityMutationResult =
  Apollo.MutationResult<OrderBook_AssignFinalAllocationSetVisibilityMutation>;
export type OrderBook_AssignFinalAllocationSetVisibilityMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_AssignFinalAllocationSetVisibilityMutation,
    OrderBook_AssignFinalAllocationSetVisibilityMutationVariables
  >;
export const OrderBook_AttestationStatusChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_AttestationStatusChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attestationStatusChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_AttestationStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_AttestationStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_AttestationStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_AttestationStatusChangedSubscription({
 *   variables: {
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useOrderBook_AttestationStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_AttestationStatusChangedSubscription,
    OrderBook_AttestationStatusChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_AttestationStatusChangedSubscription,
    OrderBook_AttestationStatusChangedSubscriptionVariables
  >(OrderBook_AttestationStatusChangedDocument, options);
}
export type OrderBook_AttestationStatusChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_AttestationStatusChangedSubscription
>;
export type OrderBook_AttestationStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_AttestationStatusChangedSubscription>;
export const OrderBook_GetOfferingFilingsSharesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_GetOfferingFilingsShares' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'terms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primarySharesBaseOffering' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondarySharesBaseOffering' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'secondarySharesOverAllotmentAuthorized',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_GetOfferingFilingsSharesQuery__
 *
 * To run a query within a React component, call `useOrderBook_GetOfferingFilingsSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_GetOfferingFilingsSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_GetOfferingFilingsSharesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_GetOfferingFilingsSharesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_GetOfferingFilingsSharesQuery,
    OrderBook_GetOfferingFilingsSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_GetOfferingFilingsSharesQuery,
    OrderBook_GetOfferingFilingsSharesQueryVariables
  >(OrderBook_GetOfferingFilingsSharesDocument, options);
}
export function useOrderBook_GetOfferingFilingsSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_GetOfferingFilingsSharesQuery,
    OrderBook_GetOfferingFilingsSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_GetOfferingFilingsSharesQuery,
    OrderBook_GetOfferingFilingsSharesQueryVariables
  >(OrderBook_GetOfferingFilingsSharesDocument, options);
}
export type OrderBook_GetOfferingFilingsSharesQueryHookResult = ReturnType<
  typeof useOrderBook_GetOfferingFilingsSharesQuery
>;
export type OrderBook_GetOfferingFilingsSharesLazyQueryHookResult = ReturnType<
  typeof useOrderBook_GetOfferingFilingsSharesLazyQuery
>;
export type OrderBook_GetOfferingFilingsSharesQueryResult = Apollo.QueryResult<
  OrderBook_GetOfferingFilingsSharesQuery,
  OrderBook_GetOfferingFilingsSharesQueryVariables
>;
export const OrderBook_IndicationChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_IndicationChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indicationChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventSource' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_IndicationChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_IndicationChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_IndicationChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_IndicationChangedSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useOrderBook_IndicationChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_IndicationChangedSubscription,
    OrderBook_IndicationChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_IndicationChangedSubscription,
    OrderBook_IndicationChangedSubscriptionVariables
  >(OrderBook_IndicationChangedDocument, options);
}
export type OrderBook_IndicationChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_IndicationChangedSubscription
>;
export type OrderBook_IndicationChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_IndicationChangedSubscription>;
export const OrderBook_InvestorRelationshipChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_InvestorRelationshipChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorFirmKeysOrIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorRelationshipChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorFirmKeysOrIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorFirmKeysOrIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'investorFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorFirmId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationships' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'underwriterEmployeeName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationshipType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InvestorRelationshipChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_InvestorRelationshipChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InvestorRelationshipChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InvestorRelationshipChangedSubscription({
 *   variables: {
 *      investorFirmKeysOrIds: // value for 'investorFirmKeysOrIds'
 *   },
 * });
 */
export function useOrderBook_InvestorRelationshipChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_InvestorRelationshipChangedSubscription,
    OrderBook_InvestorRelationshipChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_InvestorRelationshipChangedSubscription,
    OrderBook_InvestorRelationshipChangedSubscriptionVariables
  >(OrderBook_InvestorRelationshipChangedDocument, options);
}
export type OrderBook_InvestorRelationshipChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_InvestorRelationshipChangedSubscription
>;
export type OrderBook_InvestorRelationshipChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_InvestorRelationshipChangedSubscription>;
export const OrderBook_OfferingAndOrderBookStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_OfferingAndOrderBookStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_OfferingAndOrderBookStatusQuery__
 *
 * To run a query within a React component, call `useOrderBook_OfferingAndOrderBookStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_OfferingAndOrderBookStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_OfferingAndOrderBookStatusQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_OfferingAndOrderBookStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_OfferingAndOrderBookStatusQuery,
    OrderBook_OfferingAndOrderBookStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_OfferingAndOrderBookStatusQuery,
    OrderBook_OfferingAndOrderBookStatusQueryVariables
  >(OrderBook_OfferingAndOrderBookStatusDocument, options);
}
export function useOrderBook_OfferingAndOrderBookStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_OfferingAndOrderBookStatusQuery,
    OrderBook_OfferingAndOrderBookStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_OfferingAndOrderBookStatusQuery,
    OrderBook_OfferingAndOrderBookStatusQueryVariables
  >(OrderBook_OfferingAndOrderBookStatusDocument, options);
}
export type OrderBook_OfferingAndOrderBookStatusQueryHookResult = ReturnType<
  typeof useOrderBook_OfferingAndOrderBookStatusQuery
>;
export type OrderBook_OfferingAndOrderBookStatusLazyQueryHookResult = ReturnType<
  typeof useOrderBook_OfferingAndOrderBookStatusLazyQuery
>;
export type OrderBook_OfferingAndOrderBookStatusQueryResult = Apollo.QueryResult<
  OrderBook_OfferingAndOrderBookStatusQuery,
  OrderBook_OfferingAndOrderBookStatusQueryVariables
>;
export const OrderBook_OrderBookGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_OrderBookGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'salesState' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_OrderBookGraphQuery__
 *
 * To run a query within a React component, call `useOrderBook_OrderBookGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_OrderBookGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_OrderBookGraphQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_OrderBookGraphQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_OrderBookGraphQuery,
    OrderBook_OrderBookGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBook_OrderBookGraphQuery, OrderBook_OrderBookGraphQueryVariables>(
    OrderBook_OrderBookGraphDocument,
    options
  );
}
export function useOrderBook_OrderBookGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_OrderBookGraphQuery,
    OrderBook_OrderBookGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBook_OrderBookGraphQuery, OrderBook_OrderBookGraphQueryVariables>(
    OrderBook_OrderBookGraphDocument,
    options
  );
}
export type OrderBook_OrderBookGraphQueryHookResult = ReturnType<
  typeof useOrderBook_OrderBookGraphQuery
>;
export type OrderBook_OrderBookGraphLazyQueryHookResult = ReturnType<
  typeof useOrderBook_OrderBookGraphLazyQuery
>;
export type OrderBook_OrderBookGraphQueryResult = Apollo.QueryResult<
  OrderBook_OrderBookGraphQuery,
  OrderBook_OrderBookGraphQueryVariables
>;
export const OrderBook_OrderBookRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_OrderBookRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_PublishedOfferingParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalInstitutionalAllocationSet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVisibleToNonSyndicate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_OrderBookRouteQuery__
 *
 * To run a query within a React component, call `useOrderBook_OrderBookRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_OrderBookRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_OrderBookRouteQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_OrderBookRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_OrderBookRouteQuery,
    OrderBook_OrderBookRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBook_OrderBookRouteQuery, OrderBook_OrderBookRouteQueryVariables>(
    OrderBook_OrderBookRouteDocument,
    options
  );
}
export function useOrderBook_OrderBookRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_OrderBookRouteQuery,
    OrderBook_OrderBookRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBook_OrderBookRouteQuery, OrderBook_OrderBookRouteQueryVariables>(
    OrderBook_OrderBookRouteDocument,
    options
  );
}
export type OrderBook_OrderBookRouteQueryHookResult = ReturnType<
  typeof useOrderBook_OrderBookRouteQuery
>;
export type OrderBook_OrderBookRouteLazyQueryHookResult = ReturnType<
  typeof useOrderBook_OrderBookRouteLazyQuery
>;
export type OrderBook_OrderBookRouteQueryResult = Apollo.QueryResult<
  OrderBook_OrderBookRouteQuery,
  OrderBook_OrderBookRouteQueryVariables
>;
export const OrderBook_OrderBookSalesStateChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_OrderBookSalesStateChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookSalesStateChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_OrderBookSalesStateChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_OrderBookSalesStateChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_OrderBookSalesStateChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_OrderBookSalesStateChangedSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_OrderBookSalesStateChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_OrderBookSalesStateChangedSubscription,
    OrderBook_OrderBookSalesStateChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_OrderBookSalesStateChangedSubscription,
    OrderBook_OrderBookSalesStateChangedSubscriptionVariables
  >(OrderBook_OrderBookSalesStateChangedDocument, options);
}
export type OrderBook_OrderBookSalesStateChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_OrderBookSalesStateChangedSubscription
>;
export type OrderBook_OrderBookSalesStateChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_OrderBookSalesStateChangedSubscription>;
export const OrderBook_OrderBookStatusChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_OrderBookStatusChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookStatusChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_OrderBookStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_OrderBookStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_OrderBookStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_OrderBookStatusChangedSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_OrderBookStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_OrderBookStatusChangedSubscription,
    OrderBook_OrderBookStatusChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_OrderBookStatusChangedSubscription,
    OrderBook_OrderBookStatusChangedSubscriptionVariables
  >(OrderBook_OrderBookStatusChangedDocument, options);
}
export type OrderBook_OrderBookStatusChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_OrderBookStatusChangedSubscription
>;
export type OrderBook_OrderBookStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_OrderBookStatusChangedSubscription>;
export const OrderBook_ReleaseFinalInstitutionalAllocationSetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_ReleaseFinalInstitutionalAllocationSet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseFinalInstitutionalAllocationSet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setUnallocatedToZero' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutationFn = Apollo.MutationFunction<
  OrderBook_ReleaseFinalInstitutionalAllocationSetMutation,
  OrderBook_ReleaseFinalInstitutionalAllocationSetMutationVariables
>;

/**
 * __useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation__
 *
 * To run a mutation, you first call `useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookReleaseFinalInstitutionalAllocationSetMutation, { data, loading, error }] = useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutation,
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutation,
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutationVariables
  >(OrderBook_ReleaseFinalInstitutionalAllocationSetDocument, options);
}
export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutationHookResult = ReturnType<
  typeof useOrderBook_ReleaseFinalInstitutionalAllocationSetMutation
>;
export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutationResult =
  Apollo.MutationResult<OrderBook_ReleaseFinalInstitutionalAllocationSetMutation>;
export type OrderBook_ReleaseFinalInstitutionalAllocationSetMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutation,
    OrderBook_ReleaseFinalInstitutionalAllocationSetMutationVariables
  >;
export const OrderBook_UpdateOrderBookSalesStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_UpdateOrderBookSalesState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrderBookSalesStateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderBookSalesState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'orderBookState' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_UpdateOrderBookSalesStateMutationFn = Apollo.MutationFunction<
  OrderBook_UpdateOrderBookSalesStateMutation,
  OrderBook_UpdateOrderBookSalesStateMutationVariables
>;

/**
 * __useOrderBook_UpdateOrderBookSalesStateMutation__
 *
 * To run a mutation, you first call `useOrderBook_UpdateOrderBookSalesStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_UpdateOrderBookSalesStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookUpdateOrderBookSalesStateMutation, { data, loading, error }] = useOrderBook_UpdateOrderBookSalesStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderBook_UpdateOrderBookSalesStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_UpdateOrderBookSalesStateMutation,
    OrderBook_UpdateOrderBookSalesStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_UpdateOrderBookSalesStateMutation,
    OrderBook_UpdateOrderBookSalesStateMutationVariables
  >(OrderBook_UpdateOrderBookSalesStateDocument, options);
}
export type OrderBook_UpdateOrderBookSalesStateMutationHookResult = ReturnType<
  typeof useOrderBook_UpdateOrderBookSalesStateMutation
>;
export type OrderBook_UpdateOrderBookSalesStateMutationResult =
  Apollo.MutationResult<OrderBook_UpdateOrderBookSalesStateMutation>;
export type OrderBook_UpdateOrderBookSalesStateMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_UpdateOrderBookSalesStateMutation,
  OrderBook_UpdateOrderBookSalesStateMutationVariables
>;
export const UpdateOrderBookStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderBookState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderBookState' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderBookState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateOrderBookStateMutationFn = Apollo.MutationFunction<
  UpdateOrderBookStateMutation,
  UpdateOrderBookStateMutationVariables
>;

/**
 * __useUpdateOrderBookStateMutation__
 *
 * To run a mutation, you first call `useUpdateOrderBookStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderBookStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderBookStateMutation, { data, loading, error }] = useUpdateOrderBookStateMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateOrderBookStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderBookStateMutation,
    UpdateOrderBookStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderBookStateMutation, UpdateOrderBookStateMutationVariables>(
    UpdateOrderBookStateDocument,
    options
  );
}
export type UpdateOrderBookStateMutationHookResult = ReturnType<
  typeof useUpdateOrderBookStateMutation
>;
export type UpdateOrderBookStateMutationResult =
  Apollo.MutationResult<UpdateOrderBookStateMutation>;
export type UpdateOrderBookStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderBookStateMutation,
  UpdateOrderBookStateMutationVariables
>;

import {
  UseWithSubscriptionOptions,
  useWithLazySubscription,
  useWithSubscription,
} from '../../../../graphql/with-subscription';

type UseWithOrderBook_AttestationStatusChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_AttestationStatusChangedSubscription,
    OrderBook_AttestationStatusChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_AttestationStatusChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_AttestationStatusChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_AttestationStatusChangedDocument,
  });
};

export const useWithOrderBook_AttestationStatusChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_AttestationStatusChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_AttestationStatusChangedDocument,
  });
};

type UseWithOrderBook_IndicationChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_IndicationChangedSubscription,
    OrderBook_IndicationChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_IndicationChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_IndicationChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_IndicationChangedDocument,
  });
};

export const useWithOrderBook_IndicationChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_IndicationChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_IndicationChangedDocument,
  });
};

type UseWithOrderBook_InvestorRelationshipChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_InvestorRelationshipChangedSubscription,
    OrderBook_InvestorRelationshipChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_InvestorRelationshipChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InvestorRelationshipChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_InvestorRelationshipChangedDocument,
  });
};

export const useWithOrderBook_InvestorRelationshipChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InvestorRelationshipChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_InvestorRelationshipChangedDocument,
  });
};

type UseWithOrderBook_OrderBookSalesStateChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_OrderBookSalesStateChangedSubscription,
    OrderBook_OrderBookSalesStateChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_OrderBookSalesStateChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_OrderBookSalesStateChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_OrderBookSalesStateChangedDocument,
  });
};

export const useWithOrderBook_OrderBookSalesStateChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_OrderBookSalesStateChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_OrderBookSalesStateChangedDocument,
  });
};

type UseWithOrderBook_OrderBookStatusChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_OrderBookStatusChangedSubscription,
    OrderBook_OrderBookStatusChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_OrderBookStatusChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_OrderBookStatusChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_OrderBookStatusChangedDocument,
  });
};

export const useWithOrderBook_OrderBookStatusChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_OrderBookStatusChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_OrderBookStatusChangedDocument,
  });
};

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeOrderBook_GetOfferingFilingsSharesMockResponse = makeMockResponse<
  OrderBook_GetOfferingFilingsSharesQueryVariables,
  OrderBook_GetOfferingFilingsSharesQuery
>(OrderBook_GetOfferingFilingsSharesDocument);

export const makeOrderBook_OfferingAndOrderBookStatusMockResponse = makeMockResponse<
  OrderBook_OfferingAndOrderBookStatusQueryVariables,
  OrderBook_OfferingAndOrderBookStatusQuery
>(OrderBook_OfferingAndOrderBookStatusDocument);

export const makeOrderBook_OrderBookGraphMockResponse = makeMockResponse<
  OrderBook_OrderBookGraphQueryVariables,
  OrderBook_OrderBookGraphQuery
>(OrderBook_OrderBookGraphDocument);

export const makeOrderBook_OrderBookRouteMockResponse = makeMockResponse<
  OrderBook_OrderBookRouteQueryVariables,
  OrderBook_OrderBookRouteQuery
>(OrderBook_OrderBookRouteDocument);
