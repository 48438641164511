import {
  SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
  useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation,
} from '../graphql';

export type UpdateNonSyndicateSellingGroupTermsRetentionWireMutation =
  SyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation;

export const useUpdateNonSyndicateSellingGroupTermsRetentionWireMutation = () => {
  return useSyndicateWires_UpdateNonSyndicateSellingGroupTermsRetentionWireMutation();
};
