import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  text-align: start;
`;

export const StyledCopyToClipboardIcon = styled(Icon).attrs({ name: 'copy' })`
  margin-left: 8px;
`;
