import React from 'react';

import OfferingSetupHeader from '../header/OfferingSetupHeader';
import { SLoadingWrapper, StyledLinkButton, StyledPanel } from './OfferingSetupScreen.styles';

type Props = {
  testId?: string;
};

export const OfferingSetupScreen: React.FC<Props> = ({ children, testId, ...rest }) => (
  <StyledPanel data-test-id={testId} {...rest}>
    {children}
  </StyledPanel>
);

export default Object.assign(OfferingSetupScreen, {
  LinkButton: StyledLinkButton,
  Header: OfferingSetupHeader,
  Panel: StyledPanel,
  LoadingWrapper: SLoadingWrapper,
});
