import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

import SetupForm from '../components/form/OfferingSetupForm';

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
`;

export const StyledDateTimeColumn = styled(SetupForm.Column)`
  flex-basis: 35%;
`;
