import { DateRangePresetTypes, DateRangeValue, timeUtil } from '@cmg/common';
import { addDays, subDays } from 'date-fns';

export const getDaysRangeFromToday = (last: number, next: number): DateRangeValue => {
  const today = new Date();
  const lastNdays = subDays(today, last);
  const nextNdays = addDays(today, next);
  const start = timeUtil.formatAsISODate(lastNdays)!;
  const end = timeUtil.formatAsISODate(nextNdays)!;

  return {
    start,
    end,
    type: DateRangePresetTypes.DEFAULT,
  };
};
