import { CheckCircleIcon, Link, LockIcon, Stack, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  title: string;
  linkToFix: string;
  howToFixText: string;
  hasPermissionsToFix: boolean;
  missingPermissionsTooltipText?: string;
}>;

export const ProspectusHistoryValidationError: React.VFC<Props> = ({
  title,
  howToFixText,
  linkToFix,
  hasPermissionsToFix,
  missingPermissionsTooltipText,
}) => {
  return (
    <Stack direction="row" gap={1}>
      <Typography color="action.disabled" sx={{ paddingTop: '1px' }}>
        <CheckCircleIcon fontSize="inherit" color="inherit" />
      </Typography>
      <Stack direction="column">
        {hasPermissionsToFix ? (
          <Link href={linkToFix} target="_blank">
            {title}
          </Link>
        ) : (
          <Typography variant="body1">{title}</Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          {howToFixText}
        </Typography>
      </Stack>
      {!hasPermissionsToFix && (
        <Tooltip arrow placement="top" title={missingPermissionsTooltipText}>
          <Typography color="action.active" sx={{ paddingTop: '1px' }}>
            <LockIcon fontSize="inherit" color="inherit" titleAccess="Missing Permissions Icon" />
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );
};
