import { Icon } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SGroup = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.designSystem.colors.gray['000']};
  display: flex;
  cursor: pointer;
  border-bottom: ${({ theme }) =>
    css`
      ${theme.border.width.small} solid ${theme.designSystem.colors.gray['300']}
    `};
  user-select: none;
`;

export const StyledIcon = styled(Icon)`
  align-self: center;
`;

export const STitle = styled.span`
  margin-right: 8px;
  margin-left: 4px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  flex: 1;
`;
