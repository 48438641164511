import { ColDef } from 'ag-grid-community';

import { SFirmNameColumn } from './CrmSelectGrid.styles';
import CrmSelectionRadioCheckboxRenderer from './grid-components/CrmSelectionRadioCheckboxRenderer';

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.2,
};

const defaultColumnDef: ColDef = {
  sortable: false,
  editable: false,
  suppressMovable: true,
  lockPinned: true,
  cellStyle,
};

export const getCrmSelectGridColumns = onchange => [
  {
    ...defaultColumnDef,
    colId: 'selected',
    headerName: '',
    cellRendererFramework: CrmSelectionRadioCheckboxRenderer,
    maxWidth: 45,
    cellRendererParams: onchange,
  },
  {
    ...defaultColumnDef,
    colId: 'firmName',
    headerName: 'Firm Name',
    cellRendererFramework: params => <SFirmNameColumn>{params.data.firmName}</SFirmNameColumn>,
  },
  {
    ...defaultColumnDef,
    colId: 'id',
    headerName: 'Bank Investor Key',
    field: 'id',
  },
  {
    ...defaultColumnDef,
    colId: 'location',
    headerName: 'Location',
    field: 'location',
  },
  {
    ...defaultColumnDef,
    colId: 'internalSalesCoverage',
    headerName: 'Internal Sales Coverage',
    field: 'internalSalesCoverage',
  },
];
