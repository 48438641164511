import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { PinnedCellRenderer } from '../pinned-cell-renderer/PinnedCellRenderer';

export type Props = {
  value: number | null;
  errorMessage?: string;
  targetValue?: number | null;
  acceptZero?: boolean;
};

export const PercentPinnedCellRenderer: React.FC<Props> = ({
  value,
  errorMessage,
  targetValue = 1,
  acceptZero = false,
}) => {
  const safeValue = value ?? 0;
  const subValue = isNil(targetValue) ? null : targetValue - safeValue;

  return (
    <PinnedCellRenderer
      value={value}
      subValue={numericUtil.formatPercents(subValue ?? 0, 4)}
      errorMessage={errorMessage}
      targetValue={numericUtil.formatPercents(targetValue, 4)}
      acceptZero={acceptZero}
    />
  );
};
