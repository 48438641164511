import { useAuth } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  LoadingButton,
  Stack,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { Fragment } from 'react';

import { useContactSupportModalContext } from '../../../context/ContactSupportModalContext';
import {
  ContactSupportModalFormValues,
  Recipient,
  validationSchema,
} from './ContactSupportDialog.model';
import { useContactSupport_SendDlSupportRequestMutation } from './graphql';

export type Props = Readonly<{
  readonly title: string;
  readonly recipientsMissingEmail: Recipient[];
  readonly subject: string;
}>;

const emptyValues: ContactSupportModalFormValues = { subject: '', description: '', email: '' };

export const ContactSupportDialog: React.FC<Props> = ({
  recipientsMissingEmail,
  subject,
  title,
}) => {
  const { close } = useContactSupportModalContext();
  const [sendSupportRequest, { loading }] = useContactSupport_SendDlSupportRequestMutation();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const { oidcUserEmail } = useAuth();

  const formik = useFormik<ContactSupportModalFormValues>({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    initialValues: emptyValues,
    onSubmit: async ({ subject, description }) => {
      try {
        const { data: sendSupportRequestResult } = await sendSupportRequest({
          variables: {
            subject,
            description,
            missingEmailsCmgEntityKeys: recipientsMissingEmail.map(m => m.cmgEntityKey),
          },
        });
        if (sendSupportRequestResult?.sendDLSupportRequest.__typename === 'ServiceError') {
          ToastManager.error('There was an error while sending your support request.');
          return;
        }
        ToastManager.success('Your support request has been successfully sent.');
        setIsSubmitted(true);
      } catch {
        ToastManager.error('There was an error while sending your support request.');
      }
    },
  });

  const { setValues, isValid, handleSubmit } = formik;

  React.useEffect(() => {
    setValues({
      subject,
      description: `Email Distribution List missing for: ${recipientsMissingEmail
        .map(recipient => recipient.firmName)
        .join(', ')}.`,
      email: oidcUserEmail ?? '',
    });
  }, [setValues, recipientsMissingEmail, oidcUserEmail, subject]);

  return (
    <Dialog open={true} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form data-test-id="create-or-update-wire-dialog-form">
            <Stack gap={3}>
              {isSubmitted ? (
                <div>
                  <p>Thank you!</p>
                  <p>
                    We are processing your request. Stay tuned for a confirmation message from our
                    support team. If you don’t hear from us in a few minutes, please check your spam
                    folder or email{' '}
                    <Link href="mailto:support@cmgx.io" component="a">
                      support@cmgx.io
                    </Link>
                    .
                  </p>
                </div>
              ) : (
                <Fragment>
                  <Alert severity="info">
                    Please complete the form below detailing your request. If you know the
                    recipient’s email address, please include it in the description and we will
                    update it as soon as possible.
                  </Alert>

                  <FormikTextField label="Email" name="email" disabled />
                  <FormikTextField
                    label="Subject"
                    name="subject"
                    required
                    disabled={loading}
                    placeholder="Enter Subject"
                  />
                  <FormikTextField
                    label="Description"
                    name="description"
                    rows={3}
                    required
                    disabled={loading}
                    placeholder="Enter Description"
                    multiline
                  />
                </Fragment>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        {close && (
          <Button
            onClick={close}
            variant={isSubmitted ? 'contained' : 'outlined'}
            color="primary"
            disabled={loading}
          >
            {isSubmitted ? 'Close' : 'Cancel'}
          </Button>
        )}
        {!isSubmitted && (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
