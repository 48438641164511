import { Stack, Typography } from '@cmg/design-system';
import React from 'react';

import emptyStateImage from '../../../../../../common/assets/empty-state-image.svg';

const IndicationFormEmptyState: React.FC = () => {
  return (
    <Stack alignItems="center" width="100%" height="228" gap={1} padding="50px 32px 50px 32px">
      <img src={emptyStateImage} alt="ioi-form-empty-state" width="80" height="80" />
      <Stack alignItems="center" width="100%" height="228" gap={1}>
        <Typography variant="h3" color="text.primary">
          Order Book Is Not Open
        </Typography>
        <Typography variant="body1" color="textSecondary">
          To submit an IOI the Order Book must be open.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default IndicationFormEmptyState;
