import { useAuth } from '@cmg/auth';
import { useMemo } from 'react';

import {
  SyndicateWires_BrokerDealersMyRetailQueryVariables,
  useSyndicateWires_BrokerDealersMyRetailQuery,
} from '../graphql';

export const useBrokerDealersMyRetailQuery = (
  variables: SyndicateWires_BrokerDealersMyRetailQueryVariables
) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const { data, ...query } = useSyndicateWires_BrokerDealersMyRetailQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const managersWithoutCurrent = useMemo(
    () =>
      data?.syndicateWires.brokerDealersMyRetail.filter(
        ({ cmgEntityKey }) => cmgEntityKey !== oidcUserCmgEntityKey
      ),
    [data?.syndicateWires.brokerDealersMyRetail, oidcUserCmgEntityKey]
  );

  return {
    data: managersWithoutCurrent,
    ...query,
  };
};
