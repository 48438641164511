import { ApolloError } from '@apollo/client';
import React from 'react';

import Banner from '../banner/Banner';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
/**
 * Extracts error messages from an ApolloError following an order of precedence
 *  1. graphql network error
 *  2. graphql error with well formed details (body.error.details which comes from dotnet REST response body)
 *  3. graqhql error with apollo server generated message
 *  4. default error message
 * @param error
 * @returns deduped string array of all error messages
 */
export const extractApolloErrorMessages = (error: ApolloError): string[] => {
  // if it has a NetworkError, display that
  if (error.networkError) {
    return [error.networkError.message ?? DEFAULT_ERROR_MESSAGE];
  }

  // if it has a GraphQLError with well formed details (body.error.details), display the detail message
  // if it is not a well formed message, display the apollo server generated message
  const messages = error.graphQLErrors.reduce((messageAccumulator, curr) => {
    // well formed dotnet REST response body
    if (Array.isArray(curr.extensions?.response?.body?.error?.details)) {
      curr.extensions?.response?.body?.error?.details.forEach(item => {
        if (item?.message) {
          messageAccumulator.add(item?.message);
        }
      });
    } else {
      // apollo server generated message
      if (curr.message) {
        messageAccumulator.add(curr.message);
      }
    }
    return messageAccumulator;
  }, new Set<string>()); // Set to remove duplicates

  if (messages.size) {
    return [...messages];
  }

  return [DEFAULT_ERROR_MESSAGE];
};

export type Props = {
  error: ApolloError;
};

/**
 * ServerErrorsBanner displays the error message of an ApolloError.
 *
 * This is tightly coupled to both the ApolloError model and CMG's .NET REST error response model.
 */
const ServerErrorsBanner: React.FC<Props> = ({ error }) => {
  return (
    <Banner variant="error" label={error.message}>
      <div>
        {extractApolloErrorMessages(error).map(message => (
          <div key={message}>{message}</div>
        ))}
      </div>
    </Banner>
  );
};

export default ServerErrorsBanner;
