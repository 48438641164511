import { expensesCategoryOptions } from '../../../../../types/domain/final-settlement/constants';
import { SearchSuffix } from '../../../../roadshows/overview/all-roadshows/filters/suffix/Suffix';
import { useExpensesFilterContext } from '../../context/ExpensesFilterContext';
import { SFilterWrapper, StyledSelect, StyledTextInput } from './ExpensesFilter.styles';

const ExpensesFilter: React.FC = ({ children }) => {
  const { setCategoryFilter, setDescriptionFilter, categoryFilter, descriptionFilter } =
    useExpensesFilterContext();
  return (
    <SFilterWrapper>
      <StyledSelect
        placeholder="Select Category..."
        options={expensesCategoryOptions}
        value={categoryFilter === '' ? null : categoryFilter}
        onChange={value => setCategoryFilter(value === null ? '' : (value as string))}
      />
      <StyledTextInput
        placeholder="Search Detailed Description..."
        suffix={<SearchSuffix />}
        onChange={setDescriptionFilter}
        value={descriptionFilter}
      />
      {children}
    </SFilterWrapper>
  );
};

export default ExpensesFilter;
