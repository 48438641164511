import React from 'react';

import { useGetPublishedOfferingEntitlementsQuery } from '../check-entitlements/useGetPublishedOfferingEntitlementsQuery';

type Props = {
  offeringId: string;
};

type AccessCheckResult = {
  loading: boolean;
  canManage: boolean;
};

const emptyArray = [];

/**
 * Checks accessibility of an Institutional Manage Passive OB.
 */
export function useCheckInstitutionalPassiveOrderBookAccess({
  offeringId,
}: Props): AccessCheckResult {
  const { data, loading } = useGetPublishedOfferingEntitlementsQuery({ offeringId });
  const entitlements = data?.publishedOffering.entitlements?.entitlements ?? emptyArray;

  const accessChecks = React.useMemo(
    () => ({
      canManage: entitlements.includes('MANAGE:PASSIVE_ORDER_BOOK'),
    }),
    [entitlements]
  );

  return { loading, ...accessChecks };
}

export default useCheckInstitutionalPassiveOrderBookAccess;
