import styled from 'styled-components/macro';

import FlexLayout from '../../../layout/flex-layout/FlexLayout';

export const SFirmMetaData = styled.div`
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SListOption = styled(FlexLayout)<{ isSelected?: boolean }>`
  justify-content: space-between;

  ${SFirmMetaData} {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.text.color.white : theme.designSystem.colors.gray['500']};
  }
`;

export const SInfoIconWrapper = styled.div`
  display: inline;
  margin: 0 5px;
  float: right;
`;

export const SSpan = styled.span`
  background-color: ${({ theme }) => theme.background.color.dark};
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
`;

export const STitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
