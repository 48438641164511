import * as yup from 'yup';

import { AssignContactInput } from '../../../../../../graphql';
import { InvestorContactInformation_ContactCollectionPartsFragment } from './graphql/__generated__';

export type InvestorContact = {
  name: string;
  email: string;
  isCustom: boolean;
};

export type InvestorContactValues = {
  buySideContacts: InvestorContact[];
  sellSideContacts: InvestorContact[];
};

export const CONTACT_REQUIRED = 'CONTACT_REQUIRED';

export const contactsArraySchema = yup
  .array()
  .label('Investor Contacts')
  .test(
    'contact-required',
    CONTACT_REQUIRED,
    (value: InvestorContact[] | undefined, { options, parent }) => {
      if (options.context?.prospectusContactMandatory) {
        const buySideContacts = (parent?.buySideContacts as InvestorContact[]) ?? [];
        if (buySideContacts.length > 0) {
          return true;
        }
        if (value === undefined || value.length === 0) {
          return false;
        }
        return value.some(item => item.email && item.name);
      } else {
        return true; // Skip this test if contact is not required
      }
    }
  )
  .of(
    yup.object().shape(
      {
        name: yup
          .string()
          .max(255)
          .label('Contact Name')
          .required('Contact Name is a required field')
          .when('email', {
            is: email => email && email.length > 0,
            then: schema => schema.required(),
          }),
        email: yup
          .string()
          .max(255)
          .email()
          .label('Email Address')
          .required('Email Address is a required field')
          .when('name', {
            is: name => name && name.length > 0,
            then: schema => schema.required(),
          }),
      },
      [['name', 'email']]
    )
  );

export const contactsValidationSchema = yup.object().shape({
  sellSideContacts: contactsArraySchema,
});

export function mapToFormValues(
  item: InvestorContactInformation_ContactCollectionPartsFragment['contacts'][number]
): InvestorContact {
  return {
    email: item.email,
    name: item.name,
    isCustom: false,
  };
}

export function mapValuesToInput(values: InvestorContact[]): AssignContactInput[] {
  return values
    .filter(item => item.email !== '' && item.name !== '') // Ignore empty rows
    .map(item => ({
      email: item.email,
      name: item.name,
    }));
}
