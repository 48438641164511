import { UUID } from '@cmg/common';
import {
  Box,
  CheckCircleIcon,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  MoreVertOutlinedIcon,
  Stack,
  Typography,
} from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useRef, useState } from 'react';

import { OfferingStatus, OrderBookState } from '../../../../../graphql';
import { DemandGridV2Switch } from '../../../components/demand-grid-v2-switch/DemandGridV2Switch';

export type Props = Readonly<{
  canManageAllocations: boolean;
  isFinalAllocationSetReleased: boolean;
  isReleaseFinalAllocationSetLoading: boolean;
  offeringId: UUID;
  orderBookState: OrderBookState | undefined;
  offeringStatus: OfferingStatus | undefined;
  isUpdateOrderBookStatusLoading: boolean;
  onReleaseFinalAllocations: () => void;
  onUpdateOrderBookStatus: () => void;
  isVisibleToNonSyndicate?: boolean;
}>;

export const SubNavActionMenu: React.FC<Props> = ({
  offeringId,
  offeringStatus,
  orderBookState,
  isFinalAllocationSetReleased,
  isUpdateOrderBookStatusLoading,
  isReleaseFinalAllocationSetLoading,
  canManageAllocations,
  onReleaseFinalAllocations,
  onUpdateOrderBookStatus,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isReleaseAllocationsDisabled =
    orderBookState !== OrderBookState.Closed ||
    offeringStatus !== OfferingStatus.Priced ||
    isReleaseFinalAllocationSetLoading;

  return (
    <Box>
      <IconButton aria-label="Order Book actions" onClick={() => setOpen(true)} ref={buttonRef}>
        <MoreVertOutlinedIcon color="primary" />
      </IconButton>

      <Menu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        <DemandGridV2Switch Component={ListItem} />
        {canManageAllocations && !isFinalAllocationSetReleased && (
          <MenuItem disabled={isReleaseAllocationsDisabled} onClick={onReleaseFinalAllocations}>
            Release Allocations
          </MenuItem>
        )}
        {isFinalAllocationSetReleased && (
          <ListItem>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              data-test-id={xcSelectors.orderBookHeaderAllocationsReleasedStatus.testId}
              flexWrap="nowrap"
            >
              <CheckCircleIcon fontSize="small" color="success" />
              <Typography>Final Allocations Released</Typography>
            </Stack>
          </ListItem>
        )}
      </Menu>
    </Box>
  );
};
