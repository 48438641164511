import { Button, Stack } from '@cmg/design-system';
import React from 'react';

import Modal from '../../../../../common/components/overlays/modal/Modal';

export type Props = {
  calculatedField: string;
  show: boolean;
  onCalculate: () => void;
  onHide: () => void;
};

export const OverwriteModal: React.FC<Props> = ({ calculatedField, onCalculate, onHide, show }) => {
  const handleConfirm = () => {
    onHide();
    onCalculate();
  };

  return (
    <Modal show={show} title={`Calculate ${calculatedField}`} size="small">
      <Modal.Content>
        This action may result in overwriting current values. Would you like to continue?
      </Modal.Content>
      <Modal.Footer>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Button variant="outlined" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Calculate
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};
