import React from 'react';
import { useTheme } from 'styled-components/macro';

import StatusComponent from '../../../../../../../../common/components/indicators/status/Status';
import {
  SLabel,
  SStatus,
  StyledStatusIcon,
} from '../../../../../../../../common/components/indicators/status/Status.styles';
import { EventStatus } from '../../../../../../../../graphql';

type Props = {
  status?: EventStatus;
};

/**
 * Render Roadshow event status
 */
const MeetingStatus: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  if (!status) {
    return (
      <SStatus>
        <StyledStatusIcon name="circle" variant="solid" size="sm" color={theme.text.color.white} />
        <SLabel>New meeting</SLabel>
      </SStatus>
    );
  }

  return (
    <StatusComponent
      testId="meeting status"
      options={() => ({
        [EventStatus.Draft]: {
          label: 'Draft',
          color: theme.designSystem.colors.gray[200],
        },
        [EventStatus.Cancelled]: {
          label: 'Cancelled',
          color: theme.designSystem.colors.red[100],
        },
        [EventStatus.Confirmed]: {
          label: 'Confirmed',
          color: theme.designSystem.colors.green[300],
        },
        [EventStatus.OpenToSales]: {
          label: 'Open To Sales',
          color: theme.designSystem.colors.yellow[300],
        },
      })}
      status={status}
    />
  );
};

export default MeetingStatus;
