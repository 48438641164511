import { numericUtil, Popover, UUID } from '@cmg/common';
import findIndex from 'lodash/findIndex';
import React from 'react';
import { Link } from 'react-router-dom';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { MdlAllocationIoiType, MdlIndicationPricingType } from '../../../../graphql';
import offeringSidePanelBuySideRouteFactory from '../../offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from '../../OfferingSidePanelContainer';
import {
  InternalDemandSummary_MdlIndicationPartsFragment,
  useInternalDemandSummary_InternalAllocationDemandQuery,
  useInternalDemandSummary_InternalIndicationsDemandQuery,
} from './graphql';
import { formatPreface } from './InternalDemandSummary.model';
import { SHeading, SRow, SWrapper } from './InternalDemandSummary.styles';

export type Props = {
  offeringId: UUID;
};

const InternalDemandSummary: React.FC<Props> = ({ offeringId }) => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);
  const [baseIndication, setBaseIndication] =
    React.useState<InternalDemandSummary_MdlIndicationPartsFragment | null>(null);
  const [remainingIndications, setRemainingIndications] = React.useState<
    InternalDemandSummary_MdlIndicationPartsFragment[] | null
  >(null);
  const {
    data: dataAllocation,
    loading: loadingAllocation,
    error: errorAllocation,
  } = useInternalDemandSummary_InternalAllocationDemandQuery({
    variables: {
      offeringId,
    },
  });

  const {
    data: dataIndications,
    loading: loadingIndications,
    error: errorIndications,
  } = useInternalDemandSummary_InternalIndicationsDemandQuery({
    variables: {
      offeringId,
    },
  });

  const indications = dataIndications?.internalIndicationsDemand?.indications;

  const loading = loadingAllocation || loadingIndications;
  const error = errorAllocation || errorIndications;

  React.useEffect(() => {
    if (indications && indications.length > 0) {
      // determine base indication to display
      // always show market interest indication, unless no market indication, then show highest limit interest indication.
      // only care about internalIndications ie ones with
      // [Realdemanddollars, realDemandPercentage, realDemandShares]
      const internalIndications: InternalDemandSummary_MdlIndicationPartsFragment[] =
        indications.filter(indication =>
          ['realDemandDollars', 'realDemandPercentage', 'realDemandShares'].some(
            key => indication[key]
          )
        );

      const limitIndications = internalIndications
        .filter(
          indication =>
            indication.limitPrice ||
            indication.limitType === 'DISCOUNT' ||
            indication.limitType === 'PREMIUM'
        )
        .sort(
          (a, b) => (b.limitPrice! || b.limitPercentage!) - (a.limitPrice! || a.limitPercentage!)
        );

      // find market indication or highest limit indication
      const marketIndex = findIndex(internalIndications, {
        pricingType: MdlIndicationPricingType.Market,
      });

      const hasMarket = marketIndex !== -1;

      if (hasMarket) {
        setBaseIndication(internalIndications[marketIndex]);
      }

      if (limitIndications.length > 0) {
        if (!hasMarket) {
          setBaseIndication(limitIndications[0]);
          limitIndications.splice(0, 1);
        }
        setRemainingIndications(limitIndications);
      }
    }
  }, [indications, setBaseIndication, setRemainingIndications]);

  const determineStringSpan = (
    indication: InternalDemandSummary_MdlIndicationPartsFragment
  ): React.ReactNode => {
    const isMarket = indication && indication.pricingType === MdlIndicationPricingType.Market;
    const preface = formatPreface(indication);
    let postface = '';
    if (!isMarket) {
      if (indication.limitType === 'DISCOUNT') {
        postface = `Discount ${numericUtil.formatPercents(indication.limitPercentage, 2)}`;
      } else if (indication.limitType === 'PREMIUM') {
        postface = `Premium ${numericUtil.formatPercents(indication.limitPercentage, 2)}`;
      } else {
        postface = `$${numericUtil.formatNumber(indication?.limitPrice)}`;
      }
    }
    // composite key used when useing in a map interator
    return (
      <span key={`indication-${indication.pricingType}-${indication.limitPrice}`}>
        {preface} @ {isMarket ? 'Market' : postface}
      </span>
    );
  };

  const ioiType = dataAllocation?.internalAllocationDemand?.allocation?.ioiType;
  const isNormalIoiType = !ioiType || ioiType === MdlAllocationIoiType.Normal;

  return (
    <SWrapper>
      {error && <ServerErrorsBanner error={error} />}
      <SHeading>Internal Demand Summary</SHeading>
      <SRow>
        {loading && <span>Loading...</span>}
        {!loading && ioiType === MdlAllocationIoiType.Pass && (
          <span>Your firm has indicated a Pass for this offering</span>
        )}
        {!loading && ioiType === MdlAllocationIoiType.RegM && (
          <span>Your firm has indicated a Reg M conflict for this offering</span>
        )}
        {!loading && isNormalIoiType && !baseIndication && (
          <span>No Internal Demand Indications Found</span>
        )}
        {!loading && isNormalIoiType && baseIndication && (
          <React.Fragment>
            <div>
              {determineStringSpan(baseIndication!)}{' '}
              {!!remainingIndications?.length && (
                <Popover
                  content={
                    <div>
                      {remainingIndications
                        .map(determineStringSpan)
                        .reduce((pre, curr, index) => [pre, <br key={`br-${index}`} />, curr])}
                    </div>
                  }
                  placement="top"
                  variant="TOOLTIP"
                  trigger="hover"
                >
                  <Link
                    to={`${baseSidePanelUrl}${offeringSidePanelBuySideRouteFactory.internalDemand.getUrlPath()}`}
                  >
                    {remainingIndications.length} more...
                  </Link>
                </Popover>
              )}
            </div>
            <span>
              <Link
                to={`${baseSidePanelUrl}${offeringSidePanelBuySideRouteFactory.internalDemand.getUrlPath()}`}
              >
                View Details
              </Link>
            </span>
          </React.Fragment>
        )}
      </SRow>
    </SWrapper>
  );
};

export default InternalDemandSummary;
