import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';

import { useCheckRegulatoryFilingsAccess } from '../../../../../common/util/check-access/useCheckRegulatoryFilingsAccess';
import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';

type Props = {
  readonly offeringId: UUID | undefined;
  readonly isSharedDraft: boolean;
  readonly isDiscarded: boolean;
};

export const useRegulatoryFilingsRoute = ({
  offeringId,
  isSharedDraft,
  isDiscarded,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const { canRead } = useCheckRegulatoryFilingsAccess(offeringId);

  if (!offeringId) {
    return undefined;
  }

  if (isSharedDraft) {
    return undefined;
  }

  if (isDiscarded) {
    return undefined;
  }

  if (!canRead) {
    return undefined;
  }

  const href = routeFactory.regulatoryFilings.getUrlPath({ offeringId });
  return {
    'data-test-id': xcSelectors.offeringNavBarRegulatoryFilings.testId,
    href,
    label: 'Reg-M',
  };
};
