import { wireTypeConfigIssuerAllocation } from '../../../../wires/issuer-allocation/IssuerAllocationWire.model';
import { wireTypeConfigIssuerIndication } from '../../../../wires/issuer-indication/IssuerIndicationWire.model';
import { WireTypeNavigationItem } from '../wire-type-navigation-item/WireTypeNavigationItem';
import { useWireTemplateStatus } from './hooks/useWireTemplateStatus';
import { StyledMenuHeading } from './SyndicateWiresSideNav';

export type Props = {
  offeringId: string;
  getWireInfo: ReturnType<typeof useWireTemplateStatus>['getWireInfo'];
};

export const IssuerCommunicationReportsSection: React.FC<Props> = ({ offeringId, getWireInfo }) => (
  <div>
    <StyledMenuHeading>Issuer Communication</StyledMenuHeading>

    {/* Issuer Indication */}
    <WireTypeNavigationItem
      offeringId={offeringId}
      wireTypeName={wireTypeConfigIssuerIndication.wireTypeName}
      wireTypeRoute={wireTypeConfigIssuerIndication.wireTypeRoute}
      wireInfo={getWireInfo(wireTypeConfigIssuerIndication.wireTemplateType)}
    />

    {/* Issuer Allocation */}
    <WireTypeNavigationItem
      offeringId={offeringId}
      wireTypeName={wireTypeConfigIssuerAllocation.wireTypeName}
      wireTypeRoute={wireTypeConfigIssuerAllocation.wireTypeRoute}
      wireInfo={getWireInfo(wireTypeConfigIssuerAllocation.wireTemplateType)}
    />
  </div>
);
