import { UUID } from '@cmg/common';

import { ManagerErrorType } from '../../../../../../../../graphql';
import { useWireTypeConfigContext } from '../../../../context/WireTypeConfigContext';
import { SyndicateWireContextQueryResult } from '../../../../context/WireTypeConfigContext.model';

/**
 * Returns true when the validation EP says my firm doesn't have any emails filled out.
 */
export const useIsMyFirmMissingEmail = ({
  offeringId,
}: {
  offeringId: UUID;
}): SyndicateWireContextQueryResult<boolean> => {
  const validation = useWireTypeConfigContext().useWireValidation({
    offeringId,
    fetchPolicy: 'cache-first',
  });

  return {
    ...validation,
    data: validation.data?.sender.fields.includes(ManagerErrorType.DistributionList),
  };
};
