import { ManagerRole, NotificationMessageStatus } from '../../../graphql';
import {
  RegulatoryFilings_FilingNotificationMessagePartsFragment,
  RegulatoryFilings_RolodexFilingRecipientPartsFragment,
} from '../graphql';

export type RegulatoryFilingRecipient = Readonly<{
  readonly firmName: string;
  readonly cmgEntityKey: string;
  readonly role: ManagerRole;
  readonly distributionList: readonly string[];
  readonly status: NotificationMessageStatus;
}>;

export const getRecipientsFromNotificationFragments = (
  notifications: readonly RegulatoryFilings_FilingNotificationMessagePartsFragment[] | undefined
) => {
  return (
    notifications?.map<RegulatoryFilingRecipient>(notification => ({
      firmName: notification.managerSnapshot.firmName,
      cmgEntityKey: notification.managerSnapshot.cmgEntityKey,
      role: notification.managerSnapshot.role,
      status: notification.status,
      distributionList: notification.distributionList,
    })) ?? []
  );
};

export const getRecipientsFromRecipientFragments = (
  recipients: readonly RegulatoryFilings_RolodexFilingRecipientPartsFragment[] | undefined
): RegulatoryFilingRecipient[] => {
  return (
    recipients?.map<RegulatoryFilingRecipient>(
      ({ firmName, cmgEntityKey, role, distributionList }) => ({
        firmName,
        cmgEntityKey,
        role,
        distributionList,
        status: NotificationMessageStatus.NotSent,
      })
    ) ?? []
  );
};

export function getCanSendNotification(
  notifications: readonly RegulatoryFilings_FilingNotificationMessagePartsFragment[] | undefined
) {
  const unsentNotification =
    notifications?.filter(
      notification =>
        notification.status === NotificationMessageStatus.NotSent &&
        notification.distributionList.length > 0
    ) || [];
  return unsentNotification.length > 0;
}
