import { TableCell, TableHead, TableRow, useTheme } from '@cmg/design-system';
import React from 'react';

import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';

export type Props = Readonly<{
  isReadOnly?: boolean;
}>;

const InternalRetailDemandTableHeader: React.FC<Props> = ({ isReadOnly = false }) => {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell colSpan={2}>Demand</TableCell>
        <TableCell colSpan={2}>Retention</TableCell>
        <TableCell colSpan={4}>Selling Concession</TableCell>
        {!isReadOnly && <TableCell />}
      </TableRow>
      <TableRow>
        <TableCell size="small" />
        <TableCell sx={{ width: '24%', minWidth: theme.spacing(27) }}>
          Broker Dealers <MandatoryAsterisk />
        </TableCell>
        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          Shares <MandatoryAsterisk />
        </TableCell>
        <TableCell sx={{ width: '8%', minWidth: theme.spacing(14) }} align="right">
          Round Lots
        </TableCell>

        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          Shares
        </TableCell>
        <TableCell sx={{ width: '8%', minWidth: theme.spacing(14) }} align="right">
          Round Lots
        </TableCell>
        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          % Received
        </TableCell>
        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          per Share
        </TableCell>
        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          Received by the firm
        </TableCell>
        <TableCell sx={{ width: '10%', minWidth: theme.spacing(18) }} align="right">
          Retained by the bank
        </TableCell>
        {!isReadOnly && <TableCell sx={{ width: theme.spacing(6) }} />}
      </TableRow>
    </TableHead>
  );
};

export default InternalRetailDemandTableHeader;
