import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';

const TotalExpensesInfoBox: React.FC = () => {
  const { managerExpenseReimbursement, dealRelatedExpenses } = useAccountingContext();

  return (
    <AccountingInfoBox gridTemplateColumns="3fr 10px 1.5fr" sourceOfData="Sourced from Expenses">
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Manager Expense Reimbursement
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(managerExpenseReimbursement, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S">Deal Related Expenses</Table.Cell>
      <Table.Cell paddingSize="S" bold>
        +
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(dealRelatedExpenses, 2)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Total Expenses
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForCurrency(
          numericUtil.sum(managerExpenseReimbursement, dealRelatedExpenses),
          2
        )}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default TotalExpensesInfoBox;
