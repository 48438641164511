import { FormikDatePickerField } from '@cmg/design-system-formik';
import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';
import { RecipientsTable } from './components/RecipientsTable';

export const NonSyndicateSellingGroupInvitationWireForm: React.FC<CreateOrUpdateFormProps> = ({
  offeringId,
  operationType,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem xs={6}>
        <FormikDatePickerField name="masterSdaDate" label="Master SDA Date" required />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <RecipientsTable offeringId={offeringId} operationType={operationType} />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
