import { Alert, AlertTitle } from '@cmg/design-system';
import React from 'react';

import { AttestationFormStatus, ProspectusDeliveryStatus } from '../../../../../../../../graphql';
import {
  AttestationFormStatusDisplayName,
  isAttestationStatusValid,
} from '../../../../../../../order-book/attestation/Attestation.model';

export type Props = Readonly<{
  attestationStatus?: AttestationFormStatus;
  prospectusDeliveryStatus?: ProspectusDeliveryStatus;
  isMissingContacts: boolean;
}>;

const ComplianceErrorBanner: React.FC<Props> = ({
  isMissingContacts,
  prospectusDeliveryStatus,
  attestationStatus,
}) => {
  const attestationInvalid = attestationStatus && !isAttestationStatusValid(attestationStatus);
  const prospectusInvalid = prospectusDeliveryStatus === ProspectusDeliveryStatus.Failed;

  if (!attestationInvalid && !prospectusInvalid && !isMissingContacts) {
    return null;
  }

  return (
    <Alert severity="error">
      <AlertTitle>Action required for this indication</AlertTitle>

      {attestationInvalid && (
        <div> 5130/31 {AttestationFormStatusDisplayName[attestationStatus]}</div>
      )}
      {isMissingContacts && <div>Investor contacts missing</div>}
      {prospectusInvalid && <div>Prospectus failed</div>}
    </Alert>
  );
};

export default ComplianceErrorBanner;
