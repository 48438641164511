import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import emptyStateImage from '../../../../../common/assets/empty-state-image.svg';
import { InvestorFirmOption } from '../../../../../common/components/form/investor-firm-search/InvestorFirmSearch';
import { StyledTableCell } from '../../../../order-book/institutional-demand/components/compliance-allocation-release-modal/components/compliance-table/ComplianceTableRow.styles';
import { HouseAccountValue } from '../SalesCreditsHouseAccountsModal.model';
import {
  SEmptyStateText,
  SEmptyWrapper,
  STableWrapper,
  StyledInvestorFirmsSearch,
  StyledLinkButton,
  SWrapper,
} from './SalesCreditsHouseAccountsForm.styles';

export type Props = {
  onAddHouseAccount: (acc: HouseAccountValue) => void;
  onRemoveHouseAccount: (accountIndex: number) => void;
  houseAccounts: HouseAccountValue[];
};

const SalesCreditsHouseAccountsForm: React.FC<Props> = ({
  onAddHouseAccount,
  onRemoveHouseAccount,
  houseAccounts,
}) => {
  const handleAddInvestorFirm = (firm: InvestorFirmOption | null) => {
    if (firm === null) {
      return;
    }
    if (houseAccounts.some(({ id }) => id === firm.firmId)) {
      return;
    }

    onAddHouseAccount({
      id: firm.firmId,
      firmName: firm.label,
      bankInvestorKey: firm.firmKey,
      location: firm.location,
    });
  };

  const isEmpty = houseAccounts.length === 0;

  return (
    <SWrapper>
      <StyledInvestorFirmsSearch
        showCreateNewInvestor={false}
        value={null}
        onChange={handleAddInvestorFirm}
      />

      <STableWrapper>
        <Table gridTemplateColumns="1fr 0.5fr 1.5fr 32px">
          <Table.Row>
            <Table.TableHeaderCell>Firm Name</Table.TableHeaderCell>
            <Table.TableHeaderCell>Bank Investor Key</Table.TableHeaderCell>
            <Table.TableHeaderCell>Location</Table.TableHeaderCell>
            <Table.TableHeaderCell />
          </Table.Row>

          {!isEmpty &&
            houseAccounts.map(({ firmName, bankInvestorKey, location }, index) => (
              <Table.Row key={index} suppressHighlightingOnHover isFocused={false}>
                <StyledTableCell>{firmName}</StyledTableCell>
                <StyledTableCell>{bankInvestorKey || '-'}</StyledTableCell>
                <StyledTableCell>{location || '-'}</StyledTableCell>
                <Table.Cell>
                  <StyledLinkButton
                    iconLeft={{ name: 'trash-alt' }}
                    onClick={() => onRemoveHouseAccount(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table>
      </STableWrapper>

      {isEmpty && (
        <SEmptyWrapper>
          <img src={emptyStateImage} alt="No house accounts added" />
          <SEmptyStateText>No house accounts added</SEmptyStateText>
        </SEmptyWrapper>
      )}
    </SWrapper>
  );
};

export default SalesCreditsHouseAccountsForm;
