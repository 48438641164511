export type PushNotificationHarnessProps = {};

export type PushNotificationHarnessComponentProps = PushNotificationHarnessProps & {};

export type PushNotificationLocalStorage = {
  notificationState: boolean;
};

export type PushNotificationStatus =
  | 'enabled'
  | 'disabled-byUser'
  | 'disabled-byBrowser'
  | 'default';

export const PushNotificationStatusDescriptionMap: Record<PushNotificationStatus, string> = {
  enabled: 'Notifications Enabled',
  default: 'Notifications Not Set',
  'disabled-byBrowser': 'Notifications Disabled',
  'disabled-byUser': 'Notifications Paused',
};

export type NotificationPayload = {
  title: string;
  body: string;
  url: string;
  icon: string;
};
