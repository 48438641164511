import { CriticalActionDialog } from '@cmg/design-system';
import React from 'react';

import { OfferingStage } from '../../../../graphql';

export type Props = {
  firmName: string;
  offeringStage: OfferingStage;
  onHide: () => void;
  onSubmit: () => void;
};

const OFFERING_STAGE_TITLE = {
  [OfferingStage.Draft]: 'Offering Draft',
  [OfferingStage.Published]: 'Published Offering',
};

const OFFERING_STAGE_CHECKBOX_LABEL = {
  [OfferingStage.Draft]: 'Drafts View',
  [OfferingStage.Published]: 'all CMG Calendars',
};

export type DiscardOfferingModalProps = {
  firmName: string;
  offeringStage: OfferingStage;
  open: boolean;
  onHide: () => void;
  onSubmit: () => void;
};

export function DiscardOfferingModal({
  firmName,
  offeringStage,
  open,
  onSubmit,
  onHide,
}: DiscardOfferingModalProps) {
  return (
    <CriticalActionDialog
      open={open}
      title={`${firmName} — Discard ${OFFERING_STAGE_TITLE[offeringStage]}`}
      checkboxLabelText={
        <div>
          I understand that the offering for <b>{firmName}</b> will be Discarded and removed from{' '}
          {OFFERING_STAGE_CHECKBOX_LABEL[offeringStage]}.
        </div>
      }
      actionButtonText="Discard"
      handleClose={onHide}
      onSubmit={onSubmit}
    />
  );
}

export default DiscardOfferingModal;
