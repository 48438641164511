import { ApolloError } from '@apollo/client';
import { CurrencyInputField, numericUtil } from '@cmg/common';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import SetupForm from '../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../components/form/OfferingSetupFormActions';
import { OfferingSetup_FollowOnPricingPartsFragment } from '../graphql';
import { StyledActions, StyledFormField } from './FilingForm.styles';

const validationMessage = 'Must be a positive value';

export const FollowOnPricingSchema = yup.object().shape({
  lastTradeBeforeFiling: yup.number().nullable().positive(validationMessage),
  lastTradeBeforeLaunch: yup.number().nullable().positive(validationMessage),
  lastTradeBeforeOffer: yup.number().nullable().positive(validationMessage),
  reOfferLow: yup.number().nullable().positive(validationMessage),
  reOfferHigh: yup.number().nullable().positive(validationMessage),
});

type FormType = {
  lastTradeBeforeFiling: number | null;
  lastTradeBeforeLaunch: number | null;
  lastTradeBeforeOffer: number | null;
  reOfferLow: number | null;
  reOfferHigh: number | null;
};

export type Props = {
  editing?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onEdit?: () => void;
  onCancel?: () => void;
  onSubmit: (payload: FormType) => void;
  pricingCurrencyCode?: string;
  data?: OfferingSetup_FollowOnPricingPartsFragment;
};

export const FollowOnPricingFormComponent: React.FC<Props> = ({
  editing,
  disabled,
  loading,
  onEdit,
  onCancel,
  onSubmit,
  pricingCurrencyCode,
  data,
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: FollowOnPricingSchema,
    initialValues: {
      lastTradeBeforeFiling: data?.lastTradeBeforeFiling ?? null,
      lastTradeBeforeLaunch: data?.lastTradeBeforeLaunch ?? null,
      lastTradeBeforeOffer: data?.lastTradeBeforeOffer ?? null,
      reOfferLow: data?.reOfferLow ?? null,
      reOfferHigh: data?.reOfferHigh ?? null,
    },
    onSubmit: onSubmit,
  };

  const formik = useFormik(formikOptions);

  const { handleSubmit, resetForm } = formik;

  const actionProps = {
    loading,
    editing,
    disabled,
    onEdit,
    onCancel: () => {
      resetForm();
      onCancel && onCancel();
    },
    onSubmit: handleSubmit,
  };

  return (
    <FormikProvider value={formik}>
      <SetupForm.Card active={editing}>
        <SetupForm.Row>
          <SetupForm.Column>
            {editing ? (
              <CurrencyInputField
                fullWidth
                withMargin
                name="lastTradeBeforeFiling"
                label="Last Trade Before Filing"
                currencyCode={pricingCurrencyCode}
                disabled={loading}
              />
            ) : (
              <StyledFormField fullWidth withMargin label="Last Trade Before Filing">
                {numericUtil.getDisplayValueForCurrency(
                  data?.lastTradeBeforeFiling,
                  2,
                  pricingCurrencyCode
                )}
              </StyledFormField>
            )}
          </SetupForm.Column>
          <SetupForm.Column>
            {editing ? (
              <CurrencyInputField
                fullWidth
                withMargin
                name="lastTradeBeforeLaunch"
                label="Last Trade Before Launch"
                currencyCode={pricingCurrencyCode}
                disabled={loading}
              />
            ) : (
              <StyledFormField fullWidth withMargin label="Last Trade Before Launch">
                {numericUtil.getDisplayValueForCurrency(
                  data?.lastTradeBeforeLaunch,
                  2,
                  pricingCurrencyCode
                )}
              </StyledFormField>
            )}
          </SetupForm.Column>
          <SetupForm.Column>
            {editing ? (
              <CurrencyInputField
                fullWidth
                withMargin
                name="lastTradeBeforeOffer"
                label="Last Trade Before Offer"
                currencyCode={pricingCurrencyCode}
                disabled={loading}
              />
            ) : (
              <StyledFormField fullWidth withMargin label="Last Trade Before Offer">
                {numericUtil.getDisplayValueForCurrency(
                  data?.lastTradeBeforeOffer,
                  2,
                  pricingCurrencyCode
                )}
              </StyledFormField>
            )}
          </SetupForm.Column>
          <SetupForm.Column>
            {editing ? (
              <CurrencyInputField
                fullWidth
                withMargin
                name="reOfferLow"
                label="Re-Offer Low"
                currencyCode={pricingCurrencyCode}
                disabled={loading}
              />
            ) : (
              <StyledFormField fullWidth withMargin label="Re-Offer Low">
                {numericUtil.getDisplayValueForCurrency(data?.reOfferLow, 2, pricingCurrencyCode)}
              </StyledFormField>
            )}
          </SetupForm.Column>
          <SetupForm.Column>
            {editing ? (
              <CurrencyInputField
                fullWidth
                withMargin
                name="reOfferHigh"
                label="Re-Offer High"
                currencyCode={pricingCurrencyCode}
                disabled={loading}
              />
            ) : (
              <StyledFormField fullWidth withMargin label="Re-Offer High">
                {numericUtil.getDisplayValueForCurrency(data?.reOfferHigh, 2, pricingCurrencyCode)}
              </StyledFormField>
            )}
          </SetupForm.Column>
        </SetupForm.Row>
        <StyledActions>
          <OfferingSetupFormActions {...actionProps} />
        </StyledActions>
      </SetupForm.Card>
    </FormikProvider>
  );
};

export default FollowOnPricingFormComponent;
