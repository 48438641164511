import { UUID } from '@cmg/common';
import React from 'react';

import { SyndicateWiresManagerRole } from '../../../../../../graphql';
import { SyndicateWireManager } from '../../../SyndicateWiresRoute.model';
import { useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery } from '../../graphql';
import { managersToRoleGroupedEntries } from '../navigation/managers-side-nav/ManagersNavigation.model';
import {
  ManagerEconomicSharesTable,
  ManagerEconomicsRoleGroup,
} from './ManagerEconomicSharesTable';
import { getManagerWithUnderwritingCommitment } from './ManagerRevisedUnderwritingCommitment.model';

export type Props = {
  offeringId: UUID;
  onDataLoaded?: (isSuccess: boolean) => void;
  recipients: SyndicateWireManager[];
};

const ManagerRevisedUnderwritingCommitment: React.FC<Props> = ({
  offeringId,
  onDataLoaded,
  recipients,
}) => {
  const { data, loading, error, refetch } =
    useSyndicateWires_ManagerRevisedUnderwritingCommitmentQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

  const { managerRevisedUnderwritingCommitment = [] } = data?.syndicateWires ?? {};
  const groupedManagers = React.useMemo<ManagerEconomicsRoleGroup[]>(() => {
    const filteredManagers =
      recipients?.filter(
        manager => manager.role !== SyndicateWiresManagerRole.SellingGroupMember
      ) || [];

    return managersToRoleGroupedEntries(filteredManagers).map(([role, managers]) => {
      return [
        role,
        managers.map(manager => {
          return getManagerWithUnderwritingCommitment(
            managerRevisedUnderwritingCommitment,
            manager
          );
        }),
      ];
    });
  }, [managerRevisedUnderwritingCommitment, recipients]);

  return (
    <ManagerEconomicSharesTable
      label="Manager Revised Underwriting Commitment"
      managerGroups={groupedManagers}
      refetch={refetch}
      error={error}
      loading={loading}
      onDataLoaded={onDataLoaded}
      sharesColLabel="Revised Underwriting Commitment (Shares)"
    />
  );
};

export default ManagerRevisedUnderwritingCommitment;
