import { UUID } from '@cmg/common';
import flatten from 'lodash/flatten';

import {
  Roadshows_DealTeamNameAndDepartmentsPartsFragment,
  Roadshows_RoadshowsDealTeamPartsFragment,
} from '../../../../../common/graphql';

export const getOrganizationDepartments = (
  organizationCmgEntityKey: UUID,
  organizations?: readonly Roadshows_DealTeamNameAndDepartmentsPartsFragment[]
): readonly string[] => {
  return (
    organizations?.find(({ firmCmgEntityKey }) => firmCmgEntityKey === organizationCmgEntityKey)
      ?.departments ?? []
  );
};

export const getParticipatingMemberIds = (
  teamList: readonly Roadshows_RoadshowsDealTeamPartsFragment[]
): UUID[] => {
  return flatten(teamList.map(({ members }) => members.map(({ id }) => id)));
};
