import { DateRangePresetTypes, Option } from '@cmg/common';
import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import * as yup from 'yup';

import { RoadshowInput, RoadshowType } from '../../../../../graphql';
import { Roadshows_RoadshowDetailsQuery } from '../../../common/graphql';
import { useRoadshowUniqueNameValidation } from './hooks/useRoadshowUniqueNameValidation';

export type RoadshowDay = {
  date: string;
  isVirtual: boolean;
  timeZone: string | null | undefined;
  cities: string[];
  timezones: string[];
};

export type RoadshowDetailsValues = {
  roadshowName: string;
  roadshowType: RoadshowType;
  relatedOfferingField: Option | null;
  defaultTimeZone: string | null | undefined;
  dateRange: {
    start?: string;
    end?: string;
    type: DateRangePresetTypes.CUSTOM; // Needed to display value correctly
  };
  roadshowDays: readonly RoadshowDay[];
};

export const roadshowDateRangeSchema = yup
  .object()
  .shape({
    start: yup.date().label('Start Date'),
    end: yup.date().label('End Date'),
  })
  .nullable()
  .test('date-range-invalid', 'Start and End Date are required', range => {
    return !isNil(range?.start) && !isNil(range?.end);
  })
  .test('date-in-future', 'Roadshow must end in the future', range => {
    return new Date(range?.end!).getTime() > Date.now();
  });

export const roadshowDaysSchema = yup.object().shape({
  date: yup.date().label('Date').nullable().required(),
  isVirtual: yup.boolean().label('Virtual Day').nullable().required(),
  timeZone: yup
    .string()
    .label('Timezone')
    .nullable()
    .when('isVirtual', { is: true, then: schema => schema.nullable().required() }),
  cities: yup
    .array()
    .of(yup.string().label('Location').nullable().required())
    .nullable()
    .label('Location')
    .when('isVirtual', { is: value => !value, then: schema => schema.min(1).required() }),
  timezones: yup
    .array()
    .of(yup.string().label('Timezone').nullable().required())
    .nullable()
    .label('Timezone')
    .when('isVirtual', { is: value => !value, then: schema => schema.min(1).required() })
    .test(
      'city-timezones-required',
      'There has to be the same amount of Timezones as Locations',
      (timezones, context) => {
        return timezones?.length === context.parent.cities?.length;
      }
    ),
});

export function getRoadshowDetailsSchema(
  validateRoadshowName: ReturnType<typeof useRoadshowUniqueNameValidation>,
  originalName?: string
) {
  return yup.object().shape({
    roadshowName: yup
      .string()
      .label('Roadshow Name')
      .nullable()
      .required()
      .test('checkRoadshowNameUnique', 'A Roadshow with this name already exists', roadshowName => {
        if (roadshowName && originalName !== roadshowName) {
          return validateRoadshowName(roadshowName);
        } else {
          return true;
        }
      }),
    roadshowType: yup.string().label('Roadshow Type').nullable().required(),
    dateRange: roadshowDateRangeSchema,
    roadshowDays: yup.array().of(roadshowDaysSchema),
    defaultTimeZone: yup.string().label('Default Timezone').nullable(),
    relatedOfferingField: yup
      .string()
      .label('Related Offering')
      .nullable()
      .transform((option: Option) => {
        // this field uses an Option for its value
        return option?.value || null;
      }),
  });
}

export const roadshowDetailsInitialValues: RoadshowDetailsValues = {
  roadshowName: '',
  roadshowType: RoadshowType.Ecm,
  dateRange: {
    type: DateRangePresetTypes.CUSTOM,
  },
  roadshowDays: [],
  relatedOfferingField: null,
  defaultTimeZone: null,
};

export function mapDataToForm(
  roadshow: Roadshows_RoadshowDetailsQuery['roadshow']
): RoadshowDetailsValues {
  return {
    roadshowName: roadshow.roadshowName,
    roadshowType: roadshow.roadshowType,
    dateRange: {
      start: roadshow.startDate,
      end: roadshow.endDate,
      type: DateRangePresetTypes.CUSTOM,
    },
    defaultTimeZone: roadshow.defaultTimeZone,
    roadshowDays: roadshow.roadshowDays.map(day => ({
      date: day.date,
      timeZone: day.timeZone,
      isVirtual: day.isVirtual ?? false,
      timezones: day.cities.map(city => city.timeZone),
      cities: day.cities.map(city => city.name),
    })),
    relatedOfferingField:
      roadshow.offeringId && roadshow.offeringIssuerName
        ? {
            label: roadshow.offeringIssuerName,
            value: roadshow.offeringId,
          }
        : null,
  };
}

export function mapFormToRoadshow(formValues: RoadshowDetailsValues): RoadshowInput {
  return {
    roadshowName: formValues.roadshowName,
    roadshowType: formValues.roadshowType,
    startDate: formValues.dateRange.start!,
    endDate: formValues.dateRange.end!,
    offeringId: formValues.relatedOfferingField?.value,
    defaultTimeZone: formValues.defaultTimeZone,
    roadshowDays: formValues.roadshowDays.map(day => ({
      isVirtual: day.isVirtual,
      timeZone: day.timeZone,
      date: format(new Date(day.date), 'yyyy-MM-dd'),
      cities: day.cities.map((city, index) => ({
        name: city,
        timeZone: day.timezones[index],
      })),
    })),
  };
}
