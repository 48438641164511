import React from 'react';
import { matchPath } from 'react-router-dom';

import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../common/util/routeFactory';

export function useFilingDocumentTitle({
  issuerName,
  pathname,
}: {
  issuerName?: string;
  pathname: string;
}) {
  const { rpnMatch, tnMatch, usdtnMatch, noiMatch, dsMatch } = React.useMemo(
    () => ({
      rpnMatch: matchPath(pathname, {
        path: routeFactory.regulatoryFilingsRPNFiling.routePath,
        exact: false,
        strict: false,
      }),
      tnMatch: matchPath(pathname, {
        path: routeFactory.regulatoryFilingsTNFiling.routePath,
        exact: false,
        strict: false,
      }),
      usdtnMatch: matchPath(pathname, {
        path: routeFactory.regulatoryFilingsUSDTNFiling.routePath,
        exact: false,
        strict: false,
      }),
      noiMatch: matchPath(pathname, {
        path: routeFactory.regulatoryFilingsNOIFiling.routePath,
        exact: false,
        strict: false,
      }),
      dsMatch: matchPath(pathname, {
        path: routeFactory.regulatoryFilingsDSFiling.routePath,
        exact: false,
        strict: false,
      }),
    }),
    [pathname]
  );

  let filingName: string | undefined;
  if (rpnMatch) {
    filingName = 'Restricted Period Notification Form (RPN)';
  }
  if (tnMatch) {
    filingName = 'Trading Notification Form (TN)';
  }
  if (usdtnMatch) {
    filingName = 'Unregistered Secondary Distribution Trading Notification Form (USD-TN)';
  }
  if (noiMatch) {
    filingName = 'Notice of Intent Form (NOI)';
  }
  if (dsMatch) {
    filingName = 'Deal Status Form (DS)';
  }

  useDocumentTitle(
    routeFactory.regulatoryFilings.getDocumentTitle({
      issuerName,
      filingName,
    })
  );
}
