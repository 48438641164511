import { numericUtil, ODSTable as Table, UUID } from '@cmg/common';

import { InfoBox } from '../../../../../common/components/info-box/InfoBox';
import routeFactory from '../../../../../common/util/routeFactory';
import { stepIds } from '../../../constants';
import { SDivider } from '../../ManagerEconomicsRoute.styles';

type Props = {
  offeringId: UUID;
  baseOfferingShares?: number | null;
  exercisedOverallotmentShares?: number | null;
};

export const OfferingSizeCard: React.FC<Props> = ({
  offeringId,
  baseOfferingShares,
  exercisedOverallotmentShares,
}) => {
  const totalDealShares = numericUtil.getDisplayValueForNumber(
    (baseOfferingShares ?? 0) + (exercisedOverallotmentShares ?? 0),
    0
  );

  return (
    <InfoBox
      gridTemplateColumns="3fr 1fr"
      sourceOfData={{
        text: 'Offering Setup: Terms',
        link: routeFactory.offeringSetup.getUrlPath({
          stepId: stepIds.TERMS,
          offeringId,
        }),
      }}
    >
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Offering Size
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Base Offering Shares
      </Table.Cell>
      <Table.Cell
        data-test-id="base-offering-shares"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForNumber(baseOfferingShares, 0)}
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Exercised Overallotment Shares
      </Table.Cell>
      <Table.Cell
        data-test-id="exercised-overallotment-shares"
        paddingSize="S"
        align="right"
        withBottomBorder={false}
      >
        {numericUtil.getDisplayValueForNumber(exercisedOverallotmentShares, 0)}
      </Table.Cell>
      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        <SDivider />
      </Table.Cell>
      <Table.Cell paddingSize="S" withBottomBorder={false} bold>
        Total Deal Shares
      </Table.Cell>
      <Table.Cell
        data-test-id="total-deal-shares"
        paddingSize="S"
        bold
        align="right"
        withBottomBorder={false}
      >
        {totalDealShares}
      </Table.Cell>
    </InfoBox>
  );
};
