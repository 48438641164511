import { Typography } from '@cmg/design-system';
import React from 'react';

import { InterestLevels } from './InterestLevels';
import type { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const IndicationCreatedChangeContent: React.FC<Props> = ({
  update,
  pricingCurrencyCode,
}) => {
  const { investorFirm, newIndication } = update;
  const newInterestLevels = newIndication?.interestLevels ?? [];
  const isScaled = newInterestLevels.length > 1;

  return (
    <Typography aria-label="Recent update - Indication created" component="span">
      <Typography component="span" variant="highlight1">
        {investorFirm}
      </Typography>{' '}
      created.{' '}
      {isScaled ? (
        <React.Fragment>
          Demand: <br />
        </React.Fragment>
      ) : (
        'Demand '
      )}
      <InterestLevels
        interestLevels={newInterestLevels}
        pricingCurrencyCode={pricingCurrencyCode}
        demandCurrencyCode={newIndication?.currencyCode}
      />
      {isScaled ? '' : '.'}
    </Typography>
  );
};
