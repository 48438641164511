import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  background: ${({ theme }) => theme.designSystem.colors.darkBlue[100]};
  margin: 0 -16px;
  padding: 16px;
  display: flex;
  align-items: center;
`;

export const STextWrapper = styled.div`
  flex: auto;
  padding-left: 32px;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;
