import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Spinner from '../../../../../../common/components/overlays/spinner/Spinner';
import {
  MdlAllocationIoiType,
  MdlAllocationRanking,
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
  OfferingType,
} from '../../../../../../graphql';
import FirmLevelDemandBar from '../../../../components/firm-level-demand-bar/FirmLevelDemandBar';
import InterestLevelsWrapper from '../../../../components/interest-levels-wrapper/InterestLevelsWrapper';
import { InterestLevelChildrenProps } from '../../../../components/interest-levels-wrapper/InterestLevelsWrapper.model';
import {
  CommunicatedDemandFormValues,
  CommunicatedDemandValidationSchema,
  CommunicatedDemandValues,
  createInitialValues,
} from './CommunicatedDemandForm.model';
import { SContainer, SMessage, SSpinnerContainer } from './CommunicatedDemandForm.styles';
import Allocation from './components/allocation/Allocation';
import AllocationLastModifiedInfo from './components/indication-submitted-info/AllocationLastModifiedInfo';
import InterestLevel from './components/interest-level/InterestLevel';
import {
  CommunicatedDemandForm_MdlAllocationPartsFragment,
  CommunicatedDemandForm_MdlIndicationPartsFragment,
} from './graphql';

export type Props = {
  indications?: readonly CommunicatedDemandForm_MdlIndicationPartsFragment[];
  allocation?: CommunicatedDemandForm_MdlAllocationPartsFragment | null;
  onSubmit: (
    values: CommunicatedDemandValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  ) => void;
  isEditing?: boolean;
  onChangeEditing: () => void;
  loading?: boolean;
  onChangeAllocationRanking: (ranking: MdlAllocationRanking) => void;
  offeringType: OfferingType;
};

export const CommunicatedDemandFormComponent: React.FC<Props> = ({
  indications,
  allocation,
  isEditing = false,
  onChangeEditing,
  onSubmit,
  loading = false,
  onChangeAllocationRanking,
  offeringType,
}) => {
  const name = allocation?.lastModified.name;
  const submittedOn = allocation?.lastModified.timestamp;
  const allocationRanking = allocation?.ranking;

  const ioiType = allocation?.ioiType;

  const formik = useFormik<
    CommunicatedDemandFormValues<
      MdlIndicationUnitType,
      MdlIndicationPricingType,
      MdlIndicationLimitType
    >
  >({
    initialValues: createInitialValues(indications, allocation),
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: CommunicatedDemandValidationSchema,
    onSubmit: (
      values: CommunicatedDemandFormValues<
        MdlIndicationUnitType,
        MdlIndicationPricingType,
        MdlIndicationLimitType
      >
    ) => {
      const interestLevels = values.interestLevels;
      onSubmit({
        ...values,
        interestLevels: interestLevels.map(({ quantity, ...interestLevel }) => interestLevel) ?? [],
      });
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    onChangeEditing();
  };

  const isNormalIoiType = !ioiType || ioiType === MdlAllocationIoiType.Normal;

  const hasAllocationRanking =
    allocationRanking && allocationRanking !== MdlAllocationRanking.NotIndicated;

  return (
    <FormikProvider value={formik}>
      <Form>
        <FirmLevelDemandBar
          isEditing={isEditing}
          onChangeEditing={onChangeEditing}
          ioiType={ioiType}
          onSubmitForm={formik.submitForm}
          onResetForm={handleResetForm}
          loading={loading}
          title="IOI Tracker"
          hasAllocationRanking={!!hasAllocationRanking}
          convertableToPassOrRegM={false}
          isExecutingOnPlatform={false}
        />
        {loading && (
          <SSpinnerContainer>
            <Spinner show fullHeight />
          </SSpinnerContainer>
        )}
        {!loading && (
          <SContainer>
            {ioiType === MdlAllocationIoiType.Pass && <SMessage>Pass</SMessage>}
            {ioiType === MdlAllocationIoiType.RegM && <SMessage>Reg-M</SMessage>}
            {isNormalIoiType && (
              <React.Fragment>
                {!isEditing && name && submittedOn && (
                  <AllocationLastModifiedInfo name={name} submittedOn={submittedOn} />
                )}
                <InterestLevelsWrapper isEditing={isEditing}>
                  {({
                    interestLevels,
                    ...props
                  }: InterestLevelChildrenProps<
                    MdlIndicationUnitType,
                    MdlIndicationPricingType,
                    MdlIndicationLimitType
                  >) =>
                    interestLevels.map((level, index) => (
                      <InterestLevel
                        {...props}
                        index={index}
                        key={index}
                        offeringType={offeringType}
                      />
                    ))
                  }
                </InterestLevelsWrapper>
                <Allocation
                  isEditing={isEditing}
                  allocation={allocation}
                  onChangeAllocationRanking={onChangeAllocationRanking}
                />
              </React.Fragment>
            )}
          </SContainer>
        )}
      </Form>
    </FormikProvider>
  );
};

export default CommunicatedDemandFormComponent;
