import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import { BoxWrapper, SLabel } from './AccountingInfoBox.styles';

export type Props = { gridTemplateColumns: string; sourceOfData?: string };

const AccountingInfoBox: React.FC<Props> = ({ gridTemplateColumns, sourceOfData, children }) => {
  return (
    <BoxWrapper>
      <Table gridTemplateColumns={gridTemplateColumns}>{children}</Table>

      {sourceOfData && (
        <SLabel data-testid="settlement / accounting / details / info box / source of data">
          {sourceOfData}
        </SLabel>
      )}
    </BoxWrapper>
  );
};

export default AccountingInfoBox;
