import { TextInput } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import {
  SIconContainer,
  SIssuerNameFilter,
  SSeparator,
  StyledSearchIcon,
} from './IssuerFilter.styles';

export type Props = {
  issuerName: string;
  onChange: (value: string | null) => void;
};

/**
 * IssuerFilter sets the filter value for the offering search by issuer name
 */
const IssuerFilter: React.FC<Props> = ({ issuerName, onChange }) => {
  return (
    <SIssuerNameFilter>
      <TextInput
        aria-label="issuer filter"
        value={issuerName}
        onChange={onChange}
        placeholder="Offering..."
        testId={xcSelectors.myOfferingsOfferingSearchTextInput.testId}
        suffix={
          <SIconContainer>
            <SSeparator />
            <StyledSearchIcon />
          </SIconContainer>
        }
      />
    </SIssuerNameFilter>
  );
};

export default IssuerFilter;
