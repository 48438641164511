import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteIssuerIndicationWireMutation,
  SyndicateWires_IssuerIndicationWiresDocument,
  useSyndicateWires_DeleteIssuerIndicationWireMutation,
} from '../graphql';

export type DeleteIssuerIndicationWireMutation = SyndicateWires_DeleteIssuerIndicationWireMutation;

export const useDeleteIssuerIndicationWireMutation = () => {
  return useSyndicateWires_DeleteIssuerIndicationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_IssuerIndicationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
