import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { BrokerValues } from '../InternalRetailDemandTableRow.model';
import { getValidationSchema, initialValues } from './CreateBrokerDealerDialog.model';

export type Props = Readonly<{
  allBrokers: BrokerValues['broker'][];
  isOpen: boolean;
  onClose: () => void;
  onCreate: (brokerName: string) => void;
}>;

const CreateBrokerDealerDialog: React.FC<Props> = ({ allBrokers, isOpen, onClose, onCreate }) => {
  const formik = useFormik({
    validationSchema: getValidationSchema(allBrokers),
    initialValues,
    onSubmit: values => {
      onCreate(values.brokerName);
      resetForm();
    },
  });
  const { resetForm, errors, handleSubmit } = formik;

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle>Create New Broker Dealer</DialogTitle>

          <DialogContent>
            <Grid container rowGap={2}>
              {errors.brokerName && !!formik.submitCount && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    <AlertTitle>
                      An entry is required or has invalid value for the following fields:
                    </AlertTitle>
                    Broker Dealer Name
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <Alert severity="info">
                  The broker dealer will be created for this offering only
                </Alert>
              </Grid>

              <Grid item xs={6}>
                <FormikTextField name="brokerName" label="Broker Dealer Name" required autoFocus />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
              Create
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default CreateBrokerDealerDialog;
