import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { useDispatch } from 'react-redux';

import { extractApolloErrorMessages } from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import {
  OrderBook_OfferingAndOrderBookStatusDocument,
  OrderBook_OrderBookGraphDocument,
  OrderBook_OrderBookRouteDocument,
  useOrderBook_AssignFinalAllocationSetVisibilityMutation,
} from '../graphql';
import { openUpdateComplianceAllocationReleaseModal } from '../institutional-demand/components/compliance-allocation-release-modal/ComplianceAllocationReleaseModal';
import {
  closeReleaseFinalAllocationDialog,
  openReleaseFinalAllocationDialog,
} from '../institutional-demand-v2/dialogs/ReleaseFinalAllocationDialog';
import { useReleaseFinalInstitutionalAllocationSetMutation } from './useReleaseFinalInstitutionalAllocationSetMutation';

export type Props = Readonly<{
  offeringId: UUID;
  isFinalAllocationSetReleased: boolean;
  isVisibleToNonSyndicate: boolean;
  offeringName: string | undefined;
}>;

export const useHandleAssignFinalAllocation = ({
  offeringId,
  isFinalAllocationSetReleased,
  isVisibleToNonSyndicate,
  offeringName,
}: Props) => {
  const dispatch = useDispatch();

  const [
    assignFinalAllocationSetVisibility,
    { loading: isAssignFinalAllocationSetVisibilityLoading },
  ] = useOrderBook_AssignFinalAllocationSetVisibilityMutation({
    onCompleted: ({ assignFinalAllocationSetVisibility: { isVisibleToNonSyndicate } }) => {
      SnackbarManager.success(
        `Successfully ${
          isVisibleToNonSyndicate ? 'released' : 'unreleased'
        } final institutional allocation set`
      );
    },
    onError: error => {
      SnackbarManager.error(extractApolloErrorMessages(error)[0] ?? error.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(OrderBook_OrderBookRouteDocument)!],
  });

  const [releaseFinalInstitutionalAllocationSet, { loading: isReleaseFinalAllocationSetLoading }] =
    useReleaseFinalInstitutionalAllocationSetMutation({
      onCompleted: async ({ releaseFinalInstitutionalAllocationSet: { releaseTimestamp } }) => {
        if (releaseTimestamp) {
          SnackbarManager.success('Successfully released final institutional allocation set');
        } else {
          SnackbarManager.error('Failed to release final institutional allocation set');
        }
      },
      onError: error => {
        SnackbarManager.error(extractApolloErrorMessages(error)[0] ?? error.message);
      },
      refetchQueries: [
        getOperationName(OrderBook_OrderBookGraphDocument)!,
        getOperationName(OrderBook_OfferingAndOrderBookStatusDocument)!,
      ],
    });

  const handleCloseReleaseFinalAllocationsModal = async () => {
    dispatch(closeReleaseFinalAllocationDialog());
    await releaseFinalInstitutionalAllocationSet({
      variables: { offeringId },
    });
    await assignFinalAllocationSetVisibility({
      variables: {
        offeringId,
        visibility: true,
      },
    });
  };

  const handleReleaseFinalAllocationsSubmit = () => {
    if (!isFinalAllocationSetReleased) {
      const nextModal = openReleaseFinalAllocationDialog({
        offeringName,
        onSubmit: () => void handleCloseReleaseFinalAllocationsModal(),
      });

      dispatch(
        openUpdateComplianceAllocationReleaseModal({
          offeringId,
          nextModal,
        })
      );
    } else {
      assignFinalAllocationSetVisibility({
        variables: {
          offeringId,
          visibility: !isVisibleToNonSyndicate,
        },
      });
    }
  };

  return {
    handleReleaseFinalAllocationsSubmit,
    isReleaseFinalAllocationSetLoading:
      isReleaseFinalAllocationSetLoading || isAssignFinalAllocationSetVisibilityLoading,
  };
};
