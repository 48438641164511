import { useAuth } from '@cmg/auth';
import isNil from 'lodash/isNil';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { NonSyndicatePaymentRecipientInputData, WireTemplateType } from '../../../../../graphql';
import {
  createUseDefaultNonSyndicateManagers,
  createUseDefaultNonSyndicateNextRecipients,
  createWireTypeConfig,
  WireTypeConfigInitialValues,
} from '../../common/context/WireTypeConfigContext.model';
import {
  SyndicateWires_BrokerDealerMyRetailFeesPartsFragment,
  SyndicateWires_MyRetailForBrokerDealerPartsFragment,
  useSyndicateWires_BrokerDealersMyRetailFeesQuery,
  useSyndicateWires_ManagerDtcNumbersQuery,
  useSyndicateWires_WiresDisclaimerQuery,
} from '../../common/graphql';
import { createUseDefaultOutdatedNonSyndicateManagersList } from '../../common/hooks/createDefaultUseOutdatedNonSyndicateManagersList';
import { createUseDefaultIsNonSyndicateWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsNonSyndicateWireManagerListOutdated';
import { useBrokerDealersMyRetailQuery } from '../../common/hooks/useBrokerDealersMyRetailQuery';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_NonSyndicatePaymentWirePartsFragment,
  SyndicateWires_NonSyndicatePaymentWireValidationPartsFragment,
  useSyndicateWires_NonSyndicatePaymentWireDetailsQuery,
  useSyndicateWires_NonSyndicatePaymentWirePreviewQuery,
  useSyndicateWires_NonSyndicatePaymentWiresQuery,
  useSyndicateWires_NonSyndicatePaymentWireValidationQuery,
} from './graphql';
import { useCreateNonSyndicatePaymentWireMutation } from './hooks/useCreateNonSyndicatePaymentWireMutation';
import { useDeleteNonSyndicatePaymentWireMutation } from './hooks/useDeleteNonSyndicatePaymentWireMutation';
import { useSendNonSyndicatePaymentWireMutation } from './hooks/useSendNonSyndicatePaymentWireMutation';
import { useUpdateNonSyndicatePaymentWireMutation } from './hooks/useUpdateNonSyndicatePaymentWireMutation';
import { NonSyndicatePaymentWireForm } from './NonSyndicatePaymentWireForm';
import {
  NonSyndicatePaymentRecipientUserData,
  validationSchema,
  Values,
} from './NonSyndicatePaymentWireForm.model';

const wireTypeName = 'Payment';

export const getPredefinedRecipientUserData = (
  manager: SyndicateWireManager,
  initialRecipientsUserData: (NonSyndicatePaymentRecipientUserData | null)[],
  brokerPayments: readonly SyndicateWires_BrokerDealerMyRetailFeesPartsFragment[],
  retail: SyndicateWires_MyRetailForBrokerDealerPartsFragment[]
): NonSyndicatePaymentRecipientUserData | null => {
  const initialManagerValues = initialRecipientsUserData.find(
    initialUserData => initialUserData?.recipient === manager.cmgEntityKey
  );

  const initialPayment = initialManagerValues?.payment;
  const initialSellingConcession = initialManagerValues?.sellingConcession;
  const initialIpoPenaltyTracking = initialManagerValues?.ipoPenaltyTracking;

  const defaultPayment =
    brokerPayments.find(({ brokerCmgEntityKey }) => brokerCmgEntityKey === manager.cmgEntityKey)
      ?.payment ?? null;
  const defaultSellingConcession =
    retail.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey)?.sellingConcession ??
    null;

  if (
    isNil(initialPayment) &&
    isNil(defaultPayment) &&
    isNil(initialSellingConcession) &&
    isNil(defaultSellingConcession) &&
    isNil(initialIpoPenaltyTracking)
  ) {
    return null;
  } else {
    return {
      recipient: manager.cmgEntityKey,
      payment: initialPayment ?? defaultPayment,
      ipoPenaltyTracking: initialIpoPenaltyTracking ?? false,
      sellingConcession: initialSellingConcession ?? defaultSellingConcession,
    };
  }
};

const getNonSyndicatePaymentRecipientInputData = (formValues: Values) => {
  const { disclaimer, dtcNumber, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<NonSyndicatePaymentRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        ipoPenaltyTracking: recipientUserData!.ipoPenaltyTracking,
        payment: recipientUserData!.payment!,
        sellingConcession: recipientUserData!.sellingConcession!,
        dtcNumber: dtcNumber!,
        disclaimer,
      };
    });
};

export const wireTypeConfigNonSyndicatePayment = createWireTypeConfig<
  Values,
  SyndicateWires_NonSyndicatePaymentWirePartsFragment,
  SyndicateWires_NonSyndicatePaymentWireValidationPartsFragment,
  typeof routeFactory.nonSyndicateWiresPayment
>({
  wireTypeName,
  wireTypeRoute: routeFactory.nonSyndicateWiresPayment,
  wireTemplateType: WireTemplateType.NonSyndicateMemberPayment,
  // @ts-expect-error schema type mismatch on ipoPenaltyTracking
  formValidation: validationSchema,
  createOrUpdateModalSize: 'md',
  CreateOrUpdateForm: NonSyndicatePaymentWireForm,
  useManagers: createUseDefaultNonSyndicateManagers(),
  useNextRecipients: createUseDefaultNonSyndicateNextRecipients(),
  useIsWireManagerListOutdated: createUseDefaultIsNonSyndicateWireManagerListOutdated(),
  useOutdatedManagersList: createUseDefaultOutdatedNonSyndicateManagersList(),
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicatePaymentWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicatePaymentWireList,
      isLoading: loading,
      error,
    };
  },
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_NonSyndicatePaymentWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.nonSyndicatePaymentWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_NonSyndicatePaymentWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.nonSyndicatePaymentWireDetails,
        stage: data.syndicateWires.publishedOrPrivateOffering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_NonSyndicatePaymentWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
      });

    return { data: data?.nonSyndicatePaymentWireValidation, isLoading: loading, error, refetch };
  },
  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateNonSyndicatePaymentWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getNonSyndicatePaymentRecipientInputData(values),
            },
          },
        });

        if (result.data?.createNonSyndicatePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createNonSyndicatePaymentWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateNonSyndicatePaymentWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getNonSyndicatePaymentRecipientInputData(values),
            },
          },
        });

        if (result.data?.updateNonSyndicatePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateNonSyndicatePaymentWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteNonSyndicatePaymentWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteNonSyndicatePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendNonSyndicatePaymentWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendNonSyndicatePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendNonSyndicatePaymentWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    wire,
    managers,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const {
      data: brokerPaymentData,
      loading: brokerPaymentLoading,
      error: brokerPaymentError,
    } = useSyndicateWires_BrokerDealersMyRetailFeesQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    const {
      data: managerDtcData,
      loading: managerDtcLoading,
      error: managerDtcError,
    } = useSyndicateWires_ManagerDtcNumbersQuery({
      variables: {
        offeringId,
      },
    });

    const {
      data: retailData,
      loading: retailLoading,
      error: retailError,
    } = useBrokerDealersMyRetailQuery({ offeringId });

    const { oidcUserCmgEntityKey } = useAuth();

    const initialUserData = React.useMemo(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0]?.disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const initialRecipientUserData = managers.map<NonSyndicatePaymentRecipientUserData | null>(
        manager => {
          const managerValues = wire?.recipientsUserData?.find(
            ({ recipient }) => recipient === manager.cmgEntityKey
          );

          return !managerValues
            ? null
            : {
                ipoPenaltyTracking: managerValues.ipoPenaltyTracking,
                recipient: managerValues.recipient,
                payment: managerValues.payment,
                sellingConcession: managerValues.sellingConcession,
              };
        }
      );

      const predefinedRecipientUserData = managers.map<NonSyndicatePaymentRecipientUserData | null>(
        manager => {
          return getPredefinedRecipientUserData(
            manager,
            initialRecipientUserData,
            brokerPaymentData?.syndicateWires.brokerDealersMyRetailFees ?? [],
            retailData ?? []
          );
        }
      );

      const defaultDtcNumber =
        managerDtcData?.syndicateWires.managerDtcNumbers.find(
          item => item.managerKey === oidcUserCmgEntityKey
        )?.managerDtcNumber ?? null;
      const initialDtcNumber = wire?.recipientsUserData[0]?.dtcNumber ?? null;

      const initialValues: WireTypeConfigInitialValues<Values> = {
        data: {
          disclaimer,
          dtcNumber: initialDtcNumber,
          recipientsUserData: initialRecipientUserData,
        },
        predefinedData: {
          disclaimer,
          dtcNumber: initialDtcNumber ?? defaultDtcNumber,
          recipientsUserData: predefinedRecipientUserData,
        },
      };

      return initialValues;
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
      brokerPaymentData?.syndicateWires.brokerDealersMyRetailFees,
      retailData,
      managerDtcData?.syndicateWires.managerDtcNumbers,
      oidcUserCmgEntityKey,
    ]);

    const isLoading =
      disclaimerLoading || brokerPaymentLoading || retailLoading || managerDtcLoading;
    const error = disclaimerError || brokerPaymentError || retailError || managerDtcError;

    return {
      isLoading,
      error,
      data: initialUserData.data,
      predefinedData: initialUserData.predefinedData,
    };
  },
});
