import { getOperationName } from '@apollo/client/utilities';
import {
  FormField,
  Icon,
  LinkButton,
  Select,
  ServiceErrorBanner,
  ToastManager,
  UUID,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Column from '../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../common/components/layout/grid-layout/GridLayout';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { CreateDeliveryInstrumentInput, UpdateDeliveryInstrumentInput } from '../../../graphql';
import { Offering_OfferingHeaderDocument } from '../../offering/graphql/__generated__';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useOfferingSetup_DemandCurrenciesQuery } from '../currencies/graphql';
import { demandCurrencyToCurrencyOptions } from '../currencies/util/demandCurrencyToCurrencyOptions';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import DeliveryInstrumentForm from './DeliveryInstrumentForm';
import { DeliveryInstrumentSelectOption } from './DeliveryInstrumentSelectOption';
import { useOfferingSetup_DeliveryInstrumentsQuery } from './graphql';
import { useCreateDeliveryInstrumentMutation } from './hooks/useCreateDeliveryInstrumentMutation';
import { useDeleteDeliveryInstrumentMutation } from './hooks/useDeleteDeliveryInstrumentMutation';
import { useSetPricingInstrumentMutation } from './hooks/useSetPricingInstrumentMutation';
import { useUpdateDeliveryInstrumentMutation } from './hooks/useUpdateDeliveryInstrumentMutation';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

export type FormType = {
  [key: string]: CreateDeliveryInstrumentInput | UpdateDeliveryInstrumentInput;
};

/**
 * Delivery Instruments Route within Offering Setup
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const DeliveryInstrumentsRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [addNew, setAddNew] = React.useState<boolean>(false);

  const handleAddNew = (active: boolean) => {
    setEditingId(null);
    setAddNew(active);
  };

  const handleEditing = (id: string | null) => {
    setAddNew(false);
    setEditingId(id);
  };

  const { data, loading, error, refetch } = useOfferingSetup_DeliveryInstrumentsQuery({
    variables: { offeringId },
  });

  const demandCurrencyData = useOfferingSetup_DemandCurrenciesQuery({
    variables: { offeringId },
  });

  const { revalidate } = useValidateOffering(offeringId);

  const [
    createDeliveryInstrument,
    { loading: creating, error: createError, serviceError: createDeliveryInstrumentServiceError },
  ] = useCreateDeliveryInstrumentMutation({
    onCompleted: () => {
      handleAddNew(false);
      revalidate();
      refetch();
      ToastManager.success('Created Instrument');
    },
    onError: e => {
      ToastManager.error('Failed to create Instrument');
    },
  });

  const [
    updateDeliveryInstrument,
    { loading: updating, error: updateError, serviceError: updateDeliveryInstrumentServiceError },
  ] = useUpdateDeliveryInstrumentMutation({
    onCompleted: () => {
      handleEditing(null);
      setAddNew(false);
      // re-run validation if necessary to resolve errors/warnings
      revalidate();
      refetch();
      ToastManager.success('Updated Instrument');
    },
    onError: () => {
      ToastManager.error('Failed to create Instrument');
    },
  });

  const [deleteDeliveryInstrument, { loading: deleting, error: deleteError }] =
    useDeleteDeliveryInstrumentMutation({
      update(cache, { data: d }) {
        /* find Instrument id to remove */
        const idToRemove = d?.deleteDeliveryInstrument;

        /* update cache instead of refetching */
        cache.modify({
          id: cache.identify({ ...data?.offering }),
          fields: {
            deliveryInstruments(existing, { readField }) {
              return existing.filter(instrument => readField('id', instrument) !== idToRemove);
            },
          },
        });
      },
      onCompleted: () => {
        handleEditing(null);
        setAddNew(false);
        revalidate();
        ToastManager.success('Removed Instrument');
      },
      onError: () => {
        ToastManager.error('Failed to remove Instrument');
      },
    });

  const [
    setPricingInstrument,
    {
      loading: settingPricingInstrument,
      error: setPricingInstrumentError,
      serviceError: setPricingInstrumentServiceError,
    },
  ] = useSetPricingInstrumentMutation({
    onCompleted: () => {
      ToastManager.success('Successfully set Offering Pricing Instrument');
      setEditingId(null);
      setAddNew(false);
      refetch();
      revalidate();
    },
  });

  const isPageLoading = loading;

  if (isPageLoading) {
    return <Spinner show />;
  }

  const errors = error || createError || updateError || deleteError;

  const currencyOptions = demandCurrencyToCurrencyOptions(demandCurrencyData?.data);

  const instruments = data?.offering?.deliveryInstruments ?? [];
  const canAddInstrument = !loading && instruments.length < 1 && !addNew;

  if (!data) {
    return null;
  }
  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport testId={xcSelectors.offeringSetupInstruments.testId}>
        <SetupScreen.Header />
        {errors && <ServerErrorsBanner error={errors} />}
        {loading && (
          <SetupScreen.LoadingWrapper>
            <Icon name="spinner-third" spin />
          </SetupScreen.LoadingWrapper>
        )}
        <SetupForm title="Offering Pricing Instrument">
          {setPricingInstrumentError && <ServerErrorsBanner error={setPricingInstrumentError} />}
          {setPricingInstrumentServiceError && (
            <ServiceErrorBanner error={setPricingInstrumentServiceError} />
          )}
          <GridLayout>
            <Column span={3}>
              <FormField label="Pricing Instrument">
                <Select
                  isClearable={false}
                  disabled={settingPricingInstrument}
                  options={instruments.map(
                    ({ id, countryCode, currencyCode, stockSymbol, isDepositaryReceipt }) => ({
                      label: `${countryCode} - ${currencyCode} - ${stockSymbol}`,
                      value: id,
                      isDepositaryReceipt,
                    })
                  )}
                  value={data?.offering?.pricingInstrumentId}
                  onChange={value =>
                    setPricingInstrument({
                      variables: {
                        offeringId,
                        instrumentId: value as string,
                      },
                      refetchQueries: [getOperationName(Offering_OfferingHeaderDocument)!],
                    })
                  }
                  renderOption={DeliveryInstrumentSelectOption}
                />
              </FormField>
            </Column>
          </GridLayout>
        </SetupForm>

        <SetupForm
          title="Instruments"
          headerContent={
            canAddInstrument
              ? () => (
                  <LinkButton
                    testId={xcSelectors.offeringSetupInstrumentsAddNewButton.testId}
                    disabled={addNew}
                    fullWidth={false}
                    iconLeft={{ name: 'plus' }}
                    onClick={() => handleAddNew(true)}
                  >
                    Add New Instrument
                  </LinkButton>
                )
              : undefined
          }
        >
          <SetupForm.Table>
            {(createDeliveryInstrumentServiceError || updateDeliveryInstrumentServiceError) && (
              <ServiceErrorBanner
                error={
                  createDeliveryInstrumentServiceError ?? updateDeliveryInstrumentServiceError!
                }
              />
            )}
            {instruments.map(deliveryInstrument => (
              <DeliveryInstrumentForm
                key={deliveryInstrument.id}
                data={deliveryInstrument}
                currencyOptions={currencyOptions}
                loading={updating || deleting}
                disabled={editingId !== null && editingId !== deliveryInstrument.id}
                isPricingInstrument={data.offering.pricingInstrumentId === deliveryInstrument.id}
                editing={editingId === deliveryInstrument.id}
                onEdit={() => handleEditing(deliveryInstrument.id)}
                onCancel={() => handleEditing(null)}
                onDelete={() =>
                  deleteDeliveryInstrument({
                    variables: { offeringId, instrumentId: deliveryInstrument.id },
                  })
                }
                onSubmit={values =>
                  updateDeliveryInstrument({
                    variables: {
                      offeringId,
                      instrumentId: deliveryInstrument.id,
                      input: values,
                    },
                  })
                }
              />
            ))}

            {addNew && (
              <DeliveryInstrumentForm
                testId={xcSelectors.offeringSetupInstrumentsAddNewForm.testId}
                adding
                editing
                currencyOptions={currencyOptions}
                loading={creating}
                onCancel={() => handleAddNew(false)}
                onSubmit={values =>
                  createDeliveryInstrument({
                    variables: { offeringId, input: values },
                    refetchQueries: [getOperationName(Offering_OfferingHeaderDocument)!],
                  })
                }
              />
            )}
          </SetupForm.Table>
        </SetupForm>
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default DeliveryInstrumentsRoute;
