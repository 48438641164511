import { Option } from '@cmg/common';

import { DesignationRow } from '../designation-monitor-grid/DesignationMonitorGrid.model';

export const getInvestorsOptions = (rows: DesignationRow[]): Option[] => {
  return Array.from(new Set(rows.map(({ investorName }) => investorName))).map(name => ({
    label: name,
    value: name,
  }));
};
