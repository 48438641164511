import { Option } from '@cmg/common';
import * as yup from 'yup';

import { RoadshowFilterInput, RoadshowType } from '../../../../../graphql';

export const filtersSchema: yup.ObjectSchema<RoadshowFilterInput> = yup.object().shape({
  searchText: yup.string().label('Search by Name').nullable(),
  roadshowTypes: yup.array().label('Type').nullable(),
  companyName: yup.string().label('Company Name').nullable(),
  startDate: yup.string().label('Start Day After').nullable(),
  endDate: yup.string().label('End Day Before').nullable(),
  showArchived: yup.boolean().label('Show Archived Roadshows').nullable(),
});

export const filtersInitialValues: RoadshowFilterInput = {
  searchText: null,
  roadshowTypes: null,
  companyName: null,
  startDate: null,
  endDate: null,
  showArchived: false,
};

export const roadshowTypeOptions: Option<RoadshowType>[] = [
  {
    value: RoadshowType.Dcim,
    label: 'Debt Capital Markets',
  },
  {
    value: RoadshowType.Ecm,
    label: 'Equity Capital Markets',
  },
  {
    value: RoadshowType.NonDeal,
    label: 'Non deal',
  },
  {
    value: RoadshowType.Pdie,
    label: 'Pre-Deal Investor Education',
  },
  {
    value: RoadshowType.Ttw,
    label: 'Testing the waters',
  },
];
