import { Alert, IconButton } from '@cmg/common';
import React from 'react';

const SellingConcessionChangedAlert: React.FC = () => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(true);

  const handleCloseAlert = () => {
    setIsAlertVisible(false);
  };

  if (!isAlertVisible) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      action={() => <IconButton icon={{ name: 'times', size: 'lg' }} onClick={handleCloseAlert} />}
      withMargin
    >
      <Alert.Title>
        Selling Concession percentages (Manager Economics) have been updated for this offering.
        Please select all rows and Apply Fixed Economics to recalculate designations.
      </Alert.Title>
    </Alert>
  );
};

export default SellingConcessionChangedAlert;
