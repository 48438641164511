import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ButtonsContainer, PrimaryButton, SecondaryButton, SuccessButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import ButtonTooltip from '../../../../common/components/buttons/button-tooltip/ButtonTooltip';
import { MdlAllocationIoiType } from '../../../../graphql';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import SideBarSectionHeader from '../side-bar-header/SideBarHeader';
import { SFirmLevelDemandHeader } from './FirmLevelDemandBar.styles';

type FirmLevelDemandBarProps = {
  isEditing?: boolean;
  onChangeEditing: () => void;
  ioiType?: MdlAllocationIoiType;
  onSubmitForm: () => void;
  onResetForm: () => void;
  loading?: boolean;
  title?: string;
  hasAllocationRanking?: boolean;
  isExecutingOnPlatform: boolean;
};

type Props =
  | (FirmLevelDemandBarProps & { convertableToPassOrRegM: false })
  | (FirmLevelDemandBarProps & {
      convertableToPassOrRegM: true;
      onChangeIoiType: (ioiType: MdlAllocationIoiType) => void;
    });

const FirmLevelDemandBar = (props: Props) => {
  const {
    ioiType,
    title,
    loading,
    hasAllocationRanking,
    onChangeEditing,
    isEditing,
    onResetForm,
    onSubmitForm,
    isExecutingOnPlatform,
  } = props;

  const hasFullPermissions = useCheckPermissions(
    [permissionsByEntity.Ioi.FULL].concat(
      isExecutingOnPlatform ? [permissionsByEntity.InstitutionalIndication.FULL] : []
    ),
    true
  );

  const { isObsoleteOffering } = useSupersededOfferingContext();

  const isNormalIoiType = !ioiType || ioiType === MdlAllocationIoiType.Normal;
  const displayEdit = !isEditing && isNormalIoiType && hasFullPermissions;
  const displayRegMAndPass = isNormalIoiType && hasFullPermissions;

  return (
    <SideBarSectionHeader title={title}>
      <SFirmLevelDemandHeader>
        <ButtonsContainer>
          {!isNormalIoiType && props.convertableToPassOrRegM && (
            <SuccessButton
              onClick={() => props.onChangeIoiType(MdlAllocationIoiType.Normal)}
              disabled={!hasFullPermissions || loading}
              testId={xcSelectors.firmLevelDemandBarReActivateIndication.testId}
              loading={loading}
            >
              Re-activate Indication
            </SuccessButton>
          )}
          {displayRegMAndPass && props.convertableToPassOrRegM && (
            <React.Fragment>
              <SecondaryButton
                onClick={() => props.onChangeIoiType(MdlAllocationIoiType.Pass)}
                disabled={loading || hasAllocationRanking || isObsoleteOffering}
                testId={xcSelectors.firmLevelDemandBarPassIndication.testId}
              >
                Pass
              </SecondaryButton>
              <SecondaryButton
                onClick={() => props.onChangeIoiType(MdlAllocationIoiType.RegM)}
                disabled={loading || hasAllocationRanking || isObsoleteOffering}
                testId={xcSelectors.firmLevelDemandBarRegMIndication.testId}
              >
                Reg-M
              </SecondaryButton>
            </React.Fragment>
          )}
          {displayEdit && (
            <ButtonTooltip
              variant="TOOLTIP"
              placement="top"
              disabled={!isObsoleteOffering}
              hideArrow
              content="Firm Level Demand cannot be edited for an obsolete offering"
            >
              <PrimaryButton
                onClick={onChangeEditing}
                disabled={loading || isObsoleteOffering}
                testId={xcSelectors.firmLevelDemandBarEditIndication.testId}
              >
                Edit
              </PrimaryButton>
            </ButtonTooltip>
          )}
          {isEditing && isNormalIoiType && (
            <React.Fragment>
              <SecondaryButton
                onClick={onResetForm}
                testId={xcSelectors.firmLevelDemandBarDiscardChanges.testId}
                disabled={loading}
              >
                Discard Changes
              </SecondaryButton>
              <SuccessButton
                onClick={onSubmitForm}
                testId={xcSelectors.firmLevelDemandBarSaveChanges.testId}
                loading={loading}
                disabled={loading}
              >
                Save
              </SuccessButton>
            </React.Fragment>
          )}
        </ButtonsContainer>
      </SFirmLevelDemandHeader>
    </SideBarSectionHeader>
  );
};

export default FirmLevelDemandBar;
