import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { StabilizationTrade } from '../../StabilizationForm.model';

export const getBuyGain = (trade: StabilizationTrade, breakevenPrice: number) => {
  if (isNil(trade.averagePrice) || isNil(trade.numberOfShares)) {
    return null;
  }

  return (breakevenPrice - trade.averagePrice) * trade.numberOfShares;
};

export const getTotalBuyGain = (
  trades: StabilizationTrade[],
  breakevenPrice: number
): number | null => {
  return numericUtil.sum(...trades.map(trade => getBuyGain(trade, breakevenPrice)));
};
