import { getFeatureToggles } from '../../../../../../common/config/appSettings';
import {
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
} from '../graphql';

export type InvestorExtendedDataQuery = InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery;

export const useInvestorExtendedDataQuery = ({ firmKey }: { firmKey?: string | null }) => {
  const { isOrderBookDynamicColumnsOn } = getFeatureToggles();

  return useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery({
    variables: { firmKey: firmKey! },
    skip: !firmKey || !isOrderBookDynamicColumnsOn,
    // We dont want to cache since this is not updated from XC and we dont know when the value changed
    fetchPolicy: 'no-cache',
  });
};
