import { useInfiniteScroll } from '@cmg/common';
import React, { RefObject } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { PaginationDataInput } from '../../../../../../../graphql';
import { DEBOUNCE_TIMEOUT } from '../../../../../../constants';

type ScrollData<TRowType> = {
  hasNext?: boolean | null | undefined;
  nextPage: number;
  dataRows: readonly TRowType[];
  isLoading: boolean;
};

export type RequestResponse<TRowType> = {
  pagination: {
    hasNext?: boolean | null | undefined;
    activePage: number;
  };
  data: readonly TRowType[];
};

export const defaultScrollData: ScrollData<never> = {
  hasNext: false,
  nextPage: 1,
  dataRows: [],
  isLoading: false,
};

const usePaginatedInfiniteScroll = <TRowType extends {}>(
  getData: (pagination: PaginationDataInput) => Promise<RequestResponse<TRowType> | undefined>,
  shouldSkipSearch: boolean
) => {
  const [scrollData, setScrollData] = React.useState<ScrollData<TRowType>>(defaultScrollData);

  const fetchPaginatedData = async (clearPreviousValues: boolean) => {
    if (shouldSkipSearch) {
      setScrollData(defaultScrollData);

      return;
    }

    setScrollData({ ...scrollData, isLoading: true });

    const pagination: PaginationDataInput = {
      page: clearPreviousValues ? 1 : scrollData.nextPage,
      perPage: 25,
    };

    const result = await getData(pagination);

    if (result) {
      setScrollData({
        isLoading: false,
        nextPage: result.pagination.activePage + 1,
        hasNext: result.pagination.hasNext,
        dataRows: clearPreviousValues ? result.data : [...scrollData.dataRows, ...result.data],
      });
    }
  };

  const debouncedFetchPaginatedData = useDebouncedCallback(fetchPaginatedData, DEBOUNCE_TIMEOUT);

  const scrollRef: RefObject<HTMLDivElement> = useInfiniteScroll({
    hasNext: !scrollData.isLoading && !!scrollData.hasNext,
    onNext: () => {
      debouncedFetchPaginatedData(false);
    },
  });

  return {
    fetchNewData: () => {
      debouncedFetchPaginatedData(true);
    },
    scrollRef,
    dataRows: scrollData.dataRows,
    isLoading: scrollData.isLoading,
  };
};

export default usePaginatedInfiniteScroll;
