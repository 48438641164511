import { DropdownContainer, IconButton } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const StyledIconButton = styled(IconButton)<{ disabled?: boolean }>`
  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        color: ${theme.designSystem.colors.gray['300']};
        pointer-events: none;
      `;
    }
  }}
`;

export const StyledDropdownContainer = styled(DropdownContainer)`
  padding-top: 5px;
`;

export const SWrapper = styled.div`
  min-width: 115px;
  padding: 8px;
  & hr {
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.designSystem.colors.gray[100]};
    margin: 3px 0;
  }
`;

export const SItem = styled.div<{ disabled?: boolean; active?: boolean }>`
  padding: 5px 8px;
  ${({ active, disabled, theme }) => {
    if (active) {
      return css`
        color: white;
        background-color: ${theme.designSystem.colors.blue['300']};
        pointer-events: none;
      `;
    }
    if (disabled) {
      return css`
        color: ${theme.designSystem.colors.gray['300']};
        pointer-events: none;
      `;
    }
    if (!active && !disabled) {
      return css`
        cursor: pointer;
        &:hover {
          background-color: ${({ theme }) => theme.designSystem.colors.darkBlue[100]};
        }
      `;
    }
  }}
`;
