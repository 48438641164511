import { Collapse } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import offeringSidePanelBuySideRouteFactory from '../../offeringSidePanelBuySideRouteFactory';
import { StyledSubNavLink } from './SidePanelNavbar.styles';

export type Props = {
  baseSidePanelUrl: string;
};

const OrderAllocationSubNavigation: React.FC<Props> = ({ baseSidePanelUrl }) => {
  const isOrderAllocationRoute = !!useRouteMatch(
    offeringSidePanelBuySideRouteFactory.orderAllocation.routePath
  );
  const isOrderBookRoute = !!useRouteMatch(
    offeringSidePanelBuySideRouteFactory.orderBook.routePath
  );

  const expand = isOrderAllocationRoute || isOrderBookRoute;

  return (
    <Collapse isExpanded={expand}>
      <StyledSubNavLink
        activeClassName="activeLink"
        to={`${baseSidePanelUrl}${offeringSidePanelBuySideRouteFactory.communicatedDemand.getUrlPath()}`}
      >
        Communicated Demand
      </StyledSubNavLink>
      <StyledSubNavLink
        activeClassName="activeLink"
        to={`${baseSidePanelUrl}${offeringSidePanelBuySideRouteFactory.internalDemand.getUrlPath()}`}
        data-test-id={xcSelectors.offeringSidePanelInternalDemandLink.testId}
      >
        Internal Demand
      </StyledSubNavLink>
    </Collapse>
  );
};

export default OrderAllocationSubNavigation;
