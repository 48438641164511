import React from 'react';
import { useTheme } from 'styled-components/macro';

import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';

export const WireSent: React.FC = () => {
  const { wireTypeName, wireTypeCategory } = useWireTypeConfigContext();
  const theme = useTheme();

  return (
    <HeroGraphics
      testId="wire-sent-hero"
      iconNameParts={{ name: 'paper-plane' }}
      iconColor={theme.designSystem.colors.blue['300']}
      title={`${wireTypeName} ${wireTypeCategory} was successfully sent`}
      subtitle={`Select a recipient to preview their sent ${wireTypeCategory}.`}
    />
  );
};
