import { ODSTable as Table, UUID } from '@cmg/common';
import React from 'react';

import { EmptyState } from '../../../../../../design-system/components/data-display/empty-state/EmptyState';
import { ActivityGroupType, getActivitiesGroupLabel } from '../../common/activity/utils';
import { ZeroActivitiesAction } from './ZeroActivitiesAction';

export type Props = {
  groupType: ActivityGroupType;
  mode: 'edit' | 'view';
  colSpan: number;
  onAddCustom?: () => void;
  offeringId?: UUID;
};

export const ZeroActivitiesTableGroup: React.FC<Props> = ({
  groupType,
  mode,
  colSpan,
  onAddCustom,
  offeringId = '',
}) => {
  return (
    <Table.Row suppressHighlightingOnHover isFocused={false}>
      <Table.Cell colSpan={colSpan} withBottomBorder={false}>
        <EmptyState
          title={`No ${getActivitiesGroupLabel(groupType)}`}
          message={
            <ZeroActivitiesAction
              mode={mode}
              groupType={groupType}
              onAddCustom={onAddCustom}
              offeringId={offeringId}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};
