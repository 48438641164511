import {
  SyndicateWires_UpdateSellingGroupInvitationWireMutation,
  useSyndicateWires_UpdateSellingGroupInvitationWireMutation,
} from '../graphql';

export type UpdateSellingGroupInvitationWireMutation =
  SyndicateWires_UpdateSellingGroupInvitationWireMutation;

export const useUpdateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_UpdateSellingGroupInvitationWireMutation();
};
