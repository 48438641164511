import { ApolloError } from '@apollo/client';
import { useAuth } from '@cmg/auth';
import React from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import usePollingWhenVisible from '../../../../../common/hooks/usePollingWhenVisible';
import { OfferingStatus } from '../../../../../graphql';
import {
  MyOrders_PricedOfferingsListQueryVariables,
  useWithMyOrders_CoveredIndicationChangedSubscription,
} from '../../../../my-orders/graphql/__generated__';
import {
  useMyOrders_CoveredIndicationsQuery,
  useMyOrders_LiveOfferingsListQuery,
  useMyOrders_PricedOfferingsListQuery,
} from '../../../graphql';
import { matchesCoveredIndicationChangePattern } from '../../../graphql/types/CoveredIndicationChanged';
import { MyOrdersFilters } from '../../../hooks/useFilters';
import { defaultPollingInterval } from '../MyOrdersGrid.model';
import { useMyOrdersPublishedOfferingsQuery } from './useMyOrdersPublishedOfferingsQuery';

export const useMyOrdersGridPrimaryQueries = ({ filters }: { filters: MyOrdersFilters }) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const {
    isOrderBookMyOrderGridOptimizationOn,
    isOrderBookMyOrderGridSubscriptionOn,
    isOfferingSetupMyOrdersOfferingFullGql,
  } = useFeatureToggles();

  const {
    publishedOfferings,
    publishedOfferingsError,
    isPublishedOfferingsLoading,
    hasCalledPublishedOfferings,
    refetchPublishedOfferings,
  } = useMyOrdersPublishedOfferingsQuery({
    shouldSkip: !isOfferingSetupMyOrdersOfferingFullGql,
    isOrderBookMyOrderGridSubscriptionOn,
    pricingDateFilters: filters.pricingDate,
  });

  let liveOfferingsListQueryResult = useMyOrders_LiveOfferingsListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        cmgEntityKey: oidcUserCmgEntityKey,
        includeTestOfferings: true,
      },
    },
    skip: isOfferingSetupMyOrdersOfferingFullGql,
  });

  liveOfferingsListQueryResult = useWithMyOrders_CoveredIndicationChangedSubscription(
    liveOfferingsListQueryResult,
    {
      isEnabled: isOrderBookMyOrderGridSubscriptionOn && !isOfferingSetupMyOrdersOfferingFullGql,
      loadingStateBehavior: 'false-on-refetch',
      updateQuery:
        refetch =>
        (prev, { subscriptionData: { data } }) =>
          matchesCoveredIndicationChangePattern(data, [
            'OrderBookNotIndicationChange',
            'OrderBookIndicationChange',
            'AttestationChange',
          ])
            ? refetch(prev)
            : prev,
    }
  );

  const {
    data: liveOfferings,
    loading: liveOfferingsLoading,
    error: liveOfferingsError,
    called: hasCalledLiveOfferings,
  } = liveOfferingsListQueryResult;

  let pricedOfferingsListQueryResult = useMyOrders_PricedOfferingsListQuery({
    variables: {
      startPricingDate: filters.pricingDate.start!,
      endPricingDate: filters.pricingDate.end!,
      cmgEntityKey: oidcUserCmgEntityKey,
      includeTestOfferings: true,
    },
    fetchPolicy: 'cache-and-network',
    skip: isOfferingSetupMyOrdersOfferingFullGql,
  });

  pricedOfferingsListQueryResult = useWithMyOrders_CoveredIndicationChangedSubscription(
    pricedOfferingsListQueryResult,
    {
      isEnabled: isOrderBookMyOrderGridSubscriptionOn && !isOfferingSetupMyOrdersOfferingFullGql,
      loadingStateBehavior: 'false-on-refetch',
      updateQuery:
        refetch =>
        (prev, { subscriptionData: { data } }) =>
          matchesCoveredIndicationChangePattern(data, [
            'OrderBookNotIndicationChange',
            'OrderBookIndicationChange',
            'AttestationChange',
          ])
            ? refetch(prev)
            : prev,
    }
  );

  const {
    data: pricedOfferings,
    loading: pricedOfferingsLoading,
    error: pricedOfferingError,
    called: hasCallPricedOfferings,
    refetch: refetchPricedOffering,
  } = pricedOfferingsListQueryResult;

  let coveredIndicationsQueryResult = useMyOrders_CoveredIndicationsQuery({
    variables: {
      pricingDateFrom: filters.pricingDate.start!,
      pricingDateTo: filters.pricingDate.end!,
      useNewStrategy: isOrderBookMyOrderGridOptimizationOn,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  coveredIndicationsQueryResult = useWithMyOrders_CoveredIndicationChangedSubscription(
    coveredIndicationsQueryResult,
    {
      isEnabled: isOrderBookMyOrderGridSubscriptionOn,
      loadingStateBehavior: 'false-on-refetch',
      updateQuery:
        refetch =>
        (prev, { subscriptionData: { data } }) =>
          matchesCoveredIndicationChangePattern(data, [
            'OrderBookNotIndicationChange',
            'OrderBookIndicationChange',
            'AttestationChange',
          ])
            ? refetch(prev)
            : prev,
    }
  );

  const {
    data: indicationsData,
    loading: coveredIndicationsLoading,
    error: coveredIndicationsError,
    called: hasCalledCoveredIndications,
    stopPolling: stopCoveredIndicationsPolling,
    startPolling: startCoveredIndicationsPolling,
  } = coveredIndicationsQueryResult;

  usePollingWhenVisible({
    pollInterval: defaultPollingInterval,
    isEnabled: !isOrderBookMyOrderGridSubscriptionOn,
    startPolling: startCoveredIndicationsPolling,
    stopPolling: stopCoveredIndicationsPolling,
    called: hasCalledCoveredIndications,
  });

  React.useEffect(() => {
    if (coveredIndicationsError) {
      stopCoveredIndicationsPolling();
    }
  }, [coveredIndicationsError, stopCoveredIndicationsPolling]);

  const refetchOfferings = React.useCallback(
    ({
      endPricingDate,
      startPricingDate,
    }: Pick<MyOrders_PricedOfferingsListQueryVariables, 'startPricingDate' | 'endPricingDate'>) => {
      if (isOfferingSetupMyOrdersOfferingFullGql) {
        refetchPublishedOfferings({
          where: {
            or: [
              {
                status: {
                  eq: OfferingStatus.Live,
                },
              },
              {
                status: {
                  eq: OfferingStatus.Priced,
                },
                timing: {
                  pricingDate: {
                    gte: startPricingDate,
                    lte: endPricingDate,
                  },
                },
              },
            ],
          },
        });
      } else {
        refetchPricedOffering({
          startPricingDate,
          endPricingDate,
        });
      }
    },
    [isOfferingSetupMyOrdersOfferingFullGql, refetchPublishedOfferings, refetchPricedOffering]
  );

  const offerings = React.useMemo(() => {
    if (isOfferingSetupMyOrdersOfferingFullGql) {
      return publishedOfferings;
    }

    const liveOfferingsData = liveOfferings?.livePublishedOfferings?.data ?? [];
    const pricedOfferingsData = pricedOfferings?.pricedPublishedOfferings?.data ?? [];

    return [...liveOfferingsData, ...pricedOfferingsData];
  }, [liveOfferings, pricedOfferings, publishedOfferings, isOfferingSetupMyOrdersOfferingFullGql]);

  const primaryQueriesLoading = React.useMemo(
    () =>
      isOfferingSetupMyOrdersOfferingFullGql
        ? isPublishedOfferingsLoading || coveredIndicationsLoading
        : pricedOfferingsLoading || liveOfferingsLoading || coveredIndicationsLoading,
    [
      pricedOfferingsLoading,
      liveOfferingsLoading,
      coveredIndicationsLoading,
      isPublishedOfferingsLoading,
      isOfferingSetupMyOrdersOfferingFullGql,
    ]
  );
  const primaryErrors = React.useMemo(() => {
    const errors = [coveredIndicationsError];
    if (isOfferingSetupMyOrdersOfferingFullGql) {
      return errors.push(publishedOfferingsError);
    } else {
      errors.push(liveOfferingsError, pricedOfferingError);
    }

    return errors.filter((error): error is ApolloError => !!error);
  }, [
    pricedOfferingError,
    liveOfferingsError,
    coveredIndicationsError,
    publishedOfferingsError,
    isOfferingSetupMyOrdersOfferingFullGql,
  ]);

  const hasCalledPrimaryQueries = React.useMemo(
    () =>
      isOfferingSetupMyOrdersOfferingFullGql
        ? hasCalledPublishedOfferings || hasCalledCoveredIndications
        : hasCallPricedOfferings || hasCalledLiveOfferings || hasCalledCoveredIndications,
    [
      hasCallPricedOfferings,
      hasCalledLiveOfferings,
      hasCalledCoveredIndications,
      hasCalledPublishedOfferings,
      isOfferingSetupMyOrdersOfferingFullGql,
    ]
  );

  return React.useMemo(
    () => ({
      offerings,
      indicationsData,
      refetchOfferings,
      loading: primaryQueriesLoading,
      hasCalled: hasCalledPrimaryQueries,
      errors: primaryErrors,
    }),
    [
      offerings,
      primaryErrors,
      indicationsData,
      primaryQueriesLoading,
      refetchOfferings,
      hasCalledPrimaryQueries,
    ]
  );
};
