import {
  SyndicateWires_UpdateNonSyndicatePaymentWireMutation,
  useSyndicateWires_UpdateNonSyndicatePaymentWireMutation,
} from '../graphql';

export type UpdateNonSyndicatePaymentWireMutation =
  SyndicateWires_UpdateNonSyndicatePaymentWireMutation;

export const useUpdateNonSyndicatePaymentWireMutation = () => {
  return useSyndicateWires_UpdateNonSyndicatePaymentWireMutation();
};
