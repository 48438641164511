import routeFactory from '../../../../common/util/routeFactory';
import { OfferingErrorCategory } from '../../../../graphql';
import { stepIds } from '../../constants';
import { ValidationErrorsByCategory } from './useValidateOffering';

export function createGetPath(stepId: string) {
  return (offeringId: string) => routeFactory.offeringSetup.getUrlPath({ stepId, offeringId });
}

const errorCategoryOrder: OfferingErrorCategory[] = [
  OfferingErrorCategory.Issuer,
  OfferingErrorCategory.Type,
  OfferingErrorCategory.DemandCurrencies,
  OfferingErrorCategory.Instruments,
  OfferingErrorCategory.Tranches,
  OfferingErrorCategory.Managers,
  OfferingErrorCategory.ManagerResponsibilities,
  OfferingErrorCategory.Timing,
  OfferingErrorCategory.FilingsAndRevisions,
  OfferingErrorCategory.UnderwritingTerms,
  OfferingErrorCategory.FollowonPricing,
  OfferingErrorCategory.Prospectus,
  OfferingErrorCategory.Disclaimers,
  OfferingErrorCategory.Roadshow,
  OfferingErrorCategory.Syndicate,
  OfferingErrorCategory.Security,
  OfferingErrorCategory.SponsorsAndAdvisors,
  OfferingErrorCategory.Orderbook,
  OfferingErrorCategory.All,
];

export function extractErrorEntries(errors: ValidationErrorsByCategory) {
  return Object.entries(errors).sort(
    ([a], [b]) =>
      errorCategoryOrder.indexOf(a as OfferingErrorCategory) -
      errorCategoryOrder.indexOf(b as OfferingErrorCategory)
  );
}

export const validationErrorCategoryLabels: Record<
  OfferingErrorCategory,
  { label: string; getPath: (offeringId: string) => string }
> = {
  [OfferingErrorCategory.All]: {
    label: 'All',
    getPath: createGetPath(stepIds.SUMMARY),
  },
  [OfferingErrorCategory.Issuer]: {
    label: 'Issuer',
    getPath: createGetPath(stepIds.BASIC_INFO),
  },
  [OfferingErrorCategory.DemandCurrencies]: {
    label: 'Demand Currencies',
    getPath: createGetPath(stepIds.CURRENCIES),
  },
  [OfferingErrorCategory.Disclaimers]: {
    label: 'Disclaimers',
    getPath: createGetPath(stepIds.DISCLAIMERS),
  },
  [OfferingErrorCategory.FilingsAndRevisions]: {
    label: 'Terms',
    getPath: createGetPath(stepIds.TERMS),
  },
  [OfferingErrorCategory.FollowonPricing]: {
    label: 'Followon Pricing',
    getPath: createGetPath(stepIds.TERMS),
  },
  [OfferingErrorCategory.Instruments]: {
    label: 'Instruments',
    getPath: createGetPath(stepIds.INSTRUMENTS),
  },
  [OfferingErrorCategory.ManagerResponsibilities]: {
    label: 'Manager Responsibilities',
    getPath: createGetPath(stepIds.MANAGERS),
  },
  [OfferingErrorCategory.Managers]: {
    label: 'Managers',
    getPath: createGetPath(stepIds.MANAGERS),
  },
  [OfferingErrorCategory.Orderbook]: {
    label: 'Orderbook',
    getPath: (offeringId: string) => routeFactory.orderBook.getUrlPath({ offeringId }),
  },
  [OfferingErrorCategory.Prospectus]: {
    label: 'Prospectus',
    getPath: createGetPath(stepIds.PROSPECTUS),
  },
  [OfferingErrorCategory.Roadshow]: {
    label: 'Roadshow',
    getPath: createGetPath(stepIds.MARKETING),
  },
  [OfferingErrorCategory.Security]: {
    label: 'Security',
    getPath: createGetPath(stepIds.BASIC_INFO),
  },
  [OfferingErrorCategory.SponsorsAndAdvisors]: {
    label: 'Sponsors And Advisors',
    getPath: createGetPath(stepIds.SUMMARY),
  },
  [OfferingErrorCategory.Syndicate]: {
    label: 'Syndicate',
    getPath: createGetPath(stepIds.MANAGERS),
  },
  [OfferingErrorCategory.Timing]: {
    label: 'Timing',
    getPath: createGetPath(stepIds.TIMING),
  },
  [OfferingErrorCategory.Tranches]: {
    label: 'Tranches',
    getPath: createGetPath(stepIds.TRANCHES),
  },
  [OfferingErrorCategory.Type]: {
    label: 'Type',
    getPath: createGetPath(stepIds.BASIC_INFO),
  },
  [OfferingErrorCategory.UnderwritingTerms]: {
    label: 'Underwriting Terms',
    getPath: createGetPath(stepIds.TERMS),
  },
};
