import {
  Box,
  CheckCircleIcon,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  MoreVertOutlinedIcon,
  Stack,
  Typography,
} from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { OfferingStatus, OrderBookState } from '../../../../graphql';
import { DemandGridV2Switch } from '../../components/demand-grid-v2-switch/DemandGridV2Switch';
import { ActionProps } from '../hooks/useSubNavActions';
import { RecentUpdates } from '../recent-updates/RecentUpdates';

export const SubNavActionMenu: React.FC<ActionProps> = ({
  offeringId,
  offeringStatus,
  orderBookState,
  pricingCurrencyCode,
  isFinalAllocationSetReleased,
  isUpdateOrderBookStatusLoading,
  isExportInstitutionalDemandLoading,
  isReleaseFinalAllocationSetLoading,
  isSaveAllocationsLoading,
  isAllocationsUnsaved,
  canUpdateIndications,
  canManageAllocations,
  isDemandConfigDefined,
  onReleaseFinalAllocations,
  onUpdateOrderBookStatus,
  onExportInstitutionalDemand,
  onExportRevisionHistory,
  onExportProspectusEmails,
  newIndicationRoutePath,
  onDiscardAllocationChanges,
  onSaveAllocations,
  isExportProspectusEmailsLoading,
  isExportRevisionHistoryLoading,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isReleaseAllocationsDisabled =
    orderBookState !== OrderBookState.Closed ||
    offeringStatus !== OfferingStatus.Priced ||
    isReleaseFinalAllocationSetLoading;

  const isExportInstitutionalDemandDisabled =
    !isDemandConfigDefined || isExportInstitutionalDemandLoading;

  const isUpdateAllocationActionsPostReleaseVisible =
    canManageAllocations && isFinalAllocationSetReleased && isAllocationsUnsaved;

  return (
    <Box>
      <IconButton aria-label="Order Book actions" onClick={() => setOpen(true)} ref={buttonRef}>
        <MoreVertOutlinedIcon color="primary" />
      </IconButton>

      <Menu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        <DemandGridV2Switch Component={ListItem} />
        <MenuItem>
          <RecentUpdates offeringId={offeringId} pricingCurrencyCode={pricingCurrencyCode} />
        </MenuItem>
        <MenuItem
          onClick={onExportInstitutionalDemand}
          disabled={isExportInstitutionalDemandDisabled}
        >
          Export Orderbook Grid
        </MenuItem>
        <MenuItem onClick={onExportRevisionHistory} disabled={isExportRevisionHistoryLoading}>
          Export Revision History
        </MenuItem>
        <MenuItem onClick={onExportProspectusEmails} disabled={isExportProspectusEmailsLoading}>
          Export Prospectus Email History
        </MenuItem>
        {canUpdateIndications && (
          <MenuItem component={Link} to={newIndicationRoutePath}>
            New Indication
          </MenuItem>
        )}
        {canManageAllocations && !isFinalAllocationSetReleased && (
          <MenuItem disabled={isReleaseAllocationsDisabled} onClick={onReleaseFinalAllocations}>
            Release Allocations
          </MenuItem>
        )}
        {isUpdateAllocationActionsPostReleaseVisible && (
          <MenuItem onClick={onSaveAllocations} disabled={isSaveAllocationsLoading}>
            Save Allocations
          </MenuItem>
        )}
        {isUpdateAllocationActionsPostReleaseVisible && (
          <MenuItem onClick={onDiscardAllocationChanges} disabled={isSaveAllocationsLoading}>
            Discard Changes
          </MenuItem>
        )}
        {isFinalAllocationSetReleased && (
          <ListItem>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              data-test-id={xcSelectors.orderBookHeaderAllocationsReleasedStatus.testId}
              flexWrap="nowrap"
            >
              <CheckCircleIcon fontSize="small" color="success" />
              <Typography>Final Allocations Released</Typography>
            </Stack>
          </ListItem>
        )}
      </Menu>
    </Box>
  );
};
