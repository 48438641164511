import { MutationHookOptions } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_BasicInfoRoute_UpdateBasicInfoDocument,
  OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
  OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables,
} from '../../graphql';

const refetchQueries = () => {
  const offeringHeaderQuery = getOperationName(Offering_OfferingHeaderDocument);
  return [offeringHeaderQuery!];
};

export const useUpdateBasicInfoMutation = (
  options: MutationHookOptions<
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
  > = { refetchQueries }
) =>
  useServiceMutation<
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
  >(OfferingSetup_BasicInfoRoute_UpdateBasicInfoDocument, options);
