import styled from 'styled-components/macro';

export const SText = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin-bottom: 8px;
`;

export const SErrorList = styled.div`
  color: ${({ theme }) => theme.color.blue1};
`;
