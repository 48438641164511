import {
  Modal,
  numericUtil,
  PrimaryButton,
  SecondaryButton,
  ServiceErrorBanner,
  ToastManager,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import React, { useCallback } from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';
import { useDebouncedCallback } from 'use-debounce';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useFinalAllocations } from '../../contexts/finalAllocationsContext';
import {
  InstitutionalFinalSet,
  InstitutionalIndicationWithFinalAllocation,
} from '../institutional-demand-grid/types';
import { useInstitutionalDemand_UpdateFinalAllocationsMutation } from './graphql';
import {
  SButtonGroup,
  SChangesGrid,
  SFooter,
  STextBold,
  STextLight,
} from './UpdateFinalInstitutionalAllocationsModal.styles';

type OwnProps = {
  offeringId: string;
  currentInstitutionalIndications: InstitutionalIndicationWithFinalAllocation[];
  currentFinalInstitutionalAllocationSet: InstitutionalFinalSet | null;
};

export type Props = OwnProps & InjectedProps;

export const UpdateFinalInstitutionalAllocationsModal: React.FC<Props> = ({
  offeringId,
  currentInstitutionalIndications,
  currentFinalInstitutionalAllocationSet,
  handleHide,
}) => {
  const [updateFinalAllocationsResponseError, setUpdateFinalAllocationsResponseError] =
    React.useState<React.ComponentProps<typeof ServiceErrorBanner>['error'] | null>(null);
  const [finalAllocationsState, finalAllocationsDispatch] = useFinalAllocations();
  const { changesByIndicationId } = finalAllocationsState || { changesByIndicationId: {} };

  const [
    updateFinalInstitutionalAllocations,
    { loading: submitting, error: updateAllocationsError },
  ] = useInstitutionalDemand_UpdateFinalAllocationsMutation();

  const debounceDisableResetFormToogle = useDebouncedCallback(() => {
    finalAllocationsDispatch({
      type: 'setResetFormToggle',
      payload: false,
    });
  }, 500);

  const handleConfirm = useCallback(async () => {
    try {
      finalAllocationsDispatch({
        type: 'setIsUpsertChangesByIndicationIdLoading',
        payload: true,
      });
      const { data } = await updateFinalInstitutionalAllocations({
        variables: {
          offeringId,
          input: {
            allocations: Object.entries(changesByIndicationId).map(([key, value]) => ({
              indicationId: key,
              allocation: { shareQuantity: value.newValue || 0 },
            })),
          },
        },
      });

      if (data?.updateFinalInstitutionalAllocations.__typename === 'ServiceError') {
        setUpdateFinalAllocationsResponseError(data.updateFinalInstitutionalAllocations);
      } else {
        setUpdateFinalAllocationsResponseError(null);
        ToastManager.success('Successfully Updated Allocations');
        finalAllocationsDispatch({
          type: 'setResetFormToggle',
          payload: false,
        });
        finalAllocationsDispatch({ type: 'setIsEditing', payload: false });
        handleHide();
      }
    } catch {
      ToastManager.error('Failed to Update Allocations');
    } finally {
      finalAllocationsDispatch({
        type: 'setIsUpsertChangesByIndicationIdLoading',
        payload: false,
      });
    }
  }, [
    finalAllocationsDispatch,
    updateFinalInstitutionalAllocations,
    offeringId,
    changesByIndicationId,
    setUpdateFinalAllocationsResponseError,
    handleHide,
  ]);

  const handleDiscard = useCallback(() => {
    finalAllocationsDispatch({
      type: 'setResetFormToggle',
      payload: true,
    });
    finalAllocationsDispatch({ type: 'setIsEditing', payload: false });
    handleHide();
    debounceDisableResetFormToogle();
  }, [finalAllocationsDispatch, handleHide, debounceDisableResetFormToogle]);

  const determineNewTotalAllocation = useCallback(
    (
      currentIndications: InstitutionalIndicationWithFinalAllocation[],
      changes: { [key: string]: { initialValue: number | null; newValue: number | null } }
    ): number =>
      currentIndications?.reduce((acc, { id, finalAllocation }) => {
        if (!isNil(changes[id])) {
          return acc + (changes[id].newValue ?? 0);
        }

        if (!isNil(finalAllocation?.shareQuantity)) {
          return acc + finalAllocation!.shareQuantity;
        }

        return acc;
      }, 0),
    []
  );

  return (
    <React.Fragment>
      {updateAllocationsError ? <ServerErrorsBanner error={updateAllocationsError} /> : null}
      {updateFinalAllocationsResponseError && (
        <ServiceErrorBanner error={updateFinalAllocationsResponseError} />
      )}
      <Modal
        show
        size="small"
        title="Update Final Allocations"
        onHide={handleHide}
        closeButton={true}
        testId={xcSelectors.updateFinalAllocationsWindow.testId}
        footer={
          <SFooter>
            <SecondaryButton onClick={handleDiscard}>Discard Changes</SecondaryButton>
            <SButtonGroup>
              <SecondaryButton onClick={handleHide}>Continue Editing</SecondaryButton>
              <PrimaryButton
                testId={xcSelectors.updateFinalAllocationsButton.testId}
                disabled={submitting}
                onClick={handleConfirm}
              >
                Update
              </PrimaryButton>
            </SButtonGroup>
          </SFooter>
        }
      >
        <p>You are about to make the following Final Institutional Allocations Updates</p>
        <SChangesGrid>
          <span />
          <STextLight>Change From</STextLight>
          <STextLight>To</STextLight>
        </SChangesGrid>
        {Object.entries(changesByIndicationId).map(
          ([key, value]: [string, { initialValue: number | null; newValue: number | null }]) => {
            const indication: InstitutionalIndicationWithFinalAllocation | undefined = find(
              currentInstitutionalIndications,
              { id: key }
            );

            return (
              <SChangesGrid key={key}>
                <span>
                  {indication?.investorInformation.bankInvestorName ||
                    indication?.investorInformation.cmgEntityName}
                </span>
                <span>{numericUtil.formatNumber(value.initialValue, 0)} Shares</span>
                <span>{numericUtil.formatNumber(value.newValue, 0)} Shares</span>
              </SChangesGrid>
            );
          }
        )}
        <SChangesGrid>
          <STextBold>Total Allocation</STextBold>
          <STextBold>
            {numericUtil.formatNumber(
              currentFinalInstitutionalAllocationSet?.totalAllocatedShareQuantity,
              0
            )}{' '}
            Shares
          </STextBold>
          <STextBold>
            {numericUtil.formatNumber(
              determineNewTotalAllocation(currentInstitutionalIndications, changesByIndicationId),
              0
            )}{' '}
            Shares
          </STextBold>
        </SChangesGrid>
        <p>
          These changes will be communicated to the affected investors and all the underwriters that
          final allocations have been shared with.
        </p>
      </Modal>
    </React.Fragment>
  );
};

const name = 'ORDERBOOK/UPDATE_FINAL_INSTITUTIONAL_ALLOCATIONS_MODAL';
export const openUpdateFinalInstitutionalAllocationsModal = (props: OwnProps) => show(name, props);
export const closeUpdateFinalInstitutionalAllocationsModal = () => hide(name);

export default connectModal({ name })(UpdateFinalInstitutionalAllocationsModal);
