import {
  SyndicateWires_UpdatePotAccountWireMutation,
  useSyndicateWires_UpdatePotAccountWireMutation,
} from '../graphql';

export type UpdatePotAccountWireMutation = SyndicateWires_UpdatePotAccountWireMutation;

export const useUpdatePotAccountWireMutation = () => {
  return useSyndicateWires_UpdatePotAccountWireMutation();
};
