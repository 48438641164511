import { SignalREvent, useListenToServerEvent } from '@cmg/common';
import React from 'react';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import { useDocumentTitle } from '../../../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../../../common/util/routeFactory';
import { useWireTypeConfigContext } from '../../context/WireTypeConfigContext';
import SyndicateWireContent from '../syndicate-wire-content/SyndicateWireContent';
import { SyndicateWiresErrorBanner } from '../syndicate-wires-error-banner/SyndicateWiresErrorBanner';
import { WireValidation } from '../validation/wire-validation/WireValidation';
import {
  filterIgnoredManagerErrorTypes,
  hasValidationErrors,
} from '../validation/wire-validation/WireValidation.model';
import { TemplateStage } from '../wire-stage/template-stage/TemplateStage';
import { WireExistsStage } from '../wire-stage/wire-exists-stage/WireExistsStage';
import { isLoadingFirstTime, WireRouteProps } from './WireTypeRoute.model';

export function WireTypeRoute({ match, issuerName }: WireRouteProps) {
  const { offeringId, syndicateWireId, managerId } = match.params;
  const { useWireValidation, useManagers, useNextRecipients, wireTypeCategory, wireTypeName } =
    useWireTypeConfigContext();

  useDocumentTitle(
    routeFactory.syndicateWires.getDocumentTitle({
      issuerName,
      wireName: wireTypeName,
    })
  );

  const {
    data: validationBeforeFiltering,
    isLoading: isValidationLoading,
    error: validationError,
    refetch,
  } = useWireValidation({ offeringId });
  const {
    data: managers,
    isLoading: isManagersLoading,
    error: managersError,
  } = useManagers({ offeringId, syndicateWireId });
  const {
    data: nextRecipients,
    isLoading: isNextRecipientsLoading,
    error: nextRecipientsError,
  } = useNextRecipients({ offeringId, syndicateWireId });

  const validation = React.useMemo<typeof validationBeforeFiltering>(() => {
    return (
      validationBeforeFiltering && {
        ...validationBeforeFiltering,
        managers: filterIgnoredManagerErrorTypes(validationBeforeFiltering.managers),
      }
    );
  }, [validationBeforeFiltering]);

  const showLoadingIndicator = isLoadingFirstTime({
    isValidationLoading,
    isManagersLoading,
    isNextRecipientsLoading,
    validation,
    managers,
    nextRecipients,
  });

  const isValidationVisible = hasValidationErrors(validation);

  useListenToServerEvent(
    SignalREvent.CALENDAR_REFRESH,
    React.useCallback(
      refreshedOfferingId => {
        if (refreshedOfferingId !== offeringId || !isValidationVisible) {
          return;
        }

        refetch();
      },
      [offeringId, isValidationVisible, refetch]
    )
  );

  if (showLoadingIndicator) {
    return (
      <SyndicateWireContent>
        <Loading />
      </SyndicateWireContent>
    );
  }

  if (managersError || !managers || nextRecipientsError || !nextRecipients) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner
            loadedEntity={`the ${wireTypeName} ${wireTypeCategory} managers`}
          />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  if (validationError) {
    return (
      <SyndicateWireContent>
        <SyndicateWireContent.ContentSection>
          <SyndicateWiresErrorBanner
            loadedEntity={`the ${wireTypeName} ${wireTypeCategory} validation rules`}
          />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  if (validation && isValidationVisible) {
    return (
      <SyndicateWireContent data-test-id="wire-validation">
        <SyndicateWireContent.ContentSection>
          <WireValidation validation={validation} managers={managers} offeringId={offeringId} />
        </SyndicateWireContent.ContentSection>
      </SyndicateWireContent>
    );
  }

  if (syndicateWireId) {
    return (
      <WireExistsStage
        offeringId={offeringId}
        managers={managers}
        nextRecipients={nextRecipients}
        syndicateWireId={syndicateWireId}
        managerId={managerId}
      />
    );
  }

  return <TemplateStage offeringId={offeringId} nextRecipients={nextRecipients} />;
}
