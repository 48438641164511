import { SyndicateWiresManagerRole } from '../../../../../graphql';
import { SyndicateWires_WireMessagePartsFragment } from '../../common/graphql';
import { SyndicateWire } from '../../SyndicateWiresRoute.model';

export function convertEmailsToFirmSnapshotsInReport<T extends SyndicateWire>(report: T) {
  return {
    ...report,
    wireMessages: report.wireMessages?.map(convertEmailToFirmSnapshotInWireMessage),
  };
}

export function convertEmailToFirmSnapshotInWireMessage(
  message: SyndicateWires_WireMessagePartsFragment
): SyndicateWires_WireMessagePartsFragment {
  const { role: offeringManagerRole, ...manager } = convertEmailAddressToManager(
    message.emailAddresses[0]
  );

  return {
    ...message,
    firmSnapshot: {
      __typename: 'FirmSnapshot',
      offeringManagerRole,
      ...manager,
    },
  };
}

export function convertEmailAddressToManager(email: string) {
  return {
    cmgEntityKey: email,
    role: 'PRICING_COMMITTEE' as SyndicateWiresManagerRole,
    firmName: email,
  };
}
