import { Grid } from '@cmg/design-system';
import { FC } from 'react';

export const WireFormLayout: FC = ({ children }) => {
  return (
    <Grid container rowGap={3} columnSpacing={2}>
      {children}
    </Grid>
  );
};
