import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_RetentionWiresDocument,
  SyndicateWires_SendRetentionWireMutation,
  useSyndicateWires_SendRetentionWireMutation,
} from '../graphql';

export type SendRetentionWireMutation = SyndicateWires_SendRetentionWireMutation;

export const useSendRetentionWireMutation = () => {
  return useSyndicateWires_SendRetentionWireMutation({ refetchQueries, awaitRefetchQueries: true });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_RetentionWiresDocument];
  return getManyOperationNames(documentNodes);
}
