import { Icon, Popover } from '@cmg/common';
import React from 'react';

import { InfoIconWrapper, SContentWrapper } from './InfoIconPopover.styles';

const InfoIconPopover: React.FC = ({ children }) => {
  return (
    <Popover
      variant="DARK"
      arrowPointAtCenter
      placement="topRight"
      content={<SContentWrapper>{children}</SContentWrapper>}
    >
      <InfoIconWrapper>
        <Icon name="info-circle" variant="solid" />
      </InfoIconWrapper>
    </Popover>
  );
};

export default InfoIconPopover;
