import React from 'react';

import { SEmptyContent, SHeaderWrapper, SLabel, SText, STitle } from './Header.styles';

const Header: React.FC = ({ children }) => {
  return <SHeaderWrapper>{children}</SHeaderWrapper>;
};

export default Object.assign(Header, {
  Title: STitle,
  Label: SLabel,
  Text: SText,
  EmptyContent: SEmptyContent,
});
