import * as Yup from 'yup';

import { OfferingType } from '../../../../../../graphql';
import { MAX_32_BIT_INT } from '../../../../../../types/graphql/constants';
import { MAX_INCREMENT, MIN_INCREMENT } from '../types';

type Values = {
  demandMin: number;
  demandMax: number;
  demandIncrement: number;
  demandReferencePrice?: number | null;
  offeringType?: OfferingType;
};

export const validationSchema = Yup.object().shape({
  demandMin: Yup.mixed<number>()
    .required('LOW is required')
    .test('test-demandColumnMinPositive', 'LOW must be greater than 0', function checkValue(value) {
      return value > 0;
    })
    .test(
      'test-demandColumnMin',
      'LOW must be smaller than HIGH',
      function checkValue(this: typeof validationSchema, value) {
        const { demandMax } = this.parent as Values;
        return value <= demandMax;
      }
    ),
  demandMax: Yup.mixed<number>()
    .required('HIGH is required')
    .test(
      'test-demandColumnMaxPositive',
      'HIGH must be greater than 0',
      function checkValue(value) {
        return value > 0;
      }
    )
    .test(
      'test-demandColumnMax',
      'HIGH must be larger than LOW',
      function checkValue(this: typeof validationSchema, value) {
        const { demandMin } = this.parent as Values;
        return value >= demandMin;
      }
    )
    .test(
      'test-demandColumnMax-UpperBound',
      `HIGH must be less than ${MAX_32_BIT_INT}`,
      function checkValue(this: typeof validationSchema, value) {
        return value <= MAX_32_BIT_INT;
      }
    ),
  demandIncrement: Yup.number()
    .typeError('Increment is required')
    .positive('Increment must be greater than 0')
    .min(MIN_INCREMENT, `Increment must be between ${MIN_INCREMENT} and ${MAX_INCREMENT}`)
    .max(MAX_INCREMENT, `Increment must be between ${MIN_INCREMENT} and ${MAX_INCREMENT}`)
    .required('Increment is required'),
  demandReferencePrice: Yup.number()
    .typeError('Reference Price is required')
    .positive('Reference Price must be greater than 0')
    .required('Reference Price is required'),
});
