import { IconButton, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SDescription, SItem, SWrapper } from './ActionButton.styles';

export type Props = {
  onDelete?: () => void;
  onConvert?: () => void;
  onOpen?: () => void;
};

const ActionButton: React.FC<Props> = ({ onDelete, onConvert, onOpen }) => {
  const theme = useTheme();
  return (
    <Popover
      hideArrow
      placement="bottomLeft"
      trigger="click"
      content={
        <SWrapper>
          {onDelete && (
            <SItem onClick={onDelete}>
              <span>Delete from Deal Team</span>
              <SDescription>
                Permanently remove Deal Team member from Deal Team and related Roadshows
              </SDescription>
            </SItem>
          )}
          {onConvert && (
            <SItem onClick={onConvert}>
              <span>Convert to Custom Member</span>
              <SDescription>
                Add as Custom Member and keep on Deal Team and related Roadshows
              </SDescription>
            </SItem>
          )}
          {onOpen && (
            <SItem onClick={onOpen}>
              <span>Open In Deal Teams</span>
              <SDescription>
                Configure departments and deal team members in Roadshows data base
              </SDescription>
            </SItem>
          )}
        </SWrapper>
      }
    >
      <IconButton
        icon={{
          name: 'ellipsis-v',
          variant: 'regular',
          size: '2x',
        }}
        color={theme.designSystem.colors.gray[400]}
      />
    </Popover>
  );
};

export default ActionButton;
