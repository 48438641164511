import { Collapse } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useFeatureToggles } from '../../../../common/config';
import { checkRouteIsActive } from '../../../../common/util/routeFactory';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import offeringSidePanelBuySideRouteFactory from '../../offeringSidePanelBuySideRouteFactory';
import { useOfferingSidePanelContext } from '../../order-book/indication-activity/context-old/OfferingSidePanelContext';
import { StyledSubNavLink } from './SidePanelNavbar.styles';

export type Props = {
  offeringId: string;
  baseSidePanelUrl: string;
};
const OrderBookBuySideSubNavigation: React.FC<Props> = ({ baseSidePanelUrl, children }) => {
  const { pathname } = useLocation();
  const { isOrderBookAttestationOn } = useFeatureToggles();
  // check if the internal /order-book routePath matches the existing route
  const expandOrderBookSubNav = !!useRouteMatch(
    offeringSidePanelBuySideRouteFactory.orderBook.routePath
  );
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const { indication } = useOfferingSidePanelContext();

  const { isObsoleteOffering, isFiledOffering } = useSupersededOfferingContext();

  return (
    <React.Fragment>
      {children}
      <Collapse isExpanded={expandOrderBookSubNav}>
        {!isFiledOffering && (
          <StyledSubNavLink
            activeClassName="activeLink"
            to={
              baseSidePanelUrl +
              offeringSidePanelBuySideRouteFactory.orderBookIndicationActivity.getUrlPath()
            }
            data-test-id={xcSelectors.offeringSidePanelCommunicatedDemandLink.testId}
          >
            Communicated Demand
          </StyledSubNavLink>
        )}
        <StyledSubNavLink
          activeClassName="activeLink"
          to={baseSidePanelUrl + offeringSidePanelBuySideRouteFactory.internalDemand.getUrlPath()}
          data-test-id={xcSelectors.offeringSidePanelInternalDemandLink.testId}
        >
          Internal Demand
        </StyledSubNavLink>
        {(checkRouteIsActive(
          offeringSidePanelBuySideRouteFactory.orderBookUpdateHistory.routePath,
          pathname
        ) ||
          indication.data) &&
          !isObsoleteOffering && (
            <StyledSubNavLink
              activeClassName="activeLink"
              to={
                baseSidePanelUrl +
                offeringSidePanelBuySideRouteFactory.orderBookUpdateHistory.getUrlPath({
                  cmgEntityKey: cmgEntityKey!,
                })
              }
              data-test-id={xcSelectors.offeringSidePanelUpdateHistoryLink.testId}
            >
              Update History
            </StyledSubNavLink>
          )}
        {isOrderBookAttestationOn && !isObsoleteOffering && (
          <StyledSubNavLink
            activeClassName="activeLink"
            to={
              baseSidePanelUrl +
              offeringSidePanelBuySideRouteFactory.orderBookCompliance.getUrlPath()
            }
          >
            Compliance
          </StyledSubNavLink>
        )}
      </Collapse>
    </React.Fragment>
  );
};

export default OrderBookBuySideSubNavigation;
