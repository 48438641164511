import {
  finalAllocationAcknowledgementStatusPredicate,
  indicationStatusPredicate,
  indicationTypePredicate,
} from '../../utils';
import {
  IndicationWithDemandLevels,
  SyndicateManager,
} from '../components/institutional-demand-grid/types';

export const MISSING_MANAGER_DEFAULT_DISPLAY_NAME = 'Manager no longer in Syndicate';

type TotalsPredicateOptions = {
  skipIndicationStatusCheck?: boolean;
  skipIndicationTypeCheck?: boolean;
  skipFinalAllocationCheck?: boolean;
};

export const getTotalsFilterPredicate =
  (options?: TotalsPredicateOptions) => (indication: IndicationWithDemandLevels) => {
    return (
      indicationStatusPredicate(indication.status, options?.skipIndicationStatusCheck) &&
      indicationTypePredicate(indication.type, options?.skipIndicationTypeCheck) &&
      finalAllocationAcknowledgementStatusPredicate(
        indication.finalAllocation?.investorReply?.status,
        options?.skipFinalAllocationCheck
      )
    );
  };

type FirmDisplayNameOptions = Readonly<{
  ignoreAbbreviation?: boolean;
  defaultName?: string | null;
}>;

/**
 * Returns the firm name or abbreviation if available.
 * @param manager
 * @param defaultName - default name to return if no firm name or abbreviation is available
 * @param ignoreAbbreviation - if true, will return the firm name even when an abbreviation is available
 */
export const getFirmDisplayName = (
  manager: SyndicateManager | null,
  {
    defaultName = MISSING_MANAGER_DEFAULT_DISPLAY_NAME,
    ignoreAbbreviation,
  }: FirmDisplayNameOptions = {}
) => {
  if (manager?.firmNameAbbreviation?.trim() && !ignoreAbbreviation) {
    return manager.firmNameAbbreviation;
  }

  return manager?.firmName ?? defaultName;
};
