import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';

const AverageBuybackPriceInfoBox: React.FC = () => {
  const { averagePriceOfBuybackShares } = useAccountingContext();

  return (
    <AccountingInfoBox gridTemplateColumns="3fr 1.5fr">
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Average Price of Buyback Shares
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(averagePriceOfBuybackShares, 2)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default AverageBuybackPriceInfoBox;
