import { AgGridApi, Radio } from '@cmg/common';
import React from 'react';

import { DupesGridContext, DupesGridRow } from '../DupesModal.model';

type Props = {
  readonly api: AgGridApi;
  data: DupesGridRow;
  context: DupesGridContext;
};

export const refreshSelectionCellParams = {
  force: true,
  columns: ['selected'],
};

const DupesRadioCheck = ({ data, api }: Props) => {
  const currentNode = api.getRenderedNodes().find(n => n.data.id === data.id);
  const handleOnChange = () => {
    currentNode?.setSelected(true);
    api.refreshCells(refreshSelectionCellParams);
  };
  const isSelected = currentNode?.isSelected() ?? false;
  return (
    <Radio key="selected" checked={isSelected} value={data.id} onChange={() => handleOnChange()} />
  );
};

export default DupesRadioCheck;
