import { useAuth } from '@cmg/auth';
import { useListCompare } from '@cmg/common';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { InstitutionalDemandGrid_SummaryQuery } from '../../graphql';
import {
  InstitutionalDemandGridUpdate,
  UpdateType,
} from './useComputeDiffOnInstitutionalDemandGrid';

export const enum DraftAllocationPendingUpdateLabels {
  NEW_DRAFT_ALLOCATION_SHARED = 'Draft Set Shared',
  DRAFT_ALLOCATION_RENAME = 'Draft Set Renamed',
  DRAFT_ALLOCATION_SHARE_QUANTITY_UPDATE = 'Draft Allocation - Share Quantity Updated',
}

export type SharedDraftAllocationSetsArg =
  | InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalDraftAllocationSets']
  | undefined;

export const useComputeDiffOnSharedDraftAllocationSets = function (
  currDraftAllocationSets: SharedDraftAllocationSetsArg,
  nextDraftAllocationSets: SharedDraftAllocationSetsArg
) {
  const { oidcUserEmail: currentUserEmail, oidcUserCmgEntityKey: userCmgEntityKey } = useAuth();

  const currSharedDraftSets = React.useMemo(
    () => currDraftAllocationSets?.filter(set => set.authorCmgEntityKey !== userCmgEntityKey),
    [currDraftAllocationSets, userCmgEntityKey]
  );
  const nextSharedDraftSets = React.useMemo(
    () => nextDraftAllocationSets?.filter(set => set.authorCmgEntityKey !== userCmgEntityKey),
    [nextDraftAllocationSets, userCmgEntityKey]
  );

  const compareResult = useListCompare(currSharedDraftSets, nextSharedDraftSets, {
    key: item => item.id,
    equals: (a, b) => a.version === b.version,
  });

  const { addedItems: addedDraftAllocationSets } = compareResult;

  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return React.useMemo(() => {
    const draftAllocationSetsChanges: InstitutionalDemandGridUpdate[] = [];

    // new draft shared
    if (addedDraftAllocationSets.length > 0) {
      for (const item of addedDraftAllocationSets) {
        draftAllocationSetsChanges.push({
          type: UpdateType.NEW_DRAFT_ALLOCATION,
          pendingUpdateLabel: [
            `${item.name} ${DraftAllocationPendingUpdateLabels.NEW_DRAFT_ALLOCATION_SHARED}`,
          ],
          indicationId: '',
          timeStamp: new Date(item.shareTimestamp!),
          id: uuidV4(),
        });
      }
    }

    // allocation updated and set rename
    if (
      currSharedDraftSets &&
      nextSharedDraftSets &&
      currSharedDraftSets.length > 0 &&
      nextSharedDraftSets?.length > 0
    ) {
      currSharedDraftSets.forEach(curr => {
        const next = nextSharedDraftSets.find(draft => draft.id === curr.id)!;

        if (
          curr.name !== next.name &&
          (next.auditInfo?.modifiedByUserEmail !== currentUserEmail ||
            next.auditInfo?.modifiedByUserEmail === null)
        ) {
          draftAllocationSetsChanges.push({
            type: UpdateType.DRAFT_ALLOCATION_UPDATE,
            pendingUpdateLabel: [DraftAllocationPendingUpdateLabels.DRAFT_ALLOCATION_RENAME],
            indicationId: '',
            timeStamp: new Date(next.auditInfo?.modifiedAt!),
            id: uuidV4(),
          });
        }

        if (next.allocations.length !== curr.allocations.length) {
          const long =
            next.allocations.length > curr.allocations.length ? next.allocations : curr.allocations;
          const short =
            next.allocations.length < curr.allocations.length ? next.allocations : curr.allocations;

          const emptyAllocations = long.filter(alloc => {
            return !short.find(a => a.indicationId === alloc.indicationId);
          });
          emptyAllocations.forEach(emptyAllocation => {
            if (
              emptyAllocation.auditInfo.modifiedByUserEmail !== currentUserEmail ||
              emptyAllocation.auditInfo?.modifiedByUserEmail === null
            ) {
              draftAllocationSetsChanges.push({
                type: UpdateType.DRAFT_ALLOCATION_UPDATE,
                pendingUpdateLabel: [
                  DraftAllocationPendingUpdateLabels.DRAFT_ALLOCATION_SHARE_QUANTITY_UPDATE,
                ],
                indicationId: emptyAllocation.indicationId,
                timeStamp: new Date(emptyAllocation.auditInfo?.modifiedAt!),
                id: uuidV4(),
              });
            }
          });
        } else {
          next.allocations.forEach(nextAlloc => {
            const currAlloc = curr.allocations.find(
              alloc => alloc.indicationId === nextAlloc.indicationId
            )!;
            if (
              currAlloc &&
              nextAlloc &&
              currAlloc.shareQuantity !== nextAlloc.shareQuantity &&
              (nextAlloc.auditInfo.modifiedByUserEmail !== currentUserEmail ||
                nextAlloc.auditInfo?.modifiedByUserEmail === null)
            ) {
              draftAllocationSetsChanges.push({
                type: UpdateType.DRAFT_ALLOCATION_UPDATE,
                pendingUpdateLabel: [
                  DraftAllocationPendingUpdateLabels.DRAFT_ALLOCATION_SHARE_QUANTITY_UPDATE,
                ],
                indicationId: nextAlloc.indicationId,
                timeStamp: new Date(nextAlloc.auditInfo?.modifiedAt!),
                id: uuidV4(),
              });
            }
          });
        }
      });
    }

    return draftAllocationSetsChanges;
  }, [addedDraftAllocationSets, currentUserEmail, currSharedDraftSets, nextSharedDraftSets]);
};
