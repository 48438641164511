import { useAuth } from '@cmg/auth';
import { Panel, ToastManager, UUID } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import routeFactory from '../../../../common/util/routeFactory';
import DeleteDealTeamModal from '../../common/components/delete-deal-team-modal/DeleteDealTeamModal';
import DealTeamForm from './deal-team-form/DealTeamForm';
import {
  DealTeamFormValues,
  dealTeamSchema,
  getPayload,
  validate,
  valuesToForm,
} from './deal-team-form/DealTeamForm.model';
import { useRoadshows_DealTeamQuery, useRoadshows_UpdateDealTeamMutation } from './graphql';
import DealTeamDetailsHeader from './header/DealTeamDetailsHeader';

export type Props = RouteComponentProps<{ dealTeamId: UUID }>;

const RoadshowsDealTeamDetailsRoute: React.FC<Props> = ({ match }) => {
  const dealTeamId = match.params.dealTeamId;

  const [isModalOpen, setModalOpen] = React.useState(false);
  const {
    data: detailsData,
    loading: detailsLoading,
    error: detailsError,
  } = useRoadshows_DealTeamQuery({
    variables: { cmgEntityKey: dealTeamId! },
    skip: !dealTeamId,
  });
  const [updateDealTeam, { loading: updateLoading }] = useRoadshows_UpdateDealTeamMutation();
  const { push } = useHistory();
  const { oidcUserCmgEntityKey } = useAuth();

  const formik = useFormik<DealTeamFormValues>({
    initialValues: valuesToForm(detailsData?.dealTeam),
    validate: values => validate(values, dealTeamSchema),
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        await updateDealTeam({
          variables: {
            cmgEntityKey: dealTeamId,
            payload: getPayload(values),
          },
        });
        ToastManager.success('Successfully updated Deal Team');
      } catch {
        ToastManager.error('There was an error while updating Deal Team');
      }
    },
  });

  if (detailsError) {
    return <ServerErrorsBanner error={detailsError} />;
  }

  if (detailsLoading) {
    return <Loading />;
  }

  const isMyOrganization = oidcUserCmgEntityKey === dealTeamId;

  return (
    <FormikProvider value={formik}>
      <FlexLayout
        direction="column"
        data-test-id="roadshows / single-deal-team / deal-team-details"
      >
        {formik.dirty && (
          <DealTeamDetailsHeader
            dealTeam={detailsData?.dealTeam!}
            isLoading={updateLoading}
            onCancel={() => push(routeFactory.roadshowsDealTeams.getUrlPath())}
            onSave={formik.submitForm}
          />
        )}
        {!formik.dirty && !isMyOrganization && (
          <DealTeamDetailsHeader
            dealTeam={detailsData?.dealTeam!}
            onDelete={() => setModalOpen(true)}
          />
        )}
        {!formik.dirty && isMyOrganization && (
          <DealTeamDetailsHeader dealTeam={detailsData?.dealTeam!} />
        )}
        <FlexLayout direction="row">
          <Panel fillViewport>
            <DealTeamForm dealTeamId={dealTeamId} />
          </Panel>
        </FlexLayout>
      </FlexLayout>
      {!isMyOrganization && (
        <DeleteDealTeamModal
          dealTeamId={dealTeamId}
          show={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={() => push(routeFactory.roadshowsDealTeams.getUrlPath())}
        />
      )}
    </FormikProvider>
  );
};

export default RoadshowsDealTeamDetailsRoute;
