import { FetchResult, MutationResult } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../hooks/useIdentifyOrderBookPersona';
import {
  ComplianceProspectus_ProspectusDeliveryDocument,
  ComplianceProspectus_ResendProspectusMutation,
  ComplianceProspectus_ResendProspectusMutationVariables,
  useComplianceProspectus_ResendProspectusMutation,
} from '../graphql';

type Variables = Omit<
  ComplianceProspectus_ResendProspectusMutationVariables,
  'isSyndicatePersona' | 'offeringId'
>;

export type ComplianceProspectus_ResendProspectusMutationResult =
  FetchResult<ComplianceProspectus_ResendProspectusMutation> & {
    validationError: string | null;
  };

export const refetchQueries = () => {
  const getProspectusDeliveryCompliance = getOperationName(
    ComplianceProspectus_ProspectusDeliveryDocument
  )!;
  const getInstitutionalDemanGridSummary = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  )!;

  return [getProspectusDeliveryCompliance, getInstitutionalDemanGridSummary];
};

const getValidationError = (
  data: MutationResult<ComplianceProspectus_ResendProspectusMutation>['data']
) => {
  if (data?.resendProspectusAsSyndicate?.__typename === 'ServiceError') {
    return data.resendProspectusAsSyndicate.message;
  }

  if (data?.resendProspectusAsSalesAndTrading?.__typename === 'ServiceError') {
    return data.resendProspectusAsSalesAndTrading.message;
  }

  return null;
};

export const useResendProspectusMutation = ({
  offeringId,
}: {
  offeringId: UUID;
}): [
  (variables: Variables) => Promise<ComplianceProspectus_ResendProspectusMutationResult>,
  MutationResult<ComplianceProspectus_ResendProspectusMutation>
] => {
  const { type: personaType } = useIdentifyOrderBookPersona({ offeringId });

  const [resendProspectus, state] = useComplianceProspectus_ResendProspectusMutation({
    refetchQueries,
  });

  const handleResendProspectus = async (variables: Variables) => {
    const result = await resendProspectus({
      variables: {
        ...variables,
        offeringId,
        isSyndicatePersona: personaType === OrderBookPersonaType.SYNDICATE,
      },
    });

    return { ...result, validationError: getValidationError(result.data) };
  };

  return [handleResendProspectus, state];
};
