import { FormField, timeUtil } from '@cmg/common';
import React from 'react';

import { FilingStatus } from '../../../../../graphql';
import FilingStatusTag from '../filing-status-tag/FilingStatusTag';
import { SWrapper } from './FilingDetailsHeader.styles';

export type Props = {
  version: number;
  submittedDateTime: string;
  status: FilingStatus;
  formUniqueId: string;
  firmDealId: string;
};

const FilingDetailsHeader: React.FC<Props> = ({
  version,
  status,
  formUniqueId,
  firmDealId,
  submittedDateTime,
}) => {
  return (
    <SWrapper>
      <FormField withMargin fullWidth label="Version">
        <div>Version {version}</div>
      </FormField>
      <FormField withMargin fullWidth label="FINRA Submission Status">
        <div aria-label="Finra Status">
          <FilingStatusTag status={status} />
        </div>
      </FormField>
      <FormField withMargin fullWidth label="Form Unique ID">
        <div>{formUniqueId}</div>
      </FormField>
      <FormField withMargin fullWidth label="Firm Deal ID">
        <div>{firmDealId}</div>
      </FormField>
      <FormField withMargin fullWidth label="Submission Date and Time">
        <div>{timeUtil.formatAsDisplayDateTime(submittedDateTime)}</div>
      </FormField>
    </SWrapper>
  );
};

export default FilingDetailsHeader;
