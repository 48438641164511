import { Popover } from '@cmg/common';
import styled from 'styled-components/macro';

export const SLabel = styled.div`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 1px;
  text-decoration-color: ${({ theme }) => theme.text.color.light};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    background-color: ${() => 'transparent'};
  }
`;
