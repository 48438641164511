import { permissionsByEntity } from '@cmg/auth';

import { useOfferingCheckAccess } from './useOfferingCheckAccess';

/**
 * Checks accessibility for Draft Allocation
 */
export function useCheckDraftAllocationAccess({ offeringId }) {
  const canRead = {
    entitlement: 'READ:INSTITUTIONAL_ALLOCATION_DRAFT',
    permissions: [permissionsByEntity.InstitutionalAllocation.READ],
  };

  const canUpdate = {
    entitlement: 'UPDATE:INSTITUTIONAL_ALLOCATION_DRAFT',
    permissions: [permissionsByEntity.InstitutionalAllocation.FULL],
  };

  const canManage = {
    entitlement: 'MANAGE:INSTITUTIONAL_ALLOCATION_DRAFT',
    permissions: [permissionsByEntity.InstitutionalAllocation.FULL],
  };

  return useOfferingCheckAccess({
    offeringId,
    abilities: {
      canRead,
      canUpdate,
      canManage,
    },
  });
}
