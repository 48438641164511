import { assets, Group, Option } from '@cmg/common';
import React from 'react';
import { components, OptionProps } from 'react-select';

import { UnderwriterSearchOption } from '../UnderwriterSearch.types';
import {
  OnPlatformLogo,
  SUnderwriterOptionContainer,
  SUnderwriterOptionDetail,
  SUnderwriterOptionDetailsContainer,
  SUnderwriterOptionInfo,
  SUnderwriterOptionTitle,
} from './UnderwriterOption.styles';

type Props = {
  children: string;
  data: UnderwriterSearchOption;
};

export const UnderwriterOption: React.FC<Props> &
  React.FC<OptionProps<Option<string>, false, Group<string>>> = props => {
  const {
    data: {
      underwriterData: { isOnPlatform, mpid, crd },
    },
    children,
  } = props;

  return (
    <components.Option {...props}>
      <SUnderwriterOptionContainer>
        <OnPlatformLogo
          visible={isOnPlatform}
          src={assets.logoNoText}
          alt="This underwriter is on the CMG Platform."
        />
        <SUnderwriterOptionInfo>
          <SUnderwriterOptionTitle>{children}</SUnderwriterOptionTitle>
          <SUnderwriterOptionDetailsContainer>
            {mpid && <SUnderwriterOptionDetail>MPID: {mpid}</SUnderwriterOptionDetail>}
            {crd && <SUnderwriterOptionDetail>CRD: {crd}</SUnderwriterOptionDetail>}
          </SUnderwriterOptionDetailsContainer>
        </SUnderwriterOptionInfo>
      </SUnderwriterOptionContainer>
    </components.Option>
  );
};
