import { PrimaryButton, SecondaryButton, SuccessButton, ToastManager } from '@cmg/common';
import { FormControlLabel, Switch } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { FeatureToggleSwitch, getFeatureToggles } from '../../../../../../common/config';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import routeFactory from '../../../../../../common/util/routeFactory';
import offeringSidePanelSellSideRouteFactory from '../../../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { ResendProspectusResult } from '../../../../../offering-side-panel/order-book/compliance/prospectus/hooks/useBulkResendProspectus.model';
import { useFinalAllocations } from '../../../contexts/finalAllocationsContext';
import { openUpdateComplianceAllocationReleaseModal } from '../../compliance-allocation-release-modal/ComplianceAllocationReleaseModal';
import InstitutionalDemandGridUpdatesContext from '../../institutional-demand-grid/context/InstitutionalDemandGridUpdatesContext';
import { openUpdateFinalInstitutionalAllocationsModal } from '../../update-final-institutional-allocations-modal/UpdateFinalInstitutionalAllocationsModal';
import InstitutionalDemandOrdersAndAllocationsExportDropdownButton from '../components/institutional-demand-orders-and-allocations-export-button/InstitutionalDemandOrdersAndAllocationsExportDropdownButton';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../graphql';
import { GridConfiguration } from '../hooks/useDemandConfiguration';
import {
  InstitutionalDemandFilterArgs,
  InstitutionalDemandGridOfferingColumnConfig,
  InstitutionalGridSummary,
} from '../types';
import InstitutionalDemandAllocationsDropdownButton from './allocations-dropdown-menu/InstitutionalDemandAllocationsDropdownButton';
import AssignBillingAndDeliveryAgentForIndicationsModal, {
  openAssignBillingAndDeliveryAgentForIndicationsModal,
} from './assign-billing-delivery-agent-modal/AssignBillingAndDeliveryAgentModal';
import InstitutionalDemandGridColumnsDropdownButton from './column-dropdown-menu/InstitutionalDemandGridColumnDropdownButton';
import InstitutionalDemandGridDataRefereshButton from './data-refresh-button/InstitutionalDemandGridDataRefreshButton';
import DupesModal from './dupes/DupesModal';
import EditAllocationsModal, {
  openEditAllocationsModal,
} from './edit-allocations-modal/EditAllocationsModal';
import InstitutionalDemandFilters from './filters/InstitutionalDemandFilters';
import {
  SButtonWrapper,
  SToolbarSection,
  SToolbarWrapper,
} from './InstitutionalDemandToolbar.styles';
import SelectedRowsToolbar from './SelectedRowsToolbar';

export type Props = Readonly<{
  offeringId: string;
  gridSummaryData: InstitutionalGridSummary;
  activeFilters: InstitutionalDemandFilterArgs;
  onFiltersChange: (args: InstitutionalDemandFilterArgs) => void;
  onToggleMetrics: (checked: boolean) => void;
  metricsVisible: boolean;
  withMetricsSwitch: boolean;
  gridConfiguration: GridConfiguration;
  onExportToCsv: () => void;
  changeCounter: number;
  onDataRefresh: () => void;
  onViewPendingUpdates: () => void;
  selectedIndicationIds: string[];
  onSaveDupesCompleted: () => void;
  gridOfferingConfig: InstitutionalDemandGridOfferingColumnConfig | null;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  isProspectusDocumentUploaded: boolean;
  resendProspectus: (investorKeys: string[]) => Promise<ResendProspectusResult>;
  isResendProspectusLoading: boolean;
}>;

/**
 * Renders the Toolbar for the Institutional Demand Grid
 */
const InstitutionalDemandToolbar: React.FC<Props> = ({
  offeringId,
  gridSummaryData,
  gridConfiguration,
  activeFilters,
  onFiltersChange,
  onExportToCsv,
  onViewPendingUpdates,
  onToggleMetrics,
  metricsVisible,
  withMetricsSwitch,
  selectedIndicationIds,
  onSaveDupesCompleted,
  gridOfferingConfig,
  investorsExtendedData,
  isProspectusDocumentUploaded,
  isResendProspectusLoading,
  resendProspectus,
}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { isDraftAllocationOn, isOrderBookAttestationOn } = getFeatureToggles();
  const [isDupesModalOpen, setIsDupesModalOpen] = React.useState<boolean>(false);
  const [finalAllocationsState, finalAllocationsDispatch] = useFinalAllocations();
  const finalAllocationsHaveChanged =
    finalAllocationsState && !!Object.keys(finalAllocationsState.changesByIndicationId).length;
  const debounceDisableResetFormToggle = useDebouncedCallback(() => {
    finalAllocationsDispatch({
      type: 'setResetFormToggle',
      payload: false,
    });
  }, 500);

  React.useEffect(() => {
    finalAllocationsDispatch({
      type: 'setIsFinalAllocationSetReleased',
      payload: gridSummaryData.isFinalAllocationSetReleased,
    });
  }, [gridSummaryData.isFinalAllocationSetReleased, finalAllocationsDispatch]);

  const { canUpdate: canUpdateIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });
  const { canUpdate: canUpdateFinalAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const displayEditFinalAllocationsButton =
    gridSummaryData.isFinalAllocationSetReleased &&
    !finalAllocationsState.isEditing &&
    canUpdateFinalAllocations;

  const { recentUpdates } = React.useContext(InstitutionalDemandGridUpdatesContext);

  const handleOpenAllocationsModal = openUpdateFinalInstitutionalAllocationsModal({
    offeringId,
    currentInstitutionalIndications: gridSummaryData.indications,
    currentFinalInstitutionalAllocationSet: gridSummaryData.finalSet,
  });

  const handleOpenComplianceModal = openUpdateComplianceAllocationReleaseModal({
    offeringId,
    nextModal: handleOpenAllocationsModal,
  });

  const handleResendProspectus = async () => {
    try {
      const investorKeys = gridSummaryData.indications
        .filter(indication => selectedIndicationIds.includes(indication.id))
        .map(indication => indication.investorInformation.cmgEntityKey!);

      const resendProspectusResult = await resendProspectus(investorKeys);
      if (resendProspectusResult) {
        const { validationError, numOfInvestorsRecievingProspectus } = resendProspectusResult;

        if (validationError) {
          ToastManager.error(validationError);
        } else if (numOfInvestorsRecievingProspectus > 0) {
          ToastManager.success(
            `Prospectus sent for ${numOfInvestorsRecievingProspectus} ${
              numOfInvestorsRecievingProspectus === 1 ? 'Investor' : 'Investors'
            }`
          );
        }
      }
    } catch {
      ToastManager.error('Prospectus was not sent. Required data missing.');
    }
  };

  const handleDiscardChanges = () => {
    finalAllocationsDispatch({
      type: 'setResetFormToggle',
      payload: true,
    });
    finalAllocationsDispatch({ type: 'setIsEditing', payload: false });
    debounceDisableResetFormToggle();
  };

  const handleEdit = () => {
    finalAllocationsDispatch({ type: 'setIsEditing', payload: true });
    finalAllocationsDispatch({
      type: 'setResetFormToggle',
      payload: false,
    });
  };

  const unreadUpdates = recentUpdates.filter(update => !update.read).length;

  if (selectedIndicationIds.length === 0) {
    return (
      <SToolbarWrapper>
        <SToolbarSection>
          <InstitutionalDemandFilters activeFilters={activeFilters} onChange={onFiltersChange} />
        </SToolbarSection>
        <SToolbarSection align="right">
          {withMetricsSwitch && (
            <SButtonWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={metricsVisible}
                    onChange={(_, checked) => onToggleMetrics(checked)}
                  />
                }
                label="Show Metrics"
              />
            </SButtonWrapper>
          )}
          <FeatureToggleSwitch feature="isOrderBookDataRefreshOn">
            <SButtonWrapper>
              <InstitutionalDemandGridDataRefereshButton
                changeCounter={unreadUpdates}
                handleOnViewUpdates={onViewPendingUpdates}
              />
            </SButtonWrapper>
          </FeatureToggleSwitch>
          <SButtonWrapper>
            <InstitutionalDemandOrdersAndAllocationsExportDropdownButton
              offeringId={offeringId}
              onExportToCsv={onExportToCsv}
            />
          </SButtonWrapper>
          <SButtonWrapper>
            <InstitutionalDemandGridColumnsDropdownButton
              activeDemandColumnConfig={gridConfiguration.demandColumnConfig}
              finalAllocationExists={!!gridSummaryData.finalSet}
              onDemandColumnConfigChange={gridConfiguration.setConfiguration}
              isFinalAllocationSetReleased={gridSummaryData.isFinalAllocationSetReleased}
              offeringId={offeringId}
              investorsExtendedData={investorsExtendedData}
              pricingCurrencyCode={gridOfferingConfig?.pricingCurrencyCode}
            />
          </SButtonWrapper>
          {canUpdateIndications && (
            <SButtonWrapper>
              <SuccessButton
                testId={xcSelectors.institutionalDemandCreateIndicationButton.testId}
                asComponent={Link}
                disabled={false} // Eventually this needs to be disabled when allocations are released
                to={routeFactory.offeringSidePanel.getUrlPath({
                  offeringId,
                  prefixRoutePath: match.url,
                  sidePanelRoutePath:
                    offeringSidePanelSellSideRouteFactory.orderBookNewIndication.getUrlPath({}),
                })}
              >
                New Indication
              </SuccessButton>
            </SButtonWrapper>
          )}
          {isDraftAllocationOn && (
            <SButtonWrapper>
              <InstitutionalDemandAllocationsDropdownButton
                offeringId={offeringId}
                finalInstitutionalAllocationSet={gridSummaryData.finalSet}
                draftAllocationSets={gridSummaryData.draftSets}
              />
            </SButtonWrapper>
          )}

          {displayEditFinalAllocationsButton && (
            <SButtonWrapper>
              <PrimaryButton
                testId={xcSelectors.institutionalDemandEditAllocationsButton.testId}
                onClick={() => handleEdit()}
              >
                Edit Allocations
              </PrimaryButton>
            </SButtonWrapper>
          )}

          {gridSummaryData.isFinalAllocationSetReleased && finalAllocationsState.isEditing && (
            <React.Fragment>
              <SButtonWrapper>
                <SecondaryButton onClick={() => handleDiscardChanges()}>
                  Discard Changes
                </SecondaryButton>
              </SButtonWrapper>
              <SButtonWrapper>
                <PrimaryButton
                  disabled={!finalAllocationsHaveChanged}
                  testId={xcSelectors.institutionalDemandSaveAllocationsButton.testId}
                  onClick={() =>
                    dispatch(
                      isOrderBookAttestationOn
                        ? handleOpenComplianceModal
                        : handleOpenAllocationsModal
                    )
                  }
                >
                  Save Allocations
                </PrimaryButton>
              </SButtonWrapper>
            </React.Fragment>
          )}
        </SToolbarSection>
      </SToolbarWrapper>
    );
  }

  return (
    <div>
      <SelectedRowsToolbar
        indicationIds={selectedIndicationIds}
        offeringId={offeringId}
        onMarkAsDuplicate={() => {
          setIsDupesModalOpen(true);
        }}
        isProspectusDocumentUploaded={isProspectusDocumentUploaded}
        onResendProspectus={handleResendProspectus}
        isResendProspectusLoading={isResendProspectusLoading}
        onEnterAllocations={() => {
          dispatch(openEditAllocationsModal());
        }}
        onAssignBDAgent={() => {
          dispatch(openAssignBillingAndDeliveryAgentForIndicationsModal());
        }}
      />
      {isDupesModalOpen && (
        <DupesModal
          onCancel={() => {
            setIsDupesModalOpen(false);
          }}
          onSaveCompleted={() => {
            setIsDupesModalOpen(false);
            onSaveDupesCompleted();
          }}
          indicationIds={selectedIndicationIds}
          indications={gridSummaryData.indicationDemands}
          managers={gridOfferingConfig?.syndicate.managers ?? []}
          pricingCurrencyCode={gridOfferingConfig?.pricingCurrencyCode ?? undefined}
        />
      )}
      <EditAllocationsModal
        selectedIndicationIds={selectedIndicationIds}
        draftSets={gridSummaryData.draftSets}
        finalSet={gridSummaryData.finalSet}
        offeringId={offeringId}
      />
      <AssignBillingAndDeliveryAgentForIndicationsModal
        selectedIndicationIds={selectedIndicationIds}
        managers={gridOfferingConfig?.syndicate.managers ?? []}
        offeringId={offeringId}
      />
    </div>
  );
};

export default React.memo(InstitutionalDemandToolbar);
