import { useCallback, useState } from 'react';

export type UsePristineDataWarningResult = [
  isWarningShown: boolean,
  startShowingWarning: () => void
];

export function usePristineDataWarning(areDataDirty: boolean): UsePristineDataWarningResult {
  const [showWarning, setShowWarning] = useState(false);
  const startShowingWarning = useCallback(() => setShowWarning(true), []);

  return [showWarning && !areDataDirty, startShowingWarning];
}
