import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_DealTeamListDocument } from '../../../../overview/all-deal-teams/graphql';
import { useRoadshows_DeleteDealTeamMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchRoadshows_DealTeamListQuery = getOperationName(Roadshows_DealTeamListDocument);

  return [refetchRoadshows_DealTeamListQuery!];
};

export const useDeleteDealTeamMutation = () => {
  return useRoadshows_DeleteDealTeamMutation({
    refetchQueries,
    awaitRefetchQueries: false,
  });
};
