import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import { calculateFinalSettlementValues } from './FinalSettlementInfoBox.model';
import { BoxWrapper, SSource, STitle } from './FinalSettlementInfoBox.styles';

const FinalSettlementInfoBox: React.FC = () => {
  const accounting = useAccountingContext();
  const { totalFinalSettlement, totalRetailRetentionFees, netDealFees } =
    calculateFinalSettlementValues(accounting);

  return (
    <BoxWrapper>
      <Table gridTemplateColumns="2fr 1fr 10px 1.5fr">
        <Table.Cell paddingSize="S" colSpan={4} withBottomBorder={false} bold>
          Final Settlement Calculation <SSource>(from Final Settlement table)</SSource>
        </Table.Cell>

        <Table.Cell>
          <STitle>Net Deal Fees</STitle>
        </Table.Cell>
        <Table.Cell paddingSize="L" colSpan={3} align="right">
          <STitle>{numericUtil.getDisplayValueForCurrency(netDealFees, 2)}</STitle>
        </Table.Cell>

        <Table.Cell colSpan={4} withBottomBorder={false} />

        <Table.Cell paddingSize="S" colSpan={3} withBottomBorder={false}>
          Final Settlement Total
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
          {numericUtil.getDisplayValueForCurrency(totalFinalSettlement, 2)}
        </Table.Cell>

        <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
          Add Back: Retail Retention Fees
        </Table.Cell>
        <Table.Cell paddingSize="S" withBottomBorder={false} bold>
          +
        </Table.Cell>
        <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
          {numericUtil.getDisplayValueForCurrency(totalRetailRetentionFees, 2)}
        </Table.Cell>
      </Table>
    </BoxWrapper>
  );
};

export default FinalSettlementInfoBox;
