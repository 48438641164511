import React from 'react';

import { useGetNotesAndOrdersQuery } from '../hooks/useGetNotesAndOrdersQuery';
import { useGetSupersededDataOffering } from '../hooks/useGetSupersededDataOffering';

export type Props = ReturnType<typeof useGetSupersededDataOffering> &
  ReturnType<typeof useGetNotesAndOrdersQuery>;

const SupersededOfferingContext = React.createContext<Props>({
  isObsoleteOffering: false,
  supersededByOfferingId: null,
  supersededOfferingId: null,
  hasNotes: false,
  hasOrders: false,
  hasCommunicatedDemand: false,
  hasSupersedeData: false,
  oldOfferingId: undefined,
  isFiledOffering: false,
});

export const useSupersededOfferingContext = () => {
  const context = React.useContext(SupersededOfferingContext);

  if (context === undefined) {
    throw new Error('No Provider found for SupersededOfferingContext');
  }

  return context;
};

export const SupersededOfferingProvider: React.FC<{ data: Props }> = ({ children, data }) => {
  return (
    <SupersededOfferingContext.Provider value={data}>{children}</SupersededOfferingContext.Provider>
  );
};
