import { numericUtil, UUID } from '@cmg/common';
import { Grid, Stack, Typography } from '@cmg/design-system';
import React from 'react';

import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
} from '../../../../../../../../graphql';
import { isIndicationStatusAndTypeValid } from '../../../../../../../order-book/utils';
import { useIdentifyOrderBookPersona } from '../../../../../hooks/useIdentifyOrderBookPersona';
import GridRowPair from '../../../manage-indication/common/indication-section-read-view/grid-row-pair/GridRowPair';
import { getMessage } from './AllocationDetails.model';

export type Props = Readonly<{
  shareQuantity: number | null;
  offerPrice: number;
  pricingCurrencyCode?: string;
  offeringId: UUID;
  indicationStatus: IndicationStatus | null;
  orderType: InstitutionalIndicationOrderType | null;
  isOrderBookOpen: boolean;
  allocationConfirmation: string;
  isReleased: boolean;
}>;

/**
 * Header for final Allocation
 */
export const AllocationDetails: React.FC<Props> = ({
  offeringId,
  offerPrice,
  pricingCurrencyCode,
  shareQuantity,
  orderType,
  indicationStatus,
  isOrderBookOpen,
  allocationConfirmation,
  isReleased,
}) => {
  const { type: personaType, isSellSide } = useIdentifyOrderBookPersona({ offeringId });

  const label = getMessage({ personaType, indicationStatus, orderType, isOrderBookOpen });

  const isValid =
    indicationStatus && orderType && isIndicationStatusAndTypeValid(indicationStatus, orderType);
  const showLabel = !isValid && label;

  return (
    <Stack gap={1} aria-label="Allocation details" data-testid="read-allocation-details">
      {showLabel && (
        <Typography variant="body1">
          {label.prefix}
          <Typography variant="highlight1">{label.state}</Typography>
          {label.suffix}
        </Typography>
      )}
      <Grid container rowGap={1}>
        <GridRowPair
          label="Allocation Shares"
          value={isReleased ? numericUtil.getDisplayValueForInteger(shareQuantity) : '-'}
        />
        <GridRowPair
          label="Offer Price"
          value={
            isReleased
              ? numericUtil.getDisplayValueForCurrency(
                  offerPrice ? offerPrice : null,
                  undefined,
                  pricingCurrencyCode
                )
              : '-'
          }
        />
        <GridRowPair
          label="Allocation Notional"
          value={
            isReleased
              ? numericUtil.getDisplayValueForCurrency(
                  shareQuantity ? shareQuantity * offerPrice : null,
                  0,
                  pricingCurrencyCode
                )
              : '-'
          }
        />
        {isSellSide && (
          <GridRowPair
            label="Allocation Confirmation"
            value={isReleased ? allocationConfirmation : '-'}
          />
        )}
      </Grid>
    </Stack>
  );
};

export default AllocationDetails;
