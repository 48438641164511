import {
  ODSTable as Table,
  PrimaryButton,
  SecondaryButton,
  TextInputField,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Modal from '../../../../../../common/components/overlays/modal/Modal';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import Section from '../../../../common/components/section/Section';
import { useAssignCompanyMutation } from '../hooks/useAssignCompanyMutation';
import {
  getCreateCompanyVariables,
  initialValues,
  validationSchema,
  Values,
} from './CreateCompanyModal.model';
import { StyledModalFooter, StyledSectionContent } from './CreateCompanyModal.styles';
import { useRoadshows_CreateCompanyMutation } from './graphql';

export type Props = { show: boolean; onHide: () => void; roadshowId: UUID };

const CreateCompanyModal: React.FC<Props> = ({ show, onHide, roadshowId }) => {
  const [createCompany, { loading: isCreatingCompany }] = useRoadshows_CreateCompanyMutation();
  const [assignCompany, { loading: isAssigningCompany }] = useAssignCompanyMutation();

  const handleCreateAndAssignCompany = async (values: Values) => {
    try {
      const { data } = await createCompany({
        variables: getCreateCompanyVariables(values),
      });

      await assignCompany({
        variables: {
          roadshowId,
          cmgEntityKey: data?.createCompany.id,
        },
      });
    } catch {
      ToastManager.error('Failed to create or assign Company');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleCreateAndAssignCompany,
  });

  const isLoading = isAssigningCompany || isCreatingCompany;

  return (
    <Modal show={show} title="Create New Company" size="xlarge" shouldCloseOnOverlayClick={false}>
      <Modal.Content>
        <Section.Header>Company Details</Section.Header>
        <StyledSectionContent>
          <Table gridTemplateColumns="1fr">
            <Table.Row>
              <Table.TableHeaderCell>
                Company Name <MandatoryAsterisk />
              </Table.TableHeaderCell>
            </Table.Row>

            <Table.Row>
              <FormikProvider value={formik}>
                <Form>
                  <Table.Cell>
                    <TextInputField name="companyName" required />
                  </Table.Cell>
                </Form>
              </FormikProvider>
            </Table.Row>
          </Table>
        </StyledSectionContent>
      </Modal.Content>

      <StyledModalFooter>
        <SecondaryButton testId="close-create-company-modal-button" onClick={onHide}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={formik.submitForm} disabled={isLoading} loading={isLoading}>
          Create and Add Company
        </PrimaryButton>
      </StyledModalFooter>
    </Modal>
  );
};

export default CreateCompanyModal;
