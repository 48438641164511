import { UUID } from '@cmg/common';
import { DomainNav } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FC } from 'react';
import { useLocation } from 'react-router';

import routeFactory, { findActiveRouteGroup } from '../../../../common/util/routeFactory';
import { ReactRouterDomainNavTab } from '../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage, OfferingStatus, OfferingType } from '../../../../graphql';
import {
  useFinalSettlementRoute,
  useOfferingDetailsRoute,
  useOrderBookRoute,
  useRegulatoryFilingsRoute,
  useSalesCreditsRoute,
  useSyndicateWiresRoute,
  useTradeReleaseRoute,
} from './hooks';

export type Props = {
  offeringId?: UUID;
  offeringStage: OfferingStage;
  isAuthor: boolean;
  offeringType?: OfferingType;
  offeringStatus?: OfferingStatus;
  isSharedDraft: boolean;
  isDiscarded: boolean;
};

/**
 * OfferingNavbar displays a sub-navigation bar on the offerings view
 * displays navigation items for details, order-book and roadshow
 */
export const OfferingNavbar: FC<Props> = ({
  offeringId,
  offeringStage,
  isAuthor,
  offeringType,
  offeringStatus,
  isSharedDraft,
  isDiscarded,
}) => {
  const { pathname } = useLocation();
  const activeRouteGroup = findActiveRouteGroup(pathname, {
    'offering-detail': [
      routeFactory.offeringSetup.routePath,
      routeFactory.offeringDetails.routePath,
      routeFactory.sharedDraftOffering.routePath,
    ],
    'order-book': routeFactory.orderBook.routePath,
    trade: routeFactory.tradeRelease.routePath,
    'reg-m': routeFactory.regulatoryFilings.routePath,
    wires: routeFactory.syndicateWires.routePath,
    'final-settlement': routeFactory.finalSettlement.routePath,
    'sales-credits': routeFactory.salesCredits.routePath,
  });
  const orderBookRoute = useOrderBookRoute({
    offeringId,
    offeringStatus: offeringStatus,
    offeringStage: offeringStage,
  });
  const offeringDetailRoute = useOfferingDetailsRoute({
    isAuthor,
    offeringStage,
    offeringId,
    isSharedDraft,
  });
  const regulatoryFilingsRoute = useRegulatoryFilingsRoute({
    offeringId,
    isDiscarded,
    isSharedDraft,
  });
  const wiresRoute = useSyndicateWiresRoute({
    offeringId,
    isDiscarded,
    isSharedDraft,
    offeringType,
  });
  const tradeReleaseRoute = useTradeReleaseRoute({
    offeringId,
    offeringStatus,
    offeringStage,
  });
  const finalSettlementRoute = useFinalSettlementRoute({
    isAuthor,
    offeringId,
    offeringStage,
    offeringStatus,
  });
  const salesCreditsRoute = useSalesCreditsRoute({
    offeringId,
    offeringStatus,
    offeringStage,
  });
  return (
    <DomainNav
      value={activeRouteGroup?.[0]}
      data-test-id={xcSelectors.offeringNavbar.testId}
      role="navigation"
      aria-roledescription="offering navbar"
    >
      {offeringId && offeringDetailRoute && (
        <ReactRouterDomainNavTab {...offeringDetailRoute} value="offering-detail" />
      )}
      {orderBookRoute && <ReactRouterDomainNavTab {...orderBookRoute} value="order-book" />}
      {tradeReleaseRoute && <ReactRouterDomainNavTab {...tradeReleaseRoute} value="trade" />}
      {regulatoryFilingsRoute && (
        <ReactRouterDomainNavTab {...regulatoryFilingsRoute} value="reg-m" />
      )}
      {wiresRoute && <ReactRouterDomainNavTab {...wiresRoute} value="wires" />}
      {finalSettlementRoute && (
        <ReactRouterDomainNavTab {...finalSettlementRoute} value="final-settlement" />
      )}
      {salesCreditsRoute && (
        <ReactRouterDomainNavTab {...salesCreditsRoute} value="sales-credits" />
      )}
    </DomainNav>
  );
};
