import { Alert } from '@cmg/common';

export type Props = {
  variant: 'in-main-form' | 'in-activities-grid';
};

export const MissingStabilizationActivitiesAlert: React.FC<Props> = ({ variant }) => {
  return (
    <Alert withMargin={variant === 'in-main-form'} severity="warning">
      <Alert.Title>
        There was no Stabilization Activity data found. Please add Custom Activities or update the
        information in Final Settlement.
      </Alert.Title>
    </Alert>
  );
};
