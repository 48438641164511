import { ISODate, timeUtil } from '@cmg/common';

import { Recipient } from '../../../common/components/lists/recipient-list/RecipientList.model';
import { ManagerRole, NotificationMessageStatus } from '../../../graphql';
import {
  RegulatoryFilings_FilingNotificationMessagePartsFragment,
  RegulatoryFilings_RolodexFilingRecipientPartsFragment,
} from '../graphql';

export const defaultStatus: Recipient<ManagerRole>['status'] = Object.freeze({
  label: 'Not Sent',
  color: 'disabled',
});

export const getRecipientsWithDefaultStatus = (
  recipients: readonly RegulatoryFilings_RolodexFilingRecipientPartsFragment[] | undefined
) => {
  return (recipients ?? []).map<Recipient<ManagerRole>>(recipient => ({
    ...recipient,
    distributionList: recipient.distributionList,
    status: { ...defaultStatus },
  }));
};

export const getNotificationRecipientStatus = (
  sentAt: ISODate | null | undefined,
  status: NotificationMessageStatus
): Recipient<ManagerRole>['status'] => {
  if (status === NotificationMessageStatus.NotSent) {
    return { ...defaultStatus };
  }

  return { label: `Sent on ${timeUtil.formatAsDisplayDate(sentAt!)}`, color: 'success' };
};

export const getRecipientsOfNotificationMessages = (
  notifications: readonly RegulatoryFilings_FilingNotificationMessagePartsFragment[] | undefined
) => {
  return (notifications ?? []).map<Recipient<ManagerRole>>(
    ({ status, sentAt, distributionList, managerSnapshot }) => ({
      cmgEntityKey: managerSnapshot.cmgEntityKey,
      role: managerSnapshot.role,
      firmName: managerSnapshot.firmName,
      status: getNotificationRecipientStatus(sentAt, status),
      distributionList: distributionList,
    })
  );
};
