import { useInvestorFirmSearch_CoveredInvestorFirmsQuery } from '../../../common/components/form/investor-firm-search/graphql';
import { isValidCmgEntityKey } from '../../../common/util/isValidCmgEntityKey';
import { useOfferingSidePanel_InvestorFirmSearchQuery } from '../graphql';
import { useOfferingSidePanelContext } from '../order-book/indication-activity/context-old/OfferingSidePanelContext';

export function useInvestorFirmSearch(
  {
    cmgEntityKey,
    firmKeyOrId,
  }: {
    cmgEntityKey?: string;
    firmKeyOrId?: string;
  },
  isCoveredAccount: boolean = false
) {
  const { investorFirmInfo } = useOfferingSidePanelContext();

  // call this query if cmgEntityKey query param is set to firm key eg. cmgEntityKey=firmkey%abc123
  const { data: investorFirmWithoutCmgEntityKey, loading: investorFirmLoading } =
    useOfferingSidePanel_InvestorFirmSearchQuery({
      variables: {
        firmKeyOrId,
      },
      skip: !firmKeyOrId || isValidCmgEntityKey(cmgEntityKey) || isCoveredAccount,
      fetchPolicy: 'cache-and-network',
    });

  const investorFirmWithCmgEntityKey = investorFirmInfo?.allInvestorFirmsMappedToCmgEntityKey?.find(
    investor => {
      return investor.firmKey && firmKeyOrId
        ? investor.firmKey === firmKeyOrId
        : investor.cmgEntityKey === cmgEntityKey;
    }
  );

  const investorFirmResult =
    investorFirmWithCmgEntityKey || investorFirmWithoutCmgEntityKey?.investorFirm;

  const coveredInvestorFirmWithCmgEntityKey =
    investorFirmInfo.allInvestorFirmsMappedToCmgEntityKey.find(investor => {
      return investor.firmKey && firmKeyOrId
        ? investor.firmKey === firmKeyOrId
        : investor.cmgEntityKey === cmgEntityKey;
    });

  const { data: coveredInvestorFirms, loading: coveredInvestorFirmsLoading } =
    useInvestorFirmSearch_CoveredInvestorFirmsQuery({
      variables: { firmKey: firmKeyOrId!, searchText: '', page: 1, perPage: 1 },
      skip: !cmgEntityKey || isValidCmgEntityKey(cmgEntityKey) || !isCoveredAccount,
      fetchPolicy: 'cache-and-network',
    });

  const coveredInvestorFirmWithoutCmgEntityKey = coveredInvestorFirms?.coveredInvestorFirms?.data
    ? coveredInvestorFirms.coveredInvestorFirms.data[0]
    : null;

  const coveredInvestorFirmResult =
    coveredInvestorFirmWithoutCmgEntityKey || coveredInvestorFirmWithCmgEntityKey;

  return {
    data: investorFirmResult || coveredInvestorFirmResult,
    loading: investorFirmLoading || investorFirmInfo.loading || coveredInvestorFirmsLoading,
  };
}
