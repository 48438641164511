import { useAuth } from '@cmg/auth';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@cmg/design-system';
import { FormikNumberField } from '@cmg/design-system-formik';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';

import { InvalidFormFieldsAlert } from '../../../../../common/components/alerts/invalid-form-fields-alert/InvalidFormFieldsAlert';
import type { DemandGridDataContext } from '../../demand-grid/types';
import type { BulkEnterAllocationValues } from './BulkEnterAllocationsDialog.model';
import { getMyAllocationSets, validationSchema } from './BulkEnterAllocationsDialog.model';

export type Props = Readonly<{
  allocationSets: DemandGridDataContext['allocationSets'];
  isOpen: boolean;
  onClose: () => void;
  onSave: (sharesQuantity: number, allocationSetIds: string[]) => void;
}>;

export const BulkEnterAllocationsDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  allocationSets,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const myAllocationSets = getMyAllocationSets(allocationSets, oidcUserCmgEntityKey);

  const formik = useFormik<BulkEnterAllocationValues>({
    initialValues: {
      allocations: null,
      allocationSetSelection: myAllocationSets.map(() => false),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      const allocationSetIds = myAllocationSets.reduce<string[]>((acc, set, index) => {
        if (values.allocationSetSelection[index]) {
          acc.push(set.id);
        }

        return acc;
      }, []);

      onSave(values.allocations!, allocationSetIds);
      onClose();
    },
  });

  const { resetForm, dirty } = formik;

  useEffect(() => {
    if (!isOpen && dirty) {
      resetForm();
    }
  }, [resetForm, dirty, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Enter Allocations</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <InvalidFormFieldsAlert
            errors={formik.errors}
            isSubmitting={formik.isSubmitting}
            touched={formik.touched}
            validationSchema={validationSchema}
          />
          <Typography>
            Please enter an allocation amount and select the set(s) to apply it to.
          </Typography>
          <FormikProvider value={formik}>
            <FormikNumberField
              name="allocations"
              label="Allocations"
              placeholder="Enter Allocations"
              required
            />
            <FieldArray name="allocationSetSelection">
              {helpers => {
                return (
                  <FormGroup>
                    <Typography variant="h4">Allocation Sets *</Typography>
                    {myAllocationSets.map((set, index) => (
                      <FormControlLabel
                        key={set.id}
                        label={set.isFinal ? 'Final Allocation Set' : set.name}
                        control={
                          <Checkbox
                            checked={formik.values.allocationSetSelection[index]}
                            onChange={e => {
                              helpers.replace(index, e.target.checked);
                            }}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                );
              }}
            </FieldArray>
          </FormikProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
