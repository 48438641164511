import { Box, styled } from '@cmg/design-system';

export const GridLayoutContainer = styled(
  Box,
  {}
)(({ theme }) => ({
  display: 'grid',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateAreas: `
    'mainForm mainForm mainForm mainForm'
    'restInformationBlock restInformationBlock . .'
    'underwritingDiscount underwritingDiscount overallotmentDetail overallotmentDetail'
  `,
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateAreas: `
    'mainForm mainForm mainForm mainForm underwritingDiscount'
    'mainForm mainForm mainForm mainForm overallotmentDetail'
    'restInformationBlock restInformationBlock . . overallotmentDetail'
    'restInformationBlock restInformationBlock . . overallotmentDetail'
  `,
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: '2fr 2fr 1fr 1fr',
    gridTemplateAreas: `
    'mainForm mainForm underwritingDiscount overallotmentDetail'
    'mainForm mainForm underwritingDiscount overallotmentDetail'
    'mainForm mainForm underwritingDiscount overallotmentDetail'
    'mainForm mainForm restInformationBlock .'
    'mainForm mainForm restInformationBlock .'
  `,
  },
}));

export const MainFormGridArea = styled(Box)({
  gridArea: 'mainForm',
});

export const UnderwritingDiscountGridArea = styled(Box)({
  gridArea: 'underwritingDiscount',
});

export const OverallotmentDetailGridArea = styled(Box)({
  gridArea: 'overallotmentDetail',
});

export const RestInformationBlockGridArea = styled(Box)({
  gridArea: 'restInformationBlock',
});
