import React from 'react';

import { WireTypeRoute } from '../../common/components/wire-type-route/WireTypeRoute';
import { WireRouteProps } from '../../common/components/wire-type-route/WireTypeRoute.model';
import { WireTypeConfigContextProvider } from '../../common/context/WireTypeConfigContext';
import { wireTypeConfigNonSyndicatePayment } from './NonSyndicatePaymentWire.model';

export const NonSyndicatePaymentWireRoute: React.FC<WireRouteProps> = props => {
  return (
    <WireTypeConfigContextProvider value={wireTypeConfigNonSyndicatePayment}>
      <WireTypeRoute {...props} />
    </WireTypeConfigContextProvider>
  );
};
