import { useDataGridContext } from '@cmg/data-grid';
import { useCallback } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import { AlertCodes } from '../demand-grid/columns/columns.model';

type DialogContext = {
  onContinue: () => void;
};

export const useComplianceDialogState = () => {
  const { context, ...dialogState } = useDialogState<DialogContext>();
  const { setFilters } = useDataGridContext();

  const onShowInvestors = useCallback(() => {
    setFilters({
      alert: {
        filterType: 'set',
        values: [AlertCodes.AttestationNotOnFile, AlertCodes.AttestationExpired],
      },
    });
    dialogState.onClose();
  }, [dialogState, setFilters]);

  const onContinue = useCallback(() => {
    context?.onContinue();
    dialogState.onClose();
  }, [context, dialogState]);

  return { ...dialogState, onContinue, onShowInvestors };
};
