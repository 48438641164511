import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Grid, Stack } from '@cmg/design-system';
import { FormikSelectField, SelectOption } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { MdlAllocationRanking } from '../../../../graphql';
import { getRankingOptions } from '../../order-book/indication-activity/components/allocation/Allocation.model';

type Props = Readonly<{
  onChangeAllocationRanking: (ranking: MdlAllocationRanking) => void;
}>;
const AllocationRanking: React.FC<Props> = ({ onChangeAllocationRanking }) => {
  const canUpdate = useCheckPermissions([permissionsByEntity.Ioi.FULL]);
  const { isSubmitting, setFieldValue } = useFormikContext<{
    allocationRanking: MdlAllocationRanking | '';
  }>();
  const allocationRankingOptions = React.useMemo<SelectOption<UUID>[]>(
    () => getRankingOptions(),
    []
  );
  return (
    <Stack gap={1} width={220} pt={1}>
      <Grid item xs={4}>
        <FormikSelectField
          aria-label="allocationRanking"
          name="allocationRanking"
          label="Expected Allocation Ranking"
          options={allocationRankingOptions}
          showHelperTextInTooltip
          fullWidth
          required
          onChange={(value: string | null | undefined) => {
            const newValue = value || '';
            setFieldValue('allocationRanking', newValue);
            onChangeAllocationRanking(
              (newValue as MdlAllocationRanking) || MdlAllocationRanking.NotIndicated
            );
          }}
          disabled={!canUpdate || isSubmitting}
        />
      </Grid>
    </Stack>
  );
};
export default AllocationRanking;
