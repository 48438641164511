import { timeUtil } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { filterRecipientsWithMissingEmails } from '../../../../../../../common/context/ContactSupportModalContext';
import { useContactSupportModalContext } from '../../../../../../../common/context/hooks/useContactSupportModalContext';
import { syndicateWireManagerRoleLabels } from '../../../../../../../types/domain/syndicate-wires/constants';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { ManagersNavigationProps } from '../../../context/WireTypeConfigContext.model';
import { useManagersMissingEmail } from '../../../hooks/useManagersMissingEmail';
import { DisabledRecipientPopover } from '../../disabled-recipient-popover/DisabledRecipientPopover';
import { DisabledManagerReason } from '../../modals/send-wire-modal/SendWireModal.model';
import { ValidationWarningIcon } from '../../validation/validation-warning-icon/ValidationWarningIcon';
import { WireStatus } from '../../wire-status/WireStatus';
import NoManagers from '../no-managers/NoManagers';
import { ManagerRemovedIcon } from './icons/ManagerRemovedIcon';
import {
  getStatus,
  getWireMessageForManager,
  isManagerDisabled,
  managersToRoleGroupedEntries,
} from './ManagersNavigation.model';
import {
  SManager,
  SManagerIcon,
  SManagerList,
  SManagerName,
  SManagerNameContainer,
  SManagerStatus,
  SPanel,
  SRoleHeader,
} from './ManagersNavigation.styles';

export const ManagersNavigation: React.FC<ManagersNavigationProps> = ({
  managers,
  offeringId,
  syndicateWireId,
  wire,
  managerId,
  isNewestVersion,
}) => {
  const { push } = useHistory();
  const { wireTypeRoute, useOutdatedManagersList } = useWireTypeConfigContext();
  const managersMissingEmail = useManagersMissingEmail({ offeringId });
  const outdatedManagers = useOutdatedManagersList({ offeringId, syndicateWireId });
  const { open } = useContactSupportModalContext();

  const groupedManagersEntries = React.useMemo(() => {
    return managersToRoleGroupedEntries(managers);
  }, [managers]);

  const handleSelectManager = React.useCallback(
    (managerIdToSelect: string) => () => {
      if (!wire || isManagerDisabled(managerIdToSelect, wire)) {
        return;
      }

      push(
        wireTypeRoute.getUrlPath({
          offeringId,
          syndicateWireId: wire.id,
          managerId: managerIdToSelect,
        })
      );
    },
    [push, wireTypeRoute, offeringId, wire]
  );

  const handleOpenContactSupportModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    open(filterRecipientsWithMissingEmails(managers, managersMissingEmail));
  };

  return (
    <SPanel>
      {!managers.length && <NoManagers />}

      {groupedManagersEntries.map(([role, managersOfRole]) => (
        <div key={role}>
          <SRoleHeader>{syndicateWireManagerRoleLabels[role]}</SRoleHeader>
          <SManagerList>
            {managersOfRole.map(manager => {
              const isActive = manager.cmgEntityKey === managerId;
              const isRemoved = !!outdatedManagers?.some(m => m === manager.cmgEntityKey);
              const status = getStatus({
                cmgEntityKey: manager.cmgEntityKey,
                wire,
                isNewestWire: !!isNewestVersion,
                isRecipientRemoved: isRemoved,
              });
              const wireMessage = getWireMessageForManager(
                manager.cmgEntityKey,
                wire?.wireMessages
              );
              const isDisabled = isManagerDisabled(manager.cmgEntityKey, wire);
              const hasMissingEmail = managersMissingEmail.includes(manager.cmgEntityKey);

              return (
                <SManager
                  key={manager.cmgEntityKey}
                  isActive={isActive}
                  disabled={isDisabled}
                  onClick={handleSelectManager(manager.cmgEntityKey)}
                  data-test-id="manager-recipient"
                >
                  <SManagerIcon>
                    <WireStatus status={status} isActive={isActive} />
                  </SManagerIcon>
                  <SManagerNameContainer>
                    <SManagerName disabled={isDisabled} isActive={isActive}>
                      {manager.firmName}
                    </SManagerName>
                    {hasMissingEmail && (
                      <DisabledRecipientPopover
                        reason={DisabledManagerReason.MISSING_EMAIL}
                        onContactSupport={handleOpenContactSupportModal}
                      >
                        <div>
                          <ValidationWarningIcon />
                        </div>
                      </DisabledRecipientPopover>
                    )}
                    {isRemoved && <ManagerRemovedIcon />}
                  </SManagerNameContainer>
                  {!isDisabled && (
                    <SManagerStatus isActive={isActive}>
                      {status === 'draft' && (
                        <span>
                          Draft created on {timeUtil.formatAsDisplayDate(wire!.createdAt)}
                        </span>
                      )}
                      {status === 'obsolete' && <span>Obsolete</span>}
                      {status === 'sent' && wireMessage?.sentAt && (
                        <span>Sent on {timeUtil.formatAsDisplayDate(wireMessage.sentAt)}</span>
                      )}
                      {status === 'readyToSend' && <span>Ready to send</span>}
                      {status === 'recipientRemoved' && <span>Recipient removed</span>}
                      {status === 'expired' && wireMessage?.expiredAt && (
                        <span>
                          Expired on {timeUtil.formatAsDisplayDate(wireMessage.expiredAt)}
                        </span>
                      )}
                      {status === 'accepted' && wireMessage?.respondedAt && (
                        <span>
                          Accepted on {timeUtil.formatAsDisplayDate(wireMessage.respondedAt)}
                        </span>
                      )}
                      {status === 'declined' && wireMessage?.respondedAt && (
                        <span>
                          Declined on {timeUtil.formatAsDisplayDate(wireMessage.respondedAt)}
                        </span>
                      )}
                    </SManagerStatus>
                  )}
                </SManager>
              );
            })}
          </SManagerList>
        </div>
      ))}
    </SPanel>
  );
};
