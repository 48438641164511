import { Alert, LinkButton, UUID } from '@cmg/common';
import React from 'react';
import { useHistory } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';
import { stepIds } from '../../../../offering-setup/constants';

export type Props = {
  offeringId: UUID;
};

const StockSymbolWarningBanner: React.FC<Props> = ({ offeringId }) => {
  const { push } = useHistory();

  return (
    <Alert
      severity="info"
      withMargin
      action={
        <LinkButton
          onClick={() =>
            push(
              routeFactory.offeringSetup.getUrlPath({
                offeringId,
                stepId: stepIds.INSTRUMENTS,
              })
            )
          }
        >
          Configure Pricing instruments
        </LinkButton>
      }
    >
      Symbol is required in order to submit regulatory filings to FINRA. It can be configured in
      Offering Details, or a custom Issuer Symbol can be added in the form below.
    </Alert>
  );
};

export default StockSymbolWarningBanner;
