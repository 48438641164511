import { useCheckAccountTraits } from '@cmg/auth';
import { countriesList, currencyCodes, exchangeMicLabels } from '@cmg/common';
import React from 'react';

import { ExchangeMic } from '../../../graphql';
import { supportedCurrencyCodes } from '../../constants';

/**
 * Returns the list of countries, currencies and exchanges filtered by the country code
 * @param countryCode Selected country code
 * @example
 * const { countryCodes, exchanges, currencyCodes } = useIntlOptions('US');
 */
const useIntlOptions = (countryCode: string) => {
  const isInternationalFeatureOn = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const countryCodes = React.useMemo(
    () =>
      isInternationalFeatureOn
        ? Object.values(countriesList).map(country => ({
            label: country.countryCode,
            value: country.countryCode,
          }))
        : [{ label: 'USA', value: 'US' }],
    [isInternationalFeatureOn]
  );

  const supportedExchanges = React.useMemo(
    () =>
      Object.values(exchangeMicLabels).filter(exchange => {
        return Object.values(ExchangeMic).includes(exchange.name as unknown as ExchangeMic);
      }),
    []
  );

  const exchanges = React.useMemo(
    () =>
      supportedExchanges
        .filter(exchange => exchange.countryCode === countryCode)
        .map(exchange => ({
          label: exchange.shortName,
          value: exchange.name,
        })),
    [countryCode, supportedExchanges]
  );

  const currencyCodesOpts = isInternationalFeatureOn
    ? Object.values(currencyCodes)
        .map(currency => ({
          label: currency.alphabeticCode,
          value: currency.alphabeticCode,
        }))
        .filter(currency => supportedCurrencyCodes.includes(currency.value))
        // sorting, USD is always on top then alphabetical
        .sort((a, b) => {
          if (a.value === 'USD') {
            return -1;
          }
          if (b.value === 'USD') {
            return 1;
          }
          return a.value.localeCompare(b.value);
        })
    : [
        {
          label: 'USD',
          value: 'USD',
        },
      ];

  return {
    countryCodes,
    exchanges,
    currencyCodes: currencyCodesOpts,
  };
};

export default useIntlOptions;
