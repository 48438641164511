import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { CheckEntitlements_PublishedOfferingEntitlementsDocument } from '../../../../common/util/check-entitlements/graphql';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_HeaderDocument,
  OfferingSetup_PublishedHeaderDocument,
  OfferingSetup_Summary_OfferingSummaryDocument,
  OfferingSetup_Summary_PublishOfferingDocument,
  OfferingSetup_Summary_PublishOfferingMutation,
  OfferingSetup_Summary_PublishOfferingMutationVariables,
} from '../../graphql';

export const refetchQueries = () => {
  // After publishing the offering, we want to refetch the Offering Summary
  const refetchGetOfferingSummaryQuery = getOperationName(
    OfferingSetup_Summary_OfferingSummaryDocument
  );
  // After publishing the offering, we want to refetch the Offering Header
  const refetchOfferingHeader = getOperationName(Offering_OfferingHeaderDocument);
  const refetchOfferingSetupHeader = getOperationName(OfferingSetup_HeaderDocument);
  const refetchOfferingSetupPublishedHeader = getOperationName(
    OfferingSetup_PublishedHeaderDocument
  );
  // After publishing the offering, we want to refetch the entitlements
  const refetchOfferingEntitlements = getOperationName(
    CheckEntitlements_PublishedOfferingEntitlementsDocument
  );

  return [
    refetchGetOfferingSummaryQuery!,
    refetchOfferingHeader!,
    refetchOfferingSetupPublishedHeader!,
    refetchOfferingEntitlements!,
    refetchOfferingSetupHeader!,
  ];
};

/**
 * usePublishOfferingMutation publishes the offering
 */
export const usePublishOfferingMutation = () => {
  return useServiceMutation<
    OfferingSetup_Summary_PublishOfferingMutation,
    OfferingSetup_Summary_PublishOfferingMutationVariables
  >(OfferingSetup_Summary_PublishOfferingDocument, {
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
