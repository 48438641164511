import { UUID } from '@cmg/common';

import { useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery } from '../../graphql';
import { UndewritingFeesAndDiscountFormValues } from '../UnderwritingDiscountsAndFeesRoute.model';
import { useUpdateUnderwritingFeesAndDiscounts } from './useUpdateUnderwritingFeesAndDiscounts';

export const useUndewritingFeesAndDiscountsMutations = ({ offeringId }: { offeringId: UUID }) => {
  const { data: underwritingFeesDiscounts } =
    useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery({
      variables: { offeringId },
    });

  const [
    updateUnderwritingFeesAndDiscounts,
    {
      loading: loadingUpdateUnderwritingFeesAndDiscounts,
      error: errorUpdateUnderwritingFeesAndDiscounts,
    },
  ] = useUpdateUnderwritingFeesAndDiscounts();

  const handleSubmit = (values: UndewritingFeesAndDiscountFormValues) => {
    return updateUnderwritingFeesAndDiscounts({
      variables: {
        offeringId,
        input: {
          reallowance: values.reallowance,
          sharesExcludedFromGrossSpread: values.sharesExcludedFromGrossSpread,
          baseGrossSpread: {
            grossSpreadBase: values.grossSpreadBase,
            managementFeePercentage: values.baseGrossSpreadManagementFee,
            sellingConcessionPercentage: values.baseGrossSpreadSellingConcession,
            underwritingFeePercentage: values.baseGrossSpreadUnderwritingFee,
          },
          incentiveFee: {
            incentiveFee: values.incentiveFee,
            managementFeePercentage: values.incentiveFeeManagementFee,
            sellingConcessionPercentage: values.incentiveFeeSellingConcession,
            underwritingFeePercentage: values.incentiveFeeUnderwritingFee,
          },
        },
      },
    });
  };

  const loading = loadingUpdateUnderwritingFeesAndDiscounts;

  const errors = [errorUpdateUnderwritingFeesAndDiscounts];

  return {
    handleSubmit,
    loading,
    errors,
    underwritingFeesDiscounts,
  };
};
