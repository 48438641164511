import styled from 'styled-components/macro';

export const SContainer = styled.div`
  flex: 1;
  padding: 16px;
`;

export const SNavigationContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 320px;
  padding-right: 24px;
  padding-left: 8px;
`;

export const SPageContent = styled.div`
  flex-grow: 1;
`;

export const SFormContent = styled.div`
  padding: 24px 16px;
  height: 100%;
`;

export const SDescription = styled.div`
  white-space: pre-line;
  word-break: break-word;
`;
