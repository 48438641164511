import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';

import { useOfferingSidePanel_OfferingNotesQuery } from '../offering-notes/graphql';
import {
  useOrderAllocation_CommunicatedAllocationDemandQuery,
  useOrderAllocation_CommunicatedIndicationsDemandQuery,
} from '../order-allocation/ioi-tracker/graphql';
import { useFirmLevelDemand_InternalIndicationsDemandQuery } from '../order-book/my-internal-demand/components/firm-level-demand/graphql';
import { useFundLevelDemand_PublishedOfferingQuery } from '../order-book/my-internal-demand/components/fund-level-demand/graphql';

export const useGetNotesAndOrdersQuery = ({
  offeringId,
  skip,
}: {
  offeringId?: string;
  skip?: boolean;
}) => {
  const canRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const skipQueries = !canRead || !offeringId || skip;

  const { data: offeringNotesData } = useOfferingSidePanel_OfferingNotesQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: skipQueries,
  });

  const { data: dataIndication } = useFirmLevelDemand_InternalIndicationsDemandQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: skipQueries,
  });

  const { data: dataFundLevel } = useFundLevelDemand_PublishedOfferingQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: skipQueries,
  });

  const { data: communicatedDemandData } = useOrderAllocation_CommunicatedIndicationsDemandQuery({
    variables: {
      offeringId: offeringId!,
    },
    skip: skipQueries,
  });

  const { data: communicatedAllocationDemandData } =
    useOrderAllocation_CommunicatedAllocationDemandQuery({
      variables: {
        offeringId: offeringId!,
      },
      skip: skipQueries,
    });

  const internalIndications = dataIndication?.internalIndicationsDemand?.indications;
  const fundAllocations = dataFundLevel?.limitedPublishedOffering?.fundIois?.allocations;
  const fundIois = dataFundLevel?.limitedPublishedOffering?.fundIois?.iois;

  const hasCommunicatedDemand =
    !!communicatedDemandData?.communicatedIndicationsDemand?.indications?.length ||
    !!communicatedAllocationDemandData?.communicatedAllocationDemand?.allocation;
  const hasNotes = !!offeringNotesData?.offeringNotes.notes.length;

  const hasOrders = !!(internalIndications?.length || fundAllocations?.length || fundIois?.length);

  return { hasNotes, hasOrders, hasCommunicatedDemand };
};
