import { UUID } from '@cmg/common';
import { useRouteMatch } from 'react-router-dom';

import routeFactory from '../../../../../common/util/routeFactory';

type Props = Readonly<{
  sidePanelRoutePath: string;
}>;

export const useGetSidePanelPath = ({ sidePanelRoutePath }: Props) => {
  const {
    url,
    params: { offeringId },
  } = useRouteMatch<{ offeringId: UUID }>();

  return routeFactory.offeringSidePanel.getUrlPath({
    offeringId,
    prefixRoutePath: url,
    sidePanelRoutePath,
  });
};
