import { ApolloError } from '@apollo/client';
import { UUID } from '@cmg/common';
import React from 'react';

import { useFeatureToggles } from '../../../../../common/config';
import usePollingWhenVisible from '../../../../../common/hooks/usePollingWhenVisible';
import { useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess } from '../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { isValidCmgEntityKey } from '../../../../../common/util/isValidCmgEntityKey';
import { canBillingAndDeliveryAgentUpdateInstitutionalIndication } from '../../../../../common/util/offering/syndicate-entitlements';
import { useSyndicateCertificatesModel } from '../../compliance/context/hooks/certificates/syndicate/useCertificatesSyndicateModel';
import {
  Attestation,
  certificateModelDefaults,
  ComplianceWarning,
} from '../../compliance/context/hooks/certificates/useCertificatesModel';
import { OrderBookPersonaType } from '../../hooks/useIdentifyOrderBookPersona';
import { CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery } from '../components/crm-selection/graphql/__generated__';
import { useInvestorFirmModel } from './hooks/investorFirm/useInvestorFirmModel';
import {
  SyndicateIndicationQuery,
  useSyndicateIndicationQuery,
} from './hooks/syndicate/__generated__';

type AccessChecks = {
  canReadOfferingAndIndication: boolean;
  canUpdateIndication: boolean;
  canBillingAndDeliveryAgentUpdateAllocation: boolean;
};

export type IndicationActivitySyndicateContextType = {
  offeringId: UUID;
  loading: boolean;
  error?: ApolloError;
  basicPublishedOffering?: SyndicateIndicationQuery['basicPublishedOffering'];
  finalInstitutionalAllocationSet?: SyndicateIndicationQuery['finalInstitutionalAllocationSet'];
  complianceWarning: ComplianceWarning;
  attestation: Attestation | null;
  allInvestorFirmsMappedToCmgEntityKey?: CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery['allInvestorFirmsMappedToCmgEntityKey'];
  access: AccessChecks;
};

export const IndicationActivitySyndicateContext =
  React.createContext<IndicationActivitySyndicateContextType>({
    offeringId: '',
    loading: false,
    complianceWarning: certificateModelDefaults.warning,
    attestation: null,
    access: {
      canReadOfferingAndIndication: false,
      canBillingAndDeliveryAgentUpdateAllocation: false,
      canUpdateIndication: false,
    },
  });

export const useIndicationActivitySyndicateContext = () => {
  const context = React.useContext(IndicationActivitySyndicateContext);

  if (!context) {
    throw new Error('No provider found for IndicationActivitySyndicateContext');
  }

  return context;
};

export type IndicationActivitySyndicateContextProps = {
  offeringId: UUID;
  cmgEntityKey: UUID | undefined;
  indicationId: UUID;
};

export const IndicationActivitySyndicateContextProvider: React.FC<
  IndicationActivitySyndicateContextProps
> = ({ children, offeringId, cmgEntityKey, indicationId }) => {
  const { isOrderBookAttestationOn } = useFeatureToggles();

  const {
    canRead: canReadOfferingAndIndication,
    canUpdate: canUpdateIndication,
    loading: indicationAccessLoading,
  } = useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
    offeringId,
  });
  const {
    loading: isLoadingOffering,
    data,
    error,
    startPolling,
    stopPolling,
    called,
  } = useSyndicateIndicationQuery({
    variables: {
      offeringId,
      // cmgEntityKey context being actually firmKey means investor not paired to any cmg entity
      cmgEntityKey: isValidCmgEntityKey(cmgEntityKey!) ? cmgEntityKey : null,
      indicationId,
    },
    skip: !cmgEntityKey || !offeringId || !canReadOfferingAndIndication,
    fetchPolicy: 'cache-and-network',
  });

  usePollingWhenVisible({ pollInterval: 5_000, startPolling, stopPolling, called });

  const {
    loading: isLoadingCertificates,
    warning: complianceWarning,
    attestation,
  } = useSyndicateCertificatesModel({
    type: OrderBookPersonaType.SYNDICATE,
    offeringId,
    cmgEntityKey,
    isOrderBookAttestationOn,
  });
  const { loading: isLoadingInvestorFirm, allInvestorFirmsMappedToCmgEntityKey } =
    useInvestorFirmModel({
      type: OrderBookPersonaType.SYNDICATE,
      cmgEntityKey,
    });

  const context = React.useMemo<IndicationActivitySyndicateContextType>(
    () => ({
      offeringId,
      loading:
        isLoadingOffering ||
        isLoadingCertificates ||
        indicationAccessLoading ||
        isLoadingInvestorFirm,
      error,
      basicPublishedOffering: data?.basicPublishedOffering,
      finalInstitutionalAllocationSet: data?.finalInstitutionalAllocationSet,
      complianceWarning,
      attestation,
      allInvestorFirmsMappedToCmgEntityKey,
      access: {
        canReadOfferingAndIndication,
        canUpdateIndication,
        canBillingAndDeliveryAgentUpdateAllocation:
          canBillingAndDeliveryAgentUpdateInstitutionalIndication({
            billingAndDeliveryAgentCmgEntityKey:
              data?.basicPublishedOffering.orderBook.institutionalIndication
                ?.billingAndDeliveryAgentCmgEntityKey,
            syndicatesEntitlements:
              data?.basicPublishedOffering?.syndicateEntitlements.syndicateEntitlements ?? [],
          }),
      },
    }),
    [
      allInvestorFirmsMappedToCmgEntityKey,
      canReadOfferingAndIndication,
      canUpdateIndication,
      complianceWarning,
      attestation,
      data?.basicPublishedOffering,
      data?.finalInstitutionalAllocationSet,
      error,
      indicationAccessLoading,
      isLoadingCertificates,
      isLoadingInvestorFirm,
      isLoadingOffering,
      offeringId,
    ]
  );

  return (
    <IndicationActivitySyndicateContext.Provider value={context}>
      {children}
    </IndicationActivitySyndicateContext.Provider>
  );
};
