import styled, { css } from 'styled-components/macro';

import { EventStatus, RoadshowEventType } from '../../../../../../graphql';
import { Roadshows_RoadshowEventPartsFragment } from '../../../../common/graphql';

const Box = styled.div<{ event: Roadshows_RoadshowEventPartsFragment }>`
  ${({ theme, event }) => css`
    height: 100%;
    background-color: white;
    border: 2px solid #cfcfcf;
    border-left-width: 15px;
    border-radius: 3px;
    color: ${theme.text.color.darkGray};
    font-size: ${theme.text.size.small};
    padding: 4px 8px;

    ${event.roadshowEventType === RoadshowEventType.Logistics &&
    css`
      border-color: #13879a;
    `}

    ${event.roadshowEventType === RoadshowEventType.Meeting &&
    event.status === EventStatus.Draft &&
    css`
      border-color: #cfcfcf;
    `}

    ${event.roadshowEventType === RoadshowEventType.Meeting &&
    event.status === EventStatus.OpenToSales &&
    css`
      border-color: #ffc107;
    `}

    ${event.roadshowEventType === RoadshowEventType.Meeting &&
    event.status === EventStatus.Confirmed &&
    css`
      border-color: #4fc97a;
    `}

    ${event.roadshowEventType === RoadshowEventType.Meeting &&
    event.status === EventStatus.Cancelled &&
    css`
      border-color: #faa19b;
    `}

    ${event.roadshowEventType === RoadshowEventType.Meeting &&
    event.status === EventStatus.Cancelled &&
    css`
      text-decoration: line-through;
    `}
  `};
`;

const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.text.size.medium};
    font-weight: ${theme.text.weight.medium};
  `}
`;

export const Styles = {
  Box,
  Title,
};
