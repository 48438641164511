import { numericUtil } from '@cmg/common';
import type { ValueFormatterParams } from '@cmg/data-grid';

import type { DemandGridRowData, DemandGridServerColDef } from '../../types';

/**
 * Exported for testing purposes only
 */
export const valueFormatter = ({
  data,
  value,
}: ValueFormatterParams<DemandGridRowData, number>) => {
  if (data?.__typename === 'SyndicateGridTotalsRow') {
    return numericUtil.getDisplayValueForNumber(data.demandMax, 0);
  }

  return numericUtil.getDisplayValueForNumber(value, 0);
};

const demandMaxColDef: DemandGridServerColDef<number> = {
  field: 'demandMax',
  headerName: 'Max Demand',
  type: ['numericColumn', 'number'],
  filter: 'agNumberColumnFilter',
  valueFormatter,
};

export default demandMaxColDef;
