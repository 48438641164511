/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_AcceptInvitationWireMutationVariables = Types.Exact<{
  sentWireId: Types.Scalars['UUID'];
  token: Types.Scalars['String'];
  body: Types.InvitationWireResponseInput;
}>;

export type SyndicateWires_AcceptInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly acceptWire:
    | {
        readonly __typename?: 'SentWireStatus';
        readonly id: string;
        readonly sentAt: string;
        readonly expiredAt?: string | null;
        readonly respondedAt?: string | null;
        readonly responseStatus?: Types.SentWireResponseStatus | null;
        readonly legalAgreement?: string | null;
        readonly senderEmails: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeclineInvitationWireMutationVariables = Types.Exact<{
  sentWireId: Types.Scalars['UUID'];
  token: Types.Scalars['String'];
  body: Types.InvitationWireResponseInput;
}>;

export type SyndicateWires_DeclineInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly declineWire:
    | {
        readonly __typename?: 'SentWireStatus';
        readonly id: string;
        readonly sentAt: string;
        readonly expiredAt?: string | null;
        readonly respondedAt?: string | null;
        readonly responseStatus?: Types.SentWireResponseStatus | null;
        readonly legalAgreement?: string | null;
        readonly senderEmails: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_SentInvitationWireQueryVariables = Types.Exact<{
  sentWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SentInvitationWireQuery = {
  readonly __typename?: 'Query';
  readonly public: {
    readonly __typename?: 'Public';
    readonly syndicateWires: {
      readonly __typename?: 'PublicWires';
      readonly sentWire: {
        readonly __typename?: 'SentWireStatus';
        readonly id: string;
        readonly sentAt: string;
        readonly expiredAt?: string | null;
        readonly respondedAt?: string | null;
        readonly responseStatus?: Types.SentWireResponseStatus | null;
        readonly legalAgreement?: string | null;
        readonly senderEmails: ReadonlyArray<string>;
      };
    };
  };
};

export type SyndicateWires_SentWireStatusPartsFragment = {
  readonly __typename?: 'SentWireStatus';
  readonly id: string;
  readonly sentAt: string;
  readonly expiredAt?: string | null;
  readonly respondedAt?: string | null;
  readonly responseStatus?: Types.SentWireResponseStatus | null;
  readonly legalAgreement?: string | null;
  readonly senderEmails: ReadonlyArray<string>;
};

export const SyndicateWires_SentWireStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SentWireStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_AcceptInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_AcceptInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvitationWireResponseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'body' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SentWireStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_AcceptInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_AcceptInvitationWireMutation,
  SyndicateWires_AcceptInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_AcceptInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_AcceptInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_AcceptInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresAcceptInvitationWireMutation, { data, loading, error }] = useSyndicateWires_AcceptInvitationWireMutation({
 *   variables: {
 *      sentWireId: // value for 'sentWireId'
 *      token: // value for 'token'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSyndicateWires_AcceptInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_AcceptInvitationWireMutation,
    SyndicateWires_AcceptInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_AcceptInvitationWireMutation,
    SyndicateWires_AcceptInvitationWireMutationVariables
  >(SyndicateWires_AcceptInvitationWireDocument, options);
}
export type SyndicateWires_AcceptInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_AcceptInvitationWireMutation
>;
export type SyndicateWires_AcceptInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_AcceptInvitationWireMutation>;
export type SyndicateWires_AcceptInvitationWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_AcceptInvitationWireMutation,
  SyndicateWires_AcceptInvitationWireMutationVariables
>;
export const SyndicateWires_DeclineInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeclineInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvitationWireResponseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'declineWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sentWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sentWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'body' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SentWireStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeclineInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeclineInvitationWireMutation,
  SyndicateWires_DeclineInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_DeclineInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeclineInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeclineInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeclineInvitationWireMutation, { data, loading, error }] = useSyndicateWires_DeclineInvitationWireMutation({
 *   variables: {
 *      sentWireId: // value for 'sentWireId'
 *      token: // value for 'token'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSyndicateWires_DeclineInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeclineInvitationWireMutation,
    SyndicateWires_DeclineInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeclineInvitationWireMutation,
    SyndicateWires_DeclineInvitationWireMutationVariables
  >(SyndicateWires_DeclineInvitationWireDocument, options);
}
export type SyndicateWires_DeclineInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeclineInvitationWireMutation
>;
export type SyndicateWires_DeclineInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeclineInvitationWireMutation>;
export type SyndicateWires_DeclineInvitationWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_DeclineInvitationWireMutation,
  SyndicateWires_DeclineInvitationWireMutationVariables
>;
export const SyndicateWires_SentInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SentInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicateWires' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sentWire' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sentWireId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sentWireId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SentWireStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SentWireStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAgreement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SentInvitationWireQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SentInvitationWireQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SentInvitationWireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SentInvitationWireQuery({
 *   variables: {
 *      sentWireId: // value for 'sentWireId'
 *   },
 * });
 */
export function useSyndicateWires_SentInvitationWireQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SentInvitationWireQuery,
    SyndicateWires_SentInvitationWireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SentInvitationWireQuery,
    SyndicateWires_SentInvitationWireQueryVariables
  >(SyndicateWires_SentInvitationWireDocument, options);
}
export function useSyndicateWires_SentInvitationWireLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SentInvitationWireQuery,
    SyndicateWires_SentInvitationWireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SentInvitationWireQuery,
    SyndicateWires_SentInvitationWireQueryVariables
  >(SyndicateWires_SentInvitationWireDocument, options);
}
export type SyndicateWires_SentInvitationWireQueryHookResult = ReturnType<
  typeof useSyndicateWires_SentInvitationWireQuery
>;
export type SyndicateWires_SentInvitationWireLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SentInvitationWireLazyQuery
>;
export type SyndicateWires_SentInvitationWireQueryResult = Apollo.QueryResult<
  SyndicateWires_SentInvitationWireQuery,
  SyndicateWires_SentInvitationWireQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeSyndicateWires_SentInvitationWireMockResponse = makeMockResponse<
  SyndicateWires_SentInvitationWireQueryVariables,
  SyndicateWires_SentInvitationWireQuery
>(SyndicateWires_SentInvitationWireDocument);
