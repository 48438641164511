import { Tag, Theme } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { FilingStatus } from '../../../../../graphql';

export const statusMap: Record<FilingStatus, string> = {
  ERROR: 'FAILED',
  PROCESSING: 'IN PROGRESS',
  SUBMITTED: 'SUCCESSFUL',
  SUBMISSION_ERROR: 'SUBMISSION ERROR',
  MISSING_RESPONSE: 'MISSING RESPONSE',
};

const colorMap: (theme: Theme) => Record<FilingStatus, string> = (theme: Theme) => ({
  ERROR: theme.designSystem.colors['red']['300'],
  PROCESSING: theme.designSystem.colors['turquoise']['300'],
  SUBMITTED: theme.designSystem.colors['green']['300'],
  SUBMISSION_ERROR: theme.designSystem.colors['red']['300'],
  MISSING_RESPONSE: theme.designSystem.colors['yellow']['300'],
});

const FilingStatusTag: React.FC<{ status: FilingStatus }> = ({ status }) => {
  const theme = useTheme();

  return (
    <Tag variant="PILL" color={colorMap(theme)[status]}>
      {statusMap[status]}
    </Tag>
  );
};

export default FilingStatusTag;
