import { getOperationName } from '@apollo/client/utilities';

import { OrderBook_TotalRetailDemandDocument } from '../../../../graphql';
import { useOrderBook_ReleaseTotalRetailDemandMutation } from '../graphql';

export const refetchQueries = () => {
  const refetchOrderBook_TotalRetailDemandQuery = getOperationName(
    OrderBook_TotalRetailDemandDocument
  );

  return [refetchOrderBook_TotalRetailDemandQuery!];
};

export const useReleaseTotalRetailDemandMutation = () => {
  return useOrderBook_ReleaseTotalRetailDemandMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
