import { Divider, Grid, GridProps, Typography } from '@cmg/design-system';
import * as React from 'react';

export interface SectionProps extends GridProps {
  title?: string;
  children?: React.ReactNode;
  spacing?: number;
}

const headerStyles = { padding: '0 0 16px 8px' };

const Section: React.FC<SectionProps> = ({ title, children, spacing = 3, ...props }) => {
  return (
    <Grid container item spacing={spacing} {...props}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h3" sx={headerStyles}>
            {title}
          </Typography>
          <Divider />
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default Section;
