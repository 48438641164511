import { Box, CircularProgress } from '@cmg/design-system';
import React from 'react';

import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import OfferingSetupHeader from '../header/OfferingSetupHeader';
import { useOfferingSetupHeaderQuery } from './hooks/useOfferingSetupHeaderQuery';

export type Props = {
  offeringId?: string;
  negativeMargin?: boolean;
};

const OfferingSetupPage: React.FC<Props> = ({ offeringId, children, negativeMargin }) => {
  const skip = !offeringId;

  const { headerData, publishedHeaderData, loading, error } = useOfferingSetupHeaderQuery({
    offeringId,
    skip,
  });

  if (skip) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return (
      <Box bgcolor={theme => theme.palette.background.paper} width="100%">
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={theme => theme.spacing(12)}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && error && <ServerErrorsBanner error={error} />}
        {!loading && headerData && (
          <OfferingSetupHeader
            offeringId={offeringId}
            isDiscarded={headerData.offering.isDiscarded}
            issuerName={headerData.offering.issuer.name}
            stage={headerData.offering.stage}
            status={headerData.offering.status}
            version={headerData.offering.version}
            hasUnpublishedChanges={headerData.offering.hasUnpublishedChanges}
            publishedStatus={publishedHeaderData?.publishedOffering.status}
          >
            <Box mt={negativeMargin ? -4 : undefined}>{children}</Box>
          </OfferingSetupHeader>
        )}
      </Box>
    );
  }
};

export default OfferingSetupPage;
