import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { RegulationMNotificationPeriod, WireTemplateType } from '../../../../../graphql';
import { createUseDefaultSendIsDisabledReason } from '../../common/context/hooks/useDefaultSendIsDisabledReason';
import { createWireTypeConfig } from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { getDisclaimer } from '../../common/utils/disclaimer';
import {
  SyndicateWires_RegMWirePartsFragment,
  SyndicateWires_RegMWireValidationPartsFragment,
  useSyndicateWires_RegMWireDetailsQuery,
  useSyndicateWires_RegMWirePreviewQuery,
  useSyndicateWires_RegMWiresQuery,
  useSyndicateWires_RegMWireValidationQuery,
} from './graphql';
import { useCreateRegMWireMutation } from './hooks/useCreateRegMWireMutation';
import { useDeleteRegMWireMutation } from './hooks/useDeleteRegMWireMutation';
import { useSendRegMWireMutation } from './hooks/useSendRegMWireMutation';
import { useUpdateRegMWireMutation } from './hooks/useUpdateRegMWireMutation';
import { RegMWireForm } from './RegMWireForm';
import { validationSchema, Values } from './RegMWireForm.model';

const wireTypeName = 'Reg-M';

export const wireTypeConfigRegM = createWireTypeConfig<
  Values,
  SyndicateWires_RegMWirePartsFragment,
  SyndicateWires_RegMWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresRegM
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresRegM,
  wireTemplateType: WireTemplateType.RegulationM,
  CreateOrUpdateForm: RegMWireForm,
  formValidation: validationSchema,

  useSendIsDisabledReason: createUseDefaultSendIsDisabledReason({
    canBeSentForDraftOffering: true,
  }),
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_RegMWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.regMWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_RegMWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.regMWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_RegMWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.regMWireList,
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_RegMWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.regMWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateRegMWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: values,
          },
        });

        if (result.data?.createRegMWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createRegMWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateRegMWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: values },
        });

        if (result.data?.updateRegMWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateRegMWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteRegMWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId }) => {
        const result = await deleteWire({ variables: { offeringId, syndicateWireId } });

        if (result.data?.deleteRegMWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendRegMWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: {
            ...variables,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendRegMWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendRegMWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });
    const data = React.useMemo<Values>(() => {
      return {
        offeringResult: wire?.offeringResult ?? '',
        notificationPeriod: wire?.notificationPeriod ?? RegulationMNotificationPeriod.OneDay,
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [
      wire?.offeringResult,
      wire?.notificationPeriod,
      wire?.disclaimer,
      operationType,
      disclaimerData?.syndicateWires.wiresDisclaimer,
    ]);

    return { isLoading: disclaimerLoading, data, error };
  },
});
