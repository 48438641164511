import { SubNavigation } from '@cmg/common';
import React from 'react';

import routeFactory from '../../../../../common/util/routeFactory';
import {
  SButtonWrapper,
  StyledSubNavigation,
  StyledSubNavigationLink,
} from './RoadshowsSubNavigation.styles';

const RoadshowsSubNavigation: React.FC = ({ children }) => (
  <StyledSubNavigation>
    <SubNavigation.Group>
      <SubNavigation.Item>
        <StyledSubNavigationLink to={routeFactory.roadshowsList.getUrlPath()}>
          Roadshows
        </StyledSubNavigationLink>
      </SubNavigation.Item>
      <SubNavigation.Item>
        <StyledSubNavigationLink to={routeFactory.roadshowsDealTeams.getUrlPath()}>
          Deal Teams
        </StyledSubNavigationLink>
      </SubNavigation.Item>
    </SubNavigation.Group>

    <SButtonWrapper>{children}</SButtonWrapper>
  </StyledSubNavigation>
);

export default RoadshowsSubNavigation;
