import styled from 'styled-components/macro';

export const SGridWrapper = styled.div`
  // Adding a larger border to the pinned left header column
  .ag-theme-balham .ag-pinned-left-header {
    border-right: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Adding a larger border to the pinned right header column
  .ag-theme-balham .ag-pinned-right-header {
    border-left: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.dark}`};
  }

  // Removing borders in all grid cells and rows to correctly show fixed column borders
  .ag-theme-balham .ag-cell,
  .ag-row {
    border-bottom-width: 0;
    border-top-width: 0;
  }
`;
