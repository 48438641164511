import styled from 'styled-components/macro';

export const SHeader = styled.div`
  min-height: 50px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: ${({ theme }) => theme.background.color.white};
`;

export const STitle = styled.div`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.text.color.darkGray};
`;
