import {
  Badge,
  Box,
  IconButton,
  List,
  ListSubheader,
  NotificationsNoneOutlinedIcon,
  Popover,
} from '@cmg/design-system';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useState } from 'react';

import { EmptyState } from '../../../../design-system/components/data-display/empty-state/EmptyState';
import { useRecentUpdates } from '../hooks/useRecentUpdates';
import { RecentUpdateListItem } from './RecentUpdateListItem';

export type Props = Readonly<{
  offeringId: string;
  pricingCurrencyCode: string;
}>;

export const RecentUpdates: React.FC<Props> = ({ offeringId, pricingCurrencyCode }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { recentUpdates, markAllAsRead, hasUnread } = useRecentUpdates({ offeringId });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    markAllAsRead();
  }, [markAllAsRead]);

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;
  const sortedRecentUpdates = orderBy(recentUpdates, 'timestamp', 'desc');

  return (
    <Box>
      <IconButton
        aria-label="Recent Updates"
        aria-describedby={id}
        size="small"
        onClick={handleClick}
      >
        <Badge variant="dot" color="error" invisible={!hasUnread} aria-label="Unread updates badge">
          <NotificationsNoneOutlinedIcon color="primary" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        aria-label="Recent update popover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
      >
        <Box width="451px" maxHeight="610px">
          {!recentUpdates.length ? (
            <EmptyState
              title="No Updates Available"
              message="No new updates have been recorded during your browser session."
            />
          ) : (
            <List
              title="Recent Updates"
              dense
              subheader={<ListSubheader>Recent Updates</ListSubheader>}
            >
              {sortedRecentUpdates.map((update, index) => {
                const isLastItem = recentUpdates.length - index > 1;

                return (
                  <RecentUpdateListItem
                    key={index}
                    update={update}
                    pricingCurrencyCode={pricingCurrencyCode}
                    divider={isLastItem}
                  />
                );
              })}
            </List>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
