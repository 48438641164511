import React from 'react';

import Banner from '../../../../../../../../common/components/indicators/banner/Banner';
import { SText } from './ValidationErrorsBanner.styles';

export type Props = {
  errors: string[];
};

export const ServiceErrorBanner: React.FC<Props> = ({ errors }) => {
  return (
    <Banner variant="error">
      <SText>Allocation not saved for {errors.length} Allocation Set</SText>
      <div>
        There was an error saving allocations for the following allocation set(s):
        {errors.map((set, index) => {
          return (
            <span key={index}>
              {set}
              {index !== errors.length - 1 ? ', ' : ''}
            </span>
          );
        })}
      </div>
    </Banner>
  );
};
