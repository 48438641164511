import { isAfter, isBefore } from 'date-fns';
import React from 'react';

import { useFilterStorage } from '../../../../common/hooks/useFilterStorage';
import { multiFilterArray } from '../../../../common/util/multiFilterArray';
import { getOfferingTypeFilterCondition } from '../../../../common/util/offering/offering-type.util';
import { Sector } from '../../../../graphql';

export type Filters = {
  type: string[];
  status: string[];
  issuerSector: Sector[];
  issuerName: string;
  pricingDate: {
    start?: string;
    end?: string;
    type?: string;
  };
  hideObsolete: boolean;
};

export type UseFiltersParams<TOffering> = {
  sourceOfferings?: readonly TOffering[];
  withUrlFilters?: boolean;
};

export const defaultFilters: Filters = {
  type: [],
  status: [],
  issuerSector: [],
  issuerName: '',
  pricingDate: {},
  hideObsolete: true,
};

/**
 * useFilters hook used within each calendar tab and handles local filters state
 */
function useFilters<TOffering extends Record<string, unknown> = Record<string, unknown>>({
  sourceOfferings = [],
  withUrlFilters = false,
}: UseFiltersParams<TOffering>) {
  const { filters, setFilters } = useFilterStorage({
    withUrlFilterStorage: withUrlFilters,
    defaultFilters,
  });

  const filterConditions = React.useMemo(
    () => ({
      type: offering => {
        const { type, pricingInstrumentCountryCode } = offering;
        return getOfferingTypeFilterCondition({
          filters: filters.type,
          offeringType: type,
          pricingInstrumentCountryCode,
        });
      },
      status: offering => {
        return filters.status.length ? filters.status.includes(offering.status) : true;
      },
      issuerSector: offering => {
        return filters.issuerSector.length
          ? filters.issuerSector.includes(offering.issuerSector)
          : true;
      },
      issuerName: offering =>
        filters.issuerName
          ? offering.issuerName.toLowerCase().includes(filters.issuerName.toLowerCase())
          : true,
      pricingDate: offering =>
        filters.pricingDate.start && filters.pricingDate.end
          ? isAfter(new Date(offering.pricingDate), new Date(filters.pricingDate.start)) &&
            isBefore(new Date(offering.pricingDate), new Date(filters.pricingDate.end))
          : true,
      hideObsolete: offering => !(filters.hideObsolete && offering.isObsolete),
    }),
    [filters]
  );

  const filteredData = React.useMemo(
    () => (sourceOfferings.length ? multiFilterArray(sourceOfferings, filterConditions) : []),
    [sourceOfferings, filterConditions]
  );

  return { filters, setFilters, filterConditions, filteredData };
}

export default useFilters;
