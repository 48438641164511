import { LinkButton, Panel } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 20px;
`;

export const StyledPanel = styled(Panel)<{ gap?: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap ?? 0}px`};
`;

export const SLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 200px;
`;
