import { UUID } from '@cmg/common';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { phoneNumberSchema } from '../../../../../../common/util/validation';
import { DealTeamMemberType, UpdateDealTeamMemberInput } from '../../../../../../graphql';
import {
  Roadshows_DealTeamPartsFragment,
  Roadshows_RoadshowsDealTeamPartsFragment,
} from '../../../../common/graphql';
import { Roadshows_UpdateDealTeamMutationVariables } from '../../../../single-deal-team/deal-team-details/graphql';
import { Roadshows_AssignDealTeamsMutationVariables } from '../graphql';

export type DealTeamMember = {
  name: string;
  mobilePhone?: string | null | undefined;
  officePhone?: string | null | undefined;
  email: string;
  department?: string | null | undefined;
};

export const defaultDealTeamMember: DealTeamMember = {
  name: '',
  mobilePhone: null,
  officePhone: null,
  email: '',
  department: null,
};

export type Values = {
  organisationId: UUID | null;
  members: DealTeamMember[];
};

export const initialValues: Values = {
  organisationId: null,
  members: [defaultDealTeamMember],
};

export const validateSchema = (dealTeam?: Roadshows_DealTeamPartsFragment) => {
  return yup.object({
    organisationId: yup.string().label('Organisation').nullable().required(),
    members: yup.array().of(memberSchema(dealTeam)).label('Members').min(1),
  });
};

export const memberSchema = (dealTeam?: Roadshows_DealTeamPartsFragment) => {
  return yup
    .object({
      name: yup.string().label('Name').nullable().required(),
      mobilePhone: phoneNumberSchema.label('Mobile Phone').nullable(),
      officePhone: phoneNumberSchema.label('Office Phone').nullable(),
      email: yup.string().email().label('Email').nullable().required(),
      department: yup.string().nullable(),
    })
    .test('email-unique', 'Email must be unique', ({ email: currentEmail }, context) => {
      const members = context.parent as DealTeamMember[];
      const formikDuplicates = members.filter(({ email }) => email === currentEmail).length;

      const dealTeamDuplicates =
        dealTeam?.members.filter(({ email }) => email === currentEmail).length ?? 0;

      if (formikDuplicates + dealTeamDuplicates > 1) {
        const errors: ValidationError[] = [];

        members.forEach(({ email }, index) => {
          if (email === currentEmail) {
            errors.push(context.createError({ path: `members.${index}.email` }));
          }
        });

        return new ValidationError(errors);
      }
      return true;
    });
};

export const getCreateVariables = (
  dealTeam: Roadshows_DealTeamPartsFragment,
  values: Values
): Roadshows_UpdateDealTeamMutationVariables => {
  const addedMembers = values.members.map(member => ({
    ...member,
    type: DealTeamMemberType.Manual,
  }));
  const originalMembers = dealTeam.members.map<UpdateDealTeamMemberInput>(member => ({
    id: member.id,
    type: member.type,
    email: member.email,
    department: member.department,
    mobilePhone: member.mobilePhone,
    officePhone: member.officePhone,
    name: member.name,
  }));

  return {
    cmgEntityKey: values.organisationId!,
    payload: { departments: dealTeam.departments, members: [...addedMembers, ...originalMembers] },
  };
};

export const getAssignVariables = ({
  roadshowId,
  dealTeam,
  createResponse,
  participatingDealTeams,
}: {
  roadshowId: UUID;
  dealTeam: Roadshows_DealTeamPartsFragment;
  createResponse: Roadshows_DealTeamPartsFragment;
  participatingDealTeams: readonly Roadshows_RoadshowsDealTeamPartsFragment[];
}): Roadshows_AssignDealTeamsMutationVariables => {
  // createResponse returns all deal team members, but we want to assign only those we added
  // e.g. not assign members that we didn't create
  const addedMembers = createResponse.members.reduce<string[]>((returnValue, current) => {
    if (!dealTeam.members.some(({ id }) => id === current.id)) {
      return [...returnValue, current.id];
    }

    return returnValue;
  }, []);

  // we have to pass previously assigned members or they would be unassigned
  const memberIds = participatingDealTeams.reduce((returnValue, current) => {
    const memberIds = current.members.map(({ id }) => id);
    return [...returnValue, ...memberIds];
  }, addedMembers);

  return { roadshowId, payload: memberIds };
};
