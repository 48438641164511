import { Ribbon, Theme } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

export type WireStageType =
  | 'template'
  | 'draft'
  | 'readyToSend'
  | 'obsolete'
  | 'sent'
  | 'expired'
  | 'accepted'
  | 'declined';

export type Props = {
  stage: WireStageType;
};

const getBackgroundColorForStage: (theme: Theme, stage: WireStageType) => string = (
  theme,
  stage
) => {
  const stageToColorMap: { [stage in WireStageType]: string } = {
    template: theme.brand.color.lightAlternate,
    draft: theme.designSystem.colors.gray['200'],
    readyToSend: theme.designSystem.colors.yellow['300'],
    obsolete: theme.designSystem.colors.gray['200'],
    sent: theme.designSystem.colors.turquoise['300'],
    accepted: theme.designSystem.colors.green['300'],
    expired: theme.designSystem.colors.red['300'],
    declined: theme.designSystem.colors.red['300'],
  };

  return stageToColorMap[stage];
};

const getTextColorForStage: (theme: Theme, stage: WireStageType) => string = (theme, stage) => {
  const stageToColorMap: { [stage in WireStageType]: string } = {
    template: theme.text.color.white,
    draft: theme.text.color.dark,
    readyToSend: theme.text.color.dark,
    obsolete: theme.text.color.dark,
    sent: theme.text.color.white,
    accepted: theme.text.color.white,
    expired: theme.text.color.white,
    declined: theme.text.color.white,
  };

  return stageToColorMap[stage];
};

const stageToTextMap: { [stage in WireStageType]: string } = {
  template: 'TEMPLATE',
  draft: 'DRAFT',
  readyToSend: 'READY TO SEND',
  obsolete: 'OBSOLETE',
  sent: 'SENT',
  accepted: 'ACCEPTED',
  expired: 'EXPIRED',
  declined: 'DECLINED',
};

export const WireRibbon: React.FC<Props> = ({ stage }) => {
  const theme = useTheme();

  return (
    <Ribbon
      size={80}
      bgColor={getBackgroundColorForStage(theme, stage)}
      textColor={getTextColorForStage(theme, stage)}
      testId="wire-ribbon"
    >
      {stageToTextMap[stage]}
    </Ribbon>
  );
};
