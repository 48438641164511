import styled from 'styled-components/macro';

export const SDuplicateWrapper = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
`;

export const SDuplicateHeader = styled.div`
  color: ${({ theme }) => theme.text.color.light};
  margin-bottom: 5px;
`;

export const SSeparatorLine = styled.hr`
  width: 85%;
  border-top: ${({ theme }) => `${theme.border.width.small} solid ${theme.color.gray1}`};
  padding: 0px;
  margin: 0px;
  margin-left: 5%;
`;

export const SDuplicateIndicationsWrapper = styled.div`
  padding: 20px 0px 20px 0px;
`;

export const SDuplicateIndicationsRowsContainer = styled.div`
  margin: 10px 0px 10px 0px;
`;

export const SDuplicateColumn = styled.div`
  width: 33%;
  padding-left: 5%;
`;
