import { getOperationName } from '@apollo/client/utilities';

import {
  Roadshows_RoadshowDealTeamsDocument,
  useRoadshows_AssignDealTeamsMutation,
} from '../graphql';

export const refetchQueries = () => {
  const refetchRoadshowDealTeams = getOperationName(Roadshows_RoadshowDealTeamsDocument);

  return [refetchRoadshowDealTeams!];
};

export const useAssignDealTeamMembersMutation = () => {
  return useRoadshows_AssignDealTeamsMutation({ refetchQueries, awaitRefetchQueries: true });
};
