import { UUID } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../../../graphql';
import { DemandValues, RetentionValues } from '../table-row/TotalRetailDemandTableRow.model';
import {
  calculateTotals,
  getInitialValues,
  TotalRetailDemandValues,
} from './useAggregateFormsValues.model';

export const useAggregateFormsValues = (
  initialValues: readonly OrderBook_TotalRetailSummaryItemPartsFragment[]
) => {
  const [values, setValues] = React.useState<TotalRetailDemandValues>(
    getInitialValues(initialValues)
  );

  const totals = React.useMemo(() => calculateTotals(values), [values]);

  const setValue = React.useCallback(
    (managerCmgEntityKey: UUID, demand: DemandValues, retention: RetentionValues) => {
      const newValue = { ...values[managerCmgEntityKey], demand, retention };
      if (isEqual(values[managerCmgEntityKey], newValue)) {
        return;
      }

      setValues({ ...values, [managerCmgEntityKey]: newValue });
    },
    [values]
  );

  return { values, setValue, totals };
};
