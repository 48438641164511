import { useStoredPreference } from '@cmg/auth';
import { Column } from 'ag-grid-community';

import { defaultInstitutionalGridPinnedColumns } from '../InstitutionalDemandGrid.model';
import {
  CMGColumnID,
  InstitutionalDemandColumnConfig,
  InstitutionalDemandFilterArgs,
} from '../types';
import { useInstitutionalDemandColumns } from './useInstitutionalDemandColumns';

export type PinnedColumnsType = {
  right: string[];
  left: string[];
};
export type RowSortType = {
  colId: string | undefined;
  sort: string | null | undefined;
  sortIndex: number | null | undefined;
}[];
export type ColumnWidthsType =
  | {
      key: string | Column;
      newWidth: number;
    }[]
  | undefined;

export const useInstitutionalDemandPreferences = (offeringId: string) => {
  const { visibleColumns, setVisibleColumns } = useInstitutionalDemandColumns(offeringId);

  const [columnsOrder, setColumnsOrder] = useStoredPreference<string[]>({
    key: `columnsOrder_${offeringId}`,
    initialValue: [],
  });

  const [rowSortOrder, setRowSortOrder] = useStoredPreference<RowSortType>({
    key: `rowSortOrder_${offeringId}`,
    initialValue: [
      {
        colId: CMGColumnID.DEMAND_MAX,
        sort: 'desc',
        sortIndex: 0,
      },
      {
        colId: CMGColumnID.INVESTOR,
        sort: 'asc',
        sortIndex: 1,
      },
    ],
  });

  const [filters, setFilters] = useStoredPreference<InstitutionalDemandFilterArgs>({
    key: `filters_${offeringId}`,
    initialValue: {
      searchText: null,
      status: null,
      type: null,
    },
  });

  const [columnWidths, setColumnWidths] = useStoredPreference<ColumnWidthsType>({
    key: `columnWidths_${offeringId}`,
    initialValue: undefined,
  });

  const [pinnedColumns, setPinnedColumns] = useStoredPreference<PinnedColumnsType>({
    key: `pinnedColumns_${offeringId}`,
    initialValue: defaultInstitutionalGridPinnedColumns,
  });

  const [preferredInstitutionalDemandColumnConfig, setPreferredInstitutionalDemandColumnConfig] =
    useStoredPreference<InstitutionalDemandColumnConfig | null>({
      key: `demandColumnConfig_${offeringId}`,
      initialValue: null,
    });

  const [metricsVisible, setMetricsVisible] = useStoredPreference<boolean>({
    key: `metricsVisible_${offeringId}`,
    initialValue: true,
  });

  return {
    preferredInstitutionalDemandColumnConfig,
    setPreferredInstitutionalDemandColumnConfig,
    columnsOrder,
    setColumnsOrder,
    rowSortOrder,
    setRowSortOrder,
    filters,
    setFilters,
    columnWidths,
    setColumnWidths,
    visibleColumns,
    setVisibleColumns,
    pinnedColumns,
    setPinnedColumns,
    metricsVisible,
    setMetricsVisible,
  };
};
