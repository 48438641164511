import { ColDef } from '@cmg/common';

import { suppressKeyboardEvent } from '../../../../../../../../common/util/verticalTabbing';
import {
  getDraftAllocationId,
  IndicationWithDemandLevels,
  InstitutionalDraftSet,
  TotalsPinnedRow,
} from '../../../types';
import DraftAllocationFormCellContainer from '../../allocation-form-cell/DraftAllocationFormCellContainer';
import AllocationSetTotalsCell from '../../allocation-total-cell/AllocationSetTotalsCell';
import { defaultColumnProps } from '../Columns.model';

type DraftAllocationSetField = IndicationWithDemandLevels['draftAllocationsBySetId'][''];

const comparator = (valueA: DraftAllocationSetField, valueB: DraftAllocationSetField) => {
  const shareQuantityA = valueA?.shareQuantity ?? -1;
  const shareQuantityB = valueB?.shareQuantity ?? -1;
  if (shareQuantityA === shareQuantityB) {
    return 0;
  } else {
    return shareQuantityA > shareQuantityB ? 1 : -1;
  }
};

/**
 * Given an Allocation Set Data and a component for rendering the
 * Allocation Set column cell, returns a ColDef.
 */
export const generateDraftAllocationSetColumn = (
  draftAllocationSet: InstitutionalDraftSet,
  isShared: boolean,
  isOrderBookPinnedColumnsOn: boolean
): ColDef<IndicationWithDemandLevels, DraftAllocationSetField> => {
  const withHeaderNameSuffix = draftAllocationSet.isDefault || isShared;

  return {
    ...defaultColumnProps,
    colId: getDraftAllocationId(draftAllocationSet.id!),
    field: `draftAllocationsBySetId.${draftAllocationSet.id}`,
    headerName: `${draftAllocationSet.name!}${withHeaderNameSuffix ? ' - Allos' : ''}`,
    cellRendererFramework: (params: {
      data: IndicationWithDemandLevels;
      value: DraftAllocationSetField;
      colDef: ColDef;
    }) => {
      return (
        <DraftAllocationFormCellContainer data={params.data} allocationSet={draftAllocationSet} />
      );
    },
    pinnedRowCellRendererFramework: ({ data }: { data: TotalsPinnedRow }) => {
      const { totalAllocation, unallocatedShares } =
        data.filteredDraftAllocationSetTotals.find(
          totalObj => totalObj.allocationSetId === draftAllocationSet.id
        ) ?? {};

      return (
        <AllocationSetTotalsCell
          shareQuantity={totalAllocation}
          unallocatedShares={unallocatedShares}
        />
      );
    },
    minWidth: 150,
    pinned: isOrderBookPinnedColumnsOn ? undefined : 'right',
    cellStyle: () => {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0px !important',
      };
    },
    suppressKeyboardEvent,
    comparator,
    // @ts-ignore valueGetter library type expects a different return type than we need(number)
    valueGetter: ({ data }: { data: IndicationWithDemandLevels }) => {
      if (data.draftAllocationsBySetId && data.draftAllocationsBySetId[draftAllocationSet.id!]) {
        return data?.draftAllocationsBySetId[draftAllocationSet.id!] ?? '';
      }
      return '';
    },
    lockPinned: !isOrderBookPinnedColumnsOn,
  };
};
