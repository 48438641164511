import { ApolloError } from '@apollo/client';
import { errorUtil } from '@cmg/common';
import React from 'react';

import { DISMISSED_BANNER_ERRORS_KEY, transformApolloErrors } from './useDismissedErrors.model';

type Props = Readonly<{
  errors: { [key: string]: ApolloError[] };
  prefixMessageMap?: { [key: string]: string };
}>;
export const useDismissedErrors = ({ errors, prefixMessageMap }: Props) => {
  const [storedDismissedErrors, setStoredDismissedErrors] = React.useState<string[]>([]);

  const formattedAndFilteredErrors = React.useMemo(() => {
    return transformApolloErrors(errors, prefixMessageMap).filter(
      error => !storedDismissedErrors.includes(error.message)
    );
  }, [errors, storedDismissedErrors, prefixMessageMap]);

  const onDismissErrors = React.useCallback(() => {
    const errorMessages = formattedAndFilteredErrors.map(error => error.message);
    const dismissedErrors = [...storedDismissedErrors, ...errorMessages];
    sessionStorage.setItem(DISMISSED_BANNER_ERRORS_KEY, JSON.stringify(dismissedErrors));
    setStoredDismissedErrors(dismissedErrors);
  }, [setStoredDismissedErrors, formattedAndFilteredErrors, storedDismissedErrors]);

  React.useEffect(() => {
    try {
      const storedErrors = sessionStorage.getItem(DISMISSED_BANNER_ERRORS_KEY);
      if (storedErrors) {
        const dismissedErrors: string[] = JSON.parse(storedErrors);
        setStoredDismissedErrors(dismissedErrors);
      }
    } catch (error) {
      errorUtil.assertIsError(error);
      const { message } = error;
      if (
        message.includes('exceeded') ||
        message.includes('maximum') ||
        message.includes('QUOTA_EXCEEDED_ERR')
      ) {
        window.localStorage.clear();
      }
    }
  }, [setStoredDismissedErrors]);

  return { errors: formattedAndFilteredErrors, onDismissErrors };
};
