import React from 'react';

import { useIndicationActivitySyndicateContext } from '../../../../context/IndicationActivitySyndicateContext';
import DuplicateIndicationBannerComponent from '../../common/duplicate-indication-banner/DuplicateIndicationBanner';
import { useDupeIndicationBanner_IndicationEntityKeyQuery } from './graphql/__generated__';

const DuplicateIndicationBanner: React.FC = () => {
  const { offeringId, basicPublishedOffering } = useIndicationActivitySyndicateContext();
  const indication = basicPublishedOffering?.orderBook.institutionalIndication!;

  const { data, loading } = useDupeIndicationBanner_IndicationEntityKeyQuery({
    variables: { indicationId: indication.duplicateOfIndicationId, offeringId },
  });

  const investorInformation = data?.institutionalIndication?.investorInformation;

  return (
    <DuplicateIndicationBannerComponent
      duplicateCmgEntityKey={investorInformation?.cmgEntityKey}
      cmgEntityName={investorInformation?.cmgEntityName}
      bankInvestorName={investorInformation?.bankInvestorName}
      isLoading={loading}
    />
  );
};

export default DuplicateIndicationBanner;
