import React from 'react';

import { sortCaseInsensitive } from '../../../../common/util/sortCaseInsensitive';
import {
  TradeReleaseGridStatusTransition,
  TradeReleaseGridWarning,
  TradeReleaseStatus,
} from '../../../../graphql';
import { Investor } from '../../history/TradeReleaseHistory';
import { TradeRelease_TradeGridItemFragment } from '../graphql/__generated__';
import { getActionsAvailability } from '../grid/TradeReleaseGrid.model';
import { TradeGridFilters } from './useTradeGrid.model';
import { useTradeGridFilters } from './useTradeGridFilters';

export enum TradeRowSortGroup {
  WithWarning,
  WithAvailableActions,
  WithHiddenActionAndZeroAllocation,
  WithHiddenAction,
}

export enum TradeRowActionsAvailability {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  HIDDEN = 'HIDDEN',
}

const tradeGridSortGroupOrder = [
  TradeRowSortGroup.WithWarning,
  TradeRowSortGroup.WithAvailableActions,
  TradeRowSortGroup.WithHiddenActionAndZeroAllocation,
  TradeRowSortGroup.WithHiddenAction,
];

export const filterTradeRows = (
  rows: readonly TradeRelease_TradeGridItemFragment[],
  {
    releaseStatus,
    containsErrors,
    investorName,
  }: Pick<TradeGridFilters, 'containsErrors' | 'releaseStatus' | 'investorName'>
) => {
  return rows.filter(trade => {
    const isHiddenRow =
      trade.warnings.includes(TradeReleaseGridWarning.UnassignedBndAgent) &&
      trade.tradeStatus === TradeReleaseStatus.Unreleased;
    const releaseStatusPredicate = releaseStatus ? trade.tradeStatus === releaseStatus : true;
    const containsErrorsPredicate = containsErrors ? trade.warnings.length > 0 : true;
    const investorNamePredicate =
      investorName.length === 0 ||
      trade.investorFirmName.toLowerCase().includes(investorName.toLowerCase());

    return (
      releaseStatusPredicate && containsErrorsPredicate && investorNamePredicate && !isHiddenRow
    );
  });
};

export const getTradeRowSortGroup = (row: TradeRelease_TradeGridItemFragment) => {
  if (row.warnings.length > 0) {
    return TradeRowSortGroup.WithWarning;
  }

  const actionsAvailability = getActionsAvailability(row);
  if (
    actionsAvailability === TradeRowActionsAvailability.ENABLED ||
    actionsAvailability === TradeRowActionsAvailability.DISABLED
  ) {
    return TradeRowSortGroup.WithAvailableActions;
  }

  if (row.allocationShares === 0) {
    return TradeRowSortGroup.WithHiddenActionAndZeroAllocation;
  }

  return TradeRowSortGroup.WithHiddenAction;
};

export const defaultTradeRowsSort = (
  rowA: TradeRelease_TradeGridItemFragment,
  rowB: TradeRelease_TradeGridItemFragment
) => {
  const rowAGroup = getTradeRowSortGroup(rowA);
  const rowBGroup = getTradeRowSortGroup(rowB);

  const groupDiff =
    tradeGridSortGroupOrder.indexOf(rowAGroup) - tradeGridSortGroupOrder.indexOf(rowBGroup);

  if (groupDiff !== 0) {
    return groupDiff < 0 ? -1 : 1;
  }

  return sortCaseInsensitive(rowA.investorFirmName, rowB.investorFirmName);
};

export const useTradeGrid = (items: readonly TradeRelease_TradeGridItemFragment[]) => {
  const [selectedRows, setSelectedRows] = React.useState<TradeRelease_TradeGridItemFragment[]>([]);
  const [numOfUnchangedTrades, setNumOfUnchangedTrades] = React.useState<number>(0);
  const filters = useTradeGridFilters();
  const rowsWithoutBnDAgent = React.useMemo(
    () =>
      items.filter(({ warnings }) => warnings.includes(TradeReleaseGridWarning.UnassignedBndAgent)),
    [items]
  );
  const filteredRows = React.useMemo(
    () => filterTradeRows(items, filters).sort(defaultTradeRowsSort),
    [items, filters]
  );
  const rowsNeedingAction = React.useMemo(
    () =>
      items.filter(
        ({ warnings, allowedStatusTransitions }) =>
          warnings.length > 0 ||
          allowedStatusTransitions.includes(TradeReleaseGridStatusTransition.Released)
      ),
    [items]
  );
  const investors = React.useMemo<Investor[]>(
    () =>
      items.map(({ indicationId, investorFirmName }) => ({
        indicationId,
        firmName: investorFirmName,
      })),
    [items]
  );

  return {
    selectedRows,
    setSelectedRows,
    allRows: items,
    filteredRows,
    investors,
    filters,
    numOfRowsWithoutBnDAgent: rowsWithoutBnDAgent.length,
    numOfRowsNeedingAction: rowsNeedingAction.length,
    numOfUnchangedTrades,
    setNumOfUnchangedTrades,
  };
};
