import { useAccessToken, useAuth } from '@cmg/auth';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import { useFeatureToggles, useIdentityServiceHost } from '../../config';
import { useUpdateEffect } from '../../hooks';
import {
  useBrowserNotificationPermission,
  useInstrumentation,
  usePublicKey,
  usePushNotificationEnabledStatus,
  usePushNotificationTouchedStatus,
  useServiceWorker,
} from '../hooks';
import { PushNotificationStatus } from '../types';
import { notificationApi, pushApi, resolveNotificationStatus, webApi } from '../util';

const { requestPermission } = notificationApi;

export type PushNotificationContextProps = {
  isLoading: boolean;
  pushNotificationStatus: PushNotificationStatus;
  setPushNotificationEnabledStatus: (value: boolean | undefined) => void;
  requestPermission: () => void;
  pushNotificationTouchedStatus: boolean | undefined;
  setPushNotificationTouchedStatus: (value: boolean | undefined) => void;
  sendTestNotification: () => Promise<void>;
  subscription?: PushSubscription | null;
};

export const PushNotificationContext = createContext<PushNotificationContextProps>({
  isLoading: false,
  pushNotificationStatus: 'default',
  setPushNotificationEnabledStatus: () => null,
  requestPermission: () => null,
  pushNotificationTouchedStatus: false,
  setPushNotificationTouchedStatus: () => null,
  sendTestNotification: () => Promise.resolve(),
});

const PushNotificationProviderContent: React.FC = ({ children }) => {
  const publicKey = usePublicKey();
  const registration = useServiceWorker();
  const [subscription, setSubscription] = useState<PushSubscription | undefined | null>();
  const browserNotificationPermission = useBrowserNotificationPermission();
  const [pushNotificationEnabledStatus, setPushNotificationEnabledStatus] =
    usePushNotificationEnabledStatus();
  const [pushNotificationTouchedStatus, setPushNotificationTouchedStatus] =
    usePushNotificationTouchedStatus();
  const [pushNotificationStatus, setPushNotificationStatus] = useState<PushNotificationStatus>(
    resolveNotificationStatus({ browserNotificationPermission, pushNotificationEnabledStatus })
  );
  const isLoading = publicKey === undefined;
  const accessToken = useAccessToken();
  const host = useIdentityServiceHost();
  const { record } = useInstrumentation();

  useEffect(() => {
    // Recalculate pushNotificationStatus when browserNotificationPermission or pushNotificationEnabledStatus change
    setPushNotificationStatus(
      resolveNotificationStatus({ browserNotificationPermission, pushNotificationEnabledStatus })
    );
  }, [browserNotificationPermission, pushNotificationEnabledStatus]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (publicKey && registration) {
      (async () => {
        if (subscription && pushNotificationStatus !== 'enabled') {
          // A subscription was found on the client, but the notification status is not enabled.
          // Let's delete the subscription from the server and clear the state.
          setSubscription(
            await webApi.deleteSubscription({
              subscription: await pushApi.unsubscribe({ subscription }),
              host,
              accessToken,
            })
          );
        } else if (!subscription && pushNotificationStatus === 'enabled') {
          // We don't have the subscription on the client yet, but we're clear to try getting it.

          const clientSubscription = await pushApi.getSubscription({ registration });

          if (clientSubscription) {
            // Already Subscribed - A client subscription was found. Next we need to check if the server similarly has a subscription for this client.
            const { endpoint } = clientSubscription;
            const serverSubscription = await webApi.getSubscription({
              endpoint,
              host,
              accessToken,
            });
            if (!serverSubscription) {
              // Resubscribe - No subscription was found on the server for this user, despite there being one on the client. We need to resubscribe.
              await pushApi.unsubscribe({ subscription: clientSubscription });
              setSubscription(
                await webApi.postSubscription({
                  subscription: await pushApi.subscribe({ registration, publicKey }),
                  host,
                  accessToken,
                })
              );
            } else {
              // Else we're good here. The client and server are in sync.
              setSubscription(clientSubscription);
            }
          } else {
            // Initial Subscribe - No subscription was found on the client. We need to subscribe for the first time.

            setSubscription(
              await webApi.postSubscription({
                subscription: await pushApi.subscribe({ registration, publicKey }),
                host,
                accessToken,
              })
            );
          }
        }
      })();
    }
  }, [accessToken, pushNotificationStatus, publicKey, registration, subscription, host]);

  useUpdateEffect(() => {
    record('Status changed', { pushNotification_status: pushNotificationStatus });
  }, [pushNotificationStatus]);

  const sendTestNotification = useCallback(async () => {
    await webApi.send({ host, accessToken });
  }, [accessToken, host]);

  return (
    <PushNotificationContext.Provider
      value={{
        isLoading,
        pushNotificationStatus,
        setPushNotificationEnabledStatus,
        requestPermission,
        pushNotificationTouchedStatus,
        setPushNotificationTouchedStatus,
        sendTestNotification,
        subscription,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export const PushNotificationProvider: React.FC = ({ children }) => {
  const { isPushNotificationsOn } = useFeatureToggles();
  const { isLoggedIn } = useAuth();

  return isPushNotificationsOn &&
    isLoggedIn && // It is necessary to defer render until fully logged in so the useStoredPreference hooks read against the right user id
    notificationApi.isSupported() ? ( // Not all browsers and devices support notifications.
    <PushNotificationProviderContent>{children}</PushNotificationProviderContent>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
