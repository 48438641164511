import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateNonSyndicatePaymentWireMutation,
  SyndicateWires_NonSyndicatePaymentWiresDocument,
  useSyndicateWires_CreateNonSyndicatePaymentWireMutation,
} from '../graphql';

export type CreateNonSyndicatePaymentWireMutation =
  SyndicateWires_CreateNonSyndicatePaymentWireMutation;

export const useCreateNonSyndicatePaymentWireMutation = () => {
  return useSyndicateWires_CreateNonSyndicatePaymentWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicatePaymentWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
