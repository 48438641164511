import React from 'react';

export type Props = {
  firmKeyOrId?: string;
  setFirmKeyOrId: (firmKeyOrId?: string) => void;
  firmKey?: string;
  setFirmKey: (firmKey?: string) => void;
};

// There are firmKey and firmKeyOrId in the context because in Rolodex context,
// firmKey and firmId can be used interchangeably most of the time. However, in Order Bokk Context,
// we only use firmKey.
const FirmKeyOrIdContext = React.createContext<Props>({
  firmKeyOrId: undefined,
  setFirmKeyOrId: () => {},
  firmKey: undefined,
  setFirmKey: () => {},
});

export const useFirmKeyOrIdContext = () => {
  const context = React.useContext(FirmKeyOrIdContext);

  if (context === undefined) {
    throw new Error('No Provider found for FirmKeyOrIdContext');
  }

  return context;
};

export const FirmKeyOrIdProvider: React.FC = ({ children }) => {
  const [firmKeyOrId, setFirmKeyOrId] = React.useState<string>();
  const [firmKey, setFirmKey] = React.useState<string>();

  return (
    <FirmKeyOrIdContext.Provider value={{ firmKeyOrId, setFirmKeyOrId, firmKey, setFirmKey }}>
      {children}
    </FirmKeyOrIdContext.Provider>
  );
};
