import React from 'react';

import List from '../../lists/list/List';
import { SContainer } from './SideNav.styles';

export type Props = {
  testId?: string;
  className?: string;
};

const SideNav: React.FC<Props> = ({ testId, children, className }) => {
  return (
    <SContainer data-test-id={testId} className={className}>
      <List>{children}</List>
    </SContainer>
  );
};

export default SideNav;
