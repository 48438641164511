import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';

import { useFeatureToggles } from '../config';

const permissions = [permissionsByEntity.CertificateLibrary.READ];

/**
 * @returns {Object} - Object with properties:
 * - canShowAccess: boolean - whether the user has access to the certificate library ej: ff is on and account is sell side and user has permissions
 * - canRenderRoute: boolean - whether the route should be rendered ej: ff is on and account is sell side
 * - permissions: string[] - permissions required to access the certificate library
 */
const useCertificateLibraryAccess = () => {
  const hasCertificateLibraryAccess = useCheckPermissions(permissions);

  const { isCertificateLibraryOn } = useFeatureToggles();
  const isSellSide = useCheckAccountType('SELL_SIDE');

  const canShowAccess = isCertificateLibraryOn && isSellSide && hasCertificateLibraryAccess;
  const canRenderRoute = isCertificateLibraryOn && isSellSide;

  return {
    canShowAccess,
    canRenderRoute,
    permissions,
  };
};

export default useCertificateLibraryAccess;
