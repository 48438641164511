import React from 'react';
import styled from 'styled-components/macro';

export const SInfoText = styled.div`
  max-width: 215px;
  text-align: justify;
`;

const IssuerSymbolInfo: React.FC = () => {
  return <SInfoText>The symbol of the security that is the subject of the distribution</SInfoText>;
};

export default IssuerSymbolInfo;
