import React, { FC } from 'react';

import { SLayout } from './HighlightPanel.styles';

export const HighlightPanel: FC = props => {
  const { children } = props;
  return <SLayout role="presentation">{children}</SLayout>;
};

export default HighlightPanel;
