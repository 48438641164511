/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RoadshowMapsAutocompleteQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
}>;

export type Roadshows_RoadshowMapsAutocompleteQuery = {
  readonly __typename?: 'Query';
  readonly roadshowCityAutocomplete: ReadonlyArray<{
    readonly __typename?: 'CityInfo';
    readonly cityAddressParts: ReadonlyArray<string>;
    readonly fullCityAddress: string;
  }>;
};

export type Roadshows_RoadshowTimezoneInfoQueryVariables = Types.Exact<{
  address: Types.Scalars['String'];
  date: Types.Scalars['Date'];
}>;

export type Roadshows_RoadshowTimezoneInfoQuery = {
  readonly __typename?: 'Query';
  readonly roadshowTimezoneInfo: {
    readonly __typename?: 'TimezoneInfo';
    readonly timeZoneAcronym: string;
    readonly timeZoneId: string;
    readonly timeZoneName: string;
  };
};

export const Roadshows_RoadshowMapsAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowMapsAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowCityAutocomplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cityAddressParts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullCityAddress' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowMapsAutocompleteQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowMapsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowMapsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowMapsAutocompleteQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useRoadshows_RoadshowMapsAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowMapsAutocompleteQuery,
    Roadshows_RoadshowMapsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowMapsAutocompleteQuery,
    Roadshows_RoadshowMapsAutocompleteQueryVariables
  >(Roadshows_RoadshowMapsAutocompleteDocument, options);
}
export function useRoadshows_RoadshowMapsAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowMapsAutocompleteQuery,
    Roadshows_RoadshowMapsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowMapsAutocompleteQuery,
    Roadshows_RoadshowMapsAutocompleteQueryVariables
  >(Roadshows_RoadshowMapsAutocompleteDocument, options);
}
export type Roadshows_RoadshowMapsAutocompleteQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowMapsAutocompleteQuery
>;
export type Roadshows_RoadshowMapsAutocompleteLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowMapsAutocompleteLazyQuery
>;
export type Roadshows_RoadshowMapsAutocompleteQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowMapsAutocompleteQuery,
  Roadshows_RoadshowMapsAutocompleteQueryVariables
>;
export const Roadshows_RoadshowTimezoneInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowTimezoneInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowTimezoneInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timeZoneAcronym' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZoneId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZoneName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowTimezoneInfoQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowTimezoneInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowTimezoneInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowTimezoneInfoQuery({
 *   variables: {
 *      address: // value for 'address'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRoadshows_RoadshowTimezoneInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowTimezoneInfoQuery,
    Roadshows_RoadshowTimezoneInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowTimezoneInfoQuery,
    Roadshows_RoadshowTimezoneInfoQueryVariables
  >(Roadshows_RoadshowTimezoneInfoDocument, options);
}
export function useRoadshows_RoadshowTimezoneInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowTimezoneInfoQuery,
    Roadshows_RoadshowTimezoneInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowTimezoneInfoQuery,
    Roadshows_RoadshowTimezoneInfoQueryVariables
  >(Roadshows_RoadshowTimezoneInfoDocument, options);
}
export type Roadshows_RoadshowTimezoneInfoQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowTimezoneInfoQuery
>;
export type Roadshows_RoadshowTimezoneInfoLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowTimezoneInfoLazyQuery
>;
export type Roadshows_RoadshowTimezoneInfoQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowTimezoneInfoQuery,
  Roadshows_RoadshowTimezoneInfoQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowMapsAutocompleteMockResponse = makeMockResponse<
  Roadshows_RoadshowMapsAutocompleteQueryVariables,
  Roadshows_RoadshowMapsAutocompleteQuery
>(Roadshows_RoadshowMapsAutocompleteDocument);

export const makeRoadshows_RoadshowTimezoneInfoMockResponse = makeMockResponse<
  Roadshows_RoadshowTimezoneInfoQueryVariables,
  Roadshows_RoadshowTimezoneInfoQuery
>(Roadshows_RoadshowTimezoneInfoDocument);
