import { TableSkeleton } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import React from 'react';

import { LoadingErrorSection } from '../../common/components/dialogs/create-or-update-wire-dialog/error-loading-recipients-data-section/LoadingErrorSection';
import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';
import { useBillAndDeliverPaymentsQuery } from '../../common/graphql';
import { useOnFormDataLoaded } from '../../common/hooks/useOnFormDataLoaded';
import { RecipientsTable } from './components/RecipientsTable';
import { RecipientsTableHead } from './components/RecipientsTableHead';

export const ManagerBdWireForm: React.VFC<CreateOrUpdateFormProps> = ({
  managers,
  operationType,
  offeringId,
  onFormDataLoaded,
}) => {
  const { data, loading, error, refetch } = useBillAndDeliverPaymentsQuery({
    variables: {
      offeringId,
    },
  });

  useOnFormDataLoaded({ onFormDataLoaded, loading, error });

  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem xs={4}>
        <FormikTextField
          required
          label="Sender DTC Number"
          showHelperTextInTooltip
          name="senderDtc"
        />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>

      {loading && (
        <WireFormLayoutItem>
          <TableSkeleton
            aria-label="Manager B&D Wire Recipients Table"
            cellSize="medium"
            numOfColumns={4}
          />
        </WireFormLayoutItem>
      )}

      {error && !loading && (
        <WireFormLayoutItem>
          <LoadingErrorSection
            tableLabel="Manager B&D Wire Recipients Table"
            tableHead={<RecipientsTableHead />}
            onRefetch={refetch}
          />
        </WireFormLayoutItem>
      )}

      {data && !loading && !error && (
        <React.Fragment>
          <WireFormLayoutItem>
            <WireFormBanner variant="manager-specific-input" />
          </WireFormLayoutItem>
          <WireFormLayoutItem>
            <RecipientsTable
              managers={managers}
              operationType={operationType}
              billAndDeliverData={data?.syndicateWires.billAndDeliverPayments}
            />
          </WireFormLayoutItem>
        </React.Fragment>
      )}
    </WireFormLayout>
  );
};
