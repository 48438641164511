import { numericUtil } from '@cmg/common';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';

import { ExpensesValues } from '../../form/ExpensesForm.model';
import { FinalSettlement_ExpensesQuery } from '../../graphql';
import EditableManagerExpensesRow from './expenses-row/EditableManagerExpensesRow';

export type Props = {
  managers: FinalSettlement_ExpensesQuery['expenses']['managers'];
};

const EditableManagerExpenses: React.FC<Props> = ({ managers }) => {
  const { values } = useFormikContext<ExpensesValues>();

  return (
    <FieldArray
      validateOnChange={false}
      name="managerExpenses"
      render={() => {
        return values.managerExpenses.map((item, index) => {
          const expenses = values.managerExpenses[index];
          const total = numericUtil.sum(
            expenses.travelAndMeals,
            expenses.technologyAndData,
            expenses.miscellaneous
          );
          const manager = managers.find(({ cmgEntityKey }) => cmgEntityKey === item.cmgEntityKey)!;
          return (
            <EditableManagerExpensesRow
              key={item.cmgEntityKey}
              index={index}
              manager={manager}
              total={total}
            />
          );
        });
      }}
    />
  );
};

export default EditableManagerExpenses;
