import { AgGridApi, Checkbox, DropdownSelect, Icon } from '@cmg/common';
import React from 'react';

import {
  SButtonWrapper,
  SIconButton,
} from '../../../../../final-settlement/designation-monitor/designation-monitor-details/designation-monitor-grid/components/header-group-checkbox/HeaderGroupCheckbox.styles';
import { TradeRowActionsAvailability } from '../../../hooks/useTradeGrid';
import { getActionsAvailability, TradeReleaseGridContext } from '../../TradeReleaseGrid.model';
import {
  dropDownItems,
  DropDownItemValueType,
  handleCheckboxChange,
  handleDropdownSelectChange,
} from './HeaderGroupCheckboxRenderer.model';

export type Props = Readonly<{
  context: TradeReleaseGridContext;
  api: AgGridApi;
}>;

const HeaderGroupCheckboxRenderer: React.FC<Props> = ({ context, api }) => {
  const selectableRowsLength = context.filteredRows.filter(
    row => getActionsAvailability(row) === TradeRowActionsAvailability.ENABLED
  ).length;
  const selectedRowsLength = api.getSelectedRows().length;
  const isSelected = selectedRowsLength > 0 && selectableRowsLength === selectedRowsLength;
  const disabled = selectableRowsLength === 0;

  const onCheckboxChange = React.useCallback(
    (value: boolean) => {
      handleCheckboxChange(api, value);
    },
    [api]
  );

  const onDropdownSelectChange = React.useCallback(
    (values: DropDownItemValueType[]) => {
      handleDropdownSelectChange(api, values);
    },
    [api]
  );

  return (
    <SButtonWrapper>
      <Checkbox
        onChange={onCheckboxChange}
        value={isSelected}
        disabled={disabled}
        showDisabledIcon
      />
      <DropdownSelect
        items={dropDownItems}
        onChange={onDropdownSelectChange}
        popoverVariant="LIGHT"
        label={
          <SIconButton>
            <Icon name="chevron-down" variant="regular" color="black" />
          </SIconButton>
        }
      />
    </SButtonWrapper>
  );
};

export default HeaderGroupCheckboxRenderer;
