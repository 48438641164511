import { useAuth } from '@cmg/auth';
import { useListCompare } from '@cmg/common';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { UpdateType } from './useComputeDiffOnInstitutionalDemandGrid';
import { institituionalIndicationDemandsArg } from './useComputeDiffOnInstitutionalIndicationDemands';

export const enum BDPendingUpdateLabels {
  UPDATE_BD = 'B&D Agent Updated',
}

export const useComputeDiffOnBillingAgent = function (
  currIndications: institituionalIndicationDemandsArg,
  nextIndications: institituionalIndicationDemandsArg,
  settlementAgent: string | null
) {
  const { oidcUserCmgEntityKey } = useAuth();
  const isSettlementAgent = settlementAgent === oidcUserCmgEntityKey;

  const { mutatedItems } = useListCompare(currIndications, nextIndications, {
    isEnabled: !isSettlementAgent,
    key: item => item.id,
    equals: (a, b) =>
      a.billingAndDeliveryAgentCmgEntityKey === b.billingAndDeliveryAgentCmgEntityKey,
  });

  return React.useMemo(
    () =>
      mutatedItems.map(([, next]) => ({
        type: UpdateType.BD_AGENT_UPDATES,
        pendingUpdateLabel: [`${BDPendingUpdateLabels.UPDATE_BD}`],
        indicationId: next.id,
        // FE does not have audit info and needs a BE change
        // It was decided with product to leave it empty for now
        timeStamp: null,
        id: uuidV4(),
      })),
    [mutatedItems]
  );
};
