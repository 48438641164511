import type { UUID } from '@cmg/common';
import { useDataGridContext } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback, useMemo } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import { useInstitutionalDemand_UpdateFinalAllocationsMutation } from '../../institutional-demand/components/update-final-institutional-allocations-modal/graphql';
import { getUpdatedAllocations } from '../demand-grid/columns/columns.model';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../graphql/__generated__';

type AllocationChange = { indicationId: UUID; finalAllocationShares: number | null };

export type Props = Readonly<{
  offeringId: UUID;
  finalAllocationSetId: UUID | undefined;
  onDiscard: () => void;
}>;

export const useUpdateReleasedFinalAllocationDialogState = ({
  offeringId,
  finalAllocationSetId,
  onDiscard,
}: Props) => {
  const dialogState = useDialogState();
  const { applyServerSideTransaction, getRowData } = useDataGridContext();
  const [updateFinalAllocations, { loading, error: apolloError, data }] =
    useInstitutionalDemand_UpdateFinalAllocationsMutation();

  const error = useMemo(() => {
    if (apolloError) {
      return apolloError;
    }

    if (data?.updateFinalInstitutionalAllocations.__typename === 'ServiceError') {
      return data.updateFinalInstitutionalAllocations;
    }
  }, [apolloError, data?.updateFinalInstitutionalAllocations]);

  const updateRowData = useCallback(
    (changedAllocations: AllocationChange[]) => {
      if (!finalAllocationSetId) {
        return;
      }

      const changedAllocationsMap = changedAllocations.reduce((acc, curr) => {
        return { ...acc, [curr.indicationId]: curr.finalAllocationShares };
      }, {});

      const rowData = getRowData<OrderBook_InstitutionalDemand_GridRowPartsFragment>(
        Object.keys(changedAllocationsMap)
      );

      const update = rowData.map(row => {
        const updatedAllocations = getUpdatedAllocations(
          row.allocations,
          finalAllocationSetId,
          changedAllocationsMap[row.id] || 0
        );

        return { ...row, allocations: updatedAllocations };
      });

      applyServerSideTransaction({ update });
    },
    [applyServerSideTransaction, finalAllocationSetId, getRowData]
  );

  const onSave = useCallback(
    async (changedAllocations: AllocationChange[]) => {
      const mutationResult = await updateFinalAllocations({
        variables: {
          offeringId,
          input: {
            allocations: changedAllocations.map(({ indicationId, finalAllocationShares }) => ({
              indicationId,
              allocation: { shareQuantity: finalAllocationShares || 0 },
            })),
          },
        },
      });

      if (mutationResult.data?.updateFinalInstitutionalAllocations.__typename !== 'ServiceError') {
        SnackbarManager.success('Successfully Updated Final Allocations');
        updateRowData(changedAllocations);
        onDiscard();
      }
    },
    [offeringId, onDiscard, updateFinalAllocations, updateRowData]
  );

  return { ...dialogState, onSave, loading, error };
};
