import { deleteArrayItemFromGraphQLCacheFactory } from '../../../../../../../../../common/util/graphql';
import {
  Roadshows_DeleteRoadshowManagementTeamMemberMutation,
  useRoadshows_DeleteRoadshowManagementTeamMemberMutation,
} from '../graphql';

export type DeleteTeamMemberMutation = Roadshows_DeleteRoadshowManagementTeamMemberMutation;

// Management team members cache is updated instead of refetching (preffered) for user experience
export const useDeleteTeamMemberMutation = (roadshowId: string) => {
  return useRoadshows_DeleteRoadshowManagementTeamMemberMutation({
    update: deleteArrayItemFromGraphQLCacheFactory(
      { __typename: 'ManagementTeam', id: roadshowId },
      'members',
      result => ({ id: result.data?.deleteRoadshowManagementTeamMember! })
    ),
  });
};
