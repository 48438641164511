import styled, { css } from 'styled-components/macro';

type SSideBarProps = { error?: boolean };

export const baseLinkStyles = css`
  display: block;
  font-size: ${({ theme }) => theme.text.size.mediumSmall};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  color: ${({ theme }) => theme.text.color.dark};
  padding: 5px;
  margin: 3px 5px;
`;

export const SContent = styled.div`
  width: 100%;
  padding: 16px;
`;

export const SSearchWrapper = styled.div`
  margin: 0 92px;
`;

export const SPresentation = styled.div.attrs(() => ({ role: 'presentation' }))``;

export const SArticle = styled.article`
  display: flex;
  min-height: 100%;
`;

export const SLeftLayout = styled.section`
  display: flex;
  flex-direction: column;
  flex-basis: 232px;
`;

export const StyledLeftContainer = styled(SPresentation)`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 16px;
`;

export const StyledContent = styled(SPresentation)`
  display: flex;
  flex-direction: column;
  flex-basis: 554px;
  padding: 16px;
`;

export const StyledIndicationActivity = styled(SPresentation)`
  display: flex;
  margin: 10px;
  flex-direction: column;
  flex: 1;
`;

export const SSection = styled.section`
  margin-bottom: 15px;
`;

export const SBoldValue = styled.div`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SLeftHeading = styled.h1`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const StyledDesignationToolbar = styled(SPresentation)`
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  padding: 0 16px;
`;

export const SDesignationToolbarLabel = styled.label`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const StyledDesignationToolbarGroup = styled(SPresentation)`
  display: flex;
  align-items: center;
  > * :not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledTotalsContainer = styled(SPresentation)`
  margin-left: 16px;
`;

export const SSpacer = styled.div`
  min-height: 30px;
`;

export const SSideBar = styled.div<SSideBarProps>`
  flex: 0 0 230px;
  background-color: ${({ error, theme }) =>
    error ? theme.designSystem.colors.red['000'] : theme.background.color.light};
`;

export const SSidePanelLayout = styled.div`
  display: flex;
  min-height: 100%;
`;
