import { useTheme } from 'styled-components/macro';

import { StyledIcon } from './ErrorIcon.styles';

const ErrorIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <StyledIcon
      name="exclamation-triangle"
      variant="solid"
      color={theme.designSystem.colors.red['300']}
    />
  );
};

export default ErrorIcon;
