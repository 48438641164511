import { DeleteIcon, Grid, IconButton, TableCell, TableRow } from '@cmg/design-system';
import {
  FormikCurrencyField,
  FormikNumberField,
  FormikPercentageField,
  FormikSelectField,
} from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import { getLimitTypesForOffering } from '../../../../../../../../../common/util/offering/offering-type.util';
import {
  InstitutionalIndicationOrderType,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
  OfferingType,
} from '../../../../../../../../../graphql';
import {
  interestUnitLabels,
  interestUnitOptions,
  limitTypeLabels,
} from '../../../../../../../../../types/domain/order-book/constants';
import {
  institutionalIndicationInterestLevelOrderTypeOptions,
  institutionalIndicationOrderTypeLabels,
} from '../../../../../../../../order-book/constants';
import { IndicationFormValues, InterestLevelValue } from '../../utils/IndicationSectionValidation';
import { getIsLimitTypeFieldHidden, getIsOrderTypeFieldHidden } from './InterestLevelFormRow.model';

export type Props = Readonly<{
  index: number;
  onRemove: () => void;
  rowValue: InterestLevelValue;
  offeringType: OfferingType;
  pricingCurrencyCode?: string;
}>;

const InterestLevelFormRow: React.FC<Props> = ({
  onRemove,
  rowValue,
  index,
  offeringType,
  pricingCurrencyCode,
}) => {
  const { isSubmitting, setFieldValue, values } = useFormikContext<IndicationFormValues>();

  const { limitType, interestUnit, orderType, interestQuantity } = rowValue;

  const limitTypeOptions = React.useMemo(
    () => getLimitTypesForOffering(offeringType),
    [offeringType]
  );

  const isLimitTypeCurrency = limitType === InterestLevelLimitType.Price;
  const isOrderTypeLimit = orderType === InstitutionalIndicationOrderType.Limit;

  const isDemandUnitHidden = index !== 0;

  // order type field is visible only when there are any options that can be selected, there can be only one market order type
  const isOrderTypeFieldHidden = React.useMemo(
    () => getIsOrderTypeFieldHidden(values.interestLevels, isOrderTypeLimit),
    [isOrderTypeLimit, values.interestLevels]
  );

  const isLimitTypeFieldHidden = React.useMemo(
    () => getIsLimitTypeFieldHidden(values.interestLevels, index),
    [index, values.interestLevels]
  );

  const handleInterestUnitChange = React.useCallback(
    (value: unknown) => {
      // without using generic select component the value is unknown, but setting generics does not work on nested schema
      const newValue = value as InterestLevelInterestUnit | null;

      if (newValue === interestUnit) {
        return;
      }

      values.interestLevels.forEach((_, index) => {
        setFieldValue(`interestLevels.${index}.interestUnit`, value);
      });

      if (interestUnit === null) {
        values.interestLevels.forEach(({ interestQuantity }, key) => {
          if (interestQuantity !== null) {
            if (newValue === InterestLevelInterestUnit.Percent) {
              setFieldValue(`interestLevels.${key}.interestQuantity`, interestQuantity / 100);
            }
            if (newValue === InterestLevelInterestUnit.Shares) {
              setFieldValue(`interestLevels.${key}.interestQuantity`, Math.round(interestQuantity));
            }
          }
        });
      }

      if (interestUnit && interestQuantity) {
        values.interestLevels.forEach((_, key) => {
          setFieldValue(`interestLevels.${key}.interestQuantity`, null);
        });
      }
    },
    [interestUnit, values.interestLevels, setFieldValue, interestQuantity]
  );

  const handleOrderTypeChange = React.useCallback(
    (value: unknown) => {
      // without using generic select component the value is unknown, but setting generics does not work on nested schema
      const newValue = value as InstitutionalIndicationOrderType | null;

      if (newValue === orderType) {
        return;
      }

      if (newValue === InstitutionalIndicationOrderType.Market) {
        // rest limit type and limit price when we set it to market order type
        setFieldValue(`interestLevels.${index}.limitType`, null);
        setFieldValue(`interestLevels.${index}.limitPrice`, null);

        // set all other levels to limit
        values.interestLevels.forEach((_, key) => {
          if (key !== index) {
            setFieldValue(
              `interestLevels.${key}.orderType`,
              InstitutionalIndicationOrderType.Limit
            );
          }
        });
      }

      if (newValue === InstitutionalIndicationOrderType.Limit) {
        // default limit type to used limit type when changing to limit order type
        const rowWithLimitType = values.interestLevels.find(({ limitType }) => limitType);
        setFieldValue(
          `interestLevels.${index}.limitType`,
          rowWithLimitType?.limitType ?? InterestLevelLimitType.Price
        );
      }
    },
    [orderType, values.interestLevels, setFieldValue, index]
  );

  const handleLimitTypeChange = React.useCallback(
    (value: unknown) => {
      // without using generic select component the value is unknown, but setting generics does not work on nested schema
      const newValue = value as InterestLevelLimitType | null;

      if (newValue === limitType) {
        return;
      }

      // when changing limit type we set this limit type to every interest level that has limit order type and reset limit amount
      values.interestLevels.forEach((rowValue, index) => {
        setFieldValue(`interestLevels.${index}.limitPrice`, null);

        if (rowValue.orderType === InstitutionalIndicationOrderType.Limit) {
          setFieldValue(
            `interestLevels.${index}.limitType`,
            newValue ?? InterestLevelLimitType.Price
          );
        }
      });
    },
    [values.interestLevels, setFieldValue, limitType]
  );

  return (
    <TableRow>
      <TableCell>
        {interestUnit === InterestLevelInterestUnit.Currency && (
          <FormikCurrencyField
            aria-label="interestQuantity"
            name={`interestLevels.${index}.interestQuantity`}
            disabled={isSubmitting}
            fullWidth
            showHelperTextInTooltip
            currencyCode={values.currencyCode ?? undefined}
          />
        )}
        {interestUnit === InterestLevelInterestUnit.Percent && (
          <FormikPercentageField
            aria-label="interestQuantity"
            name={`interestLevels.${index}.interestQuantity`}
            disabled={isSubmitting}
            fullWidth
            showHelperTextInTooltip
          />
        )}
        {interestUnit === InterestLevelInterestUnit.Shares && (
          <FormikNumberField
            aria-label="interestQuantity"
            name={`interestLevels.${index}.interestQuantity`}
            disabled={isSubmitting}
            decimalScale={0}
            fullWidth
            showHelperTextInTooltip
          />
        )}
        {interestUnit === null && (
          <FormikNumberField
            aria-label="interestQuantity"
            name={`interestLevels.${index}.interestQuantity`}
            disabled={isSubmitting}
            fullWidth
            showHelperTextInTooltip
          />
        )}
      </TableCell>
      <TableCell>
        {isDemandUnitHidden ? (
          interestUnit && interestUnitLabels[interestUnit]
        ) : (
          <FormikSelectField
            aria-label="interestUnit"
            name={`interestLevels.${index}.interestUnit`}
            disabled={isSubmitting}
            options={interestUnitOptions}
            onChange={handleInterestUnitChange}
            showHelperTextInTooltip
            fullWidth
          />
        )}
      </TableCell>
      <TableCell>
        {isOrderTypeFieldHidden ? (
          institutionalIndicationOrderTypeLabels[orderType!]
        ) : (
          <FormikSelectField
            aria-label="orderType"
            name={`interestLevels.${index}.orderType`}
            disabled={isSubmitting}
            options={institutionalIndicationInterestLevelOrderTypeOptions}
            onChange={handleOrderTypeChange}
            showHelperTextInTooltip
            fullWidth
          />
        )}
      </TableCell>
      <TableCell>
        {isOrderTypeLimit && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={6}>
              {isLimitTypeFieldHidden ? (
                limitTypeLabels[limitType!]
              ) : (
                <FormikSelectField
                  aria-label="limitType"
                  name={`interestLevels.${index}.limitType`}
                  disabled={isSubmitting}
                  options={limitTypeOptions}
                  onChange={handleLimitTypeChange}
                  showHelperTextInTooltip
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLimitTypeCurrency ? (
                <FormikCurrencyField
                  aria-label="limitPrice"
                  data-testid="currency-limit-type-input-field"
                  name={`interestLevels.${index}.limitPrice`}
                  disabled={isSubmitting}
                  fullWidth
                  showHelperTextInTooltip
                  currencyCode={pricingCurrencyCode ?? undefined}
                />
              ) : (
                <FormikPercentageField
                  aria-label="limitPrice"
                  data-testid="percentage-limit-type-input-field"
                  name={`interestLevels.${index}.limitPrice`}
                  disabled={isSubmitting}
                  fullWidth
                  showHelperTextInTooltip
                />
              )}
            </Grid>
          </Grid>
        )}
      </TableCell>
      <TableCell>
        <IconButton title="Remove Interest Level row" color="primary" onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InterestLevelFormRow;
