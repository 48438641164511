import { MeetingType } from '../../../graphql';

export const getMeetingTypeLabel = (type: MeetingType | null | undefined) => {
  switch (type) {
    case MeetingType.OneOnOne:
      return '1:1';
    case MeetingType.TwoOnOne:
      return '2:1';
    case MeetingType.ThreeOnOne:
      return '3:1';
    case MeetingType.FourOnOne:
      return '4:1';
    case MeetingType.LargeGroup:
      return 'Large Group Meeting';
    case MeetingType.SmallGroup:
      return 'Small Group Meeting';
    default:
      return '-';
  }
};
