import styled from 'styled-components/macro';

export const SSubText = styled.div`
  margin: 16px 0;
`;

export const SPopoverText = styled.span`
  border-bottom: 1px dashed ${({ theme }) => theme.designSystem.colors.darkBlue[200]};
`;

export const SPopoverSmallText = styled.div`
  font-size: ${({ theme }) => theme.text.size.tiny};
`;
