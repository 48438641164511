import orderBy from 'lodash/orderBy';

import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../graphql';
import { getInstitutionalIndicationValue } from '../../../calendar/utils/IOIColumnsUtils';
import { MyOrdersGridCoveredIndication } from './MyOrdersGrid.model';

type MapDemandColumnSourcesResult = {
  rootElement: string;
  hoverElements?: string[];
};

// TODO - reduce cognitive complexity - adding something as simple as pricingCurrencyCode pushed it over the edge
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getDemandColumnValue(
  coveredIndication: MyOrdersGridCoveredIndication
): MapDemandColumnSourcesResult {
  if (coveredIndication.type === InstitutionalIndicationOrderType.Pass) {
    return { rootElement: 'Pass' };
  }
  if (coveredIndication?.status === IndicationStatus.Cancelled) {
    return { rootElement: 'Cancelled' };
  }

  const indications = orderBy(coveredIndication.interestLevels || [], 'limitPrice', 'desc');
  const rootMarketIndication = indications.find(indication => !indication.limitType);

  const allLimitIndications =
    coveredIndication.interestLevels?.filter(interest => interest.limitPrice !== null) ?? [];
  const [rootLimitIndication, ...restLimitIndications] = allLimitIndications;

  if (rootMarketIndication) {
    const marketTypeHoverElements = (allLimitIndications || []).filter(
      indication => !!indication.limitType
    );
    const root = getInstitutionalIndicationValue(
      rootMarketIndication,
      coveredIndication.currencyCode ?? undefined,
      coveredIndication.pricingCurrencyCode
    );
    const hover = marketTypeHoverElements.map(indication =>
      getInstitutionalIndicationValue(
        indication,
        coveredIndication.currencyCode ?? undefined,
        coveredIndication.pricingCurrencyCode
      )
    );
    return {
      rootElement: root,
      hoverElements: hover.length ? [root, ...hover] : [],
    };
  }

  if (rootLimitIndication) {
    const root = getInstitutionalIndicationValue(
      rootLimitIndication,
      coveredIndication.currencyCode ?? undefined,
      coveredIndication.pricingCurrencyCode
    );
    const hover = (restLimitIndications || []).map(indication =>
      getInstitutionalIndicationValue(
        indication,
        coveredIndication.currencyCode ?? undefined,
        coveredIndication.pricingCurrencyCode
      )
    );
    return {
      rootElement: root,
      hoverElements: hover.length ? [root, ...hover] : [],
    };
  }

  return {
    rootElement: '-',
  };
}
