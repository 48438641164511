import { Icon, Popover } from '@cmg/common';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components/macro';

import { SalesCreditWarning } from '../../../../../graphql';
import { SWarningTextWrapper } from '../../../../trade-release/layout/grid/grid-columns/trade-warning/TradeWarningRenderer.styles';

export type Props = { value: SalesCreditWarning[] };

const getWarningMessage = (value: SalesCreditWarning[]) => {
  if (value.length === 0) {
    return null;
  }

  if (value.includes(SalesCreditWarning.PublishedDesignationAmountIsZero)) {
    return 'Cancel is required, Sales Credits have been modified';
  }

  if (value.includes(SalesCreditWarning.OrderBookBankInvestorKeyChanged)) {
    return 'Bank Investor Key has been modified and needs review';
  }

  if (value.includes(SalesCreditWarning.BankInvestorKeyChanged)) {
    return 'Re-release is required, Bank Investor Key has been modified';
  }

  if (value.includes(SalesCreditWarning.OfferingChanged)) {
    return 'Re-release is required, Offering Details have been modified';
  }

  if (value.includes(SalesCreditWarning.DesignationChanged)) {
    return 'Re-release is required, Designation Details have been modified';
  }

  if (value.includes(SalesCreditWarning.SalesCreditChanged)) {
    return 'Re-release is required, Sales Credits have been modified';
  }

  if (value.includes(SalesCreditWarning.DesignationNotApplicable)) {
    return 'Cancel is required, Designation Details have been modified';
  }
};

const WarningRenderer: React.FC<Props> = ({ value }) => {
  const theme = useTheme();
  const warningMessage = useMemo(() => getWarningMessage(value), [value]);

  if (warningMessage == null) {
    return null;
  }

  return (
    <Popover
      variant="LIGHT"
      placement="bottomLeft"
      arrowPointAtCenter
      content={<SWarningTextWrapper>{warningMessage}</SWarningTextWrapper>}
    >
      <span data-testid="warning-icon">
        <Icon
          name="exclamation-triangle"
          variant="solid"
          size="lg"
          color={theme.designSystem.colors.red['300']}
        />
      </span>
    </Popover>
  );
};

export default WarningRenderer;
