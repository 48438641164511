import styled from 'styled-components/macro';

export const SBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SHeader = styled.h4`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.medium};
  line-height: 16px;
  margin: 0;
`;

export const SContent = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.medium};
  line-height: 16px;
`;
