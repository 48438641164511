import { FilterValueGetterFn } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridDataContext, DemandGridServerColDef } from '../../types';
import { managersFilterParams } from '../columns.model';

export const filterValueGetter: FilterValueGetterFn = (filterModel, context) => {
  const filterParam = filterModel?.in;
  if (Array.isArray(filterParam) && filterParam.length === 0) {
    const allManagers = Object.keys((context as DemandGridDataContext).syndicateManagers);
    return {
      all: {
        firmKey: {
          nin: allManagers,
        },
      },
    };
  }
  return {
    some: {
      firmKey: filterModel,
    },
  };
};

type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['coveringManagerNames'];

const relationshipColDef: DemandGridServerColDef<Value> = {
  field: 'coveringManagerNames',
  headerName: 'Relationship',
  filter: 'agSetColumnFilter',
  filterParams: managersFilterParams,
  server: {
    filterFieldPath: 'coveringManagers',
    filterValueGetter,
  },
};

export default relationshipColDef;
