import { UUID } from '@cmg/common';

import { InterestLevelInput } from '../../../../../../../../graphql';
import { IndicationCreateFormValues } from './IndicationSectionValidation';

export const createInstitutionalIndicationSubmitInputVariables = ({
  offeringId,
  values,
  firmKey,
  cmgEntityKey,
}: {
  offeringId: UUID;
  values: IndicationCreateFormValues;
  firmKey?: string;
  cmgEntityKey?: string;
}) => ({
  offeringId,
  input: {
    trancheId: values.trancheId!,
    instrumentId: values.instrumentId,
    currencyCode: values.currencyCode,
    interestLevels: values.interestLevels.map<InterestLevelInput>(
      ({ interestQuantity, interestUnit, limitPrice, limitType }) => ({
        interestUnit: interestUnit!,
        interestQuantity: interestQuantity!,
        limitType,
        limitPrice,
      })
    ),
    investorInformation: firmKey ? { bankInvestorKey: firmKey } : { cmgEntityKey: cmgEntityKey! },
  },
});
