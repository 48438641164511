import { Modal, SecondaryButton, UUID } from '@cmg/common';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import InstitutionalDemandGridUpdatesContext from '../institutional-demand-grid/context/InstitutionalDemandGridUpdatesContext';
import { SButtonGroup, SFooter, SHeader } from './InstitutionalDemandGridUpdatesModal.styles';
import InstitutionalDemandUpdatesGrid from './InstitutionalDemandUpdatesGrid';

type OwnProps = {
  offeringId: UUID;
};

export type Props = OwnProps & InjectedProps;

export const OrderBookUpdatesModal: React.FC<Props> = ({ handleHide }) => {
  const { recentUpdates, setRecentUpdates } = React.useContext(
    InstitutionalDemandGridUpdatesContext
  );

  const recentUpdatesSnapshot = React.useRef(recentUpdates);

  const handleClose = () => {
    const markRecentUpdatesAsRead = recentUpdates.map(update => {
      return {
        ...update,
        read: recentUpdatesSnapshot.current.includes(update) ? true : update.read,
      };
    });
    setRecentUpdates(markRecentUpdatesAsRead);
    handleHide();
  };

  return (
    <Modal
      show
      size="small"
      title={`Recent Updates (${recentUpdatesSnapshot.current.length})`}
      onHide={() => {
        handleClose();
      }}
      closeButton={false}
      footer={
        <SFooter>
          <SButtonGroup>
            <SecondaryButton onClick={handleClose}> Close </SecondaryButton>
          </SButtonGroup>
        </SFooter>
      }
    >
      <SHeader>Recent Order Book updates</SHeader>
      <InstitutionalDemandUpdatesGrid updates={recentUpdatesSnapshot.current} />
    </Modal>
  );
};

const name = 'ORDERBOOK/ORDERBOOK_GRID_UPDATES_MODAL';
export const openOrderBookGridUpdatesModal = (props: OwnProps) => show(name, props);
export const closeOrderBookGridUpdatesModal = () => hide(name);

export default connectModal({ name })(OrderBookUpdatesModal);
