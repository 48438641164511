import { UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import SalesCreditsReleaseHistorySidePanel from './side-panel/SalesCreditsReleaseHistorySidePanel';

export type SalesCreditsInvestor = {
  cmgEntityKey: UUID;
  name: string;
  firmId: UUID | null;
};

type SalesCreditsHistoryRouteParams = {
  offeringId: UUID;
  investorFirmCmgEntityKey: UUID;
};
export type Props = {
  investors: SalesCreditsInvestor[];
} & RouteComponentProps<SalesCreditsHistoryRouteParams>;

const SalesCreditsReleaseHistoryRoute: React.FC<Props> = ({ match, investors }) => {
  const { offeringId, investorFirmCmgEntityKey } = match.params;
  const { replace } = useHistory();

  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState(true);
  const handleCloseSidePanel = React.useCallback(() => {
    setIsSidePanelOpen(false);
    replace(routeFactory.salesCredits.getUrlPath({ offeringId }));
  }, [replace, offeringId]);

  return (
    <SalesCreditsReleaseHistorySidePanel
      offeringId={offeringId}
      investorFirmCmgEntityKey={investorFirmCmgEntityKey}
      isOpen={isSidePanelOpen}
      onClose={handleCloseSidePanel}
      investors={investors}
    />
  );
};

export default SalesCreditsReleaseHistoryRoute;
