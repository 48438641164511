import { useAuth } from '@cmg/auth';
import { LinkButton } from '@cmg/common';
import React from 'react';
import { useDispatch } from 'react-redux';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import { getFeatureToggles } from '../../../../common/config';
import { IndicationStatus } from '../../../../graphql';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import AllocationDiscrepancyModal from './components/allocation-discrepancy-modal/AllocationDiscrepancyModal';
import { openCrmSelectionModal } from './components/crm-selection/CrmSelectModal';
import ManageSyndicateIndication from './components/manage-indication/ManageSyndicateIndication';
import { useIndicationActivitySyndicateContext } from './context/IndicationActivitySyndicateContext';
import { SBankInvestorKey } from './IndicationActivitySellSide.styles';
import { useIndicationCoverage } from './IndicationActivitySellSideHooks';

// eslint-disable-next-line sonarjs/cognitive-complexity
const IndicationActivitySyndicate: React.FC = () => {
  const {
    offeringId,
    access,
    error,
    basicPublishedOffering,
    allInvestorFirmsMappedToCmgEntityKey,
  } = useIndicationActivitySyndicateContext();

  const dispatch = useDispatch();
  const { cmgEntityKey } = useCmgEntityKeyContext();

  const { isOrderBookDupesOn } = getFeatureToggles();
  const { prospectusContactMandatory } = useAuth();

  const isProspectusDocumentMissing = basicPublishedOffering?.prospectusDocuments.length === 0;

  const institutionalIndication = basicPublishedOffering?.orderBook.institutionalIndication;

  const { isCurrentInvestorCovered } = useIndicationCoverage(
    offeringId,
    cmgEntityKey,
    institutionalIndication?.duplicateIndicationIds ?? []
  );

  if (!access.canReadOfferingAndIndication) {
    return <NotAccessible subject="Indication" />;
  }

  const canEditIfDuplicate =
    isOrderBookDupesOn && institutionalIndication?.status !== IndicationStatus.Duplicate;

  // We need to create a way to handle editable in the parent component that will disable
  // all checks for inner components, editable checks should be moved here
  const isIndicationEditable = canEditIfDuplicate && isCurrentInvestorCovered;

  const { bankInvestorKey, cmgEntityName } = institutionalIndication?.investorInformation ?? {};

  const { id } = institutionalIndication ?? {};

  const hasDuplication =
    institutionalIndication?.duplicateIndicationIds &&
    institutionalIndication?.duplicateIndicationIds.length > 0;
  const displayEditBankInvestorKeyButton =
    hasDuplication ||
    bankInvestorKey ||
    allInvestorFirmsMappedToCmgEntityKey?.some(firm => firm.firmKey !== null);

  return (
    <FlexLayout>
      <AllocationDiscrepancyModal />
      {error && <ServerErrorsBanner error={error} />}

      {!!basicPublishedOffering && !!institutionalIndication && (
        <div>
          <SBankInvestorKey>Bank Investor Key: {bankInvestorKey ?? '-'}</SBankInvestorKey>
          {displayEditBankInvestorKeyButton && (
            <LinkButton
              testId="open-crm-modal-button"
              onClick={() =>
                dispatch(
                  openCrmSelectionModal({
                    cmgEntityKey,
                    offeringId,
                    indicationId: id,
                    cmgEntityName,
                    firmKey: bankInvestorKey,
                    duplicateIndicationIds: institutionalIndication?.duplicateIndicationIds,
                  })
                )
              }
            >
              Edit
            </LinkButton>
          )}
        </div>
      )}

      {!!basicPublishedOffering && (
        <ManageSyndicateIndication
          editable={isIndicationEditable}
          isInvestorCovered={isCurrentInvestorCovered}
          isProspectusDocumentMissing={isProspectusDocumentMissing}
          isProspectusContactMandatory={prospectusContactMandatory ?? false}
          isOrderBookOpen={false}
        />
      )}
    </FlexLayout>
  );
};

export default React.memo(IndicationActivitySyndicate);
