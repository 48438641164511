import { useAccessToken } from '@cmg/auth';
import { loggerUtil } from '@cmg/common';
import { useEffect, useState } from 'react';

import { useIdentityServiceHost } from '../../config';
import { webApi } from '../util';

/** A hook that provides the VAPID public key. */
export const usePublicKey = () => {
  const accessToken = useAccessToken();
  const host = useIdentityServiceHost();
  const [publicKey, setPublicKey] = useState<string | undefined>();

  useEffect(() => {
    if (accessToken && !publicKey) {
      (async () => {
        const vapid = await webApi.getVAPID({ host, accessToken });
        if (vapid) {
          setPublicKey(vapid.publicKey);
        } else {
          loggerUtil.warning('Failed to get VAPID public key for push notifications.');
        }
      })();
    }
  }, [accessToken, host, publicKey]);

  return publicKey;
};
