import * as yup from 'yup';

export type AdHocParticipantValues = {
  name: string;
  title: string | null;
  mobilePhone: string | null;
  officePhone: string | null;
  email: string;
};

export const initialValues: AdHocParticipantValues = {
  name: '',
  title: null,
  mobilePhone: null,
  officePhone: null,
  email: '',
};

export const validationSchema = yup.object().shape({
  name: yup.string().label('Name').nullable().required(),
  title: yup.string().label('Title').nullable(),
  mobilePhone: yup.string().label('Mobile Phone').nullable(),
  officePhone: yup.string().label('Office Phone').nullable(),
  email: yup.string().email().label('Email').nullable().required(),
});
