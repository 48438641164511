import { FlexContainer } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

export const SWrapper = styled(FlexContainer)`
  margin: 16px;
  background: ${({ theme }) => theme.background.color.white};
  height: 100%;
`;

export const SBannersWrapper = styled(FlexContainer)`
  margin: 0 16px;
`;

export const StyledForm = styled(Form)`
  display: contents;
`;
