import React from 'react';

import GridWarningTooltip from '../../../../../../../design-system/components/data-display/grid-warning-tooltip/GridWarningTooltip';

export type Props = Readonly<{
  investorName: string;
  reason?: string;
}>;

const RepublishWarning: React.FC<Props> = ({ investorName, reason }) => {
  return (
    <GridWarningTooltip variant="warning">
      <div>Designation set needs to be republished.</div>
      <div>
        {investorName} – {reason}.
      </div>
    </GridWarningTooltip>
  );
};

export default RepublishWarning;
