import { Popover, SecondaryButton, ToastManager } from '@cmg/common';
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';

import { OnExportResult } from './ExportButton.model';

export type Props = {
  onExport: () => Promise<OnExportResult>;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const ExportButton: React.VFC<Props> = ({ onExport, loading, disabled, testId }) => {
  const handleExport = async () => {
    try {
      const { fileContent, fileContentBlob, encodedFileName } = await onExport();

      if (!fileContent && !fileContentBlob) {
        return;
      }
      const fileBlobToSave = fileContentBlob || new Blob([Buffer.from(fileContent!, 'base64')]);
      saveAs(fileBlobToSave, encodedFileName);
      ToastManager.success('Export successfully completed.');
    } catch {
      ToastManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    }
  };

  return (
    <Popover variant="TOOLTIP" placement="topRight" content="Export to XLS">
      <SecondaryButton
        title="Export XLS"
        testId={testId}
        iconRight={{ name: 'file-export', variant: 'regular' }}
        loading={loading}
        disabled={disabled}
        onClick={handleExport}
      />
    </Popover>
  );
};
