import { ApolloError } from '@apollo/client';
import React from 'react';

import { PaginationDataInput } from '../../../../../graphql';
import { paginationInitialValues } from '../deal-team-list-data-grid/DealTeamListDataGrid.model';
import { Roadshows_DealTeamListQuery, useRoadshows_DealTeamListQuery } from '../graphql';

export type DealTeamPaginatedList = {
  data?: Roadshows_DealTeamListQuery;
  loading: boolean;
  error?: ApolloError;
  setPagination: (pagination: PaginationDataInput) => void;
};

export function useDealTeamPaginatedListQuery(): DealTeamPaginatedList {
  const [pagination, setPagination] = React.useState(paginationInitialValues);
  const { data, loading, error } = useRoadshows_DealTeamListQuery({
    variables: { pagination },
    fetchPolicy: 'cache-and-network',
  });

  return {
    data,
    loading,
    error,
    setPagination,
  };
}
