import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteNonSyndicatePaymentWireMutation,
  SyndicateWires_NonSyndicatePaymentWiresDocument,
  useSyndicateWires_DeleteNonSyndicatePaymentWireMutation,
} from '../graphql';

export type DeleteNonSyndicatePaymentWireMutation =
  SyndicateWires_DeleteNonSyndicatePaymentWireMutation;

export const useDeleteNonSyndicatePaymentWireMutation = () => {
  return useSyndicateWires_DeleteNonSyndicatePaymentWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicatePaymentWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
