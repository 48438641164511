import Loading from '../../../../../common/components/indicators/loading/Loading';
import { OfferingType } from '../../../../../graphql';
import SideBarHeader from '../../../components/side-bar-header/SideBarHeader';
import { useComplianceContext } from '../context/ComplianceContext';
import {
  SCertificatesContentWrapper,
  SComplianceTableHeader,
} from './ComplianceCertificates.styles';
import CertificateTable from './components/CertificateTable';
import ComplianceActionRequired from './components/ComplianceActionRequired';
import ComplianceInfo from './components/ComplianceInfo';

export type Props = {
  offeringId: string;
};

const ComplianceCertificates: React.FC<Props> = ({ offeringId }) => {
  const { canRead, attestation, loading, offeringType } = useComplianceContext().certificates;

  if (loading) {
    return <Loading />;
  }

  if (!canRead || !attestation) {
    return null;
  }

  return (
    <div>
      <ComplianceActionRequired />
      <SideBarHeader title="Certificates" padding="10px 16px 10px 16px" />
      <SCertificatesContentWrapper>
        <ComplianceInfo offeringId={offeringId} />
        {offeringType === OfferingType.Ipo && (
          <SComplianceTableHeader>Current Status</SComplianceTableHeader>
        )}
        <CertificateTable attestation={attestation} offeringType={offeringType!} />
      </SCertificatesContentWrapper>
    </div>
  );
};

export default ComplianceCertificates;
