import React from 'react';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';

const AccountingContext = React.createContext<FinalSettlement_AccountingPartsFragment | undefined>(
  undefined
);

export const useAccountingContext = () => {
  const accounting = React.useContext(AccountingContext);

  if (!accounting) {
    throw new Error('No AccountingContext.Provider found');
  }

  return accounting;
};

type Props = {
  accounting: FinalSettlement_AccountingPartsFragment;
};

export const AccountingContextProvider: React.FC<Props> = ({ accounting, children }) => {
  return <AccountingContext.Provider value={accounting}>{children}</AccountingContext.Provider>;
};
