import {
  ExpandLessIcon,
  ExpandMoreIcon,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from '@cmg/design-system';
import React, { FC, ReactNode } from 'react';

type Props = {
  readonly href?: string;
  readonly selected?: boolean;
  readonly icon?: ReactNode;
  readonly expanded?: boolean;
  readonly onClick?: MenuItemProps<'a'>['onClick'];
};

export const SideNavbarItem: FC<Props> = props => {
  const { children, icon, expanded, ...rest } = props;
  const ExpandIcon = expanded === true ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <MenuItem disableTouchRipple={expanded != null} {...rest} component={Link}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={children} />
      {expanded != null ? ExpandIcon : undefined}
    </MenuItem>
  );
};
