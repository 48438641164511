import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';

const RetailRetentionInfoBox: React.FC = () => {
  const { retailRetention } = useAccountingContext();

  return (
    <AccountingInfoBox gridTemplateColumns="3fr 1.5fr" sourceOfData="Sourced from Order Book">
      <Table.Cell paddingSize="S" withBottomBorder={false}>
        Retail Retention
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForInteger(retailRetention)}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default RetailRetentionInfoBox;
