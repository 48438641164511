import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteDesignationWireMutation,
  SyndicateWires_DesignationWiresDocument,
  useSyndicateWires_DeleteDesignationWireMutation,
} from '../graphql';

export type DeleteDesignationWireMutation = SyndicateWires_DeleteDesignationWireMutation;

export const refetchQueries = () => {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_DesignationWiresDocument];
  return getManyOperationNames(documentNodes);
};

export const useDeleteDesignationWireMutation = () => {
  return useSyndicateWires_DeleteDesignationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
