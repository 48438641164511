import {
  Button,
  CheckCircleIcon,
  Divider,
  LoadingButton,
  Stack,
  Typography,
} from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { Link } from 'react-router-dom';

import { OfferingStatus, OrderBookState } from '../../../../graphql';
import { DemandGridV2Switch } from '../../components/demand-grid-v2-switch/DemandGridV2Switch';
import { OrderBookStatusDropdownButton } from '../../institutional-demand/components/order-book-status-dropdown-button/OrderBookStatusDropdownButton';
import ExportButton from '../export/ExportButton';
import { ActionProps } from '../hooks/useSubNavActions';
import { RecentUpdates } from '../recent-updates/RecentUpdates';

export const SubNavActionButtons: React.FC<ActionProps> = ({
  offeringId,
  offeringStatus,
  orderBookState,
  pricingCurrencyCode,
  isFinalAllocationSetReleased,
  isUpdateOrderBookStatusLoading,
  isExportInstitutionalDemandLoading,
  isExportProspectusEmailsLoading,
  isExportRevisionHistoryLoading,
  isReleaseFinalAllocationSetLoading,
  isSaveAllocationsLoading,
  isAllocationsUnsaved,
  canUpdateIndications,
  canManageAllocations,
  isDemandConfigDefined,
  onReleaseFinalAllocations,
  onUpdateOrderBookStatus,
  onExportInstitutionalDemand,
  onExportProspectusEmails,
  onExportRevisionHistory,
  newIndicationRoutePath,
  onDiscardAllocationChanges,
  onSaveAllocations,
  orderBookSalesState,
  onUpdateOrderBookSalesState,
}) => {
  const isReleaseAllocationsDisabled =
    orderBookState !== OrderBookState.Closed || offeringStatus !== OfferingStatus.Priced;

  const isExportInstitutionalDemandDisabled =
    !isDemandConfigDefined || isExportInstitutionalDemandLoading;

  const isUpdateAllocationActionsPostReleaseVisible =
    canManageAllocations && isFinalAllocationSetReleased && isAllocationsUnsaved;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <DemandGridV2Switch />

      <RecentUpdates offeringId={offeringId} pricingCurrencyCode={pricingCurrencyCode} />

      <ExportButton
        isExportInstitutionalDemandDisabled={isExportInstitutionalDemandDisabled}
        loading={
          isExportInstitutionalDemandLoading ||
          isExportProspectusEmailsLoading ||
          isExportRevisionHistoryLoading
        }
        onExportInstitutionalDemand={onExportInstitutionalDemand}
        onExportProspectusEmails={onExportProspectusEmails}
        onExportRevisionHistory={onExportRevisionHistory}
      />

      {canUpdateIndications && (
        <Button variant="contained" component={Link} to={newIndicationRoutePath}>
          New Indication
        </Button>
      )}
      <Divider orientation="vertical" flexItem sx={{ margin: theme => `${theme.spacing(1)} 0` }} />
      <OrderBookStatusDropdownButton
        offeringId={offeringId}
        onUpdateOrderBookStatus={onUpdateOrderBookStatus}
        orderBookState={orderBookState}
        orderBookSalesState={orderBookSalesState}
        onUpdateOrderBookSalesState={onUpdateOrderBookSalesState}
        offeringStatus={offeringStatus}
      />
      {isUpdateAllocationActionsPostReleaseVisible && (
        <React.Fragment>
          <Button
            variant="outlined"
            onClick={onDiscardAllocationChanges}
            disabled={isSaveAllocationsLoading}
          >
            Discard Changes
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onSaveAllocations}
            loading={isSaveAllocationsLoading}
          >
            Save Allocations
          </LoadingButton>
        </React.Fragment>
      )}
      {canManageAllocations && !isFinalAllocationSetReleased && (
        <LoadingButton
          variant="outlined"
          data-testid={xcSelectors.orderBookHeaderReleaseFinalAllocationsButton.testId}
          disabled={isReleaseAllocationsDisabled}
          loading={isReleaseFinalAllocationSetLoading}
          onClick={onReleaseFinalAllocations}
        >
          Release Allocations
        </LoadingButton>
      )}
      {isFinalAllocationSetReleased && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          data-test-id={xcSelectors.orderBookHeaderAllocationsReleasedStatus.testId}
        >
          <CheckCircleIcon fontSize="small" color="success" />
          <Typography>Final Allocations Released</Typography>
        </Stack>
      )}
    </Stack>
  );
};
