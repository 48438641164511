import styled from 'styled-components/macro';

export const SAllocationSetTotalsCellWrapper = styled.div<{
  alignItems?: 'flex-end' | 'center';
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  line-height: 36px;
`;
