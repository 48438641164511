import { Box, Stack, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  alertMessages: string[];
  title: React.ReactNode;
}>;

export const AlertGroup: React.FC<Props> = ({ alertMessages, title }) => {
  if (!alertMessages.length) {
    return null;
  }

  return (
    <Box>
      <Stack>
        <Typography variant="tooltip" fontWeight="bold">
          {title}
        </Typography>
        {alertMessages.map(alert => (
          <Typography variant="tooltip" key={alert}>
            {alert}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};
