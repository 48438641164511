import { UUID } from '@cmg/common';
import React from 'react';

import { SyndicateWiresManagerRole } from '../../../../../../graphql';
import { useSyndicateWires_SyndicateWiresManagerListQuery } from '../../graphql';
import { managersToRoleGroupedEntries } from '../navigation/managers-side-nav/ManagersNavigation.model';
import {
  ManagerEconomicSharesTable,
  ManagerEconomicsRoleGroup,
} from './ManagerEconomicSharesTable';

export type Props = {
  offeringId: UUID;
  onDataLoaded?: (isSuccess: boolean) => void;
};

const ManagerEconomics: React.FC<Props> = ({ offeringId, onDataLoaded }) => {
  const { data, loading, error, refetch } = useSyndicateWires_SyndicateWiresManagerListQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const groupedManagers = React.useMemo<ManagerEconomicsRoleGroup[]>(() => {
    const filteredManagers =
      data?.syndicateWires.managers.filter(
        manager => manager.role !== SyndicateWiresManagerRole.SellingGroupMember
      ) || [];

    return managersToRoleGroupedEntries(filteredManagers).map(([role, managers]) => [
      role,
      managers.map(manager => ({
        shares: manager.economics,
        cmgEntityKey: manager.cmgEntityKey,
        firmName: manager.firmName,
      })),
    ]);
  }, [data]);

  return (
    <ManagerEconomicSharesTable
      managerGroups={groupedManagers}
      refetch={refetch}
      error={error}
      loading={loading}
      onDataLoaded={onDataLoaded}
    />
  );
};

export default ManagerEconomics;
