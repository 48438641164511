import styled from 'styled-components/macro';

export const SFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SLeftSideControls = styled.div`
  display: flex;
`;

export const SRightSideControls = styled.div`
  text-align: right;
`;
