import { DefaultTheme } from 'styled-components/macro';

import { AttestationFormStatus } from '../../../graphql';

export const AttestationFormStatusDisplayName: { [key in AttestationFormStatus]: string } = {
  [AttestationFormStatus.Blanket]: 'Blanket',
  [AttestationFormStatus.Expired]: 'Expired',
  [AttestationFormStatus.NotOnFile]: 'Not on file',
  [AttestationFormStatus.Partial]: 'Partial',
};

export const isAttestationStatusValid = (status: AttestationFormStatus) => {
  return status === AttestationFormStatus.Blanket || status === AttestationFormStatus.Partial;
};

export const getAttestationStatusColor = (status: AttestationFormStatus, theme: DefaultTheme) => {
  if (isAttestationStatusValid(status)) {
    return theme.designSystem.colors.green['300'];
  }
  return theme.designSystem.colors.red['300'];
};
