import {
  DatePickerField,
  FormLabel,
  MultiSelectField,
  SwitchField,
  TextInputField,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { RoadshowFilterInput } from '../../../../../graphql';
import { DEBOUNCE_TIMEOUT } from '../../../../constants';
import { filtersInitialValues, filtersSchema, roadshowTypeOptions } from './Filters.model';
import { SFiltersWrapper, SSwitchWrapper } from './Filters.styles';
import { DateSuffix, SearchSuffix } from './suffix/Suffix';

export type Props = {
  setFilter: (filteredValues: RoadshowFilterInput) => void;
};

const Filters: React.FC<Props> = ({ setFilter }) => {
  const formik = useFormik({
    validationSchema: filtersSchema,
    initialValues: filtersInitialValues,
    onSubmit: values => {
      const roadshowTypes = isEmpty(values.roadshowTypes)
        ? filtersInitialValues.roadshowTypes
        : values.roadshowTypes;

      setFilter({ ...values, roadshowTypes });
    },
  });

  const debouncedSubmit = useDebouncedCallback(() => formik.submitForm(), DEBOUNCE_TIMEOUT);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form data-test-id="filters">
          <SFiltersWrapper>
            <TextInputField
              name="searchText"
              onChange={() => debouncedSubmit()}
              suffix={<SearchSuffix />}
              placeholder="Roadshow..."
            />
            <MultiSelectField
              name="roadshowTypes"
              options={roadshowTypeOptions}
              onChange={() => formik.handleSubmit()}
              placeholder="Select type..."
            />
            <TextInputField
              name="companyName"
              onChange={() => debouncedSubmit()}
              suffix={<SearchSuffix />}
              placeholder="Company name..."
            />
            <DatePickerField
              name="startDate"
              onChange={() => formik.handleSubmit()}
              suffix={<DateSuffix />}
              placeholder="Start day after..."
            />
            <DatePickerField
              name="endDate"
              onChange={() => formik.handleSubmit()}
              suffix={<DateSuffix />}
              placeholder="End day before..."
            />
            {/* TODO: display once user can archive roadshows */}
            {/* eslint-disable-next-line sonarjs/no-redundant-boolean */}
            {false && (
              <SSwitchWrapper>
                <FormLabel>Show Archived Roadshows</FormLabel>
                <SwitchField name="showArchived" onChange={() => formik.handleSubmit()} />
              </SSwitchWrapper>
            )}
          </SFiltersWrapper>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Filters;
