import { UUID } from '@cmg/common';
import maxBy from 'lodash/maxBy';

import {
  OrderBook_TotalRetailSummaryItemDemandPartsFragment,
  OrderBook_TotalRetailSummaryItemManagerPartsFragment,
  OrderBook_TotalRetailSummaryItemRetentionPartsFragment,
} from '../../../graphql';

type SummaryItem =
  | OrderBook_TotalRetailSummaryItemDemandPartsFragment
  | OrderBook_TotalRetailSummaryItemRetentionPartsFragment;

export const getLatestSummaryItem = (...items: SummaryItem[]) => {
  const definedItems = items.filter(({ modifiedAt }) => !!modifiedAt) as SummaryItem[];

  if (definedItems.length > 0) {
    return maxBy(definedItems, ({ modifiedAt }) => new Date(modifiedAt!));
  }
};

export const getManagerName = (
  managers: OrderBook_TotalRetailSummaryItemManagerPartsFragment[],
  managerCmgEntityKey?: UUID | null
) => {
  return managers.find(({ cmgEntityKey }) => cmgEntityKey === managerCmgEntityKey)?.name;
};
