import { ToastManager, UUID } from '@cmg/common';
import { AgGridEvent } from 'ag-grid-community';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Route } from 'react-router-dom';

import Banner from '../../../common/components/indicators/banner/Banner';
import { useGridReady } from '../../../common/hooks/useGridReady';
import { useGridRefresh } from '../../../common/hooks/useGridRefresh';
import routeFactory from '../../../common/util/routeFactory';
import { InternalSalesCreditReleaseStatus } from '../../../graphql';
import { useSalesCreditsContext } from '../context/SalesCreditsContext';
import {
  SalesCredits_SalesCreditGridPartsFragment,
  SalesCredits_SalesCreditItemPartsFragment,
} from '../graphql';
import EditingOutOfBalanceAlert from './alerts/EditingOutOfBalanceAlert';
import FormNotValidAlert from './alerts/FormNotValidAlert';
import OutOfBalanceAlert from './alerts/OutOfBalanceAlert';
import StatusNotChangedAlert from './alerts/StatusNotChangedAlert';
import SalesCreditsBankInvestorKeyReviewModal from './bank-investor-key-review-modal/SalesCreditsBankInvestorKeyReviewModal';
import SalesCreditsDetailsHeader from './details-header/SalesCreditsDetailsHeader';
import { useSalesCredits_UpdateSalesCreditsAmountMutation } from './graphql';
import SalesCreditsGrid from './grid/SalesCreditsGrid';
import { salesCreditsCols } from './grid/SalesCreditsGrid.model';
import SalesCreditsGridActions from './grid-actions/SalesCreditsGridActions';
import {
  ReleaseSalesCreditsFunction,
  UpdateSalesCreditsStatusFunction,
} from './grid-actions/SalesCreditsGridActions.model';
import SalesCreditsGridFilters from './grid-filters/SalesCreditsGridFilters';
import { SalesCreditsGridFilter } from './grid-filters/SalesCreditsGridFilters.model';
import SalesCreditsReleaseHistoryRoute, {
  SalesCreditsInvestor,
} from './history/SalesCreditsReleaseHistoryRoute';
import { useExportSalesCredits } from './hooks/useExportSalesCredits';
import SalesCreditsHouseAccountsModal from './house-accounts-modal/SalesCreditsHouseAccountsModal';
import SalesCreditsOutOfBalanceModal from './out-of-balance-modal/SalesCreditsOutOfBalanceModal';
import {
  areSalesCreditsOutOfBalance,
  formValuesToPayload,
  getInitialValues,
  getNumOfRowsNeedingAction,
  SalesCreditsFormValues,
  useAreSalesCreditsOutOfBalance,
  useFilterRows,
  validationSchema,
} from './SalesCreditsDetailsPanel.model';
import { SBannersWrapper, StyledForm, SWrapper } from './SalesCreditsDetailsPanel.styles';

export type Props = {
  offeringIssuerName: string;
  salesCreditsData: SalesCredits_SalesCreditGridPartsFragment;
  offeringId: UUID;
  onRelease: (investorCmgEntityKeys: UUID[]) => void;
  isReleasing: boolean;
  onUpdateStatus: (status: InternalSalesCreditReleaseStatus, salesCreditIds: string[]) => void;
  isUpdatingStatus: boolean;
};

const SalesCreditsDetailsPanel: React.FC<Props> = ({
  offeringIssuerName,
  salesCreditsData: { salesCredits, sellingConcession, settlementCurrency },
  offeringId,
  onRelease,
  isReleasing,
  onUpdateStatus,
  isUpdatingStatus,
}) => {
  const [gridFilter, setGridFilter] = React.useState<SalesCreditsGridFilter>();
  const [selectedRows, setSelectedRows] = React.useState<
    SalesCredits_SalesCreditItemPartsFragment[]
  >([]);
  const [unchangedInvestors, setUnchangedInvestors] = React.useState<
    SalesCredits_SalesCreditItemPartsFragment[]
  >([]);
  const [isHouseAccountsModalOpen, setIsHouseAccountsModalOpen] = React.useState(false);
  const [isOutOfBalanceModalOpen, setIsOutOfBalanceModalOpen] = React.useState(false);
  const [isEditingOutOfBalanceAlertOpen, setIsEditingOutOfBalanceAlertOpen] = React.useState(false);
  const [isInvalidFormAlertOpen, setIsInvalidFormAlertOpen] = React.useState(false);
  const [reviewedItem, setReviewedItem] =
    React.useState<SalesCredits_SalesCreditItemPartsFragment | null>(null);
  const filteredRows = useFilterRows(salesCredits, gridFilter);

  const gridRef = React.useRef<AgGridEvent | undefined>();
  const onGridReady = useGridReady(gridRef);

  const exportSalesCredits = useExportSalesCredits(offeringIssuerName);

  useGridRefresh({ gridApi: gridRef.current?.api, rows: filteredRows });

  const numOfRowsNeedingAction = getNumOfRowsNeedingAction(salesCredits);
  const isOutOfBalance = useAreSalesCreditsOutOfBalance(sellingConcession, salesCredits);

  const [updateSalesCreditsAmount, { loading }] =
    useSalesCredits_UpdateSalesCreditsAmountMutation();

  const { isEditing, setIsEditing } = useSalesCreditsContext();

  const formik = useFormik<SalesCreditsFormValues>({
    initialValues: getInitialValues(salesCredits),
    validationSchema: validationSchema(salesCredits),
    onSubmit: async values => {
      try {
        await updateSalesCreditsAmount({
          variables: {
            offeringId,
            payload: formValuesToPayload(values),
          },
        });
        setIsEditing(false);
        ToastManager.success('Sales Credits updated');
      } catch {
        ToastManager.error('Failed to update Sales Credits');
      }
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
  });
  const { values, handleSubmit, resetForm, isValid } = formik;

  const handleCloseStatusNotChangedAlert = () => {
    setUnchangedInvestors([]);
  };

  const handleCloseHouseAccountsModal = () => {
    setIsHouseAccountsModalOpen(false);
  };
  const handleOpenHouseAccountsModal = () => {
    setIsHouseAccountsModalOpen(true);
  };

  const handleCloseOutOfBalanceModal = () => {
    setIsOutOfBalanceModalOpen(false);
  };

  const handleCloseEditingOutOfBalanceAlert = () => {
    setIsEditingOutOfBalanceAlertOpen(false);
  };

  const handleCloseFormIsNotValidAlert = () => {
    setIsInvalidFormAlertOpen(false);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleCancelEditing = () => {
    setIsEditing(false);
    setIsEditingOutOfBalanceAlertOpen(false);
    setIsInvalidFormAlertOpen(false);
    resetForm();
  };

  const handleOpenReviewModal = (reviewedItem: SalesCredits_SalesCreditItemPartsFragment) => {
    setReviewedItem(reviewedItem);
  };
  const handleCloseReviewModal = () => {
    setReviewedItem(null);
  };

  React.useEffect(() => {
    if (isInvalidFormAlertOpen && isValid) {
      setIsInvalidFormAlertOpen(false);
    }
  }, [isInvalidFormAlertOpen, isValid]);

  const investors = React.useMemo(
    () =>
      salesCredits.map<SalesCreditsInvestor>(item => ({
        cmgEntityKey: item.investorFirmCmgEntityKey,
        name: item.investorFirmName,
        firmId: item.firmId!,
      })),
    [salesCredits]
  );

  const handleRelease = React.useCallback<ReleaseSalesCreditsFunction>(
    (investorFirmCmgEntityKeys, unchangedRows) => {
      if (isOutOfBalance) {
        setIsOutOfBalanceModalOpen(true);
      } else {
        onRelease(investorFirmCmgEntityKeys);
        unchangedRows && setUnchangedInvestors(unchangedRows);
      }

      gridRef.current?.api.deselectAll();
      gridRef.current?.api.refreshCells({
        force: true,
        columns: [salesCreditsCols.selected],
      });
    },
    [isOutOfBalance, onRelease, gridRef]
  );

  const handleUpdateStatus = React.useCallback<UpdateSalesCreditsStatusFunction>(
    (status, salesCreditIds, unchangedRows) => {
      if (isOutOfBalance) {
        setIsOutOfBalanceModalOpen(true);
      } else {
        onUpdateStatus(status, salesCreditIds);
        unchangedRows && setUnchangedInvestors(unchangedRows);
      }

      gridRef.current?.api.deselectAll();
      gridRef.current?.api.refreshCells({
        force: true,
        columns: [salesCreditsCols.selected],
      });
    },
    [isOutOfBalance, onUpdateStatus, gridRef]
  );

  const handleExport = () => {
    try {
      exportSalesCredits(gridRef.current?.api);
      ToastManager.success('Successfully exported sales credits');
    } catch {
      ToastManager.error(
        'An error has occurred while trying to export your data. Please try again later.'
      );
    }
  };

  const handleSave = async () => {
    if (!isValid) {
      setIsInvalidFormAlertOpen(true);
      return;
    }
    setIsInvalidFormAlertOpen(false);

    if (
      areSalesCreditsOutOfBalance(
        sellingConcession,
        Object.values(values.salesCredits),
        salesCredits.map(({ designationShares }) => designationShares)
      )
    ) {
      setIsEditingOutOfBalanceAlertOpen(true);
      return;
    }
    setIsEditingOutOfBalanceAlertOpen(false);

    await handleSubmit();
  };

  return (
    <SWrapper direction="column">
      <SalesCreditsDetailsHeader
        numOfRowsNeedingAction={numOfRowsNeedingAction}
        sellingConcession={sellingConcession}
        settlementCurrency={settlementCurrency}
      />
      <SBannersWrapper direction="column" gap={12}>
        {numOfRowsNeedingAction === 0 && !isOutOfBalance && (
          <Banner variant="success" withoutMargin>
            No further action needed at this time
          </Banner>
        )}
        {isOutOfBalance && !isEditing && <OutOfBalanceAlert onEdit={handleStartEditing} />}
        {isEditingOutOfBalanceAlertOpen && (
          <EditingOutOfBalanceAlert onDismiss={handleCloseEditingOutOfBalanceAlert} />
        )}
        {isInvalidFormAlertOpen && <FormNotValidAlert onDismiss={handleCloseFormIsNotValidAlert} />}
        {unchangedInvestors.length > 0 && (
          <StatusNotChangedAlert
            unchangedInvestors={unchangedInvestors}
            onDismiss={handleCloseStatusNotChangedAlert}
          />
        )}
      </SBannersWrapper>

      {selectedRows.length === 0 && (
        <SalesCreditsGridFilters
          onSetGridFilter={setGridFilter}
          onEdit={handleStartEditing}
          onCancel={handleCancelEditing}
          onSave={handleSave}
          onExport={handleExport}
          onOpenHouseAccountsModal={handleOpenHouseAccountsModal}
          isEditing={isEditing}
          isLoading={loading}
        />
      )}
      {selectedRows.length > 0 && (
        <SalesCreditsGridActions
          selectedRows={selectedRows}
          onRelease={handleRelease}
          isReleasing={isReleasing}
          onUpdateStatus={handleUpdateStatus}
          isUpdatingStatus={isUpdatingStatus}
        />
      )}
      <FormikProvider value={formik}>
        <StyledForm>
          <SalesCreditsGrid
            onGridReady={onGridReady}
            rows={filteredRows}
            offeringId={offeringId}
            onSelectionChange={setSelectedRows}
            onRelease={handleRelease}
            onUpdateStatus={handleUpdateStatus}
            onOpenReviewModal={handleOpenReviewModal}
            sellingConcession={sellingConcession}
          />
        </StyledForm>
      </FormikProvider>
      <Route
        path={routeFactory.salesCreditsHistory.routePath}
        render={routeProps => (
          <SalesCreditsReleaseHistoryRoute {...routeProps} investors={investors} />
        )}
      />

      <SalesCreditsHouseAccountsModal
        show={isHouseAccountsModalOpen}
        onHide={handleCloseHouseAccountsModal}
      />
      <SalesCreditsOutOfBalanceModal
        show={isOutOfBalanceModalOpen}
        onHide={handleCloseOutOfBalanceModal}
        onEdit={handleStartEditing}
      />
      <SalesCreditsBankInvestorKeyReviewModal
        offeringId={offeringId}
        reviewedItem={reviewedItem}
        onHide={handleCloseReviewModal}
      />
    </SWrapper>
  );
};

export default SalesCreditsDetailsPanel;
