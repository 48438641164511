import { urlUtil, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { SidePanelTitle } from '../../components/side-bar/components/design-system';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../hooks/useIdentifyOrderBookPersona';
import IndicationComplianceWarningWrapper from './compliance/IndicationComplianceWarningWrapper';
import IndicationFormEmptyState from './components/indication-form-empty-state/IndicationFormEmptyState';
import {
  IndicationActivitySalesAndTradingContextProvider,
  useIndicationActivitySalesAndTradingContext,
} from './context/IndicationActivitySalesAndTradingContext';
import {
  IndicationActivitySyndicateContextProvider,
  useIndicationActivitySyndicateContext,
} from './context/IndicationActivitySyndicateContext';
import IndicationActivitySalesAndTrading from './IndicationActivitySalesAndTrading';
import IndicationActivitySyndicate from './IndicationActivitySyndicate';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const IndicationActivitySyndicateComponent: React.FC = () => {
  const { complianceWarning, loading, basicPublishedOffering } =
    useIndicationActivitySyndicateContext();

  return (
    <IndicationComplianceWarningWrapper
      complianceWarning={complianceWarning}
      indicationExists={!!basicPublishedOffering?.orderBook?.institutionalIndication}
      loading={loading}
    >
      <IndicationActivitySyndicate />
    </IndicationComplianceWarningWrapper>
  );
};

const IndicationActivitySalesAndTradingComponent: React.FC = () => {
  const { complianceWarning, loading, coveredIndication } =
    useIndicationActivitySalesAndTradingContext();

  return (
    <IndicationComplianceWarningWrapper
      complianceWarning={complianceWarning}
      indicationExists={!!coveredIndication}
      loading={loading}
    >
      <IndicationActivitySalesAndTrading />
    </IndicationComplianceWarningWrapper>
  );
};

const IndicationActivitySellSideRoute: React.FC<Props> = ({ match }) => {
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const { offeringId } = match.params;
  const { type } = useIdentifyOrderBookPersona({ offeringId });
  const { search } = useLocation();
  const indicationId = urlUtil.queryParse<{ indicationId: string }>(search)?.indicationId;
  const { isFiledOffering } = useSupersededOfferingContext();

  if (type === OrderBookPersonaType.SYNDICATE) {
    return (
      <IndicationActivitySyndicateContextProvider
        offeringId={offeringId}
        cmgEntityKey={cmgEntityKey}
        indicationId={indicationId}
      >
        <SidePanelTitle>Communicated Demand</SidePanelTitle>
        {!isFiledOffering ? <IndicationActivitySyndicateComponent /> : <IndicationFormEmptyState />}
      </IndicationActivitySyndicateContextProvider>
    );
  }

  if (type === OrderBookPersonaType.SALES_AND_TRADING) {
    return (
      <IndicationActivitySalesAndTradingContextProvider
        offeringId={offeringId}
        cmgEntityKey={cmgEntityKey}
        indicationId={indicationId}
      >
        <SidePanelTitle>Communicated Demand</SidePanelTitle>
        {!isFiledOffering ? (
          <IndicationActivitySalesAndTradingComponent />
        ) : (
          <IndicationFormEmptyState />
        )}
      </IndicationActivitySalesAndTradingContextProvider>
    );
  }

  return null;
};

export default IndicationActivitySellSideRoute;
