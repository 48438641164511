import React from 'react';

import { SPinnedRowText } from '../../../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/InstitutionalDemandGridColumns.styles';

export type Props = Readonly<{
  value: number | null;
}>;

const TotalInvestorNameRenderer: React.FC<Props> = ({ value }) => {
  return <SPinnedRowText>Total {value}</SPinnedRowText>;
};

export default TotalInvestorNameRenderer;
