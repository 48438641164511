import { useAuth, useCheckAccountType } from '@cmg/auth';
import { offeringUtil } from '@cmg/common';
import React from 'react';

import { multiFilterArray } from '../../../common/util/multiFilterArray';
import { getOfferingTypeFilterCondition } from '../../../common/util/offering/offering-type.util';
import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
  ManagerProfile,
  ManagerRole,
  OfferingType,
} from '../../../graphql';
import { SpacFilterOption } from '../components/calendar-filters/components/spac-filter/SpacFilter';
import { CalendarGridOffering } from '../components/calendar-grid/CalendarGrid.model';

export type CalendarFilters = {
  issuerName: string | null;
  issuerSector: NonNullable<CalendarGridOffering['issuerSector']>[];
  type: string[];
  isSpac: SpacFilterOption;
  leftLeadFirmName: NonNullable<CalendarGridOffering['leftLeadFirmName']>[];
  underwriters: NonNullable<ManagerProfile['cmgEntityKey']>[];
  isUnderwriterOnDeal: boolean;
  includeTestOfferings: boolean;
  country: string[];
};

type FilterConditions<TCalendarOffering> = {
  [key in keyof CalendarFilters]: (offering: TCalendarOffering) => boolean;
};

type UseFiltersParams<TCalendarOffering> = {
  sourceOfferings?: readonly TCalendarOffering[];
};

/**
 * useFilters hook used within each calendar tab and handles local filters state
 */
function useFilters<TCalendarOffering extends CalendarGridOffering = CalendarGridOffering>({
  sourceOfferings = [],
}: UseFiltersParams<TCalendarOffering>) {
  const [filters, setFilters] = React.useState<CalendarFilters>({
    issuerName: null,
    issuerSector: [],
    type: [],
    isSpac: SpacFilterOption.INCLUDE,
    leftLeadFirmName: [],
    underwriters: [],
    isUnderwriterOnDeal: false,
    includeTestOfferings: false,
    country: [],
  });
  const { oidcUserCmgEntityKey } = useAuth();
  const isBuySide = useCheckAccountType('BUY_SIDE');

  const filterConditions = React.useMemo<FilterConditions<TCalendarOffering>>(
    () => getFilterConditions(filters, oidcUserCmgEntityKey, isBuySide),
    [filters, oidcUserCmgEntityKey, isBuySide]
  );

  const filteredData = React.useMemo(
    () => (sourceOfferings.length ? multiFilterArray(sourceOfferings, filterConditions) : []),
    [sourceOfferings, filterConditions]
  );

  return { filters, setFilters, filterConditions, filteredData };
}

/**
 * Given filter settings, tests whether an offering satisfies a specific filter.
 *
 * Exported for testing purposes.
 */
export function getFilterConditions(
  filters: CalendarFilters,
  oidcUserCmgEntityKey: string | null,
  isBuySide: boolean
): FilterConditions<CalendarGridOffering> {
  return {
    issuerName: offering =>
      filters.issuerName
        ? (offering.issuerName ?? '').toLowerCase().includes(filters.issuerName.toLowerCase())
        : true,
    issuerSector: offering => {
      if (!filters.issuerSector.length) {
        return true;
      }

      // Disallow if we are filtering by issuerSector but it's not set on the offering
      if (!offering.issuerSector) {
        return false;
      }

      return filters.issuerSector.includes(offering.issuerSector);
    },
    type: offering => {
      const { type, pricingInstrumentCountryCode } = offering;
      return getOfferingTypeFilterCondition({
        offeringType: type,
        filters: filters.type,
        pricingInstrumentCountryCode: pricingInstrumentCountryCode,
      });
    },
    isSpac: offering => {
      if (filters.isSpac === SpacFilterOption.ONLY) {
        return offering.type === OfferingType.IpoSpac;
      }

      if (filters.isSpac === SpacFilterOption.EXCLUDE) {
        return offering.type !== OfferingType.IpoSpac;
      }

      return true;
    },
    leftLeadFirmName: offering => {
      if (!filters.leftLeadFirmName.length) {
        return true;
      }

      // Disallow if we are filtering by leftLeadFirmName but it's not set on the offering
      if (!offering.leftLeadFirmName) {
        return false;
      }

      return filters.leftLeadFirmName.includes(offering.leftLeadFirmName);
    },
    underwriters: offering => {
      if (!filters.underwriters.length) {
        return true;
      }

      const offeringUnderwriterKeys = offering.managers
        .filter(manager => manager.role !== ManagerRole.SellingGroupMember)
        .map(({ cmgEntityKey: underwriterKey }) => underwriterKey);

      return filters.underwriters.some(filterUnderwriterKey =>
        offeringUnderwriterKeys.includes(filterUnderwriterKey)
      );
    },
    isUnderwriterOnDeal: offering => {
      // when the filter is set to false we show all
      if (!filters.isUnderwriterOnDeal) {
        return true;
      }

      if (!isBuySide) {
        if (!oidcUserCmgEntityKey) {
          return false;
        }

        // when the filter is set to true we only show the deals the firm is an underwriter on
        return offering.managers.some(manager => manager.cmgEntityKey === oidcUserCmgEntityKey);
      }

      const isFinalAllocationOnOffering =
        'finalAllocation' in offering && !!offering.finalAllocation;
      const isActiveExternalIndicationOnOffering =
        'myInstitutionalIndication' in offering &&
        offering.myInstitutionalIndication?.status === IndicationStatus.Active;
      const isExternalPassOnOffering =
        'myInstitutionalIndication' in offering &&
        offering.myInstitutionalIndication?.type === InstitutionalIndicationOrderType.Pass;
      const hasMdlIndication =
        'mdlIndications' in offering && !!offering.mdlIndications?.indications.length;

      return (
        isFinalAllocationOnOffering ||
        isActiveExternalIndicationOnOffering ||
        isExternalPassOnOffering ||
        hasMdlIndication
      );
    },

    country: offering => {
      if (!filters.country.length) {
        return true;
      }

      return offering?.pricingInstrumentCountryCode
        ? filters.country.includes(offering.pricingInstrumentCountryCode)
        : false;
    },

    includeTestOfferings: offering => {
      if (filters.includeTestOfferings) {
        return true;
      }

      return !offeringUtil.isTestOffering(offering?.pricingInstrumentStockSymbol);
    },
  };
}

export default useFilters;
