import { NumericInputField } from '@cmg/common';
import React from 'react';

export interface Props {
  column: { colId: string };
  disabled?: boolean;
  data: {
    id: string;
  };
  precision?: number;
}

export const NumericInputRenderer: React.FC<Props> = ({
  data,
  column,
  disabled = false,
  precision = 4,
}) => {
  const fieldName = 'rows'.concat(`[${data.id}]`).concat(column.colId);
  return (
    <NumericInputField
      fullWidth
      precision={precision}
      name={fieldName}
      disabled={disabled}
      maxLength={13}
    />
  );
};
