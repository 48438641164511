/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type Roadshows_EnsureMyOrganizationIsUpToDateQuery = {
  readonly __typename?: 'Query';
  readonly ensureMyOrganizationIsUpToDate: boolean;
};

export const Roadshows_EnsureMyOrganizationIsUpToDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_EnsureMyOrganizationIsUpToDate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ensureMyOrganizationIsUpToDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_EnsureMyOrganizationIsUpToDateQuery__
 *
 * To run a query within a React component, call `useRoadshows_EnsureMyOrganizationIsUpToDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_EnsureMyOrganizationIsUpToDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_EnsureMyOrganizationIsUpToDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoadshows_EnsureMyOrganizationIsUpToDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Roadshows_EnsureMyOrganizationIsUpToDateQuery,
    Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_EnsureMyOrganizationIsUpToDateQuery,
    Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables
  >(Roadshows_EnsureMyOrganizationIsUpToDateDocument, options);
}
export function useRoadshows_EnsureMyOrganizationIsUpToDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_EnsureMyOrganizationIsUpToDateQuery,
    Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_EnsureMyOrganizationIsUpToDateQuery,
    Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables
  >(Roadshows_EnsureMyOrganizationIsUpToDateDocument, options);
}
export type Roadshows_EnsureMyOrganizationIsUpToDateQueryHookResult = ReturnType<
  typeof useRoadshows_EnsureMyOrganizationIsUpToDateQuery
>;
export type Roadshows_EnsureMyOrganizationIsUpToDateLazyQueryHookResult = ReturnType<
  typeof useRoadshows_EnsureMyOrganizationIsUpToDateLazyQuery
>;
export type Roadshows_EnsureMyOrganizationIsUpToDateQueryResult = Apollo.QueryResult<
  Roadshows_EnsureMyOrganizationIsUpToDateQuery,
  Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRoadshows_EnsureMyOrganizationIsUpToDateMockResponse = makeMockResponse<
  Roadshows_EnsureMyOrganizationIsUpToDateQueryVariables,
  Roadshows_EnsureMyOrganizationIsUpToDateQuery
>(Roadshows_EnsureMyOrganizationIsUpToDateDocument);
