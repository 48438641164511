import styled from 'styled-components/macro';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import Modal from '../../../../../../common/components/overlays/modal/Modal';

export const STableWrapper = styled.div`
  height: 350px;
  overflow: auto;
`;

export const StyledLoading = styled(Loading)`
  height: 100%;
  display: flex;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;

  & > * {
    margin: 0 4px;
  }
`;

export const SEmptyFiller = styled.span`
  flex-grow: 1;
`;
