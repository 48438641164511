import { UUID } from '@cmg/common';

export type SearchValues = {
  fullName: string | null;
  firmCmgEntityKey: string | null;
  department: string | null;
};

export const initialValues: SearchValues = {
  fullName: null,
  firmCmgEntityKey: null,
  department: null,
};

export type SelectedDealTeamMember = {
  id: UUID;
  fullName: string;
  firmName: string;
};
