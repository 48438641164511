/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHistory_BasicPublishedOfferingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type UpdateHistory_BasicPublishedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly pricingCurrencyCode: string;
    readonly securityType: Types.SecurityType;
  };
};

export type UpdateHistory_CoveredIndicationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type UpdateHistory_CoveredIndicationQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndicationByCmgEntityKey?: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
  } | null;
};

export type UpdateHistory_IndicationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type UpdateHistory_IndicationQuery = {
  readonly __typename?: 'Query';
  readonly institutionalIndication?: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
  } | null;
};

export const UpdateHistory_BasicPublishedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_BasicPublishedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_BasicPublishedOfferingQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_BasicPublishedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_BasicPublishedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_BasicPublishedOfferingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateHistory_BasicPublishedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_BasicPublishedOfferingQuery,
    UpdateHistory_BasicPublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateHistory_BasicPublishedOfferingQuery,
    UpdateHistory_BasicPublishedOfferingQueryVariables
  >(UpdateHistory_BasicPublishedOfferingDocument, options);
}
export function useUpdateHistory_BasicPublishedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_BasicPublishedOfferingQuery,
    UpdateHistory_BasicPublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateHistory_BasicPublishedOfferingQuery,
    UpdateHistory_BasicPublishedOfferingQueryVariables
  >(UpdateHistory_BasicPublishedOfferingDocument, options);
}
export type UpdateHistory_BasicPublishedOfferingQueryHookResult = ReturnType<
  typeof useUpdateHistory_BasicPublishedOfferingQuery
>;
export type UpdateHistory_BasicPublishedOfferingLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_BasicPublishedOfferingLazyQuery
>;
export type UpdateHistory_BasicPublishedOfferingQueryResult = Apollo.QueryResult<
  UpdateHistory_BasicPublishedOfferingQuery,
  UpdateHistory_BasicPublishedOfferingQueryVariables
>;
export const UpdateHistory_CoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_CoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndicationByCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_CoveredIndicationQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_CoveredIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_CoveredIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_CoveredIndicationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useUpdateHistory_CoveredIndicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_CoveredIndicationQuery,
    UpdateHistory_CoveredIndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateHistory_CoveredIndicationQuery,
    UpdateHistory_CoveredIndicationQueryVariables
  >(UpdateHistory_CoveredIndicationDocument, options);
}
export function useUpdateHistory_CoveredIndicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_CoveredIndicationQuery,
    UpdateHistory_CoveredIndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateHistory_CoveredIndicationQuery,
    UpdateHistory_CoveredIndicationQueryVariables
  >(UpdateHistory_CoveredIndicationDocument, options);
}
export type UpdateHistory_CoveredIndicationQueryHookResult = ReturnType<
  typeof useUpdateHistory_CoveredIndicationQuery
>;
export type UpdateHistory_CoveredIndicationLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_CoveredIndicationLazyQuery
>;
export type UpdateHistory_CoveredIndicationQueryResult = Apollo.QueryResult<
  UpdateHistory_CoveredIndicationQuery,
  UpdateHistory_CoveredIndicationQueryVariables
>;
export const UpdateHistory_IndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_Indication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_IndicationQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_IndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_IndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_IndicationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useUpdateHistory_IndicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_IndicationQuery,
    UpdateHistory_IndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateHistory_IndicationQuery, UpdateHistory_IndicationQueryVariables>(
    UpdateHistory_IndicationDocument,
    options
  );
}
export function useUpdateHistory_IndicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_IndicationQuery,
    UpdateHistory_IndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdateHistory_IndicationQuery, UpdateHistory_IndicationQueryVariables>(
    UpdateHistory_IndicationDocument,
    options
  );
}
export type UpdateHistory_IndicationQueryHookResult = ReturnType<
  typeof useUpdateHistory_IndicationQuery
>;
export type UpdateHistory_IndicationLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_IndicationLazyQuery
>;
export type UpdateHistory_IndicationQueryResult = Apollo.QueryResult<
  UpdateHistory_IndicationQuery,
  UpdateHistory_IndicationQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeUpdateHistory_BasicPublishedOfferingMockResponse = makeMockResponse<
  UpdateHistory_BasicPublishedOfferingQueryVariables,
  UpdateHistory_BasicPublishedOfferingQuery
>(UpdateHistory_BasicPublishedOfferingDocument);

export const makeUpdateHistory_CoveredIndicationMockResponse = makeMockResponse<
  UpdateHistory_CoveredIndicationQueryVariables,
  UpdateHistory_CoveredIndicationQuery
>(UpdateHistory_CoveredIndicationDocument);

export const makeUpdateHistory_IndicationMockResponse = makeMockResponse<
  UpdateHistory_IndicationQueryVariables,
  UpdateHistory_IndicationQuery
>(UpdateHistory_IndicationDocument);
