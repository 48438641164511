// These must be the first lines in src/index.js
import 'react-app-polyfill/stable';
/**
 * In order to support multiple major versions of Ag Grid in the same project,
 * we need to bundle the styles of Ag Grid v32 and import it as separate style sheet.
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@cmg/data-grid/dist/style.css';

import { loggerUtil } from '@cmg/common';
import { LicenseManager } from '@cmg/data-grid';
import React from 'react';
import ReactDOM from 'react-dom';

import { getAppSettings } from './common/config/appSettings';
import App from './features/core/App';

const appSettings = getAppSettings();

/**
 * Set the ag-grid enterprise license key
 */
LicenseManager.setLicenseKey(appSettings.auth.agGridLicenseKey);

loggerUtil.initDatadog({
  clusterEnvironment: appSettings.env.clusterEnvironment,
  datadogApplicationId: appSettings.logging.datadogApplicationId,
  datadogClientToken: appSettings.logging.datadogClientToken,
  serviceName: appSettings.logging.datadogServiceName,
  allowedTracingOrigin: appSettings.logging.datadogTracingOrigin,
  isDevelopment: appSettings.env.isDevelopment,
  version: appSettings.client.appVersion,
});

loggerUtil.initMixpanel({
  token: appSettings.logging.mixPanelToken,
  isDevelopment: appSettings.env.isDevelopment || !appSettings.logging.mixPanelToken, // note: isDevelopment passed here translates to mixpanels debug mode
});

ReactDOM.render(<App />, document.getElementById('root'));
