import { Popover } from '@cmg/common';
import React from 'react';

import { WarningIcon } from '../../../../../../common/components/warning-icon/WarningIcon';
import { TradeReleaseGridContext } from '../../TradeReleaseGrid.model';
import { SWarningTextWrapper } from './TradeWarningRenderer.styles';

export type Props = Readonly<{
  context: TradeReleaseGridContext;
}>;

const TotalWarningRenderer: React.FC<Props> = ({ context }) => {
  const warningCount = context.filteredRows.filter(({ warnings }) => warnings.length > 0).length;

  if (warningCount === 0) {
    return null;
  }

  return (
    <Popover
      variant="LIGHT"
      placement="topRight"
      arrowPointAtCenter
      content={
        <SWarningTextWrapper>
          {`There are alerts affecting ${warningCount} trade${warningCount === 1 ? '' : 's'}`}
        </SWarningTextWrapper>
      }
    >
      <span>
        <WarningIcon type="error" />
      </span>
    </Popover>
  );
};

export default TotalWarningRenderer;
