import styled from 'styled-components/macro';

export const SCalendarContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-direction: column;
`;

export const SFooter = styled.div`
  border-top: ${({ theme }) => `${theme.border.width.medium} solid ${theme.border.color.light}`};
`;
