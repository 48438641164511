import { FlexContainer } from '@cmg/common';
import styled from 'styled-components/macro';

export const SReconciliationLabel = styled.span`
  text-align: right;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.medium};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const StyledReconciliationFlex = styled(FlexContainer)`
  margin: 16px 0 8px 0;
`;

export const SIconWrapper = styled.div`
  margin-right: 8px;
`;
