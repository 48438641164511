import { Box, Typography } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{
  title: string;
}>;

export const ToolPanel: React.FC<Props> = ({ title, children }) => {
  return (
    <Box p={2} width={300}>
      <Typography pb={2} variant="h3">
        {title}
      </Typography>
      {children}
    </Box>
  );
};
