import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import { DealTeamMember } from '../../CreateDealTeamMembersModal.model';

export type Props = { member: DealTeamMember };

const ExistingDealTeamMemberRow: React.FC<Props> = ({ member }) => {
  return (
    <Table.Row>
      <Table.Cell>{member.name}</Table.Cell>
      <Table.Cell>{member.mobilePhone}</Table.Cell>
      <Table.Cell>{member.officePhone}</Table.Cell>
      <Table.Cell>{member.email}</Table.Cell>
      <Table.Cell>{member.department}</Table.Cell>
      <Table.Cell />
    </Table.Row>
  );
};

export default ExistingDealTeamMemberRow;
