import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../../graphql';
import { MyOrders_CoveredIndicationsDocument } from '../../../../../../../../../my-orders/graphql';
import { useCmgEntityKeyContext } from '../../../../../../../../contexts/CmgEntityKeyContext';
import {
  useCreateIndication_PassOnOfferingMutation,
  useManageIndication_PassOnCoveredIndicationMutation,
} from '../../../../graphql/__generated__';

export const refetchQueries = [getOperationName(MyOrders_CoveredIndicationsDocument)!];

export const usePassOnCoveredIndication = (
  offeringId: UUID,
  indicationId: UUID | undefined,
  indicationStatus: IndicationStatus | null = null,
  onClose: () => void,
  isPassingNewIndication: boolean,
  onFinishEditing?: () => void
): [() => Promise<void>, boolean] => {
  const [passIndication, { loading: isPassingIndication }] =
    useManageIndication_PassOnCoveredIndicationMutation();
  const [passWithoutIndication, { loading: isPassingWithoutIndication }] =
    useCreateIndication_PassOnOfferingMutation();
  const { cmgEntityKey } = useCmgEntityKeyContext();

  const handlePassWithoutIndication = React.useCallback(async () => {
    const response = await passWithoutIndication({
      variables: {
        offeringId,
        input: {
          investorInformation: { cmgEntityKey },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries,
    });

    const responseIndication = response.data!.passOfferingWithoutCoveredInstitutionalIndication;
    if (responseIndication.__typename === 'ServiceError') {
      throw new Error('Sending new indication failed');
    }
  }, [cmgEntityKey, offeringId, passWithoutIndication]);
  const handlePassCoveredIndication = React.useCallback(async () => {
    const response = await passIndication({
      variables: {
        offeringId,
        indicationId: indicationId!,
        activateIfCancelled: indicationStatus === IndicationStatus.Cancelled,
      },
      awaitRefetchQueries: true,
      refetchQueries,
    });

    const responseIndication = response.data!.passCoveredInstitutionalIndication;
    if (responseIndication.__typename === 'ServiceError') {
      throw new Error('Sending new indication failed');
    }
  }, [indicationId, indicationStatus, offeringId, passIndication]);

  const handlePass = React.useCallback(async () => {
    try {
      if (isPassingNewIndication) {
        await handlePassWithoutIndication();
      } else {
        await handlePassCoveredIndication();
      }

      SnackbarManager.success('Successfully passed indication');
      onClose();
      onFinishEditing?.();
    } catch {
      SnackbarManager.error('Failed to pass indication');
    }
  }, [
    handlePassCoveredIndication,
    handlePassWithoutIndication,
    isPassingNewIndication,
    onClose,
    onFinishEditing,
  ]);

  return [handlePass, isPassingIndication || isPassingWithoutIndication];
};
