import { Icon, Popover } from '@cmg/common';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { SPopoverContentWrapper } from './ErrorIcon.styles';

export type Props = {
  errorMessage?: string | null;
  maxWidth?: number;
};

export const ErrorIcon: React.FC<Props> = ({ errorMessage, maxWidth }) => {
  const theme = useTheme();

  return (
    <Popover
      disabled={!errorMessage}
      content={
        maxWidth ? (
          <SPopoverContentWrapper maxWidth={maxWidth}>{errorMessage}</SPopoverContentWrapper>
        ) : (
          errorMessage
        )
      }
      variant="LIGHT_ERROR"
    >
      <div>
        <Icon
          name="exclamation-triangle"
          color={theme.designSystem.colors.red['300']}
          variant="solid"
          size="1x"
        />
      </div>
    </Popover>
  );
};
