import flatten from 'lodash/flatten';

import { Roadshows_RoadshowsDealTeamPartsFragment } from '../../../../common/graphql';
import { SelectedDealTeamMember } from '../add-members-modal/AddMembersModal.model';

export const getSelectedTeamMembers = (
  teamList: readonly Roadshows_RoadshowsDealTeamPartsFragment[]
): SelectedDealTeamMember[] => {
  return flatten(
    teamList.map(({ members, firmName }) =>
      members.map<SelectedDealTeamMember>(({ id, name }) => ({
        id,
        fullName: name,
        firmName,
      }))
    )
  );
};
