export const getHubspotIFrame = (): HTMLIFrameElement | null => {
  return document.querySelector('iframe.hs-form-iframe');
};

export const setInputValue = ({
  hubspotIFrame,
  inputSelector,
  value,
}: {
  hubspotIFrame: HTMLIFrameElement | null;
  inputSelector: string;
  value: string;
}) => {
  const inputElement: HTMLInputElement | null | undefined =
    hubspotIFrame?.contentWindow?.document.querySelector(inputSelector);
  if (inputElement && value) {
    inputElement.value = value;
    inputElement.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
      })
    );
  }
};
