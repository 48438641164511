import { ODSTable as Table, Radio } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledInvestorRadio = styled(Radio)`
  margin: 0;
`;

export const StyledTableTextCell = styled(Table.Cell)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTable = styled(Table)`
  height: 400px;
  overflow: auto;
`;
