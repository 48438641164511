import { AgGridProcessCellForExportParams, exchangeMicLabels } from '@cmg/common';
import { GridApi } from 'ag-grid-community';
import React from 'react';

import { securityTypeLabels } from '../../../../types/domain/security/constants';
import { TradeRelease_TradeGridItemFragment } from '../graphql/__generated__';
import { getAcknowledgementLabel } from '../grid/grid-columns/investor-reply-status/AcknowledgementStatusRenderer';
import { releaseStatusMap } from '../grid/grid-columns/trade-status/ReleaseStatusRenderer.model';
import { tradeColsMap } from '../grid/TradeReleaseGrid.model';

export const tradeReleaseCellFormatterCallback = (params: AgGridProcessCellForExportParams) => {
  const colId = params.column.getColId();
  const data: TradeRelease_TradeGridItemFragment = params.node?.data;

  switch (colId) {
    case tradeColsMap.investorReplyStatus:
      return getAcknowledgementLabel(data.investorReplyStatus);
    case tradeColsMap.tradeStatus:
      if (!data.tradeStatus) {
        return null;
      }
      return releaseStatusMap.get(data.tradeStatus);
    case tradeColsMap.exchange:
      if (!data.exchange) {
        return null;
      }
      return exchangeMicLabels[data.exchange].shortName;
    case tradeColsMap.securityType:
      return securityTypeLabels[data.securityType];
  }

  return data[colId];
};

export const useExportTradeRelease = (offeringIssuerName?: string) => {
  return React.useCallback(
    (gridApi?: GridApi) => {
      if (!gridApi) {
        return;
      }

      gridApi.exportDataAsCsv({
        fileName: `${offeringIssuerName}_Trade_Release`,
        shouldRowBeSkipped: row => row.node.isRowPinned(),
        columnKeys: [
          tradeColsMap.investorFirmName,
          tradeColsMap.allocationShares,
          tradeColsMap.sharesReleased,
          tradeColsMap.investorReplyStatus,
          tradeColsMap.statusDetails,
          tradeColsMap.indicationSubmittedBy,
          tradeColsMap.indicationInvestorContactName,
          tradeColsMap.indicationInvestorContactEmail,
          tradeColsMap.investorReplyName,
          tradeColsMap.investorReplyEmail,
          tradeColsMap.indicationAcknowledgedByName,
          tradeColsMap.indicationAcknowledgedByEmail,
          tradeColsMap.releasedAt,
          tradeColsMap.releasedByName,
          tradeColsMap.releasedByEmail,
          tradeColsMap.symbol,
          tradeColsMap.exchange,
          tradeColsMap.cusip,
          tradeColsMap.offerPrice,
          tradeColsMap.isin,
          tradeColsMap.securityType,
          tradeColsMap.sellingConcession,
          tradeColsMap.tradeDate,
          tradeColsMap.settlementDate,
          tradeColsMap.settlementCurrency,
          tradeColsMap.offeringCurrency,
          tradeColsMap.tradeStatus,
        ],
        processCellCallback: tradeReleaseCellFormatterCallback,
      });
    },
    [offeringIssuerName]
  );
};
