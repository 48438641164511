import { Icon } from '@cmg/common';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export type IconColor = 'POSITIVE' | 'DARK';
export const SCompanyNameCellWrapper = styled.div`
  display: flex;
  line-height: 1.2;
`;

export const StyledCompanyNameLink = styled(Link)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledCompanyNameLinkWithoutLogo = styled(Link)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 32px;
`;

export const StyledStatusIcon = styled(Icon)<{ color: IconColor }>`
  margin-right: 5px;
  color: ${({ theme, color }) =>
    color === 'POSITIVE' ? theme.background.color.positive : theme.background.color.dark};
`;

export const SPopoverContentWrapper = styled.div`
  max-width: 200px;
`;

export const SOnPlatformIcon = styled.img`
  height: 15.08px;
  width: 16px;
  margin: 0px 8px 0px 8px;
`;

export const SDuplicate = styled.div`
  font-size: 11px;
  margin: 0 auto;
  display: block;
`;

export const SIconWrapper = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.color.white};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.designSystem.colors.darkBlue[300]};
  display: flex;
  align-items: center;
  width: 20px;
  height: 15px;
`;
