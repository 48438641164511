import type { EditableCallback, FilterValueGetterFn } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridDataContext, DemandGridRowData, DemandGridServerColDef } from '../../types';
import { getValueFormatter, managersFilterParams } from '../columns.model';
import { BndAgentCellEditor } from './BndAgentCellEditor';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['bndAgent'];

/**
 * Exported for testing purposes only
 */
export const valueFormatter = getValueFormatter<Value>({
  gridRow: ({ value }) => value?.firmDisplayName ?? 'Unassigned',
});

/**
 * Exported for testing purposes only
 */
export const getEditableCallback =
  (canUpdate: boolean): EditableCallback<DemandGridRowData, Value> =>
  ({ data }) => {
    return canUpdate && data?.__typename === 'SyndicateInstitutionalGridRow';
  };

export const filterValueGetter: FilterValueGetterFn = (filterModel, context) => {
  const filterParam = filterModel?.in;
  if (Array.isArray(filterParam) && filterParam.length === 0) {
    const allManagers = Object.keys((context as DemandGridDataContext).syndicateManagers);
    return {
      nin: allManagers,
    };
  }
  return { ...filterModel };
};

type Props = {
  canUpdate: boolean;
};

const getBndAgentColDef = ({ canUpdate }: Props): DemandGridServerColDef<Value> => ({
  field: 'bndAgent',
  cellDataType: 'firmInfo',
  headerName: 'B&D Agent',
  valueFormatter,
  editable: getEditableCallback(canUpdate),
  enableRowGroup: true,
  cellEditor: BndAgentCellEditor,
  cellClassRules: {
    'pointer-cell': () => canUpdate,
  },
  filter: 'agSetColumnFilter',
  filterParams: managersFilterParams,
  server: {
    sortFieldPath: 'bndAgent.firmDisplayName',
    filterFieldPath: 'bndAgent.firmKey',
    filterValueGetter,
  },
});

export default getBndAgentColDef;
