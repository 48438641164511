import { Alert, ToastManager } from '@cmg/common';
import React from 'react';

import Modal from '../../../../../../../common/components/overlays/modal/Modal';
import { useRoadshows_PublishRoadshowInternallyMutation } from './graphql';
import { SCancel, SConfirmButton, SFlexContainer } from './PublishRoadshowModal.styles';

export type Props = {
  open: boolean;
  roadshowId: string;
  onClose: () => void;
};

const PublishRoadshowModal: React.FC<Props> = ({ open, roadshowId, onClose }) => {
  const [publishRoadshow, { loading }] = useRoadshows_PublishRoadshowInternallyMutation({
    variables: { roadshowId },
  });

  const handleOnConfirm = async () => {
    try {
      await publishRoadshow({ variables: { roadshowId: roadshowId } });
      ToastManager.success('The roadshow published successfully');
      onClose();
    } catch {
      ToastManager.error('Failed to publish roadshow');
    }
  };

  return (
    <Modal title="Publish Roadshow Internally" show={open} onHide={onClose}>
      <Modal.Content>
        <Alert severity="warning">
          Please make sure you have read and agreed with the message below.
        </Alert>
        <p>
          You are about to publish Roadshow Calendar internally. This action will make Roadshow
          calendar visible to all users with Roadshow Viewer and Roadshow Sales permissions.
        </p>
        <p>You will be able to Open Meetings to Sales.</p>
        <p>This action cannot be reverted.</p>
      </Modal.Content>
      <Modal.Footer>
        <SFlexContainer>
          <SCancel onClick={onClose}>Cancel</SCancel>
          <SConfirmButton
            loading={loading}
            onClick={handleOnConfirm}
            testId="confirm and publish a roadshow internally"
          >
            Publish Roadshow
          </SConfirmButton>
        </SFlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishRoadshowModal;
