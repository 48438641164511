import { UUID } from '@cmg/common';
import React from 'react';

import {
  Roadshows_CompanyPartsFragment,
  Roadshows_ManagementTeamPartsFragment,
} from '../../../../common/graphql';
import RoadshowCreationStep from '../../common/creation-step/RoadshowCreationStep';
import CompanyDetailsSection from './company-details-section/CompanyDetailsSection';
import ManagementTeamSection from './management-team-section/ManagementTeamSection';

export type Props = {
  roadshowId: UUID;
  companyDetails: Roadshows_CompanyPartsFragment;
  managementTeam: Roadshows_ManagementTeamPartsFragment;
};

const SingleCompanyDetails: React.FC<Props> = ({ companyDetails, roadshowId, managementTeam }) => {
  return (
    <RoadshowCreationStep data-test-id="roadshows / single-roadshow / configuration / company">
      <RoadshowCreationStep.Header title="Company & Management Team" />

      <RoadshowCreationStep.Content>
        <CompanyDetailsSection roadshowId={roadshowId} companyDetails={companyDetails} />

        <ManagementTeamSection roadshowId={roadshowId} members={managementTeam.members} />
      </RoadshowCreationStep.Content>
    </RoadshowCreationStep>
  );
};

export default SingleCompanyDetails;
