import React from 'react';

import { SContent, SHeader, STitle } from './Rule134Legend.styles';

export const Rule134Legend: React.FC = ({ children }) => {
  return (
    <div>
      <SHeader>
        <STitle>Notices and Disclaimers</STitle>
      </SHeader>
      <SContent>{children}</SContent>
    </div>
  );
};

export default Rule134Legend;
