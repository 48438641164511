import styled from 'styled-components/macro';

const SMain = styled.div`
  flex-grow: 1;
`;

const SSideNav = styled.div`
  flex-shrink: 0;
  padding: 0 8px;
`;

const SyndicateWiresLayout = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  flex-grow: 1;
`;

export default Object.assign({}, SyndicateWiresLayout, { Main: SMain, SideNav: SSideNav });
