import { DotStatus } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus as IndicationStatusType } from '../../../../../../../../graphql';

export type Props = Readonly<{ status?: IndicationStatusType }>;

const IndicationStatus: React.FC<Props> = ({ status }) => {
  let component = <DotStatus color="secondary" label="Draft" />;
  if (status === IndicationStatusType.Active) {
    component = <DotStatus color="success" label="Active" />;
  }
  if (status === IndicationStatusType.Duplicate) {
    component = <DotStatus color="secondary" label="Duplicate" />;
  }
  if (status === IndicationStatusType.Cancelled) {
    component = <DotStatus color="secondary" label="Cancelled" />;
  }
  return <div data-testid="indication-status">{component}</div>;
};

export default IndicationStatus;
