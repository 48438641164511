import { getCurrencySymbol, numericUtil } from '@cmg/common';
import orderBy from 'lodash/orderBy';

import {
  IndicationStatus,
  InstitutionalIndicationOrderType,
  InterestLevel,
  InterestLevelInterestUnit,
  InterestLevelLimitType,
  MdlAllocationIoiType,
  MdlIndicationLimitType,
  MdlIndicationPricingType,
  MdlIndicationUnitType,
} from '../../../graphql';
import {
  Calendar_MdlIndicationPartsFragment,
  Calendar_MyInstitutionalIndicationPartsFragment,
} from '../graphql';

const mdlLimitTypes = {
  [MdlIndicationLimitType.Premium]: 'Premium',
  [MdlIndicationLimitType.Discount]: 'Discount',
};
const institutionalLimitTypes = {
  [InterestLevelLimitType.Premium]: 'Premium',
  [InterestLevelLimitType.Discount]: 'Discount',
};

// Get MDL indication value according to the unit price

const getMDLIndicationValue = (indication: Calendar_MdlIndicationPartsFragment): string => {
  if (!indication) {
    return '-';
  }
  let postfix: string | undefined;
  let modifier = '';

  if (indication.limitType === MdlIndicationLimitType.Price) {
    modifier = '';
    postfix = numericUtil.formatCurrency(indication.limitPrice);
  }

  if (
    indication.limitType === MdlIndicationLimitType.Discount ||
    indication.limitType === MdlIndicationLimitType.Premium
  ) {
    modifier = mdlLimitTypes[indication.limitType].concat(' ');
    postfix = numericUtil.formatPercents(indication.limitPercentage, 2);
  }

  if (indication.pricingType === MdlIndicationPricingType.Market) {
    postfix = 'Market';
    modifier = '';
  }

  if (indication.unitType === MdlIndicationUnitType.Dollars) {
    postfix = postfix ? postfix : numericUtil.formatCurrency(indication.limitPrice, 2);
    return `${numericUtil.formatCurrency(indication.dollars, 2)} @ ${modifier}${postfix}`;
  }
  if (indication.unitType === MdlIndicationUnitType.Percentage) {
    postfix = postfix ? postfix : numericUtil.formatPercents(indication.limitPercentage, 2);
    return `${numericUtil.formatPercents(indication.percentage, 2)} @ ${modifier}${postfix}`;
  }
  if (indication.unitType === MdlIndicationUnitType.Shares) {
    postfix = postfix ? postfix : `${indication.shares}`;
    return `${numericUtil.formatNumber(indication.shares, 0)} Shares @ ${modifier}${postfix}`;
  }
  return '-';
};

// Get institutional indication value according to the unit type
export const getInstitutionalIndicationValue = (
  interestLevel:
    | Calendar_MyInstitutionalIndicationPartsFragment['interestLevels'][number]
    | InterestLevel,
  demandCurrencyCode?: string,
  pricingCurrencyCode?: string
) => {
  // Early return if the interest level is undefined
  if (!interestLevel) {
    return '-';
  }

  let postfix, modifier;

  if (interestLevel.limitType) {
    if (interestLevel.limitType === InterestLevelLimitType.Price) {
      modifier = '';
      postfix = numericUtil.formatCurrency(
        interestLevel.limitPrice,
        undefined,
        getCurrencySymbol(pricingCurrencyCode)
      );
    } else {
      modifier = institutionalLimitTypes[interestLevel.limitType].concat(' ');
      postfix = numericUtil.formatPercents(interestLevel.limitPrice, 2);
    }
  } else {
    modifier = '';
    postfix = 'Market';
  }

  if (interestLevel.interestUnit === InterestLevelInterestUnit.Currency) {
    return `${numericUtil.formatCurrency(
      interestLevel.interestQuantity,
      2,
      getCurrencySymbol(demandCurrencyCode)
    )} @ ${modifier}${postfix}`;
  }
  if (interestLevel.interestUnit === InterestLevelInterestUnit.Shares) {
    return `${numericUtil.formatNumber(
      interestLevel.interestQuantity,
      0
    )} Shares @ ${modifier}${postfix}`;
  }
  if (interestLevel.interestUnit === InterestLevelInterestUnit.Percent) {
    return `${numericUtil.formatPercents(
      interestLevel.interestQuantity,
      2
    )} @ ${modifier}${postfix}`;
  }
  return `${interestLevel.interestQuantity}`;
};

type MapDemandColumnSourcesParams = {
  myInstitutionalIndication: Calendar_MyInstitutionalIndicationPartsFragment | null | undefined;
  mdlIndications: {
    indications: readonly Calendar_MdlIndicationPartsFragment[];
    ioiType?: MdlAllocationIoiType;
  } | null;
  pricingCurrencyCode?: string;
};
type MapDemandColumnSourcesResult = {
  rootElement: string;
  hoverElements?: string[];
};
// Fn to map the different data sources (myInstitutionalIndication-XC API and MDL API) into a single structure that can be displayed by the UI
export const mapIOIColumnSources = ({
  mdlIndications,
  myInstitutionalIndication,
  pricingCurrencyCode,
}: // TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
MapDemandColumnSourcesParams): MapDemandColumnSourcesResult => {
  // If myInstitutionalIndication data is not null the data mapping goes through the myInstitutionalIndication case,
  // else it should go through the MDL case
  const isInstitutionalIndication = !!myInstitutionalIndication;

  // MDL CASE
  // If is not isInstitutionalIndication its MDL CASE
  if (!isInstitutionalIndication) {
    // For MDL ioiType Reg-M return Reg-M
    if (mdlIndications?.ioiType === MdlAllocationIoiType.RegM) {
      return {
        rootElement: 'Reg-M',
      };
    }
    // For MDL ioiType Pass return Pass
    if (mdlIndications?.ioiType === MdlAllocationIoiType.Pass) {
      return {
        rootElement: 'Pass',
      };
    }
    // Order indications limitPrice on descending fashion
    const indications = orderBy(mdlIndications?.indications || [], 'limitPrice', 'desc');
    // The indication with pricingType === 'Market' is the only market indication on the list
    const rootMarketIndication = indications.find(
      indication => indication.pricingType === MdlIndicationPricingType.Market
    );
    // Get all limit price indications
    const allLimitIndications = indications.filter(
      indication => indication.pricingType === MdlIndicationPricingType.Limit
    );
    // The first indication (rootLimitIndication) will be the highest limit price and will displayed on the column
    // the other indications will be displayed on hover
    const [rootLimitIndication, ...restLimitIndications] = allLimitIndications;
    // If there's a market indication that will be displayed as root and other indications on hover
    if (rootMarketIndication) {
      const root = getMDLIndicationValue(rootMarketIndication);
      const hover = (allLimitIndications || []).map(indication =>
        getMDLIndicationValue(indication)
      );
      return {
        rootElement: root,
        hoverElements: hover.length ? [root, ...hover] : [],
      };
    }
    // If theres no market indications then display the highest limit price as root and the others on hover.
    if (rootLimitIndication) {
      const root = getMDLIndicationValue(rootLimitIndication);
      const hover = (restLimitIndications || []).map(indication =>
        getMDLIndicationValue(indication)
      );
      return {
        rootElement: root,
        hoverElements: hover.length ? [root, ...hover] : [],
      };
    }
    // else display '-'
    return {
      rootElement: '-',
    };
  } else {
    // myInstitutionalIndication CASE - XC API
    // If its not active display '-'
    if (myInstitutionalIndication?.status !== IndicationStatus.Active) {
      return { rootElement: '-' };
    }
    // If indication type is Pass then early return with the 'Pass' content
    if (myInstitutionalIndication.type === InstitutionalIndicationOrderType.Pass) {
      return { rootElement: 'Pass' };
    }
    // If the interestLevels array is empty then return '-' content
    if (
      !myInstitutionalIndication?.interestLevels ||
      myInstitutionalIndication?.interestLevels?.length === 0
    ) {
      return { rootElement: '-' };
    }
    // Order indications limitPrice on descending fashion
    const indications = orderBy(
      myInstitutionalIndication.interestLevels || [],
      'limitPrice',
      'desc'
    );
    // The indication that doesn't have the limitType is the only market indication
    const rootMarketIndication = indications.find(indication => !indication.limitType);
    // Get all indications
    const allLimitIndications = [
      InstitutionalIndicationOrderType.Limit,
      InstitutionalIndicationOrderType.Scaled,
    ].includes(myInstitutionalIndication.type)
      ? indications
      : [];
    const [rootLimitIndication, ...restLimitIndications] = allLimitIndications;
    // If there's a market indication should be displayed as root. Display all indications on hover
    if (rootMarketIndication) {
      const marketTypeHoverElements = (allLimitIndications || []).filter(
        indication => !!indication.limitType
      );
      const root = getInstitutionalIndicationValue(
        rootMarketIndication,
        myInstitutionalIndication.currencyCode ?? undefined,
        pricingCurrencyCode
      );
      const hover = marketTypeHoverElements.map(indication =>
        getInstitutionalIndicationValue(
          indication,
          myInstitutionalIndication.currencyCode ?? undefined,
          pricingCurrencyCode
        )
      );
      return {
        rootElement: root,
        hoverElements: hover.length ? [root, ...hover] : [],
      };
    }
    // If there's not market indication then display the highest indication as root. Display all indications on hover
    if (rootLimitIndication) {
      const root = getInstitutionalIndicationValue(
        rootLimitIndication,
        myInstitutionalIndication.currencyCode ?? undefined,
        pricingCurrencyCode
      );
      const hover = (restLimitIndications || []).map(indication =>
        getInstitutionalIndicationValue(
          indication,
          myInstitutionalIndication.currencyCode ?? undefined,
          pricingCurrencyCode
        )
      );
      return {
        rootElement: root,
        hoverElements: hover.length ? [root, ...hover] : [],
      };
    }
    // else display '-'
    return {
      rootElement: '-',
    };
  }
};
