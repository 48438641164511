import { Stack, Typography } from '@cmg/design-system';
import React from 'react';

import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import CertificateFilters from './components/certificate-filters/CertificateFilters';
import CertificateGrid from './components/certificate-grid';
import CertificateLibrarySidePanel from './components/side-panel/CertificateLibrarySidePanel';
import useCertificateLibraryDataSource from './hooks/useCertificateLibraryDataSouce';

const CertificateLibraryRoute: React.FC = () => {
  useDocumentTitle(routeFactory.certificateLibrary.getDocumentTitle());
  const { dataSource, setGridRef } = useCertificateLibraryDataSource();

  return (
    <Stack height="100%" direction="column">
      <Typography
        variant="h2"
        marginTop={theme => theme.spacing(1.75)}
        paddingX={theme => theme.spacing(4)}
        borderBottom={theme => `${theme.spacing(0.125)} solid ${theme.palette.grey[300]}`}
        paddingBottom={theme => theme.spacing(1)}
      >
        Certificate Library
      </Typography>
      <CertificateFilters stackComponentProps={{ marginTop: theme => theme.spacing(2) }} />
      <CertificateGrid
        onGridReady={setGridRef}
        marginX={0}
        marginTop={theme => theme.spacing(1)}
        width="100%"
        flexGrow="1"
        paddingBottom={theme => theme.spacing(5)}
        datasource={dataSource}
      />
      <CertificateLibrarySidePanel />
    </Stack>
  );
};

export default CertificateLibraryRoute;
