import { timeUtil } from '@cmg/common';
import { format, parseISO } from 'date-fns';

import {
  Roadshows_RoadshowEventPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../common/graphql';

export const createCalendarEvents = (
  roadshowMeetings: readonly Roadshows_RoadshowEventPartsFragment[]
) => {
  return roadshowMeetings.map(meeting => ({
    id: meeting.id,
    title: meeting.title ?? '',
    start: parseISO(`${meeting.startDate}T${meeting.startTime}`),
    end: parseISO(`${meeting.endDate}T${meeting.endTime}`),
    resourceId: meeting.location ?? 'virtual',
    extendedProps: meeting,
  }));
};

export const createCalendarResources = (roadshow: Roadshows_RoadshowPartsFragment, date: Date) => {
  const day = roadshow.roadshowDays.find(day => day.date === format(date, 'yyyy-MM-dd'));

  if (!day || (!day.isVirtual && day.cities.length === 0)) {
    return [];
  }

  if (day.isVirtual) {
    return [
      {
        id: 'virtual',
        title: `Virtual (${timeUtil.formatAsTimeZoneAbbreviation(day.date, day.timeZone!)})`,
      },
    ];
  }

  return day.cities.map(city => ({
    id: city.name,
    title: `${city.name} (${timeUtil.formatAsTimeZoneAbbreviation(day.date, city.timeZone)})`,
  }));
};
