import React from 'react';
import { Redirect } from 'react-router-dom';

import Loading from '../../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../../../common/util/routeFactory';
import { useRoadshows_RoadshowDetailsQuery } from '../../../../common/graphql';
import CoordinatorCalendar from './coordinator-calendar/CoordinatorCalendar';
import { useRoadshows_RoadshowEventsQuery } from './graphql';

export type Props = { roadshowId: string };

const RoadshowCoordinatorCalendar: React.FC<Props> = ({ roadshowId }) => {
  const {
    data: roadshowData,
    loading: roadshowLoading,
    error: roadshowError,
  } = useRoadshows_RoadshowDetailsQuery({
    variables: { roadshowId },
  });
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useRoadshows_RoadshowEventsQuery({
    variables: { roadshowId: roadshowData?.roadshow.id! },
    skip: !roadshowData?.roadshow.id,
  });

  const isLoading = roadshowLoading || eventsLoading;
  const error = roadshowError || eventsError;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const { roadshow } = roadshowData || {};

  if (!roadshow) {
    return <Redirect to={routeFactory.roadshowsList.getUrlPath()} />;
  }

  return (
    <CoordinatorCalendar
      roadshow={roadshow}
      events={eventsData?.roadshowEvents || []}
      testId="roadshows / single-roadshow / calendar"
    />
  );
};

export default RoadshowCoordinatorCalendar;
