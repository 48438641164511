import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SPricingDateRangeFilter = styled.div`
  min-width: 240px;
  max-width: 400px;
  flex-grow: 1;
  flex: 1 1 0px;
`;

export const SIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SSeparator = styled.span`
  background-color: #e2e2e2;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 1px;
  min-height: 22px;
`;

export const StyledIconDateRange = styled(Icon).attrs({ name: 'calendar-alt' })`
  margin: 8px 10px;
`;
