import { useAuth } from '@cmg/auth';
import type { ActionPanelSectionProps } from '@cmg/design-system';
import {
  ActionPanelSection,
  ActionPanelSectionContentSkeleton,
  Alert,
  Stack,
  Typography,
} from '@cmg/design-system';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';

import InvestorContactSearch from '../contact-search/InvestorContactSearch';
import InvestorContactsTable from '../contacts-table/InvestorContactsTable';
import { useInvestorContactInformation_UnderwriterQuery } from '../graphql/__generated__';
import {
  CONTACT_REQUIRED,
  InvestorContact,
  InvestorContactValues,
} from '../InvestorContactInformationForm.model';

export type Props = {
  editing: boolean;
  buySideContacts: InvestorContact[];
  sellSideContacts: InvestorContact[];
  bankInvestorName?: string | null;
  bankInvestorKey?: string | null;
  loading?: boolean;
  saving?: boolean;
  isCoveredAccount: boolean;
  displayMissingContacts: boolean;
  setProspectusMandatoryContactValidationFailed?: (value: boolean) => void;
  prospectusContactMandatory: boolean;
  actions?: ActionPanelSectionProps['actions'];
};

const InvestorContactInformation: React.FC<Props> = ({
  editing,
  buySideContacts,
  sellSideContacts,
  bankInvestorKey,
  bankInvestorName,
  loading,
  saving,
  isCoveredAccount,
  setProspectusMandatoryContactValidationFailed,
  prospectusContactMandatory,
  actions,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const {
    data,
    loading: queryLoading,
    error,
  } = useInvestorContactInformation_UnderwriterQuery({
    variables: { cmgEntityKey: oidcUserCmgEntityKey! },
    skip: !oidcUserCmgEntityKey || isCoveredAccount,
  }); // TODO: user's firm name should be part of auth context

  const { errors, submitCount } = useFormikContext<InvestorContactValues>();

  const hasContactRequiredError =
    (errors.sellSideContacts === CONTACT_REQUIRED ||
      errors.sellSideContacts?.includes(CONTACT_REQUIRED)) ??
    false;
  const noContactIsDefined = submitCount > 0 && hasContactRequiredError;

  React.useEffect(() => {
    setProspectusMandatoryContactValidationFailed?.(noContactIsDefined);
  }, [noContactIsDefined, setProspectusMandatoryContactValidationFailed]);

  const isLoading = queryLoading || loading;

  return (
    <ActionPanelSection id="contact-information" title="Investor Contacts" actions={actions}>
      {isLoading && <ActionPanelSectionContentSkeleton />}
      {!isLoading && (
        <Stack>
          <Stack gap={2}>
            {error && <Alert severity="error">{error.message}</Alert>}
            <FieldArray name="sellSideContacts">
              {({ unshift, remove }) => (
                <Stack>
                  {editing && (
                    <InvestorContactSearch
                      handleAddContact={unshift}
                      bankInvestorKey={bankInvestorKey ?? undefined}
                      disabled={saving || loading}
                      isCoveredAccount={isCoveredAccount}
                    />
                  )}
                  <InvestorContactsTable
                    buySideContacts={buySideContacts}
                    sellSideContacts={sellSideContacts}
                    editing={editing}
                    handleRemoveContact={remove}
                    bankInvestorName={bankInvestorName}
                    usersFirmName={data?.underwriter.displayName}
                    contactsMandatory={prospectusContactMandatory && isCoveredAccount} // form cannot be sent without contacts only for S&T users
                  />
                </Stack>
              )}
            </FieldArray>
          </Stack>
          {buySideContacts.length === 0 && sellSideContacts.length === 0 && (
            <Stack py={3} px={0}>
              <Typography variant="body1" color="text.secondary">
                No Investor Contacts Added
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </ActionPanelSection>
  );
};

export default InvestorContactInformation;
