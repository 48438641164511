import { Theme } from '@cmg/common';

import { FinalSettlement_AccountingManagerPartsFragment } from '../../graphql';
import {
  accountingGridCurrencyFormatter,
  accountingGridExpensesManagerRoleFormatter,
  createAccountingColumn,
  createAccountingFixedColumn,
  getTotal,
} from '../common/accounting-data-grid/AccountingDataGrid.model';

export const getTotalRow = (rows: readonly FinalSettlement_AccountingManagerPartsFragment[]) => {
  return {
    id: 'pinned-total-row',
    firmName: 'Total',
    managementFees: getTotal(rows, 'managementFees')!,
    underwritingFees: getTotal(rows, 'underwritingFees')!,
    designationFees: getTotal(rows, 'designationFees'),
    retailRetentionFees: getTotal(rows, 'retailRetentionFees')!,
    stabilizationGainLoss: getTotal(rows, 'stabilizationGainLoss')!,
    managerExpenseReimbursement: getTotal(rows, 'managerExpenseReimbursement'),
    dealExpenses: getTotal(rows, 'dealExpenses'),
    netDealFees: getTotal(rows, 'netDealFees')!,
    manualAdjustments: getTotal(rows, 'manualAdjustment'),
    finalSettlement: getTotal(rows, 'finalSettlement')!,
  };
};

export const generateColumns = (theme: Theme) => [
  createAccountingFixedColumn({
    field: 'managerFirmName',
    headerName: 'Underwriter',
    pinned: 'left',
    theme,
  }),
  createAccountingColumn({
    field: 'managerRole',
    headerName: 'Role',
    valueFormatter: accountingGridExpensesManagerRoleFormatter,
  }),
  createAccountingColumn({
    field: 'managementFees',
    headerName: 'Management Fees',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'underwritingFees',
    headerName: 'Underwriting Fees',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'designationFees',
    headerName: 'Designation Fees',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'retailRetentionFees',
    headerName: 'Retail Retention Fees',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'stabilizationGainLoss',
    headerName: 'Stabil. Gain/(Loss) & Selling Conc. Cost',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
    minWidth: 300,
  }),
  createAccountingColumn({
    field: 'managerExpenseReimbursement',
    headerName: 'Manager Expense Reimbursement',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
    minWidth: 250,
  }),
  createAccountingColumn({
    field: 'dealExpenses',
    headerName: 'Deal Expenses',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'netDealFees',
    headerName: 'Net Deal Fees',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'manualAdjustment',
    headerName: 'Manual Adjustment',
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
  createAccountingColumn({
    field: 'description',
    headerName: 'Comments',
    minWidth: 350,
  }),
  createAccountingFixedColumn({
    field: 'finalSettlement',
    headerName: 'Final Settlement',
    pinned: 'right',
    theme,
    valueFormatter: accountingGridCurrencyFormatter,
    cellAlign: 'right',
  }),
];
