import { LazyQueryResult } from '@apollo/client';
import { ToastManager } from '@cmg/common';
import { validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

import { phoneNumberSchema } from '../../../../../../../../common/util/validation';
import { ManagementTeamMemberInput } from '../../../../../../../../graphql';
import { Roadshows_ManagementTeamMemberPartsFragment } from '../../../../../../common/graphql';
import {
  Roadshows_RoadshowManagementTeamQuery,
  Roadshows_RoadshowManagementTeamQueryVariables,
} from '../../../graphql';

export type ManagementTeamMember = {
  id: string | null;
  name: string;
  title?: string | null | undefined;
  officePhone?: string | null | undefined;
  mobilePhone?: string | null | undefined;
  email: string;
  isParticipating: boolean;
};

export const validationSchema = yup.object().shape({
  name: yup.string().label('Name').nullable().required(),
  title: yup.string().nullable(),
  mobilePhone: phoneNumberSchema.label('Mobile Number').nullable(),
  officePhone: phoneNumberSchema.label('Office Number').nullable(),
  email: yup
    .string()
    .label('Email')
    .nullable()
    .required()
    .email('Email must be a valid email address')
    .when('$emails', ([emails], schema) => schema.notOneOf(emails, 'This email is already used')),
  isParticipating: yup.boolean().required(),
});

export async function getValidate(
  values: ManagementTeamMember,
  managementTeamQuery: () => Promise<
    LazyQueryResult<
      Roadshows_RoadshowManagementTeamQuery,
      Roadshows_RoadshowManagementTeamQueryVariables
    >
  >,
  ignoredMemberId: string | null
) {
  try {
    const { data } = await managementTeamQuery();
    const emails = data?.roadshowManagementTeam.members
      .filter(item => item.id !== ignoredMemberId)
      .map(item => item.email);
    return validate(values, emails);
  } catch {
    ToastManager.error('Failed to validate input data');
  }
}

async function validate(values: ManagementTeamMember, emails: string[] | undefined) {
  try {
    await validateYupSchema(values, validationSchema, true, {
      emails,
    });
    return {};
  } catch (err) {
    return yupToFormErrors(err);
  }
}

export const teamMemberInitialValues: ManagementTeamMember = {
  id: null,
  name: '',
  title: null,
  officePhone: null,
  mobilePhone: null,
  email: '',
  isParticipating: true,
};

export function mapFormToInput(formValues: ManagementTeamMember): ManagementTeamMemberInput {
  return {
    email: formValues.email,
    name: formValues.name,
    officePhone: formValues.officePhone,
    mobilePhone: formValues.mobilePhone,
    title: formValues.title,
    isParticipating: formValues.isParticipating,
  };
}

export function mapToFormValues(
  teamMember: Roadshows_ManagementTeamMemberPartsFragment
): ManagementTeamMember {
  return {
    id: teamMember.id,
    email: teamMember.email,
    name: teamMember.name,
    officePhone: teamMember.officePhone,
    mobilePhone: teamMember.mobilePhone,
    title: teamMember.title,
    isParticipating: teamMember.isParticipating,
  };
}
