import isEqual from 'lodash/isEqual';

import {
  AttestationFormStatus,
  IndicationStatus,
  InstitutionalIndicationOrderType,
  OfferingType,
  ProspectusDeliveryStatus,
} from '../../../../../../../graphql';
import { isAttestationStatusValid } from '../../../../../attestation/Attestation.model';
import { isIndicationStatusAndTypeValid } from '../../../../../utils';
import { InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment } from '../../graphql';
import { IndicationWithDemandLevels } from '../../types';

export type RowAlertsObject = {
  errors: number;
  warnings: number;
};

export const getRowAlertsObject = ({
  indicationDemands,
  isUserSettlementAgent,
  offeringType,
  oidcUserCmgEntityKey,
}: {
  indicationDemands: IndicationWithDemandLevels[];
  isUserSettlementAgent: boolean;
  offeringType: OfferingType;
  oidcUserCmgEntityKey: string;
}) => {
  const rowAlertErrorsCount = isComplianceErrorApplicableForOfferingType(offeringType)
    ? getRowAlertErrorsCount(indicationDemands, isUserSettlementAgent)
    : getRowBDAlertsCount(indicationDemands, isUserSettlementAgent);

  const rowAlertWarningsCount = getRowAlertWarningsCount(
    indicationDemands,
    isUserSettlementAgent,
    oidcUserCmgEntityKey,
    offeringType
  );

  return {
    errors: rowAlertErrorsCount,
    warnings: rowAlertWarningsCount,
  };
};

/**
 * Check if the compliance error is applicable for the offering type.
 * Returns true for Ipo and IpoSpac offering types.
 */
export const isComplianceErrorApplicableForOfferingType = (offeringType: OfferingType) => {
  return offeringType === OfferingType.Ipo || offeringType === OfferingType.IpoSpac;
};

export const getRowAlertErrorsCount = (
  indicationDemands: IndicationWithDemandLevels[],
  isSettlementAgent: boolean
) => {
  return indicationDemands.filter(
    indication =>
      isRowComplianceInvalid({
        indicationStatus: indication.status,
        attestationStatus: indication.attestationStatus?.status ?? null,
        prospectusDeliveryStatus: indication.prospectusDeliveryStatus?.overallDeliveryStatus,
      }) ||
      isRowValidAndBnDAgentUnassigned({
        billingAndDeliveryAgentCmgEntityKey: indication.billingAndDeliveryAgentCmgEntityKey,
        finalAllocationShareQuantity: indication.finalAllocation?.shareQuantity!,
        isSettlementAgent,
        type: indication.type,
        status: indication.status,
      })
  ).length;
};

export const getRowAlertWarningsCount = (
  indicationDemands: IndicationWithDemandLevels[],
  isSettlementAgent: boolean,
  oidcUserCmgEntityKey: string,
  offeringType: OfferingType
) => {
  return indicationDemands.reduce((count, indication) => {
    if (
      isComplianceErrorApplicableForOfferingType(offeringType) &&
      (isRowComplianceInvalid({
        indicationStatus: indication.status,
        attestationStatus: indication.attestationStatus?.status ?? null,
        prospectusDeliveryStatus: indication.prospectusDeliveryStatus?.overallDeliveryStatus,
      }) ||
        isRowValidAndBnDAgentUnassigned({
          billingAndDeliveryAgentCmgEntityKey: indication.billingAndDeliveryAgentCmgEntityKey,
          finalAllocationShareQuantity: indication.finalAllocation?.shareQuantity!,
          isSettlementAgent,
          type: indication.type,
          status: indication.status,
        }))
    ) {
      return count;
    } else if (
      isRowValidAndBnDAgentUnassigned({
        billingAndDeliveryAgentCmgEntityKey: indication.billingAndDeliveryAgentCmgEntityKey,
        finalAllocationShareQuantity: indication.finalAllocation?.shareQuantity!,
        isSettlementAgent,
        type: indication.type,
        status: indication.status,
      })
    ) {
      return count;
    } else if (
      isRowAcknowledgementRevised({
        institutionalIndicationAcknowledgements: indication.institutionalIndicationAcknowledgements,
        currentIndicationVersion: indication.version,
        indicationStatus: indication.status,
        oidcUserCmgEntityKey,
      })
    ) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const getRowBDAlertsCount = (
  indicationDemands: IndicationWithDemandLevels[],
  isSettlementAgent: boolean
) => {
  return indicationDemands.filter(indication =>
    isRowValidAndBnDAgentUnassigned({
      billingAndDeliveryAgentCmgEntityKey: indication.billingAndDeliveryAgentCmgEntityKey,
      finalAllocationShareQuantity: indication.finalAllocation?.shareQuantity!,
      isSettlementAgent,
      type: indication.type,
      status: indication.status,
    })
  ).length;
};

export const getRowDupeIndicationDemandLevelMismatchCount = (
  indicationDemands: IndicationWithDemandLevels[]
) => {
  return indicationDemands.filter(indication => {
    const isSurvivingIndication = indication.duplicateIndicationIds.length > 0;
    if (isSurvivingIndication) {
      const survivingIndication = indication;

      const dupeIndications = indicationDemands.filter(indication => {
        return survivingIndication.duplicateIndicationIds.includes(indication.id);
      });

      return dupeIndications.some(({ interestLevels }) => {
        return !isEqual(interestLevels, survivingIndication.interestLevels);
      });
    }

    return false;
  }).length;
};

export const isRowAcknowledgementRevised = ({
  institutionalIndicationAcknowledgements,
  currentIndicationVersion,
  indicationStatus,
  oidcUserCmgEntityKey,
}: {
  institutionalIndicationAcknowledgements: readonly InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment[];
  currentIndicationVersion: string;
  indicationStatus: IndicationStatus;
  oidcUserCmgEntityKey: string;
}) => {
  return institutionalIndicationAcknowledgements?.some(acknowledgement => {
    return (
      acknowledgement.acknowledgedIndicationVersion !== currentIndicationVersion &&
      acknowledgement.managerCmgEntityKey === oidcUserCmgEntityKey &&
      indicationStatus !== IndicationStatus.Duplicate
    );
  });
};

export const isRowComplianceInvalid = ({
  indicationStatus,
  attestationStatus,
  prospectusDeliveryStatus,
}: {
  indicationStatus: IndicationStatus;
  attestationStatus: AttestationFormStatus | null;
  prospectusDeliveryStatus?: ProspectusDeliveryStatus;
}) => {
  const isAttestationStatusInvalid = attestationStatus
    ? indicationStatus === IndicationStatus.Active && !isAttestationStatusValid(attestationStatus)
    : false;

  const isProspectusDeliveryStatusInvalid =
    prospectusDeliveryStatus === ProspectusDeliveryStatus.Failed;

  return isAttestationStatusInvalid || isProspectusDeliveryStatusInvalid;
};

export const isRowUnAssignedBDAgent = ({
  billingAndDeliveryAgentCmgEntityKey,
  finalAllocationShareQuantity,
  isSettlementAgent,
}: {
  billingAndDeliveryAgentCmgEntityKey: string | null;
  finalAllocationShareQuantity: number | null | undefined;
  isSettlementAgent: boolean;
}) =>
  !!(
    finalAllocationShareQuantity &&
    finalAllocationShareQuantity > 0 &&
    !billingAndDeliveryAgentCmgEntityKey &&
    isSettlementAgent
  );

export const isRowValidAndBnDAgentUnassigned = ({
  billingAndDeliveryAgentCmgEntityKey,
  finalAllocationShareQuantity,
  isSettlementAgent,
  status,
  type,
}: {
  billingAndDeliveryAgentCmgEntityKey: string | null;
  finalAllocationShareQuantity: number | null | undefined;
  isSettlementAgent: boolean;
  status: IndicationStatus;
  type: InstitutionalIndicationOrderType;
}) => {
  return (
    isRowUnAssignedBDAgent({
      billingAndDeliveryAgentCmgEntityKey,
      finalAllocationShareQuantity,
      isSettlementAgent,
    }) && isIndicationStatusAndTypeValid(status, type)
  );
};
