import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { FiberManualRecordIcon, SvgIconColors, Tooltip } from '@cmg/design-system';
import { xcSelectors } from '@cmg/e2e-selectors';

import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';
import { OfferingStage } from '../../../../../graphql';
import { stepIds } from '../../../../offering-setup/constants';

type Props = {
  readonly offeringId: UUID | undefined;
  readonly offeringStage: OfferingStage;
  readonly isAuthor: boolean;
  readonly isSharedDraft: boolean;
};

const stageToColor = (stage: OfferingStage): [label?: string, color?: SvgIconColors] => {
  switch (stage) {
    case OfferingStage.Draft: {
      return ['Offering Stage: Draft', 'action'];
    }
    case OfferingStage.Published: {
      return ['Offering Stage: Published', 'success'];
    }
    case OfferingStage.Obsolete: {
      return ['Offering Stage: Obsolete', 'error'];
    }
    case OfferingStage.Erroneous:
    case OfferingStage.Discarded: {
      return ['Offering Stage: Discarded', 'error'];
    }
    default: {
      return [];
    }
  }
};

export const useOfferingDetailsRoute = ({
  offeringId,
  offeringStage,
  isAuthor,
  isSharedDraft,
}: Props): ReactRouterDomainNavTabProps | undefined => {
  const [label, color] = stageToColor(offeringStage);

  return {
    exact: true,
    'data-test-id': xcSelectors.offeringNavBarOfferingDetails.testId,
    href: useGetOfferingUrlPath({ offeringId, isAuthor, isSharedDraft }),
    label: (
      <Tooltip title={label} placement="top" arrow>
        <span>Offering Details</span>
      </Tooltip>
    ),
    icon: <FiberManualRecordIcon color={color} />,
    iconPosition: 'start',
  };
};

type GetOfferingDetailsRouteParams = {
  readonly offeringId?: UUID;
  readonly isAuthor: boolean;
  readonly isSharedDraft: boolean;
};

function useGetOfferingUrlPath({
  offeringId,
  isAuthor,
  isSharedDraft,
}: GetOfferingDetailsRouteParams): string {
  const hasFullOfferingAccess = useCheckPermissions([permissionsByEntity.Offering.FULL]);

  if (!offeringId) {
    return routeFactory.offeringCreate.getUrlPath();
  }

  if (hasFullOfferingAccess && isAuthor) {
    return routeFactory.offeringSetup.getUrlPath({ stepId: stepIds.SUMMARY, offeringId });
  }

  if (isSharedDraft) {
    return routeFactory.sharedDraftOffering.getUrlPath({ offeringId });
  }
  return routeFactory.offeringDetails.getUrlPath({ offeringId });
}
