import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_IssuerIndicationWiresDocument,
  SyndicateWires_SendIssuerIndicationWireMutation,
  useSyndicateWires_SendIssuerIndicationWireMutation,
} from '../graphql';

export type SendIssuerIndicationWireMutation = SyndicateWires_SendIssuerIndicationWireMutation;

export const useSendIssuerIndicationWireMutation = () => {
  return useSyndicateWires_SendIssuerIndicationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_IssuerIndicationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
