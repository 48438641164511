import { PrimaryButton, SecondaryButton, SubNavigation } from '@cmg/common';
import styled from 'styled-components/macro';

export const SFooter = styled.div`
  align-items: flex-end;
`;

export const StyledCancelButton = styled(SecondaryButton)`
  flex-basis: 30%;
  margin-right: 10px;
`;

export const StyledReleaseFinalAllocationButton = styled(PrimaryButton)`
  margin-left: 15px;
`;

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  text-transform: none;
`;

export const SLoadingWrapper = styled.div`
  padding: 50px;
`;
