import type { UUID } from '@cmg/common';
import has from 'lodash/has';

import { useCheckFinalInstitutionalAllocationAccess } from '../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { OfferingStatus, OrderBookState } from '../../../../graphql';
import offeringSidePanelSellSideRouteFactory from '../../../offering-side-panel/offeringSidePanelSellSideRouteFactory';
import { useGetSidePanelPath } from '../demand-grid/hooks/useGetSidePanelPath';
import type { DemandConfigValues, DemandGridDataContext } from '../demand-grid/types';
import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../graphql/__generated__';
import { SubNavActionButtons } from '../sub-nav-actions/SubNavActionButtons';
import { SubNavActionMenu } from '../sub-nav-actions/SubNavActionMenu';

export type Props = Readonly<{
  offeringId: UUID;
  offeringStatus?: OfferingStatus;
  orderBookState?: OrderBookState;
  orderBookSalesState?: OrderBookState;
  pricingCurrencyCode: string;
  finalAllocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment | undefined;
  demandConfig: DemandConfigValues | null;
  updateOrderBookStatus: {
    loading: boolean;
    onClick: () => void;
  };
  updateOrderBookSalesState: {
    loading: boolean;
    onClick: () => void;
  };
  exportInstitutionalDemand: {
    loading: boolean;
    onClick: () => Promise<void>;
  };
  saveAllocationChanges: {
    changes: DemandGridDataContext['explicitChanges'];
    loading: boolean;
    onDiscard: () => void;
    onClick: () => void;
  };
  releaseFinalAllocations: {
    loading: boolean;
    onClick: () => void;
  };
  exportRevisionHistory: {
    loading: boolean;
    onClick: () => Promise<void>;
  };
  exportProspectusEmails: {
    loading: boolean;
    onClick: () => Promise<void>;
  };
}>;

export type ActionProps = Readonly<{
  canManageAllocations: boolean;
  canUpdateIndications: boolean;
  isAllocationsUnsaved: boolean;
  isDemandConfigDefined: boolean;
  isFinalAllocationSetReleased: boolean;
  isReleaseFinalAllocationSetLoading: boolean;
  isSaveAllocationsLoading: boolean;
  newIndicationRoutePath: string;
  offeringId: UUID;
  pricingCurrencyCode: string;
  orderBookState: OrderBookState | undefined;
  offeringStatus: OfferingStatus | undefined;
  isUpdateOrderBookStatusLoading: boolean;
  isExportInstitutionalDemandLoading: boolean;
  isExportProspectusEmailsLoading: boolean;
  isExportRevisionHistoryLoading: boolean;
  onReleaseFinalAllocations: () => void;
  onUpdateOrderBookStatus: () => void;
  onExportInstitutionalDemand: () => void;
  onExportRevisionHistory: () => void;
  onExportProspectusEmails: () => void;
  onSaveAllocations: () => void;
  onDiscardAllocationChanges: () => void;
  orderBookSalesState: OrderBookState | undefined;
  onUpdateOrderBookSalesState: () => void;
}>;

export const useSubNavActions = ({
  offeringId,
  offeringStatus,
  orderBookState,
  pricingCurrencyCode,
  finalAllocationSet,
  demandConfig,
  updateOrderBookStatus,
  exportInstitutionalDemand,
  saveAllocationChanges,
  releaseFinalAllocations,
  orderBookSalesState,
  updateOrderBookSalesState,
  exportProspectusEmails,
  exportRevisionHistory,
}: Props) => {
  const newIndicationRoutePath = useGetSidePanelPath({
    sidePanelRoutePath: offeringSidePanelSellSideRouteFactory.orderBookNewIndication.getUrlPath({}),
  });

  const { canManage: canManageAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });
  const { canUpdate: canUpdateIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });

  const isAllocationsUnsaved =
    !!finalAllocationSet &&
    Object.values(saveAllocationChanges.changes).some(change =>
      has(change.allocations, finalAllocationSet.id)
    );

  const actionProps: ActionProps = {
    offeringId,
    isFinalAllocationSetReleased: !!finalAllocationSet?.isReleased,
    isUpdateOrderBookStatusLoading: updateOrderBookStatus.loading,
    isExportInstitutionalDemandLoading: exportInstitutionalDemand.loading,
    isReleaseFinalAllocationSetLoading: releaseFinalAllocations.loading,
    isSaveAllocationsLoading: saveAllocationChanges.loading,
    canManageAllocations,
    canUpdateIndications,
    isDemandConfigDefined: !!demandConfig,
    orderBookState,
    offeringStatus,
    onReleaseFinalAllocations: releaseFinalAllocations.onClick,
    onUpdateOrderBookStatus: updateOrderBookStatus.onClick,
    onExportInstitutionalDemand: exportInstitutionalDemand.onClick,
    onExportRevisionHistory: exportRevisionHistory.onClick,
    onExportProspectusEmails: exportProspectusEmails.onClick,
    isAllocationsUnsaved,
    onSaveAllocations: saveAllocationChanges.onClick,
    onDiscardAllocationChanges: saveAllocationChanges.onDiscard,
    newIndicationRoutePath,
    onUpdateOrderBookSalesState: updateOrderBookSalesState.onClick,
    orderBookSalesState,
    pricingCurrencyCode,
    isExportProspectusEmailsLoading: exportProspectusEmails.loading,
    isExportRevisionHistoryLoading: exportRevisionHistory.loading,
  };

  return {
    md: <SubNavActionButtons {...actionProps} />,
    xs: <SubNavActionMenu {...actionProps} />,
  };
};
