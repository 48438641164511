import {
  DeepWritable,
  filterSidePanelEvent,
  useDebouncedCalendarRefreshEvent,
  UUID,
} from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ErrorRenderer from '../../../common/components/errorRenderer/Error-Renderer';
import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery } from '../../offering/offering-details/graphql';
import OfferingProfile from '../../offering-profile/OfferingProfile';
import { SidePanelTitle } from '../components/side-bar/components/design-system';
import { SupersedeOfferingBanner } from '../components/supersede-offering-banner/SupersedeOfferingBanner';
import { useSupersededOfferingContext } from '../contexts/SupersededOfferingContext';
import { NoRelationshipLegalBanner } from '../SNoRelationshipBanners';
import isManagerRelationshipError from '../utils/isManagerRelationshipError';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const BuySideOfferingDetailsRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const { data, loading, error, refetch } =
    useOffering_OfferingDetails_BasicPublishedOfferingProfileQuery({
      variables: { id: offeringId },
      fetchPolicy: 'no-cache',
    });

  const { supersededOfferingId } = useSupersededOfferingContext();

  const filterFn = React.useMemo(() => {
    return filterSidePanelEvent(offeringId);
  }, [offeringId]);

  useDebouncedCalendarRefreshEvent({
    callback: React.useCallback(() => refetch(), [refetch]),
    filterFn,
  });

  if (error) {
    // We want to show a different banner for when there is no relationship with sellside
    const shouldShowNoRelationShipBanner = isManagerRelationshipError(error);

    if (shouldShowNoRelationShipBanner) {
      return <NoRelationshipLegalBanner />;
    }

    return <ErrorRenderer error={error} />;
  }

  if (loading) {
    return <Spinner show />;
  }

  return (
    <FlexLayout direction="column">
      <SidePanelTitle>Offering Details</SidePanelTitle>
      {!!supersededOfferingId && <SupersedeOfferingBanner isSellSide={false} />}
      {data && (
        <OfferingProfile
          offering={data.basicPublishedOffering as DeepWritable<typeof data.basicPublishedOffering>}
        />
      )}
    </FlexLayout>
  );
};

export default BuySideOfferingDetailsRoute;
