import { Popover } from '@cmg/common';
import React from 'react';

import { WarningIcon } from '../../../../../../common/components/warning-icon/WarningIcon';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { useGetWarningMessage } from './hooks/useGetWarningMessage';
import { SWarningTextWrapper } from './TradeWarningRenderer.styles';

export type Props = Readonly<{
  value: TradeRelease_TradeGridItemFragment['warnings'];
}>;

const TradeWarningRenderer: React.FC<Props> = ({ value }) => {
  const message = useGetWarningMessage(value);

  if (!message) {
    return null;
  }

  return (
    <Popover
      variant="LIGHT"
      placement="bottomLeft"
      arrowPointAtCenter
      content={<SWarningTextWrapper>{message}</SWarningTextWrapper>}
    >
      <span>
        <WarningIcon type="error" />
      </span>
    </Popover>
  );
};

export default TradeWarningRenderer;
