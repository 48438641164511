import { ColDef } from 'ag-grid-community';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { MAX_32_BIT_INT } from '../../../../../../../types/graphql/constants';
import { RowAlertsObject } from '../../components/row-alert/RowAlert.model';
import { InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery } from '../../graphql';
import { useGetFinalAllocationColumns } from '../../grid-columns/columns-configuration/allocation-set-columns/FinalAllocationSetColumn';
import { useCmgColumns } from '../../grid-columns/columns-configuration/CmgColumns';
import { MenuType } from '../../grid-columns/columns-configuration/Columns.model';
import { useComplianceColumns } from '../../grid-columns/columns-configuration/ComplianceColumns';
import { useGetDemandColumns } from '../../grid-columns/columns-configuration/DemandLevelColumns';
import { useGenerageInvestorExtendedDataColumns } from '../../grid-columns/columns-configuration/InvestorExtendedDataColumns';
import { useGetNotesColumns } from '../../grid-columns/columns-configuration/NotesColumns';
import { PinnedColumnsType } from '../../preferences/useInstitutionalDemandPreferences';
import { MAX_INCREMENT, MIN_INCREMENT } from '../../types';
import PinColumnButton, { PinnedValue } from '../pin-column-button/PinColumnButton';
import { SRowWrapper, StyledCheckbox } from './InstitutionalDemandGridColumnMenu.styles';

export const MAXIMUM_INSTITUTIONAL_GRID_COLUMNS = 18;

export const validationSchema = Yup.object().shape({
  min: Yup.mixed<number>()
    .required('LOW is required')
    .test('test-demandColumnMinPositive', 'LOW must be greater than 0', function checkValue(value) {
      return value > 0;
    })
    .test(
      'test-demandColumnMin',
      'LOW must be smaller than HIGH',
      function checkValue(this, value) {
        const { max } = this.parent;
        return value <= max;
      }
    ),
  max: Yup.mixed<number>()
    .required('HIGH is required')
    .test(
      'test-demandColumnMaxPositive',
      'HIGH must be greater than 0',
      function checkValue(value) {
        return value > 0;
      }
    )
    .test(
      'test-demandColumnMax',
      'HIGH must be larger than LOW',
      function checkValue(this, value: number) {
        const { min } = this.parent;
        return value >= min;
      }
    )
    .test(
      'test-demandColumnMax-UpperBound',
      `HIGH must be less than ${MAX_32_BIT_INT}`,
      function checkValue(this: typeof validationSchema, value: number) {
        return value <= MAX_32_BIT_INT;
      }
    ),
  increment: Yup.number()
    .typeError('Increment is required')
    .positive('Increment must be greater than 0')
    .min(MIN_INCREMENT, `Increment must be between ${MIN_INCREMENT} and ${MAX_INCREMENT}`)
    .max(MAX_INCREMENT, `Increment must be between ${MIN_INCREMENT} and ${MAX_INCREMENT}`)
    .required('Increment is required'),
  referencePrice: Yup.number()
    .typeError('Reference price is required')
    .positive('Reference price must be greater than 0')
    .required('Reference price is required'),
});

export const hideFilter = (c: ColDef) => c.refData?.menuDisplayType !== MenuType.HIDE;
export const isReadOnly = (c: ColDef) =>
  c.refData?.menuDisplayType === MenuType.READ_ONLY ||
  c.refData?.menuDisplayType === MenuType.PIN_ONLY;
export const isPinnable = (c: ColDef) => c.refData?.menuDisplayType !== MenuType.READ_ONLY;
export const getHeaderName = (c: ColDef) => c.headerName ?? '';
export const defaultFilter = () => true;

export const generateVisibleColumns = ({
  visibleColumns,
  colId,
  newValue,
}: {
  visibleColumns: string[];
  colId: string;
  newValue: boolean;
}) => {
  const newColumns = visibleColumns.filter(c => c !== colId);
  return newValue ? [...newColumns, colId] : newColumns;
};

export const generatePinnedColumns = ({
  pinnedColumns,
  colId,
  isPinnedRight,
  isPinnedLeft,
}: {
  pinnedColumns: PinnedColumnsType;
  colId: string;
  isPinnedRight: boolean;
  isPinnedLeft: boolean;
}): PinnedColumnsType => {
  const pinnedRightColumns = pinnedColumns.right.filter(c => c !== colId);
  const pinnedLeftColumns = pinnedColumns.left.filter(c => c !== colId);
  return {
    right: isPinnedRight ? [...pinnedRightColumns, colId] : pinnedRightColumns,
    left: isPinnedLeft ? [...pinnedLeftColumns, colId] : pinnedLeftColumns,
  };
};

export const generateCheckbox = ({
  onChange,
  visibleColumns,
  pinnedColumns,
  height,
  displayPinIcon,
}: {
  onChange: (
    colId: string,
    isSelected: boolean,
    isPinnedRight: boolean,
    isPinnedLeft: boolean
  ) => void;
  visibleColumns: string[];
  pinnedColumns: PinnedColumnsType;
  height?: string;
  displayPinIcon?: boolean;
}): ((value: ColDef) => JSX.Element) => {
  return (c: ColDef): JSX.Element => {
    const isVisible = includes(visibleColumns, c.colId!);
    const isEnabled = visibleColumns.length < MAXIMUM_INSTITUTIONAL_GRID_COLUMNS || isVisible;
    const canBeSelected = !isReadOnly(c) && isEnabled;
    const canBePinned = isPinnable(c);
    const isPinnedRight = includes(pinnedColumns.right, c.colId!);
    const isPinnedLeft = includes(pinnedColumns.left, c.colId!);
    let pinnedValue: PinnedValue = 'UNPINNED';
    if (isPinnedRight) {
      pinnedValue = 'RIGHT';
    }
    if (isPinnedLeft) {
      pinnedValue = 'LEFT';
    }
    return (
      <SRowWrapper key={c.colId!}>
        <StyledCheckbox
          id={c.colId!}
          disabled={!canBeSelected}
          onChange={newValue =>
            !isReadOnly(c) && onChange(c.colId!, newValue, isPinnedRight, isPinnedLeft)
          }
          value={isReadOnly(c) || isVisible}
          height={height}
        >
          {getHeaderName(c)}
        </StyledCheckbox>
        {displayPinIcon && (
          <PinColumnButton
            disabled={!canBePinned}
            pinnedValue={pinnedValue}
            onPinLeft={() => isPinnable(c) && onChange(c.colId!, isVisible, false, true)}
            onPinRight={() => isPinnable(c) && onChange(c.colId!, isVisible, true, false)}
            onUnpin={() => isPinnable(c) && onChange(c.colId!, isVisible, false, false)}
          />
        )}
      </SRowWrapper>
    );
  };
};

export const generateOptions = ({
  columnDefinitions,
  visibleColumns,
  pinnedColumns,
  onChange,
  height,
  displayPinIcon,
}: {
  columnDefinitions: ColDef[];
  visibleColumns: string[];
  pinnedColumns: PinnedColumnsType;
  onChange: (
    colId: string,
    newValue: boolean,
    isPinnedRight: boolean,
    isPinnedLeft: boolean
  ) => void;
  height?: string;
  displayPinIcon?: boolean;
}): JSX.Element[] => {
  return columnDefinitions.filter(hideFilter).map(
    generateCheckbox({
      onChange,
      visibleColumns,
      height: height,
      pinnedColumns,
      displayPinIcon,
    })
  );
};

export const generateDynamicOptions = ({
  columnDefinitions,
  visibleColumns,
  pinnedColumns,
  onChange,
  height,
  displayPinIcon,
}: {
  columnDefinitions: ColDef[];
  visibleColumns: string[];
  pinnedColumns: PinnedColumnsType;
  onChange: (
    colId: string,
    newValue: boolean,
    isPinnedRight: boolean,
    isPinnedLeft: boolean
  ) => void;
  height?: string;
  displayPinIcon?: boolean;
}): { [key: string]: JSX.Element[] } => {
  const grouped: { [key: string]: ColDef[] } = groupBy(
    columnDefinitions.filter(hideFilter),
    (c: ColDef) => c.refData!.dynamicType
  );
  return Object.fromEntries(
    Object.keys(grouped).map((key: string) => [
      key,
      grouped[key].map(
        generateCheckbox({
          onChange,
          visibleColumns,
          height: height,
          pinnedColumns,
          displayPinIcon,
        })
      ),
    ])
  );
};

export const useGetMenuOptions = ({
  isFinalAllocationSetReleased,
  finalAllocationExists,
  visibleColumns,
  pinnedColumns,
  setVisibleColumns,
  setPinnedColumns,
  offeringId,
  height,
  columnFilter,
  investorsExtendedData,
  displayPinIcon,
}: {
  offeringId: string;
  isFinalAllocationSetReleased: boolean;
  finalAllocationExists: boolean;
  visibleColumns: string[];
  pinnedColumns: PinnedColumnsType;
  height?: string;
  columnFilter?: (col: ColDef) => boolean;
  setVisibleColumns: (values: string[]) => void;
  setPinnedColumns: (values: PinnedColumnsType) => void;
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'];
  displayPinIcon?: boolean;
}) => {
  const defaultRowAlertObject: RowAlertsObject = {
    errors: 0,
    warnings: 0,
  };
  const cmgDefs = useCmgColumns(offeringId, defaultRowAlertObject).filter(
    columnFilter ?? defaultFilter
  );
  const complianceDefs = useComplianceColumns().filter(columnFilter ?? defaultFilter);
  const demandsDefs = useGetDemandColumns().filter(columnFilter ?? defaultFilter);
  const notesDefs = useGetNotesColumns().filter(columnFilter ?? defaultFilter);
  const finalAllocationsDefs = useGetFinalAllocationColumns({
    finalAllocationExists,
    isFinalAllocationSetReleased,
    offeringId,
  }).filter(columnFilter ?? defaultFilter);
  const extendedData = useGenerageInvestorExtendedDataColumns(investorsExtendedData).filter(
    columnFilter ?? defaultFilter
  );

  return useMemo(() => {
    const onChange = (
      colId: string,
      newValue: boolean,
      isPinnedRight: boolean,
      isPinnedLeft: boolean
    ) => {
      const newVisibleColumns = generateVisibleColumns({ visibleColumns, colId, newValue });
      setVisibleColumns(newVisibleColumns);
      setPinnedColumns(
        generatePinnedColumns({ pinnedColumns, isPinnedLeft, isPinnedRight, colId })
      );
    };

    const commonProps = {
      visibleColumns,
      pinnedColumns,
      onChange,
      height,
    };

    const cmg = generateOptions({
      ...commonProps,
      columnDefinitions: cmgDefs,
      displayPinIcon,
    });

    const compliance = generateOptions({
      ...commonProps,
      columnDefinitions: complianceDefs,
      displayPinIcon,
    });

    const demands = generateOptions({
      ...commonProps,
      columnDefinitions: demandsDefs,
      displayPinIcon,
    });

    const notes = generateOptions({
      ...commonProps,
      columnDefinitions: notesDefs,
      displayPinIcon,
    });

    const finalAllocations = generateOptions({
      ...commonProps,
      columnDefinitions: finalAllocationsDefs,
      displayPinIcon,
    });

    const dynamicColumns = generateDynamicOptions({
      ...commonProps,
      columnDefinitions: extendedData,
      displayPinIcon,
    });

    return { cmg, compliance, demands, notes, finalAllocations, dynamicColumns };
  }, [
    cmgDefs,
    demandsDefs,
    notesDefs,
    finalAllocationsDefs,
    complianceDefs,
    height,
    visibleColumns,
    pinnedColumns,
    setVisibleColumns,
    extendedData,
    displayPinIcon,
    setPinnedColumns,
  ]);
};
