import { FirmInfoPartsFragment } from '../../../common/graphql/fragments';
import {
  AllocationAcknowledgement,
  IndicationStatus,
  InstitutionalIndicationOrderType,
} from '../../../graphql';

export * from './interest-levels';

export const MISSING_MANAGER_DEFAULT_DISPLAY_NAME = 'Manager no longer in Syndicate';

type FirmDisplayNameOptions = Readonly<{
  ignoreAbbreviation?: boolean;
  defaultName?: string | null;
}>;

/**
 * Returns the firm name or abbreviation if available.
 * @param manager
 * @param defaultName - default name to return if no firm name or abbreviation is available
 * @param ignoreAbbreviation - if true, will return the firm name even when an abbreviation is available
 */
export const getFirmDisplayName = (
  manager: FirmInfoPartsFragment,
  {
    defaultName = MISSING_MANAGER_DEFAULT_DISPLAY_NAME,
    ignoreAbbreviation,
  }: FirmDisplayNameOptions = {}
) => {
  if (manager?.firmNameAbbreviation?.trim() && !ignoreAbbreviation) {
    return manager.firmNameAbbreviation;
  }

  return manager?.firmName ?? defaultName;
};

export const indicationStatusPredicate = (status: IndicationStatus, shouldSkip?: boolean) => {
  return shouldSkip || status === IndicationStatus.Active;
};
export const indicationTypePredicate = (
  type: InstitutionalIndicationOrderType,
  shouldSkip?: boolean
) => {
  return shouldSkip || type !== InstitutionalIndicationOrderType.Pass;
};
export const finalAllocationAcknowledgementStatusPredicate = (
  finalAllocationAcknowledgement: AllocationAcknowledgement | undefined,
  shouldSkip?: boolean
) => {
  return shouldSkip || finalAllocationAcknowledgement !== AllocationAcknowledgement.Rejected;
};

export const isIndicationStatusAndTypeValid = (
  status: IndicationStatus,
  type: InstitutionalIndicationOrderType
) => {
  return indicationStatusPredicate(status) && indicationTypePredicate(type);
};

export const isIndicationHiddenToSalesAndBS = (
  status: IndicationStatus,
  type: InstitutionalIndicationOrderType,
  shareQuantity: number | null | undefined
) => {
  return !isIndicationStatusAndTypeValid(status, type) && !!shareQuantity && shareQuantity > 0;
};
