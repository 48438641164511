import * as Yup from 'yup';

export const phoneNumberSchema = Yup.string()
  .max(20)
  .matches(
    /^$|^\+?\(?[0-9]{1,4}\)?[-\s./0-9]*$/,
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be a valid phone number'
  );

export const isPercentageValid = (value: number, validPercentages = [0, 1]) => {
  const formattedValue = parseFloat(value.toPrecision(6));
  return validPercentages.includes(formattedValue);
};
