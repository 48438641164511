import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import { MissingStabilizationActivitiesAlert } from './MissingStabilizationActivitiesAlert';

export const MissingStabilizationActivitiesInfoRow: React.FC = () => {
  return (
    <Table.Row isFocused={false} suppressHighlightingOnHover={true}>
      <Table.Cell colSpan={4} withBottomBorder={false}>
        <MissingStabilizationActivitiesAlert variant="in-activities-grid" />
      </Table.Cell>
    </Table.Row>
  );
};
