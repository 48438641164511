import { SubNavigation } from '@cmg/common';
import styled from 'styled-components/macro';

// TODO this should be a default style for this type of a component across modules
export const StyledSubNavigation = styled(SubNavigation)`
  border-bottom: ${({ theme }) => theme.border.smallSolidLight};
  display: flex;
  justify-content: space-between;
`;

// TODO this should be probably a default style
export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  padding-bottom: 15px;
`;

/**
 * TODO
 * this should be "navigation actions" component, probably should not be flex-start as the whole navigation
 * component should own its own spacing
 */
export const SButtonWrapper = styled.div`
  align-self: flex-start;
`;
