import { useAuth } from '@cmg/auth';
import isNil from 'lodash/isNil';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { PaymentRecipientInputData, WireTemplateType } from '../../../../../graphql';
import {
  createWireTypeConfig,
  WireTypeConfigInitialValues,
} from '../../common/context/WireTypeConfigContext.model';
import {
  SyndicateWires_ManagerRetailRetentionFeesQuery,
  useSyndicateWires_ManagerDtcNumbersQuery,
  useSyndicateWires_ManagerRetailRetentionFeesQuery,
  useSyndicateWires_WiresDisclaimerQuery,
} from '../../common/graphql';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_PaymentWirePartsFragment,
  SyndicateWires_PaymentWireValidationPartsFragment,
  useSyndicateWires_PaymentWireDetailsQuery,
  useSyndicateWires_PaymentWirePreviewQuery,
  useSyndicateWires_PaymentWiresQuery,
  useSyndicateWires_PaymentWireValidationQuery,
} from './graphql';
import { useCreatePaymentWireMutation } from './hooks/useCreatePaymentWireMutation';
import { useDeletePaymentWireMutation } from './hooks/useDeletePaymentWireMutation';
import { useSendPaymentWireMutation } from './hooks/useSendPaymentWireMutation';
import { useUpdatePaymentWireMutation } from './hooks/useUpdatePaymentWireMutation';
import { PaymentWireForm } from './PaymentWireForm';
import { PaymentRecipientUserData, validationSchema, Values } from './PaymentWireForm.model';

const wireTypeName = 'Payment';

export const getPredefinedRecipientUserData = (
  manager: SyndicateWireManager,
  initialRecipientsUserData: (PaymentRecipientUserData | null)[],
  retentionFees: SyndicateWires_ManagerRetailRetentionFeesQuery['syndicateWires']['managerRetailRetentionFees']
): PaymentRecipientUserData | null => {
  const initialManagerValues = initialRecipientsUserData.find(
    initialUserData => initialUserData?.recipient === manager.cmgEntityKey
  );

  const initialPayment = initialManagerValues?.payment;
  const initialIpoPenaltyTracking = initialManagerValues?.ipoPenaltyTracking;

  const defaultPayment =
    retentionFees.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey)?.payment ??
    null;

  if (isNil(initialPayment) && isNil(defaultPayment) && isNil(initialIpoPenaltyTracking)) {
    return null;
  } else {
    return {
      recipient: manager.cmgEntityKey,
      payment: initialPayment ?? defaultPayment,
      ipoPenaltyTracking: initialIpoPenaltyTracking ?? false,
    };
  }
};

const getPaymentRecipientInputData = (formValues: Values) => {
  const { disclaimer, dtcNumber, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<PaymentRecipientInputData>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        ipoPenaltyTracking: recipientUserData!.ipoPenaltyTracking,
        payment: recipientUserData!.payment!,
        dtcNumber: dtcNumber!,
        disclaimer,
      };
    });
};

export const wireTypeConfigPayment = createWireTypeConfig<
  Values,
  SyndicateWires_PaymentWirePartsFragment,
  SyndicateWires_PaymentWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresPayment
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresPayment,
  wireTemplateType: WireTemplateType.Payment,
  // @ts-expect-error schema type mismatch on ipoPenaltyTracking
  formValidation: validationSchema,
  createOrUpdateModalSize: 'md',
  CreateOrUpdateForm: PaymentWireForm,

  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_PaymentWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.paymentWireList,
      isLoading: loading,
      error,
    };
  },
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_PaymentWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.paymentWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_PaymentWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.paymentWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_PaymentWireValidationQuery({
      variables: { offeringId },
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return { data: data?.paymentWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreatePaymentWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: {
              userDataForRecipients: getPaymentRecipientInputData(values),
            },
          },
        });

        if (result.data?.createPaymentWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createPaymentWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdatePaymentWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: {
            offeringId,
            syndicateWireId,
            payload: {
              userDataForRecipients: getPaymentRecipientInputData(values),
            },
          },
        });

        if (result.data?.updatePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updatePaymentWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeletePaymentWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deletePaymentWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendPaymentWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendPaymentWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} wire failed.`);
        }

        return result.data?.sendPaymentWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({
    wire,
    managers,
    offeringId,
    offeringType,
    operationType,
    wireTemplateType,
  }) => {
    const {
      data: retentionFeesData,
      loading: retentionFeesLoading,
      error: retentionFeesError,
    } = useSyndicateWires_ManagerRetailRetentionFeesQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    const {
      data: managerDtcData,
      loading: managerDtcLoading,
      error: managerDtcError,
    } = useSyndicateWires_ManagerDtcNumbersQuery({
      variables: {
        offeringId,
      },
    });

    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error: disclaimerError,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const { oidcUserCmgEntityKey } = useAuth();

    const initialUserData = React.useMemo(() => {
      /**
       * disclaimer is common manager field so if it is populated for one manager,
       * then it has to be populated for all of them
       */
      const disclaimer = getDisclaimer(
        operationType,
        wire?.recipientsUserData[0]?.disclaimer,
        disclaimerData?.syndicateWires.wiresDisclaimer
      );

      const initialRecipientUserData = managers.map<PaymentRecipientUserData | null>(manager => {
        const managerValues = wire?.recipientsUserData?.find(
          ({ recipient }) => recipient === manager.cmgEntityKey
        );

        return !managerValues
          ? null
          : {
              ipoPenaltyTracking: managerValues.ipoPenaltyTracking,
              recipient: managerValues.recipient,
              payment: managerValues.payment,
            };
      });

      const predefinedRecipientUserData = managers.map<PaymentRecipientUserData | null>(manager => {
        return getPredefinedRecipientUserData(
          manager,
          initialRecipientUserData,
          retentionFeesData?.syndicateWires.managerRetailRetentionFees ?? []
        );
      });

      const defaultDtcNumber =
        managerDtcData?.syndicateWires.managerDtcNumbers.find(
          item => item.managerKey === oidcUserCmgEntityKey
        )?.managerDtcNumber ?? null;
      const initialDtcNumber = wire?.recipientsUserData[0]?.dtcNumber ?? null;

      const initialValues: WireTypeConfigInitialValues<Values> = {
        data: {
          disclaimer,
          dtcNumber: initialDtcNumber,
          recipientsUserData: initialRecipientUserData,
        },
        predefinedData: {
          disclaimer,
          dtcNumber: initialDtcNumber ?? defaultDtcNumber,
          recipientsUserData: predefinedRecipientUserData,
        },
      };

      return initialValues;
    }, [
      operationType,
      wire?.recipientsUserData,
      disclaimerData?.syndicateWires.wiresDisclaimer,
      managers,
      retentionFeesData?.syndicateWires.managerRetailRetentionFees,
      managerDtcData?.syndicateWires.managerDtcNumbers,
      oidcUserCmgEntityKey,
    ]);

    const isLoading = retentionFeesLoading || disclaimerLoading || managerDtcLoading;
    const error = retentionFeesError || disclaimerError || managerDtcError;

    return {
      isLoading,
      error,
      data: initialUserData.data,
      predefinedData: initialUserData.predefinedData,
    };
  },
});
