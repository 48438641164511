import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { useGroupAffiliates } from '../../../../hooks/useGroupAffiliates';
import { RegulatoryFilings_TnFilingPartsFragment } from '../../graphql';

export type Props = {
  memberFirms: RegulatoryFilings_TnFilingPartsFragment['finraMemberFirms'];
};

const title = 'FINRA Member Firms';

const MemberFirmsDetail: React.FC<Props> = ({ memberFirms }) => {
  const groupedTNMemberFirmsDetailData = useGroupAffiliates(memberFirms, true);

  if (!memberFirms.length || !groupedTNMemberFirmsDetailData) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Content>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Firm Name</TableCell>
                <TableCell>Member Firm CRD#</TableCell>
                <TableCell>Firm MPID</TableCell>
              </TableRow>
            </TableHead>
            {groupedTNMemberFirmsDetailData.map(([manager, memberFirms]) => (
              <TableBody key={manager} aria-label={manager}>
                <TableGroupHeader>
                  <TableGroupHeaderCell colSpan={4}>{manager}</TableGroupHeaderCell>
                </TableGroupHeader>
                {memberFirms.map(memberFirm => (
                  <TableRow key={memberFirm.firmCrdNumber}>
                    <TableCell size="medium">{memberFirm.firmName}</TableCell>
                    <TableCell size="medium">{memberFirm.firmCrdNumber}</TableCell>
                    <TableCell size="medium">{memberFirm.firmMpid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Panel.Content>
    </Panel>
  );
};

export default MemberFirmsDetail;
