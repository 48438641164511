import styled from 'styled-components/macro';

export const SInterestLevel = styled.div`
  line-height: 1.2;
  padding-top: 5px;
`;

export const SInterestLevelText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
