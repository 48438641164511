import { Alert, IconButton } from '@cmg/common';
import React from 'react';

export type Props = { onDismiss: () => void };

const PublishErrorAlert: React.FC<Props> = ({ onDismiss }) => {
  return (
    <Alert
      severity="error"
      action={<IconButton icon={{ name: 'times', size: 'lg' }} onClick={onDismiss} />}
      withMargin
    >
      <Alert.Title>Any errors on the page must be fixed before publishing designations</Alert.Title>
    </Alert>
  );
};

export default PublishErrorAlert;
