import Loading from '../../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../../common/components/indicators/server-error/ServerErrorsBanner';
import { useRoadshows_EnsureMyOrganizationIsUpToDateQuery } from '../graphql';

const MyOrganizationExistsGuard: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { loading, error } = useRoadshows_EnsureMyOrganizationIsUpToDateQuery();

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return children;
};

export default MyOrganizationExistsGuard;
