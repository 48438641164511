import { permissionsByEntity, useCheckAccountType, useCheckPermissions } from '@cmg/auth';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Banner from '../../../../common/components/indicators/banner/Banner';
import FlexLayout from '../../../../common/components/layout/flex-layout/FlexLayout';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import { useFeatureToggles } from '../../../../common/config';
import { useGetLimitedPublishedOfferingQuery } from '../../components/side-bar/hooks/useGetLimitedPublishedOfferingQuery';
import FirmLevelDemand from './components/firm-level-demand/FirmLevelDemand';
import FundLevelDemand from './components/fund-level-demand/FundLevelDemand';
import { SRouteHeader, SVisibilityNote } from './MyInternalDemandRoute.styles';

export type Props = RouteComponentProps<{
  offeringId: string;
}>;

const MyInternalDemandRoute: React.FC<Props> = ({ match }) => {
  // just one permission is enough - specific permissions are checked in relevant components
  const canRead = useCheckPermissions(
    [permissionsByEntity.Ioi.READ, permissionsByEntity.FundIoi.READ],
    false
  );
  const isBuySideAccount = useCheckAccountType('BUY_SIDE');

  const { data, loading } = useGetLimitedPublishedOfferingQuery({
    variables: { offeringId: match.params.offeringId },
  });

  const isUsOffering = data?.limitedPublishedOffering.isUsOffering;

  const isOnPlatform = data?.limitedPublishedOffering.isExecutingOnPlatform;

  const offeringCountry = data?.limitedPublishedOffering.pricingInstrumentCountryDisplayName;

  const { isInternationalOfferingsInDL } = useFeatureToggles();

  if (loading) {
    return <Spinner show={true} fullHeight={true} />;
  }

  if (
    isOnPlatform === false &&
    isUsOffering === false &&
    offeringCountry !== null &&
    !isInternationalOfferingsInDL
  ) {
    return (
      <Banner variant="warning">
        <Banner.Title>
          This feature is currently not available for international offerings.
        </Banner.Title>
      </Banner>
    );
  }

  return !canRead || !isBuySideAccount ? (
    <NotAccessible subject="Internal Demand" />
  ) : (
    <FlexLayout>
      <SRouteHeader>Internal Demand</SRouteHeader>
      <SVisibilityNote>The following information is visible to your firm only</SVisibilityNote>
      <FirmLevelDemand offeringId={match.params.offeringId} />
      <FundLevelDemand offeringId={match.params.offeringId} />
    </FlexLayout>
  );
};

export default MyInternalDemandRoute;
