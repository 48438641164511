import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useReleaseFinalInstitutionalAllocationSetMutation } from '../../hooks/useReleaseFinalInstitutionalAllocationSetMutation';
import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';

export type Props = Readonly<{ offeringId: UUID }>;

export const useReleaseFinalAllocationsDialogState = ({ offeringId }: Props) => {
  const { onClose, ...dialogState } = useDialogState();

  const [releaseFinalInstitutionalAllocationSet, { loading, error }] =
    useReleaseFinalInstitutionalAllocationSetMutation({ awaitRefetchQueries: true });

  const onSave = useCallback(async () => {
    onClose();

    await releaseFinalInstitutionalAllocationSet({ variables: { offeringId } });
    SnackbarManager.success('Successfully released final institutional allocation set');
  }, [offeringId, onClose, releaseFinalInstitutionalAllocationSet]);

  return { ...dialogState, onClose, onSave, loading, error };
};
