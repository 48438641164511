import styled from 'styled-components/macro';

export const SLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8px;
`;

export const SLogo = styled.img`
  height: 100%;
  width: 100%;
`;
