import { timeUtil } from '@cmg/common';
import React from 'react';

import {
  SInfoColumn,
  SInfoColumnText,
  SInfoColumnTitle,
  SInfoContent,
} from './AllocationLastModifiedInfo.styles';

type Props = {
  name?: string | null;
  submittedOn?: string | null;
};

const AllocationLastModifiedInfo: React.FC<Props> = ({ name, submittedOn }) => {
  return (
    <SInfoContent className="form-section">
      <SInfoColumn>
        <SInfoColumnTitle>Submitted By</SInfoColumnTitle>
        <SInfoColumnText>{name || ' '}</SInfoColumnText>
      </SInfoColumn>
      <SInfoColumn>
        <SInfoColumnTitle>Submitted On</SInfoColumnTitle>
        <SInfoColumnText>
          {submittedOn && timeUtil.formatAsDisplayDate(submittedOn)}
        </SInfoColumnText>
      </SInfoColumn>
    </SInfoContent>
  );
};

export default AllocationLastModifiedInfo;
