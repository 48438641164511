import { AgGridProcessCellForExportParams, numericUtil } from '@cmg/common';
import { RowNode } from 'ag-grid-community';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';

import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../../graphql';
import HeaderEmptyComponent from '../../../../final-settlement/designation-monitor/designation-monitor-details/designation-monitor-grid/components/header-group-checkbox/HeaderEmptyComponent';
import { AttestationFormStatusDisplayName } from '../../../attestation/Attestation.model';
import { institutionalIndicationOrderTypeLabels } from '../../../constants';
import { isIndicationHiddenToSalesAndBS } from '../../../utils';
import { getTotalsFilterPredicate } from '../../utils';
import {
  InstitutionalDemandGrid_ColumnConfigQuery,
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  InstitutionalDemandGrid_SummaryQuery,
} from './graphql';
import IndicationAcknowledgementCell from './grid-columns/acknowledgements-cell/IndicationAcknowledgementsCell';
import { acknowledgementStatusTexts } from './grid-columns/allocation-acknowledgement-cell/AllocationAcknowledgementCell.model';
import { getTypeConfigurations } from './grid-columns/type-cell/TypeCell';
import { InstitutionalFinalAllocationMap } from './hooks/useInstitutionalDemandGridSummaryQuery';
import {
  CMGColumnID,
  DecimalExtendedData,
  ExtendedTypes,
  IndicationWithDemandLevels,
  InstitutionalDemand,
  InstitutionalDemandGridOfferingColumnConfig,
  InstitutionalDraftSet,
  InstitutionalFinalSet,
  InstitutionalIndicationWithFinalAllocation,
  StringExtendedData,
  TotalsPinnedRow,
} from './types';

export const releasedFinalAllocationHeaderClass = 'released-final-allocation-header';

export const defaultInstitutionalGridColumns = [
  CMGColumnID.TYPE,
  CMGColumnID.SUBMITTED_BY,
  CMGColumnID.RELATIONSHIP,
  CMGColumnID.ACKNOWLEGMENTS,
  CMGColumnID.DEMAND_MAX,
  CMGColumnID.DEMAND_AT_MARKET,
];

export const defaultInstitutionalGridPinnedColumns = {
  right: [],
  left: [CMGColumnID.INVESTOR],
};

export const frameworkComponents = {
  emptyComponentRenderer: HeaderEmptyComponent,
  investorAcknowledgements: IndicationAcknowledgementCell,
};

export const extendedGridOptions = {
  fillViewport: true,
  withMargin: false,
  hidePagination: true,
  hideColumnResize: true,
  hideColumnSelector: true,
  hideHeader: true,
  debounceVerticalScrollbar: true,
};

/**
 * Given the base configuration for the grid from the offering.
 */
export const getGridConfig = (
  // TODO - publishedOffering type seems incorrect. it should come from the graphql query
  publishedOffering?: InstitutionalDemandGridColumnConfigArgs | null,
  syndicateManagers?: InstitutionalDemandGrid_ColumnConfigQuery['syndicateManagers']
): InstitutionalDemandGridColumnConfigArgs | null => {
  if (publishedOffering) {
    return {
      syndicate: syndicateManagers ?? publishedOffering.syndicate,
      derivedOfferPrice: publishedOffering.derivedOfferPrice,
      pricingCurrencyCode: publishedOffering.pricingCurrencyCode,
      latestFilingPriceRangeLow: publishedOffering.latestFilingPriceRangeLow,
      latestFilingPriceRangeHigh: publishedOffering.latestFilingPriceRangeHigh,
      initialFilingPriceRangeLow: publishedOffering.initialFilingPriceRangeLow,
      initialFilingPriceRangeHigh: publishedOffering.initialFilingPriceRangeHigh,
    };
  }
  return null;
};

/**
 * Given the list of Institutional Indications with share counts at all of the demand levels,
 * get the total share counts for indications at each demand level.
 * @param institutionalIndications
 * @param demandLevels
 */
export const getIndicationDemandTotals = (
  institutionalIndications: IndicationWithDemandLevels[],
  demandLevels: number[]
) => {
  const validIndications = institutionalIndications.filter(
    getTotalsFilterPredicate({ skipFinalAllocationCheck: true })
  );

  const demandMaxTotal =
    numericUtil.sum(...validIndications.map(({ demandMax }) => demandMax)) ?? 0;

  const demandAtMarketTotal =
    numericUtil.sum(...validIndications.map(({ demandAtMarket }) => demandAtMarket)) ?? 0;

  const demandColumnTotals = demandLevels.reduce<Record<number, number>>((acc, demandIncrement) => {
    const demandAtIncrementTotal = validIndications.reduce((acc, { demandLevels }) => {
      const demandAtIncrement = demandLevels[demandIncrement];
      if (demandAtIncrement) {
        return acc + demandAtIncrement;
      }

      return acc;
    }, 0);

    return {
      ...acc,
      [demandIncrement]: demandAtIncrementTotal,
    };
  }, {});

  return {
    demandMaxTotal: demandMaxTotal,
    demandAtMarketTotal: demandAtMarketTotal,
    demandLevelTotals: demandColumnTotals,
  };
};

export type InstitutionalDemandGridColumnConfigArgs = {
  latestFilingPriceRangeLow?: number | null;
  latestFilingPriceRangeHigh?: number | null;
  initialFilingPriceRangeLow?: number | null;
  initialFilingPriceRangeHigh?: number | null;
  derivedOfferPrice?: number | null;
  pricingCurrencyCode?: string | null;
  syndicate:
    | InstitutionalDemandGrid_ColumnConfigQuery['publishedOffering']['syndicate']
    | NonNullable<InstitutionalDemandGrid_ColumnConfigQuery['syndicateManagers']>;
};

/**
 * Given filing pricing values it returns the a default column configuration
 */
export const getInitialColumnConfiguration = ({
  derivedOfferPrice = null,
  latestFilingPriceRangeLow = null,
  initialFilingPriceRangeLow = null,
  latestFilingPriceRangeHigh = null,
  initialFilingPriceRangeHigh = null,
}: InstitutionalDemandGridOfferingColumnConfig) => {
  return {
    min: latestFilingPriceRangeLow || initialFilingPriceRangeLow || derivedOfferPrice || 0,
    max: latestFilingPriceRangeHigh || initialFilingPriceRangeHigh || derivedOfferPrice || 0,
    increment: 1,
    referencePrice: derivedOfferPrice,
  };
};

/*
 * Given the ExtendedTypes we will generate a mapped version of the extended data
 * that will be mapped using key from each sub-category, i.e. investorType key
 */
export const getSingleMappedExtendedData = (
  investorsExtendedData: InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery['firmInvestorsExtendedData'][number]
) => {
  const extendedTypes = Object.values(ExtendedTypes);
  const overrideFields = Object.fromEntries(
    extendedTypes.map((type: string) => [
      type,
      Object.fromEntries(
        (investorsExtendedData[type] ?? []).map((p: StringExtendedData | DecimalExtendedData) => [
          p.key,
          p,
        ])
      ),
    ])
  );
  return {
    ...investorsExtendedData,
    ...overrideFields,
  };
};

/**
 * Given a list of Institutional Demands,
 * it returns a sorted list of prices
 * @param institutionalDemands - a list of Institutional Demands
 */
export const getDemandPriceList = (institutionalDemands: InstitutionalDemand[]): number[] => {
  return institutionalDemands.length > 0
    ? institutionalDemands[0].demandLevels.map(item => item.price).sort((a, b) => a - b)
    : [];
};

/**
 * Gets wheater or not the final set is released
 * @param finalAllocationSet - the final allocation set
 */
export const isFinalSetReleased = (finalAllocationSet: InstitutionalFinalSet | null) => {
  return !!finalAllocationSet?.isReleased;
};

/**
 * Pair indication with relevant final allocation using indication id.
 */
export const getInstitutionalIndicationsWithFinalAllocation = (
  indications: InstitutionalDemandGrid_SummaryQuery['institutionalDemandGridSummary']['institutionalIndicationDemands'],
  finalAllocations: InstitutionalFinalAllocationMap
): InstitutionalIndicationWithFinalAllocation[] => {
  return indications.map(indication => {
    const finalAllocation = finalAllocations[indication.id];

    return {
      id: indication.id,
      investorInformation: {
        bankInvestorName: indication.investorInformation.bankInvestorName,
        cmgEntityName: indication.investorInformation.cmgEntityName,
        cmgEntityKey: indication.investorInformation.cmgEntityKey,
        bankInvestorKey: indication.investorInformation.bankInvestorKey,
      },
      finalAllocation: finalAllocation
        ? {
            id: finalAllocation.indicationId,
            shareQuantity: finalAllocation.shareQuantity,
          }
        : null,
    };
  });
};

// TODO: reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export function exportDataAsCsvProcessCellCallback(
  params: AgGridProcessCellForExportParams
): string {
  const colId = params.column.getColId();
  const data: IndicationWithDemandLevels | undefined = params.node?.data;
  switch (colId) {
    case CMGColumnID.TYPE: {
      const { configurationsForExport, label } = getTypeConfigurations({
        type: data!.type,
        interestLevels: data!.interestLevels,
        demandCurrencyCode: data?.currencyCode,
        pricingCurrencyCode: data?.pricingCurrencyCode,
        forGridExport: true,
      });

      const isPassed = data!.type === InstitutionalIndicationOrderType.Pass;

      if (isPassed) {
        return institutionalIndicationOrderTypeLabels[data!.type];
      } else {
        return configurationsForExport && configurationsForExport?.length > 0
          ? `${label} - ${configurationsForExport.join(', ')}`
          : label;
      }
    }
    case CMGColumnID.INVESTOR: {
      const investorName =
        data?.investorInformation?.bankInvestorName || data?.investorInformation?.cmgEntityName;
      return investorName ?? '';
    }
    case CMGColumnID.FINAL_ALLOCATION: {
      return data?.finalAllocation?.shareQuantity?.toString() ?? '';
    }
    case CMGColumnID.FINAL_ALLOCATION_STATUS: {
      return data?.finalAllocation?.investorReply?.status == null
        ? ''
        : acknowledgementStatusTexts[data.finalAllocation.investorReply?.status];
    }
    case CMGColumnID.ATTESTATION: {
      const status = data?.attestationStatus?.status;
      return status ? AttestationFormStatusDisplayName[status] : '';
    }
    default:
      if (colId.includes(CMGColumnID.DRAFT_ALLOCATION_PREFIX)) {
        return params?.value?.shareQuantity?.toString() ?? '';
      }
  }

  return params.value;
}

export const getGridSummaryDataFromQuery = (
  institutionalDemandGridSummaryData: InstitutionalDemandGrid_SummaryQuery | undefined
) => {
  const gridSummary = institutionalDemandGridSummaryData?.institutionalDemandGridSummary;
  const institutionalDemands = gridSummary?.institutionalIndicationDemands ?? [];
  const finalSet = gridSummary?.institutionalFinalAllocationSet ?? null;
  const draftSets = gridSummary?.institutionalDraftAllocationSets ?? [];

  return {
    draftSets,
    finalSet,
    indicationDemands: institutionalDemands,
    isFinalAllocationSetReleased: isFinalSetReleased(finalSet),
    indications: getInstitutionalIndicationsWithFinalAllocation(
      institutionalDemands,
      keyBy(finalSet?.allocations, 'indicationId')
    ),
  };
};

export const getTotalsPinnedRow = (
  demandMaxTotal: number,
  demandAtMarketTotal: number,
  demandLevelTotals: {},
  offeringId: string,
  indications: IndicationWithDemandLevels[],
  draftSets: readonly InstitutionalDraftSet[],
  finalSet: InstitutionalFinalSet | null
): TotalsPinnedRow => {
  const validIndications = indications.filter(getTotalsFilterPredicate());
  const indicationsHiddenToSalesAndBS = indications.filter(({ status, type, finalAllocation }) =>
    isIndicationHiddenToSalesAndBS(status, type, finalAllocation?.shareQuantity)
  );

  const filteredFinalAllocationTotalShares =
    numericUtil.sum(
      ...validIndications.map(({ finalAllocation }) => finalAllocation?.shareQuantity)
    ) ?? 0;

  return {
    id: 'totals-row',
    demandMax: demandMaxTotal,
    demandAtMarket: demandAtMarketTotal,
    demandLevels: demandLevelTotals,
    offeringId,
    filteredIndicationCount: indications.length,
    filteredFinalAllocationTotals: {
      totalAllocation: filteredFinalAllocationTotalShares,
      unallocatedShares:
        finalSet && !isNil(finalSet.dealLayout.institutionalTarget)
          ? finalSet.dealLayout.institutionalTarget - filteredFinalAllocationTotalShares
          : undefined,
      isAnyIndicationHiddenToSalesAndBS: indicationsHiddenToSalesAndBS.length > 0,
    },
    filteredDraftAllocationSetTotals: draftSets.map(({ id, dealLayout }) => {
      const totalAllocation =
        numericUtil.sum(
          ...validIndications.map(
            ({ draftAllocationsBySetId }) => draftAllocationsBySetId[id!]?.shareQuantity
          )
        ) ?? 0;
      return {
        allocationSetId: id,
        totalAllocation,
        unallocatedShares: !isNil(dealLayout.institutionalTarget)
          ? dealLayout.institutionalTarget - totalAllocation
          : undefined,
      };
    }),
  };
};

export function isInstitutionalDemandGridSelectable(row: RowNode): boolean {
  const gridRow = row.data as IndicationWithDemandLevels;
  return gridRow.status === IndicationStatus.Active;
}
