import { Panel } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDocumentTitle } from '../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../common/util/routeFactory';
import AllocationAcknowledgementModal from './components/my-orders-grid/allocation-acknowledgement-cell/AllocationAcknowledgementModal';
import MyOrdersGrid from './components/my-orders-grid/MyOrdersGrid';

export type Props = RouteComponentProps;

const MyOrdersRoute: React.FC<Props> = () => {
  useDocumentTitle(routeFactory.myOrders.getDocumentTitle());

  return (
    <Panel fillViewport testId={xcSelectors.myOrdersScreen.testId}>
      <MyOrdersGrid />
      <AllocationAcknowledgementModal />
    </Panel>
  );
};

export default MyOrdersRoute;
