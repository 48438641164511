import * as yup from 'yup';

import { Roadshows_CreateCompanyMutationVariables } from './graphql';

export type Values = {
  companyName: string;
};

export const initialValues: Values = {
  companyName: '',
};

export const validationSchema = yup.object({
  companyName: yup.string().label('Company Name').nullable().required(),
});

export const getCreateCompanyVariables = (
  formikValues: Values
): Roadshows_CreateCompanyMutationVariables => ({
  name: formikValues.companyName,
});
