import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useBulkResendProspectus } from '../../../offering-side-panel/order-book/compliance/prospectus/hooks/useBulkResendProspectus';
import type { DemandConfigValues } from '../demand-grid/types';
import { useGetInvestorKeys } from './useGetInvestorKeys';

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
  rowSelectionState: GridRowSelectionState;
}>;

export const useBulkSendProspectusState = ({
  offeringId,
  rowSelectionState,
  demandConfig,
}: Props) => {
  const {
    resendProspectus,
    prospectusOnSendValidation,
    loading: bulkResendProspectusLoading,
    error: bulkResendProspectusError,
  } = useBulkResendProspectus({ offeringId });

  const {
    getInvestorKeys,
    loading: getInvestorKeysLoading,
    error: getInvestorKeysError,
    investors,
  } = useGetInvestorKeys({
    offeringId,
    demandConfig,
  });

  const onSave = useCallback(async () => {
    const investorKeys = await getInvestorKeys(rowSelectionState);

    if (investorKeys.length > 0) {
      const resendResult = await resendProspectus(investorKeys);
      if (resendResult && resendResult.numOfInvestorsRecievingProspectus > 0) {
        SnackbarManager.success('Prospectus delivery has been successfully scheduled for sending');
      }
    }
  }, [getInvestorKeys, resendProspectus, rowSelectionState]);

  const loading = getInvestorKeysLoading || bulkResendProspectusLoading;
  const error = getInvestorKeysError || bulkResendProspectusError;

  return { onSave, loading, error, investors, validation: prospectusOnSendValidation };
};
