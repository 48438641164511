import { useFeatureToggles } from '../../../../../../../common/config';
import { isIndicationStatusAndTypeValid } from '../../../../../../order-book/utils';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../../hooks/useIdentifyOrderBookPersona';
import { IndicationType } from '../Allocation.model';
import { IndicationActivity_FinalAllocationPartsFragment } from '../graphql/__generated__';

export type Props = {
  isReleased: boolean;
  allocation: IndicationActivity_FinalAllocationPartsFragment | null | undefined;
  indication: IndicationType | null | undefined;
};

export const useCanDisplayAllocationActions = ({ isReleased, allocation, indication }: Props) => {
  const { type: personaType } = useIdentifyOrderBookPersona({ offeringId: indication?.offeringId });
  const { isOrderBookBuySideInvestorReplyOn } = useFeatureToggles();

  if (!isReleased) {
    return false;
  }

  if (!allocation && indication?.allocationShares === null) {
    return false;
  }

  if (personaType === OrderBookPersonaType.SYNDICATE) {
    return true;
  }

  if (personaType === OrderBookPersonaType.BUY_SIDE && !isOrderBookBuySideInvestorReplyOn) {
    return false;
  }

  return indication && isIndicationStatusAndTypeValid(indication.status, indication.type);
};
