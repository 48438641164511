import { numericUtil } from '@cmg/common';
import React from 'react';

import {
  STotalShortPositionCell,
  STotalShortPositionValueCell,
} from './TotalShortPositionInfo.styles';

export type Props = {
  finalOverallotmentAuthorized: number;
  additionalShortPosition: number;
  totalShortPosition: number;
};

const TotalShortPositionInfo: React.FC<Props> = ({
  finalOverallotmentAuthorized,
  additionalShortPosition,
  totalShortPosition,
}) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>Final Overallotment Authorized</td>
          <td align="right">
            {numericUtil.getDisplayValueForNumber(-finalOverallotmentAuthorized, 0)}
          </td>
        </tr>
        <tr>
          <td>Plus: Additional Short Position</td>
          <td align="right">{numericUtil.getDisplayValueForNumber(additionalShortPosition, 0)}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <hr />
          </td>
        </tr>
        <tr>
          <STotalShortPositionCell>Total Short Position</STotalShortPositionCell>
          <STotalShortPositionValueCell>
            {numericUtil.getDisplayValueForNumber(totalShortPosition, 0)}
          </STotalShortPositionValueCell>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalShortPositionInfo;
