import { ODSTable as Table } from '@cmg/common';
import { Form } from 'formik';
import styled from 'styled-components/macro';

export const StyledForm = styled(Form)`
  display: contents;
`;

export const StyledCenteredCell = styled(Table.Cell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledStatusCell = styled(Table.Cell)`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;
