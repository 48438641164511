import React from 'react';

import { InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables } from './advanced-search/graphql';

export const AdvancedInvestorSearchFlowContext = React.createContext<{
  searchForm: InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables;
  setSearchForm: (form: InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables) => void;
}>({
  searchForm: {
    searchText: '',
    firmKey: '',
    cik: '',
    lei: '',
  },
  setSearchForm: () => {},
});

export const AdvancedInvestorSearchFlowProvider = ({ children, searchText }) => {
  const [searchForm, setSearchForm] =
    React.useState<InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables>({
      searchText: searchText || '',
      lei: '',
      crd: '',
      firmKey: '',
      cik: '',
    });

  return (
    <AdvancedInvestorSearchFlowContext.Provider
      value={{
        searchForm,
        setSearchForm,
      }}
    >
      {children}
    </AdvancedInvestorSearchFlowContext.Provider>
  );
};

export function useAdvancedInvestorSearchFlow() {
  return React.useContext(AdvancedInvestorSearchFlowContext);
}
