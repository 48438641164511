/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_GrossSpreadPartsFragment = {
  readonly __typename?: 'Syndicate';
  readonly id: string;
  readonly grossSpread?: number | null;
};

export type OfferingSetup_ManagerPartsFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly isJuniorRole: boolean;
  readonly isParticipating: boolean;
  readonly economics?: number | null;
  readonly firmName: string;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_ManagerResponsibilitiesPartsFragment = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: string;
  readonly stabilizationAgent?: string | null;
  readonly settlementAgent?: string | null;
  readonly logisticsAgent?: string | null;
  readonly leftLead?: string | null;
};

export type OfferingSetup_SyndicateQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_SyndicateQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly isAuthor: boolean;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly id: string;
      readonly grossSpread?: number | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isJuniorRole: boolean;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
        readonly recordStatus: Types.RecordStatus;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly id: string;
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
  };
};

export type OfferingSetup_UpdateSyndicateMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateSyndicateInput;
}>;

export type OfferingSetup_UpdateSyndicateMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSyndicate: {
    readonly __typename?: 'Syndicate';
    readonly id: string;
    readonly grossSpread?: number | null;
  };
};

export type OfferingSetup_CreateManagerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateManagerInput;
}>;

export type OfferingSetup_CreateManagerMutation = {
  readonly __typename?: 'Mutation';
  readonly createManager: {
    readonly __typename?: 'Manager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly role: Types.ManagerRole;
    readonly isJuniorRole: boolean;
    readonly isParticipating: boolean;
    readonly economics?: number | null;
    readonly firmName: string;
    readonly recordStatus: Types.RecordStatus;
  };
};

export type OfferingSetup_UpdateManagerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
  input: Types.UpdateManagerInput;
}>;

export type OfferingSetup_UpdateManagerMutation = {
  readonly __typename?: 'Mutation';
  readonly updateManager: {
    readonly __typename?: 'Manager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly role: Types.ManagerRole;
    readonly isJuniorRole: boolean;
    readonly isParticipating: boolean;
    readonly economics?: number | null;
    readonly firmName: string;
    readonly recordStatus: Types.RecordStatus;
  };
};

export type OfferingSetup_DeleteManagerMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type OfferingSetup_DeleteManagerMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteManager: { readonly __typename?: 'DeleteManager'; readonly id: string };
};

export type OfferingSetup_UpdateManagerSequenceMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateManagerSequenceInput;
}>;

export type OfferingSetup_UpdateManagerSequenceMutation = {
  readonly __typename?: 'Mutation';
  readonly updateManagerSequence: boolean;
};

export type OfferingSetup_UpdateManagerResponsibilitiesMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.ManagerResponsibilitiesInput;
}>;

export type OfferingSetup_UpdateManagerResponsibilitiesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateManagerResponsibilities: {
    readonly __typename?: 'ManagerResponsibilities';
    readonly id: string;
    readonly stabilizationAgent?: string | null;
    readonly settlementAgent?: string | null;
    readonly logisticsAgent?: string | null;
    readonly leftLead?: string | null;
  };
};

export const OfferingSetup_GrossSpreadPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_GrossSpreadParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Syndicate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerResponsibilitiesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerResponsibilitiesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_SyndicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Syndicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_ManagerResponsibilitiesParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerResponsibilitiesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_SyndicateQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_SyndicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_SyndicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_SyndicateQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_SyndicateQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_SyndicateQuery,
    OfferingSetup_SyndicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_SyndicateQuery, OfferingSetup_SyndicateQueryVariables>(
    OfferingSetup_SyndicateDocument,
    options
  );
}
export function useOfferingSetup_SyndicateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_SyndicateQuery,
    OfferingSetup_SyndicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_SyndicateQuery, OfferingSetup_SyndicateQueryVariables>(
    OfferingSetup_SyndicateDocument,
    options
  );
}
export type OfferingSetup_SyndicateQueryHookResult = ReturnType<
  typeof useOfferingSetup_SyndicateQuery
>;
export type OfferingSetup_SyndicateLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_SyndicateLazyQuery
>;
export type OfferingSetup_SyndicateQueryResult = Apollo.QueryResult<
  OfferingSetup_SyndicateQuery,
  OfferingSetup_SyndicateQueryVariables
>;
export const OfferingSetup_UpdateSyndicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateSyndicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSyndicateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSyndicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_GrossSpreadParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_GrossSpreadParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Syndicate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateSyndicateMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateSyndicateMutation,
  OfferingSetup_UpdateSyndicateMutationVariables
>;

/**
 * __useOfferingSetup_UpdateSyndicateMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateSyndicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateSyndicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateSyndicateMutation, { data, loading, error }] = useOfferingSetup_UpdateSyndicateMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateSyndicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateSyndicateMutation,
    OfferingSetup_UpdateSyndicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateSyndicateMutation,
    OfferingSetup_UpdateSyndicateMutationVariables
  >(OfferingSetup_UpdateSyndicateDocument, options);
}
export type OfferingSetup_UpdateSyndicateMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateSyndicateMutation
>;
export type OfferingSetup_UpdateSyndicateMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateSyndicateMutation>;
export type OfferingSetup_UpdateSyndicateMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateSyndicateMutation,
  OfferingSetup_UpdateSyndicateMutationVariables
>;
export const OfferingSetup_CreateManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateManagerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateManagerMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateManagerMutation,
  OfferingSetup_CreateManagerMutationVariables
>;

/**
 * __useOfferingSetup_CreateManagerMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateManagerMutation, { data, loading, error }] = useOfferingSetup_CreateManagerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateManagerMutation,
    OfferingSetup_CreateManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateManagerMutation,
    OfferingSetup_CreateManagerMutationVariables
  >(OfferingSetup_CreateManagerDocument, options);
}
export type OfferingSetup_CreateManagerMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateManagerMutation
>;
export type OfferingSetup_CreateManagerMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateManagerMutation>;
export type OfferingSetup_CreateManagerMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_CreateManagerMutation,
  OfferingSetup_CreateManagerMutationVariables
>;
export const OfferingSetup_UpdateManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateManagerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateManagerMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateManagerMutation,
  OfferingSetup_UpdateManagerMutationVariables
>;

/**
 * __useOfferingSetup_UpdateManagerMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateManagerMutation, { data, loading, error }] = useOfferingSetup_UpdateManagerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateManagerMutation,
    OfferingSetup_UpdateManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateManagerMutation,
    OfferingSetup_UpdateManagerMutationVariables
  >(OfferingSetup_UpdateManagerDocument, options);
}
export type OfferingSetup_UpdateManagerMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateManagerMutation
>;
export type OfferingSetup_UpdateManagerMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateManagerMutation>;
export type OfferingSetup_UpdateManagerMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateManagerMutation,
  OfferingSetup_UpdateManagerMutationVariables
>;
export const OfferingSetup_DeleteManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteManager' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteManagerMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteManagerMutation,
  OfferingSetup_DeleteManagerMutationVariables
>;

/**
 * __useOfferingSetup_DeleteManagerMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteManagerMutation, { data, loading, error }] = useOfferingSetup_DeleteManagerMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useOfferingSetup_DeleteManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteManagerMutation,
    OfferingSetup_DeleteManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteManagerMutation,
    OfferingSetup_DeleteManagerMutationVariables
  >(OfferingSetup_DeleteManagerDocument, options);
}
export type OfferingSetup_DeleteManagerMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteManagerMutation
>;
export type OfferingSetup_DeleteManagerMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteManagerMutation>;
export type OfferingSetup_DeleteManagerMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteManagerMutation,
  OfferingSetup_DeleteManagerMutationVariables
>;
export const OfferingSetup_UpdateManagerSequenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateManagerSequence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagerSequenceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerSequence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateManagerSequenceMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateManagerSequenceMutation,
  OfferingSetup_UpdateManagerSequenceMutationVariables
>;

/**
 * __useOfferingSetup_UpdateManagerSequenceMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateManagerSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateManagerSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateManagerSequenceMutation, { data, loading, error }] = useOfferingSetup_UpdateManagerSequenceMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateManagerSequenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateManagerSequenceMutation,
    OfferingSetup_UpdateManagerSequenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateManagerSequenceMutation,
    OfferingSetup_UpdateManagerSequenceMutationVariables
  >(OfferingSetup_UpdateManagerSequenceDocument, options);
}
export type OfferingSetup_UpdateManagerSequenceMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateManagerSequenceMutation
>;
export type OfferingSetup_UpdateManagerSequenceMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateManagerSequenceMutation>;
export type OfferingSetup_UpdateManagerSequenceMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateManagerSequenceMutation,
  OfferingSetup_UpdateManagerSequenceMutationVariables
>;
export const OfferingSetup_UpdateManagerResponsibilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateManagerResponsibilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ManagerResponsibilitiesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagerResponsibilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_ManagerResponsibilitiesParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerResponsibilitiesParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateManagerResponsibilitiesMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateManagerResponsibilitiesMutation,
  OfferingSetup_UpdateManagerResponsibilitiesMutationVariables
>;

/**
 * __useOfferingSetup_UpdateManagerResponsibilitiesMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateManagerResponsibilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateManagerResponsibilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateManagerResponsibilitiesMutation, { data, loading, error }] = useOfferingSetup_UpdateManagerResponsibilitiesMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateManagerResponsibilitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateManagerResponsibilitiesMutation,
    OfferingSetup_UpdateManagerResponsibilitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateManagerResponsibilitiesMutation,
    OfferingSetup_UpdateManagerResponsibilitiesMutationVariables
  >(OfferingSetup_UpdateManagerResponsibilitiesDocument, options);
}
export type OfferingSetup_UpdateManagerResponsibilitiesMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateManagerResponsibilitiesMutation
>;
export type OfferingSetup_UpdateManagerResponsibilitiesMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateManagerResponsibilitiesMutation>;
export type OfferingSetup_UpdateManagerResponsibilitiesMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateManagerResponsibilitiesMutation,
  OfferingSetup_UpdateManagerResponsibilitiesMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeOfferingSetup_SyndicateMockResponse = makeMockResponse<
  OfferingSetup_SyndicateQueryVariables,
  OfferingSetup_SyndicateQuery
>(OfferingSetup_SyndicateDocument);
