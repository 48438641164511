import { UnsavedChangesGuard, UnsavedChangesGuardProps } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import FormikUnsavedChangesModal from '../../modals/formik-unsaved-changes-modal/FormikUnsavedChangesModal';

export type Props = Omit<UnsavedChangesGuardProps, 'when'> & {
  when?: boolean;
};

/**
 * FormikUnsavedChangesGuard is a wrapper around UnsavedChangesGuard that uses formik to
 * handle errors and renders the new UnsavedChangesGuardModal. it needs to be wrapped around a Formik Context.
 */
const FormikUnsavedChangesGuard: React.FC<Props> = ({ children, when, onLeave, ...props }) => {
  const formik = useFormikContext();

  const { dirty, resetForm } = formik;

  // If is not overriden the default should be when the form is dirty
  const passedWhen = when !== undefined ? when : dirty;

  // If not overriden the default value for onLeave should be resetForm
  const passedOnLeave = onLeave || resetForm;

  return (
    <UnsavedChangesGuard
      when={passedWhen}
      onLeave={passedOnLeave}
      {...props}
      renderModal={modalProps => <FormikUnsavedChangesModal {...modalProps} />}
    >
      {children}
    </UnsavedChangesGuard>
  );
};

export default FormikUnsavedChangesGuard;
