import { AgGridColumnApi } from '@cmg/common';
import { ErrorIcon, Grid, Tooltip, WarningIcon } from '@cmg/design-system';
import React, { useEffect } from 'react';

import { CMGColumnID } from '../../types';
import { RowAlertHeaderTooltipContent } from './RowAlertHeaderTooltipContent';

export type Props = {
  alertsCount: number;
  columnApi: AgGridColumnApi;
  sortingOrder: 'asc' | 'desc';
  setSort?: (sort: string | null | undefined) => void;
  isWarningOnly?: boolean;
};

const sortingMap = new Map<string | null | undefined, string | null>([
  ['asc', 'desc'],
  ['desc', null],
  [null, 'asc'],
]);

export const RowAlertHeaderRenderer: React.FC<Props> = ({
  alertsCount,
  columnApi,
  sortingOrder,
  setSort,
  isWarningOnly,
}) => {
  useEffect(() => {
    setSort && setSort(sortingOrder);
  }, [setSort, sortingOrder]);

  const handleSort = () => {
    const currentSort = columnApi
      .getColumnState()
      .find(column => column.colId === CMGColumnID.ROW_ALERT)?.sort;
    const nextSort = sortingMap.get(currentSort);
    setSort && setSort(nextSort);

    columnApi.resetColumnState();
    columnApi.applyColumnState({
      state: [
        {
          colId: CMGColumnID.ROW_ALERT,
          sortIndex: 0,
          sort: setSort != null ? nextSort : sortingOrder,
        },
        {
          colId: CMGColumnID.INVESTOR,
          sortIndex: 1,
          sort: 'asc',
        },
      ],
    });
  };

  return (
    <Grid container alignContent="center" justifyContent="center">
      <Tooltip
        title={<RowAlertHeaderTooltipContent alertsCount={alertsCount} onSortHandle={handleSort} />}
        variant={isWarningOnly ? 'warning' : 'error'}
        placement="top"
      >
        {isWarningOnly ? (
          <WarningIcon color="warning" fontSize="small" />
        ) : (
          <ErrorIcon color="error" fontSize="small" />
        )}
      </Tooltip>
    </Grid>
  );
};

export default RowAlertHeaderRenderer;
