import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteFreeFormWireMutation,
  SyndicateWires_FreeFormWiresDocument,
  useSyndicateWires_DeleteFreeFormWireMutation,
} from '../graphql';

export type DeleteFreeFormWireMutation = SyndicateWires_DeleteFreeFormWireMutation;

export const useDeleteFreeFormWireMutation = () => {
  return useSyndicateWires_DeleteFreeFormWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_FreeFormWiresDocument];
  return getManyOperationNames(documentNodes);
}
