import { Icon, LinkButton, ODSTable as Table } from '@cmg/common';
import React from 'react';

import dealTeamImage from '../../../../../../../common/assets/deal-team.svg';
import Loading from '../../../../../../../common/components/indicators/loading/Loading';
import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { Roadshows_DealTeamMemberLimitedPartsFragment } from '../../../../../common/graphql';
import IdentityIcon from '../../../../../single-deal-team/common/identity-icon/IdentityIcon';
import { SelectedDealTeamMember } from '../AddMembersModal.model';
import { isMemberParticipating } from './AddMembersSearchResults.model';
import { SHeroWrapper, StyledTableRow, SWrapper } from './AddMembersSearchResults.styles';

export type Props = {
  hasEmptySearch: boolean;
  infiniteScrollRef: React.RefObject<HTMLDivElement>;
  memberList: readonly Roadshows_DealTeamMemberLimitedPartsFragment[];
  selectedMemberList: SelectedDealTeamMember[];
  participatingMemberList: SelectedDealTeamMember[];
  isLoading: boolean;
  onAddMember: (member: Roadshows_DealTeamMemberLimitedPartsFragment) => void;
};

const AddMembersSearchResults: React.FC<Props> = ({
  hasEmptySearch,
  infiniteScrollRef,
  memberList,
  selectedMemberList,
  participatingMemberList,
  isLoading,
  onAddMember,
}) => {
  const isTableHidden = (memberList.length === 0 || hasEmptySearch) && !isLoading;

  return (
    <SWrapper ref={infiniteScrollRef}>
      {isLoading && <Loading />}
      {isTableHidden && (
        <SHeroWrapper>
          <HeroGraphics
            image={dealTeamImage}
            imageAlt="Search Deal Teams"
            subtitle={
              hasEmptySearch
                ? 'Search and select members from My Deal Teams to participate in the Roadshow'
                : 'No Members found. Create new Deal Team member to participate in Roadshow.'
            }
          />
          {!hasEmptySearch && (
            <LinkButton testId="add members link button" iconLeft={{ name: 'plus' }} inline>
              Create new Member
            </LinkButton>
          )}
        </SHeroWrapper>
      )}
      {!isTableHidden && (
        <Table gridTemplateColumns="28px 1fr 1fr 1fr 1fr">
          <Table.Row>
            <Table.TableHeaderCell />
            <Table.TableHeaderCell>Name</Table.TableHeaderCell>
            <Table.TableHeaderCell>Organization</Table.TableHeaderCell>
            <Table.TableHeaderCell>Department</Table.TableHeaderCell>
            <Table.TableHeaderCell />
          </Table.Row>

          {memberList.map(member => {
            const isSelected = !!selectedMemberList.find(({ id }) => id === member.id);
            const isDisabled = isSelected || isMemberParticipating(member, participatingMemberList);
            const handleRowClick = () => {
              if (!isDisabled) {
                onAddMember(member);
              }
            };

            return (
              <StyledTableRow key={member.id} isDisabled={isDisabled} onClick={handleRowClick}>
                <Table.Cell>{member.isMyOrganization && <IdentityIcon size={12} />}</Table.Cell>
                <Table.Cell>{member.fullName}</Table.Cell>
                <Table.Cell>{member.firmName}</Table.Cell>
                <Table.Cell>{member.department}</Table.Cell>
                <Table.Cell>
                  {isDisabled && (
                    <React.Fragment>
                      <Icon name="check-circle" variant="light" />{' '}
                      {isSelected ? 'Selected' : 'Participating'}
                    </React.Fragment>
                  )}
                </Table.Cell>
              </StyledTableRow>
            );
          })}
        </Table>
      )}
    </SWrapper>
  );
};

export default AddMembersSearchResults;
