import { Icon, IconNameParts } from '@cmg/common';
import React from 'react';

import HeroGraphicsLayout, { HeroGraphicsSize } from './HeroGraphics.styles';

type IconProps = {
  image: string;
  imageAlt: string;
  iconNameParts?: never;
  iconColor?: never;
};

type ImageProps = {
  iconNameParts: IconNameParts;
  iconColor: string;
  image?: never;
  imageAlt?: never;
};

export type Props = {
  testId?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  size?: HeroGraphicsSize;
} & (IconProps | ImageProps);

export const HeroGraphics: React.FC<Props> = ({
  testId,
  image,
  imageAlt,
  subtitle,
  title,
  iconNameParts,
  iconColor,
  size = 'large',
  children,
}) => {
  return (
    <HeroGraphicsLayout size={size} data-test-id={testId}>
      <HeroGraphicsLayout.Container>
        {image && <HeroGraphicsLayout.Image size={size} src={image} alt={imageAlt} />}
        {iconNameParts && (
          <Icon size={size === 'large' ? '5x' : '3x'} color={iconColor} {...iconNameParts} />
        )}

        {!!(title || subtitle) && (
          <HeroGraphicsLayout.TextContainer>
            {!!title && (
              <HeroGraphicsLayout.Title size={size}>
                {typeof title === 'function' ? title() : title}
              </HeroGraphicsLayout.Title>
            )}
            {!!subtitle && (
              <HeroGraphicsLayout.Subtitle size={size}>
                {typeof subtitle === 'function' ? subtitle() : subtitle}
              </HeroGraphicsLayout.Subtitle>
            )}
          </HeroGraphicsLayout.TextContainer>
        )}

        {children}
      </HeroGraphicsLayout.Container>
    </HeroGraphicsLayout>
  );
};
