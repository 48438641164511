import {
  SItem,
  StyledDropdownContainer,
  StyledIconButton,
  SWrapper,
} from './PinColumnButton.styles';

export type PinnedValue = 'RIGHT' | 'LEFT' | 'UNPINNED';

export type Props = {
  disabled?: boolean;
  pinnedValue: PinnedValue;
  onPinLeft: () => void;
  onPinRight: () => void;
  onUnpin: () => void;
};

const PinColumnButton: React.FC<Props> = ({
  disabled,
  pinnedValue,
  onPinLeft,
  onPinRight,
  onUnpin,
}) => {
  return (
    <StyledDropdownContainer
      content={({ setVisibility }) => (
        <SWrapper>
          <SItem
            active={pinnedValue === 'LEFT'}
            onClick={() => {
              onPinLeft();
              setVisibility(false);
            }}
          >
            Pin Left
          </SItem>
          <SItem
            active={pinnedValue === 'RIGHT'}
            onClick={() => {
              onPinRight();
              setVisibility(false);
            }}
          >
            Pin Right
          </SItem>
          <hr />
          <SItem
            disabled={pinnedValue === 'UNPINNED'}
            onClick={() => {
              onUnpin();
              setVisibility(false);
            }}
          >
            Unpin
          </SItem>
        </SWrapper>
      )}
      popoverVariant="LIGHT"
    >
      <StyledIconButton
        disabled={disabled}
        icon={{ name: 'thumbtack', variant: pinnedValue === 'UNPINNED' ? 'light' : 'solid' }}
      />
    </StyledDropdownContainer>
  );
};

export default PinColumnButton;
