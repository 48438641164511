import type { UUID } from '@cmg/common';
import React from 'react';
import type { RouteComponentProps } from 'react-router';

import { OrderBookPageLayout } from '../components/order-book-page-layout/OrderBookPageLayout';

export type Props = RouteComponentProps<{ offeringId: UUID }>;

/**
 * Route for the Order Book DSP Demand page
 */
export const DspDemandRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  return (
    <OrderBookPageLayout subNavigationProps={{ offeringId }}>
      <div>
        <p>DSP Demand - todo</p>
      </div>
    </OrderBookPageLayout>
  );
};

export default DspDemandRoute;
