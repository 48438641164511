import { InterestLevelLimitType, OfferingType } from '../../../graphql';
import { usOfferingTypeToInternational } from '../../../types/domain/offering/constants';
import { limitTypeLabels } from '../../../types/domain/order-book/constants';

export const isIPOType = (type: OfferingType | undefined) =>
  type && [OfferingType.Ipo, OfferingType.IpoSpac].includes(type);

export const limitTypeOptions = {
  Price: {
    label: limitTypeLabels[InterestLevelLimitType.Price],
    value: InterestLevelLimitType.Price,
  },
  Discount: {
    label: limitTypeLabels[InterestLevelLimitType.Discount],
    value: InterestLevelLimitType.Discount,
  },
  Premium: {
    label: limitTypeLabels[InterestLevelLimitType.Premium],
    value: InterestLevelLimitType.Premium,
  },
};

export function getLimitTypesForOffering(offeringType: OfferingType) {
  switch (offeringType) {
    case OfferingType.Ipo:
    case OfferingType.IpoSpac:
      return [limitTypeOptions.Price];
    default:
      return [limitTypeOptions.Price, limitTypeOptions.Discount, limitTypeOptions.Premium];
  }
}

export function isInternationalOffering({
  pricingInstrumentCountryCode,
  isExecutingOnPlatform,
}: {
  pricingInstrumentCountryCode?: string | null;
  isExecutingOnPlatform?: Boolean;
}): Boolean {
  return (
    !!pricingInstrumentCountryCode &&
    pricingInstrumentCountryCode !== 'US' &&
    !!isExecutingOnPlatform
  );
}

export function getOfferingTypeFilterCondition({
  filters,
  pricingInstrumentCountryCode,
  offeringType,
}: {
  filters: string[];
  pricingInstrumentCountryCode?: string | null;
  offeringType: OfferingType;
}) {
  if (filters.length) {
    if (
      pricingInstrumentCountryCode &&
      pricingInstrumentCountryCode !== 'US' &&
      offeringType !== OfferingType.Ipo &&
      offeringType !== OfferingType.IpoSpac
    ) {
      return filters.includes(usOfferingTypeToInternational[offeringType]);
    }
    return filters.includes(offeringType);
  }
  return true;
}

export function getOfferingTypeFilterOptions({ pricingInstrumentCountryCode, offeringType }) {
  if (
    pricingInstrumentCountryCode &&
    pricingInstrumentCountryCode !== 'US' &&
    offeringType !== OfferingType.Ipo &&
    offeringType !== OfferingType.IpoSpac
  ) {
    return usOfferingTypeToInternational[offeringType];
  }
  return offeringType;
}
