import styled from 'styled-components/macro';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import Modal from '../../../../../../common/components/overlays/modal/Modal';

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  gap: 16px;
`;

export const SEmptyFiller = styled.span`
  flex-grow: 1;
`;

export const StyledSearchPanel = styled(Panel)`
  width: 715px;
`;

export const StyledSelectedMembersPanel = styled(Panel)`
  width: 300px;
`;
