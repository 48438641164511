import { OnDataOptions } from '@apollo/client/react/types/types';
import type { UUID } from '@cmg/common';
import { useCallback, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  OrderBook_InstitutionalDemand_RecentUpdatesSubscription,
  OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryPartsFragment,
  useOrderBook_InstitutionalDemand_RecentUpdatesSubscription,
} from '../graphql/__generated__';

export type RecentUpdate = {
  isRead: boolean;
} & OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryPartsFragment;

type State = {
  recentUpdates: RecentUpdate[];
  hasUnread: boolean;
};

export type Props = Readonly<{
  offeringId: UUID;
}>;

const MAX_UPDATES_IN_MEMORY = 100;

export const useRecentUpdates = ({ offeringId }: Props) => {
  const [state, setState] = useState<State>({
    recentUpdates: [],
    hasUnread: false,
  });

  const newUpdatesBufferRef = useRef<RecentUpdate[]>([]);

  const pushUpdatesToStateDebounced = useDebouncedCallback(
    () => {
      setState(currState => {
        const nextHasUnread = !!newUpdatesBufferRef.current.length || currState.hasUnread;
        const nextRecentUpdates = [...newUpdatesBufferRef.current, ...currState.recentUpdates];

        newUpdatesBufferRef.current = [];

        return {
          hasUnread: nextHasUnread,
          /**
           * We only want to keep the most recent updates in memory to avoid memory leaks.
           */
          recentUpdates: nextRecentUpdates.slice(
            0,
            Math.min(nextRecentUpdates.length, MAX_UPDATES_IN_MEMORY)
          ),
        };
      });
    },
    2000,
    { maxWait: 5000 }
  );

  const handleRecentUpdateData = useCallback(
    ({ data }: OnDataOptions<OrderBook_InstitutionalDemand_RecentUpdatesSubscription>) => {
      const { syndicateInstitutionalGridChangedSummaries } = data.data ?? {};

      if (!syndicateInstitutionalGridChangedSummaries) {
        return;
      }

      /**
       * When the new updates come in, we prepend them to the buffer rather than the state directly.
       * This is because we want to debounce the state update to avoid unnecessary re-renders in case
       * multiple updates come in quickly in a short period of time.
       */
      newUpdatesBufferRef.current = [
        { ...syndicateInstitutionalGridChangedSummaries, isRead: false },
        ...newUpdatesBufferRef.current,
      ];

      pushUpdatesToStateDebounced();
    },
    [pushUpdatesToStateDebounced]
  );

  const { error, loading } = useOrderBook_InstitutionalDemand_RecentUpdatesSubscription({
    variables: { offeringId },
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscriptionhookoptions-interface-ignoreresults
    ignoreResults: true,
    onData: handleRecentUpdateData,
  });

  const markAllAsRead = useCallback(() => {
    if (!state.hasUnread) {
      return;
    }

    setState(currState => ({
      hasUnread: false,
      recentUpdates: currState.recentUpdates.map(update => ({ ...update, isRead: true })),
    }));
  }, [state.hasUnread]);

  return { ...state, error, loading, markAllAsRead };
};
