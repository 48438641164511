import styled from 'styled-components/macro';

export const SFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px;
`;
