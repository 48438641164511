import { LinkButton, ODSTable as Table, Option, SelectField, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { DealTeamMemberStatus, DealTeamMemberType } from '../../../../../../../graphql';
import ErrorIcon from '../../../../common/error-icon/ErrorIcon';
import IdentityIcon from '../../../../common/identity-icon/IdentityIcon';
import { DealTeamMember } from '../../DealTeamForm.model';
import ActionButton from '../action-button/ActionButton';
import { StyledRow } from './DealTeamMemberRow.styles';

export type Props = {
  member: DealTeamMember;
  departmentsOptions: Option[];
  index: number;
  onDelete: () => void;
  onConvert: () => void;
  isMyOrganisation: boolean;
};

const DealTeamMemberRow: React.FC<Props> = ({
  member,
  index,
  departmentsOptions,
  onDelete,
  onConvert,
  isMyOrganisation,
}) => {
  const isIdentityUser = member.type === DealTeamMemberType.Identity;
  const hasError = member.status === DealTeamMemberStatus.Invalid;

  return (
    <StyledRow key={index} hasError={hasError}>
      {isMyOrganisation && (
        <Table.Cell paddingSize="S" centered>
          {!hasError && isIdentityUser && <IdentityIcon size={20} />}
          {hasError && isIdentityUser && <ErrorIcon />}
        </Table.Cell>
      )}
      <Table.Cell paddingSize="S">
        <TextInputField
          name={`dealTeamMembers.${index}.name`}
          fullWidth
          disabled={isIdentityUser}
        />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField name={`dealTeamMembers.${index}.mobilePhone`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField name={`dealTeamMembers.${index}.officePhone`} fullWidth />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <TextInputField
          name={`dealTeamMembers.${index}.email`}
          fullWidth
          disabled={isIdentityUser}
        />
      </Table.Cell>
      <Table.Cell paddingSize="S">
        <SelectField
          placeholder="Unassigned"
          name={`dealTeamMembers.${index}.department`}
          fullWidth
          options={departmentsOptions}
        />
      </Table.Cell>
      <Table.Cell paddingSize="S" centered>
        {!hasError && !isIdentityUser && (
          <LinkButton
            testId={`${xcSelectors.removeDealTeamMember.testId}.${index}`}
            inline
            iconLeft={{ name: 'trash-alt', size: 'lg' }}
            onClick={onDelete}
          />
        )}
        {hasError && isIdentityUser && <ActionButton onDelete={onDelete} onConvert={onConvert} />}
      </Table.Cell>
    </StyledRow>
  );
};

export default DealTeamMemberRow;
