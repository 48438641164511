import { useCheckAccountType } from '@cmg/auth';
import { UUID } from '@cmg/common';

import { useCheckEntitlements_PublishedOfferingEntitlementsQuery } from './graphql';

/**
 * useGetPublishedOfferingEntitlementsQuery fetches a published offering entitlements
 *
 * The concept of entitlements isn't applicable for Buy Side Accounts
 * - skip the query for these to prevent 403 (Forbidden) errors
 */
export function useGetPublishedOfferingEntitlementsQuery({ offeringId }: { offeringId?: UUID }) {
  const isBuySideAccount = useCheckAccountType('BUY_SIDE');

  return useCheckEntitlements_PublishedOfferingEntitlementsQuery({
    variables: { offeringId: offeringId! },
    skip: isBuySideAccount || !offeringId,
  });
}
