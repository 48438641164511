import { DatePickerField, LinkButton, SelectField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import Table from '../../../../../../common/components/layout/table/Table';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { activityTypeOptions, createActivity, NOIFilingFormValues } from '../NOIFilingForm.model';

const ActivityForm: React.FC = () => {
  const { values, setFieldTouched } = useFormikContext<NOIFilingFormValues>();

  function addActivity(arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.push(createActivity());
  }

  function removeActivity(arrayHelpers: FieldArrayRenderProps, index: number) {
    arrayHelpers.remove(index);
  }

  return (
    <FieldArray name="activities">
      {arrayHelpers => (
        <Panel>
          <Panel.Header title="Activities">
            <LinkButton
              inline
              testId={xcSelectors.noiAddActivity.testId}
              onClick={() => addActivity(arrayHelpers)}
              iconLeft={{ name: 'plus' }}
            >
              Add Activity
            </LinkButton>
          </Panel.Header>
          {values.activities.length > 0 && (
            <Panel.Content>
              <Table>
                <thead>
                  <tr>
                    <th>
                      Activity Type <MandatoryAsterisk />
                    </th>
                    <th>
                      Activity Date <MandatoryAsterisk />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {values.activities.map((_, index) => (
                    <tr key={index}>
                      <td>
                        <SelectField
                          name={`activities.${index}.activityType`}
                          aria-label={`${index + 1}. Activity Type`}
                          options={activityTypeOptions}
                        />
                      </td>
                      <td>
                        <DatePickerField
                          flipDisabled
                          name={`activities.${index}.date`}
                          testId={`Regulatory Filings / Filings / NOI / ${
                            index + 1
                          }. Activity Date`}
                          onCalendarClose={() => {
                            setFieldTouched(`activities.${index}.date`);
                          }}
                        />
                      </td>
                      <td>
                        {values.activities.length > 1 && (
                          <LinkButton
                            testId={`${xcSelectors.noiRemoveActivity.testId}.${index}`}
                            onClick={() => removeActivity(arrayHelpers, index)}
                            iconLeft={{ name: 'trash-alt' }}
                          >
                            Delete
                          </LinkButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Panel.Content>
          )}
        </Panel>
      )}
    </FieldArray>
  );
};

export default ActivityForm;
