import {
  AdditionalPagination,
  AsyncPaginatedSelectField,
  Group,
  Option,
  ToastManager,
} from '@cmg/common';
import React from 'react';
import { LoadOptions } from 'react-select-async-paginate';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { mapOrganizationsToOptions } from '../CreateDealTeamForm.model';
import { useRoadshows_OrganizationSearchLazyQuery } from './graphql';

const OrganizationPanel: React.FC = () => {
  const [queryOrganizations] = useRoadshows_OrganizationSearchLazyQuery();

  const handleLoadOptions: LoadOptions<
    Option<string>,
    Group<string>,
    AdditionalPagination
  > = async (inputValue, loadedOptions, additionalArgs) => {
    const page = additionalArgs?.page ?? 1;
    if (!inputValue) {
      return { options: loadedOptions };
    }

    try {
      const { data } = await queryOrganizations({
        variables: {
          name: inputValue,
          pagination: { perPage: 25, page },
        },
      });

      return {
        options: mapOrganizationsToOptions(data?.underwriters.data),
        hasMore: !!data?.underwriters.pagination?.hasNext,
        additional: { page: page + 1 },
      };
    } catch {
      ToastManager.error('There was an error while fetching Organizations');
    }
    return { options: loadedOptions };
  };

  return (
    <Panel>
      <Panel.Header title="Organization" />
      <Panel.Content>
        <AsyncPaginatedSelectField
          label="Organization"
          name="organizationField"
          isClearable
          loadOptions={handleLoadOptions}
        />
      </Panel.Content>
    </Panel>
  );
};

export default React.memo(OrganizationPanel);
