import { Box, DotStatus } from '@cmg/design-system';
import React from 'react';

import { ProspectusDeliveryStatus } from '../../../../../../graphql';
import { ProspectusDeliveryStatusLabel } from '../../../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';
import { DemandGridCellRendererProps } from '../../types';
import { getProspectusStatusColor } from './Prospectus.model';

export type Props = DemandGridCellRendererProps<string>;

const ProspectusDeliveryStatusCellRenderer: React.FC<Props> = ({ data }) => {
  const {
    overallStatus = ProspectusDeliveryStatus.NotSent,
    overallStatusDisplayValue = ProspectusDeliveryStatusLabel[ProspectusDeliveryStatus.NotSent],
    sentEmailCount = 0,
  } = data?.prospectusDeliverySummary ?? {};

  const color = getProspectusStatusColor({ overallStatus, sentEmailCount });

  return (
    <Box display="flex" alignItems="center" height="100%">
      <DotStatus color={color} label={overallStatusDisplayValue} />
    </Box>
  );
};

export default ProspectusDeliveryStatusCellRenderer;
