import { ApolloProvider } from '@apollo/client';
import { AuthenticatedSignalRContextProvider, AuthProvider, AuthProviderConfig } from '@cmg/auth';
import { ErrorBoundary, GlobalStyle, ThemeProvider, ToastManager } from '@cmg/common';
import {
  AppLayout as DesignSystemAppLayout,
  DesignSystemProvider,
  DesignSystemProviderProps,
} from '@cmg/design-system';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components/macro';

import graphqlApiClient from '../../common/api/graphqlApiClient';
import { AppSettingsContext } from '../../common/config';
import { getAppSettings } from '../../common/config/appSettings';
import { ContactSupportModalContextProvider } from '../../common/context/ContactSupportModalContext';
import { XcMixpanelAppContextProvider } from '../../common/providers/XcMixpanelAppContextProvider';
import { PushNotificationProvider } from '../../common/push-notification';
import store from '../../common/redux/store';
import routeFactory from '../../common/util/routeFactory';
import { AppBar } from '../../design-system/layout/app-bar';
import { ReactRouterMUILink } from '../../design-system/react-router/ReactRouterMUILink';
import RootRouter from './RootRouter';

const appSettings = getAppSettings();
const NormalizedHeightRootCss = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

const designSystemProviderProps: DesignSystemProviderProps = {
  themeConfig: { LinkComponent: ReactRouterMUILink },
};

const authConfig: AuthProviderConfig = {
  env: {
    rootDomain: appSettings.env.rootDomain,
    secureCookies: appSettings.env.secureCookies,
  },
  client: {
    basename: appSettings.client.basename,
  },
  logging: {
    appName: 'Xecution',
    mixPanelToken: appSettings.logging.mixPanelToken,
  },
  auth: {
    clientId: appSettings.auth.clientId,
    oidcAuthorityBaseUrl: appSettings.auth.oidcAuthorityBaseUrl,
    tokenRenewalInterval: appSettings.auth.tokenRenewalIntervalInMinutes,
    scopes: Object.values(appSettings.auth.scopes),
  },
  actions: {
    onLogout: () => {
      window.location.href = routeFactory.loggedOut.getUrlPath();
    },
  },
};

const App: React.FC = () => {
  return (
    <AuthProvider authConfig={authConfig}>
      <AppSettingsContext.Provider value={appSettings}>
        <Provider store={store}>
          <ApolloProvider client={graphqlApiClient}>
            <XcMixpanelAppContextProvider>
              <AuthenticatedSignalRContextProvider>
                <GlobalStyle />
                <NormalizedHeightRootCss />
                <DesignSystemProvider {...designSystemProviderProps}>
                  <ThemeProvider>
                    <IntlProvider locale="en" defaultLocale="en">
                      <BrowserRouter basename={appSettings.client.basename}>
                        <ErrorBoundary>
                          <ToastManager />
                          <ContactSupportModalContextProvider>
                            <DesignSystemAppLayout
                              header={
                                <PushNotificationProvider>
                                  <AppBar />
                                </PushNotificationProvider>
                              }
                              page={<RootRouter />}
                            />
                          </ContactSupportModalContextProvider>
                        </ErrorBoundary>
                      </BrowserRouter>
                    </IntlProvider>
                  </ThemeProvider>
                </DesignSystemProvider>
              </AuthenticatedSignalRContextProvider>
            </XcMixpanelAppContextProvider>
          </ApolloProvider>
        </Provider>
      </AppSettingsContext.Provider>
    </AuthProvider>
  );
};

export default App;
