import {
  AddIcon,
  Button,
  Grid,
  LoadingButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@cmg/design-system';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import MandatoryAsterisk from '../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { CurrencyFormValues } from './CurrencyGrid.model';
import { CurrencyRowForm } from './CurrencyRowForm';

type FormType = {
  currencyCode: string;
  exchangeRate: number | null;
};

export type Props = {
  pricingCurrencyCode: string;
  currencies: readonly {
    currencyCode: string;
    exchangeRate: number;
    id: string;
  }[];
  onSave: (currencies: FormType[]) => void;
  loading: boolean;
};

export const validationSchema = yup.object().shape({
  currencies: yup.array().of(
    yup.object().shape({
      currencyCode: yup.string().required('Currency Code is required'),
      exchangeRate: yup
        .number()
        .required('Exchange Rate is required')
        .min(0, 'Exchange Rate must be greater than zero')
        .test('is-decimal', 'Exchange Rate can only have at most 3 decimal places', value =>
          /^\d+(\.\d{1,3})?$/.test(value.toString())
        ),
    })
  ),
});

export const createInitialValues = (currencies: Props['currencies']): CurrencyFormValues => {
  return {
    currencies: currencies.map(currency => ({
      currencyCode: currency.currencyCode,
      exchangeRate: currency.exchangeRate,
    })),
  };
};

export const CurrencyGrid: React.FC<Props> = ({
  pricingCurrencyCode,
  currencies,
  onSave,
  loading,
}) => {
  const formik = useFormik<CurrencyFormValues>({
    initialValues: createInitialValues(currencies),
    validationSchema,
    onSubmit: values => {
      onSave(values.currencies);
    },
  });

  const { values, errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldArray
          name="currencies"
          render={({ remove, push }) => (
            <div data-testid="demand-currency-grid">
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Stack gap={2} direction="row" alignItems="center">
                    <Typography variant="h3">Currencies</Typography>
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      disabled={false}
                      onClick={() => {
                        const newCurrency = { currencyCode: null, exchangeRate: 1 };
                        push(newCurrency);
                      }}
                    >
                      Add Demand Currency
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={loading}
                    disabled={!!errors.currencies}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>

              <TableContainer sx={{ marginTop: '8px', width: '600px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={150} align="left">
                        Currency Code <MandatoryAsterisk />
                      </TableCell>
                      <TableCell width={150} align="center">
                        Pricing Currency{' '}
                        <Tooltip
                          placement="top"
                          variant="info"
                          title="Edit Pricing Currency by selecting a different currency code in the Instruments section"
                        >
                          <span>ⓘ</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">Currency Exchange Rate</TableCell>
                      <TableCell width={20} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.currencies
                      .sort((a, b) => {
                        if (a.currencyCode === pricingCurrencyCode) {
                          return -1;
                        }
                        if (b.currencyCode === pricingCurrencyCode) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((currency, index) => (
                        <CurrencyRowForm
                          currencyCode={currency.currencyCode}
                          pricingCurrencyCode={pricingCurrencyCode}
                          exchangeRate={currency.exchangeRate}
                          index={index}
                          key={index}
                          onRemove={() => {
                            remove(index);
                          }}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        />
      </Form>
    </FormikProvider>
  );
};
