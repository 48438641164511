import { ButtonsContainer, DangerButton, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import { Roadshows_DealTeamPartsFragment } from '../../../common/graphql';
import DealTeamHeader from '../../common/deal-team-header/DealTeamHeader';

export type Props = {
  dealTeam: Roadshows_DealTeamPartsFragment;
  isLoading?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
};

const DealTeamDetailsHeader: React.FC<Props> = ({
  dealTeam,
  isLoading,
  onSave,
  onDelete,
  onCancel,
}) => {
  return (
    <DealTeamHeader dealTeam={dealTeam}>
      <ButtonsContainer>
        {onCancel && (
          <SecondaryButton
            onClick={onCancel}
            testId={xcSelectors.discardChangesDealTeamButton.testId}
            disabled={isLoading}
          >
            Discard Changes
          </SecondaryButton>
        )}
        {onSave && (
          <PrimaryButton
            onClick={onSave}
            testId={xcSelectors.saveChangesDealTeamButton.testId}
            loading={isLoading}
          >
            Save Changes
          </PrimaryButton>
        )}
        {onDelete && (
          <DangerButton onClick={onDelete} testId={xcSelectors.deleteDealTeamButton.testId}>
            Delete Deal Team
          </DangerButton>
        )}
      </ButtonsContainer>
    </DealTeamHeader>
  );
};

export default DealTeamDetailsHeader;
