import { DangerButton, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { useFormikContext } from 'formik';
import React from 'react';

import EventHeaderInfo from '../../../common/event-header-info/EventHeaderInfo';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import {
  SFormTitle,
  SMeetingHeader,
} from '../../../panel-meetings-event/common/meeting-button-group/MeetingButtonGroup.styles';

export type Props = {
  isNewLogistics: boolean;
  isSaving: boolean;
  logisticsHeaderInfo: EventInfo | null;
  onDelete?: () => void;
  isDeleting?: boolean;
};

const LogisticsButtonGroup: React.FC<Props> = ({
  isNewLogistics,
  isSaving,
  logisticsHeaderInfo,
  onDelete,
  isDeleting,
}) => {
  const { dirty, isValid, submitForm, resetForm } = useFormikContext();

  const canCreate = dirty && isNewLogistics;
  const canUpdate = dirty && !isNewLogistics;
  const canDelete = !dirty && !isNewLogistics;

  return (
    <SMeetingHeader>
      {isNewLogistics && <SFormTitle>Create New Logistics</SFormTitle>}
      {!!logisticsHeaderInfo && <EventHeaderInfo eventInfo={logisticsHeaderInfo} />}

      {canCreate && (
        <PrimaryButton
          testId="save a new logistics event button"
          onClick={submitForm}
          loading={isSaving}
          loadingLabel="Saving"
          disabled={isSaving || !isValid}
        >
          Create Logistics
        </PrimaryButton>
      )}
      {canUpdate && (
        <SecondaryButton
          testId={xcSelectors.roadshowLogisticsDetailsFormDiscardButton.testId}
          onClick={() => resetForm()}
          disabled={isSaving}
        >
          Discard Changes
        </SecondaryButton>
      )}
      {canUpdate && (
        <PrimaryButton
          testId={xcSelectors.roadshowLogisticsDetailsFormUpdateButton.testId}
          onClick={submitForm}
          loading={isSaving}
          loadingLabel="Saving"
          disabled={isSaving || !isValid}
        >
          Save Changes
        </PrimaryButton>
      )}
      {canDelete && (
        <DangerButton
          testId={xcSelectors.roadshowLogisticsDetailsFormDeleteButton.testId}
          onClick={onDelete}
          loading={isDeleting}
          loadingLabel="Deleting"
          disabled={isDeleting}
        >
          Delete Logistics
        </DangerButton>
      )}
    </SMeetingHeader>
  );
};

export default LogisticsButtonGroup;
