import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';
import React from 'react';

import { useCalendarAccess } from '../../hooks/useCalendarAccess';
import { Calendar_PricedCalendarOfferingsListQuery } from '../graphql';
import { SContainer } from './BuySideAllocationCurrencyCell.styles';

type Props = {
  mdlAllocation:
    | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]['mdlAllocation']
    | null;
  finalAllocation:
    | Calendar_PricedCalendarOfferingsListQuery['pricedPublishedOfferings']['data'][number]['finalAllocation']
    | null;
  finalFilingOfferPrice: number | null;
  pricingCurrencyCode?: string;
};

const BuySideAllocationCurrencyCell: React.FC<Props> = ({
  mdlAllocation,
  finalAllocation,
  finalFilingOfferPrice,
  pricingCurrencyCode,
}) => {
  const { allowMDLAllocation, allowFinalAllocation } = useCalendarAccess();

  if (allowMDLAllocation) {
    const value = !isNil(mdlAllocation?.allocation?.investment)
      ? numericUtil.formatCurrency(mdlAllocation?.allocation?.investment)
      : '-';
    return <SContainer>{value}</SContainer>;
  }
  if (allowFinalAllocation) {
    if (isNil(finalAllocation?.shareQuantity) || isNil(finalFilingOfferPrice)) {
      return <div>-</div>;
    }
    return (
      <SContainer>
        {numericUtil.formatCurrency(
          (finalAllocation?.shareQuantity || 0) * finalFilingOfferPrice,
          2,
          pricingCurrencyCode
        )}
      </SContainer>
    );
  }
  return <div>-</div>;
};

export default BuySideAllocationCurrencyCell;
