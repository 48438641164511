import { getOperationName } from '@apollo/client/utilities';
import { ToastManager, UUID } from '@cmg/common';
import React from 'react';

import { OrderBookState } from '../../../graphql';
import { OfferingNavbar_OrderBookSubNavItem_OrderBookDocument } from '../../offering/components/navbar/graphql';
import {
  useOrderBook_OfferingAndOrderBookStatusQuery,
  useUpdateOrderBookStateMutation,
} from '../graphql';
import { InstitutionalDemandGrid_SummaryDocument } from '../institutional-demand/components/institutional-demand-grid/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../variables';

export type Props = Readonly<{
  offeringId: UUID;
}>;

export const refetchQueries = () => {
  // After the Order Book is closed, we need to refetch the Institutional Demand query
  // to re-fetch final order book data and re-render the grid.
  const refetchInstitutionalDemandSummaryGridQuery = getOperationName(
    InstitutionalDemandGrid_SummaryDocument
  );
  // After closing order book, we want to refetch the Order book sub nav
  const refetchOrderSubNav = getOperationName(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument);

  return [refetchInstitutionalDemandSummaryGridQuery!, refetchOrderSubNav!];
};

export const useUpdateOrderBookStatus = ({ offeringId }: Props) => {
  const [isUpdateOrderBookStatusModalOpen, setIsUpdateOrderBookStatusModalOpen] =
    React.useState(false);

  const {
    data: offeringOrderBookData,
    loading,
    refetch,
  } = useOrderBook_OfferingAndOrderBookStatusQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const offeringStatus = offeringOrderBookData?.publishedOffering?.status;
  const orderBookState = offeringOrderBookData?.publishedOffering?.orderBook?.state;

  const [updateOrderBookState] = useUpdateOrderBookStateMutation();

  const handleOnUpdateOrderBookStatus = () => {
    updateOrderBookState({
      variables: {
        offeringId,
        state:
          orderBookState === OrderBookState.Closed ? OrderBookState.Open : OrderBookState.Closed,
      },
      awaitRefetchQueries: true,
      onCompleted: data => {
        if (data.updateOrderBookState?.state === OrderBookState.Closed) {
          ToastManager.success('Successfully closed order book');
        } else if (data.updateOrderBookState?.state === OrderBookState.Open) {
          ToastManager.success('Successfully reopened order book');
        }
        pendingOrderBookGridForcedRefreshVar(true);
      },
      onError: () => {
        ToastManager.error(
          `Failed to ${orderBookState === OrderBookState.Closed ? 'reopen' : 'close'} order book`
        );
      },
      refetchQueries,
    });
  };

  const handleOpenUpdateOrderBookStatusModal = async () => {
    await refetch();
    setIsUpdateOrderBookStatusModalOpen(true);
  };

  const handleCloseUpdateOrderBookStatusModal = () => {
    setIsUpdateOrderBookStatusModalOpen(false);
  };

  return {
    handleOnUpdateOrderBookStatus,
    handleOpenUpdateOrderBookStatusModal,
    handleCloseUpdateOrderBookStatusModal,
    loading,
    orderBookState,
    offeringStatus,
    isUpdateOrderBookStatusModalOpen,
  };
};
