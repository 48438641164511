import { Typography } from '@cmg/design-system';
import React from 'react';

import type { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const AllocationSetSharedChangeContent: React.FC<Props> = () => {
  return (
    <Typography aria-label="Recent update - Allocation set shared" component="span">
      <Typography component="span" variant="highlight1">
        New Allocation Set
      </Typography>{' '}
      shared.
    </Typography>
  );
};
