import { getOperationName } from '@apollo/client/utilities';

import { OfferingSetup_OfferingEntitlementsDocument } from '../../entitlements/graphql';
import { useOfferingSetup_UpdateManagerMutation } from '../graphql';

export const refetchQueries = () => {
  // Refetch offering entitlements on manager update to update the entitlement grid
  const getOfferingEntitlements = getOperationName(OfferingSetup_OfferingEntitlementsDocument)!;
  return [getOfferingEntitlements];
};

export const useUpdateManagerMutation = (
  options: Parameters<typeof useOfferingSetup_UpdateManagerMutation>[0]
) =>
  useOfferingSetup_UpdateManagerMutation({
    ...options,
    refetchQueries,
  });
