import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import ManagerEconomics from '../../common/components/manager-economics/ManagerEconomics';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';

export const PricingTermsRetentionWireForm: React.FC<CreateOrUpdateFormProps> = ({
  offeringId,
  onFormDataLoaded,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <ManagerEconomics offeringId={offeringId} onDataLoaded={onFormDataLoaded} />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
