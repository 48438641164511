import { ODSTable as Table } from '@cmg/common';
import React from 'react';

import { StabilizationTrade } from '../../../StabilizationForm.model';
import OverallotmentRefreshTableHeader from '../common/overallotment-refresh-table-header/OverallotmentRefreshTableHeader';
import ReadOnlyOverallotmentRefreshRow from './read-only-overallotment-refresh-row/ReadOnlyOverallotmentRefreshRow';

export type Props = { offeringPrice: number; trades: StabilizationTrade[] };

const ReadOnlyOverallotmentRefresh: React.FC<Props> = ({ offeringPrice, trades }) => {
  return (
    <Table gridTemplateColumns="1fr 1fr 1fr 1fr">
      <OverallotmentRefreshTableHeader
        isEditable={false}
        trades={trades}
        offeringPrice={offeringPrice}
      />

      {trades.map((trade, index) => (
        <ReadOnlyOverallotmentRefreshRow key={index} trade={trade} offeringPrice={offeringPrice} />
      ))}
    </Table>
  );
};

export default ReadOnlyOverallotmentRefresh;
