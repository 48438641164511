import React from 'react';

import StatusComponent from '../../../../common/components/indicators/status/Status';
import { ProspectusDeliveryStatus } from '../../../../graphql';
import { ProspectusDeliveryStatusLabel } from '../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';

export type Props = {
  overallDeliveryStatus: ProspectusDeliveryStatus;
  sentEmailCount: number;
  totalEmailCount: number;
};

export const getProspectusDeliveryLabel = ({
  sentEmailCount,
  totalEmailCount,
}: {
  sentEmailCount: number;
  totalEmailCount: number;
}) => {
  return `Sent ${sentEmailCount}/${totalEmailCount}`;
};

const ProspectusDeliveryStatusComponent: React.FC<Props> = ({
  overallDeliveryStatus,
  sentEmailCount,
  totalEmailCount,
}) => {
  const label = getProspectusDeliveryLabel({ sentEmailCount, totalEmailCount });

  return (
    <StatusComponent
      size="xs"
      variant="DARK"
      status={overallDeliveryStatus}
      options={theme => ({
        [ProspectusDeliveryStatus.InProgress]: {
          label,
          color:
            sentEmailCount > 0
              ? theme.designSystem.colors.green['300']
              : theme.designSystem.colors.gray['300'],
        },
        [ProspectusDeliveryStatus.Sent]: {
          label,
          color: theme.designSystem.colors.green['300'],
        },
        [ProspectusDeliveryStatus.Failed]: {
          label,
          color: theme.designSystem.colors.red['300'],
        },
        [ProspectusDeliveryStatus.NotSent]: {
          label: ProspectusDeliveryStatusLabel[ProspectusDeliveryStatus.NotSent],
          color: theme.designSystem.colors.gray['300'],
        },
      })}
    />
  );
};

export default ProspectusDeliveryStatusComponent;
