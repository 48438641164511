import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@cmg/design-system';
import { FormikSelectField } from '@cmg/design-system-formik';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';

import { InvalidFormFieldsAlert } from '../../../../../common/components/alerts/invalid-form-fields-alert/InvalidFormFieldsAlert';
import { UNASSIGN_BND_AGENT_VALUE } from '../../demand-grid/DemandGrid.model';
import type { OrderBook_InstitutionalDemand_ManagerProfilePartsFragment } from '../../graphql/__generated__';
import type { BulkAssignBndAgentValues } from './BulkAssignBndAgentDialog.model';
import { getOptions, validationSchema } from './BulkAssignBndAgentDialog.model';

export type Props = Readonly<{
  isOpen: boolean;
  managers: readonly OrderBook_InstitutionalDemand_ManagerProfilePartsFragment[];
  onClose: () => void;
  onSave: (managerKey: string | null) => void;
}>;

export const BulkAssignBndAgentDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  managers,
}) => {
  const formik = useFormik<BulkAssignBndAgentValues>({
    initialValues: { bndAgentKey: null },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const bndAgentKey =
        values.bndAgentKey === UNASSIGN_BND_AGENT_VALUE ? null : values.bndAgentKey;
      onSave(bndAgentKey);
      onClose();
    },
  });

  const { resetForm, dirty } = formik;

  useEffect(() => {
    if (!isOpen && dirty) {
      resetForm();
    }
  }, [resetForm, dirty, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Assign Billing & Delivery Agent</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <InvalidFormFieldsAlert
            errors={formik.errors}
            isSubmitting={formik.isSubmitting}
            touched={formik.touched}
            validationSchema={validationSchema}
          />
          <Typography>Please select the manager to designate as the B&D Agent.</Typography>
          <FormikProvider value={formik}>
            <FormikSelectField
              name="bndAgentKey"
              label="B&D Agent"
              placeholder="Select a manager"
              options={getOptions(managers)}
            />
          </FormikProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
