import { useAuth } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Alert, AlertTitle, Button, LoadingButton, Tooltip } from '@cmg/design-system';
import React from 'react';

import { OfferingType } from '../../../../../../../../graphql';
import { IndicationForm_IndicationAcknowledgementsQuery } from '../../../indication-form/components/indication-details/components/acknowledgement/graphql/__generated__';
import { useIndicationAcknowledgementBanner } from './IndicationAcknowledgementBanner.model';

export type Props = Readonly<{
  acknowledgeButtonDisabledNoContacts?: boolean;
  indicationModifiedByFirmKey: string | null | undefined;
  indicationVersion: UUID;
  acknowledgements: IndicationForm_IndicationAcknowledgementsQuery['institutionalIndicationAcknowledgements'];
  offeringType: OfferingType;
  onAcknowledge: () => Promise<void>;
  isAcknowledging: boolean;
  onUnacknowledge: () => Promise<void>;
  isUnacknowledging: boolean;
}>;

const IndicationAcknowledgementBanner: React.FC<Props> = ({
  acknowledgeButtonDisabledNoContacts,
  indicationVersion,
  indicationModifiedByFirmKey,
  acknowledgements,
  offeringType,
  isAcknowledging,
  isUnacknowledging,
  onAcknowledge,
  onUnacknowledge,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();

  const { bannerTitle, bannerBody, isUpToDate, alertSeverity } = useIndicationAcknowledgementBanner(
    {
      acknowledgements,
      indicationVersion,
      offeringType,
      oidcUserCmgEntityKey,
    }
  );

  const isAcknowledgeButtonVisible = !isUpToDate;
  const isUnacknowlegeButtonVisible =
    isUpToDate && oidcUserCmgEntityKey !== indicationModifiedByFirmKey;

  return (
    <Alert
      title="Acknowledge indication"
      severity={alertSeverity}
      action={[
        ...(isAcknowledgeButtonVisible && acknowledgeButtonDisabledNoContacts
          ? [
              <Tooltip
                key="acknowledge-disabled"
                title="You must add at least one investor contact before acknowledging this indication"
              >
                {/* tooltip can't have disabled child */}
                <span>
                  <Button variant="text" disabled>
                    Acknowledge
                  </Button>
                </span>
              </Tooltip>,
            ]
          : []),
        ...(isAcknowledgeButtonVisible && !acknowledgeButtonDisabledNoContacts
          ? [
              <LoadingButton
                key="acknowledge"
                variant="text"
                onClick={onAcknowledge}
                loading={isAcknowledging}
              >
                Acknowledge
              </LoadingButton>,
            ]
          : []),
        ...(isUnacknowlegeButtonVisible
          ? [
              <LoadingButton
                key="unacknowledge"
                variant="text"
                onClick={onUnacknowledge}
                loading={isUnacknowledging}
              >
                Undo
              </LoadingButton>,
            ]
          : []),
      ]}
    >
      <AlertTitle>{bannerTitle}</AlertTitle>
      {bannerBody}
    </Alert>
  );
};

export default IndicationAcknowledgementBanner;
