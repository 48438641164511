import {
  FlexContainer,
  ODSTable as Table,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import {
  getInitialValues,
  getManagerData,
  getPayload,
  validationSchema,
  Values,
} from './AccountingManualAdjustmentModal.model';
import { SDescription } from './AccountingManualAdjustmentModal.styles';
import { useUpdateAccountingManualAdjustmentMutation } from './hooks/useUpdateAccountingManualAdjustmentMutation';
import ManualAdjustmentRow from './manual-adjustment-row/ManualAdjustmentRow';

export type Props = {
  show: boolean;
  onHide: () => void;
  offeringId: UUID;
};

const AccountingManualAdjustmentModal: React.FC<Props> = ({ show, onHide, offeringId }) => {
  const { managers } = useAccountingContext();
  const [updateAdjustments, { loading }] = useUpdateAccountingManualAdjustmentMutation();

  const handleSubmitForm = async values => {
    try {
      await updateAdjustments({ variables: { offeringId, payload: getPayload(values) } });

      ToastManager.success('Successfully updated manual adjustments');
      onHide();
    } catch {
      ToastManager.error('Failed to update manual adjustments');
    }
  };

  const formik = useFormik<Values>({
    validationSchema,
    initialValues: getInitialValues(managers),
    onSubmit: handleSubmitForm,
  });

  const { values, resetForm, submitForm, isValid, dirty } = formik;

  const handleCloseModal = () => {
    resetForm();
    onHide();
  };

  return (
    <Modal show={show} title="Final Settlement Manual Adjustment" size="large">
      <Modal.Content>
        <SDescription>
          Enter a manual adjustment to apply to each underwriter individually
        </SDescription>

        <FormikProvider value={formik}>
          <Form>
            <Table gridTemplateColumns="1.7fr 1.3fr 1.8fr 1.3fr">
              <Table.TableHeaderCell>Underwriter</Table.TableHeaderCell>
              <Table.TableHeaderCell>Manual Adjustment</Table.TableHeaderCell>
              <Table.TableHeaderCell>Description</Table.TableHeaderCell>
              <Table.TableHeaderCell align="right">Final Settlement</Table.TableHeaderCell>

              {values.managers.map((managerValues, index) => {
                const { managerFirmName, netDealFees, retailRetentionFees } = getManagerData(
                  managers,
                  managerValues.managerCmgEntityKey
                );

                return (
                  <ManualAdjustmentRow
                    key={index}
                    row={managerValues}
                    firmName={managerFirmName}
                    netDealFees={netDealFees}
                    retailRetentionFees={retailRetentionFees}
                    index={index}
                  />
                );
              })}
            </Table>
          </Form>
        </FormikProvider>
      </Modal.Content>

      <Modal.Footer>
        <FlexContainer direction="row-reverse" gap={16}>
          <PrimaryButton
            testId="save-button"
            disabled={loading || !isValid || !dirty}
            loading={loading}
            onClick={submitForm}
          >
            Save
          </PrimaryButton>
          <SecondaryButton testId="cancel-button" disabled={loading} onClick={handleCloseModal}>
            Cancel
          </SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountingManualAdjustmentModal;
