import { sortCaseInsensitive } from '../../../../../../../common/util/sortCaseInsensitive';
import { ProspectusInvestorErrorType } from '../../../../../../../graphql';
import { ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment } from '../../../../../../offering-side-panel/order-book/compliance/prospectus/graphql';

const NUM_OF_INVESTOR_NAMES_TO_SHOW = 10;

export type ProspectusOnSendValidationAlertInvestor = Readonly<{
  bankInvestorName?: string | null;
  cmgEntityName?: string | null;
  displayName?: string | null;
  cmgEntityKey?: string | null;
}>;

export type ProspectusOnSendValidationAlertModel = Readonly<{
  readonly investorsWithError: ProspectusOnSendValidationAlertInvestor[];
  readonly investorsWithoutContactInfo: ProspectusOnSendValidationAlertInvestor[];
  readonly investorsWithoutAcknowledgedIndication: ProspectusOnSendValidationAlertInvestor[];
}>;

export function getAlertTitle(
  investorsWithError: ProspectusOnSendValidationAlertInvestor[]
): string {
  return `Prospectus not sent for ${investorsWithError.length} ${
    investorsWithError.length === 1 ? 'Investor' : 'Investors'
  }`;
}

export function getAlertBody(
  investorsWithError: ProspectusOnSendValidationAlertInvestor[]
): string {
  const investorsToShowInAlert = investorsWithError.slice(0, NUM_OF_INVESTOR_NAMES_TO_SHOW);
  const investorNames = investorsToShowInAlert.map(x => getInvestorDisplayName(x)).join(', ');

  const numOfHiddenInvestorNames = investorsWithError.length - investorsToShowInAlert.length;
  const hiddenInvestorsSuffix = numOfHiddenInvestorNames > 0 ? ` +${numOfHiddenInvestorNames}` : '';

  return `An indication must have contact information present and be acknowledged in order to send Prospectus. Missing details for: ${investorNames}${hiddenInvestorsSuffix}`;
}

export function getInvestorDisplayName(investor: ProspectusOnSendValidationAlertInvestor): string {
  return investor.displayName ?? investor.bankInvestorName ?? investor.cmgEntityName ?? '';
}

export function getProspectusOnSendValidationAlertModel(
  investors: ProspectusOnSendValidationAlertInvestor[],
  prospectusOnSendValidation?: ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment
): ProspectusOnSendValidationAlertModel | null {
  if (!prospectusOnSendValidation) {
    return null;
  }

  const sortedInvestors = [...investors].sort((a, b) =>
    sortCaseInsensitive(getInvestorDisplayName(a), getInvestorDisplayName(b))
  );

  const investorsWithError = getInvestorsWithError(sortedInvestors, prospectusOnSendValidation);

  const investorsWithoutContactInfo = getInvestorsWithError(
    sortedInvestors,
    prospectusOnSendValidation,
    ProspectusInvestorErrorType.InvestorContactMissing
  );

  const investorsWithoutAcknowledgedIndication = getInvestorsWithError(
    sortedInvestors,
    prospectusOnSendValidation,
    ProspectusInvestorErrorType.IndicationNotAcknowledged
  );

  return {
    investorsWithError,
    investorsWithoutAcknowledgedIndication,
    investorsWithoutContactInfo,
  };
}

function getInvestorsWithError(
  investors: ProspectusOnSendValidationAlertInvestor[],
  prospectusOnSendValidation: ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment,
  specificError?: ProspectusInvestorErrorType
) {
  return investors.filter(investor =>
    prospectusOnSendValidation.investorErrors.some(
      error =>
        error.investorKey === investor.cmgEntityKey &&
        (!specificError || error.errorType === specificError)
    )
  );
}
