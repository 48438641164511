/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CreateCompanyMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;

export type Roadshows_CreateCompanyMutation = {
  readonly __typename?: 'Mutation';
  readonly createCompany: {
    readonly __typename?: 'Company';
    readonly id: string;
    readonly name: string;
    readonly website?: string | null;
    readonly industryTypes: ReadonlyArray<Types.FirmIndustryType>;
    readonly address?: {
      readonly __typename?: 'CompanyAddress';
      readonly streetLine1?: string | null;
      readonly streetLine2?: string | null;
      readonly streetLine3?: string | null;
      readonly city?: string | null;
      readonly state?: string | null;
      readonly country?: string | null;
    } | null;
  };
};

export const Roadshows_CreateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_CreateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Roadshows_CompanyParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_CompanyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetLine3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryTypes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_CreateCompanyMutationFn = Apollo.MutationFunction<
  Roadshows_CreateCompanyMutation,
  Roadshows_CreateCompanyMutationVariables
>;

/**
 * __useRoadshows_CreateCompanyMutation__
 *
 * To run a mutation, you first call `useRoadshows_CreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsCreateCompanyMutation, { data, loading, error }] = useRoadshows_CreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRoadshows_CreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_CreateCompanyMutation,
    Roadshows_CreateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_CreateCompanyMutation,
    Roadshows_CreateCompanyMutationVariables
  >(Roadshows_CreateCompanyDocument, options);
}
export type Roadshows_CreateCompanyMutationHookResult = ReturnType<
  typeof useRoadshows_CreateCompanyMutation
>;
export type Roadshows_CreateCompanyMutationResult =
  Apollo.MutationResult<Roadshows_CreateCompanyMutation>;
export type Roadshows_CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_CreateCompanyMutation,
  Roadshows_CreateCompanyMutationVariables
>;
