import { FlexContainer } from '@cmg/common';
import React from 'react';

import Banner from '../../../../../../../../../common/components/indicators/banner/Banner';
import { SBannerTitle } from './SalesCreditsInvestorKeyInfoBanner.styles';

const SalesCreditsInvestorKeyInfoBanner: React.FC = () => {
  return (
    <Banner variant="information" withoutMargin>
      <FlexContainer direction="row">
        <SBannerTitle>
          Bank Investor Key selection will only be updated for Sales Credits
        </SBannerTitle>
      </FlexContainer>
      <div>If global changes need to be made, make the selection directly from the indication</div>
    </Banner>
  );
};

export default SalesCreditsInvestorKeyInfoBanner;
