import {
  ActionPanelSection,
  Alert,
  AlertListContent,
  AlertTitle,
  Breadcrumbs,
  Button,
  Link,
  Skeleton,
  Stack,
  SxProps,
  TableSkeleton,
  Theme,
  Typography,
  useTheme,
} from '@cmg/design-system';
import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useDocumentTitle } from '../../../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../../../common/util/routeFactory';
import { CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment } from '../../../../graphql/__generated__';
import { useCertificateQueryParams } from '../../../../hooks/useCertificateQueryParams';
import useRouteWithSearchParams, {
  CertificateRouteName,
} from '../../../../hooks/useRouteWithSearchParams';
import CurrentCertificatesTable from './components/current-certificates-table/CurrentCertificatesTable';
import PastCertificateTable from './components/past-certificates-table/PastCertificatesTable';
import useGetCertificateData from './hooks/useGetCertificateData';

const skeletonSx: SxProps<Theme> = {
  borderRadius: theme => theme.spacing(0.5),
};

const useCertificateSectionRouteMatch = () => {
  let routeName: CertificateRouteName = CertificateRouteName.None;

  const currentCertificatesMatch = useRouteMatch<{ cmgEntityKey: string }>(
    routeFactory.certificateLibraryFirmCurrentCertificates.routePath
  );

  const pastCertificatesMatch = useRouteMatch<{ cmgEntityKey: string }>(
    routeFactory.certificateLibraryFirmPastCertificates.routePath
  );

  if (currentCertificatesMatch) {
    routeName = CertificateRouteName.CurrentCertificates;
  }

  if (pastCertificatesMatch) {
    routeName = CertificateRouteName.PastCertificates;
  }

  return {
    match: currentCertificatesMatch ?? pastCertificatesMatch,
    routeName,
  };
};

const LoadingCertificateSection = () => {
  const { spacing } = useTheme();

  return (
    <Stack gap={1.75}>
      <Stack direction="column" gap={4} paddingRight={theme => theme.spacing(2)}>
        <Skeleton sx={skeletonSx} height={spacing(4.75)} variant="rectangular" width="100%" />
        <Skeleton sx={skeletonSx} height={spacing(4.75)} variant="rectangular" width="50%" />
      </Stack>
      <Stack>
        <TableSkeleton numOfHeaderRows={0} numOfRows={2} numOfColumns={6} />
      </Stack>
    </Stack>
  );
};

const FirmCrmIdAndCmgEntityKeyMissingAlert = ({ onClose = () => {} }) => (
  <Alert severity="error" onClose={onClose} sx={{ marginRight: theme => theme.spacing(2) }}>
    <AlertTitle>There was an error loading data</AlertTitle>
    <Typography variant="body2">
      Please close the slide out action panel and select a firm name to view Certificate
      information.
    </Typography>
    <Typography variant="body2">Contact support if you continue to have issues.</Typography>
    <Stack marginTop={theme => theme.spacing(0.5)}>
      <AlertListContent messages={['• There is no Investor ID or CMG Entity Key provided']} />
    </Stack>
  </Alert>
);

const CurrentCertificatesSection: React.FC<{
  certificates: readonly NonNullable<CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment>[];
}> = ({ certificates }) => {
  const { cmgEntityKey, crmInvestorId } = useCertificateQueryParams();

  const pastCertificatesRoute = useRouteWithSearchParams(CertificateRouteName.PastCertificates, {
    cmgEntityKey,
    crmInvestorId,
  });

  const certificateLibraryRoute = useRouteWithSearchParams(CertificateRouteName.Library) ?? '';

  const history = useHistory();

  const onClose = useCallback(() => {
    history.push(certificateLibraryRoute);
  }, [history, certificateLibraryRoute]);

  if (!cmgEntityKey && !crmInvestorId) {
    return <FirmCrmIdAndCmgEntityKeyMissingAlert onClose={onClose} />;
  }

  return (
    <ActionPanelSection
      title="Current Certificates"
      actions={[
        <Button variant="outlined" href={pastCertificatesRoute} key="past-certificates">
          Past Certificates
        </Button>,
      ]}
    >
      <CurrentCertificatesTable certificates={certificates} />
    </ActionPanelSection>
  );
};

const PastCertificatesSection: React.FC<{
  certificates: readonly CertificateLibrary_FetchCertificateByCmgEntityKey_CertificateFragment[];
}> = ({ certificates }) => {
  const { cmgEntityKey, crmInvestorId } = useCertificateQueryParams();
  const currentCertificatesRoute = useRouteWithSearchParams(
    CertificateRouteName.CurrentCertificates,
    { cmgEntityKey, crmInvestorId }
  );

  return (
    <React.Fragment>
      <Breadcrumbs sx={{ marginBottom: theme => theme.spacing(1) }}>
        <Link color="inherit" href={currentCertificatesRoute}>
          Current Certificates
        </Link>
        <Typography color={theme => theme.palette.text.primary}>Past Certificates</Typography>
      </Breadcrumbs>
      <ActionPanelSection title="Past Certificates">
        {certificates.length > 0 ? (
          <PastCertificateTable certificates={certificates} />
        ) : (
          <Stack gap={0.5} marginTop={theme => theme.spacing(2)} alignItems="center">
            <Typography variant="h3">No past certificates</Typography>
            <Typography variant="body1" color={theme => theme.palette.text.secondary}>
              There are currently no past certificates for this firm
            </Typography>
          </Stack>
        )}
      </ActionPanelSection>
    </React.Fragment>
  );
};

const CertificatesSection: React.FC = () => {
  let documentTitle = '';
  const { routeName } = useCertificateSectionRouteMatch();

  const certificateData = useGetCertificateData();

  if (routeName === CertificateRouteName.CurrentCertificates) {
    documentTitle = routeFactory.certificateLibraryFirmCurrentCertificates.getDocumentTitle({
      firmName: certificateData?.firmCertificate?.firmName,
    });
  }

  if (routeName === CertificateRouteName.PastCertificates) {
    documentTitle = routeFactory.certificateLibraryFirmPastCertificates.getDocumentTitle({
      firmName: certificateData?.firmCertificate?.firmName,
    });
  }

  useDocumentTitle(documentTitle);

  if (routeName === CertificateRouteName.None) return null;

  if (certificateData.loading) {
    return <LoadingCertificateSection />;
  }

  const {
    firmName,
    bankInvestorKey,
    certificates: currentCertificates,
  } = certificateData.firmCertificate ?? {};

  const pastCertificates = certificateData.pastCertificates ?? [];

  return (
    <Stack>
      <Stack
        paddingY={theme => theme.spacing(1.5)}
        gap={0.5}
        borderTop={theme => `solid ${theme.spacing(0.125)} ${theme.palette.divider}`}
      >
        <Typography variant="h2">{firmName ?? '-'}</Typography>
        <Typography variant="body2" color={theme => theme.palette.text.secondary}>
          Bank Investor Key: {bankInvestorKey ?? '-'}
        </Typography>
      </Stack>
      {routeName === CertificateRouteName.CurrentCertificates && (
        <CurrentCertificatesSection certificates={currentCertificates ?? []} />
      )}
      {routeName === CertificateRouteName.PastCertificates && (
        <PastCertificatesSection certificates={pastCertificates} />
      )}
    </Stack>
  );
};

export default CertificatesSection;
