import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CloseIcon,
  IconButton,
  Typography,
} from '@cmg/design-system';
import { Fragment, useEffect, useMemo, useState, VFC } from 'react';

import { ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment } from '../../../../../../offering-side-panel/order-book/compliance/prospectus/graphql';
import {
  getAlertBody,
  getAlertTitle,
  getProspectusOnSendValidationAlertModel,
  ProspectusOnSendValidationAlertInvestor,
} from './ProspectusOnSendValidationAlert.model';
import { ProspectusOnSendValidationAlertDialog } from './ProspectusOnSendValidationAlertDialog';

export type ProspectusOnSendValidationAlertProps = Readonly<{
  readonly prospectusOnSendValidation?: ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment;
  readonly investors: ProspectusOnSendValidationAlertInvestor[];
}>;

export const ProspectusOnSendValidationAlert: VFC<ProspectusOnSendValidationAlertProps> = ({
  prospectusOnSendValidation,
  investors,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const shouldShowAlert =
      !!prospectusOnSendValidation && prospectusOnSendValidation.investorErrors.length > 0;

    setIsShown(shouldShowAlert);
  }, [prospectusOnSendValidation]);

  const alertModel = useMemo(
    () => getProspectusOnSendValidationAlertModel(investors, prospectusOnSendValidation),
    [investors, prospectusOnSendValidation]
  );

  if (!isShown || !alertModel) {
    return null;
  }

  return (
    <Box py={2}>
      <Alert
        severity="error"
        aria-label={getAlertTitle(alertModel.investorsWithError)}
        onClose={() => setIsShown(false)}
        action={
          <Fragment>
            <Button color="primary" size="small" onClick={() => setIsDialogOpen(true)}>
              More Details
            </Button>
            <IconButton
              aria-label="Close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsShown(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Fragment>
        }
      >
        <AlertTitle>{getAlertTitle(alertModel.investorsWithError)}</AlertTitle>
        <Typography variant="body2">{getAlertBody(alertModel.investorsWithError)}</Typography>
      </Alert>
      <ProspectusOnSendValidationAlertDialog
        alertModel={alertModel}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </Box>
  );
};
