import { numericUtil, UUID } from '@cmg/common';
import { useMemo } from 'react';

import {
  OrderBook_GetOfferingFilingsSharesQuery,
  useOrderBook_GetOfferingFilingsSharesQuery,
} from '../graphql';

export type Props = Readonly<{ offeringId: UUID }>;

const getOfferingShares = (data: OrderBook_GetOfferingFilingsSharesQuery | undefined) => {
  const filings = data?.publishedOffering.terms.filings ?? [];

  if (filings?.length === 0) {
    return;
  }

  const {
    primarySharesBaseOffering,
    secondarySharesBaseOffering,
    primarySharesOverAllotmentAuthorized,
    secondarySharesOverAllotmentAuthorized,
  } = filings[filings.length - 1];

  const baseDeal = numericUtil.sum(primarySharesBaseOffering, secondarySharesBaseOffering);
  const overallotmentAuthorized = numericUtil.sum(
    primarySharesOverAllotmentAuthorized,
    secondarySharesOverAllotmentAuthorized
  );

  return {
    baseDeal,
    overallotmentAuthorized,
    totalOffering: numericUtil.sum(baseDeal, overallotmentAuthorized),
  };
};

const useGetOfferingShares = ({ offeringId }: Props) => {
  const { data, loading, error } = useOrderBook_GetOfferingFilingsSharesQuery({
    variables: { offeringId },
  });

  return useMemo(() => {
    return {
      shares: getOfferingShares(data),
      loading,
      error,
    };
  }, [data, loading, error]);
};

export default useGetOfferingShares;
