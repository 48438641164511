import { DialogContentText } from '@cmg/design-system';
import capitalize from 'lodash/capitalize';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';
import { ConfirmationDialog } from '../ConfirmationDialog';

export type Props = Readonly<{
  readonly onClose: () => void;
  readonly onDelete: () => Promise<void>;
}>;

export const DeleteWireDialog: FC<Props> = ({ onClose, onDelete }) => {
  const isMounted = useRef(false);
  const { wireTypeName, wireTypeCategory } = useWireTypeConfigContext();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      await onDelete();
    } finally {
      if (isMounted.current) {
        setIsDeleting(false);
      }
    }
  }, [onDelete]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <ConfirmationDialog
      maxWidth="xs"
      isOpen={true}
      isSubmitting={isDeleting}
      title={`Delete ${wireTypeName} ${capitalize(wireTypeCategory)}`}
      cancelButtonLabel="Cancel"
      submitButtonLabel="Continue"
      onCancel={onClose}
      onSubmit={handleDelete}
      content={
        <DialogContentText>
          Are you sure you want to delete the {wireTypeName} {wireTypeCategory} draft? This action
          cannot be reverted.
        </DialogContentText>
      }
    />
  );
};
