import { FormikErrors } from 'formik';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import * as yup from 'yup';

function getFieldRef(schema: yup.AnyObjectSchema, field: string): yup.AnyObjectSchema {
  const fieldRef = yup.reach(schema, field) as yup.AnyObjectSchema;
  const isNumericFieldName = /^\d+$/.test(field);

  /**
   * If field name is number yup treats it as index and will return a parent schema instead.
   * To address this edge case we try to reach the field manually.
   */
  if (fieldRef === schema && isNumericFieldName && fieldRef.fields?.[field]) {
    return fieldRef.fields[field] as yup.AnyObjectSchema;
  }

  return fieldRef;
}

/**
 * Returns a formatted list of unique field labels.
 */
export function getFormikErrorFieldLabels<TValues extends yup.Maybe<yup.AnyObject>>(
  errorObject: FormikErrors<TValues>,
  validationSchema: yup.AnyObjectSchema
): string[] {
  const errorFieldLabels = Object.entries(errorObject).reduce<string[]>((acc, [field, error]) => {
    if (!error) {
      return acc;
    }
    const fieldRef = getFieldRef(validationSchema, field);

    if (typeof error === 'string') {
      return [...acc, `${fieldRef?.spec.label ?? startCase(field)}`];
    }

    if (Array.isArray(error)) {
      return [...acc, ...getFormikErrorFieldLabels(error as FormikErrors<TValues>, fieldRef)];
    }

    return [...acc, ...getFormikErrorFieldLabels(error, fieldRef)];
  }, []);

  return uniq<string>(errorFieldLabels);
}
