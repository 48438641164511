import { UUID } from '@cmg/common';
import {
  Alert,
  Button,
  Divider,
  List,
  ListSubheader,
  Stack,
  TableSkeleton,
} from '@cmg/design-system';
import React from 'react';

import { InstitutionalDraftSet } from '../../../../types';
import DraftAllocationListItem from './draft-allocation-list-item/DraftAllocationListItem';
import { useSeparateDraftSets } from './hooks/useSeparateDraftSets';

export type Props = Readonly<{
  offeringId: UUID;
  draftAllocationSets: readonly InstitutionalDraftSet[];
  onCreateDraftAllocationSet: () => void;
  onEditDraftAllocationSet: (draftSet: InstitutionalDraftSet) => void;
  onDeleteDraftAllocationSet: (draftSet: InstitutionalDraftSet) => void;
  onShareDraftAllocationSet: (draftSet: InstitutionalDraftSet) => void;
  onPromoteDraftAllocationSetToFinal: (draftSet: InstitutionalDraftSet) => void;
  onCopyDraftAllocationSetToDefault: (draftSet: InstitutionalDraftSet) => void;
  onCloseAllocationsDropdown: () => void;
}>;

const InstitutionalDemandMenuDrafts: React.FC<Props> = ({
  offeringId,
  draftAllocationSets,
  onCreateDraftAllocationSet,
  onEditDraftAllocationSet,
  onDeleteDraftAllocationSet,
  onShareDraftAllocationSet,
  onPromoteDraftAllocationSetToFinal,
  onCopyDraftAllocationSetToDefault,
  onCloseAllocationsDropdown,
}) => {
  const { draftSets, isLoadingSettlementAgent, error } = useSeparateDraftSets(
    draftAllocationSets,
    offeringId
  );

  if (error) {
    return <Alert severity="error">Failed to fetch Settlement Agent information</Alert>;
  }

  if (isLoadingSettlementAgent) {
    return <TableSkeleton numOfRows={3} />;
  }

  return (
    <div>
      {draftSets.defaultSection.length > 0 && (
        <List subheader={<ListSubheader>Default Allocation</ListSubheader>}>
          {draftSets.defaultSection.map(set => (
            <DraftAllocationListItem
              key={set.id!}
              offeringId={offeringId}
              draftAllocationSet={set}
              onEditDraftAllocationSet={() => onEditDraftAllocationSet(set)}
              onDeleteDraftAllocationSet={() => onDeleteDraftAllocationSet(set)}
              onShareDraftAllocationSet={() => onShareDraftAllocationSet(set)}
              onPromoteDraftAllocationSetToFinal={() => onPromoteDraftAllocationSetToFinal(set)}
              onCopyDraftAllocationSetToDefault={() => onCopyDraftAllocationSetToDefault(set)}
              onCloseAllocationsDropdown={onCloseAllocationsDropdown}
            />
          ))}
        </List>
      )}

      {draftSets.draftSection.length > 0 && (
        <List subheader={<ListSubheader>Draft Allocations</ListSubheader>}>
          {draftSets.draftSection.map(set => (
            <DraftAllocationListItem
              key={set.id!}
              offeringId={offeringId}
              draftAllocationSet={set}
              onEditDraftAllocationSet={() => onEditDraftAllocationSet(set)}
              onDeleteDraftAllocationSet={() => onDeleteDraftAllocationSet(set)}
              onShareDraftAllocationSet={() => onShareDraftAllocationSet(set)}
              onPromoteDraftAllocationSetToFinal={() => onPromoteDraftAllocationSetToFinal(set)}
              onCopyDraftAllocationSetToDefault={() => onCopyDraftAllocationSetToDefault(set)}
              onCloseAllocationsDropdown={onCloseAllocationsDropdown}
            />
          ))}
        </List>
      )}
      <Divider />

      <Stack alignItems="center" pt={1}>
        <Button onClick={onCreateDraftAllocationSet}>Add New Draft</Button>
      </Stack>
    </div>
  );
};

export default InstitutionalDemandMenuDrafts;
