import { ProspectusErrorType } from '../../../../../../graphql';
import {
  ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
  ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment,
} from '../graphql';

export type ResendProspectusResult = Readonly<{
  readonly validationError: string | null;
  readonly numOfInvestorsRecievingProspectus: number;
} | null>;

export function getValidInvestorKeys(
  isSyndicatePersona: boolean,
  allInvestorKeys: string[],
  onSendValidationQuery: ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery | undefined
): string[] {
  const onSendValidation = getOnSendValidation(isSyndicatePersona, onSendValidationQuery);

  if (!onSendValidation) {
    return allInvestorKeys;
  }

  return allInvestorKeys.filter(investorKey =>
    onSendValidation.investorErrors.every(err => err.investorKey !== investorKey)
  );
}

export function getOnSendValidation(
  isSyndicatePersona: boolean,
  onSendValidationQuery: ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery | undefined
): ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment | undefined {
  if (!onSendValidationQuery) {
    return undefined;
  }

  return isSyndicatePersona
    ? onSendValidationQuery.prospectusDelivery.onSendValidationAsSyndicate
    : onSendValidationQuery.prospectusDelivery.onSendValidationAsSalesAndTrading;
}

export function isProspectusUploaded(
  isSyndicatePersona: boolean,
  onSendValidationQuery?: ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery | undefined
): boolean {
  const onSendValidation = getOnSendValidation(isSyndicatePersona, onSendValidationQuery);

  if (!onSendValidation) {
    return true;
  }

  return onSendValidation.prospectusErrors.every(
    err => err.errorType !== ProspectusErrorType.ProspectusDocumentsMissing
  );
}
