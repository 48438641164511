import { Panel } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
`;

export const SFormContent = styled.div`
  padding: 10px;
`;

export const SFormHeader = styled.div<{ justifyContent?: string }>`
  background-color: ${({ theme }) => theme.border.color.light};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent = 'inherit' }) => justifyContent};
  padding: 10px 15px;
`;

export const SFormHeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const STable = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SColumn = styled.div<{ size?: number }>`
  flex-basis: 20%;
  align-self: baseline;
  ${({ size }) =>
    size &&
    css`
      flex: ${size};
    `}
`;

export const SField = styled.div`
  padding: 0 13px;
  flex: 1;
`;

export const SActions = styled.div<{ active?: boolean }>`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  display: flex;
  flex: 1 0 180px;
  justify-content: flex-end;
`;

export const SRow = styled.div<{ active?: boolean; withMargin?: boolean; dim?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;

  ${({ active, theme }) =>
    active &&
    css`
      padding-left: 10px;
      padding-right: 10px;
      margin-left: -10px;
      margin-right: -10px;
      background: ${({ theme }) => theme.background.color.highlighted};

      ${SColumn} {
        padding-right: 12px;
      }

      ${SActions} {
        visibility: visible;
      }
    `}

  ${({ withMargin }) =>
    withMargin &&
    css`
      padding-top: 10px;
      padding-bottom: 10px;
    `}


  &:hover {
    ${SActions} {
      visibility: visible;
    }
  }

  opacity: ${({ dim }) => (dim ? '0.1' : '1')};
`;

export const STableHeader = styled(SRow)`
  min-height: auto;
  ${SColumn} {
    padding: 5px;
    color: ${({ theme }) => theme.text.color.light};
    font-size: ${({ theme }) => theme.text.size.mediumSmall};
    vertical-align: bottom;
    border-bottom: ${({ theme }) => theme.border.width.small} solid
      ${({ theme }) => theme.border.color.light};
  }
`;

export const SCard = styled.div<{ active?: boolean; withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  margin-bottom: 10px;
  padding: 10px;

  ${({ theme, withBorder }) =>
    withBorder &&
    css`
      border: ${theme.border.smallSolidDark};
      border-radius: ${theme.border.radius.small};
    `}

  ${({ active, theme }) =>
    active &&
    css`
      margin-left: -10px;
      margin-right: -10px;
      background: ${theme.background.color.highlighted};
    `}

  ${SActions} {
    align-self: flex-end;
    visibility: visible;
  }

  &:hover {
    ${SActions} {
      visibility: visible;
    }
  }
`;

export const SCardHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const StyledPanel = styled(Panel)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
