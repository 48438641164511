import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateTerminationWireMutation,
  SyndicateWires_TerminationWiresDocument,
  useSyndicateWires_CreateTerminationWireMutation,
} from '../graphql';

export type CreateTerminationWireMutation = SyndicateWires_CreateTerminationWireMutation;

export const useCreateTerminationWireMutation = () => {
  return useSyndicateWires_CreateTerminationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [SyndicateWires_WiresDocument, SyndicateWires_TerminationWiresDocument];
  return getManyOperationNames(documentNodes);
}
