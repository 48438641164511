import isNil from 'lodash/isNil';

import CurrencyFormatter from '../../../../../../common/components/format/currency-format/CurrencyFormatter';
import { CalendarGridRendererProps } from '../../../../calendar.model';

export const CurrencyRenderer: React.FC<CalendarGridRendererProps> = ({ data, value }) => {
  return isNil(value) ? (
    <span>-</span>
  ) : (
    <CurrencyFormatter value={Number(value)} currency={data.pricingCurrencyCode} />
  );
};
