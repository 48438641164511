import styled from 'styled-components/macro';

export const SToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SToolbarSection = styled.div<{ align?: 'left' | 'right' }>`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin: 20px 0 15px;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SButtonWrapper = styled.div`
  margin: 0 10px 10px 0;
  display: flex;

  &:last-child {
    margin-right: 0;
  }
`;
