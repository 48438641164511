import React from 'react';

import { StyledLoader, StyledSpinnerIcon } from './Spinner.styles';

export type Props = {
  /**
   * Set true to show loader
   */
  show: boolean;
  /**
   * Color variant of spinner, LIGHT for dark background, DARK for light background
   */
  variant?: 'LIGHT' | 'DARK';
  /**
   * Occupy full width of parent element
   */
  fullHeight?: boolean;
  /**
   * CSS class, mostly to make wrapping by styled-component possible
   */
  className?: string;
};

/**
 * Render loading spinner when performing async operations
 */
const Spinner: React.FC<Props> = ({
  show,
  variant = 'DARK',
  fullHeight = false,
  children,
  className,
}) => {
  return (
    <StyledLoader
      fullHeight={fullHeight}
      show={show}
      backgroundStyle={{ background: 'none' }}
      message={<StyledSpinnerIcon variant={variant} />}
      className={className}
    >
      {children}
    </StyledLoader>
  );
};

export default Spinner;
