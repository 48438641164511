import { ColDef } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

export enum MenuType {
  SELECTABLE = 'selectable',
  READ_ONLY = 'readOnly',
  PIN_ONLY = 'pinOnly',
  HIDE = 'hide',
}

export const defaultColumnCellStyle = {
  lineHeight: '32px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  ...defaultColumnCellStyle,
};

export const numericCellStyle = {
  ...cellStyle,
  justifyContent: 'end',
};

export const defaultColumnProps: ColDef = {
  cellStyle: () => cellStyle,
  equals: () => false,
  refData: {
    menuDisplayType: MenuType.SELECTABLE,
  },
  comparator: (valA, valB) => {
    const valueA = isFunction(valA?.toLocaleLowerCase) ? valA.toLocaleLowerCase() : valA;
    const valueB = isFunction(valB?.toLocaleLowerCase) ? valB.toLocaleLowerCase() : valB;

    const a = valueA ? valueA : '';
    const b = valueB ? valueB : '';

    if (a === b) {
      return 0;
    } else {
      return a > b ? 1 : -1;
    }
  },
  pinnedRowCellRendererFramework: () => {
    return null;
  },
  valueFormatter: ({ value }) => value ?? '',
};

export const defaultColumnWidth = {
  minWidth: 80,
  maxWidth: 400,
};
