import { MutationOptions, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import {
  OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument,
  OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsDocument,
  OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
  OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables,
} from '../../graphql';

export const refetchQueries = () => {
  const getUnderwritingFeesDiscountsQuery = getOperationName(
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument
  )!;
  return [getUnderwritingFeesDiscountsQuery];
};

export const useUpdateUnderwritingFeesAndDiscounts = (
  options?: MutationOptions<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >
) =>
  useMutation<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >(OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsDocument, {
    ...options,
    refetchQueries,
  });
