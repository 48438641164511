import { Option } from '@cmg/common';

import { Roadshows_DealTeamNameAndDepartmentsPartsFragment } from '../../../../../common/graphql';

export const getOrganizationOptions = (
  organizationList: readonly Roadshows_DealTeamNameAndDepartmentsPartsFragment[] = []
): Option[] => {
  return organizationList.map(organization => ({
    label: organization.firmName,
    value: organization.firmCmgEntityKey,
  }));
};

export const getDepartmentOptions = (
  organizationCmgEntityKey: string | null,
  organizationList: readonly Roadshows_DealTeamNameAndDepartmentsPartsFragment[] = []
): Option[] => {
  const selectedOrganization = organizationList.find(
    item => item.firmCmgEntityKey === organizationCmgEntityKey
  );

  return (
    selectedOrganization?.departments.map(department => ({
      label: department,
      value: department,
    })) ?? []
  );
};
