type FilingName = {
  full: string;
  abbr: string;
};

export const filingNames: { [key: string]: FilingName } = {
  DS: { full: 'Deal Status', abbr: 'DS' },
  NOI: { full: 'Notice of Intent', abbr: 'NOI' },
  RPN: { full: 'Restricted Period Notification', abbr: 'RPN' },
  TN: { full: 'Trading Notification', abbr: 'TN' },
  USDTN: { full: 'Unregistered Secondary Distribution Trading Notification', abbr: 'USD-TN' },
};

export const getFilingFormLabel = (filingType: keyof typeof filingNames) => {
  const { full, abbr } = filingNames[filingType];

  return `${full} Form (${abbr})`;
};

export const getLoadFilingsErrorMessage = (filingType: keyof typeof filingNames) => {
  const { full: filingName } = filingNames[filingType];
  return `Unable to load existing ${filingName} filings`;
};
