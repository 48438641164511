import { Typography } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../graphql';
import type { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const IndicationDuplicateStatusChangeContent: React.FC<Props> = ({ update }) => {
  const { investorFirm, newIndication } = update;
  const isDuplicate = newIndication?.status === IndicationStatus.Duplicate;

  return (
    <Typography aria-label="Recent update - Duplicate status changed" component="span">
      <Typography component="span" variant="highlight1">
        {investorFirm}
      </Typography>
      {isDuplicate ? ' marked as ' : ' is no longer marked as '}
      <Typography component="span" variant="highlight1">
        duplicate
      </Typography>
      .
    </Typography>
  );
};
