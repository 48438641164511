import type { FirmInfoPartsFragment } from '../../../../common/graphql/fragments';
import type { OrderBook_InstitutionalDemand_ManagerProfilePartsFragment } from '../graphql/__generated__';

/**
 * Convert manager profile to firm info
 */
export function managerProfileToFirmInfo(
  manager: OrderBook_InstitutionalDemand_ManagerProfilePartsFragment
): FirmInfoPartsFragment {
  return {
    firmKey: manager.cmgEntityKey,
    firmName: manager.firmName,
    firmNameAbbreviation: manager.firmNameAbbreviation,
    firmDisplayName: manager.firmNameAbbreviation ?? manager.firmName,
  };
}

export function getFirmDisplayName(
  manager: OrderBook_InstitutionalDemand_ManagerProfilePartsFragment
) {
  return manager.firmNameAbbreviation ? manager.firmNameAbbreviation : manager.firmName;
}
