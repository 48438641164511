import isNil from 'lodash/isNil';
import * as yup from 'yup';

import { BrokerValues } from '../InternalRetailDemandTableRow.model';

export type CreateBrokerDealerValue = {
  brokerName: string;
};

export const getValidationSchema = (allBrokers: BrokerValues['broker'][]) =>
  yup.object({
    brokerName: yup
      .string()
      .label('Broker Dealer Name')
      .nullable()
      .required()
      .test('broker-name-unique', 'Broker Dealer has already been added', value => {
        return !allBrokers.some(
          broker => broker && isNil(broker.firmCmgEntityKey) && broker.firmName === value
        );
      }),
  });

export const initialValues: CreateBrokerDealerValue = {
  brokerName: '',
};
