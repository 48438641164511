import { useReactiveVar } from '@apollo/client';
import type { UUID } from '@cmg/common';

import { useCheckFinalInstitutionalAllocationAccess } from '../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { OfferingStatus, OrderBookState } from '../../../../graphql';
import { useHandleAssignFinalAllocation } from '../../hooks/useHandleAssignFinalAllocation';
import { useUpdateOrderBookStatusForSales } from '../../hooks/useUpdateOrderBookStatusForSales';
import { showOrderBookGridToolBarOptions } from '../../variables';
import { SubNavActionButtons } from '../components/sub-nav-action-buttons/SubNavActionButtons';
import { SubNavActionMenu } from '../components/sub-nav-action-menu/SubNavActionMenu';

export type Props = {
  offeringId: UUID;
  offeringStatus?: OfferingStatus;
  orderBookState?: OrderBookState;
  isFinalAllocationSetReleased: boolean;
  offeringName: string | undefined;
  isUpdateOrderBookStatusLoading: boolean;
  onUpdateOrderBookStatus: () => Promise<void>;
  isVisibleToNonSyndicate: boolean;
};

export const useSubNavActions = ({
  offeringId,
  offeringName,
  offeringStatus,
  orderBookState,
  isFinalAllocationSetReleased,
  isUpdateOrderBookStatusLoading,
  onUpdateOrderBookStatus,
  isVisibleToNonSyndicate,
}: Props) => {
  const displayOrderBookGridToolBarOptions = useReactiveVar(showOrderBookGridToolBarOptions);

  const { canManage: canManageAllocations } = useCheckFinalInstitutionalAllocationAccess({
    offeringId,
  });

  const { canUpdate: canUpdateIndications } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });

  const { isReleaseFinalAllocationSetLoading, handleReleaseFinalAllocationsSubmit } =
    useHandleAssignFinalAllocation({
      offeringId,
      offeringName,
      isFinalAllocationSetReleased,
      isVisibleToNonSyndicate,
    });

  const {
    loading: isUpdatingOrderBookSalesStateLoading,
    orderBookSalesState,
    handleOnUpdateOrderBookSalesState,
  } = useUpdateOrderBookStatusForSales({ offeringId });

  if (!displayOrderBookGridToolBarOptions) {
    return undefined;
  }

  const actionProps = {
    offeringId,
    isFinalAllocationSetReleased,
    isUpdateOrderBookStatusLoading,
    isReleaseFinalAllocationSetLoading,
    canManageAllocations,
    canUpdateIndications,
    orderBookState,
    offeringStatus,
    onReleaseFinalAllocations: handleReleaseFinalAllocationsSubmit,
    onUpdateOrderBookStatus,
    isVisibleToNonSyndicate,
    orderBookSalesState,
    isUpdatingOrderBookSalesStateLoading,
    onUpdateOrderBookSalesState: handleOnUpdateOrderBookSalesState,
  };

  return {
    md: <SubNavActionButtons {...actionProps} />,
    xs: <SubNavActionMenu {...actionProps} />,
  };
};
