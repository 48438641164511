import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import * as yup from 'yup';

import { CreateLogisticsInput, UpdateLogisticsInput } from '../../../../../../../graphql';
import {
  Roadshows_LogisticsPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';

export type LogisticsValues = {
  startDate: string;
  startTime: string[];
  endDate: string;
  endTime: string[];
  title: string;
  notes: string | null;
  location: string;
};

const validateTimePickerValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value
): boolean => {
  const [time, period] = value || [];
  return !!(time && period && ['AM', 'PM'].includes(period));
};

const validateStartTimeValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value,
  context
) => {
  const endDate = parse(
    context.parent.endTime.join(' '),
    'hh:mm a',
    new Date(context.parent.endDate)
  );
  const startDate = parse(value!.join(' '), 'hh:mm a', new Date(context.parent.startDate));
  return isBefore(startDate, endDate);
};

const validateEndTimeValue: yup.TestFunction<(string | undefined)[] | undefined> = (
  value,
  context
) => {
  const startDate = parse(
    context.parent.startTime.join(' '),
    'hh:mm a',
    new Date(context.parent.startDate)
  );
  const endDate = parse(value!.join(' '), 'hh:mm a', new Date(context.parent.endDate));
  return isAfter(endDate, startDate);
};

const validateStartDateLocation: (
  roadshowDays?: Roadshows_RoadshowPartsFragment['roadshowDays']
) => yup.TestFunction<Date | undefined> = roadshowDays => value => {
  if (!value || !roadshowDays) {
    return true;
  }
  const selectedDay = roadshowDays.find(day => day.date === format(value, 'yyyy-MM-dd'));

  return !!(selectedDay && selectedDay.cities.length > 0);
};

export const getValidationSchema = (
  roadshowDays?: Roadshows_RoadshowPartsFragment['roadshowDays']
) =>
  yup.object().shape({
    title: yup.string().label('Logistics Title').nullable().required(),
    startDate: yup
      .date()
      .label('Start Date')
      .typeError('Invalid date format')
      .test(
        'has-location',
        'Logistics cannot be created on virtual day',
        validateStartDateLocation(roadshowDays)
      )
      .nullable()
      .required(),
    startTime: yup
      .array()
      .test('has-time', 'Start Time is required', validateTimePickerValue)
      .test('is-before-end-time', 'Start time must be before end time', validateStartTimeValue),
    endDate: yup.date().label('End Date').typeError('Invalid date format').nullable().required(),
    endTime: yup
      .array()
      .test('has-time', 'End Time is required', validateTimePickerValue)
      .test('is-after-start-time', 'End time must be after start time', validateEndTimeValue),
    location: yup.string().label('Location').nullable().required(),
    notes: yup.string().label('Notes').nullable(),
  });

const getISOTime = (timeValue: string[]) => {
  const date = parse(timeValue.join(' '), 'hh:mm a', new Date());

  return isValid(date) ? format(date, 'HH:mm:ss') : '';
};

export const getLogisticsPayload = (
  values: LogisticsValues
): CreateLogisticsInput | UpdateLogisticsInput => ({
  title: values.title,
  endDate: values.endDate,
  startDate: values.startDate,
  startTime: getISOTime(values.startTime),
  endTime: getISOTime(values.endTime),
  notes: values.notes || null,
  location: values.location,
});

const parseISOTime = (timeString?: string) => {
  if (!timeString) {
    return;
  }
  const date = parse(timeString, 'HH:mm:ss', new Date());

  return format(date, 'hh:mm a').split(' ');
};

export const getLogisticsInitialValues = ({
  logistics,
  selectedDate,
  roadshowDays,
}: {
  logistics?: Roadshows_LogisticsPartsFragment;
  selectedDate?: string;
  roadshowDays: Roadshows_RoadshowPartsFragment['roadshowDays'];
}): LogisticsValues => {
  const values = {
    title: logistics?.title ?? '',
    notes: logistics?.notes ?? null,
    location: logistics?.location ?? '',
    endTime: parseISOTime(logistics?.endTime) ?? ['', ''],
    endDate: logistics?.endDate ?? selectedDate ?? '',
    startDate: logistics?.startDate ?? selectedDate ?? '',
    startTime: parseISOTime(logistics?.startTime) ?? ['', ''],
  };

  if (!logistics && selectedDate) {
    const roadshowDay = roadshowDays.find(day => day.date === selectedDate);

    if (roadshowDay && roadshowDay.cities.length > 0) {
      const [firstLocation] = roadshowDay.cities;

      return { ...values, location: firstLocation.name };
    }
  }

  return values;
};
