import React from 'react';

import emptyWire from '../../../../../../../common/assets/empty-wire.svg';
import { HeroGraphics } from '../../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import { useWireTypeConfigContext } from '../../../context/WireTypeConfigContext';

type Props = {
  isSendDisabled: boolean;
};

export const SelectWireManager: React.FC<Props> = ({ isSendDisabled }) => {
  const { wireTypeCategory } = useWireTypeConfigContext();

  return (
    <HeroGraphics
      image={emptyWire}
      imageAlt="Select a recipient"
      title="Select a recipient"
      subtitle={
        `Select a recipient to preview their unique ${wireTypeCategory}` +
        (isSendDisabled ? '.' : ` or click Ready to Send to send the ${wireTypeCategory}.`)
      }
    />
  );
};
