import { ActionPanelSection, Button, ModeEditOutlineIcon } from '@cmg/design-system';
import { useFormikContext } from 'formik';
import { PropsWithChildren } from 'react';

import { useFormIsEditing } from '../hooks/useFormIsEditing';
import { UnderwritingTermsDiscountsAndFeesFormValues } from '../UnderwritingTermsDiscountsAndFeesSection';
import { InputValuesSwitch } from './InputValuesSwitch';

type DiscountsAndFeesActionPanelSectionProps = PropsWithChildren<{
  currencyCode: string;
  submitClicksRef: React.MutableRefObject<number>;
  handleSubmit: () => void;
  handleEdit: () => void;
}>;

export function DiscountsAndFeesActionPanelSection({
  currencyCode,
  handleSubmit,
  handleEdit,
  children,
}: DiscountsAndFeesActionPanelSectionProps) {
  const { isEditing } = useFormIsEditing();
  const formik = useFormikContext<UnderwritingTermsDiscountsAndFeesFormValues>();

  return (
    <ActionPanelSection
      data-testid="discounts-and-fees-action-panel-section"
      title="Discounts & Fees"
      titleItems={isEditing && <InputValuesSwitch key="value-switch" currencyCode={currencyCode} />}
      actions={[
        isEditing ? (
          <Button
            key="save-button"
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>
        ) : (
          <Button key="edit-button" onClick={handleEdit} endIcon={<ModeEditOutlineIcon />}>
            Edit
          </Button>
        ),
      ]}
    >
      {children}
    </ActionPanelSection>
  );
}
