import { ISODate, timeUtil } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import ListItemSecondaryAction from '../../../../../../../common/components/lists/list/ListItemSecondaryAction';
import ListItemText from '../../../../../../../common/components/lists/list/ListItemText';
import SubNavItem from '../../../../../../../common/components/navigation/side-navigation/SubNavItem';
import { SSingleWireNavigationItemWrapper } from './SingleWireNavigationItem.styles';

export type Props = {
  createdAt: ISODate;
  name: string;
  url: string;
  isActive: boolean;
  testId?: string;
};

export const SCreatedAt = styled.span`
  color: ${({ theme }) => theme.designSystem.colors.gray['500']};
  font-size: ${({ theme }) => theme.text.size.tiny};
`;

export const SingleWireNavigationItem: React.FC<Props> = ({
  createdAt,
  name,
  url,
  isActive,
  testId,
}) => {
  return (
    <SSingleWireNavigationItemWrapper data-test-id={testId}>
      <SubNavItem url={url} isActive={isActive}>
        <ListItemText>{name}</ListItemText>
        <ListItemSecondaryAction>
          <SCreatedAt>{timeUtil.formatAsDisplayDate(createdAt)}</SCreatedAt>
        </ListItemSecondaryAction>
      </SubNavItem>
    </SSingleWireNavigationItemWrapper>
  );
};
