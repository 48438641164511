import { CustomCellRendererProps, timeUtil } from '@cmg/common';
import { DotStatus, Stack } from '@cmg/design-system';

import { AttestationFormStatus } from '../../../../../graphql/__generated__';
import { STATUS_COLORS, STATUS_LABELS } from '../../../certificate-library.model';
import {
  CertificateLibrary_FetchCertificates_CertificateFragment,
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment,
} from '../../../graphql/__generated__';

type Props = CustomCellRendererProps<
  CertificateLibrary_FetchCertificates_FirmCertificatesFragment,
  CertificateLibrary_FetchCertificates_CertificateFragment
>;

const CertificateRenderer = ({ value, data }: Props) => {
  let secondaryLabel: string | undefined;

  if (!data) {
    return <span>-</span>;
  }

  if (!value) {
    return (
      <Stack direction="column" width="100%" boxSizing="content-box">
        <DotStatus
          color={STATUS_COLORS[AttestationFormStatus.NotOnFile]}
          label={STATUS_LABELS[AttestationFormStatus.NotOnFile]}
          titleAccess={STATUS_COLORS[AttestationFormStatus.NotOnFile]}
        />
      </Stack>
    );
  }

  if (value?.expirationDate) {
    secondaryLabel = `${
      value?.certificateStatus === AttestationFormStatus.Expired ? 'Expired' : 'Expires'
    }: ${timeUtil.formatAsDisplayDate(value?.expirationDate)}`;
  }

  return (
    <Stack direction="column" width="100%" boxSizing="content-box">
      <DotStatus
        color={STATUS_COLORS[value?.certificateStatus ?? AttestationFormStatus.NotOnFile]}
        label={STATUS_LABELS[value?.certificateStatus ?? AttestationFormStatus.NotOnFile]}
        titleAccess={STATUS_COLORS[value?.certificateStatus ?? AttestationFormStatus.NotOnFile]}
        secondaryLabel={secondaryLabel}
      />
    </Stack>
  );
};

export default CertificateRenderer;
