export const isValidCmgEntityKey = (value: string | undefined) => {
  const keyFixedLength = 9;
  const minValue = 1;
  const maxValue = Math.pow(10, keyFixedLength) - 1;
  const keyPattern = new RegExp('^[0-9]{9}');

  if (value == null) {
    return false;
  }

  const valueString = value.toString();

  if (!keyPattern.test(valueString)) {
    return false;
  }

  const intValue = parseInt(valueString);
  if (intValue < minValue) {
    return false;
  }

  return intValue <= maxValue;
};
