import { DesignSystemTooltipProps } from '@cmg/data-grid';

import {
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  OrderBook_InstitutionalDemand_RowAlertPartsFragment,
} from '../../../graphql/__generated__';
import {
  AcknowledgementsAlertCodes,
  AllocationAlertCodes,
  ComplianceAlertCodes,
  DeliveryAlertCodes,
} from '../columns.model';

export const getAlertGroups = (alertCodes: readonly string[]) => {
  return alertCodes.reduce<[string[], string[], string[], string[]]>(
    ([compliance, delivery, acknowledgements, allocations], code) => {
      if (code in ComplianceAlertCodes) {
        return [
          [...compliance, ComplianceAlertCodes[code].label],
          delivery,
          acknowledgements,
          allocations,
        ];
      }

      if (code in DeliveryAlertCodes) {
        return [
          compliance,
          [...delivery, DeliveryAlertCodes[code].label],
          acknowledgements,
          allocations,
        ];
      }

      if (code in AcknowledgementsAlertCodes) {
        return [
          compliance,
          delivery,
          [...acknowledgements, AcknowledgementsAlertCodes[code].label],
          allocations,
        ];
      }

      if (code in AllocationAlertCodes) {
        return [
          compliance,
          delivery,
          acknowledgements,
          [...allocations, AllocationAlertCodes[code].label],
        ];
      }

      return [compliance, delivery, acknowledgements, allocations];
    },
    [[], [], [], []]
  );
};

export const getVariant: NonNullable<
  DesignSystemTooltipProps<
    OrderBook_InstitutionalDemand_GridRowPartsFragment,
    OrderBook_InstitutionalDemand_RowAlertPartsFragment
  >['getVariant']
> = ({ value }) => {
  return value?.severity === 'ERROR' ? 'error' : 'warning';
};
