import { UUID } from '@cmg/common';

import routeFactory from '../../../../../common/util/routeFactory';
import { ReactRouterDomainNavTabProps } from '../../../../../design-system/react-router/ReactRouterDomainNavTab';

export type Props = Readonly<{ offeringId: UUID }>;

export const useInstitutionalDemandRoute = ({
  offeringId,
}: Props): ReactRouterDomainNavTabProps => {
  const href = routeFactory.orderBookInstitutionalDemand.getUrlPath({ offeringId });

  return {
    href,
    label: 'Institutional Demand',
  };
};
