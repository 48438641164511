import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import { useGroupAffiliates } from '../../../hooks/useGroupAffiliates';
import { WithFirmOrOwnerCmgEntityKey } from '../filing-common-fields-form/FilingCommonFieldsForm.model';

export type Props = {
  nonMemberFirms: readonly ({
    firmName: string;
  } & WithFirmOrOwnerCmgEntityKey)[];
  isDetailView?: boolean;
};

const title = 'Non-Member Firms';

export const NonMemberFirmsDetail: React.FC<Props> = ({ nonMemberFirms, isDetailView }) => {
  const groupedNonMemberFirmsDetailData = useGroupAffiliates(nonMemberFirms);

  if (!nonMemberFirms.length || !groupedNonMemberFirmsDetailData) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Content>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Firm Name</TableCell>
              </TableRow>
            </TableHead>
            {groupedNonMemberFirmsDetailData.map(([manager, nonMemberFirms]) => (
              <TableBody key={manager} aria-label={manager}>
                <TableGroupHeader>
                  <TableGroupHeaderCell colSpan={4}>{manager}</TableGroupHeaderCell>
                </TableGroupHeader>
                {nonMemberFirms.map(nonMemberFirm => (
                  <TableRow key={nonMemberFirm.firmName}>
                    <TableCell size="medium">{nonMemberFirm.firmName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Panel.Content>
    </Panel>
  );
};
