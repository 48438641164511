import React from 'react';
import { match } from 'react-router';

import { MyOrders_CoveredIndications_InvestorDeliveryStatusPartsFragment } from '../../../graphql';
import { useMyOrdersFilters } from '../../../hooks/useFilters';
import {
  checkIndicationHasError,
  getInvestorsWithInvalidCompliance,
  MyOrdersGridContext,
  MyOrdersGridCoveredIndication,
  PricedAndLiveOfferings,
} from '../MyOrdersGrid.model';
import { useMyOrdersGridPrimaryQueries } from './useMyOrdersGridPrimaryQueries';
import { useMyOrdersGridSecondaryQueries } from './useMyOrdersGridSecondaryQueries';

type InvestorDeliveryStatusesByCmgEntityKeyRecord = Record<
  string,
  MyOrders_CoveredIndications_InvestorDeliveryStatusPartsFragment
>;
type OfferingByIdRecord = Record<string, PricedAndLiveOfferings>;

export const useMyOrdersGridData = ({ match }: { match: match }) => {
  const [
    prospectusDeliverySummariesByOfferingIdCmgEntityKey,
    setProspectusDeliverySummariesByOfferingIdCmgEntityKey,
  ] = React.useState<InvestorDeliveryStatusesByCmgEntityKeyRecord>({});

  const [offeringsById, setOfferingsById] = React.useState<OfferingByIdRecord>({});
  const [gridData, setGridData] = React.useState<MyOrdersGridCoveredIndication[] | undefined>(
    undefined
  );
  const { filters, setFilters, filteredData } = useMyOrdersFilters<MyOrdersGridCoveredIndication>({
    sourceIndications: gridData,
  });

  const {
    indicationsData,
    offerings,
    loading: primaryQueriesLoading,
    errors: primaryErrors,
    refetchOfferings,
    hasCalled: hasCalledPrimaryQueries,
  } = useMyOrdersGridPrimaryQueries({
    filters,
  });

  const {
    prospectusDeliverySummaries,
    getProspectusDeliverySummaries,
    errors: secondaryErrors,
    loading: secondaryQueriesLoading,
    hasCalled: hasCalledSecondaryQueries,
  } = useMyOrdersGridSecondaryQueries();

  React.useEffect(() => {
    if (prospectusDeliverySummaries?.prospectusDeliverySummaries) {
      const prospectusDeliverySummariesByOfferingIdCmgEntityKey =
        prospectusDeliverySummaries?.prospectusDeliverySummaries.reduce((result, status) => {
          return {
            ...result,
            [`${status.offeringId}.${status.cmgEntityKey}`]: status,
          };
        }, {});

      setProspectusDeliverySummariesByOfferingIdCmgEntityKey(
        prospectusDeliverySummariesByOfferingIdCmgEntityKey
      );
    }
  }, [prospectusDeliverySummaries]);

  React.useEffect(() => {
    if (offerings.length) {
      setOfferingsById(
        (offerings as PricedAndLiveOfferings[])
          .filter(offering => offering.isExecutingOnPlatform)
          .reduce((result, offering) => {
            return {
              ...result,
              [offering.id]: offering,
            };
          }, {})
      );
    }
  }, [offerings]);

  React.useEffect(() => {
    if (indicationsData) {
      const cmgEntityKeys = [
        ...new Set(
          indicationsData.coveredIndications.map(
            indication => indication.investorInformation.cmgEntityKey!
          )
        ),
      ];

      const offeringIds = [
        ...new Set(indicationsData.coveredIndications.map(indication => indication.offeringId)),
      ];

      getProspectusDeliverySummaries({
        variables: {
          offeringIds,
          investorKeys: cmgEntityKeys,
        },
      });
    }
  }, [indicationsData, getProspectusDeliverySummaries]);

  const gridContext = React.useMemo<MyOrdersGridContext>(() => {
    const investorsWithInvalidCompliance = getInvestorsWithInvalidCompliance({
      filteredData,
    });

    return {
      matchUrl: match.url,
      offerings: offeringsById,
      investorsWithInvalidCompliance,
    };
  }, [match.url, filteredData, offeringsById]);

  React.useEffect(() => {
    if (!primaryQueriesLoading && indicationsData) {
      const indications = indicationsData.coveredIndications
        .filter(indication => offeringsById[indication.offeringId])
        .map<MyOrdersGridCoveredIndication>(indication => {
          const offering = offeringsById[indication.offeringId];

          const prospectusStatus = secondaryQueriesLoading
            ? undefined
            : prospectusDeliverySummariesByOfferingIdCmgEntityKey[
                `${indication.offeringId}.${indication.investorInformation.cmgEntityKey!}`
              ] ?? null;

          const coveredIndication = {
            ...indication,
            ...{
              ...{
                prospectusStatus,
              },
            },
            issuer: offering?.issuerName,
            offeringType: offering?.type,
            offeringStatus: offering?.status,
            offeringPrice: offering?.finalFilingOfferPrice,
            pricingDate: offering?.pricingDate,
            pricingCurrencyCode: offering?.pricingCurrencyCode,
            pricingInstrumentCountryCode: offering?.pricingInstrumentCountryCode,
          };

          return {
            ...coveredIndication,
            hasAlerts: checkIndicationHasError(coveredIndication),
          };
        });

      setGridData(indications);
    }
  }, [
    indicationsData,
    offeringsById,
    primaryQueriesLoading,
    prospectusDeliverySummariesByOfferingIdCmgEntityKey,
    secondaryQueriesLoading,
  ]);

  return {
    primaryErrors,
    primaryQueriesLoading,
    hasCalledPrimaryQueries,
    secondaryErrors,
    secondaryQueriesLoading,
    hasCalledSecondaryQueries,
    gridData,
    gridContext,
    setFilters,
    filters,
    refetchOfferings,
    filteredData,
  };
};
