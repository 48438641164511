import { Theme } from '@cmg/common';

import { SalesCreditReleaseStatus } from '../../../../../../graphql';

export const releaseStatusMap = new Map<SalesCreditReleaseStatus, string>([
  [SalesCreditReleaseStatus.Unreleased, 'Unreleased'],
  [SalesCreditReleaseStatus.Booked, 'Booked'],
  [SalesCreditReleaseStatus.BookedManual, 'Booked (Manual)'],
  [SalesCreditReleaseStatus.Released, 'Released'],
  [SalesCreditReleaseStatus.ReleaseFailed, 'Release Failed'],
  [SalesCreditReleaseStatus.CancelPending, 'Cancel Pending'],
  [SalesCreditReleaseStatus.Cancelled, 'Cancelled'],
  [SalesCreditReleaseStatus.CancelledManual, 'Cancelled (Manual)'],
]);

export const getReleaseStatusColorMap = (theme: Theme): Map<SalesCreditReleaseStatus, string> =>
  new Map([
    [SalesCreditReleaseStatus.Unreleased, theme.designSystem.colors.gray[200]],
    [SalesCreditReleaseStatus.Booked, theme.designSystem.colors.green[300]],
    [SalesCreditReleaseStatus.BookedManual, theme.designSystem.colors.green[300]],
    [SalesCreditReleaseStatus.Released, theme.designSystem.colors.turquoise[300]],
    [SalesCreditReleaseStatus.ReleaseFailed, theme.designSystem.colors.red[300]],
    [SalesCreditReleaseStatus.CancelPending, theme.designSystem.colors.yellow[300]],
    [SalesCreditReleaseStatus.Cancelled, theme.designSystem.colors.red[300]],
    [SalesCreditReleaseStatus.CancelledManual, theme.designSystem.colors.red[300]],
  ]);
