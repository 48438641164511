import React from 'react';

import { InstitutionalDemandGridUpdate } from '../hooks/data-refresh-hooks/useComputeDiffOnInstitutionalDemandGrid';

// The purpose of this context is for easy access and write recent updates of Order Book
// accross multiple components and more importantly plan ahead if product wants to persist
// Updates History outsite of Institutional Demand tab, this Context can be easily lifted up
// in the component hierachy
interface ContextType {
  recentUpdates: InstitutionalDemandGridUpdate[];
  setRecentUpdates: React.Dispatch<React.SetStateAction<InstitutionalDemandGridUpdate[]>>;
}

const defaults: ContextType = {
  recentUpdates: [],
  setRecentUpdates: () => {},
};

const InstitutionalDemandGridUpdatesContext = React.createContext(defaults);

export default InstitutionalDemandGridUpdatesContext;
