import React from 'react';

import { Disclaimer } from '../../common/components/disclaimer/Disclaimer';
import { WireFormLayout } from '../../common/components/form/layout/WireFormLayout';
import { WireFormLayoutItem } from '../../common/components/form/layout/WireFormLayoutItem';
import ManagerRevisedUnderwritingCommitment from '../../common/components/manager-economics/ManagerRevisedUnderwritingCommitment';
import { WireFormBanner } from '../../common/components/modals/create-or-update-wire-modal/wire-form-banner/WireFormBanner';
import { CreateOrUpdateFormProps } from '../../common/context/WireTypeConfigContext.model';

export const GreenShoeWireForm: React.VFC<CreateOrUpdateFormProps> = ({
  offeringId,
  managers,
  onFormDataLoaded,
}) => {
  return (
    <WireFormLayout>
      <WireFormLayoutItem>
        <WireFormBanner variant="common" />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <Disclaimer />
      </WireFormLayoutItem>
      <WireFormLayoutItem>
        <ManagerRevisedUnderwritingCommitment
          offeringId={offeringId}
          recipients={managers}
          onDataLoaded={onFormDataLoaded}
        />
      </WireFormLayoutItem>
    </WireFormLayout>
  );
};
