import styled from 'styled-components/macro';

export const SContent = styled.div`
  display: block;
`;

export const SText = styled.div`
  max-width: 500px;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
