import React from 'react';

import { SyndicateWire } from '../../SyndicateWiresRoute.model';
import { RouteConfigType } from '../components/navigation/syndicate-wires-side-nav/SyndicateWiresSideNav.model';
import { WireTypeConfig, WireTypeValidation } from './WireTypeConfigContext.model';
import { SContent } from './WireTypeConfigContext.styles';

const WireTypeConfigContext = React.createContext<WireTypeConfig | undefined>(undefined);

export const useWireTypeConfigContext = <
  TValues extends Record<string, unknown> = Record<string, unknown>,
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  TRouteConfig extends RouteConfigType = RouteConfigType
>() => {
  const context = React.useContext(WireTypeConfigContext);

  if (context === undefined) {
    throw new Error('No Provider found for WireTypeConfigContext');
  }

  return context as unknown as WireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig>;
};

export function WireTypeConfigContextProvider<
  TValues extends Record<string, unknown> = Record<string, unknown>,
  TWire extends SyndicateWire = SyndicateWire,
  TWireValidation extends WireTypeValidation = WireTypeValidation,
  TRouteConfig extends RouteConfigType = RouteConfigType
>(props: {
  value: WireTypeConfig<TValues, TWire, TWireValidation, TRouteConfig>;
  children?: React.ReactNode;
}) {
  return (
    <WireTypeConfigContext.Provider value={props.value as unknown as WireTypeConfig}>
      <SContent data-test-id={`Syndicate Wires / Specific Wire / ${props.value.wireTypeName}`}>
        {props.children}
      </SContent>
    </WireTypeConfigContext.Provider>
  );
}
