import React from 'react';

import Panel from '../../../../../../../../../common/components/layout/panel/Panel';
import { Roadshows_EventParticipantPartsFragment } from '../../../../../../../common/graphql';
import { SColumn, SHeaderColumn, StyledFlexRow } from './InvestorsPanel.styles';

export type Props = {
  investors: readonly Roadshows_EventParticipantPartsFragment[];
  teamName: string;
};

const InvestorsPanel: React.FC<Props> = ({ investors, teamName }) => {
  return (
    <Panel>
      <Panel.Header>
        {teamName} ({investors.length})
      </Panel.Header>
      <Panel.Content>
        <StyledFlexRow>
          <SHeaderColumn>Name</SHeaderColumn>
          <SHeaderColumn>Email</SHeaderColumn>
        </StyledFlexRow>

        {investors.map(investor => (
          <StyledFlexRow key={investor.id}>
            <SColumn>{investor.name}</SColumn>
            <SColumn>{investor.email}</SColumn>
          </StyledFlexRow>
        ))}
      </Panel.Content>
    </Panel>
  );
};

export default InvestorsPanel;
