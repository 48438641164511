import { numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

export type GrossSpreadAllocations = {
  managementFeePercentage?: number | null;
  underwritingFeePercentage?: number | null;
  sellingConcessionPercentage?: number | null;
};

export const calculateTotalGrossSpread = ({
  managementFeePercentage,
  underwritingFeePercentage,
  sellingConcessionPercentage,
}: GrossSpreadAllocations) => {
  return isNil(managementFeePercentage) &&
    isNil(underwritingFeePercentage) &&
    isNil(sellingConcessionPercentage)
    ? null
    : numericUtil.sum(
        managementFeePercentage,
        underwritingFeePercentage,
        sellingConcessionPercentage
      );
};
