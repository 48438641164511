import React from 'react';

import { Roadshows_EventParticipantsTeamPartsFragment } from '../../../../../../common/graphql';
import ParticipantsPanel from '../../../../common/participants-panel/ParticipantsPanel';
import EventFormSubHeader from '../../../../panel-meetings-event/common/event-form-subheader/EventFormSubHeader';

export type Props = { participants: readonly Roadshows_EventParticipantsTeamPartsFragment[] };

const LogisticsParticipants: React.FC<Props> = ({ participants }) => {
  return (
    <div>
      <EventFormSubHeader title="Participants">
        {participants.map(({ participants, name, cmgEntityKey }) => (
          <ParticipantsPanel
            key={cmgEntityKey}
            teamName={name}
            participants={participants}
            showExtendedInfo={true}
          />
        ))}
      </EventFormSubHeader>
    </div>
  );
};

export default LogisticsParticipants;
