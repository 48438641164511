import { UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { Roadshows_EventParticipantPartsFragment } from '../../../../common/graphql';
import {
  SColumn,
  SHeaderColumn,
  SHeaderRow,
  SIconHeaderColumn,
  StyledFlexRow,
  StyledIconButton,
} from './ParticipantsPanel.styles';

export type Props = {
  teamName: string;
  participants: readonly Roadshows_EventParticipantPartsFragment[];
  onDelete?: (participantId: UUID) => void;
  showExtendedInfo: boolean;
};

const ParticipantsPanel: React.FC<Props> = ({
  participants,
  onDelete,
  teamName,
  showExtendedInfo,
}) => {
  const theme = useTheme();

  return (
    <Panel>
      <Panel.Header>
        {teamName} ({participants.length})
      </Panel.Header>
      <Panel.Content>
        <SHeaderRow direction="row" expand>
          <SHeaderColumn>Name</SHeaderColumn>
          {showExtendedInfo && <SHeaderColumn>Title</SHeaderColumn>}
          {showExtendedInfo && <SHeaderColumn>Email</SHeaderColumn>}
          <SIconHeaderColumn />
        </SHeaderRow>
        {participants.map(participant => (
          <StyledFlexRow key={participant.id} direction="row" expand>
            <SColumn>{participant.name}</SColumn>
            {showExtendedInfo && <SColumn>{participant.title}</SColumn>}
            {showExtendedInfo && <SColumn>{participant.email}</SColumn>}
            {onDelete && (
              <StyledIconButton
                testId={xcSelectors.roadshowParticipantsFormDeleteParticipantButton.testId}
                icon={{ name: 'trash-alt', fixedWidth: true, color: theme.text.color.link }}
                onClick={() => onDelete(participant.id)}
              />
            )}
          </StyledFlexRow>
        ))}
      </Panel.Content>
    </Panel>
  );
};

export default ParticipantsPanel;
