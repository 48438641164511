import { numericUtil } from '@cmg/common';
import React from 'react';

import { getTotalGainLoss } from '../StabilizationFormContent.model';
import { STotalGainLossCell, STotalGainLossValueCell } from './TotalGainLossInfo.styles';

export type Props = {
  stabilizationTrades: number | null;
  overallotmentRefresh: number | null;
};

const TotalGainLossInfo: React.FC<Props> = ({ stabilizationTrades, overallotmentRefresh }) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>Stabilization Trades</td>
          <td align="right">{numericUtil.getDisplayValueForCurrency(stabilizationTrades, 6)}</td>
        </tr>
        <tr>
          <td>Overallotment Refresh</td>
          <td align="right">{numericUtil.getDisplayValueForCurrency(overallotmentRefresh, 6)}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <hr />
          </td>
        </tr>
        <tr>
          <STotalGainLossCell>Total Gain/(Loss)</STotalGainLossCell>
          <STotalGainLossValueCell>
            {numericUtil.getDisplayValueForCurrency(
              getTotalGainLoss(stabilizationTrades, overallotmentRefresh),
              6
            )}
          </STotalGainLossValueCell>
        </tr>
      </tbody>
    </table>
  );
};

export default TotalGainLossInfo;
