import capitalize from 'lodash/capitalize';

import { OfferingNoteSubject } from './../../../../../graphql';

export const offeringNoteFormInitialValues = {
  subject: null,
  note: '',
};

export const offeringNoteFormSubjectOptions = Object.values(OfferingNoteSubject).map(value => ({
  value,
  label: capitalize(value),
}));
