import { DefaultTheme } from 'styled-components/macro';

export type BannerVariant = 'error' | 'warning' | 'success' | 'information';

export const getBannerIconName = (variant: BannerVariant) => {
  switch (variant) {
    case 'error':
      return 'times-circle';

    case 'warning':
      return 'exclamation-circle';

    case 'success':
      return 'check-circle';

    default:
      return 'info-circle';
  }
};

export const getIconColor = (theme: DefaultTheme, variant: BannerVariant) => {
  switch (variant) {
    case 'error':
      return theme.designSystem.colors.red['300'];

    case 'warning':
      return theme.designSystem.colors.yellow['200'];

    case 'success':
      return theme.designSystem.colors.green['300'];

    default:
      return theme.designSystem.colors.turquoise['300'];
  }
};
