import { useAuth } from '@cmg/auth';
import { Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../graphql';
import { IndicationWithDemandLevels, InstitutionalDemandGridContext } from '../../types';
import { SDuplicateAcknowledgement } from './IndicationAcknowledgementsCell.styles';
import IndicationAcknowlegment from './IndicationAcknowlegment';
import { getAcknowledgmentTooltipContent, getSortedAcknowledgementsList } from './utils';

export type Props = {
  data: IndicationWithDemandLevels;
  context: InstitutionalDemandGridContext;
};

const IndicationAcknowledgementsCell: React.FC<Props> = ({ data, context }) => {
  const { syndicateManagers } = context;
  const { oidcUserCmgEntityKey } = useAuth();

  const { institutionalIndicationAcknowledgements, version: currentIndicationVersion } = data;
  const sortedAcknowledgements = React.useMemo(
    () =>
      getSortedAcknowledgementsList(
        syndicateManagers,
        oidcUserCmgEntityKey!,
        institutionalIndicationAcknowledgements
      ),
    [institutionalIndicationAcknowledgements, oidcUserCmgEntityKey, syndicateManagers]
  );

  const hasRevised = sortedAcknowledgements.some(
    ack => ack.acknowledgedIndicationVersion !== currentIndicationVersion
  );
  const hasRevisedInAdditional = sortedAcknowledgements
    .slice(3)
    .some(ack => ack.acknowledgedIndicationVersion !== currentIndicationVersion);

  if (data.status === IndicationStatus.Duplicate) {
    return <SDuplicateAcknowledgement>-</SDuplicateAcknowledgement>;
  }

  return (
    <Tooltip
      title={getAcknowledgmentTooltipContent(
        sortedAcknowledgements,
        syndicateManagers,
        oidcUserCmgEntityKey!,
        currentIndicationVersion,
        hasRevised
      )}
      variant={hasRevised ? 'warning' : 'info'}
      placement="top"
    >
      <Typography noWrap overflow="hidden" textOverflow="ellipsis">
        {sortedAcknowledgements.slice(0, 3).map((acknowledgment, index) => {
          return (
            <span key={`acknowledgement-${acknowledgment.managerCmgEntityKey}`}>
              <IndicationAcknowlegment
                currentIndicationVersion={currentIndicationVersion}
                acknowledgment={acknowledgment}
                syndicateManagers={syndicateManagers}
                isUsersFirm={acknowledgment.managerCmgEntityKey === oidcUserCmgEntityKey}
                numberOfAcknowledgements={sortedAcknowledgements.length}
                index={index}
              />
            </span>
          );
        })}

        {sortedAcknowledgements.length > 3 && (
          <Typography
            fontSize={13}
            component="span"
            color={hasRevisedInAdditional ? theme => theme.palette.warning.dark : undefined}
          >
            &nbsp;+{sortedAcknowledgements.length - 3}...
          </Typography>
        )}
      </Typography>
    </Tooltip>
  );
};

export default IndicationAcknowledgementsCell;
