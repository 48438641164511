import { ODSTable as Table } from '@cmg/common';
import { format } from 'date-fns';
import React from 'react';

import { EmptyState } from '../../../../design-system/components/data-display/empty-state/EmptyState';
import { TextWithEllipsis } from '../../common/TextWithEllipsis';
import { TradeRelease_IndicationTradePartsFragment } from '../graphql';
import { TradeReleaseStatus } from './TradeReleaseStatus';

export type Props = {
  trades: readonly TradeRelease_IndicationTradePartsFragment[];
};

export const TradeReleaseHistoryTable: React.VFC<Props> = ({ trades }) => {
  return (
    <Table gridTemplateColumns="1fr 1fr 1fr 1fr">
      <Table.Row>
        <Table.TableHeaderCell>Date</Table.TableHeaderCell>
        <Table.TableHeaderCell>Completed by</Table.TableHeaderCell>
        <Table.TableHeaderCell>Status Details</Table.TableHeaderCell>
        <Table.TableHeaderCell>Trade Release Status</Table.TableHeaderCell>
      </Table.Row>

      {trades.length === 0 && (
        <Table.Row suppressHighlightingOnHover isFocused={false}>
          <Table.Cell colSpan={4} withBottomBorder={false}>
            <EmptyState
              title="No Trade History"
              message="There is currently no trade history for this investor"
            />
          </Table.Cell>
        </Table.Row>
      )}

      {trades.map(trade => {
        return (
          <Table.Row
            key={trade.id}
            title={trade.id}
            isFocused={false}
            suppressHighlightingOnHover={true}
          >
            <Table.Cell>{format(new Date(trade.createdAt), 'MM/dd/yyyy HH:mm a')}</Table.Cell>
            <Table.Cell>{trade.createdByUserName}</Table.Cell>
            <Table.Cell>
              <TextWithEllipsis text={trade.statusDetails} maxLength={53} />
            </Table.Cell>
            <Table.Cell>
              <TradeReleaseStatus status={trade.tradeStatus} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};
