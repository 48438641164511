import { NumericFieldCellEditor } from '@cmg/data-grid';
import React from 'react';
import * as yup from 'yup';

import type { OrderBook_InstitutionalDemand_AllocationPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridCellEditorProps } from '../../types';

export const validationSchema = yup.object().shape({
  cellEditorField: yup.number().integer().min(0).required().nullable().label('Allocation shares'),
});

export type Props = DemandGridCellEditorProps<
  OrderBook_InstitutionalDemand_AllocationPartsFragment['shares']
>;

export const AllocationCellEditor: React.FC<Props> = ({ initialValue, onValueChange }) => {
  return (
    <NumericFieldCellEditor
      initialValue={initialValue}
      onValueChange={onValueChange}
      validationSchema={validationSchema}
    />
  );
};
