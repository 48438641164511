import styled from 'styled-components/macro';

export const InfoIconWrapper = styled.span`
  margin-left: 8px;
`;

export const SContentWrapper = styled.div`
  min-width: 350px;

  & > table {
    width: 100%;
  }
`;
