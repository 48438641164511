import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { SyndicateWiresManagerRole, WireTemplateType } from '../../../../../graphql';
import {
  createUseDefaultManagers,
  createUseDefaultOfferingManagers,
  createWireTypeConfig,
} from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { createDefaultUseOutdatedManagersList } from '../../common/hooks/createDefaultUseOutdatedManagersList';
import { createUseDefaultIsWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsWireManagerListOutdated';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import {
  SyndicateWires_SellingGroupTermsWirePartsFragment,
  SyndicateWires_SellingGroupTermsWireValidationPartsFragment,
  useSyndicateWires_SellingGroupTermsWireDetailsQuery,
  useSyndicateWires_SellingGroupTermsWirePreviewQuery,
  useSyndicateWires_SellingGroupTermsWiresQuery,
  useSyndicateWires_SellingGroupTermsWireValidationQuery,
} from './graphql';
import { useCreateSellingGroupTermsWireMutation } from './hooks/useCreateSellingGroupTermsWireMutation';
import { useDeleteSellingGroupTermsWireMutation } from './hooks/useDeleteSellingGroupTermsWireMutation';
import { useSendSellingGroupTermsWireMutation } from './hooks/useSendSellingGroupTermsWireMutation';
import { useUpdateSellingGroupTermsWireMutation } from './hooks/useUpdateSellingGroupTermsWireMutation';
import { SellingGroupTermsRetentionWireForm } from './SellingGroupTermsRetentionWireForm';
import { validationSchema, Values } from './SellingGroupTermsRetentionWireForm.model';

const wireTypeName = 'Selling Group Terms/Retention';
const managerFilter = (manager: SyndicateWireManager) => {
  return manager.role === SyndicateWiresManagerRole.SellingGroupMember;
};

export const wireTypeConfigSellingGroupTermsRetention = createWireTypeConfig<
  Values,
  SyndicateWires_SellingGroupTermsWirePartsFragment,
  SyndicateWires_SellingGroupTermsWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresSellingGroupTermsRetention
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresSellingGroupTermsRetention,
  wireTemplateType: WireTemplateType.SellingGroupTerms,
  CreateOrUpdateForm: SellingGroupTermsRetentionWireForm,
  formValidation: validationSchema,

  useManagers: createUseDefaultManagers(managerFilter),
  useNextRecipients: createUseDefaultOfferingManagers(managerFilter),
  useIsWireManagerListOutdated: createUseDefaultIsWireManagerListOutdated(managerFilter),
  useOutdatedManagersList: createDefaultUseOutdatedManagersList(managerFilter),

  useWireList: ({ offeringId }) => {
    const { data, loading, error } = useSyndicateWires_SellingGroupTermsWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.sellingGroupTermsWireList,
      isLoading: loading,
      error,
    };
  },
  useGenerateWirePreview: queryArgs => {
    const { data, loading, error } = useSyndicateWires_SellingGroupTermsWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.sellingGroupTermsWirePreview.htmlContent,
      isLoading: loading,
      error,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, loading, error } = useSyndicateWires_SellingGroupTermsWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    return {
      data: data && {
        wire: data.sellingGroupTermsWireDetails,
        stage: data.offering.stage,
      },
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } =
      useSyndicateWires_SellingGroupTermsWireValidationQuery({
        variables: { offeringId },
        fetchPolicy: fetchPolicy || 'cache-and-network',
      });

    return { data: data?.sellingGroupTermsWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateSellingGroupTermsWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: { offeringId, payload: values },
        });

        if (result.data?.createSellingGroupTermsWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} wire failed.`);
        }

        return result.data?.createSellingGroupTermsWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateSellingGroupTermsWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: values },
        });

        if (result.data?.updateSellingGroupTermsWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} wire failed.`);
        }

        return result.data?.updateSellingGroupTermsWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteSellingGroupTermsWireMutation();

    return {
      mutation: async variables => {
        const result = await deleteWire({ variables });

        if (result.data?.deleteSellingGroupTermsWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} wire failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendSellingGroupTermsWireMutation();

    return {
      mutation: async variables => {
        const result = await sendWire({
          variables: { ...variables, ...generatePayloadDateTimeData() },
        });

        if (result.data?.sendSellingGroupTermsWire.__typename === 'ServiceError') {
          throw new Error(
            `Sending the ${wireTypeName} wire failed. 
${result.data.sendSellingGroupTermsWire?.message}`
          );
        }

        return result.data?.sendSellingGroupTermsWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      return {
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [operationType, wire?.disclaimer, disclaimerData?.syndicateWires.wiresDisclaimer]);

    return { isLoading: disclaimerLoading, data, error };
  },
});
