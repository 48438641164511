import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoadingButton,
} from '@cmg/design-system';
import React from 'react';

type UnsavedChangesModalProps = DialogProps & {
  isSaving: boolean;
  onContinueEditing: () => void;
  onDiscardAndLeave: () => void;
  onSave: () => void;
  isSaveButtonDisabled?: boolean;
};

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  onContinueEditing,
  onDiscardAndLeave,
  onSave,
  isSaving,
  isSaveButtonDisabled = false,
  ...props
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent dividers>
        You have unsaved changes on the page. <br />
        Do you want to save them or discard them?
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="outlined" disabled={isSaving} onClick={onContinueEditing}>
            Continue Editing
          </Button>
          <Box>
            <Button variant="outlined" disabled={isSaving} onClick={onDiscardAndLeave}>
              Discard & Leave
            </Button>
            <LoadingButton
              sx={{ marginLeft: '8px' }}
              loading={isSaving}
              disabled={isSaveButtonDisabled}
              variant="contained"
              onClick={onSave}
            >
              Save & Leave
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesModal;
