import { useCheckAccountTraits } from '@cmg/auth';
import { Icon, ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { SingleCurrencyInstrumentTrancheBanner } from '../components/single-currency-instrument-tranche-banner/SingleCurrencyInstrumentTrancheBanner';
import { CurrencyGrid } from './currency-grid/CurrencyGrid';
import DemandCurrencyForm from './DemandCurrencyForm';
import {
  useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation,
  useOfferingSetup_DemandCurrenciesQuery,
} from './graphql';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

const SLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

/**
 * Primary Route for the Offering Setup module
 */
export const DemandCurrencyRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const hasCreateInternationalTrait = useCheckAccountTraits(['XC_CREATE_INTERNATIONAL']);

  const { data, loading, error, refetch } = useOfferingSetup_DemandCurrenciesQuery({
    variables: { offeringId },
    fetchPolicy: 'cache-and-network',
  });

  const [
    createOrUpdateDemandCurrencies,
    { loading: updatingDemandCurrencies, error: updateDemandCurrenciesError },
  ] = useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation();

  const handleOfferingSetup_CreateOrUpdateDemandCurrenciesMutation = async updatedCurrencies => {
    try {
      await createOrUpdateDemandCurrencies({
        variables: {
          input: {
            offeringId,
            demandCurrencies: updatedCurrencies,
          },
        },
      });

      ToastManager.success('Successfully updated Demand Currencies');
      refetch();
    } catch (err) {
      ToastManager.error('Failed to update Demand Currencies');
    }
  };

  if (loading) {
    return <Spinner show />;
  }

  if (!data) {
    return null;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        {!hasCreateInternationalTrait && (
          <div>
            <SetupScreen.Header />
            <SingleCurrencyInstrumentTrancheBanner />
            {error && <ServerErrorsBanner error={error} />}
            {loading && (
              <SLoadingWrapper>
                <Icon name="spinner-third" spin />
              </SLoadingWrapper>
            )}

            <SetupForm title="Demand Currencies">
              <SetupForm.Table>
                <SetupForm.TableHeader>
                  <SetupForm.Column>Currency Code</SetupForm.Column>
                  <SetupForm.Column>Exchange Rate</SetupForm.Column>
                </SetupForm.TableHeader>
                {data.offering.currencies.map(demandCurrency => (
                  <DemandCurrencyForm key={demandCurrency.id} data={demandCurrency} />
                ))}
              </SetupForm.Table>
            </SetupForm>
          </div>
        )}
        {hasCreateInternationalTrait && (
          <div>
            {updateDemandCurrenciesError && (
              <ServerErrorsBanner error={updateDemandCurrenciesError} />
            )}
            <CurrencyGrid
              pricingCurrencyCode={data.offering.pricingCurrencyCode}
              currencies={data.offering.currencies}
              onSave={handleOfferingSetup_CreateOrUpdateDemandCurrenciesMutation}
              loading={updatingDemandCurrencies}
            />
          </div>
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default DemandCurrencyRoute;
