import React from 'react';

import { Roadshows_EventParticipantsTeamPartsFragment } from '../../../../../../common/graphql';
import EventFormSubHeader from '../../../common/event-form-subheader/EventFormSubHeader';
import InvestorsPanel from './investors-panel/InvestorsPanel';

export type Props = { investors: readonly Roadshows_EventParticipantsTeamPartsFragment[] };

const MeetingInvestors: React.FC<Props> = ({ investors }) => {
  return (
    <EventFormSubHeader title="Investors">
      {investors.map(investor => (
        <InvestorsPanel
          key={investor.cmgEntityKey}
          investors={investor.participants}
          teamName={investor.name}
        />
      ))}
    </EventFormSubHeader>
  );
};

export default MeetingInvestors;
