import { getCurrencySymbol, numericUtil } from '@cmg/common';
import isNil from 'lodash/isNil';

import { CalendarGridRendererProps } from '../../../../calendar.model';

export const SizeInCurrencyRenderer: React.FC<CalendarGridRendererProps> = ({
  data: { sizeInCurrency, pricingCurrencyCode },
}) => {
  return isNil(sizeInCurrency) ? (
    <span>-</span>
  ) : (
    <span>
      {numericUtil.formatCurrencyInMillions(
        sizeInCurrency,
        undefined,
        getCurrencySymbol(pricingCurrencyCode)
      )}
    </span>
  );
};
