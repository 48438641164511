import { assets, Popover } from '@cmg/common';

import { SExecutedOnPlatform } from './IsExecutedOnPlatformRenderer.styles';

export const IsExecutedOnPlatformRenderer = ({ value }) =>
  value ? (
    <Popover
      content="This offering is being managed on the CMG Platform."
      variant="DARK"
      placement="topRight"
    >
      <SExecutedOnPlatform
        src={assets.logoNoText}
        alt="This offering is being managed on the CMG Platform."
      />
    </Popover>
  ) : (
    <div />
  );
