import { Box, Icon, Stack, Typography } from '@cmg/design-system';
import { VFC } from 'react';

import emptyWire from './../../../../../../../../common/assets/empty-wire.svg';

export const LoadingErrorEmptyState: VFC = () => {
  return (
    <Stack alignItems="center" width="100%" px={4}>
      <Box pt={1} pb={2}>
        <Icon sx={{ width: '150px', height: '80px' }}>
          <img
            src={emptyWire}
            alt="There was an error loading information"
            width="150px"
            height="80px"
          />
        </Icon>
      </Box>
      <Typography variant="h3" color="text.primary">
        There was an error loading information
      </Typography>
      <Typography pt={0.5} color="text.secondary">
        In order to continue creating the wire, refresh the page
      </Typography>
    </Stack>
  );
};
