import { UUID } from '@cmg/common';
import flatten from 'lodash/flatten';

import { OrderBook_TotalRetailSummaryItemPartsFragment } from '../../graphql';
import { getLatestSummaryItem } from '../common/utils/TableRowUtils';

export type AssignDemandVariables = {
  cmgEntityKey: UUID;
  underwriterName: string;
  demandShares: number;
  roundLots: number | null;
};
export type AssignRetentionVariables = {
  cmgEntityKey: UUID;
  underwriterName: string;
  retentionShares: number;
};

export const getLatestModifiedAt = (
  rows: readonly OrderBook_TotalRetailSummaryItemPartsFragment[]
) => {
  const latestItem = getLatestSummaryItem(
    ...flatten(rows.map(({ demand, retention }) => [demand, retention]))
  );

  if (latestItem) {
    return latestItem.modifiedAt;
  }
  return null;
};
