import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';

import { getOfferingTypeFilterOptions } from '../../../../common/util/offering/offering-type.util';
import { sortCaseInsensitive } from '../../../../common/util/sortCaseInsensitive';
import { ManagerRole, Sector } from '../../../../graphql';
import { CalendarGridOffering } from '../calendar-grid/CalendarGrid.model';

export const getUnderwriters = (offerings?: readonly CalendarGridOffering[]) => {
  if (!offerings) {
    return [];
  }

  const underwriters = flatMap(offerings, offering => {
    return offering.managers.filter(manager => manager.role !== ManagerRole.SellingGroupMember);
  });

  return uniqBy(underwriters, 'cmgEntityKey').sort(
    ({ firmName: firmNameA }, { firmName: firmNameB }) => sortCaseInsensitive(firmNameA, firmNameB)
  );
};

export const getOfferingTypes = (offerings: readonly CalendarGridOffering[] | undefined) => {
  return Array.from(
    new Set(
      (offerings ?? [])
        .map(offering => {
          const { type, pricingInstrumentCountryCode } = offering;

          return getOfferingTypeFilterOptions({ offeringType: type, pricingInstrumentCountryCode });
        })
        .sort(sortCaseInsensitive)
    )
  );
};

export const getSectors = (offerings: readonly CalendarGridOffering[] | undefined) => {
  return uniq(
    offerings
      ?.map(offering => offering.issuerSector)
      .filter(sector => !!sector)
      .sort(sortCaseInsensitive) as Sector[]
  );
};

export const getLeftLeads = (offerings: readonly CalendarGridOffering[] | undefined) => {
  return uniq(offerings?.map(offering => offering.leftLeadFirmName).sort(sortCaseInsensitive));
};

export const getRegionToCountry = (offerings: readonly CalendarGridOffering[] | undefined) => {
  return offerings?.reduce((acc, offering) => {
    const {
      pricingInstrumentRegion,
      pricingInstrumentCountryDisplayName,
      pricingInstrumentCountryCode,
    } = offering;

    if (
      !pricingInstrumentRegion ||
      !pricingInstrumentCountryDisplayName ||
      !pricingInstrumentCountryCode
    ) {
      return acc || [];
    }

    const prevCountries = acc[pricingInstrumentRegion] || [];
    const nextCountries = uniqWith(
      [
        ...prevCountries,
        { title: pricingInstrumentCountryDisplayName, value: pricingInstrumentCountryCode },
      ],
      isEqual
    );

    return { ...acc, [pricingInstrumentRegion]: nextCountries };
  }, {});
};
