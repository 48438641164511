import { MyOfferings_SharedDraftsQuery, MyOfferings_SharedOfferingsQuery } from '../graphql';

export type SharedOfferingsQuery = MyOfferings_SharedOfferingsQuery;
export type SharedOffering = NonNullable<
  NonNullable<SharedOfferingsQuery['sharedOfferings']>['items']
>[number];
export type SharedDraft = NonNullable<
  NonNullable<MyOfferings_SharedDraftsQuery['allSharedDrafts']>['data']
>[number];

export const toSharedDraft = (offering: SharedOffering): SharedDraft => {
  let leftLeadFirmName: string | null = null;
  const leftLeadFirmCmgEntityKey = offering.syndicate.managerResponsibilities.leftLead;

  if (leftLeadFirmCmgEntityKey) {
    const leftLeadFirm = offering.syndicate.managers.find(
      manager => manager.cmgEntityKey === leftLeadFirmCmgEntityKey
    );
    leftLeadFirmName = leftLeadFirm?.firmName ?? null;
  }

  return {
    __typename: 'SharedDraftOfferingLimited',
    id: offering.id,
    type: offering.type,
    stage: offering.stage,
    issuerName: offering.issuer.name,
    pricingDate: offering.timing.pricingDate,
    pricingInstrumentStockSymbol: offering.pricingInstrumentStockSymbol,
    issuerSector: offering.issuer.sector,
    sizeInCurrency: offering.sizeInCurrency,
    sizeInShares: offering.sizeInShares,
    leftLeadFirmName,
    isObsolete: offering.isObsolete,
    priceRangeLow: offering.priceRangeLow,
    priceRangeHigh: offering.priceRangeHigh,
    pricingCurrencyCode: offering.pricingCurrencyCode,
  };
};
