import styled from 'styled-components/macro';

export const SBoldText = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const STotalGainLossCell = styled.td`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const STotalGainLossValueCell = styled.td`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  text-align: right;
`;
