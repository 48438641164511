import styled from 'styled-components/macro';

import { ReactComponent as OrderBookNotOpenImage } from './order-book-not-open.svg';

export const SOrderBookNotOpenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
`;

export const SHeading = styled.h2`
  font-size: ${({ theme }) => theme.text.size.xlarge};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  margin: 50px 0 10px;
`;

export const StyledOrderBookNotOpenImage = styled(OrderBookNotOpenImage)`
  margin-top: 20px;
`;
