import { ODSTable } from '@cmg/common';
import styled from 'styled-components/macro';

export const BoxWrapper = styled.div`
  padding: 8px 8px 6px 8px;
  background-color: ${({ theme }) => theme.foundation.colors.info['000']};
  width: 100%;
`;

export const AccountingInfoHeader = styled(ODSTable.TableHeaderCell)`
  background-color: ${({ theme }) => theme.foundation.colors.info['000']};
`;

export const SLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.tiny};
  text-align: right;
  color: ${({ theme }) => theme.foundation.colors.secondary['500']};
`;
