import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useSupersededOfferingContext } from '../contexts/SupersededOfferingContext';
import offeringSidePanelBuySideRouteFactory from '../offeringSidePanelBuySideRouteFactory';
import { OfferingSidePanelRouteContext } from '../OfferingSidePanelContainer';
import ComplianceRoute from './compliance/ComplianceRoute';
import IndicationActivityBuySideRoute from './indication-activity/IndicationActivityBuySideRoute';
import MyInternalDemandRoute from './my-internal-demand/MyInternalDemandRoute';
import UpdateHistoryRoute from './update-history/UpdateHistoryRoute';

/**
 * Side Panel routes for the Buy Side sections of the
 * Order Book module
 */
const OrderBookBuySideRoute: React.FC = () => {
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  const { isFiledOffering } = useSupersededOfferingContext();

  const redirectUrl =
    baseSidePanelUrl +
    (isFiledOffering
      ? offeringSidePanelBuySideRouteFactory.internalDemand.getUrlPath()
      : offeringSidePanelBuySideRouteFactory.orderBookIndicationActivity.getUrlPath());

  return (
    <Switch>
      <Route
        path={offeringSidePanelBuySideRouteFactory.orderBookIndicationActivity.routePath}
        component={IndicationActivityBuySideRoute}
      />
      <Route
        path={offeringSidePanelBuySideRouteFactory.internalDemand.routePath}
        component={MyInternalDemandRoute}
      />
      <Route
        path={offeringSidePanelBuySideRouteFactory.orderBookUpdateHistory.routePath}
        component={UpdateHistoryRoute}
      />
      <Route
        path={offeringSidePanelBuySideRouteFactory.orderBookCompliance.routePath}
        component={ComplianceRoute}
      />
      <Redirect to={redirectUrl} />
    </Switch>
  );
};

export default OrderBookBuySideRoute;
