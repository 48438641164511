import { FlexContainer, SecondaryButton, ToastManager, UUID } from '@cmg/common';
import React from 'react';

import Modal from '../../../../common/components/overlays/modal/Modal';
import { OrderBookInvestorStatus } from '../../../../graphql';
import { SalesCredits_SalesCreditItemPartsFragment } from '../../graphql';
import { useSalesCredits_ReviewSalesCreditsBankInvestorKeyChangeMutation } from './graphql';
import {
  SDivider,
  SInvestorDetailsTable,
  SLabel,
  STableHeader,
  STableWrapper,
  StyledCancelButton,
  StyledModalContent,
} from './SalesCreditsBankInvestorKeyReviewModal.styles';

export type Props = {
  offeringId: UUID;
  reviewedItem: SalesCredits_SalesCreditItemPartsFragment | null;
  onHide: () => void;
};

const SalesCreditsBankInvestorKeyReviewModal: React.FC<Props> = ({
  offeringId,
  reviewedItem,
  onHide,
}) => {
  const [reviewChanges, { loading }] =
    useSalesCredits_ReviewSalesCreditsBankInvestorKeyChangeMutation();

  const handleReviewChanges = async (
    investorStatus: OrderBookInvestorStatus,
    successToast: string,
    errorToast: string
  ) => {
    try {
      if (!reviewedItem) {
        return;
      }

      await reviewChanges({
        variables: {
          offeringId,
          cmgEntityKey: reviewedItem.investorFirmCmgEntityKey,
          payload: {
            bankInvestorKey: reviewedItem.orderBookBankInvestorKey!,
            investorStatus,
          },
        },
      });
      ToastManager.success(successToast);
      onHide();
    } catch {
      ToastManager.error(errorToast);
    }
  };

  const handleReject = async () => {
    await handleReviewChanges(
      OrderBookInvestorStatus.Rejected,
      'Successfully rejected indication bank investor key',
      'Failed to reject indication bank investor key'
    );
  };

  const handleAccept = async () => {
    await handleReviewChanges(
      OrderBookInvestorStatus.Accepted,
      'Successfully accepted indication bank investor key',
      'Failed to accept indication bank investor key'
    );
  };

  return (
    <Modal show={!!reviewedItem} title="Review Changes">
      <StyledModalContent>
        <div>
          The Bank Investor Key has been modified from the indication. Would you like to Accept this
          change or Reject and keep the current values?
        </div>
        <STableWrapper>
          <SInvestorDetailsTable>
            <tbody>
              <STableHeader>
                <td colSpan={2}>Current</td>
              </STableHeader>
              <tr>
                <SLabel>Investor:</SLabel>
                <td>{reviewedItem?.investorFirmName ?? '-'}</td>
              </tr>
              <tr>
                <SLabel>Bank Investor Key:</SLabel>
                <td>{reviewedItem?.bankInvestorKey ?? '-'}</td>
              </tr>
            </tbody>
          </SInvestorDetailsTable>
          <SDivider />
          <SInvestorDetailsTable>
            <tbody>
              <STableHeader>
                <td colSpan={2}>Modified</td>
              </STableHeader>
              <tr>
                <SLabel>Investor:</SLabel>
                <td>{reviewedItem?.orderBookBankInvestorFirmName ?? '-'}</td>
              </tr>
              <tr>
                <SLabel>Bank Investor Key:</SLabel>
                <td>{reviewedItem?.orderBookBankInvestorKey ?? '-'}</td>
              </tr>
            </tbody>
          </SInvestorDetailsTable>
        </STableWrapper>
      </StyledModalContent>
      <Modal.Footer>
        <FlexContainer direction="row" gap={16}>
          <StyledCancelButton disabled={loading} onClick={onHide}>
            Cancel
          </StyledCancelButton>
          <SecondaryButton loading={loading} onClick={handleReject}>
            Reject
          </SecondaryButton>
          <SecondaryButton loading={loading} onClick={handleAccept}>
            Accept
          </SecondaryButton>
        </FlexContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesCreditsBankInvestorKeyReviewModal;
