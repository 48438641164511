import { Alert, ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';

import Modal from '../../../../../../../common/components/overlays/modal/Modal';

export type Props = {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteDepartmentModal: React.FC<Props> = ({ show, onClose, onConfirm }) => {
  return (
    <Modal show={show} title="Delete Department" onHide={onClose}>
      <Modal.Content>
        <Alert severity="warning">
          By deleting a department you will be removing this department for all deal team members
          assigned to it
        </Alert>
        <p>This action can't be reverted. Would you like to proceed?</p>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right">
          <SecondaryButton
            testId={xcSelectors.cancelDeleteDealTeamDepartmentButton.testId}
            onClick={onClose}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            testId={xcSelectors.confirmDeleteDealTeamDepartmentButton.testId}
            onClick={onConfirm}
          >
            Confirm
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDepartmentModal;
