import { LinkButton, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { ArrayHelpers, FieldArray, FormikContextType, useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../../common/components/layout/panel/Panel';
import { DealTeamFormValues } from '../DealTeamForm.model';
import DeleteDepartmentModal from './delete-department-modal/DeleteDepartmentModal';
import { SItemWrapper, SListWrapper, STextWrapper } from './DepartmentsPanel.styles';

export type Props = {
  onAdd: ArrayHelpers['push'];
  onRemove: ArrayHelpers['remove'];
  departments: DealTeamFormValues['departments'];
  dealTeamMembers: DealTeamFormValues['dealTeamMembers'];
  setFieldValue: FormikContextType<DealTeamFormValues>['setFieldValue'];
};

// exported for testing
export const DepartmentsPanelComponent: React.FC<Props> = ({
  onAdd,
  onRemove,
  departments,
  dealTeamMembers,
  setFieldValue,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [departmentIndex, setDepartmentIndex] = React.useState<number | null>(null);

  const removeDepartment = (index: number) => {
    dealTeamMembers.forEach((member, memberIndex) => {
      if (member.department === departments[index]) {
        setFieldValue(`dealTeamMembers.${memberIndex}.department`, null, false);
      }
    });
    onRemove(index);
  };

  const changeDepartment = (value: string, index: number) => {
    dealTeamMembers.forEach((member, memberIndex) => {
      if (member.department === departments[index]) {
        setFieldValue(`dealTeamMembers.${memberIndex}.department`, value, false);
      }
    });
  };

  return (
    <Panel>
      <Panel.Header title="Departments">
        <LinkButton
          testId={xcSelectors.addDealTeamDepartment.testId}
          iconLeft={{ name: 'plus' }}
          onClick={() => onAdd('')}
          inline
        >
          Add Department
        </LinkButton>
      </Panel.Header>
      <Panel.Content>
        {departments.length === 0 && (
          <STextWrapper>
            <p>Add departments to the Organization and assign to Deal Team Members.</p>
          </STextWrapper>
        )}
        <SListWrapper>
          {departments.map((_item, index) => (
            <SItemWrapper key={index}>
              <TextInputField
                name={`departments.${index}`}
                onChange={value => changeDepartment(value, index)}
                fullWidth
              />
              <LinkButton
                iconLeft={{ name: 'trash-alt', size: 'lg' }}
                inline
                onClick={() => {
                  setDepartmentIndex(index);
                  setIsDeleteModalOpen(true);
                }}
                testId={`${xcSelectors.removeDealTeamDepartment.testId}.${index}`}
              />
            </SItemWrapper>
          ))}
        </SListWrapper>
      </Panel.Content>
      <DeleteDepartmentModal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          setIsDeleteModalOpen(false);
          removeDepartment(departmentIndex!);
          setDepartmentIndex(null);
        }}
      />
    </Panel>
  );
};

// Performace optimization - FieldArray is rendered on every change of form values
const DepartmentsPanelMemo = React.memo(DepartmentsPanelComponent);

const DepartmentsFieldArray: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<DealTeamFormValues>();

  return (
    <FieldArray name="departments" validateOnChange={false}>
      {({ push, remove }) => (
        <DepartmentsPanelMemo
          onAdd={push}
          onRemove={remove}
          departments={values.departments}
          dealTeamMembers={values.dealTeamMembers}
          setFieldValue={setFieldValue}
        />
      )}
    </FieldArray>
  );
};

export default DepartmentsFieldArray;
