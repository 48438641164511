import { SnackbarManager } from '@cmg/design-system';
import { saveAs } from 'file-saver';
import React from 'react';

import { rolodexApiClient } from '../../../common/api/rolodexApiClient';

export function useDownloadCertificate(firmId: string, certificateId: string | null | undefined) {
  const [loading, setLoading] = React.useState(false);

  const handleDownloadCertificate = React.useCallback(async () => {
    setLoading(true);
    try {
      if (!certificateId) {
        throw new Error('CertificateId is missing.');
      }
      const response = await rolodexApiClient.attestationForm_DownloadCertificatePdfById(
        firmId,
        certificateId
      );
      saveAs(response.data, 'certificate.pdf');
      SnackbarManager.success('Download successfully completed.');
    } catch {
      SnackbarManager.error(
        'An error has occurred while trying to download your data. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  }, [certificateId, firmId]);

  return { handleDownloadCertificate, loading };
}
