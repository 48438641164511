import { GridApi } from 'ag-grid-community';
import React from 'react';

import usePrevious from '../util/usePrevious';

export type GridRefreshData<TData> = {
  gridApi?: GridApi;
  rows: TData[];
};

export const useGridRefresh = <TData>({ gridApi, rows }: GridRefreshData<TData>) => {
  const previousRows = usePrevious(rows);

  React.useEffect(() => {
    if (!gridApi) {
      return;
    }

    if (!previousRows || previousRows === rows) {
      return;
    }

    gridApi.setRowData(rows);
    gridApi.redrawRows();
  }, [gridApi, rows, previousRows]);
};
