import { numericUtil } from '@cmg/common';

import { FinalSettlement_AccountingPartsFragment } from '../../../graphql';
import { getTotal } from '../../common/accounting-data-grid/AccountingDataGrid.model';

export const getTotalFinalSettlement = (accounting: FinalSettlement_AccountingPartsFragment) => {
  return getTotal(accounting.managers, 'finalSettlement');
};

export const getTotalRetailRetentionFees = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  return getTotal(accounting.managers, 'retailRetentionFees');
};

export const calculateFinalSettlementValues = (
  accounting: FinalSettlement_AccountingPartsFragment
) => {
  const totalFinalSettlement = getTotalFinalSettlement(accounting);
  const totalRetailRetentionFees = getTotalRetailRetentionFees(accounting);
  const netDealFees = numericUtil.sum(totalFinalSettlement, totalRetailRetentionFees);

  return { totalFinalSettlement, totalRetailRetentionFees, netDealFees };
};
