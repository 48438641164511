import { Option } from '@cmg/common';
import * as Yup from 'yup';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import {
  BuySellType,
  CreateUsdtnFilingInput,
  TradeCurrencyType,
  TransactionInput,
} from '../../../../../graphql';
import {
  CommonFields,
  commonFilingSchemaShape,
  createInitialCommonFilingValues,
  dateTimeSchema,
  firmCrdSchema,
  getValueOrNull,
  InitialFilingValuesParameters,
} from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { RegulatoryFilings_UsdtnFilingPartsFragment } from '../graphql';

/**
 * Types
 */

export type USDTNFilingFormValues = CommonFields & {
  transactions: TransactionFormValues[];
};

export type TransactionFormValues = {
  executionDateTimeField: {
    date: string;
    timezone: string;
  } | null;
  shares: number | null;
  executionPrice: number | null;
  executionPriceCurrency: TradeCurrencyType;
  indicator: BuySellType | null;
  counterPartyFirmName: string | null;
  counterPartyFirmCrdNumber: string | null;
};

export const buySellOptions: Option<BuySellType>[] = [
  {
    label: 'Buy',
    value: BuySellType.Buy,
  },
  {
    label: 'Sell',
    value: BuySellType.Sell,
  },
];

/**
 * Form Schema
 */

const transactionSchema = Yup.object().shape({
  executionDateTimeField: dateTimeSchema.label('Execution Date and Time').required(),
  shares: Yup.number().label('Number of Shares').positive().nullable().required(),
  executionPrice: Yup.number().label('Execution Price').positive().nullable().required(),
  executionPriceCurrency: Yup.string().label('Execution Price Currency').nullable().required(),
  indicator: Yup.string().label('Buy/Sell Indicator').nullable().required(),
  counterPartyFirmName: Yup.string().label('Counter Party Firm Name').max(1000),
  counterPartyFirmCrdNumber: firmCrdSchema.label('Counter Party Firm CRD'),
});

export const formSchema = Yup.object().shape({
  ...commonFilingSchemaShape,
  transactions: Yup.array().of(transactionSchema).min(1),
});

/**
 * Filing => Form
 */

const mapTransactionsValues = (
  input: RegulatoryFilings_UsdtnFilingPartsFragment['transactions']
): USDTNFilingFormValues['transactions'] => {
  return input.map(item => ({
    indicator: item.indicator,
    counterPartyFirmCrdNumber: item.counterPartyFirmCrdNumber ?? '',
    counterPartyFirmName: item.counterPartyFirmName ?? '',
    executionDateTimeField: {
      date: item.executionDatetime,
      timezone: item.executionTimezone,
    },
    executionPrice: item.executionPrice,
    executionPriceCurrency: item.executionPriceCurrency,
    shares: item.shares,
  }));
};

export const mapFilingToFormValues = (
  values: RegulatoryFilings_UsdtnFilingPartsFragment
): USDTNFilingFormValues => ({
  contactName: values.contactName,
  contactTitle: values.contactTitle,
  emailAddress: values.emailAddress,
  firmCrdNumber: values.firmCrdNumber,
  firmMpid: values.firmMpid,
  firmName: values.firmName,
  telephoneNumber: values.telephoneNumber,
  transactions: mapTransactionsValues(values.transactions),
  issuerSymbol: values.issuerSymbol,
  issuerName: values.issuerName,
});

/**
 * Form => Filing
 */

const mapTransactionsProps = (input: USDTNFilingFormValues['transactions']): TransactionInput[] => {
  return input.map(item => ({
    indicator: item.indicator!,
    counterPartyFirmCrdNumber: getValueOrNull(item.counterPartyFirmCrdNumber),
    counterPartyFirmName: getValueOrNull(item.counterPartyFirmName),
    executionDatetime: item.executionDateTimeField?.date!,
    executionTimezone: item.executionDateTimeField?.timezone!,
    executionPrice: item.executionPrice!,
    executionPriceCurrency: item.executionPriceCurrency,
    shares: item.shares!,
  }));
};

export const mapFormValuesToFiling = (
  values: USDTNFilingFormValues,
  recipients: string[],
  shouldAddSenderToBcc: boolean
): CreateUsdtnFilingInput => ({
  recipients,
  filingFormData: {
    contactName: values.contactName,
    contactTitle: values.contactTitle,
    emailAddress: values.emailAddress,
    firmCrdNumber: values.firmCrdNumber,
    firmMpid: values.firmMpid,
    firmName: values.firmName,
    telephoneNumber: values.telephoneNumber,
    transactions: mapTransactionsProps(values.transactions),
    issuerSymbol: values.issuerSymbol!,
  },
  submissionTimeZone: generatePayloadDateTimeData().sendDateTimeZone,
  shouldAddSenderToBcc,
});

/**
 * Initial values
 */

export function createTransaction(): TransactionFormValues {
  return {
    shares: null,
    indicator: null,
    counterPartyFirmName: '',
    counterPartyFirmCrdNumber: '',
    executionDateTimeField: null,
    executionPrice: null,
    executionPriceCurrency: TradeCurrencyType.Usd,
  };
}

export function createInitialValues({
  filing,
  underwriter,
  lastCommonValues,
  offeringDetail,
}: InitialFilingValuesParameters<RegulatoryFilings_UsdtnFilingPartsFragment> = {}): USDTNFilingFormValues {
  if (filing) {
    return mapFilingToFormValues(filing);
  }
  return {
    ...createInitialCommonFilingValues({ underwriter, lastCommonValues, offeringDetail }),
    transactions: [createTransaction()],
  };
}
