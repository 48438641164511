import { Icon, Popover, theme, UUID } from '@cmg/common';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { LoadingIndicator } from '../../../../../../../common/components/indicators/loading-indicator/LoadingIndicator';
import ListItemIcon from '../../../../../../../common/components/lists/list/ListItemIcon';
import ListItemSecondaryAction from '../../../../../../../common/components/lists/list/ListItemSecondaryAction';
import ListItemText from '../../../../../../../common/components/lists/list/ListItemText';
import NavItem from '../../../../../../../common/components/navigation/side-navigation/NavItem';
import { Wire } from '../../../../SyndicateWiresRoute.model';
import { WireStatus } from '../../wire-status/WireStatus';
import { SingleWireNavigationItem } from '../single-wire-navigation-item/SingleWireNavigationItem';
import { RouteConfigType } from '../syndicate-wires-side-nav/SyndicateWiresSideNav.model';
import { useRedirectToFirstWire } from './hooks/useRedirectToFirstWire';
import { useWireTypeStatus } from './hooks/useWireTypeStatus';
import { useWireVersions } from './hooks/useWireVersions';

export type Props = {
  offeringId: UUID;
  wireTypeName: string;
  wireTypeRoute: RouteConfigType;
  wireInfo: { wire: Wire | null; isLoading: boolean; isError: boolean; isNonSyndicate: boolean };
};

export const WireTypeNavigationItem: React.FC<Props> = ({
  wireTypeName,
  wireTypeRoute,
  wireInfo,
  offeringId,
}) => {
  const { wire, isLoading, isError, isNonSyndicate } = wireInfo;
  const { pathname } = useLocation();

  const { params } =
    matchPath<{ syndicateWireId?: UUID }>(pathname, {
      path: wireTypeRoute.routePath,
      exact: false,
      strict: false,
    }) ?? {};

  const isWireTypeActive = !!params;
  const syndicateWireId = params?.syndicateWireId;

  const wireTypeStatus = useWireTypeStatus(wire);
  const { versions } = useWireVersions({
    offeringId,
    templateType: wire?.templateType,
    isNonSyndicate,
    isWireTypeActive,
  });

  useRedirectToFirstWire({
    isWireTypeActive,
    offeringId,
    syndicateWireId,
    firstWireId: wire?.id,
    wireTypeRoute,
  });

  const nestedItems = React.useMemo(() => {
    return versions.map((wire, index) => {
      const versionNumber = versions.length - index;
      return (
        <SingleWireNavigationItem
          key={wire.id}
          createdAt={wire.createdAt}
          name={`Version ${versionNumber}`}
          url={wireTypeRoute.getUrlPath({ offeringId, syndicateWireId: wire.id })}
          isActive={wire.id === syndicateWireId}
          testId={`wire-type-navigation-item-version-${versionNumber}`}
        />
      );
    });
  }, [versions, offeringId, wireTypeRoute, syndicateWireId]);

  return (
    <NavItem
      nestedItems={isWireTypeActive ? nestedItems : undefined}
      url={wireTypeRoute.getUrlPath({ offeringId })}
    >
      <ListItemIcon>
        <WireStatus status={wireTypeStatus} isActive={isWireTypeActive} />
      </ListItemIcon>
      <ListItemText>{wireTypeName}</ListItemText>
      {isLoading && (
        <ListItemSecondaryAction>
          <LoadingIndicator size="small" />
        </ListItemSecondaryAction>
      )}
      {isError && isWireTypeActive && (
        <ListItemSecondaryAction>
          <Popover
            content={`There was an error while fetching a list of ${wireTypeName} wires.`}
            trigger="hover"
            variant="ERROR"
          >
            <div>
              <Icon
                name="exclamation-circle"
                color={theme.designSystem.colors.gray['000']}
                variant="solid"
              />
            </div>
          </Popover>
        </ListItemSecondaryAction>
      )}
    </NavItem>
  );
};
