import { numericUtil } from '@cmg/common';
import { Typography } from '@cmg/design-system';
import React from 'react';

import type { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const AllocationChangeContent: React.FC<Props> = ({ update }) => {
  const { investorFirm, newAllocationSet, allocationRevisions } = update;
  const [lastRevision] = allocationRevisions ?? [];

  return (
    <Typography aria-label="Recent update - Allocation changed" component="span">
      <Typography component="span" variant="highlight1">
        {investorFirm}
      </Typography>{' '}
      {newAllocationSet?.isDraft ? 'draft' : 'final'} allocation changed from{' '}
      <Typography component="span" variant="highlight1">
        {numericUtil.getDisplayValueForInteger(lastRevision?.old?.shares)} Shares
      </Typography>{' '}
      to{' '}
      <Typography component="span" variant="highlight1">
        {numericUtil.getDisplayValueForInteger(lastRevision?.new?.shares)} Shares
      </Typography>
      .
    </Typography>
  );
};
