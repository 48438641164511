import React from 'react';

import {
  CertificateModel,
  certificateModelDefaults,
  useComplianceCertificatesModel,
} from './hooks/certificates/useCertificatesModel';
import { ProspectusDeliveryModel } from './hooks/prospectus-delivery/ProspectusDelivery.model';
import {
  prospectusDeliveryModelDefaults,
  useProspectusDeliveryModel,
} from './hooks/prospectus-delivery/useProspectusDeliveryModel';

export type ComplianceModel = {
  certificates: CertificateModel;
  prospectusDelivery: ProspectusDeliveryModel;
};

export const defaultComplianceModel: ComplianceModel = {
  certificates: certificateModelDefaults,
  prospectusDelivery: prospectusDeliveryModelDefaults,
};

/*
The ComplianceContext is in charge of handling all the compliance data, and also helps us have a 
centralized place that we can use to decide what actions we can do with the section.

Here we will detail of the different areas that compliance will have, for example certificates.
*/
const ComplianceContext = React.createContext<ComplianceModel>(defaultComplianceModel);

export const useComplianceContext = (): ComplianceModel => {
  const context = React.useContext<ComplianceModel>(ComplianceContext);
  if (context === undefined) {
    throw new Error('No Provider found for ComplianceContext');
  }
  return context;
};

type ProviderProps = {
  offeringId: string;
  cmgEntityKey: string | undefined;
};

export const ComplianceContextProvider: React.FC<ProviderProps> = ({
  offeringId,
  cmgEntityKey,
  children,
}) => {
  const certificates = useComplianceCertificatesModel({ offeringId, cmgEntityKey });
  const prospectusDelivery = useProspectusDeliveryModel({ offeringId, cmgEntityKey });

  return (
    <ComplianceContext.Provider value={{ certificates, prospectusDelivery }}>
      {children}
    </ComplianceContext.Provider>
  );
};
