import * as yup from 'yup';

import { wiresFormDisclaimerSchema } from '../../common/components/disclaimer/Disclaimer.model';

export const validationSchema = yup
  .object({
    // The GraphQL type definitions say headerNote can be null, which is treated as undefined
    // by yup unless we specify .default(null).
    headerNote: yup.string().label('Header Note').nullable().default(null),
  })
  .concat(wiresFormDisclaimerSchema);

export type Values = {
  headerNote: string | null;
  disclaimer: string | null;
};
