import { ApolloError } from '@apollo/client';
import React from 'react';

import usePrevious from '../../../../../common/util/usePrevious';

export function useOnFormDataLoaded({
  onFormDataLoaded,
  loading,
  error,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  onFormDataLoaded?: (isSuccess: boolean) => void;
}) {
  const previousLoading = usePrevious(loading);
  React.useEffect(() => {
    const hasQueryLoadingFinished = !loading && (previousLoading === undefined || previousLoading);
    if (!hasQueryLoadingFinished) {
      return;
    }

    const isSuccess = !error;
    onFormDataLoaded?.(isSuccess);
  }, [previousLoading, loading, error, onFormDataLoaded]);
}
