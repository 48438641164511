import { Icon } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SCard = styled.div`
  flex: 1;
  padding: 0 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const SContent = styled.div<{ withBorder?: boolean; withFill?: boolean }>`
  border-radius: ${({ theme }) => theme.border.radius.large};
  flex: 1;

  ${({ withBorder }) =>
    withBorder &&
    css`
      border: ${({ theme }) => theme.border.smallSolidLight};
      padding: 20px;
    `}
  ${({ withFill }) =>
    withFill &&
    css`
      background-color: ${({ theme }) => theme.background.color.light};
      padding: 20px;
    `}
`;

export const SError = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.text.color.error};
`;

export const SErrors = styled.ul`
  margin-left: 15px;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.text.color.error};
  margin-right: 5px;
`;
