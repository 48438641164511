import { getOperationName } from '@apollo/client/utilities';
import { SnackbarManager } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../../graphql';
import SaveAllocationSetDialog, {
  Props as AllocationSetDialogProps,
} from '../SaveAllocationSetDialog';
import { getDealLayoutInput } from '../SaveAllocationSetDialog.model';
import { useOrderBook_CreateDraftAllocationSetMutation } from './graphql';

const emptyAllocationSetDealLayout = { offeringSize: null, institutionalTarget: null };

export type Props = Pick<AllocationSetDialogProps, 'isOpen' | 'onClose' | 'offeringId'> & {
  refetchQueries?: string[];
};

const CreateDraftAllocationSetDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  offeringId,
  refetchQueries,
}) => {
  const [createDraft] = useOrderBook_CreateDraftAllocationSetMutation({
    refetchQueries: [
      getOperationName(OrderBook_OrderBookRouteDocument)!,
      ...(refetchQueries ?? []),
    ],
  });

  const handleSubmit = React.useCallback<AllocationSetDialogProps['onSubmit']>(
    async values => {
      try {
        const { name, offeringSize } = values;

        await createDraft({
          variables: {
            offeringId,
            payload: { name, dealLayout: isNil(offeringSize) ? null : getDealLayoutInput(values) },
          },
        });

        onClose();
        SnackbarManager.success('Successfully created Draft Allocation set');
      } catch {
        SnackbarManager.error('Failed to create Draft Allocation set');
      }
    },
    [createDraft, offeringId, onClose]
  );

  return (
    <SaveAllocationSetDialog
      offeringId={offeringId}
      title="Create Draft Allocation dialog"
      isEdit={false}
      isOpen={isOpen}
      onClose={onClose}
      canSetName
      name={null}
      dealLayout={emptyAllocationSetDealLayout}
      onSubmit={handleSubmit}
    />
  );
};
export default CreateDraftAllocationSetDialog;
