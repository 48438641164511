import { FlexContainer } from '@cmg/common';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import BaseDealSharesInfoBox from './base-deal-shares-info-box/BaseDealSharesInfoBox';
import RemainingSharesInfoBox from './remaining-shares-info-box/RemainingSharesInfoBox';
import RetailRetentionInfoBox from './retail-retention-info-box/RetailRetentionInfoBox';
import SharesExcludedInfoBox from './shares-excluded-info-box/SharesExcludedInfoBox';

const DealLayoutPanel: React.FC = () => {
  return (
    <Panel>
      <Panel.Header title="Deal Layout (Shares)" />
      <Panel.Content>
        <FlexContainer direction="column" gap={8}>
          <BaseDealSharesInfoBox />

          <RetailRetentionInfoBox />

          <RemainingSharesInfoBox />

          <SharesExcludedInfoBox />
        </FlexContainer>
      </Panel.Content>
    </Panel>
  );
};

export default DealLayoutPanel;
