import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 80px;
`;

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.large};
  color: ${({ theme }) => theme.text.color.darkGray};
  padding-bottom: 8px;
`;

export const SSubTitle = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
  font-size: ${({ theme }) => theme.text.size.medium};
`;

export const SDetails = styled.div`
  font-size: ${({ theme }) => theme.text.size.xs};
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
`;

export const SImg = styled.img`
  padding-top: 32px;
`;
