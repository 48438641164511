import { Panel, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import RoadshowsSubNavigation from '../common/roadshows-sub-navigation/RoadshowsSubNavigation';

export type Props = {};

const AllRoadshowCompaniesRoute: React.FC<Props> = props => (
  <Panel fillViewport testId="roadshows / overview / all-companies">
    <RoadshowsSubNavigation>
      <SecondaryButton>Create New Company</SecondaryButton>
      <PrimaryButton>Import companies</PrimaryButton>
    </RoadshowsSubNavigation>
  </Panel>
);

export default AllRoadshowCompaniesRoute;
