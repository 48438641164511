import { IconSize } from '@cmg/common';
import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components/macro';

import { SLabel, SStatus, StatusVariant, StyledStatusIcon } from './Status.styles';

type StatusOption = {
  [key: string]: {
    label: string;
    color: string;
  };
};

export type Props = {
  /** options is a function that provides the theme.color to the consumer.
   * options expect and object of [status]: { label: string, color: string } to be returned
   * */
  options: (color: DefaultTheme) => StatusOption;
  /** status matches one of the mapped options */
  status?: string;
  className?: string;
  onClick?: () => void;
  testId?: string;
  size?: IconSize;
  variant?: StatusVariant;
};

/**
 * Status displays a circle icon and label
 *
 * The options function provides theme.color to the consumer to set the color to be used for the circle icon
 * when the matching option
 */
const Status: React.FC<Props> = ({
  testId,
  options,
  status,
  className,
  onClick,
  size = 'sm',
  variant = 'LIGHT',
}) => {
  const theme = useTheme();

  const optionsMap: StatusOption = options(theme);
  const optionKey = Object.keys(optionsMap).find(key => key === status);
  const option = optionKey && optionsMap[optionKey];

  if (!option) {
    return null;
  }

  return (
    <SStatus data-test-id={testId} className={className} onClick={onClick}>
      <StyledStatusIcon
        name="circle"
        variant="solid"
        size={size}
        color={option && option.color}
        fixedWidth
      />
      <SLabel variant={variant}>{option ? option.label : ''}</SLabel>
    </SStatus>
  );
};

export default Status;
