import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_DeleteNonSyndicateFreeFormWireMutation,
  SyndicateWires_NonSyndicateFreeFormWiresDocument,
  useSyndicateWires_DeleteNonSyndicateFreeFormWireMutation,
} from '../graphql';

export type DeleteNonSyndicateFreeFormWireMutation =
  SyndicateWires_DeleteNonSyndicateFreeFormWireMutation;

export const useDeleteNonSyndicateFreeFormWireMutation = () => {
  return useSyndicateWires_DeleteNonSyndicateFreeFormWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateFreeFormWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
