import { SubNavigation } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledSubNavigationLink = styled(SubNavigation.Link)`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
`;

export const StyledHeader = styled.div`
  padding-top: 16px;
  margin-bottom: 2px;
  background: ${({ theme }) => theme.background.color.white};
`;

export const StyledPanel = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledContent = styled.div`
  background: ${({ theme }) => theme.background.color.white};
  padding: 16px;
  flex-grow: 1;
`;
