import React from 'react';

import regMZeroStateImage from '../../../common/assets/reg-m-zero-state.svg';
import { HeroGraphics } from '../../../common/components/layout/hero-graphics/HeroGraphics';
import { SFormContent } from '../RegulatoryFilingsRoute.styles';

const OverviewRoute: React.FC = () => {
  const subtitle = React.useMemo(() => {
    return (
      <div>
        1. Choose a form that is pre-populated with the Offering Details data and enrich as
        necessary.
        <br />
        2. Submit the filing and CMG will ensure FINRA receives it.
        <br />
        3. Once FINRA processes your filing, you will receive an email confirmation.
        <br />
      </div>
    );
  }, []);

  return (
    <SFormContent>
      <HeroGraphics
        image={regMZeroStateImage}
        imageAlt="select wire"
        title="Regulation M filings accomplished in three easy steps: populate, submit, and receive confirmation"
        subtitle={subtitle}
      />
    </SFormContent>
  );
};

export default OverviewRoute;
