import { numericUtil, ODSTable as Table } from '@cmg/common';
import React from 'react';

import { useAccountingContext } from '../../common/accounting-context/AccountingContext';
import AccountingInfoBox from '../../common/accounting-info-box/AccountingInfoBox';
import { AccountingInfoHeader } from '../../common/accounting-info-box/AccountingInfoBox.styles';

const TotalGrossSpreadInfoBox: React.FC = () => {
  const { baseGrossSpreadValue, baseGrossSpreadPercent, incentiveFeeValue, incentiveFeePercent } =
    useAccountingContext();

  return (
    <AccountingInfoBox
      gridTemplateColumns="2fr 10px 1fr 1fr"
      sourceOfData="Sourced from Offering Setup"
    >
      <AccountingInfoHeader colSpan={2} withBottomBorder={false} withRightBorder={false} />
      <AccountingInfoHeader align="right" withBottomBorder={false} withRightBorder={false}>
        $ per share
      </AccountingInfoHeader>
      <AccountingInfoHeader align="right" withBottomBorder={false} withRightBorder={false}>
        % per share
      </AccountingInfoHeader>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false}>
        Base Gross Spread
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForCurrency(baseGrossSpreadValue, 6)}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false}>
        {numericUtil.getDisplayValueForPercents(baseGrossSpreadPercent, 4)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2}>
        Incentive Fee
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(incentiveFeeValue, 6)}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForPercents(incentiveFeePercent, 4)}
      </Table.Cell>

      <Table.Cell paddingSize="S" colSpan={2} withBottomBorder={false} bold>
        Total Gross Spread
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForCurrency(
          numericUtil.sum(baseGrossSpreadValue, incentiveFeeValue),
          6
        )}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right" withBottomBorder={false} bold>
        {numericUtil.getDisplayValueForPercents(
          numericUtil.sum(baseGrossSpreadPercent, incentiveFeePercent),
          4
        )}
      </Table.Cell>
    </AccountingInfoBox>
  );
};

export default TotalGrossSpreadInfoBox;
