import { ErrorIcon, Stack, Tooltip, WarningIcon } from '@cmg/design-system';
import React from 'react';

export type Props = Readonly<{ variant: 'error' | 'warning' }>;

const GridWarningTooltip: React.FC<Props> = ({ variant, children }) => {
  return (
    <Tooltip placement="top-start" variant={variant} title={children}>
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        {variant === 'error' ? (
          <ErrorIcon color="error" fontSize="small" />
        ) : (
          <WarningIcon color="warning" fontSize="small" />
        )}
      </Stack>
    </Tooltip>
  );
};

export default GridWarningTooltip;
