import { Checkbox, Select, TextInput } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 0;
`;

export const SSearchIconWrapper = styled.div`
  margin-left: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 300px;
`;

export const StyledSelect = styled(Select)`
  min-width: 300px;
`;

export const StyledErrorsCheckbox = styled(Checkbox)`
  margin-right: auto;
`;
