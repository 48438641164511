import includes from 'lodash/includes';

import useCheckCoveredInstitutionalIndicationAccess from '../../../../common/util/check-access/useCheckCoveredInstitutionalIndicationAccess';
import { isValidCmgEntityKey } from '../../../../common/util/isValidCmgEntityKey';
import {
  useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery,
  useIndicationActivity_IndicationsInvestorsQuery,
  useIndicationActivity_InvestorFirmByCmgEntityKeyQuery,
} from './graphql/__generated__';

export const useIndicationCoverage = (
  offeringId: string,
  cmgEntityKey: string | undefined,
  duplicateIndicationIds: readonly string[]
) => {
  const { canRead: canReadCoveredIndication } = useCheckCoveredInstitutionalIndicationAccess();

  const { loading: investorFirmByCmgEntityKeyLoading, data: investorFirmByCmgEntityKeyData } =
    useIndicationActivity_InvestorFirmByCmgEntityKeyQuery({
      variables: {
        cmgEntityKey: cmgEntityKey!,
      },
      skip: !isValidCmgEntityKey(cmgEntityKey) || canReadCoveredIndication,
    });

  const { loading: indicationInvestorsLoading, data: indicationInvestors } =
    useIndicationActivity_IndicationsInvestorsQuery({
      variables: {
        offeringId,
      },
      skip: !cmgEntityKey || duplicateIndicationIds.length === 0,
    });

  const indications =
    indicationInvestors?.basicPublishedOffering?.orderBook?.institutionalIndications ?? [];
  const duplicateCmgEntityKeys: string[] = indications
    .filter(i => includes(duplicateIndicationIds, i.id))
    .map(d => d.investorInformation.cmgEntityKey)
    .filter((x): x is string => x !== null);

  const {
    loading: firmRelationshipsByCmgEntityKeysLoading,
    data: firmRelationshipsByCmgEntityKeys,
  } = useIndicationActivity_FirmRelationshipsByCmgEntityKeysQuery({
    variables: { cmgEntityKeys: duplicateCmgEntityKeys },
    skip: !cmgEntityKey || duplicateCmgEntityKeys.length === 0,
  });

  const currentFirmCrmData = investorFirmByCmgEntityKeyData?.investorFirm;
  const duplicatesFirmsCrmData = firmRelationshipsByCmgEntityKeys?.firmInvestorsRelationships ?? [];
  return {
    duplicates: duplicateCmgEntityKeys,
    currentFirmCrmData: currentFirmCrmData,
    duplicatesFirmsCrmData: duplicatesFirmsCrmData,
    isCurrentInvestorCovered: !!currentFirmCrmData || duplicatesFirmsCrmData.length > 0,
    loading:
      firmRelationshipsByCmgEntityKeysLoading ||
      indicationInvestorsLoading ||
      investorFirmByCmgEntityKeyLoading,
  };
};
