/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_DealTeamListQueryVariables = Types.Exact<{
  pagination: Types.PaginationDataInput;
}>;

export type Roadshows_DealTeamListQuery = {
  readonly __typename?: 'Query';
  readonly dealTeams: {
    readonly __typename?: 'DealTeamLimitedPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'DealTeamLimited';
      readonly id: string;
      readonly firmName: string;
      readonly membersCount: number;
    }>;
  };
};

export const Roadshows_DealTeamListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_DealTeamList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'membersCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_DealTeamListQuery__
 *
 * To run a query within a React component, call `useRoadshows_DealTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_DealTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_DealTeamListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRoadshows_DealTeamListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_DealTeamListQuery,
    Roadshows_DealTeamListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_DealTeamListQuery, Roadshows_DealTeamListQueryVariables>(
    Roadshows_DealTeamListDocument,
    options
  );
}
export function useRoadshows_DealTeamListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_DealTeamListQuery,
    Roadshows_DealTeamListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_DealTeamListQuery, Roadshows_DealTeamListQueryVariables>(
    Roadshows_DealTeamListDocument,
    options
  );
}
export type Roadshows_DealTeamListQueryHookResult = ReturnType<
  typeof useRoadshows_DealTeamListQuery
>;
export type Roadshows_DealTeamListLazyQueryHookResult = ReturnType<
  typeof useRoadshows_DealTeamListLazyQuery
>;
export type Roadshows_DealTeamListQueryResult = Apollo.QueryResult<
  Roadshows_DealTeamListQuery,
  Roadshows_DealTeamListQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRoadshows_DealTeamListMockResponse = makeMockResponse<
  Roadshows_DealTeamListQueryVariables,
  Roadshows_DealTeamListQuery
>(Roadshows_DealTeamListDocument);
