import { Alert, LinkButton, Popover, PrimaryButton, ToastManager, UUID } from '@cmg/common';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import { OfferingStage } from '../../../graphql';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import {
  useOfferingSetup_Prospectus_ProspectusDocumentsQuery,
  useOfferingSetup_Prospectus_PublishedOfferingVersionQuery,
  useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation,
} from '../graphql';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import ProspectusForm from './components/form/ProspectusForm';
import { getCurrentDocumentPath } from './components/form/ProspectusForm.model';
import { useProspectusDocumentsRestUpload } from './hooks/useProspectusDocumentsRestUpload';
import {
  emptyInitialValues,
  getDocumentsForSubmission,
  ProspectusFormValues,
  prospectusValidationSchema,
} from './ProspectusRoute.model';

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

export const ProspectusRoute: React.FC<RouteProps> = ({
  match: {
    params: { offeringId },
  },
}) => {
  const { revalidate } = useValidateOffering(offeringId);

  const {
    data,
    loading: isProspectusDocumentQueryLoading,
    error,
    refetch,
  } = useOfferingSetup_Prospectus_ProspectusDocumentsQuery({
    variables: { offeringId },
  });

  const { data: publishedOfferingVersion, loading: isPublishedOfferingVersionLoading } =
    useOfferingSetup_Prospectus_PublishedOfferingVersionQuery({
      variables: { offeringId },
      skip: data?.offering.stage !== OfferingStage.Published,
    });

  const isLoading = isProspectusDocumentQueryLoading || isPublishedOfferingVersionLoading;

  const publishedVersionInfo = data?.offering.versions.find(
    version => version.version === publishedOfferingVersion?.publishedOffering.version
  );

  const [initialValues, setInitialValues] = React.useState(emptyInitialValues);

  const [
    updateProspectusDocumentsMetadataMutation,
    {
      error: uploadProspectusDocumentMetadataError,
      loading: uploadProspectusDocumentMetadataLoading,
    },
  ] = useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation();

  const {
    uploadMultipleProspectusDocumentsRest,
    updateProspectusDocumentsWithMetadataRest,
    loading: isUploadProspectusDocumentRestLoading,
  } = useProspectusDocumentsRestUpload();

  React.useEffect(() => {
    setInitialValues({
      prospectusDocuments:
        data?.offering.prospectusDocuments?.map(document => ({ ...document, file: null })) ??
        emptyInitialValues.prospectusDocuments,
    });
  }, [data]);

  const formik = useFormik<ProspectusFormValues>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async values => {
      const {
        uploadProspectusDocuments,
        updateProspectusDocumentsMetadata,
        updateProspectusDocuments,
      } = getDocumentsForSubmission({ values, initialValues });
      try {
        if (uploadProspectusDocuments.length) {
          await uploadMultipleProspectusDocumentsRest({
            prospectusDocuments: uploadProspectusDocuments,
            offeringId,
          });
        }
        if (updateProspectusDocumentsMetadata.length) {
          await updateProspectusDocumentsMetadataMutation({
            variables: { prospectusDocuments: updateProspectusDocumentsMetadata, offeringId },
          });
        }
        if (updateProspectusDocuments.length) {
          await updateProspectusDocumentsWithMetadataRest({
            prospectusDocuments: updateProspectusDocuments,
            offeringId,
          });
        }

        ToastManager.success('Updated successfully');
        refetch();
        revalidate();
      } catch (error) {
        ToastManager.error('Failed to upload prospectus documents');
        throw new Error('Failed to upload prospectus documents');
      }
    },
    validationSchema: prospectusValidationSchema,
  });

  const handleRemoveFile = (index: number, remove: (index: number) => void) => {
    const documentToRemove = formik.values.prospectusDocuments?.[index];

    if (documentToRemove.file && documentToRemove.id) {
      const originalDocument = data?.offering.prospectusDocuments.find(
        prospectusDocument => prospectusDocument.id === documentToRemove.id
      );

      formik.setFieldValue(getCurrentDocumentPath(index), { ...originalDocument, file: null });
      formik.setFieldTouched(getCurrentDocumentPath(index), false);
    } else {
      remove(index);
    }
  };

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard>
        <OfferingSetupPage offeringId={offeringId} negativeMargin>
          <SetupScreen.Panel fillViewport gap={16}>
            <SetupScreen.Header
              withoutMargin
              rightContent={
                <Popover variant="TOOLTIP" placement="topLeft" trigger="hover">
                  <div>
                    <PrimaryButton
                      disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                      onClick={() => formik.handleSubmit()}
                      loading={
                        uploadProspectusDocumentMetadataLoading ||
                        isUploadProspectusDocumentRestLoading
                      }
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Popover>
              }
            />

            {error && (
              <Alert
                severity="error"
                action={() => <LinkButton onClick={() => refetch()}>Retry</LinkButton>}
              >
                An error has occurred while loading the Prospectus Documents.
              </Alert>
            )}
            {uploadProspectusDocumentMetadataError && (
              <ServerErrorsBanner error={uploadProspectusDocumentMetadataError} />
            )}

            <FormikProvider value={formik}>
              <FieldArray name="prospectusDocuments">
                {({ push, remove, form }) => (
                  <ProspectusForm
                    savedDocumentsLength={data?.offering.prospectusDocuments.length ?? 0}
                    onAddFile={value => {
                      push(value);
                      // The form doesn't become dirty on the first added file unless we use the following hack
                      form.setFieldTouched('prospectusDocuments.0.documentName');
                    }}
                    onEditFile={newDoc => {
                      const docIndex = form.values.prospectusDocuments.findIndex(
                        document => document.id === newDoc.id
                      );
                      const docPath = `prospectusDocuments.${docIndex}`;

                      form.setFieldValue(docPath, newDoc);
                      form.setFieldTouched(docPath);
                    }}
                    onRemoveFile={index => handleRemoveFile(index, remove)}
                    documents={formik.values.prospectusDocuments}
                    isLoading={isLoading}
                    versionInfo={publishedVersionInfo}
                  />
                )}
              </FieldArray>
            </FormikProvider>
          </SetupScreen.Panel>
        </OfferingSetupPage>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};
