import { DataGrid } from '@cmg/common';
import { Box, BoxProps, styled } from '@cmg/design-system';
import { GridReadyEvent, IDatasource } from 'ag-grid-community';
import React from 'react';

import { CertificateLibrary_FetchCertificates_FirmCertificatesFragment } from '../../graphql/__generated__';
import { useCertificateGridColDefs } from './certificate-grid.model';

type Props = {
  datasource: IDatasource;
  onGridReady: (ref: GridReadyEvent) => void;
} & BoxProps;

// The no rows overlay template
const OverlayNoRowsTemplate = `
  <div class="no-rows-template-overlay">
    <div>
      <div class="no-rows-template-overlay-title">
      There Were No Results Matching Your Criteria
      </div>
      <div class="no-rows-template-overlay-subtitle">
      Modify filters or search criteria to view more results
      </div>
    </div>
  </div>
`;

// Styles for the headers borders and the no rows overlay
const StyledGrid = styled(
  DataGrid<Readonly<CertificateLibrary_FetchCertificates_FirmCertificatesFragment>>
)`
  --ag-header-column-separator-color: ${props => props.theme.palette.grey[300]};

  .ag-pinned-left-header {
    border-right: ${props =>
      `${props.theme.spacing(0.125)} solid ${props.theme.palette.grey[300]}`};
  }

  .ag-header {
    border-bottom: ${props =>
      `${props.theme.spacing(0.125)} solid ${props.theme.palette.grey[300]}`};
  }

  .ag-theme-balham .ag-header-container .ag-header-cell-text,
  .ag-theme-balham .ag-header-row .ag-header-cell-text {
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    font-family: ${props => props.theme.typography.fontFamily};
    color: ${props => props.theme.palette.text.secondary};
  }

  .ag-root-wrapper {
    border-top: ${props => `${props.theme.spacing(0.125)} solid ${props.theme.palette.grey[300]}`};
  }

  // https://www.ag-grid.com/react-data-grid/upgrading-to-ag-grid-31-1/
  .ag-react-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .ag-root {
    padding-left: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(4)};
  }

  .ag-overlay-no-rows-wrapper {
    align-items: start;
    margin-top: ${props => props.theme.spacing(12.5)};
  }

  .ag-overlay-loading-wrapper {
    background-color: ${props => props.theme.palette.background.paper};
    margin-top: ${props => props.theme.spacing(4.125)};
  }

  .no-rows-template-overlay-title {
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.h3.fontSize};
    font-weight: ${props => props.theme.typography.h3.fontWeight};
    line-height: ${props => props.theme.typography.h3.lineHeight};
    letter-spacing: ${props => props.theme.typography.h3.letterSpacing};
    color: ${props => props.theme.typography.h3.color};
  }

  .no-rows-template-overlay-subtitle {
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.body1.fontSize};
    font-weight: ${props => props.theme.typography.body1.fontWeight};
    line-height: ${props => props.theme.typography.body1.lineHeight};
    letter-spacing: ${props => props.theme.typography.body1.letterSpacing};
    color: ${props => props.theme.palette.text.secondary};
    margin-top: ${props => props.theme.spacing(0.5)};
  }
`;

const CertificateGrid: React.FC<Props> = ({ datasource, onGridReady, ...rest }) => {
  const columns = useCertificateGridColDefs();

  return (
    <Box {...rest}>
      <StyledGrid
        columns={columns}
        onGridReady={onGridReady}
        gridOptions={{
          suppressColumnVirtualisation: true,
          suppressCellSelection: true,
          suppressMovableColumns: true,
          rowHeight: 50,
        }}
        overlayNoRowsTemplate={OverlayNoRowsTemplate}
        extended={{
          withMargin: false,
          hideColumnResize: true,
          hideColumnSelector: true,
          hidePagination: true,
          hideHeader: true,
          fillViewport: true,
        }}
        datasource={datasource}
        infiniteScroll
        getRowNodeId={data => data.firmCmgEntityKey || ''}
        domLayout="normal"
        resizeStrategy="fit-screen"
      />
    </Box>
  );
};

export default CertificateGrid;
