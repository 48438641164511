export const serializeFirmKey = (investor: { firmKey?: string | null }) => {
  return investor.firmKey ? `firmKey:${investor.firmKey}` : null;
};

// parseFirmKey is meant for cases the cmgEntityKey query param contains serialized firmKey instead
// of regular cmgEntityKey (can happen with investors not linked to a cmg entity; as this is quite
// rare edge case it doesn't make sense to have a separate field for that)
export const parseFirmKey = (input: string | null | undefined) => {
  return input?.startsWith('firmKey:') ? input.replace('firmKey:', '') : null;
};
