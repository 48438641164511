import {
  DatePickerField,
  ISODate,
  ODSTimePickerField as TimePickerField,
  SelectField,
  TextAreaField,
  TextInputField,
  UnsavedChangesGuard,
} from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import Column from '../../../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../../../common/components/layout/grid-layout/GridLayout';
import {
  Roadshows_LogisticsPartsFragment,
  Roadshows_RoadshowPartsFragment,
} from '../../../../../common/graphql';
import { EventInfo } from '../../../common/event-header-info/EventHeaderInfo.model';
import EventFormSubHeader from '../../../panel-meetings-event/common/event-form-subheader/EventFormSubHeader';
import { getLocationOptions } from '../../../panel-meetings-event/common/meeting-details-form/MeetingDetailsForm.model';
import LogisticsButtonGroup from '../logistics-button-group/LogisticsButtonGroup';
import {
  getLogisticsInitialValues,
  getValidationSchema,
  LogisticsValues,
} from './LogisticsDetailsForm.model';

export type Props = {
  roadshow: Roadshows_RoadshowPartsFragment;
  logistics?: Roadshows_LogisticsPartsFragment;
  selectedDate?: string;
  onSubmit: (payload: LogisticsValues) => void;
  isSaving: boolean;
  logisticsHeaderInfo: EventInfo | null;
  onDelete?: () => void;
  isDeleting?: boolean;
};

const LogisticsDetailsForm: React.FC<Props> = ({
  logistics,
  selectedDate,
  roadshow,
  isSaving,
  onSubmit,
  logisticsHeaderInfo,
  onDelete,
  isDeleting,
}) => {
  const { roadshowDays, startDate, endDate } = roadshow;

  const formik = useFormik<LogisticsValues>({
    validationSchema: getValidationSchema(roadshowDays),
    initialValues: getLogisticsInitialValues({
      logistics,
      selectedDate,
      roadshowDays: roadshowDays,
    }),
    onSubmit,
    initialTouched: { startDate: true },
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const { dirty, isSubmitting, resetForm, values, setFieldValue, setFieldTouched } = formik;

  const locationOptions = getLocationOptions(values.startDate, roadshowDays);

  const handleDateChange = (nextDate: ISODate | null) => {
    const dateLocations = getLocationOptions(nextDate, roadshowDays);
    const dateLocationValues = dateLocations.map(({ value }) => value);

    // reset location when selected location is not in desired date
    if (values.location && !dateLocationValues.includes(values.location)) {
      setFieldValue('location', null);
    }

    // auto select first option if no location is selected
    if (!values.location && dateLocationValues.length > 0) {
      const [firstLocation] = dateLocationValues;

      setFieldValue('location', firstLocation);
    }
  };

  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty && !isSubmitting} onLeave={resetForm}>
        <LogisticsButtonGroup
          isNewLogistics={!logistics}
          isSaving={isSaving}
          logisticsHeaderInfo={logisticsHeaderInfo}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />

        <Form data-test-id="logistics form">
          <EventFormSubHeader title="Logistics Details">
            <TextInputField name="title" label="Logistics Title" fullWidth withMargin required />

            <GridLayout>
              <Column span={4}>
                <DatePickerField
                  name="startDate"
                  label="Start Date"
                  onChange={handleDateChange}
                  minDate={startDate}
                  maxDate={endDate}
                  onCalendarClose={() => {
                    setFieldTouched('startDate');
                  }}
                  fullWidth
                  withMargin
                  required
                />
              </Column>
              <Column span={4}>
                <TimePickerField
                  name="startTime"
                  label="Start Time"
                  fullWidth
                  withMargin
                  required
                />
              </Column>
              <Column span={4}>
                <SelectField
                  name="location"
                  label="Location"
                  options={locationOptions}
                  disabled={!values.startDate || locationOptions.length === 0}
                  isClearable={false}
                  fullWidth
                  withMargin
                  required
                />
              </Column>
            </GridLayout>

            <GridLayout>
              <Column span={4}>
                <DatePickerField
                  name="endDate"
                  label="End Date"
                  minDate={startDate}
                  maxDate={endDate}
                  onCalendarClose={() => {
                    setFieldTouched('endDate');
                  }}
                  fullWidth
                  withMargin
                  required
                />
              </Column>
              <Column span={4}>
                <TimePickerField name="endTime" label="End Time" fullWidth withMargin required />
              </Column>
            </GridLayout>

            <TextAreaField name="notes" label="Notes" fullWidth withMargin />
          </EventFormSubHeader>
        </Form>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default LogisticsDetailsForm;
