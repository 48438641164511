import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector } from '../columns.model';
import SalesCoverageCellRenderer from './SalesCoverageCellRenderer';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['salesCoverageDisplayValue'];

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: { component: SalesCoverageCellRenderer },
});

const salesCoverageColDef: DemandGridServerColDef<Value> = {
  field: 'salesCoverageDisplayValue',
  headerName: 'Sales Coverage',
  cellRendererSelector,
};

export default salesCoverageColDef;
