import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
  SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
} from '../graphql';

export type CreateNonSyndicateSellingGroupInvitationWireMutation =
  SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation;

export const useCreateNonSyndicateSellingGroupInvitationWireMutation = () => {
  return useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
