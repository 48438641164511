import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../graphql';
import { Calendar_LiveCalendarOfferingsListDocument } from '../../../../../../../../calendar/live-calendar/graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { useManageIndication_UpdateIndicationStatusMutation } from '../../../../../../indication-activity/components/manage-indication/graphql/__generated__';

export const refetchQueries = [
  getOperationName(InstitutionalDemandGrid_SummaryDocument)!,
  getOperationName(Calendar_LiveCalendarOfferingsListDocument)!,
];

export const useUpdateIndicationStatus = (
  offeringId: UUID,
  indicationId: UUID,
  successCallback?: () => void
): [(status: IndicationStatus) => Promise<void>, boolean] => {
  const [updateStatus, { loading: isUpdatingStatus }] =
    useManageIndication_UpdateIndicationStatusMutation();

  const handleUpdateStatus = React.useCallback(
    async (status: IndicationStatus) => {
      try {
        await updateStatus({
          variables: { offeringId, indicationId, status },
          awaitRefetchQueries: true,
          refetchQueries,
        });

        SnackbarManager.success('Successfully updated indication status');
        successCallback?.();
        pendingOrderBookGridForcedRefreshVar(true);
      } catch {
        SnackbarManager.error('Failed to update indication status');
      }
    },
    [indicationId, offeringId, successCallback, updateStatus]
  );

  return [handleUpdateStatus, isUpdatingStatus];
};
