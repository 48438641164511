import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
`;

export const SHeading = styled.h1`
  font-size: ${({ theme }) => theme.text.size.medium};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const SItem = styled.div`
  margin-top: 16px;
`;
