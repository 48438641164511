import {
  AccountType,
  permissionsByEntity,
  useCheckAccountType,
  useCheckPermissions,
} from '@cmg/auth';
import {
  BusinessCenterOutlinedIcon,
  CampaignOutlinedIcon,
  EventNoteOutlinedIcon,
  FactCheckOutlinedIcon,
} from '@cmg/design-system';
import { useMemo } from 'react';

import { useFeatureToggles } from '../../../../common/config';
import useCertificateLibraryAccess from '../../../../common/hooks/useCertificateLibraryAccess';
import useCertificateReviewAccess from '../../../../common/hooks/useCertificateReviewAccess';
import useHasMyOrders from '../../../../common/hooks/useHasMyOrders';
import routeFactory from '../../../../common/util/routeFactory';
import { useHasOfferingAccess } from '../../../../features/offering/hooks/useHasOfferingAccess';
import { AppBarNavigationDefinition } from '../types';

export const useNavItems = () => {
  const hasOfferingAccess = useHasOfferingAccess();
  const hasMyOrders = useHasMyOrders();
  const hasRoadshowPermissions = useCheckPermissions(
    [permissionsByEntity.Roadshow.FULL, permissionsByEntity.PublishedInternalRoadshow.READ],
    false
  );

  const { canShowAccess: canShowCertificateLibraryAccess } = useCertificateLibraryAccess();
  const { canShowAccess: canShowCertificateReviewAccess } = useCertificateReviewAccess();

  const isSellSide = useCheckAccountType(AccountType.SELL_SIDE);
  const { isRoadshowsOn } = useFeatureToggles();
  return useMemo<AppBarNavigationDefinition[]>(() => {
    const navDefinitions: AppBarNavigationDefinition[] = [];

    if (hasOfferingAccess && isSellSide && !hasMyOrders) {
      navDefinitions.push({
        id: 'my-offerings',
        href: routeFactory.myOfferings.getUrlPath(),
        label: 'My Offerings',
        routePath: [routeFactory.myOfferings.routePath, routeFactory.offering.routePath],
        startIcon: <BusinessCenterOutlinedIcon />,
      });
    }
    navDefinitions.push({
      id: 'calendar',
      href: routeFactory.calendar.getUrlPath(),
      label: 'Calendar',
      routePath: routeFactory.calendar.routePath,
      startIcon: <EventNoteOutlinedIcon />,
    });
    if (isRoadshowsOn && hasRoadshowPermissions) {
      navDefinitions.push({
        id: 'roadshows',
        href: routeFactory.roadshows.getUrlPath(),
        label: 'Roadshows',
        routePath: routeFactory.roadshows.routePath,
        startIcon: <CampaignOutlinedIcon />,
      });
    }
    if (hasMyOrders) {
      navDefinitions.push({
        id: 'my-orders',
        href: routeFactory.myOrders.getUrlPath(),
        label: 'My Orders',
        routePath: routeFactory.myOrders.routePath,
        startIcon: <BusinessCenterOutlinedIcon />,
      });
    }

    if (canShowCertificateLibraryAccess || canShowCertificateReviewAccess) {
      navDefinitions.push({
        id: 'certificate-library',
        href: routeFactory.certificateLibrary.getUrlPath(),
        label: 'Certificate Library',
        routePath: routeFactory.certificateLibrary.routePath,
        startIcon: <FactCheckOutlinedIcon />,
      });
    }

    return navDefinitions;
  }, [
    canShowCertificateLibraryAccess,
    canShowCertificateReviewAccess,
    hasMyOrders,
    hasOfferingAccess,
    hasRoadshowPermissions,
    isRoadshowsOn,
    isSellSide,
  ]);
};
