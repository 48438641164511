import { CurrencyInputField } from '@cmg/common';
import styled from 'styled-components/macro';

export const StyledCurrencyInputField = styled(CurrencyInputField)`
  padding: 0 4px;
  & input {
    height: 28px;
  }
`;

export const SWrapper = styled.div`
  padding: 0 11px;
`;
