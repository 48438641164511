import { assets } from '@cmg/common';
import React from 'react';

import { SOnPlatformIcon } from '../../../grid-columns/company-name-cell/CompanyNameCell.styles';
import { DupesGridRow } from '../DupesModal.model';
import {
  SCompanyName,
  SCompanyNameCellWrapper,
  SEmptyLogoCompanyCell,
} from './DupesCompanyNameCell.styles';

type Props = {
  data: DupesGridRow;
};

/**
 * Renders the Company Name Cell within the Institutional Demand Grid.
 */
const DupesCompanyNameCell: React.FC<Props> = ({ data }) => {
  const isOnPlatform = data.investorInformation.isOnPlatform;

  return (
    <SCompanyNameCellWrapper>
      {isOnPlatform && (
        <React.Fragment>
          <span>
            <SOnPlatformIcon src={assets.logoNoText} alt="This investor is on the CMG platform." />
          </span>
        </React.Fragment>
      )}
      {!isOnPlatform && <SEmptyLogoCompanyCell />}
      <SCompanyName>
        {' '}
        {data.investorInformation.bankInvestorName || data.investorInformation.cmgEntityName}
      </SCompanyName>
    </SCompanyNameCellWrapper>
  );
};

export default DupesCompanyNameCell;
