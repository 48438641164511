import { useAuth } from '@cmg/auth';
import { LinkButton, ODSTable as Table, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import {
  ArrayHelpers,
  ErrorMessage,
  FieldArray,
  FormikContextType,
  useFormikContext,
} from 'formik';
import React from 'react';

import logo from '../../../../../../common/assets/deal-team.svg';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { DealTeamMemberStatus, DealTeamMemberType } from '../../../../../../graphql';
import DealTeamMemberBanner from '../../../../common/components/deal-team-member-banner/DealTeamMemberBanner';
import { DealTeamFormValues, initialDealTeamMember } from '../DealTeamForm.model';
import DealTeamMemberRow from './deal-team-member-row/DealTeamMemberRow';
import { SErrorWrapper, STextWrapper, StyledSecondaryButton } from './DealTeamMembersPanel.styles';
import DeleteDealTeamMemberModal from './delete-team-member-modal/DeleteTeamMemberModal';

export type Props = {
  dealTeamId?: UUID;
  onAdd: ArrayHelpers['unshift'];
  onRemove: ArrayHelpers['remove'];
  departments: DealTeamFormValues['departments'];
  dealTeamMembers: DealTeamFormValues['dealTeamMembers'];
  setFieldValue: FormikContextType<DealTeamFormValues>['setFieldValue'];
};

// exported for testing
export const DealTeamMembersPanelComponent: React.FC<Props> = ({
  dealTeamId,
  onAdd,
  onRemove,
  departments,
  dealTeamMembers,
  setFieldValue,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [dealTeamMemberIndex, setDealTeamMemberIndex] = React.useState<number | null>(null);
  const { oidcUserCmgEntityKey } = useAuth();

  const dealTeamMemberId =
    dealTeamMemberIndex !== null ? dealTeamMembers[dealTeamMemberIndex].id : null;

  const someMembersHaveErrors = dealTeamMembers.some(
    ({ status }) => status === DealTeamMemberStatus.Invalid
  );

  const departmentsOptions = React.useMemo(
    () =>
      Array.from(new Set(departments))
        .filter(item => item !== '')
        .map(department => ({
          value: department,
          label: department,
        })),
    [departments]
  );

  const handleDeleteButtonClick = (index: number) => {
    if (dealTeamMembers[index].id) {
      setDealTeamMemberIndex(index);
      setIsDeleteModalOpen(true);
    } else {
      confirmDelete(index);
    }
  };

  const confirmDelete = (index: number) => {
    setIsDeleteModalOpen(false);
    onRemove(index);
    setDealTeamMemberIndex(null);
  };

  const handleConvertMember = (index: number) => {
    setFieldValue(`dealTeamMembers.${index}.type`, DealTeamMemberType.Manual, false);
    setFieldValue(`dealTeamMembers.${index}.status`, DealTeamMemberStatus.Valid, false);
  };

  const isMyOrganisation = dealTeamId === oidcUserCmgEntityKey;

  return (
    <Panel>
      <Panel.Header title="Deal Team Members">
        <LinkButton
          testId={xcSelectors.addDealTeamMember.testId}
          iconLeft={{ name: 'plus' }}
          onClick={() => onAdd(initialDealTeamMember)}
          inline
        >
          Add Member
        </LinkButton>
        <StyledSecondaryButton testId={xcSelectors.importDealTeamMembers.testId}>
          Import Members
        </StyledSecondaryButton>
      </Panel.Header>
      <Panel.Content>
        {dealTeamMembers.length === 0 && (
          <React.Fragment>
            <ErrorMessage name="dealTeamMembers" component={SErrorWrapper} />
            <STextWrapper>
              <img src={logo} alt="deal-team-members" />
              <p>Add Members manually or import Members to reuse Deal Team on your Roadshows.</p>
            </STextWrapper>
          </React.Fragment>
        )}
        {someMembersHaveErrors && <DealTeamMemberBanner />}
        {dealTeamMembers.length > 0 && (
          <Table gridTemplateColumns={`${isMyOrganisation ? '50px ' : ''}2fr 1fr 1fr 2fr 2fr 50px`}>
            <Table.Row>
              {isMyOrganisation && <Table.TableHeaderCell />}
              <Table.TableHeaderCell>
                Name <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell>Mobile Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>Office Phone</Table.TableHeaderCell>
              <Table.TableHeaderCell>
                Email <MandatoryAsterisk />
              </Table.TableHeaderCell>
              <Table.TableHeaderCell>Department</Table.TableHeaderCell>
              <Table.TableHeaderCell align="center" />
            </Table.Row>
            {dealTeamMembers.map((member, index) => (
              <DealTeamMemberRow
                key={index}
                member={member}
                index={index}
                departmentsOptions={departmentsOptions}
                onDelete={() => handleDeleteButtonClick(index)}
                onConvert={() => handleConvertMember(index)}
                isMyOrganisation={isMyOrganisation}
              />
            ))}
          </Table>
        )}
      </Panel.Content>
      {dealTeamMemberId && (
        <DeleteDealTeamMemberModal
          show={isDeleteModalOpen}
          dealTeamId={dealTeamId!}
          dealTeamMemberId={dealTeamMemberId}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={() => confirmDelete(dealTeamMemberIndex!)}
        />
      )}
    </Panel>
  );
};

// Performace optimization - FieldArray is rendered on every change of form values
const DealTeamMembersPanelMemo = React.memo(DealTeamMembersPanelComponent);

const DealTeamMembersFieldArray: React.FC<{ dealTeamId?: UUID }> = ({ dealTeamId }) => {
  const { values, setFieldValue } = useFormikContext<DealTeamFormValues>();

  return (
    <FieldArray
      name="dealTeamMembers"
      validateOnChange={false}
      render={({ unshift, remove }) => (
        <DealTeamMembersPanelMemo
          dealTeamId={dealTeamId}
          onAdd={unshift}
          onRemove={remove}
          departments={values.departments}
          dealTeamMembers={values.dealTeamMembers}
          setFieldValue={setFieldValue}
        />
      )}
    />
  );
};

export default DealTeamMembersFieldArray;
