/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CoveredLogisticsQueryVariables = Types.Exact<{
  logisticsId: Types.Scalars['UUID'];
  roadshowId: Types.Scalars['UUID'];
}>;

export type Roadshows_CoveredLogisticsQuery = {
  readonly __typename?: 'Query';
  readonly coveredRoadshowLogisticsDetails: {
    readonly __typename?: 'Logistics';
    readonly id: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly location: string;
    readonly notes?: string | null;
    readonly title: string;
  };
  readonly coveredRoadshowLogisticsParticipants: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export const Roadshows_CoveredLogisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CoveredLogistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredRoadshowLogisticsDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredRoadshowLogisticsParticipants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'logisticsId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'logisticsId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_LogisticsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Logistics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CoveredLogisticsQuery__
 *
 * To run a query within a React component, call `useRoadshows_CoveredLogisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CoveredLogisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CoveredLogisticsQuery({
 *   variables: {
 *      logisticsId: // value for 'logisticsId'
 *      roadshowId: // value for 'roadshowId'
 *   },
 * });
 */
export function useRoadshows_CoveredLogisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CoveredLogisticsQuery,
    Roadshows_CoveredLogisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_CoveredLogisticsQuery, Roadshows_CoveredLogisticsQueryVariables>(
    Roadshows_CoveredLogisticsDocument,
    options
  );
}
export function useRoadshows_CoveredLogisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CoveredLogisticsQuery,
    Roadshows_CoveredLogisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_CoveredLogisticsQuery,
    Roadshows_CoveredLogisticsQueryVariables
  >(Roadshows_CoveredLogisticsDocument, options);
}
export type Roadshows_CoveredLogisticsQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredLogisticsQuery
>;
export type Roadshows_CoveredLogisticsLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredLogisticsLazyQuery
>;
export type Roadshows_CoveredLogisticsQueryResult = Apollo.QueryResult<
  Roadshows_CoveredLogisticsQuery,
  Roadshows_CoveredLogisticsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_CoveredLogisticsMockResponse = makeMockResponse<
  Roadshows_CoveredLogisticsQueryVariables,
  Roadshows_CoveredLogisticsQuery
>(Roadshows_CoveredLogisticsDocument);
