import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreateNonSyndicateFreeFormWireMutation,
  SyndicateWires_NonSyndicateFreeFormWiresDocument,
  useSyndicateWires_CreateNonSyndicateFreeFormWireMutation,
} from '../graphql';

export type CreateNonSyndicateFreeFormWireMutation =
  SyndicateWires_CreateNonSyndicateFreeFormWireMutation;

export const useCreateNonSyndicateFreeFormWireMutation = () => {
  return useSyndicateWires_CreateNonSyndicateFreeFormWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export function refetchQueries() {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_NonSyndicateFreeFormWiresDocument,
  ];
  return getManyOperationNames(documentNodes);
}
