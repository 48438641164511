import { FlexContainer, Popover } from '@cmg/common';
import { CSSObject } from 'styled-components/macro';

import { WarningIcon } from './../../../../../../../common/components/warning-icon/WarningIcon';
import { CellData } from './components/cell-data/CellData';
import { getFormattedValue, hasInvalidValue } from './PinnedCellRenderer.model';

type Props = {
  value: string | number | null;
  subValue: string | number | null;
  align?: CSSObject['textAlign'];
  acceptZero?: boolean;
  errorMessage?: string;
  targetValue?: string | number | null;
  errorType?: 'warning' | 'error';
};

export const PinnedCellRenderer: React.FC<Props> = ({
  value,
  subValue,
  align,
  acceptZero = false,
  targetValue,
  errorMessage,
  errorType = 'error',
}) => {
  const formattedValue = getFormattedValue(value);

  if (hasInvalidValue({ value, targetValue, formattedValue, acceptZero })) {
    return (
      <Popover trigger="hover" content={errorMessage} variant="DARK" placement="top">
        <FlexContainer direction="row" justifyContent="center" alignItems="center" gap={10}>
          <WarningIcon type={errorType} />
          <CellData align={align} value={formattedValue} subValue={subValue} />
        </FlexContainer>
      </Popover>
    );
  }

  return <CellData align={align} value={formattedValue} subValue={subValue} />;
};
