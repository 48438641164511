import styled from 'styled-components/macro';

export const SToolbarWrapper = styled.div.attrs({ role: 'presentation' })`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const SToolbarSection = styled.div.attrs({ role: 'presentation' })<{
  align?: 'left' | 'right';
}>`
  display: flex;
  gap: 16px;
  width: 50%;
  flex-direction: column;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};

  ${({ theme }) => theme.mediaQuery.largeUp} {
    flex-direction: row;
  }
`;

export const SMultiSelectWrapper = styled.div`
  width: 400px;
`;
