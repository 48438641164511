/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_RoadshowDealTeamSearchQueryVariables = Types.Exact<{
  pagination: Types.PaginationDataInput;
  filter: Types.RoadshowDealTeamFilterInput;
}>;

export type Roadshows_RoadshowDealTeamSearchQuery = {
  readonly __typename?: 'Query';
  readonly roadshowDealTeamMembersSearchResult: {
    readonly __typename?: 'DealTeamMemberLimitedPagedList';
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly hasNext?: boolean | null;
      readonly activePage: number;
    };
    readonly data: ReadonlyArray<{
      readonly __typename?: 'DealTeamMemberLimited';
      readonly id: string;
      readonly fullName: string;
      readonly firmName: string;
      readonly isMyOrganization: boolean;
      readonly department?: string | null;
    }>;
  };
};

export const Roadshows_RoadshowDealTeamSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_RoadshowDealTeamSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationDataInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RoadshowDealTeamFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowDealTeamMembersSearchResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activePage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Roadshows_DealTeamMemberLimitedParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_DealTeamMemberLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealTeamMemberLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMyOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_RoadshowDealTeamSearchQuery__
 *
 * To run a query within a React component, call `useRoadshows_RoadshowDealTeamSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_RoadshowDealTeamSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_RoadshowDealTeamSearchQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRoadshows_RoadshowDealTeamSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_RoadshowDealTeamSearchQuery,
    Roadshows_RoadshowDealTeamSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Roadshows_RoadshowDealTeamSearchQuery,
    Roadshows_RoadshowDealTeamSearchQueryVariables
  >(Roadshows_RoadshowDealTeamSearchDocument, options);
}
export function useRoadshows_RoadshowDealTeamSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_RoadshowDealTeamSearchQuery,
    Roadshows_RoadshowDealTeamSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Roadshows_RoadshowDealTeamSearchQuery,
    Roadshows_RoadshowDealTeamSearchQueryVariables
  >(Roadshows_RoadshowDealTeamSearchDocument, options);
}
export type Roadshows_RoadshowDealTeamSearchQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamSearchQuery
>;
export type Roadshows_RoadshowDealTeamSearchLazyQueryHookResult = ReturnType<
  typeof useRoadshows_RoadshowDealTeamSearchLazyQuery
>;
export type Roadshows_RoadshowDealTeamSearchQueryResult = Apollo.QueryResult<
  Roadshows_RoadshowDealTeamSearchQuery,
  Roadshows_RoadshowDealTeamSearchQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeRoadshows_RoadshowDealTeamSearchMockResponse = makeMockResponse<
  Roadshows_RoadshowDealTeamSearchQueryVariables,
  Roadshows_RoadshowDealTeamSearchQuery
>(Roadshows_RoadshowDealTeamSearchDocument);
