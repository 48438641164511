import {
  useInvestorContactInformation_AssignContactsMutation,
  useInvestorContactInformation_AssignCoveredContactsMutation,
} from '../graphql/__generated__';

export const useAssignContactsMutation = (isCoveredAccount: boolean) => {
  const assignContactsMutation = useInvestorContactInformation_AssignContactsMutation();
  const assignCoveredContactsMutation =
    useInvestorContactInformation_AssignCoveredContactsMutation();

  return isCoveredAccount ? assignCoveredContactsMutation : assignContactsMutation;
};
