import React from 'react';

import { SSidePanel, StyledCloseButton } from './SidePanel.styles';

export type Props = React.PropsWithChildren<{
  /** isOpen side panel state */
  isOpen: boolean;
  /** onClose invoked when the close button is pressed */
  onClose: () => void;
  testId?: string;
  width?: number;
}>;

/**
 * SidePanel - UI container fixed to the right side of the screen and spans the height of the entire viewport,
 * To use this component we should wrap it with a consumer component that handles isOpen and onClose state
 */
const SidePanel: React.FC<Props> = ({ isOpen, children, onClose, testId, width }) => {
  return (
    <SSidePanel
      role="dialog"
      aria-roledescription="action panel"
      isOpen={isOpen}
      data-test-id={testId}
      width={width}
    >
      <React.Fragment>
        <StyledCloseButton
          icon={{ name: 'times', size: '2x' }}
          onClick={onClose}
          disabled={!isOpen}
          title="Close"
        />
        {children}
      </React.Fragment>
    </SSidePanel>
  );
};

export default SidePanel;
