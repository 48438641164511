import { UUID } from '@cmg/common';

const offeringSetupEntitlements = {
  routePath: '/offerings/:offeringId/setup/managers/entitlements/offering-setup',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/setup/managers/entitlements/offering-setup`,
};

const orderBookEntitlements = {
  routePath: '/offerings/:offeringId/setup/managers/entitlements/order-book',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/setup/managers/entitlements/order-book`,
};

const roadshowEntitlements = {
  routePath: '/offerings/:offeringId/setup/managers/entitlements/roadshow',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/setup/managers/entitlements/roadshow`,
};

const syndicateWiresEntitlements = {
  routePath: '/offerings/:offeringId/setup/managers/entitlements/syndicate-wires',
  getUrlPath: ({ offeringId }: { offeringId: UUID }) =>
    `/offerings/${offeringId}/setup/managers/entitlements/syndicate-wires`,
};

const entitlements = {
  routePath: '/offerings/:offeringId/setup/managers/entitlements/:boundedContext',
  getUrlPath: ({
    offeringId,
    boundedContext,
  }: {
    offeringId: UUID;
    boundedContext: 'offering-setup' | 'roadshow' | 'order-book';
  }) => `/offerings/${offeringId}/setup/managers/entitlements/${boundedContext}`,
};

const entitlementsRoutes = {
  offeringSetupEntitlements,
  orderBookEntitlements,
  roadshowEntitlements,
  syndicateWiresEntitlements,
  entitlements,
};

export default entitlementsRoutes;
