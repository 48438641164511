import styled from 'styled-components/macro';

export const EconomicBreakdownGridWrapper = styled.div`
  .economics-breakdown-header-cell .ag-header-cell-text {
    white-space: pre-wrap;
  }
  .ag-theme-balham [class^='ag-'] {
    overflow: visible !important;
  }
  height: 650px;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
