import { Stack } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus, InstitutionalIndicationOrderType } from '../../../../../../graphql';
import { useIndicationActivitySalesAndTradingContext } from '../../context/IndicationActivitySalesAndTradingContext';
import Allocation from '../allocation/Allocation';
import InvestorContactInformationForm from '../investor-contact-information/InvestorContactInformationForm';
import ComplianceErrorBanner from './common/compliance-requited-banner/ComplianceErrorBanner';
import DuplicateIndicationBanner from './sales-and-trading/duplicate-indication-banner/DuplicateIndicationBanner';
import IndicationSectionCreateView from './sales-and-trading/indication-section-create-view/IndicationSectionCreateView';
import IndicationSectionEditView from './sales-and-trading/indication-section-edit-view/IndicationSectionEditView';
import IndicationSectionReadView from './sales-and-trading/indication-section-read-view/IndicationSectionReadView';

export type Props = {
  editable: boolean;
  isInvestorCoveredAndEditable?: boolean;
  isProspectusDocumentMissing?: boolean;
  isProspectusContactMandatory?: boolean;
  isOrderBookOpen: boolean;
};

/**
 * Container component used for rendering the
 * Manage Covered Indication for ST user.
 */
const ManageCoveredIndication: React.FC<Props> = ({
  editable,
  isInvestorCoveredAndEditable,
  isProspectusDocumentMissing,
  isProspectusContactMandatory,
  isOrderBookOpen,
}) => {
  const {
    complianceWarning: { hasComplianceWarning },
    attestation,
    coveredIndication,
    basicPublishedOffering,
    access: { canBillingAndDeliveryAgentUpdateAllocationAcknowledgment },
  } = useIndicationActivitySalesAndTradingContext();

  const [isEditingIndication, setIsEditingIndication] = React.useState(false);

  const prospectusContactMandatory = isProspectusContactMandatory && !isProspectusDocumentMissing;
  const [displayMissingContacts, setDisplayMissingContacts] = React.useState(false);

  const isCreatingIndication =
    basicPublishedOffering && !coveredIndication && !hasComplianceWarning && isOrderBookOpen;

  return (
    <div aria-label="Manage Covered Indication">
      <Stack gap={1} pb={1}>
        {coveredIndication?.status === IndicationStatus.Duplicate && <DuplicateIndicationBanner />}

        <ComplianceErrorBanner
          isMissingContacts={displayMissingContacts}
          attestationStatus={hasComplianceWarning ? attestation?.formStatus : undefined}
          prospectusDeliveryStatus={coveredIndication?.prospectusStatus?.overallDeliveryStatus}
        />
      </Stack>

      {isCreatingIndication && !isEditingIndication && (
        <IndicationSectionCreateView prospectusContactMandatory={!!prospectusContactMandatory} />
      )}
      {isEditingIndication && !isCreatingIndication && (
        <IndicationSectionEditView onFinishEditing={() => setIsEditingIndication(false)} />
      )}
      {!isEditingIndication && !isCreatingIndication && coveredIndication && (
        <IndicationSectionReadView
          onStartEditing={() => setIsEditingIndication(true)}
          isIndicationEditable={editable}
          displayMissingContacts={displayMissingContacts}
        />
      )}

      {basicPublishedOffering && coveredIndication && (
        <InvestorContactInformationForm
          prospectusContactMandatory={prospectusContactMandatory}
          offering={basicPublishedOffering}
          indication={coveredIndication}
          isCoveredAccount
          setDisplayMissingContacts={setDisplayMissingContacts}
          displayMissingContacts={displayMissingContacts}
          isInvestorCoveredAndEditable={isInvestorCoveredAndEditable}
        />
      )}

      {basicPublishedOffering &&
        coveredIndication &&
        coveredIndication.type !== InstitutionalIndicationOrderType.Pass && (
          <Allocation
            isCoveredAccount
            offering={basicPublishedOffering}
            indication={coveredIndication}
            onChangeAllocationRanking={() => {}}
            billingAndDeliveryAgentCmgEntityKey={
              coveredIndication.billingAndDeliveryAgentCmgEntityKey
            }
            isOrderBookOpen={isOrderBookOpen}
            isSellSideAccount
            canUpdateAllocation
            canBillingAndDeliveryAgentUpdate={
              canBillingAndDeliveryAgentUpdateAllocationAcknowledgment
            }
          />
        )}
    </div>
  );
};

export default ManageCoveredIndication;
