import React from 'react';

import { BoundedContext } from '../../../../../graphql';
import ConfigurationTable from '../components/configuration-table/ConfigurationTable';
import { EntitlementsDataByManagerContext } from '../hooks/EntitlementsDataByManagerContext';
import { useEntitlementConfigurationByBoundingContext } from '../hooks/useEntitlementConfigurationByBoundingContext';

const SyndicateWiresEntitlements: React.VFC = () => {
  const entitlementConfiguration = useEntitlementConfigurationByBoundingContext({
    boundedContext: BoundedContext.SyndicateWires,
  });

  const { selectedManagerEntitlements } = React.useContext(EntitlementsDataByManagerContext);

  return (
    <ConfigurationTable
      configuration={entitlementConfiguration}
      managerEntitlements={selectedManagerEntitlements}
    />
  );
};

export default SyndicateWiresEntitlements;
