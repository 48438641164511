import { ButtonsContainer, IconButton, theme, timeUtil } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import capitalize from 'lodash/capitalize';
import React from 'react';

import { OfferingNotes_OfferingNotePartsFragment } from './graphql';
import {
  SNoteContainer,
  SNoteFooterText,
  SNoteFooterTitle,
  SNoteHeader,
  SNoteText,
  STag,
} from './OfferingNote.styles';

type Props = {
  note: OfferingNotes_OfferingNotePartsFragment;
  handleDeleteOfferingNote: (noteId: string) => void;
  handleEditOfferingNote: (noteId: string) => void;
};

const OfferingNote: React.FC<Props> = ({
  note,
  handleDeleteOfferingNote,
  handleEditOfferingNote,
}) => {
  const onDeleteOfferingNote = () => {
    handleDeleteOfferingNote(note.id);
  };

  const onEditOfferingNote = () => {
    handleEditOfferingNote(note.id);
  };

  return (
    <SNoteContainer>
      <SNoteHeader>
        <STag>{capitalize(note.subject)}</STag>
        <ButtonsContainer>
          <IconButton
            onClick={onEditOfferingNote}
            size="large"
            color={theme.color.blue2}
            icon={{ name: 'pencil-alt' }}
            testId={xcSelectors.offeringNotesEditNoteButton.testId}
          />
          <IconButton
            onClick={onDeleteOfferingNote}
            size="large"
            color={theme.color.blue2}
            icon={{ name: 'trash-alt' }}
            testId={xcSelectors.offeringNotesDeleteNoteButton.testId}
          />
        </ButtonsContainer>
      </SNoteHeader>
      <div>
        <SNoteText>{note.note}</SNoteText>
      </div>
      <div>
        <SNoteFooterText>
          <SNoteFooterTitle>Last Updated:</SNoteFooterTitle> {note.lastEditedBy}{' '}
          {timeUtil.formatAsDisplayDateTime(note.lastEditedAt)}
        </SNoteFooterText>
      </div>
    </SNoteContainer>
  );
};

export default OfferingNote;
