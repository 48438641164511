import { useApolloClient } from '@apollo/client';
import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import {
  AdditionalPagination,
  AsyncPaginatedSelect,
  AsyncPaginatedSelectProps,
  Group,
  Icon,
  Option,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoadOptions, UseAsyncPaginateBaseResult } from 'react-select-async-paginate';
import styled from 'styled-components/macro';

import { serializeFirmKey } from '../../../util/rolodex/firm-key-type.util';
import AdvancedInvestorSearchFlowModal, {
  openAdvancedInvestorSearchFlowModal,
} from './advanced-investor-search-flow-modal/AdvancedInvestorSearchFlowModal';
import AdvancedSearchOrCreateNewInvestorListOption from './components/CreateNewInvestorListOption';
import ListOption from './components/ListOption';
import {
  InvestorFirmSearch_CoveredInvestorFirmsDocument,
  InvestorFirmSearch_CoveredInvestorFirmsQuery,
  InvestorFirmSearch_InvestorFirmsDocument,
  InvestorFirmSearch_InvestorFirmsQuery,
  InvestorFirmSearch_InvestorFirmsQueryVariables,
} from './graphql';
import { getInvestorFirmOptions } from './InvestorFirmSearch.model';

export const SLoading = styled.div`
  text-align: center;
  padding: 10px 0;

  & * {
    color: ${({ theme }) => theme.text.color.darkGray};
  }
`;

export type InvestorFirmOption = Option<string | null>;
export type Props = {
  showCreateNewInvestor?: boolean;
  fallbackToFirmKey?: boolean;
  isCoveredAccount?: boolean;
} & Pick<
  AsyncPaginatedSelectProps<string | null>,
  'value' | 'onChange' | 'styledConfig' | 'isLoading' | 'placeholder' | 'menuPlacement'
>;

type InvestorFirmSearchQueryType = InvestorFirmSearch_InvestorFirmsQuery &
  InvestorFirmSearch_CoveredInvestorFirmsQuery;

export const InvestorFirmsSearch: React.FC<Props> = ({
  onChange,
  showCreateNewInvestor = false,
  fallbackToFirmKey = false,
  isCoveredAccount = false,
  isLoading,
  placeholder = 'Select an investor',
  menuPlacement,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState<string | undefined>();
  const dispatch = useDispatch();
  // grab the apollo client
  const client = useApolloClient();
  const handleChange = (option: InvestorFirmOption | null) => {
    onChange && onChange(option);
  };

  const canViewAdvancedSearch = useCheckPermissions([permissionsByEntity.CRM.READ]);

  const loadOptions: LoadOptions<
    InvestorFirmOption,
    Group<string | null>,
    AdditionalPagination
  > = async (searchText, _loadedOptions, additionalArgs) => {
    const page = additionalArgs?.page ?? 1;

    // execute the query using apollo client directly
    const { data: response } = await client.query<
      InvestorFirmSearchQueryType,
      InvestorFirmSearch_InvestorFirmsQueryVariables
    >({
      query: !isCoveredAccount
        ? InvestorFirmSearch_InvestorFirmsDocument
        : InvestorFirmSearch_CoveredInvestorFirmsDocument,
      variables: { searchText, page, perPage: 10 },
      fetchPolicy: 'no-cache',
    });

    // get the data and pagination from response
    const { data, pagination } = response.investorFirms || response.coveredInvestorFirms;

    // component wants options[] and for infinite scroll pagination info like, hasMore/hasNext
    return {
      options: getInvestorFirmOptions(data ?? [], fallbackToFirmKey),
      hasMore: !!pagination?.hasNext,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <React.Fragment>
      <AsyncPaginatedSelect<string | null>
        {...rest}
        // save the searched input value to populate advanced search
        onInputChange={(inputValue?: string) => setInputValue(inputValue)}
        isClearable
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        onChange={handleChange}
        loadOptions={loadOptions}
        loadOptionsOnMenuOpen
        cacheUniqs={[rest.value]}
        renderOption={({ label, firmType, firmKey, location, value }: InvestorFirmOption) => {
          const selectedValue = rest.value?.value;
          const isSelected = value === selectedValue;

          return (
            <ListOption
              label={label}
              firmType={firmType}
              firmKey={firmKey}
              location={location}
              isSelected={isSelected}
            />
          );
        }}
        renderMenu={props => {
          /**
           * isFirstLoad is coming from react-select-async-paginate, which adds several properties to main selectProps.
           * Unfortunately these properties are not added to props types so we need to type them manually.
           */
          const showLoading =
            !(
              props.selectProps as unknown as UseAsyncPaginateBaseResult<
                Option<string | null>,
                Group<string | null>
              >
            ).isFirstLoad && props.selectProps.isLoading;

          return (
            <React.Fragment>
              {props.children}
              {showLoading && (
                <SLoading>
                  <Icon name="spinner-third" spin size="1x" /> Loading...
                </SLoading>
              )}
              {canViewAdvancedSearch && (
                <AdvancedSearchOrCreateNewInvestorListOption
                  showCreateNewInvestor={showCreateNewInvestor}
                  onCreateNew={() => {
                    dispatch(
                      openAdvancedInvestorSearchFlowModal({
                        showCreateNewInvestor,
                        searchText: inputValue,
                        onApply: investor => {
                          onChange &&
                            onChange({
                              label: investor.name,
                              value:
                                investor.cmgEntityKey ??
                                (fallbackToFirmKey ? serializeFirmKey(investor) : null),
                            });
                        },
                      })
                    );
                  }}
                />
              )}
            </React.Fragment>
          );
        }}
        renderSingleSelectedOption={({ label }) => <div>{label}</div>}
        testId={xcSelectors.offeringSideBarInvestorSearchSelect.testId}
      />
      <AdvancedInvestorSearchFlowModal />
    </React.Fragment>
  );
};

export default InvestorFirmsSearch;
