import { Typography } from '@cmg/design-system';
import React from 'react';

import { InterestLevels } from './InterestLevels';
import { RecentUpdateContentProps } from './RecentUpdateListItem.model';

export type Props = RecentUpdateContentProps;

export const IndicationInterestLevelChangeContent: React.FC<Props> = ({
  update,
  pricingCurrencyCode,
}) => {
  const { investorFirm, oldIndication, newIndication } = update;
  const newInterestLevels = newIndication?.interestLevels ?? [];
  const oldInterestLevels = oldIndication?.interestLevels ?? [];
  const isScaled = newInterestLevels.length > 1 || oldInterestLevels.length > 1;

  return (
    <Typography aria-label="Recent update - Indication interest level change" component="span">
      <Typography component="span" variant="highlight1">
        {investorFirm}
      </Typography>{' '}
      demand changed from{isScaled ? <br /> : ' '}
      <InterestLevels
        interestLevels={oldInterestLevels}
        pricingCurrencyCode={pricingCurrencyCode}
        demandCurrencyCode={newIndication?.currencyCode}
      />{' '}
      to{isScaled ? <br /> : ' '}
      <InterestLevels
        interestLevels={newInterestLevels}
        pricingCurrencyCode={pricingCurrencyCode}
        demandCurrencyCode={oldIndication?.currencyCode}
      />
      {isScaled ? '' : '.'}
    </Typography>
  );
};
