import { UUID } from '@cmg/common';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import { IssuerAllocationWireInput, WireTemplateType } from '../../../../../graphql';
import { PricingCommitteeSideNav } from '../../common/components/navigation/pricing-commitee-side-nav/PricingCommitteeSideNav';
import { createWireTypeConfig } from '../../common/context/WireTypeConfigContext.model';
import { useSyndicateWires_WiresDisclaimerQuery } from '../../common/graphql';
import { getDisclaimer } from '../../common/utils/disclaimer';
import {
  convertEmailAddressToManager,
  convertEmailsToFirmSnapshotsInReport,
} from '../../common/utils/report-conversions';
import {
  SyndicateWires_IssuerAllocationWirePartsFragment,
  SyndicateWires_IssuerAllocationWireValidationPartsFragment,
  useSyndicateWires_IssuerAllocationWireDetailsQuery,
  useSyndicateWires_IssuerAllocationWireManagersQuery,
  useSyndicateWires_IssuerAllocationWirePreviewQuery,
  useSyndicateWires_IssuerAllocationWiresQuery,
  useSyndicateWires_IssuerAllocationWireValidationQuery,
} from './graphql';
import { useCreateIssuerAllocationWireMutation } from './hooks/useCreateIssuerAllocationWireMutation';
import { useDeleteIssuerAllocationWireMutation } from './hooks/useDeleteIssuerAllocationWireMutation';
import { useSendIssuerAllocationWireMutation } from './hooks/useSendIssuerAllocationWireMutation';
import { useUpdateIssuerAllocationWireMutation } from './hooks/useUpdateIssuerAllocationWireMutation';
import { IssuerAllocationWireForm } from './IssuerAllocationWireForm';
import { validationSchema, Values } from './IssuerAllocationWireForm.model';

const wireTypeName = 'Issuer Allocation';
const wireTypeCategory = 'report';

const getIssuerAllocationInputData = (values: Values) => {
  // Type cast to IssuerAllocationWireInput as form field allows null value type
  // to be used as initial values but null values won't pass through validation schema
  return values as IssuerAllocationWireInput;
};

const emptyOutdatedManagers = [];

export const wireTypeConfigIssuerAllocation = createWireTypeConfig<
  Values,
  SyndicateWires_IssuerAllocationWirePartsFragment,
  SyndicateWires_IssuerAllocationWireValidationPartsFragment,
  typeof routeFactory.syndicateWiresIssuerAllocation
>({
  wireTypeName,
  wireTypeRoute: routeFactory.syndicateWiresIssuerAllocation,
  wireTypeCategory,
  wireTemplateType: WireTemplateType.IssuerAllocation,
  CreateOrUpdateForm: IssuerAllocationWireForm,
  ManagersNavigation: PricingCommitteeSideNav,
  formValidation: validationSchema,

  useCreateIsDisabledReason: () => null,
  isCcMyFirmEnabled: false,

  useManagers: useIssuerAllocationRecipients,
  useNextRecipients: useIssuerAllocationRecipients,
  useIsWireManagerListOutdated: () => false,
  useOutdatedManagersList: () => emptyOutdatedManagers,

  useGenerateWirePreview: queryArgs => {
    const { data, error, loading } = useSyndicateWires_IssuerAllocationWirePreviewQuery({
      variables: queryArgs,
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.issuerAllocationWirePreview.htmlContent,
      error,
      isLoading: loading,
    };
  },
  useWireDetails: (queryArgs, fetchPolicy) => {
    const { data, error, loading } = useSyndicateWires_IssuerAllocationWireDetailsQuery({
      variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
      skip: !queryArgs.syndicateWireId,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    });

    const wireDetails = React.useMemo(() => {
      return (
        data && {
          wire: convertEmailsToFirmSnapshotsInReport(data.issuerAllocationWireDetails),
          stage: data.offering.stage,
        }
      );
    }, [data]);

    return {
      data: wireDetails,
      isLoading: loading,
      error,
    };
  },
  useWireList: ({ offeringId }) => {
    const { data, error, loading } = useSyndicateWires_IssuerAllocationWiresQuery({
      variables: { offeringId },
      fetchPolicy: 'cache-and-network',
    });

    return {
      data: data?.issuerAllocationWireList.map(convertEmailsToFirmSnapshotsInReport),
      isLoading: loading,
      error,
    };
  },
  useWireValidation: ({ offeringId, fetchPolicy }) => {
    const { data, loading, error, refetch } = useSyndicateWires_IssuerAllocationWireValidationQuery(
      {
        variables: { offeringId },
        fetchPolicy: fetchPolicy || 'cache-and-network',
      }
    );

    return { data: data?.issuerAllocationWireValidation, isLoading: loading, error, refetch };
  },

  useCreateMutation: () => {
    const [createWire, { loading }] = useCreateIssuerAllocationWireMutation();

    return {
      mutation: async ({ offeringId, values }) => {
        const result = await createWire({
          variables: {
            offeringId,
            payload: getIssuerAllocationInputData(values),
          },
        });

        if (result.data?.createIssuerAllocationWire.__typename === 'ServiceError') {
          throw new Error(`Creating the ${wireTypeName} ${wireTypeCategory} failed.`);
        }

        return result.data?.createIssuerAllocationWire;
      },
      isLoading: loading,
    };
  },
  useUpdateMutation: () => {
    const [updateWire, { loading }] = useUpdateIssuerAllocationWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, values }) => {
        const result = await updateWire({
          variables: { offeringId, syndicateWireId, payload: getIssuerAllocationInputData(values) },
        });

        if (result.data?.updateIssuerAllocationWire.__typename === 'ServiceError') {
          throw new Error(`Updating the ${wireTypeName} ${wireTypeCategory} failed.`);
        }

        return result.data?.updateIssuerAllocationWire;
      },
      isLoading: loading,
    };
  },
  useDeleteMutation: () => {
    const [deleteWire, { loading }] = useDeleteIssuerAllocationWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId }) => {
        const result = await deleteWire({ variables: { offeringId, syndicateWireId } });

        if (result.data?.deleteIssuerAllocationWire.__typename === 'ServiceError') {
          throw new Error(`Deleting the ${wireTypeName} ${wireTypeCategory} failed.`);
        }
      },
      isLoading: loading,
    };
  },
  useSendMutation: () => {
    const [sendWire, { loading }] = useSendIssuerAllocationWireMutation();

    return {
      mutation: async ({ offeringId, syndicateWireId, managerIds: emailAddresses }) => {
        const result = await sendWire({
          variables: {
            offeringId,
            syndicateWireId,
            emailAddresses,
            ...generatePayloadDateTimeData(),
          },
        });

        if (result.data?.sendIssuerAllocationWire.__typename === 'ServiceError') {
          throw new Error(`Sending the ${wireTypeName} ${wireTypeCategory} failed.`);
        }

        return result.data?.sendIssuerAllocationWire;
      },
      isLoading: loading,
    };
  },
  useGetInitialValues: ({ wire, offeringId, offeringType, operationType, wireTemplateType }) => {
    const {
      data: disclaimerData,
      loading: disclaimerLoading,
      error,
    } = useSyndicateWires_WiresDisclaimerQuery({
      variables: {
        offeringId,
        offeringType,
        templateType: wireTemplateType,
      },
    });

    const data = React.useMemo<Values>(() => {
      return {
        emailAddresses: wire?.emailAddresses ?? [],
        disclaimer: getDisclaimer(
          operationType,
          wire?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        ),
      };
    }, [
      operationType,
      wire?.disclaimer,
      wire?.emailAddresses,
      disclaimerData?.syndicateWires.wiresDisclaimer,
    ]);

    return { isLoading: disclaimerLoading, data, error };
  },
});

function useIssuerAllocationRecipients(args: { offeringId: UUID; syndicateWireId?: UUID }) {
  const { data, loading, error } = useSyndicateWires_IssuerAllocationWireManagersQuery({
    variables: { ...args, syndicateWireId: args.syndicateWireId! },
    skip: !args.syndicateWireId,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.issuerAllocationWireDetails.emailAddresses.map(convertEmailAddressToManager) ?? [],
    error,
    isLoading: loading,
  };
}
