import { ApolloError } from '@apollo/client';
import {
  CurrencyInputField,
  MultiSelectField,
  NumericInputField,
  numericUtil,
  UnsavedChangesGuard,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { isIPOType } from '../../../../common/util/offering/offering-type.util';
import { FilingType, OfferingType, UseOfProceeds } from '../../../../graphql';
import {
  filingTypeLabels,
  useOfProceedsLabels,
  useOfProceedsOptions,
} from '../../../../types/domain/filing/constants';
import SetupForm from '../../components/form/OfferingSetupForm';
import { OfferingSetupFormActions } from '../../components/form/OfferingSetupFormActions';
import { OfferingSetup_FilingPartsFragment } from '../graphql';
import { availableFilingTypeOptions, FilingSchema } from './FilingForm.model';
import {
  SFilingGroupFields,
  SFilingGroupTitle,
  StyledActions,
  StyledCardHeader,
  StyledColumn,
  StyledFilingGroup,
  StyledFilingTypeField,
  StyledFormField,
  StyledRowAlignBottom,
  StyledSelectField,
} from './FilingForm.styles';

type FormType = {
  filingType: FilingType;
  initialRegistrationValue: number | null;
  primarySharesBaseOffering: number | null;
  secondarySharesBaseOffering: number | null;
  notionalOverAllotmentAuthorized: number | null;
  primarySharesOverAllotmentAuthorized: number | null;
  secondarySharesOverAllotmentAuthorized: number | null;
  notionalOverAllotmentExercised: number | null;
  primarySharesOverAllotmentExercised: number | null;
  secondarySharesOverAllotmentExercised: number | null;
  ipoRangeLow: number | null;
  ipoRangeHigh: number | null;
  offerPrice: number | null;
  postOfferingShares: number | null;
  useOfProceeds: readonly UseOfProceeds[] | null;
  lockUpPeriod: number | null;
};

export type Props = {
  adding?: boolean;
  editing?: boolean;
  disabled?: boolean;
  hasInitialFiling?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit: (payload: FormType) => void;
  data?: OfferingSetup_FilingPartsFragment;
  offeringType?: OfferingType;
  pricingCurrencyCode?: string;
};

export const FilingFormComponent: React.FC<Props> = ({
  adding,
  editing,
  disabled,
  hasInitialFiling,
  onEdit,
  onCancel,
  onDelete,
  onSubmit,
  data,
  offeringType,
  pricingCurrencyCode,
  // TODO: reduce cognitive complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const formikOptions: FormikConfig<FormType> = {
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: FilingSchema,
    initialValues: {
      filingType: data?.filingType || FilingType.Initial,
      initialRegistrationValue: data?.initialRegistrationValue ?? null,
      primarySharesBaseOffering: data?.primarySharesBaseOffering ?? null,
      secondarySharesBaseOffering: data?.secondarySharesBaseOffering ?? null,
      notionalOverAllotmentAuthorized: data?.notionalOverAllotmentAuthorized ?? null,
      primarySharesOverAllotmentAuthorized: data?.primarySharesOverAllotmentAuthorized ?? null,
      secondarySharesOverAllotmentAuthorized: data?.secondarySharesOverAllotmentAuthorized ?? null,
      notionalOverAllotmentExercised: data?.notionalOverAllotmentExercised ?? null,
      primarySharesOverAllotmentExercised: data?.primarySharesOverAllotmentExercised ?? null,
      secondarySharesOverAllotmentExercised: data?.secondarySharesOverAllotmentExercised ?? null,
      ipoRangeLow: data?.ipoRangeLow ?? null,
      ipoRangeHigh: data?.ipoRangeHigh ?? null,
      offerPrice: data?.offerPrice ?? null,
      postOfferingShares: data?.postOfferingShares ?? null,
      useOfProceeds: data?.useOfProceeds || null,
      lockUpPeriod: data?.lockUpPeriod ?? null,
    },
    onSubmit: onSubmit,
  };

  const formik = useFormik(formikOptions);

  const { handleSubmit, dirty, resetForm } = formik;

  const actionProps = {
    editing,
    disabled,
    disableDelete: data?.filingType !== FilingType.Revised,
    onEdit,
    onCancel,
    onDelete,
    onSubmit: handleSubmit,
  };

  const handleCancel = () => {
    resetForm();
    onCancel && onCancel();
  };
  const isFinalFiling = data?.filingType === FilingType.Final;
  return (
    <FormikProvider value={formik}>
      <UnsavedChangesGuard when={dirty} onLeave={handleCancel}>
        <SetupForm.Card withBorder>
          <StyledCardHeader>
            {editing ? (
              <StyledSelectField
                withMargin
                required
                name="filingType"
                options={availableFilingTypeOptions(hasInitialFiling)}
                isClearable={false}
                label="Type"
                disabled={!adding}
                testId={xcSelectors.offeringSetupTermsFilingType.testId}
              />
            ) : (
              <StyledFilingTypeField fullWidth withMargin label="Type">
                {data?.filingType && filingTypeLabels[data?.filingType]}
              </StyledFilingTypeField>
            )}
            <StyledActions>
              <OfferingSetupFormActions {...actionProps} />
            </StyledActions>
          </StyledCardHeader>
          <StyledFilingGroup>
            <SFilingGroupTitle>Base Offering</SFilingGroupTitle>
            <SFilingGroupFields>
              <StyledRowAlignBottom>
                <StyledColumn>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="initialRegistrationValue"
                      label="Initial Registration Value"
                      currencyCode={pricingCurrencyCode}
                      testId={xcSelectors.offeringSetupTermsInitialRegistrationValue.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Initial Registration Value">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.initialRegistrationValue,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="primarySharesBaseOffering"
                      label="Primary Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={xcSelectors.offeringSetupTermsPrimaryShares.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Primary Shares">
                      {numericUtil.getDisplayValueForNumber(data?.primarySharesBaseOffering, 0)}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="secondarySharesBaseOffering"
                      label="Secondary Shares "
                      placeholder="Shares"
                      precision={0}
                      testId={xcSelectors.offeringSetupTermsSecondaryShares.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Secondary Shares">
                      {numericUtil.getDisplayValueForNumber(data?.secondarySharesBaseOffering, 0)}
                    </StyledFormField>
                  )}
                </StyledColumn>
              </StyledRowAlignBottom>
              <StyledRowAlignBottom>
                <SetupForm.Column size={1}>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="offerPrice"
                      label="Offer Price"
                      currencyCode={pricingCurrencyCode}
                      testId={xcSelectors.offeringSetupTermsOfferPrice.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Offer Price">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.offerPrice,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
                <SetupForm.Column size={1}>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="ipoRangeLow"
                      label="IPO Range Low"
                      currencyCode={pricingCurrencyCode}
                      disabled={!isIPOType(offeringType) || isFinalFiling}
                      testId={xcSelectors.offeringSetupTermsIPORangeLow.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="IPO Range Low">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.ipoRangeLow,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
                <SetupForm.Column size={1}>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="ipoRangeHigh"
                      label="IPO Range High"
                      currencyCode={pricingCurrencyCode}
                      disabled={!isIPOType(offeringType) || isFinalFiling}
                      testId={xcSelectors.offeringSetupTermsIPORangeHigh.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="IPO Range High">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.ipoRangeHigh,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
              </StyledRowAlignBottom>
              <StyledRowAlignBottom>
                <SetupForm.Column size={1}>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="postOfferingShares"
                      label="Post Offering Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={xcSelectors.offeringSetupTermsPostOfferingShares.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Post Offering Shares">
                      {numericUtil.getDisplayValueForNumber(data?.postOfferingShares, 0)}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
                <SetupForm.Column size={1}>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="lockUpPeriod"
                      label="Lock Up Period"
                      placeholder="Days"
                      precision={0}
                      testId={xcSelectors.offeringSetupTermsLockUpPeriod.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Lock Up Period">
                      {numericUtil.getDisplayValueForNumber(data?.lockUpPeriod, 0)}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
                <SetupForm.Column size={1}>
                  {editing ? (
                    <MultiSelectField
                      fullWidth
                      withMargin
                      name="useOfProceeds"
                      options={useOfProceedsOptions}
                      label="Use of Proceeds"
                      isClearable={true}
                      maxDisplayedValues={1}
                      testId={xcSelectors.offeringSetupTermsUseOfProceeds.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Use of Proceeds">
                      {data?.useOfProceeds && data.useOfProceeds.length
                        ? data.useOfProceeds.map(d => useOfProceedsLabels[d]).join(', ')
                        : '-'}
                    </StyledFormField>
                  )}
                </SetupForm.Column>
              </StyledRowAlignBottom>
            </SFilingGroupFields>
          </StyledFilingGroup>
          <StyledFilingGroup>
            <SFilingGroupTitle>Over-allotment Authorized</SFilingGroupTitle>
            <SFilingGroupFields>
              <StyledRowAlignBottom>
                <StyledColumn>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="notionalOverAllotmentAuthorized"
                      label="Over-allotment Authorized"
                      currencyCode={pricingCurrencyCode}
                      testId={xcSelectors.offeringSetupTermsOverAllotmentAuthorized.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Over-allotment Authorized">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.notionalOverAllotmentAuthorized,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="primarySharesOverAllotmentAuthorized"
                      label="Primary Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={
                        xcSelectors.offeringSetupTermsOverAllotmentAuthorizedPrimaryShares.testId
                      }
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Primary Shares">
                      {numericUtil.getDisplayValueForNumber(
                        data?.primarySharesOverAllotmentAuthorized,
                        0
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="secondarySharesOverAllotmentAuthorized"
                      label="Secondary Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={
                        xcSelectors.offeringSetupTermsOverAllotmentAuthorizedSecondaryShares.testId
                      }
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Secondary Shares">
                      {numericUtil.getDisplayValueForNumber(
                        data?.secondarySharesOverAllotmentAuthorized,
                        0
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
              </StyledRowAlignBottom>
            </SFilingGroupFields>
          </StyledFilingGroup>
          <StyledFilingGroup>
            <SFilingGroupTitle>Over-allotment Exercised</SFilingGroupTitle>
            <SFilingGroupFields>
              <StyledRowAlignBottom>
                <StyledColumn>
                  {editing ? (
                    // TODO - add currency code
                    <CurrencyInputField
                      fullWidth
                      withMargin
                      name="notionalOverAllotmentExercised"
                      label="Over-allotment Exercised"
                      currencyCode={pricingCurrencyCode}
                      testId={xcSelectors.offeringSetupTermsOverAllotmentExercised.testId}
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Over-allotment Exercised">
                      {numericUtil.getDisplayValueForCurrency(
                        data?.notionalOverAllotmentExercised,
                        2,
                        pricingCurrencyCode
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="primarySharesOverAllotmentExercised"
                      label="Primary Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={
                        xcSelectors.offeringSetupTermsOverAllotmentExercisedPrimaryShares.testId
                      }
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Primary Shares">
                      {numericUtil.getDisplayValueForNumber(
                        data?.primarySharesOverAllotmentExercised,
                        0
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
                <StyledColumn>
                  {editing ? (
                    <NumericInputField
                      fullWidth
                      withMargin
                      name="secondarySharesOverAllotmentExercised"
                      label="Secondary Shares"
                      placeholder="Shares"
                      precision={0}
                      testId={
                        xcSelectors.offeringSetupTermsOverAllotmentExercisedSecondaryShares.testId
                      }
                    />
                  ) : (
                    <StyledFormField fullWidth withMargin label="Secondary Shares">
                      {numericUtil.getDisplayValueForNumber(
                        data?.secondarySharesOverAllotmentExercised,
                        0
                      )}
                    </StyledFormField>
                  )}
                </StyledColumn>
              </StyledRowAlignBottom>
            </SFilingGroupFields>
          </StyledFilingGroup>
        </SetupForm.Card>
      </UnsavedChangesGuard>
    </FormikProvider>
  );
};

export default FilingFormComponent;
