import { AgGridApi } from '@cmg/common';

import { TradeReleaseStatus } from '../../../../../../graphql';
import { TradeRelease_TradeGridItemFragment } from '../../../graphql/__generated__';
import { tradeColsMap } from '../../TradeReleaseGrid.model';

export type DropDownItemValueType = 'SELECT_ALL' | TradeReleaseStatus;
export const dropDownItems: Array<{ label: string; value: DropDownItemValueType }> = [
  { label: 'Select All', value: 'SELECT_ALL' },
  { label: 'Unreleased', value: TradeReleaseStatus.Unreleased },
  { label: 'Released', value: TradeReleaseStatus.Released },
  { label: 'Cancel Pending', value: TradeReleaseStatus.CancelPending },
  { label: 'Release Failed', value: TradeReleaseStatus.ReleaseFailed },
];

function refreshSelectedRow(api: AgGridApi) {
  api.refreshCells({
    force: true,
    columns: [tradeColsMap.selected],
  });
}

export function handleCheckboxChange(api: AgGridApi, value: boolean) {
  if (value) {
    api.selectAll();
  } else {
    api.deselectAll();
  }

  refreshSelectedRow(api);
}

export function handleDropdownSelectChange(api: AgGridApi, values: DropDownItemValueType[]) {
  api.deselectAll();
  values.forEach(value => {
    api.getRenderedNodes().forEach(node => {
      const tradeRow = node.data as TradeRelease_TradeGridItemFragment;

      if (tradeRow.tradeStatus === value || value === 'SELECT_ALL') {
        node.setSelected(true);
      }
    });
  });
  refreshSelectedRow(api);
}
