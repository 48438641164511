import { SFlexContainer, SSplitCellWrapper, StyleProps } from './SplitCellWrapper.styles';

export const SplitCellWrapper: React.FC<StyleProps> = ({ children, align }) => {
  return (
    <SSplitCellWrapper align={align}>
      <SFlexContainer direction="column" justifyContent="center" gap={3}>
        {children}
      </SFlexContainer>
    </SSplitCellWrapper>
  );
};
