import { getOperationName } from '@apollo/client/utilities';

import { useServiceMutation } from '../../../../common/hooks/useServiceMutation';
import { Offering_OfferingHeaderDocument } from '../../../offering/graphql';
import {
  OfferingSetup_CreateFilingDocument,
  OfferingSetup_CreateFilingMutation,
  OfferingSetup_CreateFilingMutationVariables,
} from '../graphql';

export const refetchQueries = () => {
  const offeringHeaderQuery = getOperationName(Offering_OfferingHeaderDocument);
  return [offeringHeaderQuery!];
};

export const useCreateFilingMutation = () =>
  useServiceMutation<
    OfferingSetup_CreateFilingMutation,
    OfferingSetup_CreateFilingMutationVariables
  >(OfferingSetup_CreateFilingDocument, { refetchQueries });
