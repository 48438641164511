import { ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import EmptyDistributionListWarning from '../../../../common/components/alerts/empty-distribution-list-warning/EmptyDistributionListWarning';
import SelectRecipientsDialog from '../../../../common/components/dialogs/select-recipients-dialog/SelectRecipientsDialog';
import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { BCCSenderSection } from '../../common/dialogs/send-notification-dialog/BCCSenderSection';
import { FilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/FilingConfirmationModal';
import { useResubmitFilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/hooks/useResubmitFilingConfirmationModal';
import { filingNames, getFilingFormLabel } from '../../common/filing-name-utils';
import RegulatoryFilingsDetailHeader from '../../common/header/RegulatoryFilingsDetailHeader';
import { FilingLayout } from '../../common/layouts/FilingLayout';
import { getRecipientsOfNotificationMessages } from '../../common/recipient-utils';
import {
  useRegulatoryFilings_RegulatoryFilingsQuery,
  useRegulatoryFilings_SendFilingEmailNotificationMutation,
} from '../../graphql';
import useContactSupportDialog from '../../hooks/useContactSupportDialog';
import { useFilingRecipientsDialog } from '../../hooks/useFilingRecipientsDialog';
import {
  getCanSendNotification,
  getRecipientsFromNotificationFragments,
} from '../../hooks/useFilingRecipientsDialog.model';
import { getVersion } from '../../RegulatoryFilingsRoute.model';
import { SFormContent } from '../../RegulatoryFilingsRoute.styles';
import RPNDetail from './detail/RPNDetail';
import { useRegulatoryFilings_RpnFilingDetailQuery } from './graphql';
import { useResubmitRPNFilingMutation } from './hooks/useResubmitRPNFilingMutation';

export type Props = RouteComponentProps<{ offeringId: UUID; filingId: UUID }>;

const RestrictedPeriodNotificationDetailRoute: React.FC<Props> = ({ match }) => {
  const { filingId, offeringId } = match.params;
  const { full: filingName, abbr: filingShortName } = filingNames.NOI;

  const [shouldAddSenderToBcc, setShouldAddSenderToBcc] = React.useState(false);

  const {
    data: detailData,
    error: detailError,
    loading: detailLoading,
    refetch: refetchDetail,
  } = useRegulatoryFilings_RpnFilingDetailQuery({
    variables: { filingId: filingId!, offeringId },
    skip: !filingId,
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: filingsData,
    error: filingsError,
    loading: filingsLoading,
  } = useRegulatoryFilings_RegulatoryFilingsQuery({ variables: { offeringId } });
  const [resubmitRPNFiling, { loading: isResubmitting }] = useResubmitRPNFilingMutation();
  const { push } = useHistory();

  const { recipients, notificationDialogRecipients, canSendNotification } = React.useMemo(
    () => ({
      recipients: getRecipientsOfNotificationMessages(
        detailData?.offering.regulatoryFilings.notifications
      ),
      notificationDialogRecipients: getRecipientsFromNotificationFragments(
        detailData?.offering.regulatoryFilings.notifications
      ),
      canSendNotification: getCanSendNotification(
        detailData?.offering.regulatoryFilings.notifications
      ),
    }),
    [detailData?.offering.regulatoryFilings.notifications]
  );

  const [confirmationModalProps, { openConfirmationModal }] = useResubmitFilingConfirmationModal({
    filingName,
    filingShortName,
    onResubmit: async () => {
      await resubmitRPNFiling({ variables: { filingId, offeringId } });
    },
  });

  const [sendFilingEmailNotification, { loading: isSendingNotification }] =
    useRegulatoryFilings_SendFilingEmailNotificationMutation();

  const {
    selectableRecipients,
    handleRecipientSelectionChange,
    isOpen: isRecipientsDialogOpen,
    open: openRecipientsDialog,
    close: closeRecipientsDialog,
  } = useFilingRecipientsDialog({
    recipients: notificationDialogRecipients,
  });

  const { handleContactSupport } = useContactSupportDialog({
    recipients,
    onOpen: closeRecipientsDialog,
    onClose: useCallback(
      () => (isRecipientsDialogOpen ? openRecipientsDialog() : undefined),
      [isRecipientsDialogOpen, openRecipientsDialog]
    ),
  });

  const handleSendEmailNotifications = async (selectedRecipientKeys: string[]) => {
    closeRecipientsDialog();

    try {
      await sendFilingEmailNotification({
        variables: {
          offeringId,
          filingId,
          recipients: selectedRecipientKeys,
          shouldAddSenderToBcc,
        },
      });

      ToastManager.success('Filing email notifications sent successfully.');
      await refetchDetail();
    } catch {
      ToastManager.error('Send filing email notifications failed.');
    }
  };

  const handleAmendFiling = () => {
    push(
      routeFactory.regulatoryFilingsRPNFilingForm.getUrlPath({
        offeringId,
        filingId,
      })
    );
  };

  if (detailLoading || filingsLoading) {
    return <Loading />;
  }

  if (detailError || filingsError) {
    return <ServerErrorsBanner error={detailError ?? filingsError!} />;
  }

  const detail = detailData?.offering.regulatoryFilings.RPNFiling;
  const version = getVersion(
    filingsData?.offering.regulatoryFilings.RPNFilings,
    detail?.submittedDateTime
  );
  const isExecutingMutation = isResubmitting || isSendingNotification;

  return (
    <FilingLayout
      testId={xcSelectors.rpnFilingDetailScreen.testId}
      recipients={recipients}
      offeringId={offeringId}
    >
      {detail && version && (
        <React.Fragment>
          <RegulatoryFilingsDetailHeader
            offeringId={offeringId}
            title={getFilingFormLabel('RPN')}
            filingStatus={detail.status}
            isLoading={isExecutingMutation}
            hideEmailNotificationAction={notificationDialogRecipients.length === 0}
            onAmendFiling={handleAmendFiling}
            onResubmitFiling={openConfirmationModal}
            onManageEmailNotifications={openRecipientsDialog}
          />
          <SFormContent>
            <EmptyDistributionListWarning
              recipients={recipients}
              onContactSupport={handleContactSupport}
            />
            <RPNDetail filing={detail} version={version} />
          </SFormContent>
          <FilingConfirmationModal {...confirmationModalProps} />
          <SelectRecipientsDialog
            onSelectionChange={handleRecipientSelectionChange}
            open={isRecipientsDialogOpen}
            recipients={selectableRecipients}
            hideSubmitButton={!canSendNotification}
            onSubmit={handleSendEmailNotifications}
            onCancel={closeRecipientsDialog}
            submitButtonLabel="Confirm"
            onContactSupport={handleContactSupport}
            getManagerRoleLabel={role => managerRoleLabels[role]}
            ccmyfirm={
              <BCCSenderSection
                offeringId={offeringId}
                canSendNotification={canSendNotification}
                onChange={() => setShouldAddSenderToBcc(!shouldAddSenderToBcc)}
                shouldAddSenderToBcc={shouldAddSenderToBcc}
                onContactSupport={handleContactSupport}
              />
            }
          />
        </React.Fragment>
      )}
    </FilingLayout>
  );
};

export default RestrictedPeriodNotificationDetailRoute;
