/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestorContactInformation_ContactCollectionPartsFragment = {
  readonly __typename?: 'ContactCollection';
  readonly id: string;
  readonly indicationId: string;
  readonly version: string;
  readonly contacts: ReadonlyArray<{
    readonly __typename?: 'Contact';
    readonly email: string;
    readonly name: string;
    readonly source: Types.ContactSource;
  }>;
};

export type InvestorContactInformation_ContactsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type InvestorContactInformation_ContactsQuery = {
  readonly __typename?: 'Query';
  readonly contacts: {
    readonly __typename?: 'ContactCollection';
    readonly id: string;
    readonly indicationId: string;
    readonly version: string;
    readonly contacts: ReadonlyArray<{
      readonly __typename?: 'Contact';
      readonly email: string;
      readonly name: string;
      readonly source: Types.ContactSource;
    }>;
  };
};

export type InvestorContactInformation_CoveredContactsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type InvestorContactInformation_CoveredContactsQuery = {
  readonly __typename?: 'Query';
  readonly coveredContacts: {
    readonly __typename?: 'ContactCollection';
    readonly id: string;
    readonly indicationId: string;
    readonly version: string;
    readonly contacts: ReadonlyArray<{
      readonly __typename?: 'Contact';
      readonly email: string;
      readonly name: string;
      readonly source: Types.ContactSource;
    }>;
  };
};

export type InvestorContactInformation_AssignContactsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  input: ReadonlyArray<Types.AssignContactInput> | Types.AssignContactInput;
}>;

export type InvestorContactInformation_AssignContactsMutation = {
  readonly __typename?: 'Mutation';
  readonly assignContacts: {
    readonly __typename?: 'ContactCollection';
    readonly id: string;
    readonly indicationId: string;
    readonly version: string;
    readonly contacts: ReadonlyArray<{
      readonly __typename?: 'Contact';
      readonly email: string;
      readonly name: string;
      readonly source: Types.ContactSource;
    }>;
  };
};

export type InvestorContactInformation_AssignCoveredContactsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  input: ReadonlyArray<Types.AssignContactInput> | Types.AssignContactInput;
}>;

export type InvestorContactInformation_AssignCoveredContactsMutation = {
  readonly __typename?: 'Mutation';
  readonly assignCoveredContacts: {
    readonly __typename?: 'ContactCollection';
    readonly id: string;
    readonly indicationId: string;
    readonly version: string;
    readonly contacts: ReadonlyArray<{
      readonly __typename?: 'Contact';
      readonly email: string;
      readonly name: string;
      readonly source: Types.ContactSource;
    }>;
  };
};

export type InvestorContactInformation_UnderwriterQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type InvestorContactInformation_UnderwriterQuery = {
  readonly __typename?: 'Query';
  readonly underwriter: {
    readonly __typename?: 'Firm';
    readonly id: string;
    readonly displayName?: string | null;
  };
};

export const InvestorContactInformation_ContactCollectionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactInformation_ContactCollectionParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InvestorContactInformation_ContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorContactInformation_Contacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InvestorContactInformation_ContactCollectionParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactInformation_ContactCollectionParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorContactInformation_ContactsQuery__
 *
 * To run a query within a React component, call `useInvestorContactInformation_ContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactInformation_ContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorContactInformation_ContactsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useInvestorContactInformation_ContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorContactInformation_ContactsQuery,
    InvestorContactInformation_ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorContactInformation_ContactsQuery,
    InvestorContactInformation_ContactsQueryVariables
  >(InvestorContactInformation_ContactsDocument, options);
}
export function useInvestorContactInformation_ContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorContactInformation_ContactsQuery,
    InvestorContactInformation_ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorContactInformation_ContactsQuery,
    InvestorContactInformation_ContactsQueryVariables
  >(InvestorContactInformation_ContactsDocument, options);
}
export type InvestorContactInformation_ContactsQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_ContactsQuery
>;
export type InvestorContactInformation_ContactsLazyQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_ContactsLazyQuery
>;
export type InvestorContactInformation_ContactsQueryResult = Apollo.QueryResult<
  InvestorContactInformation_ContactsQuery,
  InvestorContactInformation_ContactsQueryVariables
>;
export const InvestorContactInformation_CoveredContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorContactInformation_CoveredContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InvestorContactInformation_ContactCollectionParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactInformation_ContactCollectionParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorContactInformation_CoveredContactsQuery__
 *
 * To run a query within a React component, call `useInvestorContactInformation_CoveredContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactInformation_CoveredContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorContactInformation_CoveredContactsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useInvestorContactInformation_CoveredContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorContactInformation_CoveredContactsQuery,
    InvestorContactInformation_CoveredContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorContactInformation_CoveredContactsQuery,
    InvestorContactInformation_CoveredContactsQueryVariables
  >(InvestorContactInformation_CoveredContactsDocument, options);
}
export function useInvestorContactInformation_CoveredContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorContactInformation_CoveredContactsQuery,
    InvestorContactInformation_CoveredContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorContactInformation_CoveredContactsQuery,
    InvestorContactInformation_CoveredContactsQueryVariables
  >(InvestorContactInformation_CoveredContactsDocument, options);
}
export type InvestorContactInformation_CoveredContactsQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_CoveredContactsQuery
>;
export type InvestorContactInformation_CoveredContactsLazyQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_CoveredContactsLazyQuery
>;
export type InvestorContactInformation_CoveredContactsQueryResult = Apollo.QueryResult<
  InvestorContactInformation_CoveredContactsQuery,
  InvestorContactInformation_CoveredContactsQueryVariables
>;
export const InvestorContactInformation_AssignContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvestorContactInformation_AssignContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignContactInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InvestorContactInformation_ContactCollectionParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactInformation_ContactCollectionParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InvestorContactInformation_AssignContactsMutationFn = Apollo.MutationFunction<
  InvestorContactInformation_AssignContactsMutation,
  InvestorContactInformation_AssignContactsMutationVariables
>;

/**
 * __useInvestorContactInformation_AssignContactsMutation__
 *
 * To run a mutation, you first call `useInvestorContactInformation_AssignContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactInformation_AssignContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorContactInformationAssignContactsMutation, { data, loading, error }] = useInvestorContactInformation_AssignContactsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvestorContactInformation_AssignContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorContactInformation_AssignContactsMutation,
    InvestorContactInformation_AssignContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorContactInformation_AssignContactsMutation,
    InvestorContactInformation_AssignContactsMutationVariables
  >(InvestorContactInformation_AssignContactsDocument, options);
}
export type InvestorContactInformation_AssignContactsMutationHookResult = ReturnType<
  typeof useInvestorContactInformation_AssignContactsMutation
>;
export type InvestorContactInformation_AssignContactsMutationResult =
  Apollo.MutationResult<InvestorContactInformation_AssignContactsMutation>;
export type InvestorContactInformation_AssignContactsMutationOptions = Apollo.BaseMutationOptions<
  InvestorContactInformation_AssignContactsMutation,
  InvestorContactInformation_AssignContactsMutationVariables
>;
export const InvestorContactInformation_AssignCoveredContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvestorContactInformation_AssignCoveredContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignContactInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignCoveredContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InvestorContactInformation_ContactCollectionParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorContactInformation_ContactCollectionParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InvestorContactInformation_AssignCoveredContactsMutationFn = Apollo.MutationFunction<
  InvestorContactInformation_AssignCoveredContactsMutation,
  InvestorContactInformation_AssignCoveredContactsMutationVariables
>;

/**
 * __useInvestorContactInformation_AssignCoveredContactsMutation__
 *
 * To run a mutation, you first call `useInvestorContactInformation_AssignCoveredContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactInformation_AssignCoveredContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorContactInformationAssignCoveredContactsMutation, { data, loading, error }] = useInvestorContactInformation_AssignCoveredContactsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvestorContactInformation_AssignCoveredContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorContactInformation_AssignCoveredContactsMutation,
    InvestorContactInformation_AssignCoveredContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorContactInformation_AssignCoveredContactsMutation,
    InvestorContactInformation_AssignCoveredContactsMutationVariables
  >(InvestorContactInformation_AssignCoveredContactsDocument, options);
}
export type InvestorContactInformation_AssignCoveredContactsMutationHookResult = ReturnType<
  typeof useInvestorContactInformation_AssignCoveredContactsMutation
>;
export type InvestorContactInformation_AssignCoveredContactsMutationResult =
  Apollo.MutationResult<InvestorContactInformation_AssignCoveredContactsMutation>;
export type InvestorContactInformation_AssignCoveredContactsMutationOptions =
  Apollo.BaseMutationOptions<
    InvestorContactInformation_AssignCoveredContactsMutation,
    InvestorContactInformation_AssignCoveredContactsMutationVariables
  >;
export const InvestorContactInformation_UnderwriterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorContactInformation_Underwriter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorContactInformation_UnderwriterQuery__
 *
 * To run a query within a React component, call `useInvestorContactInformation_UnderwriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorContactInformation_UnderwriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorContactInformation_UnderwriterQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useInvestorContactInformation_UnderwriterQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorContactInformation_UnderwriterQuery,
    InvestorContactInformation_UnderwriterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorContactInformation_UnderwriterQuery,
    InvestorContactInformation_UnderwriterQueryVariables
  >(InvestorContactInformation_UnderwriterDocument, options);
}
export function useInvestorContactInformation_UnderwriterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorContactInformation_UnderwriterQuery,
    InvestorContactInformation_UnderwriterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorContactInformation_UnderwriterQuery,
    InvestorContactInformation_UnderwriterQueryVariables
  >(InvestorContactInformation_UnderwriterDocument, options);
}
export type InvestorContactInformation_UnderwriterQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_UnderwriterQuery
>;
export type InvestorContactInformation_UnderwriterLazyQueryHookResult = ReturnType<
  typeof useInvestorContactInformation_UnderwriterLazyQuery
>;
export type InvestorContactInformation_UnderwriterQueryResult = Apollo.QueryResult<
  InvestorContactInformation_UnderwriterQuery,
  InvestorContactInformation_UnderwriterQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeInvestorContactInformation_ContactsMockResponse = makeMockResponse<
  InvestorContactInformation_ContactsQueryVariables,
  InvestorContactInformation_ContactsQuery
>(InvestorContactInformation_ContactsDocument);

export const makeInvestorContactInformation_CoveredContactsMockResponse = makeMockResponse<
  InvestorContactInformation_CoveredContactsQueryVariables,
  InvestorContactInformation_CoveredContactsQuery
>(InvestorContactInformation_CoveredContactsDocument);

export const makeInvestorContactInformation_UnderwriterMockResponse = makeMockResponse<
  InvestorContactInformation_UnderwriterQueryVariables,
  InvestorContactInformation_UnderwriterQuery
>(InvestorContactInformation_UnderwriterDocument);
