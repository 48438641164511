import { LinkButton, ToastManager, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import {
  addArrayItemToGraphQLCacheFactory,
  deleteArrayItemFromGraphQLCacheFactory,
  updateArrayItemInGraphQLCacheFactory,
} from '../../../common/util/graphql';
import OfferingSetupPage from '../components/design-system/page/OfferingSetupPage';
import SetupForm from '../components/form/OfferingSetupForm';
import SetupScreen from '../components/screen/OfferingSetupScreen';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import MarketingForm from './components/marketing/MarketingForm';
import RoadshowScheduleForm from './components/roadshow-schedule/RoadshowScheduleForm';
import {
  useOfferingSetup_CreateRoadshowScheduleMutation,
  useOfferingSetup_DeleteRoadshowScheduleMutation,
  useOfferingSetup_MarketingQuery,
  useOfferingSetup_UpdateMarketingMutation,
  useOfferingSetup_UpdateRoadshowScheduleMutation,
} from './graphql';

export const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin-top: 20px;
`;

export type RouteProps = RouteComponentProps<{ offeringId: UUID; stepId: string }>;

/**
 * Marketing Route for Offering Setup
 * Displays Roadshow Schedule collection & two text areas for marketing
 */
export const MarketingRoute: React.FC<RouteProps> = ({ match }) => {
  const { offeringId } = match.params;

  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [addNew, setAddNew] = React.useState<boolean>(false);

  const handleAddNew = (active: boolean) => {
    setEditingId(null);
    setAddNew(active);
  };

  const handleEditing = (id: string | null) => {
    setAddNew(false);
    setEditingId(id);
  };

  const { revalidate } = useValidateOffering(offeringId);

  const { data, loading, error, refetch } = useOfferingSetup_MarketingQuery({
    variables: { offeringId: offeringId },
    onCompleted: d => {
      if (d?.offering.roadshowSchedules.length === 0) {
        handleAddNew(true);
      }
    },
  });

  const [updateMarketing, { loading: updatingMarketing, error: updateMarketingError }] =
    useOfferingSetup_UpdateMarketingMutation({
      onCompleted: () => {
        refetch();
        revalidate();
        ToastManager.success('Updated Marketing');
      },
      onError: () => {
        ToastManager.error('Failed to update Marketing');
      },
    });

  const [createRoadshowSchedule, { loading: creating, error: createError }] =
    useOfferingSetup_CreateRoadshowScheduleMutation({
      update: addArrayItemToGraphQLCacheFactory(
        { __typename: 'Offering', id: offeringId },
        'roadshowSchedules',
        result => result.data?.createRoadshowSchedule
      ),
      onCompleted: () => {
        handleAddNew(false);
        refetch();
        revalidate();
        ToastManager.success('Created Roadshow Day');
      },
      onError: () => {
        ToastManager.error('Failed to create Roadshow Day');
      },
    });

  const [updateRoadshowSchedule, { loading: updating, error: updateError }] =
    useOfferingSetup_UpdateRoadshowScheduleMutation({
      update: updateArrayItemInGraphQLCacheFactory(
        { __typename: 'Offering', id: offeringId },
        'roadshowSchedules',
        result => result.data?.updateRoadshowSchedule
      ),
      onCompleted: () => {
        handleEditing(null);
        setAddNew(false);
        refetch();
        revalidate();
        ToastManager.success('Updated Roadshow Day');
      },
      onError: () => {
        ToastManager.error('Failed to create Roadshow Day');
      },
    });

  const [deleteRoadshowSchedule, { loading: deleting, error: deleteError }] =
    useOfferingSetup_DeleteRoadshowScheduleMutation({
      update: deleteArrayItemFromGraphQLCacheFactory(
        { __typename: 'Offering', id: offeringId },
        'roadshowSchedules',
        result => result.data?.deleteRoadshowSchedule
      ),
      onCompleted: () => {
        handleEditing(null);
        setAddNew(false);
        refetch();
        revalidate();
        ToastManager.success('Removed Roadshow Day');
      },
      onError: () => {
        ToastManager.error('Failed to remove Roadshow Day');
      },
    });

  const roadshowSchedules = data?.offering.roadshowSchedules || [];
  const marketing = data?.offering.marketing;
  const errors = error || updateMarketingError || createError || updateError || deleteError;

  if (loading) {
    return <Spinner show />;
  }

  return (
    <OfferingSetupPage offeringId={offeringId} negativeMargin>
      <SetupScreen.Panel fillViewport>
        <SetupScreen.Header />
        {errors && <ServerErrorsBanner error={errors} />}
        <SetupForm title="Roadshow Details">
          <SetupForm.Table>
            <SetupForm.TableHeader>
              <SetupForm.Column>Date</SetupForm.Column>
              <SetupForm.Column>Details</SetupForm.Column>
            </SetupForm.TableHeader>
            {roadshowSchedules.map(roadshowSchedule => (
              <RoadshowScheduleForm
                key={roadshowSchedule.id}
                roadshowSchedule={roadshowSchedule}
                loading={updating || deleting}
                disabled={editingId !== null && editingId !== roadshowSchedule.id}
                editing={editingId === roadshowSchedule.id}
                onEdit={() => handleEditing(roadshowSchedule.id)}
                onCancel={() => handleEditing(null)}
                onDelete={() =>
                  deleteRoadshowSchedule({
                    variables: { offeringId, roadshowScheduleId: roadshowSchedule.id },
                  })
                }
                onSubmit={values =>
                  updateRoadshowSchedule({
                    variables: {
                      offeringId,
                      roadshowScheduleId: roadshowSchedule.id,
                      input: values,
                    },
                  })
                }
              />
            ))}

            {addNew && (
              <RoadshowScheduleForm
                editing
                loading={creating}
                onCancel={() => handleAddNew(false)}
                onSubmit={values =>
                  createRoadshowSchedule({ variables: { offeringId, input: values } })
                }
              />
            )}
          </SetupForm.Table>
          {!addNew && (
            <StyledLinkButton
              fullWidth={false}
              iconLeft={{ name: 'plus' }}
              onClick={() => handleAddNew(true)}
            >
              Add Day
            </StyledLinkButton>
          )}
        </SetupForm>
        {marketing && (
          <MarketingForm
            marketing={marketing}
            loading={updatingMarketing}
            onSubmit={values =>
              updateMarketing({
                variables: {
                  offeringId,
                  input: values,
                },
              })
            }
          />
        )}
      </SetupScreen.Panel>
    </OfferingSetupPage>
  );
};

export default MarketingRoute;
