import { ToastManager } from '@cmg/common';
import React from 'react';

import {
  useFilingConfirmationModal,
  UseFilingConfirmationModalResult,
} from './useFilingConfirmationModal';

export type Props = {
  filingName: string;
  filingShortName: string;
  onResubmit: () => Promise<void>;
};

export function useResubmitFilingConfirmationModal({
  filingName,
  filingShortName,
  onResubmit,
}: Props): UseFilingConfirmationModalResult {
  const confirm = React.useCallback(async () => {
    try {
      await onResubmit();
      ToastManager.success(`${filingShortName} Filing resubmitted successfully.`);
    } catch {
      ToastManager.error(`Resubmit ${filingShortName} Filing failed.`);
    }
  }, [filingShortName, onResubmit]);

  return useFilingConfirmationModal({
    filingName,
    action: 'RESUBMIT',
    onConfirm: confirm,
  });
}
