import { Skeleton } from '@cmg/design-system';
import React from 'react';

import { RelationshipType, RolodexRecordStatus } from '../../../../../../../graphql';
import { IndicationWithDemandLevels } from '../../types';
import { SLabel, StyledPopover, StyledRemainingEmployees } from './SalesCoverageCell.styles';

type Props = {
  data: IndicationWithDemandLevels;
};

export const getAllEmployees = (
  investorEmployeeRelationships: IndicationWithDemandLevels['investorEmployeeRelationships']
) => {
  const primaryEmployees = getEmployeeNamesByRelationshipType(
    investorEmployeeRelationships,
    RelationshipType.Primary
  );
  const secondaryEmployees = getEmployeeNamesByRelationshipType(
    investorEmployeeRelationships,
    RelationshipType.Secondary
  );

  return [...primaryEmployees, ...secondaryEmployees];
};

const getEmployeeNamesByRelationshipType = (
  investorEmployeeRelationships: IndicationWithDemandLevels['investorEmployeeRelationships'],
  relationshipType: RelationshipType
) => {
  return investorEmployeeRelationships
    ? investorEmployeeRelationships
        .filter(
          employee =>
            employee.relationshipType === relationshipType &&
            employee.status !== RolodexRecordStatus.Deleted &&
            !!employee.underwriterEmployeeName
        )
        .map(employee => employee.underwriterEmployeeName)
        .sort((a, b) => a!.localeCompare(b!))
    : [];
};

/**
 * Renders the Sales Coverage Employee Relationship Name Cell within the Institutional Demand Grid.
 */
const SalesCoverageCell: React.FC<Props> = ({ data }) => {
  const allEmployees = React.useMemo(() => {
    return getAllEmployees(data?.investorEmployeeRelationships);
  }, [data.investorEmployeeRelationships]);

  if (data?.investorEmployeeRelationships === undefined) {
    return <Skeleton role="loading" variant="rectangular" height={10} />;
  }

  const allEmployeeNames = allEmployees.map((underwriterEmployeeName, index) => (
    <div key={index}>{underwriterEmployeeName}</div>
  ));

  const employeesCount = allEmployees.length;

  if (!employeesCount) {
    return null;
  }

  return (
    <StyledPopover content={allEmployeeNames} variant="DARK" placement="topLeft">
      <SLabel>
        {allEmployees[0]}
        {employeesCount > 1 && (
          <StyledRemainingEmployees> &nbsp;+{employeesCount - 1}...</StyledRemainingEmployees>
        )}
      </SLabel>
    </StyledPopover>
  );
};

export default SalesCoverageCell;
