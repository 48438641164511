import {
  SyndicateWires_UpdatePaymentWireMutation,
  useSyndicateWires_UpdatePaymentWireMutation,
} from '../graphql';

export type UpdatePaymentWireMutation = SyndicateWires_UpdatePaymentWireMutation;

export const useUpdatePaymentWireMutation = () => {
  return useSyndicateWires_UpdatePaymentWireMutation();
};
