import styled from 'styled-components/macro';

export const STitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const STitleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: baseline;
  padding: 8px 16px;
  margin-bottom: 2px;
`;
