export const notificationApi = {
  getPermission: () => Notification.permission,
  requestPermission: async () => await Notification.requestPermission(),
  onPermissionChange: function (callback: (permission: NotificationPermission) => void) {
    navigator.permissions.query({ name: 'notifications' }).then(permissionStatus => {
      permissionStatus.onchange = () => {
        callback(this.getPermission());
      };
    });

    return () => {
      navigator.permissions.query({ name: 'notifications' }).then(permissionStatus => {
        permissionStatus.onchange = null;
      });
    };
  },
  isSupported: () =>
    'Notification' in global && 'navigator' in global && 'permissions' in navigator,
};
