import styled from 'styled-components/macro';

export const SText = styled.div`
  color: ${({ theme }) => theme.text.color.darkGray};
`;

export const SSubText = styled.div`
  color: ${({ theme }) => theme.designSystem.colors.gray[500]};
  font-size: ${({ theme }) => theme.text.size.xs};
`;
