import { UUID } from '@cmg/common';
import { Box, Divider, PageHeader, PageLayout } from '@cmg/design-system';
import React from 'react';

import { ExportContextProvider } from '../../components/export-button/ExportContext';
import { OrderBook_MyRetailSummaryPartsFragment } from '../graphql';
import AutoSaveInternalRetailDemand from './auto-save/AutoSaveInternalRetailDemand';
import BulkSaveInternalRetailDemand from './bulk-save/BulkSaveInternalRetailDemand';
import { useExportMyRetailDemand } from './hooks/useExportMyRetailDemand';
import MyRetailDemandInfoBox from './info-box/MyRetailDemandInfoBox';

export type Props = Readonly<{
  retailSummary: OrderBook_MyRetailSummaryPartsFragment;
  offeringId: UUID;
  instrumentId: UUID;
  trancheId: UUID;
  offeringIssuerName: string;
}>;

const MyRetailDemandPageLayout: React.FC<Props> = ({
  retailSummary,
  offeringId,
  instrumentId,
  trancheId,
  offeringIssuerName,
}) => {
  const { handleExport, loading: isExporting } = useExportMyRetailDemand({
    offeringId,
    offeringIssuerName,
  });

  const isUsingAutoSave = React.useMemo(
    () => !retailSummary.totalRetailInfo.submittedRetailDemand,
    [retailSummary]
  );

  return (
    <div>
      <PageLayout
        header={
          <React.Fragment>
            <PageHeader pageTitle="Total Demand & Retention" />
            <Divider />
          </React.Fragment>
        }
      >
        <Box py={2} maxWidth={theme => theme.spacing(85)}>
          <MyRetailDemandInfoBox summaryInfo={retailSummary.totalRetailInfo} />
        </Box>
      </PageLayout>
      <ExportContextProvider isExporting={isExporting} onExport={handleExport}>
        {isUsingAutoSave ? (
          <AutoSaveInternalRetailDemand
            brokers={retailSummary.brokerDealers}
            sellingConcession={retailSummary.sellingConcession}
            offeringId={offeringId}
            instrumentId={instrumentId}
            trancheId={trancheId}
          />
        ) : (
          <BulkSaveInternalRetailDemand
            brokers={retailSummary.brokerDealers}
            sellingConcession={retailSummary.sellingConcession}
            offeringId={offeringId}
            instrumentId={instrumentId}
            trancheId={trancheId}
          />
        )}
      </ExportContextProvider>
    </div>
  );
};

export default MyRetailDemandPageLayout;
