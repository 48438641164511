import { numericUtil, UUID } from '@cmg/common';
import { FormikErrors } from 'formik';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { getFormikErrorFieldLabels } from '../../../../../../common/util/useFormikErrors.model';
import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import { getPnLValues, PnLValues } from '../../common/utils';
import {
  BrokerValues,
  getInitialValues,
  getValidationSchema,
} from '../table-row/InternalRetailDemandTableRow.model';

const { sum } = numericUtil;

const calculateTotals = (values: (BrokerValues & PnLValues)[]) => {
  const demandShares = sum(...values.map(({ demandShares }) => demandShares));
  const demandRoundLots = sum(...values.map(({ demandRoundLots }) => demandRoundLots));
  const retentionShares = sum(...values.map(({ retentionShares }) => retentionShares));
  const retentionRoundLots = sum(...values.map(({ retentionRoundLots }) => retentionRoundLots));
  const retainedByTheBank = sum(...values.map(({ retainedByTheBank }) => retainedByTheBank));

  return { demandShares, demandRoundLots, retentionShares, retentionRoundLots, retainedByTheBank };
};

export const getInvalidFields = (values: RowBrokerValues[]) => {
  if (values.length === 0) {
    return;
  }

  const brokers = values.map(({ broker }) => broker);
  const validationSchema = getValidationSchema(brokers);
  const aggregatedErrorFieldNames = values.map(({ errors }) =>
    getFormikErrorFieldLabels(errors, validationSchema)
  );

  return uniq(flatten(aggregatedErrorFieldNames)).join(', ');
};

export type RowBrokerValues = BrokerValues &
  PnLValues & {
    rowId: UUID;
    errors: FormikErrors<BrokerValues>;
  };

export const useAggregateFormsValues = (
  initialValues: readonly OrderBook_RetailBrokerDealerPartsFragment[],
  sellingConcession: number | null | undefined
) => {
  const [values, setValues] = React.useState<RowBrokerValues[]>(
    initialValues.map(value => {
      const initialValues = getInitialValues(value);

      return {
        ...initialValues,
        ...getPnLValues({
          sellingConcession,
          retentionShares: value.retention.shareQuantity,
          sellingConcessionPercentage: value.sellingConcessionPercentage,
        }),
        rowId: value.id,
        errors: {},
      };
    })
  );

  const removeValue = React.useCallback(
    (removedAt: number) => {
      setValues(values.filter((_, index) => index !== removedAt));
    },
    [values]
  );

  const createValue = React.useCallback(() => {
    const emptyValue: RowBrokerValues = {
      rowId: uuidV4(),
      errors: {},
      id: null,
      broker: null,
      sellingConcessionPercentage: null,
      retentionShares: null,
      retentionRoundLots: null,
      demandShares: null,
      demandRoundLots: null,
      perShare: null,
      retainedByTheBank: null,
      receivedByTheFirm: null,
    };

    setValues([...values, emptyValue]);
  }, [values]);

  const reCalculatePnLValues = React.useCallback(
    (rowValues: BrokerValues) => {
      return getPnLValues({
        sellingConcession,
        sellingConcessionPercentage: rowValues.sellingConcessionPercentage,
        retentionShares: rowValues.retentionShares,
      });
    },
    [sellingConcession]
  );

  const setValue = React.useCallback(
    (
      rowIndex: number,
      rowValue: BrokerValues,
      errors: FormikErrors<BrokerValues>,
      reCalculatePnl: boolean
    ) => {
      const newPnLValues = reCalculatePnl ? reCalculatePnLValues(rowValue) : {};
      const newValue = {
        ...values[rowIndex],
        ...newPnLValues,
        ...rowValue,
        errors,
      };

      if (isEqual(values[rowIndex], newValue)) {
        return;
      }

      setValues(values.map((value, index) => (index !== rowIndex ? value : newValue)));
    },
    [reCalculatePnLValues, values]
  );

  const totals = React.useMemo(() => calculateTotals(values), [values]);

  const isAllValid = React.useMemo(() => values.every(({ errors }) => isEmpty(errors)), [values]);

  const invalidFields = React.useMemo(() => getInvalidFields(values), [values]);

  return { values, totals, isAllValid, invalidFields, setValue, createValue, removeValue };
};
