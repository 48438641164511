/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOfferings_MyPublishedOfferingsQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
  onPlatformOnly: Types.Scalars['Boolean'];
}>;

export type MyOfferings_MyPublishedOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly myPublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly stage: Types.OfferingStage;
      readonly issuerName: string;
      readonly pricingDate?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly issuerSector?: Types.Sector | null;
      readonly hasUnpublishedChanges?: boolean | null;
      readonly isAuthor: boolean;
      readonly revisedFilingsCount?: number | null;
      readonly isUsOffering?: boolean | null;
      readonly isExecutingOnPlatform: boolean;
      readonly pricingInstrumentCountryCode?: string | null;
    }>;
    readonly offeringTypes: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly pricingInstrumentCountryCode?: string | null;
    }>;
    readonly offeringStatuses: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly status: Types.OfferingStatus;
    }>;
    readonly issuerSectors: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly issuerSector?: Types.Sector | null;
    }>;
  };
};

export type MyOfferings_PublishedOfferingsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.OfferingFullFilterInput>;
}>;

export type MyOfferings_PublishedOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly publishedOfferings?: {
    readonly __typename?: 'PublishedOfferingsCollectionSegment';
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly stage: Types.OfferingStage;
      readonly isExecutingOnPlatform: boolean;
      readonly isAuthor?: boolean | null;
      readonly hasUnpublishedChanges?: boolean | null;
      readonly pricingInstrumentId?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly issuer: {
        readonly __typename?: 'IssuerFull';
        readonly name: string;
        readonly sector?: Types.Sector | null;
      };
      readonly timing: { readonly __typename?: 'TimingFull'; readonly pricingDate?: string | null };
      readonly terms: {
        readonly __typename?: 'TermsFull';
        readonly filings: ReadonlyArray<{
          readonly __typename?: 'FilingFull';
          readonly filingType: Types.FilingType;
          readonly id: string;
        }>;
      };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly id: string;
        readonly countryCode?: string | null;
      }>;
    }> | null;
  } | null;
};

export const MyOfferings_MyPublishedOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_MyPublishedOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'onPlatformOnly' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myPublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onPlatformOnly' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'onPlatformOnly' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnpublishedChanges' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revisedFilingsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUsOffering' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentCountryCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOfferings_Filters_OfferingListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOfferings_Filters_OfferingListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringTypes' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringStatuses' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'issuerSectors' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_MyPublishedOfferingsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_MyPublishedOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_MyPublishedOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_MyPublishedOfferingsQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      onPlatformOnly: // value for 'onPlatformOnly'
 *   },
 * });
 */
export function useMyOfferings_MyPublishedOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOfferings_MyPublishedOfferingsQuery,
    MyOfferings_MyPublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOfferings_MyPublishedOfferingsQuery,
    MyOfferings_MyPublishedOfferingsQueryVariables
  >(MyOfferings_MyPublishedOfferingsDocument, options);
}
export function useMyOfferings_MyPublishedOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_MyPublishedOfferingsQuery,
    MyOfferings_MyPublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOfferings_MyPublishedOfferingsQuery,
    MyOfferings_MyPublishedOfferingsQueryVariables
  >(MyOfferings_MyPublishedOfferingsDocument, options);
}
export type MyOfferings_MyPublishedOfferingsQueryHookResult = ReturnType<
  typeof useMyOfferings_MyPublishedOfferingsQuery
>;
export type MyOfferings_MyPublishedOfferingsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_MyPublishedOfferingsLazyQuery
>;
export type MyOfferings_MyPublishedOfferingsQueryResult = Apollo.QueryResult<
  MyOfferings_MyPublishedOfferingsQuery,
  MyOfferings_MyPublishedOfferingsQueryVariables
>;
export const MyOfferings_PublishedOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_PublishedOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFullFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnpublishedChanges' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timing' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'terms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instruments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_PublishedOfferingsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_PublishedOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_PublishedOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_PublishedOfferingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMyOfferings_PublishedOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyOfferings_PublishedOfferingsQuery,
    MyOfferings_PublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOfferings_PublishedOfferingsQuery,
    MyOfferings_PublishedOfferingsQueryVariables
  >(MyOfferings_PublishedOfferingsDocument, options);
}
export function useMyOfferings_PublishedOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_PublishedOfferingsQuery,
    MyOfferings_PublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOfferings_PublishedOfferingsQuery,
    MyOfferings_PublishedOfferingsQueryVariables
  >(MyOfferings_PublishedOfferingsDocument, options);
}
export type MyOfferings_PublishedOfferingsQueryHookResult = ReturnType<
  typeof useMyOfferings_PublishedOfferingsQuery
>;
export type MyOfferings_PublishedOfferingsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_PublishedOfferingsLazyQuery
>;
export type MyOfferings_PublishedOfferingsQueryResult = Apollo.QueryResult<
  MyOfferings_PublishedOfferingsQuery,
  MyOfferings_PublishedOfferingsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeMyOfferings_MyPublishedOfferingsMockResponse = makeMockResponse<
  MyOfferings_MyPublishedOfferingsQueryVariables,
  MyOfferings_MyPublishedOfferingsQuery
>(MyOfferings_MyPublishedOfferingsDocument);

export const makeMyOfferings_PublishedOfferingsMockResponse = makeMockResponse<
  MyOfferings_PublishedOfferingsQueryVariables,
  MyOfferings_PublishedOfferingsQuery
>(MyOfferings_PublishedOfferingsDocument);
