import {
  LinkButton,
  ODSTable as Table,
  PrimaryButton,
  SecondaryButton,
  ToastManager,
  UUID,
} from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';

import roadshowCompanyZeroState from '../../../../../../common/assets/roadshow-company-zero-state.svg';
import { HeroGraphics } from '../../../../../../common/components/layout/hero-graphics/HeroGraphics';
import Panel from '../../../../../../common/components/layout/panel/Panel';
import Modal from '../../../../../../common/components/overlays/modal/Modal';
import { SContentWrapper } from '../empty-company-details/EmptyCompanyDetails.styles';
import { useAssignCompanyMutation } from '../hooks/useAssignCompanyMutation';
import CompanySearchField from './company-search-field/CompanySearchField';
import { Roadshows_CompanySearchPartsFragment } from './company-search-field/graphql';
import {
  SEmptyFiller,
  STableWrapper,
  StyledLoading,
  StyledModalFooter,
} from './CompanySearchModal.styles';
import { useRoadshows_ImportCompanyMutation } from './graphql';

export type Props = {
  show: boolean;
  onHide: () => void;
  onOpenCreateCompanyModal: () => void;
  roadshowId: UUID;
};

const CompanySearchModal: React.FC<Props> = ({
  show,
  onHide,
  onOpenCreateCompanyModal,
  roadshowId,
}) => {
  const [companies, setCompanies] = React.useState<readonly Roadshows_CompanySearchPartsFragment[]>(
    []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCompany, setSelectedCompany] =
    React.useState<Roadshows_CompanySearchPartsFragment | null>(null);
  const [companySearchValue, setCompanySearchValue] = React.useState('');

  const [assignCompany, { loading: isAssigningCompany }] = useAssignCompanyMutation();
  const [importCompany, { loading: isImportingCompany }] = useRoadshows_ImportCompanyMutation();

  const handleSelectCompany = (company: Roadshows_CompanySearchPartsFragment) => {
    setSelectedCompany(company);
  };

  const handleOnCompanySearchChange = (value: string) => {
    setSelectedCompany(null);
    setCompanySearchValue(value);
  };

  const handleHide = () => {
    setCompanies([]);
    setSelectedCompany(null);
    setCompanySearchValue('');
    onHide();
  };

  const handleAddCompany = async () => {
    if (!selectedCompany) {
      return;
    }

    try {
      const { data: companyData } = await importCompany({
        variables: {
          cmgEntityKey: selectedCompany?.cmgEntityKey,
          factSetFirmId: selectedCompany?.factSetFirmId,
        },
      });

      if (companyData) {
        await assignCompany({
          variables: {
            roadshowId,
            cmgEntityKey: companyData.importCompany.id,
          },
        });
      }
    } catch (error) {
      ToastManager.error('Failed to assign Company to Roadshow');
    }
  };

  return (
    <Modal
      show={show}
      title="Search and Add Company"
      size="xlarge"
      shouldCloseOnOverlayClick={false}
      testId="company-search-modal"
    >
      <Modal.Content>
        <Panel>
          <Panel.Header title="Company" />
          <Panel.Content paddingSize="MODAL">
            <CompanySearchField
              onSetCompanies={setCompanies}
              onSetLoading={setIsLoading}
              onChange={handleOnCompanySearchChange}
            />
          </Panel.Content>
        </Panel>
        <Panel>
          <Panel.Header title="Search Results" />
          <Panel.Content paddingSize="MODAL">
            <STableWrapper>
              {isLoading && <StyledLoading />}
              {!isLoading && companies.length > 0 && (
                <Table gridTemplateColumns="1fr" data-test-id="company-search-table">
                  <Table.Row>
                    <Table.TableHeaderCell>Company Name</Table.TableHeaderCell>
                  </Table.Row>
                  {companies.map(company => (
                    <Table.Row
                      key={company.cmgEntityKey}
                      onClick={() => handleSelectCompany(company)}
                      isFocused={selectedCompany?.cmgEntityKey === company.cmgEntityKey}
                    >
                      <Table.Cell>{company.name}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table>
              )}
              {!isLoading && companies.length === 0 && (
                <SContentWrapper>
                  <HeroGraphics
                    image={roadshowCompanyZeroState}
                    imageAlt="Roadshow Company"
                    subtitle={
                      companySearchValue
                        ? "Sorry, we couldn't find this company"
                        : 'Search and select company to add to Roadshow Project'
                    }
                  >
                    {!!companySearchValue && (
                      <LinkButton
                        testId={
                          xcSelectors.roadshowManagementTeamConfigSearchModalResultsNewCompanyButton
                            .testId
                        }
                        onClick={onOpenCreateCompanyModal}
                        inline
                      >
                        Create new Company
                      </LinkButton>
                    )}
                  </HeroGraphics>
                </SContentWrapper>
              )}
            </STableWrapper>
          </Panel.Content>
        </Panel>
      </Modal.Content>

      <StyledModalFooter>
        <LinkButton
          testId={xcSelectors.roadshowManagementTeamConfigSearchModalNewCompanyButton.testId}
          onClick={onOpenCreateCompanyModal}
        >
          + Create new Company
        </LinkButton>
        <SEmptyFiller />
        <SecondaryButton
          testId={xcSelectors.roadshowManagementTeamConfigSearchModalCancelButton.testId}
          onClick={handleHide}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          testId={xcSelectors.roadshowManagementTeamConfigSearchModalAddButton.testId}
          disabled={!selectedCompany || isAssigningCompany || isImportingCompany}
          loading={isAssigningCompany || isImportingCompany}
          onClick={handleAddCompany}
        >
          Add Company
        </PrimaryButton>
      </StyledModalFooter>
    </Modal>
  );
};

export default CompanySearchModal;
