import { ButtonsContainer, FormLabel, SecondaryButton, ToastManager, UUID } from '@cmg/common';
import { LoadingButton } from '@cmg/design-system';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import Modal from '../../../../../../../common/components/overlays/modal/Modal';
import { InstitutionalDemandGrid_ColumnConfigQuery } from '../../graphql';
import BDAgentFormComponent from '../../grid-columns/bd-agent-cell/BDAgentForm';
import { SBDAgentFormWrapper, SPrompt } from './AssignBillingAndDeliveryAgentModal.styles';
import { useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation } from './graphql';

export type OwnProps = {
  selectedIndicationIds: string[];
  managers: readonly (
    | InstitutionalDemandGrid_ColumnConfigQuery['publishedOffering']['syndicate']['managers'][number]
    | NonNullable<
        InstitutionalDemandGrid_ColumnConfigQuery['syndicateManagers']
      >['managers'][number]
  )[];
  offeringId: UUID;
};

export type Props = OwnProps & InjectedProps;

export const AssignBillingAndDeliveryAgentForIndicationsModal: React.FC<Props> = ({
  handleHide,
  selectedIndicationIds,
  offeringId,
  managers,
}) => {
  const [selectedBDAgent, setSelectedBDAgentKey] = React.useState<string | null>(null);
  const [updateBillingAndDeliveryAgentBatchMutation, { loading }] =
    useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentForIndicationsMutation();

  const handleSave = async () => {
    try {
      await updateBillingAndDeliveryAgentBatchMutation({
        variables: {
          offeringId,
          input: {
            indicationIds: selectedIndicationIds,
            billingAndDeliveryAgentKey: selectedBDAgent !== 'Unassigned' ? selectedBDAgent : null,
          },
        },
      });
      ToastManager.success(`B&D Agent assigned for ${selectedIndicationIds.length} investors.`);
      handleHide();
    } catch {
      ToastManager.error('Failed to assign B&D Agent.');
    }
  };

  const managerMap = managers.reduce<Record<string, Props['managers'][number]>>((acc, manager) => {
    return { ...acc, [manager.cmgEntityKey]: manager };
  }, {});

  return (
    <Modal
      show
      title="Assign Billing & Delivery Agent"
      onHide={handleHide}
      shouldCloseOnOverlayClick={false}
      size="small"
    >
      <Modal.Content>
        <SPrompt>Please select the manager to designate as the B&D Agent:</SPrompt>
        <FormLabel> B&D Agents </FormLabel>
        <SBDAgentFormWrapper>
          <BDAgentFormComponent
            managers={managerMap}
            offeringId={offeringId}
            billingAndDeliveryAgentCmgEntityKey=""
            loading={false}
            onChange={values => {
              setSelectedBDAgentKey(values.billingAndDeliveryAgentCmgEntityKey);
            }}
          />
        </SBDAgentFormWrapper>
      </Modal.Content>
      <Modal.Footer>
        <ButtonsContainer justifyContent="right" margin={16}>
          <SecondaryButton onClick={handleHide} testId="cancel-button" disabled={loading}>
            Cancel
          </SecondaryButton>
          <LoadingButton onClick={handleSave} loading={loading} variant="contained">
            Save
          </LoadingButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  );
};

const name = 'ORDERBOOK/ASSIGN_BILLING_AND_DELIVERY_AGENT_FOR_INDICAITONS_MODAL';
export const openAssignBillingAndDeliveryAgentForIndicationsModal = () => show(name);
export const closeAssignBillingAndDeliveryAgentForIndicationsModal = () => hide(name);

export default connectModal({ name })(
  AssignBillingAndDeliveryAgentForIndicationsModal
) as React.ComponentClass<OwnProps>;
