import { ApolloCache } from '@apollo/client';

import { Roadshows_AssignCompanyMutation, useRoadshows_AssignCompanyMutation } from '../graphql';

export type AssignCompanyMutation = Roadshows_AssignCompanyMutation;

export const updateCache = (cache: ApolloCache<AssignCompanyMutation>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'roadshowManagementTeam' });
};

export const useAssignCompanyMutation = () => {
  return useRoadshows_AssignCompanyMutation({
    // reset the cache of management team
    update: updateCache,
  });
};
