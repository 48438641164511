import { assets, Popover } from '@cmg/common';
import React from 'react';

import { getFeatureToggles } from '../../../../common/config/appSettings';
import { IndicationStatus } from '../../../../graphql';
import DuplicateIcon from '../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/company-name-cell/DuplicateIcon';
import SurvivingIcon from '../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/company-name-cell/SurvivingIcon';
import { MyOrdersGridRendererProps } from '../my-orders-grid/MyOrdersGrid.model';
import {
  SInvestorRenderWrapper,
  StyledInvestorName,
  StyledInvestorNameLinkWithoutLogo,
  StyledOnPlatform,
} from './InvestorNameRenderer.styles';

export const InvestorNameRenderer: React.FC<MyOrdersGridRendererProps> = ({ data }) => {
  const { isOrderBookDupesOn } = getFeatureToggles();
  const isMarkedAsDuplicate = data.status === IndicationStatus.Duplicate;
  const isOnPlatform = data.investorInformation.isOnPlatform;

  return (
    <SInvestorRenderWrapper>
      {isOnPlatform && (
        <Popover
          content="This investor is on the CMG platform."
          variant="DARK"
          placement="topLeft"
          arrowPointAtCenter
        >
          <span>
            <StyledOnPlatform src={assets.logoNoText} alt="This investor is on the CMG platform." />
          </span>
        </Popover>
      )}
      {isOnPlatform ? (
        <StyledInvestorName>
          {data?.investorInformation?.bankInvestorName ?? data?.investorInformation?.cmgEntityName}
        </StyledInvestorName>
      ) : (
        <StyledInvestorNameLinkWithoutLogo>
          {data?.investorInformation?.bankInvestorName ?? data?.investorInformation?.cmgEntityName}
        </StyledInvestorNameLinkWithoutLogo>
      )}
      {isOrderBookDupesOn && isMarkedAsDuplicate && (
        <DuplicateIcon duplicateOfInvestorName={null}>D</DuplicateIcon>
      )}
      {data.duplicateIndicationIds.length > 0 && <SurvivingIcon />}
    </SInvestorRenderWrapper>
  );
};
