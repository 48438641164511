import { useAuth } from '@cmg/auth';
import { Popover } from '@cmg/common';
import React from 'react';

import { IndicationStatus } from '../../../../../graphql';
import {
  SAcknowledgement,
  SAcknowledgementLayout,
  SDuplicateAcknowledgement,
} from '../../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/acknowledgements-cell/IndicationAcknowledgementsCell.styles';
import MyOrdersIndicationAcknowlegment from '../../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/acknowledgements-cell/MyOrdersIndicationAcknowlegment';
import {
  getAcknowledgementsList,
  getAcknowledgmentPopoverContent,
} from '../../../../order-book/institutional-demand/components/institutional-demand-grid/grid-columns/acknowledgements-cell/utils';
import { MyOrdersGridCellProps, PricedAndLiveOfferings } from '../MyOrdersGrid.model';

export const IndicationAcknowledgementCell: React.FC<MyOrdersGridCellProps> = ({
  data,
  context,
}) => {
  const { oidcUserCmgEntityKey } = useAuth();
  const offering = context.offerings[data.offeringId];
  const managers = React.useMemo(() => {
    return offering?.managers.reduce<Record<string, PricedAndLiveOfferings['managers'][number]>>(
      (acc, curr) => {
        return { ...acc, [curr.cmgEntityKey]: curr };
      },
      {}
    );
  }, [offering]);

  const { institutionalIndicationAcknowledgements, version: currentIndicationVersion } = data;
  const acknowledgementsList = getAcknowledgementsList(
    institutionalIndicationAcknowledgements,
    currentIndicationVersion,
    oidcUserCmgEntityKey
  );

  const popOverContent = React.useMemo(
    () => getAcknowledgmentPopoverContent(acknowledgementsList, managers),
    [acknowledgementsList, managers]
  );

  if (!managers || data.status === IndicationStatus.Duplicate) {
    return <SDuplicateAcknowledgement>-</SDuplicateAcknowledgement>;
  }

  return (
    <Popover content={popOverContent} variant="DARK" placement="topLeft">
      <SAcknowledgementLayout>
        <SAcknowledgement>
          {acknowledgementsList.map((current, index) => {
            return (
              <span key={`acknowledgement-${current.indicationId}-${current.managerCmgEntityKey}`}>
                <MyOrdersIndicationAcknowlegment
                  currentIndicationVersion={currentIndicationVersion}
                  acknowledgment={current}
                  syndicateManagers={managers}
                  isUsersFirm={current.managerCmgEntityKey === oidcUserCmgEntityKey}
                  numberOfAcknowledgements={acknowledgementsList.length}
                  index={index}
                />
              </span>
            );
          })}
        </SAcknowledgement>
      </SAcknowledgementLayout>
    </Popover>
  );
};
