import { PrimaryButton } from '@cmg/common';
import styled from 'styled-components/macro';

export const SModalContent = styled.div`
  margin: 0 32px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 16px;
`;
