/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InternalDemandSummary_MdlIndicationPartsFragment = {
  readonly __typename?: 'MdlIndication';
  readonly unitType: Types.MdlIndicationUnitType;
  readonly pricingType: Types.MdlIndicationPricingType;
  readonly limitPrice?: number | null;
  readonly limitType: Types.MdlIndicationLimitType;
  readonly limitPercentage?: number | null;
  readonly realDemandDollars?: number | null;
  readonly realDemandPercentage?: number | null;
  readonly realDemandShares?: number | null;
};

export type InternalDemandSummary_InternalIndicationsDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type InternalDemandSummary_InternalIndicationsDemandQuery = {
  readonly __typename?: 'Query';
  readonly internalIndicationsDemand: {
    readonly __typename?: 'MdlInternalDemandIndications';
    readonly id: string;
    readonly indications: ReadonlyArray<{
      readonly __typename?: 'MdlIndication';
      readonly unitType: Types.MdlIndicationUnitType;
      readonly pricingType: Types.MdlIndicationPricingType;
      readonly limitPrice?: number | null;
      readonly limitType: Types.MdlIndicationLimitType;
      readonly limitPercentage?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
    }>;
  };
};

export type InternalDemandSummary_InternalAllocationDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type InternalDemandSummary_InternalAllocationDemandQuery = {
  readonly __typename?: 'Query';
  readonly internalAllocationDemand: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly ioiType: Types.MdlAllocationIoiType;
    } | null;
  };
};

export const InternalDemandSummary_MdlIndicationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InternalDemandSummary_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InternalDemandSummary_InternalIndicationsDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InternalDemandSummary_InternalIndicationsDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalIndicationsDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'indications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InternalDemandSummary_MdlIndicationParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InternalDemandSummary_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInternalDemandSummary_InternalIndicationsDemandQuery__
 *
 * To run a query within a React component, call `useInternalDemandSummary_InternalIndicationsDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalDemandSummary_InternalIndicationsDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalDemandSummary_InternalIndicationsDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useInternalDemandSummary_InternalIndicationsDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    InternalDemandSummary_InternalIndicationsDemandQuery,
    InternalDemandSummary_InternalIndicationsDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InternalDemandSummary_InternalIndicationsDemandQuery,
    InternalDemandSummary_InternalIndicationsDemandQueryVariables
  >(InternalDemandSummary_InternalIndicationsDemandDocument, options);
}
export function useInternalDemandSummary_InternalIndicationsDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalDemandSummary_InternalIndicationsDemandQuery,
    InternalDemandSummary_InternalIndicationsDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InternalDemandSummary_InternalIndicationsDemandQuery,
    InternalDemandSummary_InternalIndicationsDemandQueryVariables
  >(InternalDemandSummary_InternalIndicationsDemandDocument, options);
}
export type InternalDemandSummary_InternalIndicationsDemandQueryHookResult = ReturnType<
  typeof useInternalDemandSummary_InternalIndicationsDemandQuery
>;
export type InternalDemandSummary_InternalIndicationsDemandLazyQueryHookResult = ReturnType<
  typeof useInternalDemandSummary_InternalIndicationsDemandLazyQuery
>;
export type InternalDemandSummary_InternalIndicationsDemandQueryResult = Apollo.QueryResult<
  InternalDemandSummary_InternalIndicationsDemandQuery,
  InternalDemandSummary_InternalIndicationsDemandQueryVariables
>;
export const InternalDemandSummary_InternalAllocationDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InternalDemandSummary_InternalAllocationDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalAllocationDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ioiType' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInternalDemandSummary_InternalAllocationDemandQuery__
 *
 * To run a query within a React component, call `useInternalDemandSummary_InternalAllocationDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalDemandSummary_InternalAllocationDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalDemandSummary_InternalAllocationDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useInternalDemandSummary_InternalAllocationDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    InternalDemandSummary_InternalAllocationDemandQuery,
    InternalDemandSummary_InternalAllocationDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InternalDemandSummary_InternalAllocationDemandQuery,
    InternalDemandSummary_InternalAllocationDemandQueryVariables
  >(InternalDemandSummary_InternalAllocationDemandDocument, options);
}
export function useInternalDemandSummary_InternalAllocationDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalDemandSummary_InternalAllocationDemandQuery,
    InternalDemandSummary_InternalAllocationDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InternalDemandSummary_InternalAllocationDemandQuery,
    InternalDemandSummary_InternalAllocationDemandQueryVariables
  >(InternalDemandSummary_InternalAllocationDemandDocument, options);
}
export type InternalDemandSummary_InternalAllocationDemandQueryHookResult = ReturnType<
  typeof useInternalDemandSummary_InternalAllocationDemandQuery
>;
export type InternalDemandSummary_InternalAllocationDemandLazyQueryHookResult = ReturnType<
  typeof useInternalDemandSummary_InternalAllocationDemandLazyQuery
>;
export type InternalDemandSummary_InternalAllocationDemandQueryResult = Apollo.QueryResult<
  InternalDemandSummary_InternalAllocationDemandQuery,
  InternalDemandSummary_InternalAllocationDemandQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeInternalDemandSummary_InternalIndicationsDemandMockResponse = makeMockResponse<
  InternalDemandSummary_InternalIndicationsDemandQueryVariables,
  InternalDemandSummary_InternalIndicationsDemandQuery
>(InternalDemandSummary_InternalIndicationsDemandDocument);

export const makeInternalDemandSummary_InternalAllocationDemandMockResponse = makeMockResponse<
  InternalDemandSummary_InternalAllocationDemandQueryVariables,
  InternalDemandSummary_InternalAllocationDemandQuery
>(InternalDemandSummary_InternalAllocationDemandDocument);
