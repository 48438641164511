import React from 'react';
import styled from 'styled-components/macro';

import InvitationResponsePanel from '../invitation-response-panel/InvitationResponsePanel';

type Props = {
  senderEmails: readonly string[];
};

export const SMessage = styled.span`
  margin-top: 40px;
`;

export const NewVersionPanel: React.FC<Props> = ({ senderEmails }) => {
  const emailList = senderEmails.join(',');
  return (
    <InvitationResponsePanel variant="info" title="There is a new version of this wire.">
      <SMessage>
        Haven’t received an email? <a href={`mailto:${emailList}`}>Contact Sender</a>
      </SMessage>
    </InvitationResponsePanel>
  );
};
