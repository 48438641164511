import isNil from 'lodash/isNil';
import React from 'react';

import { generatePayloadDateTimeData } from '../../../../../common/util/payload-timestamp';
import routeFactory from '../../../../../common/util/routeFactory';
import {
  NonSyndicateSellingGroupInvitationDataForRecipient,
  OfferingType,
  WireTemplateType,
} from '../../../../../graphql';
import { createUseDefaultSendIsDisabledReason } from '../../common/context/hooks/useDefaultSendIsDisabledReason';
import {
  createUseDefaultNonSyndicateManagers,
  createUseDefaultNonSyndicateNextRecipients,
  createWireTypeConfig,
  WireTypeConfigInitialValues,
} from '../../common/context/WireTypeConfigContext.model';
import {
  SyndicateWires_MyRetailForBrokerDealerPartsFragment,
  useSyndicateWires_WiresDisclaimerQuery,
} from '../../common/graphql';
import { createUseDefaultOutdatedNonSyndicateManagersList } from '../../common/hooks/createDefaultUseOutdatedNonSyndicateManagersList';
import { createUseDefaultIsNonSyndicateWireManagerListOutdated } from '../../common/hooks/createUseDefaultIsNonSyndicateWireManagerListOutdated';
import { useBrokerDealersMyRetailQuery } from '../../common/hooks/useBrokerDealersMyRetailQuery';
import { getDisclaimer } from '../../common/utils/disclaimer';
import { getMasterDate } from '../../common/utils/masterDate';
import { SyndicateWireManager } from '../../SyndicateWiresRoute.model';
import { useSyndicateWires_MasterSdaDateQuery } from '../selling-group-invitation/graphql';
import {
  SyndicateWires_NonSyndicateSellingGroupInvitationWirePartsFragment,
  SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationPartsFragment,
  useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
  useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
  useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
  useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
} from './graphql';
import { useCreateNonSyndicateSellingGroupInvitationWireMutation } from './hooks/useCreateNonSyndicateSellingGroupInvitationWireMutation';
import { useDeleteNonSyndicateSellingGroupInvitationWireMutation } from './hooks/useDeleteNonSyndicateSellingGroupInvitationWireMutation';
import { useSendNonSyndicateSellingGroupInvitationWireMutation } from './hooks/useSendNonSyndicateSellingGroupInvitationWireMutation';
import { useUpdateNonSyndicateSellingGroupInvitationWireMutation } from './hooks/useUpdateNonSyndicateSellingGroupInvitationWireMutation';
import { NonSyndicateSellingGroupInvitationWireForm } from './NonSyndicateSellingGroupInvitationWireForm';
import {
  RetentionRecipientUserData,
  validationSchema,
  Values,
} from './NonSyndicateSellingGroupInvitationWireForm.model';

const wireTypeName = 'Selling Group Invitation';

export const getWireTemplateType = ({ offeringType }: { offeringType: OfferingType }) =>
  [OfferingType.Ipo, OfferingType.IpoSpac].includes(offeringType)
    ? WireTemplateType.NonSyndicateMemberSellingGroupInvitationIpo
    : WireTemplateType.NonSyndicateMemberSellingGroupInvitationFo;

export const getPredefinedRecipientUserData = (
  manager: SyndicateWireManager,
  initialRecipientsUserData: (RetentionRecipientUserData | null)[],
  retail: SyndicateWires_MyRetailForBrokerDealerPartsFragment[]
): RetentionRecipientUserData | null => {
  const initialManagerValues = initialRecipientsUserData.find(
    initialUserData => initialUserData?.recipient === manager.cmgEntityKey
  );

  if (!isNil(initialManagerValues?.retention)) {
    return initialManagerValues!;
  }

  const retention =
    retail.find(({ cmgEntityKey }) => cmgEntityKey === manager.cmgEntityKey)?.retentionAmount ??
    null;

  if (retention != null) {
    return {
      recipient: manager.cmgEntityKey,
      retention,
    };
  }

  return null;
};

const getRecipientInputData = (formValues: Values) => {
  const { disclaimer, masterSdaDate, recipientsUserData } = formValues;

  return recipientsUserData
    .filter(recipientUserData => recipientUserData !== null)
    .map<NonSyndicateSellingGroupInvitationDataForRecipient>(recipientUserData => {
      return {
        recipient: recipientUserData!.recipient,
        retention: recipientUserData!.retention!,
        masterSdaDate,
        disclaimer,
      };
    });
};

export const createWireTypeConfigNonSyndicateSellingGroupInvitation = ({
  offeringType,
}: {
  offeringType: OfferingType;
}) =>
  createWireTypeConfig<
    Values,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePartsFragment,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationPartsFragment,
    typeof routeFactory.nonSyndicateWiresSellingGroupInvitation
  >({
    wireTypeName,
    wireTypeRoute: routeFactory.nonSyndicateWiresSellingGroupInvitation,
    wireTemplateType: getWireTemplateType({ offeringType }),
    useSendIsDisabledReason: createUseDefaultSendIsDisabledReason({
      canBeSentForDraftOffering: true,
    }),
    useManagers: createUseDefaultNonSyndicateManagers(),
    useNextRecipients: createUseDefaultNonSyndicateNextRecipients(),
    useIsWireManagerListOutdated: createUseDefaultIsNonSyndicateWireManagerListOutdated(),
    useOutdatedManagersList: createUseDefaultOutdatedNonSyndicateManagersList(),

    useGenerateWirePreview: queryArgs => {
      const { data, loading, error } =
        useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery({
          variables: queryArgs,
        });

      return {
        data: data?.nonSyndicateSellingGroupInvitationWirePreview.htmlContent,
        error,
        isLoading: loading,
      };
    },
    useWireDetails: (queryArgs, fetchPolicy) => {
      const { data, loading, error } =
        useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery({
          variables: { ...queryArgs, syndicateWireId: queryArgs.syndicateWireId! },
          skip: !queryArgs.syndicateWireId,
          fetchPolicy: fetchPolicy ?? 'cache-and-network',
        });

      return {
        data: data && {
          wire: data.nonSyndicateSellingGroupInvitationWireDetails,
          stage: data.syndicateWires.publishedOrPrivateOffering.stage,
        },
        isLoading: loading,
        error,
      };
    },
    useWireList: ({ offeringId }) => {
      const { data, loading, error } =
        useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery({
          variables: { offeringId },
          fetchPolicy: 'cache-and-network',
        });

      return {
        data: data?.nonSyndicateSellingGroupInvitationWireList,
        isLoading: loading,
        error,
      };
    },
    useWireValidation: ({ offeringId, fetchPolicy }) => {
      const { data, loading, error, refetch } =
        useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery({
          variables: { offeringId },
          fetchPolicy: fetchPolicy ?? 'cache-and-network',
        });

      return {
        data: data?.nonSyndicateSellingGroupInvitationWireValidation,
        isLoading: loading,
        error,
        refetch,
      };
    },
    useCreateMutation: () => {
      const [createWire, { loading }] = useCreateNonSyndicateSellingGroupInvitationWireMutation();

      return {
        mutation: async ({ offeringId, values }) => {
          const result = await createWire({
            variables: {
              offeringId,
              payload: {
                userDataForRecipients: getRecipientInputData(values),
              },
            },
          });

          if (
            result.data?.createNonSyndicateSellingGroupInvitationWire.__typename === 'ServiceError'
          ) {
            throw new Error(`Creating the ${wireTypeName} wire failed.`);
          }

          return result.data?.createNonSyndicateSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },
    useUpdateMutation: () => {
      const [updateWire, { loading }] = useUpdateNonSyndicateSellingGroupInvitationWireMutation();

      return {
        mutation: async ({ offeringId, values, syndicateWireId }) => {
          const result = await updateWire({
            variables: {
              syndicateWireId,
              offeringId,
              payload: {
                userDataForRecipients: getRecipientInputData(values),
              },
            },
          });

          if (
            result.data?.updateNonSyndicateSellingGroupInvitationWire.__typename === 'ServiceError'
          ) {
            throw new Error(`Updating the ${wireTypeName} wire failed.`);
          }

          return result.data?.updateNonSyndicateSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },
    useDeleteMutation: () => {
      const [deleteWire, { loading }] = useDeleteNonSyndicateSellingGroupInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await deleteWire({ variables });

          if (
            result.data?.deleteNonSyndicateSellingGroupInvitationWire.__typename === 'ServiceError'
          ) {
            throw new Error(`Deleting the ${wireTypeName} wire failed.`);
          }
        },
        isLoading: loading,
      };
    },
    useSendMutation: () => {
      const [sendWire, { loading }] = useSendNonSyndicateSellingGroupInvitationWireMutation();

      return {
        mutation: async variables => {
          const result = await sendWire({
            variables: {
              ...variables,
              ...generatePayloadDateTimeData(),
            },
          });

          if (
            result.data?.sendNonSyndicateSellingGroupInvitationWire.__typename === 'ServiceError'
          ) {
            throw new Error(`Sending the ${wireTypeName} wire failed.`);
          }

          return result.data?.sendNonSyndicateSellingGroupInvitationWire;
        },
        isLoading: loading,
      };
    },
    CreateOrUpdateForm: NonSyndicateSellingGroupInvitationWireForm,
    // @ts-expect-error schema type mismatch on Date
    formValidation: validationSchema,
    useGetInitialValues: ({
      wire,
      managers,
      offeringId,
      offeringType,
      operationType,
      wireTemplateType,
    }) => {
      const {
        data: sdaDateData,
        loading: sdaDateLoading,
        error: sdaDateError,
      } = useSyndicateWires_MasterSdaDateQuery({
        variables: { offeringId },
        fetchPolicy: 'cache-and-network',
      });

      const {
        data: disclaimerData,
        loading: disclaimerLoading,
        error: disclaimerError,
      } = useSyndicateWires_WiresDisclaimerQuery({
        variables: {
          offeringId,
          offeringType,
          templateType: wireTemplateType,
        },
      });

      const {
        data: retailData,
        loading: retailLoading,
        error: retailError,
      } = useBrokerDealersMyRetailQuery({ offeringId });

      const initialUserData = React.useMemo(() => {
        /**
         * disclaimer and sdaDate are common manager fields so if they are populated for one manager,
         * then they have to be populated for all of them
         */
        const disclaimer = getDisclaimer(
          operationType,
          wire?.recipientsUserData[0]?.disclaimer,
          disclaimerData?.syndicateWires.wiresDisclaimer
        );

        const masterSdaDate = getMasterDate(
          operationType,
          wire?.recipientsUserData[0]?.masterSdaDate,
          sdaDateData?.syndicateWires.masterSdaDate
        );

        const initialRecipientUserData = managers.map<RetentionRecipientUserData | null>(
          manager => {
            const managerValues = wire?.recipientsUserData?.find(
              ({ recipient }) => recipient === manager.cmgEntityKey
            );

            return !managerValues
              ? null
              : {
                  recipient: managerValues.recipient,
                  retention: managerValues.retention,
                };
          }
        );

        const predefinedRecipientUserData = managers.map<RetentionRecipientUserData | null>(
          manager => {
            return getPredefinedRecipientUserData(
              manager,
              initialRecipientUserData,
              retailData ?? []
            );
          }
        );

        const initialValues: WireTypeConfigInitialValues<Values> = {
          data: {
            masterSdaDate,
            disclaimer,
            recipientsUserData: initialRecipientUserData,
          },
          predefinedData: {
            masterSdaDate,
            disclaimer,
            recipientsUserData: predefinedRecipientUserData,
          },
        };

        return initialValues;
      }, [
        operationType,
        wire?.recipientsUserData,
        disclaimerData?.syndicateWires.wiresDisclaimer,
        sdaDateData?.syndicateWires.masterSdaDate,
        managers,
        retailData,
      ]);

      const isLoading = sdaDateLoading || disclaimerLoading || retailLoading;
      const error = sdaDateError || disclaimerError || retailError;

      return {
        isLoading,
        error,
        data: initialUserData.data,
        predefinedData: initialUserData.predefinedData,
      };
    },
  });
