import { DesignationWarning } from '../../../../../../../graphql';

const applicableWarnings = [
  DesignationWarning.IndicationIsPass,
  DesignationWarning.IndicationIsCancelled,
  DesignationWarning.IndicationIsDuplicate,
  DesignationWarning.AllocationIsRejected,
  DesignationWarning.AllocationMustBeGreaterThanZero,
];

export const rowContainsNonApplicableWarning = (warnings: readonly DesignationWarning[]) => {
  return warnings.some(warning => applicableWarnings.includes(warning));
};

export const rowContainsErrors = (warnings: readonly DesignationWarning[]) => {
  return warnings.includes(DesignationWarning.DesignationsAreOutOfBalance);
};

export const getWarningReason = (warnings: readonly DesignationWarning[]) => {
  if (warnings.includes(DesignationWarning.IndicationIsPass)) {
    return 'Indication is a Pass';
  }
  if (warnings.includes(DesignationWarning.IndicationIsCancelled)) {
    return 'Indication is Cancelled';
  }
  if (warnings.includes(DesignationWarning.IndicationIsDuplicate)) {
    return 'Indication is a Duplicate';
  }
  if (warnings.includes(DesignationWarning.AllocationIsRejected)) {
    return 'Allocation is Rejected';
  }
  if (warnings.includes(DesignationWarning.AllocationMustBeGreaterThanZero)) {
    return 'Allocation amount is 0 or null';
  }
};
