import { Alert, TextAreaField } from '@cmg/common';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { SNoteAreaFieldContainer, SNoteErrorContainer, SNoteLength } from './NotesRoute.styles';

export type FormType = {
  text: string;
};

export type Props = {
  text: string;
  disabled?: boolean;
  onSubmit: (payload: FormType) => void;
};

const NoteForm: React.FC<Props> = ({ text, onSubmit, disabled }) => {
  const formik = useFormik<FormType>({
    enableReinitialize: true,
    initialValues: { text },
    validationSchema: SubmitNotesSchema,
    onSubmit: values => {
      onSubmit(values);
    },
  });
  const textError = formik.errors.text;

  return (
    <FormikProvider value={formik}>
      <Form id="note-form" noValidate>
        <SNoteAreaFieldContainer>
          <TextAreaField placeholder="Enter notes..." name="text" fullWidth disabled={disabled} />
        </SNoteAreaFieldContainer>
        {textError && (
          <SNoteErrorContainer>
            <Alert severity="error" withMargin>
              {textError}
            </Alert>
          </SNoteErrorContainer>
        )}
        <SNoteLength>{formik.values.text.length}/2000 Characters</SNoteLength>
      </Form>
    </FormikProvider>
  );
};

export const SubmitNotesSchema = yup.object().shape({
  text: yup.string().max(2000),
});

export default NoteForm;
