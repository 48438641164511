import isEqual from 'lodash/isEqual';

import { RecipientUserData } from '../../../../SyndicateWiresRoute.model';

export enum OperationType {
  CREATE = 'create',
  UPDATE = 'update',
}

export const operationTypeToText: { [type in OperationType]: string } = {
  [OperationType.CREATE]: 'Create',
  [OperationType.UPDATE]: 'Edit',
};

/**
 * Returns true if initial data for given recipient does not exist and all next values, except recipient field, are falsy.
 */
export const canClearRecipientsData = <TValues extends RecipientUserData>(args: {
  initialUserData: (RecipientUserData | null)[];
  cmgEntityKey: string;
  nextValues: TValues;
  operationType: OperationType;
  defaultValues: Omit<TValues, 'recipient'>;
}) => {
  const { recipient, ...rest } = args.nextValues || {};
  const isEqualToDefaultValues = isEqual(rest, args.defaultValues);

  if (args.operationType === OperationType.CREATE) {
    return isEqualToDefaultValues;
  }

  // For update scenario check initial value of given manager
  const editingExistingManager = args.initialUserData.some(
    value => value?.recipient === args.cmgEntityKey
  );

  return !editingExistingManager && isEqualToDefaultValues;
};
