import styled from 'styled-components/macro';

export const SNotFoundWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: white;
`;

export const SNotFoundImg = styled.img`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 75vw;
  max-height: 75vh;
`;
