/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_DraftAllocationSetFragment = {
  readonly __typename?: 'DraftAllocationSet';
  readonly id: string;
  readonly name: string;
  readonly shareTimestamp?: string | null;
  readonly authorCmgEntityKey: string;
  readonly version: string;
};

export type OrderBook_CreateDraftAllocationSetMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateDraftAllocationInput;
}>;

export type OrderBook_CreateDraftAllocationSetMutation = {
  readonly __typename?: 'Mutation';
  readonly createDraftAllocationSet: {
    readonly __typename?: 'DraftAllocationSet';
    readonly id: string;
    readonly name: string;
    readonly shareTimestamp?: string | null;
    readonly authorCmgEntityKey: string;
    readonly version: string;
  };
};

export const OrderBook_DraftAllocationSetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftAllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_CreateDraftAllocationSetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_CreateDraftAllocationSet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDraftAllocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDraftAllocationSet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_DraftAllocationSet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftAllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_CreateDraftAllocationSetMutationFn = Apollo.MutationFunction<
  OrderBook_CreateDraftAllocationSetMutation,
  OrderBook_CreateDraftAllocationSetMutationVariables
>;

/**
 * __useOrderBook_CreateDraftAllocationSetMutation__
 *
 * To run a mutation, you first call `useOrderBook_CreateDraftAllocationSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_CreateDraftAllocationSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookCreateDraftAllocationSetMutation, { data, loading, error }] = useOrderBook_CreateDraftAllocationSetMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useOrderBook_CreateDraftAllocationSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_CreateDraftAllocationSetMutation,
    OrderBook_CreateDraftAllocationSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_CreateDraftAllocationSetMutation,
    OrderBook_CreateDraftAllocationSetMutationVariables
  >(OrderBook_CreateDraftAllocationSetDocument, options);
}
export type OrderBook_CreateDraftAllocationSetMutationHookResult = ReturnType<
  typeof useOrderBook_CreateDraftAllocationSetMutation
>;
export type OrderBook_CreateDraftAllocationSetMutationResult =
  Apollo.MutationResult<OrderBook_CreateDraftAllocationSetMutation>;
export type OrderBook_CreateDraftAllocationSetMutationOptions = Apollo.BaseMutationOptions<
  OrderBook_CreateDraftAllocationSetMutation,
  OrderBook_CreateDraftAllocationSetMutationVariables
>;
