import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Banner from '../../../common/components/indicators/banner/Banner';
import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import NotAccessible from '../../../common/components/layout/not-accessible/NotAccessible';
import Spinner from '../../../common/components/overlays/spinner/Spinner';
import { useFeatureToggles } from '../../../common/config';
import { useGetLimitedPublishedOfferingQuery } from '../components/side-bar/hooks/useGetLimitedPublishedOfferingQuery';
import OfferingNoteForm from './components/offering-note-form/OfferingNoteForm';
import { OfferingNoteFormValues } from './components/offering-note-form/types';
import { OfferingNotes_OfferingNotePartsFragment } from './components/offering-notes-list/components/offering-note/graphql';
import OfferingNotesList from './components/offering-notes-list/OfferingNotesList';
import { ViewMode } from './constants';
import {
  useOfferingSidePanel_CreateOfferingNoteMutation,
  useOfferingSidePanel_DeleteOfferingNoteMutation,
  useOfferingSidePanel_OfferingNotesQuery,
  useOfferingSidePanel_UpdateOfferingNoteMutation,
} from './graphql';
import { SNotesContainer, SNotesTitle } from './OfferingNotesRoute.styles';

export type Props = RouteComponentProps<{ offeringId: UUID }>;

const OfferingNotesRoute: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const canRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.View);
  const [selectedNote, setSelectedNote] =
    React.useState<OfferingNotes_OfferingNotePartsFragment | null>();

  const {
    loading: loadingOfferingNotes,
    data,
    error,
  } = useOfferingSidePanel_OfferingNotesQuery({
    skip: !canRead,
    variables: {
      offeringId,
    },
  });

  const { data: limitedData } = useGetLimitedPublishedOfferingQuery({ variables: { offeringId } });

  const [createOfferingNote, { loading: loadingCreateNote }] =
    useOfferingSidePanel_CreateOfferingNoteMutation({
      onCompleted: () => {
        ToastManager.success('Successfully created offering note');
        handleChangeViewMode(ViewMode.View);
      },
      onError: error => {
        ToastManager.error(`Failed to create note: ${error.message}`);
      },
    });

  const [updateOfferingNote, { loading: loadingUpdateNote }] =
    useOfferingSidePanel_UpdateOfferingNoteMutation({
      onCompleted: () => {
        ToastManager.success('Successfully updated offering note');
        handleChangeViewMode(ViewMode.View);
      },
      onError: error => {
        ToastManager.error(`Failed to update note: ${error.message}`);
      },
    });

  const [deleteOfferingNote, { loading: loadingDeleteNote }] =
    useOfferingSidePanel_DeleteOfferingNoteMutation({
      onCompleted: () => {
        ToastManager.success('Successfully deleted offering note');
        handleChangeViewMode(ViewMode.View);
      },
      onError: error => {
        ToastManager.error(`Failed to delete offering note: ${error.message}`);
      },
    });

  const handleSaveNote = (values: OfferingNoteFormValues) => {
    const noteId = selectedNote?.id;

    if (noteId) {
      updateOfferingNote({
        variables: {
          offeringId,
          input: { subject: values.subject!, note: values.note, id: noteId },
        },
      });
    } else {
      createOfferingNote({
        variables: {
          offeringId,
          input: { subject: values.subject!, note: values.note },
        },
      });
    }
  };

  const handleDeleteNote = () => {
    const noteId = selectedNote?.id;
    if (noteId) {
      deleteOfferingNote({ variables: { offeringId, input: { id: noteId } } });
    }
  };

  const loading =
    loadingOfferingNotes || loadingCreateNote || loadingUpdateNote || loadingDeleteNote;

  const notes = data?.offeringNotes.notes ?? [];

  const handleChangeViewMode = (newViewMode: ViewMode) => {
    setSelectedNote(null);
    setViewMode(newViewMode);
  };

  const getSelectedNote = (noteId: string) => {
    return find(notes, ['id', noteId]);
  };

  const handleEditOfferingNote = (noteId: string) => {
    setSelectedNote(getSelectedNote(noteId));
    setViewMode(ViewMode.Edit);
  };

  const handleDeleteOfferingNote = (noteId: string) => {
    setSelectedNote(getSelectedNote(noteId));
    setViewMode(ViewMode.Delete);
  };

  const isUsOffering = limitedData?.limitedPublishedOffering.isUsOffering;
  const isExecutingOnPlatform = limitedData?.limitedPublishedOffering.isExecutingOnPlatform;
  const offeringCountryCode = limitedData?.limitedPublishedOffering.pricingInstrumentCountryCode;

  const { isInternationalOfferingsInDL } = useFeatureToggles();

  if (
    canRead &&
    !isUsOffering &&
    !isExecutingOnPlatform &&
    !isInternationalOfferingsInDL &&
    !isNil(offeringCountryCode)
  ) {
    return (
      <Banner variant="warning">
        <Banner.Title>
          This feature is currently not available for international offerings.
        </Banner.Title>
      </Banner>
    );
  }
  const renderContent = () => {
    if (loading) {
      return <Spinner show={true} fullHeight={true} />;
    }

    if (viewMode === ViewMode.View) {
      return (
        <SNotesContainer>
          {notes.length ? (
            <OfferingNotesList
              notes={notes}
              handleEditOfferingNote={handleEditOfferingNote}
              handleDeleteOfferingNote={handleDeleteOfferingNote}
            />
          ) : (
            <p>You can record internal notes here</p>
          )}
        </SNotesContainer>
      );
    }

    return null;
  };

  if (!canRead) {
    return <NotAccessible subject="Notes" />;
  }

  return (
    <div data-test-id={xcSelectors.offeringNotesScreen.testId}>
      <SNotesTitle>Notes</SNotesTitle>
      {!error && (
        <React.Fragment>
          <Banner variant="information">
            <Banner.Title>The following information is visible to your firm only</Banner.Title>
          </Banner>
          <OfferingNoteForm
            loading={loading}
            viewMode={viewMode}
            handleChangeViewMode={handleChangeViewMode}
            handleSaveNote={handleSaveNote}
            handleDeleteNote={handleDeleteNote}
            initialValues={
              viewMode === ViewMode.Edit && selectedNote
                ? { subject: selectedNote.subject, note: selectedNote.note }
                : undefined
            }
          />
        </React.Fragment>
      )}
      {error && <ServerErrorsBanner error={error} />}
      {renderContent()}
    </div>
  );
};

export default OfferingNotesRoute;
