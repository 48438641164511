import { Icon, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import ListItemIcon from '../../../../common/components/lists/list/ListItemIcon';
import ListItemText from '../../../../common/components/lists/list/ListItemText';
import NavItem from '../../../../common/components/navigation/side-navigation/NavItem';
import SideNav from '../../../../common/components/navigation/side-navigation/SideNav';
import routeFactory from '../../../../common/util/routeFactory';
import { FilingStatus } from '../../../../graphql';
import RegulatoryFilingsSideNavItem from '../navbar-item/RegulatoryFilingsSideNavItem';
import { getIconColor, getLastFilingStatus } from './RegulatoryFilingsSideNav.model';

export type Filing = {
  id: string;
  status: FilingStatus;
  url: string;
  version: number;
};

export type Props = {
  offeringId: UUID;
  rpnFilings?: Filing[];
  tnFilings?: Filing[];
  usdtnFilings?: Filing[];
  noiFilings?: Filing[];
  dsFilings?: Filing[];
};

const RegulatoryFilingsSideNav: React.FC<Props> = ({
  offeringId,
  rpnFilings = [],
  tnFilings = [],
  usdtnFilings = [],
  noiFilings = [],
  dsFilings = [],
}) => {
  const theme = useTheme();

  return (
    <SideNav testId={xcSelectors.regulatoryFilingsNavbar.testId}>
      <NavItem
        testId={xcSelectors.regulatoryFilingsNavbarRPN.testId}
        nestedItems={rpnFilings.map(filing => (
          <RegulatoryFilingsSideNavItem filing={filing} key={filing.id} />
        ))}
        url={routeFactory.regulatoryFilingsRPNFiling.getUrlPath({ offeringId })}
      >
        <ListItemIcon>
          <Icon
            name="circle"
            variant="solid"
            size="xs"
            color={getIconColor(theme, getLastFilingStatus(rpnFilings))}
          />
        </ListItemIcon>
        <ListItemText>Restricted Period Notification Form (RPN)</ListItemText>
      </NavItem>
      <NavItem
        testId={xcSelectors.regulatoryFilingsNavbarNOI.testId}
        nestedItems={noiFilings.map(filing => (
          <RegulatoryFilingsSideNavItem filing={filing} key={filing.id} />
        ))}
        url={routeFactory.regulatoryFilingsNOIFiling.getUrlPath({ offeringId })}
      >
        <ListItemIcon>
          <Icon
            name="circle"
            variant="solid"
            size="xs"
            color={getIconColor(theme, getLastFilingStatus(noiFilings))}
          />
        </ListItemIcon>
        <ListItemText>Notice of Intent Form (NOI)</ListItemText>
      </NavItem>
      <NavItem
        testId={xcSelectors.regulatoryFilingsNavbarTN.testId}
        nestedItems={tnFilings.map(filing => (
          <RegulatoryFilingsSideNavItem filing={filing} key={filing.id} />
        ))}
        url={routeFactory.regulatoryFilingsTNFiling.getUrlPath({ offeringId })}
      >
        <ListItemIcon>
          <Icon
            name="circle"
            variant="solid"
            size="xs"
            color={getIconColor(theme, getLastFilingStatus(tnFilings))}
          />
        </ListItemIcon>
        <ListItemText>Trading Notification Form (TN)</ListItemText>
      </NavItem>
      <NavItem
        testId={xcSelectors.regulatoryFilingsNavbarUSDTN.testId}
        nestedItems={usdtnFilings.map(filing => (
          <RegulatoryFilingsSideNavItem filing={filing} key={filing.id} />
        ))}
        url={routeFactory.regulatoryFilingsUSDTNFiling.getUrlPath({ offeringId })}
      >
        <ListItemIcon>
          <Icon
            name="circle"
            variant="solid"
            size="xs"
            color={getIconColor(theme, getLastFilingStatus(usdtnFilings))}
          />
        </ListItemIcon>
        <ListItemText>
          Unregistered Secondary Distribution Trading Notification Form (USD-TN)
        </ListItemText>
      </NavItem>
      <NavItem
        testId={xcSelectors.regulatoryFilingsNavbarDS.testId}
        nestedItems={dsFilings.map(filing => (
          <RegulatoryFilingsSideNavItem filing={filing} key={filing.id} />
        ))}
        url={routeFactory.regulatoryFilingsDSFiling.getUrlPath({ offeringId })}
      >
        <ListItemIcon>
          <Icon
            name="circle"
            variant="solid"
            size="xs"
            color={getIconColor(theme, getLastFilingStatus(dsFilings))}
          />
        </ListItemIcon>
        <ListItemText>Deal Status Form (DS)</ListItemText>
      </NavItem>
    </SideNav>
  );
};

export default RegulatoryFilingsSideNav;
