import React from 'react';

import { ManagerInvestment } from '../../DesignationMonitorGrid.model';

export const useGetEditedInvestorsNames = (investments?: Record<string, ManagerInvestment>) => {
  return React.useMemo(() => {
    return Object.values(investments ?? [])
      .filter(({ shareQuantity, fixedEconomicsShares }) => shareQuantity !== fixedEconomicsShares)
      .map(({ name }) => name);
  }, [investments]);
};
