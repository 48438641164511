import React from 'react';

import { OfferingType } from '../../../../../../graphql';
import { getInitialColumnConfiguration } from '../InstitutionalDemandGrid.model';
import { useInstitutionalDemandPreferences } from '../preferences/useInstitutionalDemandPreferences';
import {
  InstitutionalDemandColumnConfig,
  InstitutionalDemandGridOfferingColumnConfig,
} from '../types';
import { validationSchema } from './useDemandConfiguration.model';

export type GridConfiguration = {
  demandColumnConfig: InstitutionalDemandColumnConfig;
  isColumnConfigurationApplied: boolean;
  isColumnConfigurationInitialized: boolean;
  setConfiguration: (config: InstitutionalDemandColumnConfig) => void;
};

export const useDemandConfiguration = (
  offeringId: string,
  args: InstitutionalDemandGridOfferingColumnConfig | null,
  offeringType?: OfferingType
): GridConfiguration => {
  const { preferredInstitutionalDemandColumnConfig, setPreferredInstitutionalDemandColumnConfig } =
    useInstitutionalDemandPreferences(offeringId);
  const [demandColumnConfig, setDemandColumnConfig] =
    React.useState<InstitutionalDemandColumnConfig>({
      min: 0,
      max: 0,
      increment: 0,
      referencePrice: null,
      ...preferredInstitutionalDemandColumnConfig,
    });

  const [isColumnConfigurationApplied, setIsColumnConfigurationApplied] =
    React.useState<boolean>(false);
  const [isColumnConfigurationInitialized, setIsColumnConfigurationInitialized] =
    React.useState(false);

  const setCombinedInstitutionalDemandColumnConfig = (
    newFilters: InstitutionalDemandColumnConfig
  ) => {
    setPreferredInstitutionalDemandColumnConfig(newFilters);
    return setDemandColumnConfig(newFilters);
  };

  React.useEffect(() => {
    if (args && !isColumnConfigurationApplied) {
      const config = getInitialColumnConfiguration(args);
      setDemandColumnConfig({
        min: config.min,
        max: config.max,
        increment: config.increment,
        referencePrice: config.referencePrice,
        ...preferredInstitutionalDemandColumnConfig,
      });

      if (
        validationSchema.isValidSync({
          demandMin: config.min,
          demandMax: config.max,
          demandIncrement: config.increment,
          demandReferencePrice: config.referencePrice,
          ...preferredInstitutionalDemandColumnConfig,
        }) ||
        preferredInstitutionalDemandColumnConfig
      ) {
        setIsColumnConfigurationApplied(true);
      }
      setIsColumnConfigurationInitialized(true);
    }
  }, [args, isColumnConfigurationApplied, preferredInstitutionalDemandColumnConfig, offeringType]);

  const setConfiguration = (config: InstitutionalDemandColumnConfig) => {
    setCombinedInstitutionalDemandColumnConfig(config);
    setIsColumnConfigurationApplied(true);
  };

  return {
    demandColumnConfig,
    isColumnConfigurationApplied,
    isColumnConfigurationInitialized,
    setConfiguration,
  };
};
