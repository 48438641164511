import { getManyOperationNames } from '../../../../../../common/util/graphql';
import { SyndicateWires_WiresDocument } from '../../../common/graphql';
import {
  SyndicateWires_CreatePricingTermsRetentionWireDocument,
  SyndicateWires_CreatePricingTermsRetentionWireMutation,
  useSyndicateWires_CreatePricingTermsRetentionWireMutation,
} from '../graphql';

export type CreatePricingTermsRetentionWireMutation =
  SyndicateWires_CreatePricingTermsRetentionWireMutation;

export const refetchQueries = () => {
  const documentNodes = [
    SyndicateWires_WiresDocument,
    SyndicateWires_CreatePricingTermsRetentionWireDocument,
  ];
  return getManyOperationNames(documentNodes);
};

export const useCreatePricingTermsRetentionWireMutation = () => {
  return useSyndicateWires_CreatePricingTermsRetentionWireMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
