import { ButtonsContainer, PrimaryButton, SecondaryButton } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { useHistory } from 'react-router';

import routeFactory from '../../../../../../common/util/routeFactory';
import { RoadshowStatus } from '../../../../../../graphql';
import { Roadshows_RoadshowPartsFragment } from '../../../../common/graphql';
import RoadshowHeader from '../../../common/roadshow-header/RoadshowHeader';
import PublishRoadshowModal from './publish-roadshow-modal/PublishRoadshowModal';

export type Props = { roadshow: Roadshows_RoadshowPartsFragment };

const CalendarHeader: React.FC<Props> = ({ roadshow }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { push } = useHistory();

  const handleEditRoadshow = () => {
    push(routeFactory.roadshowDetails.getUrlPath({ roadshowId: roadshow.id }));
  };

  const openPublishModal = () => {
    setIsModalOpen(true);
  };

  return (
    <RoadshowHeader roadshow={roadshow}>
      <ButtonsContainer>
        {roadshow.status === RoadshowStatus.Open && (
          <SecondaryButton
            onClick={openPublishModal}
            testId="open publish roadshow internally modal"
          >
            Publish
          </SecondaryButton>
        )}
        <PrimaryButton
          onClick={handleEditRoadshow}
          testId={xcSelectors.roadshowCalendarEditRoadshowConfigButton.testId}
        >
          Edit Roadshow
        </PrimaryButton>
      </ButtonsContainer>
      <PublishRoadshowModal
        roadshowId={roadshow.id}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </RoadshowHeader>
  );
};

export default CalendarHeader;
