import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import {
  FieldArray,
  Form,
  FormikProvider,
  useFormik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import React from 'react';

import FormikUnsavedChangesGuard from '../../../../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import { OrderBook_RetailBrokerDealerPartsFragment } from '../../../graphql';
import BulkEditInternalRetailDemandForm from './bulk-edit-form/BulkEditInternalRetailDemandForm';
import {
  createInitialValues,
  createUpdateMyRetailVariables,
  getUpdatedAmounts,
  getValidationSchema,
  MyRetailDemandBulkSaveValues,
} from './BulkEditInternalRetailDemandView.model';
import { useOrderBook_UpdateMyRetailMutation } from './graphql';
import UpdateRetailDemandDialog from './update-retail-demand-dialog/UpdateRetailDemandDialog';

export type Props = Readonly<{
  brokers: readonly OrderBook_RetailBrokerDealerPartsFragment[];
  sellingConcession: number | null | undefined;
  offeringId: UUID;
  trancheId: UUID;
  instrumentId: UUID;
  onFinishEditing: () => void;
}>;

const BulkEditInternalRetailDemandView: React.FC<Props> = ({
  brokers,
  sellingConcession,
  offeringId,
  trancheId,
  instrumentId,
  onFinishEditing,
}) => {
  const [isReleaseDialogOpen, setIsReleaseDialogOpen] = React.useState<boolean>(false);

  const [updateMyRetail, { loading: isUpdating }] = useOrderBook_UpdateMyRetailMutation();

  const handleSave = async () => {
    try {
      await updateMyRetail({
        variables: createUpdateMyRetailVariables({
          offeringId,
          trancheId,
          instrumentId,
          values,
        }),
      });
      setIsReleaseDialogOpen(false);
      resetForm({ values });

      onFinishEditing();
    } catch {
      SnackbarManager.error('Failed to save My Retail Demand');
    }
  };

  const validate = React.useCallback(async (values: MyRetailDemandBulkSaveValues) => {
    try {
      await validateYupSchema(values, getValidationSchema(values.brokers), true);
      return {};
    } catch (e) {
      return yupToFormErrors(e);
    }
  }, []);

  const formik = useFormik<MyRetailDemandBulkSaveValues>({
    initialValues: createInitialValues(brokers),
    validate,
    onSubmit: async values => {
      const updatedAmounts = getUpdatedAmounts(brokers, values);
      const areValuesUpdated =
        updatedAmounts.totalInitialDemandShares !== updatedAmounts.totalUpdatedDemandShares ||
        updatedAmounts.totalInitialDemandRoundLots !== updatedAmounts.totalUpdatedDemandRoundLots;

      if (areValuesUpdated) {
        setIsReleaseDialogOpen(true);
        return;
      }

      await handleSave();
    },
  });
  const { resetForm, values, dirty } = formik;

  const handleDiscardChanges = () => {
    resetForm();
    onFinishEditing();
  };
  const handleCloseUpdateDialog = () => {
    setIsReleaseDialogOpen(false);
  };

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard
        when={dirty || isReleaseDialogOpen}
        onLeave={() => resetForm({ values })}
      >
        <Form>
          <FieldArray
            name="brokers"
            render={({ push, remove }) => (
              <BulkEditInternalRetailDemandForm
                onAddValue={push}
                onRemoveValue={remove}
                onDiscardChanges={handleDiscardChanges}
                isUpdating={isUpdating}
                sellingConcession={sellingConcession}
              />
            )}
          />

          <UpdateRetailDemandDialog
            open={isReleaseDialogOpen}
            values={values}
            rows={brokers}
            isSubmitting={isUpdating}
            onSubmit={handleSave}
            onCancel={handleCloseUpdateDialog}
          />
        </Form>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};

export default BulkEditInternalRetailDemandView;
