import React from 'react';

import ListItemIcon from '../../../../common/components/lists/list/ListItemIcon';
import ListItemSecondaryAction from '../../../../common/components/lists/list/ListItemSecondaryAction';
import ListItemText from '../../../../common/components/lists/list/ListItemText';
import SubNavItem from '../../../../common/components/navigation/side-navigation/SubNavItem';
import { FilingStatus } from '../../../../graphql';
import { Filing } from '../navbar/RegulatoryFilingsSideNav';
import {
  SRegulatoryFilingsSideNavItemWrapper,
  StyledFilingStatus,
} from './RegulatoryFilingsSideNavItem.styles';

const filingStatusLabels: Record<FilingStatus, string> = {
  ERROR: 'Failed',
  PROCESSING: 'In progress',
  SUBMITTED: 'Successful',
  SUBMISSION_ERROR: 'Submission error',
  MISSING_RESPONSE: 'Missing response',
};

export type Props = {
  filing: Filing;
};

const RegulatoryFilingsSideNavItem: React.FC<Props> = ({ filing }) => (
  <SRegulatoryFilingsSideNavItemWrapper>
    <SubNavItem url={filing.url}>
      <ListItemIcon />
      <ListItemText>Version {filing.version}</ListItemText>
      <ListItemSecondaryAction>
        <StyledFilingStatus>{filingStatusLabels[filing.status]}</StyledFilingStatus>
      </ListItemSecondaryAction>
    </SubNavItem>
  </SRegulatoryFilingsSideNavItemWrapper>
);

export default RegulatoryFilingsSideNavItem;
