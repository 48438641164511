import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import { FormikCheckboxField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import {
  getCheckedState,
  getToggledValues,
  ShareAllocationSetValues,
  SyndicateManager,
} from '../ShareAllocationSetDialog.model';
import { getShareStatusChangedLabel } from './ShareAllocationSetTable.model';

export type Props = Readonly<{ firms: SyndicateManager[] }>;

const ShareAllocationSetTable: React.FC<Props> = ({ firms }) => {
  const { setValues, initialValues, values } = useFormikContext<ShareAllocationSetValues>();

  const handleToggleSelectAll = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(getToggledValues(event.target.checked, initialValues));
    },
    [initialValues, setValues]
  );

  const selectAllCheckboxState = React.useMemo(() => getCheckedState(values), [values]);

  return (
    <TableContainer sx={{ maxHeight: theme => theme.spacing(75) }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                inputProps={{ 'aria-label': 'toggle all' }}
                checked={selectAllCheckboxState.isAllChecked}
                indeterminate={selectAllCheckboxState.isSomeChecked}
                onChange={handleToggleSelectAll}
              />
            </TableCell>

            <TableCell colSpan={2}>All Underwriters</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {firms.map(({ cmgEntityKey, firmName, shareInfo }) => (
            <TableRow key={cmgEntityKey} selected={values[cmgEntityKey]}>
              <TableCell padding="checkbox">
                <FormikCheckboxField name={cmgEntityKey} sx={{ padding: 0 }} />
              </TableCell>
              <TableCell>{firmName}</TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  {getShareStatusChangedLabel(shareInfo)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShareAllocationSetTable;
