import { FieldArray, useFormikContext } from 'formik';
import lowerCase from 'lodash/lowerCase';
import React from 'react';

import InterestLevelsTableHeader from './components/interest-levels-table-header/InterestLevelsTableHeader';
import InterestLevelsWrapperHeader from './components/interest-levels-wrapper-header/InterestLevelsWrapperHeader';
import { emptyInterestLevel, FormValues, Props } from './InterestLevelsWrapper.model';
import { SMessage } from './InterestLevelsWrapper.styles';

const InterestLevelsWrapper = <UnitType, PricingType, LimitType>({
  isEditing = false,
  useFirstItemUnitType = true,
  children,
}: Props<UnitType, PricingType, LimitType>) => {
  const { values } = useFormikContext<FormValues<UnitType, PricingType, LimitType>>();
  const interestLevels = values.interestLevels;
  const containsLimitType = !!interestLevels.find(
    ({ pricingType }) => lowerCase(pricingType as unknown as string) === 'limit'
  );

  return (
    <FieldArray name="interestLevels" validateOnChange={false}>
      {({ replace, remove, push }) => {
        const handleAddInterestLevel = () => {
          push({
            ...emptyInterestLevel,
            unitType:
              useFirstItemUnitType && interestLevels.length > 0 ? interestLevels[0].unitType : null,
          });
        };

        const isEmptyInterestLevels = !(Array.isArray(interestLevels) && interestLevels.length);

        return (
          <div>
            {isEditing && <InterestLevelsWrapperHeader handleAddClick={handleAddInterestLevel} />}
            {!isEditing && isEmptyInterestLevels && (
              <SMessage>No indications of interest have been entered</SMessage>
            )}
            {!isEmptyInterestLevels && (
              <React.Fragment>
                <InterestLevelsTableHeader
                  isEditing={isEditing}
                  displayLimitConfig={containsLimitType}
                />
                {children({
                  interestLevels,
                  isEditing,
                  replace: replace,
                  remove: remove,
                })}
              </React.Fragment>
            )}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default InterestLevelsWrapper;
