import { PrimaryButton, SecondaryButton, TextInputField } from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import FlexLayout from '../../../../layout/flex-layout/FlexLayout';
import { useAdvancedInvestorSearchFlow } from '../AdvancedInvestorSearchFlowContext';
import { ButtonGroup } from '../AdvancedInvestorSearchFlowModal.styles';
import { InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables } from './graphql';

export const ShortTextInput = styled(TextInputField)`
  min-width: unset;
  width: 150px;
`;

export const LongTextInput = styled(TextInputField)`
  min-width: unset;
  width: 300px;
`;

export const StyledFlexLayout = styled(FlexLayout)`
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const AdvancedInvestorSearchSchema = yup.object().shape({
  searchText: yup.string().nullable(),
  firmKey: yup.string().nullable(),
  cik: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be numeric')
    .test('cik', 'Must be 10 digits long', value => (value ? value.length === 10 : true))
    .nullable(),
  lei: yup
    .string()
    .matches(/^[a-zA-Z0-9]/, 'Must be alphanumeric')
    .test('lei', 'Must be 20 characters long', value => (value ? value.length === 20 : true))
    .nullable(),
  crd: yup.string().nullable(),
});

export type Props = {
  onSubmit: (values: InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables) => void;
};

/**
 * AdvancedInvestorSearchForm - advanced search form for crm investor firms in rolodex
 * - at least one field has to be populated
 * - when searchText is populated on initial load we submit a search request
 */
const AdvancedInvestorSearchForm: React.FC<Props> = ({ onSubmit }) => {
  const { searchForm, setSearchForm } = useAdvancedInvestorSearchFlow();
  const formik = useFormik<InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables>({
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: AdvancedInvestorSearchSchema,
    initialValues: searchForm,
    onSubmit: onSubmit,
  });
  const { values, dirty, handleSubmit, resetForm } = formik;
  // when the form loads initially, if searchText is populated we search for it
  React.useEffect(() => {
    if (!dirty && values.searchText) {
      handleSubmit();
    }
  }, [values, dirty, handleSubmit]);

  // when values in the form change we set the search form
  // the search form will auto-populate the create-new form onload
  React.useEffect(() => {
    setSearchForm(formik.values);
  }, [formik.values, setSearchForm]);

  const emptyValues = React.useMemo(() => Object.values(values).every(value => !value), [values]);

  return (
    <FormikProvider value={formik}>
      <StyledFlexLayout direction="row">
        <LongTextInput withMargin label="Investor" name="searchText" />
        <LongTextInput withMargin label="Bank Firm Key" name="firmKey" />
        <ShortTextInput withMargin label="CIK" name="cik" />
        <ShortTextInput withMargin label="LEI" name="lei" />
        <ShortTextInput withMargin label="CRD" name="crd" />
      </StyledFlexLayout>
      <ButtonGroup>
        {/* @todo disabled when submitting [future PR] */}
        <PrimaryButton disabled={emptyValues} onClick={() => handleSubmit()}>
          Search
        </PrimaryButton>
        <SecondaryButton onClick={() => resetForm()}>Clear Search</SecondaryButton>
      </ButtonGroup>
    </FormikProvider>
  );
};

export default AdvancedInvestorSearchForm;
