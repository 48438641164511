import { FlexContainer } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SManagersColumn = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.background.color.light};
  padding: 8px 8px 8px 8px;
  margin-top: 45px;
`;

const selectedStyles = css`
  & > div {
    color: ${({ theme }) => theme.text.color.link};
  }
  background-color: ${({ theme }) => theme.background.color.highlighted};
  border-radius: ${({ theme }) => theme.border.radius.medium};
`;

export const SManager = styled.div<{
  selected: boolean;
}>`
  padding: 15px;
  cursor: pointer;

  & small {
    color: ${({ theme }) => theme.text.color.light};
  }

  &:hover {
    ${selectedStyles}
  }

  ${({ selected }) => selected && selectedStyles}
`;
