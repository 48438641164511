import { getCurrencySymbol, numericUtil, Select, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import isNil from 'lodash/isNil';
import React from 'react';

import CurrencyFormatter from '../../../common/components/format/currency-format/CurrencyFormatter';
import { useFeatureToggles } from '../../../common/config';
import { isIPOType } from '../../../common/util/offering/offering-type.util';
import { FilingType, OfferingType } from '../../../graphql';
import { useOfProceedsLabels } from '../../../types/domain/filing/constants';
import {
  SContent,
  SField,
  SHeader,
  SLabel,
  STitle,
  StyledFlexLayout,
} from '../OfferingProfile.styles';
import {
  FilingsTypeFix,
  getFilingRevisionOptions,
  getForwardSaleDisplayValue,
  Terms,
} from './OfferingProfileFilings.model';
import { SRevisionWrapper } from './OfferingProfileFilings.styles';

export type Props = {
  hasForwardAgreement: boolean | null;
  offeringType: OfferingType;
  terms: Terms;
  currency?: string;
};

/**
 * Offering Profile Filings Component
 * Displays filing info with dropdown for selecting past filings
 */
export const OfferingProfileFilings: React.FC<Props> = ({
  hasForwardAgreement,
  terms,
  offeringType,
  currency = 'USD',
}) => {
  const [activeFilingId, setActiveFilingId] = React.useState<UUID>('');
  const { isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn } = useFeatureToggles();

  const title = isOfferingSetupMergeDiscountsFeesWithManagerEconomicsOn
    ? 'Offering Terms'
    : 'Terms';
  const filings = terms.filings;
  const filingsTypeFix: FilingsTypeFix = filings;
  const filing = filingsTypeFix.find(d => d.id === activeFilingId);

  React.useEffect(() => {
    if (filingsTypeFix.length) {
      // set to latest filing id from sorted filings array
      setActiveFilingId(filingsTypeFix[filingsTypeFix.length - 1].id);
    }
  }, [filingsTypeFix]);

  const filingRevisionOptions = React.useMemo(() => getFilingRevisionOptions(filings), [filings]);

  const isFinalFiling = filing?.filingType === FilingType.Final;
  const displayOfferPrice = isFinalFiling;
  const displayPriceRange = !isFinalFiling && !filing?.offerPrice && !!isIPOType(offeringType);

  return (
    <React.Fragment>
      <SHeader>
        <STitle>{title}</STitle>
        <SRevisionWrapper>
          <Select<UUID>
            options={filingRevisionOptions}
            onChange={id => id && setActiveFilingId(id)}
            value={activeFilingId}
            isClearable={false}
            testId={xcSelectors.offeringSidePanelOfferingDetailsFilingsRevisionSelect.testId}
          />
        </SRevisionWrapper>
      </SHeader>
      <SContent>
        <StyledFlexLayout direction="row" alignBottom>
          <SField>
            <SLabel>Initial Registration Value</SLabel>
            {!isNil(filing?.initialRegistrationValue) ? (
              <CurrencyFormatter value={filing?.initialRegistrationValue} currency={currency} />
            ) : (
              '-'
            )}
          </SField>
          <SField>
            <SLabel>Base Offering Shares</SLabel>
            {!isNil(filing?.totalSharesBaseOffering)
              ? `${numericUtil.formatNumber(filing?.totalSharesBaseOffering, 0)} shares`
              : '-'}
          </SField>
          <SField>
            <SLabel>Primary</SLabel>
            {numericUtil.getDisplayValueForNumber(filing?.primarySharesBaseOffering, 0)}
          </SField>
          <SField>
            <SLabel>Secondary</SLabel>
            {numericUtil.getDisplayValueForNumber(filing?.secondarySharesBaseOffering, 0)}
          </SField>
        </StyledFlexLayout>
        <StyledFlexLayout direction="row" alignBottom>
          <SField>
            <SLabel>Overallotment Authorized ({getCurrencySymbol(currency)}M)</SLabel>
            {!isNil(filing?.notionalOverAllotmentAuthorized)
              ? numericUtil.formatCurrencyInMillions(
                  filing?.notionalOverAllotmentAuthorized,
                  undefined,
                  getCurrencySymbol(currency)
                )
              : '-'}
          </SField>
          <SField>
            <SLabel>Overallotment Shares</SLabel>
            {!isNil(filing?.totalSharesOverAllotmentAuthorized)
              ? `${numericUtil.formatNumber(filing?.totalSharesOverAllotmentAuthorized, 0)} shares`
              : '-'}
          </SField>
          <SField>
            <SLabel>Primary</SLabel>
            {numericUtil.getDisplayValueForNumber(filing?.primarySharesOverAllotmentAuthorized, 0)}
          </SField>
          <SField>
            <SLabel>Secondary</SLabel>
            {numericUtil.getDisplayValueForNumber(
              filing?.secondarySharesOverAllotmentAuthorized,
              0
            )}
          </SField>
        </StyledFlexLayout>
        <StyledFlexLayout direction="row" alignBottom>
          <SField>
            {displayOfferPrice && (
              <React.Fragment>
                <SLabel>Offering Price</SLabel>
                {!isNil(filing?.offerPrice) ? (
                  <CurrencyFormatter value={filing.offerPrice} currency={currency} />
                ) : (
                  '-'
                )}
              </React.Fragment>
            )}
          </SField>

          <SField>
            {displayPriceRange && (
              <React.Fragment>
                <SLabel>Price Range</SLabel>
                {!isNil(filing?.ipoRangeLow) && (
                  <CurrencyFormatter value={filing?.ipoRangeLow} currency={currency} />
                )}
                {' - '}
                {!isNil(filing?.ipoRangeHigh) && (
                  <CurrencyFormatter value={filing?.ipoRangeHigh} currency={currency} />
                )}
              </React.Fragment>
            )}
          </SField>

          <SField>
            <SLabel>Use of Proceeds</SLabel>
            {filing?.useOfProceeds && filing.useOfProceeds.length
              ? filing.useOfProceeds.map(d => useOfProceedsLabels[d]).join(', ')
              : '-'}
          </SField>

          <SField>
            <SLabel>Forward Sale</SLabel>
            {getForwardSaleDisplayValue(hasForwardAgreement)}
          </SField>
        </StyledFlexLayout>
      </SContent>
      {!isIPOType(offeringType) && (
        <React.Fragment>
          <SHeader>
            <STitle>Follow-On Pricing</STitle>
          </SHeader>
          <SContent>
            <StyledFlexLayout direction="row" alignBottom>
              <SField>
                <SLabel>Last Trade Before Filing</SLabel>
                {!isNil(terms.followOnPricing?.lastTradeBeforeFiling) ? (
                  <CurrencyFormatter
                    value={terms.followOnPricing?.lastTradeBeforeFiling}
                    currency={currency}
                  />
                ) : (
                  '-'
                )}
              </SField>
              <SField>
                <SLabel>Last Trade Before Launch</SLabel>
                {!isNil(terms.followOnPricing?.lastTradeBeforeLaunch) ? (
                  <CurrencyFormatter
                    value={terms.followOnPricing?.lastTradeBeforeLaunch}
                    currency={currency}
                  />
                ) : (
                  '-'
                )}
              </SField>
              <SField>
                <SLabel>Last Trade Before Offer</SLabel>
                {!isNil(terms.followOnPricing?.lastTradeBeforeOffer) ? (
                  <CurrencyFormatter
                    value={terms.followOnPricing?.lastTradeBeforeOffer}
                    currency={currency}
                  />
                ) : (
                  '-'
                )}
              </SField>
              <SField>
                <SLabel>Re-Offer Low</SLabel>
                {!isNil(terms.followOnPricing?.reOfferLow) ? (
                  <CurrencyFormatter
                    value={terms.followOnPricing?.reOfferLow}
                    currency={currency}
                  />
                ) : (
                  '-'
                )}
              </SField>
              <SField>
                <SLabel>Re-Offer High</SLabel>
                {!isNil(terms.followOnPricing?.reOfferHigh) ? (
                  <CurrencyFormatter
                    value={terms.followOnPricing?.reOfferHigh}
                    currency={currency}
                  />
                ) : (
                  '-'
                )}
              </SField>
            </StyledFlexLayout>
          </SContent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OfferingProfileFilings;
