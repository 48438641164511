import { getOperationName } from '@apollo/client/utilities';

import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import {
  IndicationForm_IndicationAcknowledgementsDocument,
  IndicationForm_UnacknowledgeIndicationMutationVariables,
  useIndicationForm_UnacknowledgeIndicationMutation,
} from '../graphql/__generated__';

export function refetchQueries() {
  const refetchInstitutionalIndicationAcknowledgementsQuery = getOperationName(
    IndicationForm_IndicationAcknowledgementsDocument
  );
  const refetchSummaryGridQuery = getOperationName(InstitutionalDemandGrid_SummaryDocument);
  return [refetchInstitutionalIndicationAcknowledgementsQuery!, refetchSummaryGridQuery!];
}

export const useUnacknowledgeInstitutionalIndicationMutation = (
  variables: IndicationForm_UnacknowledgeIndicationMutationVariables
) =>
  useIndicationForm_UnacknowledgeIndicationMutation({
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
