import { loggerUtil } from '@cmg/common';
import { useCallback, useMemo } from 'react';

/**
 * A special hook that logs push notification events to mixpanel and datadog.
 * TODO: This should be replaced with a more generic and sophisticated logger capable of being configured to log to mixpanel and datadog.
 */
export const useInstrumentation = () => {
  const { log } = loggerUtil.useMixpanel();

  const record = useCallback(
    (message: string, extraData: Record<string, unknown> = {}) => {
      const formattedMessage = `PushNotification: ${message}`;
      log(formattedMessage, extraData);
      loggerUtil.info(formattedMessage, extraData);
    },
    [log]
  );

  return useMemo(() => ({ record }), [record]);
};
