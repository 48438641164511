import { errorUtil, loggerUtil } from '@cmg/common';
import { useEffect, useState } from 'react';

import { serviceWorkerApi } from '../util';

/** A hook that provides access to service worker registration. */
export const useServiceWorker = () => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>();

  useEffect(() => {
    if (serviceWorkerApi.isSupported()) {
      (async () => {
        try {
          const _registration = await serviceWorkerApi.register();

          if (serviceWorkerApi.isActive()) {
            // Service worker is already active
            setRegistration(_registration);
          } else {
            // Listen for the service worker to become active
            const { installing } = _registration;
            installing?.addEventListener('statechange', () => {
              if (installing.state === 'activated') {
                setRegistration(_registration);
              }
            });
          }
        } catch (error) {
          errorUtil.assertIsError(error);
          loggerUtil.error(new Error('Service Worker registration failed'), error);
        }
      })();
    } else {
      loggerUtil.error(new Error('Service Workers not supported'));
    }
  }, []);

  return registration;
};
