import { Skeleton, Stack, Typography, useTheme } from '@cmg/design-system';
import React from 'react';

export type MetricProps = Readonly<{
  value: string;
  label: string;
  loading?: boolean;
}>;

const Metric = ({ value, label, loading }: MetricProps) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Stack aria-label={label} role="progressbar">
        <Skeleton width={theme.spacing(10)} />
        <Skeleton width={theme.spacing(10)} />
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end" aria-label={label} spacing={0.5}>
      <Typography variant="h2" color="text.primary">
        {value}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
    </Stack>
  );
};

export default Metric;
