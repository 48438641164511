import {
  CurrencyInputField,
  DatePickerField,
  DateTimePickerField,
  RadioGroupField,
  SelectField,
  TextAreaField,
  TextInputField,
  UUID,
} from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import {
  PricingBasisType,
  TnBasisCodeType,
  TnRestrictedPeriodType,
  TnSecurityType,
  TransactionType,
} from '../../../../../graphql';
import FilingCommonFieldsForm from '../../../common/content/filing-common-fields-form/FilingCommonFieldsForm';
import FilingDetailsForm from '../../../common/content/filing-details-form/FilingDetailsForm';
import { NonMemberFirmsDetail } from '../../../common/content/non-member-firms-detail/NonMemberFirmsDetail';
import SymbolsOfCoveredCurrenciesForm from '../../../common/content/symbols-of-covered-currencies/SymbolsOfCoveredCurrenciesForm';
import { getRestrictionStartDateTime } from '../../../common/form-utils';
import ATMTradeForm from './atm-trade/ATMTradeForm';
import MemberFirmsForm from './member-firm/MemberFirmsForm';
import NonATMTradeForm from './non-atm-trade/NonATMTradeForm';
import {
  basisCodeOptions,
  concurrentConvertibleOptions,
  pricingBasisOptions,
  restrictedPeriodOptions,
  TNFilingValues,
  transactionTypeOptions,
  typeOfSecurityOptions,
} from './TNFilingForm.model';
import { StyledActivityForm } from './TNFilingForm.styles';

export type Props = Readonly<{
  readonly offeringId: UUID;
  readonly stockSymbols: readonly string[];
  readonly hasNoStabilizationActivities: boolean;
  readonly stabilizationActivitiesLoading: boolean;
}>;

const TNFilingForm: React.FC<Props> = ({
  offeringId,
  stockSymbols,
  hasNoStabilizationActivities,
  stabilizationActivitiesLoading,
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<TNFilingValues>();

  const handleRestrictedPeriodChange = (nextRestrictedPeriod: TnRestrictedPeriodType | null) => {
    if (
      nextRestrictedPeriod === TnRestrictedPeriodType.Na ||
      !nextRestrictedPeriod ||
      !values.pricingDate
    ) {
      return;
    }

    setFieldValue(
      'restrictedPeriodStart',
      getRestrictionStartDateTime(
        values.pricingDate,
        nextRestrictedPeriod,
        values.restrictedPeriodStart.timezone ?? 'America/New_York'
      )
    );
  };

  const handleTransactionTypeChange = (nextTransactionType: TransactionType | null) => {
    if (nextTransactionType === TransactionType.Atm) {
      setFieldValue('pricingBasisType', PricingBasisType.Atm);
    }
  };

  return (
    <div>
      <FilingCommonFieldsForm />
      <FilingDetailsForm stockSymbols={stockSymbols}>
        <SymbolsOfCoveredCurrenciesForm />
        <GridLayout>
          <Column span={3}>
            <RadioGroupField
              withMargin
              required
              fullWidth
              label="Concurrent Convertible"
              name="isConcurrentConvertible"
              options={concurrentConvertibleOptions}
            />
          </Column>
        </GridLayout>
        <GridLayout>
          <Column span={3}>
            <SelectField
              withMargin
              required
              fullWidth
              label="Transaction Type"
              name="transactionType"
              options={transactionTypeOptions}
              onChange={nextTransactionType => {
                handleTransactionTypeChange(nextTransactionType as TransactionType | null);
              }}
            />
          </Column>
        </GridLayout>
        {values.transactionType === TransactionType.Atm && <ATMTradeForm />}
        {values.transactionType !== TransactionType.Atm && <NonATMTradeForm />}
        {values.transactionType === TransactionType.Other && (
          <GridLayout>
            <Column span={6}>
              <TextAreaField
                withMargin
                required
                fullWidth
                label="Transaction Type Description"
                aria-label="Transaction Type Description"
                name="transactionTypeDescription"
              />
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <SelectField
              withMargin
              required
              fullWidth
              label="Type of security"
              name="securityType"
              options={typeOfSecurityOptions}
            />
          </Column>
        </GridLayout>
        {values.securityType === TnSecurityType.Other && (
          <GridLayout>
            <Column span={6}>
              <TextAreaField
                withMargin
                required
                fullWidth
                label="Type of Security Description"
                aria-label="Type of Security Description"
                name="securityTypeDescription"
              />
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <SelectField
              withMargin
              required
              fullWidth
              label="Pricing Basis"
              aria-label="Pricing Basis"
              name="pricingBasisType"
              disabled={values.transactionType === TransactionType.Atm}
              options={pricingBasisOptions}
            />
          </Column>
        </GridLayout>
        {values.pricingBasisType === PricingBasisType.Other && (
          <GridLayout>
            <Column span={6}>
              <TextAreaField
                withMargin
                required
                fullWidth
                label="Pricing Basis Description"
                aria-label="Pricing Basis Description"
                name="pricingBasisDescription"
              />
            </Column>
          </GridLayout>
        )}

        <GridLayout>
          <Column span={3}>
            <RadioGroupField
              withMargin
              required
              fullWidth
              label="Restricted Period"
              aria-label="Restricted Period"
              name="restrictedPeriod"
              options={restrictedPeriodOptions}
              onChange={nextRestrictedPeriod => {
                handleRestrictedPeriodChange(nextRestrictedPeriod as TnRestrictedPeriodType | null);
              }}
            />
          </Column>
        </GridLayout>
        {values.restrictedPeriod === TnRestrictedPeriodType.Na && (
          <React.Fragment>
            <GridLayout>
              <Column span={6}>
                <RadioGroupField
                  withMargin
                  required
                  fullWidth
                  label="Basis Code"
                  name="basisCode"
                  options={basisCodeOptions}
                />
              </Column>
            </GridLayout>
            {values.basisCode === TnBasisCodeType.Uar && (
              <GridLayout>
                <Column span={3}>
                  <DatePickerField
                    withMargin
                    fullWidth
                    required
                    label="Underwriter Activity Report Date"
                    testId="Regulatory Filings / Filings / NOI / Underwriter Activity Report Date"
                    name="uarDate"
                    onCalendarClose={() => {
                      setFieldTouched('uarDate');
                    }}
                  />
                </Column>
              </GridLayout>
            )}
            {values.basisCode === TnBasisCodeType.AdtvPfv && (
              <div>
                <GridLayout>
                  <Column span={3}>
                    <CurrencyInputField
                      withMargin
                      fullWidth
                      required
                      label="ADTV Number"
                      aria-label="ADTV Number"
                      name="adtvNumber"
                      precision={6}
                    />
                  </Column>
                  <Column span={3}>
                    <TextInputField
                      withMargin
                      fullWidth
                      required
                      label="ADTV Source"
                      aria-label="ADTV Source"
                      name="adtvSource"
                    />
                  </Column>
                </GridLayout>
                <GridLayout>
                  <Column span={3}>
                    <CurrencyInputField
                      withMargin
                      required
                      fullWidth
                      label="Public Float Value"
                      aria-label="Public Float Value"
                      name="publicFloatValue"
                      precision={6}
                    />
                  </Column>
                  <Column span={3}>
                    <TextInputField
                      withMargin
                      fullWidth
                      required
                      label="Public Float Value Source"
                      aria-label="Public Float Value Source"
                      name="publicFloatValueSource"
                    />
                  </Column>
                </GridLayout>
              </div>
            )}
            {values.basisCode === TnBasisCodeType.Other && (
              <GridLayout>
                <Column span={6}>
                  <TextAreaField
                    withMargin
                    required
                    fullWidth
                    label="Basis Code Description"
                    aria-label="Basis Code Description"
                    name="otherBasisDescription"
                  />
                </Column>
              </GridLayout>
            )}
          </React.Fragment>
        )}
        {(values.restrictedPeriod === TnRestrictedPeriodType.OneDay ||
          values.restrictedPeriod === TnRestrictedPeriodType.FiveDays) && (
          <GridLayout>
            <Column span={3}>
              <DateTimePickerField
                withMargin
                required
                fullWidth
                label="Restricted Period Commencement Time"
                testId="Regulatory Filings / Filings / NOI / Restricted Period Commencement Time"
                name="restrictedPeriodStart"
                showTimezones
              />
            </Column>
            <Column span={3}>
              <DateTimePickerField
                withMargin
                required
                fullWidth
                label="Restricted Period End Time"
                testId="Regulatory Filings / Filings / NOI / Restricted Period End Time"
                name="restrictedPeriodEnd"
                showTimezones
              />
            </Column>
          </GridLayout>
        )}
      </FilingDetailsForm>

      {values.finraMemberFirms.length > 0 && <MemberFirmsForm />}
      {values.nonMemberFirms.length > 0 && (
        <NonMemberFirmsDetail nonMemberFirms={values.nonMemberFirms} />
      )}

      <StyledActivityForm
        offeringId={offeringId}
        hasNoStabilizationActivities={hasNoStabilizationActivities}
        stabilizationActivitiesLoading={stabilizationActivitiesLoading}
      />
    </div>
  );
};

export default TNFilingForm;
