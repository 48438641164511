import { SelectField, TextAreaField } from '@cmg/common';
import { useFormikContext } from 'formik';
import React from 'react';

import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import { TransactionType } from '../../../../../graphql';
import { transactionTypeOptions } from '../../trading-notification/form/TNFilingForm.model';
import { RPNFilingFormValues } from './RPNForm.model';

const TransactionTypeSubForm: React.FC = () => {
  const { values } = useFormikContext<RPNFilingFormValues>();
  const transactionTypeValue = values.transactionType;
  return (
    <div>
      <GridLayout>
        <Column span={3}>
          <SelectField
            withMargin
            required
            fullWidth
            label="Transaction Type"
            aria-label="Transaction Type"
            name="transactionType"
            options={transactionTypeOptions}
          />
        </Column>
      </GridLayout>
      {transactionTypeValue === TransactionType.Other && (
        <GridLayout>
          <Column span={6}>
            <TextAreaField
              withMargin
              required
              fullWidth
              label="Transaction Type Text"
              aria-label="Transaction Type Text"
              name="transactionTypeDescription"
            />
          </Column>
        </GridLayout>
      )}
    </div>
  );
};

export default TransactionTypeSubForm;
