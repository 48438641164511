import { OfferingStatus } from '../../../graphql';
import { useOffering_OfferingDetails_PublishedOfferingQuery } from '../../offering/offering-details/graphql';
import { useGetNotesAndOrdersQuery } from './useGetNotesAndOrdersQuery';

export const useGetSupersededDataOffering = ({ offeringId }: { offeringId?: string }) => {
  const { data } = useOffering_OfferingDetails_PublishedOfferingQuery({
    variables: { offeringId: offeringId! },
    skip: !offeringId,
  });

  const { supersededByOfferingId, supersededOfferingId } = data?.publishedOffering || {};

  const hasSupersedeData = !!supersededByOfferingId || !!supersededOfferingId;

  const oldOfferingId = supersededOfferingId || data?.publishedOffering.id;

  const isObsoleteOffering = !!supersededByOfferingId;

  const { hasNotes, hasOrders, hasCommunicatedDemand } = useGetNotesAndOrdersQuery({
    offeringId: oldOfferingId,
    skip: !oldOfferingId || !hasSupersedeData,
  });

  const isFiledOffering = data?.publishedOffering?.status === OfferingStatus.Filed;

  return {
    supersededByOfferingId,
    supersededOfferingId,
    oldOfferingId,
    hasCommunicatedDemand,
    hasNotes,
    hasOrders,
    hasSupersedeData,
    isObsoleteOffering,
    isFiledOffering,
  };
};
