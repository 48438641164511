import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import {
  ButtonsContainer,
  Checkbox,
  MultiSelect,
  PrimaryButton,
  SecondaryButton,
} from '@cmg/common';
import React from 'react';

import { ExportButton } from '../../../common/components/export-button/ExportButton';
import { OnExportResult } from '../../../common/components/export-button/ExportButton.model';
import { DesignationRow } from '../designation-monitor-grid/DesignationMonitorGrid.model';
import { getInvestorsOptions } from './DesignationMonitorToolBar.model';
import {
  SMultiSelectWrapper,
  SToolbarSection,
  SToolbarWrapper,
} from './DesignationMonitorToolBar.styles';

export type Props = {
  investorRows: DesignationRow[];
  isApplyFixedEconomicsEnabled?: boolean;
  isEditedOnlyFilter?: boolean;
  isExportLoading?: boolean;
  isOutOfBalanceOnlyFilter?: boolean;
  onApplyFixedEconomicsClick: () => void;
  onExportClick: () => Promise<OnExportResult>;
  onEditedOnlyFilterChange: (value: boolean) => void;
  onOutOfBalanceOnlyFilterChange: (value: boolean) => void;
  investorsFilter: string[];
  setInvestorsFilter: (value: string[]) => void;
  isPublishDisabled: boolean;
  onPublishClick: () => void;
  isPublishing?: boolean;
};

/**
 * Renders the Toolbar for the Designation Monitor Grid
 */
const DesignationMonitorToolBar: React.FC<Props> = ({
  investorRows,
  investorsFilter,
  setInvestorsFilter,
  isEditedOnlyFilter,
  isOutOfBalanceOnlyFilter,
  isExportLoading,
  isApplyFixedEconomicsEnabled,
  onApplyFixedEconomicsClick,
  onExportClick,
  onEditedOnlyFilterChange,
  onOutOfBalanceOnlyFilterChange,
  isPublishDisabled,
  onPublishClick,
  isPublishing,
}) => {
  const canEditDesignation = useCheckPermissions([permissionsByEntity.Designation.FULL]);

  const investorsOptions = React.useMemo(() => getInvestorsOptions(investorRows), [investorRows]);

  return (
    <SToolbarWrapper>
      <SToolbarSection>
        <SMultiSelectWrapper>
          <MultiSelect
            fullWidth
            isSearchable
            placeholder="Search for Investor..."
            options={investorsOptions}
            value={investorsFilter}
            onChange={setInvestorsFilter}
          />
        </SMultiSelectWrapper>
        <Checkbox
          testId="edited-only-filter"
          value={isEditedOnlyFilter}
          onChange={onEditedOnlyFilterChange}
        >
          Edited only
        </Checkbox>
        <Checkbox
          testId="out-of-balance-only-filter"
          value={isOutOfBalanceOnlyFilter}
          onChange={onOutOfBalanceOnlyFilterChange}
        >
          Out of balance only
        </Checkbox>
      </SToolbarSection>

      <SToolbarSection align="right">
        <ButtonsContainer>
          <ExportButton testId="export-xls" loading={isExportLoading} onExport={onExportClick} />
          <SecondaryButton
            testId="publish-designations"
            loading={isPublishing}
            onClick={onPublishClick}
            disabled={isPublishDisabled}
          >
            Publish Designations
          </SecondaryButton>
          {canEditDesignation && (
            <PrimaryButton
              testId="apply-fixed-economics"
              title="Apply Fixed Economics"
              onClick={onApplyFixedEconomicsClick}
              disabled={!isApplyFixedEconomicsEnabled}
            >
              Apply Fixed Economics
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </SToolbarSection>
    </SToolbarWrapper>
  );
};

export default DesignationMonitorToolBar;
