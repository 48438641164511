export const getPerShareValue = (value: number | null, offerPrice: number | null) => {
  return value && offerPrice ? (value / offerPrice) * 100 : null;
};

export const getSumDisplayValue = ({
  values,
  formValues,
  isFormDirty,
}: {
  values: (number | null)[];
  formValues: (number | null)[];
  isFormDirty: boolean;
}) => {
  if (isFormDirty) {
    return formValues.some(value => !!value)
      ? formValues.reduce((prev, current) => (prev ?? 0) + (current ?? 0), 0)
      : null;
  } else {
    return values.some(value => !!value)
      ? values.reduce((prev, current) => (prev ?? 0) + (current ?? 0), 0)
      : null;
  }
};
