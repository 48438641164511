import type { SxProps, Theme } from '@cmg/design-system';
import { Box, Stack, SvgIcon, Typography } from '@cmg/design-system';
import React from 'react';

import { ReactComponent as EmptyWire } from '../../../../common/assets/empty-wire.svg';

export type EmptyStateProps = Readonly<{
  title: React.ReactNode;
  message?: React.ReactNode;
  sx?: SxProps<Theme>;
  component?: React.ElementType;
}>;

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  message,
  sx,
  children,
  component,
}) => {
  return (
    <Stack
      alignItems="center"
      gap={2}
      py={2}
      px={4}
      width="100%"
      height="100%"
      sx={sx}
      {...(component ? { component } : {})}
    >
      <SvgIcon
        inheritViewBox
        role="img"
        component={EmptyWire}
        sx={{ fontSize: theme => theme.spacing(10) }}
      />
      <Box textAlign="center" {...(component ? { component } : {})}>
        <Typography variant="h3" color="text.primary" {...(component ? { component } : {})}>
          {title}
        </Typography>
        {message && (
          <Typography
            color="text.secondary"
            textAlign="center"
            {...(component ? { component } : {})}
          >
            {message}
          </Typography>
        )}
      </Box>
      {children}
    </Stack>
  );
};
