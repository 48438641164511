import { AllocationAcknowledgement } from '../../../../../../graphql';

export const getAllocationStatusColor = (status: AllocationAcknowledgement) => {
  switch (status) {
    case AllocationAcknowledgement.Acknowledged:
      return 'success';
    case AllocationAcknowledgement.Rejected:
      return 'error';
    case AllocationAcknowledgement.Unacknowledged:
      return 'info';
  }
};
