import { TreeSelect } from '@cmg/common';
import flatten from 'lodash/flatten';
import React from 'react';
import styled from 'styled-components/macro';

import { getRegionAndCountryOptions } from './CountryFilter.model';

export type Props = {
  values: { [key: string]: { title: string; value: string }[] };
  onChange: (value: string[]) => void;
};

export const SCountryFilter = styled.div`
  width: 220px;
`;

export const CountryFilter: React.FC<Props> = ({ values, onChange }) => {
  const options = getRegionAndCountryOptions(values);

  const filterOnChange = (value: string[] | string[][]) => {
    const countries = value.map(val => val.split(','));
    const flattenedCountries = flatten(countries);
    onChange(flattenedCountries);
  };

  return (
    <SCountryFilter role="combobox" aria-label="Country filter">
      <TreeSelect treeData={options} onChange={filterOnChange} placeholder="Region/Country..." />{' '}
    </SCountryFilter>
  );
};

export default CountryFilter;
