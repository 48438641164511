import { ToastManager } from '@cmg/common';
import { ActionPanelSection, Box, CircularProgress, LoadingButton } from '@cmg/design-system';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import {
  useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation,
  useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation,
  useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
  useOfferingSetup_UnderwritingTerms_SyndicateQuery,
  useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
  useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation,
} from '../graphql';
import { calculatedValuesType } from '../manager-economics/ManagerEconomicsRoute.model';
import { useValidateOffering } from '../validation/hooks/useValidateOffering';
import { CalculateDropdown } from './components/CalculateDropdown';
import { EconomicsBreakdownErrors } from './components/economics-breakdown-errors/EconomicsBreakdownErrors';
import {
  EconomicBreakdownGrid,
  ManagerEconomicsBreakdownFormikContext,
} from './components/economics-breakdown-grid/EconomicBreakdownGrid';
import { generateTotalValues } from './components/economics-breakdown-grid/EconomicBreakdownGrid.model';
import { ManagerEconomicsErrors } from './components/manager-economics-errors/ManagerEconomicsErrors';
import { OverwriteModal } from './components/overwrite-modal/OverwriteModal';
import {
  EconomicBreakdownValidationTypes,
  useValidateEconomicBreakdownGrid,
} from './hooks/useValidateEconomicBreakdownGrid';
import { useValidateManagerEconomicsData } from './hooks/useValidateManagerEconomicsData';
import {
  createEconomicBreakdownRows,
  createEconomicBreakdownVariables,
  handleCalculateFeesDistributionModel,
  handleCalculateOverallotmentSharesDistributionModel,
  hasNonNullValueInColumn,
} from './UnderwritingTermsEconomicsSection.model';

type UpdateManagerEconomicsVariables = ReturnType<typeof createEconomicBreakdownVariables>;

type UnderwritingTermsEconomicsSectionProps = {
  offeringId: string;
  underwritingTermsFeesAndDiscounts: ReturnType<
    typeof useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery
  >;
};

export function UnderwritingTermsEconomicsSection({
  offeringId,
  underwritingTermsFeesAndDiscounts,
}: UnderwritingTermsEconomicsSectionProps) {
  const {
    data: filingsData,
    loading: filingsLoading,
    error: filingsError,
  } = useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });
  const {
    data: syndicateData,
    loading: syndicateLoading,
    error: syndicateError,
  } = useOfferingSetup_UnderwritingTerms_SyndicateQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });
  const {
    data: underwritingTermsFeesAndDiscountsData,
    loading: underwritingTermsFeesAndDiscountsLoading,
    error: underwritingTermsFeesAndDiscountsError,
  } = underwritingTermsFeesAndDiscounts;

  const initialDataLoading =
    filingsLoading || underwritingTermsFeesAndDiscountsLoading || syndicateLoading;

  const [userSavedValues, setUserSavedValues] = React.useState<UpdateManagerEconomicsVariables>();

  const rows = React.useMemo(() => {
    if (userSavedValues && syndicateData?.offering.syndicate.managers) {
      return createEconomicBreakdownRows(
        syndicateData?.offering.syndicate.managers,
        userSavedValues
      );
    }
    return syndicateData?.offering.syndicate.managers &&
      syndicateData?.offering.syndicate.managerEconomics
      ? createEconomicBreakdownRows(
          syndicateData?.offering.syndicate.managers,
          syndicateData?.offering.syndicate.managerEconomics
        )
      : [];
  }, [
    syndicateData?.offering.syndicate.managers,
    syndicateData?.offering.syndicate.managerEconomics,
    userSavedValues,
  ]);

  const [updateManagerEconomics, { loading: updateLoading }] =
    useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation();
  const { revalidate } = useValidateOffering(offeringId);
  const [calculateFeesDistribution, { loading: loadingCalculateFeesDistribution }] =
    useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation();
  const [calculateSharesDistribution, { loading: loadingCalculateSharesDistribution }] =
    useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation();

  const formik = useFormik<ManagerEconomicsBreakdownFormikContext>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      rows,
    },
    onSubmit: async values => {
      if (validateManagerEconomicsData()) {
        try {
          const updateVariables = createEconomicBreakdownVariables(offeringId, values.rows);
          await updateManagerEconomics({
            variables: updateVariables,
          });
          ToastManager.success('Managers economics updated');
          revalidate();
          setUserSavedValues(updateVariables);
        } catch {
          ToastManager.error('Error while updating manager economics');
          throw new Error('Error while updating manager economics');
        }
      } else {
        throw new Error('Validation Failed');
      }
    },
  });

  const { values, setErrors, dirty, setFieldValue } = formik;

  const totalValues = generateTotalValues(values.rows);

  const underwritingFeesAndDiscounts =
    underwritingTermsFeesAndDiscountsData?.underwritingTermsFeesAndDiscounts;

  const latestFiling = filingsData?.getFilings[filingsData.getFilings.length - 1];

  const error = filingsError ?? underwritingTermsFeesAndDiscountsError ?? syndicateError;

  const { validateData: validateManagerEconomicsData, errors: managerEconomicsErrors } =
    useValidateManagerEconomicsData({
      totalValues,
      incentiveGrossSpread: underwritingFeesAndDiscounts?.incentiveFeeData,
      setErrors,
      values,
    });

  const { validateData: validateEconomicBreakdownData, errors: economicBreakdownErrors } =
    useValidateEconomicBreakdownGrid({
      grossSpreadBaseData: underwritingFeesAndDiscounts?.grossSpreadBaseData,
      incentiveFeeData: underwritingFeesAndDiscounts?.incentiveFeeData,
      totalValues,
      totalSharesBaseOffering: latestFiling?.totalSharesBaseOffering,
      totalSharesOverallotmentExercised: latestFiling?.totalSharesOverAllotmentExercised,
    });

  const handleCalculateFeesDistribution = React.useCallback(async () => {
    if (!loadingCalculateFeesDistribution && !loadingCalculateSharesDistribution) {
      const res = await handleCalculateFeesDistributionModel({
        values,
        calculateFeesDistribution,
        offeringId,
        calculateSharesDistribution,
      });

      if ('error' in res) {
        ToastManager.error('Error while calculating fee distribution');
      } else {
        Object.entries(res as calculatedValuesType).forEach(([cmgEntityKey, feeDistributions]) => {
          feeDistributions.forEach(feeDistribution => {
            setFieldValue(
              `rows[${cmgEntityKey}].${feeDistribution.gridColumn}`,
              feeDistribution.percentageValue !== undefined
                ? feeDistribution.percentageValue
                : feeDistribution.sharesValue
            );
          });
        });
      }
    }
  }, [
    calculateFeesDistribution,
    loadingCalculateFeesDistribution,
    values,
    setFieldValue,
    offeringId,
    calculateSharesDistribution,
    loadingCalculateSharesDistribution,
  ]);

  const handleCalculateOverallotmentSharesDistribution = React.useCallback(async () => {
    if (!loadingCalculateSharesDistribution) {
      const res = await handleCalculateOverallotmentSharesDistributionModel({
        values,
        calculateSharesDistribution,
        offeringId,
      });

      if ('error' in res) {
        ToastManager.error('Error while calculating share distribution');
      } else {
        Object.entries(res as calculatedValuesType).forEach(([cmgEntityKey, feeDistributions]) => {
          feeDistributions.forEach(feeDistribution => {
            setFieldValue(
              `rows[${cmgEntityKey}].${feeDistribution.gridColumn}`,
              feeDistribution.sharesValue
            );
          });
        });
      }
    }
  }, [
    calculateSharesDistribution,
    values,
    setFieldValue,
    offeringId,
    loadingCalculateSharesDistribution,
  ]);

  const [showOverwriteModal, setShowOverwriteModal] = React.useState<boolean>(false);
  const [calculatedField, setCalculatedField] = React.useState<string>('');

  const handleClickCalculateFeesDistribution = React.useCallback(async () => {
    if (validateEconomicBreakdownData(EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN)) {
      const hasNonNullValue = hasNonNullValueInColumn(
        [
          'managementFee',
          'underwritingWithIncentiveFee',
          'sellingConcession',
          'underwritingShares',
        ],
        formik.values.rows
      );

      if (hasNonNullValue) {
        setShowOverwriteModal(true);
        setCalculatedField(EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN);
        return;
      }

      await handleCalculateFeesDistribution();
    }
  }, [formik.values.rows, handleCalculateFeesDistribution, validateEconomicBreakdownData]);

  const handleClickCalculateSharesDistribution = React.useCallback(async () => {
    if (validateEconomicBreakdownData(EconomicBreakdownValidationTypes.EXERCISED_OVERALLTOMENT)) {
      const hasNonNullValue = hasNonNullValueInColumn(
        ['exercisedOverallotmentShares'],
        formik.values.rows
      );

      if (hasNonNullValue) {
        setCalculatedField(EconomicBreakdownValidationTypes.EXERCISED_OVERALLTOMENT);
        setShowOverwriteModal(true);
        return;
      }

      await handleCalculateOverallotmentSharesDistribution();
    }
  }, [
    formik.values.rows,
    handleCalculateOverallotmentSharesDistribution,
    validateEconomicBreakdownData,
  ]);
  return (
    <FormikProvider value={formik}>
      <ActionPanelSection
        title="Economics"
        actions={[
          <CalculateDropdown
            key="calculate-button"
            handleClickCalculateFeesDistribution={handleClickCalculateFeesDistribution}
            handleClickCalculateSharesDistribution={handleClickCalculateSharesDistribution}
          />,
          <LoadingButton
            key="save-button"
            variant="contained"
            loading={updateLoading}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </LoadingButton>,
        ]}
      >
        {initialDataLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={theme => theme.spacing(12)}
          >
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            {dirty && <ManagerEconomicsErrors errors={managerEconomicsErrors} />}

            {error && <ServerErrorsBanner error={error} />}
            <OverwriteModal
              calculatedField={calculatedField}
              show={showOverwriteModal}
              onCalculate={
                calculatedField === EconomicBreakdownValidationTypes.ECONOMIC_BREAKDOWN
                  ? handleCalculateFeesDistribution
                  : handleCalculateOverallotmentSharesDistribution
              }
              onHide={() => setShowOverwriteModal(false)}
            />
            <EconomicsBreakdownErrors errors={economicBreakdownErrors} offeringId={offeringId} />
            <EconomicBreakdownGrid
              totalSharesOverallotmentExercised={latestFiling?.totalSharesOverAllotmentExercised}
              totalSharesOverallotmentAuthorized={latestFiling?.totalSharesOverAllotmentAuthorized}
              totalSharesBaseOffering={latestFiling?.totalSharesBaseOffering}
              underwritingFeesAndDiscounts={underwritingFeesAndDiscounts}
              latestFiling={latestFiling}
              grossSpreadBaseData={underwritingFeesAndDiscounts?.grossSpreadBaseData ?? null}
              incentiveFeeData={underwritingFeesAndDiscounts?.incentiveFeeData ?? null}
              totalValues={totalValues}
            />
          </React.Fragment>
        )}
      </ActionPanelSection>
    </FormikProvider>
  );
}
