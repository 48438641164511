import isNil from 'lodash/isNil';

import { OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery } from '../../graphql';
import { EconomicsBreakdownErrors, Props } from './useValidateEconomicBreakdownGrid';

type UnderwritingFeesAndDiscounts =
  OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery['underwritingTermsFeesAndDiscounts'];

const isIncentiveFeeAllocationMissing = (
  incentiveFeeData?: UnderwritingFeesAndDiscounts['incentiveFeeData'] | null
) =>
  !!incentiveFeeData?.incentiveFee &&
  !incentiveFeeData.managementFeePercentage &&
  !incentiveFeeData.underwritingFeePercentage &&
  !incentiveFeeData.sellingConcessionPercentage;

const isGrossSpreadAllocationMissing = (
  grossSpreadBaseData?: UnderwritingFeesAndDiscounts['grossSpreadBaseData'] | null
) =>
  !!grossSpreadBaseData?.grossSpreadBase &&
  (isNil(grossSpreadBaseData?.managementFeePercentage) ||
    isNil(grossSpreadBaseData?.underwritingFeePercentage) ||
    isNil(grossSpreadBaseData?.sellingConcessionPercentage));

export const isEconomicBreakdownDataValid = ({
  grossSpreadBaseData,
  incentiveFeeData,
  totalValues,
  totalSharesBaseOffering,
  totalSharesOverallotmentExercised,
  type,
}: Props & { type: 'economics-breakdown' | 'exercised-overallotment' }) => {
  const errors: EconomicsBreakdownErrors = {
    totalIncentiveFee: false,
    totalUnderwritingFee: false,
    missingOfferingSize: false,
    missingBaseGrossSpread: null,
    missingGrossSpreadAllocation: false,
    missingOverallotmentExercised: false,
    missingIncentiveFeeAllocation: false,
  };

  if (type === 'economics-breakdown') {
    errors.totalUnderwritingFee = totalValues.underwritingFee !== 1;
    errors.totalIncentiveFee = totalValues.incentiveFee !== 1 && !!incentiveFeeData?.incentiveFee;
    errors.missingOfferingSize = isNil(totalSharesBaseOffering);
    errors.missingBaseGrossSpread =
      !!incentiveFeeData?.incentiveFee && !grossSpreadBaseData?.grossSpreadBase;
    errors.missingGrossSpreadAllocation = isGrossSpreadAllocationMissing(grossSpreadBaseData);
    errors.missingIncentiveFeeAllocation = isIncentiveFeeAllocationMissing(incentiveFeeData);
  } else if (type === 'exercised-overallotment') {
    errors.missingOverallotmentExercised = !totalSharesOverallotmentExercised;
  }

  return { errors, isValid: !Object.values(errors).includes(true) };
};
