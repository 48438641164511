import flatten from 'lodash/flatten';
import React from 'react';

import { FinalSettlement_DesignationsGridItemFragment } from '../common/graphql/__generated__';

export const useIsSellingConcessionChanged = (
  investorDesignations: readonly FinalSettlement_DesignationsGridItemFragment[] = []
) => {
  return React.useMemo(() => {
    const managerDesignations = flatten(
      investorDesignations.map(({ designations }) => designations)
    );

    return (
      managerDesignations.length > 0 &&
      managerDesignations.some(designation => designation.sellingConcessionPercentageChanged)
    );
  }, [investorDesignations]);
};
