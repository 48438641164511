/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Roadshows_CoveredMeetingQueryVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
}>;

export type Roadshows_CoveredMeetingQuery = {
  readonly __typename?: 'Query';
  readonly coveredRoadshowMeeting: {
    readonly __typename?: 'Meeting';
    readonly meetingDetails: {
      readonly __typename?: 'MeetingDetails';
      readonly id: string;
      readonly date: string;
      readonly startTime: string;
      readonly endTime: string;
      readonly timezone: string;
      readonly title: string;
      readonly location?: string | null;
      readonly address?: string | null;
      readonly venue?: string | null;
      readonly roomName?: string | null;
      readonly publicNotes?: string | null;
      readonly type: Types.MeetingType;
      readonly status: Types.EventStatus;
      readonly isPublic: boolean;
      readonly format: Types.MeetingFormat;
      readonly dialInDetails?: string | null;
      readonly hostPersonEmail?: string | null;
      readonly hostPersonFullName?: string | null;
      readonly hostFirmCmgEntityKey?: string | null;
    };
    readonly investors: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
    readonly participantsTeam: ReadonlyArray<{
      readonly __typename?: 'EventParticipantsTeam';
      readonly id: string;
      readonly name: string;
      readonly cmgEntityKey: string;
      readonly participants: ReadonlyArray<{
        readonly __typename?: 'EventParticipant';
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly title?: string | null;
        readonly isAdHoc: boolean;
        readonly officePhone?: string | null;
        readonly mobilePhone?: string | null;
        readonly status?: Types.DealTeamMemberStatus | null;
      }>;
    }>;
  };
};

export type Roadshows_UpdateCoveredInvestorTeamsMutationVariables = Types.Exact<{
  roadshowId: Types.Scalars['UUID'];
  meetingId: Types.Scalars['UUID'];
  payload: ReadonlyArray<Types.InvestorTeamInput> | Types.InvestorTeamInput;
}>;

export type Roadshows_UpdateCoveredInvestorTeamsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCoveredInvestorTeams: ReadonlyArray<{
    readonly __typename?: 'EventParticipantsTeam';
    readonly id: string;
    readonly name: string;
    readonly cmgEntityKey: string;
    readonly participants: ReadonlyArray<{
      readonly __typename?: 'EventParticipant';
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly title?: string | null;
      readonly isAdHoc: boolean;
      readonly officePhone?: string | null;
      readonly mobilePhone?: string | null;
      readonly status?: Types.DealTeamMemberStatus | null;
    }>;
  }>;
};

export const Roadshows_CoveredMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Roadshows_CoveredMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredRoadshowMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Roadshows_MeetingParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meeting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participantsTeam' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_MeetingDetailsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MeetingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'venue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicNotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dialInDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostPersonFullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostFirmCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRoadshows_CoveredMeetingQuery__
 *
 * To run a query within a React component, call `useRoadshows_CoveredMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_CoveredMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadshows_CoveredMeetingQuery({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRoadshows_CoveredMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Roadshows_CoveredMeetingQuery,
    Roadshows_CoveredMeetingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Roadshows_CoveredMeetingQuery, Roadshows_CoveredMeetingQueryVariables>(
    Roadshows_CoveredMeetingDocument,
    options
  );
}
export function useRoadshows_CoveredMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Roadshows_CoveredMeetingQuery,
    Roadshows_CoveredMeetingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Roadshows_CoveredMeetingQuery, Roadshows_CoveredMeetingQueryVariables>(
    Roadshows_CoveredMeetingDocument,
    options
  );
}
export type Roadshows_CoveredMeetingQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredMeetingQuery
>;
export type Roadshows_CoveredMeetingLazyQueryHookResult = ReturnType<
  typeof useRoadshows_CoveredMeetingLazyQuery
>;
export type Roadshows_CoveredMeetingQueryResult = Apollo.QueryResult<
  Roadshows_CoveredMeetingQuery,
  Roadshows_CoveredMeetingQueryVariables
>;
export const Roadshows_UpdateCoveredInvestorTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Roadshows_UpdateCoveredInvestorTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorTeamInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCoveredInvestorTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meetingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantsTeamParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipantsTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'participants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Roadshows_EventParticipantParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventParticipant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdHoc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Roadshows_UpdateCoveredInvestorTeamsMutationFn = Apollo.MutationFunction<
  Roadshows_UpdateCoveredInvestorTeamsMutation,
  Roadshows_UpdateCoveredInvestorTeamsMutationVariables
>;

/**
 * __useRoadshows_UpdateCoveredInvestorTeamsMutation__
 *
 * To run a mutation, you first call `useRoadshows_UpdateCoveredInvestorTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadshows_UpdateCoveredInvestorTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadshowsUpdateCoveredInvestorTeamsMutation, { data, loading, error }] = useRoadshows_UpdateCoveredInvestorTeamsMutation({
 *   variables: {
 *      roadshowId: // value for 'roadshowId'
 *      meetingId: // value for 'meetingId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useRoadshows_UpdateCoveredInvestorTeamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Roadshows_UpdateCoveredInvestorTeamsMutation,
    Roadshows_UpdateCoveredInvestorTeamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Roadshows_UpdateCoveredInvestorTeamsMutation,
    Roadshows_UpdateCoveredInvestorTeamsMutationVariables
  >(Roadshows_UpdateCoveredInvestorTeamsDocument, options);
}
export type Roadshows_UpdateCoveredInvestorTeamsMutationHookResult = ReturnType<
  typeof useRoadshows_UpdateCoveredInvestorTeamsMutation
>;
export type Roadshows_UpdateCoveredInvestorTeamsMutationResult =
  Apollo.MutationResult<Roadshows_UpdateCoveredInvestorTeamsMutation>;
export type Roadshows_UpdateCoveredInvestorTeamsMutationOptions = Apollo.BaseMutationOptions<
  Roadshows_UpdateCoveredInvestorTeamsMutation,
  Roadshows_UpdateCoveredInvestorTeamsMutationVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeRoadshows_CoveredMeetingMockResponse = makeMockResponse<
  Roadshows_CoveredMeetingQueryVariables,
  Roadshows_CoveredMeetingQuery
>(Roadshows_CoveredMeetingDocument);
