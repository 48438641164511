import { CurrencyInputField, PercentInputField, SelectField } from '@cmg/common';
import styled from 'styled-components/macro';

export const SColumn = styled.div<{ align: 'left' | 'center' | 'right' }>`
  padding-right: 5px;
  text-align: ${props => props.align};
  align-self: center;
  flex: 4;
`;

export const SLimitPriceColumn = styled(SColumn)`
  flex: 7;
`;

export const StyledLimitPricePercentInputField = styled(PercentInputField)`
  min-width: unset;
  flex: 1;
`;

export const StyledLimitPriceCurrencyInputField = styled(CurrencyInputField)`
  min-width: unset;
  flex: 1;
`;

export const StyledLimitPriceContainer = styled.div`
  display: flex;
`;

export const StyledLimitPriceSelectField = styled(SelectField)`
  flex: 1;
`;
