import { AccessControl, permissionsByEntity } from '@cmg/auth';
import { timeUtil, UUID } from '@cmg/common';
import {
  CachedIcon,
  CheckCircleOutline,
  CriticalActionDialog,
  DotStatus,
  LoadingButton,
  PageHeader,
  SnackbarManager,
  Stack,
} from '@cmg/design-system';
import React from 'react';

import ExportButton from '../../../../components/export-button/ExportButton';
import { useExportContext } from '../../../../components/export-button/ExportContext';
import { useReleaseTotalRetailDemandMutation } from './hooks/useReleaseTotalRetailDemandMutation';

export type Props = Readonly<{
  hasRows: boolean;
  offeringIssuerName: string;
  modifiedAt: string | null | undefined;
  isSavingData: boolean;
  offeringId: UUID;
}>;

const AutoSaveTotalRetailDemandPageHeader: React.FC<Props> = ({
  hasRows,
  offeringIssuerName,
  modifiedAt,
  isSavingData,
  offeringId,
}) => {
  const [isReleaseDialogOpen, setIsReleaseDialogOpen] = React.useState(false);

  const { isExporting, onExport } = useExportContext();

  const [releaseRetention, { loading: isReleasingRetention }] =
    useReleaseTotalRetailDemandMutation();

  const handleReleaseRetentions = async () => {
    try {
      await releaseRetention({ variables: { offeringId } });
    } catch {
      SnackbarManager.error('Failed to release Retentions');
    }
  };

  const handleOpenReleaseDialog = () => {
    setIsReleaseDialogOpen(true);
  };
  const handleCloseReleaseDialog = () => {
    setIsReleaseDialogOpen(false);
  };

  return (
    <div>
      <PageHeader
        pageTitle="Total Demand"
        status={<DotStatus color="info" label="Retentions not Released" />}
        actions={[
          <ExportButton key="export-button" loading={isExporting} onExport={onExport} />,
          <AccessControl
            key="release-retention-button"
            requiredPermissions={[
              permissionsByEntity.RetailDemand.FULL,
              permissionsByEntity.RetailRetention.FULL,
            ]}
            requireAllPermissions={false}
          >
            <LoadingButton
              onClick={handleOpenReleaseDialog}
              disabled={!hasRows}
              loading={isReleasingRetention}
              variant="contained"
            >
              Release Retentions
            </LoadingButton>
          </AccessControl>,
        ]}
      >
        <Stack flexGrow={1} gap={1} direction="row-reverse" alignItems="center">
          {isSavingData && (
            <React.Fragment>
              Saving
              <CachedIcon />
            </React.Fragment>
          )}
          {!isSavingData && modifiedAt && (
            <React.Fragment>
              Last Saved {timeUtil.formatAsDisplayDateTime(modifiedAt)}
              <CheckCircleOutline color="success" />
            </React.Fragment>
          )}
        </Stack>
      </PageHeader>

      <CriticalActionDialog
        open={isReleaseDialogOpen}
        title={`${offeringIssuerName} — Release Retail Retentions`}
        checkboxLabelText={
          <div>
            I understand that Retail Retentions for <b>{offeringIssuerName}</b> will be released.
          </div>
        }
        alertText={`This action will release retail retentions for ${offeringIssuerName}. Final retentions will be visible by all banks. Each bank will see their own retention only.  Underwriters without a retention entered in the system will automatically be assigned 0 shares. This action cannot be undone. `}
        actionButtonText="Release"
        handleClose={handleCloseReleaseDialog}
        onSubmit={() => {
          handleReleaseRetentions();
          handleCloseReleaseDialog();
        }}
      />
    </div>
  );
};

export default AutoSaveTotalRetailDemandPageHeader;
