import React from 'react';

export type Props = {
  step: string;
  fields: string[];
};

export const ValidationErrorGroup: React.FC<Props> = ({ step, fields }) => {
  return (
    <li>
      {step}: {fields.join(', ')}
    </li>
  );
};
