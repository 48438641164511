import { getOperationName } from '@apollo/client/utilities';

import { Roadshows_RoadshowEventsDocument } from '../../../../roadshow-calendar/roadshow-coordinator-calendar/graphql';
import {
  Roadshows_MeetingDocument,
  Roadshows_UpdateMeetingParticipantsMutation,
  useRoadshows_UpdateMeetingParticipantsMutation,
} from '../graphql';

export type UpdateMeetingParticipantsMutation = Roadshows_UpdateMeetingParticipantsMutation;

export function refetchQueries() {
  const refetchMeetingDetails = getOperationName(Roadshows_MeetingDocument)!;
  const refetchEventList = getOperationName(Roadshows_RoadshowEventsDocument)!;

  return [refetchMeetingDetails, refetchEventList];
}

export const useUpdateMeetingParticipantsMutation = () => {
  return useRoadshows_UpdateMeetingParticipantsMutation({
    awaitRefetchQueries: true,
    refetchQueries,
  });
};
