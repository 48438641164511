import { numericUtil, ODSTable as Table } from '@cmg/common';
import { format } from 'date-fns';
import React from 'react';

import { StabilizationTrade } from '../../../../StabilizationForm.model';
import { StyledGainLossCell } from '../../../common/gain-loss-table-cell/GainLossTableCell';
import { getSellGain } from '../../OverallotmentRefresh.model';

export type Props = { trade: StabilizationTrade; offeringPrice: number };

const ReadOnlyOverallotmentRefreshRow: React.FC<Props> = ({ trade, offeringPrice }) => {
  return (
    <Table.Row suppressHighlightingOnHover isFocused={false}>
      <Table.Cell paddingSize="S" align="left">
        {format(new Date(trade.date!), 'MM/dd/yyyy')}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForNumber(trade.numberOfShares, 0)}
      </Table.Cell>
      <Table.Cell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(trade.averagePrice, 6)}
      </Table.Cell>
      <StyledGainLossCell paddingSize="S" align="right">
        {numericUtil.getDisplayValueForCurrency(getSellGain(trade, offeringPrice), 6)}
      </StyledGainLossCell>
    </Table.Row>
  );
};

export default ReadOnlyOverallotmentRefreshRow;
