import { ODSTable as Table } from '@cmg/common';
import styled, { css } from 'styled-components/macro';

export const SWrapper = styled.div`
  overflow: auto;
  position: relative;
  height: 360px;
`;

export const SHeroWrapper = styled.div`
  width: 290px;
  margin: auto;
  justify-content: center;
  text-align: center;
`;

export const StyledTableRow = styled(Table.Row)<{ isDisabled: boolean }>`
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.text.color.light};
    `}
`;
