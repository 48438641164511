import { FlexContainer, Icon, Select } from '@cmg/common';
import styled from 'styled-components/macro';

import FlexLayout from '../../../common/components/layout/flex-layout/FlexLayout';
import SummaryCard from './components/SummaryCard';

export const SColumns = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SLeftColumn = styled.div`
  flex: 1;
  padding-right: 20px;
`;

export const SRightColumn = styled.div`
  flex: 2;
`;

export const SEmpty = styled.div`
  margin: 5px 0;
  padding: 10px;
  font-size: ${({ theme }) => theme.text.size.small};
  border: ${({ theme }) => theme.border.smallSolidLight};
  border-radius: ${({ theme }) => theme.border.radius.large};
  text-align: center;
`;

export const SRow = styled.div<{ withoutBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ theme, withoutBorder }) =>
    withoutBorder ? 'none' : theme.border.smallSolidLight};
  padding-bottom: 10px;
  margin-bottom: 20px;
  align-items: flex-end;
`;

export const StyledCardSection = styled(FlexLayout)`
  height: auto;
  margin-bottom: 40px;
`;

export const SBubble = styled.div`
  background-color: ${({ theme }) => theme.background.color.light};
  border: ${({ theme }) => theme.border.smallSolidLight};
  border-radius: ${({ theme }) => theme.border.radius.large};
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
`;

export const SDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SDetail = styled.div<{ alignCenter?: boolean }>`
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'right')};
`;

export const SDetailLabel = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.small};
`;

export const SDetailValue = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;

export const SCurrenciesRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SPricingCurrency = styled.div`
  margin-left: auto;
  font-weight: ${({ theme }) => theme.text.weight.medium};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

export const StyledOfferingStatusSelect = styled(Select)`
  width: 250px;
`;

export const SFiling = styled.div`
  border: ${({ theme }) => theme.border.smallSolidLight};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 10px;
  margin-bottom: 10px;
`;

export const SFilingTitle = styled.div`
  border-bottom: ${({ theme }) => theme.border.smallSolidLight};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  margin-bottom: 10px;
`;

export const SFilingRow = styled.div`
  display: flex;
`;

export const SFilingFields = styled.div`
  flex: 1;
`;

export const StyledTermsCard = styled(SummaryCard)`
  padding: 0;
`;

export const SDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SDropdownOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const SProspectusContainer = styled(FlexContainer).attrs({
  direction: 'row',
  gap: 5,
  alignItems: 'center',
})``;

export const SProspectusLinkContainer = styled(FlexContainer).attrs({ direction: 'row' })`
  margin-bottom: 10px;
`;

export const SNoProspectusDocumentsContainer = styled.div`
  color: ${({ theme }) => theme.foundation.colors.secondary[300]};
`;
