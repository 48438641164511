import { Popover } from '@cmg/common';
import React from 'react';

import { SDuplicate } from './CompanyNameCell.styles';

type Props = {
  duplicateOfInvestorName: string | null;
};

const DuplicateIcon: React.FC<Props> = ({ duplicateOfInvestorName }) => {
  return duplicateOfInvestorName ? (
    <Popover
      placement="topLeft"
      content={`Duplicate of ${duplicateOfInvestorName}`}
      variant="DARK"
      transparentBackground
    >
      <SDuplicate>D</SDuplicate>
    </Popover>
  ) : (
    <SDuplicate>D</SDuplicate>
  );
};

export default DuplicateIcon;
