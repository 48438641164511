import { Icon } from '@cmg/common';
import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;
