import React from 'react';
import { useHistory } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { CreateOfferingInput, UpdateBasicInfoInput } from '../../../graphql';
import { useCreateOfferingDraftMutation } from '../basic-info/hooks/useCreateOfferingDraftMutation';
import { stepIds } from '../constants';
import Modal from './../../../common/components/overlays/modal/Modal';
import {
  SOfferingSettlementAgentAuthoringBannerContainer,
  StyledModalContent,
} from './BasicInfoForm.styles';
import ModalBasicInfoForm from './ModalBasicInfoForm';
import OfferingSettlementAgentAuthoringBanner from './OfferingSettlementAgentAuthoringBanner';

export type Props = {
  onHide: () => void;
};

export const BasicInfoModal: React.FC<Props> = ({ onHide }) => {
  const history = useHistory();

  const [createOfferingDraft, { loading: creating, error }] = useCreateOfferingDraftMutation();

  const handleCreateOfferingDraft = async (values: CreateOfferingInput) => {
    try {
      const { data: createOfferingDraftData } = await createOfferingDraft({
        variables: { input: values },
      });

      if (
        createOfferingDraftData &&
        createOfferingDraftData?.createOfferingDraft.__typename === 'Offering'
      ) {
        onHide();
        history.push(
          routeFactory.offeringSetup.getUrlPath({
            offeringId: createOfferingDraftData.createOfferingDraft.id,
            stepId: stepIds.BASIC_INFO,
          })
        );
      }
    } catch (e) {
      console.error('Error', e);
    }
  };

  const onSubmit = async (values: CreateOfferingInput | UpdateBasicInfoInput) => {
    await handleCreateOfferingDraft(values as CreateOfferingInput);
  };
  return (
    <Modal show title="Create New Offering" onHide={onHide} size="small">
      <StyledModalContent>
        <SOfferingSettlementAgentAuthoringBannerContainer>
          <OfferingSettlementAgentAuthoringBanner />
        </SOfferingSettlementAgentAuthoringBannerContainer>
        <ModalBasicInfoForm
          onSubmit={onSubmit}
          onHide={onHide}
          submitting={creating}
          error={error}
        />
      </StyledModalContent>
    </Modal>
  );
};
